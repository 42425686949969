// import types from 'patientModule/Assessment/constants/CommonConstants'

const initState = {
  // currentPage: types.CAREPLAN_MAIN_PAGE
}

export default (state=initState, action) => {
  switch(action.type) {
    // case types.CHANGE_PAGE:
    //   return {
    //     ...state,
    //     currentPage: action.currentPage ? action.currentPage : initState.currentPage
    //   }
    default:
      return state
  }
}
