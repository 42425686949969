import React from 'react';
import { IHSearchTable } from 'ihcomponent';
import moment from 'moment';
// import MobilePhoneContiner from '../containers/MobilePhoneContainer';

const formatTime = (data)=>{
    return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY, HH:mm') :'time format error');
};
const MobileAppNameMap = {
    "com.ihealthlabs.sharecare":'ShareCare BG',
    "com.ihealthlabs.sharecarebp":'ShareCare BP'
}
const tableConfig =
    [{
        title: 'Name',
        dataIndex: 'phoneName',
    },
    {
        title:'Model',
        dataIndex:'phoneModel',
    },
    {
        title:'OS Version',
        dataIndex:'phoneOSVersion',
    },
    {
        title:'SC App Ver',
        dataIndex:'appVersion',
    },
    {
        title:'SC App Name',
        dataIndex:'appScheme',
        render:(data)=> MobileAppNameMap[data] || 'unknown App Name'
    },
    {
        title:'First Used',
        dataIndex:'firstUsed',
        render:(data)=>formatTime(data)
    },
    {
        title:'Last Used',
        dataIndex:'lastUsed',
        render:(data)=>formatTime(data)
    }
    ]

class MobilePhoneInfoComponent extends React.Component{
    render(){

        const { userPhoneList } = this.props;
        return <div style={{marginBottom:50}}>
            <IHSearchTable
                   title='Mobile Phone Info'
                   columns={tableConfig}
                   dataSource = {userPhoneList}
                   rowKey={(record,index)=>(index)}
            />
        </div>
    }
}

export default  MobilePhoneInfoComponent;
