const SERVER_URL = process.env.SERVER_URL || window.VSM_CONFIG.SERVER_URL || `http://${location.hostname}:4000`
// const UPLOAD_URL = `${window.VSM_CONFIG.SERVER_URL}/gothumb/uploadBase64`
// const SIGNED_URL = `${window.VSM_CONFIG.SERVER_URL}/gothumb/file`

// const UPLOAD_URL = `http://35.165.217.6:8888/uploadBase64`
// const SIGNED_URL = `http://35.165.217.6:8888/file`
const gothumbServerUrl = process.env.GOTHUMB_SERVER_URL || window.VSM_CONFIG.GOTHUMB_SERVER_URL || 'http://localhost:3000';

const UPLOAD_URL = `${gothumbServerUrl}/uploadBase64`;
const SIGNED_URL = `${gothumbServerUrl}/gothumb/file`;
const WORK_STATION_URL = process.env.WORK_STATION_URL || 'https://dev-api.ihealth-eng.com/v1/workstation/'
const BRAND = process.env.BRAND;
const GOOGLE_TRANSLATE_KEY = process.env.GOOGLE_TRANSLATE_KEY || 'AIzaSyB-pQVjfGpNq21xV0Vxhpm_veAOW7FsaVc';
const PUBLIC_S3_BUCKET = process.env.PUBLIC_S3_BUCKET || 'https://static.dev-new.ihealthunifiedcare.com';
const UC_SERVER_URL = process.env.UC_SERVER_URL || 'https://dev-uc.ihealth-eng.com/v1/uc';

const config = {
  SERVER_URL,
  WORK_STATION_URL,
  uploadFileUrl : UPLOAD_URL,
  signFileURL : SIGNED_URL,
  SECRET_KEY : 'WdXg91IKo8tj1sl3uQDP',
  BRAND,
  GOOGLE_TRANSLATE_KEY,
  PUBLIC_S3_BUCKET,
  UC_SERVER_URL,
};

export default config
