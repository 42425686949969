let CacheData = {}

if(window){
  window.__RequestCache = CacheData
}

const F = {
  getID : (prefix, id)=>{
    return `${prefix}-${id}`
  },
  set : (key, data, expired)=>{
    if(!key){
      throw new Error('Cache key is required')
    }

    CacheData[key] = {
      data,
      date : Date.now(),
      expired : expired || 'never'
    }

    return CacheData[key]
  },


  get : (key)=>{
    const data = CacheData[key]
    if(!data || !data.expired) return data || null
    if(data.expired === 'never') return data.data
    if(!_.isNumber(expired) || Date.now()-data.date < expired){
      return null
    }

    return data.data

  },

  clear: () => {
    CacheData = {};
  }
}

export default F
