import types from '../constants/CreateConstants';

const initState = {
  isSubmitting: false,
};

export default (state = initState, action) => {
  switch (action.type) {

    case types.SET_SUBMITTING: {
      return {
        isSubmitting: !!action.isSubmitting,
      };
    }

    default:
      return state;
  }
};
