import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Button } from 'antd';
import actions from '../actions';

const VisitNameFilter = props => {
  const { nameFilterValue, setNameFilterValue } = props;
  const [inputValue, setInputValue] = useState(nameFilterValue);

  useEffect(() => {
    setInputValue(nameFilterValue);
  }, [nameFilterValue]);

  const onSearch = () => setNameFilterValue([inputValue]);

  return <div id='name-filter-dropdown'>
    <Input
      placeholder=''
      ref={ref => { if(ref) ref.focus() }} 
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      onPressEnter={onSearch}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button type='primary' icon='search' size='small' style={{ width: 90, marginRight: 8 }}
      onClick={onSearch}
    >
      Search
    </Button>
    <Button size='small' style={{ width: 90 }}
      onClick={() => setNameFilterValue([])}
    >
      Reset
    </Button>
  </div>
};

VisitNameFilter.propTypes = {
  nameFilterValue: PropTypes.string.isRequired,
  setNameFilterValue: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  setNameFilterValue: value => dispatch(actions.setNameFilterValue(value))
});

export default connect(null, mapDispatchToProps)(VisitNameFilter);