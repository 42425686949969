import ProgramTable from 'modulesAll/patient/programTab/containers/ProgramTable.js'
import React from 'react';
import action from 'modulesAll/patient/programTab/actions/main.js';
import { connect } from 'react-redux'
import { Button,message } from 'antd';
import EditCareTeam from '../../programTab/query/editCareTeam';
import Client from 'libModule/gqlClient';
import '../css/index.scss';
import { modalAction as commonAction } from 'modulesAll/common/actions';
import _ from 'lodash';

const ChangeCareTeamContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    primaryProviderCompare = (next, curr)  => 
    {
        if (next.length !== curr.length) {
            return false;
        }
        _.map(next, (n) => {
            let flag = false;
            _.map(curr, (c) => {
                if (_.get(c, 'user.id') === _.get(n, 'user.id')) {
                    flag = true;
                }
            })
            if (!flag) return false;
        })
        return true;
    }

    onSubmit = ()=>{
        const { props } = this;
        const { closeModal, onError, ChangeCTSubmit, currentProgram } = props;
        const programId = _.get(props,'currentProgram.id');
        const { selectedCareTeam } = props;
        const teamId = _.get(selectedCareTeam,'id.0');
        const consentProviderName = _.get(selectedCareTeam, 'teamInfo.0.consentProviderName');
        const primaryProvider = _.filter(_.get(selectedCareTeam, 'teamInfo.0.members'),{'primary':true})
        const currPrimaryProvider = _.filter(_.get(currentProgram, 'team.0.members'),{'primary':true});
        const primaryProviderSame = this.primaryProviderCompare(primaryProvider, currPrimaryProvider);
        const variables = {
            teamId,
            id:programId
        }
        if (primaryProviderSame) {
            ChangeCTSubmit(variables, consentProviderName, false);
            this.setState({
                loading: true
            },()=> {
                Client.mutate({
                    mutation: EditCareTeam,
                    variables
                }).then(res => {
                    this.setState({loading: false})
                    message.success('Care team Updated');
                    closeModal();
                }).catch(err => {
                    closeModal();
                    if(onError) onError(err);
                });
            })
        }
        else {
            ChangeCTSubmit(variables, consentProviderName, true);
            closeModal();
        }
    }
    componentWillUnmount() {
        this.props.resetState();
    }

    renderSubmitButton = ()=>{
        const { state } = this;
        const { loading } = state;
        return <Button onClick={ this.onSubmit } loading={loading} id='saveChangeCareTeam'>Save</Button>
    }
    render(){
        const { props,renderSubmitButton } = this;
        const { currentProgram } = props;
        return <div>
                    <ProgramTable {...props} currentProgram={currentProgram}/>
                    { renderSubmitButton() }
               </div>
    }
}
const mapToDispatch = (dispatch)=>{
    return{
        setSelectCareteam:(index, tid, tname, teamInfo)=>dispatch(action.setSelectCareteam(index, tid, tname, teamInfo)),
        resetState:()=>dispatch(action.resetState()),
        closeModal: ()=>dispatch(commonAction.closeModal())
    }
}
const mapToProps = (props)=>{
    return {
        selectedCareTeam:_.get(props,'patient.program.selectCareteam'),
    }
}
export default connect(mapToProps,mapToDispatch)(ChangeCareTeamContainer);