import { useMemo } from 'react';
import { renderProvider } from '.';

const useProviderFilters = ({
  shortenNameForCareTeam = true,
} = {}) => {
  const providers = JSON.parse(sessionStorage.getItem('doctorName'));

  const MDFilters = useMemo(
    () => (
      _.map(providers, (p) => {
        return {
          text: renderProvider(p, shortenNameForCareTeam),
          value: p.id
        };
      })
    )
    , [providers]);

  return {
    MDFilters
  };
};

export default useProviderFilters;
