import _ from 'lodash';

export const reqAnimFrame = (animFunc) => {
  if (window.requestAnimationFrame) {
    return window.requestAnimationFrame(animFunc);
  }

  const prefix = _.find(['moz', 'ms', 'webkit'], key => `${key}RequestAnimationFrame` in window);
  return prefix
    ? window[`${prefix}RequestAnimationFrame`](animFunc)
    : setTimeout(animFunc, 1000 / 60);
};

let animId = -1;
let stopListenerAttached = false;

const easeInOutCubic = (t, b, c, d) => {
  const cc = c - b;
  t /= d / 2;
  if (t < 1) {
    return cc / 2 * t * t * t + b;
  }
  return cc / 2 * ((t -= 2) * t * t + 2) + b;
};

const attachStopListener = () => {
  if (stopListenerAttached) {
    return;
  }

  ['resize', 'wheel'].forEach((name) => {
    window.addEventListener(name, () => (animId = -1));
  });
  stopListenerAttached = true;
};

const getVerticalScroll = (target = window) => {
  const isWindow = target === window;
  let ret = isWindow ? target.pageYOffset : target.scrollTop;

  if (isWindow && typeof ret !== 'number') {
    ret = window.document.documentElement.scrollTop;
  }

  return ret;
};

export const scrollToBottom = (duration = 300, scrollTarget = window) => {
  attachStopListener();
  const startTime = animId = Date.now();

  const windowHeight = scrollTarget.innerHeight;
  const originalScrollTop = getVerticalScroll(scrollTarget);
  const targetScrollTop = (!!document.documentMode  /** VS-1912 if IE <=11, document.body.scrollHeight in IE return incorrect value **/? document.documentElement.scrollHeight : document.body.scrollHeight) - windowHeight;

  const frameFunc = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;

    if (time < duration && startTime === animId) {
      window.scrollTo(
        window.pageXOffset,
        easeInOutCubic(time, originalScrollTop, targetScrollTop, duration),
      );
      reqAnimFrame(frameFunc);
    }
  };
  reqAnimFrame(frameFunc);
};

export default scrollToBottom;
