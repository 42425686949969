import { combineReducers } from 'redux'
import OrganizationCreate from '../reducer/organizationCreateReducer';
import OrganizationEdit from '../reducer/organizationEditReducer';


const reducer = combineReducers({
    OrganizationCreate,
    OrganizationEdit
})

export default {
  organization: reducer
}
