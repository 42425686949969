import { React, IHButton } from 'ihcomponent';
import { goPath } from 'libModule/utils';
import I18N from 'modulesAll/I18N';
// component
import PreviewInnerContainer from '../../../patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer';

const NursePreviewComponent = (props) => {
  const assessmentId = props.params.assessmentId;
  const onCancel = () => goPath('/nurse_assessments');

  return (
    <div className="patient-take-assessment">
      <button className="link-like back" onClick={onCancel}>&lt; Back to {I18N.get('keywords.Assessments')}</button>
      <div className="paper">
        <PreviewInnerContainer isAssessment id={assessmentId} breadCrumb={I18N.get('keywords.Assessment')} />

        <div className="btn-wrapper">
          <div className="right">
            <IHButton label="Back" onClick={onCancel} bsStyle="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};

NursePreviewComponent.displayName = 'NursePreviewComponent';

export default NursePreviewComponent;
