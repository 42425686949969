import {React, _,IHLoading} from 'ihcomponent';
import { connect } from 'react-redux';
import   actions   from '../actions';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { getSortedAdminProgramFormSessionStorage } from 'modulesAll/utils/helpers/program'
import MonthlyTrendsComponent from '../components/MonthlyTrendsComponent';
import WeeklyTrendsComponent from '../components/WeeklyTrendsComponent';
import programMeasureTrends from '../../../graphql/programMeasureTrends'

const ProgramMeasureTrendsContainer = class extends React.Component {
  static displayName = 'dashboard/containers/ProgramMeasureTrendsContainer';

  constructor() {
      super()
  }

  renderBody(){
       let { currentPeriod,data,groupBy,loading,currentPrograms } = this.props;
       let width =  650,
           height = 420,
           marginTop = 40,
           marginRight = 20,
           marginBottom = 30,
           marginLeft =  40;

      // if(!currentPrograms.length) {
      //     return (
      //            <div className='programTrendComponContainer'>
      //               <span>no program selected</span>
      //            </div>
      //            )
      // }
      //   return <IHLoading/>
      if(loading){
          return <IHLoading />;
      }

      if(data&&groupBy==='MONTHLY' && currentPeriod ==='MONTH') {
              return <MonthlyTrendsComponent
                  programMeasureTrends={ data }
                  width={ width }
                  height={ height }
                  marginTop={ marginTop }
                  marginRight={ marginRight }
                  marginBottom = { marginBottom }
                  marginLeft = { marginLeft }
                  currentPrograms = { currentPrograms }
                  />
          }

       if(data&&groupBy==='WEEKLY' && currentPeriod ==='WEEK') {
              return <WeeklyTrendsComponent
                  programMeasureTrends={ data }
                  width={ width }
                  height={ height }
                  marginTop={ marginTop }
                  marginRight={ marginRight }
                  marginBottom = { marginBottom }
                  marginLeft = { marginLeft }
                  currentPrograms = { currentPrograms }
              />
          }

  }

  render() {
    return(
            <div className='programMeasureTrendsContainer'>
              <div className='programMeasureTrendsHeaderContainer'>
                  <div className='programMeasureTrendsHeaderBox barbardos-header'>
                    <div className='programMeasureTrendsHeader '>Blood Pressure Control Trends</div>
                    <div className='programMeasureTrendsSubheader'>Based on individual weekly / monthly average BP values</div>
                  </div>
                  <div className='programMeasureOptBox'>
                    <div className= {this.props.currentPeriod ==='MONTH' ? 'programMeasureOpt':'programMeasureOpt selectedPeriod'} onClick={ ()=>this.props.selectCurrentPeriod('WEEK') }>
                       <text>WEEKLY</text>
                    </div>
                    <div className= {this.props.currentPeriod ==='MONTH' ? 'programMeasureOpt selectedPeriod':'programMeasureOpt'} onClick={ ()=>this.props.selectCurrentPeriod('MONTH') }>
                       <text>MONTHLY</text>
                    </div>
                      <div style={{width:'60%',borderBottom:'1px solid #d9d9d9',height:'100%' }}>
                      </div>
                  </div>
              </div>
                <div className='programMeasureTrendsBottomContainer'>
                {this.renderBody()}
                </div>
            </div>
    )
  }
};

const withData = graphql(programMeasureTrends, {
  options(ownProps){
    const adminPrograms = getSortedAdminProgramFormSessionStorage()
    // const adminProgramIds = ownProps.currentProgram || _.get(adminPrograms, '0.id');
    const adminProgramIds = ownProps.currentPrograms;
    const currentPeriod = ownProps.currentPeriod;
    return {
      variables : {
        adminProgramIds,
        currentPeriod
      }
    }
  },
  props( inputData ){ // TODO: Why does this run 2x, once w/o data, next w/ data?
        inputData = inputData.data||{};
        if(inputData&&inputData.measureTrendsForProgram) {
            const {groupBy, data} = inputData.measureTrendsForProgram;
            const { loading,error } = inputData
            return {
                groupBy,
                data,
                loading,
                error
            }
        }
    }
})

ProgramMeasureTrendsContainer.propTypes = {

    currentPeriod:PropTypes.string.isRequired,
    measureTrendsForProgram: PropTypes.func,
    groupBy:PropTypes.string,
    loading:PropTypes.bool

}

ProgramMeasureTrendsContainer.defaultProps = {

    loading:true,
    error:null

}


function mapStateToProps(state){
        return{
            currentPeriod:state.dashboard.main.currentPeriod,
            currentProgram:state.dashboard.main.currentProgram
    }
}


 export default compose(
     connect(mapStateToProps,{ selectCurrentPeriod: actions.selectCurrentPeriod}),withData
)(ProgramMeasureTrendsContainer);
