import { graphql } from 'react-apollo'
import adminProgram from 'graphqlModule/adminProgram'
import deleteProgram from 'graphqlModule/deleteProgram'

import { connect } from 'react-redux'
import React, { Component } from 'react';
import { goPath, GQLHelper } from 'libModule/utils'
import actions from 'ProgramIncompleteModule/Common/actions/CommonActions'
import {modalAction  as commonAction }  from 'modulesAll/common/actions'
import get from 'lodash/get'
import { IHLoading, IHTab, IHButton } from 'ihcomponent'
import {Link} from 'react-router'
import ProgramInfoFormContainer from 'ProgramIncompleteModule/ProgramInfo/containers/ProgramInfoFormContainer'
import DeviceFormContainer from 'ProgramIncompleteModule/Device/containers/DeviceFormContainer'
import CareplanContainer from 'ProgramIncompleteModule/Careplan/containers/CommonContainer'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import update from 'immutability-helper'
import { labelCurrentinProgramBGTasks } from '../../../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';
import PropTypes from 'prop-types';
import * as R from 'ramda';

class Container extends Component {
  static displayName = 'program/ProgramIncomplete/Common/containers/CommonContainer'

  componentDidMount() {
    const { setTabsStep } = this.props
    setTabsStep('programInfo')

    if (this.props.program) {
      this.props.setBreadCrumb(`${I18N.get('keywords.Programs')} / ${this.props.program.name} (Incomplete)`)
    }
  }

  componentWillUnmount() {
    // set to initial step
    const { setTabsStep } = this.props
    setTabsStep('programInfo')
  }

  componentDidUpdate() {
    if (this.props.program) {
      this.props.setBreadCrumb(`${I18N.get('keywords.Programs')} / ${this.props.program.name } (Incomplete)`)
    }
  }

  render() {
    const { program, loading } = this.props
    if (loading) return <IHLoading />
    const pagePath = program ? program.name : 'edit'

    return (
      <div className='vsm-main-page vsm-form-container'>
        {this.renderBackLink()}
        <div className="vsm-tab-container" style={{marginBottom:'20px'}}>
          {this.renderTabs(this.props)}
        </div>
        {this.renderDeleteProgramme(this.props)}
      </div>
    )
  }

  renderDeleteProgramme(){
    const {id} = this.props.program || {};
    return (
      <div style={{ margin: '0 auto', //width:'84%', maxWidth: '1278px',
        minWidth: '940px'}}>
        <IHButton
          className="btn-delete"
          label={`Delete ${I18N.get('keywords.Program')}`}
          onClick={() => this.confirmDelete(id)}
          style={{ float: 'left' }}
        />
      </div>
    )
  }

  confirmDelete(id){
    const { closeModal, openModal } = this.props
    const p1 = {
      type : 'primary',
      label : 'Confirm',
      size : 'large',
      onClick : ()=>{
        this.deleteProgram(id)
      }
    }
    const p2 = {
      label : 'Close',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : ()=>{
        closeModal()
      }
    }
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    )
    const param = {
      size : 'sm',
      title : `Confirm Delete ${I18N.get('keywords.Program')}`,
      content : `Are you sure you want to delete this ${I18N.get('keywords.program')} template?`,
      footer
    }
    openModal(param.content, param)
  }

  renderBackLink(){
    return (
      <div className="v-link">
        <Link to="/programmes_admin">
          <i className="fa fa-angle-left" />
          <span>Back to {I18N.get('keywords.Programs')}</span>
        </Link>
      </div>
    )
  }

  renderTabs(props) {
    const { Common, ProgramInfoForm, DeviceForm, Careplan, setTabsStep, changePage, program } = props
    const p = {
      onSelect: (id) => {
        setTabsStep(id)
        switch(id){
          case 'programInfo':
            createAuditLog({action:I18N.get('auditLog.admin.programme.view.info')})
            break;
          case 'careplan':
            createAuditLog({action:I18N.get('auditLog.admin.programme.view.careplan')})
            break;
          case 'device':
            createAuditLog({action:I18N.get('auditLog.admin.programme.view.device')})
            break;
          default:
        }
      },
      itemList: [
        {
          id: 'programInfo',
          key: 'programInfo',
          icon: '1',
          title: `${I18N.get('keywords.PROGRAM')} INFO`,
          content: <ProgramInfoFormContainer {...ProgramInfoForm} changePage={changePage} program={program} />
        },
        {
          id: 'careplan',
          key: 'careplan',
          icon: '2',
          title: 'CARE PLAN',
          content: <CareplanContainer {...Careplan} changePage={changePage} program={program} />
        },
        {
          id: 'device',
          key: 'device',
          icon: '3',
          title: 'DEVICE',
          content: <DeviceFormContainer {...DeviceForm} changePage={changePage} program={program} />
        }
      ],
      active: Common.step
    }

    return (
      <IHTab {...p} />
    )
  }

  deleteProgram = async(id) => {
    const { mutate, openErrorModal, closeModal, program } = this.props
    const variables = {
      id
    }
    closeModal()
    try {
      const res = await mutate({ variables })
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.delete'),
        details: { programName: program.name },
        request: variables,
        response: res
      })

      //const message = 'You have successfully deleted program template'
      //openErrorModal(message)
      // console.log('got data', res.data);
    }
    catch (err) {
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.delete'),
        details: { programName: program.name },
        request: variables,
        response: err,
        success: false
      })
    }finally {
      goPath('/programmes_admin')
    }
  }
}

// data from apollo
const withData = graphql(adminProgram, {
  options: (ownProps) => {
    const id = get(ownProps, 'params.id')
    return {
      variables: {
        id
      },
      notifyOnNetworkStatusChange: true,
      reducer: (previousResult, action, variables) => {
        if (action.type === 'APOLLO_MUTATION_RESULT') {
          switch (action.operationName) {
            case 'setDevicesForAdminProgram':
              return update(previousResult, {
                adminProgram: {
                  devices: { $set: action.result.data.setDevicesForAdminProgram.devices }
                }
              })
              break;
            default: return previousResult
          }
        }
        return previousResult
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, adminProgram, error } = data
    if (error) {
      console.error(error)
    }
    return {
      program: R.isNil(adminProgram) || R.type(adminProgram.tasks) !== 'Array' ? adminProgram : labelCurrentinProgramBGTasks(['tasks'], adminProgram),
      loading,
      refetch
    }
  }
})

// connect apollo data with container
const ContainerWithData = graphql(deleteProgram)(withData(Container))

// mapState
const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramIncomplete
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    setTabsStep: id => dispatch(actions.setTabsStep(id)),
    changePage: (page) => dispatch(actions.changePage(page)),
    setBreadCrumb: (path) => dispatch(setBreadCrumb(path)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    closeModal: () => dispatch(commonAction.closeModal()),
    openModal: (content, modalProps) => dispatch(commonAction.openModal(content, modalProps)),
  }
}

// PropTypes validation
Container.propTypes = {
  program: PropTypes.object,
  setTabsStep: PropTypes.func,
  changePage: PropTypes.func,
  ProgramInfoForm: PropTypes.object,
  Careplan: PropTypes.object,
  DeviceForm: PropTypes.object,
  openErrorModal: PropTypes.func,
  setBreadCrumb: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  loading: PropTypes.bool
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(ContainerWithData)
