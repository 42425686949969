import Client from 'libModule/gqlClient';
import { deviceNameMap, BASE_COUNTER, defaultDeviceCount as defaultCount } from '../constant/onboardMap';
import { getVisitInventory } from '../../graphql/inventory';
import { removeFromInventoryWithVisit as updateInventoryGQL } from '../../graphql/updateQueries/removeFromInventory';

const ignoreList = ['Lancets', 'Strips', 'iPhone', 'TrackSet', '__typename'];

export const updateVisitInventory = (visit, variables) => {
  const newDeviceRecordCount = {...variables.deviceRecordCount};
  const visitId = visit.id;
  const memberId = visit.member.id;
  console.log(visit);
  const validateCount = (count = 0) => {
    const validCount = count / BASE_COUNTER;
    // for negative / giveout, when Math.ceil, it would give in-correct count
    // eg. Math.ceil(-0.9) = -0, but expecting -1
    return validCount >= 0 ? Math.ceil(validCount) : Math.floor(validCount);
  }

  //--- Step 1: validate count, especially for followup
  const updatedInventoryCount = {...newDeviceRecordCount};
  _.forEach(_.keys(updatedInventoryCount), key => {
    const count = updatedInventoryCount[key];
    // no need to validate count for devices/supplies in ignoreList
    updatedInventoryCount[key] = !_.includes(ignoreList, key) ? validateCount(count) : count;
  });
  //--- Step 2: replace inventoryCount with updatedInventoryCount
  // variables.inventoryCount = {...updatedInventoryCount};

  const execute = async () => {
    let oldInventoryCount = _.get(visit, 'inventoryCount') || {};
    const diffToUpdate = {};

    if(_.get(visit, 'checkoutAt') && _.isEmpty(_.get(visit, 'inventoryCount'))) {
      // prevent double-inventory for existing visits before ticket SC-5367 release
      // this step only happens ONLY once before inventoryCount is set
      const { data = {} } = await Client.query({
        query: getVisitInventory,
        variables: { filters: { visitId }},
        fetchPolicy: 'network-only'
      }).catch(err => console.error(err));

      const pastVisitInventory = _.get(data, 'inventoryTransactionList.data.0.inventoryHistories') || [];
      _.forEach(pastVisitInventory, ({ product }) => {
        const { productType, quantity } = product;
        const key = _.find(_.keys(deviceNameMap), dKey => deviceNameMap[dKey] === productType);
        if(key) {
          oldInventoryCount[key] = quantity === 0 ? quantity : quantity * -1;
        }
      });
    }

    oldInventoryCount = {...defaultCount, ...oldInventoryCount};

    //---get count for inventory (difference between newDeviceRecordCount and inventoryCount)
    _.forEach(_.keys(updatedInventoryCount), key => {
      const diff = updatedInventoryCount[key] - (oldInventoryCount[key] || 0);
      if(diff !== 0){
        // only add key when there is difference in count
        diffToUpdate[key] = diff;
      }
    });

    //---update inventory IFF there is difference from previous inventory
    if(!_.isEmpty(diffToUpdate)){
      const currentLoginUser = JSON.parse(sessionStorage.getItem('currentUser'));
      const products = _.map(_.keys(diffToUpdate), deviceName => ({
        productType: _.toUpper(_.get(deviceNameMap, deviceName)),
        // NOTE: positive count => giveout; negative count => restock
        quantity: _.get(diffToUpdate, deviceName)
      }));
      // add A1C to inventory
      // 20200519 Thong - A1C is not saved correctly per visit
      // use logic to check if A1C is given for visit
      // When create visit, A1C has createdAt few seconds before visit's created
      // 20200625 Thong - due to SC-4557
      // const visitCreatedAt = moment(_.get(data, 'createdAt'));
      // const A1CCreatedAt = moment(_.get(data, 'labResult.createdAt'));
      // const hasA1C = _.get(data, 'labResult.createdAt') &&
      //   (visitCreatedAt.isBefore(A1CCreatedAt) || // A1C is added with Edit Visit
      //     visitCreatedAt.diff(A1CCreatedAt, 'minutes') < 1); // A1C is added with Create Visit
      // products.push({
      //   productType: 'A1C',
      //   quantity: ~~hasA1C
      // });
      Client.mutate({
        mutation: updateInventoryGQL,
        variables: {
          organizationId: _.get(currentLoginUser, 'selectedRole.organization.id'),
          memberId,
          visitId,
          products,
          note: '--',
        },
        fetchPolicy: 'no-cache'
      }).catch(err => {
        console.error(`Failed to update inventory\n${err.message }`);
      });
    }
  }

  //--- Step 2: update
  execute();
  return {...updatedInventoryCount};
};
