import types from '../constants/EditCareTeamConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
])

const changeManager = (managers, p_managers)=>{
  return {
    type : types.CHANGE,
    data : {
      managers,
      p_managers,
    }
  }
}

const changeDoctor = (doctors, p_doctors)=>{
  return {
    type : types.CHANGE,
    data : {
      doctors,
      p_doctors
    }
  }
}

export default {
  ...commonActionsObject,
  changeManager,
  changeDoctor
}
