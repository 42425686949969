import PropTypes from 'prop-types';
import React from 'react';
import LineChart from './LineChartComponentRevised'
import moment from 'moment'
import { alertColor } from '../constants/VitalListConstants'
import { TM_UNIT } from 'modulesAll/utils/constants/task'

const returnValidTM = (data) => (
  data.filter(d => d.taskStatus === 'COMPLETED' && !!d.body_temperature)
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      'Temperature': d.body_temperature.value,
      date: d.date,
      key: 'Temperature',
      id: d.id,
      datum: d
    }))
)

const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const setFillColorTM = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity)? alertColor[d.datum.severity] : defaultFill
}
const formatTooltipTM = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const { body_temperature } = d.datum
  const tmUnit = body_temperature? body_temperature.unit : TM_UNIT
  return `${body_temperature? body_temperature.value : '--'} ${tmUnit}`
}
const TMChart = ({ data, range, thresholds_temperature }) => {
  const chartData = returnValidTM(data, ['Temperature'])
  const missed = returnMissed(data)
  const setFillColor = setFillColorTM
  const formatTooltip = formatTooltipTM
  const p = {
    data: chartData,
    missed,
    value: ['Temperature'],
    lineColor: ['#0099ff'],
    setFillColor,
    formatTooltip,
    thresholds: thresholds_temperature,
    range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
  }
  return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
}

TMChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array
}
export default TMChart
