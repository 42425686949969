import React from 'react'
import {
  IHPanel
} from 'ihcomponent'

const EmergencyContact = () => {
  return (
    <div>
      <div style={{fontSize: '16px', fontWeight: '600', margin:'80px 130px 10px 130px'}}>Emergency Contact Info</div>
      <div style={{padding: '40px 60px', margin: '0 130px 130px 130px', backgroundColor: 'white', border: '1px solid #c8c2bd', borderRadius: '4px'}}>
        {renderPanel1()}
        {renderPanel2()}
        {renderPanel3()}
      </div>
    </div>
  )

}

EmergencyContact.displayName = 'emergencyContactsComponent';

export default EmergencyContact

function renderPanel1() {
  const p = {
    header: {
      title: 'Helpdesk Contact Numbers'
    },
    body: getPanelBody()
  }

  return (
    <div style={{margin:'0 0 40px 0'}}>
      <IHPanel {...p} />
    </div>
  )
  function getPanelBody() {
    const info = [
      {department: '[Department]', category: 'For general enquiries', phone: '+1 (415) 255-3788'},
      {department: '[Department]', category: 'For [Services]', phone: '+1 (415) 255-3789'},
      {department: '[Department]', category: 'For [Services]', phone: '+1 (415) 255-3790'},
      {department: '[Department]', category: 'For [Services]', phone: '+1 (415) 255-3791'}
    ]
    return (
      <div>
        {
          info.map((item, i) => (
            <div style={{display: 'inline-block', width: '50%', height: '100px'}} key={`contact+${i}`}>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.department}</div>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.category}</div>
              <div style={{fontSize: '15px'}}>{item.phone}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

function renderPanel2() {
  const p = {
    header: {
      title: 'Medical Support'
    },
    body: getPanelBody()
  }

  return (
    <div style={{margin:'0 0 40px 0'}}>
      <IHPanel {...p} />
    </div>
  )
  function getPanelBody() {
    const info = [
      {department: '[Department]', category: 'For Medical Emergencies', phone: '+1 (415) 255-3792'},
      {department: '[Department]', category: 'For [Services]', phone: '+1 (415) 255-3793'}
    ]
    return (
      <div>
        {
          info.map((item, i) => (
            <div style={{display: 'inline-block', width: '50%', height: '100px'}} key={`contact+${i}`}>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.department}</div>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.category}</div>
              <div style={{fontSize: '15px'}}>{item.phone}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

function renderPanel3() {
  const p = {
    header: {
      title: 'Technical Support'
    },
    body: getPanelBody()
  }

  return (
    <div>
      <IHPanel {...p} />
    </div>
  )
  function getPanelBody() {
    const info = [
      {department: '[Department]', category: 'For Online Technical Emergencies', phone: '+1 (415) 255-3794'},
      {department: '[Department]', category: 'For [Services]', phone: '+1 (415) 255-3795'}
    ]
    return (
      <div>
        {
          info.map((item, i) => (
            <div style={{display: 'inline-block', width: '50%', height: '100px'}} key={`contact+${i}`}>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.department}</div>
              <div style={{color: '#19313a', fontSize: '15px', fontWeight: '600'}}>{item.category}</div>
              <div style={{fontSize: '15px'}}>{item.phone}</div>
            </div>
          ))
        }
      </div>
    )
  }
}
