import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const EmptyClinicListPlaceholder = props => {
  return (
    <div id='clinic-empty-placeholder'>
      <div id='clinic-create-tip'>
        { props.content }
      </div>
      <Button 
        type='primary' 
        onClick={props.openAddModal}
      >
        { props.buttonLabel }
      </Button>
    </div>
  )
};

EmptyClinicListPlaceholder.propTypes = {
  openAddModal: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired  
};

export default EmptyClinicListPlaceholder; 

