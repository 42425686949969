
export default {
    setYear: (year) => {
        console.log('year',year);
        return {
            type: 'SET_YEAR',
            payload: year
        }
    },
    setMonth: (month) => {
        console.log('month',month);
        return {
            type: 'SET_MONTH',
            payload: month
        }
    }
}
