import React, { Component } from 'react';
import { Row, Col, Radio, Icon, Form } from 'antd';
import roleMap from '../../constant/roleMap';
import { ROLE_NAME } from '../../../../lib/constants';

export default class SelectedMembersComponent extends Component {
  static displayName= 'newAdmin/Area/component/SelectedMembersComponent';

  constructor(props) {
      super(props)
  }


  renderHeader = () => {
    return <Row className='add-table-header'>
      <Col span={19}>
        <Col span={6} style={{paddingLeft: '30px'}}>Name</Col>
        <Col span={2} style={{paddingLeft: '15px'}}>Role</Col>
        <Col span={10} style={{paddingLeft: '10px'}}>Site</Col>
        <Col span={5} style={{paddingLeft: '20px'}}>Start Date</Col>
      </Col>
      <Col span={5}>Role in this clinic</Col>
    </Row>
  }


  renderCard = (key, provider) => {
    const {onCancelSelect, onRoleSelect, form, availableRoles} = this.props;

    // if the member is new registered, then there's no children.props, just user the document
    // see CreateMemberFormComponent
    const childProps = _.get(provider, 'props.children.props') || provider;
    const { fullName, roles, sites, startDate, previousRole, newRegistered } = childProps;

    const newRegisteredMark = <span className='new-registered-mark'>New</span>
    // if the member was a Clinic member, then this member should must also be the same role in the new clinic
    // if the member was a iHealth member, then we can assign a new RD/CA role for this member.
    const selectedRole = ((previousRole === 'Clinic Medical Assistant' || previousRole === 'Doctor')) ? previousRole : _.get(provider, 'selectedRole');

    return<Row key={key} className='selected-member'>
      <Col span={19} className='selected-card'>
        <Col span={6}>
          {fullName}
          {newRegistered && newRegisteredMark}
        </Col>
        <Col span={2}>
          {roles}
        </Col>
        <Col span={11}>
          {sites}
        </Col>
        <Col span={3}>
          {startDate}
        </Col>
        <Col span={1}>
          <Icon type='close' onClick={() => onCancelSelect(key)} style={{float: 'right', marginRight: '-20px'}}/>
        </Col>
      </Col>
      <Col span={5} style={{padding: '15px'}}>
          <Form.Item style={{ margin: 0 }}>
              {form.getFieldDecorator( `roles.${key}`, {
                initialValue: selectedRole,
                valuePropName: 'value',
                rules: [{required: true, message: 'Please choose a role'}]
              })(

                ((selectedRole === 'Clinic Medical Assistant' || selectedRole === 'Doctor')) ? <div>{roleMap[selectedRole]}</div>:
                <Radio.Group onChange = {e=> onRoleSelect(key, e.target.value)}>
                  {_.map(availableRoles, r => {
                    if(r === 'RD') return <Radio value='Nurse' key='RD'>RD</Radio>
                    if(r === 'CA') return <Radio value='Medical Assistant' key='CA'>CA</Radio>
                    if(r === roleMap[ROLE_NAME.HC]) 
                      return <Radio value={ROLE_NAME.HC} key={roleMap[ROLE_NAME.HC]}>
                        {roleMap[ROLE_NAME.HC]}
                      </Radio>;
                  })}
                  </Radio.Group>

              )}
          </Form.Item>
      </Col>
    </Row>
  }

  renderTableContent = () => {
    const {selectedProviders} = this.props;
    const keys = Object.keys(selectedProviders)
    return <div className='selected-members-wrapper'>
      <div className='selected-members-content'>
      {_.map(keys, (key, i) => {
          const p = selectedProviders[key];
          return <div key={i}>
              {this.renderCard(key, p)}
          </div>
      })}
      </div>
    </div>
  }


  render(){
    const { availableRoles } = this.props;
    return<div className='selected-members-table'>
      <div className='role-candidates'>
        <div>You can add the following roles to this clinic: </div>
        {_.join(availableRoles, ', ').trim(',')}
      </div>
      <hr className='solid' style={{borderTop: '2px solid #D2D2D2'}}/>
     {this.renderHeader()}
     {this.renderTableContent()}
    </div>
  }

}