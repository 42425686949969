import { React, IHButton, _ } from 'ihcomponent';
import PropTypes from 'prop-types';
import I18N from 'modulesAll/I18N';
import QuestionContainer from '../containers/QuestionContainer';
import {
  HEALTH_CONDITION_RADIO,
  renderValidatableInput,
  renderValidatableSelect,
} from '../../util';

export default class Component extends React.Component {
  static displayName = 'AssessmentFormComponent'

  renderSavedQuestions() {
    const { questions } = this.props.fields;
    const savedQuestions = []
    let questionNo = 0
    /* Need to preserve the index since we use them as key for React list */
    questions.forEach((v, i) => { if (v._status === 'SAVED') savedQuestions[i] = v; });

    if (savedQuestions.length) {
      return (
        <div>
          <h5>{I18N.get('keywords.Assessment')}</h5>
          <div className="saved-wrap">
            {/* _.map doesn't skip holes of sparse array.
              We want to reserve key for React list of questions, thus using sparse array */
              savedQuestions.map((question, index) => (
              <QuestionContainer question={question} key={index} index={index} questionNo={questionNo++}/>
            ))}
          </div>
        </div>
      );
    }
  }

  renderDraftQuestions() {
    const { questions } = this.props.fields;
    const draftQuestions = []
    /* Need to preserve the index since we use them as key for React list */
    questions.forEach((v, i) => { if (v._status !== 'SAVED') draftQuestions[i] = v; });
    const length = questions.length - draftQuestions.length;

    if (draftQuestions.length) {
      return (
        <div>
          <h5>Add New Question</h5>
          <div className="draft-wrap">
            {/* _.map doesn't skip holes of sparse array.
              We want to reserve key for React list of questions, thus using sparse array */
              draftQuestions.map((question, index) => (
              <QuestionContainer question={question} key={index + length} index={index + length} />
            ))}
          </div>
        </div>
      );
    }
  }

  render(showHealthCondition = true) {
    const { routing, changeField, fields, addQuestion, onSave, onCancel } = this.props;
    return (
      <div className="edit-wrap">
        <h5>Name *</h5>
        {renderValidatableInput(fields.name, {
          className: 'input-name',
          onChange: ({ target }) => changeField('name', target.value),
        })}

        <h5>Description *</h5>
        {renderValidatableInput(fields.description, {
          className: 'input-description',
          type: 'textarea',
          onChange: ({ target }) => changeField('description', target.value),
        })}

        { showHealthCondition === true ? (<h5>Health Condition *</h5>) : null }
        { showHealthCondition && renderValidatableSelect(fields.healthCondition, {
          className: 'input-health-condition',
          size: 'large',
          option: HEALTH_CONDITION_RADIO,
          onSelect: value => changeField('healthCondition', value),
        })}

        <div className="gap-line" />

        {this.renderSavedQuestions()}
        {this.renderDraftQuestions()}

        {!fields.questions.some(q => q._status !== 'SAVED') && (
          <IHButton label="Add New Question" className="add-new-btn" onClick={addQuestion} bsStyle="primary" />
        )}

        <div className="btn-wrapper">
          <div className="right">
            {fields.__error ? <div className="error">{fields.__error}</div> : null}
            <IHButton ref={btn => this.btn = btn} label="Save" loading='Saving...' onClick={onSave.bind(null, this) /* Do not bind this.btn since ref may not be called yet */} bsStyle="primary" />
            <IHButton label="Cancel" onClick={onCancel} />
          </div>
        </div>
      </div>
    );
  }
}

Component.propTypes = {
  addQuestion: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  changeField: PropTypes.func,
  fields: PropTypes.object,
  routing: PropTypes.object
}
