import React, { useMemo } from 'react';
import { decryptRole } from '../../../lib/utils';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import { IHEALTH_MEMBER_ROLES } from '../../utils/constants/role';

const withRoleInfo = (Component) => {
  const WrappedComponent = (props) => {
    const roleInfo = useMemo(() => {
      const isMD = checkIsInRole(['Doctor']);
      const isCareTeam = checkIsInRole(IHEALTH_MEMBER_ROLES);
      return { isMD, isCareTeam };
    }, [decryptRole()]);

    return (
      <Component
        roleInfo={roleInfo}
        {...props}
      />
    );
  }

  return WrappedComponent;
};

export default withRoleInfo;
