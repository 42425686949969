import React from 'react';
import { Modal, Table,notification,message } from 'antd';
import ConsentFormModalContainer from "../container/ConsentFormModalContainer";
import { Popover, Button } from 'antd';
import API from '../../API/index';
const { confirm } = Modal
const ConsentTemplateListComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showModal:false
        }
    }

    setShowModal = (showModal,id)=>{
        this.setState({
            showModal, id
        })
    }

    onCancel=(showModal=false,id=null)=>{
        const { refetchTemplateGroupList } = this.props;
        this.setShowModal(showModal,id);
        refetchTemplateGroupList&&refetchTemplateGroupList();
    }
    renderModal = ()=>{
        const { setShowModal,onCancel } = this;
        const { showModal,id } = this.state;
        const { refetchTemplateGroupList } = this.props;
        return showModal&&<Modal visible={true} footer={false} maskClosable={false}
                                 width={750} onCancel={ ()=>this.onCancel() }>
            <ConsentFormModalContainer id={id} refetchTemplateGroupList={refetchTemplateGroupList}
                                       onCancel={onCancel}
                                       setShowModal={setShowModal}/>
        </Modal>
    }

    showConfirm=(id)=>{
        const localThis = this;
        confirm({
            title: 'Are you sure to delete this consent form?',
            onOk() {
                localThis.handleRemoveTG(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    handleRemoveTG = (id)=>{
        API.removeTemplateGroup({id}).then(res=>{
            this.setShowModal(false,null);
            const { refetchTemplateGroupList } = this.props;
            message.info('Template Deleted');
            refetchTemplateGroupList&&refetchTemplateGroupList();
        });
    }

    renderButtons=(doc)=>{
        const assignedToClinics = _.get(doc,'organizations.length');
        const editButton =<Button onClick={()=>this.setShowModal(true,doc.id)}>Edit</Button>
        const removeButton = <Button onClick={()=>this.showConfirm(doc.id)} type={'danger'} disabled={assignedToClinics}>Remove</Button>

        return <div className={'templateGroupBtnsWrapper'}>{editButton}{removeButton}</div>;
    }

    renderList = ()=>{
        const { templateGroupsList,pageInfo } = this.props;
        const pagination = {
            pageSize:10,
            total:pageInfo.total
        }
        const tableConfig = [{  key:'name',dataIndex:'name',title:'Name' },
                             {
                                key: 'language', dataIndex: 'language', title: 'Language',
                                render: (d,c) => {
                                    const content = _.map(d,l=>l.description).join('/');
                                    return d.length ? <Popover content={content}>{`${d.length} languages`}</Popover> : '--'
                                }
                             },
                             {
                                 key:'organizations',dataIndex: 'organizations',title:'Assigned clinic(s)',
                                 render:(d)=>{
                                     const content = _.map(d,d=>d.name).join('\n');
                                     return d.length ? <Popover content={content}>{`${d.length} clinic`}</Popover> : '--'
                                 }
                             },
                             {
                                key:'actions',
                                render:(dummy,doc)=>{
                                    return <Popover placement="left" overlayClassName='templateGroupBtns' content={this.renderButtons(doc)} trigger={'hover'}>...</Popover>
                                    // return <button onClick={()=>this.setShowModal(true,doc.id)}>...</button>
                                }
                             }
                            ];
        return <Table dataSource={templateGroupsList} pagination={pagination} columns={tableConfig} className={'consentTemplateGroupTable'}/>

    }
    render() {
        const { renderList,renderModal } = this;
        return <div>{renderList()}{renderModal()}</div>
    }
}

export default ConsentTemplateListComponent