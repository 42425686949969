import Detail from './Detail'
import {React} from 'ihcomponent'

export default (props)=>{
  return (
    <div className="vsm-main-page">
      <Detail />
    </div>
  )
}
