import React, {Component} from 'react';
import {Form, Row, Select, Col, Button, Popover, Input} from 'antd';
import CreateUserPopup from './CreateUserPopup';
import {CLINIC_MANGER_STR, AREA_MANAGER_STR, HC_STR} from '../../constant/roleStrMap';
import { ROLE_NAME,ROLE_DEFAULT_CAPACITY } from '../../../../lib/constants';
import I18N from 'modulesAll/I18N';
import {graphql} from "react-apollo";
import helper from "../../helper";
import Client from 'libModule/gqlClient';
import userList from 'graphqlModule/miniUserList';
import CapacityIconComponent from "./CapacityIconComponent";
import MentorRDIconComponent from "./MentorRDIconComponent";

const { Option } = Select;
const organizationMap =  {
    'MA': (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Clinic Medical Assistant'),
    'Provider': (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Doctor'),
    [CLINIC_MANGER_STR]: (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Admin'),
    [AREA_MANAGER_STR]: (a) => !_.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Admin'),
    'CA': (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Medical Assistant'),
    'RD': (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Nurse'),
    'Partner Biller': (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),'Partner Biller'),
    [HC_STR]: (a) => _.get(a, 'leaf')&&_.includes(_.map(a.roles,(role)=>role.name),ROLE_NAME.HC),
}
const siteRoles = ['RD', HC_STR, 'CA','Provider', 'MA', CLINIC_MANGER_STR, 'Partner Biller'];

class SecondPage extends Component {
    constructor(props) {
        super(props);
        const selectedRoles = {};
        _.forEach(this.props.list, l => {
          selectedRoles[l.role] = true;
        });
        this.state = {
            list: this.props.list,
            current : true,
            role: [
                {"name":"RD", "disable": !!selectedRoles.RD },
                {"name":HC_STR, 'disable':  !!selectedRoles[HC_STR] },
                {"name":"CA", "disable": !!selectedRoles.CA },
                {"name":CLINIC_MANGER_STR, "disable": !!selectedRoles[CLINIC_MANGER_STR] },
                {"name":AREA_MANAGER_STR, "disable": !!selectedRoles[AREA_MANAGER_STR] },
                {"name":"Provider", "disable": !!selectedRoles.Provider },
                {"name":"MA", "disable": !!selectedRoles.MA },
                {"name":'Partner Biller', 'disable':  !!selectedRoles['Partner Biller'] },
            ],
            currRole:this.props.currRole,
            popUpVisible:false,
            RDList:[]
        }
    }
    componentDidMount() {
            Client.query({
                query: userList,
                variables:(()=>{
                    const orgRoles = [];
                    _.get(this.props, 'organization', []).forEach(o=>orgRoles.push(...o.roles));
                    const selectedRoles = ['Nurse'];
                    const roles = helper.getRefIDWithRoleCategory(selectedRoles, orgRoles);
                    return{
                            count: 500,
                            filters: { roles }
                        }
                })(),
                fetchPolicy: 'cache-first'
            }).then(({data})=>{
                let RDList = _.get(data,'userList.data',[]);
                if(RDList.length) {
                    this.setState({
                        RDList
                    })
                }
            })
        // graphql(userList, {
        // options: props => {
        //     const orgRoles = _.get(props, 'organization.roles', []);
        //     const selectedRoles = ['Doctor', 'Clinic Medical Assistant', 'Nurse', 'Medical Assistant', ROLE_NAME.HC];
        //     const roles = helper.getRefIDWithRoleCategory(selectedRoles, orgRoles);
        //     return {
        //         variables: {
        //             count: 500,
        //             filters: { roles }
        //         }
        //     };
        // },
        // props: ({ ownProps, data }) => {
        //     const userListData = _.get(data, 'userList.data', []);
        //     var userList = [];
        //     userListData.map(u => {
        //         // should filter the correct role
        //         const user = {...u};
        //         const allRoles = _.get(u, 'allRoles', []);
        //         const role = _.get(_.filter(allRoles, r => _.get(r, 'organization.id') === _.get(ownProps, 'organization.id')), '0');
        //         _.set(user, 'role', role);
        //         if(_.includes( _.get(keyMap, `${ownProps.selectedTab}.roles`, []) ,_.get(role, 'name'))){
        //             userList.push(new UserClass(user));
        //         }
        //     })
        //     return {
        //         loadingFetchUserList: data.loading,
        //         userList,
        //         userListPageInfo: _.get(data, 'userList.pageInfo', { total: 0, lastPage: 0 }),
        //         contentListLength: _.get(data, 'userList.data.length', 0),
        //         refetchList: data.refetch
        //     }
        // }
    }

    addNew = (curr) => {
        const {getFieldsValue, setFieldsValue } = this.props.form;
        const { role, site, hasCredential,capacity,mentorRD } = getFieldsValue();
        if (this.state.current) {
          const newObject = {
            role,
            site,
            capacity,
            mentorRD
          };


          if(role === HC_STR && !_.isNil(hasCredential)) {
            newObject.hasCredential = hasCredential;
          }

          this.state.list.push(newObject);
        }
        this.setState({
            current:curr,
            currRole: null,
            // role:arr
        });

        const resetValues = { role: null, site: null,capacity:null,mentorRD:null };

        setFieldsValue(resetValues);
    }
    
    delete = (index) => {
        const curr = this.state.list[index].role;
        const newRoleList = [...this.state.role];
        const roleIdx = _.findIndex(newRoleList, { name: curr })
        newRoleList.splice(roleIdx, 1, {"name":curr,"disable":false});
        this.setState ({
            list:this.state.list.filter((key, i)=> i !== index),
            role:newRoleList
        })
    }

    onChange = (idx, key, value) => {
      const newList = [...this.state.list];
      newList[idx][key] = value;
      if(key === 'role' && value !== HC_STR) {
        newList[idx] = _.omit(newList[idx], 'hasCredential');
      }
      this.setState({ list: newList });
    }

    renderList = () => {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const {list, role,currRole} = this.state;
        return _.map(list, (k, i) => {
          const item =list[i];
          const handleChange = (k, v) => this.onChange(i, k, v);
            return (
                <Form key={i} className={'secondWrapper'}>
                    <Row>
                        <Col span={15}>
                            <Form.Item label="Role" colon={false}>
                              {
                                getFieldDecorator(`${i}.role`, {
                                  initialValue: item.role
                                })(
                                  <Select onChange={_.partial(handleChange, 'role')}>
                                      {_.map(role, (key)=><Option key={key.name} value={key.name} disabled={this.isRoleSelected(item.role, key.name)}>{key.name}</Option>)}
                                  </Select>
                                )
                              }
                            </Form.Item>
                        </Col>
                        {   ROLE_DEFAULT_CAPACITY[item.role] &&
                            <Col span={15}>
                                <Form.Item label="Capacity" colon={false}>
                                    <div style={{display: 'flex'}}>
                                        {
                                            getFieldDecorator(`${i}.capacity`, {
                                                initialValue: item.capacity
                                            })(
                                                <Input/>
                                            )
                                        }
                                        <CapacityIconComponent/>
                                    </div>
                                </Form.Item>
                            </Col>
                        }
                        {list.length ===1 && getFieldValue('site') ||list.length > 1? 
                        <Button className={'removeBtn'} onClick={()=>this.delete(i)}>remove</Button>:null}
                    </Row>
                    {
                      item.role === HC_STR &&
                      <Row>
                        <Col span={15}>
                          <Form.Item label={I18N.get('admin.hcCredential.label')}>
                            {
                              getFieldDecorator(`${i}.hasCredential`, {
                                initialValue: item.hasCredential,
                                rules: [{ required: true, message: I18N.get('admin.hcCredential.errorMessage') }]
                              })(
                                <Select onChange={_.partial(handleChange, 'hasCredential')}>
                                  {
                                    _.map(I18N.get('admin.hcCredential.options'), (v, k)=><Option key={k} value={v}>
                                      {_.capitalize(k)}
                                    </Option>)
                                  }
                                </Select>
                              )
                            }
                          </Form.Item>
                        </Col>
                        {
                          item.hasCredential === I18N.get('admin.hcCredential.options').NO &&
                          <Col span={15} style={{ fontSize: 12, lineHeight: '16.24px' }}>
                            {I18N.get('admin.hcCredential.note')}
                          </Col>
                        }
                        {   item.hasCredential === I18N.get('admin.hcCredential.options').NO &&
                          <Col span={15} style={{display: 'flex'}}>
                              <Form.Item label={I18N.get('admin.mentorRD.label')} style={{width: '100%'}}>
                                  {
                                      getFieldDecorator(`${i}.mentorRD`, {
                                          initialValue: item.mentorRD,
                                          rules: [{
                                              required: getFieldValue('hasCredential') == 0,
                                              message: I18N.get('admin.hcCredential.errorMessage')
                                          }]
                                      })(
                                          <Select onChange={v => {
                                              handleChange('mentorRD', v);
                                          }}>
                                              {
                                                  _.map(this.state.RDList, (v, k) => {
                                                      let value = v.id;
                                                      let hasHealthCoach = _.get(v, 'profile.healthCoach.id');
                                                      let hasHCMsg = hasHealthCoach ? ' (Matched with another HC) ' : ''
                                                      let fullName = _.get(v, 'profile.fullName', 'Unknown');
                                                      return <Option key={value} value={value}
                                                                     disabled={hasHealthCoach}>
                                                          {_.capitalize(fullName) + hasHCMsg}
                                                      </Option>
                                                  })
                                              }
                                          </Select>
                                      )
                                  }
                              </Form.Item>
                              <MentorRDIconComponent/>
                          </Col>
                        }
                      </Row>
                    }
                    <Row>
                    {item.role === AREA_MANAGER_STR?
                    <Col span={15}>
                        <Form.Item label="Area(s)">
                          {
                             getFieldDecorator(`${i}.site`, {
                              initialValue: item.site,
                              rules: [{required: true,message: 'Please select the area'}]
                            })(
                              <Select mode="multiple" onChange={_.partial(handleChange, 'site')}>
                                  {_.map(_.filter(this.props.organization, (it)=>organizationMap[item.role](it)), (key) => <Option key={key.id} value={key.id}>{key.name}</Option>)}
                              </Select>
                            )
                          }
                        </Form.Item>
                    </Col> :
                    <Col span={15}>
                        <Form.Item  label="Site(s)/Clinic(s)">
                          {
                             getFieldDecorator(`${i}.site`, {
                              initialValue: item.site,
                              rules: [{required: true,message: 'Please select the site/clinic'}]
                            })(
                              <Select mode="multiple" onChange={_.partial(handleChange, 'site')}>
                                  {_.map(_.filter(this.props.organization, (it)=>organizationMap[item.role](it)), (key) => key.internalName?<Option key={key.id} value={key.id}>{key.internalName}</Option>:null)}
                              </Select>
                            )
                          }
                        </Form.Item>
                    </Col>}
                </Row>
                </Form>
            );
            
        })
    }

    createHandler = () => {
        this.addNew(false);
        this.setState({popUpVisible:false})
        this.props.createHandler(this.state.list);
        this.props.closeModal();
    }
    checkValidHandler = () => {
        const {validateFieldsAndScroll} = this.props.form;
        validateFieldsAndScroll((err) => {
            if (!err) {
                this.setState({popUpVisible: true})
            }
        });
    }
    backHandler = () => {
        const {getFieldValue} = this.props.form;
        let temp = "";
        if (!getFieldValue('site')) {
            temp = getFieldValue('role');
        }
        else if (this.state.current) {
            this.addNew(true);
        }
        this.props.handlerBack(this.state.list, temp);  
    }

    isRoleSelected = (selfRole, role) => {
      const { list, currRole } = this.state;
      let selectedRoles = _.map(list, 'role');
      if(currRole) {
        selectedRoles.push(this.state.currRole);
      }
      // don't disable the role for the select has that role value
      selectedRoles = _.filter(selectedRoles, sr => sr !== selfRole);
      return _.includes(selectedRoles, role);
    };

    setCurrRole = currRole => this.setState({ currRole });

    removeRole = ()=>{
      this.setState({current: false, currRole: null});
    }
    render() {
        const { currRole } = this.state;
        const { setNPI } = this.props;
        const { getFieldDecorator, getFieldValue, getFieldsValue} = this.props.form;
        return (
            <div>
                {this.renderList()}
                {this.state.current?
                <Form className={'secondWrapper'}>
                    <Row>
                        <Col span={15}>
                            <Form.Item label="Role">
                            {getFieldDecorator('role', {initialValue:this.state.currRole,rules: [{required: true,message: 'Please select the role'}]})(<Select onChange={this.setCurrRole}>
                                {_.map(this.state.role, (key)=><Option key={key.name} value={key.name} disabled={this.isRoleSelected(this.state.currRole, key.name)}>{key.name}</Option>)}
                                </Select>)}
                            </Form.Item>
                        </Col>
                        {
                            ROLE_DEFAULT_CAPACITY[currRole] &&
                            <Col span={15}>
                                <Form.Item label="Capacity" colon={false} key={currRole}>
                                  <div style={{ display: 'flex' }}>
                                    {
                                        getFieldDecorator(`capacity`, {
                                            initialValue: ROLE_DEFAULT_CAPACITY[currRole],
                                        })(
                                            <Input />
                                        )
                                    }
                                    <CapacityIconComponent />
                                  </div>
                                </Form.Item>
                            </Col>
                        }
                        {this.state.list.length > 0 ?<Button className={'removeBtn'} onClick={()=>this.removeRole()}>remove</Button>:null}
                    </Row>

                    {
                      getFieldValue('role') === HC_STR &&
                      <Row>
                        <Col span={15}>
                          <Form.Item label={I18N.get('admin.hcCredential.label')}>
                            {
                              getFieldDecorator('hasCredential', {
                                initialValue: null,
                                rules: [{ required: true, message: I18N.get('admin.hcCredential.errorMessage') }]
                              })(
                                <Select>
                                  {
                                    _.map(I18N.get('admin.hcCredential.options'), (v, k)=><Option key={k} value={v}>
                                      {_.capitalize(k)}
                                    </Option>)
                                  }
                                </Select>
                              )
                            }
                          </Form.Item>
                        </Col>
                          { getFieldValue('hasCredential')==0 &&
                              <Col span={15} style={{display:'flex'}}>
                                  <Form.Item label={I18N.get('admin.mentorRD.label')} style={{ width:'100%' }}>
                                      {
                                          getFieldDecorator('mentorRD', {
                                              initialValue: null,
                                              rules: [{ required: getFieldValue('hasCredential') == I18N.get('admin.hcCredential.options').NO, 
                                              message: I18N.get('admin.hcCredential.errorMessage') }]
                                          })(
                                              <Select>
                                                  {
                                                      _.map(this.state.RDList, (v, k)=> {
                                                          let value = v.id;
                                                          let hasHealthCoach = _.get(v,'profile.healthCoach.id');
                                                          let hasHCMsg = hasHealthCoach ? ' (Matched with another HC) ' :''
                                                          let fullName = _.get(v,'profile.fullName','Unknown');
                                                          return <Option key={value} value={value} disabled={hasHealthCoach}>
                                                              {_.capitalize(fullName)+ hasHCMsg }
                                                          </Option>
                                                      })
                                                  }
                                              </Select>
                                          )
                                      }
                                  </Form.Item>
                                  <MentorRDIconComponent/>
                              </Col>
                          }
                        {
                          getFieldValue('hasCredential') === I18N.get('admin.hcCredential.options').NO &&
                          <Col span={15} style={{ fontSize: 12, lineHeight: '16.24px' }}>
                            {I18N.get('admin.hcCredential.note')}
                          </Col>
                        }
                      </Row>
                    }
                    {getFieldValue('role') === AREA_MANAGER_STR?
                    <Row>
                        <Col span={15}>
                            <Form.Item label="Area(s)">
                            {getFieldDecorator('site',{rules: [{required: true,message: 'Please select the area'}]})(
                                        <Select mode="multiple" 
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {_.map(_.filter(this.props.organization, (a)=>organizationMap[AREA_MANAGER_STR](a)), (key, i) => <Option key={key.id} value={key.id}>{key.name}</Option>)}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row> : null} 
                    {_.includes(siteRoles, getFieldValue('role') )?
                    <Row>
                        <Col span={15}>
                            <Form.Item label="Site(s)/Clinic(s)">
                            {getFieldDecorator('site', {rules: [{required: true,message: 'Please select the site/clinic'}]})(
                                        <Select mode="multiple"
                                        filterOption={(input, option) =>option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {_.map(_.filter(this.props.organization, (a)=>organizationMap[getFieldValue('role')](a)), (key, i) => key.internalName?<Option key={key.id} value={key.id}>{key.internalName}</Option>:null)}
                                        </Select>
                                    )}
                            </Form.Item>
                            {
                                currRole==='Provider' &&
                                <React.Fragment>
                                <Form.Item  label="NPI">
                                    {
                                        getFieldDecorator(`NPI`)(
                                            <Input onChange={(e)=>setNPI(e.target.value)}/>
                                        )
                                    }
                                </Form.Item>
                                    <a href={'https://npiregistry.cms.hhs.gov/'} target={'_blank'}>NPPES NPI Registry</a>
                                </React.Fragment>
                            }
                        </Col>
                    </Row>: null}    
                </Form>: null}
                <Row>
                    {getFieldValue('site')||!this.state.current?<Button className={'addOrgBtn'} onClick={() => this.addNew(true)}>Add another role</Button> : null}
                </Row>
                <Row>
                    <Col span={18}><Button className={'secondCreateBtn'} onClick={this.backHandler}>Back</Button></Col>
                    <Col span={5} offset={1}>
                        <Popover overlayClassName='create-user-confirmation-popover' 
                        content={<CreateUserPopup onCancel={()=>{this.setState({popUpVisible:false})}} onCreate={this.createHandler} email={this.props.email}/>} 
                        trigger="click" 
                        visible={this.state.popUpVisible} 
                        onVisibleChange={this.checkValidHandler}>
                            <Button type="primary" className={'secondCreateBtn'}>Create</Button>
                        </Popover>
                    </Col>
                </Row>
            </div>
            
        )
    }
}
SecondPage = Form.create()(SecondPage);

export default SecondPage;