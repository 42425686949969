import gql from 'graphql-tag';

export const boostedOrganizations = gql`
  query getSelectedClinic{
    getSelectedClinic{
      boostedOrganizations{
          organizationId
          name
      }           
    }
  }
`;

export const clinicCandidates = gql`
  query getClinicCandidates{
    getClinicCandidates{
        name
        internalName
        organizationId
    }
  }
`;
