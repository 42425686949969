import gql from 'graphql-tag'
export default gql`
  query ICDCodeList(
    $search: ICDCodeSearchInput,
    $page: Int,
    $count: Int,
    $sort: ICDCodeListSort
  ) {
      ICDCodeList(
      page: $page,
      count: $count,
      search: $search,
      sort: $sort
    ) {
      data {
        id
        code
        title
      }
      pageInfo {
        total
        lastPage
      }
    }
  }`
