import _ from 'lodash';
import types from './TableConstants';
import { initState } from './TableReducers';
import Store from '../reduxStore';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const auditLog = (tableName, state) => {
    let action;
    let details = {
      'Search Text' : state.globalTables[tableName].search
    }
    switch(tableName){
      case 'admin_provider_list':
            action = I18N.get('auditLog.admin.tables.admin_provider_list.search');
            break;
      case 'care_team_list':
            action = I18N.get('auditLog.admin.tables.care_team_list.search');
            break;
      case 'admin_program_list':
            action = I18N.get('auditLog.admin.tables.admin_program_list.search');
            break;
      case 'admin_assessment_list':
            action = I18N.get('auditLog.admin.tables.admin_assessment_list.search');
            break;
      case 'admin_survey_list':
            action = I18N.get('auditLog.admin.tables.admin_survey_list.search');
            break;
      case 'admin_careteam_doctor_list':
            action = I18N.get('auditLog.admin.tables.admin_careteam_doctor_list.search');
            break;
      case 'admin_careteam_manager_list':
            action = I18N.get('auditLog.admin.tables.admin_careteam_manager_list.search');
            break;
      case 'enrolled_patient_list':
            action = I18N.get('auditLog.provider.tables.enrolled_patientList.search');
            break;
      case 'general_patient_list':
            action = I18N.get('auditLog.provider.tables.general_patientList.search');
            break;
      case 'nurse_assessment_list':
            action = I18N.get('auditLog.provider.tables.nurse_assessment_list.search');
            break;
      case 'nurse_survey_list':
            action = I18N.get('auditLog.provider.tables.nurse_survey_list.search');
            break;
      case 'provider_mytasks_list':
            action = I18N.get('auditLog.provider.tables.provider_mytasks_list.search');
            break;
      case 'provider_programme_list':
            action = I18N.get('auditLog.provider.tables.provider_programme_list.search');
            break;
      default:
            action = undefined;
            details = undefined;
   }
   if(action)
      createAuditLog({action, details})
}

const Actions = {
  resetTableProps:(tableName, setting)=>{
    Store.dispatch({
      type: types.RESET_TABLE_PROPS,
      tableName,
      routing: Store.getState().routing,
      setting
    });
  },
  onTableChange: (tableName, setting, page, filter, sort) => {
    Store.dispatch({
      type: types.ON_TABLE_CHANGE,
      tableName,
      routing: Store.getState().routing,
      setting,
      page,
      filter,
      sort,
    });
  },
  onTableSearch: (tableName, setting, search) => {
    Store.dispatch({
      type: types.ON_TABLE_SEARCH,
      tableName,
      routing: Store.getState().routing,
      setting,
      search,
    });
  },
  onSearchEnter: (tableName, setting) => {
    Store.dispatch({
      type: types.ON_TABLE_SEARCH_ENTER,
      tableName,
      routing: Store.getState().routing,
      setting
    });
    auditLog(tableName,Store.getState());
  },
  filterByDateRange: (tableName, setting, dateRangeToFilter) => {
    Store.dispatch({
      type: types.FILTER_TABLE_BY_DATE_RANGE,
      tableName,
      routing: Store.getState().routing,
      setting,
      dateRangeToFilter
    });
    auditLog(tableName,Store.getState());
  },
  showTasksByStatus: (tableName, setting, table_select) => {
    Store.dispatch({
      type: types.SHOW_TASKS_BY_STATUS,
      tableName,
      routing: Store.getState().routing,
      setting,
      table_select
    });
    auditLog(tableName,Store.getState());
  },
  onSelectTableRow: (tableName, setting, selectedRowKeys) => {
    Store.dispatch({
      type: types.ON_SELECT_TABLE_ROW,
      tableName,
      routing: Store.getState().routing,
      setting,
      selectedRowKeys,
    });
  },
  onLoadParams: (tableName, setting, params, isLoadFromUrl) => {
    Store.dispatch({
      type: types.ON_LOAD_PARAMS,
      tableName,
      routing: Store.getState().routing,
      setting,
      params,
      isLoadFromUrl,
    });
  },
  onSetParams: (tableName, setting, paramObj) => {
    Store.dispatch({
      type: types.ON_SET_PARAMS,
      tableName,
      routing: Store.getState().routing,
      setting,
      paramObj
    })
  },
};

const defaultSetting = {
  shouldUpdateHistory: false,
  reducerNext: (state, action) => state,
  sortFieldMap: {},
  initState: { sort: {}, filter: {}, page: {}, search: '', prevSearch: '', selectedRowKeys: [] },
};

export default (tableName, setting = {}) => {
  const mergedSetting = _.defaultsDeep(setting, defaultSetting);

  // redux state is not loaded at this time,
  // so mutate the initState directly
  if (!initState[tableName]) {
    // if redux has not loaded, mutate the initState directly
    initState[tableName] = mergedSetting.initState;
  }

  // TODO: add _.debounce to onTableSearch
  return _.mapValues(Actions, value => (...args) => value(tableName, mergedSetting, ...args));
};
