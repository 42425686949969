import React from 'react';
import SearchPatientComponent from '../component/SearchComponent';
import RemoteEnrollmentListContainer from "./RemoteEnrollmentListContainer";
import {Tabs, Row, Col, Button, Modal,Spin,Popover} from 'antd';
import remoteStatus from "../constant/remoteStatus";
import ReferAndEnrollCheckboxComponent from '../component/ReferAndEnrollCheckboxComponent';
import {compose, graphql} from "react-apollo";
import {connect} from "react-redux";
import query from "../query";
import callActions from "../../call/actions";
import helpers from "../helper";
import SelectClinicComponent from "../../workStation/component/SelectClinicComponent";

const { TabPane } = Tabs;
const tabs = Object.keys(remoteStatus);
const RemoteEnrollmentContainer = class extends React.Component{
    constructor(props) {
        super(props);
        let tabSelected = _.get(props,'defaultTab');
        let rmMember = sessionStorage.getItem('rmMember');
        rmMember  = rmMember ? JSON.parse(rmMember) : null;
        this.state = {
            tabSelected,
            dataCount:0,
            showCreateVisitModal: false,
            refetchEnrollment: null,
            showModal:false,
            callPhoneNumber:null,
            callUserName: null,
            callStatus: null,
            referredByMAMD: false,
            enrolledByMAMD: false,
            memberId:rmMember,
            showCoveredClinicsModal: false
        }
    }

    setDataCount = (dataCount)=>{
        this.setState({dataCount});
    }

    setReferralFilter = (type)=>{
        const value = _.get(this,`state.${type}`);
        this.setState({
            [type]: !value
        })
    }

    renderTotalCount = (remoteEnrollmentSummary={})=>{
        let total = 0;
        for(const [key,value] of Object.entries(remoteEnrollmentSummary)) {
            if(!isNaN(value)) total+=value;
        }
        return total;
    }

    extraContent = ()=>{
        const { renderTotalCount,props,state,setReferralFilter } = this;
        const { tabSelected,enrolledByMAMD,referredByMAMD } = state;
        const { remoteEnrollmentSummary } = props;
        return <React.Fragment>
                    <ReferAndEnrollCheckboxComponent enrolledByMAMD={enrolledByMAMD} remoteEnrollmentSummary={remoteEnrollmentSummary} referredByMAMD={referredByMAMD} tabSelected={tabSelected} setReferralFilter={setReferralFilter}/>
                    {/*{`Total Patients in Remote Enrollment: ${renderTotalCount(remoteEnrollmentSummary)}`}*/}
               </React.Fragment>;
    }

    renderTab = ()=>{
        const { renderList,props,renderTotalCount,extraContent } = this;
        const { remoteEnrollmentSummary } = props;
        const { tabSelected } = this.state;
        const enrolledByMAMDCount  = _.get(remoteEnrollmentSummary,'enrolledByMAMD');
        const referredByMAMDCount  = _.get(remoteEnrollmentSummary,'referredByMAMD');
        const showRedDot = (tab)=>{
            const hasReferred = tab==='REFERRED' && referredByMAMDCount>0;
            const hasEnrolled = tab==='CONFIRMED'&& enrolledByMAMDCount>0
            return hasReferred || hasEnrolled ? 'reddot' :'';
        }
        return <Tabs activeKey={tabSelected} className='remoteEnrollTabs'
                     tabBarExtraContent={extraContent()}
                     onChange={(k)=>this.setState({tabSelected:k})}>
                    {_.map(tabs,(t)=><TabPane key={t} tab={<span className={showRedDot(t)}>{`${remoteStatus[t]} (${_.get(remoteEnrollmentSummary,remoteStatus[t].toLowerCase(),0)})`}</span>}>{renderList(t)}</TabPane>)}
               </Tabs>
    }
    callPhone = (phoneNumber, username) => {
        /**
         * callArgs
         * {
         *      phoneNumber
         *      username
         *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
         *      duration   min
         *      date
         *      showChat
         * }
         */
        this.setState({ callPhoneNumber: phoneNumber, callUserName: username, callStatus: 0 });
    }

    setCallPhoneInfo = (phoneNumber,username)=>{
        this.setState({ callPhoneNumber: phoneNumber, callUserName: username, callStatus: 0 });
    }

    // clinicSel = ()=>{
    //     const { organizations } = this.state;
    //     const allRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
    //     const options = _.map(allRoles, role => {
    //         const { id, name, internalName } = _.get(role, 'organization');
    //         return { value: id, label:internalName||name }
    //     });
    //     return <Checkbox.Group options={options} onChange={ this.setOrganizations } value={organizations} className={'selectOrg'}/>
    //
    // }

    setShowCoveredClinicsModal=(showCoveredClinicsModal)=>{
        this.setState({
            showCoveredClinicsModal
        });
    }
    clinicName = (selectedClinic)=>{
       return <div style={{ maxHeight:'460px',overflow:'scroll' }}>{selectedClinic.map(({name}) => <Row style={{display:'flex',flexDirection:'row'}}>
            <Col style={{width:'fit-content',lineHeight:'2'}}>{name}</Col>
        </Row>)}</div>
    }
    rowWrapper = ()=>{
        const { remoteEnrollmentSummary = {},setCrossClinic,setSelectedClinic,selectedClinic=[],refetchSelectedOrgs,refetchRemoteEnrollmentSummary,clinicCandidates } = this.props;
        const { showCoveredClinicsModal } = this.state;
        let total = 0;
        for( const [k,v] of Object.entries(remoteEnrollmentSummary)) {
            total+=(_.isNumber(v)&&Object.values(remoteStatus).map(k=>k.toLowerCase()).includes(k) ? v :0)
        }

        const onChange = (crossClinic)=>{
            sessionStorage.setItem('rmCrossClinic',crossClinic);
            this.setShowCoveredClinicsModal(crossClinic)
        }

        return<Row className='remoteEnrollmentRowWrapper'>
                <Col span={24} className={'summaryAndSwitch'}>
                    <div><h4>Remote Enrollment ({total}) </h4></div>
                    {/*<div>*/}
                    {/*    <Switch defaultChecked onChange={(e)=>onChange(e)}/><span>Show cross-clinic data</span>*/}
                    {/*</div>*/}
                    <Popover content={this.clinicName(selectedClinic)} trigger={'hover'} placement={'rightBottom'}>
                        <div onClick={()=>this.setShowCoveredClinicsModal(true)} className={'fromClinicTitle'}>from {selectedClinic.length} <span>clinics</span></div>
                    </Popover>
                    <div>
                        <Modal visible={showCoveredClinicsModal}
                                width='fit-content'
                                maskClosable={false}
                                onCancel={()=>this.setShowCoveredClinicsModal(false)}
                                footer={null}
                                bodyStyle={{ padding: '48px 63px 54px 57px'}}
                                destroyOnClose
                        >
                            <di style={{ marginLeft: 15 }}>
                             <SelectClinicComponent setShowCoveredClinicsModal={this.setShowCoveredClinicsModal} refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                                                    clinicCandidates={clinicCandidates} selectedClinic={selectedClinic} refetchSelectedOrgs={refetchSelectedOrgs} target={'REMOTE_ENROLLMENT'}/>
                            </di>
                    </Modal>
                    </div>

                </Col>
                <Col span={16}>
                    { this.renderSearchPatient() }
                </Col>
                {/*<Col span={8}>*/}
                {/*    { this.renderCreateVisitButton() }*/}
                {/*</Col>*/}
              </Row>
    }

    setShowCreateVisitModal=(showCreateVisitModal)=>{
        this.setState({
            showCreateVisitModal
        });
    }

    setShowModal = (showModal,memberId,c)=>{
        const { confirm } = Modal;
        const { showCreateComponent } = this.props;
        const organizationId  = _.get(c,'props.children.props.organizationId');
        const name = _.get(c,'props.children.props.name');
        const cb = ()=>{
            let fn = (orgSwitched)=> {
                if (orgSwitched) {
                  helpers.simulatePageReload();
                }else{
                    this.setState({
                        showModal,
                        memberId
                    });
                }
            }
            helpers.saveModalMember(JSON.stringify(memberId));
            helpers.onOrganizationChange({ name ,organizationId },fn);
        }
        if(showCreateComponent) {
            confirm({
                title: 'You have an unsaved sticky note. Are you sure you want to leave?',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: () =>cb()
            })
        }else{
            cb();
        }
        // const { confirm } = Modal;
        // console.log(showModal,memberId);
        // const { showCreateComponent } = this.props;
        // const cb = ()=>{
        //     this.setState({
        //         showModal,
        //         memberId
        //     })
        // }
        // if(showCreateComponent) {
        //     confirm({
        //         title: 'You have an unsaved sticky note. Are you sure you want to leave?',
        //         okText: 'Yes',
        //         okType: 'danger',
        //         cancelText: 'No',
        //         onOk: () => cb()
        //     })
        // }else{
        //     cb();
        // }
        // // this.setState({
        // //     showModal,
        // //     memberId
        // // })
    }

    renderCreateVisitButton = ()=>{
        const onClick = ()=>this.setShowCreateVisitModal(true);
        return <Button onClick={onClick} className={'remoteEnrollmentCreateVisit'} type={"primary"}>Create Visit</Button>
    }

    renderSearchPatient=()=>{
        const { setShowModal,callPhone,props } = this;
        const { crossClinic } = props;
        return  <SearchPatientComponent setShowModal={setShowModal} {...props} crossClinic={crossClinic}/>
    }

    renderList = (currentTab)=>{
        const { setDataCount,state,props,setShowCreateVisitModal,setShowModal,callPhone,setCallPhoneInfo } = this;
        const { tabSelected,showCreateVisitModal,showModal,memberId,callPhoneNumber,referredByMAMD,enrolledByMAMD } = state;
        const { tableProps,refetchRemoteEnrollmentSummary,setContact,contact,selectedClinic } = props;
        return tabSelected===currentTab && <RemoteEnrollmentListContainer tabSelected={currentTab}
                                                                          referredByMAMD={referredByMAMD}
                                                                          enrolledByMAMD={enrolledByMAMD}
                                                                          tableProps={ tableProps }
                                                                          contact={contact}
                                                                          setDataCount={setDataCount}
                                                                          setContact={setContact}
                                                                          showModal={showModal}
                                                                          callPhone={callPhone}
                                                                          setCallPhoneInfo={setCallPhoneInfo}
                                                                          callPhoneNumber={callPhoneNumber}
                                                                          memberId={memberId}
                                                                          refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                                                                          setShowModal={setShowModal}
                                                                          setShowCreateVisitModal={setShowCreateVisitModal}
                                                                          showCreateVisitModal={showCreateVisitModal}
                                                                          selectedClinic={selectedClinic}
                                           />
    }

    render() {
        let { loadingSelectedClinic } = this.props;
        if(loadingSelectedClinic) return <Spin/>;
        return <div style={{backgroundColor:'white'}}>
                <div style={{ padding: 20 }}>
                    { this.rowWrapper() }
                </div>
                <div>
                    { this.renderTab() }
                </div>
              </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    const keys = Object.keys(remoteStatus);
    const tableProps = {};
    _.forEach(keys,k=>{
        _.set(tableProps,k,state.globalTables[k]);
    })
    return {
       tableProps,
       contact: state.AWSConnect.contact,
       showCreateComponent:state.Stickie.showCreateComponent

    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setContact: flag => dispatch(callActions.contact(flag)),
    }
};
const remoteEnrollmentSummary = graphql(query.remoteEnrollmentSummary,{
    options:(props)=>{
      const { crossClinic=true } = props;
      return {
          variables:{
              crossClinic
          },
          fetchPolicy:'network-only',
          notifyOnNetworkStatusChange: true
      }
    },
    props:({data})=>{
        const { loading,remoteEnrollmentSummary,refetch } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            remoteEnrollmentSummary,
            refetchRemoteEnrollmentSummary: refetch
        }

    }
});
const getClinicCandidates = graphql(query.clinicCandidatesQuery,{
    props:({data})=>{
        const { loading,getClinicCandidates,refetch } = data;
        return {
            clinicCandidates: getClinicCandidates || []
        }
    }
});

export default compose(remoteEnrollmentSummary,getClinicCandidates,connect(mapStateToProps, mapDispatchToProps))(RemoteEnrollmentContainer);
