import React from 'react';
import '../style/index.scss';
import { Checkbox,Radio,Button,Spin,Form ,Icon,Row,Col,message } from 'antd';
import { upsertPatientMntProgramMutation } from '../API/index';
import { upsertPatientMntProgramQuery,getPatientProgramQuery }from '../query/index';
import Client from 'libModule/gqlClient';
import { compose, graphql } from 'react-apollo';
import { MNTSTATUS } from '../constant/index';
import patientList from 'graphqlModule/patientList'
import { userMntCodes } from '../../graphql/user';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
let MNTReferralFormComponent = class extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			editMode: false
		};
	}

	renderName = (profile)=>{
		return <div className='referralPatientName'>{profile.profile.fullName}</div>
	}
	setmntConditions = (mntConditions)=>{
		this.setState({
			mntConditions,
			editMode:true
		})
	}
	// renderHealthConditions = (profile)=>{
	// 	const mntConditions = _.get(this,'state.mntConditions',_.get(this,'props.patientMntProgram.mntConditions',[]),);
	// 	const { patientMntCodes, loadingUserMntCodes } = this.props;
	// 	if (loadingUserMntCodes) {
	// 		return <Spin />;
	// 	}
	// 	return <div style={{ display:'flex',flexDirection:'column' }} className={'referralConditionsWrapper'}>
	// 			<span className={'header'}>The conditions that apply to MNT program.</span>
	// 			<Checkbox.Group options={patientMntCodes} defaultValue={mntConditions} value={mntConditions} onChange={this.setmntConditions} />
	// 		   </div>
	// }

	setDescription = (e)=>{
		const description = e.target.value;
		this.setState({
			description,
			editMode: true
		})
	}
	
	// referralDescription = (profile)=>{
	// 	const desc = ['The patient has tried multiple strategies for lifestyle & behavioral modification to manage his/her condition.','Patient is new to lifestyle change and behavioral modification, requires in-depth education'];
	// 	const description = _.get(this,'state.description',_.get(this,'props.patientMntProgram.description'));
	// 	return <div className={'referralRadioWrapper'}>
	// 				<span className={'header'}>Which of the following best describe this patient?</span>
	// 				<Radio.Group onChange={ this.setDescription } defaultValue={description} value={description}>
	// 					{_.map(desc,d=><Radio value={d}>{d}</Radio>)}
	// 				</Radio.Group>
	// 		   </div>
	// }

	onCancel = (patientaid)=>{
		const { refetchPatientMntProgram,form,patientMntProgram } = this.props;
		const variables = {};
		const status = _.get(patientMntProgram,'status');
		const needReset = status == MNTSTATUS.INELIGIBLE;
		console.log(needReset);
		variables.patientId = patientaid;
		variables.status = 'POTENTIAL';
		variables.description = needReset ? '' : patientMntProgram.description;
		variables.mntConditions = needReset ? [] : patientMntProgram.mntConditions;
		upsertPatientMntProgramMutation(variables).then(res=>{
			if(refetchPatientMntProgram) {
				refetchPatientMntProgram();
				form.resetFields();
				this.setState({
					editMode: false
				})
			}
		})
	}

	onSubmit = (status,patientId,cb)=>{
		const { form,memberId,refetch,refetchRemoteEnrollment,refetchPatientMntProgram,userList,index,setSelectedUser } = this.props;
		const { validateFields } = form;
		validateFields((error,res)=> {
			if(!error) {
				let variables = {};
				variables.patientId = patientId;
				variables.status = status;
				if( status == MNTSTATUS.REFERRED) {
					variables.referredBy = sessionStorage.getItem('currentUserId');
					variables.mntConditions = res.conditionsList;
					variables.description = res.description;
				}
				if( status == MNTSTATUS.INELIGIBLE) {
					variables.ineligibledBy = sessionStorage.getItem('currentUserId');
					variables.mntConditions = [];
					variables.description = '';
				}
				upsertPatientMntProgramMutation(variables)
						.then(res=>{
							form.resetFields();
							if(refetchPatientMntProgram) {
								refetchPatientMntProgram();
								this.setState({
									editMode: false
								})
							}
							if(cb) cb();
						})
			}
		})
	}
	onSave = (patientaid,cb)=>{
		const status = _.get(this,'props.patientMntProgram.status');
		this.onSubmit(status,patientaid,cb);
	}
	renderFooterBasedOnMNTSTATUS = (status,patientId)=>{
		const style = {
			container:{
				width: 754,
				height: 59,
				paddingLeft:50,
				background: '#F4F6F8',
				display: 'flex',
				alignItems: 'center'
			},
			strong:{
				fontWeight: 600,
				fontSize: 16,
				lineHeight: '20px',
				color: '#000000'
			},
			span:{
				color: '#000000',
				fontSize: 16,
				lineHeight: '20px',
			},
			CARDDiv:{
				height: 59,
				paddingLeft:50,
				display:'flex',
				alignItems: 'center',
				color: '#2D3238',
				fontSize: 16,
				lineHeight: '20px',
				background: '#F4F6F8'
			}
		};
		const isCAOrRDOrMA = checkIsInRole(['CA','RD','MA']);
		const referredByFullName = _.get(this,'props.patientMntProgram.referredBy.profile.fullName');
		const ineligibledByFullName = _.get(this,'props.patientMntProgram.ineligibledBy.profile.fullName');
		const cancelButton = <span onClick={()=>this.onCancel(patientId) } style={{ textDecoration:'underline',color:'#3177C9',marginLeft: 40,cursor:'pointer' }}>Cancel</span>
		// const saveButton = <span onClick={()=>this.onSave(patientId)} style={{ textDecoration:'underline',color:'#3177C9',marginLeft: 10,cursor:'pointer' }}>Save Change</span>

		if( status == MNTSTATUS.REFERRED ) {
			if(isCAOrRDOrMA) {
				return <div style={style.CARDDiv}>Referred by { referredByFullName }</div>
			}
			return <div style={ style.container }>
					<span style={ style.span }>Referred by { referredByFullName }</span>
					{cancelButton}
			</div>
		}
		if( status == MNTSTATUS.INELIGIBLE ) {
			if(isCAOrRDOrMA) {
				return <div style={style.CARDDiv}>{ ineligibledByFullName } marked this patient as ineligible.</div>
			}
			return <div style={ style.container }>
					<span style={ style.span }>{ ineligibledByFullName } marked this patient as ineligible.</span>
					{cancelButton}
			</div>
		}

	}
	
	changeSelectedUser = ({ index,userList,setSelectedUser })=>{
		setSelectedUser({ selectedUser:userList[index],index });
		const { fetchVar,setUserList } = this.props;
		if(index==userList.length-2) {
			let variables = _.pick(fetchVar,['count','filters','page','search','sort']);
			variables.page++;
			Client.query({
				query:patientList,
				variables
			}).then(res=>{
				const { data } = res;
				const { patientList } = data;
				const list = _.get(patientList,'data',[]);
				if(list.length) {
					const newPatientList = [...userList,...list.map(p=>p.user)];
					setUserList(newPatientList,variables);
				}
			})
		}
	}

	renderPreNext = ()=>{
		const { selectedUser,setSelectedUser,index,userList=[] } = this.props;
		const isReferralPTList = userList.length;
		if(isReferralPTList) {
			const {changeSelectedUser} = this;
			const uLen = userList.length;
			const isFirst = index == 0;
			const isLast = index == uLen - 1;
			const iconStyle = {
				fontSize: 20
			}
			const spanStyle = {
				fontSize: 13,
				color: '#6B7178'
			}
			return <div style={{ display:'flex',justifyContent:'flex-end' }}>
				{!isFirst && <div>
								<span style={spanStyle}>Previous Patient</span>
								<Icon type="left-circle" style={iconStyle} onClick={() => changeSelectedUser({index: index - 1, userList, setSelectedUser})}/>
							 </div>
                 }
				{!isLast && <div>
								<Icon type="right-circle" style={iconStyle} onClick={() => changeSelectedUser({index: index + 1, userList, setSelectedUser})}/>
								<span style={spanStyle}>Next Patient</span>
							</div>
                }
			</div>
		}
	}

	goToNext = ()=>{
		const { selectedUser,setSelectedUser,index,userList=[],form } = this.props;
		const isReferralPTList = userList.length;
		if(isReferralPTList) {
			const {changeSelectedUser} = this;
			const uLen = userList.length;
			const isFirst = index == 0;
			const isLast = index == uLen - 1;
			!isLast&&changeSelectedUser({index: index + 1, userList, setSelectedUser});
		}
	}

	renderFooter = (profile)=>{
		const { onSubmit,renderFooterBasedOnMNTSTATUS,props } = this;
		const values = props.form.getFieldsValue();
		const patientMntCodes = _.get(this,'props.patientMntCodes',[]);
		const isCAOrRDOrMA = checkIsInRole(['CA','RD','MA']);
		const hasValues = !!values.description && (!patientMntCodes.length || !!_.get(values,'conditionsList.length'));
		const status = _.get(this,'props.patientMntProgram.status');
		if(status&&status!='POTENTIAL') {
			return renderFooterBasedOnMNTSTATUS(status,profile.id);
		}
		return <div>
			<Button onClick={()=>onSubmit('REFERRED',profile.id,this.goToNext)} type={'primary'} disabled={!hasValues || isCAOrRDOrMA}>Refer to MNT</Button>
			<Button onClick={()=>onSubmit('INELIGIBLE',profile.id,this.goToNext)} style={{ float:'right' }} disabled={isCAOrRDOrMA}>Not eligible</Button>
		</div>
	}
	renderBody = (profile)=>{
		const { renderName,renderHealthConditions,referralDescription,renderFooter } = this;
		const { getFieldDecorator } = _.get(this,'props.form');
		const description = _.get(this, 'props.patientMntProgram.description');
		const status = _.get(this,'props.patientMntProgram.status');
		const mntConditions = _.get(this,'props.patientMntProgram.mntConditions',[]);
		const { loadingData, patientMntCodes, loadingUserMntCodes } = this.props;
		const options = _.map(patientMntCodes, (v) => ({ label: `${v.code}      ${v.title}`, value: v.code }));
		const desc = ['The patient has tried multiple strategies for lifestyle & behavioral modification to manage his/her condition.','Patient is new to lifestyle change and behavioral modification, requires in-depth education'];
		const isCAOrRDOrMA = checkIsInRole(['CA','RD','MA']);
		const isEligible = status == MNTSTATUS.INELIGIBLE;
		const disabled = isCAOrRDOrMA || isEligible;
		return <Form className='MNTReferralForm'>
					<div style={{ display:'flex',flexDirection:'column' }} className={'referralConditionsWrapper'}>
						<span className={'header'}>The conditions that apply to MNT program.</span>
						{loadingUserMntCodes
							?	<Spin />
							: <Form.Item>
							{ getFieldDecorator('conditionsList',{ initialValue:mntConditions })(<Checkbox.Group options={options} onChange={ ()=>this.setState({ editMode:true })} disabled={disabled}/>)}
						</Form.Item>}
					</div>
					<div className={'referralRadioWrapper'}>
						<span className={'header'}>Which of the following best describe this patient?</span>
						<Form.Item>
							{ getFieldDecorator('description',{ initialValue:description })
							(<Radio.Group disabled={disabled} onChange={ ()=>this.setState({ editMode:true })}>
								{_.map(desc,d=><Radio value={d}>{d}</Radio>)}
							</Radio.Group>)}
						</Form.Item>
					</div>
			   </Form>
	}

	renderSaveAndCancelBtn = (profile)=>{
		const onCancel = ()=>{
			this.setState({ editMode: false });
			this.props.form.resetFields();
		}
		const { editMode } = this.state;
		const status = _.get(this,'props.patientMntProgram.status');
		const isReferral = status === MNTSTATUS.REFERRED;
		const patientId = profile.id;
		const values = this.props.form.getFieldsValue();
		const patientMntCodes = _.get(this,'props.patientMntCodes',[]);
		const hasValues = !!values.description && (!patientMntCodes.length || !!_.get(values,'conditionsList.length'));
		const cb = ()=>message.success('Changes Saved!');
		return isReferral && editMode && <div style={{ width:'50%',marginBottom: 20 }}>
				 <Button onClick={()=>this.onSave(patientId,cb)} type={'primary'} disabled={!hasValues}>Save</Button>
				 <Button onClick={ onCancel } style={{ marginLeft:30 }}>Cancel</Button>
			   </div>
	}

	render() {
		const { renderName,renderBody,renderPreNext,renderSaveAndCancelBtn } = this;
		const profile = _.get(this,'props.selectedUser');
		return (
			<div style={{ margin: 30 }}>
				<Row>
					<Col span={16}>{ renderName(profile) }</Col>
					<Col span={8}>{ renderPreNext() }</Col>
				</Row>
				{ renderBody(profile) }
				{ renderSaveAndCancelBtn(profile) }
				{ this.renderFooter(profile) }
			</div>
		);
	}
}
MNTReferralFormComponent = Form.create({})(MNTReferralFormComponent);

const patientMntProgram  = graphql(getPatientProgramQuery,{
	options:(props)=>{
		const { selectedUser } = props;
		return {
			variables:{
				patientId:selectedUser.id
			},
			fetchPolicy:'network-only'
		}
	},
	props:({data} )=>{
		const { getPatientMntProgram,loading,refetch } = data;
		if(loading) {
			return {
				loadingData: loading
			}
		}
		return {
			patientMntProgram: getPatientMntProgram,
			refetchPatientMntProgram: refetch
		}
	}
})
const getUserMntCodes = graphql(userMntCodes, {
	options: (props) => {
		const { selectedUser } = props;
		return {
			variables:{
				id:selectedUser.id
			},
			fetchPolicy:'network-only'
		}
	},
	props:({ data }) => {
		const { user, loading } = data;
		if (loading) {
			return {
				loadingUserMntCodes: loading
			}
		}
		return {
			patientMntCodes: _.get(user, 'patientMntCodes', []),
		}
	}
})
export default compose(patientMntProgram, getUserMntCodes)(MNTReferralFormComponent);