// for svg color
const typeImgFilters = {
  'INIT': 'invert(77%) sepia(47%) saturate(635%) hue-rotate(129deg) brightness(83%) contrast(92%)',
  "FOLLOW_UP2":'invert(59%) sepia(26%) saturate(7089%) hue-rotate(195deg) brightness(80%) contrast(87%)',
  "FOLLOW_UP1":'invert(59%) sepia(26%) saturate(7089%) hue-rotate(195deg) brightness(80%) contrast(87%)',
  "FOLLOW_UP3":'invert(59%) sepia(26%) saturate(7089%) hue-rotate(195deg) brightness(80%) contrast(87%)',
  "FOLLOW_UP":'invert(59%) sepia(26%) saturate(7089%) hue-rotate(195deg) brightness(80%) contrast(87%)',
  'ANNUAL':'invert(76%) sepia(53%) saturate(654%) hue-rotate(335deg) brightness(99%) contrast(100%)',
  'ADDITIONAL':'invert(60%) sepia(32%) saturate(987%) hue-rotate(308deg) brightness(99%) contrast(89%)',
  'TECH':'invert(69%) sepia(84%) saturate(1199%) hue-rotate(349deg) brightness(103%) contrast(101%)',
  'CLINIC_FOLLOW_UP': 'invert(50%) sepia(100%) saturate(6842%) hue-rotate(247deg) brightness(97%) contrast(110%)',
  'MNT':'invert(72%) sepia(21%) saturate(943%) hue-rotate(53deg) brightness(95%) contrast(93%)',
  'TECH_ONBOARDING':'invert(49%) sepia(20%) saturate(6144%) hue-rotate(289deg) brightness(89%) contrast(80%)',
  'CROSS_CLINIC': ''
}

export default typeImgFilters;
