import { constants } from '../../../lib/utils';

export default constants('auth', 'Login', [
  'FORM_FIELD_CHANGE',
  'UPDATE_FORM',
  'INIT_DATA',
  'RESET',
  'SET_STEP',
  'SET_SUBMITTING'
])
