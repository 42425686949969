import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArticleSendingHistoryTableComponent from '../component/ArticlesSearch/ArticleSendingHistoryTableComponent';
import getArticles from '../API/getArticles';

import './ArticleSendingHistoryContainer.scss'

const ArticleSendingHistoryContainer = ({
  patientArticles,
  disabled,
}) => {
  const [loadingPatientArticles, setLoadingPatientArticles] = useState(false);
  const [sentArticles, setSentArticles] = useState([]);

  const loadAndSetPatientArticles = async (_patientArticles) => {
    setLoadingPatientArticles(true);
    if (_patientArticles && _patientArticles.length) {
      try {
        const articleIds = _.map(_patientArticles, 'articleId');
        const res = await getArticles({
          articleIds,
        });
        if (!res || res.code !== 200) {
          throw new Error('Failed to load due to code');
        }
        const { content = [] } = res.data || {};
        const contentById = _.keyBy(content, 'id');
        const newPatientArticles = _.map(_patientArticles, (a) => ({
          ...a,
          article: contentById[a.articleId],
        }));
        setSentArticles(newPatientArticles);
      } catch (error) {
        // ignore
      }
    }
    setLoadingPatientArticles(false);
  };

  useEffect(() => {
    loadAndSetPatientArticles(patientArticles);
  }, [JSON.stringify(patientArticles)]);

  return (
    <div className="article-sending-history-container">
      <div className="article-sending-history-container__label">
        Sending history
      </div>
      <ArticleSendingHistoryTableComponent
        disabled={disabled}
        patientArticles={sentArticles}
        isLoading={loadingPatientArticles}
      />
    </div>
  );
};

ArticleSendingHistoryContainer.propTypes = {};
export default ArticleSendingHistoryContainer;