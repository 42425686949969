import React, { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';

import getAutocompleteAddress from '../../../../lib/helpers/getAutoCompletedAddress';

// @params
// formatSuggestions(suggestions: Array[Object], keyword: String, OptionComponent: ReactNode): Array[any]
const AddressVerification = forwardRef((props, ref) => {
  const { disabled, setAddress, formatSuggestions, autoCompleteProps } = props;
  const [listOfSuggestions, setListOfSuggestions] = useState(null);

  const getListOfSuggestions = useCallback(_.debounce(async (keyword) => {
    let newListOfSuggestions = null;
    try {
      const res = await getAutocompleteAddress({ str: keyword });
      newListOfSuggestions = _.get(res, 'data.getAutoCompletedAddress');
    } catch (err) {
      console.error(err);
    } finally {
      let formatted = null;
      if(newListOfSuggestions && typeof formatSuggestions === 'function') 
        formatted = formatSuggestions(newListOfSuggestions, keyword, AutoComplete.Option);
      
      if(Array.isArray(formatted))
        newListOfSuggestions = formatted;
      else if(Array.isArray(newListOfSuggestions))
        newListOfSuggestions = _.map(newListOfSuggestions, s => ({
          text: s.formatted_address,
          value: s.adr_address,
        }));

      setListOfSuggestions(newListOfSuggestions);
    }
  }, 250), []);

  return (
    <AutoComplete
      disabled={disabled}
      dataSource={listOfSuggestions}
      onSearch={getListOfSuggestions}
      onSelect={setAddress}
      defaultActiveFirstOption={false}
      notFoundContent='No match'
      {...autoCompleteProps}
    />
  );
});

AddressVerification.propTypes = {
  disabled: PropTypes.bool,
  setAddress: PropTypes.func.isRequired,
  formatSuggestions: PropTypes.func, 
  autoCompleteProps: PropTypes.object
};

export default AddressVerification;