import gql from 'graphql-tag'

export default gql`
mutation doCreateUser($role:[Ref]!, $address:[InputAddress],$isTestUser: Boolean
  $email:[EmailAddress], $phone:[InputPhone],
  $commonProfile:InputCommonProfile, $memberProfile:InputMemberProfile,
  $employeeProfile:InputEmployeeProfile,
  $residency:InputMemberResidency, $username:String,
  $identification:InputIdentification,
  $capacitys: [RoleCapacityInput]
  ) {
  	createUser(
      role: $role,
      address: $address,
      email: $email,
      phone: $phone,
      commonProfile: $commonProfile,
      memberProfile: $memberProfile,
      employeeProfile: $employeeProfile,
      residency: $residency,
      username: $username,
      identification: $identification,
      isTestUser:$isTestUser
      capacitys: $capacitys
      )
    {
      id
      capacitys{
        role
        capacity
      }
      profile {
        fullName
        ...on EmployeeProfile{
          startDate
        }
         ...on MemberProfile{
          doctor{
              id
              profile{
                fullName
              }
          }
          isSelfEnrolled
      }
      }
     
    }
  }
`
