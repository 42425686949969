import React from "react";
import {Icon, message, Modal} from 'antd';

import AssigneeFormContainer from '../container/AssigneeFormContainer'

import { updatePatientAssigneeMutation } from '../API/index';
import { checkIsInRole} from '../../../lib/helpers/role-helpers';

import '../style/confirmationPopup.styles.scss';

const NOT_NECESSARY = "not-necessary";

let AssignToCTFormComponent  = class extends React.Component{
	constructor(props) {
		super(props);
	}

	resetBorderColor = ()=>{
		const inputVisitPatientDiv = document.getElementById('inputVisitPatient');
		if (inputVisitPatientDiv) {
			inputVisitPatientDiv.scrollIntoView();
			inputVisitPatientDiv.style.borderColor = '#D2D2D2';
		}
  }

  onSubmit = (values) => {
    const hasEmptyValue = _.filter(values, v=>!v).length > 0;
    const isCAOrRDOrHC = checkIsInRole(['RD','CA','HC']);
    if(hasEmptyValue && isCAOrRDOrHC){
      this.showConfirmForSubmit({ 
        onOk: () => this.handleSubmit(values) 
      });
    } else {
      this.handleSubmit(values);
    }
  }

  showConfirmForSubmit = ({ onOk }) => {
      const onCancel = () => {
      }
      Modal.confirm({
          content: <div>
             You've only selected <span >one</span> field for assignee, but <span>two</span> is recommended. Are you sure you want to submit?
          </div>,
          zIndex:3500,
          icon: <Icon type="info-circle" theme="filled" style={{ color: '#DA6453' }}/>,
          className:"assignee-confirmation-modal",
          onOk: onOk,
          onCancel:onCancel
      });
  }

  updateAssigneeVariables = (values) => {
    const {assignedToCA, assignedToRD} = values;
    let variables = _.clone(values);
    if(assignedToCA == NOT_NECESSARY){
      variables['assignedToCANotNecessary'] = true;
      variables.assignedToCA = null;
    }

    if(assignedToRD == NOT_NECESSARY){
      variables['assignedToRDNotNecessary'] = true;
      variables.assignedToRD = null;
    }
    return variables;
  }

	handleSubmit = async (values)=>{
		const { memberId,setPatient,patient,setShowForm, saveCallback} = this.props;
    const variables = this.updateAssigneeVariables(values);
		const res = await updatePatientAssigneeMutation({ memberId,...variables});
		const data = _.get(res,'data');
		_.set(patient,'user.assignees',data.updatePatientAssignee);
	    if(setShowForm) {
	      setShowForm(false);
	    }

		message.success('Care team members have been assigned to this patient');
	    this.resetBorderColor();
		if(setPatient) {
			setPatient({...patient});
		}

    if(saveCallback) {
      saveCallback(_.get(data, 'updatePatientAssignee'));
    }
	}

	render() {
		const { patient, isEnrolled, complexity } = this.props;
		return <div>
      <AssigneeFormContainer 
        patient={patient}
        isEnrolled={isEnrolled}
        complexityLevel={complexity}
        onSubmit={this.onSubmit}
      />
		</div>;
	}
}

export default AssignToCTFormComponent;