export default {
    "ENTER FOOD_LOG": "Rated and commented patient's food diary and photo.",
    "REVIEW FOOD_LOG": "Rated and commented patient's food diary and photo.",
    "ENGAGE FOOD_LOG": "Engage in patient's food diary and photo.",
    "ENTER PROVIDER_NOTES": "Entered notes for patient.",
    "REVIEW VITALS": "Reviewed and commented patient's abnormal vital reading",
    "ENTER VITALS": "Reviewed and commented patient's abnormal vital reading",
    "ENTER PATIENT_PROFILE": "Reviewed patient profile for general information and activity history",
    "REVIEW PATIENT_PROFILE": "Reviewed patient profile for general information and activity history",
    "UPDATE ENROLLED_PROGRAM": "Adjusted to patient's care plan",
    "ENGAGE CONVERSATION": "Engaged in chat conversation with patient",
    "ENGAGE TAG_PATIENT": "Engaged in tagging patient",
    "ENTER CALL_LOG": "Engaged in phone call conversation with patient (Self reported)",
    "UPDATE MEDICINE": "Updated patient medicine record",
    "UPDATE POSTIT": "Updated note about patient",
    "UPDATE ALERT": "Updated patient alert",
    "ENGAGE CLOSE_ALERT": "Engaged in reviewing and closing patient alert",
    "ENGAGE VISIT": "Engaged in patient's visit",
    "REVIEW PATIENT_DETAIL": "Reviewed patient detail information",
    "UPDATE ADDITIONALINFO": "Updated patient's additional information",
    "UPDATE HANDLE_SMART_ALERT": "Handle patient smart alert",
    'ENTER TASK_ASSIGNMENT': 'Assign and resolve patient engaging tasks',
    'UPDATE TASK_ASSIGNMENT': 'Assign and resolve patient engaging tasks',
    'CLAIMED TIME_LOG':'Claimed time log'
};
