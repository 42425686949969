import React, { useCallback, useState, useMemo } from 'react';
import { Button, Row } from 'antd';
import ReferAndEnrollForm from './ReferAndEnrollForm';
import { provider, patientPhoneNumber, otp, insurance } from '../fields/BasicInformation';
import I18N from '../../I18N';
import FormRenderer from './FormRenderer';
import InsuranceComponent from '../../patient/AdditionalInfo/Components/InsuranceComponent';
import { getConditionsValue, getPhone, getProvider, getCanUseForLogin } from '../helper/initialValueHelper';
import { PROGRAM_ELIGIBILITY_KEYS } from '../fields/ProgramEligibility';
import { editUserMutation, upsertPatientReferralMutation } from '../API/mutation';
import { getValue, onlyHasHLD } from '../helper';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { STATUS_ENUM } from '../constant';
import { GQLHelper } from '../../../lib/utils';

const MABasicInformation = ({ form, onSubmitDone, user, patientReferral, disabled, refetchUser, openErrorModal, goNextStep }) => {
  const initialValues = useMemo(() => {
    return {
      phone: getPhone(user),
      canUseForLogin: getCanUseForLogin(user),
      provider: getProvider(user),
      conditions: getConditionsValue(patientReferral,user),
    };
  }, []);
  const isReferred = _.get(patientReferral, 'status.referStatus') === 'COMPLETED';
  const selectedConditions = getValue(form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.CONDITION_LIST), initialValues.conditions);
  const hasHldOnly = onlyHasHLD(selectedConditions);
  const showButton = !hasHldOnly
      // && !_.isEmpty(selectedConditions);

  const fields = [
    provider({ col: { span: 7 }, initialValue: initialValues.provider }),
    patientPhoneNumber({ col: { span: 7, offset: 1 }, initialValue: initialValues.phone }),
    otp({  col: { span: 7, style: { marginLeft: 8 }  }, canUseForLogin: _.get(initialValues, 'canUseForLogin') })
  ];

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return goNextStep();
    }
    form.validateFields(async (err, values) => {
      if (!err) {
        const { id: memberId, phone, profile } = user;
        const variables = {
          patientId: memberId,
          // referredBy: JSON.parse(sessionStorage.getItem('currentUser')).id,
          // billableHealthConditions: _.map((icdCodes || {}), v => ({ code: v.code, description: v.description })),
          // billableHealthConditions: values.healthConditions,
          // conditionsToMonnitor: values.conditionsList,
          status: { screeningStatus: STATUS_ENUM.COMPLETED }
        };
        const memberProfile = {
          birthday: profile.birthday
        };
        const userVariables = {
          id: memberId,
          memberProfile
        };
        if (values.patientPhoneNumber) {
          const newPhones = _.map(phone, (p) => (_.omit(p, ['__typename', 'verified'])));
          const mobile = _.find(newPhones, p => p.type === 'MOBILE');
          const canUseForLogin = !!values.canUseForLogin;
          if (mobile) {
            mobile.number = values.patientPhoneNumber;
            mobile.canUseForLogin = canUseForLogin;
          } else {
            newPhones.push({
              type:'MOBILE',
              number: values.patientPhoneNumber,
              canUseForLogin,
              countryCode: '1',
            });
          }
          userVariables.phone = newPhones;
        }
        if (values.provider) {
          memberProfile.doctorId = values.provider;
        }
        if (values.insuranceProviders) {
          memberProfile.insuranceProviders = _.map(values.insuranceProviders, (ip) => ({ id: ip.id, primary: ip.primary}));
        }
        try {
          await upsertPatientReferralMutation(variables);
          await editUserMutation(userVariables).then(res=> {
            if(refetchUser)refetchUser()
          });
          // if (!isReferred) {
          //   if (_.isNil(_.get(user, 'remoteEnrollment'))) {
          //     await remoteEnrollmentApis.createRE({ memberId });
          //   }
          //   await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'INIT' });
          // }
          onSubmitDone();
        } catch (e) {
          console.error(e);
          openErrorModal(GQLHelper.formatError(e));
        }
      }
    });
  }, [form, onSubmitDone]);

  return (
    <ReferAndEnrollForm
      header="Basic Information"
      className="basic-information"
      onSubmit={handleSubmit} 
      buttons={showButton && <Button type="primary" htmlType="submit" className="new-style">Next</Button>}
    >
      <div style={{ marginBottom: 20 }}>{I18N.get('referAndEnroll.startEnrollmentNote')}</div>
      <Row>
        <FormRenderer form={form} fields={fields} disabled={disabled} />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <div style={{ color: '#262626', fontSize: 12, marginBottom: 5 }}><label>Insurance</label></div>
        <InsuranceComponent form={form} insuranceProviders={_.get(user, 'profile.insuranceProviders')} showFilterCheckbox={false} disabled={disabled} />
      </Row>
      {hasHldOnly && I18N.get('referAndEnroll.hldOnlyNote')}
    </ReferAndEnrollForm>
  );
}

export default MABasicInformation;
