import { combineReducers } from 'redux'
import { commonReplaceReturnDevicesReducer, commonReplaceReturnDevicesInitState, commonReplaceReturnDevicesInitForm, commonReplaceReturnDevicesFormReducer } from './CommonReplaceReturnDevicesReducer';
import types from '../constants';

  const initState = {
    patientInfo: {},
    loading: false,
    enrolledProgramId:'',
    enrolledProgramList:[],
    searchText: '',
    searchStatus: 'INIT',  // INIT, NO_RESULT, HAS_RESULT

  }

  const replaceReturnDevicesInitState = {
    ...commonReplaceReturnDevicesInitState,
    ...commonReplaceReturnDevicesInitForm
  }

  const ownReducer = (state = initState, action) => {
    switch(action.type) {

      case types.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText
      }

      case types.SET_SEARCH_STATUS:
      return {
        ...state,
        searchStatus: action.searchStatus
      }

      case types.SET_ENROLLEDPROGRAM_ID:
      return {
        ...state,
        enrolledProgramId : action.enrolledProgramId
      }

      case types.SET_ENROLLEDPROGRAMLIST:
      return {
        ...state,
        enrolledProgramList : action.enrolledProgramList
      }

      case types.SET_PATIENT_INFO:
      return {
        ...state,
        patientInfo: action.patientInfo
      }
      case types.SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }

      // case types.UPDATE_ENROLLEDPROGRAMLIST: //after call return devices, update devices status
      //
      // const updateEnrolledProgramList = Object.assign([], state.enrolledProgramList);
      // const indexEP =  _.findIndex(updateEnrolledProgramList, {value: action.enrolledProgramId});
      //
      // if (indexEP >= 0 )
      // {
      //   //only for update existing
      //   updateEnrolledProgramList[indexEP].devices = _.merge( updateEnrolledProgramList[indexEP].devices,  action.devices)
      // }
      //
      //
      // return {
      //   ...state,
      //   enrolledProgramList : updateEnrolledProgramList
      // }

      //for return replace devices form
      // case types.INIT_DATA:
      //   return {
      //     ...state,
      //     initData: action.initData
      //   }


      default:
      return state
    }
  }

  export default combineReducers({
    Info: ownReducer,
    ReplaceReturnDevices: (state = replaceReturnDevicesInitState, action) => commonReplaceReturnDevicesFormReducer(commonReplaceReturnDevicesReducer(state, action, types), action, types)
  })
