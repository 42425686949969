import React from 'react';
import {graphql} from "react-apollo";
import query from "../../query";
import {browserHistory} from "react-router";
import helper from "../../helper";
import {compose} from "recompose";

import { Button} from "antd";
//component import
import EditClinicComponent from "../components/EditClinicComponent";
import {Spin} from "antd";
const EditClinicContainer = class extends React.Component{
    constructor(props) {
        super(props);
    }
    handleClickBackBtn = ()=>{
        window.history.back();
    }
    renderBackButton = ()=>{
        return <div style={{ display:'flex' }}>
                <img onClick={ this.handleClickBackBtn } src={'/image/backButton.svg'} style={{width:25,cursor:'pointer'}}/>
                <span style={{ fontSize: 16,marginLeft:20,cursor:'pointer'}} onClick={this.handleClickBackBtn}>
                    Go back to clinic page
                </span>
               </div>
    }

    render() {
        return <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:30}}>
                <div style={{ width:1020 }}>
                    { this.renderBackButton() }
                    <EditClinicComponent {...this.props}/>
                </div>
               </div>
    }
}
const orgInfo = graphql(query.organization,{
    options: (ownProps) => {
        const curUrl = browserHistory.getCurrentLocation().pathname;
        const urlArr = _.split(curUrl, '/');
        const url = urlArr[urlArr.length - 2];
        return {
            variables: {
                id: url
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organization, loading, refetch, error } = data;
        if(error) {
            return { error }
        }
        return {
            organization,
            loading,
            refetch
        }
    }
})

const areaList = graphql(query.organizationList,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const rootId = _.get(currentUser,'selectedRole.organization.id');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                rootId
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organizationList,loading,refetch } = data;
        return {
            orgList: _.get(organizationList,'data',[]),
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})

const templateGroups = graphql(query.templateGroupsListQuery, {
    options: (ownProps) => {
        const variables = {
            count:100,
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { templateGroupOrganizationsList, loading,refetch } = data;
        return {
            templateGroupsList: templateGroupOrganizationsList,
            templateLoading: loading,
            refetchTemplate: refetch
        }
    }
})
const childrenOrgs = graphql(query.getChildrenOrgsQuery,{
    options:(ownProps) => {
        const orgId = helper.getViewerOrgId();
        const variables = {
            rootId: orgId
        }
        return {
            variables
        }
    },
    props:({data})=>{
        const { getChildrenClinics=[],loading,refetch } = data;
        return {
            orgList: getChildrenClinics,
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})
export default compose(orgInfo,childrenOrgs,templateGroups) (EditClinicContainer)
