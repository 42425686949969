import React from 'react';
import RoleManagement from './RoleManagementComponent';
import {
    Button,
    DatePicker,
    Input,
    Form,
    Col,
    Row,
    Modal,
    Card,
    message,
    Icon,
    Checkbox,
    InputNumber,
    Switch,
    Select,
    Tooltip
} from 'antd';
import API from '../../API';
import moment from 'moment';
import helper from '../../helper';
import { goPath } from '../../../../lib/utils';
import { emailValidator2, firstAndLastNameValidator} from 'libModule/validator';
import '../style.scss';
import {CLINIC_MANGER_STR, AREA_MANAGER_STR, HC_STR} from '../../constant/roleStrMap';
import UserEditPhotoContainer from '../Container/UserEditPhotoContainer';
import TestUserComponent from '../../../patient/component/TestUserComponent';
import { ROLE_MAP, ROLE_NAME } from '../../../../lib/constants';
import I18N from '../../../I18N';
import { nationOptions, langOptions, countryOptions,
    docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import  consentHelper  from '../../../newAdmin/ConsentForm/helper/index';
import Client from 'libModule/gqlClient';
import userList from 'graphqlModule/miniUserList';
import MentorRDIconComponent from "./MentorRDIconComponent";
import TooltipWithModal from '../../../accessories/component/TooltipWithModalComponent';
const allRoles = (roles) => {
    let set = new Set();
    _.map(roles, (key, index) => {
        key.name==='Doctor'?set.add('Provider') :null;
        key.name==='Clinic Medical Assistant'?set.add('MA'):null;
        key.organization.leaf&&key.name==='Admin'?set.add(CLINIC_MANGER_STR):null;
        !key.organization.leaf&&key.name==='Admin'?set.add(AREA_MANAGER_STR) :null;
        key.name==='Medical Assistant'?set.add('CA') :null;
        key.name==='Nurse'?set.add('RD') :null;
        key.name === 'Partner Biller' ? set.add('Partner Biller') : null;
        key.name === ROLE_NAME.HC ? set.add(HC_STR) : null;
    })
    let res = "";
    set.forEach((item) => res? res = res +','+'\xa0'+ item : res = res + item);
    return res;
}
class UserDetailComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            visible:false,
            inputValue:"",
            showWrong:false,
            basicEdit:false,
            RDList:[],
            isTestUser: _.get(props, 'userData.user.isTestUser')
        }
    }

    fetchUserList = ()=>{
        Client.query({
            query: userList,
            variables: (() => {
                const orgRoles = [];
                _.get(this.props, 'organizationList', []).forEach(o => orgRoles.push(...o.roles));
                const selectedRoles = ['Nurse'];
                const roles = helper.getRefIDWithRoleCategory(selectedRoles, orgRoles);
                return {
                    count: 500,
                    filters: {roles}
                }
            })(),
            fetchPolicy: 'network-only'
        }).then(({data}) => {
            let RDList = _.get(data, 'userList.data', []);
            if (RDList.length) {
                this.setState({
                    RDList
                })
            }
        })
    }

    componentDidMount() {
        this.fetchUserList();
    }
    inputOnChange = (event) => {
        this.setState({inputValue:event.target.value});
    }
    deleteOnCancel = () => {
        this.setState({visible:false, inputValue:""})
    }
    deleteUser = () => {
        if (this.state.inputValue !== "Deactivate") {
            this.setState({showWrong:true})
        }
        else {
            const variables = {
                id: _.get(this.props.userData,"user.id"),
                roles:_.map(this.props.userData.user.allRoles,'refId')
            }
            API.deleteEmployee(variables).then(()=>{
                message.success("The user is deactivated successful")
            })
            helper.addPrefixAndGoTo('users');
        }
    }
    basicOnCancel = () => {
        this.setState({basicEdit: false, activeKey: null, isTestUser: _.get(this.props, 'userData.user.isTestUser') });
        this.props.form.resetFields();
    }
    basicSave = () => {
        const { userData, userRefetch } = this.props;
        const { getFieldValue, validateFieldsAndScroll } = this.props.form;
        const localThis = this;
        validateFieldsAndScroll((err)=>{
            if (err) {
                return;
            }
            const email = getFieldValue('email');
            const emailArray = [];
            const userSettings = _.get(userData,'user.userSettings');
            if(email) emailArray.push(email);
            const phone = getFieldValue('phone');
            const canUseForLogin = getFieldValue('canUseForLogin');
            const phoneArray = [];
            if (phone) phoneArray.push({'countryCode':'+1','number':phone,'type':'MOBILE',canUseForLogin});
            const hasCredential = !!getFieldValue('hasCredential');
            const variables = {
                'id':this.props.userData.user.id,
                'phone':phoneArray,
                'email':emailArray,
                'username':getFieldValue('LoginId')||getFieldValue('email'),
                'commonProfile':{'firstName':getFieldValue('firstName'),'lastName':getFieldValue('lastName')},
                'isTestUser': this.state.isTestUser,
                'isSelfEnrolled': getFieldValue('isSelfEnrolled'),
                'employeeProfile':{
                  'startDate': getFieldValue('startDate'),
                  hasCredential,
                  'NPI': getFieldValue('NPI'),
                  'mentorId': !hasCredential ? getFieldValue('mentorRD') : null,
                  'providerLanguage': getFieldValue('providerLanguage'),
                   'directAddress':getFieldValue('directAddress')
                },
                'userSettings':{
                    ..._.omit(userSettings,['__typename','heartbeatInterval']),
                    sendHeartbeatLog: getFieldValue('sendHeartbeatLog'),
                    snapshotInterval: getFieldValue('sendHeartbeatLog') ? getFieldValue('snapshotInterval')*1000:null
                }
            }
            API.editEmployee(variables).then(()=>{
                localThis.setState({basicEdit: false});
                message.success("Changes saved");
                if(userRefetch){
                    userRefetch();
                }
                if(this.fetchUserList) {
                    this.fetchUserList();
                }
            }).catch((e)=>{
                Modal.error({
                    title:'Update profile Error',
                    content: e.message
                });
            })
        })
    }
    deactOnCancel = () => {
        this.setState({visible:false})
    }
    header=()=>{
        const {userData, userRefetch } = this.props;
        const mentor = _.get(userData,'user.profile.mentor.profile.fullName','');
        const mentee = _.get(userData,'user.profile.healthCoach');
        const healthCoach = _.get(mentee,'profile.fullName','No Mentee HC');
        const hasRDRole = _.get(this,'props.roles.RD.length') > 0;
        const menteePathKey = mentee ? 'hasMentorTooltip' : 'noMentorTooltip';
        return  <div style={{ background: '#FFFFFF'}}>
            <Card bordered={false}>
                <Row>
                    <Col span={2}>
                        <UserEditPhotoContainer id={_.get(userData.user, 'id')} photo={_.get(userData.user, 'profile.avatar.link')} userRefetch={userRefetch} />
                    </Col>
                    <Col span={21} offset={1}>
                        <Row className={'titleWrapper'}>
                            <Col span={20} className={'titleHeader'}>{_.get(userData.user, 'profile.fullName')}</Col>
                            <Col span={4}><Button type="link" onClick={()=>this.setState({visible: true})}>deactivate user</Button></Col>
                        </Row>
                        <Row className={'titleBody'}>{allRoles(_.get(userData.user, 'allRoles'))}</Row>
                        <Row className={'titleBody'}>
                            { mentor && `Mentor: ${mentor}` }
                            { hasRDRole && `Mentee: ${healthCoach}` }
                            { hasRDRole && <TooltipWithModal modalHeader={I18N.get(`admin.employeeProfile.${menteePathKey}.modal.title`)}
                                                               tooltipTitle={I18N.get(`admin.employeeProfile.${menteePathKey}.title`)}
                                                               modalBody={I18N.get(`admin.employeeProfile.${menteePathKey}.modal.body`)}/> }
                        </Row>
                    </Col>
                </Row>

            </Card>
        </div>
    }
    render(){
        const { getFieldDecorator,getFieldValue} = this.props.form;
        const {userData, organizationList, userRefetch, roles, location} = this.props;
        const startedAt = _.get(userData, 'user.profile.startDate')||null
        const curSendHeartbeatLog = getFieldValue('sendHeartbeatLog');
        const heartbeatInterval = _.get(userData,'user.userSettings.heartbeatInterval',0);
        const heartbeatIntervalInM = heartbeatInterval/1000;
        const isHC = !!_.get(roles, `${ROLE_MAP[ROLE_NAME.HC]}.length`);
        const needMentorRD = getFieldValue('hasCredential') === I18N.get('admin.hcCredential.options').NO
        return (
            <div>
                <Modal visible={this.state.visible} footer={null} onCancel={this.deactOnCancel} className={'deactWrapper'}>
                    <Icon type="exclamation-circle"/> 
                    <div className={'deactTitle'}>Are you sure to deactivate this user?</div>
                    <div className={'deactBody'}>This user will not be able to log in to the portal and will be removed from all associtated areas, clinics and care teams. Once being reactivated, this user is able to log in again.</div>
                    <br/>
                    <div style={{fontWeight:'bold'}} className={'deactBody'}>To continue and deactivate this user,</div>
                    <div style={{fontWeight:'bold',marginBottom:'10px'}} className={'deactBody'}>Please type "Deactivate" below:</div>
                    <input onChange={this.inputOnChange} value={this.state.inputValue}/>
                    <div style={{font:"13px", color:"red", visibility:this.state.showWrong?"visible":"hidden"}}>The input is wrong</div>
                    <Col span={12}><Button style={{float:'right'}} type="primary" onClick={this.deleteUser}>Deactivate</Button></Col>
                    <Col span={11} offset={1}><Button style={{float:'left'}} onClick={this.deleteOnCancel}>Cancel</Button></Col>
                </Modal>
                <Row className={'goBack'}>
                    <Icon type="arrow-left" className={'icon'}/>
                    <Button type="link" onClick={() => {
                      /*if(location && _.get(location, 'state.from')){
                        goPath(_.get(location, 'state.from'));
                      } else {
                        // default*/
                        helper.addPrefixAndGoTo('users');
                      }
                    }>
                      Go Back
                    </Button>
                </Row>
                { this.header() }
                {/*<div style={{ background: '#FFFFFF'}}>*/}
                {/*    <Card bordered={false}>*/}
                {/*        <Row>*/}
                {/*            <Col span={2}>*/}
                {/*                <UserEditPhotoContainer id={_.get(userData.user, 'id')} photo={_.get(userData.user, 'profile.avatar.link')} userRefetch={userRefetch} />*/}
                {/*            </Col>*/}
                {/*            <Col span={21} offset={1}>*/}
                {/*                <Row className={'titleWrapper'}>*/}
                {/*                    <Col span={20} className={'titleHeader'}>{_.get(userData.user, 'profile.fullName')}</Col>*/}
                {/*                    <Col span={4}><Button type="link" onClick={()=>this.setState({visible: true})}>deactivate user</Button></Col>*/}
                {/*                </Row>*/}
                {/*                <Row className={'titleBody'}>{allRoles(_.get(userData.user, 'allRoles'))}</Row>*/}
                {/*                {startedAt?<Row className={'titleBody'}>Started on{moment(startedAt).format('MM/DD/YYYY')}</Row>:null}*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        */}
                {/*    </Card>*/}
                {/*</div>*/}
                <Row>
                    <Col span={10} offset={1}>
                        <Card bordered={false} style={{ background: '#FFFFFF', marginTop:'50px'}}>
                            <Row className={'headerWrapper'}>
                                <Col span={20} className={'formHeader'}>Basic Information</Col>
                                <Col span={4}><Button onClick={()=>{this.setState({basicEdit:true})}}>Edit</Button></Col>
                            </Row>
                            <Form className={'providerBasicInfo'}>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label="First Name" >
                                            {getFieldDecorator('firstName',
                                            {initialValue:_.get(userData.user, 'profile.firstName'),
                                            rules: [{required: true,message: 'Please input your first name'},
                                                {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}]})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <Form.Item label="Last Name" >
                                            {getFieldDecorator('lastName',{initialValue:_.get(userData, 'user.profile.lastName'),
                                            rules: [{validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}]})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label="Login ID" >
                                            {getFieldDecorator('LoginId', {initialValue:_.get(userData, 'user.username')})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={1} >
                                        <Form.Item label="Start Date" className={'startDateWrapper'}>
                                            {getFieldDecorator('startDate', {initialValue:startedAt?moment(startedAt):null})
                                            (<DatePicker disabled={!this.state.basicEdit} className={'startDate'}/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                <Col span={12} >
                                    <Form.Item key="NPI" label="NPI" >
                                        {getFieldDecorator('NPI', {initialValue:_.get(userData, 'user.profile.NPI',null)})
                                        (<Input disabled={!this.state.basicEdit}/>)}
                                    </Form.Item>
                                </Col>
                                    <Col span={11} offset={1}>
                                        <Form.Item key="email" label="E-mail Address" >
                                            {getFieldDecorator('email', 
                                            {initialValue:_.get(userData, 'user.email')?_.get(userData, 'user.email.0.address'):null,
                                            rules: [{required: true,message: 'Please input your E-mail Address'},
                                            {validator: (rule, value, callback) => callback(emailValidator2(value))}]})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item key="phone" label="Phone number" >
                                            {getFieldDecorator('phone', {initialValue:_.get(userData, 'user.phone')?_.get(userData, 'user.phone.0.number'):null})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span ={5} offset={1}>
                                        <Form.Item key="phone" label="OTP" >
                                            { getFieldDecorator('canUseForLogin', { initialValue:_.get(userData, 'user.phone')?_.get(userData, 'user.phone.0.canUseForLogin'):false,valuePropName: 'checked'})( <Switch checkedChildren='on' unCheckedChildren={'off'} disabled={!this.state.basicEdit}/>) }
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Language'}>
                                            {getFieldDecorator('providerLanguage',{ initialValue: (_.get(userData, 'user.profile.providerLanguage').length ?  _.get(userData, 'user.profile.providerLanguage'):[{code:'EL'}]).map(l=>l.code) })(consentHelper.multiLanguageSel(langOptions,['EL'],!this.state.basicEdit))}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={23} style={{ display:'flex',alignItems:'center' }}>
                                        <Form.Item key="directAddress" label="Direct Address" style={{ width:'inherit' }}>
                                            {getFieldDecorator('directAddress',
                                                {initialValue:_.get(userData, 'user.profile.directAddress',null), rules: [{validator: (rule, value, callback) => callback(emailValidator2(value))}]})
                                            (<Input disabled={!this.state.basicEdit}/>)}
                                        </Form.Item>
                                        <Tooltip title={I18N.get('admin.directAddress.tooltip')} overlayClassName={'directAddressTooltip'}>
                                            <Icon type="info-circle" style={{ marginLeft: 10 }}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                  {
                                    isHC &&
                                    <React.Fragment>
                                    <Col span={12} >
                                      <Form.Item label={I18N.get('admin.hcCredential.label')}>
                                        {
                                          getFieldDecorator('hasCredential', {
                                            initialValue: ~~_.get(userData, 'user.profile.hasCredential'),
                                            rules: [{ required: true, message: I18N.get('admin.hcCredential.errorMessage') }]
                                          })(
                                            <Select disabled={!this.state.basicEdit}>
                                              {
                                                _.map(I18N.get('admin.hcCredential.options'), (v, k)=><Select.Option key={k} value={v}>
                                                  {_.capitalize(k)}
                                                </Select.Option>)
                                              }
                                            </Select>
                                          )
                                        }
                                      </Form.Item>
                                      {
                                        getFieldValue('hasCredential') === I18N.get('admin.hcCredential.options').NO &&
                                        <Col style={{ fontSize: 12, lineHeight: '16.24px' }}>
                                          {I18N.get('admin.hcCredential.note')}
                                        </Col>
                                      }
                                    </Col>
                                    </React.Fragment>
                                  }
                                  <Col span={isHC ? 11 : 24} offset={isHC ? 1 : 0} style={isHC ? { padding: '21px 0px' } : {}}>
                                      <Col span={isHC ? 24 : 12}>
                                          <Form.Item>
                                              {
                                                  getFieldDecorator('sendHeartbeatLog', { initialValue:_.get(userData,'user.userSettings.sendHeartbeatLog'),valuePropName: 'checked' })
                                                  (<Checkbox key={'sendHeartbeatLog'} disabled={!this.state.basicEdit} onChange={(sendHeartbeatLog)=>this.setState({sendHeartbeatLog:sendHeartbeatLog.target.value})}>

                                                      Send Heart Beat
                                                  </Checkbox>)
                                              }
                                          </Form.Item>
                                      </Col>
                                      <Col span={isHC ? 24 : 11} offset={isHC ? 0 : 1}>
                                          <Form.Item>
                                              {
                                                  getFieldDecorator('snapshotInterval', { initialValue:_.get(userData,'user.userSettings.snapshotInterval',0)/1000,
                                                                                          rules:[{ required: curSendHeartbeatLog },
                                                                                                { validator: (rule, val, cb) => {
                                                                                                  // if the checkbox is unchecked, skip validation
                                                                                                  const disabled = !this.state.basicEdit|| !curSendHeartbeatLog;
                                                                                                  if(disabled){
                                                                                                      return cb();
                                                                                                  }
                                                                                                  return !isNaN(val) && (parseInt(val)<1||parseInt(val)>heartbeatIntervalInM) ? cb(`value should be bigger than 1 and smaller than ${heartbeatIntervalInM}`):cb() }}
                                                                                                ]})
                                                      ( <InputNumber style={{ width:200 }} key={'snapshotInterval'} placeholder={`heartbeat value ${heartbeatIntervalInM}s`}
                                                                    disabled={!this.state.basicEdit|| !curSendHeartbeatLog } /> )

                                              }
                                          </Form.Item>
                                      </Col>
                                  </Col>
                                    {   isHC && needMentorRD &&
                                        <Col span={12} style={{display: 'flex'}}>
                                            <Form.Item label={I18N.get('admin.mentorRD.label')} style={{width: '100%'}}>
                                                {
                                                    getFieldDecorator('mentorRD', {
                                                        initialValue: _.get(userData, 'user.profile.mentor.id'),
                                                        rules: [{
                                                            required: getFieldValue('hasCredential') == 0,
                                                            message: I18N.get('admin.hcCredential.errorMessage')
                                                        }]
                                                    })(
                                                        <Select disabled={!this.state.basicEdit}>
                                                            {
                                                                _.map(this.state.RDList, (v, k) => {
                                                                    let value = v.id;
                                                                    let isCurMentorId = _.get(userData, 'user.profile.mentor.id')==value;
                                                                    let hasHealthCoach = _.get(v, 'profile.healthCoach.id');
                                                                    let hasHCMsg = hasHealthCoach&&!isCurMentorId ? <span className={'hint'}>(Matched with another HC)</span> : ''
                                                                    let fullName = _.get(v, 'profile.fullName', 'Unknown');
                                                                    return <Option key={value} value={value} disabled={hasHealthCoach&&!isCurMentorId}>
                                                                        <div>{ _.capitalize(fullName) }{ hasHCMsg}</div>
                                                                    </Option>
                                                                })
                                                            }
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                            <MentorRDIconComponent/>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <TestUserComponent localThis={this}
                                                           headerText={'Is this a test provider?'}
                                                           contentText={'account'}
                                                           isTestUser={this.state.isTestUser}
                                                           activeKey={this.state.activeKey}
                                                           onChangeKey={(activeKey) => this.setState({activeKey})}
                                                           disabled={!this.state.basicEdit}/>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <Button
                                            className='changePWbtn'
                                            disabled={!this.state.basicEdit}
                                            onClick={() => goPath(`patients/${_.get(this.props.userData, 'user.id')}/change-password`)}>
                                            Change Password
                                        </Button>
                                    </Col>

                                </Row>
                            </Form>
                            {this.state.basicEdit?<Row>
                                <Col span={12} ><Button style={{float:'right'}} onClick={this.basicSave}>Save</Button></Col>
                                <Col span={11} offset={1}><Button style={{float:'left'}} onClick={this.basicOnCancel}>Cancel</Button></Col>
                            </Row>: null}
                        </Card>
                    </Col>
                    <Col span={11} offset={1}>
                        <RoleManagement roles={roles} 
                        userData={userData.user} 
                        userRefetch={userRefetch} 
                        organizationList={organizationList}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
UserDetailComponent = Form.create()(UserDetailComponent);

export default UserDetailComponent;