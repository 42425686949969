/* eslint-disable */
import React from 'react';
import { Card, Button } from 'antd';
import { List, Avatar } from 'antd';
import { Timeline, Icon } from 'antd';
import * as R from 'ramda';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import troubleshooting_QUERY from './troubleshooting-overview-gql';
import moment from 'moment';
import { mkShortDatetime } from './utils';

const SplitCard = ({ cards, key }) => {
  const [[card0], cardsTail] = R.splitAt(1, cards);
  const mkCard = ({ title, body }) => {
    return (
      <Card title={title} style={{flexGrow: 2, overflow: 'scroll' }} key={title} >
        { body }
      </Card>
    );
  };

  return (
    <Card key={key} title={ card0.title }
      bodyStyle={{ padding: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }} 
      className='infocard' style={{ display: 'flex', flexDirection: 'column' }}>
      <Card style={{flexGrow: 1, flexShrink: 0 }} key='mainbody' > { card0.body } </Card>
      { R.map(mkCard, cardsTail) }
    </Card>
  )
};

const UserInfoCard = ({ loading, user }) => {
  let { address, birthday, caregiver, email, fullName, username } = user;
  const loginHistoryComponent = ({
    action, // : // "auth-login-input"
    appId, // : // null
    createdAt, // : // "2017-08-11T00:20:04.984Z"
    group, // : // "AUTHENTICATION"
    phoneName,
    networkType,
    networkSpeed,
    ip, // : // null
    note, // : // "Tina Malone logged in"
    success, // : // true
    updatedAt, // : // "2017-08-11T00:20:04.984Z"
    viewerId, // : // null
  }, i) => {
    const dt = R.isNil(createdAt) ? null : new Date(createdAt);
    const timestamp = mkShortDatetime(dt);
    if(success) return <Timeline.Item key={i} >{timestamp}: {note} - {networkType} {ip} {phoneName}</Timeline.Item>
    else {
      const dot = success ? null : <Icon type="clock-circle-o" style={{ fontSize: '16px' }} />;
      const color= success ? null : "red";
      return <Timeline.Item key={i} dot={dot} color={color} >{timestamp}: {note} {ip} </Timeline.Item>
    };
  };
  console.log('user.measure', user.measureAuditList);
  const cardsArray = [
    [
      "Basic user info",
      <div>
        <p>Name: {fullName}</p>
        <p>Email: {email}</p>
        <p>Username: {username}</p>
        <p>Birthday: {birthday}</p>
        <p>Location: {address}</p>
        <p>Caregiver: {caregiver}</p>
      </div>
    ],
    [
      "Login history",
      <div>
        <Timeline>
          { (user.loginAuditList).map(loginHistoryComponent) }
        </Timeline>
      </div>
     ]
  ];
  const cards = R.map(([title, body])=>({ title, body }), cardsArray);
  return SplitCard({ cards });
};

const PhoneConnectCard = ({ phonesList }) => {
  if(R.isEmpty(phonesList)) return null;
  const onephone = (phone, i) => ([
      i+1,
      <div>
        < hr/>
        { R.map(
            ([key, value])=>(<p key={key}>{key}: { moment(value, moment.ISO_8601).isValid() ? mkShortDatetime(moment(value, moment.ISO_8601).toDate()) : value } </p>)
            , R.toPairs(R.omit(['__typename'], phone))) 
        }
        < hr/>
      </div>
    ]);
  const cardsArray = phonesList.map(onephone);
  const cards = R.map(([title, body])=>({ title, body }), cardsArray);
  const mkCard = ({ title, body }) => {
    return (
      <Card title={title} key={title} >
        { body }
      </Card>
    );
  };
  return <Card title='Phones'
      bodyStyle={{ padding: 0, overflow: 'scroll', marginLeft: 'auto' }} 
      className='infocard' style={{ display: 'flex', flexDirection: 'column' }}>
      { R.map(mkCard, cards) }
    </Card>
};

const DeviceInfoCard = ({deviceType, devices, measureAuditList, ...info}) => {
  console.log('DeviceInfoCard', deviceType);
  const DeviceCard = (deviceInfo, i) => {
    return (
      <div key={i} >
        <p>Type: { deviceInfo.type }</p>
        <p>Model: { deviceInfo.model }</p>
        <p>Status: { deviceInfo.status }</p>
        <p>Address: { deviceInfo.address }</p>
        <p>Other: { deviceInfo.other }</p>
        <hr/>
      </div>
    );
  };

  const thisDevice = R.filter(R.propEq('type', deviceType), devices);
  const card0 = [
    <span style={{ fontSize: 16, fontWeight: 600 }}>{deviceType}</span>,
    thisDevice.map(DeviceCard)
  ];

  const measureAuditListComponent = (input, i) => {
    const {
      phoneName,
      networkType,
      deviceModel, //null,
      deviceAddress, //null,
      type, //TM,
      date, //1504649511040,
      body_temperature,
      body_weight,
      systolic_blood_pressure,
      diastolic_blood_pressure,
      heart_rate,
      blood_glucose,
        ... rest
    } = input;
    const dt = R.isNil(date) ? null : new Date(date);
    const timestamp = mkShortDatetime(dt);
    let measuredValue;
    const stringifyMeasurement = (m) => R.join(' ', [m.value, m.unit]);

    switch(deviceType) {
      case 'TM': 
        measuredValue = stringifyMeasurement(body_temperature);
        break;
      case 'HS': 
        measuredValue = stringifyMeasurement(body_weight);
        break;
      case 'BP': 
        measuredValue = stringifyMeasurement(systolic_blood_pressure) + ' / ' + stringifyMeasurement(diastolic_blood_pressure) + ', ' + stringifyMeasurement(heart_rate);
        break;
      case 'BG': 
        measuredValue = stringifyMeasurement(blood_glucose);
        break;
      default:
        measuredValue = JSON.stringify(rest);
    };
    console.log('meausre', deviceType, measuredValue);
    return <Timeline.Item key={i} >{timestamp}: {measuredValue} - {deviceModel} {deviceAddress} {networkType} {phoneName}</Timeline.Item>
  };
  const cardsTail = [[
    "Measurements",
    <div>
      <Timeline>
        { measureAuditList.map(measureAuditListComponent) }
      </Timeline>
    </div>
    ]];
  const cardsArray = R.prepend(card0, cardsTail);
  const cards = R.map(([title, body])=>({ title, body }), cardsArray);
  return SplitCard({ cards, key: info.key });
};

const bpInfo2 = {
  deviceType: 'BP',
  model: 'BP5',
  firmware: '1.0.1',
  mac: '35AG5AC ',
  batterySummary: 'Battery died 3 times/wk',
  purchaseInfo: 'Bought from BestBuy',
  accessories: {},
};

const Component = ({ loading, user }) => {
  const deviceTypeList = ['BP', 'BG', 'TM', 'PO', 'HS', 'AM'];
  const devices = R.groupBy(R.prop('type'), user.devices);
  const measureAuditList = R.groupBy(R.prop('type'), user.measureAuditList);
  const f = (deviceType) => {
    return {
      key: deviceType,
      deviceType,
      devices: devices[deviceType] || [],
      measureAuditList: measureAuditList[deviceType] || [],
    }
  };
  const deviceGroups = R.map(f, deviceTypeList);
  console.log('R.groupBy', deviceGroups);
  return (
  <div className='card-window' >
    <UserInfoCard key='userinfo' user={user} loading={loading} />
    <PhoneConnectCard key='phoneconnect' phonesList={user.phoneAuditList} loading={loading} />
    { R.map(DeviceInfoCard, deviceGroups) }
  </div>
)};

const reshape = (user) => {
  const address = R.join(', ', R.values(R.pick(['streetNumber', 'streetName', 'unit', 'city', 'postCode'], R.head(user.address))));
  const email = R.prop('address', R.head(user.email));
  return R.mergeAll([R.omit(['profile', 'userSettings'], user), { address, email }, user.profile, user.userSettings]);
};

const options = {
  options: ({ params: { userId } }) => ({
    variables: { id: userId }
  }),
  props: ({ data: { loading, refetch, user }}) => {
    console.log('old props', user, loading);
    const res = { loading, refetch, user: loading ? { devices: [], measureAuditList: [], loginAuditList: [], phoneAuditList: [] } : reshape(user)};
    console.log('new props', res.user);
    return res;
  }
};
export default graphql(troubleshooting_QUERY, options)(Component);