import gql from "graphql-tag";

export  default gql`query medicationList($memberId: EID!,$page:Int,$count:Int,$active:Boolean,$types:[MedicationTypeEnum],$sort:MedicationListSort) {
    medicationList(filters: {memberId: $memberId,active:$active,types:$types},page:$page,count:$count,sort:$sort) {
        data {
            id
            member {
                id
                profile {
                    fullName
                }
            }
            name
            type
            startDate
            endDate
            status
            otherMD
            healthCondition
            dosage
            instruction
            active
            createdAt
            updatedAt
            histories{
                field
                originalValue
                newValue
                modifiedAt
            }
        }
        pageInfo {
            total
            lastPage
        }
    }
}`
