import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Button, Icon } from 'antd';
import PESStatementCreator from './PESStatement';
import formRenderer from '../../helpers/formBuilder';
import { shortenId } from '../../helpers/nutritionHelpers';
import { categoryMap, stageOfBehavorialChanges, DEFAULT_BEHAVIOR_CHANGE } from '../../constant/nutritionInterpretationConstants';
import Client from 'libModule/gqlClient';
import { getESOptions } from 'graphqlModule/getPESTemplates';
import { editSinglePES } from 'graphqlModule/mutation/editPESStatement';
import './nutritionInterpretationSelect.styles.scss';

const getFormConfigs = (
  props, 
  PESStatementDatasource, 
  canShowPES,
  ESOptions, 
  removePESStatement, 
  fetchEtiologyAndSignSymptom
) => [
  [
    // SC-6903: move this part to Goals
    // {
    //   label: 'Stage of Behavioral Change',
    //   key: 'behaviorChange',
    //   value: _.get(props, 'behaviorChange') || DEFAULT_BEHAVIOR_CHANGE,
    //   colProps: { span: 5 },
    //   render: () => <Select 
    //     style={{ width: '100%' }}
    //     placeholder='Select'
    //     disabled={props.disabled}
    //   >
    //     {
    //       _.map(stageOfBehavorialChanges, option => 
    //         <Select.Option key={option.value} value={option.value} >{option.label}</Select.Option>)
    //     }
    //   </Select>
    // }
  ],
  // rawIndex used to clear error class
  (!props.isWorkList &&
    (
      _.get(props, 'PESStatementDatasource.length') === 0 ?
        // patient profile shows empty placeholder if there is no PES statement
        [{ label: '', key: 'empty-placeholder', 
            render: () => <h4 style={{ marginTop: 10, width: '200%' }}>
              No active PES statement to view
            </h4> 
        }]
      :
        [{ label: '', key: 'profile-information-placeholder',
            render: () => <div 
              style={{ 
                width: '200%', marginBottom: canShowPES ? -10 : 20, 
                border: '1px solid lightgrey', padding: '0px 10px',
                userSelect: 'none'
              }}
            >
              <Icon type='info-circle'  style={{ fontSize: '1em', marginRight: 10 }}/>
              PES statements can only be created, edited, and resolved in the visit's Work List
            </div>
        }]
    )),
  ...!canShowPES ? 
    [[{
      label: '', key: 'pes-loading-indicator',
      render: () => <div 
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {/* mock up IHLoading */}
        <div className='spinner rainbow'></div>
      </div>
    }]] 
  :
    _.map(props.PESStatementDatasource || [], (PESStatementEl, rawIndex) => {
      const statementIndex = PESStatementEl.statementIndex;
      const formValues = props.form.getFieldsValue();

      const problemId = 
        _.get(formValues, `problem-${statementIndex}`, _.get(PESStatementEl, 'data.problem.optionId'));

      return PESStatementCreator({
          ...props,
          disabled: props.disabled || !props.isWorkList,
          ...PESStatementEl,
          rawIndex
        }, 
        PESStatementDatasource,
        ESOptions[problemId], // giving matching E & S option for a specific selected problem
        removePESStatement,
        fetchEtiologyAndSignSymptom
      );
    })
];

const NutritionInterpretationSelect = props => {
  const { 
    form, PESStatementDatasource, setPESStatementDatasource,
    isWorkList, setHasChanges, getRefetchQueryForMember
  } = props;
  const [canShowPES, setCanShowPES] = useState(false);
  const [ESOptions, setESOptions] = useState({});
  const [__, setESOptionsCount] = useState(_.keys(ESOptions).length);

  useEffect(() => {
    if(!_.isNil(PESStatementDatasource)){
      // populate ES Options for initial rendering
      const initializeESOptions = async () => {
        if(!_.get(PESStatementDatasource, 'length') && isWorkList) {
          // add an empty template by default, ONLY in WorkList
          // don't toggle draft mode
          addPESStatement(false);
        } else {
          await Promise.all(
            _.map(PESStatementDatasource, async ({ data }) => {
              const problemId = _.get(data, 'problem.optionId', '');
              // fetch E & S options for pre-selected problem
              // it prevents wrong display for pre-selected E and S
              if(problemId) await fetchEtiologyAndSignSymptom(problemId);
            }));
        }      
        setCanShowPES(true);
      }
      initializeESOptions();
    }
  }, [PESStatementDatasource])

  const addPESStatement = (setChange = true) => {
    const newPESStatementDatasource = [...PESStatementDatasource];
    const nextIndex = _.get(_.last(newPESStatementDatasource), 'statementIndex', -1) + 1;
    newPESStatementDatasource.push({
      statementIndex: nextIndex,
      data: {},
      created: true,
      removable: true
    }); // any PES created during visit is removable
    setPESStatementDatasource(newPESStatementDatasource);
    if(setChange) setHasChanges({ PESStatementData: newPESStatementDatasource });
  };

  const removePESStatement = async (statementIndex, pesId) => {
    let newPESStatementDatasource = [...PESStatementDatasource];
    if(pesId){
      await Client.mutate({
        mutation: editSinglePES,
        variables: {
          id: pesId,
          deleted: true
        },
        refetchQueries: getRefetchQueryForMember()
      }).catch(err => console.error(err));
    }
    newPESStatementDatasource = 
      _.filter(newPESStatementDatasource, d => d.statementIndex !== statementIndex);

    setPESStatementDatasource(newPESStatementDatasource);
    // don't need to toggle edit mode
    setHasChanges({ PESStatementData: newPESStatementDatasource });
  };

  const fetchEtiologyAndSignSymptom = problemId => {
    // dont fetch if E&S options are available
    if(ESOptions[problemId]) return;

    return Client.query({
      query: getESOptions,
      variables: { id: problemId }
      // no fetchPolicy to prevent double fetch for same pId
    }).then(({ data }) => {
      // dont fetch if E&S options are available
      if(ESOptions[problemId]) return;

      const options = _.get(data, 'getPesTemplate.options', []);
      // TODO: options should not be Nil, what if Nil
      
      _.forEach(options, ({id, category, value, options = []}) => {

        const generateSubOptions = category => {
          const subOptions = _.map(options, ({ id, category: subCat, value }) => {
            // double check category
            if(category === subCat){
              return { refId: shortenId(id), value };
            }
          });
          return _.filter(subOptions, sub => !_.isNil(sub));
        };

        const categoryFieldKey = categoryMap[category];
        const categoryFieldValues = 
          _.get(ESOptions, `${problemId}.${categoryFieldKey}`, []);

        categoryFieldValues.push({
          refId: shortenId(id),
          category,
          value,
          options: generateSubOptions(category)
        });

        _.set(ESOptions, `${problemId}.${categoryFieldKey}`, categoryFieldValues);
      }); 
      // mutate object, so it will not be delay when initially fetching
      setESOptions(ESOptions);
      setESOptionsCount(_.keys(ESOptions).length); // to trigger rendering manually
    }).catch(err => {
      console.error(err);
    });
  }

  return (
    <div 
      id={`nutrition-interpretation-select-wrapper-${isWorkList ? 'in' : 'not-in'}-work-list`} 
      className={canShowPES ? 'done-loading-pes' : 'loading-pes'}
    >
      { 
        formRenderer(
          form, 
          // to add new var/ function, function should belong to parent's scope
          () => getFormConfigs(
            props,
            PESStatementDatasource,
            canShowPES,
            ESOptions, 
            removePESStatement,
            fetchEtiologyAndSignSymptom
          )
        )
      }
      {
        isWorkList && 
        <Button
          className='add-click'
          type='link' icon='plus'
          onClick={addPESStatement}
          disabled={props.disabled}
        >
          Add a new PES statement
        </Button>
      }
    </div>
  );
};

NutritionInterpretationSelect.propTypes = {
  disabled: PropTypes.bool,
  isWorkList: PropTypes.bool.isRequired,
  canResolvePES: PropTypes.bool.isRequired,
  PESStatementDatasource: PropTypes.array,
  setPESStatementDatasource: PropTypes.func.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  problemOptions: PropTypes.array.isRequired,
  getRefetchQueryForMember: PropTypes.func.isRequired
}

export default Form.create({
  onValuesChange: (props, __, allFieldsValue) => props.setHasChanges(allFieldsValue)
})(NutritionInterpretationSelect);
