import { emailValidator2 } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'

export const schema = {
  email: {
    initialValue: '',
    required: true
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const getFormProps = ({ initData }) => {
  const itemList = [
    [
      {
        key: 'email',
        label: 'Email Address',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData.email,
        rules : [
          {required : isFieldsRequired.email, message : 'Email is required'},
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ],
      }
    ]
  ]
  return {
    vertical: true,
    itemList
  }
}
