export const followUpVisitRange = {
  withDiabetes: {
    min: {
      amount: 75,
      unit: 'days'
    },
    max: {
      amount: 112,
      unit: 'days'
    },
  },
  withoutDiabetes: {
    min: {
      amount: 75,
      unit: 'days'
    },
    max: {
      amount: 195,
      unit: 'days'
    },
  }
};

export const DIABETES_ICD_CODES = [
  {
    code: 'E08.',
    startWith: true,
  },
  {
    code: 'E09.',
    startWith: true,
  },
  {
    code: 'E10.',
    startWith: true,
  },
  {
    code: 'E11.',
    startWith: true,
  },
  {
    code: 'E13.',
    startWith: true,
  },
  {
    code: 'Z79.4',
    startWith: false,
  },
]
