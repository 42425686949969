import React, {useEffect} from 'react';
import _, { get,has } from 'lodash';
import '../style/index.scss';
import AssignToCTFormModal from "./AssignCTFormModal";
import { checkIsInRole} from '../../../lib/helpers/role-helpers';
import AssignToCTMessage from './AssignToCTMessage';
import { isNotShow } from '../helper'

const AssignCTInVisitHeader = (props)=>{
	const { showForm, setShowForm, patientComplexity, isEnrolled, setPatient, refetchUser, patient } = props;
	const defaultNotAssignedValue = 'Not Assigned';
	const DISPLAY_NOT_NECESSARY_VALUE = "Not Necessary";
	const assignedToRD = get(patient,'user.assignees.assignedToRD');
	const assignedToCA = get(patient,'user.assignees.assignedToCA');
	const assignedToRDNotNecessary = _.get(patient, 'user.assignees.assignedToRDNotNecessary');
	const assignedToCANotNecessary = _.get(patient, 'user.assignees.assignedToCANotNecessary');
	const assignedToRDFullName = assignedToRDNotNecessary ? DISPLAY_NOT_NECESSARY_VALUE : get(assignedToRD,'profile.fullName',defaultNotAssignedValue);
	const assignedToCAFullName = assignedToCANotNecessary ? DISPLAY_NOT_NECESSARY_VALUE : get(assignedToCA,'profile.fullName',defaultNotAssignedValue);
	const complexityLevel = _.get(patientComplexity, 'level','NA');
	const isCROrRDOrHC = checkIsInRole(['RD','CA','HC']);
	const openForm = ()=>{
		setShowForm(true);
	}
	// useEffect(()=>{
	//
	// },[assignedToRDFullName,assignedToCAFullName]);
	const assignedStatus = ()=>{
    const { curVisitInfo, healthConditions, remoteEnrollment, isEnrolled } = props;
	let count = [assignedToRD,assignedToCA].filter(r=>!r).length;
    const prevConditionsList = _.get(curVisitInfo, 'conditionsList');
	const notShowIcon = isNotShow(assignedToRDNotNecessary, assignedToCANotNecessary, assignedToRD, assignedToCA);
    const conditionsList = !_.isEmpty(prevConditionsList) ? prevConditionsList : _.get(remoteEnrollment, 'conditionsList');
		if(count>=1 && isCROrRDOrHC && !notShowIcon) {
			return <div className={'msgWrapper'}>
				<AssignToCTMessage 
          from='VISIT_POPUP' 
          isEnrolled={isEnrolled} 
          loadingDx={_.isUndefined(curVisitInfo)}
          conditionsList={conditionsList || {}} 
          healthConditions={healthConditions}
        />
				<div style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setShowForm(true)}}>Assign</div>
			</div>
		}
		return <div></div>
	}
	const assignees = ()=>{
		return<div>
			<span>Assigned CT members: RD/HC: {assignedToRDFullName} ; CA: { assignedToCAFullName}</span>
		</div>
	}
	const renderForm = ()=>{
		const memberId = get(patient,'user.id');
		if(showForm) {
			return <div><AssignToCTFormModal {...props} memberId={memberId} patient={patient}
			                            setShowForm={setShowForm}
			                            complexity={complexityLevel} isEnrolled={isEnrolled} setPatient={setPatient} refetchUser={refetchUser}/></div>
		}
		return '';
	}
	return <div>
			{assignees()}
			{assignedStatus()}
			{renderForm()}
		   </div>
}
export default AssignCTInVisitHeader;