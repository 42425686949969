import gql from 'graphql-tag';

export default gql`
  query getSignedFileDownload(
    $userId: EID!
    $fileKey: String!
    $type: FileUploadTypeEnum
  ) {
    getSignedFileDownload(userId: $userId, fileKey: $fileKey, type: $type) {
      fileKey
      link
    }
  }
`;
