// Imports
import React from 'react';

// Modules
import Nav from '../containers/NavContainer';
import MainModal from '../containers/MainModalContainer';
import LeftNav from '../containers/LeftNavContainer'
import TaskAssignmentBannerWrapper from '../../taskAssignment/containers/TaskAssignmentBannerWrapper';
// CSS
import '../css/theme.css';

const MainLayout = (props) => {
  const { nav, children, handleChangeOrg, handleChangePage, curPage} = props;
  const childrenCopy = {...children};
  let propCopy = {...childrenCopy.props};
  propCopy.curPage = curPage;
  propCopy.handleChangePage = handleChangePage;
  childrenCopy.props = propCopy;
  return (
    <div id='master'>
      {/* <TaskAssignmentBannerWrapper curPath={props.curPath} curPage = {curPage}/> */}
      <MainModal/>
      <LeftNav curPath={props.curPath} handleChangePage = {handleChangePage} curPage = {curPage}/>
      <Nav curPath={props.curPath} handleChangeOrg={handleChangeOrg} />
      <div>
      { childrenCopy }
      </div>
  </div>
  )
}

MainLayout.displayName = 'layout/components/MainLayoutComponent';

export default MainLayout
