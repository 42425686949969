import types from 'ProgramIncompleteModule/ProgramInfo/constants/ProgramInfoFormConstants'
import moduleCommonTypes from 'ProgramIncompleteModule/Common/constants/CommonConstants'
import moduleCommonActions from 'ProgramIncompleteModule/Common/actions/CommonActions'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
])

const goNext = () => {
  return (dispatch, getState) => {
    dispatch(moduleCommonActions.setTabsStep('careplan'))
    dispatch(moduleCommonActions.changePage(moduleCommonTypes.CAREPLAN_MAIN_PAGE))
  }
}

export default {
  ...commonActionsObject,
  goNext
}
