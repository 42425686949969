import convertor from "@ihealth/convertor";
import i18nMap from "../../I18N";
import yearOfDiagnoseMap from "../../patientList/registerNew/constants/yearOfDiagnose";
import moment from 'moment-timezone';
import { decryptRole } from '../../../lib/utils';
import { checkIfPatientIsDiabetic } from '../../utils/helpers/icdCodeHelpers';
import roleMap from "../../VideoChat/constant/roleMap";
import getUserOrg from "../../layout/helper/getUserOrg";
import { VITALS_ORDER, FOLLOW_UP_VISIT_RANGE } from '../../../lib/constants';
import { typeToDuration } from '../constants/index';
import helper from "../../taskAssignment/helper";
import { getCareTeamMemberAvailabilityAPI } from '../API/index';

const getMutateVariable = (data) => {
    const appRoles = JSON.parse(sessionStorage.getItem('appRoles'));
    const phone = []
    let address = null;
    let canUseForLogin = _.get(data,'canUseForLogin','true');
    if (data.mobilePhone) {
        phone.push({
            countryCode: '+1',
            number: parseInt(data.mobilePhone, 10),
            type: 'MOBILE',
            private: data.isPrivateNumber,
            canUseForLogin: (canUseForLogin==='true')
        })
    }
    if (data.homePhone) {
        phone.push({
            countryCode : '+1',
            number : parseInt(data.homePhone, 10),
            type : 'HOME'
        })
    }
    if (data.streetName || data.streetNumber || data.city || data.state) {
        address = [
            {
                streetName: data.streetName,
                streetNumber: data.streetNumber,
                city: data.city,
                state: data.state,
                country: data.country,
            }
        ]
        if (data.unit) {
            address[0].unit = data.unit
        }
        if (data.floor) {
            address[0].floor = data.floor
        }
        if (data.postCode) {
            address[0].postCode = data.postCode
        }
    }
    const memberProfile = {
        // birthday: data.DOB.format('YYYY-MM-DD')
        birthday: moment(data.DOB).format('YYYY-MM-DD'),
        isSelfEnrolled: _.get(data, 'isSelfEnrolled', false)
    };
    const commonProfile = {
        firstName: data.firstName,
        lastName: data.lastName,
        nickname: data.nickname
    }
    if (data.gender) {
        commonProfile.gender = data.gender
    }
    if (data.race) {
        memberProfile.race = data.race
    }
    if (data.race1) {
      memberProfile.race1 = data.race1
    }
    if (data.language) {
        memberProfile.language = data.language
    }
    if (data.nationality) {
        memberProfile.nationality = data.nationality
    }
    if (data.height || data.height_inches) {
        memberProfile.height = {
            value: convertor.height(Number(convertFeetToInches(data.height)) + Number(data.height_inches), 'inch', 'cm'),
            unit: i18nMap.get('measure.units_height_cm')
        }
    }
    if (data.weight) {
        memberProfile.weight = {
            value: convertor.HS(data.weight, 'lbs', 'kg'),
            unit: i18nMap.get('measure.units_weight_kg')
        }
    }
    if(data.doctorId){
        memberProfile.doctorId = data.doctorId;
    }
    //added insurance provider field
    if(data.insuranceProvider){
        memberProfile.insuranceProvider = data.insuranceProvider;
    };
    memberProfile.yearOfDiagnose = [];
    const keys = Object.keys(yearOfDiagnoseMap);
    _.forEach(keys,(k)=>{
        if(data[`${k}`]){
            memberProfile.yearOfDiagnose.push({
                condition:yearOfDiagnoseMap[`${k}`],
                diagnosedDate:data[`${k}`]
            })
        }
    });

    if(memberProfile.yearOfDiagnose.length==0){
        _.omit(memberProfile,'yearOfDiagnose');
    }

    const result = {
        //to fix: not hard-code organizationId
        // organizationId: btoa('5841068ad7978436eeeca3ee'), // 'NTg0MTA2OGFkNzk3ODQzNmVlZWNhM2Vl'
        // role: 'MEMBER',
        role: appRoles['MEMBER:Member'],
        address,
        identification: {
            type: data.nationtype,
            value: data.identificationValue
        },
        commonProfile,
        memberProfile
        // caregiver: caregivers
    }

    if (data.email) {
        result.email = data.email
    }
    if(data.isTestUser) {
        result.isTestUser = data.isTestUser;
    }
    if (phone.length > 0) {
        result.phone = phone
    }
    if (data.username) {
        result.username = data.username;
    }
    return result
}

//trim all variables in nested object, not working for values in array;
const trimAllValues = (variable,path,src)=>{
    if(typeof src=='string'){
        _.set(variable,path,src.trim());
    }
    else{
        if(!_.isEmpty(src)){
            const keys = Object.keys(src);
            if(path!=''){
                keys.map(key=>trimAllValues(variable,`${path}.${key}`,src[key]))
            }
            else{
                keys.map(key=>trimAllValues(variable,`${key}`,src[key]))
            }
        }
    }
    return variable;
}

const isPatientInactive = (program, lastMeasuredAt) => {
  const today = moment(); 
  const start_date = moment(lastMeasuredAt);
  const programStartDate = moment(_.get(program, 'startDate'));
  const programDuration = today.diff(programStartDate, 'days');
  const noMeasurementWithin14Days = today.diff(start_date, 'days') > 14;
  return programDuration > 44 && (!lastMeasuredAt || noMeasurementWithin14Days);
}

const getTypeAndStatus = (programs, lastMeasuredAt) => {
    if(!programs ||  programs.length === 0) {
        return {
            type: '-',
            status: 'General'
        }
    }

    let string = '';
    for (let i = 0; i < programs.length; i++) {
        const program = programs[i];
        if (program.status === 'STARTED') {
            if(program.tasks){
              let vitalsInOrder = _.map(program.tasks, p => p.type);
              vitalsInOrder = _.intersection(VITALS_ORDER, vitalsInOrder);
              string = vitalsInOrder.join('/');
            }
            if(isPatientInactive(program, lastMeasuredAt)){
              return {
                type: string,
                status: '2-w inactive'
              }
            }
            return {
                type: string,
                status: 'Enrolled'
            };
        }
        if (i === programs.length - 1 && program.status === 'DISCHARGED'){
            return {
                type: '-',
                status: 'Discharged'
            };
        }
    }

    return {
        type: '-',
        status: 'Potential'
    }
}

const getTypeAndStatusFromVisit = (visitInfo, lastMeasuredAt) => {

    const vitalTypes = visitInfo.vitalTypes;
    if (visitInfo.enrolledProgramStatus === 'DISCHARGED'){
        return {
            type: '-',
            status: 'Discharged'
        };
    }
    if(!vitalTypes || vitalTypes.length === 0) {
        return {
            type: '-',
            status: 'General'
        }
    }

    let string = vitalTypes[0];
    if(vitalTypes.length > 1){
        string = vitalTypes.join('/');
    }

    if(lastMeasuredAt){
        const start_date = moment(new Date(lastMeasuredAt));
        const end_date = moment();
        if (end_date.diff(start_date, 'days') > 14) {
            return {
                type: string,
                status: 'Inactive'
            }
        }
    }

    return {
        type: string,
        status: 'Enrolled'
    }
}

const handleConditionsList = (conditionsList) => {
    if(!conditionsList){
        return null;
    }
    const list = {};
    for (const item of conditionsList) {
        list[item] = true;
    }
    return list;
}

const conditionsListToCheckbox = (conditionsList) => {
    if(!conditionsList){
        return null;
    }
    const array  = [];
    for(const item in conditionsList){
        if(item === '__typename'){
            continue;
        }
        if(conditionsList[item]){
            array.push(item);
        }
    }
    return array;
}

const combineDateHourAndMin = (date,hour,min)=>{
    return moment(date).hour(hour).minute(min).second(0);
}

const checkIsMAOrMD = () => {
    const currentRole = decryptRole();
    if(roleMap[currentRole]=='MA'||roleMap[currentRole]=='Doctor'){
        return true;
    } else {
        return false;
    }
}

const checkIsCAOrRD = () => {
  const currentRole = decryptRole();
  if(roleMap[currentRole]=='CA'||roleMap[currentRole]=='RD'||roleMap[currentRole]=='HC'){
      return true;
  } else {
      return false;
  }
}

const getRole = () => {
    const currentRole = decryptRole();
   return roleMap[currentRole];
}

const convertDateFormat = (dateString) => {
    if(_.includes(dateString,'-')){
        const array = dateString.split('-');
        const newString = array[1] + '/' + array[2] + '/' + array[0];
        return newString;
    }
    return dateString;
}

const convertDateFormatToDB = (dateString) => {
    if(dateString){
        const array = dateString.split('/');
        let newString = null;
        if(dateString.length === 10) {
            newString = array[2] + '-' + array[0] + '-' + array[1];
        } else {
            newString = array[0] + '-' + array[1];
        }
        return newString;
    }
    return  dateString;
}

const NON_INITIAL_TYPE_ENUM = {
    'FOLLOW_UP': 'Follow-up',
    'ADDITIONAL': 'Additional',
    'TECH':'Tech'
};

const INITIAL_TYPE_ENUM = {
    'INIT': 'Initial',
}

const hourMap = [
    '07','08','09','10','11','12','13','14','15','16','17','18'
];
const hourMap2 = [
    '6','7','8','9','10','11','12','13','14','15','16','17','18'
];
const hourMapIn1224Format = hourMap2.map(h=>({
    '12hour': `${parseInt(h)}` > 12 ? `${parseInt(h)-12}` : h,
    '24hour': h
}));

const patientHoursMap = Array.from(Array(24).keys());

const patientHoursIn1224Format = patientHoursMap.map(h=>({
    '12hour': h > 12 ? (h-12).toString() : h.toString(),
    '24hour': h.toString()
}));
const minMap = ['00','15','30','45'];

// const visitRoles = ['MD', 'RD', 'CA'];
const VISIT_ROLES = {
    'MA': 'MA',
    'MD': 'MD',
    'RD': 'RD',
    'CA': 'CA'
}

const conditionsList = ['HLD', 'HTN', 'DM', 'CKD', 'Obesity', 'PreDM', 'COPD', 'ESRD_Dialysis'];
const getTimezoneObj = ()=>{
    const { timezone } = getUserOrg();
    let localTZ = moment.tz.guess();
    let local = moment.tz(localTZ);
    let remote = local.clone();
    let local_offset = local.zone();
    let remote_offset = remote.tz(timezone).zone();
    let isAhead = remote_offset - local_offset < 0;
    let sign = isAhead ? 1 :-1;
    let diffInHours = Math.abs(local_offset-remote_offset)/60;

    return {
        sign,
        diffInHours,
    }
}

const localToRemoteHour = (hour)=>{
    const  { diffInHours,sign } = getTimezoneObj();
    return (Number(hour) + sign*diffInHours+24)%24;
}

const remoteToLocalHour = (hour)=>{
    const  { diffInHours,sign } = getTimezoneObj();
    return (Number(hour) - sign*diffInHours+24)%24;
}

// @params
// lastVisit: last init/followup visit regardless of checkoutAt
// healthConditions: list of non-billable health conditions
// @return
// from, to: instance of moment
const getFollowUpWindowTime = (lastVisit = {}, healthConditions) => {
  const lastVisitDate = moment(lastVisit.appointmentAt);
  const isDiabetic = checkIfPatientIsDiabetic(healthConditions);
  const key = isDiabetic ? 'withDiabetes' : 'withoutDiabetes';
  const range = FOLLOW_UP_VISIT_RANGE[key];
  return {
    standardFormat: 'MM/DD/YYYY',
    from: lastVisitDate.clone().add(range.min.amount, range.min.unit),
    to: lastVisitDate.clone().add(range.max.amount, range.max.unit)
  };
};
const onChangeAssigneeOrVisitTime = (visitType,from,cb,visitId)=>{
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const teamsFromLoginUser = loginUser.team||[];
    const assigneeIds = helper.getMembersByRole(teamsFromLoginUser,['Medical Assistant','PROVIDER:Nurse','Nurse','Health Coach']).map(m=>m.id);
    const visitDuration = typeToDuration[visitType];
    const to = moment(from).add(visitDuration,'minutes').add(-1, 'minute').valueOf();
    if(assigneeIds&&from&&to) {
        const _from = moment(from).add(1, 'minute').valueOf();
        getCareTeamMemberAvailabilityAPI({ memberIds: assigneeIds,from: _from,to,excludeVisitId:visitId}).then(res=>{
            const getCareTeamMemberAvailability = _.get(res,'data.getCareTeamMemberAvailability',[]);
            cb(getCareTeamMemberAvailability);
        })
    }
}
const validateAssigneesAvailable = (assigneesAvailabilityMap,assigneeId)=>{
        return assigneesAvailabilityMap[assigneeId]
}
const needReValidateAvailability = (changedField)=>{
    const fieldsIfChanged = ['visitRoles','type','date','min','hour'];
    return _.includes(fieldsIfChanged,changedField);
}

const reValidAssignees = (form)=>{
    form.validateFields(['assignedToCA','assignedToRD','assignedToHC']);
}

const getAppointmentToTime = (combinedStartTime, visitType) => {
  const visitDurationInMin = _.get(typeToDuration,`${visitType}`, 30);
  return combinedStartTime + visitDurationInMin * 60000;
}

// parse to match with Visit.assignees type
const parsePatientAssignees = (assignees) => {
  const parsed = Object.assign({}, ..._.map(assignees, (v, k) => {
    if(v && v.id) {
      return {
        [k]: {
          providerId: v.id,
          name: _.get(v, 'profile.fullName') || ''
        }
      };
    }
    return {};
  }));
  return parsed;
};

// Visit.assignees => field value assignees: { assignedToXX: id }
const getAssigneeFieldValue = (assignees) => {
  const fieldValues = Object.assign({}, ..._.map(assignees, (v, k) => {
    if(v && v.providerId) {
      return { [k]: v.providerId };
    }
    return null;
  }));
  return fieldValues;
}

// individual option and list should share at least some same properties to support Select
const addMissingParticipantOption = (option, optionKey, list, renderCustomLabel) => {
  const optionIndex = _.findIndex(list, { [optionKey]: _.get(option, optionKey) });
  if(optionIndex > -1)
    return list;

  if(renderCustomLabel) {
    option.CustomLabel = renderCustomLabel(option.fullName);
  }
  return [option, ...list];
}

const isNoShowApt = (selectedVisit) => {
    return selectedVisit && selectedVisit.status === 'NO_SHOW';
}

export const helpers = {
    getMutateVariable,
    trimAllValues,
    getTypeAndStatus,
    handleConditionsList,
    conditionsListToCheckbox,
    combineDateHourAndMin,
    getTypeAndStatusFromVisit,
    checkIsMAOrMD,
    checkIsCAOrRD,
    convertDateFormat,
    convertDateFormatToDB,
    NON_INITIAL_TYPE_ENUM,
    INITIAL_TYPE_ENUM,
    hourMap,
    hourMapIn1224Format,
    patientHoursIn1224Format,
    minMap,
    VISIT_ROLES,
    conditionsList,
    getTimezoneObj,
    localToRemoteHour,
    remoteToLocalHour,
    getRole,
    getFollowUpWindowTime,
    onChangeAssigneeOrVisitTime,
    validateAssigneesAvailable,
    needReValidateAvailability,
    reValidAssignees,
    getAppointmentToTime,
    parsePatientAssignees,
    getAssigneeFieldValue,
    addMissingParticipantOption,
    isNoShowApt,
}
