import { React, _, IHInput } from 'ihcomponent';

export default (props) => {
  const { answer, onChange, isPreview } = props;
  const value = JSON.parse(_.get(answer, 'content') || '""');

  const onInputChange = ({ target }) => {
    onChange({ content: JSON.stringify(target.value) });
  };

  return (
    <div className="input-long">
      <IHInput type="textarea" value={value} disabled={isPreview} onChange={onInputChange} />
    </div>
  );
};
