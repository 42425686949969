import Client from 'libModule/gqlClient';
import { userForChat, userForAvatar, patientForAvatar } from 'graphqlModule/user';
import getUserChatInfo from 'graphqlModule/getUserChatInfo';
import getIfIsCHSQuery from 'graphqlModule/getIfIsCHSQuery';
import getUserInfoCHS from 'graphqlModule/getUserInfoCHS';
import PubNubReact from 'pubnub-react';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers';

import CHS from './CHS';

/****** Our API ******/
const getUserProfile = (uuid) => {
  const variables = { id: uuid };
  return Client.query({
    query: userForChat,
    variables: {...variables},
    fetchPolicy: 'network-only'
  });
};

const getIfIsCHS = (userId)=>{
    return Client.query({
        query: getIfIsCHSQuery,
        variables: { userId },
        fetchPolicy: 'network-only'
    })
}
const apiGetUserChatInfo = (userId,isCHS) => {
  const query = isCHS ? getUserInfoCHS : getUserChatInfo;
  return Client.query({
    query: query,
    variables: { userId },
    fetchPolicy: 'network-only'
  }).then(async res => {
    const finalRes = { 
      data: _.get(res, 'data'),
      channelGroupsArr: [_.get(res, 'data.getUserChatInfo.channelGroup.name') || _.get(res, 'data.getUserChatInfo.channelGroupName')]
    };

    if(!isCHS) 
      return finalRes;

    const authKey = _.get(finalRes, 'data.getUserChatInfo.authKey');
    try {
      const notificationRes = await getNotificationChannelGroup({ authKey }) || {};
      if(notificationRes.error) {
        throw new Error(notificationRes.error);
      } else if (notificationRes.success) {
        // replace with group name from CHS notification
        _.set(finalRes, 'channelGroupsArr', [notificationRes.data.group]);
      } else {
        throw new Error('No response');
      }
    } catch (error) {
      console.error('CHS publish failed to initiate', error);
    } finally {
      return finalRes;
    }
  });
};

const getUserAvatar = id => {
  const variables = { id };

  return Client.query({
    query: userForAvatar,
    variables: { ...variables },
    fetchPolicy: 'cache-first',
  });
};

const handleApiChange =(channel, useChs)=>{
  if (useChs){
      return channel.split('-')[1];
  }
  return channel;
}

const checkAndAddUser =  (publisher,newUserMap,avatarError)  =>{
  if (publisher.split('-')[0] !== 'team') {

      // if patient sent chat message, check to see if they are in userMap
      try {

          if (typeof COMMON_HELPERS.checkIfEncoded(publisher) === 'boolean' && COMMON_HELPERS.checkIfEncoded(publisher)) {

              const decodedPatientId = atob(publisher).split(':')[1];

              // if patient is not in userMap
              if (!newUserMap[decodedPatientId]) {
                return  API.getUserProfile(publisher)
                      .then(res => {
                          const userObj = {
                              firstName: res.data.user ? res.data.user.profile.firstName : '',
                              lastName: res.data.user ? res.data.user.profile.lastName : '',
                              avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                          };
                          newUserMap[decodedPatientId] = userObj;
                      })
                      .catch(err => console.log('Error fetching user profiles: ', err));
              }
          } else if (!newUserMap[publisher]) {

             const encodedPatientId = btoa(`accounts:${publisher}`);
             return  API.getUserProfile(encodedPatientId)
                  .then(res => {
                      const userObj = {
                          firstName: res.data.user ? res.data.user.profile.firstName : '',
                          lastName: res.data.user ? res.data.user.profile.lastName : '',
                          avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                      };
                      newUserMap[publisher] = userObj;
                  })
                  .catch(err => console.log('Error fetching user profiles: ', err));
          }
          else if (newUserMap[publisher] && avatarError) {
              const encodedPatientId = btoa(`accounts:${publisher}`);
              return API.getUserProfile(encodedPatientId)
                  .then(res => {
                      const userObj = {
                          firstName: res.data.user ? res.data.user.profile.firstName : '',
                          lastName: res.data.user ? res.data.user.profile.lastName : '',
                          avatar: res.data.user.profile.avatar && res.data.user.profile.avatar.link ? res.data.user.profile.avatar.link : '/image/default_avator.png',
                      };
                      newUserMap[publisher] = userObj;
                  })
                  .catch(err => console.log('Error fetching user profiles: ', err));
          }
      } catch (err) {
          console.log('Err: ', err, ' Publisher on message not valid: ', publisher);
      }
  }
}

const getNotificationChannelGroup = (params) => {
  return CHS.request(CHS.URL.notificationChannelGroup, params);
};

const getPaginatedChannels = (params={})=>{
    const { unread } = params;
    if( !unread ) {
      params.teamIds = CHS.currentUserTeamIds;
    }
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         // 'Content-Type':'text/plain',
    //         'x-session-token': CHS.sessionToken,
    //         // 'Accept':'*/*'
    //     },
    //     // referrerPolicy: 'no-referrer',
    //     // credentials: 'same-origin',
    //     body: JSON.stringify(param)
    // };
    return CHS.request(CHS.URL.paginatedChannels, params);
}

const getChatHistoryFromChs = params =>{
  return CHS.request(CHS.URL.history, {
    ...params, 
    origin: 'new', 
    ignoreACK: true,
  });
}

const tagMessage = (params) => {
  if(_.isEmpty(params)) 
    return console.warn('warn tagMessage: param is empty');

  return CHS.request(CHS.URL.tag, params);
};

const markTagMessage = (asRead, params) => {
  if(_.isEmpty(params) || !params.messageId) 
    return console.warn('warn markTagMessage: param is empty or invalid');

  const markTagURL = asRead ? CHS.URL.tagRead : CHS.URL.tagUnread;

  return CHS.request(markTagURL, params);
};

const publishMessage = (channel, payload) => {
  if(!channel)
    return console.warn('warn publishMessage: No channel');

  const params = {
    channel,
    payload: JSON.stringify(payload)
  };
  return CHS.request(CHS.URL.publish, params);
};

/****** PubNub API ******/

let pubNub = null;

const getPubNub = () => {
  return pubNub;
}

const apiInitializePubNub = (publishKey, subscribeKey, authKey, userId) => {
    pubNub = new PubNubReact({
      publishKey,
      subscribeKey,
      authKey,
      logVerbosity: false, // use for debugging
      ssl: true,
      uuid: userId,
      presenceTimeout:300, // used to suppress presence heartbeats
      heartbeatInterval:0,
      restore: true // prevent missing messages
    });
  }

const subscribePubNub = (channelGroupsArr) => {
  const pubnubIns = getPubNub();
  if(!pubnubIns)
    return console.error('Failed to getPubNub');

  const channelGroups = _.filter(channelGroupsArr, g => !!g);
  if(!channelGroups.length)
    console.warn('subscribe to no channel');
  
  try {
    const variables = {
      channelGroups: channelGroups, // team notification channels
      triggerEvents: true
    };
    pubnubIns.subscribe(variables);
  } catch (error) {
    console.error('Failed to subscribe Pubnub', error);
  }
};

export const API = {
  getUserProfile,
  getUserAvatar,
  apiGetUserChatInfo,
  apiInitializePubNub,
  getPubNub,
  subscribePubNub,
  checkAndAddUser,
  getChatHistoryFromChs,
  handleApiChange,
  getPaginatedChannels,
  getIfIsCHS,
  tagMessage,
  markTagMessage,
  getNotificationChannelGroup,
  publishMessage
};
