/**
 * Created by zizhangai on 2/8/17.
 */
import React, { Component } from 'react'
import { IHButton } from 'ihcomponent'

// note: ref is not supported in stateless component
class ReportModalFooter extends Component {
  render() {
    const { onCancel, onSubmit, err } = this.props
    return (
      <div  className="modal-footer">
        <IHButton label="Preview" onClick={onSubmit.bind(this)} disabled={err ? true: false} type="primary" ref="btn_report_submit"/>
        <IHButton label="Cancel" onClick={onCancel} />
      </div>
    )
  }
}
export default ReportModalFooter
