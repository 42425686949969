import { constants } from 'libModule/utils';

export default constants('ProgramEdit/VitalAlert', 'AlertList', [
  'FORM_FIELD_CHANGE',
  'INIT_DATA',
  'RESET_FORM',
  'RESET',
  'TOGGLE_ALERT_FORM',
  'ADD_TAB',
  'REMOVE_TAB',
  'ON_TAB_CHANGE',
])
