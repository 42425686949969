import types from 'ProgramNewModule/ProgramInfo/constants/ProgramInfoFormConstants'
import { commonReducer } from 'libModule/helpers/reducer-helpers'


const initState = {
  isButtonDisabled: false,
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.SET_BUTTON_STATE:
      return {
        ...state,
        isButtonDisabled: action.payload
      }
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
