import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment'
import * as d3 from 'd3'
import _ from 'lodash'
import LineChart from './LineChartComponentRevised'
import LineChartBG from './LineChartComponentBGDetail'
import { alertColor } from '../constants/VitalListConstants'


const AllTypes = [
  'Before Breakfast', 'After Breakfast',
  'Before Lunch', 'After Lunch',
  'Before Dinner', 'After Dinner',
  'Before Supper', 'After Supper'
]
const ColorMap = {
  'Before Breakfast': 'rgba(120, 68, 194, 1)',
  'After Breakfast': '#7845c2',
  'Before Lunch': 'rgba(32, 159, 244, 1)',
  'After Lunch': '#21a0f4',
  'Before Dinner': 'rgba(194, 209, 54, 1)',
  'After Dinner': '#c2d136',
  'Before Supper': 'rgba(26, 207, 189, 1)',
  'After Supper': '#19cfbe'
}
// const ColorMap = {
//   'Before Breakfast': '#21a0f4',
//   'After Breakfast': '#7845c2',
//   'Before Lunch': '#21a0f4',
//   'After Lunch': '#7845c2',
//   'Before Dinner': '#21a0f4',
//   'After Dinner': '#7845c2',
//   'Before Supper': '#21a0f4',
//   'After Supper': '#7845c2'
// }
const getValueBGDetailed = (data) => {
  const arr = []
  for (let i= 0; i < AllTypes.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (data[j].hasOwnProperty(AllTypes[i])) {
        arr.push(AllTypes[i])
        break
      }
    }
  }
  return arr
}
const returnValidBGDetailed = (data) => (
  data.filter(d => d.taskStatus === 'COMPLETED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      ...d,
      schedule: {
        ...d.schedule,
        beforeMeal: d.measure.beforeMeal,
        mealType: d.measure.mealType === 'SNACK'? 'SUPPER':d.measure.mealType
      }
    })).map(d => (d.schedule.beforeMeal)? ({
      [`Before ${d.schedule.mealType.slice(0, 1).toUpperCase() + d.schedule.mealType.slice(1).toLowerCase()}`]: d.blood_glucose.value,
      date: d.date,
      datum: d
    }) : ({
      [`After ${d.schedule.mealType.slice(0, 1).toUpperCase() + d.schedule.mealType.slice(1).toLowerCase()}`]: d.blood_glucose.value,
      date: d.date,
      datum: d
    }))
)

const returnValidBGAggregate = (data) => {
  let aggregateData = data.filter(d => d.taskStatus === 'COMPLETED')
        .map(d => ({
              ...d,
              dateStr: moment(d.date).format('MMM-DD-YYYY')
        }))
  aggregateData = _.groupBy(aggregateData, 'dateStr')
  const aggregateResult = []
  Object.keys(aggregateData).forEach(dateStr => {
    const beforeMeals = [], afterMeals = []
    let unit = null
    aggregateData[dateStr].forEach(d => {
      if (d.measure.beforeMeal) beforeMeals.push(d.blood_glucose.value)
      else afterMeals.push(d.blood_glucose.value)
      if (!unit) unit = d.blood_glucose.unit
    })
    aggregateResult.push({
      'After Meals': afterMeals.length>0? d3.mean(afterMeals) : null,
      'Before Meals': beforeMeals.length>0? d3.mean(beforeMeals) : null,
      'id': dateStr,
      unit,
      date: moment(dateStr, 'MMM-DD-YYYY').add(12, 'h').toDate()
    })
  })

  return [].concat(aggregateResult.map(d => ({...d, key: 'Before Meals'})).filter(d => !!d['Before Meals']))
    .concat(aggregateResult.map(d => ({...d, key: 'After Meals'})).filter(d => !!d['After Meals']))
}
const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const filter = (datum, key) => !!datum[key]

const setFillColorBG = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (!!key) {
    if (key.slice(0, 1) === 'B') return 'white'
    else return ColorMap[key]
  }
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity && d.datum.severity !== 'NORMAL')? alertColor[d.datum.severity] : defaultFill
}
const setFillColorBGAggregate = (d, key) => {
  let defaultFill = alertColor.NORMAL
  if (d.datum && d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  if (!!key) {
    defaultFill = ['#0099ff', '#642bb6'][_.findIndex(['before meals', 'after meals'], d => d === key)]
  }
  return defaultFill
}
const setRadius = (d) => {
  const { alert, severity } = d.datum
  if (
    // !!alert &&
    !!severity) {
    if (
      // alert === true &&
      severity !== 'NORMAL') {
      return 3
    }
  }
  return 3
}
const formatTooltipBG = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const { measure: { mealType, beforeMeal } } = d.datum
  const mealInfo = beforeMeal? `before ${mealType.toLowerCase()}`:`after ${mealType.toLowerCase()}`
  const { blood_glucose: bg } = d.datum
  const bgUnit = bg? bg.unit : 'mmHg'
  return `${bg.value} ${bgUnit} ${mealInfo}`
}
const formatTooltipBGAggregate = (d) => {
  if (d.datum && d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const beforeReading = d['Before Meals']? d3.format('.2f')(d['Before Meals']) : '--'
  const afterReading = d['After Meals']? d3.format('.2f')(d['After Meals']) : '--'
  return `${afterReading}/${beforeReading} ${d.unit}`
}
const BGChartComponent = ({ data, range, mode, thresholds_beforeMeal, thresholds_afterMeal }) => {
  switch (mode) {
    case 'Detailed': {
      const chartData = returnValidBGDetailed(data)
      const missed = returnMissed(data)
      const setFillColor = setFillColorBG
      const formatTooltip = formatTooltipBG
      const value = getValueBGDetailed(chartData)
      const lineColor = value.map(d => ColorMap[d])
      const p = {
        data: chartData,
        missed,
        value,
        lineColor,
        setRadius,
        setFillColor,
        formatTooltip,
        filter,
        thresholds_beforeMeal,
        thresholds_afterMeal,
        range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
      }
      return <div style={{minHeight: '350px'}}><LineChartBG {...p} /></div>
    }
    case 'Aggregate': {
      const chartData = returnValidBGAggregate(data)
      const missed = returnMissed(data)
      const setFillColor = setFillColorBGAggregate
      const formatTooltip = formatTooltipBGAggregate
      const p = {
        data: chartData,
        missed,
        value: ['Before Meals', 'After Meals'],
        lineColor: ['#0099ff', '#642bb6'],
        setFillColor,
        formatTooltip,
        thresholds: {
          'Before Meals': thresholds_beforeMeal,
          'After Meals': thresholds_afterMeal
        },
        range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
      }
      return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
    }
    default:
      return null

  }
}

BGChartComponent.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array,
  mode: PropTypes.string
}

export default BGChartComponent
