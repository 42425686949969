import gql from 'graphql-tag';
import { PROVIDER_LIST_COUNT_MAX } from '../../lib/constants';
import { InsuranceProviderType } from './schema/InsuranceProvider';

const insuranceProviderList = gql`
  query getInsuranceProviderList(
    $match: String,
    $filters: InsuranceProviderFilters
    $page: Int, 
    $count: Int = ${PROVIDER_LIST_COUNT_MAX}
  ) {
    insuranceProviderList(
      search: { match: $match }
      filters: $filters
      page: $page,
      count: $count
    ) {
      data {
        ${InsuranceProviderType}
      }
      pageInfo {
        total
      }
    }
  }
`;

export default insuranceProviderList;