// Reducer
import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from '../component/formData/organizationCreateForm';
import * as createAction from '../action/organizationCreateAction'
/*

**************************************************
  Reducer
**************************************************
*/
// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initState = {
 initData,
 fieldsValue: initFieldsValue,
 isFormValid: calcFormStatus(initFieldsValue, isFieldsRequired),
 isFieldsRequired,
 isFormSaved: true,
 isSubmitting: false,
}
const actionTypes=createAction.actionTypes;

export default (state = initState, action) => {
 switch(action.type) {
   case actionTypes.INIT_DATA:
     return {
       ...state,
       initData: action.initData
     }
   case actionTypes.FORM_SAVED:
     return {
       ...state,
       isFormSaved: true,
     }
   case actionTypes.FORM_FIELD_CHANGE:
     const newFieldsValue = {
       ...state.fieldsValue,
       ...action.fieldsValue
     }
     return {
       ...state,
       fieldsValue: newFieldsValue,
       isFormValid: calcFormStatus(newFieldsValue, isFieldsRequired),
       isFormSaved: false
     }
   case actionTypes.RESET_FORM:
     return initState
   case actionTypes.SET_SUBMITTING:
     return {
       ...state,
       isSubmitting: action.isSubmitting
     }
   default:
     return state
 }
}