import MonthlyReviewSummarySchema from './MonthlyReviewSummary';

const miniUserWithProfile = `
  id
  profile {
    fullName
  }
`;

const SignOffTaskSchema = `
  id
  readDate
  signOffDate
  signOffBy {
    id
    profile {
      fullName
    }
  }
  comment
  tasks {
    ...on SmartAlert {
      id
      alertLevel
      alertCategory: category
      createdAt
      display
      alertReason: reason
      taskType
      providerNotes {
        id
        content
        createdBy { id, fullName }
        createdAt
      }
    }

    ...on Visit {
      id
      appointmentAt
      checkoutAt
      visitType: type
      updatedAt
    }
    
    ...on ProviderNote {
      id
      providerCategory: category
      createdAt
      createdBy { ${miniUserWithProfile} }
      content
    }

    ...on FoodLog {
      id
      mealType
      note
      images {
        link
        thumbnailLink
      }
      uploadedAt
      vitalTypes
      createdAt
      rating {
        star
        category {
          carb
          fat
          protein
          vegetable
          fruit
        }
      }
      comments {
        text
        originalText
        commentedBy { ${miniUserWithProfile} }
        commentedAt
      }
    }

    ...on TaskAssignment {
      id
      createdAt
      dueDate
      assignedTo { ${miniUserWithProfile} }
      taskReason: reason
      resolvedNote {
        note
        resolvedBy { ${miniUserWithProfile}   }
        resolvedAt
      }
      createdBy { ${miniUserWithProfile} }
      priorityLevel
      history {
        note
        assignedTo { ${miniUserWithProfile} }
        assignedBy { ${miniUserWithProfile} }
        assignedAt
      }
    }

    ...on MonthlyReview {
      id
      note
      createdAt
      dueDate
      goals {
        condition
        status
        value
        vital
      }
      type
      updatedAt
      reviewedBy { ${miniUserWithProfile} }
      previousVitals {
        vital
        count
      }
      previousGoals {
        conditionEnum
        goalEnum
        meetingStatus
      }
      preSummary {
        BG {
          ${MonthlyReviewSummarySchema}
        }
        BP {
          ${MonthlyReviewSummarySchema}
        }
        PO {
          ${MonthlyReviewSummarySchema}
        }
        TM {
          ${MonthlyReviewSummarySchema}
        }
        HS {
          ${MonthlyReviewSummarySchema}
        }
      }
    }

    ...on SignOffVersionHistory {
      type
      history {
        id
        visit {
          id
        }
        modifiedHistory {
          date
          itemsUpdated
        }
      }
    }

    ...on SignOffChatHistory {
      from
      to
      channel
    }
  }
`;

export default SignOffTaskSchema;
