import React, { useState, useMemo, createContext, useContext } from 'react';
import reduxStore from '../../../lib/reduxStore';
import { setMonthlyReviewType } from '../../workStation/action/index';
import { helpers } from '../../visitNewWorkFlow/helper';
import I18N from '../../I18N';


const MonthlyReviewContext = createContext(null);
export const wrapMonthlyReviewContext = (Component) => {
  return (props) => {
    const [systemGenerateMonthlyReviewData, setSystemGenerateMonthlyReviewData] = useState(undefined);
    const [monthlyReviewData, setMonthlyReviewData] = useState({});
    const role =  helpers.getRole();
  
    const context = useMemo(() => {
      const MRConstants = I18N.get(`monthlyReviewPlan.manualMonthlyReview.${role}`)
      return ({
        systemGenerateMonthlyReviewData,
        setSystemGenerateMonthlyReviewData,
        monthlyReviewData,
        MRConstants,
        setMonthlyReviewData: (newMonthlyReviewData) => {
          setMonthlyReviewData(newMonthlyReviewData);
          reduxStore.dispatch(setMonthlyReviewType(_.get(newMonthlyReviewData, 'type') || _.get(MRConstants, 'reviewType')));
        },
      });
    }, [
      systemGenerateMonthlyReviewData,
      setSystemGenerateMonthlyReviewData,
      monthlyReviewData,
      setMonthlyReviewData,
      role,
    ]);
  
    return (
      <MonthlyReviewContext.Provider value={context}>
        <Component {...props} {...context} />
      </MonthlyReviewContext.Provider>
    )
  }
};

export const useMonthlyReviewContext = () => {
  const context = useContext(MonthlyReviewContext) || {};
  return context;
}