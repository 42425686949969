import gql from 'graphql-tag'

export default gql`
  mutation resetPassword(
    $newPass: String!,
    $token: String!
  ){
    resetPassword(
      newPass: $newPass,
      token: $token,
    ) {
      id
      token
    }
  }
`
