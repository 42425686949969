import { React, IHSearchTable, moment, IHLoading } from 'ihcomponent';
import { Table,Icon,Tooltip } from 'antd';
import '../style/index.scss';
import constant from '../constant/index';
import I18N from 'modulesAll/I18N';
import { browserHistory } from "react-router";
import helper from '../helper';
const alertTaskMap = I18N.get('smartAlert.alertType');
const { alertLevel, alertLevelColorMap, MATaskTypesMap, alertLevelExceptFive } = constant;
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
const hoverMessage = I18N.get('smartAlert.hoverMessage');
const SmartAlertListComponent = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedRowKeys: [],
        }
        this.alertLevelOpts = _.map(alertLevelExceptFive, a => ({
            value: a,
            text: alertTaskMap[a]
        }))
        this.MATaskTypeFilters = _.map(_.keys(MATaskTypesMap), key => ({
          text: MATaskTypesMap[key],
          value: key
        }))
    }
    renderVisitWidow = (content,visitWindow,taskType)=>{
        const alertHoverMsg = hoverMessage[taskType];
        if(visitWindow.length) {
            return <div>{content}<Tooltip overlayClassName='hoverMsg' title={helper.parseVisitWindow(visitWindow)+('\n'+(alertHoverMsg||''))}><Icon type='info-circle'/></Tooltip></div>
        }
        if(alertHoverMsg){
            return <div>{content}
                        <Tooltip title={alertHoverMsg} placement='topLeft'style={{width:'fit-content'}} overlayClassName='hoverMsg'><Icon type='info-circle'/></Tooltip>
                    </div>
        }

        return content
    }

    renderTimeLeft(createdAt, left) {
        const timePassedSinceCreated = new Date() - createdAt;
        const timeLeftBeforeDue = left - timePassedSinceCreated;
        const dueInMs = moment.duration(timeLeftBeforeDue);
        const dueInDays = Math.floor(dueInMs.asDays());
        const dueInHours = Math.floor(dueInMs.asHours() % 24);
        const dueInMins = Math.floor(dueInMs.asMinutes() % 60);
        let leftTimeString = '';
        if (timeLeftBeforeDue < 0) {
            return <p style={{ color: 'red' }}>Over Due</p>
        }
        if (dueInDays > 0) {
            leftTimeString = leftTimeString.concat(`${dueInDays} day(s)`)
        }
        else if (dueInHours > 0) {
            leftTimeString = leftTimeString.concat(`${dueInHours} hour(s)`)
        }
        else if (dueInMins > 0) {
            leftTimeString = leftTimeString.concat(`${dueInMins} min(s)`);
        }

        return leftTimeString;
    }

    getColumns() {
        const { filter,type,sort } = this.props;
        return [
            {
                key: 'fullName',
                title: 'Name',
                dataIndex: 'id',
                render: (_, doc) => {
                    const { firstName, lastName } = doc;
                    return `${firstName} ${lastName}`
                }
            },
            {
                key: 'alertLevel',
                dataIndex: 'tasks',
                filters: this.alertLevelOpts,
                filteredValue: _.get(filter, 'alertLevel'),
                title: 'Alert Level',
                render: (item, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    const alertLevel = _.get(firstTask, 'alertLevel', '');
                    const { color, backgroundColor } = alertLevelColorMap[alertLevel] || {};
                    const alertLevelText = alertTaskMap[alertLevel];
                    return {
                        props: {
                            className: `alertLevelCell ${alertLevel}`,
                        },
                        children: <p style={{ color: color, fontWeight: 'bold' }}>{alertLevelText}</p>
                    }
                }
            },
            {
                key: 'dueIn',
                dataIndex: 'createdAt',
                title: 'Due In',
                render: (i, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    const { createdAt, replyTime } = firstTask;
                    return <div>{this.renderTimeLeft(createdAt, replyTime)}</div>
                }
            },
            {
                key: 'taskType',
                dataIndex: 'tasks',
                title: type === 'MA' ? 'Alert Type' : 'Task Type',
                render: (tasks, doc) => {
                    const firstTask = _.first(tasks) || {};
                    return this.renderVisitWidow(_.get(firstTask, 'display'),_.get(firstTask,'visitWindow'),_.get(firstTask,'taskType'));
                },
                filteredValue: type === 'MA' ? _.get(filter, 'taskType') : null,
                filters: type === 'MA' ? this.MATaskTypeFilters : null
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: 'Created At',
                sorter:true,
                sortOrder:_.get(sort,'order',_.get(sort,'direction')),
                render: (item, doc) => {
                    const { tasks } = doc;
                    const firstTask = _.first(tasks) || {};
                    return moment(_.get(firstTask, 'createdAt')).format('MM/DD/YYYY HH:mm');
                }
            }
        ]
    };
    onRow = ({ id,taskType }) => {
        const { curPage, handleChangePage ,type} = this.props;
        return {
            onClick: () => {
                browserHistory.push(`/patients/${id}/enrolledprogram/default`);
                if(curPage === 'tempenrolledpatients') {
                    Mixpanel.calculateDuration('tempenrolledpatients');
                }
                if(curPage === 'smartalert') {
                    Mixpanel.calculateDuration('smartalert');
                }
                Mixpanel.time_event('patient_profile')
                Mixpanel.track('clicked','level',`${type}_vlist`,{ PATIENT_ID: id,TASKTYPE:taskType })
                handleChangePage('patient_profile');
            }
        }
    };
    render() {
        const { page, onTableChange, smartAlertTasksList, loading ,pageInfo, type } = this.props;
        const { expandedRowKeys } = this.state;

        let NestedTable = () => {
            const columns = [{
                key: 'dummyCol',
                title: '',
            },
            {
                key: 'alertLevel',
                title: 'Alert Level',
                dataIndex: 'alertLevel',
                render: (alertLevel, doc) => {
                    const { color } = alertLevelColorMap[alertLevel] || {};
                    const alertLevelText = alertTaskMap[alertLevel];
                    return {
                        props: {
                            className: `alertLevelCell ${alertLevel}`,
                        },
                        children: <p style={{ color: color, fontWeight: 'bold' }}>{alertLevelText}</p>
                    }
                }
            },
            {
                key: 'dueIn',
                dataIndex: 'createdAt',
                render: (i, doc) => <div>{this.renderTimeLeft(i, doc.replyTime)}</div>
            },
            {
                key: 'taskType',
                dataIndex: 'display',
                render: (display,doc) => this.renderVisitWidow(display,doc.visitWindow,doc.taskType)
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: 'Created Time',
                render: (i) => moment(i).format('MM/DD/YYYY HH:mm')
            }]
            const expandedRowRender = (a, b, c) => {
                const keys = expandedRowKeys;
                const tasks = _.slice(a.tasks, 1);
                return tasks.length ?
                  <Table 
                    dataSource={tasks}
                    onRow={({ taskType }) => {
                      if (_.get(keys, 'length') && !_.isNil(keys[0])) {
                        const id = keys[0];
                        return this.onRow({ id ,taskType})
                      }
                    }}
                    columns={columns} pagination={false} showHeader={false} rowClassName='nestedRowClass'
                    rowKey={record => record.id} // task.id
                  /> : '';
            }
            const pagination = {
                current: _.get(page, 'current', 1),
                pageSize: _.get(page, 'pageSize', 10),
                total: _.get(pageInfo, 'total', 0),
            };
            const noDataElem = (
                <div>
                    <p style={{ fontSize: '18px' }}>No Smart Alert</p>
                </div>
            );
            const onChange = (p, f, s) => {
                const newAlertLevel = f.alertLevel;
                Mixpanel.track('set','alert_level_filter',`${type}_WORKING_LIST`,{ ALERT_LEVEL: newAlertLevel });
                const newFilter = { ...f };
                const newPage = { ...p };
                const newSort = { ...s}
                return onTableChange(newPage, newFilter, newSort)
            };
            const loadObj = { spinning:!!loading,indicator: <IHLoading/> }
            return (
                <IHSearchTable
                    className="smartAlertListTable"
                    columns={this.getColumns()}
                    loading={loadObj}
                    expandedRowKeys={expandedRowKeys}
                    expandedRowRender={(a, b, c) => expandedRowRender(a, b, c)}
                    onExpand={(d, doc) => {
                      const newExpandedKey = _.get(doc, 'id');
                      const res = []
                      if(!_.includes(expandedRowKeys, newExpandedKey)){
                        res.push(newExpandedKey);
                      }
                      this.setState({ expandedRowKeys: res });

                      Mixpanel.track('Clicked','plus_to_expand',`${type}_WORKING_LIST`,{ PATIENT_ID: newExpandedKey });
                    }}
                    dataSource={smartAlertTasksList}
                    pagination={pagination}
                    onChange={onChange}
                    onRow={this.onRow}
                    noDataElem={noDataElem}
                    onCell={this.onCell}
                    rowClassName={(x) => x.tasks.length > 1 ? '' : 'hidden-extend-btn' }
                    rowKey={record => record.id} // id is memberId in full
                />
            );
        }
        return <div>{NestedTable()}</div>
    }
}

export default SmartAlertListComponent;
