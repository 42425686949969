import gql from 'graphql-tag'

export default gql`

query getEnrolledUserStatus(
  $adminProgramIds: [EID],
  $startDate: Date,
  $endDate: Date) {
  enrolledUserStatus(
    adminProgramIds: $adminProgramIds,
    startDate: $startDate,
    endDate: $endDate) {
    inactiveUsers {
      id
      createdAt
      username
      firstName
      lastName
      gender
      lastVisitTime
      lastMeasurementTime
      totalMeasurement
      lastFoodlogTime
      totalFoodlog
      enrolledSince
      birthday
      vitalTypes
      lastProviderNote{
        updatedAt
        createdAt
        content
      }
      stats{
        name
        count
      }
      emails {
        address
        primary
        verified
      }
      phones {
        type
        countryCode
        areaCode
        number
        primary
        verified
      }
      lastReviewedAt
      lastMeasuredAt
    }
  }
}`
