import React from 'react';
import { Row, Col, Button, Select, message} from 'antd';
import { IHPanel } from '../../../../../../package/IHComponent';
import { updateCoveredClinics } from 'modulesAll/graphql/mutation/editUser';
import Client from 'libModule/gqlClient';
import '../css/messageNoti.scss';

const NotificationList = ["NOTIFY_ALWAYS", "NOTIFY_LESS", "NOTIFICATIONS_OFF"];
const NotificationMap = {
    "NOTIFY_ALWAYS" : {"text" : "Notify always", "description" : "Receive notifications for all new messages."},
    "NOTIFY_LESS" : {"text" : "Notify less", "description" : "Receive notifications only when you’re @mentioned."},
    "NOTIFICATIONS_OFF":{"text" : "Notifications off", "description" : "You won’t get any notifications, but @mentions will continue to get a notification dot."}
}

let MessageNotifiComponent = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            notification: this.getNotificationSettings()
        }
    }

    getCurrentUser = () => {
        return JSON.parse(sessionStorage.getItem('currentUser'));
    }

    getNotificationSettings = ()=>{
        return  JSON.parse(sessionStorage.getItem('notificationFreq'));
    }

    setUserSettings = (userSettings)=>{
        sessionStorage.setItem('notificationFreq',JSON.stringify(userSettings));
    }

    renderNotiBody = () => {
        const { editable, notification} = this.state;
        return (
            <Row>
                <Col span={21}>Notification</Col>
                <Col style={{ marginTop: -5, float: 'right' }}>
                    <Button 
                    type={editable ? 'primary' : 'default'}
                    size='small'
                    onClick={this.handleOnClick}
                    >
                    {editable ? 'Save' : 'Edit'}
                    </Button>
                </Col>
                <Col span={24}>
                    <Select 
                    type='large'
                    className="notificationSelect"
                    value={notification}
                    onChange={e => this.setState({notification: e})}
                    disabled={!editable} 
                    >
                    {
                        _.map(NotificationList, n => 
                        <Select.Option key={n} value={n}>
                                <span style={{fontWeight:"bold"}}>{NotificationMap[n].text}</span><br/>
                                <span style={{size:"12px"}}>{NotificationMap[n].description}</span>
                        </Select.Option>
                        )
                    }
                    </Select>
                </Col>
                <Col span={24} style={{opacity:0.33}}>@mention and push notifications coming soon!</Col>
            </Row>
        )
    }
    saveNotification = async e => {
        const userId = _.get(this.getCurrentUser(), 'id');
        const variables = {
            id: userId,
            userSettings: {
                notificationFreq : this.state.notification
            },
        }
        try{
            await Client.mutate({
                mutation: updateCoveredClinics,
                variables
              });
            message.success('Notification setting saved successfully');
        } catch (error) {
            console.log(error);
        }
    }

    handleOnClick = () => {
        const {editable, notification} = this.state;
        if (editable) {
            this.saveNotification();
            this.setUserSettings(notification)
        }
        this.setState ({editable : !editable});
    }
    render() {
        return (
            <div className='settings-inner'>
            <div className='change-password-title'>Message Notification Configuration</div>
            <div className='clinic-container'>
              <IHPanel body={this.renderNotiBody()}/>
            </div>
          </div>
        )
    }
}

export default MessageNotifiComponent;