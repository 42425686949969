import types from 'ProgramEditModule/Survey/constants/FormConstants'
import ListTypes from 'ProgramEditModule/Survey/constants/ListConstants'
import moduleCommonActions from 'ProgramEditModule/Survey/actions/CommonActions'
import moduleCommonTypes from 'ProgramEditModule/Survey/constants/CommonConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
])

const toggleAlertForm = (isShowAlertForm=true) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.TOGGLE_ALERT_FORM,
      isShowAlertForm
    })
  }
}

const goMainPage = () => {
  return moduleCommonActions.changePage(moduleCommonTypes.CAREPLAN_MAIN_PAGE)
}

const addFormToList = (fieldsValue) => {
  return (dispatch, getState) => {
    dispatch({
      type: ListTypes.ADD_ITEM_DATA,
      fieldsValue
    })
    dispatch({
      type: types.RESET_FORM
    })
    dispatch(goMainPage())
  }
}

const setValidateCalendar = (validateCalendar) => ({
  type: types.SET_VALIDATE_CALENDAR,
  validateCalendar
})


export default {
  ...commonActionsObject,
  toggleAlertForm,
  goMainPage,
  addFormToList,
  setValidateCalendar,
}
