import { React, IHTable, IHLoading } from 'ihcomponent';
import UserClass from 'modulesAll/graphql/class/User';
import PatientStarComponent from './PatientStarComponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { PATIENT_TYPE, VITAL_FILTERS, DAYS_OF_MEASUREMENT_FILTERS,MNTReferralColumns,SUBSCRIBE_MAP } from '../constants/MainConstants';
import CreateNewTaskContainer from '../../../taskAssignment/containers/CreateNewTaskContainer';
import { Button,Popover, Icon } from 'antd';
import CreateRemoteEnrollmentContainer from "../../../remoteEnrollment/container/CreateRemoteEnrollmentContainer";
import {getProviderName, goPath} from "../../../../lib/utils";
import I18N from '../../../I18N';
import UnderlinedTooltip from './UnderlinedTooltip';
import { VITALS_ORDER } from '../../../../lib/constants';
import { hasCCMOrRPM,getTotalTime,getComplianceRes,isPremiumClinic,parseSubscriptionFilter,getEnrollerRoleType } from '../helper';
import ClaimBillableTimeForm from "../../../patient/main/components/ClaimBillableTimeForm";
import '../../main/css/index.scss';
import { renderSelfTag } from '../../../visitsPage/helpers';
import { getReferredPrograms } from '../../../referAndEnroll/helper';
import moment from 'moment';

const DISPLAY_NOT_NECESSARY_VALUE = "Not Necessary";
export default class extends React.Component {
  static displayName = 'patientList/main/components/ListComponent'

  constructor(props) {
    super(props);
    this.state = {
      showCreateRE: false,
      memberId: null,
      showClaimTimeLogModal: false
    }
    // this.state = {
    //   isStatusFilterClicked: false,
    // }
    // this.adminProgramFilter = UserClass.getAdminProgramFilter();
    // this.changeTableActionFilter = this.changeTableActionFilter.bind(this);

    // get key from PATIENT_TYPE from value props.patientType
    this.actualPatientType = _.find(_.keys(PATIENT_TYPE), key => PATIENT_TYPE[key] === props.patientType);
  }

  setShowClaimTimeLogModal = (showClaimTimeLogModal)=>{
    this.setState({
      showClaimTimeLogModal
    })
  }
  setShowCreateRE = (showCreateRE)=>{
    this.setState({
      showCreateRE
    })
  }
  renderStatus = (user) =>{
    const  remoteEnrollment  = _.get(user,'o.user.remoteEnrollment',{ });
    const remoteEnrollmentStatus = _.get(remoteEnrollment,'status',);
    const showRemoteEnrollStatus = _.includes(['TBD','EXCLUDED'],remoteEnrollmentStatus);
    const patientRefer = _.get(remoteEnrollment,'patientRefer',{ });
    const referStatus = _.get(patientRefer,'status.referStatus',null);
    const enrollStatus = _.get(patientRefer,'status.enrollStatus',null);
    const referredPrograms = getReferredPrograms(patientRefer);

    if(user.o.enrolledPrograms.length) {
      return <div>{
        _.map(_.orderBy(user.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) =>
            <div key={index}>{ep.status === 'SUSPENDED' ? 'Suspended' :
                ((ep.status === 'CREATED' || ep.status === 'PENDING') ? 'Incomplete' : (ep.status == 'DISCHARGED') ? 'Discharged' : 'Enrolled')}</div>
        )
      }
      </div>
    }
    if(showRemoteEnrollStatus) {
        return remoteEnrollmentStatus;
    }
    if(referStatus === 'COMPLETED') {
        return `Referred(${ referredPrograms.join(',') })`
    }
  }

  renderPopover = (memberId,fullName)=>{
      const { data: { refetch } } = this.props;
      return <Popover key={memberId}
                      arrowPointAtCenter={true}
                      overlayClassName={'createRMPopover'}
                      placement={'bottomLeft'}
                      content={<div style={{ width:400,minHeight:320 }}><CreateRemoteEnrollmentContainer memberId={memberId} fullName={fullName} refetch={refetch}/></div>}
                      trigger={'click'}
             >
        <Button onClick={()=>{ this.setShowCreateRE(true)}} className={'createRMButton'}>Add to remote enrollment</Button>
      </Popover>
    }

  getAssigneeFilters = (assignees) => {
    let filters = _.map(assignees, ({fullName, id}) => ({text: fullName, value: id}));
    _.sortBy(filters, ['text']);
    filters.push({text: "Not Assigned", value: "null"});
    filters.push({text: DISPLAY_NOT_NECESSARY_VALUE, value : "not-necessary"});
    return filters;
  }

  renderAssignee = (data, path) => {
    const isNotNecessary = _.get(data, `user.variables.assignees.${path}NotNecessary`);
    const firstName = _.get(data, `user.variables.assignees.${path}.profile.firstName`, '');
    const lastName = _.get(data, `user.variables.assignees.${path}.profile.lastName`, '-');
    const fullName = isNotNecessary? DISPLAY_NOT_NECESSARY_VALUE :`${firstName} ${lastName}`;
    return fullName;
  }

  // changeTableActionFilter(newFilter) {
  //   const { onTableChange, filter = {}, sort = {} } = this.props;

  //   const sortParam = _.pick(sort, ['field', 'order']);
  //   const filterParam = {
  //     ...filter,
  //     ...newFilter,
  //   };
  //   onTableChange({current: 1, size: 10}, filterParam, sortParam);
  // }

  getTableProps(){
    const { actualPatientType, props } = this;
    const {
      onTableChange, data, setModalData, patientType,
      filter: allFilters, sort: allSorters, page: allPagers,
      goToPatientProfile, isMAorMD, selectedTab,isMNTReferral
    } = props;
    const filter = _.get(allFilters, patientType, {});
    const sort = _.get(allSorters, patientType, {});
    const page = _.get(allPagers, patientType, {});

    const { loading, patientList } = data;
    const list = _.get(patientList, 'data');
    const loadObj = { spinning:loading,indicator: <IHLoading/> };
    const { CAs, RDAndHCs } = this.props;

    const columns = [
          {
            key: 'Star',
            width: '60px',
            render: (t, o) => (
            <PatientStarComponent
              user={o.user}
              enrolledPrograms={t.o.enrolledPrograms}
              setModalData={setModalData}
              hasStar={o.user._get('taskAssignmentCount') > 0}
            />
            ),
          },
          (patientType === PATIENT_TYPE.ENROLLED
            ? {
              title:<UnderlinedTooltip
                tooltipMsg={I18N.get('enrollment.enrolledDate.tip')}
              >
                Enrollment Date
              </UnderlinedTooltip>,
              // title: 'Enrollment Date',
              key: 'enrolledDate',
              dataIndex:'enrolledDate',
              render: (t, doc) => doc.user.format('enrolledDate'),
              sorter: true,
              sortOrder: _.get(sort, 'field', null) === 'enrolledDate' ? _.get(sort, 'order', false) : null,
            }
            : {
              title:'Registration Date',
              key: 'registeredAt',
              dataIndex:'registeredAt',
              width: '8%',
              render: (t, doc) => doc.user.format('registeredAt'),
              sorter:true,
              sortOrder: _.get(sort, 'field', null) === 'registeredAt' ? _.get(sort, 'order', false) : null,
          }),
          {
            title: 'Name',
            width: '13%',
            key: 'fullName',
            dataIndex: 'fullName',
            render: (t, doc) => {
              const isSelfEnrolled = doc.user._get('profile.isSelfEnrolled') ? doc.user._get('profile.isSelfEnrolled') : false;
              return <a
                onClick={(e)=> {
                    e.preventDefault();
                    // TODO: refactor this to use goToPatientProfile
                    // if(selectedTab === PATIENT_TYPE.ENROLLED && !isMAorMD) {
                    //   goPath(`/workstation/${doc.user._get('id')}/single`);
                    // } else {
                    //   goPath(`/patients/${doc.user._get('id')}`);
                    // }
                    goPath(`/patients/${doc.user._get('id')}/enrolledprogram/default`);
                }}>
                  {doc.user._get('profile.fullName')}{isSelfEnrolled ? renderSelfTag() : ""}
                </a>
          },
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'fullName' ? _.get(sort, 'order', false) : null,
      },
      {
        title: 'Age',
            key: 'age',
          dataIndex: 'age',
          width: '6%',
          render: (t, doc) => {
        return doc.user.format('age') + 'yr'
      },
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'age' ? _.get(sort, 'order', false) : null,
      },
      {
        title:'DOB',
            key:'DOB',
        width:'8%',
          render:(t,doc)=>doc.user.format('dob'),
      },
      {
        title: 'Gender',
            key: 'gender',
          width: '8%',
          render: (t, doc) => doc.user.format('gender'),
          filters: [
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' }
      ],
          filteredValue: _.get(filter, 'gender'),
      },
      {
        title: I18N.get('provider.label'),
        width: '8%',
            key:'doctorId',
          filters: _.map(JSON.parse(sessionStorage.getItem('doctorName')), p =>({ text: getProviderName(p), value: p.id })),
          render:(t,doc)=> getProviderName(doc.user._get('profile.doctor')),
          filteredValue: _.get(filter, 'doctorId'),
      },
      {
        title:'MRN',
            key:'MRN',
          render:(t,doc)=>doc.user.format('MRN'),
          width:'8%'
      },
      {
        title:'Enrolled Program',
            key:'programsEnrolled',
          width:'12%',
          render:(t,doc)=>{
        const categories = doc.user.format('CCM/RPM');
        const remoteEnrollment  = _.get(doc,'o.user.remoteEnrollment',{ });
        const memberId = _.get(doc,'o.user.id');
        const patientReferral = _.get(remoteEnrollment,'patientRefer',{ });
        const enrollStatus = _.get(patientReferral,'status.enrollStatus');
        const isEnrolled = enrollStatus ==='COMPLETED';
        let content = [];
        let res = '';
        let enrollDiv = '';
        categories.forEach((o,i)=>{
          if(o.enrolled){
            content.push(_.toUpper(o.name));
          }
        })
        if(isEnrolled){
          let { status } = patientReferral;
          let enrolledByType = getEnrollerRoleType(remoteEnrollment) || 'MA';
          let needAllFinishedToShowChecked = ['patientConsentEnroll', 'confirmDevicesEnroll', 'scheduleInitVisitEnroll', 'enrollStatus'];
          let flag = needAllFinishedToShowChecked.reduce((flag,cur)=>{ flag = flag&&(status[cur]==='COMPLETED');return flag },true);
          let icon = flag?  <Icon type="check-circle" theme="filled" style={{ marginLeft: 5,color:'#00C8BC' }}/> :     <Icon type="info-circle" theme="filled" style={{color:"#DA6453",marginLeft: 5}} />
          enrollDiv =  <span style={{color: '#3177C9'}}>{enrolledByType} Enrolled{icon}</span>
        }

            return <div>{ content.length>0 ? content.join(', ') :'--' } <div>{enrollDiv}</div></div>
      },
        filters: isMAorMD ? [
      { value: 'CCM', text: 'CCM only' },
      { value: 'RPM', text: 'RPM only' },
      { value: 'RPM_WITH_LOG', text: 'RPM only w/ current monthly call log' },
      { value: 'RPM_WITHOUT_LOG', text: 'RPM only w/o current monthly call log' },
      { value: 'CCM,RPM', text: 'CCM + RPM' },
      { value: 'CCM,MNT', text: 'CCM + MNT' },
      { value: 'RPM,MNT', text: 'RPM + MNT' },
      { value: 'CCM,RPM,MNT', text: 'CCM + RPM + MNT' },
      { value: 'NONE', text: 'None' },
    ] : [
      { value: 'CCM', text: 'CCM only' },
      { value: 'RPM', text: 'RPM only' },
      { value: 'CCM,RPM', text: 'CCM + RPM' },
      { value: 'CCM,MNT', text: 'CCM + MNT' },
      { value: 'RPM,MNT', text: 'RPM + MNT' },
      { value: 'CCM,RPM,MNT', text: 'CCM + RPM + MNT' },
      { value: 'NONE', text: 'None' },
    ],
        filteredValue: _.get(filter, 'programsEnrolled'),
        filterMultiple: false
    },
    {
      title:'Vitals',
          key:'vitals',
        width:'10%',
        render:(t,doc)=>{
      const program = doc.o.enrolledPrograms;

      return program.map((o)=>{
        if(o.status=='STARTED') {
          let vitalsInOrder = _.map(o.tasks, p => p.type);
          vitalsInOrder = _.intersection(VITALS_ORDER, vitalsInOrder);
          return vitalsInOrder.length ? vitalsInOrder.join(',')
              //     _.map(vitalsInOrder, v => (
              //   <div key={v}>{v}</div>
              // ))
              : '--';
        }
      })
    },
        filters: VITAL_FILTERS,
        filteredValue: _.get(filter, 'vitals')
    },
    {
      title: <UnderlinedTooltip
          tooltipMsg='Total # of billable days in this calendar month in which vitals (from an iHealth auto-synced device) were measured.'
      >
        RPM Days
      </UnderlinedTooltip>,
          key: 'monthlyMeasurementDaysRange',
        dataIndex: 'monthlyMeasurementDaysRange',
        render: (__, doc) => {
      const e = _.get(doc, 'o.user.monthlyMeasurementDays');
      return e >= 0 ? `${e} day${e > 1 ? 's': ''}`: '--';
    },
        sorter: true,
        sortOrder: _.get(sort, 'field', null) === 'monthlyMeasurementDaysRange' ? _.get(sort, 'order', false) : null,
        filters: DAYS_OF_MEASUREMENT_FILTERS,
        filteredValue: _.get(filter, 'monthlyMeasurementDaysRange'),
    },
    {
      title:<UnderlinedTooltip
          tooltipMsg='Total billable time accrued in the calendar month (CCM)'
      >
        Time
      </UnderlinedTooltip>,
          key:'timeSpent',
        dataIndex:'timeSpent',
        sorter:true,
        sortOrder: _.get(sort, 'field', null) === 'timeSpent' ? _.get(sort, 'order', false) : null,
        filters: [
      { value: `15:20`, text: '15-20 mins' },
      { value: '35:40', text: '35-40 mins' },
      { value: `55:60`, text: '55-60 mins' }
    ],
        filteredValue: _.get(filter, 'timeSpent'),
        render:(value,data)=>{
      const user = new UserClass(data);
      const totalTime = _.get(user,'variables.user.variables.monthlyTimeSpent.totalTime', 0) ;
      const timeInMins = Math.round(totalTime/60);
      return timeInMins + (timeInMins > 1 ? ` mins` : ` min`);
    }
    },
    {
      title: 'Status',
          key: 'status',
        width: '12%',
        render: (t, doc) => {
          return this.renderStatus(doc);
        },
        // <div>
        //   {
        //     _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) =>
        //         <div key={index}>{ep.status === 'SUSPENDED'? 'Suspended' :
        //             ((ep.status === 'CREATED' || ep.status === 'PENDING') ? 'Incomplete' : (ep.status=='DISCHARGED') ? 'Discharged' : 'Enrolled' )}</div>
        //     )
        //   }
        // </div>

        filters: [
          { value: 'REFERRED', text : 'Reffered'},
          { value: 'EXCLUDED', text: 'Excluded' },
          { value: 'TBD', text: 'TBD'},
          { value: 'DISCHARGED', text: 'Discharged'}
    ],
        filteredValue: _.get(filter, 'status'),
        filterMultiple: true
    },
    // {
    //   title: <UnderlinedTooltip
    //     tooltipMsg='Total # of days with vitals measurements in the calendar month (RPM)'
    //   >
    //     Billable Days
    //   </UnderlinedTooltip>,
    //   key: 'monthlyMeasurementDaysRange',
    //   dataIndex: 'monthlyMeasurementDaysRange',
    //   render: (__, doc) => {
    //     const e = _.get(doc, 'o.user.monthlyMeasurementDays');
    //     return e >= 0 ? `${e} day${e > 1 ? 's': ''}`: '--';
    //   },
    //   sorter: true,
    //   sortOrder: _.get(sort, 'field', null) === 'monthlyMeasurementDaysRange' ? _.get(sort, 'order', false) : null,
    //   filters: DAYS_OF_MEASUREMENT_FILTERS,
    //   filteredValue: _.get(filter, 'monthlyMeasurementDaysRange'),
    // },
    {
      title: 'Discharged date',
      key:'dischargedDate',
      dataIndex: 'dischargedDate',
      render: (__, doc) => {
        const { status, dischargedDetail } = _.get(doc, 'o.enrolledPrograms.0') || {};
        if (status === 'DISCHARGED') {
          const dischargedAt = _.get(dischargedDetail, 'dischargedAt');
          return dischargedAt ? moment(dischargedAt).format('MM/DD/YYYY') : '--';
        }
        return null;
      },
      sorter: true,
      sortOrder: _.get(sort, 'field', null) === 'dischargedDate' ? _.get(sort, 'order', false) : null,
    },
    {
      key:'remoteEnrollment',
          title:'Remote Enrollment',
        dataIndex: 'remoteEnrollment',
        render:(__,doc)=>{
      const user = _.get(doc,'o.user');
      const memberId = _.get(user,'id');
      const fullName = _.get(user,'profile.fullName');
      const remoteEnrollment = _.get(user,'remoteEnrollment');
      return remoteEnrollment ? 'Added to remote enrollment':<div>{this.renderPopover(memberId,fullName)}</div>
    }
    },
    // {
    //   key:'compliance',
    //   title:'Compliance',
    //   render:(doc)=>{
    //     const { content,header }  = getComplianceRes(doc,{ isFromList:true });
    //     const totalTime = getTotalTime(doc);
    //     const hasCCMOrRPMRes= hasCCMOrRPM(doc);
    //     const { setShowClaimTimeLogModal } = this;
    //     const { showClaimTimeLogModal } = this.state;
    //     const patientId = doc.user.key;
    //     return <Popover overlayStyle={{ width:500 }}
    //                     placement={'rightBottom'}
    //                     onVisibleChange={(visible)=>{
    //                       if(!visible) {
    //                         setShowClaimTimeLogModal(false)
    //                       }
    //                     }}
    //                     trigger={'click'}
    //                     visible={showClaimTimeLogModal==patientId}
    //                     destroyTooltipOnHide={true}
    //                     overlayClassName={'claimBillableTimePopover'}
    //                     content={<ClaimBillableTimeForm patientId={patientId}
    //                                                     user = { doc }
    //                                                     showTotalTimeNum={true}
    //                                                     setShowModal = { setShowClaimTimeLogModal }
    //                                                     header = { header }
    //                                                     hasCCMOrRPMRes={hasCCMOrRPMRes}
    //                                                     totalTime={totalTime}/>}>
    //                          <div onClick={()=>this.setShowClaimTimeLogModal(patientId)}>{ content }</div>
    //           </Popover>
    //   }
    // }
    // {
    //   title: `${I18N.get('keywords.Program')} Start Date`,
    //   key: 'psd',
    //   width: '10%',
    //   render: (t, doc) => {
    //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => (
    //       (ep.status === 'CREATED' || ep.status === 'PENDING')? <br key={index}/> :
    //       ep.startDate
    //         ? <div key={index}>{moment(ep.startDate).format('MMM-DD-YYYY')}</div>
    //         : <br key={index}/>
    //     ))
    //   }
    // },
    // {
    //   title: `${I18N.get('keywords.Program')} End Date`,
    //   key: 'endDate',
    //   width: '10%',
    //   render: (t, doc) => {
    //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => (
    //       (ep.status === 'CREATED' || ep.status === 'PENDING')? <br key={index}/> :
    //       ep.endDate
    //         ? <div key={index}>{moment(ep.endDate).format('MMM-DD-YYYY')}</div>
    //         : <br key={index}/>
    //     ))
    //   }
    // },
    // {
    //   title: 'Duration',
    //   key: 'dura',
    //   width: '7%',
    //   render: (t, doc) => {
    //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => {
    //       if (!ep.endDate || !ep.startDate) {
    //         return <br key={index} />
    //       }
    //       if (ep.status === 'CREATED' || ep.status === 'PENDING') {
    //         return <br key={index} />
    //       }
    //
    //       const diff = moment(ep.endDate).diff(ep.startDate, 'days') + 1;
    //       return <div key={index}>{showMonthsOrDays(diff, 'text')}</div>;
    //     });
    //   }
    // },
    // {

    //   title: `Days left in ${I18N.get('keywords.Program')}`,
    //   key: 'dlip',
    //   render: (t, doc) => {
    //     return _.map(_.orderBy(doc.o.enrolledPrograms, [programme => programme.name.toLowerCase()], ['asc']), (ep, index) => {
    //       if (!ep.endDate) {
    //         return <br key={index} />
    //       }
    //       if (ep.status === 'CREATED' || ep.status === 'PENDING') {
    //         return <br key={index} />
    //       }
    //       let diff;
    //       if(ep.startDate > moment()){
    //         //future date
    //          diff = moment(ep.endDate).diff(ep.startDate, 'days') + 1;
    //       } else
    //          diff = moment(ep.endDate).diff(moment(), 'days') + 1;
    //       return <div key={index}>{diff} days</div>;
    //     });
    //   },
    //   filterDropdown: (
    //     <div className="custom-filter-dropdown">
    //       <IHInputNumber
    //         min={1}
    //         max={200}
    //         onChange={value => this._daysRemain = value}
    //         defaultValue={_.get(filter, 'daysRemaining.0', '')}
    //       />
    //       <IHButton
    //         type="primary"
    //         onClick={e => this.changeTableActionFilter({ daysRemaining: [this._daysRemain] })}
    //       >OK</IHButton>
    //     </div>
    //   ),
    // }
  ]

    const RDFilters = this.getAssigneeFilters(RDAndHCs);
    const CAFilters = this.getAssigneeFilters(CAs);
    let subscriptionColumn = {
          title:'Subscription',
          dataIndex:'subscription',
          key:'subscription',
          width: '9%',
          render:(t,doc) => doc.user.format('subscription') ,
          filters:SUBSCRIBE_MAP,
          filteredValue: _.get(filter, 'subscription'),
          filterMultiple: true
    };

    if(patientType === PATIENT_TYPE.ENROLLED) {
      columns.push(
          {
              key:'compliance',
              title:'Billable',
              render:(doc)=>{
                const { content,header }  = getComplianceRes(doc,{ isFromList:true });
                const totalTime = getTotalTime(doc);
                const hasCCMOrRPMRes= hasCCMOrRPM(doc);
                const { setShowClaimTimeLogModal } = this;
                const { showClaimTimeLogModal } = this.state;
                const categories = doc.user.format('CCM/RPM');
                let programEnrolled = [];
                categories.forEach((o,i)=>{
                    if(o.enrolled){
                      programEnrolled.push(_.toUpper(o.name));
                    }
                });
                const patientId = doc.user.key;
                const consentInfo = _.get(doc,'user.variables.consentInfo',{});
                const hasSigned = consentInfo && consentInfo.consent;
                const hasProgramEnrolled = programEnrolled.length;
                return hasSigned&&hasProgramEnrolled ? <Popover overlayStyle={{ width:700 }}
                                placement={'rightBottom'}
                                onVisibleChange={(visible)=>{
                                  if(!visible) {
                                    setShowClaimTimeLogModal(false)
                                  }
                                }}
                                trigger={'click'}
                                visible={showClaimTimeLogModal==patientId}
                                destroyTooltipOnHide={true}
                                overlayClassName={'claimBillableTimePopover'}
                                content={<ClaimBillableTimeForm patientId={patientId}
                                                                user = { doc }
                                                                showTotalTimeNum={true}
                                                                setShowModal = { setShowClaimTimeLogModal }
                                                                header = { header }
                                                                hasCCMOrRPMRes={hasCCMOrRPMRes}
                                                                totalTime={totalTime}/>}>
                                     <div onClick={()=>this.setShowClaimTimeLogModal(patientId)}>{ content }</div>
                      </Popover> : '--';
              }
            },
            {
              title: 'Assigned RD/HC',
              key:'assignedToRD',
              dataIndex:'assignedToRD',
              filters: RDFilters,
              filteredValue: _.get(filter, 'assignedToRD'),
              render: (value, data) => this.renderAssignee(data, 'assignedToRD')
            },
            {
              title: 'Assigned CA',
              key:'assignedToCA',
              dataIndex:'assignedToCA',
              filters: CAFilters,
              filteredValue: _.get(filter, 'assignedToCA'),
              render: (value, data) => this.renderAssignee(data, 'assignedToCA')
            },
      )
    }
    if(isPremiumClinic()) {
      let nameIndex = columns.findIndex(c=>c.dataIndex=='fullName');
      columns.splice(nameIndex+1, 0, subscriptionColumn);
    }


    return {
      // title: `Patients (${_.get(patientList, 'pageInfo.total', 0)})`, // remove the title
      rowKey: doc => doc.user._get('id'),
      // showSearchInput: false,
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(patientList, 'pageInfo.total', 0),
        // onChange: (e) => {
        //   Mixpanel.track('clicked','pager', `${actualPatientType} patient`, {PAGE_NUM : "Page" + e});
        //   checkPage = true;
        // }
      },
      onChange: (p, f, s) => {
        const newPager = {
          ...allPagers,
          [patientType]: p
        }
        const newFilter = {
          ...allFilters,
          [patientType] : f
        }
        const newSorter = {
          ...allSorters,
          [patientType]: _.pick(s, ['field', 'order'])
        }
        let result = null, action = null;
        if(!_.isEqual(f.doctorId, filter.doctorId)) {
          action = !((!f.doctorId && filter.doctorId) || (f.doctorId && f.doctorId.length === 0 && filter.doctorId)) ? 'set' : 'reset';
          result = [action, 'doctor name filter'];
        } else if(!_.isEqual(f.gender, filter.gender)) {
          action = !((!f.gender && filter.gender) || (f.gender && f.gender.length === 0 && filter.gender)) ? 'set' : 'reset';
          result = [action, 'gender filter'];
        } else if(!_.isEqual(f.timeSpent, filter.timeSpent)) {
          action = !((!f.timeSpent && filter.timeSpent) || (f.timeSpent && f.timeSpent.length === 0 && filter.timeSpent)) ? 'set' : 'reset';
          result = [action, 'billable time filter'];
        } else if(!_.isEqual(f.status, filter.status)){
          action = !((!f.status && filter.status) || (f.status && f.status.length === 0 && filter.status)) ? 'set' : 'reset';
          result = [action, 'status filter'];
        } else if(!_.isEqual(f.vitals, filter.vitals)){
          action = !((!f.vitals && filter.vitals) || (f.vitals && f.vitals.length === 0 && filter.vitals)) ? 'set' : 'reset';
          result = [action, 'vitals filter'];
        } else if(!_.isEqual(f.monthlyMeasurementDaysRange, filter.monthlyMeasurementDaysRange)){
          action = !(
          (!f.monthlyMeasurementDaysRange && filter.monthlyMeasurementDaysRange) ||
            (f.monthlyMeasurementDaysRange && f.monthlyMeasurementDaysRange.length === 0 &&
              filter.monthlyMeasurementDaysRange)
          ) ? 'set' : 'reset';
          result = [action, 'monthlyMeasurementDaysRange filter'];
        } else if(!_.isEqual(f.programsEnrolled, filter.programsEnrolled)){
          action = !((!f.programsEnrolled && filter.programsEnrolled) || (f.programsEnrolled && f.programsEnrolled.length === 0 && filter.programsEnrolled)) ? 'set' : 'reset';
          result = [action, 'CCM/RPM filter'];
        } else {
          // first sort will be ignored when page obj has no current
          parseSubscriptionFilter(newFilter, patientType);
          if(!_.isEqual(_.get(page, 'current'), _.get(p, 'current'))){
            Mixpanel.track('clicked','pager', `${actualPatientType} patient`, {PAGE_NUM : "Page" + _.get(p, 'current')});
            return onTableChange(newPager, newFilter, newSorter);
          }
          let actionName, results;
          if ((s && s.field) || (sort && sort.field)) {
            const field = s.field || sort.field; // sort.field is when cancel a sorter
            switch(field) {
              case 'monthlyMeasurementDaysRange':
                actionName = 'days of measurement';
                results = ['more to less', 'less to more'];
                break;
              case 'age':
                actionName = 'age';
                results = ['old to young', 'young to old'];
                break;
              case 'fullName':
                actionName = 'last name';
                results = ['Z to A', 'A to Z'];
                break;
              case 'enrolledDate':
                actionName = 'enrollment date';
                results = ['most recent to less', 'least recent to more'];
                break;
              case 'registeredAt':
                actionName = 'registration date';
                results = ['most recent to less', 'least recent to more'];
                break;
              case 'timeSpent':
                actionName = 'billable time';
                results = ['more to less', 'less to more'];
                break;
              case 'dischargedDate':
                actionName = 'discharged date';
                results = ['most recent to oldest', 'oldest to most recent'];
                break;
              default:
                actionName = field;
                results = ['desc', 'asc'];
            }
          }

          // no sort => sort
          // OR sort field changes (eg. sort age => sort days of measurement)
          // OR sort order changes (eg. sort ascending => sort descending)
          if((!sort.field && s.field) ||
              (s.field && (sort.field !== s.field)) ||
              (s.order && (sort.order !== s.order))) {
            // add sort
            const result = s.order === 'descend' ? results[0] : results[1];
            Mixpanel.track('clicked', 'sort', `${actionName} ` + result + `for ${actualPatientType}`, '');
            return onTableChange(newPager, newFilter, newSorter);
          }
          if(sort.order && !s.order) {
            // remove sort
            Mixpanel.track('clicked', 'sort', `${actionName} is canceled` + `for ${actualPatientType}`, '');
            return onTableChange(newPager, newFilter, newSorter);
          }
        }
        if(result && result.length) Mixpanel.track(result[0] + '', result[1] + '', actualPatientType, {FILTER_TYPE: result[1]});
        return onTableChange(newPager, newFilter, newSorter)
      },
      loading:loadObj,
      dataSource: _.map(list, l => ({
        user: new UserClass(l.user),
        o: l
      })),
      columns
    }

  }

  modifyTableColumnsBasedOnRoleAndTab = (columns, tab,isMNTReferral)=>{

    if(this.props.isMAorMD){
      // remove column with given key
      const removedKeys = ['Star','remoteEnrollment'];
      columns = _.filter(columns,(col)=> {
        return !_.includes(removedKeys, col.key);
      });
    }

    if(tab === PATIENT_TYPE.ENROLLED){
      const enrolledRemovedKeys = ['status', 'remoteEnrollment','DOB','MRN', 'dischargedDate'];
      columns = _.filter(columns, col => !_.includes(enrolledRemovedKeys, col.key));
      if(isMNTReferral) {
        const { setShowReferralModal,filter: allFilters,patientType,setShowEditVisitModal } = this.props;
        const filter = _.get(allFilters, patientType, {});
        const list = _.get(this,'props.data.patientList.data');
        const variables = _.get(this,'props.data.variables');
        columns = _.filter(columns, col => !_.includes(['compliance','programsEnrolled','vitals','monthlyMeasurementDaysRange','timeSpent'], col.key));
        columns.push(...MNTReferralColumns({setShowReferralModal,list,fetchVar: variables,filter,setShowEditVisitModal}));
      }
    }

    if(tab === PATIENT_TYPE.UNENROLLED){
      const unenrolledRemovedKeys = ['programsEnrolled', 'timeSpent', 'vitals', 'monthlyMeasurementDaysRange'];
      columns = _.filter(columns, col => !_.includes(unenrolledRemovedKeys, col.key));
    }
    return columns;
  }

  render() {
    const { props } = this;
    const { showModal,loading,data, patientType,isMNTReferral} = props;
    const { refetch } = data;
    if(loading){
      return <div>loading</div>
    }
    let tableProps = this.getTableProps();
    tableProps.columns = this.modifyTableColumnsBasedOnRoleAndTab(tableProps.columns, patientType,isMNTReferral);
    // Because we remove the search function and table title, it's better to user IHTable rather then IHSearchTable
    // for it will not have the header line
    return (
      <div>
        {<IHTable {...tableProps} />}
        {/*{ renderShowCreateREModal() }*/}
        { showModal ? <CreateNewTaskContainer {...props} renderBody={true} refetchUserList = { refetch } />:'' }
      </div>
    );
  }
}
