import {
    React, ND, BaseComponent, _
} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
    render(){

        const dp = {
            overlay : this.renderMenu(),
            trigger : this.props.trigger || ['click'],
            overlayClassName: this.props.overlayClassName,
        }

        const divP = this.getClassAndStyleProp()

        return (
            <div {...divP}>
                <ND.Dropdown {...dp}>
                    {this.renderTitle()}
                </ND.Dropdown>

            </div>
        )
    }

    renderTitle(){
        return this.props.title
    }

    renderMenu(){

        return (
            <ND.Menu onClick={this.handleClick.bind(this)}>
                {_.map(this.props.itemList, (item, index)=>{
                    return this.renderEachMenu(item, index)
                })}
            </ND.Menu>
        )
    }

    renderEachMenu(item, i){
        return (
            <ND.Menu.Item key={item.id}>
                {item.title}
            </ND.Menu.Item>
        )
    }

    defineBaseClassName(){
        return 'IH-Dropdown'
    }

    handleClick(e){
        if(this.props.onClick){
            this.props.onClick(e.key)
        }
    }
}

Component.propTypes = {
    title : PropTypes.element
}

export default Component
