import { React, IHSearchTable, BaseComponent, IHButton } from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import Client from 'libModule/gqlClient'
import { goPath } from 'libModule/utils'
import { Button, Modal, message } from 'antd'
import {deactivateUser} from 'modulesAll/graphql/deactivateUser'
import '../style/index.scss'


const Component = class extends BaseComponent {
    static displayName = 'superadmin/providers/providereditrole'

    constructor(props){
        super(props);
        this.user = this.props.data
        this.userObject = new UserClass(this.user)
        this.state = {
            visible: false,
            showAlert: false
        }
    }

    render() {
        return (
            <div className="v-provider-profileDetail">
                {this.renderBox1()}
            </div>
        )
    }

    handleSumbit = () => {
        this.setState({
          visible: false,
        });
        this.handleDeleteUser()
    }
    
    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    showModal = () => {
        const {team} = this.props.data
        const name = _.get(this.props, 'data.profile.fullName')
        if(!team){
            this.setState({
                visible: true,
            })
        }else if(team.length == 0){
            Modal.warning({
                className: 'provider-edit-role-modal',
                content: <div>You cannot remove {name} because {name} is in some care teams of another organization</div>
            })
        }else{
            const teamList = _.map(team, "name").join(", ")
            const teamName = team.length == 1 ? teamList : teamList.substring(0, teamList.length - 2)
            Modal.warning({
                className: 'provider-edit-role-modal',
                content: <div style = {{fontSize: '16px'}}>To remove provider {name}, you need to delete the provider from the following care team:
                    <p style = {{fontWeight: 'bold'}}>{teamName}</p></div>
            })
        }
    };

    handleDeleteUser = () => {
        const {data} = this.props
        const providerId = _.get(data, "id")
        const refIds = _.map(data.allRoles,'refId')

        Client.mutate({
            mutation: deactivateUser,
            variables:{ 
              id: providerId,
              roles: refIds,
            },
        }).then(()=>{
            message.success('Provider removed successfully.');
            goPath("superadmin/providers")
        }).catch(e=>{
            console.log(e);
            message.error(e.message)
        })
    }

    renderBox1() {
        const deactivate = {
            label : 'Remove Provider',
            size : 'large',
            onClick : ()=>{
                this.showModal();
            }
        }  
        const name = _.get(this.props, 'data.profile.fullName')

        return (
            <div className=''>
                <div className="v-box-1">
                    <img src={this.userObject.format('avator')} />
                    <div>
                        <div className="c-n1" style={{display:'flex',flexDirection:'row'}}>
                            <h2>{this.userObject._get('profile.fullName')}</h2>
                            <span style = {{marginLeft: 'auto', display: 'flex', size:'large'}}>
                                <IHButton {...deactivate}/>
                            </span>
                            <Modal
                                className = 'provider-edit-role-modal'
                                title = {`Remove Provider:  ${name}`}
                                visible={this.state.visible}
                                onOk={this.handleSumbit}
                                onCancel={this.handleCancel}
                                okText = 'Submit'
                            >
                            <div style = {{fontSize :'18px'}}> Are you sure you want to remove provider {name} from the system?</div>
                            </Modal>
                        </div>
                        <p className="c-n2">
                            <span className="c-tag">{this.userObject.format('employeeId')}    |  </span>
                            <span className="c-tag">{this.userObject.format('loginID')}    |  </span>
                            <span className="c-tag">{this.userObject.format('role')}    |  </span>
                            <span className="c-tag"><Button onClick={() => goPath(`patients/${this.userObject._get('id')}/change-password`)}>Change Password</Button></span>
                        </p>
                    </div>
                </div>
                <div className="vsm-main-990">
                    <div className="v-table">
                        {this.renderRolesTable()}
                    </div>
                </div>
            </div>
        )
    }
    renderRolesTable() {
        const p = this.getTablePropsForRoleList();
        return <IHSearchTable {...p} />
    }
    getTablePropsForRoleList() {
        const roles = this.userObject._get("allRoles");
        const roleArray = this.props.data.allRoles;
        let roleIdArray = [];
        if (roleArray && roleArray.length > 0) {
            roleArray.forEach(r => {
                roleIdArray.push(r.refId);
            });
        }
        return {
            title: `Roles`,
            dataSource: roleArray,
            rowKey: (doc) => {
                return doc.refId;
            },
            columns: [
                {
                    key: 'name',
                    title: 'Name',
                    dataIndex: 'name',
                    render: (t, doc) => doc.name
                },
                {
                    title: 'Organization',
                    key: 'organization',
                    render: (t, doc) => doc.organization.name
                },
                {
                    title: 'Actions',
                    key: 'Actions',
                    render: (t, doc) => {
                        const bp = {
                            size: 'exsmall',
                            bsStyle: 'primary',
                            onClick: () => {
                                this.removeRole(doc.refId);
                            },
                            className: 'table-action-button'
                        }
                        const label = '-'
                        return (
                            <IHButton {...bp}>
                                {label}
                            </IHButton>
                        )
                    }
                }
            ]
        }
    }

    removeRole(roleRefId) {
        this.props.onRemoveRole(this.user, roleRefId);
        message.success('Role removed successfully.');
    }
}

export default Component
