import types from "../../../postIt/constants";

const now = new Date();
const INITIAL_STATE = {
    year: now.getFullYear(),
    month: now.getMonth()+1
}

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case 'SET_YEAR':
            return {
                ...state,
                year: action.payload
            }
        case 'SET_MONTH':
            return {
                ...state,
                month: action.payload
            }
        default:
            return state;
    }
}
export default {
    patientTimeSpentReducer: reducer,
};


