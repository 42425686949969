import React from 'react';
import { Checkbox, Form } from 'antd';

// export interface InsuranceCopayCheckboxFormItemComponentProps extends FormItemProps {}
export const InsuranceCopayCheckboxFormItemComponent = (
  props,
) => {
  const {
    form,
    initialValue,
    ...restProps
  } = props;
  if (!form) return null;
  return (
    <Form.Item
      label=""
      {...restProps}
    >
      {
        form.getFieldDecorator('hasCopay', {
          valuePropName: 'checked',
          initialValue,
        })(
          <Checkbox>
            Patient has co-pay
          </Checkbox>
        )
      }
    </Form.Item>
  );
};
