// Constants
import { constants } from 'libModule/utils';
import { actionTypes as VitalListTypes } from 'patientModule/Careplan/Vital/ducks/VitalListDuck'
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers'
// Reducer

/*
**************************************************
  Constants - Action Types
**************************************************
*/
// TODO: change the path after moving into Careplan or Careplan/Vital
const actionTypes = constants('patient/VitalAlert', 'Common', [
  'CHANGE_PAGE',
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {};

actionCreators.initVitalList = selectedProgram => {
  return {
    type : VitalListTypes.INIT_DATA,
    selectedProgram
  };
};

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  // currentPage: types.CAREPLAN_MAIN_PAGE
};

export default (state = initState, action) => {
  switch(action.type) {
    // case types.CHANGE_PAGE:
    //   return {
    //     ...state,
    //     currentPage: action.currentPage ? action.currentPage : initState.currentPage
    //   }
    default:
      return state;
  }
};
