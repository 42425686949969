import React from 'react';
import { Table,Icon } from 'antd';
import { IHCheckbox,IHSearchTable } from 'ihcomponent';
import UserClass from 'modulesAll/graphql/class/User'
const iconStyle = {
    fontSize:20
};

const AllMembersTable = class extends React.Component{
    constructor(){
        super();
    }

    getColumns = (lastIndex)=>{
        const { visibleMembers,moveMemUp,moveMemDown,primaryMembers } = this.props;
        const columns = [
                            {
                                title:'',
                                width:50,
                                key:'order',
                                render:(_,doc,index)=>{
                                    return (<div style={{ display:'flex',flexDirection:'column' }}>
                                            {
                                                index!=0 ?  <Icon type="up-square" theme="filled" style={iconStyle} onClick={()=>moveMemUp(index)}/> :''
                                            }
                                            {
                                                lastIndex != index  ? <Icon type="down-square" theme='filled' style={iconStyle} onClick={()=>moveMemDown(index,lastIndex)}/> :''
                                            }
                                           </div>)
                                }
                            },
                            {
                                title: 'Name',
                                key: 'Name',
                                render: (t, doc) => {
                                    return doc._get('profile.fullName')
                                }
                            },
                            {
                                title: 'Specialty',
                                key: 'Specialty',
                                render(t, doc){
                                    return doc.format('specialty')
                                }
                            },
                            {
                                title: 'Contact Number',
                                key: 'Contact Number',
                                render(t, doc){
                                    return doc.format('mobilePhone')
                                }
                            },
                            {   title: 'Visibility',
                                key: 'row_s',
                                render: (t, doc,index) => {
                                    const checked = _.includes(visibleMembers, doc._get('id'));
                                    return <IHCheckbox checked={checked} onChange={()=>this.onVisibilityCheckBoxChange(doc._get('id'),index,checked)}/>
                                }
                            },
                            {   title: 'Primary',
                                key: 'row_s2',
                                render: (t, doc,index) => {
                                    const checked = _.includes(primaryMembers, doc._get('id'));
                                    return <IHCheckbox checked={checked} onChange={()=>this.onPrimaryCheckBoxChange(doc._get('id'),index,checked)}/>
                                }
                            }
                        ]
        return columns;
    }

    onPrimaryCheckBoxChange(id, index,checked){
        const { checkPrimaryMembers, uncheckPrimaryMembers } = this.props;
        if(checked){
            uncheckPrimaryMembers(id);
        }
        else{
            checkPrimaryMembers(id)
        }
    }

    onVisibilityCheckBoxChange(id, index,checked){
        const { checkMemberVisible, uncheckMemberVisible } = this.props;
        if(checked){
            uncheckMemberVisible(id);
        }
        else{
            checkMemberVisible(id)
        }
    }

    parseInputData =()=>{
        const { userList,selectedMembers } = this.props;
        let sortedMembers = [];
        _.each(selectedMembers,(id)=>{
            _.forEach(userList,(u)=>{
                if(u.id==id) {
                    sortedMembers.push(u);
                }
            })
        })

        return sortedMembers;
    }

    render(){
        const { selectedMembers=[] } = this.props;
        const pagination = {
            pageSize: 9999,
        };
        const dataSource = this.parseInputData(selectedMembers);
        return <IHSearchTable columns={this.getColumns( selectedMembers ? selectedMembers.length-1:0 )} dataSource = {_.map(dataSource||[], (u) => new UserClass(u))} pagination={false}/>
    }

}

export default AllMembersTable;
