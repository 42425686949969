import BG from './VitalListBGReducer'
import BP from './VitalListBPReducer'
import HS from './VitalListHSReducer'
import HR from './VitalListHRReducer'
import TM from './VitalListTMReducer'
import PO from './VitalListPOReducer'
import Wrapper from './VitalListWrapperReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  BG,
  BP,
  HS,
  HR,
  TM,
  PO,
  Wrapper
})

// import types from '../constants/VitalListConstants'
// // Default State
// const initState = {
//   sorterBP: { columnKey: 'date', order: null },
//   sorterBG: { columnKey: 'date', order: null },
//   sorterHS: { columnKey: 'date', order: null },
//   pagination: {pageSize: 5},
//   currentPage: 1,
//   view: {BP: 'List', BG: 'List', HS: 'List'},
//   currProgIdx: 0,
//   range: { vitals: null, BP: null, HS: null, BG: null },
//   BPMode: 'BP',
//   BGMode: 'Detailed'
// }
// const returnRange = (prevRange, { range, vital }) => {
//   switch (vital) {
//     case 'vitals': {
//       return { vitals: range, BP: range, BG: range, HS: range }
//     }
//     default:
//       return { ...prevRange, vitals: null, [vital]: range }
//   }
// }
// export default (state = initState, action) => {
//   switch (action.type) {
//     case types.ON_TABLE_CHANGE: {
//       return {
//         ...state,
//         ['sorter' + action.vital]: action.sorter
//       }
//     }
//     case types.CHANGE_VIEW: {
//       const { vital, view } = action
//       return {
//         ...state,
//         view: {...state.view, [vital]: view}
//       }
//     }
//     case types.CHANGE_PROGRAM:
//       return {
//         ...state,
//         currProgIdx: action.currProgIdx
//       }
//     case types.CHANGE_RANGE:
//       return {
//         ...state,
//         range: returnRange(state.range, action)
//       }
//     case types.CHANGE_BPMODE:
//       return {
//         ...state,
//         BPMode: action.mode
//       }
//     case types.CHANGE_BGMODE:
//       return {
//         ...state,
//         BGMode: action.mode
//       }
//     default:
//       return state
//   }
// }
//
