import React from 'react';
import { moment,IHLoading } from 'ihcomponent'
import {compose, graphql} from "react-apollo/index";
import visitList from 'modulesAll/graphql/patientVisitList';
import {connect} from "react-redux";
import {helpers} from "../helpers";
import { openErrorModal,openModal } from 'layoutModule/actions/MainModal'
import modalActions  from 'modulesAll/common/actions/modal'
import { Row,Col,Collapse,Badge } from 'antd';
import PatientAppointmentComponent from '../components/PatientAppointmentsComponent';
import PostItContainer from '../../../modules/postIt/containers/PostItContainer';
import PostItList from '../../graphql/postItList';
import types from '../constants/map';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from '../../I18N';

const Panel = Collapse.Panel;
const additionalFollow_Up_Types = {
    'FOLLOW_UP1':'1st Follow Up',
    'FOLLOW_UP2':'2nd Follow Up',
    'FOLLOW_UP3':'3rd Follow Up',
    'ANNUAL': 'Annual Visit',
}
const PatientAppointmentContainer = class extends React.Component {
    constructor(){
        super();
    }
    // constructor(){
    //     super();
    //     this.state = {
    //         activeKey :-1,
    //     }
    //     this.ref = React.createRef();
    //
    // }
    //
    // setActiveKey = (activeKey)=>{
    //     this.setState({
    //         activeKey
    //     })
    // }
    //
    // componentWillReceiveProps(nextProps){
    //
    //     if(!this.props.visitList&&nextProps.visitList){
    //         const activeKey = _.findIndex(nextProps.visitList,(v)=> moment(v.appointmentAt).isAfter(moment().startOf('day')));
    //         this.setState({
    //             activeKey
    //         })
    //     }
    //
    // }
    //
    // deleteVisit = (id)=>{
    //     const { patientId,filter,year } = this.props;
    //     const { provider } = filter&&filter.provider ? filter : { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
    //                 res.push(d.id)
    //                 return res;
    //             },[]
    //         )};
    //     let variables = { };
    //     const startDate = moment({year}).startOf('year');
    //     const endDate = moment({year}).endOf('year');
    //
    //     variables.memberId = patientId;
    //     variables.providerIds = provider;
    //
    //     Client.mutate({
    //         mutation: deleteVisitQuery,
    //         notifyOnNetworkStatusChange: true,
    //         variables: {id:id},
    //         refetchQueries:[{
    //             query: visitList,
    //             variables: helpers.setFromAndToDate(variables,startDate,endDate),
    //             fetchPolicy:'network-only'
    //         }]
    //     }).then(()=>{
    //         message.success('Patient Appointment canceled');
    //     }).catch((error)=>{
    //         this.props.openErrorModal(GQLHelper.formatError(error));
    //     })
    // }

    // renderNavButton=(year)=>{
    //     const setYear = _.get(this,'props.setYear');
    //     return <Row style={{fontSize:18}}>
    //              <Col span={12}>
    //                  <Icon type="left-square" onClick={()=>setYear(-1)}/>
    //              </Col>
    //              <Col span={12}>
    //                 <Icon type="right-square" onClick={()=>setYear(1)}/>
    //              </Col>
    //            </Row>
    // }

    // handleOnClickCreateAppointment=()=>{
    //     const { props } = this;
    //     const { openModal } = props;
    //
    //     openModal(<CreateAppointmentModal {...props}/>,{className:'createVisitModal'});
    // }

    // renderCreateAppointmentButton = (patientId,fullName,patientBirthday)=>{
    //     // const parsedFullName = btoa(fullName);
    //     return <Button
    //         // onClick={()=>goPath(`/appointments/create/${patientId}/${parsedFullName}/${patientBirthday}`)}
    //                    type='primary'
    //                    size='small'
    //                    onClick={()=>this.handleOnClickCreateAppointment(patientId)}
    //                    style={{ float:'right',marginRight: 20,backgroundColor:'#4278c3',border:'unset' }}
    //            >+</Button>
    // }

    // renderHeader=(year,patientId,fullName,patientBirthday)=>{
    //     return <Row style={{ padding: '10px 0 10px 20px' }}>
    //              <Col span={5}>
    //                  <span style={{ fontSize:18,fontWeight:'bold' }}>Appointments</span>
    //              </Col>
    //              <Col span={2}>
    //                  <span style={{ fontSize:18,fontWeight:'bold' }}>{year}</span>
    //              </Col>
    //              <Col span={2}>
    //                  { this.renderNavButton(year) }
    //              </Col>
    //              <Col span={15}>
    //                  { this.renderCreateAppointmentButton(patientId,fullName,patientBirthday)}
    //              </Col>
    //            </Row>
    // }

    render(){
        const { 
          patientName,patientId, postItList ,loadingPostIt,patientBirthday,
          className,isInChat,nextVisit,doctor,currentProgram, loadingNextVisit, refetchNextVisit 
        } = this.props;
        // if(loading) {
        //     return <div style={{
        //                         display: 'flex',
        //                         justifyContent: 'center',
        //                         alignItems: 'center',
        //                         height: '40px'
        //                       }}>
        //              <IHLoading/>
        //           </div>;
        // }
        const postCount = _.get(postItList,'length',0);
        const nextVisitInfo = loadingNextVisit ? ''
          : (nextVisit ?
          `${types[nextVisit.type]||additionalFollow_Up_Types[nextVisit.type]} at ${moment(nextVisit.appointmentAt).format('MM/DD/YYYY hh:mm a')}`
          : 'No Upcoming Appointment');
        const header = <Row onClick={() =>
                        {Mixpanel.track('Open', 'Charting_Button', 'Patient Profile Page', {PATIENT_ID: this.props.patientId})}}>
                        <Col span={isInChat ? 24 : 16}>
                          Next Appointment: {nextVisitInfo}
                        </Col>
                        <Col span={isInChat ? 24 : 8} >
                          {I18N.get('stickyNote.pluralLabel')}:
                          { postCount ?
                              <Badge style={{ marginLeft:5,marginBottom:2 }}
                                count={postCount}
                                showZero={false}
                              /> : <span>{` ${postCount}`}</span>
                          }
                        </Col>
                       </Row>;

        return <Collapse className='patientAppointCollapse'>
                <Panel header = {header} >
                    <div className={className} ref={this.ref}>
                        <Row style={{ display:'flex' }}>
                            <Col span={1}></Col>
                            <Col span={15} style={{ marginTop:20 }} >
                                    <PatientAppointmentComponent 
                                      patientId={patientId} patientName={patientName}
                                      doctor={doctor}
                                      currentProgram={currentProgram}
                                      patientBirthday={patientBirthday} 
                                      nextVisit={nextVisit}
                                      router={this.props.router} route={this.props.route}
                                      patient={this.props.patient}
                                      isInChat = {this.props.isInChat}
                                      refetchUser={this.props.refetchUser}
                                      refetchNextVisit={refetchNextVisit}
                                      timerName={this.props.timerName}
                                    />
                            </Col>
                            <Col span={7} style={{ margin: '20px 0px 0 20px', display:'flex' }}>
                                    <PostItContainer patientId = { patientId } postItList = { postItList } loading = { loadingPostIt } />
                            </Col>
                            <Col span={1}>

                            </Col>
                        </Row>
                    </div>
                </Panel>
               </Collapse>
    }
}
const nextVisit = graphql(visitList,{
    options: (ownProps) => {
        const { filter,patientId } = ownProps;
        const  variables = {
          count: 5, // give extra count to prevent rare cases
        }
        let startDate = moment().startOf('day');
        variables.appointmentFrom = Number(startDate);
        variables.memberId = patientId;

        return {
            variables,
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ data }) => {
        const { loading, visitList, refetch } = data;

        if(loading){
            return {
              loadingNextVisit: loading
            };
        }

        if(visitList&&visitList.data) {        
          const nextVisits = _.filter(visitList.data, v => {
            // sync 3-hour logic for No Show In-clinic visits
            const threeHourInMs = 3 * 60 * 60 * 1000;
            const now = new Date().getTime();
            const visitTime = _.get(v, 'appointmentAt');
            const isPast3Hour = visitTime && (now - visitTime >= threeHourInMs);

            return !isPast3Hour && !v.checkoutAt;
          });
          return {
            nextVisit:_.first(nextVisits,{}),
            refetchNextVisit: refetch
          }
        }
    }
})
const postItListData = graphql(PostItList,{
    options:(ownProps)=>{
        const { patientId } = ownProps;
        return {
            variables:{ memberId : patientId },
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'network-only'
        }
    },
    props:({owProps,data}) =>{
        const { loading, postItList } = data;
        if(loading){
            return {
                loadingPostIt: loading
            };
        }

        if(postItList){
            return {
                postItList
            }
        }
    }
})


const mapToDispatch = (dispatch)=>{
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(modalActions.confirm(props)),
        openModal:(content,props)=>dispatch(openModal(content,props)),
    }
}

export default compose(connect(null,mapToDispatch), nextVisit,postItListData)(PatientAppointmentContainer);

