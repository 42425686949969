import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import helper from '../helper';
import { goPath } from '../../../lib/utils';

const ORG_BREADCRUMBS_KEY = 'orgBreadcrumbs';

const getOrgBreadCrumb = organization => {
  const { id, name, internalName, leaf, parent } = organization || {};
  const parentId = _.get(parent, 'id');

  const orgName = leaf ? (internalName || '') : (name || '');
  const type = leaf ? 'clinic' : 'area';
  const path = `/${type}/${id}`;
  return { id, name: orgName, path, parentId }; 
};

const getOrgBreadCrumbs = organization => {
  // generate path from org hierarchy for current viewing org
  const currentUser = helper.getCurrentUser();
  const isSuperAdmin = _.get(currentUser, 'selectedRole.name') === 'Super Admin';
  const currentLoginOrg = _.get(currentUser, 'selectedRole.organization') || {};
  let currentLoginOrgPath = isSuperAdmin ? 'areas' : 'organizations';
  currentLoginOrgPath = helper.addPrefixAndGoTo(currentLoginOrgPath, {}, false);

  let paths = [];
  const orgBreadCrumb = getOrgBreadCrumb(organization);

  if(currentLoginOrg.id === organization.id) {
    orgBreadCrumb.path = currentLoginOrgPath;
  } else {
    const breadCrumbs = JSON.parse(sessionStorage.getItem(ORG_BREADCRUMBS_KEY)) || [];

    paths = [...breadCrumbs];
    const associatedIndex = _.findIndex(paths, { id: orgBreadCrumb.parentId });
  
    if(associatedIndex > -1){
      paths.splice(associatedIndex+1);
    }
  }
  paths.push(orgBreadCrumb);

  // uniqBy is to prevent adding the same org to breadcrumbs
  paths = _.uniqBy(paths, 'id');

  sessionStorage.setItem(ORG_BREADCRUMBS_KEY, JSON.stringify(paths));
  return paths;
  // const setPath = org => {
  //   const { id, name, internalName, leaf, parent } = org || {};
  //   const orgName = leaf ? (internalName || '') : (name || ''); 
  //   if(id) {
  //     let path = '';
  //     if(id === currentLoginOrg.id) {
  //       path = currentLoginOrgPath;
  //       paths.unshift({ id, name: orgName, path }); 
  //     } else {
  //       const type = leaf ? 'clinic' : 'area';
  //       path = `/${type}/${id}`;
  //       paths.unshift({ id, name: orgName, path }); 
  //       setPath(parent);
  //     }     
  //   }
  // };
  // setPath(organization);
  // if(_.get(_.first(paths), 'id') !== currentLoginOrg.id){
  //   // current/ top org is not found in org hierarchy, 
  //   // hide / remove the first one
  //   paths.shift();
  //   // add "..." as More orgs might be in between
  //   paths.unshift({ id: 'more-paths', name: '...' });
  //   // add current/ top org 
  //   const { id, name, internalName, leaf } = currentLoginOrg;
  //   const orgName = leaf ? (internalName || '') : (name || ''); 
  //   paths.unshift({ id, name: orgName, path: currentLoginOrgPath });
  // }

  // return paths;
};
class OrgBreadCrumbs extends React.Component {
  constructor(props){
    super(props);
    this.state = { 
      currentViewingOrg: {},
      breadCrumbs: [], // { id, name, path, parentId }
    }
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    let newState = {...state};
    if(nextProps.organization &&
        !_.isEqual(nextProps.organization, state.currentViewingOrg)
      ) {
      const { organization } = nextProps;
      newState = {
        ...newState,
        currentViewingOrg: organization,
        breadCrumbs: getOrgBreadCrumbs(organization)
      };
    }
    return newState;
  };

  render() {
    const { breadCrumbs } = this.state;
    const isLeaf = helper.checkIsCurrentOrganizationLeaf();
    return <div style={{ margin: '0px 0px 10px 25px' }}>
      {
        !isLeaf && breadCrumbs.length > 1 &&
        <Breadcrumb 
          routes={breadCrumbs}
          itemRender={(route, params, routes, paths) => {
            const { id, name, path} = route || {};
            const isLast = _.findIndex(routes, { id }) === routes.length - 1;
            const isClickable = path && !isLast;
            return id && <a 
                key={id}
                onClick={() => goPath(path)}
                disabled={!isClickable}
              >
                {name}
              </a>;
            }}
          />
      }
      </div>;
  };
};

OrgBreadCrumbs.propTypes = {
  organization: PropTypes.object.isRequired
};

export default OrgBreadCrumbs;