import React from 'react';
import { useCGMContext } from './CGMContext';

export const makeCGMContextConsumer = (Component) => {
  const Consumer = (props) => {
    const cgmContextValue = useCGMContext();

    return (
      <Component
        {...props}
        cgmContextValue={cgmContextValue}
      />
    );
  };

  return Consumer;
};
