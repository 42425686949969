import React from 'react';
import singleREMap from "../constant/SingleREColumnsMap";
import helper from '../helper/index';
const CompletedEnrollmentComponent = class extends React.Component{

    render() {
        const { remoteEnrollment } = this.props;
        return <div>{helper.renderSingleREColumns(singleREMap('COMPLETED',this),remoteEnrollment)}</div>
    }
}

export default CompletedEnrollmentComponent;

