import Client from 'libModule/gqlClient';
import SystemDynamicConfigListComponent from '../components/SystemDynamicConfigsListComponent';
import {Input, Modal, message} from 'antd';
import { React, IHButton } from 'ihcomponent';
import { getSystemDynamicConfigsList, createSystemDynamicConfig } from 'modulesAll/graphql/systemDynamicConfigs';
import { SYSTEM_CONFIG_COUNT_MAX } from 'libModule/constants';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateModal: false,
      createKey: '',
      createValue: '',
      configsDataSource: null,
      searchStr: '',
      onSearchLoading: false,
    };
    this.startSearch = _.debounce(this.startSearch, 1000);
  }

  componentDidMount = () => this.loadDataSource();

  loadDataSource = () => {
    Client.query({
      query: getSystemDynamicConfigsList,
      variables: {
        count: SYSTEM_CONFIG_COUNT_MAX,
      },
      fetchPolicy: 'network-only'
    }).then(res => {
      this.setState({ configsDataSource: _.get(res, 'data.getSystemDynamicConfigs.data', [])});
    });
  }

  onTableSearchChange = searchStr => this.setState({ searchStr, onSearchLoading: true }, () => this.startSearch());

  startSearch = () => this.setState({ onSearchLoading: false });

  renderCreateModal = () => <Modal
    title = 'Create A Config'
    visible={true}
    onCancel={() => this.setState({ showCreateModal: false })}
    footer={[
      <IHButton label='Cancel' key='back' onClick={() => this.setState({ showCreateModal: false })} />,
      <IHButton label='Done' key='submit' type="primary" onClick={() => this.handleSubmit()} />
    ]}>
    <Input placeholder="config key" onChange={e => this.setState({ createKey: e.target.value })} style={{marginBottom: '8px'}}/>
    <Input placeholder="config value" onChange={e => this.setState({ createValue: e.target.value })}/>
  </Modal>

  handleSubmit() {
    const { createKey = '', createValue = '' } = this.state;
    if(!createKey || !createValue) {
      return Modal.error({ content: 'Missing key or value' });
    }
    Client.mutate({
      mutation: createSystemDynamicConfig,
      variables: {
        key: _.trim(createKey),
        value: createValue
      },
    }).then(() => {
      this.setState({ showCreateModal: false }, () => this.completeAction('Created successfully.'));
    }).catch(e => {
      console.error(e);
      Modal.error({ content: _.get(_.split(e.message, ':'), '2', e.message) });
    });
  }

  completeAction = successMsg => {
    message.success(successMsg);
    this.loadDataSource();
  }

  render() {
    const buttonProps = {
      label: 'Create New Config',
      size: '',
      type: 'primary',
      onClick: () => {
        this.setState({ showCreateModal: true, createKey: '', createValue: '' });
      },
      style: { marginRight: '23px' }
    };

    return (
      <div className="vsm-main-page vsm-patientList-main">
        <div className="vsm-main-990">
          <div className="v-rd">
            <IHButton {...buttonProps} />
          </div>
          <div className="v-table">
            <SystemDynamicConfigListComponent  
              configsDataSource={this.state.configsDataSource} 
              searchStr={this.state.searchStr}
              onTableSearchChange={this.onTableSearchChange}
              onSearchLoading={this.state.onSearchLoading}
              completeAction={this.completeAction}
            />
          </div>
          {this.state.showCreateModal && this.renderCreateModal()}
        </div>
      </div>
    );
  }
}

export default Component;