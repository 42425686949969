/**
 * Created by zizhangai on 1/9/17.
 */
import React from 'react';
import { createContainer, programConditionMap } from 'libModule/utils'
import { graphql } from 'react-apollo'
import actions from '../actions/MyProgramsActions'
//queries
import { enrolledProgramListForPatient as enrolledProgramList } from 'graphqlModule/enrolledProgramList'
import Component from '../components/MyProgramsComponent'
import get from 'lodash/get'
import { addTransform, CommonContainer } from 'libModule/helpers/container-helpers'
import { variables } from '../constants/MyProgramsConstants'

const searchFields = ['name']

const container = class extends CommonContainer {
  static displayName = 'patientView/myPrograms/containers/MyProgramsContainer'

  render() {
    return <Component {...this.props} />
  }
}

const ContainerWithData = graphql(enrolledProgramList, {
  options: (ownProps)=>{
    return {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  },
  props: ({ownProps, data})=>{
    const { loading, enrolledProgramList } = data || {}
    const { sorter, searchText } = ownProps
    const dataSource = addTransform(get(enrolledProgramList, 'data'), { sorter, searchText, searchFields })
                        .map(v => ({...v, healthCondition: programConditionMap[v.healthCondition]}))
                        .filter(p => (p.status !== 'CREATED'))
    return {
      loading,
      dataSource
    }
  }
})(container)

const mapState = ({MyPrograms}, ownProps)=>{
  return {
    ...MyPrograms
  }
}

export default createContainer(ContainerWithData, mapState, actions)
