const IMG_PATH = '/image/workstation';
export const UNKNOWN_GOAL_STATUS_IMG = `${IMG_PATH}/assessment-unknown-status.svg`;

export const GOAL_STATUS_ENUM = _.mapKeys(['MEETING', 'UNABLE_TO_CONCLUDE','NOT_MEETING']);

export const GOAL_STATUS_LIST = [
  {
    label: 'Meeting',
    value: GOAL_STATUS_ENUM.MEETING,
    icon: IMG_PATH + '/assessment-meeting-status.svg'
  },
  {
    label: 'Not Meeting',
    value: GOAL_STATUS_ENUM.NOT_MEETING,
    icon: IMG_PATH + '/assessment-not-meeting-status.svg'
  },
  {
    label: 'Unable to conclude',
    value: GOAL_STATUS_ENUM.UNABLE_TO_CONCLUDE,
    icon: IMG_PATH + '/assessment-unable-to-conclude-status.svg'
  }
];