export const addPatientToHistory = (patient)=>{
    return {
        type:'ADD_PATIENT_TO_HISTORY',
        payload: patient
    }
};

export const setMonthlyReviewStatus = (status) => {
    return {
        type: 'SET_MONTLY_REVIEW_STATUS',
        payload: status
    }
}

export const setMonthlyReviewOpen = (showMonthlyReviewTab) => {
    return {
        type: 'SET_MONTLY_REVIEW_OPEN',
        payload: showMonthlyReviewTab
    }
}

export const setMonthlyReviewType = (type) => {
    return {
        type: 'SET_MONTLY_REVIEW_TYPE',
        payload: type
    }
}
