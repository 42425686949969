import gql from 'graphql-tag'
export default gql`
  mutation manageTeamForEnrolledProgram(
    $id: EID!,
    $teamId : [EID]!,
    $managerId : [EID]
    ){
      manageTeamForEnrolledProgram(id:$id,teamId:$teamId,managerId:$managerId) {
        id
        name

    }
  }

`
