// Imports
import React from 'react';

const PreLoginLayout = (props) => {
  const { main } = props
  const logoUrl = window.VSM_CONFIG.DEMO ? '/logos/logo.png' : '/image/vsm/logo.png'
  return (
    <div className="prelogin">
      {/*<div className="rectangle-header">*/}
        {/*<img alt="vsm" src={logoUrl} className="vsm-logo"/>*/}

        {/*</div>*/}
        {main}
    </div>
  )
}

PreLoginLayout.displayName = 'layout/components/PreLoginLayoutComponent';

export default PreLoginLayout
