import { createContainer } from '../../../../lib/utils';
import SuspendConfirmComponent from '../components/SuspendConfirmComponent';
import suspendEnrolledProgram from 'modulesAll/graphql/suspendEnrolledProgram';
import { modalAction } from 'modulesAll/common/actions';
import actions from '../actions/Suspend'
import { RequestCache } from 'libModule/utils';
import { graphql } from 'react-apollo';
import _ from 'lodash'

const wrapGQL = graphql(suspendEnrolledProgram, {
  options: ownProps => ({
    variables: {
      id: ownProps.currentProgram && ownProps.currentProgram.id,
      reason: _.get(ownProps, 'suspend.reason')
    },
  //  refetchQueries: ['lightResultsFromToday']
  }),
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    suspend : state.patient.main.Suspend,
    startDate : ownProps.currentProgram.startDate,
    id: ownProps.currentProgram.id,
    reason: ownProps.currentProgram.reason,
    currentPatient: RequestCache.get('nurse/currentPatient'),
  }
};

const mapDispatchToProps = (dispatch, ownprops) => ({
  dispatch,
  closeModal: () => dispatch(modalAction.closeModal()),
  changeSuspendReason: (reason) => dispatch(actions.changeSuspendReason(reason))
});

const Container = createContainer(wrapGQL(SuspendConfirmComponent), mapStateToProps, mapDispatchToProps);

Container.displayName = 'patient/main/containers/SuspendConfirmContainer';

export default Container;
