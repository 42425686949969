import React from 'react';
import { compose } from 'react-apollo';
import {graphql} from "react-apollo";
import { insuranceListQuery } from '../query/index';
import  InsuranceCardComponent from '../component/InsuranceCardComponent';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {MyDoc} from "../component/InsuranccePDF";

const InsuranceCardContainer = class extends React.Component{
	constructor() {
		super();
		this.state = {
			preview: false,
		}
	}

	setPreview = (preview)=>{
		this.setState({ preview });
	}

	render() {
		const { setPreview,props,state } = this;
		const { loading,insuranceList } = props;
		const { preview } = state;

		if(loading) return <div></div>
		return <div>
			<InsuranceCardComponent insuranceList={insuranceList} setPreview={setPreview} preview={preview}/>
		</div>
	}
}

const withData = graphql(insuranceListQuery,{
	options:(props)=>{
		const { memberId } = props;
		return {
			variables:{
				memberId
			},
			fetchPolicy:'network-only'
		}
	},
	props:({data,props})=>{
		const { loading,insuranceList=[] } = data;
		if(loading) {
			return {
				loading
			}
		}
		return {
			insuranceList
		};
	}
})
export default compose(withData)(InsuranceCardContainer);