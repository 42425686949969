import { IHPanel, IHBaseForm, IHButton, React, IHLoading } from 'ihcomponent';
import I18N from '../../I18N';
import { verificationCodeValidator } from '../../../lib/validator';
import get from 'lodash/get';
import { submittingStyle } from 'libModule/utils'

const TITLES = I18N.get('onboard.step2');

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = { expanded: false };
    this.onExpand = this.onExpand.bind(this);
    this.validAndSubmit = this.validAndSubmit.bind(this);
  }

  validAndSubmit(e, { validateFieldsAndScroll }) {
    e.preventDefault();

    validateFieldsAndScroll((err, values) => {
      !err && this.props.submit(values);
    });
  }

  onExpand(evt) {
    this.setState({ expanded: true });
  }

  componentWillUnmount(){
    this.props.reset();
  }

  render() {
    const { fieldsValue, back, sendNewCode, isSubmitting } = this.props;
    const number = get(fieldsValue, 'number.value');

    const formProp = {
      onSubmit: this.validAndSubmit,
      itemList: [
        {
          key: 'code',
          label: (
            <div>
              <span>{TITLES.verificationTitle}</span>
              <button className="link-like" type="button" onClick={() => sendNewCode()}>{TITLES.sendNew}</button>
            </div>
          ),
          type: 'text',
          placeholder: TITLES.verificationPlaceholder,
          rules: [
            { validator: (rule, value, callback) => callback(verificationCodeValidator(value)) },
          ],
        },
      ],
    };

    const bodyElem = (
      <IHBaseForm {...formProp}>
        <div className="buttons-wrap">
          <IHButton bsStyle="default" type="button" label={TITLES.buttonBack} onClick={back} size="large" />
          <IHButton bsStyle="primary" disabled={isSubmitting} type="submit" size="large" style={submittingStyle(isSubmitting)}>
            {isSubmitting ? <IHLoading /> : TITLES.buttonVerify}
          </IHButton>
        </div>
      </IHBaseForm>
    );

    const headerEleme = (
      <div>
        <h3>{TITLES.title}</h3>
        <p>{`${TITLES.description} xxxx-${number}. ${TITLES.descriptionPart2}`}</p>
      </div>
    );

    const footerElem = (
      <div>
        <h5 onClick={this.onExpand}>{TITLES.footerTitle}</h5>
        {this.state.expanded && (
          <div className="detail">
            <button className="link-like" type="button" onClick={back}>{TITLES.footerDetailGoback}</button>
            <span dangerouslySetInnerHTML={{ __html: TITLES.footerDetail }} />
          </div>
        )}
      </div>
    );

    const panelProps = {
      header: headerEleme,
      body: bodyElem,
      //footer: footerElem,
    };

    return (
      <div className="vsm-auth-box vsm-new-box auth-step2">
        <IHPanel {...panelProps} />
      </div>
    );
  }
}
