/**
 * Created by zizhangai on 1/9/17.
 */
import types from '../constants/MyProgramsConstants';
import { commonReducer } from 'libModule/helpers/reducer-helpers.js'

// Default State
const initState = {
  sorter: { field: 'startDate', order: 'ascend' },
  filter: {},
  pagination: {pageSize: 5},
  currentPage: 1
}

const ownReducer = (state=initState, action) => {
  switch(action.type) {
    case types.RESET:
      return initState
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
