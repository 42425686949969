import type from '../constants/index';

export  default {
    startTimer: (displayName,event,patientId) => {
        return {
            displayName,
            event,
            patientId,
            type: type.START_TIMER
        }
    },
    pauseTimer: (displayName,event)=>{
      return{
          displayName,
          event,
          type:type.PAUSE_TIMER
      }
    },
    resumeTimer:(displayName,event)=>{
        return{
            displayName,
            event,
            type:type.RESUME_TIMER
        }
    },
    stopTimer:(displayName,event)=>{
        return{
            displayName,
            event,
            type:type.STOP_TIMER
        }
    },
    abort:(displayName,event)=>{
        return{
            displayName,
            event,
            type:type.ABORT
        }
    },
    resetTimer:(displayName,canRestUUid,flag)=>{
        return{
            displayName,
            canRestUUid,
            type:type.RESET_TIMER
        }
    },
    updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>{
        return{
            displayName,
            apiName,
            resource,
            docId,
            summary,
            type:type.UPDATE_INTERVENTION_DETAILS
        }
    },
    setUUId:(uuid)=>{
        return{
            uuid,
            type:type.SET_UUID
        }
    },
    resetCoolDown:(displayName,cooldown)=>{
        return{
            displayName,
            cooldown,
            type:type.RESET_COOLDOWN
        }
    },
    pauseTimeOut:(displayName)=>{
        return{
            displayName,
            type:type.TIMEOUT_PAUSE
        }
    },

    startChat:(displayName)=>{
        return{
            displayName,
            type:type.START_CHAT
        }
    },
    resumeTimeOut:(displayName)=>{
        return{
            displayName,
            type:type.TIMEOUT_RESUME
        }
    },
    focusPage:(displayName)=> {
        return {
            displayName,
            type: type.FOCUS_PAGE
        }
    },
    blurPage:(displayName)=>{
        return{
            displayName,
            type:type.BLUR_PAGE
        }
    },
    clearUUId:()=>{
        return {
            type:type.CLEAR_UUID
        }
    }
}
