import gql from 'graphql-tag';
export default gql`mutation unassignLoanDevice($deviceId:String,$memberId:EID!) {
  unassignLoanDevice(
    deviceId:$deviceId,
    memberId:$memberId
  ) {
    deviceId
    serialNumber
    osVersion
    appVersion
    status
    member {
      profile {
        firstName
        lastName
      }
    }
  }
}`;
