import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
// import editMemberProfile from 'graphqlModule/mutation/editMemberProfile'
import editUser from 'graphqlModule/mutation/editUser';
import { goPath, FormHelper, convertToFieldsValue, getRouterParam, setAsyncRouteLeaveHook, GQLHelper } from 'libModule/utils'
import { IHBaseFormWithRow, IHButton, message } from 'ihcomponent'
import { getFormProps } from 'patientModule/profileEdit/components/formData/healthConditionForm'
import actions from 'patientModule/profileEdit/actions'
import moment from 'moment'
import { modalAction } from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import _ from 'lodash'
import UserClass from 'modulesAll/graphql/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import i18nMap from '../../../I18N/index';
import convertor from '@ihealth/convertor';
import Client from 'libModule/gqlClient';
import userGQL from 'graphqlModule/user'
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import patient from "../../../utils/constants/rolePath/patient";

class Container extends Component {
  static displayName = 'patient/profileEdit/containers/HealthConditionEditContainer'

  componentWillMount() {
    // console.log("-------",this.props)
    const { userData, updateForm } = this.props
    const initData = this.convertUserDataToFormData(userData)
    const fieldsValue = convertToFieldsValue({healthConditions: initData.healthConditions})
    updateForm(fieldsValue)
  }
  componentWillUnmount() {
    this.props.updateForm()
  }


  routerWillLeave() {
    const { openConfirmModal } = this.props
    const submit = this.submit.bind(this)
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      openConfirmModal(resolve, submit)
    })
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()
    // console.log(this.props);
    return (
      <div className='vsm-user-edit-page'>
        <div className='user-edit-form v-form-required'>
          {body}
          {footer}
        </div>
      </div>
    )
  }
  convertUserDataToFormData() {
    const { userData } = this.props
    const userClassData = new UserClass(userData)
    // setPagePath(`Patients / ${userData.profile.fullName} / Edit Profile`)
    const { role, username } = userData


    const initData = {}

    if (userClassData.variables.profile.healthConditions && userClassData.variables.profile.healthConditions.length > 0) {
      initData.healthConditions = userClassData.variables.profile.healthConditions;
    }
    else {
      initData.healthConditions = [];
    }

    return initData
  }
  renderBody() {
    const { onFieldsChange, fieldsValue, userData } = this.props
    const userClassData = new UserClass(userData);

    if (!fieldsValue) return
    const user = userClassData.variables;

    const formProps = getFormProps({ onFieldsChange, fieldsValue, user })
    // console.log(formProps);
    return <div>
      <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
    </div>
  }
  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          goPath(`/patients/profile/${this.props.id}`)
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save',
        style: {
          marginRight: 10
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} />
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  getMutateVariable(data) {
    let memberProfile = {}
    const user = this.props.userData;

    if(data.healthConditions) {
      memberProfile.healthConditions = data.healthConditions;
    }

    const result = {
      id: this.props.id,
      memberProfile,
    }

    return result
  }

  async submit() {
    const { mutate, openErrorModal, handleFormSaved, fieldsValue } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    const user = this.props.userData
    const patientNRIC = _.get(user, 'identification[0].value')
    const patientName = _.get(user, 'profile.fullName')
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) return
    const variables = this.getMutateVariable(formValue)

    try{
      await handleFormSaved()
      const res = await mutate({ variables })

      if(res.data.editUser){
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.editProfile'),
          patientNRIC,
          patientName,
          details: _.omit(variables, 'id'),
          request: variables,
          response: res
        })
        message.success('Edit patient healthcondtions success')

        // 2/26/18 - JV - Update user data after health conditions are added (IH-286)
        const variables = {
          id: this.props.id
        };
        Client.query({
          query: userGQL,
          variables: { ...variables },
          fetchPolicy: 'network-only'})
          .then( res => {
            goPath(`/patients/profile/${this.props.id}`);
          })
          .catch( err => console.log('Error user query: ', err))
          .finally( () => goPath(`/patients/profile/${this.props.id}`));
      }
    }catch(err){
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.editProfile'),
        patientNRIC,
        patientName,
        details: _.omit(variables, 'id'),
        request: variables,
        response: err,
        success: false
      })
      openErrorModal(GQLHelper.formatError(err))
    }
  }
}
Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(editUser)(Container)

const mapState = (state, ownProps) => {
  const id = getRouterParam( state, 'patientId')
  return {
    ...state.patient.profileEdit,
    id
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    updateForm: (initData) => dispatch(actions.updateForm(initData)),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openConfirmModal: (resolve, submit) => dispatch(modalAction.openConfirmModal(resolve, submit)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
  }
}

Container.propTypes = {
  id: PropTypes.string,
  userData: PropTypes.object,
  onFieldsChange: PropTypes.func,
  updateForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  route: PropTypes.object,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  isFieldsRequired: PropTypes.object,
  isFormValid: PropTypes.bool,
  isFormSaved: PropTypes.bool,
  setBreadCrumb: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openErrorModal: PropTypes.func,
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
