// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers'
import { actionTypes as ListTypes } from './AssessmentListDuck'
// Reducer

/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Assessment', 'Common', [
  'CHANGE_PAGE',
  'FORM_PAGE',
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  // 'changePage'
]);

actionCreators.initList = (selectedProgram)=>{
  return {
    type : ListTypes.INIT_DATA,
    selectedProgram
  }
}

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  // currentPage: actionTypes.CAREPLAN_MAIN_PAGE
}

export default (state=initState, action) => {
  switch(action.type) {
    // case actionTypes.CHANGE_PAGE:
    //   return {
    //     ...state,
    //     currentPage: action.currentPage ? action.currentPage : initState.currentPage
    //   }
    default:
      return state
  }
}
