import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HiatusInfoModal from './InfoModal';

const Indicator = props => {
  const { shouldShowModal = true, children, ...restProps } = props;
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfoModal = () => {
    if(shouldShowModal)
      setShowInfo(!showInfo);
  };

  let Component = children;
  if(typeof children === 'function')
    Component = () => children(toggleInfoModal);

  return (
    <React.Fragment>
      <Component toggleInfoModal={toggleInfoModal}/>
      <HiatusInfoModal visible={showInfo} handleCancel={() => setShowInfo(false)} {...restProps}/>
    </React.Fragment>
  );
};

Indicator.Clickable = (props) => {
  const { 
    component: ChildComponent,
    ...restProps
  } = props;

  return <Indicator {...restProps}>
    {
      toggleInfoModal => 
      <ChildComponent onClick={toggleInfoModal}/>
    }
  </Indicator>;
};

Indicator.Clickable.propTypes = {
  component: PropTypes.func.isRequired
};

export default Indicator;