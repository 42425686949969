import React from 'react';
import { compose } from 'react-apollo';
import { Form, Button, Tooltip, Icon } from 'antd';
import PropTypes from 'prop-types'

import ComplexityInfoComponent from '../../patient/main/components/ComplexityInfoComponent';
import AssigneeOptionDropdown from '../component/AssigneeOptionDropdown';

import {COMPLEXITY_LEVELS} from '../../../lib/constants';
import { withAssigneeOptions } from '../API/query';

const NOT_NECESSARY = 'not-necessary';

const isMissingAssigneeData = (assigneeId, list = []) => {
  return _.findIndex(list, { memberId: assigneeId }) === -1;
};

const AssigneeFormContainer = (props) => {
  const { form, isEnrolled, complexityLevel, loadingAssigneeOptions, assigneeOptions, onSubmit } = props;
  const { getFieldDecorator, getFieldsValue } = form;

  const patient = _.get(props, 'patient.user') || props.patient;
  const complexityLevelText = COMPLEXITY_LEVELS[complexityLevel] || 'N/A';

  const renderOptions = (key, initialValue) => {
    const userList = _.get(assigneeOptions, key) || [];
    let missingAssigneeData;
    if (!loadingAssigneeOptions && initialValue && initialValue !== NOT_NECESSARY) {
      if (isMissingAssigneeData(initialValue, userList)) {
        const assigneeFullname = _.get(patient, `assignees.${key}.profile.fullName`) || '--';
        missingAssigneeData = {
          id: initialValue,
          fullName: assigneeFullname,
          renderLabel: (name) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{name}</div>
              <Tooltip 
                overlayClassName="missingAssigneeTip"
                title={`This assignee is not in the same care team with you or this participant is set as "Visitor"\
                              in your care team. Please inform your manager if needed.`}
              >
                <Icon type="info-circle" theme="filled" style={{ color: 'red', marginLeft: 3  }}/>
              </Tooltip>
            </div>
          )
        };
      }
    }
    return <AssigneeOptionDropdown 
      isLoading={loadingAssigneeOptions}
      patient={patient}
      userList={userList}
      complexityLevel={complexityLevel}
      missingAssigneeData={missingAssigneeData}
    />
  }

  const getFormItems = () => {
    const assignedCA =  _.get(patient, 'assignees.assignedToCA', null);
    const assignedRdHC = _.get(patient, 'assignees.assignedToRD', null);
    const assignedToCANotNecessary = _.get(patient, 'assignees.assignedToCANotNecessary') ? NOT_NECESSARY : null;
    const assignedToRDNotNecessary = _.get(patient, 'assignees.assignedToRDNotNecessary') ? NOT_NECESSARY : null;
    return [{
			key:'assignedToRD',
      label: 'RD/HC',
      rules: {
        initialValue: _.get(assignedRdHC, 'id', assignedToRDNotNecessary)
      }
		},{
			key:'assignedToCA',
      label:'CA',
      rules: {
        initialValue: _.get(assignedCA, 'id', assignedToCANotNecessary)
      }
		}]
  }

  const renderButton = () => {
		const values = getFieldsValue();
    const hasValue = _.some(values,(v,k)=> v);
		return <div>
      <Button 
        type='primary'
        disabled={!hasValue} 
        onClick={() => onSubmit(values)}
      >
        Submit
      </Button>
    </div>
	}

  const renderForm = () => {
    const formItems = getFormItems();
    return formItems.map(({key,rules,label})=>{
			return <Form.Item key={key} label={label}>
				{getFieldDecorator(key,rules)(renderOptions(key, rules.initialValue))}
			</Form.Item>
		})
  };

  return(
    <div>
      <div className='title'>Assign Care Team members to patient</div>
      <div className='complexLevel'>Patient's Condition Complexity Level: {complexityLevelText}
        <ComplexityInfoComponent isEnrolled={isEnrolled}>
          <Tooltip trigger='hover' title='What is Condition Complexity Level?'>
            <Icon type='question-circle' />
          </Tooltip>
        </ComplexityInfoComponent>
      </div>
      <Form>
        { renderForm() }
      </Form>
      { renderButton() }
    </div>
  );

};

AssigneeFormContainer.propTypes = {
  patient: PropTypes.object, 
  isEnrolled: PropTypes.bool, 
  complexityLevel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}
const EnhancedContainer = Form.create({})(AssigneeFormContainer);
export default compose(
  withAssigneeOptions
)(EnhancedContainer);