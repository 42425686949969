import { React, IHTooltip, _ } from 'ihcomponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from '../../../I18N';

export default class extends React.Component {
  static displayName = 'patientList/main/components/PatientStarComponent'

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.stopPropagation();
    const { showPatientStarModal, user, onChange,enrolledPrograms, hasStar } = this.props;
    const { id,profile } = user;
    const patientName = user._get('profile.fullName');
    const memberId = user._get('id');
    const enrolledProgramsOnly = _.filter(enrolledPrograms,p=>p.status=="STARTED");
    this.props.setModalData({ showModal:true,modalData:{ patientName,memberId,showCreateFormOnly: !hasStar,enrolledPrograms:enrolledProgramsOnly }});
    if (onChange) {
      onChange(!hasStar);
    }
    if(hasStar) {
      Mixpanel.track('clicked','untag','patients page', {PATIENT_ID : user.key});
    } else {
      Mixpanel.track('clicked','tag','patients page', {PATIENT_ID : user.key});
    }
  }

  render() {
    const { user,enrolledPrograms, hasStar} = this.props;

    const text = hasStar ? I18N.get('taskAssignment.patient.viewTask') : I18N.get('taskAssignment.patient.addTask');
    const className = hasStar ? 'fa fa-star' : 'fa fa-star-o';

    return (
      <IHTooltip overlayClassName="vsm-tooltip" title={text} placement="right">
        <a onClick={this.onClick} className="v-star v-y">
          <i className={className} />
        </a>
      </IHTooltip>
    );
  }
};
