import React, { useState } from 'react';
import { Popover, Button } from 'antd';

import HiatusMainContainer from '../containers/MainContainer';

import '../styles/popover.scss';

const HiatusPopupButton = props => {
  const { user, hiatusStatus, refetchHiatusStatus, popupProps, customBtnTxt } = props;
  const [showPopup, setShowPopup] = useState(false);
  const hasHiatus = !!_.get(hiatusStatus, 'id');

  const buttonText = customBtnTxt || (!hasHiatus ? 'Put patient in hiatus status' : 'View hiatus info');

  const handleFinish = (res) => {
    setShowPopup(false);
    if(refetchHiatusStatus)
      refetchHiatusStatus();
  };

  return (<div id='hiatus-status-patient-detail-button'>
    <Popover
      id='hiatus-status-patient-detail-popover'
      trigger='click'
      placement='bottomLeft'
      visible={showPopup}
      content={<HiatusMainContainer 
        user={user}
        hiatusStatus={hiatusStatus}
        onSubmit={handleFinish}
        onCancel={() => setShowPopup(false)}
        onResume={handleFinish}
      />}
      destroyTooltipOnHide
      {...popupProps}
    >
      <Button onClick={() => setShowPopup(!showPopup)}>
        {buttonText}
      </Button>
    </Popover>
  </div>);
};

export default HiatusPopupButton;