import React, { useCallback } from 'react';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { Input } from 'antd';
import { getTranslate } from '../API';
import '../style/index.scss';
import chatPopoverHelper from "../../chat/helper/chatPopoverHelper";
const { TextArea } = Input;

const TranslationInput = ({ targetLanguage, placeholder, onPressEnter, value, translatedValue, onChange }) => {
  const hasTranslation = !!translatedValue; 

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    if (onChange) onChange({ value, translatedValue: '' });
  }, [onChange]);

  const translate = useCallback(_.debounce(async (value) => {
    Mixpanel.track('clicked', 'translation', 'foodlog_comment');
    const val = await getTranslate(value, targetLanguage);
    const translatedValue = chatPopoverHelper.decodeHTML(_.get(val, 'data.translations.0.translatedText', ''));
    onChange({ value, translatedValue });
  }, 500), [onChange, targetLanguage]);

  return (
    <div className="translation-container">
      <Input.Group compact>
        {hasTranslation &&
          <React.Fragment>
            <div className="translated-text-container">
              <div className="translated-text-content-wrapper">
                <div className="translated-text-content">
                  {translatedValue.replaceAll('<br/>', '\n')}
                </div>
                <span className="translated-text-note" style={{ fontStyle: 'italic' }}>
                  (Translated by Google)
                </span>
              </div>
            </div>
          </React.Fragment>
        }
        <TextArea
          className={'inputBox' + (hasTranslation ? ' with-translation' : '')}
          type="text"
          value={value}
          placeholder={placeholder}
          autoSize={{ minRows: 2, maxRows: 4 }}
          onChange={handleChange}
          onPressEnter={onPressEnter}
        />
        {value && targetLanguage !== 'English' && 
          <img
            className={'translate-btn' + (hasTranslation ? ' with-translation' : '')}
            onClick={() => { translate(value); }} 
            src={hasTranslation ? '/image/translateWColor.svg' : '/image/translate.svg'}
          />
        }
        {hasTranslation &&
          <span className="translated-text-note" style={{ backgroundColor: '#FFFFFF', paddingTop: 6 }}>
            Note: Patient will receive translated message ONLY upon sending.
          </span>
        }
      </Input.Group>
    </div>
  );
}

export default TranslationInput;
