import { React, _, IHSearchTable, moment, IHButton } from 'ihcomponent';
import { browserHistory } from 'react-router';
import I18N from 'modulesAll/I18N';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'


export default class extends React.Component {
  static displayName = 'assessment/main/components/ListComponent'

  componentWillUnmount() {
    if (!new RegExp(/^((\/admin_preview_assessment)|(\/edit_assessment))/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null, true);
    }
  }
  getTableProps() {
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, assessmentList } = data;
    const filters = _.get(assessmentList, 'filters', []);

    return {
      title: `${I18N.get('keywords.Assessments')} (${_.get(assessmentList, 'pageInfo.total', 0)})`,
      rowKey: 'id',
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      onChange: onTableChange,
      inputPlaceholder: 'Search by Name',
      onRow: record => {
        return {
          onClick: () => {
            browserHistory.push(`/admin_preview_assessment/${record._id}`);
          }
        }
      },
      loading,
      dataSource: _.get(assessmentList, 'data', []),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(assessmentList, 'pageInfo.total', 0),
      },
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Health Condition',
          key: 'healthCondition',
          dataIndex: 'healthCondition',
          render: (value, row, index) => programConditionMap[value],
          filters: filters.map(key => ({text: programConditionMap[key], value: key})),
          filteredValue: _.get(filter, 'healthCondition', []),
          onFilter: (value, record) => record['healthCondition'].includes(value),
        },
        // {
        //   title: 'Created By',
        //   dataIndex: 'createdBy',
        //   key: 'createdBy',
        //   render : (t, o)=>{
        //     return _.get(t, 'profile.fullName')
        //   },
        // },
        // {
        //   title: 'Created On',
        //   dataIndex: 'createdAt',
        //   key: 'createdAt',
        //   sorter: true,
        //   sortOrder: _.get(sort, 'field', null) === 'createdAt' ? _.get(sort, 'order', false) : null,
        //   render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : '',
        // },
        // {
        //   title: 'Last Modified By',
        //   dataIndex: 'modifiedBy',
        //   key: 'modifiedBy',
        //   render: (t, o)=>{
        //     return _.get(t, 'profile.fullName')
        //   }
        // },
        {
          title: 'Last Modified On',
          dataIndex: 'modifiedAt',
          key: 'modifiedAt',
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'modifiedAt' ? _.get(sort, 'order', false) : null,
          render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : ''
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          width: '100px',
          render: (value, row) => (
            <IHButton
              label="Edit >" bsStyle="primary" className="ih-exsmall" onClick={(e) => {
                browserHistory.push(`/edit_assessment/${row._id}`);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
        ),
        },
      ],
    };
  }

  render() {
    return (
      <div className="vsm-main-page vsm-provider-list-main">
        <div className="vsm-main-table">
          <div style={{ textAlign: 'right' }}>
            <IHButton
              bsStyle="primary" style={{ margin: '0 0 12px 0' }} label={`Create New ${I18N.get('keywords.Assessment')}`} onClick={() => {
                browserHistory.push('/create_assessment');
              }}
            />
            <IHSearchTable {...this.getTableProps()} />
          </div>
        </div>
      </div>
    );
  }
}
