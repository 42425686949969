import React from 'react';
import PostItContainer from "../../postIt/containers/PostItContainer";
import {compose, graphql} from "react-apollo";
import PostItList from "../../graphql/postItList";


const PostItContainerWrapper = class extends React.Component{
    render() {
        const { props } = this;
        const { patientId, postItList,loadingPostIt, style } = props;
        return <PostItContainer patientId={patientId} postItList={postItList} loadingPostIt={loadingPostIt} style={style}/>
    }
}

const postItListData = graphql(PostItList,{
    options:(ownProps)=>{
        const { patientId } = ownProps;
        return {
            variables:{ memberId : patientId },
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'network-only'
        }
    },
    props:({ownProps:{ setRefetchPostItFn,refetchPostItFn },data}) =>{
        const { loading, postItList,refetch } = data;
        if(loading){
            return {
                loadingPostIt: loading
            };
        }
        if(setRefetchPostItFn&&!refetchPostItFn){
            setRefetchPostItFn(refetch);
        }
        if(postItList){
            return {
                postItList
            }
        }
    }
});

export default compose(postItListData)(PostItContainerWrapper);

