import types from '../constants/PatientTaskListConstants';
import {commonReducer} from 'libModule/helpers/reducer-helpers.js'

// Default State
const initState = {
  sorter: { field: 'createdAt', order: 'ascend' },
  pagination: {pageSize: 5},
  currentPage: 1
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.SET_REF_ID:
      return {
        ...state,
        refId: action.refId
      }
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
