import React, { useMemo, useState } from 'react';
import { Radio } from 'antd';
import { CGMLineChartComponent } from './CGMLineChartComponent';
import { CGMPointChartComponent } from './CGMPointChartComponent';
import { findMaxWithStep } from '../helpers';

const getData = (data) => data;

export const CGMChartComponent = ({
  data,
  fromDate,
  toDate,
}) => {
  const [value, setValue] = useState('lineChart');

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  const max = useMemo(() => (
    findMaxWithStep(
      [...getData(data)].map((data) => data.y),
      50,
      600,
    )
  ), [data]);

  return (
    <div>
       <Radio.Group value={value} onChange={handleChange}>
        <Radio.Button value="lineChart">Trend View</Radio.Button>
        <Radio.Button value="pointChart">Point View</Radio.Button>
      </Radio.Group>
      {value === 'lineChart' && (
        <CGMLineChartComponent
          data={data}
          fromDate={fromDate}
          toDate={toDate}
          max={max}
        />
      )}
      {value === 'pointChart' && (
        <CGMPointChartComponent
          data={data}
          fromDate={fromDate}
          toDate={toDate}
          max={max}
        />
      )}
    </div>
  );
};

