import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Icon, Modal, Select } from 'antd';

import ComplexityInfoComponent from './ComplexityInfoComponent';

import {COMPLEXITY_ENUM, COMPLEXITY_LEVELS} from '../../../../lib/constants';

const ComplexityEditComponent = (props) => {
  const { value, disabled, isEnrolled, onChange, onSubmit, previousUpdatedBy, showUpdatedByName = true } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false);

  const handleChange = (option) => {
    if(onChange) {
      onChange(option)
    }
  }

  const handleSubmit = () => {
    setIsSubmited(true);
    setShowEditModal(false);
    onSubmit();
  };

  const updatedBy = isSubmitted ? JSON.parse(sessionStorage.getItem('currentUser')) : previousUpdatedBy;
  const lastNameInitial = _.get(updatedBy, 'profile.lastName.0');
  const updatedByName = _.get(updatedBy, 'profile.firstName') + (lastNameInitial ? ` ${lastNameInitial}.` : '');
  const disableSel = (value)=> value === COMPLEXITY_ENUM.NULL_CODES;

  return (
    <div style={{ display: 'inline-block', marginLeft: 8, paddingTop: 1 }}>
      {
        showUpdatedByName && updatedByName &&
        <div style={{ display: 'inherit' }}>
          <span style={{ color: '#6B7178' }}>
            by {updatedByName}
          </span>
        </div>
      }
      <Button 
        type='link' 
        style={{ color: '#6B7178', padding: 0 }} 
        onClick={() => setShowEditModal(true)} 
        disabled={disabled}
      >
        <span style={{ textDecoration: 'underline' }}>
          Edit
        </span>
      </Button>
      <ComplexityInfoComponent isUnenrolled={!isEnrolled}>
        <Tooltip trigger='hover' title='What is Condition Complexity Level?'>
          <Icon type='question-circle' />
        </Tooltip>
      </ComplexityInfoComponent>
      <Modal 
        visible={showEditModal} 
        okText='Done' 
        onOk={handleSubmit}
        onCancel={() => {
          setShowEditModal(false);
          handleChange(value); // reset value
        }} 
      >
        <div>
          <h4 style={{ marginBottom: 20 }}>Please choose patient's Condition Complexity Level:</h4>
          <p style={{ marginBottom: 20 }}>
            Based on your clinical judgement, if you think patient need more intensive care, you might change patient's Condition Complexity Level.
          </p>
          <div>
            <label style={{ display: 'block', color: '#6B7178', fontSize: 12, fontWeight: 'normal' }}>
              Condition Complexity Level
            </label>
            <Select onChange={handleChange} value={value} style={{ width: 200, marginBottom: 20 }}>
              {_.map(COMPLEXITY_LEVELS, (v, k) => <Select.Option key={k} value={k} disabled={disableSel(k)}>{v}</Select.Option>)}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ComplexityEditComponent.propTypes = {
  previousUpdatedBy: PropTypes.object,
  value: PropTypes.any, 
  isEnrolled: PropTypes.bool.isRequired, 
  onChange: PropTypes.func.isRequired, 
  onSubmit: PropTypes.func.isRequired, 
};

export default ComplexityEditComponent;