import React, { useState } from 'react';
import { Button, Tooltip, Icon } from 'antd';

import WorkListContainer from '../../../../visitWorkList/container/workListContainer';
import SignOffSubmitInfo from '../SignOffSubmitInfo';

import VISIT_TYPE_ENUM from '../../../../visit/constants/map';
import { getTimeWithFormat } from '../../../helper/signOffHelper'

const SignOffVisit = (props) => {
  const { visits } = props;
  const [showWorklistFor, setShowWorklistFor] = useState(null);

  return <div className='signOffItem'>
    <div className='header'>
      Visit
    </div>
    {
      _.map(visits, (visit => {
        const { id, type, appointmentAt, checkoutAt, updatedAt } = visit;

        return <div key={id} className='signOffCard signOffVisit'>
          <div className='description'>
            {VISIT_TYPE_ENUM[type] || '--'} Visit; {getTimeWithFormat(appointmentAt)}
          </div>
          <Button type='link' onClick={() => setShowWorklistFor(visit)}>
            View visit worklist 
          </Button>
          <div 
            className='description' 
            style={{ textAlign: 'right', display: 'flex', justifyContent: 'end', marginTop: 10 }}
          >
            <SignOffSubmitInfo 
              submittedByLabel='Finished by:'
              submittedBy='Not able to detect'
              submittedAt={updatedAt}
            />
            <Tooltip 
              title={`Currently, the system cannot detect who finished the visit with this patient. For most cases, it's finished by your mentee HC, but there is a slight chance that another HC or RD finishes the visit. Please review the sections that the RD/HC is responsible for in the visit worklist anyway.`}
              overlayClassName='visitInfoTip'
            >
              <Icon type='info-circle' theme='filled' style={{ alignSelf: 'end', marginBottom: 3, marginLeft: 6 }} />
            </Tooltip>
          </div>
        </div>;
      }))
    }
    {
      !_.isEmpty(showWorklistFor) &&
      <WorkListContainer 
        id={showWorklistFor.id}
        appointmentAt={showWorklistFor.appointmentAt}
        memberId={_.get(showWorklistFor, 'member.id')}
        onCancel={() => setShowWorklistFor(null)}
      />
    }
  </div>;
}

export default SignOffVisit;