import gql from 'graphql-tag'

export default gql`
query getIoTDevice($deviceId: String, $memberId: EID, $position: Int) {
  getIoTDevice(deviceId: $deviceId, memberId: $memberId, position: $position){
    deviceId
    position
    member {
      id
      profile {
        firstName
        lastName
      }
    }
    position
  }
}`
