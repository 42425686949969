import React from 'react';
import ABTestingOrgTableConfig from "../../constant/ABTestingOrgTableConfig";
import {Input, Table} from "antd";
const { Search } = Input;
import '../../style/ABTestingConfig.scss';
const ABTestingConfigComponent = class extends React.Component{
   constructor(props) {
       super(props);
       this.state = {
           searchKey:''
       }
   }

   handleOnSearchChange = (searchKey)=>{
       this.setState({ searchKey })
   }

   renderSearchInput = ()=>{
       const { handleOnSearchChange } = this;
       return <Search onSearch={ handleOnSearchChange }/>
   }
    renderForm =()=>{
        const { orgList } = this.props;
        const { searchKey } = this.state;
        const columns = ABTestingOrgTableConfig;
        const filteredOrgList = !searchKey.trim().length ? orgList :  _.filter(orgList, (o) => new RegExp(searchKey,'i').test(o.internalName));
        return <div className='ABTestingWrapper'>
                { this.renderSearchInput() }
                <Table dataSource={filteredOrgList} columns={columns} rowKey={(r)=>r.id}/>
               </div>
    }
    render() {
        return this.renderForm()
    }
}

export default ABTestingConfigComponent;
