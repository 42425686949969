import gql from 'graphql-tag';

// 20200401 - Thong
// not fetch heart_rate from PO, removed
// not fetch measurement from type HR, removed

// filters: { memberId, type: [Vital] }
// Vital Enum: BG, BP, HS, HR, PO, ECG, TM
// TM: Thermometer
// PO: Pulse Oximeter
// sort { field: DATE, direction: [ASC, DESC] }
export default gql`
  query measureLatestByType($filters: MeasureListFilters, $sort: MeasureListSort) {
    measureLatestByType (
      filters: $filters
      sort: $sort
    ) {
      id
      type
      date
      deviceModel
      ... on BPMeasure {
        systolic_blood_pressure
        diastolic_blood_pressure
        heart_rate
      }
      ... on BGMeasure {
        blood_glucose
      }
      ... on HSMeasure {
        body_weight
        body_weight_change
      }
      ... on POMeasure {
        blood_oxygen
      }
      ... on TMMeasure {
        body_temperature
      }
      ... on ECGMeasure {
        startTime
        endTime
        averageHeartRate
        maxHeartRate
        totalHeartBeats
        abnormalPointsNumber
      }
    }
  }
`;