import React, { useMemo } from 'react';
import { useCellularDevicesContext } from '../../context/CellularDevicesContext';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularDeviceVitalDisplayComponent = () => {
  const {
    cellularDevices,
  } = useCellularDevicesContext();

  return (
    (cellularDevices || []).map(({
      brand,
      deviceType,
    }) => (
      <span key={brand} style={{ marginRight: 8 }}>
        {
          CellularDevicesHelpers.makeCellularDeviceName(
            deviceType,
            brand
          )
        }
      </span>
    ))
  );
};
