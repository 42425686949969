import types from 'patientModule/enrolledProgramInfo/Survey/constants/CommonConstants'
import { commonActions } from 'libModule/helpers/action-helpers'
import ListTypes from 'patientModule/enrolledProgramInfo/Survey/constants/ListConstants'

const commonActionsObject = commonActions(types, [
  // 'changePage'
])

const initList = (selectedProgram)=>{
  return {
    type : ListTypes.INIT_DATA,
    selectedProgram
  }
}

export default {
  ...commonActionsObject,
  initList
}
