import React from 'react';
import ReferralProgramEligibility from "../component/ReferralProgramEligibility";
import EnrollmentProgramEligibility from "../component/EnrollmentProgramEligibility";
import ReferredSuccess from "../component/ReferredSuccess";
import ScheduleInitialVisitComponent from "../component/ScheduleInitialVisitComponent";
import REConsentFormComponent from '../component/REConsentFormComponent';
import ScreeningSuccess from "../component/ScreeningSuccess";
import ConfirmDeviceComponent from '../component/ConfirmDeviceComponent';
import EnrolledSuccessComponent from '../component/EnrolledSuccessComponent';
import MntReferral from "../component/MntReferral";
import { isMNTVisible } from "../helper";
import MAReferralBasicInfo from "../component/MAReferralBasicInfo";
import MAReferredEnrollmentProgramEligibility from "../component/MAReferredEnrollmentProgramEligibility";
import ClinicGoalComponent from '../component/ClinicGoalComponent';
import NotReferredProgramEligibility from '../component/NotReferredProgramEligibility';

const STEPS_MAP = {
  maReferralBasicInfo: {
    label: 'Basic Information',
    key: 'maBasicInfo',
    statusPath: 'basicInfoEnroll',
    Content: MAReferralBasicInfo,
  },
  referralProgramEligibility: {
    label: 'Program Eligibility',
    key: 'programEligibility',
    statusPath: 'prgramEligibilityEnroll',
    Content: ReferralProgramEligibility,
  },
  maReferredProgramEligibility: {
    label: 'Program Eligibility',
    key: 'programEligibility',
    statusPath: 'prgramEligibilityEnroll',
    Content: MAReferredEnrollmentProgramEligibility,
  },
  maReferredProgramEligibilityWithoutDeviceAccessibility: {
    label: 'Program Eligibility',
    key: 'programEligibility',
    statusPath: 'prgramEligibilityEnroll',
    Content: (props) => <MAReferredEnrollmentProgramEligibility {...props} hideDeviceAccessibility={true} />,
  },
  notReferredProgramEligibility: {
    label: 'Program Eligibility',
    key: 'programEligibility',
    statusPath: 'prgramEligibilityEnroll',
    Content: NotReferredProgramEligibility,
  },
  mntReferral: {
    label: 'MNT Referral',
    key: 'mntReferral',
    statusPath: 'mntReferralEnroll',
    isVisible: isMNTVisible,
    Content: MntReferral,
  },
  clinicGoal: {
    label: 'Clinical Goals',
    key: 'clinicGoal',
    statusPath: 'clinicalGoalsEnroll',
    Content: ClinicGoalComponent,
  },
  enrollmentProgramEligibility: {
    label: 'Program Eligibility',
    key: 'programEligibility',
    statusPath: 'prgramEligibilityEnroll',
    Content: EnrollmentProgramEligibility,
  },
  referredSuccess: {
    label: <div>
      <span>Referred!</span>
      <img src='/image/referandenroll/confetti.svg' width='14px' style={{ marginTop: -4, marginLeft: 6 }}/>
    </div>,
    key: 'referredSuccess',
    statusPath: 'referredSuccess',
    Content: ReferredSuccess,
  },
  midReferralSuccess: {
    label: <div>
      <span>Referred!</span>
      <img src='/image/referandenroll/confetti.svg' width='14px' style={{ marginTop: -4, marginLeft: 6 }}/>
    </div>,
    key: 'referredSuccess',
    statusPath: 'referStatus',
    Content: (props) => <ReferredSuccess {...props} showContinueButton={true} />,
  },
  screeningSuccess: {
    label: 'Screened',
    key: 'screeningSuccess',
    statusPath: 'screeningSuccess',
    Content: ScreeningSuccess,
  },
  scheduleInitialVisit: {
    label: 'Schedule Visit',
    key: 'scheduleInitialVisit',
    statusPath: 'scheduleInitVisitEnroll',
    Content: ScheduleInitialVisitComponent
  },
  patientConsent: {
    label: 'Patient Consent',
    key: 'patientConsent',
    statusPath: 'patientConsentEnroll',
    Content: REConsentFormComponent
  },
  patientConsentWithReferTransition: {
    label: 'Patient Consent',
    key: 'patientConsent',
    statusPath: 'patientConsentEnroll',
    Content: (props) => <REConsentFormComponent {...props} transitionFromRefer />
  },
  confirmDevice: {
    label: 'Confirm Devices',
    key: 'confirmDevice',
    statusPath: 'confirmDevicesEnroll',
    Content: ConfirmDeviceComponent
  },
  enrolledSuccess: {
    label: <div>
      <span>Enrolled!</span>
      <img src='/image/referandenroll/confetti.svg' width='14px' style={{ marginTop: -4, marginLeft: 6 }}/>
    </div>,
    key: 'enrolledSuccess',
    statusPath: 'enrolledSuccess',
    Content: EnrolledSuccessComponent
  }
}
export const STATUS_KET_TO_STATUS_PATH = _.reduce(STEPS_MAP,(res,step)=> { _.set(res,_.get(step,`key`),_.get(step,`statusPath`)); return res;  },{})
const FORM_REFERRAL_AND_ENROLL_MAP = {
  MD: {
    REFERRAL: [
      STEPS_MAP.referralProgramEligibility,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.mntReferral,
      STEPS_MAP.referredSuccess,
    ],
    ENROLL: [ // isReferred = false AND refer button still there
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.notReferredProgramEligibility,
      STEPS_MAP.clinicGoal,
      // STEPS_MAP.referralProgramEligibility,
      // .maReferredProgramEligibility,
      STEPS_MAP.mntReferral,
      // STEPS_MAP.midReferralSuccess,
      STEPS_MAP.patientConsentWithReferTransition,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ],
    REFERRED_ENROLL: [ // isReferred = true AND refer button is gone
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.maReferralBasicInfo,
      // STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ]
  },
  MA: {
    REFERRAL: [
      STEPS_MAP.referralProgramEligibility,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.mntReferral,
      STEPS_MAP.referredSuccess
    ],
    ENROLL: [ // isReferred = false AND refer button still there
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.notReferredProgramEligibility,
      STEPS_MAP.clinicGoal,
      // STEPS_MAP.referralProgramEligibility,
          // .maReferredProgramEligibility,
      STEPS_MAP.mntReferral,
      // STEPS_MAP.midReferralSuccess,
      STEPS_MAP.patientConsentWithReferTransition,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ],
    REFERRED_ENROLL: [ // isReferred = true AND refer button is gone
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.maReferralBasicInfo,
      // STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ],
  },
  CA: {
    ENROLL: [
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.mntReferral,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
    ],
    REFERRAL: [
      STEPS_MAP.referralProgramEligibility,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.mntReferral,
      STEPS_MAP.referredSuccess
    ],
    REFERRED_ENROLL: [ // isReferred = true AND refer button is gone
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.maReferralBasicInfo,
      // STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ]

  },
  RD: {
    ENROLL: [
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
    ],
    REFERRAL: [
      STEPS_MAP.referralProgramEligibility,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.mntReferral,
      STEPS_MAP.referredSuccess
    ],
    REFERRED_ENROLL: [ // isReferred = true AND refer button is gone
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.maReferralBasicInfo,
      // STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ]
  },
  HC: {
    ENROLL: [
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.mntReferral,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
    ],
    REFERRAL: [
      STEPS_MAP.referralProgramEligibility,
      STEPS_MAP.clinicGoal,
      STEPS_MAP.mntReferral,
      STEPS_MAP.referredSuccess
    ],
    REFERRED_ENROLL: [
      STEPS_MAP.maReferredProgramEligibility,
      STEPS_MAP.maReferralBasicInfo,
      STEPS_MAP.patientConsent,
      STEPS_MAP.scheduleInitialVisit,
      STEPS_MAP.confirmDevice,
      STEPS_MAP.enrolledSuccess,
    ]
  }
};

export const getReferralOrEnrollMap = (role, type) => {
  return _.get(FORM_REFERRAL_AND_ENROLL_MAP, `${role}.${type}`);
};
