import CreateNewTaskComponent from '../components/CreateNewTaskComponent';
import React from "react";
import { graphql, withApollo, compose } from 'react-apollo';
import getTaskAssignmentsByPatientId from '../query/getTaskAssignmentsByPatientId';
import enrolledProgram from "graphqlModule/enrolledProgram";
import { browserHistory } from 'react-router'
import API from "../API";

let CreateNewTaskContainer = class extends React.Component {
    constructor(props) {
        super();
        this.state = {
            assignees: { }
        }
    }
    async componentDidMount() {
        const id = _.get(this.props,'modalData.memberId');
        const data = await API.getPatientAssignee(id);
        const assignees = _.get(data,'data.user.assignees');
        this.setState({
            assignees
        })
    }

    setModalData = async (modalData) => {
      if(browserHistory.getCurrentLocation().pathname.includes('workstation')) {
        await this.props.refetchByPatient();
      }
      this.props.setModalData(modalData);
    }

    render(){
        const { props,state } = this;
        const { assignees } = state;
        return <CreateNewTaskComponent {...props} setModalData={this.setModalData} assignees={assignees}/>
    }
}

const withData = graphql(getTaskAssignmentsByPatientId,{
    options:(props)=>{
        const memberId  = _.get(props,'memberId',_.get(props,'modalData.memberId'));
        return{
            variables:{
                memberId
            },
            fetchPolicy:'network-only'
        }
    },
    props:({ data })=>{
        const { getTaskAssignmentsByPatientId, loading, refetch } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            taskList:_.get(getTaskAssignmentsByPatientId,'taskAssignments',[]),
            refetchByPatient: refetch
        }
    }
});
const team = graphql(enrolledProgram,{
    options:(props)=>{
        const { currentProgramId } = props
        return{
            variables:{
                id:currentProgramId
            }
        }
    },
    props:({data})=>{
        const { loading } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            organization: _.get(data,'enrolledProgram.organization'),
            team:_.get(data,'enrolledProgram.team')
        }
    }

})

export  default compose(withData,team)(CreateNewTaskContainer);
