import PropTypes from 'prop-types';
import React from 'react';
import LineChart from './LineChartComponentRevised'
import moment from 'moment'
import { alertColor } from '../constants/VitalListConstants'
import { PO_UNIT } from 'modulesAll/utils/constants/task'

const returnValidPO = (data) => (
  data.filter(d => d.taskStatus === 'COMPLETED' && !!d.blood_oxygen)
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      'Blood Oxygen': d.blood_oxygen.value,
      date: d.date,
      key: 'Blood Oxygen',
      id: d.id,
      datum: d
    }))
)

const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const setFillColorPO = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity)? alertColor[d.datum.severity] : defaultFill
}
const formatTooltipPO = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const { blood_oxygen } = d.datum
  const poUnit = blood_oxygen? blood_oxygen.unit : PO_UNIT
  return `${blood_oxygen? blood_oxygen.value : '--'} ${poUnit}`
}
const POChart = ({ data, range, thresholds_po }) => {
  const chartData = returnValidPO(data, ['Blood Oxygen'])
  const missed = returnMissed(data)
  const setFillColor = setFillColorPO
  const formatTooltip = formatTooltipPO
  const p = {
    data: chartData,
    missed,
    value: ['Blood Oxygen'],
    lineColor: ['#0099ff'],
    setFillColor,
    formatTooltip,
    thresholds: thresholds_po,
    range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
  }
  return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
}

POChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array
}
export default POChart
