/**
 * Created by zizhangai on 1/2/17.
 */
import gql from 'graphql-tag';
import { EnrolledProgram } from './schema/EnrolledProgram'

export const getResultNotes = gql`
  query result($id: EID) {
    result(id: $id) {
      id
      notes (count: 99999) {
        data {
          id
          category
          content
          createdBy {
            id
            profile {
              fullName
             }
          }
          error {
            markedBy {
              profile {
                fullName
              }
            }
            reason
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;


export const resultThreshold= gql`
  query result($id: EID) {
    result(id: $id) {
      id
      threshold {
        measure
        unit
        baseline
        range
      }
      user{
      id
      profile{
        ...on MemberProfile{
          height
        }
      }
    }
    }
  }
`
// profile{
// ...on MemberProfile{
//         height
//     }
// }
// }
export const getMeasurementCounts = gql`
  query getMeasurementCount($filters: ResultListOpts!) {
    resultList(filters: $filters) {
      data {
        id
        type
      }
    }
  }
`;