import React, { useState } from 'react';

import DraggablePopup from '../component/DraggablePopup';
import GoalAssessmentPopup from '../component/GoalAssessmentPopup';
import { monthlyReviewStatusMap } from '../constant';

import I18N from '../../I18N';

export const checkIfMonthlyReviewInCompleted = (status) => {
  // Draft is considered as incompleted
  return [monthlyReviewStatusMap.START, monthlyReviewStatusMap.DRAFT].includes(status);
}

export const getMonthlyReviewByRoles = (role, data = []) => {
    let types = I18N.get(`monthlyReviewPlan.manualMonthlyReview.${role}.typeFilters`);
    let monthlyReviewData = _.first(_.filter(data, (d) => {
      const { type, status, createdBy } = d;
      const isIncluded = _.includes(types, type);
      let validStatus = true;
      // if MTPR is draft and for system generated (no createdBy)
      if (_.startsWith(type, 'MTPR') && status === monthlyReviewStatusMap.DRAFT) {
        validStatus = !createdBy;
      }
      return isIncluded && validStatus;
    })) || {};
    return monthlyReviewData;
}

export const useDraggableSummaryPopup = () => {
  const DEFAULT_POPUP_TOP = 150;
  const DEFAULT_POPUP_LEFT = (window.innerWidth / 2) - 300;
  const [draggablePopups, setDraggablePopups] = useState([]);

  const handleOpenPopup = (popup) => {
    let newDraggablePopups = [...draggablePopups];
    let popupIdx = _.findIndex(draggablePopups, { key: popup.key }),
        isVisible = true;
    const curPopup = newDraggablePopups[popupIdx];
    if(curPopup && popupIdx !== newDraggablePopups.length -1) {
      // popup exists, bring it to front (by moving to end of array) and make it visible
      newDraggablePopups.splice(popupIdx, 1);
      newDraggablePopups.push(popup);
      popupIdx = newDraggablePopups.length -1;
    } else if(curPopup) {
      // popup exists, visible and in the front
      // replace curPopup to get new updates
      newDraggablePopups.splice(popupIdx, 1, popup);
    } else {
      // popup doesn't exist
      newDraggablePopups.push(popup);
      popupIdx = newDraggablePopups.length - 1;
    }
    newDraggablePopups[popupIdx].isVisible = isVisible;
    setDraggablePopups(newDraggablePopups);
  };
  
  const handleClosePopup = (draggablePopups, key) => {
    const newDraggablePopups = [...draggablePopups];
    const popupIdx = _.findIndex(draggablePopups, { key });
    newDraggablePopups[popupIdx].isVisible = false;
    setDraggablePopups(newDraggablePopups);
  };

  const genDraggablePopup = (key, children, containerStyles) => {
    const popupCount = draggablePopups.length;
    return {
      key,
      isVisible: false,
      render: (draggablePopups, isVisible, newChildren) => (
        <DraggablePopup 
          key={key}
          isVisible={isVisible} 
          onClose={() => handleClosePopup(draggablePopups, key)}
          containerStyles={{
            top: DEFAULT_POPUP_TOP + (30 * popupCount), // translate down
            left: DEFAULT_POPUP_LEFT + (30 * popupCount), // translate right
            ...containerStyles
          }}
        >
          {
            newChildren || children
          }
        </DraggablePopup>
      )
    };
  };

  const renderVitalsSummaryPopup = (goalType, popupProps) => {
    const { availableGoals, monthlyReviewSummary, isMRE, locateAssessment, popupKey } = popupProps || {};
    if(!goalType) {
      message.warn('Goal category is not available');
      return console.warn('warn renderVitalsSummaryPopup: invalid goal type');
    }

    const POPUP_KEY = (popupKey || 'vitalsSummary');

    const children = <GoalAssessmentPopup
      key={POPUP_KEY}
      preSelectedTab={goalType}
      availableGoals={availableGoals}
      monthlyReviewSummary={monthlyReviewSummary}
      isMRE={!!isMRE}
      locateAssessment={!!locateAssessment}
    />;

    let popup = _.find(draggablePopups, { key: POPUP_KEY });
    if(popup) {
      popup.render = _.partialRight(popup.render, children);
    } else {
      popup = genDraggablePopup(POPUP_KEY, 
        children
      ,{ height: 600, minWidth: 600, minHeight: 260 });
    }

    handleOpenPopup(popup, popup);
  };

  return [draggablePopups, renderVitalsSummaryPopup];
};