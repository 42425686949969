import gql from 'graphql-tag';

export default gql`
query getPatientAlertConfig($memberId:EID!){
    getPatientAlertConfig(memberId:$memberId){
        id,memberId,configs{endDate,startDate,taskType,isValid,snoozeInterval,alertDisplay,
          alertLevel
      }
    }
}`;
