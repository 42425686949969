import React, { useCallback, useState, useMemo } from 'react';
import { Button, Form, Card } from 'antd';
import ReferAndEnrollForm from './ReferAndEnrollForm';
import I18N from '../../I18N';
import { wantToJoinProgram, deviceAccessability, exclusionReason, exclusionReasonOther, smartPhoneSkills, anotherCCMProvider } from '../fields/ProgramEligibility';
import FormRenderer from './FormRenderer';
import VitalsAndMonitoring from './VitalsAndMonitoringPrograms';
import Overlay from './Overlay';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { upsertPatientReferralMutation } from '../API/mutation';
import { getBinaryRadioValue, getValue, getAnotherCCMProviderOrgPermissions, getRadioValue } from '../helper';
import { 
  getAnotherCCMFieldValue, getDeviceAccessibilityValue, 
  getProgramsValue, getReferredByLastName, getVitalsValue, getWouldJoinValue 
} from '../helper/initialValueHelper';
import _ from 'lodash';
import { ELIGIBLE_PROGRAMS, VITAL_TYPE_ENUM } from '../constant';

const EnrollmentProgramEligibility = ({ patientReferral, user, form, onSubmitDone, type, userRole, closeModal, refetchPatientReferral }) => {
  const hasAnotherCCMProvider = getAnotherCCMProviderOrgPermissions();
  const initialValues = useMemo(() => {
    const selectedVitals = getVitalsValue(patientReferral) || [];
    const selectedPrograms = getProgramsValue(patientReferral) || [];
    return {
      selectedVitals,
      notSelectedVitals: (_.keys(VITAL_TYPE_ENUM) || []).filter(v => !selectedVitals.includes(v)).map(v => ({ key: v })),
      selectedPrograms,
      notSelectedPrograms: (_.keys(ELIGIBLE_PROGRAMS) || []).filter(v => !selectedPrograms.includes(v)).map(v => ({ key: v })),
      wouldJoin: getWouldJoinValue(patientReferral),
      isDeviceAccessible: getDeviceAccessibilityValue(patientReferral),
      isAnotherCcmProviderSelected: getAnotherCCMFieldValue(patientReferral),
    };
  }, []);
  const { validateFields, getFieldsValue } = form;
  const fieldsValues = getFieldsValue();
  const isJoiningProgram = getValue(getBinaryRadioValue(fieldsValues.joinProgram), initialValues.wouldJoin);
  const selectedExclusionReason = fieldsValues.exclusionReason;
  const isDeviceAccessible = getValue(getBinaryRadioValue(fieldsValues.deviceAccessability), initialValues.isDeviceAccessible);

  const fields = [
    wantToJoinProgram({ initialValue: getRadioValue(initialValues.wouldJoin) }),
  ];
  if (isJoiningProgram) {
    fields.push(deviceAccessability({ initialValue: getRadioValue(initialValues.isDeviceAccessible) }));
    // if (loanerPhoneConfig && isDeviceAccessible === false) {
    //   fields.push(smartPhoneSkills());
    // }
  } else if (isJoiningProgram === false) {
    fields.push(exclusionReason({  }));
    if (selectedExclusionReason === 'OTHER') {
      fields.push(exclusionReasonOther());
    }
  } 
  if (hasAnotherCCMProvider && isDeviceAccessible) {
    fields.push(anotherCCMProvider({ initialValue: getRadioValue(initialValues.isAnotherCcmProviderSelected) }));
  }

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const { id: memberId } = user;
        // console.log(values);
        const variables = {
          patientId: memberId,
          referredBy: JSON.parse(sessionStorage.getItem('currentUser')).id,
          recommendedVitals: values.recommendVitalsList,
          eligiblePrgorams: values.eligiblePrograms,
          // billableHealthConditions: icdCodes.map(v => ({ code: v.code, description: v.description })), // Should not be able to change it if enrolled
          // conditionsToMonnitor: conditionsList,
          wouldJoin: !!values.joinProgram,
          haveSmartphone: false,
          deviceAccessbility: !!values.deviceAccessability,
          // techLevel: values.smartPhoneSkills
          // enrolledAnother: false,
        };
        // console.log(variables);
        if (values.joinProgram === 0) {
          await remoteEnrollmentApis.editExcludedReason({
            memberId,
            excludedReason: values.exclusionReason,
          });
          await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'EXCLUDED' })
        }
        await upsertPatientReferralMutation(variables);
        await refetchPatientReferral();
        // console.log(variables);
        onSubmitDone();
      }
    });
  }, [validateFields, onSubmitDone]);

  const handleNotEligibleClick = () => {
    // mark patient as excluded
    if (closeModal) closeModal();
  }

  const notDeviceAccessibleOverlay = () => {
    return (
      <Overlay>
        <div className="flex-center fit-parent">
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.noSmartphoneEligibility')}</div>
            <div style={{ marginBottom: 20 }}>{I18N.get('referAndEnroll.noSmartphoneEligibilitySubText')}</div>
            <div className="flex-center">
              <Button type="primary" className="new-style" onClick={handleNotEligibleClick}>{I18N.get('referAndEnroll.noSmartPhoneEligibilityButton')}</Button>
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
  // console.log(isDeviceAccessible);
  return (
    <React.Fragment>
      <ReferAndEnrollForm 
        header={
          <div>
            <div style={{ fontSize: 15 }}>{I18N.get('referAndEnroll.referredByDoctor').replace('[name]', getReferredByLastName(patientReferral))}</div>
            <div style={{ fontWeight: 'normal', fontSize: 14 }}>{I18N.get('referAndEnroll.referredByDoctorSubText')}</div>
          </div>
        } 
        className="program-eligibility"
        onSubmit={handleSubmit} 
        buttons={<Button type="primary" htmlType="submit" className="new-style">Next</Button>}
      >
        <Card style={{ boxShadow: '0px 0px 10px -4px rgba(0,0,0,0.7)', margin: '0 10px 30px 10px' }}>
          <VitalsAndMonitoring 
            form={form}
            overlay={isDeviceAccessible === false && notDeviceAccessibleOverlay()}
            disabledPrograms={initialValues.notSelectedPrograms}
            disabledVitals={initialValues.notSelectedVitals}
            vitalsInitialValue={initialValues.selectedVitals}
            eligibleProgramsInitialValue={initialValues.selectedPrograms}
          />
          {/* {isDeviceAccessible === false && <div style={{ color: '#DA6453' }}>{I18N.get('referAndEnroll.noSmartPhoneBPAndRpm')}</div>} */}
        </Card>
        <FormRenderer form={form} fields={fields} />
        {isJoiningProgram === false && selectedExclusionReason && 
          <div style={{ fontSize: 11, width: 400, marginTop: 20 }}>
            {I18N.get('referAndEnroll.notWillingToJoinNote')}
          </div>
        }
      </ReferAndEnrollForm>
    </React.Fragment>
  );
}

export default Form.create()(EnrollmentProgramEligibility);
