import { combineReducers } from 'redux'
// import TaskNew from 'modulesAll/providerTask/TaskNew/reducers/TaskNewReducer'
import TaskList from 'modulesAll/providerTask/TaskList/reducers/TaskListReducer'
// import TaskEdit from 'modulesAll/task/TaskEdit/Common/reducers/TaskEditReducer'

const reducer = combineReducers({
  // TaskNew,
  TaskList,
  // TaskEdit

})
export default {
  task: reducer
}
