import React from 'react'
import BaseComponent from '../BaseComponent'
import {DatePicker} from 'antd'
import _ from 'lodash'
import enUS from 'antd/lib/date-picker/locale/en_US'

const Component = class extends BaseComponent{

    render(){
        let p = _.omit(this.props, [])

        return (
            <DatePicker format="DD/MMM/YYYY" locale={enUS} className="IH-DatePicker" {...p} />
        )

    }
}

Component.propTypes = {

}

export default Component
