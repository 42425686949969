/**
 * Created by zizhangai on 2/11/17.
 */
import gql from 'graphql-tag';

export default gql`
  mutation generateDataDetailReport(
    $enrolledProgramId: EID!, 
    $startDate: Date!, 
    $endDate: Date!) {
    generateDataDetailReport(
      enrolledProgramId: $enrolledProgramId, 
      startDate: $startDate, 
      endDate: $endDate) {
      reportId
      fileUrl
      message
    }
  }
`;
