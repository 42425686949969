import { getFieldsRequiredFromSchema, getInitDataFromSchema } from 'libModule/helpers/reducer-helpers'
import { phoneOptions } from 'libModule/constants'

export const schema = {
  mobileCountryCode: {
    initialValue: '+1',
    required: true
  },
  mobile: {
    initialValue: '',
    required: true
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const getFormProps = ({ initData }) => {
  const itemList = [
    [
      {
        key: 'mobileCountryCode',
        label: 'Mobile',
        type: 'select',
        disabled: true,
        option: phoneOptions,
        initialValue: initData.mobileCountryCode,
        col: { span: 6, offset: 0 },
        rules : [
          {required : isFieldsRequired.mobileCountryCode}
        ],
      },
      {
        key: 'mobile',
        label: 'Mobile Phone',
        type: 'text',
        initialValue: initData.mobile,
        col: { span: 18, offset: 0 },
        rules : [
          {required : isFieldsRequired.mobile, message : 'Mobile Number is required'},
          //{ len: 8, message: 'Mobile Number requires 8 digits'}
        ],
        className: 'hide-required-mark'
      }
    ]

  ]
  return {
    vertical: true,
    itemList
  }
}
