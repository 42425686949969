import { graphql } from 'react-apollo';
import { enrolledProgramListWithTeam } from './query/enrolledProgramList';

export const getMostRecentEP = graphql(enrolledProgramListWithTeam, {
  options: ({ patientId }) => ({
    variables: {
      filters: {
        memberId: patientId,
      },
      count: 1,
      sort: {
        field: 'START_DATE',
        direction: 'DESC',
      }
    },
    fetchPolicy: 'cache-and-network'
  }),
  props: ({ data }) => {
    const { loading, enrolledProgramList, error } = data;

    if (error) {
      console.error(error);
    }

    const mostRecentEP = _.get(enrolledProgramList, 'data.0');

    return {
      mostRecentEP,
      loadingMostRecentEp: loading,
    };
  },
});