import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import LoginStep2Component from '../components/LoginStep2Component'
import login2FA from 'modulesAll/graphql/login2FA'
import resendTfaCode from 'modulesAll/graphql/resendTfaCode'
import UserClass from 'modulesAll/graphql/class/User'
import * as actions from '../actions/Login'
import { browserHistory } from 'react-router'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { message } from 'ihcomponent'
import { GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

// container
class Container extends Component {
  static displayName = 'LoginStep2Container'

  constructor(props) {
    super(props);

    this.state = { loginId: null };
  }

  submit = async ({ code }) => {
    const { fieldsValue, login2FA, reset, setSubmitting, updateForm, openErrorModal } = this.props
    const { token, username } = fieldsValue
    const variables = {
      code,
      token: token.value,
      role: ['DOCTOR', 'NURSE', 'ADMIN', 'MEMBER'],
    }
    const loginId = username && username.value
    if (!this.state.loginId) {
      this.setState({ loginId })
    }

    setSubmitting(true);

    try {
      const res = await login2FA({ variables })
      const { sessionToken, token, id, role, appSettings } = res.data.login2FA || {}

      if(!sessionToken) {
        createAuditLog({
          action: I18N.get('auditLog.login.step2.verify2fa'),
          loginId: this.state.loginId,
          details: {
            status: 'Login Failed',
            reason: 'Invalid 2FA Code'
          },
          request: variables,
          response: res,
          success: false
        })
        openErrorModal('Invalid 2FA Code')
        updateForm({
          ...fieldsValue,
          token: { name: 'token', value: token}, //use the new token
        })
      }
      else {
        createAuditLog({
          action: I18N.get('auditLog.login.step2.verify2fa'),
          loginId: this.state.loginId,
          details: {
            status: 'Login Success',
            reason: '2FA Code Verified'
          },
          request: variables,
          response: res
        })
        await UserClass.saveUserInfo({sessionToken, id, role, appSettings})
        reset()
        browserHistory.push('/')
      }
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.step2.verify2fa'),
        loginId: this.state.loginId,
        details: {
          status: 'Login Failed',
          reason: 'An error has occurred while verifying 2FA Code'
        },
        request: variables,
        response: err,
        success: false
      })
      openErrorModal(GQLHelper.formatError(err))
    }
    finally {
      setSubmitting(false);
    }
  }

  sendNewCode = async () => {
    const { fieldsValue, resendTfaCode, updateForm, openErrorModal } = this.props
    const { token, username } = fieldsValue
    const variables = {
      token: token.value
    }
    const loginId = username && username.value

    try {
      const res = await resendTfaCode({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.step2.resend2fa'),
        loginId,
        details: variables,
        request: variables,
        response: res
      })
      const { token } = res.data.resendTfaCode || {}
      updateForm({
        ...fieldsValue,
        token: { name: 'token', value: token}, //the new token
      })
      message.success('Successfully resent code.')
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.step2.resend2fa'),
        loginId,
        details: variables,
        request: variables,
        response: err,
        success: false
      })
      openErrorModal(GQLHelper.formatError(err))
    }
  }

  render() {
    const props = {
      ...this.props,
      submit: this.submit,
      sendNewCode: this.sendNewCode
    }
    return (
      <LoginStep2Component {...props} />
    )
  }
}

const mapState = ({ Login }, ownProps) => {
  return {
    ...Login
  }
}

const mapDispatch = {
  ...actions,
  openErrorModal
}

export default compose(
  withApollo,
  graphql(login2FA, { name: 'login2FA' }),
  graphql(resendTfaCode, { name: 'resendTfaCode' }),
  connect(mapState, mapDispatch),
)(Container);
