import type from '../constants/ImportPatientInfo'
import { handleFormFieldsChange } from 'libModule/helpers/action-helpers'

export default {
  resetForm : ()=>{
    return {
      type : type.RESET_FORM
    }
  },

  updateForm : (fieldsProps, changedFields)=>{
    return handleFormFieldsChange(fieldsProps, changedFields, type)
  }
}
