import { ROLE_NAME } from '../../../lib/constants';

export default {
    'ADMIN:Admin': 'Admin',
    'SUPER_ADMIN:Super Admin': 'Super Admin',
    'Nurse':'RD',
    'Doctor':'Doctor',
    'Clinic Medical Assistant':'MA',
    'Medical Assistant':'CA',
    'PROVIDER:Doctor':'Doctor',
    "PROVIDER:Clinic Medical Assistant":'MA',
    'PROVIDER:Medical Assistant':'CA',
    'PROVIDER:Nurse':'RD',
    'OTHER:Vendor': 'Vendor',
    'PROVIDER:Biller': 'BILLER',
    'PROVIDER:Partner Biller': 'Partner Biller',
    [ROLE_NAME.HC]: 'HC',
    [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
}
