import React from 'react';
import { Row,Col,Modal,Divider,Button,Skeleton, Switch, message } from 'antd';
import { top }from '../API/index';
import {handlePatientChange, getTasks, preFetchChatHistory} from '../helper/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {helpers} from "../../visitNewWorkFlow/helper";
import UnderlinedTooltip from '../../patientList/main/components/UnderlinedTooltip';
import OnCallContainer from '../container/OnCallContainer';

import { setCoveredMembers } from '../../assignCT/API/coveredMembers';
import { ROLE_MAP } from '../../../lib/constants';
import { decryptRole } from '../../../lib/utils';

const TotalCaseSummaryComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            currentRole: ROLE_MAP[decryptRole()],
            loadingUpdateCoveredMembers: false,
            coveredMembers: [],
            onCallPopupVisible: false,
            onCallToggle: false
        }
    }

    componentDidUpdate = (prevProps) => {
      const { loadingCoveredMembers, coveredMembers, coverAll } = this.props;
      if(prevProps.loadingCoveredMembers && !loadingCoveredMembers) {
        this.setState({ 
          coveredMembers, 
          coverAll,
          onCallToggle: !!coveredMembers.length 
        });
      }
    }

    handleSaveCoveredMembers = async (
      members = [], 
      coverAll = false, 
      shouldRefetch = true
    ) => {
      const { refetchReports } = this.props;
      this.setState({ loadingUpdateCoveredMembers: true });
      return await setCoveredMembers({ variables: { members: _.map(members, 'id'), coverAll } })
      .then(() => {
        this.setState({ coveredMembers: members, coverAll, onCallToggle: !!members.length });
        if(shouldRefetch) {
          refetchReports();
        }
      })
      .catch(message.error)
      .finally(() => this.setState({ loadingUpdateCoveredMembers: false }));
    };

    renderMainHeader = ()=>{
      return <UnderlinedTooltip
        tooltipMsg='The numbers in this section represent the aggregated workload from your patients and the covered workload from patients of selected care team members if you are on-call.'
        tooltipProps={{
          overlayClassName: 'underlined-tip mainHeaderText'
        }}
        spanStyles={{
          cursor: 'default',
        }}
      >
        <span className={'mainHeader'}>Total Patient Case</span>
      </UnderlinedTooltip>;       
    }

    renderOnCall = () => {
      const { currentRole, coveredMembers, onCallPopupVisible, onCallToggle, loadingUpdateCoveredMembers, coverAll } = this.state;
      const { loadingCoveredMembers } = this.props;

      const handleToggle = _.debounce(async (checked) => {
        if(checked) {
          handleOpenModal();
        } else {
          await this.handleSaveCoveredMembers([], false);
        }
      }, 250);

      const handleOpenModal = () => this.setState({ onCallPopupVisible: true });

      const handleCloseModal = () => this.setState(prev => ({ 
        onCallPopupVisible: false, 
        onCallToggle: !!prev.coveredMembers.length,
      }));

      const handleConfirm = async (coveredMembers = [], coverAll) => {
        try {
          if(coveredMembers.length) {
            await this.handleSaveCoveredMembers(coveredMembers, coverAll);
          }
        } catch (error) {
          message.error(error);
        } finally {    
          this.setState(prev => ({ 
            onCallToggle: prev.onCallToggle, 
            onCallPopupVisible: false
          }));
        }
      };

      return(
        <div>
          <div style={{ display: 'flex', float: 'right', fontSize: 14 }}>
            <span>
              On-call
            </span>
            {
              !!coveredMembers.length ? 
              <div 
                style={{ textDecoration: 'underline', marginLeft: 4, cursor: 'pointer' }}
                onClick={handleOpenModal}
              >
                for {coveredMembers.length} {currentRole}{coveredMembers.length > 1 ? 's' : ''}
              </div>
              : ''
            }
            <Switch 
              checked={onCallToggle}
              onChange={handleToggle}
              className='onCalleeSwitch'
              loading={loadingCoveredMembers || loadingUpdateCoveredMembers}
            />
          </div>
          <Modal
            visible={onCallPopupVisible}
            onCancel={handleCloseModal}
            width='40vw'
            maskClosable={false}
            footer={null}
            bodyStyle={{ padding: '48px 63px 54px 57px'}}
            destroyOnClose
          >
            <OnCallContainer 
              coveredMembers={coveredMembers}
              coverAll={coverAll}
              loadingUpdateCoveredMembers={loadingUpdateCoveredMembers}
              onConfirm={handleConfirm}
            />
          </Modal>
        </div>
      );
    }

    getNotificationStatus = () => {
        return sessionStorage.getItem('notificationFreq');
    }

    renderTasks = (reports)=>{
        const remove = !_.includes(this.getNotificationStatus(), 'NOTIFY_ALWAYS') ? ['MESSAGE_UNREAD'] : [];
        const taskArray = getTasks(reports, remove).filter(x => x.count !== 0);
        return (
            <Row className={'totalTasks'}>
                <div className={'taskContainer'}>
                    {_.map(taskArray, ({ src, title, count }, i) => (
                        // <Col span={12} key={i}>
                            <div key={i} className={'task-wrapper'}>
                                <img className={'task-image'} src={src}/>
                                <strong className={'total'}>{count}</strong>
                                <span className={'type'}>{title}</span>
                            </div>
                        // </Col>
                    ))}
                </div>
            </Row>
        );
    }

    handleStartNow = async ()=>{
        const response = await top();
        preFetchChatHistory(response);
        const { data } = response;
        Mixpanel.track('clicked', 'start_now', 'workstation_dashboard');
        handlePatientChange(data);
    }

    renderTotalAnnBtn = ()=>{
        const { total } = this.props.reports;
        const { handleStartNow } = this;
        return <Row className={'totalAndBtn'}>
                <Col span={24} className={'total'}>{total}</Col>
                <Col span={24} className={'subtitle'}>Patient Cases</Col>
                <Col span={24} className={'btnWrapper'}>
                    <Button 
                      type={'primary'} 
                      onClick={ handleStartNow } 
                      style={{ background: 'var(--dark-blue)', borderColor: 'var(--dark-blue)' }}
                    >
                      Start Now
                    </Button>
                </Col>
               </Row>
    }
    renderBody = ()=>{
        const { reports } = this.props;
        return <Row className={'body'}>
                <Col span={7}>{ this.renderTotalAnnBtn() }</Col>
                <Col span={1}><Divider type="vertical" /></Col>
                <Col span={16}>{this.renderTasks(reports.task)}</Col>
               </Row>
    }

    render() {
        const {renderMainHeader,renderOnCall,renderBody,props, loadingUpdateCoveredMembers } = this;
        const { loading,reports, loadingCoveredMembers } = props;
        const isCAOrRD = helpers.checkIsCAOrRD();

        if(loadingCoveredMembers || loadingUpdateCoveredMembers) return null;

        return <Row className={'totalCasesWrapper'}>
                    <Row style={{ margin: '10px 0' }}>
                        <Col span={8}>{ renderMainHeader() }</Col>
                        <Col span={16}> {isCAOrRD && renderOnCall() }</Col>
                    </Row>
                    <Col span={24} style={{ height:'90%' }}>{ loading ? <Skeleton/> :
                        <React.Fragment>
                            {_.get(reports, 'total', 0) === 0
                                ?   <Col span={24} className={'no-unpinned-case'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                    <div style={{ padding: 20, marginRight: 20 }}>
                                        <img src={'/image/workstation/done-patient-case.png'} />
                                    </div>
                                    <div>
                                        <p>There is 0 patient case currently.</p>
                                        <p>Good job!</p>
                                    </div>
                                </Col>
                                : renderBody()

                            }
                        </React.Fragment>
                    }</Col>
                </Row>
    }
}

export default TotalCaseSummaryComponent;