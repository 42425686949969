import React, { useEffect, useState, useRef } from 'react';
import { Icon, message } from 'antd';
import { COMPLEXITY_ENUM, PATIENT_COMPLEXITY_SOURCE_ENUM } from '../../../../lib/constants';
import I18N from '../../../I18N';

import ComplexityEditComponent from './ComplexityEditComponent';
import ComplexitySummaryContainer from '../containers/ComplexitySummaryContainer';

const { MANUAL_INPUT, ICD_CODE } = PATIENT_COMPLEXITY_SOURCE_ENUM;

// const ManagedByTeam = (level, isEnrolled, hasAssignees) => {
//   return (
//     hasAssignees ? "" :
//     <p>
//       {isEnrolled ? '' : 'Once enrolled, '}Patient will be managed by:&nbsp;
//       <span style={{ fontWeight: 'bold' }}>
//         {level === COMPLEXITY_ENUM.NON_COMPLEX ? 'Health Coach (HC) Or ': ''}Registered Dietitian (RD)
//       </span>&nbsp;
//       + Clinical Assistant (CA) in Unified Care care team.
//     </p>
//   );
// }

const ComplexityComponent = (props) => {
  const { isEnrolled, complexity: _complexity, onChange, codeBasedComplexity, canManualEdit = true } = props;
  const initialRender = useRef(true);
  const isInitialManual = _.get(_complexity, 'source') === MANUAL_INPUT;
  const [isManual, setIsManual] = useState(false);

  const initialLevel = _.get(_complexity, 'level');
  const [complexityValue, setComplexityValue] = useState(initialLevel);
  const [complexityTempValue, setComplexityTempValue] = useState(initialLevel);
  
  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else if (!isManual && !isInitialManual) {
      const newLevel = codeBasedComplexity || complexityValue;
      if (onChange && newLevel !== complexityValue) {
        setComplexityValue(newLevel);
        setComplexityTempValue(newLevel);
        onChange({ 
          level: newLevel, 
          source: ICD_CODE
        });
      }
    } 
  }, [codeBasedComplexity])

  const handleComplexitySubmit = () => {
    setIsManual(true);
    setComplexityValue(complexityTempValue);
    if (onChange) {
      onChange({ 
        level: complexityTempValue, 
        source: MANUAL_INPUT 
      });
    }
    message.success('Condition Complexity Level is updated.');
  }

  if (_.isNil(complexityValue)) 
    return null;

  if(initialLevel === COMPLEXITY_ENUM.NULL_CODES && complexityValue === COMPLEXITY_ENUM.NULL_CODES) {
    const text = I18N.get('healthCondition.noDiagnosisNote.healthConditionSection');
    return (<div style={{ display: 'flex', lineHeight: '19.07px' }}>
              {text}
            </div>);
  }

  return (
    <div>
      <ComplexitySummaryContainer 
        oldComplexity={_complexity}
        newComplexity={{
          level: complexityTempValue,
          source: isManual ? MANUAL_INPUT : ICD_CODE
        }}
        EditComponent={
          canManualEdit ?
            <ComplexityEditComponent 
              {...props}
              showUpdatedByName={isManual || isInitialManual}
              previousUpdatedBy={_.get(_complexity, 'updatedBy')}
              value={complexityTempValue}
              onChange={setComplexityTempValue}
              onSubmit={handleComplexitySubmit}
            />
          : null
        }
      />
    </div>
  );
};

export default ComplexityComponent;
