export const mappingDevices = {
  'BP':'BP3L',
  'LANCET':'Lancets',
  'CLEAR': 'Clear',
  'BG':'BG1',
  'STRIP' : 'Strips',
  'PHONE': 'iPhone',
  'HS':'HS2',
  'A1C':'A1C',
  'THERMOMETER': 'Thermometer',
  'OXIMETER': 'Oximeter',
  'BG5S':'BG5S',
  'NEXUS': 'HS2S'
};
