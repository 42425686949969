import gql from 'graphql-tag'
import { taskHistoryData } from './taskDataFragment';
import { Identification } from '../../graphql/schema/common';

export default gql`query taskAssignmentList($page:Int,$count:Int,$filters:TaskAssignmentListOpts,$sort:TaskAssignmentListSort){
  taskAssignmentList(page:$page,count:$count,filters:$filters,sort:$sort){
    data{
      dueDate
      assignedTo{
        id
        profile{
          fullName
        }
      }
      member{
        id
        profile{
          fullName
          ...on MemberProfile {
            isSelfEnrolled
          }
        }
      }
      doctor{
        id
        profile{
          firstName
          lastName
          fullName
        }
      }
      createdAt
      createdBy{
        id
        profile{
          fullName
        }
      }
      taskAssignments{
         id
         status
         createdAt
         member{
            id
            profile{
                fullName
            }
            identification{
              ${Identification}
            }
            
         }
         doctor{
            id
            profile{
                fullName
            }
        }
        resolvedNote{
            note
            resolvedBy{
              id
              profile{
                  fullName
              }
            }
            resolvedAt
        }
        dueDate
        assignedTo{
          id
          profile{
           fullName
          }
        }
        reason
        createdBy{
          id
          profile{
            fullName
          }
        }
        priorityLevel
        isRead {
          value
          readAt
          readBy
        }
        ${taskHistoryData}
      }
    }
    pageInfo{
      total
      lastPage
    }
  }
}`;
