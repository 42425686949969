import React from 'react'
import { goPath, isCurrentUserAdmin } from 'libModule/utils'
import { IHButton } from 'ihcomponent'
import ListTable from './ListTable'
import I18N from 'modulesAll/I18N'

const Component = (props) => {
  const buttonProps = {
      label : `Create New ${I18N.get('keywords.Program')}`,
      size : '',
      type : 'primary',
      onClick : ()=>{
          goPath('/programmes/new')
      }
  }

  const buttonElem = !isCurrentUserAdmin() ? '' : (
    <div className="v-rd">
      <IHButton {...buttonProps} />
    </div>
  )
  return (
    <div className="vsm-main-page vsm-program-list-main">
      <div className="vsm-main-table">
        {buttonElem}
        <div className="v-table">
          <ListTable />
        </div>
      </div>
    </div>
  )
}

Component.displayName = 'program/ProgramList/containers/ProgramListContainer';

export default Component
