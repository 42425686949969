import convertor from '@ihealth/convertor';
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';

export default {
    blood_glucose: { name: 'BG', getValue: (value, unit) => unit == 'mmol/L' ? [convertBG_mmolTomgdl(value), 'mg/dL'] : [value, unit] },
    systolic_blood_pressure: { name: 'systolic', getValue: (value, unit) => [value, unit] },
    diastolic_blood_pressure: { name: 'diastolic', getValue: (value, unit) => [value, unit] },
    body_temperature: { name: 'temperature', getValue: (value, unit) => unit == 'C' ? [convertor.temperature(value, 'c', 'f'), 'F'] : [value, unit] },
    blood_oxygen: { name: 'spo2', getValue: (value, unit) => [value, unit] },
    body_weight: { name: 'weight', getValue: (value, unit) => unit == 'kg' ? [convertor.HS(value, 'kg', 'lbs', 1), 'lbs'] : [value, unit] },
    heart_rate: { name: 'heartBeat', getValue: (value, unit) => [value, unit] },
}

// 20200401 Thong - not using this right now
export const mappingMeasurementsForVisit = {
    BG: 'details.bgMeasurement.blood_glucose',
    systolic: 'details.bpMeasurement.systolic_blood_pressure',
    diastolic: 'details.bpMeasurement.diastolic_blood_pressure',
    temperature: 'details.temperature',
    spo2: 'details.spo2',
    weight: 'details.weight',
    heartBeat: 'details.bpMeasurement.heart_rate',
}