const mesNotificationType = {
    'text':'text',
    'foodUpload':'foodUpload',
    'fileUpload': 'fileUpload',
    'measurement':'measurement',
    'measurements':'measurements',
    'resultTriggerAlert':'resultTriggerAlert',
    'commentFoodLog': 'commentFoodLog'
}

export const autoMsgSubtypes = ['systemMsg', 'ONLINE_CLASS'];

export default  mesNotificationType;

