import React from 'react';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions'
import providerNoteList from 'graphqlModule/providerNoteList'
import ListTable from "../../../allNotes/List/components/ListComponent";
import moment from 'moment';
import {Icon, Tooltip} from "antd";
import { HIATUS_UNABLE_ENGAGE_TOOLTIP } from '../constant/index';
import {getPatientHiatusQuery} from "../../../graphql/getHiatus";
import HiatusPopupButton from "../../hiatusStatus/components/PopupButton";
import '../style/index.scss';

const tableActions = createTableAction('reengagement', {
	sortFieldMap: { createdAt: 'CREATED_AT' },
})
const ReengagementContainer = class extends React.Component{
	renderLastMeasuredAt = ()=>{
		const { lastMeasuredAt } = this.props;
		return <span>Last Measurement Date: {moment(lastMeasuredAt).format('L')}
					<Tooltip title={HIATUS_UNABLE_ENGAGE_TOOLTIP}><Icon type="info-circle" theme='filled' style={{ marginLeft: 5 }}/></Tooltip>
		</span>
	}
	renderHiatusButton = () => {
		const { user, hiatusStatus, refetchHiatusStatus } = this.props;
		return !_.isEmpty(user) && (<div>
			<HiatusPopupButton user={user} hiatusStatus={hiatusStatus} refetchHiatusStatus={refetchHiatusStatus}/>
		</div>);
	};

	render(){
		return <React.Fragment >
			<div className='reegagementContent'>
				<div style={{ padding:'10px 0px',display:'flex',justifyContent:'space-between',alignItems:'center' }}>
					{ this.renderLastMeasuredAt() }
					{ this.renderHiatusButton() }
				</div>
				<ListTable {...this.props}/>
			</div>
		</React.Fragment>
	}
}
const getPatientHiatusStatus = graphql(getPatientHiatusQuery, {
	options: (ownProps) => {
		const memberId = ownProps.patientId
		return {
			variables: {
				memberId
			},
			fetchPolicy: 'network-only'
		}
	},
	props: ({data}) => {
		const { loading, getPatientHiatusStatus, refetch, error } = data;
		if(error)
			console.error(error);

		return {
			hiatusStatus: loading ? {} : (getPatientHiatusStatus || {}),
			refetchHiatusStatus: refetch
		};
	}
});
const withData = graphql(providerNoteList, {
	options: (ownProps) => {
		const { lastMeasuredAt } = ownProps;
		const filters = {
			'memberId': ownProps.patientId,
		...ownProps.filter,
		};
		if(lastMeasuredAt) {
			filters.from = lastMeasuredAt;
		}
		const variables = {
			filters,
			page: _.get(ownProps, 'page.current', 1),
			count: 10,
			sort: { 'field': 'CREATED_AT', 'direction': 'DESC' }
		}
		const sortField = _.get(ownProps, 'sort.fieldGraph', null);
		if (sortField) {
			variables.sort = {
				field: sortField,
				direction: _.get(ownProps, 'sort.direction', 'DESC'),
			};
		}
		return {
			variables,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
		}
	}
});
const mapState = (state, ownProps) => ({
	...ownProps,
	...state.globalTables.reengagement
})
const mapDispatch = (dispatch, ownProps) => {
	return {
		...tableActions
	}
};
export default compose(connect(mapState,mapDispatch),getPatientHiatusStatus,withData)(ReengagementContainer);
