import Client from 'libModule/gqlClient';
import { setSystemDynamicConfig, deleteSystemDynamicConfig } from 'modulesAll/graphql/systemDynamicConfigs';
import { React, IHSearchTable, IHButton } from 'ihcomponent';
import {Button, Input, Modal} from 'antd';
import { GQLHelper } from 'lib/utils';

const initState = {
  showEditModal: false,
  showDeleteModal: false,
  modalConfig: null,
  editValue: ''
}

export default class SystemDynamicConfigsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...initState};
  }

  // componentWillMount() {
  //   this.props.onLoadParams(null, true);
  // }

  renderEditModal = doc => <Modal
    title={'Edit: ' + doc.configKey}
    id={`e-${doc.id}`}
    visible={this.state.showEditModal}
    onOk={this.handleSubmit}
    onCancel={() => this.handleCancel()}
    footer={[
      <Button key="back" onClick={() => this.handleCancel()}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" onClick={() => this.handleSubmit('edit', doc)}>
        Done
      </Button>
    ]}>
    <Input value={this.state.editValue} onChange={e => this.setState({ editValue: e.target.value })}/>
  </Modal>

  renderDeleteModal = doc => <Modal
    title={'Delete: ' + doc.configKey}
    id={`d-${doc.id}`}
    visible={this.state.showDeleteModal}
    onOk={this.handleDeleteSubmit}
    onCancel={() => this.handleCancel()}
    footer={[
      <Button key="back" onClick={() => this.handleCancel()}>
        Cancel
      </Button>,
      <Button key="submit" type="primary" onClick={() => this.handleSubmit('delete', doc)}>
        Delete
      </Button>
    ]}>
      Confirm?
  </Modal>

  handleSubmit = (action, doc) => {
    const isEdit = action === 'edit';
    const mutation = isEdit ? setSystemDynamicConfig : deleteSystemDynamicConfig;
    const successfulMsg = `${isEdit ? 'Updated' : 'Deleted'} successfully`;
    const variables = { id: doc.id };
    if(isEdit) variables.value = this.state.editValue;
    Client.mutate({
      mutation,
      variables,
    }).then(() => {
      this.handleCancel();
      this.props.completeAction(successfulMsg);
    }).catch(e => {
      console.log(e);
      this.props.openErrorModal(GQLHelper.formatError(e))
    });
  }

  handleCancel = () => this.setState({ ...initState });

  showEditWindow(doc) {
    this.setState({
      showEditModal: true,
      modalConfig: doc,
      editValue: doc.value,
    });
  }

  showDeleteWindow(doc) {
    this.setState({
      showDeleteModal: true,
      modalConfig: doc
    });
  }

  getTableProps() {
    const { configsDataSource, searchStr, onTableSearchChange, onSearchLoading } = this.props;
    const dataSource = 
      !searchStr ? configsDataSource 
        : (onSearchLoading ? [] : 
          _.filter(configsDataSource, e => {
            const lowerSearchStr = _.toLower(searchStr).trim();
            if(_.includes(_.toLower(e.configKey), lowerSearchStr)) return true;
            if(_.includes(_.toLower(e.value), lowerSearchStr)) return true;
          }));

    return {
      title: 'System Dynamic Configs',
      rowKey: doc => doc.id,
      showSearchInput: true,
      searchValue: searchStr,
      inputPlaceholder: 'Search by name and value substring',
      onSearch: onTableSearchChange,
      // onChange: (p, f, s) => {
      //   const newFilter = { ...f};
      //   const newPage = {...p};
      //   const newSorter = {..._.pick(s, ['field', 'order'])};
      //   return onTableChange(newPage, newFilter, newSorter);
      // },
      loading: !dataSource || onSearchLoading,
      dataSource,
      columns: [
        {
          width: '40%',
          key: 'configKey',
          title: 'Key',
          dataIndex: 'configKey',
        },
        {
          width: '40%',
          key: 'value',
          title: 'Value',
          dataIndex: 'value',
        },
        {
          width: '20%',
          title: 'Actions',
          key: 'Actions',
          render: (t, doc) => {
            const config = _.omit(doc, '__typename');
            const buttonProps = {
              size: 'exsmall',
              bsStyle: 'primary',
              className: 'table-action-button'
            };
            return (
              <div id={config.id}>
                <IHButton label='Edit' {...buttonProps} style={{marginRight: '5px'}} onClick={() => this.showEditWindow(config)}/>
                <IHButton label='Delete' {...buttonProps} onClick={() => this.showDeleteWindow(config)}/>
              </div>
            )
          }
        }
      ],
      // pagination: {
      //   current: _.get(page, 'current', 1),
      //   pageSize: _.get(page, 'pageSize', 10),
      //   total: _.get(this.props.list, 'pageInfo.total', 0),
      // },
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>No dynamic system config found</p>
      </div>
    );
    const { showEditModal, showDeleteModal, modalConfig  } = this.state;

    return (
      <div style={{ padding:20}}>
        <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
        {showEditModal && this.renderEditModal(modalConfig)}
        {showDeleteModal && this.renderDeleteModal(modalConfig)}
      </div>
    );
  }
}