import {moment} from "../../../../../package/IHComponent";
import API from "../../VideoChat/API";
import action from '../../VideoChat/action/index';
import { VISIT_COUNT_MAX } from 'libModule/constants';
import { Checkbox, Tooltip, Icon } from 'antd';
import React from 'react';
import { getProviderName } from "../../../lib/utils";
import I18N from "../../I18N";

const filterDoctors =  ({ shortName = true } = {}) => {
    const teams = JSON.parse(sessionStorage.getItem('currentUser'))&&JSON.parse(sessionStorage.getItem('currentUser')).team;
      let doctors =[];
      for(let index in teams){
          const members = teams[index].members;
          for(let memberId in members){
                const member = members[memberId];
                const allRoles = _.get(member,'user.allRoles',[]);
                const hasDoctorRole = _.filter(allRoles,a=>a.name=='Doctor').length!=0;
                const p  = member.user;
                if(hasDoctorRole){
                      const doctor = {
                            id: p.id,
                            fullName: (getProviderName && shortName) ? 
                                      getProviderName(p) : 
                                      _.get(p, 'profile.fullName', '--'),
                            primary:true
                      }
                      if(!_.find(doctors,{id: p.id}))
                        doctors.push(doctor);
                }
          }
      }

      return doctors;
}

const PARTICIPANT_ENUM = [
    { value: ['CA'], text: 'CA' },
    { value: ['RD'], text: 'RD' },
    { value: ['MD'], text: 'MD' },
    { value: ['MA'], text: 'MA' },
    { value: ['CA','MA','MD','RD'], text: 'ALL' }
]

const combineDateAndTime =(date,time)=>{

    const startOfDate = moment(date).startOf('day').toDate().getTime();
    const startOfTime = moment(time).startOf('day').toDate().getTime();
    const timeInMs = moment(time).toDate().getTime() - startOfTime;
    const combinedTime = (startOfDate + timeInMs);
    return combinedTime;
}
const setFromAndToDate = (variables,defaultStartDay,defaultEnDay,sortByConfirm)=>{
    variables.appointmentFrom  = Number(defaultStartDay);
    variables.appointmentTo = Number(defaultEnDay);
    variables.count = VISIT_COUNT_MAX;
    const { provider } = { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                res.push(d.id)
                return res;
            },[]
        )};
    variables.providerIds= provider;
    variables.sort = {};
    variables.sortByConfirm = sortByConfirm||false;
    return variables;
}
const getUniqueIds = (visitList)=>{
    const groupedVisits = _.groupBy(visitList,v=>v.member.id);
    const ids =Object.keys(groupedVisits);
    return ids;
}

const parseVisitList = (visitList,clientStatus)=>{
    const groupedStatus = _.groupBy(clientStatus,c=>c.visitId);
    let obj = {};
    for (let [i, k] of Object.entries(groupedStatus)) {
        obj[i] = groupedStatus[i][0];
    }
    for(let [i,k] of Object.entries(obj)){
        _.forEach(visitList,v=>{
            if(v.id==i){
                v = Object.assign(v,{
                    ...k
                })
            }
        })
    }
}

const handleJoinMeeting=(meetingNumber,password,setVisit,visit,apikey,signature)=>{
    const zoomMeeting = document.getElementById('zmmtg-root');
    action.joinMeeting(meetingNumber,password,zoomMeeting,setVisit,visit,apikey,signature);

}
const handleJoinVideo = async (visit,setVideoVisit,localThis)=>{
    const { status } = visit;
    const visitId = _.get(visit,'id');
    if(status==='CALLING_IN') {
        await  API.activePatientMutate({
            eventType: "ACCEPT",
            visitId
        })
    }

    const token = await API.getVideoToken({ visitId });
    const { data } = token;
    const { getVirtualVisitToken } = data;
    const { password,meetingNumber,apikey,signature } = getVirtualVisitToken;
    const memberId = _.get(visit,'member.id');
    localThis.meetingNumber = meetingNumber;
    handleJoinMeeting(meetingNumber,password,setVideoVisit,memberId,apikey,signature,visitId);
};

const renderPhoneIndicator = (phone) => {
  const hasPhone = Array.isArray(phone) && !!phone.length;
  return !hasPhone ? (
    <Tooltip title='No phone number exists for this patient'>
      <Icon type='info-circle'  style={{color:'#F0666B', fontSize: 14}}/>
    </Tooltip>
  ) : null;
};

const patientIndicator = (user, visitCategory)=>{
  const { phoneReportInfos, phone } = user;
  const isVirtual = visitCategory === 'VIRTUAL';
  //Sort based on Last used time
  phoneReportInfos.sort((phoneOne, phoneTwo) => {
    return phoneTwo.LastUsedAt - phoneOne.LastUsedAt;
  });
  let hasLoggedIn = false;
  let newerThan1810 = false;
  let olderThanLatest = true;
  let update = true;
  let camera = false;
  let audio = false;
  //Get The Last Used Device
  const phoneInfo = _.get(phoneReportInfos, '0', null);
  let lastUsedPhone = phoneInfo ? phoneInfo : null;
  if(lastUsedPhone) {
      const {LastUsedAt, mobileInfo,authorityInfo} = lastUsedPhone;
      camera = authorityInfo.cameraStatus === 'granted';
      audio = authorityInfo.microphoneStatus === 'granted';
      let appVersion = mobileInfo.appVersion;
      let latestAppVersion = mobileInfo.latestAppVersion;
      
      if(LastUsedAt){
          hasLoggedIn = true;
      }
      
      if(appVersion && update){
          let versionInNum = Number(appVersion.split('.').join(''));
          let latestAppVersionInNum = latestAppVersion === null ? 1817 : Number(latestAppVersion.slice(0,6).split('.').join(''));
          newerThan1810 = versionInNum >= 1810;
          olderThanLatest = versionInNum < latestAppVersionInNum;
      }
  }
  update = newerThan1810 && olderThanLatest;
  let indication = update ? 'The patient needs to update the App. ' : '';
  let permission = camera ? '' : ' camera';
  permission += audio ? '' : ' audio';
  indication += permission === '' ? '' : 'App doesn’t have' + permission + ' permission.';
  indication = !hasLoggedIn ? I18N.get('visit.tips.noApp') : indication;
//    if(lastUsedPhone && hasLoggedIn) {
//         Mixpanel.track('login', 'app successfully', '', {APP_VERSION: lastUsedPhone.mobileInfo.appVersion});
//         if(!camera || !audio) {
//             Mixpanel.track('show','app status indicator','dashboard', {NEED_PERMISSION: indication});
//         } else {
//             Mixpanel.track('dismiss','app status indicator','dashboard', {DISMISS_PERMISSION: 'Camera and Audio'});
//         }
//    }
   
  return {
    hasLoggedIn,
    update,
    message: indication,
    render: (containerStyle = {}, imgStyle = {}) => visitCategory &&
    <span style={{ maxWidth: 'fit-content', marginRight: 8, ...containerStyle }}>
      {
        ({
          PHONE: (
            <React.Fragment>
              <img src='/image/telephone.svg' style={{ width: 20, marginRight: 3, marginTop: -5, filter: 'contrast(0.3)', ...imgStyle }} />
              {renderPhoneIndicator(phone)}
            </React.Fragment>
          ),
          VIRTUAL: (
            <React.Fragment>
              <img src='/image/camera.svg' style={{ width: 20, marginRight: 3, marginTop: -1, filter: 'contrast(0.3)', ...imgStyle }} /> 
              { 
                indication !== '' &&
                <Tooltip title={indication}>
                  <Icon type='info-circle'  style={{color:'#F0666B', fontSize: 14}}/>
                </Tooltip> 
              }
            </React.Fragment>
          )
        })[visitCategory] || ''
      }
    </span>,
    renderIndicator: (containerStyle) => ({
      PHONE: (
        <React.Fragment>
          {renderPhoneIndicator(phone)}
        </React.Fragment>
      ),
      VIRTUAL: (<span style={{ maxWidth: 'fit-content', ...containerStyle }}>
        { 
          indication !== '' &&
          <Tooltip title={indication}>
            <Icon type='info-circle'  style={{color:'#F0666B', fontSize: 14}}/>
          </Tooltip> 
        }
      </span>)
    })[visitCategory] || ''
  };
};

// const getProviderListFromVisits = (visits)=>{
//     const visitsUniqByProviderId = _.uniqBy(visits,(v)=>_.get(v,'providers.0.id'));
//     const providersList = _.map(visitsUniqByProviderId,(v)=>_.get(v,'providers.0'));
//     return providersList;
// }

const generateFilters = (filterKey,list,localThis,keyPath,valuePath,render)=>{
    const { onChangeFilter } = localThis
    const value = _.get(localThis,`state.tempFilters.${filterKey}`);
    return <Checkbox.Group onChange={ (checkedValue)=>onChangeFilter(filterKey,checkedValue) } value={value}>
            {
                _.map(list,p=><div className={`${filterKey}Filter`}><Checkbox id={_.get(p,keyPath)} value={_.get(p,keyPath)}>{ render ? render(_.get(p,keyPath)) : _.get(p,valuePath)}</Checkbox></div>)
            }
           </Checkbox.Group>
};

const renderFilterContainer = (filterKey,list,localThis,keyPath,valuePath,render)=>{
    const checkboxList = generateFilters(filterKey,list,localThis,keyPath,valuePath,render);
    return checkboxList
}

export const helpers = {
    filterDoctors,
    combineDateAndTime,
    setFromAndToDate,
    getUniqueIds,
    parseVisitList,
    handleJoinVideo,
    patientIndicator,
    PARTICIPANT_ENUM,
    generateFilters,
    renderFilterContainer
}