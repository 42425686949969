import { combineReducers } from 'redux'
import Common from 'ProgramIncompleteModule/Common/reducers/CommonReducer'
import ProgramInfoForm from 'ProgramIncompleteModule/ProgramInfo/reducers/ProgramInfoFormReducer'
import DeviceForm from 'ProgramIncompleteModule/Device/reducers/DeviceFormReducer'
import Careplan from 'ProgramIncompleteModule/Careplan/reducers'

const ProgramIncomplete = combineReducers({
  Common,
  ProgramInfoForm,
  DeviceForm,
  Careplan
})

export default ProgramIncomplete
