import React from 'react';
import ConsentTemplateListComponent from "../component/ConsentTemplateListComponent";
import { compose, graphql } from 'react-apollo/index';
import query from "../../query";
import helper from "../../helper";
const CONSENT_FORM_MAX_COUNT = 100;
const ConsentTemplateListContainer = class extends React.Component{
    componentDidUpdate(prevProps, prevState, snapshot) {
        const loadingPre = prevProps.loading;
        const loadingCur = this.props.loading;
        const { refetchTemplateGroupList } = this.props;
        // console.log(refetchTemplateGroupListPre,refetchTemplateGroupList);
        if(loadingPre!=loadingCur&&refetchTemplateGroupList) {
            this.props.setRefetchTemplateGroupList(refetchTemplateGroupList);
        }
    }

    render() {
        const { templateGroupsList,loading,refetchTemplateGroupList,pageInfo } = this.props;
        if(loading){
            return <span>loading</span>;
        }
        return <ConsentTemplateListComponent templateGroupsList={templateGroupsList} refetchTemplateGroupList={refetchTemplateGroupList} pageInfo={pageInfo}/>
    }
}
const templateGroupsList = graphql(query.templateGroupsListQuery,{
    options: (ownProps) => {
        return {
            variables:{
                count: CONSENT_FORM_MAX_COUNT
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-first'
        }
    },
    props: ({data}) => {
        const { templateGroupOrganizationsList,loading,refetch } = data;
        return {
            templateGroupsList: _.get(templateGroupOrganizationsList,'data',[]),
            pageInfo:_.get(templateGroupOrganizationsList,'pageInfo'),
            loading,
            refetchTemplateGroupList:refetch
        }
    }
})
export default compose(templateGroupsList)(ConsentTemplateListContainer);