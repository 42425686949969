import React from 'react';
import { cgmBrandNameMap, useCGMContext } from '../../context/CGMContext';

export const CGMVitalDisplayTextComponent = () => {
  const { hasCGM, CGMInfo } = useCGMContext();

  return hasCGM
   ? (
    <span>
      CGM
      {' '}
      ({cgmBrandNameMap[CGMInfo.cgmBrand] || '--'})
    </span>
   ) : null;
};
