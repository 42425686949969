import { combineReducers } from 'redux';
import type from '../constants';
import moment from "moment/moment";

const INITIAL_STATE = {
    logInfo: {},
    updatedLogInfo:{},
    initFoodLogList:{},
    // toDate:moment(new Date()).endOf('day'),
    // fromDate:moment().subtract(13,'day').startOf('day'),
    // timeInterval:'w',
    value: 0,
    year: 0
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    // let newObj = _.cloneDeep(state);
    let logInfo = { ...state.logInfo };
    let id = _.get(action.payload,'id');
    let { value,year } = state;
    let newYear = _.get(action,'payload',year);
    let newMonth = _.get(action,'payload',value);


    switch (action.type) {
        // case type.INIT_FOOD_LOG_LIST:
        //     newObj.initFoodLogList = action.payload.data;
        //     return newObj;
        case type.SET_INITIAL_STATE:

            return {
                logInfo: {},
                updatedLogInfo:{},
                initFoodLogList:{},
                value:moment(new Date()).month(),
                year:moment(new Date()).year()
            }

        case type.RESET:
            if(logInfo[id]){
                logInfo[id] = {};
            }
            return {
                ...state,
                logInfo:logInfo
            };

        // case type.RESET_INIT_FOOD_LOG_LIST:
        //     newObj.initFoodLogList = { };
        //     return newObj

        case type.SET_RATING:
            const rating = action.payload.rating;

            if(rating==0){
                Object.assign(logInfo, {
                    [id]: {
                        ...logInfo[id],
                        rating:0,
                        isEditMode: false
                    }
                })
                return {
                    ...state,
                    logInfo:logInfo
                }
            }

            if((logInfo[id]&&logInfo[id].rating != rating) || (!logInfo[id])) {
                Object.assign(logInfo, {
                    [id]: {
                        ...logInfo[id],
                        rating: rating,
                        isEditMode: true
                    }
                })
            }
            return {
                ...state,
                logInfo:logInfo
            };

        case type.UPDATE_COMMENTS:
            const comments = action.payload.comments;

            if (comments == null || !comments.replace(/\s/g, '').length) {
                Object.assign(logInfo, {
                    [id]: {
                        isEditMode: false
                    }
                });
                return {
                    ...state,
                    logInfo:logInfo
                };
            }

            if((logInfo[id]&&logInfo[id].comments != comments) || (!logInfo[id])) {
                    Object.assign(logInfo, {
                        [id]: {
                            ...logInfo[id],
                            comments: comments,
                            isEditMode: true
                        }
                    });
            }

            return {
                ...state,
                logInfo:logInfo
            };


        //change to previous month
        case type.CHANGEPRE:

            if(value-1>=0){
                value = value-1;
            }

            return {
                ...state,
                value:value
            };

        //change to next month
        case type.CHANGENEXT:
            if(value+1<=11){
                value ++;
            }

            return {
                ...state,
                value:value
            };
        //change year
        case type.CHANGEYEAR:
            return {
                ...state,
                year:newYear
            };
        //chang month
        case type.CHANGEMONTH:
            return {
                ...state,
                value:newMonth
            };

        case type.RESETSTATE:
            return INITIAL_STATE;
        default:
            return state
        }
}


export default {
    foodlog: combineReducers({
        main: reducer
    })
};
