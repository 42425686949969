if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

var IEVersion = ( !!window.ActiveXObject && +( /msie\s(\d+)/i.exec( navigator.userAgent )[1] ) ) || NaN;
// Internet Explorer 6-11
var isIE = /*@cc_on!@*/false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
if(IEVersion === 9) {
  require('./polyfills/ie9');
  require('iterators-polyfill')
}
else if(IEVersion === 10) {
  require('./polyfills/ie10');
  require('iterators-polyfill')
}
else if(isIE || isEdge) {
  require('./polyfills/ieEdge');
  require('iterators-polyfill')
}

require('./polyfills/matchmedia');

require('whatwg-fetch');
