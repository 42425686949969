import {Modal} from "antd";
import {ccpUrl, clicktoDial} from "../../call/helper/helpers";
import React from 'react';
import Mixpanel from '../../mixPanel/mixPanel';
import { getMostRecentEP } from '../../../lib/utils/enrolledProgram';
export default (localThis,phoneNumber,row)=>{
    if (localThis.props.contact === 'onConnecting' || localThis.props.contact == 'onConnected') {
        return window.CCPClass && window.CCPClass.openCCP();
    }

    // validate phone number
    if (phoneNumber == null) {
        Modal.error({
            title: "Error",
            content: "Phone Number is invalid"
        })
        return
    }

    // validate agent
    // when user logout, terminate CCP, then portal user relogin, agent is undefined.
    // the process is load steam -> addCCP -> subscribe

    let agent = window.awsCCP != undefined ? window.awsCCP.agent : undefined;
    if (agent == undefined) {
        Modal.confirm({
            title: 'Click Yes to log in call center, choose Cancel if you are already logged in',
            content: <div><br/>  Please keep the pop-up CCP(Contact Control Panel) open on the side. <br/>  <br/>
                If you are prompted to allow access to your microphone and speaker, choose Allow. <br/>  <br/>
                Stay on the patient page during calling, so that Call Log can be popped up when the call ends.</div>,
            okText: `Yes`,
            onOk: () => {
              window.CCPClass.checkLogin();
            },
            cancelText: `Cancel`,
            onCancel:()=>{
            }
        })
        return
    } else {
        // one call at a time
        let state = agent.getState()
        if (state && (state.name == 'CallingCustomer' || state.name == 'Busy')) {
            // Modal.error({
            //     title: "Error",
            //     content: "Cannot dial now, the line is busy."
            // })
            return
        }

        // Offline happens(1) logout (2) Agent set status to Offline
        // set Agent online, which is no need when portal users make calls
        // could be useful for inbound call
        if (state && state.name == 'Offline') {

            let routableState = agent.getAgentStates().filter(function(state) {
                return state.type === global.connect.AgentStateType.ROUTABLE;
            })[0];
            //  console.log('routableState', routableState)
            agent.setState(routableState, {
                success: function() {
                    // console.log('success')
                },
                failure: function() {
                    // console.log('failure')
                }
            });
        }
    }

    // switch clinic if patient is in different clinic
    if(localThis.setShowStickyNoteModal){
      localThis.setShowStickyNoteModal(true,row, () => {
        startCalling(localThis,phoneNumber,row)
      });
    } else {
      startCalling(localThis,phoneNumber,row);
    }    
}

const startCalling = async (localThis,phoneNumber,row) => {
  let username = ''
    const member = _.get(row,'member');
    if (member) {
        username = _.get(member,'profile.fullName');
    };
    const invokePhone = _.get(localThis,'callPhone',_.get(localThis,'props.callPhone'));
    const setContact = _.get(localThis,'setContact',_.get(localThis,'props.setContact'));
    invokePhone(phoneNumber, username);

    //sync panel status
    setContact('onConnecting');

    //mark patient id
    const patientId  = member.id;
    const res = await getMostRecentEP(patientId);
    const mostRecentEP = _.get(res, 'data.enrolledProgramList.data.0');
    // let contact = {'patientId': patientId, 'name': username }
    // localStorage.setItem('call-center-last-contact', JSON.stringify(contact))
    
    if (sessionStorage.getItem('inboundAttributes')){
        sessionStorage.removeItem('inboundAttributes');
    }
    // save outbound attribute, click to jump
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    let institution = currentUser.selectedRole.organization.id;
    let outboundAttributes = {
      patientId:{value: patientId}, 
      organizationId: { value: institution }, 
      name: { value: username },
      enrolledProgramId: {value: _.get(mostRecentEP, 'id')},
      operationStatus: {value: _.get(mostRecentEP, 'status')},
    }

    if (sessionStorage.getItem('outboundAttributes')){
        sessionStorage.removeItem('outboundAttributes');
    }
    sessionStorage.setItem('outboundAttributes', JSON.stringify(outboundAttributes));
    clicktoDial(phoneNumber);
    Mixpanel.track('clicked', 'phone', 'clickToDial', { "PATIENT_ID": patientId });
}