import { startActionMap, endActionMap } from '../constants/map';
import actionCreator from '../actions';
import { createClaimedTimeLog } from '../../patient/main/API';
import moment from 'moment';

export const calculateTotalTime = (clientEvents) => {
  let totalTime = 0;

  for (let i = clientEvents.length-1; i >= 1; i--) {
    const curEvent = clientEvents[i];
    const preEvent = clientEvents[i-1];

    if (curEvent.timestamp && endActionMap[curEvent.action]) {
      if (preEvent.timestamp && startActionMap[preEvent.action]) {
        totalTime += (curEvent.timestamp - preEvent.timestamp);
      }
    }
  }

  return totalTime;
}

export const getTimerClaimedId = (id, patientId) => {
  return `${id}::${patientId}`;
}

export const mapDispatchToTimerActions = (dispatch, id, patientId) => {
  const actions = actionCreator(id, patientId);
  return {
    startTimer: (event) => dispatch(actions.startTimer(event)),
    pauseTimer: (event) => dispatch(actions.pauseTimer(event)),
    resumeTimer: (event) => dispatch(actions.resumeTimer(event)),
    pauseTimeOut: (event) => dispatch(actions.pauseTimeOut(event)),
    resumeTimeOut: (event) => dispatch(actions.resumeTimeOut(event)),
    stopTimer: (event, cb) => dispatch(actions.stopTimer(event, cb)),
    resetTimer: () => dispatch(actions.resetTimer()),
    abort: (event) => dispatch(actions.abort(event)),
    // setUUId: (uuid) => dispatch(setUUId(uuid)),
    setCoolDown: (coolDown) => dispatch(setCoolDown(coolDown)),
    focusPage: () => dispatch(actions.focusPage()),
    blurPage: () => dispatch(actions.blurPage()),
    interacted: (interactionName, description = '') => dispatch(actions.interacted(interactionName, description)),
    setDone: (value) => dispatch(actions.setDone(value)),
  }
}

export const getUniqueInteractions = (timerInfo) => {
  const { interactions = [] } = timerInfo;
  return _.uniq(interactions.map(v => v.name));
}

export const sendClaimedTimeReq = (variables) => {
  const { serviceDate, monthOfYear, totalTime, startTime, endTime, ...rest } = variables;
  const now = moment();
  const mOfYear = parseInt(now.format('YYYYMM'));
  if (Math.round(totalTime / 1000) === 0) { return; }

  // console.log({ ...rest, monthOfYear: monthOfYear || mOfYear, serviceDate: serviceDate || now, totalTime: totalTime / 1000 });
  return createClaimedTimeLog({
    ...rest, monthOfYear: monthOfYear || mOfYear,
    serviceDate: (serviceDate || now).valueOf(),
    totalTime: Math.round(totalTime / 1000),
    startTime: startTime ? startTime.valueOf() : undefined,
    endTime: endTime ? endTime.valueOf() : undefined,

  })
}
