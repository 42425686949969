import gql from 'graphql-tag'

export default gql` mutation updateIoTDeviceMember($deviceId:String!, $position: Int, $memberId: EID) {
  updateIoTDeviceMember(deviceId: $deviceId, position:$position, memberId: $memberId) {
    id
    deviceId
    position
    member {
      profile {
        fullName
      }
    }
    config{
        language
        volume
    }
    createdAt
    updatedAt
    isActive
    accessedAt
    quittedAt
  }
}`
