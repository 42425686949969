import React from 'react';
import PropTypes from 'prop-types';

import { getTimeWithFormat } from '../../helper/signOffHelper';

const SignOffSubmitInfo = (props) => {
  const { submittedByLabel, submittedBy, submittedAtLabel, submittedAt, submittedAtFormat } = props;

  return (submittedBy || submittedAt) ? (
    <div className='submitInformation'>
      <div>
        {
          submittedBy ? 
          <div>
            <span>{submittedByLabel || 'Submitted by:'}</span>
            <span style={{ marginLeft: 5 }}>{submittedBy || '--'}</span>
          </div>
          : null
        }        
      </div>
      <div>
        {
          submittedAt ?
          <div>
            <span>{submittedAtLabel || 'on'}</span>
            <span style={{ marginLeft: 5 }}>{getTimeWithFormat(submittedAt, submittedAtFormat)}</span>
          </div>
          : null
        }
      </div>
    </div>
  ) : null;
};

SignOffSubmitInfo.propTypes = {};
export default SignOffSubmitInfo;