const map = {
    init:{
        MAWorkingList:[
            'onBoard','VisitBilling','Medication','LabResults','FollowUpVisit'
        ],
        RDWorkingList:[
            'ChronicDisease','Medication','LifestyleRoutine','NutritionIntake','NutritionInterpretation','GoalStatement','EnrollmentStatus','Charting',
        ]
    }
}

export default  map;
