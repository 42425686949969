import {
    React, BaseComponent, ND, _, classNames
} from './util'


const Input = ND.Input
const InputGroup = Input.Group
const Component = class extends BaseComponent{
    constructor(p) {
      super(p)
      this.onChange = this.onChange.bind(this)
      this.state = {
        value: this.props.value
      }
    }

    onChange(e) {
      const value = e.target.value
      if (value !== null && value !== undefined) {
        this.setState({ value });
      }
      this.props.onChange(e)
    }

    render(){

        return (
            <div className="IH-Input ant-search-input-wrapper">
                <InputGroup className="ant-search-input">
                    {this.renderInput()}
                    <div className="ih-icon ant-input-group-wrap">
                        {this.renderIcon()}
                    </div>
                </InputGroup>
            </div>
        )
    }

    handleEnter(e){
        const v = this.input.refs.input.value
        if(this.props.onPressEnter){
            this.props.onPressEnter(v)
        }
    }

    renderInput(){
        const p = _.omit(this.props, ['icon', 'onPressEnter', 'onChange']);

        //if "onBlur" is passed in props, only trigger redux action when onBlur for better performance on IE
        if(this.props.onBlur) {
          p.onBlur = this.onChange
        }
        else {
          p.onChange = this.onChange
        }

        // If 'value' is passed as props, then return a controlled component; otherwise, return uncontrolled component
        if (p.value) {
          return (
            <Input ref={refNode => { this.input = refNode }} {...p}
               placeholder={this.props.placeholder}
               value={this.props.value}
               onPressEnter={this.handleEnter.bind(this)} />
          )
        }
        else {
          return (
            <Input ref={refNode => { this.input = refNode }} {...p}
               placeholder={this.props.placeholder}
               defaultValue={this.state.value}
               onPressEnter={this.handleEnter.bind(this)} />
          )
        }
    }

    renderIcon(){
        const p = _.pick(this.props, ['icon', 'size'])

        if(!p.icon){
            return null
        }

        return (
            <ND.Button {...p} className="ant-search-btn" onClick={this.handleEnter.bind(this)} />
        )
    }
}

export default Component
