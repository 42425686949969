import configs from '../../../lib/config';
import { decodeId } from '../../../lib/helpers/common-helpers';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/cgm-controller/getPatientCGMStatus
export const updateCellularDevicesPath = `${domain}/patient/{memberId}/device-status`;
export default (
  params, 
  requestOptions
) => {
  const { 
    patientId, 
    devices,
  } = params || {};

  if (!patientId) {
    console.warn('No patient id found');
    return Promise.resolve(undefined);
  }

  const decodedPatientId = decodeId(patientId);

  const bodyParams = {
    devices,
  };

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(bodyParams),
    ...requestOptions
  };

  const updateCellularDevicesURL = `${updateCellularDevicesPath.replace('{memberId}', decodedPatientId)}`;

  return fetch(
    updateCellularDevicesURL, 
    fetchOptions).then((res) => res.json()
  );
};