import Client from 'libModule/gqlClient';
import { graphql } from 'react-apollo';
import { getAssigneeOptionsQuery } from '../../graphql/assignee';

export const withAssigneeOptions = graphql(getAssigneeOptionsQuery, {
  options: () => {
    return {
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getAssigneeOptions, error } = data;
    
    if(error)
      console.error(error);

    return { 
      loadingAssigneeOptions: loading,
      assigneeOptions: getAssigneeOptions || []
    };
  }
});

export const getTeamsForUser = (userId) => {
  const currentUserId = userId || sessionStorage.getItem('currentUserId');
  return Client.query({
    query: teamsForUser,
    variables: { id: currentUserId },
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });
};
