import React, { useCallback, useMemo } from 'react';
import { Button, Form, Row } from 'antd';
import I18N from '../../I18N';
import FormRenderer from './FormRenderer';
import IcdCodeCondition from './IcdCodeCondition';
import { mntConditionList, mntReferral, patientMntDescription } from '../fields/MntReferral';
import { upsertPatientReferralMutation } from '../API/mutation';
import { getConditionsValue, getIcdCodesValue, getBillableIcdCodesValue, getMNTConditionsValue, getPatientMntDescriptionValue, getWantMntReferral } from '../helper/initialValueHelper';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import { upsertPatientMntProgramMutation } from '../../mntReferral/API';
import { getBinaryRadioValue, getRadioValue, getValue, getPatientConditions } from '../helper';

const MntReferral = ({ FormWrapper, form, onSubmitDone, patientReferral, user, disabled, goNextStep, formWrapperProps }) => {
  const initialValues = useMemo(() => {
    const diagnosisSource = getValue(patientReferral.diagnosisSource, DIAGNOSIS_SOURCE.CONDITION);
    const conditions = getConditionsValue(patientReferral,user);
    const billableIcdCodes = getBillableIcdCodesValue(patientReferral);
    const patientConditions = getPatientConditions(diagnosisSource, conditions, billableIcdCodes);
    // need to take the intersection in case they change the conditions in program eligibility after submitting mnt conditions
    // i.e.: program eligibility: ['DM', 'HLD', 'OBS'] -> mnt: ['HLD', 'OBS'] -> program eligibility: ['DM', 'HLD']
    const selectedConditions = _.intersection(patientConditions, getMNTConditionsValue(patientReferral));
    const selectedPatientMntDescription = getPatientMntDescriptionValue(patientReferral);
    const wantMntReferral = getWantMntReferral(patientReferral);
    // const selectedNonMntConditions = allConditions.filter(v => !MNT_CONDITIONS.includes(v));
    return {
      conditions,
      selectedConditions,
      selectedPatientMntDescription,
      // selectedNonMntConditions,
      wantMntReferral,
    }
  }, []);
  const { validateFields, getFieldsValue } = form;
  const fieldsValue = getFieldsValue();
  const wantMntReferral = getValue(getBinaryRadioValue(fieldsValue.mntReferral), initialValues.wantMntReferral);
  // const mntConditionListValue = getValue(fieldsValue.mntConditionList, initialValues.selectedConditions);
  // const patientMntDescriptionValue = getValue(fieldsValue.patientMntDescription, initialValues.selectedPatientMntDescription);
  const showButton = !_.isNil(wantMntReferral);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return goNextStep();
    }
    validateFields(async (err, values) => {
      if (!err) {
        try {
          const { id: memberId } = user;
          const referredBy = JSON.parse(sessionStorage.getItem('currentUser')).id;
          // console.log(values);
          const variables = {
            patientId: memberId,
            wantMNTReferral: values.mntReferral,
            // billableHealthConditions: values.healthConditions,
            // conditionsToMonnitor: initialValues.selectedNonMntConditions.concat(values.mntConditionList),
            mntConditions: values.mntConditionList,
            patientDescribe: values.patientMntDescription,
          };
          const mntVariables = {
            patientId: memberId,
            referredBy,
            description: values.patientMntDescription,
            mntConditions: initialValues.billableIcdCodes,
            status: 'POTENTIAL',
          };
          if (values.mntReferral === 1) {
            // variables.eligiblePrgorams = _.uniq([...(patientReferral.eligiblePrgorams || []), 'MNT']);
            // console.log('upsertPatientMntProgramMutation', mntVariables)
            await upsertPatientMntProgramMutation(mntVariables);
          }
          // console.log(variables);
          await upsertPatientReferralMutation(variables);
          onSubmitDone();
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, [validateFields, onSubmitDone]);

  return (
    <FormWrapper
      header={I18N.get('referAndEnroll.ccmRpmSuccess')}
      className="basic-information"
      onSubmit={handleSubmit} 
      buttons={showButton && <Button type="primary" htmlType="submit" className="new-style">Next</Button>}
      {...formWrapperProps}
    >
      <Row>
        <FormRenderer form={form} fields={[mntReferral({ initialValue: getRadioValue(initialValues.wantMntReferral) })]} disabled={disabled} />
      </Row>
      {wantMntReferral &&
        <div style={{ padding: '10px 20px', backgroundColor: '#F4F6F8', borderRadius: 4 }}>
          <div style={{ fontSize: 15, color: '#242525', fontWeight: 'bold' }}>{I18N.get('referAndEnroll.mntConditionsToMonitor')}</div>
          <IcdCodeCondition 
            form={form}
            subHeader={/*I18N.get('referAndEnroll.mntConditionsToMonitorSubText')*/''}
            conditionListField={mntConditionList(initialValues.conditions)}
            conditionsInitialValue={initialValues.selectedConditions}
            disabled={disabled}
            diagnosisSource={DIAGNOSIS_SOURCE.CONDITION}
          />
          <Row>
            <FormRenderer form={form} fields={[patientMntDescription({ initialValue: initialValues.selectedPatientMntDescription })]} disabled={disabled} />
          </Row>
        </div>
      }
    </FormWrapper>
  );
}

export default Form.create({ 
  name: 'mntReferral',
  onValuesChange: (props) => {
    props.setHasChange();
  }
 })(MntReferral);
