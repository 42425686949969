import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import actions from '../actions';
import ChatInput from '../components/ChatInputComponent';
import ChatHistory from '../components/ChatHistoryComponent';
import PatientMain from 'patientModule/main/containers/ViewOnlyMain';
import ChatEmptyThirdPanel from '../components/ChatEmptyThirdPanel';
import { openErrorModal } from 'modulesAll/layout/actions/MainModal';
import { API } from '../actions/api';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers'
import TimerContainer from '../../timer/containers/TimerContainer';
import { Icon } from 'antd';
import $ from 'jquery';
import { decryptRole } from 'libModule/utils';
import { MAX_MSG_PER_CHANNEL } from 'libModule/constants';
import roleMap from '../../layout/constants/roleMap';
import markAsUnreadRoles from '../constants/markAsUnreadRoles';
import ChatChannelListContainer from "./ChatChannelistContainer";
// const timeFormatString = 'HH:mm A'; // TODO: Refactor to use i18n
import mesNotificationType from '../../chat/constants/messageTypes';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { changeMenuStats } from "../../layout/actions/Nav";
import { RequestCache } from '../../../lib/utils';
import helper, { checkShouldShowChatRedDot } from '../helper';
import { interacted, stopTimer } from '../../timerClaimed/actions';
import { EVENT_TYPES } from '../../idleTime/constant';
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import Client from 'libModule/gqlClient';
import { userLanguages } from 'modulesAll/graphql/user';
import { OMRONContextProvider } from '../../OMRONContext/OMRONContextProvider';

const CHAT_CHANNEL_TIMER = 'CHAT_CHANNEL_TIMER';
class ChatContainer extends Component {
  static displayName = 'chat/containers/ChatContainer';

  constructor(props) {
    super(props);
    const currentUserId = sessionStorage.getItem('currentUserId');
    const decodedUserId = atob(currentUserId).split(':')[1];
    this.sendMessage = this.sendMessage.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.fetchHistoryFromChs = this.fetchHistoryFromChs.bind(this);
    this.getTimestampOfLastTextMsg = this.getTimestampOfLastTextMsg.bind(this);
    this.beforeUnload = this.beforeUnload.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.state = {
        isLoading:false,
        showStickyHeader:false,
        height:0,
        loadingChatInfo: false,
        isNavExpand: $('.VSM-LeftNav').first().hasClass('VSM-LeftNav-expanded'),
        chatLoadingError: '', // for error message
        userInfo: undefined
    }
    this.ref = null;
  }

  componentWillReceiveProps(nextProps){
      //if channel changed reset patientDiv back to 0 and set showStickyHeader back to false;
      const { selectedChannel, shouldGetUserInfo } = this.props;
      if(nextProps.selectedChannel!=selectedChannel){
          this.props.setPatientDivHeight(0);
          this.setState({
              showStickyHeader:false
          }, async () => {
            if(shouldGetUserInfo) {
              const patientId = this.getSelectedPatientId();
              await Client.query({
                query: userLanguages,
                variables: { id: patientId },
                fetchPolicy: 'network-only'
              }).then(res => {
                this.setState({ userInfo: _.get(res, 'data.user') });
              }).catch(console.error);
            }
          })
      }
  }

  componentWillMount() {
    const { props } = this;
    const userId = sessionStorage.getItem('currentUserId');
    const decodedUserId = atob(userId).split(':')[1];

    props.setCurrentUserId(decodedUserId);
  }

  componentWillUnmount() {
    const isExpanded = $('.VSM-LeftNav').first().hasClass('VSM-LeftNav-expanded');
    // if(!isExpanded) {
    //     if( this.state.isNavExpand ) this.toggleExpand();
    // }

    const { shouldRenderChatWindow = true } = this.props;

    if(shouldRenderChatWindow) { // only clean-up when there is a chat window
      this.props.clearHistory(); // TODO: Refactor!
      this.props.setSelectedChannel("");
    }
  }

  toggleExpand() {
    document.querySelector('.VSM-LeftNav').classList.toggle('VSM-LeftNav-expanded');
    document.querySelector('#master').classList.toggle('pushed');
    document.querySelector('.vsm-top-nav').classList.toggle('pushed');
    document.querySelector('.v-logo').classList.toggle('logo-hidden');
    const isToggled = document.querySelector('.VSM-LeftNav-expanded');
    this.props.changeMenuStats(!!isToggled)
  }

  componentDidMount() {
    const { props } = this;
    // if channels r not already set (for when user navigates to different page, then back to Chat page)
    const channelsArr = [];
    props.channelGroups ? props.channelGroups.map( val => {
        return val.channelGroups.map(channelList=>{
            return channelList.channels.map(channel=>{
                channelsArr.push(channel);
            });
        })
    }):[];

   // if(chatWithIndividual&&channelName&&isFullyLoaded&&selectedChannel.length==0){
   //    setSelectedChannel(channelName);
   // }

    const isExpanded = $('.VSM-LeftNav').first().hasClass('VSM-LeftNav-expanded');
    if(isExpanded) {
        this.toggleExpand();
    }

    window.addEventListener('beforeunload', this.beforeUnload);
  }

  componentDidUpdate(prevProps) {
    const { props,ref } = this;
    const { selectedChannel, channels, interacted } = props;
    // if channels r not already set (for when user navigates to different page, then back to Chat page)
      const channelsArr = [];
      if (channels && selectedChannel && prevProps.selectedChannel != selectedChannel) {
        interacted(this.getSelectedPatientId(), EVENT_TYPES.CONVERSATION, 'Entered chat');
      }
      props.channelGroups ? props.channelGroups.map( val => {
          return val.channelGroups.map(channelList=>{
              return channelList.channels.map(channel=>{
                  channelsArr.push(channel);
              });
          })
      }):[];
    const combinedChannels = _.flatten(channelsArr);
    if (combinedChannels && combinedChannels.length > 0 ) {
       if(!channels||channels.length==0) {
                this.setChannels(combinedChannels);
            }
    }
  }
  // shouldComponentUpdate(nextProps){
  //   // let flag =false;
  //   // if (nextProps.channelGroups&&!_.isEqual(this.props.channelGroups, nextProps.channelGroups)) {
  //   //   // return true if not equal
  //   //   return true;
  //   // }
  //   // const channelsInProps = this.props.channels;
  //   // const channelIsEmpty = Object.keys(channelsInProps).length == 0 ? true : false;
  //   // if(nextProps.channels&&channelIsEmpty){
  //   //   return true;
  //   //
  //   // }
  //
  //   // tell React not to re-render by default
  //   return true;
  // }
  // if browser refresh, and there is selected channel, send ACK message
  beforeUnload() {
    this.props.clearHistory(); // TODO: Refactor!
    this.props.setSelectedChannel("");
  }

  handleInteraction = (description) => {
    const { onInteraction } = this.props;
    if (onInteraction) {
      onInteraction(description);
    }
  }

  async sendMessage(selectedChannel, message) {
    // const userId = sessionStorage.getItem('currentUserId');
    // const { props } = this;
    const res = await API.publishMessage(selectedChannel, message);
    if(!res)
      return;
    
    const CHANNEL_ID = selectedChannel;
    this.handleInteraction('SEND');
    const messageType = _.get(message,'type');
    if(messageType=='fileUpload'){
      Mixpanel.track('sent','image','message');
    } else {
      Mixpanel.track('send','message',null,{ CHANNEL_ID });
    }
    // API.getPubNub().publish({
    //   channel: selectedChannel,
    //   message: message,
    // }, (status, resp) => {
    //   if (status.error) {
    //     // props.openErrorModal(PubNubHelper.formatError(status));
    //     // get new authKey & re-subscribe to channels if expired
    //     if (status.category === 'PNAccessDeniedCategory' && status.operation === 'PNPublishOperation') {

    //       API.apiGetUserChatInfo(userId, props.useChs)
    //         .then(res => {

    //           // need to re-subscribe after auth key reset
    //           const channelGroupsArr = res.data.getUserChatInfo.channelGroup ? [res.data.getUserChatInfo.channelGroup.name] : [];

    //           if (API.getPubNub()) {
    //             API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);

    //             API.getPubNub().subscribe({
    //               channelGroups: channelGroupsArr,
    //               triggerEvents: true
    //             });

    //             API.getPubNub().publish({
    //               channel: selectedChannel,
    //               message: message,
    //             }, (status, resp) => {
    //               if (status.error) {
    //                 console.log('Error re-publishing: ', status);
    //               }

    //             });
    //           }
    //         })
    //         .catch(err => console.log('Error getUserChatInfo, ', err));
    //     }
    //   } else{
    //       // --- 20200604 Thong SC-
    //       // PubNub listener.message sometimes is not responsive even message is published successfully
    //       // make sure to render all message published successfully, and ignore ACK message.type
    //       if(message.type !== 'ACK'){
    //         // msgObj has the same obj structure as in listener.message
    //         const msgObj = {
    //           timetoken: Number(resp.timetoken),
    //           entry: message,
    //           isListening: true, // flag to increment unread counter, only if comes from listener
    //           lastMsgText: message.text,
    //           lastMsgTime: resp.timetoken,
    //           lastMsgSender: message.displayName,
    //           type: message.type,
    //           publisher: message.publisher
    //         };
    //         props.addMessage(props.selectedChannel, msgObj);
    //       }
    //       // const PATIENT_FULL_NAME = _.get(message,'displayName','');
    //       const CHANNEL_ID = selectedChannel;
    //       this.handleInteraction('SEND');
    //       Mixpanel.track('send','message',null,{ CHANNEL_ID });
    //   }
    // });
  }

  retryLoading = (channelId, patientId, lastMsgTimestamp,isBatch,toBottom) => {
    this.setState({ loadingChatInfo: true, chatLoadingError: '' }, () => {
      API.apiGetUserChatInfo().then(res => {
        // need to re-subscribe after auth key reset
        const channelGroupsArr = res.channelGroupsArr;

        if (API.getPubNub()) {
          API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);

          API.subscribePubNub(channelGroupsArr);

          this.fetchHistory(channelId, patientId, lastMsgTimestamp,isBatch,toBottom);
        }
        this.setState({ loadingChatInfo: false });
      }).catch(err => {
        console.error('Error getUserChatInfo, ', err);
        this.setState({ loadingChatInfo: false, chatLoadingError: 'chat user info'})
      });
    });
  }

  fetchHistory(channelId, patientId, lastMsgTimestamp,isBatch,toBottom) {
    const { props } = this;
    // without lastMsgTimestamp, it will fetch history from the current point of time
    const isFetchingMore = !!lastMsgTimestamp;
    if (channelId) {
        const historyVariables = {
          channel: channelId,
          stringifiedTimeToken: true,
          count: MAX_MSG_PER_CHANNEL
        };
        if(isFetchingMore) historyVariables.start = lastMsgTimestamp;

        this.setState({isLoading:true},()=>{
          !this.props.useChs ?
          API.getPubNub().history(historyVariables, (status, response) => {
            if(status.error){
              const { message, service, payload } = status.errorData;
              console.error(`Failed to load chat history Message: ${message} | service: ${service}`, payload);
              this.setState({ isLoading: false, chatLoadingError: 'chat history' });
            } else if (response) {
              const messageCount = response.messages.length;
              const hasMoreMessage = messageCount === MAX_MSG_PER_CHANNEL; // probably has more messages
              // if there is history, query & add to userMap & add to chat history
              // 'start' option does not always return msgs up to startTimeToken (exclusive) so,
              // do not add duplicate messages to chat history
              let lastMsgTS = parseInt(response.endTimeToken);
              props.addChatHistory(channelId, patientId, response.messages, lastMsgTS, messageCount, false, toBottom, hasMoreMessage);

              if(
                messageCount > 0 &&
                // don't need to update lastMsgTime and lastMsgText when load more history of a chat channel
                !isFetchingMore
              ){
                let msgObj = helper.getLastMessageInfo(response.messages);
                msgObj.isListening = false;
                if(msgObj.lastMsgSender && msgObj.lastMsgText && msgObj.lastMsgTime){
                  props.updateChannelInfo(channelId, msgObj);
                }
              }
            }
            this.setState({ isLoading:false });
          }) : this.fetchHistoryFromChs(channelId, patientId, lastMsgTimestamp, toBottom, isFetchingMore);
      });
    }
  }

  fetchHistoryFromChs(channelId, patientId, lastMsgTimestamp, toBottom, isFetchingMore) {
    const optionalParams = {};
    if (isFetchingMore && lastMsgTimestamp != "0") {
      optionalParams.fromtimestamp = lastMsgTimestamp;
    }
    helper.fetchChatHistoryFromCHS(
      patientId,
      {
        params: optionalParams,
        toBottom,
        shouldUpdateUnread: !isFetchingMore
      }
    )
    .catch(console.error)
    .finally(() => this.setState({ isLoading: false }));
  }
  getTimestampOfLastTextMsg(messages) {
      let lastMessageTimestamp = null;
      // since first message in array is oldest, get last msg timestamp starting from index 0
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].entry.type === 'text') {
          lastMessageTimestamp = messages[i].timetoken;
          break;
        }
      }
      return lastMessageTimestamp;
  }

  getSelectedPatientId(){
    const {selectedChannel, channels} = this.props;
    if(selectedChannel && channels){
      const id = _.get(channels[selectedChannel],'patientId');
      return id ? btoa(`accounts:${id}`):'';
    }else{
      return null;
    }
  }

  handleContainerScroll(e){
    const target = e.target;
    const height = $(target).find("#profile_detail").outerHeight();
    const visitListTop = _.get($(target).find('.patientAppointCollapse').parent().parent().offset(),'top');

    const { profileHeight } = this.props;
    if(this.props.profileHeight==0){
        this.props.setPatientDivHeight(height);
    }
    //54 is the height of top menu;
    //87 is the height of collapsed patient profile div;

    //if scroll to height less than top menu height plus collapsed patient profile div,
    //then show the sticky header;
    if(visitListTop < -50){
        this.setState(((preState)=>{
            if(!preState.showStickyHeader){
                return { showStickyHeader: true }
            }
        }))
        return;
    }
    //if scroll to height to 100px less than original patient profile div
    // hide sticky header;
    if(visitListTop > 50){
        this.setState((preState)=>{
            if(preState.showStickyHeader){
                return { showStickyHeader: false }
            }
        })
        return;
    }
  }
  handleMarkAsUnread(val){
    const { props } = this;
    const {showChat, channels, selectedChannel, setSelectedChannel, setChatStatus, patientId} = props;

    const curChannel = channels[val];

    const curChLastMsgIndex = curChannel.history.length - 1;
    // console.log('leaveChannel: ', leaveChannel, ' lastMsg: ', lastMsg);
    if(curChannel.counter === 0) {
      helper.sendACKMessage(val,curChannel,'markAsUnread');
    }
    if(showChat && selectedChannel){
      setSelectedChannel('');
    }
    setChatStatus(false);
    Mixpanel.track('clicked','mark_as_unread','patient_profile',{ PATIENT_ID: this.getSelectedPatientId() });
  }

  renderChatHeader = () =>{
      const { channels, setSelectedChannel,selectedChannel } = this.props;
      const setChatStatus = _.get(this,'props.setChatStatus');
      const showChat = _.get(this,'props.showChat',false);
      const unreadCount = _.get(channels, `${selectedChannel}.counter`) || 0;
      const currentUserRole = decryptRole();
      const handleCloseButton = ()=>{

          if(showChat&&selectedChannel){
              setSelectedChannel('');
          }
          setChatStatus(false);
      }
      // mark as unread

      const chatHeader = <div className='chatHistoryHeader' >
        <p>Chat History</p>
        {markAsUnreadRoles.includes(roleMap[currentUserRole]) &&  unreadCount === 0 &&  <a herf = "javascript:void(0);" onClick={()=> this.handleMarkAsUnread(selectedChannel)}>
                                    Mark as unread
                              </a>
        }
        <Icon type="close" onClick={()=> handleCloseButton() } style={{ float: 'right' }}/>
        </div>;
      return chatHeader
  };

  showShowCloseButton = ()=>{
      const { setSelectedChannel,selectedChannel,updateChannelInfo,verifyAndAddChannel,channels,isFullyLoaded,getChatInfo,patientId,patientIdToChannel,useChs, setChatStatus, showChat = false, renderChatIcon } = this.props;
      const pId = patientId && (_.split(atob(patientId), ':')[1] || atob(patientId));
      let channelName  = selectedChannel || _.get(patientIdToChannel,pId,'');
      
      const messages =  _.get(channels,`${channelName}.history`,[]);
      // const hasUnread = _.get(channels,`${channelName}.counter`,0) != 0;
      // let redDotFlag = JSON.parse(sessionStorage.getItem('notificationFreq')) === 'NOTIFY_ALWAYS';
      const shouldShowRedDot = checkShouldShowChatRedDot(channelName);

      const handleShowButton = async ()=>{
              if(selectedChannel.length==0) {
                  if (channelName.length == 0) {
                    this.setState({
                        loadingChatInfo:true
                    });
                    try {
                      channelName = await getChatInfo();
                      this.setState({
                          loadingChatInfo: false
                      });
                    } catch (err) {
                      console.error('Failed to load chat channel ', err);
                      this.setState({ loadingChatInfo: false, chatLoadingError: 'chat user info' });
                    }
                  }
                  let ptObj = RequestCache.get('nurse/currentPatient');
                  ptObj = helper.getUserMapObj(Object.assign({}, ptObj, { uuid: pId }));
                  verifyAndAddChannel(channelName, pId, ptObj);
                  setSelectedChannel(channelName);
                  let msgObj = helper.getLastMessageInfo(messages);
                  msgObj.isListening = false;
                  updateChannelInfo(channelName, msgObj);  // updateChannelInfo doesn't use unreadCountFromOffline, don't need to check tag
                  if(channels[channelName]){
                    const clickedChannel = channels[channelName];
                    helper.sendACKMessage(channelName, clickedChannel);
                  }

              }
              setChatStatus(true);
              Mixpanel.track('clicked','message_float_bubble','patient_profile',{ PATIENT_ID:patientId })

      };

      return renderChatIcon ?
                  (typeof renderChatIcon === 'function' ?
                    renderChatIcon({ 
                      chatFullyLoaded: isFullyLoaded, 
                      shouldShowRedDot, 
                      handleToggleChat: () => {
                        if(!selectedChannel) { 
                          // open
                          return handleShowButton();
                        }
                        // close
                        setSelectedChannel('');
                        setChatStatus(false);
                      }
                    }) : renderChatIcon)
              : !showChat && isFullyLoaded ? (
                  <p className={`${ shouldShowRedDot ? 'hasUnread showChatButton' : 'showChatButton'}`}>
                    <img src='/image/patient-chat-icon-bubble.svg'
                        onClick={ handleShowButton } 
                        style={{ cursor:'pointer' }}
                    />
                  </p>
                ) : null;
  }

  handleChannelChange = (channel) => {
    const { stopTimer, setSelectedChannel, selectedChannel } = this.props;
    if (!selectedChannel) {
      setSelectedChannel(channel)
    } else {
      stopTimer(this.getSelectedPatientId(), () => setSelectedChannel(channel));
    }
  }

  render() {
    const { props, sendMessage, fetchHistory,showShowCloseButton, retryLoading} = this;
    const { selectedChannel,timer,chatWithIndividual,showProfileStickyHeader,showChat,useChs, isFullyLoaded, renderChatIcon, channels, shouldRenderChatWindow = true, userInfo, keepIconOnShow,currentProgram } = props;
    const { isLoading,showStickyHeader,loadingChatInfo, chatLoadingError, userInfo: fetchedUserInfo } = this.state;
    const  chatStarted  = _.get(timer,'chatStarted',false);
    const selectedPatientId = this.getSelectedPatientId();
    let displayName = this.constructor.displayName;
    const displayNameWithoutPatientId = displayName;
    displayName = `${displayName}:${selectedPatientId}`;
    const viewingDetails ={
          component : displayName,
          path:window.location.pathname
    };
    const timerDiv = chatStarted ? <TimerContainer displayName={displayNameWithoutPatientId} category='CONVERSATION'  chatStarted ={chatStarted}
                                                   action='ENGAGE' viewingDetails={viewingDetails} selectedChannel={selectedChannel}
                                                   patientId={selectedPatientId}
    /> :'';
    const chsClassName = useChs ? 'isCHS' : 'isPubnub';
    const renderChatHistory = () => (
      <React.Fragment>
        {timerDiv}
        <ChatHistory userId={props.currentUserId} userMap={props.userMap}
          fetchHistory={fetchHistory} addToUserMap={props.addToUserMap}
          chatWithIndividual={chatWithIndividual}
          currentProgram={currentProgram}
          key={selectedChannel}
          loadingChatInfo={loadingChatInfo}
          isChatLoading={isLoading || loadingChatInfo}
          chatLoadingError={chatLoadingError}
          retryLoading={retryLoading}
        />
      </React.Fragment>
    )

    if(chatWithIndividual) {
        if(loadingChatInfo){
            return <div className={`message-container row rowUpdate chatWithIndividual
                                   ${ showProfileStickyHeader ? 'showProfileStickyHeader' :''}`}>
                      { renderChatHistory() }
                    </div>
        }

        const wrapperClassName = `message-container row rowUpdate chatWithIndividual
                            ${ showProfileStickyHeader ? 'showProfileStickyHeader' :''}
                            ${ showChat ? 'showChat' : 'closeChat' }
                          `;

        if(selectedChannel) {
            const renderChatWindow = () => (
              <div className={wrapperClassName}>
                { this.renderChatHeader() }
                { renderChatHistory() }
                {
                  !chatLoadingError &&
                  <ChatInput userId={props.currentUserId} sendMessage={sendMessage}
                              chatStarted={chatStarted} selectedChannel={selectedChannel}
                              displayName={displayName}
                              userInfo={fetchedUserInfo || userInfo}
                              onInteraction={this.handleInteraction}
                  />
                }
              </div>
            );

            if(shouldRenderChatWindow) {
              return renderChatIcon ?
                      (
                        <React.Fragment>
                          {showShowCloseButton()}
                          {renderChatWindow()}
                        </React.Fragment>
                      )
                      : renderChatWindow();
            } else if (keepIconOnShow) {
              return showShowCloseButton();
            } else {
              return null;
            }
        } else {
            //To do, initial login, open a patient profile with new message, no red dot show up on it before rendering in chat container
            return renderChatIcon ?
                  (
                    <React.Fragment>
                      {showShowCloseButton()}
                      <div className={wrapperClassName}></div> {/* manipulate sliding effect */}
                    </React.Fragment>
                  ) : (
                    <div className={wrapperClassName}>
                      { showShowCloseButton() }
                      {/* <EmptyChannelComponent/> */}
                    </div>
                  );
        }
    }
    const height = 'calc(100vh - 54px)'; // 54px of top navigation bar
    // if (selectedChannel) {
      return (
        <React.Fragment>
          {selectedChannel && <TimerClaimedContainer patientId={selectedPatientId} id={CHAT_CHANNEL_TIMER} />}
          <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height, width: '100%' }} className={'message-container'}>
            <div style={{ position: 'inherit', width: '50%', display: 'flex', flexDirection: 'row' }}>
              <div style={{ padding: 0, width: '50%' }}>
                {isFullyLoaded && <ChatChannelListContainer
                    setSelectedChannel={ this.handleChannelChange }
                    addToUserMap={ props.addToUserMap }
                    //fetchHistory={ fetchHistory }
                  />}
              </div>
              {
                selectedChannel &&
                <div style={{ padding: 0, width: '50%', display: 'flex', flexDirection: 'column' }}>
                  { renderChatHistory() }
                  <div style={{ borderRight: '1px #e0dfdf solid' }}>
                    {
                      !chatLoadingError &&
                      <ChatInput userId={ props.currentUserId } sendMessage={ sendMessage }
                                chatStarted ={ chatStarted } selectedChannel={selectedChannel}
                                displayName={displayName}
                                userInfo={fetchedUserInfo || userInfo}
                              />
                    }
                  </div>
                </div>
              }
            </div>
            {
              selectedChannel ?
              <div className='outtercontainer'
                onScroll={(e)=>this.handleContainerScroll(e)}
                ref={ref=>this.ref = ref}
              >
                <div className='profileBox row rowUpdate' >
                  {
                    selectedPatientId
                    ? ( 
                      <OMRONContextProvider
                        patientId={selectedPatientId}
                      >
                        <PatientMain 
                          patientId={selectedPatientId}
                          isInChat={true}
                          showStickyHeader = { showStickyHeader }
                          { ...props }
                          timerName={CHAT_CHANNEL_TIMER}
                        /> 
                      </OMRONContextProvider>
                    ): null}
                </div>
              </div>
              :
              <div style={{ width: '50%' }}>
                <ChatEmptyThirdPanel />
              </div>
            }
          </div>
        </React.Fragment>
      );
    // } else {
    //   return (
    //     <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: 'calc(100vh - 54px)' }}>
    //       <div style={{ padding: 0, width: 440 }}>
    //         <div style={{ overflow: useChs ? 'auto' : 'hidden', height: '100%' }}>
    //           <ChatChannelListContainer
    //             setSelectedChannel={ props.setSelectedChannel }
    //             addToUserMap={ props.addToUserMap }
    //             //fetchHistory={ fetchHistory }
    //           />
    //         </div>
    //       </div>
    //       <div className={'col-lg-9'}>
    //         <ChatEmptyThirdPanel />
    //       </div>
    //     </div>
    //   );
    // }
  }
}

const mapState = (state,ownProps) => {
  const { chat } = state;

  const selectedChannel = _.get(chat,'main.selectedChannel');
  const patientId = _.get(chat,`main.channels.${selectedChannel}.patientId`);
  const parsedPatientId = patientId ? btoa('accounts:'+patientId) : '';
  const displayName = `chat/containers/ChatContainer:${parsedPatientId}`;

  const showChat = !!selectedChannel;

  return {
    testInputMap: chat.main.testInputMap,
    currentUserId: chat.main.currentUserId,
    selectedChannel: chat.main.selectedChannel,
    channels: chat.main.channels,
    patientIdToChannel: chat.main.patientIdToChannel,
    userMap: chat.main.userMap,
    timer:state.timer.main[displayName],
    profileHeight:chat.main.profileHeight,
    isFullyLoaded:chat.main.isFullyLoaded,
    useChs: chat.main.useChs,
    showChat
  }
};


const mapDispatch = (dispatch) => {
  return {
    chatTextInputChange: (channel, text) => dispatch(actions.chatTextInputChange(channel,text)),
    addMessage: (channel, msg) => dispatch(actions.addMsg(channel, msg)),
    setCurrentUserId: userId => dispatch(actions.setCurrentUserId(userId)),
    addChatHistory: (...args) => dispatch(actions.addChatHistory(...args)),
    clearHistory: () => dispatch(actions.clearHistory()),
    addChannels: (channelsObj) => dispatch(actions.addChannels(channelsObj)),
    setSelectedChannel: channel=> dispatch(actions.setSelectedChannel(channel)),
    addToUserMap: (newUser,avatarError) => dispatch(actions.addToUserMap(newUser,avatarError)),
    updateChannelInfo : (channel,msgObj)=>dispatch(actions.updateChannelInfo(channel,msgObj)),
    verifyAndAddChannel:(channel, pId, ptObj)=>dispatch(actions.verifyAndAddChannel(channel, pId, ptObj)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setPatientDivHeight:(height)=>dispatch(actions.setPatientDivHeight(height)),
    changeMenuStats: (menuOpened) => dispatch(changeMenuStats(menuOpened)),
    interacted: (patientId, name, description) => dispatch(interacted(CHAT_CHANNEL_TIMER, patientId, name, description)),
    stopTimer: (patientId, cb) => dispatch(stopTimer(CHAT_CHANNEL_TIMER, patientId, 'done', cb)),
  }
}

ChatContainer.propTypes = {
  channels: PropTypes.object,
  currentUserId: PropTypes.string,
  addMessage: PropTypes.func,
  chatTextInputChange: PropTypes.func,
  testInputMap: PropTypes.object,
  setCurrentUserId: PropTypes.func,
  selectedChannel: PropTypes.string,
  addChatHistory: PropTypes.func,
  addChannels: PropTypes.func,
  openErrorModal: PropTypes.func
};

export default compose(
  connect(mapState, mapDispatch)
)(ChatContainer);
