import { browserHistory } from 'react-router';

export const goBack = (props) => {
  const backPath = props.location.query.backPath
  if(backPath) {
    browserHistory.push(`/${backPath}`)
  }
  else {
    browserHistory.push('/assessments_surveys')
  }
}
