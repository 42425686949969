import { MEDICINE_COUNT_MAX } from 'libModule/constants';

export  default function getVariables(ownProps){
    const fieldMap = {
        startDate:'START_DATE',
        endDate:'END_DATE'
    }

    const patientId = ownProps.patientId;
    const { filter={},sort={} } = ownProps;
    const  variables = {
        page: _.get(ownProps,'page.current',1),
        count: MEDICINE_COUNT_MAX,
    }

    variables.memberId = patientId;
    variables.active = true;
    variables.types = filter.type||[];
    variables.sort = {
        field:fieldMap[sort.field],
        direction:sort.direction
    };
    return variables;
}
