export default  {
	startEnrollmentNote: 'To start the enrollment, please confirm the following information with patient.',
	provider: 'Provider',
	patientPhoneNumber: 'Patient phone number',
	insurance: 'Insurance',
	cantFindInsuranceCompany: 'Can\'t find the insurance company?',
	icdCodeTable: {
		id: 'ID',
		insurance: 'Insurance',
		primary: 'Primary',
		secondary: 'Secondary',
	},
	hldOnlyNote: '*To enroll this patient, you need to select another condition.',
	hldOnlyOverlayBold: 'This patient is currently not eligible to enroll, based on the condition you selected above.',
	hldOnlyOverlaySubText: 'Please add another condition in order to enroll this patient',
  noIcdCodeOverlayBold: 'This patient is currently not eligible to enroll, because you haven\'t selected condition(s) above.',
  noIcdCodeOverlaySubText: 'Using the checkboxes above, please mark the conditions you want to track. ',
	patientConditionHeader:'Patient Conditions',
	patientConditionSubheader:	'What conditions do you want us to help monitor (select all that apply, or enter ICD codes to be more precise)',
	patientConditionIcdCodes: 'enter ICD codes',
	hasSmartPhoneHeader:'Does the patient have a smartphone?',
	hasSmartPhoneSubheader:'Patients who don\'t have a smartphone may not be eligible for Glucose management program.',
	hasOtherCCMProviderHeader:'Is this patient enrolled with another CCM provider (check the EMR to be sure)?',
	hasOtherCCMProviderSubheader:'Patients who are already enrolled with another CCM vendor contracted with your organization are only eligible to enroll in RPM with UnifiedCare.',
	ccmDisabledReason: 'You need to select at least 2 patient conditions to enroll patient to CCM program.',
	addBillableConditionsSubHeader: 'Search the health conditions below to add it to the list.',
	addBillableConditionRelatedToVitals: 'How billable conditions related to program and vitals?',
	addBillableConditionsSubHeader2: 'What conditions do you want us to help monitor (Add the ICD codes or quick select conditions that apply)',
	addBillableConditionQuickBox: 'quick select conditions',
	vitalsHeader:'Based on what you’ve selected above, we recommend the following vitals and monitoring programs.',
	vitalsSubheader:'Please review and adjust as needed.',
	recommendVitalsHeader:'Recommended Vitals',
	eligibleProgramsHeader:'Eligible programs',
	excludedOverlayText: 'This patient was previously labeled as excluded. Reason: [show backend reason]. Are you sure you still want to continue?',
	excludedOverlayReason: '[show backend reason]',
  patientConsent: {
    resubmitAppText: 'Resend consent form to the App',
    submitAppText: 'Send consent form to the App',
    submitAppNote: 'Clicking this button will automatically send the patient a text message with a link to download the UnifiedCare app. Please assist the patient to download and install the app.',
    invalidTemplate: 'Template might be still in-progress or corrupted. Please wait for a moment and try again',
    appLink: 'Send App download link',
    appLinkSent: 'App download link has been sent!',
    appLinkNotSent: 'Failed to send app download link',
    appLinkNoPhone: 'Failed to send app download link. No Mobile phone number found'
  },
	referredByDoctor: '[name] has referred this patient to Unified Care program.',
	referredByDoctorSubText: 'Please confirm the following information with patient before moving to the next step.',
	joinProgramQuestion: 'Would this patient like to join the program?',
	introduceUnifiedCare: 'Introduce Unified Care Program',
	whyChooseUnifiedCareHeader: 'Why choose iHealth Unified Care?',
	whyChooseUnifiedCareDescription: 'Unified Care provides an easy and interactive way to reform your ' +
		'lifestyle. A Care Team You Can Trust, We provide quality 1:1 coaching and remote monitoring ' +
		'services. Better Health with Personalized Care, We set up an easy-to-follow care plan for you ' + 
		'to stay on top of your health lifestyle Support Anytime, Anywhere, Chat with us at anytime about ' +
		'your condition or your lifestyle. Smart Products for Close Monitoring, Self-manage your ' +
		'health with our user-friendly smart devices and mobile-app',
	deviceAccessabilityTitle: 'Device accessability',
	deviceAccessabilityDescription: 'Does the patient have a smartphone/tablet that can install Unified Care App to monitor vitals?',
	reasonFormProgramExclusion: 'Please select the reason for exclusion',
	notWillingToJoinNote: '* This patient is not eligible to enroll since patient is not willing to join the program. Click “complete” to exit the flow, and we will mark this patient as “excluded”.',
	noSmartphoneEligibility: 'This patient is not eligible to enroll, since patient needs a smartphone to remotely monitor selected vitals.',
	noSmartphoneEligibilitySubText: 'Please double check with patient or click the button below to exit the flow.',
	noSmartPhoneEligibilityButton: 'Patient is not eligible to enroll, exit the flow',
	noSmartPhoneBPAndRpm: '*Since this patient does not have smartphone, the patient will only be eligible to enroll in the blood pressure and RPM program.',
	loanerPhoneText: 'Got it! We\'ll provide patient a loaner phone (a locked phone only support UC App) to support daily vitals monitoring. ' 
		+ 'Please carefully evaluate the patient\'s tech level and see if the patient feels comfortable to use smartphone.',
	patientSmartphoneTitle: 'Does the patient have a smartphone?',
	patientSmartphoneSubText: 'Patients who don\'t have a smartphone may not be eligible for certain programs.',
	isReferredByDoctor: 'Is this patient referred by doctor?',
	notEligibleToEnroll: 'This patient is currently not eligible to enroll.', 
	notEligibleToEnrollNoDevice: 'Based on what you’ve selected above, and the device provisions for this clinic, it looks like we do not have an alternative device for this patient to use for monitoring.',
	notEligibleToEnrollNoDeviceSuggestion: 'If the patient has a tablet, iPad, or other smart device, you may go back and adjust your selection.',
	notEligibleToEnrollNoCondition: 'It looks like we do not currently support enrolling this patient based on the conditions you’ve selected in the previous step.',
	notEligibleToEnrollNoConditionSuggestion: 'If the patient has other chronic conditions that may be monitored, like Diabetes, pre-diabetes, elevated blood pressure, or weight conditions, you may go back and add them.',
	notEligibleToEnrollNoSmartphone: 'Sorry, this patient is not eligible to be enrolled  because they have a condition which requires a smartphone to be enrolled and monitored.',
  shippingAddress: {
    streetName: 'Street',
    unit: 'APT',
    city: 'City',
    state: 'State',
    postCode: 'Zip code'
  },
  confirmDeviceLabel: {
    SHIP_TO_PATIENT: {
      main: 'Confirm patient’s shipping address.',
      sub: ' '
    },
    PICK_UP: {
      main: 'The rest of the device we’ll send directly to patient’s home address.',
      sub: 'Please confirm patient’s shipping address below.'
    }
  },
  giveoutDevicesLabel: {
    SHIP_TO_PATIENT: 'These are the devices that will be sent out. If this is not correct, please go back and readjust',
    PICK_UP: 'Check on the devices that will be giving out today'
  },
	ccmRpmSuccess: 'Awesome! You’ve successfully referred this patient.',
	qualifiesMntReferral: 'It looks like this patient also qualifies for our Medical Nutrition Therapy (MNT) program for intensive nutrition consultations. Do you want to refer this patient now?',
	mntConditionsToMonitor: 'What conditions do you want us to help monitor?',
	mntConditionsToMonitorSubText: 'Select all that apply, or enter ICD codes to be more precise.',
	mntPatientDescription: 'Which of the following best describe this patient?',
  clinicGoal: {
    errorWithNoGoal: 'Please finish entering clinical goals in order to completely enroll this patient',
    discussedWithPatient: {
      title: 'Here is one more thing to complete the CCM/RPM referral',
      checkboxText: '”I acknowledge that I have discussed the goals of referral and enrollment with this patient, and the patient verbally agrees to enroll.“',
      withCCM: 'Click the checkbox below if all the information you entered has been discussed with the patient during an office visit today. If the checkbox was checked and patient is enrolled later this month, we will log a G0506 add-on code to your monthly billing report.',
      withoutCCM: 'Click the checkbox below if all the information you entered has been discussed with the patient during an office visit today.'
    }
  },
	patientSkillLevel: 'Please evaluate the patient\'s tech level and see if the patient is able to use smartphone.',
	anotherCCMAlert: '*Patient is not eligible to enroll CCM, Since this patient has another CCM provider.',
	phoneCallScheduleButton: 'Schedule a phone call with Unified Care team',
	phoneCallScheduledText: 'Phone call with Unified Care team is scheduled at:',
	phoneCallScheduleTitle: 'Schedule a phone call with Unified Care',
	phoneCallScheduleDescription: 'The patient will receive a confirmation text message after the phone call visit is scheduled.',
}
