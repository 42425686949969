import React from 'react';
import { graphql, compose } from 'react-apollo';
import PatientsTable from '../../patientList/main/containers/PatientsTable';
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import { modalAction } from 'modulesAll/common/actions'
import { PATIENT_TYPE  } from '../../patientList/main/constants/MainConstants';
import MNTReferralFormComponent from '../component/MNTReferralFormComponent';
import MNTReferralListFormContainer from '../container/MNTReferralListFormContainer';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import getVisit from 'graphqlModule/singleVisit';
import Client from 'libModule/gqlClient';

import { Modal } from 'antd';
const MNTReferralPatientListContainer = class extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			showReferralModal: false,
			selectedUser: null,
			showEditVisitModal: false,
		}
	}

	setShowReferralModal =(showReferralModal,selectedUser,userList,index,fetchVar)=>{
		this.setState({
			showReferralModal, selectedUser,userList,index,fetchVar
		})
	}

	setShowEditVisitModal = async (visitId) => {
		let visit;
		if (visitId) {
			visit = await Client.query({
				query: getVisit,
				variables: { id: visitId },
				fetchPolicy: 'network-only',
			});
		}
		this.setState({ showEditVisitModal: _.get(visit, 'data.getVisit', false) });
	}

	setUserList = (userList,fetchVar)=>{
		this.setState({
			userList,fetchVar
		})
	}

	setSelectedUser = ({index,selectedUser})=>{
		this.setState({
			selectedUser,
			index
		})
	}

	handleModalClose = () => {
		this.refetchPatientList();
		this.setShowReferralModal(false);
	}

	showReferralModal = ()=>{
		const { showReferralModal,selectedUser,userList,index,fetchVar } = this.state;
		const { handleModalClose,setSelectedUser,setUserList } = this;
		return showReferralModal && <Modal visible={showReferralModal} width={'fit-content'} onCancel={handleModalClose} footer={false}>
					<MNTReferralFormComponent selectedUser={selectedUser} setUserList={setUserList} fetchVar={fetchVar}
					                          {...this.props}
											  key={selectedUser.id}
					                          selectedUser={selectedUser} userList={userList} index={index} setSelectedUser={setSelectedUser}/>
			   </Modal>
	}

	showEditVisitModal = () => {
		const { showEditVisitModal } = this.state;
		if (!showEditVisitModal) return null;
		return (
			<EditVisitInDashboardContainer refetch={() => {}} visitInfo={showEditVisitModal} onCancel={() => this.setShowEditVisitModal(false)}/>
		);
	}

	render() {
		return  <React.Fragment>
				{ this.showReferralModal() }
				{ this.showEditVisitModal() }
				<PatientsTable isMNTReferral={true}
													setRefetchPatientList={(refetch) => { this.refetchPatientList = refetch; }} // should not set this to state because it will trigger a rerender (infinite loop)
		                      patientType={'e'}
		                      {...this.props} // props include reduxActions
		                      {...this.props.globalTableState}
		                      setShowReferralModal={this.setShowReferralModal}
													setShowEditVisitModal={this.setShowEditVisitModal}
			   />
		</React.Fragment>
	}
}
const mapStateToProps = state => ({
	globalTableState: state.globalTables.mntReferral_patient_list,
});

const mapDispatchToProps = dispatch => ({
	// table actions, and initState if it doesn't exist
	...createTableAction('mntReferral_patient_list',{
		sortFieldMap: { age: 'BIRTHDAY', fullName:'LAST_NAME',timeSpent:'TIME_SPENT', enrolledDate:'ENROLLMENT_DATE',
						registeredAt: 'REGISTERED', monthlyMeasurementDaysRange: 'MONTHLY_MEASUREMENT_DAYS'
					  },
		shouldUpdateHistory: true,
		initState: {
			selectedTab: _.values(PATIENT_TYPE)[0],
		}
	}),
	showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user)),
	openErrorModal:(error)=>dispatch(openErrorModal(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MNTReferralPatientListContainer);