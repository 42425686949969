import API from '../../MAPatient/helper'; 
import { editUserMutation, editClinicGoalsMutation } from '../API/mutation';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { doesOrgHaveMNT } from '../../../lib/utils';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import { getReferredPrograms } from './index';
import { editConditionsList } from '../../visitNewWorkFlow/API';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import BillableConditionHelper from '../../careplanNew/component/HealthCondition/BillableConditionHelper';


const getCurrentUserInfo = () => {
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const id = _.get(currentUser, 'id');
  const fullName = _.get(currentUser, 'profile.fullName');
  const teams = _.get(currentUser, 'team');
  return { fullName, id, teams };
};

const getCallInfo = (currentUserInfo, phoneNumbers) => {
  const lastCall = JSON.parse(sessionStorage.getItem('call-center-last-call') || '{}');
  const info = {
    caller: currentUserInfo.fullName,
    callerId: currentUserInfo.id,
  };
  if (Array.isArray(phoneNumbers) && phoneNumbers.includes(lastCall.phoneNumber)) {
    info.callNumber = lastCall.phoneNumber;
    info.callDate = lastCall.startTime;
  }
  return info;
};

const getCareTeam = (primaryDoctorId, teams = []) => {
  // automatically select care team
  if(!primaryDoctorId) {
    console.warn('warn getCareTeam: invalid primaryDoctorId');
    return {};
  }

  let teamsHaveDoctor = [];
  for(let team of teams) {
    const members = _.get(team, 'members') || [];
    const doctor = _.find(members, member => member.user.id === primaryDoctorId);
    if(!doctor)
      continue;
    if(doctor.primary) {
      // team where doctor with primary=true
      teamsHaveDoctor = [team];
      break;
    } else {
      // team where doctor is in, regardless of primary flag
      teamsHaveDoctor.push(team);
    }
  };

  const team = teamsHaveDoctor[(Math.random() * teamsHaveDoctor.length) << 0];

  return team;
};

const sendAppDownloadLink = async (memberId, memberPhones = []) => {
  const mobilePhone = _.find(memberPhones, { type: 'MOBILE' });
  
  if(_.isEmpty(mobilePhone)) {
    console.warn('warn sendAppDownloadLink: patient has no mobile phone');
    return null;
  }

  try {
    await API.sendInvitationLinkMutation({memberId}, null, false);
    return true;
  } catch (err) {
    console.error('error sendAppDownloadLink', err);
    return false;
  }
};

// to sync program categories
// to sync ICD 10 codes (aka. health conditions and billable health conditions)
const syncAccounts = (user, patientReferral, enrolledProgram, consentInfo) => {
  const { diagnosisSource, healthConditions, billableHealthConditions } = patientReferral || {};

  let added = 0;
  const userVariables = {
    id: _.get(user, 'id'),
    memberProfile: {
      birthday: _.get(user, 'profile.birthday')
    }
  };
  const SYNCABLE_PROGRAMS = ['CCM', 'RPM','VALUE_BASED'];
  const referredPrograms = getReferredPrograms(patientReferral);

  if(checkIsInRole(['RD', 'CA']) && doesOrgHaveMNT()) { 
    // only sync IFF role is RD/CA AND org has MNT selected
    SYNCABLE_PROGRAMS.push('MNT');
  }
  const programsToEnroll = _.intersection(SYNCABLE_PROGRAMS, referredPrograms);

  if(programsToEnroll.length) {
    const programCategories = [],
          now = new Date();

    _.map(programsToEnroll, pName => {
      const program = {
        name: pName,
        enrolled: null
      };

      if(programsToEnroll.indexOf(pName) > - 1) {
        program.enrolled = true;
        program.enrolledDate = now.getTime();
      }

      programCategories.push(program);
    });

    userVariables.memberProfile.programCategories = _.uniqBy(programCategories, 'name');
    added++;
  }

  if (diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE) {
    // override current
    if (Array.isArray(healthConditions)) {
      userVariables.memberProfile.healthConditions = healthConditions; 
    }
    const bhc = _.map(billableHealthConditions, c => _.omit(c, '__typename'));
    userVariables.memberProfile.billableHealthConditions = bhc;
    added++;
  }

  const billableConditionHelper = new BillableConditionHelper({
    patient: user,
    enrolledProgram,
    patientReferral,
    consentInfo
  });

  const codesWithAutoMark = billableConditionHelper.handleAutoMarkBillableDuringEnrollment();
  if (codesWithAutoMark && codesWithAutoMark.length) {
    userVariables.memberProfile.billableHealthConditions = codesWithAutoMark; // override current
    const CCMRPM = billableConditionHelper.getValidPrograms(codesWithAutoMark, true);
    if (CCMRPM.hasProgramChange) {
      const curr = userVariables.memberProfile.programCategories || [];
      const newPrograms = [...CCMRPM.programCategories, ...curr];
      userVariables.memberProfile.programCategories = _.uniqBy(newPrograms, 'name');
    }
    added++;
  }

  if(added > 0)
    return editUserMutation(userVariables).catch(console.error);
  return;
};

// with remoteEnrollment
const syncConditionsList = (user, patientReferral) => {
  const { conditionsToMonnitor } = patientReferral || {};
  if(!user || !Array.isArray(conditionsToMonnitor) || !conditionsToMonnitor.length)
    return;

  const conditionsList = dataHelper.handleConditionsList(conditionsToMonnitor);

  return remoteEnrollmentApis.editConditionList({
    memberId: user.id,
    conditionsList
  }).catch(console.error);
};

const syncClinicGoals = (enrolledProgramId, clinicGoals) => {
  if(!enrolledProgramId || !clinicGoals)
    return console.warn('warn syncClinicGoals: invalid args');

  const variables = {
    id: enrolledProgramId,
    clinicGoals: _.map(clinicGoals, g => _.omit(g, '__typename'))
  };
  return editClinicGoalsMutation(variables).catch(console.error);
};

// valid when referredAt is within Calendar month
const validateReferredDate = (referredAt) => {
  if(!referredAt)
    return false;
  
  const today = new Date();
  return new Date(referredAt).getMonth() === today.getMonth();
};

export default {
  getCurrentUserInfo,
  getCallInfo,
  getCareTeam,
  sendAppDownloadLink,
  syncAccounts,
  syncConditionsList,
  syncClinicGoals,
  validateReferredDate
};