import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHLoading } from 'ihcomponent'
import { graphql } from 'react-apollo'
import broadcastGQL from 'graphqlModule/broadcast'
import EditContainer from './EditContainer'
import get from 'lodash/get'
import { connect } from 'react-redux'

class Container extends Component {

  render() {
    const { data } = this.props
    const broadcastData = data.broadcastById
    if(!broadcastData){
      return <IHLoading />
    }

    return <EditContainer broadcastData={broadcastData} {...this.props} />
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.broadcastId')
  return {
    ...ownProps,
    id
  }
}

const withGql = graphql(broadcastGQL, {
  options: (ownProps)=>{
    const id = ownProps.id
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only',
    }
  }
})

const ContainerWithData = withGql(Container)

Container.propTypes = {
  data: PropTypes.object,
}

export default connect(
  mapState
)(ContainerWithData)
