import gql from 'graphql-tag';
import React, { Component } from 'react'

// ["NTg2NmRiOTg5MWI2NzQ1MjY4ZDhlMzFj"]


export default gql `query measureTrendsForProgram($adminProgramIds:[EID!]!,$currentPeriod:ProgramTrendPeriod!) {
  measureTrendsForProgram(
    filters: {
      adminProgramIds: $adminProgramIds,
      timePeriod: $currentPeriod
    }
  ) {
    groupBy
    data {
      group
      conditionCounts {
        normal
        elevated
        hyperStage1
        hyperStage2
        hyperCrisis
      }
      total
    }
  }
}`;
