import { React, IHSearchTable, _ } from 'ihcomponent';
import { sortStringAlphabetical } from 'libModule/utils';
import { renderStatus, renderType, renderAction } from '../../utils';
import { formatSchedule } from 'modulesAll/utils/helpers/task'
import I18N from 'modulesAll/I18N';

export default class extends React.Component {
  static displayName = 'patientView/myAssessmentAndSurvey/main/components/ListComponent'

  componentWillUnmount() {
    this.props.onLoadParams(null, true);
  }
  getTableProps() {
    const { ListReducer, onTableSearch, onTableChange, onSearchEnter, data, sort, page, programFilters, search = '' } = this.props;
    const { loading = true, resultList } = data;

    return {
      title: `${I18N.get('keywords.Assessments')} & Surveys (${_.get(resultList, 'data.length', 0)})`,
      //inputPlaceholder: 'Search by Name',
      //initSearchValue: '',
      rowKey: 'id',
      onChange: onTableChange,
      //onSearch: onTableSearch,
      dataSource: _.get(resultList, 'data', []),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: 10,
        total: _.get(resultList, 'pageInfo.total', 0),
      },
      loading,
      columns: [
        {
          title: 'Status',
          key: 'taskStatus',
          dataIndex: 'taskStatus',
          render: renderStatus,
          filters: [
            { value: 'COMPLETED', text: 'COMPLETED' },
            { value: 'INCOMPLETE', text: 'INCOMPLETE' },
            { value: 'MISSED', text: 'MISSED' }
          ],
          onFilter: (a, doc) => a === doc.taskStatus,
        },
        {
          title: 'Type',
          key: 'type',
          dataIndex: 'type',
          render: renderType,
          filters: [
            { value: 'ASSESSMENT', text: I18N.get('keywords.Assessments') },
            { value: 'SURVEY', text: 'Survey' },
          ],
          onFilter: (a, doc) => a === doc.type,
        },
        {
          title: 'Name',
          key: 'name',
          dataIndex: 'name',
          render: (name, row) => _.get(row, 'assessment.name') || _.get(row, 'assessmentResult.name'),
        },
        {
          title: 'Schedule',
          key: 'schedule',
          dataIndex: 'schedule',
          render: (a, row) => formatSchedule(row),
        },
        {
          title: I18N.get('keywords.Program'),
          key: 'enrolledProgram.name',
          dataIndex: 'enrolledProgram.name',
          filters: programFilters || [],
          onFilter: (a, doc) => a === doc.program.id,
        },
        {
          title: 'Actions',
          key: 'action',
          dataIndex: 'taskStatus',
          render: (status, record) => renderAction(status, record),
        },

      ],
    };
  }
  render() {
    return (
      <div className="vsm-main-page patient-assessment-list">
        <IHSearchTable {...this.getTableProps()} />
      </div>
    );
  }

}
