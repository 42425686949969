import React from 'react';
import { Table,Radio,Icon } from 'antd';
import moment from "moment/moment";
import { convertBG_mmolTomgdl } from '../../../lib/utils/convertUnits';
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import SingleMeasureRenderComponent from "./SingleMeasureRenderComponent";
import MultiMeasureComponent from './MultiMeasureComponent';
import PairedMeasuresComponent from './PairedMeasuresComponment'
import '../style/index.scss';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import $ from 'jquery';
import { IHLoading } from 'ihcomponent';
import helpers from '../helpers/index';
const { Column, ColumnGroup } = Table;

// const { Button,Group } = Radio;
const readingSeverityMap ={
    "RISK":'riskReading',
    "NORMAL":'normalReading',
    "CRITICAL":'criticalReading'
}
const valueToSeverity = (readingValue)=>{

}
const LogBookListComponent = class extends React.Component {
   constructor(){
       super();
   }
   generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }
        return timeSpots.reverse();
    }

    isPairedMeasure(resultList,type){
        const measures = _.reject(resultList[type],(i)=>i.severity==='MISSED');
        const beforeMeal = _.filter(measures,(d)=>d.blood_glucose&&d.beforeMeal&&d.severity!='MISSED');
        const afterMeal = _.filter(measures,(d)=>d.blood_glucose&&!d.beforeMeal&&d.severity!='MISSED');
        let res=[];
        if(measures&&measures.length==2&&beforeMeal.length==1&&afterMeal.length==1){
            res[0] = beforeMeal[0];
            res[1] = afterMeal[0];
            return res;
        }
        return false;
    }
    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in</span>
            <span style={{fontWeight:900}}>&nbsp;mg/dL</span>
        </Icon>
    }
    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const parsedData =  _.groupBy(data,(d)=>{
            return moment(d.date).format('MM/DD/YYYY')
            })

        let groupedData = _.map(timeSpots,(key)=>{
                return {
                    [key]:parsedData[key]||[{date:new Date(key)}]
                }
        })
        return groupedData;
    }

    // renderTimeRange(){
    //     const { fromDate,toDate } = this.props;
    //     const fromDateString = moment(fromDate).format('MM/DD/YYYY');
    //     const toDateString = toDate==undefined ? moment().subtract(6,'day').format('MM/DD/YYYY') : moment(toDate).format('MM/DD/YYYY');
    //     return <div style={{marginLeft:5,marginRight:5}}>
    //               <p className='timeRange'>{fromDateString} - {toDateString}</p>
    //            </div>
    // }

    filterTypeAndBefore(input,type){
        const typeAndBeforeMap = {
            'beforeBreakfast':{
                type:'BREAKFAST',
                before:true
            },
            'afterBreakfast':{
                type:'BREAKFAST',
                before:false
            },
            'beforeLunch':{
                type:'LUNCH',
                before:true
            },
            'afterLunch':{
                type:'LUNCH',
                before:false
            },
            'beforeDinner':{
                type:'DINNER',
                before:true
            },
            'afterDinner':{
                type:'DINNER',
                before:false
            },
            'bedtime':{
                type:'BEDTIME',
                before:false
            },
            'overnight':{
                type:'OVERNIGHT',
                before:true
            }
        }
        const obj = typeAndBeforeMap[type];
        return _.filter(input[obj.type],(d)=>{
            if(d.mealType!='BEDTIME') {
                return (d.severity != 'MISSED') && (d.beforeMeal == obj.before)
            }
            else{
                return d.severity != 'MISSED';
            }

        });
    }

    handleData(filteredList){
        const allMissed = filteredList.length>0 && !_.find(filteredList,(i)=>(i.severity!='MISSED'));
        let readingCount = !allMissed ? _.reject(filteredList,(i)=>i.severity=='MISSED').length :'';
        const readingObj = filteredList.length>0&&!allMissed ? _.find(filteredList,(i)=>(i.severity!='MISSED')) : {};
        const readingValue = readingObj.blood_glucose ? readingObj.blood_glucose.value : '';
        const readingDate = readingObj.date;
        const hasUserNote = (_.filter(filteredList,(i)=>_.get(i,'measure.user_notes'))).length!=0;
        let className = allMissed ? 'missedReading' : (readingCount>1 ? 'logbookCellWithBadge':readingCount>0 ? 'logbookCell':'') +" "+ readingSeverityMap[helpers.getBGReadingSeverity(readingObj.beforeMeal,convertBG_mmolTomgdl(readingValue))];
            // (readingSeverityMap[readingObj.severity]||'');
        const mgdlValue = allMissed ? 'Missed' : convertBG_mmolTomgdl(readingValue)!=0 ? convertBG_mmolTomgdl(readingValue):'';
        const unit =  mgdlValue>0 ? ' mg/dL':'';
        readingCount = readingCount>1 ? readingCount : null;
        const hasUserNoteClassName = hasUserNote  ? (` hasUserNote ${mgdlValue<=99 ? `twoDigit`: `threeDigit`}`):'';
        className =className.concat(hasUserNoteClassName);
        return {
            readingCount,
            mgdlValue,
            unit,
            className,
            readingDate,
            allMissed,
            hasUserNote
        }
    }

    // renderNextPreFooter(){
    //     return <div>
    //             <ul className='ant-pagination logBookPreNext' >
    //              <li title="Previous Page" className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.props.changePre()}>
    //                  <a className="ant-pagination-item-link"></a>
    //              </li>
    //                 { this.renderTimeRange() }
    //              <li title="Next Page" className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.props.changeNext()}>
    //                  <a className="ant-pagination-item-link"></a>
    //              </li>
    //             </ul>
    //            </div>
    // }

    // renderRadioButton(){
    //     const fromDate = moment(_.get(this,'props.fromDate',moment()));
    //     const toDate = moment(_.get(this,'props.toDate',moment()));
    //     const diffInWeek = toDate.diff(fromDate,'week');
    //     const oneWeekChecked = (diffInWeek===0) ;
    //     const twoWeekChecked = (diffInWeek===1) ;
    //     const oneMonthChecked = (diffInWeek===4) ;
    //     return <Radio.Group className='it-dd logBookButtonGroup' style={{marginLeft:20}}>
    //         <Radio.Button value="7"   onClick={()=>this.props.setTimeInterval('w',1)} checked = {oneWeekChecked}>1 Week</Radio.Button>
    //         <Radio.Button value="14"  onClick={()=>this.props.setTimeInterval('w',2)} checked = {twoWeekChecked} >2 Weeks</Radio.Button>
    //         <Radio.Button value="30"  onClick={()=>this.props.setTimeInterval('M',1)} checked = {oneMonthChecked}>Month</Radio.Button>
    //     </Radio.Group>
    // }

    // renderVitalType(){
    //     return <h4 style={{marginBottom:0,flex:1}}>Blood Glucose</h4>
    // }

    // renderTableHeader(){
    //     return <div style=
    //                     {{
    //                         height: '46px',
    //                         padding: '0 20px',
    //                         position: 'relative',
    //                         display:'flex',
    //                         alignItems:'flex-end',

    //                     }}
    //                     className='ih-dd logBookHeaderContainer'>
    //              { this.renderVitalType() }
    //              { this.renderNextPreFooter() }
    //              { this.renderRadioButton() }
    //            </div>
    // }

    renderTable(){
        const { loading }= this.props;
        const dataToRender = this.groupData();
        const parsedData = _.map((dataToRender),(d)=>{
           const valueArray = Object.values(d)[0];
            return {
                data:_.groupBy(valueArray,(d)=>d.mealType),
                date:valueArray[0].date
            }
        })

      return  <div className='IH-SearchTable' style={{border:'unset'}}>
          {/* {this.renderTableHeader()} */}
        <Table dataSource={parsedData} rowKey={(data,index)=>data.date+index}
               className='logBookTable' bordered
               scroll={{ y: 305 }}
               style={{ borderTop:'solid 1px #e9e9e9',margin:20,marginBottom:0}}
               pagination={false}
               loading={{spinning: loading, indicator: <IHLoading/>}}
        >
                  <Column
                      title="Date"
                      dataIndex="date"
                      // key='date'
                      sorter={(a,b)=>a.date-b.date}
                      render={(data)=>moment(data).format('MM/DD/YYYY')}
                      width={'12%'}
                  />
            <Column
                title=""
                dataIndex="data.OVERNIGHT"
                key='overnight'
                width='11%'
                className='overnight logBookIcon'
                render={(data, index) => {
                    const { readingCount,className,mgdlValue,unit } = this.handleData(data||[]);
                    return {
                        props: {
                            className:'number',
                            data:readingCount

                        },
                        children: <div data={readingCount} className={className}>
                                    <div>
                                        <span>{ mgdlValue }</span>
                                    </div>
                                </div>
                    }
                }
                }

                onCell={(data) => {
                    return {
                        onClick: (e) => {
                            const eleIsMissedReading = $(e.target).hasClass('missedReading');
                            const ele = e.target;
                            const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                            const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                            if(isMissedReading){

                                return;
                            }
                            const measures = this.filterTypeAndBefore(data.data,'overnight');

                            if (measures.length == 0) return;
                            if (measures.length == 1) {
                                this.props.openModal(<SingleMeasureRenderComponent
                                    data={measures}
                                    title='Overnight Measurement'
                                    handleData={this.handleData}
                                    beforeMeal={true}
                                    patientId={this.props.patientId}
                                    date={data.date}
                                    showSingleReading={true}
                                />,{
                                    className:'singleMeasureModal'
                                })
                            }
                            if (measures.length > 1) {
                                this.props.openModal(<MultiMeasureComponent
                                        data={measures}
                                        title='Overnight Measurement'
                                        handleData={this.handleData}
                                        beforeMeal={true}
                                        patientId={this.props.patientId}
                                        date={data.date}
                                    />,{
                                        className:'multiMeasureModal'
                                    }
                                )
                            }
                        }
                    }
                }
                }
            />
                  <ColumnGroup title="Breakfast">
                      <Column
                          title="B"
                          key='beforeBreakfast'
                          width='11%'
                          dataIndex="data.BREAKFAST"
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);
                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount
                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                            }
                          }

                          onCell={(data)=> {
                              return{
                                onClick:(e)=>{
                                  const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                  const ele = e.target;
                                  const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                  const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                  if(isMissedReading){
                                      return;
                                  }
                                  const pairedMeasures = this.isPairedMeasure(data.data, 'BREAKFAST');
                                  if (pairedMeasures) {
                                      this.props.openModal(<PairedMeasuresComponent
                                          data={pairedMeasures}
                                          title='Paired Breakfast Measurements'
                                          handleData={this.handleData}
                                          patientId={this.props.patientId}
                                          date={data.date}
                                      />,{
                                          className:'pairedMeasureModal'
                                      })
                                      return;
                                  }
                                  const measures = this.filterTypeAndBefore(data.data, 'beforeBreakfast');
                                  if (measures.length == 0) return;
                                  if (measures.length == 1) {
                                      this.props.openModal(<SingleMeasureRenderComponent
                                          data={measures}
                                          title='Before Breakfast Measurement'
                                          handleData={this.handleData}
                                          beforeMeal={true}
                                          patientId={this.props.patientId}
                                          date={data.date}
                                          showSingleReading={true}
                                      />,{
                                          className:'singleMeasureModal'
                                      })
                                  }

                                  if (measures.length > 1) {
                                      this.props.openModal(<MultiMeasureComponent
                                              data={measures}
                                              title='Before Breakfast Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={true}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              className='multiMeasureModal'

                                          />,{
                                              className:'multiMeasureModal'
                                          }
                                      )
                                  }

                              }
                          }
                          }
                          }
                      />
                      <Column
                          title="A"
                          dataIndex="data.BREAKFAST"
                          key='afterBreakfast'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);
                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                             }
                          }
                          onCell={(data) => {
                              return {
                                  onClick: (e) => {
                                      const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                      const ele = e.target;
                                      const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                      const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                      if(isMissedReading){
                                          return;
                                      }
                                      const pairedMeasures = this.isPairedMeasure(data.data, 'BREAKFAST');
                                      if (pairedMeasures) {
                                          this.props.openModal(<PairedMeasuresComponent
                                              data={pairedMeasures}
                                              title='Paired Breakfast Measurements'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                          />,{
                                              className:'pairedMeasureModal'
                                          })
                                          return;
                                      }

                                      const measures = this.filterTypeAndBefore(data.data, 'afterBreakfast');
                                      if (measures.length == 0) return;
                                      if (measures.length == 1) {
                                          this.props.openModal(<SingleMeasureRenderComponent
                                              data={measures}
                                              title='After Breakfast Measurement'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              showSingleReading={true}
                                              beforeMeal={false}
                                          />,{
                                              className:'singleMeasureModal'
                                          })
                                      }
                                      if (measures.length > 1) {
                                          this.props.openModal(<MultiMeasureComponent
                                                  data={measures}
                                                  title='After Breakfast Measurement'
                                                  handleData={this.handleData}
                                                  beforeMeal={false}
                                                  patientId={this.props.patientId}
                                                  date={data.date}
                                                  className='multiMeasureModal'

                                              />,{
                                                  className:'multiMeasureModal'
                                              }
                                          )
                                      }
                                  }
                              }
                            }
                          }
                      />
                  </ColumnGroup>
                  <ColumnGroup title="Lunch">
                      <Column
                          title="B"
                          width='11%'
                          dataIndex="data.LUNCH"
                          key='beforeLunch'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                          }
                          onCell={(data) => {
                              return {
                                  onClick: (e) => {
                                      const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                      const ele = e.target;
                                      const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                      const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                      if(isMissedReading){
                                          return;
                                      }
                                      const pairedMeasures = this.isPairedMeasure(data.data, 'LUNCH');
                                      if (pairedMeasures) {
                                          this.props.openModal(<PairedMeasuresComponent
                                              data={pairedMeasures}
                                              title='Paired Lunch Measurements'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                          />,{
                                              className:'pairedMeasureModal'
                                          })
                                          return;
                                      }
                                      const measures = this.filterTypeAndBefore(data.data, 'beforeLunch');
                                      if (measures.length == 0) return;
                                      if (measures.length == 1) {
                                          this.props.openModal(<SingleMeasureRenderComponent
                                              data={measures}
                                              title='Before Lunch Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={true}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              showSingleReading={true}

                                          />,{
                                              className:'singleMeasureModal'
                                          })
                                      }
                                      if (measures.length > 1) {
                                          this.props.openModal(<MultiMeasureComponent
                                                  data={measures}
                                                  title='Before Lunch Measurement'
                                                  handleData={this.handleData}
                                                  beforeMeal={true}
                                                  patientId={this.props.patientId}
                                                  date={data.date}
                                                  className='multiMeasureModal'

                                              />,{
                                                  className:'multiMeasureModal'
                                              }
                                          )
                                      }
                                  }
                              }
                            }
                          }
                      />
                      <Column
                          title="A"
                          dataIndex="data.LUNCH"
                          key='afterLunch'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                          }

                          onCell={(data) => {
                              return {
                                  onClick: (e) => {
                                      const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                      const ele = e.target;
                                      const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                      const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                      if(isMissedReading){
                                          return;
                                      }
                                      const pairedMeasures = this.isPairedMeasure(data.data, 'LUNCH');
                                      if (pairedMeasures) {
                                          this.props.openModal(<PairedMeasuresComponent
                                              data={pairedMeasures}
                                              title='Paired Lunch Measurements'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                          />,{
                                              className:'pairedMeasureModal'
                                          })
                                          return;
                                      }
                                      const measures = this.filterTypeAndBefore(data.data, 'afterLunch');
                                      if (measures.length == 0) return;
                                      if (measures.length == 1) {
                                          this.props.openModal(<SingleMeasureRenderComponent
                                              data={measures}
                                              title='After Lunch Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={false}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              showSingleReading={true}
                                          />,{
                                              className:'singleMeasureModal'
                                          })
                                      }
                                      if (measures.length > 1) {
                                          this.props.openModal(<MultiMeasureComponent
                                                  data={measures}
                                                  title='After Lunch Measurement'
                                                  handleData={this.handleData}
                                                  beforeMeal={false}
                                                  patientId={this.props.patientId}
                                                  date={data.date}
                                                  className='multiMeasureModal'

                                              />,{
                                                  className:'multiMeasureModal'
                                              }
                                          )
                                      }
                                  }
                              }
                            }
                          }
                      />
                  </ColumnGroup>
                  <ColumnGroup title="Dinner">
                      <Column
                          title="B"
                          dataIndex="data.DINNER"
                          width='11%'
                          key='beforeDinner'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                          }

                          onCell={(data)=> {
                              return {
                                  onClick: (e) => {
                                      const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                      const ele = e.target;
                                      const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                      const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                      if(isMissedReading){
                                          return;
                                      }
                                      const pairedMeasures = this.isPairedMeasure(data.data, 'DINNER');
                                      if (pairedMeasures) {
                                          this.props.openModal(<PairedMeasuresComponent
                                              data={pairedMeasures}
                                              title='Paired Dinner Measurements'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                          />,{
                                              className:'pairedMeasureModal'
                                          })
                                          return;
                                      }
                                      const measures = this.filterTypeAndBefore(data.data, 'beforeDinner');
                                      if (measures.length == 0) return;
                                      if (measures.length == 1) {
                                          this.props.openModal(<SingleMeasureRenderComponent
                                              data={measures}
                                              title='Before Dinner Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={true}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              showSingleReading={true}

                                          />,{
                                              className:'singleMeasureModal'
                                          })
                                      }
                                      if (measures.length > 1) {
                                          this.props.openModal(<MultiMeasureComponent
                                                  data={measures}
                                                  title='Before Dinner Measurement'
                                                  handleData={this.handleData}
                                                  beforeMeal={true}
                                                  patientId={this.props.patientId}
                                                  date={data.date}
                                                  className='multiMeasureModal'

                                              />,{
                                                  className:'multiMeasureModal'
                                              }
                                          )
                                      }
                                  }
                              }
                            }
                          }
                      />
                      <Column
                          title="A"
                          dataIndex="data.DINNER"
                          key='afterDinner'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                           </div>
                              }
                          }
                          }
                          onCell={(data)=> {
                              return {
                                  onClick: (e) => {
                                      const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                      const ele = e.target;
                                      const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                      const isMissedReading = eleIsMissedReading || parentIsMissedReading;
                                      if(isMissedReading){
                                          return;
                                      }
                                      const pairedMeasures = this.isPairedMeasure(data.data, 'DINNER');
                                      if (pairedMeasures) {
                                          this.props.openModal(<PairedMeasuresComponent
                                              data={pairedMeasures}
                                              title='Paired Dinner Measurements'
                                              handleData={this.handleData}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                          />,{
                                              className:'pairedMeasureModal'
                                          })
                                          return;
                                      }
                                      const measures = this.filterTypeAndBefore(data.data, 'afterDinner');
                                      if (measures.length == 0) return;
                                      if (measures.length == 1) {
                                          this.props.openModal(<SingleMeasureRenderComponent
                                              data={measures}
                                              title='After Dinner Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={false}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              showSingleReading={true}

                                          />,{
                                              className:'singleMeasureModal'
                                          })
                                      }
                                      if (measures.length > 1) {
                                          this.props.openModal(<MultiMeasureComponent
                                                  data={measures}
                                                  title='After Dinner Measurement'
                                                  handleData={this.handleData}
                                                  beforeMeal={false}
                                                  patientId={this.props.patientId}
                                                  date={data.date}
                                                  className='multiMeasureModal'

                                              />,{
                                                  className:'multiMeasureModal'
                                              }
                                          )
                                      }
                                  }
                              }
                            }
                          }
                      />
                  </ColumnGroup>
                  <Column
                      title=""
                      dataIndex="data.BEDTIME"
                      key='bedtime'
                      className='bedtime logBookIcon'
                      width='11%'
                      render={(data, index) => {
                          const { readingCount,className,mgdlValue,unit } = this.handleData(data||[]);
                          return {
                              props: {
                                  className:'number',
                                  data:readingCount

                              },
                              children: <div data={readingCount} className={className}>
                                          <div>
                                              <span>{ mgdlValue }</span>
                                          </div>
                                       </div>
                          }
                      }
                      }

                      onCell={(data) => {
                          return {
                              onClick: (e) => {
                                  const eleIsMissedReading = $(e.target).hasClass('missedReading');
                                  const ele = e.target;
                                  const parentIsMissedReading = $(ele).parent().hasClass('missedReading');
                                  const isMissedReading = eleIsMissedReading || parentIsMissedReading;

                                  if(isMissedReading){
                                      return;
                                  }

                                  const measures = this.filterTypeAndBefore(data.data, 'bedtime');
                                  if (measures.length == 0) return;
                                  if (measures.length == 1) {
                                      this.props.openModal(<SingleMeasureRenderComponent
                                          data={measures}
                                          title='Bedtime Measurement'
                                          handleData={this.handleData}
                                          beforeMeal={false}
                                          patientId={this.props.patientId}
                                          date={data.date}
                                          showSingleReading={true}
                                      />,{
                                          className:'singleMeasureModal'
                                      })
                                  }
                                  if (measures.length > 1) {
                                      this.props.openModal(<MultiMeasureComponent
                                              data={measures}
                                              title='Bedtime Measurement'
                                              handleData={this.handleData}
                                              beforeMeal={true}
                                              patientId={this.props.patientId}
                                              date={data.date}
                                              className='multiMeasureModal'
                                              shouldRenderFoodLog={false}
                                          />,{
                                              className:'multiMeasureModal'
                                          }
                                      )
                                  }
                              }
                          }
                        }
                      }
                  />
                  {/*<Column*/}
                      {/*title=""*/}
                      {/*dataIndex="data.OVERNIGHT"*/}
                      {/*key='overnight'*/}
                      {/*width='11%'*/}
                      {/*className='overnight logBookIcon'*/}
                      {/*render={(data, index) => {*/}
                          {/*const { readingCount,className,mgdlValue,unit } = this.handleData(data||[]);*/}
                          {/*return {*/}
                              {/*props: {*/}
                                  {/*className:'number',*/}
                                  {/*data:readingCount*/}

                              {/*},*/}
                              {/*children: <div data={readingCount} className={className}>*/}
                                  {/*<span>{ mgdlValue }</span>*/}
                              {/*</div>*/}
                          {/*}*/}
                      {/*}*/}
                      {/*}*/}

                      {/*onCell={(data) => {*/}
                          {/*return {*/}
                              {/*onClick: (e) => {*/}
                                  {/*const eleIsMissedReading = $(e.target).hasClass('missedReading');*/}
                                  {/*const ele = e.target;*/}
                                  {/*const parentIsMissedReading = $(ele).parent().hasClass('missedReading');*/}
                                  {/*const isMissedReading = eleIsMissedReading || parentIsMissedReading;*/}
                                  {/*if(isMissedReading){*/}

                                      {/*return;*/}
                                  {/*}*/}
                                  {/*const measures = this.filterTypeAndBefore(data.data,'overnight');*/}

                                  {/*if (measures.length == 0) return;*/}
                                  {/*if (measures.length == 1) {*/}
                                      {/*this.props.openModal(<SingleMeasureRenderComponent*/}
                                          {/*data={measures}*/}
                                          {/*title='Overnight Measurement'*/}
                                          {/*handleData={this.handleData}*/}
                                          {/*beforeMeal={true}*/}
                                          {/*patientId={this.props.patientId}*/}
                                          {/*date={data.date}*/}
                                          {/*showSingleReading={true}*/}

                                      {/*/>)*/}
                                  {/*}*/}
                                  {/*if (measures.length > 1) {*/}
                                      {/*this.props.openModal(<MultiMeasureComponent*/}
                                              {/*data={measures}*/}
                                              {/*title='Overnight Measurement'*/}
                                              {/*handleData={this.handleData}*/}
                                              {/*beforeMeal={true}*/}
                                              {/*patientId={this.props.patientId}*/}
                                              {/*date={data.date}*/}
                                          {/*/>*/}
                                      {/*)*/}
                                  {/*}*/}
                              {/*}*/}
                          {/*}*/}
                        {/*}*/}
                      {/*}*/}
                  {/*/>*/}
              </Table>
            </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }


}


const mapToDispatch = (dispatch)=> {
    return {
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        // changePre:()=>dispatch(actions.changePre()),
        // changeNext:()=>dispatch(actions.changeNext()),
        // setTimeInterval:(interval,value)=>dispatch(actions.setTimeInterval(interval,value)),
        // initialState:()=>dispatch(actions.initialState()),
        // resetState:()=>dispatch(actions.resetState()),
    }
}

export default compose(connect(null,mapToDispatch))(LogBookListComponent);
