import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;
import { getMemberProfile } from 'patientModule/AdditionalInfo/helpers/index';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';


const socialSupportItems = [
    [
      {
        key: 'socialSupport.communitySupport',
        label: 'Community/Social Support',
        path: 'profile.socialSupport.communitySupport',
        span: 8,
        rules : [{required : true, message : 'Field is required'}],
        inputContent: (disabled) => <Input disabled={disabled} placeholder='Caregiver/Organization Name'/>
      },
      {
        key: 'socialSupport.contactInfo',
        label: 'Contact Information',
        path: 'profile.socialSupport.contactInfo',
        span: 8,
        // rules : [{required : true, message : 'Field is required'}],
        inputContent: (disabled) => <Input disabled={disabled} placeholder='Email or phone number'/>
      },
      {
        key: 'socialSupport.otherProviders',
        label: 'Other Providers',
        path: 'profile.socialSupport.otherProviders',
        span: 8,
        // rules : [{required : true, message : 'Field is required'}],
        inputContent: (disabled) => <Input disabled={disabled} placeholder='Type…'/>
      },
    ],
];

const socialSupportNote = [
    [{
        key: 'socialSupportNote',
        label: 'Notes',
        path: 'profile.socialSupport.extraNote',
        span: 12,
        inputContent: (disabled, localThis) => <TextArea autoSize={{ minRows: 1}}
                                                         disabled ={disabled}
                                                         placeholder='Add note...'
                                                         onChange={localThis.setNotes}
                                                         allowClear/>
    }]
]

export {
    socialSupportItems,
    socialSupportNote
}
