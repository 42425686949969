import I18N from '../../I18N';

const DEVICE_VITAL_MAP = {
  Ease: s => s && s !== 'INVALID' ? I18N.get('vitals.BP') : null,
  Align: s => s && s !== 'INVALID' ? I18N.get('vitals.BG') : null,
  Lina: s => s && s !== 'INVALID' ? I18N.get('vitals.HS') : null,
  Thermometer: s => s && s !== 'INVALID' ? I18N.get('vitals.TM') : null,
  Oximeter: s => s && s !== 'INVALID' ? I18N.get('vitals.PO') : null,
  TrackSet: s => s ? I18N.get('vitals.BP') : null,
  BG5S: s => s && s !== 'INVALID' ? I18N.get('vitals.BG') : null,
  Nexus: s => s && s !== 'INVALID' ? I18N.get('vitals.HS') : null,

};

const DEVICE_VITAL_TYPE_MAP = {
  easeCheckbox: I18N.get('vitals.BP'),
  alignCheckbox: I18N.get('vitals.BG'),
  linaCheckbox: I18N.get('vitals.HS'),
  tmCheckbox: I18N.get('vitals.TM'),
  poCheckbox: I18N.get('vitals.PO'),
  BG5S:I18N.get('vitals.BG'),
  nexusCheckbox: I18N.get('vitals.HS'),
  trackSet: I18N.get('vitals.BP'),
};
const ALL_CHECKBOX_DEVICES = {
  ...DEVICE_VITAL_TYPE_MAP,
  iPhone: `iPhone`,
}

// mapping devices' name
// for InventoryProduct.ProductTypeEnum
const deviceNameMap = {
  Ease: 'BP', Align: 'BG', Lina: 'HS',
  iPhone: 'PHONE', TrackSet: 'CLEAR',
  Lancets: 'LANCET', Strips: 'STRIP',
  Oximeter: 'OXIMETER', Thermometer: 'THERMOMETER',BG5S:'BG5S',
  Nexus:'NEXUS'
};

const userDeviceRecordsTypeName = {
  Lancets: 'Lancets',
  Strips: 'Strips',
  TrackSet: 'TrackSet',
  iPhone: 'iPhone',
  Thermometer: 'Thermometer',
  Oximeter: 'Oximeter',
  Ease: 'Ease',
  Align: 'Align',
  Lina: 'Lina',
  BG5S: 'BG5S',
  Nexus: 'Nexus',
}

const giveOutMap = {
  Ease: 'giveOutEase',
  Align: 'giveOutAlign',
  Lina: 'giveOutLina',
  Thermometer: 'giveOutTM',
  Oximeter: 'giveOutPO',
  BG5S: 'giveOutBG5S',
  Nexus:'giveOutNexus'
};

const BASE_COUNTER = 10; // only affect inventory in Follow Up Visit

const defaultDeviceCount = {
  Ease: 0, Align: 0, Lina: 0, Thermometer: 0, Oximeter: 0,
  Lancets: 0, Strips: 0, iPhone: 0, TrackSet: 0,BG5S: 0,Nexus:0
};
const APP_SYNC = 'APP_SYNC';
const MANUAL = 'MANUAL_INPUT';
const assignedDeviceTitles = {
  trackSet: {
    title: 'Assign iHealth Clear Device',
    note: 'To uncheck Track Set, you need to unassign the device first'
  },
  iPhone: {
    title: 'Assign iPhone Device',
    note: 'To uncheck iPhone, you need to unassign the device first'
  }
};

const editDeviceNameMap = {
  Ease: `Ease (BP3L): App Sync`,
  Align: `Align (BG1): App Sync`,
  Lina: `Lina (HS2): App Sync`,
  Thermometer: `Thermometer: App Sync`,
  Oximeter: `Oximeter: App Sync`,
  iPhone: `iPhone`,
  TrackSet: 'iHealth Clear',
  BG5S: 'Gluco+ (BG5S): App Sync',
  Nexus:'Nexus (HS2S): App Sync'
};

const techLevel = [
  { value: 'HIGH', name: 'High' },
  { value: 'NORMAL', name: 'Normal' },
  { value: 'LOW', name: 'Low' }
];

// field names, not real device names
const BGDevices = ['Align', 'BG5S'];

const DEVICE_VITAL_MAP_SHORT = {
  Ease: 'BP' ,
  Align: 'BG',
  BG5S: 'BG',
  Lina: 'HS',
  Nexus:'HS',
  Thermometer: 'TM',
  Oximeter: 'PO',
};

const defaultDeviceRecord = Object.assign({}, ..._.map(userDeviceRecordsTypeName, (v, k) => ({ [k]: undefined })));
const APP_SYNC_FIELDS = ['easeAppSync','alignAppSync','linaAppSync','tmAppSync','poAppSync','BGAppSync'];
export {
  deviceNameMap,
  giveOutMap,
  BASE_COUNTER,
  defaultDeviceCount,
  assignedDeviceTitles,
  editDeviceNameMap,
  techLevel,
  DEVICE_VITAL_MAP,
  DEVICE_VITAL_TYPE_MAP,
  APP_SYNC,
  MANUAL,
  BGDevices,
  DEVICE_VITAL_MAP_SHORT,
  userDeviceRecordsTypeName,
  defaultDeviceRecord,
  APP_SYNC_FIELDS,
  ALL_CHECKBOX_DEVICES
};
