import {
  React, _, IHSelect, message, IHButton, IHTable
} from 'ihcomponent';
import I18N from 'modulesAll/I18N';
import { REPLACE_OR_RETURN, populateFormData, prepareFinalValuesWhenProcessReplaceOrReturnDevices, filterOnlyUniqueDeviceAddress, disabledTooOldDate } from 'modulesAll/vendor/patient/common/utils';
import { createAuditLog } from 'libModule/utils/auditLog'
import { getDevicesHistoryModalProps } from './DevicesReplacementHistory'
import {getFormProps as replaceDevicesFormProps} from 'modulesAll/vendor/patient/components/ReplaceDevicesForm'
import {getFormProps as returnDevicesFormProps} from 'modulesAll/vendor/patient/components/ReturnDevicesForm'
import GoThumbButtonComponent from 'modulesAll/gothumb/components/GoThumbButtonComponent'
import ReplaceReturnBaseForm from 'modulesAll/vendor/patient/components/ReplaceReturnBaseForm'
import PropTypes from 'prop-types'

const withCommonDevicesList = (WrappedComponent) => {
  return class CommonDevicesList extends React.PureComponent {
    static displayName = 'vendor/patient/components/CommonDevicesList'

    static propTypes = {
      devicesStatus: PropTypes.array,
      changeDeviceStatusTableInfo: PropTypes.func,
      replaceOrReturnDevicesStep: PropTypes.number,
      changeDeviceRemarkTableInfo: PropTypes.func,
      closeModal: PropTypes.func,
      openModal: PropTypes.func,
      patientInfo: PropTypes.object,
      onFieldsChange: PropTypes.func,
      setReplaceDevicesStep: PropTypes.func,
      processReplaceOrReturnDevicesAsync: PropTypes.func,
      returnDevicesStep: PropTypes.number,
    }



  replaceStep1Columns = (epStatus) => [
    {
      title: 'Device Name',
      dataIndex: 'model',
      key: 'model',
      render: (item, row) => {
          return I18N.get(`Devices.${row.type}.${row.model}`);
      }
    },
    {
      title: 'Device ID',
      render : (t, doc)=>{
          return this.renderDeviceId(doc, epStatus)
      },
      key: 'address',
      width:'20%'
    }
  ]

  replaceStep2Columns = (epStatus) => [
    ...this.replaceStep1Columns(epStatus),
    {
      title : 'Condition',
      key : 'condition',
      render : (t, doc)=>{
          return this.renderSelectStatus(doc)
      },
      width:'17%'
    },
    {
      title : 'Remarks',
      key : 'remark',
      render : (t, doc)=>{
          return this.renderRemark(doc)
      }
    }
  ]


  getSelectStatusProps(doc) {

    const selectedValue = _.find(this.props.devicesStatus, e => e.deviceId === doc.address);
    return {

        option : [
            {name:'Normal', value: 'NORMAL'},
            {name:'Lost', value: 'LOST'},
            {name:'Damaged', value: 'DAMAGED'},

        ],
        rules : [
            {required : true}
        ],
        onChange : (selectedValue)=>{
            this.props.changeDeviceStatusTableInfo(doc.address,selectedValue)
        },
        value: selectedValue && selectedValue.select ? selectedValue.select : 'NORMAL'


      }
  }

  renderStatusReadOnly(doc){
    const selectedValue = _.find(this.props.devicesStatus, e => e.deviceId === doc.address);
    return <div>{selectedValue && selectedValue.select ? selectedValue.select : 'NORMAL'}</div>
  }

  renderSelectStatus(doc) {

    if(doc.status === 'PATIENT_OWNED')
      return '';

    return (
      this.props.replaceOrReturnDevicesStep < REPLACE_OR_RETURN.SUBMITTED ?
          <IHSelect {...this.getSelectStatusProps(doc)} /> :  this.renderStatusReadOnly(doc)
    )
  }

  renderDeviceId(doc, epStatus){
    if(doc.status === 'VENDOR_LOAN')
     return doc.address
    else if (doc.status === 'PATIENT_OWNED'){
      return (!!doc.other && doc.other.length) ? `Patient Owned. ${doc.other}` : 'Patient Owned'
    }else if(doc.status === 'RETURNED' && epStatus === 'RETURNED')
      return  'Returned'
    else
      return ''
  }

  getRemarkProps(doc){
    const selectedValue = _.find(this.props.devicesStatus, e => e.deviceId === doc.address);
    return {
      onBlur : (e)=>{
        this.props.changeDeviceRemarkTableInfo(doc.address, e.target.value)
      },
      defaultValue: selectedValue && selectedValue.remark ? selectedValue.remark : '',
      maxLength:250
    }
  }

  renderRemarkReadOnly(doc){
    const selectedValue = _.find(this.props.devicesStatus, e => e.deviceId === doc.address);
    return <div>{selectedValue && selectedValue.remark ? selectedValue.remark : ''}</div>
  }

  renderRemark(doc) {

    if(doc.status === 'PATIENT_OWNED')
      return '';

      return (
          this.props.replaceOrReturnDevicesStep < REPLACE_OR_RETURN.SUBMITTED ?
          <input type="text" className="textInput" {...this.getRemarkProps(doc)}/> : this.renderRemarkReadOnly(doc)
      )
  }

  showMessageNoLoanForm = (i18nNoLoanForm, epName ) => {
    message.error('Patient does not have a loan form');

    const currentPatient = this.props.patientInfo
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
      action: i18nNoLoanForm,
      patientNRIC,
      patientName,
      details: { programName: epName },
    })
  }


  renderNoLoanForm(i18nNoLoanForm, epName){
    return  <IHButton label="Loan Form" bsStyle="primary" onClick={ () => this.showMessageNoLoanForm(i18nNoLoanForm, epName )} />
  }

  callReplaceOrReturnDevicesStepButton = (isDischarged) => {
    if( this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE  ) {
      populateFormData({onFieldsChange: this.props.onFieldsChange, isDischarged });
    }
    this.props.setReplaceDevicesStep(this.props.replaceOrReturnDevicesStep < REPLACE_OR_RETURN.SUBMITTED  ? this.props.replaceOrReturnDevicesStep+1 : /** when already in step 3, if want to repeat the process, go back step 2 **/ REPLACE_OR_RETURN.FORM_DETAILS);
  }


  afterConfirmProcessReplaceOrReturnDevices = (isDischarged, ep, epId) => {
    if(isDischarged)
    this.callReplaceOrReturnDevicesStepButton(isDischarged);//only call if  return devices, for replace devices, keep on same state==2, just like enropllprogram page

    const replaceDevices  = prepareFinalValuesWhenProcessReplaceOrReturnDevices(ep, this.props.devicesStatus);

    //this.props.enrolledProgramList always has value because 'modulesAll/graphql/enrolledProgramList' graphQL result root shape is enrolledProgramList
    this.props.processReplaceOrReturnDevicesAsync(epId , replaceDevices, isDischarged);
  }


  processReplaceOrReturnDevices = async (isDischarged, epId, ep, form) => {
    //is not discharge means replace devices, if discharge means is return devices
    //for replace devices auto populate form , so no need validate
    if(isDischarged){
      let isFormValid = true;
      const callback = (result, value) => {
        if (!result) {
          isFormValid = false
        }
      };
      await form.getFormData(callback);
      if (!isFormValid) {
        return
      }
    }

    //confirmation dialog
    const { closeModal, openModal } = this.props;

    const p1 = {
      type : 'primary',
      label : 'Confirm',
      size : 'large',
      onClick : () => {
         this.afterConfirmProcessReplaceOrReturnDevices(isDischarged, ep, epId);
         closeModal();
      }
    };
    const p2 = {
      label : 'Close',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : () => {
        closeModal();
      }
    };
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    );
    const param = {
      size : 'sm',
      title : `Confirm ${isDischarged ? 'Return' : 'Replace'} Devices`,
      content : `Are you sure you want to ${isDischarged ? 'return' : 'replace' } devices?`,
      footer
    };
    openModal(param.content, param);


  };

  openDevicesHistory = (i18nRoleOfViewerDeviceHistory, epName, epId, updateDevicesInEnrolledProgramInList /** optional **/) => {
    const currentPatient = this.props.patientInfo
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
      action: i18nRoleOfViewerDeviceHistory,
      patientNRIC,
      patientName,
      details: { programName: epName}
    })

    const p = getDevicesHistoryModalProps(epId, this.props.closeModal, updateDevicesInEnrolledProgramInList)
    this.props.openModal(p.body, p)
  }


  renderMainLayout = ({ep, loanForm, epStatus, epStartDate, epId, updateDevicesInEnrolledProgramInList,
    i18nLoanForm, i18nViewDevicesHistory, i18nNoLoanForm,
    hasVendorLoanDevices, isEnrolledProgramDischarged,
    onFieldsChange, fieldsValue,
    deviceTableProps
  }) => {

    deviceTableProps.dataSource = filterOnlyUniqueDeviceAddress(_.get(ep, 'devices', []) || [])/** .filter( device => device.status === 'VENDOR_LOAN' ) VS-2084 shows all devices including patient owned **/;

        /** VS-2084 thus devices will not be empty because will also show PATIENT_OWNED **/
        if (!deviceTableProps.dataSource || (deviceTableProps.dataSource && deviceTableProps.dataSource.length === 0) ) {
          return (
                <div className="v-tab-content">
                  <div>No devices found
                  <div className="btn-wrapper">
                      <div className="right">
                        {loanForm ? <GoThumbButtonComponent filename={_.split(loanForm.uri, '/files/')[1]} buttonLabel='Loan Form' programName={ep.name} action={i18nLoanForm} style={{}} /> : this.renderNoLoanForm(i18nNoLoanForm, ep.name)}
                      </div>
                  </div>
                  </div>
                  </div>)
        }

      const formProps = isEnrolledProgramDischarged ?
        ( this.props.replaceOrReturnDevicesStep < REPLACE_OR_RETURN.SUBMITTED ? returnDevicesFormProps({ onFieldsChange, fieldsValue, disabledTooOldDate: disabledTooOldDate(epStartDate) })
        : returnDevicesFormProps({ onFieldsChange, fieldsValue, /** step 3 only disable fields **/
                                   disabledTooOldDate: disabledTooOldDate(epStartDate),
                                   adhocDisableFields: {returnedBy:true, returnedDateAndTime:true, processedBy:true, processedDateAndTime:true} }))
        : replaceDevicesFormProps({ onFieldsChange, fieldsValue, disabledTooOldDate: disabledTooOldDate(epStartDate) })

      return   (
        <div className="v-tab-content">
          <IHTable {...deviceTableProps}
            columns={this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE ? this.replaceStep1Columns(epStatus) : this.replaceStep2Columns(epStatus) } />

          { (
              ( !isEnrolledProgramDischarged/**replace devices**/ && this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.SUBMITTED ) ||
              ( isEnrolledProgramDischarged/**return devices**/ && this.props.replaceOrReturnDevicesStep >= REPLACE_OR_RETURN.FORM_DETAILS /**sep 2 or 3 will show form **/ )
            )
            &&
          <div style={{paddingTop:'30px',width:'100%', maxWidth:'784px'}}>
          <ReplaceReturnBaseForm {...formProps} ref={refNode => { this.returnReplaceDevicesForm = refNode }} step={this.props.replaceOrReturnDevicesStep}/>
          </div>
          }

      <div className="btn-wrapper" style={{margin:'30px 0 40px 0'}}>
        <div className="right">

          { <IHButton
            label='Devices History'
            bsStyle="primary"
            style={{marginRight: '10px'}}
            onClick={ ()=>this.openDevicesHistory(i18nViewDevicesHistory, ep.name, epId, updateDevicesInEnrolledProgramInList) }
          /> }

          {loanForm ? <GoThumbButtonComponent filename={_.split(loanForm.uri, '/files/')[1]} buttonLabel='Loan Form' programName={ep.name} action={i18nLoanForm} style={{}} /> : this.renderNoLoanForm()}

          { ( (ep.status === 'STARTED' /**|| ep.status === 'SUSPENDED' **/ ) && this.props.replaceOrReturnDevicesStep < REPLACE_OR_RETURN.SUBMITTED && hasVendorLoanDevices) &&

            <IHButton
              label={ (this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE || this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.SUBMITTED ) ? "Replace Devices" : "Next" }
              bsStyle="primary"
              onClick={ (this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE || this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.SUBMITTED ) ?
                              ()=> this.callReplaceOrReturnDevicesStepButton(isEnrolledProgramDischarged) : ()=> this.processReplaceOrReturnDevices(isEnrolledProgramDischarged, epId, ep, this.returnReplaceDevicesForm)  }
            />
          }

          { ( isEnrolledProgramDischarged && this.props.returnDevicesStep < REPLACE_OR_RETURN.SUBMITTED && hasVendorLoanDevices) &&
            // returnDevicesStep  step 1, show 'Return Devices', step 2, show 'Done', step 3, hide the button
            // replaceOrReturnDevicesStep step 1 , non editable table, step 2, editable table listing

           <IHButton
              label={ this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE ? "Return Devices" :  "Done" }
              bsStyle="primary"
              onClick={ this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.INITIAL_STATE ? ()=> this.callReplaceOrReturnDevicesStepButton(isEnrolledProgramDischarged)  : ()=>this.processReplaceOrReturnDevices(isEnrolledProgramDischarged, epId, ep, this.returnReplaceDevicesForm)}
           />
          }
      </div>
      </div>
      </div>
    )
  }




  render() {
    return (
       <div>
         <WrappedComponent {...this.props}
           replaceStep1Columns={this.replaceStep1Columns}
           replaceStep2Columns={this.replaceStep2Columns}
           showMessageNoLoanForm={this.showMessageNoLoanForm}
           callReplaceOrReturnDevicesStepButton={this.callReplaceOrReturnDevicesStepButton}
           afterConfirmProcessReplaceOrReturnDevices={this.afterConfirmProcessReplaceOrReturnDevices}
           processReplaceOrReturnDevices={this.processReplaceOrReturnDevices}
           openDevicesHistory={this.openDevicesHistory}
           renderNoLoanForm={this.renderNoLoanForm}
           renderMainLayout={this.renderMainLayout}
         />
       </div>
     );
  }

  };
}

export default withCommonDevicesList;
