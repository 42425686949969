import _ from 'lodash';
import React, { Component } from 'react';
import { IHLoading } from 'ihcomponent';

class PdfLinkComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: undefined,
            error: undefined,
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        const { link } = this.props;
        this.setState({ isLoading: true, data: undefined, error: undefined })
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.responseType = 'blob'
        xmlHttp.onreadystatechange = () => { 
            if (xmlHttp.readyState == 4 && xmlHttp.status === 200) {
                console.log(xmlHttp.response);
                const blob = new Blob([xmlHttp.response], { type: 'application/pdf' });
                console.log(window.URL.createObjectURL(blob));
                this.setState({ isLoading: false, data: window.URL.createObjectURL(blob) });
            }
        }
        xmlHttp.onerror = () => {
            this.setState({ isLoading: false, error: xmlHttp.response || 'Something went wrong!' });
        };
        xmlHttp.open('GET', link, true);
        xmlHttp.send(null);
    }
    
    render() {
        const { isLoading, error, data } = this.state;
        const { link, name } = this.props;

        if (isLoading)  return <IHLoading />;
        if (error) return <div>Cannot show the preview! Click the button to download it. <a href={link} download={name}>{name}</a></div>;
        if (data) return <iframe src={data} style={{ width: '100%', minHeight: 700 }} />;
        return <div>Something went wrong!</div>
    }
}

export default PdfLinkComponent;