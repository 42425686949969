import update from 'immutability-helper'

export const program = {
  adminProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.editAdminProgram

    return update(prev, {
      adminProgram: {
        $set: result
      }
    })
  },
}

export const changeAdminProgramStatus = {
  adminProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.changeAdminProgramStatus

    return update(prev, {
      adminProgram: {
        $set: result
      }
    })
  },
}

export const removeTaskFromAdminProgram = {
  adminProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.removeTaskFromAdminProgram

    return update(prev, {
      adminProgram: {
        $set: result
      }
    })
  },
}
