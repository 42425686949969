import gql from 'graphql-tag';
import { AssessmentList,LightAssessment } from './schema/Assessment';

export default gql`
  query assessmentList(
    $filters: InputAssessmentFilters,
    $sort: AssessmentListSort,
    $page: Int,
    $count: Int
  ) {
    assessmentList(
      filters: $filters,
      sort: $sort,
      page: $page,
      count: $count
    ) {
      data {
        ${AssessmentList}
      }
      pageInfo {
        total
        lastPage
      }
    }
  }
`;

export const LightAssessmentList = gql`
  query assessmentList(
    $filters: InputAssessmentFilters,
    $sort: AssessmentListSort,
    $page: Int,
    $count: Int
  ) {
    assessmentList(
      filters: $filters,
      sort: $sort,
      page: $page,
      count: $count
    ) {
      data {
        ${LightAssessment}
      }
      pageInfo {
        total
        lastPage
      }
    }
  }
`;

export const assessmentListWithSearch = gql`
  query assessmentList(
    $filters: InputAssessmentFilters,
    $search: InputAssessmentSearch,
    $sort: AssessmentListSort,
    $page: Int,
    $count: Int
  ) {
    assessmentList(
      filters: $filters,
      search: $search,
      sort: $sort,
      page: $page,
      count: $count
    ) {
      data {
        ${AssessmentList}
      }
      pageInfo {
        total
        lastPage
      }
    }
  }
`;
