import gql from 'graphql-tag'
import {EnrolledProgram} from './schema/EnrolledProgram'

export default gql`
  mutation resumeEnrolledProgram(
    $id : EID!
  ){
      resumeEnrolledProgram(
        id: $id
      ) {
        ${EnrolledProgram}
      }
  }
`
