import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

const SignOffSubmitInput = ({ form, submitBtnText, handleSubmit }) => {
  const _handleSubmit = () => {
    if(typeof handleSubmit !== 'function')
      return console.error('error handleSubmit is invalid');

    handleSubmit();
  }

  return(<div className='submitCommentContainer'>
    {/* <div className='label'>
      Sign off note
    </div> */}
    <Form.Item>
      {
        form.getFieldDecorator('comment', {
          // rules: [{ required: true, message: 'Sign off note is required', whitespace: true }],
          initialValue: ''
        })( <Input.TextArea
          placeholder='(Optional) Please add a comment here'
          autoSize={{ minRows: 3, maxRows: 3 }}
        />)
      }
    </Form.Item>
    {/* <div className='note'>
      Note: A provider note will be added once signing off.
    </div> */}
    <Button type='primary' onClick={_handleSubmit}>
      {submitBtnText || 'Submit'}
    </Button>
  </div>);
}

SignOffSubmitInput.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default SignOffSubmitInput;