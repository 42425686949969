import React from 'react';
import moment from "moment/moment";
import { closeModal } from 'layoutModule/actions/MainModal'
import { Button,Divider,Tabs } from 'antd';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import { graphql } from "react-apollo/index";
import { foodLogList } from "../query";
import  '../style/index.scss';
import helpers from '../helpers';
import {convertBG_mmolTomgdl} from "../../../lib/utils/convertUnits";

const TabPane = Tabs.TabPane;
const readingSeverityMap ={
    "RISK":'riskReading',
    "NORMAL":'normalReading',
    "CRITICAL":'criticalReading'
}

const tagsMap = {
    'hadMedicine':'Took Medication',
    'hadInsulin' : 'Took Insulin',
    'hadActivity': "Exercised"
}

const styles = {
    patientNoteTitle: {
        width: '400px',
        textAlign: 'start',
        fontSize: '14px',
        fontWeight:'bold'
    },
    tagStyle:{
        padding:2,
        fontWeight:'normal',
        borderRadius:4,
        whiteSpace:'nowrap',
        marginRight:5,
    },
    moodStyle:{
        fontWeight:'normal',
        fontSize:14,
    },
    divStyle:{
        display:'flex',
    },
    pStyle:{
        fontSize:14,
        fontWeight:'normal',
        whiteSpace:'nowrap',
        marginRight: 10
    }
};

const PairedMeasuresComponent = class extends React.Component {

    renderFoodLogImg(images){
        const tabs =  _.map(images,(imgList,index)=> {

            return _.map(imgList.images, (img) => {
                const imgRender = <img src={img.link} width={100} height={100}/>;
                const notes = imgList.note ? <div style={{fontSize:14,fontWeight:500}}>
                                                {imgList.note}
                                             </div>
                                           : <span style={{color:'#c5c5c5',fontWeight:'normal'}}>No Comment</span>;
                return<TabPane tab={imgRender} key={index} forceRender={true} className='pairedImgRender'>
                     <span style={{
                         fontWeight: 600,
                         marginTop: 10,
                         marginRight:10,
                     }}>
                        Patient Notes (Food Entry)
                     </span>
                    <p></p>
                    { notes }
                </TabPane>
            })
        })
        let  renderImgList = images.length == 0 ?
            <div className='col-lg-12'>
                                  <span style={{
                                      fontWeight: 600,
                                      marginTop: 10,
                                      marginBottom: 10,
                                      fontSize:14
                                  }}>Food Diary Entry</span>
                <div style={{height:100,background:'#f7f7f7',
                    display:'flex',alignItems:'center',marginTop:10,
                    justifyContent:'center',color:'#c5c5c5'}}>

                    No Diary Entry
                </div>
            </div>
            :
            <div className='col-lg-12'>
                                    <span style={{
                                        fontWeight: 600,
                                        marginTop: 10,
                                        marginBottom: 10,

                                    }}>Food Diary Entry</span>
                <Tabs type="card" className=' pairedImgRender'>
                    {tabs}
                </Tabs>
            </div>

        return renderImgList;
    }
    handleData(readingObj){
        const readingValue = readingObj.blood_glucose ? readingObj.blood_glucose.value : '';
        const readingDate = readingObj.date;
        const beforeMeal = readingObj.beforeMeal;
        const className =  'logbookCell'+' '+(readingSeverityMap[readingObj.severity]||'');
        const mgdlValue = convertBG_mmolTomgdl(readingValue)!=0 ? convertBG_mmolTomgdl(readingValue):'';
        const unit =  mgdlValue>0 ? ' mg/dL':'';
        const { hadInsulin,hadMedicine,hadActivity,moodTags} = readingObj;
        const userNotes = _.get(readingObj,'measure.user_notes',null);
        return {
            mgdlValue,
            unit,
            className,
            readingDate,
            beforeMeal,
            hadActivity,
            hadMedicine,
            hadInsulin,
            moodTags,
            userNotes
        }

    }

    renderTags(measures){
        const beforeMeasure = measures[0];
        const afterMeasure = measures[1];
        const beforeRender = this.renderSingleMeasureTags(beforeMeasure);
        const afterRender = this.renderSingleMeasureTags(afterMeasure);
        return <div style={styles.divStyle} className='col-lg-12'>
            <div style={styles.patientNoteTitle}>
                Tags
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>Before Meal:</p> { beforeRender }
                </div>
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>After Meal:</p> { afterRender }
                </div>
            </div>
        </div>
    }

    renderPatientNotes = (notes)=>{
        {/*<div className='col-lg-12' style={{textAlign:'start',marginLeft:0,fontSize:14}}>*/}
        {/*<span style={{fontWeight:600,fontSize:14}}>Patient Notes (Measurement)</span>*/}
        {/*<div className='row'>*/}
        {/*<div className='col-lg-12' style={{fontSize:14,fontWeight:500,paddingLeft: 15,paddingRight: 15,display:'flex'}}>*/}
        {/*<p style={styles.pStyle}>Before Meal:</p> {notes[0]}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className='row' >*/}
        {/*<div className='col-lg-12' style={{fontSize:14,fontWeight:500,paddingLeft: 15,paddingRight: 15,display:'flex'}}>*/}
        {/*<p style={styles.pStyle}>After Meal:</p> {notes[1]}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        return <div style={styles.divStyle} className='col-lg-12'>
            <div style={styles.patientNoteTitle}>
                <span>Patient Notes (Measurement)</span>
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>Before Meal:</p> { notes[0] }
                </div>
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>After Meal:</p> { notes[1] }
                </div>
            </div>
        </div>
    }

    renderSingleMeasureTags(doc){
        let isEmpty = true;
        const keys =  Object.keys(tagsMap);
        _.forEach(keys,(k)=>{
            if(Array.isArray(doc[k])){
                isEmpty&=!(!!doc[k].length)
            }else{
                isEmpty&=!(doc[k]);
            }
        });
        let content='';
        if(isEmpty){
            content = <div style={{marginLeft:10}}>--</div>;
        }else{
            content = keys.map((k,i)=>{
                if(doc[k]) {
                    let content = <span style={styles.tagStyle} key={i}>{tagsMap[k]}</span>
                    return content;
                }
            })
        }
        return<div style={{marginLeft:10}}>{ content }</div>
    }

    renderSignleMeasureMoods(doc){
        const { moodTags } = doc;
        return  <span style={styles.moodStyle}>
                    {
                        moodTags.length>0 ? moodTags.join(', ') : '--'
                    }
                </span>

    }

    renderMoodTags(measures){
        const beforeMeasure = measures[0];
        const afterMeasure = measures[1];
        const beforeRender = this.renderSignleMeasureMoods(beforeMeasure);
        const afterRender = this.renderSignleMeasureMoods(afterMeasure);

        return <div style={styles.divStyle} className='col-lg-12'>
            <div style={styles.patientNoteTitle}>
                Mood and Symptoms
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>Before Meal:</p> { beforeRender }
                </div>
                <div style={{display:'flex',marginTop:10}}>
                    <p style={styles.pStyle}>After Meal:</p> { afterRender }
                </div>
            </div>
        </div>
    }

    render(){

        const { title,date,data,foodLogList, timezone } = this.props;
        const mealType = title&&title.split(' ')[1].toUpperCase();
        const images = _.filter(foodLogList,(d)=>d.mealType==mealType);
        const measures =[];
        measures[0] = this.handleData(data[0]);
        measures[1] = this.handleData(data[1]);
        let diff = 0;
        const renderMeasures = _.map(measures,(m,index)=> {
             const readingContainer = m.className.split(' ')[1] + " " + 'readingContainer paired';
             const beforeMealTitle = m.beforeMeal ? 'Before Meal' :'After Meal';
             const measureTime = moment(m.readingDate).format('HH:mm');
             diff = Math.abs(m.mgdlValue-diff);
             return <div className='col-lg-4' key={index}>
                        <span style={{fontWeight:'bold',fontSize:14,marginRight:5}}>{beforeMealTitle}</span>
                        <span style={{fontSize:14}}>{measureTime}</span>
                        <div className={readingContainer}>
                        <span style={{fontWeight: 'bold'}}>{m.mgdlValue}</span><span style={{marginLeft: 5}}> {m.unit}</span>
                        </div>
                    </div>
        })

        let notes =[];
        notes[0] = measures[0].userNotes ? <span style={{fontWeight:'normal'}}> { measures[0].userNotes }</span> : <span style={{color:'#c5c5c5',paddingLeft:10,fontWeight:'normal'}}>No Comment</span>;
        notes[1] = measures[1].userNotes  ? <span style={{fontWeight:'normal'}}> { measures[1].userNotes }</span> : <span style={{color:'#c5c5c5',paddingLeft:10,fontWeight:'normal'}}>No Comment</span>;
        return <div className='row' style={{ mingHeight:'400px',textAlign:'start', marginLeft:0,marginRight:0 }}>
            <div className='col-lg-12'
                 style={{fontWeight:'550',
                     textAlign:'start',
                     display:'flex',alignItems:'center',
                     fontSize:14
                 }}>
                <span>{title}</span>
            </div>
            <div style={{color:'#303639',fontSize:14,color:'#303639'}} className='col-lg-12'>
                { helpers.getMeasureTimeDisplayText(_.get(data, '0'), 'MM/DD/YYYY ddd') }
            </div>
            { renderMeasures }
            <div className='col-lg-3'>
                <span style={{fontWeight:600,fontSize:14}}>Difference</span>
                <div className='readingDiff'>
                    <span style={{fontWeight: 'bold'}}>{diff}</span><span style={{marginLeft: 5}}>mg/dL</span>
                </div>
            </div>
            {this.renderTags(measures)}
            {this.renderMoodTags(measures)}
            {this.renderPatientNotes(notes)}
            {/*<div className='col-lg-12' style={{textAlign:'start',marginLeft:0,fontSize:14}}>*/}
                {/*<span style={{fontWeight:600,fontSize:14}}>Patient Notes (Measurement)</span>*/}
                {/*<div className='row'>*/}
                   {/*<div className='col-lg-12' style={{fontSize:14,fontWeight:500,paddingLeft: 15,paddingRight: 15,display:'flex'}}>*/}
                       {/*<p style={styles.pStyle}>Before Meal:</p> {notes[0]}*/}
                   {/*</div>*/}
               {/*</div>*/}
               {/*<div className='row' >*/}
                   {/*<div className='col-lg-12' style={{fontSize:14,fontWeight:500,paddingLeft: 15,paddingRight: 15,display:'flex'}}>*/}
                       {/*<p style={styles.pStyle}>After Meal:</p> {notes[1]}*/}
                   {/*</div>*/}
               {/*</div>*/}
            {/*</div>*/}
            {this.renderFoodLogImg(images)}
            <div  style={{textAlign:'start'}}>
                <Button  onClick={()=>this.props.closeModal()} type="primary"
                         className='logBookCloseButton'
                >
                    Close
                </Button>
            </div>
        </div>
    }
}

const withData = graphql(foodLogList, {
    options: (ownProps) => {
        const { date,patientId,title } = ownProps;
        const fromDate = moment(date).startOf('day');
        const toDate = moment(date).endOf('day');
        const mealType = title&&title.split(' ')[1].toUpperCase();
        const  variables = { };
        variables.filters = {
            memberId:patientId,
            fromDate,
            toDate,
            mealType
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,foodLogList } = data;
        if(foodLogList&&foodLogList.data) {
            return {
                foodLogList:foodLogList.data,
                loading,
            }
        }
        return {
            loading
        }
    }
})

const mapToDispatch = (dispatch)=> {
    return {
        closeModal:()=>dispatch(closeModal()),
    }
}

export default compose(connect(null,mapToDispatch),withData)(PairedMeasuresComponent);
