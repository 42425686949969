import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, Form, Card, Spin } from 'antd';
import ReferralCodeConditionContainer from '../container/ReferralCodeConditionContainer';
import { editUserMutation, upsertPatientReferralMutation } from '../API/mutation';
import { getConditionsValue, getIcdCodesValue, getProgramsValue, getVitalsValue, getBillableIcdCodesValue } from '../helper/initialValueHelper';
import { getDisabledProgramKeys, getValue, getVitalsAndPrograms, onlyHasHLD, getPatientConditions } from '../helper';
import renderIneligibleOverlay from '../helper/renderIneligibleOverlay';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import VitalsAndProgramsComponent from './VitalsAndProgramsComponent';
import { PROGRAM_ELIGIBILITY_KEYS } from '../fields/ProgramEligibility';
import { GQLHelper } from '../../../lib/utils';

const ReferralProgramEligibility = ({ FormWrapper, user, form, onSubmitDone, patientReferral, disabled, openErrorModal, goNextStep, formWrapperProps }) => {
  const { validateFields, getFieldsValue } = form;
  const fieldsValue = getFieldsValue();

  const [isLoading, setIsLoading] = useState(true);
  const [initialFieldsValue, setInitialFieldsValue] = useState({
    icdCodesInitialValue: null,
    billableIcdCodesInitialValue: null,
    conditionsInitialValue: null,
    vitalsInitialValue: null,
    eligibleProgramsInitialValue: null
  });

  const initialValues = useMemo(() => {
    const selectedConditions = getConditionsValue(patientReferral, user);
    const selectedIcdCodes = getIcdCodesValue(patientReferral, user);
    const selectedBillableIcdCodes = getBillableIcdCodesValue(patientReferral);
    const selectedVitals = getVitalsValue(patientReferral);
    const selectedPrograms = getProgramsValue(patientReferral);
    const diagnosisSource = getValue(patientReferral.diagnosisSource) || DIAGNOSIS_SOURCE.CONDITION;
    const patientConditions = getPatientConditions(diagnosisSource, selectedConditions, selectedBillableIcdCodes);
    const hasHldOnly = onlyHasHLD(patientConditions);
    const isConfirmed = !_.isEmpty(patientConditions);
    return {
      selectedConditions: patientConditions,
      selectedIcdCodes,
      selectedBillableIcdCodes,
      selectedVitals,
      selectedPrograms,
      disabledPrograms: getDisabledProgramKeys(patientConditions),
      isConfirmed,
      diagnosisSource,
      patientConditions,
      hasHldOnly,
    };
  }, []);

  useEffect(() => {
    const { eligiblePrograms, vitals } = getVitalsAndPrograms(
      initialValues.patientConditions,
      initialValues.disabledPrograms
    );

    setInitialFieldsValue({
      icdCodesInitialValue: initialValues.selectedIcdCodes,
      billableIcdCodesInitialValue: initialValues.selectedBillableIcdCodes,
      conditionsInitialValue: initialValues.selectedConditions,
      vitalsInitialValue: initialValues.selectedVitals === null ? vitals : initialValues.selectedVitals,
      eligibleProgramsInitialValue: initialValues.selectedPrograms === null ? eligiblePrograms : initialValues.selectedPrograms
    });

    setIsLoading(false);
  }, []);

  const [isConfirmed, setIsConfirmed] = useState(initialValues.isConfirmed);
  const [disabledPrograms, setDisabledPrograms] = useState(initialValues.disabledPrograms);
  const selectedDiagnosisSource = getValue(fieldsValue.diagnosisSource) || initialValues.diagnosisSource;
  const selectedConditions = getValue(fieldsValue.conditionsList, initialFieldsValue.conditionsInitialValue);
  const selectedBillableIcdCodes = getValue(fieldsValue.billableHealthConditions, initialFieldsValue.billableIcdCodesInitialValue);
  const patientConditions = getPatientConditions(selectedDiagnosisSource, selectedConditions, selectedBillableIcdCodes);
  const hasHldOnly = onlyHasHLD(patientConditions);
  const hasNoConditionFromIcdCode = selectedDiagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE && _.isEmpty(patientConditions);
  const selectedPrograms = getValue(fieldsValue.eligiblePrograms, initialFieldsValue.eligibleProgramsInitialValue);
  const selectedVitals = getValue(fieldsValue.recommendVitalsList, initialFieldsValue.vitalsInitialValue);
  const hasSelectedPrograms = !_.isEmpty(selectedPrograms);
  const hasSelectedVitals = !_.isEmpty(selectedVitals);
  const showButton = isConfirmed && hasSelectedVitals && hasSelectedPrograms;

  const handleConditionSelect = (patientConditions) => {
    const disabledPrograms = getDisabledProgramKeys(patientConditions);
    const { eligiblePrograms, vitals } = getVitalsAndPrograms(patientConditions, disabledPrograms.map(x => x.key));
    setDisabledPrograms(disabledPrograms);
    form.setFieldsValue({
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: vitals,
    });
  }

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return goNextStep();
    }
    validateFields(async (err, values) => {
      if (!err) {
        const { id: memberId } = user;
        const patientConditions = getPatientConditions(values.diagnosisSource, values.conditionsList, values.billableHealthConditions);
        const variables = {
          patientId: memberId,
          recommendedVitals: values.recommendVitalsList,
          eligiblePrgorams: values.eligiblePrograms,
          conditionsToMonnitor: patientConditions,
          diagnosisSource: values.diagnosisSource,
          healthConditions: values.healthConditions,
          billableHealthConditions: values.billableHealthConditions,
        };
        try {
          await upsertPatientReferralMutation(variables);
          if (values.diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE) {
            await editUserMutation({
              id: memberId,
              memberProfile: {
                birthday: _.get(user, 'profile.birthday'),
                healthConditions: values.healthConditions,
                billableHealthConditions: values.billableHealthConditions,
              }
            });
          }
          onSubmitDone();
        } catch (e) {
          console.error(e);
          openErrorModal(GQLHelper.formatError(e));
        }
      }
    });
  }, [validateFields, onSubmitDone]);

  return isLoading ? <Spin />
    : (
      <FormWrapper
        header="Patient Conditions*"
        className="program-eligibility"
        onSubmit={handleSubmit}
        buttons={showButton && <Button type="primary" htmlType="submit" className="new-style">Next</Button>}
        {...formWrapperProps}
      >
        <ReferralCodeConditionContainer
          form={form}
          handleConditionSelect={handleConditionSelect}
          disabled={disabled}
          diagnosisSourceInitialValue={initialValues.diagnosisSource}
          icdCodesInitialValue={initialFieldsValue.icdCodesInitialValue}
          billableIcdCodesInitialValue={initialFieldsValue.billableIcdCodesInitialValue}
          conditionsInitialValue={initialFieldsValue.conditionsInitialValue}
          isConfirmed={isConfirmed}
          setIsConfirmed={setIsConfirmed}
          selectedConditions={selectedConditions}
          selectedBillableIcdCodes={selectedBillableIcdCodes}
        />
        <Card
          style={{
            position: 'relative',
            boxShadow: '0px 0px 10px -4px rgba(0,0,0,0.7)',
            margin: '0 10px',
            display: isConfirmed ? 'block' : 'none'
          }}
        >
          {(hasNoConditionFromIcdCode || hasHldOnly) && renderIneligibleOverlay(hasNoConditionFromIcdCode)}
          <VitalsAndProgramsComponent
            form={form}
            disabled={disabled}
            disabledPrograms={disabledPrograms}
            {...initialFieldsValue}
          // vitalsInitialValue={}
          // eligibleProgramsInitialValue={}
          />
        </Card>
      </FormWrapper>
    );
}

export default Form.create({
  name: 'referralProgramEligibility',
  onValuesChange: (props) => {
    props.setHasChange();
  }
})(ReferralProgramEligibility);
