import React from 'react';
import { Button,Row,Col,DatePicker } from 'antd';
import {graphql,compose} from "react-apollo/index";
import visitListQuery from '../../graphql/visitListWithEp';
import VisitsWithVideoComponent from 'modulesAll/VideoChat/component/VisitsWithVideoComponent'
import { IHLoading } from 'ihcomponent';
import CreateVisitInDashboardContainer from "../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer";
import { VISIT_COUNT_MAX } from '../../../lib/constants';
const { RangePicker } = DatePicker;

const ScheduleVisitsContainer = class  extends React.Component{
    constructor(){
        super();
        this.state = {
            createContainer: false
        }
    }

    showCreate=(flag)=>{
        this.setState({
            createContainer: flag
        });
    }

    renderRangePicker = ()=>{
        const { defaultStartDay,defaultEndDay,onChangeRangePicker } = this.props;
        return <RangePicker style={{ marginRight:20,float:'right' }}
                            defaultValue={[defaultStartDay, defaultEndDay]}
                            onChange={(dateRangeToFilter)=>onChangeRangePicker(dateRangeToFilter)}/>
    }

    cancelCreate=()=>{
        const { refetchVisitList } = this.props;
        this.setState({
            createContainer: false
        },()=>{
            refetchVisitList();
        });
    }

    renderCreateButton = ()=>{
        return <Button onClick={()=>this.showCreate(true)}  type='primary' style={{ float:'right' }}>Create Visit</Button>
    }

    render(){
        const { cancelCreate } = this;
        const { createContainer } = this.state;
        const { visitList, refetchVisitList,loading } = this.props;
        if(loading){
            return <IHLoading/>
        }
        return <Row style={{ padding: 20 }}>
                <Col span={12}><h4>MySchedule</h4></Col>
                <Col span={9}>{ this.renderRangePicker() }</Col>
                <Col span={3}>{ this.renderCreateButton() }</Col>
                <Col span={24}><VisitsWithVideoComponent visitList={visitList} refetchVisitList={refetchVisitList} hideStatus={true}/></Col>
                <Col span={24}>{ createContainer ? <CreateVisitInDashboardContainer onCancel={ cancelCreate } curTab={'Today'}/> : '' }</Col>
              </Row>
    }
};
const visitList = graphql(visitListQuery,{
    options:(ownProps)=>{
        const { dateRangeToFilter,defaultEndDay,defaultStartDay } = ownProps;
        let startDate = _.get(dateRangeToFilter, '0', defaultStartDay);
        let endDate = _.get(dateRangeToFilter, '1', defaultEndDay);
        const variables = {
            count: VISIT_COUNT_MAX
        };
        variables.appointmentFrom = startDate;
        variables.appointmentTo = endDate;
        const { provider } = {
            provider: _.reduce(
                JSON.parse(sessionStorage.getItem('doctorName')),
                (res, d) => {
                    res.push(d.id);
                    return res;
                },
                []
            )
        };
        variables.providerIds = provider;
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props:({ ownProps, data })=>{
        const { loading, error, visitList,refetch } = data;
        if (error) {
            return { error };
        }
        if (loading) return { loading };
        let todayIndex = 1;
        let indexVisitList = _.map(visitList.data, v =>
            Object.assign(v, { index: todayIndex++ })
        );
        return {
            visitList:indexVisitList,
            refetchVisitList:refetch
        }
    }
})
export  default compose(visitList)(ScheduleVisitsContainer);
