import Assessment from './Assessment';
import Measure from './Measure';

export default `
  ... on BPResult {
    systolic_blood_pressure
    diastolic_blood_pressure
    heart_rate
  }
  ... on BGResult {
    blood_glucose
    beforeMeal
    mealType
  }
  ... on HSResult {
    body_weight,
    body_weight_change,
    body_weight_change_baseline
  }
  ... on HRResult {
    heart_rate
  }
  ... on TMResult {
    body_temperature
  }
  ... on POResult {
    blood_oxygen
  }
  ... on ASSESSMENTResult {
    assessment {
      ${Assessment}
    }
    completed
  }
  manualInput
`;

export const ResultForAlerts = `
  id
  date
  type
  severity
  taskStatus
  careStatus
  hadMedicine
  hadActivity
  hadInsulin
  moodTags
  caredBy {
    id
    profile {
      fullName
    }
  }
  user {
    id
    profile {
      fullName
      ...on MemberProfile{
          height
      }
    }
    identification {
      value
    }
  }
  enrolledProgram {
    id
    name
    tasks {
      id
      repeat
      type
    }
  }
  measure {
    user_notes
    imageUrl
    ${Measure}
    
  }
  manualInput
  ... on BPResult {
    systolic_blood_pressure
    diastolic_blood_pressure
    heart_rate
    arrhythmia
  }
  ... on BGResult {
    type
    beforeMeal
    mealType
    blood_glucose
  }
  ... on HSResult {
    body_weight,
    body_weight_change,
    body_weight_change_baseline
  }
  ... on HRResult {
    heart_rate
  }
  ... on TMResult {
    body_temperature
  }
  ... on POResult {
    blood_oxygen
  }
  ... on ASSESSMENTResult {
    assessment {
      _id
      name
    }
  }
  threshold {
    measure
  }
  repeat
  schedule {
    calendar
    target
    start
    end
    beforeMeal
    mealType
  }
`;

export const BGStat = `
  fasting {
    count
    average
    range
    inRangePercentage
  }
  beforeMeal {
    count
    average
    range
    inRangePercentage
  }
  afterMeal {
    count
    average
    range
    inRangePercentage
  }
  bedtime {
    count
    average
    range
    inRangePercentage
  }
  overnight {
    count
    average
    range
    inRangePercentage
  }
  bedtimeOvernightHypo {
    count
    average
    range
    inRangePercentage
  }
  criticalLow {
    count
    average
    range
    inRangePercentage
  }
  criticalHigh {
    count
    average
    range
    inRangePercentage
  }
`;

export const BPStat = `
  all {
    count
    average
    range
    inRangePercentage
  }
  overnight {
    count
    average
    range
    inRangePercentage
  }   
  morning {
    count
    average
    range
    inRangePercentage
  }
  afternoon {
    count
    average
    range
    inRangePercentage
  }
  evening {
    count
    average
    range
    inRangePercentage
  }
  low {
    count
    average
    range
    inRangePercentage
  }
  normal {
    count
    average
    range
    inRangePercentage
  }
  elevated {
    count
    average
    range
    inRangePercentage
  }
  hypertension1 {
    count
    average
    range
    inRangePercentage
  }
  hypertension2 {
    count
    average
    range
    inRangePercentage
  }
  criticalHigh {
    count
    average
    range
    inRangePercentage
  }
  arrhythmia {
    count
    average
    range
    inRangePercentage
  }
  pulsePressure {
    count
    average
    range
    inRangePercentage
  }
`;

export const POStat = `
  all {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  overnight {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  morning {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  afternoon {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  evening {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  normal {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  low {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
  criticalLow {
    count
    average
    range
    perfusionIndexAverage
    pulseRateAverage
  }
`;
