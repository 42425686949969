import React from 'react';
import AccumulatedBillableTimeComponent from "../component/AccumulateddBillableTimeComponent";
import {compose} from "react-apollo/index";
import { billableTimeQuery } from '../API/index';
const AccumulatedBillableTimeContainer = class extends React.Component{

    render() {
        const { loading,billableTime={},refetchBillabelTimeToday } = this.props;
        const { totalEnrTime, totalUnenrTime } = billableTime;
        return <div>
                <AccumulatedBillableTimeComponent header={'Time Spent on Enrolled Patient Today'} loading={ loading } billableTime={totalEnrTime||0} refetchBillabelTimeToday={refetchBillabelTimeToday}/>
                <AccumulatedBillableTimeComponent header={'Time Spent on Unenrolled Patients Today'} loading={ loading } billableTime={totalUnenrTime||0} refetchBillabelTimeToday={refetchBillabelTimeToday}/>
        </div>
    }
}

export default compose(billableTimeQuery)(AccumulatedBillableTimeContainer);
