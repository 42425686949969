import { UserName } from './User';

export const Question = `
  _id
  category
  type
  text
  options {
    label
    meaning
  }
  answer {
    content
    other
  }
`;

export const TaskAssessment = `
  id
  _id
  nameKey
  version
  name
  description
  questions{
    ${Question}
  }
`;

export default `
  _id
  id
  name
  nameKey
  version
  description
  healthCondition
  type
  status
  organizationId
  createdAt
  updatedAt
  modifiedAt
  questions {
    ${Question}
  }
`;

export const AssessmentList = `
  _id
  id
  name
  nameKey
  description
  healthCondition
  type
  status
  createdAt
  updatedAt
  modifiedAt
`;

export const LightAssessment = `
  _id
  id
  name
  nameKey
  version
  description
  healthCondition
  type
  status
  organizationId
`;
