import React from 'react';
import { useCellularDevicesContext } from './CellularDevicesContext';

export const makeCellularDeviceContextConsumer = (Component) => {
  const Consumer = (props) => {
    const cellularDeviceContextValue = useCellularDevicesContext();

    return (
      <Component
        {...props}
        cellularDeviceContextValue={cellularDeviceContextValue}
      />
    );
  };

  return Consumer;
};
