import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Select, Button, Tooltip } from 'antd';
import chatHelper from '../helper/chatPopoverHelper';

const ChatMessageTagPopover = props => {
  const { currentUser, patientCareTeamMembers, messageId, tagObj } = props;
  const { tagged, taggedto, read } = tagObj || {};
  const [newTaggedTo, setNewTaggedTo] = useState('');

  const currentUserId = _.get(currentUser, 'id');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {
        tagged && read && _.isEqual(chatHelper.decodeUserId(currentUserId), taggedto) &&
        <Tooltip
          overlayClassName='chat-tag-mark-as-unread-tooltip'
          title='Mark as unread'
        >
          <img
            // className={translatedText ? 'disabled' : ''}
            src='/image/tag-mark-as-unread.svg'
            // onClick={handleTranslate}
            style={{ marginRight: 8, filter: 'brightness(1.3)' }}
            onClick={() => chatHelper.toggleMarkTagMessage(
                            tagObj, 
                            () => chatHelper.showMessage('success', 'Message marked as unread!')
                          )}
          />
        </Tooltip>
      }
      <Popover
        overlayClassName='chat-tag-popover'
        trigger='click'
        placement='top'
        content={(
          <div className='chat-tag-popover-content-wrapper'>
            <span>Tag a person to view this message</span>
            <Select
              style={{ marginBottom: 50 }}
              onChange={setNewTaggedTo}
            >
              {
                _.map(patientCareTeamMembers, ({ user }) => {
                  const { id, profile = {} } = user || {};
                  return <Select.Option
                    key={id}
                    value={id}
                  >
                    {profile.fullName || '--'}
                  </Select.Option>;
                })
              }
            </Select>
            <Button
              type='primary'
              size='small'
              onClick={() => chatHelper.handleClickTag(messageId, currentUserId, newTaggedTo)}
            >
              Tag
            </Button>
          </div>
        )}
        destroyTooltipOnHide
        {...tagged ? { visible: false } : null}
      >
        <Tooltip 
          overlayClassName='chat-tag-popover-tooltip'
          title={tagged ? 'Message has already been tagged' : 'Tag people to view'}
        >
          <img 
            src='/image/person-tag.svg' 
            className={tagged ? 'disabled' : ''}
          />
        </Tooltip>
      </Popover>
    </div>
  );
};

ChatMessageTagPopover.propTypes = {
  messageId: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  patientCareTeamMembers: PropTypes.array.isRequired,
  tagObj: PropTypes.object.isRequired
};

export default ChatMessageTagPopover;