import gql from 'graphql-tag'

export default gql`
    mutation setDateForEnrolledProgram(
        $id:EID!,
        $startDate:Date,
        $endDate:Date
    ){
        setDateForEnrolledProgram(
            id: $id,
            startDate: $startDate,
            endDate: $endDate
        ){
            id
            startDate,
            endDate
        }

    }
`
