import { ELIGIBLE_PROGRAMS_ENUM, PROGRAMS_DISABLED_REASON_MAP, VITAL_TYPE_ENUM, STATUS_ENUM } from '../constant';
import { CCM_CONDITION_TO_ICD, CONDITIONS_TO_VITALS, CONDITION_LIST, RPM_CONDITION_TO_ICD, SMARTPHONE_SKILL_ENUM, DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import { getConditionsValue, getStatuses } from './initialValueHelper';
import {checkIsInRole} from "../../../lib/helpers/role-helpers";
import React  from 'react';
import {Icon, message, Modal} from "antd";
import { doesOrgHaveCCM, doesOrgHaveRPM, doesOrgHaveMNT, doesOrgHaveValueBased} from '../../../lib/utils';
import { upsertPatientReferralMutation } from '../API/mutation';
import remoteEnrollmentApis from '../../remoteEnrollment/API';

export const recommendVitalsList = ()=>{

}

export const getValidICDCodes = (codesArray, selectedCodes) => {
  const codes = new Set();
  for (const selectedCode of selectedCodes) {
    // Check selected code
    const upperCaseCode = (selectedCode || '').toUpperCase();
    for (const _code of codesArray) {
      const code = (_code || '').toUpperCase();
      const startWith = !!code.endsWith('.X');
      const parsedCode = startWith ? code.substr(0, code.length - 1) : code;
      if (startWith && upperCaseCode.startsWith(parsedCode)) {
        codes.add(upperCaseCode);
      } else if (upperCaseCode === parsedCode) {
        codes.add(upperCaseCode);
      }
    }
  }
  return Array.from(codes);
}

export const getRadioValue = (value) => {
  return value === true ? 1 : (value === false ? 0 : undefined);
}

export const getBinaryRadioValue = (value) => {
  return value === undefined ? undefined : !!value;
}

export const isMNTQualified = (patientReferral) => {
  const c = getConditionsValue(patientReferral) || [];
  // return c.includes('DM') || (c.includes('HLD') && (c.includes('HTN') || c.includes('Obesity'))) || (c.includes('HTN') && c.includes('Obesity'));
  return c.includes('DM');
};

export const isMNTVisible = (patientReferral = {}) => {
  const isQualified = isMNTQualified(patientReferral);
  const orgHasMNT = doesOrgHaveMNT();
  return isQualified && orgHasMNT;
};

export const getValue = (v1, v2) => {
  return v1 === undefined ? v2 : v1;
}

export const getSmartphoneOrgPermissions = () => {
  const { orgPermissions } = JSON.parse(sessionStorage.getItem('currentUserConfigPermissions')) || {};
  return {
    supportClear: !!_.get(orgPermissions, 'supportClear'),
    supportLoanerPhone: !!_.get(orgPermissions, 'supportLoanerPhone'),
  };
}

export const getKeepOwnDevicesOrgPermission = ()=>{
  const { orgPermissions } = JSON.parse(sessionStorage.getItem('currentUserConfigPermissions')) || {};
  return {
    keepOwnDevices: _.get(orgPermissions,'keepOwnDevices')
  }
}

export const getAnotherCCMProviderOrgPermissions = () => {
  const { otherCCMProvider } = JSON.parse(sessionStorage.getItem('currentUserConfigPermissions')) || {};
  return !!otherCCMProvider;
}

export const getBPEligibility = (conditions) => {
  return _.intersection(conditions, ['HTN', 'CKD']).length > 0;
}

// export const getPatientEnrollmentEligibility = (patientReferral) => {
//   const hasSmartphone = getHasSmartPhoneValue(patientReferral);
//   const { supportClear, supportLoanerPhone } = getSmartphoneOrgPermissions();
//   if (supportLoanerPhone) {

//   } else {
//     if (hasSmartphone) return true;
//     const isBPEligible = getBPEligibility(patientReferral);
//     if (!isBPEligible) return false;
//     if (!supportClear) return false;
//   }
// }

export const onlyHasHLD = (conditionList) => conditionList && conditionList.length === 1 && conditionList[0] === CONDITION_LIST.HLD;

export const onlyOneExceptHLD = (conditionList) => conditionList && conditionList.length === 1 && conditionList[0] !== CONDITION_LIST.HLD;

export const getDisabledProgramKeys = (patientConditions, reasonMap = PROGRAMS_DISABLED_REASON_MAP) => {
  const keys = [];
  if (onlyOneExceptHLD(patientConditions)) {
    keys.push({ key: 'CCM', reason: reasonMap.onlyOneExceptHLD, });
  }
  return keys;
}

export const getEligibleProgramsBasedOnConditions = (patientConditions, disabledProgramKeys) => {
  // setting the eligibleProgram 
  let newEligibleProgramsValue = [];
  const rpmIntersection = _.intersection(_.keys(RPM_CONDITION_TO_ICD), patientConditions);
  const ccmIntersections = _.intersection(_.keys(CCM_CONDITION_TO_ICD), patientConditions);
  if (rpmIntersection.length === 1) {
    newEligibleProgramsValue = ['RPM','VALUE_BASED'];
  }
  if (rpmIntersection.length > 0 && ccmIntersections.length > 1) { 
    newEligibleProgramsValue = ['CCM', 'RPM'];
  }

  newEligibleProgramsValue = newEligibleProgramsValue.filter(v => !disabledProgramKeys.includes(v));
  return newEligibleProgramsValue;
}

export const getVitalsBasedOnConditions = (
  conditions,
) => { 
  const conditionVitals = _.map(conditions, c => CONDITIONS_TO_VITALS[c] || null).filter(v => v !== null);
  const vitalsSet = new Set(conditionVitals);
  return Array.from(vitalsSet);
};

export const getVitalsAndPrograms = (patientConditions, disabledPrograms) => {
  const newVitals = getVitalsBasedOnConditions(patientConditions);
  const newEligiblePrograms = getEligibleProgramsBasedOnConditions(patientConditions, disabledPrograms);
  let newEligibleProgramsFiltered = newEligiblePrograms.filter(x => !disabledPrograms.includes(x));
  const orgHasCCM = doesOrgHaveCCM();
  const orgHasRPM = doesOrgHaveRPM();
  const orgHasValueBased = doesOrgHaveValueBased();
  newEligibleProgramsFiltered = orgHasCCM ? _.intersection(newEligibleProgramsFiltered,['CCM']) : newEligibleProgramsFiltered;
  newEligibleProgramsFiltered = orgHasRPM ? _.intersection(newEligibleProgramsFiltered,['RPM']) : newEligibleProgramsFiltered;
  newEligibleProgramsFiltered = orgHasValueBased ? _.intersection(newEligibleProgramsFiltered,['VALUE_BASED']) :newEligibleProgramsFiltered;
  return {
    eligiblePrograms: newEligibleProgramsFiltered,
    vitals: newVitals,
  };
}

export const checkBpAndRpmOnly = (smartPhoneSkills, isBPEligible) => {
  const loanerPhoneConfig = getSmartphoneOrgPermissions();
  if (loanerPhoneConfig.supportLoanerPhone) {
    return smartPhoneSkills === SMARTPHONE_SKILL_ENUM.SMART_PHONE_NOT_PREFERRED;
  }
  if (loanerPhoneConfig.supportClear) {
    return isBPEligible;
  }
  return false;
}

export const noSmartPhoneEligible = (smartPhoneSkills) => {
  const loanerPhoneConfig = getSmartphoneOrgPermissions();
  if (loanerPhoneConfig.supportLoanerPhone) {
    return smartPhoneSkills === SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE;
  } else if (!loanerPhoneConfig.supportClear) {
    return true;
  }
  return false;
}

export const getDisabledAndRecommendations = (options) => {
  const { isReferred, isDeviceAccessible, smartPhoneSkills, isBPEligible, conditions, eligiblePrograms = [], vitals = [], hasAnotherCCMProvider, notSelectedPrograms, notSelectedVitals, userRole } = options;
  const isBpAndRpmOnly = checkBpAndRpmOnly(smartPhoneSkills, isBPEligible);
  const orgHasAnotherCCMProvider = getAnotherCCMProviderOrgPermissions();
  // const loanerPhoneConfig = getSmartphoneOrgPermissions();
  let referredDisabledPrograms = [];
  let referredDisabledVitals = [];
  let nonReferredDisabledPrograms = [];
  let bpAndRpmOnlyDisabledPrograms = [];
  let bpAndRpmOnlyDisabledVitals = [];
  let anotherCCMDisabledPrograms = [];
  let disabledPrograms = [];
  let disabledVitals = [];

  if (isReferred && ['MA'].includes(userRole)) {
    referredDisabledPrograms = notSelectedPrograms;
    referredDisabledVitals = notSelectedVitals;
  } else {
    nonReferredDisabledPrograms = getDisabledProgramKeys(conditions);
  }
  if (isDeviceAccessible === false && isBpAndRpmOnly) {
    bpAndRpmOnlyDisabledPrograms = _.keys(ELIGIBLE_PROGRAMS_ENUM).filter(v => v !== ELIGIBLE_PROGRAMS_ENUM.RPM);
    bpAndRpmOnlyDisabledVitals = _.keys(VITAL_TYPE_ENUM).filter(v => v !== VITAL_TYPE_ENUM.BP);
  } 
  if (isDeviceAccessible && orgHasAnotherCCMProvider && hasAnotherCCMProvider) {
    anotherCCMDisabledPrograms = [ELIGIBLE_PROGRAMS_ENUM.CCM];
  }

  disabledPrograms = _.union(referredDisabledPrograms, nonReferredDisabledPrograms, bpAndRpmOnlyDisabledPrograms, anotherCCMDisabledPrograms);
  disabledVitals = _.union(referredDisabledVitals, bpAndRpmOnlyDisabledVitals);

  const newEligiblePrograms = eligiblePrograms.filter(v => !disabledPrograms.includes(v));
  const newVitals = vitals.filter(v => !disabledVitals.includes(v));
  // console.log({ disabledPrograms, disabledVitals, newEligiblePrograms, newVitals, eligiblePrograms });
  return {
    vitals: newVitals,
    eligiblePrograms: newEligiblePrograms,
    disabledPrograms: disabledPrograms.map(v => ({ key: v })),
    disabledVitals: disabledVitals.map(v => ({ key: v })),
  };
}


export const checkEnrollStatus = (patientReferral,hideCompleted = false)=>{
  const enrollStatus = _.get(patientReferral,'status.enrollStatus');
  const isEnrolled = enrollStatus ==='COMPLETED';
  let isMA = checkIsInRole(['MA']);
  let enrollDiv = '';
  let allFinished = true;
  if(isEnrolled){
    let { status } = patientReferral;
    let needAllFinishedToShowChecked = ['patientConsentEnroll', 'confirmDevicesEnroll', 'scheduleInitVisitEnroll', 'enrollStatus'];
    allFinished = needAllFinishedToShowChecked.reduce((flag,cur)=>{ flag = flag&&(status[cur]==='COMPLETED');return flag },true);
    let icon = allFinished && !hideCompleted?  <Icon type="check-circle" theme="filled" style={{ marginLeft: 5,color:'#00C8BC' }}/> : <Icon type="info-circle" theme="filled" style={{color:"#DA6453",marginLeft: 5}} />;
    enrollDiv =  <span style={{color: '#3177C9'}}>MA Enrolled{icon}</span>
  }
  return {
    content:enrollDiv,
    allFinished,
    isMA
  };
}

export const getReferredPrograms = (patientReferral) => {
  const { eligiblePrgorams, wantMNTReferral } = patientReferral || {};
  let referredPrograms = _.clone(eligiblePrgorams) || [];
  if(wantMNTReferral)
    referredPrograms.push('MNT');

  return _.uniq(referredPrograms);
};

export const updateReferStatus = async (props, cb) => {
  const { patientReferral, user, userRole, shouldUpdateCompletedReferStatus, refetchPatientReferral } = props
  try {
    const { id: memberId = ''} = user || {};
    // combining the icd codes in user with the new onces
    const isDoctorRoles = ['MD','MA'];
    const referStatus = _.get(patientReferral, 'status.referStatus');
      if (!referStatus) {
        // update remote enrollment
        await remoteEnrollmentApis.createRE({ memberId, doctorReferral: isDoctorRoles.includes(userRole)});
        await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'REFERRED' });
      }
      if(shouldUpdateCompletedReferStatus) {
        await upsertPatientReferralMutation({ patientId: memberId, status: { ...getStatuses(), referStatus: STATUS_ENUM.COMPLETED }});
      }
      if(referStatus !== STATUS_ENUM.COMPLETED) {
        message.success('Referral Complete. Keep going to enroll');
      }
      if(refetchPatientReferral) {
        await refetchPatientReferral();
      }
      if(cb) {
        cb();
      }
    } catch (error) {
      Modal.error({ 
        centered: true,
        title: 'Something went wrong submitting referral!',
        content: _.get(error, 'message') || '',
        okText: 'Try again',
        destroyOnClose: true,
        onOk: () => updateReferStatus(props, cb)
      });
    }
}

export const getPatientConditions = (diagnosisSource, conditions, billableIcdCodes) => {
  let patientConditions = [];
  if (diagnosisSource === DIAGNOSIS_SOURCE.CONDITION) return conditions;
  const codes = _.map(billableIcdCodes, 'code').filter(c => !!c);
  // otherCodes can't be mapped to any condition
  const conditionToIcdMap = _.omit(CCM_CONDITION_TO_ICD, 'otherCodes');
  _.map(conditionToIcdMap, (codesArray, condition) => {
    const codesForCondition = getValidICDCodes(codesArray, codes);
    if (codesForCondition.length) {
      patientConditions.push(condition);
    }
  });
  return patientConditions;
};
