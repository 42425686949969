
import gql from 'graphql-tag';

export  default gql`
query foodLogUnread($filters: FoodLogListOpts!){
  foodLogList(filters:$filters){
    pageInfo{
      total
    }
  }
}`
