import React, { useCallback } from 'react';
import VisitPropTypes from '../constants/propTypes';
import { Divider, Popover, Button, Icon, Row, Col, Checkbox, Form } from 'antd';
import { connect } from 'react-redux';

import { VISIT_CATEGORY_MAPPING } from '../constants';
import { getVisitTypeOptions } from '../../../lib/utils';
import { helpers as visitHelpers } from '../../visit/helpers';
import roleHelpers from '../../taskAssignment/helper';
import actions from '../actions';
import { roleNameMap } from '../../utils/constants/role';

const VisitAdvancedFilters = props => {
  const { form, selectedAdvancedFilters, setSelectedAdvancedFilters } = props;
  const { getFieldDecorator, getFieldsValue } = form;

  const renderAdvancedFilterOptions = useCallback(
    (label, key, options, getOptions, getOption) => (
      <Row>
        <Col className='visits-advanced-filters-group-item' span={24}>{label}</Col>
        <Col span={24}>
          {
            getFieldDecorator(key, {
              initialValue: _.get(selectedAdvancedFilters, key)
            })
            (<Checkbox.Group 
              options={_.map(getOptions(options), option => getOption(option, options))}
            />)
          }
        </Col>
      </Row>
  ), [selectedAdvancedFilters]);
  
  const providers = visitHelpers.filterDoctors();
  
  const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const teamsFromLoginUser = loginUser.team || [];
  const CAs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.CA]);
  const RDs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.RD]);
  const HCs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.HC]);
  // const getParitipantProviders = visitList => {
  //   let participantProviders = _.uniqBy(visitList,(v)=>_.get(v,'providers.0.id'));
  //   return _.map(participantProviders, pp =>{
  //     const p = _.get(pp,'providers.0');
  //     return {
  //       id: _.get(p, 'id'),
  //       fullName: _.get(p, 'profile.fullName')
  //     };
  //   });
  // };

  const hasFilters = !_.isEmpty(selectedAdvancedFilters) && 
                     _.some(selectedAdvancedFilters, filters => !_.isEmpty(filters));

  return <Popover
    // width 900px
    trigger='click'
    placement='bottomRight'
    overlayClassName='visits-advanced-filters'
    destroyTooltipOnHide
    onVisibleChange={visible => {
      if(!visible) form.resetFields();
    }}
    content={<div>
      <Row>
        <Col span={9}>
          <Row>
            <Col className='visits-advanced-filters-group' span={24}>
              Filter by visit
            </Col>
            <Col span={10}>
              {
                renderAdvancedFilterOptions(
                  'Category', 'filterByCategory', VISIT_CATEGORY_MAPPING,
                  options => _.keys(options),
                  (option, options) => ({ label: options[option], value: option })
                )
              }
            </Col>
            <Col span={14}>
              {
                renderAdvancedFilterOptions(
                  'Type', 'filterByType', getVisitTypeOptions(),
                  options => _.keys(options),
                  (option, options) => ({ label: options[option], value: option })
                )
              }
            </Col>
          </Row>
        </Col>
        {/* <Col offset={2} span={13}>
          <Row>
            <Col className='visits-advanced-filters-group' span={24}>
              Filter by patient info
            </Col>
            <Col span={12}>
              {
                renderAdvancedFilterOptions(
                  'Primary Provider', 'filterByPCP', providers,
                  options => options,
                  ({ id, fullName }) => ({ label: fullName, value: id })
                )
              }
            </Col>
            <Col span={10}>
              {
                renderAdvancedFilterOptions(
                  'Vitals', 'filterByVitals', VITALS_ORDER.concat(['NONE']),
                  options => options,
                  (option) => ({ 
                    label: _.includes(VITALS_ORDER, option) ? 
                      I18N.get(`vitalsAbbreviation.${option}`) : 'No Vitals (GP)',
                    value: option
                  })
                )
              }
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Divider />
      <Row>
        <Col className='visits-advanced-filters-group' span={24}>
          Filter by visit participant
        </Col>
        <Col span={8}>
          {
            renderAdvancedFilterOptions(
              'Provider', 'filterByMD', providers,
              options => options,
              ({ id, fullName }) => ({ label: fullName, value: id })
            )
          }
        </Col>
        <Col span={8}>
          {
            renderAdvancedFilterOptions(
              'CA', 'filterByCA', CAs,
              options => options,
              ({ id, fullName }) => ({ label: fullName, value: id })
            )
          }
        </Col>
        <Col span={8}>
          {
            renderAdvancedFilterOptions(
              'RD', 'filterByRD', RDs,
              options => options,
              ({ id, fullName }) => ({ label: fullName, value: id })
            )
          }
        </Col>
        <Col span={8}>
          {
            renderAdvancedFilterOptions(
              'HC', 'filterByHC', HCs,
              options => options,
              ({ id, fullName }) => ({ label: fullName, value: id })
            )
          }
        </Col>
      </Row>
      <Divider />
      <div id='visits-advanced-filters-buttons'>
        <Button 
          type='link'
          onClick={() => {
            form.resetFields();
            setSelectedAdvancedFilters({});
          }}
        >
          Clear
        </Button>
        <Button 
          type='primary' 
          onClick={() => {
            // TODO: check to remove undefined values
            setSelectedAdvancedFilters(getFieldsValue())
          }}
        >
          Ok
        </Button>
      </div>
    </div>}
  >
    <Button id='visits-advanced-filters-trigger' type='link'>
      <Icon type='filter' theme={hasFilters ? 'filled' : 'outlined'} />
      Advanced Filter
    </Button>
  </Popover>;
};

VisitAdvancedFilters.propTypes = VisitPropTypes.advancedFilters;

const FormWrapped = Form.create({})(VisitAdvancedFilters);

const mapDispatchToProps = dispatch => ({
  setSelectedAdvancedFilters: advancedFilters => 
                      dispatch(actions.setSelectedAdvancedFilters(advancedFilters))
});

const mapStateToProps = state => {
  const { selectedTabKey, selectedAdvancedFilters } = state.visitsPage;
  return {
    selectedAdvancedFilters: selectedAdvancedFilters[selectedTabKey] || {}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWrapped);