import React from 'react';
import { Radio, Spin, Form, Button, Row } from 'antd';
import moment from 'moment';
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils';
import editVisit from 'graphqlModule/mutation/editVisit';
import { upsertPatientMntProgramMutation } from '../../mntReferral/API';

const titleStyle = {
  fontWeight: 'bold',
  fontSize: 16,
  margin: '20px 0 10px 0',
};

const radioStyle = {
  display: 'block',
  height: 30,
  lineHeight: '30px',
};

const MNTReferralComponent = (props) => {
  const { getFieldDecorator } = props.form;
  const description = _.get(props.patientMntProgram, 'description');
  const referredAt = _.get(props.patientMntProgram, 'referredAt');
  const referredBy = _.get(props.patientMntProgram, 'referredBy.profile.fullName');
  const mntConditions = _.get(props.patientMntProgram, 'mntConditions', []);
  const codeMap = _.reduce(props.patientMntCodes, (res, { title, code }) => { res[code] = title; return res; }, []);
    
  const submit = () => {
    props.form.validateFieldsAndScroll(async (error, values) => {
      if (!error) {
        try {
          await upsertPatientMntProgramMutation({ 
            id: props.patientMntProgram.id,
            patientId: props.memberId,
            status: values.agreement ? 'AGREED' : 'REJECTED',
            visitId: props.visitId
          });
          if (props.visitType !== 'MNT' && values.agreement) {
            await Client.mutate({
              mutation: editVisit,
              variables: { id: props.visitId, type: 'MNT' },
              fetchPolicy: 'no-cache'
            });
          }
          props.afterSubmitSucc();
        } catch (e) {
          props.openErrorModal(GQLHelper.formatError(e));
        }
      }
    });
  }

  if (props.loading) {
    return <Spin />;
  }

  return (
    <React.Fragment>
      <div className='fix-header charting-header'>MNT Referral</div>
      <Row className='scrollable-content'>
        <div style={titleStyle}>
          <div>{referredBy} has referred this patient on {moment(referredAt).format('MM/DD/YYYY')}</div>
          <div>Does the patient agree to join MNT program?</div>
        </div>
        <Form.Item>
          {getFieldDecorator('agreement', {
            initialValue: _.get(props, 'patientMntProgram.status') === 'REFERRED' ? undefined : _.get(props, 'patientMntProgram.status') === 'AGREED',
            rules: [{
              required: true,
              message: 'Please select an option!',
            }],
          })(
            <Radio.Group>
              <Radio style={radioStyle} value={true}>Yes</Radio>
              <Radio style={radioStyle} value={false}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <div style={titleStyle}>Review the conditions that apply to MNT program.</div>
        {_.map(mntConditions, (v, i) => (
            <div key={i}>
              <div style={{ margin: '10px 0' }}>{v}: {codeMap[v]}</div>
              {i !== mntConditions.length - 1 && <hr style={{ margin: 0 }} />}
            </div>
        ))}
        <div style={{ marginTop: 25 }}>Note: {description}</div>
      </Row>
      <Row className='fix-footer' style={{ float: 'right', paddingTop: 10 }}>
        <Button onClick={submit} type={'primary'} disabled={props.disabled}>
          {props.submitButtonText || 'Complete'}
        </Button>
      </Row>
    </React.Fragment>
  );
}

export default Form.create({})(MNTReferralComponent)
