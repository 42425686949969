import types from '../constants/MainModal'

// Default State
const initState = {
  content: null,
  modalProps: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        content: action.content,
        modalProps: action.modalProps
      }
    case types.CLOSE_MODAL:
      return {
        ...state,
        content: false,
        modalProps: {}
      }
    default:
      return state
  }
}
