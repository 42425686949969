import React from 'react';
import VisitPropTypes from '../constants/propTypes';
import { connect } from 'react-redux';

import { Tabs, Row, Col, Button, notification } from 'antd';
import I18N from '../../I18N';
import VisitTabContainer from './VisitTabContainer';
import CreateVisitInDashboardContainer from '../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import WorkListContainer from '../../visitWorkList/container/workListContainer';
import VideoChatCenterComponent from '../../VideoChat/component/VideoChatCenterComponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import CreateAppointmentWithCalendarContainer from '../../remoteEnrollment/component/CreateAppointmentWithCalendarContainer';
import actions from '../actions';
import { changeMenuStats } from '../../layout/actions/Nav';
import virtualVisitActions from '../../VideoChat/action/reducerActions';
import {statusMap, VISIT_TABS} from '../constants';
import { API as ChatAPI } from '../../chat/actions/api';
import '../styles/index.scss';
import '../../visit/styles/index.scss';
import {getVisitStatus} from "../helpers";
import { OMRONContextProvider } from '../../OMRONContext/OMRONContextProvider';

class VisitsMainContainer extends React.Component {
  initialState = {
    showVisitCreate: false,
    visitToEdit: null,
    workListVisit: null,
    visitListProps: {},
    showVirtualVisit: { visible: false, visit: null }
  }
  constructor(props) {
    super(props);

    this.state = this.initialState;
  }




  componentDidMount = () => {
    this.props.closeNavBar();

    // fetch coveredClinics for VideoChat/helpers/index.js > handleNotification
    const userId = sessionStorage.getItem('currentUserId');
    ChatAPI.getUserProfile(userId)
    .then( res => {
      const coveredClinics = res.data.user.userSettings.listeningOrgs;
      sessionStorage.setItem('coveredClinics', JSON.stringify(coveredClinics));
    })
    .catch( err => console.error('Error fetching user listening Orgs: ', err));

    //// re-open Work List after enroll
    // let data = JSON.parse(sessionStorage.getItem('backToCurVisit'));
    // if(data && data.id) {
    //   sessionStorage.removeItem('backToCurVisit');
    //   this.renderVisitWorkList(data.id);
    // }
  }

  getSelectedTabString = () => _.capitalize(this.props.selectedTabKey);

  setVisitListProps = (tabKey, newVisitListProps) => {
    this.setState(prev => ({ visitListProps: {
      ...prev.visitListProps,
      [tabKey]: newVisitListProps
    }}));
  };

  setShowVirtualVisit = ({ visible = true, visit, ...restProps } = {}) => {
    let newShowVirtualVisit = { ...this.state.showVirtualVisit, visible };
    if(!visible) 
      return this.setState({ showVirtualVisit: newShowVirtualVisit });

    newShowVirtualVisit = { ...newShowVirtualVisit, visit, ...restProps };
    this.setState({ showVirtualVisit: newShowVirtualVisit }, () => {
      if(visit) {
        this.props.setVirtualVisit(visit.id);
        this.triggerVirtualPopup(newShowVirtualVisit);
      }
    });
  };

  setShowVisitCreate = flag => this.setState({ showVisitCreate: flag });

  createVisitButton = (
    <Row>
        <Col span={24} style={{ paddingRight: 20, textAlign: 'right' }}>
          <Button 
            type='primary'
            className='visits-create-visit-button'
            onClick={() => {
              Mixpanel.track('clicked', 'create visit', `on ${this.getSelectedTabString()} visit tab`, {});
              this.setShowVisitCreate(true);
            }}
          >
              {I18N.get('visit.createBtn')}
          </Button>
        </Col>
    </Row>
  );

  setShowVisitEdit = visit => {
    let isNoShow = false;
    const { isEdit=false } = visit;
    if(visit) {
      const {key} = getVisitStatus(visit);
      isNoShow = key === statusMap.NO_SHOW;
    }
    this.setState({ visitToEdit: visit,rescheduledFromId: !isEdit&&visit&&isNoShow ? visit.id :null })
  }

  renderVisitEdit = visit => {
    const selectedTabString = this.getSelectedTabString();
    Mixpanel.track('clicked', 'edit visit', `on ${selectedTabString} visit tab`, {});
    this.setShowVisitEdit(visit);
  };

  setShowVisitWorkList = visit => this.setState({ workListVisit: visit });

  renderVisitWorkList = visit => {
    const selectedTabString = this.getSelectedTabString();
    Mixpanel.track('clicked', 'Chart', `on ${selectedTabString} visit tab`, {});
    this.setShowVisitWorkList(visit);
  };

  refetchAllVisitList = () => {
    const { visitListProps } = this.state;
    if(!_.isEmpty(visitListProps)) {
      _.forEach(visitListProps, vProps => {
        if(vProps.refetchVisitList)
          vProps.refetchVisitList();
      });
    }
  };

  triggerVirtualPopup = ({ visit, currentRole }) => {
    const { id: visitId, member } = visit;
    if(visitId){
      notification.close(visitId);
    }
    notification.open({
      key:'videoChatCenterNotification',
      message: 'Video chat Left Div View',
      description: <VideoChatCenterComponent 
        setVirtualVisit={this.props.setVirtualVisit} 
        user={member} 
        setModal={(visible, visit) => this.setShowVirtualVisit({ visible, visit })}
        currentVisit={visit} 
        currentRole={currentRole} 
        refetchVisitList={this.refetchAllVisitList}
        refetchSingleVisit={newVisitData => this.setShowVirtualVisit({ visit: newVisitData, currentRole })}
      />,
      duration: 0,
      placement:'topLeft',
      className:'videoChatCenterNotification',
      style:{ zIndex:500 }
    });
  };

  onCancelVisitPopup = (shouldRefetchVisitList, cb) => {
    try {
      if(shouldRefetchVisitList){
        this.refetchAllVisitList();
      }
      if(cb) cb();
    } catch (err) {
      console.error(err);
    }
  };

  renderShowCalendar = () => {
    const { visitToEdit,rescheduledFromId } = this.state;
    const userId = _.get(visitToEdit, 'member.id');
    return <CreateAppointmentWithCalendarContainer
        patientId={userId}
        showModalOnMount
        rescheduledFromId={rescheduledFromId}
        visitInfo={visitToEdit}
        onCancel={() => {
          this.setState(this.initialState);
          this.onCancelVisitPopup(true)
        }}
      />
  }


  render() {
    const { showVisitCreate, visitToEdit, workListVisit, visitListProps } = this.state;
    const { selectedTabKey, setSelectedTabKey } = this.props;
    return (
      <div id='visits-main-container'>
        <Tabs 
          defaultActiveKey={selectedTabKey}
          tabBarExtraContent={this.createVisitButton}
          onChange={activeKey => setSelectedTabKey(activeKey)}
        >
        { 
          _.map(VISIT_TABS, tabConfig => {
            const { tabKey, tabForceRender, getTabTitle } = tabConfig;
            const tabVisitListProps = visitListProps[tabKey] || {};
            const currentVisitCount = tabVisitListProps.visitListCount || 0;
            return <Tabs.TabPane 
              key={tabKey}
              tab={getTabTitle(currentVisitCount)}
              forceRender={tabForceRender}
            >
              <VisitTabContainer
                visitTabConfig={tabConfig}
                setVisitListProps={this.setVisitListProps} 
                renderVisitWorkList={this.renderVisitWorkList}
                renderVisitEdit={this.renderVisitEdit}
                setShowVirtualVisit={this.setShowVirtualVisit}
                refetchAllVisitList={this.refetchAllVisitList}
              />
            </Tabs.TabPane>;
          })
        }
        </Tabs>
        {
          showVisitCreate &&
          <CreateVisitInDashboardContainer
            onCancel={shouldRefetch => {
              this.onCancelVisitPopup(shouldRefetch, () => this.setShowVisitCreate(false));
            }}
          />
        }
        {
          visitToEdit &&
          this.renderShowCalendar()
          // <EditVisitInDashboardContainer
          //   visitInfo={visitToEdit}
          //   refetch={() => {}} // no-op
          //   onCancel={shouldRefetch => {
          //     this.onCancelVisitPopup(shouldRefetch, () => this.setShowVisitEdit(null));
          //   }}
          // />
        }
        {
          workListVisit &&
          <OMRONContextProvider
            patientId={_.get(workListVisit, 'member.id')}
          >
            <WorkListContainer 
              id={workListVisit.id}
              appointmentAt={workListVisit.appointmentAt}
              memberId={_.get(workListVisit, 'member.id')}
              onCancel={() => {
                this.onCancelVisitPopup(true, () => this.setShowVisitWorkList(null));
              }}
            />
          </OMRONContextProvider>
        }
      </div>
    );
  }
};

VisitsMainContainer.propTypes = VisitPropTypes.main;

const mapStateToProps = state => ({
  selectedTabKey: state.visitsPage.selectedTabKey
});

const mapDispatchToProps = dispatch => ({
  closeNavBar: () => {
    const isExpanded = document.querySelector('.VSM-LeftNav-expanded');
    if(!!isExpanded) {
      document.querySelector('.VSM-LeftNav').classList.toggle('VSM-LeftNav-expanded');
      document.querySelector('#master').classList.toggle('pushed');
      document.querySelector('.vsm-top-nav').classList.toggle('pushed');
      document.querySelector('.v-logo').classList.toggle('logo-hidden');
      dispatch(changeMenuStats(false));
    }
  },
  setVirtualVisit: visitId => dispatch(virtualVisitActions.setVirtualVisit(visitId)),
  setSelectedTabKey: activeKey => dispatch(actions.setSelectedTab(activeKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitsMainContainer);