import React from 'react';
import { graphql,compose } from "react-apollo";
import moment from 'moment';
import { Spin } from 'antd';
import _ from 'lodash';
import FoodLogReviewComponent from './FoodLogReviewComponent';
import BGRes from '../query/index';
import { RESULT_COUNT_MAX } from 'libModule/constants';
import { convertBG_mmolTomgdl } from "libModule/utils/convertUnits";
import I18N from 'modulesAll/I18N';
import helpers from '../../logBook/helpers';
import TimerContainer from '../../timer/containers/TimerContainer';
import { connect } from 'react-redux';
import timerActions from '../../timer/actions';
import { MapActionsConfiguration } from '../../timer/constants/map';
import { getTranslate } from '../../translation/API';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { stopTimer, updateInterventionDetails } = timerActions;
const TIMER_ACTIONS = MapActionsConfiguration.WORKSTATION_FOOD_LOG;

const MEAL_TYPE_STYLE = {
  borderRadius: 4,
  backgroundColor: '#E5F9F8',
  padding: '5px 10px',
  fontWeight: 'bold',
  width: 'fit-content',
};

const DATE_WRAPPER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 5,
};

const DATE_TITLE_STYLE = {
  color: '#6A727D',
  fontWeight: 'bold',
};

const DATE_STYLE = {
  color: '#6A727D',
};

const TITLE_STYLE = {
  color: '#2D3238',
  fontWeight: 'bold',
  marginBottom: 5,
};

const COMMENT_STYLE = {
  color: '#2D3238',
};

const DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';

class WorkStationSingleFoodLog extends React.Component {
  static displayName = 'WorkStationSingleFoodLog';
  constructor(props) {
    super(props);
    this.state = {
      translatedComment: '',
      showOriginal: true,
    };
  }

  get foodLog() {
    return this.props.foodLog;
  }

  shouldTranslate = () => {
    const { appLanguage, language } = this.props;
    return !(language === appLanguage && appLanguage == 'English');
  }

  translateComment = async () => {
    const comment = _.get(this.foodLog, 'note');
    const val = await getTranslate(comment, 'English'); 
    return _.get(val, 'data.translations.0.translatedText', '');
  }

  handlePatientCommentChange = async () => {
    const { showOriginal } = this.state;
    Mixpanel.track('clicked', 'see_translation', 'patient_foodlog_comment');
    if (!this.state.translatedComment) {
      const translatedComment = await this.translateComment();
      this.setState({ translatedComment, showOriginal: !showOriginal });
    } else {
      this.setState({ showOriginal: !showOriginal });
    }
  }

  handleSubmit = (values) => {
    // const displayName = this.getTimerDisplayName();
    // this.props.stopTimer(displayName, TIMER_ACTIONS.stopTimerAction);
    const cb = (res) => {
      // const apiName = Object.keys(res.data)[0];
      // const data = res.data[apiName];
      // const docId = data.id;
      // const resource = atob(docId).split(':')[0];
      // const summary = _.last(res.data[apiName].comments).text;
      // this.props.updateInterventionDetails(displayName, apiName, resource, docId, summary);
    }
    this.props.onSubmit(this.foodLog, values, cb);
  }

  handleCancel = () => {
    this.props.onCancel(this.foodLog);
  };

  renderGlucoseResult = () => {
    const { props, foodLog } = this;
    const { resultList } = props;
    const mealType = _.toLower(_.upperFirst(_.get(foodLog, 'mealType', '')));
    const beforeMeal = _.filter(resultList, (res) => (res.beforeMeal && res.mealType.toUpperCase() === mealType.toUpperCase() && res.severity !== "MISSED"));
    const afterMeal  = _.filter(resultList, (res) => (!res.beforeMeal && res.mealType.toUpperCase() === mealType.toUpperCase() && res.severity !== "MISSED"));

    const beforeMealRes = beforeMeal[0] && _.get(beforeMeal[0],'blood_glucose') || '';
    const beforeMealDate = beforeMeal[0] && _.get(beforeMeal[0],'date') || '';
    const formattedBeforeMealDate = beforeMealDate ? moment(beforeMealDate).format('HH:mm') : '';
    const beforeMealValue = (convertBG_mmolTomgdl(beforeMealRes.value)) || '';
    const beforeMealUnit = !!beforeMealValue ? I18N.get('measure.units_BG_US_standard'): '';
    const beforeMealSeverity = beforeMealValue ? helpers.getBGReadingSeverity(true, beforeMealValue) : '';

    const afterMealRes = afterMeal[0] && _.get(afterMeal[0], 'blood_glucose') || '';
    const afterMealValue = (convertBG_mmolTomgdl(afterMealRes.value)) || '';
    const afterMealUnit = !!afterMealValue ? I18N.get('measure.units_BG_US_standard'): '';
    const afterMealDate = afterMeal[0] && _.get(afterMeal[0], 'date') || '';
    const formattedAfterMealDate = afterMealDate ? moment(afterMealDate).format('HH:mm') : '';
    const afterMealSeverity = afterMealValue ? helpers.getBGReadingSeverity(false, afterMealValue) : '';

    if (!beforeMealValue && !afterMealValue) return null; 

    return (
      <div style={{ marginTop: 20 }}>
        <div style={TITLE_STYLE}>Glucose Result</div>
        <div>
          <div className={beforeMealSeverity} style={{ marginBottom: 5 }}>
            Before Meal - {formattedBeforeMealDate}
            <span className='BGReading'>{beforeMealValue}</span><span className='BGUnit'>&nbsp;{beforeMealUnit}</span>
          </div>
          <div className={afterMealSeverity} style={{ marginBottom: 5 }}>
            After Meal - {formattedAfterMealDate}
            <span className='BGReading'>{afterMealValue}</span><span className='BGUnit'>&nbsp;{afterMealUnit}</span>
          </div>
        </div>
      </div>
    );
  }

  renderPatientComments = () => {
    const comment = _.get(this.foodLog, 'note');
    const { translatedComment, showOriginal } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <div style={TITLE_STYLE}>Patient Comments:</div>
        {comment && <div style={COMMENT_STYLE}>{showOriginal ? comment : translatedComment}</div>}
        {this.shouldTranslate() && comment && 
          <div style={{ marginTop: 10 }} >
            <a onClick={this.handlePatientCommentChange}>{showOriginal ? 'See translation' : 'See original'}</a>
          </div>
        }
      </div>
    );
  }

  renderMealType = () => {
    const mealType = _.upperFirst(_.get(this.foodLog, 'mealType').toLowerCase());
    return (
      <div style={MEAL_TYPE_STYLE}>
        {mealType}
      </div>
    );
  }

  renderDates = () => {
    const postedAt = moment(_.get(this.foodLog, 'createdAt')).format(DATE_TIME_FORMAT); 
    const uploadedAt = moment(_.get(this.foodLog, 'uploadedAt')).format(DATE_TIME_FORMAT); 

    return (
      <div style={{ marginTop: 20 }}>
        <div style={DATE_WRAPPER_STYLE}><span style={DATE_TITLE_STYLE}>Posted:</span><span style={DATE_STYLE}>{postedAt}</span></div>
        <div style={DATE_WRAPPER_STYLE}><span style={DATE_TITLE_STYLE}>Uploaded:</span><span style={DATE_STYLE}>{uploadedAt}</span></div>
      </div>
    );
  }

  getTimerDisplayName = () => {
    return `${this.getDisplayName()}:${this.props.patientId}`;
}

  getDisplayName = () => {
    return `${this.constructor.displayName}-${this.foodLog.id}`;
  }

  renderInfo = () => {
    if (!this.foodLog) return null;
    const { patientId } = this.props;

    return (
      <div style={{ fontSize: 14, height: '100%' }}>
        <TimerContainer
          displayName={this.getDisplayName()}
          category="WORKSTATION_FOOD_LOG"
          action='REVIEW'
          viewingDetails={{
              component: this.constructor.displayName,
              path: window.location.pathname
          }}
          patientId={patientId}
          docId={this.foodLog.id}
          keepEventListener={false}
        />
        <div className="workstation-section-body">
          <div>
            {this.renderMealType()}
            {this.renderDates()}
            {this.renderGlucoseResult()}
            {this.renderPatientComments()}
          </div>
          <div>
            <FoodLogReviewComponent
              userAvatar={this.props.userAvatar}
              id={this.foodLog.id}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              isSubmitting={this.props.isSubmitting}
              style={{ marginTop: 20 }}
              setShowUnsavedModal={this.props.setShowUnsavedModal}
              translationLang={this.props.translationLang}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <Spin size="large" >
          <div style={{ height: 700 }}></div>
        </Spin>
      );
    }

    return (
      <React.Fragment>
        {this.renderInfo()}
      </React.Fragment>
    );
  }
}

const BGResultData = graphql(BGRes, {
  options: (ownProps) =>{ 
    const memberId = _.get(ownProps, 'foodLog.member.id');
    const createdAt = _.get(ownProps, 'foodLog.createdAt');
    const fromDate = moment(createdAt).startOf('day');
    const toDate = moment(createdAt).endOf('day');

    return {
      variables: {
      count: RESULT_COUNT_MAX,
      filters: {
          memberId,
          fromDate,
          toDate
        }
      },
      fetchPolicy:'network-only'
    };
  },
  props: (data) => {
    const { loading, resultList } = data.data;
    const type = _.get(data, 'ownProps.foodLog.mealType');
    if (resultList) {
      return {
        resultList: _.filter(resultList.data, (res) => res.mealType === type),
        loading
      }
    }
  }
})

const mapToDispatch = (dispatch)=>{
  return {
    stopTimer: (displayName, event) => dispatch(stopTimer(displayName,event)),
    updateInterventionDetails:(displayName,apiName,resource,docId,summary) => dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
  }
}
export default compose(BGResultData,connect(null,mapToDispatch))(WorkStationSingleFoodLog);