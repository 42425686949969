import React, { useMemo } from 'react';
import { compose } from 'react-apollo';

import { getMostRecentEP } from '../apis/getEnrolledProgram';

const withMostRecentEp = (Component) => {
  const WrappedComponent = (props) => {
    const EnhancedC = useMemo(() => compose(getMostRecentEP)(Component), []);
    return <EnhancedC {...props} />;
  };

  return WrappedComponent;
};

const withTeamMembersByPatient = (Component, shouldSkip) => {
  const EnhancedComponent = (props) => {
    const {
      mostRecentEP,
      loadingMostRecentEp,
    } = props;

    const teamMembers = useMemo(() => {
      if (!loadingMostRecentEp && mostRecentEP) {
        const currentTeam = _.get(mostRecentEP, 'team.0') || {};
        return _.map(currentTeam.members, ({ currentRole, user }) => {
          return {
            ..._.omit(user, '__typename'),
            roleType: _.get(currentRole, 'roleType'),
          };
        })
      }
      return null;
    }, [loadingMostRecentEp, mostRecentEP]);

    return <Component {...props} teamMembers={teamMembers} />;
  };

  const WrappedComponent = (props) => {
    const skip = shouldSkip(props);
    const FinalComponent = useMemo(() => {
      if (skip) {
        return Component;
      }
      return withMostRecentEp(EnhancedComponent)
    }, [skip]);

    return <FinalComponent {...props} />
  };

  return WrappedComponent;
};

export default withTeamMembersByPatient;
