import React from 'react';
import { Table,Icon } from 'antd';
import moment from "moment/moment";
import $ from 'jquery';

const { Column } = Table;
const POLogBookMiniComponent = class extends React.Component {
    constructor(){
        super();
    }

    generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }
        return timeSpots.reverse();
    }

    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in</span>
            <span style={{fontWeight:900}}>&nbsp;Percentage (%)</span>
        </Icon>
    }

    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const parsedData =  _.groupBy(data,(d)=>{
            return moment(d.date).format('MM/DD/YYYY')
        })
        let groupedData = _.map(timeSpots,(key)=>{
            if(parsedData[key] == undefined) {
                return null;
            }
            return {
                [key]:parsedData[key]
            }
        }).filter(v => !!v)
        return groupedData;
    }

    parseData = (data) =>{
        const map = [{
                key:'DAWN',
                startTime:0,
                endTime: 4*3.6e6
            },
            {
                key:'MORNING',
                startTime:4,
                endTime: 12*3.6e6
            },
            {
                key:'NOON',
                startTime: 12,
                endTime: 18*3.6e6
            },
            {
                key:'NIGHT',
                startTime: 18,
                endTime: 24*3.6e6
            }];
        const { date } = data;
        const dateObj = new Date(date);
        const startHour = dateObj.getHours();
        const endHour = dateObj.getHours()*3.6e6 + dateObj.getMinutes()*6e4 + dateObj.getSeconds()*0.1e4 + dateObj.getMilliseconds();
        let type = '';
        _.forEach(map,(o)=>{
            if(startHour>=o.startTime&&endHour<o.endTime&&!(startHour==0&&endHour==0)){
                type = o.key;
            }
        })
        return type;
    }

    handleData(filteredList){
        const allMissed = filteredList.length > 0 && !_.find(filteredList, (i) => (i.severity != 'MISSED'));
        let readingCount = !allMissed ? _.reject(filteredList,(i) => i.severity == 'MISSED').length : 0;
        const readingObj = filteredList.length > 0 && !allMissed ? _.find(filteredList, (i) => (i.severity && i.severity != 'MISSED')) || {} : {};
        const severity = _.get(readingObj, 'severity', '');
        const bloodOxygen = _.get(readingObj, 'blood_oxygen', { value: '', unit: '' });
        const perfusionIndex = _.get(readingObj, 'perfusion_index', { value: '', unit: '' });
        const readingValue = allMissed 
            ? 'Missed' 
            : !_.isEmpty(readingObj) ? {
                severity,
                bloodOxygen,
                perfusionIndex,
                strValue: (bloodOxygen ? `${bloodOxygen.value}${bloodOxygen.unit}` : '') + (!!perfusionIndex ? ` (PI: ${perfusionIndex.value}${perfusionIndex.unit})` : '')
            } : '';

        const readingDate = readingObj.date;
        let className = allMissed ? 'missedReading' : (readingCount > 1 ? 'logbookCellWithBadge BP' : readingCount > 0 ? 'logbookCell' : '') + ` spo-${severity.toLowerCase()}`;
        readingCount = readingCount > 1 ? readingCount : null;
        const hasUserNote = (_.filter(filteredList, (i)=> _.get(i, 'measure.user_notes'))).length != 0;
        
        className = className.concat(hasUserNote ? ' hasUserNote' : '');

        return {
            readingValue,
            allMissed,
            readingCount,
            readingDate,
            hasUserNote,
            className,
        };
    }

    renderReadingInCell(readingValue){
        if(typeof readingValue === 'string'){
            return <span>{readingValue}</span>;
        } else {
            return (
                <div>
                    <div>
                        <span>{readingValue.strValue}</span>
                    </div>
                </div>
            );
        }
    }

    renderTable(){
        const dataToRender = this.groupData();
        const result = [];
        const parsedData = _.map((dataToRender),(d) => {
            const valueArray = Object.values(d)[0];
            _.forEach(valueArray, (val) => {
                result.push({
                    data:{[this.parseData(val)]:[val]},
                    date:val.date
                })
            })
            return {
                data:_.groupBy(valueArray, (d) => this.parseData(d)),
                date:valueArray[0].date
            }
        })
        return  <div className='IH-SearchTable'>
            <Table dataSource={result} rowKey={(data,index)=>data.date+index}
                   className='logMiniBookTable' bordered
                   style={{ marginBottom:0, fontSize:10}}
                   pagination={false}
            >
                <Column
                    title="Date"
                    dataIndex="date"
                    // key='date'
                    render={(data)=>moment(data).format('MM/DD/YYYY')}
                    width={'10%'}
                />
                <Column
                    title={<div>Overnight<p>(24:00 - 4:00)</p></div>}
                    dataIndex="data.DAWN"
                    key='DAWN'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div>Morning<p>(4:00 - 12:00)</p></div>}
                    dataIndex="data.MORNING"
                    key='MORNING'
                    className='logBookIcon'
                    width='11%'
                    render={(data) => {
                        const { readingCount,className,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div>Afternoon<p>(12:00 - 18:00)</p></div>}
                    dataIndex="data.NOON"
                    key='NOON'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className,readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div>Evening<p>(18:00 - 24:00)</p></div>}
                    dataIndex="data.NIGHT"
                    key='NIGHT'
                    width='11%'
                    className='logBookIcon'
                    render={(data, index) => {
                        const { readingCount,className,readingValue } = this.handleData(data||[]);

                        return {
                            props: {
                                className:'number',
                                data:readingCount

                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
            </Table>
        </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }
}

export default POLogBookMiniComponent;
