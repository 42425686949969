
import { basicInfoConfig } from "./basicInfo";
import { medicalHistoryConfig } from "./medicalHistory";
import { familyHistoryConfig , familyHistoryKeys } from "./familyHistory";
import { liefStyleConfig } from './lifeStyle';


export{
    basicInfoConfig,
    medicalHistoryConfig,
    familyHistoryConfig,
    familyHistoryKeys,
    liefStyleConfig,
}
