import gql from 'graphql-tag';
export default gql`
      query getPresignedFileUpload($userId: EID!, $filename: String!) {
        getPresignedFileUpload(userId: $userId, filename: $filename) {
          data {
            url
            fields
          }
        }
      }
    `;
