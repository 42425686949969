import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AlertFormContainer from 'patientModule/enrolledProgramInfo/VitalAlert/containers/AlertFormContainer'
import AlertListContainer from 'patientModule/enrolledProgramInfo/VitalAlert/containers/AlertListContainer'

// container
class Container extends Component {
  static displayName = 'patient/enrolledProgramInfo/VitalAlert/containers/CommonContainer'
  render() {
    return <AlertListContainer {...this.props}/>
  }
}

// mapState
const mapState = ({ patient }, ownProps) => {
  return {
    ...patient.enrolledProgramInfo.Careplan.VitalAlert.Common
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
  }
}


// PropTypes validation
Container.propTypes = {
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Container)
