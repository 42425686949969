import gql from 'graphql-tag';

export default gql`
  query getPresignedUpload(
    $userId: EID!
    $filename: String!
  ) {
    getPresignedUpload(userId: $userId, filename: $filename) {
      data{
          url
          fields
      }
      info{
          expires
      }
    }
  }
`;
