import React, { useEffect } from 'react';
import VisitPropTypes from '../constants/propTypes';
import { Table, Tooltip } from 'antd';
import tableColumns from '../constants/tableColumns';
import handleFilterData, { visitNameFilter } from '../constants/tableFilters';
import VisitTableTitle from './VisitTableTitle';

const scrollContainer = '.ant-table-scroll .ant-table-body';
const CONFIRMED_TABLE_KEY = 'visits-confirmed-table';
const CONFIRMED_SCROLL = `#${CONFIRMED_TABLE_KEY} ${scrollContainer}`;
const PENDING_TABLE_KEY = 'visits-pending-table';
const PENDING_SCROLL = `#${PENDING_TABLE_KEY} ${scrollContainer}`;

const TodayVisitsComponent = props => {
  const { visitList, visitListCount, tableSelectedFilters, tableSelectedAdvancedFilters } = props;
  const allSelectedFilters = { ...tableSelectedFilters, ...tableSelectedAdvancedFilters };
  const { filteredData, advancedFilterResultCount} = handleFilterData(allSelectedFilters, visitList);

  const pendingVisits = _.filter(filteredData, v => !v.confirm);
  const confirmedVisits = _.differenceBy(filteredData, pendingVisits, 'id');

  useEffect(() => {
    let confirmedRef = document.querySelector(CONFIRMED_SCROLL);
    let pendingRef = document.querySelector(PENDING_SCROLL);
    if(confirmedRef && pendingRef) {
      const addScrollEvent = (listenFrom, target, key) => {
        listenFrom.addEventListener('scroll', _.throttle(() => {
          try {
            target.scrollLeft = listenFrom.scrollLeft;
          } catch (error) {
            //ignore
          }
        }));
      };
      addScrollEvent(confirmedRef, pendingRef);
      addScrollEvent(pendingRef, confirmedRef);
    }
    return () => {
      confirmedRef = null;
      pendingRef = null;
    }
  }, []);

  useEffect(() => {
    try {
      if(confirmedVisits.length > 1 && pendingVisits.length > 1) 
        return; // scrolls already synced, doesn't matter with filtered data

      // probably new visit added in either table, sync scroll
      const confirmedRef = document.querySelector(CONFIRMED_SCROLL);
      const pendingRef = document.querySelector(PENDING_SCROLL);
      if(confirmedRef.scrollLeft !== 0) {
        pendingRef.scrollLeft = confirmedRef.scrollLeft; // sync confirmed to pending
      } else if(pendingRef.scrollLeft !== 0) {
        confirmedRef.scrollLeft = pendingRef.scrollLeft; // sync pending to confirmed
      }
    } catch (err) {
      console.error(err);
    }
  }, [visitListCount]);
  
  const tableProps = {
    scroll: { x: '130%' },
    pagination: false,
    rowKey: (record, idx) => record.id || idx,
    columns: [
      tableColumns.visitCategory(),
      tableColumns.visit(),
      tableColumns.patient(visitNameFilter(allSelectedFilters)),
      tableColumns.primaryProvider(),
      tableColumns.enrollStatusVitals(),
      tableColumns.visitNote(),
      tableColumns.participant(),
      tableColumns.visitStatus(),
      tableColumns.visitAction({}, props),
      tableColumns.chart({}, props)
    ]
  };

  return <div>
    <VisitTableTitle 
      resultCount={advancedFilterResultCount}
      totalCount={visitListCount}
      visitWindowTime={props.visitWindowTime}
      tableSelectedAdvancedFilters={props.tableSelectedAdvancedFilters}
      tableAdvancedFilters={props.tableAdvancedFilters}
    />
    <Table
      key={CONFIRMED_TABLE_KEY}
      id={CONFIRMED_TABLE_KEY}
      {...tableProps}
      dataSource={confirmedVisits}
    />
    <Table
      key={PENDING_TABLE_KEY}
      id={PENDING_TABLE_KEY}
      {...tableProps}
      showHeader={false}
      dataSource={pendingVisits}
      title={() => {
        return <div style={{ display: 'flex', alignItems: 'center'}} >
          <span 
            className='visits-table-label'
            style={{ marginLeft: 41 }}
          >
            In-Clinic Visits Pending Confirmation
          </span>
          <Tooltip 
            trigger='hover'
            overlayClassName='visits-tooltip-pending'
            title='This table is for in-clinic visit. When patient arrives in the clinic, click “Confirm” to confirm patient’s presence. Once the visit is confirmed, you can check in this patient.'
          >
            <a 
              className='visits-table-pending-tiptext'
              onClick={e => e.preventDefault()}
            >
              What does it mean?
            </a>
          </Tooltip>
        </div>;
      }}
    />
  </div>;
};

TodayVisitsComponent.displayName = 'visitsPage/components/TodayVisitsComponent';
TodayVisitsComponent.propTypes = VisitPropTypes.tab;

export default TodayVisitsComponent;