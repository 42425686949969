import  meetingConfig  from '../constant/index';
// import { ZoomMtg } from '@zoomus/websdk';
import API from '../API';
import { decryptRole } from 'libModule/utils';

// it's option if you want to change the WebSDK dependency link resources.
// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.0/lib', '/av'); // CDN version default
// ZoomMtg.setZoomJSLib('https://jssdk.zoomus.cn/1.7.0/lib', '/av'); // china cdn option
// ZoomMtg.setZoomJSLib('http://localhost:9999/node_modules/@zoomus/websdk/dist/lib', '/av'); // Local version default
import { notification } from 'antd';
import React from 'react';
// ZoomMtg.setZoomJSLib('https://dmogdx0jrul3u.cloudfront.net/1.7.0/lib', '/av'); // CDN version default
// // let zoomJSAVLib='/av';
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
// ZoomMtg.showInviteFunction({ show: false });
// ZoomMtg.showMeetingHeader({ show: false });
// ZoomMtg.showRecordFunction({ show: false });
import Client from 'libModule/gqlClient';
import {userWithoutTeam as userGQL} from "../../graphql/user";
import { message } from 'antd';
import getCurrentVisit from 'graphqlModule/singleVisit';
import getLatestMeasurementsByType from 'graphqlModule/measureLatestByType';


const setShowVideoLoadingSpin = (localThis,showVideoLoadingSpin) =>{
        if(localThis&&localThis.setShowVideoLoadingSpin) {
            localThis.setShowVideoLoadingSpin(showVideoLoadingSpin)
        }
}

const joinMeeting  = async (meetingNumber,password,zoomMeeting,setVisit,visit,apikey,signature,visitId,VideoChatLeftComponent,currentVisit,fn,localThis)=>{
    setShowVideoLoadingSpin(localThis,true);
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const currentRole = decryptRole();
    const fullName = _.get(currentUser,'profile.fullName');
    const meetConfig = meetingConfig(meetingNumber,password,apikey,fullName);

    const res = await Client.query({
        query:userGQL,
        variables:{
            id:visit
        },
        fetchPolicy:'network-only'
    });


    // if(roleMap[currentRole] == 'Doctor' || roleMap[currentRole] == 'MA') {
    //     // prevent stale data from currentVisit
    //     // ignore if currentRole is not MA or MD because other roles don't use measurement form
    //     const updatedCurrentVisit = await getUpdatedCurrentVisit(visitId);
    //     if(_.get(updatedCurrentVisit, 'details') && _.get(updatedCurrentVisit, 'reason') !== undefined){
    //         visitInfo.details = _.get(updatedCurrentVisit, 'details');
    //         visitInfo.reason = _.get(updatedCurrentVisit, 'reason');
    //         visitInfo.isUpdatedForVideo = true;
    //     }

    //     if(roleMap[currentRole] == 'MA'){
    //         // only MA needs to see the latest measurements from patient
    //         const latestMeasurements = await getLatestMeasurements(_.get(currentVisit, 'member.id'));
    //         _.forEach(_.get(latestMeasurements, 'measureLatestByType', []), measure => {
    //             // clear noise in measure to get actual measurement, ie. blood_glucose: Object
    //             const measurements = _.pick(measure, _.keys(mappingMeasurements));
    //             _.forEach(_.keys(measurements), measurementType => { // measurementType, ie blood_glucose: String
    //                 // formKeyName, ie BG: String
    //                 const formKeyName = _.get(mappingMeasurements, `${measurementType}.name`);
    //                 // latest value and its unit
    //                 const { value: measurementValue, unit: measurementUnit } = _.get(measurements, measurementType);
    //                 // final value and unit
    //                 const [value, unit] = _.get(mappingMeasurements, measurementType).getValue(measurementValue, measurementUnit);
    //                 const newValue = { value: _.round(value, 1), unit }
    //                 // valuePath is path to measurement in visitInfo
    //                 const valuePath = _.get(mappingMeasurementsForVisit, formKeyName);
    //                 // make sure not to replace other values
    //                 const oldValues = _.get(visitInfo, valuePath);
    //                 _.set(visitInfo, valuePath, {...oldValues, ...newValue});
    //             })
    //         });
    //         console.log('updated with latest ', visitInfo);
    //     }
    // }

    const user = _.get(res,'data.user');
    const currentPath = window.location.pathname;
    ZoomMtg.init({
        leaveUrl: currentPath,
        isSupportAV: true,
        success() {
            ZoomMtg.join(
                {
                    meetingNumber: meetConfig.meetingNumber,
                    userName: meetConfig.userName,
                    signature,
                    apiKey: meetConfig.apiKey,
                    userEmail: 'email@gmail.com',
                    passWord: meetConfig.passWord,
                    success(initRes) {
                        zoomMeeting.style.display = 'flex';
                        // const currentRole = decryptRole();
                        console.log('debug log',user);
                        notification.open({
                            message: 'Video chat Left Div View',
                            description:<VideoChatLeftComponent user={user} currentVisit={currentVisit} currentRole={currentRole} refetchVisitList={fn}/>,
                            duration: 0,
                            placement:'topLeft',
                            key:'leftNav',
                            style:{ visibility:'hidden' }
                        })
                        API.activePatientMutate({
                            eventType: 'JOIN',
                            visitId
                        })
                        setShowVideoLoadingSpin(localThis,false);
                    },
                    error(res) {
                        console.log(res);
                    }
                }
            );
        },
        error(res) {
            console.log(res);
        }
    });
}

const leaveMeeting = ()=>{
    try{
        ZoomMtg.leaveMeeting();
    }catch (e){
        console.log(e);
    }
}

const getUpdatedCurrentVisit = async visitId => {
    const updatedCurrentVisit = await Client.query({
        query: getCurrentVisit,
        variables: { id: visitId },
        fetchPolicy: 'network-only',
    }).catch(err => {
        console.log('failed to get updated current visit:', err);
        return null;
    });
    return _.get(updatedCurrentVisit, 'data.getVisit', {});
}

const getLatestMeasurements = async memberId => {
    const latestMeasurements = await Client.query({
        query: getLatestMeasurementsByType,
        variables: {
            filters: {
                memberId,
                type: ['BG', 'BP', 'PO', 'HS', 'HR', 'TM']
            }
        },
        fetchPolicy: 'network-only',
    }).catch(err => {
        console.log('failed to get latest measurements:', err);
        message.error('Video: Failed to get the latest measurements from patient');
        return null;
    });
    return _.get(latestMeasurements, 'data', []);;
}

export default {
    joinMeeting,
    leaveMeeting,
    getUpdatedCurrentVisit,
    getLatestMeasurements,
}
