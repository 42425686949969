import I18N from '../../I18N';
export const BMIRes = (bmi)=> {
    switch(true){
        case (bmi < 18.5):
            return {
                key: 'underweight',
                text: I18N.get('measure.BMI.underweight')
            }

        case (bmi>=18.5&&bmi<=24.9):
            return {
                key: 'normal',
                text: I18N.get('measure.BMI.normal')
            }
        case (bmi>=25&&bmi<=29.9):
            return {
                key: 'overweight',
                text: I18N.get('measure.BMI.overweight')
            }
        case (bmi>=30):
            return {
                key: 'obese',
                text: I18N.get('measure.BMI.obese')
            }
        default:
            return {

            }
    }
};

