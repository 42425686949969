import gql from 'graphql-tag'

export default gql`
  query deviceHubList{
  deviceHubList(filters:{hubStatusFilter:ALL}){
    data{
      id  
      serialNumber
      member{
        id
      }
      lastHeartbeat
      devices{
        id
        type
        unit
      }
    }
  }
}`
