import React from 'react';
import parse from 'html-react-parser';


const PreviewConsentComponent = class extends React.Component{
    render() {
        const { template } = this.props;
        console.log(template);
        try {
            if (template) {
                return <div>{parse(template.content)}</div>;
            }
        }catch(e) {
            console.log(e);
        }
        return <div></div>
    }
}

export default PreviewConsentComponent;