import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { IHLoading } from 'ihcomponent';

import Client from 'libModule/gqlClient';
import getPatientConsentGQL from '../../../profileDetail/query/GetpatientConsent';

import { CONSENT_TYPE } from '../../constants';
import ConsentFormModal from './ConsentFormModal';

import '../../css/consentForm.scss';

const checkIfPaperConsent = consentType => consentType === CONSENT_TYPE.PAPER;

const ConsentFormPreview = forwardRef((props, ref) => {
  const { 
    viewText, 
    viewTextStyles = {},
    iconProps, 
    memberId, 
    shouldShowIcon = true 
  } = props;
  const [viewModal, setViewModal] = useState(false);
  const [pdfViewUrl, setPDFViewURL] = useState(null);

  const isPaperConsent = checkIfPaperConsent(props.consentType);
  const statusText = props.statusText ||
                      (isPaperConsent ? 'Paper consent form signed.' : 'Consent form signed!');

  const getPDFViewURL = async () => {
    let fileUrl = props.fileUrl;
    // consent flag changed in real-time, fetching for link
    if(!fileUrl) { 
      try {
        const res = await Client.query({ 
          query: getPatientConsentGQL,
          variables: { memberId },
          fetchPolicy: 'network-only'
        });

        fileUrl = _.get(res, 'data.getPatientConsent.fileUrl');
      } catch (e) {
        console.error(e);
        // don't need to notify user about error
        // Can simply close Task List and re-open if it fails
      }
    }

    return new Promise((resolve, reject) => {
      if(pdfViewUrl) return resolve(pdfViewUrl);

      const xhttp = new XMLHttpRequest();
      function onDataReceived() {
        if(xhttp.readyState === XMLHttpRequest.DONE) {
          if (xhttp.status === 0 || (xhttp.status >= 200 && xhttp.status < 400)) {
            const blob = new Blob([xhttp.response], { type: 'application/pdf' });
            return resolve(window.URL.createObjectURL(blob));
          } else {
            return reject('Failed to fetch file');
          }
        }
      }
      xhttp.onreadystatechange = onDataReceived;
      xhttp.open('GET', fileUrl, true);
      xhttp.responseType = 'arraybuffer';
      xhttp.send();
    });
  }

  useEffect(() => {
    if(viewModal) {
      getPDFViewURL().then(url => {
        setPDFViewURL(url);
      }).catch(err => {
        console.error(err);
      });
    }
  }, [viewModal]);

  return (
    <div className='consent-form-container-wrapper'>
      {
        viewModal &&
        <ConsentFormModal 
          modalBody={
            pdfViewUrl ?
            <iframe 
              src={pdfViewUrl} 
              style={{ width: '100%', height: '100%', border: 'none'}}
            >
              {/* Fallback when unsupported */}
              <div>Unsupported browser</div>
            </iframe>
            : <IHLoading />
          }
          onCancel={() => setViewModal(false)}
        />
      }
      <div className='consent-form-status'>
        <div className='consent-form-status-signed'>
          {
            shouldShowIcon &&
            <Icon type='check-circle' {...iconProps} />
          }
          <div className='consent-form-status-text'>
            {statusText}
            {
              !isPaperConsent &&
              <a 
                style={{...viewTextStyles}}
                onClick={e => {
                  e.preventDefault();
                  setViewModal(true);
                }}
              >
                {viewText}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

ConsentFormPreview.propTypes = {
  memberId: PropTypes.string.isRequired,
  shouldShowIcon: PropTypes.bool,
  iconProps: PropTypes.object,
  consentType: PropTypes.string,
  fileUrl: PropTypes.string,
  viewText: PropTypes.string,
  viewTextStyles: PropTypes.object,
};

export default ConsentFormPreview;