import keywords from './keywords';

export default {
  deviceForm : {
      messages : {
        bpReq : 'iHealth Blood Pressure Monitor (Blood Pressure, Heart Rate) is required',
        hrReq : 'iHealth Heart Rate Monitor (Blood Pressure, Heart Rate) is required',
        bgReq : 'iHealth Blood Glucose Monitor (Blood Glucose) is required',
        hsReq : 'iHealth Weight Scale (Weight) is required',
        tmReq : 'iHealth Body Temperature Monitor is required',
        poReq : 'iHealth Blood Oxygen Monitor is required',
        tabletReq : 'iPad Mini is required',
      },
      labels : {
        bp : 'iHealth Blood Pressure Monitor (Blood Pressure, Heart Rate)',
        hr : 'iHealth Heart Rate Monitor (Blood Pressure, Heart Rate)',
        bg : 'iHealth Blood Glucose Monitor (Blood Glucose)',
        hs : 'iHealth Weight Scale (Weight)',
        tm: 'iHealth Body Temperature Monitor',
        po: 'iHealth Blood Oxygen Monitor',
        tablet : 'iPad Mini',
      }
  },
  programInfoForm : {
      messages : {
        nameReq : 'Name is required',
        descriptionReq : 'Description is required',
        conditionReq : 'Health Condition is required',
        durationLengthReq : 'Duration is required',
        durationLengthMin : 'Duration can not be less than 1',
        durationLengthType : 'Duration can not have decimal',
        durationCalendarReq : 'Unit is required',
      },
      labels : {
        name : 'Name',
        description : 'Description',
        condition : 'Health Condition',
        durationLength : `${keywords.Program} Duration`,
      }
  },
  alertTmpl: {
    subject: 'Alert',
    tmplBody: {
      threshold: {
        raw: '[alert_type] on [vital_name] for [patient_name] [Medical Record Id] at [date] [time] is [severity].',
        message: '[Alert Type] on [Vital Name] for [Patient Name] [Medical Record Id] at [date] [time] is [severity].'
      },
      missed: {
        raw: '[alert_type] on [vital_name] for [patient_name] [Medical Record Id] at [date] [time].',
        message: '[Alert Type] on [Vital Name] for [Patient Name] [Medical Record Id] at [date] [time].'
      }
    }

  },
  thresholds: {
    title: 'Remote Vitals Monitoring'
  },
  newCarePlan: {
    careCoord: 'Clinicians from the iHealth ShareCare team collaborate with the primary care physician to set up the patient’s care plan and provide periodic in-clinic visits to review progress. iHealth ShareCare’s clinicians remotely monitor patient vitals and provide point-of-care support and real-time behavioral coaching under the supervision of the primary physician.',
    followUp: 'Follow up in-clinic visits are taken every 3-6 months or as needed. Patients can communicate with their care team via in-app secured messaging and/or phone calls.',
        // 'Follow-up in-clinic visits are taken every three months. Patients can communicate with their care team via in-app secured messaging and phone calls.',
    goals: 'Clinical Prognosis and Treatment Goals:\n\nBehavioral Goals:',
    assess:'Medical history:\n' +
    '\n' +
    '- Disease name / Year of diagnosis \n' +
    '\n' +
    '- Family history:\n' +
    '\n' +
    '- Treatment history (when started oral meds, insulin, recent hospitalization, or surgery, etc.)\n' +
    '\n' +
    '- Medication compliance: \n' +
    '\n' +
    '- Home monitoring (vitals, current device, frequency)\n' +
    '\n' +
    '- Social Support:\n' +
    '\n' +
    '\n' +
    'Lifestyle:\n' +
    '\n' +
    '- Daily routine (activity, sleep, etc.)\n' +
    '- Diet history:   \n' +
    '1. Typical diet routine / Meal time or 24-hr recall \n' +
    'B: \n' +
    'L: \n' +
    'D: \n' +
    'Snacks: \n' +
    'Fluids: Water intake / Caffeine intake\n' +
    'Eating out / Frequency per week (Restaurant VS Fast food)\n' +
    '2. Restriction / Food allergies\n' +
    '3. Basic nutrition knowledge (know carb/pro?, food labels, etc.)\n' +
    '4. Previous diets tried / Special diet (Who cooks & shops for food)\n' +
    '- Alcohol: Y / N    (type/frequency/amount)   \n' +
    '- Smoking: Y / N   (type/frequency/amount/history)\n' +
    '- Exercise: Type/routine/duration\n' +
    '- Weight history / Weight change:\n' +
    '\n' +
    '\n' +
    '\n' +
    'For BG pt only, Last exam date:\n' +
    '\n' +
    '- Eye exam\n' +
    '\n' +
    '- Foot exam\n' +
    '\n' +
    '- Dental exam',
    // assess: 'Disease Hx: Year of Diagnosis:  , Home Monitoring:  , Family History:  , Weight History:   , Weight Change:  ,\n\nMedication Compliance:\n\nLifestyle:\n    Diet History: who cooks and shops for food, Special Diet/Diet: , Restriction/Food Allergies:  , previous diets tried: , Eating out, frequency per\n    '
    //         + 'week: restaurant food vs fast food:\n    24-hr recall or Typical diet routine, including meal times:\n    B:\n    L:\n    D:\n    Snack(s)\n    Fluids: Water Intake, caffeine intake\n    Alcohol y/n, Smoking y/n\n    Exercise type, routine, and duration:\n\nPertinent Labs: Last lab results, renal, liver, A1C, cholesterol, cbc\n\nLast eye, foot, & dental exams:',
    resource: `Patient's care giver: `,
    interventions: 'Symptom and medication management; medication reconciliation with review of adherence and potential interactions; oversight of self-management of medications',
    assess_placeholder: 'Include a summary of the physical, mental, cognitive, psychosocial, functional, and environmental assessments)',
    problemList: ' ',
    save_success: 'Care Plan save successful',
  },
  programDetails:{
    header:'Program Details',
    startDate:'Start Date',
    programDescription:'Program Description',
    careTeam:'Care Team',
    dischargeDetails:'Discharge Details',
    lastUpdated:'Last Updated'
  }
}
