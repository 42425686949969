import React from 'react';
import { graphql, withApollo, compose } from 'react-apollo';
import { Modal } from 'antd';
import setUserPassword from 'modulesAll/graphql/setUserPassword';
import PasswordComponent from '../Components/PasswordComponent';
import { GQLHelper } from 'libModule/utils';
import { createAuditLog } from 'libModule/utils/auditLog';
import I18N from 'modulesAll/I18N';

class SetPasswordContainer extends React.Component {
  submit = async ({ newPass }) => {
    const { mutate, setSubmitting, patientId: id, onSuccess } = this.props

    const variables = {
      newPass,
      id
    };

    try {
      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'), // TODO: change to create password
        loginId: variables.identification,
        response: res
      });
      if(onSuccess) {
        onSuccess();
      }
      Modal.success({
        title: 'Success',
        content: 'Password changed successfully.'
      });
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'),
        loginId: variables.identification,
        response: err,
        success: false,
      })
      Modal.error({
        title: 'Error',
        content: `An error occurred while saving your password. Please try again. ${GQLHelper.formatError(err)}`
      });
    }
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <PasswordComponent onSubmit={this.submit} onCancel={this.onCancel}/>
    );
  }
}

export default compose(
  withApollo,
  graphql(setUserPassword),
  // connect(mapState, mapDispatch),
)(SetPasswordContainer)