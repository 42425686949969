const common = {
  'Critically Low' : 'Critically Low threshold value is invalid.',
  'Low' : 'Low threshold value is invalid.',
  'Critically High' : 'Critically High threshold value is invalid',
  'High' : 'High threshold value is invalid',

  normal_require : 'Both Low and High threshold value are required',
  normal : 'High threshold value should not be lower than Low threshold value',
  mn : 1,
  floatFixed : 0,
  critical_min_gap: 1
}

export default {
  BP : {
    ...common,
  },
  BG : {
    ...common,
    mn : 0.1,
    floatFixed : 1,
    critical_min_gap: 0.1
  },
  HS : {
    ...common,
    mn : 0,
    floatFixed : 1,
    critical_min_gap: 0.1
  },
  HR : {
    ...common,
  },
  TM : {
    ...common
  },
  PO : {
    ...common
  }
}
