import gql from 'graphql-tag'

export default gql`
  query organization($id: EID!) {
    organization(
      id: $id
    ) {
      orgSchedules {
        id
        affectiveDays
        blockingRoles
        startTime
        endTime
      }
    }
  }
`;