import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import I18N from '../../I18N';
import { getVitalsBasedOnIcdCodes } from '../API/query';
import { CCM_CONDITION_TO_ICD, CONDITIONS_TO_VITALS, RPM_CONDITION_TO_ICD } from '../constant/programEligibility';
import { eligiblePrograms, PROGRAM_ELIGIBILITY_KEYS, recommendVitalsList } from '../fields/ProgramEligibility';
import { getValidICDCodes } from '../helper';
import FormRenderer from './FormRenderer';

const VitalsAndMonitoring = ({ form, disabledPrograms, disabledVitals, conditions, icdCodes, overlay, vitalsInitialValue, eligibleProgramsInitialValue }) => {
  const disabledProgramKeys = useMemo(() => disabledPrograms && disabledPrograms.map(x => x.key), [disabledPrograms]);
  const fields = [
    recommendVitalsList({ className: 'vitals-programs', disabledKeys: disabledVitals, initialValue: vitalsInitialValue }),
    eligiblePrograms({ className: 'vitals-programs', disabledKeys: disabledPrograms, initialValue: eligibleProgramsInitialValue }),
  ];

  // Setting Programs based on conditions
  useEffect(() => {
    if (!conditions || conditions.length === 0) return;
    let values = [];
    const rpmIntersection = _.intersection(_.keys(RPM_CONDITION_TO_ICD), conditions);
    const ccmIntersections = _.intersection(_.keys(CCM_CONDITION_TO_ICD), conditions);
    if (rpmIntersection.length === 1) {
      values = ['RPM'];
    }
    if (rpmIntersection.length > 0 && ccmIntersections.length > 1) { 
      values = ['CCM', 'RPM'];
    } 

    values = values.filter(v => !disabledProgramKeys.includes(v));
    form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: values });
  }, [conditions, disabledProgramKeys]);

  // Setting Programs based on ICD codes
  // useEffect(() => {
  //   if (!icdCodes || icdCodes.length === 0) return;
  //   let values = [];
  //   const rpmCount = _.reduce(RPM_CONDITION_TO_ICD, (res, v) => {
  //     const validCodes = getValidICDCodes(v, icdCodes); 
  //     return validCodes.length > 0 ? res + 1 : res;
  //   }, 0);
  //   const ccmCount = _.reduce(CCM_CONDITION_TO_ICD, (res, v) => {
  //     const validCodes = getValidICDCodes(v, icdCodes); 
  //     return validCodes.length > 0 ? res + 1 : res;
  //   }, 0);
  //   if (rpmCount === 1) {
  //     values = ['RPM'];
  //   }
  //   if (rpmCount > 0 && ccmCount > 1) {
  //     values = ['CCM', 'RPM'];
  //   }

  //   values = values.filter(v => !disabledProgramKeys.includes(v));
  //   console.log('123123', disabledProgramKeys, values, form.getFieldsValue());
  //   form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: values });
  // }, [icdCodes, disabledProgramKeys]);

  const debouncedGetAndSetVitalsBasedOnIcdCodes = useCallback(_.debounce(async (icdCodes) => {
    try {
      const res = await getVitalsBasedOnIcdCodes(icdCodes);
      const goalsTemplate = _.get(res, 'data.getClinicGoalTemplates', []);
      const vitalsForEachCode = _.map(goalsTemplate, v => _.get(v, 'vitals', []));
      const valueSet = new Set(_.flatMap(vitalsForEachCode));
      const valueArray = Array.from(valueSet);

      return valueArray;
    } catch (e) {
      console.error(e);
      return [];
    }
  }, 200), []);

  // Setting vitals based on conditions and ICD codes
  useEffect(() => {
    if ((!icdCodes || icdCodes.length === 0) && (!conditions || conditions.length === 0)) return;
    const getVitals = async () => {
      // Adding vitals from conditions
      const conditionVitals = _.map(conditions, c => CONDITIONS_TO_VITALS[c] || null).filter(v => v !== null);
      let vitalsSet = new Set(conditionVitals);
      form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: Array.from(vitalsSet) });

      // Adding vitals from ICD codes
      if (icdCodes && icdCodes.length > 0) {
        const icdCodeVitals = await debouncedGetAndSetVitalsBasedOnIcdCodes(icdCodes);
        vitalsSet = new Set([...icdCodeVitals, ...conditionVitals]);
        form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: Array.from(vitalsSet) });
      } 
    }

    getVitals();
  }, [icdCodes, conditions]);

  return (
    <div style={{ position: 'relative' }}>
      {overlay}
      <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.vitalsHeader')}</div>
      <div>{I18N.get('referAndEnroll.vitalsSubheader')}</div>
      <hr />
      <FormRenderer form={form} fields={fields} />
    </div>
  );
}

export default VitalsAndMonitoring;
