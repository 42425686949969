import gql from 'graphql-tag'
export default gql `
    mutation login2FA(
      $token:String!,
      $role:[String]!,
      $code:String!
    ){
        login2FA(
          token:$token,
          role:$role,
          code: $code
        ){
          id
          sessionToken
          token
          role {
            refId
            name
            category
            organization {
              id
              name
            }
            access {
              action
              rule
            }
          },
          access {
            action
            rule
          }
          appSettings {
            role {
              refId
              name
              category
            }
          }
        }
    }
`
