import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input } from 'antd';

import withTeamMembersByPatient from '../helpers/withTeamMembersByPatient';
import { ROLE_MAP } from '../../../lib/constants';
import withRoleInfo from '../helpers/withRoleInfo';

const ReviewSubmitAdditionalOptionsFormItemComponent = ({
  patientId,
  form,
  roleInfo,
  primaryDoctor,
  // the below only available when isMD != true
  teamMembers,
}) => {
  const { getFieldDecorator } = form;


  const renderMDOptions = () => {
    return (
      <Form.Item
        label="Provider's comment"
      >
        {
          getFieldDecorator('comment')
            (
              <Input.TextArea
                placeholder="Type here (optional)"
              />
            )
        }
      </Form.Item>
    );
  };

  const renderCareTeamOptions = () => {
    const { id: primaryDoctorId = '' } = primaryDoctor || {};
    const patientDoctors = _.filter(teamMembers, (member) => {
      const { id, roleType } = member;
      if (id === primaryDoctorId) {
        // shift to front later
        return false;
      }
      return roleType === ROLE_MAP.Doctor;
    });

    if (primaryDoctorId) {
      patientDoctors.unshift(primaryDoctor);
    }

    return (
      <Form.Item
        label="Reviewing with"
      >
        {
          getFieldDecorator('reviewedBy', {
            initialValue: primaryDoctorId,
            rules: [{ required: true, message: 'Provider is required' }],
          })
            (
              <Select>
                {
                  _.map(patientDoctors, (doctor) => {
                    const { 
                      id, 
                      profile,
                    } = doctor || {};
                    return (
                      <Select.Option key={id} value={id}>
                        {/* Care team is working with doctor */}
                        {/* Don't need to shorten doctor name here */}
                        {profile.fullName}
                      </Select.Option>
                    );
                  })
                }
              </Select>
            )
        }
      </Form.Item>
    );
  };

  return (
    <div className='review-submit-additional-options-component'>
      {
        roleInfo.isMD
          ? renderMDOptions()
          : (
            roleInfo.isCareTeam
              ? renderCareTeamOptions()
              : null
          )
      }
    </div>
  );
};

ReviewSubmitAdditionalOptionsFormItemComponent.propTypes = {
  patientId: PropTypes.string,
  form: PropTypes.object.isRequired,
  primaryDoctor: PropTypes.object,
};

export default withRoleInfo(
  (
    withTeamMembersByPatient(
      ReviewSubmitAdditionalOptionsFormItemComponent,
      ({ roleInfo }) => roleInfo.isMD,
    )
  )
);