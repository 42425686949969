import React from "react";
import { Row,Col } from "antd";
import moment from "moment";
import { parseSubscriptionStatus } from '../constant/index';
export const SubscriptionHistoryComponent = (props) => {
	const { subscriptionList = [] } = props;
	const header = <p style={{ fontWeight:'bold',marginBottom:10}}>Subscription History</p>;
	const list = <div style={{ height:200,overflowY:'scroll' }}>{subscriptionList.map(({createdAt,status}) => <Row style={{display:'flex',flexDirection:'row'}}>
		<Col style={{width:'fit-content'}}>{moment(createdAt).format('MM/DD/YYYY')}</Col>
		<Col style={{width:'fit-content',marginLeft:10 }}>{parseSubscriptionStatus(status,'HISTORY').label}</Col>
	</Row>)}</div>
	return <div>
		{header}
		{list}
	</div>
}
