import { createContainer, goPath } from '../../../../lib/utils';
import TopBar from '../components/TopBar';
import RequestCache from 'libModule/requestCache'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const Container = createContainer(TopBar, (state, ownProps) => ({
  ...ownProps,
}), (dispatch, ownProps) => ({
  enrollButtonClick: () => {
    const _currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
    const patientName = _currentPatient.profile.fullName
    createAuditLog({action:I18N.get('auditLog.provider.patient.enrollInNewProgram.enroll_in_new_program'), patientNRIC, patientName })
    goPath(`/patients/${ownProps.patientId}/enrolledprogram/new`);
  },
  goCurrentProgramTab: () => {
    ownProps.updateTabSelected('current');
    goPath(`/patients/${ownProps.patientId}/enrolledprogram/current`);
  },
  goPastProgramTab: () => {
    ownProps.updateTabSelected('past');
    goPath(`/patients/${ownProps.patientId}/enrolledprogram/past`);
  }
}));

Container.displayName = 'patient/main/containers/TopBar';

export default Container;
