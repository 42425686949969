import gql from 'graphql-tag'
import { loginResponse } from './schema/Login';

export default gql `
  mutation loginWithToken($token: Token!) {
    loginWithToken(token: $token) {
      ${loginResponse}
    }
  }
`;
