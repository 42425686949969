import { React } from 'ihcomponent'
import {createContainer, goPath, mapPropsToFields, FormHelper, GQLHelper} from 'libModule/utils'
import { withApollo } from 'react-apollo'
import ImportPatientInfo from '../components/ImportPatientInfo'
import actions from '../actions/ImportPatientInfo'
import searchAction from '../actions/SearchPatientResultModal'
import searchPatientGQL from 'modulesAll/graphql/searchPatient'
import {modalAction} from 'modulesAll/common/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const Container = class extends React.Component{
    static displayName = 'patientList/registerNew/containers/ImportPatientInfo'

    getProps(){
        const {dispatch, client} = this.props

        const formValue = FormHelper.getValue(this.props.ImportPatientInfo.fieldsValue)
        return {
            ...this.props,
            onFieldsChange : (fieldsProps, fields)=>{
                dispatch(actions.updateForm(fieldsProps, fields))
            },
            mapPropsToFields,
            handleCancelClick : ()=>{
                goPath('/patients')
            },
            formError : !formValue,
            handleImportClick : async ()=>{
                const value = formValue
                if(false === value) return false

                const variables = {
                    firstName : value.firstName || '',
                    lastName : value.lastName || '',
                    // gender : value.gender,
                    dob : value.DOB.format('YYYY-MM-DD'),   // query from NEHR use this format
                    id : value.nric
                }
                // console.log(variables)

                //graphql query
                try{
                    const res = await client.query({
                        query : searchPatientGQL,
                        variables,
                        fetchPolicy: 'network-only'
                    })
                    // console.log(res.data.searchPatient)
                    if(res.data.searchPatient){
                      createAuditLog({
                        action: I18N.get('auditLog.provider.patient.searchImportPatient'),
                        details: variables,
                        request: variables,
                        response: res
                      })
                      dispatch(searchAction.setList(res.data.searchPatient))
                    }
                }catch(e){
                  createAuditLog({
                    action: I18N.get('auditLog.provider.patient.searchImportPatient'),
                    details: variables,
                    request: variables,
                    response: e,
                    success: false
                  })
                  dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
                }


            }
        }
    }

    render(){
        const p = this.getProps()

        return <ImportPatientInfo {...p} />
    }
}

const ComponentWithData = withApollo(Container)

export default createContainer(ComponentWithData, ({patient})=>{
    return {
        ...patient.register
    }
}, (dispatch)=>{
    return {
        dispatch,
        goPath
    }
})
