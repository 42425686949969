import React from 'react';
import ProviderAlertListTable from './ProviderAlertListTableComponent';

const ProviderAlertList = props => (
  <div className="vsm-main-page vsm-patientList-main">
    <div className="vsm-main-990">
      <div className="v-table">
        <ProviderAlertListTable {...props} />
      </div>
    </div>
  </div>
  );

ProviderAlertList.displayName = 'ProviderAlertListComponent';

export default ProviderAlertList;
