import { Tag, Button, Icon, Checkbox, Col } from 'antd';
import moment from 'moment';
import React from 'react';
import { LanguageAbbreviation } from '../../../../lib/constants'
import './ArticleOptionComponent.scss';

export const ArticleOptionComponent = ({
  label,
  link,
  isNew = false,
  hasVideo = false,
  sentOn,
  language,
  isSelected,
  excludeLanguageTags,
}) => {
  const renderLanguage = () => {
    const tagText = LanguageAbbreviation[language] || language;
    const isExcluded = _.includes(excludeLanguageTags, tagText);
    return tagText && !isExcluded
      ? (
        <Tag key={language} className="article-select-option-info__language">
          {tagText}
        </Tag>
      ) : null
  };

  return (
    <div className="article-select-option">
      <Col span={18} className="article-select-option-info">
        {
          link
            ? (
              <span
                onClick={(e) => {
                  if (!link) return;
                  e.stopPropagation();
                  window.open(link, '_blank');
                }}
                className="article-select-option-info__link"
              >
                {label}
              </span>
            )
            : label
        }
        {isNew && <Tag color="cyan">New</Tag>}
        {renderLanguage()}
        {hasVideo && <Tag color="blue" className="new-video-tag"><img src="/image/play-button.svg" />With Video</Tag>}
      </Col>
      <Col span={6} className="article-select-option-icon">
        {
          isSelected
            ? <Checkbox checked />
            : (
              <React.Fragment>
                <div className="article-select-option-icon__last-sent">
                  {
                    sentOn !== undefined
                    && `Last sent on ${moment(sentOn).format('MM/DD/YYYY')}`
                  }
                </div>
                <Icon type="plus-square" style={{ color: 'var(--dark-blue)' }} />
              </React.Fragment>
            )
        }
      </Col>
    </div>
  );
};
