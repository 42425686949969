import React, { Component } from 'react';
import {Col, Row, Form, Checkbox} from "antd";
import I18N from '../../I18N';
const {Item} = Form;

class VisitRemoteEnrollCheckBox extends Component{
    render() {
        const { getFieldDecorator } = this.props.form;
        const { isRemoteEnrollment } = this.props;
        return (
            <Row gutter={16}>
                <Col span={24}>
                    <Item>
                        {getFieldDecorator('remoteStatus',{
                            valuePropName: 'checked',initialValue:isRemoteEnrollment
                        })
                        (
                            <Checkbox >{I18N.get('remoteEnrollment.addText')}</Checkbox>
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }
}

export default VisitRemoteEnrollCheckBox;
