import React from 'react';
import {Steps, Row, Col, Tabs, message, Spin} from "antd";
const { Step } = Steps;
const { TabPane } = Tabs;
import DetailFormComponent from "./DetailFormComponent";
import moment from "moment-timezone";
import helper from "../../helper";
import OrgPermissionsMap from '../../constant/OrgPermissionsMap';
import ClinicFeatureConfigComponent from "./ClinicFeatureConfigComponent";
import API from "../../API";
const wrapperStyle = {
     display:'flex'
};

const EditClinicComponent = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            editMode: false
        }
    }

    setParentName = (curParentName) => {
        this.setState({
            curParentName
        })
    }

    // editClinic = (localThis) => {
    //     const { refetch, form, organization } = localThis.props;
    //     const { validateFields } = form;
    //     const id = organization.id;
    //     // const isDemo = localThis.state.isDemo;
    //     const prevParent = organization.parent;
    //     const { managers } = organization
    //     const prevClinicManagerList = _.map(managers, m => m.id)
    //
    //     validateFields((err,res) => {
    //         if(!err) {
    //             let { internalName, businessName, brandName, description, timeZone, areaBelong,
    //                 clinicNumber, location, city, state, zip, siteManager, consentForm, orgPermissions,
    //                 visitServeTimeStart, visitServeTimeEnd,isDemo
    //             } = res;
    //
    //             this.setState({
    //                 creatingOrg: true
    //             })
    //
    //             const address = {}
    //             address.state = state;
    //             address.postCode = zip;
    //             address.city = city;
    //             address.streetName = location;
    //             address.streetNumber = '121'
    //             address.country = 'US'
    //
    //             const phone = {}
    //             phone.countryCode = '1';
    //             phone.number = clinicNumber ? clinicNumber : ' ';
    //             phone.type = 'WORK'
    //
    //             const variables = {
    //                 id,
    //                 name: businessName ? businessName : ' ',
    //                 internalName: internalName,
    //                 brandedName: brandName,
    //                 timezone: timeZone,
    //                 address,
    //                 parentId: areaBelong,
    //                 description,
    //                 logo:localThis.state.logo,
    //                 phone,
    //                 templateGroupIds: consentForm,
    //                 managerIds: siteManager,
    //                 excludeBilling: isDemo,
    //                 identifier: internalName,
    //                 orgPermissions,
    //             }
    //
    //             const dateFormat = 'YYYY-MM-DD';
    //             const dateTimeFormat = 'YYYY-MM-DD HH:mm';
    //             const visitServeTimezone = 'America/Los_Angeles';
    //             const startTime = moment.tz(`${moment().format(dateFormat)} ${visitServeTimeEnd}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();
    //             const endTime = moment.tz(`${moment().add(1, 'day').format(dateFormat)} ${visitServeTimeStart}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();
    //
    //             const clinicScheduleVariables = {
    //                 affectiveDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    //                 blockingRoles: ['MA', 'MD'],
    //                 startTime,
    //                 endTime,
    //             };
    //
    //             if (organization.orgSchedules.length > 0) {
    //                 clinicScheduleVariables.id = organization.orgSchedules[organization.orgSchedules.length - 1].id;
    //             } else {
    //                 clinicScheduleVariables.organizationId = id;
    //             }
    //
    //             const { addedList, removedList } = helper.getAddedAndRemovedIds(prevClinicManagerList, siteManager);
    //             const managersChanged = (addedList.length) || (removedList.length);
    //             const cb = ()=>{
    //                 message.info('Clinic details updated!')
    //                 refetch();
    //                 this.onCancelDetail();
    //             }
    //
    //             if(prevParent && (prevParent.id !== areaBelong)) {
    //                 this.renderPopUp(localThis, variables);
    //             } else {
    //                 API.editClinic(variables)
    //                     .then(() => {
    //                         if(managersChanged){
    //                             return helper.addAndRemoveManager(addedList, removedList, id)
    //                         }
    //                     })
    //                     .then(() => {
    //                         if (organization.orgSchedules.length > 0) {
    //                             return API.editClinicSchedule(clinicScheduleVariables);
    //                         } else {
    //                             return API.createClinicSchedule(clinicScheduleVariables);
    //                         }
    //                     })
    //                     .then((res) => {
    //                         cb();
    //                     })
    //                     .catch(e=>{
    //                         console.log(e);
    //                         message.error(e.message);
    //                     })
    //             }
    //         }
    //     })
    // }

    onCancelDetail = () => {
        this.setState({
            showDetail: false,
            curParentName: null
        })
    }

    renderPopUp = (localThis, variables) => {
        const { organization, refetch } = localThis.props;
        // If any change apply to this clinic's internal name, use the updated name first, then use the previous internal name
        const clinicName = variables.internalName || organization.internalName;
        const curName = this.state.curParentName;
        const curThis = this
        const msg = 'Are you sure you want to move ' + clinicName + ' under ' + curName;

        confirm({
            title: msg,
            onOk() {
                API.editClinic(variables)
                    .catch( e => {
                        console.log(e);
                        return
                    })
                    .then( res => {
                        message.info('Clinic details updated!')
                        refetch();
                        curThis.setState({
                            showDetail: false
                        })
                    })
            }
        });
    }

    renderFeatureConfig = ()=>{
        const { orgsLoading,templateLoading,loading,organization }  = this.props;
         if(!organization|| orgsLoading||templateLoading||loading ) {
            return <div className='spinnerWrapper'><Spin/></div>
         }
        return _.map(OrgPermissionsMap,({header,description,permissionKey,actionTitle,style})=>{
           return <ClinicFeatureConfigComponent header={header} style={style} description={description} actionTitle={actionTitle} organization={organization} permissionKey={permissionKey} key={permissionKey}/>
        })
    }

    renderStepSel = ()=>{

        return <Tabs tabPosition="left" className='editClinicSteps'>
                    <TabPane tab="Basic Information" key="1">
                        { this.basicInfo() }
                    </TabPane>
                    <TabPane tab='Portal Feature Customization' key='2' className={'portalFeatureWrapper'}>
                        {this.renderFeatureConfig()}
                    </TabPane>
               </Tabs>
    }

    basicInfo=()=>{
        const { props,setFormEnabled,state } = this;
        const { editMode, curParentName } = state;
        const { orgsLoading,templateLoading,loading,organization }  = props;
        if(!organization|| orgsLoading||templateLoading||loading ) {
            return  <div className='spinnerWrapper'><Spin/></div>
        }
        const isDemo = organization.excludeBilling ? organization.excludeBilling : false;
        const managers = organization.managers ? organization.managers : [];
        const templateGroups = organization.templateGroups
        let templateGroupIds = [];
        let managerIds = [];
        _.forEach(managers, (manager) => {
            managerIds.push(manager.id);
        })
        _.forEach(templateGroups, (template) => {
            templateGroupIds.push(template.id);
        })
        return <DetailFormComponent setFormEnabled={setFormEnabled} editMode={editMode} {...this.props} curParentName={curParentName} onCancelDetail={this.onCancelDetail} isDemo={isDemo} managerIds={managerIds} templateGroupIds={templateGroupIds} setParentName={this.setParentName}/>

    }
    setFormEnabled = (editMode)=>{
        this.setState({
            editMode
        })
    }
    render() {
        return <Row className={'EditClinicWrapper'}>
                <Col style={{fontSize:20,color:'#222222',width:270,'borderRight':'1px solid #e8e8e8',padding:'24px'}}>
                        <span>Clinic Settings</span>
                </Col>
                <Col span={24} style={wrapperStyle}>
                    { this.renderStepSel() }
                </Col>
               </Row>

          {/*{...this.props} editClinic={this.editClinic} onCancelDetail={this.onCancelDetail} isDemo={isDemo} managerIds={managerIds} templateGroupIds={templateGroupIds} setParentName={this.setParentName}/>*/}
    }
}


export default EditClinicComponent;

