import helper from "../../taskAssignment/helper";
import { ROLE_MAP, ROLES_BY_COMPLEXITY } from "../../../lib/constants";

export const getIdNameMapByRole = (role)=>{
	const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
	const teamsFromLoginUser = loginUser.team||[];
	const roles = Object.keys(ROLE_MAP).filter(k=>ROLE_MAP[k]==role);
	return helper.getMembersByRole(teamsFromLoginUser,roles).reduce((result, curr) => {
		result.push({
			fullName:curr.fullName,
			id:curr.id
		})
		return result;
	}, []);
}

// export const getAssigneeOptionLists = (assignees) => {
//   const RDs = helper.getAssigneeMembersByRole(assignees, 'RD');
//   const HCs = helper.getAssigneeMembersByRole(assignees, 'HC');
//   const CAs = helper.getAssigneeMembersByRole(assignees, 'CA');
//   return {
//     RDs: RDs || [],
//     HCs: HCs || [],
//     CAs: CAs || [],
//   };
// };

const getPatientLanguage = (patient) => {
  const { language, appLanguage } = _.get(patient, 'profile') || {};
  return _.get(language, 'code') || _.get(appLanguage, 'code') || 'EL';
}

export const isNotShow = (assignedToRDNotNecessary, assignedToCANotNecessary, assignedToRD, assignedToCA) => {
  return (assignedToRDNotNecessary && assignedToCANotNecessary) ||
	((assignedToRD && !assignedToRDNotNecessary) && assignedToCANotNecessary) ||
	(assignedToRDNotNecessary && (assignedToCA && !assignedToCANotNecessary));
}

export const sortUserList = (patient, complexity, userList) => {
  const patientLanguage = getPatientLanguage(patient);
  const rolesByComplexity = ROLES_BY_COMPLEXITY[complexity] || [];
  const sorted = (userList || []).sort((u1, u2) => {
    const providerLanguageCodes1 = _.map(_.get(u1, 'providerLanguage'), 'code');
    const providerLanguageCodes2 = _.map(_.get(u2, 'providerLanguage'), 'code');
    const orgAssignedPatientCount1 = _.get(u1, 'orgAssignedPatientCount') || 0;
    const orgAssignedPatientCount2 = _.get(u2, 'orgAssignedPatientCount') || 0;
    const occupancyRate1 = _.get(u1, 'occupancyRate') || 0;
    const occupancyRate2 = _.get(u2, 'occupancyRate') || 0;
    const complexityRank1 = ~~_.includes(rolesByComplexity, ROLE_MAP[_.get(u1, 'selectedRole')]);
    const complexityRank2 = ~~_.includes(rolesByComplexity, ROLE_MAP[_.get(u2, 'selectedRole')]);
    let i = -1;
    if(complexityRank1 < complexityRank2) {
      i = 1;
    } else if(complexityRank1 > complexityRank2) {
      i = -1;
    } else if(!_.includes(providerLanguageCodes1, patientLanguage) && _.includes(providerLanguageCodes2, patientLanguage)) {
      i = 1;
    } else if(_.includes(providerLanguageCodes1, patientLanguage) && !_.includes(providerLanguageCodes2, patientLanguage)) {
      i = -1;
    } else {
      if(orgAssignedPatientCount1 > orgAssignedPatientCount2)
        i = -1;
      else if(orgAssignedPatientCount1 < orgAssignedPatientCount2)
        i = 1;
      else if(occupancyRate1 > occupancyRate2)
        i = 1;
    }
    return i;
  });
  return sorted;
}
