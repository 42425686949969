import gql from 'graphql-tag';

export default gql`
mutation updateHubMember($memberId:EID!,$id:EID,$serialNumber:String){
    updateHubMember(memberId:$memberId,
        serialNumber:$serialNumber,id:$id){
        id
        member{
            profile{
                fullNameWithNickname
            }
        }
        devices{
            id
            type
        }
        serialNumber
    }
}`
