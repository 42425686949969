import React from 'react';
import moment from "moment/moment";
import { Table,Button } from 'antd';
import visitMap from '../../visit/constants/map';

const VisitForMAComponent = class extends React.Component{
    visitTableView = (visitList)=>{
        const { setReschedule } = this.props;
        const columns = [
            {
               key:'video',
               dataIndex:'index'
            },
            {
                key:'appointmentAtDate',
                dataIndex:'appointmentAt',
                title:'Date',
                render:(t,doc)=>moment(t).format('MM/DD/YYYY')
            },
            {
                key:'appointmentAtTime',
                dataIndex:'appointmentAt',
                title:'Time',
                render:(t,doc)=>moment(t).format('hh:mm a')
            },
            {
                key:'provider',
                dataIndex:'providers',
                title:'Provider',
                render:(p,doc)=>_.get(p,'0.profile.fullName')
            },
            {
                key:'type',
                dataIndex:'type',
                title:'Type',
                render:(t,doc)=>visitMap[t]
            },
            {
                key:'',
                render:(v,doc)=><a onClick={()=>setReschedule(doc)}><img src='/image/edit.png' width={20}/></a>
            }
        ];

        return <Table dataSource={visitList} columns={columns} pagination={false}/>
    }
    render(){
        const { visitList } = this.props;
        return this.visitTableView(visitList);
    }
}

export default VisitForMAComponent;
