import Tab from './Tab';
import Suspend from './Suspend';
import { combineReducers } from 'redux';
import program from '../../programTab/reducers';
import Careplan from 'patientModule/Careplan/CareplanDuck';
import device from '../../deviceTab/reducers';
import profileEdit from 'modulesAll/patient/profileEdit/reducers';
import register from 'modulesAll/patientList/registerNew/reducers';
import enrolledProgramInfo from '../../enrolledProgramInfo/reducers';
import types from '../constants'

const thisYear = ''+new Date().getFullYear();
const INITIAL_STATE = thisYear;


const Year = (state=INITIAL_STATE,action={})=>{
    switch (action.type) {
        case types.CARE_YEAR_CHANGE:
          return action.payload;
        default:
          return state;
    }
}

const reducer = combineReducers({
  main: combineReducers({
    Tab,
    Suspend,
    Year
  }),
  program,
  Careplan,
  device,
  register,
  enrolledProgramInfo,
  profileEdit
});

export default {
  patient: reducer,
};
