import React from 'react';
import  helper  from '../../helper'
import {Form, Button, message} from 'antd';
import API from '../../API';
import {compose, graphql} from "react-apollo";
import query from "../../query";

const { renderFormByKey } = helper;
let AreaEditComponent = class extends React.Component{
    constructor(props) {
        super(props);
        const { organization: { managers } }= props
        this.state = {
            userList: null,
            loadingMember: true,
            currentAreaManagerList:_.map(managers,m=>m.id),
            selectedMember: _.map(managers,m=>m.id),
        }
    }

    onSelectMember = (val)=>{
        let { selectedMember } = this.state;
        this.setState({
            selectedMember: [...selectedMember,val]
        })
    }

    onDeselectMember = (val)=>{
        let { selectedMember } = this.state;
        this.setState({
            selectedMember: _.filter(selectedMember,(m)=>m!==val)
        })
    }

    editOrg=()=>{
        const { props,state } = this;
        const { setShowEditModal,form,organization,refetchOrg } = props;
        const id  = _.get(organization,'id');
        let { name, timezone,description,parentId } = form.getFieldsValue();
        const { selectedMember,currentAreaManagerList } = state;
        const { addedList,removedList } = helper.getAddedAndRemovedIds(currentAreaManagerList,selectedMember);
        const managersChanged = (addedList.length)||(removedList.length);
        let variables = {
            name,timezone,description,id,parentId
        };
        const cb = ()=>{
            message.info('Area Updated');
            setShowEditModal(false);
            refetchOrg&&refetchOrg();
        }
        API.editOrg(variables).then(res=>{
            if(managersChanged){
                helper.addAndRemoveManager(addedList,removedList,id).then(res=>{
                    cb();
                }).catch(e=>message.error(e.message));
            }else{
                cb();
            }
        }).catch(e=>message.error(e.message));
    }

    render() {
        const {currentAreaManagerList,selectedMember } = this.state;
        const localThis = this;
        return<div>
            { renderFormByKey(localThis,'createAreaForm') }
            <div className={'createAreaButtonWrapper'}>
                <Button onClick={ this.editOrg } type={'primary'}>Save</Button>
            </div>
        </div>
    }
};

AreaEditComponent = Form.create({})(AreaEditComponent);
const userList = graphql(query.userListWithOnlyRoles,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const { organization } = ownProps;
        const { roles } = organization;
        const refId = _.get(helper.filterRefIdByNam(roles,'Admin'),'refId');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                roles:refId&&[ refId ]
            }
        }
        return {
            variables,
            skip:!refId,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { userList,loading,refetch } = data;
        return {
            areaManagerList: _.get(userList,'data',[]),
            areaManagerListLoading: loading,
            refetchOrgList: refetch
        }
    }
})
const areaList = graphql(query.orgListWithOnlyNameAndId,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const rootId = _.get(currentUser,'selectedRole.organization.id');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                leafOnly: false,
                rootId
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organizationList,loading,refetch } = data;
        return {
            areaList: _.get(organizationList,'data',[]),
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})

export default compose(areaList,userList)(AreaEditComponent);
