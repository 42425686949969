export default [
    { key: "NO_ANSWER_LEFT_VOICEMAIL", value: "No answer/left voicemail" },
    { key: "NEED_TO_THINK_ABOUT_DOCTOR_FIRST", value: "Needs to think about it/talk to doctor first" },
    { key: "VERY_INTERESTED", value: "Very interested, wants to enroll in the clinic" },
    { key: "CHANGE_PCP", value: "Changed PCP; relocated" },
    { key: "CALL_FAILED", value: "Call failed" },
    { key: "REJECTED_NO_INTEREST", value: "Rejected: No interest" },
    { key: "REJECTED_LANGUAGE_BARRIER", value: "Rejected: Language barrier" },
    { key: "REJECTED_COPAY_CONCERN", value: "Copay concern" },
    { key: "REJECTED_NOT_APPROPRIATE", value: "Rejected: Not appropriate at this time" },
    { key: "REJECTED_STRONG_TECH_BARRIER", value: "Rejected: Strong tech barrier" },
    { key: "REJECTED_DOES_NOT_TRUST_THE_PROGRAM", value: "Rejected: Does not trust the program" },
    { key: "NO_SMART_PHONE", value: "Don't have a smartphone" },
    { key: "OTHER", value: "Other" }
];
