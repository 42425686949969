import React from 'react';

const Component = (props) => {

  return <div style={{margin: '5em'}}>Not authorized to view this page</div>

}

Component.displayName = 'NotAuthorized';

export default Component
