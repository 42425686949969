import React from "react"
import { Button } from "antd";

export default class CopyTextToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: false,
    };
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand('copy');
    this.setState({ copySuccess: true });
    this.timer = setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 2000);
  }

  render() {
    const { copySuccess } = this.state;
    const { value, buttonText, children } = this.props;
    return (
      <div>
        <div>
          <textarea
            readOnly
            style={{ position: 'absolute', left: -9999 }}
            ref={(textarea) => this.textArea = textarea}
            value={value}
          />
        </div>
        <div>
          {children 
          ? <span onClick={() => this.copyCodeToClipboard()}>{children}</span>
          : <Button onClick={() => this.copyCodeToClipboard()}>
            {copySuccess ? 'Copied!' : buttonText}
          </Button>}
        </div>
      </div>
    )
  }
}