import React from 'react';
import { Icon } from 'antd';
import { userDeviceRecordsTypeName } from '../../visitWorkList/constant/onboardMap';
import EXCLUSION_REASONS_ARRAY from '../../remoteEnrollment/constant/excludedReasons';
import I18N from '../../I18N';

export const VITAL_TYPE_ENUM = { BG: 'BG', BP: 'BP', HS: 'HS', HR: 'HR', PO: 'PO', ECG: 'ECG', TM: 'TM' };
export const VITAL_TYPE_MAP = { BG:'Blood Glucose',HS:'Weight',TM:'Temperature',BP:'Blood Pressure',PO:'Blood Oxygen' }
export const ELIGIBLE_PROGRAMS = { CCM: 'Chronic Care Management (CCM)', RPM: 'Remote Patient Monitoring (RPM)',VALUE_BASED:'Value-Based' }
export const ELIGIBLE_PROGRAMS_ENUM = { CCM: 'CCM', RPM: 'RPM' };

export const conditionToNameMap = {
  'DM': 'Diabetes',
  'PreDM': 'Pre-Diabetes',
  'HTN': 'Hypertension',
  'Obesity': 'Obesity',
  'ESRD_Dialysis': 'Chronic Kidney Disease', 
  'CKD': 'Chronic Kidney Disease',
  'CHF': 'Congestive Heart Failure',
  'COPD': 'Chronic Obstructive Pulmonary Disease',
  'HLD': 'Hyperlipidemia'
};

// Temporary solution: get sample ICD code for clinic goal template
export const conditionToCode = {
  'DM': 'E08.00', // Glucose Management
  'PreDM': 'R73.01', // // Glucose Management
  'HTN': 'I10', // // Blood Pressure Management
  'Obesity': 'E66.01', // // Weight Reduction Management
  'ESRD_Dialysis': 'I97.130', // Chronic Kidney Disease
  'CKD': 'I97.130', // Chronic Kidney Disease
  'CHF': 'I38', // Congestive Heart Failure
  'COPD': 'J96.11', // Chronic Obstructive Pulmonary Disease
  'HLD': 'E78.00' // Lipid Management
};

export const CONSENT_FORM_KEYS = _.mapKeys(['template', 'consentType', 'consentDate', 'careTeamSignature', 'providers']);
export const consentFormConstants = {
  CONSENT_INPUT_WIDTH: 348,
  RE_CONSENT_TYPE_MAP: [0, 1, 2, 3],
  DEFAULT_CONSENT_LANGUAGE: { value: 'EL', groupId: null },
};

export const visitConstants = {
  VISIT_DATE_FORMAT: 'MM/DD/YYYY',
  DEFAULT_VISIT_TYPE: 'INIT',
};

export const CONFIRM_DEVICE_KEYS = _.mapKeys(['deliveryType', 'giveoutDevices', 'hasRouter', 'streetName', 'unit', 'city', 'state', 'postCode', 'cuffSize', 'helpedSetupAndUseDevice']);
export const DELIVERY_TYPE_ENUM = _.mapKeys(['PICK_UP', 'SHIP_TO_PATIENT']);
export const confirmDeviceConstants = {
  STEP_FIELDS: {
    0: [CONFIRM_DEVICE_KEYS.deliveryType],
    1: [CONFIRM_DEVICE_KEYS.hasRouter, CONFIRM_DEVICE_KEYS.giveoutDevices, CONFIRM_DEVICE_KEYS.cuffSize],
    2: [CONFIRM_DEVICE_KEYS.streetName, CONFIRM_DEVICE_KEYS.city, CONFIRM_DEVICE_KEYS.state, CONFIRM_DEVICE_KEYS.postCode],
    3: [CONFIRM_DEVICE_KEYS.techHelp]
  },
  STEP_INDEX_MAP: {
    [CONFIRM_DEVICE_KEYS.deliveryType]: 0,
    [CONFIRM_DEVICE_KEYS.hasRouter]: 1,
    [CONFIRM_DEVICE_KEYS.giveoutDevices]: 1,
    [CONFIRM_DEVICE_KEYS.cuffSize]: 1,
    [CONFIRM_DEVICE_KEYS.streetName]: 2,
    [CONFIRM_DEVICE_KEYS.unit]: 2,
    [CONFIRM_DEVICE_KEYS.city]: 2,
    [CONFIRM_DEVICE_KEYS.state]: 2,
    [CONFIRM_DEVICE_KEYS.postCode]: 2,
    [CONFIRM_DEVICE_KEYS.helpedSetupAndUseDevice]: 3,
  },
  DELIVERY_TYPE: [
    { label: 'Pick up from clinic', value: DELIVERY_TYPE_ENUM.PICK_UP },
    { label: 'Ship to patient', value: DELIVERY_TYPE_ENUM.SHIP_TO_PATIENT },
  ],
  DEVICES: [
    { value: userDeviceRecordsTypeName.Ease, vital: 'BP', imgSrc: '/image/referandenroll/bp_bp3l.svg', displayName: 'Blood Pressure Monitor', otherName: 'iHealth Ease' },
    { value: userDeviceRecordsTypeName.BG5S, vital: 'BG', imgSrc: '/image/referandenroll/bg_bg5s.svg', displayName: 'Blood Glucose Monitor', otherName: 'iHealth Gluco+' },
    { value: userDeviceRecordsTypeName.Nexus, vital: 'HS', imgSrc: '/image/referandenroll/hs_nexus.svg', displayName: 'Weight Scale', otherName: 'iHealth Nexus' },
    { value: userDeviceRecordsTypeName.Oximeter, vital: 'PO', imgSrc: '/image/referandenroll/po_po3.svg', displayName: 'Oximeter', otherName: 'iHealth PO3' },
    { value: userDeviceRecordsTypeName.Thermometer, vital: 'TM', imgSrc: '/image/referandenroll/tm_ts28b.svg', displayName: 'Thermometer', otherName: 'iHealth TS28B' },
  ],
  CLEAR: { value: userDeviceRecordsTypeName.TrackSet, vital: 'BP', imgSrc: '/image/referandenroll/bp_bpm1.svg', displayName: 'Blood Pressure Monitor', otherName: 'iHealth BPM1' },
  LOANER_PHONE: { value: userDeviceRecordsTypeName.iPhone, vital: undefined, imgSrc: '/image/referandenroll/loanerPhone.svg', displayName: 'Unified Care Loaner Phone', otherName: '' },
  ROUTER: { imgSrc: '/image/referandenroll/router.svg', displayName: 'Wi-Fi Router', value: 'ROUTER' },
  HOTSPOT: { imgSrc: '/image/referandenroll/hotspot.svg', displayName: 'Hotspot', value: 'HOTSPOT' },
  TECH_HELP: [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
  ],
  HAS_ROUTER: [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
  ],
  CUFF_SIZE: {
    [userDeviceRecordsTypeName.Ease]: [
      { label: 'Standard (22~36cm)', value: 'STANDARD_CUFF_SIZE' },
      { label: 'Mini (17~22cm)', value: 'MINI_CUFF_SIZE' },
      { label: 'Large (30~42cm)', value: 'LARGE_CUFF_SIZE' },
      { label: 'Extra Large (42~48cm)', value: 'X_LARGE_CUFF_SIZE' },
    ],
    [userDeviceRecordsTypeName.TrackSet]: [
      { label: 'Standard (22~36cm)', value: 'STANDARD_CUFF_SIZE' },
      { label: 'Mini (17~22cm)', value: 'MINI_CUFF_SIZE' },
      { label: 'Extra Large (42~48cm)', value: 'X_LARGE_CUFF_SIZE' },
    ]
  },
  CUFF_SIZE_DISPLAY_NAME: {
    'STANDARD_CUFF_SIZE': 'Standard',
    'MINI_CUFF_SIZE': 'Mini' ,
    'LARGE_CUFF_SIZE': 'Large' ,
    'X_LARGE_CUFF_SIZE': 'Extra Large',
  },
  AUTOCOMPLETE_ADDRESS_MAP: {
    'street-address': CONFIRM_DEVICE_KEYS.streetName,
    'locality': CONFIRM_DEVICE_KEYS.city,
    'region': CONFIRM_DEVICE_KEYS.state,
    'postal-code': CONFIRM_DEVICE_KEYS.postCode,
    'country-name': ''
  }
};

export const MNT_CONDITIONS = ['DM', 'HLD', 'HTN', 'Obesity'];

export const EXCLUSION_REASONS_MAP = _.reduce(EXCLUSION_REASONS_ARRAY, (res, v) => { res[v.key] = v.value; return res; }, {});

export const MNT_PATIENT_DESCRIPTION = [
  'The patient has tried multiple strategies for lifestyle & behavioral modification to manage his/her condition.',
  'Patient is new to lifestyle change and behavioral modification, requires in-depth education',
];

export const STATUS_ENUM = {
  TODO: 'TODO',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const PROGRAMS_DISABLED_REASON_MAP = {
  onlyOneExceptHLD: <div><Icon type="info-circle" theme="filled" className="red-svg"/>&nbsp;{I18N.get('referAndEnroll.ccmDisabledReason')}</div> 
};
