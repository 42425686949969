import gql from 'graphql-tag';
import { User } from '../schema/User'
import { Identification } from '../schema/common'

export default gql`mutation doEditUser($id:EID!, $residency:InputMemberResidency,
  $address:[InputAddress], $phone:[InputPhone], $email:[EmailAddress],$isTestUser: Boolean,
  $userSettings:InputUserSettings, $commonProfile:InputCommonProfileEdit,
  $memberProfile:InputMemberProfile, $employeeProfile:InputEmployeeProfile,$identification:InputIdentification,$username:String) {
  editUser (
    id:$id,
    isTestUser:$isTestUser,
    residency:$residency,
    address:$address,
    phone:$phone,
    email:$email,
    userSettings:$userSettings,
    commonProfile:$commonProfile,
    memberProfile:$memberProfile,
    employeeProfile:$employeeProfile
    identification: $identification
    username: $username
  ) {
    id
    profile {
      firstName
      lastName
      nickname
      fullName
      fullNameWithNickname
      ...on MemberProfile {
        isSelfEnrolled
      }
    }
  }
}`

export const editUserWithSemiCompleteData = gql`mutation doEditUser($id:EID!, $residency:InputMemberResidency,
  $address:[InputAddress], $phone:[InputPhone], $email:[EmailAddress],
  $userSettings:InputUserSettings, $commonProfile:InputCommonProfileEdit,
  $memberProfile:InputMemberProfile, $employeeProfile:InputEmployeeProfile,$identification:InputIdentification,$username:String) {
  editUser (
    id:$id,
    residency:$residency,
    address:$address,
    phone:$phone,
    email:$email,
    userSettings:$userSettings,
    commonProfile:$commonProfile,
    memberProfile:$memberProfile,
    employeeProfile:$employeeProfile
    identification: $identification
    username: $username
  ) {
    ${User}
  }
}`

export const updateCoveredClinics = gql`
  mutation editUserSetting(
    $id: EID!,
    $userSettings: InputUserSettings
    ) {
      editUser (
        id: $id,
        userSettings: $userSettings
      ) {
        id
      }
    }
`

export const medicationManagement = gql`
  mutation editUserSetting(
    $id: EID!,
    $memberProfile: InputMemberProfile
    ) {
      editUser (
        id: $id,
        memberProfile: $memberProfile
      ) {
        id
      }
    }
`

export const editUserForMissingInfoQuery = gql`
  mutation doEditUser($id:EID!, $residency:InputMemberResidency,
    $address:[InputAddress], $phone:[InputPhone], $email:[EmailAddress],$isTestUser: Boolean,
    $userSettings:InputUserSettings, $commonProfile:InputCommonProfileEdit,
    $memberProfile:InputMemberProfile, $employeeProfile:InputEmployeeProfile,$identification:InputIdentification,$username:String) 
  {
    editUser (
      id:$id,
      isTestUser:$isTestUser,
      residency:$residency,
      address:$address,
      phone:$phone,
      email:$email,
      userSettings:$userSettings,
      commonProfile:$commonProfile,
      memberProfile:$memberProfile,
      employeeProfile:$employeeProfile
      identification: $identification
      username: $username
    ) 
    {
      id
      identification{
        ${Identification}
      }
      profile{
        firstName
        lastName
        nickname
        fullName
        fullNameWithNickname
        ... on MemberProfile {
          birthday
          isSelfEnrolled
          programCategories{
            name
            enrolled
            enrolledDate
          }
          healthConditions
          insuranceProvider
          mntHealthConditions {
              code
          }
          insuranceProvider2
          insuranceProviders{
            primary  
            insuranceProvider{
              payerName
              id
            }
          }
          
          billableHealthConditions {
            code
            description
          }
        }
      }
    }
  }
`;
