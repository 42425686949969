import React from 'react';
import moment from 'moment';
import {Avatar, Popover, Icon, Tooltip} from 'antd';
import roleMap from '../constant/roleMap';
import { ROLE_NAME } from '../../../lib/constants';

const roleOptions = {
    'IHEALTH_MEMBER': [
        { value: 'RD', text: 'RD'},
        { value: 'CA', text: 'CA'},
        { value: 'HC', text: 'HC'}, // TODO: check if classified correctly
    ],
    'CLINIC_MEMBER': [
        { value: 'MD', text: 'Provider' },
        { value: 'MA', text: 'MA'}
    ]
}

const commonFields = (tabSelected, localThis) => [
    {
        key: 'avatar',
        width: '3%',
        render: (t, doc) => {
        return <div style={{width: '100%'}}>
            <Avatar src={doc._get('profile.avatar.link') || '/image/default_avator.png'}/>
        </div>
        }
    },
    {
        title : 'Name',
        key : 'name',
        dataIndex: 'name',
        width : '15%',
        render : (t, doc) => doc._get('profile.fullName'),
        sorter: true
    },
    {
        title : 'Role',
        key : 'role',
        width : '8%',
        render : (t, doc) => roleMap[doc._get('role.name')],
        filters: roleOptions[tabSelected]
        // onFilter: (t, doc) => t.includes(doc._get('role.name'))
    },
    {
        title : 'Mobile Phone',
        key : 'mobilePhone',
        width : '12%',
        render : (id, doc) => doc.format('mobilePhone')
    },
    {
        title : 'Email',
        key : 'email',
        width : '15%',
        render : (t, doc) => _.get(doc._get('email'), '0.address'),
    },
    {
        title : 'Start Date',
        key : 'startDate',
        width : '12%',
        render : (t, doc) => {
            let date = doc._get('profile.startDate');
            if(date){
                date = moment(date).format('MM/DD/YYYY');
                return date;
            }
            return '-';
        },
    },
    {
        title : 'Care Team',
        key : 'team',
        width : '15%',
        render : (t, doc) => {
            const teams = [];
            _.map(_.get(doc,'team',[]), (team, i)=>{
                const teamOrganization = _.get(team, 'organization.id');
                const currentOrganization = _.get(localThis.props, 'organization.id');
                if(teamOrganization === currentOrganization){
                    teams.push(<div key={i}>{_.get(team, 'name', '--')}</div>);
                }
            });
            return <div style={{overflowY: 'auto', maxHeight: '240px' }}>
                {teams}
            </div>
        }
    }
];

const editField = (localThis) => [
    {
        key: 'remove',
        width: '5%',
        render: (t,doc) => {
          // icon source: https://material.io/resources/icons/?icon=more_vert&style=baseline
          return <Popover placement='left'
                  content={<a onClick={() => localThis.props.openRemoveModal(doc)}>Remove</a>}
                  trigger='hover'>
              <img src='/image/more_vert.svg' style={{width: '22px', height: '25px'}} />
            </Popover>
        }
    }
];

const statusField = (localThis) => [
    {
        title:'Status',
        key: 'status',
        width: '20%',
        render: (t, doc) => {
            return !!doc._getLastLogin() ? 'Active' :
            <div>
                <span style={{color: '#D01407'}}>Pending...</span>
                <Tooltip title='This user hasn’t logged in yet.'>
                    <Icon type='info-circle' theme='filled'/>
                </Tooltip>
                <br/>
                <a  style={{color: '#3177C9'}} onClick={() => localThis.props.resendInvitationLink(t)}>Resend Invitation Link</a>
            </div>
        }
    }
];


const tableMap =(tabSelected,localThis)=>({
    'IHEALTH_MEMBER':[
        ...commonFields(tabSelected, localThis),
        ...editField(localThis)
    ],
    'CLINIC_MEMBER':[
        ...commonFields(tabSelected, localThis),
        ...statusField(localThis),
        ...editField(localThis)
    ],
    'CARE_TEAM':[
        ...commonFields(localThis)
    ],
}[tabSelected]);

export default tableMap;
