import {Modal} from 'antd';
import React, {Component} from 'react';
import InputVisitFormComponent from "../components/InputVisitFormComponent";
import '../CSS/style.scss';
import {message} from "../../../../../package/IHComponent";
import {connect} from "react-redux";
import { GQLHelper } from 'lib/utils';
import CURRENT_COMPONENT from "../constants/CurrentComponent";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { API } from '../../visit/query/index';
import VisitInputDeleteConfirm from "../components/VisitInputDeleteConfirm";
import AppointmentCalenderWrapperContainer from '../../visit/containers/AppointmentCalenderWrapperContainer';

class EditVisitInDashboardContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            disableSaveButton: true,
            successDeleteCb: null,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.executeDelete = this.executeDelete.bind(this);
        this.editVisit = this.editVisit.bind(this);
        this.setDisableSaveButton = this.setDisableSaveButton.bind(this);
    }

    setDisableSaveButton(value){
        this.setState({
            disableSaveButton: value
        });
    }

    handleDelete(cb){
        this.setState({
            value: CURRENT_COMPONENT.DELETE_VISIT,
            successDeleteCb: cb,
        })
    }

    executeDelete(){
        const { successDeleteCb } = this.state;
        API.deleteVisit(this.props.visitInfo.id)
            .then((res) => {
                if (successDeleteCb) successDeleteCb();
                this.props.refetch();
                this.props.onCancel(true);
            }).catch(error => {
                console.log(error);
                this.props.openErrorModal(GQLHelper.formatError(error));
            })
    }

    editVisit(variables){
        API.updateVisitFromVisitList(variables)
            .then((res)=>{
                message.success('Appointment Updated');
                this.props.refetch();
                if(this.props.refetchUser) {
                    this.props.refetchUser();
                }
            this.props.onCancel(true);
        }).catch((error)=>{
            console.log(error);
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    renderSelectComponent(){
        const patient = _.get(this,'props.patient');
        const { remoteEnrollment, showPostIt, visitInfo, onCancel, timerName, refetchVisitListInPatientProfile, refetchNextVisit,rescheduledFromId } = this.props;
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT) {
            return  <AppointmentCalenderWrapperContainer
                        selectedVisit={visitInfo ? visitInfo : patient}
                        isRemoteEnrollment={!remoteEnrollment}
                        onSchedule={this.hideCalendar}
                        skipSearch
                        patient={patient}
                        remoteEnrollment={remoteEnrollment}
                        showPostIt={showPostIt === undefined ? true : showPostIt}
                        onCancel={onCancel}
                        isRemoteEnrollment={false}
                        timerName={timerName}
                        setPatient={this.setPatient}
                        refetchVisitListInPatientProfile={refetchVisitListInPatientProfile}
                        refetchNextVisit={refetchNextVisit}
                        rescheduledFromId={rescheduledFromId}
                    />
            // return <InputVisitFormComponent key={_.get(this, 'props.visitInfo.id')}
            //                                  visitInfo={this.props.visitInfo}
            //                                  patient={ this.props.patient || {user:patient}}
            //                                  editStatus={true}
            //                                  handleDelete={this.handleDelete}
            //                                  editVisit={this.editVisit}
            //                                  disableSaveButton={this.state.disableSaveButton}
            //                                  setDisableSaveButton={this.setDisableSaveButton}
            //                                  onCancel={() => this.props.onCancel()}
            //                                  timerName={this.props.timerName}/>
    }

        if(this.state.value === CURRENT_COMPONENT.DELETE_VISIT) {
            return (
                <VisitInputDeleteConfirm onCancel={() => this.props.onCancel()}
                                         executeDelete={this.executeDelete}
                />
            )
        }

    }

    getTitle(){
        if(this.state.value === CURRENT_COMPONENT.DELETE_VISIT){
            return "Please confirm"
        }
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return "Edit Visit"
        }
    }

    render() {
        Mixpanel.track('showed', 'edit a visit popup', '', {});
        return (
            <Modal 
                   width='90%'
                   style={{ top: 64 }}
                   bodyStyle={{ height: '90%' }}
                   title={this.getTitle()}
                   visible={true}
                   footer={null}
                   onCancel={() => this.props.onCancel()}
                   maskClosable={false}
                   destroyOnClose
            >
                {this.renderSelectComponent()}
            </Modal>
        );
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(EditVisitInDashboardContainer);
