import React, { useEffect, useRef, useState } from 'react';
import { Form, Rate, Button, Col } from 'antd';
import _ from 'lodash';
import TranslationInput from '../../translation/component/TranslationInput';
import helper from '../../smartAlert/helper';

const BUTTONS_STYLE = {
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: 10,
};

const FoodLogReviewComponent = ({ id, onSubmit, onCancel, form, style, isSubmitting, translationLang, userAvatar }) => {
  const [translatedText, setTranslatedText] = useState('');
  const [comment, setComment] = useState('');

  const initialRender = useRef(true);
  const resetValues = () => {
    form.setFieldsValue({ rating: null, comment: '' });
  }

  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else {
      resetValues();
    }
  }, [id]);

  const handleCancel = () => {
    resetValues();
    setComment('');
    setTranslatedText('');
    if (onCancel) {
      onCancel()
    }
  }

  const handleSubmit = () => {
    const { validateFields } = form;

    validateFields((error, values) => {
      if (!error && onSubmit) {
        onSubmit({ ...values, translatedText });
      }
    })
  }

  const renderRating = () => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label="Rating" style={{ display:'flex', flexDirection:'row', fontSize: 14 }}>
        {getFieldDecorator('rating', {
          rules: [{
            required: true,
            message:'Please rate this food log.',
            validator: (rule, value, callback) => {
              if(!value){
                callback('Please rate this food log.');
              }
              callback();
            }
          }]
        })(<Rate />)
        }
      </Form.Item>
    );
  }

  const handleChange = ({ value, translatedValue }) => {
    setComment(value);
    setTranslatedText(translatedValue);
  }

  const renderComment = () => {
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        <Col span={3}>
          {helper.renderAvatar(userAvatar)}
        </Col>
        <Col span={21}>
          <Form.Item>
            {getFieldDecorator('comment', { 
              rules: [{ required: true, message: 'Please add comment.', whitespace: true }]
            })(
              <div>
                <TranslationInput value={comment} translatedValue={translatedText} targetLanguage={translationLang} onChange={handleChange} />
              </div>
            )}
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  }

  const renderFooter = () => {
    const { rating, comment: thisComment } = form.getFieldsValue();

    return (
      <div style={BUTTONS_STYLE}>
        {(rating || thisComment) && <Button onClick={handleCancel} style={{ marginRight: 5 }}>Cancel</Button>}
        <Button type="primary" onClick={handleSubmit} disabled={!rating || !thisComment || isSubmitting} loading={isSubmitting}>Submit</Button>
      </div>
    )
  }

  return (
    <Form style={style}>
      {renderRating()}
      {renderComment()}
      {renderFooter()}
    </Form>
  )
}

export default Form.create({
  onValuesChange: (props, changedValues) => {
    const { setShowUnsavedModal } = props;
    setShowUnsavedModal(true);
  },
},
{ name: 'foodLogForm' })(FoodLogReviewComponent);