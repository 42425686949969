import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import editUserPhone from 'graphqlModule/mutation/editUserPhone'
import { userPhone as updateQueries } from 'graphqlModule/updateQueries/userPhone'
import { IHButton, message, IHForm, IHLoading, _ } from 'ihcomponent'
import { getFormProps } from 'patientModule/profileEdit/components/formData/PatientEditHomePhoneForm'
import { GQLHelper, validateAntdForm, submittingStyle } from 'libModule/utils';
import { closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import actions from 'patientModule/profileEdit/actions'
import UserClass from 'modulesAll/graphql/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'patient/profileEdit/containers/PatientEditHomePhoneContainer'

  init() {
    const { userData } = this.props
    const userClassData = new UserClass(userData)

    const homeArray = userClassData.format('homePhone').split(' ')
    const homePhone = homeArray.length > 1 ? homeArray[1] + homeArray[2] : ''
    const homeCountryCode = homeArray.length > 1 ? homeArray[0] : '+1'

    const mobileArray = userClassData.format('mobilePhone').split(' ').splice(1);
    const mobile = mobileArray.length > 1 ? mobileArray.join('') : ' ';
    // const mobileCountryCode = mobileArray.length > 1 ? mobileArray[0] : '+1'
    const mobileCountryCode = '+1';

    const initData = {
      homePhone,
      homeCountryCode,
      mobile,
    }
    return initData
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-form-container'>
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const initData = this.init()
    const formProps = getFormProps({ initData })

    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }

  renderFooter() {
    const { closeModal, isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 10
      }
    }

    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          closeModal()
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        type: 'submit',
        disabled: isSubmitting,
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  getMutateVariable(data) {
    const { userData } = this.props
    const currentPhone = this.init()
    const phone = []

    if (data.homePhone) {
      phone.push(
        {
          countryCode: 1,
          number: parseInt(data.homePhone, 10),
          type: 'HOME'
        }
      )
    }

    if (currentPhone.mobile) {
      phone.push(
        {
          countryCode: 1,
          number: parseInt(currentPhone.mobile, 10),
          type: 'MOBILE'
        }
      )
    }

    const result = {
      id: userData.id,
      phone
    }
    return result
  }

  async submit() {
    const formRef = this.baseForm

    // validate all fields before submission
    const isFormValid = await validateAntdForm(formRef)
    if (!isFormValid) return

    const { mutate, closeModal, openErrorModal, setSubmitting } = this.props
    const fieldsValue = formRef.getValue()
    const variables = this.getMutateVariable(fieldsValue)

    const user = this.props.userData
    const patientNRIC = _.get(user, 'identification[0].value')
    const patientName = _.get(user, 'profile.fullName')

    try {
        setSubmitting(true)
        const res = await mutate({ variables, updateQueries })
        if(res.data.changeUserPhone){
            createAuditLog({
              action: I18N.get('auditLog.provider.patient.editHomePhone'),
              patientNRIC,
              patientName,
              details: fieldsValue,
              request: variables,
              response: res
            })
            message.success('Edit patient home phone success')
            closeModal()
        }
    } catch(e){
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.editHomePhone'),
          patientNRIC,
          patientName,
          details: fieldsValue,
          request: variables,
          response: e,
          success: false
        })
        openErrorModal(GQLHelper.formatError(e))
    } finally {
        setSubmitting(false)
    }
  }
}

// data from apollo
const withData = graphql(editUserPhone)

// connect apollo data with container
const ContainerWithData = withData(Container)

const mapState = (state, ownProps) => {
  return {
    ...state.patient.profileEdit,
  }
}

const mapDispatch = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  userData: PropTypes.object,
  mutate: PropTypes.func,
  closeModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  isSubmitting: PropTypes.bool
}

export default connect(mapState, mapDispatch)(ContainerWithData)
