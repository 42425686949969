import React from 'react';
import TotalCaseSummaryComponent from '../component/TotalCaseSummaryComponent';
import PinnedPatientsContainer from "./PinnedPatientContainer";
import { Row,Col } from 'antd';
import {reports,pinnedPatients,setData} from "../API";

const WorkStationSummaryContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reports:[],
            pinnedPatients:[],
        }
    }

    setLoading = (key,loading)=>{
        this.setState({
            [key]:loading
        })
    }
    componentDidMount() {
        this.fetchReports();
        this.fetchPinnedPatients();
    }
    
    setReports = (reports,cb)=>{
        this.setState({ reports },()=>{
            if(cb) cb();
        });
    }

    setPinnedPatients = (pinnedPatients,cb)=>{
        this.setState({ pinnedPatients },()=>{
            if(cb) cb();
        });
    }

    fetchReports = () => {
      const { setLoading } = this;
      setData(reports(),(res)=>this.setState({ reports:res.data },()=>setLoading('loadingReports',false)),setLoading,'loadingReports');
    }

    fetchPinnedPatients = () => {
      const { setLoading } = this;
      setData(pinnedPatients(),(res)=>this.setState({ pinnedPatients:res.data },()=>setLoading('loadingPinnedPatients',false)),setLoading,'loadingPinnedPatients');
    }

    renderTotalCaseSummary = ()=>{
        const { reports,loadingReports } = this.state;
        const { setReports,setLoading,setPinnedPatients,fetchReports } = this;
        return <TotalCaseSummaryComponent {...this.props} setReports={setReports} setLoading={setLoading} loading={loadingReports} setPinnedPatients={setPinnedPatients} reports={reports} refetchReports={fetchReports}/>
    }
    renderPinnedPatients = ()=>{
        const { loadingPinnedPatients,pinnedPatients } = this.state;
        return <PinnedPatientsContainer 
          loading={loadingPinnedPatients} 
          pinnedPatients={pinnedPatients} 
          refetchPinnedPatients={this.fetchPinnedPatients}
        />;
    }
    render() {
        return  <Row>
                    <Col>
                        {this.renderTotalCaseSummary()}
                        { this.renderPinnedPatients() }
                    </Col>
                </Row>;
    }
}

export default WorkStationSummaryContainer;
