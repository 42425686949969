import gql from 'graphql-tag'

export default gql`
mutation editOrganization(
  $id:EID!,
  $identifier:String, 
  $name:String!,
  $phone:InputPhone,
  $description:String, 
  $address:InputAddress,
  $timezone:String,
  $brandedName: String,
  $logo: String,
  $internalName:String,
  $excludeBilling:Boolean,
  $parentId:EID,
  $consentTemplateIds:[EID],
  $orgPermissions:OrgPermissionsInput,
  $templateGroupIds:[EID],
  $operationModel:OrgOperationModelEnum
  $programParticipation: [ProgramParticipationEnum]
  $monthlyEnrollGoalCount:Int
  $monthlyEnrollGoalMonth: Int
  $monthlyEnrollGoalYear: Int
  $potentialPatientCount:Int
  $EHR: String
  ) {
  	editOrganization(
      id:$id,
      identifier: $identifier,
      name: $name,
      phone: $phone,
      description: $description,
      address: $address,
      timezone: $timezone,
      brandedName: $brandedName,
      logo:$logo,
      internalName:$internalName,
      excludeBilling:$excludeBilling,
      parentId:$parentId,
      consentTemplateIds:$consentTemplateIds,
      orgPermissions:$orgPermissions,
      templateGroupIds:$templateGroupIds,
      operationModel:$operationModel
      programParticipation: $programParticipation
      EHR: $EHR
      potentialPatientCount:$potentialPatientCount
      monthlyEnrollGoalCount: $monthlyEnrollGoalCount
      monthlyEnrollGoalMonth: $monthlyEnrollGoalMonth
      monthlyEnrollGoalYear: $monthlyEnrollGoalYear
    )
    {
      id
      name
      brandedName
      internalName
      logo
      description
      operationModel
      programParticipation
      potentialPatientCount
      monthlyEnrollGoalCount
      monthlyEnrollGoalCountHistory {
        count
        createdAt
        month
        year
      }
      EHR
      orgPermissions {
        analyticsAPI
        useDefaultMobile
        keepOwnDevices
      }
      templateGroups {
        id
        name
      }
    }
  }
`
