import React from 'react';
import BannerMap from '../constants/BannerMap';
import {  notification } from 'antd';
const  TaskAssignmentBannerComponent = class extends React.Component {
    constructor(){
        super();

    }
    componentDidMount(){
        const { total,name,className,render } = this.props;
        if(total){
            render({
                message:<div style={{display:'none'}}></div>,
                description:BannerMap[name].render(total),
                duration: 0,
                className,
                key:name
            });
        }
    }

    render() {
        return   <div></div>
    }
}

export default  TaskAssignmentBannerComponent;
