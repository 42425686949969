import types from '../constants/VitalListBPConstants'
// Default State
const initState = {
  sorter: { columnKey: 'date', order: null },
  pagination: {pageSize: 5},
  view: 'List',
  range: null,
  mode: 'BP'
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.ON_TABLE_CHANGE:
      const { sorter, pagination } = action
      pagination.pageSize = 5 // this may change!!
      return {
        ...state,
        sorter,
        pagination,
      }
    case types.CHANGE_VIEW:
      return {
        ...state,
        view: action.view
      }
    case types.CHANGE_MODE:
      return {
        ...state,
        mode: action.mode
      }
    case types.CHANGE_RANGE:
      return {
        ...state,
        range: action.range
      }
    default:
      return state
  }
}

