import React from 'react';
import OnBoardComponent from '../component/OnBoardComponent';
import {compose, graphql} from "react-apollo/index";
import iotDeviceList from '../../patient/DevicesAssign/query/iotDeviceList';
import getLoanDevice from '../../patient/loanDevices/query/getLoanDevice';
import { getClearDeviceList } from '../../../lib/helpers/device-helpers';

const OnBoardContainer = class extends React.Component{
    render(){ 
      return <OnBoardComponent {...this.props}/> 
    }
}

// Fetch trackSet / iPhone data for patient, if applicable
const getIoTDeviceListData = graphql(iotDeviceList, {
  options: ownProps => {
    const memberId = _.get(ownProps, 'data.user.id', _.get(ownProps, 'userId'));
    return {
      variables: { memberId },
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    };
  },
  props: ({ data }) => {
    const iotDeviceList = getClearDeviceList(_.get(data, 'iotDeviceList.data', []));
    const tsCount = ~~(iotDeviceList && iotDeviceList.length > 0);
    return {
      iotDeviceData: iotDeviceList,
      refetchIoTDeviceData: data.refetch,
      loadingIoTDeviceData: data.loading, 
      tsCount
    };
  }
});

const getLoanDeviceData = graphql(getLoanDevice, {
  options: ownProps => {
    const memberId = _.get(ownProps, 'data.user.id', _.get(ownProps, 'userId'));
    return {
      variables: { memberId },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const getLoanDevice = _.get(data, 'getLoanDevice', null);
    const phoneCount = ~~!_.isEmpty(getLoanDevice);
    return {
      loanDeviceData: getLoanDevice,
      // doesn't work properly without notifyNetworkStatusChange which causes refresh the page and retains initial state
      refetchLoanDeviceData: data.refetch,
      loadingLoanDeviceData: data.loading,
      phoneCount
    };
  }
});

export  default compose(
  getIoTDeviceListData,
  getLoanDeviceData
)(OnBoardContainer);

