import Program from './Program'
import {moment, _} from 'ihcomponent'
import CareTeam from './CareTeam'
import { showMonthsOrDays } from 'libModule/utils'

export default class extends Program{
  format(type, param=false){
    switch(type){
      case 'date_range':
        return this._getDate_range()
      case 'durationProps':
        return this._getDurationProps()
      case 'duration':
        return this._getDuration()
      case 'status':
        return this.checkStatus()
      case 'careteams':
        return this._getCareteams()
      default:
        break
    }
    return super.format(type, param)
  }

  _getDuration(){
    // VS-756
    // TODO For vs-756, if want to get program duration, please use Program class to get it, This is EnrolledProgram Class, ony get data for enrolled program. contact Jacky if have questions.
    const {startDate, endDate, duration} = this.variables
    if(!startDate || !endDate) {
      if (duration) {
          return `${duration.length} ${duration.calendar.toLowerCase()}${duration.length === 1 ? '' : 's'}`
        } else {
          return ''
        }
      }
      const diff = moment(endDate).diff(moment(startDate), 'days') + 1
      return showMonthsOrDays(diff,'text')
  }
  _getDurationProps(){
    let {startDate, endDate} = this.variables
    if(!startDate || !endDate) return null
    const diff = moment(endDate).diff(moment(startDate), 'days') + 1
    return showMonthsOrDays(diff,'array')
  }

  _getDate_range(){
    const {suspended, startDate, endDate} = this.variables

    if(suspended) return 'pending'
    const format = 'MMM-DD-YYYY'
    if(startDate && endDate){
      return moment(startDate).format(format)+' - '+moment(endDate).format(format)
    }

    return ''
  }

  checkStatus(){
    const {status} = this.variables

    return {
      CREATED : 'create',
      PENDING : 'pending',
      READY : 'ready',
      COMPLETED : 'complete',
      STARTED : 'start',
      SUSPENDED : 'suspend',
      DISCHARGED : 'discharge',
      RETURNED : 'return',
    }[status]
  }

  _getCareteams(){
    const {team} = this.variables

    if(!team) return []
    return _.map(team, (ct)=>{
      const co = new CareTeam(ct)
      return co
    })
  }
}
