import React from 'react';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { Button, Tabs, message, Modal } from 'antd';
import { browserHistory } from 'react-router';
import * as R from 'ramda';
import { GQLHelper } from 'libModule/utils';
import Client from 'libModule/gqlClient';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';
import UnenrolledPatientDetailsContainer from './UnenrolledPatientDetailsContainer';
import { IHLoading } from '../../../../../../package/IHComponent';
import { tabToName } from '../helpers';
import { patientGQL } from '../../../graphql/user';
import editUser from '../../../graphql/mutation/editUser';
import phoneListInfo from '../../../graphql/phoneListInfo';
import { API } from '../../../timer/query/index';
import EnrollPatientContainer from "../../EnrollPatient/container/EnrollPatientContainer";
import { getReferredPrograms } from '../../../referAndEnroll/helper';
import { ENROLL_FROM, ROLE_NAME } from '../../../../lib/constants';

const { TabPane } = Tabs;
const defaultTabSelected = 'DETAIL';
const tabs = Object.keys(tabToName);
const VIEW_ENROLL_BUTTON_ROLES = ['Nurse', 'Medical Assistant','Doctor','Clinic Medical Assistant', ROLE_NAME.HC];

import '../css/unenrollStyles.scss';
import {checkIsInRole} from "../../../../lib/helpers/role-helpers";
import ReferAndEnrollButtonWrapper from "../../../referAndEnroll/container/ReferAndEnrollButtonWrapper";
import moment from "moment";
const UnenrolledPatientProfileContainer = class extends React.Component{
    static displayName ='UnenrolledPatientProfileContainer';
    constructor(props){
        super(props);
        const { viewerRole } = props;
        const showBtn = _.includes(VIEW_ENROLL_BUTTON_ROLES , viewerRole);
        this.state = {
            selectedTab: 'DETAIL',
            isEditMode: false,
            enroll: false,
            showBtn,
        }

    }

    setEditMode =(isEditMode) => {
        this.setState({isEditMode});
    }
    setShowModal = (showModal)=>{
        this.setState({
            showModal
        })
    }
    onChangeTab = (activeKey) =>{
        const { isEditMode, selectedTab } = this.state;
        if(isEditMode){
            Modal.confirm({
                title:'You have unsaved changes on this patient profile, are you sure you want to continue without saving?',
                okText: 'Yes, leave without saving',
                onOk:()=>{
                    this.setState({
                        selectedTab:activeKey,
                    })
                    this.setEditMode(false)
                },
                cancelText:`Cancel`,
                onCancel:()=> {}
            })
        }
        //if no change made change tab directly
        else{
            this.setState({
                selectedTab:activeKey,
            })
        }
    }

    onSave = async (mutatedValue, stopResetEditMode)=>{
        const patientId = _.get(this.props,'patientId');
        const variables = DATE_HELPERS.trimAllValues(mutatedValue,'',mutatedValue);
        try{
            const res = await Client.mutate({ mutation:editUser,
                                              variables:variables,
                                              refetchQueries:[{ query:patientGQL, variables: { id: patientId },
                                              fetchPolicy:'network-only'}]
                                            });

            if(!res.error) {
                message.success('All Changes Saved');
                const apiName = Object.keys(res.data)[0];
                const docId = res.data[apiName].id;
                API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'saveUpdate');
                if (!stopResetEditMode) {
                    this.setEditMode(false);
                }
            }
        }
        catch(e){
            console.log(e);
            this.props.openErrorModal(GQLHelper.formatError(e));
        }
    }

    renderHeader = () =>{
        const { showBtn } = this.state;
        const { patientReferral,user  } = this.props;
        const referredRoleType = (_.first(_.get(patientReferral,'referredBy.allRoles')) || {}).roleType;
        const isReferredByMA = referredRoleType==='MA';
        const primaryDoctor = _.get(user,'profile.doctor.profile.fullName');
        const status = _.get(patientReferral,'status',{ });
        const enrollStatus = _.get(status,'enrollStatus');
        const referStatus = _.get(status,'referStatus');
        const isReferred = referStatus ==='COMPLETED';
        const referredByFullName = _.get(patientReferral,'referredBy.profile.fullName');
        const referredPrograms = getReferredPrograms(patientReferral);
        const className = showBtn ? 'unenroll-placeholder withBtn' : 'unenroll-placeholder';
        const referredAt = moment(_.get(patientReferral,'referredAt')).format('MM/DD/YYYY');
        let header = isReferred ? `Patient has been referred to ${referredPrograms.join(',') } by ${isReferredByMA ? `${primaryDoctor}'s team ` : referredByFullName} on ${referredAt} `:'This patient is currently not enrolled in any program.';
        return <div className={className}>
                    <span>{header}</span>
                </div>
    }

    renderButton = () => {
        const { user, lightEnrolledProgramListRefetch, setEnrollStatus,patientReferral, refetchPatientReferral} = this.props;
        const path = `${browserHistory.getCurrentLocation().pathname}_`;
        const isMAorMD = checkIsInRole(['MA','Doctor']);
        const isCAorRD = checkIsInRole(['CA','RD','HC']);
        let patientReferStatus = _.get(patientReferral,'status')||{};
        let { referStatus, enrollStatus }  = patientReferStatus;
        if(isCAorRD)
            return <div className='enroll-button'>
                {/*<Button type='primary' onClick={() => {*/}
                {/*        goPath(path);*/}
                {/*        setEnrollStatus(true);*/}
                {/*    }}>*/}
                {/*    Enroll Patient*/}
                {/*</Button>*/}
                <EnrollPatientContainer
                  user={user}
                  fromPlace={ENROLL_FROM.PATIENT_PROFILE}
                  onCompleteEnrollment={() => {
                    if(lightEnrolledProgramListRefetch)
                      lightEnrolledProgramListRefetch(); // refetch for new enrolledStatus
                    //setEnrollStatus(true);
                  }}
                />
                </div>
        if(isMAorMD) return <ReferAndEnrollButtonWrapper
                                btnType={'PATIENT_PROFILE'}
                                patientReferral={patientReferral}
                                memberId={_.get(user,'id')}
                                enrollStatus={enrollStatus}
                                referStatus={referStatus}
                                setEnrollStatus={setEnrollStatus}
                                onCompleteEnrollment={() => {
                                    if(lightEnrolledProgramListRefetch)
                                      lightEnrolledProgramListRefetch(); // refetch for new enrolledStatus
                                }}
                                refetchQuery={refetchPatientReferral}/>
    }

    renderTab = () => {
        const { renderTabInfo, onChangeTab, state } = this;
        const { selectedTab } = state;

        return <Tabs defaultActiveKey={defaultTabSelected}
                     className='unenrolled-patient-tabs'
                     activeKey={selectedTab}
                     onChange={(k)=>onChangeTab(k)}
                     type='card'
                     >
                {_.map(tabs,(t)=>
                    <TabPane key={t} tab={tabToName[t]}>
                        {renderTabInfo(t)}
                    </TabPane>)}
               </Tabs>
    }

    renderTabInfo = (currentTab) => {
        const { state, setEditMode, props, onSave } = this;
        const { selectedTab, isEditMode } = state;
        const { user, phoneInfoList, patientId, viewerId, consentInfo } = props;
        const userId = patientId ? R.last(R.split(':', atob(patientId))) : null;
        return selectedTab===currentTab &&
        <UnenrolledPatientDetailsContainer selectedTab={currentTab}
                                        isEditMode={isEditMode}
                                        setEditMode={setEditMode}
                                        user={user}
                                        userId={userId}
                                        patientId={patientId}
                                        phoneInfoList={phoneInfoList}
                                        viewerId={viewerId}
                                        save={onSave}
                                        consentInfo={consentInfo}
                                        />
    }


    render(){
        const { showBtn } = this.state;
        const { userLoading, phoneInfoLoading, data } = this.props;
        const loading = userLoading || phoneInfoLoading || data.loading;
        if(loading){
            return <IHLoading />
        }
        return(
        <div className='unenrolledPatientProfile'>
            { this.renderHeader() }
            { showBtn && this.renderButton() }
            {   this.renderTab()  }
        </div>)

    }
}

const withUserData = graphql(patientGQL, {
    options : (ownProps)=> {
      const id =  _.get(ownProps, 'patientId');
      return {
        variables: {
          id
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    },
    props: ({data}) => {
        const { loading, error, user} = data;
        if(loading || error){
            return {
                userLoading: loading
            }
        }
        return {
            user: user
        }
    }
})

const phoneInfo = graphql(phoneListInfo, {
    options: (ownProps) => {
        const memberId = _.get(ownProps, 'patientId');
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if(loading){
            return  {
                phoneInfoLoading:loading
            }
        }
        return {
            phoneInfoList: _.get(data, 'phoneReportInfoList.data', []),
        }
    }
})

export default compose(
    withProps(() => {
        const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
        const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
        const viewerRole = viewerInfo ? _.get(viewerInfo, 'selectedRole.name') : null;
        return { viewerInfo, viewerId, viewerRole};
      }),
    withUserData,phoneInfo)
(UnenrolledPatientProfileContainer);
