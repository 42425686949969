import React from "react";
import PatientAppointmentContainer from '../containers/PatientAppointmentsContainer';
import moment from 'moment';

const PatientAppointmentContainerWrapper = class extends React.Component {
    constructor(){
        super();
        this.state = {
            year: moment().format('YYYY'),
        }
    }

    setYear = (direction)=>{
        const year = _.get(this,'state.year');
        this.setState({
            year: Number(year) +direction
        })
    }


    render(){
        const patientId = _.get(this,'props.patientId');
        const year = _.get(this,'state.year');
        const patientName = _.get(this,'props.patientName');
        const patientBirthday = _.get(this,'props.patientBirthday');
        const doctor = _.get(this,'props.doctor');
        const className = _.get(this,'props.className','');
        const { props } = this;
        return <PatientAppointmentContainer year = { year }
                                     patientId = { patientId }
                                     patientName = { patientName }
                                     setYear = { this.setYear }
                                     patientBirthday = { patientBirthday }
                                     className={className}
                                     doctor={doctor}
                                     {...props}
               />
    }
}


export default PatientAppointmentContainerWrapper;
