import gql from "graphql-tag";
import SignOffTaskSchema from '../../graphql/schema/SignOffTask';
import { Identification } from '../../graphql/schema/common';

export const unresolvedTaskAssignmentsByPatientId = gql`query unresolvedTaskAssignmentsByPatientId($memberId:EID!,$status:[TaskAssignmentStatus]){
  getTaskAssignmentsByPatientId(memberId:$memberId,status:$status){
    taskAssignments{
        id
    } 
  }
}
`;

export const unresolvedSmartAlertsQuery = gql`
    query patientSmartAlertList($filters:PatientSmartAlertOpts,$count:Int){
        patientSmartAlertList(filters:$filters,count:$count){
            pageInfo{
                total
            }
        }
    }`;
export const providerBillableTimeQuery = gql`query getProviderBillableTime {
    getProviderBillableTime{
        totalEnrTime
		totalUnenrTime
    }
}`

export const getCoveredOrgQuery = gql`query getCoveredOrgs($memberId:EID!) {
    getCoveredOrganizations(memberId:$memberId){
      organizations{
        organizationId
        name
      }
      isDefault
    }
  }`;

export const setCoveredOrgsMutation = gql`mutation setCoveredOrgs($memberId:EID!,$organizations:[EID],$isDefault:Boolean) {
    setCoveredOrganizations(memberId:$memberId,organizations:$organizations,isDefault:$isDefault){
      organizations{
        organizationId
        name
      }
      isDefault
    }
  }`

export const setSelectedOrgsMutation =  gql`mutation setSelectedClinic($organizations:[EID]) {
    setSelectedClinic(organizations:$organizations){
      organizations{
        organizationId
        name
      }
    }
  }`
// export const miniUserAPIQuery = API.miniChatUserInfo;
export const miniUserAPIQuery = gql`query miniUser($id:EID){
  user(id:$id){
      id
      identification{
        ${Identification}
      }
      enrollmentDate
      profile{
          fullName
          firstName
          lastName
          avatar{
              link           
          }

          ... on MemberProfile {
            birthday
            programCategories{
              name
              enrolled
              enrolledDate
            }
            healthConditions
            insuranceProvider
            mntHealthConditions {
                code
            }
            insuranceProvider2
            insuranceProviders{
              primary  
              insuranceProvider{
                payerName
                id
              }
            }
            
            billableHealthConditions {
              code
              description
            }
          }
      }
      assignees{
        assignedToRD{
         id
         profile{
           fullName
         }
       }
       assignedToCA{
         id
         profile{
           fullName
         }
       }
     }
  }
}`;

export const missingInfoQuery = gql`query missingInfo($memberId:EID!){
  getMissingInfo(id:$memberId){
    memberId
    id
    status
    missingTasks
  }
}`;

export const missingInfoListQuery = gql`query getMissingInfoList($memberId:EID!,$status:String){
  getMissingInfoList(filters:{ memberId:$memberId,status:$status}){
    id
    memberId
    status
    missingTasks
      
  }
}`;

export const editMissingInfoMutation =gql`mutation editMissingInfo($id:EID!,$status:String){
  editMissingInfo(id:$id,status:$status){
    id
    status
  }
}`;

export const muteMissingInfoMutation = gql`mutation muteMissingInfo($memberId:EID!,$mute:Boolean!,$note:String!){
  muteMissingInfo(memberId:$memberId,note:$note,mute:$mute) 
}`;

export const futureFollowUpVisitCountQuery = gql`
  query futureFollowUpVisitCount(
    $count: Int = 1
    $filters: VisitListOpts,
  ) {
    visitList(count: $count, filters: $filters) {
      pageInfo { total }
    }
  }
`;

// for RD
export const getNeedSignOffPatientsQuery = gql`
  query getNeedSignOffPatients($mentorId: EID!) {
    getNeedSignOffPatients(mentorId: $mentorId) {
      healthCoach {
        id
        profile {
          fullName
        }
      }
      patientIds
    }
  }
`;

export const getNeedSignOffPatientIdsQuery = gql`
query getNeedSignOffPatients($mentorId: EID!) {
  getNeedSignOffPatients(mentorId: $mentorId) {
    patientIds
  }
}
`;

// for HC
export const getUnreadSignOffCommentsQuery = gql`
  query getUnreadSignOffComments($healthCoachId: EID!) {
    getUnreadSignOffComments(healthCoachId: $healthCoachId) {
      mentor {
        id
        profile {
          fullName
        }
      }
      signOffTaskIds
      count
    }
  }
`;

export const getUnreadSignOffTaskIdsQuery =  gql`
  query getUnreadSignOffComments($healthCoachId: EID!) {
    getUnreadSignOffComments(healthCoachId: $healthCoachId) {
      signOffTaskIds
      count
    }
  }
`;

export const getPatientSignOffTasksQuery = gql`
  query getPatientSignOffTasks($patientId: EID!) {
    getPatientSignOffTasks(patientId: $patientId) {
      ${SignOffTaskSchema}
      healthCoach {
        id
      }
    }
  }
`;

export const getTaskOrgIdByPatientIdQuery = gql`
  query getPatientSignOffTasks($patientId: EID!) {
    getPatientSignOffTasks(patientId: $patientId) {
      id
      patient {
        id
      }
      organization {
        id
      }
    }
  }
`;

// id: signOffTaskId
export const getSignOffTaskQuery = gql`
  query getSignOffTask($id: EID!) {
    getSignOffTask(id: $id) {
      ${SignOffTaskSchema}
      healthCoach {
        id
      }
    }
  }
`;

// id: signOffTaskId
export const getTaskOrgIdByTaskIdQuery = gql`
  query getSignOffTask($id: EID!) {
    getSignOffTask(id: $id) {
      id
      patient {
        id
      }
      organization {
        id
      }
    }
  }
`;

// id: signOffTaskId
export const signOffByMentorMutation = gql`
  mutation signOffByMentor ($id: EID!, $comment: String!) {
    signOffByMentor(id: $id, comment: $comment) {
      id
      comment
    }
  }
`;

// id: signOffTaskId
export const readSignOffCommentMutation = gql`
  mutation readSignOffComment ($id: EID!) {
    readSignOffComment(id: $id) {
      id
      comment
    }
  }
`;

export const listMonthlySummaryReport = gql`query listMonthlySummaryReport($memberId:EID!,$monthOfYear:Int!){
    listMonthlySummaryReport(
        memberId:$memberId,
        monthOfYear: $monthOfYear
){
        id
        createdAt
        memberId
  }
  }
`

export const createMonthlySummaryReportMutation = gql`mutation createMonthlySummaryReport($memberId:EID!){
    createMonthlySummaryReport(memberId:$memberId){
        id
    }
}`
