import { _ } from 'ihcomponent';
import types from '../constants/QuestionConstants';
import {
  getQuestionType,
  validateAndReturnFields,
  validateAndReturnQuestion,
} from '../../util';

const initState = {
  __metaAssessment: null, // store the origin question
  fields: validateAndReturnFields({ questions: [{}] }),
  // fields: {
  //   name: '',
  //   description: '',
  //   healthCondition: '',
  //   questions: [],
  //   _status: 'DRAFT',
  // }
};

export default (state = initState, action) => {
  switch (action.type) {

    case types.FIELD_CHANGE: {
      const _fieldsNew = Object.assign({}, state.fields)
      _.set(_fieldsNew, action.fieldPath, action.value)

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(_fieldsNew),
      };
    }

    case types.CANCEL_ASSESSMENT: {
      return initState;
    }

    case types.NEW_QUESTION: {
      state.fields.questions.push({});

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(state.fields),
      };
    }

    case types.REMOVE_QUESTION: {
      // Reserve react key for better performance
      const newFields = state.fields
      delete newFields.questions[action.index]

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(newFields),
      };
    }

    case types.SAVE_QUESTION: {
      const question = state.fields.questions[action.index];
      question._status = 'PENDING';

      // question validation successed
      if (!validateAndReturnQuestion(question).__error) {
        question._status = 'SAVED';
      }

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(state.fields),
      };
    }

    case types.CHANGE_TYPE: {
      const { index, questionType } = action;
      const question = state.fields.questions[index];

      question._status = 'DRAFT';
      question.type = questionType;
      question.options = getQuestionType(questionType).defaultOption;

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(state.fields),
      };
    }

    case types.LOAD_ASSESSMENT: {
      let { assessment } = action;
      assessment = _.cloneDeep(assessment);

      if (assessment && assessment._id) {
        assessment._status = 'SAVED';
        assessment.questions.forEach(q => {
          q._status = 'SAVED';
        });

        return {
          __metaAssessment: assessment,
          fields: validateAndReturnFields(assessment),
        };
      }

      return initState;
    }

    case types.SAVE_ASSESSMENT: {
      state.fields._status = 'PENDING';

      return {
        __metaAssessment: state.__metaAssessment,
        fields: validateAndReturnFields(state.fields),
      };
    }

    default:
      return state;
  }
};
