import React from 'react';
import I18N from '../../I18N';
import { Checkbox, Col, Radio } from 'antd';
import { MNT_CONDITIONS, MNT_PATIENT_DESCRIPTION } from '../constant';

export const mntReferral = ({ onChange, ...configs } = {}) => ({
	key: 'mntReferral',
	className: 'bold-form-subtext-container',
	label: '',
	subText: I18N.get('referAndEnroll.qualifiesMntReferral'),
  ...configs,
	render: ({ disabled }) => {
    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio style={radioStyle} value={1}>Yes</Radio>
        <Radio style={radioStyle} value={0}>No</Radio>
      </Radio.Group>
    );
	}
});

export const mntConditionList = (selectedConditions) => ({ onChange, ...configs } = {}) => ({
	key: 'mntConditionList',
	label: '',
  ...configs,
	render: ({ disabled }) => {
		return (
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        {_.map(_.intersection(MNT_CONDITIONS, selectedConditions), (k) => <Col span={4} key={k}><Checkbox value={k}>{k}</Checkbox></Col>)}
      </Checkbox.Group>
    );
	}
});

export const patientMntDescription = ({ onChange, ...configs } = {}) => ({
	key: 'patientMntDescription',
	className: 'bold-form-container',
	label: I18N.get('referAndEnroll.mntPatientDescription'),
  ...configs,
	render: ({ disabled }) => {
    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        {_.map(MNT_PATIENT_DESCRIPTION, v => <Radio style={radioStyle} key={v} value={v}>{v}</Radio>)}
      </Radio.Group>
    );
	}
});
