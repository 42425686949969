import gql from 'graphql-tag'

export default gql `
mutation deleteUser (
  $id: EID!,
  $reason: InputDeleteReason,
) {
  deleteUser (
    id: $id,
    reason: $reason,
  ){
      id
  }
}
`
