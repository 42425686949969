import React, {Component} from 'react';
import {Form, Row, Col, Select, DatePicker, Input, Button, Tooltip, Icon} from 'antd';
import moment from 'moment';
import { emailValidator2, firstAndLastNameValidator} from 'libModule/validator';
import TestUserComponent from '../../../patient/component/TestUserComponent';
import I18N from 'modulesAll/I18N';
import '../style.scss';
import { nationOptions, langOptions, countryOptions,
    docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import helper from '../../../newAdmin/ConsentForm/helper/index';

class FirstPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTestUser : false
        };
        this.submitHandler = this.submitHandler.bind(this);
    }
    componentDidMount = () => {
        const {setFieldsValue } = this.props.form;
        const {basicInfor} = this.props;
        setFieldsValue({
            'startDate': basicInfor.startDate ? basicInfor.startDate : null,
            'firstName': basicInfor.firstName,
            'lastName': basicInfor.lastName,
            'id':basicInfor.id,
            'email' : basicInfor.email,
            'phone': basicInfor.phone
        });
    }
    submitHandler = (e) => {
        e.preventDefault();
        const {getFieldValue, validateFields} = this.props.form;
        validateFields((error, values) => {
            if (error) {
                return;
            }
            const newObject = {
                startDate: getFieldValue("startDate")||null,
                firstName: getFieldValue("firstName"),
                lastName: getFieldValue("lastName"),
                id: getFieldValue("id")||null,
                email: getFieldValue("email"),
                phone: getFieldValue("phone")||null,
                isTestUser: this.state.isTestUser,
                providerLanguage: getFieldValue('providerLanguage'),
                directAddress: getFieldValue('directAddress')
            };
            this.props.handlerNext(newObject);
        });
        
    }
    render(){
        const { getFieldDecorator} = this.props.form;
        return(
            <Form className={'firstWrapper'}>
                <Row>
                    <Col span={11} offset={1}>
                        <Form.Item label="First Name">
                            {getFieldDecorator('firstName',
                                {rules: [ {required: true,message: 'Please input your first name'},
                                          { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
                                    ]})(<Input/>)}
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item label="Last Name">
                            {getFieldDecorator('lastName',
                                {rules: [ {required: true,message: 'Please input your last name'},
                                          { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}]})(<Input/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11} offset={1}>
                        <Form.Item label="Login ID">
                            {getFieldDecorator('id')(<Input/>)}
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1} >
                        <Form.Item label="Start Date" className={'firstStateDate'}>
                            {getFieldDecorator('startDate')(<DatePicker/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11} offset={1}>
                        <Form.Item key="email" label="E-mail Address">
                            {getFieldDecorator('email',{rules: [{required: true,message: 'Please input your E-mail Address'},
                                                                {validator: (rule, value, callback) => callback(emailValidator2(value))}
                                ]})(<Input/>)}
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                        <Form.Item key="phone" label="Phone number">
                            {getFieldDecorator('phone')(<Input/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={22} offset={1} style={{ display:'flex',alignItems:'center' }}>
                        <Form.Item key="directAddress" label="Direct Address" style={{ width:'inherit' }}>
                            {getFieldDecorator('directAddress',{rules: [ {validator: (rule, value, callback) => callback(emailValidator2(value))}
                                ]})(<Input/>)}
                        </Form.Item>
                        <Tooltip title={I18N.get('admin.directAddress.tooltip')} overlayClassName={'directAddressTooltip'}>
                            <Icon type="info-circle" style={{ marginLeft: 10 }}/>
                        </Tooltip>
                    </Col>
                </Row>
                <Row>
                    <Col span={23} offset={1}>
                        <Form.Item label={'Language'}>
                            {getFieldDecorator('providerLanguage',{ initialValue:['EL'] })(helper.multiLanguageSel(langOptions,['EL']))}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={20} style={{marginLeft: '4.167%'}}>
                        <TestUserComponent localThis={this}
                                            headerText={'Creating a test provider?'}
                                            contentText={'account'}/>
                    </Col>
                </Row>
          
                <Button type="primary" className={'firstNextBtn'} onClick={this.submitHandler}>Next</Button>
            </Form>
        )
    }

}
FirstPage = Form.create()(FirstPage);
export default FirstPage;