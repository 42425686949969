import { _ } from 'ihcomponent';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions';
import ListComponent from '../components/ListComponent';

// graphql
import broadcastList from 'graphqlModule/broadcastList';

const tableActions = createTableAction('broadcast_list', {
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    startTime: 'START_AT'
  },
});

const withData = graphql(broadcastList, {
  options: (ownProps) => {
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      sort: { 'field': 'START_AT', 'direction': 'DESC' }
    }

    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      };
    }

    return {
      variables,
      fetchPolicy: 'network-only',
    };
  }
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.broadcast_list
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...tableActions
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData,
)(ListComponent);
