import adminPath from 'modulesAll/utils/constants/rolePath/admin'
import providerPath from 'modulesAll/utils/constants/rolePath/provider'
import patientPath from 'modulesAll/utils/constants/rolePath/patient'
import providerAndPatientPath from 'modulesAll/utils/constants/rolePath/providerAndPatient'
import vendorPath from 'modulesAll/utils/constants/rolePath/vendor'
import { ROLES } from 'modulesAll/utils/constants/role'

const isPathMatch = (paths, pathname) => {
  if (paths.pathOnly.indexOf(pathname) !== -1) return true
  for (let i = 0; i < paths.pathStartsWith.length; i++) {
    if (pathname.startsWith(paths.pathStartsWith[i])) {
      return true
    }
  }
  return false
}

const getAccess = pathname => {
  let requiredAccess = []
  switch(true){
    // admin
    case isPathMatch(adminPath, pathname):
      requiredAccess = [ROLES.admin];
      break;
    // provider
    case isPathMatch(providerPath, pathname):
      requiredAccess = [ROLES.doctor, ROLES.nurse, ROLES.medicalAssistant, ROLES.clinicMedicalAssistant, ROLES.healthCoach];
      break;
    // member
    case isPathMatch(patientPath, pathname):
      requiredAccess = [ROLES.patient];
      break;
    // vendor
    case isPathMatch(vendorPath, pathname):
      requiredAccess = [ROLES.vendor];
      break;
    // member and provider
    case isPathMatch(providerAndPatientPath, pathname):
      requiredAccess = [ROLES.patient, ROLES.doctor, ROLES.nurse, ROLES.medicalAssistant, ROLES.clinicMedicalAssistant, ROLES.healthCoach];
      break;
    default:
        requiredAccess =[];
  }
  return requiredAccess
}

export default getAccess