import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CONDITION_ENUM_MAP } from '../../careplanNew/component/helper/goalHelper';
import { convertBG_mmolTomgdl, convertKgToPound } from '../../../lib/utils/convertUnits';
import _, { map } from 'lodash';

const GoalMeasurementVitalSummary = props => {
  const { associatedVitals, monthlyReviewSummary, goalType} = props;
  const vitalSummary = _.pick(monthlyReviewSummary, associatedVitals);
  const text = useMemo(() => getSummaryText(vitalSummary, associatedVitals), [vitalSummary, associatedVitals]);
  const titleText = `${CONDITION_ENUM_MAP[goalType] || '--'} Summary `;

  return (
    <div id='goal-measurement-vitals-summary'>
      <div className='title'>
        {titleText}
      </div>
      <div className='description'>
        {text}
      </div>
    </div>
  );
};

const getSummary1ExtraNotes = (curSum) => {
  const { drop, rose, similar } = curSum || {};
  let extraNotes = '.';
  if (!!drop) {
    extraNotes = `, and looks like it is dropping since last month!`;
  } else if (!!rose) {
    extraNotes = `, and looks like it rose since last month!`;
  } else if (!!similar) {
    extraNotes = `, and is similar to last month.`;
  }
  return extraNotes;
}

const getSummaryText = (vitalSummary, associatedType) => {
  let textArr = [];
  _.forEach(associatedType, (curType) => {
    let summaries = _.get(vitalSummary, curType);
    _.forEach(summaries, (curSum) => {
      const { type, vital, unit, percentage, previous2To4Week, previous4To8Week, recent2Week, schedule, timeSlots, mealType, beforeMeal} = curSum;
      let prevTwoAvg = previous2To4Week && previous2To4Week.average ? previous2To4Week.average : 0;
      let prevFourAvg = previous4To8Week && previous4To8Week.average ? previous4To8Week.average : 0;
      let recentTwoAvg = recent2Week && recent2Week.average ? recent2Week.average : 0;
      let diastolicPreTwo = previous2To4Week && previous2To4Week.diastolic ? previous2To4Week.diastolic : prevTwoAvg;
      let systolicPreTwo = previous2To4Week && previous2To4Week.systolic ? previous2To4Week.systolic : prevTwoAvg;
      let diastolicPreFour = previous4To8Week && previous4To8Week.diastolic ? previous4To8Week.diastolic : prevFourAvg;
      let systolicPreFour = previous4To8Week && previous4To8Week.systolic ? previous4To8Week.systolic : prevFourAvg;
      let diastolicRecTwo = recent2Week && recent2Week.diastolic ? recent2Week.diastolic : recentTwoAvg;
      let systolicRecTwo = recent2Week && recent2Week.systolic ? recent2Week.systolic : recentTwoAvg;
      prevTwoAvg = vital == 'BP' ? (systolicPreTwo + '/' + diastolicPreTwo + ' ') : getAvgBasedOnType(vital, prevTwoAvg) + ' ';
      prevFourAvg = vital == 'BP' ? (systolicPreFour + '/' + diastolicPreFour + ' ') : getAvgBasedOnType(vital, prevFourAvg) + ' ';
      recentTwoAvg = vital == 'BP' ? (systolicRecTwo + '/' + diastolicRecTwo + ' ') : getAvgBasedOnType(vital, recentTwoAvg) + ' ';
      let unitVal = getUnitBasedOnType(vital, unit);
      let perVal = (schedule && schedule.target) || '--';
      let perUnit = (schedule && schedule.unit) || '--';
      let beforeOrNot = beforeMeal ? 'before ' : 'after ';
      let timeSlot = vital == 'BG' ? beforeOrNot + String(mealType).toLocaleLowerCase() : 'at ' + String(timeSlots).toLocaleLowerCase();
      let vitalName = vital == 'HS' ? 'weight' : vital;
      const template = {
        "SUMMARY_1" : `The average of ${vitalName} in recent 2 weeks is ${recentTwoAvg}${unitVal}, the average in previous 2 weeks was ${prevTwoAvg}${unitVal}, and the average in the 30 days prior to those recent 4 weeks was ${prevFourAvg}${unitVal}`,
        "SUMMARY_2" : `The patient usually monitors ${percentage + '%' || '--'} of the time ${timeSlot || '--'}.`,
        "SUMMARY_3" : `The patient usually monitors ${percentage + '%' || '--'} of the time ${timeSlot || '--'}.`,
        "SUMMARY_4" : `The patient usually monitors ${percentage + '%' || '--'} of the time ${timeSlot || '--'}.`,
        "SUMMARY_5" : `Also, it appears that this patient typically takes only part of a paired glucose reading (pre or post) but doesn’t often complete a paired reading.`,
        "SUMMARY_6" : `Also, it appears that this patient typically takes fasting glucose readings, but doesn’t often complete a paired reading around breakfast.`,
        "SUMMARY_7" : `Of all their paired glucose readings in the last 30 days, over 50% show a glucose change over 100mg/dL.`,
        "SUMMARY_8" : `Of all their paired glucose readings in the last 30 days, over half of these are showing an adequate rise of 100mg/dL or less.`,
        "SUMMARY_9" : `This patient has blood pressure in or above stage 2 range.`,
        "SUMMARY_10" : `The patient has not measured this vital at all in the recent 30 days.`
      }
      let curText = {key: type, text: _.get(template, type)};
      if (type === 'SUMMARY_1') {
        const extraNotes = getSummary1ExtraNotes(curSum);
        curText.text = `${curText.text}${extraNotes}`;
      }
      if(!_.find(textArr, {key: type})) {
        textArr.push(curText);
      }
    })
  })
  return '\n' + _.map(textArr, 'text').join('\n');
}

const getAvgBasedOnType = (vital, val) => {
  switch(vital) {
    case 'BG': 
      return convertBG_mmolTomgdl(val);
    case 'HS':
      return convertKgToPound(val);
    default:
      return val;
  }
}

const getUnitBasedOnType = (vital, val) => {
  switch(vital) {
    case 'BG':
      return 'mg/dL';
    case 'HS':
      return 'lb';
    default:
      return val;
  }
}

GoalMeasurementVitalSummary.propTypes = {
  associatedVitals: PropTypes.array,
  monthlyReviewSummary: PropTypes.object
};

export default GoalMeasurementVitalSummary;