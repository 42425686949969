import gql from 'graphql-tag'

export default gql `query getUserInfoCHS($userId:EID!,$newAuthKey:Boolean) {
  getUserChatInfo(userId:$userId, newAuthKey:$newAuthKey) {
    userChatId
    publishKey
    subscribeKey
    authKey
    cipherKey
    messageSource
    channelGroupName
   
  }
}`
