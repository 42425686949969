import PropTypes from 'prop-types';
import React from 'react';
import LineChart from './LineChartComponentRevised'
import moment from 'moment'
import { alertColor } from '../constants/VitalListConstants'
import { HR_UNIT } from 'modulesAll/utils/constants/task'

const returnValidHR = (data) => (
  data.filter(d => d.taskStatus === 'COMPLETED' && !!d.heart_rate)
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      'Heart Rate': d.heart_rate.value,
      date: d.date,
      key: 'Heart Rate',
      id: d.id,
      datum: d
    }))
)

const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const setFillColorHR = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity)? alertColor[d.datum.severity] : defaultFill
}
const formatTooltipHR = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const { heart_rate: hr } = d.datum
  const hrUnit = hr? hr.unit : HR_UNIT
  return `${hr? hr.value : '--'} ${hrUnit}`
}
const HRChart = ({ data, range, thresholds_heartRate }) => {
  const chartData = returnValidHR(data, ['Heart Rate'])
  const missed = returnMissed(data)
  const setFillColor = setFillColorHR
  const formatTooltip = formatTooltipHR
  const p = {
    data: chartData,
    missed,
    value: ['Heart Rate'],
    lineColor: ['#0099ff'],
    setFillColor,
    formatTooltip,
    thresholds: thresholds_heartRate,
    range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
  }
  return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
}

HRChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array
}
export default HRChart
