// patient
import I18N from 'modulesAll/I18N';

const menus = {
  MyTasks: {
    id: 'mytasks',
    title: 'My Tasks',
    // icon: 'mytasks',
    icon: 'tasks',
    href: '/mytasks',
    class:'mytasks'
  },

  MyVitals: {
    id: 'myvitals',
    title: 'My Vitals',
    //icon: 'dashboard',
    icon: 'bar-chart',
    href: '/myvitals',
    class:'myvitals'
  },

  MyProgrammes: {
    id: 'myprogrammes',
    title: `My ${I18N.get('keywords.Programs')}`,
    // icon: 'programmes',
    icon: 'programs',
    href: '/myprogrammes',
    class:'myprogrammes'
  },

  AssessementAndSurveys: {
    title: `${I18N.get('keywords.Assessments')} & Surveys`,
    id: 'assessments_surveys',
    // icon: 'assessments',
    icon: 'assessment',
    href: '/assessments_surveys',
    class: 'assessments_surveys',
  },

  EmergencyContact: {
    id: 'emergency_contact',
    title: 'Emergency Contact',
    //icon: 'patients',
    icon: 'person',
    href: '/emergency_contact',
    class:'emergency_contact'
  }
}

const menusArray = [
  menus.MyTasks,
  menus.MyVitals,
  menus.MyProgrammes,
  menus.EmergencyContact,
  menus.AssessementAndSurveys,
]

export default menusArray

export const HOMEPAGE = '/mytasks'
