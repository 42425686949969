/**
 * Created by zizhangai on 1/25/17.
 */
import types from '../constants/InterventionsConstants'

export const addNewNote = (noteId) => ({
  type: types.ADD_NOTE,
  id: noteId,
  payload: {
    notes: {
      [noteId]: {
        isValid: true,
        reason: '',
        saveDisable: false
      }
    }
  }
})

export const addReason = (noteId, reason) => ({
  type: types.ADD_REASON,
  payload: {
    notes: {
      [noteId]: {
        reason
      }
    }
  }
})

export const disableButton = (noteId) => ({
  type: types.DISABLE_BUTTON,
  payload: {
    notes: {
      [noteId]: {
        saveDisable: true
      }
    }
  }
})

export const enableButton = (noteId) => ({
  type: types.ENABLE_BUTTON,
  payload: {
    notes: {
      [noteId]: {
        saveDisable: false
      }
    }
  }
})

export const clearState = () => ({
  type: types.CLEAR_STATE,
  payload: {
    notes: {},
    view: 'partial'
  }
})

export const switchView = (view) => ({
  type: types.SWITCH_VIEW,
  payload: view
})

export const deleteState = (noteId) => ({
  type: types.DELETE_STATE,
  payload: noteId
})
