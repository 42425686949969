import React from 'react';
import  { DATE_HELPERS  } from '../../../../../lib/helpers/date-helpers';
import obj from '../../Measurement/constants/index';
import { Divider } from 'antd';
import moment from 'moment';
import I18N from 'modulesAll/I18N'

const timeFormatString = I18N.get('dates.L');

class POMeasurementsComponent extends React.Component{

    render(){
        const { POMeasureTypeMap , style } = obj;
        const { dateFrom, dateTo,text,type,duration } = this.props;
        const { title, path, logoPath } = POMeasureTypeMap[duration] || POMeasureTypeMap[type];
        const { container, header, divider1, message, date, divider2, textStyle, rangedHeader, rangedHeaderSub } = style;

        const [ fromDate, toDate ] = [DATE_HELPERS.formattedDate(moment(dateFrom), imeFormatString), DATE_HELPERS.formattedDate(moment(dateTo), timeFormatString)];

        return <div style={container}>
                <div style={rangedHeader}>
                    <img src={logoPath} height={26}/>
                    <div style={rangedHeaderSub}>
                        <span>{title}</span>
                        <span style={date}>{fromDate} - {toDate}</span>
                        <Divider style={divider1}/>
                    </div>
                </div>
                <div style={message}>
                    <img src={path} width={'40px'} height={'40px'}/>
                    <Divider type='vertical' style={divider2}/>
                    <div style={textStyle}>
                        {text}
                    </div>
                </div>
        </div>
    }
}

export default POMeasurementsComponent;
