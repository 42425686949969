import React from 'react';
import ReferralAndEnrollContainer from '../container/index';
import { Icon } from 'antd';
import { getReferredPrograms } from '../helper';
import helpers from "../../remoteEnrollment/helper";
import { getEnrollerRoleType } from '../../patientList/main/helper';

const ReferStatusInPatientHeaderComponent = class extends React.Component{
	constructor(props) {
		super(props);
		let showModal = sessionStorage.getItem('prMember') && (sessionStorage.getItem('prMember') == props.memberId);
		this.state = {
			showModal
		}
	}

	renderReferStatus = ()=>{
		const { btnText,patientReferral } = this.props;
        const referredPrograms = getReferredPrograms(patientReferral);
		const btn = `Referred (${referredPrograms.join('/')})`
		return <span onClick={()=>this.setShowModal(true)} style={{textDecorationLine: 'underline', color: '#3177C9',cursor:'pointer'}}>{btnText||btn}</span>
	}


	renderEnrollStatus = ()=>{
		const { patientReferral,textFn,remoteEnrollment } = this.props;
		let status  = _.get(patientReferral,'status',[]);
		let enrolledByType = getEnrollerRoleType(remoteEnrollment) || 'MA';
		let needAllFinishedToShowChecked = ['patientConsentEnroll', 'confirmDevicesEnroll', 'scheduleInitVisitEnroll', 'enrollStatus'];
		let flag = needAllFinishedToShowChecked.reduce((flag,cur)=>{ flag = flag&&(status[cur]==='COMPLETED');return flag },true);
		let icon = flag?  <Icon type="check-circle" theme="filled" style={{ marginLeft: 5,color:'#00C8BC' }}/> :     <Icon type="info-circle" theme="filled" style={{color:"#DA6453",marginLeft: 5}} />
		let content = textFn&&(typeof textFn === 'function') ? <React.Fragment>{textFn(patientReferral)}{icon}</React.Fragment>: <React.Fragment>{enrolledByType} Enrolled{icon} </React.Fragment>;
		return <span onClick={()=>this.setShowModal(true)} style={{textDecorationLine: 'underline', color: '#3177C9',cursor:'pointer'}}>{ content }</span>
	}
	renderStatus = (type) =>{
		return ({
			'REFERRAL': this.renderReferStatus,
			'ENROLL': this.renderEnrollStatus
		}[type])();
	}

	setShowModal =(showModal)=>{
		const { organization = { },memberId } = this.props;
		const { id, name } = organization;
		let fn = (orgSwitched)=>{
			if(orgSwitched){
				helpers.simulatePageReload();
			}else {
				this.setState({showModal})
			}
		}
		helpers.saveModalReferral(memberId,showModal);
		helpers.onOrganizationChange({ organizationId:id,name },fn);
	}

	render() {
		const { showModal } = this.state;
		const { memberId,patientReferral={},type,refetchQuery } = this.props;
		const { setShowModal } = this;
		return <div>
				{ this.renderStatus(type) }
				{ showModal&&<ReferralAndEnrollContainer refetchQuery={refetchQuery} visible={showModal} memberId={memberId} patientReferral={patientReferral} setShowModal={setShowModal} type={type}/> }
			   </div>
	}
}

export default ReferStatusInPatientHeaderComponent;