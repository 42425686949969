import convertor from "@ihealth/convertor/index";
import { InputNumber,Input,Row,Col } from 'antd';
import React from 'react';
import {
    dobValidator,
    emailValidator2,
    postCodeValidator,
    blockValidator,
    floorValidator,
    unitValidator,
    firstAndLastNameValidator,
    NRICValidator,
    patientWeightRegiterValidator,
    patientHeightInputValidator,
    patientHeightInchesInputValidator,
    mobileValidator,
    homePhoneValidator,
    yearOfDiagnoseValidator
} from 'libModule/validator';
import moment from 'moment';
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import { convertCelsiusToFahrenheit } from 'libModule/utils/convertUnits';

const { TextArea } = Input;
const MAFormConfig =(props,fn,localThis)=> [
                        [
                            {
                                key: 'height',
                                label: 'Height (ft)',
                                path: 'profile.height.value',
                                labelCol: { span: 24 },
                                wrapperCol: { span: 20 },
                                span: 6,
                                min: 0,
                                max: 9,
                                rules:[{
                                    validator: (rule, value, callback) => {
                                        if(value==''||value==undefined){
                                            callback()
                                            return;
                                        }
                                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                                            callback('Input Invalid');
                                            return;
                                        }
                                        if(value < 0 || value > 9 ) {
                                            return callback('should between 0 and 9 feet')
                                        }
                                        callback();
                                    }
                                }],
                                inputContent: () => <InputNumber  />,
                                formatValue: (height, format) => {
                                    const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                                    return heightInInches
                                        ? convertInchesToFeet(heightInInches, 'cm', 'inch', 0)
                                        : '';
                                }
                            },
                            {
                                key: 'height_inches',
                                label: `(in)`,
                                labelCol: { span: 24 },
                                wrapperCol: { span: 20 },
                                path: 'profile.height.value',
                                span: 6,
                                min: 0,
                                max: 11,
                                rules:[{
                                    validator: (rule, value, callback) => {
                                        if(value==''||value==undefined){
                                            callback()
                                            return;
                                        }
                                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                                            callback('Input Invalid');
                                            return;
                                        }
                                        if(value < 0 || value > 11 ) {
                                            return callback('should between 0 and 10 inch')
                                        }
                                        callback();
                                    }
                                }],
                                inputContent: () => <InputNumber />,
                                formatValue: height => {
                                    const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                                    return height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
                                }
                            },
                            {
                                key: 'weight',
                                label: 'Weight (lbs)',
                                labelCol: { span: 24 },
                                wrapperCol: { span: 24 },
                                path: 'profile.weight.value',
                                span: 12,
                                rules:[ {
                                    validator: (rule, value, callback) => {
                                        if(value==''||value==undefined){
                                            callback()
                                            return;
                                        }
                                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                                            callback('Input Invalid');
                                            return;
                                        }
                                        if(value < 25 || value > 999 ) {
                                            return callback('should between 25 and 999')
                                        }
                                        callback()
                                    }
                                }],
                                rowStyle: { marginLeft: 10 },
                                inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
                                formatValue: weight =>
                                    weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
                            }],
                            [
                            {
                                key: 'systolic',
                                label: 'BP (mmHg)',
                                span: 5,
                                labelCol: { span: 24 },
                                wrapperCol: { span: 24 },
                                path: 'details.bpMeasurement.systolic_blood_pressure.value',
                                formatValue: () => _.get(props, 'currentVisit.details.bpMeasurement.systolic_blood_pressure.value'),
                                inputContent: () => <InputNumber />
                            },
                            {
                                key: 'none',
                                label: '',
                                span: 1,
                                labelCol: { span: 24 },
                                wrapperCol: { span: 24 },
                                colStyle: { paddingTop: 30 },
                                inputContent: () => <div>/</div>
                            },
                            {
                                key: 'diastolic',
                                label: `  `,
                                labelCol: { span: 24 },
                                wrapperCol: { span: 20 },
                                colStyle: { paddingTop: 6 },
                                span: 6,
                                path: 'details.bpMeasurement.diastolic.value',
                                inputContent: () => <InputNumber />,
                                formatValue: () => _.get(props, 'currentVisit.details.bpMeasurement.diastolic_blood_pressure.value')
                            },
                            {
                                key: 'heartBeat',
                                label: `Heart Rate(BPM)`,
                                labelCol: { span: 24 },
                                wrapperCol: { span: 24 },
                                rowStyle: { marginLeft: 10 },
                                span: 12,
                                path: 'details.bpMeasurement.heart_rate.value',
                                formatValue: () =>
                                    _.get(props, 'visit.details.bpMeasurement.heart_rate.value') == 0
                                        ? null
                                        : _.get(props, 'currentVisit.details.bpMeasurement.heart_rate.value'),
                                inputContent: () => <InputNumber />
                            }
                        ],
                        [
                            {
                                key: 'BG',
                                label:'BG (mg/dL)',
                                labelCol: { span: 24 },
                                wrapperCol: { span: 20 },
                                span: 7,
                                inputContent: () => <InputNumber />,
                                formatValue: () => _.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.unit') == 'mmol/L' ?
                                    convertor.BG(_.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.value'), 'mmol/l', 'mg/dl', 0) :
                                    _.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.value')
                            },
                            {
                                key: 'temperature',
                                label:'Temperature (F)',
                                labelCol: { span: 24 },
                                wrapperCol: { span: 22 },
                                span: 10,
                                inputContent: () => <InputNumber />,
                                formatValue: () => _.get(props, 'currentVisit.details.temperature.unit')=='C' ?
                                                    convertCelsiusToFahrenheit(_.get(props, 'currentVisit.details.temperature.value')):
                                                   _.get(props, 'currentVisit.details.temperature.value')
                            },
                            {
                                key: 'spo2',
                                label:'SpO2 (%)',
                                labelCol: { span: 24 },
                                wrapperCol: { span: 20 },
                                span: 7,
                                inputContent: () => <InputNumber />,
                                formatValue: () => _.get(props, 'currentVisit.details.spo2.value')
                            },
                        ],
                        // [
                        //     {
                        //         key:'reason',
                        //         label:'Notes',
                        //         labelCol: { span: 24 },
                        //         inputContent:()=><Input.TextArea  rows={3}/>,
                        //         formatValue: () => _.get(props, 'currentVisit.reason')
                        //     }
                        // ],
                        [
                            {
                                key:'createVisit',
                                inputContent:()=>{
                                   // const { currentVisit }  = props;
                                   const  associatedVisit  = _.get(localThis,'state.associatedVisit');
                                   if(associatedVisit){
                                       const { appointmentAt,visitRoles=[],category } = associatedVisit;
                                       const visitDay= moment(appointmentAt).format('MM/DD/YY');
                                       const visitTime = moment(appointmentAt).format('HH:mm a');
                                       const isVirtual = category=='VIRTUAL';
                                       return <Row style={{margin:'10px 0px'}}>
                                                <Col span={24}><h6>Follow Up Visit</h6></Col>
                                                <Col>
                                                    <Row style={{ fontSize:14 }}>
                                                        { isVirtual ? <Col span={2}><img src='/image/camera.png' width={15}/></Col> :'' }
                                                        <Col span={10}>{`${visitDay} ${visitTime}`}</Col>
                                                        <Col span={8}>{`Visit:${visitRoles.join(',')}`}</Col>
                                                        <Col style={{ float:'right' }}>
                                                            <a href={'javascript:(void)'} style={{textDecoration:'underline'}} onClick={()=>fn(true)}>Edit</a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                              </Row>
                                   }
                                   return <a href={'javascript:(void)'} style={{textDecoration:'underline'}} onClick={()=>fn(true)}>Schedule Follow Up</a>
                                }
                            }
                        ]
                     ]
const MAFormConfigTwoRows = (props,fn,localThis)=> [
        [
            {
                key: 'height',
                label: 'Height (ft)',
                path: 'profile.height.value',
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                span: 4,
                min: 0,
                max: 9,
                rules:[{
                    validator: (rule, value, callback) => {
                        if(value==''||value==undefined){
                            callback()
                            return;
                        }
                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                            callback('Input Invalid');
                            return;
                        }
                        if(value < 0 || value > 9 ) {
                            return callback('should between 0 and 9 feet')
                        }
                        callback();
                    }
                }],
                inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
                formatValue: (height, format) => {
                    const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                    return heightInInches
                        ? convertInchesToFeet(heightInInches, 'cm', 'inch', 0)
                        : '';
                }
            },
            {
                key: 'height_inches',
                label: `(in)`,
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                path: 'profile.height.value',
                span: 4,
                min: 0,
                max: 11,
                rules:[{
                    validator: (rule, value, callback) => {
                        if(value==''||value==undefined){
                            callback()
                            return;
                        }
                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                            callback('Input Invalid');
                            return;
                        }
                        if(value < 0 || value > 11 ) {
                            return callback('should between 0 and 10 inch')
                        }
                        callback();
                    }
                }],
                inputContent: ({ disabled }) => <InputNumber />,
                formatValue: height => {
                    const heightInInches = convertor.height(height, 'cm', 'inch', 0);

                    return height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
                }
            },
            {
                key: 'weight',
                label: 'Weight (lbs)',
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                path: 'profile.weight.value',
                span: 6,
                rowStyle: { marginLeft: 10 },
                rules : [ {
                    validator: (rule, value, callback) => {
                        if(value==''||value==undefined){
                            callback()
                            return;
                        }
                        if(isNaN(Number(value))||(value+'').trim().length!=(value+'').length){
                            callback('Input Invalid');
                            return;
                        }
                        if(value < 25 || value > 999 ) {
                            return callback('should between 25 and 999')
                        }
                        callback()
                    }
                }],
                inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
                formatValue: weight =>
                    weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
            },
            {
                key: 'temperature',
                label:'Temperature (F)',
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                span: 5,
                inputContent: () => <InputNumber />,
                formatValue: () =>_.get(props, 'currentVisit.details.temperature.unit')=='C' ?
                    convertCelsiusToFahrenheit(_.get(props, 'currentVisit.details.temperature.value')):
                    _.get(props, 'currentVisit.details.temperature.value')
            },
            {
                key: 'spo2',
                label:'SpO2 (%)',
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                span: 5,
                inputContent: () => <InputNumber />,
                formatValue: () => _.get(props, 'currentVisit.details.spo2.value')
            }],
            [{
                key: 'systolic',
                label: 'BP (mmHg)',
                span: 4,
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                colStyle: { paddingTop: 15 },
                path: 'details.bpMeasurement.systolic_blood_pressure.value',
                formatValue: () => _.get(props, 'currentVisit.details.bpMeasurement.systolic_blood_pressure.value'),
                inputContent: () => <InputNumber />
            },
            {
                key: 'none',
                label: '',
                span: 1,
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                colStyle: { paddingTop: 53, marginLeft: -10 },
                inputContent: () => <div>/</div>
            },
            {
                key: 'diastolic',
                label: `  `,
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                colStyle: { paddingTop: 20, marginLeft: -6 },
                span: 4,
                path: 'details.bpMeasurement.diastolic.value',
                inputContent: () => <InputNumber />,
                formatValue: () => _.get(props, 'currentVisit.details.bpMeasurement.diastolic_blood_pressure.value')
            },
            {
                key: 'heartBeat',
                label: `Heart Rate (BPM)`,
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                colStyle: { paddingTop: 15, paddingLeft: 6 },
                span: 6,
                path: 'details.bpMeasurement.heart_rate.value',
                formatValue: () =>
                    _.get(props, 'visit.details.bpMeasurement.heart_rate.value') == 0
                        ? null
                        : _.get(props, 'currentVisit.details.bpMeasurement.heart_rate.value'),
                inputContent: () => <InputNumber />
            },
            {
                key: 'BG',
                label:'BG (mg/dL)',
                labelCol: { span: 24 },
                wrapperCol: { span: 20 },
                colStyle: { paddingTop: 15 },
                span: 5,
                inputContent: () => <InputNumber />,
                formatValue: () => _.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.unit') == 'mmol/L' ?
                                    convertor.BG(_.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.value'), 'mmol/l', 'mg/dl', 0) :
                                    _.get(props, 'currentVisit.details.bgMeasurement.blood_glucose.value')
            }
        ],
        [
            {
                key:'reason',
                label:'Notes',
                labelCol: { span: 24 },
                colStyle: { marginTop: 15, marginBottom: 15 },
                inputContent:()=><Input.TextArea rows={3}/>,
                formatValue: () => _.get(props, 'currentVisit.reason')
            }
        ],
        [
            {
                key:'createVisit',
                inputContent:()=>{
                    const associatedVisit  = _.get(localThis,'state.associatedVisit');
                    if(associatedVisit){
                        const { appointmentAt,visitRoles=[],category } = associatedVisit;
                        const visitDay= moment(appointmentAt).format('MM/DD/YY');
                        const visitTime = moment(appointmentAt).format('HH:mm a');
                        const isVirtual = category=='VIRTUAL';
                        return <Row style={{margin:'10px 0px'}}>
                            <Col span={24}><h6>Follow Up Visit</h6></Col>
                            <Col>
                                <Row style={{ fontSize:14 }}>
                                    { isVirtual ? <Col span={2}><img src='/image/camera.png' width={15}/></Col> :'' }
                                    <Col span={10}>{`${visitDay} ${visitTime}`}</Col>
                                    <Col span={8}>{`Visit:${visitRoles.join(',')}`}</Col>
                                    <Col span={4}>
                                        <a href={'javascript:(void)'} style={{textDecoration:'underline'}} onClick={()=>fn(true)}>Edit</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    return <a href={'javascript:(void)'} style={{textDecoration:'underline'}} onClick={()=>fn(true)}>Schedule Follow Up</a>
                }
            }
        ]
    ]

const doctorFormConfig = props =>{
    return [
        [
            {
                key: 'reason',
                label:'Notes',
                labelCol: { span: 24 },
                span: 24,
                style:{ padding:'20px 0px' },
                inputContent: () => <TextArea rows={5}/>,
                formatValue: () => _.get(props, 'currentVisit.reason')
            },
        ]
    ]
}

const commonNoteFormConfig = props =>{
    return [
        [
            {
                key: 'reason',
                label:'Notes',
                labelCol: { span: 24 },
                span: 24,
                style:{ padding:'20px 0px' },
                inputContent: () => <TextArea rows={5}/>,
                formatValue: () => _.get(props, 'currentVisit.reason')
            },
        ]
    ]
}


const formConfig = (role,props,fn,localThis)=>{
   const map =  {
        'MA':MAFormConfig(props,fn,localThis),
        'MATwoRows':MAFormConfigTwoRows(props,fn,localThis),
        'Doctor': doctorFormConfig(props),
        'DoctorTwoRows':doctorFormConfig(props),
        'CA':doctorFormConfig(props),
        'RD':doctorFormConfig(props),
        'noteFormConfig': commonNoteFormConfig(props),
    }
    return map[role];
}

export {
    formConfig
}
