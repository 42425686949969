import gql from 'graphql-tag'
export default gql`
  mutation unstarMember($id:EID!,$reason:String){
    unFlagMember(id:$id,reason:$reason){
      id
      hasFlag
      flagDetails{
          createdBy{
              profile{
                  fullName
              }
          }
          reason
          createdAt
      }
    }
  }
`
