import { React, _, IHRadio } from 'ihcomponent';

export default (props) => {
  const { answer, onChange, isPreview } = props;
  const value = JSON.parse(_.get(answer, 'content') || '""');

  const OPTIONS = [{ name: 'YES', value: 0 }, { name: 'NO', value: 1 }];

  const onRadioChange = ({ target }) => {
    onChange({ content: JSON.stringify(target.value) });
  };

  return (
    <div className="true-or-false">
      <IHRadio
        option={OPTIONS}
        size="large"
        type="radio"
        disabled={isPreview}
        value={value}
        onChange={onRadioChange}
      />
    </div>
  );
};
