import React from "react";
import {Tag, Popover} from "antd";
import { parseSubscriptionStatus } from '../constant/index';
import { SubscriptionHistoryComponent } from '../component/SubscriptionHistoryComponent';
import '../style/index.scss';
export const SubscriptionTagComponent = (props)=>{
	const { subscriptionStatus,subscriptionList } = props;
	const TAG_STYLE = { marginLeft: 5, height: 20, border: 'none', verticalAlign: 'middle' };
	const { label,className } = parseSubscriptionStatus(subscriptionStatus.status,'TAG');
	return <Popover trigger="click" content={<SubscriptionHistoryComponent subscriptionList={subscriptionList}/>} overlayStyle={{ width:'fit-content' }}>
		<div style={{ display:'table'}} className={'subscriptionHeader '+className}><Tag style={{ ...TAG_STYLE, cursor: 'pointer' }}>MVP - {label}</Tag></div>
	</Popover>
}