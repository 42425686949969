import type from '../constants'

export default {
    noteStatus: (flag) => {
        return {
            type: type.NOTE_STATUS,
            payload: flag
        }
    },
    setNote: (flag) => {
        return {
            type: type.SET_NOTE,
            payload: flag
        }
    },
    setCallStickie: (flag) => {
        return {
            type: type.SET_CALL_STICKIE,
            payload: flag
        }
    },
    setNonExistNotification: (flag) => {
        return {
            type: type.SET_NON_EXIST_NOTIFICATION,
            payload: flag
        }
    }
}

export const setShowCreateComponent = (flag)=>({
    type : type.NOTE_STATUS,
    payload: flag
});