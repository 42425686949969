import { React, BaseComponent, ND, _, wrappedMapPropsToFields } from './util';
import IHFormItem from './IHFormItem';
import PropTypes from 'prop-types';

const Component = class extends BaseComponent {

  static displayName = 'IHFBaseorm';

  getEachFormItem(item) {
    // multiple items can be in a row, recursively
    // { type: 'rowGroup', gutter: 16, className, items: [{}, {}] }
    if (item.type === 'rowGroup') {
      return (
        <ND.Row className={item.className || ''} gutter={item.gutter || 0}>
          {item.items.map(i => (<ND.Col span={i.rowGroupSpan || 24}>{this.getEachFormItem(i)}</ND.Col>))}
        </ND.Row>
      );
    }

    const key = item.key;
    const { getFieldDecorator, getFieldError, isFieldValidating } = this.props.form;
    const ANTD_ATTR = [
      'label', 'labelCol', 'wrapperCol', 'help', 'validateStatus', 'hasFeedback', 'option', 'extra','type'
    ];

    const itemProp = Object.keys(item).reduce((prev, key) => {
      if (ANTD_ATTR.some(i => i === key) || key.startsWith('html')) {
        return { ...prev, [key]: item[key] };
      }
      return prev;
    }, {});

    const FieldFn = getFieldDecorator(key, {
      initialValue: item.initialValue,
      rules: item.rules || [],
    });

    const jsxTag = this.getJsxTag(item);
    const className = item.className ? `IH-FormItem ${item.className}` : 'IH-FormItem ';
    return (
      <ND.Form.Item key={item.key} className={className} {...itemProp}>
        {FieldFn(jsxTag)}

        {item.children || null}
      </ND.Form.Item>
    );
  }

  getJsxTag(item) {
        // TODO
    return <IHFormItem {...item} />;
  }

  render() {
    //console.log('Form:', this.props.form.getFieldsValue());

    const formProp = {
      style: this.props.style || {},
    };
    if (this.props.horizontal) {
      formProp.horizontal = "true";
    } else if (this.props.vertical) {
      formProp.vertical = "true";
    } else if (this.props.inline) {
      formProp.inline = "true";
    }

    if (this.props.onSubmit) {
      formProp.onSubmit = (evt) => {
        this.props.onSubmit(evt, this.props.form);
      };
    }

    return (
      <ND.Form className={'IH-Form'} {...formProp}>
        {this.props.itemList.map(item => this.getEachFormItem(item, item.key))}

        {this.props.children || null}
      </ND.Form>
    );
  }

};

Component.propTypes = {
  // horizontal : React.PropTypes,
  itemList: PropTypes.array.isRequired,
};

// export default ND.Form.create({
//     onFieldsChange : (props, fd)=>{
//         console.log(props, fd)
//     },
//     mapPropsToFields: ()=>{
//         console.log(arguments)
//     }
// })(Component)

const Form = class extends BaseComponent {
  // shouldComponentUpdate(nextProps, nextState) {
  //   return false;
  // }
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    if (!this.Demo) {
      const { onFieldsChange, mapPropsToFields } = this.props;
      this.Demo = ND.Form.create({ onFieldsChange, mapPropsToFields:wrappedMapPropsToFields(mapPropsToFields)
      })(Component);
    }

    return <this.Demo wrappedComponentRef={refNode => { this._form = refNode }} {...this.props}  />;
  }


  getValue() {
    return this._form.props.form.getFieldsValue();
  }

    // will validate
  getFormData(callback) {
        // let data = this.getValue()
    this._form.props.form.validateFieldsAndScroll((error, values) => {
      if (error) {
        callback(false);
        return;
      }
      callback(true, values);
    });
  }

  getFormDataSync(){
    return new Promise((resolve, reject)=>{
      this._form.props.form.validateFieldsAndScroll((error, values) => {
        if(error){
          reject(false)
          return
        }
        resolve(values)
      })
    })
  }

  setFormData(data) {
    if (data) {
      this._form.props.form.setFieldsValue(data);
    }
  }

  componentDidMount() {
    if (this.props.initData) {
      _.delay(() => {
        this.setFormData(this.props.initData);
      }, 10);
    }
  }
};

export default Form;
