const alertType = { 'LEVEL_ONE':'Level 1',
                    'LEVEL_TWO':'Level 2',
                    'LEVEL_THREE':'Level 3',
                    'LEVEL_FOUR':'Level 4',
                    'LEVEL_FIVE':'Level 5'
                  };

const taskType = {
                    'BG_CRITICAL_LOW':'BG Critical Low',
                    'BG_CRITICAL_HIGH':'BG Critical High',
                    'BP_CRITICAL_LOW':'BP Critical Low',
                    'BP_CRITICAL_HIGH':'BP Critical High',
                    'BG_HIGH_READING_LEVEL_TWO':'BG High Reading Level Two',
                    'BG_HIGH_READING_LEVEL_THREE':'BG High Reading Level Three'
                 };
const statusType = {
                    'TODO':'To do'
                    }
const hoverMessage = {
    'LEVEL_ONE_VISIT_DUE':'Schedule f/u visit w/ A1C (as needed). Message (1st attempt for active pts) or call patients (inactive or clear user), snooze for 1 day for 1st alert, 1 week for 2nd alert, and so on.',
    'LEVEL_TWO_A1C_DUE':'Transcribe new A1C & labs from EMR/Remind patient to do labs/Remind Dr to order labs/Do an in-house A1C.',
    'LEVEL_THREE_VISIT_OVERDUE':'Schedule f/u visit w/ A1C (as needed). Message (1st attempt for active pts) or call patients (inactive or clear user), snooze for 1 day for 1st alert, 1 week for 2nd alert, and so on.',
    'LEVEL_THREE_INACTIVE_PATIENT':'Call the patient to re-engage. Snooze for no more than 1 week at a time, attempting to call every week for at least 4 weeks before starting the discharge protocol.'
}

const alertDescriptions = {
  // PROVIDER
    LEVEL_ONE_BP_HTN_CRITICAL_HIGH: 'A critical high BP reading exists and the patient reports having new or concerning symptoms.',
    LEVEL_ONE_BP_HTN_CRITICAL_LOW: 'An unusually low BP reading for which the patient has reported having new or concerning symptoms. This depends on the patient\'s usual reading and will only be shown if this reading not typical for the patient.',
    LEVEL_TWO_BG_FASTING_HIGH: 'This patient\'s average fasting glucose is high according to ADA standards.', // Fasting Glucose High
    LEVEL_THREE_BP_HTN_STAGE_2_HIGH: 'The patient\'s weekly average blood pressure is above an acceptable stage 2 range.',
    LEVEL_THREE_BP_HTN_STAGE_2_AVERAGE: 'This patient\'s average blood pressure has been in stage 2 range set by the AHA.',
  // MA
    LEVEL_ONE_VISIT_DUE: 'There is no follow up visit scheduled between 7 days before the visit window and last day of the visit window, and there is no follow up visit scheduled in the future.',
    LEVEL_TWO_A1C_DUE: 'There is no A1C result between 15 days before and 15 days after the last follow-up visit, or 30 days before or 15 days after an initial visit.',
    LEVEL_THREE_VISIT_OVERDUE: 'Today is past the last day of the follow up visit window and no visit is scheduled within the visit window or in the future.',
    LEVEL_THREE_INACTIVE_PATIENT: 'This patient did not record vitals for 2 consecutive weeks after 1 month of enrollment.', // 2-week-inactive
  //CDE
    LEVEL_TWO_BP_HTN_CRITICAL_HIGH:'The patient has had frequent critical high readings recently, but has never reported to have any symptoms.', // Asymptomatic
    LEVEL_TWO_BP_HTN_CRITICAL_LOW: 'The patient has had frequent critical low readings, but has never reported to have any symptoms.', // Asymptomatic
    LEVEL_ONE_BG_CRITICAL_HIGH: 'This patient recorded a high glucose reading and may or may not have a retest that confirms this.\nIf the patient retests and it is out of critical range, this alert will close automatically.',
    LEVEL_ONE_BG_CRITICAL_LOW: 'This is a low glucose. The patient may or may not have a retest that confirms this\nIf the patient retests their glucose and it was in normal range, this alert would be automatically closed.',
    LEVEL_ONE_BP_MANUAL_TRIAGE_CRITICAL_HIGH: 'This patient recorded a critical high blood pressure and has not confirmed if symptomatic or not.',
    LEVEL_ONE_BP_CRITICAL_LOW: 'This patient recorded an atypically critical low blood pressure reading and has not confirmed if symptomatic or not.', // BP Low Manual Triage
    LEVEL_ONE_SPO2_CRITICAL_LOW: 'This patient recorded a critical low blood oxygen level according to diagnoses on file, and the patient\'s baseline, if it exists.',
    LEVEL_ONE_SPO2_HYPOXIA_CRITICAL_LOW: 'This patient recorded a critical low blood oxygen level according to chronic hypoxic diagnoses on file.',
    LEVEL_TWO_SPO2_LOW: 'This patient recorded a low blood oxygen level according to diagnoses on file, and the patient\'s baseline, if it exists.',
    LEVEL_TWO_SPO2_HYPOXIA_LOW: 'This patient recorded a low blood oxygen level according to chronic hypoxic diagnoses on file.',
    LEVEL_TWO_BG_CRITICAL_HIGH_BEFORE_MEAL: 'This patient has multiple readings before a meal that are higher than the ADA\'s recommended range for good control.',
    LEVEL_TWO_BG_CRITICAL_HIGH_AFTER_MEAL: 'This patient has multiple readings after a meal that are higher than the ADA\'s recommended range for good control.',
    LEVEL_TWO_BG_PAIR_READING: 'This patient has a pre and post meal glucose reading where the difference between the readings is >100mg/dL',
    LEVEL_THREE_1_DAY_AFTER_ENROLLMENT: 'It is the day after the patient\'s first reading.',
    LEVEL_THREE_14_DAY_ENROLLMENT_ENGAGEMENT: 'If this patient is an app user, he/she has not taken a measurement for 14 days after signing the consent form. If this patient is not an app user, it has been 14 days since the patient has signed the consent form.',
    LEVEL_THREE_BIRTHDAY: 'Today is the patient\'s birthday.',
    LEVEL_THREE_HIGH_FASTING_BG: 'This patient\'s pre-meal glucose is frequently elevated, but not urgently high per typical ranges suggested by the ADA.', // High BG Pre-meal
    LEVEL_THREE_HIGH_AFTER_MEAL_BG: 'This patient\'s post-meal glucose is frequently elevated, but not urgently high per typical ranges suggested by the ADA.',
    LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS: 'There is no provider note written by RD on this patient\'s chart in the last 4 weeks.' // Engage & Document
};

export default {
    alertType,
    taskType,
    statusType,
    hoverMessage,
    alertDescriptions
}
