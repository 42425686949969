import React from 'react'
import BaseComponent from '../BaseComponent'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range';
import * as ND from 'antd'
import * as RB from 'react-bootstrap'
import classNames from 'classnames'

let _id = 1
const getUuid = ()=>{
    _id++
    return `uuid_${_id}`
}

const message = ND.message
message.config({
  top : 80,
  duration : 2
})

const wrappedMapPropsToFields = (mapPropsToFields)=>{
  return (props)=>{
    const fields = _.isFunction(mapPropsToFields)? mapPropsToFields(props):mapPropsToFields;
    if (!fields) return fields;
    const wrappedFileds = {};
    Object.keys(fields).forEach(k=>{
      wrappedFileds[k] = ND.Form.createFormField(fields[k])
    });
    return wrappedFileds
  };
}

const moment = extendMoment(Moment);
export {
    React,
    BaseComponent,
    _,
    moment,
    ND,
    RB,
    getUuid,
    classNames,
    message,
    wrappedMapPropsToFields
}
