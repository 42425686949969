import PropTypes from 'prop-types';
import React from 'react';
import ListTable from './MyProgramsTableComponent'

const MyPrograms = (props) => {
  return (
    <div className="vsm-main-page vsm-program-list-main">
      <div className="vsm-main-table">
        <div className="v-table">
          <ListTable {...props} />
        </div>
      </div>
    </div>
  )
}

export default MyPrograms
