import update from 'immutability-helper'

export const enrolledProgram = {
  enrolledProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.editEnrolledProgram

    return update(prev, {
      enrolledProgram: {
        $set: result
      }
    })
  },
}

export const enrolledProgramList = {
  enrolledProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.editEnrolledProgram

    return update(prev, {
      enrolledProgram: {
        $set: result
      }
    })
  },
}

export const getUserEnrolledProgramList = {
  enrolledProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.editEnrolledProgram

    return update(prev, {
      enrolledProgram: {
        $set: result
      }
    })
  },
}

export const removeTaskFromEnrolledProgram = {
  enrolledProgram: (prev, { mutationResult }) => {
    const result = mutationResult.data.removeTaskFromEnrolledProgram

    return update(prev, {
      enrolledProgram: {
        $set: result
      }
    })
  },
}
