import { graphql } from 'react-apollo'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateAntdForm } from 'libModule/utils'
import { getFormProps } from 'ProgramEditModule/ProgramInfo/components/formData/ProgramInfoForm'
import { editProgramInfo } from 'graphqlModule/mutation/editAdminProgram'
import { program as updateQueries } from 'graphqlModule/updateQueries/program'
import pick from 'lodash/pick'
import { IHForm, IHButton, message } from 'ihcomponent'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'program/ProgramEdit/ProgramInfo/containers/ProgramInfoFormContainer'

  init() {
    const { program } = this.props
    const formData = pick(program, ['name', 'description', 'healthCondition', 'duration'])
    const formatedFormData = {
      ...formData,
      durationLength: formData.duration.length,
      durationCalendar: formData.duration.calendar
    }
    delete formatedFormData.duration
    return formatedFormData
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className="program-new-info-form">
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const initData = this.init()
    const formProps = getFormProps(initData)
    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '67%',
        paddingRight: 5
      }
    }

    const buttonProps = {
      submit: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save'
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.submit} />
      </div>
    )
    return footer
  }

  async submit() {
    const formRef = this.baseForm
    // validate all fields before submission
    const isFormValid = await validateAntdForm(formRef)
    if (!isFormValid) return
    const { mutate, program } = this.props
    const fieldsValue = formRef.getValue()
    const {
      name,
      description,
      durationLength,
      durationCalendar
    } = fieldsValue
    const variables = {
      id: program.id,
      name,
      description,
      duration: {
        length: durationLength,
        calendar: durationCalendar
      }
    }

    try {
      const res = await mutate({ variables, updateQueries })
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.edit.save_program_info'),
        details: fieldsValue,
        request: variables,
        response: res
      })
      message.success(`${I18N.get('keywords.Program')} Changes have been Saved`)
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.edit.save_program_info'),
        details: fieldsValue,
        request: variables,
        response: err,
        success: false
      })
    }
  }
}

// data from apollo
const withData = graphql(editProgramInfo)

// connect apollo data with container
const ContainerWithData = withData(Container)

Container.propTypes = {
  mutate: PropTypes.func,
  program: PropTypes.object,
}

export default ContainerWithData
