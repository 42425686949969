import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import get from 'lodash/get'
import clone from 'lodash/clone'
import uniqBy from 'lodash/uniqBy'
import { statusSortOrder } from 'modulesAll/utils/helpers/task'
import actions from '../actions/PatientTaskListActions'
import createTableAction from 'libModule/table/TableActions';
import PatientUpcomingTaskListTableComponent from '../components/PatientUpcomingTaskListTableComponent';
import { resultsFromTodayMinimal as resultsFromToday } from 'modulesAll/graphql/resultsFromToday'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'

const tableActions = createTableAction('patient_upcoming_task_list');

//To Fix: cache this calculation in MapState with reselect
const vitalFilters = (resultsFromToday) => {
  return uniqBy(resultsFromToday || [], 'type').map( (d) => {
    return {
      value: get(d, 'type'),
      text: TASK_TYPE_MAP[get(d, 'type')]
    }
  })
}

//To Fix: cache this calculation in MapState with reselect
const programFilters = (resultsFromToday) => {
  return uniqBy(resultsFromToday || [], 'enrolledProgram.name').map( (d) => {
    return {
      value: get(d, 'enrolledProgram.id'),
      text: get(d, 'enrolledProgram.name')
    }
  })
}

//To Fix: cache this calculation in MapState with reselect
const getSortedList = (resultsFromToday) => {
  return (clone(resultsFromToday) || []).sort( (a, b) => {
    const difference = statusSortOrder.indexOf(a.taskStatus) - statusSortOrder.indexOf(b.taskStatus)
    if(difference === 0) {
      return a.date - b.date
    }
    return difference
  })
}

const getFilteredList = (resultsFromToday) => {
  return (resultsFromToday || [])
  .filter( (a) => {
    return ['CURRENT', 'UPCOMING', 'INCOMPLETE'].includes(a.taskStatus)
  })
}

const withData = graphql( resultsFromToday, {
  options: (ownProps) => {
    const variables = {
      "filters": {
        "memberId": sessionStorage.getItem('currentUserId')
      },
    }
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ownProps, data}) => {
    const { loading } = data
    return {
      dataSource: getFilteredList(data.resultsFromToday || []),
      data,
      loading,
      programFilters: programFilters(getFilteredList(data.resultsFromToday || [])),
      vitalFilters: vitalFilters(getFilteredList(data.resultsFromToday || []))
    }
  }
})

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.PatientTaskList,
  ...state.globalTables.patient_upcoming_task_list,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...tableActions,
    addReading: (row) => dispatch(actions.addReading(row))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData,
)(PatientUpcomingTaskListTableComponent);
