import { React, _, message } from 'ihcomponent';
import { withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { GQLHelper } from 'libModule/utils';
import { browserHistory } from 'react-router';
import createAssessment from 'modulesAll/graphql/createAssessment';
import publishAssessment from 'modulesAll/graphql/publishAssessment';
import { openErrorModal, openSuccessModal } from 'layoutModule/actions/MainModal';
import CreateComponent from '../components/CreateComponent';
import action from 'modulesAll/assessment/edit/actions';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import {
  validateAndReturnFields,
  getGraphAssessment,
} from 'modulesAll/assessment/util';

const component = (props) => {
  const { client, fields, saveAssessment, setSubmitting } = props;
  // fields.healthCondition.__value = 'HEART_FAILURE'; //front-end removed health condition input, workaround backend checking

  const save = async ({ btn }) => {
    const isSurvey = true
    if (validateAndReturnFields(_.set(fields, '_status', 'PENDING'), isSurvey).__error) {
      return saveAssessment();
    }

    const variables = {
              ...getGraphAssessment(fields, 'SURVEY'),
              healthCondition: 'HEART_FAILURE',
              // Publish at the same time
              publish: true
    }

    try {
      setSubmitting(true);
      if (btn) btn.loading(true)

      const res = await client.mutate({
        mutation: createAssessment,
        variables
      });

      createAuditLog({
        action:I18N.get('auditLog.admin.survey.create'),
        details: { ...getGraphAssessment(fields, 'SURVEY') },
        request: variables,
        response: res
      })

      // await client.mutate({
      //   mutation: publishAssessment,
      //   variables: {
      //     // id: newAssessment.data.createAssessment._id,
      //     nameKey: newAssessment.data.createAssessment.nameKey,
      //   },
      // });

      const modalTitle = 'Survey Created Successfully'
      const modalContent = 'A new survey has been created successfully'
      props.openSuccessModal(modalTitle, modalContent);
      browserHistory.push('/surveys');
    } catch (e) {
      props.openErrorModal(GQLHelper.formatError(e));
      createAuditLog({
        action:I18N.get('auditLog.admin.survey.create'),
        details: { ...getGraphAssessment(fields, 'SURVEY') },
        request: variables,
        response: e,
        success: false
      })
    } finally {
      setSubmitting(false);
      if (btn) btn.loading(false)
    }
  };

  return <CreateComponent onSave={save} {...props} />;
};

component.displayName = 'survey/edit/containers/CreateContainer';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  fields: state.Assessment.edit.fields,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSubmitting: isSubmitting => dispatch(action.setSubmitting(isSubmitting)),
  saveAssessment: () => dispatch(action.saveAssessment()),
  resetForm: () => dispatch(action.loadAssessment()),
  onCancel: () => browserHistory.push('/surveys'),
  openErrorModal: errorMessage => dispatch(openErrorModal(errorMessage)),
  openSuccessModal: (title, content) => dispatch(openSuccessModal(title, content)),
});

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
)(component);
