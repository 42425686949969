import type from '../constants/ProgramInfoTab'

const initialState = {
  step : 'programme'
};

export default (state = initialState, action) => {
  if (action.type === type.SET_CURRENT_STEP) {
    return {
      ...state,
      step : action.step
    }
  }

  return state;
};
