import React from 'react';
import { Row, Col, Tooltip, Badge, Popover, Tag } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from '../../I18N';
import _ from 'lodash';

import { VISIT_ROLES, VISIT_TYPE_NAME_MAPPING, VITALS_ORDER } from '../../../lib/constants';
import { goPath, extractUserName, getProviderName } from '../../../lib/utils';
import { WORKING_LIST_STATUS_MAPPING, POTENTIAL_CCMRPM_LIST,  } from './index';
import {
  getVisitCategoryImg,
  getPatientPhoneInfo,
  getPatientStatus,
  getPatientInfo,
  getVisitStatus,
  toArrayConditionList,
  renderSelfTag,
  visitStatusContent, checkIfRescheduled, renderRescheduledVisit
} from '../helpers';
import { formatDate, APPOINTMENT_TIME_FORMAT, SIMPLE_DATE_FORMAT } from '../helpers/visitDateHelpers';
import UnderlinedTooltip from '../../patientList/main/components/UnderlinedTooltip';
import ViewMoreEffect from '../../patient/main/components/ViewMoreEffect';
import ReferAndEnrollButtonWrapper from '../../referAndEnroll/container/ReferAndEnrollButtonWrapper';

const colStyle = {
  display: 'flex',
  flexDirection: 'column'
};

const renderVitals = (vitalTypes, vitalsWithStatus, vitalOrder = VITALS_ORDER) => {
  if(!vitalTypes || !vitalTypes.length) return 'N/A';

  return <div>
    {
      _.map(_.intersection(vitalOrder, vitalTypes), type => {
        const { inactive = true } = vitalsWithStatus[type] || {};
        const getTooltipMsg = () => {
          const vitalName = _.get(I18N.get('vitalsAbbreviation'),type);
          return `Patient has ${inactive ? 'not' : ''} checked ${vitalName} vitals in the past 2 weeks`;
        };
        return <span key={type} style={{ marginRight: 3 }}>
          <Tooltip title={getTooltipMsg()} overlayClassName='vitals-status-tooltip'>
            <img
              style={{ width: 20 /*, margin: '-1px 8px 0px 0px'*/ }}
              src={`/image/${type}.svg`}
              className={inactive ? 'vitals-inactive-status' : ''}
            />
          </Tooltip>
        </span>
      })
    }
  </div>;
};

const commonVisitRender = record => {
  const { appointmentAt, type, category, member } = record;
  const isInitialVisit = VISIT_TYPE_NAME_MAPPING[type] === 'Initial Visit';
  return <Col span={24} style={colStyle}>
    <span>
      {getPatientPhoneInfo(member, category)}
      {formatDate(appointmentAt, APPOINTMENT_TIME_FORMAT)}
    </span>
    <span style={{
      fontWeight: isInitialVisit ? 600 : 'unset',
      color: isInitialVisit ? 'rgb(54, 197, 204)' : 'inherit'
    }}>
      {VISIT_TYPE_NAME_MAPPING[type] || type}
    </span>
  </Col>;
};

const visitCategory = (columnProps, props) => ({
  key: 'visitCategory',
  title: I18N.get('visit.columns.category'),
  className: 'visits-table-column-category',
  width: 32,
  fixed: 'left',
  render: (__, record) => {
    const category = record.category;
    const { src, tip, style } = getVisitCategoryImg(category);
    const customImgStyle = _.get(props, 'imgStyle', {});
    return src ?
    <Tooltip overlayClassName='visits-tooltip-category' title={tip}>
      <img src={src} alt={category} width={15} style={{...style, ...customImgStyle}}/>
    </Tooltip>
    : ''; // empty is important, to align pending visit table
  },
  ...columnProps
});

const visit = (columnProps) => ({
  key: 'visit',
  title: I18N.get('visit.columns.visit'),
  className: 'visits-table-column-visit',
  width: 150,
  fixed: 'left',
  render: (__, record) => {
    return <Row>
      {commonVisitRender(record)}
    </Row>;
  },
  ...columnProps
});

const allVisit = (columnProps) => visit({
  render: (__, record) => {
    const { appointmentAt } = record;
    return <Row>
      <Col span={24}>
        {formatDate(appointmentAt, SIMPLE_DATE_FORMAT)}
      </Col>
      {commonVisitRender(record)}
    </Row>;
  },
  ...columnProps
});

const renderPatient = (record, { highlightName } = {}) => {
  const patientInfo = getPatientInfo(record.member);
  const isSelfEnrolled = _.get(record, 'member.profile.isSelfEnrolled');
  return <Row>
    <Col style={colStyle}>
      <a
        className='visits-patient-name-link'
        onClick={e => {
          e.preventDefault();
          // expandToggle statically defined in dashboard/main
          Mixpanel.calculateDuration('expandToggle');
          Mixpanel.track('clicked', 'Patient name', 'Dashboard', { PATIENT_ID: patientInfo.id });
          goPath(patientInfo.profilePath);
          Mixpanel.time_event('patient_profile');
        }}
      >
        {
          typeof highlightName === 'function' ?
          highlightName(patientInfo.fullName) :
          patientInfo.fullName
        }
        {isSelfEnrolled ? renderSelfTag() : ''}
      </a>
      <div>{ patientInfo.age } ys/{ patientInfo.gender }</div>
    </Col>
  </Row>;
};

const patient = (columnProps, props) => ({
  key: 'patient',
  title: I18N.get('visit.columns.patient'),
  className: 'visits-table-column-patient',
  width: 230,
  fixed: 'left',
  render: (__, record) => renderPatient(record, props),
  ...columnProps
});

const primaryProvider = (columnProps) => ({
  key: 'primaryProvider',
  title: <UnderlinedTooltip tooltipMsg={`This is the patient's primary care provider`}>
    Provider
  </UnderlinedTooltip>,
  className: 'visits-table-column-primary-provider',
  width: 140,
  render: (__, record) => {
    const doctor = _.get(record, 'member.profile.doctor');
    return getProviderName(doctor);
  },
  ...columnProps
});

const enrollStatusVitals = (columnProps) => ({
  key: 'enrollStatusVitals',
  title: I18N.get('visit.columns.enrollVitals'),
  className: 'visits-table-column-enroll-status-vitals',
  width: 212,
  render: (__, record) => {
    const { member, enrolledProgramStatus, vitalTypes, conditionsList, id } = record;
    const refetchVisitList  = _.get(columnProps,'refetchVisitList');
    const patientInfo = getPatientInfo(member);
    let patientStatus = getPatientStatus(enrolledProgramStatus);
    let patientReferStatus = _.get(record,'patientRefer.status')||{};
    let { referStatus, enrollStatus }  = patientReferStatus;
    let referCompletedStatus  = referStatus === 'COMPLETED' ? ' - Referred':'';
    let patientEPInfo = '';
    let showReferAndEnrollBtn = enrollStatus !== 'COMPLETED';
    switch(patientStatus){
      case 'Enrolled':
        patientStatus += patientInfo.enrolledProgramCategories;
        patientEPInfo = renderVitals(vitalTypes, patientInfo.vitalsWithStatus);
        referCompletedStatus = '';
        showReferAndEnrollBtn = false;
        break;
      default:
        const conditionsListArray = toArrayConditionList(conditionsList);
        if(_.intersection(POTENTIAL_CCMRPM_LIST, conditionsListArray).length){
          patientEPInfo = <div>
            <UnderlinedTooltip tooltipMsg='This patient is a potential candidate for CCM/RPM according to the selected diagnoses in the visit note.'>
              <span style={{ fontWeight: 'bold' }}>Potential CCM/RPM</span>
            </UnderlinedTooltip>
          </div>;
        }
    }

    return <div>
            <div>{patientStatus}{referCompletedStatus}</div>
            {(_.get(member, 'patientMntProgram.status') === 'REFERRED' || _.get(member, 'patientMntProgram.visit.id') === id) &&
              <span style={{color: 'var(--dark-blue)'}}>Referred - MNT</span>}
            {patientEPInfo}
            {
              showReferAndEnrollBtn &&
              <ReferAndEnrollButtonWrapper
                patientReferral={ record.patientRefer }
                btnType={'VISIT_TABLE'}
                memberId={member.id}
                referStatus={referStatus}
                enrollStatus={enrollStatus}
                refetchQuery={refetchVisitList}
              />
            }
           </div>;
  },
  ...columnProps
});

const participant = (columnProps) => ({
  key: 'participant',
  title: I18N.get('visit.columns.participant'),
  className: 'visits-table-column-participant',
  width: 212,
  render: (__, record) => {
    const { type, visitRoles, assignees } = record;
    const visitRolesInOrder = _.intersection(VISIT_ROLES, visitRoles);
    const { assignedToCA, assignedToRD, assignedToHC } = assignees || {};

    const visitProvider = _.get(record, 'providers.0') || {};

    const getParticipantName = fullName => {
      return fullName || 'Not assigned';
      // const extractedName = extractUserName({ profile: { fullName } });
      // const { firstName, lastName } = extractedName || {};
      // return (!firstName && !lastName) ? 'Not assigned' :
      //        `${firstName || ''}${lastName ? ` ${lastName}` : ''}`;
    };

    const RDdisplay = type === 'MNT' ? 'RD' : 'RD/HC';

    const nameRenderMap = {
      'MD': { label: 'Provider', getName: () => getProviderName(visitProvider) },
      'MA': { },
      'RD': { label: RDdisplay, getName: () => getParticipantName(_.get(assignedToRD, 'name')) },
      'CA': { getName: () => getParticipantName(_.get(assignedToCA, 'name')) },
      // 'HC': { getName: () => getParticipantName(_.get(assignedToHC, 'name')) },
    };

    return <Row>
      <Col style={colStyle}>
        {
          _.map(visitRolesInOrder, role => {
            const mapped = nameRenderMap[role];
            if(!mapped) return null;
            const { label, getName } = mapped;
            return <span key={role}>
              {label || role}{getName ? `: ${getName()}` : ''}
            </span>;
          })
        }
      </Col>
    </Row>
  },
  ...columnProps
});

const visitNote = (columnProps) => ({
  key: 'visitNote',
  title: I18N.get('visit.columns.note'),
  className: 'visits-table-column-note',
  width: 240,
  render: (__, record) => {
    const { reason, conditionsList } = record;
    let visitNote = reason || '';
    let conditionsListArray = toArrayConditionList(conditionsList);
    if(conditionsListArray.length) {
      // "Dx:" is the title
      const cdListString = `\nDx: ${_.join(conditionsListArray, ' ')}`;
      visitNote = visitNote.concat(cdListString);
    }
    const replacedVisitNote = _.replace((visitNote || '').trim(), /\n/g, ' ');
    const visitNoteTokens = _.split(replacedVisitNote, /\s+/g);
    return <ViewMoreEffect
        idSuffix={record.id} // important
        elements={visitNoteTokens}
        renderEl={(token, key) => {
          return <span key={key} style={{ display: 'inline-block', marginRight: 3 }}>
            {token}
          </span>;
        }}
        elementContainer={elNodes =>
          _.get(elNodes, 'length') ? elNodes : <span>--</span>
        }
        numRows={2}
        elementHeight={21}
        customViewMoreEl={({ key }) => {
          return <Popover
            key={key}
            trigger='click'
            overlayClassName='visit-note-show-all-popup'
            content={
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {visitNote}
              </div>
            }
            arrowPointAtCenter
            destroyTooltipOnHide
          >
            <a id={key}>
              view more
            </a>
          </Popover>
        }}
      />;
  },
  ...columnProps
});

const visitStatus = (columnProps) => ({
  key: 'visitStatus',
  title: I18N.get('visit.columns.status'),
  className: 'visits-table-column-visit-status',
  width: 172,
  render: (__, record) => {
    return visitStatusContent(record);
    // const {
    //   statusText, badgeStatus, extraStatus, statusColor, customStatus
    // } = getVisitStatus(record);
    //
    // return <Row>
    //   <Col span={24}>
    //     <Badge
    //       color={statusColor}
    //       status={badgeStatus || null}
    //       text={statusText}
    //       {...customStatus}
    //     />
    //   </Col>
    //   {
    //     extraStatus &&
    //     <Col span={24}>
    //       {extraStatus({ record })}
    //     </Col>
    //   }
    // </Row>
  },
  ...columnProps
});

const visitAction = (columnProps, props) => ({
  key: 'visitAction',
  title: I18N.get('visit.columns.action'),
  className: 'visits-table-column-visit-action',
  width: 140,
  render: (__, record) => {
    const { rescheduledToVisit } = record;
    const { action } = getVisitStatus(record);
    const { isNoShow,visitRescheduled } = checkIfRescheduled(record);
    if(isNoShow&&visitRescheduled) {
      return renderRescheduledVisit(rescheduledToVisit);
    }
    return action ? action({ record, ...props }) : '--';
  },
  ...columnProps
});

const chart = (columnProps, props) => ({
  key: 'chart',
  title: I18N.get('visit.columns.chart'),
  width: 140,
  render: (__, record) => {
    const workingListStatus = _.get(record, 'workingListStatus') || 'TODO';
    const { imgSrc, tip = '', imgProps = {} } = WORKING_LIST_STATUS_MAPPING[workingListStatus];
    const isChartDisabled = !record.confirm;
    return <div style={{ paddingLeft: 11 }}>
      <Tooltip overlayClassName='visits-tooltip-chart-status' title={tip}>
        <img
          src={imgSrc}
          alt={workingListStatus}
          width={22} height={24.5}
          className='clickable-img'
          {
            ...isChartDisabled ?
            { style: { cursor: 'not-allowed' } }
            :
            { onClick: () => props.renderVisitWorkList(record) }
          }
          {...imgProps}
        />
      </Tooltip>
      <img
        src='/image/edit.png'
        alt='edit'
        className={`edit-icon-hoverable clickable-img` }
        width={20} height={20}
        onClick={() => props.renderVisitEdit({...record,isEdit:true})}
      />
    </div>;
  },
  ...columnProps
});

const tableColumns = {
  visitCategory,
  visit,
  allVisit,
  renderPatient,
  patient,
  primaryProvider,
  enrollStatusVitals,
  participant,
  visitNote,
  visitStatus,
  visitAction,
  chart
};

export default tableColumns;
