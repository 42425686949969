import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Select, InputNumber, Tooltip, Icon } from 'antd';
import { frequencyRanges, previousDietOptions, nutritionUnderstandingOptions } from '../../constant/nutritionIntakeConstants';
import formRenderer from '../../helpers/formBuilder';
import './nutritionIntakeQuestionaire.styles.scss';
import I18N from 'modulesAll/I18N';

const generateFrequencyConfig = ({
    props, 
    label, timesKey, timesUnit, 
    rangeKey
  }) => [
  {
    label,
    key: timesKey,
    value: _.get(props, `nutritionValues.${timesKey}`), // default to Blank
    colProps: { span: 3, divider: '/' },
    render: () => <InputNumber
      disabled={props.disabled}
      style={{ width: '90%', marginRight: '2%' }}
      min={0}
      max={10}
      formatter={value => {
        const intValue = _.parseInt(value);
        if(intValue > 0) {
          const times = `${timesUnit}${intValue > 1 ? 's' : ''}`;
          return `${intValue}${intValue === 10 ? '+' : ''} ${times}`;
        }
        return value;
      }}
      // parser used to remove timesUnit added in formatter
      parser={value => {
        const regEx = new RegExp(`[+\s]*${timesUnit}[s]*`,'g');
        return value ? parseInt(value.replace(regEx, '').trim()) : null;
      }}
    />
  },
  {
    label: '',
    key: rangeKey,
    colProps: { span: 3, style: { marginRight: '5rem' } },
    value: _.get(props, `nutritionValues.${rangeKey}`, frequencyRanges[0].key), // default to WEEK
    render: () => <Select
      disabled={props.disabled}
      style={{ width: '90%', marginRight: '2%' }}
    >
    {
      _.map(frequencyRanges, option => 
        <Select.Option key={option.key} value={option.key}>{option.value}</Select.Option>
      )
    }
    </Select>
  }
]

const getFormConfigs = props => [
  [
    ...generateFrequencyConfig({
      props, 
      label: 'Fast Food Frequency', 
      timesKey: 'fastFoodFreq.value',
      timesUnit: 'time', 
      rangeKey: 'fastFoodFreq.unit'
    }),
    ...generateFrequencyConfig({
      props, 
      label: 'Sweet Beverage Frequency', 
      timesKey: 'sweetBeverageFreq.value',
      timesUnit: 'drink', 
      rangeKey: 'sweetBeverageFreq.unit'
    }),
  ],
  [
    {
      label: 'Previous Calorie Management Regimens',
      key: 'previousDiets',
      value: _.get(props, 'nutritionValues.previousDiets', []),
      render: () => <Checkbox.Group
        disabled={props.disabled}
        name='previous-diets-cb-group' // used for custom styles 
        options={previousDietOptions}
        onChange={checkedValues => {
          const oldCheckedValues = props.form.getFieldsValue().previousDiets
          // form setFieldsValue doesn't work
          // mutate the checked values directly works
          if (_.includes(oldCheckedValues, 'NONE') && checkedValues.length > 1) {
            // NONE is checked and new box is checked, remove NONE
            _.pull(checkedValues, 'NONE');
          } else if(_.includes(checkedValues, 'NONE')) {
            // NONE is checked, keep only NONE
            _.remove(checkedValues, value => value !== 'NONE');
          }
        }}
      />
    }
  ],
  [
    {
      label: <span>Nutrition Understanding<Tooltip title={I18N.get('NutritionUnderstanding')}><Icon type="info-circle" style={{marginLeft: 5}} /></Tooltip></span>,
      key: 'nutritionUnderstanding',
      colProps: { span: 12 },
      value: _.get(props, 'nutritionValues.nutritionUnderstanding', ''),
      render: () => <Select
          disabled={props.disabled}
          style={{ width: '98%', height: '52px !important' }}
        >
        {
          _.map(nutritionUnderstandingOptions, (option, index) => {
              return <Select.Option 
                className='nutrition-understanding-item' 
                value={option} 
                key={index}>
                  {option}
              </Select.Option>
            })
        }
      </Select>
    },
    {
      label: 'Additional Comments',
      key: 'additionalComments',
      colProps: { span: 12 },
      value: _.get(props, 'nutritionValues.additionalComments', ''),
      render: () => <Input.TextArea
        disabled={props.disabled}
        style={{ marginTop: 3.7 }} autoSize={{ minRows: 2 }}
      />
    }
  ]
]

const NutritionIntakeQuestionaire = props => {  
  return <div id='food-drink-freq-container'>
    { formRenderer(props.form, () => getFormConfigs(props)) }
  </div>
};

NutritionIntakeQuestionaire.propTypes = {
  nutritionValues: PropTypes.object.isRequired,
  setHasChanges: PropTypes.func.isRequired
}

export default Form.create({
  onValuesChange: (props, __, allFieldsValue) => props.setHasChanges(allFieldsValue)
})(NutritionIntakeQuestionaire);
