import { IHLoading, _ } from 'ihcomponent'
import { connect } from 'react-redux'
import React, { Component } from 'react';
import VitalFormContainer from 'patientModule/enrolledProgramInfo/Careplan/containers/VitalFormContainer'
import MainPage from 'patientModule/enrolledProgramInfo/Careplan/containers/MainPageContainer'
import actions from 'patientModule/enrolledProgramInfo/Careplan/actions/CommonActions'
import types from 'patientModule/enrolledProgramInfo/Careplan/constants/CommonConstants'
import AssessmentFormContainer from 'patientModule/enrolledProgramInfo/Assessment/containers/FormContainer'
import SurveyFormContainer from 'patientModule/enrolledProgramInfo/Survey/containers/FormContainer'
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import { graphql, compose } from 'react-apollo';
import get from 'lodash/get'
import careplanActions from 'patientModule/enrolledProgramInfo/Careplan/actions/CommonActions'
import { labelCurrentinProgramBGTasks, trace } from '../../../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';
import { withProps } from 'recompose';
import PropTypes from 'prop-types';
import * as R from 'ramda';
// container
class Container extends Component {
  static displayName = 'patient/enrolledProgramInfo/Careplan/containers/CommonContainer'

  componentDidMount() {
    // for TemporaryButton.js use
    const { initVitalList, currentProgram, changePage } = this.props
    const program = currentProgram
    initVitalList(program)

    changePage()
  }
  render() {
    if (this.props.data.loading) {
      return <IHLoading className="vsm-patient-enrolledprogram-main" />
    }
    return (
      <div className='vsm-careplan-page'>
        {this.renderCurrentPage()}
      </div>
    )
  }

  renderCurrentPage() {
    const { Common, changePage,isVitalEditBtnDisabled } = this.props
    const { currentPage } = Common
    const program = this.props.data.enrolledProgram
    const careplanProps = {
      ...Common,
      ...this.props
    }
    const commonProps = {
      changePage,
      program
    }

    if (_.isNull(this.props.program)) {
      console.error('program created error, program is: ', program)
      return <div>There is an error retrieving your data, please try to refresh the browser.</div>
    }

    switch(currentPage) {
      case types.CAREPLAN_MAIN_PAGE:
        return <MainPage {...careplanProps} {...commonProps} />
      case types.CAREPLAN_VITAL_FORM_PAGE:
        return <VitalFormContainer {...commonProps} changeOnFirstLoadFlag={this.props.changeOnFirstLoadFlag} patientId = {this.props.patientId} isVitalEditBtnDisabled={isVitalEditBtnDisabled}/>
      case types.ASSESSMENT_FORM_PAGE:
        return <AssessmentFormContainer {...commonProps} />
      case types.SURVEY_FORM_PAGE:
        return <SurveyFormContainer {...commonProps} />
      default:
        return <MainPage {...careplanProps} {...commonProps} />
    }
  }
}

// mapState
const mapState = ({ patient }, ownProps) => {
  return {
    ...patient.enrolledProgramInfo.Careplan
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    changePage: (page) => dispatch(actions.changePage(page)),
    initVitalList: (program) => dispatch(careplanActions.initVitalList(program))
  }
}

const withData = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: get(ownProps, 'currentProgram.id'),
    },
    notifyOnNetworkStatusChange: true,
  }),
  props: (props) => labelCurrentinProgramBGTasks(['data', 'enrolledProgram', 'tasks'], props),
});

// PropTypes validation
Container.propTypes = {
  changePage: PropTypes.func,
  data: PropTypes.object,
  Common: PropTypes.object,
  initVitalList: PropTypes.func,
  currentProgram: PropTypes.object,
}

// connect container with mapState and mapDispatch then export
export default compose(
  connect(mapState, mapDispatch),
  withData,
)(Container);
