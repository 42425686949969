import gql from 'graphql-tag';

export default gql`
  query weeklyBGScheduleTemplateMany {
    weeklyBGScheduleTemplateMany {
      userId
      viewerId
      name
      description
      _id
      createdAt
      updatedAt
      schedule {
        mon {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        tues {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        wed {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        thurs {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        fri {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        sat {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
        sun {
          before_breakfast
          after_breakfast
          before_lunch
          after_lunch
          before_dinner
          after_dinner
          bedtime
          overnight
        }
      }
    }
  }
`