const types = {
    'INIT':{
        text:'Initial Visit',
        borderColor:'#36c5cc',
        backgroundColor:'rgba(196, 252, 255, 0.24)'
    },
    "FOLLOW_UP2":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP1":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP3":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    "FOLLOW_UP":{
        text:'Initial / Follow Up Visit',
        borderColor:'#4278c3',
        backgroundColor:'rgba(181, 214, 255, 0.24)'
    },
    'ANNUAL':{
        text:'Annual',
        borderColor:'#fdb543',
        backgroundColor:'rgb(255, 225, 178,0.24)'

    },
    'ADDITIONAL':{
        text:'Additional Visit',
        borderColor:'#ee7c81',
        backgroundColor:'rgba(255, 204, 204, 0.24)'
    },
    'TECH':{
        text:'Tech Visit',
        borderColor:'#FFB310',
        backgroundColor:'rgba(255, 179, 16, 0.24)'
    },
    'CLINIC_FOLLOW_UP': {
        text:'General Visit',
        borderColor:'#6236FF',
        backgroundColor:'rgba(98, 54, 255, 0.24)'
    },
    'MNT': {
        text: 'MNT',
        borderColor:'#83C858',
        backgroundColor:'rgb(131, 200, 88,0.1)'
    },
    'TECH_ONBOARDING': {
        text: 'Tech Onboarding',
        borderColor:'#CF51AB',
        backgroundColor:'rgb(207, 81, 171,0.1)'
    },
    'CROSS_CLINIC': {
        text: '',
        borderColor:'none',
        backgroundColor:'rgb(133, 133, 133, 0.2)'
    }
}

export default types;
