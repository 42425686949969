import React from 'react';
import { Table } from 'antd';
import BgMap from '../constants/BGMap';


const ResultStatisticsComponent = class extends React.Component {
    renderLowBSRisk(){
        const { stat = { } } = this.props;
        const source = [];
        Object.entries(stat).forEach(e =>
            {   
                if(e[0] === '__typename') {
                    return
                }
                let type = BgMap[e[0]];
                let value = e[1];
                let count = _.get(value, 'count', 'N/A');
                let average = _.get(value, 'average.[0]', 'N/A');
                let rangeArr = _.get(value, 'range', undefined);
                let range = (rangeArr.length === 0 || rangeArr === undefined) ? 'N/A' : rangeArr[0] + '-' + rangeArr[1];
                let percentage = (type === BgMap.all || _.get(value, 'inRangePercentage') === null) ? 'N/A' : _.get(value, 'inRangePercentage') + '%';
                source.push({
                    type,
                    count,
                    average,
                    range,
                    percentage
                })
            }
        );

        const columns = [
            {
                title:'Type',
                key:'type',
                align:'center',
                dataIndex: 'type',
                width: '20%'
            },
            {
                title:'#',
                key:'count',
                align:'center',
                dataIndex:'count',
                width: '10%'
            },
            {
                title:'Avg\n(mg/dL)',
                key:'average',
                align:'center',
                dataIndex:'average'
            },
            {
                title:'Range\n(mg/dL)',
                key:'range',
                align:'center',
                dataIndex:'range',
                width: '20%'
            },
            {
                title:'Vitals in Normal Range',
                key:'percentage',
                align:'center',
                dataIndex:'percentage'
            },
        ];

        return <Table 
                    dataSource={source}
                    rowKey={(record,index) => index}
                    columns={columns}
                    pagination={false}
                    bordered={false}
                    title={ ()=> <span>Blood Glucose Summary</span> }
                    rowClassName={(record) => {
                      let defaultCls = 'statisticsTableRow';
                      if(_.get(record, 'type') === BgMap.all)
                        defaultCls = defaultCls.concat(' bgAllStat ');
                      return defaultCls;
                    }}
                    className='statisticsTable'
                />
    }

    render(){
        const { loading } = this.props;
        if(loading){
            return <div>Loading</div>
        }
        return<div>
                { this.renderLowBSRisk() }
            </div>
    }
}


export default  ResultStatisticsComponent;
