import React, { Component } from 'react';
import { Divider, Modal, Rate, Icon } from 'antd';
import FoodlogImage from './FoodlogImage';
import MessageTextComponent from '../FileUpload/MessageTextComponent';
import { compose, graphql } from "react-apollo/index";
import getFoodLog from 'graphqlModule/getFoodLog';
import AddFoodLog from 'modulesAll/foodLog/components/AddFoodLogNotes';
import actions from "../../../foodLog/actions";
import { connect } from "react-redux";
import moment from 'moment';
const FoodLogView = class extends Component {
    constructor() {
        super();
        this.state = {
            showAddFoodLogModal: false,
            foodLog: null
        }
    }
    renderStar = (startCount, isInChat) => {
        if (!isNaN(startCount) && startCount != 0) {

            return <div>
                <label >Rating: </label>
                <Rate className='tableStar'
                    value={startCount}
                    disabled
                    className='foodDiaryLogBook'
                    style={{ fontSize: isInChat ? 15 : 20, marginLeft: 5 }}>
                </Rate>
            </div>
        }
    }

    handleShowAddFoodLogModal = (flag) => {
        this.setState({
            showAddFoodLogModal: flag
        })
    }

    renderFoodLogModal = () => {
        const { showAddFoodLogModal, foodLog } = this.state;
        const userId = _.get(foodLog, 'member.id');
        const { props } = this;
        const hidePatientName = false;

        if (showAddFoodLogModal) {
            const year = moment(foodLog.createdAt).year();
            const month=moment(foodLog.createdAt).month();
            const keepEventListener  = true;
            return <Modal visible={true} onCancel={() => this.setState({ showAddFoodLogModal: false, foodLog: null })}
                width={1000} footer={null} maskClosable={false} closable={false}
            >
                <AddFoodLog userId={userId} row={foodLog} reset={this.props.reset}
                    setRating={this.props.setRating}
                    updateComment={this.props.updateComment}
                    renderStar={this.renderStar}
                    hidePatientName={hidePatientName}
                    keepEventListener={keepEventListener}
                    handleShowAddFoodLogModal={this.handleShowAddFoodLogModal}
                    year={year}
                    value={month}
                    {...props}
                />
            </Modal>
        }

    }

    setFoodLog = (foodLog) => {
      if(!foodLog)
        return console.warn('warn setFoodLog: foodLog is not valid');

        this.setState({
            foodLog
        }, () => {
            this.handleShowAddFoodLogModal(true)
        })
    }

    render() {
        const { loading, text, foodLog } = this.props;

        const dividerStyle = {
            width: '3px',
            height: 'inherit'
        };
        const container = {
            padding: '10px 10px',
            backgroundColor: 'white',
            border: 'solid 1px rgb(80,176,231)',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column'
        };

        return (
            <div style={container}>
                {this.renderFoodLogModal()}
                { loading ?
                  <Icon type='loading' size={10} />
                  : <FoodlogImage foodLog={foodLog} setFoodLog={this.setFoodLog} />
                }
                <Divider type="vertical" style={dividerStyle} />
                <MessageTextComponent text={text} />
            </div>
        );
    }
}
const withData = graphql(getFoodLog, {
    options: (ownProps) => {
        const variables = {
            id: ownProps.foodLogId
        }
        return {
            variables,
            fetchPolicy: 'network-only',
        }
    },
    props: ({ data: { getFoodLog, loading } }) => {
        const foodLog = getFoodLog;
        if (loading) {
            return { loading };
        }
        return {
            foodLog
        }
    }
})
const mapToDispatch = (dispatch) => {
    return {
        setRating: (id, rating) => dispatch(actions.setRating(id, rating)),
        updateComment: (id, comments) => dispatch(actions.updateComment(id, comments)),
        reset: (id) => dispatch(actions.reset(id)),
    }
}

export default compose(connect(null, mapToDispatch), withData)(FoodLogView);
