// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers';
import { actionTypes as VitalListTypes } from './VitalListDuck';
// Reducer

/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Careplan', 'Common', [
  'CHANGE_PAGE',
  'CAREPLAN_MAIN_PAGE',
  'CAREPLAN_VITAL_FORM_PAGE',
  'ASSESSMENT_FORM_PAGE',
  'SURVEY_FORM_PAGE',
  'EDUCATION_FORM_PAGE',
  'SET_ASSESSMENT_HAS_EMPTY_SCHEDULE',
  'SET_SURVEY_HAS_EMPTY_SCHEDULE'
]);

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'changePage'
]);

actionCreators.initVitalList = (selectedProgram)=>{
  return {
    type : VitalListTypes.INIT_DATA,
    selectedProgram
  };
};

actionCreators.setAssessmentHasEmptySchedule = (hasEmptySchedule) => {
  return {
    type : actionTypes.SET_ASSESSMENT_HAS_EMPTY_SCHEDULE,
    hasEmptySchedule,
  };
};

actionCreators.setSurveyHasEmptySchedule = (hasEmptySchedule) => {
  return {
    type : actionTypes.SET_SURVEY_HAS_EMPTY_SCHEDULE,
    hasEmptySchedule,
  };
};

export { actionCreators };

// **************************************************
//   Reducer
// **************************************************
const initState = {
  currentPage: actionTypes.CAREPLAN_MAIN_PAGE,
  assessmentHasEmptySchedule: false,
  surveyHasEmptySchedule: false
};

export default (state = initState, action) => {
  switch(action.type) {
    case actionTypes.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.currentPage ? action.currentPage : initState.currentPage
      };
    case actionTypes.SET_ASSESSMENT_HAS_EMPTY_SCHEDULE:
      return {
        ...state,
        assessmentHasEmptySchedule: action.hasEmptySchedule
      };
    case actionTypes.SET_SURVEY_HAS_EMPTY_SCHEDULE:
      return {
        ...state,
        surveyHasEmptySchedule: action.hasEmptySchedule
      };
    default:
      return state;
  };
};
