import React from 'react';
import { Table, Icon } from 'antd';
import moment from "moment/moment";
import $ from 'jquery';
const { Column } = Table;
import { setBPReadingColor } from 'libModule/utils/common';
import _, {set} from 'lodash';

// const { Button,Group } = Radio;
const readingSeverityMap ={
    "RISK":'riskReading',
    "NORMAL":'normalReading',
    "CRITICAL":'criticalReading'
}

const style = {
    imageStyle:{
        width: 20,
        display: 'flex',
        alignSelf: 'flex-start',
        marginRight: '-20px',
        marginTop: 5
    }
}
const map = [{
    key:'DAWN',
    startTime:0,
    endTime: 4*3.6e6
},
    {
        key:'MORNING',
        startTime:4,
        endTime: 12*3.6e6
    },
    {
        key:'NOON',
        startTime: 12,
        endTime: 18*3.6e6
    },
    {
        key:'NIGHT',
        startTime: 18,
        endTime: 24*3.6e6
    }];
const BPLogBookMiniComponent = class extends React.Component {
    constructor(){
        super();
    }
    generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }
        return timeSpots.reverse();
    }

    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in</span>
            <span style={{fontWeight:900}}>&nbsp;mmHg</span>
        </Icon>
    }

    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const filterByMealType = (resList=[],mealType)=> _.filter(resList,res=> this.parseData(res) == mealType );
        const parsedData =  _.groupBy(data,(d)=>{
            return moment(d.date).format('MM/DD/YYYY')
        })

        let groupedData = _.map(timeSpots,(key)=>{
            let currentArray = parsedData[key];
            if(currentArray == undefined) {
                return null;
            }
            let tmp = { };
            for (const [ _,val ] of Object.entries(map)) {
                const { key } = val;
                let arr = filterByMealType(currentArray,key);
                let maxLen = tmp['maxLen'] || 0;
                set(tmp,`${key}`,arr);
                set(tmp,'maxLen',Math.max(arr.length,maxLen));
                if(arr[0]&&!tmp['date']) {
                    set(tmp,'date',arr[0].date);
                }
            }
            return {
                [key]:tmp
            }
        }).filter(v => !!v)

        return groupedData;
    }

    parseData = (data) =>{

        const { date } = data;
        const dateObj = new Date(date);
        const startHour = dateObj.getHours();
        const endHour = dateObj.getHours()*3.6e6 + dateObj.getMinutes()*6e4 + dateObj.getSeconds()*0.1e4 + dateObj.getMilliseconds();
        let type = '';
        _.forEach(map, (o) => {
            if(startHour>=o.startTime&&endHour<o.endTime&&!(startHour==0&&endHour==0)){
                type = o.key;
            }
        })
        return type;
    }

    handleData(filteredList){
        const allMissed = filteredList.length > 0 && !_.find(filteredList, (i) => (i.severity!='MISSED'));
        let readingCount = !allMissed ? _.reject(filteredList, (i)=>i.severity=='MISSED').length : '';
        const readingObj = filteredList.length > 0 && !allMissed ? _.find(filteredList, (i) => (i.severity && i.severity!='MISSED')) || {} : {};
        const readingDate = readingObj.date;
        const hasUserNote = (_.filter(filteredList,(i)=>_.get(i,'measure.user_notes'))).length != 0;
        const hasArrhythmia = (_.filter(filteredList,i=>i.arrhythmia).length)!=0;
        const systolic_bpValue = _.get(readingObj, 'systolic_blood_pressure', '') ? readingObj.systolic_blood_pressure.value: '';
        const diastolic_bpValue = _.get(readingObj,'diastolic_blood_pressure', '')? readingObj.diastolic_blood_pressure.value : '';
        const heartRateValue = _.get(readingObj, 'heart_rate.value', '') ? readingObj.heart_rate.value :'';
        let className = allMissed ? 'missedReading' : (readingCount > 1 ? 'logbookCellWithBadge BP': readingCount > 0 ? 'logbookCell' : '') + " " +
        (setBPReadingColor(systolic_bpValue, diastolic_bpValue) || '');
        const readingValue =   allMissed ? 'Missed' : !_.isEmpty(readingObj) ? {
                               'systolic_blood_pressure_value' : systolic_bpValue,
                               'diastolic_blood_pressure_value': diastolic_bpValue,
                               'heart_rate_value': heartRateValue,
                                hasArrhythmia
        } : '';

        const valueString = allMissed ? '':( _.get(readingValue,'systolic_blood_pressure_value','')+''+_.get(readingValue,'diastolic_blood_pressure_value','')+''+_.get(readingValue,'heart_rate_value',''));
        const valueLenClass = valueString.length==6 ? 'sixDigit' : valueString.length==7 ? 'sevenDigit' :valueString.length==8 ? 'eightDigit': valueString.length==9 ? 'nineDigit':'' ;
        const unit =  readingValue ? 'bpm':'';
        readingCount = readingCount > 1 ? readingCount : null;
        const hasUserNoteClassName = hasUserNote  ? ` hasUserNote ${valueLenClass}`:'';
        className =className.concat(hasUserNoteClassName);

        return {
            readingCount,
            unit,
            readingValue,
            className,
            readingDate,
            allMissed,
            hasUserNote,
            hasArrhythmia
        }
    }

    renderReadingInCell(readingValue){
        const { hasArrhythmia } = readingValue;
        if(typeof readingValue === 'string'){
            return <span>{readingValue}</span>;
        }
        return <div>
                <div>
                    <span>{readingValue.systolic_blood_pressure_value}/{readingValue.diastolic_blood_pressure_value}</span>
                    <span style={{ marginLeft:10 }}>{`${readingValue.heart_rate_value} bpm`}</span>
                    { hasArrhythmia && <img src={'/image/arrhythmia-hr-icon-white.svg'} style={style.imageStyle}/>}
                </div>
              </div>
    }

    renderTable(){
        const dataToRender = this.groupData();
        const result = [];
        for(const arrayItem of dataToRender) {
            let arrayData = Object.values(arrayItem)[0];
            let { maxLen,date } = arrayData;
            for (let i=0;i<maxLen;i++) {
                let data = [];
                for (const [ key,array ] of Object.entries(arrayData)) {
                    if(_.isArray(array)&&array[i]) {
                        data.push(array[i])
                    }
                }
                result.push({ data:_.groupBy(data, (d) => this.parseData(d)),date });
            }
        }
        return  <div className='IH-SearchTable'>
            <Table dataSource={result} rowKey={(data, index) => data.date + index}
                   className='logMiniBookTable' bordered
                   style={{ marginBottom:0, fontSize:10}}
                   pagination={false}
            >
                <Column
                    title={<div style={{fontSize:12}}>Date</div>}
                    dataIndex="date"
                    render={(data)=><div style={{fontSize:12, textAlign:'center'}}>{moment(data).format('MM/DD/YYYY')}</div>}
                    width={'10%'}
                />
                <Column
                    title={<div style={{fontSize:12}}>Overnight<p>(24:00 - 4:00)</p></div>}
                    dataIndex="data.DAWN"
                    key='DAWN'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount, className, readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount
                            },
                            children: 
                            <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div style={{fontSize:12}}>Morning<p>(4:00 - 12:00)</p></div>}
                    dataIndex="data.MORNING"
                    key='MORNING'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount, className, readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount
                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div style={{fontSize:12}}>Afternoon<p>(12:00 - 18:00)</p></div>}
                    dataIndex="data.NOON"
                    key='NOON'
                    className='logBookIcon'
                    width='11%'
                    render={(data, index) => {
                        const { readingCount,className, readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount
                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
                <Column
                    title={<div style={{fontSize:12}}>Evening<p>(18:00 - 24:00)</p></div>}
                    dataIndex="data.NIGHT"
                    key='NIGHT'
                    width='11%'
                    className='logBookIcon'
                    render={(data, index) => {
                        const { readingCount, className, readingValue } = this.handleData(data||[]);
                        return {
                            props: {
                                className:'number',
                                data:readingCount
                            },
                            children: <div data={readingCount} className={className}>
                                {this.renderReadingInCell(readingValue)}
                            </div>
                        }
                    }
                    }
                />
            </Table>
        </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }
}

export default BPLogBookMiniComponent;