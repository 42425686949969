import gql from 'graphql-tag'
export default gql`query getpatientConsent($memberId:EID!){
  getPatientConsent(memberId:$memberId){
    id
    consent
    consentType
    content
    fileUrl
    signatureAt
  }
}`