import React ,{ useState } from 'react';
import {Table, Tag,Tooltip} from 'antd';
import constant from "../constant";
import moment from "moment";
import I18N from 'modulesAll/I18N';
import '../style/index.scss';
import helper from "../helper/index";
import { goPath } from 'libModule/utils'
import {browserHistory} from "react-router";

const { alertLevelColorMap,alertLevelExceptFive,alertStatus } = constant;
const alertTaskMap = I18N.get('smartAlert.alertType');
// const map = {
//     LEVEL_ONE_BP_HTN_CRITICAL_HIGH:'Pt is symptomatic per Triage, AND SBP≥180 or DBP≥120',
//     LEVEL_ONE_BP_HTN_CRITICAL_LOW:'Pt is symptomatic per Triage, AND - DBP<50, OR - SBP < 90, OR - 50<DBP<60, AND given the DBP today is more than 5+ mmHg below last 7-day’s average',
//     LEVEL_TWO_BP_HTN_CRITICAL_HIGH:'BP reading is SBP≥180 or DBP≥120 and patient has no symptoms per triage, AND,7-day average is SBP≥180 or DBP≥120, AND,No HTN Critical High-Asymptomatic alert was triggered in the last 7 days',
//     LEVEL_TWO_BP_HTN_CRITICAL_LOW:"Patient has no symptoms per triag AND,DBP<50, OR ,SBP < 90, OR,50<DBP<60, AND given the DBP today is more than 5+ mmHg below last 7-day’s average,AND,Patient rechecked BP in 10 minutes and second BP is still critical low (meeting logics above for critical low), OR patient doesn’t check the second reading,AND,No HTN Critical Low-Asymptomatic alert was triggered in the last 14 days",
//     LEVEL_THREE_BP_HTN_STAGE_2_HIGH:"SBP≥150 AND DBP≥90, AND,7-day average (including this reading) is also in SBP ≥150 AND DBP≥90 range，AND,No “HTN Stage 2 High” or “HTN Stage 2 Average” has been triggered in the past 7 days",
//     LEVEL_TWO_BG_FASTING_HIGH:"FBG today is >180 mg/dL, AND,7-day average (including this reading) is in FBG>180 mg/dL range, AND,No “Fasting Glucose High” alert generated in the past 7 days",
//     LEVEL_THREE_BP_HTN_STAGE_2_AVERAGE:"Patient has a SBP≥140 OR DBP≥90 reading, AND,30-day average (including this reading) is in the SBP≥140 OR DBP≥90 range, AND,No “HTN Stage 2 Average” alert was generated or closed in the past 30 days, AND,No “HTN Stage 2 High” alert was generated or closed in the past 7 days"
// }
const ProviderSmartAlertComponent = class extends React.Component{

    getColumns = ()=>{
        const alertLevelOpts = _.map(alertLevelExceptFive, a => ({
            value: a,
            text: alertTaskMap[a]
        }))
        const { status,setStatus } = this.props;
        const alertStatusOpts = _.map(alertStatus,a=>({
            value: a,
            text: a
        }))
        const clickableText = { textDecoration:'underline',cursor:'pointer'  };
        const backgroundBlack = { backgroundColor: 'black !important '};
        const columns = [
            {
                title: 'Patient Name',
                width:'15%',
                key: 'name',
                render:(dummy,{firstName,lastName})=>{
                    const { memberId } = dummy;
                    const onClick = ()=>browserHistory.push(`/provideralert/${memberId}/`);
                    const fullName = `${firstName} ${lastName}`;
                    return <a className='patientName v-name' onClick={onClick}>{fullName}</a>;
                }
            },
            {
                title: 'Alert',
                width:'15%',
                dataIndex: 'alert',
                render:(dummy,{tasks})=>{
                    return _.map(tasks,t=>helper.renderSmartAlert(t));
                },
                filters: alertLevelOpts,
                onFilter: (value,record)=>{
                    return _.get(record,'tasks',[]).filter(a=>a.alertLevel === value).length!=0;
                }
            },
            {
                title:'Alert Reason',
                dataIndex: 'reason',
                render:(dummy,{tasks})=>_.map(tasks,(t,i)=> <div key={i} style={{ margin:'15px 0px',height:28,width:'fit-content',display:'flex','alignItems':'center'}}>
                            <Tooltip overlayClassName={'providerAlertReasonTooltip'} trigger={'click'} style={backgroundBlack} title={helper.renderAlertReason(t)}>
                                <p style={clickableText}>Review</p>
                            </Tooltip></div>)
            },
            {
                title:'Triggered On',
                width:'15%',
                dataIndex: 'time',
                sorter:(a,b)=>{
                  return _.first(_.get(a,'tasks')).createdAt - _.first(_.get(b,'tasks')).createdAt
                },
                render:(dummy,{tasks})=>{
                    return _.map(tasks,(t,i)=><p key={i} style={{ margin:'15px 0px',height:28,display:'flex','alignItems':'center' }}>{moment(t.createdAt).format('hh:mm a, MM/DD/YYYY')}</p>);
                }
            },
            {
                title:'Resolved On',
                width:'15%',
                dataIndex: 'closeAt',
                sorter:(a,b)=>{
                    return _.first(_.get(a,'tasks')).closeAt - _.first(_.get(b,'tasks')).closeAt
                },
                render:(dummy,{tasks})=>{
                    return _.map(tasks,(t,i)=><p key={i} style={{ margin:'15px 0px',height:28,display:'flex','alignItems':'center'}}>{t.closeAt && moment(t.closeAt).format('hh:mm a, MM/DD/YYYY')}</p>);
                }
            },
            {
                title:'Resolved Note',
                width:'15%',
                dataIndex: 'providerNotes',
                render:(dummy,{tasks})=>{
                    return _.map(tasks,(t,i)=>{
                        let { providerNotes } = t;
                        return <div key={i} style={{ margin:'15px 0px',width:'fit-content',height:28,display:'flex','alignItems':'center'}}>
                            {  providerNotes.length ?
                                <Tooltip overlayClassName={'providerAlertReasonTooltip'} style={backgroundBlack}
                                         trigger={'click'} title={providerNotes.map(p => p.content).join('/n')}>
                                    <p style={clickableText}>Review</p>
                                </Tooltip> : '--'
                            }
                        </div>
                    });
                }
            },
            {
                title:'Status',
                width: '15%',
                dataIndex: 'status',
                render:(dummy,{tasks})=>{
                    return _.map(tasks,(t,i)=><p key={i} style={{ margin:'15px 0px',height:28,display:'flex','alignItems':'center'}}>{t.status}</p>);
                },
                filteredValue:status ? [status] :[],
                filters: alertStatusOpts,
                filterMultiple:false,
                onFilter: (value,record)=>{
                    return status && _.get(record,'tasks',[]).filter(a=>status === a.status).length!=0;
                }
            }]
        return columns;
    }
    sortSmartAlertTasksList = (smartAlertTasksList)=>{
        return smartAlertTasksList;
    }
    renderList = ()=>{
        const { sortSmartAlertTasksList } = this;
        const { loadingAlert,smartAlertTasksList,setStatus } = this.props;
        const columns = this.getColumns();
        return <Table loading={loadingAlert} columns={columns}
                      pagination={{ pageSize:15 }}
                      onChange = {(p,f,s)=>{
                          if(f.status) {
                              setStatus(f.status[0]);
                          }
                      }}
                      dataSource={sortSmartAlertTasksList(smartAlertTasksList)}/>
    }

    render() {
        return this.renderList();
    }
}
export default ProviderSmartAlertComponent;
