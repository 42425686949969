import React from "react";
import {compose} from "react-apollo/index";
import { Form,Select,Button,Switch,Icon,Tooltip } from 'antd';
import '../style/index.scss';
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import { message } from 'ihcomponent';
import { language,languageConfig } from '../constant/';

import SearchClearComponent from './SearchClearComponent';

const Item = Form.Item;

let AddNewDevice =  class extends React.Component {
    constructor(){
        super();
    }

    renderAutoComplete() {
        const { disabled }  = this.props;
        return (
            <SearchClearComponent 
              disabled={disabled}
            />
        )
    }

    getLanguageOptions() {
        const { disabled }  = this.props;
        return <Select
                style={{ width: 200 }}
                onChange={()=>this.setVolume()}
                disabled={disabled}
               >
                {
                  language.map(({key,value}) => {
                    return <Select.Option key={key} value={key}>{value}</Select.Option>
                  })
                }
               </Select>

    }

    onSubmit = (e)=>{
        e.preventDefault();
        const { form,updateIoTDeviceMemberAPI,editIoTDeviceConfigAPI,openErrorModal,setInputFieldDisabled, setAssignment } = this.props;
        const memberId = _.get(this.props, 'data.user.id') || _.get(this.props, 'userId') || _.get(this.props, 'patientId');
        const { validateFields } = form;

        validateFields(['deviceId', 'language', 'volume'], (errors, { deviceId, language, volume }) => {
            if(!errors){
                let variables = {
                    deviceId,
                    memberId,
                };
                updateIoTDeviceMemberAPI(variables,memberId)
                    .then(res=>
                        editIoTDeviceConfigAPI({ deviceId,language,volume: volume ? 50 : 0 },memberId)
                            .then(res=>{
                                message.success('Device Assigned');
                                if(setInputFieldDisabled) setInputFieldDisabled('clearInfo');
                                if(setAssignment) setAssignment(true, { deviceId });
                            })
                            .catch(e=>{
                                console.log(e);
                                openErrorModal(GQLHelper.formatError(e))
                            })
                            
                    )
                    .catch(e=>{
                        console.log(e);
                        openErrorModal(GQLHelper.formatError(e))
                    });

            }
        })
    }

    setVolume(){
        const { form } = this.props;
        const { resetFields } = form;
        resetFields(['volume'])
    }

    renderForm=()=>{
        const { form } = this.props;
        const { getFieldDecorator,getFieldValue } = form;
        const selectedLanguage = getFieldValue('language');
        const { disabled }  = this.props;

        return <Form className='addDeviceForm' layout={'vertical'}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Item label="Device ID" required={true}>
                          {getFieldDecorator('deviceId',{rules:[{
                                  required:true, message: 'Please select a device Id'
                              }]})(this.renderAutoComplete())}
                      </Item>
                    </div>
                    <Item label="Device Language" >
                        {getFieldDecorator('language',{ initialValue:'en'})(this.getLanguageOptions())}
                    </Item>
                    <Item label={
                                    <div>
                                    Text-to-Speech
                                    <Tooltip title={`Enable speech output to read out blood pressure results to the patient. Currently only available for English and French.`} >
                                        <Icon type='info-circle' style={{ marginLeft: 10 }}/>
                                    </Tooltip>
                                    </div>
                                 }
                    >
                        {getFieldDecorator('volume', { initialValue:false,valuePropName: 'checked' })(
                                <Switch disabled={disabled||(!_.get(languageConfig,`${selectedLanguage}.volume`,false))} />
                            )}
                    </Item>
                    <Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit" onClick={(e)=>this.onSubmit(e)} disabled={disabled}>
                            Assign Device
                        </Button>
                    </Item>
               </Form>
    }

    render(){
        return this.renderForm();
    }
}

AddNewDevice = Form.create({onFieldsChange(props){
        if(props.handleOnFocus) {
            props.handleOnFocus('clearInfo')
        }
    }})(AddNewDevice);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
export  default compose(connect(null,mapToDispatch))(AddNewDevice);
