const inviteTableConfig = [
    {
        role:'Nurse'
    },
    {
        role:'Doctor'
    }
];

export default {
    inviteTableConfig
}

