import React, { useEffect, useState } from 'react';
import { Icon, Button, Spin, Modal } from 'antd';
import moment from 'moment';
import { getConditionsValue, getInitialVisit, getProgramsValue, getStatuses, getVitalsValue } from '../helper/initialValueHelper';
import { getReferredPrograms } from '../helper';
import { upsertPatientReferralMutation } from '../API/mutation';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { STATUS_ENUM, VITAL_TYPE_MAP } from '../constant';
import I18N from '../../I18N';
import SchedulePhoneCallComponent from './SchedulePhoneCallComponent';

const ReferredSuccess = ({ user, closeModal, userRole, patientReferral, showContinueButton, onSubmitDone, openErrorModal,shouldUpdateCompletedReferStatus,refetchQuery }) => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSchedulePhoneVisit, setShowSchedulePhoneVisit] = useState(false);
  const [phoneVisit, setPhoneVisit] = useState(getInitialVisit(patientReferral));

  const onVisitSchedule = async (id, dateTime, note) => {
    try {
      setPhoneVisit({ id, dateTime, note });
      await upsertPatientReferralMutation({ patientId: user.id, initialVisitId: id });
    } catch (error) {
      console.log(error);
      setShowError(error.message);
    }
  }

  const update = async () => {
    const { id: memberId } = user;
    // combining the icd codes in user with the new onces
    const isDoctorRoles = ['MD','MA'];
    setLoading(true);
    try {
      if (!_.get(patientReferral, 'status.referStatus')) {
        // update remote enrollment
        await remoteEnrollmentApis.createRE({ memberId, doctorReferral: isDoctorRoles.includes(userRole)});
        await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'REFERRED' });
      }
      if(shouldUpdateCompletedReferStatus) {
        await upsertPatientReferralMutation({ patientId: memberId, status: { ...getStatuses(), referStatus: STATUS_ENUM.COMPLETED }});
      }
    } catch (error) {
      console.log(error);
      setShowError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => { 
    if (_.get(patientReferral, 'status.enrollStatus') !== STATUS_ENUM.COMPLETED) {
      update(); 
    }
  }, []);

  const onDone = () => {
    if (showContinueButton) {
      onSubmitDone();
    } else {
      closeModal();
    }
    if(refetchQuery) refetchQuery();
  }

  // const programs = getProgramsValue(patientReferral) || [];
  const programs = getReferredPrograms(patientReferral);
  const vitals = (getVitalsValue(patientReferral) || []).map(v => VITAL_TYPE_MAP[v]);
  const conditions = getConditionsValue(patientReferral,user) || [];
  const hasMnt = programs.includes('MNT');
  const wantMNTReferral = _.get(patientReferral,'wantMNTReferral');
  const patientDescribe = _.get(patientReferral,'patientDescribe');
  const hideConditionsAndMsg = (hasMnt && !wantMNTReferral) || !hasMnt;
  if (loading) {
    return <Spin />;
  }
  if (showError) {
    return (
      <div className="flex-center fit-parent">
        <div>
          <div style={{ marginBottom: 20 }}>Something went wrong submitting!</div>
          <div><Button type="primary" className="new-style" onClick={update}>Try again</Button></div>
          </div>
      </div>
    );
  }

  const renderScheduledVisit = () => {
    const style = { marginBottom: 10 };
    const { dateTime, note } = phoneVisit;
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={style}>{I18N.get('referAndEnroll.phoneCallScheduledText')}</div>
        <div style={style}>
          <span style={{ marginRight: 10, fontWeight: 'bold' }}>{dateTime && moment(dateTime).format('MM/DD/YYYY, HH:mm')}</span>
          <a onClick={() => setShowSchedulePhoneVisit(true)}>Edit</a>
        </div>
        <div style={{ fontWeight: 'bold', ...style }}>{note}</div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%' }}>
      <div>
        <img src="/image/smile_face.png" width="100px" />
        <div style={{ margin: 20 }}>
          <div style={{ fontWeight: 'bold' }}>Great! You've successfully referred {_.get(user, 'profile.fullName')} to Unified Care.</div>
          <div>Program: [{programs.join(', ')}]</div>
          <div>Vitals: [{vitals.join(', ')}]</div>
          <div>{ !hideConditionsAndMsg && `Conditions: [${conditions.join(', ')}] `} </div>
        </div>
        { !hideConditionsAndMsg && <div style={{ width: 500, padding: '15px 25px', backgroundColor: '#F4F6F8', marginBottom: 20 }}>
          {
            hasMnt && wantMNTReferral && patientDescribe
            ? 'You may also want to keep a record of this referral in your EHR, as needed. '
            : <React.Fragment>
                <Icon type="info-circle" theme="filled" className="red-svg" />&nbsp;
                Please make sure to complete the MNT referral in your EMR, send us the patient’s medical history, current medications list and labs. Thanks!
              </React.Fragment>
          }
        </div> }
        <div style={{ marginBottom: 20 }}><Button type="primary" className="new-style" onClick={onDone}>{showContinueButton ? 'Continue' : 'Close'}</Button></div>
        {phoneVisit
          ? renderScheduledVisit()
          : <a onClick={() => setShowSchedulePhoneVisit(true)}>{I18N.get('referAndEnroll.phoneCallScheduleButton')}</a>
        }
        <Modal visible={showSchedulePhoneVisit} onCancel={() => setShowSchedulePhoneVisit(false)} footer={null}>
          <div style={{ padding: 20 }}>
            <SchedulePhoneCallComponent
              visitId={_.get(phoneVisit, 'id')}
              user={user}
              closeModal={() => setShowSchedulePhoneVisit(false)}
              openErrorModal={openErrorModal}
              userRole={userRole}
              onVisitSchedule={onVisitSchedule}
              dateTime={_.get(phoneVisit, 'dateTime')}
              note={_.get(phoneVisit, 'note')}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ReferredSuccess;
