import React from 'react';
import _ from 'lodash';
import { Modal, Button, message, Input } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { goPath, GQLHelper } from 'libModule/utils';
import Client from 'libModule/gqlClient';
import deleteUser from 'modulesAll/graphql/deleteUser';

const DeletePatientButton = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showDeleteUserModal: false,
    };
  }

  handleCancel = () => {
    this.setState({
      reason: '',
      showDeleteUserModal: false,
    });
  }

  handleDeleteUser = () => {
    const { onInteraction } = this.props;
    const { reason } = this.state;
    const { id } = this.props.user;

    Client.mutate({
        mutation: deleteUser,
        variables: {
          id,
          reason: {
            key: "MANUALINPUT",
            value: reason,
          },
        },
    }).then(()=>{
      if (onInteraction) {
        onInteraction('DELETE');
      }
      goPath('/patients');
      message.success('Patient deleted successfully.');
    })
    .catch(e => {
      message.error(GQLHelper.formatError(e));
    });
  }

  renderDeleteUserModal = () => {
    const { user } = this.props;
    const name =  "Delete Patient: " + user.profile.fullName;

    return (
      <Modal
        title={name}
        visible={this.state.showDeleteUserModal}
        onOk={this.handleDeleteUser}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleDeleteUser} disabled={this.state.reason.trim() == '' ? true : false}>
            Submit
          </Button>,
        ]}
      >
        <Input.TextArea
          rows={6}
          value={this.state.reason || ''}
          onChange={e => { this.setState({ reason: e.target.value }); }}
          placeholder="Please state the reason of why you want to delete this patient..."
        />
      </Modal>
    );
  }

  showConfirmForDeleteUser = () => {
    return new Promise(() => {
        this.setState({ showDeleteUserModal: true });
        this.renderDeleteUserModal();
    });
  }

  handleClick = () => {
    const { id } = this.props.user;
    Mixpanel.track('clicked', 'delete', 'patient detail', { PATIENT_ID: id });
    this.showConfirmForDeleteUser();
  }

  render() {
    const { showDeleteUserModal } = this.state;

    return (
      <React.Fragment>
        <Button onClick={this.handleClick} size="large">Delete Patient</Button>
        {showDeleteUserModal && this.renderDeleteUserModal()}
      </React.Fragment>
    );
  }
}

export default DeletePatientButton;