/**
this file is used by /Provider/src/modules/vendor/patient/reducers/index.js (Vendor)
and /Provider/src/modules/patient/enrolledProgramInfo/reducers/EnrolledProgramInfoReducer.js (Nurse)

**/

import {  firstAndLastNameValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields } from 'libModule/utils'

export const schema = {
  replacedBy: {
    initialValue: '',
    required: true,
    disabled: true
  },
  replacedDateAndTime: {
    initialValue: null,
    required: true,
    disabled: true
  },
}

export const initData = getInitDataFromSchema(schema)
export const isFieldsRequired = getFieldsRequiredFromSchema(schema)
export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange, disabledTooOldDate }) => {

  const itemList = [
    [
      {
        key: 'replacedBy',
        label: 'Replaced By',
        type: 'text',
        placeholder: '',
        initialValue: initData['replacedBy'],
        col: { span: 16, offset: 0 },

        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        disabled: isFieldsDisabled.replacedBy,
        rules : [
          {required : isFieldsRequired.replacedBy, message : 'Replace By is required'},
          {max: 100, message: 'Replace By is too long'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'replaceBy'))}
        ],
      },

      {
        key: 'replacedDateAndTime',
        label: 'Replaced Date/Time',
        type: 'date',
        initialValue: initData['replacedDateAndTime'],
        col: { span: 8, offset: 0 },
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        showTime: {format: 'hh:mm a'},
        format:"MMM-DD-YYYY hh:mm a",
        disabledDate: disabledTooOldDate,
        disabled: isFieldsDisabled.replacedDateAndTime,
        rules : [
          {required : isFieldsRequired.replacedDateAndTime, message : 'Replaced Date/Time is required'}
        ],
      },

    ],


  ]

    return {
      fieldsValue,
      onFieldsChange,
      mapPropsToFields,
      itemList
    }

}
