import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import PhotoUploader from './PhotoUploader';
import { patientPhotoUploadValidator } from 'libModule/validator';

const VITALS = ['Blood Pressure', 'Blood Glucose', 'Weight', 'Heart Rate', 'Temperature'];

export default class extends React.Component {
  static displayName = 'patientView/manualInput/components/ManualInputComponent'

  validateAndSubmit(e, { validateFieldsAndScroll }) {
    e.preventDefault();

    validateFieldsAndScroll((err, values) => {
      !err && this.props.submit(values);
    });
  }

  getFormProps() {
    const { vitalForm, vitalType } = this.props;

    return {
      onSubmit: this.validateAndSubmit.bind(this),
      vertical : true,
      itemList : [
        {
          key : 'vitalType',
          label: 'Vital Type',
          type: 'radioButton',
          disabled: true,
          className:'ih-max',
          initialValue: vitalType,
          option: VITALS.map(v => ({ name: v, value: v }))
        },
        {
          key : 'random1',
          label : 'Reading',
          type : 'label',
          className : 'v-lb'
        },

        ...vitalForm,

        {
          key : 'random3',
          type: 'rowGroup',
          rowGroupSpan: 24,
          gutter: 10,
          className: 'number-row',
          items: [
            {
              key: 'image',
              label: '',
              type: 'selfComponent',
              component : PhotoUploader,
              rowGroupSpan: 5,
              rules : [
                {validator: (rule, value, callback) => callback(patientPhotoUploadValidator(value))}
              ],
            },
            {
              key: 'notes',
              label: '',
              className: 'notes-row',
              type: 'textarea',
              rowGroupSpan: 19,
            },
          ],
        },
      ],
    };

  }

  render() {
    return (
      <IHBaseForm {...this.getFormProps()}>
        <div className="buttons-wrap">
          <IHButton bsStyle="primary" type="submit" size="large">Done</IHButton>
          <IHButton bsStyle="default" type="button" size="large"
            style={{marginLeft: '10px'}}
            onClick={this.props.closeModal}>Cancel</IHButton>
        </div>
      </IHBaseForm>
    );
  }
}
