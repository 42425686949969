import React from 'react';
import { Modal } from 'antd';
import {compose, graphql} from "react-apollo";
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { IHLoading } from 'ihcomponent';
import AppointmentCalenderWrapperContainer from '../../visit/containers/AppointmentCalenderWrapperContainer';
import { userWithoutTeam as userGQL } from "../../graphql/user";
import query from '../query';
import { EP_COUNT_MAX } from '../../../lib/constants';
const { getRemoteEnrollment } = query;
import { GQLRetry } from '../../../lib/helpers/gql-helpers';

const CreateAppointmentWithCalendarContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showCalendar: this.props.showModalOnMount || false,
            patient: null,
        };
    }

    componentDidMount() {
        this.setState({ patient: this.getPatientInfo() });
    }

    componentDidUpdate(prevProps) {
        const { loading, user } = this.props;
        if ((prevProps.loading && !loading)) {
            this.setState({ patient: this.getPatientInfo() });
        }
    }

    getPatientInfo = () => {
        const { enrolledProgramList: enrolledPrograms, user } = this.props;
        if (!enrolledPrograms || !user) {
            return null;
        }
        const patient = {
            user,
            enrolledPrograms,
        };
        return patient;
    }

    setPatient = (patient) => this.setState(prev => ({ patient: { ...prev.patient, ...patient } }));

    setShowCalendar = () => {
        this.setState({
            showCalendar: true,
        });
    }

    hideCalendar = () => {
        this.setState({
            showCalendar: false,
        }, () => {
            const { onCancel } = this.props;
            if (onCancel) {
                onCancel();
            }
        });
    }

    renderVisitButtons = () => {
        return (
            <a onClick={this.setShowCalendar} href="javascript:void(0);" style={{ color: 'gray' }}>
                {this.props.children || 'Create Appointment'}
            </a>
        );
    }

    renderShowCalendar = () => {
        const { showCalendar, patient } = this.state;
        const { remoteEnrollment, showPostIt, loading, visitInfo, onCancel, timerName,rescheduledFromId } = this.props;

        return (
            <Modal visible={showCalendar} width='90%' className={'visitCalenderModal'} footer={false} onCancel={this.hideCalendar}>
                {loading || !patient
                    ? <IHLoading />
                    : <AppointmentCalenderWrapperContainer
                        selectedVisit={visitInfo ? visitInfo : patient}
                        isRemoteEnrollment={!remoteEnrollment}
                        onSchedule={this.hideCalendar}
                        skipSearch
                        patient={patient}
                        remoteEnrollment={remoteEnrollment}
                        showPostIt={showPostIt === undefined ? true : showPostIt}
                        onCancel={onCancel}
                        isRemoteEnrollment={false}
                        rescheduledFromId={rescheduledFromId}
                        timerName={timerName}
                        setPatient={this.setPatient}
                    />
                }
            </Modal>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showCalendar && this.renderShowCalendar()}
                {!this.props.showModalOnMount && this.renderVisitButtons()}
            </React.Fragment>
        );
    }
}

const withRemoteEnrollment = graphql(getRemoteEnrollment, {
    options: (props) => {
        const { patientId } = props;
        return {
            variables: {
                memberId: patientId
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy:'network-only'
        }
    },
    props: ({ data }) => {
        const { loading, refetch, getRemoteEnrollment } = new GQLRetry('getRemoteEnrollment').checkResponse(data);
        
        if (loading){
          return { loading };
        }

        return {
            remoteEnrollment: getRemoteEnrollment,
            refetchRemoteEnrollment: refetch
        };
    }
})

const lightEPList = graphql(lightEnrolledProgramList, {
    options: ownProps => ({
        variables: {
            count: EP_COUNT_MAX,
            page: 1,
            filters: {
                memberId: ownProps.patientId,
            }
        },
        notifyOnNetworkStatusChange: true
    }),
    props: ({ data }) => {
        const { enrolledProgramList,loading } = new GQLRetry('lightEnrolledProgramList').checkResponse(data);
        
        if (loading){
          return { loading };
        }

        return {
            enrolledProgramList: _.get(enrolledProgramList,'data')
        };

    }
});

const userData = graphql(userGQL, {
    options: (ownProps) => {
        const id = _.get(ownProps, 'patientId');
        return {
            variables: {
                id
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy:'network-only'
        }
    },
    props: ({ data })=>{
        const { loading, user } = new GQLRetry('userGQL').checkResponse(data);

        if (loading){
          return { loading };
        }

        return {
          user
        }
    }
});

export default compose(withRemoteEnrollment, userData, lightEPList)(CreateAppointmentWithCalendarContainer);
