import React, { useState } from 'react';
import { Row, Col, Button, Input, Avatar, Modal, message, Checkbox } from 'antd';

import proptypes from '../../constants/proptypes';
import resolveTaskAssignment from '../../query/resolveTaskAssignment';
import Client from '../../../../lib/gqlClient';
import {helpers} from '../../../visitNewWorkFlow/helper';
import helper from '../../helper';

const TaskWorkStationInput = props => {
  const { memberId, task, currentUser, refetchTaskAssignments,refetchUnresolvedTasksCount, handleCreateTrackingTime, onInteraction, setShowUnsavedModal } = props;
  const { id: taskId, createdBy, assignedTo } = task;
  const [resolvedNote, setResolvedNote] = useState('');
  const [addToPN, setAddToPN] = useState(true);

  // creator should not be able to resolve their own-created task
  // they can resolve when they are assignee
  const currentAssigneeId = _.get(assignedTo, 'id');
  const canResolveTask = currentUser.id !== _.get(createdBy, 'id') || currentUser.id === currentAssigneeId;

  const handleResolveTask = ()=>{
    Client.mutate({
      mutation: resolveTaskAssignment,
      variables:{ id: taskId, resolvedNote }
    }).then(res=>{
      message.success('Task Resolved');
      onInteraction('RESOLVED');
      refetchTaskAssignments();
      if(refetchUnresolvedTasksCount) refetchUnresolvedTasksCount();
      if (setShowUnsavedModal) setShowUnsavedModal(false);

      handleCreateTrackingTime(res, () => {
        if(addToPN){
          helper.createPN({
            memberId,
            content: resolvedNote
          });
        }
        // else {
        //     this.close();
        // }
        // const {priorityLevel} = this.props.modalData;
        // if(fromTaskList === 'TaskList') {
        //     Mixpanel.track('clicked', 'resolve', 'patient task list popup');
        // } else {
        //     Mixpanel.track('clicked', 'resolve', 'task detail popup', {PRIORITY_LEVEL: priorityLevel});
        // }
      });
    }).catch(e=>{
        Modal.error({ content : e.message });
    });
  };

  return (
    <Row className='input-wrapper'>
      <Row>
        <Col span={4}>
          <Avatar
            icon='user'
            src={_.get(currentUser, 'profile.avatar.link')}
            onError={() => {}}
          />
        </Col>
        <Col span={20} style={{ display: 'flex', flexDirection: 'column' }}>
          <Input.TextArea
            rows={canResolveTask ? 3 : 1}
            placeholder='Please add note here'

            value={resolvedNote}
            onChange={e => {
              setShowUnsavedModal(true);
              setResolvedNote(e.target.value)
            }}
            disabled={!canResolveTask}
          />
          <Checkbox 
            style={{ marginTop: 5 }}
            checked={addToPN}
            onChange={e => {
              const { setShowUnsavedModal } = props;
              setShowUnsavedModal(true);
              setAddToPN(e.target.checked)
            }} 
            disabled={!canResolveTask} 
          >
            Add to Provider Notes
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right', marginTop: 14 }}>
          <Button
            type='primary'
            disabled={!canResolveTask || !resolvedNote}
            onClick={handleResolveTask}
          >
            Resolve task
          </Button>
        </Col>
        {
          !canResolveTask &&
          <Col span={24} style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
            <span style={{ textAlign: 'right', fontStyle: 'italic', fontSize: 13 }}>Creator cannot resolve this task.</span>
            <span style={{ textAlign: 'right', fontStyle: 'italic', fontSize: 13 }}>Please reassign to yourself first.</span>
          </Col>
        }
      </Row>
    </Row>
  );
};

TaskWorkStationInput.propTypes = proptypes.TaskWorkStationInput;

export default TaskWorkStationInput;
