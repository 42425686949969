import gql from 'graphql-tag'

export default gql `
  mutation setDevicesForAdminProgram($id: EID!, $devices: AdminProgramDevicesInput!) {
    setDevicesForAdminProgram(id: $id, devices: $devices) {
      id
      devices {
        type
        model
      }
    }
  }
`
