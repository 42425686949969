import React, {useEffect,useState} from "react";
import { getSubscriptionList } from '../../main/API/index';
import { SubscriptionTagComponent } from '../component/SubscriptionTagComponent';
export const MVPSubscriptionStatusContainer = (props) =>{
	const { memberId,subscriptionStatus } = props;
	const [ subscriptionList, setSubscriptionList ] = useState([]);
	const [ loadingList,setLoadingList ] = useState(false);
	useEffect(()=>{
			if(memberId) {
				setLoadingList(true);
				getSubscriptionList({memberId})
					.then(res=>setSubscriptionList(_.get(res,'data.subscribesList.data',[])))
				setLoadingList(false);
			}
	},[loadingList]);
	if(subscriptionStatus) return <SubscriptionTagComponent subscriptionStatus={subscriptionStatus} subscriptionList={subscriptionList} loadingList = {loadingList} />;
	return '';
};