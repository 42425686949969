// USA Format
export default {
  llll: 'MMMM Do YYYY - HH:mm',
  lll: 'MM/DD/YYYY HH:mma', //  10/01/2017 12:03 pm
  ll: 'ddd', // 'Fri'
  l: 'M/DD',
  LTS: 'HH:mm', // 13:00
  LLL: 'MMMM YYYY',
  LL: 'MMMM Do YYYY',
  L: 'MM/DD/YYYY',
  yesterday: 'Yesterday'
}