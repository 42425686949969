import gql from 'graphql-tag'
import {UserMiniWithEmail} from 'graphqlModule/schema/User'

export default gql `
    mutation changeUserEmail (
      $id: EID!,
      $email: EmailAddress!,
    ) {
      changeUserEmail (
        id: $id,
        email: $email,
      ) {
        ${UserMiniWithEmail}
      }
    }
`
