import React, { useState } from 'react';
import { Button, Icon, Collapse, Checkbox } from 'antd';
import '../style/information.scss';

const greyOutStyles = {
  opacity: 0.5
};

const InformationComponent = (props) => {
  const { noShowKey, children } = props;
  if (_.isEmpty(noShowKey)) {
    console.warn('warn InformationComponent, invalid noShowKey');
    return null;
  }

  const NO_SHOW_KEY = `stop-${noShowKey}`;

  const {
    clickableText = 'Show Information',
    informationTitle = '',
    okText = 'OK',
    noShowText = '',
    containerClass = '',
    iconType = 'question-circle',
  } = props;
  const [noShowNextTime, setNoShowNextTime] = useState(!!localStorage.getItem(NO_SHOW_KEY));
  const [show, setShow] = useState(!noShowNextTime); // default to show if checkbox is not checked

  const handleCollapseClick = () => setShow(!show);

  const handleBtn = () => {
    if(noShowNextTime) {
      localStorage.setItem(NO_SHOW_KEY, true + '');
    } else {
      localStorage.removeItem(NO_SHOW_KEY);
    }
    setShow(false);
  }

  const renderInformationTitle = () => {
    return <div className='titleWrapper'>
      <Icon type={iconType} style={{ color: '#3177C9', marginTop: 3 }} />
      <span className='text'>
        {informationTitle}
      </span>
    </div>;
  }

  const renderCheckbox = () => {
    return noShowText && <Checkbox
      checked={!!noShowNextTime}
      onChange={e => {
        const newNoShowNextTime = !!e.target.checked;
        setNoShowNextTime(newNoShowNextTime);
      }}
    >
      {noShowText}
    </Checkbox>;
  }

  const renderBtn = () => {
    return <div className='btnWrapper'>
      <Button
        type='primary'
        className='okButton'
        onClick={handleBtn}
        ghost
      >
        {okText}
      </Button>
    </div>;
  }

  return <Collapse
    defaultActiveKey={[~~show]}
    activeKey={[~~show]}
    className={`informationCollapse ${containerClass}`}
    expandIconPosition='right'
  >
    <Collapse.Panel
      header={(
        <div onClick={handleCollapseClick} style={show ? greyOutStyles : {}}>
          {clickableText}
        </div>
      )}
      key='1'
      showArrow={false}
    >
      <div className={`informationContentWrapper expandable`}>
        {renderInformationTitle()}
        <div className='bodyWrapper'>
          {children || '--'}
        </div>
        {renderCheckbox()}
        {renderBtn()}
      </div>
    </Collapse.Panel>
  </Collapse>;
}

export default InformationComponent;