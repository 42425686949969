import * as d3 from 'd3'
import PropTypes from 'prop-types';
import {React, BaseComponent, _} from 'ihcomponent'
import { clearTypenameFields } from 'libModule/utils/common';
import BPChart from './BloodPressureChart';

// TODO: Refactor to use COLOR_MAP instead
const COLOR_MAP = {
  group1: '#1FBCD9', //"lt18"
  group2: '#3572C4', //"18-35"
  group3: '#9FC53F', //"36-55"
  group4: '#F4CD56', //"56-75"
  group5: '#F28244' //"gt75"
};

const GROUP_MAP = {
  group1: '49 and under',
  group2: '50 - 64',
  group3: '65 - 74',
  group4: '75 - 79',
  group5: '80 and older'
}

const COLOR_ARRAY = Object.values(COLOR_MAP);

const EnrolledPatientStatsComponent = class extends BaseComponent {
  static displayName = 'dashboard/components/EnrolledPatientStatsComponent'

  constructor(props) {
    super(props);
    this.createDonutChart = this.createDonutChart.bind(this);
  }
  componentDidMount() {
    this.createDonutChart(this.props.enrolledStats, this.props.className);
  }
  componentWillUnmount() {
    d3.select(this._container).selectAll('*').remove();
  }
  componentWillReceiveProps(nextProps) {

    if (!_.isEqual(nextProps.enrolledStats, this.props.enrolledStats)) {
      d3.select(this._container).selectAll('*').remove();
      this.createDonutChart(nextProps.enrolledStats, this.props.className);
    }
  }
  render() {
    return (
      <div className='enrolledPatientStatisticsContainer' ref={ node => {this._container = node }}></div>
    )
  }
  createDonutChart(enrolledStats, className) {
    const modifiedAgeGroups = clearTypenameFields(enrolledStats.ageGroups);
    const ageGroupData = Object.values(enrolledStats.ageGroups);
    const ageGroupKeys = Object.keys(modifiedAgeGroups);

    const width = 460;
    const height = 460;
    const radius= 130;
    // arc & label generator
    let arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(90)
      .padAngle(0.01);

    let pie = d3.pie()
      .sort(null)
      .value(d => {
        return d;
      });

    // define svg
    let svg = d3.select(this._container).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g') // group similar elements together
      .attr('transform', 'translate(' + width / 3 + ', ' + height / 3 + ')');

    // different coordinates for left and right positioned pie charts on dashboard
    const updateX = className == 'left' ? 240 : 820;
    const updateY = className == 'right' ? 500 : 500;

    // append g elements (arc)
    const g = svg.selectAll('.arc')
      .data(pie(ageGroupData))
      .enter().append('g')
      .attr('class', 'arc');

    // append the path of the arc
    g.append('path')
      .attr('d', arc)
      .attr('data-legend', val => {
        return val.data;
      })
      .attr('data-legend-pos', (val) => {
        return val.index;
      })
      .style('fill', val => {
        return COLOR_ARRAY[val.index];
      })
      .on('mouseout', function () {
        d3.select(`.tooltip.piechart.${className}`).attr('style', 'display:none');
      })
      .on("mousemove", function (d) {
        if ((d[1] - d[0]) !== 0) {
          tooltip.style("left", d3.event.pageX - updateX + "px");
          tooltip.style("top", d3.event.pageY - updateY + "px");
          tooltip.style("display", "flex");
          let classBeforeAdding = tooltip.attr('class');
          tooltip.attr('class',classBeforeAdding+" "+className);
          let value = d.data;
          // tooltip.html(`<h2 style=padding:5px>${precentage}%</h2><div style=padding:5px;align-self:center><span style=font-weight: bold;>${key}</span><br>${value} of ${totalMeasurements} patients</div>`);
          tooltip.html(`<div style=padding:5px>${value} patients</div>`);
        }
      })


    // append with label
    g.append('text')
      .attr('transform', d => {
        var c = arc.centroid(d),
          x = c[0],
          y = c[1],
          // pythagorean theorem for hypotenuse
          h = Math.sqrt(x * x + y * y);
          let labelX = 0;
          let labelY = 0;
          if(x<0){
             labelX = radius +20  ;
          }
          else{
            labelX = radius;
          }
          if(y<0){
            labelY = radius +5;
          }
          else {
            labelY = radius;
          }

        return "translate(" + (x / h * labelX) + ',' +
          (y / h * labelY) + ")";
        // return 'translate(' + labelArc.centroid(d) + ')';

        // var c = arc.centroid(d);
        // return "translate(" + c[0] * 1.4 + "," + c[1] * 1.3 + ")";
        // return 'translate(' + labelArc.centroid(d) + ')';
      })
      .text(val => {
        if (typeof val.data === 'number' && val.data) {
          return Math.round((val.data / enrolledStats.total) * 100) + '%';
        }
        return null;
      });


    let z = d3.scaleOrdinal()
      .range(COLOR_MAP);
    z.domain(ageGroupKeys);

    let legend = g.append('g')
      .append('svg')
      .attr('width',300)
      .attr('height',200)
      .append('g')
      .attr('class', 'legend')
      .attr('transform',`translate(-180,0)`)
      .attr("font-family", "Helvetica Neue")
      .attr("font-size", 12)
      .selectAll("g")
      .data(ageGroupKeys.slice())
      .enter().append("g")
      .attr("transform", function (d, i) {
        return "translate(-80," + i * 25 + ")";
      });

    legend.append("circle")
      .attr("cx", width)
      .attr("r", 8)
      .attr("cy", 25)
      .attr("fill", function(z){
        return COLOR_MAP[z];
      });

      let parentNode = d3.select(svg.node().parentNode.parentNode);
      let tooltip = parentNode.append("div")
        .attr("class", "piechart tooltip arrow_box")


    legend.append("text")
      .attr("x", width + 20)
      .attr("y", 25)
      .attr("dy", "0.32em")
      .text(function (d) {
          return GROUP_MAP[d];
      });
  }
};

EnrolledPatientStatsComponent.propTypes = {
  enrolledStats: PropTypes.object.isRequired
};

export default EnrolledPatientStatsComponent;
