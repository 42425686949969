/**
 * Created by zizhangai on 2/11/17.
 */
import gql from 'graphql-tag';

export default gql`
  mutation generateInterventionNotes(
    $enrolledProgramId: EID!, 
    $reason: String, 
    $summary: String, 
    $startDate: Date!, 
    $endDate: Date!) {
    generateInterventionNotes(
      enrolledProgramId: $enrolledProgramId, 
      reason: $reason, 
      summary: $summary, 
      startDate: $startDate, 
      endDate: $endDate) {
      interventionNotes_id
      fileUrl
      message
    }
  }
`;
