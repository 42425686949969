const billerMenuArray = [
  {
    id: 'reports',
    title: 'Reports',
    href: '/reports',
    src: '/image/reports.svg',
    class: 'reports',
  },
]

export const HOMEPAGE = '/reports'

export default billerMenuArray;
