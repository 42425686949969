import gql from 'graphql-tag';

export default gql`
query smartAlertTasksList($filters:smartAlertOpts, $page:Int, $count:Int,$sort:SmartAlerttListSort){
  smartAlertTasksList(filters:$filters, count:$count, page:$page,sort:$sort){
    data{ 
        id
        memberId
    }
  }
}`;
