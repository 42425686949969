const taskHistoryData = `
  history {
    note
    assignedTo {
      id
      profile{
        fullName
      }
    }
    assignedBy {
      id
      profile{
        fullName
      }
    }
    assignedAt
  }
`;

export {
  taskHistoryData
};