import React, { Component } from 'react';
import {browserHistory} from "react-router";


class intermediaLogOutComponent extends Component {

    render(){
        return<div></div>
    }
}

export default intermediaLogOutComponent;
