import I18N from '../../I18N';

const motivations = I18N.get('motivation');

const stageOfBehavorialChanges = _.map(_.keys(motivations), mK => {
  const v = motivations[mK];
  return {
    label: v,
    value: mK
  };
});

const DEFAULT_BEHAVIOR_CHANGE = _.first(stageOfBehavorialChanges).value;

const resolutionOptions = [
  { label: 'Improvement shown', value: 'IMPROVEMENT_SHOWN' },
  { label: 'Unresolved', value: 'UNRESOLVED' },
  { label: 'Resolved', value: 'RESOLVED' },
  { label: 'No longer appropriate', value: 'NO_LONGER_APPROPRIATE' },
]

const STATEMENT_DELIMITER = '.'; // delimiter for statementIndex and the rest
const GROUP_DELIMITER = '-'; // delimiter for groupId and refId
const VALUE_DELIMITER = '|'; // delimiter for refId and value

// key = ENUM of type
// value = key of PESGroup
const categoryMap = {
  PROBLEM: 'problem',
  ETIOLOGY: 'etiology',
  SIGN_SYMPTOM: 'signSymptom'
};

const PESStatementActions = {
  CREATE: 'create',
  EDIT: 'edit',
};

const visitTypesCanResolvePES = ['FOLLOW_UP', 'ADDITIONAL', 'MNT'];

const NUTRITION_INTERPRETATION_FIELDS = ['behaviorChange', 'interpretationNote'];

export { 
  stageOfBehavorialChanges,
  DEFAULT_BEHAVIOR_CHANGE,
  resolutionOptions,
  categoryMap,
  STATEMENT_DELIMITER,
  GROUP_DELIMITER,
  VALUE_DELIMITER,
  PESStatementActions,
  visitTypesCanResolvePES,
  NUTRITION_INTERPRETATION_FIELDS
}