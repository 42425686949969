import {_} from 'ihcomponent'

export default class{
  constructor(obj){
    this.variables = {}
    this.key = obj && obj.id || ''
    if (!obj) return
    _.each(obj, (v, k)=>{
      this.variables[k] = v
    })
  }

  _get(key){
    return _.get(this.variables, key)
  }

  _getData(){
    return this.variables
  }

  format(type){
    return ''
  }
}
