import React, {Component} from 'react';
import { singleVisitInCalendarQuery } from '../query/singleVisitQuery';
import { compose, graphql } from 'react-apollo';
import {connect} from "react-redux";
import '../../visitNewWorkFlow/CSS/style.scss'
import CURRENT_COMPONENT from '../../visitNewWorkFlow/constants/CurrentComponent'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { Modal } from 'antd';
import InputVisitFormComponent from "../../visitNewWorkFlow/components/InputVisitFormComponent";
import actions  from 'modulesAll/common/actions/modal'
import WorkListContainer from '../../visitWorkList/container/workListContainer';
// import {API} from "../query";
import { IHLoading } from 'ihcomponent';

class EditVisitInAppointmentsContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            disableSaveButton: true,
            showTaskModal: false
        };
        this.editVisit = this.editVisit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setDisableSaveButton = this.setDisableSaveButton.bind(this);
    }
    componentDidMount(){
        // const visitId = _.get(this,'props.routeParams.visitId',_.get(this,'props.selectedVisit.id',null));
        // if(visitId){
        //     API.getVisit(visitId)
        //         .then((res)=> {
        //             const visit = _.get(res,'data.getVisit',null);
        //             this.props.setSelectedVisit(visit);
        //         })
        //         .catch((error)=>{
        //             this.props.openErrorModal(GQLHelper.formatError(error));
        //         })
        // }
    }
    showConfirmForCancel(id) {
        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Yes, Cancel Appointment',
                handler: ()=>{
                    resolve(this.props.deleteVisit(id) || true);
                },
                style:{
                    border: 'solid 1px #aaaaaa',
                    color:'red',
                    background:'white',
                    textShadow:'none'
                },
                closeModal: true
            }
            const No = {
                label: 'No',
                style:{
                    border: 'solid 1px #4278c3',
                    color:'#4278c3',
                    background:'white',
                    textShadow:'none'
                },
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Cancel Appointment?',
                content: 'Are you sure you want to cancel this appointment? This action can not be undone.'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    setDisableSaveButton(value){
        this.setState({
            disableSaveButton: value
        });
    }

    async handleDelete(cb){
        const {visitInfo} = this.props;
        const deleted = await this.showConfirmForCancel(visitInfo.id);
        if (cb && deleted) {
            cb();
        }
    }

    editVisit(variables){
        const { saveChanges, refetchVisitListInPatientProfile, refetchNextVisit } = this.props;
        saveChanges(variables).then(() => {
          if(refetchVisitListInPatientProfile) refetchVisitListInPatientProfile();
          if(refetchNextVisit) refetchNextVisit(); // only in patient Profile
        });

    }

    setShowTaskModal = (visit)=>{
        this.setState({
            showTaskModal:visit
        })
    }
    renderTaskModal = () => {
      const { setShowTaskModal, props: { refetch: refetchVisitList } } = this;
      const visitId = _.get(this, 'state.showTaskModal.id');
      const appointmentAt = _.get(this, 'state.showTaskModal.appointmentAt');
      const memberId = _.get(this, 'state.showTaskModal.member.id');
      return visitId && <WorkListContainer 
        id={visitId}
        appointmentAt={appointmentAt}
        memberId={memberId}
        onCancel={() => {
          if (refetchVisitList) refetchVisitList();
          setShowTaskModal(null);
        }}
      />;
    };
    renderSelectComponent(){
        const { isFromVisitTable, visitInfo } = this.props;
        const { showWorkListModal } = this.state;
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT) {
            return (<InputVisitFormComponent key={_.get(visitInfo, 'id')}
                                             visitInfo={visitInfo}
                                             editStatus={true}
                                             handleDelete={this.handleDelete}
                                             editVisit={this.editVisit}
                                             disableSaveButton={this.state.disableSaveButton}
                                             setDisableSaveButton={this.setDisableSaveButton}
                                             fromAppointments={true}
                                             isFromVisitTable={isFromVisitTable}
                                             backToSearch={this.props.renderFromEditToCreate}
                                             setShowTaskModal={this.setShowTaskModal}
                                             showWorkListModal={showWorkListModal}
                                             onCancel={this.props.onCancel}
                                             onDateChange={this.props.setAppointMonthAndYear}
                                             onAssigneeChange={this.props.onAssigneeChange}
                                             patient={this.props.patient || {
                                               user: _.get(visitInfo, 'member')
                                             }}
                                             setPatient={this.props.setPatient}
            />)
        }
    }

    render() {
      const {isLoadingGetVisitInfo } = this.props;
      if (isLoadingGetVisitInfo) return <IHLoading />;
        return (
            <div className='newCreateVisitContainer'>
                {this.renderSelectComponent()}
                {this.renderTaskModal()}
            </div>
        )
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(actions.confirm(props)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

const getVisitInfo = graphql(singleVisitInCalendarQuery, {
  options: (ownProps) => {
    const visitId = _.get(ownProps, 'selectedVisitId');
    return {
      variables: {
        id: visitId,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props: ({ data }) => {
    const { loading, error, getVisit } = data;
    if (error) {
      console.error(error);
    }
    return {
      isLoadingGetVisitInfo: loading,
      visitInfo: getVisit,
    };
  }
})

export default compose(
  connect(null,mapToDispatch),
  getVisitInfo
)(EditVisitInAppointmentsContainer);

