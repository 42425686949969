const commonReplaceReturnDevicesActionsHelper = (types) => {
  return {

    setReplaceDevicesStep: (replaceOrReturnDevicesStep) => ({
        type: types.SET_REPLACE_DEVICES_STEP,
        replaceOrReturnDevicesStep
    }),

    setReturnDevicesStep: (returnDevicesStep) => ({
        type: types.SET_RETURN_DEVICES_STEP,
        returnDevicesStep
    }),

    changeDeviceStatusTableInfo: (deviceId, selectValue) => ({
            type: types.CHANGE_DEVICES_INFO,
            devicesStatus:  {deviceId, select:selectValue}
    }),

    changeDeviceRemarkTableInfo: (deviceId, remark) => ({
            type: types.CHANGE_DEVICES_INFO,
            devicesStatus:  {deviceId, remark}
    }),

    resetDevicesStatusTableInfo: () => ({
        type: types.RESET_DEVICES_INFO
    }),

    initDevicesStatusTableInfo: (devicesStatus) => ({
        type: types.INIT_DEVICES_INFO,
        devicesStatus
    })

  }
}

export const commonReplaceReturnDevicesActions = (types, actionNames) => {
  const common = commonReplaceReturnDevicesActionsHelper(types)
  const actionObjects = {}
  actionNames.map( (a) => {
    actionObjects[a] = common[a]
  })
  return actionObjects
}
