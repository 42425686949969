import gql from 'graphql-tag'

export default gql `
  mutation resendTfaCode($token: String!) {
    resendTfaCode(token: $token) {
      id
      token
      mobile {
        number
        countryCode
      }
    }
  }
`
