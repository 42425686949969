/**
 * Created by zizhangai on 4/17/17.
 */
import React from 'react'
import _ from 'lodash'
import { graphql } from 'react-apollo'
import { resultThreshold } from 'graphqlModule/result'
import { TASK_TYPE_MAP, VITAL_TYPE_MAP_FROM_SHORT } from 'modulesAll/utils/constants/task'
import { alertColor } from 'modulesAll/patientView/vitalList/constants/VitalListConstants.js'
import { formatSchedule } from 'modulesAll/utils/helpers/task';
import { convertDBToThreshold } from 'modulesAll/utils/helpers/task'
import { renderThresholds } from 'libModule/helpers/component-helpers'

const styles = {
  alertMsg: {
    name: {
      fontSize: '22px',
      fontWeight: 600,
      color: '#19313a',
      wordWrap: 'break-word',
      textAlign: 'left'
    },
    wrapper: {
      textAlign: 'left',
      margin: '10px 0 20px 0',
      lineHeight: 1.7,
      color: '#19313a'
    },
    span: {
      fontSize: '15px',
      margin: '0 10px 0 0',
      fontWeight: 600
    },
    spanValue: {
      borderRadius: '4px',
      padding: '0 4px',
    },
    threshold: {
      width: '150%', // some div might be very long
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
}

class ResultInfo extends React.Component {
  static displayName = 'ResultInfoContainer'

  render() {
    const { row, resultThreshold } = this.props
    return (
      <div style={styles.alertMsg.wrapper}>
        <div>
          <span style={styles.alertMsg.span}>Type</span>
          {alertMsgRenderAlert(row)}
        </div>
        <div>
          <span style={styles.alertMsg.span}>Schedule</span>
          {alertMsgRenderSchedule(row)}
        </div>
        <div>
          <span style={styles.alertMsg.span}>Status</span>
          {alertMsgRenderStatus(row)}
        </div>
        <div>
          <span style={styles.alertMsg.span}>Threshold</span>
          <div style={styles.alertMsg.threshold}>
            {resultThreshold? alertThreshold(row, resultThreshold) : `Loading...`}
          </div>
        </div>
      </div>
    )
  }

}

const withData = graphql(resultThreshold, {
  options: (ownProps) => {
    return {
      variables: {
        "id": ownProps.row.id
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data: { loading, result } }) => {
    return {
      resultThreshold: _.get(result, 'threshold'),
      loading
    }
  }
})

export default withData(ResultInfo)


function alertMsgRenderAlert(row) {
  if (row.taskStatus === 'MISSED') {
    const style = {
      ...styles.alertMsg.spanValue
    }
    return <span style={style}>{`Missed ${TASK_TYPE_MAP[row.type]}
      ${(row.type === 'ASSESSMENT' || row.type === 'SURVEY')? '' : ' Reading'}`}</span>
  }
  return <span>{TASK_TYPE_MAP[row.type]}</span>
}
function alertMsgRenderSchedule(row) {
  // const date = moment(row.date).format('MMM-DD-YYYY')
  const style = {
    backgroundColor: '#f0f0f0',
    ...styles.alertMsg.spanValue
  }
  return <span style={style}>{formatSchedule(row)}</span>
}
function alertMsgRenderStatus(row) {
  const style = {
    ...styles.alertMsg.spanValue,
    fontWeight: row.careStatus === 'NEW'? 'normal':100,
    backgroundColor: row.careStatus === 'NEW'? '#f0f0f0':'#258ce6',
    color:row.careStatus === 'NEW'? '#19313a':'#fff'
  }
  const map = {
    'NEW': 'Open',
    'HANDLED': 'Reviewed',
    'CLOSED': 'Closed'
  }
  return <span style={style}>{map[row.careStatus]}</span>
}
function alertThreshold(row, resultThreshold) { //VS-2107
  const { type, measure } = row
  if (type === 'ASSESSMENT' || type === 'SURVEY') return null
  const threshold = {
    ...convertDBToThreshold(resultThreshold, null),
    'vitalType': VITAL_TYPE_MAP_FROM_SHORT[type],
    isBaselineExist: !!_.get(resultThreshold, '0.baseline')
  }
  return renderThresholds(null, threshold, null, measure)
}
