import gql from 'graphql-tag';


export default gql `
                mutation addFoodLogComment($id:EID,$comment:FoodLogCommentInput!) {
                  addFoodLogComment(id:$id,comment:$comment) {
                    id
                    comments {
                      text
                      originalText
                      commentedBy {
                        id
                        profile {
                          fullName
                        }
                      }
                      commentedAt
                    }
                  }
                }`

