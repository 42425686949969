import {Component} from "react";
import React from 'react';
import { Button,Modal,Input,Checkbox,message } from 'antd';
import moment from "moment/moment";
import map from "../constants/tableMap";
import resolveTaskAssignment from '../query/resolveTaskAssignment';
import Client from 'libModule/gqlClient';
import helper from '../helper/';
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';
import I18N from "../../I18N";
import ResolvedNote from "./ResolvedNote";
import TrackingTaskModalComponent from "./TrackingTaskModalComponent";
import {helpers} from '../../visitNewWorkFlow/helper';
import TaskAssignmentHistory from "./TaskAssignmentHistory";
import TimerClaimedContainer from "../../timerClaimed/containers/TimerClaimedContainer";
import { mapDispatchToTimerActions } from "../../timerClaimed/helpers";
import { EVENT_TYPES } from "../../idleTime/constant";

const { stopTimer,updateInterventionDetails }= timerActions.default;
const { priorityMap } = map;
const { TextArea } = Input;
const TASK_MODAL_CONTAINER_TIMER = 'TASK_MODAL_CONTAINER_TIMER';

const style = {
        rowStyle:{
            display:'flex',
            flexDirection:'row'
        },
        buttonDivStyle:{
          margin:'10px 0px'
        },
        labelStyle:{
          marginRight: 10
        },
        headerStyle:{
          fontWeight:'bold',
          fontSize:18,
          color: '#000000'
        },
        buttonStyle:{

        },
        reasonStyle:{
           paddingBottom: '10px',
           color: '#474747',
           opacity:0.7
        },
        assignerStyle:{
            color:'#555555',
            marginRight: 10,
            fontWeight:'bold'
        },
        resolveStyle:{
            color:'#555555',
            display:'flex',
            justifyContent:'flex-end'
        }

}

class MyTasksModalComponent extends Component {
    static displayName ='MyTasksModalComponent';

    constructor(){
        super();
        const { selectedRole } = JSON.parse(sessionStorage.getItem('currentUser'));
        const { name } = selectedRole;
        //const isMD = (name==='Doctor'||name==='Clinic Medical Assistant');
        this.state = {
            note:'',
            addToPN: true, // resolve task will be added to provider note by default
            reassignmentFormVisibility: false
        }

    }

    setReassignmentFormVisibility = (isVisible = true) => this.setState({ reassignmentFormVisibility: isVisible });

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }

    onChangeCheckBox = ()=>{
        const { addToPN } = this.state;
        const { fromTaskList } = this.props;
        this.setState({
            addToPN:!addToPN
        })
        if(fromTaskList === 'TaskList') {
            Mixpanel.track('checked', 'add to provider notes', 'patient task list popup');
        } else {
            Mixpanel.track('checked', 'add to provider notes', 'task detail popup', {});
        }
    }

    close = ()=>{
        const { setModalData } = this.props;
        Mixpanel.track('clicked', 'close', 'task detail popup');
        setModalData({showModal:false,modalData:null});
    }

    handleClick = ()=>{
        const { note,addToPN } = this.state;
        const { modalData,refetchUserList,refetchUser, refetchTasksCount, timerClaimed,refetchTaskList,setResolveFormVisibility } = this.props;
        const { id,refetch } = modalData;
        const { fromTaskList } = this.props;
        const memberId = _.get(modalData,'member.id');
        Client.mutate({
            mutation:resolveTaskAssignment,
            variables:{ id, resolvedNote:note }
        }).then(res=>{
            if(refetchTaskList) {
                refetchTaskList();
                if(setResolveFormVisibility) setResolveFormVisibility(false);
            }
            if(refetch){
                refetch();
            }
            if(refetchUser){
                refetchUser();
            }
            if(refetchUserList){
                refetchUserList();
            }

            // fetch the tasks count when resolve task
            if(refetchTasksCount){
                refetchTasksCount();
            }

            API.fetchTaskUnreadList();
            message.success('Task Resolved');
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            const resource = atob(docId).split(':')[0];
            let displayName = this.constructor.displayName;
            const summary = res.data[apiName].id;
            displayName = `${displayName}:${memberId}`;
            this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
            this.props.stopTimer(displayName, 'saveModal');
            timerClaimed.interacted(EVENT_TYPES.TASK_ASSIGNMENT, 'RESOLVED');
            if(addToPN){
              helper.createPN({
                memberId,
                content:note
              }).then(res=>{
                timerClaimed.interacted(EVENT_TYPES.PROVIDER_NOTES);
                timerClaimed.stopTimer();
                this.close();
              });
            }else {
              timerClaimed.stopTimer();
              this.close();
            }
            const {priorityLevel} = this.props.modalData;
            if(fromTaskList === 'TaskList') {
              Mixpanel.track('clicked', 'resolve', 'patient task list popup');
            } else {
              Mixpanel.track('clicked', 'resolve', 'task detail popup', {PRIORITY_LEVEL: priorityLevel});
            }
        }).catch(e=>{
            Modal.error({ content : e.message});
        })

    }
    renderBody = ()=>{
        const { handleClick,onChangeInput,props,state } = this;
        const { modalData,renderBody, showFormOnly, setResolveFormVisibility } = props;
        const { note,addToPN, reassignmentFormVisibility } = state;
        const needReason = !note;
        const { 
          reason,priorityLevel,dueDate,assignedTo,createdAt,member,resolvedNote, history
        } = modalData||{};
        const  patientName  = _.get(member,'profile.fullName');
        const mrn = _.get(member,'identification.0.value');
        const assignedToName = _.get(assignedTo,'profile.fullName');

        const headerText = renderBody ? 'Reason' :`${patientName} (${mrn})`;

        const reassignBtn = <a onClick={this.setReassignmentFormVisibility}>
          Edit
        </a>;

        // if renderBody true, left, right container will be hidden
        return reassignmentFormVisibility ?
        <TrackingTaskModalComponent 
          {...props} 
          customHeader={<div style={{ lineHeight: '24px', marginBottom: 10}}>
            <span style={{ fontSize: 18, color: '#000', opacity: '45%' }}>
              Task Reassignment
            </span>
            <br/>
            <span style={{ ...style.headerStyle, fontSize: 14 }}>
              {headerText}
            </span>
          </div>}
          setReassignmentFormVisibility={this.setReassignmentFormVisibility}
          showFormOnly 
        />
        :
        <div className={`myTaskModal ${renderBody ? 'inPatientProfile':''}`}>
          <div className='outerContainer'>
            <div className='upperContainer'>
                <div className={renderBody ? 'upper-container-header' : ''}>
                  <div style={style.headerStyle}>
                    { headerText }
                  </div>
                  {
                    renderBody && !showFormOnly && reassignBtn
                  }
                </div>
                <div style={style.rowStyle} className='leftContainer'>
                  <div style={{ marginRight:15 }}>
                      <b style={style.labelStyle}>{I18N.get('taskAssignment.priorityLevel.label')}:</b>{priorityMap[priorityLevel]}
                  </div>
                  <div>
                      <b style={style.labelStyle}>{I18N.get('taskAssignment.dueDate')}:</b>{ dueDate ? moment(dueDate).format('MM/DD/YYYY'):'Not Specified'}
                  </div>
                </div>
                <div className='rightContainer'>
                    <div style={style.assignerStyle}>
                      <div style={{...style.assignerStyle, marginRight: 5 }}>
                        {I18N.get('taskAssignment.assignedTo')}:&nbsp;
                        {assignedToName}
                      </div>
                      {
                        !resolvedNote && reassignBtn
                      }
                    </div>
                    <div>{moment(createdAt).format('MM/DD/YYYY hh:mm')}</div>
                </div>
                <div className='tracking-task-reason-container'>
                  <div>{reason}</div>
                </div>
                {
                  !renderBody &&
                  <TaskAssignmentHistory taskCreatedAt={createdAt} history={history}/>
                }
            </div>
            {
              resolvedNote ? 
              <ResolvedNote {...resolvedNote} />
              :
              <div className='lowerContainer'>
                <TextArea 
                  rows={6} placeholder="Add Note Here"  
                  onChange={ (e)=>onChangeInput(e) }
                />
                <div style={{ marginTop:10 }}>
                    { 
                      !needReason &&  
                      <Checkbox 
                        onChange={ this.onChangeCheckBox } 
                        defaultChecked={addToPN}
                      >
                        Add to Provider Notes
                      </Checkbox> 
                    }
                </div>
                <div style={style.buttonDivStyle}>
                  <Button
                    style={{ marginRight: 10 }} 
                    onClick={ ()=>handleClick() } 
                    type='primary' disabled={needReason}
                  >
                    Resolve Task
                  </Button>
                  {
                    showFormOnly && 
                    <Button onClick={() => setResolveFormVisibility(false)}>
                      Cancel
                    </Button>
                  }
                </div>
              </div>
            }
          </div>
        </div>
    }
    renderTimer(){
        const { modalData } = this.props;
        let displayName = this.constructor.displayName;
        const memberId = _.get(modalData,'member.id');
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return (
          <React.Fragment>
            <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='UPDATE'
                                 viewingDetails={viewingDetails}
                                 patientId={memberId}
                                 keepEventListener={keepEventListener}
            />
            <TimerClaimedContainer id={TASK_MODAL_CONTAINER_TIMER} patientId={memberId} />
          </React.Fragment>
        )
    }
    render(){
        const { props,state } = this;
        const { setModalData,renderBody, showFormOnly } = props;

        return <div>
          {this.renderTimer()}
          {
              !renderBody && !showFormOnly ?
              <Modal visible={true} footer={null} maskClosable={false}
                      onCancel={() => setModalData({showModal: false, modalData: null})} destroyOnClose={true}>
                  {this.renderBody()}
              </Modal> : this.renderBody()
          }
        </div>
    }

}
const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch, ownProps)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
        timerClaimed: mapDispatchToTimerActions(dispatch, TASK_MODAL_CONTAINER_TIMER, _.get(ownProps,'modalData.member.id'))
    }
};
export default connect(mapToProps,mapToDispatch)(MyTasksModalComponent);

