import gql from "graphql-tag";

export const upsertPatientMntProgramQuery = gql`mutation upsertPatientMntProgram ($patientId: EID!,
$referredBy: EID,
$ineligibledBy: EID
$description: String,
$mntConditions: [String],
$visitId: EID,
$status: MntProgramStatusEnum){
  upsertPatientMntProgram(patientId:$patientId,description:$description,mntConditions:$mntConditions,referredBy:$referredBy,ineligibledBy:$ineligibledBy,status:$status,visitId:$visitId){
    id
    patient{
        id
    }
    referredBy{
        id
        profile{
            fullName
        }
    }
    ineligibledBy {
        id
        profile {
            fullName
        }
    }
    description
    mntConditions
    status
    visit {
        id
    }
  }
}`

export const getPatientProgramQuery = gql`query getPatientProgramQuery ($patientId:EID!){
  getPatientMntProgram(patientId:$patientId){
    id
    patient{
        id
    }
    referredBy{
        id
        profile{
            fullName
        }
    }
    ineligibledBy {
        id
        profile {
            fullName
        }
    }
    description
    mntConditions
    status
    visit {
        id
    }
  }
}
`