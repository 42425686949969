const categories = [
  {
    text: 'Virtual Visit',
    value: 'VIRTUAL'
  },
  {
    text: 'In-Clinic Visit',
    value: 'INCLINIC'
  }
];

export default categories;