
import gql from 'graphql-tag';
export default gql`query user($id: EID!) {
	user(id: $id) {
    loginAuditList {
      createdAt
      phoneName
      networkType
      networkSpeed
      ip
      success
      action
      note
    }
    phoneAuditList {
      phoneId
      phoneName
      phoneModel
      serverUrl
      appScheme
      appVersion
      phoneOSVersion
      firstUsed
      lastUsed
    }
    measureAuditList {
			phoneName
      networkType
      networkSpeed
      deviceModel
      deviceAddress	
      type
      date
      ... on BPMeasureAudit {
        systolic_blood_pressure
        diastolic_blood_pressure
        heart_rate
      }
      ... on BGMeasureAudit {
        blood_glucose
      }
      ... on HSMeasureAudit {
        body_weight
      }
      ... on POMeasureAudit {
        blood_oxygen
        heart_rate
        perfusion_index
      }
      ... on TMMeasureAudit {
        body_temperature
      }
    }
    devices {
      type
      model
      status
      address
      other
    }
    id
    createdAt
    updatedAt
    username
    email {
      address
    }
    address {
      type
      streetName
      streetNumber
      floor
      unit
      city
      state
      country {
        code
        description
        __typename
      }
      postCode
      __typename
    }
    phone {
      type
      countryCode
      number
      verified
      __typename
    }
    identification {
      type {
        code
        description
        __typename
      }
      value
      MRN
      __typename
    }
    profile {
      fullName
      ... on MemberProfile {
        birthday
        race {
          code
          description
          __typename
        }
        language {
          code
          description
          __typename
        }
        nationality {
          code
          description
          __typename
        }
        height
        weight
        latestWeight
      }
    }
    userSettings {
      preferredUnit
      notification
      __typename
    }
    starred
    starredReason
    caregiver {
      username
      email {
        address
        verified
        __typename
      }
      profile {
        fullName
        gender
        ... on EmployeeProfile {
          employeeId
          department
          specialty
        }
      }
      team {
        id
        name
        healthCondition
        __typename
      }
    }
	}
}`