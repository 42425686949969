import gql from 'graphql-tag';

export const updatePatientComplexity = gql`
  mutation updatePatientComplexity(
    $memberId: EID!,
    $level: PatientComplexityLevel!,
    $source:PatientComplexitySource
  ) {
    updatePatientComplexity(memberId: $memberId, level: $level, source: $source) {
      memberId
      level
      source
      updatedBy {
        id
        profile {
          firstName
          lastName
          fullName
        }
      }
      updatedAt
    }
  }
`;

export default updatePatientComplexity;
