import {React, _, IHButton, IHTable} from 'ihcomponent'
import {SuperContainer, createGQLContainer, setPagePath, GQLHelper, getRouterParam, goPath} from 'libModule/utils'
import userGQL from 'modulesAll/graphql/user'
import searchPatientGQL from 'modulesAll/graphql/searchPatient'
import editUserProfile from 'modulesAll/graphql/mutation/editMemberProfile'
import { graphql } from 'react-apollo'
import UserClass from 'modulesAll/graphql/class/User'
import {modalAction} from 'modulesAll/common/actions'
import I18N from 'modulesAll/I18N'
import { createAuditLog } from 'libModule/utils/auditLog'

const Component = class extends SuperContainer{
  static displayName = 'patient/profileReimport/containers/main'

  constructor(p){
    super(p)
    this.state = {
      param : []
    }
    this.select = []
  }
  renderMain(){
    const {user} = this.props
    setPagePath(`Patients / ${user.profile.fullName} / reimport`)

    this.user = user
    this.patient = this.props.searchPatient ? this.props.searchPatient[0] : null


    return (
      <div className="vsm-main-page">
        <div className="vsm-main">
          {this.renderBackLink()}
          {this.renderBody()}
        </div>
      </div>
    )
  }

  renderBody(){
    if(!this.patient){
      return null
    }

    this.checkResult = this.checkDifferent()

    if(!this.checkResult.flag){
      return null
    }

    return (
      <div className="v-patient-profileDetail">
        {this.renderTable(this.checkResult.diff)}
        {this.renderBottomButton()}
      </div>
    )
  }

  renderTable(list){
    const p = {
      columns : [
        {
          title : 'Parameter',
          key : 'Parameter',
          dataIndex : 'name'
        },
        {
          title : 'Original Value',
          key : 'Original Value',
          dataIndex : 'old'
        },
        {
          title : 'New Imported Value',
          key : 'New Imported Value',
          dataIndex : 'new'
        }
      ],
      rowKey : (d)=>d.id,
      rowSelection : {
        type : 'checkbox',
        selectedRowKeys : this.state.param,
        onChange : (id, obj)=>{
          this.select = obj
          this.setState({
            param : id
          })
        }
      },
      dataSource : list
    }
    return (
      <div>
        <p className="vm-comp-up-title">Select Profile Changes to Accept</p>
        <IHTable {...p} />
      </div>
    )
  }

  renderBottomButton(){
    const b2 = {
      label : 'Save',
      type : 'primary',
      size : 'large',
      ref : 'sbtn',
      onClick : async ()=>{
        const profile = this.user.profile
        const variables = {
          id : this.props.patientId,
          profile : _.merge({}, {
            firstName : profile.firstName,
            lastName : profile.lastName,
            nickname : profile.nickname,
            birthday: profile.birthday,
            gender : profile.gender
          }, _.fromPairs(_.map(this.select, (o)=>{
            return [o.id, o.new]
          })))
        }
        //console.log(variables)
        this.refs.sbtn.loading(true)

        const user = this.props.userData
        const patientNRIC = _.get(user, 'identification[0].value')
        const patientName = _.get(user, 'profile.fullName')

        try{
          const res = await this.props.editUserProfile({variables})
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.reimportProfile'),
            patientNRIC,
            patientName,
            details: _.omit(variables, 'id'),
            request: variables,
            response: res
          })
          this.goBack()
        }catch(e){
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.reimportProfile'),
            patientNRIC,
            patientName,
            details: _.omit(variables, 'id'),
            request: variables,
            response: e,
            success: false
          })
          this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
        }finally{
          this.refs.sbtn && this.refs.sbtn.loading()
        }
      }
    }
    const b1 = {
      label : 'Cancel',
      size : 'large',
      onClick : this.goBack.bind(this)
    }

    return (
      <div className="v-bottom">
        <IHButton {...b1} />
        <IHButton {...b2} />
      </div>
    )
  }

  checkDifferent(){
    const u = new UserClass(this.user)
    const p = new UserClass(this.patient)

    const result = {
      flag : false,
      diff : []
    }

    const get_list = (doc)=>{
      //TODO add more values to compare
      return [
        {
          key : 'firstName',
          name : 'First Name',
          value : doc._get('profile.firstName')
        },
        {
          key : 'lastName',
          name : 'Last Name',
          value : doc._get('profile.lastName')
        },
        {
          key : 'gender',
          name : 'Gender',
          value : doc._get('profile.gender')
        },
        {
          key : 'birthday',
          name : 'DOB',
          value : doc._get('profile.birthday')
        }
        // {
        //   key : 'mobilePhone',
        //   name : 'Mobile Phone',
        //   value : doc.format('mobilePhone')
        // },
        // {
        //   key : 'homePhone',
        //   name : 'Home Phone',
        //   value : doc.format('homePhone')
        // }

      ]
    }

    const u_list = get_list(u)
    const p_list = get_list(p)

    _.each(u_list, (item, i)=>{
      if(item.value !== p_list[i].value){
        result.flag = true
        result.diff.push({
          id : item.key,
          name : item.name,
          old : item.value,
          new : p_list[i].value
        })
      }
    })

    return result
  }

  goBack(){
    goPath(`/patients/profile/${this.props.patientId}`)
  }

  renderBackLink(){
    const b1 = {
      size : 'large',
      type : 'link',
      className : 'vm-backlink',
      onClick : this.goBack.bind(this)
    }

    return (
      <a {...b1}>
        <i className="fa fa-angle-left"></i>
        {I18N.get('demographics.backToDemographics')}
      </a>
    )
  }
  runOnceAfterDataLoading(){
    if(this.props.error){
      this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(this.props.error)))

      return false
    }
    if(!this.patient){
      this.props.dispatch(modalAction.alert({
        title : 'Info',
        content : 'No data found',
        Yes : {
          label : 'Back',
          handler : this.goBack.bind(this)
        }
      }))

      return false
    }

    const rs = this.checkResult
    if(!rs.flag){
      //open up-to-date modal
      _.delay(()=>{
        this.props.dispatch(modalAction.alert({
          title : 'Patient Profile Up-to-Date',
          content : (
            <p>{`There was no new information detected from reimporting ${_.get(this.user, 'profile.fullName')}'s information so no changes have been made.`}</p>),
          Yes : {
            label : 'Ok',
            handler : this.goBack.bind(this)
          }
        }))
      }, 100)
    }
    else{
      _.delay(()=>{
        this.props.dispatch(modalAction.alert({
          title : 'New Profile Information Found ',
          content : (
            <p>{`Please select the profile information changes that you would like to accept.`}</p>),
          Yes : {
            label : 'Ok'
          }
        }))
      }, 100)
    }
  }
}

export default createGQLContainer([
  graphql(userGQL, {
    options(ownProps){
      return {
        variables : {
          id : ownProps.patientId
        },
        notifyOnNetworkStatusChange: true,
      }
    },
    props({data, ownProps}){
      return {
        user : data.user,
        loading : data.loading,
        error : data.error
      }
    }
  }),
  graphql(searchPatientGQL, {
    skip(ownProps){
      return !ownProps.user
    },
    options(ownProps){
      const {user} = ownProps
      const u = new UserClass(user)
      const variables = {
        id : u.format('NRIC'),
        firstName : u._get('profile.firstName'),
        lastName : u._get('profile.lastName'),
        dob : u._get('profile.birthday'),
        gender : u._get('profile.gender')
      }

      return {
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props({data, ownProps}){
      return {
        searchPatient : data.searchPatient,
        loading : data.loading,
        error : data.error
      }
    }
  }),
  graphql(editUserProfile, {
    name : 'editUserProfile'
  })
], Component, (state)=>{
  const patientId = getRouterParam(state, 'patientId')
  return {
    patientId
  }
})
