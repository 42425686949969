import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoginStep1Container from './LoginStep1Container'
import LoginStep2Container from './LoginStep2Container'
import RequestResetPasswordContainer from './RequestResetPasswordContainer'
import { browserHistory } from 'react-router'
import MainModal from 'layoutModule/containers/MainModalContainer';
import VersionContainer from './VersionContainer'
import actions from '../actions/Login'
import {Button, Col, Modal, Row} from "antd";

// container
class Login extends Component {
  static displayName = 'LoginContainer';

  constructor(props){
    super(props);

    //check whether is chrome.
    let showChromeSuggestion = false;
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== "undefined";
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");
    const isHeadlessChrome = winNav.userAgent.includes("HeadlessChrome");
    // alert(winNav.userAgent);

    if (isIOSChrome) {
      // is Google Chrome on IOS
       showChromeSuggestion = false;
    } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
      // is Google Chrome
      showChromeSuggestion = false;
    } else if (isHeadlessChrome) {
      // is headless chrome
      showChromeSuggestion = false;
    } else {
      // not Google Chrome
      showChromeSuggestion = true;
    }
    this.state = {
      showChromeSuggestion: showChromeSuggestion
    };

  }

  componentDidMount() {
      //console.log('componentDidMount ', sessionStorage.getItem("authToken"))
      if(sessionStorage.getItem("authToken")) {
        browserHistory.push('/')
      }
  }

  showChromeSuggestion(){
    if(this.state.showChromeSuggestion){
        return (
              <Modal
              title="Please Use Chrome Browser "
              visible={true}
              closable={true}
              maskClosable={false}
             footer={null}
          >
                <div>
                  <p>Hey There,</p>
                  <p>We strongly recommend using the <span style={{fontWeight: 'bold', fontColor:'black'}}>Chrome Browser</span> to visit our portal.</p>
                  <p>It will have a better user experience when you access our portal.</p>
                  <p>So please switch <span style={{fontWeight: 'bold', fontColor:'black'}}>Chrome Browser</span> and visit us again.</p>
                  <p>Thank you very much!</p>
                </div>
                <Row gutter={16} style={{marginTop: 20}}>
                  <Col span={16} offset={8}>
                    <Button type="primary" onClick={() => {this.setState({showChromeSuggestion: false})}}>Skip and use my current Browser to have a try</Button>
                  </Col>
                </Row>
          </Modal>
        )
    }
  }


  render() {
    const { step } = this.props
    return <div>
      <MainModal />
      {
        step === 1 ?
          <LoginStep1Container />
        : step === 1.5 ?
          <RequestResetPasswordContainer />
        : <LoginStep2Container />
      }
      <div style={{textAlign: "center"}}>
        <VersionContainer />
      </div>
      {this.showChromeSuggestion()}
    </div>

  }
}

// mapState
const mapState = ({ Login }, ownProps) => {
  return {
    ...Login
  }
}


// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    next: () => dispatch(actions.next())
  }
}


// PropTypes validation
Login.propTypes = {
  step: PropTypes.number
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Login)
