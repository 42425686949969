import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import clone from 'lodash/clone'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { statusSortOrder } from 'modulesAll/utils/helpers/task'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'

//files
import PatientProgramTaskList from '../components/PatientProgramTaskListComponent';
import actions from '../actions/PatientProgramTaskListActions'
import { openModal, closeModal } from 'layoutModule/actions/MainModal';


//queries
import { lightResultsFromToday } from 'modulesAll/graphql/resultsFromToday'

const PatientProgramTaskListWrapper = class extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/containers/PatientProgramTaskListContainer'

  componentDidUpdate(prevProps) {
    if((prevProps.currentPage !== this.props.currentPage) || (prevProps.sorter !== this.props.sorter) || (prevProps.currentProgram.updatedAt !== this.props.currentProgram.updatedAt /***case: care plan schedule updated, compare by date change is faster than deep compare for enrolledprogram.tasks*/)) {
      const { sorter, pagination, currentPage } = this.props
      const count = get(pagination, 'pageSize')
      this.props.refetch && this.props.refetch({sort: sorter, count, currentPage})
    }
  }

  render() {
    return <PatientProgramTaskList {...this.props} />
  }
}

const vitalFilters = (resultsFromToday) => {
  return sortBy(uniqBy(resultsFromToday || [], 'type')).map( (d) => {
    return {
      value: get(d, 'type'),
      text: TASK_TYPE_MAP[get(d, 'type')]
    }
  })
}

//To Fix: cache this calculation in MapState with reselect
const getSortedList = (resultsFromToday) => {
  return clone(resultsFromToday || []).sort( (a, b) => {
    return statusSortOrder.indexOf(a.status) - statusSortOrder.indexOf(b.status)
  })
}

const withData = graphql( lightResultsFromToday, {
  options: (ownProps) => {
    const { enrolledProgramId } = ownProps
    return {
      variables: {
        "filters": {
          "memberId": ownProps.patientId,
          "enrolledProgramId": enrolledProgramId,
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      reducer: (prev, action) => {
        if (action.type === 'APOLLO_MUTATION_RESULT' && action.operationName === 'insertBP'){
          const result = action.result.data.insertBP
          const { diastolic_blood_pressure, systolic_blood_pressure, heart_rate } = result
          const resultsFromToday = cloneDeep(prev.resultsFromToday)
          for (let i = 0; i < resultsFromToday.length; i++) {
            let item = resultsFromToday[i]
            if(item.refId === ownProps.refId && item.status === 'UPCOMING') {
              item = {
                ...item,
                diastolic_blood_pressure,
                systolic_blood_pressure,
                heart_rate,
                date: new Date().getTime(),
                status: 'COMPLETED'
              }
              resultsFromToday[i] = item
              break
            }
          }
          return update(prev, {
            resultsFromToday: {
              $set: resultsFromToday
            }
          })
        }
        else {
          return prev
        }
      }
    }
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, programList } = data
    return {
      patientProgramTaskList: data.resultsFromToday || [],
      loading,
      refetch,
      vitalFilters: vitalFilters((data.resultsFromToday || []))
    }
  }
})

const PatientProgramTaskListWithData = withData(PatientProgramTaskListWrapper)

const mapState = ({ PatientProgramTaskList, routing }, ownProps) => {
  return {
    ...PatientProgramTaskList,
    patientId: routing.params.patientId
  }
}

const mapDispatch = (dispatch) => {
  return {
    openModal: (content, modalProps) => dispatch(openModal(content, modalProps)),
    closeModal: () => dispatch(closeModal()),
    ...actions
  }
}

PatientProgramTaskListWrapper.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  refetch: PropTypes.func,
}

export default connect(
  mapState, mapDispatch
)(PatientProgramTaskListWithData)
