import React from 'react';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import { GQLHelper } from 'libModule/utils';
import { calculateTotalTime, getTimerClaimedId, getUniqueInteractions, mapDispatchToTimerActions, sendClaimedTimeReq } from '../helpers';
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import IdleTimeContainer from '../../idleTime/container/IdleTimeContainer';
import { IDLE_TIME_THRESHOLD_TIME_IN_MS } from '../constants';
import { EVENTS, EVENT_TYPES } from '../../idleTime/constant';
import { enrolledProgramAndConsent } from '../../graphql/enrolledProgramList';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const COOL_DOWN_IN_MS = 60000;
let GLOBAL_COOL_DOWN;

class TimerClaimedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedCall: false,
    };
  }

  get timerId() {
    const { id, patientId } = this.props;
    return getTimerClaimedId(id, patientId);
  }

  componentDidMount() {
    const { startTimer, coolDown = true } = this.props;

    this.startCoolDown();
    startTimer();

    $(window).on('blur', () => {
      clearTimeout(GLOBAL_COOL_DOWN);
      const { isFocused } = this.props.timerInfo;
      if (isFocused) {
        this.props.pauseTimer();
        clearTimeout(GLOBAL_COOL_DOWN);
      }
    });

    $(window).on('focus', () => {
      const { isFocused } = this.props.timerInfo;
      if (!isFocused) {
        this.props.resumeTimer();
        this.startCoolDown();
      }
    });

    if (coolDown) {
      $(window).on('click', () => {
        this.activatePage();
      });

      $(window).on('scroll', () => {
        this.activatePage();
      });

      $(window).on('keyup', () => {
        this.activatePage();
      });

      $(window).on('mousemove', () => {
        this.activatePage();
      });
    }

  }

  componentDidUpdate(prevProps) {
    const prevEvents = _.get(prevProps, 'timerInfo.allEvents') || [];
    const prevLastEvent = _.last(prevEvents) || {};
    const currentEvents = _.get(this, 'props.timerInfo.allEvents') || [];
    const currentLastEvent = _.last(currentEvents) || {};
    if (prevLastEvent.action !== 'STOP_TIMER' && currentLastEvent.action === 'STOP_TIMER') {
      this.calculateTimerInfo();
    }
    // add call event if there is a call going on with the patient
    if (this.isSamePatientAsTheCall() && !this.state.addedCall) {
      this.props.interacted(EVENT_TYPES.CALL_PATIENT);
      this.setState({ addedCall: true });
    }
  }

  componentWillUnmount() {
    const { timerInfo } = this.props;

    $(window).off('blur');
    $(window).off('focus');
    $(window).off('scroll');
    $(window).off('keyup');
    $(window).off('click');
    $(window).off('mousemove');

    if (!timerInfo) return;

    const { sendOnUnmount } = timerInfo;

    clearTimeout(GLOBAL_COOL_DOWN);

    if (sendOnUnmount) {
      this.calculateTimerInfo();
    }
  }

  isSamePatientAsTheCall = () => {
    const { patientId, callPatientId, inboundCallPatientId, callStatus } = this.props;
    return callStatus === 'onConnected' && (patientId === callPatientId || patientId === inboundCallPatientId);
  }

  calculateTimerInfo = async () => {
    const { patientId, timerInfo, closeModal, setDone, loadingEnrolledProgramAndConsent, isEnrolled, hasConsent, timerId } = this.props;
    const { allEvents: events, startTime, stopTime, cb } = timerInfo;
    const callCbAndDone = () => {
      if (cb) {
        cb();
      }
      setDone(true);
    }

    // if (!startTime) {
    //   if (cb) {
    //     cb();
    //   }
    //   setDone(true);
    //   return;
    // }

    const allEvents = [...events];
    const now = moment();
    if (stopTime === null) {
      allEvents.push({ event: 'unmount', timestamp: now.clone(), action: 'STOP_TIMER' })
    }
    const nonIdleTime = calculateTotalTime(allEvents);
    const endTime = stopTime || now.clone();
    const eventTypes = getUniqueInteractions(timerInfo);
    const totalTime = endTime - (startTime || now.clone());
    const idleTime = Math.round((totalTime - nonIdleTime) / 1000);

    Mixpanel.track(
      'info',
      'TimerClaimedContainer',
      'calculateTimerInfo',
      {
        patientId,
        timerId,
        events: eventTypes.map((v) => EVENTS[v] ? EVENTS[v].text : v),
        idleTime,
        nonIdleTime,
        totalTime,
        startTime: startTime ? moment(startTime).format('YYYY-MM-DD HH:mm:ss') : null,
        stopTime: stopTime ? moment(stopTime).format('YYYY-MM-DD HH:mm:ss') : null,
        endTime: endTime.format('YYYY-MM-DD HH:mm:ss'),
        isEnrolled,
        hasConsent,
        loadingEnrolledProgramAndConsent,
      }
    );

    if (totalTime === 0 || patientId === null) {
      callCbAndDone();
      return;
    }

    const onDone = () => {
      closeModal();
      callCbAndDone();
      // resetTimer();
    }

    if (!eventTypes || eventTypes.length === 0) {
      callCbAndDone();
      return;
    }
    if (idleTime * 1000 > IDLE_TIME_THRESHOLD_TIME_IN_MS && !loadingEnrolledProgramAndConsent && isEnrolled && hasConsent) {
      this.showIdleTimeModal(totalTime, nonIdleTime, eventTypes, startTime, endTime, idleTime, onDone);
    } else {
      try {
        await sendClaimedTimeReq({
          memberId: patientId,
          events: eventTypes.map((v) => EVENTS[v] ? EVENTS[v].text : v).map(v => ({ name: v })),
          totalTime: nonIdleTime,
          startTime,
          endTime,
          idleTime: idleTime
        });
      } catch (e) {
        Mixpanel.track(
          'Encoutered Error',
          'TimerClaimedContainer',
          'sendClaimedTimeReq',
          {
            PATIENT_ID: this.props.patientId,
            errorMessage: e.message,
          }
        );
        this.handleError(e);
      }
      callCbAndDone();
      // resetTimer();
    }
  }

  handleError = (e) => {
    if (e.message.includes('Cannot read property \'consent\' of null')) {
      return;
    }
    this.props.openErrorModal(GQLHelper.formatError(e));
  }

  showIdleTimeModal = (totalTime, nonIdleTime, eventTypes, startTime, endTime, idleTime, onDone) => {
    const { patientId } = this.props;
    const content = (
      <IdleTimeContainer
        defaultEvents={eventTypes}
        onDone={onDone}
        patientId={patientId}
        totalTime={totalTime}
        idleTime={idleTime}
        nonIdleTime={nonIdleTime}
        startTime={startTime}
        endTime={endTime}
        handleError={this.handleError}
      />
    );
    const modalProps = {
      isAntdModal: true,
      title: null,
      footer: null,
      width: 500,
      closable: false
    };
    this.props.openModal(content, modalProps);
  }

  activatePage = () => {
    const { timerInfo } = this.props;
    if (!timerInfo) return;

    const { isPaused } = timerInfo;
    if (isPaused) {
      this.props.resumeTimeOut();
    }

    this.startCoolDown();
  }

  startCoolDown = () => {
    const { coolDown = true } = this.props;
    if (coolDown) {
      clearTimeout(GLOBAL_COOL_DOWN);
      GLOBAL_COOL_DOWN = setTimeout(() => {
        this.props.pauseTimeOut();
      }, COOL_DOWN_IN_MS);
    }
  }

  render() {
    return <div className='timerClaimedContainer'></div>;
  }
}


const mapStateToProps = (state, ownProps) => {
  const { id, patientId } = ownProps;
  const timerId = getTimerClaimedId(id, patientId);
  return {
    timerInfo: state.timerClaimed.main[timerId],
    timerId,
    callPatientId: btoa(`accounts:${_.get(state, 'AWSConnect.lastCallPatientId')}`),
    inboundCallPatientId: btoa(`accounts:${_.get(state, 'AWSConnect.inboundAttributes.patientId.value')}`),
    callStatus: _.get(state, 'AWSConnect.contact'),
  };
}

const mapToDispatch = (dispatch, ownProps) => {
  const { id, patientId } = ownProps;
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    openModal: (content, props) => dispatch(openModal(content, props)),
    closeModal: () => dispatch(closeModal()),
    ...mapDispatchToTimerActions(dispatch, id, patientId),
  };
}

const withEnrolledPrograms = graphql(enrolledProgramAndConsent, {
  options: ownProps => {
    const memberId = ownProps.patientId;
    return {
      variables: {
        page: 1,
        count: 1,
        filters: {
          memberId,
        },
        memberId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props: ({ data }) => {
    const isEnrolled = _.get(data, 'enrolledProgramList.data[0].status') === 'STARTED';
    const hasConsent = _.get(data, 'getPatientConsent.consent', false);
    return { isEnrolled, hasConsent, refetch: data.refetch, loadingEnrolledProgramAndConsent: data.loading };
  },
});

const TimerClaimedContainer = compose(connect(mapStateToProps, mapToDispatch), withEnrolledPrograms)(TimerClaimedComponent);

export default (props) => {
  return <TimerClaimedContainer key={props.patientId} {...props} />
};
