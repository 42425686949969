import config from '../../../lib/config';
import { FILTER_MAP } from '../constants';

export const REPORTS_SERVER_URL = config.SERVER_URL + '/reports';
const getSessionToken = () => sessionStorage.getItem('authToken') || '';

const addFilters = (url, filters) => {
  let newUrl = (' ' + url).slice(1);
  const filterKeys = _.keys(filters);
  if (filterKeys.length) {
    filterKeys.forEach((k) => {
      const mappedFilterKey = FILTER_MAP[k];
      const valuesStr = _.join(filters[k], ',');
      if (mappedFilterKey && valuesStr) {
        newUrl = `${newUrl}&${mappedFilterKey}=${valuesStr}`;
      }
    });
  }
  return newUrl;
};

export const getInitialReports = (args= { }) => {
  const { page=1,pageSize=1,sortDirection='DESC', filters } = args;
  let url = `${REPORTS_SERVER_URL}/list/initial?page=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}`;
  url = addFilters(url, filters);
  return baseRequest(url);
}

export const getSummaryReports = (args= { }) => {
  const { page=1,pageSize=1,sortDirection='DESC', filters } = args;
  let url = `${REPORTS_SERVER_URL}/list/summary?page=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}`;
  url = addFilters(url, filters);
  return baseRequest(url);
}

export const getReportsCount = (type) => {
  const url = `${REPORTS_SERVER_URL}/list/${type}/count`;
  return baseRequest(url);
}

export const getDetailReports = () => {
  const url = `${REPORTS_SERVER_URL}/list/detail`;
  return baseRequest(url);
}

export const getZipReports = () => {
  const url = `${REPORTS_SERVER_URL}/list/zip`;
  return baseRequest(url);
}

export const downloadReport = (id) => {
  const url = `${REPORTS_SERVER_URL}/download/${id}`;
  return baseRequest(url, { responseType: 'blob' });
}

export const publishReport = (ids) => {
  const url = `${REPORTS_SERVER_URL}/update/published`;
  return baseRequest(url, { method: 'PUT', body: { published: true, ids } });
}

const baseRequest = (url, options = {}) => {
  const { method = 'GET', body, responseType = 'JSON' } = options;
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    if (responseType === 'blob') {
      xhttp.responseType = responseType;
    }
    xhttp.onreadystatechange = function() {
      if (+this.readyState === 4) {
        if (+this.status >= 200 && +this.status < 300) {
          const res = responseType === 'JSON' ? JSON.parse(this.responseText || '{}') : this.response;
          return resolve(res);
        } else {
          reject(this.response);
        }
      }
    };
    xhttp.open(method, url, true);
    xhttp.setRequestHeader('x-session-token', getSessionToken());
    let dataBody = null;
    if (body) {
      dataBody = JSON.stringify(body);
      xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    }
    xhttp.send(dataBody);
  });
}
