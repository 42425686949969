import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { compose, withProps, withState, withPropsOnChange, withHandlers } from 'recompose';
import moment from 'moment';
import { NUM_OF_ROWS } from '../constant/constants';
import { processDataSource, getFromToDateByViewOption } from '../helper/inventoryContainerHelper';
import loadInventoryReport from '../helper/loadInventoryReport';
import loadDataSource from '../helper/loadDataSource';
import InventoryListContainer from './InventoryListContainer';
import DateControlContainer from './DateControlContainer';

export class InventoryContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: 'Removed', // for filter radio selection, Removed is the default selection
      fetchingMore: false, // flag to determine if it is fetching more
    };
    this.todayRef = null;
    this.observer = null;
  }

  setTodayRef = ref =>  _.set(this, 'todayRef', ref);

  removeObserver = () => _.set(this, 'observer', null);

  setIntersectionObserver = ref => {
    // this function is disabled, change to manual fetchMore
    const { observer, state: { fetchingMore } } = this;

    if(ref && !observer) {
      let observer = new IntersectionObserver((entries, self) => {
        entries.forEach(entry => {
          if(entry.isIntersecting && !fetchingMore) {
            this.fetchMore(() => {
              self.unobserve(entry.target);
              this.removeObserver();
            });
          }
        })
      }, {
        // options
        threshold: 1.0, //The callback is triggered when the element is in or passes its $number % threshold
      });
      observer.observe(ref);
      _.set(this, 'observer', observer);
    }
  };

  setFilter = e => this.setState({ filter: e.target.value });

  onClickToday = e => {
    e.preventDefault();
    // parentElement.parentElement => tr node
    const todayRowNode = this.todayRef.parentElement.parentElement;
    todayRowNode.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }

  fetchMore = cb => {
    const { 
      props: { 
        dataSource, setDataSource, 
        dateRange: { fromDate }, setDateRange,
        fetchMoreInventoryData, viewOption,
        setCanFetchMore, setIsViewOptionDisabled
      }, 
    } = this;
    this.setState({ fetchingMore: true }, () => {
      setIsViewOptionDisabled(true);
      // get new date from the past
      const [newFromDate, toDate] = getFromToDateByViewOption(viewOption, fromDate);
      // start execution for fetchmore
      fetchMoreInventoryData({
        variables: {
          fromDate: newFromDate,
          toDate,
          groupBy: viewOption,
        },
        updateQuery: (prevData, { fetchMoreResult: { inventoryReport }}) => {
          setTimeout(() => {
            this.setState({ fetchingMore: false }, () => {
              let newDataSource = dataSource;
              let ableToFetchMore = inventoryReport && inventoryReport.length >= NUM_OF_ROWS /5;
              setCanFetchMore(ableToFetchMore);
              if(inventoryReport) {
                const fetched = processDataSource(inventoryReport, viewOption);
                newDataSource.pop(); // remove ghost row for fetchMore effect
                newDataSource = [...newDataSource, ..._.reverse(fetched)]; // last is ghost row for fetchMore effect
              }
              if(ableToFetchMore) {
                setDateRange({ fromDate: newFromDate, toDate });
                newDataSource.push({});
              }
              setDataSource(newDataSource);
              setIsViewOptionDisabled(false);
            });
          }, 1500);
        },
      }).catch(_ => { 
        setDataSource([]);
        setIsViewOptionDisabled(false);
      }); // prevent infinite loop if error
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={18}>
            <InventoryListContainer 
              filter={this.state.filter}
              setFilter={this.setFilter}
              onClickToday={this.onClickToday}
              hasTodayRef={this.props.hasTodayRef}
              fetchingMore={this.state.fetchingMore}
              fetchMore={this.fetchMore}
              canFetchMore={this.props.canFetchMore}
              setTodayRef={this.setTodayRef}
              setIntersectionObserver={this.setIntersectionObserver}
              sortDataSource={this.sortDataSource}
              {...this.props} 
            />
          </Col>
          <Col span={6}>
            <DateControlContainer {...this.props} removeObserver={this.removeObserver} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  withProps(props => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const organizationId = _.get(currentUser, 'selectedRole.organization.id');
    const organizationName = _.get(currentUser, 'selectedRole.organization.name');
    const memberId = _.get(currentUser, 'id');
    return { organizationId, organizationName, memberId };
  }),
  withState('dataSource', 'setDataSource', null),
  withState('totalAmounts', 'setTotalAmounts', null),
  withState('forecastAmounts', 'setForecastAmounts', null),
  withState('dateRange', 'setDateRange', { 
    fromDate: moment().subtract(1, 'week').startOf('week').startOf('day').valueOf(),  // default view data for 2 weeks from today
    toDate: moment().endOf('day').valueOf()
  }),
  withState('viewOption', 'setViewOption', 'DAY'), // don't use setViewOption, use fetchWithViewOption instead
  withState('isViewOptionDisabled', 'setIsViewOptionDisabled', false), // prevent user switch between view options very fast, data will display wrong
  withState('canFetchMore', 'setCanFetchMore', false), // flag to determine if there more data can be fetched
  withState('hasTodayRef', 'setHasTodayRef', false),
  // withPropsOnChange(['dataSource', 'totalAmounts', 'forecastAmounts', 'canFetchMore', 'hasTodayRef', 'viewOption', 'dateRange'], props => {
  //   return ({ ...props })
  // }),
  loadInventoryReport,
  withPropsOnChange(['loading', 'networkStatus'], loadDataSource),
  withHandlers({
    fetchWithViewOption: props => e => {
      // props.setIsViewOptionDisabled(true);
      const newViewOption = e.target.value;
      props.setViewOption(newViewOption);
      let [fromDate, toDate] = getFromToDateByViewOption(newViewOption, moment().endOf('day').valueOf());
      if(e.target.rangePicked) {
        fromDate = e.target.rangePicked.fromDate;
        toDate = e.target.rangePicked.toDate;
        if(_.get(props, 'dateRange.fromDate') === fromDate && _.get(props, 'dateRange.toDate') === toDate){
          // don't need to re-render when same time is picked
          return;
        }
      }
      props.setDateRange({ fromDate, toDate });
      props.refetchInventoryData();
    }
  }),
)(InventoryContainer);