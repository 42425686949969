import { constants } from 'libModule/utils';

export default constants('ProgramNew', 'Common', [
  'SET_TABS_STEP',
  'CHANGE_PAGE',
  'PROGRAM_INFO_FORM_PAGE',
  'CAREPLAN_MAIN_PAGE',
  'CAREPLAN_VITAL_FORM_PAGE',
  'CAREPLAN_ASSESSMENT_FORM_PAGE',
  'CAREPLAN_EDUCATION_FORM_PAGE',
])
