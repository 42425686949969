import gql from 'graphql-tag';

export default gql`
  query careTeamMemberVisits($memberIds: [EID!]!, $from: Date!, $to: Date, $excludeOrgIds: [EID], $types: [VisitTypeEnum], $organizationIds: [EID]) {
    careTeamMemberVisits(
      memberIds: $memberIds,
      from: $from,
      to: $to,
      excludeOrgIds: $excludeOrgIds,
      organizationIds: $organizationIds,
      types: $types,
    ) {
      id
      visits {
        type
        id
        appointmentAt
        appointmentTo
        organization {
          id
          internalName
        }
      }
    }
  }
`;
