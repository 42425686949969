import React from 'react';
import convertor from '@ihealth/convertor/index';
import { Form, Row, Button, InputNumber, Input, Checkbox } from 'antd';
import RenderFormComponent from '../../patient/AdditionalInfo/Components/RenderFormComponent';
import {
  patientWeightRegiterValidator,
  patientHeightInputValidator,
  patientHeightInchesInputValidator,
  patientDiastolicValueInputValidator,
  patientSystolicValueInputValidator,
  patientTemperatureValueInputValidator,
  patientSpO2ValueInputValidator,
} from 'libModule/validator';
import editUser from 'modulesAll/graphql/mutation/editUser';
import Client from 'libModule/gqlClient';
import editVisit from 'graphqlModule/mutation/editVisit';
import singleVisit from '../../graphql/singleVisit';

import { convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { getMutateVariableMedicalHistory } from '../../patient/AdditionalInfo/helpers/getMutateValueHelper';
import { convertFahrenheitToCelsius } from 'libModule/utils/convertUnits';

import { updateGoalUponICDCode } from '../../careplanNew/component/helper/goalHelper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { BPNotRequiredCategory, preFilledBPCategory } from '../constant/vitalsAndBilling';
import InsuranceComponent, { getInsuranceProviderName } from '../../patient/AdditionalInfo/Components/InsuranceComponent';
import I18N from '../../I18N';
import { updatePatientComplexity } from '../../patient/main/API';
import { eventHelperCreator, EVENT_MAPS } from '../../../lib/helpers/event-helpers';
import { renderDraftSavedText } from '../helpers';
import { InsuranceCopayComponent } from '../../patient/InsuranceCopayComponent/InsuranceCopayComponent';
// import addWeightToVisit from '../API/addWeightToVisit';

const eventHelper = eventHelperCreator(EVENT_MAPS.complexity, 'icdCode');

const updateComplexity = async (props) => {
  const { complexityLevel } = props.form.getFieldsValue();
  if (complexityLevel) {
    const { level, source } = complexityLevel;
    try {
      await updatePatientComplexity({ memberId: props.userId, level, source });
      eventHelper.create();
    } catch (err) {
      console.error(err);
    }
  }
}

const parseValue = (props, isDraft) => {
  const { id: visitId, userId } = props;
  const mutatedValue = getMutateVariableMedicalHistory(props);
  let variables = DATE_HELPERS.trimAllValues(mutatedValue, '', mutatedValue);
  variables.id = userId;
  variables =  _.omit(variables, 'memberProfile.yearOfDiagnose');

  // const syncWeight = async () => {
  //   try {
  //     const res = await addWeightToVisit({
  //       visitId,
  //       value: _.get(mutatedValue, 'memberProfile.weight.value'),
  //       unit: _.get(mutatedValue, 'memberProfile.weight.unit'),
  //     });
  //     if (!res || res.code !== 200) {
  //       throw new Error(_.get(res, 'msg') || '');
  //     }
  //   } catch (error) {
  //     message.error(`Failed to sync weight, ${error.message}`);
  //   }
  // }  

  const update = async () => {
    await Client.mutate({
      mutation: editVisit,
      variables: parseVisitValue(props, isDraft),
      fetchPolicy: 'no-cache'
    })
  };

  update();
  updateComplexity(props);
  // syncWeight();

  return variables;

};

// verify non-empty inputs recursively before saving, **null inputs will pass
// if more than 1 input, all inputs must be valid to pass verification
const verifyInput = (values) => {
  if (!values) return true; // there is no more input
  const { systolic, diastolic, temperature, spo2 } = values || {};
  const getNextValues = (key) => _.omit(values, key);
  const getFlag = (res) => res === undefined ? true : false; // res = void || error message

  if (systolic) {
    return getFlag(patientSystolicValueInputValidator(systolic)) && verifyInput(getNextValues('systolic'));
  }
  if (diastolic) {
    return getFlag(patientSystolicValueInputValidator(diastolic)) && verifyInput(getNextValues('diastolic'));
  }
  if (temperature) {
    return getFlag(patientTemperatureValueInputValidator(temperature)) && verifyInput(getNextValues('temperature'));
  }
  if (spo2) {
    return getFlag(patientSpO2ValueInputValidator(spo2)) && verifyInput(getNextValues('spo2'));
  }
  return true;
}

const parseVisitValue = (props, isDraft) =>{
  // reverted from SC-6793, 0405-only revert the behavioral goal, so bring back the function back
  // update goals only when patient it enrolled
  if(!isDraft && _.get(props, 'program.id')){ // skip update when it's draft
    updateGoalUponICDCode(props)
    .catch(e => {
        console.error(e);
        props.openErrorModal(`Goals are not updated: ${e.message}`);
    });
  }
  const data = props.form.getFieldsValue();
  const { weight, systolic, diastolic, temperature, spo2, heartBeat, declineToCheckVital, unableToCheckVital } = data;

  const variables = {
    id: _.get(props, 'visit.id')
  };

  if(temperature && verifyInput({ temperature })){
    variables.temperature = {
      value: convertFahrenheitToCelsius(temperature),
      unit: 'C'
    };
  } else if (temperature === null) variables.temperature = null;

  if(spo2 && verifyInput({ spo2 })){
    variables.spo2 = {
      value: spo2,
      unit: '%'
    };
  } else if (spo2 === null) variables.spo2 = null;

  let bpMeasurement = {};

  if(heartBeat){
    bpMeasurement.heart_rate = {
      value: heartBeat,
      unit: 'beats/min'
    };
  }

// these 2 values must come in pair
  // if only one is given, it will save NONE
  if(systolic && diastolic && verifyInput({ systolic, diastolic })){
    bpMeasurement.systolic_blood_pressure = {
      value: systolic,
      unit: 'mmHg'
    };
    bpMeasurement.diastolic_blood_pressure = {
      value: diastolic,
      unit: 'mmHg'
    }
  }

  variables.bpMeasurement = bpMeasurement;

  if (typeof declineToCheckVital === 'boolean'){
    variables.declineToCheckVital = declineToCheckVital;
  }

  if (typeof unableToCheckVital === 'boolean') {
    variables.unableToCheckVital = unableToCheckVital;
  }

  let weightInKg = null;
  if (typeof weight == 'number' && weight >= 25 && weight < 1000) {
    const convertedWeight = convertor.HS(weight, 'lbs', 'kg');
    weightInKg = { value: convertedWeight, unit: I18N.get('measure.units_weight_kg') };
  }

  variables.weight = weightInKg;

  return variables;
}

const vitalsAndBillingConfig = props => {
  const { visit, form, lastMeasurement } = props;
  const { category } = visit;
  const { getFieldValue } = form;

  // get the filled value first, if not, then props value
  const formValueDismiss =  getFieldValue('declineToCheckVital') || getFieldValue('unableToCheckVital');
  const propsValueDismiss = _.get(props, 'visit.declineToCheckVital')||_.get(props, 'visit.unableToCheckVital');
  // dismiss the requirement for virtual visits
  const dismissRequirement = _.includes(BPNotRequiredCategory,category) ? true : formValueDismiss !== undefined ? formValueDismiss : propsValueDismiss;
  return [[
    {
      key: 'height',
      label: 'Height (ft)',
      path: 'profile.height.value',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 5 },
      span: 2,
      min: 0,
      max: 9,
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientHeightInputValidator(value, 'height'))
        },
        { required: true }
      ],
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: (height, format) => {
        const heightInInches = convertor.height(height, 'cm', 'inch', 0);
        return heightInInches
          ? convertInchesToFeet(heightInInches, 'cm', 'inch', 0)
          : '';
      }
    },
    {
      key: 'height_inches',
      label: `(in)`,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 15 },
      path: 'profile.height.value',
      span: 2,
      min: 0,
      max: 11,
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientHeightInchesInputValidator(value, 'height'))
        }
      ],
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: height => {
        const heightInInches = convertor.height(height, 'cm', 'inch', 0);

        return height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
      }
    },
    {
      key: 'weight',
      label: 'Weight (lbs)',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 50 },
      path: 'profile.weight.value',
      span: 2,
      rules: [
        {
          validator: (rule, value, callback) =>
            callback(patientWeightRegiterValidator(value, 'weight'))
        },
        // { required: true }
      ],
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: weight =>
        weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
      // formatValue: () => {
      //   const prevSavedValue = _.get(visit, 'details.weight');
      //   let weight = prevSavedValue;
      //   if (!_.get(weight, 'value')) {
      //     // prefilled with patient profile weight
      //     weight = _.get(props, 'user.profile.weight');
      //   }
      //   let weightValue = _.get(weight, 'value');
      //   // weight from server is in kg => convert from kg to lbs
      //   weightValue = weightValue ? convertor.HS(weightValue, 'kg', 'lbs', 0) : '';
      //   return weightValue;
      // }
    },
    {
      key: 'systolic',
      label: 'BP (mmHg)',
      span: 3,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 5 },
      formatValue: () => {
        // only prefill device-input reading
        // Inclinic: do not prefill
        // Vitrual: if home reading exists from last 8 hours before visit start, prefill
        if( _.includes(preFilledBPCategory,category)&& lastMeasurement && lastMeasurement.length > 0 && !_.get(lastMeasurement, '0.manualInput')) {
          return _.get(lastMeasurement, '0.systolic_blood_pressure.value');
        }
        return _.get(props, 'visit.details.bpMeasurement.systolic_blood_pressure.value');
      },
      rules: () => {
        const diastolic = getFieldValue('diastolic');
        if( dismissRequirement ){
          return [{
            validator: (rule, value, callback) => {
              if(value || diastolic) {
                callback(patientSystolicValueInputValidator(value, 'systolic'));
              }
              callback();
            }
          }];
        }

        return [{
          required: !dismissRequirement,
          validator: (rule, value, callback) => {
            callback(patientSystolicValueInputValidator(value, 'systolic'));
          }
        }];
      },
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
    },
    {
      key: 'none',
      label: '',
      span: 1,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { paddingTop: 30 },
      inputContent: () => <div>/</div>
    },
    {
      key: 'diastolic',
      label: `  `,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginLeft: '-25px', marginRight: 15 },
      span: 2,
      rules: () => {
        const systolic = getFieldValue('systolic');
        if( dismissRequirement ){
          // if either box is checked, don't require the diastolic
          return [{
            validator: (rule, value, callback) =>{
              if(value || systolic) {
                callback(patientDiastolicValueInputValidator(value, 'diastolic'));
              }
              callback();
            }
          }]
        }

        return [{
          validator: (rule, value, callback) =>{
            callback(patientDiastolicValueInputValidator(value, 'diastolic'));
          }
        }];
      },
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
      formatValue: () => {
        // only prefill device-input reading
        // Inclinic: do not prefill
        // Vitrual: if home reading exists from last 8 hours before visit start, prefill
        if( _.includes(preFilledBPCategory,category) && lastMeasurement && lastMeasurement.length > 0 && !_.get(lastMeasurement, '0.manualInput')) {
          return _.get(lastMeasurement, '0.diastolic_blood_pressure.value');
        }
        return _.get(
          props,
          'visit.details.bpMeasurement.diastolic_blood_pressure.value'
        )
      }
    },
    {
      key: 'heartBeat',
      label: `Heart Rate (BPM)`,
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 50 },
      span: 3,
      formatValue: () =>
        _.get(props, 'visit.details.bpMeasurement.heart_rate.value') == 0
          ? null
          : _.get(props, 'visit.details.bpMeasurement.heart_rate.value'),
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />,
    },
    {
      key: 'temperature',
      label: 'Temperature (F)',
      path: 'visit.details.temperature.value',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 15 },
      span: 3,
      rules: [{
        validator: (rule, value, callback) => {
          callback(patientTemperatureValueInputValidator(value));
        }
      }],
      formatValue: () => {
        return _.get(props, 'visit.details.temperature.value') ?
        (_.get(props, 'visit.details.temperature.unit') == 'C'
          ? convertor.temperature(_.get(props, 'visit.details.temperature.value'), 'c', 'f', 1)
          : _.get(props, 'visit.details.temperature.value'))
        : null
      },
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />
    },
    {
      key: 'spo2',
      label: 'SpO2 (%)',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colStyle: { marginRight: 15 },
      span: 2,
      rules: [{
        validator: (rule, value, callback) => {
          callback(patientSpO2ValueInputValidator(value));
        }
      }],
      formatValue: () => _.get(props, 'visit.details.spo2.value') == 0 ? null : _.get(props, 'visit.details.spo2.value'),
      inputContent: ({ disabled }) => <InputNumber disabled={disabled} />
    },
  ],
  [
    {
      key: 'declineToCheckVital',
      span: 24,
      style: { margin: 'unset'},
      itemClassName: 'no-vital-reading-checkbox',
      valuePropName: 'checked',
      formatValue: () => _.get(props, 'visit.declineToCheckVital'),
      inputContent: ({ disabled }) => <Checkbox disabled={disabled}>Patient verbally declines to check one or more vitals. Vitals obtained, if any, are noted above.</Checkbox>
    }
  ],
  [
    {
      key: 'unableToCheckVital',
      span: 24,
      itemClassName: 'no-vital-reading-checkbox',
      valuePropName: 'checked',
      formatValue: () => _.get(props, 'visit.unableToCheckVital'),
      inputContent: ({ disabled }) => <Checkbox disabled={disabled}>Patient is unable to check one or more vitals. Vitals obtained, if any, are noted above.</Checkbox>
    }
  ],
  [
    {
      key: 'insuranceInformation',
      label: '',
      span: 24,
      style: { margin: 0 },
      inputContent:() => <h4 style={{ fontWeight: 400, }}>Insurance</h4>
    }
  ],
  [
    {
      key: 'insuranceProvider',
      label: I18N.get('patientlist.manualInputForm.insuranceProvider'),
      labelCol: { span: 24 },
      wrapperCol: { span: 16 },
      span: 12,
      style: { marginBottom: 3 },
      // rules: [
      //   {
      //     validator: (rule, value, callback) => {
      //       if (typeof value !== 'string' || !value.trim() || value.trim() === 'null') {
      //         callback('Primary Insurance Provider is invalid');
      //       }
      //       callback();
      //     }
      //   },
      //   { required: true}],
      path: 'profile.insuranceProvider',
      hide: localThis => false,
      inputContent: () => <Input/>,
      rules: [
        { required: true, message: 'Insurance provider is required, type "N/A" if not available'}
      ]
    },
    {
      key: 'insuranceProvider2',
      label: I18N.get('patientlist.manualInputForm.insuranceProvider2'),
      labelCol: { span: 24 },
      wrapperCol: { span: 16 },
      span: 12,
      style: { marginBottom: 3 },
      path: 'profile.insuranceProvider2',
      hide: localThis => false,
      inputContent: () => <Input/>,
      rules: [
        { required: true, message: 'Insurance provider is required, type "N/A" if not available'}
      ]
    }
  ],
  // [
  //   {
  //     key: 'previousInsuranceNote',
  //     label: '',
  //     span: 24,
  //     style: { marginBottom: 6 },
  //     hide: localThis => shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), true) && 
  //                       shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), false),
  //     inputContent:() => <span>You cannot edit the above field(s). Please transfer the insurance information to the table below.</span>
  //   }
  // ],
  [
    {
      key: 'insuranceProviderInputs',
      label: '',
      labelCol: { span: 24 },
      span: 24,
      inputContent: (props) => <div>
        <InsuranceComponent 
          {...props} 
          insuranceProviders={_.get(props, 'localThis.props.user.profile.insuranceProviders') || []}
          rules={[
            {
              validator: (rule, value, callback) => {
                const primaryInsuranceName = getInsuranceProviderName(_.get(props, 'localThis.props.user.profile'), true);
                if((!value || !value.length) && !primaryInsuranceName.trim()) {
                  return callback('Primary Insurance Provider is invalid');
                }
                callback();
              }
            },
          ]}
        />
      </div>
    }
  ],
  [
    {
      key: 'insuranceProviderCopay',
      label: '',
      labelCol: { span: 24 },
      span: 24,
      inputContent: (props) => (
        <InsuranceCopayComponent 
          patientId={_.get(props, 'localThis.props.user.id')}
          {...props}
        />
      ),
    }
  ]
]};

let VitalsAndBillingComponent = class extends React.Component {

  saveEdit = async (completeMode, setEditMode, msg) => {
    const { resetDraftState, toNextStep, form, setIsEditMode, id: visitId } = this.props;
    const {validateFieldsAndScroll } = form;
    let refetchQueries = completeMode ? null : [
      {
        query: singleVisit,
        variables: { id: visitId },
        fetchPolicy: 'network-only'
      }
    ];
    let toValidateFields = ['systolic', 'diastolic', 'temperature', 'spo2', 'heartBeat'];


    await validateFieldsAndScroll(toValidateFields, async (error ,fields) =>{
      const {diastolic, systolic, } = fields;
      let validateError = (diastolic && systolic) ? error : !(!diastolic && !systolic);
      if(!validateError){
        await Client.mutate({
          mutation: editUser,
          variables: parseValue(this.props),
          fetchPolicy: 'no-cache',
          refetchQueries
        }).then(res => {
          completeMode ? this.props.afterSubmitSucc() : this.props.afterSaveDraftSucc();
          // leaveAndSaveWorkTask('VisitBilling', _.get(this.props, 'visit.id'), 'Vitals & Billing draft is Saved', () => resetDraftState());
        })
      }
    })
  };
  handleOnClickSaveDraft = () => this.saveEdit(false, true, 'Vitals And Billing draft is Saved');

  handleOnClick = () => {
    const { form, title, submitButtonText } = this.props;
    let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
    Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
    form.validateFieldsAndScroll((error) => {
      if (!error) {
        this.saveEdit(true, true, 'Vitals And Billing is Saved');
      }
    });
  }

  // componentWillUnmount() {
  //   const { props } = this;
  //   const { setIsEditMode, isEditMode } = props;
  //   if (isEditMode) {
  //     setIsEditMode(false);
  //   }
  // }

  render() {
    const { props, handleOnClick, handleOnClickSaveDraft } = this;
    const { form, user, isEditMode, disabled, visitType, enrolledProgram } = props;

    return (
      <div>
        <div className='fix-header charting-header'>
          <div className='fixHeaderTitle'>
            Vitals & Billing
            {renderDraftSavedText()}
          </div>
        </div>
        <Row className='scrollable-content'>
          <RenderFormComponent
            user={user}
            config={vitalsAndBillingConfig(props)}
            disabled={disabled}
            renderPicker={true}
            form={form}
            handleEnableEditMode={() => {}}
            program={enrolledProgram}
          />
        </Row>
        <Row className='fix-footer' style={{ float: 'right', paddingTop: 10 }}>
          {isEditMode ? (
            <Button
              onClick={() => handleOnClickSaveDraft()}
              disabled={disabled}
              className='saveDraftBtn'
            >
              Save Draft
            </Button>
          ) : (
            ''
          )}
          <Button
            onClick={() => handleOnClick()}
            type={'primary'}
            disabled={disabled}
          >
            {this.props.submitButtonText || 'Complete'}
          </Button>
        </Row>
      </div>
    );
  }
};

VitalsAndBillingComponent = Form.create({
  onFieldsChange(props, fields) {
    // const isCompleted =
    //   _.get(props, 'visit.maWorkingList.VisitBilling') == 'COMPLETED';
    const { childProps, setChildProps, isEditMode, setIsEditMode, id: visitId } = props;

    if (!childProps) {
      const refetchQueries = [
        {
          query: singleVisit,
          variables: { id: visitId },
          fetchPolicy: 'network-only'
        }
      ];

      const saveDraftParseValue = props => parseValue(props, true);
      setChildProps(
          props,
          saveDraftParseValue,
          editUser,
          refetchQueries,
          'Vitals And Billing'
      );
    }
    // if (!isEditMode) {
    //   setIsEditMode(true);
    // }
    // props.onTouch();
    // if (isCompleted) {
    //   leaveAndSaveWorkTask('VisitBilling', null, props);
    // }
  },
  onValuesChange(props) {
    props.onTouch();
  }
})(VitalsAndBillingComponent);

export default VitalsAndBillingComponent;