import { combineReducers } from 'redux'
import ProviderNew from 'providerModule/ProviderNew/ProviderNewDuck'
import ProviderEdit from 'providerModule/ProviderEdit/ProviderEditDuck'

const reducer = combineReducers({
  ProviderNew,
  ProviderEdit
})
export default {
  provider: reducer
}
