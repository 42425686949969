import { Input } from 'antd';
import {
    React, IHTab, IHPanel, IHButton, BaseComponent, moment, _, message
} from 'ihcomponent';
import I18N from 'modulesAll/I18N';

const CarePlanField = ({props,state,field,carePlanConfig,handleOnFocus,setInputFieldDisabled,onChange, draft})=>{
    const isUpdated = true;
    return  <div>
                <p className="care-plan-fields"><b>{carePlanConfig[`${field}`].title}</b>
                    {
                        state[`${field}`].init || props[`${field}`] || state[`${field}`].onFocus ?
                        <span style={{float:'right'}}>
                          <IHButton disabled={state[`${field}`].isBtnDisabled}
                                    className={state[`${field}`].disabled ? 'care-plan-edit-btn' : 'care-plan-done-btn'}
                                    size='exsmall'
                                    onClick={ () => setInputFieldDisabled(field) }>
                            { state[`${field}`].disabled ? 'Edit' : 'Done' }
                          </IHButton>
                        </span> : ''
                    }
                </p>
                <Input.TextArea disabled={state[`${field}`].disabled}
                                defaultValue={state[`${field}`].init ? state[`${field}`].init : I18N.get(`Program.newCarePlan.${field}`)}
                                placeholder="Type here" autosize={{ minRows: 3 }}
                                onChange={ e => {
                                    onChange(carePlanConfig[field].key,e.target.value,isUpdated);
                                    draft(carePlanConfig[field].key,e.target.value,isUpdated);
                                }}
                                onFocus={ ()=>handleOnFocus(field) }
                                ref={state[`${field}`].textInput}
                />
                
            </div>
}



export default  CarePlanField;
