import gql from 'graphql-tag';
import AssessmentFilled from './schema/AssessmentFilled';

export default gql `
  mutation updateAssessmentFilled (
    $id: EID!
    $date: Date!
    $timezone: String!
    $submit: Boolean
    $questionResults: [ InputAssessmentFilledQuestion ]
  ) {
    updateAssessmentFilled (
      id: $id
      date: $date
      timezone: $timezone
      submit: $submit
      questionResults: $questionResults
    ) {
      ${AssessmentFilled}
    }
  }
`;
