import { compose, graphql } from 'react-apollo';
import { patientGQL } from 'modulesAll/graphql/user'
import { getPESStatements } from '../../graphql/getPESStatements';
import PrintCarePlanComponent from '../component/PrintCarePlanComponent';

const userData = graphql(patientGQL, {
  options: (ownProps) => {
    const id = _.get(ownProps, 'enrolledProgram.user.id');
    return {
      variables: {
        id
      },
    }
  },
  props: ({ data }) => {
    const { loading, user } = data;
    if (loading) {
      return {
        loading
      }
    }
    return {
      user
    }
  }
});

const fetchUserPESStatements = graphql(getPESStatements, {
  options: (ownProps) => ({
    variables: {
      memberId: _.get(ownProps, 'enrolledProgram.user.id'),
      visitId: _.get(ownProps, 'enrolledProgram.visit.id')
    },
    fetchPolicy: 'network-only'
 }),
  props: ({ ownProps, data }) => {
    if(data.error) {
      console.error('Failed to fetch user PES Statements ', data.error);
    if(ownProps.openErrorModal) 
      ownProps.openErrorModal(`Failed to retrieve PES Statement(s)`);
    }
    return {
      loadingUserPESStatements: data.loading,
      userPESStatements: _.get(data, 'getPESStatements', []),
      refetchUserPESStatements: data.refetch
    }
  }
});

export default compose(userData, fetchUserPESStatements)(PrintCarePlanComponent)
