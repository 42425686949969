import createPostIt from '../../graphql/mutation/createPostIt';
import PostItList from '../../graphql/postItList';
import Client from 'libModule/gqlClient';

const createPostItMutation =(variables)=> Client.mutate({
    mutation: createPostIt,
    variables
})
const queryPostItList = (variables)=>Client.query({
    query:PostItList,
    fetchPolicy:'network-only',
    variables
})
export default {
    createPostItMutation,
    queryPostItList
}


