import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import I18N from '../../I18N';

import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import CreateNewTaskContainer from '../containers/CreateNewTaskContainer';

import '../style/taskStar.scss';

class PatientTaskStarComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showModal: false
    }
  }

  setModalData = data => this.setState(data);

  render(){
    const { 
      user, refetchUser, currentProgramId, overlayClassName, onInteraction
    } = this.props;
    const { showModal } = this.state;
    const hasTasks = _.get(user, 'taskAssignmentCount', 0) > 0;
    const memberId = _.get(user, 'id');
    const patientName = _.get(user, 'profile.fullName');

    let tooltipMsg = I18N.get(`taskAssignment.patient.${hasTasks ? 'viewTask' : 'addTask'}`);
    let starText = hasTasks ? 'Tasks' : '';
    let starIconClassName = `fa fa-star${hasTasks ? '' : '-o'}`;

    const openModal = () => {
      Mixpanel.track('clicked', 'task tag', 'patient profile page');
      this.setModalData({ showModal: true });
    };

    return <div style={{ marginTop: -1 }}>
      <Tooltip
        overlayClassName={overlayClassName || ''}
        title={tooltipMsg}
        placement='right'
      >
        <a onClick={openModal} className='v-patient-task-star'>
          <i className={starIconClassName} /> {starText}
        </a>
      </Tooltip>
      {
        showModal &&
        <CreateNewTaskContainer
          setModalData={this.setModalData}
          modalData={{
            memberId,
            patientName
          }}
          refetchUser={refetchUser}
          memberId={memberId} 
          currentProgramId={currentProgramId}
          renderBody
          onInteraction={onInteraction}
        />
      }
    </div>;
  }
}

PatientTaskStarComponent.propTypes = {
  user: PropTypes.object.isRequired,
  refetchUser: PropTypes.func.isRequired,
  currentProgramId: PropTypes.string,
  overlayClassName: PropTypes.string
};

export default PatientTaskStarComponent;

