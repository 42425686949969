import * as R from 'ramda';

const trace = (label) => R.tap((x)=>console.log(label, x));
// retrieve from DB and create dict by templateId
const mkDict = (keyCol, arr) => R.zipObj(R.pluck(keyCol, arr), arr);

// number each testItem with a unique 'key'
const addItemsIndex = R.map(R.over(R.lensProp('items'), R.addIndex(R.map)((testItem, i) => R.merge(testItem, { id: 'test'+i, key: 'test'+i }))));

// include testItem count for each template
const addItemsCount = R.map(testTemplate => R.assoc('count', R.length(testTemplate.items), testTemplate));

export { trace, mkDict, addItemsIndex, addItemsCount };
