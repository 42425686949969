import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { IHBaseFormWithRow, IHButton, message, IHLoading, _ } from 'ihcomponent'
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'

import createTeamGQL from 'modulesAll/graphql/createTeam'

import actions from '../actions/NewCareTeamActions'
import { modalAction } from 'modulesAll/common/actions'
import DoctorTable from './DoctorTable'
import ManagerTable from './ManagerTable'
import { getFormProps } from '../components/formData/NewCareTeamForm'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'careteam/createCareTeam/containers/NewCareTeamContainer'

  componentWillMount() {
    setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))
  }

  routerWillLeave() {
    const { openConfirmModal } = this.props
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      const Yes = {
        label: 'Confirm',
        handler: ()=>{
          resolve(true)
        },
        closeModal: true
      }
      const No = {
        label: 'Cancel',
        handler: () => {
          resolve(false)
        }
      }
      const modalProps = {
        Yes,
        No,
        title: 'Leave This Page?',
        content: 'Please confirm if you would like to leave this page'
      }
      openConfirmModal(modalProps)
    })
  }
  componentWillUnmount() {
    this.props.resetForm()
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-careteam-new-team'>
        <div className='careteam-new-team'>
          {body}
          {/*hide dcotor and manger based on jira-2152*/}
          {/*<DoctorTable ref="c_doctor" />*/}
          {/*<ManagerTable ref="c_manager" />*/}
          {footer}
        </div>
      </div>
    )
  }

  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    const formProps = getFormProps({ onFieldsChange, fieldsValue })
    return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }

  renderFooter() {
    const { isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick: (e)=>{
          goPath('/careteams')
        },
        bsStyle: 'default',
        disabled: false,
        label: 'Cancel'
      },
      done: {
        onClick : ()=>{
          this.createTeam()
        },
        bsStyle: 'primary',
        disabled: isSubmitting,
        type: 'submit',
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    }
    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} >
          {isSubmitting ? <IHLoading /> : 'Create'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  async createTeam(){
    const { mutate, fieldsValue, handleFormSaved, resetForm, openSuccessModal, openErrorModal, setSubmitting } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }
    const variables = this.getMutateVariable(formValue)

    try{
      setSubmitting(true)
      await handleFormSaved()

      const res = await mutate({variables})
      createAuditLog({
        action: I18N.get('auditLog.admin.careTeam.create'),
        details: variables,
        request: variables,
        response: res
      })

      if (res.data.createTeam) {
        resetForm()

        const modalTitle = 'Care Team Creation Successful'
        const modalContent = `Care Team has been created!`
        openSuccessModal(modalTitle, modalContent)
        goPath('/careteams')
      }
    }catch(err){
      console.log('there was an error in createTeam ', err)
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action:I18N.get('auditLog.admin.careTeam.create'),
        details: variables,
        request: variables,
        response: err,
        success: false
      })
    }finally{
      setSubmitting(false)
    }
  }

  getMutateVariable(data) {
    const variables = {
      name : data.name,
      description : data.description,
      email: _.isEmpty(data.email) ? null : data.email,
      healthCondition : data.healthCondition,
      contactName:data.contactName,
      phone: data.number ? {
        countryCode: data.countryCode.replace('+', ''),
        number: data.number,
        type: 'MOBILE'
      } : null,
      forwardingPhone: data.forwardingPhone ? {
          countryCode: data.forwardingPhoneCountryCode,
          number:data.forwardingPhone,
          type:'MOBILE'
      }:null
    }

    variables.memberId = _.uniq(this.props.managers.concat(this.props.doctors))
    variables.primary =  _.uniq(this.props.p_managers.concat(this.props.p_doctors))

    return variables
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(createTeamGQL)(Container)

const mapState = (state, ownProps) => {
  return {
    ...state.Careteam.CareTeamNew,
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    resetForm: () => dispatch(actions.resetForm()),
    openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  openErrorModal: PropTypes.func,
  onFieldsChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  fieldsValue: PropTypes.object,
  resetForm: PropTypes.func,
  openConfirmModal: PropTypes.func,
  isFormSaved: PropTypes.bool,
  p_managers: PropTypes.array,
  managers: PropTypes.array,
  p_doctors: PropTypes.array,
  doctors: PropTypes.array,
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
