import React from 'react';
import { IHLoading } from 'ihcomponent';
import { connect }from 'react-redux';
import moment from 'moment';
import FoodDiaryModule from './FoodDiaryModule';
import monthMenuMap from '../../utils/constants/monthMenuMap';
import actions from "../actions";
import { Table,Button,Rate,Select,Icon,Modal } from 'antd';
import "antd/lib/rate/style/index.css";
import '../styles/index.scss';

const Option = Select.Option;
const FoodDiaryLogBook = class extends React.Component {
    static displayName = 'FoodDiaryLogBook';

    constructor(props) {
        super(props);
        this.state = {
            showAddFoodLogModal:false
        }
    }

    componentDidUpdate(prevProps) {
        const { foodLogList } = this.props;
        if (prevProps.foodLogList !== foodLogList) {
            this.setState({ rows: this.getRows() });
        }
    }

    renderStar(startCount,isInChat){
        if(!isNaN(startCount)&&startCount!=0) {

            return <div>
                      <label >Rating: </label>
                      <Rate  className='tableStar'
                             value={startCount}
                             disabled
                             className='foodDiaryLogBook'
                             style={{ fontSize: isInChat ? 15 : 20,marginLeft: 5 }}>
                      </Rate>
                   </div>
        }
    }
    renderMonthDropDown(monthMenuMap){
        const value = _.get(this,'props.value');
        const month = monthMenuMap[value].value;
        const menu = _.map(monthMenuMap,(data,index)=> {
            return <Option key={index} value={index}>
                {data.value}
            </Option>
        });

        return <Select style={{ width: 120 }}
                       defaultValue={month}
                       value={month}
                       dropdownClassName='monthDropDown'
                       className='monthDropDown'
                       onChange={(month)=>this.props.changeMonth(month)}
               >
                  {menu}
               </Select>
    }

    renderYearDropDown(){
        let curYear = moment().year();
        let lastYear = curYear - 1;
        let yearBeforeLast = lastYear - 1;
        const yearList = [{
                            key: yearBeforeLast,
                            value: yearBeforeLast
                          },
                          {
                            key: lastYear,
                            value: lastYear
                          },
                          {
                            key: curYear,
                            value: curYear
                          }
                        ]
        //need more detailed requirements on this yearlist
        const menu = _.map(yearList,(key,index)=>{
                        return <Option value={key.key} key={index}>
                                {key.value}
                               </Option>
                    });
        const year = _.get(this,'props.year');
        return <Select  style={{ width: 100,marginLeft:20 }}
                        onChange={(year)=>this.props.changeYear(year)}
                        value = { year }
                        dropdownClassName='yearDropDown'
                        className='yearDropDown'
               >
                 {menu}
               </Select>
    }

    renderNextPreFooter(){
        const month = this.props.value;

        const disableNext = month+1>=12;
        const disablePre = month-1<0;
        return <div style={{display:'flex',alignItems:'center'}}>
                <Button className='ant-pagination foodDiaryPre'  onClick={()=>this.props.changePre()} disabled={disablePre}>
                    <Icon type="left" />
                </Button>
                    { this.renderMonthDropDown(monthMenuMap) }
                <Button className='ant-pagination foodDiaryNext'  onClick={()=>this.props.changeNext()} disabled={disableNext}>
                    <Icon type="right" />
                </Button>
                {this.renderYearDropDown()}
               </div>
    }


    renderTableHeader(){
        const style = {
            height: '46px',
            padding: '0 12px',
            position: 'relative',
            display:'flex',
            alignItems:'center',
        };


        return <div style = { style }
                    className='ih-dd foodLogHeaderContainer'>
            { this.renderNextPreFooter() }
        </div>
    }

    getRows = () => {
        const { selectedDate, selectedMeal } = this.state;
        const { foodLogList } = this.props;
        if (selectedDate && selectedMeal) {
            const data = _.get(foodLogList,'data');
            const sortedData = _.groupBy(data,(d)=>moment(d.createdAt).format('MM/DD/YYYY'));
            return _.filter(sortedData[selectedDate],(d)=>d.mealType==selectedMeal.toUpperCase());
        }
    }

    renderTable(){
        const { loading,foodLogList,isInChat } = this.props;
        const data = _.get(foodLogList,'data');
        let sortedData = _.groupBy(data,(d)=>moment(d.createdAt).format('MM/DD/YYYY'));
        const allDates = Object.keys(sortedData);
        let inputData = [];
        for(let i=0;i<allDates.length;i++){
            const date = allDates[i];
            const breakfast = _.filter(sortedData[date],(d)=>d.mealType=='BREAKFAST');
            const lunch = _.filter(sortedData[date],(d)=>d.mealType=='LUNCH');
            const dinner = _.filter(sortedData[date],(d)=>d.mealType=='DINNER');
            const snack = _.filter(sortedData[date],(d)=>d.mealType=='SNACK');
            inputData.push({
                date:allDates[i],
                breakfast:breakfast,
                lunch:lunch,
                dinner:dinner,
                snack:snack
            })
        }
        const mealTypeMap = [
            { key:'Breakfast',value:'breakfast'},
            { key:'Lunch',value:'lunch'},
            { key:'Dinner',value:'dinner'},
            { key:'Snack',value:'snack'}
        ]

        const columns = [
            {
                title: 'Date',
                key: 'date',
                className:'foodDiaryCell',
                dataIndex: 'date',
                render:(k,v)=>{
                    const weekDayAbbr = moment(k).format('dddd').slice(0,3);
                    return <div className='dateCell'>
                            <p>{weekDayAbbr}</p>
                            {k}
                           </div>
                }
            }
        ]
        const postedTime = (time,startCount,imgCount)=>{
            if(time){
                const imgCountSpan = `${imgCount} items`;
                const content = imgCount >1 ? (isInChat ? <p> {imgCountSpan}</p>
                                                        : <span style={{float:'right'}}> {imgCountSpan}</span>)
                                            :'';

                return <div style={{textAlign:'start'}}>
                    <span style={{ fontWeight:!startCount ? 'bold':''}}>Posted: {moment(time).format('HH:mm')}</span>
                    {content}
                </div>
            }

        }

        const imgDiv = (src)=>{
            const width = isInChat ? '100px' : '140px';
            const height = isInChat ? '100px' : '140px';
            if(src) {
                return <div>
                    <img src={src} width={width} height={height} />
                </div>
            }
        }

        const renderButton = (userId,rows,startCount,selectedDate,selectedMeal)=> {
            const props = {
                showModal: true,
                showHeaderCloseButton: false,
                className: 'vsm-provider-modal',
                size: 'lg'
            }
            const { keepEventListener } = this.props;
            const onClickEvent = ()=> {
                this.setState({showAddFoodLogModal:true,userId:userId,rows:rows,selectedDate,selectedMeal});
            }

                // this.props.openModal(<FoodDiaryModule rows={rows}
                //                                       userId={userId}
                //                                       renderStar={this.renderStar}
                //                                       hidePatientName={true}
                //                                       keepEventListener = { keepEventListener }
                //                                       closeModal={this.props.closeModal}
                //                                       className='FoodDiaryModal' />,props);

            const button = !startCount ?  <span onClick={()=>onClickEvent()}
                                               style={{color:'#4278c3','fontWeight':'bold',cursor:'pointer'}}>
                                            View &gt;
                                         </span>
                                      : <button onClick={()=>onClickEvent()} className='IH-Button btn-primary'>Review</button>;
            if (!_.isEmpty(rows)) {
                return <div>
                        { button }
                       </div>
            }
        }

        // const thirdColStyle = isInChat ? { flexDirection:'column',alignItems:'center' } : { flexDirection:'',alignItems:'center' };
        for(let i=0;i<mealTypeMap.length;i++){
            const { key,value } = mealTypeMap[i];
            columns.push(
                {
                    title: key,
                    key: value,
                    className:'foodDiaryCell',
                    width:'20%',
                    render: (data) => {
                        const item = data[`${value}`]||[];
                        const imgCount = item.length;
                        const images = _.get(item[0],'images',[]);
                        const src = _.get(images,'0.link','');
                        const startCount = _.get(item[0],'rating.star');
                        const unratedCount = _.filter(item,(i)=>i.rating.star==0).length;
                        const createdAt = _.get(item[0],'createdAt');
                        const { userId }= this.props;
                        return <div style={{'display':'flex','flexDirection':'column'}} >
                            { postedTime(createdAt,startCount,imgCount) }
                            { imgDiv(src) }
                            <div style={{display:'flex', flexDirection:'column',alignItems:'center' ,width:'100%','marginTop':'10px'}}>
                                { this.renderStar(startCount,isInChat) }
                                { renderButton(userId,item,unratedCount,data.date,value) }
                            </div>
                        </div>
                    }
                })

        }
        const noDataElem = (
            <div style={{  display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'HelveticaNeue',color:'#c5c5c5' }}>
                <div>
                    <img  src="image/food diary empty state icon.svg"
                          className="no-foodLog-graphic" width='116.6px' height='56.1px'/>
                </div>
                <div style={{ fontSize: '26px',marginTop:20,marginBottom:10}}>No Food Diary Data</div>
                <div style={{fontSize:'16px',maxWidth:'405px'}}>
                    There is no food diary entry for this month
                </div>
            </div>
        );
        const LOCAL = {
            emptyText: noDataElem
        };
        return (
            <React.Fragment>
                {this.renderTableHeader()}
                <Table dataSource={inputData} columns={columns}
                       bordered
                       rowKey='date' className='foodDiaryLogBook'
                       pagination={false}
                       loading={{spinning: loading, indicator: <IHLoading/>}}
                       locale={LOCAL}
                />
            </React.Fragment>
        )

    }

    handleShowAddFoodLogModal(flag){
        this.setState({
            showAddFoodLogModal:flag
        })
    }
    renderFoodLogModal(){
        const { props } = this;
        // const props = {
        //     showModal: true,
        //     showHeaderCloseButton: false,
        //     className: 'vsm-provider-modal',
        //     size: 'lg'
        // }
        const { showAddFoodLogModal,userId,rows } = this.state;
        const { keepEventListener } = this.props;

        if(showAddFoodLogModal) {
            return <Modal visible={true}
                          className='vsm-provider-modal' footer={null}
                          destroyOnClose={true}
                          bodyStyle={{padding:0}}
                          width={1000}
                          closable={false}
                    >
                        <FoodDiaryModule rows={rows}
                                         userId={userId}
                                         renderStar={this.renderStar}
                                         hidePatientName={true}
                                         keepEventListener={keepEventListener}
                                         className='FoodDiaryModal '
                                         handleShowAddFoodLogModal={(flag) => this.handleShowAddFoodLogModal(flag)}
                                         {...props}
                        />
                   </Modal>
        }
    }

    render(){
        return <div>
                 { this.renderTable() }
                 { this.renderFoodLogModal() }
              </div>
    }
}



const mapToDispatch = (dispatch)=>{
    return {
        setRating: (id,rating)=>dispatch(actions.setRating(id,rating)),
        updateComment:(id,comments)=>dispatch(actions.updateComment(id,comments)),
        reset: (id)=>dispatch(actions.reset(id)),
    }
}

export  default  connect(null,mapToDispatch)(FoodDiaryLogBook);
