import gql from 'graphql-tag'
import { EnrolledProgram, EnrolledProgramForPatient, EnrolledProgramForVendor } from './schema/EnrolledProgram'

export default gql`
  query enrolledProgram(
    $id : EID!
  ){
    enrolledProgram(
      id : $id
    ){
      ${EnrolledProgram}
    }
  }
`

export const enrolledProgramForPatient = gql`
  query enrolledProgram(
    $id : EID!
  ){
    enrolledProgram(
      id : $id
    ){
      ${EnrolledProgramForPatient}
    }
  }
`

export const enrolledProgramForVendor = gql`
  query enrolledProgram(
    $id : EID!
  ){
    enrolledProgram(
      id : $id
    ){
      ${EnrolledProgramForVendor}
    }
  }
`


export const enrolledProgramWithDevicesHistory = gql`
  query enrolledProgram(
    $id : EID!
  ){
    enrolledProgram(
      id : $id
    ){
      devices {
        type
        model
        status
        address
        other
        returnedBy
        returnedDate
        replacedBy
        replacedDate
        receivedBy
        receivedDate
      }
      deviceHistories {
        date
        devices {
          type
          model
          status
          address
          other
          returnedBy
          returnedDate
          replacedBy
          replacedDate
          receivedBy
          receivedDate
        }
      }
    }
  }
`
