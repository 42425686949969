import {
  React, IHButton, IHSearchTable, _
} from 'ihcomponent'
import PropTypes from 'prop-types';
import { goPath, programConditionMap } from 'libModule/utils'
import { connect } from 'react-redux'
import Store from 'libModule/reduxStore'
import createTableAction from 'libModule/table/TableActions';
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import moment from 'moment'
import I18N from 'modulesAll/I18N'
import { browserHistory } from 'react-router';

//queries
import { adminProgramListWithSearch as adminProgramList } from 'graphqlModule/adminProgramList'

const tableActions = createTableAction('provider_programme_list', {
  sortFieldMap: {
    name: 'NAME',
    //activatedAt: 'ACTIVATED_AT',
    updatedAt: 'UPDATED_AT',
    modifiedAt: 'MODIFIED_AT'
  }
})

class Component extends React.Component{
  static displayName = 'program/ProgramList/containers/ListTable'

  componentWillUnmount() {
    if (!new RegExp(/^(\/programmes)/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null)
    }
  }

  render(){
    return (
      <div>
        {this.renderTable()}
      </div>
    )
  }

  getTableProps() {
    const { onTableChange, page, filter, sort, data, onTableSearch, onSearchEnter, search = '' } = this.props
    const { loading = true, adminProgramList } = data
    const dataSource = _.get(adminProgramList, 'data', [])
    const filters = _.get(adminProgramList, 'filters', []);
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        sortOrder: _.get(sort, 'field', null) === 'name' ? _.get(sort, 'order', false) : null,
      },
      {
        title: 'Health Condition',
        key: 'healthCondition',
        dataIndex: 'healthCondition',
        render: (value, row, index) => programConditionMap[value],
        filters: filters.map(key => ({text: programConditionMap[key], value: key})),
        filteredValue: _.get(filter, 'healthCondition', []),
        onFilter: (value, record) => record['healthCondition'].includes(value),
      },
      {
        title: 'Duration',
        key: 'duration',
        dataIndex: 'duration',
        render: (t, doc) => {
          return `${get(doc, 'duration.length')} ${get(doc, 'duration.calendar')}`;
        }
      },
      // {
      //   title: 'Created By',
      //   dataIndex: 'createdBy',
      //   key: 'createdBy',
      //   render: (t, doc)=>{
      //     return get(doc, 'createdBy.profile.fullName')
      //   }
      // },
      // {
      //   title: 'Created On',
      //   key: 'activatedAt',
      //   dataIndex: 'activatedAt',
      //   sorter: true,
      //   sortOrder: _.get(sort, 'field', null) === 'activatedAt' ? _.get(sort, 'order', false) : null,
      //   render: (value, row, index) => {
      //     return value && moment(value).format('MMM-DD-YYYY hh:mm a')
      //   }
      // },
      // {
      //   title: 'Last Modified By',
      //   dataIndex: 'modifiedBy',
      //   key: 'modifiedBy',
      //   render: (t, doc)=>{
      //     return get(doc, 'modifiedBy.profile.fullName')
      //   }
      // },
      {
        title: 'Last Modified On',
        key: 'modifiedAt',
        dataIndex: 'modifiedAt',
        sorter: true,
        sortOrder: _.get(sort, 'field', null) === 'modifiedAt' ? _.get(sort, 'order', false) : null,
        render: (value, row, index) => {
          return value && moment(value).format('MMM-DD-YYYY hh:mm a')
        }
      },
      {
        title : 'Actions',
        key : 'Actions',
        render : (t, doc)=>{
          const bp = {
            size: 'exsmall',
            bsStyle: 'primary',
            className: 'table-action-button'
          }
          const label = 'Details'
          return (
            <IHButton {...bp}>
              {label}
              <i className="fa fa-angle-right ih-right"></i>
            </IHButton>
          )
        }
      }
    ]

    return {
      title: `${I18N.get('keywords.Programs')} (${_.get(adminProgramList, 'pageInfo.total', 0)})`,
      inputPlaceholder: 'Search by Name',
      //initSearchValue: search ? (search.match||''):'',
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      onChange: onTableChange,
      dataSource,
      loading,
      pagination : {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(adminProgramList, 'pageInfo.total', 0),
      },
      onRow(doc){
        const id = doc.id;
        return {
          onClick: () => goPath(`/programmes/${id}`)
        }
      },
      // onSearch: (v)=>{
      //     this.props.setSearchVariables(v)
      // },
      columns,
      rowKey: 'id'
    }
  }

  renderTable(){
    const p = this.getTableProps()
    return <IHSearchTable {...p} />
  }
}

const ComponentWithData = graphql(adminProgramList, {
  options: (ownProps)=>{
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      search: {
        fields: ['NAME'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: null
    }
    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction'),
      };
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  }
})(Component)

const mapState = ({ globalTables }, ownProps)=>{
  return {
    ...globalTables.provider_programme_list
  }
}

Component.propTypes = {
  programList: PropTypes.object,
  variables: PropTypes.object,
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  refetch: PropTypes.func,
  setSearchVariables: PropTypes.func,
  loading: PropTypes.bool,
}

export default connect(mapState, () => tableActions)(ComponentWithData)
