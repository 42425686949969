import Client from '../../../../lib/gqlClient';
import moment from 'moment';
import { buildGetPatientHiatusQuery } from '../../../graphql/getHiatus';
import { buildSetPatientHiatusQuery, resumePatientHiatusQuery } from '../../../graphql/mutation/setHiatus';
import createProviderNote from '../../../graphql/createProviderNote';

import hsConstants from '../constants';
import { getSelectedCategoryValues } from '../helpers';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const setPatientHiatus = ({ variables, options = {}, dataFields = [], hiatusAction }) => {
  return Client.mutate({
    mutation: buildSetPatientHiatusQuery(dataFields),
    variables,
    ...options
  }).then(res => {
    const { id } = res;
    const { memberId, startDate, endDate, reason, note } = variables;
    const categories = getSelectedCategoryValues(variables);

    const pnVars = {
      memberId,
      category: 'PAUSE_FOLLOW_UP',
      patientHiatusStatusId: id,
      hiatusAction,
      hiatusStartDate: startDate,
      resumeDate: endDate,
      content: `${reason}\n${note}`,
      hiatusCategories: _.map(categories, c => hsConstants.categoryMap[c])
    };

    const pauseDuration = (moment(endDate).valueOf() - moment(startDate).valueOf()) / DAY_IN_MS;
    if(pauseDuration > 0)
      pnVars.pauseDuration = Math.ceil(pauseDuration);
    else
      console.warn('warn createProviderNote, pauseDuration is invalid', pauseDuration);

    return Client.mutate({
      mutation: createProviderNote,
      variables: pnVars
    });
  });
};

export const getPatientHiatus = ({ variables, options = {}, dataFields = [] }) => {    
  return Client.query({
    query: buildGetPatientHiatusQuery(dataFields),
    variables,
    ...options
  });
};

export const resumePatientHiatus = ({ variables, options = {} }) => {    
  return Client.mutate({
    mutation: resumePatientHiatusQuery,
    variables,
    ...options
  });
};