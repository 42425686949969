import React from 'react';
import { graphql,compose } from "react-apollo";
import foodLogList from '../../graphql/foodLogList';
import { Spin, Popover } from 'antd';
import _ from 'lodash';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import Client from 'libModule/gqlClient';
import addFoodLogComment from 'graphqlModule/mutation/addFoodLogComment';
import rateFoodLog from 'graphqlModule/mutation/rateFoodLog';
import WorkStationSingleFoodLog from './WorkStationSingleFoodLog';
import { GQLHelper } from 'lib/utils';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import moment from "moment";
import { userLanguages } from '../../graphql/user';
import { getUserTranslateLanguage } from '../helper';
import { eventHelperCreator, EVENT_MAPS } from '../../../lib/helpers/event-helpers';

const SELECTED_IMAGE_STYLE = {
  border: '2px solid white',
  boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.5)',
};
const ENGLISH = 'English';

const eventHelper = eventHelperCreator(EVENT_MAPS.foodLog, 'WorkStationFoodLogs');
const NUMBER_OF_DAYS_IN_PAST = -7;

class WorkStationFoodLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      hoverIndex: null,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    eventHelper.listen(this.handleEventCb);
  }

  componentWillUnmount() {
    eventHelper.remove(this.handleEventCb);
  }

  getList = () => {
    return _.get(this.props, `foodLogList.data`, []);
  }

  getSelectedFoodLog = () => {
    const { selectedIndex } = this.state;
    return selectedIndex != null ? this.getList()[selectedIndex] : null;
  }

  selectIndex = (i) => {
    this.setState({ selectedIndex: i });
  }

  selectHoverImage = (i) => {
    this.setState({ hoverIndex: i });
  }

  leaveHoverImage = (i) => {
    if (this.state.hoverIndex === i) {
      this.setState({ hoverIndex: null });
    }
  }

  handleCancel = (foodLog) => {
    const memberId = _.get(this.props, 'memberId');
    Mixpanel.track('clicked', 'cancel', 'food log', { PATIENT_ID : memberId });
  };

  getDisplayName = (foodLog) => {
    const displayName = this.constructor.displayName;
    const docId = foodLog.id;
    return `${displayName}-${docId}`;
  }

  handleEventCb = ({ detail }) => {
    if (detail.date && moment(detail.date).isSameOrAfter(moment().startOf('day').add(NUMBER_OF_DAYS_IN_PAST, 'day'))) {
      const refetchCount = _.get(this.props, 'refetchUnratedFoodLogsCount');
      this.props.refetch();
      if (refetchCount) {
        refetchCount();
      }
    }
  }

  handleSubmit = async (foodLog, { rating, comment: text, translatedText }, updateInterventionDetails) => {
    const memberId = _.get(this.props, 'memberId');
    const refetch = _.get(this.props,'refetchUnratedFoodLogsCount');
    const { onInteraction, setShowUnsavedModal } = this.props;

    const { id, createdAt } = foodLog;
    Mixpanel.track('clicked', 'submit', 'food log', { PATIENT_ID : memberId });
    this.setState({ isSubmitting: true });

    try {
      const rateRes = await Client.mutate({
        mutation: rateFoodLog,
        variables: {
          id,
          rating: { star: rating }
        },
        fetchPolicy: 'no-cache',
      });

      const commentRes = await Client.mutate({
        mutation: addFoodLogComment,
        variables: {
          id,
          comment: {
            text: translatedText || text.trim(),
            commentedById: sessionStorage.getItem('currentUserId'),
            originalText: text.trim(),
          }
        },
        fetchPolicy: 'no-cache',
      });
      eventHelper.create({ date: createdAt });
      updateInterventionDetails(commentRes);
      onInteraction();
      if(refetch) {
        refetch();
      }
      if(setShowUnsavedModal){
        setShowUnsavedModal(false);
      }

      this.props.refetch();

    } catch (error) {
      console.log(error);
      this.props.openErrorModal(GQLHelper.formatError(error));
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  renderThumbNails = () => {
    const { selectedIndex } = this.state;
    const list = this.getList().map(x => _.get(x, 'images[0].thumbnailLink'));
    return (
      <div style={{ display: 'flex', overflow: 'auto', minHeight: (50 + 4 * 2) /* margin 4 */  }}>
        {list.map((x, i) => (
          <a
            key={i}
            onClick={() => this.selectIndex(i)}
            onMouseEnter={() => this.selectHoverImage(i)}
            onMouseLeave={() => this.leaveHoverImage(i)}
            style={{ margin: 4 }}
          >
            <img style={{ width: 50, borderRadius: 4, ...(i === selectedIndex ? SELECTED_IMAGE_STYLE : {}) }} src={x} />
          </a>
        ))}
      </div>
    );
  }

  renderImageModal = () => {
    const { hoverIndex,selectedIndex } = this.state;
    if (hoverIndex === null) return null;
    const src = _.get(this.getList(), `${[hoverIndex]}.images[0].link`);
    return (
        <Popover
          visible
          overlayClassName='worskstation-image-popover'
          placement='rightTop'
          content={<img style={{ width: 400, borderRadius: 4, ...SELECTED_IMAGE_STYLE }} src={src} />}
          destroyTooltipOnHide
        >
          <div style={{ position: 'absolute', top: 15, right: -5 }}></div>
        </Popover>
    );
  }

  getCurrentUserAvatar = () => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    return _.get(currentUser, 'profile.avatar');
  }

  render() {
    const { isSubmitting } = this.state;
    const { memberId } = this.props;
    const foodLog = this.getSelectedFoodLog();
    const language = _.get(this.props, 'user.profile.language.description');
    const appLanguage = _.get(this.props, 'user.profile.appLanguage.description');

    if (this.props.loading) {
      return (
        <Spin size="large" >
          <div style={{ height: 700 }}></div>
        </Spin>
      );
    }

    return (
      <div style={{ padding: 15, position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
        {this.renderThumbNails()}
        {this.renderImageModal()}
        <div style={{ flex: '1', marginTop: 30 }}>
          {foodLog === null 
            ? null 
            : <WorkStationSingleFoodLog
              userAvatar={this.getCurrentUserAvatar()}
              patientId={memberId}
              foodLog={foodLog}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              isSubmitting={isSubmitting}
              setShowUnsavedModal={this.props.setShowUnsavedModal}
              translationLang={getUserTranslateLanguage({ language, appLanguage })}
              appLanguage={_.get(this.props, 'user.profile.appLanguage.description')}
              language={_.get(this.props, 'user.profile.language.description')}
            />}
        </div>
      </div>
    );
  }
}

const withData = graphql(foodLogList, {
  options: (ownProps) => {
    return {
      variables: {
        filters: {
          memberId: ownProps.memberId,
          fromDate: moment().startOf('day').add(NUMBER_OF_DAYS_IN_PAST, 'day').valueOf(),
          reviewed: false,
        },
        sort: {
          field: 'CREATED_AT',
          direction: 'ASC'
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const { loading, foodLogList, refetch } = data;
    return {
      foodLogList,
      loading,
      refetch
    }
  }
});

const withUserData = graphql(userLanguages, {
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.memberId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const { loading, user } = data;
    return {
      user,
      loadingUser: loading,
    }
  }
});

const mapToDispatch = (dispatch)=>({
  openErrorModal: (e) => dispatch(openErrorModal(e))
});
export default compose(withData, withUserData, connect(null,mapToDispatch))(WorkStationFoodLogs);
