import gql from 'graphql-tag';
import CoveredMembersSchema from './schema/CoveredMembers';

export const getCoveredMembersQuery = gql`
query getCoveredMembers {
  getCoveredMembers {
    ${CoveredMembersSchema}
  }
}
`;

export const setCoveredMembersMutation = gql`
mutation setCoveredMembers($members: [EID], $coverAll: Boolean) {
  setCoveredMembers(members: $members, coverAll: $coverAll) {
    ${CoveredMembersSchema}
  }
}
`;