import type from '../constants';

export default {
    initVisitList: (data) => {
        return {
            type: type.INIT_VISIT_LIST,
            payload: {
                data
            }
        }
    },
    addVisit:(data)=>{
        return{
            type: type.ADD_VISIT,
            payload:{
                data
            }
        }
    },
    deleteVisit:(id)=>{
        return {
            type:type.DELETE_VISIT,
            payload:{
                id
            }
        }
    },
    updateVisit:(id,data)=>{
        return {
            type:type.UPDATE_VISIT,
            payload:{
                id,
                data
            }
        }
    },
    setYear:(offset)=>{
      return{
          type:type.SET_YEAR,
          payload:{
              offset
          }
      }
    },
    resetVisit:()=>{
        return{
            type:type.RESET_VISIT
        }
    }
}
