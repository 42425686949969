import  React  from 'react';
import { compose } from "react-apollo/index";
import PatientSmartAlertComponent from '../component/PatientSmartAlertComponent';
import closeSmartAlert from '../query/closeSmartAlert';
import getPatientSmartAlert from '../query/getPatientSmartAlert';
import getPatientAlertConfig from '../query/getPatientAlertConfig';
import { connect } from 'react-redux'
import { GQLHelper } from 'libModule/utils'
import { modalAction } from 'modulesAll/common/actions'
import Client from 'libModule/gqlClient';
import { message,Modal,Row,Col,Tag,Form,Input,Button,Checkbox, Popover, DatePicker, Radio } from 'antd';
import {API} from "../../timer/query";
import createProviderNote from 'modulesAll/graphql/createProviderNote'
import constant from "../constant";
import providerNoteList from 'graphqlModule/providerNoteList'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from "../../timer/actions";
import patientSmartAlertList from 'modulesAll/smartAlert/query/patientSmartAlertList.js'
import helper from '../helper/';
import setPatientAlertConfig from '../query/setPatientAlertConfig';
import moment from 'moment-timezone';
import { EVENT_TYPES } from '../../idleTime/constant';

const { alertLevelColorMap } = constant;
const { stopTimer,updateInterventionDetails }= timerActions.default;
const MS_OF_1DAY = 24 * 3600000;
const style ={
    tagStyle:{
        display: 'flex',
        height: 28,
        alignItems: 'center',
        border: '1px',
        borderRadius: 15,
        marginBottom: 15,
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}

let PatientSmartAlertTasksContainer = class extends React.Component {
    static  displayName = 'PatientSmartAlertTasksContainer';

    constructor(){
        super();
        this.state = {
            showSmartPNModal: false,
            alert:{ },
            showSnoozeConfig: false,
            showSnoozeString: false,
            snoozeConfigStart: moment(),
            // snoozeConfigInterval: '1d',
            showSnoozeListModal: false,
            defaultAction:'RESOLVE'
        }
        this.handleSnoozePopoverCancel = this.handleSnoozePopoverCancel.bind(this);
        this.handleSnoozePopoverChange = this.handleSnoozePopoverChange.bind(this);
    }
    
    handleInteraction = (type, description) => {
        const { onInteraction } = this.props;
        if (onInteraction) {
            onInteraction(type, description);
        }
    }
    
    handleSnoozePopoverCancel() {
        const { alert } = this.state;
        this.setState({ showSnoozeConfig: false });
    }

    async handleEditPopoverChange(snoozeConfig, start, interval) {
        this.setState({ 
            showSnoozeConfig: false
            // snoozeConfigInterval: interval,
        });
        let snoozeInterval = '1 day';
        let end = new Date();

        if (interval === '1d') {
            end = start + MS_OF_1DAY;
        } else if (interval === '1w') {
            snoozeInterval = '1 week';
            end = start + 7 * MS_OF_1DAY;
        } else if (interval === '2w') {
            snoozeInterval = '2 weeks';
            end = start + 14 * MS_OF_1DAY;
        } else if (interval === '1m') {
            snoozeInterval = '1 month';
            end = start + 30 * MS_OF_1DAY;
        }

        if (start && end && end > start && alert) {
            const res = await this.setPatientAlertConfig({
                startDate: start, 
                endDate: end,
                taskType: snoozeConfig.taskType,
                isValid: true,
                snoozeInterval: snoozeInterval,
                display: snoozeConfig.alertDisplay
            });
            this.handleInteraction(EVENT_TYPES.ALERT, 'SNOOZE');
        }
    }

    async handleRemoveSnoozeChange(snoozeConfig) {
        const { onInteraction } = this.props;
        await this.setPatientAlertConfig({
            startDate: snoozeConfig.startDate, 
            endDate: snoozeConfig.endDate,
            taskType: snoozeConfig.taskType,
            isValid: false,
            snoozeInterval: snoozeConfig.snoozeInterval,
            display: snoozeConfig.alertDisplay
        });
        this.handleInteraction(EVENT_TYPES.ALERT, 'UNSNOOZE');
    }



    async handleSnoozePopoverChange(start, interval) {
        const { alert } = this.state;
        //console.log({alert, interval, start});

        this.setState({ 
            showSnoozeConfig: false,
            showSnoozeString: true,
            snoozeConfigStart: start,
            // snoozeConfigInterval: interval,
        });
        let snoozeInterval = '1 day';
        let end = new Date();
        if (interval === '1d') {
            end = start + MS_OF_1DAY;
        } else if (interval === '1w') {
            snoozeInterval = '1 week';
            end = start + 7 * MS_OF_1DAY;
        } else if (interval === '2w') {
            snoozeInterval = '2 weeks';
            end = start + 14 * MS_OF_1DAY;
        } else if (interval === '1m') {
            snoozeInterval = '1 month';
            end = start + 30 * MS_OF_1DAY;
        } else if(interval ==='3m') {
            snoozeInterval = '3 months';
            end = start + 90 * MS_OF_1DAY;
        }

        if (start && end && end > start && alert) {
            const res = await this.setPatientAlertConfig({
                startDate: start, 
                endDate: end,
                taskType: alert.taskType,
                isValid: true,
                snoozeInterval: snoozeInterval,
                display: alert.display
            });
            this.handleInteraction(EVENT_TYPES.ALERT, 'SNOOZE');
        }
    }

      setPatientAlertConfig = async (variables)=>{
        const { memberId } = this.props;
        const {startDate, endDate, taskType, isValid, snoozeInterval, display} = variables;
        console.log(variables)

        return Client.mutate({
            mutation: setPatientAlertConfig,
            variables: {
                memberId: memberId,
                config: [{
                    startDate: startDate,
                    endDate: endDate,
                    taskType: taskType,
                    snoozeInterval: snoozeInterval,
                    isValid: isValid,
                    alertDisplay: display
                }]
            },
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query:getPatientAlertConfig,
                    variables:{ memberId },
                    fetchPolicy:'network-only'
                }
            ]
        });
    }

    editPatientAlertConfig = async (variables)=>{
        const {memberId, startDate, endDate, taskType, isValid, snoozeInterval, display} = variables;

        return Client.mutate({
            mutation: setPatientAlertConfig,
            variables: {
                memberId: memberId,
                config: [{
                    startDate: startDate,
                    endDate: endDate,
                    taskType: taskType,
                    snoozeInterval: snoozeInterval,
                    isValid: isValid,
                    alertDisplay: display
                }]
            },
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query:getPatientAlertConfig,
                    variables:{ memberId },
                    fetchPolicy:'network-only'
                }
            ]
        });
    }

    handleSnoozeCheckboxChange = async (e) => {
        const { alert } = this.state;
        const { patientAlertConfig } = this.props;

        if (!e.target.checked) {
            if (patientAlertConfig && patientAlertConfig.configs) {
                let alertConfig = patientAlertConfig.configs.filter(c => c.taskType === alert.taskType)[0];
                if (alertConfig) {
                    await this.setPatientAlertConfig({
                        startDate: alertConfig.startDate, 
                        endDate: alertConfig.endDate,
                        taskType: alert.taskType,
                        isValid: false,
                        snoozeInterval: alertConfig.snoozeInterval,
                        display: alert.display
                    });
                    this.handleInteraction(EVENT_TYPES.ALERT, 'UNSNOOZE');
                }
            }
        }
        else {
            this.setState({defaultAction:'SNOOZE'})
        }
        this.setState({ showSnoozeConfig: e.target.checked });
    }
    
    changeSmartPNModalStatus = (alert) => {
        const { showSmartPNModal } = this.state;
        const { memberId } = this.props;
        const { taskType } = alert;
        this.setState({
            showSmartPNModal: !showSmartPNModal,
            alert
        },()=>{
            Mixpanel.track('clicked',taskType,'patient_profile',{ PATIENT_ID:memberId });
        })
    }

    changeSnoozeListModalStatus = (alertConfig) => {
        const { showSnoozeListModal } = this.state;
        const { memberId } = this.props;
        this.setState({
            showSnoozeListModal: !showSnoozeListModal,
            patientAlertConfig: alertConfig
        },()=>{
            Mixpanel.track('clicked','snooze_list',{ PATIENT_ID:memberId });
        })
    }

    renderPatientInfo= () =>{
        const { identification, fullName } = this.props;
        return <Row>
                <Col span={24}>
                    <span style={{ fontSize:18,fontWeight:'bold',color:'#000000' }}>{`${fullName} (${identification})`}</span>
                </Col>
               </Row>
    }

    renderSmartAlert = ()=>{
        const { alert } = this.state;
        const style ={
            tagStyle:{
                display: 'flex',
                height: 28,
                alignItems: 'center',
                border: '1px',
                borderRadius: 15,
                marginBottom: 15,
                marginTop:15,
                width:'fit-content',
                paddingLeft:'10px',
                paddingRight: '10px'
            }
        };
        const {color, backgroundColor} = alertLevelColorMap[alert.alertLevel];

        return <Tag style={{color: '#2D3238', backgroundColor: backgroundColor,...style.tagStyle}}>
                    <div style={{ width: 8, height: 8, borderRadius: 6, backgroundColor: color, display: 'inline-block', marginRight: 5 }}/>
                    <p style={{cursor:'point'}}>{alert.display}</p>
                    {helper.renderSmartAlertInfo(alert)}
               </Tag>
    }
    createProviderNote = async (variables)=>{
        const { props } = this;
        const refetchVariable = (() => {
            const variables = {
                'filters': {
                    'enrolledProgramId': props.currentProgramId,
                    ...props.filter,
                },
                page: _.get(props, 'page.current', 1),
                count: 10,
                sort: {'field': 'CREATED_AT', 'direction': 'DESC'}
            }
            const sortField = _.get(props, 'sort.fieldGraph', null);
            if (sortField) {
                variables.sort = {
                    field: sortField,
                    direction: _.get(props, 'sort.direction', 'DESC'),
                };
            }
            return variables;
        })();
        return Client.mutate({
            mutation:createProviderNote,
            variables,
            refetchQueries: [{
                query: providerNoteList,
                variables: refetchVariable,
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
            }]
        });
    }

    handleSubmit = async (isRequired, e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });

        const { form, memberId, openErrorModal, smartAlertTableProps } = this.props;
        const { alert, defaultAction } = this.state;
        const { taskType } = alert;
        const { getFieldsValue } = form;
        const { notes } = getFieldsValue();
        if(notes&&notes.trim().length!=0 || !isRequired){
            let newNotes = (notes && notes.trim().length != 0) ? notes.trim() : null
            let variables = {
                memberId,
                smartAlertId:alert.id,
                category:'SMART_ALERT',
                content: newNotes
            }
            try {
                //Ticket-4716: Remove creation if no note
                if(newNotes !== null) {
                    const res = await this.createProviderNote(variables);
                    this.handleInteraction(EVENT_TYPES.PROVIDER_NOTES, 'ADD');
                    const apiName = Object.keys(res.data)[0];
                    const docId = res.data[apiName].id;
                    const summary = res.data[apiName].content;
                    const resource = atob(docId).split(':')[0];
                    let displayName = this.constructor.displayName;
                    displayName = `${displayName}:${memberId}`;
                    this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
                    this.props.stopTimer(displayName, 'saveModal');
                }
                if(defaultAction === 'RESOLVE'){
                    this.handleCloseAlert(alert.id,taskType);
                } else {
                    Client.query({
                        query: patientSmartAlertList,
                        variables:{ filters: {memberId, alertLevel: smartAlertTableProps.filter.alertLevel}, page: smartAlertTableProps.page.current},
                        fetchPolicy:'network-only'
                    });
                }
                this.setState({
                    showSmartPNModal:false,
                    alert:{ }
                })
                Mixpanel.track('clicked','submit_on_alert','PATIENT_PROFILE',{ PATIENT_ID:memberId })
            }
            catch(e){
                console.log(e);
                openErrorModal(GQLHelper.formatError(e))

            }
        }
    }

    setSnoozeString = () => {
        const { alert } = this.state;
        const { patientAlertConfig } = this.props;

        const taskType = alert.taskType;

        if (patientAlertConfig && patientAlertConfig.configs && patientAlertConfig.configs.length) {
            const alertConfig = patientAlertConfig.configs.filter(c => c.taskType === taskType)[0];
            if (alertConfig && alertConfig.isValid && alertConfig.endDate > new Date()) {
                this.setState({
                    showSnoozeString: true
                });
                return `Snooze time: ${alertConfig.snoozeInterval} Starts from: ${moment(alertConfig.startDate).tz(moment.tz.guess()).format('MM/DD/YY')}`;
            }
        }
        return ''
    }

    setRadioDisplay = (interval) => {
        if (interval === '1 day') {
            return '1d';
        }
        if (interval === '1 week') {
            return '1w';
        }
        if (interval === '2 weeks') {
            return '2w';
        }
        if (interval === '1 month') {
            return '1m';
        }
    }

    handleOtherRatioChange = (e) =>{
        this.setState({showSnoozeConfig : false, defaultAction : e.target.value});
    }

    renderForm = ()=>{
        const { alert } = this.state;
        const { TextArea } = Input;
        const { form, patientAlertConfig } = this.props;
        const { getFieldDecorator } = form;
        const { showSnoozeString, snoozeConfigStart } = this.state;
        let snoozeConfigInterval = '1d';
        let snoozeStr = '';
        let tempAlertConfig = null;
        let datePickerStartDate = moment().tz(moment.tz.guess());
       // console.log({patientAlertConfig})
        if (patientAlertConfig && patientAlertConfig.configs && patientAlertConfig.configs.length) {
            tempAlertConfig = patientAlertConfig.configs.filter(c => c.taskType === alert.taskType && c.isValid)[0];
            if (tempAlertConfig && new Date() < tempAlertConfig.endDate) {
                datePickerStartDate = moment(tempAlertConfig.startDate).tz(moment.tz.guess());
                snoozeConfigInterval = this.setRadioDisplay(tempAlertConfig.snoozeInterval);
                snoozeStr = `Snooze time: ${tempAlertConfig.snoozeInterval} Starts from: ${moment(tempAlertConfig.startDate).tz(moment.tz.guess()).format('MM/DD/YY')}`;
            }
        }
        const radioMap = [
            {
                value:'RESOLVE',
                label:'Resolve alert'
            },
            {
                value:'COMMENT',
                label:'Write note only'
            }]
        const snoozeTaskTypes = ['LEVEL_ONE_VISIT_DUE', 'LEVEL_TWO_A1C_DUE', 'LEVEL_THREE_VISIT_OVERDUE', 'LEVEL_THREE_INACTIVE_PATIENT', 'LEVEL_THREE_PATIENT_APP_ENGAGEMENT', 'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT'];
        const displaySnoozeWrapper = snoozeTaskTypes.indexOf(alert.taskType) > -1;
        let isRequired = snoozeStr === ''
        // const snoozeConfigString = this.setSnoozeString();

        return <Form layout="inline" style={{ width:'100%', paddingRight:20 }}>
                 <Form.Item style={{ width:'inherit' }} className='smartAlertPNForm'>
                     {getFieldDecorator('notes', {
                        rules: [{ required: isRequired,
                            message: 'Please Enter Provider Notes' },
                            { whitespace:true,message: 'Please Enter Provider Notes' }],
                     })(
                         <TextArea rows={6}/>
                     )}
                 </Form.Item>
                 <Form.Item>
                 <Radio.Group value={this.state.defaultAction}>
                        { _.map(radioMap,({value,label},i)=><Radio value={value} key={i} onChange={this.handleOtherRatioChange}>{label}</Radio>) }
                        {displaySnoozeWrapper && (<Popover 
                            placement='bottom' 
                            content={<SnoozeFormWrapper defaultStart={datePickerStartDate} defaultInterval={snoozeConfigInterval} 
                            onCancel={this.handleSnoozePopoverCancel}
                            onChange={this.handleSnoozePopoverChange}
                            alert={alert} />}
                            trigger='focus'
                            visible={this.state.showSnoozeConfig && displaySnoozeWrapper}
                        >
                            <Radio value='SNOOZE' defaultChecked={!!snoozeStr} onChange={(e) => { 
                               this.handleSnoozeCheckboxChange(e);
                            }}>Snooze alert</Radio>
                        </Popover>)}
                </Radio.Group>
                        {snoozeStr && displaySnoozeWrapper && (
                            <div style={{marginLeft: '173px'}}>
                                <span>{snoozeStr}</span>
                                {/* <span>Starts from: {snoozeConfigStart.format('MM/DD/YY')}</span> */}
                                <Button type="link" style={{color: '#1890ff', border: 'none'}} onClick={() => this.setState({ showSnoozeConfig: true })}>Edit</Button>
                            </div>
                        )}

                 </Form.Item>
                     <Button type="primary" htmlType='submit' onClick={(e) => this.handleSubmit(isRequired, e)} style={{ float:'right',marginTop:20 }}>
                         Submit
                     </Button>
               </Form>
    }

    renderVisitWindow = ()=>{
        const { alert:{ visitWindow }} = this.state;
        return helper.parseVisitWindow(visitWindow);
    }

    renderModal = ()=>{
        const { showSmartPNModal } = this.state;
        let displayName = this.constructor.displayName;
        const { memberId } = this.props

        const viewingDetails = {
            component : displayName,
            path:window.location.pathname
        }
        const keepEventListener = true;

        return showSmartPNModal ?
                <Modal visible={showSmartPNModal} onCancel={ ()=>this.setState({ showSmartPNModal: false, showSnoozeConfig: false })} width={940} afterClose={() => this.setState({ showSnoozeConfig: false })}
                      maskClosable={false} footer={null}>
                <TimerContainer displayName={'PatientSmartAlertTasksContainer'} category='ALERT' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={memberId}
                                keepEventListener={keepEventListener}
                />
                 <Row>
                     <Col span={8}>
                         { this.renderPatientInfo() }
                         { this.renderSmartAlert() }
                         { this.renderVisitWindow() }
                     </Col>
                     <Col span={16}>
                        { this.renderForm() }
                     </Col>
                 </Row>
                </Modal> :''
    }

    renderSnoozeListModal = ()=>{
        const { showSnoozeListModal, alert } = this.state;
        const {patientAlertConfig} = this.props;
        const { memberId } = this.props

        if (!patientAlertConfig || !patientAlertConfig.configs) {
            return '';
        }
        const valueOfSmartAlertType = {
            LEVEL_ONE_VISIT_DUE: 'LEVEL_ONE',
            LEVEL_TWO_A1C_DUE: 'LEVEL_TWO',
            LEVEL_THREE_VISIT_OVERDUE: 'LEVEL_THREE',
            LEVEL_THREE_INACTIVE_PATIENT: 'LEVEL_THREE',
            LEVEL_THREE_PATIENT_APP_ENGAGEMENT: 'LEVEL_THREE', 
            LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT: 'LEVEL_THREE'
        };

        let alertConfigs = patientAlertConfig.configs.filter(c => new Date() < c.endDate && c.isValid);

        const snoozeList = <div>
            {
                _.map(alertConfigs, (m, i) => {
                    const {color, backgroundColor} = alertLevelColorMap[valueOfSmartAlertType[m.taskType]] || {};
                    const stateShowKey = `showPopover${i}`;

                    return (
                        <Row key={m.taskType}>
                            <Col span={6}>
                                <Tag key={i} style={{color: '#2D3238', backgroundColor: backgroundColor, ...style.tagStyle}}>
                                <div style={{ width: 8, height: 8, borderRadius: 6, backgroundColor: color, display: 'inline-block', marginRight: 5 }}/>
                                    <p style={{fontSize: '12', cursor:'point'}}>{m.alertDisplay || 'snooze'}</p>
                                    {helper.renderSmartAlertInfo(m)}
                                </Tag>
                            </Col>
                            <Col span={12} style={{lineHeight: '32px'}}>
                                <span style={{marginRight: '10px'}}>Snooze time: {m.snoozeInterval}</span>
                                <span>Starts from: {moment(m.startDate).tz(moment.tz.guess()).format('MM/DD/YY')}</span>
                            </Col>
                            <Col span={6}>
                                <Popover
                                    placement='bottom'
                                    content={<SnoozeFormWrapper defaultStart={moment(m.startDate)} defaultInterval={this.setRadioDisplay(m.snoozeInterval)}
                                    onCancel={() => this.setState({ [stateShowKey]: false })}
                                    onChange={(start, interval) => {
                                        this.handleEditPopoverChange(m, start, interval);
                                        this.setState({[stateShowKey]: false});
                                    }}
                                    alert={alert}/>}
                                    trigger='focus'
                                    visible={this.state[stateShowKey]}
                                >
                                    <Button type="link" style={{color: '#1890ff', border: 'none'}} onClick={() => this.setState({ [stateShowKey]: true })}>Edit</Button>
                                </Popover>
                                <Button type="link" style={{color: '#1890ff', border: 'none'}}
                                    onClick={() => this.handleRemoveSnoozeChange(m)}>Remove</Button>
                            </Col>
                        </Row>
                    );
                })
            }
        </div>
        return showSnoozeListModal ?
                <Modal visible={showSnoozeListModal} onCancel={ ()=>this.setState({ showSnoozeListModal: false, showSnoozeConfig: false })} width={640}
                      maskClosable={false} footer={null} afterClose={() => this.setState({ showSnoozeConfig: false })}>
                    <Form layout="vertical">
                        <Form.Item label="Snooze Alert" style={{marginBottom: '0'}}/>
                        <Form.Item>{snoozeList}</Form.Item>
                    </Form>
                </Modal> :''
    }

    handleCloseAlert = (taskId,taskType)=>{
        const { openErrorModal,memberId,uuid, smartAlertTableProps } = this.props
        const event = 'HANDLE_SMART_ALERT';
        const category = 'ALERT';
        const action = 'UPDATE';
        const timeTrackerEvent = API.startTimeTracker(event,category,action,memberId);
        Client.mutate({
            mutation: closeSmartAlert,
            variables:{taskId},
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query:getPatientSmartAlert,
                    variables:{ memberId },
                    fetchPolicy:'network-only'
                },
                {
                    query:patientSmartAlertList,
                    variables:{ filters: {memberId, alertLevel: smartAlertTableProps.filter.alertLevel}, page: smartAlertTableProps.page.current},
                    fetchPolicy:'network-only'
                }
            ]
        }).catch(e=>{
            openErrorModal(GQLHelper.formatError(e))
        }).then(_=> {
            const canRestUUid = false;
            this.handleInteraction(EVENT_TYPES.ALERT, action);
            API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);
            Mixpanel.track('Closed',taskType,'PATIENT_PROFILE',{ PATIENT_ID:memberId });
            message.success('Alert Closed')
        });

    }

    render(){
        const { props,changeSmartPNModalStatus, changeSnoozeListModalStatus } = this;
        const { showSmartPNModal } = this.state;
        const { smartAlertTableProps, onInteraction } = this.props;
        return <div>
                    <PatientSmartAlertComponent {...props} showConfirmForCloseAlert={this.handleCloseAlert}
                                                showSmartPNModal={ showSmartPNModal }
                                                changeSmartPNModalStatus={changeSmartPNModalStatus}
                                                changeSnoozeListModalStatus={changeSnoozeListModalStatus}
                                                onInteraction={onInteraction}

                    />
                    { this.renderModal() }
                    { this.renderSnoozeListModal() }
               </div>
    }
}

class SnoozeConfigPopover extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleSubmit(e) {
        const { form, onChange } = this.props;

        e.preventDefault();
        form.validateFields((err, values) => {
            const { start=moment(), interval } = values;
            onChange && onChange(start, interval);
        });
    }

    handleCancel() {
        const { onCancel } = this.props;
        onCancel && onCancel();
    }

    render() {
        const { form, alert } = this.props;
        const { getFieldDecorator } = form;
        const { defaultStart, defaultInterval } = this.props;
        const curTime = moment().format('MM/DD/YYYY')

        return (
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <span style={{fontWeight:'bold'}}>Start time: {curTime}</span>
                <Form.Item label="Choose type" style={{ marginTop: 5}}>
                {getFieldDecorator('interval', {
                    rules: [{ required: true }],
                    initialValue: defaultInterval  
                })(
                    <Radio.Group>
                        {this.getSnoozeOptions(alert.taskType)}
                    </Radio.Group>
                )}
                </Form.Item>
        
                <Form.Item>
                    <Button type="primary" htmlType='submit' style={{marginRight: '10px'}}>Set snooze time</Button>
                    <Button type="link" style={{color: '#1890ff', border: 'none'}} onClick={this.handleCancel}>Cancel</Button>
                </Form.Item>
            </Form>
        );
    }

    getSnoozeOptions = (taskType) => {
        const oneDayArray = ['LEVEL_THREE_PATIENT_APP_ENGAGEMENT', 'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT']
        if(_.includes(oneDayArray, taskType)) {
            return  <Radio value="1d">1 day</Radio>
        } else {
            return (
                <div style={{display:'flex', flexDirection:'row'}}>
                    <Radio value="1d">1 day</Radio>
                    <Radio value="1w">1 week</Radio>
                    <Radio value="2w">2 weeks</Radio> 
                    <Radio value="1m">1 month</Radio>
                </div>
            )
        }
    }
}

const SnoozeFormWrapper = Form.create({ name: 'snooze_config' })(SnoozeConfigPopover);

const mapState = (state) => {
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        // ...state.globalTables.provider_alert_list,
        ...state.globalTables.allNotes,
        smartAlertTableProps:state.globalTables.patient_smart_alert_table,
        uuid,
    }
};
const mapDispatch = (dispatch) => {
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal: msg => dispatch(modalAction.openErrorModal(msg)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
PatientSmartAlertTasksContainer = Form.create({})(PatientSmartAlertTasksContainer);

export default compose(connect(mapState,mapDispatch))(PatientSmartAlertTasksContainer);