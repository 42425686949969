// Imports
import { _ } from 'ihcomponent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import chatActions from '../../chat/actions';
import { stopTimer } from '../../timerClaimed/actions';

import { handleScroll, setInstitution, resetState } from '../../layout/actions/Nav';

// Modules
import Nav from '../components/NavComponent';
class Container extends Component {
  static displayName = 'NavContainer'

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    //window.addEventListener('scroll', this.props.onScroll);
    }
    // componentWillUnmount() {
    // //window.removeEventListener('scroll', this.props.onScroll);
    // }
    // componentDidMount() {
    //     Client.query({
    //         query: query.levelOneAlertNotificationQuery
    //     }).then(({data})=> {
    //         if (data) {
    //             this.setState({
    //                 levelOneAlertNotification:data.levelOneAlertNotification
    //             })
    //         }
    //     });
    //     Client.query({
    //           query: query.orgNotificationsQuery
    //     }).then(({data})=> {
    //           if (data) {
    //               this.setState({
    //                   orgNotifications:data.orgNotifications
    //           })
    //       }
    //      });
    // }

  handleOnNavigatingAway = (event, next) => {
    const { timer, stopTimer } = this.props;
    if (timer && timer.id && timer.patientId) {
      stopTimer(timer.id, timer.patientId, event, next);
      return;
    }
    if (typeof next === 'function') next();
  }

    render() {
    return (
      <Nav
        {...this.props}
        handleOnNavigatingAway={this.handleOnNavigatingAway}
      />
    );
  }
}

const mapState = (state, ownProps) => {
    let path = state.Nav.path
    if(!path){
      path = _.map(ownProps.curPath.replace(/^\//, '').split('/'), (l)=>_.upperFirst(l)).join(' / ')
    }
    return {
        userMap: state.chat.main.userMap,
        path,
        breadCrumb: state.Nav.breadCrumb,
        institution: state.Nav.institution,
        OrgHasUnread: state.chat.main.OrgHasUnread,
        orgTeamMap: state.chat.main.orgTeamMap,
        timer: _.find(state.timerClaimed.main, (x) => x.isDone === false),
    }
}
const mapDispatch = (dispatch) => ({
    dispatch,
    addToUserMap: user => dispatch(chatActions.addToUserMap(user)),
    onScroll: () => dispatch(handleScroll()),
    setInstitution: (institution) => dispatch(setInstitution(institution)),
    resetState: () => dispatch(resetState()),
    stopTimer: (id, patientId, event, cb) => dispatch(stopTimer(id, patientId, event, cb)),
})

export default connect(
  mapState,
  mapDispatch
)(Container);
