import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const UnderlinedTooltip = props => {
  return <Tooltip
    title={props.tooltipMsg}
    overlayClassName='underlined-tip'
    autoAdjustOverflow={false}
    {...props.tooltipProps}
  >
    <span className={props.spanClassName} style={{ borderBottom: '1.5px dashed #d2d2d2', cursor: 'help', ...props.spanStyles }} >
      {props.children}
    </span>
  </Tooltip>;
};

UnderlinedTooltip.propTypes = {
  tooltipMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tooltipProps: PropTypes.object,
  children: PropTypes.any.isRequired
};

export default UnderlinedTooltip;