import gql from 'graphql-tag';
import { MAX_PATIENT_PER_PAGE } from '../../constants';
import { CTAssigneeSchema } from '../../../graphql/schema/CTAssignee';

export const boostedPatientListPageInfoOnly = gql`
  query boostedRPMPatientListPageInfo (
    $filters: InputPatientFilters,
  ) {
    boostedPatientList (
      filters: $filters,
    ) {
      pageInfo {
        total
      }
    }
  }
`;

export const boostedPatientListForQueue = gql`
  query boostedRPMPatientList (
    $page: Int = 1, 
    $count: Int = ${JSON.stringify(MAX_PATIENT_PER_PAGE)},
    $sort: UserListSort, 
    $filters: InputPatientFilters,
  ) {
    boostedPatientList (
      page: $page,
      count: $count,
      filters: $filters,
      sort: $sort,
    ) {
      data {
        user {
          id
          patientStatus {
            lastReviewFinishedIn
          }
          organization{
            id
            name
          }
          profile {
            fullName
            ...on MemberProfile {
              doctor {
                id
                profile {
                  fullName
                }
              }
            }
          }
        }
      }
      pageInfo {
        lastPage
        total
      }
    }
  }
`;


export const boostedPatientList = gql`
  query boostedRPMPatientList (
    $page: Int = 1, 
    $count: Int = ${JSON.stringify(MAX_PATIENT_PER_PAGE)},
    $sort: UserListSort, 
    $filters: InputPatientFilters,
  ) {
    boostedPatientList (
      page: $page,
      count: $count,
      filters: $filters,
      sort: $sort,
    ) {
      data {
        user {
          id
          ${CTAssigneeSchema}
          organization{
            id
            name
          }
          patientStatus {
            lastReviewFinishedIn
          }
          profile {
            fullName
            ...on MemberProfile {
              doctor {
                id
                profile {
                  fullName
                }
              }
            }
          }
        }
      }
      pageInfo {
        total
      }
    }
  }
`;
