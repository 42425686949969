import React, {Component} from 'react';
import {connect} from "react-redux";
import '../../visitNewWorkFlow/CSS/style.scss'
import CURRENT_COMPONENT from '../../visitNewWorkFlow/constants/CurrentComponent'
import { openErrorModal } from 'layoutModule/actions/MainModal'

import InputVisitFormComponent from "../../visitNewWorkFlow/components/InputVisitFormComponent";
import SearchInputComponent from "../../visitNewWorkFlow/components/SearchInputComponent";
import { helpers } from '../../visitNewWorkFlow/helper';

class CreateVisitInAppointmentsContainer extends Component {

    constructor(props){
        super(props);
        const { skipSearch, patient } = props;
        this.state = {
            value: (skipSearch&& patient)  ? CURRENT_COMPONENT.INPUT_VISIT_COMPONENT :CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: patient ? patient : null,
            visitInfo: null,
            disableSaveButton: false,
        };
        this.changeToInputVisit = this.changeToInputVisit.bind(this);
        this.createVisit = this.createVisit.bind(this);
        this.changeToSearch = this.changeToSearch.bind(this);
        this.setDisableSaveButton = this.setDisableSaveButton.bind(this);
    }

    changeToInputVisit(patient){
        this.setState({
            value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
            patient
        });
    }

    changeToSearch(){
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null
        });
    }

    createVisit(variables){
        const { scheduleVisit } = this.props;
        scheduleVisit(variables);
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null,
        });
    }

    setDisableSaveButton(value){
        this.setState({
            disableSaveButton: value
        });
    }

    renderSelectComponent(){
        const { isRemoteEnrollment,remoteEnrollment,rescheduledFromId } = this.props;

        if(this.state.value === CURRENT_COMPONENT.SEARCH_COMPONENT){
            return (<SearchInputComponent selectPatient={this.changeToInputVisit}
                                          onCreate={this.changeToCreatePatient}
                                          fromAppointments={true}

            />)
        }

        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return (<InputVisitFormComponent key={_.get(this, 'state.patient.user.id')}
                                             patient={this.state.patient}
                                             backToSearch={this.changeToSearch}
                                             createVisit={this.createVisit}
                                             editStatus={false}
                                             rescheduledFromId={rescheduledFromId}
                                             disableSaveButton={this.state.disableSaveButton}
                                             setDisableSaveButton={this.setDisableSaveButton}
                                             remoteEnrollment={remoteEnrollment}
                                             isRemoteEnrollment={isRemoteEnrollment}
                                             onCancel={this.props.onCancel}
                                             onDateChange={this.props.setAppointMonthAndYear}
                                             onAssigneeChange={this.props.onAssigneeChange}
                                             timerName={this.props.timerName}
                                             setPatient={this.changeToInputVisit}
                                             visitInfo={helpers.isNoShowApt( this.props.selectedVisit) ? this.props.selectedVisit : undefined}
            />)
        }
    }

    render() {

        return (
            <div className='newCreateVisitContainer'>
                {this.renderSelectComponent()}
            </div>
        )
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(CreateVisitInAppointmentsContainer);

