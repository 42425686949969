import UserClass from 'modulesAll/graphql/class/User'
import store from '../../../lib/reduxStore';
import { setInstitution } from '../../layout/actions/Nav'
import {getUserRole, goPath} from "../../../lib/utils";
import {resolveMissingInfoAPI, switchOrg} from "../API";
import {Tooltip, notification, message, Modal} from "antd";
import { roleTasksMap, taskMap } from '../constant/index';
import React from 'react';
import {programCategoriesMap} from "../../patient/AdditionalInfo/constants/map";
import moment from "moment";
import {missingInfoTasksResolver} from "../constant/MissingInfoConstant";
import chatHelpers, { checkShouldShowChatRedDot } from '../../chat/helper';

export const getPatientInitial = (fullName='A A')=>{
    const arr = fullName.split(/\s+/g);
    const initial = _.map(arr,s=>(_.first(s) || '').toUpperCase()).join('');
    return initial
}
export const checkIsPinned = (pinnedPatients,id)=>{
    return  _.filter(pinnedPatients,p=>p.id === id).length;
}

export const handleSwitchOrgCB = (res,cb)=>{
    const chooseLoginOrganization = res.data.chooseLoginOrganization || {};
    const { sessionToken,id } = chooseLoginOrganization;
    if (sessionToken) {
         UserClass.saveUserInfo({...chooseLoginOrganization }).then(res=>{
             store.dispatch(setInstitution(id))
             cb&&cb()
         });
    }
}

export const getCurrentOrg = ()=>{
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentOrg = _.get(currentUser,'selectedRole.organization');
  return currentOrg || {};
}

export const checkIfNeedChangeOrg = (organizationId)=>{
  return organizationId !== getCurrentOrg().id;
}

export const handlePatientChange = (patient)=>{
    const { organizationId,id,organizationName } = patient || {};
    const cb =()=>goPath(`/workstation/${id}`);
    if(checkIfNeedChangeOrg(organizationId)) {
        const authAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
        const selectedOrg = _.first(_.filter(authAllRoles,role=>role.organization.id == organizationId));
        const { refId } = selectedOrg || {};
        if(!refId) {
            Modal.error({
                title: 'Switching Clinic Error',
                content: `You can't access to ${organizationName}, please ask admin manager to change your team settings.`
            });
            return;
        }
        switchOrg(organizationId,organizationName).then(res=>{
            notification.open({
                placement:'topRight',
                duration:3,
                className:'orgChangeNotification',
                description:`You have switched to ${organizationName}.`
            })
            handleSwitchOrgCB(res,cb)
        })
    }else{
        cb();
    }
}

export const handlePatientChangeForSignOff = (patient, handleError = false) => {
  const { id, switchToOrg } = patient || {};
  if(!id) {
    return message.error('Something went wrong');
  }
  if(_.isNull(switchToOrg)) {
    message.warn(`Can't find the clinic for patient.`);
    if(handleError)
      throw new Error(`user is not in patient's clinic`);
    return;  
  }

  const cb = () => goPath(`/workstation/${id}/sign-off`);
  if(switchToOrg) {
    const { id: organizationId, internalName: organizationName = '' } = switchToOrg;
    switchOrg(organizationId).then(res=>{
      notification.open({
        placement:'topRight',
        duration:3,
        className:'orgChangeNotification',
        description:`You have switched to ${organizationName}.`
      });
      handleSwitchOrgCB(res,cb);
    })
  }else{
    cb();
  }
}


export const renderTask = (type)=>{
    const src = taskMap[type].src;
    const title = taskMap[type].title;
    const tooltipDiv = <Tooltip>
        <img src={src}/>{title}
    </Tooltip>
    return tooltipDiv;
}


const shouldExclude = (tasks, exclude) => {
    let include = false;
    _.forEach(tasks, task => {
        if (!exclude.includes(task)) {
            include = true;
        }
    });
    return !include;
}
export const ifUserHasUnread = (memberId)=>{
    const reduxStorage = store.getState();
    const patientIdToChannel = reduxStorage.chat.main.patientIdToChannel;
    const channels = _.get(reduxStorage,'chat.main.channels',[]);
    const pId = memberId && atob(memberId).split(':')[1];
    let channelName  = _.get(patientIdToChannel,pId,'');
    const hasUnread = _.get(channels,`${channelName}.counter`,0) != 0;
    return hasUnread;
}
export const getTasks = (reports, exclude = []) => {
    const role = getUserRole();
    return _.map(roleTasksMap[role], (v) => {
        return shouldExclude(v.tasks, exclude)
            ? null
            : {
                src: v.src,
                title: v.title,
                count: v.tasks.reduce((result, key) => {
                    return exclude.includes[key] ? result : result + _.get(reports, key, 0)
                }, 0),
            };
    }).filter(v => v != null);
}

export const getProgramCategories = (val, type) => {
    const filteredRes = _.filter(val, k => k.name == programCategoriesMap[type]);
    const enrolledDate = _.get(filteredRes, '[0].enrolledDate', null);
    const programCategory = {
        enrolled: _.get(filteredRes, '[0].enrolled'),
        enrolledDate: enrolledDate ? moment(enrolledDate) : enrolledDate
    };
    return programCategory;
};

export const resolveMissingInfoHandler = (props,id)=>{
    try{
        const { refetchMissingInfo} = props;
        resolveMissingInfoAPI({id}).then(res=>{
            message.info('Resolved');
            refetchMissingInfo&&refetchMissingInfo();
        })

    }
    catch (e) {
        console.log(e);
    }
}

export const getMissingInfoCount = (user,missingInfo)=>{
    const missingInfoTaskType = new Set();
    const missingInfoCount = _.reduce(Object.keys(missingInfoTasksResolver),(res,cur)=>{
        const resolver = _.get(missingInfoTasksResolver,`${cur}.resolver`);
        const hasValue = typeof resolver ==='function' && resolver(user,missingInfo);
        if(!hasValue) {
            res+=1;
            missingInfoTaskType.add(cur);
        }
        return res;
    },0)
    return {
        missingInfoCount,
        missingInfoTaskType:Array.from(missingInfoTaskType)
    };
}

// check whether to refetch chat history
export const preFetchChatHistory = (response) => {
  const { id, highestPriority } = _.get(response, 'data') || {};
  const isHighestPriorityMessage = _.get(highestPriority, 'name') === 'MESSAGE_UNREAD';

  if(!id || !isHighestPriorityMessage)
    return;
    
  const patientId = chatHelpers.getDecodedUserId(id);
  const channelName = chatHelpers.getChannelNameByPatientId(patientId);
  const hasRedDot = checkShouldShowChatRedDot(channelName);
  // channel is not pre-fetched or channel has no counter/red dot
  // then fetch chat history and check for red dot to notify user
  if(!channelName || (channelName && !hasRedDot)) {
    chatHelpers.fetchChatHistoryFromCHS(patientId);
  }
}