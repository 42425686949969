import { constants } from 'libModule/utils';
import { Button } from 'antd';
import React from 'react';
import moment from 'moment';
export default constants('PatientList', 'Main', [
  'SET_SEARCH_DATABASE','SET_STATUS_FILTERED_CLICKED'
]);

export const PATIENT_TYPE = {
  ENROLLED: 'e',
  UNENROLLED: 'ue',
};

export const SEARCH_TABS = {
  ENROLLED: {
    name: 'Enrolled Patients',
    key: PATIENT_TYPE.ENROLLED,
    tableActions: 'enrolledTableActions',
    tableState: 'enrolledTableState',
    tooltip: {
      title: 'Patient currently enrolled in Unified Care',
      style: { width: 400 }
    },
    testId: 'enrolledPatients'
  },
  UNENROLLED: {
    name: 'Unenrolled Patients',
    key: PATIENT_TYPE.UNENROLLED,
    tableActions: 'unenrolledTableActions',
    tableState: 'unenrolledTableState',
    tooltip: {
      title: 'Patient never enrolled or discharged',
      style: { width: 365 }
    },
    testId: 'unenrolledPatients'
  }
};

export const VITAL_FILTERS = _.map(['BP', 'BG', 'HS', 'TM', 'PO'], vital => ({ value: vital, text: vital }));

const DAYS_OF_MEASUREMENT = [
  [1, 4], [5, 8], [9, 12], [13, 15], [16, 31]
];

export const DAYS_OF_MEASUREMENT_FILTERS = _.map(DAYS_OF_MEASUREMENT, (pair, index) => {
  const text = index !== DAYS_OF_MEASUREMENT.length -1 ? `${_.join(pair, '-')} days` : '>= 16 days';
  return { text, value: pair };
});


export const MNTReferralColumns = ({setShowReferralModal,list,fetchVar,filter,setShowEditVisitModal})=>{
  return [
      {
        title:'MRN',
        key:'MRN',
        render:(t,doc)=>doc.user.format('MRN'),
        width:120
      },
      {
        key:'mntProgramStatus',
        title:'MNT Enrollment Status',
        filters: [
          { value: 'INELIGIBLE', text: 'Ineligible' },
          { value: 'POTENTIAL', text: 'Potential' },
          { value: 'REFERRED', text: 'Referred' }
        ],
        filteredValue: _.get(filter, 'mntProgramStatus'),
        render:(doc)=>{
          const hasStatus = !!_.get(doc,'o.user.patientMntProgram.status');
          const status = _.get(doc,'o.user.patientMntProgram.status','Potential');
          const style = {
              textDecoration: hasStatus&&status!='POTENTIAL' ? 'underline' :'auto',
              color: status == 'REFERRED' ? 'green':'inherit',
              cursor:'pointer',
              textTransform: 'capitalize'
          }
          return <span style={style} onClick={()=>setShowReferralModal(true,doc.o.user,[],-1,fetchVar)}>{_.upperFirst(_.get(doc,'o.user.patientMntProgram.status','Potential').toLowerCase())}</span>
        }
      },
      {
        key: 'lastestVisit',
        title:'Lastest Visit',
        render: (doc) => {
          const latestVisit = _.get(doc, 'o.user.latestVisit');
          return latestVisit ? <a onClick={() => setShowEditVisitModal(latestVisit.id)}>{moment(latestVisit.appointmentAt).format('MM/DD/YYYY')}</a> : 'NA';
        },
      },
      {
        key: '',
        render:(doc,record,index)=>{
          const patientIds = list.map(l=>l.user);
          const hasStatus = !!_.get(doc,'o.user.patientMntProgram.status');
          return (!hasStatus ||  status=='POTENTIAL' )&&<Button type={'primary'} onClick={()=>setShowReferralModal(true,doc.o.user,patientIds,index,fetchVar)}>Refer</Button>
        }
      }
    ]
}
export const SUBSCRIBE_MAP = [
  { value: 'SUBSCRIBED', text : 'Subscribed'},
  { value: 'EXPIRED', text: 'Expired' },
  { value: 'UNSUBSCRIBED', text: 'Not start'},
]

export const SUBSCRIBE_STATUS_FILTER = {
  "SUBSCRIBED":[
    'SUBSCRIBED','RENEW','CANCEL'
  ],
  'EXPIRED':[
    'EXPIRED'
  ],
  'UNSUBSCRIBED':[
    'UNSUBSCRIBED'
  ]
}