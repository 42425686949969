import Client from 'libModule/gqlClient';
import updateIoTDeviceMember from '../query/updateIoTDeviceMember';
import iotDeviceList from '../query/iotDeviceList';
import editIoTDeviceConfig from '../query/editIoTDeviceConfig';

const  updateIoTDeviceMemberAPI = (variables,userId) =>{

         return  Client.mutate({
               mutation: updateIoTDeviceMember,
               variables: variables,
               // refetchQueries: [{query: iotDeviceList, variables: {memberId: userId}, fetchPolicy: 'network-only'}]
           })
}
const removeIotDeviceMemberAPI = async (variables,userId)=>{
    return Client.mutate({
        mutation: updateIoTDeviceMember,
        variables: variables,
    }).then((res) => {
      Client.query({
        query: iotDeviceList, 
        variables: {memberId: userId}, 
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      });
      return res;
    });
}
const editIoTDeviceConfigAPI = (variables,userId)=>{
    return Client.mutate({
            mutation:editIoTDeviceConfig,
            variables,
    }).then((res) => {
      Client.query({
        query: iotDeviceList, 
        variables: {memberId: userId}, 
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      });
      return res;
    });
}


export default {
    updateIoTDeviceMemberAPI,
    editIoTDeviceConfigAPI,
    removeIotDeviceMemberAPI
}
