const VISIT_TYPE_ENUM = {
    'CLINIC_FOLLOW_UP': 'General',
    'INIT': 'Initial',
    'FOLLOW_UP': 'Follow-up',
    'ADDITIONAL': 'Additional',
    'TECH':'Tech',
    'MNT':'MNT',
    'TECH_ONBOARDING':'Tech Onboarding'
};


export default VISIT_TYPE_ENUM;
