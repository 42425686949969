import gql from 'graphql-tag'

export default gql `
  mutation deleteAdminProgram (
    $id: EID!
  ) {
    deleteAdminProgram (
      id: $id
    )
  }
`
