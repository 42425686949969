import moment from "moment/moment";
import API from "../API";
import i18nMap from "../../I18N";
import I18N from 'modulesAll/I18N';
import convertor from "@ihealth/convertor/index";
import roleMap from "../constant/roleMap";
import { convertFeetToInches, convertFahrenheitToCelsius } from 'libModule/utils/convertUnits'
import React from 'react';
import { Button,notification,Modal } from 'antd';
import { API as VisitAPI } from '../../visit/query/index';
import action from "../action";
import { decryptRole } from 'libModule/utils';
import VideoChatLeftComponent from '../../VideoChat/component/VideoChatLeftComponent';
import VideoNotificationContainer from '../container/VideoNotificationContainer';
import reducerActions from '../action/reducerActions';
import statusTimeFormat from '../../visit/constants/statusTimeFormat';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import BMIStatusMap from 'modulesAll/patient/main/constants/BMIStatusMap.js';

import enhanceAgoraRTC from "agoran-awe";
import AgoraRTC from "agora-rtc-sdk";
import {GQLHelper} from "../../../lib/utils";
import { VIDEO_STR } from "../../../lib/constants";
import { VISIT_CATEGORY_ENUM } from '../../visitsPage/constants';

let AgoraRTClient = enhanceAgoraRTC(AgoraRTC);

const showNotificationEvents = {
    'INVITE':'INVITE',
    'CHECK_IN':'CHECK_IN',
    'JOIN':'JOIN'
}

const getAge = t => {
    return moment().diff(moment(t, 'YYYY-MM-DD'), 'years');
};

const getGender = (gender) => {
    if (!gender) return '';
    
    return I18N.get(`gender.${gender}`)
}

const getBMIValue = (weightInKg, heightInM) => {
    return (weightInKg / heightInM / heightInM).toFixed(1);
}

const getBMIStatus = (value) => {
    let status = '';
    switch (true) {
      case (value < 18.5):
        status = 'UNDERWEIGHT';
        break;
      case (value >= 18.5 && value <= 24.9):
        status = 'NORMAL';
        break;
      case (value >= 25 && value < 29.9):
        status = 'OVERWEIGHT';
        break;
      default:
        status = 'OBESITY';
        break;
    }
    return BMIStatusMap[status];
}

const getABW = (heightInInches, weight, gender) => {
    let IBW = "", ABW = "";

    if (gender === 'Male') {
        IBW = heightInInches == 60 ? 50 : 50 + 2.3 * (heightInInches - 60);
        ABW = IBW + 0.4 * (weight - IBW);
    } else if (gender === 'Female') {
        IBW = heightInInches == 60 ? 45.5 : 45.5 + 2.3 * (heightInInches - 60);
        ABW = IBW + 0.4 * (weight - IBW);
    }

    return isNaN(ABW) ? "" : ABW;
}

const getBMR = (heightInCm, weight, BMI, gender, age, ABW) => {
    let BMR = 0;

    if (BMI <= 40) {
        if (gender === 'Male') {
            BMR = 10 * weight + 6.25 * heightInCm - 5 * age + 5;
        } else if (gender === 'Female') {
            BMR = 10 * weight + 6.25 * heightInCm - 5 * age - 161;
        }
    } else {
        if (gender === 'Male') {
            BMR = 10 * ABW + 6.25 * heightInCm - 5 * age + 5;
        } else if (gender === 'Female') {
            BMR = 10 * ABW + 6.25 * heightInCm - 5 * age - 161;
        }
    }
    return isNaN(BMR) ? "" : Math.round(BMR);
}

const handleJoinMeeting=(meetingNumber,password,setVisit,visit,apikey,signature,visitId,currentVisit)=>{
    const zoomMeeting = document.getElementById('zmmtg-root');
    action.joinMeeting(meetingNumber,password,zoomMeeting,setVisit,visit,apikey,signature,visitId,VideoChatLeftComponent,currentVisit);
}

const isJoinEvent = (e) =>{
    return (showNotificationEvents[e]=='JOIN')
}

const shouldCloseVisitModal = (store,virtualEvent,state,viewerId,creater,visitId)=>{
    if(virtualEvent=='CHECK_OUT'&&state=='END'&&(viewerId!=creater)){
        const visitIdFromRedux  = _.get(store.getState(),'VideoChat.main.visitId');
        if(visitIdFromRedux==visitId){
            return true;
        }
    }
    return false;
}
window.pop = null;
const isPTJoin = (createrId,visitId) =>{
    return createrId&&visitId&&createrId==visitId;
}
const handleNotification = async (msg,store)=>{
    let { patient,organization, visit:{ id,memberId },state,virtualEvent,forUsers,forRoles,creater } = msg;
    const viewerId = sessionStorage.getItem('currentUserId');
    // console.log(msg);
    if(shouldCloseVisitModal(store,virtualEvent,state,viewerId,creater,id)){
        notification.close('videoChatCenterNotification');
        store.dispatch(reducerActions.setVirtualVisit(null));
        return;
    }
    if(!showNotificationEvents[virtualEvent]){
        return;
    }
    if(isJoinEvent(virtualEvent)){
        if(!isPTJoin(creater,id)){
            notification.close(id);
            return;
        }
    }
    
    const currentUserRole = decryptRole();
    const currentRole = currentUserRole.split(':')[1];
    const isInForUsers = _.filter(forUsers,(u)=>u.id===viewerId).length!=0;
    const isInForRoles = _.filter(forRoles,(r)=>currentRole==r).length!=0;
    const visit = await action.getUpdatedCurrentVisit(id);
    let coveredClinics = JSON.parse(sessionStorage.getItem('coveredClinics'))||[];
    let fromCoveredClinics = false;

    if (state === "CALLING_IN") {
        fromCoveredClinics = coveredClinics.includes(organization.id)
    }
    
    if((isInForUsers||isInForRoles) && fromCoveredClinics) {
        notification.config({
            placement: 'topRight',
            top: 5,
        });
        notification.open({
            key: id,
            description: <VideoNotificationContainer memberId={memberId}
                                                     visit={visit}
                                                     uniqueId={id}
                                                     store={store}
                                                     forUsers={forUsers}
                                                     forRoles={forRoles}
                                                     patient={patient}
                                                     organization={organization}
                                                     status={state}/>,
            top:5,
            duration: 0,
            placement: 'topRight',
            className:'VideoChatNotificationContainer'
        })
    }
}

const handleConsent = async (msg,store)=>{
    const { userId,event } = msg;
    const { dispatch } = store;

    if(event=='ACCEPT') {
        dispatch(reducerActions.setConsentInfo(userId, {consent: true}))
    }
    // if(event=='NEW'){
    //     dispatch(reducerActions.setConsentInfo(userId, {consent: false}))
    // }
}

const handleClickButton = async (memberId,visit,status,setVisit,localThis,currentVisit)=>{
    const { setCurrentVisit,props,getUserData,setModal,state,openNotification } = localThis;
    const { refetchVisitList,setVirtualVisit } = props;
    const visitId  = _.get(visit,'id');
    const currentUserRole = decryptRole();
    const currentRole = currentUserRole.split(':')[1];
    sessionStorage.setItem('storeCurVisit', JSON.stringify(visit));
    //set for testing only
    //for testing
    if(status==='CALLING_IN') {
        await  API.activePatientMutate({
            eventType: "ACCEPT",
            visitId,
        })
    }
    try {
        const token = await API.getVideoToken({visitId});
        const { data } = token;
        const { getVirtualVisitToken } = data;
        const { password,meetingNumber,apikey,signature,joinUrl, agoraToken, agoraChannelName,agoraAppId,agoraUid } = getVirtualVisitToken;
        console.log(getVirtualVisitToken);
        localThis.meetingNumber = meetingNumber;
        //remove handleJoinMeeting with Zoom sdk
        // this.handleJoinMeeting(meetingNumber,password,setVisit,memberId,apikey,signature,visitId,VideoChatLeftComponent);
        //remove handleJoinMeeting with Zoom Sdk
        API.activePatientMutate({
            eventType: 'JOIN',
            visitId
        }).then(res=>{
            if(agoraToken&&agoraChannelName){
                let openUrl = `/AgoraVideo/provider/${encodeURIComponent(agoraAppId)}/${encodeURIComponent(agoraChannelName)}/${encodeURIComponent(agoraToken)}/${encodeURIComponent(agoraUid)}/${encodeURIComponent(visitId)}`;
                Mixpanel.track('opened', 'agora', '', {PROVIDER_ROLE: currentRole});
                window.pop = window.open(openUrl);
            }else{
                Mixpanel.track('opened', 'zoom', '', {PROVIDER_ROLE: currentRole});
                window.open(joinUrl);
            }
        });

        if(!visit.checkinAt) {
            onClickCheckIn({ visitId });
        }
        setModal(true,openNotification,{memberId,visit,currentRole,refetchVisitList});
        setVirtualVisit(visitId)

    }catch (e){
        console.log(e)
    }

    //set visit after all process step is done
    // setCurrentVisit(visit);

}

const onClickCheckIn = ({ visitId, needWarning = false, needMixPanel = false, cb }) => {
  const now = new Date();
  VisitAPI.updateVisit(visitId, 'checkIn', now).then(res => {
    const { checkinAt, checkoutAt } = _.get(res, 'data.editVisit', {});
    // for checkinAt and checkoutAt, the time is saved with given 'now' directly
    const isChecked = checkoutAt || (checkinAt && checkinAt !== now.getTime());
    if(needWarning && isChecked) {
      const warningMsg = checkoutAt ? 'Checked Out' : 'Checked In';
      return Modal.warning({ content: `Visit is already ${warningMsg}` });
    }
    if(cb) cb();
  });
  if(needMixPanel) Mixpanel.track('clicked', 'Check In', 'on visit page', {});   
};

const onClickCheckOut = ({ visitId, needWarning = false, needMixPanel = false, cb }) => {
  const now = new Date();
  VisitAPI.updateVisit(visitId, 'checkOut', now).then(res => {
    const checkoutAt = _.get(res, 'data.editVisit.checkoutAt');
    // for checkoutAt, the time is saved with given 'now' directly
    if(needWarning && checkoutAt && checkoutAt !== now.getTime()) {
      return Modal.warning({ content: 'Visit is already Checked Out' });
    }
    if(cb) cb();
  }).catch(error => {
    console.error(error);
    // if(needWarning && _.includes(_.get(error, 'message'), 'already checked out')) {
    //   Modal.warning({ content: 'Visit is already Checked Out' });
    // }
  });
  if(needMixPanel) Mixpanel.track('clicked', 'Check Out', 'on visit page', {});
};

const renderButtonBaseOnStatus = (visitId,isVirtual,checkinAt,checkoutAt,isFinished,memberId,visit,status,setVisit,localThis)=>{
    const { joinable } = visit;
    //offline visit
    if(!isVirtual){
        if(!checkinAt) return <Button onClick={()=>onClickCheckIn({ visitId, needWarning: true, needMixPanel: true })} type='primary' className='checkinButton'>Check In</Button>;
        else if(!checkoutAt)  return <Button onClick={() =>onClickCheckOut({ visitId, needWarning: true, needMixPanel: true })} type='primary' className='checkoutButton'>Check Out</Button>;
    }
    //virtual visit
    if(isVirtual){
        if(!isFinished) return <Button onClick={()=>handleClickButton(memberId,visit,status,setVisit,localThis,visit)}
                                       disabled={!joinable} type='primary' className={`${joinable&&'joinVideoButton'}`}
                               >{VIDEO_STR['OPEN']}</Button>;
        else if(!checkoutAt)  return <Button onClick={() =>onClickCheckOut({ visitId })} type='primary' className='checkoutButton'>Check Out</Button>
    }
    // if(checkinAt&&checkoutAt){
    //     return <span>{`${checkedInAt} - ${checkedOutAt}`}</span>
    // }
    if(checkinAt&&checkoutAt){
        return <span>Done</span>
    }

}
const getVariables = (formValues,visitId,role)=>{
    const { spo2,temperature,systolic,diastolic,BG,heartBeat,height,height_inches=0,weight,reason,doctorNotes } = formValues;
    let heightInCm = height && height_inches >= 0 ? {
        value: convertor.height(Number(convertFeetToInches(height)) + Number(height_inches), 'inch', 'cm'),
        unit: i18nMap.get('measure.units_height_cm')
    }: null
    let weightInKg = weight ? {
        value: convertor.HS(weight, 'lbs', 'kg'),
        unit: i18nMap.get('measure.units_weight_kg')
    }:null
    let BGinMMOL = BG ? {
        value: convertor.BG(BG, 'mg/dl', 'mmol/l'),
        unit: 'mmol/L'
    }:null

    let details ={
        MA:{
            reason,
            height:heightInCm,
            weight:weightInKg,
            spo2:spo2 ? {
                value:spo2,
                unit:'%'
            }:null,
            temperature:temperature ? {
                value: convertFahrenheitToCelsius(temperature),
                unit:'C'
            }:null,
            bpMeasurement:{
                systolic_blood_pressure:systolic ? {
                    value:systolic,
                    unit:'mmHg'
                }:null,
                diastolic_blood_pressure:diastolic?{
                    value:diastolic,
                    unit:'mmHg'
                }:null,
                heart_rate:heartBeat ? {
                    value:heartBeat,
                    unit:"beats/min"
                }:null
            },
            bgMeasurement:{
                blood_glucose:BGinMMOL
            }
        },
        Doctor:{
            reason
        },
        CA:{
            reason
        },
        RD:{
            reason
        },
        HC:{
            reason
        }
    }
    return {
        ...details[roleMap[role]],
        id:visitId
    }
}
const parseStatus = (visit,status,step,checkedOut,needAction,waitingTime)=>{
    const checkedInAt = visit.checkinAt ? moment(visit.checkinAt).format(statusTimeFormat):'';
    const checkedOutAt = visit.checkoutAt ? moment(visit.checkoutAt).format(statusTimeFormat):''
    if(checkedOut){
        return <span>{`${checkedInAt} - ${checkedOutAt}`}</span>
        // return 'Checked Out';
    }
    const statusMap = {
        'WITH':'With',
        'WAITING':'Waiting for',
        'CALLING_IN':'Patient calling in',
        'END':'Meeting Finished',
        'NO_SHOW':'No Show',
        'NOT_SHOWING':'Not Showing'
    }
    if(status==='CALLING_IN'){
        return <span style={{ color: 'red' }}>{`Patient has been waiting for ${(waitingTime/60000).toFixed(0)} mins`}</span>
    }
    if(status=='INIT'){
        return '--';
    }
    if(step){
        const currentUserId = sessionStorage.getItem('currentUserId')
        const stepUserId = step.id;
        const role  =roleMap[_.get(step,'role')];
        const isRedFont = currentUserId==stepUserId||status=='CALLING_IN'||needAction;
        return <span style={{ color: isRedFont? 'red' :' black' }}>{`${statusMap[status]} ${ step.name|| role}  ${waitingTime ? ((waitingTime/60000).toFixed(0) +'mins') :''}`}</span>
    }
    return statusMap[status];
}

const setRemoteStreamList = (remoteStream,localThis,fn1,fn2,params)=>{

    localThis.setState({
        remoteStream:[...remoteStream]
    },()=>{
        if(params) {
            const {streamId, visitId} = params;
            if(fn1) fn1(localThis.state.remoteStream);
            if(fn2) fn2(streamId,visitId,localThis);
        }

    })
}

const setSpeakerAndSoundLevel = (evt,localThis)=>{
    const firstSpeaker = _.get(evt,'attr.0');
    const soundLevel = _.get(firstSpeaker,'level',null);
    const speaker = _.get(firstSpeaker,'uid',null);

    if(soundLevel&&speaker) {
        localThis.setState({
            soundLevel,
            speaker
        })
    }
}

const setLocalStream = (stream,localThis)=>{
    localThis.setState({
        stream
    })
}

const setLocalAudio = (localThis)=>{
    let { stream,localAudio,client } = localThis.state;
    localThis.setState({ localAudio:!localAudio },()=>{
        if(localAudio){
            stream.muteAudio();
        }else{
            stream.unmuteAudio();
        }
    });
}

const intiOneClickVideo =  (localThis,client) =>{
    // const {setRemoteStreamList, setLocalStream, setSpeakerAndSoundLevel} = localThis;
        client.enableAudioVolumeIndicator();
        const addRemote = async (evt) => {
            const {stream} = evt;
            const { remoteStream } = localThis.state;
            const visitId = _.get(localThis,'props.visitId',_.get(localThis,'props.params.visitId'));
            setRemoteStreamList([...remoteStream, stream],localThis,null, setAudienceMap,{ streamId:stream.getId(),visitId });
            const { state: { remoteAudio } } = localThis;
            if (!remoteAudio) {
                localThis.setState({
                    shouldShowOpenAudio: true
               })
            }

        };
        // remove stream
        const removeRemote = (evt) => {
            const { remoteStream } = localThis.state;
            const {stream} = evt;
            if (stream) {
                const id = stream.getId();
                const index = remoteStream.findIndex(item => item.getId() === id);
                if (index !== -1) {
                    remoteStream.splice(index, 1);
                    setRemoteStreamList(remoteStream,localThis,(newRemote)=>{
                        if(newRemote.length){
                            localThis.setState({
                                speaker:_.first(newRemote).getId(),
                                soundLevel: 0
                            })
                        }
                    });
                }
            }
        };

        // subscribe when added
        const doSub = async (evt,localThis) => {
            try {
                client.subscribe(evt.stream);

            }
            catch(e){
                console.log('error in dosub',e);
            }
        };
        // add when published
        const addLocal = (evt) => {
            const {stream} = evt;
            const stop = stream.stop;
            const close = stream.close;
            stream.close = (func => () => {
                func()
                setLocalStream(undefined,localThis);
            })(close);
            stream.stop = (func => () => {
                func()
                setLocalStream(undefined,localThis);
            })(stop);
            setLocalStream(stream,localThis)
        };

        const removeLocal = (env,localThis)=>{
            localThis.setState({
                stream: null
            })
        }
        if (client) {
            // client.on("stream-published", (env)=>addLocal(env,localThis));
            client.on("stream-added", (env)=>doSub(env,localThis));
            client.on("stream-subscribed", (env)=>addRemote(env,localThis));
            client.on('connection-state-change',(env)=>removeLocal(env,localThis));
            client.on("peer-leave", (env)=>removeRemote(env,localThis));
            client.on("stream-removed", (env)=>removeRemote(env,localThis));
            client.on('volume-indicator', (env)=>setSpeakerAndSoundLevel(env,localThis))
        }
}

const setCameras = (cameras,localThis)=>{
    localThis.setState({
        cameras
    })
};
const switchDevice = (localThis)=>{
    const { stream,cameras,currentCamera } = localThis.state;
    const otherCamera = _.first(_.filter(cameras,c=>c.deviceId!=currentCamera.deviceId))||currentCamera;
    console.log(cameras,otherCamera);
    stream.getVideoTrack().stop();
    stream.switchDevice("video",otherCamera.deviceId);
    localThis.setState({
        currentCamera:otherCamera
    })
    // }
};
const joinOneClickVideo = async  (client,state,localThis)=>{
    const videoProfile = '480p_1';
     let {
         agoraUid,
         agoraAppId,
         agoraChannelName,
         agoraToken
    } = state;

    try {
        // const uid = isNaN(Number(3)) ? null : Number(state.uid);
        // agoraAppId='acfc6c9bc42a4de4bb2acf18cc9fd2e1';
        // agoraChannelName='test';
        // agoraToken='006acfc6c9bc42a4de4bb2acf18cc9fd2e1IAAZWtIC8uSIzJDXZWlxEOPS937kyV3FDg4C2vghya+qGAx+f9gAAAAAEABfqfzl5G/JXgEAAQDvb8le'
        intiOneClickVideo(localThis,client);
        // initializes the client with appId
        // console.log(agoraAppId,agoraToken,agoraChannelName);
        // client.startProxyServer();
        AgoraRTClient.Logger.enableLogUpload();
        AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.INFO);
        await client.init(agoraAppId);

        // joins a channel with a token, channel, user id
        await client.join(agoraToken, agoraChannelName,agoraUid);

        // create a new stream
        const stream = AgoraRTClient.createStream({
            streamID: agoraUid,
            video: true,
            audio: true,
            screen: false
        });
        stream.setVideoProfile(videoProfile);

        // Initalize the stream
        await stream.init();

        // Publish the stream to the channel.
        await client.publish(stream);
        return stream;
        // // Set the state appropriately
        // enqueueSnackbar(`Joined channel ${state.channel}`, { variant: "info" });
    } catch (err) {
        console.log(err);
        // enqueueSnackbar(`Failed to join, ${err}`, { variant: "error" });
    }
}

// const setRemoteStreamList = (remoteStream,localThis)=>{
//     // const { remoteStream } = this.state;
//     localThis.setState({
//         remoteStream:[...remoteStream]
//     })
// }
//
// const setSpeakerAndSoundLevel = (evt,localThis)=>{
//     const firstSpeaker = _.get(evt,'attr.0');
//     const soundLevel = _.get(firstSpeaker,'level');
//     const speaker = _.get(firstSpeaker,'uid');
//     localThis.setState({
//         soundLevel,
//         speaker
//     })
// }
//
// const setLocalStream = (stream,localThis)=>{
//     localThis.setState({
//         stream
//     })
// }
//
// const setLocalAudio = async ()=>{
//     const { stream,localAudio } = this.state;
//     if(localAudio){
//         stream.muteAudio();
//     }else{
//         stream.unmuteAudio();
//     }
//     this.setState(({localAudio})=>{
//         return{ localAudio:!localAudio }
//     })
// }

const handleLeaveOneClickVideo = (localThis,client,leaveEvent)=>
    Modal.confirm({
        cancelText:'Cancel',
        okText:'Yes',
        title:'Are you sure you want to end this call?',
        onOk:()=>{
            if(client){
                try {
                    client.leave();
                    if(leaveEvent){
                        leaveEvent();
                    }
                }
                catch (e){
                    console.log(e);
                }
            }
        },
        onCancel:()=>{
            console.log(localThis);
        },
    })


const setAudienceMap = async (uid,visitId,localThis)=>{
    const { audienceMap } = localThis.state;
    return new Promise(async (resolve,reject)=> {
        if (!audienceMap[uid]) {
            try {
                const { data: { getVisitMemberProfile }} = await API.getVisitMemberProfile({memberId: uid,visitId});
                const fullName = _.get(getVisitMemberProfile, 'fullName', 'Unknow User');
                localThis.setState({
                    audienceMap: {
                        ...audienceMap,
                        [uid]: fullName
                    }
                }, () => {
                    resolve(localThis.state.audienceMap);
                })
            }catch(e){
                reject(e);
                resolve(localThis.state.audienceMap);
                // localThis.setState({
                //     audienceMap: {
                //         ...audienceMap,
                //         [uid]: 'unknown User'
                //     }
                // }, () => {
                //     resolve(localThis.state.audienceMap);
                // })
            }
        }
    })
}

const confirmVisit = id => VisitAPI.updateVisitConfirm(id, true);

const reviewBillable = (id, flag) => VisitAPI.updateVisitReviewBillable({ id, reviewBillable: flag })

const handleOpenVideoVisit = async (visit, cb) => {
  if(visit.category !== VISIT_CATEGORY_ENUM.VIRTUAL) return;
  const { id: visitId, checkinAt, status } = visit;
  const currentUserRole = decryptRole();
  const currentRole = currentUserRole.split(':')[1];
  sessionStorage.setItem('storeCurVisit', JSON.stringify(visit));
    //set for testing only
    //for testing
  if(status === 'CALLING_IN') {
    await API.activePatientMutate({ visitId, eventType: 'ACCEPT' });
  }
  try {
    let token = await API.getVideoToken({visitId});
    token = _.get(token, 'data.getVirtualVisitToken');
    const { joinUrl, agoraToken, agoraChannelName, agoraAppId, agoraUid } = token;
    // localThis.meetingNumber = meetingNumber;
    await API.activePatientMutate({ visitId, eventType: 'JOIN' });
    let videoType = 'zoom';
    if(agoraToken && agoraChannelName){
      videoType = 'agora';
      const agoraURL = `/AgoraVideo/provider/${encodeURIComponent(agoraAppId)}/${encodeURIComponent(agoraChannelName)}/${encodeURIComponent(agoraToken)}/${encodeURIComponent(agoraUid)}/${encodeURIComponent(visitId)}`;
      window.pop = window.open(agoraURL);
    } else {
      window.open(joinUrl);
    }
    Mixpanel.track('opened', videoType, '', {PROVIDER_ROLE: currentRole});

    if(!checkinAt) {
      onClickCheckIn({ visitId });
    }

    if(cb) cb({ visit, currentRole });
  } catch (err){
    console.error(err);
  }
};



export default {
    getAge,
    getGender,
    handleClickButton,
    getVariables,
    renderButtonBaseOnStatus,
    parseStatus,
    handleNotification,
    handleConsent,
    intiOneClickVideo,
    joinOneClickVideo,
    setLocalAudio,
    handleLeaveOneClickVideo,
    switchDevice,
    setAudienceMap,
    getBMIValue, 
    getBMIStatus,
    getABW,
    getBMR,
    onClickCheckIn,
    onClickCheckOut,
    confirmVisit,
    handleOpenVideoVisit,
    reviewBillable
}
