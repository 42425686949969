// provider

const pathStartsWith = [
  '/programmes',
  '/nurse_preview_assessment',
  '/nurse_preview_survey',
]

const pathOnly = [
  '/dashboard',
  '/nurse_assessments',
  '/nurse_surveys',
  '/patients',
  '/tagged_patients',
  '/tasks',
  '/alerts',
]
      
export default {
  pathStartsWith,
  pathOnly
}
