import { constants } from 'libModule/utils';

export default constants('global', 'table', [
  'ON_TABLE_CHANGE',
  'ON_TABLE_SELECT_ROW',
  'ON_TABLE_SEARCH',
  'ON_TABLE_SEARCH_ENTER',
  'FILTER_TABLE_BY_DATE_RANGE',
  'ON_LOAD_PARAMS',
  'SHOW_TASKS_BY_STATUS',
  'RESET_TABLE_PROPS',
  'ON_SET_PARAMS'
]);
