import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';

import '../css/chatMessagePopover.scss';
import { getTranslate } from '../../translation/API';
import { LANGUAGE_MAP } from '../../translation/API/languageMap';
import actions from '../actions';
import chatHelper from '../helper/chatPopoverHelper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import ChatMessageTagPopover from './ChatMessageTagPopover';

const DISABLE_TAG = true;

export const renderTranslatedTextView = (translatedText, originalText, shouldShowTranslation = true, shouldHideCredit = false) => {
  return shouldShowTranslation ?
    translatedText && (
      <div className={'chat-translated-text-container' + (originalText ? ' with-original-text' : '')}>
        <span className='chat-translated-text'>{chatHelper.decodeHTML(translatedText)}</span>
        {
          !shouldHideCredit &&
          <span className='chat-translation-credit'>Translated by Google</span>
        }
      </div>
    ) : '';
};

export const renderChatTaggedTextView = (tagObj, currentUser, patientCareTeamMembers) => {
  const { tagged, taggedto, taggedby, read } = tagObj;

  if(!tagged || read)
    return null;

  const taggedtoEncodedId = chatHelper.encodeUserId(taggedto);
  const taggedbyEncodedId = chatHelper.encodeUserId(taggedby);
  const { firstName: taggedToFName = 'N/A', lastName: taggedToLName = '' } = chatHelper.getUserProfile(patientCareTeamMembers, taggedtoEncodedId);
  const { firstName: taggedByFName = 'N/A', lastName: taggedByLName = '' } = chatHelper.getUserProfile(patientCareTeamMembers, taggedbyEncodedId);
  const taggedToDisplayName = `${taggedToFName}${taggedToLName ? `, ${_.upperCase(_.first(taggedToLName))}.` : ''}`;
  const taggedByDisplayName = `${taggedByFName}${taggedByLName ? `, ${_.upperCase(_.first(taggedByLName))}.` : ''}`;

  const currentUserId = _.get(currentUser, 'id');
  // users other than taggedby and taggedto
  const isThirdPerson = currentUserId !== taggedtoEncodedId && currentUserId !== taggedbyEncodedId;

  let displayText = '--';
  let textStyles = { 
    fontSize: 12, 
    fontWeight: 400, 
    lineHeight: '18px', 
    color: '#6B7178',
    userSelect: 'none'
  };

  if(isThirdPerson) {
    displayText = `${taggedByDisplayName} tagged ${taggedToDisplayName} to view`;
  } else if (currentUserId === taggedbyEncodedId) {
    const isSelfTag = taggedbyEncodedId === taggedtoEncodedId;
    displayText = `You tagged ${isSelfTag ? 'yourself' : `${taggedToDisplayName} - Unread`}`;
    textStyles = { ...textStyles, ...isSelfTag ? { fontWeight: 500, color: '#EF973B' } : {}};
  } else if (currentUserId === taggedtoEncodedId) {
    displayText = `${taggedByDisplayName} tagged you to view this message`;
    textStyles = { ...textStyles, fontWeight: 500, color: '#EF973B' };
  }

  return (
    <div>
      {
        currentUserId === taggedtoEncodedId &&
        <Tooltip
          overlayClassName='chat-tag-mark-as-read-tooltip'
          title='Mark as read'
          trigger='hover'
        >
          <img
            // className={translatedText ? 'disabled' : ''}
            src='/image/tag-mark-as-read.svg'
            // onClick={handleTranslate}
            style={{ marginRight: 4, cursor: 'pointer' }}
            height={16}
            onClick={() => chatHelper.toggleMarkTagMessage(
                            tagObj, 
                            () => chatHelper.showMessage('success', 'Message mark as read')
                          )}
          />
        </Tooltip>
      }
      <span style={textStyles}>
        {displayText}
      </span>
    </div>
  );
};

// originalText and originalTextLanguage exist => text is already translated
const ChatMessagePopover = props => {
  const {
    children,
    msgObjEntry,
    storeChatTranslation,
    prevTranslation,
    disabled,
    hideTranslation = false,
    hideTag = false,
    messageId,
    currentUser,
    patientCareTeamMembers,
    tagObj,
    updateChatTag
  } = props;
  const { text: messageText = '', originalText, originalTextLanguage } = msgObjEntry || {};

  const shouldHideTranslation = hideTranslation || !messageText;
  // Pubnub will not have message Id
  const shouldHideTag = hideTag || !messageId ||  _.isEmpty(tagObj) || !tagObj.can || (!tagObj.tagged && DISABLE_TAG);
  const isPopoverDisabled = disabled || (shouldHideTranslation && shouldHideTag);

  let { translatedText, detectedSourceLanguage } = originalText ?
    { translatedText: messageText, detectedSourceLanguage: originalTextLanguage } :
    ((Object.keys(prevTranslation).length && prevTranslation) || {});

  const shouldShowTranslation = originalText || detectedSourceLanguage !== LANGUAGE_MAP.English;

  const handleTranslate = async (e) => {
    e.preventDefault();
    Mixpanel.track('clicked', 'translation', 'chat_message');
    if (!messageText || translatedText)
      return;

    try {
      const resp = await getTranslate(messageText, LANGUAGE_MAP.English);
      const translation = _.get(resp, 'data.translations.0') || {};
      storeChatTranslation(translation);
    } catch (e) {
      console.error(e);
    }
  };

  const renderChildren = () => {
    const childrenFn = typeof children === 'function' ? 
                        children : 
                        () => children;
    const renderChatTranslatedText = (shouldHideCredit) => {
      return renderTranslatedTextView(translatedText, originalText, shouldShowTranslation, shouldHideCredit)
    }
    return childrenFn(renderChatTranslatedText, originalText);
  }

  return !isPopoverDisabled ? (
    <Popover
      overlayClassName='chat-message-popover'
      trigger='hover'
      placement='topRight'
      align={{ offset: [18, 15] }}
      content={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            !shouldHideTranslation &&
            <Tooltip
              overlayClassName={!shouldShowTranslation ? 'chat-translation-tooltip' : 'chat-translate-tooltip'}
              title={!shouldShowTranslation ? 
                (
                  <div className='chat-translation-tooltip-content'>
                    <Icon type='info-circle' theme='twoTone' twoToneColor='#3177C9' />
                    Original content is English
                  </div>
                ) : translatedText ? '' : 'Translate'
              }
            >
              <img
                className={translatedText ? 'disabled' : ''}
                src='/image/translate.svg'
                onClick={handleTranslate}
              />
            </Tooltip>
          }
          {
            !shouldHideTag &&
            <div style={{ marginLeft: 8 }}>
              <ChatMessageTagPopover
                currentUser={currentUser}
                patientCareTeamMembers={patientCareTeamMembers}
                messageId={messageId}
                tagObj={tagObj}
                updateChatTag={updateChatTag}
              />
            </div>
          }
        </div>
      )}
    >
      {renderChildren()}
    </Popover>
  ) : renderChildren();
};

ChatMessagePopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  msgObjEntry: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  patientCareTeamMembers: PropTypes.array.isRequired,
  tagObj: PropTypes.object,
};

ChatMessagePopover.displayName = 'ChatMessagePopover';

export default connect(
  (state, ownProps) => {
    const selectedChannel = state.chat.main.selectedChannel;
    const translation = state.chat.main.translations[selectedChannel] || {};
    return {
      selectedChannel,
      prevTranslation: translation[ownProps.timetoken] || {}
    };
  },
  (dispatch, ownProps) => ({
    storeChatTranslation: (translation) => dispatch(actions.setChatTranslation(translation, ownProps.timetoken))
  })
)(ChatMessagePopover);