import React from 'react';
import moment from 'moment-timezone';

const GENERATED_ON_FORMAT = 'hh:mm a [timezone], MM/DD/YYYY';
const DUE_DATE_MAP = {
	MTPR: 24,
	MRE: 48,
};

const MonthlyReviewHeaderComponent = ({ 
  type, 
  generatedDate, 
  dueDate: _dueDate, 
  showDueDate = true,
  draftInfo,
}) => {
  const { draftSavedBy, draftSavedAt } = draftInfo;
  const calculateDueDateValue = () => {
		const now = moment();
		const hoursToAdd = DUE_DATE_MAP[type];
		if (hoursToAdd === undefined) return 'N/A';
		const dueDate = _dueDate || moment(generatedDate).add(hoursToAdd, 'hour');
		
		if (dueDate.isBefore(now)) {
			return 'overdue';
		}
		if (dueDate.diff(now, 'hour') < 1) {
			return 'less than an hour';
		}
		return dueDate.diff(now, 'hour') + ' hour';
	}

  return (
    <div className="monthly-review-header">
      <div className="monthly-review-header-item">
        <div>Generated on:</div>
        <div>{moment(generatedDate).tz('America/Los_Angeles').format(GENERATED_ON_FORMAT).replace('timezone', 'PST')}</div>
      </div>
      {showDueDate && <div className="monthly-review-header-item">
        <div>Due in:</div>
        <div>{calculateDueDateValue()}</div>
      </div>}
      {
        draftSavedBy &&
        <div className="monthly-review-header-item">
          Draft saved by
          {" "}
          {_.get(draftSavedBy, 'profile.fullName')}
          {" "}
          on
          {" "}
          {moment(draftSavedAt).format('MM/DD hh:mm a')}
        </div>
      }
    </div>
  );
}

export default MonthlyReviewHeaderComponent;
