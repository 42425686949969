import React from 'react';
import { CONSENT_TYPE, CONSENT_DATE_FORMAT } from '../../constants';
import ConsentFormTemplate from './ConsentFormTemplate';
import ConsentOptionsComponent from './ConsentOptionsComponent';
import Client from 'libModule/gqlClient';
import { helpers } from '../../../../visit/helpers';
import { Select, DatePicker, Input, Button, message } from 'antd';
import API from '../../../../VideoChat/API';
import reducerActions from '../../../../VideoChat/action/reducerActions';
import moment from 'moment';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import I18N from '../../../../I18N';
import '../../css/consentForm.scss';
import ConsentFormLanguageSelector from './ConsentFormLanguageSelector';
import EditCareTeam from '../../../programTab/query/editCareTeam';
const { Option } = Select;

class ConsentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    const effectiveDate = _.get(props, 'consentInfo.effectiveDate', moment());
    const providers = _.get(props, 'consentInfo.providers', []);
    const consentType = _.get(props, 'consentInfo.consentType') || CONSENT_TYPE.APP;
    const providersIds = providers ? _.map(providers, p => p.id) : [];
    const consentProviderName = _.get(props,'consentProviderName');
    const currentUserInfo = this.getCurrentUserInfo();
    this.state = {
      consentType,
      providers: providersIds,
      doctorsNames: this.getDoctorsNames(providersIds),
      effectiveDate: moment(effectiveDate),
      effectiveDateUpdated: false,
      providerUpdated: false,
      careTeamSignature: '',
      template: null,
      consentProviderName,
      submitting: false,
      currentUserFullName: currentUserInfo.fullName,
      callInfo: this.getCallInfo(),
      language: _.get(props, 'language'),
      languageGroupId: _.get(props, 'languageGroupId'),
      consentDate: null
    };
  }

  getCallInfo = () => {
    const { phoneNumbers } = this.props;
    const lastCall = JSON.parse(sessionStorage.getItem('call-center-last-call') || '{}');
    const currentUserInfo = this.getCurrentUserInfo();
    const info = {
      caller: currentUserInfo.fullName,
      callerId: currentUserInfo.id,
    }

    if (Array.isArray(phoneNumbers) && phoneNumbers.includes(lastCall.phoneNumber)) {
      info.callNumber = lastCall.phoneNumber;
      info.callDate = lastCall.startTime;
    }

    return info;
  }

  handleConsentTypeChange = (type) => {
    this.setState({ 
      consentType: type, 
      careTeamSignature: '', 
      consentDate: type === CONSENT_TYPE.VERBALLY ? moment() : null
    });
  }

  handleProviderChange = (providers) => {
    this.setState({ providerUpdated: true, providers, doctorsNames: this.getDoctorsNames(providers) });
  }

  handleTemplateChange = (template) => {
    this.setState({ template });
  }

  getDoctorsNames = (providerIds) => {
    const doctorOptions = helpers.filterDoctors({ shortName: false });
    return doctorOptions.filter(({ id }) => _.includes(providerIds, id)).map(p => p.fullName);
  }

  renderProvider = () => {
    const { providers } = this.state;
    const doctorOptions = helpers.filterDoctors({ shortName: false });

    return (
      <React.Fragment>
        <strong className='consent-form-provider-label'>{I18N.get('consentForm.label.provider')}</strong>
        <Select
          mode="multiple"
          placeholder="Please select"
          style={{ width: '100%', alignSelf: 'center' }}
          onChange={this.handleProviderChange}
          defaultValue={providers}
        >
          {_.map(doctorOptions, (program) =>
              <Option value={program.id} key={program.id}>{program.fullName}</Option>
          )}
        </Select>
      </React.Fragment>
    );
  }

  handleSignatureChange = (evt) => {
    this.setState({ careTeamSignature: evt.target.value });
  }

  renderCareTeamForm = () => {
    const { careTeamSignature } = this.state;

    return (
      <div className='consent-form-device-initials'>
        <strong>{I18N.get('consentForm.label.device.legalStatement')}</strong>
        <span>{I18N.get('consentForm.label.device.text')}</span>
        <Input style={{ width: 248 }} onChange={this.handleSignatureChange} value={careTeamSignature} />
      </div>
    );
  }

  checkIfPaperConsent = consentType => consentType === CONSENT_TYPE.PAPER;

  isButtonDisabled = () => {
    const { template, careTeamSignature, consentType, providers = [], consentDate } = this.state;
    const isCareTeamSignatureValid = consentType !== CONSENT_TYPE.TEAM_MEMBER_DEVICE || careTeamSignature;
    const isPaperConsent = this.checkIfPaperConsent(consentType);
    return isPaperConsent ?
       (!providers.length || !consentDate)
       :
       (!template || !template.isValid || !isCareTeamSignatureValid);
  }

  afterSubmit = async (editCTRes) => {
    const { consentType } = this.state;
    const { setConsentInfo, refetchConsentInfo, setShowConsentForm, memberId } = this.props;
    let res;
    switch (consentType) {
      case CONSENT_TYPE.APP:
        res = await this.handleAppSubmit();
        break;
      case CONSENT_TYPE.TEAM_MEMBER_DEVICE:
        res = await this.handleCareTeamSignatureSubmit();
        break;
      case CONSENT_TYPE.VERBALLY:
        res = await this.handleVerbalSubmit();
        break;
      case CONSENT_TYPE.PAPER:
        res = await this.handlePaperSubmit();
        break;
      default:
        break;
    }
    this.setState({ submitting: false }, () => {
      if (res && setConsentInfo) {
        setConsentInfo(memberId, res);
      }
      if (setShowConsentForm && refetchConsentInfo) {
        setShowConsentForm(false, refetchConsentInfo);
      }
    });
  }

  handleAppSubmit = async () => {
    const { memberId,consentProviderName } = this.props;
    const { providers, effectiveDate } = this.state;
    const timezone = moment.tz.guess();

    const appConsentVariables = {
      consentProviderName,
      memberId, 
      providers, 
      effectiveDate: effectiveDate.valueOf(), 
      timezone, 
      consentType: CONSENT_TYPE.APP
    };

    const res = await API.enrollPatientConsentMutate(appConsentVariables);
    message.success('Consent form has been sent.');
    return _.get(res, 'data.enrollPatientConsent');
  }

  submitNonAPP = async (consentType) => {
    const { memberId } = this.props;
    const { template, providers, effectiveDate, consentDate } = this.state;
    const timezone = moment.tz.guess();
    const fileContent = template.content;

    const nonAppConsentVariables = {
      memberId, 
      providers, 
      effectiveDate: (consentDate || effectiveDate).valueOf(), 
      timezone, 
      fileContent, 
      consentType
    };

    const res = await API.createAndSignConsentMutation(nonAppConsentVariables);
    message.success('Consent form signed!');
    return _.get(res, 'data.createAndSignConsent');
  }

  handleCareTeamSignatureSubmit = async () => {
    return await this.submitNonAPP(CONSENT_TYPE.TEAM_MEMBER_DEVICE);
  }

  handleVerbalSubmit = async () => {
    return await this.submitNonAPP(CONSENT_TYPE.VERBALLY);
  }

  handlePaperSubmit = async () => {
    const { memberId } = this.props;
    const { consentType, providers, effectiveDate, consentDate } = this.state;

    const paperConsentVariables = {
      memberId,
      providers,
      effectiveDate: (consentDate || effectiveDate).valueOf(),
      signatureAt: consentDate,
      timezone: moment.tz.guess(),
      consentType
    };

    return await API.createPaperConsentMutation(paperConsentVariables);
  }

  onSubmit = () => {
    const { changeCTVal, openErrorModal } = this.props;
    this.setState({ submitting: true });
    Client.mutate({
      mutation: EditCareTeam,
      variables: changeCTVal
    }).then(editCTRes => {
      message.success('Care team Updated');
      this.afterSubmit(editCTRes);
    }).catch(err => {
      console.error(err);
      openErrorModal(`You're not authorized to make this change since you're not in this patient's care team`);
    })
  }

  openGraphQLErrorModal = (e) => {
    this.props.openErrorModal(GQLHelper.formatError(e));
  }

  getCurrentUserInfo = () => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const fullName = _.get(currentUser, 'profile.fullName');
    const id = _.get(currentUser, 'id');
    return { fullName, id };
  }

  selectConsentFormLanguage = (value, languageGroupId = null) => this.setState({ language: value, languageGroupId });

  handleChangeConsentDate = (consentDate) => this.setState({ consentDate });

  renderConsentDateSelector = (isPaperConsent) => {
    const { consentDate } = this.state;

    const label = isPaperConsent ? 
                  I18N.get('consentForm.label.paper.consentDate') :
                  I18N.get('consentForm.label.verbally.consentDate');

    return (
      <React.Fragment>
        <strong className='consent-form-date-label'>{label}</strong>
        <DatePicker
          style={{ width: 248 }}
          value={consentDate}
          onChange={this.handleChangeConsentDate}
          format={CONSENT_DATE_FORMAT}
        />
      </React.Fragment>
    );
  }

  render() {
    const { memberFullname, memberId,consentProviderName,className, isEnrolled } = this.props;
    const { doctorsNames, consentType, effectiveDate, submitting, currentUserFullName, callInfo, language, languageGroupId , consentDate} = this.state;
    const isPaperConsent = this.checkIfPaperConsent(consentType);
    const shouldShowProviderOptions = isPaperConsent || !consentProviderName;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className={className}>
        <div style={{ flex: 1, marginRight: 15, height: 700 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
            <ConsentFormLanguageSelector
              language={language} 
              setLanguage={this.selectConsentFormLanguage} 
              isDisabled={!!isPaperConsent}
            />
          </div>
          {
            !isPaperConsent ?
              <ConsentFormTemplate
                language={language}
                languageGroupId={languageGroupId}
                consentProviderName={consentProviderName}
                patientId={memberId}
                patientName={memberFullname}
                doctorsNames={doctorsNames}
                effectiveDate={consentDate || effectiveDate}
                consentType={consentType}
                careTeamFullname={currentUserFullName}
                callInfo={callInfo}
                onChange={this.handleTemplateChange}
                openErrorModal={this.openGraphQLErrorModal}
              />
            :
              <div id='consent-template'>
                No available preview for Paper consent form
              </div>
          }
        </div>
        <div style={{ flex: 1, marginLeft: 15, display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' }}>
            <div>
              <div className='consent-form-header-title' style={{ margin: '40px 0px 22px' }}>
                {I18N.get('consentForm.label.changeCTHeader')} 
              </div>
              <div className='consent-form-option-header-title' style={{ margin: '40px 0px 22px' }}>
                {I18N.get('consentForm.label.optionHeader')}  
              </div>
              <div style={{ marginRight: 153 }}>  
                <ConsentOptionsComponent onChange={this.handleConsentTypeChange} value={consentType} isEnrolled={isEnrolled} />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                  {  shouldShowProviderOptions ?
                      <div style={{flex: 1, marginRight: 5}}>
                        {this.renderProvider()}
                      </div> :''
                  }
                  {  !shouldShowProviderOptions ? <div style={{flex: 1, marginRight: 5}}></div> :'' }
                </div>
                {
                  consentType === CONSENT_TYPE.TEAM_MEMBER_DEVICE && 
                  <div style={{ marginTop: 20 }}>
                    {this.renderCareTeamForm(consentProviderName)}
                  </div>
                }
                {
                  (isPaperConsent ||
                    consentType === CONSENT_TYPE.VERBALLY) &&
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: shouldShowProviderOptions ? 20 : 40 }}>
                      {this.renderConsentDateSelector(isPaperConsent) }
                    </div>
                }
              </div>
            </div>
            <Button
              type="primary"
              onClick={this.onSubmit}
              disabled={this.isButtonDisabled() || submitting}
              style={{ alignSelf: 'flex-end' }}
              loading={submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    setConsentInfo: (memberId,consentInfo) => dispatch(reducerActions.setConsentInfo(memberId,consentInfo)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
  }
};

export default connect(null, mapDispatch)(ConsentFormComponent);
