import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql } from "react-apollo";
import { Modal, message } from 'antd';
import ClinicInfoComponent from '../components/ClinicInfoComponent';
import ClinicMemberContainer, {defaultTeamVariables} from './ClinicMemberContainer';
import DetailFormComponent from '../components/DetailFormComponent';
import SearchOrgComponent from "../components/SearchOrgComponent";

import API from '../../API';
import { browserHistory } from 'react-router';
import query from '../../query';
import helper from '../../helper';
import moment from 'moment-timezone';
import {goPath} from "../../../../lib/utils";
import { clinicTabKeyMap } from '../../constant/formConfig';

const {confirm} = Modal;

export class ClinicManagementContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showDetail: false,
            curParentName: null,
            teamList:[],
            teamListPageInfo: { },
            loadingTeamList:false,
            selectedTab: _.first(_.keys(clinicTabKeyMap)),
            teamListInitiallyLoaded: false,
        }

        this.onClickDetail = this.onClickDetail.bind(this);
        this.onCancelDetail = this.onCancelDetail.bind(this);
    }
    fetchAndSetTeam = async (variables)=>{
        this.setState({
            loadingTeamList: true
        })
        let data = await API.getTeamList(variables);
        let teamList = _.get(data,'data.teamList');
        this.setTeam( teamList );
        if (!this.state.teamListInitiallyLoaded) {
          this.setState({ teamListInitiallyLoaded: true });
        }
    }

    refetchAndUpdateTeam = async (teamId) => {
      this.setState({ loadingTeamList: true });
      let data = await API.getTeam({ id: teamId });
      const updated = _.get(data,'data.team');
      const newTeamList = [...(this.state.teamList || [])];
      const teamIndexInState = _.findIndex(newTeamList, { id: teamId });
      newTeamList.splice(teamIndexInState, 1, {
        ...newTeamList[teamIndexInState],
        ...updated
      });
      this.setState({
        teamList: newTeamList,
        loadingTeamList: false,
      });
    }

    setTeam = (teamList)=>{
        const {  data,pageInfo } = teamList;
        const newTeamList = [...data];
        // add corresponding refetch for each team
        newTeamList.forEach((d) => {
          d.refetchCareTeamList = () => this.refetchAndUpdateTeam(d.id);
        });
        this.setState({
            teamList:[
                ...this.state.teamList,
                ...newTeamList
            ],
            loadingTeamList:false,
            teamListPageInfo:pageInfo
        })
    }

    appendTeam = async (team)=>{
        let res = await API.getTeam({ id:team.id });
        let teamFromRes = _.get(res,'data.team');
        this.setState({
            teamList:[
                teamFromRes,
                ...this.state.teamList,
            ]
        })
    }

    deleteTeam = (teamId) => {
      const newTeamList = _.filter(this.state.teamList, t => t.id !== teamId);
      this.setState({ teamList: newTeamList });
    }

    setParentName = (curParentName) => {
        this.setState({
            curParentName
        })
    }

    editClinic = (localThis) => {
        const { refetch, form, organization } = localThis.props;
        const { validateFields } = form;
        const id = organization.id;
        const isDemo = localThis.state.isDemo;
        const prevParent = organization.parent;
        const { managers } = organization
        const prevClinicManagerList = _.map(managers, m => m.id)

        validateFields((err,res) => {
            if(!err) {
                let { internalName, businessName, brandName, description, timeZone, areaBelong, 
                    clinicNumber, location, city, state, zip, siteManager, consentForm, orgPermissions,
                    visitServeTimeStart, visitServeTimeEnd, programParticipation, EHR
                } = res;

                this.setState({
                    creatingOrg: true
                })

                const address = {}
                address.state = state;
                address.postCode = zip;
                address.city = city;
                address.streetName = location;
                address.streetNumber = '121'
                address.country = 'US'

                const phone = {}
                phone.countryCode = '1';
                phone.number = clinicNumber ? clinicNumber : ' ';
                phone.type = 'WORK'

                const variables = {
                    id,
                    name: businessName ? businessName : ' ',
                    internalName: internalName,
                    brandedName: brandName,
                    timezone: timeZone,
                    address,
                    parentId: areaBelong, 
                    description,
                    logo:localThis.state.logo,
                    phone,
                    templateGroupIds: consentForm,
                    managerIds: siteManager,
                    excludeBilling: isDemo,
                    identifier: internalName,
                    orgPermissions,
                    programParticipation,
                    EHR
                }

                const dateFormat = 'YYYY-MM-DD';
                const dateTimeFormat = 'YYYY-MM-DD HH:mm';
                const visitServeTimezone = 'America/Los_Angeles';
                const startTime = moment.tz(`${moment().format(dateFormat)} ${visitServeTimeEnd}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();
                const endTime = moment.tz(`${moment().add(1, 'day').format(dateFormat)} ${visitServeTimeStart}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();
                
                const clinicScheduleVariables = {
                    affectiveDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
                    blockingRoles: ['MA', 'MD'],
                    startTime,
                    endTime,
                };

                if (organization.orgSchedules.length > 0) {
                    clinicScheduleVariables.id = organization.orgSchedules[organization.orgSchedules.length - 1].id;
                } else {
                    clinicScheduleVariables.organizationId = id;
                }

                const { addedList, removedList } = helper.getAddedAndRemovedIds(prevClinicManagerList, siteManager);
                const managersChanged = (addedList.length) || (removedList.length);
                const cb = ()=>{
                    message.info('Clinic details updated!')
                    refetch();
                    this.onCancelDetail();
                }
                
                if(prevParent && (prevParent.id !== areaBelong)) {
                    this.renderPopUp(localThis, variables);
                } else {
                    API.editClinic(variables)
                    .then(() => {
                        if(managersChanged){
                            return helper.addAndRemoveManager(addedList, removedList, id)
                        }
                    })
                    .then(() => {
                        if (organization.orgSchedules.length > 0) {
                            return API.editClinicSchedule(clinicScheduleVariables);
                        } else {
                            return API.createClinicSchedule(clinicScheduleVariables);
                        }
                    })
                    .then((res) => {
                        console.log(res);
                        cb();
                    })
                    .catch(e=>{
                      console.log(e);
                      message.error(e.message);
                    })
                }
            }
        })
    }

    onClickDetail = () => {
        const curURL = window.location.href;
        goPath(`${curURL}/edit`);
    }

    onCancelDetail = () => {
        this.setState({
            showDetail: false,
            curParentName: null
        })
    }

    renderPopUp = (localThis, variables) => {
        const { organization, refetch } = localThis.props;
        // If any change apply to this clinic's internal name, use the updated name first, then use the previous internal name
        const clinicName = variables.internalName || organization.internalName;
        const curName = this.state.curParentName;
        const curThis = this
        const msg = 'Are you sure you want to move ' + clinicName + ' under ' + curName;

        confirm({
            title: msg,
            onOk() {
                API.editClinic(variables)
                    .catch( e => {
                        console.log(e);
                        return
                    })
                    .then( res => {
                        message.info('Clinic details updated!')
                        refetch();
                        curThis.setState({
                            showDetail: false
                        })
                    })
            }
          });
    }

    setSelectedTab = (newSelectedTab) => {
      this.setState({ selectedTab: newSelectedTab }, () => {
        if (newSelectedTab === 'CARE_TEAM' && !this.state.teamListInitiallyLoaded) {
          const selectedOrgId = _.get(this, 'props.params.orgId');
          const variables = {
              ...defaultTeamVariables,
              filters: {
                  organizationId: selectedOrgId
              }
          }
          this.fetchAndSetTeam(variables);
        }
      });
    }

    render() {
        const displayName = 'Clinic Settings';
        const { fetchAndSetTeam,setTeam,appendTeam, deleteTeam } = this;
        const { teamList,loadingTeamList,teamListPageInfo, selectedTab} = this.state;
        const { organization,  templateGroupsList } = this.props;
        const isViewerOrgLeaf = helper.checkIsCurrentOrganizationLeaf();
        if(!organization) {
            return this.props.loading
        }
        return (
            <div>
              {
                !isViewerOrgLeaf && // Site Manager doesn't need searchOrg feature
                <SearchOrgComponent/>
              }
                <ClinicInfoComponent {...this.props} onClickDetail={this.onClickDetail} displayName={displayName}/>
                <ClinicMemberContainer 
                  {...this.props}  
                  careTeamList={teamList} 
                  appendTeamToFront={appendTeam}
                  deleteTeam={deleteTeam}
                  loadingTeamList={loadingTeamList} 
                  teamListPageInfo={teamListPageInfo} 
                  fetchTeamList={fetchAndSetTeam} 
                  selectedTab={selectedTab}
                  setSelectedTab={this.setSelectedTab}
                />

                {this.state.showDetail && this.renderDetail()}
            </div>
        )
    }

    renderDetail() {
        const { organization } = this.props;
        const name = _.get(organization, 'internalName') || '';
        return (
            <Modal width={720}
                   style={{height:548}}
                   title={name}
                   visible={true}
                   footer={null}
                   onCancel={this.onCancelDetail}
                   maskClosable={false}
            >
                {this.renderDetailComponent()}
            </Modal>
        );
    }

    renderDetailComponent() {
        const { organization } = this.props;
        const isDemo = organization.excludeBilling ? organization.excludeBilling : false;
        const managers = organization.managers ? organization.managers : [];
        const templateGroups = organization.templateGroups
        let templateGroupIds = [];
        let managerIds = [];
        _.forEach(managers, (manager) => {
            managerIds.push(manager.id);
        })
        _.forEach(templateGroups, (template) => {
            templateGroupIds.push(template.id);
        })
        
        return (
            <DetailFormComponent {...this.props} editClinic={this.editClinic} onCancelDetail={this.onCancelDetail} isDemo={isDemo} managerIds={managerIds} templateGroupIds={templateGroupIds} setParentName={this.setParentName}/>
        ) 
    }
}

const orgInfo = graphql(query.organization,{
    options: (ownProps) => {
        const curUrl = browserHistory.getCurrentLocation().pathname;
        const urlArr = _.split(curUrl, '/');
        const url = urlArr[urlArr.length - 1];
        return {
            variables: {
                id: url
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organization, loading, refetch, error } = data;
        if(error) {
            return { error }
        }
        return {
            organization,
            loading,
            refetch
        }
    }
})

const areaList = graphql(query.organizationList,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const rootId = _.get(currentUser,'selectedRole.organization.id');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                rootId
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organizationList,loading,refetch } = data;
        return {
            orgList: _.get(organizationList,'data',[]),
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})

const templateGroups = graphql(query.templateGroupsListQuery, {
    options: (ownProps) => {
        const variables = {
            count:100,
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { templateGroupOrganizationsList, loading,refetch } = data;
        return {
            templateGroupsList: templateGroupOrganizationsList,
            templateLoading: loading,
            refetchTemplate: refetch
        }
    }
})

export default compose(orgInfo, /*areaList,,*/templateGroups) (ClinicManagementContainer)