
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

import '../css/chatIcon.scss';

const ChatIcon = props => {
  const {  
    alwaysShowIcon = false,
    chatFullyLoaded,
    shouldShowRedDot, 
    handleToggleChat,
    containerStyles = {},
    badgeStyles = {},
    imageStyles = {},
    imageClassName = ''
  } = props;

  return (alwaysShowIcon || !!chatFullyLoaded) && (
    <div id='custom-chat-icon' style={containerStyles}>
      <Badge dot={shouldShowRedDot} style={badgeStyles}>
        <img src='/image/patient-chat-icon.png'
             onClick={handleToggleChat} 
             style={imageStyles}
             className={imageClassName}
        />
      </Badge>
    </div>
  );
};

ChatIcon.propTypes = {
  alwaysShowIcon: PropTypes.bool,
  chatFullyLoaded: PropTypes.bool.isRequired, 
  shouldShowRedDot: PropTypes.bool.isRequired, 
  handleToggleChat: PropTypes.func.isRequired,
  containerStyles: PropTypes.object,
  badgeStyles: PropTypes.object,
  imageStyles: PropTypes.object,
  imageClassName: PropTypes.string
};

export default ChatIcon;