import { graphql } from 'react-apollo';
import {
  boostedPatientList as boostedPatientListQuery,
  boostedPatientListPageInfoOnly as boostedPatientListPageInfoOnlyQuery,
  boostedPatientListForQueue as boostedPatientListForQueueQuery,
} from './query/boostedPatientList';
import { MAX_PATIENT_PER_PAGE, DEFAULT_PATIENT_LIST_SORT } from '../constants';
import { parseFilters } from '../helpers';

export const getTODORPMPatientListCount = graphql(boostedPatientListPageInfoOnlyQuery, {
  options: (ownProps) => {
    const {
      filters = {},
    } = ownProps;

    const parsedFilters = parseFilters(filters);

    return {
      variables: {
        filters: parsedFilters,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    };
  },
  props: ({ data }) => {
    const { loading, error, boostedPatientList, refetch } = data;

    if (error) {
      console.error(error);
    }

    return {
      loadingBoostedPatientListPageInfo: loading,
      boostedPatientListPageInfo: _.get(boostedPatientList, 'pageInfo'),
      refetchBoostedPatientListPageInfo: refetch
    };
  }
});

const _buildRPMPatientListAPI = (query, options, props) => graphql(query, {
  options: options || ((ownProps) => {
    const {
      filters = {},
      count,
      page = {},
    } = ownProps;

    const parsedFilters = parseFilters(filters);

    return {
      variables: {
        filters: parsedFilters,
        page: page.current,
        count: count || MAX_PATIENT_PER_PAGE,
        sort: DEFAULT_PATIENT_LIST_SORT,
      },
      fetchPolicy: count <= MAX_PATIENT_PER_PAGE ? 'network-only' : 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    };
  }),
  props: props || (({ data }) => {
    const { loading, error, boostedPatientList, refetch } = data;

    if (error) {
      console.error(error);
    }

    return {
      loadingBoostedPatientList: loading,
      boostedPatientList: _.get(boostedPatientList, 'data'),
      boostedPatientListCount: _.get(boostedPatientList, 'pageInfo.total'),
      boostedPatientListLastPage: _.get(boostedPatientList, 'pageInfo.lastPage'),
      refetchBoostedPatientList: refetch,
    };
  }),
});

export const getRPMPatientList = _buildRPMPatientListAPI(boostedPatientListQuery);

export const getRPMPatientQueue = _buildRPMPatientListAPI(boostedPatientListForQueueQuery);