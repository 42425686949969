import { React, IHTable } from 'ihcomponent';
import {
	DAYS_OF_MEASUREMENT_FILTERS,
} from "../../patientList/main/constants/MainConstants";
import UserClass from 'modulesAll/graphql/class/User';

import UnderlinedTooltip from "../../patientList/main/components/UnderlinedTooltip";
import I18N from "../../I18N";
import { goPath} from "../../../lib/utils";
import {renderSelfTag} from "../../visitsPage/helpers";
import helper from "../../remoteEnrollment/helper";
import {getComplianceRes, getEnrollerRoleType, getTotalTime, hasCCMOrRPM} from "../../patientList/main/helper";
import {Icon, Popover} from "antd";
import ClaimBillableTimeForm from "../../patient/main/components/ClaimBillableTimeForm";
const DISPLAY_NOT_NECESSARY_VALUE = "Not Necessary";

const CrossClinicPatientsComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {

		}
	}
	renderAssignee = (data, path) => {
		const isNotNecessary = _.get(data, `user.variables.assignees.${path}NotNecessary`);
		const firstName = _.get(data, `user.variables.assignees.${path}.profile.firstName`, '');
		const lastName = _.get(data, `user.variables.assignees.${path}.profile.lastName`, '-');
		const fullName = isNotNecessary? DISPLAY_NOT_NECESSARY_VALUE :`${firstName} ${lastName}`;
		return fullName;
	}

	setShowClaimTimeLogModal = (showClaimTimeLogModal)=>{
		this.setState({
			showClaimTimeLogModal
		})
	}

	getAssigneeFilters = (assignees) => {
		let filters = _.map(assignees, ({fullName, id}) => ({text: fullName, value: id}));
		_.sortBy(filters, ['text']);
		filters.push({text: "Not Assigned", value: "null"});
		filters.push({text: DISPLAY_NOT_NECESSARY_VALUE, value : "not-necessary"});
		return filters;
	}
	handlePatientChange = (params)=>{
		const { organizationId,id,name } = params || {};
		const cb =(orgSwitched)=>{
			goPath(`/patients/${id}`);
			if (orgSwitched) helper.simulatePageReload();
		}
		helper.onOrganizationChange({organizationId,name},cb);
	}
	getTableProps(){
		const { filter, page,patientList,pageInfo,patientListLoading,refetchPatientList } = this.props;
		const { CAs=[], RDAndHCs=[] } = this.props;
		const onChange = (a, b, c) => this.props.onTableChange(a, b, c);
		const RDFilters = this.getAssigneeFilters(RDAndHCs);
		const CAFilters = this.getAssigneeFilters(CAs);

		const columns = [
			{
				title: 'Name',
				width: '13%',
				key: 'fullName',
				dataIndex: 'fullName',
				render: (t, doc) => {
					const isSelfEnrolled = doc.user._get('profile.isSelfEnrolled') ? doc.user._get('profile.isSelfEnrolled') : false;
					const patientId = doc.user._get('id');
					const { id,name } = doc.user    ._get('organization');
					return <a
						onClick={(e)=> {
							e.preventDefault();
							this.handlePatientChange({ organizationId:id,id:patientId,name })
						}}>
						{doc.user._get('profile.fullName')}{isSelfEnrolled ? renderSelfTag() : ""}
					</a>
				},
			},
			{
				title: 'Organization',
				render: (dummy, doc) => doc.user._get('organization.name')
			},
			{
				title: I18N.get('provider.label'),
				key:'doctorId',
				render:(dummy,doc)=>{
					return doc.user._get('profile.doctor.profile.fullName');
				}
			},
			{
				title: <UnderlinedTooltip
					tooltipMsg='Total # of billable days in this calendar month in which vitals (from an iHealth auto-synced device) were measured.'
				>
					RPM Days
				</UnderlinedTooltip>,
				key: 'monthlyMeasurementDaysRange',
				dataIndex: 'monthlyMeasurementDaysRange',
				render: (__, doc) => {
					const e = _.get(doc, 'o.user.monthlyMeasurementDays');
					return e >= 0 ? `${e} day${e > 1 ? 's': ''}`: '--';
				},
				filters: DAYS_OF_MEASUREMENT_FILTERS,
				filteredValue: _.get(filter, 'monthlyMeasurementDaysRange'),
			},
			{
				title:<UnderlinedTooltip
					tooltipMsg='Total billable time accrued in the calendar month (CCM)'
				>
					Time
				</UnderlinedTooltip>,
				key:'timeSpent',
				dataIndex:'timeSpent',
				// sorter:true,
				// sortOrder: _.get(sort, 'field', null) === 'timeSpent' ? _.get(sort, 'order', false) : null,
				filters: [
					{ value: `15:20`, text: '15-20 mins' },
					{ value: `21:34`, text: '21-34 mins' },
					{ value: '35:40', text: '35-40 mins' },
					{ value: `55:60`, text: '55-60 mins' }
				],
				filteredValue: _.get(filter, 'timeSpent'),
				render:(value,data)=>{
					const user = new UserClass(data);
					const totalTime = _.get(user,'variables.user.variables.monthlyTimeSpent.totalTime', 0) ;
					const timeInMins = Math.round(totalTime/60);
					return timeInMins + (timeInMins > 1 ? ` mins` : ` min`);
				}
			},
			{
				title: 'Enrolled Program',
				key: 'programsEnrolled',
				width: '12%',
				render: (t, doc) => {
					const categories = doc.user.format('CCM/RPM');
					let content = [];
					let enrollDiv = '';
					categories.forEach((o, i) => {
						if (o.enrolled) {
							content.push(_.toUpper(o.name));
						}
					})
					return <div>{content.length > 0 ? content.join(', ') : '--'}
						<div>{enrollDiv}</div>
					</div>
				},
				filters: [
					{ value: 'CCM', text: 'CCM only' },
					{ value: 'RPM', text: 'RPM only' },
					{ value: 'CCM,RPM', text: 'CCM + RPM' },
					{ value: 'CCM,MNT', text: 'CCM + MNT' },
					{ value: 'RPM,MNT', text: 'RPM + MNT' },
					{ value: 'CCM,RPM,MNT', text: 'CCM + RPM + MNT' },
					{ value: 'NONE', text: 'None' },
				],
				filteredValue: _.get(filter, 'programsEnrolled'),
				filterMultiple: false
			},
			{
				key:'compliance',
				title:'Billable',
				render:(doc)=>{
					const { content,header }  = getComplianceRes(doc,{ isFromList:true });
					const totalTime = getTotalTime(doc);
					const hasCCMOrRPMRes= hasCCMOrRPM(doc);
					const { setShowClaimTimeLogModal } = this;
					const { showClaimTimeLogModal } = this.state;

					const categories = doc.user.format('CCM/RPM');
					let programEnrolled = [];
					categories.forEach((o,i)=>{
						if(o.enrolled){
							programEnrolled.push(_.toUpper(o.name));
						}
					});
					const patientId = doc.user.key;
					const consentInfo = _.get(doc,'user.variables.consentInfo',{});
					const hasSigned = consentInfo && consentInfo.consent;
					const hasProgramEnrolled = programEnrolled.length;
					return hasSigned&&hasProgramEnrolled ? <Popover overlayStyle={{ width:700 }}
					                                                placement={'rightBottom'}
					                                                onVisibleChange={(visible)=>{
						                                                if(!visible) {
							                                                setShowClaimTimeLogModal(false)
						                                                }
					                                                }}
					                                                trigger={'click'}
					                                                visible={showClaimTimeLogModal==patientId}
					                                                destroyTooltipOnHide={true}
					                                                overlayClassName={'claimBillableTimePopover'}
					                                                content={<ClaimBillableTimeForm patientId={patientId}
					                                                                                user = { doc }
					                                                                                refetchMontlySpent={ refetchPatientList }
					                                                                                showTotalTimeNum={ true }
					                                                                                setShowModal = { setShowClaimTimeLogModal }
					                                                                                header = { header }
					                                                                                hasCCMOrRPMRes={hasCCMOrRPMRes}
					                                                                                totalTime={totalTime}/>}>
						<div onClick={()=>this.setShowClaimTimeLogModal(patientId)}>{ content }</div>
					</Popover> : '--';
				}
			},
			{
				title: 'Assigned RD/HC',
				key:'assignedToRD',
				dataIndex:'assignedToRD',
				filters: RDFilters,
				filteredValue: _.get(filter, 'assignedToRD'),
				render: (value, data) => this.renderAssignee(data, 'assignedToRD')
			},
			{
				title: 'Assigned CA',
				key:'assignedToCA',
				dataIndex:'assignedToCA',
				filters: CAFilters,
				filteredValue: _.get(filter, 'assignedToCA'),
				render: (value, data) => this.renderAssignee(data, 'assignedToCA')
			}
		]

		return {
			pagination: {
				current: _.get(page, 'current', 1),
				pageSize: _.get(page, 'pageSize', 10),
				total: _.get(pageInfo, 'total', 0),
			},
			columns,
			dataSource: _.map(patientList, l => ({
				user: new UserClass(l.user),
				o: l
			})),
			loading:patientListLoading,
			onChange
		}
	}

	render(){
		let tableProps = this.getTableProps();
		return <IHTable {...tableProps}  className={'patientList'}/>
	}
}

export default CrossClinicPatientsComponent;
