import React from 'react';
import { graphql } from 'react-apollo';
import getLoanDevice from '../loanDevices/query/getLoanDevice';
import getIoTDevice from '../DevicesAssign/query/iotDeviceList';
import getDeviceHubQuery from '../AdditionalInfo/query/getDeviceHub';
import { mappingDeviceModels } from './constants';
import { userDeviceRecordsTypeName } from '../../visitWorkList/constant/onboardMap';
import { CGMVitalDisplayTextComponent } from '../../CGM/components/CGMVitalDisplayTextComponent/CGMVitalDisplayTextComponent';
import { CellularDeviceVitalDisplayComponent } from '../../CellularDevices/components/CellularDeviceDisplayComponent/CellularDeviceVitalDisplayComponent';
import { getClearDeviceList } from '../../../lib/helpers/device-helpers';

const hubDevice = graphql(getDeviceHubQuery, {
  options: (ownProps) => {
    return {
      variables: {
        memberId: _.get(ownProps, 'data.user.id') || _.get(ownProps, 'patientId'),
      },
    };
  },
  props: ({ ownProps, data }) => {
    const { loading, getDeviceHub } = data;

    if (loading) return { loading };
    return { getDeviceHub };
  }
});

const loanDevice = graphql(getLoanDevice, {
  options: (ownProps) => {
    const memberId = _.get(ownProps, 'data.user.id') || _.get(ownProps, 'patientId');
    return { variables: { memberId } };
  },
  props: ({ data }) => {
    const getLoanDevice = _.get(data, 'getLoanDevice', null);
    const loading = _.get(data, 'loading');

    if (loading) return { loading };
    return { getLoanDevice };
  }
});

const ioTDevice = graphql(getIoTDevice, {
  options: (ownProps) => {
    const memberId = _.get(ownProps, 'data.user.id') || _.get(ownProps, 'patientId');
    return { variables: { memberId }, errorPolicy: 'ignore' };
  },
  props: ({ data }) => {
    const getIoTDevice = getClearDeviceList(_.get(data, 'iotDeviceList.data', null));
    const loading = _.get(data, 'loading');
    if (loading) return { loading };
    return { getIoTDevice };
  }
});

const renderDevices = props => {
  const { getDeviceHub, getIoTDevice = [], getLoanDevice } = props;
  const deviceRecords = _.omit(_.get(props, 'data.user.profile.deviceRecords'), '__typename');
  const devices = [];
  // set the value based on the assignment in patient details
  _.set(deviceRecords, userDeviceRecordsTypeName.TrackSet, !_.isEmpty(getIoTDevice));
  _.set(deviceRecords, userDeviceRecordsTypeName.iPhone, !!getLoanDevice);

  _.mapKeys(deviceRecords, (value, deviceType) => {
    if (value && (typeof value == 'boolean' || value == 'APP_SYNC') && mappingDeviceModels[deviceType]) {
      devices.push(`${mappingDeviceModels[deviceType]}`);
    }
  })

  // HUB is not tied to the deviceRecords
  if (getDeviceHub) devices.push('HUB-eDevice');
  const showDevices = _.uniq(devices);
  showDevices.push(<CGMVitalDisplayTextComponent />);
  showDevices.push(<CellularDeviceVitalDisplayComponent />);
  return (
    <div style={{ lineHeight: 2 }} className={"profile-keyvalue"}>
      <span style={{ fontWeight: 'bold' }}>Devices: </span>
      {
        showDevices.length ?
          _.map(showDevices, (device, index) =>
            <span className='v-tag' key={index}>{device}</span>
          )
          :
          'N/A'
      }
    </div>
  )
};

const deviceHelpers = {
  hubDevice,
  ioTDevice,
  loanDevice,
  renderDevices
};

export {
  deviceHelpers
};