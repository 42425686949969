import { Button, Modal } from 'antd';
import React, {
  useState,
  Fragment,
} from 'react';
import { CellularDeviceUnassignModalComponent } from '../CellularDeviceUnassignModalComponent/CellularDeviceUnassignModalComponent';

export const CellularDeviceUnassignButtonComponent = ({
  children,
  disabled,
  onUnassign,
  modalWidth,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleToggleShowModal = () => setShowModal(!showModal);

  const handleUnassign = () => {
    if (children) {
      handleToggleShowModal();
      return;
    }
    if (onUnassign) onUnassign();
  };

  return (
    <Fragment>
      <Button
        type="primary"
        className="cellular-devices-unassign-button"
        size="small"
        onClick={handleUnassign}
        ghost
        disabled={disabled}
      >
        Unassign
      </Button>
      {
        (children && showModal)
          ? (
            <CellularDeviceUnassignModalComponent
              onCancel={handleToggleShowModal}
              width={modalWidth}
            >
              {children}
            </CellularDeviceUnassignModalComponent>
          ) : null
      }
    </Fragment>
  );
};