/**
 * Created by zizhangai on 1/2/17.
 */
import React, {Component} from 'react'
import moment from 'moment'
import { IHButton,message } from 'ihcomponent'
import { durationMap } from "../constants/map";
import I18N from 'modulesAll/I18N';
import { Button,Row,Col } from 'antd';
import { closeModal,openErrorModal } from 'layoutModule/actions/MainModal';
import { compose } from 'react-apollo'
import { connect } from 'react-redux';
import { GQLHelper } from 'libModule/utils'
import { Modal } from "antd/lib/index";
import { Input } from 'antd';
import createAccountEventHistory from '../../../graphql/mutation/createAccountEventHistory';
import { EDIT_DELETE_RULE } from '../constants/InterventionsConstants';
import { browserHistory } from 'react-router';
import Client from 'libModule/gqlClient';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';

const { TextArea } = Input;

class Note extends Component {

    state = {
        content: this.props.note.content, 
        canEdit: false, 
        isCreator: false,
        isEdit: false,
        isSaved: false,
        showRuleModal: false
    }

    handleInteraction = (description) => {
        const { onInteraction } = this.props;
        if (onInteraction) {
          onInteraction(description);
        }
      }    

    renderPauseFollowUp (row){
        const { tags,resumeDate,pauseDuration } = row;
        const durationInDays = _.find(durationMap,(i)=>i.value==pauseDuration);
        const durationValue = _.get(durationInDays,'name','unknown');

        return <div className='note-content'>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Reason: </span>{I18N.get(`providerNoteTags.${_.get(tags,0,'')}`)}
                </div>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Duration: </span>{durationValue}
                </div>
                <div className="intervention-notes-form-header note-content" >
                    <span style={{ fontWeight:'bold' }}>Est. Return Date: </span>{moment(resumeDate).format('MM/DD/YYYY')}
                </div>
              </div>
    }

    renderContentMap(category,row){
        const contentMap = {
            'PAUSE_FOLLOW_UP': this.renderPauseFollowUp(row)
        };
        const defaultContent =  '';

        return _.get(contentMap,category,defaultContent);
    }

    handleSyncPN = async () => {
      const { category, smartAlert, patientId } = this.props.note || {};
      const isOnWorkstation = browserHistory.getCurrentLocation().pathname.includes('workstation');
      if(patientId && category === 'SMART_ALERT' && isOnWorkstation && smartAlert && !smartAlert.closeAt) {
        // sync provider note when alert is not closed AND on workstation => refetch
        // request options must be same as in ProviderAlertResolveComponent
        const res = await Client.query({
          query: getPatientSmartAlert,
          variables: {  memberId: patientId },
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true
        }).catch(console.error);
        return res;
      }
      return;
    }

    handleDeletePN(id,category){
        return new Promise((resolve,reject)=> {

            Modal.confirm({
                title:  'Are you sure to delete this Provider Note ?',
                onOk: () => {
                    this.deletePN(id,category);
                },
                zIndex: 2000,
                okText: 'Confirm',
                cancelText: `Cancel`,
                onCancel: ()=>{
                    resolve(true);
                }
            })
        })
    }

    deletePN(id,category){
        const { props } = this;
        const { closeModal,openErrorModal,closeAfterDelete } = props;
        const deleteProviderNote  = _.get(props,'note.deleteProviderNote',_.get(props,'deleteProviderNote'));
        deleteProviderNote(id,category)
            .catch(e=>{
                openErrorModal(GQLHelper.formatError(e))
            })
            .then(async () => {
                this.handleInteraction('DELETE');
                if(this.props.refetch) {
                    this.props.refetch();
                }
                await this.handleSyncPN();
                message.success('Provider Note deleted');
            }).finally(() => {
              if(closeAfterDelete) {
                closeModal();
              }
            });
        
    }

    handleEditPN(id, content){
        const { props } = this; 
        const editProviderNote = _.get(props,'editProviderNote',_.get(props,'note.editProviderNote'));

        if (this.state.canEdit && !this.state.isEdit) {
            this.setState({isEdit: true, isSaved: false})
        }
        else if (this.state.canEdit && this.state.isEdit) {
            editProviderNote(id, content,id)
                .catch(e => {
                    openErrorModal(GQLHelper.formatError(e))
                })
                .then(() => {
                  this.setState({isSaved: true, isEdit: false}, () => {
                    this.handleSyncPN();
                  });
                    this.handleInteraction('EDIT');
                    this.setState({isSaved: true, isEdit: false})
                })
        }
    }

    isCreatorOfNote(){
        const { createdBy } = this.props.note;

        return createdBy.id === sessionStorage.currentUserId ? true : false;
    }

    getTimeDiff(){
        const { createdAt } = this.props.note;
        const timeNow = moment();
        const timeCreated = moment(createdAt);

        return timeNow.diff(timeCreated, 'minutes');
    }

    renderButtons(){
        const { id, category } = this.props.note;
        const minutesDiff = this.getTimeDiff();
        let buttonText = this.state.canEdit && this.state.isEdit ? 'Save' : 'Edit';

        if (!this.state.isCreator && this.isCreatorOfNote()) this.setState({isCreator: true});
        if (!this.state.canEdit && minutesDiff <= 1440 && this.state.isCreator) this.setState({canEdit: true});

        return (
            <div style={{display: this.state.isCreator ? 'flex' : 'none', paddingTop: 15, alignItems: 'center'}}>
                <Button style={{marginRight: 'auto'}} onClick={() => this.handleDeletePN(id,category)}>
                    Delete
                </Button>
                <span style={{display: this.state.isSaved ? 'initial' : 'none'}}>
                    <img src='image/icon_check.png' style={{height: 16, marginRight: 5}}/>
                    Edit saved!
                </span>
                <Button 
                    style={{display: this.state.canEdit ? 'initial' : 'none', marginLeft: 10}}
                    onClick={() => this.handleEditPN(id, this.state.content)}
                    type={this.state.canEdit && this.state.isEdit ? 'primary' : 'secondary'}
                >
                    {buttonText}
                </Button>
            </div>
        )
    }

    renderRuleModal(){
        if (this.state.showRuleModal){
            return (
                <Modal
                    visible={this.state.showRuleModal}
                    footer={null}
                    onCancel={() => this.setState({showRuleModal: false})}
                    bodyStyle={{fontSize: 16, padding: 25}}
                    zIndex={2000}
                >
                    <div style={{textAlign: 'center', fontWeight: 'bold', margin: '10px auto'}}>Edit and Delete Rule</div>
                    <div style={{margin: 10}}>{EDIT_DELETE_RULE}</div>
                </Modal>
            )
        }
    }

    render() {
        const { props } = this;
        const { category, createdBy, createdAt, content, error, id } = props.note;
        const { profile: {fullName} } = createdBy;
        const time = moment(createdAt).format("MMM-DD-YYYY HH:mm");
            // "MMM-DD-YYYY :mm a")

        return (
            <div className={`intervention-notes-single-note ${error && 'note-error'} `}>
                <div><label>Creator:</label> {fullName}</div>
                <div><label>Time Created:</label> {time}</div>
                <div>
                    {category !== 'CALL_LOG' && '*'}
                    <label>Note type:</label> {I18N.get(`providerNoteCat.${category}`)}
                </div>
                { this.renderContentMap(category, props.note) }
                <div>
                    <label>Details: </label>
                    <a
                        style={{display: !this.state.canEdit ? 'initial' : 'none', color: '#777777', textDecoration: 'underline', float: 'right'}}
                        onClick={()=> this.setState({showRuleModal: true})}
                    >
                        Why can't I edit or delete?
                    </a>
                </div>
                <div>
                    <TextArea
                        style={{margin: "5px 0px", padding: "5px 15px", background: '#FFFFFF', color: "#555555"}}
                        value={this.state.content}
                        disabled={this.state.canEdit && this.state.isEdit ? false : true}
                        onChange={(e) => this.setState({content: e.target.value, isEdit: true})}
                    />
                </div>
                {!error && props.children}
                {this.renderButtons()}
                {this.renderRuleModal()}
            </div>
        )
    }
}


const mapDispatch = (dispatch) => {
    return {
        // closeModal: () => dispatch(closeModal()),
        openErrorModal:(errorMessage) => dispatch(openErrorModal(errorMessage))
    }
}
export default compose(connect(null,mapDispatch))(Note);