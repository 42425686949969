import { IHTable, IHButton } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import { createContainer, TableColumnsWidth } from 'libModule/utils'
import actions from 'ProgramIncompleteModule/Careplan/actions/VitalListActions'
import { renderThresholdsTitle, renderThresholds, renderSchedule } from 'libModule/helpers/component-helpers'

class Component extends React.Component {
  static displayName = 'program/ProgramIncomplete/Careplan/containers/VitalListContainer'

  render() {
    const p = {
      columns: [
        /* HACK: Vertical alignment with table Assessment & Survey
          Empty column whose width (49px) is same as that of column checkbox in table Assessment & Survey.
        */
        {
          title: '',
          dataIndex: 'hidden',
          key: 'hidden',
          width: 49,
        },
        {
          title: 'Vital Type',
          dataIndex: 'vitalType',
          key: 'vitalType',
          width: TableColumnsWidth.CarePlan.VitalList.VitalType,
          render: (vital, row) =>{
            if (vital === 'Weight' && row.weightBasedOn === 'Last Measurement') return 'Weight Change'
            return vital
          }
        },
        {
          title: 'Schedule',
          dataIndex: 'schedule',
          key: 'schedule',
          width: TableColumnsWidth.CarePlan.VitalList.Schedule,
          render: (value, row, index) => renderSchedule(value, row, index)
        },
        {
          title: renderThresholdsTitle(),
          dataIndex: 'thresholds',
          key: 'thresholds',
          render: (value, row, index) => renderThresholds(value, row, index)
          /* Do not assign width for this column since it will take the remaining space.
             Assigning width will actually affect other columns due to additional column above
           */
        },
        {
          key: 'Actions',
          title: 'Actions',
          width: TableColumnsWidth.CarePlan.VitalList.Actions,
          render: (value, row, index) => this.renderEditVital(row)
        }
      ],
      dataSource: this.props.data,
      noDataElem: this.renderAddVitalElem('Add Vital Sign', 'flex_center', {height:'200px'}),
      rowKey:(row)=>row.id
    }
    const vitalListLength = this.props.data.length
    const addAnotherVitalBtn = vitalListLength > 0 && vitalListLength < 5 ? this.renderAddVitalElem('Add Another Vital', '', {margin: '20px 0 30px'}) : ''

    return (
      <div>
        <IHTable {...p} />
        {addAnotherVitalBtn}
      </div>
    )
  }

  renderEditVital(row, className, style) {
    const { changePage, updateForm } = this.props
    const pb = {
      bsStyle: 'primary',
      // label: 'Edit',
      size: 'exsmall',
      onClick: () => {
        changePage()
        updateForm(row, true)
      }
    }

    return (
      <div className={className} style={style}>
        <IHButton {...pb} >
          Edit
          <i className="fa fa-angle-right ih-right"></i>
        </IHButton>
      </div>
    )
  }
  renderAddVitalElem(label, className, style) {
    const { changePage, resetForm } = this.props
    const pb = {
      bsStyle: 'primary',
      label,
      size: 'small',
      onClick: () => {
        changePage()
        resetForm()
      }
    }

    return (
      <div className={className} style={style}>
        <IHButton {...pb} />
      </div>
    )
  }
}

const mapState = ({ program }) => {
  return {
    data: program.ProgramIncomplete.Careplan.VitalList.data
  }
}

const mapDispatch = (dispatch) => {
  return {
    updateForm: (rowData, isEditMode) => dispatch(actions.updateForm(rowData, isEditMode)),
    resetForm: () => dispatch(actions.resetForm()),
    changePage: () => dispatch(actions.goToCareplanVitalForm())
  }
}

Component.propTypes = {
  data: PropTypes.array,
  updateForm: PropTypes.func,
  resetForm: PropTypes.func,
  changePage: PropTypes.func
}

export default createContainer(Component, mapState, mapDispatch)
