import React from 'react';
import { createClaimedTimeLog } from '../API/index';
import {Checkbox, Input, Row, Col, Select, Button, DatePicker, message, InputNumber} from 'antd';
import moment from 'moment';

import { getComplianceRes } from '../../../patientList/main/helper';
import {helpers} from "../../../visitNewWorkFlow/helper";
import { getActivityOptions } from '../constants';

const { TextArea } = Input;
const { Option } = Select;

const defaultState = {
    showOtherInput: false,
    otherInput:null,
    hour: 0,
    min: 0,
    checkedValues:[],
    serviceDate: moment()
}
let ClaimBillableTimeForm = class extends React.Component{
    constructor(props) {
        super(props);
        const availableServiceDates = this.getAvailableDates();
        this.state = { 
          ...defaultState,
          totalTime:props.totalTime,
          header:props.header, 
          availableServiceDates,
        };
    }

    onChangeOther = (checked)=>{
        const { showOtherInput } = this.state;
        if(showOtherInput!=checked) {
            this.setState({
                showOtherInput: checked
            })
        }
    }

    renderOtherInput = ()=>{
       return <TextArea rows={3} onChange={(e)=>this.setState({otherInput:e.target.value})}/>
    }

    getComplianceResHelper = ()=>{
        const { totalTime,min,checkedValues } = this.state;
        const { user,showTotalTimeNum } = this.props;

        return getComplianceRes(user,{totalTimeFromForm:totalTime+min*60,events:checkedValues,showTotalTimeNum },'Billable time:');
    }

    getMainHeader = ()=>{
        return this.getComplianceResHelper().header;
    }

    timeSelect = (type)=>{
        return <InputNumber min={0} defaultValue={0} max={90} step={5}
                            onChange={(number)=>this.setState({ min: number})}/>

    }

    onChangeCheckbox = (checkedValues)=>{
        this.setState({
            checkedValues
        })
        this.onChangeOther(checkedValues.includes('Other'));
    }

    renderOptions = ()=>{
        const role = helpers.checkIsMAOrMD()? 'MD' :'RD';
        return <Checkbox.Group onChange={this.onChangeCheckbox}>
                <Row>
                {_.map(getActivityOptions(role),({value,label,onChange})=>
                  <Col span={12} style={{ lineHeight:'30px' }} key={value}>
                    <Checkbox value={value} >{label}</Checkbox>
                  </Col>
                )}
                </Row>
            </Checkbox.Group>
    }

    onSubmit = ()=>{
        const { checkedValues,otherInput,min,hour,serviceDate } = this.state;
        const { patientId,setShowModal,refetchMontlySpent,user,showTotalTimeNum } = this.props;
        const eventsWithoutOther = _.filter(checkedValues,c=> c !== 'Other');
        const fullName = _.get(user,'user.variables.profile.fullName',_.get(user,'profile.fullName'));
        const events = _.map(otherInput ? [...eventsWithoutOther,otherInput]:eventsWithoutOther ,v=>({name:v}));
        const totalTime = (hour*60+min)*60;
        const monthOfYear = parseInt(moment(serviceDate).format('YYYYMM'));
        createClaimedTimeLog({ memberId:patientId,events,totalTime,monthOfYear,serviceDate:serviceDate.valueOf(),manualInput:true })
            .then(res=>{
                refetchMontlySpent&&refetchMontlySpent();
                setShowModal&&setShowModal(false);
                message.success({content:`Your offline time has been logged successfully for ${fullName}!${ showTotalTimeNum ?` (Please note your logged time will reflect on the table in 10 mins)`:''}`,duration:5});
            })
    }

    renderButtons = ()=>{
        const { setShowModal,user } = this.props;
        const { min,checkedValues, otherInput} = this.state;
        const hasOtherAndNoInput = checkedValues.includes('Other')&&!otherInput;
        const disabled = !min || !checkedValues.length|| hasOtherAndNoInput;
        return <Row>
            <Button onClick={ this.onSubmit } disabled={ disabled } type={'primary'}>Submit</Button>
            <Button onClick={()=>setShowModal(false)}>Cancel</Button>
        </Row>
    }

    getAvailableDates = () => {
      const today = moment().endOf('day');
      const firstDate = today.clone().startOf('month');
      return [firstDate, today];
    };

    render() {
        const { showOtherInput,serviceDate,totalTime,header, availableServiceDates } = this.state;
        const isFromPatientList = _.isNumber(totalTime);
        return <div>
                <div>
                    <Row className={'header'}>
                        <strong>{ isFromPatientList ? header : 'Choose the activities outside of this web portal (excluding face-to-face interactions) that apply to you. Input the total time that you spent on these activities.'  }</strong>

                    </Row>
                    {
                        isFromPatientList &&
                        <Row style={{display: 'flex',padding:'10px 0px'}}>
                            <Col span={12}>{ this.getComplianceResHelper().billableTimeContent}</Col>
                            <Col span={12}>{ this.getComplianceResHelper().spokenWithPatientContent}</Col>
                        </Row>
                    }
                    { this.renderOptions() }
                    { showOtherInput&& this.renderOtherInput() }
                </div>
                <Row>
                    <Col className={'timeSelOutter'} span={12}>
                        <Row className={'subheader'}><span>Time spent:</span></Row>
                        <div className={'timeSelWrapper'}>
                            { this.timeSelect('min')}
                            <span className={'unit'}>mins</span>
                        </div>
                    </Col>
                    <Col className={'DOSWrapper'} span={11}>
                        <span>Date Of Service:</span>
                        <DatePicker 
                          defaultValue={serviceDate} 
                          allowClear={false} 
                          onChange={(serviceDate)=>this.setState({serviceDate})}
                          disabledDate={(cur) => cur && !cur.isBetween(...availableServiceDates)}
                        />
                    </Col>
                    <Col span={1}></Col>

                </Row>
                { this.renderButtons() }
                </div>
    }
}

export default ClaimBillableTimeForm;
