import { useEffect, useState, useCallback, useMemo } from 'react';
import configs from '../../../lib/config';
import { API } from '../actions/api';
import Client from '../../../lib/gqlClient';
import getPatientChatInfo from '../../graphql/getPatientChatInfo';
import reduxStore from '../../../lib/reduxStore';
import { decodeId } from '../../../lib/helpers/common-helpers';

export const getIOSNotificationConfig = (userAvatarLink) => ({
  aps: {
    alert: 'You have a new message!',
    bigPictureUrl: userAvatarLink,
    sound: 'default',
    "mutable-content": 1
  },
  pn_bundle_ids: [
    'com.ihealthlabs.sharecare',
    'com.ihealthlabs.sharecarebp'
  ]
});

export const getAndroidNotificationConfig = (userAvatarLink) => ({
  data: {
    message: 'You have a new message!',
    bigPictureUrl: userAvatarLink,
    sound: 'default',
  }
});

export const setPatientChatChannel = async (patientId, cb) => {
  const getChatInfo = async () => {
    let _patientChannelName = '';
    const res = await Client.query({
      query: getPatientChatInfo,
      variables: { userId: patientId },
    });
    _patientChannelName = _.get(res, 'data.getUserChatInfo.channels[0].name');
    if (!_patientChannelName && res.error) {
      console.warn('It might have failed to get channelName', res.error);
    }
    return _patientChannelName;
  };

  const decodedPatientId = decodeId(patientId);
  const savedChannel = _.get(reduxStore.getState(), `chat.main.patientIdToChannel.${decodedPatientId}`);
  const patientChannelName = savedChannel || await getChatInfo();
  cb && cb(patientChannelName);
}

export default ({
  patientId,
  channelName: _channelName,
  shouldIncludePushNotification = true,
} = {}) => {
  const [channelName, setChannelName] = useState(_channelName);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [payloadHistory, setPayloadHistory] = useState([]);
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

  useEffect(() => {
    if (!patientId) {
      console.warn('No patient id found');
    } else if (!channelName) {
      setPatientChatChannel(patientId, setChannelName);
    }
  }, [patientId]);

  const sendMessage = async (
    payload,
    onAfterSend
  ) => {
    setIsSendingMessage(true);
    if (!channelName || !payload) {
      console.warn('Invalid input or input is not ready');
      return;
    }

    const {
      message,
      fileKey,
      translation,
      ...others
    } = payload;

    const publicS3bucket = configs.PUBLIC_S3_BUCKET;
    const userAvatarFileKey = _.get(currentUser, 'profile.avatar.fileKey');
    const userAvatarLink = userAvatarFileKey ? `${publicS3bucket}/${userAvatarFileKey}` : undefined;
    const publisherId = _.split(atob(currentUser.id), ':')[1];

    const msgObj = {
      type: 'text',
      text: message,
      publisher: publisherId,
      displayName: currentUser.profile.fullName,
      userRole: _.get(currentUser, 'selectedRole.name') || 'Unknown',
      ...others,
    };

    if (shouldIncludePushNotification) {
      // for push notifications on iOS
      msgObj.pn_apns = getIOSNotificationConfig(userAvatarLink);

      // Google Cloud Messaging / Firebase Cloud Messaging
      msgObj.pn_gcm = getAndroidNotificationConfig(userAvatarLink);
    }

    if (fileKey) {
      msgObj.text = 'Uploaded a file';
      msgObj.type = 'fileUpload';
      msgObj.fileKey = fileKey;
    }

    if (translation && translation.translatedText) {
      msgObj.originalText = message;
      msgObj.originalTextLanguage = translation.detectedSourceLanguage;
    }

    const res = await API.publishMessage(channelName, msgObj);
    setIsSendingMessage(false);
    if (res && res.success) {
      setPayloadHistory(payload);
    }
    if (onAfterSend) onAfterSend(res);
  };

  return {
    payloadHistory,
    channelName,
    sendMessage,
    isSendingMessage,
  };
};