import ProgramNew from 'ProgramNewModule/Common/reducers'
import ProgramEdit from 'ProgramEditModule/Common/reducers'
import ProgramIncomplete from 'ProgramIncompleteModule/Common/reducers'
import { combineReducers } from 'redux'

const reducer = combineReducers({
  ProgramNew,
  ProgramEdit,
  ProgramIncomplete,
})

export default {
  program: reducer
}
