import gql from 'graphql-tag'
import { Program } from './schema/Program'

export default gql`
  query adminProgramList (
    $page: Int,
    $count: Int,
    $sort: ProgramListSort
  ) {
    adminProgramList (
      page: $page,
      count: $count,
      sort: $sort
    ) {
      data {
        ${Program}
      }
      pageInfo {
        total
        lastPage
      }
    }
  }`

export const adminProgramListWithSearch = gql`
  query adminProgramList (
    $page: Int,
    $count: Int,
    $sort: ProgramListSort,
    $search: ProgramListSearch
  ) {
    adminProgramList (
      page: $page,
      count: $count,
      sort: $sort,
      search: $search
    ) {
      data {
        ${Program}
      }
      pageInfo {
        total
        lastPage
      }
    }
  }`;
