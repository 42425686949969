import gql from "graphql-tag";

export default gql`
  query labResultList($filter: FilterFindManyLabResultsInput) {
    labResultMany(filter: $filter) {
      userId
      _id
      viewerId
      templateId
      deleted
      templateName
      dateCollected
      createdAt
      results {
        name
        unit
        value
        flag
        interval
        isInHouse
      }
    }
  }
`
