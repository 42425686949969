import React from 'react';
// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers';

/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('Organization', 'OrganizationEdit', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'UPDATE_FORM',
  'SET_SUBMITTING'
]);

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
]);

const { resetForm, updateForm, onFieldsChange, handleFormSaved, setSubmitting } = actionCreators;
export {
    resetForm, updateForm, onFieldsChange, handleFormSaved, setSubmitting,actionTypes
};
