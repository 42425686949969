import { combineReducers } from 'redux'
import Common from './CommonDuck'
import AssessmentForm from './AssessmentFormDuck'
import AssessmentList from './AssessmentListDuck'
import Alert from 'patientModule/AssessmentAlert/reducers'

const assessment = combineReducers({
  Common,
  AssessmentForm,
  AssessmentList,
  Alert,
})

export default assessment
