import React, { Component } from 'react';
import { message, Button, Icon, notification } from 'antd';
import '../css/index.scss';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Client from 'libModule/gqlClient';
import chooseLoginOrganization from 'modulesAll/graphql/mutation/chooseLoginOrganization';
import { setInstitution, setRemountMainLayout } from '../../layout/actions/Nav';
import UserClass from 'modulesAll/graphql/class/User';
import { goPath } from 'libModule/utils';
import { CONTACT_STATUS_MAP } from '../constants';
import { setLocalStorageLastCall } from '../helper/helpers';
import callActions from '../actions';
import helper from '../../newAdmin/helper';

const wrapper = class callComponent extends Component {
    constructor() {
        super();
        this.state = {
            isMinCall: false,
            isUnknownOutbound: false,
            profilesWithMatchingNumber: undefined,
            minimizedMultiProfileView: false,
        }
    }

    ifOutbound = () => {
      const currentCallData = JSON.parse(localStorage.getItem('call-contact-data'));
      return currentCallData && !currentCallData.isInbound;
    }

    getAttributes = () => {
      let attributes = undefined;
      if (sessionStorage.getItem('inboundAttributes')) {
          attributes = JSON.parse(sessionStorage.getItem('inboundAttributes'));
      } else if (sessionStorage.getItem('outboundAttributes')) {
          attributes = JSON.parse(sessionStorage.getItem('outboundAttributes'));
      }
      return attributes;
    }

    getAttribute = (key) => {
      const attributes = this.getAttributes();
      return (attributes && attributes[key]) ? attributes[key].value : undefined;
    }

    checkIsUnknownOutbound = () => {
      const currentCallData = JSON.parse(localStorage.getItem('call-contact-data'));
      if(!currentCallData || !!currentCallData.isInbound) { 
        // no call or call ended or inbound call
        return false;
      }
      return window.CCPClass && window.CCPClass.isUnknownOutbound();
    }

    componentDidUpdate() {
      const { isUnknownOutbound, profilesWithMatchingNumber } = this.state;
      const { status, ccpStatus } = this.props;
      const isCalling = [CONTACT_STATUS_MAP.onConnecting, CONTACT_STATUS_MAP.onConnected].includes(status);
      if(!isUnknownOutbound && this.checkIsUnknownOutbound() && isCalling) {
        this.setState({ isUnknownOutbound: true });
      }

      const allProfiles = this.getAttribute('allProfiles');
      if (!profilesWithMatchingNumber && allProfiles && isCalling) {
        this.setState({ profilesWithMatchingNumber: allProfiles });
      }

      if(profilesWithMatchingNumber && !isCalling) {
        this.setState({ profilesWithMatchingNumber: undefined, minimizedMultiProfileView: false });
      }
      
      // auto close when call ended
      // if(isUnknownOutbound &&  status === CONTACT_STATUS_MAP.onEnded && ccpStatus !== 'close') {
      //   this.close();
      // }
    }

    handlerSizeBox = () => {
        const isMinCall = this.state.isMinCall;
        if (isMinCall) {
            this.setState({ isMinCall: false });
            this.props.maxCall();
        }
        else {
            this.setState({ isMinCall: true });
            this.props.minCall();
        }
    }

    parseProfileToAttributes = (profile) => {
      if (!profile) return null;
      const { name, roleId, language, isEp, patientId, organizationId, enrolledProgramId } = profile;
      return ({
        name: { value: name },
        roleId: { value: roleId },
        language: { name: 'language', value: language },
        isEp: { name: 'isEp', value: isEp },
        patientId: { value: patientId ? btoa(`accounts:${patientId}`) : undefined },
        organizationId: { value: organizationId ? btoa(`organizations:${organizationId}`) : undefined },
        enrolledProgramId: { value: enrolledProgramId ? btoa(`enrolled_programs:${enrolledProgramId}`) : undefined },
      });
    }

    handleClickPatient =  _.debounce(async (e, selectedProfile)  => {
        if(this.checkIsUnknownOutbound()) return;

        let attributes = this.getAttributes();
        if (selectedProfile) {
          attributes = { ...attributes, ...this.parseProfileToAttributes(selectedProfile) };
        }
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        let institution = currentUser.selectedRole.organization.id;
        let patientOrgId = _.get(attributes,'organizationId.value', undefined)
        // attributes.organizationId.value
        //testing
        //let patientOrgId1 = '5a31ba2f29a4aa7591648b06'

        let p = _.get(attributes,'patientId.value', undefined)
        let name = _.get(attributes,'name.value', undefined)
        const enrolledProgramId = _.get(attributes,'enrolledProgramId.value', undefined);

        const goToPatientProfile = () => {
          if (selectedProfile) {
            const { status, minimizedNote, setMinimizedNote } = this.props;
            // prevent showing popup when 
            // -- call is just trying to connect
            if (status === CONTACT_STATUS_MAP.onConnected) {
              // close minimized popup
              if (minimizedNote) {
                notification.close('minimizedNote');
                setMinimizedNote(null);
              }
              window.CCPClass.toggleNotePopup(p, enrolledProgramId);
            }
            const lastContact = JSON.parse(localStorage.getItem('call-center-last-contact')) || {};
            setLocalStorageLastCall({ ...lastContact, ...attributes });
            sessionStorage.setItem('inboundAttributes', JSON.stringify(attributes));
            this.props.setInboundAttributes(attributes);
          }
          message.success('Jump to ' + name);
          goPath('/patients/' + p + '/enrolledprogram/default');
        }

        if (institution == patientOrgId) {
          // go to patient page directly
          goToPatientProfile();
        } else {
            // change org first
            const authAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
            let refId = undefined;
            let i;
            for (i = 0; i < authAllRoles.length; i++) {
                let val = authAllRoles[i]
                let institution = val.organization.id;

                if (institution === patientOrgId) {
                    refId = val.refId;
                    break;
                }
            }

            if (refId == undefined) {
              return message.error('You are not in the same clinic with calling patient');
            }

            await Client.mutate({
                mutation: chooseLoginOrganization,
                variables: { ref: refId },
            }).then(async res => {

                const { sessionToken, id, role, appSettings } = res.data.chooseLoginOrganization || {};

                if (sessionToken) {
                    await UserClass.saveUserInfo({ sessionToken, id, role, appSettings });

                    this.props.setInstitution(role.refId);

                    message.success('Organization changed');
                    setTimeout(() => {
                      goToPatientProfile();
                      this.props.setRemountMainLayout(true);
                    }, 1000);
                }

            }).catch(error => {
                console.log('chooseLoginOrganization, error: ', error);
            })

        }
    }, 500);

    close = () => {
      this.props.close();
      // reset CCP UI states
      this.setState({ isMinCall: false, isUnknownOutbound: false });
      this.props.maxCall();
    }

    renderResizeButton = () => {
      const icon = this.state.isMinCall ? 'max' : 'min';
      return(
        <span className={'btnclose'} onClick={this.handlerSizeBox}>
          <img src={'/image/call-' + icon + '.png'} />
        </span>
      );
    }

    renderHeaderWithMultipleProfiles = (attributes) => {
      let curName = _.get(attributes,'name.value', '')
      let curPatientId = _.get(attributes,'patientId.value', '')
      const { isUnknownOutbound, profilesWithMatchingNumber = [], minimizedMultiProfileView } = this.state;
      // included default patient
      if (profilesWithMatchingNumber.length > 1) {
        return (
          <div className="multipleProfilesWrapper">
            <div 
              className="header" 
              onClick={() => {
                this.setState((prev) => ({ minimizedMultiProfileView: !prev.minimizedMultiProfileView }));
              }}
            >
              Patients with the same number
              {
                minimizedMultiProfileView 
                ? <Icon type="up-circle"  />
                : <Icon type="down-circle" />
              }
            </div>
            {
              !minimizedMultiProfileView
              && profilesWithMatchingNumber.map((profile) => {
                const parsedProfile = this.parseProfileToAttributes(profile);
                const patientId = _.get(parsedProfile, 'patientId.value');
                const name = _.get(parsedProfile, 'name.value');
                const organizationId = _.get(parsedProfile, 'organizationId.value');
                if (!patientId) return null;
                const isSamePatient = patientId === curPatientId;
                const isInSameClinic = organizationId == helper.getViewerOrgId();
                return (
                  <div className="callHeader" key={patientId}>
                    <div className="indicator">
                      {
                        isSamePatient 
                        && (
                          <img 
                            src={'/image/call-phone.png'} 
                            className={'callimg'} 
                            ref={(el) => {
                              if (el && !minimizedMultiProfileView) {
                                el.scrollIntoView({ block: 'center', inline: 'center' });
                              }
                            }}
                          />
                        ) 
                      }
                      <Button 
                        type="link" 
                        onClick={(e) => this.handleClickPatient(e, profile)} 
                        disabled={isUnknownOutbound}
                      >
                        {name}
                      </Button>
                    </div>
                    <div className="note">
                      {
                        (!isInSameClinic) ?
                          <span>(found in another clinic)</span>
                        : null
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
        )
      } 
      return null;
    }

    render() {
        /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
     *      duration   min
     *      date
     *      showChat
     * }
     */
        let attributes = this.getAttributes();
        const isNewPortal = this.getAttribute('newPortal');
        const isCallerClickable = !(isNewPortal || this.state.isUnknownOutbound);
        let isOutbound = this.ifOutbound();
        let username = _.get(attributes,'name.value', '')
        let callUser = isOutbound ? 'Calling '+username : username+' calling'

        // const username = this.props.username;
        let status = this.props.status;

        // let contactStatus = this.props.contactStatus
        // if (contactStatus == 'onConnected') {
        //     status = 1
        // } else if (contactStatus == 'onConnecting') {
        //     status = 0
        // } else if (contactStatus == 'onEnded') {
        //     status = 2
        // } else if (contactStatus == 'onClosed') {
        //     status = 7
        // }

        let lightClass = '';
        switch (status) {
            case 0:
                break;
            case 1:
                lightClass = 'connected';
                break;
            case 2:
                lightClass = 'ended';
                break;
        }
        let header = (<div className="callHeaderWrapper">
          { !this.state.isMinCall && this.renderHeaderWithMultipleProfiles(attributes) }
          <div className={'callHeader'}>
            <img src={'/image/call-phone.png'} className={'callimg'} />
            <span className={'light' + ' ' + lightClass}></span>
            <div
              className={(
                `
                  title 
                  ${isCallerClickable ? '' : ' not-clickable'}
                `
              )}
              onClick={isCallerClickable ? this.handleClickPatient : undefined}
            >
              {callUser}
            </div>
            { this.renderResizeButton() }
          </div>
        </div>);
        if (status == 2 || status == 7) {
            let time = '';
            if (this.props.date&&this.props.duration) {
                // let date = new Date(this.props.date);
                let duration = this.props.duration;
                time = this.props.date + ', ' + duration;
            }
            header = <div className={'callHeader ended'}>
               { username & time ?
                <div   style={{ cursor:'pointer' }} onClick={this.handleClickPatient}>
                    <span className={'title'} >Last call: {username}</span>
                    <span className={'time'}>{time}</span>
                </div>
                : 
                <div> <span className={'title-vertical-middle'}>Contact Control Panel</span></div>
               }
               {
                !this.state.isMinCall 
                 ? <span className={'btnclose'} onClick={this.close}>
                    <img src='/image/call-close.png' />
                  </span>
                 : this.renderResizeButton()
               }
            </div>
        }
        let body = <div className={'callBody'} id={'callBody'}>
            {/* <iframe src={'https://www.baidu.com'}></iframe> */}
        </div>;
        return <div className={'callMain'}>
            {header}
            {body}
        </div>
    }
}

const mapPropsToState = (state) => {
    return {
        inboundAttributes: state.AWSConnect.inboundAttributes,
        contactStatus: state.AWSConnect.contact,
        ccpStatus: state.AWSConnect.ccpStatus,
        minimizedNote: state.AWSConnect.minimizedNote,
    }
}
const mapDispatch = (dispatch) => {
    return {        
        setInstitution: inst => dispatch(setInstitution(inst)),
        setRemountMainLayout: flag => dispatch(setRemountMainLayout(flag)),
        setInboundAttributes: (attributes) => dispatch(callActions.inboundAttributes(attributes)),
        setMinimizedNote: (name) => dispatch(callActions.setMinimizedNote(name))
    }
}

export default compose(connect(mapPropsToState, mapDispatch))(wrapper);

// export default CallContainer