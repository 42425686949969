import {
    React
} from 'ihcomponent'
import TopBar from '../components/TopBar'
import AddNewPatient from './AddNewPatient'
import {createContainer} from 'libModule/utils'

export default createContainer((props)=>{

    // setPagePath('Patients / Manual Input Info')

    const body = <AddNewPatient />
    return (
        <div>
            {
              //<TopBar active={2} />
            }
            <div className="vsm-main-page">
                <div className="vsm-main">
                    {body}
                </div>
            </div>
        </div>
    )
}, (state)=>{
    return {

    }
})
