import React from 'react'
import BaseComponent from '../BaseComponent'
import * as RB from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{

    init(){
        this.state = {
            loading : false
        }
    }

    defineProp(){
        const p = this.props
        return {
            ...p
        }
    }

    render(){

        let p = this.defineProp()
        let label = p.label
        delete p.label

        // for supporting button type, check props.type if it's a type or a bsStyle
        const types = ['button', 'submit', 'reset'];

        // because the default type of a button is submit
        let type = 'submit';

        if (_.find(types, t => p.type === t)) {
            type = p.type;
            delete p.type;
        }

        const bsStyle = p.bsStyle || p.type || 'default'
        delete p.bsStyle

        if(type) p.type = type

        let className = ['IH-Button']
        if(p.icon && !label){
            className.push('ih-icon-only')
        }
        if(p.className){
            className = className.join(' ')+' '+p.className
            delete p.className
        }
        const size = p.size || 'normal'
        delete p.size
        className += ' ih-'+size

        if(this.state.loading){
            p.disabled = true
            label = p.loading || 'Loading...'
        }
        delete p.loading

        if(p.authorize)
        delete p.authorize

        return (
            <RB.Button className={className} bsStyle={bsStyle} {...p}>
                {p.icon?<RB.Glyphicon glyph={p.icon} />:null}
                {label||p.children||null}
            </RB.Button>
        )
    }

    loading(f=false){
        //if(!this.props.loading) return false
        this.setState({
            loading : f
        })
    }
}

Component.propTypes = {
    label : PropTypes.string,
    //size : React.PropTypes.stirng,
    loading : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ])
}

export default Component
