import { React, IHButton, message, ND } from 'ihcomponent';
import { connect } from 'react-redux';
import ReportModal from '../../../report/containers/ReportModalContentContainer'
import { closeModal } from 'modulesAll/layout/actions/MainModal'
import UserClass from 'graphqlModule/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { goPath, RequestCache } from 'libModule/utils';
import { GQLHelper } from 'libModule/utils';
import { Select, Input } from 'antd';
import reducerActions from '../../../VideoChat/action/reducerActions';

const reasonObj = I18N.get('dischargeReason');
const Component = class extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/containers/DischargeConfirmContainer'

  constructor(props) {
    super(props);
    this.state = { canSubmit: false, value: '', submiting: false,dischargedDetail:{ } };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleCancelOnClick = this.handleCancelOnClick.bind(this);
    this.handleDischargeOptChange = this.handleDischargeOptChange.bind(this);
    this.ref = null;
  }

  onSubmit() {
    if (this.state.canSubmit) {
      const { currentProgram, dischargeProgram, refetchEnrolledProgramList, dispatch, currentPatient,openErrorModal, refetchPatientReferral, setConsentInfo } = this.props;
      const patientId = _.get(currentPatient, 'id');
      const patientNRIC = _.get(currentPatient, 'identification[0].value')
      const patientName = _.get(currentPatient, 'profile.fullName')
      const { dischargedDetail } = this.state;
      const handleDischargeAsync = async () => {
        try {
          const res = await dischargeProgram({
            variables: { id: currentProgram.id,dischargedDetail }
          });
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.dischargeSuccessful'),
            patientNRIC,
            patientName,
            details: { 'programName' : _.get(currentProgram, 'name') },
            request: { 'id' : _.get(currentProgram, 'id') },
            response: res    //temporily remove due to res too huge
          })
          message.success('Program discharge succeeded!');
          refetchEnrolledProgramList();
          UserClass.saveEPsForProvider();
          if(refetchPatientReferral)
            refetchPatientReferral();
          dispatch(reducerActions.resetConsentInfo(patientId, {})); // reset local state

          /** workaround for http://localhost:3000/patients/..==/enrolledprogram/default to transition into past tab if press discharge button**/
          const currentLocation = this.props.location.pathname;
          if(currentLocation && (currentLocation.endsWith('enrolledprogram/default') || currentLocation.endsWith('enrolledprogram/current')) )
            goPath(`/patients/${currentPatient.id}/enrolledprogram/${_.get(currentProgram, 'id')}`);

        }
        catch(e) {
            this.props.openErrorModal(GQLHelper.formatError(e));
            createAuditLog({
            action: I18N.get('auditLog.provider.patient.dischargeUnsuccessful'),
            patientNRIC,
            patientName,
            details: { 'programName' : _.get(currentProgram, 'name') },
            request: { 'id' : _.get(currentProgram, 'id') },
            response: e,
            success: false
          })
          message.error('Program discharge failed!');
        }
      }
      // try {
      //   this.setState({ submiting: true });

        // message.success('program discharge success!');
      const p = {
        // title: 'Generate NEHR Report',
        title: 'Generate Report',
        showModal: true,
        showHeaderCloseButton: false,
        body: <ReportModal startDate={ currentProgram.startDate }
                           reason="Discharge"
                           enrolledProgramId={ currentProgram.id }
                           handleDischargeAsync={ handleDischargeAsync } />
      }
      //dispatch(openModal(p.body, p));

      handleDischargeAsync();
      dispatch(closeModal());

      //
      // } catch (e) {
      //   message.error(e.message);
      // }

      // closeModal && closeModal();
    }
  }

  onChange(e) {
    let { value = '' } = e.target;
    value = value.toUpperCase();

    this.setState({
      canSubmit: value === 'DISCHARGE',
      value,
    });
  }

  handleCancelOnClick() {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
      action: I18N.get('auditLog.provider.patient.cancelDischarge'),
      patientNRIC,
      patientName
    })

    this.props.closeModal()
  }

  handleDischargeOptChange(v,o){
    this.setState({
        dischargedDetail:{
            key:v,
            description:reasonObj[v]
        }
    })
  }

  handleDetailInputChange = e => {
    const note = e.target.value;
    this.setState(prev => ({
      dischargedDetail: {
        ...prev.dischargedDetail,
        note
      }
    }));
  }

  render() {
    const { onSubmit, onChange, handleCancelOnClick } = this;
    const { closeModal, currentPatient,openErrorModal } = this.props;
    const { value, canSubmit, submiting,dischargedDetail } = this.state;
    const keys = Object.keys(reasonObj);
    const Option = Select.Option;

    return (
      <div className="discharge-modal-body">
        <p>
          This is an irreversible process. When patient is discharged from a {I18N.get('keywords.program')}, they cannot upload any more data to the {I18N.get('keywords.program')}.
        </p>
        <p>
          To continue and discharge [{currentPatient.profile.firstName} {currentPatient.profile.lastName}], please type “DISCHARGE” below:
        </p>

        <span className="IH-FormItem">
          <span className="ant-form-item-control">
            <ND.Input onChange={onChange} value={value} />
          </span>
        </span>
        <div style={{paddingBottom:20}}>
          <p style={{margin:'0 0 10px 50px',float:'left'}}>Reason to Discharge</p>
          <Select style={{width:350}} onChange={(v,o)=>this.handleDischargeOptChange(v,o)} disabled={!canSubmit} >
              {_.map(keys,(k,i)=><Option value={k} key={i}>{reasonObj[k]}</Option>)}
          </Select>
          <Input.TextArea
            style={{ width:350, marginTop: 15 }}
            placeholder='Type details here (optional)'
            disabled={_.isEmpty(dischargedDetail)}
            value={dischargedDetail.note}
            onChange={this.handleDetailInputChange}
          />
        </div>
        {
          //<p style={{marginTop: '-15px'}}>You will be required to submit a report to complete the discharge process.</p>
        }
        <div className="discharge-buttons">
          {submiting
            ? <IHButton bsStyle="primary" label="Discharging" disabled />
            : <IHButton bsStyle="primary" label="Complete Discharge" disabled={!canSubmit||_.isEmpty(dischargedDetail)} onClick={onSubmit} />
          }
          <IHButton label="Cancel" onClick={handleCancelOnClick} />
        </div>
      </div>
    );
  }
};

export default connect()(Component);
