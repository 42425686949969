import React from 'react';
import AddMemberContainer from '../Clinic/containers/AddMemberContainer';
import CareTeamModalBody from '../CareTeam/components/CareTeamModalBody';
import { Modal } from 'antd';

const addModalContainer = (selectedTab, localThis) => {
  const { addModalVisible, modalBodyProps, providerList, loadingProviderList } = _.get(localThis, 'state') || {};
  const containerRef = {
    ...localThis,
    props: {
      ...localThis.props,
      setEditMode: localThis.setEditMode, // using this.setEditMode
      providerList,
      loadingProviderList,
    }
  };

  return <Modal className={selectedTab+ '-modal'}
                style={{ minWidth:500, ...modalBodyProps.modalStyle }}
                footer={null}
                visible={selectedTab===addModalVisible}
                onCancel={localThis.closeAddModal}
                maskClosable={false}
                destroyOnClose
    {
      ...selectedTab === 'CARE_TEAM' && {
        wrapClassName: 'careteam-modal-wrap',
        style: { top: 55 },
        width: '80vw'
      }
    }
  >
    {addModalMap[selectedTab](containerRef)}
  </Modal>
}

const addModalMap = {
  'IHEALTH_MEMBER': ({props, closeAddModal, updateNeedRefetchTeamList }) =>
                      <AddMemberContainer 
                        {...props}
                        updateNeedRefetchTeamList={updateNeedRefetchTeamList}
                        closeAddModal={closeAddModal}
                      />,
  'CLINIC_MEMBER': ({props, closeAddModal, updateNeedRefetchTeamList }) =>
                      <AddMemberContainer 
                        {...props}
                        updateNeedRefetchTeamList={updateNeedRefetchTeamList}
                        closeAddModal={closeAddModal}
                      />,
  'CARE_TEAM': ({ closeAddModal, state, props }) =>
                    <CareTeamModalBody  
                      {...props}
                      modalBodyProps={state.modalBodyProps}
                      closeAddModal={closeAddModal}
                    />
};

export default {
  addModalContainer
};