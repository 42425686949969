import React, { forwardRef, useRef, useEffect } from 'react';
import PatientPreferencesPropTypes from './proptypes';
import { Row, Col, Radio,Switch } from 'antd';

import '../../css/patientPreferences.scss';

import upConstants from './constants';

const MESSAGE_FORM_FIELDS = [
  {
    key:'sendVitalReminder',
    label:'sendVitalReminder',
    getInitialValue:(value)=>{
      return value ? _.get(value,'value') ==='YES' : 'YES'
    }
  }
]
const SERVICES_FORM_FIELDS = [
  {
    key: 'followupVisits',
    label: <label><b>Visits</b> (Initial visit, follow-up visits every 3-6 months)</label>,
    options: [
      {
        value: upConstants.valueEnum.YES,
        label: 'Yes, interested'
      },
      {
        value: upConstants.valueEnum.NO,
        label: 'No, declined'
      }
    ],
    getInitialValue: (hasOnlyYesOption, selectedValue) => {
      return hasOnlyYesOption ?
             upConstants.valueEnum.YES :
             (selectedValue || upConstants.valueEnum.YES)
    }
  },
  {
    key: 'nutritionCoaching',
    label: <label><b>Nutrition</b> (Customized online nutrition coaching with RD)</label>,
    options: [
      {
        value: upConstants.valueEnum.YES,
        label: 'Yes, interested'
      },
      {
        value: upConstants.valueEnum.NO,
        label: 'No, declined'
      }
    ],
    getInitialValue: (hasOnlyYesOption, selectedValue) => {
      return hasOnlyYesOption ?
             upConstants.valueEnum.YES :
             (selectedValue || upConstants.valueEnum.YES)
    }
  }
];

const PatientPreferencesComponent = forwardRef((props) => {
  const initialRender = useRef(true);
  const { form, userPreference = {}, showDescription, isMNTEnrolled } = props;
  const hasOnlyYesOption = !!isMNTEnrolled;
  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else if(hasOnlyYesOption) {
      // set default to YES
      const serviceFieldValues = _.map(SERVICES_FORM_FIELDS, f => ({ [f.key]: 'YES' }));
      form.setFieldsValue(_.assign({}, ...serviceFieldValues));
    }
  }, [hasOnlyYesOption]);

  return (<div id='vsm-patient-preferences'>
            <div>
              <h4>Messages</h4>
              {/*<p>When toggle ON,this patient will receive all engagement auto-messages to remind them to take their vitals.</p>*/}
            </div>
            <div>
              {
                MESSAGE_FORM_FIELDS.map(field=>{
                  const { key: fieldKey, label, getInitialValue } = field;
                  return <Row key={fieldKey} style={{ marginBottom:10 }}>
                    <Col span={14}>
                      <p>When toggle ON,this patient will receive all engagement auto-messages to remind them to take their vitals.</p>
                    </Col>
                    <Col span={10}>
                      {
                        form.getFieldDecorator(fieldKey, {
                          valuePropName: 'checked',
                          initialValue: getInitialValue(userPreference[fieldKey])
                        })(
                            <Switch/>
                        )
                      }
                    </Col>
                  </Row>
                })
              }
            </div>
            <div id='vsm-patient-preferences-content'>
              <h4>Patient's Interested/Declined Services</h4>
              {
                showDescription &&
                <p>
                  <span className='highlight'>Note:</span> When discussing the services we offer, note here if the patient is interested in any particular service(s) or not. This works as data collection only and it won’t affect any enrollment status of the patient. The basic service that all patients must receive as part of RPM care is <span className='highlight'>a Monthly Review of Vitals Patterns and Clinical Goals.</span>
                </p>
              }
            </div>
            <div id='vsm-patient-preferences-options' className={hasOnlyYesOption ? 'hide-No-options' : ''}>
              {
                SERVICES_FORM_FIELDS.map((field) => {
                  const { key: fieldKey, label, options, getInitialValue } = field;

                  return <Row key={fieldKey}>
                    <Col span={14}>
                      {label}
                    </Col>
                    <Col span={10}>
                      {
                        form.getFieldDecorator(fieldKey, {
                          initialValue: getInitialValue(hasOnlyYesOption, (userPreference[fieldKey] || {}).value)
                        })(
                          <Radio.Group options={options} disabled={!!isMNTEnrolled} />
                        )
                      }
                    </Col>
                  </Row>
                })
              }
              {
                hasOnlyYesOption &&
                <span className='patient-preferences-mnt-note'>
                  Note: Patient is enrolled in MNT already
                </span>
              }
            </div>
          </div>);
});

PatientPreferencesComponent.displayName = 'PatientPreferencesComponent';
PatientPreferencesComponent.propTypes = PatientPreferencesPropTypes.component;

export default PatientPreferencesComponent;
