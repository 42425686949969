import React, {Component} from 'react';
import {Col, Row, Form, InputNumber, DatePicker, Divider} from "antd";
import moment from "moment";
import {helpers as dataHelper} from "../helper"

const {Item} = Form;

const dateFormat = 'MM/DD/YYYY';

class VisitInputA1C extends Component{

    constructor(props){
        super(props);
        this.state = {
            inputA1C: null,
            inputA1CDate: null,
        }
        this.selectDate = this.selectDate.bind(this);
    }

    checkWhetherShowA1C(){

        const {patient, editStatus, visitInfo} = this.props;

        //check whether show a1c, if the use has program and the program doesn't contain BG, hidden A1C
        if(editStatus){
            const type = visitInfo.vitalTypes;
            if(type && type.length > 0){
                const allType = type.join('/');
                if(allType.indexOf('BG') === -1){
                    return false;
                }
            }
            return true;
        } else {
            const typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, patient.user.profile.lastMeasuredAt);
            if(typeAndStatus.status !== 'Potential'){
                if(typeAndStatus.type.indexOf('BG') === -1){
                    return false;
                }
            }
            return true;
        }
    }

    checkWhetherShowNeedInHouse(){
        const {inputA1CDate} = this.state;
        let checkWhetherShowNeedInHouse = false;

        if(inputA1CDate){
            const dateDiff = this.calculateDateDiff(inputA1CDate);
            if(dateDiff > 30){
                checkWhetherShowNeedInHouse = true;
            }
            return checkWhetherShowNeedInHouse;
        }

        const {A1C, A1CDate} = this.props.data;
        if(A1C){
            const dateDiff = this.calculateDateDiff(new Date(A1CDate));
            if (dateDiff > 30) {
                checkWhetherShowNeedInHouse = true;
            }
        } else {
            checkWhetherShowNeedInHouse = true;
        }
        return checkWhetherShowNeedInHouse;
    }

    calculateDateDiff(date){
        const start_date = moment(date);
        const end_date = moment();
        return end_date.diff(start_date, 'days');
    }

    disabledDate(current){
        return current > moment().endOf('day');
    }

    renderA1CLabel(showNeedInHouse){

        const {A1C, A1CDate} = this.props.data;

        if(!A1C){
            return <Col span={24}></Col>
        }

        const start_date = moment(A1CDate);
        const end_date = moment();
        let style = {
            marginTop: 10,
            color: (end_date.diff(start_date, 'days')) > 30 ? 'red' : ''
        };

        // special styling for FollowUpVisitComponent, won't affect this component if used whereelse
        if(!showNeedInHouse) {
            style = _.assign(style, _.get(this, 'props.A1CrecordStyle'));
        } else if(_.get(this, 'props.needInHouseStyle')){
            style = _.assign(style, { marginTop: -10 })
        }

        return (
            <Col span={24} style={style}>
                Last A1C recorded: {A1C}% Date: {moment(A1CDate).format(dateFormat)}
            </Col>
        )
    }

    renderNeedInHouseLabel(){
        let style = {marginTop: 35, color:'red'};
        style = _.assign(style, _.get(this, 'props.needInHouseStyle'));
        return (
                <Col span={8} style={style} >
                    Need in-house A1C
                </Col>
            )
    }

    selectDate(value){
        this.setState({
            inputA1CDate: value
        })
    }

    render() {
        const showA1CComponent = this.checkWhetherShowA1C();
        if(!this.props.alwaysShow && !showA1CComponent){
            return '';
        }

        const { getFieldDecorator } = this.props.form;
        // const disableA1CBox = this.checkWhetherDisableCheckBox();
        const showNeedInHouse = this.checkWhetherShowNeedInHouse();

        return (
          <div>
            <Divider></Divider>
            <Row style={_.get(this, 'props.rowStyle')} gutter={4}>
                  <Col span={8} style={_.get(this, 'props.colStyle')}>
                      <Item label="A1C%" {..._.get(this, 'props.itemStyle')}>
                          {getFieldDecorator('A1C', {
                              rules: [
                                  { required: false},
                              ],
                          })(
                              <InputNumber min={0} style={{ width: 'auto' }}/>
                          )}
                      </Item>
                  </Col>

                  <Col span={8} style={_.get(this, 'props.colStyle')}>
                      <Item label=" " colon={false} {..._.get(this, 'props.itemStyle')}>
                          {getFieldDecorator('A1CDate', {
                              rules: [{required: false}]})
                          (
                              <DatePicker placeholder='Record Date'
                                          format={dateFormat}
                                          onChange={this.selectDate}
                                          disabledDate={this.disabledDate}/>
                          )}
                      </Item>
                  </Col>
                  {showNeedInHouse ? this.renderNeedInHouseLabel() :''}
                  {this.renderA1CLabel(showNeedInHouse)}

              </Row>
          </div>
        )
    }

}


export default VisitInputA1C;
