import * as R from 'ramda';
import moment from 'moment';

const trace = R.tap(R.compose(R.partial(console.log, ['trace: date - '])));

const formatMinutes = (s) => (s.toString().length === 1 ? '0' : '') + s
const mkShortDatetimeF = (dt) => dt instanceof Date ? dt.getMonth()+1 + '/' + dt.getDate() + ' ' + dt.getHours() + ':' + formatMinutes(dt.getMinutes()) : dt;
const mkShortDateF = (dt) => dt instanceof Date ? dt.getMonth()+1 + '/' + dt.getDate() : dt;
const mkShortDatetime = (value) => moment(value, moment.ISO_8601).isValid() ? mkShortDatetimeF(moment(value, moment.ISO_8601).toDate()) : value;
const mkShortDate = (value) => moment(value, moment.ISO_8601).isValid() ? mkShortDateF(moment(value, moment.ISO_8601).toDate()) : value;
const mkShortDatetimeT = R.compose(trace, mkShortDatetime, trace);
const mkShortDateT = R.compose(trace, mkShortDate, trace);

export { mkShortDatetime, mkShortDate };