import { React } from 'ihcomponent'
import { graphql } from 'react-apollo';
import ListComponent from '../components/ListComponent';
import { patientListTableQuery } from 'graphqlModule/patientList'
import {helpers as dataHelper} from "../../../visitNewWorkFlow/helper";
import { correctFilter, getMeasurementDaysFilter, getProgramsEnrolledFilter ,getMNTStatusFilter } from '../helper/searchHelper'; 
import { PATIENT_TYPE  } from '../constants/MainConstants';
import CreatePatientFormComponent from '../../../visitNewWorkFlow/components/CreatePatientFormComponent';
import CreatedPatientSuccessComponent from "../../../referAndEnroll/component/CreatedPatientSuccessComponent";
import { GQLHelper } from 'lib/utils';
import { API } from '../../../visit/query';
import { Modal } from 'antd';
import {checkIsInRole} from "../../../../lib/helpers/role-helpers";
import CURRENT_COMPONENT from "../../../visitNewWorkFlow/constants/CurrentComponent";
import roleHelpers from '../../../taskAssignment/helper';
import { roleNameMap } from '../../../utils/constants/role';       
const NOT_NECESSARY = "not-necessary";
const withData = graphql(patientListTableQuery, {
  options: (ownProps) => {
    const patientType = ownProps.patientType;
    const isMNTReferral = ownProps.isMNTReferral;
    const allFilters = _.get(ownProps, 'filter', {});
    // remove the table name in filter
    let tableFilters = _.pick(allFilters, _.values(PATIENT_TYPE));
    tableFilters = _.get(tableFilters, patientType, {});
    const restFilters = _.omit(allFilters, _.values(PATIENT_TYPE));
    let filter = _.cloneDeep({ ...tableFilters, ...restFilters });
    let { timeSpent } = filter;
    filter.showAll = true;

    if (filter.programsEnrolled) {
      if (filter.programsEnrolled.includes('RPM_WITH_LOG')) {
        filter.programsEnrolled = ["RPM"];
        filter.hasCallLog = true;
      }
      if (filter.programsEnrolled.includes('RPM_WITHOUT_LOG')) {
        filter.programsEnrolled = ["RPM"];
        filter.hasCallLog = false;
      }
    }

    // replace the assignee 'null' to null
    // replace not-necessary to assignedToCANotNecessary/assignedToRDNotNecessary
    if(filter.assignedToRD){
      let assignedToRD = [];
      _.forEach(filter.assignedToRD, assignee => {if(!_.includes(['null', 'not-necessary'], assignee)) assignedToRD.push(assignee)});
      if(_.includes(filter.assignedToRD, 'null')) assignedToRD.push(null);
      if(_.includes(filter.assignedToRD, NOT_NECESSARY)){
        filter.assignedToRDNotNecessary = true;
      } else {
        filter.assignedToRDNotNecessary = false;
      }
      filter.assignedToRD = assignedToRD;
    }

    if(filter.assignedToCA){
      let assignedToCA = [];
      _.forEach(filter.assignedToCA, assignee => {if(!_.includes(['null', 'not-necessary'], assignee)) assignedToCA.push(assignee)});
      if(_.includes(filter.assignedToCA, 'null')) assignedToCA.push(null);
      if(_.includes(filter.assignedToCA, NOT_NECESSARY)){
        filter.assignedToCANotNecessary = true;
      } else {
        filter.assignedToCANotNecessary = false;
      }
      filter.assignedToCA = assignedToCA;
    }
    // do not pass the assignee if the array is empty
    if(_.isEmpty(filter.assignedToRD )){
      filter = _.omit(filter, ['assignedToRD']);
    }
    if(_.isEmpty(filter.assignedToCA )){
      filter = _.omit(filter, 'assignedToCA');
    }



    // Not using daysRemaining (when program ends) as of 20200630
    // if (!isNaN(filter.daysRemaining)) {
    //   filter.daysRemaining = filter.daysRemaining[0];
    // }

    //let searchStr = _.get(ownProps, 'search', _.get(ownProps, 'prevSearch', ''));

    //parse time spent in min-max range
    if(timeSpent) {
      let timeSpentObj = (() => {
        return _.map(timeSpent, (t) => {
          const timeRange = t.split(':');
          return { min: timeRange[0]*60, max: timeRange[1]*60 };
        })
      })()
      filter.timeSpent = timeSpentObj;
    }

    filter = getProgramsEnrolledFilter(filter);
    
    filter = getMeasurementDaysFilter(filter, patientType === PATIENT_TYPE.ENROLLED);

    filter = correctFilter(filter, patientType !== PATIENT_TYPE.ENROLLED);
    filter = isMNTReferral ? getMNTStatusFilter(filter) : filter;
    const variables = {
      page: _.get(ownProps, `page.${patientType}.current`, 1),
      count: _.get(ownProps, `page.${patientType}.pageSize`, 10),
      filters: filter,
      search: {
        fields: ['NAME', 'IDENTIFICATION', 'PHONE', 'BIRTHDAY'],
        match: '',
      },
      // default sorting, not explicit to user
      sort: { 'field': 'LAST_NAME', 'direction': 'ASC' } //{ 'field': 'BIRTHDAY', 'direction': 'DESC' }
    };

    const sortField = _.get(ownProps, `sort.${patientType}.fieldGraph`, null);
    if (sortField) {
      let direction = _.get(ownProps, `sort.${patientType}.direction`);
      const INVERSE = {
        'ASC': 'DESC',
        'DESC': "ASC"
      };

      if (sortField === 'BIRTHDAY') direction = INVERSE[direction]; //sorting by birthday is inverse of sorting by age

      variables.sort = {
        field: sortField,
        direction: direction
      };
    }


    return {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all'
    };
  },
  props: ({ data,variables }) => {
    const { error } = data;
    if (error) {
      console.error(error);
    }
    return {
      data
    };
  }
});


const Component = class extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        showModal: false,
        modalData: null,
        showReferOrEnroll: false
      };
    }

    componentDidMount() {
      const { data, setRefetchPatientList } = this.props;
      const refetchPatientList =  _.get(data, 'refetch');
      if (setRefetchPatientList && _.get(data, 'refetch')) {
        setRefetchPatientList(refetchPatientList);
      }
    }

    componentDidUpdate(prevProps) {
      const { data, setRefetchPatientList } = this.props;
      const refetchPatientList =  _.get(data, 'refetch');
      if (setRefetchPatientList && _.get(prevProps, 'data.refetch') !== refetchPatientList) {
        setRefetchPatientList(refetchPatientList);
      }
    }


	changeStateAfterSucceed = (res)=>{
		const isMAorMD = checkIsInRole(['MA','Doctor']);
		const { profile:{ fullName },id }  = _.get(res,'data.createUser');
		// const patientId = _.get(res,'data.createdUser.profile')
		if(isMAorMD) {
			this.setState({
				showReferOrEnroll: true,
				fullName,
                patientId: id
			});
		}
	}

    // --- For MA/MD only
    createPatient = (values)=>{
      const { changeStateAfterSucceed } = this;
      const { data:{ refetch }, setCreateNewPatientForm, openErrorModal } = this.props;
      const mutatedValue = dataHelper.getMutateVariable(values);
      const variables = dataHelper.trimAllValues(mutatedValue,'',mutatedValue);
      API.createPatient(variables)
      .then((res) => {
      	console.log(res);
        setCreateNewPatientForm(false);
        changeStateAfterSucceed(res);
        refetch();
      })
      .catch(e => {
          console.error(e);
          openErrorModal(GQLHelper.formatError(e));
      });
    }

    renderCreateNewPatientForm = () => {
      return  <Modal visible={true} footer={false} onCancel={() => this.props.setCreateNewPatientForm(false)}>
	             <CreatePatientFormComponent onCreatePatient={this.createPatient}/>
              </Modal>
    }

    setShowReferOrEnroll = (showReferOrEnroll)=>{
    	this.setState({
		    showReferOrEnroll
	    })
    }

    renderPatientSuccess = ()=>{
    	const { fullName,patientId } = this.state;
	    return  <Modal visible={true} footer={false} onCancel={() => this.setShowReferOrEnroll(false)}>
		    <CreatedPatientSuccessComponent onCreatePatient={this.createPatient} fullName={fullName} closeModal={this.setShowReferOrEnroll} memberId={patientId}/>
	    </Modal>
    }
    // --- END for MA/MD only

    // --- for Task Assignment Modal
    setModalData = ({showModal,modalData})=> this.setState({ showModal, modalData });
    // --- End for Task Assignment

    render() {
      const { setModalData, state, props,renderPatientSuccess } = this;
      const { showModal,modalData,showReferOrEnroll } = state;
      const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
      const teamsFromLoginUser = loginUser.team || [];
      const CAs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.CA]);
      const RDAndHCs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.RD, roleNameMap.HC]);
      return (
        <React.Fragment>
          <ListComponent {...props} setModalData={setModalData} modalData={modalData} showModal={showModal}
            CAs={CAs} RDAndHCs={RDAndHCs}/>
          {
            // for MA/MD only
            this.props.showCreateNewPatientForm &&
            this.renderCreateNewPatientForm()
          }
          {
			showReferOrEnroll && renderPatientSuccess()
          }
        </React.Fragment>
      )
    }
};

export default withData(Component);
