import React from 'react';
import { Form } from 'antd';
import { CGMDropdownFormItemComponent } from '../components/CGMDropdownComponent/CGMDropdownFormItemComponent';
import { useCGMContext } from '../context/CGMContext';

export const CGMDropdownContainer = ({
  form,
  onSubmit,
  disabled,
}) => {
  const {
    CGMInfo,
    getAvailableCGMBrands,
  } = useCGMContext();

  const handleSubmit = (values) => {
    onSubmit && onSubmit(values);
  };

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <CGMDropdownFormItemComponent
        form={form}
        deviceList={getAvailableCGMBrands()}
        initialValue={CGMInfo.cgmBrand || null}
        disabled={disabled}
      />
    </Form>
  );
};
