import types from 'ProgramIncompleteModule/Device/constants/DeviceFormConstants'
import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from 'programModule/Common/components/formData/DeviceForm'
import { commonReducer } from 'libModule/helpers/reducer-helpers'

// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { vitalType : {name: 'vitalType', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initState = {
  initData,
  fieldsValue: null,
  isFormValid: calcFormStatus(initFieldsValue, isFieldsRequired),
  isFieldsRequired
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormValid: calcFormStatus(newFieldsValue, isFieldsRequired)
      }
    case types.RESET_FORM:
      return initState
    default:
      return state
  }
}

export default (state = {}, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
