import React from 'react';
import { Row,Col,Modal } from 'antd';
import EditPatientFormComponent from '../../visitNewWorkFlow/components/EditPatientFormComponent'
import helper from '../../utils/helpers/index';
import { IHLoading, IHSearchTable, IHButton, _, IHInput,message } from 'ihcomponent';
import API from '../helper/index';
let divStyle = {
    backgroundColor: '#FFFFFF',
    padding: 30,
    border:'solid 1px rgba(0,0,0,0.1)',
};

let topDivStyle = Object.assign({ boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1)' },divStyle) ;
import VisitListFromContainer from '../container/VisitListForMAContainer';

const MAPatientProfileComponent = class extends React.Component{
    constructor(){
        super();
        this.state = {
            showEditProfile: false
        }
    }

    rightBottomDiv = (visitList)=>{
        const { refetchVisitList,user,enrolledPrograms } = this.props;
        return <Row style={ Object.assign({ margin:'30px 30px 0px 30px' },divStyle )}>
            <Col span={24} style={{ marginBottom:20 }}><h4>Visits</h4></Col>
            <Col span={24}>
                <VisitListFromContainer visitList={visitList} refetchVisitList={refetchVisitList} user={user} enrolledPrograms={enrolledPrograms}/>
            </Col>
        </Row>
    }

    topDiv = (user)=>{
        const { profile:{ fullName,gender,birthday,doctor,language:{description} } } = user;

        return <Row style={topDivStyle}>
            <Col span={24} style={{ marginBottom:20 }}><h4>{fullName}</h4></Col>
            <Col span={24}>
                <span style={{ fontSize: 14 }}>{`${gender} ${helper.getAge(birthday)} y ${ description } ${ _.get(doctor,'profile.fullName')||'--' }`}</span>
            </Col>
        </Row>
    }

    createEleBasedOnMap = (map)=>{
        return _.map(map,({title,value,render},index)=>{
            return <Col span={8} key={index} style={{ marginTop: 30 }}>
                <Row>
                    <Col span={24} style={{ color:'#6B6B6B',marginBottom: 5  }}>{title}</Col>
                    <Col style={{color:'#fffff'}}>{! render? (value||'--') : render() }</Col>
                </Row>
            </Col>
        })
    }

    setShowEditProfile = (showEditProfile)=>{
        this.setState({
            showEditProfile
        })
    }

    handleSendLink = (memberId)=>{
        API.sendInvitationLinkMutation({memberId})
            .then(res=>{
                const { data:{ sendInvitationLink:{notified } } } = res;
                const notifiedMsg = _.get(res,'data.sendInvitationLink.message');
                if(notified){
                    message.success(notifiedMsg);
                }else{
                    message.error(notifiedMsg);
                }
            })
    }
    leftBottomDiv = (user)=>{
        const { profile:{ firstName,lastName,doctor,birthday,language:{description},gender },username,identification,phone,id } = user;
        const medicalId = _.get(identification,'0.value');
        const homePhone = _.first(_.filter(phone,({type})=>type =='HOME'))||{};
        const mobilePhone = _.first(_.filter(phone,({type})=>type =='MOBILE'))||{};
        const map = [   { title:'First Name',value:firstName},
            { title:'Last Name',value:lastName},
            { title:'Username',value:username },
            { title:'DOB',value:birthday },
            { title:'Spoken Language',value:description },
            { title:'Gender',value:gender },
            { title:'Doctor Name',value:_.get(doctor,'profile.fullName')},
            { title:'Medical Id',value:medicalId },
            { title:'Home Phone',value:homePhone.number },
            { title:'Mobile Phone',value:mobilePhone.number },
            { title:'OTP Login',value:mobilePhone.canUseForLogin ? 'M' : '--' },
            {
                title:'App Installation',
                value:'',
                render:()=><a onClick={()=>this.handleSendLink(id) }>Send Link</a>
            }
        ];

        return <Row style={ Object.assign({ margin:'30px 0px 0px 30px' },divStyle )}>
            <Col span={24}><h4>Basic Information</h4></Col>
            <Row style={{ marginBottom:30,fontSize:14 }}>
                { this.createEleBasedOnMap(map) }
            </Row>
            <Col span={24}>{this.editUserButton()}</Col>
        </Row>
    }


    editUserButton = ()=>{
        const { setShowEditProfile } = this;
        return <div onClick={()=>setShowEditProfile(true)} style={{textDecoration:'underline',padding:'10px 0px',fontSize:14}}>
            <a href="javascript: void(0)" style={{ color:'black' }}>Edit Patient Profile</a>
        </div>
    }

    bottomDiv = (user,visitList)=>{
        return <Row style={{ display:'flex',flexDirection:'row' }}>
                <Col span={12}>{ this.leftBottomDiv(user) }</Col>
                <Col span={12}>{ this.rightBottomDiv(visitList) }</Col>
               </Row>
    }

    renderEditProfileModal = ()=>{
        const { setShowEditProfile } = this;
        const { user } = this.props;
        return <Modal visible={true} footer={false} onCancel={()=>setShowEditProfile(false)}>
                 <EditPatientFormComponent user={user} setShowEditProfile={setShowEditProfile}/>
               </Modal>
    }

    render(){
        const { props,topDiv,bottomDiv,state,renderEditProfileModal } = this;
        const { user,loading,visitList } = props;
        const { showEditProfile } = state;
        const phone = _.get(this,'props.user.phone');
        const homePhone = _.first(_.filter(phone,({type})=>type =='HOME'))||{};
        const mobilePhone = _.first(_.filter(phone,({type})=>type =='MOBILE'))||{};
        if(loading){
            return <IHLoading/>
        }
        const { profile:{ fullName } } = user;
        return<div>
                { showEditProfile ? renderEditProfileModal() :'' }
                { topDiv(user) }
                { bottomDiv(user,visitList)}
              </div>
    }
}

export  default MAPatientProfileComponent;
