import { useState } from 'react';
import { STORAGE_KEY } from '../constants';

const useOption = ({
  storageKey = STORAGE_KEY
} = {}) => {
  const [option, setOption] = useState(JSON.parse(sessionStorage.getItem(storageKey)) || {});

  const handleRemoveOption = () => {
    sessionStorage.removeItem(storageKey);
    setOption({});
  };

  return {
    ...option,
    removeOption: handleRemoveOption,
  };
};

export default useOption;
