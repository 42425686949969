import React from 'react';
import setConditionCodeAbbr from '../constants/codePrefixAbbr';
import PropTypes from 'prop-types';

export const genHC = (user) => {
  let abbrList = [];
  const healthConditionsArray = (user._get ? 
                                user._get('profile.healthConditions') : 
                                user.profile && user.profile.healthConditions) || 
                                [];
  healthConditionsArray.forEach(rawConditionName => {
    setConditionCodeAbbr(rawConditionName, abbrList);
  });
  return [...abbrList];
};

// Thong - get array of raw data of healthconditions (ie: conditionName::code)
// @param
//  user (required) - User class
//  containerStyle - custom style for container
const HealthConditionRenderer = ({user, containerStyle}) => {
  const els = genHC(user);
  return (
    <div style={{ lineHeight: 2, ...containerStyle }} >
      <span style={{ fontWeight: 'bold', marginRight: 3 }}>Health Condition(s):</span>
      {
        els.length ?
          els.map((displayName, index) => 
            displayName && <span className='v-tag' key={index}>{displayName}</span>
          ) 
        : 
          'N/A'
      }
    </div>
  )
};

HealthConditionRenderer.propTypes = {
  user: PropTypes.object.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.any })
  ])
}

export default HealthConditionRenderer;