const CURRENT_COMPONENT = {
    SEARCH_COMPONENT: 'search',
    INPUT_VISIT_COMPONENT: 'input_visit',
    CREATE_PATIENT_COMPONENT: 'create_patient',
    INITIAL_PATIENT_COMPONENT: 'initial_visit',
    DELETE_VISIT: 'delete_visit',
    EDIT_VISIT: 'edit_visit',
    CREATE_SUCCEED: 'create_succeed'
};

export default CURRENT_COMPONENT;
