import { constants } from 'libModule/utils';

export const alertColor = {
  NORMAL: '#64c2b1',
  RISK: '#e89b5c',
  CRITICAL: '#e95c69',
  UNDEFINED: '#64c2b1',
  MISSED: '#dcdcdc'
}

export const alert_BP_Color = {
  aha_NormalReading: 'rgb(105,211,118)',
  aha_ElevatedReading: 'rgb(253,181,67)',
  aha_HypStage_1: 'rgb(255,134,46)',
  aha_HypStage_2: 'rgb(239,69,69)',
  aha_HypCris: 'rgb(217,21,21)',
  BPLowReading: 'rgb(49,119,201)',
  UNDEFINED: '#64c2b1',
  MISSED: '#dcdcdc'
}

export const HSColor = {
    NORMAL: '#64c2b1',
    OVERWEIGHT: '#fca642',
    UNDEFINED: '#e3e3e3',
    MISSED: '#dcdcdc',
    OBESE:'#ff6464',
    UNDERWEIGHT:'#fdd487'
}
export default constants('patientView', 'VitalList', [
  'SORT',
  'CHANGE_PAGE',
  'LOADING',
  'CACHE',
  'CHANGE_PROGRAM',
  'CHANGE_VIEW',
  'CHANGE_RANGE',
  'CHANGE_BPMODE',
  'CHANGE_BGMODE',
  'ON_TABLE_CHANGE'
])
