import { combineReducers } from 'redux'
import Common from './Vital/ducks/CommonDuck'
import VitalForm from './Vital/ducks/VitalFormDuck'
import VitalList from './Vital/ducks/VitalListDuck'
import VitalAlert from 'patientModule/VitalAlert/ducks'
import Assessment from 'patientModule/Assessment/ducks/'
import Survey from 'patientModule/Survey/reducers'

const Careplan = combineReducers({
  Common,
  VitalForm,
  VitalList,
  VitalAlert,
  Assessment,
  Survey,
})

export default Careplan
