import {
    React, BaseComponent, ND, _, wrappedMapPropsToFields
} from './util'

import IHFormItem from './IHFormItem'
import * as R from 'ramda'
import PropTypes from 'prop-types';

const style = {
  c: {
    margin : '20px',
    padding : '20px'
  }
};

const Component  = class extends BaseComponent{

  static displayName = 'IHFBaseormWithRow';

  init(){
  }

  getEachFormItem(item, key){
    const { getFieldDecorator, getFieldError, isFieldValidating, setFields } = this.props.form

    let itemProp = {}
    const arr = [
      'label', 'labelCol', 'wrapperCol', 'help', 'validateStatus', 'hasFeedback', 'option',
      'extra', 'validateTrigger',
    ]
    _.each(arr, (key)=>{
      if(!_.isUndefined(item[key])){
        itemProp[key] = item[key]
      }
    })

    const fieldDecoratorProp = {
      initialValue : item.initialValue,
      rules : item.rules || []
    }
    if (item.validateTrigger) {
      fieldDecoratorProp.validateTrigger = item.fieldDecoratorProp
    }

    const FieldFn = getFieldDecorator(key, fieldDecoratorProp)

    const clearErrors = function () {
      if (!_.get(this, 'key')) return;
      setFields({
        [this.key]: {
          errors: null
        }
      })
    }
    item.clearErrors = clearErrors.bind(item)
    const jsxTag = this.getJsxTag(item)
    const className = item.className ? 'IH-FormItem ' + item.className : 'IH-FormItem'
    const span = _.get(item.col, 'span')
    const offset = _.get(item.col, 'offset')

    return (

      <ND.Col span={span} offset={offset} key={item.key+'_col'}>
        <div id={item.key}>
        <ND.Form.Item key={item.key} className={className} {...itemProp}>
          {FieldFn(jsxTag)}
          {item.children || null}
        </ND.Form.Item>
        </div>
      </ND.Col>
    )
  }

  getJsxTag(item){
    return <IHFormItem {...item} />
  }

  render(){
    let formProp = {
      style : this.props.style || {}
    }
    if(this.props.horizontal){
      formProp.horizontal = "true"
    }
    else if(this.props.vertical){
      formProp.vertical = "true"
    }
    else if(this.props.inline){
      formProp.inline = "true"
    }
    const className = this.props.className ? `IH-Form ${this.props.className}` : 'IH-Form'
    return (
      <ND.Form className={className} {...formProp}>
        {
          _.map(this.props.itemList, (row, i)=>{
            return (
              <ND.Row gutter={10} key={i}>
                {_.map(row, (item)=>{
                  return this.getEachFormItem(item, item.key)
                })}
              </ND.Row>
            )
          })
        }
        {this.props.children || null}
      </ND.Form>
    )
  }
}

Component.propTypes = {
  itemList : PropTypes.array.isRequired
}

const Form = class extends BaseComponent{
  shouldComponentUpdate(nextProps, nextState) {
    // return !_.isEqual(this.props, nextProps);
    if(nextProps.shouldComponentUpdate){
      return true
    }

    // legacy code, will remove in future
    const fieldsToShowNext = _.get(nextProps, 'fieldsToShow')
    const fieldsToShowCurrent = _.get(this.props, 'fieldsToShow')
    const isFieldsToShow = fieldsToShowCurrent
      && fieldsToShowNext && fieldsToShowCurrent.length < fieldsToShowNext.length
    const vitalTypeChanged = _.get(nextProps, 'fieldsValue.vitalType.value')
      !== _.get(this.props, 'fieldsValue.vitalType.value')
    const weightBasedOnChanged = _.get(nextProps, 'fieldsValue.weightBasedOn.value')
      !== _.get(this.props, 'fieldsValue.weightBasedOn.value')

    const nricEnableChanged = _.get(this.props,'nricEnable')!=_.get(nextProps,'nricEnable');
    const usernameEnableChanged = _.get(nextProps,'usernameEnable')!=_.get(this.props,'usernameEnable');

    if (vitalTypeChanged || weightBasedOnChanged || nricEnableChanged || usernameEnableChanged ) {
      return true
    } else {
      return false
    }
  }
  render(){
    // if (!this.Demo) {
      const { onFieldsChange, mapPropsToFields } = this.props;
      this.Demo = ND.Form.create({ onFieldsChange, mapPropsToFields:wrappedMapPropsToFields(mapPropsToFields)
      })(Component);
    // }
    return <this.Demo wrappedComponentRef={refNode => { this._form = refNode }} {...this.props} />
  }

  getValue(){
    return this._form.props.form.getFieldsValue()
  }

  // will validate
  getFormData(callback){
    this._form.props.form.validateFieldsAndScroll((error, values)=>{
      if(error){
        callback(false)
        return
      }
      callback(true, values)
    })
  }

  getFormDataSync(){
    return new Promise((resolve, reject)=>{
      this._form.props.form.validateFieldsAndScroll((error, values) => {
        if(error){
          reject(false)
          return
        }
        resolve(values)
      })
    })
  }

  setFormData(data){
    if(data){
      this._form.props.form.setFieldsValue(data)
    }
  }

  componentDidMount(){
    if(this.props.initData){
      _.delay(()=>{
        this.setFormData(this.props.initData)
      }, 10)
    }
  }
}

export default Form
