import { React } from 'ihcomponent';
import PropTypes from 'prop-types';
import { Modal, Form, Icon, Button } from 'antd';
import '../../style/careTeam.styles.scss';
import helpers from '../../helper';
import { CREATE_CARETEAM, processStatusMap } from '../../constant/careTeamConstants';
import careTeamFormConfig from '../../constant/careTeamFormConfig';

const { renderForm } = helpers;

export class CareTeamModalBody extends React.Component {
  constructor(props){
    super(props);
    const { 
      modalAction = CREATE_CARETEAM, careTeamProps = {}, teamSelections = [], teamChannelCount 
    } = props.modalBodyProps || {};
    this.state = {
      loadingModal: '',
      loadingError: false,
      // object for loadingProgress
      // id: any unique id
      // process: name of the process, ie. add member,
      // status: status of the process, ie. inProgress, failed, succeeded, pending
      loadingProgress: [],
      stepCurrentIndex: 0,
      modalAction,
      careTeamProps,
      teamSelections,
      teamChannelCount
    };
  };
  
  setLoadingModal = 
  ({ loadingModal, forceClose, cb, ...extraOrCleanUpStates }) => {
    // prevent closing modal when there is loading Error
    // forceClose - to close modal manually
    if(!this.state.loadingError || forceClose) {
      this.setState({ loadingModal, ...extraOrCleanUpStates }, 
        () => {
          if(cb) cb();
        });
    }
  }

  updateLoadingProcess = (processId, process, status, error) => {
    const newProgress = [...this.state.loadingProgress];
    const [mainProcessId, subProcessId] = _.split(processId, '-');
    const processIndex = _.findIndex(newProgress, { id: mainProcessId });
    const mainProcess = _.get(newProgress, processIndex, {});
    if(subProcessId) { // has subProcess
      const subProcesses = mainProcess.subProcess;
      // using full Id to find subprocesses
      const subProcessIndex = _.findIndex(subProcesses, { id: processId });
      const prevSub = _.get(subProcesses, subProcessIndex, {});
      newProgress[processIndex].subProcess[subProcessIndex] = {
        id: processId,
        process: process || prevSub.process,
        status: status || prevSub.status,
        error
      };
    } else { // update standalone process
      newProgress[processIndex] = {
        id: mainProcessId,
        process: process || mainProcess.process,
        status: status || mainProcess.status,
        error
      };
    }
    const loadingError = !!this.state.loadingError || !!error;
    this.setState({ loadingProgress: newProgress, loadingError });
  }

  setTeamSelections = (newTeamSelections = [], isEditMode) => {
    this.setState({ teamSelections: newTeamSelections }, 
      () => this.props.setEditMode(isEditMode));
  };

  // using refDataSource instead of teamSelections
  // refDataSource give more correct ref indices if there is disabled provider
  setTeamOrder = (refDataSource, oldIndex, newIndex) => {
    const newTeamSelections = [...refDataSource];
    // swap p with p+1/ p-1
    // pToSwap, where the up/down arrow is clicked
    const pToSwap = newTeamSelections[oldIndex];
    // pToBeSwapped, where pToSwap will be moved to
    const pToBeSwapped = newTeamSelections[newIndex];

    newTeamSelections[newIndex] = pToSwap;
    newTeamSelections[oldIndex] = pToBeSwapped; 
    this.setTeamSelections(newTeamSelections);
  };

  toggleDisability = (providerIds, isDisabled) => {
    const newTeamSelections = _.map(this.state.teamSelections, p => {
      if(_.includes(providerIds, p.id)){
        return { ...p, disabled: isDisabled };
      }
      return {...p};
    });
    return newTeamSelections;
  };

  // disableOnly is to toggle disabled for selected provider
  // instead of adding new or removing from the current list
  addTeamMembers = (addProviders = [], disableOnly) => {
    let newTeamSelections;
    if(disableOnly) {
      const addProviderIds = _.map(addProviders, 'id');
      newTeamSelections = this.toggleDisability(addProviderIds, false);
    } else {
      newTeamSelections = [...this.state.teamSelections, ...addProviders];
      newTeamSelections = _.uniqBy(newTeamSelections, 'id');
    }
    this.setTeamSelections(newTeamSelections);
  };

  removeTeamMembers = (removePIds = [], disableOnly) => {
    let newTeamSelections;
    if(disableOnly) {
      newTeamSelections = this.toggleDisability(removePIds, true);
    } else {
      newTeamSelections = 
        _.filter([...this.state.teamSelections], p => !_.includes(removePIds, p.id));
    }
    this.setTeamSelections(newTeamSelections);
  };

  goNextStep = (newStepCurrentIndex = (this.state.stepCurrentIndex + 1)) => 
    this.setState({ stepCurrentIndex: newStepCurrentIndex });

  changeAuthority = (providerId, newAuthority) => {
    const newTeamSelections = [...this.state.teamSelections];
    const pIndex = _.findIndex(newTeamSelections, { id: providerId });
    newTeamSelections.splice(pIndex, 1, {...newTeamSelections[pIndex], ...newAuthority});
    this.setTeamSelections(newTeamSelections);
  };

  renderProgress = (progress, isSubProcess = false) => (
    <ul style={{ padding: 0, marginTop: isSubProcess ? 10 : 18 }}>
      {
        _.map(progress, (p, index) => {
          const { id: pId, process, status, subProcess = [], error } = p;
          return <li 
            key={`${pId}-${index}`}
            style={{ 
              display: 'flex', 
              marginBottom: 12,
              flexDirection: 'column',
              paddingLeft: isSubProcess ? 10 : 0
            }}
          >
            <div>
              <span>{process}</span>
              {
                subProcess.length > 0 ?
                this.renderProgress(subProcess, true) // recursive rendering
                :
                this.renderProcessStatus(status, error)
              }
            </div>
            {
              error &&
              <div style={{ fontSize: '0.8em', color: 'red' }}>
                {error.message}
              </div>
            }
          </li>
        })
      }
    </ul>
  )

  renderProcessStatus = (status, error) => {
    const { type, color, spin = false } = processStatusMap[status] || {};
    return type ?
    <Icon type={type} spin={spin} style={{ color, fontSize: '1.6em', marginLeft: 5 }}/>
    :
    <span style={{fontSize: '0.8em', fontStyle: 'italic', marginTop: 3, marginLeft: 5}}>
      pending...
    </span>;
  }; 
  
  render() {
    const {
      state, props,
      setLoadingModal, updateLoadingProcess, goNextStep,
      setTeamOrder, addTeamMembers, removeTeamMembers, changeAuthority,
      renderProgress
    } = this;
    const { 
      loadingModal, loadingError, loadingProgress, 
      modalAction, careTeamProps, stepCurrentIndex, 
      teamSelections, teamChannelCount
    } = state;
    const { form, isEditMode, setEditMode, closeAddModal, organization,needRefetchTeamList } = props;
    const organizationRoleRefIds = _.get(organization, 'roles', [])

    return <React.Fragment>
      <div className='care-team-modal-title'>
        {`${_.capitalize(modalAction)} care team`}
      </div>
      {
        renderForm({
          modalAction, stepCurrentIndex, goNextStep,
          organization, organizationRoleRefIds,
          teamSelections, setTeamOrder,
          addTeamMembers, removeTeamMembers, changeAuthority, 
          isEditMode, setEditMode, 
          setLoadingModal, updateLoadingProcess,
          onModalCancel: closeAddModal,
          needRefetchTeamList,
          careTeamProps, teamChannelCount,
          // form render in helpers using user for value
          // to get inital value, passing to user as props
          props: {
            user: { careTeamProps }, // used this to render form values from path
            form
          } 
        }, careTeamFormConfig)
      }
      {
        (loadingModal || loadingError) &&
        <Modal
          visible={true}
          destroyOnClose
          maskClosable={false}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: 'rgba(0,0,0,.9)' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <span>{loadingModal}</span>
            <div style={{ textAlign: 'left' }}>
              { !loadingProgress.length && <Icon type='sync' spin /> }
              { renderProgress(loadingProgress) }
            </div>
            {
              loadingError &&
              <div style={{ textAlign: 'right' }}>
                <Button 
                  onClick={() => {
                    setLoadingModal({
                      loadingModal: '',
                      loadingProgress: [],
                      loadingError: false,
                      forceClose: true, 
                      cb: () => closeAddModal({ checkEditMode: false })
                    });
                  }}
                >
                  Close
                </Button>
              </div>
            }
          </div>
        </Modal>
      }
    </React.Fragment>
  };
};

CareTeamModalBody.propTypes = {
  modalBodyProps: PropTypes.shape({
    modalAction: PropTypes.string,
    careTeamProps: PropTypes.object, 
    teamSelections: PropTypes.array,
    teamChannelCount: PropTypes.number
  }),
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  isEditMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  closeAddModal: PropTypes.func.isRequired
};

export default Form.create({
  onValuesChange: props => {
    const { isEditMode, setEditMode } = props;
    if(!isEditMode && setEditMode) {
      setEditMode();
    }
  }
})(CareTeamModalBody);
