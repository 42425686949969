import gql from 'graphql-tag';
import { Phone } from "../../graphql/schema/common";
import { ConditionsListSchema } from "../../graphql/schema/ConditionsList";

const VisitEnrolledProgram = `
  enrolledProgram {
    id
    status
  }
`
const VisitMember = `
  member{
    id
    remoteEnrollment{
      status
      doctorReferral 
      doctorReferralDate
      ${ConditionsListSchema}
    }
    phone{
      ${Phone}
    }
    assignees{
      assignedToRD{
        id
        profile{
          fullName
        }
      }
      assignedToCA{
        id
        profile{
          fullName
        }
      }
      assignedToCANotNecessary
      assignedToRDNotNecessary
    }
    profile{
      fullName
      gender
      
      ...on MemberProfile{
        birthday
        lastMeasuredAt
        isSelfEnrolled
        healthConditions
        programCategories {
          name
          enrolled
        }
        doctor {
          id
          profile {
            firstName
            lastName
            fullName
          }
        }
        appLanguage{
          code
          description
        }
        language{
          code
          description
        }
        healthConditions
        billableHealthConditions{
          description
          code
        }
      }
    }
  }
`;
export const singleVisitInCalendarQuery = gql`
query singleVisitInCalendar($id: EID!) {
  getVisit(id: $id) {
    id
    assignees{
      assignedToRD{
          providerId,name
      }
      assignedToCA{
          providerId,name
      }
      assignedToHC{
        providerId,name
      }
    }
    type
    confirm
    reason
    
    doctorNotes
    conditionsList{
      HLD
      HTN
      DM
      CKD
      Obesity
      PreDM
      COPD
      ESRD_Dialysis
    }
    category
    appointmentAt
    appointmentTo
    checkinAt
    checkoutAt
    providers{
      id
      profile{
        fullName
        firstName
        lastName
      }
    }
    visitRoles
    shouldSeeDoctor  

    ${VisitMember}
    ${VisitEnrolledProgram}
  }
}
`;
