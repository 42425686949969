import React  from "react";
import {graphql} from "react-apollo";
import { compose } from 'react-apollo'
import {getMonthlySummaryReportByIdQuery, previewMonthlySummaryReport} from '../API/index';
import MonthlyReportComponent from "../component/MonthlyReportComponent";
const MonthlyReportContainer = class extends React.Component{
	render() {
		const { monthlyReport, loadingMonthlyReport,currentProgram } = this.props;
		if(loadingMonthlyReport) {
			return <div>loading</div>
		}
		return <div><MonthlyReportComponent loading={loadingMonthlyReport} monthlyReport={monthlyReport} currentProgram={currentProgram}/></div>
	}
}
const withData = graphql(getMonthlySummaryReportByIdQuery, {
	skip:(ownProps)=>{
		const { id } = ownProps;
		return typeof id==='boolean';
	},
	options: (ownProps) => {
		const { id } = ownProps;
		return {
			skip: !id,
			variables:{
				id
			},
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
		};
	},
	props: ({ownProps, data}) => {
		const { loading,getMonthlySummaryReportById,refetch } = data;
		return {
			monthlyReport: getMonthlySummaryReportById,
			loadingMonthlyReport: loading,
			refetchMR: refetch,
		}
	}
})

const withDataPreview = graphql(previewMonthlySummaryReport, {
	skip:(ownProps)=>{
		const { id } = ownProps;
		return typeof id==='string';
	},
	options: (ownProps) => {
		const { memberId,id } = ownProps;
		return {
			skip: id,
			variables:{
				memberId
			},
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
		};
	},
	props: ({ownProps, data}) => {
		const { loading,previewMonthlySummaryReport,refetch } = data;
		return {
			monthlyReport: previewMonthlySummaryReport,
			loadingMonthlyReport: loading,
			refetchMR: refetch,
		}
	}
})
export default compose(withData,withDataPreview)(MonthlyReportContainer);
