import React from 'react';
import { Button,message } from 'antd';
import { resolveMissingInfoHandler } from '../helper/index';
import { resolveMissingInfoAPI } from '../API/index';
const AllMissingInfoResolvedComponent = class extends React.Component{

	renderButton = (id)=>{
		return <div style={{ marginTop:15 }}>
				<Button onClick={()=>resolveMissingInfoHandler(this.props,id)} type={'primary'}>Confirm All Resolved</Button>
			   </div>
	}
	renderText = ()=>{
		return <p>All missing patient info has been resolved,please double confirm and close this task</p>
	}
	render() {
		const { missingInfo } = this.props;
		const missingInfoId = _.get(missingInfo,'id');
		return <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',padding:15, height: '100%' }}>
					{ this.renderText() }
					{ this.renderButton(missingInfoId) }
			   </div>

	}
}

export default AllMissingInfoResolvedComponent