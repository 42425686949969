import update from 'immutability-helper'

export const userPhone = {
  user: (prev, { mutationResult }) => {
    const newPhone = mutationResult.data.changeUserPhone.phones
    return update(prev, {
      user: {
        phones: {
          $set: newPhone,
        }
      }
    })
  },
}
