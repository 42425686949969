import { IHPanel, React, IHButton, IHLoading, IHBaseForm } from 'ihcomponent';
import I18N from '../../../I18N';
import { pwValidatorNoErrorMsgs, passwordConfirmValidator, tooShortPassword, requireDigitsInPassword, requireLowercaseInPassword,
  requireUppercaseInPassword, reqSpecCharsInPassword } from '../../../../lib/validator';
import { submittingStyle, goPath } from 'libModule/utils'
import MainModal from 'layoutModule/containers/MainModalContainer';

const TITLES = I18N.get('auth.password');

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.showHidePw = this.showHidePw.bind(this);
    this.handleShowHideClick = this.handleShowHideClick.bind(this);
    this.state = {
      isPasswordVisible: false,
      inputField:'',  // used for valid rules coloring
      fields: {       // will be passed to and updated by the login form
        newPass: {
          value: '',
        },
        newPassCheck: {
          value: ''
        }
      },
    }
  }

  //call back function when form fields are changed
  handleFormChange = (changedFields) => {
      this.setState(({ fields }) => ({
        fields: { ...fields, ...changedFields },
        inputField: fields.newPass.value
      }));
  }

  handleShowHideClick(e) {
    e.preventDefault();

    this.setState((prevState, props) => {
      return {
        isPasswordVisible: !prevState.isPasswordVisible
      }
    });
  }
  showHidePw() {
    return this.state.isPasswordVisible ? <img src='/image/eye_on_icon.svg' onClick={ e => this.handleShowHideClick(e) } className='change-password-eye-off' />
      : <img src='/image/eye-off.svg' onClick={ e => this.handleShowHideClick(e) } className='change-password-eye-off' />
  }
  render() {
    const {
      selectedUser,
      onSubmit,
      isSubmitting,
    } = this.props;

    const validAndSubmit = (e, { validateFieldsAndScroll }) => {
      e.preventDefault();

      validateFieldsAndScroll((err, values) => {
        !err && onSubmit(values);
      });
    };

    const formProp = {
      onSubmit: validAndSubmit,
      horizontal: true,
      ref: 'form',
      itemList: [
        {
          key: 'newPass',
          label: TITLES.newPw,
          type: this.state.isPasswordVisible ? 'text' : 'password',
          suffix: this.showHidePw(),
          rules: [
            { validator: (rule, value, callback) => {

                return callback(pwValidatorNoErrorMsgs(value));
              }
            },
          ],
        },
        {
          key: 'newPassCheck',
          label: TITLES.confirmNewPw,
          type: this.state.isPasswordVisible ? 'text' : 'password',
          suffix: this.showHidePw(),
          rules: [
            { validator: (rule, value, callback) => {
              const pwd = this.refs.form.getValue().newPass;
              return callback(passwordConfirmValidator(value, pwd));
            },
            },
          ],
        },
      ],
    };

    const onCancel = e => {
      e.preventDefault();
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }

    return (
      <div className="vsm-auth-box vsm-new-box auth-reset-password" style={{ margin: 0, width: 300 }}>
        {/* <MainModal /> */}
        <div>
          <IHBaseForm {...formProp} {...this.state.fields}
                      onFieldsChange={(props, changedFields)=>{props.onChange(changedFields);}}
                      mapPropsToFields={(props)=>{
                        return {newPass: props.newPass, newPassCheck: props.newPassCheck};}}
                      onChange={this.handleFormChange}>
            <div className='change-password-rules'>
              <ul>
                <li className={ tooShortPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule1 }</li>
                <li className={ requireDigitsInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule2 }</li>
                <li className={ requireLowercaseInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule3 }</li>
                <li className={ requireUppercaseInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule4 }</li>
              </ul>
            </div>
            <div className="buttons-wrap">
              <IHButton className='change-password-submit-btn' bsStyle="primary" type='submit' disabled={isSubmitting} style={submittingStyle(isSubmitting)}>
                {!isSubmitting ? I18N.get('auth.resetPassword.submit'):<IHLoading />  }
              </IHButton>
              <IHButton className='change-password-cancel-btn' bsStyle="default" onClick={ onCancel }>
                  { I18N.get('auth.resetPassword.cancel') }
              </IHButton>
            </div>
          </IHBaseForm>
        </div>
      </div>
    );
  }
}
