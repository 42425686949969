import config from 'libModule/config';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers';
const ROOT_URL = `${config.SERVER_URL}/chs`;

class CHS {
  entryPoints = {
    notificationChannelGroup: '/notificationChannelGroup',
    paginatedChannels: '/paginatedChannels',
    history: '/history',
    tag: '/tagMessage',
    tagRead: '/tagMessageRead',
    tagUnread: '/tagMessageUnread',
    publish: '/publish'
  };

  constructor(props) {
    const urls = Object.entries(this.entryPoints).map(([k, v]) => ({ [k]: `${ROOT_URL}${v}` }));
    this.urlObject = Object.assign({}, ...urls);
  };

  get URL() {
    return this.urlObject;
  };

  get sessionToken() {
    const sessionToken = sessionStorage.getItem('authToken') || '';
    return sessionToken;
  };

  get currentUserTeamIds() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const teamIds = _.get(currentUser,'team',[]).map(t=>{
      const teamId = t.id;
      const isEncoded = COMMON_HELPERS.checkIfEncoded(teamId);
      return isEncoded ? atob(teamId).split(':')[1] : teamId;
    });
    return teamIds;
  };

  async request(url, params = {}, requestOptions = {}) {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'x-session-token': this.sessionToken
      },
      body: JSON.stringify(params),
      ...requestOptions
    };

    if(!url)
      return console.warn('warn CHS request: url can\t be empty');

    let res;
    try {
      res = await fetch(url, fetchOptions);
      const data = await res.json();
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && (data.error || data.message)) || res.status;
        return Promise.reject(error);
      }
      return data;
    } catch (error) {
      console.error(`error CHS request with ${url}: `, error);
    }
  }
};

let CHSInstance = global.CHS;

if(!CHSInstance) {
  CHSInstance = new CHS();
  global.CHS = CHSInstance;
}

export default CHSInstance;