import Base from './Base'
import {_} from 'ihcomponent'
import I18N from 'modulesAll/I18N'
import { formatSingleAssSurScheduleArray, formatSingleAssSurAlert } from 'libModule/helpers/component-helpers'
import H from './helper'

export default class extends Base{

  format(type, param=false){
    switch(type){
      case 'duration':
        return this._getDuration()
      case 'devices':
        return this._getDevices()
      case 'thresholdComponentProps':
        return this._getThresholdComponentProps()
      case 'task_assessment':
        return this._getOtherTaskType('ASSESSMENT')
      case 'task_survey':
        return this._getOtherTaskType('SURVEY')
      default:
    }


    return super.format(type, param)
  }

  _getDuration(){
    const {duration} = this.variables
    if(!duration) return ''
    return `${duration.length} ${duration.calendar.toLowerCase()}(s)`
  }


  _getDevices(){
    const {devices} = this.variables
    if(!devices) return ''
    return _.map(devices, (one)=>I18N.get(`Devices.${one.type}.${one.model}`)).join(', ')
  }

  _getThresholdComponentProps(){
    const {tasks} = this.variables

    return H.getProgramTaskThresholdComponentProps(tasks)
  }

  _getOtherTaskType(type){
    const result = []
    const {tasks} = this.variables
    //
    // const REPEAT_MAP = {
    //   WEEKLY : 'Once every week',
    //   DAILY : 'Once every day'
    // }

    _.each(tasks, (item)=>{
      if(type === item.type){
        let name
        if(item.type === 'ASSESSMENT'){
          name = item.assessment.name || item.assessment._id
        }
        else if(item.type === 'SURVEY'){
          name = item.assessment.name
        }
        result.push({
          name,
          schedule : formatSingleAssSurScheduleArray(item),
          alert : formatSingleAssSurAlert(item)
        })
      }
    })

    return result
  }
}
