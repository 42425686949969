import React from 'react';
import { Form, Icon, Row, Col, Button } from 'antd';
import Client from 'libModule/gqlClient';
import editUserGQL from 'graphqlModule/mutation/editUser';
import getVisit from 'graphqlModule/singleVisit';
import '../style/lifestyle.styles.scss';
import UserClass from 'modulesAll/graphql/class/User';
import { itemList, radioGroups, statusItems, extraNote, getMutateVariableLifeStyle } from '../constant/lifestyleConstant';
import { GQLHelper } from 'libModule/utils';
import renderOverlayDiv from '../helpers/overLayButton';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { renderDraftSavedText } from '../../visitWorkList/helpers';

const { Item } = Form;

// Component starts here
let LifestyleRoutineComponent = class extends React.Component{
    static displayName = 'lifestyleRoutineComponent';
    static title = 'Lifestyle';
    constructor(props) {
      super(props);
      const programCategories = _.get(props, 'user.profile.programCategories', []);
      let enrollMNT = false;
      _.forEach(programCategories, ({name, enrolled}) => {
        if(name === 'MNT' && enrolled === true){
          enrollMNT = true;
        }
      })
      const { isWorkList } = props;
      this.state = {
          hasNote: _.get(this.props, 'user.profile.lifestyleNote', null),
          // in worklist, only determined by visit type,
          // in patient profile, determined by enrolled program type
          isMNT: isWorkList ? _.isEqual(_.get(this.props, 'visit.type'), 'MNT'): enrollMNT,
      }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      if(prevProps.disabled === false && this.props.disabled === true){ // saved changes
        this.setState({hasNote: _.get(this.props, 'user.profile.lifestyleNote', null)})
      }
    }

    handleOnSave = () => {
      const parsedValue = getMutateVariableLifeStyle(this.props);
      Client.mutate({
        mutation: editUserGQL,
        variables: parsedValue
      }).then(() => this.props.refetch())
      .then(()=>this.props.afterSaveDraftSucc())
      .catch((error)=>{
        const {openErrorModal} = this.props;
        if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
      })
    }

    handleSubmit = () =>{
      const { afterSubmitSucc, openErrorModal, form, title, submitButtonText} = this.props
      let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
      Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
      form.validateFieldsAndScroll((errors)=>{
        if(!errors){
          const parsedValue = getMutateVariableLifeStyle(this.props);
          Client.mutate({
            mutation: editUserGQL,
            variables: parsedValue
          }).then((res)=>{
            afterSubmitSucc();
          }).catch((error)=>{
            if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
          })
        }
      })
    }

    renderStatusItems = (group, status) => {
      const { user, form, disabled} = this.props;
      const items = statusItems[status][group];
      return (
        _.map(items, (item) => {
          return (
          <Col key={`col-${item.key}`} id={`col-${item.key}`} span={item.span} >
            <Item key={item.key} label={item.label} wrapperCol={item.wrapperCol} labelCol={item.labelCol}>
              {form.getFieldDecorator(item.key,
                { rules: typeof item.rules === 'function' ? item.rules(form, this) : item.rules,
                  initialValue: _.get(user, item.path, item.initialValue),
                  valuePropName: item.valuePropName || 'value'}
                )(item.inputContent(disabled))}
            </Item>
          </Col>
          )
        })
      )
    }

    renderHistory = (group, status) => {
      const { user, form, disabled} = this.props;
      let item = radioGroups[group];
      // if status is null, show only radio group
      // if status is true, show trueItems, else show falseItems
      return (
        <Row className={`lifestyle-row-${group}`} id={`lifestyle-row-${group}`} >
          <Col className={`col-${item.key}`} id={`col-${item.key}`} span={item.span} >
            <Item key={item.key} label={item.label} wrapperCol={item.wrapperCol} labelCol={item.labelCol}>
              {form.getFieldDecorator(item.key,
                { rules: typeof item.rules === 'function' ? item.rules(form, this) : item.rules,
                  initialValue: _.get(user, item.path, item.initialValue),
                  valuePropName: item.valuePropName || 'value'}
              )(item.inputContent(disabled))}
            </Item>
          </Col>
          {
            typeof status === 'boolean'? this.renderStatusItems(group, status) :''
          }
        </Row>
      )
    }

    renderBodyInfo() {
      const { user } = this.props;
      this.uc = new UserClass(user);
      const bodyInfo = this.uc.format('bodyInfo');
      let showInfo;
      if (bodyInfo.length > 0) {
          showInfo = (
            <div className= 'lifestyle-bodyInfo' id='lifestyle-bodyInfo'>
                {
                    _.map(bodyInfo, (info, index) => {
                    return <span className="v-tag" style={{marginRight: 10}} key={index} id={`lifestyle-bodyInfo-${index}`}>{info}</span>
                    })
                }
            </div>
          )
      } else {
        showInfo = (<div className='lifestyle-bodyInfo'>Body info not on file</div>)
      }

      return(
        <div className='fix-header' style={{ marginLeft: 8 }}>
          <div className='lifestyle-worklist-header' style={{fontSize: '16px', fontWeight: 'bold', color: '#000000', lineHeight: '19px'}}>
            <div className='fixHeaderTitle'>
              Lifestyle
              {renderDraftSavedText()}
            </div>
          </div>
          {showInfo}
        </div>
      )
    }

    setNotes = (e) => {
      this.setState({hasNote: e.target.value});
      this.props.form.setFieldsValue({'lifestyleNote': e.target.value});
    }

    renderForm = (itemList) =>{
      const { user, form, disabled} = this.props;
      const { isMNT } = this.state;
      return _.map(itemList, (items, index) => {
        return (
          <Row key={index} className={`lifestyle-row-${index}`} id={`lifestyle-row-${index}`}>
            {
              _.map(items, ({span, key, label, wrapperCol, labelCol, rules, initialValue, path, inputContent, valuePropName, placeHolder, MNTOnly}, i) => {
                let value = _.get(user, path);
                if(!value && value !== 0) value = initialValue;

                return (
                  <Col key={i} className={`lifestyle-col-${i}`} id ={`lifestyle-col-${i}`} span={span} >
                    { (!isMNT && MNTOnly) ? null : placeHolder ? inputContent(this) : <Item key={key} label={label} wrapperCol={wrapperCol} labelCol={labelCol}>
                      {form.getFieldDecorator(key,
                        {rules: typeof rules === 'function' ? rules(form, this) : rules,
                         initialValue: value,
                         valuePropName:valuePropName || 'value'}
                        )(inputContent(disabled, this))}
                    </Item>
                    }
                  </Col>
                  )
              })
            }
          </Row>
        )
      })
    }

    render(){
      const { hasNote } = this.state;
      const { isWorkList, disabled, form, isEditMode, user, enrolledProgramId } = this.props;
      const isDrinking = typeof form.getFieldValue('drinkingHistory.value') === 'boolean' ?  form.getFieldValue('drinkingHistory.value') :  _.get(user, 'profile.drinkingHistory.value');
      const isSmoking = typeof form.getFieldValue('smokingHistory.value') === 'boolean' ? form.getFieldValue('smokingHistory.value') :  _.get(user, 'profile.smokingHistory.value');
      return (
        <div>
          {!enrolledProgramId && isWorkList && renderOverlayDiv(this.props)}
          <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.1 : 1 }}>
              {isWorkList ? this.renderBodyInfo() :''}
            <Form id='lifestyleRoutine' className='lifestyleRoutine scrollable-content' colon={false}>
              { this.renderForm(itemList) }
              { this.renderHistory('drinking', isDrinking ) }
              { this.renderHistory('smoking', isSmoking) }
              {hasNote? this.renderForm(extraNote) : <Button type='link' onClick={()=> this.setState({hasNote: true})} disabled={disabled} className='add-click'><Icon type='plus'/>Add Note</Button>}
            </Form>
            {
                isWorkList ?
                (<Row className='fix-footer'>
                {isEditMode ? (
                  <Button
                    onClick={this.handleOnSave}
                    className='saveDraftBtn'
                  >
                    Save Draft
                  </Button>
                ) : ''}
                <Button
                  onClick={this.handleSubmit}
                  type={'primary'}
                >
                  {this.props.submitButtonText || 'Complete'}
                </Button>
              </Row>) : ''
              }
            </div>
          </div>
      )
    }
}

LifestyleRoutineComponent = Form.create({
  onValuesChange: (props) => {
    const { onTouch, setChildProps} = props;
    if(typeof onTouch === 'function'){
      onTouch();
      const refetchQueries = [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}];
      setChildProps(props, getMutateVariableLifeStyle, editUserGQL, refetchQueries, LifestyleRoutineComponent.title);
    }
  },
  onFieldsChange: (props) => {
    const { onChange } = props;
    // if(typeof onTouch === 'function'){
    //   onTouch();
    //   const refetchQueries = [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}];
    //   setChildProps(props, getMutateVariableLifeStyle, editUserGQL, refetchQueries, LifestyleRoutineComponent.title);
    // }
    if(typeof onChange ==='function'){
      let editUserVar = getMutateVariableLifeStyle(props);
      let shouldEditUser = true;
      onChange({
        shouldEditUser,
        editUserVar,
        props
      });
    }

  }
})(LifestyleRoutineComponent);
export default LifestyleRoutineComponent;
