import React from 'react';

import { getTimeWithFormat } from '../../helper/signOffHelper';

const SignOffSubmittedNote = (props) => {
  const { comment, signOffDate } = props;

  return signOffDate ? (
    <div className='signOffSubmittedComment'>
      <div className='title'>
        <div>Mentor RD's comment</div>
        <div>{getTimeWithFormat(signOffDate)}</div>
      </div>
      <div className='comment'>
        {comment}
      </div>
    </div>
  ) : null;
};

export default SignOffSubmittedNote;