import update from 'immutability-helper'

export const userEmail = {
  user: (prev, { mutationResult }) => {
    const newEmail = mutationResult.data.changeUserEmail.emails
    return update(prev, {
      user: {
        emails: {
          $set: newEmail,
        }
      }
    })
  },
}
