import React, { useState, useEffect } from 'react';
import { Button, Spin } from 'antd';
import { compose } from 'react-apollo';

import UnderlinedTooltip from '../../patientList/main/components/UnderlinedTooltip';

import { withSignOffSummary } from '../API';

import I18N from '../../I18N';
import { getRoleAndRoleName, getAssociatedUser } from '../helper/signOffHelper';
import { handlePatientChangeForSignOff } from '../helper';
import { SIGN_OFF_ROLES } from '../../utils/constants/role';
import '../style/signOffSummary.scss';

const SignOffSummaryContainer = props => {
  const { role, roleName } = getRoleAndRoleName();
  const [isValidRole] = useState(SIGN_OFF_ROLES.indexOf(role) > -1);
  if(!isValidRole)
    return null;

  const { loadingSignOffSummary, mentor, mentee, patientCount, totalCaseCount } = props;
  const associatedUser = getAssociatedUser(roleName, mentor, mentee);

  useEffect(() => {
    if(!loadingSignOffSummary) {
      window.signOffCursor.resetCursor(); // start over in P1
    }
  }, [loadingSignOffSummary]);

  const onClickStart = async () => {
    const nextPatient = await window.signOffCursor.getNextPatient();
    handlePatientChangeForSignOff(nextPatient);
  };

  if(loadingSignOffSummary) 
    return <Spin />;

  if(!_.get(associatedUser, 'id'))
    return null;

  const summaryCount = patientCount || totalCaseCount;

  return (
    <div id='signOffSummaryContainer'>
      <div className='summaryPanel'>
        <UnderlinedTooltip
          tooltipMsg={I18N.get(`signOff.summary.tipText.${roleName}`)}
          spanClassName='underlinedTooltipText'
        >
          {I18N.get(`signOff.summary.title.${roleName}`)}
        </UnderlinedTooltip>
        <div className='associatedUsers'>
          {I18N.get(`signOff.summary.userTitle.${roleName}`)}
          <span key={_.get(associatedUser, 'id')} style={{ marginLeft: 6 }}>
            { _.get(associatedUser, 'profile.fullName') }
          </span>
        </div>
        <div className='total'>
          <span className='number'>{summaryCount || 0}</span>
          {` patient${summaryCount > 1 ? 's' : ''}`}
        </div>
      </div>
      {
        summaryCount ?
        <div className='buttonPanel'>
          <Button 
            type='primary'
            disabled={loadingSignOffSummary}
            onClick={onClickStart}
          >
            {I18N.get(`signOff.summary.btnText.${roleName}`)}
          </Button>
        </div> : null
      }
    </div>
  );
}

export default compose(
  withSignOffSummary
)(SignOffSummaryContainer);