import React, { Component } from 'react';
import '../css/index.scss';
import CallComponent from '../components/callComponent';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import callActions from '../actions';

const wrapper = class CallContainer extends Component {
    constructor() {
        super();
        this.state = {
            isMinCall: false
        }
    }

    /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw
     *      duration   min
     *      date
     *      showChat
     * }
     *  //7 is close the panel; 2 is show close icon
     */
    render() {
        const { showChat, status } = this.props.callArgs;
        let isShowCall = this.props.ccpStatus === 'open';
        const isMinCall = this.state.isMinCall;

        return <div className={`callBox ${isMinCall ? 'minCall' : ''} ${isShowCall?'':'closeCall'} ${showChat ? 'showChat' : 'closeChat'}`}>
            <CallComponent 
              {...this.props.callArgs}
              close={() => {
                this.props.setCcpStatus('close')
                this.props.close() 
              }} 
              maxCall={this.maxCall} 
              minCall={this.minCall} 
              status={status}
            />
        </div>
    }

    minCall = () => {
        this.setState({ isMinCall: true })
    }

    maxCall = () => {
        this.setState({ isMinCall: false })
    }


}
const mapPropsToState = (state) => {
    return {
        inboundAttributes: state.AWSConnect.inboundAttributes,
        contactStatus: state.AWSConnect.contact,
        ccpStatus: state.AWSConnect.ccpStatus,
    }
}
const mapDispatch = (dispatch) => {
    return {        
        setInstitution: inst => dispatch(setInstitution(inst)),
        setCcpStatus: flag => dispatch(callActions.ccpStatus(flag)),
    }
}

export default compose(connect(mapPropsToState, mapDispatch))(wrapper);

// export default CallContainer
