import NutritionIntakeComponent from '../container/NutritionIntake';
import NutritionInterpretationComponent from '../container/NutritionInterpretation';
import InterventionEducationComponent from '../container/InterventionEducation';
import ChronicHistoryComponent from '../component/ChronicHistoryComponent';
import LifestyleRoutineComponent from '../component/lifestyleRoutineComponent';
import ClinicGoalContainer from '../container/ClinicGoalContainer';
import VitalMonitoringContainer from '../container/VitalMonitoringContainer';
import BehavioralGoalContainer from '../container/BehavioralGoalContainer';
import MedicationContainer from '../container/MedicationContainer';
import BehavioralChangeContainer from '../container/BehavioralChangeContainer';
import SocialSupportComponent from '../component/SocialSupportComponent';

import helpers from '../helpers';
const excludedIfNotMNT = ['NutritionInterpretationComponent'];

const collapseComponents = props => {
  let components = {
    ChronicHistoryComponent,
    MedicationContainer,
    SocialSupportComponent,
    LifestyleRoutineComponent,
    NutritionIntakeComponent,
    NutritionInterpretationComponent
  };

  if(!helpers.isMNTEnrolled(props)) {
    components = _.omit(components, excludedIfNotMNT);
  }

  return components;    
};

const componentList = (props)=> {
   let list =  {
      ClinicGoalContainer,
      VitalMonitoringContainer,
      BehavioralChangeContainer,
      BehavioralGoalContainer,
      InterventionEducationComponent
    }

    if(!helpers.isMNTEnrolled(props)) {
      list = _.omit(list,excludedIfNotMNT);
    }

    return list;
}

export { collapseComponents,componentList };
