import React, { Component } from 'react'
import { Button,  Modal, message } from 'antd';
import TeamTableComponent from '../components/TeamTableComponent'
import API from '../../API';
const unresolvedWarning = 'This member has unfinished tasks in this clinic.';
const oneClinicWarning = 'A member must be in a clinic';

export default class EditTeamContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAddVisible: false,
            isTableVisible: true,
            isPopup: true,
            isModalVisible: false
        }
    }

    openRemoveModal = (doc) => {
        const variables = {
            filters: {
                assignedTo: doc.key,
                status: 'OPEN'
        }};
        API.checkTaskList(variables).then(res => {
            const tasks = res.data.taskAssignmentList.data;
            this.setState({
                isModalVisible: true,
                toBeRemoved: doc,
                hasUnreslovedTasks: !_.isEmpty(tasks)
            });
        })
    }

    closeRemoveModal = () => {
        this.setState({isModalVisible: false})
    }

    handleVisibleChange = isPopup => {
        this.setState({ isPopup });
    };

    render() {
        return (
            <div>
                {this.state.isAddVisible ? this.renderNoMember(this.props.curTab) : ""}
                {this.state.isTableVisible ? this.renderTeamTable(this.props.curTab) : ""}
                {this.renderRemoveModal()}
                {/* {this.state.isPopup ? this.renderPopup(this.props.curTab) : ""} */}
            </div>
        )
    }

    success = () => {
        const{curTab, updateNeedRefetchTeamList, refetchList, refetch} = this.props;
        const {toBeRemoved} = this.state;
        const variables = {
            id: toBeRemoved._get('id'),
            roles: [toBeRemoved._get('role.refId')]
        };

        API.removeEmployee(variables).then(r => {
            updateNeedRefetchTeamList(true);
            refetchList();
            // adding this function to update the header
            refetch();
            switch(curTab){
                case 'CLINIC_MEMBER':
                    message.success('Clinic member removed!');
                    break;
                case 'IHEALTH_MEMBER':
                    message.success('iHealth member removed!');
                    break;
                case 'CARE_TEAM':
                    message.success('Removed from care team');
                    break;
            }
        }).catch(e => {
            if(e.message){
                Modal.error({content: e.message});
            }
            console.log(e);
        });
        this.setState({isModalVisible: false})
    };

    renderRemoveModal = () => {
        let fullName = '';
        const { closeRemoveModal, state} = this;
        const {toBeRemoved, isModalVisible, hasUnreslovedTasks} = state;
        if(toBeRemoved) {
            fullName = toBeRemoved._get('profile.fullName');
        }

        return(
            <Modal  className='remove-ihealth-member-modal'
                    style={{ width: 484 }}
                    visible={isModalVisible}
                    footer={null}
                    onCancel={closeRemoveModal}
                    maskClosable={false}
            >
                    <div style={{margin: '50px'}}>
                    <div className='remove-modal-header' style={{fontSize: '18px', fontWeight: 'bold'}}>Are you sure you want to remove {fullName} from this clinic?</div>
                            <div className='remove-modal-content' style={{fontSize: '14px', marginTop: '9px'}}>
                                This will remove this user from all care teams in this clinic.
                                {hasUnreslovedTasks && unresolvedWarning}
                            </div>
                            <div className='remove-modal-footer' style={{display: 'flex', justifyContent: 'center', marginTop: '33px'}}>
                                <Button onClick={this.success} type='primary'>Yes, Remove</Button>
                                <Button onClick={this.closeRemoveModal} style={{marginLeft: 20}}>Cancel</Button>
                            </div>
                    </div>
            </Modal>
        )
    }

    resendInvitationLink = (user) => {
        API.resendOnBoardEmailLink({
            id: user.key,
        }).then(() => {
            message.success('Invitation Link Sent!')
        }).catch(e => {
            console.error(e);
            message.error(e.message);
        })
    }

    renderNoMember(curTab) {
        let text = '';
        let btnText = '';
        switch(curTab) {
            case "CLINIC_MEMBER":
                text = 'Clinic members refer to the providers, medical assistant or other staff that works in this clinic'
                btnText = 'Add clinic members'
                break;
            case "IHEALTH_MEMBER":
                text = 'iHealth members refer to the nurse, clinic medical assistant in iHealth team'
                btnText = 'Add iHealth members'
                break;
            case "CARE_TEAM":
                text = 'Choose clinic members and iHealth members to create a care team'
                btnText = 'Create a unified care team'
                break;
        }
        return (
            <div>
                <span>{text}</span>
                <Button type="primary">{btnText}</Button>
            </div>
        )
    }


    renderTeamTable(curTab) {
        return (
            <div style={{marginTop:40}}>
                <TeamTableComponent curTab={curTab}
                                    openRemoveModal={this.openRemoveModal}
                                    handleVisibleChange={this.handleVisibleChange}
                                    resendInvitationLink={this.resendInvitationLink}
                                    {...this.props}/>
            </div>
        )
    }
}
