import gql from 'graphql-tag';

export const getPatientComplexity = gql`
  query getPatientComplexity($memberId: EID!) {
    getPatientComplexity(memberId: $memberId) {
      memberId
      level
      source
      updatedBy {
        id
        profile {
          firstName
          lastName
          fullName
        }
      }
      updatedAt
    }
  }
`;

export const getPatientComplexityByCodes = gql`
  query getPatientComplexityByCodes(
    $billableCodes: [String],
    $nonBillableCodes: [String]
  ) {
    getPatientComplexityByCodes(billableCodes: $billableCodes, nonBillableCodes: $nonBillableCodes)
  }
`;

export const getPatientComplexityByConditions = gql`
  query getPatientComplexityByConditions($conditions: [String]){
    getPatientComplexityByConditions(conditions: $conditions)
  }
`;