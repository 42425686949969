import gql from 'graphql-tag';

const OnCalleeOptionSchema = `
  memberId
  fullName
`;

export const getOnCalleeOptionsQuery = gql`
  query getOnCalleeOptions {
    getOnCalleeOptions {
      ${OnCalleeOptionSchema}
    }
  }
`;

const AssigneeOptionInfoSchema = `
  memberId
  fullName
  orgAssignedPatientCount
  providerLanguage {
    code
    description
  }
  occupancyRate
  selectedRole
`;

export const getAssigneeOptionsQuery = gql`
  query getAssigneeOptions {
    getAssigneeOptions {
      assignedToRD {
        ${AssigneeOptionInfoSchema}
      }
      assignedToCA {
        ${AssigneeOptionInfoSchema}
      }
    }
  }
`;