import { connect } from 'react-redux';

/**
 * Created by zizhangai on 1/2/17.
 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo'
import { addNewNote, clearState } from '../actions/InterventionsActions'

//queries
import { getResultNotes } from 'modulesAll/graphql/result'
//components
import { IHLoading } from 'ihcomponent'
import InputForInvalidation from '../containers/InputForNoteInvalidationContainer'
import Note from '../components/NoteComponent'
import Client from 'libModule/gqlClient';
import editProviderNote from 'graphqlModule/mutation/editProviderNote.js';
import providerNoteList from 'graphqlModule/providerNoteList'

class InterventionNotes extends Component {
  static displayName = 'InterventionNotesContainer'

  // componentWillUnmount() {
  //   this.props.clearState()
  // }

  // componentDidMount() {
  //   this.props.refetch({ id: this.props.id })
  // }
  
  render() {
    const { loading, result, deleteProviderNote,closeModal,editProviderNote,refetch } = this.props;
    const docId = _.get(this.props,'id');
    if (loading) {
      return <IHLoading />
    }
    const { notes } = result || {}
    if (!notes || notes.data.length === 0) {
      return (<div>No notes yet</div>)
    }

    return (
      <div>
        {
          notes.data.map((item, i) => {
            const id = item.id
            return (
              <Note key={`provider-notes-${i}`}
                    note={item}
                    row={this.props.row}
                    onClick={() => this.props.addNewNote(id)}
                    deleteProviderNote = {deleteProviderNote}
                    editProviderNote={editProviderNote}
                    closeModal={closeModal}
                    refetch={refetch}
              >

                {(this.props.stateNotes[id])?<InputForInvalidation id={id} note={item} row={this.props.row}/>: null}
              </Note>
            )
          })
        }
      </div>
    )
  }
}

const wrapper = (dispatch,props)=>{
    return {
        deleteProviderNote: (id, category,docId) => {
            return Client.mutate({
                mutation: editProviderNote,
                variables: {
                    id,
                    category,
                    deleted: true
                },
                refetchQueries:[{
                    query:getResultNotes,
                    variables:{
                      id:docId
                    },
                    notifyOnNetworkStatusChange: true,
                    fetchPolicy:'network-only'
                }]
            })
        },
        editProviderNote:(id,content,docId,)=>{
            return Client.mutate({
                mutation: editProviderNote,
                variables: {
                    id,
                    content
                },
                refetchQueries:[{
                    query:getResultNotes,
                    variables:{
                        id:docId
                    },
                    notifyOnNetworkStatusChange: true,
                    fetchPolicy:'network-only'
                }]
            })
        }
    }
}
// const withData = graphql(getResultNotes, {
//   options: (ownProps) => {
//     return {
//       variables: {
//         "id": ownProps.id
//       },
//       notifyOnNetworkStatusChange: true,
//       fetchPolicy: 'network-only' // this is necessary as we are updating exisiting data
//     }
//   },
//   props: ({ ownProps, data }) => {
//     const { loading, result, refetch } = data
//     return {
//       result,
//       loading,
//       refetch
//     }
//   }
// })

const mapState = ({ Interventions }) => {
  const { notes } = Interventions
  return { stateNotes: notes || {} }
}
export default compose(
  // withData,
  connect(mapState, { addNewNote, clearState }),
  connect(null,wrapper)
)(InterventionNotes)
