import type from '../constants'

export default {
  selectCurrentProgram: (currentProgram)=>{
    return {
      type : type.SET_CURRENT_PROGRAM,
      currentProgram,
    }
  },
  reset: () => {
    return {
      type: type.RESET,
    }
  }
}
