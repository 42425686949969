import gql from 'graphql-tag'
import {User, UserNProfile} from './schema/User'
import {MiniEnrolledProgram} from './schema/MiniEnrolledProgram'
import {PaginationInfo, Identification, Email, Phone, Address} from './schema/common'
import { UserRemoteEnrollmentSchema } from './schema/RemoteEnrollment'
import { UserPreferenceSchema } from './schema/UserPreference';

export const taggedPatients = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters
  ){
    patientList(
      filters:$filters,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data{
        user{
          ${User}
        }
        enrolledPrograms{
          ${MiniEnrolledProgram}
        }

      }
      pageInfo{
        ${PaginationInfo}
      }

    }
  }
`;

const UserData = `
  data{
    user{
      ${User}
      monthlyMeasurementDays
      consentInfo{
        consent
      }
      patientMntProgram{
            id
            status
      }
      remoteEnrollment{
        ${UserRemoteEnrollmentSchema}
      }
    }
    enrolledPrograms{
      ${MiniEnrolledProgram}
    }
  }
`;

export default gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      pageInfo{
        ${PaginationInfo}
      }
      ${UserData}
    }
  }
`;

const UserNData = `
data{
  user{
    id
    isTestUser
    createdAt
    updatedAt
    registeredAt
    username
    email {
      ${Email}
    }
    address {
      ${Address}
    }
    phone {
      ${Phone}
    }
    identification {
      ${Identification}
    }
    profile {
      ${UserNProfile}
    }
    userPreference {
      ${UserPreferenceSchema}
    }
    starred
    starredReason
    hasFlag
    flagDetails {
      reason
      createdAt
    }
    hasPassword
    isDefaultPassword
    deleted
    remoteEnrollment {
      ${UserRemoteEnrollmentSchema}
    }
    enrollmentDate
    monthlyTimeSpent {
      totalTime
      monthOfYear
      updatedAt
    }
    taskAssignmentCount
    assignees{
      assignedToRD{
          id
          profile {
            firstName,
            lastName
          }
      }
      assignedToCA{
          id
          profile {
            firstName,
            lastName
          }
      }
      assignedToCANotNecessary
      assignedToRDNotNecessary
    }
    monthlyMeasurementDays
    consentInfo{
      consent
    }
    patientMntProgram{
      id
      status
    }
  }
  enrolledPrograms{
    id
    name
    description
    startDate
    endDate
    status
    tasks{
      type
    }
    taskAssignmentCount
    dischargedDetail {
      dischargedAt
    }
  }
}
`;

export const patientListTableQuery = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      pageInfo{
        ${PaginationInfo}
      }
      ${UserNData}
    }
  }
`;




export const userListWithoutTeam = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data{
        user {
          id
          remoteEnrollment{
            ${UserRemoteEnrollmentSchema}
          }
          phone{
              type
              number
              canUseForLogin
              countryCode
              private
          }
          assignees{
             assignedToRD{
              id
              profile{
                fullName
              }
            }
            assignedToCA{
              id
              profile{
                fullName
              }
            }
            assignedToCANotNecessary
            assignedToRDNotNecessary
          }
          profile{
            fullName
            gender
            
            ...on MemberProfile{
              birthday
              isSelfEnrolled
              lastMeasuredAt
              healthConditions
              programCategories {
                name
                enrolled
              }
              doctor{
                id
                profile{
                  firstName
                  lastName
                  fullName
                }
              }
              language {
                code
                description
              }
              appLanguage {
                code
                description
              }
            }
          }
        }
        enrolledPrograms {
          status
          startDate
          tasks {
            type
          }
        }
      }
    }
  }
`;

export const patientListOnlyWithPageInfo = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters
  ){
    patientList(
      filters:$filters,
      page:$page,
      count:$count,
      sort:$sort
    ){
      pageInfo{
        ${PaginationInfo}
      }

    }
  }
`;
