import { React, IHButton } from 'ihcomponent';
import I18N from 'modulesAll/I18N';

// component
import AssessmentFilledContainer from '../containers/AssessmentFilledContainer';

export default (props) => {
  const { onSave, onCancel } = props;

  const confirmSubmit = (isSave) => {
    const { closeModal, openModal, assessmentFilled } = props;
    const p1 = {
      type : 'primary',
      label : 'Yes',
      size : 'large',
      onClick : () => {
        onSave(isSave)
        closeModal();
      }
    };
    const p2 = {
      label : 'No, continue editing',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : () => {
        closeModal();
      }
    };
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    );

    const type = assessmentFilled.type === 'ASSESSMENT' ? I18N.get('keywords.Assessment') : 'Survey'
    const param = {
      size : 'sm',
      title : `Submit ${type}?`,
      content : `Are you sure you want to submit your ${type}?`,
      footer
    };
    openModal(param.content, param);
  }

  return (
    <div className="patient-take-assessment">
      <button className="link-like back" onClick={onCancel}>&lt; Back</button>
      <div className="paper">
        <AssessmentFilledContainer {...props} />

        <div className="btn-wrapper">
          <div className="left">
            <IHButton label="Save Draft" onClick={() => onSave(false)} bsStyle="primary" />
          </div>
          <div className="right">
            <IHButton label="Cancel" onClick={onCancel} />
            <IHButton label="Submit" onClick={() => confirmSubmit(true)} bsStyle="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};
