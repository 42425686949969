import { React, BaseComponent, IHButton } from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import { goPath } from 'libModule/utils'
import { Upload, Button, Icon, Tooltip } from 'antd'

const EditPhoto = class extends BaseComponent {
    static displayName = 'provider/ProviderEdit/components/EditPhoto'

    render() {
        this.user = this.props.user;
        this.userObject = new UserClass(this.user);
        const { closeModal, beforeUploadPhoto, saveImage } = this.props;
        const b1 = {
            label: 'Save',
            size: 'small',
            type: 'primary',
            onClick: () => {
                saveImage();
            }
        };
        const upbntprops = {
            name: 'file',
            showUploadList: false,
            onChange({ file }) {
                beforeUploadPhoto(file);
                return false;
            }
        };
        const previewImage = this.props.previewImage;
        const img = previewImage ? previewImage : this.userObject.format('avator');
        return (

            <div>
                <div>
                    <img src={img} style={{ width: "120px", height: "120px" }} />
                </div>
                <div className={'btn-upload'}>
                    <Upload {...upbntprops}>
                        <Button>
                            <Icon type="upload" /> Select a Photo
                        </Button>
                    </Upload>
                </div>
                <div className={'btn-upload'}>
                    <IHButton  {...b1} />
                </div>
            </div>
        )
    }
}

export default EditPhoto
