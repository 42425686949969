import gql from 'graphql-tag';


const getCareTeamMemberAvailability = gql` query getCareTeamMemberAvailability ($memberIds:[EID!]!,$from:Date!,$to:Date!, $excludeVisitId: EID){
  getCareTeamMemberAvailability(memberIds:$memberIds,from:$from,to:$to,excludeVisitId:$excludeVisitId){
    id
    overlapVisits{
        appointmentAt
        appointmentTo
    }
  }
    }`;

const editConditionsListQuery = gql`mutation editConditionsList($id: EID!, $conditionsList: InputConditionsListType!) {
  editConditionsList(id: $id, conditionsList: $conditionsList){
    profile{
      ...on MemberProfile {
        conditionsList {
          HLD
          HTN
          DM
          CKD
          Obesity
          PreDM
          COPD
          ESRD_Dialysis
        }
      }
    }
  }
}`

export {
    getCareTeamMemberAvailability,
    editConditionsListQuery
}
