import gql from "graphql-tag";
import Client from 'libModule/gqlClient';
import {Modal} from "antd/lib/index";

const sendInvitationLink =  gql`mutation sendInvitationLink($memberId:EID!){
	sendInvitationLink(memberId:$memberId){
    notified
    message
  }
}`

const  sendInvitationLinkMutation = (variables, cb, showNotificationMessage = true) => Client.mutate({
    mutation:sendInvitationLink,
    variables
}) .then(res=>{
    const { data:{ sendInvitationLink:{notified } } } = res;
    const notifiedMsg = _.get(res,'data.sendInvitationLink.message');
    if(notified){
        if(cb) cb();
        if(showNotificationMessage) {
          Modal.success({
              title:'The link has been sent.',
              content:'Please assist patient login and schedule the first video appointment for the patient.',
              okText:'Got it'
          });
        }
      }else{
        if(showNotificationMessage) {
          Modal.error({
            content:notifiedMsg
          });
        }
    }
}).catch(error=>{
    Modal.error({
        content:error.message
    });
})
export default {
    sendInvitationLinkMutation
}
