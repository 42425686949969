import gql from 'graphql-tag';
import { UserMini } from './schema/User';
import { Phone, Email } from './schema/common';

export default gql`
  mutation createTeam($name:String!, $description:String,$forwardingPhone:InputPhone, $phone: InputPhone, $email: EmailAddress,$contactName:String,$primary:[EID], $visibleMembers:[EID], $organizationId: EID, $consentProviderName: String){
    createTeam(name:$name, description:$description, phone: $phone, email: $email, contactName: $contactName, primary:$primary, visibleMembers:$visibleMembers, forwardingPhone:$forwardingPhone, organizationId: $organizationId, consentProviderName: $consentProviderName){
      id
      createdAt
      updatedAt
      modifiedAt
      name
      description
      phone {
        ${Phone}
      }
      contactName
      email {
        ${Email}
      }
      healthCondition
     
      organization {
        id
        name
      }
    }
  }
`
