import gql from 'graphql-tag';

export default gql `
  mutation upsertUserRoles($id: EID!, $roles: [Ref]) {
    upsertUserRoles(
      id: $id,
      roles: $roles
    ) {
      id
      profile {
        firstName
        lastName
        fullName
        nickname
        fullNameWithNickname
      }
    }
  }
`;

export const upsertUserRolesReturnID = gql`
  mutation updateDefaultClinic($id: EID!, $defaultRole: Ref) {
    upsertUserRoles(
      id: $id,
      defaultRole: $defaultRole
    ) {
      id
    }
  }
`
