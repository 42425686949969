import types from 'ProgramNewModule/Common/constants/CommonConstants'

const initState = {
  step : 'programInfo'
}

export default (state=initState, action) => {
  switch(action.type) {
    case types.SET_TABS_STEP:
      return {
        ...state,
        step : action.step
      }
    case types.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }
    default:
      return state
  }
}
