import React, { useState, useCallback } from 'react';
import { Form, Select, Checkbox, Modal, Button, message } from 'antd';
import { compose, graphql } from 'react-apollo';
import I18N from 'modulesAll/I18N';

import { CLINIC_GOAL_ENUM, CONDITION_ENUM_MAP } from '../../careplanNew/component/helper/goalHelper';
import getClinicGoalTemplateQuery from 'graphqlModule/getClinicGoalTemplates';
import { upsertPatientReferralMutation } from '../API/mutation';
import { VITAL_TYPE_MAP, conditionToNameMap, conditionToCode } from '../constant';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import { getReferredPrograms } from '../helper';
import { getBillableIcdCodesValue } from '../helper/initialValueHelper';

import '../style/clinicGoal.scss';

const renderGoalFormItem = (props) => {
  const disabled = props.disabled;
  const clinicGoals = _.get(props, 'patientReferral.clinicGoals') || [];
  return props.goalTemplates.map(goal => {
    const { condition, goals: goalOptions } = goal;
    const conditionDisplayText = CONDITION_ENUM_MAP[condition];
    return (
      <Form.Item key={condition} label={conditionDisplayText} colon={false}>
        {
          props.form.getFieldDecorator(condition, {
            initialValue: _.get(_.find(clinicGoals, { conditionEnum: condition }), 'goalEnum') || _.first(goalOptions),
            rules: [{
              required: true,
              message: ' '
            }]
          })(
            <Select
              disabled={disabled}
              style={{ width: 714 }}
            >
              {
                goalOptions.map(goalOption => {
                  const displayText = CLINIC_GOAL_ENUM[goalOption];
                  return (
                    <Select.Option key={goalOption} value={goalOption}>
                      {displayText}
                    </Select.Option>
                  );
                })
              }
            </Select>
          )
        }
      </Form.Item>
    )
  });
};

const ClinicGoalComponent = props => {
  const { FormWrapper, form, user, onSubmitDone, goBack, patientReferral, disabled, goNextStep, formWrapperProps } = props;
  const { validateFieldsAndScroll } = form;
  const {
    recommendedVitals,
    conditionsToMonnitor
  } = patientReferral || {};

  const handleNext = useCallback(_.debounce(() => {
    if (disabled) {
      return goNextStep();
    }
    validateFieldsAndScroll(async (error, goals) => {
      if (error)
        return message.error(I18N.get('referAndEnroll.clinicGoal.errorWithNoGoal'));


      const clinicGoals = [];
      for (let conditionEnum of _.keys(goals)) {
        const goalEnum = goals[conditionEnum];
        clinicGoals.push({ conditionEnum, goalEnum });
      }

      const variables = {
        patientId: _.get(user, 'id'),
        clinicGoals,
      };

      await upsertPatientReferralMutation(variables);

      onSubmitDone();
    });
  }, 500));

  return (
    <FormWrapper
      header={
        <div className='refer-enroll-clinic-goal-header'>
          Great! Let set up clinical goals for the condition you just chose for patient.
          <div className='sub-header'>Please review and choose proper clinical goal for patient.</div>
        </div>
      }
      onSubmit={handleNext}
      buttons={
        <div className='refer-enroll-clinic-goal-buttons'>
          <Button
            type='primary'
            htmlType='submit'
            className='new-style'
            style={{ marginRight: 20 }}
          >
            Next
          </Button>
          <Button onClick={goBack}>Go back</Button>
        </div>}
      {...formWrapperProps}
    >
      <div id='refer-enroll-clinic-goal'>
        {renderGoalFormItem(props)}
      </div>
    </FormWrapper>
  );
};

const getClinicGoalTemplates = graphql(getClinicGoalTemplateQuery, {
  options: (ownProps) => {
    const { patientReferral } = ownProps;
    const diagnosisSource = _.get(patientReferral, 'diagnosisSource') || DIAGNOSIS_SOURCE.CONDITION;
    let codes = [];
    if (diagnosisSource === DIAGNOSIS_SOURCE.CONDITION) {
      const conditionsToMonnitor = _.get(patientReferral, 'conditionsToMonnitor');
      codes = _.map(conditionsToMonnitor, c => conditionToCode[c]).filter(c => !!c);
    } else {
      const billableIcdCodes = getBillableIcdCodesValue(patientReferral);
      codes = _.map(billableIcdCodes, 'code');
    }
    return {
      variables: {
        codes
      },
      fetchPolicy: 'network-only',
    };
  },
  props: ({ data }) => {
    const { loading, getClinicGoalTemplates, error } = data;

    if (error)
      console.error('referAndEnroll getClinicGoalTemplates', error);

    const goalTemplates = _.uniqBy(getClinicGoalTemplates || [], 'condition');
    return {
      loadingGoalTemplates: loading,
      goalTemplates
    };
  },
});

export default Form.create({
  onValuesChange: (props) => {
    props.setHasChange();
  }
})(
  compose(getClinicGoalTemplates)
    (ClinicGoalComponent)
);