import {Document, Image, Page, PDFViewer, Text, View} from "@react-pdf/renderer";
import React, {useEffect} from "react";
import moment from "moment";
import styles from '../style/styles';
export const MyDoc = (props) => {
	const renderImages = ()=>{
		const { insuranceList } = props;
		return _.map(insuranceList, ({fileInfo,createdAt},index) => {
			const link = fileInfo.link;
			const uploadedAt = createdAt && moment(createdAt).format('MM/DD/YYYY');
			return<View key={index+'view1'}>
					<Image src={link} key={index} style={{ marginBottom:20,height:500 }}/>
					<View key={index+'view2'}>
						<Text key={index+'text'}>UploadedAt:{uploadedAt}</Text>
					</View>
				</View>
		});
	}
	return <PDFViewer>
			<Document>
			<Page  style={styles.body}>
			{renderImages()}
			</Page>
		</Document>
	 </PDFViewer>

}