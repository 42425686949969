import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { branch, withProps } from 'recompose';
import { Table,Spin } from 'antd';
import { userListWithTeamChannelCount } from 'graphqlModule/userList';
import { processProviderData } from '../../helper/careteamHelpers';
import { USER_LIST_COUNT_MAX } from '../../../../lib/constants';
import InfiniteScroll from 'react-infinite-scroller';
import '../../style/teamTable.scss';
class MemberTable extends React.Component {

  fetchMore = async ()=>{
    const { fetchAndSetProviders,page,roleName,loadingProvider,organization } = this.props;
    if(fetchAndSetProviders.length && loadingProvider) return;
    await fetchAndSetProviders({ page,roleName,rootId:organization.id,sort:{ field:'ENROLLMENT_DATE',direction:'DESC' } });
  }

  renderInfinTable = ()=>{
    const {
      tableId = '',
      footer,
      pagination = false,
      columns = [],
      getRowSelection = () => null,
      scroll = {},
      dataSource,
      providerList,
      pageInfo,
      organization,
      shouldParseData,
      loadingFetchProviderList,
      ...restProps
    } = this.props;
    const { total }  = pageInfo || { };
    const hasMore = total>providerList.length;
    const tableColumns = typeof columns === 'function' ? columns(this.props) : columns;
    return <InfiniteScroll loadMore={this.fetchMore} getScrollParent={()=>document.querySelector('.infiniteTable div.ant-table-body')}
                           useWindow={false} hasMore={hasMore} threshold={40}>
            <Table
                    loading={loadingFetchProviderList}
                    className={'infiniteTable'}
                    columns={tableColumns}
                    dataSource={shouldParseData ? dataSource.map(p => processProviderData(organization.id, { user: p })) : dataSource }
                    rowSelection={getRowSelection(this.props)}
                    size='small'
                    id={'scrollTable'}
                    rowKey={record => record.id}
                    pagination={pagination}
                    scroll={scroll}
                    {
                      ...footer && { // prevent showing footer shadow when not necessary
                        id: tableId, // need tableId to control visibility of footer
                        footer
                      }
                    }
                    {...restProps}
              />
    </InfiniteScroll>
  }

  renderTable = () => {
    const {
      tableId = '',  
      footer,
      pagination = false,
      columns = [],
      getRowSelection = () => null,
      scroll = {},
      dataSource,
      providerList,
      loadingFetchProviderList,
      ...restProps
    } = this.props;
    const tableColumns = typeof columns === 'function' ? columns(this.props) : columns;
    return <Table
        loading={loadingFetchProviderList}
        columns={tableColumns}
        dataSource={dataSource}
        rowSelection={getRowSelection(this.props)}
        size='small'
        rowKey={record => record.id}
        pagination={pagination}
        scroll={scroll}
        {
          ...footer && { // prevent showing footer shadow when not necessary
            id: tableId, // need tableId to control visibility of footer
            footer
          }
        }
        {...restProps}
    />
  }

  render() {
    const { header,infiniteScroll } = this.props;
    return <div>
      {
        header &&
        <div style={{ width: '100%', marginBottom: 10, fontSize: 14 }}>{header}</div>  
      } 
      {
        infiniteScroll ? this.renderInfinTable(): this.renderTable()
      }   
    </div>
  }
};

MemberTable.displayName = 'MemberTable';

MemberTable.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  teamSelections: PropTypes.array,
  loadingFetchProviderList: PropTypes.bool,
  dataSource: PropTypes.array.isRequired,
  selectedRowKeys: PropTypes.array,
};

const fetchProviderList = graphql(userListWithTeamChannelCount, {
  options: ({ roles }) => {
    return {
      variables: {
        count: USER_LIST_COUNT_MAX,
        filters: { roles }
      },
      fetchPolicy: 'cache-first'  ,
    };
  },
  props: ({ ownProps, data }) => {
    const providerList = _.map(_.get(data, 'userList.data'), 
                      p => processProviderData(ownProps.organization.id, { user: p }));
    return {
      loadingFetchProviderList: data.loading,
      providerList,
      providerPageInfo: _.get(data, 'userList.pageInfo', { total: 0, lastPage: 0 })
    }
  }
});

// skip in apollo-client doesn't work properly
// using recompose-branch to prevent API request when showing view tables
export default compose(
  withProps(props => {
    let { 
      isForOrderAndVisibility, providerList = [], teamSelections, dataSource
    } = props;
    console.log(props);
    // selected / checked providers are on top of the list
    let sortedProvidersByChecked;
    // rowKey is provider.id => selectedRowKeys must be array of provider ids
    let selectedRowKeys = [];

    if(_.get(teamSelections, 'length')){ // no team selections in list view
      if(isForOrderAndVisibility) {
        selectedRowKeys = _.filter(teamSelections, p => !p.disabled);
        selectedRowKeys = _.map(selectedRowKeys, 'id');
        sortedProvidersByChecked = _.sortBy(teamSelections, p => ~~p.disabled);
      } else {
        for(let index in providerList) {
          const p = providerList[index];
          const isSelected = _.findIndex(teamSelections, { id: p.id }) > -1;
          if(isSelected) {
            selectedRowKeys.push(p.id);
          }
        }
      }
    }
    return {
      ..._.omit(props, 'providerList'),
      dataSource: sortedProvidersByChecked || dataSource || providerList,
      selectedRowKeys
    }
  })
)(MemberTable);
