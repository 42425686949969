import { React, IHPanel, IHButton, IHBaseForm, IHLoading } from 'ihcomponent';
import { usernameValidator, emailValidator } from '../../../lib/validator';
import { submittingStyle } from 'libModule/utils';
import { UNIFIED_CARE_DEFAULT_LOGO } from '../../../lib/constants';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.onExpand = this.onExpand.bind(this);
    this.validAndSubmit = this.validAndSubmit.bind(this);
  }

  validAndSubmit(e, { validateFieldsAndScroll }) {
    e.preventDefault();

    validateFieldsAndScroll((err, values) => {
      !err && this.props.submit(values);
    });
  }

  onExpand(evt) {
    this.setState({ expanded: true });
  }

  render() {
    const { back, isSubmitting } = this.props;
    const formProp = {
      onSubmit: this.validAndSubmit,
      itemList: [
        // {
        //   key: 'loginID',
        //   label: 'Login ID',
        //   type: 'text',
        //   rules: [
        //     { validator: (rule, value, callback) => callback(usernameValidator(value)) },
        //   ],
        // },
        {
          key: 'email',
          label: 'Email',
          type: 'text',
          rules: [
            { validator: (rule, value, callback) => callback(emailValidator(value)) },
          ],
        },
      ],
    };

    const bodyElem = (
      <div className='reset-pw-container'>
        <div className='reset-pw-title-container'>
          <img style={{width:25, height:25, paddingTop:6, filter: 'grayscale(1) brightness(1.2)'}} src={UNIFIED_CARE_DEFAULT_LOGO} />&nbsp;&nbsp;<span className='reset-pw-title'>&nbsp;|&nbsp;&nbsp;iHealth ShareCare</span>
        </div>
        <div className='reset-pw-instructions-container'>
          <div className='forgot-pw'>Forgot your password?</div>
          <div className='enter-email'>Enter your email below, and we'll send you a password reset link.</div>
        </div>
        <IHBaseForm {...formProp}>
          <div className="buttons-wrap">
            <IHButton className='reset-pw-btn' bsStyle="primary" disabled={isSubmitting} type="submit" size="large" style={submittingStyle(isSubmitting)}>
              {isSubmitting ? <IHLoading /> : 'Send Reset Link'}
            </IHButton>
          </div>
          <div>
            <IHButton className="back-to-login" size='large' type='submit' onClick={back}>Back to Login</IHButton>
          </div>
        </IHBaseForm>
      </div>
    );

    // const headerEleme = (
    //   <div>
    //     <h3>FORGOT PASSWORD</h3>
    //     <p>Enter your Login Id and email and we’ll email you a link to reset your password.</p>
    //   </div>
    // );

    const footerElem = (
      <div>
        <h5 onClick={this.onExpand}>Not getting the link?</h5>
        {this.state.expanded && (
          <div className="detail">
            <span>Try sending it again or contact<br /> Support Centre</span>
          </div>
        )}
      </div>
    );

    const panelProps = {
      // header: headerEleme,
      body: bodyElem,
      // footer: footerElem,
      footer: null,
    };

    return (
      <div className="vsm-auth-box vsm-new-box auth-step2">
        <IHPanel {...panelProps} />
      </div>
    );
  }
}
