import React from 'react';
import { Icon } from 'antd';

const BGMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Blood Sugar Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Blood Sugar Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'measurement':{
        title:'Single Blood Sugar Data.'
    },
}

const BPMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Blood Pressure Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/heart-icon-2tone.svg'


    },
    'TWO_WEEK':{
        title:'2-Week Blood Pressure Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/heart-icon-2tone.svg'

    },
    'measurement':{
        title:'Single Blood Pressure Data.'
    }
}

const TEMPMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Thermometer Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Thermometer Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'measurement':{
        title:'Single Thermometer Data.'
    },
}

const POMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Pulse Oximeter Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Pulse Oximeter Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'measurement':{
        title:'Single Pulse Oximeter Data.'
    },
}

const HSMeasureTypeMap = {
    'ONE_MONTH':{
        title:'1-Month Body Weight Data',
        path:'/image/1-month-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'TWO_WEEK':{
        title:'2-Week Body Weight Data',
        path:'/image/2-weeks-icon.svg',
        logoPath:'/image/blood-drop-icon-2tone.svg'
    },
    'measurement':{
        title:'Single Body Weight Data.'
    },
}

const BGSeverityMap = {
    "CriticallyLow":{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'CriticallyHigh':{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'Normal':{
        text:'NORMAL',
        color:'rgb(54,204,141)'
    },
    'High':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'Low':{
        text:'LOW',
        color:'rgb(253,181,67)'
    }
}

const BPSeverityMap = {
    'aha_HypCris':{
        text:'CRISIS',
        color:'rgb(217,21,21)'
    },
    "aha_HypStage_2":{
        text:'STAGE 2',
        color:'rgb(239,69,69)'
    },
    "aha_HypStage_1":{
        text:'STAGE 1',
        color:'rgb(255,134,46)'
    },
    'aha_ElevatedReading':{
        text:'ELEVATED',
        color:'rgb(253,181,67)'
    },
    'aha_NormalReading':{
        text:'NORMAL',
        color:'rgb(105,211,118)'
    },
    'BPLowReading':{
        text:'LOW',
        color:'rgb(49,119,201)'
    }
}

const TEMPSeverityMap = {
    "CRITICAL":{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'NORMAL':{
        text:'NORMAL',
        color:'rgb(54,204,141)'
    },
    'HIGH':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'LOW':{
        text:'LOW',
        color:'rgb(253,181,67)'
    }
}

const HSSeverityMap = {
    'CRITICAL':{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'NORMAL':{
        text:'NORMAL',
        color:'rgb(54,204,141)'
    },
    'HIGH':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'LOW':{
        text:'LOW',
        color:'rgb(253,181,67)'
    }
}

const POSeverityMap = {
    'CRITICAL':{
        text:'CRITICAL',
        color:'rgb(255,100,100)'
    },
    'NORMAL':{
        text:'NORMAL',
        color:'rgb(54,204,141)'
    },
    'HIGH':{
        text:'HIGH',
        color:'rgb(253,181,67)'
    },
    'LOW':{
        text:'LOW',
        color:'rgb(253,181,67)'
    }
}

const style = {
    container: {
        padding: '10px 20px',
        backgroundColor: 'white',
        border: 'solid 1px rgb(80,176,231)',
        borderRadius:'8px'
    },
    date:{
        fontWeight:'bold'
    },
    header:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        minWidth:'60px'
    },
    rangedHeader:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        minWidth:'60px'
    },
    rangedHeaderSub:{
        display:'flex',
        flexDirection:'column',
        marginLeft:15
    },
    message:{
        display:'flex',
        flexDirection:'row',
        paddingTop:'10px',
    },
    divider1:{
        margin:'0',
    },
    divider2:{
        height:'inherit'
    },
    textStyle:{
        width:'70%',
    },
}

export default {
    BGMeasureTypeMap,BGSeverityMap,style,BPMeasureTypeMap,BPSeverityMap,TEMPSeverityMap,POSeverityMap,HSSeverityMap,TEMPMeasureTypeMap,POMeasureTypeMap, HSMeasureTypeMap
}
