import gql from 'graphql-tag';

export default gql`
query getPatientSmartAlert($memberId:EID!){
  getPatientSmartAlert(memberId:$memberId){
    id
    lastName
    firstName
    tasks {
            id
            createdAt
            taskType
            alertLevel
            visitWindow
            status
            display
            replyTime 
            category
            closeAt
            closeBy {
              profile {
                fullName
              }
            }  
            providerNotes{
                    id
                    content
                    createdAt
                    createdBy{
                        id
                        fullName
                        avatar{
                            link
                        }
                        
                    }
            }
            reason
           }
    }
}`;
