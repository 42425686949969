import React from 'react';
import { Table,Radio,Icon } from 'antd';
import { set } from 'lodash';
import moment from "moment/moment";
import { convertBG_mmolTomgdl } from '../../../../lib/utils/convertUnits';
import $ from 'jquery';
import { IHLoading } from 'ihcomponent';
import helpers from '../../../logBook/helpers/index';
const { Column, ColumnGroup } = Table;

// const { Button,Group } = Radio;
const readingSeverityMap ={
    "RISK":'riskReading',
    "NORMAL":'normalReading',
    "CRITICAL":'criticalReading'
}
const valueToSeverity = (readingValue)=>{

}
const typeAndBeforeMap = {
    'beforeBreakfast':{
        type:'BREAKFAST',
        before:true
    },
    'afterBreakfast':{
        type:'BREAKFAST',
        before:false
    },
    'beforeLunch':{
        type:'LUNCH',
        before:true
    },
    'afterLunch':{
        type:'LUNCH',
        before:false
    },
    'beforeDinner':{
        type:'DINNER',
        before:true
    },
    'afterDinner':{
        type:'DINNER',
        before:false
    },
    'bedtime':{
        type:'BEDTIME',
        before:false
    },
    'overnight':{
        type:'OVERNIGHT',
        before:true
    }
}
const BGLogBookMiniComponent = class extends React.Component {
   constructor(){
       super();
   }
   generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }
        return timeSpots.reverse();
    }

    isPairedMeasure(resultList,type){
        const measures = _.reject(resultList[type],(i)=>i.severity==='MISSED');
        const beforeMeal = _.filter(measures,(d)=>d.blood_glucose&&d.beforeMeal&&d.severity!='MISSED');
        const afterMeal = _.filter(measures,(d)=>d.blood_glucose&&!d.beforeMeal&&d.severity!='MISSED');
        let res=[];
        if(measures&&measures.length==2&&beforeMeal.length==1&&afterMeal.length==1){
            res[0] = beforeMeal[0];
            res[1] = afterMeal[0];
            return res;
        }
        return false;
    }
    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in</span>
            <span style={{fontWeight:900}}>&nbsp;mg/dL</span>
        </Icon>
    }
    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const filterByMealType = (resList=[],mealType,isBeforeMeal)=> _.filter(resList,res=> res['mealType'] == mealType && res['beforeMeal']==isBeforeMeal );
        const parsedData =  _.groupBy(data,(d)=>{
            return moment(d.date).format('MM/DD/YYYY')
            })

        let groupedData = _.map(timeSpots,(key)=>{
            if(parsedData[key] == undefined) {
                return null;
            }
            let tmp = { };
            for (const [ _,val ] of Object.entries(typeAndBeforeMap)) {
                const { type,before } = val;
                let arr = filterByMealType(parsedData[key],type,before);
                let maxLen = tmp['maxLen'] || 0;
                set(tmp,`${type}-${before?'TRUE':'FALSE'}`,arr);
                set(tmp,'maxLen',Math.max(arr.length,maxLen));
                if(arr[0]&&!tmp['date']) {
                    set(tmp,'date',arr[0].date);
                }
            }
            return {
                [key]:tmp
            }
        }).filter(v => !!v)

        return groupedData;
    }

    filterTypeAndBefore(input,type){
        const typeAndBeforeMap = {
            'beforeBreakfast':{
                type:'BREAKFAST',
                before:true
            },
            'afterBreakfast':{
                type:'BREAKFAST',
                before:false
            },
            'beforeLunch':{
                type:'LUNCH',
                before:true
            },
            'afterLunch':{
                type:'LUNCH',
                before:false
            },
            'beforeDinner':{
                type:'DINNER',
                before:true
            },
            'afterDinner':{
                type:'DINNER',
                before:false
            },
            'bedtime':{
                type:'BEDTIME',
                before:false
            },
            'overnight':{
                type:'OVERNIGHT',
                before:true
            }
        }
        const obj = typeAndBeforeMap[type];
        return _.filter(input[obj.type],(d)=>{
            if(d.mealType!='BEDTIME') {
                return (d.severity != 'MISSED') && (d.beforeMeal == obj.before)
            }
            else{
                return d.severity != 'MISSED';
            }

        });
    }

    handleData(filteredList){
        const allMissed = filteredList.length>0 && !_.find(filteredList,(i)=>(i.severity!='MISSED'));
        let readingCount = !allMissed ? _.reject(filteredList,(i)=>i.severity=='MISSED').length :'';
        const readingObj = filteredList.length>0&&!allMissed ? _.find(filteredList,(i)=>(i.severity!='MISSED')) : {};
        const readingValue = readingObj.blood_glucose ? readingObj.blood_glucose.value : '';
        const readingDate = readingObj.date;
        const hasUserNote = (_.filter(filteredList,(i)=>_.get(i,'measure.user_notes'))).length!=0;
        let className = allMissed ? 'missedReading' : (readingCount>1 ? 'logbookCellWithBadge':readingCount>0 ? 'logbookCell':'') +" "+ readingSeverityMap[helpers.getBGReadingSeverity(readingObj.beforeMeal,convertBG_mmolTomgdl(readingValue))];
            // (readingSeverityMap[readingObj.severity]||'');
        const mgdlValue = allMissed ? 'Missed' : convertBG_mmolTomgdl(readingValue)!=0 ? convertBG_mmolTomgdl(readingValue):'';
        const unit =  mgdlValue>0 ? ' mg/dL':'';
        readingCount = readingCount>1 ? readingCount : null;
        const hasUserNoteClassName = hasUserNote  ? (` hasUserNote ${mgdlValue<=99 ? `twoDigit`: `threeDigit`}`):'';
        className =className.concat(hasUserNoteClassName);
        return {
            readingCount,
            mgdlValue,
            unit,
            className,
            readingDate,
            allMissed,
            hasUserNote
        }
    }

    renderTable(){
        const dataToRender = this.groupData();
        const result = [];
        for(const arrayItem of dataToRender) {
			let arrayData = Object.values(arrayItem)[0];
            let { maxLen,date } = arrayData;
            for (let i=0;i<maxLen;i++) {
                let data = [];
                for (const [ key,array ] of Object.entries(arrayData)) {
                   if(_.isArray(array)&&array[i]) {
                        data.push(array[i])
                   }
                }
                result.push({ data:_.groupBy(data, (d) => d.mealType),date });
            }
        }
        return  <div className='IH-SearchTable'>
            {/* {this.renderTableHeader()} */}
        <Table dataSource={result} rowKey={(data,index)=>data.date+index}
                className='logMiniBookTable' bordered
                style={{ marginBottom:0, fontSize:10}}
                pagination={false}
        >
                    <Column
                        title="Date"
                        dataIndex="date"
                        // key='date'
                        render={(data)=>moment(data).format('MM/DD/YY')}
                        width={'14%'}
                    />
            <Column
                title=""
                dataIndex="data.OVERNIGHT"
                key='overnight'
                width='11%'
                className='overnight logBookIcon'
                render={(data, index) => {
                    const { readingCount,className,mgdlValue,unit } = this.handleData(data||[]);
                    return {
                        props: {
                            className:'number',
                            data:readingCount

                        },
                        children: <div data={readingCount} className={className}>
                                    <div>
                                        <span>{ mgdlValue }</span>
                                    </div>
                                </div>
                    }
                }
                }
            />
                  <ColumnGroup title="Breakfast">
                      <Column
                          title="B"
                          key='beforeBreakfast'
                          width='11%'
                          dataIndex="data.BREAKFAST"
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);
                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount
                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                            }
                          }
                      />
                      <Column
                          title="A"
                          dataIndex="data.BREAKFAST"
                          key='afterBreakfast'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);
                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                             }
                          }
                      />
                  </ColumnGroup>
                  <ColumnGroup title="Lunch">
                      <Column
                          title="B"
                          width='11%'
                          dataIndex="data.LUNCH"
                          key='beforeLunch'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                        }
                      />
                      <Column
                          title="A"
                          dataIndex="data.LUNCH"
                          key='afterLunch'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                        }
                      />
                  </ColumnGroup>
                  <ColumnGroup title="Dinner">
                      <Column
                          title="B"
                          dataIndex="data.DINNER"
                          width='11%'
                          key='beforeDinner'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                            </div>
                              }
                          }
                          }
                      />
                      <Column
                          title="A"
                          dataIndex="data.DINNER"
                          key='afterDinner'
                          width='11%'
                          render={(data, index) => {
                              const filteredList = _.filter(data,(d)=>!d.beforeMeal);
                              const { readingCount,className,mgdlValue,unit } = this.handleData(filteredList);

                              return {
                                  props: {
                                      className:'number',
                                      data:readingCount

                                  },
                                  children: <div data={readingCount} className={className}>
                                              <div>
                                                  <span>{ mgdlValue }</span>
                                              </div>
                                           </div>
                              }
                          }
                          }
                      />
                  </ColumnGroup>
                  <Column
                      title=""
                      dataIndex="data.BEDTIME"
                      key='bedtime'
                      className='bedtime logBookIcon'
                      width='11%'
                      render={(data, index) => {
                          const { readingCount,className,mgdlValue,unit } = this.handleData(data||[]);
                          return {
                              props: {
                                  className:'number',
                                  data:readingCount

                              },
                              children: <div data={readingCount} className={className}>
                                          <div>
                                              <span>{ mgdlValue }</span>
                                          </div>
                                       </div>
                          }
                      }
                      }
                  />
              </Table>
            </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }
}

export default BGLogBookMiniComponent;
