import { useState, useEffect } from 'react';
import moment from 'moment';
import getArticleCategories from '../API/getArticleCategories';

export const ALL_CATEGORIES = 'ALL';
const ARTICLE_CATEGORY_KEY = 'articleCategories';

const sortedListByName = Object.assign(
  {},
  ..._.map([
    'All',
    'Diabetes',
    'Hypertension',
    'Weight Management',
    'Wellness',
    'Chronic Kidney Disease (CKD)',
    'Hyperlipidemia',
    'Chronic Obstructive Pulmonary Disease (COPD)',
    'Congestive Heart Failure (CHF)',
    'Holiday Treats',
  ], (v, index) => ({ [v]: index }))
);

// Object: { categories: [], cachedDate: Date }
export const getSessionCategories = () => JSON.parse(sessionStorage.getItem(ARTICLE_CATEGORY_KEY)) || {};
const setSessionCategories = (data) => {
  const cachedDate = new Date().getTime();
  sessionStorage.setItem(ARTICLE_CATEGORY_KEY, JSON.stringify({
    categories: data,
    cachedDate,
  }));
};

export default () => {
  const [isLoadingArticleCategories, setIsLoadingArticleCategories] = useState(false);
  const [articleCategories, setArticleCategories] = useState(undefined);
  const today = moment().startOf('day');

  const handleSetArticleCategories = (data) => {
    setSessionCategories(data);
    setArticleCategories(data);
  };

  const checkAndSetArticleCategories = async () => {
    if (isLoadingArticleCategories) return;

    const { categories, cachedDate } = getSessionCategories();
    setIsLoadingArticleCategories(true);
    if (
      !cachedDate 
      || !today.isSame(moment(cachedDate), 'day')      
    ) {
      try {
        const res = await getArticleCategories();
        if (!res || res.code !== 200) {
          throw new Error('Server error: ', _.get(res, 'msg'));
        }
        const _articleCategories = _.map(res.data, (d) => ({
          id: d.id,
          name: d.name,
          updatedAt: d.updatedAt,
          slug: d.slug,
          publishDate: d.publishDate,
          icd: d.icd,
        }));

        _articleCategories.unshift({
          id: ALL_CATEGORIES,
          name: 'All',
        });

        const unsorted = [];
        let sorted = Array(_articleCategories.length).fill(-1);
        _.map(_articleCategories, (a) => {
          const orderIndex = sortedListByName[a.name];
          if (orderIndex > -1) 
            sorted.splice(orderIndex, 0, a);
          else
            unsorted.push(a);
        });
        sorted = _.filter(sorted, (s) => typeof s !== 'number');

        handleSetArticleCategories([...sorted, ...unsorted]);
      } catch (error) {
        console.error('Failed to load article categories, ', error);
      }
    } else if (categories && !articleCategories) {
      handleSetArticleCategories(categories);
    }
    setIsLoadingArticleCategories(false);
  };

  useEffect(() => { checkAndSetArticleCategories() }, [today.valueOf()]);

  return {
    articleCategories,
    isLoadingArticleCategories,
  };
};