import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import editUserEmail from 'graphqlModule/mutation/editUserEmail'
import editUserPhone from 'graphqlModule/mutation/editUserPhone'
import { userEmail as updateQueries } from 'graphqlModule/updateQueries/userEmail'
import { IHForm, IHButton, message, IHLoading } from 'ihcomponent'
import PropTypes from 'prop-types';
import EditPhoto from 'providerModule/ProviderEdit/components/ProviderEditPhotoComponent'
import presignedUploadGQL from '../../gql/getpresignedUpload';
import { GQLHelper, validateAntdForm, submittingStyle } from 'libModule/utils';
import { closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import * as actions from 'providerModule/ProviderEdit/ProviderEditDuck'
import UserClass from 'modulesAll/graphql/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import Client from 'libModule/gqlClient';
import '../css/index.scss'
import helpers from '../../../upload/API/helpers';
import API from '../../../upload/API/index';
import editUserGQL from 'modulesAll/graphql/mutation/editUser';
import { userWithoutTeam as userGQL } from 'modulesAll/graphql/user'

const getBase64 = helpers.getBase64;

class Container extends Component {
    static displayName = 'provider/ProviderEdit/containers/ProviderEditPhotoContainer'
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            previewImage: null
        }
    }
    closeModal() {
        const { closeModal } = this.props;
        closeModal();
    }

    render() {
        const user = this.props.user;
        const previewImage = this.state.previewImage;
        return (
            <div className={"ih-editphoto-box"}>
                <EditPhoto user={user} previewImage={previewImage} closeModal={() => this.closeModal()}
                    beforeUploadPhoto={(file) => this.beforeUploadPhoto(file)}
                    saveImage={() => this.saveImage()}
                />
            </div>
        )
    }

    beforeUploadPhoto = async (file) => {
        let img = file.originFileObj;
        if (!img.url && !img.preview) {
            img.preview = await getBase64(img);
        }
        this.setState({
            previewImage: img.url || img.preview,
            image: img
        });
    }


    saveImage = () => {

        const image = this.state.image;
        if (image) {
            const imageName = _.get(image, 'name');
            const user = this.props.user;

            getUploadUrl(user.id, imageName).then(async res => {
                const data = res.getPresignedUpload.data;

                const url = data.url;
                let fields = data.fields;

                fields = _.isString(fields) ? JSON.parse(fields) : fields;
                const fileExtension = imageName.split('.')[1];
                const mimeType = fileExtension ? (`image/${_.toLower(fileExtension)}`) : 'image/jpg';
                fields['Content-Type'] = mimeType;

                let fileKey = await API.sendAJaxReq({ uploadUrl: url, filename: imageName, file: image, mimeType, fields });
                
                await updateUser({ id: user.id, commonProfile: { avatar: { fileKey } } }, this.props)
                this.props.closeModal();
            });
        } else {

        }
    }
}

const updateUser = (userVariables, props) => {
    return Client.mutate({
        mutation: editUserGQL,
        variables: userVariables,
        refetchQueries: [
            {
                query: userGQL, variables: { id: userVariables.id },
                fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true
            }
        ]
    });
}

const getUploadUrl = (userId, filename) => {
    const variables = {
        userId,
        filename
    };
    return Client.query({
        query: presignedUploadGQL,
        variables,
        fetchPolicy: 'network-only'
    }).then(res => {
        return res.data;
    });
}


const mapState = ({ provider }, ownProps) => {
    return {
        ...provider.ProviderEdit
    }
}

const mapDispatch = (dispatch) => {
    return {
        closeModal: () => dispatch(closeModal()),
        //updateUser: (v,p) => dispatch(updateUser(v,p))
    }
}

Container.propTypes = {
    userData: PropTypes.object,
    closeModal: PropTypes.func,
}

export default compose(connect(mapState, mapDispatch))(Container)
