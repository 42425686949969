import React, { useState } from 'react';
import { Button, Icon } from 'antd';

import SignOffSubmitInfo from '../SignOffSubmitInfo';
import TaskAssignmentHistory from '../../../../taskAssignment/components/TaskAssignmentHistory';

import { getTimeWithFormat } from '../../../helper/signOffHelper';
import map from "../../../../taskAssignment/constants/tableMap";


const SignOffTaskAssignment = (props) => {
  const { tasks } = props;

  const [viewTaskDetails, setViewTaskDetails] = useState({});

  const handleToggleViewTaskDetails = (id) => {
    setViewTaskDetails({
      ...viewTaskDetails,
      [id]: !viewTaskDetails[id]
    });
  };

  const renderTaskDetailContent = (task) => {
    const { priorityLevel, reason, history, createdAt } = task;
    const priorityText = map.priorityMap[priorityLevel];
    return <div>
      <div className='taskDetail'>
        <span className='label'>Priority:</span>
        <span className='detail'  >
          {priorityText || '--'}
        </span>
      </div>
      <div className='taskDetail'>
        <span className='label'>Reason:</span>
        <span className='detail'>{reason}</span>
      </div>
      {
        _.get(history, 'length') &&
        <TaskAssignmentHistory
          taskCreatedAt={createdAt}
          history={history}
          showButton={(isShowing, buttonProps, taskHistoryLength) => {
            return (
              <Button {...buttonProps} type='link' style={{ padding: 0, paddingLeft: 8, color: 'var(--gray-scale-1)'}}>
                {!isShowing ? 'View' : 'Hide'} reason history ({taskHistoryLength})
              </Button>
            );
          }}
        />
      }
    </div>;
  }
  return <div className='signOffItem'>
    <div className='header'>
      Task
    </div>
    {
      _.map(tasks, task => {
        const { id, createdAt, resolvedNote } = task;
        const { note, resolvedAt, resolvedBy } = resolvedNote || {};
        const resolvedByName = _.get(resolvedBy, 'profile.fullName');

        return <div key={id} className='signOffCard signOffTask'>
          <Button type='link' onClick={() => handleToggleViewTaskDetails(id)}>
            { viewTaskDetails[id] ? 'Hide' : 'View' } task details
            <Icon type={!viewTaskDetails[id] ? 'down' : 'up'} style={{ fontSize: 12 }} />
          </Button>
          {/* </Popover> */}
          {
            viewTaskDetails[id] &&
            <div className='taskDetailContainer'>
              {renderTaskDetailContent(task)}
            </div>
          }
          <div className='description'>
            Generated on: {getTimeWithFormat(createdAt)}
          </div>
          <div className='description note'>
            {
              note && 
              <p className='hideOnList'>
                {
                  `Resolve Note: 
                  ${note}`
                }
              </p>
            }
            <SignOffSubmitInfo 
              submittedBy={resolvedByName}
              submittedAt={resolvedAt}
            />
          </div>
        </div>;
      })
    }
  </div>;
}

export default SignOffTaskAssignment;