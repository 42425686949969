import types from 'patientModule/enrolledProgramInfo/Survey/constants/FormConstants'
import { commonReducer } from 'libModule/helpers/reducer-helpers'

const initState = {
  isShowAlertForm: false,
  isEditMode: false
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.UPDATE_FORM:
      return {
        ...state,
        fieldsValue: action.fieldsValue,
        isEditMode: action.isEditMode,
        assessment: action.assessment
      }
    case types.TOGGLE_ALERT_FORM:
      return {
        ...state,
        isShowAlertForm: action.isShowAlertForm
      }
    case types.RESET_FORM:
      return initState
    case types.FORM_FIELD_CHANGE:
      return {
        ...state,
        validateCalendar: 'NO_ERROR'
      }
    case types.SET_VALIDATE_CALENDAR:
      return {
        ...state,
        validateCalendar: action.validateCalendar
      }
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
