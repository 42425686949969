import I18N from 'modulesAll/I18N'
import {_} from 'ihcomponent'

export default {
  getProgramTaskThresholdComponentProps : (tasks)=>{
    const loop = (task)=>{
      const tmp = I18N.get(`Threshold.${task.type}`)
      const rs = {
        name : tmp.title,
        unit : tmp.unit,
        data : [],
        schedule : task.schedule,
        repeat: task.repeat
      }

      _.each(task.threshold, (v)=>{
        rs.data.push({
          name : tmp.name ? tmp.name[v.measure] : v.measure,
          range : v.range //_.filter(v.range, (l)=>!!l)
        })
        rs.isBaseline = (v.measure === 'WEIGHT_CHANGE_BASELINE')
        if (v.baseline) {
          rs.baselineWeight = {
            value: v.baseline,
            unit: v.unit
          }
        }

        rs.unit = v.unit
      })

      // if(_.size(rs.data) < 2){
      //   rs.data.push(rs.data[0])
      // }

      return rs
    }

    const result = _.map(tasks, (t)=>{
      return loop(t)
    })

    return result
  }
}
