import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import {
    unratedFoodLogs,
    unresolvedTasks,
    unresolvedSmartAlerts,
    miniUserAPI,
    top,
    workers,
    reports,
    pinnedBy,
    billableTimeQuery,
    withEnrolledCurrentProgram,
    missingInfoListAPI,
    withFutureFollowUpVisitCount,
} from '../API/index';
import {compose} from "react-apollo/index";
import { pinnedPatients,pin,unpin,postpone,work } from "../API/index";
import { getPatientInitial,checkIsPinned,handlePatientChange,ifUserHasUnread, getCurrentOrg, preFetchChatHistory } from '../helper/index';
import PatientPage from 'modulesAll/patient/main/containers/main';
import {Col, Row,Tabs,Button,Divider,message,Icon,Tooltip,Popover,Modal,Select,Badge,Spin} from "antd";
import TaskWorkStationContainer from "../../taskAssignment/containers/TaskWorkStationContainer";
import WorkStationFoodLogs from '../../foodLog/components/WorkStationFoodLogs';
import MonthlyReviewPlanContainer from "./MonthlyReviewPlanContainer";
import ChatWorkStationComponent from '../component/ChatWorkStationComponent';
import {getUserRole, goPath} from "../../../lib/utils";
import ProviderAlertResolveComponent from '../../smartAlert/component/ProviderAlertResolveComponent';
import TaskCompletedMessage from '../component/TaskCompletedMessage';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import TotalSummarySimpleComponent from '../component/TotalSummarySimpleComponent';
import { tabMap, monthlyReviewStatusMap, MONTHLY_REVIEW_TYPES, MONTHLY_REVIEW_TITLE } from '../constant';
import { connect } from 'react-redux'
import { addPatientToHistory } from '../action/index';
import { checkShouldShowChatRedDot } from '../../chat/helper';
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../idleTime/constant';
import I18N from '../../I18N';
import { setMonthlyReviewStatus, setMonthlyReviewType, setMonthlyReviewOpen } from '../action/index';
import { helpers } from '../../visitNewWorkFlow/helper';
import MTPRModal from  "../../patient/main/components/MTPRModal";
import MissingInfoContainer from '../container/MissingInfoContainer';
import withRouteLeaveHooks from '../../../lib/hooks/withRouteLeaveHooks';
import { checkIfMonthlyReviewInCompleted } from '../helper/monthlyReviewHelper';
import { wrapMonthlyReviewContext } from '../../monthlyReview/hooks/useMonthlyReview';
import { OMRONContextProvider } from '../../OMRONContext/OMRONContextProvider';

const MONTHLY_REVIEW_CRON_DAY = 25;
const BILLABLE_MINUTES = 18;
const RD = "RD";
const TASK_RESOLVER_TIMER = 'TASK_RESOLVER_TIMER';
const { TabPane } = Tabs;
const VIEW_MISSINGINFO_ROLE = ["CA"];
const MTPR_TYPES = ["MTPR", "MTPR_ADDITIONAL"]
const VIEW_MTPR_MODAL_ROLES = ["RD"];

const initialState = {
    showPostponePopover: false,
    totalPatientCase: 0,
    loadingPatient: false,
    others: [],
    reports: {},
    tabSelected: undefined,
    nextPatientData: null,
    showUnsavedModal: false,
    shouldBlockNavigation :false,
    cancelBlockNavigation: false,
    timeSpentInMin: 0,
    hasUnreadMessage: false,
};

const TaskResolveContainer = class extends React.Component {
    constructor(props) {
        super(props);
        const role = helpers.getRole();
        this.state = {
            ...initialState,
            role,
        }
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
    }
    
    get isSingle() {
        return this.props.route.isSingle;
    }

    componentDidMount() {
        const { id } = this.props.params;
        this.init(id);
        this.props.addRouteLeaveHook(this.routerWillLeave);
    }

    componentWillUnmount() {
      const { addPatientToHistory } = this.props;
      addPatientToHistory(this.state.viewingPatient);
    }

    componentDidUpdate(prevProps, prevState) {
        const { 
          unresolvedAlerts,
          unratedFoodLogs,
          unresolvedTasks,
          user,
          monthlyReviewStatus,
          setMonthlyReviewOpen,
          userRole,
          loadingFutureFollowUpVisit,
        } = this.props;

        if (prevProps.unresolvedAlerts > 0 && unresolvedAlerts === 0) {
            message.success('Alert(s) has been resolved!');
            this.setState({ tabSelected: undefined });
        } else if (prevProps.unratedFoodLogs > 0 && unratedFoodLogs === 0) {
            message.success('Foodlog(s) has been reviewed!');
            this.setState({ tabSelected: undefined });
        } else if (prevProps.unresolvedTasks > 0 && unresolvedTasks === 0) {
            message.success('Task(s) has been resolved!');
            this.setState({ tabSelected: undefined });
        } else if (this.getShowMonthlyReviewStatus(prevProps, prevState) && !this.getShowMonthlyReviewStatus()) {
            setMonthlyReviewOpen(false);
            this.setState({ tabSelected: undefined });
        }
        if(prevProps.loadingUser && !this.props.loadingUser && !this.state.viewingPatient) {
          const currentPatient = user || {};
          const currentPatientOrg = getCurrentOrg();
          const viewingPatient = {
            id: currentPatient.id,
            name: _.get(currentPatient, 'profile.fullName'),
            organizationId: currentPatientOrg.id,
            organizationName: currentPatientOrg.name
          };
          this.setState({ viewingPatient });
        }

        if(prevProps.monthlyReviewStatus !== monthlyReviewStatus && monthlyReviewStatus == monthlyReviewStatusMap.IN_PROGRESS){
            this.onMonthlyReviewTabOpen();
        }

       
        if(user && !loadingFutureFollowUpVisit){
            const assignees = _.get(user, 'assignees');
            let assigneesId = [];
            _.map(assignees, (v,k) => { if(v&&v.id) assigneesId.push(v.id)})
            const currentUserId = sessionStorage.getItem('currentUserId');
            if(_.includes(VIEW_MTPR_MODAL_ROLES, userRole) && _.includes(assigneesId, currentUserId)){
                // Check the conditions of blocking navigation
                this.checkShouldBlockNavigation();
            }
        }

        const hasNewUnreadMessage = user && ifUserHasUnread(user.id);
        if (prevState.hasUnreadMessage !== hasNewUnreadMessage) {
          this.setState({ hasUnreadMessage: hasNewUnreadMessage });
        }

        const { taskCount: prevTaskCount } = this.getTasksCount(prevProps);
        const { taskCount: curTaskCount } = this.getTasksCount();
        if (
          prevState.totalPatientCase === 1 
          && (
            (prevTaskCount > 0 && curTaskCount === 0)
            || (prevState.hasUnreadMessage && !hasNewUnreadMessage)
          )
        ) {
          this.debounceGetTotalPatientCase();
        }
     }

     debounceGetTotalPatientCase = _.debounce(() => this.getTotalPatientCase(), 1000);

     getTotalPatientCase = async () => {
      const reportsRes = await reports();
      const reportData = _.get(reportsRes, 'data') || {};
      const totalPatientCase = reportData.total || 0;
      this.setState({ 
        totalPatientCase,
        reports: reportData,
      });
      return totalPatientCase;
     };

     checkShouldBlockNavigation = () => {
        // Three conditions of blocking navigation:
        // 1. timeSpent larger than BILLABLE_MINUTES
        // 2. before monthly review cron day
        // 3. no MTPR submitted before
        const now = new Date();
        const { timeSpentInMin, shouldBlockNavigation } = this.state;
        const { systemGenerateMonthlyReviewData, hasFutureFollowUpVisit } = this.props;
        const beforeCronDay = now.getDate() < MONTHLY_REVIEW_CRON_DAY;
        const isMTPR = _.includes(MTPR_TYPES, _.get(systemGenerateMonthlyReviewData, 'type'));
        const hasSubmitted = _.get(systemGenerateMonthlyReviewData, 'status') === monthlyReviewStatusMap.COMPLETED;
        const validTimeSpent = timeSpentInMin >= BILLABLE_MINUTES;
        const blockNavigation = beforeCronDay && !(isMTPR && hasSubmitted) && validTimeSpent && !hasFutureFollowUpVisit;
        if(blockNavigation !== shouldBlockNavigation){
            this.setState({shouldBlockNavigation: blockNavigation});
        }
    }

    createNewMonthlyReview = () => {
      const { 
        systemGenerateMonthlyReviewData, 
        setSystemGenerateMonthlyReviewData, 
        MRConstants,
        setMonthlyReviewStatus,
      } = this.props;
      if(_.isEmpty(systemGenerateMonthlyReviewData)){
          const newMonthlyReviewData = {
              type: _.get(MRConstants, 'reviewType'),
              status : monthlyReviewStatusMap.START
          }
          setSystemGenerateMonthlyReviewData(newMonthlyReviewData);
      }
      setMonthlyReviewStatus(monthlyReviewStatusMap.IN_PROGRESS);
    }

    routerWillLeaveClickOK = () => {
        // Open a new monthly review
        this.setState({visible: false});
        this.createNewMonthlyReview();
    }

    rounterWillLeaveClickCancel = () => {
        // Upon cancel, cancel blocking and set the warning modal invisible
        // route to the previous expected location
        const { cancelMTPRCb } = this.state;
        this.setState({ cancelBlockNavigation: true, visible: false }, () => {
          if (cancelMTPRCb) cancelMTPRCb();
        });
    }

    routerWillLeave = (nextLocation) => {
      return new Promise((resolve) => {
        const flag = this.checkIfShowMTPRPrompt(() => browserHistory.push(nextLocation));
        resolve(!flag); // true: continue routing; false: otherwise 
      })
    }

    setTimeSpentInMin = (timeSpent) => {
        const { timeSpentInMin } = this.state;
        if(timeSpent !== timeSpentInMin){
            this.setState({timeSpentInMin: timeSpent});
        }
    }

    handleMouseOver = () => {
        this.setState({
            showPatientTotalTask: 'block'
        })
    }

    handleMouseOut = () => {
        this.setState({
            showPatientTotalTask: 'none'
        })
    }

    init = async (id) => {
        const workersRes = await workers(id);
        const reportsRes = await reports();
        const pinnedByRes = await pinnedBy(id);
        work(id);
        this.setState({ pinnedBy:_.get(pinnedByRes,'data',[]),totalPatientCase: _.get(reportsRes, 'data.total', 0), others: _.get(workersRes, 'data', []), reports: _.get(reportsRes, 'data', {})});
    }

    renderOthersWorkingOn = ()=>{
        const { others } = this.state;
        const names = others.join(',');
        return <div className={'workingWrapper'}>
                <div className={'inner'}>
                    <span style={{marginRight: 20 }} className={'worker'}>{names} is currently on patient profile</span>
                    <Tooltip overlayClassName='working-tooltip' title="When other care team members are on this patient's profile at the same time, changes you made might be lost since there might be conflict. Please communicate with each other and proceed cautiously." placement='bottom'>
                        <Icon type="info-circle" />
                        <span className={'info'}>What does it mean?</span>
                    </Tooltip>
                </div>
               </div>
    }

    renderPinnedBy = ()=>{
        const { pinnedBy } = this.state;
        const names = pinnedBy.join(',');
        return <div className={'workingWrapper'}>
            <div className={'inner'}>
                <span style={{marginRight: 20 }} className={'worker'}>This patient is pinned by {names}</span>
                <Tooltip overlayClassName='working-tooltip' title="The member(s) who pinned this patient might also follow up on this case later. Please communicate with each other about the case coverage if necessary." placement='bottom'>
                    <Icon type="info-circle" />
                    <span className={'info'}>What does it mean?</span>
                </Tooltip>
            </div>
        </div>
    }

    getDefaultActiveKey = () => {
      const { unresolvedAlerts, unresolvedTasks, unratedFoodLogs, setMonthlyReviewStatus, missingInfo=[], systemGenerateMonthlyReviewData } = this.props;
      const { role} = this.state;
      const {  id, status } = systemGenerateMonthlyReviewData || {};
      const shouldViewMissingInfo = VIEW_MISSINGINFO_ROLE.includes(role);
      const hasMissingInfo = _.find(missingInfo,m=>m.status==='INIT');
      let defaultActiveKey;
      if(unresolvedAlerts) {
        defaultActiveKey = tabMap.Alert.tabKey;
      } else if (unresolvedTasks) {
        defaultActiveKey = tabMap.Task.tabKey;
      } else if (unratedFoodLogs) {
        defaultActiveKey = tabMap.FoodLog.tabKey;
      } else if (id && status == monthlyReviewStatusMap.START){
        defaultActiveKey = tabMap.MonthlyReview.tabKey;
        setMonthlyReviewStatus(monthlyReviewStatusMap.IN_PROGRESS);
      } else if(hasMissingInfo && shouldViewMissingInfo){
        defaultActiveKey = tabMap.MissingInfo.tabKey;
      }
      return defaultActiveKey;
    }

    foodlogTab = ()=>{
        const { unratedFoodLogs } = this.props;
        return <div className={'resolveTaskItem'}><img className={'foodlog'} src={'/image/FoodDiary.svg'}/>Food Diary ({unratedFoodLogs})</div>
    }

    tasktab = ()=>{
        const { unresolvedTasks } = this.props;
        return <div className={'resolveTaskItem'}><img className={'task'} src={'/image/Task.svg'}/>Task ({unresolvedTasks})</div>
    }

    alertTab = ()=>{
        const { unresolvedAlerts } = this.props;
        return <div className={'resolveTaskItem'}><img className={'alertTask'} src={'/image/Alert.svg'}/>Alert ({unresolvedAlerts})</div>
    }

    missingInfoTab = ()=>{
        return <div className={'resolveTaskItem'}><img className={'alertTask'} src={'/image/Task.svg'}/>Missing Information</div>
    }

    monthlyReviewTab = ()=> {
        const { monthlyReviewData, MRConstants } = this.props;
        const { type, id, status } = monthlyReviewData;
        const title = MONTHLY_REVIEW_TITLE[MONTHLY_REVIEW_TYPES[type]];
        return !(id && checkIfMonthlyReviewInCompleted(status)) ?
        <div className={'resolveTaskItem'}><img className={'alertTask'} src={'/image/workstation/monthly_review.svg'}/>{MONTHLY_REVIEW_TITLE[_.get(MRConstants, 'reviewType')]}<Icon type="close" onClick={this.onMonthlyReviewTabClose}/></div>:
        <div className={'resolveTaskItem'}><img className={'alertTask'} src={'/image/workstation/monthly_review.svg'}/>{title}</div>
    }

    onMonthlyReviewTabClose = () => {
        const { 
          systemGenerateMonthlyReviewData,
          setMonthlyReviewStatus,
          setMonthlyReviewOpen,
          setMonthlyReviewData,
        } = this.props;
        const { status } = systemGenerateMonthlyReviewData;
        setMonthlyReviewStatus(status);
        setMonthlyReviewOpen(false);
        setMonthlyReviewData(systemGenerateMonthlyReviewData);
        this.setState({ tabSelected: undefined });
    }
    onMonthlyReviewTabOpen = () =>{
        const { setMonthlyReviewOpen } = this.props;
        setMonthlyReviewOpen(true);
        this.setState({tabSelected: tabMap.MonthlyReview.tabKey});
    }
    setShowUnsavedModal = (status) => {
        this.setState({
            showUnsavedModal: status
        })
    }

    

    renderTabs = ()=>{
        const { interacted,user } = this.props;
        const memberId = this.props.params.id;
        const member = { id: this.props.params.id } ;
        const { refetchUnratedFoodLogsCount,unratedFoodLogs,refetchUnresolvedTasksCount,unresolvedTasks,unresolvedAlerts, refetchUnresolvedAlertCount, missingInfo=[],refetchMissingInfo,refetchMiniUser, currentProgram, MRConstants } = this.props;
        const content = [];
        const { role } = this.state;
        const showMonthlyReviewTab = this.getShowMonthlyReviewStatus();
        const shouldViewMissingInfo = VIEW_MISSINGINFO_ROLE.includes(role);
        const hasMissingInfo = _.find(missingInfo,m=>m.status==='INIT');
        
        if(unresolvedAlerts) {
            content.push(
                <TabPane tab={ this.alertTab()} key={tabMap.Alert.tabKey}>
                    <ProviderAlertResolveComponent
                        isWorkStation={true} key={ memberId } patientId={memberId}
                        refetchUnresolvedAlertCount={refetchUnresolvedAlertCount}
                        onInteraction={(description) => { interacted(EVENT_TYPES.ALERT, description); }}
                        setShowUnsavedModal={this.setShowUnsavedModal}
                    />
                </TabPane>)
        }
        if(unresolvedTasks) {
            content.push(
            <TabPane tab={ this.tasktab()} key={tabMap.Task.tabKey}>
                <TaskWorkStationContainer
                    member={member}
                    refetchUnresolvedTasksCount={refetchUnresolvedTasksCount}
                    onInteraction={(description) => { interacted(EVENT_TYPES.TASK_ASSIGNMENT, description); }}
                    setShowUnsavedModal={this.setShowUnsavedModal}
                    currentProgram={currentProgram}
                />
            </TabPane>)
        }
        if(unratedFoodLogs) {
            content.push(
                <TabPane tab={ this.foodlogTab() } key={tabMap.FoodLog.tabKey}>
                    <WorkStationFoodLogs
                        memberId={memberId}
                        refetchUnratedFoodLogsCount={refetchUnratedFoodLogsCount}
                        onInteraction={(description) => { interacted(EVENT_TYPES.FOOD_LOG, description); }}
                        setShowUnsavedModal={this.setShowUnsavedModal}
                    />
                </TabPane>
            )
        }
        if(showMonthlyReviewTab){
            content.push(<TabPane key={tabMap.MonthlyReview.tabKey} tab={ this.monthlyReviewTab()}>
                <MonthlyReviewPlanContainer {...this.props}
                                            defaultMonthlyReviewType={_.get(MRConstants, 'reviewType')}
                                            onInteraction={() => { interacted(EVENT_TYPES.MTPR_MRE); }}/>
            </TabPane>)
        }
        if(hasMissingInfo && shouldViewMissingInfo) {
            content.push(<TabPane tab={ this.missingInfoTab() } key={tabMap.MissingInfo.tabKey} forceRender={true}>
                <MissingInfoContainer user={user} missingInfo={hasMissingInfo} refetchMissingInfo={refetchMissingInfo} refetchMiniUser={refetchMiniUser}/></TabPane>)
        }
        const chatIcon = <ChatWorkStationComponent patientId={memberId} />;
        if(content.length==0) return <div style={{ padding: 10 }}>{chatIcon}</div>;

        return <Tabs
          className='unresolvedTaskWrapper'
          tabBarExtraContent={chatIcon}
          destroyInactiveTabPane
          activeKey={this.state.tabSelected || this.getDefaultActiveKey()}
          onChange={this.handleChangeTab}
        >
          { content }
        </Tabs>
    }

    handleChangeTab = (tabSelected) => {
        const { setMonthlyReviewStatus, setMonthlyReviewOpen, systemGenerateMonthlyReviewData } = this.props;
        if(tabSelected == tabMap.MonthlyReview.tabKey){
            setMonthlyReviewStatus(monthlyReviewStatusMap.IN_PROGRESS);
            setMonthlyReviewOpen(true);
        } else {
            setMonthlyReviewStatus(systemGenerateMonthlyReviewData.status);
            setMonthlyReviewOpen(false);
        }
        this.setState({
          tabSelected,
          showUnsavedModal:false
        }, () => console.log('Tab', tabSelected, '---State', this.state));
    }

    handlePostponeError = (res) => {
      const { code,data,msg } = res;
      if(code === 500) {
        console.error(`${msg} ${data}`);
        message.error('Failed to postpone!');
        return true;
      }
      return false;
    }

    handlePinBtn = (isPinned,id)=>{
        const response = isPinned ? unpin(id): pin(id);
        const actionMsg = isPinned ? 'Unpinned' : 'Pinned';
        response.then(res=>{
            const { code,data,msg } = res;
            if(!isPinned) {
              void postpone(id).then(this.handlePostponeError);
            }
            if(code==200) {
                this.props.refetchPinnedPatients()
                message.success(`Patient ${actionMsg}.`);
            }
            if(code==500){
                message.warning(`${msg} ${data}`)
            }
        });
    }

    handlePostponePatient = (patientId)=>{
        postpone(patientId).then(res=> {
            if(this.handlePostponeError(res)) {
              return;
            }
            this.setState({
                showPostponePopover: false
            },async ()=>{
                await this.handleNextPatient()
                message.success('Patient Postponed.')
            });
        }).catch(message.error);
    }

    handleClickPinnedPatient = (patient)=>{
        if (patient.id === this.props.params.id) return;
        // this.props.stopTimer('save', () => handlePatientChange(patient));
        handlePatientChange(patient);
    }

    renderCases = () => {
        const { totalPatientCase } = this.state;
        return (
            <Col span={24} style={{ padding: '10px 20px', backgroundColor: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 17 }}>
                    <div style={{ display:'flex' }}>
                        <a style={{ color: '#757980' }} href={'/workstation'}>Workstation \</a>&nbsp;
                        <Popover placement="bottom" overlayClassName={'totalPatientHover'} content={<TotalSummarySimpleComponent reports={this.state.reports}/>}
                        ><div><span style={{ color: '#2D3238' }}>{`Patient Cases( ${totalPatientCase} )`}</span></div></Popover>
                    </div>
                    <div>
                        {this.renderBillableTime()}
                    </div>
                </div>
            </Col>
        );
    }
    renderBillableTime = ()=>{
        const { billableTime={ } } = this.props;
        const { totalEnrTime } = billableTime;
        return <div className={'totalTime'} style={{ marginLeft: 'auto' }}>
            <span style={{ fontSize:14 }}>{ `Total time spent on enrolled patients:  `}</span>
            <span className={'number'} style={{ fontWeight:800 }}>{totalEnrTime ? Math.round(totalEnrTime/60) :'--'}</span>
            <span className={'unit'}> min</span>
        </div>

    }
    renderPatients = ()=>{
        const { others } = this.state;
        const { user, pinnedPatients, patientHistory, patientIdToChannel } = this.props;

        return <div className={'patientsWrapper'}>
                {_.map(pinnedPatients,patient=> {
                  const decodedPatientId = (_.split(atob(patient.id), ':')[1] || atob(patient.id));
                  const channelName = patientIdToChannel[decodedPatientId];
                  const shouldShowRedDot = checkShouldShowChatRedDot(channelName);

                  return (<Tooltip title={patient.fullName}>
                    <div className={'patientName pinned'} onClick={()=>this.handleClickPinnedPatient(patient)}>
                      <Badge dot={shouldShowRedDot} offset={[2, -6]}>
                          <span>{getPatientInitial(patient.fullName)}</span>
                      </Badge>
                    </div>
                  </Tooltip>
                  );
                })}
                        <Divider type={'vertical'}></Divider>
                {
                    patientHistory.length ?
                        <Tooltip placement="top" title={'Show recent patient cases'} className={'patientHisSelWrapper'}>
                            <Select dropdownStyle={{width:'fit-content'}} dropdownClassName={'patientHisSel'}
                                    value={''}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onChange={(id,option)=>{
                                      const patient = _.find(patientHistory, { id });
                                      handlePatientChange(patient);
                                    }}>
                                {
                                  _.map(patientHistory,(history)=> {
                                    const { id, name } = history || {}
                                    if (!id) return null;
                                    return (<Select.Option value={id} >{name || '--'}</Select.Option>);
                                  })
                                }
                            </Select>
                        </Tooltip> :''
                }
                        <div className={'patientName current'}>
                            <span>{getPatientInitial(_.get(user,'profile.fullName'))}</span>
                        </div>
                {/*{ this.renderBillableTime() }*/}
               </div>
    }

    handlePatientChangeWrapper = (data)=>{
        handlePatientChange(data);
    }

    checkIfShowMTPRPrompt = (cancelMTPRCb) => {
      const {shouldBlockNavigation, cancelBlockNavigation} = this.state;
      const { shouldRemoveMTPRBlock } = this.props;
      if(shouldBlockNavigation && !cancelBlockNavigation && !shouldRemoveMTPRBlock){
        this.setState({visible: true, cancelMTPRCb});
        return true;
      }
      return false;
    }

    handleNextPatient = async ()=>{
        if (this.checkIfShowMTPRPrompt(this.handleNextPatient)) 
          return;
        
        if(this.state.showUnsavedModal) {
            const resp = await new Promise((resolve, reject) => Modal.confirm({
                title: 'Are you sure you want to leave this page?',
                content: <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 600, marginTop: 8, marginBottom: 4 }}>
                    You have unsaved changes. Your changes will be lost if you navigate away from this page.
                  </span>
                </div>,
                onOk: () => {
                    resolve(true)
                    this.setState({showUnsavedModal : false});
                },
                onCancel: () => resolve(false)
            }));
            if(!resp)
                return;    
        }
        Mixpanel.calculateDuration('workstation next patient');
        Mixpanel.time_event('workstation next patient');
        this.setState({ loadingPatient: true, visible: false, cancelMTPRCb: null });
        const response = await top();
        this.setState({ loadingPatient: false });
        const { data } = response;
        if (!data) {
          const nextCaseCount = await this.getTotalPatientCase();
          if (nextCaseCount === 0) {
            message.success('Great, there is no patient left.');
          } else {
            message.error('Something went wrong when loading next patient. Please try again later!');
          }
          return;
        }
        preFetchChatHistory(response);
        const nextPatientId = _.get(data,'id');
        const currentPatientId = _.get(this,'props.user.id');
        const currentPatientName = _.get(this,'props.user.profile.fullName');
        if(nextPatientId&&nextPatientId == currentPatientId ) {
            Modal.confirm({
                title: 'You have unfinished action items that are still high priority for this patient.',
                cancelText: "Postpone",
                okText: "Stay on this patient",
                onOk:()=>{},
                onCancel:()=>this.handlePostponePatient(currentPatientId),
                content: <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Please postpone this patient if you cannot resolve the remaining action items.</span>
                </div>
            });
        }else {
          // this.props.stopTimer('save', () => handlePatientChange(data));
          handlePatientChange(data)
        }
        Mixpanel.track('clicked', 'next_patient', 'workstation');
    }

    renderToolBar = ()=>{
        const { id } = this.props.params;
        const { pinnedPatients } = this.props;
        const { showPostponePopover,loadingPatient,totalPatientCase,pinnedBy=[]} = this.state;
        const isPinned = checkIsPinned(pinnedPatients,id);
        const content = <div>
            <span>You will leave this patient case and it will not show until after the last priority case.</span>
            <div className={'btnInPostpone'}>
                <div className={'cancelDiv'} onClick={()=>this.setState({ showPostponePopover:false })}>
                    Cancel
                </div>
                <Button className={'postponeBtn'} onClick={()=>{
                                                                 Mixpanel.track('clicked', 'yes', 'postpone_icon');
                                                                 this.handlePostponePatient(id);
                                                               }}
                                                            type={'primary'}>
                    Yes, postpone
                </Button>
            </div>
        </div>
        return <div className={'toolbar'}>
                <Popover
                    trigger={'click'}
                    overlayClassName={'postponePopover'}
                    placement={'rightBottom'}
                    title={'Are you sure you want to postpone this patient case?'}
                    content={content}
                    visible={showPostponePopover}
                >
                    <Tooltip overlayClassName={'tool-tip-customized'} title={'Postpone'}><img onClick={ ()=>this.setState({ showPostponePopover:true })} className={'postponeIcon'} src={`/image/postpone.svg`}/></Tooltip>
                </Popover>
                <Tooltip overlayClassName={'tool-tip-customized'} title={isPinned ? 'Unpin': 'Pin'}>
                    <img onClick={ ()=>{
                                         Mixpanel.track('clicked', 'on_pin_patient_icon');
                                         this.handlePinBtn(isPinned,id);
                                       }}
                        className={'pinIcon'} src={`/image/${isPinned ? 'unpin' : 'pin'}.svg`}/>
                </Tooltip>
                <Button loading={loadingPatient} disabled={loadingPatient || totalPatientCase === 0} onClick={ this.handleNextPatient }>Next Patient &gt;</Button>
               </div>
    }

    getStickyStyle = () => {
        return {
            position: 'sticky',
            top: this.isSingle ? 0 : 108,
            zIndex: 1,
        };
    }

    getTasksCount = (props = this.props) => {
        const { unratedFoodLogs, unresolvedTasks, unresolvedAlerts, missingInfo=[], params = {} } = props;
        const { role } = this.state;
        const memberId = params.id;
        const hasUnread = ifUserHasUnread(memberId);
        const hasMissingInfo = _.find(missingInfo,m=>m.status==='INIT');
        const shouldViewMissingInfo = VIEW_MISSINGINFO_ROLE.includes(role);
        const missingInfoCount = !!hasMissingInfo && shouldViewMissingInfo;
        const newMonthlyReview = this.getShowMonthlyReviewStatus(props);

        return { taskCount: unratedFoodLogs + unresolvedTasks + unresolvedAlerts + hasUnread + newMonthlyReview + missingInfoCount,hasUnread } ;
    }

    getShowMonthlyReviewStatus = (props = this.props, state) => {
        const { monthlyReviewStatus, systemGenerateMonthlyReviewData } = props;
        const { id, status } = systemGenerateMonthlyReviewData || {};
        return (
          (id && checkIfMonthlyReviewInCompleted(status))
          || ([monthlyReviewStatusMap.IN_PROGRESS].includes(monthlyReviewStatus))
        );
    }

    getFinishedMessage = () => {
        const { loadingPatient, totalPatientCase } = this.state;
        const memberId = this.props.params.id;

        const hasMoreCases = totalPatientCase > 0;
        const hasUnread = ifUserHasUnread(memberId);

        return (
            <div>
                <div style={{ marginBottom: 10 }}>{ hasUnread ? I18N.get('workStation.unReadMessage') : (I18N.get('workStation.message') + (hasMoreCases ? '' : I18N.get('workStation.noMorePatientText')) )}</div>
                {   hasUnread ? '' :
                    hasMoreCases
                    ? <Button block type="primary" className="new-style" loading={loadingPatient} disabled={loadingPatient} onClick={this.handleNextPatient}>Next Patient</Button>
                    : <Button block type="primary" className="new-style" onClick={() => goPath('/workstation')}>Go back to work station dashboard</Button>}
            </div>
        );
    }

    setShouldLeave = () => {
        this.setState({shouldBlockNavigation: true});
    }
    render() {
        const { loadingUser, loadingUnresolvedTasks, loadingUnresolvedAlerts, loadingUnresolvedFoodLogs, billableTime, currentProgram, currentProgramLoading } = this.props;
        const stickyStyle = this.getStickyStyle();
        const { id, enrolledProgramId } = this.props.params;
        const hasTasks = this.getTasksCount().taskCount > 0;
        const hasUnread = this.getTasksCount().hasUnread;
        const isLoadingTasks = loadingUnresolvedTasks || loadingUnresolvedAlerts || loadingUnresolvedFoodLogs;

        const showOnlyPatientProfile = _.get(currentProgram, 'status') !== 'STARTED' || (this.isSingle && !hasTasks);
        const { tabSelected,others=[],pinnedBy=[] } = this.state;

        const profileTabSelected = (tabMap[tabSelected || this.getDefaultActiveKey()] || {}).profileTabKey;

        if (loadingUser || currentProgramLoading) return <Spin />;

        return <div className='providerSmartAlertResolveOutter taskResolveContainer'>
            <TimerClaimedContainer patientId={id} id={TASK_RESOLVER_TIMER} />
           {!this.isSingle && <Col span={24} className="patientsHeader">
                { this.renderCases() }
                <Col span={24}><hr style={{ margin: '0 60px', backgroundColor: 'gray'  }} /></Col>
                { this.renderPatients() }
            </Col>}
            <Row>
                <Col span={24}>{ others.length ? this.renderOthersWorkingOn() :'' }</Col>
                <Col span={24}>{ pinnedBy.length ? this.renderPinnedBy():'' }</Col>
            </Row>
                <MTPRModal
                    visible = {this.state.visible}
                    handleOk={this.routerWillLeaveClickOK}
                    handleCancel={this.rounterWillLeaveClickCancel}
                    />
            <Row className="resolveWrapper">
                {!showOnlyPatientProfile &&
                  <Col
                    span={6}
                    style={{ ...stickyStyle, zIndex: 501, width: 'var(--provider-task-resolve-wrapper-width)' }}
                  >
                    <div className='providerSmartAlertResolveWrapper'>
                        { !this.isSingle && this.renderToolBar() }
                        { hasTasks &&
                            <React.Fragment>
                                { this.renderTabs() }
                            </React.Fragment>
                        }
                        <TaskCompletedMessage
                            show={!isLoadingTasks && !hasTasks || hasUnread}
                            showChat={ hasUnread }
                            imageSrc={ "/image/workstation/good-job.png" }
                            message={ "Good Job!" }
                            subMessage={this.getFinishedMessage()}
                        />
                    </div>
                </Col>}
                <Col
                  span={showOnlyPatientProfile ? 24 : 18}
                  style={{
                    ...stickyStyle,
                    width: !showOnlyPatientProfile ?
                            'calc(100% - var(--provider-task-resolve-wrapper-width))' : '100%'
                  }}
                  className={showOnlyPatientProfile ? 'single-no-task' : ''}
                >
                    <OMRONContextProvider
                      patientId={id}
                    >
                      <PatientPage enrolledProgramId={enrolledProgramId || 'default'}
                                  isWorkStation={!this.isSingle}
                                  timerName={TASK_RESOLVER_TIMER}
                                  {...this.props} isInChat={true}
                                  patientId={id}
                                  chatClassName={''}
                                  setTimeSpentInMin={this.setTimeSpentInMin}
                                  profileTabSelected={profileTabSelected}
                      />
                    </OMRONContextProvider>
                </Col>
            </Row>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    const patientId = ownProps.params.id;
    // const timerId = getTimerClaimedId(TASK_RESOLVER_TIMER, patientId);
    return {
        patientHistory: _.get(state,'workstation.main.patientHistory'),
        patientIdToChannel: state.chat.main.patientIdToChannel,
        monthlyReviewStatus: _.get(state,'workstation.main.monthlyReviewStatus'),
        shouldRemoveMTPRBlock: _.get(state,'Logout.shouldRemoveMTPRBlock')
    };
}
const mapToDispatch = (dispatch, ownProps) => {
    const patientId = ownProps.params.id;
    return {
        addPatientToHistory: (patient)=>dispatch(addPatientToHistory(patient)),
        setMonthlyReviewStatus: (status)=>dispatch(setMonthlyReviewStatus(status)),
        setMonthlyReviewType: (type) => dispatch(setMonthlyReviewType(type)),
        setMonthlyReviewOpen: (showMonthlyReviewTab) => dispatch(setMonthlyReviewOpen(showMonthlyReviewTab)),
        ...mapDispatchToTimerActions(dispatch, TASK_RESOLVER_TIMER, patientId)
    }
}


const TaskResolveWrapper = compose(
  connect(mapStateToProps, mapToDispatch),
  missingInfoListAPI,
  withEnrolledCurrentProgram,
  unratedFoodLogs,
  unresolvedTasks,
  unresolvedSmartAlerts,
  miniUserAPI,
  billableTimeQuery,
  withFutureFollowUpVisitCount,
)(
  withRouteLeaveHooks(
    wrapMonthlyReviewContext(TaskResolveContainer)
  )
);

export default (props) => {
    const [pinned, setPinned] = useState([]);
    const userRole = getUserRole();
    
    const getPinnedPatients = () => {
        pinnedPatients().then(res => {
            setPinned(res.data.map(p => p.patient))
        });
    }
    
    useEffect(() => {
        const userId = props.params.id;
        if (['MA', 'MD'].includes(userRole)) {
            goPath(`/provideralert/${userId}/single`);
        }
    }, [userRole]);

    useEffect(() => {
        getPinnedPatients();
    }, []);

    return <TaskResolveWrapper {...props} key={props.params.id} pinnedPatients={pinned} refetchPinnedPatients={getPinnedPatients} userRole={userRole}/>;
}