import { constants } from 'libModule/utils';

export default constants('Broadcast', 'EditBroadcast', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'SET_SUBMITTING',
  'CHANGE',
  'UPDATE_FORM'
])
