import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { InsuranceCopayCheckboxFormItemComponent } from './InsuranceCopayCheckboxFormItemComponent';
import { InsuranceCopayInputFormItemComponent } from './InsuranceCopayInputFormItemComponent';
import getPatientInsurance from './hooks/getPatientInsurance';

import './InsuranceCopayComponent.scss';

export const InsuranceCopayComponent = (
  props,
) => {
  const {
    form,
    patientId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [hasCopay, setHasCopay] = useState();
  const [copay, setCopay] = useState();

  useEffect(() => {
    if (patientId) {
      (async () => {
        setIsLoading(true);
        const res = await getPatientInsurance({ patientId });
        if (res && res.code !== 200) {
          message.error('Failed to get patient insurance'.concat(err));
        } else {
          const { hasCopay, copay } = res.data || {};
          setHasCopay(hasCopay);
          setCopay(copay);
        }
        setIsLoading(false);
      })()
    }
  }, [patientId]);

  return !isLoading
    ? (
      <div className="insurance-copay-component">
        <InsuranceCopayCheckboxFormItemComponent
          form={form}
          initialValue={hasCopay}
        />
        <InsuranceCopayInputFormItemComponent
          form={form}
          initialValue={copay}
        />
      </div>
    ) : null;
};
