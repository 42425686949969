import types from 'patientModule/enrolledProgramInfo/Careplan/constants/VitalListConstants'
import { convertDBToVitalList, addVitalDataToList } from 'modulesAll/utils/helpers/task'

// Default State
const initState = {
  data: []
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        data: convertDBToVitalList(action.selectedProgram)
      }
    case types.ADD_VITAL_DATA:
      return addVitalDataToList(state, action.fieldsValue)
    default:
      return state
  }
}
