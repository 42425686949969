import React from "react";
import { Form,Row,Col,Button,Modal } from 'antd';
import { message } from 'ihcomponent';
import { GQLHelper } from 'libModule/utils';
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import Client from 'libModule/gqlClient';
import unassignLoanDevice from "../mutate/unassignLoanDevice";
import getLoanDevice from "../query/getLoanDevice";
import '../styles/index.scss';

let  RenderAndUpdateLoanDevice = class extends React.Component {
    constructor(){
        super();
    }

    renderDeviceInfo(getLoanDevice){
        console.log(getLoanDevice);
            const { deviceId,serialNumber,osVersion,appVersion } = getLoanDevice;
            return  <Row className='renderLoanDeviceInfo'>
                        <Col span={14}><strong>Device ID: </strong>{deviceId}</Col>
                        {/* <Col span={18}><strong>Serial Number:</strong>{serialNumber}</Col>
                        <Col span={18}><strong>OS Version:</strong>{osVersion}</Col>
                        <Col span={18}><strong>App Version:</strong>{appVersion}</Col> */}
                        <Col span={6}>{this.renderUnassignButton(deviceId)}</Col>
                    </Row>


    }

    async unassignDevice(deviceId){
        const { openErrorModal,setHasDevice,refetchLoanDevice,setInputFieldDisabled } = this.props;
        const memberId = _.get(this.props,'data.user.id') || _.get(this.props, 'patientId');
        let variables = {
            deviceId,
            memberId
        }

        return new Promise((resolve,reject)=> {
            Modal.confirm({
                title:  'Unassign Device for this patient?',
                onOk: ()=>
                    Client.mutate({
                        mutation:unassignLoanDevice,
                        variables,
                        refetchQueries:[{
                        query:getLoanDevice,
                        variables:{
                                memberId
                        },
                        fetchPolicy:'network-only',
                        notifyOnNetworkStatusChange: true
                        }]
                    })
                    .then(res => {
                    message.success('Device Unassigned');
                    resolve(true);
                }).catch(e => {
                    console.log(e);
                    openErrorModal(GQLHelper.formatError(e))
                }),
                okText: "Unassign Device",
                cancelText: 'Cancel',
                onCancel: ()=>{
                    resolve(true);
                }
            })
        })

    }

    renderUnassignButton(d){
        // const { disabled }  = _.get(this,'props.state.loanDeviceInfo');
        const { disabled }  = this.props;
        return <Button disabled={disabled}
                       onClick={()=>this.unassignDevice(d)}
                       className='care-plan-edit-btn ih-exsmall IH-Button'
                       style={{marginTop:-5}}>
            Unassign Devices
        </Button>
    }

    render(){
        const { getLoanDevice } = this.props;
        console.log(this.props);
        return this.renderDeviceInfo(getLoanDevice||{});
    }
}

RenderAndUpdateLoanDevice = Form.create({})(RenderAndUpdateLoanDevice);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
export  default connect(null,mapToDispatch)(RenderAndUpdateLoanDevice);
