import React, { Component } from 'react';
import { Col, Row, Button, Form, Modal, Popconfirm, Input, Radio, Tooltip, Popover, Divider } from "antd";
import helper from '../../VideoChat/API';
import I18N from "../../I18N";
import moment from 'moment';
const { Item } = Form;
import { mobileValidator } from 'libModule/validator';
import { formatFieldLabels, renderMobileForm } from "../../patient/AdditionalInfo/helpers";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../../MAPatient/helper';
import Client from 'libModule/gqlClient';
import editUser from 'modulesAll/graphql/mutation/editUser';
import VisitRemoteEnrollCheckBox from "./VisitRemoteEnrollCheckBox";

class VisitInputBottomButton extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileVisible: { webLink: false, appLink: false },
            mobilePhone: null,
        }
        this.mobilePhoneRules = [
          { validator: (rule, value, callback) => callback(mobileValidator(value)) }
        ];
        this.getPatient = () => {
          return {..._.get(props,'visitInfo.member') || _.get(props,'patient.user')};
        };
    }

    getPatientMobilePhone = (phoneList) => {
      const { phone: defaultPhoneList = [] } = this.getPatient();
      const currentPhoneList = phoneList || defaultPhoneList;
      return {..._.find(currentPhoneList, { type: 'MOBILE' })};
    }

    onMobileVisibleChange = (key, mobileVisible) => {
      const { setFields, resetFields, setFieldsValue } = this.props.form;
      this.setState({ 
        mobileVisible: { [key]: mobileVisible }
      });
      if(!mobileVisible) {
        resetFields(['mobilePhone', 'canUseForLogin']);
        setFields({ mobilePhone: { errors: null }});
      }
    }

    handleOnClick = (visitId)=>{
        const { setFields, validateFields, getFieldValue } = this.props.form;
        validateFields(['mobilePhone'], (err) => {
          if(err) return;

          const phoneNumber = getFieldValue('mobilePhone');
          if(!phoneNumber) 
            return setFields({ 
              mobilePhone: { errors: [new Error(I18N.get('validator.mobile.required'))] }
            });

          helper.sendOneClickMeeting({visitId,phoneNumber})
          .then(res=>{
              this.onMobileVisibleChange('webLink', false);
              Modal.success({title:'Web video link SMS has been sent!'});
          })
          .catch(e=>{
              Modal.error({ content : e.message });
          });
        });
    }

    handleSendLink = (memberId, patient)=>{
        const { setFields, validateFields, getFieldsValue } = this.props.form;
        let { canUseForLogin, mobilePhone } = getFieldsValue();
        validateFields(['mobilePhone'] ,(err) => {
          if(err) return;

          if(!mobilePhone) 
            return setFields({ 
              mobilePhone: { errors: [new Error(I18N.get('validator.mobile.required'))] }
            });
           
          let { phone = [] } = patient;
          const newPhone = _.map(phone, p => _.omit(p, ['__typename', 'verified']));
          const hasMobilePhone = !!this.getPatientMobilePhone(newPhone).number;
          let newObj = {};
          if(!hasMobilePhone) {
            newObj = {
              countryCode : '1',
              type : 'MOBILE',
              number : mobilePhone,
              canUseForLogin,
              private: false
            };
            newPhone.push(newObj);
          } else {
            const mpIndex = _.findIndex(newPhone, { type: 'MOBILE' });
            newPhone[mpIndex].number = mobilePhone;
            newPhone[mpIndex].canUseForLogin = canUseForLogin;
          }
          Client.mutate({ mutation:editUser, variables:{phone:newPhone,id:memberId}})
          .then(res => {
              // mutate directly
              if(!hasMobilePhone) {
                phone.push(newObj);
              } else {
                _.forEach(phone, p => {
                  if(p.type=='MOBILE'){
                    p.number = mobilePhone;
                    p.canUseForLogin = canUseForLogin;
                  }
                });
              }
              API.sendInvitationLinkMutation({memberId}, () => {
                this.onMobileVisibleChange('appLink', false);
              });
              Mixpanel.track('clicked','send download app link','edit Visit Popup');
          })
          .catch(error=>{
              Modal.error({ content: error.message });
          });
        });
    }

    renderOnClickVideoButton = ()=>{
      const { props,renderPhone } = this;
      const appointmentAt = _.get(props,'visitInfo.appointmentAt');
      const isToday = moment().isSame(appointmentAt,'day');
      const visitId = _.get(this,'props.visitInfo.id');
      const onVisibleChange = visible => {
        Mixpanel.track('clicked','send virtual visit web link','edit visit popup');
        this.onMobileVisibleChange('webLink', visible);
      }
      if(isToday) 
        return <Col span={24} className='downloadDiv'>
          <Popconfirm 
            title={renderPhone()} 
            placement='rightBottom'
            overlayStyle={{width: '295px'}}
            visible={!!this.state.mobileVisible.webLink}
            onClick={() => onVisibleChange(true)}
            okText='Send out web video link'
            onConfirm={()=>this.handleOnClick(visitId)}
            onCancel={() => onVisibleChange(false)}
          >
            <span  style={{cursor:'pointer',textDecoration:'underline', color: '#3177C9'}}>
              {I18N.get('visit.details.webLink')}
            </span>
          </Popconfirm>
        </Col>;
    }

    renderPhone=()=>{
        const { getFieldDecorator } = this.props.form;
        // const value = mobilePhone.canUseForLogin;
        return (
            <Row className='sendVideoLinkDiv'>
                <Col span={24}>Mobile Phone</Col>
                <Col span={24}>
                    <Item >
                        {getFieldDecorator('mobilePhone', {
                            initialValue: this.getPatientMobilePhone().number,
                            rules: this.mobilePhoneRules
                          })
                        (
                            <Input/>
                        )}
                    </Item>
                </Col>
            </Row>
        )

    }

    renderAppDownloadLink = () => {
      const patient = this.getPatient();
      const memberId = patient.id;
      return (
        <Col span={24}>
          { 
            <Popover 
              overlayStyle={{width: '395px', height: '268px'}}
              visible={!!this.state.mobileVisible.appLink} 
              onClick={() => this.onMobileVisibleChange('appLink', true)}
              trigger='click'
              content={this.renderUpdateMobile(memberId, patient)}
            >
              <span style={{textDecoration: 'underline', cursor: 'pointer', color: '#3177C9'}}>
                {I18N.get('visit.details.appLink')}
              </span>
            </Popover> 
          }
        </Col>);
    };

    renderUpdateMobile = (memberId, patient)=>{
      const patientMobilePhone = this.getPatientMobilePhone();
      const { number, canUseForLogin } = patientMobilePhone;
        const config = [
            [
                {
                    key: 'mobilePhone',
                    label: 'Message will be sent to the following ' + formatFieldLabels('mobilePhone','mobilePhone'),
                    formatValue: () => number,
                    span: 24,
                    inputContent:({disabled})=><Input disabled={disabled} style={{width: '200px'}}/>,
                    rules: this.mobilePhoneRules
                },
                {
                    key: 'canUseForLogin',
                    label: (<div className='otp-login-label' style={{display: 'flex'}}>
                      {formatFieldLabels('canUseForLogin', 'canUseForLogin')}
                      <Tooltip 
                        trigger='click'
                        overlayClassName='visit-bottom-otp-tip'
                        title={I18N.get('OTPLogInInfo')}
                      > 
                        <div style={{marginLeft: 10, fontSize: 12, textDecoration: 'underline', color: '#717171', cursor: 'pointer' }}>
                          What is OTP log in?
                        </div>
                      </Tooltip>
                    </div>),
                    type: 'radioButton',
                    span: 24,
                    formatValue: () => number ? canUseForLogin : true,
                    style:{float:'left',width:'100%'},
                    inputContent:({disabled})=> <Radio.Group disabled={disabled}>
                        <Radio value={true}>On</Radio>
                        <Radio value={false}>Off</Radio>
                    </Radio.Group>,
                },
                {
                    key: 'button',
                    label: `    `,
                    type: 'button',
                    span: 6,
                    className: 'radio-button-2-option',
                    style:{float:'left',width:'100%'},
                    colStyle: { marginRight: 5 }, 
                    inputContent:()=><Button type='primary' block onClick={()=>this.handleSendLink(memberId,patient)}>Send</Button>
                },
                {
                    key: 'button',
                    label: `    `,
                    type: 'button',
                    span: 6,
                    style:{float:'left',width:'100%'},
                    inputContent:()=><Button type='text' onClick={()=>this.onMobileVisibleChange('appLink', false)}>Nevermind</Button>
                },
            ]
        ];

        return renderMobileForm(this, config);
    }
    
    render() {
        const { props, renderOnClickVideoButton, renderAppDownloadLink } = this;
        const { editStatus, remoteEnrollment, isRemoteEnrollment, form } = props;
        return (
          <Row className='visit-bottom'>
              <Divider></Divider>
              <span className='ant-form-item-label'>
                <label className='ant-form-item-no-colon'>
                  More Options
                </label>
              </span>
              { editStatus && renderOnClickVideoButton() }
              { renderAppDownloadLink() }
              <Col span={24}>
                  { 
                    remoteEnrollment ? 
                      <span>This patient is remote enrollment patient</span> 
                      : 
                      <VisitRemoteEnrollCheckBox 
                        form={form} 
                        isRemoteEnrollment={isRemoteEnrollment}
                      /> 
                  }
              </Col>
          </Row>
        );
    }

}

export default VisitInputBottomButton;
