import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tabs } from 'antd';
import { IHLoading } from 'ihcomponent';

import { RPM_TAB_TITLE_PLACEHOLDER } from '../constants';
import ReviewPatientTabContainer from './ReviewPatientTabContainer';
import { RouteContextProvider } from '../helpers/useRouteContext';
import ReviewPatientClinicContainer from './ReviewPatientClinicContainer';

import '../styles/ReviewPatientPageContainer.scss';

const ReviewPatientPageContainer = ({
  route,
}) => {
  const [rpmPatientListCount, setRPMPatientListCount] = useState(0);
  // for first time select boosted organizations
  const [reloadTabs, setReloadTabs] = useState(false);

  const handleOnSubmitClinic = () => {
    // simulate reload component
    setReloadTabs(true);
    setTimeout(() => setReloadTabs(false), 200);
  };
  
  return (
    <div className="review-patient-page-container">
      <RouteContextProvider value={route}>
        <Row className="review-patient-page-container__header">
          <Col className="review-patient-page-container__header__title">
            <h4>Patient List</h4>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <ReviewPatientClinicContainer onSubmit={handleOnSubmitClinic} />
          </Col>
        </Row>
        {
          !reloadTabs
          ? (
            <Tabs>
              <ReviewPatientTabContainer
                key="rpmPatientList"
                tab={RPM_TAB_TITLE_PLACEHOLDER.replaceAll('[count]', rpmPatientListCount)}
                setRPMPatientListCount={(count) => setRPMPatientListCount(count || 0)}
                isActive
              />
            </Tabs>
          ) : <IHLoading />
        }
      </RouteContextProvider>
    </div>
  );
};

ReviewPatientPageContainer.propTypes = {
  route: PropTypes.object.isRequired,
};
export default ReviewPatientPageContainer;