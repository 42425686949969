import React from 'react';
import MNTReferralFormComponent from '../component/MNTReferralFormComponent';
import { Icon } from 'antd';
const MNTReferralListFormContainer = class extends React.Component{
	constructor(props) {
		super(props);
	}

	renderSingleForm = ()=>{
		const { selectedUser } = this.props;
		return <MNTReferralFormComponent selectedUser={selectedUser}/>
	}

	changeSelectedUser = ({ index,userList,setSelectedUser })=>{
		console.log(index,userList);
	}

	renderPreNext = ()=>{
		const { selectedUser,setSelectedUser,index,userList=[] } = this.props;
		const { changeSelectedUser } = this;
		const uLen = userList.length;
		const isFirst = index==0;
		const isLast = index==uLen -1;
		return <div>
				  { !isFirst&&<Icon type="left-circle" onClick={()=>changeSelectedUser({ index:index-1,userList })}/> }
				  { !isLast&&<Icon type="right-circle" onClick={()=>changeSelectedUser({ index:index+1,userList })}/> }
			   </div>
	}

	render() {
		const { renderSingleForm,renderPreNext } = this;
		return <div>
				{ renderPreNext() }
				{ renderSingleForm() }
			   </div>
	}
}

export default MNTReferralListFormContainer;