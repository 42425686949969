/**
 * Created by zizhangai on 6/22/17.
 */
import config from 'libModule/config'
import I18N from 'modulesAll/I18N';
import { createAuditLog } from 'libModule/utils/auditLog'
import { decryptRole } from 'libModule/utils';
import { getAPI_KEY } from 'modulesAll/utils/helpers/API_KEY'

const redirect = () => {
  createAuditLog({
    action: I18N.get('auditLog.superadmin.audittrail')
  })

  const myHeaders = new Headers();

  myHeaders.append('x-session-token', sessionStorage.getItem('authToken'));
  const userRole = decryptRole();
  const API_KEY = getAPI_KEY(window.VSM_CONFIG.NODE_ENV, userRole)
  myHeaders.append('x-api-key', API_KEY);
  let token
  fetch(`${config.SERVER_URL}/graylogSSO?timestamp=${+new Date()}`, {
    method: 'GET',
    headers: myHeaders
  })
    .then(res => res.text())
    .then(res => {
      token = res
      const aTag = document.createElement('form');
      aTag.setAttribute('action', `/redirect?key=${token}`);
      aTag.setAttribute('target', '_blank');
      aTag.setAttribute('method', 'POST');
      document.body.appendChild(aTag);
      aTag.submit();
      document.body.removeChild(aTag);
    })
}

export default redirect
