import React from 'react';
import moment from "moment/moment";
import ScheduleVisitsContainer from '../container/ScheduleVisitsContainer';
import {DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const ScheduleVisitContainerWrapper = class extends React.Component{
    constructor(){
        super();
        this.state = {
            defaultStartDay: moment(new Date()).startOf('day'),
            defaultEndDay: moment(new Date()).endOf('day')
        }
    }
    // renderRangePicker = ()=>{
    //     const { defaultStartDay,defaultEndDay } = this.state;
    //     return <RangePicker style={{ marginRight:20 }}
    //                         defaultValue={[defaultStartDay, defaultEndDay]}
    //                         onChange={(dateRangeToFilter)=>this.setState({
    //                             dateRangeToFilter
    //                         })}/>
    // }
    onChangeRangePicker = (dateRangeToFilter)=>{
        this.setState({ dateRangeToFilter })
    }

    render(){
        const { onChangeRangePicker } = this;
        const { dateRangeToFilter,defaultStartDay,defaultEndDay } = this.state;
        return <div>
                <ScheduleVisitsContainer dateRangeToFilter={dateRangeToFilter} defaultStartDay={defaultStartDay} defaultEndDay={defaultEndDay} onChangeRangePicker={onChangeRangePicker}/>
              </div>
    }


}

export default ScheduleVisitContainerWrapper;
