import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import RecallOrTypicalTable from '../component/NutritionIntake/RecallOrTypicalTable';
import NutritionIntakeQuestionaire from '../component/NutritionIntake/NutritionIntakeQuestionaire';
import { MEAL_TYPES } from '../constant/nutritionIntakeConstants';
import { renderBodyInfo, parseNutritionVariables } from '../helpers/nutritionHelpers';
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils';
import editUser from 'graphqlModule/mutation/editUserNutrition';
import getVisit from '../../graphql/singleVisit';
import '../style/nutritionIntake.styles.scss';
import { NUTRITION_INTERPRETATION_FIELDS } from '../constant/nutritionInterpretationConstants';
import renderOverlayDiv from '../helpers/overLayButton';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { renderDraftSavedText } from '../../visitWorkList/helpers';


const  NutritionIntake = props => {
  const { 
    disabled = false, isEditMode = false, onChange: globalOnChange, 
    user, visit, enrolledProgramId, isWorkList, onTouch, setChildProps, 
    submitButtonText, afterSubmitSucc, afterSaveDraftSucc, openErrorModal, title,
  } = props;
  const userNutritionValues = _.get(user, 'profile.nutrition');
  const [nutritionValues, setNutritionValues] = useState(userNutritionValues || {});
  const [intakeNote, setIntakeNote] = useState(_.get(nutritionValues, 'intakeNote') || '');
  const [hasIntakeNote, setHasIntakeNote] = useState(!_.isEmpty(intakeNote));

  useEffect(() => {
    if(!_.isEqual(nutritionValues, userNutritionValues)) {
      setNutritionValues(userNutritionValues);
    }
    const newNote = _.get(userNutritionValues, 'intakeNote');
    if(!_.isEqual(intakeNote, newNote)){
      setIntakeNote(newNote);
      setHasIntakeNote(!_.isEmpty(newNote));
    }
  }, [userNutritionValues]);

  const visitRefetchQuery = [{ 
    query: getVisit,
    variables: { id: _.get(visit, 'id') },
    fetchPolicy: 'network-only'
  }];

  // using debounce to reduce multiple updates when typing input fields
  const setHasChanges = changeValues => {
    const allValues = { ...nutritionValues, ...changeValues };
    if(globalOnChange) {
      globalOnChange({ 
        shouldEditUser: true,
        editUserVar: parseValue(allValues),
        cb: allValues => {
          const newIntakeNote = _.get(allValues, 'intakeNote', '');
          setHasIntakeNote(!_.isEmpty(newIntakeNote));
        },
        cbVar: allValues
      });
    } else if(isWorkList && onTouch) {
      if(!isEditMode) onTouch();
      setChildProps(
        allValues,
        parseValue,
        editUser,
        visitRefetchQuery,
        NutritionIntake.title
      );
    }
    setNutritionValues(allValues);   
  };

  const parseValue = (allValues = nutritionValues) => ({
    id: _.get(user, 'id'),
    memberProfile: {
      birthday: _.get(user, 'profile.birthday'), // required by editUser
      nutrition: parseNutritionVariables(
        userNutritionValues,
        // prevent overwriting other Nutrition values
        _.omit(allValues, NUTRITION_INTERPRETATION_FIELDS) 
      ),
    }
  });

  const handleSave = _.debounce(actionType => Client.mutate({
    mutation: editUser,
    variables: parseValue(),
    refetchQueries: actionType === 'draft' ? visitRefetchQuery : []
    // auto-update values in patient profile, via cache
  }).then(__ => {
    switch(actionType){
      case 'draft':
        setHasIntakeNote(!_.isEmpty(intakeNote));
        return afterSaveDraftSucc();
      case 'complete':
        let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
        Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
        return afterSubmitSucc();
      default:
        return console.warn(`Missing action when handing save in ${NutritionIntake.displayName}`);
    }
  }).catch(err => {
    console.error(err);
    if(openErrorModal) openErrorModal(GQLHelper.formatError(err));
  }), 500);

  return (
    <div id='nutrition-intake-main-container'>
      {!enrolledProgramId && isWorkList && renderOverlayDiv(props)}
      <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.2 : 1 }}>
        { 
          isWorkList && 
          <div className="fix-header">
            <div className='fixHeaderTitle'>
              <div className='section-label-h3'>
                Nutrition Intake
              </div>
              {renderDraftSavedText()}
            </div>
            { renderBodyInfo(user) }
          </div>
        }
        <div className="scrollable-content">
          <div className='section-label'>24-hour Recall or Typical intake</div>
          <RecallOrTypicalTable 
            dataSource={_.get(nutritionValues, 'intake', [])} 
            setHasChanges={setHasChanges}
            disabled={disabled}
          />
          <NutritionIntakeQuestionaire 
            nutritionValues={nutritionValues || {}}
            MEAL_TYPES={MEAL_TYPES}
            disabled={disabled}
            setHasChanges={setHasChanges}
          />
          <div className='extra-notes-area-wrapper'>
            {
              !hasIntakeNote ?
                <Button
                  className='add-click'
                  type='link' icon='plus'
                  disabled={disabled}
                  onClick={() => setHasIntakeNote(true)}
                >
                  Add Note
                </Button>
              :
                <div className='extra-notes-textarea-wrapper'>
                  <div className='section-label'>Notes</div>
                  <Input.TextArea
                    disabled={disabled}
                    autoSize={{ minRows: 3 }}
                    value={intakeNote}
                    onChange={e => {
                      const newIntakeNote = e.target.value;
                      setIntakeNote(newIntakeNote);
                      setHasChanges({ intakeNote: newIntakeNote });
                    }}
                    placeholder='Add additional information here...'
                  />
                </div>
            }
          </div>
        </div>
        {
          isWorkList &&
          <div className='fix-footer'>
            {
              isEditMode &&
              <Button 
                disabled={disabled} 
                onClick={() => handleSave('draft')}
                className='saveDraftBtn'
              >
                Save Draft
              </Button>
            }
            <Button 
              disabled={disabled} 
              type='primary' 
              onClick={() => handleSave('complete')}
            >
              { submitButtonText || 'Complete Section' }
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

NutritionIntake.propTypes = {
  disabled: PropTypes.bool, // to toggle editmode in global
  isEditMode: PropTypes.bool, // to notify component has change
  onChange: PropTypes.func, // set queryVariables and editMode=>true
  /* for WorkList only */
  visit: PropTypes.object,
  showDiabetesAlert: PropTypes.bool,
  isWorkList: PropTypes.bool,
  onTouch: PropTypes.func,
  setChildProps: PropTypes.func,
  submitButtonText: PropTypes.string,
  afterSubmitSucc: PropTypes.func,
  afterSaveDraftSucc: PropTypes.func,
  openErrorModal: PropTypes.func,
  /*-------------------*/
  user: PropTypes.object.isRequired,
};

NutritionIntake.title = 'Nutrition Intake';
NutritionIntake.displayName = 'NutritionIntakeContainer';

export default NutritionIntake;
