import React from 'react';
import { Alert, Icon, Modal, Tooltip, Spin, Slider, Col, Row, Button } from 'antd';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import moment from 'moment';
import { connect } from 'react-redux';
import ClaimBillableTimeComponent from './ClaimBillableTimeComponent';
import { getMonthlyTimeSpent } from '../../TimeSpent/query';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { goPath } from 'libModule/utils'
import { calculateTotalTime } from '../../../timerClaimed/helpers';


const TIME_THRESHOLDS_IN_MIN = [
  { min: 0, max: 20, backgroundColor: ['#3177C9', '#D5E3F3', '#eeeeee'], arrowColor: 'blue', type: 'error', alertDescription: 'Time is NOT qualified to be billed. Please reach 20 min in order to bill the service.', color: '#DA6453', sliderClassName: 'low' },
  { min: 20, max: 22, backgroundColor: ['#13A9C1', '#CAEAEF', '#eeeeee'], arrowColor: 'green', type: 'success', alertDescription: 'Great! The time spent on this patient QUALIFIES as billable.', color: '#3BA767', sliderClassName: 'perfect' },
  { min: 22, backgroundColor: ['#13A9C1', '#CAEAEF', '#eeeeee'], arrowColor: 'blue-green', type: 'warning', alertDescription: 'Please be mindful of the time spent on this patient.', color: '#EF973B', sliderClassName: 'high' },
];

export const scaleLabelStyle = {
  fontSize: 15,
};

export const fontSize = 15;
export const chartBarColor = '#9fdef1';

class BillableTimeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccumulateInfoModal: false,
      currSessionTimeInMin: 0,
    };
  }

  get rangeObj() {
    const { timeInMinutes } = this.props;
    const { currSessionTimeInMin } = this.state;
    const total = timeInMinutes + currSessionTimeInMin;
    const _rangeObj = _.find(TIME_THRESHOLDS_IN_MIN, x => {
      if (x.max) {
        return total >= x.min && total < x.max;
      }
      return total >= x.min;
    });
    let totalPercentage;
    if (total <= 20) {
      totalPercentage = 70 * total / 20;
    } else {
      totalPercentage = 70 + (30 * (total - 20) / 2)
    }
    return { ..._rangeObj, total, totalPercentage };
  }

  componentDidMount() {
    this.updateUnreadCounter();
    this.interval = setInterval(() => {
      this.updateUnreadCounter();
    }, 60000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.timeInMinutes !== this.props.timeInMinutes ||
      prevState.currSessionTimeInMin !== this.state.currSessionTimeInMin) {
        const total = this.props.timeInMinutes + this.state.currSessionTimeInMin;
        if(this.props.setTimeSpentInMin) this.props.setTimeSpentInMin(total);
    } 
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  updateUnreadCounter = () => {
    const events = [..._.get(this.props, 'timerClaimed.allEvents', []), { event: 'current', timestamp: moment(), action: 'STOP_TIMER' }];
    // const currSessionTimeInMin = moment().diff(this.props.startTime, 'minute');
    const currSessionTimeInMin = Math.round(calculateTotalTime(events) / 60000);
    this.setState({ currSessionTimeInMin });
  }

  handleClick = () => {
    const patientId = _.get(this.props, 'user.id');
    Mixpanel.track('clicked', 'billable_time', 'patient_profile', { PATIENT_ID: patientId });
    goPath(`/patients/${patientId}/timespent/`);
  }
  
  renderAccumulateInfoModal = () => {
    const { showAccumulateInfoModal } = this.state;
    return (
      <Modal visible={showAccumulateInfoModal} width={600} onCancel={() => this.setState({ showAccumulateInfoModal: false })} footer={null}>
        <div>
          <h4 style={{ marginBottom: 20 }}>How is the time captured?</h4>
          <p>The total time spent on the patient is captured through the following 3 ways</p>
          <ul style={{ paddingLeft: 20 }}>
            <li style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Auto Captured:</span> The system tracks and captures the time you actively spend on
              the patient's profile and visits automatically.
            </li>
            <li style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Manually Added Idle Time:</span> When there is no activity captured while you are working on 
              the patient profile, the system will prompt you to adjust the time manually 
              before you leave the patient profile. 
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>Manually Added Time Spent Outside of the Portal:</span> You can manually add the
              time of the work that is done outside of the portal, such as working on EMR, 
              or research patient’s condition online etc.
            </li>
          </ul>
        </div>
      </Modal>
    );
  }

  renderAlert = () => {
    const { type, alertDescription } = this.rangeObj;
    return (
      <Alert showIcon type={type} message={alertDescription} className="billable-alert" />
    );
  }
  
  render() {
    const { showAddButton, user, refetchMonthlySpent, loading } = this.props;
    const { color, total, sliderClassName, totalPercentage } = this.rangeObj;

    const marks = {
      0: '0',
      70: '20',
      100: '22+',
    }
    
    if (loading) {
      return <Spin />;
    }
    
    return (
      <div className="billable-container" style={{ width: 350 }}>
        {this.renderAccumulateInfoModal()}
        <div style={{ color: 'black', fontSize: 14, marginBottom: 10 }}>
          Total Time Spent on this patient this Month
          <Tooltip title="How is time spent captured?">
            <Icon type="question-circle" style={{ fontSize: 11, marginLeft: 10 }} onClick={() => this.setState({ showAccumulateInfoModal: true })} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', marginTop: 20, alignItems: 'flex-end' }}>
          <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.handleClick}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 14 }}>
              <div style={{ lineHeight: '40px' }}><span style={{ fontSize: 34, fontWeight: 'bold', color, marginRight: 5 }}>{total}</span>min</div>
              <div style={{ lineHeight: '40px' }}><span style={{ color: 'var(--dark-blue)' }}><Icon type="arrow-up" />&nbsp;{this.state.currSessionTimeInMin}</span> min this session</div>
            </div>
            <Slider marks={marks} value={totalPercentage} className={sliderClassName} />
          </div>
          <div style={{ marginLeft: 5, paddingBottom: 23 }}>(min)</div>
        </div>
        <div style={{ marginTop: 30 }}>{this.renderAlert()}</div>
        <hr style={{ margin: '20px 0'}} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: 14 }}>Working outside the portal?</div>
          <div>
            {showAddButton && 
              <ClaimBillableTimeComponent style={{ display: 'inline-block'}} patientId={user.id} user={user} refetchMontlySpent={refetchMonthlySpent}>
                &nbsp;<Button type="default" className="new-style">Add more time</Button>
              </ClaimBillableTimeComponent>
            }
          </div>
        </div>
      </div>
    );
  }
}

const withData = graphql(getMonthlyTimeSpent, {
  options: (ownProps) => {
    const monthOfYear = Number(moment().format('YYYYMM'));
    return {
      variables:{
        memberId: ownProps.user.id,
        monthOfYear
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data:{ getMonthlyTimeSpent: monthlyTimeSpent, loading, refetch }}) => {
    if (loading) {
      return {
        loading,
      };
    }
    return {
      timeInMinutes: Math.round(_.get(monthlyTimeSpent, 'totalTime') / 60),
      refetchMonthlySpent: refetch,
    };
  }
});

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.user.id;
  return  { timerClaimed: (_.find(_.get(state, 'timerClaimed.main'), (v) => v.patientId === userId && v.isDone === false) || {}) };
}
export default compose(connect(mapStateToProps), withData)(BillableTimeComponent);