import {React, BaseComponent, _} from 'ihcomponent';
import PropTypes from 'prop-types';

const EnrolledOverallStatsComponent = class extends BaseComponent {
  static displayName = 'dashboard/components/EnrolledOverallStatsComponent'

  constructor(props) {
    super(props);
  }
  render() {
    const { enrolledStats } = this.props;
    return (
      <div className="enrolled-statistics-legend">
        <div style={{textAlign: 'left'}}>
          <div className='population-title'>Population</div>
          <div className='enrolled-stats-number'>{ enrolledStats && enrolledStats.total }</div>
          <div className='total-enrolled-title'>Total Enrolled</div>

          <div className="dashboard-legend-row">
            <div className="dashboard-legend-column">
              <div className="enrolled-stats-gender-number">{ enrolledStats && enrolledStats.male }</div>
              <div className="enrolled-stats-gender-title">Male</div>
            </div>
            <div className="dashboard-legend-column">
              <div className="enrolled-stats-gender-number">{ enrolledStats && enrolledStats.female }</div>
              <div className="enrolled-stats-gender-title">Female</div>
            </div>
          </div>

          <hr className="divider"></hr>
          <div className="enrolled-statistics-age-groups">
            <div className="age-groups">Age Groups</div>
          </div>
        </div>
      </div>
    )
  }
}

EnrolledOverallStatsComponent.propTypes = {
  enrolledStats: PropTypes.object.isRequired
}

export default EnrolledOverallStatsComponent;
