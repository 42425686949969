import React, { useContext, useMemo } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { useCGMContext } from '../../context/CGMContext';

import './CGMVitalIconComponent.scss';

export const CGMVitalIconComponent = ({
  activeTooltipMsg,
  inActiveTooltipMsg,
  tooltipClassName,
  inActiveClassName,
}) => {
  const { CGMInfo, hasCGM } = useCGMContext();
  const today = moment().endOf('day');

  const isActive = useMemo(() => {
    if (!hasCGM) return false;
    const lastSyncAt = _.get(CGMInfo, 'lastSeen');
    const isWithin14Days = today.diff(lastSyncAt,'days') < 14;
    return isWithin14Days;
  }, [_.get(CGMInfo, 'lastSeen'), today]);

  return hasCGM
    ? (
      <Tooltip 
        title={isActive ? activeTooltipMsg : inActiveTooltipMsg} 
        overlayClassName={tooltipClassName}
      >
        <img 
          className={`cgm-vital-icon ${isActive ? '' : inActiveClassName}`}
          src="image/cgm-vital.svg" 
          alt="cgm-vital"
        />
      </Tooltip>
    ) : null;
};
