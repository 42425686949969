import { graphql, withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import get from 'lodash/get';
import LoginStep1Component from '../components/LoginStep1Component';
// import authenticate2FA from 'modulesAll/graphql/authenticate2FA';
import loginWithUsernameOrEmail from 'modulesAll/graphql/loginWithUsernameOrEmail';
import UserClass from 'modulesAll/graphql/class/User'
import actions from '../actions/Login';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import Client from 'libModule/gqlClient';
import createAccountEventHistory from '../../graphql/mutation/createAccountEventHistory';

class Container extends Component {
  static displayName = 'LoginStep1Container'

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  getLoginMethod (username) {
    const { mutate } = this.props
    // const byPassUserObj = {
    //   test_admin: 'Admin',
    //   super_admin: 'SuperAdmin',
    //   yaoming: 'Nurse',
    //   p774489ec4oaq1: 'Patient',
    //   test_vendor: 'Vendor',
    // }
    // if (window.VSM_CONFIG.BYPASS_USER && byPassUserObj.hasOwnProperty(username)) {
    //   return this[`loginWithoutPasswordAs${byPassUserObj[username]}`].bind(this)
    // }
    return mutate
  }
  async submit({ username, password }) {
    const { next, setSubmitting, updateForm, openErrorModal } = this.props;
    const variables = {
      // identification: username,
      // pwd: password,
      // role: ['DOCTOR', 'NURSE', 'ADMIN', 'MEMBER', 'SUPER_ADMIN'],
      usernameOrEmail: username,
      password
    };
    const loginMethod = this.getLoginMethod(username)
    try {
      setSubmitting(true);
      const res = await loginMethod({ variables });
      Client.mutate({
          mutation:createAccountEventHistory,
          variables:{
            event:'LOGIN'
          }
      });
      if (_.get(res,'data.loginWithUsernameOrEmail.role.category') === 'MEMBER') {
        openErrorModal(I18N.get('errors.1000001'));
      } else {
        const { sessionToken, token, id, role, appSettings, allRoles, lastLogIn, orgPermissions, globalConfigs,userSettings } = res.data.loginWithUsernameOrEmail || {};
        if (sessionToken) {
          const dataPack = res.data.loginWithUsernameOrEmail;
          Mixpanel.identify(dataPack.id);
          Mixpanel.people.set({
            "$organization": dataPack.role.organization.name,
            "$category": dataPack.role.category,
            "$role": dataPack.role.name,
            "$name" : variables.usernameOrEmail
            // "$email" : "ethan.chen@ihealthlabs.com",
            // "phone" : '0001234567'
          });
          await UserClass.saveUserInfo({sessionToken, id, role, appSettings, allRoles, lastLogIn, orgPermissions, globalConfigs,userSettings});
          // browserHistory.push('/');
          sessionStorage.setItem('currentUserId', res.data.loginWithUsernameOrEmail.id);
          if (res && res.data.loginWithUsernameOrEmail && res.data.loginWithUsernameOrEmail.isDefaultPassword) {
            browserHistory.push(`/setuserpassword/${res.data.loginWithUsernameOrEmail.id}`);
          } else {
            browserHistory.push('/');
          }
        }
      }

      //const { token, mobile } = get(res, 'data.authenticate2FA') || {};

      // request is not log because it contains password
      // createAuditLog({
      //   action: I18N.get('auditLog.login.step1'),
      //   loginId: variables.identification,
      //   response: res
      // })
      // updateForm({
      //   username: { name: 'username', value: username },
      //   password: { name: 'password', value: password },
      //   token: { name: 'token', value: token },
      //   number: { name: 'number', value: get(mobile, 'number') },
      // });
      //next();
    } catch (err) {
      // request is not log because it contains password
      // createAuditLog({
      //   action: I18N.get('auditLog.login.step1'),
      //   loginId: variables.identification,
      //   response: err,
      //   success: false
      // })
      openErrorModal(GQLHelper.formatError(err));
    }
    finally {
      setSubmitting(false);
    }
  }

  async loginForTesting({ userName, password }) {
    try{
        const res = await this.props.client.mutate({
          mutation: loginWithUsernameOrEmail,
          variables: {
            usernameOrEmail: userName,
            password
          }
        });
        const loginedRes = get(res, 'data.loginWithUsernameOrEmail')
        const { sessionToken, id, role, appSettings, orgPermissions, globalConfigs } = loginedRes;
        if (loginedRes) {
          await UserClass.saveUserInfo({ sessionToken, id, role, appSettings, orgPermissions, globalConfigs });
          browserHistory.push('/');
        }
    } catch (e) {
      //catch error if needed, not needed since this is just testing
    }
  }

  loginWithoutPasswordAsAdmin() {
    //window.VSM_CONFIG.API_KEY = window.VSM_CONFIG.API_KEYS_LOCAL['ADMIN:Admin'];
    const userName = 'test_admin';
    const password = 'Zz11111111';
    sessionStorage.setItem('currentUserRoles', 'ADMIN:Admin')
    this.loginForTesting({ userName, password });
  }

  loginWithoutPasswordAsSuperAdmin(){
    const userName = 'super_admin';
    const password = 'Zz11111111';
    sessionStorage.setItem('currentUserRoles', 'SUPER_ADMIN:Super Admin')
    this.loginForTesting({ userName, password });
  }

  loginWithoutPasswordAsNurse() {
    //window.VSM_CONFIG.API_KEY = window.VSM_CONFIG.API_KEYS_LOCAL['PROVIDER:Nurse'];
    const userName = 'yaoming';
    const password = 'Zz11111111';
    sessionStorage.setItem('currentUserRoles', 'PROVIDER:Nurse')
    this.loginForTesting({ userName, password });
  }

  loginWithoutPasswordAsPatient() {
    //window.VSM_CONFIG.API_KEY = window.VSM_CONFIG.API_KEYS_LOCAL['MEMBER:Member'];
    const userName = 'p774489ec4oaq1';
    const password = 'Zz11111111';
    sessionStorage.setItem('currentUserRoles', 'MEMBER:Member')
    this.loginForTesting({ userName, password });
  }

  loginWithoutPasswordAsVendor() {
    //window.VSM_CONFIG.API_KEY = window.VSM_CONFIG.API_KEYS_LOCAL['OTHER:Vendor'];
    const userName = 'test_vendor';
    const password = 'Zz11111111';
    sessionStorage.setItem('currentUserRoles', 'OTHER:Vendor')
    this.loginForTesting({ userName, password });
  }

  loginFromLocalStorageForTest(){
    const userName = localStorage.getItem('userName');
    const password = localStorage.getItem('password');

    if(!userName || !password) {
      // alert('You must set localStorage item for "userName, password" to use this function')
      return false
    }

    this.loginForTesting({ userName, password });

  }

  render() {
    const props = {
      ...this.props,
      submit: this.submit,
      loginWithoutPasswordAsAdmin: this.loginWithoutPasswordAsAdmin.bind(this),
      loginWithoutPasswordAsSuperAdmin : this.loginWithoutPasswordAsSuperAdmin.bind(this),
      loginWithoutPasswordAsNurse: this.loginWithoutPasswordAsNurse.bind(this),
      loginWithoutPasswordAsPatient: this.loginWithoutPasswordAsPatient.bind(this),
      loginWithoutPasswordAsVendor: this.loginWithoutPasswordAsVendor.bind(this),
      loginFromLocalStorageForTest : this.loginFromLocalStorageForTest.bind(this)
    };
    return (
      <LoginStep1Component {...props} />
    );
  }
}

Container.propTypes = {
  submit: PropTypes.func,
  handleFieldsChange: PropTypes.func,
  next: PropTypes.func,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  username: PropTypes.string,
  password: PropTypes.string,
  client: PropTypes.object
};

const mapState = ({ Login }, ownProps) => ({
  ...Login
})

const mapDispatch = {
  ...actions,
  openErrorModal
}

export default compose(
  withApollo,
  graphql(loginWithUsernameOrEmail),  //graphql(authenticate2FA),
  connect(mapState, mapDispatch),
)(Container);
