export default {
 // Units
  units_height_cm: 'cm',
  units_weight_kg: 'kg',
  units_height_inches: 'inches',
  units_height_ft: 'ft',
  units_weight_lbs: 'lbs',
  units_BG_US_standard: 'mg/dL',
  units_BG_UK_standard: 'mmol/L',
  BMI:{
      underweight:'UNDERWEIGHT',
      normal:'NORMAL',
      overweight:'OVERWEIGHT',
      obese:"OBESE"
  }
};
