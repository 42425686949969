import React, { useState } from 'react';
import IdleTimeComponent from '../component/IdleTimeComponent';
import { Button, Form } from 'antd';
import _ from 'lodash';
import { EVENTS } from '../constant';
import { sendClaimedTimeReq } from '../../timerClaimed/helpers';

const convertMsToMin = (timeInMs) => {
  return Math.round(timeInMs / 60000);
}

const IdleTimeContainer = ({ defaultEvents = [], onDone, patientId, totalTime, nonIdleTime, startTime, endTime, idleTime=0, handleError }) => {
  const [selectedTime, setSelectedTime] = useState(convertMsToMin(nonIdleTime));
  const [events, setEvents] = useState(defaultEvents);
  const [activities, setActivities] = useState([]);
  const [other, setOther] = useState('');
  const [isSending, setIsSending] = useState(false);

  const getEvents = () => {
    return _.map(events, (v) => EVENTS[v] ? EVENTS[v].text : v);
  }

  const getActivities = () => {
    const eventsWithoutOther = _.filter(activities, c => c !== 'Other');
    const hasOthers = eventsWithoutOther.length !== activities.length;
    return hasOthers ? [...eventsWithoutOther, other] : eventsWithoutOther;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSending(true);
      const evts = _.map([...getEvents(), ...getActivities()]).map(v => ({ name: v }));
      await sendClaimedTimeReq({ memberId: patientId, events: evts, totalTime: selectedTime * 60000, startTime,idleTime, endTime,manualInput: true });
    } catch (e) {
      handleError(e);
    } finally {
      setIsSending(false);
    }
    if (onDone) {
      onDone();
    }
  }

  const onCancel = async (e) => {
    try {
      setIsSending(true);
      const evts = _.map([...getEvents(), ...getActivities()]).map(v => ({ name: v }));
      await sendClaimedTimeReq({ memberId: patientId, events: evts, totalTime: nonIdleTime, startTime, idleTime, endTime, manualInput: false });
    } catch (e) {
      handleError(e);
    } finally {
      setIsSending(false);
    }
    if (onDone) {
      onDone();
    }
  }

  return (
    <div style={{ padding: 30 }}>
      <Form onSubmit={onSubmit}>
        <IdleTimeComponent
          selectedTime={selectedTime}
          onTimeChange={setSelectedTime}
          activities={activities}
          onActivitiesChange={setActivities}
          events={events}
          onEventsChange={setEvents}
          other={other}
          onOtherChange={setOther}
          nonIdleTime={convertMsToMin(nonIdleTime)}
          totalTime={convertMsToMin(totalTime)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', marginTop: 30 }}>
          <Button type="default" className="new-style" style={{ width: 160 }} onClick={onCancel} disabled={isSending} loading={isSending}>No thanks</Button>
          <Button type="primary" htmlType="submit" className="new-style" style={{ width: 160 }} disabled={selectedTime === 0 || isSending} loading={isSending}>Submit</Button>
        </div>
      </Form>
    </div>
  );
};

export default IdleTimeContainer;
