import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { graphql } from 'react-apollo';
import { compose, withPropsOnChange, withState } from 'recompose';
import moment from 'moment';
import reduxStore from '../../../lib/reduxStore';
import actions from '../actions';

import BPResultStatisticsComponent from '../../logBook/components/BPResultStatisticsComponent';
import BGResultStatisticsComponent from '../../logBook/components/ResultStatisticsComponent';
import POResultStatisticsComponent from '../../logBook/components/POResultStatisticsComponent';

import { RESULT_COUNT_MAX } from '../../../lib/constants';
import { vitalsSummaryStats } from '../../graphql/resultList';

const WEEK_TYPE = 'week';
const MONTH_TYPE = 'month';
const YEAR_TYPE = 'year';
const INTERVALS = {
  week1: {
    type: WEEK_TYPE,
    interval: 1,
    name: '1 Week',
  },
  week2: {
    type: WEEK_TYPE,
    interval: 2,
    name: '2 Weeks',
  },
  month1: {
    type: MONTH_TYPE,
    interval: 1,
    name: '1 Month',
  },
  month2: {
    type: MONTH_TYPE,
    interval: 2,
    name: '2 Months',
  },
  month3: {
    type: MONTH_TYPE,
    interval: 3,
    name: '3 Months',
  },
  month6: {
    type: MONTH_TYPE,
    interval: 6,
    name: '6 Months',
  },
  year1: {
    type: YEAR_TYPE,
    interval: 1,
    name: '1 Year',
  }
};

const VITALS_TABLE_STAT_MAPPING = {
  BP: {
    title: 'Blood Pressure Summary',
    TableComponent: BPResultStatisticsComponent
  },
  BG: {
    title: 'Blood Glucose Summary',
    TableComponent: BGResultStatisticsComponent
  },
  PO: {
    title: 'Blood Oxygen Summary',
    TableComponent: POResultStatisticsComponent
  }
};

const VitalsSummaryComponent = props => {
  const { 
    selectedIntervalKey, setSelectedIntervalKey, timeRange, setTimeRange,
    vitalType, ...restProps
  } = props;
  const { fromDate, toDate } = timeRange;
  const { title, TableComponent } = VITALS_TABLE_STAT_MAPPING[vitalType];

  const renderInterval = () => {
    return (
      <Select 
        value={selectedIntervalKey} 
        onChange={value => {
          setSelectedIntervalKey(value);

          const { type: intervalType, interval } = INTERVALS[value];
          const now = new Date();
          setTimeRange({
            fromDate: moment(now).add(-1 * interval, intervalType).startOf('day'),
            toDate: moment(now).endOf('day'),
          });
        }} 
        style={{ width: 152 }}
      >
        {
          _.map(_.keys(INTERVALS), intervalKey => (
            <Select.Option value={intervalKey} key={intervalKey}>
              { INTERVALS[intervalKey].name }
            </Select.Option>
          ))
        }
      </Select>
    );
  }

  const renderTimeRange = () => {
    const fromDateString = fromDate.format('MM/DD/YYYY');
    const toDateString = toDate.format('MM/DD/YYYY');

    return (
      <div style={{ marginLeft: 5, marginRight: 5 }}>
        <p className='vitals-summary-popup-time-range'>{fromDateString} - {toDateString}</p>
      </div>
    );
  }

  const changePrevNext = (sign) => {
    const { type: intervalType, interval } = INTERVALS[selectedIntervalKey];

    setTimeRange({
      fromDate: fromDate.clone().add(sign * interval, intervalType).add(sign, 'day').startOf('day'),
      toDate: toDate.clone().add(sign * interval, intervalType).add(sign, 'day').endOf('day'),
    });
  }

  const renderNextPreFooter = () => {
    return (
      <ul className='ant-pagination pre-next-footer' >
        <li 
          title='Previous Page' 
          className=' ant-pagination-prev' 
          aria-disabled='false' 
          onClick={() => changePrevNext(-1)}
        >
          <a className='ant-pagination-item-link vitals-summary-popup-prev-link'></a>
        </li>
          {renderTimeRange()}
        <li 
          title='Next Page' 
          className=' ant-pagination-next' 
          aria-disabled='false' 
          onClick={() => changePrevNext(1)}
        >
          <a className='ant-pagination-item-link vitals-summary-popup-next-link'></a>
        </li>
      </ul>
    );
  }

  return (
    <div key={vitalType} className='vitals-summary-popup-table-wrapper'>
      <span className='vitals-summary-popup-table-title'>
        {title}
      </span>
      <div className='vitals-summary-popup-table-time-range'>
        <span>Date Range</span>
        <div className='vitals-summary-popup-table-time-control'>
          {renderInterval()}
          {renderNextPreFooter()}
        </div>
      </div>
      <TableComponent {...restProps} />
    </div>
  );
};

VitalsSummaryComponent.propTypes = {
  defaultIntervalKey: PropTypes.string,
  vitalType: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  visitId: PropTypes.string.isRequired,
  enrolledProgramId: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  stat: PropTypes.object.isRequired,
};

const getVitalsStat = graphql(vitalsSummaryStats, {
  options: ownProps => {
    const { memberId, enrolledProgramId, vitalType, timeRange } = ownProps;
    const { fromDate, toDate } = timeRange; 
    return {
      variables: {
        count: RESULT_COUNT_MAX,
        filters: {
          memberId,
          enrolledProgramId,
          type: [vitalType],
          fromDate: fromDate.valueOf(),
          toDate: toDate.valueOf()
        },
        [vitalType]: true
      }
    }
  },
  props: ({ ownProps, data }) => {
    const { error, loading, resultList = {} } = data;
    if(error) console.error(error);

    const stat = _.get(resultList, `stat.${_.toLower(ownProps.vitalType)}`) || {};
    return {
      loading,
      stat: _.omit(stat, '__typename')
    }
  }
});

const getReduxState = (props, key, defaultValue) => {
  const { memberId, visitId, vitalType } = props;
  const vitalsSummary = reduxStore.getState().visitWorkList.main.vitalsSummary;
  return _.get(vitalsSummary, `${memberId}.${visitId}.${vitalType}.${key}`) ||
         defaultValue;;
};

export default compose(
  withState('selectedIntervalKey', 'setSelectedIntervalKey', props => {
    // default
    const defaultIntervalKey = getReduxState(props, 'selectedIntervalKey', 'month3');
    return defaultIntervalKey || _.first(_.keys(INTERVALS));
  }),
  withPropsOnChange(['selectedIntervalKey'], props => {
    const pickValues = ['memberId', 'visitId', 'vitalType', 'selectedIntervalKey'];
    reduxStore.dispatch(actions.setSelectedSummaryIntervalKey(_.pick(props, pickValues)));
  }),
  withState('timeRange', 'setTimeRange', props => { 
    // default
    const { interval, type } = INTERVALS[props.selectedIntervalKey];
    const toDate = moment().endOf('day');
    const fromDate = moment(toDate).add(-1 * interval, type).startOf('day');
    let defaultTimeRange = { fromDate, toDate };
    defaultTimeRange = getReduxState(props, 'selectedTimeRange', defaultTimeRange);
    return defaultTimeRange;
  }),
  withPropsOnChange(['timeRange'], props => {
    const pickValues = ['memberId', 'visitId', 'vitalType', 'timeRange'];
    reduxStore.dispatch(actions.setSelectedSummaryTimeRange(_.pick(props, pickValues)));
  }),
  getVitalsStat
)(VitalsSummaryComponent);