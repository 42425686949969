import { IHLoading } from 'ihcomponent'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import teamGQL from 'graphqlModule/team'
import EditCareTeamContainer from './EditCareTeamContainer'
import get from 'lodash/get'
import { connect } from 'react-redux'

class EditBroadCastContainer extends Component {
  static displayName = 'careteam/editCareTeam/containers/index'
  
  render() {
    const { data } = this.props
    const teamData = data.team
    if(!teamData){
      return <IHLoading />
    }

    return <EditCareTeamContainer teamData={teamData} {...this.props} />
  }
}
EditBroadCastContainer.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.careteamId')
  return {
    ...ownProps,
    id
  }
}

const withGql = graphql(teamGQL, {
  options: (ownProps)=>{
    const id = ownProps.id
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }
  }
})

const ContainerWithData = withGql(EditBroadCastContainer)

EditBroadCastContainer.propTypes = {
  data: PropTypes.object,
}

export default connect(
  mapState
)(ContainerWithData)
