import gql from 'graphql-tag'
import { UserProfile } from './schema/User'
import { Phone, Email, Team } from './schema/common';
export default gql`
  query userList(
    $filters: UserListFilters!,
    $search: InputUserSearch,
    $page: Int,
    $count: Int,
    $sort: UserListSort
  ) {
    userList(
      page: $page,
      count: $count,
      filters: $filters,
      search: $search,
      sort: $sort
    ) {
      data {
        id
        createdAt
        registeredAt
        username
        phone{
          ${Phone}
        }
        email{
          ${Email}
        }
        team{
          ${Team}
        }
        allRoles {
          refId
          name
          category
          organization {
            id
            name
            leaf
            parent {
              id
              name
            }
          }
        }
        profile {
          ${UserProfile}
        }
      }
      pageInfo {
        total
        lastPage
      }
    }
  }`
