
import React, { forwardRef, useCallback, useState } from 'react';
import { Select } from 'antd';
import searchCellularDevices from '../../api/searchCellularDevices';

export const CellularDevicesIdSearchDropdownComponent = forwardRef((
  {
    deviceType,
    ...props
  },
  ref
) => {
  const [, setSearchStr] = useState('');
  const [availableDevices, setAvailableDevices] = useState([]);
  const debouncedSearch = useCallback(_.debounce(async (
    inputValue,
  ) => {
    if (!inputValue) return;
    const res = await searchCellularDevices({
      searchStr: inputValue,
      deviceType,
    });
    const list = _.get(res, 'data.content') || [];
    setAvailableDevices(list);
  }, 500), []);

  const handleOnSearch = (
    inputValue,
  ) => {
    setSearchStr(inputValue);
    debouncedSearch(inputValue);
  };

  return (
    <Select
      ref={ref}
      onSearch={handleOnSearch}
      showSearch
      autoClearSearchValue={false}
      defaultActiveFirstOption={false}
      dropdownMatchSelectWidth={false}
      placeholder="Search device ID"
      notFoundContent="No Device ID found"
      {...props}
    >
      {
        availableDevices.map(({
          id,
          deviceId,
        }) => (
          <Select.Option
            key={id}
            value={deviceId}
          >
            {deviceId}
          </Select.Option>
        ))
      }
    </Select>
  );
});
