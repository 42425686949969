import React from 'react';
import DoctorReferralIcon from './DoctorReferralIcon';

const ReferredByMDComponent = class extends React.Component {

    renderAddUser = ()=>{
        const remoteEnrollment = _.get(this,'props.remoteEnrollment');
        const refetchRemoteEnrollmentList = _.get(this,'props.localThis.props.refetchRemoteEnrollmentList');
        const memberId = _.get(remoteEnrollment,'member.id');
        const doctorReferral = _.get(remoteEnrollment,'doctorReferral') || false;
        const patientRefer = _.get(remoteEnrollment,'patientRefer');

        return <DoctorReferralIcon
          memberId={memberId}
          doctorReferral={doctorReferral}
          patientRefer={patientRefer}
          remoteEnrollment={remoteEnrollment}
          iconProps={{
            width: 25, 
            className: 'referredByMDIcon'
          }}
          onClickCallback={() => refetchRemoteEnrollmentList()}
        />;
    }

    render() {
        return this.renderAddUser()
    }
}
export default ReferredByMDComponent