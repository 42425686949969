import gql from 'graphql-tag'
export default gql`

mutation removeFromInventory($organizationId:EID!, $memberId:EID!, $products:[InventoryProductInput!]!, $note:String) {
    removeFromInventory(organizationId: $organizationId, memberId:$memberId,  products: $products, note:$note) {
        id
        organization {
            id
        }
        products {
            productType
            quantity
            description
        }
    }
}`


export const removeFromInventoryWithVisit =  gql`

mutation removeFromInventory($organizationId:EID!, $memberId:EID!, $visitId: EID!, $products:[InventoryProductInput!]!, $note:String) {
    removeFromInventory(organizationId: $organizationId, memberId:$memberId, visitId: $visitId, products: $products, note:$note) {
        id
        organization {
            id
        }
        products {
            productType
            quantity
            description
        }
    }
}`