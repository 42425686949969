import gql from 'graphql-tag';


export const createClaimedTimeLogMutation =  gql` mutation createClaimedTimeLog($memberId:EID!,$events:[ClaimedTimeLogEventInput]!,
    $totalTime:Int!,$idleTime:Int,$monthOfYear:Int!,$serviceDate:Date!,$startTime:Date,$endTime:Date,$manualInput:Boolean,$providerId: EID){
    createClaimedTimeLog(memberId: $memberId,events:$events,
      ,totalTime:$totalTime,,monthOfYear:$monthOfYear,idleTime:$idleTime,
      serviceDate:$serviceDate,startTime:$startTime,endTime:$endTime,
      manualInput:$manualInput, providerId: $providerId
    ){
      memberId,
      viewerId,
      events{
        name
      }
      totalTime,
      monthOfYear,
      startTime,
      endTime,
      idleTime
    }
  }`;


export const patientReferralMini = gql`query getMiniPatientReferral(
   $patientId: EID
){
  getPatientReferral(patientId:$patientId){
   eligiblePrgorams
   referredBy{
        id
        selectedRole {
            name
            roleType
        }
        allRoles {
            name
            roleType
        }
        profile{
            fullName
        }
   }
   referredAt
   status {
    referStatus
    enrollStatus
    screeningStatus
    patientConsentEnroll
    confirmDevicesEnroll
    scheduleInitVisitEnroll
    basicInfoEnroll
    prgramEligibilityEnroll
    mntReferralEnroll
    clinicalGoalsEnroll    
   }
   wantMNTReferral
}}`;

export const subscriptionListQuery = gql`query subscribesList($memberId:EID!){
  subscribesList(memberId:$memberId) {
    data{
      createdAt
      status
    }
  }
}`;