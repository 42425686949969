import React from 'react';
import I18N from '../../I18N';
import { Input, Select, Radio, Tooltip, Icon } from 'antd';
import { parseDigit, ReactInput, templateFormatter, templateParser } from 'input-format';
import { helpers } from '../../visit/helpers';
const Option = Select.Option;

export const provider = (config = {}) => ({
	key: 'provider',
	label: I18N.get('referAndEnroll.provider'),
	...config,
	render: ({ disabled }) => {
		return (
      <Select filterOption={false} disabled={disabled}>
        {_.map(helpers.filterDoctors(), p => (
          <Option value={p.id} key={p.id}>
            {p.fullName}
          </Option>
        ))}
      </Select>
    );
	}
});

const PHONE_TEMPLATE = '(xxx) xxx-xxxx';
const parse = templateParser(PHONE_TEMPLATE, parseDigit);
const format = templateFormatter(PHONE_TEMPLATE)

const phoneValidator = (value) => {
  if (!value) I18N.get('validator.mobile.required');
  if (!/^\d{10}$/.test(value)) {
		return I18N.get('validator.mobile.invalid');
  }
};

export const patientPhoneNumber = (config = {}) => ({
	key: 'patientPhoneNumber',
	label: I18N.get('referAndEnroll.patientPhoneNumber'),
	rules: [{ required: true, validator: (rule, v, callback) => callback(phoneValidator(v)) }],
  ...config,
	render: ({ disabled }) => <ReactInput className='ant-input' parse={parse} format={format} disabled={disabled} onChange={() => {}} />,
});

export const otp = (config = {}) => {  
  const { canUseForLogin, ...restConfigs } = config;
  return {
    key: 'canUseForLogin',
    label: (<div style={{ width: 130, textAlign: 'left' }}>
      <span>{I18N.get('canUseForLogin')}</span>
      <Tooltip title={I18N.get('OTPLogInInfo')} overlayClassName='otpTooltip'>
        <Icon type='info-circle' style={{fontSize: 14, marginLeft: 5, position:'absolute' }}/>
      </Tooltip>
    </div>),
    initialValue: !_.isNil(canUseForLogin) ? ~~canUseForLogin : 1,
    ...restConfigs,
    render: ({ disabled }) => (
      <Radio.Group disabled={disabled}>
        <Radio.Button value={1} style={{width: 60, textAlign: 'center'}}>On</Radio.Button>
        <Radio.Button value={0} style={{width: 60, textAlign: 'center'}}>Off</Radio.Button>
      </Radio.Group>
    )
  };
}

export const insurance = (config = {}) => ({
	key: 'insurance',
	label: I18N.get('referAndEnroll.insurance'),
  ...config,
	render: ({ disabled }) => <Input disabled={disabled} />,
});

export const cantFindInsuranceCompany = (config = {}) => ({
	key: 'cantFindInsuranceCompany',
	label: '',
  ...config,
	render: () => <a style={{ textDecoration: 'underline' }}>{I18N.get('referAndEnroll.cantFindInsuranceCompany')}</a>,
});

export const patientHasSmartPhone = ({ onChange, ...configs } = {}) => ({
	key: 'hasSmartPhone',
	className: 'bold-form-with-subtext-container',
	label: I18N.get('referAndEnroll.patientSmartphoneTitle'),
	subText: I18N.get('referAndEnroll.patientSmartphoneSubText'),
  ...configs,
	render: ({ disabled }) => {
    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio style={radioStyle} value={1}>Yes</Radio>
        <Radio style={radioStyle} value={0}>No</Radio>
      </Radio.Group>
    );
	}
});

export const hasReferredByDoctor = ({ onChange, ...configs } = {}) => ({
	key: 'referredByDoctor',
	className: 'bold-form-container',
	label: I18N.get('referAndEnroll.isReferredByDoctor'),
  ...configs,
	render: ({ disabled }) => {
    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio style={radioStyle} value={1}>Yes</Radio>
        <Radio style={radioStyle} value={0}>No</Radio>
      </Radio.Group>
    );
	}
});
