/**
 * Created by zizhangai on 2/15/17.
 */
import React, { Component } from 'react'
import { withApollo } from 'react-apollo';
import { message } from 'ihcomponent'
import { connect } from 'react-redux'
import { IHButton, _ } from 'ihcomponent'
import detectIE from '../../../../../package/IHComponent/common/detectIE'
import { closeModal } from 'layoutModule/actions/MainModal'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import I18N from 'modulesAll/I18N'
import { browserHistory } from 'react-router';

// query
import sendNEHRReport from 'graphqlModule/sendNEHRReport'

class IFrame extends Component {

  componentDidMount() {
    const param = this.props.url
    const tar = document.getElementById('vsm-pdf-ifm')
    if(tar.contentWindow && tar.contentWindow.renderPDF){
      tar.contentWindow.renderPDF(param)
    }
    else{
      tar.onload = function(){
        tar.contentWindow.renderPDF(param)
      }
    }
  }
  render() {
    return (<iframe id="vsm-pdf-ifm" src="/renderPDF.html"/>)
  }
}

class ReportPreview extends Component {
  static displayName = 'report/containers/ReportPreviewContainer'

  render() {
    const { pdfUrl, reportId, handleNext, dispatch, isFullReport } = this.props
    return (
      <div className="pdf-preview-modal">
        <h5>(The pdf file may take up to a few seconds to load)</h5>
        <div className="iframe-wrapper">
          <IFrame url={pdfUrl} />
        </div>
        <IHButton label="Download" type="primary" onClick={() => this.handleDownload(pdfUrl)} />
        {
          !isFullReport &&
          <IHButton label="Send to NEHR" type="primary" style={{marginLeft: '10px'}} onClick={this.handleSend.bind(this, reportId)} ref="btn_send"/>
        }
        {
          handleNext &&
            <IHButton label="Proceed" onClick={ handleNext } type="primary" style={{marginLeft: '10px'}} />
        }
        <IHButton label="Cancel" onClick={this.handleCancelOnClick.bind(this)} style={{marginLeft: '10px'}}/>
      </div>
    )
  }

  handleCancelOnClick() {
    const { dispatch, isFullReport } = this.props
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    if (!isFullReport) {
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.cancelSendNEHRReport'),
        patientNRIC,
        patientName
      })
    }

    dispatch(closeModal())
  }

  handleDownload(url) {
    const { isFullReport } = this.props
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')
    const action = isFullReport ? I18N.get('auditLog.provider.patient.downloadFullReport') : I18N.get('auditLog.provider.patient.downloadNEHRReport')
    createAuditLog({ action, patientNRIC, patientName })

    const elem = document.createElement('a');
    elem.href = url;
    elem.target = '_blank';
    elem.download = url;
    if (!detectIE()){
      if (typeof InstallTrigger !== 'undefined') { // check if Firefox
        const clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
        })
        elem.dispatchEvent(clickEvent)
      } else {
        elem.click();
      }
    } else {
      window.open(url);
    }
    // window.open(url, '_self')
    // the content type of the url is binary, so the browser will not open it but download it
  }

  async handleSend(reportId) {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    this.refs.btn_send.loading(true)

    try {
      let status;
      if (_.get(window, 'VSM_CONFIG.IS_NEHR_SUPPORTED')) {
        const res = await this.runMutation(reportId)
        status = res.data.sendInterventionNotes.status
      } else {
        status = 'SUCCESS'
      }

      if (status === 'SUCCESS') {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.sendReportToNEHR'),
          patientNRIC,
          patientName,
          request: { interventionNotesId: reportId },
          //response: res
        })
        this.props.dispatch(closeModal()) // VET-338
        message.success('Report is sent to NEHR!')
        // discharge patient if necessary
        if (this.props.reason === 'Discharge') {
          await this.props.handleDischargeAsync()
        }
      } else {
        throw new Error('Report failed to be sent to NEHR!')
      }
      _.get(this, 'refs.btn_send.loading') && this.refs.btn_send.loading(false) // when modal is closed, the button is gone.

    } catch(err) {
      // this.props.dispatch(openErrorModal('An Error Occurred'))
      if (err.message === 'Report failed to be sent to NEHR!'){
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.sendReportToNEHR'),
          patientNRIC,
          patientName,
          request: { interventionNotesId: reportId },
          response: err,
          success: false
        })
      }
      message.error(err.message)
      this.refs.btn_send.loading(false)
    }

  }

  runMutation(reportId) {
    return this.props.client.mutate({
      mutation: sendNEHRReport,
      variables: {
        interventionNotesId: reportId
      },
    });
  }
}

export default connect(null, (dispatch) => ({ dispatch }))(withApollo(ReportPreview))
