import gql from 'graphql-tag'
export default gql`
    query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                id
                profile{
                  firstName
                  lastName
                  fullName  
                  ...on EmployeeProfile {
                    healthCoach {
                        id
                    }
                  }
                }
                
            }
            pageInfo {
                total
                lastPage
            }
        }
    }

`;