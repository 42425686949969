import React, { forwardRef, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Modal, message, Form, DatePicker, Tooltip, Radio, Popconfirm, Checkbox, Spin } from 'antd';
import moment from 'moment';
import { compose, graphql } from 'react-apollo';
import enrolledProgramQuery from 'graphqlModule/enrolledProgram';

import proptypes from '../../constants/proptypes';

import deleteTask from '../../query/deleteTaskAssignment';
import editTask from '../../query/editTask';

import TaskConstants from '../../constants/tableMap';
import Client from '../../../../lib/gqlClient';
import I18N from '../../../I18N';
import helper from '../../helper';

import API from '../../../taskAssignment/API/index';
const { priorityMap } = TaskConstants;

const TaskWorkStationReassignment = Form.create({
  onValuesChange: (props, changedValues) => {
    const { setShowUnsavedModal } = props;
    setShowUnsavedModal(true);
  },
})(forwardRef((props, ref) => {
  const { 
    form, currentUser, task, setShowReassignForm, 
    refetchTaskAssignments, handleCreateTrackingTime, onInteraction, memberId,
    loadingTeam, enrolledPrograms = [], team = []
  } = props;
  const [isReasonRequired, setIsReasonRequired] = useState(false);
  const [addToPN, setAddToPN] = useState(false);
  const [ assignees,setAssignees] = useState({});
  const { getFieldDecorator, validateFields, getFieldsValue } = form;
  const { resolvedNote, assignedTo, createdBy, dueDate, priorityLevel } = task;

  const currentAssigneeId = (assignedTo || {}).id;
  const teamsFromLoginUser = currentUser.team;
  // ONLY creator can delete the task
  const canDeleteTask = currentUser.id === _.get(createdBy, 'id');
  const fetchAssignees = async ()=>{
    return await API.getPatientAssignee(memberId);
  }
  useEffect(() => {
    if(!isReasonRequired) {
      validateFields(['reason'], { force: true });
    }
  }, [isReasonRequired]);
  useEffect(()=>{
    (async ()=> {
      let data = (await API.getPatientAssignee(memberId));
      const assigneesFromData = _.get(data, 'data.user.assignees');
      setAssignees(assigneesFromData);
    })();
  },[assignees])
  const handleSubmit = () => {
    validateFields(['reason', 'assignedTo', 'dueDate'], errors => {
      if(errors) return;
      const { reason, assignedTo, dueDate, priorityLevel } = getFieldsValue();

      const variables = {
        id: task.id, reason, assignedTo, priorityLevel,
        dueDate: dueDate ? moment(dueDate).endOf('day').valueOf() : null,
      };

      // Mixpanel.track('clicked', 'submit', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
      Client.mutate({
        mutation: editTask,
        variables
      }).then(res=>{
        message.success('Task Updated');
        onInteraction('UPDATED');
        refetchTaskAssignments();
        handleCancel();

        handleCreateTrackingTime(res);

        if(addToPN){
          helper.createPN({
            memberId,
            content: reason
          });
        }
      });
    });
  };

  const handleDelete = () => {
    // const { priorityLevel } = form.getFieldsValue();
    // Mixpanel.track('clicked', 'delete task', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
    Client.mutate({
      mutation: deleteTask,
      variables: { id: task.id }
    }).then(res => {
      message.success('Task Deleted');
      onInteraction('DELETED');
      refetchTaskAssignments();
    }).catch(e=>{
      Modal.error({ content : e.message });
    });
  };

  const handleCancel = () => setShowReassignForm(false);
  const handleChangeAssignee = (value) => {
    const isReasonRequired = !_.isEqual(currentAssigneeId, value);
    setIsReasonRequired(isReasonRequired);
  };
  const { reason } = getFieldsValue();

  if (loadingTeam) {
    return <Spin />;
  }
  return (
    <Row className='task-reassignment-container'>
      <Row>
        <Col span={24}>
          <Form.Item key='reason' colon={false}>
            {
              getFieldDecorator('reason', { 
                rules: [
                  { 
                    required: isReasonRequired,
                    message: 'Reason is required when reassigning',
                    whitespace: true,
                  }
                ] 
              })(<Input.TextArea rows={6} disabled={resolvedNote} placeholder='Please add reassign reason here' />)
            }
          </Form.Item>
        </Col>
        <Col span={24}>
            <Checkbox
                onChange={ (e) => setAddToPN(e.target.checked) }
                disabled={!reason}
            >
                Add to Provider Notes
            </Checkbox>
        </Col>
        <Col span={24}>
          <Form.Item key='assignedTo' label={I18N.get('taskAssignment.assignedTo')} colon={false}>
            {
              getFieldDecorator('assignedTo', {
                rules: [{required: true, message: I18N.get('taskAssignment.assignedToMsg')}],
                initialValue: currentAssigneeId || null
              })
              (helper.assignedToDropDown(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team, resolvedNote, handleChangeAssignee,assignees))
            }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item key='assignedTo' label={I18N.get('taskAssignment.dueDate')} colon={false}>
            {
              getFieldDecorator('dueDate', { 
                rules: [{required: true, message: I18N.get('taskAssignment.dueDateMsg')}],
                initialValue: dueDate ? moment(dueDate) : null
              })
              (<DatePicker format='MM/DD/YYYY' disabled={resolvedNote}/>)
            }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={I18N.get('taskAssignment.priorityLevel.label')} colon={false}>
            {
              getFieldDecorator('priorityLevel',{ 
                initialValue: priorityLevel || null
              })
              (<Radio.Group disabled={resolvedNote}>
                { 
                  _.map(priorityMap,(value,key)=>(
                    <Radio value={key} key={value}>{value}</Radio>
                  )) 
                }
              </Radio.Group>)
            }
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 24 }}>
        <Col>
          <Tooltip
            overlayClassName='task-assignment-tooltip' // TODO: check for styles
            title={canDeleteTask ? '' : 'Only creator can delete this task'}
          >
            <Popconfirm
              placement='topLeft' 
              title='Delete Task Assignment?' 
              onConfirm={handleDelete} 
              okText='Yes' cancelText='No'
              disabled={!canDeleteTask}
            >
              <Button type='danger' disabled={!canDeleteTask}>
                Delete Task
              </Button>
            </Popconfirm>
          </Tooltip>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {
            setShowReassignForm &&
            <Button onClick={handleCancel} style={{ marginRight: 5, borderColor: 'transparent' }}>
              Cancel
            </Button>
          }
          <Button type='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Row>
    </Row>
  );
}));

TaskWorkStationReassignment.propTypes = proptypes.TaskWorkStationReassignment;

const getEnrolledProgram = graphql(enrolledProgramQuery, {
  options: ownProps => ({
      variables: {
          id: _.get(ownProps, 'currentProgram.id'),
      },
      notifyOnNetworkStatusChange: true,
  }),
  props: ({ data }) => { 
    return { 
      loadingTeam: data.loading,
      team: _.get(data, 'enrolledProgram.team'),
    } 
  },
});
export default compose(getEnrolledProgram)(TaskWorkStationReassignment);