import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import moment from 'moment';

import { GOAL_STATUS_LIST, UNKNOWN_GOAL_STATUS_IMG } from './constants';

const goalStatusList = _.keyBy(GOAL_STATUS_LIST, 'value');

const GoalStatus = props => {
  const { form, fieldKeyPrefix, goalStatus, disabled, selectPlaceholder } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { meetingStatus, statusUpdatedAt } = goalStatus || {}; // initial value
  const initialRender = useRef(true);

  const getFieldKey = (fieldKey) => fieldKeyPrefix ? `${fieldKeyPrefix}.${fieldKey}` : fieldKey;

  const currentStatus = getFieldValue(getFieldKey('meetingStatus')) || meetingStatus || undefined;

  useEffect(() => {
    if(initialRender.current)
      initialRender.current = false;
    else {
      const meetingStatusKey = getFieldKey('meetingStatus');
      const prevGoalStatus = getFieldKey('prevGoalStatus');
      const newPrevGoalStatus = {...goalStatus, meetingStatus, statusUpdatedAt};
      setFieldsValue({ 
        [meetingStatusKey]: meetingStatus,
        [prevGoalStatus]: newPrevGoalStatus
      });
    }
  }, [meetingStatus, statusUpdatedAt]);

  return (
    <div className='goal-status-wrapper'>
      <div className='goal-status'>
        <div className='goal-status-icon'>
          <img
            src={`${(goalStatusList[currentStatus] || {}).icon || UNKNOWN_GOAL_STATUS_IMG}`}
            width='17.5px'
          />
        </div>
        <div className='goal-status-result'>
          <span style={{ fontSize: 12, minWidth: 'fit-content' }}>
            Goal Status:
          </span>
          <Form.Item>
            {
              getFieldDecorator(getFieldKey('meetingStatus'), {
                initialValue: currentStatus
              })(<Select
                style={{ width: '100%' }}
                className='goal-status-result-select'
                placeholder={selectPlaceholder || 'Select goal status'}
                defaultActiveFirstOption={false}
                disabled={disabled}
                dropdownMatchSelectWidth={false}
              >
                {
                  _.map(goalStatusList, ({ label, value }) => 
                    <Select.Option key={value} value={value}>{label}</Select.Option>
                  )
                }
              </Select>)
            }
          </Form.Item>
        </div>
      </div>
      {
        meetingStatus && statusUpdatedAt &&
        <div className='goal-status-updated-at'>
          Last updated at: {moment(statusUpdatedAt).format('MM/DD/YYYY')}
        </div>
      }
      <div>
        { // shadow field
          getFieldDecorator(getFieldKey('prevGoalStatus'), {
            initialValue: goalStatus || {}
          })(<div style={{ display: 'none' }}></div>)
        }
      </div>
    </div>

  )
};

GoalStatus.propTypes = {
  form: PropTypes.object.isRequired
};

export default GoalStatus;