import types from '../constants/AddNewPatient'
import { handleFormFieldsChange } from 'libModule/helpers/action-helpers'

export default {
  addCaregiver : ()=>{
    return {
      type : types.ADD_CAREGIVER
    }
  },
  removeCaregiver: (idx) => {
    return {
      type: types.REMOVE_CAREGIVER,
      payload: idx
    }
  },
  resetForm : ()=>{
    return {
      type : types.RESET_FORM
    }
  },

  updateForm : (fieldsProps, changedFields)=>{
    return handleFormFieldsChange(fieldsProps, changedFields, types)
  }
}
