
import Login from './Login';
import ResetPassword from './ResetPassword';
import Logout from './Logout';


export default {
    Login,
    ResetPassword,
    Logout
};
