import React from 'react';
import moment from 'moment';
import { closeModal } from 'layoutModule/actions/MainModal'
import { Button,Divider,Tabs } from 'antd';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import { graphql } from "react-apollo/index";
import { foodLogList } from "../query";
import helpers from '../helpers';
import  '../style/index.scss';

const TabPane = Tabs.TabPane;
const tagsMap = {
    'hadMedicine':'Took Medication',
    'hadInsulin' : 'Took Insulin',
    'hadActivity': "Exercised"
}
const tagsMapSrc = {
    'hadMedicine':'/image/took-meds-icon.svg',
    'hadInsulin' :'/image/took-insulin-icon.svg',
    'hadActivity':'/image/exercised-icon.svg'
}
const styles = {
    patientNoteTitle: {
        width: '100%',
        textAlign: 'start',
        fontWeight: 600,
        fontSize: 14,
    },
    tagStyle:{
        fontWeight:'normal',
        whiteSpace:'nowrap',
        marginRight:10,
        fontSize:14
    },
    moodStyle:{
        fontWeight:'normal',
        marginTop: 10
    },
    divStyle:{
        display:'flex',
    }
};
const SingleMeasureComponent = class extends React.Component {
    renderFoodLogImg(images,beforeMeal,type,shouldRenderFoodLog){
        if(beforeMeal||type=='BP'||type=='PO'||!shouldRenderFoodLog){
           return;
        }

        const tabs =  _.map(images,(imgList,index)=> {
            return _.map(imgList.images, (img) => {
             const imgRender = <img src={img.link} width={100} height={100}/>;
             return<TabPane tab={imgRender} key={index} forceRender={true} className='imgRender'>
                     <span style={{
                         fontWeight: 600,
                         marginTop: 10,
                         marginRight:10,
                     }}>
                        Patient Notes (Food Entry)
                     </span>
                     <div style={{fontSize:14,fontWeight:500}}>
                         {imgList.note}
                     </div>
                   </TabPane>
            })
        })
        let  renderImgList = images.length == 0 ?
                              <div className='col-lg-12'>
                                  <span style={{
                                      fontWeight: 600,
                                      marginTop: 10,
                                      marginBottom: 10,
                                  }}>Food Diary Entry</span>
                                  <div style={{height:100,background:'#f7f7f7',
                                                display:'flex',alignItems:'center',marginTop:10,
                                                justifyContent:'center',color:'#c5c5c5'}}>
                                       No Diary Entry
                                   </div>
                               </div>
                               :
                                <div className='col-lg-12'>
                                    <span style={{
                                        fontWeight: 600,
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}>Food Diary Entry</span>
                                    <Tabs type="card" className='foodlogImgRender'>
                                        {tabs}
                                    </Tabs>
                                </div>

        return renderImgList;
    }

    renderMoods(doc){
        const { moodTags } = doc;
        const hasTags = moodTags.length>0;
        return <div style={styles.divStyle}>
                <div style={styles.patientNoteTitle}>
                    Mood and Symptoms
                   <div>
                        <span style={styles.moodStyle}>
                            {
                                 hasTags ? moodTags.join(', ') : '--'
                            }
                        </span>
                   </div>
                </div>
        </div>
    }

    renderTags(doc){
        let isEmpty = true;
        const keys =  Object.keys(tagsMap);
        _.forEach(keys,(k)=>{
            if(Array.isArray(doc[k])){
                isEmpty&=!(!!doc[k].length)
            }else{
                isEmpty&=!(doc[k]);
            }
        });
        let content='';
        if(isEmpty){
            content = <div>--</div>;
        }else{
            content = keys.map((k,i)=>{
                if(doc[k]) {
                    let content = <div style={styles.tagStyle} key={i}>
                                        <img src={tagsMapSrc[k]} style={{ marginRight: 5,height: 15 }}/>
                                        <span>{tagsMap[k]}</span>
                                  </div>;
                    return content;
                }
            })
        }
        return <div style={styles.divStyle}>
               <div style={styles.patientNoteTitle}>
                Tags
               <div style={{ marginTop:10,display:'flex' }}>
                {
                   content
                }
               </div>
            </div>
        </div>
    }

    render(){
        const { title,data,beforeMeal,foodLogList,showSingleReading,type,shouldRenderFoodLog } = this.props;
        let filteredList;
        if (type === 'PO') {
            filteredList = _.filter(data, (d) => d.blood_oxygen);
        } else {
            filteredList = _.filter(data,(d)=>d.blood_glucose||(d.systolic_blood_pressure&&d.diastolic_blood_pressure)&&(!!d.beforeMeal == beforeMeal));
        }
        const { mgdlValue,unit,className,readingValue,hasArrhythmia } = this.props.handleData(filteredList);
        const mealType = title&&title.split(' ')[1].toUpperCase();
        const images = _.filter(foodLogList,(d)=>d.mealType==mealType);
        let notes =[];
        let measureValue;
        if (type === 'PO') {
            measureValue = readingValue.strValue;
        } else {
            measureValue = mgdlValue ? mgdlValue : (_.get(readingValue,'systolic_blood_pressure_value','')+'/'+_.get(readingValue,'diastolic_blood_pressure_value','')+' '+_.get(readingValue,'heart_rate_value',''));
        }
        _.map(filteredList,(d)=> d.measure.user_notes ? notes.push(d.measure.user_notes):'');
        notes = notes.length > 0 ?  <span style={{fontWeight:'normal',fontSize:14}}> <p></p>{notes}</span>
                                 : <div><span style={{color:'#c5c5c5',fontWeight:'normal'}}>No Comment</span></div>;
        const splitClassName = className.split(' ');
        const classLen = splitClassName.length;
        const hasNoteClassName = (splitClassName[classLen-1]||'')+' '+(splitClassName[classLen-2]||'');
        const readingContainer = className.split(' ')[1]+" "+'readingContainer col-lg-12'+' '+hasNoteClassName;
        let triageInfo = _.get(data, '[0].triageInfo');
        triageInfo = triageInfo
            ?   <div style={{ fontWeight: 'normal', fontSize: 14}}>{triageInfo}</div>
            :   <div style={{ color: '#c5c5c5', fontWeight: 'normal' }}>No info</div>;

        return <div className='row' style={{ mingHeight:'400px',textAlign:'start',marginLeft:0,marginRight:0 }}>
                 <div className='col-lg-12'>
                     {helpers.getMeasureTimeDisplayText(_.get(data, '0')) }
                 </div>
                 <div className={readingContainer} style={{display:showSingleReading ? 'flex' : 'none',marginLeft:15}}>
                     <span style={{ fontWeight:'bold' }}>{ measureValue }</span><span style={{marginLeft:5}}> { unit }</span>
                     { hasArrhythmia&&<img src={'/image/arrhythmia-hr-icon-white.svg'}/>}
                 </div>
                 <div className='col-lg-12'>
                     {this.renderTags(data[0])}
                 </div>
                <div className='col-lg-12'>
                    {this.renderMoods(data[0])}
                </div>
                 <div className='col-lg-12 singleReadingContainer' style={{ textAlign:'start',fontSize:14 }}>
                     <span>Patient Notes (Measurement)</span>
                     {notes}
                 </div>
                 {type === 'BP' && <div className='col-lg-12 singleReadingContainer' style={{ textAlign:'start', fontSize:14 }}>
                     <span>Triage information</span>
                     {triageInfo}
                 </div>}
                 {this.renderFoodLogImg(images,beforeMeal,type,shouldRenderFoodLog)}
               </div>
    }

}

const withData = graphql(foodLogList, {
    options: (ownProps) => {
        const { date,patientId,title } = ownProps;
        const fromDate = moment(date).startOf('day');
        const toDate = moment(date).endOf('day');
        const titleSplited = title&&title.split(' ');
        const mealType = titleSplited.length ==3 ? titleSplited[1].toUpperCase() : titleSplited[0].toUpperCase();
        const  variables = { };
        variables.filters = {
            memberId:patientId,
            fromDate,
            toDate,
            mealType
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,foodLogList } = data;
        if(foodLogList&&foodLogList.data) {
            return {
                foodLogList:foodLogList.data,
                loading,
            }
        }
        return {
            loading
        }
    }
})

const mapToDispatch = (dispatch)=> {
    return {
        closeModal:()=>dispatch(closeModal()),
    }
}

export default compose(connect(null,mapToDispatch),withData)(SingleMeasureComponent);

