import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import { IHButton, message, IHForm, IHLoading, _ } from 'ihcomponent'
import { getFormProps } from '../components/OneTimeTaskComponent'
import { closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import { validateAntdForm, submittingStyle, createContainer, GQLHelper, RequestCache } from 'libModule/utils'
import actions from '../actions/OneTimeTaskActions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

// graphql
import assessmentListGQL from 'graphqlModule/assessmentList';
import addOneTimeTaskToEnrolledProgramGQL from 'graphqlModule/mutation/addOneTimeTaskToEnrolledProgram'

class Container extends Component {
  static displayName = 'patient/enrolledProgramInfo/containers/OneTimeTaskContainer'

  componentWillUnmount() {
    this.props.resetForm()
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-form-container'>
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const { onFieldsChange, fieldsValue, assessmentAndSurveyList, programmeTasks, healthCondition } = this.props
    const formProps = getFormProps({ fieldsValue, onFieldsChange, assessmentAndSurveyList, programmeTasks, healthCondition })

    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }

  renderFooter() {
    const { resetForm, closeModal, isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }

    const buttonProps = {
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        type: 'submit',
        disabled: isSubmitting,
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      },
      cancel: {
        onClick : (e)=>{
          resetForm()
          closeModal()
        },
        bsStyle: 'default',
        label: 'Cancel'
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Done'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  async submit() {
    // validate all fields before submission
    const formRef = this.baseForm
    const isFormValid = await validateAntdForm(formRef)
    if (!isFormValid) return

    const { closeModal, openErrorModal, resetForm, enrolledProgramId, enrolledProgramName, assessmentAndSurveyList } = this.props
    const fieldsValue = formRef.getValue()

    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')
    const selectedAssessment = _.find(_.get(assessmentAndSurveyList, 'data'), { nameKey: fieldsValue.assessment || fieldsValue.survey })
    let variables = {}
    let auditLogDetails = {}
    let taskVitalTypeMap = ''
    switch(fieldsValue.task) {
      case 'BG':
        taskVitalTypeMap = 'Blood Glucose'
        break
      case 'HR':
        taskVitalTypeMap = 'Blood Pressure & Heart Rate'
        break
      case 'HS':
        taskVitalTypeMap = 'Weight'
        break
      default:
        taskVitalTypeMap = ''
    }

    if (fieldsValue.task === 'ASSESSMENT' || fieldsValue.task === 'SURVEY') {
      variables = {
        id: enrolledProgramId,
        type: fieldsValue.task,
        assessment: {
          nameKey: fieldsValue.assessment || fieldsValue.survey
        }
      }
      auditLogDetails = {
        programName: enrolledProgramName,
        type: fieldsValue.task,
        name: selectedAssessment.name
      }
    }
    else {
      variables = {
        id: enrolledProgramId,
        type: fieldsValue.task
      }
      auditLogDetails = {
        programName: enrolledProgramName,
        type: taskVitalTypeMap
      }
    }

    try {
      const res = await this.props.addOneTimeTaskToEnrolledProgramGQL({variables})
      if (res.data.addOneTimeTaskToEnrolledProgram) {
        createAuditLog({
          action: I18N.get('auditLog.provider.onetimetask.createOneTimeTask'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: res
        })
        message.success('One-time Task Created Successfully')
        this.props.refetch()
        closeModal()
      }
    }
    catch (e) {
      createAuditLog({
        action: I18N.get('auditLog.provider.onetimetask.createOneTimeTask'),
        patientNRIC,
        patientName,
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })
      openErrorModal(GQLHelper.formatError(e))
    }
    finally {
      resetForm()
    }
  }
}

const mapState = (state, ownProps) => {
  return {
    ...state.patient.enrolledProgramInfo.OneTimeTask,
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    resetForm: () => dispatch(actions.resetForm()),
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: isSubmitting => dispatch(actions.setSubmitting(isSubmitting)),
  }
}

Container.propTypes = {
  mutate: PropTypes.func,
  resetForm: PropTypes.func,
  closeModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  setSubmitting: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onFieldsChange: PropTypes.func,
  fieldsValue: PropTypes.object,
  assessmentAndSurveyList: PropTypes.object,
  addOneTimeTaskToEnrolledProgramGQL: PropTypes.func,
  refetch: PropTypes.func,
  programmeTasks: PropTypes.array,
  healthCondition: PropTypes.string
}

export default createContainer(GQLHelper.wrapper([
  graphql(assessmentListGQL, {
    options : ()=>{
      return {
        variables : {
          /* Temp fix. TODO: Need BE to support 'all' to fetch all data */
          count: 999999,
          filters: {},
          sort: {
            field: 'NAME',
            direction: 'ASC'
          },
          page: 1
        }
      }
    },
    props : ({data})=>{
      return {
        loading : data.loading,
        assessmentAndSurveyList : data.assessmentList,
      }
    }
  }),
  graphql(addOneTimeTaskToEnrolledProgramGQL, {name : 'addOneTimeTaskToEnrolledProgramGQL'})
], Container), mapState, mapDispatch)
