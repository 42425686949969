import gql from 'graphql-tag'

export default gql`
  query broadcastById($id:EID!){
    broadcastById(id:$id){
      id,
      startTime,
      endTime,
      message,
      title,
      status
    }
  }
`
