import React from "react";
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import moment from 'moment-timezone';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { IHLoading } from 'ihcomponent';
import { CHART_SEVERITY, RESULT_COUNT_MAX } from "../../../lib/constants";
const freqMap = {
    'WEEKLY':7
}
const  ExerciseAreaChartContainer  = class extends React.Component {
    constructor(props){
        super(props);
    }
    getTarget = ()=>{
        const { currentProgram } = this.props;
        const exercise = _.filter(_.get(currentProgram,'tasks'),t=>t.type=='EXERCISE')[0];
        const exerciseGoal = _.get(exercise,'threshold.0.exerciseGoal',{});
        const { timeUnit,target } = exerciseGoal;
        const stepsPerUnit = `${Math.round(target/freqMap[timeUnit])} Steps / Day `;

        return { value:Math.round(target/freqMap[timeUnit]),label:stepsPerUnit };
    }

    getDayRange = ({allVisit,fromDate,toDate,maxDate,minDate})=>{
        let startDate = allVisit ? minDate: fromDate;
        let endDate = allVisit ? maxDate: toDate;
        let diffInDays = moment(endDate).diff(startDate,'d');
        if(diffInDays<33) {
            return 1;
        }
        if( diffInDays >= 190 ){
            return 14;
        }
        return 7;
    }
    getSummary = (vitalList2)=>{
        //using target from EP
        const target = this.getTarget().value;
        const total = vitalList2.length;
        const aboveTarget = _.filter(vitalList2,v=>_.get(v,'measure.steps.value') >= target).length;
        const belowTarget = _.filter(vitalList2,v=>_.get(v,'measure.steps.value') < target).length;

        return {total, aboveTarget,belowTarget,aboveRatio:(aboveTarget/total||0).toFixed(2),belowRatio:(belowTarget/total||0).toFixed(2) }
    }

    getSummary2 = (vitalList2)=>{
        const total = vitalList2.length;
        //using target from each threshold of measurement;
        const aboveTarget = _.filter(vitalList2,v=>_.get(v,'measure.steps.value') >= _.get(v,'threshold.0.exerciseGoal.target')/7).length;
        const belowTarget = _.filter(vitalList2,v=>_.get(v,'measure.steps.value') < _.get(v,'threshold.0.exerciseGoal.target')/7).length;

        return {total, aboveTarget,belowTarget,aboveRatio:(aboveTarget/total||0).toFixed(2),belowRatio:(belowTarget/total||0).toFixed(2) }
    }

    renderChart = ()=>{
        let { loading,vitalList2=[],fromDate,toDate, chartWidth,allVisit } = this.props;
        let joinedList = _.filter(vitalList2,(v2)=>(v2.measure.steps));
        joinedList.sort((v1,v2)=> (v1.date) - (v2.date));
        let maxDate = _.get(_.last(joinedList),'date');
        let minDate = _.get(_.first(joinedList),'date');
        let values = _.map(joinedList,(value)=>({
            y: _.get(value,'measure.steps.value'),
            x: moment(value.date).startOf('day').valueOf(),
            target: _.get(value,'threshold.0.exerciseGoal.target')/7,
            marker: {
                symbol: 'circle',
                halo:null,
                radius: 4,
                states: {
                    hover: {
                        fillColor: '#FCA642',
                        lineColor: 'white',
                        lineWidth: 0,
                        halo: null,
                        animation:{
                            duration: 20
                        }
                    }
                }
            },
            dataLabels:{
                enabled: value.details,
                color: '#0099FF',
                format: '{y} steps',
                style:{
                    fontSize: 12
                }
            },
        }));
        const {aboveTarget,belowTarget,aboveRatio,belowRatio,total } = this.getSummary2(vitalList2);
        const options =  {
            chart: {
                type: 'line',
                width: chartWidth
            },
            title: {
                align:'left',
                text:'Goal:' + this.getTarget().label,
                x:-10,
                style:{
                    fontSize:'12px',
                }
            },
            subtitle: {
                text: `<div>
                            <span>Total: ${total}</span>
                            <br/>
                            <span>Above Target: ${aboveTarget}(${aboveRatio*100}%)</span>
                            <br/>
                            <span>Below Target: ${belowTarget}(${belowRatio*100}%)</span>
                       </div>`,
                useHTML:true,
                align: 'right',
                x: -10
            },
            noData: "No Data",
            tooltip:{
                headerFormat:'',
                backgroundColor:'#FFFFFF',
                borderColor:'#D9D9D9',
                pointFormatter:function(){
                    const { y,category,target } = this;
                    const date = moment(category).format('MM/DD/YYYY');
                    return `<br>
                              Date: <b>${date}</b><br/>
                              Exercise: <b>${y} steps</b><br/>
                              Target:<b>${target}</b>
                            </br>`;
                },
                padding: 12,
                style:{
                    fontSize: 12,
                    width:'200px'
                },
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                }
            },
            plotBackgroundColor:'#0099FF',
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            xAxis: {
                type: 'datetime',
                tickWidth:2,
                tickInterval: 24 * 3600 * 1000*this.getDayRange({allVisit,fromDate,toDate,minDate,maxDate}),
                min: allVisit ? moment(minDate).startOf('day').valueOf() : fromDate.valueOf(),
                max: allVisit ? moment(maxDate).startOf('day').valueOf() : toDate.valueOf(),
                labels:{
                    formatter:(value)=>{
                        const xValue = value.value;
                        return moment(xValue).format('MM/DD/YYYY');
                    }
                },
                crosshair:{
                    width:"1",
                    color:'#00A3FF'
                }
            },
            yAxis: {
                title: {
                    text: 'Steps / Day'
                },
                plotLines:[{
                    value:this.getTarget().value,
                    width:2,
                    label:{
                        text: this.getTarget().value
                    }
                }],
                min: 0,
                gridLineDashStyle:'Dash'
            },
            credits: {
                enabled: false
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            series: [
                {
                    data: values,
                    name:'Visit',
                    states:{
                        hover:{
                            halo: null
                        }
                    },
                    findNearestPointBy:'xy',
                    linkedTo:'visit'
                }
            ],
        }
        return <HighchartsReact
          highcharts={Highcharts} options={options}
          callback={this.props.chartCallback}
        />
    }

    render(){
        let { loading } = this.props;
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};
        return <div style={style}> { loading ? <IHLoading/> : this.renderChart()} </div>
    }
}

const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate, patientId,allVisit } = ownProps;
        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.currentProgram.id,
                    "type": "EXERCISE",
                    fromDate:!allVisit ? Number(fromDate) : null,
                    toDate: !allVisit ? Number(toDate) : null,
                    severity: CHART_SEVERITY
                },
                "count": RESULT_COUNT_MAX, // retrieve all at once; intended behavior
                "sort": {field: 'DATE', direction: 'ASC'}
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            vitalList2: _.get(resultList,'data',[]),
            loading,
        }
    }
})
export default  compose(withResData)(ExerciseAreaChartContainer);

