import React from 'react';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';
import monthlyReviewList from 'graphqlModule/monthlyReviewList';
import MonthlyReviewPlanContainer from '../../workStation/container/MonthlyReviewPlanContainer';
import { getUserRole } from '../../../lib/utils';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
import { monthlyReviewStatusMap } from '../../workStation/constant';
import {helpers} from "../../visitNewWorkFlow/helper";
import {Icon, Tooltip} from "antd";
import { checkIfMonthlyReviewInCompleted } from '../../workStation/helper/monthlyReviewHelper';
import { useMonthlyReviewContext } from '../../monthlyReview/hooks/useMonthlyReview';

const TITLE_MAP = {
  MTPR: 'MTPR (Monthly Treatment Plan Review)',
  MTPR_ADDITIONAL: 'MTPR (Monthly Treatment Plan Review)',
};

const DEFAULT_MTPR = "MTPR";
import I18N from 'modulesAll/I18N';

const MTPRFinishedComponent = (props)=>{
    const { monthlyReview={} } = props;
    const { updatedAt,note } = monthlyReview;
    const role = helpers.getRole();
    const MRConstants =  I18N.get(`monthlyReviewPlan.manualMonthlyReview.${role}`);
    const date = moment(updatedAt).format('MM/DD/YYYY');
    return <div className={'generate-mtpr-finished'} style={{ marginBottom: 15 }}>
      <Icon type="check-circle" theme="filled" style={{marginRight: '12px', color:"#3BA767" }}/>
      {_.get(MRConstants, 'finishedBtnText')} {date}
      <Tooltip title={note} overlayStyle={{ marginLeft:10 }}>
        <Icon type="info-circle" theme="filled" stype={{fontSize: '14px'}}/>
      </Tooltip>
    </div>
}
const MonthlyReviewPlan = (props) => {
  const { monthlyReviewLoading, monthlyReview = {}, monthlyReviewRefetch, clinicGoals, draftSaved, containerRef } = props;
  const monthlyReviewContext = useMonthlyReviewContext();
  const type = _.get(monthlyReview, 'type', '');
  const status = _.get(monthlyReview, 'status');
  const isCompleted = status === monthlyReviewStatusMap.COMPLETED;
  const isCompletedByUser = isCompleted && _.get(monthlyReview,'reviewedBy.id',false);
  const shouldShowClinicalGoals = () => {
    return type.includes('MRE') || isCompletedByUser || ['CA'].includes(getUserRole()) || !['FOLLOW_UP'].includes(_.get(props, 'visit.type'));
  }
  if (monthlyReviewLoading) return null;
  if (shouldShowClinicalGoals()) {
    return <div>
              { isCompletedByUser && <MTPRFinishedComponent monthlyReview={monthlyReview}/> }
              {clinicGoals}
           </div>;
  }
  return (
    <div>
      <div ref={containerRef} style={{ boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.5)', padding: 30, margin: '20px 10px' }}>
        <h4 className='monthlyReviewPlanHeader'>
          {TITLE_MAP[type] || TITLE_MAP.MTPR}
          {draftSaved && renderDraftSavedText()}
        </h4>
        <p style={{ marginBottom: 20 }}>Please review patient’s clinical goals and make adjustment if necessary. Please perform the MTPR for this patient during the visit.</p>
        <MonthlyReviewPlanContainer
          {...props}
          {...monthlyReviewContext}
          defaultMonthlyReviewType={DEFAULT_MTPR}
          currentProgram={props.enrolledProgram}
          isWorkList
          monthlyReviewData={monthlyReview}
          monthlyReviewRefetch={monthlyReviewRefetch}
        />
      </div>
    </div>
  );
};

const withMonthlyReview = graphql(monthlyReviewList, {
  options: (ownProps) => {
    const { memberId } = ownProps;
    return {
      variables: {
        filters: {
          memberId,
          monthOfYear: moment().format('YYYYMM'),
          includeDraft: true
        },
        sort: {
            field: 'CREATED_AT',
            direction: 'DESC'
        }
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    };
  },
  props: ({ data }) => {
    const { loading, refetch } = data;
    if (loading) {
      return  {
        monthlyReviewLoading: loading,
      };
    }

    const monthlyReviewList = _.get(data, 'monthlyReviewList.data') || [];
    // let user finish any draft
    const draftMTPR = _.find(monthlyReviewList, { status: monthlyReviewStatusMap.DRAFT });
    const monthlyReview = draftMTPR || _.first(monthlyReviewList);

    return {
      monthlyReview,
      monthlyReviewRefetch: refetch,
    };
  }
})

export default compose(withMonthlyReview)(MonthlyReviewPlan);
