import { IHLoading, _ } from 'ihcomponent'
import { connect } from 'react-redux'
import React, { Component } from 'react';
import VitalFormContainer from './VitalFormContainer'
import MainPage from './MainPageContainer'
import { actionTypes as types, actionCreators as actions } from '../ducks/CommonDuck'
import AssessmentFormContainer from 'patientModule/Assessment/containers/AssessmentFormContainer'
import SurveyFormContainer from 'patientModule/Survey/containers/FormContainer'
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import { graphql, compose } from 'react-apollo';
import get from 'lodash/get'

import { labelCurrentinProgramBGTasks, trace } from '../../../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withProps } from 'recompose';

// container
class Container extends Component {
  static displayName = 'patient/Careplan/Vital/containers/CommonContainer'

  componentDidMount() {
    // for TemporaryButton.js use
    const { initVitalList, currentProgram, changePage } = this.props
    const program = currentProgram
    initVitalList(program)

    changePage()
  }
  render() {
    if (this.props.data.loading) {
      return <IHLoading className="vsm-patient-enrolledprogram-main" />
    }

    return (
      <div className='vsm-form-container vsm-careplan-page'>
        <div className="vsm-tab-container-enrollment">
          {this.renderCurrentPage()}
        </div>
      </div>
    )
  }

  renderCurrentPage() {
    const { Common, changePage } = this.props
    const { currentPage } = Common
    const program = this.props.data.enrolledProgram
    const careplanProps = {
      ...Common,
      ...this.props
    }
    const commonProps = {
      changePage,
      program
    }

    if (_.isNull(this.props.program)) {
      console.error('program created error, program is: ', program)
      return <div>There is an error retrieving your data, please try to refresh the browser.</div>
    }

    switch(currentPage) {
      case types.CAREPLAN_MAIN_PAGE:
        return <MainPage {...careplanProps} {...commonProps} />
      case types.CAREPLAN_VITAL_FORM_PAGE:
        return <VitalFormContainer {...commonProps} />
      case types.ASSESSMENT_FORM_PAGE:
        return <AssessmentFormContainer {...commonProps} />
      case types.SURVEY_FORM_PAGE:
        return <SurveyFormContainer {...commonProps} />
      default:
        return <MainPage {...careplanProps} {...commonProps} />
    }
  }
}

// mapState
const mapState = ({ patient }, ownProps) => {
  return {
    ...patient.Careplan
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    changePage: (page) => dispatch(actions.changePage(page)),
    initVitalList: (program) => dispatch(actions.initVitalList(program))
  }
}

const withData = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: get(ownProps, 'currentProgram.id'),
    },
    notifyOnNetworkStatusChange: true,
  }),
  props: (props) => labelCurrentinProgramBGTasks(['data', 'enrolledProgram', 'tasks'], props),
});

// PropTypes validation
Container.propTypes = {
  changePage: PropTypes.func,
  data: PropTypes.object,
  Common: PropTypes.object,
  initVitalList: PropTypes.func,
  currentProgram: PropTypes.object,
}

// connect container with mapState and mapDispatch then export
export default compose(
  withData,
  connect(mapState, mapDispatch),
)(Container);
