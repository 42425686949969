import configs from '../../../lib/config';
import { decodeId } from '../../../lib/helpers/common-helpers';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/patient-articles-controller/search_10
export const getPatientArticles = `${domain}/patient-articles/search`;
export default (params, requestOptions) => {
  const { patientId } = params || {};

  if (!patientId) {
    console.warn('No patient id found');
    return Promise.resolve(undefined);
  }

  const _params = {
    filter: { 
      patientId: decodeId(patientId),
    },
  };

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(_params),
    ...requestOptions
  };

  return fetch(getPatientArticles, fetchOptions).then((res) => res.json());
};