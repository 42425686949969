import moment from 'moment-timezone';
import getUserOrg from '../../layout/helper/getUserOrg';

export const TAB_TIME_FORMAT = 'ddd, MM/DD/YYYY';

export const APPOINTMENT_TIME_FORMAT = 'h:mm A';

export const SIMPLE_DATE_FORMAT = 'MM/DD/YYYY';

export const DATE_FORMAT_WITH_DAY_OF_WEEK = 'ddd, MM/DD/YYYY';

export const getMoment = date => moment(date);

// based on clinic timezone
export const getClinicTime = (time) => {
  const { timezone: remoteTZ } = getUserOrg();
  return moment(time).tz(remoteTZ);
}

export const formatDate = (date, formatString) => {
  return moment(date || undefined).format(formatString); // prevent null
};

export const getTodayFromTo = () => ({
  from: moment().startOf('day').valueOf(),
  to: moment().endOf('day').valueOf()
});

export const getNextDay = formatString => {
  const nextDay = moment().add(1, 'day');
  if(formatString) return nextDay.format(formatString);
  return nextDay;
};

export const getNextDayFromTo = () => ({
  from: getNextDay().startOf('day').valueOf(),
  to: getNextDay().endOf('day').valueOf()
});

export const getTimeDiff = (from, to, unit) => moment(from).diff(moment(to), unit);