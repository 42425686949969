import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment'
import LineChart from './LineChartComponentRevised'
import { alertColor } from '../constants/VitalListConstants'


const returnValidBP = (data) => {
  const validData = data.filter(d => d.taskStatus === 'COMPLETED')
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
  const diastolic = validData.map(d => ({
    Diastolic: d.diastolic_blood_pressure? d.diastolic_blood_pressure.value : null,
    date: d.date,
    id: d.id,
    key: 'Diastolic',
    datum: d }))
  const systolic =  validData.map(d => ({
    Systolic: d.systolic_blood_pressure? d.systolic_blood_pressure.value : null,
    date: d.date,
    key: 'Systolic',
    id: d.id,
    datum: d }))
  return [].concat(diastolic, systolic)
}

const returnValidHR = (data) => (
  data.filter(d => d.taskStatus === 'COMPLETED' && !!d.heart_rate)
  .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
  .map(d => ({
    'Heart Rate': d.heart_rate.value,
    key: 'Heart Rate',
    id: d.id,
    date: d.date,
    datum: d })
  )
)
const returnMissed = (data) => (
  data.filter(d => d.taskStatus === 'MISSED')
  .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .sort((a, b) => moment(a.date).toDate() > moment(b.date).toDate()? 1 : -1)
    .map(d => ({
      date: d.date,
      datum: d
    }))
)
const setFillColorBP = (d, key) => {
  let defaultFill = alertColor['NORMAL']
  if (!!key) {
    defaultFill = {
      'Diastolic':'#0099ff',
      'Systolic': '#642bb6'
    }[key]
  }
  if (d.datum.taskStatus === 'MISSED') return '#dcdcdc'
  return (!!d.datum.severity)? alertColor[d.datum.severity] : defaultFill
}
const setFillColorHR = () => {
  return alertColor['NORMAL']
}
const formatTooltipBP = (d) => {
  if (d.datum.taskStatus === 'MISSED') {
    return `Missed Measurement`
  }
  const {systolic_blood_pressure: sbp, diastolic_blood_pressure: dbp } = d.datum
  const bpUnit = sbp? sbp.unit : dbp? dbp.unit : 'mmHg'
  return `${sbp? sbp.value : '--'}/${dbp? dbp.value : '--'} ${bpUnit}`
}
const formatTooltipHR = (d) => {
  const { heart_rate: hr } = d.datum
  return `${hr.value} ${hr.unit}`
}
const BPChartComponent = ({ data, mode, range, thresholds_BP }) => {
  switch (mode) {
    case 'BP': {
      const chartData = returnValidBP(data)
      const missed = returnMissed(data)
      const setFillColor = setFillColorBP
      const formatTooltip = formatTooltipBP
      const p = {
        data: chartData,
        missed,
        value: ['Diastolic', 'Systolic'],
        lineColor: ['#0099ff', '#642bb6'],
        setFillColor,
        formatTooltip,
        thresholds: thresholds_BP,
        range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
      }
      return <LineChart {...p} />
    }
    case 'HR': {
      const chartData = returnValidHR(data)
      const missed = returnMissed(data)
      const setFillColor = setFillColorHR
      const formatTooltip = formatTooltipHR
      const p = {
        data: chartData,
        missed,
        value: ['Heart Rate'],
        lineColor: ['#0099ff'],
        setFillColor,
        formatTooltip,
        thresholds: {'Heart Rate': [null, null]},
        range: [range[0].startOf('day').toDate(), range[1].endOf('day').toDate()]
      }
      return <div style={{minHeight: '350px'}}><LineChart {...p} /></div>
    }
    default:
      return null
  }

}

BPChartComponent.propTypes = {
  data: PropTypes.array,
  mode: PropTypes.string,
  range: PropTypes.array,
  thresholds_BP: PropTypes.object
}

export default BPChartComponent
