import { React, IHButton, IHLoading } from 'ihcomponent';
import { compose, withProps, withState } from "recompose";
import {goPath} from 'libModule/utils';
import UserClass from 'modulesAll/graphql/class/User';
import { timeSpentListGQL, getUserTotalTimeSpent,timeSpentListMiniGQL } from '../query/index';
import { graphql } from "react-apollo/index";
import { patientGQL } from "../../../graphql/user";
import { Divider } from 'antd';
import '../style/index.scss';
import  { ProcessBarComponent,
          MinIndicatorComponent,
          TimeSpentReadingComponent,
          TimeSpentListComponent} from '../components/index';
import '../../../patient/main/components/bootstrap_white.css';
import { TIMESPENT_COUNT_MAX } from 'libModule/constants';
import { getTotalTimeOfAMonth, getPreMonthReading } from '../helpers';
import {connect} from "react-redux";

class PatientTimeSpentContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      currentMonthTotalTime: 0,
      preMonthTotalTime: 0
    };
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    const { loading, loadingTotalTimeSpent, timeSpentList, currentPage,curAndPreMonth } = nextProps;
    const newStates = { ...state };
    console.log(curAndPreMonth);
    if(!loading && !loadingTotalTimeSpent && currentPage === 1) {
      // only update current / previous month total time when it's on page 1
      const today = new Date();
      const actualMonthOfYear = (today.getFullYear() * 100) + (today.getMonth() + 1);
      newStates.currentMonthTotalTime = getTotalTimeOfAMonth(curAndPreMonth, actualMonthOfYear);
      newStates.preMonthTotalTime = getPreMonthReading(curAndPreMonth, actualMonthOfYear);
    }

    return newStates;
  };

  renderAvator(userId){
    if(userId)
    return (
      <a href="javascript:void(0)" className="v-avator">
        <img
          src={this.uc.format('avator')}
          onError={ () => this.onAvatarError(userId) }
        />
      </a>
    )
  };

  renderTopButton(userId) {
    const b1 = {
      label: '< Back to Patient Profile',
      size: 'large',
      type: 'link',
      onClick: () => {
        goPath('/patients/' + userId + '/enrolledprogram/default')
      }
    }
    return (
      <div className="returnButton">
        <IHButton {...b1} />
      </div>
    );
  };

  renderName(){
    if(!this.props.user) return '';

    // TODO: check for user status, it was hard-coded
    return (
      <div>
        <span className="v-name">
          {this.uc._get('profile.fullName')}
          <span>({this.uc.format('nric')})</span>
        </span>
        <span className={`v-status v-Active`}>Active</span>
      </div>
    );
  };

  renderTimeSpentReading(key,reading){
    return <TimeSpentReadingComponent k={key} reading={reading} />;
  };

  renderProgressBar(){
    const monthlyTimeSpent = _.get(this.props,'user.monthlyTimeSpent.totalTime',0);
    return <ProcessBarComponent monthlyTimeSpent={monthlyTimeSpent} />;
  };

  renderTimeSpentContent(){
    const {
      loading, loadingTotalTimeSpent, totalTimeSpent, totalTimeSpentMonths
    } = this.props;
    const { currentMonthTotalTime, preMonthTotalTime } = this.state;

    if(loading || loadingTotalTimeSpent) return <IHLoading />;

    // calculate total average
    const average = Math.round(totalTimeSpent/ totalTimeSpentMonths);

    return(
      <div>
        <div>
          <h4 className='timeSpentHeader'>
            Billable Time Details
          </h4>
        </div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',height:'100px'}}>
          <div style={{flex: 1}}>
            {this.renderTimeSpentReading('curMonth', currentMonthTotalTime)}
          </div>
          <div style={{flex: 3,display:'flex',flexDirection:'row',height:'100%',alignItems:'flex-end',marginBottom:15}}>
            <div>
              {this.renderProgressBar()}
              <MinIndicatorComponent/>
            </div>
            <div className='divider' style={{flex:1,display:'flex',justifyContent:'center',height:'100%'}}>
              <Divider type="vertical"/>
            </div>
          </div>
          <div style={{flex: 1}}>
            {this.renderTimeSpentReading('preMonth', preMonthTotalTime)}
          </div>
          <div style={{flex: 1}}>
            {this.renderTimeSpentReading('average', average)}
          </div>
        </div>
      </div>
    );
  };

  renderTags(){
    const tags = this.uc.format('tag');
    const len = tags.length;
    return (
      <div style={{paddingBottom: 10}}>
        {
          _.map(tags, (tag, index)=>{
            if(index==len-1) {
              return <span className="v-tag" key={index}>{tag}</span>;
            }
            else return <span className="v-tag" key={index}>{tag},</span>
          })
        }
      </div>
    );
  };

  renderTimeSpentList(){
    const {
      totalTimeSpentMonths, loading, timeSpentList, currentPage, setCurrentPage,year,month,curAndPreMonth
    } = this.props;
    return <TimeSpentListComponent
      data={timeSpentList}
      loading={loading}
      year={year}
      curAndPreMonth={curAndPreMonth}
      month={month}
      tablePagination={{
        current: currentPage,
        pageSize: TIMESPENT_COUNT_MAX,
        total: totalTimeSpentMonths,
        onChange: page => setCurrentPage(page)
      }}
    />;
  };

  render(){
    this.uc = new UserClass(this.props.user);
    const userId = _.get(this.props,'user.id');
    return(
      <div>
        <div className="vsm-main">
          {this.renderTopButton(userId)}
        </div>
        <div className="vsm-patient-member-detail">
          {this.renderAvator(userId)}
          <div style={{display:'flex',flexDirection:'column'}}>
            {this.renderName()}
            {this.renderTags()}
          </div>
        </div>
        <div style={{padding:'40px 80px 40px 80px'}}>
          {this.renderTimeSpentContent()}
        </div>
        {this.renderTimeSpentList()}
      </div>
    );
  };
}

const withData = graphql(patientGQL, {
    options: (props) => ({
      variables: { id: _.get(props, 'params.patientId')}
    }),
    props: ({data}) => ({
      user: data.user,
      timeSpent: _.get(data,'user.monthlyTimeSpent',0)
    })
})
const now = new Date();
const curMonth =  now.getMonth() + 1;
const curYear = now.getFullYear();
const getMonthsInNum = (months,year= new Date().getFullYear())=>{
    months = _.isArray(months) ? months : [months]
    return months.map(m=> m<=0  ? (year-1) * 100 + (12+m) : year * 100 + m);
}
const paresVar = (props,allMonths) => {
    // control the graphql options for timeSpentListData request
    const now = new Date();
    const {currentPage, year = curYear, month =curMonth} = props;
    const monthOfYear = getMonthsInNum( month,year);
    const timeSpentListDataOptions = {
        variables: {
            memberId: _.get(props, 'params.patientId'),
            monthOfYear: allMonths ? null : monthOfYear,
            count: TIMESPENT_COUNT_MAX,
            page: currentPage,
        }
    };

    // only need to make real-time request if page is 1
    // for realtime current month billable time
    timeSpentListDataOptions.fetchPolicy = 'network-only';
    return timeSpentListDataOptions ;
};

const timeSpentListData = graphql(timeSpentListGQL,{
    options: props => {
      const month = props.month || curMonth;
      return paresVar({ ...props, month: [month, month-1] });
    },
    props:({ data })=>
    ({
      timeSpentList: _.get(data, 'monthlyTimeSpentList.data') || [],
      totalTimeSpentMonths: _.get(data,'monthlyTimeSpentList.pageInfo.total') || 0,
      loading: data.loading
    })
});

const curAndPreMonth = graphql(timeSpentListMiniGQL,{
    options: (props)=>paresVar({...props, month:[curMonth,curMonth-1] },true),
    props:({ data })=>
        ({
            curAndPreMonth: _.get(data, 'monthlyTimeSpentList.data') || [],
            totalTimeSpentMonths: _.get(data,'monthlyTimeSpentList.pageInfo.total') || 0,
            loading: data.loading
        })

})
const totalTimeSpent = graphql(getUserTotalTimeSpent, {
  options: (props) => ({
    variables: { memberId: _.get(props, 'params.patientId') }
  }),
  props: ({ data }) => {
    if(data.error) console.error(data.error);
    return {
      totalTimeSpent: _.get(data, 'getMemberTotalTimeSpent') || 0,
      loadingTotalTimeSpent: data.loading
    };
  }
});
const mapToProps = (state,props)=>{
    return {
        year:state.patientTimeSpentReducer.year,
        month:state.patientTimeSpentReducer.month,
    }
};
export default compose(
  connect(mapToProps,null),
  totalTimeSpent,
  withData,timeSpentListData,curAndPreMonth,
  withState('currentPage', 'setCurrentPage', 1)
  )(PatientTimeSpentContainer);
