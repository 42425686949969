import {
  React, IHSearchTable, IHButton, _
} from 'ihcomponent'
import PropTypes from 'prop-types';
import { goPath, programConditionMap, decryptRole } from 'libModule/utils'
import { graphql, compose } from 'react-apollo'
import moment from 'moment'
import I18N from 'modulesAll/I18N'

//queries
import { adminProgramListWithSearch as adminProgramList } from 'graphqlModule/adminProgramList'
import createTableAction from 'libModule/table/TableActions';
import { connect } from 'react-redux';
import Store from 'libModule/reduxStore'

const tableActions = createTableAction('admin_program_list', {
  sortFieldMap: {
    //activatedAt: 'ACTIVATED_AT',
    modifiedAt: 'MODIFIED_AT',
    name: 'NAME',
  },
});

class Component extends React.Component{
  static displayName = 'program/ProgramListAdmin/containers/ListTable'

  componentWillUnmount() {
    if (!new RegExp(/^(\/programmes_admin)/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null);
    }
  }

  render(){
    return (
      <div>
        {this.renderTable()}
      </div>
    )
  }

  getTableProps() {
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort={}, page, search = '' } = this.props;
    const { loading = true, adminProgramList } = data;
    const list = _.get(adminProgramList,'data');
    const filters = _.get(adminProgramList, 'filters', []);
    let filtered
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        sortOrder:  sort.field === 'name' && sort.order,
      },
      {
        title: 'Health Condition',
        key: 'healthCondition',
        dataIndex: 'healthCondition',
        render: (value, row, index) => programConditionMap[value],
        filters: filters.map(key => ({text: programConditionMap[key], value: key})),
        filteredValue: _.get(filter, 'healthCondition', []),
        onFilter: (value, record) => record['healthCondition'].includes(value),
      },
      {
        title: 'Duration',
        key: 'duration',
        dataIndex: 'duration',
        render: (t, doc) => {
          return `${_.get(doc, 'duration.length')} ${_.get(doc, 'duration.calendar')}`;
        }
      },
      // {
      //   title: 'Created By',
      //   dataIndex: 'createdBy',
      //   key: 'createdBy',
      //   render: (t, doc)=>{
      //     return _.get(doc, 'createdBy.profile.fullName')
      //   }
      // },
      // {
      //   title: 'Created On',
      //   key: 'activatedAt',
      //   dataIndex: 'activatedAt',
      //   sorter: true,
      //   sortOrder: sort.field === 'activatedAt' && sort.order,
      //   render: (value, row, index) => {
      //     if (row.status === 'ACTIVE') {
      //       return value && moment(value).format('MMM-DD-YYYY hh:mm a')
      //     }
      //   }
      // },
      // {
      //   title: 'Last Modified By',
      //   dataIndex: 'modifiedBy',
      //   key: 'modifiedBy',
      //   render: (t, doc)=>{
      //     if (doc.status === 'ACTIVE') {
      //       return _.get(doc, 'modifiedBy.profile.fullName')
      //     }
      //   }
      // },
      {
        title: 'Last Modified On',
        key: 'modifiedAt',
        dataIndex: 'modifiedAt',
        sorter: true,
        sortOrder: sort.field === 'modifiedAt' && sort.order,
        render: (value, row, index) => {
          if (row.status === 'ACTIVE') {
            return value && moment(value).format('MMM-DD-YYYY hh:mm a')
          }
        }
      }
    ]

    if (decryptRole() === 'ADMIN:Admin') {
      const actions = {
        title: 'Actions',
        key: 'status',
        dataIndex: 'status',
        render: (value, row, index) => {
          const buttonProps = value === 'ACTIVE' ? {
            label: 'Edit',
            // icon: 'right',
            size: 'exsmall',
            bsStyle: 'primary',
            onClick: ()=>{
              const path = 'edit'
              goPath(`/programmes_admin/${row.id}/${path}`)
            },
            className: 'table-action-button',
          } : {
            label: 'Resume',
            // icon: 'right',
            size: 'exsmall',
            bsStyle: 'danger',
            onClick: ()=>{
              const path = 'edit-incomplete'
              goPath(`/programmes_admin/${row.id}/${path}`)
            },
            className: 'table-action-button danger',
          }
          const label = buttonProps.label
          delete buttonProps.label
          return (
            <IHButton {...buttonProps}>
              {label}
              <i className="fa fa-angle-right ih-right"></i>
            </IHButton>
          )
        },
        //comment out because backend graphql does not have feature to sort by status
        // sorter: true,
        // sortOrder: _.get(sort, 'field', null) === 'actions' ? _.get(sort, 'order', false) : null,
        filters: [
          {text: 'Edit', value: 'ACTIVE'},
          {text: 'Resume', value: 'DRAFT'}
        ],
        filteredValue: (filtered = _.get(filter, 'status', []), filtered.constructor !== Array? [filtered] : filtered),
        onFilter: (value, record) => record.status === value
      }
      columns.push(actions)
    }

    const totalCount = _.get(adminProgramList, 'pageInfo.total', 0);

    return {
      title: `${I18N.get('keywords.Programs')} (${totalCount})`,
      inputPlaceholder: 'Search by Name',
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      onChange: onTableChange,
      dataSource: list,
      loading,
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: totalCount,
      },
      // onSearch: (v)=>{
      //     this.props.setSearchVariables(v)
      // },
      columns,
      rowKey: 'id'
    }
  }

  renderTable(){
    const p = this.getTableProps()
    return <IHSearchTable {...p} />
  }
}

const withData = graphql(adminProgramList, {
  options: (ownProps) => {
    // currently the status only accepts a string, not an array
    const statusFilter = _.get(ownProps,'filter.status')
    if (statusFilter && statusFilter.constructor === Array) {
      if (statusFilter.length === 1) ownProps.filter.status = ownProps.filter.status[0]
      else if (statusFilter.length === 2) ownProps = _.omit(ownProps, 'filter.status')
    }
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      search: {
          fields: ['NAME'],
          match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: null
    };

    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction'),
      };
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    };
  },
});

const mapStateToProps = (state, ownProps) => ({
  ...state.globalTables.admin_program_list,
});

Component.propTypes = {
  programList: PropTypes.object,
  variables: PropTypes.object,
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  refetch: PropTypes.func,
  setSearchVariables: PropTypes.func,
  onTableChange: PropTypes.func,
  loading: PropTypes.bool,
}

export default compose(
  connect(mapStateToProps, () => tableActions),
  withData,
)(Component);
