import { React, IHUpload, _, IHLoading } from 'ihcomponent';
import config from 'libModule/config';

export default class extends React.Component {
  static displayName = 'patientView/manualInput/components/PhotoUploader'

  state = {
    loading: false,
    error: false,
    url: null,
  };

  onChange(states) {
    if (this.props.onChange) {
      this.props.onChange(states);
    }

    this.setState(states);
  }

  uploadAndGetUrl(request) {
    this.onChange({ loading: true, url: null, error: false });
    const reader = new FileReader();
    const headers = new Headers();
    headers.append('File-Name', request.file.name || 'photo');
    headers.append('GoThumb-File-Location', 'local');

    reader.addEventListener("load", () => {
      const dataURL = reader.result;
      //console.log(dataURL);
      //const dataURL = 'test-file';
      fetch(config.uploadFileUrl, { method: 'POST', body: dataURL, headers })
        .then(res => res.text())
        .then(url => _.split(url, '/files/')[1])
        .then(url => fetch(`${config.signFileURL}/${url}`, { method: 'GET', headers }))
        .then(res => res.text())
        .then(url => this.onChange({ loading: false, url, error: false }))
        .catch(err => {
          console.log('err ', err);
          this.onChange({ loading: false, url: null, error: true })
        });
    });
    reader.readAsDataURL(request.file);
  }

  render() {
    const { loading, url, error } = this.state;

    let content;
    if (loading) {
      content = <IHLoading />;
    } else if (url) {
      content = <img src={url} />;
    } else if (error) {
      content = <span className="v-bn">Retry</span>;
    } else {
      content = <span className="v-bn">Upload Photo</span>;
    }

    return (
      <div className="vsm-manual-input-uploadMeal-comp">
        <IHUpload customRequest={this.uploadAndGetUrl.bind(this)} showUploadList={false}>
          {content}
        </IHUpload>
      </div>
    );
  }
}
