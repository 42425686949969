const BMIStatusMap = {
        'NORMAL':{
            color:'#0083FF',
            displayName:'Normal'
        },
        'UNDERWEIGHT':{
            color:'#FFA22C',
            displayName:'Underweight'
        },
        'OVERWEIGHT':{
            color:'#D61CC6',
            displayName:'Overweight',
        },
        'OBESITY':{
            color:'#F80000',
            displayName:'Obesity'
        }
}

export default BMIStatusMap;
