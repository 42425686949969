import gql from 'graphql-tag';
import { ConditionsListSchema } from '../../graphql/schema/ConditionsList';

export const upsertPatientReferralMutate = gql` mutation upsertPatientReferral(
  $referredBy: EID,
  $patientId: EID,
  $recommendedVitals: [String],
  $eligiblePrgorams: [String],
  $deviceAccessbility: Boolean,
  $wouldJoin: Boolean,
  $haveSmartphone: Boolean,
  $conditionsToMonnitor: [String],
  $mntConditions: [String],
  $billableHealthConditions: [InputBillableHealthCondition],
  $devicePickup: [String],
  $deviceShip: [String],
  $shipAddresses: InputAddress,
  $helpedSetupAndUseDevice: Boolean,
  $enrolledAnother: Boolean,
  $patientDescribe: String,
  $status: InputReferralStatus,
  $clinicGoals: [InputClinicGoal],
  $discussedWithPatient: Boolean,
  $initialVisitId: EID,
  $techLevel: ReferTechLevelEnum,
  $wantMNTReferral: Boolean,
  $exclusionOtherReason: String,
  $healthConditions: [String],
  $diagnosisSource: DiagnosisSourceEnum
 ){
  upsertPatientReferral( referredBy:$referredBy,patientId:$patientId,eligiblePrgorams:$eligiblePrgorams,recommendedVitals:$recommendedVitals,deviceAccessbility:$deviceAccessbility,wouldJoin:$wouldJoin,haveSmartphone:$haveSmartphone,conditionsToMonnitor:$conditionsToMonnitor,
    billableHealthConditions:$billableHealthConditions,devicePickup:$devicePickup,deviceShip:$deviceShip,
    shipAddresses:$shipAddresses,helpedSetupAndUseDevice:$helpedSetupAndUseDevice,enrolledAnother:$enrolledAnother,patientDescribe:$patientDescribe,
    mntConditions: $mntConditions,
    status:$status,
    clinicGoals: $clinicGoals,
    discussedWithPatient: $discussedWithPatient,
    initialVisitId: $initialVisitId,
    techLevel: $techLevel,
    wantMNTReferral: $wantMNTReferral,
    exclusionOtherReason: $exclusionOtherReason,
    healthConditions: $healthConditions,
    diagnosisSource: $diagnosisSource
  ){
    referredBy  {
      id
      profile {
        lastName
      }
    }
  }
} `;

export const getPatientReferralQuery = gql`query getPatientReferral(
   $referredBy: EID,$patientId: EID
){
  getPatientReferral(referredBy:$referredBy,patientId:$patientId){
    referredBy {
      id
      profile {
        lastName
        fullName
      }
    }
    referredAt
    patientId
    recommendedVitals
    eligiblePrgorams
    deviceAccessbility
    wouldJoin
    haveSmartphone
    conditionsToMonnitor
    mntConditions
    diagnosisSource
    healthConditions
    billableHealthConditions {
      description
      code
    }
    devicePickup
    deviceShip
    shipAddresses {
      streetName
      city
      state
      country{
        code
        description
      }
      postCode
      unit
    }
    status {
      referStatus
      enrollStatus
      screeningStatus
      patientConsentEnroll
      confirmDevicesEnroll
      scheduleInitVisitEnroll
      basicInfoEnroll
      prgramEligibilityEnroll
      mntReferralEnroll
      clinicalGoalsEnroll
    }
    helpedSetupAndUseDevice
    enrolledAnother
    patientDescribe
    clinicGoals {
      conditionEnum
      goalEnum
    }
    discussedWithPatient
    techLevel
    initialVisit {
      id
      appointmentAt
      category
      reason
      member {
        id
        profile {
          ...on MemberProfile {
            doctor {
              id
            }
          }
        }
      }
    }
    techLevel
    wantMNTReferral
    exclusionOtherReason
  }
}
`;

export const getDischargedPatientReferralQuery = gql`query getDischargedPatientReferral(
  $referredBy: EID,$patientId: EID, $deleted: Boolean = true
){
 getPatientReferral(referredBy:$referredBy,patientId:$patientId, deleted: $deleted){
   id
   recommendedVitals
   eligiblePrgorams
   deviceAccessbility
   wouldJoin
   haveSmartphone
   conditionsToMonnitor
   diagnosisSource
   healthConditions
   billableHealthConditions {
     description
     code
   }
   enrolledAnother
   clinicGoals {
     conditionEnum
     goalEnum
   }
   discussedWithPatient
   techLevel
   exclusionOtherReason
 }
}
`;

export const getPatientReferralForRDCAEnroll = gql`query getPatientReferral(
  $referredBy: EID,$patientId: EID
){
 getPatientReferral(referredBy:$referredBy,patientId:$patientId){
  referredAt
  referredBy{
    id
    profile{
      fullName
    }
  }
  patientId
  recommendedVitals
  eligiblePrgorams
  conditionsToMonnitor
  diagnosisSource
  healthConditions
  billableHealthConditions {
    code
    description
  }
  clinicGoals {
    conditionEnum
    goalEnum
  }
  discussedWithPatient
  wantMNTReferral
 }
}
`;

export const remoteEnrollmentReferAndEnrollQuery = gql`
  query remoteEnrollmentReferAndEnroll($memberId: EID) {
    getRemoteEnrollment(memberId: $memberId) {
      status
      excludedReason
      doctorReferral
      ${ConditionsListSchema}
    }
  }
`;
