/**
 * Created by zizhangai on 2/12/17.
 */
import gql from 'graphql-tag'
import update from 'immutability-helper'

export default gql`
  mutation markProviderNoteError($id: EID!, $reason: String) {
    markProviderNoteError(id: $id, reason: $reason) {
      id
      category
      content
      error {
        markedBy {
          id
          createdAt
          profile {
            fullName
          }
        }
        reason
      }
      createdBy {
        id
        profile {
          fullName
        }
      }
      result {
        id
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
        }
        ... on BGResult {
          blood_glucose
          beforeMeal
          mealType
        }
        ... on HSResult {
          body_weight
        }
        ... on HRResult {
          heart_rate
        }
        assessmentFilled {
          id
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateQueries = {
  result: (prev, {mutationResult: {data}}) => {
    if (data.markProviderNoteError.result) {
      if (prev.result.id !== data.markProviderNoteError.result.id || !prev.result.notes) {
        return prev
      }
    }
    const newNote = data.markProviderNoteError
    const newList = prev.result.notes.data.map(n => n.id === newNote.id? newNote : n )
    return update(prev, {
      result: {
        notes: {
          data: {
            $set: newList
          }
        }
      }
    })
  },
  providerNoteList: (prev, {mutationResult: {data}}) => {
    const newNote = data.markProviderNoteError
    const newList = prev.providerNoteList.data.map(n => n.id === newNote.id? newNote : n)

    return update(prev, {
      providerNoteList: {
        data: {
          $set: newList
        }
      }
    })
  },
  providerNoteCursorList: (prev, { mutationResult: { data }}) => {
    const newNote = data.markProviderNoteError
    const newList = prev.providerNoteCursorList.data.map(n => n.id === newNote.id? newNote : n)
    return update(prev, {
      providerNoteCursorList: {
        data: {
          $set: newList
        }
      }
    })
  }
}
