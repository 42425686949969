import configs from '../../../../lib/config';
import { COMMON_HELPERS, decodeId } from '../../../../lib/helpers/common-helpers';

const domain = configs.UC_SERVER_URL;

export const setPatientInsurancePath = `${domain}/patient/`;
export default (
  params, 
  requestOptions
) => {
  const { patientId, hasCopay, copay, ...restParams } = params || {};


  if (!patientId) {
    console.warn('No patient id found');
    return Promise.resolve(undefined);
  }

  const decodedPatientId = COMMON_HELPERS.checkIfEncoded(patientId) ? decodeId(patientId): patientId;

  const _params = {
    hasCopay,
    copay: (hasCopay && copay) ? copay : '', // non nullable
    ...restParams,
  };

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(_params),
    ...requestOptions
  };

  const getPatientInsuranceUrl = `${setPatientInsurancePath}${decodedPatientId}/insurance`;

  return fetch(
    getPatientInsuranceUrl, 
    fetchOptions).then((res) => res.json()
  );
};