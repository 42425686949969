import React from 'react';
import AgoraVideoComponent from '../component/AgoraProviderVideoComponent';
import API from "../API";
const AgoraVideoContainer = class extends React.Component{
    constructor(){
        super();
        this.state ={
            agoraChannelName: 0,
            authorized: false,
            audienceMap:{ }
        }
    }
    setAudienceMap = async (uid)=>{
        const { audienceMap } = this.state;
        if(!audienceMap[uid]) {
            try {
                const user = await API.getChatUserInfo({id: uid});
                const fullName = _.get(user, 'data.user.profile.fullName', 'Unknow User');
                this.setState({
                    audienceMap: {
                        ...audienceMap,
                        [uid]: fullName
                    }
                })
            }catch(e){
                console.log(e);
                this.setState({
                    audienceMap: {
                        ...audienceMap,
                        [uid]: 'unknow User'
                    }
                })
            }
        }
    }

    render(){
        const { setAudienceMap,state } = this;
        const { audienceMap } = state;
        return <AgoraVideoComponent setAudienceMap={setAudienceMap}
                                    audienceMap={audienceMap}/>
    }
}

export default AgoraVideoContainer;
