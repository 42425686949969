const PreferenceType = `
  value
  note
`;
const sentVitalReminder = `sendVitalReminder {
   ${PreferenceType}
}`;
export const UserPreferenceSchema = `

  followupVisits {
    ${PreferenceType}
  }
  nutritionCoaching {
    ${PreferenceType}
  }
  ${sentVitalReminder}
`;
