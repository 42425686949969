import { combineReducers } from 'redux'
import Common from 'ProgramIncompleteModule/SurveyAlert/reducers/CommonReducer'
import AlertForm from 'ProgramIncompleteModule/SurveyAlert/reducers/AlertFormReducer'
import AlertList from 'ProgramIncompleteModule/SurveyAlert/reducers/AlertListReducer'

const surveyAlert = combineReducers({
  Common,
  AlertForm,
  AlertList
})

export default surveyAlert
