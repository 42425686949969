import DevicesList from '../components/DevicesList'
import {commonReplaceReturnDevicesFormActionsObject} from '../actions'
import { connect } from 'react-redux'
import { DataHelper } from 'libModule/utils'
import { openModal, closeModal } from 'layoutModule/actions/MainModal';


const DevicesListContainer = (props) => {
  return <DevicesList {...props} />
}

export default

  connect(
    (state, ownProps) => ({
      fieldsValue: state.Vendor.Patient.ReplaceReturnDevices.fieldsValue,
      currentUserFullName: DataHelper.getCurrentUser().profile.fullName
    })
    ,
    {
    //replace return devices form
    onFieldsChange: commonReplaceReturnDevicesFormActionsObject.onFieldsChange,
    closeModal,
    openModal
    }
  )(DevicesListContainer);
