import gql from 'graphql-tag';

export default gql`
  query getOrgTeams {
    getOrgTeams {
      organizationId
      teamIds
    }
  }
`
