import moment from 'moment-timezone';
import { USA_DATE } from '../../constants';

export const createCGMChartOptions = ({
  series,
  fromDate,
  toDate,
  max,
}) => ({
  chart: {
    type: 'line',
  },
  title: null,
  plotBackgroundColor: '#0099FF',
  xAxis: {
    type: 'datetime',
    tickWidth: 0,
    tickInterval: 24 * 3600 * 1000,
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis) => (moment(xAxis.value).format(USA_DATE)),
    },
  },
  yAxis: {
    title: {
      text: 'mg/dL',
    },
    min: 0,
    max,
    gridLineColor: 'transparent',
    plotBands: {
      from: 0,
      to: max,
      color: 'rgb(119,119,119,0.05)',
    }
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
  },
  tooltip: {
    shared: true,
    crosshairs: true,
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
  },
  time: {
    useUTC: false,
    timezone: moment.tz.guess(),
  },
  credits: {
    enabled: false,
  },
  series,
});
