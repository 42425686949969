import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CGMChartComponent } from '../components/CGMChartComponent';
import getCGMData from '../../api/getCGMData';

const fetchCGMData = async (
  patientId,
  startDate,
  endDate,
  cb,
) => {
  if (!startDate) return;
  const startDateStr = moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss');
  const endDateStr = moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss');
  const res = await getCGMData({
    patientId,
    startDate: startDateStr,
    endDate: endDateStr
  });
  if (!res || res.code !== 200) {
    // message.error(`Failed to load CGM data ${res.error || ''}`);
    return;
  }
  cb(res);
};

export const CGMChartContainer = ({
  patientId,
  fromDate,
  toDate,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchCGMData(
      patientId,
      fromDate,
      toDate,
      (res) => {
        const CGMData = _.map(_.get(res, 'data.data'), (d) => ({
          x: moment.utc(d.displayTime).local().valueOf(),
          y: d.value,
        }));
        setData(CGMData);
      }
    );
  }, [
    fromDate,
    toDate
  ]);

  return (
    <div className="cgm-chart__container">
      <CGMChartComponent
        data={data}
        fromDate={fromDate}
        toDate={toDate}
      />
    </div>
  );
};
