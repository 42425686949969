import {graphql} from "react-apollo";
import Client from "libModule/gqlClient";
import getClinicGoalTemplates from "../../graphql/getClinicGoalTemplates";
import {getDischargedPatientReferralQuery, getPatientReferralQuery, remoteEnrollmentReferAndEnrollQuery} from "../query/index";
import Query from '../../VideoChat/API';
import { enrolledProgramListMinimal } from 'modulesAll/graphql/enrolledProgramList';
import { userReferAndEnroll } from "../../graphql/user";

export const getPatientReferral = graphql(getPatientReferralQuery,{
	options:(ownProps)=>{
		return {
      variables: {
        patientId: ownProps.memberId,
      },
			fetchPolicy:'network-only'
		}
	},
	props:({data})=>{
		const {loading,getPatientReferral,refetch } = data;
		if(loading) {
			return {
				patientReferralLoading: loading
			}
		}
		return {
			patientReferral: getPatientReferral || {},
			getPatientReferralRefetch:refetch
		}
	}
});

export const getVitalsBasedOnIcdCodes = (codes = []) => {
	return Client.query({
		query: getClinicGoalTemplates,
		variables: {
			codes
		},
		// fetchPolicy: 'network-only',
	});
};

export const getMostRecentEP = graphql(enrolledProgramListMinimal, {
  options: (ownProps) => {
    const memberId = _.get(ownProps,'user.id') || _.get(ownProps, 'memberId');
    return {
      variables:{ 
        filters: { memberId },
        count: 1
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, enrolledProgramList, refetch, error } = data;

    if(error)
      console.error('referAndEnroll enrolledProgramList', error);

    const mostRecentEP = _.first(_.get(enrolledProgramList, 'data', []));
    const isEnrolled = _.get(mostRecentEP, 'status') === 'STARTED';
    const isDischarged = _.get(mostRecentEP, 'status') === 'DISCHARGED'

    return {
      loadingMostRecentEP: loading,
      mostRecentEP,
      isEnrolled,
      isDischarged,
      refetchMostRecentEP: refetch
    };
  }
});

export const getPatientConsent = graphql(Query.getPatientConsentForReferEnroll, {
  options: (ownProps) => {
    const memberId = _.get(ownProps,'user.id');
    return {
      variables:{ memberId },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getPatientConsent, refetch, error } = data;

    if(error)
      console.error('referAndEnroll getPatientConsent', error);

    return {
      loadingConsentInfo: loading,
      consentInfo: getPatientConsent || {},
      refetchConsentInfo: refetch
    };
  }
});

export const getUserData = graphql(userReferAndEnroll, {
	options: (ownProps) => {
		return {
			variables: {
				id: ownProps.memberId,
			},
			fetchPolicy: 'network-only',
		};
	},
	props: ({ data }) => {
		const { refetch } = data;
		return {
			userData: data,
			refetchUser: refetch
		};
	},
});

export const getDischargedPatientReferral = graphql(getDischargedPatientReferralQuery, {
  options: (ownProps) => ({
    variables: {
      patientId: ownProps.memberId,
      deleted: true
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => {
    const {loading,getPatientReferral,refetch } = data;

		if(loading) {
			return {
				dischargedPatientReferralLoading: loading
			}
		}
		return {
			dischargedPatientReferral: getPatientReferral || {},
			dischargedPatientReferralRefetch:refetch
		}
  }
});

export const getRemoteEnrollmentData = graphql(remoteEnrollmentReferAndEnrollQuery, {
  options: (ownProps) => ({
    variables: {
      memberId: ownProps.memberId
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => {
    const { loading, getRemoteEnrollment, refetch } = data;

    return {
      loadingRE: loading,
      remoteEnrollment: getRemoteEnrollment || null,
      refetchRE: refetch
    };
  }
})