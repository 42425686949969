import React from 'react';

const icon = status =>
  status == 'COMPLETED' ? (
    <img src={'/image/icon_check.png'} width={20} />
  ) : (
    <img src={'/image/Oval.png'} width={20} />
  );

const MA_WORKING_LIST = 'maWorkingList';
const RD_WORKING_LIST = 'rdWorkingList';

const STEPS = {
  mntReferral: {
    title: 'MNT Referral',
    key: 'mntReferral',
    statusPath: `${RD_WORKING_LIST}.MntReferral`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
    show: (visit) => _.get(visit, 'member.patientMntProgram.status') === 'REFERRED' || _.get(visit, 'member.patientMntProgram.visit.id') === _.get(visit, 'id'),
  },
  onboarding: {
    title: 'Onboarding',
    key: 'onboarding',
    statusPath: `${MA_WORKING_LIST}.onBoard`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  deviceReview: {
    title: 'Device Review',
    key: 'onboarding',
    statusPath: `${MA_WORKING_LIST}.onBoard`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  vitalsAndBilling: {
    title: 'Vitals & Billing',
    key: 'vitalsAndBilling',
    statusPath: `${MA_WORKING_LIST}.VisitBilling`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  medicationMgmt: {
    title: 'Medication Mgmt',
    key: 'medicationMgmt',
    statusPath: `${RD_WORKING_LIST}.Medication`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  labResults: {
    title: 'Lab Results',
    key: 'labResults',
    statusPath: `${MA_WORKING_LIST}.LabResults`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  followUp: {
    title: 'Follow-Up',
    key: 'followUp',
    statusPath: `${MA_WORKING_LIST}.FollowUpVisit`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
  diseaseHistory: {
    title: 'Disease History',
    key: 'diseaseHistory',
    statusPath: `${RD_WORKING_LIST}.ChronicDisease`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  lifestyle: {
    title: 'Lifestyle',
    key: 'lifestyle',
    statusPath: `${RD_WORKING_LIST}.LifestyleRoutine`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  socialSupport:{
    title: 'Social Support',
    key: 'socialSupport',
    statusPath: `${RD_WORKING_LIST}.SocialSupport`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  nutritionIntake: {
    title: 'Nutrition Intake',
    key: 'nutritionIntake',
    statusPath: `${RD_WORKING_LIST}.NutritionIntake`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  nutritionInterpretation: {
    title: 'Nutrition Interpretation',
    key: 'nutritionInterpretation',
    statusPath: `${RD_WORKING_LIST}.NutritionInterpretation`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  goals: {
    title: 'Goals',
    key: 'goals',
    statusPath: `${RD_WORKING_LIST}.GoalStatement`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  charting: {
    title: 'Charting',
    key: 'charting',
    statusPath: `${RD_WORKING_LIST}.Charting`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: true,
  },
  education: {
    title: 'Education',
    key: 'education',
    statusPath: `${RD_WORKING_LIST}.Education`,
    className: 'substep',
    icon: status => icon(status),
    noConsentOverlay: false,
  },
};

const VISIT_WORKLIST_MAP = {
  INIT: {
    type: 'INIT',
    name: 'Initial',
    steps: [
      { step: STEPS.mntReferral , disabled: false },
      { step: STEPS.onboarding , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      { step: STEPS.diseaseHistory , disabled: false },
      { step: STEPS.socialSupport, disabled: false },
      { step: STEPS.lifestyle , disabled: false },
      { step: STEPS.nutritionIntake , disabled: false },
      { step: STEPS.goals , disabled: false },
      { step: STEPS.education , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  FOLLOW_UP: {
    type: 'FOLLOW_UP',
    name: 'Follow Up',
    steps: [
      { step: STEPS.mntReferral , disabled: false },
      { step: STEPS.deviceReview , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      { step: STEPS.diseaseHistory , disabled: false },
      { step: STEPS.socialSupport, disabled: false },
      { step: STEPS.lifestyle , disabled: false },
      { step: STEPS.nutritionIntake , disabled: false },
      { step: STEPS.goals , disabled: false },
      { step: STEPS.education , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  ADDITIONAL: {
    type: 'ADDITIONAL',
    name: 'Additional',
    steps: [
      { step: STEPS.mntReferral , disabled: false },
      { step: STEPS.deviceReview , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      { step: STEPS.diseaseHistory , disabled: false },
      { step: STEPS.socialSupport, disabled: false },
      { step: STEPS.lifestyle , disabled: false },
      { step: STEPS.nutritionIntake , disabled: false },
      { step: STEPS.goals , disabled: false },
      { step: STEPS.education , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  TECH: {
    type: 'TECH',
    name: 'Tech Visit',
    steps: [
      { step: STEPS.deviceReview , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  MNT: {
    type: 'MNT',
    name: 'MNT',
    steps: [
      { step: STEPS.mntReferral , disabled: false },
      { step: STEPS.deviceReview , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      { step: STEPS.diseaseHistory , disabled: false },
      { step: STEPS.socialSupport, disabled: false },
      { step: STEPS.lifestyle , disabled: false },
      { step: STEPS.nutritionIntake , disabled: false },
      { step: STEPS.nutritionInterpretation , disabled: false },
      { step: STEPS.goals , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  CLINIC_FOLLOW_UP: {
    type: 'CLINIC_FOLLOW_UP',
    name: 'General',
    steps: [
      { step: STEPS.onboarding , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      { step: STEPS.diseaseHistory , disabled: false },
      { step: STEPS.socialSupport, disabled: false },
      { step: STEPS.lifestyle , disabled: false },
      { step: STEPS.nutritionIntake , disabled: false },
      { step: STEPS.goals , disabled: false },
      { step: STEPS.education , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  },
  TECH_ONBOARDING :{
    type: 'TECH_ONBOARDING',
    name: 'Tech Onboarding',
    steps: [
      { step: STEPS.onboarding , disabled: false },
      { step: STEPS.vitalsAndBilling , disabled: false },
      { step: STEPS.medicationMgmt , disabled: false },
      { step: STEPS.labResults , disabled: false },
      // { step: STEPS.diseaseHistory , disabled: false },
      // { step: STEPS.lifestyle , disabled: false },
      // { step: STEPS.nutritionIntake , disabled: false },
      // { step: STEPS.goals , disabled: false },
      { step: STEPS.education , disabled: false },
      { step: STEPS.charting , disabled: false },
      { step: STEPS.followUp , disabled: false },
    ],
  }
}

export {
  STEPS,
  VISIT_WORKLIST_MAP,
};
