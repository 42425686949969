import { connect } from 'react-redux'
import get from 'lodash/get'
import actions from '../actions';
import { moment} from "../../../../../package/IHComponent";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup,ListGroupItem } from 'react-bootstrap';
import { API } from '../actions/api';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import I18N from 'modulesAll/I18N'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { decryptRole } from 'libModule/utils';
import roleMap from '../../layout/constants/roleMap';
import markAsUnreadRoles from '../constants/markAsUnreadRoles';
import helper, { checkShouldShowChatRedDot } from "../helper/index";
import { DEFAULT_PAGE_SIZE } from '../constants';
const timeFormatString = I18N.get('dates.LTS'); // 'h:mm A'
const dateFormat = I18N.get('dates.L'); // 'MM/DD/YYYY'
import { Spin } from 'antd';
import { ChatChannelPatientNameComponent } from './ChatChannelPatientName';

class ChannelListComponent extends Component {
    static displayName = 'chat/components/ChannelListComponent';

    constructor(props) {
        super(props);
        this.state = {
            loadingMoreChannels: false,
            allChannelsLoaded: false
        }
        this.renderChannels = this.renderChannels.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.renderLastMsgTime = this.renderLastMsgTime.bind(this);
        this.renderLastMsg = this.renderLastMsg.bind(this);
        this.onAvatarError = this.onAvatarError.bind(this);
    }

    onAvatarError(id) {
      const encodedId = btoa(`accounts:${id}`);
      API.getUserAvatar(encodedId)
        .then( res => {
          const userProfile = get(res.data.user, 'profile') || {};
          const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];

          const newUser = {
            uuid: currentUserId,
            firstName: userProfile.firstName ||'unknown',
            lastName: userProfile.lastName || 'unknown',
            avatar: _.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
          };

          this.props.addToUserMap(newUser);
        })
        .catch(err => console.log('onAvatarError, err: ', err));
    }

    getAvatar(patientId) {
      const avatarUrl = _.get(this, `props.userMap.${patientId}.avatar`) || '';
      return <img src={avatarUrl} alt='' className='avatar' onError={ () => this.onAvatarError(patientId) } />
    }

    renderUnreadCounter(patientId, channelId){
        const shouldShowRedDot = checkShouldShowChatRedDot(channelId);
        return (
          <div 
            className={
              `\
              col-lg-2 \
              channelAvatarContainer \
              colUpdate \
              ${shouldShowRedDot ? 'v-badge' : 'channelAvatarContainerWithoutCounter'}
              `
            }
            data-content={~~shouldShowRedDot}
          >
            { this.getAvatar(patientId) }
          </div>
        );
    }

    sendAckHandler(val){
      if (val) {
        const clickedChannel = this.props.channels[val];
        helper.sendACKMessage(val, clickedChannel);
      }
    }

    handleClickEvent(curChannel){
        // if current channel is same as selected channel, do not send ACK messages
       if(curChannel=== this.props.selectedChannel){
           return;
       }

       // for user's initial channel selection, previous channel not same as current channel, send ACK
       this.sendAckHandler(curChannel);

       this.props.setSelectedChannel(curChannel);
        Mixpanel.track('clicked','chanel','messages',{ CHANELID : curChannel})

        //fetchHistory(curChannel,channels[curChannel].patientId, _.get(channels,`${curChannel}.lastMessageTimestamp`),false,true);
       // }
       //will not move to bottom
    }

    renderLastMsgTime(lastMsgTime) {
      const dateOfLastMsgTime = moment(lastMsgTime/1e4).format(dateFormat);

      if (DATE_HELPERS.isToday(moment(dateOfLastMsgTime, dateFormat))) {
        return moment(lastMsgTime/1e4).format(timeFormatString);
      }

      else { // default


          if (lastMsgTime > 0) {
            return dateOfLastMsgTime;
          } else {
            return '';
          }
      }
    }


    renderLastMsg(lastMsgText) {
      if (lastMsgText.trim().length > 25) {
        return lastMsgText.substring(0, 25) + '...';
      } else {
        return lastMsgText;
      }
    }
   
    markAsUnread(val, e){
      const { props } = this;
      const channels = props.channels;
      const curChannel = channels[val];
      const curChLastMsgIndex = curChannel.history.length - 1;
      const pId = val.split('-')[1];

      // console.log('leaveChannel: ', leaveChannel, ' lastMsg: ', lastMsg);
      if(curChannel.counter === 0) {
        helper.sendACKMessage(val, curChannel, 'markAsUnread');
      }
      
      if(props.selectedChannel === val) {
        Mixpanel.track('clicked','mark_as_unread','message_channel_list',{ PATIENT_ID:pId });
        this.props.setSelectedChannel("");
      }

      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    handleGetMoreChannels = async ()=>{
        const { lastMsgTSInOrg } = this.props;
        const { timestamp,pageNumber } = lastMsgTSInOrg['YWNjb3VudHM6NWFlNTM5YzkzN2M3NDAwMDE0YzM3MmM4'];
        const variables = {
            fromTimestamp: timestamp,
            pageNumber:pageNumber,
            pageSize: DEFAULT_PAGE_SIZE,
            teamIds: helper.getCurrentUserTokenAndTeamIds().teamsId,
            unread: false
        }
        this.setState({ loadingMoreChannels: true }, async  () => {
          await helper.getChannelsAndHistoryWithCHS(variables, { isFetchingMoreChannels: true });
          this.setState({ loadingMoreChannels: false });
        });
    }

    getMoreChannelsBtn = ()=>{
        const { loadingMoreChannels } = this.state;
        const { hasMoreChannels } = this.props;
        return loadingMoreChannels ? <Spin className={'loadMoreMsgSpinner'}/> : hasMoreChannels&&<div className={'moreChannelsDiv'}>
                                    <span className={'show-more'} onClick={ this.handleGetMoreChannels } >More Channels</span>
                                </div>
    }
    renderChannelList(val,key,showMoreBtn){
        const { props } = this;
        const channels = props.channels;
        const userMap = props.userMap;
        const curChannel = channels[val];
        const patientId = curChannel.patientId;
        const curUserInfo = userMap&&userMap[patientId];
        const { lastMsgSender,lastMsgText,lastMsgTime } = curChannel;
        const lastMsgTimeFormatted = this.renderLastMsgTime(lastMsgTime);
        const lastMessageText = this.renderLastMsg(lastMsgText||'');
        const unreadCount = curChannel.counter;
        const currentUserRole = decryptRole();
        return (
            <React.Fragment key={key} >
            <ListGroupItem className={ props.selectedChannel === val ? 'channelItemContainer selected-channelItemContainer' : 'channelItemContainer' }
                           onClick={()=>this.handleClickEvent(val)}
                           channel = { val }
            >
                <div className='row channelItem rowUpdate channel-list-container'>
                    {this.renderUnreadCounter(patientId, val)}
                    <div className='col-lg-10 colUpdate channelTextContainer'>
                        <div className='row rowUpdate channelTextTop'>
                            <div className='col-lg-8 colUpdate name'>
                                <ChatChannelPatientNameComponent 
                                  firstName={_.get(curUserInfo, 'firstName')}
                                  lastName={_.get(curUserInfo, 'lastName')}
                                />
                            </div>
                            {
                              markAsUnreadRoles.includes(roleMap[currentUserRole]) && unreadCount === 0 && 
                              <div className='col-lg-4 colUpdate mark' style={{ fontSize: 12 }}>
                                <a herf = "javascript:void(0);" onClick={(e)=>this.markAsUnread(val, e)}>
                                  Mark as unread
                                </a>
                              </div>
                            }
                        </div>
                        <div className='row rowUpdate channelTextBottom'>
                            <div className='col-lg-8 colUpdate channelTextBottom message' style={{ minHeight: 14 }}>
                              <span>
                                {`${lastMsgSender||''}${(lastMsgSender && lastMessageText) ? ': ' + lastMessageText : lastMessageText} `}
                              </span>
                            </div>
                            <div className='col-lg-4 colUpdate time'>
                                { lastMsgTimeFormatted }
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
                {showMoreBtn&&this.getMoreChannelsBtn()}
            </React.Fragment>
        );
    }

    // sortChannels(filteredChs) {
    //   const { props } = this;
    //
    //   // put object key inside ea object
    //   const reformatted = filteredChs.map( val => {
    //     let newObj = _.clone(props.channels[val]);
    //     newObj.id = val;
    //     return newObj;
    //   });
    //   const sortedCh = _.orderBy(reformatted, ['counter','lastMsgTime'], ['desc','desc']);
    //   return sortedCh;
    // }
    //
    // filterChannels() {
    //   const { channelStatus,channels } = this.props;
    //   const channelsArr = Object.keys(this.props.channels);
    //   // render only channels that belong to user's team (of selected organization)
    //   const curUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //   const teamIds = curUser.team.map(val => atob(val.id).split(':')[1]);
    //
    //   const teamIdMap = {};
    //   teamIds.forEach(val => teamIdMap[val] = true);
    //   const onlyShowUnread = channelStatus!=='ALL';
    //   let filterCondition = (val)=>{
    //       return teamIdMap[val.split('-')[0]] && (onlyShowUnread ? channels[val].counter:true );
    //   }
    //   return channelsArr.filter(val => filterCondition(val) );
    // }
    renderChannels() {
      const { props } = this;
      const { showLoadMoreBtn,sortedChannels,channels } = props;
        // const channelsArr = Object.keys(props.channels);
      if (sortedChannels.length > 0) {
          // const filtered = this.filterChannels();
          // console.log(filtered);
          // const sortedCh = this.sortChannels(filtered);

          return sortedChannels.map((val, key) => {
             return this.renderChannelList(val, key,(showLoadMoreBtn&&(key===sortedChannels.length-1)));
          })
      }
      else
          {
              return null; // nothing in props.channels!
          }

    }
    render() {
      const { props } = this;
      const { useChs } = props;
      const className='channelListGroup'.concat( !useChs ? ' isPubNub':'')
      return (
        <div ref='channelList' className='people-list colUpdate' id='people-list'>
            <ListGroup style={{ maxHeight: 'calc(100vh - 100px)' }} className={className}>
              { props.channels ? this.renderChannels() : 'Loading' }
            </ListGroup>
        </div>
        );
    }
}

const mapState = ({ chat }, ownProps) => {

  return {
    selectedChannel: chat.main.selectedChannel,
    channels: chat.main.channels,
    userMap: chat.main.userMap,
    useChs: chat.main.useChs,
    isFullyLoaded:chat.main.isFullyLoaded,
    lastMsgTSInOrg: chat.main.lastMsgTSInOrg,
    hasMoreChannels:chat.main.hasMoreChannels
  }
};

const mapDispatch = (dispatch) => {
    return {
        addChatHistory: (...args) => dispatch(actions.addChatHistory(...args)),
        updateChannelInfo: (channel, msgObj, isbatch, isFetchingMoreChannels) => dispatch(actions.updateChannelInfo(channel, msgObj, isbatch, isFetchingMoreChannels)),
        initUnreadCounter:(channel,unreadMsgCount)=>dispatch(actions.initUnreadCounter(channel,unreadMsgCount)),
        setUserMap: userMap => dispatch(actions.setUserMap(userMap)),
        setIsFullyLoaded: flag => dispatch(actions.setIsFullyLoaded(flag)),
        setHasMoreChannels: flag => dispatch(actions.setHasMoreChannels(flag)),
        setLastMSGTOfOrg: (org,timestamp,pageNumber)=>dispatch(actions.setLastMSGTOfOrg(org,timestamp,pageNumber))
    }
}
ChannelListComponent.propTypes = {
  userMap: PropTypes.object,
  channels: PropTypes.object,
  selectedChannel: PropTypes.string
};

export default connect(mapState,mapDispatch)(ChannelListComponent);
