import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertBG from 'modulesAll/graphql/insertBG';
import ManualInputContainer from './ManualInputContainer';
import { patientGlucoseInputValidator } from 'libModule/validator';
import { convertUnits } from 'libModule/utils/convertUnits'

const VSM_US_units = convertUnits('VSM_US');

const BEFORE_MEAL_OPTIONS = [{ name: 'Before', value: 'before' }, { name: 'After', value: 'after' }];
const MEAL_TYPE_OPTIONS = [
  { name: 'Breakfast', value: 'BREAKFAST' },
  { name: 'Lunch', value: 'LUNCH' },
  { name: 'Dinner', value: 'DINNER' },
  { name: 'Supper', value: 'SNACK' },
];

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/BGInputContainer'

  getVitalForm() {
    const { beforeMeal, mealType } = this.props;
    const beforeMealValue = beforeMeal === 'BEFORE_MEAL' ? 'before':
      beforeMeal === 'AFTER_MEAL' ? 'after': '';

    return [
      {
        type: 'rowGroup',
        rowGroupSpan: 24,
        gutter: 10,
        className: 'number-row',
        items: [
          {
            key: 'glucose',
            label: `Glucose ${VSM_US_units.BG.unit}`,
            type: 'inputNumber',
            wrapperCol: { span: 8 },
            rules: [
              {validator: (rule, value, callback) => callback(patientGlucoseInputValidator(value))}
            ],
          },
        ]
      },
      {
        key: 'beforeMeal',
        label: 'Before Or After',
        type: 'select',
        option: BEFORE_MEAL_OPTIONS,
        initialValue: beforeMealValue,
        disabled: beforeMeal && beforeMeal !== 'ANY_TIME',
        wrapperCol: { span: 16 },
        rules: [
          {required : true, message : 'before meal is required'},
        ],
      },
      {
        key: 'mealType',
        label: 'Meal',
        type: 'select',
        option: MEAL_TYPE_OPTIONS,
        initialValue: mealType,
        disabled: beforeMeal && beforeMeal !== 'ANY_TIME',
        wrapperCol: { span: 16 },
        rules: [
          {required : true, message : 'meal type is required'},
        ],
      },
    ];
  }

  insertVital(values, commonFields) {
    const { glucose, mealType, beforeMeal } = values;

    const variables = {
      ...commonFields,
      glucose: { value: glucose, unit: VSM_US_units.BG.unit },
      mealType,
      beforeMeal: beforeMeal === 'before',
    };

    return this.props.insertBG({ variables })
      .then(res => _.get(res, 'data.insertBG.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Blood Glucose"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertBG, { name: 'insertBG' })
)(component);
