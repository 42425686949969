/*
  This Component will return PDF rendered in an iframe, using the signedUrl.
  GoThumb's 15min signedUrl validity has been handled.
  signedUrl will only be fetch if empty or expired.

  Trigger to fetch: on render
*/

import PropTypes from 'prop-types';

import React from 'react';
import { _ } from 'ihcomponent'
import config from 'libModule/config'

class GoThumbPDFComponent extends React.Component {
  static displayName = 'GoThumbPDFComponent'

  static cache = {}

  componentWillMount() {
    this.isUnmounted = false
  }
  componentWillUnmount() {
    this.isUnmounted = true
  }

  updateForm = () => {
    if (this.isUnmounted) return
    const tar = this.form
    if (!tar) return
    const originUrl = this.props.filename
    const cacheItem = GoThumbPDFComponent.cache[originUrl]

    if(tar.contentWindow.renderPDFNoPagination && !cacheItem.rendering){
        cacheItem.rendering = true
        tar.contentWindow.renderPDFNoPagination(cacheItem.signedUrl)
    }
    else{
        tar.onload = function(){
            tar.contentWindow.renderPDFNoPagination(cacheItem.signedUrl)
        }
    }
  }
  componentDidMount() {
    const originUrl = this.props.filename
    const cacheItem = GoThumbPDFComponent.cache[originUrl]
    if (!cacheItem || Date.now() - cacheItem.timestamp > 15 * 60 * 1000) {
      this.request(originUrl).then(
        this.updateForm
      )
    } else {
      this.updateForm()
    }
  }

  request(url) {
    GoThumbPDFComponent.cache[url] = { timestamp: 0, loading: true, rendering: false, errorMessage: '' }

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    const myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    return fetch(`${config.signFileURL}/${url}`, myInit)
      .then(response =>
        response.text()
      )
      .then(signedUrl => {
        GoThumbPDFComponent.cache[url] = { timestamp: Date.now(), signedUrl, loading: false, rendering: false }
      })
      .catch((error) => {
        GoThumbPDFComponent.cache[url] = { timestamp: Date.now(), signedUrl: '', loading: false, rendering: false, errorMessage: error}
      });
  }

  render() {
    const originUrl = this.props.filename
    const cacheItem = GoThumbPDFComponent.cache[originUrl]

    const defaultStyles = {
        width : '100%',
        height : '100%',
        border : 'none'
    }
    const style = this.props.style ? this.props.style : defaultStyles

    if (_.size(cacheItem && cacheItem.errorMessage) > 0) {
      return <h3> Error retrieving consent form. </h3>
    }
    return <iframe ref={(form) => {this.form = form}} src="/renderPDFNoPagination.html" style={style}/>
  }
}

GoThumbPDFComponent.propTypes = {
  filename: PropTypes.string,
  style: PropTypes.object
}

export default GoThumbPDFComponent
