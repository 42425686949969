import {
    React,
    BaseComponent,
    ND
} from './util'

const Component = class extends BaseComponent{
    render(){
        return <ND.Icon {...this.props} className="IH-Icon" />
    }
}

Component.propTypes = {

}

export default Component
