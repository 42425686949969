import React from 'react';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

const SignOffCarePlan = (props) => {
  const { carePlan } = props;

  return(
    <div className='signOffItem'>
      <div className='header'>
        Care plan
      </div>
      <div className='signOffCard signOffCarePlan'>
        <div className='description'>
          Edited section:
        </div>
        {
          _.map(carePlan.histories, (history) => {
            const { id, sections, updatedBy, updatedAt } = history;
            const updatedByName = _.get(updatedBy, 'profile.fullName');
            if(!sections)
              return null;
            return <div key={id} className='description note'>
              <div>
                {sections}
              </div>
              <SignOffSubmitInfo 
                submittedByLabel=' '
                submittedBy={updatedByName}
                submittedAtLabel=' '
                submittedAt={updatedAt}
              />
            </div>
          })
        }
        <div className='description' style={{ marginTop: 12 }}>
          Please check patient profile &gt; Care plan tab to view the details
        </div>
      </div>
    </div>
  );
};

SignOffCarePlan.propTypes = {};
export default SignOffCarePlan;