import React from "react";
import ProviderSmartAlertContainer from "./ProviderSmartAlertContainer";
import {compose, graphql} from "react-apollo";
import smartAlertTaskList from "../query/smartAlertTasksList";
import moment from "moment";
const ProviderSmartAlertContainerWrapper = class extends React.Component{
	constructor(props) {
		super(props);
		let fromDate = moment().subtract(7,'day').startOf('day');
		let toDate = moment().endOf('day');
		this.state = {
			fromDate,
			toDate,
			status: null
		}
	}
	setSelectedRange = ({ fromDate, toDate })=>{
		this.setState({
			fromDate,toDate
		})
	}
	setStatus = (status)=>{
		this.setState({
			status
		})
	}
	render() {
		const { fromDate,toDate,status } = this.state;
		// const { loading,smartAlertTasksList } = this.props;
		// if(loading) return <div>Loading....</div>
		return <ProviderSmartAlertContainer fromDate={fromDate} toDate={toDate} setStatus={this.setStatus}
		                                    setSelectedRange={this.setSelectedRange} status={status}/>
	}
}
// const  smartAlertTasksList = graphql(smartAlertTaskList,{
// 	options:(ownProps)=>{
// 		let variables = {};
// 		let filter = { categories: ['PROVIDER']};
// 		variables.count = 100;
// 		variables.filters = filter;
//
// 		return {
// 			variables:variables,
// 			fetchPolicy:'network-only'
// 		}
// 	},
// 	props:({data})=> {
// 		const { smartAlertTasksList, loading } = data;
// 		if (loading) {
// 			return {
// 				loading
// 			}
// 		}
//
// 		return {
// 			smartAlertTasksList: smartAlertTasksList.data,
// 			pageInfo:smartAlertTasksList.pageInfo
// 		};
// 	}
// })

export default (ProviderSmartAlertContainerWrapper);