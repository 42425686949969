import React from 'react';
import WeeklyEnrollmentsContainer from './WeeklyEnrollmentsContainer';
import WeeklyActiveRateContainer from './WeeklyActiveRateContainer';
import { getOrganizationInfo, getUserRole } from '../helpers';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import MonthlyActiveRateContainer from './MonthlyActiveRateContainer';
import { goPath } from 'libModule/utils';
import UserClass from 'graphqlModule/class/User';
import '../styles/main.scss';
import MonthlyBillablePatientsContainer from './MonthlyBillablePatientsContainer';
import VitalsDistributionContainer from './VitalsDistributionContainer';
const { TabPane } = Tabs;

const STYLE = {
  padding: 20,
  minHeight: 900
};

class DataInsights extends React.Component {
  constructor(props) {
    super(props);
    const orgInfo = getOrganizationInfo();
    const canAccess = this.hasAccess();
    this.state = {
      organizationName: orgInfo.organizationName,
      organizationId: orgInfo.organizationId,
      canAccess,
    };

    if (!canAccess) {
      goPath('/');
    }
  }

  hasAccess = () => {
    const userPermissions = UserClass.getConfigPermissions();
    if (!userPermissions.orgPermissions.analyticsAPI) {
      return false;
    }
    const config = _.find(userPermissions.globalConfigs, (o) => {
      return o.configKey === 'DATA_ANALYTICS_ENABLED';
    }) || {};
    if ((config.value || '').toLowerCase() !== 'true') {
      return false;
    }
    return true;
  }

  render() {
    const { openErrorModal } = this.props;
    const { organizationName, organizationId, canAccess } = this.state;
    const role = getUserRole();

    if (!canAccess) {
      return null;
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Tabs defaultActiveKey="growth">
            <TabPane tab="Growth" key="growth">
              <div className="chart-tab-container">
                <div className="chart-container">
                  <WeeklyEnrollmentsContainer openErrorModal={openErrorModal} organizationName={organizationName} organizationId={organizationId} defaultColumnCount={4} />
                </div>
                <div className="chart-container">
                  <VitalsDistributionContainer openErrorModal={openErrorModal} organizationName={organizationName} organizationId={organizationId} />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Active Rate" key="activeRateWeekly">
              <div className="chart-tab-container">
                <div className="chart-container">
                  <WeeklyActiveRateContainer openErrorModal={openErrorModal} organizationName={organizationName} organizationId={organizationId} defaultColumnCount={4} />
                </div>
                <div className="chart-container">
                  <MonthlyActiveRateContainer openErrorModal={openErrorModal} organizationName={organizationName} organizationId={organizationId} defaultColumnCount={4} />
                </div>
              </div>
            </TabPane>
            {(role === 'RD' || role === 'CA') && <TabPane tab="Billing" key="billing">
              <div className="chart-tab-container">
                <div className="chart-container">
                  <MonthlyBillablePatientsContainer openErrorModal={openErrorModal} organizationName={organizationName} organizationId={organizationId} defaultColumnCount={6} />
                </div>
              </div>
            </TabPane>}
          </Tabs>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
  };
}
export default connect(null, mapDispatch)(DataInsights);