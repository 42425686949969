import gql from 'graphql-tag'
import {Address, ProviderProfile,Email} from './schema/User';
import {Phone} from "./schema/common";

// WARNING: This query should be ONLY used to list providers

export default gql`
    query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                ${ProviderProfile}
            }
            pageInfo {
                total
                lastPage
            }
        }
    }

`;
const miniProviderProfile =  `id
capacitys{
    role
    capacity
}
isTestUser
createdAt
updatedAt
registeredAt
selectedRole {
    refId
    name
    category
}
username
allRoles {
    refId
    name
    category
    organization {
        id
        name
        internalName
        leaf
        parent {
            id,
                name
        }
    }
    active
}
profile {
    title
    firstName
    lastName
    nickname
    fullName
    fullNameWithNickname
    gender
    avatar {
        link
    }
}
userSettings {
    preferredUnit
    notification
    listeningOrgs
    sendHeartbeatLog
    heartbeatInterval
    snapshotInterval
}
lastLogIn{
    id
}`
const providerInfo = `id
  capacitys{
        role
        capacity
  }
  isTestUser
  createdAt
  updatedAt
  registeredAt
  selectedRole {
    refId
    name
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  profile {
    title
    firstName
    lastName
    nickname
    fullName
    fullNameWithNickname
    gender
    avatar {
      link
    }
    ...on EmployeeProfile {
      employeeId
      department
      specialty
      startDate
      NPI
      directAddress
      mentor{
        id
        profile{
            fullName
        }
      }
      healthCoach {
        id
        profile {
            fullName
        }
      }
      providerLanguage{
        code
        description
      }

      hasCredential
    }
  }
  allRoles{
    name
    organization{
        id
    }
  }
  team {
    id
    name
    healthCondition
    organization {
      id
    }
  }
  userSettings {
    preferredUnit
    notification
    listeningOrgs
    sendHeartbeatLog
    heartbeatInterval
    snapshotInterval
  }
  lastLogIn{
    id
  }
`
export const userListWithTeamChannelCount =  gql`
    query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                ${providerInfo}
              providerTotalChannels
            }
            pageInfo {
                total
                lastPage
            }
        }
    }

`;
