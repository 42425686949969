import {
    React, IHSelect, IHButton, IHCheckbox
} from 'ihcomponent'
import {createContainer, goPath, GQLHelper} from 'libModule/utils'
import actions from '../actions'
import tabAction from '../../main/actions/Tab'
import DeviceTable from './DeviceTable'
import UploadForm from './UploadForm'
import {modalAction} from 'modulesAll/common/actions'
// import { graphql } from 'react-apollo'
// import setDevicesForEnrolledProgram from 'modulesAll/graphql/setDevicesForEnrolledProgram'
import config from 'libModule/config';
import RequestCache from 'libModule/requestCache'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const Component = class extends React.Component{
    static displayName = 'patient/deviceTab/containers/main'

    render(){
        const step = this.props.currentStep

        return (
            <div className="v-box vsm-patient-device-tab">
                {step===1 && this.renderPage1()}
                {step===2 && this.renderPage2()}

                {this.renderBottomButton()}
            </div>
        )
    }

    renderPage1(){
        return (
            <div>
                {this.renderTable()}
                {/*this.renderCheckbox()*/}
                {/*this.renderSelectDevice()*/}
            </div>
        )
    }

    renderCheckbox(){
        const p = {
            className : 'v-check',
            checked : this.props.isHomeSetup,
            onChange : (v)=>{
                this.props.changeHomeSetup(v.target.checked)
            }
        }

        return (
            <IHCheckbox {...p}>
                <span className="v-ck-inner"><b>Select Home Setup</b> (additional charges may be incurred)</span>
            </IHCheckbox>
        )
    }

    renderTable(){
        return <DeviceTable currentProgram={this.props.currentProgram} />
    }

    renderSelectDevice(){
        const selectProps = {
            size : 'large',
            placeholder : 'Select Set',
            //option : this.props.selectDeviceList,
            option : [{
              name: 'Set abc',
              value: 'Set abc'
            }, {
              name: 'Set mnb',
              value: 'Set mnb'
            }],
            onChange : (v)=>{
                this.props.changeSelectDevice(v)
            }
        }
        if(this.props.selectDeviceValue){
            selectProps.value = this.props.selectDeviceValue
        }

        return (
            <div className="v-selectdevice">
                <p className="v-tlt v-required">Select Device Set</p>
                <IHSelect {...selectProps} />
            </div>
        )
    }
    getCompleteModalProps(){
        const {closeModal, currentProgram} = this.props;
        const qrcode = config.SERVER_URL+'/qrcode/get?string='+currentProgram.qrcode;
        const projectName = window.VSM_CONFIG.DEMO || 'Readmission'
        const body = (
          <div>
            <p style={{textAlign:'left', fontSize:'16px'}}>Please pick up a tablet and perform the following steps to complete the enrollment:</p>
            <ul style={{textAlign:'left',paddingLeft:'40px',marginTop:'15px'}}>
              <li>1. Assign the Devices to the Patient</li>
              <li>2. Sign the {projectName} {I18N.get('keywords.Program')} Participation Form</li>
              <li>3. Sign the Device Loan Form</li>
            </ul>
            <p style={{marginTop:'8px',textAlign:'left', fontSize:'16px'}}>
              Scan the below QR code to begin.
            </p>
            <img alt="" src={qrcode} style={{width:'80%',marginTop:'15px',marginBottom:'15px'}} />

            <p style={{marginTop:'8px',textAlign:'left', fontSize:'16px'}}>
                If you prefer to start the {I18N.get('keywords.program')} at a later time, you can close this box now.
                <br/>You can always start the {I18N.get('keywords.program')} any time by scanning this QR code any time.
            </p>
          </div>
        )
        const b1 = {
          key : 'v1',
          size : 'md',
          label : 'Close',
          type : 'primary',
          onClick : ()=>{
              closeModal();
          }
        }
        return {
          title : 'Enrollment Partially Complete',
          body,
          size : 'sm',
          footer : [
                <IHButton {...b1} />
            ]
        }
    }

    clickNextButton(){
        const p = this.getCompleteModalProps()
        this.props.openModal(p.body, p)
        this.submitDevices(()=>{
            RequestCache.get('refetch-enrolledProgramList').call()

        })
    }

    renderBottomButton(){
        const { setTabsStep, changeCurrentStep } = this.props
        const step = this.props.currentStep

        const p1 = {
            type : 'primary',
            label : 'Done', //'Next',
            onClick : ()=>{
                if(true || step === 1){
                    //dispatch(actions.changeCurrentStep(2))
                    this.clickNextButton()
                }
                else{
                    //TODO done handle
                    setTabsStep('consent')

                }
            },
            className : 'v-nb'
        }
        const p2 = {
            label : 'Back',
            className : 'v-bb',
            onClick : ()=>{
                if(false && step === 2){
                    changeCurrentStep(1)
                }
                else{
                  const _currentPatient = RequestCache.get('nurse/currentPatient')
                  const patientNRIC = _.get(_currentPatient,'identification[0].value')
                  const patientName = _.get(_currentPatient,'profile.fullName')
                    createAuditLog({
                      action:I18N.get('auditLog.provider.patient.enrollInNewProgram.back_to_care_plan_tab'),
                      patientNRIC,
                      patientName
                    })
                    setTabsStep('careplan')
                }

            }
        }

        return (
            <div className="v-bottom-btn">
                <IHButton {...p2} />
                <IHButton {...p1} />
            </div>
        )
    }

    renderPage2(){
        return (
            <div className="v-form">
                <UploadForm />
            </div>
        )
    }

    async submitDevices(callback){
        //get params
        // const {currentProgram, deviceTableValue, dispatch, closeModal, setDevicesForEnrolledProgram, setTabsStep} = this.props
        // const _currentPatient = RequestCache.get('nurse/currentPatient')
        // const patientNRIC = _.get(_currentPatient,'identification[0].value')
        // const patientName = _.get(_currentPatient,'profile.fullName')
        // const variables = {
        //     id : currentProgram.id
        // }
        // _.each(deviceTableValue, (item, key)=>{
        //     if(!item.select){
        //         variables[key] = {
        //             other : item.reason || '',
        //             status: 'PATIENT_OWNED'
        //         }
        //     }
        //     else{
        //       variables[key] = {
        //         status: 'VENDOR_LOAN'
        //       }
        //     }
        // })

        //handle BP and HR separately, need to pass the same "status" and "other" if both BP and HR exist
        // if (_.find(currentProgram.tasks, (t) => t.type === 'BP') &&
        //     _.find(currentProgram.tasks, (t) => t.type === 'HR')) {
        //     variables.BP = variables.HR
        // }

        // const auditLogDetails = _.mapKeys(variables, (value, key) => {
        //   switch(key) {
        //     case 'BG':
        //       return 'blood glucose'
        //     case 'BP':
        //       return 'blood pressure'
        //     case 'HR':
        //       return 'heart rate'
        //     case 'HS':
        //       return 'weight'
        //     default:
        //       return key
        //   }
        // })

        // try{
            // const res = await setDevicesForEnrolledProgram({variables})
            // createAuditLog({
            //   action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_device_tab'),
            //   details: _.omit(auditLogDetails, 'id'),
            //   request: variables,
            //   response: res,
            //   patientNRIC,
            //   patientName
            // })

            // callback()
            //closeModal()
            //setTabsStep('programme')
        // }catch(e){
        //     dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
        //     createAuditLog({
        //       action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_device_tab'),
        //       details: _.omit(auditLogDetails, 'id'),
        //       request: variables,
        //       response: e,
        //       success: false,
        //       patientNRIC,
        //       patientName
        //     })
        // }finally{

        // }
    }
}

// const ComponentWithData = GQLHelper.wrapper([
//     graphql(setDevicesForEnrolledProgram, {
//         name : 'setDevicesForEnrolledProgram'
//     })
// ], Component)

export default createContainer(Component, (state)=>{
    const device = state.patient.device

    return {
        ...device
    }
}, (dispatch)=>{
    return {
        dispatch,
        setTabsStep: (dispath) => dispatch(tabAction.setTabsStep(dispath)),
        changeCurrentStep: (step) => dispatch(actions.changeCurrentStep(step)),
        changeSelectDevice: (v) => dispatch(actions.changeSelectDevice(v)),
        changeHomeSetup: (flag) => dispatch(actions.changeHomeSetup(flag)),
        closeModal: () => dispatch(modalAction.closeModal()),
        openModal: (content, modalProps) => dispatch(modalAction.openModal(content, modalProps))
    }
})
