import React from 'react';
import { Modal } from 'antd';

import HiatusInfoContainer from '../containers/InfoContainer';
import '../styles/modal.scss';

const HiatusInfoModal = props => {
  const { visible, handleCancel, modalProps, ...restProps } = props;

  return (<Modal
    className='hiatus-status-modal'
    width={600}
    style={{ top: '20%' }}
    visible={visible}
    onCancel={handleCancel}
    maskClosable={false}
    footer={null}
    {...modalProps}
  >
    <HiatusInfoContainer {...restProps}/>
  </Modal>);
};

export default HiatusInfoModal;