import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { graphql, withApollo, compose } from 'react-apollo'
import get from 'lodash/get'
// Graphql
import editEnrolledProgram from '../../../../graphql/mutation/editEnrolledProgram'
import { enrolledProgram as updateQueries } from 'graphqlModule/updateQueries/enrolledProgram'
// Utils
import { validateAntdFormWithFieldsValue } from 'libModule/utils'
import { CareplanHelper } from 'modulesAll/utils/helpers/task'
import RequestCache from 'libModule/requestCache'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
// Containers & Components
import { getFormProps } from 'commonModule/components/careplan/formData/VitalForm'
import AlertFormContainer from 'patientModule/VitalAlert/containers/CommonContainer'
// Ducks
import { actionCreators } from '../ducks/VitalFormDuck'
import { actionCreators as alertListActions } from 'patientModule/VitalAlert/ducks/AlertListDuck';
// UIs
import { IHBaseFormWithRow, IHButton } from 'ihcomponent'

// Libraries
import PropTypes from 'prop-types';

import * as R from 'ramda'
import { withProps } from 'recompose';
import { message } from 'antd';
import * as timerActions from '../../../../timer/actions/index';
import TimerContainer from '../../../../timer/containers/TimerContainer';
const { stopTimer,updateInterventionDetails }= timerActions.default;
class Container extends Component {
  static displayName = 'patient/Careplan/Vital/containers/VitalFormContainer'

  componentWillUnmount () {
    this.props.reset()
  }
  render() {
    const title = this.renderTitle()
    const backLink = this.renderBackLink()
    const body = this.renderBody()
    const submitBtn = this.renderSubmitBtn()
    const alertForm = this.renderAlertForm()
    const displayName = this.constructor.displayName;
    const viewingDetails ={
        component : displayName,
        path:window.location.pathname
    }
    return (
      <div>
        {backLink}
        <div style={{ minWidth: 1050 }}>
          {title}
          {body}
          {alertForm}
	        {submitBtn}
        </div>
      </div>
    )
  }
  renderAlertForm() {
    const { AlertForm } = this.props
    const alertForm = <AlertFormContainer {...AlertForm} />
    return alertForm
  }
  renderBody() {
    const { onFieldsChange, fieldsValue, VitalList, isEditMode } = this.props
    const formInfo = {
      onFieldsChange,
      fieldsValue,
      VitalList,
      className: 'vsm-form-body v-form-required',
      isEditMode,
      // userRole: 'PROVIDER',
      self: this
    };
    const formProps = getFormProps(formInfo);
    const body = <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }
  async submit() {
    const { fieldsValue, program, editEnrolledProgram, changePage, AlertList } = this.props
    const _currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(_currentPatient,'identification[0].value')
    const patientName = _.get(_currentPatient,'profile.fullName')
    // validate all fields before submission
    const userRole = 'PROVIDER'
    const isFormValid = await validateAntdFormWithFieldsValue(fieldsValue, this.baseForm, userRole)
    if (!isFormValid) return
    const panesFieldsValue = AlertList.panesFieldsValue
    const variables = {
      id: program.id,
      tasks: [CareplanHelper.convertVitalToDBTaskForEP(fieldsValue, panesFieldsValue)]
    }
    const auditLogDetails = {
      programName: program.name,
      vitalType: fieldsValue.vitalType.value,
      tasks: [CareplanHelper.convertVitalToDBTaskForEP(fieldsValue, panesFieldsValue)]
    }
    try {
      const res = await editEnrolledProgram({ variables, updateQueries })
      createAuditLog({
        action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_vital'),
        details: auditLogDetails,
        request: variables,
        response: res,
        patientNRIC,
        patientName })

      changePage()
    }
    catch (err) {
      message.error(err.message);
      console.error(err);
      createAuditLog({
        action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_vital'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false,
        patientNRIC,
        patientName
      })
    }
  }

  renderBackLink(){
    return (
      <div className="v-link" onClick={() => this.props.changePage()}>
        <div>
          <i className="fa fa-angle-left" />
          Back to Care Plan
        </div>
      </div>
    )
  }
  renderTitle() {
    return <h3 className='vsm-form-title'>Add Vitals to Care Plan</h3>
  }
  renderSubmitBtn() {
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnRight: {
        alignSelf: 'flex-end'
      }
    }
    const addBtnProp = {
      onClick : (e)=>{
        this.submit()
      }
    }
    const btn = (
      <div style={styles.footer}>
        <div style={styles.btnRight}>
          <IHButton {...addBtnProp} bsStyle="primary" label="Save" />
        </div>
      </div>
    )
    return btn
  }
}

const mapState = ({ patient }, ownProps) => R.merge({
  AlertList: get(patient, 'Careplan.VitalAlert.AlertList'),
  AlertForm: get(patient, 'Careplan.AlertForm'),
  VitalList: get(patient, 'Careplan.VitalList'),
}, get(patient, 'Careplan.VitalForm'));

const mapDispatch = (dispatch) => {
  const reset = alertListActions.reset
  return {
    ...bindActionCreators(actionCreators, dispatch),
    reset: () => dispatch(reset())
  }
}

Container.propTypes = {
  program: PropTypes.object,
  changePage: PropTypes.func,
  onFieldsChange: PropTypes.func,
  editEnrolledProgram: PropTypes.func,
  fieldsValue: PropTypes.object,
  AlertForm: PropTypes.object,
  VitalList: PropTypes.object,
  isEditMode: PropTypes.bool,
}

const forceSlotToWeeklyMeal = (props) => (
  R.pathEq(['fieldsValue', 'schedule', 'value', 'frequency'], 'slot', props) && R.pathEq(['fieldsValue', 'vitalType', 'value'], 'Blood Glucose', props)
    ? R.assocPath(['fieldsValue', 'schedule', 'value', 'frequency'], 'weeklyMeal', props)
    : props
  );

export default compose(
  withApollo,
  graphql(editEnrolledProgram, {name: 'editEnrolledProgram'}),
  connect(mapState, mapDispatch),
  withProps(forceSlotToWeeklyMeal),
)(Container);
