import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AlertFormContainer from 'patientModule/SurveyAlert/containers/AlertFormContainer'
import AlertListContainer from 'patientModule/SurveyAlert/containers/AlertListContainer'

// container
class Container extends Component {
  static displayName = 'patient/SurveyAlert/containers/CommonContainer'

  render() {
    return <AlertListContainer />
  }
}

// mapState
const mapState = ({ patient }, ownProps) => {
  return {
    ...patient.Careplan.Survey.Alert.Common
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {

  }
}


// PropTypes validation
Container.propTypes = {
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Container)
