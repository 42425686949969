export const NutritionSchema = `
  nutrition {
    intake {
      meal
      timeRange
      foodTypeAmount
      mealFreq
      skipFreq
    }
    fastFoodFreq {
      value
      unit
    }
    sweetBeverageFreq {
      value
      unit
    }
    previousDiets
    behaviorChange
    nutritionUnderstanding
    additionalComments
    intakeNote
    interpretationNote
  } 
`;