// Imports
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducers
import Client from './gqlClient';
import reducers from './reducer';
import tables from './table/TableReducers';
import layoutConstants from 'modulesAll/layout/constants/Nav';
const { RESET_STATE } = layoutConstants;

const appReducer = combineReducers({
  ...reducers,
  globalTables: tables,
  routing: (state={}, action)=>{
    if(action.type === 'STORE/ROUTING'){
      return {
        ...state,
        location : action.location,
        params : action.params
      }
    }
    return state
  },
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    const persistedStates = action.payload;
    const resetState = _.pick(state, persistedStates);
    state = !_.isEmpty(resetState) ? resetState : undefined;
  }
  return appReducer(state, action);
};

export default createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({})),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ),
);
