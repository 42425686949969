import { _ } from 'ihcomponent';
import types from '../constants/ListConstants';

const initState = {
  variables: {
    page: 1,
    count: 10,
    options: {
      search: {
        fields: ['NAME'],
        match: '',
      },
    },
  },
  sorter: {
    columnKey: 'name',
    order: 'ascend',
  },
  filter: {},
  pagination: { pageSize: 10 },
  currentPage: 1,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.ON_TABLE_CHANGE: {
      const { filter, sorter, pagination } = action;
      return {
        ...state,
        filter,
        sorter,
        pagination,
      };
    }
    case types.SET_VARIABLES: {
      const variables = _.merge({}, state.variables, action.variables);
      return {
        ...state,
        variables,
      };
    }
    case types.ON_TABLE_RESET:
      return initState;

    default:
      return state;
  }
};
