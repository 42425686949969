import gql from 'graphql-tag';

export default gql`
query quicksightDashboard (
    $username: String!,
    $dashboardId: String!,
    $organizationId: EID
  ){
    quicksightDashboard(
    username: $username,
    dashboardId: $dashboardId,
    organizationId: $organizationId
    )
  }`;