import React from 'react';
import TaskAssignmentListComponent from '../components/TaskAssignmentListComponent';
import { Tabs, Tooltip,Input,notification } from 'antd';
import {connect} from "react-redux";
import createTableAction from 'libModule/table/TableActions';
import map  from '../constants/tableMap';
import taskAssignmentList from '../query/taskAssignmentList';
import MyTasksModalComponent from '../components/MyTasksModalComponent';
import TrackingTaskModalComponent from '../components/TrackingTaskModalComponent';
import { graphql, compose } from 'react-apollo';
const { TabPane } = Tabs;
const { taskTypes } = map;
const tableActions = createTableAction('task_assignment_list',{ shouldUpdateHistory: false });
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from '../../I18N';
import taskAssignmentUnreadList from '../query/taskAssignmentUnreadList';
import {ROLE_MAP} from '../../../lib/constants';

class TaskAssignmentListContainer extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            note:''
        }
    }

    handleClick = ()=>{
        const { setModalData } = this.props;
        setModalData({
            showModal:false,
            modalData:null
        })
    }

    componentDidMount(){
        notification.close('newTask');
        notification.close('dueToday');
        notification.close('overdue');
    }
    renderModal = () => {
        const { props, refetchTasksCount } = this;
        const { modalData,taskType } = props;
        const currentUserId = sessionStorage.getItem('currentUserId');
        const { assignedTo,createdBy } = modalData;
        const assigneeId = _.get(assignedTo,'id');
        const assignerId = _.get(createdBy,'id');

        // taskType and Tab
        // 0: My Tasks; 1: Tracking Tasks; 2: allTasks
        if(taskType=='1' && assigneeId !== currentUserId){
          return <TrackingTaskModalComponent { ...props} refetchTasksCount={refetchTasksCount}/>;
        }
        if(taskType=='2'&&(assigneeId!=currentUserId&&assignerId!=currentUserId)){
          return <MyTasksModalComponent {...props} refetchTasksCount={refetchTasksCount}/>;
        }
        return assigneeId===currentUserId ?  
          <MyTasksModalComponent {...props} refetchTasksCount={refetchTasksCount}/>
          : <TrackingTaskModalComponent { ...props} refetchTasksCount={refetchTasksCount}/>;
    };

    componentWillUnmount(){
        const { resetTableProps } = this.props;
        resetTableProps();
    };

    onChangeTab = (index)=>{
        const { setTaskType,resetTableProps } = this.props;
        setTaskType(index);
        let task = '';
        switch(index) {
            case('0'):
                task = I18N.get('taskAssignment.tab.myTasks.label') + ' ';
                break;
            case('1'):
                task = I18N.get('taskAssignment.tab.tracking.label') + ' ';
                break;
            case('2'):
                task = I18N.get('taskAssignment.tab.all.label') + ' ';
                break;
        }
        Mixpanel.track('clicked', task, 'task list page', '')
        resetTableProps();
    }

    getTabTitle = (i) => {
        const { myTasksCount = 0, trackingTasksCount = 0, allTasksCount = 0 } = this.props;
        if(i == 0) return myTasksCount;
        else if( i == 1) return trackingTasksCount;
        return allTasksCount;
    }

    refetchTasksCount = () => {
        const { refetchMyTasks, refetchTrackingTasks, refetchAllTasks } = this.props;
        // consider tracking reAssign logic
        // no need to differetiate the tab
        if( refetchMyTasks){
            refetchMyTasks();
        }

        if( refetchTrackingTasks ){
            refetchTrackingTasks();
        }

        if(refetchAllTasks){
            refetchAllTasks();
        }
    }

    showTasksTitle = () => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const selectedRole = _.get(ROLE_MAP, currentUser.selectedRole.name);
        const showTitleRoles = ['RD', 'CA'];
        if (_.includes(showTitleRoles, selectedRole)) {
            return <h4 style={{padding: '35px 10px 0px 20px'}}>Tasks in Current Clinic</h4>
        }
    }

    render(){
       const { props,onChangeTab, getTabTitle }= this;
       const { taskType,setTaskType,showModal } = this.props;

       const tabArray = _.map(taskTypes,({type,title,tooltip},i)=> <TabPane 
          tab={
            <Tooltip 
              title={tooltip} 
              key={i}
              overlayClassName={`taskassignment-tab-tooltip-${i}`} 
              destroyTooltipOnHide
            >
              {title + `(${getTabTitle(i)})`}
            </Tooltip>
          } 
          key={i}
        >
          <TaskAssignmentListComponent {...props} title={title}/>
        </TabPane>)
       return <div>
                    { showModal ? this.renderModal():'' }
                    {this.showTasksTitle()}
                    <Tabs activeKey={taskType} style={{ padding:20 }} animated={false} tabBarStyle={{ height:50 }} onChange={ (index)=>onChangeTab(index) } className='taskAssignmentTabs' id='taskAssignmentTabs'>
                        { tabArray }
                    </Tabs>
               </div>
    }

}
const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    ...state.globalTables.task_assignment_list,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...tableActions
    };
}
const sortMap = {
    createdAt:'CREATED_AT',
    dueDate:'DUE_DATE'
}

const withData = graphql(taskAssignmentList,{
    options:(props)=>{
        const currentUserId = sessionStorage.getItem('currentUserId');
        const { taskType,page,sort,filter } = props;
        const { pageSize,current } = page;
        let variables = {};
        let filters = {};
        let role = null;
        if(taskType=='0'){
            role = 'assignedTo';
        }
        else if(taskType=='1'){
            // role = 'createdBy';
            // as long as currentUser is in the loop of a task
            // taskAssignment.history container currentUser as assigner
            filters.notAssignedByUser = false;
        }
        if(role) {
            filters[`${role}`]= currentUserId
        }
        variables.count = pageSize;
        variables.page = current;
        variables.sort = {
            field:sortMap[_.get(sort,'field')],
            direction: _.get(sort,'direction')
        }
        filters = _.extend(filters,filter);
        variables.filters = filters;
        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
         const { loading, error, taskAssignmentList, refetch, page } = data;
         if(error) {
          console.error(error);
         }
         if(loading){
            return { loading };
         }
        return {
            taskList:_.get(taskAssignmentList,'data'),
            pageInfo:_.get(taskAssignmentList,'pageInfo'),
            refetch
         }
    }
});

const myTasksList = graphql(taskAssignmentUnreadList,{
    options:(props)=>{
        const currentUserId = sessionStorage.getItem('currentUserId');
        const variables = {
            filters: {assignedTo: currentUserId},
            page: 1,
            count: 200
        }

        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const {  taskAssignmentList, refetch} = data;
        return {
            myTasksCount: _.get(taskAssignmentList,'pageInfo.total'),
            refetchMyTasks: refetch
         }
    }
});

const trackingTasksList = graphql(taskAssignmentUnreadList,{
    options:(props)=>{
        const currentUserId = sessionStorage.getItem('currentUserId');
        const variables = {
            // previously, the Tracking Tasks tab only tracks the creator's
            // but as SC-5989, this tab also tracks tasks reassigned by the user
            filters: { notAssignedByUser: false },
            page: 1,
            count: 200
        }

        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const {  taskAssignmentList, refetch} = data;
        return {
            trackingTasksCount: _.get(taskAssignmentList,'pageInfo.total'),
            refetchTrackingTasks: refetch
         }
    }
});

const allTasksList = graphql(taskAssignmentUnreadList,{
    options:(props)=>{
        const variables = {
            filters: { },
            page: 1,
            count: 200
        }

        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const {  taskAssignmentList, refetch} = data;
        return {
            allTasksCount: _.get(taskAssignmentList,'pageInfo.total'),
            refetchAllTasks: refetch
         }
    }
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withData,
    myTasksList,
    trackingTasksList,
    allTasksList)(TaskAssignmentListContainer);
