import gql from 'graphql-tag';
import { PatientHiatusSchema } from './schema/common';

export const getPatientHiatusQuery = gql`
  query getPatientHiatus($memberId: EID!) {
    getPatientHiatusStatus(memberId: $memberId) {
      ${PatientHiatusSchema}
    }
  }
`;

// get only what is needed
export const buildGetPatientHiatusQuery = (dataFields = []) => {
  if(_.isEmpty(dataFields))
    return getPatientHiatusQuery;
    
  return gql`
    query getPatientHiatus(memberId: EID!) {
      getPatientHiatusStatus(memberId: $memberId) {
        id
        ${dataFields.join('\n')}
      }
    }
  `;
};