export const EVENT_MAPS = {
  foodLog: 'foodLog',
  alert: 'alert',
  complexity: 'complexity',
};

export const eventHelperCreator = (eventName, creatorName) => {
  const handler = (cb) => (e) => {
    if (creatorName !== e.detail.creatorName) {
      cb(e);
    }
  }
  return {
    create: (details = {}) => {
      const event = new CustomEvent(eventName, { detail: { creatorName, ...details }});
      window.dispatchEvent(event);
    },
    listen: (cb) => {
      window.addEventListener(eventName, handler(cb), false);
    },
    remove: (cb) => {
      window.removeEventListener(eventName, handler(cb), false);
    },
  }
};
