import React from 'react';
import PropTypes from 'prop-types';

import { CLINIC_GOAL_ENUM } from '../../careplanNew/component/helper/goalHelper';

import '../styles/ReviewGoalItemComponent.scss';

const ReviewGoalItemComponent = ({
  goal = {},
  vitals = []
}) => {
  const { value, goalEnum } = goal;

  const renderDaysOfMeasurement = () => (
    vitals.map((_vital) => {
      const { vital, count } = _vital;
      return (
        <div 
          key={vital}
          className="review-goal-component__days-of-measurement"
        >
          <img src={`image/workstation/${vital}-vital.svg`} />
          {' '}
          {count}
          {' '}
          Days of measurement
        </div>
      );
    })
  );

  return (
    <div className="review-goal-component">
      {renderDaysOfMeasurement()}
      <div className="review-goal-component__label">
        Current Goal
      </div>
      <div>
        { CLINIC_GOAL_ENUM[goalEnum] || value }
      </div>
    </div>
  );
};

ReviewGoalItemComponent.propTypes = {};
export default ReviewGoalItemComponent;