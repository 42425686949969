import gql from 'graphql-tag'
import {EnrolledProgram} from './schema/EnrolledProgram'

export default gql`
  mutation dischargeFromEnrolledProgram(
    $id : EID!
    $dischargedDetail: DischargedDetailInput
  ){
      dischargeFromEnrolledProgram(
        id: $id
        dischargedDetail: $dischargedDetail
      ) {
        ${EnrolledProgram}
      }
  }
`
