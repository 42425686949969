// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers';
// TODO: Do we need these commons
import { actionTypes as VitalListTypes } from './VitalListDuck';
import { actionCreators as moduleCommonActions, actionTypes as moduleCommonTypes } from './CommonDuck';
// Reducer
import { commonReducer } from 'libModule/helpers/reducer-helpers';

/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Careplan', 'VitalForm', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'UPDATE_FORM',
]);

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
]);

actionCreators.goCareplanMainPage = () => {
  return moduleCommonActions.changePage(moduleCommonTypes.CAREPLAN_MAIN_PAGE);
};

actionCreators.addVitalFormToList = (fieldsValue) => {
  return (dispatch, getState) => {
    dispatch({
      type: VitalListTypes.ADD_VITAL_DATA,
      fieldsValue
    });

    dispatch({
      type: actionTypes.RESET_FORM
    });

    dispatch(goCareplanMainPage());
  };
};

export { actionCreators };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  isEditMode: false
};

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.RESET_FORM:
      return initState;
    default:
      return state;
  }
};

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes);
};
