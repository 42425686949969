export const getBillableAndNonBillableCodes = ({ billableHealthConditions, healthConditions }, user) => {
  let _healthConditions = healthConditions,
      _billableHealthConditions = billableHealthConditions;

  if(!Array.isArray(billableHealthConditions) && !Array.isArray(healthConditions)) {
    _healthConditions = _.get(user, 'profile.healthConditions') || [];
    _billableHealthConditions = _.get(user, 'profile.billableHealthConditions') || [];
  }

  const splittedConditions = _.map(_healthConditions, (v) => { 
      const [description, code] = v.split('::');
      return { description, code };
  });
  const nonBillableHealthConditions = _.filter(splittedConditions, (v) => { return !_.find(_billableHealthConditions, x => x.code === v.code) });
  return { 
      billableHealthConditions: _billableHealthConditions.map(v => v.code),
      nonBillableHealthConditions: nonBillableHealthConditions.map(v => v.code)
  };
}