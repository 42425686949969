import gql from 'graphql-tag'
import update from 'immutability-helper'
import { ProgramWithoutCreator } from 'graphqlModule/schema/Program'

export default gql `
  mutation createAdminProgram (
    $name: String,
    $description: String,
    $duration: DurationInput,
    $healthCondition: HealthCondition!,
    $tasks: [InputTask]
  ) {
    createAdminProgram (
      name: $name,
      description: $description,
      duration: $duration,
      healthCondition: $healthCondition,
      tasks: $tasks
    ) {
      ${ProgramWithoutCreator}
    }
  }
`

export const updateQueries = {
  adminProgramList: (prev, { mutationResult }) => {
    const result = mutationResult.data.createAdminProgram

    return update(prev, {
      adminProgramList: {
        data: {
          $unshift: [result]
        }
      }
    })
  },
}
