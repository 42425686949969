import React from 'react';
import { Row, Col } from 'antd';
import '../style/printCarePlan.styles.scss';
import moment from 'moment';
import convertor from '@ihealth/convertor';
import { activityOptions, educationOptions, foodAccessibilityOptions, routineOptions, sleepOptions } from '../constant/lifestyleConstant';
import { previousDietOptions } from '../constant/nutritionIntakeConstants';
import en from '../../I18N/en';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { SIGNOFF_TIME_FORMAT } from '../../../lib/constants';
import _ from 'lodash';
import VISIT_TYPE_ENUM from '../../visit/constants/map';
import { graphql } from 'react-apollo';
import { matchContionsWithOptions as matchConditionsWithOptions, mappingCondition, CLINIC_GOAL_ENUM } from './helper/goalHelper';
import getClinicGoalTemplate from 'graphqlModule/getClinicGoalTemplates';
import { parseBGOrBPInitial } from '../helpers/healthConditionHelper';
import { DISEASES } from '../constant/chronicHistoryConstant';
import helpers from '../helpers/index';
import I18N from 'modulesAll/I18N';

const VITALS_MAP = {
  BP: 'BP',
  BG: 'Glucose',
  PO: 'Oxygen',
  TM: 'Temperature',
  HS: 'Weight',
  EXERCISE: 'Exercise',
};

const BEHAVIORAL_GOALS_MAP = {
  BLOOD_GLUCOSE_MANAGEMENT: 'Glucose Management',
  BLOOD_PRESSURE_MANAGEMENT: 'BP Management',
  LIPID_MANAGEMENT: 'Lipid Management',
  WEIGHT_REDUCTION: 'Weight Reduction',
  CANCER: 'Cancer',
  UNDERWEIGHT: 'Underweight',
  CHRONIC_KIDNEY_DISEASE: 'Chronic Kidney Desease',
  CONGESTIVE_HEART_FAILURE: 'Congestive Heart Failure',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: 'Chronic Obstructive Pulmonary Disease',
  WELLNESS: 'Wellness',
};

const REPEAT_MAP = {
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
}

const inLineStyle = {
  display: 'inline-block',
  marginBottom: 0,
};

const convertArrayToMap = (arr) => {
  return arr.reduce((r, x) => {
    r[x.value] = x.label;
    return r;
  }, {});
}

const EDUCATION_LEVEL_MAP = convertArrayToMap(educationOptions);
const FOOD_ACCESSIBILITY_MAP = convertArrayToMap(foodAccessibilityOptions);
const ACTIVITY_LEVEL_MAP = convertArrayToMap(activityOptions);
const PREV_DIET_MAP = convertArrayToMap(previousDietOptions);
const ROUTINE_MAP = convertArrayToMap(routineOptions);
const SLEEP_FREQ_MAP = convertArrayToMap(sleepOptions);

const NA = 'N/A';

class PrintCarePlanComponent extends React.Component {

  get isMNT() {
    const programCategories = _.get(this.props, 'user.profile.programCategories', []);
    const isMNTEnrolled = !!_.find(programCategories, ({ name, enrolled }) => (name === 'MNT' && enrolled === true));

    return _.get(this.props, 'visitType', false) === 'MNT' || isMNTEnrolled;
  }

  get isINIT() {
    return _.get(this.props, 'visitType', false) === 'INIT';
  }

  get isFollowUp() {
    return _.get(this.props, 'visitType', false) === 'FOLLOW_UP';
  }

  get hasVisit() {
    return !!_.get(this.props, 'visitType', false);
  }

  get isCcmOrRpmEnrolled() {
    const programCategories = _.get(this.props, 'user.profile.programCategories', {});
    const enrolled = _.filter(programCategories, (o) => o.enrolled && (o.name.toUpperCase() === 'CCM' || o.name.toUpperCase() === 'RPM'));
    return enrolled.length > 0;
  }

  renderHeader = (title) => {
    return <div style={{ fontSize: '1.2em', fontWeight: 'bold', margin: '10px 0' }}>{title}</div>;
  }

  renderSubHeader = (title, style = {}) => {
    return <div style={{ fontWeight: 'bold', margin: '5px 0', ...style }}>{title}</div>;
  }

  getEnrolment = () => {
    const programCategories = _.get(this.props, 'user.profile.programCategories', {});
    const enrolled = _.filter(programCategories, { enrolled: true });
    const enrolledNames = enrolled.map(e => {
      const name = e.name.toUpperCase();
      return name === 'MNT' ? 'MNT/DSMT' : name;
    }).join('/');
    return (
      <div>
        {enrolledNames ? <b>Enrolled in {enrolledNames}</b> : <b>Unenrolled</b>}
      </div>
    );
  }

  renderPatientInfo = () => {
    const user = _.get(this.props, 'user', {});
    const vitals = _.get(this.props, 'enrolledProgram.tasks', []).filter(v => v.type !== 'EXERCISE').map(v => VITALS_MAP[v.type] || v.type);
    const enrolment = this.getEnrolment();
    let visitInfo = '';
    if (this.hasVisit) {
      const date = _.get(this.props, 'visitDate');
      visitInfo += VISIT_TYPE_ENUM[_.get(this.props, 'visitType')] + ' Visit ' + (date ? moment(date).format('MM/DD/YYYY') : '');
    }
    const identification = _.get(user, 'identification', [{}]);
    const profile = _.get(user,'profile', {});
    const { fullName, birthday, gender, height, weight } = profile;
    const mr = _.get(identification, '0.value');
    const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : 0;
    const heightInches = heightInInches % 12;
    const heightFeet = Math.floor(heightInInches / 12);

    return (
      <div>
        <div>
          <table style={{ marginBottom: 10 }}>
            <tbody>
              <tr>
                <td style={{ paddingLeft: 0, paddingRight: 20, borderRight: '1px solid black' }}><h3 style={{ margin: 0 }}>{fullName}</h3></td>
                <td style={{ paddingLeft: 20, paddingRight: 20, borderRight: '1px solid black' }}><h4 style={{ margin: 0 }}>{enrolment}</h4></td>
                <td style={{ paddingLeft: 10, }}><h4 style={{ margin: 0 }}>{visitInfo && <div><b>{visitInfo}</b></div>}</h4></td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div><b>{moment().diff(moment(birthday, 'YYYY-MM-DD'), 'years')}y</b></div>
            <div><b>{gender}</b></div>
            <div><b>{mr}</b></div>
            <div><b>{heightInInches && `${heightFeet}'${heightInches}"`}</b></div>
            <div><b>{weight ? (convertor.HS(weight.value, 'kg', 'lbs', 0) + 'lb') : ''}</b></div>
            <div><b>Vitals: {vitals.join(', ')}</b></div>
          </div>
        </div>
        <hr style={{ borderBottom: '1px black solid' }} />
      </div>
    );
  }

  renderProgramDescription = () => {
    return (
      <div>
        {this.renderHeader('Program Description & Care Coordination')}
        <b>
          {this.isMNT
            ? `The Unified Care clinicians set up the patient’s care plan, complete with customized
            diet and lifestyle recommendations and goal setting. The patient has periodic visits
            with the dietitian to provide point-of-care support, real-time behavioral coaching,
            personalized nutrition suggestions, and comprehensive nutrition counseling.`
            : `The primary care physician collaborates with care team members to set up the
            patient's care plan and provide periodic in-clinic visits to review progress.
            The UnifiedCare clinicians remotely monitor patients vitals and provide
            point-of-care support and real-time behavioral coaching under the supervision
            of the primary care physician.`
          }
        </b>
      </div>
    );
  }

  renderCareTeam = () => {
    const team = _.get(this.props, 'enrolledProgram.team.0.members');
    return (
      <div>
        {this.renderHeader('Unified Care Team')}
        <Row>
          {_.map(team, ({ user }, i) => (
            <Col span={12} key={i}>
              <b style={{ margin: '0 10px' }}>
                {_.get(user, 'profile.fullName')}
              </b>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  renderHealthConditions = () => {
    const healthConditions = _.get(this.props, 'user.profile.healthConditions', []);
    
    if (!healthConditions || _.isEmpty(healthConditions)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Health Conditions')}
        <Row>
          {healthConditions.map((hc, i) => {
            const [description, code] = hc.split('::');
            return (
              <Col span={12} key={i}>
                <Row>
                  <Col span={4}><b>{code}</b></Col>
                  <Col span={18} offset={2}><b style={{ marginRight: 20 }}>{description}</b></Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  renderPatientDetailsReviewed = () => {
    return (
      <div style={{ fontStyle: 'italic' }}>
        {this.isMNT
          ? <b>
            Patient's medical history, medication compliance, work schedule,
            physical activity level, dietary pattern-eating habits, - and exercise
            routine was reviewed.
          </b>
          : <b>
            Patient's medical history, lifestyle, and medication compliance
            have been reviewed.
          </b>
        }
      </div>
    );
  }

  getDiagnoseDate(val, type) {
    const filteredRes = _.filter(val, (k) => k.condition === type);
    const diagnosedDate = filteredRes.length > 0 ? filteredRes[0].diagnosedDate : null;
    return diagnosedDate;
  }

  getBillableNames = () => {
    return _.get(this.props, 'billableDiseases', []);
  }

  getOtherConditions = () => {
    const chronicDisease = _.get(this.props, 'user.profile.chronicDiseaseHistory', []);
    const otherBillableConditions = {};
    const otherConditions = [];
    _.forEach(chronicDisease, (ch) => {
      if( _.includes(DISEASES, ch.name) && !otherBillableConditions[ch.name]){
        otherBillableConditions[ch.name] = ch;
      } else {
        otherConditions.push(ch);
      }
    });
    return { otherBillableConditions, otherConditions };
  }

  hasBillableBG = () => {
    return _.includes(this.getBillableNames(), 'DIABETES');
  }

  hasBillableBP = () => {
    return _.includes(this.getBillableNames(), 'HYPERTENSION');
  }

  renderOtherBillables = (conditions) => {
    const billableByName = this.getBillableNames();
    const result = [];
    _.forEach(DISEASES, d => {
      const data = conditions[d] || {};
      const dataWithoutNoise = _.omit(data,['__typename','name']);
      const hasValue = helpers.hasSomeValue(dataWithoutNoise);
      const hasCondition = _.includes(billableByName, d);
      const showCondition = hasCondition || hasValue;
      const printoutName = I18N.get(`chronicDisease.${d}.printoutName`);
      if (showCondition) {
        const { yearsWithCondition, treatments, specialistInfo, seenDoctor, freq, unit, testRegularly } = dataWithoutNoise;
        const testInfo = `${freq || ''} ${unit || ''}`.trim();
        result.push(
          <div style={{ marginBottom: 10 }} key={d}>
            {this.renderSubHeader(mappingCondition[d])}
            {!_.isNil(yearsWithCondition) && <div><b>Years with this condition: {yearsWithCondition} years</b></div>}
            {!_.isNil(seenDoctor) && <div><b>{seenDoctor ? 'Has seen doctor in the past 6 months' : 'Has not seen doctor in past 6 months'}.</b></div>}
            {(!_.isNil(testRegularly) && printoutName!== 'NONE') && <div>
              <div><b>{testRegularly ? `Patient has tested ${printoutName} in the past${testInfo ? (', ' + testInfo) : ''}` : `Patient has not tested ${printoutName} before`}.</b></div>
            </div>}
            {!_.isNil(specialistInfo) && <div>
              <b>
                {specialistInfo.seenSpecialist
                  ? `Patient sees a specialist for this condition: ${specialistInfo.contact || 'N/A'}`
                  : 'Patient does not see a specialist for this condition'
                }.
              </b>
            </div>}
            {!_.isNil(treatments) && <div><b>Current Treatment: {treatments && treatments.length > 0 ? treatments.join(', ') : 'No Treatment'}.</b></div>}
          </div>
        );
      }
    });
    return result;
  }

  renderDiseaseHistory = () => {
    const profile = _.get(this.props, 'user.profile', {});
    const { otherBillableConditions, otherConditions } = this.getOtherConditions();
    const bgInfo = parseBGOrBPInitial('BG', profile);
    const bpInfo = parseBGOrBPInitial('BP', profile);
    const bgHasValue = helpers.hasSomeValue((bgInfo));
    const bpHasValue = helpers.hasSomeValue((bpInfo));
    const diabetesDYear = this.getDiagnoseDate(profile.yearOfDiagnose, 'DIABETES');
    const hypertensionDYear = this.getDiagnoseDate(profile.yearOfDiagnose, 'HYPERTENSION');
    const BGTestInfo = `${_.get(profile, 'BGTest.freq') || ''} ${_.get(profile, 'BGTest.unit') || ''}`.trim();
    const BPTestInfo = `${_.get(profile, 'BPTest.freq') || ''} ${_.get(profile, 'BPTest.unit') || ''}`.trim();
    // const chronicDiseaseNote = _.get(profile, 'chronicDiseaseNote', '');
    return (
      <div>
        {this.renderHeader('Disease History')}
        {(this.hasBillableBG() || bgHasValue) && <div style={{ marginBottom: 10 }}>
          {this.renderSubHeader('Diabetes')}
          {!_.isNil(diabetesDYear) && <div><b>Years with this condition: {diabetesDYear} years</b></div>}
          {!_.isNil(profile.BGVisits) && <div><b>{profile.BGVisits ? 'Has seen doctor in the past 6 months.' : 'Has not seen doctor in past 6 months.'}</b></div>}
          {!_.isNil(_.get(profile, 'BGSpecialistInfo.seenSpecialist')) && <div>
            <b>
              {_.get(profile, 'BGSpecialistInfo.seenSpecialist')
                ? `Patient sees a specialist for this condition: ${_.get(profile, 'BGSpecialistInfo.contact') || 'N/A'}.`
                : 'Patient does not see a specialist for this condition.'
              }
            </b>
          </div>}
          {/* {_.get(profile, 'BGTest.testRegularly') && <div><b>Patient has tested BG in the past, {_.get(profile, 'BGTest.freq')} {_.get(profile, 'BGTest.unit')}.</b></div>} */}
          {!_.isNil(_.get(profile, 'BGTest.testRegularly')) && <div><b>{_.get(profile, 'BGTest.testRegularly') ? (`Patient has tested BG in the past${BGTestInfo ? (', ' + BGTestInfo + '.') : '.'}`) : 'Patient has not tested BG before.'}</b></div>}
          <div><b>{_.get(profile, 'BGTreatments.length') === 0 ? "" : ('Current Treatment: ' + (_.get(profile, 'BGTreatments.length', 0) > 0 ? profile.BGTreatments.join(', ') + '.' : 'No Treatment.'))}</b></div>
          {/* {_.get(profile, 'BGSpecialistInfo.seenSpecialist') && <div><b>Patient sees a specialist for this condition: {_.get(profile, 'BGSpecialistInfo.contact')}</b></div>} */}
        </div>}

        {(this.hasBillableBP() || bpHasValue) && <div style={{ marginBottom: 10 }}>
          {this.renderSubHeader('Hypertension')}
          {!_.isNil(hypertensionDYear) && <div><b>Years with this condition: {hypertensionDYear} years</b></div>}
          {/* {profile.BPVisits && <div><b>Has seen doctor regularly in the past 6 months.</b></div>} */}
          {!_.isNil(profile.BPVisits) && <div><b>{!profile.BPVisits ? 'Has not seen doctor in past 6 months.' : 'Has seen doctor in the past 6 months.'}</b></div>}
          {!_.isNil(_.get(profile, 'BPSpecialistInfo.seenSpecialist')) && <div>
            <b>
              {(_.get(profile, 'BPSpecialistInfo.seenSpecialist')
                ? `Patient sees a specialist for this condition: ${_.get(profile, 'BPSpecialistInfo.contact') || 'N/A'}.`
                : 'Patient does not see a specialist for this condition.')
              }
            </b>
          </div>}
          {/* {_.get(profile, 'BPSpecialistInfo.seenSpecialist') && <div><b>Patient sees a specialist for this condition: {_.get(profile, 'BPSpecialistInfo.contact')}</b></div>} */}
          {!_.isNil(_.get(profile, 'BPTest.testRegularly')) && <div><b>{_.get(profile, 'BPTest.testRegularly') ? (`Patient has tested BP in the past${BPTestInfo ? (', ' + BPTestInfo + '.') : '.'}`) : 'Patient has not tested BP before.'}</b></div>}
          {/* {_.get(profile, 'BPTest.testRegularly') && <div><b>Patient has tested BP in the past, {_.get(profile, 'BPTest.freq')} {_.get(profile, 'BPTest.unit')}.</b></div>} */}
          <div><b>{_.get(profile, 'BPTreatments.length', 0) === 0 ? '' : ('Current Treatment: ' + (_.get(profile, 'BPTreatments.length', 0) > 0 ? profile.BPTreatments.join(', ') + '.' : 'No Treatment.'))}</b></div>
          {/* {_.get(profile, 'BPTreatments.length', 0) > 0 &&
            <div>
              <b>
              Current Treatment: {_.get(profile, 'BPTreatments.length', 0) > 0 ? profile.BPTreatments.join(', ') : 'No Treatment'}.
              </b>
            </div>
          } */}
        </div>}
        {this.renderOtherBillables(otherBillableConditions)}
        {otherConditions.map((o, i) => (
          <div style={{ marginBottom: 10 }} key={i}>
            {this.renderSubHeader(o.name)}
            {!_.isNil(o.yearsWithCondition) && <div><b>Years with this condition: {o.yearsWithCondition} years</b></div>}
            {o.takingMedication !== null && <div><b>Patient is {o.takingMedication ? '' : 'not '}taking medication</b></div>}
            {o.otherTreatment !== null && <div><b>Other Treatments: {o.otherTreatment || 'No treatment'}</b></div>}
            {o.comments && <div><b>Additional Comment: {o.comments}</b></div>}
            {/* {_.get(o, 'specialistInfo.seenSpecialist') && <div><b>Patient sees a specialist for this condition: {_.get(o, 'specialistInfo.contact')}</b></div>} */}
            {_.get(o, 'specialistInfo.seenSpecialist') != null && 
              <div>
                <b>
                  {_.get(o, 'specialistInfo.seenSpecialist') 
                    ? `Patient sees a specialist for this condition: ${_.get(o, 'specialistInfo.contact') || 'N/A'}`
                    : 'Patient does not see a specialist for this condition'
                  }.
                </b>
            </div>
            }
          </div>
        ))}
        {/*{chronicDiseaseNote && <div style={{ marginBottom: 10 }}>*/}
        {/*  <b>{this.renderSubHeader('Note:',  { display: 'inline-block' })}{chronicDiseaseNote}</b>*/}
        {/*</div>}*/}
      </div>
    );
  }

  renderMedicationReconciliation = () => {
    const reconcilationCompliance = _.get(this.props, 'user.profile.medicationManagement.reconcilationCompliance', '');
    return (
      <div>
        {this.renderHeader('Medication Reconciliation and Compliance')}
        <b style={{ fontStyle: 'italic' }}>
          Patient’s updated medication list and compliance status was reviewed. Other OTC or nonprescription
          medications (if any) will be listed on patients medication list.
        </b>
        <br />
        <b>{reconcilationCompliance}</b>
      </div>
    );
  }

  renderEatingSleepingRoutine = () => {
    const routine = _.get(this.props, 'user.profile.routine');
    const sleepFreq = _.get(this.props, 'user.profile.sleepFreq');

    if (!routine && !sleepFreq) {
      return null;
    }
    return (
      <div>
        {routine && <b>Eating & Sleeping Routine: {ROUTINE_MAP[routine] || routine}</b>}
        {sleepFreq && <b>Frequency of patient getting 7-8 hours of sleep: {SLEEP_FREQ_MAP[sleepFreq] || sleepFreq}</b>}
      </div>
    );
  }

  renderTypicalLifestylePatterns = () => {
    const lifestylePattern = _.get(this.props, 'user.profile.lifestylePattern', '');

    if (!lifestylePattern) {
      return null;
    }
    return (
      <div>
        <b>Typical Lifestyle Patterns: {lifestylePattern}</b>
      </div>
    );
  }

  renderEmploymentStatusAndWorkDuties = () => {
    const occupation = _.get(this.props, 'user.profile.occupation', '');
    const workDuties = _.get(this.props, 'user.profile.workDuties', '');

    if (!occupation && !workDuties) {
      return null;
    }
    return (
      <div>
        {occupation && <div>
          <b>Employment Status: {occupation}</b>
        </div>}
        {workDuties && <div>
          <b>Work Duties and Schedules: {workDuties}</b>
        </div>}
      </div>
    );
  }

  renderCommunitySocialSupport = () => {
    const socialSupport = _.get(this.props, 'user.profile.socialSupport', {});
    const communitySupport = _.get(socialSupport, 'communitySupport', '');
    const contactInfo = _.get(socialSupport, 'contactInfo', '');
    const otherProviders = _.get(socialSupport, 'otherProviders', '');

    if (!communitySupport && !contactInfo && !otherProviders) {
      return null;
    }
    return (
      <div style={{ marginBottom: 10 }}>
        {communitySupport && 
          <React.Fragment>
            <b>Community/Social Support:&nbsp;</b>
            <b style={{ marginRight: 10 }}>{communitySupport}</b>
          </React.Fragment>
        }
        {contactInfo && <b>Contact Information: {contactInfo}</b>}
        {otherProviders && <b>Other Providers: {otherProviders}</b>}
      </div>
    );
  }

  renderPhysicalActivityLevel = () => {
    const activityLevel = _.get(this.props, 'user.profile.activityLevel', '')
    const exerciseHabits = _.get(this.props, 'user.profile.exerciseHabits', {});
    const freq = _.get(exerciseHabits, 'freq.value');

    if (!activityLevel && !freq) {
      return null;
    }
    return (
      <div>
        {activityLevel && <b>Physical Activity Level: {ACTIVITY_LEVEL_MAP[activityLevel] || activityLevel}</b>}&nbsp;
        {freq && <b>Frequency: {freq} {_.get(exerciseHabits, 'freq.unit')}, Duration: {_.get(exerciseHabits, 'duration')} mins</b>}
      </div>
    );
  }

  renderFoodAccessibility = () => {
    const foodAccessibility = _.get(this.props, 'user.profile.foodAccessibility', '');

    if (!foodAccessibility) {
      return null;
    }
    return (
      <div>
        <b>Food Accessibility: {FOOD_ACCESSIBILITY_MAP[foodAccessibility] || foodAccessibility}</b>
      </div>
    );
  }

  renderEducationLevel = () => {
    const educationLevel = _.get(this.props, 'user.profile.educationLevel', '');

    if (!educationLevel) {
      return null;
    }
    return (
      <div>
        <b>Education Level: {EDUCATION_LEVEL_MAP[educationLevel] || educationLevel}</b>
      </div>
    );
  }

  renderDrinkingHistory = () => {
    const drinkingHistory = _.get(this.props, 'user.profile.drinkingHistory', {}) || {};
    if (_.isNil(drinkingHistory.value)) {
      return null;
    }
    const drinkingHistoryFreq = _.get(drinkingHistory, 'freq.value');
    const drinkingHistoryUnit = _.get(drinkingHistory, 'freq.unit');
    const drinkingHistoryQuitYear = _.get(drinkingHistory, 'quitYear');
    return (
      <div>
        {this.renderSubHeader('Drinking History:', inLineStyle)}&nbsp;
        {drinkingHistory.value
          ? <span>
            <b>
              Patient drinks{drinkingHistory.drinkType && <span>&nbsp;{drinkingHistory.drinkType}</span>}.
              {!_.isNil(drinkingHistoryFreq) && !_.isNil(drinkingHistoryUnit) && <span>&nbsp;Frequency: {drinkingHistoryFreq} {drinkingHistoryUnit}.</span>}
              {!_.isNil(drinkingHistory.amount) && <span>&nbsp;Quantity: {drinkingHistory.amount}.</span>}
              {/* Patient started drinking since {drinkingHistory.startSinceYear} and like to drink {drinkingHistory.drinkType}
              &nbsp;for  {drinkingHistory.freq.unit}, {drinkingHistory.amount}ml each time. Patient
              has quit drinking for {(+moment().format('YYYY')) - (+drinkingHistory.quitYear)} years */}
            </b>
          </span>
          : <b>Patient does not drink. {drinkingHistoryQuitYear ? <span>Quit year: {drinkingHistoryQuitYear}</span> : ''}</b>
        }
      </div>
    );
  }

  renderSmokingHistory = () => {
    const smokingHistory = _.get(this.props, 'user.profile.smokingHistory', {}) || {};
    if (_.isNil(smokingHistory.value)) {
      return null;
    }
    const smokingHistoryFreq = _.get(smokingHistory, 'freq.value');
    const smokingHistoryUnit = _.get(smokingHistory, 'freq.unit');
    const quitYear = _.get(smokingHistory, 'quitYear');
    return (
      <div>
        {this.renderSubHeader('Smoking History:', inLineStyle)}&nbsp;
        {smokingHistory.value
          ? <span>
            <b>
            Patient smokes{!_.isNil(smokingHistoryFreq) && !_.isNil(smokingHistoryUnit) && <span>&nbsp;Frequency: {smokingHistoryFreq} {smokingHistoryUnit}</span>}.
            {/* Patient started smoking since {smokingHistory.startSinceYear},
            &nbsp;{smokingHistory.freq.value} {smokingHistory.freq.unit}. Patient
            has quit smoking for {(+moment().format('YYYY')) - (+smokingHistory.quitYear)} years */}
            </b>
          </span>
          : <b>Patient does not smoke. {quitYear ? <span>Quit year: {quitYear}</span> : ''}</b>
        }
      </div>
    );
  }

  renderLifestyle = () => {
    const lifestyleSections = [
      this.renderEatingSleepingRoutine(),
      this.renderEmploymentStatusAndWorkDuties(),
      this.renderPhysicalActivityLevel(),
      this.renderFoodAccessibility(),
      this.renderEducationLevel(),
      this.renderTypicalLifestylePatterns(),
      this.renderDrinkingHistory(),
      this.renderSmokingHistory(),
    ].filter(v => v !== null);

    if (lifestyleSections.length === 0) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Lifestyle')}
        {lifestyleSections.map((v, i) => <React.Fragment key={i}>{v}</React.Fragment>)}
      </div>
    );
  }

  renderSocialSupport = () =>{
    const socialSupportSections = [
      this.renderCommunitySocialSupport()
    ].filter(v => v !== null);
    if (socialSupportSections.length === 0) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Social Support')}
        {socialSupportSections.map((v, i) => <React.Fragment key={i}>{v}</React.Fragment>)}
      </div>
    );
  }

  renderNutritionIntake = () => {
    const nutrition = _.get(this.props, 'user.profile.nutrition', {});
    const intakes = _.get(nutrition, 'intake', []);
    const hasFastFoodFreq = _.get(nutrition, 'fastFoodFreq');
    const fastFoodFreq = _.get(nutrition, 'fastFoodFreq.value');
    const fastFoodUnit = _.get(nutrition, 'fastFoodFreq.unit', '').toLowerCase();
    const hasBeverageFreq = _.get(nutrition, 'sweetBeverageFreq');
    const beverageFreq = _.get(nutrition, 'sweetBeverageFreq.value');
    const beverageUnit = _.get(nutrition, 'sweetBeverageFreq.unit', '').toLowerCase();
    const previousDiets = _.get(nutrition, 'previousDiets', []).map(d => PREV_DIET_MAP[d]).join(', ');
    const nutritionUnderstanding = _.get(nutrition, 'nutritionUnderstanding') || '';
    const additionalComments = _.get(nutrition, 'additionalComments') || '';

    if (!nutrition || ((!intakes || _.isEmpty(intakes)) && !hasFastFoodFreq && !fastFoodFreq && !fastFoodUnit &&
        !hasBeverageFreq && !beverageFreq && !beverageUnit && !previousDiets && !nutritionUnderstanding && !additionalComments)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Nutrition Intake')}
        {intakes.map((x, i) => (x.foodTypeAmount || x.mealFreq || x.skipFreq || x.comments || x.timeRange) && (
          <div key={i}>
            <div>
              {this.renderSubHeader(x.meal.charAt(0).toUpperCase() + x.meal.slice(1).toLowerCase(), inLineStyle)}&nbsp;
              {x.timeRange && <b>({x.timeRange})</b>}
            </div>
            {x.foodTypeAmount && <div><b>Food Types and Amount: {x.foodTypeAmount}</b></div>}
            {x.mealFreq && <div><b>Meal Frequency: {x.mealFreq}</b></div>}
            {x.skipFreq && <div><b>Skip Frequency: {x.skipFreq}</b></div>}
            {x.comments && <div><b>Comment: {x.comments}</b></div>}
          </div>
        ))}
        <div style={{ margin: '10px 0' }}>
          {!_.isNil(hasFastFoodFreq) && !_.isNil(fastFoodFreq) && <div>
            {this.renderSubHeader('Fast Food Frequency:', inLineStyle)}&nbsp;
            <b>{`${fastFoodFreq} times / ${fastFoodUnit}`}</b>
          </div>}
          {!_.isNil(hasBeverageFreq) && !_.isNil(beverageFreq) && <div>
            {this.renderSubHeader('Sweet Beverage Frequency:', inLineStyle)}&nbsp;
            <b>{`${beverageFreq} times / ${beverageUnit}`}</b>
          </div>}
          {previousDiets && <div>
            {this.renderSubHeader('Previous Diet(s) Tried:', inLineStyle)}&nbsp;<b>{previousDiets || 'None'}.</b>
          </div>}
        </div>
        {nutritionUnderstanding && <div>
          {this.renderSubHeader('Nutrition Understanding:', inLineStyle)}&nbsp;<b>{nutritionUnderstanding}</b>
        </div>}
        {additionalComments && <div>
          <b>Additional Comment: {additionalComments}</b>
        </div>}
      </div>
    );
  }

  renderNutritionInterpretation = () => {
    const behaviorChange = _.get(this.props, 'user.profile.nutrition.behaviorChange');
    const pesStatements = _.get(this.props, 'userPESStatements', []) || [];

    if (!behaviorChange && (!pesStatements || _.isEmpty(pesStatements))) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Nutrition Interpretation')}
        {behaviorChange && <div>
          {this.renderSubHeader('Stage of Behavioral Change:', inLineStyle)}&nbsp;
          <span><b>{en.motivation[behaviorChange] || behaviorChange}</b></span>
        </div>}
        <ol style={{ paddingLeft: 15 }}>
          {pesStatements.map((v, i) => (
            <li key={i}>
              <b>
                Patient is at risk of {_.get(v, 'problem.value', '')} as evidenced by&nbsp;
                {_.get(v, 'signSymptom', []).map(x => x.value).join(', ')}, related to&nbsp;
                {_.get(v, 'etiology', []).map(x => x.value).join(', ')}. ({_.get(v, 'resolution.status') || NA})
              </b>
            </li>
          ))}
        </ol>
      </div>
    );
  }

  renderPlannedInterventions = () => {
    return (
      <div>
        {this.renderHeader('Planned Interventions')}
        <div style={{ fontStyle: 'italic' }}>
          <b>
            Remote Vitals Monitoring and Chronic Care Management: Patient is provided with
            blood pressure, blood glucose, weight scale, pulse oximeter or a combination of
            these vitals to measure vitals per care plan goals and take any medications as prescribed.
            Unified Care mobile app and CCM and/or RPM services are prescribed to the patient.
            Care team will monitor patient's vitals remotely and provide real time advice for lifestyle
            coaching. Medication compliance will be enhanced via mobile app reminders. Instructed on best
            practices for taking a measurement.
          </b>
        </div>
      </div>
    );
  }

  renderInterventionAndEducation = () => {
    const handouts = _.get(this.props, 'user.profile.handouts', []);

    if (!handouts || _.isEmpty(handouts)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Educational Handouts Provided')}
        <b>{handouts.map(h => h.title).join('. ')}</b>
      </div>
    );
  }

  getGroupedClinicGoals = () => {
    const clinicGoals = _.get(this.props, 'enrolledProgram.clinicGoals', []);
    let groupedG = {};
    _.forEach(clinicGoals, cg => {
      const { conditionEnum, goalEnum, condition, value } = cg;
      let displayedCondition = conditionEnum && conditionEnum !== 'UNMAPPED' ? mappingCondition[conditionEnum] : condition;
      let displayedValue = goalEnum ? CLINIC_GOAL_ENUM[goalEnum] : value;
      let previousGoalValueArray = groupedG[displayedCondition] || [];
      groupedG[displayedCondition] = [...previousGoalValueArray, displayedValue];
    })
    return groupedG;
  }

  renderExpectedOutcome = () => {
    const groupedG = this.getGroupedClinicGoals();

    if (!groupedG || _.isEmpty(groupedG)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Goals')}
        {_.map(groupedG, (v, k) => (
          <div key={k} style={{ marginBottom: 10 }}>
            {this.renderSubHeader(k)}
            <b>{v.map((displayedValue, i) => <div key={i}>{displayedValue}</div>)}</b>
          </div>
        ))}
      </div>
    );
  }

  renderVitalsMonitoring = () => {
    const vitals = _.get(this.props, 'enrolledProgram.tasks', []).filter(v => v.type !== 'EXERCISE');

    if (!vitals || _.isEmpty(vitals)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Vitals Monitoring')}
        {vitals.map((v, i) => {
          const vName = VITALS_MAP[v.type] || v.type;
          if (v.type === 'BG') {
            return (
              <div key={i}>
                {this.renderSubHeader(vName)}
                <b>{_.get(v, 'templateDetails.description') || ''}.</b>
              </div>
            );
          }
          return (
            <div key={i}>
              {this.renderSubHeader(vName)}
              <b>
                Patient is scheduled to monitor their {vName} for&nbsp;
                {_.get(v, 'schedule[0].target', NA)} times per {REPEAT_MAP[v.repeat] || v.repeat}.
              </b>
            </div>
          );
        })}
      </div>
    );
  }

  renderBehavioralGoals = () => {
    const behavioralGoals = _.get(this.props, 'enrolledProgram.behavioralGoals', []) || [];
    const groupedBG = _.groupBy(behavioralGoals, 'type');

    if (!groupedBG || _.isEmpty(groupedBG)) {
      return null;
    }
    return (
      <div>
        {this.renderHeader('Behavioral Goals')}
        {_.map(groupedBG, (v, k) => (
          <div key={k} style={{ marginBottom: 10 }}>
            {this.renderSubHeader(BEHAVIORAL_GOALS_MAP[k] || k)}
            <ol style={{ paddingLeft: 15 }}>
              {v.map((b, i) => <li key={i}><b>{b.value}</b></li>)}
            </ol>
          </div>
        ))}
      </div>
    );
  }

  renderAgreement = () => {
    return (
      <div>
        <b style={{ fontWeight: 'bold' }}>
        {this.isMNT
            ? `The patient’s physician has referred this patient to Medical Nutrition Therapy
            services and the patient has chosen Unified Care as their MNT service provider.
            The patient has verbally agreed to receive nutrition services from iHealth. All
            services will be billed directly to Medicare or the applicable insurance company.`
            : `This Care Plan was created under the supervision of the provider per the terms
            in the partnership agreement.`
          }  
        </b>
      </div>
    );
  }

  renderFollowUpNote = () => {
    return (
      <div>
        {this.renderAgreement()}
        <br />
        <b style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
          {this.isMNT
            ? `Follow-up nutrition counseling visits are taken as needed. Patients can 
            communicate with their care team via in-app secured messaging and/or phone calls.`
            : `Follow up visits are taken every 3-6 months or as needed.
            Patients can communicate with their care team via in-app secured messaging
            and/or phone calls.`
          }  
        </b>
      </div>
    );
  }

  formatTime = time => {
    let formattedTime = DATE_HELPERS.getTimeInClinicTimezone(time);
    formattedTime = formattedTime ? formattedTime.format(SIGNOFF_TIME_FORMAT) : NA;
    return formattedTime;
  }

  renderSignOffInfo = () => {
    const { signOffStatus = {} } = this.props
    const signedOffBy = _.get(signOffStatus, 'signedOffBy.profile.fullName');
    let checkinTime = this.formatTime(_.get(signOffStatus, 'checkinTime'));
    let signedOffTime = this.formatTime(_.get(signOffStatus, 'signedOffTime'));
    const timeSpent = _.get(signOffStatus, 'timeSpent');
    const titleStyle = { fontStyle: 'italic' };

    if (!signedOffBy && !checkinTime && !signedOffTime && !timeSpent) {
      return null;
    }
    return (
      <div>
        {signedOffBy && <div><b><span style={titleStyle}>Sign off by:</span>&nbsp;{signedOffBy};</b></div>}
        {checkinTime && <div><b><span style={titleStyle}>Visit check-in time:</span>&nbsp;{checkinTime};</b></div>}
        {signedOffTime && <div><b><span style={titleStyle}>Sign off time:</span>&nbsp;{signedOffTime};</b></div>}
        {timeSpent && <div><b><span style={titleStyle}>Estimated time spent on this patient:</span>&nbsp;{timeSpent} min</b></div>}
      </div>
    );
  }

  render() {
    const sections = [
      { render: this.renderProgramDescription, shouldRender: true },
      { render: this.renderCareTeam, shouldRender: true },
      { render: this.renderHealthConditions, shouldRender: true },
      { render: this.renderPatientDetailsReviewed, shouldRender: true },
      { render: this.renderDiseaseHistory, shouldRender: true },
      { render: this.renderMedicationReconciliation, shouldRender: true },
      { render: this.renderSocialSupport, shouldRender: true},
      { render: this.renderLifestyle, shouldRender: true },
      { render: this.renderNutritionIntake, shouldRender: true },
      { render: this.renderNutritionInterpretation, shouldRender: true },
      { render: this.renderPlannedInterventions, shouldRender: !this.isMNT },
      { render: this.renderInterventionAndEducation, shouldRender: true },
      { render: this.renderExpectedOutcome, shouldRender: true },
      { render: this.renderVitalsMonitoring, shouldRender: true },
      { render: this.renderBehavioralGoals, shouldRender: true },
      { render: this.renderFollowUpNote, shouldRender: true },
      { render: this.renderSignOffInfo, shouldRender: this.isMNT && this.props.signOffStatus },
    ];

    return (
      <Row className="remove-row-div-padding print" style={{ margin: '0px 15px' }}>
        <table>
          <thead>
            <tr><td>{this.renderPatientInfo()}</td></tr>
          </thead>
          <tbody>
            <tr>
              <td>
              {sections.filter(o => o.shouldRender).map((o, i) => {
                const render = o.render();
                return (
                  <React.Fragment key={i}>
                    {render}
                    {i < (sections.length - 1) && render !== null && <hr />}
                  </React.Fragment>
                );
              })}
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
    );
  }
}

const billableData = graphql(getClinicGoalTemplate, {
  options:(ownProps) =>{
      const billableConditions = _.get(ownProps, 'user.profile.billableHealthConditions', []);
      const codes = _.map(billableConditions, o => o.code);
      return {
        variables: {
          codes,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      };
  },
  props: ({  data }) => {
      const { loading, getClinicGoalTemplates, networkStatus } = data
      if (loading) {
        return { billableLoading: loading, billableNetworkStatus: networkStatus };
      }

      const result = matchConditionsWithOptions(getClinicGoalTemplates);

      return {
        billableDiseases: result.chronicDiseases,
      };
  }
})

export default billableData(PrintCarePlanComponent);
