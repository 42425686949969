// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers'
// Reducer
import { commonReducer } from 'libModule/helpers/reducer-helpers'
/*
**************************************************
  Constants - Action Types
**************************************************
*/
// TODO: change the path after moving into Careplan or Careplan/Vital
const actionTypes = constants('patient/VitalAlert', 'AlertForm', [
  'FORM_FIELD_CHANGE',
  'INIT_DATA',
  'RESET_FORM'
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
]);

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
};

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.RESET_FORM:
      return initState
    default:
      return state
  }
};

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes);
};
