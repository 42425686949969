import { graphql } from 'react-apollo'
import { createContainer, GQLHelper } from 'libModule/utils'
import { _ } from 'ihcomponent'
import createTableAction from 'libModule/table/TableActions';
import { openErrorModal } from 'modulesAll/layout/actions/MainModal'
import action from '../actions'
import ListTableComponent from '../components/ListTableComponent'
import update from 'immutability-helper'

// graphql
import todoListGQL from 'modulesAll/graphql/todoList'
import editTodoGQL from 'modulesAll/graphql/editTodo'

const tableActions = createTableAction('provider_mytasks_list', {
  sortFieldMap: {
    dueDate: 'DUE_DATE',
    completedAt: 'COMPLETED_AT'
  },
  initState: {
    page: {
      current: 1,
      pageSize: 10
    },
    dateRangeToFilter: null,
    search: '',
    table_select: 'Current'
  }
});


const mapState = ({ task, globalTables }) => ({
  ...task.TaskList,
  ...globalTables.provider_mytasks_list
})

const mapDispatch = (dispatch)=>{
  return {
    dispatch,
    setSearchVariables : (v)=>{
      const options = {}
      options.search = {
        fields : ['NAME', 'IDENTIFICATION'],
        match : v||''
      }

      dispatch(action.setVariables({
        options
      }))
    },
    showModal : (show, select)=>{
      dispatch(action.showModal(show, select));
    },
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    reset : () => dispatch(action.reset()),
    ...tableActions
  }
}

export default createContainer(GQLHelper.wrapper([
  graphql(todoListGQL, {
    options : ownProps => {
      const dateRangeToFilter =  _.get(ownProps, 'dateRangeToFilter', []) || []
      const field = _.get(ownProps, 'sort.fieldGraph')
      const direction = _.get(ownProps, 'sort.direction')
      const sort = field && direction && { field, direction } || null

      const variables = {
        page: _.get(ownProps, 'page.current', 1),
        count: _.get(ownProps, 'page.pageSize', 10),
        options: {
          completed: _.get(ownProps, 'table_select', 'Current') === 'Completed',
          search: {
            fields: ['DESCRIPTION'],
            match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
          },
          dueDate: dateRangeToFilter.length > 0 ? {
            startDate: _.get(ownProps, 'dateRangeToFilter[0]').startOf('day'),
            endDate: _.get(ownProps, 'dateRangeToFilter[1]').endOf('day'),
          } : null
        },
        sort
      }

      return {
        variables,
        fetchPolicy: 'network-only'
      }
    },
    props : ({data})=>{
      return {
        loading : data.loading,
        list : data.todoList,
        refetch : data.refetch
      }
    }
  }),
  graphql(editTodoGQL, {name : 'editTodoGQL'})
], ListTableComponent), mapState, mapDispatch)
