export const CONDITION_LIST = {
  HLD: 'HLD', 
  HTN: 'HTN',
  DM: 'DM',
  CKD: 'CKD',
  Obesity: 'Obesity',
  PreDM: 'PreDM',
  COPD: 'COPD',
  ESRD_Dialysis: 'ESRD on Dialysis',
  // Other: 'Other',
};

export const RPM_CONDITION_TO_ICD = {
  DM: ['E08.x', 'E09.x', 'E11.x', 'E13.x', 'E10.x', 'Z79.4', 'E08.22', 'E09.22', 'E10.22', 'E11.22', 'E13.22'],
  HTN: ['I10', 'I11.x', 'I15.x', 'R03.0', 'Z01.30', 'Z01.31', 'I12.x', 'I13.x'],
  PreDM: ['R73.x'],
  Obesity: ['E66.x'],
  COPD: ['J96.11', 'R09.02', 'J43.X', 'J42', 'J44.9', 'J44.0', 'J44.1'],
  CKD: ['I97.130', 'I97.131', 'N18.x'],
  CHF: ['I50.x', 'I38'],
};

export const CCM_CONDITION_TO_ICD = {
  otherCodes: [
    'R63.6', 'C16.x', 'C17.x', 'C18.x', 'C21.x', 'C25.x', 'C54.1', 'C54.2', 'C54.3', 'C67.x', 'C75.x',
    'D35.x', 'C71.x', 'O25.3', 'O25.10', 'E64.0', 'E46', 'E44.0', 'E44.1', 'E45', 'E43', 'E64.x'
  ],
  DM: ['E08.x', 'E09.x', 'E11.x', 'E13.x', 'E10.x', 'Z79.4', 'E08.22', 'E09.22', 'E10.22', 'E11.22', 'E13.22'],
  HTN: ['I10', 'I11.x', 'I15.x', 'R03.0', 'Z01.30', 'Z01.31', 'I12.x', 'I13.x'],
  PreDM: ['R73.x'],
  Obesity: ['E66.x'],
  COPD: ['J96.11', 'R09.02', 'J43.x', 'J42', 'J44.9', 'J44.0', 'J44.1'],
  CKD: ['I97.130', 'I97.131', 'N18.x'],
  CHF: ['I50.x', 'I38'],
  HLD: ['E78.x', 'I25.x', 'Z95.1', 'Z95.5', 'Z98.61', 'I21.x', 'I22.x', 'I24.x', 'G45.x'],
};

export const FORM_TYPES = {
  SCREEN: 'SCREEN',
  ENROLL: 'ENROLL',
  REFER: 'REFER',
};

export const CONDITIONS_TO_VITALS = {
  HTN: 'BP',
  CKD: 'BP',
  DM: 'BG',
  PreDM:'BG',
  CHF: 'HS',
  COPD: 'PO',
  Obesity: 'HS',
};

export const VITAL_TO_CONDITIONS = {
  BP: ['HTN', 'CKD'],
  BG: ['DM', 'PreDM'],
  HS: ['CHF', 'Obesity'],
  PO: ['COPD'],
  TM: [], // no mapping yet, 0920
}

export const SMARTPHONE_SKILL_ENUM = {
  SMART_PHONE_PREFERRED: 'SMART_PHONE_PREFERRED',
  SMART_PHONE_NOT_PREFERRED: 'SMART_PHONE_NOT_PREFERRED',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
};

export const DIAGNOSIS_SOURCE = {
  ICD_CODE: 'ICD_CODE', CONDITION: 'CONDITION'
};