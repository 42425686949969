import type from '../constants'

export default {
    contact: (flag) => {
        return {
            type: type.CONTACT_STATUS,
            payload: flag
        }
    },
    callStatus: (flag) => {
        return {
            type: type.CALL_STATUS,
            payload: flag
        }
    },
    lastCallPatientId: (flag) => {
        return {
            type: type.LAST_CALL_PATIENT_ID,
            payload: flag
        }
    },
    lastCallPatientName: (flag) => {
        return {
            type: type.LAST_CALL_PATIENT_NAME,
            payload: flag
        }
    },
    lastCallStartTime: (flag) => {
        return {
            type: type.LAST_CALL_START_TIME,
            payload: flag
        }
    },
    lastCallEndTime: (flag) => {
        return {
            type: type.LAST_CALL_END_TIME,
            payload: flag
        }
    },
    lastCallEnrolledProgramId: (flag) => {
        return {
            type: type.LAST_CALL_ENROLLEDPROGRAM_ID,
            payload: flag
        }
    },
    inboundAttributes: (flag) => {
        return {
            type: type.INBOUND_ATTRIBUTES,
            payload: flag
        }
    },
    agentStatus: (flag) => {
        return {
            type: type.AGENT_STATUS,
            payload: flag
        }
    },
    ccpStatus: (flag) => {
        return {
            type: type.CCP_STATUS,
            payload: flag
        }
    },
    setMinimizedNote: (name) => {
      return {
        type: type.SET_MINIMIZED_NOTE,
        payload: name,
      }
    }
}

export const providerNotesModalIsVisibleAction = (flag)=>({
    type : type.CALL_STATUS,
    payload: flag
});

export const lastCallPatientIdAction = (flag)=>({
    type : type.LAST_CALL_PATIENT_ID,
    payload: flag
});