
export default {
    setVirtualVisit: (visitId) => {
        return {
            visitId,
            type: 'SET_VIRTUAL_VISIT'
        }
    },
    setConsentInfo:(memberId,consent)=>{
        return {
            memberId,
            consent,
            type:'SET_CONSENT_INFO'
        }
    },
    resetConsentInfo:(memberId)=>{
      return {
          memberId,
          type:'RESET_CONSENT_INFO'
      }
  },
    setRoomNumber: (roomNUm)=>{
        return {
            roomNUm,
            type:'SET_ROOM_NUMBER'
        }
    }
}
