import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import moment from 'moment'

import createBroadcastGQL from 'modulesAll/graphql/createBroadcast'

import { IHButton, message, IHLoading, IHForm } from 'ihcomponent'
import { goPath, FormHelper, GQLHelper, submittingStyle } from 'lib/utils'
import actions from '../actions/CreateBroadcastActions'
import { modalAction } from 'modulesAll/common/actions'
import { getFormProps } from '../components/BroadcastFormComponent'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {

  componentWillUnmount() {
    this.props.resetForm()
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()
    return (
      <div className='vsm-broadcast-new'>
        <div className='broadcast-new'>
            {body}
            {footer}
        </div>
      </div>
    )
  }

  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    const formProps = getFormProps({ onFieldsChange, fieldsValue })
    return <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }

  renderFooter() {
    const { isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick: (e)=>{
          goPath('/broadcasts')
        },
        bsStyle: 'default',
        disabled: false,
        label: 'Cancel'
      },
      done: {
        onClick : ()=>{
          this.createBroadcast()
        },
        bsStyle: 'primary',
        disabled: isSubmitting,
        type: 'submit',
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} >
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )

    return footer
  }

  async createBroadcast(){
    const { mutate, fieldsValue, handleFormSaved, openSuccessModal, openErrorModal, setSubmitting } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }
    const variables = this.getMutateVariable(formValue)
    const auditLogDetails = {
      title: variables.title,
      message: variables.message,
      startDate: moment(variables.startTime).format('MMM-DD-YYYY hh:mm a:ss'),
      endDate: moment(variables.endTime).format('MMM-DD-YYYY hh:mm a:ss')
    }
    try {
      setSubmitting(true)
      await handleFormSaved()

      const res = await mutate({variables})

      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.create'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      if (res.data.createBroadcast) {
        const modalTitle = 'Broadcast Creation Successful'
        const modalContent = `Broadcast has been created!`
        openSuccessModal(modalTitle, modalContent)
        goPath('/broadcasts')
      }
    }
    catch(err) {
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.create'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
    finally {
      setSubmitting(false)
    }
  }

  getMutateVariable(data) {
    const variables = {
      title : (data.title === 'others')? data.title_text : data.title,
      startTime : data.startTime,
      endTime : data.endTime,
      message : data.message
    }
    return variables
  }

}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(createBroadcastGQL)(Container)

const mapState = (state, ownProps) => {
  return {
    ...state.Broadcast.CreateBroadcast,
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    changeField: (fieldPath, value) => dispatch(actions.changeField(fieldPath, value)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    resetForm: () => dispatch(actions.resetForm()),
    openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  mutate: PropTypes.func,
  openErrorModal: PropTypes.func,
  onFieldsChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  fieldsValue: PropTypes.object,
  resetForm: PropTypes.func,
  openConfirmModal: PropTypes.func,
  isFormSaved: PropTypes.bool,
  handleFormSaved: PropTypes.func,
  openSuccessModal: PropTypes.func,
  setSubmitting: PropTypes.func
}


export default compose(
  withApollo,
  connect(mapState, mapDispatch),
)(ContainerWithData);
