import gql from 'graphql-tag'

export const SEARCH_FIELDS = {
  DEVICE_ID: 'DEVICEID',
};

export default gql`
query iotDeviceConfigList($deviceStatusFilter:DeviceStatusFilterEnum, $direction: SortDirection){
  iotDeviceConfigList(
    filters:{deviceStatusFilter:$deviceStatusFilter}, 
    sort: { direction: $direction }
  ){
    data{
        id
        deviceId
        volume
        firmwareVersion
        language
        lastSeenAt
        battery
        createdAt
        updatedAt
        isActive
    }
  }
}`;

export const iotDeviceConfigListWithSearch = gql`
  query iotDeviceConfigList(
    $deviceStatusFilter:DeviceStatusFilterEnum, 
    $direction: SortDirection, 
    $search: IoTDeviceListConfigSearchInput,
    $count: Int
  ){
    iotDeviceConfigList(
      filters:{deviceStatusFilter:$deviceStatusFilter}, 
      sort: { direction: $direction }
      search: $search
      count: $count
    ){
      data{
          id
          deviceId
          volume
          firmwareVersion
          language
          lastSeenAt
          battery
          createdAt
          updatedAt
          isActive
      }
    }
  }
`;
