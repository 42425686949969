import type from '../constants/index';

export default (id, patientId) => ({
  startTimer: (event = 'open') => {
    return {
      id,
      patientId,
      event,
      type: type.START_TIMER
    }
  },
  pauseTimer: (event = 'blur') => {
    return {
      id,
      patientId,
      event,
      type: type.PAUSE_TIMER
    }
  },
  resumeTimer: (event = 'focus') => {
    return {
      id,
      patientId,
      event,
      type: type.RESUME_TIMER
    }
  },
  stopTimer: (event = 'save', cb = undefined) => {
    return {
      id,
      patientId,
      event,
      cb,
      type: type.STOP_TIMER
    }
  },
  abort: (event = 'abort') => {
    return {
      id,
      patientId,
      event,
      type: type.ABORT
    }
  },
  resetTimer: (canRestUUid) => {
    return {
      id,
      patientId,
      // canRestUUid,
      type: type.RESET_TIMER
    }
  },
  // updateInterventionDetails: (apiName, resource, docId, summary) => {
  //   return {
  //     id,
  //     apiName,
  //     resource,
  //     docId,
  //     summary,
  //     type: type.UPDATE_INTERVENTION_DETAILS
  //   }
  // },
  // setUUId: (uuid) => {
  //   return {
  //     uuid,
  //     type: type.SET_UUID
  //   }
  // },
  setCoolDown: (coolDown) => {
    return {
      id,
      patientId,
      coolDown,
      type: type.RESET_COOL_DOWN
    }
  },
  pauseTimeOut: (event = 'timeout') => {
    return {
      id,
      patientId,
      event,
      type: type.TIMEOUT_PAUSE
    }
  },
  // startChat: () => {
  //   return {
  //     id,
  //     patientId,
  //     type: type.START_CHAT
  //   }
  // },
  resumeTimeOut: (event = 'timeoutResume') => {
    return {
      id,
      patientId,
      event,
      type: type.TIMEOUT_RESUME
    }
  },
  focusPage: () => {
    return {
      id,
      patientId,
      type: type.FOCUS_PAGE
    }
  },
  blurPage: () => {
    return {
      id,
      patientId,
      type: type.BLUR_PAGE
    }
  },
  interacted: (interactionName, description = '') => {
    return {
      id,
      patientId,
      interactionName,
      description,
      type: type.INTERACTION
    }
  },
  setDone: (value) => {
    return {
      id,
      patientId,
      isDone: value,
      type: type.DONE
    }
  },
  // clearUUId: () => {
  //   return {
  //     type: type.CLEAR_UUID
  //   }
  // }
});


export const interacted = (id, patientId, interactionName, description = '') => {
  return {
    id,
    patientId,
    interactionName,
    description,
    type: type.INTERACTION
  }
}
export const stopTimer = (id, patientId, event = 'save', cb = undefined) => {
  return {
    id,
    patientId,
    event,
    cb,
    type: type.STOP_TIMER
  }
}
