import React from "react";
import MyTasksModalComponent from "./MyTasksModalComponent";
import TrackingTaskModalComponent from './TrackingTaskModalComponent';
import { IHTable } from 'ihcomponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';
import Client from 'libModule/gqlClient';
import setTaskToRead from "../query/setTaskToRead";
import helper from '../helper';

let TaskAssignmentTableComponent = class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          expandIndex:[],
          taskAssignments: [...props.taskList]
        }
    }
    onRow = (record, rowIndex)=>{
        const { expandIndex, taskAssignments } = this.state;
        const refetch = _.get(this.props,'refetchByPatient');
        const { id } = record;
        let res = [];
        Mixpanel.track('clicked', 'task', 'patient task list popup');
        if(!expandIndex.includes(rowIndex)){
            res.push(rowIndex);
        }
        this.setState({
            expandIndex:res
        })
        if(helper.checkNeedFold(record)) {
            Client.mutate({
                mutation:setTaskToRead,
                variables:{id}
            }).then(res=>{
              // update real-time status without refetch/close popup
              const newTaskAssignments = [...taskAssignments];
              const taskIndex = _.findIndex(newTaskAssignments, { id });
              const task = newTaskAssignments[taskIndex];
              _.set(task, 'isRead.value', true);
              newTaskAssignments.splice(taskIndex, 1, task);
              this.setState({ taskAssignments: newTaskAssignments }, () => {
                API.fetchTaskUnreadTasks();
              });
            })
        }
    };

    renderTable = (renderBody,columns)=>{
        const { expandIndex, taskAssignments } = this.state;
        const { currentProgramId,team,setModalData,refetchUser,refetchUserList } = this.props;
        return <IHTable columns = {columns} 
          dataSource={taskAssignments}
          onRow={ (record,index)=>({ onClick:()=>this.onRow(record,index) }) }
          expandedRowKeys={ expandIndex || null }
          className={
            `${
              renderBody ? 
                'TaskAssignmentTableComponent inPatientTaskTable' 
              :
                'TaskAssignmentTableComponent'
            }`
          }
          expandedRowRender={(doc, rowIndex) =>{
            const expandingIndex = _.first(expandIndex);
            const taskComponentProps = {
              key: doc.id,
              modalData: doc, 
              renderBody, 
              refetchUser, 
              refetchUserList,
              currentProgramId, 
              team, 
              setModalData,
            };
            // comparing expandingIndex and rowIndex
            return expandingIndex === rowIndex ?
              <TrackingTaskModalComponent {...taskComponentProps}/>
              : null;
          }}
          rowKey={(__,id) => id}
        />;
    }

    render(){
        const { props } = this;
        const { renderBody,columns,enrolledPrograms } = props;

        return this.renderTable(renderBody,columns,enrolledPrograms);
    }
}

export default TaskAssignmentTableComponent;
