export const CTAssigneeSchema = `
  assignees {
    assignedToRD{
      id
      profile{
        fullName
      }
    }
    assignedToCA{
      id
      profile{
        fullName
      }
    }
    assignedToCANotNecessary
    assignedToRDNotNecessary
  }
`;