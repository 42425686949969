import React from 'react';
import {graphql} from "react-apollo/index";
import TempEnrolledPatientsListComponent from '../components/TempEnrolledPatientsListComponent';
import createTableAction from 'libModule/table/TableActions';
import tempPatients from 'modulesAll/graphql/tempEnrolledPatientsList';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux'

const tableActions = createTableAction('provider_temp_enrolled_patients_list',{ shouldUpdateHistory: true });

const getVariables = (ownProps) => {
	const { page, count } = ownProps;
	return {
		page: _.get(ownProps, 'page.current', 1),
		count: 10
	};
};

const withData = graphql(tempPatients, {
	options: (ownProps) => {
		const variables = getVariables(ownProps);

		return {
			variables,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only'
		};
	},
	props: ({ ownProps, data }) => {
		const { loading, tempEnrolledPatientsList } = data;
		if (tempEnrolledPatientsList && tempEnrolledPatientsList.data.length) {
			tempEnrolledPatientsList.data.forEach(t => t.name = t.firstName + ' ' + t.lastName);
		}
		return {
			loading,
			list: tempEnrolledPatientsList
		}
	}
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.provider_temp_enrolled_patients_list,
});

const mapDispatchToProps = (dispatch) => {
  return {
  	...tableActions
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData,
)(TempEnrolledPatientsListComponent);
