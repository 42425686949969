import { STATUS_ENUM } from '../constant';

export const makePatientReferral = (curPatientReferral = {}, dischargedPatientReferral = {}) => {
  let _curPatientReferral = curPatientReferral || {};
  const isReferred = _.get(_curPatientReferral, 'status.referStatus') === STATUS_ENUM.COMPLETED;
  if(_.isEmpty(dischargedPatientReferral) || isReferred)
    return {..._curPatientReferral};

  _curPatientReferral = Object.assign({}, ..._.map(_curPatientReferral, (v, k) => {
    if(!_.isEmpty(v) || typeof v === 'boolean')
      return ({ [k]: v });
    return null;
  }));

  return { ..._.omit(dischargedPatientReferral, 'id'), ..._curPatientReferral };
}