import React from 'react';
import { Button } from 'antd';

const UnassignComponent = props => {
  const { onClick, onCancel } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ fontSize: 16, lineHeight: '20px', fontWeight: 700, marginBottom: 50 }}>
        Are you sure to unassign this device
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type='primary'
          style={{ marginRight: 12, background: 'var(--dark-blue)' }}
          onClick={onClick}
        >
          Unassign
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UnassignComponent;