import gql from 'graphql-tag'

export default gql`
query getEnrolledStatistics($adminProgramIds:[EID!]!) {
  enrolledPatientStatistics(adminProgramIds:$adminProgramIds) {
    total
    male
    female
    ageGroups {
      group1
      group2
      group3
      group4
      group5
    }
  }
}`