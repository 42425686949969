import React from 'react';
import LabAddAndListContainer from '../container/LabAddAndListContainer';
import gql from 'graphql-tag';
import { Button, Modal } from 'antd';
import { compose, graphql } from 'react-apollo/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const LabResInWorkListContainer = class extends React.Component {
  constructor(props){
    super(props);
    // 20200625 Thong - SC-4561
    // ref for save button in AddLabResults
    this.saveButtonRef = null;
    // SC-6173, ref for save button in LabResults
    this.saveEditButtonRef = null;
  }

  handleClick = () => {
    const { props, saveButtonRef, saveEditButtonRef } = this;
    const { toNextStep, title, submitButtonText } = props;

    // check whether there is unsaved lab results iff Save button is enabled
    try {
      // if (!saveButtonRef) throw new Error('Failed to auto-save lab result(s). No button ref');
      // const { disabled, onClick } = _.get(saveButtonRef, 'props', {});
      // if (disabled === false && onClick) {
      //   onClick(); // manipulate clicking Save button
      // }
      const { onClick: onClickEdit } = _.get(saveEditButtonRef, 'props', {});
      if(onClickEdit) {
        onClickEdit();
      }
    } catch (err) {
      Modal.error({ 
        content: err.message, 
        mask: false,
      });
    } finally {
      // toNextStep('LabResults', null, 'Lab Result is Saved');
      this.props.afterSubmitSucc();
      let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
      Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
      this.saveButtonRef = null;
      this.saveEditButtonRef = null;
    }
  };

  setSaveButtonRef = el => {
    if(!this.saveButtonRef) this.saveButtonRef = el;
  };

  setSaveEditButtonRef = el => {
    if(!this.saveEditButtonRef || !el) this.saveEditButtonRef = el;
  };

  render() {
    const { props, handleClick } = this;
    return (
      <div>
        <div className='fix-header charting-header'>Lab Results</div>
        <div className='scrollable-content'>
          <LabAddAndListContainer 
            {...props} 
            setSaveButtonRef={this.setSaveButtonRef}
            setSaveEditButtonRef={this.setSaveEditButtonRef}
          />
        </div>
        <div className='fix-footer' style={{ float: 'right' }}>
          <Button onClick={handleClick} type={'primary'}>
            {this.props.submitButtonText || 'Complete'}
          </Button>
        </div>
      </div>
    );
  }
};
const withData = graphql(
  gql`
    query labResultList($filter: FilterFindManyLabResultsInput) {
      labResultMany(filter: $filter) {
        userId
        _id
        viewerId
        templateId
        deleted
        templateName
        dateCollected
        createdAt
        results {
          name
          unit
          value
          flag
          interval
          isInHouse
        }
      }
    }
  `,
  {
    options: ({ userId }) => ({
      variables: { filter: { userId, filterDeleted: true } },
      fetchPolicy: 'network-only'
    }),
    props: ({
      data: {
        loading: labResultListLoading,
        labResultMany,
        refetch: labResultListRefetch
      }
    }) => {
      if (labResultListLoading) {
        return {
          loading: labResultListLoading
        };
      }
      return {
        labResultListLoading,
        labResultMany,
        labResultListRefetch
      };
    }
  }
);

export default compose(withData)(LabResInWorkListContainer);
