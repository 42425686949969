import React, { useState, useEffect } from 'react';

let finalResult = undefined,
    finalResultSet = undefined;

const withRouteLeaveHooks = (WrappedComponent) => {
  const RouteLeaveHookWrapper = props => {
    const { router, route } = props;
    const [leaveHooks, setLeaveHooks] = useState([]);

    // should call add function once component is mount on route
    // hook should be function returns Promise
    // index is to determine order of hooks
    const addRouteLeaveHook = (hook, index = leaveHooks.length) => {
      const newLeaveHooks = [...leaveHooks];
      newLeaveHooks.splice(index, 0, hook);
      setLeaveHooks(newLeaveHooks);
      // remove added leave hook when unmount without leaving route
      return () => {
        newLeaveHooks.splice(index, 1);
        setLeaveHooks(newLeaveHooks);
      }
    }

    const chainingHooks = (nextLocation, curHookIndex = 0) => {
      const hook = leaveHooks[curHookIndex];
      finalResultSet = typeof hook !== 'function' || finalResult === false;
      if(finalResultSet) {
        // try to push to confirm navigation again
        router.push(nextLocation);
        return;
      }
        
      hook(nextLocation).then(result => {
        finalResult = result;
        chainingHooks(nextLocation, curHookIndex+1);
      })
    }
  
    useEffect(() => {
      if(!router || !route) {
        console.warn('warn withRouteLeaveHooks: router and route are not found!');
        return;
      }

      const unsubcribeLeaveHooks = router.setRouteLeaveHook(route, (nextLocation) => {
        if(!sessionStorage.getItem('authToken')) return;  //if logged out, do nothing

        if(!finalResultSet) {
          chainingHooks(nextLocation);
        }
        const result = finalResult || false;
        finalResult = undefined;
        finalResultSet = undefined;
        return result;
      });
      return () => unsubcribeLeaveHooks();
    }, [leaveHooks]);
  
    return <WrappedComponent {...props} addRouteLeaveHook={addRouteLeaveHook} />
  }
  
  RouteLeaveHookWrapper.displayName = 'RouteLeaveHookWrapper';
  return RouteLeaveHookWrapper;
}

export default withRouteLeaveHooks;