import gql from 'graphql-tag'
import { EnrolledProgram } from 'graphqlModule/schema/EnrolledProgram'

export const removeTaskFromEnrolledProgram = gql `
  mutation removeTaskFromEnrolledProgram (
    $id: EID!,
    $taskId: [EID]!
  ) {
    removeTaskFromEnrolledProgram (
      id: $id,
      taskId: $taskId
    ) {
      ${EnrolledProgram}
    }
  }
`
