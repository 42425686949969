import gql from 'graphql-tag';
import {Phone,Identification} from '../../graphql/schema/common';
import PostItList from "../../graphql/postItList";
import { PatientInsuranceProvider } from '../../graphql/schema/InsuranceProvider';


const checkRemoteEnrollmentTask = gql`mutation moveRemoteEnrollment($memberId:EID!,
                                      $nutritionAssessment:Boolean,$techOnBoard: Boolean,$deviceDelivered: Boolean,
                                      $carePlanFiled: Boolean,$consentFiled: Boolean,$billingCodeAttached: Boolean,
                                      $pinnedToEMR: Boolean
                                      ){
  editRemoteEnrollment(nutritionAssessment:$nutritionAssessment,techOnBoard:$techOnBoard,deviceDelivered:$deviceDelivered,
                       carePlanFiled:$carePlanFiled,consentFiled:$consentFiled,billingCodeAttached:$billingCodeAttached,
                       pinnedToEMR:$pinnedToEMR
                       memberId:$memberId){
    status
  }
}`;
const moveRemoteEnrollment = gql`mutation moveRemoteEnrollment($memberId:EID!,$status:RemoteEnrollmentStatus){
  editRemoteEnrollment(status:$status,memberId:$memberId){
    status
  }
}`;

const addLastCallBy = gql`mutation addCallerInfo($memberId:EID!,$lastCallBy:InputRemoteEnrollmentCallRecord){
  editRemoteEnrollment(lastCallBy:$lastCallBy,memberId:$memberId){
    status
  }
}`;
const remoteEnrollmentSummary = gql`query RemoteEnrollmentSummary($crossClinic:Boolean){
  remoteEnrollmentSummary(crossClinic:$crossClinic){
    screened
    referred
    tbd
    confirmed
    completed
    excluded
    referredByMAMD
    enrolledByMAMD
  }

}`;

 const createRE = gql`mutation createRM($memberId:EID!,$conditionsList: InputConditionsListType,$doctorReferral:Boolean,$doctorReferralDate: Date ){
    createRemoteEnrollment(memberId:$memberId,conditionsList:$conditionsList,doctorReferral:$doctorReferral,remoteStatus:true,doctorReferralDate: $doctorReferralDate){
        status
    }
}`;

const remoteEnrollment = `
      organization{
        id
        name
      }
      member{
        id
        profile{
          fullName
          ...on MemberProfile{
            doctor{
              id
              profile{
                firstName
                lastName
                fullName
              }
            }
            language{
                code
                description
            }
            isSelfEnrolled
            insuranceProvider
            insuranceProvider2
            insuranceProviders {
              ${PatientInsuranceProvider}
            }
            conditionsList {
              HLD
              HTN
              DM
              CKD
              Obesity
              PreDM
              COPD
              ESRD_Dialysis
            }
            programCategories {
                name
                enrolled
                enrolledDate
            }
          }
        }
        phone{
          number
          countryCode
          type
        }      
      } 
      organization {
        id
      }
      doctorReferral
      doctorReferralDate
      nutritionAssessment {
        value
        updatedBy{
         name 
        } 
      }
      pinnedToEMR{
        value
        updatedBy{
         name 
        }
      }
      shippingInfo{
        shippingNumber
      }
      techOnBoard {
        value
        updatedBy{
         name 
        } 
      }
      deviceDelivered {
        value
        updatedBy{
         name 
        } 
      }
      carePlanFiled {
        value
        updatedBy{
         name 
        } 
      }
      consentFiled {
        value
        updatedBy{
         name 
        } 
      }
      conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
            ESRD_Dialysis
      }
      billingCodeAttached {
        value
        updatedBy{
         name 
        } 
      }
      callHistories{
        providerId
        name
        callDate
        isPickedUp

      }
      confirmedBy{
        providerId
        name
      }
      patientRefer { 
        doctorReferral
        enrolledBy {
          id
          profile {
            fullName
          }
          allRoles {
            roleType
            organization {
              id
            }
          }
        }
        referredBy {
          id
          profile {
            fullName
          }
        }
        referredAt
        conditionsToMonnitor
        haveSmartphone
        status {
           patientConsentEnroll
           screeningStatus
           confirmDevicesEnroll
           scheduleInitVisitEnroll 
           referStatus
           enrollStatus
        }
      }
      callers{
        name
        providerId
      }
      nextVisit{
        visitId
        appointmentAt
      }
      status
      latestStickyNote{
        note
      }
      stickyNotesCount
`;

const remoteEnrollmentList = gql`query rmList($filters:RemoteEnrollmentListOpts,$sort:[RemoteEnrollmentListSort],$page:Int,$count:Int){
  remoteEnrollmentList(filters:$filters,sort:$sort,page:$page,count:$count){
    data{
      member{
        id
        profile{
          fullName
          ...on MemberProfile{
            doctor{
              id
              profile{
                firstName
                lastName
                fullName
              }
            }
          }
        }
       
        phone{
          number
        }      
      }
      ${remoteEnrollment}
    }
    pageInfo{
     total
     lastPage 
    }
  }
  
}`;

const patientListWithRemoteEnrollment = gql`query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data{
        user{
            organization{
                id
                name
            }
            identification{
                ${ Identification }
            }
            phone {
                ${ Phone }
            }
            id
            profile{
                fullName
                ...on MemberProfile{
                    birthday
                    isSelfEnrolled
                   
                }
                
            }
            remoteEnrollment{
                ${remoteEnrollment}
            }
        }        

      }
    }
  }
`;
const editShippingNumber = gql`mutation moveRemoteEnrollment($memberId:EID!,$shippingNumber:String){
  editRemoteEnrollment(shippingNumber:$shippingNumber,memberId:$memberId){
    status
  }
}`;

const editReferByDoctor = gql` mutation editReferByDoctor($memberId:EID!,$doctorReferral:Boolean){
    editRemoteEnrollment(doctorReferral:$doctorReferral,memberId:$memberId){
    status
  }
 }
`;

const editExcludedReason = gql` mutation editReferByDoctor($memberId:EID!,$excludedReason:RemoteEnrollmentExcludedReasonEnums){
    editRemoteEnrollment(excludedReason:$excludedReason,memberId:$memberId){
    status
  }
 }
`;
const getRemoteEnrollment = gql`query getRemoteEnrollment($memberId:EID!){
  getRemoteEnrollment(memberId:$memberId){
    ${remoteEnrollment}
  }
}`


const latestVisit =  gql`query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
        pageInfo{
     	    total
            lastPage
        }
        data{    
          id
          type
          appointmentAt
          appointmentTo
          checkinAt
          checkoutAt
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
            ESRD_Dialysis
          }
          member {
            profile {
              ...on MemberProfile {
                conditionsList {
                  HLD
                  HTN
                  DM
                  CKD
                  Obesity
                  PreDM
                  COPD
                  ESRD_Dialysis
                }
              }
            }
          }
         
          
          }
     }

}`
const editConditionListMutate = gql`mutation editConditionList($memberId:EID!,$conditionsList: InputConditionsListType){
  editRemoteEnrollment(conditionsList:$conditionsList,memberId:$memberId){
    status
  }
}`;

const sendPromoteSMSMutate = gql`mutation sendPromoteSMS($memberId:EID!,$number:String!,$countryCode:String,$type:SmsMsgTypeEnum!){
  sendMemberSms(memberId:$memberId,number:$number,countryCode:$countryCode,type:$type){
    notified
    message
  }
}`;
const getSelectedOrgsQuery = gql`query getSelectedClinic{
    getSelectedClinic{
            organizations{
                organizationId
                name
            }
            
    }
}`
const clinicCandidatesQuery = gql`query getClinicCandidates{
    getClinicCandidates{
        name
        internalName
        organizationId
    }
}`;
export default {
    remoteEnrollmentList,
    moveRemoteEnrollment,
    addLastCallBy,
    checkRemoteEnrollmentTask,
    patientListWithRemoteEnrollment,
    getRemoteEnrollment,
    remoteEnrollmentSummary,
    editShippingNumber,
    editReferByDoctor,
    editExcludedReason,
    PostItList,
    latestVisit,
    createRE,
    editConditionListMutate,
    sendPromoteSMSMutate,
    getSelectedOrgsQuery,
    clinicCandidatesQuery
}
