import React, { Component } from 'react';
import { IHSearchTable, IHLoading, _, message } from 'ihcomponent';
import { Icon, Input, Select, Modal, Button, Form, Row, Timeline } from 'antd';
import moment from 'moment';
import MedicineModalComponent from 'modulesAll/medicine/components/MedicineModalComponent';
import createMedication from 'graphqlModule/mutation/createMedication';
import editMedication from 'graphqlModule/mutation/editMedication';
import medicationList from 'modulesAll/graphql/medicationList';
import Client from 'libModule/gqlClient';
import getVariables from 'modulesAll/medicine/query/variables.js';
import '../../style/_medication.styles.scss';
import { medicationManagement } from 'modulesAll/graphql/mutation/editUser';
import { enumToType, statusEnumToType, medicationEnumToType } from '../../constant/medicationConstant';
import helper from '../helper/medicationHelper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const Item = Timeline.Item;

const { TextArea } = Input
export class MedicationComponent extends Component {
    constructor(props) {
        super(props);
        let medicationManagement = _.get(this.props, 'user.profile.medicationManagement', '')
        this.state = {
            medicine: null,
            modal: { },
            hasNote: false,
            reconcilationCompliance: _.get(medicationManagement, 'reconcilationCompliance', ''),
            otherInfo: _.get(medicationManagement, 'otherInfo', ''),
            extraNote: _.get(medicationManagement, 'extraNote', ''),
            hasHistory: true,
            hasOtherMed: false,
            otherMed: '',
            showHistory:false,
            history:[],
            showSave: false
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const preMed = _.get(prevProps, 'user.profile.medicationManagement') || {};
        let newMed = _.get(this.props, 'user.profile.medicationManagement') || {};
        console.log('Props', this.props);
        if(newMed && !_.isEqual(preMed, newMed)) {
            if(!helper.checkIsOtherMed(newMed.reconcilationCompliance)) {
                this.setState({
                    hasHistory: true,
                    hasOtherMed: true,
                })
                this.props.form.setFieldsValue({ 'medication': "Other"});
                this.props.form.setFieldsValue({ 'other-med': newMed.reconcilationCompliance});
            } else {
                if(!newMed.reconcilationCompliance === medicationEnumToType['NoTaken']) {
                    this.setState({
                        hasHistory: false,
                        hasOtherMed: false
                    })
                } else {
                    this.setState({
                        hasHistory: true,
                        hasOtherMed: false
                    })
                }
                this.props.form.setFieldsValue({ 'medication': newMed.reconcilationCompliance});
            }
            this.setState({
                reconcilationCompliance: newMed.reconcilationCompliance,
                otherInfo: _.get(newMed, 'otherInfo', ''),
                extraNote: _.get(newMed, 'extraNote', ''),
            })
            // this.props.setEditMode ? this.props.setEditMode(false) : "";
        };
    }

    setShowHistoryModal = (flag)=>{
        this.setState({
            showHistoryModal:flag
        })
    }

    renderMedicineHistory(){
        const  { setShowHistoryModal, props, state } = this;
        const { showHistoryModal, curMed } = state;
        const { history, name } = this.state.history;
        const filteredHis = _.filter(history, h => h.field);
        if(showHistoryModal){
            return <Modal visible={showHistoryModal} closable={true} footer={null}
                          mask={false} width={'fit-content'}
                          style={{minWidth:300}}
                          destroyOnClose={ true }
                          onCancel={()=>setShowHistoryModal(false)}
            >
                {
                    filteredHis.length > 0 ?
                        <div style={{ padding:20 }}>
                            <h4 style={{ marginLeft: 18,marginBottom: 30 }}>{ name }</h4>
                            <Timeline>
                                {_.map(filteredHis, ({originalValue, newValue, field, modifiedAt}, index) =>
                                    <div key={index}>
                                        <li>
                                            <div>{ moment(modifiedAt).format('MM/DD/YYYY') }</div>
                                        </li>
                                        <Item key={index}>{`${_.upperFirst(field)} changed ${originalValue} to ${newValue}`}</Item>
                                    </div>)
                                }
                            </Timeline>
                        </div> :
                        <div>
                            <h4 style={{ marginLeft: 20 }}>{ name }</h4>
                            <span style={{ marginLeft: 20 }}>No History</span>
                        </div>
                }
            </Modal>
        }
    }

    onClickEdit = (curMed) => {
        if(this.props.disabled) {
           return
        }
        const modal = {
            type:'update',
            query:editMedication
        }
        this.props.setShowMedModal(true);
        this.setState({
            medicine: curMed,
            modal
        })
    }

    onClickAdd = () => {
        if(this.props.disabled) {
            return
        }
        const modal = {
            type:'add',
            query:createMedication
        }
        this.props.setShowMedModal(true);
            this.setState({
                medicine: null,
                modal
        })
    }

    onClickAddNote = () => {
        if(this.props.disabled) {
            return
        }
        this.setState({hasNote:true})
    }

    deleteMed = (curMed) => {
        const id = curMed.id;
        const query = editMedication;
        const patientId = this.props.patientId;

        let variables = {
            id:id,
            memberId: patientId,
            active:false
        }

        Client.mutate({
        mutation: query,
        variables: variables,
        refetchQueries:[{
            query:medicationList,
            variables:getVariables(this.props),
            fetchPolicy:'network-only'
        }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props, this.constructor.displayName, apiName, patientId, docId, 'removeMedicine');
            setShowMedModal(false);
            message.success('Medication Removed');

        }).catch((error)=>{
            console.log(error);
        })
    }

    parseValue = (childProps) => {
        return childProps
    }

    onTouchCb = (variables) => {
        this.props.onTouch()
        const query = medicationManagement;
        const childProps = variables;
        const draftType = 'Medication Management';
        const refetchQueries = this.props.refetch;
        this.props.setChildProps(childProps, this.parseValue, query, refetchQueries, draftType)
    }

    addOtherInfo = ({target: {value}}) => {
        this.setState({
            otherInfo: value,
            showSave: this.props.medicationTab
        }, () => {
            let variables = this.generateVar();
            let queryVariables = this.generateQueryVar(variables)
            if(!this.props.workList && !this.props.medicationTab) {
                this.props.onChange(queryVariables)
            }
            if(this.props.onTouch) {
                this.onTouchCb(variables);
            }
        })
    }

    addOtherMed = ({target: {value}}) => {
        this.setState({
            otherMed: value,
            reconcilationCompliance: "Other",
            showSave: this.props.medicationTab
        }, () => {
            let variables = this.generateVar();
            let queryVariables = this.generateQueryVar(variables)
            if(!this.props.workList && !this.props.medicationTab) {
                this.props.onChange(queryVariables)
            }
            if(this.props.onTouch) {
                this.onTouchCb(variables);
            }
        })
    }

    addExtraNote = ({target: {value}}) => {
        this.setState({
            extraNote: value,
            hasNote: value ? true : false,
            showSave: this.props.medicationTab
        }, () => {
            let variables = this.generateVar();
            let queryVariables = this.generateQueryVar(variables)
            if(!this.props.workList && !this.props.medicationTab) {
                this.props.onChange(queryVariables)
            }
            if(this.props.onTouch) {
                this.onTouchCb(variables);
            }
        })
    }

    addReconcilationCompliance = (val) => {
        let variables = '';
        let queryVariables = '';
        switch (val) {
            case "NoTaken":
                this.setState({
                    hasHistory: false,
                    hasOtherMed: false,
                    otherMed: "",
                    reconcilationCompliance: medicationEnumToType[val],
                    showSave: this.props.medicationTab
                }, () => {
                    variables = this.generateVar();
                    queryVariables = this.generateQueryVar(variables)
                    if(!this.props.workList && !this.props.medicationTab) {
                        this.props.onChange(queryVariables)
                    }
                    if(this.props.onTouch) {
                        this.onTouchCb(variables);
                    }
                })
                break;
            case "Other":
                this.props.form.setFieldsValue({ 'medication': "Other"});
                this.setState({
                    hasHistory: true,
                    hasOtherMed: true,
                    reconcilationCompliance: this.state.otherMed,
                    showSave: this.props.medicationTab
                }, () => {
                    variables = this.generateVar();
                    queryVariables = this.generateQueryVar(variables)
                    if(!this.props.workList && !this.props.medicationTab) {
                        this.props.onChange(queryVariables)
                    }
                    if(this.props.onTouch) {
                        this.onTouchCb(variables);
                    }
                })
                break;
            default:
                this.setState({
                    hasHistory: true,
                    hasOtherMed: false,
                    otherMed: "",
                    reconcilationCompliance: medicationEnumToType[val],
                    showSave: this.props.medicationTab
                }, () => {
                    variables = this.generateVar();
                    queryVariables = this.generateQueryVar(variables)
                    if(!this.props.workList && !this.props.medicationTab) {
                        this.props.onChange(queryVariables)
                    }
                    if(this.props.onTouch) {
                        this.onTouchCb(variables);
                    }
                })
                break;
        }
    }

    showDeleteConfirm = (curMed) => {
        if(this.props.disabled) {
            return
        }
        Modal.confirm({
            title: 'Are you sure you want to delete this medication?',
            content:"Once it’s deleted, this medication will not show in the medication history.",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex:2000,
            mask:true,
            className:'deleteMedModule',
            maskClosable:true,
            onOk:()=>{
               this.deleteMed(curMed);
            }
        });
    }

    setModal = (modal)=>{
        modal.query = editMedication;
        if(modal.type == 'update') {
            this.setState({
                modal
            });
        }
    }

    setMedicine = (medicine)=>{
        this.setState({
            medicine
        })
    }

    handleOnClick() {
        const { afterSubmitSucc, form, title, submitButtonText } = this.props;
        let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
        Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
        form.validateFields((err) => {
            if(err) return;
            let variables = this.generateVar();
            Client.mutate({
                mutation: medicationManagement,
                variables: variables
            }).then((res)=>{
                if(!this.props.medicationTab){
                    afterSubmitSucc();
                }
            }).catch((error)=>{
                console.log(error);
            })
        })
    }

    handleOnSave() {
        this.props.form.validateFields((err) => {
            if(err) return;
            let variables = this.generateVar();
            Client.mutate({
                mutation: medicationManagement,
                variables: variables
            }).then((res)=>{
                if(this.props.medicationTab) {
                    this.props.refetchUser();
                    message.success('Medication Saved');
                    this.handleOnCancel();
                } else {
                    this.props.refetch()
                    this.props.afterSaveDraftSucc();
                }
            }).catch((error)=>{
                console.log(error);
            })
        })
    }

    handleOnCancel() {
        this.setState({
            showSave: false
        })
    }

    saveEdit = (flag, setEditMode, msg) => {
        const { props } = this;
        const { toNextStep, setIsEditMode } = props;
        flag ? message.success(msg) : '';
    };

    generateVar = () => {
        const patientId = this.props.patientId;
        const memberProfile = {
            birthday: this.props.workList ? this.props.member.profile.birthday : this.props.user.profile.birthday,
            medicationManagement : {}
        }
        memberProfile.medicationManagement.reconcilationCompliance = !_.isEmpty(this.state.otherMed) ? this.state.otherMed : this.state.reconcilationCompliance;
        memberProfile.medicationManagement.otherInfo = this.state.otherInfo;
        memberProfile.medicationManagement.extraNote = this.state.extraNote;
        let variables = {
            id: patientId,
            memberProfile
        }
        return variables;
    }

    generateQueryVar = (variables) => {
        let queryVariables = { };
        queryVariables.props = this.props;
        // let flag = this.props.workList
        // let cb = (variables) => Client.mutate({
        //     mutation: medicationManagement,
        //     variables: variables,
        //     refetchQueries:[{ query:userGQL, variables: { id:variables.id }, fetchPolicy:'network-only'}]
        // }).then((res)=>{
        //     this.saveEdit(flag, false, 'Medication Management is Saved');
           
        // }).catch((error)=>{
        //     console.log(error);
        // })
        // queryVariables.cbVar = variables
        // queryVariables.cb = cb
        queryVariables.shouldEditUser = true
        queryVariables.editUserVar = variables
        return queryVariables
    }

    renderMedList = (tableProps, noDataElem)=>{
        tableProps.title = 'Medication History';
        return  <div className="patient-task-table">
            { this.renderCurrentMedList(Object.assign({},tableProps),noDataElem) }
            <div style={{ height:20 }}></div>
            { this.renderPreMedList(Object.assign({},tableProps),noDataElem)}
        </div>
    }
    renderCurrentMedList = (tableProps,noDataElem)=>{
        let { dataSource } = tableProps;
        dataSource = _.filter(dataSource,d=> helper.checkIfMedicationActive(d));
        tableProps.dataSource = dataSource;
        tableProps.title = 'Current Prescribed Medications';
        return  <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    }

    renderPreMedList = (tableProps,noDataElem)=>{
        let { dataSource } = tableProps;
        dataSource = _.filter(dataSource, d=> helper.checkIfMedicationDiscontinued(d));
        tableProps.dataSource = dataSource;
        tableProps.title = 'Previous Medications';
        tableProps.actions = null;
        const colLen = tableProps.columns.length;
        tableProps.columns = tableProps.columns.slice().splice(0,colLen-1);

        return  <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    }

    renderMedicineModal() {
        const { showMedModal, setShowMedModal, disabled } = this.props;
        if(showMedModal){
            const { props, setModal, setMedicine } = this;
            // avoid passing formProps to another Form
            const { form, ...restProps } = props; 

            return <Modal visible={true}
                          footer={null}
                          mask={false} 
                          maskClosable = { false }
                          destroyOnClose={ true }
                          onCancel={()=>setShowMedModal(false)}
                          style={{marginTop:30}}
                          width={550}
                    >
                        <MedicineModalComponent 
                          {...restProps} 
                          modal={this.state.modal} 
                          curMed={this.state.medicine} 
                          setModal={setModal} 
                          setMedicine={setMedicine} 
                          onChange ={ () => {
                            const vars = this.generateVar();
                            const queryVariables = this.generateQueryVar(vars);
                            this.props.onChange(queryVariables);
                          }}
                          onTouchCb={this.onTouchCb}
                        />
                   </Modal>
        }
    }

    renderForm = () => {
        const { disabled, workList, form } = this.props;
        const { getFieldDecorator } = form;
        let { hasOtherMed, reconcilationCompliance } = this.state
        return <div className={workList ? "medication-form-workList" : ''}>
                    <Form.Item className={workList ? '' : 'medication-select-profile'}>
                        {
                            getFieldDecorator('medication', {
                                rules: [{ required: true, message:'Medication Reconciliation & Compliance is required' }],
                                initialValue: helper.checkIsOtherMed(reconcilationCompliance) ? reconcilationCompliance: 'Other'
                                    // ? reconcilationCompliance : 'Other') : 'Taken'
                            })(<Select className='medication-select-option' /*value={helper.checkIsOtherMed(reconcilationCompliance) ? reconcilationCompliance : 'Other'}*/ onChange={this.addReconcilationCompliance} disabled={disabled}>
                                <Select.Option value="Taken">Medications taken as prescribed</Select.Option>
                                <Select.Option value="TakenWithForget">Medications taken as prescribed, but sometimes forgets</Select.Option>
                                <Select.Option value="SelfTaken">Some medications patient often self titrates or stops independent of/ in conflict with physician advice</Select.Option>
                                <Select.Option value="NoTaken">Not taking medications because of non-compliance</Select.Option>
                                <Select.Option value="NotDone">Medication reconciliation not done...Patient will do at follow up with MD or with RD online</Select.Option>
                                <Select.Option value="NoPrescription">Patient does not have any medications prescribed</Select.Option>
                                <Select.Option value="Other">Other</Select.Option>
                            </Select> )
                        }
                    </Form.Item>
                    {
                        (hasOtherMed || (reconcilationCompliance && !helper.checkIsOtherMed(reconcilationCompliance))) ?
                        <Form.Item className={workList ? 'medication-other-workList' : 'medication-other-profile'}>
                            {
                                getFieldDecorator('other-med', {
                                    rules: [{ required: true, message: 'Please input the other medication', whitespace: true }],
                                    initialValue: helper.getDefaultVal(this.state)
                                })(<Input className='medication-other-input' placeholder="Type new..." /*value={helper.getDefaultVal(this.state)}*/ onChange={this.addOtherMed} disabled={disabled} allowClear/>)
                            }
                        </Form.Item>
                        : ""
                    }
                </div>
    }

    render() {
        const { setShowHistoryModal } = this;
        const {loading, medicationList, disabled, workList } = this.props;
        const data = _.get(medicationList, 'data', []);
        let { hasHistory } = this.state
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                width:'10%',
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend'],
                sorter:(a,b)=>_.trim(a.name).localeCompare(_.trim(b.name)),
                render:(value) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{value}</span>
                }
            },
            {
                title: 'Type',
                dataIndex: 'type',
                width:'10%',
                filters: [
                    { text: 'Oral Medication', value:  'ORAL' },
                    { text: 'Injectable', value:'INJECTABLE' },
                    { text: 'External', value:'EXTERNAL' },
                    { text: 'Inhalable',value:'INHALED' }
                ],
                onFilter: (value,row) => {
                    return row.type == value;
                },
                render:(value)=> {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{enumToType[value]}</span>
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width:'10%',
                filters: [
                    { text: 'Taking', value:  'TAKING' },
                    { text: 'Not Sure', value: 'NOT_SURE' },
                    { text: 'Not Taking', value: 'NOT_TAKING' },
                    { text: 'Taking Irregularly', value: 'TAKING_IRREGULARLY'}
                ],
                onFilter: (value, row) => {
                    return row.status == value;
                },
                render:(value) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{statusEnumToType[value]}</span>
                }
            },
            {
                title:'Dosage',
                width:'8%',
                dataIndex:'dosage',
                render:(value) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{value}</span>
                }
            },
            {
                title:'Start Date',
                dataIndex:'startDate',
                width:'13%',
                sorter: (a, b) => a.startDate - b.startDate,
                render:(v) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{v ? moment(v).format('MM/DD/YYYY'):''}</span>
                }
            },
            {
                title:'End Date',
                dataIndex:'endDate',
                width:'13%',
                sorter:(a, b) => a.endDate - b.endDate,
                render:(v) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{v ? moment(v).format('MM/DD/YYYY'):''}</span>
                }
            },
            {
                title: 'Instruction',
                width:'15%',
                dataIndex: 'instruction',
                render:(value) => {
                    return <span style={{cursor: disabled ? 'not-allowed' : 'pointer'}}>{value}</span>
                }
            },
            {
                title:'',
                width: '8%',
                render:(curMed)=>{
                    const onClick = (e) => {
                        setShowHistoryModal(true);
                        this.setState({
                            history:{ history:curMed.histories,name:curMed.name }
                        })
                    }
                    return <span style={{textDecoration:'underline',cursor:'pointer' }} onClick={() => onClick(curMed)}>History</span>
                }
            },
            {
                title: '',
                width: '6%',
                render: (curMed, row) => {
                    return <span
                                style={{textDecoration:'underline', cursor: disabled ? 'not-allowed' : 'pointer'}}
                                onClick={() => this.onClickEdit(curMed)}>Edit</span>
                }
            },
            {
                title: '',
                width:'5%',
                render: (curMed, row) => {
                    return <Icon
                                type="close"
                                style={{cursor: disabled ? 'not-allowed' : 'pointer'}}
                                onClick={() => this.showDeleteConfirm(curMed)} />
                }
            }
        ];

        let tableProps = {
            columns:  columns,
            rowKey: 'id',
            loading: { spinning: loading, indicator: <IHLoading/> },
            dataSource: _.isEmpty(data) ? []: data,
            optionsBox: true
        };

        const noDataElem = (
            <div style={{ paddingTop:'2em', display:'flex', flexDirection:'column', alignItems:'center', fontFamily:'HelveticaNeue', color:'#c5c5c5' }}>
                <div>
                    <img  src="/image/no-medication-graphic.svg"
                          className="no-medication-graphic" width='116.6px' height='56.1px'/>
                </div>
            </div>
        );

        return (
            <div>
                <div className="scrollable-content">
                    <label className='ant-form-item-required'>
                      <b>Medication Reconciliation & Compliance</b>
                    </label>
                    {this.renderForm()}
                    { this.renderMedicineModal() }
                    { this.renderMedicineHistory() }
                    <div style={{marginTop:14}}>
                        <span>Other Medication Information</span>
                        <Input style={{marginTop:6}} placeholder="Enter details about skipped medications and/or nutritional supplements" value={this.state.otherInfo} onChange={this.addOtherInfo} disabled={disabled} allowClear/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        {hasHistory && this.state.reconcilationCompliance !== 'Not taking medications because of non-compliance' ? <Button type='link' onClick={() => this.onClickAdd()} disabled={disabled} className='add-click'><Icon type='plus'/>Add Medication</Button> : ''}
                    </div>
                    {hasHistory && this.state.reconcilationCompliance !== 'Not taking medications because of non-compliance' ? this.renderMedList(Object.assign({}, tableProps), noDataElem) : ""}
                    {/*{hasHistory && this.state.reconcilationCompliance !== 'Not taking medications' ? <Button type='link' onClick={() => this.onClickAdd()} disabled={disabled} className='add-click'><Icon type='plus'/>Add Condition</Button> : ''}*/}
                    <div>
                        {
                            this.state.hasNote || this.state.extraNote !== '' ?
                            <div style={{display:'flex', flexDirection:'column', marginTop: this.props.medicationTab ? 14 : 0}}>
                                <span>Notes</span>
                                <TextArea style={{width:550}} placeholder="Enter any additional information here (eg., family history, treatment history etc...)" value={this.state.extraNote} disabled={disabled} onChange={this.addExtraNote} allowClear/>
                            </div> :
                        <Button type='link' onClick={() => this.onClickAddNote()} disabled={disabled} className='add-click'><Icon type='plus'/>Add Note</Button>
                        }
                    </div>

                </div>
                {
                    this.props.workList ?
                    <Row className='fix-footer'>
                        {this.props.isEditMode ? (
                        <Button onClick={() => this.handleOnSave()} className='saveDraftBtn'>
                            Save Draft
                        </Button>
                        ) : ('')}
                        <Button onClick={() => this.handleOnClick()} type={'primary'}>
                        {this.props.submitButtonText || 'Complete'}
                        </Button>
                    </Row> : 
                    (this.props.medicationTab && this.state.showSave ? 
                        <Row style={{marginTop: 14}}>
                            <Button onClick={() => this.handleOnCancel()} type={'primary'}>Cancel</Button>
                            <Button style={{marginLeft: 10}} onClick={() => this.handleOnSave()} type={'primary'}>Save</Button>
                        </Row> : '')
                }
            </div>
        )
    }
}

MedicationComponent = Form.create({
    onValuesChange: props => {
        if(!props.isEditMode && !props.workList && !props.disabled && !props.medicationTab) {
            props.onChange({});
        }
    }
  })(MedicationComponent)

export default MedicationComponent
