import React from 'react';
import PropTypes from 'prop-types';
import I18N from 'modulesAll/I18N';
const goalAssessmentI18N = I18N.get('workStation.goalAssessmentContent');

import { CONDITION_ENUM_MAP } from '../../careplanNew/component/helper/goalHelper';
import { getUserRole } from '../../../lib/utils';

const ASSESSMENT_ROLE_MAP = {
  RD: goalAssessmentI18N.RD_GOAL_ASSESSMENT,
  MA: goalAssessmentI18N.MA_GOAL_ASSESSMENT,
  MD: goalAssessmentI18N.MD_GOAL_ASSESSMENT,
};

const GoalAssessmentContent = (props) => {
  const { goalType, goalAssessment } = props;

  const userRole = getUserRole();
  const descriptionMap = ASSESSMENT_ROLE_MAP[userRole] || {};
  const titleText = `${CONDITION_ENUM_MAP[goalType] || '--'} Goal Assessment`;
  const descriptionText = _.map(goalAssessment, ga => {
    const { params, assessment } = ga;
    let text = descriptionMap[assessment];

    _.forEach(params, p => {
      const { name, value } = p;
      text = _.replace(text, `{${name}}`, CONDITION_ENUM_MAP[value] || value);
    });

    if(text && /[A-Z]/.test(text.charAt(0)))
      text = '\n' + text;

    return text;
  });

  return (
    <div className='goal-assessment-content'>
      <div className='title'>
        {titleText}
      </div>
      <div className='description'>
        {descriptionText.join('') || 'N/A'}
      </div>
    </div>
  );
};

GoalAssessmentContent.propTypes = {
  goalType: PropTypes.string.isRequired,
  goalAssessment: PropTypes.arrayOf(PropTypes.shape({
    params: PropTypes.any,
    assessment: PropTypes.string,
  }))
};

export default GoalAssessmentContent;