import gql from 'graphql-tag'
import { ProgramWithoutCreator } from 'graphqlModule/schema/Program'

export const editAdminProgram = gql `
  mutation editAdminProgram (
    $id: EID!,
    $name: String,
    $description: String,
    $healthCondition: HealthCondition,
    $duration: DurationInput,
    $tasks: [InputTask]
  ) {
    editAdminProgram (
      id: $id,
      name: $name,
      description: $description,
      healthCondition: $healthCondition,
      duration: $duration
      tasks: $tasks
    ) {
      ${ProgramWithoutCreator}
    }
  }
`

export const editProgramInfo = gql `
  mutation editProgramInfo (
    $id: EID!,
    $name: String,
    $description: String,
    $healthCondition: HealthCondition,
    $duration: DurationInput,
  ) {
    editAdminProgram (
      id: $id,
      name: $name,
      description: $description,
      healthCondition: $healthCondition,
      duration: $duration
    ) {
      ${ProgramWithoutCreator}
    }
  }
`

export const editAdminProgramTask = gql `
  mutation editAdminProgramTask (
    $id: EID!,
    $tasks: [InputTask]
  ) {
    editAdminProgram (
      id: $id,
      tasks: $tasks
    ) {
      ${ProgramWithoutCreator}
    }
  }
`
