import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';


const PatientListOptionTemplate = props => {
  return props.hasNoResult ?
    <Row>
      <Col>No patient found</Col>
    </Row>
  :
    <Row>
      <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
        {props.fullName}
      </Col>
      <Col span={4}>
        {props.DOB}
      </Col>
      <Col span={4} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
        {props.MRN}
      </Col>
      <Col span={4}>
        {props.mobilePhone}
      </Col>
      <Col span={4}>
        {props.status}
      </Col>
      <Col>
        {props.CCMRPM}
      </Col>
    </Row>;
};

// title
PatientListOptionTemplate.defaultProps = {
  fullName: 'Name',
  DOB: 'DOB',
  MRN: 'MedID',
  mobilePhone: 'Tel',
  status: 'Type',
  CCMRPM: 'CCM/RPM'
};

PatientListOptionTemplate.propTypes = {
  hasNoResult: PropTypes.bool
};

export default PatientListOptionTemplate;