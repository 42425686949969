import React, { useState } from 'react';
import { Button } from 'antd';
import I18N from '../../I18N';
import Overlay from './Overlay';
import { EXCLUSION_REASONS_MAP } from '../constant';

const ExcludedOverlay = ({ user, closeModal, onContinue }) => {
  const [showExcludedOverlay, setShowExcludedOverlay] = useState(_.get(user, 'remoteEnrollment.status') === 'EXCLUDED');
  const reason = EXCLUSION_REASONS_MAP[_.get(user, 'remoteEnrollment.excludedReason')];

  const handleContinue = () => {
    setShowExcludedOverlay(false);
    if (onContinue) onContinue();
  }

  if (!showExcludedOverlay) return null;
  return (
    <Overlay styles={{ zIndex: 10000 }}>
      <div className="flex-center fit-parent">
        <div>
          <div style={{ marginBottom: 20 }}>{I18N.get('referAndEnroll.excludedOverlayText').replace(I18N.get('referAndEnroll.excludedOverlayReason'), reason)}</div>
          <div className="flex-center">
            <Button onClick={closeModal}>Go back</Button>&nbsp;
            <Button type="primary" className="new-style" onClick={handleContinue}>Continue</Button>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

export default ExcludedOverlay;