import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';


class EnrolledProgramWithId extends React.Component {

  render() {
    const { data } = this.props;
    return (
      this.props.children(data)
    );
  }
}

const addEnrolledProgramGQL = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: ownProps.enrolledProgramId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => { return { data } },
});


const enhance = compose(
  addEnrolledProgramGQL,
);
export default enhance(EnrolledProgramWithId);
