import type from '../constants'
import { actionTypes as VitalListTypes } from 'patientModule/Careplan/Vital/ducks/VitalListDuck'
import get from 'lodash/get'

export default{
  changeSelect : (selectedProgram)=>{
    return {
      type : type.CHANGE_SELECT,
      selectedProgram,
      id: get(selectedProgram, 'id')
    }
  },
  initVitalList : (selectedProgram)=>{
    return {
      type : VitalListTypes.INIT_DATA,
      selectedProgram
    }
  },
  changeStartDate: (startDate)=>{
    return {
      type : type.CHANGE_START_DATE,
      startDate
    }
  },
  changeDuration : (duration)=>{
    return {
      type : type.CHANGE_DURATION,
      duration
    }
  },

  setSelectCareteam : (index, id, name, teamInfo)=>{
    return {
      type : type.SET_SELECT_CARETEAM,
      selectCareteam : {
        index,
        id,
        name,
        teamInfo
      }
    }
  },
  setHaveCareTeam : (haveCareTeam) => {
    return {
      type : type.SET_HAVE_CARETEAM,
      haveCareTeam
    }
  },
  initialState : ()=>{
    return {
      type : type.INITIAL_STATE
    }
  },
  resetState:()=>{
    return {
      type: type.RESET_STATE
    }
  },
  enrolledProgramAndGotoNext : ()=>{

  }
}
