import { ROLE_NAME } from '../../../lib/constants';

export default {
    'Nurse':'RD',
    'Doctor':'Doctor',
    'Clinic Medical Assistant':'MA',
    'Medical Assistant':'CA',
    'PROVIDER:Doctor':'Doctor',
    "PROVIDER:Clinic Medical Assistant":'MA',
    'PROVIDER:Medical Assistant':'CA',
    'PROVIDER:Nurse':'RD',
    [ROLE_NAME.HC]: 'HC',
    [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
}

