import React, { Component } from 'react';
import { Col, Row, Select, Form, Checkbox, Tooltip, Icon } from "antd";
import {helpers} from "../../visit/helpers";
import {helpers as dataHelper} from "../helper";
import I18N from "../../I18N";
import helper from "../../taskAssignment/helper/index";
import { getVisitRoles } from '../../../lib/utils';

const {Item} = Form;
const {Option} = Select;

const renderMissingParticipantOption = (fullName) => {
  return <div style={{ display: 'inline-block'}}>
    <span>{fullName}</span>
    <Tooltip 
      overlayClassName="missingParticipantTip"
      title={`This participant is not in the same care team with you or this participant is set as "Visitor"\
                    in your care team. Please inform your manager if needed.`}
    >
      <Icon type="info-circle" theme="filled" style={{ color: 'red', marginLeft: 3  }}/>
    </Tooltip>
  </div>
}

const assigneesMap = {
    'CA': 'assignedToCA',
    'RD': 'assignedToRD',
    // 'HC': 'assignedToHC'
}

// const EXCLUSIVE_ROLES = {
//     'HC': 'RD',
//     'RD': 'HC'
// }

class VisitInputProviders extends Component{
    constructor(props) {
        super(props);
        this.state = {
            participantRoles: this.getInitialVisitRoles(),
        };
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {form, patient, autoFillAssignees} = this.props;
        if (!prevState.updatedCheckboxes && this.state.updatedCheckboxes) {
            this.setState({ updatedCheckboxes: false });
            this.props.form.validateFields();
        }
        const preVisitType = prevProps.visitType;
        const curVisitType = this.props.visitType;
        let assignees;
        if (curVisitType == 'MNT' && curVisitType != preVisitType) {
          assignees = { 'assignedToCA': null, 'assignedToRD': null };
        } else if(curVisitType != 'MNT'&&curVisitType != preVisitType){
          const assignedToRDId = _.get(patient, 'user.assignees.assignedToRD.id');
          const assignedToCAId = _.get(patient, 'user.assignees.assignedToCA.id');
          const {visitRoles, assignedToCA, assignedToRD} = form.getFieldsValue();
          assignees = { 
            'assignedToCA': visitRoles.includes('CA') ? (assignedToCA || assignedToCAId) : null, 
            'assignedToRD': visitRoles.includes('RD') ? (assignedToRD || assignedToRDId) : null
          };
        }
        if(!_.isEmpty(assignees)) {
          form.setFieldsValue({ ...assignees });
          if(autoFillAssignees) {
            const visitAssignees = Object.assign({}, ..._.map(assignees, (id, k) => {
              let name = '';
              if(id) {
                const u = _.find(_.get(this, `optionsCopy.${k}`, []), { id }) || {};
                name = u.fullName || '';
              }
              return {
                [k]: !id ? {} : {
                  providerId: id,
                  name
                }
              };
            }));
            autoFillAssignees(visitAssignees);
          }
        }
    }

    getInitialCategory = () => {
      const { visitInfo, editStatus, hasLoadDevice } = this.props;
      const category = _.get(visitInfo, 'category');
      const defaultVisitType = hasLoadDevice ? 'INCLINIC' :'VIRTUAL';
      return category || (editStatus ? 'INCLINIC' : defaultVisitType);
    }

    renderProvider(){
        const {form, visitInfo, editStatus, patient} = this.props;
        const { getFieldDecorator } = form;
        let doctorOptions = helpers.filterDoctors();
        let initDoctorOption = {};
        if(editStatus && visitInfo.providers){
          initDoctorOption = { id: _.get(visitInfo, 'providers.0.id'), profile: _.get(visitInfo, 'providers.0.profile') };
        }
        if(!initDoctorOption.id) {
          initDoctorOption = _.get(patient,'user.profile.doctor') ||
                             _.get(_.filter(doctorOptions,d=>d.primary),'0') || {};
                             
        }
        if(initDoctorOption && initDoctorOption.id) {
          const initialOption = {
            id: _.get(initDoctorOption, 'id'),
            fullName: _.get(initDoctorOption, 'profile.fullName') || _.get(initDoctorOption, 'fullName')
          }
          doctorOptions = dataHelper.addMissingParticipantOption(initialOption, 'id', doctorOptions, renderMissingParticipantOption);
        }

        return (
            <Item>
                {getFieldDecorator('doctor',{
                  initialValue:initDoctorOption.id ||null,
                  rules:[{required:true , message: I18N.get('provider.required.message') }]
                })
                (
                    <Select filterOption={false}>
                        {_.map(doctorOptions,(d)=>{
                            return (
                                <Option value={d.id} key={d.id}>{d.CustomLabel || d.fullName}</Option>
                            )
                        })}
                    </Select>
                )}
            </Item>
        )
    }

    // checkVisitRoles = (rule, value, callback) => {
    //     const {visitType} = this.props;
    //     if(visitType !== 'TECH' && value.length === 1 && value[0] ==='MD'){
    //         callback("You can't schedule a visit only for MD.");
    //     }
    //     callback();
    // }

    getInitialAssignee = (type) => {
      const { editStatus, patient, visitInfo } = this.props;
      const assignees  = _.get(visitInfo,'assignees');
      const isMNTFlag = this.isMNT();

      const assignedTo = _.get(patient,`user.assignees.${type}`) || {};
      let initialValue = {};
      if (_.get(assignees, type)) {
        initialValue = {
          id: _.get(assignees, `${type}.providerId`),
          fullName: _.get(assignees, `${type}.name`),
        };
      } else if (!isMNTFlag && !editStatus) {
        initialValue = {
          id: assignedTo.id,
          fullName: _.get(assignedTo, 'profile.fullName'),
        };
      }
      return initialValue;
    }

    renderAssignees = (type,role)=>{
        const {onAssigneeSearch,assigneesAvailability} = this.props;
        const assigneesAvailabilityMap = {};
        _.forEach(assigneesAvailability,(s=> _.set(assigneesAvailabilityMap,s.id, s.overlapVisits.length!=0)));
        const { getFieldDecorator } = this.props.form;
        const searchKey = _.get(this.props.searchKey,`${type}`,'');
        const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const teamsFromLoginUser = loginUser.team||[];
        

        const isMNTFlag = this.isMNT();

        const placeholderMap = {
            assignedToCA: 'Assign a CA (Optional)',
            assignedToRD: `Assign a ${isMNTFlag ? 'RD' : 'RD/HC'} (Optional)`,
            // assignedToHC: 'Assign a HC (Optional)'
        }

        const initialValue = this.getInitialAssignee(type);

        let options = helper.getMembersByRole(teamsFromLoginUser,role);
        if(initialValue.id) {
          options = dataHelper.addMissingParticipantOption(initialValue, 'id', options, renderMissingParticipantOption);
        }
        _.set(this, `optionsCopy.${type}`, options);

        return  <Item>
            {getFieldDecorator(type,{
                initialValue: initialValue.id,
                rules:[{
                    validator:(rule,value,cb)=>{
                       if(dataHelper.validateAssigneesAvailable(assigneesAvailabilityMap,value)) {
                           cb('Assignee is unavailable at this time. Please re-select the time or reassign.');
                       }
                       else cb();
                    }
                }]
            })
            (
                <Select filterOption={false} allowClear={true} showSearch placeholder={placeholderMap[type]}
                        onChange={(t,option)=>this.props.changeMemberSel(type,t,option||{})}
                        // onSelect={(id,target)=>this.props.changeMemberSel(type,id,target)}
                        onSearch={ (str)=>onAssigneeSearch(type,str) }
                        onBlur={()=> onAssigneeSearch(type,'')}
                        className="assignees-select"
                >
                    {_.map(options.filter(({ fullName })=>{
                        const regex = new RegExp(searchKey, 'gi');
                        return fullName && fullName.match(regex);
                    }),({id,fullName, CustomLabel})=>{
                        return (
                            <Option key={id} data-fullname={fullName} disabled={assigneesAvailabilityMap[id]} value={id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                      { CustomLabel || <span>{fullName}</span>}
                                      <span style={{ marginLeft: 3 }}>
                                        {loginUser.id===id ? '(Assign to me)':''}
                                      </span>
                                    </div>
                                    <div style={{ color:'#aaaaaa' }}>
                                        {assigneesAvailabilityMap[id] ? 'Unavailable':''}
                                    </div>
                                </div>
                            </Option>
                        )
                    })}
                </Select>
            )}
        </Item>
    }

    handleCheckbox = (checked) => {
        // const { participantRoles } = this.state;
        // if (participantRoles.length > checked.length) {
        //     const removedRole = _.difference(participantRoles, checked)[0];
        //     const type = assigneesMap[removedRole];
        //     if (type) {
        //         this.props.changeMemberSel(type, undefined, {});
        //     }
        // }

        // const lastChecked = _.last(checked);
        // if(_.includes(Object.keys(EXCLUSIVE_ROLES), lastChecked)){
        //     const removedRole = EXCLUSIVE_ROLES[lastChecked];
        //     _.pull(checked, removedRole);
        //     const type = assigneesMap[removedRole];
        //     if (type) {
        //         this.props.changeMemberSel(type, undefined, {});
        //     }
        // } 
        this.setState({ updatedCheckboxes: true, participantRoles: checked });
    }

    handleSingleCheckboxChange = (isChecked, role) => {
      const { changeMemberSel } = this.props;
      const type = assigneesMap[role];
      if (!type) return;
      if (isChecked) {
        const initialValue = this.getInitialAssignee(type);
        changeMemberSel(type, initialValue.id, {
          props: { 'data-fullname': initialValue.fullName },
        });
      } else {
        changeMemberSel(type, undefined, {});
      }
    }

    getInitialVisitRoles = () => {
        const { visitType, visitInfo, patientComplexity} = this.props;
        return _.get(visitInfo, 'visitRoles') || getVisitRoles(visitType, this.getInitialCategory(),patientComplexity);
    }

    isMNT = ()=>{
        const { visitType } = this.props;
        return  visitType=='MNT';
    }

    renderParticipants = () => {
        const { form: { getFieldDecorator, getFieldValue } } = this.props;
        const { renderAssignees, changeMemberSel } = this;
        const formVisitRoles = getFieldValue('visitRoles') || this.getInitialVisitRoles();
        const isMNTFlag = this.isMNT();
        let rolesMap = {
            assignedToCA:['Medical Assistant'],
            assignedToRD: isMNTFlag ? ['PROVIDER:Nurse','Nurse']: ['PROVIDER:Nurse','Nurse','Health Coach', 'PROVIDER:Health Coach'],
            // assignedToHC: ['Health Coach', 'PROVIDER:Health Coach']
        }

        return (
          <Item label={I18N.get('visit.details.visitRoles.required.label')} >
              {getFieldDecorator('visitRoles', {
                  initialValue: this.getInitialVisitRoles(),
                  rules: [
                      { required: true, message: I18N.get('visit.details.visitRoles.required.message'), type: 'array' },
                      // { validator: (rule, value, callback) => this.checkVisitRoles(rule, value, callback)}
                  ],
              })(
                  <Checkbox.Group style={{width: '100%'}} onChange={this.handleCheckbox}>
                        {
                            _.map(_.keys(I18N.get('teamRoles')), (role, key) => {
                                let roleLabelMap = {
                                    MD:'Provider',
                                    RD: isMNTFlag ? 'RD':'RD/HC',
                                }
                                return (
                                    <div className='visit-roles-checkbox-item' style={{ display: 'flex', alignItems: 'center' }} key={key}>
                                        <Col span={8} className='visit-role-checkbox'>
                                            <Checkbox 
                                              key={key} 
                                              value={role} 
                                              onChange={(e) => this.handleSingleCheckboxChange(e.target.checked, role)}
                                            >
                                              {roleLabelMap[role] || role}
                                            </Checkbox>
                                        </Col>
                                        <Col span={14}>
                                            {
                                                (role === 'MD' && formVisitRoles.includes('MD')) 
                                                  ? this.renderProvider() 
                                                : (role === 'CA' && formVisitRoles.includes('CA'))
                                                  ? renderAssignees('assignedToCA',rolesMap['assignedToCA']) 
                                                : (role === 'RD' && _.intersection(formVisitRoles, ['RD', 'HC']).length > 0)
                                                  ? renderAssignees('assignedToRD',rolesMap['assignedToRD']) 
                                                : null
                                                /* role === 'HC' && formVisitRoles.includes('HC') ? renderAssignees('assignedToHC',rolesMap['assignedToHC']) : */
                                            }
                                        </Col>
                                    </div>
                                )
                            })
                        }
                  </Checkbox.Group>
              )}
          </Item>
        )
    }

    render() {
      return (
        <Row>
          <Col span={24}>
            {this.renderParticipants()}
          </Col>
        </Row>
      );
    }
}

export default VisitInputProviders;
