import { IHPanel, React, IHButton, IHLoading, IHBaseForm } from 'ihcomponent';
import I18N from '../../../I18N';
import { pwValidatorNoErrorMsgs, passwordConfirmValidator, tooShortPassword, requireDigitsInPassword,
  requireLowercaseInPassword, requireUppercaseInPassword, reqSpecCharsInPassword } from '../../../../lib/validator';
import { submittingStyle, goPath } from 'libModule/utils'
import MainModal from 'layoutModule/containers/MainModalContainer';

const TITLES = I18N.get('auth.password');

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.showHidePw = this.showHidePw.bind(this);
    this.handleShowHideClick = this.handleShowHideClick.bind(this);
    this.state = {
      isPasswordVisible: false,
      inputField:'',  // used for valid rules coloring
      fields: {       // will be passed to and updated by the login form
        newPass: {
          value: '',
        },
        newPassCheck:{
          value:''
        }
      },
      isSubmitting: false
    }
  }
  //call back function when form fields are changed
  handleFormChange = (changedFields) => {

    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
      inputField:fields.newPass.value
    }));
  }
  handleShowHideClick(e) {
    e.preventDefault();

    this.setState((prevState, props) => {
      return {
        isPasswordVisible: !prevState.isPasswordVisible
      }
    });
  }
  showHidePw() {
    return this.state.isPasswordVisible ? <img src='/image/eye_on_icon.svg' onClick={ e => this.handleShowHideClick(e) } className='change-password-eye-off' />
      : <img src='/image/eye-off.svg' onClick={ e => this.handleShowHideClick(e) } className='change-password-eye-off' />
  }
  render() {
    const {
      selectedUser,
      submit
      // isSubmitting,
    } = this.props;
    const { isSubmitting } = this.state;

    const validAndSubmit = (e, { validateFieldsAndScroll }) => {
      e.preventDefault();

      validateFieldsAndScroll((err, values) => {
        if (err) return;
        this.setState({ isSubmitting: true});
        submit(values)
          .finally(() => {
            this.setState({ isSubmitting: false});
          });
      });
    };

    const formProp = {
      onSubmit: validAndSubmit,
      horizontal: true,
      ref: 'form',
      itemList: [
        {
          key: 'newPass',
          label: `${TITLES.createPw} *`,
          type: this.state.isPasswordVisible ? 'text' : 'password',
          suffix: this.showHidePw(),
          rules: [
            { validator: (rule, value, callback) => {
                //this.setState({ inputField: value });
                const pwdConfirm = this.refs.form.getValue().newPassCheck;
                if (pwdConfirm) {
                  this.refs.form._form.props.form.validateFields(['newPassCheck']);
                }
                return callback(pwValidatorNoErrorMsgs(value));
              }
            },
          ],
        },
        {
          key: 'newPassCheck',
          label: `${TITLES.confirmPw} *`,
          type: this.state.isPasswordVisible ? 'text' : 'password',
          suffix: this.showHidePw(),
          rules: [
            { validator: (rule, value, callback) => {
              const pwd = this.refs.form.getValue().newPass;
              return callback(passwordConfirmValidator(value, pwd));
            },
            },
          ],
        },
      ],
    };

    const onCancel = e => {
      console.log('on cancel');
      e.preventDefault();
      goPath(`patients/${selectedUser.id}/enrolledprogram/default`);
    }

    const bodyElem = (
      <div className='change-password-container'>
        <div className='change-password-full-name'>
          { selectedUser ? selectedUser.profile.fullName : '' }
        </div>
        <hr />
        <div className='change-password-username'>
          Username: { selectedUser ? selectedUser.username : null }
        </div>
        <div className='change-password-email'>
          { selectedUser && selectedUser.email.length > 0 ? selectedUser.email[0].address : null }
        </div>
        {/*use onFieldsChange & mapPropsToFields to pass data in/out the form. this.state.field is passed in as the props to control the form value */}
        <IHBaseForm {...formProp} {...this.state.fields}
                    onFieldsChange={(props, changedFields)=>{props.onChange(changedFields);}}
                    mapPropsToFields={(props)=>{
                      return {newPass: props.newPass, newPassCheck:props.newPassCheck};}}
                    onChange={this.handleFormChange}>

          <div className='change-password-rules'>
            <ul>
              <li className={ tooShortPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule1 }</li>
              <li className={ requireDigitsInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule2 }</li>
              <li className={ requireLowercaseInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule3 }</li>
              <li className={ requireUppercaseInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule4 }</li>
              {/*<li className={ reqSpecCharsInPassword(this.state.inputField, TITLES.errorClass, TITLES.validClass) }>{ TITLES.rule5 }</li>*/}
            </ul>
          </div>
          <div className="buttons-wrap">
            {/*<IHButton className='change-password-cancel-btn' bsStyle="default" size='large' onClick={ onCancel }>*/}
              {/*{ I18N.get('auth.resetPassword.cancel') }*/}
            {/*</IHButton>*/}
            <IHButton className='change-password-submit-btn' bsStyle="primary" disabled={isSubmitting} type="submit" size="large" style={submittingStyle(isSubmitting)}>
              {this.state.isSubmitting ? <IHLoading /> : I18N.get('auth.resetPassword.submit') }
            </IHButton>
              <IHButton className='change-password-cancel-btn' bsStyle="default" size='large' onClick={ onCancel }>
                  { I18N.get('auth.resetPassword.cancel') }
              </IHButton>
          </div>
        </IHBaseForm>
      </div>
    );

    // const headerElem = (
    //   <div>
    //     <h3>{TITLES.title}</h3>
    //     <p>{TITLES.description}</p>
    //   </div>
    // );

    const panelProps = {
      // header: headerElem,
      body: bodyElem
    };

    return (
      <div className="vsm-auth-box vsm-new-box auth-reset-password">
        <MainModal />
        <div className='change-password-title'>{ TITLES.createPw }</div>
        <IHPanel {...panelProps} />
      </div>
    );
  }
}
