import {
    React, BaseComponent, ND
} from './util'

export default class extends BaseComponent{

    defineBaseClassName(){
        return 'IH-Checkbox'
    }

    render(){
        const p = this.getBaseProps()

        return <ND.Checkbox {...p}>{p.children}</ND.Checkbox>
    }
}
