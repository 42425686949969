import React from 'react';
import { Row,Col } from 'antd'
import '../style/index.css';
import visitListMini from 'modulesAll/graphql/visitListMini';
import { compose, graphql } from 'react-apollo';
import userGQL from '../../../graphql/user'
import moment from 'moment';
import Parser from 'html-react-parser';
import I18N from 'modulesAll/I18N';
import { VISIT_COUNT_MAX } from '../../../../lib/constants';

const typeMap = {
    'INIT': 'Initial visit',
    'FOLLOW_UP': 'Follow-up visit',
    'ADDITIONAL': 'Additional visit',
    'CLINIC_FOLLOW_UP': 'General visit',
    'TECH': 'Tech visit',
    'Null': 'Other'
}

const carePlanFieldsMap = {
                            'problemList': { title:'Problem List',key:'user.profile.billableHealthConditions'},//'user.profile.healthConditions'},//
                            'careCoord': { title:'Care Coordination',key:'careCoordination'},
                            'followUp': { title:'Follow Up', key:'followupSchedule' },
                            'patientDecline': { title: 'patientDecline', key: 'patientDecline'},
                            'assess': { title:'Assessment',key:'assessments'},
                            'interventions':{ title:'Interventions',key:'interventions'},
                            'goals': { title:'Goals', key:'goals'},
                            'resource': { title:'Other Resources',key:'resource' }
                          };

const CarePlanPrint = class extends React.Component {
    static displayName = 'careplanPrint';
    constructor(){
        super();
    }

    renderCCMEnrollmentDate() {
        const programCategories = _.get(this, 'props.user.profile.programCategories', {});
        // SC-3701 Thong
        // strength the condition to determine if CCM is enrolled
        // also prevent crash happened randomly
        const CCMEnrollment = _.filter(programCategories, { name: 'CCM', enrolled: true })[0];
        let enDate = 'Not Enrolled';
        if(CCMEnrollment) {
            enDate = CCMEnrollment.enrolledDate
            enDate = moment(enDate).format('MM/DD/YYYY')
        }
        return <Col>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>CCM Enrollment Date: </strong>{enDate}</b><br/>
               </Col>
    }

    renderRPMEnrollmentDate() {
        const programCategories = _.get(this, 'props.user.profile.programCategories', {});
        // SC-3701 Thong
        // strength the condition to determine if RPM is enrolled
        // also prevent crash happened randomly
        const RPMEnrollment = _.filter(programCategories, { name: 'RPM', enrolled: true })[0];
        let enDate = 'Not Enrolled';
        if(RPMEnrollment) {
          enDate = RPMEnrollment.enrolledDate
          enDate = moment(enDate).format('MM/DD/YYYY')
        }
        return <Col>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>RPM Enrollment Date: </strong>{enDate}</b><br/>
               </Col>
    }

    renderLastVisitInfo(){
        const  visitList  = _.get(this.props,'visitList',[]);
        const  latestVisitWithCheckoutAt =  _.first(_.orderBy(_.filter(visitList,(v)=>v.checkoutAt),['checkoutAt'],['desc']));
        const  hasLastVisit = _.get(latestVisitWithCheckoutAt,'checkoutAt');
        const  lastVisitTime = hasLastVisit ?  moment( hasLastVisit).format('MM/DD/YYYY') :'';

        return <Col>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>Last Visit Date:</strong>{lastVisitTime}</b><br/>
               </Col>
    }

    renderCareTeam() {
        const team = _.get(this.props, 'team.0.members');
        return <Col style={{ marginTop: 20 }}>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>Care Team:</strong><br/></b>
                 {  _.map(team,(t,i)=> <b style={{marginLeft:'10%'}} key={i}>{_.get(t,'user.profile.fullName')}<br/></b>) }
               </Col>
    }

    renderPatientInfo(){

        const identification = _.get(this,'props.user.identification',[{}]);
        const profile = _.get(this,'props.user.profile',{});
        const { fullName,birthday } = profile;
        const mr = _.get(identification,'0.value');

        return <Col>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>Patient:</strong>{fullName}</b><br/>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>DOB:</strong>{birthday}</b><br/>
                 <b><strong style={{ fontWeight:800,marginRight:10 }}>MR#:</strong>{mr}</b><br/>
               </Col>
    }

    renderCarePlanInfo(){
        const list = Object.keys(carePlanFieldsMap);
        const { props } = this;
        const { visitList } = props;
        const renderMethod= (str)=>{
            if(_.isArray(str)) return _.map(str,(s,i)=><b key={i}>{Parser((s.description+'::'+s.code).replace(/\n/g, '<br/>'))}<br/></b>);
            return <b>{Parser(str.replace(/\n/g, '<br/>'))}</b>;
        }
        return _.map(list,(l,i)=> {
            if(l !== 'patientDecline'){
                const htmpString = _.get(props,`${carePlanFieldsMap[l].key}`)||I18N.get(`Program.newCarePlan.${l}`);
                return  <Col style={{ marginTop:20,/*pageBreakInside:'avoid'*/ }} key={i} >
                        <b><strong style={{ fontWeight:800,marginRight:10 }}>{_.get(carePlanFieldsMap,`${l}.title`)}:</strong></b>
                        <div style={{marginLeft:'10%'}}>
                            { renderMethod(htmpString) }
                        </div>
                    </Col>
            } else {
                // add patient's decline reason in the print version of care plan
                const declineHistory = [];
                _.forEach(visitList, visit => {
                    const {declineToCheckVital, unableToCheckVital, type, appointmentAt} = visit;
                    let displayString = '';

                    if(declineToCheckVital) displayString += 'Patient verbally declines to check one or more vitals. ';
                    if(unableToCheckVital) displayString += 'Patient is unable to check one or more vitals. ';
                    if(displayString.length > 0) {
                        declineHistory.push(<div>{`(${typeMap[type]} at ${moment(appointmentAt).format('MM/DD/YYYY, h:mm A')}, ${displayString} )`}</div>)
                    }
                })
                return declineHistory.length === 0 ? '': <Col style={{marginTop: 20}}>{declineHistory}</Col>
            }
            })
    }

    render(){
        const programCategories = _.get(this, 'props.user.profile.programCategories', {});

        return <Row className='print' style={{ margin:'10%', marginTop: '5%' }}>
                    <div style={{textAlign:'center'}}><h4>CCM/RPM Comprehensive Care Plan</h4></div>
                    { this.renderPatientInfo() }
                    { this.renderCCMEnrollmentDate() }
                    { this.renderRPMEnrollmentDate() }
                    { this.renderLastVisitInfo() }
                    { this.renderCareTeam() }
                    { this.renderCarePlanInfo() }
               </Row>
    }
}

const visitListData = graphql(visitListMini,{options: (ownProps) => {

        const memberId = _.get(ownProps,'user.id');
        const  variables = {
            page: 1,
            count: VISIT_COUNT_MAX,
            memberId,
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList } = data;
        if(loading){
            return {
                loading
            }
        }
        if(visitList&&visitList.data) {
            return {
                visitList:visitList.data,
                loading
            }
        }
        return {
            loading
        }
    }
});

const userData = graphql(userGQL, {
    options : (ownProps)=> {
        const id = _.get(ownProps,'user.id');
        return {
            variables: {
                id
            },
        }
    },
    props:({ownProps,data})=> {
        const {loading, user} = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            user
        }
    }
});

export default compose(visitListData,userData)(CarePlanPrint);
