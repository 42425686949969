import React from 'react';
import { IHButton, message, ND } from 'ihcomponent';
import API from '../../API';
import {goPath} from "../../../../lib/utils";
const TerminateClinicComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            submitting: false,
            canSubmit: false
        }

    }

    onChange=(e)=>{
        let { value = '' } = e.target;
        this.setState({
            canSubmit: value === 'TERMINATE',
            value,
        });
    }

    onSubmit = ({id})=>{
        const { setShowTerminateClinicModal } = this.props;
        this.setState({
            submitting: true
        },()=>{
            if (this.state.canSubmit) {
                API.terminateClinic({id})
                    .then(res=> {
                        message.success('Clinic terminated');
                        goPath('/superadmin/areas');
                    })
                    .catch(error=>message.error(error.message))
                    .finally(setShowTerminateClinicModal(false));
            }
        })

    }

    render() {
        const { state,props,onSubmit,onChange } = this;
        const { name,id,setShowTerminateClinicModal,internalName } = props;
        const { submitting,canSubmit,value } = state;
        return (
            <div className="discharge-modal-body">
                <p>This is an irreversible process.
                    When clinic is terminated, all patients in this clinic will be discharged and any devices linked to patients will be unassigned.
                </p>
                <p>To continue and terminate {internalName} , please type “TERMINATE” below:</p>
                <div style={{paddingBottom:20}}>
                    <span className="IH-FormItem">
                      <span className="ant-form-item-control">
                        <ND.Input onChange={onChange}/>
                      </span>
                    </span>
                </div>
                <div className="discharge-buttons">
                    {submitting
                        ? <IHButton bsStyle="primary" label="Complete Termination" disabled loading={submitting}/>
                        : <IHButton bsStyle="primary" label="Complete Termination" disabled={!canSubmit} onClick={()=>onSubmit({name,internalName,id})}
                        />
                    }
                    <IHButton label="Cancel" onClick={()=>setShowTerminateClinicModal(false)}/>
                </div>
            </div>
        );
    }

}

export default TerminateClinicComponent
