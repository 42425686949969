import React from 'react';
import { IHButton, _ } from 'ihcomponent'
import actions from 'modulesAll/layout/actions/MainModal'
import types from '../constants'

actions.confirm = (opts) => (dispatch) => {
  opts = _.merge({
    Yes: {},
    No: {}
  }, opts || {})

  const close = () => {
    dispatch(actions.closeModal())
  }
  const b1 = {
    key: 'v1',
    size: 'normal',
    label: opts.Yes.label || 'Confirm',
    type: 'primary',
    style: opts.Yes.style,
    onClick: () => {
      opts.Yes.handler(close)
      if (_.get(opts, 'Yes.closeModal')) { close() }
    }
  }
  const b2 = {
    key: 'v2',
    size: 'normal',
    label: opts.No.label || 'Cancel',
    type: 'default',
    style: {
      marginLeft: '10px',
      ...opts.No.style
    },
    onClick: () => {
      close()
      if (opts.No.handler) opts.No.handler()
    }
  }


  const props = {
    size: 'sm',
    //title : '<img src="image/modal-alert.png"/>',//opts.title || 'Confirm',
    showHeaderCloseButton: false,
    footer: [
      <IHButton {...b2} key={2} />,
      <IHButton {...b1} key={1} />
    ]
  }

  const content = (
    <div>
      <div>
        <img src='image/modal-alert.png' className={'icon-img'} />
      </div>
      <div className={'message'}>
        {opts.content}
      </div>
    </div>
  )

  dispatch(actions.openModal(content, props))
}

actions.alert = (opts) => (dispatch) => {
  opts = _.merge({
    Yes: {
      handler: _.noop
    }
  }, opts || {})

  const close = () => {
    dispatch(actions.closeModal())
  }
  const b1 = {
    key: 'v1',
    size: 'large',
    label: opts.Yes.label || 'Done',
    type: 'primary',
    onClick: () => {
      opts.Yes.handler()
      close()
    }
  }

  const props = {
    size: 'sm',
    title: opts.title || 'Alert',
    showHeaderCloseButton: false,
    footer: [
      <IHButton {...b1} key={1} />
    ]
  }

  dispatch(actions.openModal(opts.content || null, props))
}

actions.showPatientStarModal = (show = false, type = 'add', user) => {
  return {
    type: types.PATIENT_STAR_MODAL,
    PatientStarModal: {
      show,
      type,
      user
    }
  }
}


export default actions
