import React from 'react';
import { removeTaskFromEnrolledProgram } from 'graphqlModule/mutation/removeTaskFromEnrolledProgram';

import Client from 'libModule/gqlClient';
import { message } from 'ihcomponent'
import I18N from 'modulesAll/I18N';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import { GQLHelper } from 'libModule/utils';
import VitalsClass from '../helpers/VitalsClass';
import singleVisit from '../../graphql/singleVisit';
import { CGMHelperServices } from '../../CGM/helpers';


 const openConfirmModal=(localThis,taskId,vitalType)=>{
    const { fullName=''} = localThis.props;
    return new Promise((resolve, reject) => {
        const Yes = {
            label: 'Confirm',
            handler: ()=>{
              const { patientId } = localThis.props;
              if (vitalType === CGMHelperServices.brandFieldName) {
                resolve(CGMHelperServices.removeCGM(patientId));
                return;
              }
                resolve(handleRemoveTaskFromEnrolledProgram(localThis,taskId,vitalType));
            },
            closeModal: true
        }
        const No = {
            label: 'Cancel',
            handler: () => {
                resolve(false)
            }
        }

        const vitalTypeName = (() => {
          switch (vitalType) {
            case CGMHelperServices.brandFieldName:
              return 'CGM';
            default:
              return vitalType;
          }
        })();

        const modalProps = {
            Yes,
            No,
            title: I18N.get('vital.deleteVital.confirm.title'),
            content: I18N.get('vital.deleteVital.confirm.content')(vitalTypeName)
        };
        localThis.props.openConfirmModal(modalProps)
    })
}

 const handleRemoveTaskFromEnrolledProgram = (localThis,taskId,vitalType)=>{
    const { openErrorModal,program } = localThis.props;
    const { selectedVitals } = localThis.state;
    const enrolledProgramId = program.id;
    try{
        Client.mutate({
            mutation: removeTaskFromEnrolledProgram,
            variables: {
                id:enrolledProgramId,
                taskId:[taskId]
            },
            refetchQueries:[
                {
                    query:enrolledProgramGQL,
                    variables: {
                        id: enrolledProgramId
                    },
                }
            ]
        })
            .then(()=>{
                localThis.setSelectedVitals(_.filter(selectedVitals,t=>t!==vitalType));
                message.success('Vital deleted successfully.');
            })
            .catch(e=>openErrorModal(GQLHelper.formatError(e)));
    }catch(e){
        openErrorModal(GQLHelper.formatError(e))
    }
}

const handleAddTasks = async (enrolledProgram, vitalList, openErrorModal) => {
  const vitalsClass = new VitalsClass({ program: enrolledProgram });
  vitalsClass.handleAddVitals({ 
    vitalList,
    onError: error => {
      console.error('handleAddTasks error:' + error);
      if(!openErrorModal)
        return;
      if(error === 'invalidProgram') {
        openErrorModal(<div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontFamily: 'var(--font-family)', fontSize: 16, marginBottom: 8, color: '#555555', fontWeight: 600 }}>
            Failed to sync vitals
          </span>
          <span style={{ fontFamily: 'var(--font-family)', color: '#555555' }}>
            This patient hasn't enrolled in the program, please enroll the patient and try again
          </span>
        </div>);
      } else if (error === 'notAuthorized') {
        openErrorModal(<div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontFamily: 'var(--font-family)', fontSize: 16, marginBottom: 8, color: '#555555', fontWeight: 600 }}>
            Failed to sync vitals
          </span>
          <span style={{ fontFamily: 'var(--font-family)', color: '#555555' }}>
            This patient is currently not in your team, please check patient's team information and try again
          </span>
        </div>);
      } else {
        openErrorModal(GQLHelper.formatError(error));
      }
    }
  });
};

const onboardRemoveTaskConfirm = props => {
  const { openConfirmModal, openErrorModal, enrolledProgram, vitalType, id: visitId } = props;
  return new Promise(resolve => {
    const vitalsClass = new VitalsClass({ program: enrolledProgram });
    if(!vitalsClass.needRemoveConfirm(vitalType)) {
      return resolve(true); // auto-confirm
    }

    const Yes = {
      label: 'Confirm',
      handler: ()=>{
        vitalsClass.handleRemoveVitals({
          vitalType,
          onSuccess: res => {
            message.success(I18N.get('vital.deleteVitalInOnboard.success'));
            resolve(true);
          },
          onError: err => {
            openErrorModal(GQLHelper.formatError(err));
            resolve(true); // ignore error from removing vital
          },
          refetchQueries: [{
            query: singleVisit,
            variables: { id: visitId }
          }]
        });
      },
      closeModal: true
    }
    const No = {
      label: 'Cancel',
      handler: () => resolve(false)
    }
    const modalProps = {
      Yes,
      No,
      title: I18N.get('vital.deleteVitalInOnboard.confirm.title'),
      content: I18N.get('vital.deleteVitalInOnboard.confirm.content')
    };

    openConfirmModal(modalProps);
  });
}



export default {
     handleRemoveTaskFromEnrolledProgram,
     openConfirmModal,
     handleAddTasks,
     onboardRemoveTaskConfirm
}
