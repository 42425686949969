import defaultBGDefaultValue from "../constants/defatulBGThreshold";
import momentTZ from 'moment-timezone';
import { weekDayMap } from '../constants/timezoneMap';
import { DeviceModelMap } from '../constants/deviceModelMap';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';

const between = (value,start,end)=>{
    return  (value<=end&&value>=start);
        // ||(isNaN(end)&&value>=start);
}

const getBGReadingSeverity = (isBeforeMeal,BGValue)=>{
    if(typeof isBeforeMeal=='undefined') return;
    let rangeObj = defaultBGDefaultValue[(isBeforeMeal)+0];
    let severity = '';
    for(const [key,range] of Object.entries(rangeObj)){
       if(between(BGValue,range[0],range[1])) {
            severity =key;
           return severity&&severity.split('_')[0]
       }
    }
}

const getTimezoneForClearDevice = () => {
  const clinicTimezone = DATE_HELPERS.getClinicTimezone();
  return clinicTimezone;
};

const getMeasureTimeDisplayText = (resultData, timeFormat = 'MM/DD/YYYY ddd HH:mm') => {
  if (!resultData) return '-- --';
  const uploadedAt = resultData.date;
  const { timezone, deviceModel } = resultData.measure || {};

  let measureTimezone = timezone;
  if (deviceModel === DeviceModelMap.Clear) {
    measureTimezone = getTimezoneForClearDevice();
  }

  let measureTimeStr = '';
  if (measureTimezone) {
    measureTimeStr = momentTZ(uploadedAt).tz(measureTimezone).format(`${timeFormat} (z)`)
  } else {
    measureTimeStr = momentTZ(uploadedAt).format(timeFormat);
  }
  let weekday = measureTimeStr.substring(11,14);

  if(weekDayMap[weekday]){
    measureTimeStr =  measureTimeStr.replace(weekday, weekDayMap[weekday]);
  }

  return measureTimeStr || '-- --';
};

export default {
    getBGReadingSeverity,
    getMeasureTimeDisplayText,
}