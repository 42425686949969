import types from 'patientModule/enrolledProgramInfo/Careplan/constants/VitalFormConstants'
import VitalListTypes from 'patientModule/enrolledProgramInfo/Careplan/constants/VitalListConstants'
import moduleCommonActions from 'patientModule/enrolledProgramInfo/Careplan/actions/CommonActions'
import moduleCommonTypes from 'patientModule/enrolledProgramInfo/Careplan/constants/CommonConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
])

const goCareplanMainPage = () => {
  return moduleCommonActions.changePage(moduleCommonTypes.CAREPLAN_MAIN_PAGE)
}

const addVitalFormToList = (fieldsValue) => {
  return (dispatch, getState) => {
    dispatch({
      type: VitalListTypes.ADD_VITAL_DATA,
      fieldsValue
    })
    dispatch({
      type: types.RESET_FORM
    })
    dispatch(goCareplanMainPage())
  }
}

const changeEditMode = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_FORM
    })
  }
}

export default {
  ...commonActionsObject,
  goCareplanMainPage,
  addVitalFormToList,
  changeEditMode
}
