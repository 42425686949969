import editVisit from 'graphqlModule/mutation/editVisit';
import editCheckInCheckOut from 'graphqlModule/mutation/editCheckInCheckOut';
import createVisitQuery from 'graphqlModule/mutation/createVisit';
import deleteVisitQuery from 'graphqlModule/mutation/deleteVisit';
import updateVisitConfirmQuery from 'graphqlModule/mutation/updateVisitConfirm';
import updateVisitReviewBillableQuery from 'graphqlModule/mutation/updateVisitReviewBillable';
import visitList from 'modulesAll/graphql/visitList';
import Client from 'libModule/gqlClient';
import singleVisitQuery from 'graphqlModule/singleVisit';
import createUser from 'graphqlModule/createUser';
import * as R from "ramda";
import addA1CLabResult from "../../graphql/mutation/addA1CLabResult";

 const createVisit = (variables)=>{
                   return  Client.mutate({
                         mutation: createVisitQuery,
                         variables: variables,
                         fetchPolicy: 'no-cache'
                   })
 }

const getVisitList = (variables)=>{
     return Client.query({
                    query:visitList,
                    variables:variables,
                    fetchPolicy:'network-only'
     })
}

const getVisit = (id)=>{
     return Client.query({
         query:singleVisitQuery,
         variables:{id :id},
         fetchPolicy:'network-only'
     })
}

const deleteVisit = (id)=>{
    let variables = {
        id:id
    };

    return Client.mutate({
        mutation: deleteVisitQuery,
        variables: variables,
        fetchPolicy: 'no-cache'
    })
}
 const updateVisit = (id, type, checkedTime) =>{
                        let mutationOptions = {
                          mutation: editVisit,
                          variables: { id },
                          fetchPolicy: 'no-cache'
                        };

                        if(type=='checkIn'){
                          mutationOptions.variables.checkinAt = 
                            checkedTime ? checkedTime : new Date();
                        }

                        if(type=='checkOut') {
                          mutationOptions.variables.checkoutAt = 
                            checkedTime ? checkedTime : new Date();
                        }

                        if(checkedTime) {
                          mutationOptions.mutation = editCheckInCheckOut
                          // remove fetchPolicy to update cache
                          mutationOptions = _.omit(mutationOptions, 'fetchPolicy');
                        }

                        return Client.mutate(mutationOptions);
                      };

 const updateVisitFromVisitList = (variables)=>{
                             return   Client.mutate({
                                 mutation: editVisit,
                                 variables: variables,
                                 fetchPolicy: 'no-cache'
                             })
                         };

 const rescheduleVisit = (data)=>{
     let variables = data;
     return Client.mutate({
         mutation: editVisit,
         variables: variables,
         fetchPolicy: 'no-cache'
     })
}

const updateVisitConfirm = (id, confirm) => {
    let variables = {
        id:id,
        confirm:confirm
    }
    return Client.mutate({
        mutation: updateVisitConfirmQuery,
        variables: variables,
    })
}

const createPatient = (variables) => {
     return Client.mutate({
         mutation: createUser,
         variables,
         fetchPolicy:'no-cache'
     })
}

const uploadA1C = (memberId, A1CVariables) => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const record = {
        viewerId: R.last(R.split(':', atob(currentUser.id))),
        userId: R.last(R.split(':', atob(memberId))),
        templateId: 'hemoa1c',
        templateName: 'Hemoglobin A1c',
        dateCollected: A1CVariables.A1CDate,
        results: [{
            name: 'Hemoglobin A1c',
            unit: '%',
            value: A1CVariables.A1C,
            interval: 'Pre-diabetes: 5.7 - 6.4 Diabetes: >6.4 Glycemic control for adults with diabetes: <7.0',
        }]
    };
    const a1cVariables = {record};
    return Client.mutate({
        mutation: addA1CLabResult,
        variables: a1cVariables,
        fetchPolicy: 'no-cache'
    })
}

const updateVisitReviewBillable = variables => {
  return Client.mutate({
    mutation: updateVisitReviewBillableQuery,
    variables
  });
}

    export const API = {
     updateVisit,
     createVisit,
     deleteVisit,
     rescheduleVisit,
     getVisitList,
     updateVisitFromVisitList,
     getVisit,
     updateVisitConfirm,
     createPatient,
     uploadA1C,
     updateVisitReviewBillable
    };

