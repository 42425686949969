import { graphql } from 'react-apollo'
import setDevicesForAdminProgram from 'graphqlModule/setDevicesForAdminProgram'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHBaseFormWithRow, IHButton, message, _ } from 'ihcomponent'
import actions from 'ProgramEditModule/Device/actions/DeviceFormActions'
import types from 'ProgramEditModule/Device/constants/DeviceFormConstants'
import { getFormProps, initData } from 'programModule/Common/components/formData/DeviceForm'
import { convertToFieldsValue } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'program/ProgramEdit/Device/containers/DeviceFormContainer'

  componentWillMount() {
    const { updateForm, program: { devices } } = this.props
    const newInitData = {}
    if (Array.isArray(devices)) {
      devices.forEach(v => {newInitData[v.type] = v.model || initData[v.type]})
      updateForm(convertToFieldsValue(newInitData))
    }
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  render() {
    const title = this.renderTitle()
    const body = this.renderBody()
    const footer = this.renderFooter()
    return (
      <div className='vsm-device-form-container'>
        {title}
        {body}
        {footer}
      </div>
    )
  }

  renderTitle() {
    return <h3 className='vsm-form-title vsm-form-main-title'>Select Device models</h3>
  }

  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    if (!fieldsValue) return;
    const mapPropsToFields = props => props.fieldsValue
    const formProps = getFormProps({ fieldsValue, onFieldsChange, mapPropsToFields })
    const body = <IHBaseFormWithRow {...formProps} />
    return body
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '50%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        // disabled: !isFormValid,
        label: 'Save',
        style: {
          marginLeft: 10
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} />
      </div>
    )
    return footer
  }

  async submit() {
    const { mutate, program, fieldsValue } = this.props
    let devices = {}
    for (let field in fieldsValue) {
      devices[fieldsValue[field].name.toUpperCase()] = fieldsValue[field].value
    }
    // BP and HR use the same device
    if (devices.BP && devices.HR) devices.HR = devices.BP

    const variables = {
      id: program.id,
      devices
    }
    const auditLogDetails = {
      programName: program.name,
      devices: _.mapKeys(devices, (value, key) => {
        switch(key) {
          case 'BG':
            return 'blood glucose'
          case 'BP':
            return 'blood pressure'
          case 'HR':
            return 'heart rate'
          case 'HS':
            return 'weight'
          default:
            return key
        }
      })
    }

    try {
      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.edit.save_device'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      message.success('Device has been Saved')
    }
    catch(err) {
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.edit.save_device'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
  }
}

const ContainerWithData = graphql(setDevicesForAdminProgram)(Container)

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields, types)),
    updateForm: (fieldsValue) => dispatch(actions.updateForm(fieldsValue)),
    resetForm: () => dispatch(actions.resetForm()),
  }
}

Container.propTypes = {
  onFieldsChange: PropTypes.func,
  updateForm: PropTypes.func,
  resetForm: PropTypes.func,
  mutate: PropTypes.func,
  program: PropTypes.object,
  fieldsValue: PropTypes.object,
  isFormValid: PropTypes.bool
}

export default connect(
  null, mapDispatch
)(ContainerWithData)
