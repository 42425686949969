const admin = {
  employeeProfile:{
    hasMentorTooltip:{
      title:'How to change or delete mentee HC?',
      modal: {
        title: 'How to change or delete mentee HC?',
        body: 'To delete mentee HC, please go to the current mentee’s profile to dissociate the mentorship. If you want to change mentee HC, please also go to the intended mentee’s profile to select this RD as mentor.'
      }
    },
    noMentorTooltip: {
      title: 'How to assign a mentee HC to RD?',
      modal: {
        title: 'How to assign a mentee HC to RD??',
        body: 'To assign a mentee HC to RD, please go to the intended mentee HC’s profile and select this RD as mentor.'
      }
    }
  },
  hcCredential: {
    label: 'Does this HC have credential?',
    options: {
      YES: 1,
      NO: 0
    },
    errorMessage: 'Please select',
    note: `Note: The work finished by a HC who doesn't have credential needs to be reviewed by RD in the portal.`
  },
  mentorRD:{
    label:'Mentor RD',
    errorMessage:'Please select a Mentor'
  },
  directAddress: {
    tooltip:'A Direct address is needed to exchange health information using Direct Secure Messaging.Direct addresses look similar to an e-mail address. An example of a Direct address is b.wells@direct.aclinic.org.'
  }
};

export default admin;