import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { Document, Page, Image, Text, View } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer';
import '../style/indes.scss';
import styles from '../style/styles';

const InsuranceCardComponent = class extends React.Component{
	constructor(props) {
		super(props);
	}

	renderImages = ()=>{
		const { insuranceList } = this.props;
		return _.map(insuranceList, ({fileInfo,createdAt},index) => {
			const link = fileInfo.link;
			const uploadedAt = createdAt && moment(createdAt).format('MM/DD/YYYY');
			return <Page  style={styles.body}>
				<View>
					<Image src={link} key={index} style={{ marginBottom:20,height:500 }}/>
					<View><Text>UploadedAt:{uploadedAt}</Text></View>
				</View>
			</Page>
		});
	}
	renderModal = ()=>{
		const { preview,setPreview } = this.props;
 		if(preview) {
			return <Modal visible={preview} width={1000} style={{ top:0 }}footer={false}  onCancel={()=>setPreview(false)}
			              bodyStyle={{ height: '90vh', padding: '50px 0px 0px 0px' }} className={'pdfPreview'}>
				<PDFViewer>
					<Document>
						{this.renderImages()}
					</Document>
				</PDFViewer>
			</Modal>
		}
	}
	render() {
		const { setPreview } = this.props;
		const { insuranceList } = this.props;
		const statusText = 'View Insurance Card';

		return  <div className='consent-form-status'>
				<div className='consent-form-status-signed'>
				<div className='consent-form-status-text' style={{ marginTop:5 }}>
					{ !!insuranceList.length && <a style={{textDecoration: 'underline'}}
					            onClick={e => {
						            e.preventDefault();
						            setPreview(true);
					            }}
					>
						{statusText}
					</a>
					}
				</div>
				{ this.renderModal()}
				</div>
		</div>
	}
}
export default InsuranceCardComponent;