/**
 * Created by zizhangai on 1/10/17.
 */
import {
  React, IHButton
} from 'ihcomponent'
import {createContainer, goPath} from 'libModule/utils'
import ProgramInfo from '../containers/ProgramInfo'
import I18N from 'modulesAll/I18N'

const Component = class extends React.Component{
  static displayName = 'patientView/myPrograms/components/Summary'

  render(){
    return (
      <div className="vsm-patient-programSummary" style={{padding:'30px 20px'}}>
        {this.renderBackLink()}
        {this.renderSummary()}
      </div>
    )
  }

  renderBackLink(){
    const goBack = ()=>{
      goPath('/myprogrammes')
    }
    return (
      <div className="v-back" onClick={goBack.bind(this)}>
        <i className="fa fa-angle-left"></i>
        <span>Back to My {I18N.get('keywords.Programs')}</span>
      </div>
    )
  }

  renderSummary(){
    return (
      <div className="v-summary">
        <p className="v-tlt">{I18N.get('keywords.Program')} Summary</p>
        <div className="v-summary-main">
          <ProgramInfo />
        </div>
      </div>
    )
  }

}

const ComponentWithData = (Component)

export default createContainer(ComponentWithData, (state)=>{
  return {

  }
})
