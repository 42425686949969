import { React, _, IHSelect, IHRadio } from 'ihcomponent';

export default (props) => {
  const { options, answer, onChange, isPreview } = props;
  const value = _.get(answer, 'content');

  const OPTIONS = _.map(options, (o, i) => ({
    name: o.label,
    value: `${i}`,
  }));

  const onRadioChange = ({ target }) => {
    onChange({ content: target.value });
  };
  const onSelectChange = (selectedValue) => {
    onChange({ content: selectedValue });
  };

  return (
    <div className="single-select">
      {
        options.length >= 8
        ? <IHSelect
          option={OPTIONS}
          disabled={isPreview}
          size="large"
          value={value}
          onChange={onSelectChange}
        />
        : <IHRadio
          option={OPTIONS}
          size="large"
          type="radio"
          disabled={isPreview}
          value={value}
          onChange={onRadioChange}
        />
      }
    </div>
  );
};
