import types from '../constants'

const INITIAL_STATE = {
    showCreateComponent: false,
    note: '',
    callStickie: false,
    nonExistNotification: false
}

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.NOTE_STATUS:
          return {
            ...state,
            showCreateComponent: action.payload
          }
        case types.SET_NOTE:
          return {
            ...state,
            note: action.payload
          }
        case types.SET_CALL_STICKIE:
          return {
            ...state,
            callStickie: action.payload
          }
        case types.SET_NON_EXIST_NOTIFICATION:
          return {
            ...state,
            nonExistNotification: action.payload
          }
        default:
            return state;
    }
}
export default {
    Stickie: reducer,
};
