import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import moment from 'moment-timezone';
import { IHLoading } from 'ihcomponent';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { RESULT_COUNT_MAX, CHART_SEVERITY } from 'libModule/constants'

const BGDotChartComponent = class extends React.Component {
    groupData = (array,{mealTypes=[],beforeMeal})=>{
        array.sort((a1,a2)=>a1.date-a2.date);
        const filteredArray = _.filter(array,(a)=> (typeof beforeMeal =='boolean' ? (a.beforeMeal == beforeMeal) : true )&&(mealTypes.length==0 ? true : _.includes(mealTypes,a.mealType) ));
        const data  = [];

        filteredArray.forEach(v=>{
            const { date,blood_glucose,severity }  = v;
            const value = _.get(blood_glucose,'value',0);
            const marker =  {
                enabled:true,
                // symbol:'circle',
                halo:null,
                states: {
                    hover: {
                        lineWidth: 0,
                        halo: null,
                        animation:{
                            duration: 20
                        }
                    }
                },
                radius:4,
                fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :''),
                lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :''),

            };
            data.push({
                x:moment(date).startOf('day').valueOf(),
                y:Number(convertBG_mmolTomgdl(value)),
                severity,
                marker
            })

        })
        return data;
    }

    groupDataBySeverity = (array,{severity})=>{
        array.sort((a1,a2)=>a1.date-a2.date);
        const filteredArray = _.filter(array,(a)=>_.isArray(severity) ? _.includes(severity,a.severity) : a.severity==severity);
        const data  = [];
        filteredArray.forEach(v=>{
            const { date,blood_glucose,severity }  = v;
            const value = _.get(blood_glucose,'value',0);
            const marker =  {
                enabled:true,
                // symbol:'circle',
                halo:null,
                states: {
                    hover: {
                        lineWidth: 0,
                        halo: null,
                        animation:{
                            duration: 20
                        }
                    }
                },
                radius:4,
                fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :''),
                lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :''),

            };
            data.push({
                x:moment(date).startOf('day').valueOf(),
                y:Number(convertBG_mmolTomgdl(value)),
                severity,
                marker
            })

        })
        return data;

    }

    generateSeriesInfo = (resultList,fromDate)=>{
        const seriesMap = {

            'BEFORE_BREAKFAST':{
                mealTypes:['BREAKFAST'],
                beforeMeal:true,
                name:'Before Breakfast',
                lineWidth: 0,
                fillColor:null,
                color:'#f095bd',
                symbol:'triangle',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    symbol:'triangle',
                    fillColor: '#FFFFFF',
                    radius: 6,
                    lineWidth: 1,
                    lineColor:null

                },
                fn:this.groupData
            },
            'BEFORE_MEAL':{
                mealTypes:['BREAKFAST','LUNCH','DINNER'],
                beforeMeal:true,
                name:'Before Meal',
                lineWidth: 0,
                color:'#7bd002',
                fillColor:null,
                symbol:'circle',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    symbol:'circle',
                    fillColor: '#FFFFFF',
                    radius: 4,
                    lineWidth: 1,
                    lineColor:null
                },
                fn:this.groupData

                // type:'line'
            },
            'AFTER_MEAL':{
                mealTypes:['BREAKFAST','LUNCH','DINNER'],
                beforeMeal:false,
                name:'After Meal',
                color:'#0091ff',
                lineWidth: 0,
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                visible: false,
                marker: {
                    enabled: true,
                    radius: 4
                },
                fn:this.groupData
            },
            'OVERNIGHT_BEDTIME':{
                mealTypes:['OVERNIGHT','BEDTIME'],
                beforeMeal:undefined,
                name:'Overnight/Bedtime',
                lineWidth: 0,
                color:'#6236FF',
                symbol:'square',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                visible: false,
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'square'
                },
                fn:this.groupData

                // type:'line'
            },
            'CRITICAL':{
                mealTypes:[],
                beforeMeal:undefined,
                name:'Critical',
                severity:['CRITICAL'],
                lineWidth: 0,
                lineColor:'#EE6161',
                color:'#EE6161',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'square'
                },
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },
                tooltip:{
                    pointFormatter:function(){
                        const { y,severity } = this;
                        return `<br>
                             Severity:<b>${_.upperFirst(severity.toLowerCase())}</b><br/>
                             BG: <b>${y}</b><br>`
                    }
                },
                fn:()=>[]
            },
            'RISK':{
                mealTypes:[],
                beforeMeal:undefined,
                name:'Risk',
                severity:['RISK'],
                lineWidth: 0,
                lineColor:'#FFBD03',
                color:'#FFBD03',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'square'
                },
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },
                tooltip:{
                    pointFormatter:function(){
                        const { y,severity } = this;
                        return `<br>
                             Severity:<b>${_.upperFirst(severity.toLowerCase())}</b><br/>
                             BG: <b>${y}</b><br>`
                    }
                },
                fn:()=>[]
            },

            // 'RISK':{
            //     mealTypes:[],
            //     beforeMeal:undefined,
            //     name:'RISK',
            //     severity:'RISK',
            //     lineWidth: 0,
            //     states: {
            //         hover: {
            //             lineWidthPlus: 0
            //         }
            //     },
            //     marker: {
            //         enabled: true,
            //         radius: 4
            //     },
            //     fn:this.groupDataBySeverity
            // }
        }
        const seriesConfig = [];
        for (let [k, v] of Object.entries(seriesMap)) {
            const { mealTypes,beforeMeal,type,name,lineWidth,states,marker,data,fn,severity,visible,lineColor,color,tooltip,events={},symbol } = v;
            seriesConfig.push({
                type,
                name,
                lineWidth,
                states,
                marker,
                findNearestPointBy:'xy',
                data:fn(resultList,{mealTypes,beforeMeal,severity}),
                visible,
                lineColor,
                color,
                tooltip,
                events,
                symbol

                // tooltip:{
                //     headerFormat:`${moment(point.x).format('MM/DD/YYYY')}`
                // }
            });
        }
        return seriesConfig;
    }

    renderBGChart = ()=>{
        const { props,loading } = this;
        const { patientId, chartWidth, xAxisLabelPerDay } = props;
        if(loading){
            return <div>loading placeholder</div>
        }
        const { resultList,fromDate,toDate } = props;
        const seriesConfig = this.generateSeriesInfo(resultList,fromDate);
        const options =  {
            chart: {
                type: 'line',
                width: chartWidth
            },
            title:null,
            plotBackgroundColor:'#0099FF',
            xAxis: {
                type: 'datetime',
                tickWidth:0,
                tickInterval: 24 * 3600 * 1000 * xAxisLabelPerDay,
                min:fromDate.valueOf(),
                max:toDate.valueOf(),
                labels:{
                    formatter:(value)=>{
                        const xValue = value.value;
                        return moment(xValue).format('MM/DD/YYYY');
                    }
                }
            },
            yAxis:{
                title: {
                    text: 'mg/dL'
                },
                min:0,
                max:600,
                gridLineColor: 'transparent',
                plotBands: [{
                    from: 60,
                    to: 180,
                    color: 'rgb(119,119,119,0.05)',
                }]
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            tooltip: {
                crosshairs: true,
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            const { name } = this;
                            Mixpanel.track('clicked',name+'_BG',null,{ PATIENT_ID:patientId })
                        }
                    }
                }
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            credits: {
                enabled: false
            },
            series:seriesConfig
        }
        return <HighchartsReact
          highcharts={Highcharts} options={options}
          callback={this.props.chartCallback}
        />

    }
    render(){
        const { props } = this;
        const { loading } = props;
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};
        return <div style={style}>
            { loading? <IHLoading/> : this.renderBGChart() }
        </div>
    }
}
const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate } = ownProps;

        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.enrolledProgramId,
                    "type": "BG",
                    fromDate,
                    toDate,
                    severity: CHART_SEVERITY
                },
                "count": RESULT_COUNT_MAX, // retrieve all at once; intended behavior
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            resultList: _.get(resultList,'data',[]),
            loading,
        }
    }
})

export default compose(withResData)(BGDotChartComponent);
