import { graphql } from 'react-apollo';
import { createClaimedTimeLogMutation,subscriptionListQuery } from '../query/index'
import Client from 'libModule/gqlClient';
import { getPatientComplexity as getPatientComplexityQuery } from 'modulesAll/graphql/patientComplexity';
import updatePatientComplexityMutation from 'modulesAll/graphql/mutation/updatePatientComplexity';
import { getPatientComplexityByCodes as getPatientComplexityByCodesQuery, getPatientComplexityByConditions as getPatientComplexityByConditionsQuery  } from 'modulesAll/graphql/patientComplexity';
import chatHelper from '../../../chat/helper';


export const createClaimedTimeLog = (variables)=> Client.mutate({mutation:createClaimedTimeLogMutation,variables});

export const updatePatientComplexity = (variables) => Client.mutate({ 
  mutation: updatePatientComplexityMutation, 
  variables,
  update: (store, { data }) => {
    const patientComplexity = _.get(data, 'updatePatientComplexity') || {};
    const { memberId } = patientComplexity;
    chatHelper.updateUserMap(memberId, { patientComplexity }, true);
  }
});
export const getPatientComplexityByConditions = (variables) => Client.query({ query: getPatientComplexityByConditionsQuery, variables });
export const getPatientComplexityByCodes = (variables) => Client.query({ query: getPatientComplexityByCodesQuery, variables });
export const getPatientComplexity = (variables) => Client.query({ query: getPatientComplexityQuery, variables })
  .then(({ data }) => _.get(data, 'getPatientComplexity'));

export const withComplexityWithMemberFunc = (getMemberIdFunc) => graphql(getPatientComplexityQuery, {
  options: (ownProps) => {
    const memberId = typeof getMemberIdFunc === 'function' ? getMemberIdFunc(ownProps) : ownProps.patientId;
    return {
      variables: {
        memberId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => ({ 
    patientComplexity: _.get(data, 'getPatientComplexity'), 
    patientComplexityLoading: data.loading,
    patientComplexityRefetch: data.refetch,
  })
});

export const withComplexity = withComplexityWithMemberFunc();
export const getSubscriptionList = (variables)=> Client.query({ query: subscriptionListQuery,variables })