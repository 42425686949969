import React from 'react';
import { Col, Row } from 'antd';

const genSVGImage = (src, style) => ({ 
  src, 
  style: { width: 16, marginLeft: -2, ...style } 
});
const orgCount = [
  { key:'areaCount',
    title:'Areas', 
    img: genSVGImage('/image/icon_area.svg')
  },
  { key:'clinicCount',
    title:'Clinics', 
    img: genSVGImage('/image/icon_clinic.svg')
  }
];
const headerRoleArray = [
  {key:'MD',title:'Providers', img: genSVGImage('/image/icon_provider.svg')},
  {key:'MA',title:'MAs'},
  {key:'RD',title:'RDs'},
  {key:'HC',title:'HCs'},
  {key:'CA',title:'CAs'},
];
const DEFAULT_IMG = {
  src: '/image/combined-shape.png',
  style: { width: 13, height: 13, opacity: 0.8 }
};
const OrgSummaryHeaderComponent = class extends React.Component{
  renderCounter = (img, value, title) => {
    const { src, style } = img || DEFAULT_IMG;
    return <Row>
      <Col span={3}>
        <img src={src} style={{ objectFit: 'contain', ...style }}/>
      </Col>
      <Col span={5} className='value'>
        {value}
      </Col>
      <Col span={5} className='title'>
        {title}
      </Col>
    </Row>
  };

    renderOrgMember(orgSummary={}) {
        const { roleCounts=[] } = orgSummary;
        let roleToCountMap = {};
        _.map(roleCounts,({roleType,count})=>{
            _.set(roleToCountMap,roleType,count);
        })
        return (
            <div className={'wrapper'}>
                {
                    _.map(headerRoleArray, ({key,title, img}) => {
                      return <div key={key} className={'roleCountCell'}>
                        { this.renderCounter(img, `${roleToCountMap[key] || '0'}`, title) }
                      </div>
                    })
                }
            </div>
        )
    }
    renderSubOrg=(orgSummary={})=>{
        return <div className={'wrapper'}>
            {
                _.map(orgCount, ({key,title, img}) =>{
                    return <div key={key} className={'roleCountCell'}>
                      { this.renderCounter(img, `${orgSummary[key]}`, title) }
                    </div>
                })
            }
        </div>
    }
    render(){
        const { orgSummary ,className, leaf } = this.props;
        return <div className={className}>
            { leaf ? '' : this.renderSubOrg(orgSummary) }
            { this.renderOrgMember(orgSummary) }
        </div>
    }
}

export default OrgSummaryHeaderComponent