import type from '../constants/SearchPatientResultModal'
const initial = {
  list : [],
  show : false,
  gql_variable : null
}

export default (state=initial, action)=>{
  if(action.type === type.SET_LIST){
    return {
      ...state,
      show : true,
      list : action.list
    }
  }

  if(action.type === type.CHANGE_MODAL){
    return {
      ...state,
      show : action.show
    }
  }

  if(action.type === type.SET_GQL_VARIABLES){
    return {
      ...state,
      gql_variable : action.value
    }
  }

  return state
}
