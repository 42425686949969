const language = [
                    {key: 'en', value: 'English', volume:true },
                    {key: "fr", value: "French",volume:true},
                    {key: 'zh', value: 'Chinese (Simplified)'},
                    {key: "nl", value: "Dutch"},
                    {key: "de", value: "German"},
                    {key: "el", value: "Greek"},
                    {key: "it", value: "Italian"},
                    {key: "pt", value: "Portuguese"},
                    {key: 'es', value: 'Spanish'},

];

const languageConfig = {
    en:{
        volume: true
    },
    fr:{
        volume: true
    }
}
export {
    language,
    languageConfig
}

