import get from 'lodash/get'
import I18N from 'modulesAll/I18N'

export const schema = {
  repeat: {
    initialValue: 'SCHEDULED',
    required: true
  },
  calendar: {
    initialValue: [], //VET-230 remove default value
    required: true
  },
}

export const itemLayout = {
  labelCol : {span : 24},
  wrapperCol : {span : 24}
}

export const getItemList = ({ onFieldsChange, fieldsValue, title='Assessment' }) => {
  const repeat = get(fieldsValue, 'repeat.value')
  const calendar = get(fieldsValue, 'calendar.value')
  const repeatTitle = I18N.get(`keywords.${title}`)
  const itemList = [
    [
      {
        key: 'scheduleDetails',
        label: 'Schedule Details',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'repeat',
        label: `Select Patient ${repeatTitle}-taking Schedule`,
        type: 'radioButton',
        ...itemLayout,
        initialValue: repeat,
        col: { span: 20, offset: 0 },
        className: 'single-checkbox-border',
        option: [
          { name: 'Weekly', value: 'SCHEDULED'},
          // { name: 'Programme Time-points', value: 'Programme Time-points', disabled: true  }
        ]
      }
    ],
    [
      {
        key: 'calendar',
        label: '',
        type: 'checkboxButtonGroup',
        checkType: 'checkbox',
        ...itemLayout,
        initialValue: calendar,
        col: { span: 20, offset: 4 },
        option: [
          { name: 'S', value: 'SUNDAY' },
          { name: 'M', value: 'MONDAY' },
          { name: 'T', value: 'TUESDAY' },
          { name: 'W', value: 'WEDNESDAY' },
          { name: 'TH', value: 'THURSDAY' },
          { name: 'F', value: 'FRIDAY' },
          { name: 'S', value: 'SATURDAY' },
        ],
        onFieldsChange
      }
    ],
  ]
  return itemList
}
