import { message } from 'antd';

const defaultMsgConfigs = {
  getContainer: () => document.body,
  top: 80,
  duration: 2
};

// containerSelector: Recommend using unique id for specified container
const showMessageOnContainer = (props) => {
  const { type, content, configs = {}, containerSelector } = props;
  if(!message[type])
    return console.error('showMessageOnContainer error: message type is invalid');

  let style;

  // to make it appear on top of specified if containerSelector specified
  const messageConfigs = {
    ...defaultMsgConfigs,
    ...configs,
  };

  if(containerSelector) {
    messageConfigs.getContainer = () => document.querySelector(containerSelector);
    style = document.createElement('style');
    style.innerHTML = `
      ${containerSelector} .ant-message {
        position: absolute !important;
      }
    `;
    document.head.appendChild(style);
  }

  message.config(messageConfigs);

  return message[type]({
    content,
    // reset to default, check package/IHComponent/IHComponents/util.js
    onClose: () => { 
      try {
        document.head.removeChild(style);
      } catch (err) {
        console.error(err);
      } finally {
        message.config(defaultMsgConfigs);
      }
    }
  })
}

export default showMessageOnContainer;