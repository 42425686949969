import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Tooltip, Icon, Popconfirm, Form } from 'antd';
import Client from 'libModule/gqlClient';
import getMonthlyReviewGoalMeetingQuery from '../../graphql/getMonthlyReviewGoalMeeting';
import { GQLHelper } from '../../../lib/utils';
import { CONDITION_ENUM_MAP, CLINIC_GOAL_ENUM } from '../../careplanNew/component/helper/goalHelper';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import { rolesViewAssessmentLink } from '../../utils/constants/role';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from 'modulesAll/I18N';
import _ from 'lodash';

const IMG_PATH = '/image/workstation';
const goalI18N = I18N.get('workStation.goalAssessment');
const whyTextTip = goalI18N.whyTextTip;

const STATUS_ENUM = _.mapKeys(['MEETING', 'NOT_MEETING_ABOVE', 'NOT_MEETING_BELOW', 'UNABLE_TO_CONCLUDE','NOT_MEETING']);

const statusList = [
  {
    label: 'Meeting',
    value: STATUS_ENUM.MEETING,
    icon: '/assessment-meeting-status.svg'
  },
  {
    label: 'Not Meeting',
    value: STATUS_ENUM.NOT_MEETING,
    icon: '/assessment-not-meeting-status.svg'

  },
  // {
  //   label: 'Not meeting (above)',
  //   value: STATUS_ENUM.NOT_MEETING_ABOVE,
  //   icon: '/assessment-not-meeting-status.svg'
  // },
  // {
  //   label: 'Not meeting (below)',
  //   value: STATUS_ENUM.NOT_MEETING_BELOW,
  //   icon: '/assessment-not-meeting-status.svg'
  // },
  {
    label: 'Unable to conclude',
    value: STATUS_ENUM.UNABLE_TO_CONCLUDE,
    icon: '/assessment-unable-to-conclude-status.svg'
  }
];

const GoalAssessmentComponent = props => {
  const { 
    user, form, measurementCounts, isMRE,
    associatedVitals, hasAssociatedVitals,
    condition, goalType, 
    initialGoalValue, goalOptions,
    initialStatusValue, isSystemStatus,
    billable, renderVitalsSummaryPopup, handleRemoveManualGoal,
    openErrorModal, goalAssessment, currentProgram, missingGoal
  } = props;

  const initialRender = useRef(true);
  const [goal, setGoal] = useState(initialGoalValue);
  const [status, setStatus] = useState(initialStatusValue);
  const [showLightningIcon, setShowLightningIcon] = useState(isSystemStatus);
  const [isClicked, setIsClicked] = useState({});
  const hasDuplicate = [false];

  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else if (!_.isEqual(goal, initialGoalValue)) {
      // goal changed from other places
      handleSetGoalStatus(initialGoalValue, initialStatusValue, isSystemStatus);
    }
  }, [initialGoalValue]);

  const handleSetGoalStatus = (goalValue, statusValue, shouldShowLightningIcon) => {
    setGoal(goalValue);
    setStatus(statusValue);
    setShowLightningIcon(shouldShowLightningIcon);
    form.setFieldsValue({
      [`${condition}-value`]: goalValue,
      [`${condition}-status`]: statusValue
    });
  };

  const handleSetGoal = useCallback(_.debounce(newGoal => {
    Client.query({
      query: getMonthlyReviewGoalMeetingQuery,
      variables: {
        memberId: _.get(user, 'id'),
        conditionEnum: goalType,
        goalEnum: newGoal
      }
    }).then(res => {
      const { getMonthlyReviewGoalMeeting } = _.get(res, 'data') || {};
      const { goalEnum, meetingStatus } = getMonthlyReviewGoalMeeting || {};
      handleSetGoalStatus(goalEnum, meetingStatus, true);
    }).catch(err => {
      console.error('error getMonthlyReviewGoalMeetingQuery', err);
      openErrorModal(GQLHelper.formatError(err));
    });
  }, 250));

  const renderTitle = () => {
    const title = condition || goalI18N.emptyGoalName;
    const titleStyle = goalType ? {} : { fontSize: 14, fontWeight: 400, color: '#6B7178' };
    return (
      <div className='title'>
        <span style={titleStyle}>
          {title}
        </span>
        {
          !billable &&
          <Popconfirm
            overlayClassName='goal-remove-popover'
            trigger='click'
            placement='bottom'
            title='Are you sure you want to remove this goal?'
            okText='Yes'
            onConfirm={handleRemoveManualGoal}
          >
            <Tooltip title='Remove goal'>
              <Icon type='delete' size={10}/>
            </Tooltip>
          </Popconfirm>
        }
      </div>
    );
  };

  const renderMonthlyMeasurementDays = () => {
    const ep = [];
    _.forEach(_.get(currentProgram, 'tasks'), (task) => ep.push(task.type));
    const handleClick = (buttonKey) => {
      if(buttonKey) {
        renderVitalsSummaryPopup();
        setIsClicked({ ...isClicked, [buttonKey]: true });
        Mixpanel.track('clicked', 'vitals_summary', 'monthly_review');
      }
    };

    const ButtonTemplate = props => (
      <Button 
        id={props.buttonKey}
        className={`monthly-measurement-days ${isClicked[props.buttonKey] ? 'clicked' : ''}`}
        style={{...props.style}} 
        onClick={() => handleClick(props.buttonKey)}
      >
        {props.children}
      </Button>
    );

    return hasAssociatedVitals ? 
      _.map(associatedVitals, associatedVital => {
        const vitalName = associatedVital === 'HS' ? 'WT' : associatedVital;
        const measurementCount = measurementCounts[associatedVital] || 0;
        const buttonKey= `${goalType}-${vitalName}-summary-btn`;
        const isNotEnrolled = !ep.includes(associatedVital);
        if(isNotEnrolled && hasDuplicate[0] === false) {
          hasDuplicate[0] = true;
          return <ButtonTemplate style={{width:'100%'}}>
                  <span>Patient is not monitoring related vital</span>
                 </ButtonTemplate>
        } else if(hasDuplicate[0]) {
          return;
        }
        return (
          <ButtonTemplate key={buttonKey} buttonKey={buttonKey}>
            <div className='content'>
              <Tooltip overlayClassName='monthly-measurement-days-tooltip' title='View vital measurement summary'>
                <img src={`${IMG_PATH}/${associatedVital}-vital.svg`} height='14px' style={{ marginTop: -5 }}/>
                  <span className='number-of-days'>{measurementCount}</span>
                <span>Days of meas.</span>
              </Tooltip>
              {
                measurementCount < 12 &&
                <div className='note'>  
                  <Tooltip
                    overlayClassName='monthly-measurement-days-note-tooltip'
                    title={goalI18N.tips.dayNote[isMRE ? 'MRE' : 'MTPR'].replace('XXX', vitalName)}
                  >
                    <div className='info-icon'>!</div>
                  </Tooltip>
                </div>
              }
            </div>
          </ButtonTemplate>)
      }) : (<ButtonTemplate>
        <span>No associated vital</span>
      </ButtonTemplate>);
  };

  const renderVitalGoal = () => {
    return (
      <div className='vital-goal'>
        <div className='label'>
          <span>Goal</span>
          {!goal && <span>(Goal has not been added in care plan)</span>}
        </div>
        {
          form.getFieldDecorator(`${condition}-value`, {
            initialValue: goal
          })
          (<Select 
            style={{ width: '100%' }} 
            onChange={handleSetGoal}
          >
            {
              goalOptions.map((goalEnum, i) => (
                <Select.Option key={i} value={goalEnum}>
                  {CLINIC_GOAL_ENUM[goalEnum] || '--'}
                </Select.Option>
              ))
            }
          </Select>)
        }
      </div>
    );
  };

  const renderGoalDropdown = () => {
    return (
      <div style={{ display: 'flex', height: 24 }}>
        <span style={{ fontSize: 12 }}>
          Goal Status:
        </span>
         <Form.Item label=''>
          {
            form.getFieldDecorator(`${condition}-status`, {
              initialValue: status,
              rules: !isMRE ? [{
                required: true,
                message: goalI18N.warning.statusRequired           
              }] : []
            })
            (<Select
              className='goal-status-result-select'
              style={{ width: 150 }}
              onChange={value => {
                setStatus(value);
                setShowLightningIcon(false);
              }}
              showArrow={false}
              dropdownMatchSelectWidth={false}
              defaultActiveFirstOption={false}
              placeholder='Please select one'
            >
              {
                statusList.map((s) => (
                  <Select.Option 
                    key={s.value} 
                    value={s.value} 
                    disabled={status === s.value}
                  >
                    {s.label}
                  </Select.Option>
                ))
              }
            </Select>)
          }
        </Form.Item>
        {
          showLightningIcon &&
          <Tooltip
            overlayClassName='assessment-lightning-bolt-tooltip'
            title={goalI18N.tips.lightningBolt}
          >
            <img src={`${IMG_PATH}/assessment-lightning-bolt.svg`} height='16px' />
          </Tooltip>
        }
      </div>
    )
  };

  const renderGoalStatus = () => {    
    const hasNoGoalAssessment = hasAssociatedVitals && !goal;
    const shouldShowUnableToConcludeTip = (!hasAssociatedVitals && status === STATUS_ENUM.UNABLE_TO_CONCLUDE);
    const showReview = !missingGoal.includes(goal);
    return !hasNoGoalAssessment ? 
      (
        <div className='goal-status'>
          <div className='goal-status-icon'>
            <img 
              src={`${IMG_PATH}${(_.find(statusList, {value: status}) || {}).icon || '/assessment-unknown-status.svg'}`} 
              width='17.5px' 
            />
          </div>
          <div style={{ width: '100%' }}>
            <div className='goal-status-result'>
              { renderGoalDropdown() }
              {
                shouldShowUnableToConcludeTip &&
                <Tooltip 
                  overlayClassName='goal-assessment-why-tooltip'
                  title={
                    !billable ? 
                      whyTextTip['manualGoal'] // Scenario 3.6.2 (not available in 0909 release)
                      : shouldShowUnableToConcludeTip ? // Scenario 3.5
                        whyTextTip[goal ? 'goalExists' : 'goalNotExists']
                        : ''
                  }
                >
                  <div className='goal-assessment-why-text'>
                    Why?
                  </div>
                </Tooltip>
              }
            </div>
            {
              checkIsInRole(rolesViewAssessmentLink) &&
              Array.isArray(goalAssessment) && 
              goalAssessment.length && showReview ?
                <Button 
                  type='link' style={{ padding: 0, height: 'auto' }}
                  onClick={() => renderVitalsSummaryPopup(true)}
                >
                  View goal assessment
                </Button>
              : null
            }
          </div>
        </div>
      ) 
    : <div className='goal-status goal-status-result'>
        <div>No goal assessment</div>
        <Tooltip 
          overlayClassName='goal-assessment-why-tooltip'
          title={whyTextTip.noGoalAssessment[checkIsInRole(['MD']) ? 'MD' : 'default']}
        >
          <div style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: -5, fontSize: 12, lineHeight: '16px' }}>
            Why?
          </div>
        </Tooltip>
      </div>;
  };

  return (
    <div className='goal-assessment-component-wrapper'>
      <div className="title-days-of-measurement">
        {renderTitle()}
        {renderMonthlyMeasurementDays()}
      </div>
      {
        !isMRE ?
          condition ? (
            <div className="vital-goal-status">
              {renderVitalGoal()}
              {renderGoalStatus()}
            </div>
          )
          : (
            <div className='info'>
              <div className='icon-wrapper'>
                <Icon type='info-circle' theme='filled' size={14}/>
              </div>
              <div className='text'>
                {goalI18N.missedBillableICD}
              </div>
            </div>
          )
        : null
      }
    </div>
  );
};

GoalAssessmentComponent.displayName = 'GoalAssessmentComponent';
GoalAssessmentComponent.propTypes = {
  condition: PropTypes.string, // not required due to vitalsWithGoal
  goalType: PropTypes.string,
  associatedVitals: PropTypes.array,
  hasAssociatedVitals: PropTypes.bool,
  initialGoalValue: PropTypes.string,
  initialStatusValue: PropTypes.string,
  goalOptions: PropTypes.arrayOf(PropTypes.string),
  billable: PropTypes.bool,
  renderVitalsSummaryPopup: PropTypes.func.isRequired,
  handleRemoveManualGoal: PropTypes.func,
  measurementCounts: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  openErrorModal: PropTypes.func.isRequired
};

export default GoalAssessmentComponent;