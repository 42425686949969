import React from 'react';
import {graphql,compose} from "react-apollo";
import query from "../../query";
import helper from "../../helper";
import OrgCardComponent from "../component/OrgCardComponent";

const OrgCardContainer = class extends React.Component{
	render() {
		const { type,key,orgId,organization,loading,name } = this.props;
		return <OrgCardComponent key={key} data={organization} type={type} orgId={orgId} loading={loading} name={name}/>
	}
};
const singleArea = graphql(query.organization,{
	options: (ownProps) => {
		const orgId = _.get(ownProps, 'orgId', helper.getViewerOrgId());
		const variables = {
			id: orgId
		}
		return {
			variables,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'cache-first'
		}
	},
	props: ({data}) => {
		const { organization, loading,refetch } = data;
		return {
			organization,
			loading,
			refetchOrg:refetch
		}
	}
});
export default compose(singleArea)(OrgCardContainer)