const StatementSchema = `
  groupId
  optionId
  value
`;

export const PESStatementSchema = `
  id
  createdAt
  createdBy {
    id
    profile {
      fullName
    }
  }
  updatedAt
  updatedBy {
    id
    profile {
      fullName
    }
  }
  visit {
    id
    appointmentAt
  }
  resolution {
    status
    updatedAt
    updatedBy
    visit {
      id
    }
  }
  problem {
    ${StatementSchema}
  }
  etiology {
    ${StatementSchema}
  }
  signSymptom {
    ${StatementSchema}
  }
  deleted
`;