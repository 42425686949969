import React from 'react';
import {Row, Col, Input, Button, DatePicker, Icon, Select, message,Tooltip,Popover} from 'antd';
import PostItContainerWrapper from "../../visitNewWorkFlow/containers/PostItContainerWrapper";
import '../style/index.scss';
import helper from "../helper/index";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { goPath } from '../../../lib/utils';
import remoteStatus from "../constant/remoteStatus";
const Option = Select.Option;
import moment from "moment";
import API from "../API/index";
import {compose, graphql} from "react-apollo";
import singleVisit from "../../graphql/singleVisit";
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { IHLoading } from 'ihcomponent';
import ConfirmExcludedReasonComponent from '../component/ConfirmExcludedReasonComponent';
import { ENROLL_FROM, EP_COUNT_MAX, PATIENT_COMPLEXITY_SOURCE_ENUM } from '../../../lib/constants';
import { Form,Checkbox } from 'antd';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import noteActions from "../../postIt/actions";
import postItAPI from '../../postIt/API/index'
import {connect} from "react-redux";
import CreateRemoteEnrollmentContainer from "../container/CreateRemoteEnrollmentContainer";
import SendSMSComponent from "./SendSMSComponent";
import EnrollPatientContainer from '../../patient/EnrollPatient/container/EnrollPatientContainer';
import { getPatientComplexityByConditions , updatePatientComplexity, withComplexity } from '../../patient/main/API';
import { getUpdatedComplexitySourceAndLevel } from '../helper/complexityLevel';

const hintMsg = 'By clicking “Move”, patient information will be moved\n' +
    'to the selected category immediately.';
const availableStatus = ['onEnded'];

let StickyNoteActionComponent = class extends React.Component{
    constructor(props) {
        super(props);
        const { currentId,currentUserFullName } = helper.getCurrentUser();
        this.state = {
            showHint: false,
            caller: currentId,
            callerFullName:currentUserFullName,
            callDate: moment(new Date()),
            calledAdded : false,
            showCallerAddedMsg: false,
            showExcludedReason: false,
            isEditMode: false,
            refetchPostItFn: null,
            showSendSMS: false,
            complexityLevel: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { props,state } = this;
        const { refetchRemoteEnrollment,currentDoc,callPhoneNumber } = props;
        const { caller,callDate,callerFullName,calledAdded } = state;
        const memberId = _.get(currentDoc,'member.id');
        if(!calledAdded&&callPhoneNumber) {
            this.setState({
                calledAdded: true
            },()=>{
                API.addLastCallByHandler({memberId,lastCallBy:{ providerId:caller,callDate,name:callerFullName }})
                    .then(()=>{
                        if(refetchRemoteEnrollment) {
                            refetchRemoteEnrollment()
                        };
                    });
            })
        }

    }

    showExcludedReasonDiv = ()=>{
        const { props,state,setShowExcludedReasonModal } = this;
        const { showExcludedReason } = state;
        const { refetchRemoteEnrollment,currentDoc,callPhoneNumber } = props;
        const { caller,callDate,callerFullName,showCallerAddedMsg } = state;
        const memberId = _.get(currentDoc,'member.id');
        return showExcludedReason&&<ConfirmExcludedReasonComponent memberId={memberId}
                                                                   refetchPostNote={()=>{
                                                                       refetchRemoteEnrollment();
                                                                       API.fetchPostItList({memberId},'network-only')
                                                                   }}
                                                                   setShowExcludedReasonModal={setShowExcludedReasonModal}/>
    }

    setComplexityLevel = (complexityLevel) => this.setState({complexityLevel})
    setEditMode = (isEditMode) => this.setState({isEditMode})

    onCheckboxChange = (checkedValue) => {
        this.setEditMode(true);
        getPatientComplexityByConditions({conditions: checkedValue}).then((res) => {
            const complexityLevel = _.get(res, 'data.getPatientComplexityByConditions')
            this.setComplexityLevel(complexityLevel);
        })
    }

    setShowExcludedReasonModal =(showExcludedReason,showHint)=>{
        this.setState({
            showExcludedReason,
            showHint:!!showHint
        })
    }
    combinePostInfo = ({memberId,callerFullName,callDate,format})=>{
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const organizationId = _.get(currentUser,'selectedRole.organization.id');
        let variable = { memberId,organizationId };
        let noteBody = 'The patient did not pick up.';
        const timeStamp = `Call Date: ${moment(callDate).format(format)}`;
        const caller = `Caller: ${callerFullName}`;
        noteBody = `${noteBody} \n ${caller} \n ${timeStamp}`;
        _.set(variable,'note',noteBody);
        return variable;
    }

    renderCallInfo = ()=>{
        const { props,state } = this;
        const { refetchRemoteEnrollment,currentDoc,callPhoneNumber } = props;
        const { caller,callDate,callerFullName,noPickedUp,refetchPostItFn } = state;
        const memberId = _.get(currentDoc,'member.id');
        const format ='MM/DD/YYYY'
        const changeMemberSel = (a,b)=>{
            this.setState({
                caller: a,
                callerFullName:b.props['data-fullname']
            })
        }

        const fromCall = callPhoneNumber
        const map = [
                     { title:'Call Date',path:'lastCallBy.name',
                       input:<DatePicker disabled={fromCall} defaultValue={callDate} format={format} onChange={(t)=>this.setState({callDate:t })}/>},
                     { title:'Caller',path:'lastCallDate',input:helper.getAllMembersSel(caller,changeMemberSel,fromCall)},
                     { title:'', path:'noPickedUp',input:<Checkbox checked={ noPickedUp } onChange={(t)=>this.setState({ noPickedUp:t.target.checked })}>No pick up</Checkbox>}
                    ]
        const onClick = ()=>API.addLastCallByHandler({memberId,lastCallBy:{ providerId:caller,callDate,name:callerFullName,isPickedUp:!noPickedUp }})
                                                     .then(()=>{
                                                                 if(refetchRemoteEnrollment) { refetchRemoteEnrollment() }
                                                                 if(noPickedUp) {
                                                                     postItAPI.createPostItMutation(this.combinePostInfo({memberId,callerFullName,callDate,format})).then(res=>{
                                                                         if(refetchPostItFn){
                                                                             refetchPostItFn();
                                                                         }
                                                                     });
                                                                 }
                                                                 this.setState({
                                                                     showCallerAddedMsg: true,
                                                                     noPickedUp:false,
                                                                 },()=>{
                                                                     setTimeout(()=>this.setState({
                                                                         showCallerAddedMsg: false,

                                                                     }),2000)
                                                                 })
                                                     });
        if(!currentDoc){
            return <div></div>
        }
        return <Row className='callInfo'>
                    {
                        _.map(map,({title,path,input})=>
                            <Col span={7} offset={1} key={title}>
                                <div>
                                    <span>{title}</span>
                                    { input }
                                </div>
                            </Col>
                        )

                    }
                    {
                        !fromCall&&<Col span={5} offset={1} style={{ marginTop: 10 }}>
                             <Button type='primary' onClick={()=>onClick()}>Add to Caller</Button>

                        </Col>
                    }
              </Row>
    }

    renderSendSmS = ()=>{
       const { setShowSendSMS } = this;
       const { currentDoc } = this.props;
       const { showSendSMS } = this.state;
       return showSendSMS&&<SendSMSComponent setShowSendSMS={setShowSendSMS} currentDoc={currentDoc}/>
    }

    setShowSendSMS =(showSendSMS)=>{

      this.setState({showSendSMS})
    }
    renderVisitButtons = ()=>{
        const { setShowCalender,singleVisit,enrolledProgramList,user, refetchSingleVisit } = this.props;
        const { showSendSMS } = this.state;
        let patient = {};
        patient.user = user;
        patient.enrolledPrograms = enrolledProgramList;
        return <Row style={{ marginTop: 0,marginBottom: 30 }} className={'visitButton'}>
                {
                    singleVisit &&
                    <Col span={4} offset={1} onClick={() => setShowCalender(true, singleVisit, 'EDIT', refetchSingleVisit)}>
                        <Icon type="edit"/>
                        <span>Edit Visit</span>
                    </Col>
                }
                <Col span={11} offset={1} onClick={()=>setShowCalender(true,patient,'CREATE', refetchSingleVisit)}>
                    <span>Create new Visit</span>
                </Col>
                <Col span={4} offset={1}>
                    <Tooltip title={'This intro SMS message contains text and a program introduction PDF link. '} overlayClassName={'smsTooltip'}>
                        <Popover content={this.renderSendSmS()} trigger="click" placement={'bottom'} visible={showSendSMS} overlayStyle={{ width:400 }}>
                            <span onClick={()=>this.setShowSendSMS(!showSendSMS)}>Send program intro SMS message</span>
                        </Popover>
                    </Tooltip>
                </Col>
               </Row>
    }

    handleOnSelectChange = (o,variables)=>{
        this.setState({
            showHint:true,
            selectedMove: o,
            showExcludedReason: o==='EXCLUDED'
        })
    }

    movePatientContent = ()=>{
        const { showExcludedReasonDiv } = this;
        const { showHint,selectedMove,showExcludedReason } = this.state;
        const { currentDoc,refetchRemoteEnrollmentList,refetchRemoteEnrollment,loading, enrolledProgramList,enrolled, user, refetchEnrolledProgramList} = this.props;
        const status  = _.get(currentDoc,'status');
        const enrolledStatus = enrolled||_.includes(_.map(enrolledProgramList,(item)=>item.status), 'STARTED') || _.includes(_.map(enrolledProgramList,(item)=>item.status), 'CREATED')
        const memberId = _.get(currentDoc,'member.id');
        const path = `/patients/${memberId}/enrolledprogram/_`;
        const onClick = ()=>API.moveRemoteEnrollmentHandler({ status:selectedMove,memberId })
                                                            .then(res=>{
                                                                    // if(selectedMove==='EXCLUDED') {
                                                                    //     helper.renderReasonToExcluded(memberId,true);
                                                                    // }else{
                                                                        message.success(`Moved to ${remoteStatus[selectedMove]}`)
                                                                    this.setState({
                                                                        showHint:false
                                                                    })
                                                                    // }
                                                                    refetchRemoteEnrollment();
                                                            });
        if( !currentDoc ){
            return <div></div>
        }
        return<Row>
                <Col span={17} offset={1}>Move to</Col>
                <Col span={16} offset={1}>
                    { helper.getMoveOptions(this,selectedMove||status,currentDoc) }
                </Col>
                <Col span={6} offset={1}>
                    {
                      status === "CONFIRMED" && !enrolledStatus && 
                      <EnrollPatientContainer 
                        user={user}
                        fromPlace={ENROLL_FROM.REMOTE_ENROLLMENT}
                        onCompleteEnrollment={() => {
                          if(refetchEnrolledProgramList)
                            refetchEnrolledProgramList(); // refetch for new enrolledStatus
                        }}
                        enrollButtonOnClick={() => {
                          try {
                            Mixpanel.track('clicked', 'enroll patients to the program', "in Remote Enrollment", {});
                            //sessionStorage.setItem('backToCurVisit', JSON.stringify(visit));
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        enrollButtonText='Enroll to Program'
                      />
                    }
                    {/* <Button
                    onClick={() => {
                        try {
                          Mixpanel.track('clicked', 'enroll patients to the program', "in Work List", {});
                          //sessionStorage.setItem('backToCurVisit', JSON.stringify(visit));
                        } catch (err) {
                          console.error(err);
                        } finally {
                          goPath(path);
                        }
                      }} 
                      type={'primary'}
                    >Enroll to Program</Button> : null} */}
                </Col>
                {
                    showHint&&
                    <Col span={17} offset={1} style={{ marginTop: 20,margin:20 }}>
                        <span>{hintMsg}</span>
                    </Col>
                }
                <Col span={17} offset={1}>
                    { showExcludedReasonDiv() }
                </Col>
                {
                    showHint&&!showExcludedReason&&
                    <Col span={17} offset={1}>
                        <Button type={'primary'} onClick={onClick}>Move</Button>
                    </Col>
                }
              </Row>
    }

    renderShowCallerAddedMsg = (showCallerAddedMsg)=>{
            return <Col span={24} offset={1} className={`showCallerAddedMsg ${showCallerAddedMsg ?'':'hideMsg' }`}>
                     <div><Icon type="check-circle" />Caller added to system!</div>
                   </Col>
    }

    onClick = ()=>{

        const { form,patientId,refetchRemoteEnrollment,patientComplexity } = this.props;
        const { validateFields } = form;
        const { complexityLevel } = this.state;
        const currComplexity = {
            source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
            level: complexityLevel
        }
        const { source, level } = getUpdatedComplexitySourceAndLevel(patientComplexity, currComplexity);
        validateFields((error,res)=>{
            let { conditionsList } = res;
            let variables = { conditionsList:dataHelper.handleConditionsList(conditionsList),memberId:patientId };
            API.editConditionList(variables).then(()=>{

                refetchRemoteEnrollment();
                message.success('Diagnosis updated!');

                if(complexityLevel) {
                    updatePatientComplexity({ memberId: patientId, level, source})
                }
            });
        })
    }

    renderConditionsList = ()=>{
        const localThis = this;
        return helper.renderConditionsListForm(localThis)
    }

    rightDiv = ({ currentDoc,patientId,refetchRemoteEnrollment })=>{
        const { renderCallInfo,renderConditionsList,renderVisitButtons,movePatientContent,state } = this;
        const { showCallerAddedMsg } = state;
        if(!currentDoc){
            return <div style={{ padding:'0 40px'}}>
                    <span>*This patient is not currently managed remotely. You may add the patient to remote enrollment here:</span>
                    <CreateRemoteEnrollmentContainer memberId={patientId} refetchRemoteEnrollment={refetchRemoteEnrollment}/>
                   </div>
        }
        return <Row >
                <Col span={24}>{ renderConditionsList() }</Col>
                <Col span={24}>{ renderCallInfo() }</Col>
                { this.renderShowCallerAddedMsg(showCallerAddedMsg)}
                <Col span={24}>{ renderVisitButtons() }</Col>
                <Col span={24}>{ movePatientContent() }</Col>
               </Row>
    }

    componentWillUnmount() {
        const { refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary,contact,setCallPhoneInfo,callPhoneNumber } = this.props;
        if(refetchRemoteEnrollmentList) {
            refetchRemoteEnrollmentList()
        }
        if(refetchRemoteEnrollmentSummary){
            refetchRemoteEnrollmentSummary()
        }
        if(callPhoneNumber) {
            helper.turnOnline();
            setCallPhoneInfo(null, null);
        }
    }

    setRefetchPostItFn = (refetchPostItFn)=>{
        this.setState({
            refetchPostItFn
        })
    }
    render(){
        const { rightDiv } = this;
        const { refetchPostItFn } = this.state;
        const { patientId,loading,currentDoc,refetchRemoteEnrollment } = this.props;

        if (loading){
            return <IHLoading />
        }
        return <Row>
                    <Col span={11}>
                        <PostItContainerWrapper patientId={patientId} refetchPostItFn={refetchPostItFn}
                                                setRefetchPostItFn={this.setRefetchPostItFn}/>
                    </Col>
                    <Col span={13}>{rightDiv({currentDoc,patientId,refetchRemoteEnrollment})}</Col>
              </Row>
    }
}

StickyNoteActionComponent = Form.create()(StickyNoteActionComponent);
// const withUserDate
const withData =  graphql(singleVisit,{
    options:(ownProps)=>{
        const visitId = _.get(ownProps,'currentDoc.nextVisit.visitId');
        return {
            variables: {
                id:visitId
            },
            fetchPolicy:'network-only',
        }
    },
    props:({data})=>{
        const { loading, getVisit, refetch }  = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            singleVisit: getVisit,
            refetchSingleVisit: refetch
        }
    }
})
// const userData = graphql(userGQL, {
//     options: (ownProps) => {
//         const id = _.get(ownProps,'currentDoc.member.id',_.get(ownProps,'patientId'));
//         return {
//             variables: {
//                 id
//             },
//             notifyOnNetworkStatusChange: true,
//             fetchPolicy:'network-only'
//         }
//     },
//     props:({ownProps,data})=>{
//         const { loading,user } = data;
//         if(loading){
//             return {
//                 loading
//             }
//         }
//         return {
//             user
//         }
//     }
// });
const lightEPList = graphql(lightEnrolledProgramList, {
    options: ownProps => ({
        variables: {
            count: EP_COUNT_MAX,
            page: 1,
            filters: {
                memberId: ownProps.patientId,
            }
        }
    }),
    props:({data})=>{
        const { enrolledProgramList,loading, refetch } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            enrolledProgramList: _.get(enrolledProgramList,'data'),
            refetchEnrolledProgramList: refetch
        }

    }
});
const mapToDispatch = (dispatch)=>{
    return {
        setNote: flag => dispatch(noteActions.setNote(flag)),
    }
};
export default compose(
    withData,
    lightEPList,
    withComplexity
)(connect(null,mapToDispatch)(StickyNoteActionComponent));
