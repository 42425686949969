import React from 'react';
import VisitPropTypes from '../constants/propTypes';
import { connect } from 'react-redux';
import { Table, DatePicker } from 'antd';

import actions from '../actions';
import tableColumns from '../constants/tableColumns';
import { getMoment, DATE_FORMAT_WITH_DAY_OF_WEEK } from '../helpers/visitDateHelpers';

import VisitTableTitle from './VisitTableTitle';

const AllVisitsComponent = props => {
  const { visitWindowTime, visitList, visitListCount, tableSelectedPageInfo, setSelectedPageInfo, tableSelectedAdvancedFilters, setSelectedAdvancedFilters } = props;

  return <div>
    <VisitTableTitle
      tableSelectedAdvancedFilters={tableSelectedAdvancedFilters}
      setSelectedAdvancedFilters={setSelectedAdvancedFilters}
      tableAdvancedFilters={props.tableAdvancedFilters}
      showResultCount={false}
    >
      <div style={{ marginLeft: 15 }}>
        <DatePicker.RangePicker 
          format={DATE_FORMAT_WITH_DAY_OF_WEEK}
          defaultValue={[getMoment(visitWindowTime.from), getMoment(visitWindowTime.to)]}
          onChange={dates => {
            const [from, to] = dates;
            props.setSelectedRange(
              from.startOf('day').valueOf(),
              to.endOf('day').valueOf(),
            );
          }}
          allowClear={false}
        />
        <span style={{ marginLeft: 10, marginRight: 3 }}>
          {visitListCount} visits in selected time range
        </span>
        {
          !_.isEmpty(tableSelectedAdvancedFilters) &&
          <span>
            <span style={{ marginRight: 5 }}>with filters</span>
            <a 
              onClick={e => {
                e.preventDefault();
                setSelectedAdvancedFilters({});
              }}
            >
              Clear all filters
            </a>
          </span>
        }
      </div>
    </VisitTableTitle>
    <Table
      scroll={{ x: '130%' }}
      dataSource={visitList}
      pagination={visitListCount ? {
        current: tableSelectedPageInfo.current || 1,
        pageSize: tableSelectedPageInfo.pageSize,
        total: visitListCount,
        onChange: setSelectedPageInfo
      } : false}
      rowKey={(record, idx) => record.id || idx}
      columns={[
        tableColumns.visitCategory({}, { imgStyle: { 
          position: 'absolute', top: '50%', transform: 'translateX(-65%) translateY(-50%)' }
        }),
        tableColumns.allVisit(),
        tableColumns.patient(),
        tableColumns.primaryProvider(),
        tableColumns.enrollStatusVitals(),
        tableColumns.visitNote(),
        tableColumns.participant(),
        tableColumns.visitStatus(),
        tableColumns.visitAction({}, props),
        tableColumns.chart({}, props),
      ]}
    />
  </div>;
};

AllVisitsComponent.displayName = 'visitsPage/components/AllVisitsComponent';
AllVisitsComponent.propTypes = VisitPropTypes.tab;

const mapDispatchToProps = dispatch => ({
  setSelectedRange: (from, to) => dispatch(actions.setSelectedRange({ from, to })),
  setSelectedPageInfo: (current, pageSize) => 
                      dispatch(actions.setSelectedPageInfo({ current, pageSize })),
  setSelectedAdvancedFilters: advancedFilters => 
                      dispatch(actions.setSelectedAdvancedFilters(advancedFilters))
});

export default connect(null, mapDispatchToProps)(AllVisitsComponent);