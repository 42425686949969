const user = {
    name : 'John Doe',
    id : 'VSM1234',
    status : 'Active',
    avator : 'http://www.haiwai.com/images/300/300/upload/biz/a1/23/63/a1236363ea982572651179d7f0a8b58e.jpg',
    tags : [
        'Male', '36 yrs', '176cm', '85kg', '24.2BMI', 'Heart Failure', 'English'
    ]
}

export default user
