import {Button, Col, Row} from "antd";
import React, {Component} from "react";

class VisitInputDeleteConfirm extends Component{

    render(){
        return (
            <div>
                <div>Are you sure you want to delete this visit?</div>
                <Row gutter={16}>
                    <Col span={4} offset={16}>
                        <Button onClick={this.props.onCancel}>Cancel</Button>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" onClick={this.props.executeDelete}>Yes</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default VisitInputDeleteConfirm;