/**
 * Created by zizhangai on 1/10/17.
 */
import {
  React, BaseComponent, IHLoading, _
} from 'ihcomponent'
import ProgramClass from 'modulesAll/graphql/class/EnrolledProgram'
import Threshold from 'modulesAll/common/components/Threshold1'
import I18N from 'modulesAll/I18N'

const Component = class extends BaseComponent{
  static displayName = 'patientView/myPrograms/components/ProgramInfo'

  componentDidUpdate() {
    if (!this.props.loading && this.props.program) {
      this.props.setBreadCrumb(`My ${I18N.get('keywords.Programs')} / ${this.props.program.name}`)
    }
  }
  componentDidMount() {
    if (!this.props.loading && this.props.program) {
      this.props.setBreadCrumb(`My ${I18N.get('keywords.Programs')} / ${this.props.program.name}`)
    }
  }
  render(){
    const ready = this.props.loading
    if(ready){
      return (
        <div className="vsm-component-patient-programInfo">
          <IHLoading />
        </div>
      )
    }

    this.program = new ProgramClass(this.props.program)

    return (
      <div className="vsm-component-patient-programInfo">
        {this.renderBaseInfo()}

        {/*{this.renderCareplan()}*/}

        {/*{this.renderProgramDevice()}*/}
      </div>
    )
  }
  renderBaseInfo(){
    return (
      <div className="c-box v-baseinfo">
        <div className="v-title">{I18N.get('keywords.Program')} Info</div>

        <div className="c-cont">

          {this.render_b_span(I18N.get('keywords.Program'), this.program._get('name'))}
          {this.render_b_span('Description', this.program._get('description'))}
          {this.render_b_span('Duration', this.program.format('duration'))}
        </div>

      </div>
    )
  }


  renderProgramDevice(){
    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Devices</div>
        <div className="c-cont">
          {this.program.variables.devices.length > 0 ? <div className="c-l">
            <b className="b1">Devices to loan</b>
            {
                _.map(_.sortBy(this.adminProgram.variables.devices, ['type']), (l, i)=>{
                    return <p className="s1">{I18N.get(`Devices.${l.type}.${l.model}`)}</p>
                })
            }
            </div> : null}
        </div>
      </div>
    )
  }

  renderCareplan(){
    const thProps = {
      list : this.program.format('thresholdComponentProps')
    }

    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Care Plan</div>
        <Threshold {...thProps} />
      </div>
    )
  }

  render_b_span(b, span){
    return (
      <p className="c-l">
        <b className="b1">{b}</b>
        <span className="s1">{span}</span>
      </p>
    )
  }
}

export default Component
