export const loginResponse = `
  id
  isDefaultPassword
  sessionToken
  globalConfigs {
    configKey
    value
  }
  orgPermissions {
    analyticsAPI
    enableABTesting
    pinnedToEMRRequired
    supportLoanerPhone
    supportClear
    keepOwnDevices
    otherCCMProvider
  }
  userSettings{
    sendHeartbeatLog
    snapshotInterval
    heartbeatInterval
    notificationFreq
  }
  role {
    refId
    name
    category
    access {
      action
      rule
    }
    organization {
      id
      name
      internalName
      leaf
      brandedName
      logo
      premiumClinic
      timezone
      templateGroups {
        id
        templates {
          id
          language {
            code
            description
          }
        }
      }
    }
  }
  lastLogIn{
    createdAt
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
      internalName
    }
  }
  appSettings {
    role {
      refId
      name
      category
    }
  }
`;
