import gql from 'graphql-tag';
import { PESStatementSchema } from '../schema/PESStatement';

const createSinglePES = gql`
  mutation createSinglePES (
    $memberId: EID!, 
    $visitId: EID, 
    $problem: String,
    $etiology: [InputStatement],
    $signSymptom: [InputStatement],
    $resolution: InputResolution
  ) {
    createPESStatement(
      memberId: $memberId, 
      visitId: $visitId, 
      problem: $problem, 
      etiology: $etiology,
      signSymptom: $signSymptom
      resolution: $resolution
    ) {
      ${PESStatementSchema}
    }
  }
`;

const createMultiPES = gql`
  mutation createMultiPES (
    $memberId: EID!, 
    $visitId: EID, 
    $pesStatements: [InputPESStatement]
  ) {
    createMultiPESStatements(
      memberId: $memberId, 
      visitId: $visitId, 
      pesStatements: $pesStatements
    ) {
      ${PESStatementSchema}
    }
  }
`;

export {
  createSinglePES,
  createMultiPES
};