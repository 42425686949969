import React from 'react';
import { InputNumber } from 'antd';
import moment from 'moment';
import MonthlyActiveRate from '../components/MonthlyActiveRate';
import { debounce } from 'lodash';
import { CHART_DEBOUNCE_TIME } from '../constants';

class MonthlyActiveRateContainer extends React.Component {
  constructor(props) {
    super(props);
    const defaultColumnCount = props.defaultColumnCount || 4;
    this.state = {
      months: defaultColumnCount,
      startDate: this.getStartingDate(defaultColumnCount),
    };
    this.deboundUpdateStartDate = debounce(this.updateStartDate, CHART_DEBOUNCE_TIME);
  }

  updateStartDate = () => {
    this.setState({ startDate: this.getStartingDate(this.state.months) });
  }
  
  handleMonthsChange = (months) => {
    this.setState({ months }, this.deboundUpdateStartDate);
  }

  getStartingDate = (months) => {
    return moment().endOf('months').add(-months, 'months');
  }

  render() {
    const { organizationName, organizationId,  openErrorModal } = this.props;
    const { months, startDate } = this.state;

    return (
      <div>
        Months <InputNumber onChange={this.handleMonthsChange} value={months} />
        <MonthlyActiveRate organizationName={organizationName} organizationId={organizationId} openErrorModal={openErrorModal} startDate={startDate} />
      </div>
    );
  }
}

export default MonthlyActiveRateContainer;