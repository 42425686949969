import React from 'react';

import { COMPLEXITY_LEVELS } from '../../../../lib/constants';

const ComplexityMessageTemplateBySources = (props) => {
  const { sources, oldLevel, newLevel, updatedByName = '--', EditComponent } = props;
  const { oldSource, newSource } = sources
  const levelString = (level) => (
    <span style={{ fontWeight: 'bold', color: '#333', marginLeft: 5 }}>
      {COMPLEXITY_LEVELS[level] || level }
    </span>
  );
  const oldLevelString = levelString(oldLevel);
  const newLevelString = levelString(newLevel);

  let commonMessage = '';

  const renderMessageWithEditButton = (message) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        {message}
      </div>
      <div style={{ display: 'inline-block' }}>
        {EditComponent || ''}
      </div>
    </div>
  );

  // { [oldSource] : { [newSource] }}
  const messageTemplates = {
    MANUAL_INPUT: (() => {
      commonMessage = <div>Changing the ICD-10 Code wouldn't update the condition complexity level.</div>;
      return {
        MANUAL_INPUT: <div>
          {renderMessageWithEditButton(
            <div>
              Patient's Condition Complexity Level has been manually changed to: 
              {newLevelString}
            </div>
          )}
          {commonMessage}
        </div>,
        ICD_CODE: <div>
          {renderMessageWithEditButton(
            <div>
              Patient's Condition Complexity Level has previously been manually changed to:
              {oldLevelString}
            </div>
          )}
          {commonMessage}
        </div>,
        CONDITION: <div>
          Patients' Condition Complexity Level has been manually changed to {oldLevelString} by {updatedByName || '--'}. Ticking the condition checkboxes wouldn't update the condition complexity level. 
        </div>
      }
    })(),
    ICD_CODE: (() => {
      commonMessage = <div>
        The patient's Condition Complexity Level will be updated accordingly.
      </div>;
      return {
        MANUAL_INPUT: <div>
          {renderMessageWithEditButton(
            <div>
              Patient's Condition Complexity Level has been manually changed to: 
              {newLevelString}
            </div>
          )}
          {commonMessage}
          <div>Changing the ICD-10 Code wouldn't update the condition complexity level.</div>
        </div>,
        ICD_CODE: <div>
          {renderMessageWithEditButton(
            <div>
              Based on the conditions selected above, the patient's Condition Complexity Level is: 
              {newLevelString}
            </div>
          )}
          {commonMessage}
        </div>,
        CONDITION: <div>
          Patients' Condition Complexity Level: {oldLevelString} has been defined by ICD-10 code. By ticking the condition checkboxes wouldn't update the condition complexity level. 
        </div>
      };
    })(),
    CONDITION: (() => {
      commonMessage = <div>
        <div>
          Based on the conditions selected above, the patient's Condition Complexity Level is: {newLevelString}
        </div>
        <div>
          The patient's Condition Complexity Level will be updated accordingly.
        </div>
      </div>;
      return {
        MANUAL_INPUT: '',
        ICD_CODE: commonMessage,
        CONDITION: commonMessage
      };
    })()
  };

  // oldSource can be null, using newSource as reference for message
  return <div>
    {messageTemplates[oldSource || newSource][newSource]}
  </div>;
};

export default ComplexityMessageTemplateBySources;