import { useState, useMemo } from 'react';
import roleHelpers from '../../taskAssignment/helper';
import { roleNameMap } from '../../utils/constants/role';
import { DISPLAY_NOT_ASSIGNED_VALUE, DISPLAY_NOT_NECESSARY_VALUE } from '../constants';
import { getTeamsFromLoginUser } from '.';

const addNoAssigneeOptions = (assignees) => {
  let filters = _.map(assignees, ({
    fullName,
    id,
  }) => ({
    text: fullName,
    value: id,
  }));

  filters = _.sortBy(filters, ['text']);

  filters = filters.concat([
    {
      text: DISPLAY_NOT_ASSIGNED_VALUE,
      value: 'null',
    },
    {
      text: DISPLAY_NOT_NECESSARY_VALUE,
      value: 'not-necessary',
    }
  ]);

  return filters;;
}

const useAssigneeFilters = () => {
  const [teamsFromLoginUser] = useState(
    getTeamsFromLoginUser()
  );
  const CAs = useMemo(
    () => roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.CA])
    , [teamsFromLoginUser]);
  const RDAndHCs = useMemo(
    () => roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.RD, roleNameMap.HC])
    , [teamsFromLoginUser]);

  const CAFilters = useMemo(() => addNoAssigneeOptions(CAs), [CAs]);
  const RDHCFilters = useMemo(() => addNoAssigneeOptions(RDAndHCs), [RDAndHCs]);

  return {
    CAFilters,
    RDHCFilters,
  };
};

export default useAssigneeFilters;