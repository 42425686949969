import React from 'react';
import { graphql } from "react-apollo/index";
import { compose } from 'recompose';
import GetpatientConsent from '../../profileDetail/query/GetpatientConsent';
import { CONSENT_TYPE } from '../constants';

const withDownload = graphql(GetpatientConsent, {
  options: (ownProps) => {
    const memberId = ownProps.patientId
    return {
      variables: { memberId }
    };
  },
  props: ({ data }) => {
      const { getPatientConsent, loading } = data;
      if (loading) {
        return {
          loadingConsent: loading
        };
      }
      return {
        ...getPatientConsent
      };
  }
});

const DownloadConsentButton = ({ consent, consentType, fileUrl, loadingConsent }) => {
  const isPaperConsent = consentType === CONSENT_TYPE.PAPER;
  return (
    <div style={{ lineHeight: 2, paddingTop: 6 }}>
      {
        loadingConsent ? 
          'Loading Consent Info...' 
        : 
          (consent && isPaperConsent) ?
            <span>Paper consent form signed.</span>
          :
            fileUrl ? 
              <a href={fileUrl} download style={{ textDecoration: 'underline' }}>
                Download consent form
              </a> : ''
      }
    </div>
  );
}

export default compose(withDownload)(DownloadConsentButton);