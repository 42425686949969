import common from 'modulesAll/common/reducers';
import layout from 'modulesAll/layout/reducers';
import dashboard from 'modulesAll/dashboard/main/reducers';
import patient from 'modulesAll/patient/main/reducers';
import auth from 'modulesAll/auth/reducers';
import program from 'modulesAll/program/Common/reducers';
import provider from 'modulesAll/provider/Common/reducers';
import task from 'modulesAll/providerTask/Common/reducers';
import Assessment from 'modulesAll/assessment/main/reducers';
import VitalList from 'modulesAll/patientView/vitalList/reducers';
import PatientTaskList from 'modulesAll/patientView/patientTaskList/reducers';
import VitalListPatient from 'modulesAll/patient/vitalList/reducers';
import MyPrograms from 'modulesAll/patientView/myPrograms/reducers';
import myAssessmentAndSurvey from 'modulesAll/patientView/myAssessmentAndSurvey/main/reducers';
import Onboard from 'modulesAll/onboard/reducers';
import Careteam from 'modulesAll/careteam/Common/reducers';
import Interventions from 'modulesAll/alerts/InterventionNotes/reducers';
import Broadcasts from 'modules/broadcasts/common/reducers/index';
import Alerts from 'modulesAll/alerts/ProviderAlerts/reducers';
import InactivePatientsList from 'modulesAll/patientList/inactivePatients/reducers';
import ChatHistory from 'modulesAll/chat/reducers';
import foodLog from 'modulesAll/foodLog/reducers';
import Report from '../modules/report/reducers'
import PatientList from 'modulesAll/patientList/main/reducers';
import Vendor from '../modules/vendor/common/reducers';
import Medicine from 'modulesAll/medicine/reducers';
import Visit from 'modulesAll/visit/reducers';
import Timer from 'modulesAll/timer/reducers';
import Organization from 'modulesAll/superAdmin/organization/common/reducer';
import AWSConnect from 'modulesAll/call/reducers';
import Stickie from 'modulesAll/postIt/reducers'
import VideoChat from 'modulesAll/VideoChat/reducer/reducers'
import OrgPath from 'modulesAll/newAdmin/reducer/index';
import PatientTimeSpentReducer from '../modules/patient/TimeSpent/reducer/index';
import VisitWorkList from '../modules/visitWorkList/reducers';
import VisitsPage from '../modules/visitsPage/reducers';
import workstationReducer from '../modules/workStation/reducer/index';
import TimerClaimed from '../modules/timerClaimed/reducers';

// Default State
const DEF_APP_STATE = {
  init: false,
  action: null,
  location: {},
};

const appReducer = (state = DEF_APP_STATE, action) => {
  switch (action.type) {
    case 'root/app/init':
      if (!state.init) {
        return {
          ...state,
          init: true,
          action: action.type,
        };
      }
      return { ...state, action: action.type };
    // case 'root/app/SET_LOCATION':
    //
    //   return {
    //     ...state,
    //     location: {
    //       ...action.location,
    //       ...{ params: action.params },
    //     },
    //   };

    default:
      return { ...state, action: action.type };
  }
};

export default {
  ...TimerClaimed,
  ...common,
  ...layout,
  ...dashboard,
  ...patient,
  ...program,
  ...provider,
  ...auth,
  ...Onboard,
  ...task,
  ...VitalList,
  ...PatientTaskList,
  ...Assessment,
  ...VitalListPatient,
  ...MyPrograms,
  ...myAssessmentAndSurvey,
  ...Careteam,
  ...Interventions,
  ...Broadcasts,
  ...Report,
  ...PatientList,
  ...Vendor,
  ...Alerts,
  ...InactivePatientsList,
  ...ChatHistory,
  ...foodLog,
  ...Medicine,
  ...Visit,
  ...VisitWorkList,
  ...Timer,
  ...Organization,
  ...AWSConnect,
  ...VideoChat,
  ...Stickie,
  ...OrgPath,
  ...PatientTimeSpentReducer,
  ...VisitsPage,
  ...workstationReducer,
  app: appReducer,
};
