import gql from 'graphql-tag'
import { Program } from 'graphqlModule/schema/Program'

export default gql `
  mutation changeAdminProgramStatus($id: EID!, $status: ProgramStatus!) {
    changeAdminProgramStatus(id: $id, status: $status) {
      id
      name
      description
      status
      healthCondition
      duration {
        length
        calendar
      }
      createdBy {
        id
        username
      }
      tasks {
        schedule {
          calendar
          target
          start
          end
          beforeMeal
        }
        repeat
      }
    }
  }
`
