import { React, _, IHInput, IHSelect } from 'ihcomponent';
import {
  assessmentNameValidator,
  assessmentDescriptionValidator,
  assessmentHealthConditionValidator,
  questionTitleValidator,
  questionOption40Validator,
  questionOption400Validator,
} from 'libModule/validator';
import InputLongComponent from '../edit/components/optionComponents/InputLongComponent';
import MultiSelectComponent from '../edit/components/optionComponents/MultiSelectComponent';
import SingleSelectComponent from '../edit/components/optionComponents/SingleSelectComponent';
import TrueOrFalseComponent from '../edit/components/optionComponents/TrueOrFalseComponent';
import ScaledComponent from '../edit/components/optionComponents/ScaledComponent';

const extractValue = obj => (_.isObject(obj) ? obj.__value : obj || '');

const TYPES_MAP = [
  {
    type: 'TRUE_OR_FALSE',
    display: 'Yes or No',
    component: TrueOrFalseComponent,
    defaultOption: [{ label: 'true' }, { label: 'false' }],
    validator: options => options.map(o => ({
      label: { __value: extractValue(o.label) },
    })),
  },
  {
    type: 'MULTI_SELECT',
    display: 'Multi-select',
    component: MultiSelectComponent,
    defaultOption: [{ label: '' }, { label: '' }],
    /* _.map doesn't skip holes of sparse array.
      We want to reserve key for React in options, thus using sparse array
    */
    validator: (options, isDraft) => options.map(o => {
      const value = extractValue(o.label);

      return {
        label: {
          __value: value,
          __error: !isDraft && questionOption400Validator(value),
        },
      };
    }),
  },
  {
    type: 'SINGLE_SELECT',
    display: 'Single-select',
    component: SingleSelectComponent,
    defaultOption: [{ label: '' }, { label: '' }],
    /* _.map doesn't skip holes of sparse array.
      We want to reserve key for React in options, thus using sparse array
    */
    validator: (options, isDraft) => options.map(o => {
      const value = extractValue(o.label);

      return {
        label: {
          __value: value,
          __error: !isDraft && questionOption400Validator(value),
        },
      };
    }),
  },
  {
    type: 'SCALED',
    display: 'Number Range',
    component: ScaledComponent,
    defaultOption: _.map(Array(10), (v, i) => ({ label: i + 1 })),
    validator: (options, isDraft) => options.map((o, i) => {
      const value = extractValue(o.meaning);
      const __option = {
        label: { __value: extractValue(o.label) },
        meaning: { __value: value },
      };

      if (!isDraft && (i === 0 || i === 9)) {
        __option.meaning.__error = questionOption40Validator(value);
      }

      return __option;
    }),
  },
  {
    type: 'INPUT_LONG',
    display: 'Open Text Response',
    component: InputLongComponent,
    validator: () => {},
  },
];

export const QUESTION_TYPE_RADIO = _.map(TYPES_MAP, t => ({ name: t.display, value: t.type }));
export const HEALTH_CONDITION_RADIO = [
  { name: 'Diabetes', value: 'DIABETES' },
  { name: 'Heart Failure', value: 'HEART_FAILURE' },
  { name: 'AMI', value: 'AMI' },
];

export const getQuestionType = type => _.find(TYPES_MAP, v => v.type === type);

export const validateAndReturnQuestion = (q) => {
  const text = extractValue(q.text);
  const type = extractValue(q.type);
  const isDraft = !q._status || q._status === 'DRAFT';

  const ret = {
    type: { __value: type },
    text: { __value: text, __error: !isDraft && questionTitleValidator(text) },
    options: !!type && getQuestionType(type).validator(q.options, isDraft),
    _status: q._status || 'DRAFT',
  };

  let optionError = '';
  _.find(ret.options, (option) => {
    optionError = _.get(option, 'label.__error') || _.get(option, 'meaning.__error');
    return !!optionError;
  });

  if (!isDraft) {
    ret.__error = ret.text.__error || optionError;
  }

  return ret;
};

export const validateAndReturnFields = (fields, isSurvey = false) => {
  const __fields = {
    name: { __value: extractValue(fields.name) },
    description: { __value: extractValue(fields.description) },
    healthCondition: { __value: extractValue(fields.healthCondition) },
    /* _.map doesn't skip holes of sparse array.
      We want to reserve key for React list of questions, thus using sparse array
    */
    questions: fields.questions.map(validateAndReturnQuestion),
    _status: fields._status || 'DRAFT',
  };

  if (__fields._status !== 'DRAFT') {
    __fields.name.__error = assessmentNameValidator(__fields.name.__value);
    __fields.description.__error = assessmentDescriptionValidator(__fields.description.__value);
    __fields.healthCondition.__error = !isSurvey && assessmentHealthConditionValidator(__fields.healthCondition.__value);

    __fields.__error = __fields.name.__error
      || __fields.description.__error
      || __fields.healthCondition.__error
      || (__fields.questions.some(q => q._status !== 'SAVED') && 'You have unsaved question!')
      || _.get(__fields.questions.some(q => !!q.__error), '__error');
  }

  return __fields;
};

export const renderValidatableInput = (data = {}, props = {}, wrapperClassName = '') => {
  const { __value = '', __error = '' } = data;

  return (
    <div className={`validate ${wrapperClassName}${__error ? ' has-error' : ''}`}>
      <IHInput value={__value} {...props} />
      {__error && <span className="error">{__error}</span>}
    </div>
  );

  // 1/23/18 - JV - Mark added below code, I am not sure why or if we really need it anymore
  // const onBlur = !__error && props.onChange //only trigger redux action when onBlur for better performance on IE (after the "required" error is cleared)
  // return (
  //   <div className={`validate ${wrapperClassName}${__error ? ' has-error' : ''}`}>
  //     <IHInput value={__value} {...props} onBlur={onBlur} />
  //     {__error && <span className="error">{__error}</span>}
  //   </div>
  // );
};

export const renderValidatableSelect = (data = {}, props = {}, wrapperClassName = '') => {
  const { __value = '', __error = '' } = data;

  return (
    <div className={`validate ${wrapperClassName}${__error ? ' has-error' : ''}`}>
      <IHSelect value={__value} {...props} />
      {__error && <span className="error">{__error}</span>}
    </div>
  );
};

export const getGraphAssessment = (fields, assessmentOrSurvey = 'ASSESSMENT') => ({
  name: fields.name.__value,
  description: fields.description.__value,
  // Filter out holes in sparse array
  questions: fields.questions.filter(v => v).map(q => {
    const ret = {
      type: q.type.__value,
      text: q.text.__value,
    };

    if (q.options && q.options.length) {
      // Filter out holes in sparse array
      ret.options = q.options.filter(v => v).map(o => {
        const option = {
          label: o.label.__value,
        };
        if (o.meaning && o.meaning.__value) {
          option.meaning = o.meaning.__value;
        }

        return option;
      });
    }

    return ret;
  }),
  healthCondition: fields.healthCondition.__value,
  type: assessmentOrSurvey,
});
