import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import _ from 'lodash'
import uniqBy from 'lodash/uniqBy'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'
import { IHMultiSelect } from 'ihcomponent';
import Client from 'libModule/gqlClient';
import moment from 'moment';
//files
import ProviderAlertList from '../components/ProviderAlertListComponent';
import { onAddClick } from '../actions/ProviderAlertListActions';
import createTableAction from 'libModule/table/TableActions';
import actions from '../actions';
//queries
import resultList from 'modulesAll/graphql/resultList'
import { resultListMinimal } from 'graphqlModule/resultList'
import UserClass from 'graphqlModule/class/User'
import enrolledUserStatus from 'modulesAll/graphql/enrolledUserStatus';
import updateLastReviewedAt from 'modulesAll/graphql/updateLastReviewedAt';
import InterventionNotes from '../../InterventionNotes/containers/InterventionNotes'
//import UI liabray
import { Modal } from 'antd';
import '../style/index.scss';

const tableActions = createTableAction('provider_alert_list', {
  sortFieldMap: { date: 'DATE' },
  initState:{filter:{ severity:['CRITICAL']}},
  shouldUpdateHistory: false
});

const vitalFilters = () => {
  // return uniqBy(resultList || [], 'type').map( (d) => {
  //   return {
  //     value: _.get(d, 'type'),
  //     text: TASK_TYPE_MAP[_.get(d, 'type')]
  //   }
  // })
  return _.map(TASK_TYPE_MAP, (val, key) => ({
    value: key,
    text: val
  }))
}

const Container = class extends React.Component {
  static displayName = 'ProviderAlertListContainer'

  constructor(p) {
    super(p)
    this.adminProgramFilter = UserClass.getAdminProgramFilter()
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleReviewButton = this.handleReviewButton.bind(this);
    this.state = { currentPrograms: [], optionRaw: [], defaultValue: [],showAddAlertModal:false,alert:{ } };
  }
  componentWillMount() {
    this.props.onLoadParams(null, true);

    // get program options
    const optionRaw = _.map(JSON.parse(sessionStorage.getItem('adminProgramList')) || [], (prog) => ({
      name : prog.name,
      value : prog.id
    }));

    // set default value
    const defaultValue = optionRaw && optionRaw.length > 0 ? [ optionRaw[0].value ] : [];

    this.setState({ defaultValue, optionRaw, currentPrograms: defaultValue }, () => this.props.selectCurrentProgram(this.state.defaultValue));
  }
  componentWillUnmount() {
    this.props.onLoadParams(null, false);
  }
  handleProgramChange(val) {
    this.setState({ currentPrograms: val }, () => this.props.selectCurrentProgram(this.state.currentPrograms));
  }
  async handleReviewButton(id) {
    const variables = {
      adminProgramIds: this.state.currentPrograms,
      memberId: id
    };

    try {
      const res = await this.props.runUpdateLastReviewedAt({ variables });

      if (res.data.updateLastReviewedAt) {
        const enrolledUsersVariables = {
          adminProgramIds: this.state.currentPrograms
        };

        // 2-22-18 - JV - Rerun 'enrolledUserStatus' query to fetch latest
        Client.query({
          query: enrolledUserStatus,
          variables: { ...enrolledUsersVariables },
          fetchPolicy: 'network-only'})
        .then(res => console.log('----- enrolledUserStatus query successful! res: ', res))
        .catch(err => console.log('----- ERROR: enrolledUserStatus Client.query! err: ', err));
      }
    } catch (e) {
      console.log('------ ERROR: updateLastReviewedAt! e: ', e);
    }
  }

  renderAlertModal=()=>{
      const { alert,showAddAlertModal } = this.state;
      const setCategoryToAlerts = true;
      if(showAddAlertModal) {
          return <Modal visible={true} onCancel={()=>this.closeAlertModal()} width='1000px'
                        maskClosable={false}
                        footer={null}
                        className='addAlertModal'
                        style={{ marginTop:-20 }}>
                    <InterventionNotes row={alert} noteType='alert_note' setCategoryToAlerts={setCategoryToAlerts}/>
                 </Modal>
      }
  }

  openAlertModal=(alert)=>{
    this.setState({
        showAddAlertModal:true,
        alert
    })
  }

  setAlert(alert){
    this.setState({
        alert
    })
  }

  closeAlertModal=()=>{
    this.setState({
        showAddAlertModal:false
    })
  }
  
  render() {
    const props = {
      ...this.props,
      adminProgramFilter: this.adminProgramFilter
    }
    return (
      <div className='alertsContainer'>
        {
          this.props.currentAlertsOption === 'INACTIVE_PATIENTS' ?
            <div className='alertsRow-dropDown'>
              <IHMultiSelect option={this.state.optionRaw}
                             defaultValue={this.state.defaultValue}
                             onChange={ val => this.handleProgramChange(val) } />
            </div> : null
        }
        <div>
          { this.renderAlertModal() }
        </div>
        <div className='alertsRow-list'>
          <ProviderAlertList handleReviewButton={this.handleReviewButton} {...props} onAddClick={ this.openAlertModal } setAlert={ this.setAlert }/>
        </div>
      </div>
    );
  }
}

const withData = graphql(resultList, {
  options: (ownProps) => {
    const enrolledProgramId = UserClass.getEnrolledProgramActiveIdList() || []
    const careStatusFromRedux =   _.get(ownProps, 'filter.careStatus',['NEW','HANDLED']);
    const careStatusOpt = careStatusFromRedux.indexOf('All')!=-1 ? null : careStatusFromRedux;
    const  variables = {
      filters: {
        fromDate : (moment(new Date()).subtract(7,'days').endOf('day')).toDate(),
        severity: _.get(ownProps, 'filter.severity'),
        // _.get(ownProps, 'filter.severity') || ['MISSED', 'RISK', 'CRITICAL'],
        enrolledProgramId,
        'type': _.get(ownProps, 'filter.type'),
        'adminProgramId': _.get(ownProps, 'filter.programId'),
        'careStatus': careStatusOpt,
        alertsOnly: true
      },
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      sort: { field: 'DATE', direction: 'DESC'}
    }
    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      };
    }
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };

  },
  props: ({ownProps, data}) => {
    const { loading } = data;
    return {
      alertList: _.get(data, 'resultList'),
      vitalFilters: vitalFilters((_.get(data, 'resultList.data') || [])),
      loading,
    }
  }
})

const total  = graphql(resultListMinimal, {
  options: (ownProps) => {
    return {
      variables: {
        page: 1,
        count: 1,
        filters: {
          get enrolledProgramId() {
            return UserClass.getEnrolledProgramActiveIdList()
          },
          severity: ['MISSED', 'RISK', 'CRITICAL'],
          alertsOnly: true
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 1800000 //poll every 30 minutes to get the alert badge
    };
  },
  props: ({ownProps, data}) => {
    return {
      alertsCount: _.get(data, 'resultList.pageInfo.total', 0),
      isLoading: data.loading
    }
  }
});

const loadEnrolledUsersList = graphql(enrolledUserStatus, {
  options: (ownProps) => {
    return {
      variables: {
        adminProgramIds: ownProps.currentProgram
      }
    }
  },
  props: ({ ownProps, data }) => {
    return {
      enrolledUserStatusList: _.get(data, 'enrolledUserStatus'),
      isLoading_enrolledUserStats: _.get(data, 'loading')
    }
  }
});

const runUpdateLastReviewedAt = graphql(updateLastReviewedAt, { name: 'runUpdateLastReviewedAt' });

const mapState = (state) => {
  return {
    ...state.globalTables.provider_alert_list,
    currentAlertsOption: state.alerts.main.currentAlertsOption,
    currentProgram: state.alerts.main.currentProgram
  }
};

const mapDispatch = (dispatch) => ({
  ...tableActions,
  // onAddClick: (row,setCategoryToAlerts) => dispatch(onAddClick(row,setCategoryToAlerts)),
  changeCurrentAlertsOption: selectedAlertOption => dispatch(actions.changeCurrentAlertsOption(selectedAlertOption)),
  selectCurrentProgram: currentProgram => dispatch(actions.selectCurrentProgram(currentProgram))
});

Container.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  selectCurrentProgram: PropTypes.func.isRequired,
  currentProgram: PropTypes.array
}

export default compose(
  connect(mapState, mapDispatch),
  withData,
  total,
  loadEnrolledUsersList,
  runUpdateLastReviewedAt
)(Container)
