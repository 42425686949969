import ProgramInfo from '../components/ProgramInfo'
import {createContainer, RequestCache} from 'libModule/utils'

import { graphql } from 'react-apollo';
import programGQL from 'modulesAll/graphql/adminProgram'
import userGQL from 'modulesAll/graphql/user'

const testProgramId = 'UHJvZ3JhbTo1ODQ1OWY0YTdmYTYwNDUyYmU0ZTdjZGE='
const testPatientId = 'VXNlcjo1ODQ1NjJlZGIzMDIyZDEyMTRkYTQxMmU='
const ComponentWithData = graphql(programGQL, {
  name : 'program',
  options : (ownProps)=>{
    const programId = ownProps.location.query.programId || testProgramId
    return {
      variables : {
        id : programId
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props : ({ownProps, adminProgram})=>{
    // console.log(adminProgram)
    return {
      program : adminProgram
    }
  }
})(graphql(userGQL, {
  name : 'patientData',
  options : (ownProps)=>{
    return {
      variables : {
        id : testPatientId
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props : ({ownProps, patientData})=>{
    return {
      patientData
    }
  }
})(ProgramInfo))

const Container = createContainer(ComponentWithData, (state)=>{
  return {
    location : state.app.location
  }
});

Container.displayName = 'patient/programSummary/containers/ProgramInfo';

export default Container;
