import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHForm } from 'ihcomponent'
import { getInitDataFromSchema, convertFieldsValue } from 'libModule/utils'
import { bindActionCreators } from 'redux'
import { getItemList, schema } from 'commonModule/components/careplan/formData/SurveyAlertForm'
import actions from 'ProgramEditModule/SurveyAlert/actions/AlertFormActions'

class AlertFormContainer extends Component {
  static displayName = 'program/ProgramEdit/SurveyAlert/containers/AlertFormContainer'

  render() {
    const body = this.renderBody()
    return (
      <div className="program-new-info-form">
        {body}
      </div>
    )
  }
  renderBody() {
    const { onFieldsChange, AlertList } = this.props
    const { activeKey, panesFieldsValue } = AlertList
    const fieldsValue = panesFieldsValue[activeKey]
    const initData = fieldsValue ? convertFieldsValue(fieldsValue) : getInitDataFromSchema(schema)
    const itemList = getItemList({ onFieldsChange, fieldsValue })
    const ref = refNode => { this.IHForm = refNode }
    const formProps = {
      vertical: true,
      itemList,
      initData,
      onFieldsChange,
      ref,
      isNewForm: true
    }
    const body = (
      <IHForm {...formProps} />
    )
    return body
  }
}

const mapState = ({ program }, ownProps) => {
  const SurveyAlert = program.ProgramEdit.Careplan.Survey.Alert
  return {
    ...SurveyAlert.AlertForm,
    AlertList: SurveyAlert.AlertList,
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators(actions, dispatch)
}

AlertFormContainer.propTypes = {
  onFieldsChange: PropTypes.func,
  initData: PropTypes.object,
  fieldsValue: PropTypes.object,
  AlertList: PropTypes.object,
  formRef: PropTypes.string,
}

export default connect(
  mapState, mapDispatch
)(AlertFormContainer)
