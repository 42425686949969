import React from "react";
import POAreaChartComponent from '../components/POAreaChartComponent';
import moment from "moment/moment";
import { Switch,Radio } from 'antd';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const POAreaChartContainer = class extends React.Component {
    constructor(){
        super();
        this.state = {
            allVisit: false,
            toDate:moment().endOf('day'),
            fromDate:moment().subtract(3,'month').startOf('day'),
            timeInterval:'M',
            value:3
        }
    }

    // changePre = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const { patientId } = this.props;
    //     const updatedFromDate = fromDate.subtract(value,timeInterval).startOf('day');
    //     const updatedToDate = toDate.subtract(value,timeInterval).endOf('day');
    //     const map = {
    //         3:'THREE',
    //         6:'SIX',
    //         1:'ONE'
    //     };
    //     const strVal =  map[value];
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'YEAR')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','BACK_PO',null,{ PATIENT_ID:patientId,DURATION: str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // changeNext = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const { patientId } = this.props;
    //     const updatedFromDate = fromDate.add(value,timeInterval).startOf('day');
    //     const updatedToDate = toDate.add(value,timeInterval).endOf('day');
    //     const map = {
    //         3:'THREE',
    //         6:'SIX',
    //         1:'ONE'
    //     };
    //     const strVal =  map[value];
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'YEAR')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','NEXT_PO',null,{ PATIENT_ID:patientId,DURATION: str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // renderNextButton = () =>{
    //     const { value,timeInterval } = this.state;
    //     const unit = (timeInterval==='Y') ? 'Year' :'Months';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <span>{`Next ${value} ${unit}`}</span>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.changeNext()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //             </div>
    // }

    // renderPrevButton = ()=>{
    //     const { value,timeInterval } = this.state;
    //     const unit = (timeInterval==='Y') ? 'Year' :'Months';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.changePre()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //                 <span>{`Past ${value} ${unit}`}</span>
    //           </div>
    // }


    // setTimeInterval = (updatedTimeInterval,updatedValue)=>{
    //     const { patientId } = this.props;
    //     const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
    //     const updatedToDate = moment().endOf('day');
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate,
    //         timeInterval: updatedTimeInterval,
    //         value: updatedValue
    //     },()=>{
    //         const map = {
    //             3:'THREE',
    //             6:'SIX',
    //             1:'ONE'
    //         };
    //         const str = map[updatedValue]+'_'+(updatedTimeInterval=='Y' ? 'YEAR' : 'MONTH');
    //         Mixpanel.track('clicked',str+'_PO',null,{ PATIENT_ID: patientId });
    //     })
    // }

    // renderRadioButton = ()=>{
    //     const { value } = this.state;
    //     const threeMonthChecked = (value===3) ;
    //     const sixMonthChecked = (value===6) ;
    //     const oneYearChecked = (value===1) ;

    //     return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginLeft:10, marginRight: 10}}>
    //                 <Radio.Button  value='3'  onClick={()=>this.setTimeInterval('M',3)} checked = {threeMonthChecked}>3 Months</Radio.Button>
    //                 <Radio.Button  value='6'  onClick={()=>this.setTimeInterval('M',6)} checked = {sixMonthChecked} >6 Months</Radio.Button>
    //                 <Radio.Button  value='1' onClick={()=>this.setTimeInterval('Y',1)} checked = {oneYearChecked}>1 Year</Radio.Button>
    //             </Radio.Group>
    // }

    setAllData = ()=>{
        const { patientId } = this.props;

        this.setState({ allVisit: !this.state.allVisit });
        Mixpanel.track('clicked','ALL_DATA_PO',null,{ PATIENT_ID:patientId })
    }

    render(){
        // const { props,renderRadioButton,renderPrevButton,renderNextButton } = this;
        let { fromDate,toDate, xAxisLabelPerDay, ...props } = this.props;
        const { allVisit } = this.state;
        return   <div style={{ padding: 20 }} className='row'>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                        {/* <div style={{ fontSize:16,fontWeight:'bold' }}>Oximeter</div>
                        <div style={{marginLeft: 'auto', display: 'flex', marginRight: 40}}>
                                {renderPrevButton()}
                                {renderRadioButton()}
                                {renderNextButton()}
                            </div> */}
                            <span style={{ marginRight: 20, marginLeft: 10 }}>All Data</span> <Switch onChange={()=>this.setAllData()} />
                      </div>
                    <POAreaChartComponent
                        allVisit = {allVisit}
                        {...props}
                        // changePre={() => this.changePre()}
                        // changeNext={() => this.changeNext()}
                        // setTimeInterval={(i, v) => this.setTimeInterval(i, v)}
                        fromDate={fromDate.toDate()}
                        toDate={toDate.toDate()}
                        xAxisLabelPerDay={xAxisLabelPerDay}
                        // timeInterval = {timeInterval}
                        // value={value} 
                    />

                </div>
    }
}


export default POAreaChartContainer;
