import _ from 'lodash'
import ThresholdInput from 'commonModule/components/ThresholdInput'
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields, isNumeric } from 'libModule/utils'
import I18N from 'modulesAll/I18N'
import {
  VITAL_TYPES_LONG,
  VITAL_THRESHOLD_KEY_MAP_ONE,
  VITAL_THRESHOLD_KEY_MAP_TWO,
  VITAL_TYPE_MAP,
  VITAL_LABEL_MAP,
  VITALS_WITH_SINGLE_TYPE
} from 'modulesAll/utils/constants/task'
import { RequestCache } from 'libModule/utils'
import { convertKgToPound } from 'libModule/utils/convertUnits';

const ERROR = I18N.get('ThresholdInput')

const validateNumber = (v, label, type) => {
  if(v && !/^(-)?(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(v)){
    return `${ERROR[type][label]}`
  }

  return null
}

const validate = (value, type) => {
  let [clow, low, high, chigh] = value
  const error = []

  if(!low || !high){
    console.log(low, high);
    error.push(ERROR[type].normal_require)

    return error
  }

  _.each([[clow, 'Critically Low'], [low, 'Low'], [high, 'High'], [chigh, 'Critically High']], (n)=>{
    const se = validateNumber(n[0], n[1], type)

    if(se){
      error.push(se)
    }
  })

  clow = parseFloat(clow)
  low = parseFloat(low)
  high = parseFloat(high)
  chigh = parseFloat(chigh)

  if (type === "HS") {
    if (low >= 0 || (!isNaN(clow) && clow >= 0)) error.push(`Values for Critically Low and Low must be negative`)
    if (high <=0 || (!isNaN(chigh) && chigh <=0)) error.push(`Values for Critically High and High must be positive`)
  }

  const min_gap_from_low = low - ERROR[type].critical_min_gap
  const min_gap_from_high = high + ERROR[type].critical_min_gap

  if (low >= high) error.push(ERROR[type].normal)
  if (!isNaN(clow) && clow >= min_gap_from_low) error.push(`Value for Critically Low must be less than ${min_gap_from_low.toFixed(1)}`)
  if (!isNaN(chigh) && chigh <= min_gap_from_high) error.push(`Value for Critically High must be greater than ${min_gap_from_high.toFixed(1)}`)

  return error
}

export const schema = {
  vitalType: {
    initialValue: '',
    required: true
  },
  systolic: {
    initialValue: [70,90,130,180],
        // [null, null, null, null],
    required: false
  },
  diastolic: {
    initialValue:[50,60,80,120],
        // [null, null, null, null],
    required: false
  },
  BGBeforeMeal: {
    initialValue: [60,80,130,200],
        // [null, null, null, null],
    required: false
  },
  BGAfterMeal: {
    initialValue:[ 70,100,180,250],
        // [null, null, null, null],
    required: false
  },
  weightBasedOn: {
    initialValue: 'Last Measurement',
    required: false
  },
  HS: {
    initialValue: [null, -3, 3, null, null],
    required: false
  },
  HR: {
    initialValue: [null, null, null, null],
    required: false
  },
  TM: {
    initialValue: [94, 97, 99, 103],
    required: false
  },
  PO: {
    initialValue: [null, 75, 100, null],
    required: false
  },
  schedule: {
    initialValue: '',
    required: false
  },
  description: {
    initialValue: '',
    required: false
  },
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const itemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}
const getWeightBasedOn = (vitalType) => {
  let result = null
  if (vitalType === 'Weight') {
    const key = 'weightBasedOn'
    result = [
      {
        key,
        label: 'Weight Change Based On',
        type: 'radioButton',
        ...itemLayout,
        placeholder: '',
        initialValue: initData[key],
        rules: [
          { required: isFieldsRequired[key], message: `${key} is required` }
        ],
        col: { span: 20, offset: 0 },
        option: [
          { name: 'Baseline', value: 'Baseline' },
          { name: 'Last Measurement', value: 'Last Measurement' }
        ]
      }
    ]
  }
  return result
}

const getThresholdRange = (vitalType, onFieldsChange, weightBasedOn, userRole) => {
  if (!vitalType) return []
  const currentPatient = RequestCache.get('nurse/currentPatient')
  const baselineUnit = _.get(currentPatient, 'profile.weight.unit')
  let baselineValue = _.get(currentPatient, 'profile.weight.value')
  baselineValue = baselineUnit === 'kg' ? convertKgToPound(baselineValue) : baselineValue

  const keys = VITALS_WITH_SINGLE_TYPE.indexOf(vitalType) !== -1
    ? [VITAL_THRESHOLD_KEY_MAP_ONE[vitalType]] : [VITAL_THRESHOLD_KEY_MAP_ONE[vitalType], VITAL_THRESHOLD_KEY_MAP_TWO[vitalType]]
  const result = [
    [
      {
        key: 'thresholdRangeLabel',
        label: 'Threshold Range',
        type: 'label',
        col: { span: 6, offset: 0 },
        style: { textAlign: 'left' },
        className: 'label-title'
      }
    ]
  ]
  const isBaselineWeight = vitalType === 'Weight' && weightBasedOn === 'Baseline'
  keys.forEach(key => {
    const onChange = (value) => {
      const changedFields = {
        [key]: {
          name: key,
          value
        }
      }
      onFieldsChange(null, changedFields)
    }
    const component = ThresholdInput
    const initialValue = isBaselineWeight ? _.set(initData[key], '4', baselineValue) : initData[key];
    const componentObject = {
      key,
      label: VITAL_LABEL_MAP[key],
      placeholder: '',
      initialValue,
      vitalType,
      vital_type: VITAL_TYPE_MAP[vitalType],
      validateTrigger: 'onSubmit',
      // Already have validations inside component
      rules: [
        {
          validator(rule, value, callback, source, options) {
            const errors = []
            if (isBaselineWeight && userRole === 'PROVIDER') {
              if (value[4] < 10) {
                errors.push('Weight Change Baseline value can not be less than 10')
              }
              if (!isNumeric(value[4])) {
                errors.push('Please Set Baseline value for Threshold')
              }
            }
            const otherErrors = validate(value, VITAL_TYPE_MAP[vitalType])
            callback(errors.concat(otherErrors))
          }
        }
      ],
      type: 'selfComponent',
      component,
      ...itemLayout,
      style: { textAlign: 'left' },
      col: { span: 24, offset: 0 },
      onChange
    }
    if (weightBasedOn === 'Baseline' && userRole === 'PROVIDER') {
      componentObject.hs_type = true
    }
    result.push([
      componentObject
    ])
  })

  return result
}

const getOptions = (vitalType, VitalList, isEditMode=false) => {
  const currentTypes = VitalList.data.map(data => {
    return data.vitalType
  })
  const disabledTypes = _.intersection(VITAL_TYPES_LONG, currentTypes)
  const isDisabled = (vitalType, disabledTypes) => {
    if (isEditMode) return true
    return disabledTypes.indexOf(vitalType) > -1
  }
  const options = VITAL_TYPES_LONG.map(vitalType => {
    return { name: vitalType, value: vitalType, disabled: isDisabled(vitalType, disabledTypes) }
  })
  return options
}

export const getFormProps = ({ fieldsValue, onFieldsChange, isEditMode=false, VitalList, className, userRole='ADMIN', self }) => {
  const vitalType = _.get(fieldsValue, 'vitalType.value')
  const weightBasedOnFromFieldsValue = _.get(fieldsValue, 'weightBasedOn.value')
  const weightBasedOn = weightBasedOnFromFieldsValue ? weightBasedOnFromFieldsValue : schema.weightBasedOn.initialValue
  const vitalTypeElem = [
    {
      key: 'vitalType',
      label: 'Vital Type',
      type: 'radioButton',
      ...itemLayout,
      placeholder: '',
      initialValue: initData.vitalType,
      rules: [
        { required: isFieldsRequired.vitalType, message: 'Vital Type is required' }
      ],
      col: { span: 20, offset: 0 },
      option: getOptions(initData.vitalType, VitalList, isEditMode),
      // onChange: (a, b) => {
      //   try {
      //     self.baseForm._form.setFields({
      //       schedule: {
      //         errors: null
      //       }
      //     })
      //   } catch (err) {
      //     console.log(err)
      //   }
      // }
    }
  ]
  const weightBasedOnElem = getWeightBasedOn(vitalType)
  const thresholdRangeElem = getThresholdRange(vitalType, onFieldsChange, weightBasedOn, userRole);
  const otherElem = [
    [
      {
        key: 'schedule',
        label: 'Select Patient Self-Monitoring Schedule *',
        type: 'timePickerBoxGroup',
        full: true,
        vitalType,
        slotType: vitalType === 'Blood Glucose' ? 'meal' : 'time',
        // frequency: vitalType === 'Weight' ? 'slot' : (vitalType === 'Blood Glucose' ? false : true),
        frequency: vitalType === 'Weight' ? _.get(fieldsValue, 'schedule.value.frequency', 'slot') : true,
        // frequency: vitalType === 'Blood Glucose' ? false : true,
        ...itemLayout,
        initialValue: '',
        showInitialValue: isEditMode,
        onFieldsChange
      }
    ],
    [
      {
        key: 'description',
        label: 'Description',
        type: 'text',
        ...itemLayout,
        placeholder: 'Add description here',
        initialValue: initData.description,
        col: { span: 15, offset: 0 }
      }
    ]
  ]

  if(vitalType === 'Temperature'){
    if(!fieldsValue.TM){
      fieldsValue.TM = {
        dirty:false,
        errors:undefined,
        name:'TM',
        value: schema['TM'].initialValue
      }
    }
  }

  if(vitalType === 'Blood Oxygen'){
    if(!fieldsValue.PO){
      fieldsValue.PO = {
        dirty:false,
        errors:undefined,
        name:'PO',
        value: schema['PO'].initialValue
      }
    }
  }


  if(vitalType === "Blood Pressure"){
      if(!fieldsValue.systolic){
          fieldsValue.systolic = {
            dirty:false,
            errors:undefined,
            name:'systolic',
            value: schema['systolic'].initialValue
          }
      }
      if(!fieldsValue.diastolic){
          fieldsValue.diastolic = {
              dirty:false,
              errors:undefined,
              name:'diastolic',
              value: schema['diastolic'].initialValue
          }
      }
  }

  if(vitalType === "Blood Glucose"){
      if(!fieldsValue.BGBeforeMeal){
          fieldsValue.BGBeforeMeal = {
              dirty:false,
              errors:undefined,
              name:'BGBeforeMeal',
              touched:true,
              value: schema['BGBeforeMeal'].initialValue
          }
      }
      if(!fieldsValue.BGAfterMeal){
          fieldsValue.BGAfterMeal = {
              dirty:false,
              errors:undefined,
              name:'BGAfterMeal',
              touched:true,
              value: schema['BGAfterMeal'].initialValue
          }
      }
  }

  if (vitalType === 'Weight') {
    if(!fieldsValue.weightBasedOn) {
      fieldsValue.weightBasedOn = {
        dirty:false,
        errors:undefined,
        name:'weightBasedOn',
        validating: false,
        value: schema['weightBasedOn'].initialValue
      }
    }
    if(!fieldsValue.HS) {
      fieldsValue.HS = {
        dirty:false,
        errors:undefined,
        name:'HS',
        validating: false,
        value: schema['HS'].initialValue
      }
    } else {
      let value = fieldsValue.HS.value;
      if(weightBasedOn !== 'Baseline'){
        _.set(value, '4', null);
      } else {
        value = value[4] ? value : _.get(thresholdRangeElem, '1.0.initialValue');
      }
      fieldsValue.HS = {
        ...fieldsValue.HS,
        value
      }
    }
  }

  const itemList = [
    vitalTypeElem,
    weightBasedOnElem,
    ...thresholdRangeElem,
    ...otherElem
  ]
  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    className,
    itemList,
  }
}
