const MEAL_TYPES = ['Breakfast', 'Lunch', 'Dinner', 'Snacks'];

const columnMap = [
  {
    title: 'Meal',
    dataIndex: 'meal',
    width: '12%',
    render: text => _.capitalize(text)
  },
  {
    title: 'Time Range',
    dataIndex: 'timeRange',
    width: '15%',
    editable: true,
    placeholder: 'eg. 7-9 am'
  },
  {
    title: 'Food Types & Amount',
    dataIndex: 'foodTypeAmount',
    editable: true,
    placeholder: 'eg. Banana, cereal (1 cup), 2% milk ...'
  },
  {
    title: 'Meal Frequency',
    dataIndex: 'mealFreq',
    width: '17%',
    editable: true,
    placeholder: 'eg. 4x/wk'
  },
  {
    title: 'Skip Frequency',
    dataIndex: 'skipFreq',
    width: '17%',
    editable: true,
    placeholder: 'eg. 2x/wk'
  },
]

const frequencyRanges = [
  { key: 'WEEK', value: 'Week' },
  { key: 'MONTH', value: 'Month' },
];

// order of options affect where options display, order per Design
// current order: from left to right
// e.g
// None   Exercise   Low sodium   Whole 30
// Atkins ...
const previousDietOptions = [
  { label: 'None', value: 'NONE' },
  { label: 'Exercise', value: 'EXERCISE' },
  { label: 'Low sodium diet', value: 'LOW_SODIUM' },
  { label: 'Whole 30 diet', value: 'WHOLE_30' },
  { label: 'Atkins diet', value: 'ATKINS' },
  { label: 'Calorie Restriction', value: 'CALORIE_RESTRICTION' },
  { label: 'Low sugar', value: 'LOW_SUGAR' },
  { label: 'Weight watchers diet', value: 'WEIGHT_WATCHERS' },
  { label: 'Blood type diet', value: 'BLOOD_TYPE' },
  { label: 'Diet pills', value: 'DIET_PILLS' },
  { label: 'Low carb diet', value: 'LOW_CARB' },
  { label: 'South beach diet', value: 'SOUTH_BEACH' },
  { label: 'Keto diet', value: 'KETO' },
  { label: 'High protein diet', value: 'HIGH_PROTEIN' },
  { label: 'Low fat diet', value: 'LOW_FAT' },
];

const nutritionUnderstandingOptions = [
  'Patient is unaware of most foods that raise measurement levels.',
  'Patient is aware of most foods that raise measurement levels, but unaware of how to balance meals.',
  'Patient is clearly aware of foods that affect their health status'
];

export {
  MEAL_TYPES,
  columnMap,
  frequencyRanges,
  previousDietOptions,
  nutritionUnderstandingOptions,
}

