import ProcessBarComponent from './ProcessBarComponent';
import MinIndicatorComponent from './MinIndicatorComponent';
import TimeSpentReadingComponent from './TimeSpentReadingComponent';
import PatientStarComponent from './PatientStarComponent';
import TimeSpentListComponent from './TimeSpentListComponent';
import TimeSpentComponent from './TimeSpentComponent';
export {
    ProcessBarComponent,
    MinIndicatorComponent,
    TimeSpentReadingComponent,
    PatientStarComponent,
    TimeSpentListComponent,
    TimeSpentComponent
}
