import gql from 'graphql-tag'

export default gql`
  query iotDeviceListAndLoanDevice($memberId: EID, $deviceStatusFilter: DeviceStatusFilterEnum) {
    iotDeviceList(filters:{ memberId: $memberId, deviceStatusFilter:$deviceStatusFilter }) {
      data {
        id
        deviceId
        position
        member {
          profile {
            fullName
          }
        }
        config {
          deviceId
          volume
          firmwareVersion
          language
          lastSeenAt
          battery
        }
        createdAt
        updatedAt
        isActive
        accessedAt
        quittedAt    
      }
    }
    getLoanDevice(memberId: $memberId) {
      deviceId
      serialNumber
      osVersion
      appVersion
      status
      member {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;