import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { message } from 'antd';
import updateCellularDevices from '../api/updateCellularDevices';
import { CellularDevicesHelpers } from '../helpers';
import getCellularDevices from '../api/getCellularDevices';

const fetchCellularDevicesInfo = async (patientId, cb) => {
  const res = await getCellularDevices({ patientId });
  if (!res) {
    message.error('Failed to load CellularDevices status');
    return;
  }
  cb(res);
};

export const CellularDevicesContext = createContext();

export const useCellularDevicesContext = () => {
  const cellularDevicesContextValue = useContext(CellularDevicesContext);
  return cellularDevicesContextValue || {};
};

export const CellularDevicesContextProvider = ({
  patientId,
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bpCellularDeviceInfo, setBPCellularDeviceInfo] = useState();
  const [bgCellularDeviceInfo, setBGCellularDeviceInfo] = useState();

  const handleFetchCellularDevicesInfo = useCallback(() => {
    setIsLoading(true);
    fetchCellularDevicesInfo(patientId, (res) => {
      const devices = res.devices || [];
      const devicesGroupedByType = _.groupBy(devices, 'deviceType');
      const BPDevice = devicesGroupedByType[CellularDevicesHelpers.DEVICE_TYPE_ENUM.BP];
      const BGDevice = devicesGroupedByType[CellularDevicesHelpers.DEVICE_TYPE_ENUM.BG];
      if (BPDevice && BPDevice.length) {
        setBPCellularDeviceInfo(BPDevice[0]);
      }
      if (BGDevice && BGDevice.length) {
        setBGCellularDeviceInfo(BGDevice[0]);
      }
      setIsLoading(false);
    });
  }, [patientId]);

  const handleUpdateDevices = (
    device
  ) => {
    return updateCellularDevices({
      patientId,
      devices: [device],
    });
  };

  const handleUnassignBP = async () => {
    await handleUpdateDevices({
      deviceType: CellularDevicesHelpers.DEVICE_TYPE_ENUM.BP,
      isActive: false,
    });
    handleFetchCellularDevicesInfo();
  };

  const handleUnassignBG = async () => {
    await handleUpdateDevices({
      deviceType: CellularDevicesHelpers.DEVICE_TYPE_ENUM.BG,
      isActive: false,

    });
    handleFetchCellularDevicesInfo();
  };

  const handleAssignDevice = async (values) => {
    const deviceType = CellularDevicesHelpers.BRAND_TO_DEVICE_TYPE_MAP[values.brand];
    const device = {
      deviceId: values.deviceId,
      brand: values.brand,
      language: values.language,
      deviceType,
      isActive: true,
    };
    await handleUpdateDevices(device);
    handleFetchCellularDevicesInfo();
  };

  const getDeviceInfo = (
    info,
  ) => {
    if (!info || !info.isActive) return undefined;
    return info;
  };


  useEffect(() => {
    handleFetchCellularDevicesInfo();
  }, []);

  const cellularDevicesContextValue = useMemo(() => ({
    isLoading,
    bpCellularDeviceInfo: getDeviceInfo(bpCellularDeviceInfo),
    bgCellularDeviceInfo: getDeviceInfo(bgCellularDeviceInfo),
    cellularDevices: [
      getDeviceInfo(bpCellularDeviceInfo),
      getDeviceInfo(bgCellularDeviceInfo),
    ].filter((info) => !!info),
    handleUnassignBP,
    handleUnassignBG,
    handleAssignDevice,
    refetch: handleFetchCellularDevicesInfo,
  }), [
    isLoading,
    bpCellularDeviceInfo,
    bgCellularDeviceInfo,
    handleUnassignBP,
    handleUnassignBG,
    handleAssignDevice,
    handleFetchCellularDevicesInfo,
  ]);


  return (
    <CellularDevicesContext.Provider value={cellularDevicesContextValue}>
      {children}
    </CellularDevicesContext.Provider>
  );
};
