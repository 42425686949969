import React from 'react';
import { Button, Modal, Select } from 'antd';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import VitalsSummaryPrintout from '../../Print/component/VitalsSummaryPrintout';
const { Option } = Select;

const OPTIONS = [
  { value: 'Recent 1 week', period: { amount: 1, unit: 'weeks' }},
  { value: 'Recent 2 weeks', period: { amount: 2, unit: 'weeks' }},
  { value: 'Recent 1 month', period: { amount: 1, unit: 'months' }},
  { value: 'Recent 3 months', period: { amount: 3, unit: 'months' }},
  { value: 'Recent 6 months', period: { amount: 6, unit: 'months' }},
];

class VitalsSummaryPrintoutModal extends React.Component {
  constructor(props) {
    super(props);
    const defaultOption = OPTIONS[0];
    this.componentRef = null;
    this.state = {
      showModal: false,
      selectedPeriodValue: defaultOption.value,
      fromDate: this.getFromDate(defaultOption),
      toDate: moment().endOf('day').toDate().getTime(),
    };
  }

  getFromDate = (option) => {
    return moment().add(-option.period.amount, option.period.unit).startOf('day').toDate().getTime();
  }

  showModal = () => {
    this.setState({ showModal: true });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  handleChange = (index) => {
    console.log(index);
    this.setState({ selectedPeriodValue: OPTIONS[index].value, fromDate: this.getFromDate(OPTIONS[index]) });
  }

  renderPrintButton = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" id="vitalPrintoutBtn">Print</Button>
      </div>
    );
  }

  render() {
    const { showModal, selectedPeriodValue, fromDate, toDate } = this.state;
    const { enrolledProgramId, patientId, user, currentProgram } = this.props;
    return (
      <React.Fragment>
        <Modal visible={showModal} destroyOnClose footer={false} title="Vitals Summary Printout" onCancel={this.hideModal}>
          <h2>Choose a time range</h2>
          <Select value={selectedPeriodValue} onChange={this.handleChange} style={{ display: 'block', marginBottom: 10 }}>
            {OPTIONS.map((o, i) => <Option key={o.value} value={i}>{o.value}</Option>)}
          </Select>
          <ReactToPrint
            trigger={this.renderPrintButton}
            content={() => this.componentRef}
            pageStyle="@page { size: auto; margin: 0px !important;} @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }"
          />
          <VitalsSummaryPrintout 
            ref={el => (this.componentRef = el)}
            enrolledProgramId={enrolledProgramId}
            fromDate={fromDate}
            toDate={toDate}
            patientId={patientId}
            user={user}
            currentProgram={currentProgram}
          />
        </Modal>
        <Button onClick={this.showModal}>Print Vitals Data</Button>
      </React.Fragment>
    );
  }
}

export default VitalsSummaryPrintoutModal;
