import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { message } from 'ihcomponent'
import ResetPasswordComponent from '../components/ResetPasswordComponent'
import resetPassword from 'modulesAll/graphql/resetPassword'
import actions from '../actions/ResetPassword'
import { browserHistory } from 'react-router'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import get from 'lodash/get'
import { GQLHelper, getParameterByName } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class ResetPasswordContainer extends Component {
  static displayName = 'ResetPasswordContainer'

  submit = async ({newPass}) => {
    const { location, mutate, setSubmitting, openErrorModal } = this.props
    const token = getParameterByName('token');

    const variables = {
      newPass,
      token: get(location, 'query.token') || token,
    }

    try {
      setSubmitting(true);
      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'),
        loginId: variables.identification,
        response: res
      })
      message.success('Your password has been set successfully.')
      browserHistory.push('/login')
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'),
        loginId: variables.identification,
        response: err,
        success: false,
      })
      openErrorModal(GQLHelper.formatError(err))
    }
    finally {
      setSubmitting(false);
    }
  }

  render() {
    const props = {
      ...this.props,
      submit: this.submit
    }

    return (
      <ResetPasswordComponent {...props} />
    )
  }
}

const mapState = ({ ResetPassword }, ownProps) => {
  return {
    ...ResetPassword
  }
}

const mapDispatch = {
  ...actions,
  openErrorModal
}

ResetPasswordContainer.propTypes = {
  submit: PropTypes.func,
  handleFieldsChange: PropTypes.func,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  username: PropTypes.string,
  password: PropTypes.string
}

export default compose(
  withApollo,
  graphql(resetPassword),
  connect(mapState, mapDispatch),
)(ResetPasswordContainer)
