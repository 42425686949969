import {
    React, IHTable
} from 'ihcomponent'
import {createContainer, SuperContainer, DataHelper} from '../../../../lib/utils'
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import action from '../actions/main'
// import UserClass from '../../../graphql/class/User'
import I18N from '../../../I18N'

const Component = class extends SuperContainer{
    static displayName = 'patient/programTab/containers/ProgramTable'

    renderMain(){
        const p = this.getProps()
        return <IHTable {...p} />
    }

    getProps(){
        const {program, dispatch,setSelectedTeam} = this.props
        const dataSource = _.map(this.props.list, (item)=> {
            return new CareTeamClass(item)
        })

        return {
            rowKey : doc => doc.key,
            columns : [
                {
                    title : 'Name',
                    key : 'name',
                    render : (t, doc) => {
                        return doc._get('name')
                    }
                },
                {
                    title : I18N.get('provider.label'),
                    key : 'doctor',
                    render : (t, doc) => {
                        return _.map(doc.format('allDoctors'), (u, i)=>{
                            return <div key={i}>{u._get('profile.fullName')}</div>
                        })
                        // return _.map(_.get(doc,'variables.members'), (u, i) => {
                        //     const eachUser = new UserClass(u.user);
                        //     const occupation = eachUser._getRole();
                        //     return occupation === 'Doctor' ? <div key={i}>{eachUser._get('profile.fullName')}</div> : ''
                        // })
                    }
                },
                {
                    title : 'RD',
                    key : 'rd',
                    render : (t, doc) => {
                        return _.map(doc.format('allNurses'), (u, i)=>{
                            return <div key={i}>{u._get('profile.fullName')}</div>
                        })
                        // return _.map(_.get(doc,'variables.members'), (u, i) => {
                        //     const eachUser = new UserClass(u.user);
                        //     const occupation = eachUser._getRole();
                        //     return occupation === 'Nurse' ? <div key={i}>{eachUser._get('profile.fullName')}</div> : ''
                        // })
                    }
                },
                {
                    title : 'HC',
                    key : 'hc',
                    render : (t, doc) => {
                        return _.map(doc.format('allHealthCoaches'), (u, i)=>{
                            return <div key={i}>{u._get('profile.fullName')}</div>
                        })
                    }
                },
                {
                    title : 'CA',
                    key : 'ca',
                    render : (t, doc) => {
                        return _.map(doc.format('allMedicalAssistants'), (u, i)=>{
                            return <div key={i}>{u._get('profile.fullName')}</div>
                        })
                        // return _.map(_.get(doc,'variables.members'), (u, i) => {
                        //     const eachUser = new UserClass(u.user);
                        //     const occupation = eachUser._getRole();
                        //     return occupation === 'Medical Assistant' ? <div key={i}>{eachUser._get('profile.fullName')}</div> : ''
                        // })
                    }
                },
                // {
                //     title : 'Members',
                //     key : 'Members',
                //     render : (t, doc)=>{
                //         return _.map(_.get(doc,'variables.members'), (u, i)=>{
                //           const eachUser = new UserClass(u.user);
                //           return <div key={i}>{eachUser._get('profile.fullName')} ({u.primary && 'Primary '}{eachUser._getRole()})</div>
                //         })
                //     }
                // },
            ],
            dataSource,
            rowSelection : {
                type : 'radio',
                selectedRowKeys : program.selectCareteam.index,
                onSelect : (doc, selected, rows)=>{
                  const key = [doc.key];
                  let teamNameArray = [];
                  let teamInfo = [];
                  if(rows.length > 0) {
                    teamNameArray = _.map(rows, 'variables.name');
                    teamInfo = _.map(rows, 'variables');
                  }
                  if(setSelectedTeam) {
                    const teamProviderIds = _.map(doc.format('allDoctors'), u => u._get('id'));
                    const consentProviderName = doc.format('consentProviderName');
                    setSelectedTeam(key, consentProviderName, teamProviderIds);
                  } // in enrollment popup
                  dispatch(action.setSelectCareteam(key, key, teamNameArray, teamInfo))
                }
            }
        }
    }

    runOnceAfterDataLoading(){
        if(this.props.currentProgram){
            const team = this.props.currentProgram.team || []
            const tid = _.map(team, (t)=>t.id)
            const tname = _.map(team, (t)=>t.name)
            const teamInfo = _.map(team, (t)=> t)

            _.delay(()=>{
                this.props.dispatch(action.setSelectCareteam(tid, tid, tname, teamInfo))
            }, 200)

        }
    }

    shouldComponentUpdate(np, nextState){
        if(np.currentProgram && (!this.props.currentProgram || np.currentProgram.id !== this.props.currentProgram.id)){
            const team = np.currentProgram.team || []
            const tid = _.map(team, (t)=>t.id)
            const tname = _.map(team, (t)=>t.name)
            const teamInfo = _.map(team, (t)=> t)

            _.delay(()=>{
                this.props.dispatch(action.setSelectCareteam(tid, tid, tname, teamInfo))
            }, 200)

        }
        return true
    }
}

export default createContainer((Component), ({patient}, ownProps)=>{
    const currentUser = DataHelper.getCurrentUser()
    const program = ownProps.currentProgram || patient.program.selectedProgram
    let team = currentUser.team || []
    // don't filter teams with healthCondition
    // if(program){
    //     team = _.filter(team, (t)=>t.healthCondition === program.healthCondition)
    // }

    return {
        list : team,
        loading : !program,
        ...patient
    }
})
