import Result from './Result'
import AssessmentFilled from './AssessmentFilled'

const lightEnrolledResult = `
  id
  schedule {
    calendar
    target
    start
    end
    beforeMeal
    mealType
  }
  repeat
  refId
  careStatus
  taskStatus
  severity
  user {
    id
  }
  enrolledProgram {
    id
  }
  type
  date
  ${Result}
  threshold {
    measure
  }
  assessmentFilled {
    ${AssessmentFilled}
  }
`

export default lightEnrolledResult
