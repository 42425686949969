import {
    React, IHButton, IHUpload, IHIcon, _
} from 'ihcomponent'
import config from 'libModule/config'
import PropTypes from 'prop-types'
export default class extends React.Component{
    static displayName = 'common/components/UploadForm'

    static propTypes = {
        file : PropTypes.object,
        printButton : PropTypes.object.isRequired,
        title : PropTypes.string,
        reUploadButton : PropTypes.object.isRequired,
        uploadButton : PropTypes.object.isRequired,

        uploadEndCallback : PropTypes.func,
        uploadErrorCallback : PropTypes.func
    }

    render(){
        return (
            <div className="vsm-common-upload-form" style={this.props.style||{}}>
                <dd className="v-header">
                    <p>{this.props.title}</p>
                    {this.getPrintoutButton()}
                </dd>
                <dd className="v-body">
                    {this.getBody()}
                </dd>
                <dd className="v-footer">
                    {this.getReUploadButton()}
                </dd>
            </div>
        )
    }

    getPrintoutButton(){
        const btn = this.props.printButton
        const p = {
            label : btn.title,
            type : 'default',
            size : 'small',
            disabled : !this.props.file,
            style : {
                width : 'auto',
                float : 'right'
            },
            onClick : ()=>{
                btn.onClick && btn.onClick()
            }
        }

        return (
            <IHButton {...p} />
        )
    }

    getPreviewFile(){
        const file = this.props.file
        // console.log(file)
        if(file.type === 'image'){
            return this.previewImage(file)
        }
        else if(file.type === 'pdf'){
            return this.previewPDFIframe(file)
        }
    }

    previewImage(file){
        return <img style={{maxWidth:'100%', maxHeight:'100%'}} src={file.url} />
    }


    previewPDFIframe(file){

        const iframeStyle = {
            width : '100%',
            height : '100%',
            border : 'none'
        }

        //const src = 'http://mozilla.github.io/pdf.js/web/viewer.html?file='+file.url
        const param = file.url
        // console.log(param)

        const startRenderPDF = (bindary)=>{
            const tar = document.getElementById('vsm-pdf-ifm')
            if(tar.contentWindow.renderPDF){
                tar.contentWindow.renderPDF(bindary)
            }
            else{
                tar.onload = function(){
                    tar.contentWindow.renderPDF(bindary)
                }
            }
        }



        _.delay(()=>{


        }, 100)

        return <iframe id="vsm-pdf-ifm" src={param} style={iframeStyle} />
    }


    getBody(){
        if(this.props.file){
            return this.getPreviewFile()
        }

        const self = this
        const btn = this.props.uploadButton
        const p = {
            label : btn.title,
            type : 'primary',
            size : 'small'
        }
        const uploadProps = {
            name : 'uploadfile',
            action : config.uploadFileUrl,
            showUploadList : false,
            headers : {

            },
            onChange : (info)=>{
                if(info.file.status === 'uploading'){
                    console.log('uploading....')
                }
                else if(info.file.status === 'done'){
                    //self.props.dispatch('url', info.file.response.Location)
                    console.log(info.file)
                    const file = {
                        type : _.startsWith(info.file.type, 'image')?'image':(info.file.type==='application/pdf'?'pdf':'nil'),
                        url : info.file.response.Location
                    }
                    this.props.uploadEndCallback && this.props.uploadEndCallback(file)
                }
                else if (info.file.status === 'error') {
                    this.props.uploadErrorCallback && this.props.uploadErrorCallback(info.file)
                }
            }
        }

        return (
            <IHUpload {...uploadProps}>
                <p className="v-info">{this.props.contentTitle}</p>
                <IHButton {...p} />
            </IHUpload>
        )
    }

    getDraggerBody(){
        const props = {
          name: 'file',
          showUploadList: false,
          action: '/upload.do',
          onChange(info) {
            if (info.file.status !== 'uploading') {
              console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
              //message.success(`${info.file.name} file uploaded successfully.`);
            } else if (info.file.status === 'error') {
              //message.error(`${info.file.name} file upload failed.`);
            }
          },
        };


        return (
            <div style={{width:'100%',height:'100%'}}>
              <IHUpload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <IHIcon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
              </IHUpload.Dragger>
            </div>
        )
    }

    getReUploadButton(){
        if(!this.props.file){
            return null
        }

        const self = this
        const btn = this.props.reUploadButton
        const p = {
            type : 'primary',
            label : btn.title
        }

        const uploadProps = {
            name : 'uploadfile',
            action : config.uploadFileUrl,
            showUploadList : false,
            headers : {

            },
            // customRequest(param){
            //     const file = param.file
            //     console.log(file)
            // },
            beforeUpload(file){
                let f = new FileReader()
                f.onload = function(){
                    let bindary = new Uint8Array(this.result)
                    console.log(this)
                    // self.setState({
                    //     file : {
                    //         type : 'pdf',
                    //         url : bindary
                    //     }
                    // })
                }

                f.readAsArrayBuffer(file);

                return false;
            },
            onChange(info){

            }
        }

        return (
            <IHUpload {...uploadProps}>
                <IHButton {...p} />
            </IHUpload>
        )
    }


}
