import { COMMON_HELPERS } from 'libModule/helpers/common-helpers'
import {API} from "../actions/api";
import mesNotificationType, { autoMsgSubtypes } from "../constants/messageTypes";
import markAsUnreadRoles from "../constants/markAsUnreadRoles";
import roleMap from "../../layout/constants/roleMap";
import { decryptRole } from 'libModule/utils';
import { MAX_MSG_PER_CHANNEL, COMPLEXITY_ENUM } from 'libModule/constants';
import VideoChatAPI from "../../VideoChat/API";
import chatFilters from './chatFilters';
import reduxStore from 'libModule/reduxStore';
import chatActions from '../actions';

const MAX_CHANNELS_PER_BATCH = 299;
const DEFAULT_PAGE_SIZE = 15;

const getListOfChannels = () => {
  const channels = _.get(reduxStore.getState(), 'chat.main.channels') || {};
  return channels;
}

// @return string
const getChannelNameByPatientId = (patientId) => {
  if(!patientId) {
    return '';
  }
  const channels = getListOfChannels();
  const channelName = _.findKey(channels, { patientId });
  return channelName;
};

const getEncodedUserId = (userId) => COMMON_HELPERS.checkIfEncoded(userId) ? userId : btoa(`accounts:${userId}`);
const getDecodedUserId = (userId) => COMMON_HELPERS.checkIfEncoded(userId) ? _.split(atob(userId), ':')[1] : userId;

const getCurrentUserTokenAndTeamIds = ()=>{
    let sessionToken = sessionStorage.getItem('authToken') || '';
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let teamsId = _.get(currentUser,'team',[]).map(t=>transferId(t.id).userMapId);
    return  { teamsId,sessionToken };
}
const transferId = (userId)=>{
    const isEncoded = COMMON_HELPERS.checkIfEncoded(userId);
    const userProfileId = isEncoded ? userId : btoa(`accounts:${userId}`);
    const userMapId = isEncoded ? atob(userId).split(':')[1] : userId;
    return { userProfileId,userMapId } ;
}

const shouldIgnoreCounter = (channel, hasTagMessage) => {
  let shouldIgnore = false;
  
  const currentUserRole = decryptRole();
  const channelId = _.get(channel, 'id');
  const patientId = _.split(channelId, '-')[1];
  try {
    switch(roleMap[currentUserRole]) {
      case 'HC':
        if(!patientId) break;
        if(hasTagMessage) break; // regardless of complexity level
        const userMapData = _.get(reduxStore.getState(), `chat.main.userMap.${patientId}`);
        const patientComplexityLevel = _.get(userMapData, 'patientComplexity.level');
        if(patientComplexityLevel !== COMPLEXITY_ENUM.NON_COMPLEX)
          shouldIgnore = true;
        break;
      default:
        break;
    }
  } catch (error) {
    console.error('error verifyUnreadCounter', error);
  } finally {
    return shouldIgnore;
  }
}

// last message that can be visible to user
const getLastMessageInfo = (messages = []) => {
  let curHistory = [...messages],
      lastIndex = curHistory.length -1;
  while (lastIndex >= 0) {
    const msgEntry = _.get(curHistory, `${lastIndex}.entry`);
    if(!mesNotificationType[msgEntry.type] || chatFilters.shouldExcludeMessage(msgEntry))
      lastIndex--;
    else
      break;
  }

  let lastMsgText = '',
      lastMsgTime = '',
      lastMsgSender = '';

  if(lastIndex > -1) {
    lastMsgText = _.get(curHistory, `${lastIndex}.entry.text`);
    lastMsgTime = _.get(curHistory, `${lastIndex}.timetoken`);
    lastMsgSender = _.get(curHistory, `${lastIndex}.entry.displayName`, 'Notification');
  }

  return { lastMsgText, lastMsgTime, lastMsgSender };
}

const handleUnreadAndLastMsg = (messages, channel)=> {
  const currentUserRole = decryptRole();
  let curHistory = [...messages];
  let historyLength = curHistory.length;
  let hasOfflineMessage = channel.teamUnread > 0;
  let hasTagMessage = false;

  const listOfReadableMessages = _.filter(curHistory, (msg) => {
    const { type, subType } = _.get(msg, 'entry') || {};
    return mesNotificationType[type]
          && !_.includes(autoMsgSubtypes, subType);
  });

  const lastReadableMessage = _.last(listOfReadableMessages) || {};
  const lastMsgText = _.get(lastReadableMessage, 'entry.text');
  const lastMsgTime = lastReadableMessage.timetoken || 0;
  const lastMsgSender = _.get(lastReadableMessage, 'entry.displayName', 'Notification');

  hasTagMessage = getTagMessageIndex(channel) > -1; 
  const unreadCountFromOffLine = markAsUnreadRoles.includes(roleMap[currentUserRole]) ? (~~hasOfflineMessage + ~~hasTagMessage) : 0;

  return {
    ..._.omit(channel, ['messages']),
    unreadCountFromOffLine,
    shouldIgnoreCounter: shouldIgnoreCounter(channel, hasTagMessage),
    hasTagMessage,
    lastMsg: _.get(curHistory, historyLength - 1),
    lastMsgText,
    lastMsgTime,
    lastMsgSender,
  };
}

const getTimestampOfLastTextMsg = (messages)=> {
    let lastMessageTimestamp = null;
    // since first message in array is oldest, get last msg timestamp starting from index 0
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].entry.type === 'text') {
            lastMessageTimestamp = messages[i].timetoken;
            break;
        }
    }
    return lastMessageTimestamp;
}

// messages should be sorted list by timetoken ASC and before any filtering
// used as time point to fetch more message
const getFromTimestamp = (messages) => {
  if(!Array.isArray(messages) || !messages.length)
    return 0;
  
  const fromTimestamp = (_.first(messages) || {}).timetoken || "0";
  return fromTimestamp;
};

const updateUserMap = (memberId, newData = {}, shouldUpdateUnreadCounter = false) => {
  const reduxChat = _.get(reduxStore.getState(), 'chat.main');
  try {
    const newUserMap = {...(_.get(reduxChat, 'userMap') || {})};
    const patientId = memberId && (_.split(atob(memberId), ':')[1] || atob(memberId));
    if(patientId && newUserMap[patientId]) {
      newUserMap[patientId] = {...newUserMap[patientId], ...newData};
      reduxStore.dispatch(chatActions.setUserMap(newUserMap));
      // update userMap could affect red dot flag
      const channels = _.get(reduxChat, 'channels') || [];
      const channelId = _.get(reduxChat, `patientIdToChannel.${patientId}`);       
      const channel =  _.get(channels, channelId);
      if(shouldUpdateUnreadCounter && !_.isEmpty(channel)) {
        const { tagMessageOffset, tagMessageOffsetFrom, history } = channel;
        const channelInfoFromLastMessage = handleUnreadAndLastMsg(history, { 
          ...channel,
          id: channelId, 
          tagMessageOffset, 
          tagMessageOffsetFrom, 
          history,
        });
        channelInfoFromLastMessage.isListening = false; 
        reduxStore.dispatch(chatActions.updateUnreadCounter(channelId, channelInfoFromLastMessage));
      }
    }
  } catch(error) {
    console.error('error updateUserMap', error);
  }
}

const getUserMapObj = userData => {
  const patientComplexity = _.get(userData, 'patientComplexity');
  const userObj = {
    firstName: _.get(userData, 'profile.firstName', ''),
    lastName: _.get(userData, 'profile.lastName', ''),
    avatar: _.get(userData, 'profile.avatar.link', '/image/default_avator.png'),
  };
  if(patientComplexity) userObj.patientComplexity = patientComplexity;
  return userObj;
}

const setUserMap = (chatInfo,isChs)=> {
    const userMap = {};
    isChs ? _.map(chatInfo,c=>c.data.user&&_.set(userMap,transferId(c.data.user.id).userMapId,getUserMapObj(c.data.user))):
        chatInfo.channelGroup.channels.forEach(ch => {
            if (ch.member && ch.member.id) {
                const decodedId = atob(ch.member.id).split(':')[1];
                userMap[decodedId] = getUserMapObj(ch.member);
            }
        });

    reduxStore.dispatch(chatActions.setUserMap(userMap));
}

// resp: Object of channels
// channelsArr: Array of channelId
const handleBatchHistory = (resp, channelsArr, isOngoing, isFetchingMoreChannels) => {
    const { useChs, channels: addedChannels = {} } = _.get(reduxStore.getState(), 'chat.main');
    //add channel messages by chunk
    let batchChatObject = {};
    let batchMsgObj = {};
    let isBatch = true;
    if (resp && Object.keys(resp).length > 0) {
      channelsArr.forEach(channelId => {
        // add chat history for ea channel
        //get patient id or channel name according to API(chs/pubnub)
        let key = API.handleApiChange(channelId, useChs);

        let patientId = useChs ? key : key.split('-')[1];
        const channel = resp[key] || {};
        let messages = [];
        let tagMessageOffset = channel.tagMessageOffset || -1;
        let tagMessageOffsetFrom = null;
        if (!_.isEmpty(channel)) {
          messages = channel.messages.filter(val => {
            // if (typeof val.message === 'object' && val.message.member === '') { console.log('', val); }
            if (typeof val === 'object') {//val.message.type !== 'ACK') { val.message.publisher
              return val;
            } else {
              // if (typeof val.message === 'object') {
              //     console.log('message:', val.message.type, val.message.text);
              // }
              // if (typeof val.message === 'object' && val.message.type !== 'text') {
              //     console.log('ACK message format: ', val);
              // } else {
              //     console.log('Invalid message format: ', val);
              // }
            }
          }).map(val => genMessageObject(val, useChs));
          tagMessageOffsetFrom = (_.last(messages) || {}).timetoken;
          
          const channelHistory = _.get(addedChannels, `${channelId}.history`, []);
          messages = _.uniqBy([...messages, ...channelHistory], m => Number(m.timetoken));
          messages = _.sortBy(messages, 'timetoken');

          let lastMsgTS;

          if (messages.length > 0) {
              lastMsgTS = getTimestampOfLastTextMsg(messages);
          } else {
              lastMsgTS = 0;
          }

          const fromTimestamp = getFromTimestamp(messages);
          
          const messageCount = messages.length;
          const hasMoreMessage = messageCount >= MAX_MSG_PER_CHANNEL; // probably has more messages
          batchChatObject[channelId] = { patientId, messages, lastMsgTS, messageCount, hasMoreMessage, tagMessageOffset, tagMessageOffsetFrom, fromTimestamp };

        } else {
          batchChatObject[channelId] = { patientId, messages, messageCount: 0, hasMoreMessage: false, tagMessageOffset: -1 };
        }
        let msgObj = handleUnreadAndLastMsg(messages, { 
          ...channel,
          id: channelId, 
          tagMessageOffset,
          tagMessageOffsetFrom, 
          history: messages 
        });
        // flag to not increment counter channel if msg from fetchHistory
        msgObj.isListening = false;
        batchMsgObj[channelId] = msgObj;
      });
    }
    reduxStore.dispatch(chatActions.addChatHistory(batchChatObject, {}, {}, {}, {}, isBatch, {}, {},isOngoing));
    reduxStore.dispatch(chatActions.updateChannelInfo({}, batchMsgObj, isBatch, isFetchingMoreChannels));
    reduxStore.dispatch(chatActions.setIsFullyLoaded(true));
    //add message by chunk
}

const getChannelsAndHistoryWithCHS = async (paginatedChannelParams, otherOptions) => {
    const { shouldSetHasMoreChannels = true, isFetchingMoreChannels, isOngoing } = otherOptions || {};
    try {
      const data = (await API.getPaginatedChannels(paginatedChannelParams));
      const paginatedChannels = data.Channels;
      const { NextFromTimestamp,NextPageNumber } = data;
      if(shouldSetHasMoreChannels && paginatedChannels.length < DEFAULT_PAGE_SIZE) {
        reduxStore.dispatch(chatActions.setHasMoreChannels(false));
      }
      const patientIds = paginatedChannels.map(c=>c.split('-')[1]);
      let param = {
          patientIds: patientIds,
          count: MAX_MSG_PER_CHANNEL
        }
      const msgRes = await API.getChatHistoryFromChs(param);
      const map = parseChatChannelsResponse(msgRes, true);
  
      const promiseList =  _.map(patientIds,(c)=>VideoChatAPI.getPatientChatInfoWithComplexity({id:transferId(c).userProfileId }));
      let userChatInfo = await Promise.all(promiseList);
      setUserMap(userChatInfo, true);

      handleBatchHistory(map,paginatedChannels, isOngoing, isFetchingMoreChannels);
      reduxStore.dispatch(chatActions.setLastMSGTOfOrg('YWNjb3VudHM6NWFlNTM5YzkzN2M3NDAwMDE0YzM3MmM4',NextFromTimestamp,NextPageNumber));
    } catch (error) {
      console.error(error);
    } finally {
      return;
    }
}

const getTagMessageIndex = (selectedChannel, history) => {
  if(_.isEmpty(selectedChannel))
    return -1;

  const currentUserId = sessionStorage.getItem('currentUserId');
  const tagMessageOffset = _.get(selectedChannel, 'tagMessageOffset') || -1;
  const tagMessageOffsetFrom = _.get(selectedChannel, 'tagMessageOffsetFrom');
  const channelHistory = history && history.length ? history : _.get(selectedChannel, 'history', []);

  const firstTimeToken = (_.first(channelHistory) || {}).timetoken || 0;
  const lastTimeToken = (_.last(channelHistory) || {}).timetoken || 0;
  const isHistorySorted = Number(firstTimeToken) < Number(lastTimeToken); // true - ascending by timetoken

  let tagMessageOffsetFromIndex = _.findIndex(channelHistory, { timetoken: tagMessageOffsetFrom });
  if(tagMessageOffsetFromIndex > -1 && isHistorySorted) { // correct offset index
    tagMessageOffsetFromIndex = (channelHistory.length - 1) - tagMessageOffsetFromIndex;
  }
  if(tagMessageOffset > -1 && ((tagMessageOffsetFromIndex + 1) + tagMessageOffset) >= channelHistory.length) {
    return channelHistory.length + tagMessageOffset;
  }

  for(let msgIdx in channelHistory) {
    const { tag } = channelHistory[msgIdx] || {};
    const taggedtoEncodedId = btoa(`accounts:${_.get(tag, 'taggedto')}`);
    if(!_.isEmpty(tag) && tag.tagged && !tag.read && _.isEqual(taggedtoEncodedId, currentUserId)) {   
      return msgIdx;
    }
  }

  return -1;
};

export const checkShouldShowChatRedDot = (channelName) => {
  const channels = getListOfChannels();
  if(!channelName) {
    return false;
  }
  const selectedChannel = channels[channelName] || {};

  const hasUnread = selectedChannel.counter > 0;
  const shouldIgnoreRedDot = !!selectedChannel.shouldIgnoreCounter;

  const redDotFlag = JSON.parse(sessionStorage.getItem('notificationFreq')) === 'NOTIFY_ALWAYS';

  return !shouldIgnoreRedDot && redDotFlag && hasUnread;
}

const genMessageObject = (val, useChs) => {
  const msgObj = {
    timetoken: useChs ? val.timestamp : val.timetoken,
    entry: useChs ? val.payload : val.message
  };
  if(useChs) {
    msgObj.messageId = val.messageId;
    msgObj.tag = val.tag
  }

  return msgObj;
};

const parseChatChannelsResponse = (channels, useChs) => {
  let parsedData = {};
  if(useChs) {
    _.forEach(channels, channel => {
      if(channel.messages) {
        parsedData[channel.key] = { ...channel };
      }
    });
  } else {
    const channelIds = Object.keys(channels);
    _.forEach(channelIds, channelId => {
      const messages = [...channels[channelId]];
      parsedData[channelId] = { messages };
    });
  }
  return parsedData;
};

// ackType: string - default or markAsUnread
const sendACKMessage = (selectedChannel, channelInfo, ackType) => {
  if(!selectedChannel)
    return console.warn('warn sendACKMessage to no channel');
  
  const currentUserRole = decryptRole();
  const isChannelUnread = _.get(channelInfo, 'teamUnread', 0) > 0;
  let publisherPrefix, shouldSendACKMessage;
  
  switch(ackType) {
    case 'markAsUnread':
      publisherPrefix = 'unread';
      shouldSendACKMessage = true; // pre-checked before call this func
      break;
    default:
      // const lastMsgEntry = _.get(lastMsg, 'entry');
      publisherPrefix = 'team',
      shouldSendACKMessage = markAsUnreadRoles.includes(roleMap[currentUserRole]) 
                              && isChannelUnread;
                              // lastMsgEntry && ((lastMsgEntry.type!='ACK') || (lastMsgEntry.publisher.split('-')[0]!='team'));
  }
      
  const publisher = `${publisherPrefix}-${selectedChannel.split('-')[0]}`;
  if(shouldSendACKMessage) {
    API.publishMessage(selectedChannel, { publisher, type: 'ACK' });
  }
};

const fetchChatHistoryFromCHS = (
  patientId, 
  options,
) => {
  const { 
    params = {},
    toBottom = false,
    shouldUpdateUnread = true,
  } = options || {};

  let param = {
    patientIds: [patientId],
    count: MAX_MSG_PER_CHANNEL,
    ...params,
  }

  return API.getChatHistoryFromChs(param)
    .then(
      res => {
        const channels = getListOfChannels();
        const channel = res[0];
        const channelName = _.get(channel, 'messages.0.channel') || '';
        const messageCount = _.get(channel,'messages.length') || 0;
        const hasMoreMessage = messageCount == MAX_MSG_PER_CHANNEL;

        let messages = channel.messages.filter(val => {
          return typeof val === 'object';
        }).map(val => genMessageObject(val, true));
        const tagMessageOffset = channel.tagMessageOffset || -1;
        const tagMessageOffsetFrom = (_.last(messages) || {}).timetoken;

        const channelHistory = _.get(channels, `${channelName}.history`, []);
        messages = _.uniqBy([...messages, ...channelHistory], m => Number(m.timetoken));
        messages = _.sortBy(messages, 'timetoken');

        const fromTimestamp = getFromTimestamp(messages);

        reduxStore.dispatch(chatActions.addChatHistory(channelName, patientId, messages, null, messageCount, false, toBottom, hasMoreMessage, false, tagMessageOffset, tagMessageOffsetFrom, false, fromTimestamp));

        if(messageCount > 0 && shouldUpdateUnread){
          let msgObj = handleUnreadAndLastMsg(messages, { 
            ...channel,
            id: channelName, 
            tagMessageOffset, 
            tagMessageOffsetFrom, 
            history: messages, 
          });
          msgObj.isListening = false;
          reduxStore.dispatch(chatActions.updateUnreadCounter(channelName, msgObj));
        }
      }
  )
};

// const getLatestMsgTS = (channels)=>{
//   const chs = Object.keys(channels);
//   let latestMsgTimestamp  = new Date().getTime()*1e4;
//   for(const ch of chs){
//       for(const msg of channels[ch]) {
//           latestMsgTimestamp = Math.min(parseInt(msg.timestamp), (latestMsgTimestamp));
//       }
//   }
//   console.log(latestMsgTimestamp);
// };
export default {
    getChannelNameByPatientId,
    getEncodedUserId,
    getDecodedUserId,
    getCurrentUserTokenAndTeamIds,
    transferId,
    handleBatchHistory,
    getLastMessageInfo,
    handleUnreadAndLastMsg,
    getUserMapObj,
    setUserMap,
    getChannelsAndHistoryWithCHS,
    genMessageObject,
    parseChatChannelsResponse,
    getTagMessageIndex,
    sendACKMessage,
    getFromTimestamp,
    updateUserMap,
    fetchChatHistoryFromCHS
}