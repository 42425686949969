import React from 'react';
import {Form, Select, Row, Col, Button, Radio, Icon, Alert, Divider, Checkbox} from 'antd';
import { API } from '../../visit/query/index';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
const { Option } = Select;
const { createVisit, rescheduleVisit } = API;
import { message, IHLoading } from '../../../../../package/IHComponent';
import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import getLatestA1C from 'graphqlModule/getLatestA1C';
import VisitInputA1C from '../../visitNewWorkFlow/components/VisitInputA1C';
import VisitInputTypeAndProvider from '../../visitNewWorkFlow/components/VisitInputTypeAndProvider';
import VisitInputProviders from '../../visitNewWorkFlow/components/VisitInputProviders';
import VisitInputTime from '../../visitNewWorkFlow/components/VisitInputTime';
import VisitInputNote from '../../visitNewWorkFlow/components/VisitInputNote';
import getLoanDevice from "../../patient/loanDevices/query/getLoanDevice";
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import { getVisitRoles, getVisitTypeOptions } from 'libModule/utils';
import visitListMini from '../../graphql/visitListMini';
import { followUpVisitRange, DIABETES_ICD_CODES } from '../constant/followUp';
import PatientHealthCondition from '../../graphql/PatientHealthCondition';
import visitList from 'modulesAll/graphql/visitListWithEp';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import helper from "../../taskAssignment/helper";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import visitHelper from "../helpers/index";
import { COMPLEXITY_ENUM, VISIT_REQUIRE_ASSIGNEE } from '../../../lib/constants';
import { getPatientHiatusQuery } from '../../graphql/getHiatus';
import HiatusOverlapNote from '../../patient/hiatusStatus/components/OverlapNote';
import AssignToCTMessage from '../../assignCT/component/AssignToCTMessage';
import AssignToCTFormComponent from '../../assignCT/component/AssignToCTForm';
import { ENROLLED_STATUS } from '../../visitsPage/constants';
import { CT_ASSIGNEE_ROLES, ASSIGNEE_REMINDER_ROLE } from '../../utils/constants/role';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';

const { Item } = Form;

const followUpVisitTypeToSelectBasedOnType = (type)=>{
  let visitTypes;
   switch (type) {
     case 'TECH_ONBOARDING':
       visitTypes = ['CLINIC_FOLLOW_UP','INIT','FOLLOW_UP', 'ADDITIONAL', 'MNT', 'TECH'];
       break;
     default:
       visitTypes = ['CLINIC_FOLLOW_UP','FOLLOW_UP', 'ADDITIONAL', 'MNT', 'TECH'];
   }
  return _.pick(getVisitTypeOptions(), visitTypes);
}

const getFollowUpRange = (isDiabetic) => {
  if (isDiabetic) {
    return followUpVisitRange.withDiabetes;
  } else {
    return followUpVisitRange.withoutDiabetes;
  }
}

const hasDiabetes = (icdCodes) => {
  const codes = icdCodes.map(c => c.split('::')[1]);
  for (let i = 0; i < codes.length; i++) {
    const upperCaseCode = (codes[i] || '').toUpperCase();
    for (let j = 0; j < DIABETES_ICD_CODES.length; j++) {
      const { code, startWith } = DIABETES_ICD_CODES[j];
      if (startWith) {
        if (upperCaseCode.startsWith(code)) {
          return true;
        }
      } else {
        if (upperCaseCode === code) {
          return true;
        }
      }
    }
  }
  return false;
}

let FollowUpVisitComponent = class extends React.Component {
  static displayName = 'visitWorkList/component/FollowUpVisitComponent';
  constructor(props) {
    super(props);
    this.state = this.init(props);
  }
  parseAssignees = (obj)=>{
    const key = '__typename';
    const newObj = {};
    for( const k of Object.keys(obj||{})){
      if(k!==key){
        if(typeof obj[k]=='object'){
          newObj[k] = this.parseAssignees(obj[k]);
        }else {
          newObj[k] = obj[k];
        }
      }
    }
    return newObj;
  }
  init = (_props) => {
    const props = _props || this.props;
    const currentVisit = _.get(props, 'visit');
    const followUpVisit = _.get(currentVisit, 'associatedVisit', {});
    const patientAssignees = dataHelper.parsePatientAssignees(_.get(props, 'member.assignees'));
    const assignees = _.get(followUpVisit,'assignees') || patientAssignees;
    let visitStartAt = _.get(followUpVisit,'appointmentAt');
    let localAMOrPM = null;
    let remoteAMOrPM = null;
    let selectedHour = null;
    let assigneesAvailability = [];
    const ctAssignees =  _.get(props, 'member.assignees') || {};

    let selectedMin = '00';
    if( visitStartAt ) {
      let visitStartAtDate = new Date(visitStartAt);
      selectedHour = dataHelper.localToRemoteHour(visitStartAtDate.getHours());
      selectedMin = visitStartAtDate.getMinutes();
      if(selectedMin < 10) {
        selectedMin = '0' + selectedMin;
      }
      localAMOrPM =  visitStartAtDate.getHours() >= 12 ? 'PM':'AM';
      remoteAMOrPM = selectedHour >= 12 ? 'PM':'AM';
    }

    return {
      selectedHour:selectedHour > 12 ? selectedHour - 12 : selectedHour ,
      selectedMin,
      localAMOrPM,
      remoteAMOrPM,
      assignees:this.parseAssignees(assignees),
      followUpRadioValue: false,
      showReschedule: false,
      assigneesAvailability,
      visitType : "",
      missingCTAssignee: _.filter(ctAssignees, v => !!v).length < CT_ASSIGNEE_ROLES.length
    }
  }

  onChangHour = (val,localToRemote)=>{
    const {localToRemoteHour,remoteToLocalHour } = dataHelper;
    let hourValue = (!localToRemote ? remoteToLocalHour(val) : localToRemoteHour(val));
    // let localHour =  !localToRemote ? remoteToLocalHour(val) : val;
    // let remoteHour = localToRemote ? localToRemoteHour(val) : val
    let key = !localToRemote ? 'hour' : 'remoteHour';

    this.props.form.setFieldsValue({
      [key]: hourValue > 12 ? hourValue - 12 : hourValue,
      // amOrpm: hourValue >= 12 ? 'PM' : 'AM'
    });
    let { remoteAMOrPM,localAMOrPM }  = this.state;

    if(!localToRemote) {
       remoteAMOrPM = val >= 12 ? 'PM' : 'AM';
       localAMOrPM = hourValue >= 12 ? 'PM' : 'AM';
    }else{
      remoteAMOrPM = hourValue >= 12 ? 'PM' : 'AM';
      localAMOrPM = val >= 12 ? 'PM' : 'AM';
    }
    this.setState({
      remoteAMOrPM,
      localAMOrPM
    },this.checkAvailability)
  }

  onChangeMin = (val)=>{
    this.props.form.setFieldsValue({
      min:val,
      remoteMin:val
    },this.checkAvailability)
  }
  renderAssignees = (type,role)=>{
    const {visitInfo, editStatus, visitType,onAssigneeSearch} = this.props;
    const { assignees } = this.state;
    const searchKey = _.get(this.props.searchKey,`${type}`,'');
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const teamsFromLoginUser = loginUser.team||[];
    const titleMap = {
      assignedToCA:'CA Assignee',
      assignedToRD:'RD Assignee',
      assignedToHC:'RD Assignee'
    };
    return  {
        label:titleMap[type],
        key:type,
        initialValue: _.get(assignees,`${type}.providerId`),
        render:()=>
            <Select filterOption={false} allowClear={true} showSearch
                  onChange={(t,option)=>this.changeMemberSel(type,t,option||{})}
              // onSelect={(id,target)=>this.props.changeMemberSel(type,id,target)}
                  onSearch={ (str)=>this.onAssigneeSearch(type,str) }
                  onBlur={()=>this.onAssigneeSearch(type,'')}
          >
            {_.map(helper.getMembersByRole(teamsFromLoginUser,role).filter(({ fullName })=>{
              const regex = new RegExp(searchKey, 'gi');
              return fullName.match(regex);
            }),({id,fullName})=>{
              return (
                  <Option key={id} data-fullname={fullName}>{`${fullName} ${loginUser.id===id ?'(Assign to me)':''}`}</Option>
              )
            })}
          </Select>
      }
  }
  changeMemberSel = (type,providerId,{ props } )=>{
    const { assignees={} } = this.state;
    _.set(assignees, type, providerId ? { providerId, name: props['data-fullname'] }:{});
    this.setState({assignees});
  }

  autoFillAssignees = (assignees) => {
    this.setState({ assignees });
    this.props.form.setFieldsValue({ ...dataHelper.getAssigneeFieldValue(assignees) });
  }

  onAssigneeSearch = (type,str)=>{
    const { searchKey={} } = this.state;
    _.set(searchKey,type,str);
    this.setState({ searchKey });
  }

  setAvailability = (assigneesAvailability)=>{
    this.setState({
      assigneesAvailability
    },()=>{
      dataHelper.reValidAssignees(this.props.form);
    })
  }
  checkAvailability = ()=>{
    const { form, visit } = this.props;
    const { followUpRadioValue } = this.state;
    const {date,hour, min,type } = form.getFieldsValue();
    const combinedStartTime = dataHelper.combineDateHourAndMin(date,parseInt(hour),parseInt(min))+0;
    if(date&&hour&&min) {
      const visitId = !!followUpRadioValue ? undefined : _.get(visit, 'associatedVisit.id');
      dataHelper.onChangeAssigneeOrVisitTime(type, combinedStartTime, this.setAvailability, visitId);
      dataHelper.reValidAssignees(this.props.form);
    }
  }
  setVisitType = (visitType) => {
    this.setState({ visitType },this.checkAvailability);
  }

  // shouldRenderNotScheduleVisit = () => {
  //   const complexity = _.get(this.props, 'patientComplexity.level');
  //   const visitType = _.get(this.props, 'visit.type');
  //   if (!['FOLLOW_UP', 'INIT', 'ADDITIONAL', 'TECH'].includes(visitType)) return false;
  //   if (complexity !== COMPLEXITY_ENUM.NON_COMPLEX) return false;
  //   return true; 
  // }

  shouldShowMissingCTAssignee = (defaultVisitType) => {
    const visitType = this.props.form.getFieldValue('type') || defaultVisitType;
    return VISIT_REQUIRE_ASSIGNEE.includes(visitType) && checkIsInRole(ASSIGNEE_REMINDER_ROLE) && !!this.state.missingCTAssignee;
  }

  // renderNotScheduleCheckbox = () => {
  //   const { form } = this.props;
  //   const { getFieldDecorator } = form;

  //   return (
  //     <Item>
  //       {getFieldDecorator('notSchedulingVisit')(
  //         <Checkbox>Not scheduling visit now</Checkbox>
  //       )}
  //     </Item>
  //   );
  // }

  renderMissingCTAssignee = () => {
    const { patientComplexity, member, refetch, enrolledProgramStatus, prevConditionsList  } = this.props;
    const isEnrolled = _.includes(ENROLLED_STATUS, enrolledProgramStatus);
    const complexity = _.get(patientComplexity, 'level');
    const rmConditionsList = _.get(member, 'remoteEnrollment.conditionsList');
    const conditionsList = !_.isEmpty(prevConditionsList) ? prevConditionsList : rmConditionsList;
    return (
      <div ref={e => this.ctAssigneeWrapperRef = e} className='ctAssigneeWrapper'>
        <AssignToCTMessage 
          from='FOLLOW_UP'
          isEnrolled={isEnrolled}
          loadingDx={_.isUndefined(prevConditionsList)}
          conditionsList={conditionsList || {}}
          healthConditions={_.get(member, 'profile.healthConditions')}
        />
        <AssignToCTFormComponent 
          isEnrolled={isEnrolled} 
          complexity={complexity} 
          memberId={_.get(member, 'id')}
          saveCallback={data => {
            if(data) {
              const assignees = dataHelper.parsePatientAssignees(data);
              this.setState({ missingCTAssignee: false });
              this.autoFillAssignees(assignees);
              if(refetch) refetch();
            }
          }}
        />
      </div>
    )
  }

  renderForm = () => {
    const { assigneesAvailability, followUpRadioValue } = this.state;
    const { hiatusStatus, form, member } = this.props;
    const { getFieldValue } = form;
    const currentVisit = _.get(this, 'props.visit');
    const enrolledProgram = _.get(currentVisit, 'enrolledProgram');
    const type = _.get(this, 'props.type');
    const patient = {
      user: member || _.get(currentVisit, 'member', {}),
      enrolledPrograms: enrolledProgram ? [enrolledProgram] : []
    };
    const followUpVisit = !!followUpRadioValue ? {} : _.get(currentVisit, 'associatedVisit', {});   
    const loanDeviceInfo = _.get(this,'props.loanDeviceInfo');
    const hasLoadDevice = !!loanDeviceInfo;
    const currentVisitType = _.get(currentVisit,'type');
    const editStatus = !_.isEmpty(followUpVisit);
    const A1CData = {
      id: _.get(followUpVisit, 'labResult.id', _.get(this, 'props.patientLatestA1C.id')),
      A1C: _.get(followUpVisit, 'labResult.results.0.value', _.get(this, 'props.patientLatestA1C.results.0.value')),
      A1CDate: _.get(followUpVisit, 'labResult.dateCollected', _.get(this, 'props.patientLatestA1C.dateCollected')),
    };
    const defaultVisitType = visitHelper.getDefaultType(followUpVisit, type);
    // const notSchedulingVisitToday = !!this.props.form.getFieldValue('notSchedulingVisit');
    // const showNotFollowUpVisitRequired = this.shouldRenderNotScheduleVisit();
    const showMissingCTAssignee = this.shouldShowMissingCTAssignee(defaultVisitType);
    // const hasFutureVisits = this.props.futureVisits && this.props.futureVisits.length > 0;
    const patientComplexity = _.get(this.props, 'patientComplexity');
    const followUpVisitTime = getFieldValue('date') || _.get(followUpVisit, 'appointmentAt') || moment();

    return <Row key={_.get(followUpVisit, 'id')}>
      <Col span={15}>
        {/* {showNotFollowUpVisitRequired && !hasFutureVisits && this.renderNotScheduleCheckbox()} */}
        {showMissingCTAssignee && this.renderMissingCTAssignee()}
        {/* {!notSchedulingVisitToday &&  */}<React.Fragment>
          <VisitInputTypeAndProvider
          form={this.props.form}
          // visitType={_.get(followUpVisit, 'type', 'FOLLOW_UP')}
          visitType={defaultVisitType}
          type={type}
          setVisitType={this.setVisitType} // no-op
          visitInfo={followUpVisit}
          patient={patient}
          user={patient.user}
          hasLoadDevice={hasLoadDevice}
          editStatus={editStatus}
          changeMemberSel={this.changeMemberSel}
          onAssigneeSearch={this.onAssigneeSearch}
          searchKey={this.state.searchKey}
          assigneesAvailability={assigneesAvailability}
          setAvailability={this.setAvailability}
          followUpVisitTypeOptions={followUpVisitTypeToSelectBasedOnType(currentVisitType)}
          showFollowUpTypeWarning={!this.props.loading && _.isEmpty(this.props.lastVisit)}
        />
        <Divider></Divider>
        <VisitInputTime
          form={this.props.form}
          visitInfo={followUpVisit}
          editStatus={editStatus}
          patient={patient}
          currentVisit = {_.get(this, 'props.visit')}
          lastVisit={_.get(this, 'props.lastVisit')}
          selectedVisitType={this.state.visitType}
          setAvailability={this.setAvailability}
          healthConditions={_.get(this, 'props.healthConditions')}
          orgSchedules={_.get(this, 'props.orgSchedules')}
        />
        <Divider></Divider>
        <VisitInputProviders
          form={this.props.form}
          visitType={getFieldValue('type') || defaultVisitType}
          setVisitType={this.setVisitType} // no-op
          visitInfo={followUpVisit}
          patient={patient}
          user={patient.user}
          hasLoadDevice={hasLoadDevice}
          editStatus={editStatus}
          changeMemberSel={this.changeMemberSel}
          onAssigneeSearch={this.onAssigneeSearch}
          searchKey={this.state.searchKey}
          assigneesAvailability={assigneesAvailability}
          setAvailability={this.setAvailability}
          followUpVisitTypeOptions={followUpVisitTypeToSelectBasedOnType(currentVisitType)}
          patientComplexity={patientComplexity}
          autoFillAssignees={this.autoFillAssignees}
        />
        <Divider></Divider>
        <VisitInputNote
          form={this.props.form}
          visitInfo={followUpVisit}
          editStatus={editStatus}
          patient={patient} />
        <VisitInputA1C
          needInHouseStyle={{ marginTop: 0, paddingTop: 25 }}
          itemStyle={{ labelCol: { style: {lineHeight: 1} } }}
          form={this.props.form}
          editStatus={false}
          data={A1CData}
          patient={patient}
          visitInfo={followUpVisit}
          alwaysShow />
        {
          _.get(hiatusStatus, 'visitHiatus') &&
          followUpVisitTime && 
          <div>
            <Divider></Divider>
            <HiatusOverlapNote 
              hiatusStatus={hiatusStatus}
              visit={{ appointmentAt: followUpVisitTime }}
            />
          </div>
        }
        </React.Fragment>
        {/* } */}
      </Col>
    </Row>
  };

  saveEdit = (flag, setEditMode, msg) => {
    const { followUpRadioValue, assignees } = this.state;
    const { futureVisits, form, userId, visit, refetch,user, afterSubmitSucc, afterSaveDraftSucc } = this.props;
    const fieldValues = form.getFieldsValue();
    const doctorId = _.get(user,'profile.doctor.id');
    const visitId = _.get(visit, 'id');
    const hasFollowUpVisit = _.get(visit, 'associatedVisit');

    const thenFn = (successMsg) => {
      flag ? afterSubmitSucc() : afterSaveDraftSucc();
      if (successMsg) {
        message.success(successMsg);
      }
    };

    const hasFutureVisits = futureVisits && futureVisits.length > 0;
    const isFormPresent = !hasFutureVisits || followUpRadioValue;
    const makeAnotherVisit = !!followUpRadioValue;
    
    let { date, min, hour, reason, visitRoles, type, doctor, A1C, A1CDate, category/* , notSchedulingVisit */ } = fieldValues;

    if(!isFormPresent/*  || notSchedulingVisit */) {
      return thenFn();
    }

    const A1CVariables = this.processA1CData(A1C, A1CDate);
    const appointmentTime = moment(date).hour(parseInt(hour)).minute(parseInt(min)).second(0).valueOf();

    let variables = {
      memberId: userId,
      type,
      appointmentAt: appointmentTime,
      appointmentTo: moment(appointmentTime).add(30, 'minutes').valueOf(),
      reason,
      visitRoles,
      A1C: A1CVariables,
      category,
      assignees
    };

    if(_.includes(visitRoles, 'MD')) {
      variables.providers = [doctor];
    } else {
      variables.providers = [doctorId];
    }

    if(type !== 'MNT' && _.includes(visitRoles, 'RD')) {
      variables.visitRoles.push('HC');
      variables.visitRoles = _.uniq(variables.visitRoles);
    }

    if (!hasFollowUpVisit || makeAnotherVisit) {
      createVisit(variables).then(res => {
        if(hasFollowUpVisit) { // makeAnotherVisit case and already has associated visit
          thenFn('');
          return refetch && refetch();
        }
        const associatedVisitId = _.get(res, 'data.createVisit.id');
        // associate new visit to current visit
        rescheduleVisit({
          id: visitId,
          associatedVisitId
        }).then(res => {
          // thenFn('Follow Visit is Scheduled');
          thenFn('');
          if (refetch) {
            return refetch();
          }
        }).catch(err => console.log('has no followup', err));
      });
    } else {
      variables.id = hasFollowUpVisit.id;
      rescheduleVisit(variables).then(res => {
        // thenFn('Follow Visit is Rescheduled');
        thenFn('');
        if (refetch) {
          return refetch();
        }
      }).catch(err => console.log('has followup', err));
    }
  };

  handleClick = () => {
    const { form, title, submitButtonText } = this.props;
    let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
    // shouldShowMissingCTAssignee.visitType = form.getField.type
    if(this.shouldShowMissingCTAssignee() && this.state.missingCTAssignee) {
      return this.ctAssigneeWrapperRef && this.ctAssigneeWrapperRef.scrollIntoView();
    }
    Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
    form.validateFieldsAndScroll((error, res) => {
      if (!error) {
        this.saveEdit(true, false, 'Follow Up Visit is Saved');
      }
    });
  };

  processA1CData = (value, dateCollected) => {
    const latestA1C = {
      id: _.get(this, 'props.patientLatestA1C.id'),
      A1C: _.get(this, 'props.patientLatestA1C.results.0.value'),
      A1CDate: _.get(this, 'props.patientLatestA1C.dateCollected'),
      isInHouse: _.get(this, 'props.patientLatestA1C.results.0.isInHouse')
    }

    let A1CVariables = null;
    if(value && dateCollected){
      //have input data
      if(!latestA1C.A1C){
        // no previous data, save data
        A1CVariables = {
          value,
          dateCollected,
        }
      } else {
        //have previous data, create and save status are same.
        let start_date = moment(latestA1C.A1CDate);
        const end_date = moment();
        if ((end_date.diff(start_date, 'days')) < 30) {
          //create and save status, previous date < 30
          const originalDate = start_date.format('MM/DD/YYYY');
          const modifiedDate = dateCollected.format('MM/DD/YYYY');
          if (latestA1C.A1C != value || originalDate != modifiedDate) {
            // if input data is different, save
            A1CVariables = {
                value,
                dateCollected,
            }
          }
        } else {
          //create and edit status, previous date > 30, input < 30 save, input > 30 no save
          start_date = moment(dateCollected);
          if ((end_date.diff(start_date, 'days')) < 30) {
            A1CVariables = {
              value,
              dateCollected,
            }
          }
        }
      }
      // A1C is given by patient, so it can't be inHouse
      if(A1CVariables) A1CVariables.isInHouse = false;
    } else if(latestA1C.A1C){
      A1CVariables = {
        id: latestA1C.id,
        value: latestA1C.A1C,
        dateCollected: latestA1C.A1CDate,
        isInHouse:latestA1C.isInHouse
      }
    }
    return A1CVariables;
  }

  onFollowUpRadioButtonChange = (evt) => {
    this.setState({ followUpRadioValue: evt.target.value });
  }

  handleVisitEdit = (visit) => {
    this.setState({ showReschedule: visit });
  }

  renderSingleFutureVisit = ({ from, to }) => (futureVisit, i) => {
    const date = moment(futureVisit.appointmentAt);
    const { sign, diffInHours } = dataHelper.getTimezoneObj()
    const localTime = date.clone().add(sign*diffInHours, 'hour');

    return (
      <div key={futureVisit.id}>
        {i > 0 && <hr style={{ margin: '15px 0' }} />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{date.format('MM/DD/YYYY')} at {date.format('hh:mm a z')} {momentTimezone().tz(momentTimezone.tz.guess()).format('z')} ({localTime.format('hh:mm A')} Patient local time)</div>
          <div>
            <span style={{ margin: '0 20px', cursor: 'pointer' }} onClick={() => this.handleVisitEdit(futureVisit)}>
              <img
                src='/image/edit.png'
                style={{ width: '14px', height: '14px' }}
              />
            </span>
          </div>
          <div className="visit-window-container">
            {date.isBetween(from, to)
              ? <div><Icon type="check-circle" theme="filled" style={{ color: '#63c145' }}/>&nbsp;In visit window</div>
              : <div><Icon type="info-circle" theme="filled" style={{ color: '#df483e' }} />&nbsp;Not in visit window</div>
            }
          </div>
        </div>
      </div>
    );
  }

  renderFollowUpVisitInfoAlert = () => {
    const { futureVisits, isDiabetic, refetchFutureVisitList, refetch } = this.props;
    const { showReschedule } = this.state;
    if (!futureVisits || futureVisits.length === 0) return null;
    const range = getFollowUpRange(isDiabetic);
    const lastVisit = _.get(this, 'props.lastVisit.appointmentAt');
    const lastVisitM = moment(lastVisit);

    const from = lastVisitM.clone().add(range.min.amount, range.min.unit).startOf('day');
    const to = lastVisitM.clone().add(range.max.amount, range.max.unit).endOf('day');

    return (
      <div>
        <Alert message={
          <div style={{ display: 'flex', fontSize: 15, lineHeight: '15px', padding: '10px 0' }}>
            {showReschedule &&
              <EditVisitInDashboardContainer
                refetch={() => {
                  refetch().then(() => {
                    return refetchFutureVisitList();
                  }).then(() => {
                    this.setState(this.init());
                  });
                }}
                visitInfo={showReschedule}
                onCancel={() => {
                  this.setState({ showReschedule: false });
                }}
                timerName={this.props.timerName}
              />
            }
            <div style={{ fontWeight: 'bold', paddingRight: 20 }}>
              <Icon type="info-circle" theme="filled" style={{ color: '#3177c9' }}/>&nbsp;
              Follow-up visit exists:
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {futureVisits.map(this.renderSingleFutureVisit({ from, to }))}
            </div>
          </div>
        } type="info" />
        <div style={{ marginTop: 15, marginBottom: 25 }}>
          <span style={{ marginRight: 20}}>Still want to make another one?</span>
          <Radio.Group onChange={this.onFollowUpRadioButtonChange} value={this.state.followUpRadioValue}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
      </div>
    );
  }

  render() {
    const { 
      lastVisit, 
      futureVisits, 
      type,
      loading, loadingPatientInfo, loadingPatientEnrolledProgramList, loadingPatientLatestA1C
    } = this.props;
    const { followUpRadioValue } = this.state;
    const hasFutureVisits = futureVisits && futureVisits.length > 0;

    if(loading || loadingPatientInfo || loadingPatientEnrolledProgramList || loadingPatientLatestA1C){
      return <IHLoading />;
    }
    return (
      <div>
        <div className='fix-header charting-header'>Follow-Up</div>
        {/* {this.shouldRenderNotScheduleVisit() && 
          <div style={{ fontSize: 14, color: '#2D3238', marginTop: 10 }}>This patient is a “Non-complex” patient thus follow-up visit is not required.</div>} */}
        <div className='scrollable-content' style={{ minHeight: 500, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {!_.isEmpty(lastVisit) && hasFutureVisits && this.renderFollowUpVisitInfoAlert()}
          {
            (_.isEmpty(lastVisit) || !hasFutureVisits || followUpRadioValue) &&
            <Form>
              { this.renderForm() }
            </Form>
          }
        </div>
        <div className='fix-footer' style={{ display: 'flex', float: 'right', paddingTop: 10, alignSelf: 'flex-end' }}>
          <div style={{ color: 'var(--gray-scale-2)'}}>
            Changes in the section can't be saved as draft. Please complete directly.
          </div>
          <Button
            onClick={() => this.handleClick()}
            type={'primary'}
          >
            {this.props.submitButtonText || 'Complete'}
          </Button>
        </div>
      </div>
    );
  }
};

FollowUpVisitComponent = Form.create({
  onValuesChange: (props, changedValues, allValues) => {
    const { form, visit } = props;
    const followUpVisit = _.get(visit, 'associatedVisit');
    const { type, category } = changedValues;
    if(form && (type || category)) {
      // current values of type and category
      const visitType = _.get(allValues, 'type');
      const visitCategory = _.get(allValues, 'category');
      const patientComplexity = _.get(props, 'patientComplexity');
      const visitRoles = getVisitRoles(visitType, visitCategory,patientComplexity, followUpVisit);

      form.setFieldsValue({ visitRoles });
    }
    if(dataHelper.needReValidateAvailability(_.first(Object.keys(changedValues)))) {
      dataHelper.reValidAssignees(props.form);
    }
  }
})(compose(
  withProps(ownProps => {
    const healthConditions = _.get(ownProps, 'visit.member.profile.healthConditions');
    return {
      ...ownProps,
      healthConditions,
      isDiabetic: hasDiabetes(healthConditions)
    };
}))(FollowUpVisitComponent));


const loanDeviceInfo = graphql(getLoanDevice,{
  options:({ member }) =>{

    return {
      variables: {
        memberId: member.id,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  },
  props:({data})=>{
    const { loading } = data;
    if(loading){
      return  {
        loading:loading
      }
    }
    return {
      loanDeviceInfo: _.get(data, 'getLoanDevice', [])
    }
  }
})
const getPatientLatestA1C = graphql(getLatestA1C, {
  options: ({ member }) => ({
    variables: { memberId: member.id },
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => ({
    loadingPatientLatestA1C: data.loading,
    patientLatestA1C: _.get(data, 'getLatestA1C', {}),
    refetchPatientLatestA1C : data.refetch
  }),
});
// const withHealthConditions = graphql(PatientHealthCondition, {
//   options: (ownProps) => {
//     const { enrolledProgramId } = ownProps;
//     return {
//       variables: {
//         id: enrolledProgramId
//       },
//       fetchPolicy:'network-only',
//     }
//   },
//   props: ({data}) => {
//     const { loading, enrolledProgram }  = data;
//     if (loading) {
//       return {
//         loading
//       };
//     }
//     const healthConditions = _.get(enrolledProgram, 'user.profile.healthConditions', []);
//     return {
//       healthConditions,
//       isDiabetic: hasDiabetes(healthConditions),
//     };
//   }
// });
const withLastVisit = graphql(visitListMini, {
  options: (ownProps) => {
    const { member, appointmentAt } = ownProps;
    return {
      variables: {
        page: 1,
        count: 1,
        // adding 1 minute to include the current visit
        appointmentTo: moment(appointmentAt).add(1, 'minute').valueOf(),
        memberId: member.id,
        type: ['INIT', 'FOLLOW_UP'],
        sort: { field: 'APPOINTMENT_AT', direction: 'DESC' },
      },
      fetchPolicy:'network-only',
    }
  },
  props: ({data}) => {
    const { loading, visitList }  = data;
    if(loading){
      return {
        loading
      };
    }
    return {
      lastVisit: _.get(visitList, 'data.0') || {}, // last init / follow-up visit
    };
  }
});
const withFutureVisit = graphql(visitList, {
  options: (ownProps) => {
    const { member, appointmentAt } = ownProps;
    return {
      variables: {
        page: 1,
        count: 100,
        appointmentFrom: moment(appointmentAt).add(1, 'minute').toDate().getTime(),
        memberId: member.id,
        type: ['FOLLOW_UP'],
        sort: { field: 'APPOINTMENT_AT', direction: 'ASC' },
      },
      fetchPolicy:'network-only',
    }
  },
  props: ({data}) => {
    const { loading, visitList, refetch }  = data;
    if(loading){
      return {
        loading
      };
    }
    return {
      futureVisits: _.get(visitList, 'data', []) || [],
      refetchFutureVisitList: refetch,
    };
  }
});
const getPatientHiatusStatus = graphql(getPatientHiatusQuery, {
  options: (ownProps) => {
    return {
      variables: {
          memberId: _.get(ownProps, 'member.id')
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getPatientHiatusStatus, refetch, error } = data;
    if(error)
      console.error(error);
      
    return {
      hiatusStatus: loading ? {} : (getPatientHiatusStatus || {}),
    };
  }
});

const getConditionListFromVisit = graphql(visitList, {
  options: (ownProps) => ({
    variables: {
      memberId: _.get(ownProps, 'member.id'),
      type: ['INIT', 'CLINIC_FOLLOW_UP'],
      sort: { 'field': 'CREATED_AT', 'direction': 'DESC' },
      count: 1
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }) => {
      const { loading } = data;

      if(loading) 
        return { loading };

      const visit = _.get(data, 'visitList.data.0') || {};
      const prevConditionsList = _.get(visit, 'conditionsList') || {};
      return {
        prevConditionsList
      }
  }
})

export default compose(
  loanDeviceInfo,
  getPatientLatestA1C,
  // withHealthConditions,
  withLastVisit,
  withFutureVisit,
  getPatientHiatusStatus,
  getConditionListFromVisit
)(FollowUpVisitComponent);
