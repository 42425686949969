import React, { Component } from 'react'
import '../css/ProgramMeasureTrendsComponent.css';
import * as d3 from 'd3';
import {connect} from "react-redux";
const legendNameMap = {
    "hyperCrisis":"HTN Crisis",
    "hyperStage2":'HTN Stage II',
    "hyperStage1":'HTN Stage I',
    "elevated":"ELEVATED",
    "normal":"Normal"
};

const colorRange = ["#7ABFB1", "#F5E82F", "#F2AC66", "#E04252", "#7213D0"];
const colorAndKeyMap = {
    '#7ABFB1': 'Normal',
    '#F5E82F':'Elevated',
    '#F2AC66':'HTN Stage1',
    '#E04252':'HTN Stage2',
    '#7213D0':'HyperCrisis'
}


class ProgramMeasureTrendsComponent extends Component {
    constructor(props){
        super(props);

    }

    render() {
        return (
            <div className='programTrendComponContainer' ref={ node => {this._container = node }}></div>
        )
    }

    componentWillReceiveProps(){
        d3.select(this._container).selectAll('*').remove();
        this.createBarChart();
    }
    componentDidMount() {
        this.createBarChart()
    }

    createBarChart() {
            let { parsedData,xAxisKeys,chartTitle,width,height,marginTop,marginLeft,marginBottom,marginRight } = this.props;
            let keys = Object.keys(parsedData[0]).slice(0, 5).reverse();

            //keys refer to four measurement types
            //"hyperCrisis":"HTN Crisis",
            //"hyperStage2":'HTN Stage II',
            //"hyperStage1":'HTN Stage I',
            //"elevated":"ELEVATED",
            //"normal":"Normal"
            let container = d3.select(this._container);
            let svg = container.append("svg")
                      .attr('width', width )
                      .attr('height', height );
                width = +svg.attr("width") - marginLeft - marginRight,
                height = +svg.attr("height") - marginTop - marginBottom;

                svg.append('g')
                    .attr('transform', `translate(15,${marginTop - 10})`)
                    .append('text')
                    .attr('class', 'chartTitle')
                    .text(chartTitle);

            let g = svg.append("g").attr("transform", "translate(" + marginLeft + "," + 60 + ")");
                    svg.attr('style', 'background-color: white;');

            let x = d3.scaleBand()
                .rangeRound([0, width - 120])
                .paddingInner(0.05)
                .align(0.1);

            let y = d3.scaleLinear()
                .rangeRound([(height-20), 0]);
            let z = d3.scaleOrdinal()
                .range(colorRange);
                x.domain(xAxisKeys);

                y.domain([0, d3.max(parsedData, function (d) {
                    return d.total;
                })]).nice();

                z.domain(keys);

            //draw x axis
            g.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(20," + (height-20) + ")")
                .call(d3.axisBottom(x))
                .selectAll('path')
                .remove()


            //draw y axis
            g.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(20,0)")
                .call(d3.axisLeft(y).ticks(Math.min(d3.max(parsedData,function(d){
                    return d.total
                }),6))
                .tickSize(-width + 130))//this width changes x-axis length
                .append("text")
                .attr('class','yAxisText')
                .attr("dy", "0.32em")
                .attr("fill", "#787878")
                .attr("text-anchor", "start")
                .attr("transform", "translate(" + -40 + "," + 190 + ") rotate(-90)")  // text is drawn off the screen top left, move down and out and rotate
                .text('Total Number of Patients')

            //draw bar chart
            g.append("g")
                .attr('transform', function (d) {
                    return `translate(${x.bandwidth() -10},0)`;
                })
                .selectAll("g")
                .data(d3.stack().keys(keys)(parsedData))
                .enter().append("g")
                .attr("fill", function (data) {
                    return z(data.key);
                })
                .selectAll("rect")
                .data(function (d) {
                    return d;
                })
                .enter().append("rect")
                .attr("x", function (d) {
                    return x(d.data.Week ? d.data.Week : d.data.Month);
                })
                .attr("y", function (d) {
                    return y(d[1]);
                })
                .attr("height", function (d) {
                    let diff = d[0] - d[1];
                    if (diff)
                        return y(d[0]) - y(d[1]);

                })
                .attr("width", x.bandwidth() * 0.6)
                .on('mouseout', function () {
                    d3.select('.tooltip.barchart').attr('style', 'display:none');
                })
                .on("mousemove", function (d) {
                    if ((d[1] - d[0]) !== 0) {
                        tooltip.style("left", d3.event.pageX + 10 + "px");
                        tooltip.style("top", d3.event.pageY - 25 + "px");
                        tooltip.style("display", "flex");
                        let elements = document.querySelectorAll(':hover');
                        let l = elements.length;
                        l = l - 1;
                        let color = elements[l].parentNode.getAttribute('fill');
                        let key = colorAndKeyMap[color]
                        let value = parseInt(d[1] - d[0]);
                        let totalMeasurements = parseInt(d.data.total);
                        let precentage = Math.floor((value / totalMeasurements) * 100);
                        tooltip.html(`<h2 style=padding:5px>${precentage}%</h2><div style=padding:5px;align-self:center><span style=font-weight:bold;font-size:16px;>${key}</span><br>${value} of ${totalMeasurements} patients</div>`);
                    }

                });

            //draw legend
            let legend = g.append("g")
                .attr('class', 'legend')
                .attr('transform',`translate(0,40)`)
                .attr("font-family", "Helvetica Neue")
                .attr("font-size", 12)
                .attr("text-anchor", "start")
                .selectAll("g")
                .data(keys.slice().reverse())
                .enter().append("g")
                .attr("transform", function (d, i) {
                    return "translate(-80," + i * 25 + ")";
                });


            legend.append("circle")
                .attr("cx", width)
                .attr("r", 8)
                .attr("cy", 25)
                .attr("fill", z);

            legend.append("text")
                .attr("x", width + 20)
                .attr("y", 25)
                .attr("dy", "0.32em")
                .text(function (d) {
                    return legendNameMap[d];
                });


            let svgParent = d3.select(svg.node().parentNode);
            let tooltip = svgParent.append("div")
                .attr("class", "barchart tooltip arrow_box")


    }

}

export default ProgramMeasureTrendsComponent
