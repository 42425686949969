import gql from 'graphql-tag'


export default gql`
  mutation returnDevices(
    $id : EID!
    $returnee : ReturneeInput!,
    $BP : ReturnDeviceInput,
    $BG : ReturnDeviceInput,
    $HS : ReturnDeviceInput,
    $HR : ReturnDeviceInput,
    $TABLET : ReturnDeviceInput

  ){
      returnDevices(
        id: $id,
        returnee: $returnee,
        BP : $BP,
        BG : $BG,
        HS : $HS,
        HR : $HR,
        TABLET: $TABLET
      ){
        id,
        name,
        status,
        qrcode,
        devices{
          address
          model
          type
          status
        }
        forms{
          type
          uri
          date
        }

      }
  }
`;
