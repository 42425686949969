import gql from 'graphql-tag'
import Measure from './schema/Measure'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import { getStatus } from 'modulesAll/utils/helpers/task'

export default gql `
  mutation insertHR(
    $date: Date!,
    $timezone: String!,
    $heartrate: HRValue!,
    $imageUrl: String,
    $notes: String,
    $memberId: EID,
  ){
    insertHR(
      date: $date,
      timezone: $timezone,
      heartrate: $heartrate,
      imageUrl: $imageUrl,
      notes: $notes,
      memberId: $memberId
    ) {
      ${Measure}
    }
  }
`
