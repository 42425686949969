import { commonReducer, commonInitialState } from 'libModule/helpers/reducer-helpers'
import types from 'patientModule/profileEdit/constants'

const initialState = Object.assign(commonInitialState(types), {
  isFormSaved: true,
  isSubmitting: false
})

const ownReducer = (state, action) => {
  switch (action.type) {
    case types.RESET_FORM:
      return initialState
    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
