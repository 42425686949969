import {IHBaseFormWithRow} from 'ihcomponent'
export default class Component extends IHBaseFormWithRow {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.step !== nextProps.step){
        return true;
    }
    else {
        return false;
    }
  }
}
