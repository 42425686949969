import gql from 'graphql-tag'
import { Task } from '../../graphql/schema/Program';

const measureType = `
  id
  user_notes
  timezone
  deviceModel
  date
`;

export const resultList = gql`
 query resultList($filters:ResultListOpts!, $count: Int = 9999){
  resultList(filters:$filters,count: $count){
    data{
      ...on BGResult {
        blood_glucose
        mealType
        severity
        beforeMeal
        date
        timezone
        id
        measure {
          ${measureType}
        }
      }
      ...on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          severity
          arrhythmia
          date
          timezone
          measure {
            ${measureType}
          }
          triageInfo
      }
      ... on POResult {
        severity
        date
        blood_oxygen
        perfusion_index
        measure {
          ${measureType}
        }
      }
      ... on HSResult {
        body_weight,
        body_weight_change,
        body_weight_change_baseline
        date
      }
      moodTags
      hadMedicine
      hadActivity
      hadInsulin
    }
    stat {
      bg {
        all {
          count
          average
          range
          inRangePercentage
        }
        fasting {
          count
          average
          range
          inRangePercentage
        }
        beforeMeal {
          count
          average
          range
          inRangePercentage
        }
        afterMeal {
          count
          average
          range
          inRangePercentage
        }
        bedtimeOvernightHypo {
          count
          average
          range
          inRangePercentage
        }
        bedtime {
          count
          average
          range
          inRangePercentage
        }
        overnight {
          count
          average
          range
          inRangePercentage
        }
        criticalHigh {
          count
          average
          range
          inRangePercentage
        }
        criticalLow {
          count
          average
          range
          inRangePercentage
        }
      }
      bp {
        all {
          count
          average
          range
          inRangePercentage
        }
        overnight {
          count
          average
          range
          inRangePercentage
        }
        morning {
          count
          average
          range
          inRangePercentage
        }
        afternoon {
          count
          average
          range
          inRangePercentage
        }
        evening {
          count
          average
          range
          inRangePercentage
        }
        low {
          count
          average
          range
          inRangePercentage
        }
        normal {
          count
          average
          range
          inRangePercentage
        }
        elevated {
          count
          average
          range
          inRangePercentage
        }
        hypertension1 {
          count
          average
          range
          inRangePercentage
        }
        hypertension2 {
          count
          average
          range
          inRangePercentage
        }
        criticalHigh {
          count
          average
          range
          inRangePercentage
        }
        arrhythmia {
          count
          average
          range
          inRangePercentage
        }
        pulsePressure {
          count
          average
          range
          inRangePercentage
        }
      }
      po {
        highestPO
        all {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        overnight {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        morning {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        afternoon {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        evening {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        normal {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        low {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
        criticalLow {
          count
          average
          range
          perfusionIndexAverage
          pulseRateAverage
        }
      }
    }
  }
}
`


export const foodLogList = gql`
query foodLogList($count: Int, $page: Int, $filters: FoodLogListOpts!,$sort:FoodLogListSort) {
  foodLogList(count: $count, page: $page, filters: $filters, sort: $sort) {
    data {
      id
      member {
        id
        profile {
          fullName
        }
      }
      mealType
      note
      images{
        link
      }
      createdAt
      rating {
        star
        category {
          carb
          fat
          fruit
          vegetable
        }
      }
      comments {
        text
        commentedBy {
          profile {
            fullName
          }
        }
        commentedAt
      }
    }
    pageInfo {
      total
      lastPage
    }
  }
}`

export const POResultList = gql`
  query resultList($filters: ResultListOpts!, $count: Int = 9999) {
    resultList(filters: $filters, count: $count) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        ... on POResult {
          severity
          date
          timezone
          blood_oxygen
          perfusion_index
          measure {
            ${measureType}
          }
        }
      }
      stat {      
        po {
          highestPO
          all {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          overnight {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          morning {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          afternoon {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          evening {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          normal {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          low {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
          criticalLow {
            count
            average
            range
            perfusionIndexAverage
            pulseRateAverage
          }
        }
      }
    }
  }
`;

export const BPLogbookList = gql`
  query resultList($filters: ResultListOpts!, $count: Int = 9999) {
    resultList(filters: $filters, count: $count) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        ...on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          severity
          arrhythmia
          date
          timezone
          measure {
            ${measureType}
          }
          triageInfo
        }
      }
    }
  }
`;

export const BGLogbookList = gql`
  query resultList($filters: ResultListOpts!, $count: Int = 9999) {
    resultList(filters: $filters, count: $count) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        ...on BGResult {
          blood_glucose
          mealType
          severity
          beforeMeal
          date
          timezone
          id
          measure {
            ${measureType}
          }
        }
      }
    }
  }
`;

export const POLogbookList = gql`
  query resultList($filters: ResultListOpts!, $count: Int = 9999) {
    resultList(filters: $filters, count: $count) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        ... on POResult {
          severity
          date
          blood_oxygen
          perfusion_index
          measure {
            ${measureType}
          }
        }
      }
    }
  }
`;

export const TaskQuery = gql`
  query enrolledProgram($id: EID!) {
    enrolledProgram(id: $id) {
      id
      tasks {
        ${Task}
      }
    }
  }
`;