// id
// payerId
// payerName
// description
// clearingHouse
export const InsuranceProviderType = `
  id
  payerId
  payerName
  description
`;

export const PatientInsuranceProvider = `
  insuranceProvider {
    ${InsuranceProviderType}
  }
  primary
`;