import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { graphql } from "react-apollo/index";
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';
import getPatientAlertConfig from 'modulesAll/smartAlert/query/getPatientAlertConfig';
import SmartAlertContainer from './SmartAlert';
import GenerateMTPRContainer from '../../../workStation/container/GenerateMTPRContainer';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'lib/utils';
import { setMonthlyReviewStatus } from '../../../workStation/action/index';
import monthlyReviewList from 'graphqlModule/monthlyReviewList';
import Client from 'libModule/gqlClient';
import moment from 'moment';
import { helpers } from '../../../visitNewWorkFlow/helper';
import { getMonthlyReviewByRoles } from '../../../workStation/helper/monthlyReviewHelper';
import { monthlyReviewStatusMap } from '../../../workStation/constant';

import 'modulesAll/workStation/style/generateMTPR.scss';
import '../css/smartAlert.scss';

class SmartAlertAndMTPR extends Component {
    static displayName = 'main/containers/SmartAlertAndMTPR';
    constructor(props) {
        super(props);

        const { patientId, setMonthlyReviewStatus, openErrorModal, setMonthlyReviewData, viewOnly, setSystemGenerateMonthlyReviewData } = props;
        if(!viewOnly){
            const role = helpers.getRole();
            Client.query({
                query: monthlyReviewList,
                variables :{
                    filters: {
                        memberId: patientId,
                        monthOfYear: moment().format('YYYYMM'),
                        includeDraft: true,
                    },
                    sort: {
                        field: 'CREATED_AT',
                        direction: 'DESC',
                    }
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
            }).then(res => {
                const { data, refetch } = res;
                let monthlyReviewList = _.get(data, 'monthlyReviewList.data', []);
                let monthlyReviewData = getMonthlyReviewByRoles(role, monthlyReviewList);
                const status = this.getMonthlyReviewStatus(monthlyReviewData);
                setMonthlyReviewStatus(status);
                if(setMonthlyReviewData){
                    setSystemGenerateMonthlyReviewData(monthlyReviewData)
                    setMonthlyReviewData(monthlyReviewData)
                }
            }).catch(e => {
                console.error("MTPR", e);
                openErrorModal(GQLHelper.formatError(e))
            })
        }
    }

    getMonthlyReviewStatus = (monthlyReviewData) => {
      const { status } = monthlyReviewData || {};
      if (status === monthlyReviewStatusMap.DRAFT)
        return monthlyReviewStatusMap.START;
      return status;
    }


    isCCMRPMEnrolled = ()=>{
        let isCCMRPMEnrolled = false;
        const user = _.get(this, 'props.data.user');
        let programCategories = _.get(user, 'profile.programCategories')||[];
        _.forEach(programCategories, ({name, enrolled}) => {
            if((name === 'CCM' || name === 'RPM') && enrolled === true){
                isCCMRPMEnrolled = true;
            }
        })
        return isCCMRPMEnrolled;
    }

    render(){
        const { inProfile, enrollmentStatus, viewOnly } = this.props;
        const isCCMRPMEnrolled = this.isCCMRPMEnrolled();
        const isMAOrMD = helpers.checkIsMAOrMD();

        this.isEnrolledPatient = _.startsWith(enrollmentStatus, 'Enrolled');
        // SC-7916: remove the smart alert section in patient's profile
        // SC-8160: remove the smart alert section entirely
        return this.isEnrolledPatient && <Row className='mtpr-smartAlert-container'>
            {/*
            {viewOnly && <div className='card smartAlert'>
               <SmartAlertContainer {...this.props} />
            </div>} */}
            {  // display the section when in the profile
                (inProfile && isCCMRPMEnrolled) && !isMAOrMD && <div className='card mtpr'>
                <GenerateMTPRContainer {...this.props}/>
              </div>}
          </Row>
    }
}

const mapToProps = (state)=>{
    return {
        monthlyReviewStatus: _.get(state,'workstation.main.monthlyReviewStatus'),
        monthlyReviewType: _.get(state,'workstation.main.monthlyReviewType')
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        setMonthlyReviewStatus: (status)=>dispatch(setMonthlyReviewStatus(status)),
        openErrorModal: (e) => dispatch(openErrorModal(e))
    }
}


const smartAlerts = graphql(getPatientSmartAlert, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            smartAlertsList: _.get(data, 'getPatientSmartAlert.tasks', []),
            loadingSmartAlertsList: data.loading
        }
    }
})

const patientAlertConfig = graphql(getPatientAlertConfig, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            patientAlertConfig: _.get(data, 'getPatientAlertConfig', {}),
        }
    }
})

export default compose(connect(mapToProps,mapToDispatch), smartAlerts, patientAlertConfig)(SmartAlertAndMTPR);
