import { VITAL_TYPE_MAP } from "../../referAndEnroll/constant";
import { convertKgToPound,convertCelsiusToFahrenheit } from "../../../lib/utils/convertUnits";
import React from 'react'
export const MONTHLY_REPORT_VITAL_TYPE_ENUM = { BG: 'BG', BP: 'BP', HS: 'HS', PO: 'PO',TM: 'TM' };

const getVitalTitle = (type)=>VITAL_TYPE_MAP[type];
const getAverage =(monthlyReport,unitParser,unit)=>{
	const { statistics = {},severity } = monthlyReport;
	const { all = {} } = statistics;
	const { average=[] } = all;
	let parsedValue = unitParser ? unitParser(average): average;
	if(!isNaN(parsedValue)) {
		parsedValue = Math.round(parsedValue).toFixed(1);
	}
	return <p>{`The average was ${severity ? ' - ' + severity : ''} ${parsedValue} ${unit}.`}</p>
}

const RESULT_TEMPLATE = (monthlyReport,unit,unitParser,headerSummary)=> {
	const { statistics = {},measurementDays } = monthlyReport;
	const { all = {} } = statistics;
	const { count } = all;
	return <div>
		<p>{`Patient took ${count} ${count > 1 ? 'measurements' : 'measurement'} in ${measurementDays} ${measurementDays > 0 ? 'days' : 'day'}`}</p>
		<p>{headerSummary(monthlyReport,unitParser,unit)}</p>
	</div>
}

const getFooterSummary = (statistics) => {
	let str = '';
	if (statistics.morning.count > 0) {
		str += ', ' + `xxx_C ${statistics.morning.count > 1 ? 'measurements' : 'measurement'} in the xxx_P`.replace('xxx_C', `${statistics.morning.count}`).replace('xxx_P', 'Morning');
	}
	if (statistics.afternoon.count > 0) {
		str += ', ' + `xxx_C ${statistics.afternoon.count > 1 ? 'measurements' : 'measurement'} in the xxx_P`.replace('xxx_C', `${statistics.afternoon.count}`).replace('xxx_P', ('Afternoon'));
	}
	if (statistics.evening.count > 0) {
		str += ', ' + `xxx_C ${statistics.evening.count > 1 ? 'measurements' : 'measurement'} in the xxx_P`.replace('xxx_C', `${statistics.evening.count}`).replace('xxx_P', ('Evening'));
	}
	if (statistics.overnight.count > 0) {
		str += ', ' + `xxx_C ${statistics.overnight.count > 1 ? 'measurements' : 'measurement'} in the xxx_P`.replace('xxx_C', `${statistics.overnight.count}`).replace('xxx_P', ('Overnight'));
	}
	if (str.lastIndexOf(',') === 0) {
		return str.replace(',', '');
	} else {
			const originalStr = str.substring(str.indexOf(',') + 1, str.lastIndexOf(',')) + ' and ' + str.substring(str.lastIndexOf(',') + 2);
			const re = /measurements /gi;
			const re2 = /measurement /gi;
			return originalStr.substring(0, originalStr.indexOf('in the')) + (originalStr.substring(originalStr.indexOf('in the'))).replace(re, '').replace(re2, '');
	}
}
const getFooterSummaryForBG = (statistics) => {
	let str = '';
	if (statistics.beforeMeal.count > 0) {
		str += ', ' + `xxx_C xxx_P ${statistics.beforeMeal.count > 1 ? 'measurements' : 'measurement'}`.replace('xxx_C', statistics.beforeMeal.count).replace('xxx_P', 'Pre-meal');
	}
	if (statistics.afterMeal.count > 0) {
		str += ', ' +(`xxx_C xxx_P ${statistics.afterMeal.count > 1 ? 'measurements' : 'measurement'}`).replace('xxx_C', statistics.afterMeal.count).replace('xxx_P', ('Post-meal'));
	}
	if (statistics.bedtime.count > 0 || statistics.overnight.count > 0) {
		const totalNight = statistics.bedtime.count + statistics.overnight.count;
		str += ', ' + (`xxx_C ${totalNight > 1 ? 'measurements' : 'measurement'} in the xxx_P`).replace('xxx_C', totalNight).replace('xxx_P', ('night'));
	}
	if (str.lastIndexOf(',') === 0) {
		return str.replace(',', '');
	} else {
		return str.substring(str.indexOf(',') + 1, str.lastIndexOf(',')) + ' and ' + str.substring(str.lastIndexOf(',') + 2);
	}
}
const resultsAndAverage = (monthlyReport,unitParser,unit,getSummary = getFooterSummary,headerSummary=getAverage )=>{
	const { statistics = {} } = monthlyReport;
	const { all = {} } = statistics;
	const { count } = all;
	return <div className={'resultsAndAverage'}>
			<p>{ count > 0 ? RESULT_TEMPLATE(monthlyReport,unit,unitParser,headerSummary) :'No Measurement' }</p>
			<p>{ count > 0 ? `The patient took ${getSummary(statistics)}.`:'' }</p>
	</div>
}

const headerSummaryForHS = (monthly)=>`Last weight reading was ${ Math.round(convertKgToPound(_.get(monthly,'data.0.body_weight.value',0))).toFixed(1) } pounds.`;

const parseBPValue = (value)=> _.get(value,'length')==2 ? value.join('/') : value;
export const MONTHLY_REPORT_VITAL_MAP = {
	BG:{
		title:getVitalTitle,
		resultsAndAverage:(monthlyReport)=>resultsAndAverage(monthlyReport,null,'mg/dL',getFooterSummaryForBG)
	},
	BP:{
		title:getVitalTitle,
		resultsAndAverage:(monthlyReport)=>resultsAndAverage(monthlyReport,parseBPValue,'mmHg',)
	},
	HS:{
		title:getVitalTitle,
		resultsAndAverage:(monthlyReport)=>resultsAndAverage(monthlyReport,convertKgToPound,'pound',getFooterSummary,headerSummaryForHS),
    },
	TM:{
		title:getVitalTitle,
		resultsAndAverage:(monthlyReport)=>resultsAndAverage(monthlyReport,convertCelsiusToFahrenheit,'F')
	},
	PO:{
		title:getVitalTitle,
		resultsAndAverage:(monthlyReport)=>resultsAndAverage(monthlyReport,null,'%')
	}
}
