import { combineReducers } from 'redux'
import Common from 'patientModule/enrolledProgramInfo/VitalAlert/reducers/CommonReducer'
import AlertForm from 'patientModule/enrolledProgramInfo/VitalAlert/reducers/AlertFormReducer'
import AlertList from 'patientModule/enrolledProgramInfo/VitalAlert/reducers/AlertListReducer'

const vitalAlert = combineReducers({
  Common,
  AlertForm,
  AlertList
})

export default vitalAlert
