import {React, _, ND} from '../../../../../../package/IHComponent/index';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import '../../../../../../package/IHComponent/ShareCare/WeeklyMealSchedule.scss';
import { WeeklyMealScheduleInputGrid } from '../../../../../../package/IHComponent/ShareCare/WeeklyMealScheduleInputGrid';
import { compose, withProps, withState, branch, renderComponent } from 'recompose';
import { addWeeklyBGScheduleTemplateAPI, labelCurrentTemplate, addIDWhenMissing } from '../../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
const trace = debug ? traceF : () => R.identity;

const { Icon, Row, Dropdown, Menu, Button, Spin} = ND;
import {Select} from 'antd';
const {Option} = Select;
const mkMenu = (arr, onMenuItemClick) => (
  <Menu onClick={(e)=>{
    const t = R.find(({ _id, name })=> _id+name === e.key, arr);
    onMenuItemClick(R.pick(['name', 'description', 'schedule', '_id'], t));
  }}>
    {
       arr.map(({ _id, name }) => ( <Menu.Item key={_id+name}>{name}</Menu.Item>))
    }
  </Menu>
);

const style = {
  row1: { marginBottom: 20 },
  flexHorizontal: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
  flexVertical: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    color: 'rgba(0,0,0,.65)',
    fontWeight: 100,
  },
};

class WeeklyMealScheduleComponent extends React.Component {
  state = { visibleModal: 'none' }  // new, edit, none

  setVisibleModal = (visibleModal) => {
    this.setState({
      visibleModal,
    });
  };

  clearBG = () =>{
    const {weeklyMealSchedule, updateForm} = this.props;
    let updateBG = {...weeklyMealSchedule};
    let updateSchedule = _.get(updateBG, 'schedule');
    let freq = _.get(updateBG, 'frequency');
    if(freq && typeof freq === 'object'){
      freq.times = 0;
      freq.every = 0;
    }
    const empty = {
      after_breakfast: false,
      after_dinner: false,
      after_lunch: false,
      bedtime: false,
      before_breakfast: false,
      before_dinner: false,
      before_lunch: false,
      overnight: false,
    }
    if(updateBG.name && !_.endsWith(updateBG.name, '(Customized)')){
      updateBG.name = updateBG.name+'(Customized)';
    }
    if(updateSchedule){
      const days = Object.keys(updateSchedule);
      _.map(days, day => {
        updateSchedule[day] = empty
      })
      updateBG.schedule = updateSchedule;
    }
    updateForm(updateBG);
  }

  onSelectClick = (option) =>{
    const {templates, updateForm} = this.props;
    const t = R.find(({ _id, name })=> _id+name === option, templates);
    updateForm(R.pick(['name', 'description', 'schedule', '_id'], t));
  }

  renderSelectBar = () =>{
    const {templates, disabled, weeklyMealSchedule} = this.props;
    const value = R.isNil(weeklyMealSchedule) ? null : (weeklyMealSchedule._id||'')+weeklyMealSchedule.name;
    return(
      <Select disabled={disabled}
              showSearch onSelect={this.onSelectClick}
              placeholder='Select a template'
              value={value}
              className='schedule-select'
              style={{width: '100%'}}
              dropdownClassName='schedule-select-dropdown'
              id='schedule-select'>
        {_.map(templates, (t, index) => {
          const {_id, name} = t;
          return <Option key={index} value={ (_id||'')+name}>{name}</Option>
        })}
      </Select>
    )
  }

  render() {
    const { templates, directEdit,  weeklyMealSchedule, disabled} = this.props;
    return (
      <div>
        <Row>
            <Row>
              {/* <Dropdown overlay={mkMenu(templates, (t)=>this.props.updateForm(t))} trigger={['click']} disabled={disabled}>
                <Button size='large' style={R.merge(style.flexHorizontal, { height: 40, width: 397, float: 'left' } )} >
                  { R.isNil(weeklyMealSchedule) ? 'Select a template' : weeklyMealSchedule.name }
                  <Icon type="down" />
                </Button>
              </Dropdown> */}
              {this.renderSelectBar()}
            </Row>
            <Row style={{ minWidth: 405 }}>
              { R.isNil(weeklyMealSchedule) ? null
                : (<div>
                  <WeeklyMealScheduleInputGrid value={ weeklyMealSchedule.schedule }
                    allowEdit={directEdit && !disabled}
                    onChange={(schedule) => {
                      if(directEdit) this.props.updateForm(
                        labelCurrentTemplate('Customized', R.merge(weeklyMealSchedule, { schedule }))
                      );
                  }}/>
                  <Row style={{marginTop: '10px'}}>
                  <a type='link' onClick={this.clearBG} style={{color: '#777777', textDecoration: 'underline', float: 'right', fontSize: '13px', lineHeight:'14px'}} disabled={disabled}>
                   Clear All Selected Times
                  </a>
                  </Row>
                </div>)
              }
            </Row>
        </Row>
      </div>
    )
  }
}

WeeklyMealScheduleComponent.propTypes = {
  templates: PropTypes.array.isRequired,
  weeklyBGScheduleTemplateAPI: PropTypes.object,
  // updateForm: React.PropTypes.function,
  weeklyMealSchedule: PropTypes.object,
};

const Spinner = () => (
  <div style={{"textAlign":"center","background":"rgba(0,0,0,0.05)","borderRadius":"4px","marginBottom":"20px","padding":"30px 50px","margin":"20px 0"}} >
    <Spin />
  </div>
);

const spinnerWhileLoading = isLoadingF =>
  branch(
    isLoadingF,
    renderComponent(Spinner)
  )

const enhance = compose(
  addWeeklyBGScheduleTemplateAPI,
  spinnerWhileLoading(R.path(['weeklyBGScheduleTemplateAPI', 'loading'])),
  withState('currentSchedule','updateCurrentSchedule_notused', ({ weeklyMealSchedule }) => weeklyMealSchedule),
  withProps(({ weeklyBGScheduleTemplateAPI: { records }, currentSchedule, weeklyMealSchedule }) => ({
      templates: R.compose(R.uniqBy(({ _id, name })=>_id+name), R.concat(records), R.map(addIDWhenMissing), R.reject(R.isNil))([weeklyMealSchedule, currentSchedule])
    })
  ),
);

const WeeklyMealSchedule = enhance(WeeklyMealScheduleComponent);
export default WeeklyMealSchedule;
// export { WeeklyMealScheduleInputGrid, WeeklyMealSchedule, WeeklyMealScheduleEditor };
