import { IHButton, React, _ } from 'ihcomponent';
import { browserHistory } from 'react-router';
import I18N from 'modulesAll/I18N';
import InputLongComponent from '../edit/components/optionComponents/InputLongComponent';
import MultiSelectComponent from '../edit/components/optionComponents/MultiSelectComponent';
import SingleSelectComponent from '../edit/components/optionComponents/SingleSelectComponent';
import TrueOrFalseComponent from '../edit/components/optionComponents/TrueOrFalseComponent';
import ScaledComponent from '../edit/components/optionComponents/ScaledComponent';
import { createAuditLog } from 'libModule/utils/auditLog'

export const COMPONENTS_MAP = {
  TRUE_OR_FALSE: { component: TrueOrFalseComponent },
  MULTI_SELECT: { component: MultiSelectComponent },
  SINGLE_SELECT: { component: SingleSelectComponent },
  SCALED: { component: ScaledComponent },
  INPUT_LONG: { component: InputLongComponent },
};

const STATUS_MAP = {
  COMPLETED: { color: '#258ce6', display: 'Completed' },
  SUSPENDED: { color: '#dcdcdc', display: 'Suspended' },
  MISSED: { color: '#dcdcdc', display: 'Missed' },
  UPCOMING: { color: '#c850c7', display: 'Upcoming' },
  INCOMPLETE: { color: '#64c2b1', display: 'Incomplete' },
};
const TYPE_MAP = {
  SURVEY: { color: '#988fe8', display: 'Survey' },
  ASSESSMENT: { color: '#1fbdd4', display: I18N.get('keywords.Assessment') },
};
const ACTION_MAP = {
  VIEW: { color: '#258ce6', display: 'View >' },
  SURVEY: { color: '#988fe8', display: 'Take Survey >' },
  ASSESSMENT: { color: '#1fbdd4', display: `Take ${I18N.get('keywords.Assessment')} >` },
};

export const renderStatus = (status) => {
  const data = STATUS_MAP[status];

  return (
    <div>
      <span>{data.display}</span>
    </div>
  );
};

export const renderType = (type) => {
  const data = TYPE_MAP[type];

  return (
    <div style={{ backgroundColor: data.color }}>{data.display}</div>
  );
};

export const renderAction = (status, { type, assessment, assessmentFilled, refId, id, user, ref, enrolledProgram }) => {
  if (status === 'MISSED') {
    return null;
  }

  const isView = (status !== 'UPCOMING' && status !== 'INCOMPLETE');
  const actionData = ACTION_MAP[isView ? 'VIEW' : type];

  const hasTaken = !!assessmentFilled;
  const isSurvey = type === 'SURVEY';

  const backPathStr = `${ ref ==='nurse' ? `?patientId=${_.get(user, 'id')}&epId=${_.get(enrolledProgram, 'id')}` : ''}`

  const TUPLE = [
    {
      condition: isSurvey && isView && hasTaken,
      path: `/preview_survey_filled/${_.get(assessmentFilled, '_id')}${backPathStr}`,
    },
    {
      condition: isSurvey && !isView && hasTaken,
      path: `/update_survey_filled/${_.get(assessmentFilled, '_id')}`,
    },
    {
      condition: isSurvey && !isView && !hasTaken,
      path: `/create_survey_filled/${_.get(assessment, '_id')}/${refId}`,
    },
    {
      condition: !isSurvey && isView && hasTaken,
      path: `/preview_assessment_filled/${_.get(assessmentFilled, '_id')}${backPathStr}`,
    },
    {
      condition: !isSurvey && !isView && hasTaken,
      path: `/update_assessment_filled/${_.get(assessmentFilled, '_id')}`,
    },
    {
      condition: !isSurvey && !isView && !hasTaken,
      path: `/create_assessment_filled/${_.get(assessment, '_id')}/${refId}`,
    },
  ];

  const path = _.get(_.find(TUPLE, 'condition'), 'path');

  let auditLog
  if(isSurvey && isView && hasTaken) {
    auditLog = { action:'View Survey' }
  }
  else if(!isSurvey && isView && hasTaken) {
    auditLog = { action:'View Assessment' }
  }
  return (
    <IHButton
      label={actionData.display}
      bsStyle="primary"
      className="ih-exsmall"
      style={{ backgroundColor: actionData.color }}
      type="button"
      onClick={e => {
        auditLog && createAuditLog(auditLog)
        browserHistory.push(path);
      }}
    />
  );
};

export const getGraphQuestions = assessmentFilled => _.map(assessmentFilled.questions, (q) => {
  const ret = {
    _id: q._id,
    type: q.type,
    text: q.text,
    category: q.category,
  };

  ret.options = _.map(q.options, (o) => {
    const option = {
      label: o.label,
    };
    if (o.meaning && o.meaning) {
      option.meaning = o.meaning;
    }

    return option;
  }) || [];

  if (q.answer) {
    ret.answer = {
      content: q.answer.content,
    };
  }

  return ret;
});
