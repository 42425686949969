import gql from 'graphql-tag'
import Measure from './schema/Measure';

export default gql`
query providerNoteList (
  $page: Int,
  $count: Int,
  $filters: ProviderNoteListOpts,
  $sort: ProviderNoteListSort
) {
  providerNoteList (
    page: $page,
    count: $count,
    filters: $filters,
    sort: $sort
  )
  {
    data {
      id
      category
      smartAlert {
        id
        closeAt
      }
      smartAlertLevel
      smartAlertType
      content
      createdAt
      updatedAt
      pauseDuration
      hiatusStartDate
      hiatusAction
      hiatusCategories
      resumeDate
      createdBy {
         id
         profile {
          fullName
        }
      }
      error {
        markedBy {
          id
          createdAt
          profile {
            fullName
          }
        }
        reason
      }
      tags
      result {
        id
        type
        severity
        taskStatus
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
        }
        ... on BGResult {
          blood_glucose
        }
        ... on HSResult {
          body_weight,
          body_weight_change,
          body_weight_change_baseline
        }
        ... on HRResult {
          heart_rate
        }
        measure {
          ${Measure}
        }
        threshold {
          measure
        }
        schedule {
          calendar
          target
          start
          end
          beforeMeal
          mealType
        }
      }
    }
    pageInfo {
      total
      lastPage
    }
  }
}
`
export const providerNoteCursorList = gql`
  query providerNoteCursorList($after: Ref, $before: Ref, $count: Int, $filters: ProviderNoteListCursorOpts, $sort: ProviderNoteListSort) {
    providerNoteCursorList(after: $after, before: $before, count: $count, filters: $filters, sort: $sort) {
      data {
        id
        category
        content
        error {
          markedBy {
            id
            createdAt
            profile {
              fullName
            }
          }
          reason
        }
        createdBy {
          id
          profile {
            fullName
          }
        }
        result {
          id
          ... on BPResult {
            systolic_blood_pressure
            diastolic_blood_pressure
            heart_rate
          }
          ... on BGResult {
            blood_glucose
            beforeMeal
            mealType
          }
          ... on HSResult {
            body_weight
          }
          assessmentFilled {
            id
          }
        }
        createdAt
        updatedAt
      }
      cursorInfo {
        hasMore
        total
        cursor
      }
    }
  }
`

export const updateQueryFetchMore = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) { return previousResult }
  const { providerNoteCursorList: { data, cursorInfo } } = fetchMoreResult
  return {
    providerNoteCursorList: {
      data: [...previousResult.providerNoteCursorList.data, ...data],
      cursorInfo: cursorInfo
    }
  }
}
