import React from "react";
import TMAreaChartComponent from '../components/TMAreaChartComponent';
import moment from "moment/moment";
import { Switch,Radio } from 'antd';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

const TMAreaChartContainer = class extends React.Component {
    constructor(){
        super();
        this.state = {
            allVisit: false,
            // toDate:moment().endOf('day'),
            // fromDate:moment().subtract(2,'week').startOf('day'),
            // timeInterval:'w',
            // value:2
        }
    }

    // changePre = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const { patientId } = this.props;
    //     const updatedFromDate = fromDate.subtract(value,timeInterval).startOf('day');
    //     const updatedToDate = toDate.subtract(value,timeInterval).endOf('day');
    //     const map = {
    //         1:'ONE',
    //         2:'TWO',
    //         3:'THREE',
    //     };
    //     const strVal =  map[value];
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','BACK_TM',null,{ PATIENT_ID:patientId,DURATION: str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // changeNext = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const { patientId } = this.props;
    //     const updatedFromDate = fromDate.add(value,timeInterval).startOf('day');
    //     const updatedToDate = toDate.add(value,timeInterval).endOf('day');
    //     const map = {
    //         1:'ONE',
    //         2:'TWO',
    //         3:'THREE',
    //     };
    //     const strVal =  map[value];
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','NEXT_TM',null,{ PATIENT_ID:patientId,DURATION: str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // renderNextButton = () =>{
    //     const { value,timeInterval } = this.state;
    //     const unit = (timeInterval==='w') ? 'Weeks' :'Months';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <span>{`Next ${value} ${unit}`}</span>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.changeNext()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //             </div>
    // }

    // renderPrevButton = ()=>{
    //     const { value,timeInterval } = this.state;
    //     const unit = (timeInterval==='w') ? 'Weeks' :'Months';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.changePre()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //                 <span>{`Past ${value} ${unit}`}</span>
    //           </div>
    // }


    // setTimeInterval = (updatedTimeInterval,updatedValue)=>{
    //     const { patientId } = this.props;
    //     const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
    //     const updatedToDate = moment().endOf('day');
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate,
    //         timeInterval: updatedTimeInterval,
    //         value: updatedValue
    //     },()=>{
    //         const map = {
    //             1:'ONE',
    //             2:'TWO',
    //             3:'THREE',
    //         };
    //         const str = map[updatedValue]+'_'+(updatedTimeInterval=='w' ? 'WEEK' : 'MONTH');
    //         Mixpanel.track('clicked',str+'_TM',null,{ PATIENT_ID: patientId });
    //     })
    // }

    // renderRadioButton = ()=>{
    //     const { value } = this.state;
    //     const twoWeeksChecked = (value===2) ;
    //     const threeMonthChecked = (value===3) ;
    //     const oneMonthChecked = (value===1) ;

    //     return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginLeft:10, marginRight: 10}}>
    //                 <Radio.Button  value='2' onClick={()=>this.setTimeInterval('w',2)} checked = {twoWeeksChecked}>2 Weeks</Radio.Button>
    //                 <Radio.Button  value='1'  onClick={()=>this.setTimeInterval('M',1)} checked = {oneMonthChecked}>1 Months</Radio.Button>
    //                 <Radio.Button  value='3'  onClick={()=>this.setTimeInterval('M',3)} checked = {threeMonthChecked} >3 Months</Radio.Button>
    //             </Radio.Group>
    // }

    setAllData = ()=>{
        const { patientId } = this.props;

        this.setState({ allVisit: !this.state.allVisit });
        Mixpanel.track('clicked','ALL_DATA_TM',null,{ PATIENT_ID:patientId })
    }

    render(){
        // const { props,renderRadioButton,renderPrevButton,renderNextButton } = this;
        let { fromDate, toDate, xAxisLabelPerDay, ...props } = this.props;
        const { allVisit } = this.state;
        return   <div style={{ padding: 20 }} className='row'>
                    <div style={{display:'flex',flexDirection:'row', justifyContent: 'flex-end'}}>
                        {/* <div style={{ fontSize:16,fontWeight:'bold' }}>Temperature</div>
                           <div style={{marginLeft: 'auto', display: 'flex', marginRight: 40}}>
                                {renderPrevButton()}
                                {renderRadioButton()}
                                {renderNextButton()}
                            </div> */}
                            <span style={{ marginRight: 20, marginLeft: 10 }}>All Data</span> <Switch onChange={()=>this.setAllData()} />
                        </div>

                    <TMAreaChartComponent
                        allVisit = {allVisit}
                        {...props}
                        // changePre={() => this.changePre()}
                        // changeNext={() => this.changeNext()}
                        // setTimeInterval={(i, v) => this.setTimeInterval(i, v)}
                        fromDate={fromDate.toDate()}
                        toDate={toDate.toDate()}
                        // timeInterval = {timeInterval}
                        xAxisLabelPerDay={xAxisLabelPerDay}
                        // value={value} 
                    />

                </div>
    }
}


export default TMAreaChartContainer;
