const types = {
    'general' : {
        text: 'General Visit',
        color: '#6236FF'
    },
    'init/followUp':{
        text:'Initial Visit',
        color:'#36c5cc'
    },
    'followUp':{
        text:'Follow Up Visit',
        color:'#4278c3'
    },
    'additionalVisit':{
        text:'Additional Visit',
        color:'#ee7c81'
    },
    'techVisit': {
        text: 'Tech Visit',
        color: '#FFB310'
    },
    'MNT': {
        text:'MNT',
        color:'#83C858'
    },
    'techOnBoarding': {
        text:'Tech Onboarding',
        color:'#CF51AB'
    }


}

export default types;
