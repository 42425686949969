

const durationMap = [
    {
        key:'TWO WEEKS',
        name:'Two Weeks',
        value:14,
    },
    {
        key:'ONE MONTH',
        name:'One Month',
        value:30
    },
    {
        key:'THREE MONTH',
        name:'Three Month',
        value:90
    },
    {
        key:'SIX MONTH',
        name:'Six Month',
        value:180
    }
];

export { durationMap };
