import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { message } from 'antd';
import getCGMInfo from '../api/getCGMInfo';

const fetchCGMInfo = async (patientId, cb) => {
  const res = await getCGMInfo({ patientId });
  if (!res || res.code !== 200) {
    message.error(`Failed to load CGM status ${res.error || ''}`);
    return;
  }
  cb(res);
};

export const cgmBrandNameMap = {
  DEXCOM: 'Dexcom',
  LIBRE: 'Freestyle Libre',
};

const CGM_OPTIONS = [
  { cgmBrand: 'DEXCOM', name: cgmBrandNameMap.DEXCOM },
  { cgmBrand: 'LIBRE', name: cgmBrandNameMap.LIBRE }
];

export const CGMContext = createContext();

export const useCGMContext = () => {
  const cgmContextValue = useContext(CGMContext);
  return cgmContextValue || {};
};

export const CGMContextProvider = ({
  patientId,
  children
}) => {
  const [CGMInfo, setCGMInfo] = useState({});

  const handleFetchCGMInfo = useCallback(() => {
    fetchCGMInfo(patientId, (res) => {
      setCGMInfo(res.data)
    });
  }, [patientId]);

  useEffect(() => {
    handleFetchCGMInfo();
  }, []);

  const getAvailableCGMBrands = useCallback(() => {
    return CGM_OPTIONS;
  }, []);

  const cgmContextValue = useMemo(() => ({
    CGMInfo,
    hasCGM: !!CGMInfo && !!CGMInfo.isCGMEnabled,
    refetch: handleFetchCGMInfo,
    getAvailableCGMBrands,
  }), [
    CGMInfo,
    handleFetchCGMInfo,
    getAvailableCGMBrands,
  ]);

  return (
    <CGMContext.Provider value={cgmContextValue}>
      {children}
    </CGMContext.Provider>
  );
};
