import {React, BaseComponent, RB, _, getUuid} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
    init(){
        this.active = 0
    }

    render(){

        let itemList = _.clone(this.props.itemList)
        if(this.props.active){
            const active = this.props.active
            const nn = _.findIndex(itemList, (l)=>{return l.id===active})
            _.each(itemList, (item, n)=>{
                if(nn === n){
                    item.selected = true
                    item.type = 'start'
                }
                else if(nn < n){
                    item.type = 'start'
                    item.icon && (item.disabled = true)
                }
                else{
                    item.type = 'end'
                }
            })
        }


        _.each(itemList, (item)=>{
            // if(!item.id){
            //     item.id = getUuid()
            // }
            if(item.selected){
                this.active = item.id
            }
        })

        return (
            <RB.Tab.Container id={getUuid()} onSelect={this.handleOnSelect.bind(this)} className="IH-Tab" activeKey={this.active}>
                <RB.Row className="clearfix">
                    <RB.Col sm={12}>
                        <RB.Nav bsStyle="tabs">
                            {
                                _.map(itemList, (item)=>{
                                    return this.renderEachTitle(item)
                                })
                            }
                        </RB.Nav>
                    </RB.Col>

                    <RB.Col sm={12}>
                        <RB.Tab.Content animation>
                            {
                                _.map(itemList, (item)=>{
                                    return this.renderEachContent(item)
                                })
                            }
                        </RB.Tab.Content>
                    </RB.Col>

                </RB.Row>
            </RB.Tab.Container>
        )
    }

    handleOnSelect(id){
        this.props.onSelect && this.props.onSelect(id)
    }

    renderEachTitle(item){
        if(item.type === 'end' && item.icon){
            item.icon = 'check-circle-o'
        }
        let icon = item.icon ? <i className={`fa fa-${item.icon} ih-${item.type}`}></i> : null
        if(_.isNumber(item.icon) || /^[0-9]$/g.test(item.icon)){
            icon = <i className={`fa ih-n ih-${item.type}`}>{item.icon}</i>
        }

        return (
            <RB.NavItem disabled={item.disabled||false} key={item.id} eventKey={item.id}>
                {icon}
                <span>{item.title}</span>
            </RB.NavItem>
        )
    }

    renderEachContent(item) {
        return this.active !== item.id ? '' : (
            <RB.Tab.Pane key={item.id} eventKey={item.id}>
                {item.content}
            </RB.Tab.Pane>
        )
    }


}

Component.propsType = {
    //big normal
    //size : React.PropTypes.string,

    /*
     * id
     * title
     * content
     * selected
     * disabled
     * type : start, end
     * icon
     */
    itemList : PropTypes.array.isRequired

}

export default Component
