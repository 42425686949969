import gql from 'graphql-tag';

export default gql`
  query inventoryHistoryList($filters: InventoryHistoryListOpts) {
    inventoryHistoryList(filters: $filters) {
      data {
        createdAt
        visit{
          type
        }
        product {
          productType
          quantity
        }
      }
    }
  }
`
