import React, {Component} from 'react';
import map  from '../constants/tableMap';
import { IHTable,IHLoading } from 'ihcomponent';
import { Table } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from "../../I18N";
import setTaskToRead from "../query/setTaskToRead";
import Client from 'libModule/gqlClient';
import API from '../API/index';
import helper from '../helper/index'

const { tableMap, expandedRowColumn } = map;
class TaskAssignmentListComponent extends Component {
    constructor(props){
      super(props);
      this.state = {
        expandedRowKeys: []
      }
    }
    getAllAssignedTo = ()=>{
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;
        const currentUserId = sessionStorage.getItem('currentUserId');
        let currentUser = null;

        const members = [];
        _.forEach(teamsFromLoginUser,t=>_.forEach(t.members,m=>{
            const user = { id:m.user.id,fullName: m.user.profile.fullName };
            if(currentUserId!=m.user.id) {
                members.push(user);
            }else{
                currentUser = user;
            }
        }));
        if(currentUser) members.unshift(currentUser);
        return _.uniqBy(members,m=>m.id);
    }
    
    getArray = (taskType)=>{
        let arr  = [];
        if(taskType=='0'){
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title: I18N.get('taskAssignment.createdBy'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    // const firstTask = _.first(taskAssignments);
                    // const profile = _.get(firstTask,'createdBy');
                    const name = _.get(d, 'profile.fullName', '--');
                    const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                    return <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}} >{name}</p>
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
        }
        if(taskType=='1'){
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title: I18N.get('taskAssignment.assignedTo'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'assignedTo');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
        }
        if(taskType=='2'){
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title: I18N.get('taskAssignment.createdBy'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'createdBy');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))
            })
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title: I18N.get('taskAssignment.assignedTo'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    const firstTask = _.first(taskAssignments);
                    const profile = _.get(firstTask,'assignedTo');
                    return _.get(profile,'profile.fullName');
                },
                filters:_.map(this.getAllAssignedTo(), ({id,fullName})=>({ text:fullName,value:id,key:id }))

            })
        }
        return arr;
    }

    getExpandableArray =(taskType)=> {
        let arr  = []
        if(taskType=='0'){
            arr.push({
                id:'created',
                dataIndex:'createdBy',
                title: I18N.get('taskAssignment.createdBy'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
        }
        if(taskType=='1'){
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title: I18N.get('taskAssignment.assignedTo'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
        }
        if(taskType=='2'){
            arr.push({
                id:'createdBy',
                dataIndex:'createdBy',
                title: I18N.get('taskAssignment.createdBy'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })
            arr.push({
                id:'assignedTo',
                dataIndex:'assignedTo',
                title: I18N.get('taskAssignment.assignedTo'),
                width: '120px',
                render:(d,{taskAssignments})=>{
                    return _.get(d,'profile.fullName');
                }
            })

        }
        return arr;
    }

    sortRead = (data) => {
        let taskUnRead = [];
        let taskRead = [];
        _.forEach(data, (task) => {
            if(task.isRead && task.isRead.value) {
                taskRead.push(task);
            } else {
                taskUnRead.push(task);
            }
        })
        let task = taskUnRead.concat(taskRead)
        return task;
    }

    getTableProps = ()=>{
        const { onTableChange, page, loading,setModalData,taskList,refetch,pageInfo,taskType } = this.props;
        const { expandedRowKeys } = this.state;
        _.forEach(taskList, (task) => {
            let taskAssignments = _.get(task, 'taskAssignments', []);
            taskAssignments = this.sortRead(taskAssignments);
            task.taskAssignments = taskAssignments;
        })
        const columns = tableMap(this.props,this.getArray(taskType)).map(({ id,dataIndex,title,render,width,sorter,sortOrder,filters,filteredValue,onFilter },i)=>{
            return {
                key: id,
                title,
                render,
                width,
                dataIndex:dataIndex||id,
                sorter,
                sortOrder,
                filters,
                filteredValue,
                onFilter,
                id
            }
        });
        const noDataElem = (
            <div style={{ height: '150px', paddingTop: '2em' }}>
                <p style={{ fontSize: '18px' }}>There is no data!</p>
            </div>
        );

        const expandedRowRender = (a, b, c) =>{
            const [first, ...taskAssignments] = a.taskAssignments;
            return taskAssignments.length ? <Table dataSource={taskAssignments} columns={expandedRowColumn(this.props, this.getExpandableArray(taskType))}
                         rowKey={({ id }) => id}
                         loading = {{ spinning: !!loading,indicator: <IHLoading/> }}
                         onRow = {(taskAssignment) => {
                            const doc = {
                              ..._.clone(taskAssignment),
                              refetch
                            };
                            const id = doc.id;

                            return {
                                onClick: () => {
                                    if(helper.checkNeedFold(taskAssignment)) {
                                        Client.mutate({
                                            mutation:setTaskToRead,
                                            variables:{ id }
                                        }).then(res=>{
                                            if(refetch){
                                                refetch();
                                            };
                                            API.fetchTaskUnreadTasks();
                                        })
                                    }
                                    setModalData({ showModal:true, modalData:Object.assign({ refetch },doc) })
                                }
                                }
                            }}
                         pagination={false} showHeader={false} rowClassName='nestedRowClass'/>
                  : '';
        }
                         
        const pagination = {
            current: _.get(page, 'current', 1),
            pageSize: _.get(page, 'pageSize', 10),
            total: _.get(pageInfo, 'total', 0),
        };

        const tableProps = {
            columns,
            pagination,
            // title:'Task Assignments',  //remove the title
            noDataElem,
            dataSource:taskList,
            loading: { spinning:!!loading,indicator: <IHLoading/> },
            rowKey: (doc, i) => _.get(doc, 'member.id', i),
            expandedRowKeys,
            expandedRowRender,
            onExpand:(d,doc)=>{
              const newExpandedKey = _.get(doc, 'member.id');
              let res = [];
              // let rowIndex = _.findIndex(taskList,(t)=>doc.member.id==t.member.id);
              if(!_.includes(expandedRowKeys, newExpandedKey)){
                res.push(newExpandedKey);
              }
              this.setState({ expandedRowKeys:res });
            },
            onChange: (p, f, s) => {
                const newFilter = { ...f};
                const newPage = {...p};
                return onTableChange(newPage, newFilter, s)
            },
            onRow(d){
                const firstTask = _.first(d.taskAssignments);
                const doc = {
                  ..._.clone(firstTask),
                  refetch
                };
                const id = doc.id;
                const { priorityLevel,dueDate } = doc;
                return {
                    onClick: () => {
                        if(helper.checkNeedFold(firstTask)) {
                            Client.mutate({
                                mutation:setTaskToRead,
                                variables:{id}
                            }).then(res=>{
                                if(refetch){
                                    refetch();
                                };
                                API.fetchTaskUnreadTasks();
                            })
                        }
                        setModalData({ showModal:true, modalData:doc })
                        Mixpanel.track('clicked', 'task', 'task list page', {PATIENT_ID: id, PRIORITY_LEVEL: priorityLevel, DUE_IN: dueDate});
                    }
                }
            },
            rowClassName: (x) => x.taskAssignments.length > 1 ? '' : 'hidden-extend-btn'
        }
        return tableProps
    }

    render(){
        const tableProps = this.getTableProps();
        // Because we remove the search function and table title, it's better to user IHTable rather then IHSearchTable
        // for it will not have the header line
        return <div id='task-list-table-wrapper'>
          <IHTable  { ...tableProps} className="taskAssignmentListTable" />
        </div>
    }
}

export  default TaskAssignmentListComponent;
