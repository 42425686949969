import React from 'react'
import VitalListTable from './VitalListTableComponent'

const VitalList = (props) => {
  // return (
  //     <div className="vsm-main-page vsm-patientList-main">
  //         <div className="vsm-main-990">
  //             <div className="v-table">
  //                 <VitalListTable {...props} />
  //             </div>
  //         </div>
  //     </div>
  // )
  return (
    <VitalListTable {...props} />
  )
}

export default VitalList
