import React from 'react';
import { tabToComponent, getValueFunMap } from '../helpers';
import { Form } from 'antd';
import { updatePatientComplexity } from '../../main/API';
import { eventHelperCreator, EVENT_MAPS } from '../../../../lib/helpers/event-helpers';

const eventHelper = eventHelperCreator(EVENT_MAPS.complexity, 'icdCode');

let UnenrolledPatientDetailsContainer = class extends React.Component{
    constructor(props){
        super(props);
    }

    onSave = async () =>{
        const { selectedTab, form, save, user } = this.props;
        const { getFieldsValue, validateFieldsAndScroll } = form;
        const formValues = getFieldsValue();
        const parseValueFn = getValueFunMap[selectedTab];

        const parsedValue = parseValueFn(this.props,formValues,this.state)

        const updateComplexity = async () => {
            if (selectedTab === 'ICDCODES') {
                const { complexityLevel } = formValues;
                if (complexityLevel) {
                    const { level, source } = complexityLevel;
                    try {
                        await updatePatientComplexity({ memberId: user.id, level, source });
                        eventHelper.create();
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        }

        if(selectedTab === 'DETAIL'){
            // 20200327 Thong - can be changed in getMutateVariableInfo, but need a confirmation
            const capitalizedFn = _.get(parsedValue, 'commonProfile.firstName').charAt(0).toUpperCase() + 
                                    _.get(parsedValue, 'commonProfile.firstName').substring(1);
            const capitalizedLn = _.get(parsedValue, 'commonProfile.lastName').charAt(0).toUpperCase() + 
                                    _.get(parsedValue, 'commonProfile.lastName').substring(1);;
            _.set(parsedValue, 'commonProfile.firstName', capitalizedFn);
            _.set(parsedValue, 'commonProfile.lastName', capitalizedLn);
        }

        validateFieldsAndScroll(async (error,fields)=>{
            if(!error) {
                await updateComplexity();
                save(parsedValue);
            }
        })
    }

    render(){
        const { selectedTab } = this.props;
        return <div className={`patientInfo-container ${_.lowerCase(selectedTab)}-tab`}>
            {tabToComponent(this)}
        </div>
    }
}

UnenrolledPatientDetailsContainer= Form.create({
    onFieldsChange:(props)=>{
        const {isEditMode, setEditMode} = props;
        if(!isEditMode) {
            setEditMode(true);
        }
}
})(UnenrolledPatientDetailsContainer);

export default UnenrolledPatientDetailsContainer;