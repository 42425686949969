import React from 'react';

import SignOffVisit from '../component/SignOff/Items/SignOffVisit';
import SignOffAlert from '../component/SignOff/Items/SignOffAlert';
import SignOffMTPR from '../component/SignOff/Items/SignOffMTPR';
import SignOffCallLog from '../component/SignOff/Items/SignOffCallLog';
import SignOffFoodLog from '../component/SignOff/Items/SignOffFoodLog';
import SignOffTaskAssignment from '../component/SignOff/Items/SignOffTaskAssignment';
import SignOffChat from '../component/SignOff/Items/SignOffChat';
import SignOffProviderNote from '../component/SignOff/Items/SignOffProviderNote';
import SignOffCarePlan from '../component/SignOff/Items/SignOffCarePlan'; 

export const ITEM_TYPE_ENUM = {
  VISIT: 'VISIT',
  CLINICAL_ALERT: 'CLINICAL_ALERT',
  PROVIDER_ALERT: 'PROVIDER_ALERT',
  MONTHLY_REVIEW: 'MONTHLY_REVIEW',
  FOOD_LOG: 'FOOD_LOG',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  CHAT_HISTORY: 'CHAT_HISTORY',
  PROVIDER_NOTE: 'PROVIDER_NOTE',
  CALL_LOG: 'CALL_LOG',
  CARE_PLAN: 'CARE_PLAN',
};

export const ITEM_TYPE_RENDER_MAP = {
  [ITEM_TYPE_ENUM.VISIT]: ({ data, ...restProps }) => <SignOffVisit visits={data} {...restProps} />,
  [ITEM_TYPE_ENUM.CLINICAL_ALERT]: ({ data, ...restProps }) => <SignOffAlert headerText='Clinical Alert' alerts={data} {...restProps} />,
  [ITEM_TYPE_ENUM.PROVIDER_ALERT]: ({ data, ...restProps }) => <SignOffAlert headerText='Provider Alert' alerts={data} {...restProps} />,
  [ITEM_TYPE_ENUM.MONTHLY_REVIEW]: ({ data }) => <SignOffMTPR monthlyReviews={data} />,
  [ITEM_TYPE_ENUM.FOOD_LOG]: ({ data }) => <SignOffFoodLog foodLogs={data} />,
  [ITEM_TYPE_ENUM.TASK_ASSIGNMENT]: ({ data }) => <SignOffTaskAssignment tasks={data} />,
  [ITEM_TYPE_ENUM.CHAT_HISTORY]: ({ data }) => <SignOffChat chatHistory={data} />,
  [ITEM_TYPE_ENUM.PROVIDER_NOTE]: ({ data }) => <SignOffProviderNote providerNotes={data} />,
  [ITEM_TYPE_ENUM.CALL_LOG]: ({ data }) => <SignOffCallLog callLogs={data} />,
  [ITEM_TYPE_ENUM.CARE_PLAN]: ({ data, ...restProps }) =>  <SignOffCarePlan carePlan={data} {...restProps} />,
};

export const ITEM_TYPE_DATA_GETTER = {
  [ITEM_TYPE_ENUM.CHAT_HISTORY]: (data) => _.first(data),
  [ITEM_TYPE_ENUM.CARE_PLAN]: (data) => _.first(data),
  DEFAULT: (data) => data
};