import { Address, Phone, Identification } from './common';
import { NutritionSchema } from '../schema/Nutrition';
import { HandoutSchema } from './Handout';
import { SpecialistInfoSchema } from '../schema/SpecialistInfo';
import { UserRemoteEnrollmentSchema } from './RemoteEnrollment';
import { UserPreferenceSchema } from '../schema/UserPreference';
import UserDeviceRecords from './UserDeviceRecords';
import { PatientInsuranceProvider } from './InsuranceProvider';

const UserProfile = `
  title
  firstName
  lastName
  nickname
  fullName
  fullNameWithNickname
  gender
  avatar {
    link
  }
  ...on EmployeeProfile {
    startDate
    hasCredential
  }
  ...on MemberProfile {
    isSelfEnrolled
    techLevel
    motivation
    birthday
    callLogFlag
    subscription {
        status
    }
    claimedTimeLogFlag
    lastMeasuredAt
    lastMeasuredByType{
      BG,BP,PO,TM,HS,ECG,HR,EXERCISE
    }
    race {
      code
      description
    }
    race1 {
      code
      description
    }
    insuranceProvider
    insuranceProvider2
    insuranceProviders {
      ${PatientInsuranceProvider}
    }
    insuranceUpdatedAt
    economicStatus
    occupation
    doctor{
      id
      profile{
        fullName
        firstName
        lastName
      }
    }
    programCategories{
      name
      enrolled
      enrolledDate
    }
    language {
      code
      description
    }
    appLanguage {
      code
      description
    }
    maritalStatus{
      code
      description
    }
    nationality {
      code
      description
    }
    height
    weight
    latestWeight
    healthConditions
    billableHealthConditions{
      description
      code
    }
    mntHealthConditions{
        description
        code
    }
    yearOfDiagnose {
      condition
      diagnosedDate
    }
    deviceRecords{
      ${UserDeviceRecords}
    }
    insulinDependent
        BGTreatments
        BPTreatments
        stroke
        cardiovascularDisease
        retinopathy
        BGOther
        BGVisits
        BPVisits
        BGMedicine
        BGTest{
          testRegularly
          freq
          unit
        }
        BPTest{
          testRegularly
          freq
          unit
        }
        kidneyDisease
        liverDisease
        heartAttack{
          hasHeartAttack
          year
         }
        exerciseHabits{
          value
          freq{
            value
            unit
          }
          duration
        }
        dietHabits{
          value
          other
          dietProperties
        }
        drinkingHistory{
          value
          startSinceYear
          drinkType
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        smokingHistory{
           value
          startSinceYear
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        spouseMedicalHistory
        fatherMedicalHistory
        motherMedicalHistory
        siblingMedicalHistory
        paternalFatherMedicalHistory
        paternalMotherMedicalHistory
        paternalUnclesOrAuntsMedicalHistory
        maternalFatherMedicalHistory
        maternalMotherMedicalHistory
        maternalUnclesOrAuntsMedicalHistory
    routine
    sleepingRoutine
    eatingRoutine
    sleepFreq
    lifestylePattern
    lifestyleNote
    activityLevel
    educationLevel
    foodAccessibility
    workDuties
    socialSupport{
      communitySupport
      contactInfo
      otherProviders
      extraNote
    }
    BGSpecialistInfo{
      ${SpecialistInfoSchema}
    }
    BPSpecialistInfo{
      ${SpecialistInfoSchema}
    }
    chronicDiseaseHistory{
      name
      yearsWithCondition
      comments
      takingMedication
      otherTreatment
      seenDoctor
      testRegularly
      freq
      unit
      treatments
      specialistInfo{
        ${SpecialistInfoSchema}
      }
      seenDoctor
      testRegularly
      freq
      unit
      treatments
    }
    chronicDiseaseNote
    medicationManagement {
      reconcilationCompliance
      otherInfo
      extraNote
    }
    ${NutritionSchema}
    ${HandoutSchema}
    hasSmartPhone
    conditionsList {
      HLD
      HTN
      DM
      CKD
      Obesity
      PreDM
      COPD
      ESRD_Dialysis
    }
  }
  ...on EmployeeProfile {
    employeeId
    department
    specialty
  }
`

export const UserNProfile = `
title
firstName
lastName
nickname
fullName
fullNameWithNickname
gender
...on MemberProfile {
  isSelfEnrolled
  techLevel
  motivation
  birthday
  callLogFlag
  subscription {
      status
  }
  claimedTimeLogFlag
  lastMeasuredAt
  lastMeasuredByType{
    BG,BP,PO,TM,HS,ECG,HR,EXERCISE
  }
  race {
    code
    description
  }
  race1 {
    code
    description
  }
  insuranceUpdatedAt
  economicStatus
  occupation
  doctor{
    id
    profile{
      fullName
      firstName
      lastName
    }
  }
  programCategories{
    name
    enrolled
    enrolledDate
  }
  language {
    code
    description
  }
  appLanguage {
    code
    description
  }
  maritalStatus{
    code
    description
  }
  nationality {
    code
    description
  }
  height
  weight
  latestWeight
  healthConditions
  billableHealthConditions{
    description
    code
  }
  mntHealthConditions{
      description
      code
  }
  hasSmartPhone
  conditionsList {
    HLD
    HTN
    DM
    CKD
    Obesity
    PreDM
    COPD
    ESRD_Dialysis
  }
}
`

export const Email = `
  address
  verified
`

const Organization = `
  id
  name
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  departments {
    name
    code
  }
  specialties {
    name
    code
  }
  notePreset {
    category
    display
    subCategories
  }
`

const UserWithoutCaregivers = `
  id
  isTestUser
  devices{
    status
    address
    model
    type
  }
  createdAt
  updatedAt
  registeredAt
  assignees{
     assignedToRD{
      id
      profile{
        fullName
      }
    }
    assignedToCA{
      id
      profile{
        fullName
      }
    }
  }
  selectedRole {
    refId
    name
    category
    organization {
      ${Organization}
    }
    access  {
      action
      rule
    }
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
      internalName
      leaf
      parent {
        id,
        name
      }
    }
    active
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  profile {
    ${UserProfile}
  }

  team {
    id
    name
    healthCondition
    organization {
      id
    }
  }
  userSettings {
    preferredUnit
    notification
    listeningOrgs
    sendHeartbeatLog
    heartbeatInterval
    snapshotInterval

  }
  userPreference {
    ${UserPreferenceSchema}
  }
  starred
  starredReason
  hasFlag
  flagDetails {
    reason
    createdAt
  }
  hasPassword
  isDefaultPassword
  deleted
  remoteEnrollment {
    ${UserRemoteEnrollmentSchema}
  }
  enrollmentDate
  latestVisit {
    id
    appointmentAt
  }
`

const User = `
  ${UserWithoutCaregivers}
  caregiver {
    ${UserWithoutCaregivers}
  }
   monthlyTimeSpent {
      totalTime
      monthOfYear
      updatedAt
    }
    taskAssignmentCount
    assignees{
      assignedToRD{
          id
          profile {
            firstName,
            lastName
          }
      }
      assignedToCA{
          id
          profile {
            firstName,
            lastName
          }
      }
      assignedToCANotNecessary
      assignedToRDNotNecessary
    }
`
const UserWithoutTeam = `
  id
  createdAt
  updatedAt
  remoteEnrollment{
    ${UserRemoteEnrollmentSchema}
  }
  assignees{
     assignedToRD{
      id
      profile{
        fullName
      }
    }
    assignedToCA{
      id
      profile{
        fullName
      }
    }
    assignedToCANotNecessary
    assignedToRDNotNecessary
  }
  selectedRole {
    category
    name
    organization {
      ${Organization}
    }
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  assignees{
    assignedToRD{
     id
     profile{
       fullName
     }
   }
   assignedToCA{
     id
     profile{
       fullName
     }
   }
 }
  identification {
    ${Identification}
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
    }
    active
  }
  assignees{
    assignedToRD{
     id
     profile{
       fullName
     }
   }
   assignedToCA{
     id
     profile{
       fullName
     }
   }
 }
  profile {
    ${UserProfile}
  }
`

const UserForSessionStorage = `
  id
  username
  profile {
    firstName
    lastName
    fullName
    avatar {
      link
      fileKey
    }
  }
  identification {
    ${Identification}
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      leaf
      active
      name
      logo
      brandedName
      departments {
        name
        code
      }
      specialties {
        name
        code
      }
      notePreset {
        category
        display
        subCategories
      }
      programParticipation
    }
  }
  selectedRole {
    organization {
      id
      name
      logo
      brandedName
      departments {
        name
        code
      }
      specialties {
        name
        code
      }
      notePreset {
        category
        display
        subCategories
      }
    }
  }
  team {
    id
    name
    healthCondition
    members {
      user {
        id
        profile {
          firstName
          lastName
          fullName
          avatar {
            link
          }
        }
        allRoles {
          refId
          name
          category
          organization {
            id
          }
        }
        selectedRole {
          category
          name
        }
      }
      primary
    }
    consentProviderName
  }
`

const UserMini = `
  id
  profile {
    fullName
    fullNameWithNickname
    avatar {
      fileKey
      link
      thumbnailLink
    }
    firstName
    lastName
    ...on MemberProfile {
      appLanguage { 
        code
      }
      language {
        code
      }
    }
  }
  identification {
    ${Identification}
  }
  allRoles {
    name
    category
    startFrom
    organization{
      id
    }
    refId
  }
`
const UserName= `
  profile {
    fullName
    fullNameWithNickname
  }
`

const UserMiniWithEmail = `
  id
  profile {
    fullName
    fullNameWithNickname
  }
  emails {
    ${Email}
  }
`

const UserMiniWithPhone = `
  id
  profile {
    fullName
    fullNameWithNickname
  }
  phones {
    ${Phone}
  }
`
const UserMiniWithSetting = `
  id
  userSettings{
    listeningOrgs
  } 
`

const UserMiniForVendor = `
  id
  emails {
    ${Email}
  }
  addresses {
    ${Address}
  }
  phones {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  profile {
    fullName
    fullNameWithNickname
    gender
    avatar
    ...on MemberProfile {
      birthday
      language {
        description
      }
      nationality {
        description
      }
      height
      weight
      latestWeight
    }
  }
`;

const ProviderProfile = `
  id
  capacitys{
        role
        capacity
  }
  isTestUser
  createdAt
  updatedAt
  registeredAt
  selectedRole {
    refId
    name
    category
    organization {
      ${Organization}
    }
    access  {
      action
      rule
    }
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
      internalName
      leaf
      parent {
        id,
        name
      }
    }
    active
  }
  profile {
    title
    firstName
    lastName
    nickname
    fullName
    fullNameWithNickname
    gender
    avatar {
      link
    }
    ...on EmployeeProfile {
      employeeId
      department
      specialty
      startDate
      NPI
      directAddress
      mentor{
        id
        profile{
            fullName
        }
      }
      healthCoach {
        id
        profile {
            fullName
        }
      }
      providerLanguage{
        code
        description
      }

      hasCredential
    }
  }
  team {
    id
    name
    healthCondition
    organization {
      id
    }
  }
  userSettings {
    preferredUnit
    notification
    listeningOrgs
    sendHeartbeatLog
    heartbeatInterval
    snapshotInterval
  }
  lastLogIn{
    id
  }
`
const UserForAdmin = `
  id
  createdAt
  updatedAt
  registeredAt
  selectedRole {
    refId
    name
    category
    organization {
      ${Organization}
    }
    access  {
      action
      rule
    }
  }
  allRoles {
    refId
    name
    category
    organization {
      id
      name
      internalName
      leaf
      parent {
        id,
        name
      }
    }
    active
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  profile {
    ${UserProfile}
  }
  team {
    id
    name
    healthCondition
    organization {
      id
    }
  }
  userSettings {
    preferredUnit
    notification
    listeningOrgs
    sendHeartbeatLog
    heartbeatInterval
    snapshotInterval

  }
  isTestUser
`

export {
    User,
    UserName,
    UserMini,
    UserWithoutTeam,
    UserMiniWithEmail,
    UserMiniWithPhone,
    UserMiniForVendor,
    UserMiniWithSetting,
    UserForSessionStorage,
    Address,
    UserProfile,
    ProviderProfile,
    UserForAdmin
}
