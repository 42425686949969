const ONE_MONTH_MEMBERSHIP = '1 month Membership '
const UNSUBSCRIBED = 'Unsubscribed';
const SUBSCRIBED = 'Subscribed';
const EXPIRED = 'Expired';
export const SUBSCRIPTION_STATUS_MAP = (type)=>{
	let HISTORY = {
		SUBSCRIBED: ONE_MONTH_MEMBERSHIP,
		RENEW: ONE_MONTH_MEMBERSHIP,
		CANCEL: SUBSCRIBED,
		EXPIRED: EXPIRED
	}
	let TAG = {
		SUBSCRIBED: SUBSCRIBED,
		RENEW: SUBSCRIBED,
		CANCEL: SUBSCRIBED,
		EXPIRED: EXPIRED
	}
	if(!type) {
		return TAG;
	}
	return {
		HISTORY,
		TAG
	}[type]
};

export const parseSubscriptionStatus = (status,type)=>{
	return  { label: _.get(SUBSCRIPTION_STATUS_MAP(type),status,status),className: _.get(SUBSCRIPTION_STATUS_MAP(type),status,status) }
}