import {React, _,IHLoading} from 'ihcomponent';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { Spin } from 'antd';
import enrolledPatientStatistics from 'modulesAll/graphql/enrolledPatientStatistics'
import EnrolledPatientStatsComponent from '../components/EnrolledPatientStatsComponent';
import EnrolledOverallStatsComponent from '../components/EnrolledOverallStatsComponent';
import { getSortedAdminProgramFormSessionStorage } from 'modulesAll/utils/helpers/program'

const EnrolledPatientStatsContainer = class extends React.Component {
  static displayName = 'dashboard/containers/EnrolledPatientStatsContainer'

  render() {
    const { enrolledPatientStatistics, currentProgram, title } = this.props;
    // if (currentProgram && !currentProgram.length) {
    //   return (
    //     <div className="barbardos-enrolled-stats_box">
    //       <div className="barbardos-header">
    //         <div>`Population of ${title}`</div>
    //       </div>
    //       <span>no program selected</span>
    //     </div>
    //   );
    // }
      if (this.props.loading) {
      return (
        <div style={{height:'100%',justifyContent:'center',alignItems: 'center',display:'flex', paddingLeft: 200}}>
          <IHLoading />
        </div>
      );
    }
    return (
      <div className="barbardos-enrolled-stats_box">
        <div className="barbardos-header">
          <div>Population of {title}</div>
        </div>
        <EnrolledOverallStatsComponent enrolledStats={enrolledPatientStatistics} />
        <EnrolledPatientStatsComponent enrolledStats={enrolledPatientStatistics} className={this.props.className} />
      </div>
    );
  }
}

const withData = graphql(enrolledPatientStatistics, {
  options(ownProps){
    const adminProgramIds = ownProps.currentPrograms

    return {
      variables : {
        adminProgramIds
      }
      // notifyOnNetworkStatusChange: true,
      // fetchPolicy: 'network-only',
      // skip: !adminProgramIds
    }
  },
  props({ data }){
    if (data && data.enrolledPatientStatistics) {
      const { enrolledPatientStatistics, loading } = data;
      return {
        loading,
        enrolledPatientStatistics
      }
    }
  }
})

EnrolledPatientStatsContainer.defaultProps = {
  loading: true
};

function mapStateToProps(state) {
  return {
    currentProgram: state.dashboard.main.currentProgram
  }
}

export default compose(
  connect(mapStateToProps, null),
  withData,
)(EnrolledPatientStatsContainer);
