import moment from "moment/moment";
import { programCategoriesMap } from "../constants/map";
import { getMemberProfile } from "./index";
import { familyHistoryKeys } from "../constants/familyHistory";
import yearOfDiagnoseMap from "../../../patientList/registerNew/constants/yearOfDiagnose";
import convertor from "@ihealth/convertor/index";
import i18nMap from "../../../I18N";
import { convertFeetToInches } from 'libModule/utils/convertUnits';
import { parseNutritionVariables } from '../../../careplanNew/helpers/nutritionHelpers';
import { schema as PatientSchema } from '../../../patientList/registerNew/components/formData/AddNewPatientForm';
import $ from 'jquery';
import setPatientInsurance from '../../InsuranceCopayComponent/hooks/setPatientInsurance';
import { message } from 'antd';
const parseProgramCategories = (data)=>{
    let programCategoriesUpdated = [];
    const keys = Object.keys(programCategoriesMap);
    _.forEach(keys,(k)=> {
        const key = data[`${k}`];
        if (key&&key!==null) {
            programCategoriesUpdated.push({
                name:programCategoriesMap[k],
                enrolled:key.enrolled,
                enrolledDate:key.enrolledDate
            })
        }
        if(typeof key=='undefined'){
            const unchangedItem = data.programCategories&&data.programCategories.filter(item=>item.name==programCategoriesMap[k])||[];
            if(unchangedItem.length>0) {
                programCategoriesUpdated.push({
                    name: unchangedItem[0].name,
                    enrolled: unchangedItem[0].enrolled,
                    enrolledDate:unchangedItem[0].enrolledDate
                });
            }
        }
    });
    return programCategoriesUpdated;
}
const parseYearOfDiagnose = (data)=>{
    let yearOfDiagnoseUpdated = [];
    const keys = Object.keys(yearOfDiagnoseMap);
    _.forEach(keys,(k)=> {
        const key = data[`${k}`];
        if (key&&key!==null) {
            yearOfDiagnoseUpdated.push({
                condition:yearOfDiagnoseMap[k],
                diagnosedDate:key
            })
        }
        if(typeof key=='undefined'){
            const unchangedItem = data.yearOfDiagnose&& data.yearOfDiagnose.filter(item=>item.condition==yearOfDiagnoseMap[k])||[];
            if(unchangedItem.length>0) {
                yearOfDiagnoseUpdated.push({
                    condition: unchangedItem[0].condition,
                    diagnosedDate: unchangedItem[0].diagnosedDate
                });
            }
        }
    });
    return yearOfDiagnoseUpdated;
}

const  getMutateVariableInfo = (props,hasData)=>{
    const { userId,user } = props;
    const getFieldsValue  = _.get(props,'form.getFieldsValue');
    const data = hasData||getFieldsValue();
    let memberProfile = {}
    let commonProfile = {};
    let address = [];
    const { language,maritalStatus,DOB,gender,
        occupation,economicStatus,race,race1, firstName,lastName, nickname,
        streetName,streetNumber='',state,unit,city,country='US',postCode,insuranceProvider,insuranceProvider2, insuranceProviders,
        canUseForLogin, ChartA1C,EMR_Charting,CCM,motivation,pinnedToEMR, hasCopay, copay,
    } = data;
    const email = data.email ? data.email.trim() : _.get(user,'email.0.address',[]);
    const identificationSrc = user.identification;
    const nric = data.identification;
    if(streetName&&state&&city&&country&&postCode){
        let addressObj = {
            type:'MAIN',
            streetName,
            streetNumber,
            city,
            unit,
            state,
            country,
            postCode
        }
        address[0]= addressObj;
    }

    if(race){
        memberProfile.race = race;
    }

    if(race1){
      memberProfile.race1 = race1;
    }

    if (language) {
        memberProfile.language = language
    }


    if(DOB) {
        memberProfile.birthday = moment(DOB).format('YYYY-MM-DD');
    }

    if(maritalStatus){
        memberProfile.maritalStatus = maritalStatus;
    }
    if(firstName) {
        commonProfile.firstName = firstName
    }
    if(lastName) {
        commonProfile.lastName = lastName
    }
    if(typeof nickname === 'string') {
        commonProfile.nickname = nickname;
    }

    if(gender) {
        commonProfile.gender = gender
    }

    if(economicStatus){
        memberProfile.economicStatus = economicStatus;
    }

    if(typeof insuranceProvider === 'string'){
        memberProfile.insuranceProvider = insuranceProvider;
    }
    if(typeof insuranceProvider2 === 'string'){
        memberProfile.insuranceProvider2 = insuranceProvider2;
    }
    if(insuranceProviders) {
      memberProfile.insuranceProviders = _.map(insuranceProviders, e => ({ id: e.id, primary: e.primary }));
    }
    
    let programCategories = parseProgramCategories(data);
    memberProfile.programCategories = programCategories;

    if(occupation){
        memberProfile.occupation = occupation;
    }
    if(motivation){
        memberProfile.motivation = motivation;
    }
    const emrList = {}
    if(EMR_Charting) {
      emrList.EMR_Charting = EMR_Charting;
    }
    if(ChartA1C) {
      emrList.ChartA1C = ChartA1C;
    }
    if(CCM) {
      emrList.CCM = CCM;
    }
    if(pinnedToEMR) {
      emrList.pinnedToEMR = pinnedToEMR;
    }
    if(_.keys(emrList).length) {
      memberProfile.emrList = emrList;
    }
    let phone = [];
    if (data.homePhone) {
        phone.push({
            number: data.homePhone && data.homePhone.trim(),
            type: 'HOME',
            countryCode: '+1'
        });
    }

    if (data.mobilePhone) {
        phone.push({
            type: 'MOBILE',
            number: data.mobilePhone && data.mobilePhone.trim(),
            countryCode: '+1',
            canUseForLogin: typeof canUseForLogin=='boolean' ? canUseForLogin : (typeof canUseForLogin==='string' &&canUseForLogin.length>0) ? canUseForLogin=='true' : true,
            private: data.isPrivateNumber ? true : false
        });
    }

    let identification = {};
    if(nric){
        let src = identificationSrc[0];
        identification = {
            type : _.get(src,'type.code') || _.get(PatientSchema, 'nationtype.initialValue') || 'SP',
            value: nric
        }
    }

    let result = {
        id: btoa(userId),
        memberProfile,
        commonProfile,
        email,
        phone,
        address,
        identification
    }

    if (typeof hasCopay !== 'undefined') {
      setPatientInsurance({
        patientId: userId,
        hasCopay,
        copay,
      }).catch(message.error);
    }

    // if(email){
    //     result.email = email;
    // }
    return result;
}

const getPatientInfoVariables = (props, hasData, state) => {
  let results = getMutateVariableInfo(props, hasData),
      memberProfile = {};
  let isTestUserCheck = _.get($('#isTestUserCheckbox'),'0.checked',null);
  const getFieldsValue  = _.get(props,'form.getFieldsValue');
  const data = hasData || getFieldsValue();
  // temp: add missing fields to result
  const { techLevel, username, doctorId, height, height_inches, weight, behaviorChange, hasSmartPhone, isSelfEnrolled } = data;
  if(typeof isTestUserCheck == 'boolean') results.isTestUser = isTestUserCheck;
  if(username) results.username = username;
  if(techLevel) memberProfile.techLevel = techLevel;
  if(doctorId) memberProfile.doctorId = doctorId;
  memberProfile.isSelfEnrolled = isSelfEnrolled
  if(typeof hasSmartPhone == 'boolean') memberProfile.hasSmartPhone = hasSmartPhone;

  if ((height && height >= 0 && height < 10) && (height_inches >= 0 && height_inches < 12)) {
    const feetToInches = Number(convertFeetToInches(height||0));
    const convertedHeight = convertor.height(feetToInches + Number(data.height_inches||0), 'inch', 'cm');
    memberProfile.height = {
      value: convertedHeight,
      unit: i18nMap.get('measure.units_height_cm')
    };
  } else if (height !== undefined) {
    memberProfile.height = null;
  }
    

  if(typeof weight=='number' && weight >= 25 && weight < 1000) {
    const convertedWeight = convertor.HS(weight, 'lbs', 'kg');
    memberProfile.weight = {
      value: convertedWeight,
      unit: i18nMap.get('measure.units_weight_kg')
    };
  } else if (weight !== undefined) {
    memberProfile.weight = null;
  }

  if(behaviorChange){
    let nutrition = parseNutritionVariables(
      _.get(props, 'user.profile.nutrition'),
      { behaviorChange }
    );
    memberProfile.nutrition = nutrition;
  }

  if(_.keys(memberProfile).length) {
    results.memberProfile = {
      ...results.memberProfile,
      ...memberProfile
    };
  }

  return results;
}



const getMutateVariableFamilyHistory = (props,hasData)=>{
    const { userId,user } = props;
    const getFieldsValue  = _.get(props,'form.getFieldsValue');
    const data = hasData||getFieldsValue();
    let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday'])

    familyHistoryKeys.map(k=>memberProfile[`${k}`]=data[`${k}`]);

    const result = {
        id: btoa(userId),
        memberProfile,
    }

    return result;
}

const getMutateVariableLifeStyle = (props,hasData)=>{
    const { userId,user } = props;
    const getFieldsValue  = _.get(props,'form.getFieldsValue');
    const data = hasData||getFieldsValue();
    let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday'])
    const { dietHabits,drinkingHistory,exerciseHabits,smokingHistory } = data;
    memberProfile.dietHabits = {
        value: dietHabits.value,
        other: dietHabits.other,
        dietProperties: dietHabits.dietProperties
    };


    if((typeof drinkingHistory.value)=='boolean') {
        memberProfile.drinkingHistory = {
            value: drinkingHistory.value,
            drinkType: drinkingHistory.drinkType,
            freq:{
                value:Number(drinkingHistory.freq),
                unit: 'times/day'
            },
            amount: drinkingHistory.amount,
            quitYear: drinkingHistory.quitYear,
            startSinceYear: drinkingHistory.startSinceYear,
        };

        if ((typeof drinkingHistory.freq)!='number') {
            memberProfile.drinkingHistory = _.omit(drinkingHistory,'freq');
        }
    }


    if((typeof smokingHistory.value)=='boolean') {
        memberProfile.smokingHistory = {
            value: smokingHistory.value,
            freq:{
                value:Number(smokingHistory.freq),
                unit: 'times/day'
            },
            quitYear: smokingHistory.quitYear,
            startSinceYear: smokingHistory.startSinceYear,
            amount: smokingHistory.amount
        };

        if ((typeof smokingHistory.freq)!='number') {
            memberProfile.smokingHistory = _.omit(smokingHistory,'freq');
        }
    }

    if((typeof exerciseHabits.value) =='boolean') {

        memberProfile.exerciseHabits = {
            value: exerciseHabits.value,
        };

        if((typeof exerciseHabits.duration)=='number'){
            memberProfile.exerciseHabits.duration = exerciseHabits.duration;
        }

        if((typeof exerciseHabits.freq)=='number'){
            memberProfile.exerciseHabits.freq =    {
                value: Number(exerciseHabits.freq),
                unit: 'times/week'
            }
        }
    }

    const result = {
        id: btoa(userId),
        memberProfile,
    }

    return result;
}
export const copyBillableHealthConditions = (array)=>{
    const copiedArray = array.slice();
    return copiedArray.map(i=>({
        description:i.description,
        code:i.code
    }));
}
const getMutateVariableMedicalHistory=(props,state)=>{
    const { userId,user } = props;
    const getFieldsValue  = _.get(props,'form.getFieldsValue');
    const data = getFieldsValue();
    let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday'])
    const { height,height_inches = 0,weight,insulinDependent,BGTreatments,stroke,cardiovascularDisease,retinopathy,insuranceProvider2,
            BGOther,BGVisits,BGMedicine,BGTest={},BPTest={},kidneyDisease,liverDisease,heartAttack={},healthConditions,mntHealthConditions,billableHealthConditions,insuranceProvider, insuranceProviders, hasCopay, copay } = data;

    if ((height && height >= 0 && height < 10) && (height_inches >= 0 && height_inches < 12)) {
        const feetToInches = Number(convertFeetToInches(height||0));
        const convertedHeight = convertor.height(feetToInches + Number(data.height_inches||0), 'inch', 'cm');
        memberProfile.height = { value: convertedHeight, unit: i18nMap.get('measure.units_height_cm') }
    } else if (height !== undefined) {
        memberProfile.height = null;
    }

    if(typeof weight=='number' && weight >= 25 && weight < 1000) {
        const convertedWeight = convertor.HS(weight, 'lbs', 'kg');
        memberProfile.weight = {value: convertedWeight, unit: i18nMap.get('measure.units_weight_kg')}
    } else if(weight !== undefined) {
        memberProfile.weight = null;
    }
    
    if((typeof insulinDependent)=='boolean'){
        memberProfile.insulinDependent = insulinDependent;
    }


    if(BGTreatments){
        memberProfile.BGTreatments = BGTreatments;
    }

    if(stroke){
        memberProfile.stroke = stroke;
    }

    if(cardiovascularDisease){
        memberProfile.cardiovascularDisease = cardiovascularDisease;
    }

    if(retinopathy){
        memberProfile.retinopathy = retinopathy;
    }

    if(BGOther){
        memberProfile.BGOther = BGOther;
    }

    if(typeof BGVisits=='boolean'){
        memberProfile.BGVisits = BGVisits;
    }

    if(typeof BGMedicine=='boolean'){
        memberProfile.BGMedicine = BGMedicine;
    }

    if((typeof BGTest.testRegularly)=='boolean'){
        memberProfile.BGTest ={
            testRegularly:BGTest.testRegularly,
            freq:BGTest.freq
        }
        if((typeof BGTest.freq!='number')){
            memberProfile.BGTest = _.omit(BGTest,'freq');
        }
    }

    if((typeof BPTest.testRegularly)=='boolean'){
        memberProfile.BPTest ={
            testRegularly:BPTest.testRegularly,
            freq:BPTest.freq
        }
        if((typeof BPTest.freq)!=='number'){
            memberProfile.BPTest = _.omit(BPTest,'freq');
        }
    }

    if(kidneyDisease){
        memberProfile.kidneyDisease = kidneyDisease;
    }

    if(liverDisease){
        memberProfile.liverDisease = liverDisease;
    }

    if((typeof heartAttack.hasHeartAttack)=='boolean'){
        memberProfile.heartAttack = heartAttack;
    }

    if(typeof insuranceProvider === 'string'){
        memberProfile.insuranceProvider = insuranceProvider;
    }
    if(typeof insuranceProvider2 === 'string') {
        memberProfile.insuranceProvider2 = insuranceProvider2;
    }
    if(insuranceProviders) {
      memberProfile.insuranceProviders = _.map(insuranceProviders, e => ({ id: e.id, primary: e.primary }));
    }

    if(Array.isArray(healthConditions)) {
        memberProfile.healthConditions = healthConditions;
    }

    if(Array.isArray(billableHealthConditions)){
        memberProfile.billableHealthConditions = copyBillableHealthConditions(billableHealthConditions);
    }
    if(Array.isArray(mntHealthConditions)) {
        memberProfile.mntHealthConditions = copyBillableHealthConditions(mntHealthConditions);
    }

    const yearOfDiagnoseUpdated = parseYearOfDiagnose(data);
    memberProfile.yearOfDiagnose = yearOfDiagnoseUpdated;
    const result = {
        id: btoa(userId),
        memberProfile,
    }

    if (typeof hasCopay !== 'undefined') {
      setPatientInsurance({
        patientId: userId,
        hasCopay,
        copay,
      }).catch(message.error);
    }

    return result;
}

export { getMutateVariableInfo, getMutateVariableFamilyHistory, getMutateVariableLifeStyle, getMutateVariableMedicalHistory, parseProgramCategories, getPatientInfoVariables }
