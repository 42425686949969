import remoteStatus from "../constant/remoteStatus";
import {Button, Checkbox, Form, Icon, Input, Modal, notification, Select} from "antd";
import { message } from 'ihcomponent';
import React from "react";
import '../style/index.scss';
import API from "../API/index";
import PostAPI from '../../postIt/API/index';
import { Row,Col } from 'antd';
import $ from 'jquery';
import excludedReasons from "../constant/excludedReasons";
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import { NON_COMPLEX_CONDITIONS, COMPLEXITY_LEVELS, PATIENT_COMPLEXITY_SOURCE_ENUM } from '../../../lib/constants';
import ComplexitySummaryContainer from "../../patient/main/containers/ComplexitySummaryContainer";
import {checkIfNeedChangeOrg, handleSwitchOrgCB} from "../../workStation/helper";
import {switchOrg} from "../../workStation/API";
import reduxStore from 'libModule/reduxStore';
import { setRemountMainLayout } from '../../layout/actions/Nav';
import disableOptionMap from '../constant/disableOptionMap';
const Option = Select.Option;



const keyToValue ={ NO_ANSWER_LEFT_VOICEMAIL: 'No answer/left voicemail',
    NEED_TO_THINK_ABOUT_DOCTOR_FIRST: 'Needs to think about it/talk to doctor first',
    VERY_INTERESTED: 'Very interested, wants to enroll in the clinic',
    CHANGE_PCP: 'Changed PCP; relocated',
    CALL_FAILED: 'Call failed',
    REJECTED_NO_INTEREST: 'Rejected: No interest',
    REJECTED_LANGUAGE_BARRIER: 'Rejected: Language barrier',
    REJECTED_COPAY_CONCERN: 'Copay concern',
    REJECTED_NOT_APPROPRIATE: 'Rejected: Not appropriate at this time',
    REJECTED_STRONG_TECH_BARRIER: 'Rejected: Strong tech barrier',
    REJECTED_DOES_NOT_TRUST_THE_PROGRAM: 'Rejected: Does not trust the program',
    OTHER: 'Other' }
const redFont = (key)=>{
        return { color: key==='EXCLUDED' ? 'red':'' }
};

const getAllMembers = ()=>{
    const userFromSession = JSON.parse(sessionStorage.getItem('currentUser'));
    const teamsFromLoginUser = userFromSession.team;
    const members = [];
    _.forEach(teamsFromLoginUser,t=>_.forEach(t.members,m=>{
    const user = { id:m.user.id,fullName: m.user.profile.fullName };
            members.push(user);

    }));
    return _.uniqBy(members,m=>m.id);
};

const getAllMembersSel = (caller,onChange,fromCall)=>{
    const members = getAllMembers();
    return <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a person"
        optionFilterProp="children"
        defaultValue={caller}
        disabled={fromCall}
        onChange={(a,b,c)=>onChange(a,b,c)}
    >
        {
            _.map(members,(m,i)=>{
                const { id,fullName} = m;
                return <Option value={id} key={i} data-fullname={fullName}>{fullName}</Option>
            })
        }
    </Select>
}
const getMoveOptions = (localThis,status,{currentDoc})=>{
   const memberId = _.get(currentDoc,'member.id');
   const tabSelected = _.get(localThis,'props.tabSelected');
   const disabledOpts = _.get(disableOptionMap,status,[]);
   return <Select onChange={(o,option)=> {localThis.handleOnSelectChange(o,{ status:o,memberId }); }}
                  key={memberId} style={{width: '100%'}}  dropdownClassName='moveOption'
                  value={status}
          >
                {
                    _.map(remoteStatus,(val,key)=>{
                        const disabled = disabledOpts.includes(key);
                        return  <Option disabled={tabSelected==key || disabled} value={ key }
                                        key={memberId}
                                        style={ redFont(key) }>{ val } </Option>
                })
                }
          </Select>
}

const renderSingleREColumns = (colsList,doc)=>{
     const colCount = colsList.length;
     const spanSize = Math.floor(24/colCount);
     if(doc) {
         return <Row className='singleRERow'>
             {
                 _.map(colsList, ({render, path, title, span}) =>
                     <Col span={span ? span : spanSize} key={title}>
                         {title && <div className='header'>{title}</div>}
                         {render(_.get(doc, path), doc)}
                     </Col>)
             }
         </Row>
     }
}

const generateExcludedReasonOptions = ()=>{
        return <Select className={'excludedReasonDropDown'}>
                    { _.map(excludedReasons,({key,value},i)=> <Option value={key} k={key}>{value}</Option>) }
               </Select>
}


const setExcludedReason = (excludedReason,note,memberId,refetchPostNote,refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary,closeModalFn)=>{
    if(excludedReason&&excludedReason.trim().length>0){
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        let organizationId = currentUser.selectedRole.organization.id;
        API.editExcludedReason({memberId,excludedReason: excludedReason}).then(()=>{
            API.moveRemoteEnrollmentHandler({ memberId,status:'EXCLUDED' }).then(()=>{
                if(refetchRemoteEnrollmentList) {
                    refetchRemoteEnrollmentList();
                    refetchRemoteEnrollmentSummary();
                }
                closeModalFn(false,null);
                message.success(`Patient moved to ${remoteStatus['EXCLUDED']}`);
            });
            PostAPI.createPostItMutation({organizationId, memberId,note:`${keyToValue[excludedReason]}. ${note}`}).then(() => {
                    refetchPostNote&&refetchPostNote();
                    message.success('Post Note Created.');
                }
            );

        });
    }
};


const confirmMove = (o,variables,refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary)=>{
    Modal.confirm({
        title:`Move Patient to ${remoteStatus[o]} ?`,
        onOk:()=> {
            API.moveRemoteEnrollmentHandler(variables).then(res => {

                    message.success(`Patient moved to ${remoteStatus[o]}`)

                    if (refetchRemoteEnrollmentList) {
                        refetchRemoteEnrollmentList();
                    }
                    if (refetchRemoteEnrollmentSummary) {
                        refetchRemoteEnrollmentSummary();
                    }
                }
            )
        }

    })
}
const trackingLinkPrefix = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=`;

const shippingInfoContent = (localThis,doc,className)=>{
    const shippingNumber = _.get(doc,'shippingInfo.shippingNumber');
    const memberId =  _.get(doc, 'member.id');
    const openLink = (e)=> {
        let parent = $(e.target).parents('span.ant-input-wrapper')[0];
        let currentShipNumber = $(parent).find('input')[0].value;
        window.open(`${trackingLinkPrefix}${currentShipNumber}`, "_blank");
    }
    return <div className={className ? className : 'trackingInfo'}>
            <Input className='trackingInput'
                   id='trackingInput'
                   addonAfter={<img type="setting" className='trackingAddOn' src='/image/track.png' onClick={ openLink }/>}
                   onPressEnter={(e)=>{
                       const shippingNumber = e.target.value;
                       API.editShippingNumber({memberId,shippingNumber}).then(()=>message.success('Shipping Number Updated'))
                   }}
                   defaultValue={shippingNumber}
            />
           </div>
}
const turnOnline = (contactStatus) =>{
    let agent = window.awsCCP.agent
    let routableState = agent.getAgentStates().filter(function(state) {
        return state.type === global.connect.AgentStateType.ROUTABLE;
    })[0];
    agent.setState(routableState, {
        success: function() { },
        failure: function() { }
    });
    return true;
    // if (contactStatus && contactStatus === 'onEnded') {

    // }
    // if (contactStatus && contactStatus === 'onConnected' && !this.state.checkEndWarning) {

    //     this.submitWarning();
    //     this.setState({
    //         checkEndWarning: true
    //     })
    //     return false;
    // }
    // return false;
}

const parseSingleFilter = (key, filters) => {
  const value = _.get(filters, key) || [];
  return _.first(value);
};

const removeEmptyFilters = (filters) => {
  let newFilters = {...filters};
  _.forEach(_.keys(newFilters), k => {
    const filter = newFilters[k];
    if(_.isArray(filter) && !filter.length) {
      newFilters = _.omit(newFilters, k);
    }
  });
  return newFilters;
}

const renderConditionsList = (localThis)=>{
        const options = dataHelper.conditionsList.map(k=>({ label:k, value: k }));
        return <Checkbox.Group options={ options } onChange={localThis.onCheckboxChange}/>;

}

// const renderComplexity = (localThis) => {
//     const { complexityLevel } = localThis.state;
//     const displayComplexityLevel = COMPLEXITY_LEVELS[complexityLevel];
//     return <div>
//         <div>Based on the conditions, patient's Condition Complexity level is: <span style={{fontWeight: 700}}>{displayComplexityLevel}</span></div>
//         <div>The patient’s Condition Complexity Level will be updated accordingly.</div>
//         </div>
// }


const renderConditionsListForm = (localThis)=>{
    const { getFieldDecorator } = _.get(localThis,'props.form');
    const conditionsList = _.get(localThis,'props.currentDoc.conditionsList') || _.get(localThis, 'props.currentDoc.member.profile.conditionsList');
    const checkboxValue = dataHelper.conditionsListToCheckbox(conditionsList);

    const { isEditMode } = localThis.state;
    const { patientComplexity } = localThis.props;
    return <Col offset={1}>
            <Form className='editConditionsListForm'>
                <span className={'header'}>{'Patient diagnosis'}</span>
                <Form.Item>
                    { getFieldDecorator('conditionsList',{ initialValue:checkboxValue })(renderConditionsList(localThis))}
                </Form.Item>
                <ComplexitySummaryContainer 
                  oldComplexity={patientComplexity}
                  newComplexity={{
                    source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
                    level: localThis.state.complexityLevel
                  }}
                />
                <div>
                    <Button type='primary' onClick={()=>localThis.onClick()} disabled={!isEditMode}>Save</Button>
                </div>
             </Form>
           </Col>
}
const parsePinnedSort = (relist)=>{
    return _.orderBy(relist,[(r)=>{
        return _.get(r,'pinnedToEMR.value');
    },(r)=>{
        const categories = _.get(r,'member.profile.programCategories',['desc','asc']);
        const enrolledCategories = _.filter(categories,o => o.enrolled);
        const enrolledDate = enrolledCategories.reduce((prev, curr) => prev < curr.enrolledDate ? prev : curr.enrolledDate, Infinity);
        return enrolledDate
    }])
}
const parseREList = (field,relist)=>{
    switch (field){
        case 'PINNED_TO_EMR':
            return parsePinnedSort(relist);
        case 'pinnedToEMR':
            return parsePinnedSort(relist);
        default:{
            return relist

        }
    }
}
const parseSorter = ({fieldGraph,field,direction})=>{
    // sort has selected sorter by user
    const sort = [{ direction ,field:fieldGraph||field }];
    switch (fieldGraph){
        case 'PINNED_TO_EMR':
          sort.push({ field:'CREATE_AT',direction:'ASC' });
          break;
        case 'DOCTOR_REFERRAL':
          sort.push({ direction:'ASC', field:'LAST_CALL_DATE' });
          break;
        default:{
          break;
        }
    }
    return sort;
}
const getCurrentUser = ()=>{
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const currentUserFullName = _.get(currentUser,'profile.fullName');
    const currentId = currentUser.id;
    return {
        currentId,currentUserFullName
    }
}

const saveModalMember = (memberId)=>{
    if(memberId) sessionStorage.setItem('rmMember',memberId);
    else sessionStorage.removeItem('rmMember');
}

const saveModalReferral = (memberId,showModal)=>{
    if(showModal&&memberId) sessionStorage.setItem('prMember',memberId);
    else sessionStorage.removeItem('prMember');
}
const onOrganizationChange = (org = {},cb)=>{
    const { organizationId,name } = org;
    if(organizationId && checkIfNeedChangeOrg(organizationId)) {
        switchOrg(organizationId).then(res=>{
            notification.open({
                placement:'topRight',
                duration:3,
                className:'orgChangeNotification',
                description:`You have switched to ${name}.`
            })
            handleSwitchOrgCB(res,()=>cb(true));
        })
    }else{
        cb(false);
    }
}

export const simulatePageReload = () => {
  return reduxStore.dispatch(setRemountMainLayout(true));
};
export default {
    getMoveOptions,
    confirmMove,
    getAllMembersSel,
    getAllMembers,
    renderSingleREColumns,
    shippingInfoContent,
    turnOnline,
    generateExcludedReasonOptions,
    parseSingleFilter,
    removeEmptyFilters,
    setExcludedReason,
    renderConditionsList,
    renderConditionsListForm,
    parseSorter,
    parseREList,
    getCurrentUser,
    saveModalMember,
    saveModalReferral,
    onOrganizationChange,
    simulatePageReload
    // renderComplexity
}
