import { commonReducer, commonInitialState, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import types from '../constants/EditCareTeamConstants'
import { schema } from '../components/formData/EditCareTeamForm'

// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initialState = Object.assign(commonInitialState(types), {
  initData,
  fieldsValue: initFieldsValue,
  isFieldsRequired,
  isFormSaved: true,
  isSubmitting: false,
})

const ownReducer = (state, action) => {
  switch (action.type) {
    case types.RESET_FORM:
      return initialState
    case types.CHANGE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
