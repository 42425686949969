
import {helpers as dataHelper} from "../../../visitNewWorkFlow/helper";
/* 
* Process searchStr for DOB
* @Return DOB
* newSearchStr - can be string
*/
export const getSearchStrForDOB = searchStr => {
  let newSearchStr = searchStr;
  if(newSearchStr){
    if(newSearchStr.length === 5) {
      const pattern = /\d{2}\/\d{2}/;
      if(pattern.test(newSearchStr)){
          newSearchStr = dataHelper.convertDateFormatToDB(newSearchStr);
      }
    }
    if(newSearchStr.length === 10){
      const pattern = /\d{2}\/\d{2}\/\d{4}/;
      if(pattern.test(newSearchStr)){
        newSearchStr = dataHelper.convertDateFormatToDB(newSearchStr);
      }
    }
  }
  return newSearchStr;
}

/* 
* Process searchStr for First name and Last name
* If it is not given name format (more than 1 word or include comma), return default fields and original searchStr
* @Return [fields, newSearchStr]
* fields - can be string for only FIRST_LAST or array
* newSearchStr - can be string or array for only [first, last]
*/
export const getFieldsAndMatch = searchStr => {
  let fields = ['NAME', 'IDENTIFICATION', 'PHONE', 'BIRTHDAY'], // default
      newSearchStr = searchStr,
      namePattern = /,|\s/;
  if(searchStr && namePattern.test(searchStr.trim())){
    fields = 'FIRST_LAST'; // overwrite default search fields
    if(!_.includes(searchStr, ',')){
      // first last
      newSearchStr = searchStr.split(/\s+/g);
    } else {
      // last, first or last, _emptyFirst or _emptyLast, first or _empty, _empty
      newSearchStr = _.reverse(searchStr.split(','));
    }
    if(newSearchStr.length > 2) newSearchStr[1] = newSearchStr.splice(1).join(' ');
    newSearchStr = [newSearchStr[0].trim(), newSearchStr[1].trim()];
  }
  return [fields, newSearchStr];
};

/*
* Process filter (used for MA/MD search feature only)
* If filter.status array is empty, filter has NO generalPatient
* If filter.status array has length and includes GENERAL, generalPatient = true
* If filter.status array has length and doesn't include GENERAL, generalPatient = false
* @Return filter: Obj
*/
// export const filterGeneralPatients = filter => {
//   const statusFilters = _.get(filter, 'status', []);
//   if(statusFilters.length){
//     _.set(filter, 'generalPatient', _.includes(statusFilters, 'GENERAL'));
//   }
//   // always remove GENERAL from filter.status
//   const newStatusFilters = _.filter(statusFilters, statusFilter => statusFilter !== 'GENERAL');
//   return { ...filter, status: newStatusFilters };
// }

/*
* correct the filter between UE and EP
* isNotForEP: whether Enrolled Patients tab is not selected
* generalPatient: true, the flag will ignore the status filter completely
*/
export const correctFilter = (originalFilter, isNotForEP) => { 
  // Always remove GENERAL from filter.status
  const processedStatusFilters = isNotForEP ? _.without(_.get(originalFilter, 'status', []), 'GENERAL') : ['ENROLLED'];
  // if isNotForEP, processedStatusFilter has length of either 0 or 1
  // if proccessedStatusFilter.length === 1, ['DISCHARGED']
  // 10/25 ----
  // processedStatusFilter can have multiple status
  // if isNotForEp, status won't have "ENROLLED"
  return {...originalFilter, generalPatient: isNotForEP && !_.includes(processedStatusFilters, "ENROLLED"), status: processedStatusFilters};
};

/*
  process filter to pass filter of monthly measurement days
  isForEP: whether Enrolled Patients tab is selected
*/
export const getMeasurementDaysFilter = (originalFilter, isForEP) => {
  if(!isForEP) return originalFilter;
  const monthlyMeasurementDays = _.get(originalFilter, 'monthlyMeasurementDaysRange');
  const filterLen = _.get(monthlyMeasurementDays, 'length');
  if(filterLen) {
    let firstPair = _.split(_.first(monthlyMeasurementDays), ',');
    let low = Number(firstPair[0]), 
        high = Number(firstPair[1]);
    if(filterLen > 1){
      // finding low and high for list of filters, if there is more than 1 filter
      _.forEach(monthlyMeasurementDays, pair => {
        let [potentialNewLow, potentialNewHigh] = _.split(pair, ',');
        potentialNewLow = Number(potentialNewLow);
        potentialNewHigh = Number(potentialNewHigh);
        if (potentialNewLow < low) {
          low = potentialNewLow;
        } else if (potentialNewHigh > high) {
          high = potentialNewHigh;
        }
      });
    }
    return {
      ...originalFilter,
      monthlyMeasurementDaysRange: [low, high]
    };
  }
  return _.omit(originalFilter, 'monthlyMeasurementDaysRange'); // remove daysRange filter
}

/*
  process filter for CCM / RPM
  CCM/ RPM colum has key programsEnrolled
  programsEnrolled is array of 1 filter (filter is not multiple)
*/

export const getMNTStatusFilter = (originalFilter)=>{
  let newFilter = {...originalFilter};
  let programsEnrolled = ['CCM','RPM'];
  newFilter.programsEnrolled = programsEnrolled;
  newFilter.hasMntCodes = true;
  newFilter.hasMntProgram = true;
  return newFilter;
}
export const getProgramsEnrolledFilter = originalFilter => {
  let newFilter = {...originalFilter};
  let programsEnrolled = _.get(newFilter, 'programsEnrolled', []);
  if(programsEnrolled.length && _.includes(programsEnrolled[0], ',')){
    programsEnrolled = programsEnrolled[0].split(',');
    newFilter = {...newFilter, programsEnrolled};
  }
  return newFilter;
}