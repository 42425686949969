// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { actionTypes as ListTypes } from './AssessmentListDuck'
import { actionTypes as moduleCommonType, actionCreators as moduleCommonActions } from './CommonDuck'
import { commonActions } from 'libModule/helpers/action-helpers'
// Reducer
import { commonReducer } from 'libModule/helpers/reducer-helpers'
/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Assessment', 'AssessmentForm', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'UPDATE_FORM',
  'TOGGLE_ALERT_FORM',
  'SET_VALIDATE_CALENDAR'
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/


const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
]);

actionCreators.toggleAlertForm = (isShowAlertForm=true) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TOGGLE_ALERT_FORM,
      isShowAlertForm
    })
  }
}

actionCreators.goMainPage = () => {
  return moduleCommonActions.changePage(moduleCommonTypes.CAREPLAN_MAIN_PAGE)
}

actionCreators.addFormToList = (fieldsValue) => {
  return (dispatch, getState) => {
    dispatch({
      type: ListTypes.ADD_ITEM_DATA,
      fieldsValue
    })
    dispatch({
      type: actionTypes.RESET_FORM
    })
    dispatch(goMainPage())
  }
}

actionCreators.setValidateCalendar = (validateCalendar) => ({
  type: actionTypes.SET_VALIDATE_CALENDAR,
  validateCalendar
})

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  isShowAlertForm: false,
  isEditMode: false
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.UPDATE_FORM:
      return {
        ...state,
        fieldsValue: action.fieldsValue,
        isEditMode: action.isEditMode,
        assessment: action.assessment
      }
    case actionTypes.TOGGLE_ALERT_FORM:
      return {
        ...state,
        isShowAlertForm: action.isShowAlertForm
      }
    case actionTypes.RESET_FORM:
      return initState
    case actionTypes.FORM_FIELD_CHANGE:
      return {
        ...state,
        validateCalendar: 'NO_ERROR'
      }
    case actionTypes.SET_VALIDATE_CALENDAR:
      return {
        ...state,
        validateCalendar: action.validateCalendar
      }
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes)
}
