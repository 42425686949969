import React from 'react';
import {notification,Button,Tooltip} from "antd";
import helper from '../helper';
import {userWithoutTeam as userGQL} from "../../graphql/user";
import Client from 'libModule/gqlClient';
import VideoChatCenterComponent from '../component/VideoChatCenterComponent';
import Sound from 'react-sound';
import {connect} from "react-redux";
import reducerActions from "../action/reducerActions";
import {API as VisitAPI} from "../../visit/query";
import roleMap from '../constant/roleMap';
import { setInstitution } from '../../layout/actions/Nav';
import { VIDEO_STR } from '../../../lib/constants';


const VideoNotificationComponent = class extends React.Component{
    constructor(){
        super();
        this.state = {
            showModal:false,
            shouldPlaySound:true,
            playCount: 0
        }
    }

    // Can't find the use of this
    onClickCheckIn = (visitId)=>{
        VisitAPI.updateVisit(visitId, 'checkIn')
    }

    getUserData = async (id)=>{
        const res = await Client.query({
            query:userGQL,
            variables:{
                id
            },
            fetchPolicy:'network-only'
        });
        return _.get(res,'data.user');
    }

    openNotification = async (params)=>{
        const { state,getUserData,setModal,props } = this;
        const { showModal } = state;
        const { setVirtualVisit,uniqueId } = props;
        const { visit,currentRole,refetchVisitList,memberId } = params;
        const user = await getUserData(memberId);
        const key = 'videoChatCenterNotification';
        notification.close(uniqueId);
        notification.open({
            message: 'Video chat Left Div View',
            description:<VideoChatCenterComponent user={user} showModal={showModal} setModal={setModal} setVirtualVisit={setVirtualVisit}
                                                  currentVisit={visit} currentRole={currentRole} refetchVisitList={refetchVisitList}
                                                  refetchSingleVisit={(newVisitData) => this.openNotification({...params, visit: newVisitData})}/>,
            duration: 0,
            placement:'topLeft',
            key,
            className:'videoChatCenterNotification',
            style:{ zIndex:500 }
        })

    }

    setModal = (showModal,fn,params)=>{
        this.setState({
            showModal
        },()=>{
            if(fn&&params){
                fn(params)
            }
        })
    }


    handleJoinVideo = ()=>{
        const { props,setVisit,handleChangeOrg} = this;
        const { memberId,visit,status,uniqueId } = props;
        const { handleClickButton } = helper;
        handleClickButton(memberId,visit,status,setVisit,this,visit);
    }

    renderJoinButton = ()=>{
        const { handleJoinVideo,props } = this;
        const { visitId } = props;
        // disabled when there is an on-going video
        return <Button  onClick={()=>handleJoinVideo()} disabled={visitId}>{VIDEO_STR['OPEN']}</Button>;
    }

    handleSongFinishedPlaying = ()=>{
        const { playCount } = this.state;
        this.setState({
            playCount: playCount+1
        })
    }

    renderContent = ()=>{
        const { props } = this;
        const { organization, patient,status,forUsers} = props;
        const { firstName,lastName} = patient;
        const firstUser = _.first(forUsers)||{firstName:'',lastName:''};
        const fistUserFN = firstUser.firstName;
        const firstUserLN = firstUser.lastName;
        const rolesSet = _.reduce(forUsers, (set, o) => { set.add(o.roleName); return set; }, new Set());
        const mappedRoles = _.map(Array.from(rolesSet), (r) => roleMap[r]);
        const rolesString = _.reduce(mappedRoles, (prev, r, i) => (i === 0 ? r : prev + ', ' + r), '');
        const name = `${firstName} ${lastName}`;
        const text = ` from ${organization.name} is ${status.replace('_',' ').toLowerCase()} ${rolesString}`;
        let info = ` is ${status.replace('_',' ').toLowerCase()} ${fistUserFN} ${firstUserLN}`
        if (status === "CALLING_IN"){
            info = text;
        }
        //notification with hover for overflow
        return<div className='videoChatNotification'>
                <div style={{ minWidth:25 }}>
                    <img src='image/green_phone.png' width={25} className='icon'/>
                </div>
                <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <Tooltip placement="left" title={name + info}>
                        <span className='fullName'>{name}</span>
                        <span className='visitStatus'>{info}</span>
                    </Tooltip>
                </div>
              </div>
    }

    render() {
        const { props, state,handleSongFinishedPlaying,renderContent } = this;
        const { organization } = props;
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        let institution = currentUser.selectedRole.organization.id;

        const { playCount } = state;
        const shouldPlaySound = playCount<2;
        return <div>
                { shouldPlaySound &&<Sound url='/sound/insight.mp3'
                           playStatus={Sound.status.PLAYING}
                           onFinishedPlaying={handleSongFinishedPlaying}
                           autoLoad={true}
                    />}
                { renderContent() }
                { institution === organization.id && this.renderJoinButton() }
               </div>
    }

}
const mapToProps = (state)=>{
    return {
        visitId: state.VideoChat.main.visitId
    }
}
const mapToDispatch = (dispatch)=>{

    return {
        setVirtualVisit: (visitId) => dispatch(reducerActions.setVirtualVisit(visitId)),
        setInstitution: inst => dispatch(setInstitution(inst)),
    }
}

export default connect(mapToProps,mapToDispatch)(VideoNotificationComponent);
