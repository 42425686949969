import  React  from 'react';
import  { Tag  } from 'antd';
import '../style/index.scss';
import constant from "../constant";
import helper from "../helper";
const { alertLevelColorMap } = constant;
const style ={
    tagStyle:{
        display: 'flex',
        height: 28,
        alignItems: 'center',
        border: 1,
        borderRadius: 15,
        marginBottom: 15,
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}
const PatientSmartAlertComponent = class extends React.Component {

    closeAlert=(e,id,taskType)=>{
        e.preventDefault();
        const { showConfirmForCloseAlert } = this.props;
        showConfirmForCloseAlert(id,taskType);
    }

    addSmartAlertPN = (alert) => {
        const { changeSmartPNModalStatus } = this.props;
        changeSmartPNModalStatus(alert);
    }

    renderExtraBasedOnCategory = (category)=>{
        const contentBaseOnCategory = {
            'PROVIDER':<p style={{ color:'red',padding:5,fontWeight:'bold' }}>P</p>
        }
        return contentBaseOnCategory[category]||'';
    }
    parseSmartAlertName = (display) =>{
        return typeof display=='string' ? display.replaceAll('>=','≥').replaceAll('<=','≤'): display;
    }
    render(){
        const { addSmartAlertPN,renderExtraBasedOnCategory,parseSmartAlertName } = this;
        const { smartAlertsList, patientAlertConfig, changeSnoozeListModalStatus } = this.props;
        const alertsAndConfig = patientAlertConfig ? smartAlertsList.concat(patientAlertConfig) : smartAlertsList;
        const lists = <div className='PatientSmartAlertComponent'>
                            { _.map(alertsAndConfig, (m, i) => {
                                if (m && m.configs && m.configs.length) {
                                    let snoozeCount = (m.configs.filter(c => new Date() < c.endDate && c.isValid)).length;
                                    if (snoozeCount > 0) {
                                        return <Tag key={i} style={{color: 'rgb(111, 110, 110)', backgroundColor: 'rgb(233, 233, 233)',...style.tagStyle}}>
                                                <p style={{fontWeight:'bold',cursor:'pointer'}} onClick={ () => changeSnoozeListModalStatus(m) }>{`Snoozed Alerts (${snoozeCount})`}</p>
                                               </Tag>
                                    }
                                } else if (m && alertLevelColorMap[m.alertLevel]) { // alertLevel is undefined in patientAlertConfig
                                    const {color, backgroundColor} = alertLevelColorMap[m.alertLevel];
                                    return <Tag key={i} style={{backgroundColor: backgroundColor,...style.tagStyle}} closable
                                                onClose={(e) => this.closeAlert(e, m.id,m.taskType)}>
                                                 { renderExtraBasedOnCategory(m.category)}
                                                 <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: color, display: 'inline-block', marginRight: 10 }} />
                                                 <p style={{color: '#2D3238', fontSize:'12', cursor:'point'}} onClick={ ()=> addSmartAlertPN(m) }>{parseSmartAlertName(m.display)}</p>
                                                 {helper.renderSmartAlertInfo(m)}
                                           </Tag>
                                }
                            })
                        }
                      </div>
        return lists;
    }
}

export default PatientSmartAlertComponent;
