import { React, IHButton } from 'ihcomponent';
import { goPath } from 'libModule/utils';
import I18N from 'modulesAll/I18N';
// component
import PreviewInnerContainer from '../../../patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer';

function PreviewComponent(props) {
  const assessmentId = props.params.assessmentId;
  const onCancel = () => goPath('/assessments');
  const onEdit = () => goPath(`/edit_assessment/${assessmentId}`);

  return (
    <div className="patient-take-assessment">
      <button className="link-like back" onClick={onCancel}>&lt; Back to {I18N.get('keywords.Assessments')}</button>
      <div className="paper">
        <PreviewInnerContainer isAssessment id={assessmentId} breadCrumb={`${I18N.get('keywords.Assessment')} / Preview`} />

        <div className="btn-wrapper">
          <div className="right">
            <IHButton label="Edit" onClick={onEdit} />
            <IHButton label="Done" onClick={onCancel} bsStyle="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewComponent.displayName = 'PreviewComponent';

export default PreviewComponent;
