import {React, _} from 'ihcomponent'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import {SuperContainer, goPath} from 'libModule/utils'
import SelectAlerts from '../components/SelectAlerts'
import actions from '../actions'
import adminProgramSummary from 'modulesAll/graphql/adminProgramSummaryList'
import { getSortedAdminProgramFormSessionStorage } from 'modulesAll/utils/helpers/program'

const Container = class extends React.Component{
  static displayName = 'SelectAlerts'

  componentDidMount() {
    const adminProgramList = getSortedAdminProgramFormSessionStorage()

    if (adminProgramList.indexOf(this.props.currentProgram) === -1 && adminProgramList.length > 0) {
      goPath('/dashboard?programId='+adminProgramList[0].id)
    }
  }
  render(){
    const { progDetail } = this.props
    const p = {
      title : 'Overview',
      actions,
      list : [
        {
          id : 'a',
          num : _.get(progDetail, 'alertSummary.critical'),
          title : 'CL/CH Threshold',
          info : 'Alerts',
          type : 'Alert'
        },
        {
          id : 'a1',
          num : _.get(progDetail, 'alertSummary.risk'),
          title : 'L/H Threshold',
          info : 'Alerts',
          type : 'Alert'
        },
        {
          id : 'a2',
          num : _.get(progDetail, 'alertSummary.missed'),
          title : 'Missed Task',
          info : 'Alerts',
          type : 'Alert'
        },
        {
          id : 'a3',
          num : _.get(progDetail, 'memberSummary.nearDischarge'),
          title : 'Patients with',
          info : '<7 days left',
          type : 'Patient'
        },
        {
          id : 'a4',
          num : _.get(progDetail, 'memberSummary.suspended'),
          title : 'Patients',
          info : 'Suspended',
          type : 'Patient'
        }
      ],
      currentProgram : this.props.currentProgram
    }

    return <SelectAlerts {...p} enrolledProgramName={progDetail} />
  }
}

const mapStateToProps = (state) => {
  const dashboard = state.dashboard.main
  const currentProgram = _.get(state, 'routing.location.query.programId')
    || _.get(getSortedAdminProgramFormSessionStorage(), '0.id')
  return {
    ...dashboard,
    currentProgram
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setSelectAlert: (list, selected, error) => dispatch(actions.setSelectAlert(list, selected, error)),
//   }
// }
const withData = graphql(adminProgramSummary, {
  options(ownProps){
    const adminPrograms = getSortedAdminProgramFormSessionStorage()
    const id = ownProps.currentProgram || _.get(adminPrograms, '0.id');
    return {
      variables : {
        id
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      skip: !id
    }
  },
  props({data}){
    return {
      loading : data.loading,
      progDetail : _.get(data, 'adminProgramSummary')
    }
  }
})
export default compose(
  connect(mapStateToProps),
  withData,
)(Container);
