import { React } from 'ihcomponent';
import { DatePicker, Menu, Dropdown, Button, Icon, Row, Col, Popconfirm, message,Modal } from 'antd';

import moment from 'moment';
import * as R from 'ramda';

import './labresults.css';
import { compose, withState, withProps, lifecycle, withHandlers } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { RequestCache } from 'libModule/utils';
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav';
// import { labResultTemplates } from './labResultTemplates';
import { TestPanelForm, CustomTestPanel } from './TestPanelForm';
import { mkDict, addItemsIndex, addItemsCount } from './utils';
import { spinnerWhileLoading, trace } from '../../../../lib/helpers/component-helpers';
import { formatDateMM } from '../../../utils/helpers/task';

const styles = {};

const queryLabResultTemplateMany = graphql(
  gql`
    query labResultTemplateMany {
      labResultTemplateMany(sort:TEMPLATE_NAME_ASC) {
        _id
        viewerId
        templateId
        templateName
        isCommon
        items {
          name
          unit
          interval
        }
      }
    }
  `,
  {
    options: () => ({ variables: {} }),
    props: ({ ownProps, data: { loading, labResultTemplateMany = [], refetch } }) => {
      return {
        labResultTemplateMany,
        labResultTemplateManyLoading: loading,
        labResultTemplateManyRefetch: refetch
      };
    }
  }
);

const addLabResultSubmit = graphql(
  gql`
    mutation labResultCreate($record: CreateOneLabResultsInput!) {
      labResultCreate(record: $record) {
        recordId
      }
    }
  `,
  {
    name: 'labResultCreate',
    props: p2 => ({
      labResultCreateMany: templateArray => {
        const { labResultCreate, ownProps } = p2;
        // if(debug) console.log('labResultCreateMany check', templateArray, p2);
        const { viewerId } = ownProps;
        const userId = _.get(ownProps, 'userId', _.get(ownProps, 'routeParams.userId'));

        //console.log(userId);

        const saveData = res => {
          const { templateId, templateName, dateCollected, results } = res;

          // if(debug) console.log('labResultCreate start', ownProps0, res);

          return labResultCreate({
            variables: {
              record: {
                viewerId,
                userId,
                templateId,
                templateName,
                dateCollected,
                results
              }
            }
          }).then(
            ({ data }) => {
              //console.log(data);
            }
            // if(debug) console.log('labResultCreate', data);
          );
        };

        return Promise.all(R.map(saveData, templateArray));
      }
    })
  }
);

const labResultTemplateCreateMutation = graphql(
  gql`
    mutation labResultTemplateCreate($resultTemplate: CreateOneLabResultTemplatesInput!) {
      labResultTemplateCreate(record: $resultTemplate) {
        recordId
      }
    }
  `,
  {
    name: 'labResultTemplateCreate',
    options: labResultTemplate => ({
      variables: { resultTemplate: labResultTemplate }
    }),
    props: p2 => ({
      labResultTemplateCreate: resultTemplate => {
        const { labResultTemplateCreate, ownProps: ownProps0 } = p2;
        const { viewerId, userId } = ownProps0;
        return labResultTemplateCreate({
          variables: {
            resultTemplate: R.merge(
              {
                viewerId,
                userId
              },
              resultTemplate
            )
          }
        }).then(({ data }) => {
          // if(debug) console.log('labResultTemplateCreate', data);
          return data;
        });
      }
    })
  }
);

const labResultTemplateRemoveMutation = graphql(
  gql`
    mutation labResultTemplateRemove($labResultTemplateId: MongoID!) {
      labResultTemplateRemove(_id: $labResultTemplateId) {
        recordId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      labResultTemplateRemove: labResultTemplateId =>
        mutate({ variables: { labResultTemplateId } }).then(({ data }) => {
          // if(debug) console.log('labResultTemplateRemove', data);
          return data;
        })
    })
  }
);

styles.menuItem = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.5,
  width: '100%'
};
styles.button = {
  padding: 0,
  fontSize: '13px',
  height: '34px',
  width: '86px',
  justifyContent: 'center',
  lineHeight: '1'
};

class AddLabResultsComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      defaultTestPanel: props.testPanels
    }
  }
  mkTestPanel = testPanel => {
    const {
      updateTestPanels, updateTestPanelsCount, testPanels, testPanelsCount,
      addPanel, deletePanel, deleteCustomPanel,
      onCellChangeRoot, onCellChangeRootPath, onDeleteTestItemRoot, onAddTestItemRoot,
      hideCancel, selectedDate, setSelectedDate,
      setSaveButtonRef,
      updateTabSelected,
      labResultCreateMany,
      labResultListRefetch,
      labResultTemplateRemove,
      labResultTemplateMany,
      labResultTemplateManyLoading,
      labResultTemplateManyRefetch,
      updateDateCollected,
      router
    } = this.props;
    const key = testPanel.templateId;
    const onCellChange = R.partial(onCellChangeRoot, [testPanel.templateId]);
    const onDelete = R.partial(onDeleteTestItemRoot, [testPanel.templateId]);
    const handleAdd = R.partial(onAddTestItemRoot, [testPanel.templateId]);
    const panelTemplate = testPanel;

    return testPanel.type === 'custom' ? (
        <CustomTestPanel
            {...{
              key,
              onCellChange,
              onCellChangeRootPath,
              onDelete,
              handleAdd,
              panelTemplate,
              updateTabSelected,
              labResultCreateMany,
              deletePanel,
              deleteCustomPanel
            }}
        />
    ) : (
        <TestPanelForm
            {...{
              key,
              onCellChange,
              panelTemplate,
              updateTabSelected,
              labResultCreateMany,
              deletePanel,
              deleteCustomPanel
            }}
        />
    );
  };

  renderCancelButton = ()=>{
    return <Button type={'default'} onClick={this.resetTemplates} style={{ ...styles.button, marginRight: 15 }}>
      Cancel
    </Button>
  }

  resetTemplates = ()=>{
    const { testPanels,updateTestPanels,updateTestPanelsCount } = this.props;
    const { defaultTestPanel } = this.state;
    updateTestPanelsCount(0)
    updateTestPanels(defaultTestPanel);
  }
  renderModal = ()=>{
    const { testPanelsCount,testPanels,updateTestPanelsCount,updateTestPanels } = this.props;
    return <Modal visible={testPanelsCount>0} footer={false} onCancel={ this.resetTemplates } width={1000}>
            <div style={{ padding: '16px 0px 0px 0px' }}>
              {R.map(this.mkTestPanel, R.filter(R.prop('show'), R.values(testPanels)))}
              <div style={{ display:'flex',justifyContent:'flex-end',marginTop:20 }}>
                { this.renderCancelButton() }
                { this.renderSaveButton() }
              </div>
            </div>
           </Modal>
  }
  renderTemplateList = (testPanels,title='title')=>{
    const {
      addPanel,
      labResultTemplateRemove,
      labResultTemplateManyRefetch,
    } = this.props;
    return <Menu.ItemGroup title={title}>{R.map(
          ({ templateName, templateId, _id }) => (
              <Menu.Item key={templateId}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Popconfirm
                      title={`Are you sure delete template ${templateName}?`}
                      okText='Yes'
                      cancelText='No'
                      onConfirm={e => {
                        {
                          labResultTemplateRemove(_id)
                              .then(trace('labResultTemplateRemove button'))
                              .then(r => {
                                // console.log('Template ' + templateName + ' deleted', r);
                                message.success('Template ' + templateName + ' deleted' + JSON.stringify(r), 3);
                              })
                              .then(labResultTemplateManyRefetch)
                              .catch(e => {
                                console.log('Error deleting template ' + templateName, e);
                                message.error('Error deleting template ' + templateName + JSON.stringify(e), 3);
                              });
                        }
                      }}
                  >
                    {/*<Icon type="close-circle" style={{ marginRight: 10, fontSize: 15 }} />*/}
                  </Popconfirm>
                  <div onClick={addPanel.bind(null, templateId)} style={styles.menuItem}>
                    {templateName}
                  </div>
                </div>
              </Menu.Item>
          ),
          R.filter(R.propEq('show', false), R.values(testPanels))
    )}</Menu.ItemGroup>
  }

  parseTemplate = (testPanels,key,value)=>{
   return _.reduce(testPanels,(result, v, k)=>{
     if(!!v[key]==value) {
       result[k] = v;
     }
      return result;
    },{});
  }
  parseInputData = ()=>{
    const {
      testPanels
    } = this.props;
    let templates = Object.assign({}, testPanels);
    // make sure date collected exist
    if (!templates.dateCollected) {
      let dateCollected = moment(new Date()).toDate();
      templates = R.merge({ dateCollected }, R.map(R.set(R.lensProp('dateCollected'), dateCollected), testPanels));
    }

    const formatItems = R.pick(['name', 'unit', 'value', 'flag', 'interval', 'isInHouse']);

    const validateObj = ({ templateId, items, templateName, dateCollected }) => {
      return R.none(R.isNil, [templateId, items, templateName, dateCollected]);
    };
    const checkVal = (v)=>{
      return R.isNil(v) || R.isEmpty(v);
    }
    const formatResults = ({ templateId, items, templateName, dateCollected }) => {
      return {
        dateCollected,
        templateId,
        templateName,
        results: R.map(formatItems, R.filter(R.compose(R.not, checkVal, R.prop('value')), items))
      };
    };

    return R.filter(R.compose(R.not, R.isEmpty, R.prop('results')), R.map(formatResults, R.filter(validateObj, R.values(templates))));
  }
  renderSaveButton = ()=>{
    const {
      updateTestPanels, updateTestPanelsCount, testPanels, testPanelsCount,
      hideCancel, selectedDate, setSelectedDate,
      setSaveButtonRef,
      labResultCreateMany,
      labResultListRefetch,
      labResultTemplateMany,
      router
    } = this.props;
    const res = this.parseInputData();
    return <div>
      {!hideCancel ? (
          <Button type='primary' ghost style={styles.button} onClick={router.goBack}>
            Cancel
          </Button>
      ) : (
          ''
      )}
      <Button
          ref={el => {
            if(setSaveButtonRef) {
              setSaveButtonRef(el);
            }
          }}
          type='primary'
          disabled={selectedDate === null || testPanelsCount === 0 || res.length==0}
          style={styles.button}
          onClick={() => {
            // let templates = Object.assign({}, testPanels);
            // // make sure date collected exist
            // if (!templates.dateCollected) {
            //   let dateCollected = moment(new Date()).toDate();
            //   templates = R.merge({ dateCollected }, R.map(R.set(R.lensProp('dateCollected'), dateCollected), testPanels));
            // }
            //
            // const formatItems = R.pick(['name', 'unit', 'value', 'flag', 'interval', 'isInHouse']);
            //
            // const validateObj = ({ templateId, items, templateName, dateCollected }) => {
            //   return R.none(R.isNil, [templateId, items, templateName, dateCollected]);
            // };
            //
            // const formatResults = ({ templateId, items, templateName, dateCollected }) => {
            //   return {
            //     dateCollected,
            //     templateId,
            //     templateName,
            //     results: R.map(formatItems, R.filter(R.compose(R.not, R.isNil, R.prop('value')), items))
            //   };
            // };
            //
            // const res = R.filter(R.compose(R.not, R.isEmpty, R.prop('results')), R.map(formatResults, R.filter(validateObj, R.values(templates))));
            if(res.length==0) {
              this.resetTemplates();
              return;
            }
            labResultCreateMany(res)
                .then(message.success('Lab results has been saved.',5000))
                .then(labResultListRefetch);

            setSelectedDate(moment(new Date()));
            updateTestPanels(mkDict('templateId', addDisplayToggle(addItemsIndex(addItemsCount(labResultTemplateMany)))));
            updateTestPanelsCount(0);
            if (this.props.onInteraction) {
              this.props.onInteraction('ADD');
            }
          }}
      >
        Save
      </Button>
    </div>
  }
  render() {
    const { 
      updateTestPanels, updateTestPanelsCount, testPanels, testPanelsCount, 
      addPanel, deletePanel, deleteCustomPanel, 
      onCellChangeRoot, onCellChangeRootPath, onDeleteTestItemRoot, onAddTestItemRoot, 
      hideCancel, selectedDate, setSelectedDate,
      setSaveButtonRef,
      updateTabSelected,
      labResultCreateMany,
      labResultListRefetch,
      labResultTemplateRemove,
      labResultTemplateMany,
      labResultTemplateManyLoading,
      labResultTemplateManyRefetch,
      updateDateCollected,
      router
    } = this.props;
    const { parseTemplate } = this;

    const mkTestPanel = testPanel => {
      const key = testPanel.templateId;
      const onCellChange = R.partial(onCellChangeRoot, [testPanel.templateId]);
      const onDelete = R.partial(onDeleteTestItemRoot, [testPanel.templateId]);
      const handleAdd = R.partial(onAddTestItemRoot, [testPanel.templateId]);
      const panelTemplate = testPanel;

      return testPanel.type === 'custom' ? (
        <CustomTestPanel
          {...{
            key,
            onCellChange,
            onCellChangeRootPath,
            onDelete,
            handleAdd,
            panelTemplate,
            updateTabSelected,
            labResultCreateMany,
            deletePanel,
            deleteCustomPanel
          }}
        />
      ) : (
        <TestPanelForm
          {...{
            key,
            onCellChange,
            panelTemplate,
            updateTabSelected,
            labResultCreateMany,
            deletePanel,
            deleteCustomPanel
          }}
        />
      );
    };
    return (
      <div style={{ padding: '0px 0px 20px 0px', fontSize: 14, fontWeight: 600 }} className='add-lab-results'>
        <div className='add-lab-results-label'>
          <span>Enter Lab Results</span>
          {this.renderModal()}
        </div>
        <Row type='flex' justify='start'>
          <Col>
            <label className='ant-form-item-required'>
              <small>Date Collected</small>
            </label>
            <br />
            <DatePicker style={{ padding: '0px !important' }} format='MM/DD/YYYY' value={selectedDate} onChange={updateDateCollected} allowClear={testPanelsCount < 1} disabledDate={current => current && current > moment().endOf('day')} />
          </Col>
          <Col style={{ marginLeft: 12 }}>
            <Row type='flex' justify='start'>
              <Col className='select-panel-template'>
                <label>
                  <small>Select Test Panel Template</small>
                </label>
                <br />
                <Dropdown
                  disabled={selectedDate === null}
                  trigger={['click']}
                  overlay={
                    <Menu style={{ overflowY: 'auto',maxHeight:500 }}>
                        {this.renderTemplateList(parseTemplate(testPanels,'isCommon',true),'Common Test Panels')}
                        {this.renderTemplateList(parseTemplate(testPanels,'isCommon',false),'Other Test Panels')}
                    </Menu>
                  }
                >
                  <Button className='select-test-panel-dd-button'>
                    <Icon type='down' style={{ float: 'right', fontSize: 20 }} />
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                &nbsp;
                <br />
                <span style={{ padding: 10, fontSize: 20, cursor: 'pointer' }}>
                  {!labResultTemplateManyLoading ? (
                    // {/*
                    //   20200220 [Thong] Change to remove test panels, discussed with Adam
                    //   labResultTemplateManyRefetch()
                    //   .then(trace("labResultTemplateManyRefetch"))
                    //   .then(({ data, loading }) =>
                    //     updateTestPanels(() =>
                    //       mkDict(
                    //         "templateId",
                    //         addDisplayToggle(
                    //           addItemsIndex(
                    //             addItemsCount(data.labResultTemplateMany)
                    //           )
                    //         )
                    //       )
                    //     )
                    //   );
                    // */}
                    <Popconfirm
                      title={`Delete all test panels?`}
                      okText='Yes'
                      cancelText='No'
                      onConfirm={e => {
                        updateTestPanels(mkDict('templateId', addDisplayToggle(addItemsIndex(addItemsCount(labResultTemplateMany)))));
                        updateTestPanelsCount(0);
                      }}
                      disabled={testPanelsCount < 1}
                    >
                      <Icon type='delete' style={{ display: testPanelsCount > 0 ? '' : 'none' }} />
                    </Popconfirm>
                  ) : (
                    <Icon type='loading' style={{ padding: '5px 0.1em !important' }} onClick={e => labResultListRefetch()} />
                  )}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        {/*<div style={{ padding: '16px 0px' }}>{R.map(mkTestPanel, R.filter(R.prop('show'), R.values(testPanels)))}</div>*/}
        {/* <div style={{ display: 'flex' }}> */}
        {/*<div>*/}
        {/*  {!hideCancel ? (*/}
        {/*    <Button type='primary' ghost style={styles.button} onClick={router.goBack}>*/}
        {/*      Cancel*/}
        {/*    </Button>*/}
        {/*  ) : (*/}
        {/*    ''*/}
        {/*  )}*/}
        {/*  <Button*/}
        {/*    ref={el => {*/}
        {/*      if(setSaveButtonRef) { */}
        {/*        setSaveButtonRef(el); */}
        {/*      }*/}
        {/*    }}*/}
        {/*    type='primary'*/}
        {/*    disabled={selectedDate === null || testPanelsCount === 0}*/}
        {/*    style={styles.button}*/}
        {/*    onClick={() => {*/}
        {/*      let templates = Object.assign({}, testPanels);*/}
        {/*      // make sure date collected exist*/}
        {/*      if (!templates.dateCollected) {*/}
        {/*        let dateCollected = moment(new Date()).toDate();*/}
        {/*        templates = R.merge({ dateCollected }, R.map(R.set(R.lensProp('dateCollected'), dateCollected), testPanels));*/}
        {/*      }*/}

        {/*      const formatItems = R.pick(['name', 'unit', 'value', 'flag', 'interval', 'isInHouse']);*/}

        {/*      const validateObj = ({ templateId, items, templateName, dateCollected }) => {*/}
        {/*        return R.none(R.isNil, [templateId, items, templateName, dateCollected]);*/}
        {/*      };*/}

        {/*      const formatResults = ({ templateId, items, templateName, dateCollected }) => {*/}
        {/*        return {*/}
        {/*          dateCollected,*/}
        {/*          templateId,*/}
        {/*          templateName,*/}
        {/*          results: R.map(formatItems, R.filter(R.compose(R.not, R.isNil, R.prop('value')), items))*/}
        {/*        };*/}
        {/*      };*/}

        {/*      const res = R.filter(R.compose(R.not, R.isEmpty, R.prop('results')), R.map(formatResults, R.filter(validateObj, R.values(templates))));*/}

        {/*      // console.log(res);*/}

        {/*      // const customPanels = R.filter(*/}
        {/*      //   R.propEq("type", "custom"),*/}
        {/*      //   testPanels*/}
        {/*      // );*/}

        {/*      // const saveLabResultTemplate = ({*/}
        {/*      //   templateId,*/}
        {/*      //   templateName,*/}
        {/*      //   items*/}
        {/*      // }) => {*/}
        {/*      //   const customTemplate = {*/}
        {/*      //     templateId,*/}
        {/*      //     templateName,*/}
        {/*      //     items: R.map(R.pick(["name", "unit", "interval"]), items)*/}
        {/*      //   };*/}
        {/*      //   return labResultTemplateCreate(customTemplate).then(*/}
        {/*      //     labResultTemplateManyRefetch*/}
        {/*      //   );*/}
        {/*      // };*/}

        {/*      // const saveLabResultTemplateResult = R.map(*/}
        {/*      //   saveLabResultTemplate,*/}
        {/*      //   customPanels*/}
        {/*      // );*/}
        {/*      // if(debug) console.log('formValues', res, testPanels, customPanels, saveLabResultTemplateResult);*/}
        {/*      labResultCreateMany(res).then(labResultListRefetch);*/}

        {/*      // to show new lab result respective to date*/}
        {/*      // setNewlyAddedDate(templates.dateCollected);*/}

        {/*      setSelectedDate(moment(new Date()));*/}
        {/*      updateTestPanels(mkDict('templateId', addDisplayToggle(addItemsIndex(addItemsCount(labResultTemplateMany)))));*/}
        {/*      updateTestPanelsCount(0);*/}
        {/*      if (this.props.onInteraction) {*/}
        {/*        this.props.onInteraction('ADD');*/}
        {/*      }*/}
        {/*      //router.goBack();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Save*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

const mapDispatch = dispatch => ({
  setBreadCrumb: breadCrumb => dispatch(setBreadCrumb(breadCrumb))
});

// add display toggle to control visible panels
const addDisplayToggle = R.map(R.merge({ show: false }));

const renameDict = {
  id: 'templateId',
  name: 'templateName'
};
// const renameKeys = R.compose(
//   R.fromPairs,
//   R.map(([key, val]) => [R.propOr(key, key, renameDict), val]),
//   R.toPairs
// );
const addlabResultList = graphql(
  gql`
    query labResultList($filter: FilterFindManyLabResultsInput) {
      labResultMany(filter: $filter) {
        userId
        _id
        viewerId
        templateId
        deleted
        templateName
        dateCollected
        createdAt
        results {
          name
          unit
          value
          flag
          interval
          isInHouse
        }
      }
    }
  `,
  {
    options: ({ userId, params }) => ({
      variables: {
        filter: {
          userId: userId || params.patientId,
          filterDeleted: true /*temporarily solution to exclusive deleted labRes */
        }
      },
      fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data: { loading: labResultListLoading, labResultMany, refetch: labResultListRefetch } }) => {
      let labResultList = {}; // default value

      if (!labResultListLoading) {
        const r0a = R.reject(({ dateCollected, templateName }) => R.isNil(templateName) || R.isNil(dateCollected));
        const r0b = R.compose(R.reverse, R.sortBy(R.prop('dateCollected')));
        const r0 = R.map(R.over(R.lensProp('dateCollected'), dt => formatDateMM(dt)));
        const r1 = R.groupBy(R.prop('dateCollected'));
        const r2 = R.map(R.groupBy(R.prop('templateId')));
        const r3 = R.compose(trace('r2'), r2, r1, trace('r0'), r0, trace('r0b'), r0b, trace('r0a'), r0a, trace('r0a start'))(labResultMany);
        labResultList = r3;
      }

      return {
        labResultList,
        labResultListLoading,
        labResultListRefetch
      };
    }
  }
);

const enhanceAddLabResults = compose(
  addlabResultList,
  connect(null, mapDispatch),
  lifecycle({
    componentDidMount() {
      const currentPatient = RequestCache.get('nurse/currentPatient');
      const patientNameFromProgramme = _.get(this.currentProgram, 'user.profile.fullName');
      const patientNameFromRequestCache = _.get(currentPatient, 'profile.fullName');
      this.props.setBreadCrumb(`Patients / ${patientNameFromProgramme || patientNameFromRequestCache} / Add Lab Results`);
    }
  }),
  withProps(({ labResultTemplateMany }) => ({
    testPanelsDict: mkDict('templateId', addDisplayToggle(addItemsIndex(addItemsCount(labResultTemplateMany))))
  })),
  withProps(trace('enhanceAddLabResults props: ')),
  withState('testPanels', 'updateTestPanels', R.prop('testPanelsDict')),
  withState('testPanelsCount', 'updateTestPanelsCount', 0), // to determine if Save Button should be enabled
  withState('selectedDate', 'setSelectedDate', moment(new Date())),
  withHandlers({
    addPanel: ({ updateTestPanels, updateTestPanelsCount }) => {
      return R.compose(
          id =>
              updateTestPanels(testPanels => {
                updateTestPanelsCount(testPanelsCount => testPanelsCount + 1);
                return R.set(R.lensPath([id, 'show']), true)(testPanels);
              }),
          trace('addPanel')
      )
    },
    addCustomPanel: ({ updateTestPanels, updateTestPanelsCount }) =>
      R.compose(
        () =>
          updateTestPanels(testPanels => {
            updateTestPanelsCount(testPanelsCount => testPanelsCount + 1);
            const templateId = 'custom' + Math.trunc(Math.random() * 1e6);
            const templateBase = {
              type: 'custom',
              show: true,
              templateId,
              items: [],
              count: 0
            };
            return R.merge(testPanels, { [templateId]: templateBase });
          }),
        trace('addCustomPanel')
      ),
    deleteCustomPanel: ({ updateTestPanels, updateTestPanelsCount }) =>
      R.compose(
        id =>
          updateTestPanels(testPanels => {
            updateTestPanelsCount(testPanelsCount => testPanelsCount - 1);
            return R.omit([id])(testPanels);
          }),
        trace('deleteCustomPanel')
      ),
    deletePanel: ({ updateTestPanels, updateTestPanelsCount }) =>
      R.compose(
        id =>
          updateTestPanels(testPanels => {
            updateTestPanelsCount(testPanelsCount => testPanelsCount - 1);
            return R.set(R.lensPath([id, 'show']), false)(testPanels);
          }),
        trace('deletePanel')
      ),
    resetPanels: ({ updateTestPanels }) => R.compose(() => updateTestPanels(R.map(R.set(R.lensProp('show'), false)), trace('resetPanel'))),
    updateDateCollected: ({ updateTestPanels, setSelectedDate }) => dateCollectedM =>
      updateTestPanels(arr => {
        // 20200213 Thong
        setSelectedDate(dateCollectedM);

        if (dateCollectedM !== null) {
          // 20200213 Thong - should have at least 1 panel to enable Save button
          //updateSaveButtonEnabled(true);

          const dateCollected = dateCollectedM.toDate();
          // save dateCollected date for custom panels created after date is selected
          const arr2 = R.merge({ dateCollected }, R.map(R.set(R.lensProp('dateCollected'), dateCollected), arr));
          // console.log('date arr',  arr2)
          return arr2;
        }

        return arr;
      }),
    onCellChangeRootPath: ({ updateTestPanels }) => path => {
      return R.compose(value => {
        // console.log('cell update', path, value)
        const templateItemColLens = R.lensPath(path);
        updateTestPanels(R.set(templateItemColLens, value));
      }, trace('onCellChangeRootPath'));
    },
    onCellChangeRoot: ({ updateTestPanels }) => (templateId, itemKey, testCol) => {
      return R.compose(value => {
        // console.log('cell update', templateId, itemKey, testCol, value)
        const templateItemColLens = R.lensPath([templateId, 'items']);
        updateTestPanels(
          R.over(
            templateItemColLens,
            R.map(item => (item.key === itemKey ? R.merge(item, { [testCol]: value }) : item))
          )
        );
      }, trace('onCellChangeRoot'));
    },
    onDeleteTestItemRoot: ({ updateTestPanels }) => (templateId, itemKey) => {
      // console.log('onDeleteTestItemRoot',  templateId, itemKey);
      const templateLens = R.lensPath([templateId, 'items']);
      updateTestPanels(R.over(templateLens, R.compose(trace('2'), R.reject(R.propEq('key', itemKey)), trace('1'))));
    },
    onAddTestItemRoot: ({ updateTestPanels }) => templateId => {
      const templateLens = R.lensProp(templateId);
      updateTestPanels(testPanelsObj => {
        const dateCollected = testPanelsObj.dateCollected;
        const addItemToTemplate = testTemplate => {
          const newData = {
            key: 'testitem_' + testTemplate.count,
            name: 'name of test',
            unit: 'enter unit',
            interval: ''
          };
          const res = R.merge(
            testTemplate,
            (dateCollected ? R.merge({ dateCollected }) : R.identity)({
              count: testTemplate.count + 1,
              items: R.append(newData, testTemplate.items)
            })
          );
          return res;
        };
        return R.over(templateLens, addItemToTemplate, testPanelsObj);
      });
    }
  }),
  withProps(() => {
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
    return { viewerInfo, viewerId };
  }),
  withProps(({ routeParams }) => ({ userId: routeParams.patientId })),
  addLabResultSubmit,
  labResultTemplateCreateMutation,
  labResultTemplateRemoveMutation
);

const AddLabResults_v0 = enhanceAddLabResults(AddLabResultsComponent);

// Now use the `spinnerWhileLoading()` helper to add a loading spinner to any
// base component
const enhance = compose(queryLabResultTemplateMany, spinnerWhileLoading(R.prop('labResultTemplateManyLoading')));
const AddLabResults = enhance(AddLabResults_v0);

export { AddLabResults };
