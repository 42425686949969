import gql from 'graphql-tag'
import {EnrolledProgram} from 'graphqlModule/schema/EnrolledProgram'

export default gql`
  mutation changeCareTeam(
    $id: EID!
    $teamId:EID!
  ){
    editEnrolledProgram(
      id: $id
      teamId:$teamId
    ){
      ${EnrolledProgram}
    }
  }
`
