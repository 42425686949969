import type from '../constants';

export default{
  setSelectAlert : (list, selected)=>{
    const sa = {}
    if(list){
      sa.data = list
    }
    if(selected){
      sa.selected = selected
    }
    return {
      type : type.SET_SELECTALERT_LIST,
      SelectAlerts : sa
    }
  },
  selectCurrentProgram : (select)=>{
    return {
      type : type.SET_CURRENT_PROGRAM,
      select,
    }
  },
  selectCurrentPeriod:(select)=>{
    return {
      type: type.SET_CURRENT_PEROID,
      select,
    }
  },
  reset : ()=>{
    return {
      type : type.RESET,
    }
  },

  setSelectedTab: tab => ({
    type: type.SET_SELECTED_TAB_KEY,
    tab
  }),

  setSelectedRange: ({ from, to }) => ({
    type: type.SET_SELECTED_RANGE,
    from,
    to
  }),

  setSelectedFilters: filters => ({
    type: type.SET_SELECTED_FILTERS,
    filters
  }),

  setSelectedSorters: sorters => ({
    type: type.SET_SELECTED_SORTERS,
    sorters
  }),

  setSelectedPageInfo: pageInfo=>({
    type: type.SET_SELECTED_PAGEINFO,
    pageInfo
  })

}
