import { constants } from 'libModule/utils';

export default constants('Patient', 'profileEdit', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'UPDATE_FORM',
  'SET_SUBMITTING',
  'UPDATE_CG_FORM',
])
