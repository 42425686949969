import React from 'react';
import API from "../API";
import {Button, Col, message, Row} from "antd";
import helper from "../helper";
import singleREMap from "../constant/SingleREColumnsMap";
const hintMsg = 'By clicking “Move”, patient information will be moved\n' +
    'to the selected category immediately.';
const NotRemoteEnrolledComponent = class extends React.Component{
    render() {
        const { remoteEnrollment } = this.props;
        return <div>{helper.renderSingleREColumns(singleREMap('UNENROLLED',this),remoteEnrollment)}</div>
    }
    // constructor() {
    //     super();
    //     this.state = {
    //         showHint:false,
    //         selectedMove:null,
    //         memberId:null
    //     }
    // }
    // handleOnSelectChange=(selectedMove,memberId)=>{
    //     this.setState({
    //         selectedMove,
    //         showHint: true
    //     })
    // }
    // movePatientContent = ()=>{
    //     const { showHint,selectedMove } = this.state;
    //     const { currentDoc,refetchRemoteEnrollmentList,patientId } = this.props;
    //     const onClick = ()=>API.moveRemoteEnrollmentHandler({ status:selectedMove,memberId:patientId })
    //         .then(res=>{refetchRemoteEnrollmentList();
    //                     message.success(`Moved to ${selectedMove}`)}
    //              );
    //     return<Row>
    //         <Col span={17} offset={1}>Move to</Col>
    //         <Col span={17} offset={1}>
    //             { helper.getMoveOptions(this,selectedMove,{ currentDoc:{ memberId: patientId }}) }
    //         </Col>
    //         {
    //             showHint&&
    //             <Col span={17} offset={1} style={{ marginTop: 20,margin:20 }}>
    //                 <span>{hintMsg}</span>
    //             </Col>
    //         }
    //         {
    //             showHint&&
    //             <Col span={17} offset={1}>
    //                 <Button type={'primary'} onClick={onClick}>Move</Button>
    //             </Col>
    //         }
    //     </Row>
    // }
    // render(){
    //     return this.movePatientContent();
    //     // return 'this patient has is not remote enrolled, please create a visit and add this patient'
    // }
}

export default NotRemoteEnrolledComponent;