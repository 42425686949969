import gql from 'graphql-tag'
import {UserMini} from 'graphqlModule/schema/User'

export default gql `
  mutation editCaregiver (
    $id: EID!,
  ) {
    editCaregiver (
      id: $id,

    ) {
      ${UserMini}
    }
  }
`
