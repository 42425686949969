import gql from 'graphql-tag'
import {UserMini} from 'graphqlModule/schema/User'

export default gql`
  mutation registerCaregiverForMember(
    $id: EID!,
    $caregiver: [InputCareGiver]
  ) {
    registerCaregiverForMember(
      id: $id,
      caregiver: $caregiver
    ) { 
      ${UserMini}
    }
  }
`
