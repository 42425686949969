import types from '../constants/CommonConstants'

const initState = {
  careCoordination: '',
  followupSchedule: '',
  problemList: '',
  assessments: '',
  interventions: '',
  goals: '',
  resource: '',
  carePlanUpdated:false
}

export default (state=initState, action) => {
  switch(action.type) {
      case types.UPDATE_FIELD:
        if(action.payload.isUpdated){
          state.carePlanUpdated = true;
        }
        state[`${action.payload.field}`] = action.payload.str;
        return state;

      // case types.CAREPLANE_UPDATED:
      //   state.carePlanUpdated = true;
      //   return state;

      case types.RESET_CAREPLANE_UPDATED:
        state.carePlanUpdated = false;
        return state;

    //     careCoordination: action.payload
    //   }
    // case types.UPDATE_FOLLOWUP_SCHEDULE:
    //   return {
    //     ...state,
    //     followUpSchedule: action.payload
    //   }
    // case types.UPDATE_PROBLEM_LIST:
    //   return {
    //     ...state,
    //     problemList: action.payload
    //   }
    // case types.UPDATE_ASSESSMENTS:
    //   return {
    //     ...state,
    //     assessments: action.payload
    //   }
    // case types.UPDATE_INTERVENTIONS:
    //   return {
    //     ...state,
    //     interventions: action.payload
    //   }
    // case types.UPDATE_GOALS:
    //   return {
    //     ...state,
    //     goals: action.payload
    //   }
    // case types.UPDATE_RESOURCE:
    //   return {
    //     ...state,
    //     resource: action.payload
    //   }
    default:
      return state
  }
}
