import {
    React, BaseComponent, ND, moment, _
} from '../IHComponents/util'
import enUS from 'antd/lib/locale-provider/en_US';
// import moment from 'moment';
// import 'moment/locale/en-ca';
// moment.locale('en');

const format = 'MM/DD/YYYY';
    // 'MM-DD-YYYY'
const Component = class extends BaseComponent{

    init(){
        this.state = {
            start : null,
            end : null
        }
    }

    defineBaseClassName(){
        return 'VSM-DateRange'
    }
    componentWillReceiveProps(nextProps) {
      if (!nextProps.value ) {
        this.setState({
          start: null,
          end: null
        })
      } else {
        this.setState({
          start: nextProps.value[0],
          end: nextProps.value[1]
        })
      }

    }
    render(){
        let boxProps = this.getClassAndStyleProp()

        return (
            <ND.LocaleProvider locale={enUS}><div {...boxProps}>
                <span onClick={this.handlePrev.bind(this)}><i className="fa fa-angle-left"></i></span>
                <div className="ih-main">
                    {this.renderDateRange()}
                </div>
                <span onClick={this.handleNext.bind(this)}><i className="fa fa-angle-right"></i></span>
            </div></ND.LocaleProvider>
        )
    }

    handlePrev(){
        if(!this.state.start || !this.state.end)
            return false
        const diff = this.state.end.diff(this.state.start, 'days')

        const pa = this.state.start.clone().subtract(diff, 'days')

        this.setState({
            start : pa,
            end : this.state.start
        })

        this.handleOnChange()
    }
    handleNext(){
        if(!this.state.start || !this.state.end)
            return false
        const diff = this.state.end.diff(this.state.start, 'days')

        const pa = this.state.end.clone().add(diff, 'days')

        this.setState({
            start : this.state.end,
            end : pa
        })

        this.handleOnChange()
    }

    renderDateRange(){
        const p = {
            format : format,
            placeholder : ['Start Date', 'End Date'],
            allowClear : false,
            onChange : (mv)=>{
                this.setState({
                    start : mv[0],
                    end : mv[1]
                })

                this.handleOnChange()
            },
            value: null
        }

        if(this.state.start && this.state.end){
            p.value = [this.state.start, this.state.end]
        }
        else if(this.props.initData){
            this.state.start = this.props.initData[0]
            this.state.end = this.props.initData[1]
            p.defaultValue = this.props.initData
        }

        return <ND.DatePicker.RangePicker {...p} />
    }

    handleOnChange(){
        _.delay(()=>{
            const rs = [this.state.start.clone().toDate(), this.state.end.clone().toDate()]
            if(this.props.onChange){
                this.props.onChange(rs)
            }
        }, 200)
    }
}

export default Component
