import { message } from 'antd';
import useSendChatMessage from '../../chat/helper/useSendChatMessage';
import upsertPatientArticles from '../API/upsertPatientArticles';

export const articleMetaDataFields = [
  'id', 'url', 'name',
  'thumbnailImageUrl', 'summary', 'slug',
  'hasVideo', 'isNew', 'language',
  'publishDate', 'updatedAt', 'createdAt'
];

export default ({
  patientId,
} = {}) => {
  const { sendMessage } = useSendChatMessage({
    patientId,
  });

  const handleSendArticles = async (
    articles,
  ) => {
    let errorMessage = '';
    const loader = message.loading('Sending articles. Please wait');
    const sentResults = await Promise.all(_.map(articles, (article) => (
      new Promise((resolve) => {
        const { id, url } = article || {};
        if (!url) {
          resolve(null);
        }
        const metaData = _.pick(article, articleMetaDataFields);
        sendMessage({
          message: article.url,
          articleMetaData: metaData,
        }, (msgRes) => {
          if (!!_.get(msgRes, 'success')) {
            return resolve(id)
          }
          resolve(null)
        });
      })
    )));
    loader();
    const sentIds = _.filter(sentResults, (r) => !!r);
  
    if (sentIds.length !== articles.length) {
      const failedToSendIds = _.difference(_.map(articles, 'id'), sentIds);
      console.error(failedToSendIds.join(', '));
      errorMessage = 'Failed to send some article(s)';
    }

    return {
      sentIds,
      errorMessage,
    };
  };

  const handleSaveArticles = async (
    articleIds, 
    { onSuccess, onError } = {}
  ) => {
    try {
      if (articleIds.length) {
        const res = await upsertPatientArticles({
          patientId,
          articleIds,
        });
        if (!res || res.code !== 200) {
          throw new Error('Server error, ', _.get(res, 'msg'));
        }
        if (onSuccess)
          return onSuccess(res);
        return res;
      }
    } catch (error) {
      if (onError)
        return onError(error);
      return error;
    }
  };

  const handleSendAndSaveArticles = async (
    articles, 
    { onSuccess, onError } = {},
  ) => {
    const { sentIds, errorMessage } = await handleSendArticles(articles);

    return await handleSaveArticles(sentIds, {
      onSuccess: (res) => {
        onSuccess && onSuccess(res, !!errorMessage);
      }, 
      onError,
    });
  };
  
  return {
    sendArticles: handleSendArticles,
    saveArticles: handleSaveArticles,
    sendAndSaveArticles: handleSendAndSaveArticles,
  };
};