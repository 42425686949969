import { useState, useCallback, useEffect, useRef } from 'react';
import { ALL_CATEGORIES } from './useArticleCategories';
import searchArticles, { limit } from '../API/searchArticles';
import { LanguageEnum } from '../../../lib/constants';

export const INITIAL_CATEGORY = ALL_CATEGORIES;
export const PAGE_SIZE = limit;
export default ({
  initialSearchValue = '',
  initialCategory = INITIAL_CATEGORY,
  initialLanguages = [],
  initialPage = 1,
  initialDataSource = {},
  sendOnMount = true,
} = {}) => {
  const initialRender = useRef(true);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [category, setCategory] = useState(initialCategory);
  const [languages, setLanguages] = useState(initialLanguages);
  const [page, setPage] = useState(initialPage);
  const [isLoadingArticlesDataSource, setIsLoadingArticlesDataSource] = useState(false);
  const [articlesDataSource, setArticlesDataSource] = useState(initialDataSource);

  const searchAndSetArticlesDataSource = useCallback(_.debounce(async (
    searchValue,
    selectedCategory,
    selectedLanguages,
    page,
  ) => {
    if (isLoadingArticlesDataSource) return;

    setIsLoadingArticlesDataSource(true);
    try {
      let category = selectedCategory;
      if (selectedCategory === ALL_CATEGORIES) {
        category = undefined;
      }
      const languagesSupported = _.values(LanguageEnum);
      const languages = _.intersection(selectedLanguages, languagesSupported);

      const res = await searchArticles({
        searchString: searchValue,
        category,
        languages: !languages.length ? ['en'] : languages,
        page,
      });
      if (!res || res.code !== 200) {
        throw new Error('Server error: ', _.get(res, 'msg'));
      }
      const { content = [], totalPage, totalSize } = res.data || {};
      setArticlesDataSource({
        data: content,
        totalPage,
        totalSize,
      });
    } catch (error) {
      console.error('Failed to search articles, ', error);
    }
    setIsLoadingArticlesDataSource(false);
  }, 500), []);

  const onChange = ({
    changedSearchValue = searchValue,
    changedCategory = category,
    changedLanguages = languages,
    changedPage = page,
  }) => {
    if (changedSearchValue !== searchValue)
      setSearchValue(changedSearchValue);
    if (changedCategory !== category)
      setCategory(changedCategory);
    if (!_.isEqual(changedLanguages, languages))
      setLanguages(changedLanguages);
    if (changedPage !== page)
      setPage(changedPage);
  };

  const resetToInitial = () => {
    setSearchValue(initialSearchValue);
    setCategory(initialCategory);
    setLanguages(initialLanguages)
    setPage(initialPage);
  };

  useEffect(() => {
    if(!sendOnMount && initialRender.current)
      initialRender.current = false;
    else
      searchAndSetArticlesDataSource(searchValue, category, languages, page);
  }, [sendOnMount, searchValue, category, languages, page]);

  return {
    searchValue,
    category,
    page,
    onChange,
    resetToInitial,
    articlesDataSource,
    isLoadingArticlesDataSource,
  };
};