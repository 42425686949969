import React from 'react';
import { symbols, getRowTemplate } from '../constant/constants';

export const generatingCommonColumns = ({ dataSource, totalAmounts, forecastAmounts, filter, canFetchMore }) => _.map(_.keys(commonColumnMap), key => {
    return ({
      title: titleMaker(_.get(commonColumnMap, `${key}.title`), _.get(totalAmounts, key, 0), _.get(forecastAmounts, key, 0)),
      children: [
        {
          title: _.get(forecastAmounts, key, 0),
          key,
          dataIndex: key,
          render: (text, record, index) => cellRenderer(dataSource, canFetchMore, filter, _.get(record, key), index),
          width: _.get(commonColumnMap, `${key}.width`),
        }
      ]
    })
})

const commonColumnMap = {
 ease: { title: 'Ease (BP3L)', width: 80 },
 align: { title: 'Align (BG1)', width: 80 },
 lina: { title: 'Lina (HS2)', width: 80 },
 clear: { title: 'Clear (Hub)', width: 80 },
 iphone: { title: 'iPhone', width: 80 },
 lancet: { title: 'Lancets', width: 80 },
 strip: { title: 'Strips', width: 80 },
 a1c: { title: 'A1C', width: 80 },
 thermometer: { title: 'Thermometer', width: 80 },
 oximeter: { title: 'Oximeter', width: 80 },
 BG5S:{ title:'BG5S',width:80 },
 nexus: { title:'Nexus',width:80 }
}

const titleMaker = (title, totalAmount, forecastAmount) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <h6 style={{ textAlign:'center' }}>{title}</h6>
    <span style={{ fontSize: 20, fontWeight: 'bold', color: totalAmount < forecastAmount ? 'red' : '' }}>{totalAmount}</span>
  </div>
);

const cellRenderer = (dataSource, canFetchMore, filter, item, index) => {
  const showAdded = filteredBy(filter, 'Added', item);
  const showRemoved = filteredBy(filter, 'Removed', item);
  const addedRemovedRenderer = () => (!showAdded && !showRemoved) ? '-' : <span>{showAdded}{showRemoved}</span>
  return (index < dataSource.length -1) || !canFetchMore ?
    (
      <div style={{ textAlign: 'center', fontSize: 11 }}>
        {
          item ?
            <div>
              { addedRemovedRenderer() }
            </div>
            :
            '-'
        }
      </div>
    )
  :
    ({
      children: '',
      props: { colSpan: 0 }
    })
}

const mappingFilters = {
  sign: { Removed: '-', Added: '+' },
  color: { Removed: 'red', Added: 'green' },
}

const filteredBy = (filter, type, item) => {
  // if filter is both, first _.get will return undefined
  const sign = _.get(mappingFilters, `sign.${filter}`, _.get(mappingFilters, `sign.${type}`));
  const value = _.get(item, `total${type}`);
  const color = _.get(mappingFilters, `color.${type}`)
  const symbol = _.get(symbols, type);
  const isFiltered = _.includes(filter, type);
  const bothSelected = _.includes(filter, 'And');
  return isFiltered ? (+value > 0 ? <span style={{ color }}> {sign + value}{symbol}</span> : bothSelected ? '' : '-') : '';
}
