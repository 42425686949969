import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import editUserEmail from 'graphqlModule/mutation/editUserEmail'
import { userEmail as updateQueries } from 'graphqlModule/updateQueries/userEmail'
import { IHForm, IHButton, message, IHLoading } from 'ihcomponent'
import PropTypes from 'prop-types';
import { getFormProps } from 'providerModule/ProviderEdit/components/formData/ProviderEditEmailForm'
import { GQLHelper, validateAntdForm, submittingStyle } from 'libModule/utils';
import { closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import * as actions from 'providerModule/ProviderEdit/ProviderEditDuck'
import UserClass from 'modulesAll/graphql/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'provider/ProviderEdit/containers/ProviderEditEmailContainer'

  init() {
    const { userData } = this.props
    const userClassData = new UserClass(userData)
    const email = userClassData.format('email')

    const initData = {
      email
    }
    return initData
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-form-container'>
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const initData = this.init()
    const formProps = getFormProps({ initData })

    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }

  renderFooter() {
    const { closeModal, isSubmitting } = this.props

    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }

    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          closeModal()
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e)=>{
          this.submit()
        },
        type: 'submit',
        bsStyle: 'primary',
        disabled: isSubmitting,
        style: {
          marginLeft: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.cancel} />
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
      </div>
    )
    return footer
  }

  async submit() {
    const formRef = this.baseForm

    // validate all fields before submission
    const isFormValid = await validateAntdForm(formRef)
    if (!isFormValid) return

    const { mutate, userData, closeModal, openErrorModal, setSubmitting } = this.props
    const fieldsValue = formRef.getValue()
    const { email } = fieldsValue
    const variables = {
      id: userData.id,
      email
    }
    // const auditLogDetails = {
    //   loginId: userData.username,
    //   employeeId: userData.profile.employeeId,
    //   fullName: userData.profile.fullName,
    //   nric: userData.identification[0].value,
    //   email
    // }

    try {
        setSubmitting(true)
        const res = await mutate({ variables, updateQueries })

        // createAuditLog({
        //   action:I18N.get('auditLog.admin.provider.editEmail'),
        //   details: auditLogDetails,
        //   request: variables,
        //   response: res
        // })

        if(res.data.changeUserEmail){
            message.success('Edit provider email success')
            closeModal()
        }
    } catch(e){
        openErrorModal(GQLHelper.formatError(e))

        // createAuditLog({
        //   action:I18N.get('auditLog.admin.provider.editEmail'),
        //   details: auditLogDetails,
        //   request: variables,
        //   response: e,
        //   success: false
        // })
    } finally {
      setSubmitting(false)
    }
  }
}

// data from apollo
const withData = graphql(editUserEmail)

// connect apollo data with container
const ContainerWithData = withData(Container)

const mapState = ({ provider }, ownProps) => {
  return {
    ...provider.ProviderEdit
  }
}

const mapDispatch = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  userData: PropTypes.object,
  mutate: PropTypes.func,
  closeModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  isSubmitting: PropTypes.bool
}

export default connect(mapState, mapDispatch)(ContainerWithData)
