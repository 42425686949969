import gql from 'graphql-tag';

export default gql`
    query monthlyReviewList (
        $page: Int
        $count: Int
        $filters: MonthlyReviewListOpts
        $sort: MonthlyReviewListSort
      ){
        monthlyReviewList (
            page: $page
            count: $count
            filters: $filters
            sort:$sort
        ){
            data{
                id
                status
                memberId
                organizationId
                teamId
                updatedAt
                createdAt
                type
                dueDate
                note
                reviewedBy{
                    id
                    profile {
                      fullName
                    }
                }
                createdBy{
                  id
                }
                goals {
                  condition
                  value
                  status
                }
            }
              pageInfo{
                lastPage
                total
            }
        }
      }
`
