export default {
  typeText: {
    MTPR: 'Monthly Treatment Review Plan',
    MRE: 'Monthly Review & Engage',
		MR: 'Monthly Review',
  },
  collapseText: 'What is XXX?',
	header:'What is “XXX” and how should I handle it?',
  	MTPR: {
		contentTop : `The law requires that the treatment plan (clinical goals) be reviewed every month to check if the goals still apply based on the patient’s health status.`,
		contentMid:`For each of the clinical goals, please`,
		contentBottom:`1. Review the measurement summary and goal progress, if provided.
			2. Update the goal and/or status as needed.
			3. Follow the recommended action and proceed accordingly.`
	},
	MRE: {
		contentTop: `Monthly Review and Engage is generated for patients who are not having enough days of measurement. Please review the patient’s vitals activity, engage with the patient to understand their situation, boost motivation, and encourage more readings, with a goal of at least 4 total days of measurements per week.`,
		contentBottom: `Be mindful of the patient’s visit and labs compliance when engaging: ensure that f/u visit with MD is scheduled/completed, the labs are up to date or patient has a plan to obtain labs, and that the patient has the most up-to-date version of the app when necessary.`
	},
	MR: {
		header: 'What is “Monthly Review“ (MR)?',
		contentTop: `Monthly Review (MR) is generated for patients whose “Monthly Treatment Plan Review” (MTPR) has been finished by RD, but the total time spent on the patient this month is still less than 20 min (Time that is less than 20 min is NOT billable). Please review the patient’s related vitals and complete any necessary updates from the EMR to understand the patient’s situation and boost the time to reach 20 min.`,
	},
  MRENoteTemplate: {
    TEMPLATE_1: 'Reviewed the care plan and EMR information and made updates as needed.\nThis patient is infrequently measuring all their enrolled vitals, making the vitals trend estimate less accurate.\nReviewed the monitoring schedule with the patient and also encouraged more frequent readings.',
    TEMPLATE_2: 'Patient measures [vital] often enough to make assessing the vitals trend more accurate.\nReviewed the care plan and EMR information and made updates as needed.\nReviewed monitoring schedule and vitals trends.',
    TEMPLATE_3: 'This patient is infrequently measuring their [vital], making the vitals trend estimate less accurate.',
    TEMPLATE_4: 'Reviewed the care plan and EMR information and made updates as needed.\nReviewed monitoring schedule and the vitals trends.',
    TEMPLATE_5: 'This patient is infrequently measuring their [vital], making the vitals trend estimate less accurate.\nReviewed the care plan and EMR information and made updates as needed.\nReviewed the monitoring schedule and also encouraged more frequent readings.',
    TEMPLATE_6: 'This patient has not monitored [vital] at all in the past 30 days',
    TEMPLATE_7: 'This patient measured [vital] [count] times in the last 30 days. The average in recent 2 weeks is [recent2Week], the average in previous 2 weeks was [previous2To4Week], and the average in the 30 days prior to these recent 4 weeks was [previous4To8Week].',
  },
  	leaveModal:{
		header: "Almost done!",
		note: "Did you just finish a detailed review of this patient’s chart? Complete a Monthly Review of their progress now, and save some time later! ",
		startBtnText: "Start a MTPR",
		cancelBtnText: "I will do MTPR later"
	},
	checkboxText:'Do not show me next time',
	initMonthlyReview: "This is triggered automatically in the workstation every month as needed for billing purposes. It should only need to be done once monthly. However, you can choose to review it early here based on your own clinical judgement.",
	doneMonthlyReview: "This is triggered automatically in the workstation every month as needed for billing purposes. It should only be done once monthly.  However, if you feel you want to complete it again to adjust a patient's status, you may.",
	manualMonthlyReview: {
		"CA":{
			startBtnText: "Monthly Review (MR)",
			tooltipText: "Doing a Monthly Review can help to boost the time. The system will prompt you if time spent is less than 20 min, or you can choose to do it from here anytime based on your own clinical judgement.",
			reviewType: "MRE_ADDITIONAL",
			typeFilters: ["MRE_ADDITIONAL","MRE"],
			finishedBtnText: "Monthly Review (MR) was done on"
		},
		"RD":{
			startBtnText: "Monthly Treatment Plan Review (MTPR)",
			tooltipText: "Patient’s Monthly Treatment Plan Review needs to be done ONCE/month for billing purposes. You can wait for the system to prompt you, or you can choose to do it from here anytime based on your own clinical judgement. If the MTPR is done, the system won’t prompt you later.",
			reviewType: "MTPR",
			typeFilters: ["MTPR", "MTPR_ADDITIONAL"],
			finishedBtnText: "Monthly Treatment Plan Review (MTPR) was done on"
		},
		"HC":{
			startBtnText: "Monthly Treatment Plan Review (MTPR)",
			tooltipText: "Patient’s Monthly Treatment Plan Review needs to be done ONCE/month for billing purposes. You can wait for the system to prompt you, or you can choose to do it from here anytime based on your own clinical judgement. If the MTPR is done, the system won’t prompt you later.",
			reviewType: "MTPR",
			typeFilters: ["MTPR", "MTPR_ADDITIONAL"],
			finishedBtnText: "Monthly Treatment Plan Review (MTPR) was done on"
		}
	}
}
