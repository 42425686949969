import React, { Component } from 'react'
import { Table } from 'antd';
import tableMap from '../../constant/tableMap';
import '../../style/teamTable.scss';
const MAX_COUNT = 20;
import { keyMap,directionMap } from '../../User/Component/UserListComponent';
export default class TeamTableComponent extends Component {
    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        )
    }

    renderTable() {
          const { curTab, userList,userListPageInfo={ },page,setPage,loading,setOrder,setFilter } = this.props;
          // only have the pagination when the count is larger than 20
          const pagination = {
              total: userListPageInfo.total,
              current:page,
              onChange:(a)=>{
                  setPage(a)
              }
          }
          const onTableChange = (page,filter,sorter)=>{
              const { pageSize,current } = page
              const { columnKey, order } = sorter;
              const newSorter = {
                  field: keyMap[columnKey],
                  direction:directionMap[order]
              }
              setFilter(filter);
              setOrder(newSorter);
          }
          return <Table columns={tableMap(curTab,this)}
                        dataSource={userList}
                        loading={loading}
                        pagination={pagination}
                        onChange={onTableChange}
                        className='edit-team-table'
                        style={{fontSize: '12px'}}/>
    }
}
