import {renderForm} from "../../patient/AdditionalInfo/helpers";
import formConfig from '../constant/formConfig';
import { Select,Input,Spin, Avatar, AutoComplete } from 'antd';
import React from 'react';
import Client from 'libModule/gqlClient';
import providerList from 'graphqlModule/providerList';
import query from '../query/index';
import API from '../API/index';
import { browserHistory } from 'react-router';
import ProviderListOptionTemplate from '../Clinic/components/ProviderListOptionTemplate';
import { roleMap } from '../User/Component/UserListComponent';
import moment from 'moment';
import _ from "lodash";
import userList from 'graphqlModule/userList';
import {userListWithTeamChannelCount} from "../../graphql/userList";

const {Option} = AutoComplete;
const MAX_USER_COUNT = 500;
const { keyToForm } = formConfig;
const renderFormByKey = (localThis,key)=>{
    return renderForm(localThis,keyToForm(localThis,key));
}
const excludedRoles = ["Member","Reporter","Resident"];
const getMutateVariable=(data)=>{
    const phone = [];
    if (data.mobile) {
        phone.push({
            countryCode: data.mobileCountryCode ? parseInt(data.mobileCountryCode, 10) : '',
            number: data.mobile ? data.mobile.trim() : '',
            type: 'MOBILE'
        });
    }
    const appRoles = JSON.parse(sessionStorage.getItem('appRoles'));
    const roleOrg = appRoles[Object.keys(appRoles).find(key => key.toUpperCase().indexOf(":" + data.role.toUpperCase()) >= 0)];
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const result = {
        role: roleOrg,
        email: data.email,
        phone,
        employeeProfile: {
            department: data.department,
            employeeId: data.identificationValue,
            specialty: data.specialty
        },
        commonProfile: {
            firstName: data.firstName,
            lastName: data.lastName
        },
        username: data.username ? data.username : data.email
    };
    return result;
}

const convertUserDataToFormData=(variables)=> {
    const { email, phone, username, allRoles,id }  = variables;
    const role = allRoles && allRoles.find( val => { return val.category === 'PROVIDER' });
    const mobile = phone && phone.length > 0 ? phone[0].number : '';
    const mobileCountryCode = phone.length > 0 ? phone[0].countryCode : '+1';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const institution = _.get(currentUser,'selectedRole.organization.id') ||'';
    const { firstName, lastName, employeeId, department, specialty } = _.get(userClassData, 'variables.profile')
    const initData = {
        id,
        identificationValue: employeeId,
        firstName,
        lastName,
        role: role.name,
        mobile,
        mobileCountryCode,
        email: email && email.length > 0 ? email[0].address : '',
        institution,
        department,
        specialty,
        username
    }
    return initData
}
const getCurrentUser = ()=>{
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    return currentUser || {};
}
const getViewerOrgId = ()=>{
    const currentUser = getCurrentUser();
    return  _.get(currentUser,'selectedRole.organization.id');
}

const checkIsCurrentOrganizationLeaf = () => {
    const currentUser = getCurrentUser();
    return  _.get(currentUser,'selectedRole.organization.leaf');
}

const filterRefIdByNam = (roles,name)=>{
    return _.first(_.filter(roles,r=>r.name==name));
}
const getAllSubOrg =  ()=>{
    const orgId = getViewerOrgId();
    const variables = {
        page: 1,
        count: 99999,
        filters: {
            rootId:orgId
        }
    }
   return Client.query({
        query:query.organizationListWithOnlyRoles,
        variables,
        fetchPolicy:'network-only'
    })
}
export const defaultSort =  { 'field': 'LAST_NAME', 'direction': 'ASC' };
const getMembersByAppRoles = async (args)=>{
    const { roleName,page=1,count=10,rootId,sort=defaultSort,search } = args;
    const variables = {
        page,
        count,
        filters: { rootId,roleName,search },
        sort,

    };

    return Client.query({ query: query.userListWithOnlyRoles, variables: variables,fetchPolicy: 'network-only'});
}

const getMembersByRolesWithTeam = async(args)=>{
    const { roleName,page=1,count=10,rootId } = args;
    const variables = {
        page,
        count,
        filters: { rootId,roleName },
        sort: { 'field': 'LAST_NAME', 'direction': 'ASC' }
    };
    return Client.query({ query: userList,variables,fetchPolicy:'network-only'});
}
const getUserListWithOnlyRolesSummary =  async (args)=>{
    const { roleName,page=1,count=10,rootId } = args;
    const variables = {
        page,
        count,
        filters: { rootId,roleName },
        sort: { 'field': 'LAST_NAME', 'direction': 'ASC' }
    };
    return Client.query({ query:query.userListWithOnlyRolesSummary,variables,fetchPolicy:'network-only'});
}
const getAddedAndRemovedIds = (oldArray,newArray)=>{
    const addedList = _.difference(newArray,oldArray);
    const removedList = _.difference(oldArray,newArray);
    return {
        addedList,removedList
    }
}
const addAndRemoveManager = (addedList,removedList,orgId)=>{
    const promises =[];
    if(addedList.length) {
        promises.push(API.addOrgManagers({organizationId: orgId, managerIds: addedList}));
    };
    if(removedList.length) {
        promises.push(API.removeOrgManagers({organizationId: orgId, managerIds: removedList}));
    }
    return Promise.all(promises);
}
const renderMemberSel= (localThis)=>{
    const { userList,loadingMember } = localThis.state;
    const children = [];
    const { onSelectMember,onDeselectMember } = localThis;
    const rootId = getViewerOrgId();
    if(!userList) {
        getMembersByAppRoles({ rootId,count: MAX_USER_COUNT,roleName:roleMap['ALL'] }).then(res=>{
                localThis.setState({
                    userList: _.get(res, 'data.userList.data', []),
                    loadingMember:false
                })
        });
    }
    for(let user of userList||[]){
        const { profile:{ fullName }, id } = user;
        children.push(<Select.Option key={id} value={id} >{fullName}</Select.Option>)
    }

    if(!userList){
       return <div>Fetching user...</div>
    }

   return  <Select
            onSelect={onSelectMember}
            filterOption={(input, option) =>{
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
            onDeselect={onDeselectMember}
            mode="multiple"
            showSearch
            notFoundContent={loadingMember ? <Spin size="small" /> : null}
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
           >
            {children}
           </Select>
}

const renderTemplateSel = (localThis)=>{
    const { templateGroupsList } = localThis.props
    const { onSelectTemplates, onDeselectTemplates } = localThis;
    const templateGroups = _.get(templateGroupsList, 'data', [])

    return  <Select 
            onSelect={onSelectTemplates}
            onDeselect={onDeselectTemplates}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select">
                {
                    _.map(templateGroups, ({name, id, language}) => <Select.Option value={id} key={id}>
                                {_.upperFirst(name) + (language.length > 1 ? (" (" + language.length + ' languages)') : (" (" + _.get(language, '0.language.description') + ')', ''))}
                                </Select.Option>
                                )
                }
            </Select>
}

const getParentIds = (localThis, parentId, parentName) => {
    const orgList = _.filter(localThis.props.orgList, (org) => {
        return org.leaf === false;
    })
    let parentIds = [];
    parentIds.push({parentName:parentName, parentId:parentId})
    _.forEach(orgList, (val) => {
        if(val.id != '') {
            parentIds.push({parentName: val.name, parentId: val.id});
        }
    })
    parentIds = _.uniqBy(parentIds, a => a.parentId);
    return parentIds;
} 

const getRefIDWithRoleCategory = (selectedRoles = [], roles) => {
    let refIds = [];
    _.map(roles, r => {
        if(_.includes(selectedRoles, r.name)){
            refIds.push(r.refId);
        }
    })
    return refIds;
}

const addPrefixAndGoTo = (path, state, needGoTo = true) => {
  let from = browserHistory.getCurrentLocation().pathname || '/';

  let prefixUrl = '';
  const currentUser = getCurrentUser();
  const roleName = _.get(currentUser, 'selectedRole.name');
  if(roleName === 'Super Admin'){
    prefixUrl = '/superadmin';
  }

  const pathname = `${prefixUrl}/${path}`;

  if(needGoTo) {
    browserHistory.push({
      pathname, 
      state: { from, ...state }
    });
  }
  return pathname;
};


const generateDropDown = (list)=>{
    return <Select>
            { _.map(list, (l) => <Select.Option value={l.value} key={l}>{l.name}</Select.Option>)}
           </Select>
}
const hourMap = [
    '07','08','09','10','11','12','13','14','15','16','17','18'
];
const hourMap2 = [
    '6','7','8','9','10','11','12','13','14','15','16','17','18'
];
const hourMapIn1224Format = hourMap2.map(h=>({
    '12hour': parseInt(h) > 12 ? `${parseInt(h)-12}:00 pm` : `${h}:00 am`,
    '24hour': h
}));


const timeList = [{name: 'Eastern Standard Time (GMT-5)', value: 'America/New_York'}, 
                  {name: 'Central Standard Time (GMT-6)', value: 'America/Chicago'}, 
                  {name: 'Mountain Standard Time (GMT-7)', value:'America/Phoenix'}, 
                  {name: 'Pacific Standard Time (GMT-8)', value: 'America/Los_Angeles'}, 
                  {name: 'Alaska Standard Time (GMT-9)', value: 'America/Anchorage'}, 
                  {name: 'Hawaii-Aleutian Standard Time (GMT-10)', value: 'Pacific/Honolulu'}];


const renderProviderList = (localThis) => {
    const { state, props } = localThis;
    const { availableRoles, searchStr, canSearch }= state;
    const { organization } = props;
    const { providerList } = state;
    const options = [];
    if(canSearch){
        _.map(providerList, provider => {
            const { id, profile, allRoles } = provider;
            const {fullName, startDate} = profile;
            const roles = [];
            const sites = [];
            const previousRole = _.get(allRoles, '0.name');
            const displayedStartDate = startDate ? moment(startDate).format('MM/DD/YYYY') : '-';

            // default flag is false
            let alreadyInClinic = false;

            // use previousRole to determine the role-to-be-added
            // if the previousRole is included in the availableRoles, user is able to select this member
            // otherwise, user cannot select this member
            // ref: SelectedMembersComponent
            let available = _.includes(availableRoles, roleMap[previousRole]);

            _.map(allRoles, (r, i) => {

                // once found it existed, set flag to be true, disable this option
                if( _.get(r, 'organization.id') === _.get(organization, 'id')){
                    alreadyInClinic = true;
                }

                roles.push(<div key={i}>{roleMap[_.get(r, 'name')]}</div> );
                sites.push(<div key={i}>{_.get(r, 'organization.name')}</div>);

            })

            const avatar =  <Avatar src={_.get(profile, 'avatar.link') || '/image/default_avator.png'} />;

            const providerProps = {
                avatar,
                fullName,
                roles,
                sites,
                startDate: displayedStartDate,
                previousRole,
                alreadyInClinic
            };

            // only when the member has available previous role AND this member is NOT in the clinic
            // can  we enable the option
            let disabledOption = !(available && !alreadyInClinic);
            if(searchStr){
                if( _.includes(_.toLower(fullName), _.toLower(searchStr)) ){
                    options.push(<Option key={id} disabled={disabledOption}>
                        <ProviderListOptionTemplate {...providerProps} />
                    </Option>);
                }
            } else {
                options.push(<Option key={id} disabled={disabledOption}>
                    <ProviderListOptionTemplate {...providerProps} />
                </Option>);
            }

        })
        // add title for options if there is a result, else show no result
        options.unshift(
            <Option key='search-result-title' disabled>
                <ProviderListOptionTemplate hasNoResult={!options.length}/>
            </Option>
        )
    }

    return options
}
const getMembersByOrgIdWithChannelCount = async (args,isLastPage)=> {
    const {roleName, page = 1, count = 10, rootId, sort = defaultSort, search} = args;
    const variables = {
        page,
        count,
        filters: {rootId, roleName, search},
        sort,

    };

    return Client.query({query: userListWithTeamChannelCount, variables: variables,fetchPolicy:isLastPage ? 'network-only' :'cache-first' });
}
const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => _.isArray(fieldsError[field]));
}

export default {
    renderForm,
    renderFormByKey,
    getMutateVariable,
    convertUserDataToFormData,
    getCurrentUser,
    getViewerOrgId,
    checkIsCurrentOrganizationLeaf,
    renderMemberSel,
    filterRefIdByNam,
    getAddedAndRemovedIds,
    addAndRemoveManager,
    getParentIds,
    getMembersByAppRoles,
    getRefIDWithRoleCategory,
    addPrefixAndGoTo,
    generateDropDown,
    renderTemplateSel,
    timeList,
    hourMapIn1224Format,
    renderProviderList,
    hasErrors,
    getUserListWithOnlyRolesSummary,
    getMembersByRolesWithTeam,
    getMembersByOrgIdWithChannelCount
}