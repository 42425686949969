import PropTypes from 'prop-types';

const PatientPreferencePropType = PropTypes.shape({
  value: PropTypes.string,
  note: PropTypes.string
});

const PatientPreferencesPropType = PropTypes.shape({
  followupVisits: PatientPreferencePropType,
  nutritionCoaching: PatientPreferencePropType
}).isRequired;

const PatientPreferencesPropTypes = {
  container: {
    user: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
  },
  component: {
    form: PropTypes.object.isRequired,
    showDescription: PropTypes.bool,
    isMNTEnrolled: PropTypes.bool.isRequired,
    userPreference: PatientPreferencesPropType
  },
  text: {
    userPreference: PatientPreferencesPropType,
    onClick: PropTypes.func,
    customStyles: PropTypes.object,
    classNames: PropTypes.object
  }
};

export default PatientPreferencesPropTypes;