import gql from 'graphql-tag'

export default gql`
  mutation verifyOnBoardToken(
    $token: String!
  ){
    verifyOnBoardToken(
      token: $token
    ) {
      mobile {
        number
        countryCode
      }
      id
      token
      username
      identification {
        value
        MRN
      }
      role {
        category
      }
    }
  }
`
