import moment from 'moment';
const  greetingText = () => {
    const now = moment()
    const currentHour = now.hour()
    if (currentHour >= 12 && currentHour <=17) return "Good Afternoon,"
    else if (currentHour >= 18) return "Good Evening,"
    else return "Good Morning,"
}

const getAge = t => {
    return moment().diff(moment(t, 'YYYY-MM-DD'), 'years');
};

export const getAverageWeight = (recentWeights) => {
    const avgWeight = recentWeights.reduce((a, b) => a + b) / recentWeights.length;

    return isNaN(avgWeight) ? "" : avgWeight;
}

export const getABW = (heightInInches, avgWeight, gender) => {
    let IBW = "", ABW = "";
    
    if (gender === 'Male') {
        IBW = heightInInches == 60 ? 50 : 50 + 2.3 * (heightInInches - 60);
        ABW = IBW + 0.4 * (avgWeight - IBW);
    } else if (gender === 'Female') {
        IBW = heightInInches == 60 ? 45.5 : 45.5 + 2.3 * (heightInInches - 60);
        ABW = IBW + 0.4 * (avgWeight - IBW);
    }
    
    return isNaN(ABW) ? "" : ABW;
}

export const getBMR = (heightInCm, weight, BMI, gender, age, ABW, activity) => {
    let BMR = "";
    let multiMap = {
        SEDENTARY: 1.4, ACTIVE: 1.8, VERY_ACTIVE: 2.1,
        EXTREMELY_INACTIVE: 1.2, IRREGULARLY_EXERCISING: 1.5,
        MODERATELY_ACTIVE: 1.6, EXTREMELY_ACTIVE: 2.4
    }
    let multiplier = multiMap[activity] ? multiMap[activity] : 1;

    if (BMI <= 40) {
        if (gender === 'Male') {
            BMR = 10 * weight + 6.25 * heightInCm - 5 * age + 5;
        } else if (gender === 'Female') {
            BMR = 10 * weight + 6.25 * heightInCm - 5 * age - 161;
        }
    } else if (BMI > 40) {
        if (gender === 'Male') {
            BMR = 10 * ABW + 6.25 * heightInCm - 5 * age + 5;
        } else if (gender === 'Female') {
            BMR = 10 * ABW + 6.25 * heightInCm - 5 * age - 161;
        }
    } else {
        BMR = "";
    }
    
    return isNaN(BMR) ? "" : Math.round(multiplier * BMR);
}

export const getWeightsInRange = (recentWeights, weight) => {
    let weightsInRange = [];
    if (recentWeights.length > 0 && weight) {
        for (let i = 0; i < recentWeights.length; i++) {
            if (weightsInRange.length === 3) break;
            if (recentWeights[i].body_weight.value >= 0.85 * weight && recentWeights[i].body_weight.value <= 1.15 * weight) weightsInRange.push(recentWeights[i].body_weight.value);
        }
    }

    return weightsInRange;
}

export default {
    greetingText,
    getAge,
}
