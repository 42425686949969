import gql from 'graphql-tag';

const MonthlyReviewGoalMeetingType = `
  goalEnum
  meetingStatus
`;

export default gql`
  query getMonthlyReviewGoalMeeting ($memberId: EID!, $goalEnum: ClinicGoalEnum!, $conditionEnum: ConditionEnum!) {
    getMonthlyReviewGoalMeeting(
      memberId: $memberId,
      goalEnum: $goalEnum,
      conditionEnum: $conditionEnum
    ) {
      ${MonthlyReviewGoalMeetingType}
    }
  }

`;