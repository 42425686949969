import gql from 'graphql-tag'

export default gql`
  mutation editIoTDeviceConfig($deviceId:String!,$language:String,$volume:Int,$timbre:Int){
  editIoTDeviceConfig(deviceId:$deviceId,language:$language,volume:$volume,timbre:$timbre){
    id
    language
    volume
    timbre
  }
}`
