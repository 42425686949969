import gql from 'graphql-tag'
import Measure from './schema/Measure'

export default gql `
  mutation insertBG (
    $date: Date!,
    $timezone: String!,
    $glucose: BGValue!,
    $beforeMeal: Boolean,
    $mealType: BGMealType,
    $imageUrl: String,
    $notes: String,
    $memberId: EID,
  ){
    insertBG(
      date: $date,
      timezone: $timezone,
      glucose: $glucose,
      beforeMeal: $beforeMeal,
      mealType: $mealType,
      imageUrl: $imageUrl,
      notes: $notes,
      manualInput: true,
      memberId: $memberId
    ) {
     ${Measure}
    }
  }
`
