import $ from 'jquery';
import API from '../API';
import Store from 'libModule/reduxStore';


class HeartBeatTracking {
    _screenshots=[];
    constructor() {
        this.heartbeatMonitoring();
    }

    getPatientFromSelectedChannel=()=>{
        const state = Store.getState();
        const { chat } = state;
        const selectedChannel = _.get(chat,'main.selectedChannel');
        return selectedChannel;
    }

    registerScreenShotHelper = () => {
        const userSettings = this.getUserSettings();
        const {snapshotInterval, heartbeatInterval,heartbeatTimer,snapshotTimer} = userSettings;
        const variables = {
            heartbeatInterval,
            snapshots: this._screenshots
        };
        if(!userSettings) {
            clearInterval(snapshotTimer);
            clearInterval(heartbeatTimer);
            return;
        }
        API.createHeartbeatLog(variables).then(res => {
            const path = 'data.createHeartbeatLog';
            const sendHeartbeatLog = _.get(res, `${path}.sendHeartbeatLog`);
            const newHeartbeatInterval = _.get(res,`${path}.heartbeatInterval`);
            const newSnapshotInterval = _.get(res,`${path}.snapshotInterval`);
            const needRestHeartBeatInterval = newHeartbeatInterval !== heartbeatInterval;
            const needRestSnapShotInterval = newSnapshotInterval !== snapshotInterval;
            //if sendHeartbeatLog == true of snapShotInterval updated or heartbeatInterval updated reset usersettings
            if (!sendHeartbeatLog||needRestSnapShotInterval||needRestHeartBeatInterval) {
                userSettings.sendHeartbeatLog = sendHeartbeatLog;
                clearInterval(snapshotTimer);
                clearInterval(heartbeatTimer);
                //if snapShotInterval updated or heartbeatInterval updated
                if(sendHeartbeatLog) {
                    userSettings.heartbeatInterval = newHeartbeatInterval;
                    userSettings.snapshotInterval = newSnapshotInterval;
                    this.setUserSettings(userSettings);
                    this.startHeartBeat( newHeartbeatInterval );
                    this.startScreenShot( newSnapshotInterval );
                }
            }
        });
        //reset snapshots
        this._screenshots = [];
    }


    startHeartBeat = (heartbeatInterval) => {
        const userSettings = this.getUserSettings();
        let timer = setInterval(() => this.registerScreenShotHelper(), heartbeatInterval);
        userSettings.heartbeatTimer = timer;
        this.setUserSettings(userSettings);
    }

    startScreenShot = (snapshotInterval) => {
        const userSettings = this.getUserSettings();
        let timer = setInterval(() => this.generateScreenshot(), snapshotInterval);
        userSettings.snapshotTimer = timer;
        this.setUserSettings(userSettings);
    }

    getOrgPermissions = ()=>{
        return JSON.parse(sessionStorage.getItem('currentUserConfigPermissions')) || {};
    }
    getUserSettings = ()=>{
        return  JSON.parse(sessionStorage.getItem('userSettings')) || {};
    }

    setUserSettings = (userSettings)=>{
        sessionStorage.setItem('userSettings',JSON.stringify(userSettings));
    }
    setOrgPermissions=(getOrgPermissions)=>{
        sessionStorage.setItem('getOrgPermissions',JSON.stringify(getOrgPermissions));
    }
    heartbeatMonitoring = async () => {
        const { id } = JSON.parse(sessionStorage.getItem('currentUser'));
        const userSettings = _.get(await API.getUserSettings({id}),'data.user.userSettings');
        const {heartbeatInterval, snapshotInterval,sendHeartbeatLog} = userSettings;
        this.setUserSettings(userSettings);
        if(sendHeartbeatLog) {
            this.startScreenShot(snapshotInterval);
            this.startHeartBeat(heartbeatInterval);
        }
    }

    actionsIfEnableABTesting = (variables)=>{
        const selectedChannel = this.getPatientFromSelectedChannel();
        const patientId = selectedChannel.split('-')[1];
        const parsedPatientId = patientId ? btoa('accounts:'+patientId) : null;
        variables.patientId = parsedPatientId;
    }

    generateScreenshot = () => {
        const { snapshotInterval } = this.getUserSettings();
        const { orgPermissions } = this.getOrgPermissions();
        const { _screenshots } = this;
        // let _getFocusedElement = ()=>{
        //     if(document.hasFocus()){
        //         return document.activeElement.outerHTML;
        //     }
        //     return null;
        // }
        let hasFocusedElement = () => {
            return document.hasFocus();
        }
        let _parseEventObj = ({event}) => {
            const componentPath = window.location.href;
            let eventObj = {
                createdAt: new Date(),
                componentPath,
                snapshotInterval,
                focused: hasFocusedElement(),
                // event&&focusedElement ? focusedElement : !!focusedElement
            };
            if(orgPermissions.enableABTesting) {
                this.actionsIfEnableABTesting(eventObj);
            }
            return eventObj;
        }
        let _getElementByArgs = ({classNameList, ele}) => {
            let modalType = 'unknown type';
            for (let cs of classNameList) {
                if ($(ele).find(cs)[0]) {
                    modalType = cs;
                    break;
                }
            }
            return _parseEventObj({event: modalType});
        }

        let _getModalElement = (ele) => {
            const classNameList = ['.foodDiaryLogBookTab', '.medicine_form', '.editPostItModal'];
            return _getElementByArgs({ele, classNameList});
        }

        return (() => {
            let event = null;
            if ($('.ant-modal-wrap ')[0]) {
                event = _getModalElement($('.ant-modal-wrap ')[0]);
            } else {
                event = _parseEventObj({event});
            }
            _screenshots.push(event);
        })();
    }

}
export default HeartBeatTracking;
