import {
  React, IHButton
} from 'ihcomponent'
import {createContainer, goPath} from 'libModule/utils'
import {modalAction} from 'modulesAll/common/actions'
import I18N from 'modulesAll/I18N'

import ProgramInfo from './ProgramInfo'

const Component = class extends React.Component{
  static displayName = 'patient/programSummary/containers/main'

  render(){
    return (
      <div className="vsm-patient-programSummary">
        {this.renderBackLink()}
        {this.renderSummary()}
      </div>
    )
  }

  renderBackLink(){
    const goBack = ()=>{
      this.props.dispatch(goPath('/patients/testid'))
    }
    return (
      <div className="v-back" onClick={goBack.bind(this)}>
        <i className="fa fa-angle-left"></i>
        <span>Back to Care Plan</span>
      </div>
    )
  }

  renderSummary(){
    return (
      <div className="v-summary">
        <p className="v-tlt">{I18N.get('keywords.Program')} Summary</p>
        <div className="v-summary-main">
          <ProgramInfo />
          {this.renderBottomButton()}
        </div>
      </div>
    )
  }

  renderBottomButton(){
    const p1 = {
      type : 'default',
      label : 'Back to Editing',
      className : 'v-1',
      onClick : ()=>{

      }
    }
    const p2 = {
      type : 'primary',
      label : 'Complete Enrollment',
      className : 'v-2',
      onClick : ()=>{
        this.openConfirmModal()
      }
    }

    return (
      <div className="v-btn">
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    )
  }

  openConfirmModal(){
    const p1 = {
      type : 'primary',
      label : 'Continue to Confirmation',
      size : 'large',
      onClick : ()=>{

      }
    }
    const p2 = {
      label : 'Close',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : ()=>{
        this.props.dispatch(modalAction.closeModal())
      }
    }
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    )
    const param = {
      size : 'sm',
      title : 'Confirm Enrollment',
      content : 'Please confirm your patient enrollment infomation to complete the enrollment process.',
      footer : footer
    }

    this.props.dispatch(modalAction.openModal(param.content, param))
  }
}

const ComponentWithData = (Component)

export default createContainer(ComponentWithData, (state)=>{
  return {

  }
})
