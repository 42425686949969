import React from 'react';
import AutoCompleteComponent from "../../../patientList/main/components/AutoCompleteComponent";
import query from '../../query/index';
import helper from "../../helper";
import {Icon, Input} from "antd";
import OrganizationListTemplate from "./OrganizationListTemplate";
const SEARCH_INTERVAL = 600; // allow user to search every SEARCH_INTERVAL (ms)
import { SEARCH_COUNT_MAX } from 'libModule/constants';
import {goPath} from "../../../../lib/utils";
import '../../style/clinic.scss';
const SearchOrgComponent = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchStr: '',
        };
        this.startSearch = _.debounce(this.startSearch, SEARCH_INTERVAL); // delay the API call when typing
    };
    onSearchChange = searchStr => {
        //this.props.onSetParams({ search: searchStr, canSearch: false });
        this.setState({ searchStr, canSearch: false }, () => {
            this.startSearch();
        });
    };
    startSearch = () => this.setState({ canSearch: true });

    goToOrgProfile = (orgId,{ props })=>{
        const leaf = _.get(props,'children.props.leaf');
        const path = leaf ? 'clinic' :'area';
        goPath(`${path}/${orgId}`);
    }
    render() {
        const { searchStr = '', canSearch, showCreateNewPatientForm } = this.state;
        const currentUser = helper.getCurrentUser();
        const rootId = _.get(currentUser,'selectedRole.organization.id');
        const autocompleteVariables = {
            page: 1,
            count: SEARCH_COUNT_MAX,
            filters: { search: searchStr,rootId },
            search: { fields:['NAME'], match:searchStr },
        };
        return <AutoCompleteComponent
                    queryOptions={{
                        query: query.orgListForSearch,
                        variables: autocompleteVariables,
                        skip: !canSearch || !searchStr, // do not search when flag canSearch is false, or search keyword is empty
                        errorPolicy: 'ignore', // ignore error and allow MA/MD to view data
                    }}
                    autocompleteVariables={autocompleteVariables}
                    autocompleteProps={{
                        style:{ width: 400 },
                        className: 'search-orgList-autocomplete',
                        value: searchStr,
                        children: (
                            <Input
                                className='searchOrgName'
                                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Search by area or clinic name"
                            />
                        ),
                        onSearch: this.onSearchChange, // used to prevent multiple API calls when typing
                        onSelect: (org,doc) => this.goToOrgProfile(org,doc),
                        autoFocus: true,
                        defaultActiveFirstOption: false,
                        dropdownMenuStyle: { width: '400px !important', maxHeight: 'fit-content' }
                    }}
                    processDataSource={(data, OptionLayout) => {
                        const organizationList = _.get(data, 'organizationList.data', []);
                        const options =  organizationList.map(org => {
                        const { id } = org;

                        return <OptionLayout key={id} className={'orgItemClass'}>
                            <OrganizationListTemplate {...org} />
                        </OptionLayout>;
                        })

                        return options;
                    }}

                />
    }
}

export default SearchOrgComponent;