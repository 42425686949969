import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import I18N from '../../../I18N';
import { getSelectedCategoryLabels } from '../helpers';
import '../styles/info.scss';

const HiatusInfoContainer = props => {
  const { hiatusStatus } = props;

  if(_.isEmpty(hiatusStatus))
    return '--';

  const { startDate, endDate, reason, note } = hiatusStatus;

  return (<div id='hiatus-status-info-container'>
    <Row className='header'>This patient is in Hiatus Status</Row>
    <Row className='info-row categories'>
      <Col span={5} className='label'>
        Category:
      </Col>
      <Col>
        { _.map(getSelectedCategoryLabels(hiatusStatus)) }
      </Col>
    </Row>
    <Row className='info-row reason'>
      <Col span={5} className='label'>
        Reason:
      </Col>
      <Col>
        { reason }
      </Col>
    </Row>
    <Row className='info-row duration'>
      <Col span={5} className='label'>
        Duration:
      </Col>
      <Col>
        {moment(startDate).format('MM/DD/YYYY')}
        -
        {moment(endDate).format('MM/DD/YYYY')}
      </Col>
    </Row>
    <Row className='info-row note'>
      <Col span={5} className='label'>
        Note:
      </Col>
      <Col>
        {note}
      </Col>
    </Row>
    <Row className='info-row guide-note'>
      {I18N.get('hiatusStatus.guideNote')}
    </Row>
  </div>);
};

export default HiatusInfoContainer;