import configs from '../../../lib/config';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/article-controller/getCategories
export const getArticleCategoriesPath = `${domain}/articles/categories`;
export default (requestOptions) => {
  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    ...requestOptions
  };

  return fetch(getArticleCategoriesPath, fetchOptions).then((res) => res.json());
};