import React from 'react';
import { Divider } from 'antd';
import { AddLabResults } from '../../patient/main/containers/AddLabResults';
import { LabResults } from '../../patient/main/containers/LabResults';

let LabAddAndListContainer = class extends React.Component {
  renderAddLabRes = props => {
    let { userId, setSaveButtonRef } = props;
    return (
      <AddLabResults
        {...props}
        userId={userId}
        hideCancel={true}
        routeParams={{ userId }}
        setSaveButtonRef={setSaveButtonRef}
      />
    );
  };

  renderLabResults = props => {
    const { setSaveEditButtonRef } = props;
    return (
      <LabResults
        {...props}
        userId={props.userId}
        setEditEnabled={() => {}}
        setLabResStatus={() => {}}
        setSaveEditButtonRef={setSaveEditButtonRef}
      />
    );
  };

  render() {
    const { props } = this;
    return (
      <div>
        {this.renderAddLabRes(props)}
        <Divider />
        {this.renderLabResults(props)}
      </div>
    );
  }
};

export default LabAddAndListContainer;
