import React from 'react';
import { cellularDeviceBrandNameMap } from '../../constants';

export const CellularDeviceDisplayBrandComponent = ({
  brand,
}) => (
  brand
   ? <span>{cellularDeviceBrandNameMap[brand]}</span>
   : '--'
);
