import React from "react";
import { Checkbox,Button } from "antd";
import { VITAL_TYPES_LONG,VITAL_TYPE_MAP_FROM_SHORT, DEFAULT_VITAL_SCHEDULE } from 'modulesAll/utils/constants/task';
import {graphql} from "react-apollo";
import weeklyBGScheduleTemplateManyGQL
    from "../../../../../package/IHComponent/ShareCare/gql/weeklyBGScheduleTemplateMany";
import { compose } from 'react-apollo'
import '../style/index.scss';
import * as R from "ramda";
import {removeTypename} from "../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI";
import { CGMHelperServices } from '../../CGM/helpers';
const defaultTimes = 1;
const defaultFreq = 'DAILY';
const defaultBGTemplate = 'New to Monitoring - 2 Bedtime paired and 2 mealtime paired';

const AddNewVitalComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            vitalsToBeAdded:[]
        }
    }

    renderCheckboxGroup = ()=>{
        const { vitalsToBeAdded } = this.state;
        const { selectedVitals=[] } = this.props;
        const exerciseIndex = _.findIndex(VITAL_TYPES_LONG, 'Exercise Goal');
        const VITAL_TYPES_LONG_WITH_CGM = [
          ..._.slice(VITAL_TYPES_LONG, 0, exerciseIndex), 
          CGMHelperServices.brandFieldName,
          ..._.slice(VITAL_TYPES_LONG, exerciseIndex),
        ];
        const notAddedVitals = _.difference(VITAL_TYPES_LONG_WITH_CGM,selectedVitals);
        const options  = _.map(notAddedVitals,(v)=>({
            label: (
              v === CGMHelperServices.brandFieldName
                ? CGMHelperServices.dropdownOption.label 
                : v
            ),
            value: v,
        }));
        return <Checkbox.Group className='addVitalsCheckBoxGroup' options={options} value={vitalsToBeAdded} onChange={(vitalsToBeAdded)=> this.setState({ vitalsToBeAdded})}/>
    }

    handleAddVitals = ()=>{
        const { props,state } = this;
        const { selectedVitals=[],setShowAddModal,setSelectedVitals,addLocalVitals, weeklyBGScheduleTemplateMany=[] } = props;
        const { vitalsToBeAdded } = state;
        let vitalsAddToLocal = { };

        _.forEach(vitalsToBeAdded,v=>{
            const schedule = DEFAULT_VITAL_SCHEDULE[v] || {};
            const { times = 4, unit = 'DAILY' } = schedule;
            vitalsAddToLocal[v] = {
                type:v,
                templateDetails:{ },
                repeat:times,
                target:times,
                frequencyValue:{
                    times:times,
                    frequency: unit
                }
            }
        })
        if(_.includes(vitalsToBeAdded,'Blood Glucose')) {
            let defaultTemplate = {};
             _.forEach(weeklyBGScheduleTemplateMany, template => {if(template.name === defaultBGTemplate) defaultTemplate = template});
            const { _id,description,name,schedule } = defaultTemplate;
            vitalsAddToLocal['BGSchedule'] = {
                    frequency:'weeklyMeal',
                    weeklyMealSchedule:{
                        _id,description,name,schedule
                    }
            }
        }

        setSelectedVitals(selectedVitals.concat(vitalsToBeAdded));
        setShowAddModal(false);
        addLocalVitals(vitalsAddToLocal);
        this.setState({
            vitalsToBeAdded:[]
        })

    }

    renderButtons = ()=>{
        const { handleAddVitals,props } = this;
        const { setShowAddModal } = props
        return  <div className={'row'}>
                  <div className={'col-lg-12'} style={{ display:'flex' }}>
                    <Button onClick={ ()=>handleAddVitals() } id='addVitalsButton'>Add Vitals</Button>
                    <Button onClick={()=>setShowAddModal(false)} id='cancelAddVitalsButton' >Cancel</Button>
                  </div>
                </div>
    }

    render() {
        const { renderCheckboxGroup,renderButtons } = this;
        return <div style={{ display:'flex',flexDirection:'column'}}>
                    { renderCheckboxGroup() }
                    { renderButtons() }
               </div>
    }
}
const weeklyBGScheduleTemplateMany = graphql(weeklyBGScheduleTemplateManyGQL, {
    options: () => ({ variables: { }}),
    props: ({ ownProps, data: { loading, weeklyBGScheduleTemplateMany = [], refetch }}) => {


        const weeklyBGScheduleTemplateMany2 =
            // weeklyBGScheduleTemplateMany;
            R.map(removeTypename, weeklyBGScheduleTemplateMany);
        return ({
            weeklyBGScheduleTemplateMany: weeklyBGScheduleTemplateMany2,
            weeklyBGScheduleTemplateManyLoading: loading,
            weeklyBGScheduleTemplateManyRefetch: refetch,
        })
    },
});

export default compose(weeklyBGScheduleTemplateMany)(AddNewVitalComponent);