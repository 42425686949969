import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHLoading, IHButton} from 'ihcomponent';
import { goPath } from 'libModule/utils'
import { Icon, Button } from 'antd'
import StatusDropDown from '../../InterventionNotes/containers/StatusDropDownContainer'
import UserClass from 'graphqlModule/class/User'
import { formatReadingFromRow, hasMeasurement, hasMissedAlert, renderVitalType } from 'modulesAll/utils/helpers/task';
import moment from 'moment'
import I18N from 'modulesAll/I18N';
import '../style/index.scss';
import $ from 'jquery';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {isEqual} from 'lodash';

const ProviderAlertListTable = class extends React.Component {
  static displayName = 'ProviderAlertListTableComponent';

  constructor(props) {
    super(props);
    this.addReviewedBtns = this.addReviewedBtns.bind(this);
    this.checkIfReviewed = this.checkIfReviewed.bind(this);
    this.state = { reviewedBtnList: [] };
  }
  addReviewedBtns(id) {
    this.setState({ reviewedBtnList: [...this.state.reviewedBtnList, id] }); // TODO: Refactor to use prevState
  }
  checkIfReviewed(id) {
    return this.state.reviewedBtnList.includes(id);
  }
  renderSubtitle(data){
    $('.ih-tlt').addClass('alertSubtitle');
    $('.alertSubtitle').attr('data',data);
  }

  getVariables(){
        const ownProps = this.props;
        const enrolledProgramId = UserClass.getEnrolledProgramActiveIdList() || []
        const careStatusFromRedux =   _.get(ownProps, 'filter.careStatus',['NEW','HANDLED']);
        const careStatusOpt = careStatusFromRedux.indexOf('All')!=-1 ? null : careStatusFromRedux;
        const variables = {
            filters: {
                fromDate: new Date((new Date()).getTime() - 7 * 24 * 3600000),
                severity: _.get(ownProps, 'filter.severity'),
                // _.get(ownProps, 'filter.severity') || ['MISSED', 'RISK', 'CRITICAL'],
                enrolledProgramId,
                'type': _.get(ownProps, 'filter.type'),
                'adminProgramId': _.get(ownProps, 'filter.programId'),
                'careStatus': careStatusOpt,
                alertsOnly: true
            },
            page: _.get(ownProps, 'page.current', 1),
            count: 10,
            sort: { field: 'DATE', direction: 'DESC'}
        }
        const sortField = _.get(ownProps, 'sort.fieldGraph', null);
        if (sortField) {
            variables.sort = {
                field: sortField,
                direction: _.get(ownProps, 'sort.direction', 'ASC'),
            };
        }
        return variables;
  }

  render() {
    const { alertList, onAddClick, loading, onTableChange, adminProgramFilter, vitalFilters, page, sort, alertsCount, filter,
      changeCurrentAlertsOption, currentAlertsOption, enrolledUserStatusList, isLoading_enrolledUserStats, handleReviewButton } = this.props;
    const setCategoryToAlerts = true;
    // Columns & data for alerts table
    const columns = [
      {
        title: 'Date / Time',
        key: 'date',
        dataIndex: 'date',
        sorter: (a, b) => a.date - b.date,
        render: (item, row) => moment(item).format('MM/DD/YYYY HH:mm')
        // format('MMM-DD-YYYY hh:mm a')
      },
      {
        title: 'Patient Name',
        key: 'user.profile.fullName',
        dataIndex: 'user.profile.fullName',
      },
      {
        title: <span>Reading <em style={{fontSize: '11px'}}> (M = Manual)</em></span>,
        key: 'severity',
        dataIndex: 'severity',
        render: (item, row) => hasMissedAlert(row) ? <span className="miss-black">Missed</span> : hasMeasurement(row) ? formatReadingFromRow(row) : '',
        filters: [
          { value: 'RISK', text: 'L/H' },
          { value: 'CRITICAL', text: 'CL/CH' },
          { value: 'MISSED', text: 'Missed' }
        ],
        filteredValue: _.get(filter, 'severity')
      },
      // {
      //   title: I18N.get('keywords.NRIC/FIN'),
      //   key: 'user.identification.0.value',
      //   dataIndex: 'user.identification.0.value',
      // },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: renderVitalType,
        filters: vitalFilters || [],
        onFilter : (a, doc)=>{
          return a === doc.type
        }
      },
      {
        title: `${I18N.get('keywords.Program')}`,
        key: 'programId',
        dataIndex: 'enrolledProgram.id',
        render:(id, row) => row.enrolledProgram.name,
        filters: adminProgramFilter,
        filteredValue: _.get(filter, 'programId')
      },
      {
        title: 'Status',
        key: 'careStatus',
        dataIndex: 'careStatus',
        render: (item, row) => {
          switch (item) {
            case 'NEW': return 'Open'
            case 'HANDLED': return 'Reviewed by ' + _.get(row.caredBy, 'profile.fullName')
            case 'CLOSED': return 'Closed by ' + _.get(row.caredBy, 'profile.fullName')
          }
        },
        filteredValue: _.get(filter, 'careStatus', []),
        filters: [
          { value: 'All', text: 'All' },
          { value: 'NEW', text: 'Open' },
          { value: 'HANDLED', text: 'Reviewed' },
          { value: 'CLOSED', text: 'Closed' }
        ]
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '14%',
        render: (item) => <StatusDropDown row={item} variables={this.getVariables()}/>
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        width: '8%',
        render: (notes, row) => {
          const setCategoryToAlerts = true;
          if (notes && notes.length > 0) {
            return <div onClick={(e) => {
              e.stopPropagation()
              onAddClick(row,setCategoryToAlerts)
            }} style={{cursor: 'pointer'}}><Icon type="file-text"/></div>
          }
          const buttonProps = {
            label: 'Add >',
            size: 'small',
            bsStyle: 'primary',
            onClick: (e) => {
              Mixpanel.track('clicked','add','alert', {PATIENT_ID : row.user.id});
              e.stopPropagation()
              onAddClick(row,setCategoryToAlerts)
            },
            className: 'table-action-button'
          }
          return <IHButton {...buttonProps} />
        }
      }
    ];

    // Columns & data for 'inactive patients' option
    const optionsColumns = [{
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName'
    }, {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName'
    }, {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender'
    }, {
      title: 'Birthday',
      key: 'birthday',
      dataIndex: 'birthday',
      render: (item, row) => row.birthday ? moment(row.birthday).format('MMM-DD-YYYY') : null
    }, {
        title: 'Email',
        key: 'email.address',
        dataIndex: 'email.address',
        render: (item, row) => _.get(row, 'emails[0].address', '')
    }, {
      title: 'Phone',
      key: 'phone.number',
      dataIndex: 'phone.number',
      render: (item, row) => {
        const number1 = _.get(row, 'phones[0].countryCode', '') + ' ' +  _.get(row, 'phones[0].number', '');
        const number2 = _.get(row, 'phones[1].countryCode', '') + ' ' +  _.get(row, 'phones[1].number', '');
        return <div><p>{ number1 }</p><p>{ number2 }</p></div>;
      }
    }, {
      title: 'Last Reviewed At',
      key: 'lastReviewedAt',
      dataIndex: 'lastReviewedAt',
      render: (item, row) => item ? moment(item).format('MMM-DD-YYYY hh:mm a') : null
    }, {
      title: 'Last Measured At',
      key: 'lastMeasuredAt',
      dataIndex: 'lastMeasuredAt',
      render: (item, row) => item ? moment(item).format('MMM-DD-YYYY hh:mm a') : null
    }, {
      title: 'Contact Method',
      key: 'contactMethod',
      dataIndex: 'contactMethod',
      render: (item, row) => {
        const patientId = row.id;
        const isReviewed = this.checkIfReviewed(patientId);
        const buttonProps = {
          size: 'default',
          onClick: (e) => {
            Mixpanel.track('clicked','patient','alert', {PATIENT_ID : patientId});
            e.stopPropagation();
            this.addReviewedBtns(patientId);
            handleReviewButton(patientId);
          },
          className: isReviewed ? 'alerts-review-btn-clicked' : 'alerts-review-btn'
        }
        return <Button type='primary' {...buttonProps}>{ isReviewed ? 'Reviewed' : 'Review' }</Button>
      }
    }
    ];
    const total = _.get(alertList, 'pageInfo.total', 0);
    const alertCount = alert
      // const careStatus = filter.careStatus&&filter.careStatus[0] || 'Open';
    const currentPage = _.get(page,'current',1);
    const start = (currentPage-1)*10+1;
    const end = Math.min(currentPage*10,total);
    let check = false;
    const tableProps = {
      columns: currentAlertsOption === 'ALERTS' ? columns : optionsColumns,
      rowKey: 'id',
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: 10,
        total: _.get(alertList, 'pageInfo.total', 0),
        onChange: (e) => {
          Mixpanel.track('clicked','pager','alert', {PAGE_NUM : "Page" + e});
          check = true;
        }
        // showTotal:(tota,range)=>`${range[0]}-${range[1]} of ${total}`
      },
      onChange: (p, f, s) => {
        let result = null;
        if(!isEqual(f.severity, this.props.filter.severity)) {
          result = ['set', 'reading filter'];
        } else if(!isEqual(f.type, this.props.filter.type)) {
          result = ['set', 'type filter'];
        } else if(!isEqual(f.programId, this.props.filter.programId)) {
          result = ['set', 'program filter'];
        } else if(isEqual(f.careStatus, this.props.filter.careStatus)){
          result = ['set', 'status filter'];
        }
        if(result){
          if(result[1] == 'reading filter') {
            if((!f.severity && this.props.filter.severity) || (f.severity && f.severity.length === 0 && this.props.filter.severity)) {
              result[0] = 'reset';
              result[1] = 'reading filter';
            }
          } else if(result[1] == 'type filter') {
            if((!f.type && this.props.filter.type) || (f.type && f.type.length === 0 && this.props.filter.type)) {
              result[0] = 'reset';
              result[1] = 'type filter';
            }
          } else if(result[1] == 'program filter') {
            if((!f.programId && this.props.filter.programId) || (f.programId && f.programId.length === 0 && this.props.filter.programId)) {
              result[0] = 'reset';
              result[1] = 'program filter';
            }
          } else if(result[1] == 'status filter'){
            if((!f.careStatus && this.props.filter.careStatus) || (f.careStatus && f.careStatus.length === 0 && this.props.filter.careStatus)) {
              result[0] = 'reset';
              result[1] = 'status filter';
            }
          }
        
        if(check == false) {
          Mixpanel.track(result[0] + '', result[1] + '', 'alert', {FILTER_TYPE: result[1]});
        }
      }
        return onTableChange(p, f, s);
      },
      loading: currentAlertsOption === 'ALERTS' ? { spinning:loading,indicator: <IHLoading/> } : isLoading_enrolledUserStats,
      dataSource: currentAlertsOption === 'ALERTS' ? (alertList ? alertList.data : null) : (enrolledUserStatusList ? enrolledUserStatusList.inactiveUsers : null),
      // title: `Alerts (${_.get(alertList, 'pageInfo.total', 0)}/${alertsCount})`,
      title:`Alerts:`,
          // `Alerts ${_.get(alertList,'pageInfo.total',0)}`,
      onRow: (row) => {
        const userId = row.user.id;
        return {
          onClick: () => {
            Mixpanel.track('clicked','patient','alert', {PATIENT_ID : userId});
            goPath(`patients/${userId}/enrolledprogram/default`)
            // goPath(`patients/${row.user.id}/enrolledprogram/${row.enrolledProgram.id}`)
          }
        }
      },
      // 2/14/18 - JV - Below for options box: alerts & inactive patients
      optionsBox: true,
      titleClassName: 'alertsOptBox',
      currentAlertsOption,
      changeCurrentAlertsOption,
      optionsColumns
    };
    this.renderSubtitle(`${total}`!=0 ? `(${start}-${end} of ${total})`:'0');
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no data available</p>
      </div>
    );

    return (
      <div className="patient-task-table">
        <IHSearchTable {...tableProps} noDataElem={noDataElem} />
      </div>
    );
  }
};

ProviderAlertListTable.propTypes = {
  alertList: PropTypes.object,
  onRow: PropTypes.func,
  changeCurrentAlertsOption: PropTypes.func.isRequired,
  currentAlertsOption: PropTypes.string.isRequired,
  enrolledUserStatusList: PropTypes.object,
  currentProgram: PropTypes.array
};

const styles = {
  takeReading: {
    backgroundColor: '#258ce6',
    width: '7.3em',
    padding: '0.2em 1em',
    borderRadius: '10px',
    color: 'white',
    cursor: 'pointer',
  },
};

export default ProviderAlertListTable;
