import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Checkbox, Select, DatePicker, Input, Button, Popover, Tooltip, message } from 'antd';
import moment from 'moment-timezone';

import HiatusOverlapNote from '../components/OverlapNote';

import I18N from '../../../I18N';
import hsConstants from '../constants';
import { getSelectedCategoryValues } from '../helpers';
import { setPatientHiatus, resumePatientHiatus } from '../apis';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';

import '../styles/main.scss';
import monent from "moment";

const HiatusMainContainer = props => {
  const { form, user, hiatusStatus, onSubmit, onCancel, onResume } = props;
  const { getFieldDecorator, getFieldsValue, validateFieldsAndScroll, setFieldsValue } = form;
  const [actionLoading, setActionLoading] = useState(false);

  const { categories, reason, startDate, endDate, note } = getFieldsValue();

  const memberId = _.get(user, 'id');
  const hiatusExists = hiatusStatus && _.get(hiatusStatus, 'id');
  const hiatusAction = !hiatusExists ? hsConstants.ACTION_ENUM.CREATE : hsConstants.ACTION_ENUM.UPDATE;

  const handleCancel = () => {
    if (onCancel)
      onCancel();
  };

  const handleSubmit = () => {
    validateFieldsAndScroll(async (error, values) => {
      if (error)
        return;

      setActionLoading(true);
      try {
        const { categories, startDate, endDate, reason, note } = values;

        const variables = {
          memberId,
          startDate: startDate.startOf('day').valueOf(),
          endDate: endDate.endOf('day').valueOf(),
          reason: hsConstants.REASON_ENUM[reason],
          timezone: DATE_HELPERS.getClinicTimezone(),
          note
        };

        _.forEach(hsConstants.categoryOptions, ({ value }) => {
          _.set(variables, value, !!_.includes(categories, value));
        });

        const res = await setPatientHiatus({ variables, hiatusAction });
        if (hiatusAction === hsConstants.ACTION_ENUM.CREATE)
          message.success(I18N.get('hiatusStatus.toast.createSuccess'));
        else if (hiatusAction === hsConstants.ACTION_ENUM.UPDATE)
          message.success(I18N.get('hiatusStatus.toast.updateSuccess'));

        if (onSubmit)
          onSubmit(res);
      } catch (error) {
        console.error('Error: handleSubmit', error);
        message.error(error.message);
      } finally {
        setActionLoading(false);
      }
    });
  };

  const handleResume = async () => {
    setActionLoading(true);

    try {
      const { resumeReason } = getFieldsValue(['resumeReason']);
      const variables = {
        memberId,
        reason: resumeReason || ''
      };

      const res = await resumePatientHiatus({ variables });
      message.success(I18N.get('hiatusStatus.toast.resumeSuccess'));

      if (onResume)
        onResume(res);
    } catch (error) {
      console.error(error);
    } finally {
      setActionLoading(false);
    }
  };

  const handleSelectHiatusCategory = (selectedCategories = []) => {
    const isEmptyDate = !startDate || !endDate;
    const is2YearSelected = moment(endDate).diff(moment(startDate), true) >= 2;
    const shouldFillDates = isEmptyDate || !is2YearSelected;
    if (
      shouldFillDates
      && (!categories || !categories.includes('hiatusUnableToEngage'))
      && selectedCategories.includes('hiatusUnableToEngage')
    ) {
      const _startDate = moment(startDate || new Date());
      setFieldsValue({
        startDate: _startDate,
        endDate: _startDate.clone().add(2, 'years')
      });
    }
  };

  const onChangeHiatusReason = (reason) => {
    // const now = new Date();
    // if (reason === 'HIATUS_UNABLE_TO_ENGAGE') {
    //   setFieldsValue({
    //     startDate: moment(now),
    //     endDate: moment(now).add(2,'years')
    //   })
    // }
  }

  const checkIfNewHiatusValid = () => {
    return !_.isEmpty(categories) && reason && startDate && endDate && note;
  };
  const hasStartDate = !!_.get(hiatusStatus, 'startDate') || !!startDate;
  const isNewHiatusValid = checkIfNewHiatusValid(); // new and editing

  return (
    <div id='hiatus-status-main-container'>
      <Row>
        <Form.Item label='In which category do you want to put patient in hiatus status' colon={false}>
          {
            getFieldDecorator('categories', {
              initialValue: getSelectedCategoryValues(hiatusStatus),
              rules: [
                { required: true, message: 'Please select any' }
              ]
            })(
              <Checkbox.Group
                options={hsConstants.categoryOptions}
                onChange={handleSelectHiatusCategory}
              />
            )
          }
        </Form.Item>
      </Row>
      <Row>
        <Form.Item label='Reason' colon={false}>
          {
            getFieldDecorator('reason', {
              initialValue: hsConstants.REASON_ENUM_INV[_.get(hiatusStatus, 'reason')],
              rules: [
                { required: true, message: 'Please select' }
              ]
            })(<Select style={{ width: '100%' }} onChange={onChangeHiatusReason}>
              {_.map(hsConstants.REASON_ENUM, (label, value) => <Select.Option key={value} value={value}>{label}</Select.Option>)}
            </Select>)
          }
        </Form.Item>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label='From' colon={false}>
            {
              getFieldDecorator('startDate', {
                initialValue: _.get(hiatusStatus, 'startDate') ? moment(_.get(hiatusStatus, 'startDate')) : null,
                rules: [
                  { required: true, message: 'Please select' }
                ]
              })(<DatePicker
                format='MM/DD/YYYY'
                disabledDate={cur => cur.isBefore(moment(new Date()).startOf('day'))}
              />)
            }
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='To' colon={false}>
            {
              getFieldDecorator('endDate', {
                initialValue: _.get(hiatusStatus, 'endDate') ? moment(_.get(hiatusStatus, 'endDate')) : null,
                rules: [
                  { required: true, message: 'Please select' }
                ]
              })(<DatePicker
                disabled={!hasStartDate}
                disabledDate={cur => startDate && cur.isBefore(startDate)}
                format='MM/DD/YYYY'
              />)
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item label='Note' colon={false}>
          {
            getFieldDecorator('note', {
              initialValue: _.get(hiatusStatus, 'note') || '',
              rules: [
                { required: true, whiteSpace: true, message: 'Please enter' }
              ]
            })(<Input.TextArea
              autoSize={{ minRows: 2 }}
            />)
          }
        </Form.Item>
      </Row>
      {
        isNewHiatusValid &&
        <div>
          <Row>
            <Col className='submit-guide'>
              {I18N.get('hiatusStatus.submitGuide')}
            </Col>
          </Row>
          <HiatusOverlapNote
            memberId={memberId}
            hiatusStatus={{
              startDate,
              endDate,
              visitHiatus: !!_.includes(categories, 'visitHiatus')
            }}
          />
        </div>
      }
      <Row className='main-buttons'>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button type='primary' onClick={handleSubmit} disabled={!isNewHiatusValid || actionLoading}>
          {!hiatusExists ? 'Submit' : 'Save'}
        </Button>
      </Row>
      {
        (hiatusExists) &&
        <Row>
          <Col>
            <Popover
              overlayClassName='hiatus-status-resume-popover'
              content={<div className='resume-content'>
                <div className='title'>
                  Are you sure to resume this patient?
                </div>
                <div className='reason'>
                  {
                    getFieldDecorator('resumeReason')
                      (<Input.TextArea autoSize={{ minRows: 2 }} placeholder='(Optional) Please write the reason' />)
                  }
                </div>
                <div className='buttons'>
                  <Button onClick={() => {
                    const btnEl = document.querySelector('.ant-btn-link.resume-button');
                    btnEl && btnEl.click();
                  }}>
                    Cancel
                  </Button>
                  <Button type='primary' onClick={handleResume}>
                    Yes, resume
                  </Button>
                </div>
              </div>}
              trigger='click'
              destroyTooltipOnHide
            >
              <Tooltip
                overlayClassName='hiatus-status-resume-tip'
                title={I18N.get('hiatusStatus.tipText.resume')}
              >
                <Button type='link' className={`resume-button ${(!_.isNil(categories) && _.isEmpty(categories)) ? 'revert' : ''}`}>
                  I want to resume this patient
                </Button>
              </Tooltip>
            </Popover>
          </Col>
        </Row>
      }
    </div>
  );
};

HiatusMainContainer.propTypes = {
  user: PropTypes.object.isRequired,
  hiatusStatus: PropTypes.any
};

export default Form.create({})(HiatusMainContainer);