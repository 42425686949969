import {
    React
} from 'ihcomponent';


class MinIndicatorComponent extends React.Component{
    render(){
        return(
            <div style={{width:400,display:'flex'}}>
                <div style={{flex:4,marginLeft:2}} className='minIndicator bar1'>
                    <span>0</span>
                    <span>min</span>
                </div>
                <div style={{flex:8,marginLeft:2}} className='minIndicator bar2'>
                    <span>20</span>
                    <span>mins</span>
                </div>
                <div style={{flex:6,marginLeft:2}} className='minIndicator bar3'>
                    <span>60</span>
                    <span>mins</span>
                </div>
                <div style={{flex:1}} className='minIndicator bar4'>
                    <span>90</span>
                    <span>mins</span>
                </div>
            </div>
        )
    }
}


export default MinIndicatorComponent;
