import moment from 'moment';
import React, { Component } from 'react';
import {goPath} from "../../../../lib/utils";
import { Modal,Carousel } from 'antd';
import '../../style/clinic.scss';
import OrgSummaryHeaderComponent from "../../Area/component/OrgSummaryHeaderComponent";
import helper from '../../helper';
import PreviewConsentComponent from "../../ConsentForm/component/PreviewConsentComponent";
import OrgBreadCrumbs from '../../component/OrgBreadCrumbs';

const orgName = {
    fontSize: 18,
    fontWeight: 600,
    color: '#000000',
    marginRight: 10
}
export default class ClinicInfoComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            templateGroup: null,
            templateGroupName: null,
            templateIndex: null
        }
    }

    onClickSave = () => {
        this.props.onClickSave(true);
    }

    renderConsentGroup = ()=>{
        const { organization } = this.props;
        const  { templateGroups=[] } = organization;
        const onClick = (templateGroup,name)=>{
            this.setState({
                templateGroup,
                templateIndex:0,
                templateGroupName:name
            })
        }
        return  _.map(templateGroups,({name,templates},i)=>
                    <span className={'consentGroupName'} key={i} onClick={()=>onClick(templates,name)}>
                        {_.upperFirst(name)}
                    </span>
                )
    }

    renderOrgName() {
        const { organization, displayName } = this.props;
        const leaf = _.get(organization, 'leaf', false);
        const { allRoles, selectedRole } = helper.getCurrentUser();
        const currentLoginOrg = _.get(selectedRole, 'organization');
        const isSuperAdmin = _.first(_.filter(allRoles,a=>a.name=="Super Admin"));
        const adminOrgId = _.get(isSuperAdmin,'organization.id');
        const isSuperAdminOrg = adminOrgId === organization.id;
        const parent = _.get(organization, 'parent', {});
        const name = leaf ? _.get(organization, 'internalName', '') : _.get(organization, 'name', '');
        const { name: parentName, id: parentId } = parent || {};
        // Temporary solution: 
        // NO parent's view access
        // NO self's edit access
        // if landing organization is login organization
        const isSelf = currentLoginOrg.id === organization.id;
        return (
            <div>
                <span style={orgName}>{name}</span>
                { 
                  parentId && 
                  <span>
                      {
                        (!organization.leaf && !isSelf) ? 
                        <a onClick={() => goPath(`/area/${parentId}`)}>
                          {parentName}
                        </a>
                        :
                        parentName
                      }
                  </span> 
                }
                {
                  (!isSuperAdminOrg && !isSelf) &&
                    <span 
                      style={{float: 'right', marginRight: 40, textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={this.props.onClickDetail}
                    >
                      {displayName}
                    </span>
                }
            </div>
        )
    }

    renderOrgInfo() {
        const { organization } = this.props;
        const leaf = _.get(organization, 'leaf', false);
        const createdTime = moment(organization.createdAt).format('MM/DD/YY');
        let curAddress = _.get(organization, 'address', null);
        let curCity = curAddress ? curAddress.city : 'Sunnyvale';
        let type = leaf ? 'Site' : 'Area';
        const managers = _.get(organization, 'managers', []);
        let mangers = '';
        _.forEach(managers, (obj) => {
            mangers += obj.fullName + '/';
        })
        mangers = mangers.substr(0, mangers.length - 1);

        return (
             <div style={{marginTop:9, fontSize:14}}>
                <span>Area: {curCity}</span>
                <span style={{marginLeft:20}}>{type} Admin: {mangers}</span>
                <span style={{marginLeft:20}}>Created on: {createdTime}</span>
                {leaf ? <span style={{marginLeft:20}}>Consent form:{this.renderConsentGroup()} </span> : ''}
            </div>
        )
    }

    renderTemplatesCarousel = ()=>{
        const { templateGroup,templateGroupName,templateIndex } = this.state;
        if(templateGroup) {
            const title = `${templateGroupName}_${templateGroup.length ? templateGroup[templateIndex].language.description : ''}`;
            return <Modal title={title} visible={!!templateGroup} onCancel={() => this.setState({
                templateGroup: null,
                templateIndex: null,
                templateGroupName: null
            })} footer={false} className={'templatePreviewCarousel'}>
                <Carousel afterChange={(a, b, c) => this.setState({templateIndex: a})}>
                    {_.map(templateGroup, (t, i) => <div key={i}><PreviewConsentComponent template={t}/></div>)}
                </Carousel>
            </Modal>
        }else return <span></span>
    }

    render() {
        const { organization } = this.props;
        const { orgSummary, leaf } = organization;
        return (
            <div>
              <OrgBreadCrumbs organization={organization}/>
              <div className='ClinicInfo'>
                { this.renderOrgName() }
                { this.renderOrgInfo() }
                <OrgSummaryHeaderComponent 
                  orgSummary={orgSummary} 
                  className={'roleCountInHeader'} 
                  leaf={leaf}
                />
                { this.renderTemplatesCarousel() }
              </div>
            </div>
        )
    }
}