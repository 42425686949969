import React, { Component } from 'react';
import { IHLoading } from 'ihcomponent';
import { compose } from 'recompose';
import { graphql } from "react-apollo/index";
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';
import getPatientAlertConfig from 'modulesAll/smartAlert/query/getPatientAlertConfig';
import SmartAlertComponent from '../components/SmartAlertComponent';
import 'modulesAll/workStation/style/generateMTPR.scss';
import '../css/smartAlert.scss';

class SmartAlertContainer extends Component {
    static displayName = 'main/containers/SmartAlert';
    render(){
        const { patientLoading } = this.props;
        return patientLoading ? <IHLoading /> : <SmartAlertComponent {...this.props}/>
    }
}


const smartAlerts = graphql(getPatientSmartAlert, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            smartAlertsList: _.get(data, 'getPatientSmartAlert.tasks', []),
            loadingSmartAlertsList: data.loading
        }
    }
})

const patientAlertConfig = graphql(getPatientAlertConfig, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            patientAlertConfig: _.get(data, 'getPatientAlertConfig', {}),
        }
    }
})

export default compose(smartAlerts, patientAlertConfig)(SmartAlertContainer);
