import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'antd';
import ChangePwComponent from '../components/ChangePwComponent'
import MessageNotifiComponent from '../components/MessageNotifiComponent'
import setUserPassword from 'modulesAll/graphql/setUserPassword'
import {userMiniWithSetting as userSettingGQL} from 'modulesAll/graphql/user'
import actions from '../actions/ChangePw'
import { browserHistory } from 'react-router'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import get from 'lodash/get'
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { API } from '../../../chat/actions/api';
import '../css/changepw.scss';
import MyClinicComponent from '../components/MyClinicComponent';
import helper from '../../../newAdmin/helper';
import VersionContainer from '../../../auth/containers/VersionContainer';

class ChangePwContainer extends Component {
  static displayName = 'ChangePwContainer'

  constructor(props) {
    super(props);
    this.state = { 
      selectedUser: null,
      isClinicEditable: false,
      userId: '',
      userClinics: [],
      prevSelectedClinic: '',
      selectedClinic: '',
      coveredClinics: [],
      isCoveredClinicsEditable: false
    };
  }

  getDefaultRole = () => sessionStorage.getItem('defaultRole');

  setDefaultRole = defaultRole => sessionStorage.setItem('defaultRole', defaultRole);

  //session Storage the coveredClinics
  getSessionCover = () => JSON.parse(sessionStorage.getItem('coveredClinics'));

  setSessionCover = coveredClinics => sessionStorage.setItem('coveredClinics', JSON.stringify(coveredClinics));

  componentWillMount(){
    const id = this.props.params.patientId ? this.props.params.patientId : sessionStorage.getItem('currentUserId');
    
    //query to get the coveredClinics 
    API.getUserProfile(id)
      .then( res => 
        this.setState({
        coveredClinics : res.data.user.userSettings.listeningOrgs
      }))
      .catch( err => console.log('Error fetching user listening Orgs: ', err));
  }

  componentDidMount() {
    const id = this.props.params.patientId ? this.props.params.patientId : sessionStorage.getItem('currentUserId');

    API.getUserProfile(id)
      .then( res => {
        this.setState({ selectedUser: res.data.user })
      })
      .catch( err => console.log('Error fetching user profile: ', err));
      
      if(!this.props.params.patientId) { // viewer is provider
        const allRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
        const userClinics = _.map(allRoles, role => {
          const refId = _.get(role, 'refId');
          const { id, name, internalName } = _.get(role, 'organization');
          return { key: id, refId, name, internalName }
        });
        let selectedClinic = this.getDefaultRole(); ;
        if(!selectedClinic) {
          selectedClinic = _.get(userClinics, '0.refId', '');
          this.setDefaultRole(selectedClinic);
        }
        this.setState({ userId: id, userClinics, prevSelectedClinic: selectedClinic, selectedClinic});
      }
  }
  
  submit = async ({newPass}) => {
    const { location, mutate, setSubmitting, openErrorModal } = this.props
    // const id = this.props.params.patientId;
    const id = this.props.params.patientId ? this.props.params.patientId : sessionStorage.getItem('currentUserId');
    const variables = {
      newPass,
      id
    }

    try {
      setSubmitting(true);
      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'), // TODO: change to create password
        loginId: variables.identification,
        response: res
      })
      Modal.success({
        title: 'Success',
        content: 'Password changed successfully.'
      });

      const currentRole = JSON.parse(sessionStorage.currentUser);
      if (currentRole.selectedRole.name === 'Super Admin' && sessionStorage.getItem('currentUserId') !== id) {
        helper.addPrefixAndGoTo(`users/${id}`);
        //browserHistory.push(`/superadmin/providers/${id}/setrole`);
      } else if (sessionStorage.getItem('currentUserId') === id) {
        browserHistory.push('/');
      } else if (currentRole.selectedRole.name === 'Admin' && sessionStorage.getItem('currentUserId') !== id) {
        helper.addPrefixAndGoTo(`users/${id}`);
        //browserHistory.push(`/providers/profile/${id}/edit`);
      } else {
        browserHistory.push(`/patients/profile/${id}/edit`);
      }
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.resetpassword'),
        loginId: variables.identification,
        response: err,
        success: false,
      })
      Modal.error({
        title: 'Error',
        content: `An error occurred while saving your password. Please try again. ${GQLHelper.formatError(err)}`
      });
      // openErrorModal(GQLHelper.formatError(err))
    }
    finally {
      setSubmitting(false);
    }
  }

  setClinicEditable = isClinicEditable => this.setState({ isClinicEditable });

  setCoveredClinicsEditable = isCoveredClinicsEditable => this.setState({ isCoveredClinicsEditable });

  setSelectedClinic = selection => {
    this.setState(selection);
  }
  
  setCoveredClinics = selection => {
    this.setState(selection);
  }

  render() {
    const isForSettingsPage = _.get(this, 'props.router.location.pathname', '') === '/providerSettings/change-password';
    const props = {
      ...this.props,
      title: isForSettingsPage ? 'Password Setting' : '', // ChangePwComponent title
      submit: this.submit
    };
    return (
      <div id={isForSettingsPage ? 'settings-container-wrapper' : ''} >
        { isForSettingsPage && <div>
          <MyClinicComponent containerInstance={this}/>
          <MessageNotifiComponent/>
          </div>}
        <div className='settings-inner'>
          <ChangePwComponent selectedUser={this.state.selectedUser} {...props}/>
          <div className='settings-inner-version'>
            <VersionContainer/>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = ({ ResetPassword }, ownProps) => {
  return {
    ...ResetPassword
  }
}

const mapDispatch = {
  ...actions,
  openErrorModal
}

ChangePwContainer.propTypes = {
  submit: PropTypes.func,
  handleFieldsChange: PropTypes.func,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  username: PropTypes.string,
  password: PropTypes.string,
  router: PropTypes.object
}

export default compose(
  withApollo,
  graphql(setUserPassword),
  connect(mapState, mapDispatch),
)(ChangePwContainer)
