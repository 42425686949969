// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import { commonActions } from 'libModule/helpers/action-helpers'
import get from 'lodash/get'
import find from 'lodash/find'
import { CareplanHelper, getInitAlertFormData, getAlertFormData } from 'modulesAll/utils/helpers/task'
import { actionTypes as CareplanCommonTypes, actionCreators as CareplanCommonActions } from 'patientModule/Careplan/Vital/ducks/CommonDuck'
import alertFormActions from 'patientModule/AssessmentAlert/actions/AlertFormActions'
import { actionTypes as FormTypes } from './AssessmentFormDuck'
import AlertListTypes from 'patientModule/AssessmentAlert/constants/AlertListConstants'
import AlertFormTypes from 'patientModule/AssessmentAlert/constants/AlertFormConstants'
import AlertFormContainer from 'patientModule/AssessmentAlert/containers/AlertFormContainer'
// Reducer
import {commonReducer, commonInitialState} from 'libModule/helpers/reducer-helpers.js'
/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Assessment', 'AssessmentList', [
  'CHANGE_PAGE',
  'ADD_ITEM_DATA',
  'INIT_DATA',
  'ON_CHECK_TABLE_ROW',
  'ON_TABLE_CHANGE',
  'SELECT_ITEM',
  'CHANGE_TASKS',
  'RESET'
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const FORM_PAGE = CareplanCommonTypes.ASSESSMENT_FORM_PAGE;

const actionCreators = commonActions(actionTypes, [
  'onCheckTableRow',
  'onTableChange',
  'changePage',
  'reset'
]);

actionCreators.updateForm = (rowData, isEditMode)=> {
  return (dispatch, getState) => {
    dispatch({
      type: FormTypes.UPDATE_FORM,
      fieldsValue: convertToFieldsValue(rowData),
      isEditMode,
      assessment: {
        id: get(rowData, 'id'),
        nameKey: get(rowData, 'nameKey'),
        name: get(rowData, 'name')
      }
    })
  }
}

actionCreators.updateAlertForm = (rowData, tasks, isEditMode=true)=> {
  return (dispatch, getState) => {
    const currentTask = find(tasks, task => get(task, 'assessment._id') === btoa(atob(rowData.id).split(':')[1]))
    if (!currentTask) return
    const initData = CareplanHelper.convertTaskToAlertFormData(currentTask)
    dispatch(alertFormActions.updateForm(convertToFieldsValue(initData), isEditMode))
    const fakeRowData = { alertFormData: getAlertFormData(currentTask) }
    dispatch({
      type: AlertListTypes.INIT_DATA,
      initFormData: getInitAlertFormData(fakeRowData, AlertFormContainer, AlertListTypes.REMOVE_TAB, AlertFormTypes.RESET_FORM, dispatch)
    })
  }
}

actionCreators.resetForm = ()=> {
  return {
    type: FormTypes.RESET_FORM
  }
}

actionCreators.goToForm = () => {
  return CareplanCommonActions.changePage(FORM_PAGE)
}

actionCreators.selectItem = (selectedIds)=> {
  return {
    type: actionTypes.SELECT_ITEM,
    selectedIds,
  }
}
// tasks: {
//   tasks,
//   removedTasksId
// }
actionCreators.changeTasks = (tasks) => {
  return {
    type: actionTypes.CHANGE_TASKS,
    tasks,
  }
}

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initialState = Object.assign({
  data: [],
  pagination: {pageSize: 5},
  selectedIds: null, // Do not use [], [] means no selected items
  tasks: []
}, commonInitialState(actionTypes))

const ownReducer = (state, action) => {
  switch (action.type) {
    // case actionTypes.ADD_ITEM_DATA:
    //   return addDataToList(state, action.fieldsValue)
    case actionTypes.ON_TABLE_CHANGE:
      const { filter, sorter, pagination } = action
      return {
        ...state,
        filter,
        sorter,
        pagination,
      }
    case actionTypes.SELECT_ITEM:{
      const { selectedIds } = action;

      return {
        ...state,
        selectedIds
      }
    }

    case actionTypes.CHANGE_TASKS:{
      const { tasks } = action;

      return {
        ...state,
        ...tasks
      }
    }
    case actionTypes.RESET: {
      return initialState
    }

    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes)
}
