import gql from 'graphql-tag';

export default gql`
	query organizationListWithOnlyRoles($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id
				roles{
				    refId
				    name
				}
            }
		}
	}
`;