import gql from "graphql-tag";

export default gql`
  mutation labResultUpdateById($_id:MongoID!,$deleted:Boolean,$results: [LabResultsLabResultsResultsInput],$dateCollected:Date) {
  labResultUpdateById(record: {_id: $_id, deleted: $deleted,results:$results,dateCollected:$dateCollected}) {
    record {
      _id
      deleted
    }
  }
}
`
