import Client from 'libModule/gqlClient';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import moment from 'moment';
import {message} from 'antd';
import { GQLHelper } from 'libModule/utils';

const submitGoals = (localThis) =>{
  const variables = {
    id: _.get(localThis.props, 'program.id') || _.get(localThis.props, 'currentProgram.id'),
    goalsFlag: moment().format('MM-DD-YYYY')
  }
  Client.mutate({
    mutation: editEnrolledProgram,
    variables
  }).then(res =>{
    localThis.setState({showOldCareplanGoal: false});
    message.success('Care plan Updated!');
  }).catch((error)=>{
    const {openErrorModal} = localThis.props;
    if(typeof openErrorModal == 'function'){
      openErrorModal(GQLHelper.formatError(error));
    }
    console.log(error);
  })
}

const submitAssessments = (localThis) =>{
  const variables = {
    id: _.get(localThis.props, 'program.id') || _.get(localThis.props, 'currentProgram.id'),
    assessmentsFlag: moment().format('MM-DD-YYYY')
  }
  Client.mutate({
    mutation: editEnrolledProgram,
    variables
  }).then(res =>{
    localThis.setState({showOldCareplanAssessment: false});
    message.success('Care plan Updated!');
  }).catch((error)=>{
    const {openErrorModal} = localThis.props;
    if(typeof openErrorModal == 'function'){
      openErrorModal(GQLHelper.formatError(error));
    }
    console.log(error);
  })
}

export default {
  submitAssessments,
  submitGoals
}
