import gql from 'graphql-tag';

export default gql`
query smartAlertTasksList($filters:smartAlertOpts, $page:Int, $count:Int,$sort:SmartAlerttListSort){
  smartAlertTasksList(filters:$filters, count:$count, page:$page,sort:$sort){
    data{ 
        id
        firstName
        lastName
        memberId
        tasks {
                id
                createdAt
                taskType
                status
                alertLevel
                display       
                replyTime
                visitWindow
                providerNotes{ 
                    content
                }
                reason
                category
                closeAt
               }     

        }
    pageInfo {
            lastPage 
            total
    }
  }
}`;

export const smartAlertTasksPageInfo = gql`
  query smartAlertTasksPageInfo(
    $filters:smartAlertOpts,
  ) {
    smartAlertTasksList(filters:$filters) {
      pageInfo {
        total
      }
    }
  }
`;
