import gql from 'graphql-tag';
import { PatientHiatusSchema } from '../schema/common';

export const setPatientHiatusQuery = gql`
  mutation setPatientHiatus (
    $memberId: EID!
    $vitalsMonitoringHiatus: Boolean
    $visitHiatus: Boolean
    $hiatusUnableToEngage: Boolean
    $reason: String
    $note: String
    $startDate: Date!
    $endDate: Date!
    $timezone: String!
  ) {
    setPatientHiatusStatus(
      memberId: $memberId
      vitalsMonitoringHiatus: $vitalsMonitoringHiatus
      visitHiatus: $visitHiatus
      hiatusUnableToEngage: $hiatusUnableToEngage
      reason: $reason
      note: $note
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      ${PatientHiatusSchema}
    }
  }
`;

export const buildSetPatientHiatusQuery = (dataFields = []) => {
  if(_.isEmpty(dataFields))
    return setPatientHiatusQuery;

  return gql`
    mutation setPatientHiatus (
      $memberId: EID!
      $vitalsMonitoringHiatus: Boolean
      $visitHiatus: Boolean
      $reason: String
      $note: String
      $startDate: Date!
      $endDate: Date!
      $timezone: String!
    ) {
      setPatientHiatusStatus(
        memberId: $memberId
        vitalsMonitoringHiatus: $vitalsMonitoringHiatus
        visitHiatus: $visitHiatus
        reason: $reason
        note: $note
        startDate: $startDate
        endDate: $endDate
        timezone: $timezone
      ) {
        id
        ${dataFields.join('\n')}
      }
    }
  `;
};

export const resumePatientHiatusQuery = gql`
  mutation resumePatientHiatus (
    $memberId: EID!
    $reason: String
  ) {
    resumePatientHiatus(
      memberId: $memberId
      reason: $reason
    )
  }
`;