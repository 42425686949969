import {
    React
} from 'ihcomponent';
import  timeSpentType  from '../constant/constant'
class TimeSpentReadingComponent extends React.Component{
    render(){
        const { reading } = this.props;
        const key = this.props.k;
        const timeSpentObj = timeSpentType[key];
        const { defaultValue, title } = timeSpentObj;
        const monthlyTimeSpent = _.isFinite(reading) ? reading :defaultValue;
        const validMin = !isNaN(monthlyTimeSpent);
        const timeInMinutes = validMin ? Math.round(monthlyTimeSpent/60) : monthlyTimeSpent;
        const inValidMinClass = !validMin ? 'inValidMinClass' : undefined;
        let min = timeInMinutes > 1 ? 'mins' : 'min';
        return (
            <div className='timeSpent' style={{display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'}}>
                <div style={{ paddingTop:5,paddingBottom:5,display:'flex',
                    width:'fit-content',flexDirection:'column'
                }}>
                    <span style={{fontWeight:900,fontSize:16}}>{title}</span>
                    <div>
                        <span style={{fontSize:'30px',color:'#67afe2',fontWeight:'bold'}} className={inValidMinClass}>{timeInMinutes}</span>
                        <span style={{color:'#6e7980',fontSize:16}}>{min}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default TimeSpentReadingComponent;
