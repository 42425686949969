import configs from '../../../lib/config';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/article-controller/search_19
export const getArticlesPath = `${domain}/articles/search`;


// params: { searchString: string, categories: [id] | undefined, page, count }
export default (params, requestOptions) => {
  const sessionToken = sessionStorage.getItem('authToken') || '';
  const { 
    articleIds,
  } = params || {};

  const finalParams = {
    filter: {
      idIn: {
        in: articleIds,
      }
    }
  };

  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(finalParams),
    ...requestOptions
  };

  return fetch(getArticlesPath, fetchOptions).then((res) => res.json());
};