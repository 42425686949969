  import types from '../constants';

  import { commonActions } from 'libModule/helpers/action-helpers'
  import { commonReplaceReturnDevicesActions } from './CommonReplaceReturnDevicesActions'
  //for return replace devices form
  export const commonReplaceReturnDevicesFormActionsObject = {
    ...commonActions(types, [
    'resetForm',
    'updateForm',
    'onFieldsChange',
    'handleFormSaved',
    'setSubmitting'
  ])
}



  export const commonReplaceReturnDevicesActionsObject =
{
  ...commonReplaceReturnDevicesActions(types, [
    'setReplaceDevicesStep',
    'setReturnDevicesStep',
    'changeDeviceStatusTableInfo',
    'changeDeviceRemarkTableInfo',
    'resetDevicesStatusTableInfo',
    'initDevicesStatusTableInfo'
  ])
}




  export const setPatientInfo = (patientInfo) => ({
      type: types.SET_PATIENT_INFO,
      patientInfo
  });

  export const setLoading = (loading) => ({
      type: types.SET_LOADING,
      loading
  });


  export const onChangeEnrolledProgram = (enrolledProgramId)=> ({
      type: types.SET_ENROLLEDPROGRAM_ID,
      enrolledProgramId
  });

  export const setEnrolledProgromList = (enrolledProgramList)=>({
      type: types.SET_ENROLLEDPROGRAMLIST,
      enrolledProgramList
  });


  // export const updateEnrolledProgromList = (enrolledProgramId, devices)=>({
  //     type: types.UPDATE_ENROLLEDPROGRAMLIST,
  //     enrolledProgramId,
  //     devices
  // });




  export const setSearchText = (searchText) => ({
      type: types.SET_SEARCH_TEXT,
      searchText
  });

  export const setSearchStatus = (searchStatus) => ({
      type: types.SET_SEARCH_STATUS,
      searchStatus

  });
