import {
    React, IHModal
} from 'ihcomponent'

const MainModal = (props) => {
  const { closeModal, content, modalProps } = props
  const { showHeaderCloseButton, title, footer } = modalProps || {}
  const _modalProps = {
      ...modalProps,
      showModal: !!content || false,
      showHeaderCloseButton: showHeaderCloseButton || false,
      title: title || '',
      body: content || '',
      footer,
      onHide: closeModal
  } 
  
  return <IHModal {..._modalProps} />
}

MainModal.displayName = 'layout/components/MainModalComponent';

export default MainModal
