const commonActionsHelper = (types) => {
  return {
    sort: (sorter) => {
      return {
        type: types.SORT,
        sorter: { ...sorter }
      }
    },
    // antd form
    onFieldsChange: (fieldsProps, changedFields) => {
      return {
        type: types.FORM_FIELD_CHANGE,
        fieldsValue: changedFields
      }
    },
    onTableChange: (pagination, filter, sorter) => {
      return {
        type: types.ON_TABLE_CHANGE,
        sorter,
        filter,
        pagination
      }
    },
    handleFieldsChange: (fieldsProps, changedFields, types) => {
      return {
        type: types.FORM_FIELD_CHANGE,
        fieldsValue: changedFields
      }
    },
    onCheckTableRow: (selectedRowKeys) => {
      return {
        type: types.ON_CHECK_TABLE_ROW,
        selectedRowKeys
      }
    },
    changePage: (currentPage) => {
      return {
        type: types.CHANGE_PAGE,
        currentPage: currentPage
      }
    },
    setTabsStep(step){
      return {
        type : types.SET_TABS_STEP,
        step
      }
    },
    onChangeNotes: (_id, content) => {
      return {
        type: types.ON_CHANGE_NOTES,
        _id: _id,
        content: content
      }
    },
    removeSaveButton: (_id) => {
      return {
        type: types.REMOVE_SAVE_BUTTON,
        _id: _id
      }
    },
    onSearch: (text) => {
      return {
        type: types.ON_SEARCH,
        text: text
      }
    },
    onChangeInput: (label, value) => {
      return {
        type: types.ON_CHANGE_INPUT,
        label: label,
        value: value
      }
    },
    selectRow: (rowProps) => {
      const { _id } = rowProps
      return {
        type: types.SELECT_ROW,
        _id: _id
      }
    },
    loading: () => {
      return {
        type: types.LOADING
      }
    },
    updateForm: (fieldsValue, isEditMode=false) => {
      return {
        type: types.UPDATE_FORM,
        fieldsValue,
        isEditMode
      }
    },
    updateFormField: (fieldName, value) => {
      const formData = { [fieldName]: value };
      return {
        type: types.UPDATE_FORM,
        formData
      }
    },
    validateForm: () => {
      return {
        type: types.VALIDATE_FORM
      }
    },
    handleFormSaved: ()=> {
      return {
        type : types.FORM_SAVED
      }
    },
    resetForm: () => {
      return {
        type: types.RESET_FORM
      }
    },
    reset: () => {
      return {
        type: types.RESET
      }
    },
    setSubmitting: (isSubmitting) => {
      return {
        type: types.SET_SUBMITTING,
        isSubmitting
      }
    },
  }
}

export const commonActions = (types, actionNames) => {
  const common = commonActionsHelper(types)
  const actionObjects = {}
  actionNames.map( (a) => {
    actionObjects[a] = common[a]
  })
  return actionObjects
}

 // antd form
export const handleFormFieldsChange = (fieldsProps, changedFields, types) => {
  //console.log(changedFields)
  return (dispatch, getState) => {
    dispatch({
      type: types.FORM_FIELD_CHANGE,
      fieldsValue: changedFields
    })
  }
}
