import gql from "graphql-tag";
import { SignOffStatusSchema } from "./schema/SignOffStatus";

export  default gql`
  query getVersionHistoryList($enrolledProgramId: EID!) {
	getVersionHistoryList(enrolledProgramId: $enrolledProgramId) {
    id
    careplanData
    visit {
      id
      type
      checkinAt
      appointmentAt
      ${SignOffStatusSchema}
    }
    modifiedHistory {
      user {
        profile {
          fullName
        }
      }
      itemsUpdated
      updateType
      date
    }
  }
}
`;
