export const EVENT_TYPES = {
  PATIENT_PROFILE: 'PATIENT_PROFILE',
  FOOD_LOG: 'FOOD_LOG',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  CALL_PATIENT: 'CALL_PATIENT',
  ALERT: 'ALERT',
  CONVERSATION: 'CONVERSATION',
  UPDATE_PATIENT_INFO: 'UPDATE_PATIENT_INFO',
  MEDICINE: 'MEDICINE',
  PROVIDER_NOTES: 'PROVIDER_NOTES',
  CARE_PLAN: 'CARE_PLAN',
  LAB_RESULTS: 'LAB_RESULTS',
  CREATE_VISIT: 'CREATE_VISIT',
  EDIT_VISIT: 'EDIT_VISIT',
  DELETE_VISIT: 'DELETE_VISIT',
  MTPR_MRE: 'MTPR_MRE',
  CLIPBOARD: 'CLIPBOARD',
  MONTHLY_REVIEW: 'MONTHLY_REVIEW',
  SEND_ARTICLE: 'SEND_ARTICLE',
}

export const EVENTS = {
  PATIENT_PROFILE: { text: 'Review patient\'s profile' },
  FOOD_LOG: { text: 'Review food log' },
  TASK_ASSIGNMENT: { text: 'Task assignment' },
  CALL_PATIENT: { text: 'Call patient' }, 
  ALERT: { text: 'Review alerts' },
  CONVERSATION: { text: 'Chat with patient' },
  SEND_ARTICLE: { text: 'Send article to patient\'s app' },
  UPDATE_PATIENT_INFO: { text: 'Update patient info' },
  MEDICINE: { text: 'Update medication' },
  PROVIDER_NOTES: { text: 'Provider notes' },
  CARE_PLAN: { text: 'Update care plan' },
  LAB_RESULTS: { text: 'Update lab results' },
  CREATE_VISIT: { text: 'Create visit' },
  EDIT_VISIT: { text: 'Edit visit' },
  DELETE_VISIT: { text: 'Delete visit' },
  MTPR_MRE: { text: 'Review MTPR/MRE' },
  CLIPBOARD: { text: 'Clipboard' },
  MONTHLY_REVIEW: { text: 'Monthly review completed' },
};
