import React from 'react';
import { Carousel,Modal,Card,Tooltip,Icon,Button } from 'antd';
import '../css/index.scss';
import API from '../API/index';
const { next,pre } = Carousel;
const SlidesIconComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
        this.state = {
            showModal:props.showModal,
            current:0
        }

    }

    next() {
        this.carousel.next();
    }
    previous() {
        this.carousel.prev();
    }

    beforeChange = (from,to)=>{
        this.setState({
            current:to
        })
    }
    renderCascader = ()=>{
        const { images } = this.props;
        return <div className={'slidesWrapper'}>
                    <Carousel className={'slides'} beforeChange={(from,to)=>this.beforeChange(from,to)} ref={node => (this.carousel = node)}>
                        {_.map(images,src=><Card bordered={false}><img src={src.link} style={{width: 878, height: 446}}/></Card>)}
                    </Carousel>
        </div>
    }

    setShowModal = ()=>{
        const { showModal,current } = this.state;
        const { shouldEditLastReadRelease,latestRelease } = this.props;
        this.setState({
            current: showModal ? 0 : current,
            showModal: !showModal,
        },()=>{
            const currentUserId = sessionStorage.getItem('currentUserId');
            const editLastReadRelease = showModal && shouldEditLastReadRelease;
            editLastReadRelease&&API.setLastReadRelease({id:currentUserId,lastReleaseRead:latestRelease });
        })
    }

    renderModal = ()=>{
        const { showModal } = this.state;
        return <Modal visible={showModal} className={'slidesModal'} onCancel={ this.setShowModal } width={960} style={{ height: 580 }} footer={this.renderFooter()}>{this.renderCascader()}</Modal>
    }

    onFinish = ()=>{
        const { setShowModal } = this;
        setShowModal();

    }

    renderFooter = ()=>{
        const { current } = this.state;
        const { images } = this.props;
        const { onFinish } = this;
        const lastIndex = images.length-1;
        const isFirst = current == 0;
        const isLast = current==lastIndex;
        return <div style={{ display:'flex',flexDirection:'column',paddingRight:20 }} className={'buttonWrapper'}>
                <div style={{ marginBottom: 15 }}>
                    { isFirst ? '': <Button onClick={ this.previous } style={{ float:'left' }}>Previous</Button> }
                    { isLast ? <Button type={'primary'} style={{ marginLeft:'auto' }} onClick={onFinish}>Finish</Button> : <Button onClick={ this.next } style={{ marginLeft:'auto' }} type={'primary'}>{ isFirst ? `Let's go!` :'Next' } </Button> }
                </div>
                <div>
                    <a href={'https://forms.gle/3QBkkwdQjno6vSAN7'} target="_blank" style={{ fontSize:16,textDecoration:'underline',marginTop:15 }}>We need your feedback! Start survey here.</a>
                </div>
               </div>
    }


    render() {
        return <React.Fragment>
               <Tooltip title={'Help and resources'} overlayStyle={{ width:200,whiteSpace:'nowrap' }}>
                    <div style={{ fontSize: 20 }}><Icon className="slideIcon" type="question-circle" theme="filled" onClick={this.setShowModal} /></div>
               </Tooltip>
                { this.renderModal() }
              </React.Fragment>
    }
}
export default SlidesIconComponent
