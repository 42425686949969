import {notification} from "antd";

export let notificationkey = 'patientListDownload';
export let successModal = ()=>notification.open({
	message: '',
	duration:0,
	key:notificationkey,
	description:'Generating patient list,please wait',
});

export let failModal = ()=>notification['error']({
	message: 'Error',
	key:notificationkey,
	description:'Oops. Something went wrong while generating the patient list. Please try again.',
})