// provider
// 9/11/17 - JV - AlertBadge deprecated per UX direction (Ted)
// import AlertBadge from 'modulesAll/alerts/Badge/containers/AlertBadgeContainer'
import I18N from 'modulesAll/I18N';
import * as R from 'ramda';

export const providerMenuItems = {
  remoteEnrollment: {
    id:'remoteEnrollment',
    title:'Remote Enrollment',
    src:'/image/remote_enrollment.png',
    href:'/remoteEnrollment',
    class:'remoteEnrollment'
  },
  dashboard: {
    id: 'dashboard',
    title: 'Visits',
    src: '/image/visits.png',
    // icon: 'dashboard',
    icon: 'bar-chart',
    href: '/dashboard',
    class: 'dashboard'
  },
  Appointments: {
    id: 'appointments',
    title: 'Calendar',
    src: 'image/appointments.svg',
    href: '/appointments',
    class: 'appointments'
  },
  Inventory: {
    id: 'inventory',
    title: 'Inventory',
    src: 'image/inventory.png',
    href: '/inventory',
    class: 'inventory'
  },
  MyTasks_Provider: {
    id: 'tasks',
    title: 'My Tasks',
    // icon: 'mytasks',
    icon: 'tasks',
    href: '/tasks',
    class: 'tasks'
  },
  SmartAlerts: {
    id: 'smartalert',
    title: 'Clinical Alerts',
    href: '/smartalert',
    src: '/image/nav_cdelist.png',
    class: 'smartAlert'
  },
  Chat: {
    id: 'messages',
    title: 'Messages',
    href: '/messages',
    src: '/image/chat-icon.svg',
    class: 'messages'
  },
  Food_Log: {
    id: 'foodlog',
    title: 'Food Diary',
    src: 'image/food-diary-icon.svg',
    href: '/foodlog',
    class: 'foodlog'
  },
  TempEnrolledPatients: {
    id: 'tempenrolledpatients',
    title: 'Compliance Alerts',
    href: '/tempenrolledpatients',
    src: '/image/nav_malist.png',
    class: 'tempenrolledpatients'
  },
  Patients: {
    id: 'patients',
    title: 'Patients',
    // icon: 'patients',
    // icon: 'person',
    src: '/image/patients-nav-icon.svg',
    href: '/patients',
    class: 'patients'
  },
  TaggedPatients_Provider: {
    id: 'tagged_patients',
    title: 'Tagged Patients',
    //icon: 'taggedpatients',
    // icon: 'star',
    src: 'image/taggedpat-nav-icon.svg',
    href: '/tagged_patients',
    class: 'tagged_patients'
  },
  InactivePatients: {
    id: 'inactive_patients',
    title: 'Inactive Patients',
    href: '/inactive_patients',
    src: '/image/inactivepat-nav-icon.svg',
    class: 'inactive_patients'
    // src: '/image/inactive-patient-icon2.svg'
  },
  NurseAssessment: {
    id: 'nurse_assessments',
    title: I18N.get('keywords.Assessments'),
    // icon: 'assessments',
    icon: 'assessment',
    href: '/nurse_assessments',
    class: 'nurse_assessments'
  },
  NurseSurvey: {
    id: 'nurse_surveys',
    title: 'Surveys',
    // icon: 'surveys',
    icon: 'survey',
    href: '/nurse_surveys',
    class: 'nurse_surveys'
  },
  Programmes: {
    id: 'programmes',
    title: `${I18N.get('keywords.Programs')}`,
    src: '/image/programs-nav-icon.svg',
    // icon: <Icon type="solution" style={R.merge(iconStyle, { marginRight: 3 })} />,
    href: '/programmes',
    class: 'programmes',
  },
  Alerts: {
    id: 'provideralert',
    title: 'Alerts',
    // icon: 'alerts',
    icon: 'bell',
    // href: '/alerts',
    href:'/provideralert',
    class: 'alerts'
    // Badge: <AlertBadge />
  },
  TaskAssignments: {
    id: 'taskAssignments',
    title: 'Task List',
    href: '/taskAssignments',
    src: 'image/taggedpat-nav-icon.svg'
  },
  DataInsights: {
    id: 'dataInsights',
    title: 'Data Insights',
    href: '/dataInsights',
    icon: 'bar-chart',
    class: 'dataInsights',
    orgPermissionKey: 'analyticsAPI',
    globalConfigKey: 'DATA_ANALYTICS_ENABLED',
  },
  Reports: {
    id: 'reports',
    title: 'Reports',
    href: '/reports',
    src: '/image/reports.svg',
    class: 'reports',
},
  WorkStation : {
    id: 'workstation',
    title: 'Workstation',
    href: '/workstation',
    src: '/image/workstation.svg',
    class: 'workstation-nav'
  },
  CrossClinic: {
    id:'CrossClinic',
    title:'Cross Clinic',
    href:'/crossClinic',
    src: '/image/crossClinic.png',
    class: 'crossClinic-nav'

  },
  RPMPatientList: {
    id: 'RPMPatientList',
    title: 'RPM Patient List',
    href: '/rpmPatientList',
    src: 'image/inventory.png',
    class: 'rpmPatientList-nav'
  }
  // Settings: {
  //   id: 'providerSettings',
  //   title: 'Settings',
  //   src: '/image/settings-icon.png',
  //   href: `/providerSettings/change-password`
  // }
};

const nurseArray = [
  'remoteEnrollment',
  'dashboard',
  'Appointments',
  'WorkStation',
  'TaskAssignments',
  // 'SmartAlerts',
  'Chat',
  // 'Food_Log',
  'Patients',
  'InactivePatients',
  'Alerts',
  // 'TempEnrolledPatients',
  'Inventory',
  'DataInsights',
  'CrossClinic'
  // 'Settings'
];

const maArray = [
  'remoteEnrollment',
  'dashboard',
  'Appointments',
  'WorkStation',
  'TaskAssignments',
  // 'TempEnrolledPatients',
  'Chat',
  // 'Food_Log',
  'Patients',
  'InactivePatients',
  'Alerts',
  // 'SmartAlerts',
  'Inventory',
  'DataInsights',
  'Reports',
  'CrossClinic'

  // 'Settings'
];

const hcArray = [
  'remoteEnrollment',
  'dashboard',
  'Appointments',
  'WorkStation',
  'TaskAssignments',
  // 'SmartAlerts',
  'Chat',
  // 'Food_Log',
  'Patients',
  'InactivePatients',
  'Alerts',
  // 'TempEnrolledPatients',
  'Inventory',
  'DataInsights',
  'CrossClinic'

  // 'Settings'
];

const nurseMenusArray = R.map(R.flip(R.prop)(providerMenuItems), nurseArray);
const maMenusArray = R.map(R.flip(R.prop)(providerMenuItems), maArray);
const hcMenusArray = R.map(R.flip(R.prop)(providerMenuItems), hcArray);

const providerMenu = {
  nurse: nurseMenusArray,
  medicalAssistant: maMenusArray,
  healthCoach: hcMenusArray
};

export default providerMenu;
export const HOMEPAGE = '/dashboard';
