const SignOffStatusSchema = `
  signOffStatus {
    signOff
    signedOffBy {
      id
      profile {
        fullName
      }
    }
    signedOffTime
    timeSpent
  }
`;

export {
  SignOffStatusSchema
};