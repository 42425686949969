import {
    React, IHSearchTable, _, IHButton, moment
} from 'ihcomponent'
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'
import I18N from 'modulesAll/I18N';
import UserClass from 'modulesAll/graphql/class/User';

const Component = class extends React.Component {
    static displayName = '/superadmin/provider/list'

    componentWillUnmount() {
        if (!new RegExp(/^(\/provider)/).test(Store.getState().routing.location.pathname)) {
            this.props.onLoadParams(null)
        }
    }

    render() {
        const p = this.getTableProps()
        return <IHSearchTable {...p} />
    }

    renderRoles(roles) {
        return (<div>
            {roles.map((role) => {
                return (<p><b>Organization:</b>{role.organization.name}
                    <b>Role:</b>{role.name}</p>)
            })}
        </div>);
    }

    getTableProps() {
        const { userData, list, loading, search, onTableSearch, onSearchEnter, sort = {}, onTableChange, page, filter } = this.props;
        const dataSource = _.get(list, 'data');
        const filters = _.get(list, 'filters', []);
        return {
            title: `Providers (${_.get(list, 'pageInfo.total', 0)})`,
            dataSource: _.get(list, 'data', []).map(v => new UserClass(v)),
            rowKey: (doc) => {
                return doc._get('id');
            },
            onChange: onTableChange,
            pagination: {
                current: _.get(page, 'current', 1),
                pageSize: _.get(page, 'pageSize', 10),
                total: _.get(list, 'pageInfo.total', 0),
            },
            showSearchInput: true,
            searchValue: search,
            onSearch: onTableSearch,
            onSearchEnter,
            inputPlaceholder: 'Search by Name',
            loading,
            columns: [
                {
                    key: 'fullName',
                    title: 'Name',
                    dataIndex: 'fullName',
                    render: (t, doc) => doc._get('profile.fullName')
                }, {
                    key: 'username',
                    title: 'Login ID',
                    dataIndex: 'username',
                    render: (id, doc) => doc._get('username')
                },
                {
                    title: 'Roles',
                    key: 'role',
                    render: (t, doc) => {
                        let roles = doc._get('allRoles');
                        return <div>
                            {roles.map((role, index) => {
                                return (<p key={index}><label>Organization:</label>{role.organization.name}
                                    &nbsp;&nbsp;<label>Role:</label>{role.name}</p>)
                            })}
                        </div>;
                    }
                },
                {
                    title: 'Actions',
                    key: 'Actions',
                    render: (t, doc) => {
                        const bp = {
                            size: 'exsmall',
                            bsStyle: 'primary',
                            onClick: () => {
                                browserHistory.push(`/superadmin/providers/${doc._get('id')}/setrole`)
                            },
                            className: 'table-action-button'
                        }
                        const label = 'Edit'
                        return (
                            <IHButton {...bp}>
                                {label}
                                <i className="fa fa-angle-right ih-right"></i>
                            </IHButton>
                        )
                    }
                }
            ]
        }
    }
}

Component.propTypes = {
    loading: PropTypes.bool,
    refetch: PropTypes.func,
    list: PropTypes.object,
    onSearchEnter: PropTypes.func,
    onTableSearch: PropTypes.func,
    search: PropTypes.string,
}

export default Component
