import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { IHLoading } from 'ihcomponent';

import { getRPMPatientList } from '../apis/getPatientList';
import ReviewStartButtonContainer from './ReviewStartButtonContainer';
import ReviewPatientTableComponent from '../components/ReviewPatientTableComponent';
import createTableActions from '../../../lib/table/TableActions';
import {
  REDUX_STATE_NAME,
  commonRPMPatientListFilters,
  commonTODORPMPatientListFilters,
  DEFAULT_PATIENT_LIST_SORT
} from '../constants';

import '../styles/ReviewPatientTableContainer.scss';

const ReviewPatientTableContainer = (props) => {
  const {
    filters = {},
    page,
    onTableChange,
    loadingBoostedPatientList,
    boostedPatientList,
    boostedPatientListCount,
    refetchBoostedPatientList,
    onRefetch,
  } = props;

  const data = useMemo(() => {
    if (!loadingBoostedPatientList && boostedPatientList) {
      return _.map(boostedPatientList, 'user');
    }
    return [];
  }, [loadingBoostedPatientList, boostedPatientList]);

  const handleOnRefetch = () => {
    refetchBoostedPatientList();
    if (onRefetch) onRefetch();
  };

  return (
    <div className="review-patient-table-container">
      <Row>
        <Col>
          <ReviewStartButtonContainer
            filters={{
              ...commonTODORPMPatientListFilters,
              ...filters
            }}
            disabled={loadingBoostedPatientList || data.length === 0}
            onRefetch={handleOnRefetch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReviewPatientTableComponent
            tableData={data}
            isLoadingTableData={loadingBoostedPatientList}
            filteredValues={filters}
            pagination={{
              ...page,
              total: boostedPatientListCount,
            }}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </div>
  );
};

ReviewPatientTableContainer.propTypes = {
  customFilters: PropTypes.object,
};

const mapStateToProps = (state) => {
  const globalTables = state.globalTables[REDUX_STATE_NAME];
  return {
    isGlobalTablesReady: !_.isEmpty(globalTables),
    ...globalTables || {},
  };
};

const mapDispatchToProps = () => ({
  ...createTableActions(REDUX_STATE_NAME, {
    shouldUpdateHistory: true,
    sortFieldMap: {
      fullName: 'LAST_NAME',
    },
    initState: {
      filters: commonRPMPatientListFilters,
      sort: DEFAULT_PATIENT_LIST_SORT,
    },
  }),
});

const EnhancedC = compose(
  getRPMPatientList,
)(ReviewPatientTableContainer);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)((props) => {
  const {
    onLoadParams,
    isGlobalTablesReady,
    filter, // from state.globalTables
    filters,
    ...restProps
  } = props;

  useEffect(() => {
    onLoadParams(filter, true);
  }, []);

  if (!isGlobalTablesReady) {
    return <IHLoading />;
  }

  return (
    <EnhancedC
      {...restProps}
      // correct name of filter => filters
      filters={{ ...filter, ...filters }}
    />
  );
});