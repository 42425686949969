export class CellularDevicesHelpers {
  static BPCellularCheckboxFieldName = 'bpCellularDevice';

  static BGCellularCheckboxFieldName = 'bgCellularDevice';

  static deviceBrandFieldName = 'brand';

  static deviceIdFieldName = 'deviceId';

  static deviceLanguageFieldName = 'language';

  static AVAILABLE_BRAND_ENUM = {
    PYLO: 'PYLO',
    BIOLAND: 'BIOLAND'
  };

  static DEVICE_TYPE_ENUM = {
    BP: 'BP',
    BG: 'BG',
  };

  static BRAND_TO_DEVICE_TYPE_MAP = {
    [CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.PYLO]: CellularDevicesHelpers.DEVICE_TYPE_ENUM.BP,
    [CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.BIOLAND]: CellularDevicesHelpers.DEVICE_TYPE_ENUM.BG,
  };

  static makeCellularDeviceName = (
    deviceType,
    brand,
  ) => `${_.toUpper(deviceType)} Cellular Device ${_.capitalize(brand)}`;
}
