export default  (type)=> {
  switch (type){
    case 'INIT':
      return {
        // DESC: true -> false
        sort: {direction: 'DESC', field:'DOCTOR_REFERRAL', fieldGraph:'DOCTOR_REFERRAL' }
      };
    case 'TBD':
      return {
        // DESC: true -> false
        sort: {direction: 'DESC', field:'DOCTOR_REFERRAL', fieldGraph:'DOCTOR_REFERRAL' }
      };
    case 'COMPLETED':
      return {
        sort:{
            direction:'DESC',field:'PINNED_TO_EMR',fieldGraph:'PINNED_TO_EMR'
        }
      };
    default:
      return {
        sort:{ direction:'DESC',field: 'NEXT_VISIT_TIME'}
      };
  }  
}