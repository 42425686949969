import gql from "graphql-tag";
import UserDeviceRecords from '../schema/UserDeviceRecords';
import { Identification } from '../schema/common';

export default gql `
mutation editVisit ($id:EID!,$type:VisitTypeEnum,$appointmentAt:Date,$appointmentTo:Date,$providers:[EID],$doctorNotes:String
                    $reason:String,$programIds:[EID],$checkinAt:Date,$checkoutAt:Date,$deviceRecordCount:InputUserDeviceRecordCount,
                    $inventoryCount: InputUserDeviceRecordCount,
                    $height:HTValue,$weight:HSValue,$waistHipRatio:Float,$bgMeasurement:VisitBgMeasureInput,$bpMeasurement:VisitBpMeasureInput
                    $eyeExam: VisitTaskStatus,$doctorSession: VisitTaskStatus,$cdeSession: VisitTaskStatus,$technicalSetup: VisitTaskStatus
                    $footExam: VisitTaskStatus,
                    $urineTest: VisitTaskStatus
                    $mpTest:VisitTaskStatus,
                    $a1cTest:Float,
                    $spo2:POValue,
                    $temperature:TMValue,
                    $shouldSeeDoctor:Boolean,
                    $visitRoles:[String],
                    $addToContactsStatus: VisitTaskStatus,
                    $autoUpdateStatus: VisitTaskStatus,
                    $appIsUptoDate: VisitTaskStatus,
                    $EMRAttached: Boolean,
                    $userDeviceRecords: InputUserDeviceRecords
                    $conditionsList:InputConditionsListType,
                    $userLanguage:Language,
                    $category:VisitCategoryEnum,
                    $userAppLanguage: Language,
                    $userTechLevel: TechLevel,
                    $A1C:InputA1CObject,
                    $needInHouseA1C: Boolean
                    $associatedVisitId:EID
                    ){
    editVisit(id:$id,type:$type,appointmentAt:$appointmentAt,appointmentTo:$appointmentTo,providerIds:$providers,reason:$reason,associatedVisitId:$associatedVisitId,
              programIds:$programIds,checkinAt:$checkinAt,checkoutAt:$checkoutAt,shouldSeeDoctor:$shouldSeeDoctor,visitRoles:$visitRoles,deviceRecordCount:$deviceRecordCount,inventoryCount:$inventoryCount,conditionsList:$conditionsList,doctorNotes:$doctorNotes,category:$category,details:{addToContactsStatus:$addToContactsStatus, EMRAttached: $EMRAttached,
                autoUpdateStatus:$autoUpdateStatus,spo2:$spo2,temperature:$temperature,
                appIsUptoDate:$appIsUptoDate,
                height:$height,weight:$weight,waistHipRatio:$waistHipRatio,bgMeasurement:$bgMeasurement,bpMeasurement:$bpMeasurement,
              eyeExam:$eyeExam,footExam:$footExam,mpTest:$mpTest,urineTest:$urineTest,doctorSession:$doctorSession,cdeSession:$cdeSession,technicalSetup:$technicalSetup,
              a1cTest:$a1cTest
              },userDeviceRecords:$userDeviceRecords,userLanguage:$userLanguage, userAppLanguage:$userAppLanguage, userTechLevel:$userTechLevel,A1C:$A1C, needInHouseA1C: $needInHouseA1C){
                id
                createdAt
                deviceRecordCount{
                  ${UserDeviceRecords}
                }
                inventoryCount {
                  ${UserDeviceRecords}
                }
                enrolledProgramStatus
                member{
                  id
                  identification{
                    ${Identification}
                  }
                 
                  profile{
                      fullName    
                      
                      ...on MemberProfile{
                          lastMeasuredAt
                          birthday
                          race {
                            code
                            description
                          }
                          language {
                            code
                            description
                          }
                          nationality {
                            code
                            description
                          }
                          deviceRecords{
                            ${UserDeviceRecords}
                          }
                          height
                          weight
                          gender
                          appLanguage{
                              code
                              description
                          }
                          language{
                              code
                              description
                          }
                          latestWeight
                          healthConditions
                          billableHealthConditions{
                              description
                              code
                          }
                          insuranceProvider
                          motivation
                          techLevel
                          programCategories{
                              name
                              enrolled
                              enrolledDate
                          }
                          emrList{
                              EMR_Charting
                              ChartA1C
                              CCM
                          }
                      }
                  }
                  
                  allRoles{
                      refId
                      category
                  }
                }
                enrolledProgram{
                  id
                  assessments
                  goals
                  tasks{
                      id
                      type
                  }
                }
                vitalTypes
                type
                reason
                appointmentAt
                appointmentTo
                checkinAt
                checkoutAt
                providers{
                  id
                  profile{
                    fullName
                  }
                
                }
                rdWorkingList{
                    Assessment
                    Charting
                    GoalStatement
                    MntReferral
                }
                maWorkingList{
                    onBoard
                    LabResults
                    VisitBilling
                    Medication
                    FollowUpVisit
                }
                conditionsList{
                  HLD
                  HTN
                  DM
                  CKD
                  Obesity
                  PreDM
                  COPD
                  ESRD_Dialysis
                }
                associatedVisit{
                  id
                  type
                  visitRoles
                  providers{
                      id
                      profile{
                        fullName
                      }
                  }
                  appointmentAt
                  reason
                  category
                  labResult{
                    id
                    results{
                        value
                        id
                    }
                    dateCollected
                  }
                }
                tasks{
                  key
                  value
                }  
                labResult{
                  createdAt
                  id
                  results{
                      value
                      id
                  }
                  dateCollected
                }
                shouldSeeDoctor
                visitRoles
                details{
                  doctorSession
                  cdeSession
                  autoUpdateStatus
                  addToContactsStatus
                  technicalSetup
                  eyeExam
                  height
                  weight
                  waistHipRatio
                  bgMeasurement{
                    blood_glucose
                      beforeMeal
                    mealType
                  }
                  EMRAttached
                  bpMeasurement{
                    systolic_blood_pressure
                    diastolic_blood_pressure
                    heart_rate
                  }
                  temperature
                  spo2
                  appIsUptoDate
              }
              labTests{
                  id
                  createdAt
                  updatedAt
                  dateCollected
                  templateId
                  templateName
                  results{
                      name
                      value
                      unit
                      isInHouse
                  }
              }    
           }
}`

