import React, { useEffect } from 'react';

export default ({ show, message, subMessage, imageSrc, onDone, timerAmount,showChat }) => {
  let timer;

  useEffect(() => {
    if (show) {
      timer = setTimeout(() => {
        if (onDone) {
          onDone();
        }
      }, timerAmount || 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    }
  }, [show]);

  return show ? (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', minHeight: 500 }}>
            <React.Fragment>
              { !showChat && <div><img src={imageSrc || '/image/icon_check.png'} width={50}/></div> }
              <div style={{marginTop: 10, padding: 20, textAlign: 'center'}}>
              { !showChat && <div style={{fontWeight: 'bold', fontSize: 17}}>{message}</div> }
              {subMessage && <div style={{fontSize: 14, marginTop: 10}}>{subMessage}</div>}
              </div>
            </React.Fragment>
    </div>
  ) : null;
}
