import React, { useState } from 'react';
import {Modal, Form, Icon, Tooltip, message} from 'antd';
import AssigneeFormContainer from '../container/AssigneeFormContainer';
import I18N from '../../I18N';
import { updatePatientAssigneeMutation } from '../API/index';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import '../style/index.scss';
import '../style/confirmationPopup.styles.scss';
import { isNotShow } from '../helper'

const defaultEmptyValue = 'Not Assigned';
const NOT_NECESSARY = "not-necessary"
const DISPLAY_NOT_NECESSARY_VALUE = "Not Necessary";

const AssignCTInVisitWorkList = (props) => {
  const { member, enrolledProgramStatus, patientComplexity, onSubmitSuccess } = props;
  const [showForm, setShowForm] = useState(false);

  const isEnrolled = enrolledProgramStatus === 'STARTED';
	const assignedToRD = _.get(member,'assignees.assignedToRD');
  const assignedToCA = _.get(member,'assignees.assignedToCA');
  const assignedToRDNotNecessary = _.get(member, 'assignees.assignedToRDNotNecessary');
  const assignedToCANotNecessary = _.get(member, 'assignees.assignedToCANotNecessary');
	const assignedToRDFullName = assignedToRDNotNecessary ? DISPLAY_NOT_NECESSARY_VALUE : _.get(assignedToRD,'profile.fullName', defaultEmptyValue);
	const assignedToCAFullName = assignedToCANotNecessary ? DISPLAY_NOT_NECESSARY_VALUE :_.get(assignedToCA,'profile.fullName', defaultEmptyValue);
  const complexity = _.get(patientComplexity, 'level');
	const isCROrRDOrHC = checkIsInRole(['RD','CA','HC']);

  const assignedStatus = ()=>{
		return (
      <div className={'msgWrapper'} style={{ display: 'inherit', marginLeft: 10 }}>
        <div style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => setShowForm(true)}>
          Edit
        </div>
      </div>
    );
  };

  const assignees = ()=>{
		let count = [assignedToRD,assignedToCA].filter(r=>!r).length;
    const shouldShowWarning = count >= 1 && isCROrRDOrHC;
    const notShowIcon = isNotShow(assignedToRDNotNecessary, assignedToCANotNecessary, assignedToRD, assignedToCA);
		return(<div style={{ display: 'inline-block' }}>
      <span style={{ fontWeight: 700, marginRight: 5 }}>
        Assigned CT members:
      </span> 
      RD/HC: {assignedToRDFullName}; 
      CA: { assignedToCAFullName}
      {assignedStatus()} 
      <div style={{ display: 'inherit' }}>
        {
          (shouldShowWarning && !notShowIcon) && 
          <Tooltip
            title={I18N.get('ctAssignees.missingMsg.WORKLIST.text')}
            overlayClassName='missingAssigneeTipInWorkList'
            destroyTooltipOnHide
          >
            <img src='/image/red_info_icon.png' style={{ marginLeft: 5 }}/>
          </Tooltip>
        }
      </div>
    </div>);
	};

  const onSubmit = (values) => {
    const hasEmptyValue = _.filter(values, v=>!v).length > 0;
    if(hasEmptyValue && isCROrRDOrHC){
      showConfirmForSubmit({
        onOk: () => handleSubmit(values)
      });
    } else {
      handleSubmit(values);
    }
  }

  const showConfirmForSubmit = ({ onOk }) => {
    const onCancel = () => {
    }
    Modal.confirm({
        content: <div>
           You've only selected <span >one</span> field for assignee, but <span>two</span> is recommended. Are you sure you want to submit?
        </div>,
        zIndex:3500,
        icon: <Icon type="info-circle" theme="filled" style={{ color: '#DA6453' }}/>,
        className:"assignee-confirmation-modal",
        onOk: onOk,
        onCancel:onCancel
    });
  }

  const updateAssigneeVariables = (values) => {
    const {assignedToCA, assignedToRD} = values;
    let variables = _.clone(values);
    if(assignedToCA == NOT_NECESSARY){
      variables['assignedToCANotNecessary'] = true;
      variables.assignedToCA = null;
    }

    if(assignedToRD == NOT_NECESSARY){
      variables['assignedToRDNotNecessary'] = true;
      variables.assignedToRD = null;
    }

    return variables;
  }

  const handleSubmit = async (values)=>{
    const memberId = _.get(member , 'id');
    const variables = updateAssigneeVariables(values);
		const res = await updatePatientAssigneeMutation({ memberId, ...variables});
		const data = _.get(res,'data.updatePatientAssignee');
		message.success('Care team members have been assigned to this patient');
    setShowForm(false);
    if(onSubmitSuccess) {
      onSubmitSuccess(data);
    }
	}

  const renderFormModal = () =>{
		return(
      <Modal 
        visible={showForm} 
        onCancel={()=>setShowForm(false)} 
        footer={null} 
        className={'assignCTForm'}
        destroyOnClose
      >
        <AssigneeFormContainer 
          patient={member}
          isEnrolled={isEnrolled}
          complexityLevel={complexity}
          onSubmit={onSubmit}
        />
      </Modal>
    );
	};

  return(
    <div>
      {assignees()}
      {renderFormModal()}
    </div>
  );
};

export default Form.create({})(AssignCTInVisitWorkList);