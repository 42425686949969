import { React, IHLoading } from 'ihcomponent';
import { Menu, Dropdown } from 'antd';
import { Icon, Row, Col } from 'antd';
import * as R from 'ramda';
import './labresults.css';
import { compose, withState, withProps, withPropsOnChange, branch, renderComponent } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { trace as traceF } from './utils';
import { formatDateMM } from '../../../utils/helpers/task';
import EditableTable from '../containers/EditLabResult';
import { routerWillLeaveHelper } from '../../enrolledProgramInfo/helper/helpers';
import NoDataComponent from '../components/NoDataComponent';

const debug = false;
const trace = debug ? traceF : () => R.identity;

// let defaultColumns = [
//   {
//     title: 'Tests',
//     dataIndex: 'name'
//     // width: '30%',
//   },
//   {
//     title: 'Result',
//     dataIndex: 'value'
//     // width: '10%',
//   },
//   {
//     title: 'Units',
//     dataIndex: 'unit',
//     // width: '20%',
//     render: value => Parser(value)
//   },
//   {
//     title: 'Flag',
//     dataIndex: 'flag'
//     // width: '10%',
//   },
//   {
//     title: 'Reference Interval',
//     dataIndex: 'interval',
//     width: '30%'
//   }
// ];

// const columnMap = {
//   eye_exam: [
//     { title: 'Tests', dataIndex: 'name', width: '20%' },
//     { title: 'Result', dataIndex: 'value' }
//   ],
//   '﻿foot_exam': [
//     { title: 'Tests', dataIndex: 'name', width: '20%' },
//     { title: 'Result', dataIndex: 'value' }
//   ]
// };

// class TestPanelFormComponent extends React.Component {
//   handleClick(_id, userId) {
//     const { deleteLabRes, openErrorModal } = this.props;

//     return new Promise(resolve => {
//       Modal.confirm({
//         title: 'Are you sure you want to delete this Lab Result ?',
//         onOk: () => {
//           deleteLabRes(_id, userId)
//             .then(_ => message.success('Lab Result deleted'))
//             .catch(e => openErrorModal(GQLHelper.formatError(e)));
//         },
//         zIndex: 2000,
//         okText: 'Confirm',
//         cancelText: `Cancel`,
//         onCancel: () => {
//           resolve(true);
//         }
//       });
//     });
//   }

//   render() {
//     const styles = {};
//     styles.button = {
//       fontSize: '13px',
//       display: 'flex',
//       justifyContent: 'center',
//       lineHeight: '1'
//     };
//     let {
//       onCellChange,
//       onDelete,
//       handleAdd,
//       panelTemplate,
//       updateTabSelected,
//       labResultCreate
//     } = this.props;
//     const patientProfileWidth = $('.vsm-patient-member-detail').width();
//     const className = patientProfileWidth <= 800 ? 'labResWrapper' : '';
//     let inputSource = {};
//     panelTemplate.forEach(d => {
//       d.results.forEach(res => {
//         if (inputSource[d.templateName]) {
//           inputSource[d.templateName].push(res);
//         } else {
//           inputSource[d.templateName] = [res];
//         }
//       });
//     });

//     return _.map(panelTemplate, (t, i) => {
//       const d = t.results;
//       const key = t._id;
//       const { templateName, _id, userId } = t;

//       let extendedColumns = defaultColumns.slice();
//       let columns = defaultColumns.slice();

//       if (_.isEqual(_.get(d, '0.name'), 'Eye Exam')) {
//         columns = columnMap['eye_exam'];
//       }

//       if (_.isEqual(_.get(d, '0.name'), 'Foot Exam')) {
//         columns = columnMap['﻿foot_exam'];
//       }
//       if (
//         d[0].name != 'Foot Exam' &&
//         d[0].name != 'Eye Exam' &&
//         panelWithInHouse.indexOf(t.templateName) != -1
//       ) {
//         extendedColumns[0].width = '20%';
//         extendedColumns.push({
//           title: 'In-house Test',
//           dataIndex: 'isInHouse',
//           render: val => <Checkbox checked={val} disabled />
//         });
//         return (
//           <div className={className} key={key}>
//             <div
//               style={{
//                 paddingLeft: 8,
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center'
//               }}
//             >
//               {templateName}
//               <Button onClick={() => this.handleClick(_id, userId)}>
//                 Delete
//               </Button>
//             </div>
//             <Table
//               pagination={false}
//               columns={extendedColumns}
//               style={{
//                 border: 'solid 1.5px #aaaaaa',
//                 marginTop: 5,
//                 marginBottom: 20
//               }}
//               dataSource={d}
//               rowKey={(d, i) => i}
//             />
//           </div>
//         );
//       }

//       return (
//         <div className={className} key={i}>
//           <div
//             style={{
//               paddingLeft: 8,
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center'
//             }}
//           >
//             {templateName}
//             <Button onClick={() => this.handleClick(_id, userId)}>
//               Delete
//             </Button>
//           </div>
//           <Table
//             pagination={false}
//             columns={columns}
//             style={{
//               border: 'solid 1.5px #aaaaaa',
//               marginTop: 5,
//               marginBottom: 20
//             }}
//             dataSource={d}
//             rowKey={(d, i) => i}
//           />
//         </div>
//       );
//     });
//   }
// }

const loadLabResultList = graphql(
  gql`
    query labResultList($filter: FilterFindManyLabResultsInput) {
      labResultMany(filter: $filter) {
        userId
        _id
        viewerId
        templateId
        deleted
        templateName
        dateCollected
        createdAt
        results {
          name
          unit
          value
          flag
          interval
          isInHouse
        }
      }
    }
  `,
  {
    options: ({ userId }) => ({
      variables: {
        filter: {
          userId,
          filterDeleted: true /*temporarily solution to exclusive deleted labRes */
        }
      },
      fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data: { loading: labResultListLoading, labResultMany, refetch: labResultListRefetch } }) => {
      let labResultList = {}; // default value

      if (!labResultListLoading) {
        const r0a = R.reject(({ dateCollected, templateName }) => R.isNil(templateName) || R.isNil(dateCollected));
        const r0b = R.compose(R.reverse, R.sortBy(R.prop('dateCollected')));
        const r0 = R.map(R.over(R.lensProp('dateCollected'), dt => formatDateMM(dt)));
        const r1 = R.groupBy(R.prop('dateCollected'));
        const r2 = R.map(R.groupBy(R.prop('templateId')));
        const r3 = R.compose(trace('r2'), r2, r1, trace('r0'), r0, trace('r0b'), r0b, trace('r0a'), r0a, trace('r0a start'))(labResultMany);
        labResultList = r3;
      }

      // console.log('labREsultList ', labResultList);

      return {
        labResultList,
        labResultListLoading,
        labResultListRefetch
      };
    }
  }
);
// const mapDispatch = (dispatch, props) => {
//     return {
//         deleteLabRes: (_id, userId) => {
//             return Client.mutate({
//                 mutation: editLabRes,
//                 variables: {
//                     _id,
//                     deleted: true
//                 },
//                 refetchQueries: [{
//                     query: labResultList,
//                     variables: {filter: {userId, filterDeleted: true}},
//                     notifyOnNetworkStatusChange: true,
//                     fetchPolicy: 'network-only',
//                 }]
//             })
//         },
//         openErrorModal: (err) => dispatch(openErrorModal(err))
//     }
// }

// const LabResultView = compose(connect(null,mapDispatch))(TestPanelFormComponent);
// const NoReport = () => <div style={{
//   "display": "flex",
//   "flexDirection": "column",
//   "alignItems": "center",
//   "color": "#ccc",
//   fontSize: 15
// }} >
//   <img width='59px' src='/image/lab-results-icon.svg'/>
//   <div style={{ fontSize: 22, marginTop: 12 }}> No Lab Results </div>
//   <div> There are no lab results recorded for this patient. </div>
//   <div> Click on Add Lab Results to start recording </div>
// </div>

const LabResultsComponent = class extends React.Component {
  constructor() {
    super();
    this.state = {
      editEnabled: false,
      activeEditKey: -1
    };
  }

  componentDidMount() {
    // setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this))
  }

  routerWillLeave() {
    return new Promise((resolve, reject) => {
      if (!this.state.editEnabled) {
        resolve(true);
        return;
      }
      routerWillLeaveHelper(this.routerWillLeaveClickOK, resolve, 'Lab Result');
    });
  }

  routerWillLeaveClickOK = () => {
    this.setState({
      editEnabled: false
    });
  };

  setEditEnabled = (editEnabled, activeEditKey) => {
    this.setState({
      editEnabled,
      activeEditKey
    });
  };

  render() {
    const { editEnabled, activeEditKey } = this.state;
    const {
      //newlyAddedDate,
      labResultList,
      userId,
      reportSelected,
      updateReportSelected,
      setLabResStatus,
      setSaveEditButtonRef
    } = this.props;
    return (
      <div className='lab-results-container'>
        <Row style={{ fontSize: 18, fontWeight: 600, marginBottom: 15 }}>
          <Col>
            <span>Lab Results</span>
            <span style={{ paddingLeft: 18 }}>
              {R.keys(labResultList).length === 0 ? (
                <NoDataComponent 
                  containerStyle={{ textAlign: 'unset', maxWidth: 'fit-content' }} 
                  descriptionStyle={{ textAlign: 'center'}}
                />
              ) : (
                <Dropdown
                  trigger={['click']}
                  disabled={editEnabled}
                  overlay={
                    <Menu>
                      {R.keys(labResultList).map((date, i) => {
                        const resArray = labResultList[date];
                        const keys = Object.keys(labResultList[date]);
                        const templatesNameArray = _.map(keys, (v, i) => {
                          return _.get(resArray, `${v}[0].templateName`);
                        });
                        const templatesNameString = templatesNameArray.length > 0 ? templatesNameArray.join(', ') : '';
                        return (
                          <Menu.Item key={i}>
                            <div
                              onClick={() => {
                                updateReportSelected(date);
                                this.setEditEnabled(false, -1);
                              }}
                            >
                              {date}
                              <p>{templatesNameString}</p>
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                >
                  <a className='ant-dropdown-link'>
                    {reportSelected}
                    <Icon type='down' />
                  </a>
                </Dropdown>
              )}
            </span>
          </Col>
        </Row>
        <Row>
          {_.map(labResultList[reportSelected], (labs, index) => {
            //console.log(`lab#${index}`);
            return _.map(labs, (l, i) => {
              //console.log('before ', l.results);
              return <EditableTable 
                key={i + '_' + index + '_' + reportSelected}
                labResult={l}
                userId={userId}
                setEditEnabled={this.setEditEnabled}
                reportSelected={reportSelected}
                editEnabled={editEnabled}
                activeEditKey={activeEditKey}
                index={i + '_' + index}
                setLabResStatus={setLabResStatus}
                setSaveEditButtonRef={setSaveEditButtonRef}
                onInteraction={this.props.onInteraction}
              />;
            });
          })}
        </Row>
      </div>
    );
  }
};

const spinnerWhileLoading = isLoadingF =>
  branch(
    isLoadingF,
    renderComponent(() => <IHLoading />)
  );

const enhanceLabResults = compose(
  loadLabResultList,
  spinnerWhileLoading(R.prop('labResultListLoading')),
  withState('reportSelected', 'updateReportSelected', R.compose(R.head, R.keys, R.prop('labResultList'))),
  withPropsOnChange((props,nextProps)=>{
    if(!_.isEqual(props.labResultList,nextProps.labResultList)){
      return true;
    }
  },props=>{
    props.updateReportSelected(R.keys(props.labResultList)[0]);
    return { labResultList: props.labResultList };
  }),
  // withPropsOnChange(['labResultList'], props => {
  //   console.log(props.labResultList);
  //   props.updateReportSelected(R.keys(props.labResultList)[0]);
  //   return { labResultList: props.labResultList };
  // }),
  withProps(trace('enhanceLabResults'))
);

const LabResults = enhanceLabResults(LabResultsComponent);

export { LabResults };