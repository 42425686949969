export default {
  RD_GOAL_ASSESSMENT: {
    GOAL_STATUS_MEETING: 'It appears that the patient is meeting their {CONDITION} goal',
    GOAL_STATUS_NOT_MEETING: 'It appears that the patient is not meeting their {CONDITION} goal',
    GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded, either because the goal is difficult to measure OR the patient\'s vitals span multiple ranges',
    MEASURE_GTE_12: ', and the patient measured 12 or more times this month, which we estimate to be enough for a clear assessment.',
    MEASURE_LE_12: ', but the patient is measuring less than 12 times per month, so we cannot be sure of the assessment',
    GLUCOSE_AVG: 'The average glucose is estimated in the A1C range of {A1C_LEVEL}',
    INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals. Review medication compliance, any future follow ups with the provider, and dietary habits as per patient preference. Help to set behavioral goals as the patient requires. ',
    CLINICAL_CAUTION_DIABETES: 'Note: If this patient is type 1, or on insulin, sulfonylureas, SGLT-2 inhibitors, glinides, or other glucose-lowering medications, or if this patient has a history and higher risk of heart attack or stroke, then an A1C goal range of 7-8% may be appropriate.  If the patient has type 1 or is insulin dependent and there are no other heart disease risks, then 7-7.5% may be a better target range. However, if the patient has none of the above, then 6.5-7% would be most clinically suitable based on ADA guidelines. Contact the provider if you are unsure of the patient’s goal range, and update the clinical goal as necessary. ',
    CLINICAL_CAUTION_PRE_DIABETES: 'Note: this patient appears to have a pre-diabetes diagnosis, but their average glucose appears higher than the typical pre-diabetes range. This patient may benefit from getting their A1C rechecked by the lab. Talk with the doctor about rechecking the A1C, or ask patient to check more often or more times of day to confirm. ',
    CLINICAL_CAUTION_BP_1: 'Note: This patient\'s systolic BP is high, while their diastolic is normal or low. This creates a pulse pressure at or above 80mmHg that may be at increased cardiac risk. Please consult with the provider regarding this patient to see how this may need to be addressed.',
    CLINICAL_CAUTION_BP_2: 'If the patient agrees to nutrition counseling, screen them for adequate fluid intake, other reasons for dehydration, and make sure that they are not over-restricting dietary sodium. Help to set behavioral goals as the patient requires. If there is no clear reason for the low blood pressure, this may be the patient’s usual bp, or the patient may require a physician visit.In such a circumstance, task the provider with the typical bp range. If any new behavioral goals are set with this patient, encourage them to test more often this month to observe for any difference. Task the physician if the patient cannot or will not check vitals more frequently.',
    CLINICAL_CAUTION_BP_3: 'Discuss and encourage stress relief, biofeedback techniques for lowering blood pressure, and diet as the patient is comfortable.',
    WEIGHT_CONDITION: 'The patient has a {CONDITION} goal to ',
    WEIGHT_GOAL_TYPE: '{WEIGHT_GOAL_TYPE} their weight, and the patient is',
    WEIGHT_TRENDING_GAIN: 'and their weight appears to be rising. ',
    WEIGHT_TRENDING_LOSE: ' and their weight appears to be falling. ',
    WEIGHT_NO_DATA: ' and they haven\'t taken any weights recently to establish a trend. ',
    WEIGHT_MAINTAIN: 'and their weight appears to be stable with little to no change. Please double-check that this patient is not having a weight change so gradual that is hard to detect. ',
    WEIGHT_NOT_MAINTAIN: 'and their weight appears to be fluctuating, making it hard to determine a trend.',
    WEIGHT_GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded by the system because the goal is difficult to measure, ',
    WEIGHT_GOAL_STATUS_MEETING: 'The patient appears to be meeting their weight goals set, ',
    WEIGHT_GOAL_STATUS_NOT_MEETING: 'The patient appears to be not meeting their weight goals set, ',
    WEIGHT_MEASURE_GTE_12: 'and the patient measured their weight 12 or more times this month, which we estimate to be enough for a clear assessment.',
    WEIGHT_MEASURE_LE_12: 'however, they measured their weight less than 12 times this month, so we cannot be sure of the assessment.',
    WEIGHT_INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals. Review medication compliance, any future follow ups with the provider, and dietary habits as per patient preference. Help to set behavioral goals as the patient requires. ',
    WEIGHT_GOAL_CHF: 'NOTE: This may not be critical. Review the weight history and patient’s typical dry weight, and double check the clinical goals. You may want to notify the provider if this patient has symptoms of exacerbated CHF or their weekly weight gain has recently exceeded 5lb.'
  },
  MA_GOAL_ASSESSMENT: {
    GOAL_STATUS_MEETING: 'It appears that the patient is meeting their {CONDITION} goal',
    GOAL_STATUS_NOT_MEETING: 'It appears that the patient is not meeting their {CONDITION} goal',
    GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded, either because the goal is difficult to measure OR the patient\'s vitals span multiple ranges',
    MEASURE_GTE_12: ', and the patient measured 12 or more times this month, which we estimate to be enough for a clear assessment.',
    MEASURE_LE_12: ', but the patient is measuring less than 12 times per month, so we cannot be sure of the assessment',
    GLUCOSE_AVG: '',
    INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals, and discuss with the provider as necessary.',
    CLINICAL_CAUTION_DIABETES: '',
    CLINICAL_CAUTION_PRE_DIABETES: '',
    CLINICAL_CAUTION_BP_1: '',
    CLINICAL_CAUTION_BP_2: '',
    CLINICAL_CAUTION_BP_3: '',
    WEIGHT_CONDITION: 'The patient has a {CONDITION} goal to ',
    WEIGHT_GOAL_TYPE: '{WEIGHT_GOAL_TYPE} their weight, and the patient is',
    WEIGHT_TRENDING_GAIN: 'and their weight appears to be rising. ',
    WEIGHT_TRENDING_LOSE: ' and their weight appears to be falling. ',
    WEIGHT_NO_DATA: ' and they haven\'t taken any weights recently to establish a trend. ',
    WEIGHT_MAINTAIN: 'and their weight appears to be stable with little to no change. Please double-check that this patient is not having a weight change so gradual that is hard to detect. ',
    WEIGHT_NOT_MAINTAIN: 'and their weight appears to be fluctuating, making it hard to determine a trend.',
    WEIGHT_GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded by the system because the goal is difficult to measure, ',
    WEIGHT_GOAL_STATUS_MEETING: 'The patient appears to be meeting their weight goals set, ',
    WEIGHT_GOAL_STATUS_NOT_MEETING: 'The patient appears to be not meeting their weight goals set, ',
    WEIGHT_MEASURE_GTE_12: 'and the patient measured their weight 12 or more times this month, which we estimate to be enough for a clear assessment.',
    WEIGHT_MEASURE_LE_12: 'however, they measured their weight less than 12 times this month, so we cannot be sure of the assessment.',
    WEIGHT_INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals, and discuss with the provider as necessary.',
    WEIGHT_GOAL_CHF: 'Please discuss this patient\'s case with the provider, and set an appropriate cliinical goal. Reach out to this patient to review details on trends and clinical goals.'
  },
  MD_GOAL_ASSESSMENT: {
    GOAL_STATUS_MEETING: 'It appears that the patient is meeting their {CONDITION} goal',
    GOAL_STATUS_NOT_MEETING: 'It appears that the patient is not meeting their {CONDITION} goal',
    GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded, either because the goal is difficult to measure OR the patient\'s vitals span multiple ranges',
    MEASURE_GTE_12: ', and the patient measured 12 or more times this month, which we estimate to be enough for a clear assessment.',
    MEASURE_LE_12: ', but the patient is measuring less than 12 times per month, so we cannot be sure of the assessment',
    GLUCOSE_AVG: '',
    INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals, and discuss with the provider as necessary.',
    CLINICAL_CAUTION_DIABETES: '',
    CLINICAL_CAUTION_PRE_DIABETES: '',
    CLINICAL_CAUTION_BP_1: '',
    CLINICAL_CAUTION_BP_2: '',
    CLINICAL_CAUTION_BP_3: '',
    WEIGHT_CONDITION: 'The patient has a {CONDITION} goal to ',
    WEIGHT_GOAL_TYPE: '{WEIGHT_GOAL_TYPE} their weight, and the patient is',
    WEIGHT_TRENDING_GAIN: 'and their weight appears to be rising. ',
    WEIGHT_TRENDING_LOSE: ' and their weight appears to be falling. ',
    WEIGHT_NO_DATA: ' and they haven\'t taken any weights recently to establish a trend. ',
    WEIGHT_MAINTAIN: 'and their weight appears to be stable with little to no change. Please double-check that this patient is not having a weight change so gradual that is hard to detect. ',
    WEIGHT_NOT_MAINTAIN: 'and their weight appears to be fluctuating, making it hard to determine a trend.',
    WEIGHT_GOAL_STATUS_UNABLE_TO_CONCLUDE: 'It appears that the goal status cannot be concluded by the system because the goal is difficult to measure, ',
    WEIGHT_GOAL_STATUS_MEETING: 'The patient appears to be meeting their weight goals set, ',
    WEIGHT_GOAL_STATUS_NOT_MEETING: 'The patient appears to be not meeting their weight goals set, ',
    WEIGHT_MEASURE_GTE_12: 'and the patient measured their weight 12 or more times this month, which we estimate to be enough for a clear assessment.',
    WEIGHT_MEASURE_LE_12: 'however, they measured their weight less than 12 times this month, so we cannot be sure of the assessment.',
    WEIGHT_INSTRUCTION: 'Please reach out to this patient to review details on trends and clinical goals, and discuss with the provider as necessary.',
    WEIGHT_GOAL_CHF: ''
  }
}