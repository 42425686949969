import React from 'react';
import Client from 'libModule/gqlClient';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import createVersionHistory  from  'modulesAll/graphql/mutation/createVersionHistory';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import enrolledProgramGQL from '../../graphql/enrolledProgram';
import { patientGQL } from '../../graphql/user';
import { getPESStatements } from '../../graphql/getPESStatements';

class CreateVersionHistoryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      alreadySent: false,
    };
  }

  get isReqDataAvailable() {
    const { enrolledProgram, user, userPESStatements, visitId } = this.props;
    if (enrolledProgram && user && userPESStatements && visitId) {
      return true;
    }
  }

  componentDidUpdate() {
    const { sending, alreadySent } = this.state;
    if (this.props.sendWithoutClick && !sending && !alreadySent && !this.isDataLoading()) {
      this.setState({ alreadySent: true });
      this.sendRequest();
    }
  }

  isDataLoading = () => {
    const { enrolledProgramLoading, userLoading, userPESStatementsLoading } = this.props;
    if (enrolledProgramLoading || userLoading || userPESStatementsLoading) {
      return true;
    }
  }

  sendRequest = () => {
    const { enrolledProgramId, itemsUpdated, onSuccess, enrolledProgram, user, userPESStatements, visitId } = this.props;
    const { sending } = this.state;

    if(sending) return;

    if (!this.isReqDataAvailable || !enrolledProgramId || !itemsUpdated) {
      throw new Error('Missing input!')
    }

    const variables = {
      enrolledProgramId,
      visitId,
      itemsUpdated,
      careplanData: JSON.stringify({
        enrolledProgram,
        user,
        pes: userPESStatements,
      }),
    };

    this.setState({ sending: true });
    return Client.mutate({
      mutation: createVersionHistory,
      variables,
    }).then(({ data }) => {
      this.setState({ sending: false });
      if (onSuccess) {
        onSuccess(data.upsertVersionHistory);
      }
    }).catch(() => {
      this.props.openErrorModal('Something went wrong creating the PDF! Please try again!')
      this.setState({ sending: false });
    });
  }

  render() {
    const { style, children, loadingIndicator, sendingIndicator } = this.props;
    const { sending } = this.state;

    if (this.isDataLoading()) {
      return loadingIndicator || <Spin />;
    }

    if (sending) {
      return sendingIndicator || <div>Generating PDF...</div>;
    }

    return (
      <div style={style} onClick={this.sendRequest} id="print-pdf-btn">
        {children || ''}
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal:(errorMessage) => dispatch(openErrorModal(errorMessage))
  }
}

const getEnrolledProgram = graphql(enrolledProgramGQL, {
  options: ownProps => ({
    variables: {
      id: ownProps.enrolledProgramId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => { return data.loading ? { enrolledProgramLoading: true } : { enrolledProgram: data.enrolledProgram }},
});

const getUser = graphql(patientGQL, {
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.memberId
      },
    }
  },
  props: ({ data }) => { return data.loading ? { userLoading: true } : { user: data.user }},
});

const getUserPESStatements = graphql(getPESStatements, {
  options: (ownProps) => ({
   variables: {
     memberId: ownProps.memberId,
     visitId: ownProps.visitId,
   },
   fetchPolicy: 'network-only'
 }),
  props: ({ data }) => { return data.loading ? { userPESStatementsLoading: true } : { userPESStatements: data.getPESStatements }},
});

const enhance = compose(
  connect(null, mapDispatch),
  getEnrolledProgram,
  getUser,
  getUserPESStatements,
);

export default enhance(CreateVersionHistoryButton);
