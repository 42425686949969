import { graphql } from 'react-apollo';
import moment from 'moment';
import { inventoryReportGQL } from 'modulesAll/graphql/inventory';
import loadDataSource from './loadDataSource';

export default graphql(inventoryReportGQL, {
  options: ownProps => {
    const { organizationId, viewOption, dateRange: { fromDate, toDate } } = ownProps;
    // console.log('fromDate ', moment(fromDate).format('MM/DD/YYYY'));
    // console.log('toDate ', moment(toDate).format('MM/DD/YYYY'));
    return {
      variables: {
        fromDate,
        toDate,
        organizationId,
        groupBy: viewOption || 'DAY',
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  },
  props: ({ 
    ownProps, 
    data
  }) => {
    const { error, loading, inventoryReport, getInventory, inventoryTransactionList, refetch, fetchMore, networkStatus } = data;
    // console.log('data ', data);
    return {
      error,
      loading,
      networkStatus,
      inventoryReport,
      getInventory,
      inventoryTransactionList,
      fetchMoreInventoryData: fetchMore,
      refetchInventoryData: (giveOuts, isAfterMutation) => {
        const { setDataSource, dateRange, setDateRange, setIsViewOptionDisabled } = ownProps;
        // if(giveOuts) {
        //   const newForecastAmounts = _.assign({}, forecastAmounts);
        //   _.map(giveOuts, giveOut => {
        //     const newAmount = _.get(giveOut, 'quantity');
        //     if(newAmount > 0) {
        //       const productType = _.findKey(mappingDevices, value => value === _.get(giveOut, 'productType'));
        //       const oldAmount = _.get(newForecastAmounts, productType);
        //       _.set(newForecastAmounts, productType, _.sum([oldAmount, newAmount]));
        //     }
        //   })
        //   setForecastAmounts(newForecastAmounts); // when refetch called from Restock and GiveOut actions
        // }
        if(isAfterMutation) setDateRange({...dateRange, toDate: moment().endOf('day').valueOf()}); // prevent not updating the latest after Restock or GiveOut
        setDataSource(undefined);
        refetch().catch(_ => {
          setDataSource([]);
          setIsViewOptionDisabled(false);
        }); // set empty dataSource when the error is 'no doc for inventory'
      }
    };
  }
});