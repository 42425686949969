import React from 'react';
import { Row, Col } from 'antd';
import { CellularAssignedDeviceComponent } from '../CellularAssignedDeviceComponent/CellularAssignedDeviceComponent';
import { CellularDeviceUnassignButtonComponent } from '../CellularDeviceUnassignButtonComponent/CellularDeviceUnassignButtonComponent';

import './CellularAssignedDevicesComponent.scss';
import { useCellularDevicesContext } from '../../context/CellularDevicesContext';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularAssignedDevicesComponent = ({
  devices,
}) => {
  const {
    handleUnassignBP,
    handleUnassignBG,
  } = useCellularDevicesContext();

  const handleUnassign = (
    brand,
  ) => {
    const isBP = CellularDevicesHelpers.BRAND_TO_DEVICE_TYPE_MAP[brand] === CellularDevicesHelpers.DEVICE_TYPE_ENUM.BP;
    if (isBP) {
      handleUnassignBP();
    } else {
      handleUnassignBG();
    }
  };

  return (
    <div className="cellular-devices-assigned-devices">
      <Row>
        <Col
          className="cellular-devices-assigned-devices__title"
        >
          Assigned Device:
        </Col>
      </Row>
      <Row>
        {
          devices.map(({
            deviceId,
            brand,
            language,
          }) => (
            <Col
              key={brand}
              className="cellular-devices-assigned-devices__device"
              span={10}
            >
              <CellularAssignedDeviceComponent
                deviceId={deviceId}
                brand={brand}
                language={language}
              />
              <CellularDeviceUnassignButtonComponent 
                onUnassign={() => handleUnassign(brand)}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};
