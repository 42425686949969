import React from 'react';
import PatientPreferencesPropTypes from './proptypes';
import upConstants from './constants';

const styles = {
  wrapper: {
    fontSize: 14,
    lineHeight: '19px',
    color: '#AAAAAA',
    margin: '0px 0px 0px 10px'
  },
  title: {
    userSelect: 'none',
    textDecoration: 'underline',
  },
  clickableTitle: {
    userSelect: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  selections: {
    marginLeft: 5
  }
};

const PatientPreferencesText = props => {
  const isClickable = !!props.onClick;
  const customStyles = props.customStyles || {};
  const classNames = props.classNames || {};

  const renderSelections = () => {
    const userPreference = props.userPreference;
    const optionKeys = _.keys(userPreference);
    const selections = [];
    _.forEach(optionKeys, key => {
      const text = upConstants.keyTextMapping[key];
      if(text && (userPreference[key] || {}).value === upConstants.valueEnum.NO) {
        selections.push(text);
      }
    });
    return selections.length ? selections.join(', ') : 'None';
  };

  const onClick = () => {
    if(isClickable) props.onClick();
  };

  return <div style={{...styles.wrapper, ...customStyles.wrapper}} className={classNames.wrapper}>
    <span 
      style={{...isClickable ? styles.clickableTitle : styles.title, ...customStyles.title}} 
      className={classNames.title}
      onClick={onClick}
    >
      Declined Services:
    </span>
    <span 
      style={{...styles.selections, ...customStyles.selections}}
      className={classNames.selections}
    >
      {renderSelections()}
    </span>
  </div>
};

PatientPreferencesText.displayName = 'PatientPreferencesText';
PatientPreferencesText.propTypes = PatientPreferencesPropTypes.text;

export default PatientPreferencesText;