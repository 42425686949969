import { IHTable, IHButton } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import { updateSelectedRowKeys, convertToFieldsValue, TableColumnsWidth } from 'libModule/utils'
import { CareplanHelper } from 'modulesAll/utils/helpers/task'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
import { initData as alertInitData } from 'commonModule/components/careplan/formData/SurveyAlertForm'
import actions from 'patientModule/enrolledProgramInfo/Survey/actions/ListActions'
import { attachScheduleDetails } from 'libModule/helpers/component-helpers'
import { sortSelectedAssessmentsOnTop } from 'libModule/utils'

const assessmentType = 'SURVEY'
const title = 'Survey'

class Component extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/Survey/containers/ListContainer'

  componentWillMount() {
    const { program } = this.props
    this.origSelectedIds = (get(program, 'tasks') || []).filter(v => v.type === assessmentType).map(v => v.assessment.id)
  }

  componentDidMount () {
    const { assessmentListData, selectItem, program, pagination } = this.props
    selectItem(updateSelectedRowKeys(assessmentListData, selectItem, program, assessmentType))
    setTimeout(this.updateTasks.bind(this, program), 0)

    if (pagination && pagination.current !== 1) {
      this.props.onTableChange({ pageSize: 5, current: 1 })
    }
  }

  componentWillReceiveProps (nextProps) {
    const { assessmentListData, selectItem, program, pagination } = this.props
    const currentTasks = get(program, 'tasks')
    const nextTasks = get(nextProps.program, 'tasks')

    if (currentTasks && nextTasks && !isEqual(currentTasks, nextTasks)) {
      this.origSelectedIds = (get(nextProps.program, 'tasks') || []).filter(v => v.type === assessmentType).map(v => v.assessment.id)
      selectItem(updateSelectedRowKeys(assessmentListData, selectItem, nextProps.program, assessmentType))
      setTimeout(this.updateTasks.bind(this, nextProps.program), 0)

      if (pagination && pagination.current !== 1) {
        this.props.onTableChange({ pageSize: 5, current: 1 })
      }
    }

    // Have to do this in componentWillReceiveProps because component will not mount when changing tabs
    if (this.props.step && nextProps.step && this.props.step !== nextProps.step) {
      if (pagination && pagination.current !== 1) {
        this.props.onTableChange({ pageSize: 5, current: 1 })
      }
    }
  }

  render() {
    const {assessmentListData, selectItem, selectedIds, program } = this.props
    const surveysWithSchedule = attachScheduleDetails(assessmentListData, get(program, 'tasks') || [])
    this.sortedSurveys = sortSelectedAssessmentsOnTop(surveysWithSchedule, this.origSelectedIds)
    const rowSelection = {
      selectedRowKeys: selectedIds,
      onSelect: (record, selected, selectedRows) => {
        selectItem(selectedRows.map(r => r._id));
        setTimeout(this.updateTasks.bind(this), 0)
      }
    }
    const columns = [
      {
        title,
        dataIndex: 'name',
        key: 'name',
        width: TableColumnsWidth.CarePlan.Survey.Name,
      },
      {
        title: 'Schedule',
        dataIndex: 'schedule',
        key: 'schedule',
        width: TableColumnsWidth.CarePlan.Survey.Schedule,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        /* Do not assign width for this column since it will take the remaining space.
           Assigning width will actually affect other columns due to additional column above
         */
      },
      {
        title: 'Actions',
        dataIndex: '_id',
        render: (value, row, index) => this.renderAlertAddButton(value, row, index),
        width: TableColumnsWidth.CarePlan.Survey.Actions,
      }
    ]
    const dataSource = this.sortedSurveys
    const p = {
      rowKey: '_id',
      columns,
      dataSource,
      rowSelection,
      onChange: this.props.onTableChange,
      pagination: this.props.pagination
    }

    return (
      <div>
        <p className="v-tlt" style={{marginTop: 30}}>{`Select ${title}`}</p>
        <IHTable {...p} />
        { this.props.surveyHasEmptySchedule ? <div style={{color:'#d72013'}}>Please ensure all selected survey(s) has a schedule</div> : '' }
      </div>
    )
  }

  updateTasks(nextProgram) {
    const { AlertForm, program, selectedIds, changeTasks } = this.props
    const finalProgram = nextProgram ? nextProgram : program
    const dbData = CareplanHelper.convertAssessmentListToDBTasks({ assessmentListData: this.sortedSurveys, AlertForm, program: finalProgram, selectedIds, assessmentType })
    changeTasks(dbData)
  }

  renderAlertAddButton(value, row, index) {
    const { goToForm, updateForm, selectedIds, program, updateAlertForm } = this.props
    const disabled = !selectedIds || !selectedIds.includes(value)
    const pb = {
      bsStyle: 'primary',
      size: 'exsmall',
      disabled,
      onClick: () => {
        goToForm()
        updateForm(row, !disabled)
        if (program.tasks) { updateAlertForm(row, program.tasks) }
      }
    }

    return (
      <IHButton {...pb} >
        Edit
        <i className="fa fa-angle-right ih-right"></i>
      </IHButton>
    )
  }

  renderActions(value, row, index) {
    const { goToForm, updateForm, selectedRowKeys } = this.props
    const disabled = !selectedRowKeys.includes(value)
    const pb = {
      bsStyle: 'primary',
      label: 'View',
      size: 'exsmall',
      onClick: () => {
        goToForm()
        updateForm(row, !disabled)
      }
    }

    return (
      <div>
        <IHButton {...pb} />
      </div>
    )
  }
}

const mapState = ({ patient }) => {
  return {
    ...patient.enrolledProgramInfo.Careplan.Survey.SurveyList,
    ...patient.enrolledProgramInfo.ProgramInfoTab,
    AlertForm: { fieldsValue: convertToFieldsValue(alertInitData) },
  }
}

const mapDispatch = {
  ...actions
};

Component.propTypes = {
  program: PropTypes.object,
  pagination: PropTypes.object,
  AlertForm: PropTypes.object,
  assessmentListData: PropTypes.array,
  updateForm: PropTypes.func,
  changeTasks: PropTypes.func,
  selectItem: PropTypes.func,
  updateAlertForm: PropTypes.func,
  selectedRowKeys: PropTypes.array,
  selectedIds: PropTypes.array,
  onTableChange: PropTypes.func,
  goToForm: PropTypes.func,
  step: PropTypes.string
}


export default connect(mapState, mapDispatch)(Component)
