import { React, _ } from 'ihcomponent';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions';
import ListComponent from '../components/ListComponent';

// graphql
import { assessmentListWithSearch as assessmentList } from 'graphqlModule/assessmentList';

const tableActions = createTableAction('admin_assessment_list', {
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    modifiedAt: 'MODIFIED_AT'
  },
});

const withData = graphql(assessmentList, {
  options: (ownProps) => {
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      filters: {
        type: ['ASSESSMENT'],
        ...ownProps.filter,
      },
      search: {
        fields: ['NAME'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: { 'field': 'CREATED_AT', 'direction': 'DESC' } //VS-1351 newly created assessment show on top
    };

    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      };
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    };
  },
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.admin_assessment_list,
});

export default compose(
  connect(mapStateToProps, () => tableActions),
  withData,
)(ListComponent);
