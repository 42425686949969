import {
    React,IHTooltip
} from 'ihcomponent';
import {modalAction} from 'modulesAll/common/actions'
import { connect } from 'react-redux'
import I18N from '../../../I18N';


class PatientStarComponent extends React.Component{
    render(){
        const format = _.get(this.props,'us.format');
        const { uc } = this.props;
        const star = typeof format ==='function' ? format('star'): {};
        if(star.status){
            const click = ()=>{
                this.props.dispatch(modalAction.showPatientStarModal(true, 'delete', uc.variables))
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip" title={star.reason || ''} placement="right">
                    <a onClick={click} className="v-star v-y"><i className="fa fa-star" /> Tagged</a>

                </IHTooltip>
            )
        }
        else{
            const click = ()=>{
                this.props.dispatch(modalAction.showPatientStarModal(true, 'add', uc.variables))
            }
            return (
                <IHTooltip overlayClassName="vsm-tooltip" title={I18N.get('taskAssignment.patient.addTask')} placement="right">
                    <span onClick={click} className="v-star v-n"><i className="fa fa-star-o" /></span>
                </IHTooltip>
            )
        }
    }
}
//tag patient functon is not working yet
//removed from tag header
export default connect(null,null)(PatientStarComponent);
