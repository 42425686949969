import React from 'react';
import { Form, Select, Button, Switch } from 'antd';
import { language, languageConfig } from '../../../patient/DevicesAssign/constant';
import SearchClearComponent from '../../../patient/DevicesAssign/component/SearchClearComponent';

const Item = Form.Item;

let AddNewClearComponent = class extends React.Component {

  renderAutoComplete() {
    const { disabled } = this.props
    return (
      <SearchClearComponent 
        disabled={disabled}
      />
    );
  }

  getLanguageOptions() {
    const { disabled } = this.props;
    return <Select
      style={{ width: 200 }}
      onChange={() => this.setVolume()}
      disabled={disabled}
    >
      {
        language.map(({ key, value }) => {
          return <Select.Option key={key} value={key}>{value}</Select.Option>
        })
      }
    </Select>

  }

  onSubmit = (e) => {
    e.preventDefault();
    const { form, updateIoTDeviceMemberAPI, editIoTDeviceConfigAPI, openErrorModal, onAssign } = this.props;
    const memberId = _.get(this, 'props.user.id');

    form.validateFields(['deviceId', 'language', 'volume'], (errors, { deviceId, language, volume }) => {
      if(errors || !memberId)
        return;

      let variables = {
        deviceId,
        memberId,
      };
      updateIoTDeviceMemberAPI(variables, memberId)
        .then(res =>
          editIoTDeviceConfigAPI({ deviceId, language, volume: volume ? 50 : 0 }, memberId)
            .then(res => {
              if(onAssign) onAssign(res);
            })
            .catch(e => {
              openErrorModal(e)
            })
        )
        .catch(e => {
          openErrorModal(e)
        });
    })
  }

  setVolume() {
    const { form } = this.props;
    const { resetFields } = form;
    resetFields(['volume'])
  }

  renderForm = () => {
    const { form, disabled } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const selectedLanguage = getFieldValue('language');

    return <Form className='addDeviceForm' layout={'vertical'}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Item label='Device ID' required={true}>
          {getFieldDecorator('deviceId', {
            rules: [{
              required: true, message: 'Please select a device Id'
            }]
          })(this.renderAutoComplete())}
        </Item>
      </div>
      <Item label='Device Language' >
        {getFieldDecorator('language', { initialValue: 'en' })(this.getLanguageOptions())}
      </Item>
      <div className='language-switch' style={{ display: 'flex' }}>
        <Item label='' wrapperCol={{ span: 3 }}>
          {getFieldDecorator('volume', { initialValue: false, valuePropName: 'checked' })(
            <Switch disabled={disabled || (!_.get(languageConfig, `${selectedLanguage}.volume`, false))} />
          )}
        </Item>
        <div style={{ marginTop: 13, marginLeft: 8 }}>
          <div>Read out BP reading</div>
          <div style={{ fontSize: 12 }}>(only available in English &#38; French)</div>
        </div>
      </div>
      <Item wrapperCol={{ span: 12 }}>
        <Button 
          type='primary'
          onClick={this.onSubmit} 
          style={{ background: 'var(--dark-blue)', color: '#FFF' }}
          disabled={disabled}
        >
          Assign Device
        </Button>
      </Item>
    </Form>
  }

  render() {
    return this.renderForm();
  }
}

export default Form.create({})(AddNewClearComponent);