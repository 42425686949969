import  React  from 'react';
import {compose, graphql} from "react-apollo/index";
import { connect } from 'react-redux';
import smartAlertTaskListGQL from'../query/smartAlertTasksList';
import createTableAction from 'libModule/table/TableActions';
import SmartAlertListComponent from '../component/SmartAlertListComponent';

const TABLES = {
  MA: {
    tableName: 'CA_WorkingList',
    states: 'CAstates',
    actions: 'CAactions'
  },
  CDE: {
    tableName: 'CDE_WorkingList',
    states: 'CDEstates',
    actions: 'CDEactions'
  },
}
const typeToCategoriesMap = {
  CDE:['CDE','PROVIDER'],
  MA:['MA']
}
const getType = ownProps => {
  const pathname = ownProps.location.pathname;
  let type = null;
  if(pathname === '/smartalert') {
      type = 'CDE';
  }
  if(pathname === '/tempenrolledpatients') {
      type = 'MA'; // TODO: need to change filter.category in API
  }
  return type;
}

const createTableActions = tableName => createTableAction(tableName,
    {
      shouldUpdateHistory: true,
      sortFieldMap: { createdAt: 'CREATE_DATE' },
      initState: {
        sort: {direction: 'ascend', field: 'createdAt'}
      }
});

const SmartAlertListContainer = class extends React.Component {
    componentDidMount(){
      const { onLoadParams, type } = this.props;
      onLoadParams(this.props[TABLES[type].states], true);
    };

    render(){
      const type = this.props.type;
      const propsCopy = {...this.props, ...this.props[TABLES[type].states]};
      return type ? <SmartAlertListComponent {...propsCopy}/> : <div>Type is not defined</div>;
    }
}

const fetchSmartAlertTaskList = graphql(smartAlertTaskListGQL,{
  options:(ownProps)=>{
    const tableStates = ownProps[TABLES[ownProps.type].states];
    const filters = _.cloneDeep({
      ..._.get(tableStates, 'filter', {}),
      categories: typeToCategoriesMap[ownProps.type]
    });
    let tableSort = _.get(tableStates,`sort`,{});
    const { direction,fieldGraph } = tableSort;
    const sort =  (direction&&fieldGraph && { direction: direction, field:fieldGraph})|| { direction:'ASC',field: 'CREATE_DATE'};
    return {
      variables: {
        page: _.get(tableStates,'page.current',1),
        count: _.get(tableStates,'page.size',10),
        filters,
        sort
      },
      fetchPolicy:'network-only'
    }
  },
  props:({data})=> {
      const { smartAlertTasksList, loading } = data;
      return {
        loading,
        smartAlertTasksList : _.get(smartAlertTasksList, 'data', []),
        pageInfo: _.get(smartAlertTasksList, 'pageInfo', {})
      };
  }
});

const mapStateToProps = (state, ownProps) => {
  const type = getType(ownProps);
  return {
    type,
    [TABLES[type].states]: state.globalTables[TABLES[type].tableName]
  }
};

const mapDispatchToProps = (__, ownProps) => ({
  ...createTableActions(TABLES[getType(ownProps)].tableName)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), fetchSmartAlertTaskList)(SmartAlertListContainer);
