import React from 'react';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

// import timezoneList from '../../../../alerts/InterventionNotes/constants/timezoneConstants';

const SignOffCallLog = (props) => {
  const { callLogs } = props;

  return <div className='signOffItem'>
    <div className='header'>
      Call log
    </div>
    {
      _.map(callLogs, log => {
        const { id, content, createdAt, createdBy } = log; 
        // const [ callTime, ...notes ] = _.split(content, '\n');

        // let from, to, timezone;
        // [from, to] = _.split(callTime, ' - ');
        // [to, timezone] = _.split(to, '(');
        // [timezone] = _.split(timezone, ')');
        // timezone = _.invert(timezoneList)[timezone];
        // // standardize date format for moment
        // from = new Date(from);
        // to = new Date(to);

        // const note = _.join(notes, '\n');
        const createdByName = _.get(createdBy, 'profile.fullName');

        return <div key={id} className='signOffCard signOffCallLog'>
          <div className='description note'>
            {
              content &&
              <p className='hideOnList'>
                {
                  `Content: 
                  ${content}`
                }
              </p>
            }
            <SignOffSubmitInfo 
              submittedBy={createdByName}
              submittedAt={createdAt}
            />
          </div>
        </div>;
      })
    }
  </div>;
}

export default SignOffCallLog;