import React from 'react'
import VideoChatComponent from '../component/index';
import { compose, graphql } from 'react-apollo/index';
import visitListQuery from '../../graphql/visitListWithEp';
import moment from 'moment';
import  helpers from '../../utils/helpers/index';
import { Row,Col,Button,Tabs,Tooltip,Icon,DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import { IHLoading } from 'ihcomponent';
import { decryptRole } from 'libModule/utils';
import { VISIT_COUNT_MAX } from 'libModule/constants';
import roleMap from "../constant/roleMap";
import CreateVisitInDashboardContainer from "../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer";
import actions from '../../dashboard/main/actions';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { connect } from 'react-redux';
import { VISIT_TAB_KEY_TO_TEXT, MA_VISIT_TYPE_ENUM, MD_VISIT_TYPE_ENUM } from '../../../lib/constants';
import I18N from '../../I18N';

const { TabPane } = Tabs;
const styles = {
    createButton:{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginBottom: 10
    }
}
let filterVisits = {
    'MA':_.keys(MA_VISIT_TYPE_ENUM),
    'Doctor':_.keys(MD_VISIT_TYPE_ENUM)
}

const VideoChatWrapperContainer = class extends React.Component{
    constructor(){
        super();
        this.state ={
            showLeftDiv: false,
            createContainer: false,
            tabSel:'1',
        }
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.showCreate = this.showCreate.bind(this);
    }



    showCreate(){
        this.setState({
          createContainer: true
        });
        Mixpanel.track('clicked', 'create a new visit', 'on Tomorrow\'s visit page', {});
    }
    cancelCreate=(shouldRefetchVisitList)=>{
        const { refetchVisitList } = this.props;
        this.setState({
            createContainer: false
        },()=>{
            if(shouldRefetchVisitList && refetchVisitList) refetchVisitList();
        });
    }


    getUniqueIds = (visitList)=>{
        const groupedVisits = _.groupBy(visitList,v=>v.member.id);
        const ids =Object.keys(groupedVisits);
        return ids;
    }
    renderHeader = (visitList)=>{
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const { profile:{ fullName } } = currentUser;
        const { selectedTabKey } = this.props;
        const visitCountEle = <span style={{color:'#4278C3'}}>{visitList.length}</span>
        const period = {
          '1': _.toLower(VISIT_TAB_KEY_TO_TEXT[0]),
          '2': _.toLower(VISIT_TAB_KEY_TO_TEXT[1]),
          '3': 'in total',
        };
        return <Row>
                <Col span={24}><h4>{`${helpers.greetingText()} ${fullName}`}</h4></Col>
                <Col span={24}>
                    <span style={{fontSize: 14 }}>You have {visitCountEle} Appointments {period[selectedTabKey]}</span>
                </Col>
               </Row>
    }
    renderRangePicker = ()=>{
        const { setSelectedRange, selectedRange: { from, to } } = this.props;
        return (
          <RangePicker
            style={{ marginRight:20 }}
            defaultValue={[moment(from), moment(to)]}
            onChange={selectedRange =>
              setSelectedRange({ from: _.get(selectedRange, '0').startOf('day').valueOf(), to: _.get(selectedRange, '1').endOf('day').valueOf()})
            }
          />
        )
            // onChange={(dateRangeToFilter)=>this.setState({dateRangeToFilter})}/>
    }

    getNextDateOfWeek = () => {
        let nextWeekDayStartOfDay = moment().add(1, 'day').startOf('day');
        let nextWeekDayEndOfDay = moment().add(1, 'day').endOf('day'); 
        // while(nextWeekDayStartOfDay.day()==0){
        //     nextWeekDayStartOfDay.add(1,'day').startOf('day');
        //     nextWeekDayEndOfDay.add(1,'day').endOf('day');
        // }
        return [nextWeekDayStartOfDay, nextWeekDayEndOfDay];
    }

    handleChangeTab(key) {
        const [nextWeekDayStartOfDay, nextWeekDayEndOfDay] = this.getNextDateOfWeek();
        let fromDate = key === '2' ? nextWeekDayStartOfDay : (key=='1' ? moment().startOf('day'): moment().subtract(3,'day').startOf('day'));
        let toDate = key === '2' ? nextWeekDayEndOfDay :(key=='1' ? moment().endOf('day'): moment().endOf('day'));
        let text = VISIT_TAB_KEY_TO_TEXT[key - 1]; // key is misaligned with constantMap
        this.props.setSelectedTab(key);
        this.props.setSelectedRange({ from: fromDate.valueOf(), to: toDate.valueOf() });
        Mixpanel.track('clicked', text + '\s Visits tab', 'on dashboard', {});
    }


    render() {
        const { setLeftDivState,props,state,renderHeader,cancelCreate,renderRangePicker } = this;
        const { createContainer,tabSel } = state;
        const { visitList=[],loading,refetchVisitList,startTime, selectedTabKey,selectedRange } = props;
        const startDate = moment(selectedRange.from).startOf('day');
        const endDate = moment(selectedRange.to).endOf('day');
        const uniqueVisitIds = _.map(_.filter(visitList,(v)=>v.category=='VIRTUAL'),v=>v.id);
        const operations = <Row style={{ float:'right',display:'flex' }}>
                        {
                            selectedTabKey === '3' ?
                            <Col>
                              { renderRangePicker() }
                            </Col>:''
                        }
                        <Col>
                          <Button style={{marginRight:'25px',float:'right'}} type='primary' onClick={this.showCreate}>{I18N.get('visit.createBtn')}</Button>
                        </Col>
                    </Row>;
        const curTab = selectedTabKey === '3' ? 'All' : selectedTabKey === '2'?'Tomorrow' :'Today';
        if(loading) return <IHLoading/>
        const videoTable = <div style={{ margin:30 }}>
                                {/*<div style={styles.createButton}>{ renderCreateButton() }</div>*/}
                                <div>{ createContainer ? <CreateVisitInDashboardContainer onCancel={ cancelCreate }
                                                                                          closeModal={()=>cancelCreate(false)}
                                                                                          curTab={curTab}/> : '' }</div>
                                <VideoChatComponent
                                  curTab={selectedTabKey === '3' ? 'All' : selectedTabKey === '2' ? 'Tomorrow' : 'Today'}
                                  defaultStartDay={startDate} 
                                  defaultEndDay={endDate}
                                  refetchVisitList={refetchVisitList}
                                  setLeftDivState={setLeftDivState}
                                  visitList={visitList}
                                  uniqueVisitIds={uniqueVisitIds}
                                />
                           </div>
        const tomorrowVisitTab = <div>{I18N.get('visit.tab.nextDay')}
            <Tooltip title={this.getNextDateOfWeek()[0].format('MM/DD/YYYY')}><Icon type="info-circle" /></Tooltip>
        </div>
        return <div style={{ margin:30 }}>
                { renderHeader(visitList) }
                <Tabs defaultActiveKey={selectedTabKey || '1'} onChange={this.handleChangeTab} tabBarExtraContent={operations}>
                    <TabPane tab={I18N.get('visit.tab.today')} key="1">
                            { videoTable }
                    </TabPane>
                    <TabPane tab={tomorrowVisitTab} key="2">
                        { videoTable }
                    </TabPane>
                    <TabPane tab={I18N.get('visit.tab.all')} key="3">
                            { videoTable }
                    </TabPane>
            </Tabs>
            </div>
    }
}
const visitList = graphql(visitListQuery,{
    options:(props)=>{
        const { selectedRange: 
          { 
            from = moment().startOf('day').valueOf(), 
            to = moment().endOf('day').valueOf()
          } 
        } = props;
        const variables = {
            count: VISIT_COUNT_MAX
        };
        variables.appointmentFrom = from;
        variables.appointmentTo = to;
        const { provider } = {
            provider: _.reduce(
                JSON.parse(sessionStorage.getItem('doctorName')),
                (res, d) => {
                    res.push(d.id);
                    return res;
                },
                []
            )
        };
        variables.providerIds = provider;
        const currentRole = decryptRole();
        const filteredVisitTypes = filterVisits[roleMap[currentRole]];
        if(filteredVisitTypes){
          variables.type = filteredVisitTypes;
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props:({ ownProps, data })=>{
        const { loading, error,refetch } = data;
        if (error) {
            return { error };
        }
        if (loading) return { loading };
        let todayIndex = 1;
        const visitList = _.get(data, 'visitList.data') || [];
        // const currentRole = decryptRole();
        // const filteredVisits = filterVisits[roleMap[currentRole]] ? _.filter(_.get(visitList, 'data', []),v=>v.type!='TECH') : _.get(visitList, 'data', []);
        let indexVisitList = _.map(visitList, v =>
            Object.assign(v, { index: todayIndex++ })
        );
        return {
            visitList:indexVisitList,
            refetchVisitList:refetch
        }
    }
});

const mapStateToProps = state => ({
  selectedTabKey: state.dashboard.main.selectedTabKey,
  selectedRange: state.dashboard.main.selectedRange
})

const mapDispatchToProps = dispatch => ({
  setSelectedTab: tab => dispatch(actions.setSelectedTab(tab)),
  setSelectedRange: ({ from, to }) => dispatch(actions.setSelectedRange({ from, to })),
})
export default compose(connect(mapStateToProps, mapDispatchToProps), visitList)(VideoChatWrapperContainer);
