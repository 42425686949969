import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from 'antd';
import PrintCarePlanContainer from './PrintCarePlanContainer';
import PrintCarePlanComponent from '../component/PrintCarePlanComponent';

export default class PrintCarePlanButton extends React.Component {

  render() {
    const { skipFetch, style } = this.props;
    const PrintCarePlan = skipFetch ? PrintCarePlanComponent : PrintCarePlanContainer;
    return (
      <div style={style}>
        <ReactToPrint
          trigger={()=> this.props.children || <Button>Print Care Plan</Button>}
          content={()=>this.componentRef}
          // not recommended, and using it as temp solution to avoid error message "unable to load link <img>"
          // should remove this option when changing CarePlanPrint to display error console
          suppressErrors={true}
        />
        <PrintCarePlan ref={el => (this.componentRef = el)} {...this.props}/>
      </div>
    );
  }
}
