import { combineReducers } from 'redux'
import CareTeamNew from '../../createCareTeam/reducers/NewCareTeamReducer'
import CareTeamEdit from '../../editCareTeam/reducers/EditCareTeamReducer'

const reducer = combineReducers({
  CareTeamNew,
  CareTeamEdit
})
export default {
  Careteam: reducer
}
