import React from 'react';
import { constants } from 'libModule/utils'

export default constants('foodlog', 'main', [
    'SET_RATING','UPDATE_COMMENTS','RESET',
    'INIT_FOOD_LOG_LIST',
    'UPDATE_INIT_FOOD_LOG_LIST','RESET_INIT_FOOD_LOG_LIST',
    'REMOVE_FOODLOG',
    'CHANGEPRE','CHANGENEXT','SETTIMEINTERVAL','RESETSTATE','CHANGEYEAR','CHANGEMONTH','SET_INITIAL_STATE'
])

export const ratingsTooltip = <div style={{width: '500px', fontSize: 12, margin: '12px'}}>
    <div style={{fontWeight: 700, fontSize: 14}}>Food Diary Rating Criteria:</div>
    <ul>
        <li>5 stars are given when a meal is balanced using the basic portioned plate (1/2 plate vegetables, 1/4 plate carbs, 1/4 plate protein) and the food groups are all from nutrient-dense sources.</li>
        <li>
            Removal of Stars:
            <ul>
                <li>1 star is docked for each missing food group (vegetables, carbs, and protein)</li>
                <li>1 star is docked when the meal contains too much carbohydrate (if in DM program) or too much sodium (if in HTN program)</li>
                <li>1 star is docked for 2 or more low-quality food groups </li>
                <li>If pre-meal glucose is >180 and the patient intentionally restricts carbohydrates at a meal as a result, this should not be docked points.</li>
                <li><span>Giving fewer than two stars is not recommended, and may deter participation.</span></li>
            </ul>
        </li>
    </ul>
</div>    

export const commentsTooltip = <div style={{width: '500px', fontSize: 12, margin: '12px'}}>
    <div style={{fontWeight: 700, fontSize: 14, marginBottom: 8}}>Comment Tips:</div>
    <ul>
        <li>If less than 5-star rating is give, note why stars were docked and how meals could be improved. Note <span>approximate grams of macronutrients</span> in a given meal, and how much the patient should be consuming.</li>
        <li>Offer <span>meaningful advice</span>, use this as a <span>teaching opportunity</span>, or <span>fun fact</span>.</li>
        <ul>
            <li>If a patient have same 5-star meal daily, you can also remind the patient about progress on their goals, accomplishments they have achieved, general tips or fun facts about health and nutrition, questions to make them think and generate ideas, etc. </li>
        </ul>
        <li><span>BE NICE!</span> It's important to learn a habit of phrasing things in a way that conveys positivity without being judgemental.</li>
    </ul>
</div>
