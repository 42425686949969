/**
this file is used by /Provider/src/modules/vendor/patient/reducers/index.js (Vendor)
and /Provider/src/modules/patient/enrolledProgramInfo/reducers/EnrolledProgramInfoReducer.js (Nurse)
**/

import {  firstAndLastNameValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields } from 'libModule/utils'

export const schema = {
  returnedBy: {
    initialValue: '',
    required: true
  },
  returnedDateAndTime: {
    initialValue: null,
    required: true
  },
  processedBy: {
    initialValue: '',
    required: true,
    disabled: true
  },
  processedDateAndTime: {
    initialValue: null,
    required: true,
    disabled: true
  },
}

export const initData = getInitDataFromSchema(schema)
export const isFieldsRequired = getFieldsRequiredFromSchema(schema)
export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange, disabledTooOldDate, adhocDisableFields=isFieldsDisabled }) => {
  const itemList = [

    [
      {
        key: 'returnedBy',
        label: 'Returned By',
        type: 'text',
        placeholder: '',
        initialValue: initData['returnedBy'],
        col: { span: 16, offset: 0 },
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        disabled: adhocDisableFields.returnedBy,
        rules : [
          {required : isFieldsRequired.returnedBy, message : 'Returned By is required'},
          {max: 100, message: 'Returned By is too long'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'returnedBy'))}
        ],
      },

      {
        key: 'returnedDateAndTime',
        label: 'Returned Date/Time',
        type: 'date',
        initialValue: initData['returnedDateAndTime'],
        col: { span: 8, offset: 0 },
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        disabled: adhocDisableFields.returnedDateAndTime,
        showTime: {format: 'hh:mm a'},
        format:"MMM-DD-YYYY hh:mm a",
        disabledDate: disabledTooOldDate,
        rules : [
          {required : isFieldsRequired.returnedDateAndTime, message : 'Return Date/Time is required'}
        ],
      },

    ],

    [
      {
        key: 'processedBy',
        label: 'Processed By',
        type: 'text',
        placeholder: '',
        initialValue: initData['processedBy'],
        col: { span: 16, offset: 0 },

        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        disabled: adhocDisableFields.processedBy,
        rules : [
          /** {required : isFieldsRequired.processedBy, message : 'Received By is required'}, **/
          {max: 100, message: 'Received By is too long'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'receivedBy'))}
        ],
      },

      {
        key: 'processedDateAndTime',
        label: 'Processed Date/Time',
        type: 'date',
        initialValue: initData['processedDateAndTime'],
        col: { span: 8, offset: 0 },
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        showTime: {format: 'hh:mm a'},
        format:"MMM-DD-YYYY hh:mm a",
        disabledDate: disabledTooOldDate,
        disabled: adhocDisableFields.processedDateAndTime,
        rules : [
          /** {required : isFieldsRequired.processedDateAndTime, message : 'Received Date/Time is required'} **/
        ],
      },

    ],


  ]

    return {
      fieldsValue,
      onFieldsChange,
      mapPropsToFields,
      itemList
    }

}
