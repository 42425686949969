import React from 'react';
import { _ } from 'ihcomponent';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo'
import createTableAction from 'libModule/table/TableActions'
import { openModal,closeModal } from 'layoutModule/actions/MainModal';
import ListTable from '../components/ListComponent'
import providerNoteList from 'graphqlModule/providerNoteList'
import CreateNote from '../../../alerts/InterventionNotes/containers/AddInterventionNoteFormComponentNoReading'
import SingleNote from '../../../alerts/InterventionNotes/containers/SingleNoteContainer'
import { presetNotes } from 'modulesAll/graphql/user'
import { RequestCache } from 'libModule/utils'


const tableActions = createTableAction('allNotes', {
  sortFieldMap: { createdAt: 'CREATED_AT' },
})

const withData = graphql(providerNoteList, {
  options: (ownProps) => {
    const variables = {
      'filters': {
        'memberId': ownProps.patientId,
        ...ownProps.filter,
      },
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      sort: { 'field': 'CREATED_AT', 'direction': 'DESC' }
    }
    const sortField = _.get(ownProps, 'sort.fieldGraph', null);
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'DESC'),
      };
    }
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  }
});

const withDataUser = graphql(presetNotes, {
  options: () => {
    return {
      variables: {
        "id": sessionStorage.getItem('currentUserId')
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props: ({ data: { loading, user, refetch } }) => {
    const notes = _.get(user, 'organization.0.notePreset')
    return {
      notes,
      loading,
      refetch
    }
  }
})


const mapState = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.allNotes
})

const mapDispatch = (dispatch, ownProps) => {
  const canRestUUid = ownProps.canRestUUid;
  const onInteraction = ownProps.onInteraction;
  return {
    ...tableActions,
    openAddModal: () => {
      const props = {
        showModal: true,
        showHeaderCloseButton: true,
        title: 'Create a Note',
        size: 'md'
      }
      dispatch(openModal(<CreateNote noteType='provider_note' patientId={ownProps.patientId}
                                     programId={ownProps.currentProgram.id}
                                     programName={ownProps.currentProgram.name} canRestUUid={canRestUUid}
                                     closeModal={()=>dispatch(closeModal())}
                          />, props))
    },
    onRowClick: (note) => {
      const props = {
        showModal: true,
        title: 'Note Detail',
        size: 'md',
        showHeaderCloseButton: true,
        backdrop: 'static'
      }

      const _cacheCurrentPatient = RequestCache.get('nurse/currentPatient');
      const _user = {
        user: {identification:[{value: _.get(_cacheCurrentPatient, 'identification[0].value') }],
        profile: {fullName: _cacheCurrentPatient.profile.fullName}}
      }
      dispatch(openModal(<SingleNote note={note} user={ _user } closeModal={()=>dispatch(closeModal())} closeAfterDelete = {true} onInteraction={onInteraction}/>, props))
    }
  }
}

export default compose(
  connect(mapState,  mapDispatch ),
  withData,
  withDataUser
)(ListTable);
