import React from 'react';
import { Table } from 'antd';
import { IHSearchTable, IHLoading, IHButton, _,IHInput, } from 'ihcomponent';
import moment from "moment/moment";

// const epFieldsMap = ['careCoordination','followupSchedule','assessments', 'manualInputEnable','goals','interventions','resource','problemList'];
const epSectionMapping = {
    careCoordination:'Care Coordination',
    followupSchedule:'Follow Schedule',
    assessments:'Assessments',
    goals:'Goals',
    interventions:'Interventions',
    resource:'Resource',
    problemList:'Problem List',
    manualInputEnable:'Manual Input Enable',
    histories:'',
    tasks:'Tasks Updated'
}

    class ProgramUpdateHistoryComponent extends React.Component{
        getColumnSetting(){
            const columns = [
                {
                    title: 'Date / Time',
                    key: 'updatedAt',
                    dataIndex: 'updatedAt',
                    sorter: (a,b) => a.updatedAt - b.updatedAt,
                    render: (item, row) => moment(item).format('MM/DD/YYYY HH:mm')
                },
                {
                    title: 'Updated By',
                    key: 'modifiedByUser',
                    dataIndex: 'modifiedByUser',
                    render: (item) => {return _.get(item,'profile.fullName','unknown')}
                },
                {
                    title: 'Section Updated',
                    key: 'change',
                    dataIndex: 'change',
                    render: (item) => {
                        let inputKeys = Object.keys(epSectionMapping);
                        let sectionkeys = Object.keys(item);
                        let section = '';
                        inputKeys.forEach(v => {
                            if(section==''&&sectionkeys.indexOf(v)!=-1&&(item[v]!=null)) {
                                section = v;
                                return;
                            }
                        })

                        if(section=='histories'){
                            return _.last(item[`${section}`]).action;
                        }
                        return epSectionMapping[section];
                    }
                }

            ]
            return columns;
        }

        renderTable(){
            const columns = this.getColumnSetting();
            const { data,pageInfo } = _.get(this.props,'epChangeLogList',{});
            const { loading } = this.props;
            const { total } = pageInfo||{};
            const { current } = _.get(this.props,'page',1);
            const tableProps = {
                columns:  columns,
                rowKey: 'id',
                pagination: {
                    current:current,
                    pageSize: 5,
                    total:total
                },
                loading:{ spinning: !!loading,indicator: <IHLoading/> },
                title: null,
                onChange:(a,b,c)=>{
                    this.props.onTableChange(a,b,c)
                },
                dataSource:  data||[],
                optionsBox: true,
            };

            // return <IHSearchTable {...tableProps } dataSource={data} columns={columns} />
            return <Table {...tableProps } dataSource={data} columns={columns} />

        }

        render(){
            return (
                <div>
                    {this.renderTable()}
                </div>
            )
        }
    }

export default ProgramUpdateHistoryComponent;

