import gql from 'graphql-tag';

export default gql`
mutation setTaskToRead($id:EID!){
  setTaskAssignmentAsRead(id:$id){
    id
    isRead {
      value
      readAt
      readBy
    }
  }
}`
