const hiatusStatus = {
  warningNote: {
    inDetail: 'This patient has a [visitType] on [visitDate]. If the time period of the hiatus overlaps with the visit, then the visit will be removed. Please double confirm.',
    inVisitPopup: 'The date you selected for the visit is during the patient’s [link_text] ([startDate] to [endDate]), please be aware of it. Once the patient is checked in, then their Visit Hiatus will end.'
  },
  guideNote: 'Note: If you want to change patient’s hiatus information, please go to Patient details page ---> click the “View hiatus info” button on the to right.',
  submitGuide: 'Hiatus information will be added to provider notes upon submitting.',
  tipText: {
    resume: 'By resuming this patient, the patient will be moved out from ALL Hiatus status.'
  },
  toast: {
    createSuccess: 'Patient is put in hiatus status',
    updateSuccess: 'Patient\'s hiatus status is saved',
    resumeSuccess: 'Patient is resumed.'
  }
};

export default hiatusStatus;