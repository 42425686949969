const getTotalTimeOfAMonth = (timeSpentList, month) => {
  const monthObj = _.find(timeSpentList, { monthOfYear: month });
  return _.get(monthObj, 'totalTime', 0);
};

const getPreMonthReading = (timeSpentList, curMonth) => {
  let year = Math.round(curMonth/100);
  let month = curMonth % 100;
  let preMonth = 0;
  if(month==1){
    preMonth = 12;
    year = year -1;
  }else{
    preMonth = month-1;
  }

  preMonth = year * 100 + preMonth;

  return getTotalTimeOfAMonth(timeSpentList, preMonth);
};

export {
  getTotalTimeOfAMonth,
  getPreMonthReading
};