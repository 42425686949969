import gql from 'graphql-tag'
import {EnrolledProgram} from 'graphqlModule/schema/EnrolledProgram';
import { ClinicGoalSchema } from '../schema/common';

export default gql`
  mutation editEnrolledProgram(
    $id: EID!
    $tasks: [InputTaskEnrolled]
    $careCoordination: String
    $followupSchedule: String
    $problemList: String
    $assessments: String
    $interventions: String
    $goals: String
    $resource: String
    $manualInputEnable: Boolean
    $manualInputEnabledTaskTypes: [TaskType]
    $deviceInputEnabledTaskTypes: [TaskType]
    $clinicGoals: [InputClinicGoal]
    $behavioralGoals: [InputBehavioralGoal]
    $extraNote: String
    $assessmentsFlag: String
    $goalsFlag: String
  ){
    editEnrolledProgram(
      id: $id
      tasks: $tasks
      careCoordination: $careCoordination
      followupSchedule: $followupSchedule
      problemList: $problemList
      assessments: $assessments
      interventions: $interventions
      goals: $goals
      resource: $resource
      manualInputEnable: $manualInputEnable
      manualInputEnabledTaskTypes: $manualInputEnabledTaskTypes
      deviceInputEnabledTaskTypes: $deviceInputEnabledTaskTypes
      clinicGoals: $clinicGoals
      behavioralGoals: $behavioralGoals
      extraNote: $extraNote
      assessmentsFlag: $assessmentsFlag
      goalsFlag: $goalsFlag
    ){
      ${EnrolledProgram}
    }
  }
`;

export const editClinicGoalsQuery = gql`
  mutation editEnrolledProgram(
    $id: EID!
    $clinicGoals: [InputClinicGoal]
  ){
    editEnrolledProgram(
      id: $id
      clinicGoals: $clinicGoals
    ){
      id
      clinicGoals {
        ${ClinicGoalSchema}
      }
    }
  }
`;
