import React from 'react';
import moment from 'moment-timezone';
import { Radio, Button } from 'antd';
import _ from 'lodash';

const MS_OF_1DAY = 24 * 3600000;
const TIMER_MAP = {
  oneDay: {
    interval: '1 day',
    value: MS_OF_1DAY,
  },
  threeDays: {
    interval: '3 days',
    value: 3 * MS_OF_1DAY,
  },
  oneWeek: {
    interval: '1 week',
    value: 7 * MS_OF_1DAY
  },
  twoWeeks: {
    interval: '2 weeks',
    value: 2 * 7 * MS_OF_1DAY,
  },
  oneMonth: {
    interval: '1 month',
    value: 30 * MS_OF_1DAY
  },
  threeMonths: {
    interval: '3 months',
    value: 3 * 30 * MS_OF_1DAY
  }
};

const RADIO_STYLE = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

export default class SnoozeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.defaultKey = 'oneDay';
    this.state = {
      value: this.getValue(props) || this.defaultKey,
    };
  }
  
  getValue = (props) => {
    const { value } = props || this.props;
    return _.first(_.keys(_.pickBy(TIMER_MAP, (v) => v.value === value || v.interval === value )));
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value)
    this.setState({ value: this.getValue() || this.defaultKey });
  }

  handleTimerChange = (e) => {
    this.setState({ value: e.target.value, });
  }

  renderTimers = () => {
    return (
      <div style={{ margin: '12px 0' }}>
        <div style={{ color: '#757980' }}>Snooze time:</div>
        <Radio.Group onChange={this.handleTimerChange} value={this.state.value}>
          {_.map(TIMER_MAP, (v, k) => (
            <Radio key={k} style={RADIO_STYLE} value={k}>{v.interval}</Radio>
          ))}
        </Radio.Group>
      </div>
    );
  }

  renderAlertNote = () => {
    return (
      <div style={{ color: '#757980', fontSize: 14 }}>This type of alert won't be triggered until the snooze time ends.</div>
    )
  }

  handleConfirm = () => {
    this.props.onConfirm(TIMER_MAP[this.state.value], moment().clone());
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  handleUnsnooze = () => {
    this.props.onUnsnooze();
  }

  renderButtons = () => {
    return (
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button onClick={this.handleConfirm} type="primary">Ok</Button>&nbsp;
          <Button onClick={this.handleCancel}>Cancel</Button>
        </div>
        {this.props.showUnsnooze && <div>
          <Button onClick={this.handleUnsnooze}>Unsnooze</Button>
        </div>}
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: 300 }}>
          <h4>Please choose snooze time:</h4>
          <div style={{ fontSize: 14 }}>
            <span>Start Date:</span>&nbsp;<span>{moment().format('MM/DD/YYYY')} (Today)</span>
          </div>
          {this.renderTimers()}
          {this.renderAlertNote()}
          {this.renderButtons()}
      </div>
    );
  }
}
