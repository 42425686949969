import {
  React, IHSearchTable, _, IHCheckbox
} from 'ihcomponent'
import {getRefIDWithRoleCategory} from 'libModule/utils'
import { graphql,compose } from 'react-apollo'
import userListGQL from 'modulesAll/graphql/userList'
import UserClass from 'modulesAll/graphql/class/User'
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import  actions from '../actions/EditCareTeamActions'
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import addTeamMember from "../../query/addTeamMember";
import removeTeamMember from "../../query/removeTeamMember";
import Client from 'libModule/gqlClient';

const Component = class extends React.Component {
  static displayName = 'careteam/editCareTeam/containers/ManagerTable'

  componentWillUnmount() {
    this.props.onLoadParams(null);
  }

  render(){
    const p = this.getTableProps()
    return (
      <div className="v-table">
        <p>Select Care Managers</p>
        <IHSearchTable {...p} />
      </div>
    )
  }

  componentWillReceiveProps(nextProps){
      const { updateUserList } = this.props;
      const nextUserList = _.get(nextProps,'data.userList.data',[]);
      const addedManagers = _.get(nextProps,'addedManagers');
      if(nextUserList.length>0&&!addedManagers){
          updateUserList(nextUserList,'addedManagers');
      }
  }

  changeSelect(id, type, checked){
    const {managers, p_managers,removeFromSelectedMem,addToSelectedMem} = this.props
    const modified_managers = Object.assign([],managers)
    const modified_p_managers = Object.assign([],p_managers)
    if(checked){
      if(type === 'member'){
        _.remove(modified_managers, (v)=>v===id)
        removeFromSelectedMem(id);
      }
      _.remove(modified_p_managers, (v)=>v===id)
    }
    else{
      if(type === 'primary'){
        modified_p_managers.push(id)
      }
      modified_managers.push(id)
      addToSelectedMem(id);
    }

    this.props.changeManager(modified_managers, modified_p_managers)
  }


  getTableProps(){
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, userList } = data;
    const teamId = _.get(this,'props.teamData.id','');

    return {
      title : `Care Managers (${_.get(userList, 'pageInfo.total', 0)})`,
      columns : [
        {
          title : ' ',
          key : 'row_s',
          width : '40px',
          render : (t, doc)=>{
              const memberId =  doc._get('id');
              const checked = _.includes(this.props.managers, memberId);
              return <IHCheckbox checked={checked}
                                 onChange={()=>{
                                     Client.mutate({
                                         mutation: !checked ? addTeamMember : removeTeamMember,
                                         variables:{
                                             teamId,
                                             memberIds:[doc._get('id')]
                                         }
                                     }).then(()=>{
                                         this.changeSelect.bind(this, doc._get('id'), 'member', checked)();
                                     }).catch(e=>{
                                         console.log(e);
                                     })
                                 }}/>
          },
          // Client requires select doctor/managers to be display on top of the list
          sorter: (a, b) => {
            return (_.includes(this.props.managers, a._get('id')) ? 1 : 0) - (_.includes(this.props.managers, b._get('id')) ? 1 : 0)
          },
          sortOrder: 'descend'
        },
        {
          title : 'Name',
          key : 'Name',
          render : (t, doc)=>{
            return doc._get('profile.fullName')
          }
        },
        {
          title : 'Specialty',
          key : 'Specialty',
          render(t, doc){
            return doc.format('specialty')
          }
        },
        {
          title : 'Contact Number',
          key : 'Contact Number',
          render(t, doc){
            return doc.format('mobilePhone')
          }
        },
        // {
        //   title : 'Mark as Primary',
        //   key : 'Mark as Primary',
        //   render : (t, doc)=>{
        //     const checked = _.includes(this.props.p_managers, doc._get('id'))
        //     return <IHCheckbox checked={checked} onChange={this.changeSelect.bind(this, doc._get('id'), 'primary', checked)} />
        //   }
        // }
      ],
      pagination: {
        pageSize: 5
      },
      dataSource: _.map(_.get(userList, 'data', []) , (u) => new UserClass(u)),
      inputPlaceholder: 'Search by Name',
      autoFocus: false,
      onSearch: onTableSearch,
      onChange: onTableChange,
      onSearchEnter,
      initSearchValue : search,
      loading
    }
  }

  runOnceAfterDataLoading(np){
    const { teamData } = this.props

    if(teamData){
      const team = new CareTeamClass(teamData)
      const members = team._get('members')
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
      const organizationId = _.get(currentUser,'selectedRole.organization.id','');
      const managers = []
      _.each(members, (u)=>{
          const memberAllRoles = u.user.allRoles;
          const roleObject = _.find(memberAllRoles,(d)=>d.organization.id===organizationId);
          if(roleObject.category === 'PROVIDER' && 
            (roleObject.name === 'Nurse' || roleObject.name === 'Medical Assistant' || roleObject.name === 'Clinic Medical Assistant')) {
              managers.push(u.user.id)
        }
      })

      const pm = team.format('primaryManagers')
      const p_managers = _.map(pm, (p)=>p._get('id'))
      np.changeManager(managers, p_managers)
    }
  }

  componentDidMount(){
    this.runOnceAfterDataLoading(this.props);
  }

}

const tableActions = createTableAction('admin_careteam_manager_list');

const withData = graphql(userListGQL, {
  withRef : 'component',
  options : (ownProps)=>{
    return {
      variables : {
        page : 1,
        count : 9999, //Not using server pagination because client wants selected doctors/managers to be display on top
        filters : {
          roles : [ getRefIDWithRoleCategory('PROVIDER:Nurse'), getRefIDWithRoleCategory('PROVIDER:Medical Assistant'), getRefIDWithRoleCategory('PROVIDER:Clinic Medical Assistant') ],
          search: {
            fields: ['NAME'],
            match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
          }
        },
        sort : {}
      },
      fetchPolicy:'network-only'
    }
  }
})

const mapDispatchToProp = (dispatch) => {
  return {
    ...tableActions,
    changeManager: (managers, p_managers) => dispatch(actions.changeManager(managers, p_managers)),
  }
}


export default compose(
  connect(
    (state)=>{
      const param = state.Careteam.CareTeamEdit
      return {
        ...param,
        ...state.globalTables.admin_careteam_manager_list,
      }
    }
    , mapDispatchToProp),
  withData,
)(Component);
