import { combineReducers } from 'redux';
import type from '../constants';

const INITIAL_STATE = {
    status:false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {

    switch (action.type) {

        case type.IS_UPDATED:
            return {
                ...state,
                status:action.payload.isUpdated
            }

        default:
            return state;
    }

}


export default {
    medicineList: combineReducers({
        main: reducer
    })
};
