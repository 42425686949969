import React, { cloneElement, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input } from 'antd';
import Client from 'libModule/gqlClient';

const { Option } = AutoComplete;

const fetchData = async (queryOptions, cb) => {
  let res = {};
  try {
    res = await Client.query(queryOptions);
  } catch (error) {
    console.error(error);
  }
  if (cb) cb(res);
};

const defaultProps = {
  queryOptions: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
}

const AutoCompleteComponent = props => {
  let { queryOptions, autocompleteProps, processDataSource } = props;
  // 20200714 Thong - functionalComponent.defaultProps didn't work as expected
  queryOptions = { ...defaultProps.queryOptions, ...queryOptions };
  const {
    skip = false,
  } = queryOptions;
  const {
    value, // omit this
    children,
    renderInput,
    ...restAutoCompleteProps
  } = autocompleteProps;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (!skip) {
      setIsLoading(true);
      fetchData(queryOptions, (res) => {
        setData(res.data);
        setIsLoading(false);
      })
    }
  }, [JSON.stringify(queryOptions)]);

  useEffect(() => {
    if (!value) {
      setData([]);
    }
  }, [value]);

  const autoCompleteChildren = useMemo(() => {
    let InputElement = children;
    // render customized input
    if (typeof renderInput === 'function') {
      InputElement = renderInput(isLoading);
    }
    return InputElement;
  }, [children, renderInput, isLoading]);


  return (
    <AutoComplete
      {...restAutoCompleteProps}
      dataSource={!isLoading ? processDataSource(data, Option) : []}
      notFoundContent={!isLoading ? 'No match' : ''}
    >
      {autoCompleteChildren}
    </AutoComplete>
  );
};

AutoCompleteComponent.propTypes = {
  queryOptions: PropTypes.shape({
    query: PropTypes.object.isRequired,
    variables: PropTypes.object.isRequired,
    notifyOnNetworkStatusChange: PropTypes.bool,
    fetchPolicy: PropTypes.string,
    errorPolicy: PropTypes.string
  }),
  autocompleteProps: PropTypes.object.isRequired,
  processDataSource: PropTypes.func.isRequired,
};

export default AutoCompleteComponent;
