import {
  React,
  BaseComponent,
} from 'ihcomponent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Tooltip } from 'antd';
import { browserHistory } from 'react-router';
import UserClass from 'graphqlModule/class/User';
import { compose } from "react-apollo/index";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { changeMenuStats } from '../../layout/actions/Nav';
import { helpers } from '../../visitNewWorkFlow/helper/index';
import '../css/taskList.scss';
import { UNIFIED_CARE_DEFAULT_LOGO } from '../../../lib/constants';
import withPollNotificationsByRole from '../constants/pollNotifications';
import { goPath } from 'libModule/utils';

const Component = class extends BaseComponent{
  static displayName = 'common/components/VSMLeftNav'
    init(){
        this.toggleExpand = this.toggleExpand.bind(this);
        this.throttledResize = this.throttledResize.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
        this.checkNewUnreadMsgs = this.checkNewUnreadMsgs.bind(this);
        this.timerId = null;
        this.state = {
            msgCount: 0,
            select : null,
            menu: [
              ...this.props.menu,
              {
                 id: 'expandToggle',
                 icon: 'right',
                 class: 'expand',
                 onClick: this.toggleExpand
              }
            ],
        }
    }

    componentDidMount() {
      window.addEventListener("resize", this.throttledResize, false);
      if(!helpers.checkIsMAOrMD()){
        this.toggleExpand();
      } else {
        this.props.changeMenuStats(false)
      }
    }

    componentWillUnmount() {
      clearInterval(this.timerId);
    }

    throttledResize() {
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(function() {
           this.resizeTimeout = null;
           this.resizeHandler();
         }.bind(this), 66);
       }
    }

    resizeHandler() {
      if (window.innerWidth < 1250) {
        let vsmLNavClArr = document.querySelector('.VSM-LeftNav');
        if (vsmLNavClArr != null && vsmLNavClArr.classList.contains('VSM-LeftNav-expanded')) {
          this.toggleExpand();
        }
      }
    }

    render(){
      // if(this.props.current) {
      //   let cur = this.props.curPath.split('/');
      //   if(cur[cur.length - 1] == 'single') {
      //     this.state.select = this.props.curPage == 'patient_profile' ? "inactive_patients" : "patients";
      //   } else {
      //     this.state.select = this.props.current;
      //   }
      // }
      return (
        <div className="VSM-LeftNav">
            {this.renderLogo()}
            {this.renderMenus()}
        </div>
      )
    }

    renderLogo(){
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const selectedRole = _.get(currentUser,'selectedRole',{});
        const { logo,brandedName } = _.get(selectedRole,'organization',{});
        const defaultLogo = UNIFIED_CARE_DEFAULT_LOGO;
        return (
            <div className="v-logo logo-hidden">
                <div className="clickable" onClick={() => goPath('/')}>
                    <img src={logo} onError={(e)=>{e.target.onerror = null; e.target.src= defaultLogo }}/>
                    {/*<img src={logo.src} style={{'width':'30px','height':'30px'}} alt={ logo.name }/>*/}
                    <div className="logoHeader">
                        <span className='logoName'> { brandedName } </span>
                        <span className='poweredBy'> Powered by iHealth Unified Care </span>
                    </div>
                </div>
            </div>
        )
    }

    renderBadge(Badge) {
      return (
        <div className="v-badge">
            {Badge}
        </div>
      )
    }

    toggleExpand() {
      document.querySelector('.VSM-LeftNav').classList.toggle('VSM-LeftNav-expanded');
      document.querySelector('#master').classList.toggle('pushed');
      document.querySelector('.vsm-top-nav').classList.toggle('pushed');
      document.querySelector('.v-logo').classList.toggle('logo-hidden');
      const menu = this.state.menu;
      const newMenu = menu.map( (m) => {
        if (m.id === 'expandToggle') {
          const newClass = m.class === 'expand' ? 'collapse' : 'expand';
          const newIcon = m.icon === 'right' ? 'left' : 'right';
          return {
            ...m,
            class: newClass,
            icon: newIcon
          }
        }
        return m;
      });
      this.setState({ menu: newMenu });
      const isToggled = document.querySelector('.VSM-LeftNav-expanded');
      this.props.changeMenuStats(!!isToggled)
    }

    renderMenus(){
        const userPermissions = UserClass.getConfigPermissions();
        const items = _.filter(this.state.menu, ({ orgPermissionKey, globalConfigKey }) => {
          if (orgPermissionKey && !userPermissions.orgPermissions[orgPermissionKey]) {
            return false;
          }
          if (globalConfigKey) {
            const config = _.find(userPermissions.globalConfigs, (o) => {
              return o.configKey === globalConfigKey;
            }) || {};
            if ((config.value || '').toLowerCase() === 'false') {
              return false;
            }
          }
          return true;
        });
        return (
            <div className="v-menu">
                {
                    _.map(items, (item)=>{
                        return this.renderEachMenu(item)
                    })
                }

            </div>
        )
    }

    checkNewUnreadMsgs() {
        const { selectedRole } = JSON.parse(sessionStorage.getItem('currentUser'));
        const id  = _.get(selectedRole,'organization.id');
        const { OrgHasUnread } = this.props;

        return _.get(OrgHasUnread,`${btoa(atob(id).split(':')[1])}`,0);
    }

    renderEachMenu(item){
        const curPage = this.props.curPage;
        const select = item.id===curPage ?'active':''
        const alertCount = _.get(this, 'props.smartAlertsCount');
        const unReadTaskCount = _.get(this, 'props.unreadTaskCount');
        const unresolvedWorkStationTaskCount = this.props.unresolvedWorkStationTaskCount;
        const alertsFlag = alertCount > 0 ; // display red dot on alerts, if there are alerts
        const foodlogUnreadFlag = _.get(this, 'props.foodlogUnreadCount') > 0;
        const openTasksFlag = _.get(this, 'props.openTaskCount') > 0;
        const newChatMsgFlag = this.checkNewUnreadMsgs();
        const notificationSetting = JSON.parse(sessionStorage.getItem('notificationFreq'))
        const showOrgRedDot = notificationSetting === 'NOTIFY_ALWAYS' && newChatMsgFlag;
        const title = item.title === 'Alerts' ? `Patients with alerts: ${alertCount}` : item.title;
        const menuOpened = this.props.menuOpened;

        return (
            <nav onClick={this.handleOnClick.bind(this, item)} className={`v-item ${select} ${item.class}`} key={item.id}>
                <div className='icon-container'>
                  {
                    item.id === 'expandToggle' ?
                      <Tooltip title={menuOpened ? '' : 'Expand'} placement='right'>
                        <Icon type={menuOpened ? 'left' : 'right'} className='leftnav-icon' />
                      </Tooltip>
                    :
                      <Tooltip title={!menuOpened ? title : ''} placement='right'>
                        <div className={item.id === 'provideralert' && alertsFlag ? 'red-circle-chat' : ''}></div>
                        <div className={item.id === 'messages' && showOrgRedDot ? 'red-circle-chat' : ''}></div>
                        <div className={item.id === 'foodlog' && foodlogUnreadFlag ? 'red-circle-chat' : ''}></div>
                        <div className={item.id ==='taskAssignments'&& openTasksFlag ? 'red-circle-chat':''}></div>
                        <div className={item.id ==='workstation' && unresolvedWorkStationTaskCount > 0 ? 'red-circle-chat':''}></div>
                          {
                          item.src ? <img className='inactive-patient-icon' src={item.src} id={item.title}/> :
                          (
                            React.isValidElement(item.icon)
                              ? item.icon
                              : <i className={`vsm-icon icon-${item.icon}`} />
                          )
                        }
                      </Tooltip>
                  }
                </div>
                <div className='icon-container collapse_icon'>
                    {
                        item.id === 'expandToggle' ?
                        <Tooltip title={menuOpened ? '' : 'Expand'} placement='right'>
                          <Icon type={menuOpened ? 'left' : 'right'} className='leftnav-icon' />
                        </Tooltip>
                            :
                            <Tooltip title={!menuOpened ? title : ''} placement='right'>
                                <div className={item.id === 'provideralert' && alertsFlag ? 'red-circle-chat' : ''}></div>
                                <div className={item.id === 'messages' && showOrgRedDot ? 'red-circle-chat' : ''}></div>
                                <div className={item.id === 'foodlog' && foodlogUnreadFlag ? 'red-circle-chat' : ''}></div>
                                <div className={item.id ==='taskAssignments' && openTasksFlag ? 'red-circle-chat':''}></div>
                                <div className={item.id ==='workstation' && unresolvedWorkStationTaskCount > 0 ? 'red-circle-chat':''}></div>
                                {
                                    item.src ? <img className='inactive-patient-icon' src={item.src} id={item.title}/> :
                                        (
                                            React.isValidElement(item.icon)
                                                ? item.icon
                                                : <i className={`vsm-icon icon-${item.icon}`} />
                                        )
                                }
                            </Tooltip>
                    }
                </div>
                <b>{item.title}</b>
                <p className='unReadMsg'>{(item.id === 'taskAssignments' && menuOpened && unReadTaskCount > 0) ? unReadTaskCount + ' New' : ''}</p>
                {this.renderBadge(item.Badge)}
            </nav>
        )
    }

    handleOnClick(item){
      const {handleChangePage, curPage} = this.props;
      if(item.isLink && item.href) {
        window.open(item.href)
      }
      else if(item.href){
          browserHistory.push(item.href)
      }
      else if(item.onClick){
          item.onClick(item)
      }
      if(curPage == null) {
        handleChangePage('dashboard');
        Mixpanel.time_event('dashboard');
      }

      if(curPage != item.id) {
        Mixpanel.calculateDuration(curPage);
        handleChangePage(item.id);
        Mixpanel.time_event(item.id);
      }
      Mixpanel.track('clicked',item.id,'menu');
    }
}

Component.propTypes = {
    /*
     * {id, title, icon, href||onClick}
     */
    menu : PropTypes.array,

    /*
     * {src, href}
     */
    logo : PropTypes.object,
    //channels: PropTypes.object
}

const mapState = (data, ownProps) => {
  return {
    // channels: chat.main.channels,
      OrgHasUnread: data.chat.main.OrgHasUnread,
      menuOpened: data.Nav.menuOpened
  }
};

const mapDispatch = dispatch => {
    return {
        changeMenuStats: (menuOpened) => dispatch(changeMenuStats(menuOpened))
    }
};

const PolledComponent = withPollNotificationsByRole(Component);

export default compose(
  connect(mapState, mapDispatch),
)(PolledComponent);
