export default {
    yearsWithCondition:'Disease History:',
    comments:'Comments:',
    seenDoctorInLastSixMonth:'Seen doctor in the past 6 mos:',
    'specialistInfo.seenSpecialist': 'See specialist for this condition:',
    'specialistInfo.contact': 'Specialist Contact:',
    testRegularly: 'Measure at home:',
    freq:'How Often?',
    unit:'Frequency:',
    treatments:'What do you use to treat:',
}
// export default {
//     COPD:{
//
//     }
//
// }
