import gql from 'graphql-tag';
import VisitSchema from '../schema/Visit';

export default gql`
mutation updateVisitWorkingList(
  $id:EID!,
  $maWorkingList:VisitMAWorkingListInput
  $rdWorkingList:VisitRDWorkingListInput
){
  updateVisitWorkingList(
    id:$id,
    maWorkingList:$maWorkingList, 
    rdWorkingList:$rdWorkingList
  ){
    ${VisitSchema}
  }
}`;
