import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, Button, DatePicker, Input, Modal, Select, message, Spin } from 'antd';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import moment from 'moment';
import I18N from '../../I18N';

import ReferAndEnrollForm from './ReferAndEnrollForm';
import REConsentFormPreview from './REConsentFormPreview';
import ConsentFormLanguageSelector from '../../patient/main/components/consentForm/ConsentFormLanguageSelector';
import ConsentFormTemplate from '../../patient/main/components/consentForm/ConsentFormTemplate';

import { helpers as visitHelpers } from '../../visit/helpers'; 
import { CONSENT_TYPE, CONSENT_DATE_FORMAT, getConsentTypeOptions } from '../../patient/main/constants';
import { CONSENT_FORM_KEYS as KEYS, consentFormConstants } from '../constant';

import { getPatientConsent, getMostRecentEP } from '../API/query';
import reducerActions from '../../VideoChat/action/reducerActions';
import consentFormHelpers from '../helper/consentFormHelpers';
import enrollMemberHelper from '../../../lib/utils/enrollMember';
import submitConsentFormHelper from '../../../lib/utils/submitConsentForm';
import { upsertPatientReferralMutation } from '../API/mutation';
import { STATUS_ENUM } from '../constant';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import { updateReferStatus } from '../helper'


import '../style/reConsentForm.scss';

const getConsentDate = (referredAt) => consentFormHelpers.validateReferredDate(referredAt) ? moment(referredAt) : moment();

const REConsentFormContainer = (props) => {
  const { 
    onSubmitDone, disabled, goNextStep,
    form, user, patientReferral, 
    loadingConsentInfo, consentInfo, setConsentInfo,
    mostRecentEP, isEnrolled, loadingMostRecentEP,
    transitionFromRefer,isValueBasedOrg
  } = props;
  const { recommendedVitals, clinicGoals, referredAt, conditionsToMonnitor, deviceAccessbility } = patientReferral || {};

  if(loadingConsentInfo || loadingMostRecentEP)
    return null;

  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;

  const userPrimaryDoctorId = _.get(user, 'profile.doctor.id');
  const userLanguage = useMemo(() => {
    const appLanguage = _.get(user, 'profile.appLanguage.code');
    const spokenLanguage = _.get(user, 'profile.language.code');
    return { 
      value: appLanguage || spokenLanguage || 'EL',
      groupId: null
    };
  }, [user.id]);

  const shouldUpdateReferStatus = transitionFromRefer && _.get(patientReferral, 'status.enrollStatus') !== STATUS_ENUM.COMPLETED;

  const [__, setFormRendered] = useState(false);
  const [language, setLanguage] = useState(userLanguage || consentFormConstants.DEFAULT_CONSENT_LANGUAGE);
  const [isSendingConsent, setIsSendingConsent] = useState(false);
  const [header, setHeader] = useState(null);
  const [loadingUpdateReferStatus, setLoadingUpdateReferStatus] = useState(shouldUpdateReferStatus);

  useEffect(() => {
    if(shouldUpdateReferStatus) {
      updateReferStatus(props, () => setLoadingUpdateReferStatus(false));
    }
    setFormRendered(true);
  }, []);

  const currentUserInfo = consentFormHelpers.getCurrentUserInfo();
  const currentUserFullName = currentUserInfo.fullName;
  const phoneNumbers = _.get(user, 'phone', []).map(p => '+' + p.countryCode + p.number);
  const callInfo = consentFormHelpers.getCallInfo(currentUserInfo, phoneNumbers);

  const [autoCareTeam] = useState(consentFormHelpers.getCareTeam(userPrimaryDoctorId, currentUserInfo.teams));
  const doctorOptions = useMemo(() => visitHelpers.filterDoctors({ shortName: false }) || [], [currentUserInfo]);
  const doctorsNames = _.filter(doctorOptions, ({ id }) => _.includes(providers, id)).map(p => p.fullName);

  const [consentTypeOptions, defaultConsentType] = useMemo(() => {
    let allOptions = getConsentTypeOptions(consentFormConstants.RE_CONSENT_TYPE_MAP);
    const disableOptions = [];

    if(!deviceAccessbility)
      disableOptions.push(CONSENT_TYPE.APP);

    if(disableOptions.length)
      allOptions = _.map(allOptions, (o, idx) => {
        if(_.includes(disableOptions, o.value))
          o.disabled = true;
        return o;
      });

    const defaultOption = _.filter(allOptions, o => !o.disabled)[0] || {};

    return [allOptions, defaultOption.value];
  }, [deviceAccessbility]);
  
  const prevConsentType = _.get(consentInfo, 'consentType'),
        prevConsentDate = _.get(consentInfo, 'effectiveDate') ? moment(_.get(consentInfo, 'effectiveDate')) : null,
        prevProviders = _.get(consentInfo, 'providers'),
        memberId = _.get(user, 'id'),
        memberFullname = _.get(user, 'profile.fullName'),
        consentProviderName = _.get(autoCareTeam, 'consentProviderName'),
        effectiveDate = getConsentDate(referredAt);
        
  const { consentType, consentDate, providers } = getFieldsValue() || {};
        
  const handleSubmitConsent = useCallback(_.debounce(() => {
    form.validateFieldsAndScroll(async (errors) => {
      if(errors) {
        if(errors.template && Object.keys(errors).length === 1)
          message.error(I18N.get('referAndEnroll.patientConsent.invalidTemplate'));
        return;
      }

      setIsSendingConsent(true);
      let enrolledProgram = mostRecentEP,
          hasEnrollError = false;
      if(!isEnrolled) { 
        // enroll patient if unenrolled
        await enrollMemberHelper({ 
          user, 
          selectedVitalTypes: recommendedVitals,
          selectedCareTeam: autoCareTeam,
          onSuccess: res => {
            enrolledProgram = _.get(res, 'data.enrollMember');
          },
          onError: error => {
            hasEnrollError = true;
            message.error(error);
          }
        });
        // if(hasEnrollError)
        //   return;
      }

      const values = {
        ...getFieldsValue(),
        consentProviderName,
        effectiveDate
      };

      // send patient consent regardless of status
      await submitConsentFormHelper({ 
        user, 
        values,
        needRefetch: false,
        onSuccess: async (res) => {
          const isResend = !_.isEmpty(consentInfo);
          const consentSuccessText = `Consent form has been ${consentType === CONSENT_TYPE.APP ? 'sent' : 'submitted'}`;
          const titleText = !isEnrolled && !hasEnrollError ? 'Great! Patient has been successfully enrolled!' : consentSuccessText;

          if(isResend) {
            message.success(consentSuccessText);
            if (res && setConsentInfo)
              setConsentInfo(memberId, res);
            onSubmitDone();
            return;
          }
          // mostRecentEP can be Discharged, and enrollMember can fail, ie. if no recommendedVitals selected
          if(!hasEnrollError) {
            consentFormHelpers.syncClinicGoals(_.get(enrolledProgram, 'id'), clinicGoals);
          }
          consentFormHelpers.syncAccounts(user, patientReferral, enrolledProgram, res);
          consentFormHelpers.sendAppDownloadLink(memberId, _.get(user, 'phone'));

          const patientId = _.get(user, 'id');

          if(!_.get(user, 'remoteEnrollment')) {
            await remoteEnrollmentApis.createRE({ 
              memberId: patientId,
              doctorReferral: true,
              conditionsList: dataHelper.handleConditionsList(conditionsToMonnitor)
            }).catch(console.error);
          } else {
            consentFormHelpers.syncConditionsList(user, patientReferral);
          }
          await remoteEnrollmentApis.moveRemoteEnrollmentHandler({
            memberId: patientId,
            status: 'CONFIRMED'
          }).catch(console.error);

          let uprVariables = {
            patientId,
            status: {
              enrollStatus: STATUS_ENUM.COMPLETED
            }
          };

          await upsertPatientReferralMutation(uprVariables);
          
          Modal.success({
            className: 'refer-enroll-consent-form-success-popup',
            content: <div className='refer-enroll-consent-form-success-popup-content'>
              <div className='title'>
                {titleText}
              </div>
              <div className='instruction'>
                Patient will receive a text message with Unified Care App downloading link. Please assist patient to download and install the App.
              </div>
            </div>,
            okText: 'Got it',
            onOk: () => {
              message.success(consentSuccessText);
              if(res && setConsentInfo)
                setConsentInfo(memberId, res);
              onSubmitDone();
            }
          });
        },
        onError: (err) => {
          setIsSendingConsent(false);
          Modal.error({ content: err.message });
        }
      });
    });
  }, 500), []);

  const renderProviderInput = () => {
    return (
      <Form.Item 
        className='consent-form-provider-label' 
        label={I18N.get('consentForm.label.provider')} 
        colon={false}
      >
        {
          getFieldDecorator(KEYS.providers, {
            initialValue: _.map(prevProviders, 'id'),
            rules: [{
              required: !consentProviderName,
              type: 'array',
              message: I18N.get('consentForm.error.provider')
            }]
          })(
            <Select
              mode='multiple'
              placeholder='Please select'
              style={{ width: consentFormConstants.CONSENT_INPUT_WIDTH }}
              disabled={disabled}
            >
              { 
                doctorOptions.map(d => (
                  <Select.Option value={d.id} key={d.id}>{d.fullName}</Select.Option>
                ))
              }
            </Select>
          )
        }
      </Form.Item>
    );
  };

  const renderCareTeamInput = () => {
    return (
      <div className='consent-form-device-initials'>
        <strong>{I18N.get('consentForm.label.device.legalStatement')}</strong>
        <Form.Item 
          className='consent-form-device-label' 
          label={I18N.get('consentForm.label.device.text')} 
          colon={false}
        >
          {
            getFieldDecorator(KEYS.careTeamSignature, {
              initialValue: '',
              rules: [{ 
                required: true, 
                whitespace: true, 
                message: I18N.get('consentForm.error.device') 
              }]
            })(<Input 
              style={{ width: consentFormConstants.CONSENT_INPUT_WIDTH }}
              disabled={disabled}
            />)
          }
        </Form.Item>
      </div>
    );
  };

  const renderConsentDateInput = () => {
    const isPaperConsent = consentType === CONSENT_TYPE.PAPER;

    const label = isPaperConsent ? 
                  I18N.get('consentForm.label.paper.consentDate') :
                  I18N.get('consentForm.label.verbally.consentDate');
    return (
      <Form.Item 
        className='consent-form-date-label' 
        label={label} 
        colon={false}
      >
        {
          getFieldDecorator(KEYS.consentDate, {
            initialValue: prevConsentDate || (consentType === CONSENT_TYPE.VERBALLY ? getConsentDate(referredAt) : null),
            rules: [{ 
              required: true, 
              message: I18N.get(`consentForm.error.${_.toLower(consentType)}.consentDate`) 
            }]
          })(
            <DatePicker
              style={{ width: consentFormConstants.CONSENT_INPUT_WIDTH }}
              format={CONSENT_DATE_FORMAT}
              disabled={disabled}
            />
          )
        }
      </Form.Item>
    );
  };

  const renderConsentInput = useCallback(() => {
    const isResendApp = !_.isEmpty(consentInfo) && prevConsentType === CONSENT_TYPE.APP;

    const renderNonAppSubmitButton = (buttonStyle) => {
      return <Button 
        loading={isSendingConsent}
        disabled={isSendingConsent || disabled}
        type='primary' 
        onClick={handleSubmitConsent}
        className='submit-non-app'
        style={buttonStyle}
      >
        Submit
      </Button>
    };

    const renderSendAppLinkButton = () => {
      return <Button 
        type='link'
        onClick={async () => {
          const isSent = await consentFormHelpers.sendAppDownloadLink(_.get(user, 'id'), _.get(user, 'phone'));
          if(isSent) {
            message.success(I18N.get('referAndEnroll.patientConsent.appLinkSent'));
          } else {
            const errorMsg = isSent === null ?
                            I18N.get('referAndEnroll.patientConsent.appLinkNoPhone')
                          : I18N.get('referAndEnroll.patientConsent.appLinkNotSent');
            message.error(errorMsg);
          }
        }}
        disabled={disabled}
      >
        {I18N.get('referAndEnroll.patientConsent.appLink')}
      </Button>
    };

    switch(consentType) {
      case CONSENT_TYPE.VERBALLY:
        return <React.Fragment>
          {renderConsentDateInput()}
          {renderNonAppSubmitButton()}
        </React.Fragment>;
      case CONSENT_TYPE.TEAM_MEMBER_DEVICE:
        return <React.Fragment>
          {renderCareTeamInput()}
          {renderNonAppSubmitButton({ marginLeft: -20 })}
        </React.Fragment>;
      case CONSENT_TYPE.PAPER:
        return <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {renderProviderInput()}
            <div style={{ marginTop: 5 }}>
              {renderConsentDateInput()}
            </div>
          </div>
          {renderNonAppSubmitButton()}
        </React.Fragment>;
      case CONSENT_TYPE.APP:
        return <React.Fragment>
          <Button 
            loading={isSendingConsent}
            disabled={disabled || isSendingConsent} 
            type='primary' 
            style={{ width: 269 }} 
            onClick={handleSubmitConsent}
          >
            {
              !isResendApp ?
                I18N.get('referAndEnroll.patientConsent.submitAppText')
              : I18N.get('referAndEnroll.patientConsent.resubmitAppText')
            }
          </Button>
          {
            !isResendApp ?
              <div className='consent-app-buttons-note'>
                {I18N.get('referAndEnroll.patientConsent.submitAppNote')}
              </div>
            :
              renderSendAppLinkButton()
          }
        </React.Fragment>;
      default:
        if(_.isEmpty(consentInfo))
          return null;
        return (
          <div>
            <Button
              type='primary'
              onClick={goNextStep}
            >
              Next
            </Button>
            {renderSendAppLinkButton()}
          </div>
        );
    }
  }, [disabled, isSendingConsent, consentType, consentInfo]);

  return loadingUpdateReferStatus ? 
  <Spin />
  :
  (
    <ReferAndEnrollForm 
      header={header || ''} 
      onSubmit={console.log} 
      buttons={
        <div id='refer-enroll-consent-form-button-wrapper'>
          <div className='consent-input'>
            {renderConsentInput()}
          </div>
        </div>
      }
    >
      {
        !_.isEmpty(consentInfo) ?
          <REConsentFormPreview 
            memberId={_.get(user, 'id')} 
            consentInfo={consentInfo}
            doctorNames={doctorsNames}
            setHeader={setHeader}
          />
        : (
          <div id='refer-enroll-consent-form'>
            <ConsentFormLanguageSelector 
              labelText='Choose consent form language'
              prefixCls='refer-enroll'
              language={language.value}
              setLanguage={(value, groupId) => setLanguage({ value, groupId })}
              isDisabled={disabled}
              isValueBasedOrg={isValueBasedOrg}
            />
            <div className='consent-form-type-options'>
              <span>Select method</span>
              <Form.Item>
                {
                  getFieldDecorator(KEYS.consentType, {
                    initialValue: prevConsentType || defaultConsentType,
                    rules: [{ 
                      required: true, 
                      message: I18N.get('consentForm.error.consentType') 
                    }]
                  })(<Radio.Group
                    options={consentTypeOptions}
                    disabled={disabled}
                  />)
                }
              </Form.Item>
            </div>
            {
              consentType !== CONSENT_TYPE.PAPER ?
              getFieldDecorator(KEYS.template, {
                initialValue: null,
                rules: [{
                  validator: (__, value, cb) => {
                    if(!value || !value.isValid)
                      return cb('invalid template');
                    cb();
                  }
                }]
              })(
                <ConsentFormTemplate
                  language={language.value}
                  languageGroupId={language.groupId}
                  consentProviderName={consentProviderName}
                  patientId={memberId}
                  patientName={memberFullname}
                  doctorsNames={doctorsNames}
                  effectiveDate={(consentDate || effectiveDate).clone().startOf('day')}
                  consentType={consentType}
                  careTeamFullname={currentUserFullName}
                  callInfo={callInfo}
                  onChange={template => setFieldsValue({ [KEYS.template]: template })}
                  openErrorModal={console.error}
                />
              ) 
              : (
                <div id='consent-template'>
                  No available preview for Paper consent form
                </div>
              )
            }
          </div>
        )
      }
    </ReferAndEnrollForm>
  );
};

REConsentFormContainer.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapToProps = (state, props) => {
  const consentInfoStatus = state.VideoChat.main.consentInfo[_.get(props, 'user.id')] || {};
  return {
    consentInfo: {...props.consentInfo, ...consentInfoStatus}
  };
};

const mapToDispatch = dispatch => ({
  setConsentInfo: (memberId,consentInfo) => dispatch(reducerActions.setConsentInfo(memberId,consentInfo))
});

export default Form.create({})(
  compose(
    getPatientConsent,
    getMostRecentEP,
    connect(mapToProps, mapToDispatch),
  )
  (REConsentFormContainer)
);