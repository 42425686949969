// Super Admin
import I18N from '../../../I18N';
import redirect from '../../helpers/auditTrailRedirect'

const menus = {
  AuditTrail: {
    id: 'audittrail',
    title: 'Audit Trail',
    icon: 'assessment',
    href: '/superadmin/audittrail',
    class:'audittrail'
    // onClick: redirect
  },
  UserTroubleshooting: {
    id: 'troubleshooting',
    title: 'User Troubleshooting',
    icon: 'bell',
    href: '/superadmin/troubleshooting',
    class:'UserTroubleshooting'
  },
  AppAnalytics: {
    id: 'appanalytics',
    title: 'App Analytics',
    icon: 'bar-chart',
    href: '/superadmin/appanalytics',
    class:'appanalytics'
  },
  // Providers: {
  //   id: 'providers',
  //   title: 'Providers',
  //   icon: 'peopled',
  //   href: '/superadmin/providers',
  //   class:'providers'
  // },
  SystemDynamicConfigs: {
    id: 'systemdynamicconfigs',
    title: 'System Dynamic Configs',
    icon: 'assessment',
    href: '/superadmin/systemdynamicconfigs',
    class: 'SystemDynamicConfigs'
  },
  ABTestingConfigs: {
    id: 'ABTestingConfigs',
    title: 'A/B Testing Configs',
    icon: 'assessment',
    href: '/superadmin/abtestingconfig',
    class: 'ABTestingConfigs'
  },
  // Settings: {
  //   id: 'providerSettings',
  //   title: 'Settings',
  //   src: '/image/settings-icon.png',
  //   href: '/providerSettings/change-password'
  // }
  Areas: {
    id: 'areas',
    title: I18N.get('keywords.menu.areas'),
    src: '/image/team_mag.png',
    href: '/superadmin/areas',
    class:'areas'
  },
  Staff: {
    id: 'users',
    title: I18N.get('keywords.menu.users'),
    icon: 'peopled',
    href: '/superadmin/users',
    class:'users'
  },
  ConsentForm: {
    id: 'consentform',
    title: I18N.get('keywords.menu.consentform'),
    icon: 'assessment',
    href: '/superadmin/consentform',
    class:'consent-form'
  }
};


const menusArray = Object.values(menus);

export default menusArray;

export const HOMEPAGE = '/superadmin/audittrail';
// export const HOMEPAGE = '/audit_trails'
