import React from 'react';
import RenderFormComponent from '../../AdditionalInfo/Components/RenderFormComponent';
import RenderDeviceInfo from '../../AdditionalInfo/Components/RenderDeviceInfo';
import { basicInfoConfig} from '../../AdditionalInfo/constants/basicInfo';
import MedicineContainer from '../../../medicine/containers/MedicineContainer';
import LabResultContainer from '../../../visitWorkList/container/LabAddAndListContainer';
import { getPatientInfoVariables, getMutateVariableMedicalHistory } from '../../AdditionalInfo/helpers/getMutateValueHelper';
import HealthCdContainer from '../containers/HealthConditionsContainer';

export const tabToComponent = (localThis) => {
    const {props, onSave} = localThis;
    const { selectedTab, user, form, isEditMode, setEditMode, phoneInfoList, userId, patientId, viewerId, consentInfo } = props;

    const maps = {
        'DETAIL':     <RenderFormComponent   user={user}
                                             form={form}
                                             config={basicInfoConfig('UNENROLLED')}
                                             handleEnableEditMode={setEditMode}
                                             onSave={onSave}
                                             disabled={!isEditMode}
                                             consentInfo={consentInfo}
                                             />,
        'ICDCODES':   <HealthCdContainer     user={user}
                                             form={form}
                                             patientId={patientId}
                                             isEditMode={isEditMode}
                                             setEditMode={setEditMode}
                                             onSave={onSave}/>,
        'LABRESULTS': <LabResultContainer    userId={userId}
                                             viewerId={viewerId}
                                             labResultIsDisabled={!isEditMode}
                                             setLabResStatus={setEditMode}/>,
        'MEDICATION': <MedicineContainer     patientId={patientId}/>,
        'DEVICES':    <RenderDeviceInfo      user={user}
                                             phoneInfoList={phoneInfoList} />
    }
    return maps[selectedTab];

};


export const tabToName = {
    'DETAIL': 'PATIENT DETAILS',
    'ICDCODES': 'ICD CODES',
    'LABRESULTS': 'LAB RESULTS',
    'MEDICATION': 'MEDICATION',
    'DEVICES': 'DEVICES',
}

export const getValueFunMap = {
    'DETAIL': getPatientInfoVariables,
    'ICDCODES': getMutateVariableMedicalHistory
}