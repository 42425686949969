import React from 'react';
import { compose } from 'react-apollo'
import { graphql } from "react-apollo/index";
import versionHistoryQuery  from  'modulesAll/graphql/versionHistory';
import _ from 'lodash';
import moment from 'moment';
import VersionHistoryModal from '../component/VersionHistoryModal';
import { Spin, Tooltip } from 'antd';

class VersionHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false
    };
  }

  showModal = async () => {
    try {
      await this.props.refetch();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isShowingModal: true });
    }
  }

  closeModal = () => {
    this.setState({ isShowingModal: false });
  }

  getMorePeopleTooltip = (VH) => {
    const people = _.get(VH, 'modifiedHistory', []).map(mh => _.get(mh, 'user.profile.fullName'));
    if (people.length === 0) { return null; }
    const lastPerson = _.last(people);
    const others = people.splice(0, people.length - 1);
    const peopleSet = _.uniq(others.filter(x => x !== lastPerson)) || [];
    if (peopleSet.length <= 0) { return null; }

    return (
      <span>&nbsp;and&nbsp;
        <Tooltip title={peopleSet.join(', ')}>
          {peopleSet.length} more
        </Tooltip>
      </span>
    )
  }

  render() {
    const { isShowingModal } = this.state;
    const { versionHistoryList, loading, enrolledProgramId } = this.props;

    if (loading) {
      return <Spin />;
    }

    const latest = versionHistoryList[0];
    
    return (
      <div style={{ fontStyle: 'italic' }}>
        {versionHistoryList && versionHistoryList.length > 0 && 
          <React.Fragment>
            Last updated: {moment(_.get(_.last(latest.modifiedHistory || []), 'date')).format('MM/DD/YYYY')},&nbsp;
            updated by {_.get(_.last(latest.modifiedHistory || []), 'user.profile.fullName')}{this.getMorePeopleTooltip(latest)}.&nbsp;
          </React.Fragment>
        }
        <a href="javascript:void(0);" onClick={this.showModal}>Version History</a>
        <VersionHistoryModal versionHistoryList={versionHistoryList} onClose={this.closeModal} visible={isShowingModal} enrolledProgramId={enrolledProgramId} />
      </div>
    );
  }
}

const withData = graphql(versionHistoryQuery, {
  options: (ownProps) => {
    const variables = { enrolledProgramId: ownProps.enrolledProgramId };
    return {
      variables,
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getVersionHistoryList, refetch } = data;

    if(getVersionHistoryList) {
      return {
        versionHistoryList: _.orderBy((getVersionHistoryList || []), (o) => { 
          return _.get(_.last(o.modifiedHistory), 'date')
        }, ['desc']),
        loading,
        refetch,
      }
    }

    return {
      loading
    }
  }
})

export default compose(withData)(VersionHistoryContainer)

