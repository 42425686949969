import update from 'immutability-helper'

import gql from 'graphql-tag'
export const extendEnrolledProgramGQL = gql`
  mutation extendEnrolledProgram($id: EID!, $endDate: Date!) {
    extendEnrolledProgram(id: $id, endDate: $endDate) {
      id
      name
      startDate
      endDate
    }
  }
`
export const extendEnrolledProgramUpdateQueries =
{
  enrolledProgram: (prev, { mutationResult: { data } }) => {
    const epChanges = data.extendEnrolledProgram;
    return update(prev, {
      enrolledProgram: {
          $merge: epChanges
      }
    })
  },
}
