import { connect } from 'react-redux';
import { SendIcon } from '../icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import actions from "../actions";
import * as timerActions from "../../timer/actions";
import UploadImgComponent from '../../upload/components/UploadImgComponent';
import helpers from '../../upload/API/helpers';
import API from '../../upload/API/index';
import { Icon, Input,message,Modal,Progress, Tooltip } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import AttachmentPreviewComponent from '../../upload/components/AttachmentPreviewComponent';
import { deviceHelpers } from '../../patient/main/helper';
import { getTranslate } from '../../translation/API';
import { LANGUAGE_MAP } from '../../translation/API/languageMap';
import chatPopoverHelper from '../helper/chatPopoverHelper';
import chatHelpers from '../helper';
import configs from '../../../lib/config';
import ChatSendArticleButtonComponent from './ChatSendArticleButtonComponent';

const { updateInterventionDetails,startChat } = timerActions.default;
const { TextArea } = Input;
const getBase64 = helpers.getBase64;

class ChatInputComponent extends Component {
  static displayName = 'chat/components/ChatInputComponent';

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.state = {
        fileList:[ ],
        sendingAttachment: false,
        filename: null,
        attachmentType: null,
        processingAttachment: false,
        processAttachmentProgress: 0,
        previewImage: null,
        // isNotAppUser: false,
        previewVisible:false,
        progress: 0,
        showUploadModal: false,
        fileKey:null,
        translation: {}
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }

  removeImg = () =>{
    this.setState({
        fileList:[],
        previewImage: null,
        previewVisible: false,
        attachmentType: null,
        fileKey: null,
        filename: null,
    })
  }

  beforeUpload = async (file)=>{
        const fileType= file.name.split('.')[1];
        const isLt10M = file.size / 1024 / 1024 < 10;

        if (!isLt10M) {
            Modal.error({
                content: 'Image must be smaller than 10MB',
            });
            return;
        }

        if(fileType.toLowerCase() =='jpeg'){
            Modal.error({
                content: 'jpeg format is not allowed',
            });
            return;
        }
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }
        const newFileList = new Array();
        newFileList.push(file);
        this.setState({
            fileList: newFileList,
            showUploadModal: true,
            attachmentType: fileType,
            filename: file.name,
        },()=>{
            Mixpanel.track('selected','image','message');
        });

  }

  componentDidMount() {
    // add prompt for hub users, CDE don't want to message nonApp users
    // this.checkIsAppUser()
  }

  componentDidUpdate(preProps) {
      const preSelectedChannel = _.get(preProps, 'selectedChannel');
      const curSelectedChannel = _.get(this, 'props.selectedChannel');

      if (preSelectedChannel != curSelectedChannel) {
          this.setState({
              previewImage: null,
              fileList: [],
              attachmentType: null,
              filename: null,
              fileKey: null,
              translation: {}
          })
          // add prompt for hub users, CDE don't want to message nonApp users
          // update each time when switch channels
          // this.checkIsAppUser()
      }
  }

  startImgProcessTimer = ()=>{
      const { processingAttachment,processAttachmentProgress } = this.state;

          if(processingAttachment) {
              this.setState({
                  processAttachmentProgress: processAttachmentProgress + 0.5
              },()=>{
                  setTimeout(this.startImgProcessTimer,1000);
              })
          };

  }

  renderModal =  ()=>{
      const { showUploadModal,previewImage,progress,fileList,processingAttachment,processAttachmentProgress, attachmentType } = this.state;
      const { props:{ userId,displayName} } = this;
      const userIdParsed = displayName.split(':')[1];
      const file = fileList[0];

      const handleOk = async ()=>{
          try {
              const fileKey = await this.saveImage({ userId:userIdParsed });
              this.setState({
                  processingAttachment: true,
              },()=> {
                  Mixpanel.track('uploaded','image','message');
                  this.startImgProcessTimer();
                  API.getFileUrl({
                    fileKey, 
                    userId: chatHelpers.getEncodedUserId(userId), 
                    type: 'THUMBNAIL'
                  }).then((res)=> {

                      this.setState({
                          previewVisible: false,
                          fileList: [],
                          showUploadModal: false,
                          fileKey,
                          progress: 0,
                          sendingAttachment: false,
                          processingAttachment: false,
                          previewImage: file.url || file.preview,
                          processAttachmentProgress: 0,
                      });

                      const link  = _.get(res,'data.getSignedFileDownload.link');
                      if(!link) {
                          Modal.error({ content:'Process takes longer than expected.'})
                      }
                  });
              });
          }catch(e){
              console.error(e);
              Modal.error({ content : 'Failed to upload the file. Please reload the page and try again!' });
              this.setState({
                  previewVisible: false,
                  fileList: [],
                  showUploadModal:false,
                  attachmentType: null,
                  filename: null,
                  previewImage: null,
                  progress: 0,
                  processingAttachment: false,
                  processAttachmentProgress: 0,
                  sendingAttachment: false
              });

              return;
          }
      }

      const handleCancel = ()=>{
          this.setState({
              previewVisible: false,
              fileList: [],
              showUploadModal: false,
              previewImage: false,
              filename: null,
              fileKey:null,
              attachmentType: null,
              progress: 0
          })
      }

      return showUploadModal ? <Modal visible={showUploadModal} onOk={ handleOk } onCancel={ handleCancel } maskClosable={false} okText={'Upload'} className='imageUploadModal'>
                {attachmentType === 'pdf'
                    ? <div>{file.name}</div>
                    : <img src={_.get(file,'url',_.get(file,'preview',''))} width={'100%'}/>
                }
                {
                    progress&&progress!=1 ? <div style={{ display:'flex',flexDirection:'row',marginTop:20,fontSize:14 }} className='uploadingBar'>
                                                <span style={{ marginRight: 10 }}>Uploading:</span> <Progress percent={progress*100} showInfo={false}/>
                                            </div> :''
                }
                {
                    processingAttachment ? <div style={{ display:'flex',flexDirection:'row',marginTop:20,fontSize:14 }} className='processingBar'>
                                        <span style={{ marginRight: 10 }}>Processing: </span><Progress showInfo={false} percent={processAttachmentProgress*10}/>
                                    </div>:''
                }
             </Modal> :'';
  }

  saveImage = async ({userId})=>{

      const { fileList, filename, attachmentType } = this.state;
      const file = fileList[0];

      if(!file){
          return;
      }
      this.setState({
          sendingAttachment: true
      });
      let res = {};
      try{
          res = await API.getPresignedFileUpload({ filename,userId });
      }
      catch(e){
          throw(e);
      }
      const ctx = {};
      const fields = _.isString(res.fields) ? JSON.parse(res.fields) : res.fields;
      const  mimeType = attachmentType ? ( `image/${_.toLower(attachmentType)}`) : 'image/jpg';
      fields['Content-Type'] = mimeType;
      ctx.fields = fields;
      const uploadUrl = res.url;
      let fileKey = {};

      try {
          fileKey = await API.sendAJaxReq({ uploadUrl, filename, mimeType, fields, file,setProgress:this.setProgress });
      }catch(e){
          throw(e);
      }

      return fileKey
  }


  async onSubmit(e) {
      if(e.shiftKey){
          return;
      }
    e.preventDefault();

    const { props:{ chatStarted,startChat,updateInterventionDetails,displayName,selectedChannel,sendMessage,userId } } = this;
    const { previewImage, translation } = this.state;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const { fileKey } = this.state;
    // const userIdParsed = displayName.split(':')[1];
    let message = translation.translatedText || this.props.testInputMap[this.props.selectedChannel] || '';

    if (message.trim().length === 0&&!previewImage) {
          return;
    }
    // let fileKey = '';
    // try{
    //     fileKey = await this.saveImage({userId:userIdParsed});
    // }
    // catch(e){
    //     Modal.error({ content : e });
    //     this.setState({
    //         message:'',
    //         previewImage: null,
    //         previewVisible: false,
    //         fileList: []
    //     });
    //     return;
    // }

    const publicS3bucket = configs.PUBLIC_S3_BUCKET;
    const userAvatarFileKey = _.get(currentUser, 'profile.avatar.fileKey');

    const userAvatarLink = userAvatarFileKey ? `${publicS3bucket}/${userAvatarFileKey}`: undefined;

    // for push notifications on iOS
    const pn_apns = {
        aps: {
          alert: 'You have a new message!',
          bigPictureUrl: userAvatarLink,
          sound: 'default',
          "mutable-content": 1
        },
        pn_bundle_ids: [
          'com.ihealthlabs.sharecare',
          'com.ihealthlabs.sharecarebp'
        ]
    };

    // Google Cloud Messaging / Firebase Cloud Messaging
    const pn_gcm = {
        data: {
          message: 'You have a new message!',
          bigPictureUrl: userAvatarLink,
          sound: 'default',
        }
    };

    let msgObj = {
        type: 'text',
        text: message ? `${message}` : 'Uploaded a file',
        publisher: userId,
        displayName: currentUser.profile.fullName,
        userRole: _.get(currentUser,'selectedRole.name') || 'Unknown',
        // userRole: currentUser.allRoles.length > 0 ? currentUser.allRoles[0].name : 'Unknown',
        // refactored from allRole[0] to selectedRole(IH-1052)
        pn_apns,
        pn_gcm
    };

    if(fileKey){
        msgObj.type = 'fileUpload';
        msgObj.fileKey = fileKey;
    }

    if(translation.translatedText && this.props.testInputMap[this.props.selectedChannel]) {
      msgObj.originalText = this.props.testInputMap[this.props.selectedChannel];
      msgObj.originalTextLanguage = translation.detectedSourceLanguage;
    }

    sendMessage(selectedChannel, msgObj);
    this.props.chatTextInputChange(this.props.selectedChannel, '');

    this.setState({
        previewImage: null,
        previewVisible: false,
        fileList: [],
        attachmentType: null,
        filename: null,
        progress: 0,
        fileKey: null,
        sendingAttachment: false,
        translation: {}
    })

    if(!chatStarted) {
          const apiName = 'pubnub.publish';
          const resource = 'teams';
          const docId = btoa(selectedChannel.split('-')[0]);
          const summary = 'pubnub send message to channel ' + selectedChannel;
          startChat(displayName);
          updateInterventionDetails(displayName, apiName, resource, docId, summary);
    }

    // this.refs.txtMessage.value = '';
    // this.refs.txtMessage.focus();
  }

  onClickImg = ()=>{
     this.setState({
         previewVisible: true
     })
  }

  setProgress = (progress)=>{
      this.setState({
          progress
      })
  }
  showSendButton  = ({ bottom = {} })=>{
      const { previewImage,sendingAttachment,progress } = this.state;
      let message = this.props.testInputMap[this.props.selectedChannel];
      const hasInput = message ? message.length > 0 : false || previewImage;
      
      return <button className="send-icon" /*style={{ bottom, zIndex: 2 }}*/>
               { !sendingAttachment ? <SendIcon width={20} height={20} hasInput={hasInput}/> : '' }
             </button>
  }

  showImgModal = ()=>{
    const { previewVisible, previewImage } = this.state;

    return  <Modal visible={previewVisible}
                   footer={null}
                   className='imageUploadModal'
                   onCancel={()=>this.setState({
                        previewVisible: false
                   })}>
                <img src={previewImage} width={'100%'}/>
           </Modal>;
  }

  showTranslateButton = () => {
    const { translation : { translatedText }, sendingAttachment } = this.state;
    const { selectedChannel, testInputMap = {}, userInfo = {} } = this.props;
    const messageText = testInputMap[selectedChannel];


    const appLanguage = _.get(userInfo, 'profile.appLanguage.description', 'English');
    const spokenLanguage = _.get(userInfo, 'profile.language.description', 'English');
    let targetLanguage;

    if(appLanguage === spokenLanguage) {
      targetLanguage = appLanguage;
    } else {
      targetLanguage = appLanguage === 'English' ? spokenLanguage : appLanguage;
    }

    const handleTranslate = async (e) => {
      e.preventDefault();
      Mixpanel.track('clicked', 'translation', 'sending_message');
      if(!messageText)
        return message.warn('No text found');

      if(translatedText)
        return;

      try {
        const query = messageText.replaceAll('\n', '<br/>');
        const resp = await getTranslate(query, LANGUAGE_MAP[targetLanguage]);
        const translation = _.get(resp, 'data.translations.0') || {};
        if(translation.translatedText) {
          translation.translatedText = translation.translatedText.replaceAll('<br/>', '\n');
          translation.translatedText = chatPopoverHelper.decodeHTML(translation.translatedText);
          
          translation.renderTranslatedText = () => (
            <div className='chat-input-translated-text'>
              {/* <div className='top-divider'></div> */}
              <div className='translated-text-content-wrapper'>
                <div className='translated-text-content'>
                  {translation.translatedText}
                </div>
                {/* <div className='translated-text-note'> */}
                <span className='chat-input-translated-text-note' style={{ fontStyle: 'italic' }}>
                  (Translated by Google)
                </span>
              </div>
              {/* </div> */}
            </div>
          );
        }
        this.setState({ translation });
      } catch (e) {
        console.error(e);
      }
    };

    return !sendingAttachment && targetLanguage !== 'English' && (
      <div className='chat-input-translation-icon-wrapper' onClick={handleTranslate}>
        <img 
          id='chat-translate-icon'
          src={translatedText ? '/image/translated.svg' : '/image/translate.svg'}
        /> 
      </div>
    );
  }

  showAttachButton = (state,{ bottom = { } })=>{
      const { beforeUpload, removeImg } = this;
      const { sendingAttachment } = this.state;
      const { fileList } = state;
      return  !sendingAttachment ? <UploadImgComponent beforeUpload={ beforeUpload }
                                 removeImg={ removeImg }
                                 fileList={fileList}
                                //  bottom = { bottom }
                                customStyles={{ position: 'inherit', right: 'unset', marginRight: 12 }}
                                customImageStyles={{ filter: 'brightness(0.7)' }}
                           /> :''

  }

  // checkIsAppUser = async () => {
  //   const { props:{ selectedChannel } } = this;
  //   const memberId = btoa(selectedChannel.split('-')[1]);
  //   // console.log('------memeberId:', memberId);
  //   const setIsNotAppUser = isNotAppUser => this.setState({ isNotAppUser });
  //   try {
  //     // TODO: sync the updates from CARE PLAN
  //     const loanResult = await Client.query({
  //       query: getLoanDevice,
  //       variables: { memberId },
  //       fetchPolicy: 'network-only'
  //     });
  //     if(!_.isEmpty(_.get(loanResult, 'data.getLoanDevice'))){
  //       return setIsNotAppUser(false);
  //     }

  //     const hubResult = await Client.query({
  //       query: getDeviceHub,
  //       variables: { memberId },
  //       fetchPolicy: 'network-only'
  //     });
  //     if(_.get(hubResult, 'data.getDeviceHub'))
  //       return setIsNotAppUser(true);

  //     const deviceResult = await Client.query({
  //       query: getIoTDevice,
  //       variables: { memberId },
  //       fetchPolicy: 'network-only'
  //     });
  //     if(!_.isEmpty(_.get(deviceResult, 'data.getIoTDevice'))){
  //       return setIsNotAppUser(true);
  //     }

  //     setIsNotAppUser(false);
  //   } catch (err) {
  //     console.error('---error', err);
  //   }
  // }

  render() {
    const { previewImage,fileList, attachmentType, filename, translation } = this.state;
    const { props:{ displayName, onInteraction, userInfo } } = this;
    const userIdParsed = displayName.split(':')[1];
    const file = fileList[0];
    // console.log(file);
    const placeholder = this.props.isNotAppUser ? 
    'Unfortunately, you cannot message this patient since he/she does not use the iHealth Unified Care App.' 
    : 
    'Type message';
    const hasTranslation = !!translation.translatedText;

    return (
      <footer className="message-form">
        <form className='form-container' onSubmit={ this.onSubmit }>
            <div className='inputBox-container'>
                <div style={{ position: 'relative' }}>
                    <Input.Group compact>
                      {
                        hasTranslation &&
                        <React.Fragment>
                          {translation.renderTranslatedText()}
                        </React.Fragment>
                      }
                      <TextArea
                        className={hasTranslation ? "inputBox with-translation" : "inputBox"}
                        type="text"
                        value = {this.props.testInputMap[this.props.selectedChannel]}
                        id="message-to-send"
                        placeholder={placeholder}
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        onChange = {e => {
                          this.props.chatTextInputChange(this.props.selectedChannel, e.target.value);
                          if(hasTranslation) {
                            this.setState({ translation: {} });
                          }
                        }}
                        onPressEnter={this.onSubmit}
                      />
                      {
                        hasTranslation &&
                        <span className='chat-input-translated-text-note' style={{ backgroundColor: '#FFFFFF', paddingTop: 6 }}>
                          Note: Patient will receive translated message ONLY upon sending.
                        </span>
                      }
                    </Input.Group>
                    { this.showImgModal() }
                    { this.renderModal() }
                    {/* { 
                      hasTranslation && !previewImage && 
                      <div style={{ height: 43.25 }}></div>
                    } */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', padding: '8px 0px' }}>
                      <div style={{ minWidth: !previewImage ? 85 : 'unset' }}>
                        { previewImage && 
                          <AttachmentPreviewComponent onRemove={this.removeImg} onClick={this.onClickImg}>
                            {
                              attachmentType === 'pdf' ?
                              <Tooltip title={filename} placement='top' overlayClassName='pdf-preview-tooltip'>
                                <div style={{ width: 85, height: 85, display: 'flex', alignItems: 'center', border: '1px solid lightgray' }}>
                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                    {filename}
                                </span>
                                </div>
                              </Tooltip>
                              : <img src={previewImage} width={85} height={85} />
                            }
                          </AttachmentPreviewComponent>
                        }
                      </div>
                      <div style={{ display: 'flex' }} className="chat-input-controls">
                        { this.showTranslateButton() }
                        <ChatSendArticleButtonComponent 
                          patientId={userIdParsed}
                          patientLanguageSettings={(
                            _.pick(_.get(userInfo, 'profile'), ['language', 'appLanguage'])
                          )}
                          onInteraction={onInteraction}
                        />
                        { this.showAttachButton(this.state,{ bottom:12 }) }
                        { this.showSendButton({ bottom:12 }) }
                      </div>
                    </div>
                </div>
            </div>
        </form>
      </footer>
    );
  }
}

const mapState = ({ chat }) => {
  return {
    selectedChannel: chat.main.selectedChannel,
    testInputMap: chat.main.testInputMap
  }
};

const mapDispatch = (dispatch) => {
    return {
        chatTextInputChange: (channel, text) => dispatch(actions.chatTextInputChange(channel,text)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
        startChat:(displayName)=>dispatch(startChat(displayName)),

    }
}
ChatInputComponent.propTypes = {
  userId: PropTypes.string,
  sendMessage: PropTypes.func,
  selectedChannel: PropTypes.string,
  testInputMap: PropTypes.object,
  chatTextInputChange: PropTypes.func.isRequired,
};

const { loanDevice, hubDevice, ioTDevice } = deviceHelpers;

export default compose(
  connect(mapState, mapDispatch),
  withProps(props => {
    const selectedChannel = props.selectedChannel || '';
    const memberId = btoa(`accounts:${selectedChannel.split('-')[1]}`);
    const data = { user: { id: memberId }}; // consistent with deviceHelpers.ownProps
    return { ...props, data };
  }),
  loanDevice,
  hubDevice,
  ioTDevice,
  withProps(props => {
    const { getLoanDevice, getDeviceHub, getIoTDevice } = props;
    let isNotAppUser = false;
    if(!_.isEmpty(getLoanDevice)){
      // as long as patient has loaner device, ignore status of the others
      isNotAppUser = false;
    } else if (!_.isEmpty(getDeviceHub) || _.get(getIoTDevice, 'length')){
      isNotAppUser = true;
    }
    return { ...props, isNotAppUser };
  })
)(ChatInputComponent);
