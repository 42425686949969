import React from 'react';

const AttachmentPreviewComponent = ({ onRemove, onClick, children }) => {
  return (
    <div className='hasImage'>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div onClick={onClick}>
          {children}
        </div>
        <div onClick={onRemove} style={{ position: 'relative', left: -10, top: -40 }}>
          <img src='image/crossbtn.png' width={22} className='attachImg' />
        </div>
      </div>
    </div>
  );
};

export default AttachmentPreviewComponent;