import { AutoComplete, Pagination } from 'antd';

import { Query } from 'react-apollo';
import ICDCodeListGQL from 'modulesAll/graphql/ICDCodeList';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
const Option = AutoComplete.Option;
const PAGE_COUNT = 10;

class HealthConditionPicker extends Component {
  static displayName =
    '/patient/profileDetail/containers/HealthConditionPicker';
  constructor(props) {
    super(props);
    this.state = {
      keyWords: props.noInitialValue ? '' : 'I10',
      value: ''
    };
  }

  startSearch = _.debounce(() => {
    this.setState(prev => ({ canSearch: !!prev.keyWords }));
  }, 500);

  handleSearch = value => {
    this.setState({
      keyWords: value || '',
      value,
      canSearch: false,
    }, () => {
      this.startSearch();
    });
  };

  onSelect = value => {
    const { addHealthCondition } = this.props;
    addHealthCondition(value, () => {
      this.setState({ keyWords: '', value: '' });
    });
  }

  getOptions(ICDCodeList) {
    const options = _.map(ICDCodeList, item => {
      return (
        <Option
          key={item.id}
          code={item.code}
          value={`${item.title}::${item.code}`}
        >
          {item.code}: {item.title}
        </Option>
      );
    });

    return options;
  }

  // infinit scroll is not supported yet
  getLoadMore({ pageInfo, fetchMore }) {
    //see if there are more to load
    if (pageInfo.lastPage === 1)
      return [];
    const loadMore = (
      <Option disabled key={'loadMore'}>
        <Pagination
          defaultCurrent={1}
          total={pageInfo.total || 0}
          onChange={(page, pageSize) => {
            fetchMore({
              variables: {
                search: {
                  fields: ['CODE', 'TITLE'],
                  match: this.state.keyWords
                },
                page: page,
                count: pageSize
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }
                return Object.assign({}, prev, {
                  ICDCodeList: {
                    ...prev.ICDCodeList,
                    data: _.get(fetchMoreResult, 'ICDCodeList.data') || [],
                  }
                });
              }
            });
          }}
        ></Pagination>
      </Option>
    );
    return [loadMore];
  }
  render() {
    const { disabled } = this.props;
    const { canSearch, keyWords } = this.state;
    return (
      <Query
        query={ICDCodeListGQL}
        variables={{
          search: {
            fields: ['CODE', 'TITLE'],
            match: this.state.keyWords
          },
          page: 1,
          count: PAGE_COUNT
        }}
        skip={!canSearch || !keyWords}
      >
        {
        ({ loading, error, data, fetchMore }) => {
          const ICDCodeList = _.get(data, 'ICDCodeList.data') || [];
          const pageInfo = _.get(data, 'ICDCodeList.pageInfo') || {};
          const children = error
            ? null
            : loading
            ? []
            : [
                ...this.getOptions(ICDCodeList),
                ...this.getLoadMore({ pageInfo, fetchMore })
              ];

          return (
            <AutoComplete
              disabled={disabled}
              value={this.state.value}
              onChange={this.handleSearch}
              onSelect={this.onSelect}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 'fit-content' }}
              placeholder='Search ICD Code or Name'
              dataSource={children}
              optionLabelProp='code'
              allowClear={true}
              backfill={false}
            />
          );
        }}
      </Query>
    );
  }
}
HealthConditionPicker.propTypes = {
  onSelect: PropTypes.func
};
export default HealthConditionPicker;
