import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import { GOAL_TYPE_ENUM } from '../../careplanNew/component/helper/goalHelper';

import GoalAssessmentVitalsSummary from './GoalAssessmentVitalsSummary';
import GoalAssessmentContent from './GoalAssessmentContent';

const GoalAssessmentPopup = (props) => {
  const { availableGoals, preSelectedTab, isMRE, locateAssessment, monthlyReviewSummary } = props;
  const [selectedTab, setSelectedTab] = useState(preSelectedTab);
  const summaryRef = useRef({});
  const assessmentRef = useRef({});

  const handleSetFocus = () => {
    if(!locateAssessment && (!summaryRef || !summaryRef.current))
      return;
    if(locateAssessment && (!assessmentRef || !assessmentRef.current))
      return;
    
    const ref = locateAssessment ? assessmentRef : summaryRef;
    ref.current[preSelectedTab].scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if(!_.isEqual(preSelectedTab, selectedTab)) {
      setSelectedTab(preSelectedTab);
    }
    setTimeout(handleSetFocus, 100);
  }, [preSelectedTab, locateAssessment]);

  return (
    <div id='goal-assessment-popup'>
      <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
        {
          _.map(availableGoals, agn => (
          <Tabs.TabPane key={agn.goalType} tab={agn.condition}>
            <div className='body'>
              <div ref={el => summaryRef.current[agn.goalType] = el} className='container-wrapper'>
                <GoalAssessmentVitalsSummary 
                  key={agn.goalType}
                  goalType={agn.goalType}
                  associatedVitals={agn.associatedVitals}
                  monthlyReviewSummary={monthlyReviewSummary}
                />
              </div>
              {
                !_.isEmpty(agn.goallAssessment) &&
                !isMRE && 
                <div ref={el => assessmentRef.current[agn.goalType] = el} className='container-wrapper'>
                  <GoalAssessmentContent
                    key={agn.goalType}
                    goalType={agn.goalType}
                    goalAssessment={agn.goalAssessment}
                  />
                </div>
              }
              {
                !isMRE && selectedTab === GOAL_TYPE_ENUM.GLUCOSE_MANAGEMENT &&
                <React.Fragment>
                  <div className='glucose-management-assessment-guide'>
                    How to evaluate glucose management goal status
                  </div>
                  <img 
                    src='/image/workstation/glucose-management-assessment-guide.png' 
                    width='100%' 
                    style={{ marginLeft: -5 }} 
                  />
                </React.Fragment>
              }
            </div>
          </Tabs.TabPane>
          ))
        }
      </Tabs>
    </div>
  );
};

GoalAssessmentPopup.propTypes = {
  availableGoals: PropTypes.array, 
  preSelectedTab: PropTypes.string.isRequired, 
  isMRE: PropTypes.bool.isRequired,
  locateAssessment: PropTypes.bool,
  monthlyReviewSummary: PropTypes.object
};

export default GoalAssessmentPopup;