import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { DataHelper } from '../../../../../lib/utils/common';
import { DEFAULT_TEMPLATE_LANGUAGES } from '../../../../../lib/constants';
import { doesOrgHaveValueBased } from "../../../../../lib/utils/index";
const ConsentFormLanguageSelector = forwardRef(props => {
  const {
    prefixCls, 
    labelText = 'Consent form Language', 
    setLanguage,
    isValueBasedOrg,

  } = props;
  let {
    language,
    isDisabled = false
  } = props;
  language = isValueBasedOrg ? 'EL' : language;
  isDisabled = isValueBasedOrg;

  const [templateLanguages, setTemplateLanguages] = useState(DEFAULT_TEMPLATE_LANGUAGES);
  const handleChangeLanguage = languageCode => {
    let { groupId } = _.find(templateLanguages, { code: languageCode }) || {};
    setLanguage(languageCode, groupId);
  }

  useEffect(() => {
    const currentUser = DataHelper.getCurrentUser();
    let orgTemplateLanguages = _.get(currentUser, 'selectedRole.organization.templateLanguages') || [];

    if(orgTemplateLanguages.length) {
      orgTemplateLanguages = orgTemplateLanguages.slice().sort((a, b) => {
        const indexOfa = _.findIndex(templateLanguages, { code: a.code });
        const indexOfb = _.findIndex(templateLanguages, { code: b.code });
        if (indexOfa === -1){
          return 1;
        } else if (indexOfb === -1) {
          return -1;
        } else {
          return indexOfa - indexOfb;
        }
      });
      setTemplateLanguages(orgTemplateLanguages);
    }
  }, []);

  useEffect(() => {
    const templateForLanguage = _.find(templateLanguages, { code: language });
    if (!templateForLanguage) {
      // clinic doesn't have consent form template for the language
      // default to the first available option
      const { code: defaultCode = 'EL'} = _.first(templateLanguages) || {};
      handleChangeLanguage(defaultCode);
    }
  }, [templateLanguages])

  return <div className={`${prefixCls ? prefixCls + '-' : ''}consent-form-header-language`}>
    <span className={`${prefixCls ? prefixCls + '-' : ''}consent-form-header-language-label`}>
      {labelText}
    </span>
    <Select 
      style={{ width: 136, marginLeft: 11 }} 
      value={language} 
      onChange={handleChangeLanguage} 
      disabled={!!isDisabled}
    >
      {
        _.map(templateLanguages, ({ code, description }) => (
          <Select.Option key={code} value={code}>{description}</Select.Option>
        ))
      }
    </Select>
  </div>
});

ConsentFormLanguageSelector.propTypes = {
  prefixCls: PropTypes.string, 
  labelText: PropTypes.string, 
  language: PropTypes.string.isRequired, 
  setLanguage: PropTypes.func.isRequired, 
  isDisabled: PropTypes.bool
};

export default ConsentFormLanguageSelector;