import React from 'react';
import { IHSearchTable, IHRadio, moment, _ } from 'ihcomponent';
import { SuperContainer, GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import { Checkbox } from 'antd';
import action from '../actions'
import I18N from 'modulesAll/I18N'

export default class ListTableComponent extends React.Component {
  static displayName = 'providerTask/TaskList/components/ListTableComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  async componentWillUpdate() {
    if(this.props.refetch){
      await this.props.refetch()

      if (this.props.list && this.props.list.data.length === 0 && this.props.page.current !== 1) {
        this.props.onTableChange({ pageSize: 10, current: 1 })
      }
    }
  }

  render(){
    return (
      <div className='provider-task-list-table'>
        {this.renderTable()}
      </div>
    )
  }

  renderTable(){
    const p = this.getTableProps()
    return <IHSearchTable {...p} />
  }

  getRowSelection(list){
    const onSelectChange = (obj, flag) => {
      if(flag){
        this.editTodoGQL(_.merge({}, obj, {
          completedAt : moment().toDate()
        }))
      }
      else{
        this.editTodoGQL(_.merge({}, obj, {
          completedAt : null
        }))
      }
    }
    return {
      selectedRowKeys : _.map(_.filter(list, (l)=>!!l.completedAt), (l)=>l.id),
      onSelect: onSelectChange,
    };
  }

  getTableProps(){
    const { data = [], pageInfo: { total = 0 } = {} } = _.get(this.props, 'list', {})
    const tableActions = this.renderTableActions()
    const { onTableChange, onSearchEnter, onTableSearch, filterByDateRange, page, loading = true, search = '' } = this.props


    return {
      title : `My Tasks (${total})`,
      //inputPlaceholder : 'Search by Description',
      //showSearchInput: true,
      //searchValue: search,
      //onSearchEnter,
      //onSearch: onTableSearch,
      onChange: onTableChange,
      //filterByDateRange,
      loading,
      rowKey : (doc)=>doc.id,
      //actions: tableActions,
      rowSelection: this.getRowSelection(data),
      columns: this.getColumns(),
      dataSource : data,
      onRow : (doc)=>{
        return {
          onClick: () => this.props.showModal(true, doc)
        }
      },
      pagination : {
        current:_.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total,
      }
    }
  }

  renderTableActions() {
    const option = [
      {name: 'Current', value: 'Current'},
      {name: 'Completed', value: 'Completed'},
    ]
    const radioProps = {
      option,
      type: 'radioButton',
      className: 'radio-button-height-30',
      value : this.props.table_select,
      onChange : (e)=>{
        if (e.target.value === 'Current') {
          createAuditLog({ action: I18N.get('auditLog.provider.tasks.currentTasks') })
        }
        else {
          createAuditLog({ action: I18N.get('auditLog.provider.tasks.completedTasks') })
        }

        this.props.showTasksByStatus(e.target.value)
        /* TODO: As of now, everytime we change task's status, fetch & show page 1
          Add state to this component to store current page for each status [Current, Completed]
        */
        this.props.onTableChange({ pageSize: 10, current: 1 })
      }
    }
    const tableActions = (
      <div className='task-table-actions'>
        <IHRadio className="ih-radio" {...radioProps} />
      </div>
    )
    return tableActions
  }

  getColumns() {
    const {table_select} = this.props
    if (table_select === 'Current') {
      return (
        [
          {
            title : 'Task Description',
            key : 'taskDescription',
            dataIndex: 'description',
            width : 350,
            render(t, doc){
              return doc.description
            },
          },
          {
            title : 'Due On',
            key : 'due',
            dataIndex: 'dueDate',
            sorter: (a, b) => a.dueDate - b.dueDate,
            render : (t, doc)=>{
              return this.renderDueData(doc)
            },
          }
        ]
      )
    }
    else {
      return (
        [
          {
            title : 'Task Description',
            key : 'taskDescription',
            dataIndex: 'description',
            width : 350,
            render(t, doc){
              return doc.description
            },
          },
          {
            title : 'Due On',
            key : 'due',
            dataIndex: 'dueDate',
            width: 200,
            sorter: (a, b) => a.dueDate - b.dueDate,
            render : (t, doc)=>{
              return this.renderDueData(doc)
            },
          },
          {
            title : 'Completed On',
            key : 'completedAt',
            dataIndex: 'completedAt',
            sorter: (a, b) => a.completedAt - b.completedAt,
            render : (t, doc)=>{
              return this.renderCompletedData(doc)
            },
          }
        ]
      )
    }
  }

  renderDueData(doc){
    const {table_select} = this.props
    const t = moment(doc.dueDate).format('MMM-DD-YYYY')
    const sy = {
      color : ''
    }

    // Not using momentjs (isBefore) method because of a depreciated warning
    // This warning will cause momentjs to not work in IE
    const dueDate = new Date(doc.dueDate).setHours(0,0,0,0)
    const dateNow = new Date().setHours(0,0,0,0)

    if(table_select === 'Current' && dueDate < dateNow) {
      sy.color = '#e85c6a'
    }

    return <font style={sy}>{t}</font>
  }

  renderCompletedData(doc){
    const t = moment(doc.completedAt).format('MMM-DD-YYYY')
    const sy = {
      color : ''
    }

    return <font style={sy}>{t}</font>
  }

  async editTodoGQL(variables){
    const auditLogDetails = {
      description: variables.description || '',
      dueDate: moment(variables.dueDate).format('MMM-DD-YYYY'),
      createdAt: moment(variables.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
      updatedAt: moment(variables.updatedAt).format('MMM-DD-YYYY hh:mm a:ss'),
      completedAt: variables.completedAt && moment(variables.completedAt).format('MMM-DD-YYYY hh:mm a:ss'),
    }

    try{
      const res = await this.props.editTodoGQL({variables})
      const {table_select} = this.props

      if(table_select === 'Current') {
        createAuditLog({
          action: I18N.get('auditLog.provider.tasks.completeTask'),
          details: auditLogDetails,
          request: variables,
          response: res
        })
      }
      else {
        createAuditLog({
          action: I18N.get('auditLog.provider.tasks.uncompleteTask'),
          details: auditLogDetails,
          request: variables,
          response: res
        })
      }
    }
    catch(e){
      const {table_select} = this.props

      if(table_select === 'Current') {
        createAuditLog({
          action: I18N.get('auditLog.provider.tasks.completeTask'),
          details: auditLogDetails,
          request: variables,
          response: e,
          success: false
        })
      }
      else {
        createAuditLog({
          action: I18N.get('auditLog.provider.tasks.uncompleteTask'),
          details: auditLogDetails,
          request: variables,
          response: e,
          success: false
        })
      }

      this.props.openErrorModal(GQLHelper.formatError(e))
    } finally {
      this.props.reset()
    }
  }
}
