import Assessment, { Question } from './Assessment';

export default `
  _id
  id
  name
  description
  nameKey
  version
  questions {
    ${Question}
  }
  type
  healthCondition
  organization {
    id
  }
  assessment {
    ${Assessment}
  }
  user {
    id
  }
  date
  timezone
  completed

  createdAt
  updatedAt
`;
