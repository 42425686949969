import React from 'react';
import { Modal } from 'antd';
import '../../style/goalStatement.styles.scss';
import I18N from 'modulesAll/I18N';

const GoalStatementTooltipComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showTaskProviderModal:false
        }
    }

    setTaskProviderVisible = (isVisible) => {
        this.setState({
            showTaskProviderModal: isVisible
        })
    }

    renderGoalTipModal = () => {
        const { tooltip } = I18N.get('goalstatement');
        const { title, tipOne, tipTwo} = tooltip;
        return <Modal
                wrapClassName={'modal-custom'}
                title={title}
                visible={this.state.showTaskProviderModal}
                footer={null}
                onCancel={() => this.setTaskProviderVisible(false)}
                >
                    <div>
                        <p className={'goalModalContent'}>{tipOne}</p>
                        <p className={'goalModalContent'}>{tipTwo}</p>
                    </div>
                </Modal>
    }

    renderLink = ()=>{
        return <div onClick={() => this.setTaskProviderVisible(true)}
                    style={{ cursor:'pointer',color:'#717171',textDecoration:'underline',fontSize:14,float:'right', marginRight:10, marginTop:-14}}>
                    How do clinical goals work with ICD codes?
               </div>
    }

    render() {
        const { renderLink, renderGoalTipModal } = this;
        return <div>
            {renderLink()}
            {this.state.showTaskProviderModal ? renderGoalTipModal() : ''}
        </div>
    }


};

export default GoalStatementTooltipComponent;
