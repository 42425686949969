// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers';
/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('Organization', 'OrganizationNew', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'SET_SUBMITTING'
]);
/* Result:
{
  FORM_FIELD_CHANGE: 'Provider/ProviderNew/FORM_FIELD_CHANGE',
  RESET_FORM: 'Provider/ProviderNew/RESET_FORM',
  FORM_SAVED: 'Provider/ProviderNew/FORM_SAVED',
  SET_SUBMITTING: 'Provider/ProviderNew/SET_SUBMITTING',
}
*/

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
]);

/*
actionCreators {
  resetForm: some action creator,
  updateForm: some action creator,
  ...
}
*/

const { resetForm, updateForm, onFieldsChange, handleFormSaved, setSubmitting } = actionCreators;
export {
    resetForm, updateForm, onFieldsChange, handleFormSaved, setSubmitting,actionTypes
}