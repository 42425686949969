import React from 'react';
import { Icon, Tooltip } from 'antd';
import I18N from 'modulesAll/I18N';
import { monthlyReviewStatusMap } from '../constant';
import moment from 'moment';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { helpers } from '../../visitNewWorkFlow/helper';
import { checkIfMonthlyReviewInCompleted } from '../helper/monthlyReviewHelper';

const GenerateMTPRComponent = class extends React.Component{
    constructor(props){
        super(props);
        const role = helpers.getRole();
        const MRConstants =  I18N.get(`monthlyReviewPlan.manualMonthlyReview.${role}`);
        this.state = {
            MRConstants
        }
    }
    renderFinishedImage = (disabled = false) => {
        const color = disabled ? '#B5B9BE' :'#3BA767';
        return <Icon type="check-circle" theme="filled" style={{marginRight: '12px', color }}/>;
    }

    renderTooltip = (hasTitle = true) => {
        const { monthlyReviewStatus, monthlyReviewType} = this.props;
        const { MRConstants } = this.state;
        const isFinished = _.includes(_.get(MRConstants, 'typeFilters'), monthlyReviewType) && monthlyReviewStatus === monthlyReviewStatusMap.DONE;
        const title = hasTitle && ( isFinished ? I18N.get('monthlyReviewPlan.doneMonthlyReview') : _.get(MRConstants, 'tooltipText'));
        return <Tooltip title={title}>
            <Icon type="info-circle" theme="filled" stype={{fontSize: '14px'}}/>
        </Tooltip>
    }

    renderStarted = (disabled = false) => {
        const { setMonthlyReviewStatus, systemGenerateMonthlyReviewData, setSystemGenerateMonthlyReviewData } = this.props;
        const { MRConstants } = this.state;
        const color = disabled ? '#B5B9BE': 'black' ;
        const source = disabled ? '/image/workstation/monthly_review_disabled.svg' :'/image/workstation/monthly_review.svg';
        return <div className={'generate-mtpr-started'} style={{color}}>
                    <img src={source} />
                        Start patient's
                    <a  disabled={disabled}
                        style={{textDecoration: "underline", color}}
                        onClick={() => {
                            if(_.isEmpty(systemGenerateMonthlyReviewData)){
                                const newMonthlyReviewData = {
                                    type: _.get(MRConstants, "reviewType"),
                                    status : "INIT"
                                }
                                setSystemGenerateMonthlyReviewData(newMonthlyReviewData);
                            }
                            setMonthlyReviewStatus(monthlyReviewStatusMap.IN_PROGRESS);
                        }}> {_.get(MRConstants, 'startBtnText')}</a>
                    {this.renderTooltip(!disabled)}
                </div>
    }


    renderFinished = (disabled = false) => {
        const { setMonthlyReviewStatus, setMonthlyReviewData, systemGenerateMonthlyReviewData } = this.props;
        const { updatedAt } = systemGenerateMonthlyReviewData || {};
        const { MRConstants } = this.state;
        const date = moment(updatedAt).format('MM/DD/YYYY');
        const color = disabled ? '#B5B9BE': 'black';
        return <div className={'generate-mtpr-finished'} styles={{color}}>
            {this.renderFinishedImage(disabled)}
            {_.get(MRConstants, 'finishedBtnText')} {date}
            <a  disabled={disabled}
                style={{textDecoration: "underline", color}}
                onClick={() => {
                    setMonthlyReviewStatus(monthlyReviewStatusMap.IN_PROGRESS);
                    setMonthlyReviewData({
                        type:_.get(MRConstants, "reviewType"),
                        status : "INIT"
                    });
                    Mixpanel.track('clicked', 'review', 'patient_profile');
                }}> Start a new one</a>
            {this.renderTooltip(!disabled)}
        </div>
    }

    parseStatus = (monthlyReviewStatus,systemGenerateMonthlyReviewData)=>{
        //filter if MTPR closed by system;
        if(
          monthlyReviewStatus==='DONE'
          && !_.get(systemGenerateMonthlyReviewData, 'reviewedBy')
        ) {
            return monthlyReviewStatusMap.START;
        }
        return monthlyReviewStatus;
    }

    render(){
        let { monthlyReviewStatus, systemGenerateMonthlyReviewData } = this.props;
        const initialStatus = _.get(systemGenerateMonthlyReviewData, 'status');
        switch(this.parseStatus(monthlyReviewStatus,systemGenerateMonthlyReviewData)) {
            case monthlyReviewStatusMap.START:
                return this.renderStarted();
            case monthlyReviewStatusMap.IN_PROGRESS:
                return checkIfMonthlyReviewInCompleted(initialStatus) ? this.renderStarted(true) : this.renderFinished(true);
            case monthlyReviewStatusMap.COMPLETED:
                return this.renderFinished();
            default:
                return this.renderStarted();
          }
    }
}

export default GenerateMTPRComponent;
