import { combineReducers } from 'redux'
import Common from 'ProgramEditModule/Common/reducers/CommonReducer'
import ProgramInfoForm from 'ProgramEditModule/ProgramInfo/reducers/ProgramInfoFormReducer'
import DeviceForm from 'ProgramEditModule/Device/reducers/DeviceFormReducer'
import Careplan from 'ProgramEditModule/Careplan/reducers'

const ProgramEdit = combineReducers({
  Common,
  ProgramInfoForm,
  DeviceForm,
  Careplan
})

export default ProgramEdit
