import gql from 'graphql-tag';
import Client from 'libModule/gqlClient';
import editVisit from 'graphqlModule/mutation/editVisit';
import React from 'react';
import { message } from 'antd';
import getVirtualVisitTeam from "../../graphql/getVirtualVisitTeam";
import { decryptRole } from '../../../lib/utils';
import { roleMap } from '../../../lib/constants';

const getVideoTokenQuery =  gql`
query getVirtualVisitToken($visitId:EID!){
  getVirtualVisitToken(visitId:$visitId){
    password
    signature
    meetingNumber
    apikey
    signature
    joinUrl
    agoraAppId
    agoraToken
    agoraChannelName
    agoraUid
  }
}`;
const getVisitMemberProfileQuery = gql`query getVisitMemberProfile($visitId:EID!,$memberId:EID!){
  getVisitMemberProfile(visitId:$visitId,memberId:$memberId){
    fullName
    avatar{
      thumbnailLink
    }
  }
}`
const activePatient = gql`mutation postVirtualMeetingEvent($eventType:VirtualMeetingEventType,$params:VirtualMeetingventParams,$visitId: EID!){
  postVirtualMeetingEvent(eventType:$eventType,params:$params,visitId:$visitId)
}`;

const enrollPatientConsent = gql`mutation enrollPatientConsent($memberId:EID!,$providers:[EID],$effectiveDate:Date!,$timezone:String,$consentType:String,$consentProviderName:String){
    enrollPatientConsent(consentProviderName:$consentProviderName,memberId:$memberId,providers:$providers,effectiveDate:$effectiveDate,timezone:$timezone,consentType:$consentType){
        memberId
        consent
        content
        fileUrl
        effectiveDate
        providers{
            id
            name
        }
        consentType
    }
}`;
const getPatientConsent = gql`query getpatientConsent($memberId:EID!){
  getPatientConsent(memberId:$memberId){
    consent
    effectiveDate
    providers{
        id
        name
    }
    id
    consentType
    fileUrl
    signatureAt
  }
}`;

const getPatientConsentForReferEnroll = gql`query getpatientConsent($memberId:EID!){
  getPatientConsent(memberId:$memberId){
    consent
    effectiveDate
    providers{
        id
        name
    }
    id
    consentType
    fileUrl
    content
  }
}`;

const getConsentTemplate = gql`
    query getConsentTemplate($language: Language!) {
        getConsentTemplate(language: $language) {
            id
            title
            language {
              code
              description
            }
            content
        }   
    }
`;

const PatientConsentMiniInfo = `
  id
  memberId
  consent
  consentType
  effectiveDate
  signatureAt
  content
  fileUrl
`;

const submitPatientConsent = gql`
    mutation submitPatientConsent($memberId: EID!, $fileContent: String!) {
        submitPatientConsent(memberId: $memberId, fileContent: $fileContent) {
          ${PatientConsentMiniInfo}
        }
    }
`;

const createAndSignConsent = gql`
    mutation createAndSignConsent($memberId: EID!, $providers: [EID], $effectiveDate: Date!, $timezone: String!, $fileContent: String!, $consentType: String!) {
        createAndSignConsent(
            memberId: $memberId, providers: $providers, effectiveDate: $effectiveDate,
            timezone: $timezone, fileContent: $fileContent, consentType: $consentType
        ) {
          ${PatientConsentMiniInfo}
        }
    }
`;

const createPaperConsent = gql`
  mutation createPaperConsent($memberId: EID!, $providers: [EID], $effectiveDate: Date!, $signatureAt: Date!, $timezone: String!, $fileContent: String!, $consentType: String!) {
    createPaperConsent(
      memberId: $memberId, providers: $providers, effectiveDate: $effectiveDate,
      signatureAt: $signatureAt, timezone: $timezone, fileContent: $fileContent, consentType: $consentType
    ) {
      ${PatientConsentMiniInfo}
    }
  }
`;

const createOneClickMeeting = gql`mutation createOneClickMeeting($visitId:EID!,$phoneNumber:String!){
  createOneClickMeeting(visitId:$visitId,phoneNumber:$phoneNumber)
}`;

const miniChatUserInfo = gql`query miniUser($id:EID){
    user(id:$id){
        id
        profile{
            fullName
            firstName
            lastName
            avatar{
                link           
            }
        }
    }
}`;

const miniPatientChatInfoWithComplexity = gql`query miniUser($id:EID){
  user(id:$id){
      id
      profile{
          fullName
          firstName
          lastName
          avatar{
              link           
          }
      }
      patientComplexity {
        memberId
        level
        source
      }
  }
}`;

const loginWithOneClickMutate = gql`mutation loginWithOneClickMeeting($code: String!,$verification: String){
  loginWithOneClickMeeting(code: $code, verification:$verification){
    id,
    username,
    visitId,
    sessionToken
    role{
        organization{
            name
        }
    }
  }
}`;

const getVideoToken = (variables)=> Client.query({
    query:getVideoTokenQuery,
    variables
})
    // .catch(error=>message.error(error.message))

const activePatientMutate = (variables ) =>
    Client.mutate({
    mutation:activePatient,
    variables,
    refetchQueries:[{
        query:getVirtualVisitTeam,
        variables:{
            visitId:variables.visitId
        },
        fetchPolicy:'network-only',
    }]
    })
    .catch(error=>{
        console.log(error);
        // message.error(error.message)
    })

const checkInPatient = (variables)=>{
   return Client.mutate({
       mutation: activePatient,
       variables,
   })
}
const editVisitMutate = (variables ) => Client.mutate({
    mutation:editVisit,
    variables
})

const enrollPatientConsentMutate = (variables)=>{
    return Client.mutate({
          mutation:enrollPatientConsent,
          variables,
          refetchQueries:[{
              query: getPatientConsent,
              variables:{
                  memberId:variables.memberId
              },
              fetchPolicy:'network-only'
          }]
      })
}
const sendOneClickMeeting = (variables)=>{
    return Client.mutate({
        mutation:createOneClickMeeting,
        variables
    })
}
const getChatUserInfo = (variables)=> Client.query({
    query: miniChatUserInfo,
    variables,
    errorPolicy:'ignore',
    fetchPolicy: 'cache-first',
})

const getPatientChatInfoWithComplexity = (variables) => {
  const currentUserRole = decryptRole();
  // only get complexity when user is HC, due to specific red dot logic
  if (roleMap[currentUserRole] === 'HC') {
    return Client.query({
      query: miniPatientChatInfoWithComplexity,
      variables,
      fetchPolicy: 'cache-first',
      errorPolicy:'ignore'
    });
  }
  return getChatUserInfo(variables);
};
//
const loginWithOneClickMeeting = (variables )=>Client.mutate({
    mutation:loginWithOneClickMutate,
    variables
})

const getVisitMemberProfile = (variables)=>Client.query({
    query:getVisitMemberProfileQuery,
    variables
})

const submitPatientConsentMutation = (variables, needRefetch = true) => {
    return Client.mutate({
        mutation: submitPatientConsent,
        variables,
        refetchQueries: needRefetch ? [{
            query: getPatientConsent,
            variables: {
                memberId: variables.memberId
            },
            fetchPolicy:'network-only'
        }] : [],
    });
}

const createAndSignConsentMutation = (variables, needRefetch = true) => {
    return Client.mutate({
        mutation: createAndSignConsent,
        variables,
        refetchQueries: needRefetch ? [{
            query: getPatientConsent,
            variables: {
                memberId: variables.memberId
            },
            fetchPolicy:'network-only'
        }] : [],
    });
}

const createPaperConsentMutation = (variables, needRefetch = true) => {
  variables.signatureAt = variables.signatureAt.startOf('day').valueOf();
  // fileContent is required
  // if put some string, it will generate fileInfo which is not needed at 0506 release
  variables.fileContent = '';
  return Client.mutate({
    mutation: createPaperConsent,
    variables,
    refetchQueries: needRefetch ? [{
      query: getPatientConsent,
      variables: {
          memberId: variables.memberId
      },
      fetchPolicy:'network-only'
    }] : [],
  }).then(res => _.get(res, 'data.createPaperConsent'));
}

// const finishMeeting = (variables) = Client.mutate({
//     mutation:activePatient,
//     variables
// })
export default {
    getVideoToken,
    activePatientMutate,
    checkInPatient,
    editVisitMutate,
    enrollPatientConsent,
    getPatientConsent,
    enrollPatientConsentMutate,
    sendOneClickMeeting,
    getChatUserInfo,
    getPatientChatInfoWithComplexity,
    getVideoTokenQuery,
    loginWithOneClickMeeting,
    getVisitMemberProfile,
    getConsentTemplate,
    submitPatientConsent,
    submitPatientConsentMutation,
    createAndSignConsentMutation,
    createPaperConsentMutation,
    miniChatUserInfo,
    getPatientConsentForReferEnroll
    // leaveMeeting,
    // finishMeeting
}
