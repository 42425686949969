import { constants } from 'libModule/utils'

export default constants('enrolledProgramInfo', 'EnrolledProgramInfo', [
  'SET_REPLACE_DEVICES_STEP',
  'CHANGE_DEVICES_INFO',
  'SET_RETURN_DEVICES_STEP',

  'INIT_DEVICES_INFO',

  //for return replace devices form
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'SET_SUBMITTING',
  'RESET_DEVICES_INFO'

])
