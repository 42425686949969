import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../css/chatIcon.scss';
import ChatContainer from './ChatContainer';

import Client from 'libModule/gqlClient';
import getPatientChatInfo from 'modulesAll/graphql/getPatientChatInfo';
import { setChat } from 'patientModule/enrolledProgramInfo/actions/EnrolledProgramInfoActions';

const ChatContainerWrapper = props => {
  const {
    programStatus,
    shouldShowChat = true,
    chatWithIndividual = false,
    showProfileStickyHeader,
    patientId,
    syncShowChat,
    onInteraction,
    // setIsInChat
  } = props;

  const handleShowChat = (showChat) => {
    if(syncShowChat) {
      syncShowChat(showChat); // to update showChat in parent component
    }
  };

  const getChatInfo = async () => {
    let channelName = '';
    const res = await Client.query({
      query: getPatientChatInfo,
      variables: { userId: patientId },
      fetchPolicy: 'network-policy',
    });
    channelName = _.get(res, 'data.getUserChatInfo.channels[0].name');
    if(!channelName && res.error) {
      console.warn('getChatInfo, failed to get channelName', res.error);
    }
    return channelName;
  };

  return (programStatus === 'STARTED' && shouldShowChat) && (
    <ChatContainer 
      {...props}
      chatWithIndividual={chatWithIndividual}
      showProfileStickyHeader={showProfileStickyHeader}
      // channelName={channelName}
      getChatInfo={getChatInfo}
      setChatStatus={handleShowChat}
      onInteraction={onInteraction}
    />
  );
};

ChatContainerWrapper.propTypes = {
  programStatus: PropTypes.string.isRequired,
  shouldShowChat: PropTypes.bool, // give control how to show chat icon from parent component
  patientId: PropTypes.string.isRequired,
  showProfileStickyHeader: PropTypes.bool,
  // showChat: PropTypes.bool,
  syncShowChat: PropTypes.func, // to update showChat in parent component
  renderChatIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  chatWithIndividual: PropTypes.bool,
};

export default connect(
  null,
  dispatch => ({
    setIsInChat: (isInChat) => dispatch(setChat(isInChat)),
  })
)(ChatContainerWrapper);