import goalAssessmentContent from './goalAssessmentContent';

const goalAssessment = {
  whyTextTip: {
    noGoalAssessment: {
      MD: 'This patient is measuring their vitals, but you haven\'t yet set a clinical goal for their condition.',
      default: 'This patient is measuring their vitals, but we haven\'t yet set a clinical goal for the condition'
    },
    manualGoal: 'The progress towards this goal cannot be measured because it was either set manually or is not monitored by any vital. Please assess the status manually, add a mappable diagnosis and mark as billable, or adjust the goal.',
    goalExists: 'This patient has a goal, but there is no associated vital. You may want to consult any new labs or offline EHR information to assess goal progress and either change, remove, or assess the goal manually.',
    goalNotExists: 'This patient has not set goal for this condition that was listed as billable, and goal progress cannot be assessed because there is no associated vital. You may want to consult any new labs or offline EHR information to determine the goal and assess the status manually, if possible.'
  },
  tips: {
    lightningBolt: 'Based on system detection of the data in the past 30 days.',
    dayNote: {
      MRE: 'There are <12 days of [XXX] measurements in the last 30 days, which is not enough data to concretely determine trends.',
      MTPR: 'There are <12 days of [XXX] measurements in the last 30 days, which is not enough data to concretely determine trends. Confirm that the system-generated goal status is appropriate.'
    }
  },
  emptyGoalName: 'There is no goal mapped to this vital',
  missedBillableICD: 'Please make sure there is an ICD10-code marked as billable. This will automatically allow you to add a clinical goal and assess it’s status here.',
  note: {
    vitalSummary: 'For now, please summarize/review the following 6 points yourself. Don’t worry, the auto-genreated summary comming next release.',
    recommendedAction: 'Tip: You may want to begin forming a provider note before talking to the patient to build a template of your talking points.'
  },
  warning: {
    conditionHasNoValue: `You have the ICD code [XXX] marked as billable, but you haven’t set any goal for this. You may set a goal in the monthly treatment plan review panel, OR unmark this ICD code as billable if care for this condition is not being discussed.`,
    conditionHasNoStatus: 'Status is required to submit',
    statusRequired: 'Status is required'
  }
};

const onCall = {
  RD: {
    name: 'Registered Dietitians (RDs)',
    todoCategory: ['Level 1 Clinical Alerts', 'Level 1 Provider Alerts', 'MTPR (Start day 25th each month)', 'Unread messages'],
    coverAll: `I'll cover all the workstation tasks for selected RD/HC`
  },
  HC: {
    name: 'Health Coached (HCs)',
    todoCategory: ['Level 1 Clinical Alerts', 'Level 1 Provider Alerts', 'MTPR (Start day 25th each month)', 'Unread messages'],
    coverAll: `I'll cover all the workstation tasks for selected RD/HC`
  },
  CA: {
    name: 'Clinical Assistants (CAs)',
    todoCategory: [],
    coverAll: `I'll cover all the workstation tasks for selected CA`
  }
};

const workStation = {
  message: 'All issues from this patient have been taken care of.',
  noMorePatientText: 'And there is no new patient case left.',
  unReadMessage: 'This patient has an unread message in the chat channel. Please review.',
  goalAssessment,
  goalAssessmentContent,
  onCall
};

export default workStation;