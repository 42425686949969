import React from "react";
import helper from "../helper/index"
import {Form, Button, Input} from 'antd';
import API from "../API";
import PostAPI from "../../postIt/API";


let ConfirmExcludedReasonComponent = class extends React.Component{

    renderForm = ()=>{
        const { getFieldDecorator } = _.get(this,'props.form');
        return <Form className='excludedReasonForm'>
                <Form.Item label="Please select the reason for exclusion">
                    { getFieldDecorator('excludedReason', { rules: [{ required: true,message:'Field is Required' }] })(this.renderDropDown())}
                </Form.Item>
                <Form.Item>
                    { getFieldDecorator('note')
                        (
                            <Input.TextArea className='reasonToExcludedInput'
                                          placeholder='(Optional)Add extra note here and it will be saved in Sticky Notes.'
                                          rows={4} autoSize={true}
                            />
                        )
                    }

                </Form.Item>
                { this.renderButton() }
               </Form>
    }

    onSubmit = ()=>{
        const { form ,memberId,refetchRemoteEnrollmentList,setShowExcludedReasonModal,refetchRemoteEnrollmentSummary,refetchPostNote } = this.props;
        form.validateFields((error,res)=>{
            if(res) {
                const { excludedReason,note='' } = res;
                helper.setExcludedReason(excludedReason,note,memberId,refetchPostNote,refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary,setShowExcludedReasonModal);

            }
        })
    }

    renderButton=()=>{
        return <Button type={'primary'} onClick={this.onSubmit }>Move</Button>
    }

    renderDropDown =()=>{
        return helper.generateExcludedReasonOptions();
    }
    render() {
        const { renderForm } = this;
        return <div>{renderForm()}</div>
    }
}
ConfirmExcludedReasonComponent = Form.create()(ConfirmExcludedReasonComponent);
export default ConfirmExcludedReasonComponent;