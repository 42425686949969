import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import { Link } from 'react-router'
import get from 'lodash/get'

import { IHBaseFormWithRow, IHButton, message, _, moment } from 'ihcomponent'
import { goPath, FormHelper, convertToFieldsValue, setAsyncRouteLeaveHook, GQLHelper, createContainer } from 'libModule/utils'

import editTeamGQL from 'modulesAll/graphql/editTeam'
import { updateQueries } from 'modulesAll/graphql/editTeam'
import deleteTeamGQL from 'modulesAll/graphql/deleteTeam'

import DoctorTable from './DoctorTable'
import ManagerTable from './ManagerTable'
import AllMembersTable from './AllMembersTable';
import { getFormProps } from '../components/formData/EditCareTeamForm'
import actions from '../actions/EditCareTeamActions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { modalAction } from 'modulesAll/common/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'careteam/editCareTeam/containers/EditCareTeamContainer'
  constructor(){
      super();
      this.state = {
          userList :[],
          addedDoctors: false,
          addedManagers: false,
          visibleMembers:[ ],
          primaryMembers:[ ],
          selectedMembers:null,
          addedSelectedMembers: false,
          addedPrimaryMembers: false
      }
  }
  componentWillMount() {
    setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))

    const { teamData, updateForm, setBreadCrumb } = this.props
    setBreadCrumb(`Care Teams / ${teamData.name} / Edit`)
    const initData = this.convertUserDataToFormData(teamData)
    const fieldsValue = convertToFieldsValue(initData)

    updateForm(fieldsValue)

  }

  componentDidUpdate(preProps){
    const { addedSelectedMembers,addedPrimaryMembers } = this.state;
    const { teamData:{ members } } = preProps;
    if(!addedSelectedMembers&&!addedPrimaryMembers){
        this.setSelectedAndPrimaryMembers(_.map(members,m=>m.user.id),_.map(_.filter(members,m=>m.visible),(i)=>i.user.id),_.map(_.filter(members,m=>m.primary),(i)=>i.user.id))
    }
  }

  setSelectedAndPrimaryMembers=(selectedMembers,visibleMembers,primaryMembers)=>{
    this.setState({
        selectedMembers,
        addedSelectedMembers: true,
        visibleMembers,
        primaryMembers,
        addedPrimaryMembers: true
    })
  }

  removeFromSelectedMem=(id)=>{
    const newMemberList = _.filter(this.state.selectedMembers,m=>m!=id);
    const newVisibleMembers = _.filter(this.state.visibleMembers,m=>m!=id);
    this.setState({
        selectedMembers: newMemberList,
        visibleMembers: newVisibleMembers
    })
  }

  addToSelectedMem=(id)=>{
      const newMemberList = this.state.selectedMembers.concat(id);
      this.setState({
          selectedMembers:_.uniq(newMemberList)
      })
  }

  moveMemUp=(index)=>{
    const { selectedMembers } = this.state;
    const id = selectedMembers[index];
    const newMemberList = selectedMembers.slice();
    if(index>0){
      const preIndex = index-1;
      const preId = newMemberList[preIndex];
      newMemberList[preIndex] = id
      newMemberList[index] = preId;
    }
    this.setState({
      selectedMembers:newMemberList
    })
  }

  moveMemDown=(index,lastIndex)=>{
      const { selectedMembers } = this.state;
      const id = selectedMembers[index];
      const newMemberList = selectedMembers.slice();
      if(index<lastIndex){
          const nextIndex = index+1;
          const nextId = newMemberList[nextIndex];
          newMemberList[nextIndex] = id
          newMemberList[index] = nextId;
      }
      this.setState({
          selectedMembers:newMemberList
      })
  }

  reorderAfterCheckMembersBasedOnVis = (id,visibleMembers) =>  {
        const { selectedMembers }  = this.state;
        const copiedArray = selectedMembers.slice();
        const indexInSelectedMembers = copiedArray.indexOf(id);
        copiedArray.splice(indexInSelectedMembers,1);
        const lenOfVis = visibleMembers.length;
        copiedArray.splice(lenOfVis==0 ? 0 : lenOfVis-1,0,id);
        return copiedArray;
  }

  reorderAfterUncheckMembersBasedOnVis = (id) =>  {
      const { selectedMembers }  = this.state;
      const copiedArray = selectedMembers.slice();
      const indexInSelectedMembers = copiedArray.indexOf(id);
      copiedArray.splice(indexInSelectedMembers,1);
      copiedArray.push(id);
      return copiedArray;
  }

  checkMemberVisible=(id)=>{
      const visibleMembers = this.state.visibleMembers.concat(id);
      const reorderedArray = this.reorderAfterCheckMembersBasedOnVis(id,visibleMembers);
      this.setState({
          visibleMembers: visibleMembers,
          selectedMembers: reorderedArray
      })
  }

  checkPrimaryMembers=(id)=>{
        const visibleMembers = this.state.visibleMembers.concat(id);
        const primaryMembers = this.state.primaryMembers.concat(id);
        this.setState({
            visibleMembers: visibleMembers,
            primaryMembers: primaryMembers,
        })
  }

  uncheckPrimaryMembers=(id)=>{
        const primaryMembers = _.remove(this.state.primaryMembers,(i)=>i!=id);
        this.setState({
            primaryMembers: primaryMembers,
        })
  }

  uncheckMemberVisible=(id)=>{
      const visibleMembers = _.remove(this.state.visibleMembers,(i)=>i!=id);
      const reorderedArray = this.reorderAfterUncheckMembersBasedOnVis(id,visibleMembers);

      this.setState({
          visibleMembers:visibleMembers,
          selectedMembers:reorderedArray
      })
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  updateUserList=(users,role)=>{
    this.setState({
        userList : _.uniqBy(this.state.userList.concat(users),'id'),
        [role]: true
    })
  }

  componentWillReceiveProps (nextProps) {
    const { updateForm } = this.props

    if(!_.isEqual(nextProps.teamData, this.props.teamData)) {
      const initData = this.convertUserDataToFormData(nextProps.teamData)
      const fieldsValue = convertToFieldsValue(initData)

      updateForm(fieldsValue)
    }
  }

  routerWillLeave() {
    const { openConfirmModal } = this.props
    const submit = this.submit.bind(this)
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      openConfirmModal(resolve, submit)
    })
  }

  convertUserDataToFormData(teamData) {
    const initData = {
      name: teamData.name,
      description: teamData.description,
      healthCondition: teamData.healthCondition,
      contactName:teamData.contactName,
      email: teamData.email && teamData.email.address,
      number: teamData.phone && teamData.phone.number,
      countryCode:  teamData.phone&&teamData.phone.countryCode,
      forwardingPhoneCountryCode: teamData.forwardingPhone&&teamData.forwardingPhone.countryCode,
      forwardingPhone: teamData.forwardingPhone&&teamData.forwardingPhone.number
    }
    return initData
  }

  // moveMemberToFront(index){
  //     if(index>0){
  //       const newArray = this.state.
  //     }
  // }
  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()
    const { teamData,doctors,managers } = this.props
    const { visibleMembers,addedDoctors,addedManagers,userList,selectedMembers,primaryMembers } = this.state;

    return (
      <div className='vsm-careteam-new-team'>
        {this.renderBackLink()}
        <div className='careteam-new-team'>
          {body}
          <DoctorTable ref="c_doctor" teamData={teamData} updateUserList={this.updateUserList} userList={userList}
                       addedDoctors={ addedDoctors} removeFromSelectedMem={this.removeFromSelectedMem}
                       addToSelectedMem = { this.addToSelectedMem }
          />
          <ManagerTable ref="c_manager" teamData={teamData} updateUserList={this.updateUserList} addedManagers={ addedManagers }
                        removeFromSelectedMem={this.removeFromSelectedMem}
                        addToSelectedMem = { this.addToSelectedMem }
          />
          <AllMembersTable doctors={doctors} managers={managers} userList={ userList} visibleMembers={visibleMembers}
                           setSelectedMembers={this.setSelectedMembers}
                           selectedMembers = { selectedMembers }
                           addedManagers={ addedManagers } addedDoctors={ addedDoctors}
                           moveMemUp={this.moveMemUp }
                           moveMemDown={ this.moveMemDown }
                           checkMemberVisible={this.checkMemberVisible}
                           uncheckMemberVisible={this.uncheckMemberVisible}
                           checkPrimaryMembers = { this.checkPrimaryMembers }
                           uncheckPrimaryMembers={ this.uncheckPrimaryMembers }
                           primaryMembers = { primaryMembers }
          />
          {footer}
        </div>
      </div>
    )
  }


  renderBackLink(){
    return (
      <div className="v-link">
        <Link to={'/careteams/'}>
          <i className="fa fa-angle-left" />
          <span>Back to Care Teams</span>
        </Link>
      </div>
    )
  }

  renderBody() {
    const { onFieldsChange, teamData } = this.props

    const initData = this.convertUserDataToFormData(teamData)
    const fieldsValue = convertToFieldsValue(initData);
    const formProps = getFormProps({ onFieldsChange, fieldsValue })

    return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'inline-block',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 10,
      }
    }
    const buttonProps = {
      delete: {
        onClick : ()=>{
          this.confirmDelete()
        },
        bsStyle: 'primary',
        label: 'Delete Care Team',
        className: "btn-delete",
        style: {
          float: 'left'
        }
      },
      cancel: {
        onClick : (e)=>{
          goPath('/careteams/')
        },
        bsStyle: 'default',
        label: 'Cancel',
        style: {
          float: 'right',
          marginLeft: '10px'
        }
      },
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save',
        style: {
          float: 'right'
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.delete} />
        <IHButton {...buttonProps.cancel} />
        <IHButton {...buttonProps.done} />
      </div>
    )
    return footer
  }

  getMutateVariable(data) {
    const { visibleMembers,selectedMembers,primaryMembers } = this.state;

    const result = {
      id: this.props.id,
      name: data.name,
      description: data.description,
      email: _.isEmpty(data.email) ? null : data.email,
      contactName:data.contactName,
      phone: data.number ? {
        countryCode: '1',
        number: data.number,
        type: 'MOBILE'
      } : null,
      forwardingPhone: data.forwardingPhone ? {
        countryCode: '1',
        number:data.forwardingPhone,
        type:'MOBILE'
      }:null
    }
    let orderedVisibleMembers = [];
    result.memberId = _.uniq(this.state.selectedMembers);

    //for visible members based on selected members order;
    _.forEach(selectedMembers,(s)=>{
      if(_.indexOf(visibleMembers,s)!=-1){
          orderedVisibleMembers.push(s);
      }
    })
    result.primary =  _.uniq(primaryMembers);
    result.visibleMembers = orderedVisibleMembers;

    return result
  }

  confirmDelete(){
      const { closeModal, openModal } = this.props
      const p1 = {
        type : 'primary',
        label : 'Confirm',
        size : 'large',
        onClick : ()=>{
          this.deleteTeam()
        }
      }
      const p2 = {
        label : 'Cancel',
        size : 'large',
        style : {
          marginLeft : '10px'
        },
        onClick : ()=>{
          closeModal()
        }
      }
      const footer = (
        <div>
          <IHButton {...p1} />
          <IHButton {...p2} />
        </div>
      )
      const param = {
        size : 'sm',
        title : 'Delete Care Team?',
        content : 'Please confirm if you want to delete this care team',
        footer
      }
      openModal(param.content, param)
  }

  async submit() {
    const { fieldsValue, handleFormSaved, openErrorModal, editTeam } = this.props
    const formValue = FormHelper.getValue(fieldsValue);
    console.log(formValue);
    let isFormValid = true
    const mutate = editTeam
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }
    const variables = this.getMutateVariable(formValue);
    const auditLogDetails = this.getMutateVariable(formValue)
    console.log(variables);
    try {
        await handleFormSaved()
        const res = await mutate({ variables, updateQueries })

        if (res.data.editTeam) {
          delete auditLogDetails['id']
          delete auditLogDetails['memberId']
          delete auditLogDetails['primary']
          auditLogDetails.members = res.data.editTeam.members
          createAuditLog({
            action: I18N.get('auditLog.admin.careTeam.edit'),
            details: auditLogDetails,
            request: variables,
            response: res
          })
          message.success('Edit care team success')
          goPath('/careteams/')
        }
    } catch(e) {
        openErrorModal(GQLHelper.formatError(e))
        createAuditLog({
          action: I18N.get('auditLog.admin.careTeam.edit'),
          details: auditLogDetails,
          request: variables,
          response: e,
          success: false
        })
    }
  }

  async deleteTeam() {
    const { deleteTeam, id, closeModal, openErrorModal, handleFormSaved, teamData } = this.props
    const variables = { id }
    const auditLogDetails = {
      name: teamData.name,
      description: teamData.description,
      healthCondition: teamData.healthCondition,
      email: teamData.email && teamData.email.address,
      phone: teamData.phone,
      members: teamData.members,
      createdBy: teamData.createdBy && teamData.createdBy.profile.fullName,
      createdAt: moment(teamData.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
      modifiedBy: teamData.modifiedBy && teamData.modifiedBy.profile.fullName,
      modifiedAt: teamData.modifiedAt && moment(teamData.modifiedAt).format('MMM-DD-YYYY hh:mm a:ss'),
    }

    closeModal()

    try {
      await handleFormSaved()
      const res = await deleteTeam({ variables })
      createAuditLog({
        action: I18N.get('auditLog.admin.careTeam.delete'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      if (res.data.deleteTeam) {
        message.success('Delete Care Team Success')
        goPath('/careteams/')
      }
    }
    catch (err) {
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action: I18N.get('auditLog.admin.careTeam.delete'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const wrapperData = graphql(deleteTeamGQL, {name : 'deleteTeam'})(graphql(editTeamGQL, {name : 'editTeam'})(Container))

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.careteamId')
  return {
    id,
    ...state.Careteam.CareTeamEdit,
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    updateForm: (initData) => dispatch(actions.updateForm(initData)),
    resetForm: () => dispatch(actions.resetForm()),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openConfirmModal: (resolve, submit) => dispatch(modalAction.openConfirmModal(resolve, submit)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    openModal: (content, modalProps) => dispatch(modalAction.openModal(content, modalProps)),
    closeModal: () => dispatch(modalAction.closeModal()),
  }
}

Container.propTypes = {
  id: PropTypes.string,
  onFieldsChange: PropTypes.func,
  resetForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  updateForm: PropTypes.func,
  teamData: PropTypes.object,
  route: PropTypes.object,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  isFormSaved: PropTypes.bool,
  setBreadCrumb: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  p_managers: PropTypes.array,
  managers: PropTypes.array,
  p_doctors: PropTypes.array,
  doctors: PropTypes.array,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

export default createContainer(wrapperData,
  mapState, mapDispatch
)
