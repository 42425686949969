import types from 'patientModule/enrolledProgramInfo/SurveyAlert/constants/AlertListConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'reset',
  'resetForm',
  'updateForm',
  'onFieldsChange'
])

const toggleAlertForm = (isShowAlertForm=true) => {
  return {
    type: types.TOGGLE_ALERT_FORM,
    isShowAlertForm
  }
}

const addTab = (panes, activeKey)=> {
  return {
    type : types.ADD_TAB,
    panes,
    activeKey
  }
}
const removeTab = (activeKey)=> {
  return {
    type : types.REMOVE_TAB,
    activeKey
  }
}
const onTabChange = (activeKey)=> {
  return {
    type : types.ON_TAB_CHANGE,
    activeKey
  }
}

export default {
  ...commonActionsObject,
  toggleAlertForm,
  addTab,
  removeTab,
  onTabChange
}
