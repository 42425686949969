import { React, _ } from 'ihcomponent';
import { renderValidatableInput } from '../../../util';

export default (props) => {
  const { onChange, options } = props;

  const onOptionChange = (value, index) => {
    options[index].label = value;
    onChange(options);
  };

  const onOptionAdd = () => {
    options.push({ label: '' });
    onChange(options);
  };

  const onOptionRemove = (index) => {
    // Reserve react key for better performance
    delete options[index]
    onChange(options);
  };

  return (
    <div className="single-select">
      {options.map((option, index) => (
        <div key={index} className="option-item">
          {renderValidatableInput(option.label, {
            type: 'textarea',
            size: 'large',
            autosize: true,
            onChange: ({ target }) => onOptionChange(target.value, index),
          })}

          {index > 1 && (
            <button className="link-like" onClick={() => onOptionRemove(index)}>+</button>
          )}
        </div>
      ))}

      <button className="link-like" onClick={onOptionAdd}>+ Add an Answer</button>
    </div>
  );
};
