import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import Draggable from 'react-draggable';

import '../style/draggablePopup.styles.scss';

// draggable and resizable popup
const DraggablePopup = (props) => {
  const { isVisible, draggableOptions, containerStyles, children, onClose } = props;
  return isVisible ? (
    <Draggable
      bounds={{ top: -120, left: -500, right: 520, bottom: 450 }}
      handle='.draggable-popup-handle'
      {...draggableOptions}
    >
      <div 
        className='draggable-popup-container'
        style={{ 
          width: 600, minWidth: 330, maxWidth: 600,
          height: 330, minHeight: 320, maxHeight: 600,
          top: 100, left: (window.innerWidth / 2) - 300, // 300 is half of container width
          overflow: 'auto', resize: 'both',
          ...containerStyles 
        }}
      >
        <div className='draggable-popup-handle'>
          <span>&nbsp;</span>
          <span className='title'>&nbsp;</span>
          <Icon type='close' onClick={onClose}/>
        </div>
        <div className='draggable-popup-content'>
          {children}
        </div>
      </div>
    </Draggable>
  ): null;
};

DraggablePopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  draggableOptions: PropTypes.object, 
  containerStyles: PropTypes.object
};

export default DraggablePopup;