import moment from 'moment';

import { Input,Form,AutoComplete,Checkbox,Row,Col,Card,Button,Modal,Select } from 'antd';
import getHubList from '../query/getHubList';
import React, { Component } from 'react';
import {compose, graphql} from "react-apollo/index";
import { Query } from "react-apollo";
import Client from 'libModule/gqlClient';
import updateHubMember from '../query/updateHubMember';
import { message } from 'ihcomponent';
import removeDeviceFromHub from "../query/removeDeviceFromHub";
import { patientGQL } from "../../../graphql/user";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import {connect} from "react-redux";
import { GQLHelper } from 'libModule/utils';

const Option = AutoComplete.Option;

const Item = Form.Item;
const deviceMap = {
    'BP':'Blood Pressure',
    'HS':'Weight Scale'
}
class HubInfoComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedHub: ''
        }
    }

    getOptions(deviceHubList) {
        const options = deviceHubList.map(item => {
            const {serialNumber} = item;

            return <Option key={item.id} serial={serialNumber} value={serialNumber}>{serialNumber}</Option>

        });

        return options;
    }

    onSelect = (value) => {
        this.setState({
            selectedHub: value
        })
    }

    onChange=(value)=>{
        if(value==''||!value){
            this.setState({
                selectedHub:''
            })
        }
    }

    renderAutoComplete(openHubList) {
        const children = [...this.getOptions(openHubList)];
        const { disabled, isBtnDisabled }  = this.props;
        // const { disabled,isBtnDisabled }  = _.get(this,'props.state.hubInfo');

        // return <Query
        //     query={getHubList}
        // >
        //     {//handle data:
        //         ({loading, error, data, fetchMore}) => {
        //             //if (loading) return "Loading...";
        //             if (error) return `Error! ${error.message}`;
        //             const children = loading ? ['loading'] : [...this.getOptions(data)];
                    return (
                        <Select
                            dropdownMatchSelectWidth={true}
                            dataSource={children}
                            style={{ width: 200 }}
                            allowClear={true}
                            onSelect={this.onSelect}
                            onChange={this.onChange}
                            showSearch
                            disabled={disabled}
                            notFoundContent='Serial Number not Found'
                        >
                            { children }
                        </Select>
                    )
        //         }
        //     }
        // </Query>
    }

    async handleRemoveHub(patientHasHub){
        const { user,openErrorModal } = this.props;
        const userId = user.id;
        const { serialNumber,devices } = patientHasHub;

        return new Promise((resolve,reject)=> {

                Modal.confirm({
                    title:  'Unassign Device for this patient?',
                    onOk: async() => {
                        await this.updateHubMember('',serialNumber,devices);
                    },
                    okText: 'Unassign Device',
                    cancelText: `Cancel`,
                    onCancel:()=>{
                        resolve(true);
                    }
                })
        })

    }

    renderRemoveHub(patientHasHub){
        const { serialNumber,devices, lastHeartbeat } = patientHasHub;
        const lastSignalStyle = { fontSize: '12px' };
        // const { disabled }  = _.get(this,'props.state.hubInfo');
        const { disabled }  = this.props;

        let title1 = `Serial Number: ${serialNumber}`;
        const lastSignalDate = `Last time seen online: ${lastHeartbeat ? moment(lastHeartbeat).format('MM/DD/YY HH:mm') : ''}`
        let title = (<div>
            <div>{title1}
                 {  patientHasHub ?
                    <Button disabled={disabled} onClick={()=>this.handleRemoveHub(patientHasHub)}
                            className='care-plan-edit-btn ih-exsmall IH-Button'
                            style={{float:'right'}}>Unassign Devices</Button> : ""
                 }
            </div>
            <div style={lastSignalStyle}>{lastSignalDate}</div>
        </div>);

        return <div>
                <Card title={title} style={{ borderTop:'unset' }}>
                    {_.map(devices,(d,i)=><p key={i}>{deviceMap[d.type]} ({d.id})</p>)}
                </Card>
               </div>
    }

    updateHubMember = (userId,serialNumber,devicesToPatient)=>{

        return Client.mutate({
            mutation:updateHubMember,
            variables:{
                memberId:userId,
                serialNumber:serialNumber,
            },
            refetchQueries:[{
                        query:getHubList,
                        fetchPolicy:'network-only'
                    }]
        })
    }

    onClick(selectedHubObj){
        const { getFieldsValue,validateFields } = this.props.form;
        const { user,openErrorModal,setInputFieldDisabled } = this.props;
        const userId = user.id;
        const { devices } = getFieldsValue();
        const { serialNumber } = selectedHubObj;
        let devicesToPatient = [];
        // let removeDevices = [];
       validateFields(async(errors, values) => {
            if(errors){
                return;
            }
           _.each(selectedHubObj.devices, (d) => {
               const {id, type, unit} = d;
               if (devices.indexOf(d.id) != -1) {
                   devicesToPatient.push({
                       id, type, unit
                   })
               }
               // }else{
               //     removeDevices.push({
               //         id,type,unit
               //     })
               // }
           })
           try {
               const res = await this.updateHubMember(userId, serialNumber, devicesToPatient);
               if (res.data) {
                   message.success('Assigned Devices');
                   this.setState({
                       selectedHub: ''
                   })
                   this.props.form.resetFields();
                   setInputFieldDisabled('hubInfo');

               }
           } catch (e) {
                console.log(e);
               openErrorModal(GQLHelper.formatError(e));
           }
       })
            // Client.mutate({
            //     mutation:removeDeviceFromHub,
            //     variables:{
            //         devices:removeDevices,
            //         memberId:userId,
            //         serialNumber
            //     },
            //     refetchQueries:[{
            //         query:getHubList,
            //         fetchPolicy:'network-only'
            //     }]
            // })
        // }
    }

    renderAddHub(deviceHubList){
        const {getFieldDecorator} = this.props.form;
        const openHubList = _.filter(deviceHubList,(d)=>d.member==null);
        const { selectedHub } = this.state;
        const selectedHubObj = _.first(openHubList.filter(i=>i.serialNumber==selectedHub))||[];
        // const { disabled }  = _.get(this,'props.state.hubInfo');
        const { disabled }  = this.props;

        let deviceListInit = [];
        const options = _.map(_.get(selectedHubObj,'devices',[]),(item) => {
            const { id,type } = item;
            deviceListInit.push(id);
            return <Checkbox key={item.id} value={item.id}>{deviceMap[type]}</Checkbox>
        });

        return <Form className='hubInfoForm' style={{border:'1px solid #d9d9d9',borderTop:'unset',padding:5,display:'flex',flexDirection:'column',paddingTop:20}}>
                <Item label="Hub Serial Number" required={true}>
                    {getFieldDecorator('hubId',{rules:[{
                        required:true
                    }]})(this.renderAutoComplete(openHubList))}
                </Item>
                <Item label="Devices">
                    {getFieldDecorator('devices', {
                        initialValue: deviceListInit
                    })(
                        <Checkbox.Group style={{width: '100%'}} disabled>
                            {options}
                        </Checkbox.Group>
                    )}
                </Item>
                    <Button type='primary' disabled={disabled} onClick={()=>this.onClick(selectedHubObj)} style={{float:'right',width:'fit-content',alignSelf:'center'}}>Assign Device</Button>
               </Form>
    }

    render() {

        const { user } = this.props;
        const userId = _.get(user,'id');
        const deviceHubList = _.get(this,'props.data.deviceHubList.data',[]);
        const patientHasHub = _.first(_.filter(deviceHubList,(d)=>_.get(d,'member.id')==userId));

        return <div className='IH-table'>
                {patientHasHub ? this.renderRemoveHub(patientHasHub) : this.renderAddHub(deviceHubList)}
               </div>
    }
}

const user = graphql(patientGQL, {
    options: (ownProps)=>{
        const userId  = _.get(ownProps,'currentProgram.user.id');
        return {
            variables: {
                id: userId
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props:({data})=>{

        const { loading,user } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        if(user) {
            return {
                user
            };
        }
    }
});
const withData = graphql(getHubList);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
HubInfoComponent = Form.create({onFieldsChange(props){
    if (props.handleOnFocus) {
        props.handleOnFocus('hubInfo')
    }
}})(HubInfoComponent);

export  default compose(connect(null,mapToDispatch), withData,user)(HubInfoComponent);
