import React from 'react';
import { Form } from 'antd';
import BehavioralGoals from '../component/GoalStatement/BehavioralGoals';
import goalHelper from '../component/helper/goalHelper';

import '../style/goalStatement.styles.scss';

// Used in patient profile ONLY
let BehavioralGoalsContainer = class extends React.Component{
    render(){
      return (
      <div className='BehavioralGoalContainer' id='behavioral-goal-container'>
          <BehavioralGoals {...this.props} showBehavioralGoals showDiabetesNote />
        {/* {showOldCareplanGoal && isWorkList&& <CarePlanCarryOver {...this.props} carryOverSubmit={() => carryoverHelper.submitGoals(this)} path='goals' className='historicalGoals-worklist'/> } */}
      </div>)
    }
}


BehavioralGoalsContainer = Form.create({
  onFieldsChange: (props) => {
    const { onChange } = props;
    if(typeof onChange ==='function'){
      let editEnrolledProgramVar = goalHelper.getMutateVariableGoals(props);
      let shouldEditEnrolledProgram = true;
      onChange({
        shouldEditEnrolledProgram,
        editEnrolledProgramVar,
        props
      });
    }
  }
})(BehavioralGoalsContainer);

BehavioralGoalsContainer.title = 'Behavioral Goals';
BehavioralGoalsContainer.displayName = 'BehavioralGoalsContainer';
export default BehavioralGoalsContainer;
