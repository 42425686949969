import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VitalFormContainer from 'ProgramIncompleteModule/Careplan/containers/VitalFormContainer'
import MainPage from 'ProgramIncompleteModule/Careplan/containers/MainPageContainer'
import actions from 'ProgramIncompleteModule/Careplan/actions/CommonActions'
import types from 'ProgramIncompleteModule/Careplan/constants/CommonConstants'
import AssessmentFormContainer from 'ProgramIncompleteModule/Assessment/containers/FormContainer'
import SurveyFormContainer from 'ProgramIncompleteModule/Survey/containers/FormContainer'

// container
class Container extends Component {
  static displayName = 'program/ProgramIncomplete/Careplan/containers/CommonContainer'

  componentDidMount() {
    const { changePage } = this.props
    changePage()
  }
  render() {
    return (
      <div className='vsm-careplan-page'>
        {this.renderCurrentPage()}
      </div>
    )
  }

  renderCurrentPage() {
    const { Common, changePage, program } = this.props
    if (!program) {
      console.error('program created error, program is: ', program)
      return <div>There is an error retrieving your data, please try refreshing browser.</div>
    }
    const { currentPage } = Common
    const commonProps = {
      changePage,
      program
    }

    switch(currentPage) {
      case types.CAREPLAN_MAIN_PAGE:
        return <MainPage {...Common} {...commonProps} />
      case types.CAREPLAN_VITAL_FORM_PAGE:
        return <VitalFormContainer {...commonProps} />
      case types.ASSESSMENT_FORM_PAGE:
        return <AssessmentFormContainer {...commonProps} />
      case types.SURVEY_FORM_PAGE:
        return <SurveyFormContainer {...commonProps} />
      default:
        return <MainPage {...Common} {...commonProps} />
    }
  }
}

// mapState
const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramIncomplete.Careplan
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    changePage: (page) => dispatch(actions.changePage(page))
  }
}


// PropTypes validation
Container.propTypes = {
  changePage: PropTypes.func,
  program: PropTypes.object,
  Common: PropTypes.object,
  DeviceForm: PropTypes.object
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Container)
