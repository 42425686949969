import React from 'react';
import WorkStationWrapper from '../container/WorkStationWrapper';
import {compose, graphql} from "react-apollo/index";
import { getCoveredMembersQuery } from '../../graphql/coveredMembers';
import { SignOffCursorClass } from '../helper/signOffHelper';

import '../style/index.scss';

window.signOffCursor = new SignOffCursorClass();

const WorkStationMainContainer = class extends React.Component{
    render() {
        return <WorkStationWrapper {...this.props}/>
    }
}
const withData = graphql(getCoveredMembersQuery,{
    options:()=>{
      return {
        fetchPolicy:'network-only'
      };
    },
    props:({data})=>{
      const { loading, getCoveredMembers, refetch } = data;

      const { members, coverAll } = getCoveredMembers || {};
      const coveredMembers = _.map(members, (m = {}) => ({ id: m.memberId, ...m }));
      
      return {
        loadingCoveredMembers: loading,
        refetchCoveredMembers: refetch,
        coveredMembers,
        coverAll,
      };
    }
})

export default compose(withData)(WorkStationMainContainer);
