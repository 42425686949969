import { scrollToBottom } from 'libModule/helpers/scrollTo';
import { React, IHLoading } from 'ihcomponent';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import dischargeEnrolledProgram from 'modulesAll/graphql/dischargeEnrolledProgram';
import resumeEnrolledProgram from 'modulesAll/graphql/resumeEnrolledProgram';
import resultListMinimal from '../../../graphql/resultListMini';
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";

import { graphql } from 'react-apollo';
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import PatientProgramTaskListContainer from './PatientProgramTaskListContainer';
import ProgramInfoTab from './ProgramInfoTab';
import DischargeConfirmContainer from './DischargeConfirmContainer';
import SuspendConfirmContainer from '../../main/containers/SuspendConfirmContainer';
import { RequestCache } from 'libModule/utils'
import PatientAlertTableContainer from '../../alertTable/containers/PatientAlertTableContainer'
import AllNotes from '../../../../modules/allNotes/List/containers/ListContainer'
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import ReportModal from '../../../report/containers/ReportModalContentContainer'
import { setResuming, setExtendStep, resetExtendValueType } from '../actions/EnrolledProgramInfoActions';
import { providerNotesModalIsVisibleAction } from '../../../call/actions';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { setTab } from '../actions/ProgramInfoTab';
import moment from 'moment-timezone';
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import { Tabs, Badge, Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReactDOM from 'react-dom';
import CarePlanContainer from '../../../careplanNew/container/careplanContainer';
import { LabResults } from 'patientModule/main/containers/LabResults';
import FoodLog from '../../../foodLog/containers/FoodLogContainerWrapper';
import LogBook from '../../../logBook/cotainers/LogBookContainerWrapper';
import LogBookContainer from '../../../logBook/cotainers/LogBookContainer';
import MedicineLog from 'modulesAll/medicine/containers/MedicineContainer';
import $ from 'jquery';
import foodLogHelper from '../../../foodLog/helper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import VitalsMonitoringContainer from "../../../visitWorkList/container/VitalsMonitoringContainer";
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'
// import 'antd/dist/antd.css'
import 'ant-design-draggable-modal'
import 'modulesAll/allNotes/style/index.scss';
// import CareplanTab from '../Careplan/containers/CommonContainer';
import VitalList from '../Careplan/containers/VitalListContainer'
// import VitalForm from 'modules/program/ProgramEdit/Careplan/reducers/VitalFormReducer'
// import VitalFormContainer from '../Careplan/containers/VitalFormContainer';
import { convertArraySlotsToMergedWeeklySchedule } from 'modulesAll/utils/helpers/task';

import { compose } from 'recompose';
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import CreateNote from '../../../alerts/InterventionNotes/containers/AddInterventionNoteFormComponentNoReading'
import { goPath } from "../../../../lib/utils";
import { routerWillLeaveHelper, deleteDraft } from '../helper/helpers';
import HSAreaChartContainer from '../../../charts/containers/HSAreaChartContainer';
import TMAreaChartContainer from '../../../charts/containers/TMAreaChartContainer';
import POAreaChartContainer from '../../../charts/containers/POAreaChartContainer';
import BGLineChartContainer from '../../../charts/containers/BGLineChartContainer';
import BPLinChartContainer from '../../../charts/containers/BPLineChartContainer';
import ExerciseAreaChartContainer from "../../../charts/containers/ExerciseAreaChartContainer";
import PatientSmartAlertTableContainer from '../../alertTable/containers/PatientSmartAlertTableContainer';
import TaskAssignmentByPatientContainer from '../../../taskAssignment/containers/TaskAssignmentByPatientContainer';

import LabAddAndListContainer from '../../../visitWorkList/container/LabAddAndListContainer';
import { ALERT_COUNT_MAX, FOODLOG_COUNT_MAX } from '../../../../lib/constants';
import VitalsSummaryPrintoutModal from '../components/VitalsSummaryPrintoutModal';
import LogBookOrChartView from '../components/LogBookOrChartView';
import POLogBookContainer from '../../../logBook/cotainers/POLogBookContainer';

import getClinicGoalTemplate from '../../../graphql/getClinicGoalTemplates';
import { matchContionsWithOptions } from '../../../careplanNew/component/helper/goalHelper';
import { EVENT_TYPES } from '../../../idleTime/constant';
import { eventHelperCreator, EVENT_MAPS } from '../../../../lib/helpers/event-helpers';
import { removeMTPRBlockAction } from '../../../auth/actions/Logout';
import ReengagementContainer from "../../Reengagement/container/ReengagementContainer";
import { makeCGMContextConsumer } from '../../../CGM/context/makeCGMContextConsumer';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';
import { CGMHelperServices } from '../../../CGM/helpers';
import { CGMChartContainer } from '../../../CGM/chart/containers/CGMChartContainer';
import { cgmBrandNameMap } from '../../../CGM/context/CGMContext';
import { makeCellularDeviceContextConsumer } from '../../../CellularDevices/context/makeCellularDeviceContextConsumer';

const TabPane = Tabs.TabPane;
const foodLogEventHelper = eventHelperCreator(EVENT_MAPS.foodLog, 'profileFoodLog');

const EnrolledProgramInfoComponent = class extends React.Component {

    static displayName = 'patient/enrolledProgramInfo/containers/EnrolledProgramInfoComponent'
    static propTypes = {
        resuming: PropTypes.bool,
        data: PropTypes.object,
        dischargeEnrolledProgram: PropTypes.func,
        currentProgram: PropTypes.object,
        enrolledProgramId: PropTypes.string,
        refetchEnrolledProgramList: PropTypes.func,
        setResuming: PropTypes.func,
        openModal: PropTypes.func,
        closeModal: PropTypes.func,
        openErrorModal: PropTypes.func,
        resumeEnrolledProgram: PropTypes.func,
        initDevicesStatusTableInfo: PropTypes.func,
        setExtendStep: PropTypes.func,
        resetExtendValueType: PropTypes.func,
        callActions: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            careplanIsDisabled: false,
            labResultIsDisabled: false,
            activeKey: '0',
            enabledField: '',
            showAddPNModal: false,
            showVitalsModal: false
        }
        this.routerWillLeaveClickOK = this.routerWillLeaveClickOK.bind(this);
        this.index = null
    }

    componentWillMount() {
        this.props.setExtendStep(1);//reset 'extend component' src/modules/patient/programTab/components/DescArea.js
        this.props.resetExtendValueType();// reset input number for days and drop down for week,month
        foodLogEventHelper.listen(this.foodLogEventHandlerCb);
    }

    setCarePlanStatus = (careplanIsDisabled, enabledField) => {
        this.setState({
            careplanIsDisabled,
            enabledField
        })
    }

    setLabResStatus = (labResultIsDisabled)=>{
        this.setState({
            labResultIsDisabled
        })
    }

    componentWillUpdate(nextProps, nextState) {

        if (nextProps.currentProgram && (nextProps.currentProgram.status !== this.props.currentProgram.status)) {
            RequestCache.set('refetch-enrolledProgramList', this.props.refetch());
        }

        if (nextProps.enrolledProgramId !== this.props.enrolledProgramId) {
            this.props.setExtendStep(1); //reset 'extend component' src/modules/patient/programTab/components/DescArea.js
            this.props.resetExtendValueType(); // reset input number for days and drop down for week,month
        }
    }

    componentWillReceiveProps = (nextProps) => {
      // sync tab with selected tab from workstation
      if(nextProps.profileTabSelected !== this.props.profileTabSelected && nextProps.profileTabSelected) {
        this.onChangeTab(nextProps.profileTabSelected);
      }
    }

    routerWillLeaveClickOK() {
        const { updateTabSelected } = this.props;
        this.setState((preState) => ({
            careplanIsDisabled: preState.careplanIsDisabled ? !preState.careplanIsDisabled : preState.careplanIsDisabled,
            labResultIsDisabled: preState.labResultIsDisabled  ? !preState.labResultIsDisabled : preState.labResultIsDisabled
        }), () => updateTabSelected(this.index))

        //leave without saving. delete draft
        const localStorageKey = 'carePlanDraft' + this.props.data.enrolledProgram.id;
        deleteDraft(localStorageKey)
    }

    handleInteraction = (type) => (description) => {
        const { onInteraction } = this.props;
        if (onInteraction) {
            onInteraction(type, description);
        }
    }

    onChangeTab(index) {
        this.index = index
        const { tabSelected, updateTabSelected } = this.props
        if(this.props.isInChat) {
            Mixpanel.track('clicked',`${tabSelected}_redirect_tag_page`,'message', {PATIENT_ID : this.props.patientId});
        }
        if (this.state.careplanIsDisabled){
            routerWillLeaveHelper(this.routerWillLeaveClickOK)
        }
        else if(this.state.labResultIsDisabled) {
            routerWillLeaveHelper(this.routerWillLeaveClickOK,false,'Lab Result');
        } else {
            updateTabSelected(index);
        }
    }
    mkTabPane = ({ key, name, content }) => {
        const { tabSelected } = this.props;
        const patientProfileWidth = $('.vsm-patient-member-detail').width();
        const style = {
            background: 'white',
            padding: patientProfileWidth <= 800 ? '20px 20px' : '20px 40px',
            // marginBottom: '120px'
        }
        return <TabPane tab={name} key={key} style={style} forceRender={true}>
            {key == tabSelected ? content : ''}
        </TabPane>
    };

    renderModal() {
        const ownProps = this.props;
        const { showAddPNModal } = this.state;

        //  modal pop up from Amazon Connect in MainLayoutContainer
        const dateFormat = 'MM-DD-YYYY HH:mm';
        return <DraggableModal visible={showAddPNModal}
                      className='AddNoteModal'
                      onCancel={() => {
                        this.setState({ showAddPNModal: false })
                      }}
                      title="Add Provider Note"
                      footer={null}
                      destroyOnClose={true}
                      maskClosable={false}
                      initialWidth={700}
                      initialHeight={400}
                      zIndex={999}
                >
                    <CreateNote
                        noteType='provider_note'
                        patientId={ ownProps.patientId}
                        programId={ownProps.currentProgram.id}
                        programName={ownProps.currentProgram.name}
                        canRestUUid={ownProps.canRestUUid}
                        onInteraction={this.handleInteraction(EVENT_TYPES.PROVIDER_NOTES)}
                        closeModal={() => {
                            this.setState({ showAddPNModal: false })
                        }}
                    />
                </DraggableModal>
    };

    routeToPatientList(){
        const { removeMTPRBlockAction } = this.props;
        removeMTPRBlockAction(true);
        const cb = () => goPath('/patients');
        cb();
    };

    renderNotAuthorizedConfirm() {
        const button = <Button onClick={() => this.routeToPatientList()}>OK</Button>

        return <Modal visible={true} title={'Not Authorized'} closable={false} footer={button}>
            You are not authorized to view this patient’s program. We will route you back to the patients list. In the meantime, please check to make sure you are in this patient’s care team.
        </Modal>

    }

    setShowVitalsModal=(showVitalsModal)=>{
        this.setState({
            showVitalsModal
        })
    }
    renderVitalsMonitoringModal=()=>{
        const { state,props,setShowVitalsModal } = this;
        const { showVitalsModal } = state;
        const { currentProgram, patientId, params } = props;
        const fullName = _.get(props,'user.profile.fullName');
        return <Modal visible={showVitalsModal} destroyOnClose={true} closable={false} footer={false} className='vitalMonitorModal' title={'Vitals Monitoring Schedule'}>
                    <VitalsMonitoringContainer 
                      patientId={patientId || params.patientId}
                      program={currentProgram} 
                      callback={setShowVitalsModal} 
                      fullName={fullName}
                    />
               </Modal>
    }

    componentWillUnmount = () => {
      this.resizeObserver = null;
      this.chartRefs = {};
      foodLogEventHelper.remove(this.foodLogEventHandlerCb);
    }

    foodLogEventHandlerCb = () => {
        const { refetchUnratedFoodLogs } = this.props;
        refetchUnratedFoodLogs();
    }

    componentDidUpdate = () => {
      const vitalsWrapper = document.querySelector(`#vitals-tab-wrapper`);
      if(!vitalsWrapper) {
        this.resizeObserver = null;
        this.chartRefs = {};
        return;
      }
      // opening Vitals tab
      try {
        this.resizeObserver = new ResizeObserver(() => {
          _.debounce(() => {
            if(_.isEmpty(this.chartRefs)) return;

            for(let type in this.chartRefs) {
              const chartRef = this.chartRefs[type];
              try {
                if(chartRef.reflow)
                  chartRef.reflow();
              } catch (err) {
                console.log('chart is probably not visible');
                this.chartRefs = _.omit(this.chartRefs, type);
              }
            }
          }, 200)();
        });
        this.resizeObserver.observe(vitalsWrapper);
      } catch(err) {
        console.error(err);
        this.resizeObserver = false; // browser not supported
      }
    }

    setChartRef = (type, chart) => {
      this.chartRefs = {
        ...this.chartRefs,
        [type]: chart
      };
    }

    renderVitals = () => {
        const { enrolledProgramId, patientId, data, isInChat, params, menuOpened, patientName, viewerInfo, cgmContextValue } = this.props;
        const { CGMInfo, hasCGM } = cgmContextValue || {};
        // let width = (isInChat && menuOpened ) ? (window.innerWidth - 605) : (isInChat) ? (window.innerWidth - 544) : (menuOpened) ? (window.innerWidth - 360) : (window.innerWidth - 179);
        // // temporarily reduce width to prevent Chart and tooltip cut-off in Patient Profile, it works fine in Messages page
        // width = width - (width * 0.03);
        // if(viewOnly) {
        //   width = (window.innerWidth - (menuOpened ? 240 : (240-181)));
        //   width = width / 2; // patient care plan view is half of the rest width
        //   width = width - 60; // horizontal padding for care plan container
        //   width = width - 40; // horizontal padding of chart UI
        // }
        let width = null;
        const currentProgram = data.enrolledProgram;

        const shouldHaveCGM = !!hasCGM;

        const shouldHaveBP = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BP').length != 0;
        const shouldHaveBG = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BG').length != 0;
        const shouldHavePO = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'PO').length != 0;
        const shouldHaveTM = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'TM').length != 0;
        const shouldHaveHS = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'HS').length != 0;
        const shouldHaveExercise = _.filter(_.get(currentProgram,'tasks'),t=>t.type=='EXERCISE').length!=0;

        const currentPatient = RequestCache.get('nurse/currentPatient')
        const identification = _.get(currentPatient, 'identification[0].value');
        const careName = viewerInfo.profile.fullName;

        return (
            <div id='vitals-tab-wrapper'>
              <div id="cgm-chart">
                {
                  shouldHaveCGM 
                  && (
                    <LogBookOrChartView
                      title={(
                        <div className="vitals-title">
                          <div className="vital-title-name">
                            CGM
                            {' '}
                            <span className="vital-title-name__device-brand">
                              ({cgmBrandNameMap[CGMInfo.cgmBrand]})
                            </span>
                          </div>
                          <div className="vital-title__last-sync">
                            Last Sync:
                            {' '}
                            {
                              (CGMInfo && CGMInfo.lastSeen)
                              ? (
                                DATE_HELPERS
                                  .getTimeInClinicTimezone(CGMInfo.lastSeen)
                                  .format('MM/DD/YYYY')
                              ) : '--'   
                            }
                          </div>
                        </div>
                      )}
                      type={CGMHelperServices.brandFieldName}
                      intervals={['week1', 'week2', 'month1', 'month2', 'month3', 'month6']}
                      defaultInterval="month1"
                      chartView={<CGMChartContainer patientId={patientId || params.patientId} />}
                    />
                  )
                }
              </div>
                {shouldHaveBP && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Blood Pressure</div>&nbsp;(mm/Hg)</div>}
                    type="BP"
                    intervals={['week1', 'week2', 'month1', 'month2', 'month3', 'month6']}
                    defaultInterval="month1"
                    logbookView={<LogBookContainer patientId={patientId || params.patientId} currentProgram={currentProgram} type="BP" isInChat={isInChat || false}/>}
                    chartView={<BPLinChartContainer enrolledProgramId={enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}
                    chartCallback={chart => this.setChartRef('BP', chart)}
                    />}
                />}
                {shouldHaveBG && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Blood Glucose</div>&nbsp;(mg/dL)</div>}
                    patientId={patientId}
                    type="BG"
                    intervals={['week1', 'week2', 'month1', 'month2', 'month3', 'month6']}
                    defaultInterval="month1"
                    logbookView={<LogBookContainer patientId={patientId || params.patientId} currentProgram={currentProgram} type="BG" isInChat={isInChat || false}/>}
                    chartView={<BGLineChartContainer enrolledProgramId={enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}
                    chartCallback={chart => this.setChartRef('BG', chart)}
                    />}
                />}
                {shouldHavePO && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Blood Oxygen</div></div>}
                    patientId={patientId}
                    type="PO"
                    intervals={['week1', 'week2', 'month1', 'month2', 'month3', 'month6', 'year1']}
                    defaultInterval="month1"
                    logbookView={<POLogBookContainer patientId={patientId || params.patientId} currentProgram={currentProgram} isInChat={isInChat || false}/>}
                    chartView={<POAreaChartContainer enrolledProgramId={enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}
                    chartCallback={chart => this.setChartRef('PO', chart)}
                    /> }
                />}
                {shouldHaveHS && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Weight</div></div>}
                    patientId={patientId}
                    type="HS"
                    intervals={['week1', 'week2', 'month1', 'month2', 'month3', 'month6', 'year1']}
                    defaultInterval="month3"
                    chartView={<HSAreaChartContainer currentProgram={currentProgram} patientId={patientId || params.patientId} chartWidth={width}
                    chartCallback={chart => this.setChartRef('HS', chart)}
                    />}
                />}
                {shouldHaveTM && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Temperature</div></div>}
                    patientId={patientId}
                    type="TM"
                    intervals={['week1', 'week2', 'month1', 'month2', 'month3']}
                    defaultInterval="week2"
                    chartView={<TMAreaChartContainer enrolledProgramId={enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}
                    chartCallback={chart => this.setChartRef('TM', chart)}
                    />}
                />}
                {shouldHaveExercise && <LogBookOrChartView
                    title={<div className="vitals-title"><div className="vital-title-name">Exercise</div></div>}
                    patientId={patientId}
                    type="EXERCISE"
                    intervals={['week1', 'week2', 'month2', 'month3', 'month6', 'year1']}
                    defaultInterval='week2'
                    chartView={<ExerciseAreaChartContainer currentProgram={currentProgram} enrolledProgramId={enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}
                    chartCallback={chart => this.setChartRef('EXERCISE', chart)}
                    />}
                />}
                <PatientSmartAlertTableContainer enrolledProgramId={enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false}
                                                careName={careName} identification={identification}/>
                <PatientAlertTableContainer enrolledProgramId={enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false} />
            </div>
        );
    }

    render() {
        const { tabSelected, updateTabSelected, patientName, unratedAlerts, unratedFoodLogs, viewerId, patientId, route, setBreadCrumb, openAlertModal, setInitDeviceData, params, isInChat, user, menuOpened,router,
            billableGoalOptions, billable, nonBillableGoalOptions, nonBillable,  nonBillableDiseases, billableDiseases, patientReferral, refetchPatientReferral } = this.props;
        const userId = patientId ? R.last(R.split(':', atob(patientId))) : null;
        const { careplanIsDisabled,labResultIsDisabled,showAddPNModal } = this.state;
        const { props } = this;
        const careName = props.viewerInfo.profile.fullName;
        const currentPatient = RequestCache.get('nurse/currentPatient')
        const identification = _.get(currentPatient, 'identification[0].value')
        if (this.props.data.loading) {
            return <IHLoading className="vsm-patient-enrolledprogram-main" />;
        }
        const displayDate = (ts) => {
            const m = moment(ts);
            if (m.format() === 'Invalid date') return 'N/A';
            return moment(ts).format("MMM Do YYYY");
        }

        const displayDuration = (duration) => {
            const len = _.get(duration, 'length');
            const cal = _.get(duration, 'calendar');
            if (!len || !cal) return 'N/A';
            return `${len} ${cal.toLowerCase()}${len > 1 ? 's' : ''}`;
        }
        // 1/24/18 - JV - For Barbabos project, do not need Patient Daily Tasks component
        const currentProgram = this.props.data.enrolledProgram
        const programObject = new EnrolledProgramClass(currentProgram);
        const { Common, VitalForm, changePage } = this.props
        const program = this.props.data.enrolledProgram
        const commonProps = {
            changePage,
            program
        }

        if (!currentProgram) {
            return <div>
                {this.renderNotAuthorizedConfirm()}
            </div>;
        };


        const shouldHaveBG = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BG').length != 0;
        const shouldHaveBP = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BP').length != 0;
        const showVitalsPrintButton = shouldHaveBG || shouldHaveBP;
        
        const tabBarExtraContent = (
            <div>
                <Button type='primary' onClick={()=>this.setShowVitalsModal(true)} style={{ marginRight: 10 }}>Vitals Monitoring Schedule</Button>
                {showVitalsPrintButton && <VitalsSummaryPrintoutModal
                    enrolledProgramId={this.props.enrolledProgramId}
                    patientId={this.props.patientId || this.props.params.patientId}
                    user={user}
                    currentProgram={currentProgram}
                />}
            </div>
        );

        // Controlling the width of highcharts to make it responsive
        // There're 4 cases of the width according to the status of chat channel and left menu.
        // They are: (under 1440x900 resolution)
        // Menu open and chat open    : 745px
        // Menu closed and chat open  : 896px
        // Menu open and chat closed  :1080px
        // Menu closed and chat closed:1261px
        // after calculation, pass the parameter to component
        const width = (isInChat && menuOpened ) ? (window.innerWidth - 695) : (isInChat) ? (window.innerWidth - 544) : (menuOpened) ? (window.innerWidth - 360) : (window.innerWidth - 179);

        const tabs = [
            {
                key: 'vitals',
                name: <div>Vitals<Badge count={unratedAlerts} showZero={false} /></div>,
                content: (
                    <div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                            {tabBarExtraContent}
                        </div>
                        {this.renderVitals()}
                    </div>
                //     <Tabs defaultActiveKey = '0' tabBarExtraContent={tabBarExtraContent}>
                //         <TabPane  tab="Log Book View" key="0">
                //             <div style={{ backgroundColor: 'white' }} >
                //                 { shouldHaveBG ? <LogBook patientId={this.props.patientId || this.props.params.patientId} currentProgram={this.props.data.enrolledProgram} type='BG' isInChat={isInChat || false}/> :'' }
                //                 { shouldHaveBP ? <LogBook patientId={this.props.patientId || this.props.params.patientId} currentProgram={this.props.data.enrolledProgram} type='BP' isInChat={isInChat || false}/> :'' }
                //                 <PatientSmartAlertTableContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false}
                //                                                 careName={careName} identification={identification}/>
                //                 <PatientAlertTableContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} patientName={patientName} isInChat={isInChat || false} />
                //             </div>
                //         </TabPane>
                //         <TabPane  tab="Chart View" key="1">
                //             <div className="vsm-patient-enrolledprogram-main vitalContainer">
                //                 { shouldHaveBP ? <BPLinChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId}  isInChat={isInChat || false} chartWidth={width}/> :'' }
                //                 { shouldHaveBG ?  <BGLineChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}/> :'' }
                //                 { shouldHavePO && <POAreaChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}/> }
                //                 <HSAreaChartContainer currentProgram={this.props.data.enrolledProgram} patientId={this.props.patientId || this.props.params.patientId} hasHS={hasHS} chartWidth={width}/>
                //                 { shouldHaveTM && <TMAreaChartContainer enrolledProgramId={this.props.enrolledProgramId} patientId={patientId} isInChat={isInChat || false} chartWidth={width}/>}
                //                 {shouldHaveExercise ? <ExerciseAreaChartContainer currentProgram={this.props.data.enrolledProgram} enrolledProgramId={this.props.enrolledProgramId} patientId={patientId}  isInChat={isInChat || false} chartWidth={width} />:''}
                //             </div>
                //         </TabPane>
                //     </Tabs>
                )
            },
            {
                key: 'programDetails',
                name: 'Care Plan',
                content: (
                    <div className="vsm-patient-enrolledprogram-main">
                        {
                            currentProgram
                                ?
                                <CarePlanContainer
                                              user={user}
                                              data={currentProgram}
                                              currentProgram={this.props.data.enrolledProgram}
                                              refreshProgram={this.props.data.refetch}
                                              consentInfo={this.props.consentInfo}
                                              refetchConsentInfo={this.props.refetchConsentInfo}
                                              refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
                                              route={this.props.route}
                                              openModal={this.props.openModal}
                                              closeModal={this.props.closeModal}
                                              dischargeEnrolledProgram={this.props.dischargeEnrolledProgram}
                                              lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                                              location={this.props.location}
                                              ICD10Code={true}
                                              manualInput={true}
                                              router={router}
                                              setCarePlanStatus={this.setCarePlanStatus}
                                              careplanIsDisabled={careplanIsDisabled}
                                              isInChat={isInChat}
                                              billable={billable}
                                              nonBillable={nonBillable}
                                              billableGoalOptions={billableGoalOptions}
                                              nonBillableGoalOptions={nonBillableGoalOptions}
                                              nonBillableDiseases={nonBillableDiseases}
                                              billableDiseases={billableDiseases}
                                              onInteraction={this.handleInteraction(EVENT_TYPES.CARE_PLAN)}
                                              goalLoading={this.props.goalLoading}
                                              patientReferral={patientReferral}
                                              refetchPatientReferral={refetchPatientReferral}
                                              addRouteLeaveHook={this.props.addRouteLeaveHook}

                                />
                                // <ProgramInfoTab layoutType='vertical'
                                //                   data={currentProgram}
                                //                   currentProgram={this.props.data.enrolledProgram}
                                //                   refreshProgram={this.props.data.refetch}
                                //                   refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
                                //                   route={this.props.route}
                                //                   openModal={this.props.openModal}
                                //                   closeModal={this.props.closeModal}
                                //                   dischargeEnrolledProgram={this.props.dischargeEnrolledProgram}
                                //                   lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                                //                   location={this.props.location}
                                //                   ICD10Code={true}
                                //                   manualInput={true}
                                //                   setCarePlanStatus={this.setCarePlanStatus}
                                //                   careplanIsDisabled={careplanIsDisabled}
                                //                   isInChat={isInChat}
                                // />
                                : ' no program info'
                        }
                        {/*<Threshold title='Vital Signs & Thresholds' list={programObject.format('thresholdComponentProps')} />*/}
                        {/*<PatientAlertTableContainer enrolledProgramId={ this.props.enrolledProgramId } />*/}
                    </div>
                )
            },
            {
                key: 'providerNotes',
                name: 'Provider Notes',
                content: <div>
                    <AllNotes currentProgram={currentProgram} patientId={patientId} isInChat={isInChat || false} canRestUUid={false}
                              onInteraction={this.handleInteraction(EVENT_TYPES.PROVIDER_NOTES)}
                              handlePNModal={(flag) => {
                                //   this.props.providerNotesModalIsVisibleAction(flag);
                                  this.setState({ showAddPNModal: flag })
                              }
                              } />
                    <TaskAssignmentByPatientContainer patientId={patientId} isInChat={isInChat || false}/>
                    {/*<MedicineLog patientId = {this.props.patientId||this.props.params.patientId}/>*/}
                </div>
            },
            {
                key: 'foodlog',
                // name: 'Food Diary',
                name: <div>Food Diary<Badge count={unratedFoodLogs} showZero={false} style={{ marginLeft: 5 }} /></div>,
                content: (
                    <div style={{ backgroundColor: 'white' }} className='patientFoodLogTable'>
                        <FoodLog userId={patientId} isInChat={isInChat || false} keepEventListener={true} onInteraction={this.handleInteraction(EVENT_TYPES.FOOD_LOG)} />
                    </div>
                )
            },
            {
                key: 'labresults',
                name: 'Lab Results',
                content: (
                    <div>
                        {/* <LabResults updateTabSelected={updateTabSelected}
                                    route={this.props.route}
                                    viewerId={viewerId} userId={userId}
                                    labResultIsDisabled={labResultIsDisabled}
                                    setLabResStatus={this.setLabResStatus}
                                    location={this.props.location}
                                    {...props}
                        /> */}
                        <LabAddAndListContainer
                            userId={userId}
                            route={this.props.route}
                            viewerId={viewerId}
                            labResultIsDisabled={labResultIsDisabled}
                            setLabResStatus={this.setLabResStatus}
                            location={this.props.location}
                            {...props}
                            onInteraction={this.handleInteraction(EVENT_TYPES.LAB_RESULTS)}
                        />

                    </div>
                )
            },
            {
                key: 'medicine',
                name: 'Medication',
                content: (
                    <div>
                        <MedicineLog patientId={this.props.patientId || this.props.params.patientId}
                                     onInteraction={this.handleInteraction(EVENT_TYPES.MEDICINE)}
                                     />
                    </div>
                )
            },
            {
                key:'reengagement',
                name:'Re-engagement',
                content: <div>
                    <ReengagementContainer patientId={this.props.patientId || this.props.params.patientId}
                                           lastMeasuredAt={_.get(user,'profile.lastMeasuredAt')}
                                           user={user}
                    />
                </div>
            }
        ];

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4, width: '100%' }}>
                    <Tabs activeKey={tabSelected}
                        animated={false}
                        style={{ paddingTop: 20 }}
                        // onTabClick={(i)=>console.log(i)}
                        type="card"
                        onChange={(index) => this.onChangeTab(index)}
                        forceRender={true}
                        className='patient-profile-tabs'
                        ref={(node) => this.ref = node}>
                        {R.map(this.mkTabPane, tabs)}
                    </Tabs>
                    { showAddPNModal && <DraggableModalProvider>{ this.renderModal() }</DraggableModalProvider> }
                    { this.renderVitalsMonitoringModal() }
                </div>
            </div>
        )
      }

  clickExtendButton = () => {
    this.props.setExtendStep(2);
    this.props.setTab('programme');
    this.scrollToBottom();
  };

  scrollToBottom() {
    scrollToBottom(600, window);
  }

  renderPatientDailyTask() {
    return (<div><br /><br />
      <PatientProgramTaskListContainer {...this.props} />
    </div>);
  }

  programInfoBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('programme');
  }

  suspendBtnOnClick = () => {
    const modal = <SuspendConfirmContainer
      refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
      currentProgram={this.props.data.enrolledProgram}
    />;

    this.props.openModal(modal, {
      title: 'Suspend Patient ?',
      className: 'discharge-modal',
    });
  }

clickExtendButton = () => {
    this.props.setExtendStep(2);
    this.props.setTab('programme');
    this.scrollToBottom();
};

scrollToBottom() {
    scrollToBottom(600, window);
}

renderPatientDailyTask() {
    return (<div><br /><br />
        <PatientProgramTaskListContainer {...this.props} />
    </div>);
}

programInfoBtnOnClick = () => {
    this.scrollToBottom();
    this.props.setTab('programme');
}

suspendBtnOnClick = () => {
    const modal = <SuspendConfirmContainer
        refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
        currentProgram={this.props.data.enrolledProgram}
    />;

    this.props.openModal(modal, {
        title: 'Suspend Patient ?',
        className: 'discharge-modal',
    });
}

dischargeBtnOnClick = () => {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
        action: I18N.get('auditLog.provider.patient.dischargeProgramme'),
        patientNRIC,
        patientName
    })

    const dischargeConfirm = (
        <DischargeConfirmContainer
            openMadal={this.props.openModal}
            closeModal={this.props.closeModal}
            dischargeProgram={this.props.dischargeEnrolledProgram}
            currentPatient={RequestCache.get('nurse/currentPatient')}
            refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
            currentProgram={this.props.data.enrolledProgram}
            location={this.props.location}
        />
    );

    this.props.openModal(dischargeConfirm, {
        title: 'Discharge Patient ?',
        className: 'discharge-modal',
    });
}

    genNEHRBtnOnClick = () => {
        const p = {
            // title: 'Generate NEHR Report',
            title: 'Generate Report',
            showModal: true,
            showHeaderCloseButton: false,
            body: <ReportModal startDate={this.props.data.enrolledProgram.startDate} reason="Condition change"
                               enrolledProgramId={this.props.data.enrolledProgram.id} enrolledProgramName={this.props.data.enrolledProgram.name} />
            ,
        }
        return this.props.openModal(p.body, p)
    }

    genFullReportBtnOnClick = () => {
        const p = {
            title: 'Generate Full Report',
            showModal: true,
            showHeaderCloseButton: false,
            body: <ReportModal startDate={this.props.data.enrolledProgram.startDate}
                               isFullReport
                               enrolledProgramId={this.props.data.enrolledProgram.id}
                               enrolledProgramName={this.props.data.enrolledProgram.name} />
        };
        this.props.openModal(p.body, p)
    }

    replaceDeviceBtnOnClick = () => {
        this.scrollToBottom();
        this.props.setTab('device');
    }

    returnDeviceBtnOnClick = () => {
        this.scrollToBottom();
        this.props.setTab('device');
    }
};

const addResumeEnrolledProgram = graphql(resumeEnrolledProgram, {
    name: 'resumeEnrolledProgram',
    options: ownProps => ({
        variables: {
            id: ownProps.currentProgram.id
        },
        refetchQueries: ['lightResultsFromToday']
    })
});

const addDischargeEnrolledProgram = graphql(dischargeEnrolledProgram, {
    name: 'dischargeEnrolledProgram',
    options: ownProps => ({
        variables: {
            id: ownProps.enrolledProgramId
        }
    })
});

const addEnrolledProgramGQL = graphql(enrolledProgramGQL, {
    options: ownProps => ({
        variables: {
            id: ownProps.enrolledProgramId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => { return { data, refetch: data.refetch } },
});

const getAlertVariables = (props) => {
    const { enrolledProgramId } = props;

    return {
        count: ALERT_COUNT_MAX,
        filters: {
            severity: ['RISK', 'CRITICAL'],
            careStatus: ["NEW", "HANDLED"],
            alertsOnly: true,
            enrolledProgramId
        }
    }
};

const unratedAlerts = graphql(resultListMinimal, {
    options: ownProps => ({
        variables: getAlertVariables(ownProps),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        return {
            unratedAlerts: _.get(data, 'resultList.pageInfo.total', 0)
        }
    }
});

const getFoodVariables = (props) => {
    const memberId = _.get(props, 'patientId') || _.get(props, 'params.patientId');
    const v = foodLogHelper.getFoodLogVariables({
        count: FOODLOG_COUNT_MAX,
        userId: memberId,
        filter: {
            reviewed: false,
            memberId: memberId
        }
    });
    return v;
}

const unratedFoodLogs = graphql(foodLogUnreadList, {
    options: ownProps => ({
        variables: getFoodVariables(ownProps),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        return {
            unratedFoodLogs: _.get(data, 'foodLogList.pageInfo.total', 0),
            refetchUnratedFoodLogs: data.refetch,
        }
    }
});

const nonBillableData = graphql(getClinicGoalTemplate, {
    options: (ownProps) => {
      const billableConditions = _.get(ownProps, 'user.profile.billableHealthConditions', []);
      let billables = _.map(billableConditions, o => {return o.code});
      let codes=[];
      let healthConditions = _.get(ownProps, 'user.profile.healthConditions');
      _.map(healthConditions, con => {
        const array = _.split(con, '::');
        if( array && array[1] && !_.includes(billables, array[1])){
            codes.push(array[1]);
        }
      })
      return {
        variables: { codes },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: ({ data }) => {
      const { loading, getClinicGoalTemplates} = data
      if(loading){
        return { goalLoading: loading }
      }

      let result = matchContionsWithOptions(getClinicGoalTemplates);
      return {
        nonBillableGoalOptions: result.options,
        nonBillable: result.conditions,
        nonBillableDiseases: result.chronicDiseases,
      }
    }
  })

const billableData = graphql(getClinicGoalTemplate, {
    options:(ownProps) =>{
        const billableConditions = _.get(ownProps.user, 'profile.billableHealthConditions', []);
        let billables = [];
        _.map(billableConditions, o => {
          billables.push(o.code);
        });
        return {
        variables: { codes: billables },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
        }
    },
    props: ({  data }) => {
        const { loading, getClinicGoalTemplates} = data;
        if(loading){
            return { goalLoading: loading };
        }

        let result = matchContionsWithOptions(getClinicGoalTemplates);

        return {
            billableGoalOptions: result.options,
            billable: result.conditions,
            billableDiseases: result.chronicDiseases,
            billableVitals: result.billableVitals //only need for billable codes
        }
    }
})

const mapState = (state, ownProps) => ({
    enrolledProgramId: ownProps.currentProgram.id,
    resuming: state.patient.enrolledProgramInfo.EnrolledProgramInfo.resuming,
    shouldRemoveMTPRBlock: _.get(state,'Logout.shouldRemoveMTPRBlock')
});

const mapDispatch = {
    setResuming,
    openModal,
    closeModal,
    openErrorModal,
    setTab,
    setExtendStep,
    resetExtendValueType,
    providerNotesModalIsVisibleAction,
    removeMTPRBlockAction
};

const enhance = compose(
    connect(mapState, mapDispatch),
    addResumeEnrolledProgram,
    addDischargeEnrolledProgram,
    addEnrolledProgramGQL,
    unratedAlerts,
    unratedFoodLogs,
    billableData,
    nonBillableData
);

const EnrolledProgramInfo = enhance(withRouter(EnrolledProgramInfoComponent));

export default makeCGMContextConsumer(makeCellularDeviceContextConsumer(EnrolledProgramInfo));
