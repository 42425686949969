import ProgramNew from 'ProgramNewModule/Common/containers/CommonContainer'
import ProgramEdit from 'ProgramEditModule/Common/containers/CommonContainer'
import ProgramIncomplete from 'ProgramIncompleteModule/Common/containers/CommonContainer'
import ProgramList from 'ProgramListModule/containers'
import ProgramListAdmin from 'ProgramListAdminModule/containers'

export {
  ProgramNew,
  ProgramEdit,
  ProgramIncomplete,
  ProgramList,
  ProgramListAdmin
}
