import { React, IHTable, _, IHSelect } from 'ihcomponent'
import {createContainer} from 'libModule/utils'
import actions from '../actions'
import PropTypes from 'prop-types';
import I18N from 'modulesAll/I18N'

const P_MAP = {
    BG : '/image/device/bg.png',
    BP : '/image/device/bp5.png',
    HR : '/image/device/bp5.png',
    HS : '/image/device/hs4.png',
    TABLET : '/image/device/ipadmini.png',
}

const Input = class extends React.Component {
  render(){
      const p = _.omit(this.props, ['initValue'])
      return <input ref="input" {...p} />
  }

  componentDidMount() {
    if (this.props.initValue) {
        this.refs.input.value = this.props.initValue
    }
  }



}

Input.propTypes = {
  initValue: PropTypes.object
}


const Component = class extends React.Component {
    static displayName = 'patient/deviceTab/containers/DeviceTable'

    componentDidMount(){
        this.props.setInitDeviceData.apply(null, this.getInitData())
    }

    componentWillReceiveProps(nextProps) {
        if(!_.isEqual(this.props.deviceTableData, nextProps.deviceTableData)){
          this.props.setInitDeviceData.apply(null, this.getInitData())
        }
    }

    render(){
        let dataSource = this.props.deviceTableData
        const tableProps = {
            rowKey : doc => doc.id,
            columns : [
                {
                    title : 'Device',
                    dataIndex : 'name',
                    key : 'name'
                },
                {
                    title : 'Picture',
                    key : 'description',
                    width : '150px',
                    className : 'v-picture',
                    render(t, doc){
                        return <img src={doc.picture} />
                    }
                },
                {
                    title : 'Status',
                    key : 'status',
                    width : '50%',
                    render : (t, doc) => {
                        return this.renderTableStatus(doc)
                    }
                }
            ],
            dataSource,
            rowSelection : {
                type : 'checkbox',
                selectedRowKeys : this.getSelectRowKeys(),
                onChange : (list) => {
                    console.log(list)
                    this.props.changeSelectTableSelectedRows(list)
                },
                getCheckboxProps: (record) => ({
                    disabled: record.id === 'tablet'
                })
            },
            pagination : {
                pageSize : 10
            }
        }

        return (
            <div className="v-table">
                <p className="v-tlt">Devices</p>
                <IHTable {...tableProps} />
            </div>
        )
    }

    getInitData(){
        const {devices} = this.props.currentProgram
        let table = []
        const value = {}
        const modelAdded = {}

        _.each(devices, (item)=>{
            let id = item.type
            if (id==='TABLET') id = 'tablet'
            table.push({
              id,
              name : I18N.get(`Devices.${item.type}.${item.model}`),
              picture : P_MAP[item.type],
              state : ''
            })
            if (id==='tablet') {

            }
            modelAdded[item.model] = true
            value[id] = {
                select : item.status !== 'PATIENT_OWNED',
                model : true,
                reason : item.address || item.other
            }

        })
        //for demo purpose
        // table.push({
        //   id: 'gateway',
        //   name: 'Gateway',
        //   type: 'Gateway',
        //   picture: process.env.DEMO ? '/device/e-device.png' : '/image/device/e-device.png'
        // })
        // value['gateway'] = {
        //   select: false,
        //   model: true,
        //   reason: ''
        // }

        if (_.find(table, ['id', 'BP']) && _.find(table, ['id', 'HR'])) {
            table = table.filter(d => d.id !== 'BP')
        }
        return [table, value]
    }

    getSelectRowKeys(){
        const rs = []
        _.each(this.props.deviceTableValue, (v, k)=>{
            if(v.select){
                rs.push(k)
            }
        })

        return rs
    }

    getSelectReasonProps(doc){
        return {
            placeholder : 'Select Reason to Not Loan',
            size : 'small',
            width : '230px',
            disabled : true,
            //value : 'owned',
            option : this.props.deviceTableStatusReasonSelectList,
            onChange : (v)=>{
                this.props.changeSelectDeviceTableStatusReason(v, doc.id)
            }
        }
    }

    renderTableStatus(doc){
        const d = this.props.deviceTableValue[doc.id]
        if (d.select) {
            return <span className="v-d-sp1">Loan to Patient</span>
        } else {
            if (false && !d.reason) {
                const sp = this.getSelectReasonProps(doc)

                return <div className="v-ipt"><IHSelect {...sp} /></div>
            } else{
                const sp = this.getSelectReasonProps(doc)
                sp.value = 'owned' //d.reason

                const inputProps = {
                    // placeholder : 'Modal',
                    placeholder : '',
                    initValue : d.reason || '',
                    onChange : (e)=>{
                        const v = e.target.value
                        this.props.changeDeviceTableStatusReasonModal(v, doc.id)
                    }
                }

                return (
                    <div className="v-ipt">
                        <IHSelect {...sp} />

                    {(true || d.modal!==false)?<Input type="text" {...inputProps} />:null}
                    </div>
                )
            }
        }
    }
}

const mapDispatchToProp = (dispatch) => {
  return {
    setInitDeviceData: (table, value) => dispatch(actions.setInitDeviceData(table, value)),
    changeSelectDeviceTableStatusReason: (v, docId) => dispatch(actions.changeSelectDeviceTableStatusReason(v, docId)),
    changeDeviceTableStatusReasonModal: (v, docId) => dispatch(actions.changeDeviceTableStatusReasonModal(v, docId)),
    changeSelectTableSelectedRows: (list) => dispatch(actions.changeSelectTableSelectedRows(list)),
  }
}

Component.propTypes = {
  deviceTableData: PropTypes.array,
  deviceTableValue: PropTypes.object,
  deviceTableStatusReasonSelectList: PropTypes.array,
  currentProgram: PropTypes.object,
  setInitDeviceData: PropTypes.func,
  changeSelectDeviceTableStatusReason: PropTypes.func,
  changeDeviceTableStatusReasonModal: PropTypes.func,
  changeSelectTableSelectedRows: PropTypes.func
}

export default createContainer(Component, ({patient})=>{
    return {
        ...patient.device
    }
}, mapDispatchToProp)
