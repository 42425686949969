import moment from 'moment';
import { CALL_ROLES } from 'modulesAll/utils/constants/role';
import { decryptRole } from 'libModule/utils';

let env = process.env.CONFIG_ENV;//window.VSM_CONFIG.NODE_ENV;
const ccpDomain = process.env.CCP_DOMAIN || 'https://unifiedcare-prod.awsapps.com/connect';
export const ccpUrl = process.env.CCP_URL || `${ccpDomain}/ccp-v2`;
export const ccpLoginUrl = process.env.CCP_LOGIN_URL || `${ccpDomain}/login`;
export const ccpLogoutUrl = process.env.CCP_LOGOUT_URL || `${ccpDomain}/logout`;

let callingPhoneNumber = '';

let makeACallError = null;

export const clicktoDial = function handleClicktoDial(phoneNumber, funcOnConnected, funcOnEnded, funcMakeACallError) {
  // onConnected = funcOnConnected
  // onEnded = funcOnEnded
  makeACallError = funcMakeACallError

  callingPhoneNumber = phoneNumber.replace(/\s+/g, '')

  makeACall()
}

export const loadConnectStreams = function loadConnectStreams(sub) {
  const currentUserRole = decryptRole();
  if (Object.values(CALL_ROLES).includes(currentUserRole)) {
    let connectStreams = document.getElementById('connect-streams');
    if (connectStreams == null) {
      const script = document.createElement("script");
      script.src = "connect-streams-min.js";
      script.async = true;
      script.id = 'connect-streams';
      document.body.appendChild(script);
      script.onload = () => {
        // add CCP to div
        addCCP();
        // subscription in MainLayoutContainer
        sub();
      };
    } else {
      // add CCP to div
      addCCP();
      // subscription in MainLayoutContainer
      sub();
    }
  }
}

export const cleanUpCCP = () => {
  if (!window.awsCCP || !window.connect) return;
  if (window.awsCCP.eventBus && window.awsCCP.eventBus.subscriptions) {
    window.awsCCP.eventBus.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }
  connect.core.terminate();
  const containerDiv = document.getElementById("callBody");
  const iframe = containerDiv && containerDiv.firstElementChild;
  if (iframe) containerDiv.removeChild(iframe);
  window.awsCCP = undefined;
}

export const logoutCCP = async () => {
  await fetch(ccpLogoutUrl, { credentials: 'include', mode: 'no-cors' });
  cleanUpCCP();
}

function makeACall() {
  const endpoint = global.connect.Endpoint.byPhoneNumber(callingPhoneNumber);
  window.awsCCP.agent.connect(endpoint, {
    success: (argument) => { // connectionId available here as an argument
      //console.log('Make call success!', argument);
    },
    failure: (err) => {
      //console.log('Make call failed!', err);
      let obj = JSON.parse(err)
      makeACallError(obj)
    }
  });
}

export const addCCP = function addCCP() {
  let containerDiv = document.getElementById('callBody');
  if (containerDiv && containerDiv.getElementsByTagName('iframe').length == 0) {
    window.connect.core.initCCP(containerDiv, {
      ccpUrl: ccpUrl,
      loginPopup: false,
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true
      },
      storageAccess: {
        canRequest: false,
      },
    });
  } else {
    // console.log('already added CCP');
  }
}

export const removeCCP = function removeCCP() {
  const myNode = document.getElementById("callBody");
  myNode.innerHTML = '';
}

export const isCCPAdded = function isCCPAdded() {
  let containerDiv = document.getElementById('callBody');
  return !(containerDiv && containerDiv.getElementsByTagName('iframe').length == 0)
}

Date.minutesBetween = function (date1, date2) {
  if (date1 == undefined || date2 == undefined) return;
  date1 = typeof date1 == 'number' ? moment(date1) : date1;
  date2 = typeof date2 == 'number' ? moment(date2) : date2;
  //Get 1 day in milliseconds
  let one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  let date1_ms = date1.toDate().getTime();
  let date2_ms = date2.toDate().getTime();

  // Calculate the difference in milliseconds
  let difference_ms = date2_ms - date1_ms;
  if (difference_ms == 0) {
    return 0;
  }
  //take out milliseconds
  difference_ms = difference_ms / 1000;
  let seconds = Math.floor(difference_ms % 60);
  difference_ms = difference_ms / 60;
  let minutes = Math.floor(difference_ms % 60);
  difference_ms = difference_ms / 60;
  let hours = Math.floor(difference_ms % 24);
  let days = Math.floor(difference_ms / 24);

  //return days + ' days, ' + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
  return minutes + ' min, ' + seconds + ' sec';
}

export const getInBoundAttributes = (
  contact, 
  attributes
) => {
  const contactId = contact.getContactId();
  const phoneNumber = contact.getInitialConnection().getEndpoint().phoneNumber;
  const isNewPortal = _.get(attributes, 'newPortal.value') === 'true';
  if (isNewPortal) {
    // show only number when it's found in UC2.0
    return {
      contactId, 
      name: { value: phoneNumber },
      newPortal: { value: true },
    };
  } else {
    const inboundPatientId = _.get(attributes, 'patientId.value', undefined)
    const patientName = _.get(attributes, 'name.value', undefined)
    const organizationId = _.get(attributes, 'organizationId.value', undefined)
    const enrolledProgramId = _.get(attributes, 'enrolledProgramId.value', undefined)
    const allProfiles = _.get(attributes, 'found.value', undefined);
    return {
      contactId,
      ...attributes,
      'name': { value: patientName },
      'patientId': { value: inboundPatientId && btoa('accounts:' + inboundPatientId) },
      'organizationId': { value: organizationId && btoa('organizations:' + organizationId) },
      'enrolledProgramId': { value: enrolledProgramId && btoa('enrolled_programs:' + enrolledProgramId) },
      'allProfiles': { value: allProfiles && JSON.parse(allProfiles) },
    };
  }
}

export const setLocalStorageLastCall = (localStorageContact) => {
  try {
    let newLocalStorageContact = _.clone(localStorageContact);
    const prevContact = JSON.parse(localStorage.getItem('call-center-last-contact'));
    const { startTime, patientId, endTime } = prevContact || {};
    const patientIdValue = _.get(patientId, 'value');
    const newPatientIdValue = _.get(newLocalStorageContact, 'patientId.value');
    if (patientIdValue && newPatientIdValue && patientIdValue === newPatientIdValue && !endTime) {
      // use prev startTime
      newLocalStorageContact = { ...newLocalStorageContact, startTime };
    }
    localStorage.setItem('call-center-last-contact', JSON.stringify(newLocalStorageContact))
  } catch (e) {
    localStorage.setItem('call-center-last-contact', JSON.stringify(localStorageContact))
    console.error('Something wrong when setLocalStorageLastCall', e);
  }
}
