import types from '../constants/ImportPatientInfo'
import {moment} from 'ihcomponent'
import {FormHelper} from 'libModule/utils'

const initData = {
  firstName : {
    value : ''
  },
  lastName : {
    value : ''
  },
  nickname : {
    value : ''
  },
  nric : {
    value : '',
    errors : true
  },
  gender : {
    value : ''
  },
  DOB : {
    //value : ''   browser console will throw error on field change date is object not string
  }
}

const initState = {
  initData,
  fieldsValue: FormHelper.initValue(initData)
}

export default (state = initState, action)=>{
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue
      }
    case types.RESET_FORM:
      return initState
    default:
      return state
  }
}
