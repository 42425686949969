export const createCGMPointChartSeries = (data) => ([
  {
    data,
    name: 'Daily average blood glucose',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    color: '#7AD000',
    fillColor: '#7AD000',
    symbol: 'circle',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      fillColor: '#7AD000',
      radius: 4,
      lineWidth: 1,
      lineColor: null,
    },
  },
]);
