
import gql from 'graphql-tag';

export default gql`
mutation createVisit($memberId:EID!,$assignees:AssigneeInput,$organizationId:EID,$type:VisitTypeEnum,$category:VisitCategoryEnum,$appointmentAt:Date,$appointmentTo:Date,$visitRoles:[String],$providers:[EID],$rescheduledFromId:EID,$reason:String,$programIds:[EID],$shouldSeeDoctor:Boolean, $conditionsList:InputConditionsListType, $A1C:InputA1CObject, $needInHouseA1C: Boolean, $associatedVisitId: EID,$remoteStatus: Boolean){
    createVisit(memberId:$memberId,assignees:$assignees,organizationId:$organizationId,type:$type,appointmentAt:$appointmentAt,appointmentTo:$appointmentTo,providerIds:$providers,reason:$reason,programIds:$programIds,shouldSeeDoctor:$shouldSeeDoctor
    visitRoles:$visitRoles, conditionsList:$conditionsList, A1C:$A1C, needInHouseA1C: $needInHouseA1C, associatedVisitId:$associatedVisitId,category:$category,remoteStatus:$remoteStatus,rescheduledFromId:$rescheduledFromId){
      id
      reason
      type
      shouldSeeDoctor
      appointmentAt
      appointmentTo 
      visitRoles
      checkinAt
      checkoutAt
      createdAt
      assignees{
        assignedToRD{
            providerId,name
        }
        assignedToCA{
            providerId,name
        }
        assignedToHC{
          providerId,name
        }
      }
      programs{
        id
        name
      }
      associatedVisit{
        id
      }
      rescheduledFromVisit{
        id
        
      }
      category
      providers{
        id
        profile{
          fullName
        }
        }
    }
  }`
