import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';

import { CLINIC_GOAL_ENUM } from '../../helper/goalHelper';
import GoalStatus from './GoalStatus';

const BillableGoalComponent = props => {
  const { 
    form, 
    idx, 
    goalCondition, 
    goalValue, 
    rowClassName, 
    isReferredGoal, 
    disabled, 
    goalOptions,
    hideGoalStatus,
    isUnmappedGoal
  } = props;
  const { getFieldDecorator } = form;

  return (
    <Row id='billable-goal-component' className={rowClassName}>
      <Col span={24} className={isReferredGoal ? 'referred-goal' : ''}>
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator(`billable.${idx}.condition`, { initialValue: goalCondition })(
            <Input disabled={disabled} readOnly />
          )}
        </Form.Item>
        <div className='condition-label'>
          {goalCondition}
        </div>
      </Col>
      <Col span={hideGoalStatus ? 24 : 14}>
        <Form.Item>
          {getFieldDecorator(`billable.${idx}.goalEnum`,{
            initialValue: goalValue,
            rules: [{required: true, message: 'Please select a goal'}]
          })(
            isUnmappedGoal ?
            <Input 
              style={{width: '100%'}} 
              disabled={disabled} 
              placeholder ='Enter Goal'
            />
            :
            <Select
              className='goal-value-select'
              placeholder ='Select Goal'
              disabled={disabled}
              defaultActiveFirstOption={false}
              dropdownClassName={`billable-goal-dropdown-${idx}`}
            >
            {
              _.map(goalOptions, (o, i)=>{
                return <Select.Option key={i} value={o}>{CLINIC_GOAL_ENUM[o] || o}</Select.Option>
              })
            }
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={hideGoalStatus ? 0 : 6} offset={1}>
        <GoalStatus
          {...props}
          fieldKeyPrefix={`billable.${idx}`}
          selectPlaceholder={undefined}
        />
      </Col>
    </Row>
  )
};

export default BillableGoalComponent;