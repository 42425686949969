import PropTypes from 'prop-types';
import React from 'react';
import { IHTable, _ } from 'ihcomponent'
import { formatSchedule, formatReadingFromRow, formatDate } from 'modulesAll/utils/helpers/task';
import { noDataElem, i18nTableColumns } from 'libModule/helpers/component-helpers'
import GoThumbImageComponent from 'modulesAll/gothumb/components/GoThumbImageComponent'

const VitalListTable = (props) => {
  const { loading, sorter, onTableChange, pagination, type, openPatientVitalModal } = props
  const columns = [
  {
    title: 'Time',
    key: 'date',
    dataIndex: 'date',
    width: '15%',
    sorter: (a, b) => a.date > b.date? 1 : -1,
    sortOrder: sorter.columnKey === 'date' && sorter.order,
    render: (item) => formatDate(item)
  },
  {
    title: <p>Reading <em style={{fontSize: '11px'}}> (M = Manual)</em></p>,
    key: 'measure',
    dataIndex: 'measure',
    width: '20%',
    render: (item, row) => (row.taskStatus === 'COMPLETED')? formatReadingFromRow(row) :
      (row.taskStatus === 'MISSED')?<span className="miss-black">Missed</span> : null
  },
  {
    title: 'Schedule',
    key: 'schedule',
    dataIndex: 'schedule',
    width: '15%',
    render: (item, row) => formatSchedule(row),
  },
  {
    title: 'Patient Notes',
    key: 'measure-notes',
    dataIndex: 'measure',
    render: (measure, row) => {
      // const renderPic = (!!measure && !!measure.imageUrl)? <GoThumbImageComponent filename={_.split(measure.imageUrl, '/files/')[1]} />
      const renderPic = (!!measure && !!measure.imageUrl)? <img src={measure.imageUrl} />
       : null
      const noteDiv = <div>{measure? (measure.user_notes? measure.user_notes : '---') : '---'}</div>
      return (<div className="patient-note-wrapper">
                {renderPic}
                {noteDiv}
        </div>)
    }
  }
  ]
  const tableProps = {
    onRow: (row) => {
      return {
        onClick: () => openPatientVitalModal(row, type)
      }
    },
    onChange: onTableChange,
    dataSource: props.data,
    pagination,
    columns,
    loading
  }

  return (
    <div className="patient-vital-list-table">
      <IHTable {...i18nTableColumns(tableProps)} noDataElem={noDataElem} style={{height: '24em'}}/>
    </div>
  )
}

VitalListTable.propTypes = {
  patientTaskList: PropTypes.array,
  openPatientVitalModal: PropTypes.func,
  onTableChange: PropTypes.func
}



export default VitalListTable
