import type from '../constants';

const initialState = {
  step: 'programme',
};
export default (state = initialState, action) => {
  if (action.type === type.SET_TABS_STEP) {
    return {
      ...state,
      step : action.step
    }
  }

  return state;
};
