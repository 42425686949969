import gql from 'graphql-tag'
import update from 'immutability-helper';
import { UserMini } from './schema/User';
import { Phone, Email } from './schema/common';

export default gql`
  mutation editTeam($id:EID!, $name:String,$consentProviderName:String,$description:String,$forwardingPhone:InputPhone, $phone: InputPhone,$contactName:String, $email: EmailAddress, $memberId:[EID], $primary:[EID],$visibleMembers:[EID]){
    editTeam(id:$id, name:$name, consentProviderName:$consentProviderName,description:$description, forwardingPhone:$forwardingPhone,phone: $phone, email: $email, providerId:$memberId, primary:$primary, contactName:$contactName,visibleMembers:$visibleMembers){
      id
      createdAt
      updatedAt
      modifiedAt
      name
      description
      phone {
        ${Phone}
      }
      contactName
      consentProviderName
      email {
        ${Email}
      }
      healthCondition
      members{
        role {
          category
          name
        }
        primary
        visible
        user{
          ${UserMini}
        }
      }
    }
  }
`

export const updateQueries = {
  team: (prev, { mutationResult }) => {
    const result = mutationResult.data.editTeam;

    return update(prev, {
      team: {
         $set: result,
      },
    });
  }
}
