export const createCGMLineChartSeries = (data) => ([
  {
    data,
    name: 'Daily average blood glucose',
    type: 'line',
    color: '#7AD000',
    marker: {
      enabled: false,
    },
  },
]);
