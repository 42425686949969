import React from "react";
import {Form, Icon, Modal, Tooltip} from "antd";
import ComplexityInfoComponent from "../../patient/main/components/ComplexityInfoComponent";
import AssignToCTForm from './AssignToCTForm';
const AssignCTFormModal = (props) => {
	const { className='', saveCallback, setShowForm, subtitle, required = false, form ,isEnrolled, memberId, patient, complexity = '', setPatient } = props;
	return <div>
		<Modal visible={true} onCancel={()=>setShowForm(false)} footer={null} className={'assignCTForm '+className}>
			<AssignToCTForm memberId={memberId} patient={patient}
			                     setShowForm={setShowForm}
			                     complexity={complexity} isEnrolled={isEnrolled} setPatient={setPatient} saveCallback={saveCallback}/>
		</Modal>
	</div>
}

export default AssignCTFormModal;