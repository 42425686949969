import types from '../constants/Login'
import {commonReducer} from 'libModule/helpers/reducer-helpers.js'

const initState = {
  step: 1
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.RESET:
      return initState
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
