import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/ReviewPatientCompletedComponent.scss';

const ReviewPatientCompletedComponent = ({
  autoCloseIn = 0, // second
  onClose
}) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, autoCloseIn * 1000);
  }, []);

  return (
    <div className="review-patient-completed-component">
      <div className="review-patient-completed-component__success-icon">
        <img src="/image/icon_check.png" />
      </div>
      <div className="review-patient-completed-component__success-message">
        Thanks for your effort! You&apos;ve completed the review for all RPM patients at this point
      </div>
    </div>
  );
};

ReviewPatientCompletedComponent.propTypes = {
  autoCloseIn: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};
export default ReviewPatientCompletedComponent;