import { commonReducer } from 'libModule/helpers/reducer-helpers'
import types from '../constants/BroadcastFormConstants'

const initState = {
  isSubmitting: false
}

const ownReducer = (state, action) => {
  switch(action.type) {
    case types.FORM_SAVED:
      return {
        ...state,
      }
    case types.RESET_FORM:
      return initState
    case types.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }
    case types.CHANGE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
