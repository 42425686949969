import gql from 'graphql-tag';
const MonthlyReport = `
    id
    memberId
    monthOfYear
    startDate
    endDate
    createdAt
    createdBy{
      profile{
        firstName
        lastName
      }
    }
    BP{
      data {
        systolic_blood_pressure
        diastolic_blood_pressure
        date
      }
      severity
      statistics {
        all{
          count
          average
        }
        morning{
          count
        }
        afternoon{
          count
        }
        evening{
          count
        }
        overnight{
          count
        }
      }
      measurementDays
    }
    BG{
      data{
        blood_glucose
        beforeMeal
        mealType
        date
      }
      severity
      statistics{
        all{
          count
          average
        }
        fasting{
          count
        }
        beforeMeal{
          count
        }
        afterMeal{
          count
        }
        bedtime{
          count
        }
        overnight{
          count
        }
      }
      measurementDays
    }
    PO{
      data{
        blood_oxygen
        date
      }
      statistics{
        all{
          count
          average
        }
        morning{
          count
        }
        afternoon{
          count
        }
        evening{
          count
        }
        overnight{
          count
        }
      }
      measurementDays
    }
    HS{
      data{
        body_weight
        date
      }
      statistics{
        all{
          count
          average
        }
        morning{
          count
        }
        afternoon{
          count
        }
        evening{
          count
        }
        overnight{
          count
        }
      }
      measurementDays
    }
    TM{
      data{
        body_temperature
        date
      }
      statistics{
        all{
          count
          average
        }
        morning{
        count
        }
        afternoon{
          count
        }
        evening{
          count
        }
        overnight{
          count
        }
      }
      measurementDays
    }
    review {
      isHelpful
    }
    reviewedAt
`;


export const getMonthlySummaryReportByIdQuery = gql`query getMonthlySummaryReportById(
    $id: EID!
  ) {
    getMonthlySummaryReportById(id: $id) {
      ${MonthlyReport}
    }
  }
`;
export const previewMonthlySummaryReport = gql`query previewMonthlySummaryReport($memberId:EID!){
            previewMonthlySummaryReport(memberId:$memberId) {
                  ${MonthlyReport}
      }
}`
