import gql from 'graphql-tag';


export default gql`
query getVirtualVisitStatus($visitIds:[EID]!){
  getVirtualVisitStatus(visitIds:$visitIds){
    visitId
    status
    waitingTime
    step{
        id
        role
        name
    }
    joinable
    needAction
  }
  
}
`;
