import {
    React
} from 'ihcomponent'
import {goPath} from 'libModule/utils'

export default class extends React.Component{
    static displayName = 'patientList/registerNew/components/TopBar'

    render(){

        return (
            <div className="vsm-patient-topbar">
                {this.renderLeft()}
            </div>
        )
    }

    renderLeft(){
        const active = this.props.active || 1
        const p1 = {
            href : 'javascript:void(0)',
            key : '1',
            className : 'v-1'
        }
        const p2 = {
            href : 'javascript:void(0)',
            key : '2',
            className : 'v-2'
        }
        if(active === 1){
            p2.onClick = ()=>{
                goPath('/patients/register/manual_input')
            }
            p1.className += ' active'
        }
        else if(active === 2){
            p1.onClick = ()=>{
                goPath('/patients/register/import_patient_info')
            }
            p2.className += ' active'
        }

        return [
            <a {...p1}>IMPORT PATIENT INFO</a>,
            <a {...p2}>MANUAL INPUT</a>
        ]
    }

}
