import React from 'react';
import { Input,Row,Col,Form,Button } from 'antd';
import moment from 'moment';
import { validator } from "../helper/validator";
import TimerContainer from '../../timer/containers/TimerContainer';
import {connect} from "react-redux";
import noteActions from '../actions';
import callActions from '../../call/actions'
import {Modal} from 'antd';
import I18N from '../../I18N';

const { TextArea } = Input;
const style = {
    fullNameStyle:{
        fontSize:16,
        fontWeight: 'bold'
    },
    timeStampStyle:{
        color: '#aaaaaa'
    }

}
let PostItCreateComponent = class extends React.Component{
    static displayName ='PostItCreateComponent';

    constructor(){
        super()
        this.confirm = this.confirm.bind(this);
        this.state = {
            checkPopup: false
        }
        

    }

    renderHeader=()=>{
      
        const createdAtString = moment().format('MM/DD/YYYY, HH:mm')
        const header = 'Sticky Note';
        return<Row style={{ margin:10 }}>
                <Col span={12}><span style={style.fullNameStyle}>{header}</span></Col>
                <Col span={24}><span style={style.timeStampStyle}>{createdAtString}</span></Col>
             </Row>
    }

    handleDoneButton = async (note)=>{
        const { createPostIt,form,setNote, status,setCallStickie} = this.props;
        const res = await createPostIt({note});
        if(res) {
            form.resetFields();
            setNote('');
            setCallStickie && setCallStickie(false);
        }
        if (this.props.turnOnline) this.props.turnOnline();
    }
    confirm() {
        let onOk = () => {
            this.setState({
                checkPopup: false,
            })
            this.executeCancel();
            // clearInterval(this.idleInterval);
        }
        let onCancel = () =>{
            this.setState({
                checkPopup: false,
            })
        }
        Modal.confirm({
            title: 'Did you submit a call log',
            content: 'Please remember to submit a call log for this patient. Thank you',
            zIndex:3500,
            onOk: onOk,
            onCancel:onCancel
        });
    }

    handleCancelButton = ()=>{
        
        if (!this.props.callStickie){
            this.executeCancel();
            return;
        }
        if (!this.state.checkPopup){
            this.confirm();
            this.setState({
                checkPopup: true
            })
        }

    }

    executeCancel(){
        const { setShowCreateComponent,setNote,form,status,setCallStickie } = this.props;
        setShowCreateComponent(false);
        setNote('');
        setCallStickie && setCallStickie(false);
        form.resetFields();
    }

    renderButton=()=>{
        const { note } = this.props;
        const noteSize = _.get(note,'length',0);
        const disabled = noteSize==0 || noteSize > 250;
        return<Row style={{alignSelf:'flex-end'}}>
                <Button onClick={()=>this.handleCancelButton( ) } style={{marginRight:10}}>Cancel</Button>
                <Button type='primary' onClick={()=>this.handleDoneButton(note)} disabled={disabled}>Done</Button>
              </Row>

    }


    render(){
        const { setNote,form,note,patientId } = this.props;
        const { getFieldDecorator } = form;
        const noteSize = _.get(note,'length',0);
        const remainChar = 250-noteSize < 0 ? 0 : 250-noteSize;

        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <div className={'createPostItModal'}>
                <div >{ this.renderHeader() }</div>
                    <TimerContainer displayName={'PostItContainer'} category='POSTIT' action='UPDATE'
                                    viewingDetails={viewingDetails} patientId={patientId}
                                    keepEventListener={keepEventListener}
                    />
                    <Form>
                        <Form.Item style={{ margin:0 }}>
                            {getFieldDecorator('note', {
                                rules: [{ required: true, message: I18N.get('stickyNote.required.message') },
                                        { validator: validator }],
                                initialValue:note,
                                value:note
                            })(
                                <TextArea style={{ height: 160 }} onChange={(e)=>setNote(e.target.value.trim())} onPressEnter={()=>{ setNote(note+'\r\n') }}/>
                            )}
                        </Form.Item>
                    </Form>
                    <div style={{display: 'flex',
                        flexDirection: 'column',
                        width: '100%'}}>
                        <span style={{ alignSelf:'flex-end',color:'#aaaaaa',marginTop:'5px 0px',}}>{ remainChar } characters</span>
                        { this.renderButton() }
                    </div>
               </div>
    }
}

PostItCreateComponent = Form.create({})(PostItCreateComponent);

const mapToProps = (state,props)=>{
    
    return {
        contactStatus: state.AWSConnect.contact,
        showCreateComponent:state.Stickie.showCreateComponent,
        note:state.Stickie.note,
        callStickie:state.Stickie.callStickie

    }
};

const mapToDispatch = (dispatch)=>{
    return {
        setShowCreateComponent: flag => dispatch(noteActions.noteStatus(flag)),
        setNote: flag => dispatch(noteActions.setNote(flag)),
        setContact: flag => dispatch(callActions.contact(flag)),
    }
};

export default connect(mapToProps,mapToDispatch)(PostItCreateComponent);
