import React from 'react';
import { Skeleton } from 'antd';

export const ChatChannelPatientNameComponent = ({
  firstName = '',
  lastName = '',
}) => {
  if (!firstName && !lastName) {
    return <Skeleton paragraph={false} active />;
  }

  return `${firstName} ${lastName}`.trim();
};
