import PatientProgramTaskList from './PatientProgramTaskListReducer'
import ProgramInfoTab from './ProgramInfoTab'
import EnrolledProgramInfo from './EnrolledProgramInfoReducer'
import ReplaceReturnDevices from './ReplaceReturnDevicesReducer'
import OneTimeTask from './OneTimeTaskReducer'
import Careplan from 'patientModule/enrolledProgramInfo/Careplan/reducers'
import { combineReducers } from 'redux'

export default combineReducers({
  OneTimeTask,
  EnrolledProgramInfo,
  ReplaceReturnDevices,
  PatientProgramTaskList,
  ProgramInfoTab,
  Careplan
})
