import React from 'react';
import { CGMContextProvider } from '../CGM/context/CGMContext';
import { CellularDevicesContextProvider } from '../CellularDevices/context/CellularDevicesContext';

export const OMRONContextProvider = ({
  patientId,
  children
}) => {
  return (
    <CGMContextProvider 
      patientId={patientId}
    >
      <CellularDevicesContextProvider
        patientId={patientId}
      >
        {children}
      </CellularDevicesContextProvider>
    </CGMContextProvider>
  )
};
