import React from 'react';
import query from '../query/index';
let { remoteEnrollmentList } = query;
import RemoteEnrollmentListComponent from "../component/RemoteEnrollmentListComponent";
import {graphql} from "react-apollo";
import { compose, withState, withProps, lifecycle, withHandlers } from 'recompose';
import CreateVisitInDashboardContainer from "../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer";
import {Modal} from "antd";
import SingleRemoteEnrollmentContainer from "./SingleRemoteEnrollmentContainer";
import helper from '../helper/index';
import defaultSortAndFilterMap from "../constant/defaultSortAndFilterMap";
const RemoteEnrollmentListContainer = class extends React.Component{
        constructor(props) {
            super(props);
            this.state ={
                showModal:false,
                memberId:props.memberId || null
            }
        }


    onCancel = ()=>{
            const { refetchRemoteEnrollmentList,setShowCreateVisitModal } = this.props;
            if(refetchRemoteEnrollmentList){
                refetchRemoteEnrollmentList();
            }
            setShowCreateVisitModal(false)
        }

        renderCreateVisitModal = ()=>{
            return <CreateVisitInDashboardContainer onCancel={()=>this.onCancel() } isRemoteEnrollment={true}/>;
        }

        renderModal = ()=>{
            const { memberId,refetchRemoteEnrollmentList,setShowModal,callPhone,setContact,contact,setCallPhoneInfo,callPhoneNumber} = this.props;
            // const memberIdFromState = _.get(this,'state.memberId');
            return <Modal key={ memberId } visible={true} onCancel={()=>setShowModal(false,null)} width={1000} footer={false} destroyOnClose={true}>
                <SingleRemoteEnrollmentContainer memberId={memberId}setContact={setContact} contact= {contact}
                                                 refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}
                                                 callPhone={callPhone}
                                                 setCallPhoneInfo={setCallPhoneInfo}
                                                 callPhoneNumber={callPhoneNumber}
                />
            </Modal>
        }

        render(){
                const { renderModal,props } = this;
                const { showModal,callPhone,setContact,contact,setCallPhoneInfo,callPhoneNumber } = props;
                const { tabSelected,remoteEnrollmentList,loading,refetchRemoteEnrollmentList,showCreateVisitModal,pageInfo,refetchRemoteEnrollmentSummary,memberId } = this.props;
                return <div>
                        <RemoteEnrollmentListComponent tabSelected={tabSelected}
                                                       loading={loading}
                                                       memberId={memberId}
                                                       callPhone={callPhone }
                                                       setContact={setContact}
                                                       contact={contact}
                                                       setCallPhoneInfo={setCallPhoneInfo}
                                                       callPhoneNumber={callPhoneNumber}
                                                       refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}
                                                       refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                                                       remoteEnrollmentList={remoteEnrollmentList}
                                                       pageInfo={pageInfo}
                        />
                        { showModal&&renderModal() }
                        { showCreateVisitModal&&this.renderCreateVisitModal() }
                       </div>
            }
}

const data = graphql(remoteEnrollmentList, {
        options:(props)=>{
          const { tableProps,tabSelected,enrolledByMAMD,referredByMAMD,crossClinic=true,selectedClinic } = props;
          let tableSort = _.get(tableProps,`${tabSelected}.sort`,{});
          const { direction,fieldGraph,field } = tableSort;
          const sort =  (direction&&fieldGraph && helper.parseSorter({fieldGraph,direction,field})) || helper.parseSorter(defaultSortAndFilterMap(tabSelected).sort);
          let filters = _.get(tableProps,`${tabSelected}.filter`) || {};
          const  page = _.get(tableProps, `${tabSelected}.page.current`, 1);
          const  count = _.get(tableProps, `${tabSelected}.page.pageSize`, 10);
          filters = { ...filters, status: tabSelected };
          filters.doctorReferral = helper.parseSingleFilter('doctorReferral', filters);
          filters.pinnedToEMR = helper.parseSingleFilter('pinnedToEMR', filters);
          // should not pass empty array, ie. providerId, callerId
          filters = helper.removeEmptyFilters(filters);
          filters.crossClinic = crossClinic;
          filters.enrolledByMAMD = tabSelected === 'CONFIRMED' && enrolledByMAMD;
          filters.referredByMAMD = tabSelected === 'REFERRED' && referredByMAMD;
          return{
              variables:{
                      sort:sort,
                      filters,
                      page,
                      count
              },
              fetchPolicy:'network-only'
          }
        },
        props: ({ data }) => {
            const { loading,remoteEnrollmentList,refetch } = data;
            if(loading){
                return {
                    loading
                }
            }
            return {
                pageInfo:_.get(remoteEnrollmentList,'pageInfo'),
                remoteEnrollmentList: _.get(remoteEnrollmentList,'data',[]),
                refetchRemoteEnrollmentList: refetch
            };
        }

})
// const remoteEnrollmentSummary = graphql(query.remoteEnrollmentSummary,{
//     props:({data})=>{
//         const { loading,remoteEnrollmentSummary,refetch } = data;
//         if(loading) {
//             return {
//                 loading
//             }
//         }
//         return {
//             remoteEnrollmentSummary,
//             refetchRemoteEnrollmentSummary: refetch
//         }
//
//     }
// });
// export default (RemoteEnrollmentListContainer);
export default compose(data)(RemoteEnrollmentListContainer);
