import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { compose } from 'react-apollo';

import { getTODORPMPatientListCount } from '../apis/getPatientList';
import ReviewPatientTableContainer from './ReviewPatientTableContainer';
import { commonTODORPMPatientListFilters } from '../constants';

const ReviewPatientTabContainer = (props) => {
  const {
    key,
    isActive,
    setRPMPatientListCount,
    boostedPatientListPageInfo,
    refetchBoostedPatientListPageInfo,
    ...restProps
  } = props;

  useEffect(() => {
    if (boostedPatientListPageInfo) {
      setRPMPatientListCount(boostedPatientListPageInfo.total);
    }
  }, [boostedPatientListPageInfo]);

  const handleOnRefetch = () => {
    refetchBoostedPatientListPageInfo();
  };

  return (
    <Tabs.TabPane key={key} {...restProps}>
      {
        isActive
          ? (
            <ReviewPatientTableContainer
              onRefetch={handleOnRefetch}
            />
          ) : null
      }
    </Tabs.TabPane>
  );
};

ReviewPatientTabContainer.propTypes = {
  tab: PropTypes.any.isRequired,
  key: PropTypes.string,
  isActive: PropTypes.bool,
};

const EnhancedComponent = compose(
  getTODORPMPatientListCount,
)(ReviewPatientTabContainer);

export default (props) => {
  return (
    <EnhancedComponent
      filters={{
        ...commonTODORPMPatientListFilters,
      }}
      {...props}
    />
  );
};
