import {
    React, IHButton, IHUpload, IHIcon, _
} from 'ihcomponent'
import config from 'libModule/config'
import I18N from 'modulesAll/I18N'
import GoThumbPDFComponent from 'modulesAll/gothumb/components/GoThumbPDFComponent'
import GoThumbButtonComponent from 'modulesAll/gothumb/components/GoThumbButtonComponent'

export default class extends React.Component{
  static displayName = 'upload/components/UploadConsentForm'

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props, nextProps)) {
      return true
    }
    return false
  }

  render() {
    return (
      <div className="vsm-upload-box" style={this.props.style||{}}>
          <dd className="v-header">
              {this.getPrintoutButton()}
          </dd>
          <dd className="v-body" style={{'justifyContent': 'center'}}>
              {this.getBody()}
          </dd>
      </div>
    )
  }

  getPrintoutButton() {
    const { forms, name } = this.props.currentProgram
    if (_.size(forms) > 0) {

      const consentForm = _.find(forms, (form) => {
        return form.type === 'CONSENT_FORM'
      } );

      if (consentForm) {
        return <GoThumbButtonComponent filename={_.split(consentForm.uri, '/files/')[1]} buttonLabel='Print Consent Form'
                programName={name} action={I18N.get('auditLog.provider.patient.printConsentForm')} />
      }
    }
  }

  getBody() {
    const { forms } = this.props.currentProgram
    if (_.size(forms) > 0) {

      const consentForm = _.find(forms, (form) => {
        return form.type === 'CONSENT_FORM'
      } );

      if (consentForm) {
        return <GoThumbPDFComponent filename={_.split(consentForm.uri, '/files/')[1]} />
      }
    }

    return (
      <h3> There are no consent form uploaded. Please upload one using the iPad. </h3>
    )

      //
      // const self = this
      // const p = {
      //     label : 'Upload Signed Consent Form',
      //     type : 'primary',
      //     size : 'small'
      // }
      // const uploadProps = {
      //     name : 'uploadfile',
      //     action : config.uploadFileUrl,
      //     showUploadList : false,
      //     customRequest : (obj)=>{
      //       const xmlhttp = new XMLHttpRequest()
      //       const formData = new FormData()
      //       formData.append('uploadfile', obj.file)
      //
      //       xmlhttp.open('POST', config.uploadFileUrl)
      //       xmlhttp.send(formData)
      //
      //       xmlhttp.onprogress = ()=> {
      //         this.setState({
      //           isUploading : true
      //         })
      //       }
      //       xmlhttp.onload = ()=> {
      //         this.setState({
      //           uploadSuccessURL : xmlhttp.responseText
      //         })
      //         this.getSignImageURL(_.split(xmlhttp.responseText, '/files/')[1])
      //       }
      //     },
      //     data(file){
      //         return {
      //
      //         }
      //     },
      //     headers : {
      //
      //     },
      //     onChange(info){
      //         if(info.file.status === 'uploading'){
      //             self.props.dispatch('uploading...')
      //         }
      //         else if(info.file.status === 'done'){
      //             self.props.dispatch('url', info.file.response.Location)
      //         }
      //         else if (info.file.status === 'error') {
      //             self.props.dispatch('file upload error')
      //         }
      //     }
      // }
      //
      // return (
      //     <IHUpload {...uploadProps}>
      //         <p className="v-info">{`Please Upload Signed Consent Form to Continue`}</p>
      //         <IHButton {...p} />
      //     </IHUpload>
      // )
  }

  getDraggerBody(){
    const props = {
      name: 'file',
      showUploadList: false,
      action: '/upload.do',
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          //message.success(`${info.file.name} file uploaded successfully.`);
        } else if (info.file.status === 'error') {
          //message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
        <div style={{width:'100%',height:'100%'}}>
          <IHUpload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <IHIcon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
          </IHUpload.Dragger>
        </div>
    )
  }

  getReUploadButton(){
    if(!this.state.file){
        return null
    }

    const self = this
    const p = {
        type : 'primary',
        label : 'Re-upload Signed Consent Form'
    }

    const uploadProps = {
      name : 'uploadfile',
      action : config.uploadFileUrl,
      showUploadList : false,
      headers : {

      },
      // customRequest(param){
      //     const file = param.file
      //     console.log(file)
      // },
      beforeUpload(file){
          let f = new FileReader()
          f.onload = function(){
              let bindary = new Uint8Array(this.result)

              self.setState({
                  file : {
                      type : 'pdf',
                      url : bindary
                  }
              })
          }

          f.readAsArrayBuffer(file);

          return false;
      },
      onChange(info){

      }
    }

    return (
        <IHUpload {...uploadProps}>
            <IHButton {...p} />
        </IHUpload>
    )
  }
}
