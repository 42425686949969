import gql from 'graphql-tag';

export default gql`
query getBehavioralGoalTemplates($types: [BehavioralGoalsType], $search: String){
  getBehavioralGoalTemplates(types: $types, search: $search){
    id
    type
    category
    values
  }
}`