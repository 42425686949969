import React from 'react';
import {Button} from 'antd';

class CreateUserPopup extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div>
                <div style={{ lineHeight: '30px' }}>
                  By create this account, an invitation email will be sent to this user's email address: <br/>
                  <span style={{ fontWeight: '600' }}>{this.props.email}</span> <br/>
                  Are you sure to create now?
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <Button 
                    type='primary' 
                    style={{ marginRight: 5 }}
                    onClick={this.props.onCreate}
                  >
                    Yes
                  </Button>
                  <Button onClick={this.props.onCancel}>No, not creating this time</Button>
                </div>
            </div>
        )
    }
}

export default CreateUserPopup;