import types from '../constants/AddNewPatient'
import {moment, _} from 'ihcomponent'
import {FormHelper} from 'libModule/utils'
import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from 'modulesAll/patientList/registerNew/components/formData/AddNewPatientForm'

// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)


//
// const initData = {
//   firstName : {
//     value : ''
//   },
//   lastName : {
//     value : ''
//   },
//   nric : {
//     value : ''
//   },
//   gender : {
//     value : 'Male'
//   },
//   DOB : {
//     value : moment()
//   },
//   height : {
//     value : 180
//   },
//   weight : {
//     value : 75
//   },
//   nationality : {
//     value : 2
//   },
//   homePhone : {
//     value : 12345678
//   },
//   mobilePhone : {
//     value : ''
//   },
//   email : {
//     value : ''
//   },
//   race : {
//     value : 'Foreigner'
//   },
//   language : {
//     value : 'English'
//   },
//   condition : {
//     value : 'Blood Pressure'
//   }
// }

const initCaregiverData = {
  isValid: true
}

const initState = {
  initData,
  fieldsValue: initFieldsValue,
  isFormValid: calcFormStatus(initFieldsValue, isFieldsRequired),
  isFieldsRequired,
  isFormSaved: false,

  import : false,
  refresh : 0,
  caregiverList : []
}
export default (state = initState, action)=>{
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.FORM_SAVED:
      return {
        ...state,
        isFormSaved: true
      }
    case types.UPDATE_FORM:
      return {
        ...state,
        fieldsValue: action.fieldsValue,
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormValid: calcFormStatus(newFieldsValue, isFieldsRequired),
        isFormSaved: false
      }
    case types.RESET_FORM:
      return initState
    case types.ADD_CAREGIVER:
      return {
        ...state,
        caregiverList: state.caregiverList.concat(true)
      }
    case types.REMOVE_CAREGIVER:
      return {
        ...state,
        caregiverList: state.caregiverList.map((d, i) => (i === action.payload)? false: d )
      }
    default:
      return state
  }
}
