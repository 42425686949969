import React from 'react';
import { Steps, Input } from 'antd';
import { contentMaps } from './careTeamConstants';
import { generateSteps, renderStepButtons } from '../helper/careteamHelpers';

const inputItemStyle = { marginBottom: 10 };
const rowItemStyle = { marginTop: 15 };

const careTeamFormConfig = [
  [
    {
      key: 'name',
      label: <div>Care Team Name <span style={{ color: 'red' }}>*</span></div>,
      rules: [{ required: true, whitespace: true, message: 'Care Team Name is required' }],
      span: 10,
      path: 'careTeamProps.name',
      style: inputItemStyle,
      inputContent: () => <Input placeholder='Please input a name' />
    },
    {
      key: 'phone',
      label: 'Care Team Mobile number',
      rules: [{
        validator: (__, value, cb) => (
          // phone number not required (which is value === '')
          // or can be xxxyyyzzzz or (xxx)yyyzzzz or xxx-yyy-zzzz
          value === '' || 
          (value && new RegExp(/^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/, 'g').test(value)) 
            ? cb() 
            : cb(new Error('Please enter a valid 10-digit mobile number'))
        )
      }],
      span: 8,
      offset: 1,
      path: 'careTeamProps.phone.number',
      style: inputItemStyle,
      inputContent: () => <Input addonBefore='+1 ' style={{ maxWidth: 180 }} />
    }
  ],
  [
    {
      key: 'consentProviderName',
      label: <div>Consent Form Provider Name <span style={{ color: 'red' }}>*</span></div>,
      rules: [{ required: true, whitespace: true, message: 'Consent Provider Name is required' }],
      span: 10,
      path: 'careTeamProps.consentProviderName',
      style: inputItemStyle,
      inputContent: () => <Input placeholder='Please input a provider name' />
    },
    {
      key:'contactName',
      label:<div>Contact Name(for patient’s phonebook)<span style={{ color: 'red' }}>*</span></div>,
      rules: [{ required: true, whitespace: true, message: 'contact Name is required' }],
      span:10,
      offset: 1,
      path:'careTeamProps.contactName',
      inputContent:()=><Input placeholder='Please input contact name'/>
    }
  ],
  [
    {
      key: 'modal-steps',
      span: 7,
      style: rowItemStyle,
      inputContent: ({ localThis: { modalAction, stepCurrentIndex }}) => {
        return <Steps
          direction='vertical' size='small' current={stepCurrentIndex}
        >
          { 
            generateSteps({
              modalAction,
              // START props used for edit care team
              // stepCurrentIndex,
              // isEdit: modalAction === EDIT_CARETEAM,
              // onStepClick: newStepCurrentIndex => {
              //   if(
              //     !_.isEqual(stepCurrentIndex, newStepCurrentIndex) &&
              //     modalAction === EDIT_CARETEAM
              //   ){
              //     goNextStep(newStepCurrentIndex);
              //   }
              // }
              // END props used for edit care team 
            }) 
          }
        </Steps>;
      }
    },
    {
      key: 'modal-body-table',
      span: 17,
      style: rowItemStyle,
      colStyle: { height: 460 },
      inputContent: ({ localThis }) => {
        const { modalAction, stepCurrentIndex, ...restProps } = localThis;
        return contentMaps[stepCurrentIndex]({
              key: `${modalAction}-step-${stepCurrentIndex}`,
              modalAction,
              ...restProps
            });
      }
    }
  ],
  [
    {
      key: 'modal-body-buttons',
      style: inputItemStyle,
      inputContent: ({ localThis }) => {
        const { careTeamProps, ...restProps } = localThis;
        return renderStepButtons({ ...careTeamProps, ...restProps });
      }
    }
  ]
];

export default careTeamFormConfig;