import I18N from "../../../I18N";
import moment from "moment/moment";
import React from 'react';
import { nationOptions, langOptions, countryOptions,
    docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import {formatFieldLabels, renderRadioWithExtraContent, renderCheckBoxGroup, renderRadioButton,renderRadioOfDietHabits} from '../helpers';
import { Input, Form,InputNumber } from 'antd'
import { dobValidator, emailValidator2, postCodeValidator, blockValidator, floorValidator, unitValidator,
    firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientHeightInputValidator,
    patientHeightInchesInputValidator, mobileValidator, homePhoneValidator,yearOfDiagnoseValidator } from 'libModule/validator';
const Item = Form.Item;
const radioMap = {
                    exerciseHabits:[
                        { value:true,name:'YES'},
                        { value:false,name:'NO'}
                    ],
                    drinkingHistory:[
                        { value:true,name:'YES'},
                        { value:false,name:'NO'}
                    ],
                    smokingHistory:[
                        { value:true,name:'YES'},
                        { value:false,name:'NO'}
                    ],
                    dietHabits:[
                        { value: 'Pescatarian', label: 'Pescatarian' },
                        { value: 'Vegetarian', label: 'Vegetarian' },
                        { value: 'Vegan', label: 'Vegan' },
                        { value: 'Paleo', label: 'Paleo' },
                        { value: 'Keto', label: 'Keto' },
                        { value: 'Low carb', label: 'Low carb' },
                        { value: 'Regular', label: 'Regular' },
                        { value:'Other',label:'Other'}
                    ],
                    dietProperties:[
                        { value: 'High fat', label: 'High fat' },
                        { value: 'High carbohydrates', label: 'High carbohydrates' },
                        { value: 'High salt', label: 'High salt' },
                        { value: 'High processed foods', label: 'High processed foods' },
                        { value: 'Low vegetable', label: 'Low vegetable' }
                    ]

}
const liefStyleConfig = [
                    [
                        {
                            span:24,
                            placeHolder:<div><h4>Exercise Habits:</h4></div>
                        }
                    ],
                    [
                        {
                            key:'exerciseHabits.value',
                            label: formatFieldLabels('exerciseHabits.value', 'exerciseHabits.value'),
                            path:'profile.exerciseHabits.value',
                            span:6,
                            inputContent:(props)=>renderRadioButton(radioMap['exerciseHabits'],props),
                        },
                        {
                            key:'exerciseHabits.freq',
                            path:'profile.exerciseHabits.freq.value',
                            label: formatFieldLabels('exerciseHabits.freq', 'exerciseHabits.freq'),
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><InputNumber disabled={disabled} min={0}/>,
                        },
                        {
                            key:'exerciseHabits.duration',
                            path:'profile.exerciseHabits.duration',
                            label: formatFieldLabels('exerciseHabits.duration', 'exerciseHabits.duration'),
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><InputNumber disabled={disabled} min={0}/>,
                        }
                    ],
                    [
                        {
                            span:24,
                            placeHolder:<div><h4>Eating Habits:</h4></div>
                        }
                    ],
                    [
                        {
                            key:'dietHabits.value',
                            label: formatFieldLabels('dietHabits.value', 'dietHabits.value'),
                            path:'profile.dietHabits.value',
                            span:24,
                            inputContent:(props,user,local)=> renderRadioOfDietHabits(radioMap['dietHabits'],props,
                                <Item layout={'inline'} key='dietHabits.other' className='dietHabitsOther'>
                                {
                                    local.props.form.getFieldDecorator('dietHabits.other', {
                                        initialValue: _.get(user,'profile.dietHabits.other')
                                    })(<Input />)
                                }
                            </Item>)
                        }
                    ],
                    [
                        {
                            key:'dietHabits.dietProperties',
                            path:'profile.dietHabits.dietProperties',
                            label:formatFieldLabels('dietHabits.dietProperties','dietHabits.dietProperties'),
                            span:24,
                            inputContent:(props)=>renderCheckBoxGroup(radioMap['dietProperties'],props),
                            formatValue:(data)=> Array.isArray(data)?data:[]
                        }
                    ],
                    [
                        {
                            span:24,
                            placeHolder:<div><h4>Drinking History:</h4></div>
                        }
                    ],
                    [
                        {
                            key:'drinkingHistory.value',
                            label: formatFieldLabels('drinkingHistory.value', 'drinkingHistory.value'),
                            path:'profile.drinkingHistory.value',
                            span:24,
                            inputContent:(props)=>renderRadioButton(radioMap['drinkingHistory'],props),
                        }
                    ],
                    [
                        {
                            key:'drinkingHistory.startSinceYear',
                            label: formatFieldLabels('drinkingHistory.startSinceYear', 'drinkingHistory.startSinceYear'),
                            path:'profile.drinkingHistory.startSinceYear',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        },
                        {
                            key:'drinkingHistory.drinkType',
                            label: formatFieldLabels('drinkingHistory.drinkType', 'drinkingHistory.drinkType'),
                            path:'profile.drinkingHistory.drinkType',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        },
                        {
                            key:'drinkingHistory.freq',
                            label: formatFieldLabels('drinkingHistory.freq', 'drinkingHistory.freq'),
                            path:'profile.drinkingHistory.freq.value',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><InputNumber disabled={disabled} min={0}/>,
                        },
                        {
                            key:'drinkingHistory.amount',
                            label: formatFieldLabels('drinkingHistory.amount', 'drinkingHistory.amount'),
                            path:'profile.drinkingHistory.amount',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        },
                        {
                            key:'drinkingHistory.quitYear',
                            label: formatFieldLabels('drinkingHistory.quitYear', 'drinkingHistory.quitYear'),
                            path:'profile.drinkingHistory.quitYear',
                            wrapperCol:{span:22},
                            span:4,
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        }
                    ],
                    [
                        {
                            span:24,
                            placeHolder:<div><h4>Smoking History:</h4></div>
                        }
                    ],
                    [
                        {
                            key:'smokingHistory.value',
                            label: formatFieldLabels('smokingHistory.value', 'smokingHistory.value'),
                            path:'profile.smokingHistory.value',
                            span:24,
                            inputContent:(props)=>renderRadioButton(radioMap['smokingHistory'],props),
                        }
                    ],
                    [
                        {
                            key:'smokingHistory.startSinceYear',
                            label: formatFieldLabels('smokingHistory.startSinceYear', 'smokingHistory.startSinceYear'),
                            path:'profile.smokingHistory.startSinceYear',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        },
                        {
                            key:'smokingHistory.freq',
                            label: formatFieldLabels('smokingHistory.freq', 'smokingHistory.freq'),
                            path:'profile.smokingHistory.freq.value',
                            span:4,
                            wrapperCol:{span:22},
                            inputContent:({disabled})=><InputNumber disabled={disabled} min={0}/>,
                        },
                        {
                            key:'smokingHistory.quitYear',
                            label: formatFieldLabels('smokingHistory.quitYear', 'smokingHistory.quitYear'),
                            path:'profile.smokingHistory.quitYear',
                            wrapperCol:{span:22},
                            span:4,
                            inputContent:({disabled})=><Input disabled={disabled} />,
                        }
                    ]
        ]

export {
    liefStyleConfig
}
