import React from 'react';
import { Form,Row,Col, Modal, message, Icon } from 'antd';
import map from "../constant/workListConstants";
import singleVisit from "../../graphql/singleVisit";
const Item = Form.Item;
import Client from 'libModule/gqlClient';
import updateVisitWorkingList from '../../graphql/mutation/updateVisitWorkingList';

// import { mappingCondition,nameMapping,conditionToType,revertNameMapping } from '../../careplanNew/component/helper/goalHelper';
import I18N from 'modulesAll/I18N';
const leaveAndSaveWorkTask = (workingListKey, visitId, msg, cb)=>{
    // check for the current status of other tasks
    Client.query({
      query: singleVisit,
      variables: { id: visitId },
      fetchPolicy: 'network-only'
    }).then(res => {
      const MAWorkingList = _.get(map,'init.MAWorkingList');
      const RDWorkingList = _.get(map,'init.RDWorkingList');
      let maWorkingList = _.get(res, 'data.getVisit.maWorkingList', {});
      let rdWorkingList = _.get(res, 'data.getVisit.rdWorkingList', {});
      let parsedMaWorkingList = {};
      let parsedRDWorkingList = {};

      for (let [index, key] of Object.entries(MAWorkingList)) {
          parsedMaWorkingList[key]  = key=== workingListKey ? 'PENDING' : _.get(maWorkingList,key, 'TODO');
      }

      for (let [index, key] of Object.entries(RDWorkingList)) {
          parsedRDWorkingList[key]  = key=== workingListKey ? 'PENDING' : _.get(rdWorkingList,key, 'TODO');
      }

      Client.mutate({
          mutation: updateVisitWorkingList,
          variables:{
              id: visitId,
              maWorkingList: _.pick(parsedMaWorkingList,MAWorkingList),
              rdWorkingList: _.pick(parsedRDWorkingList,RDWorkingList)
          },
      }).then(res => {
        if(cb) cb();
        message.success(msg);
      });
    }).catch(e => Modal.error({ content: e.message }));
}

const getCheckBoxGroupOptions = (VITAL_TYPES_LONG)=>{
    const options = VITAL_TYPES_LONG.map( opt => {
        return { label: opt, value: opt }
    })
    return options
}
const createForm = (localThis,config,formClass, handleCheck, openModal, phoneInfo) =>{
    const { getFieldDecorator,loading } = localThis.props.form;
    return(
        <Form layout='vertical' className={formClass}>
            {  _.map(config,(c,i)=>
                <Row key={i} style={{ display:'flex' }} gutter={16}>
                {
                _.map(c,(arrayItem,index)=> {
                    const { render,key,span,colStyle,wrapperCol,label,labelCol,col,itemStyle,className,renderOnly,offset,initialData,path,valuePropName,type,rules,disabled,changeToBoolean,firstPath, hidden } = arrayItem;
                    let data =  firstPath ? _.get(localThis,`props.${firstPath}`,_.get(localThis,`props.${path}`)): _.get(localThis,`props.${path}`) ;
                    //console.log(`data ${key}`, data);
                    //console.log(`initial ${key}`, initialData);
                    if(changeToBoolean){
                        data = changeToBoolean(data);
                    }
                    const action = {
                        trackSet: e => handleCheck(key, e),
                        iPhone: e => handleCheck(key, e),
                        editTrackSet: () => openModal('trackSet'),
                        appVersion: phoneInfo.version,
                        unassignIPhone: () => openModal('iPhone'),
                    }

                    if (!!hidden) return null;
                    return(
                             <Col span={span} key={index} style={colStyle} offset={offset}>
                                 {
                                   typeof renderOnly=='function' ? renderOnly() :
                                       <Item layout={'vertical'} key={key} label={label || ''}
                                             style={itemStyle} className={className}
                                       >

                                         {
                                             getFieldDecorator(key,{
                                              rules,
                                              initialValue: data || initialData,
                                              valuePropName: valuePropName || 'value',
                                              disabled: localThis.props.disabled || disabled
                                            })(render(action[key]))
                                         }
                                     </Item>
                                 }
                            </Col>
                    )})
                }
                </Row>
            )}
        </Form>
    )
}

const getConfigBasedOnSelfEvaAndHealthCode = (args)=>{
        const { billableDiseases,nonBillableDiseases } = args;
        const selectedHealthConditions = Array.from(new Set([...(billableDiseases || []),...(nonBillableDiseases || [])]));
        const selfEvaluation= _.get(args,'visit.selfEvaluation',{});
        const evaluations  = _.get(selfEvaluation,'evaluations',[]);
        return _.filter(evaluations,n=>!_.includes(selectedHealthConditions, n.name));
}
const getFullSelfEvalAns = (args)=>{
       const selfEvaluation= _.get(args,'visit.selfEvaluation',{});
       return _.get(selfEvaluation,'evaluations',[]);
}

const renderSelfEvaluationAns = (evaluations)=>{
    const obj = I18N.get('selfEvaluation');
    const keys = Object.keys(obj);
    const DISEASES = I18N.get('chronicDisease');
    DISEASES['HYPERTENSION'] = {
        title:'Hypertension'
    }
    DISEASES["DIABETES"] = {
        title:'Diabetes'
    }
    DISEASES['OBESITY'] = {
        title: 'Weight Reduction'
    }
    return _.map(evaluations,e=> {
          return <div className={'selfEvaluationAnsContainer'} key={e.name}>
                      <h5>[{_.get(DISEASES,`${e.name}.title`,e.name)}]</h5>
                      { _.map(keys,k=>{
                          const value = _.get(e,k);
                          return <Row key={k}>{ (!_.isEmpty(value)||_.isNumber(value))&&<Col>{`${obj[k]} ${value}`} </Col> }</Row>
                        })
                      }
                 </div>

      })
}

const getDefaultType = (followUpVisit, curType) => {
    const curFollowUp = _.get(followUpVisit, 'type')
    return curFollowUp ? curFollowUp : (curType === 'MNT' ? 'MNT' : 'FOLLOW_UP')
}

export const renderDraftSavedText = () => {
  return <div className='draftSavedText'>
    <Icon type='check-circle' style={{ marginRight: 5 }} />
    Draft Saved
  </div>;
}

export default {
    createForm,
    leaveAndSaveWorkTask,
    getCheckBoxGroupOptions,
    getConfigBasedOnSelfEvaAndHealthCode,
    getFullSelfEvalAns,
    renderSelfEvaluationAns,
    getDefaultType
}
