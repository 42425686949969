import React from 'react';
import { Upload,Icon, Tooltip } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { UPLOAD_FILETYPES } from '../../../lib/constants';

export default class extends React.Component {
    constructor(){
        super();
        this.state = {
            focused :false
        }
    }


    renderUpload(){
        const { beforeUpload,removeImg,bottom, customStyles = {}, customImageStyles = {} } = this.props;

        const accept = _.map(UPLOAD_FILETYPES, 'accept').join(',');

        const props = {
            onRemove: () => {
                removeImg();
            },
            onChange: ({file}) => {
                beforeUpload(file.originFileObj);
                return false;
            },

            accept,
            fileList:[]
        };

        const tipFileTypesText = _.map(UPLOAD_FILETYPES, (v, idx) => {
          if(!v || !v.display) return null;
          const lastIdx = UPLOAD_FILETYPES.length - 1;
          return v.display + (idx === lastIdx ? '' : (idx !== lastIdx - 1 ? ', ' : ' and '));
        }).join('');
        const tipTitle = `Filetypes ${tipFileTypesText} are supported`;

        return <Tooltip
            title={tipTitle}
            trigger='hover'
            overlayClassName='uploadImgTip'
          >
            <div style={{ position:'absolute',bottom, ...customStyles }} className='uploadImg' >
               <Upload {...props}>
                    <span onClick={()=>Mixpanel.track('clicked','attach_image_button','message')}><img src="image/attach.png" width={ 14 } style={{...customImageStyles}}  /></span>
               </Upload>
              {/*{ file ? <Icon type="upload" onClick={ this.handleUpload }/> : '' }*/}
            </div>
          </Tooltip>;

    }

    render(){
        return this.renderUpload();
    }
}
