/**
 * Created by zizhangai on 6/22/17.
 */
import React, { Component } from 'react';
import { IHButton } from 'ihcomponent';
import redirect from '../../utils/helpers/auditTrailRedirect'

const styles = {
  height: window.innerHeight/2,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
export default class extends Component {
  static displayName = 'superAdmin/containers/index'

  render() {
    return (
    <div style={styles} >
      <IHButton
        bsStyle="primary"
        label="Click Here to Access Audit Trail"
        onClick={redirect}
      />
    </div>
    )
  }
}
