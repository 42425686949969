import { _ } from 'ihcomponent'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'react-apollo'

const GQLHelperClass = class{
  constructor(name, data){

    this.__class = 'GQLHelperClass'
    this.type = 'query'
    this._name = data.name
    this._data = data
    if(_.isFunction(data)){
      // is mutation
      this.type = 'mutation'
      this.loading = false
      this.error = null
    }
    else{
      this.loading = data.loading
      this.refetch = data.refetch
      this.error = data.error
    }
  }

  out(){

  }

  execute(variables){
    if(this.type !== 'mutation'){
      throw new Error('can not excute a query type gql')
    }

    return new Promise(async (resolve, reject)=>{
      try{
        const res = await this._data({variables})
        resolve(res)
      }catch(e){
        reject(e)
      }
    })
  }
}

export const combine = (arg)=>{
  let rs = {
    loading : false,
    error : null
  }
  _.each(arg, (item)=>{
    if(item.__class === 'GQLHelperClass'){
      rs = {
        loading : rs.loading || item.loaidng,
        error : rs.error || item.error
      }
    }
  })

  return rs
}



/*
  requestGraphqlList : Array
  [0] graphql
  [1] config
*/
export const createPageContainer = (requestGraphqlList=[], PageContainer, mapState=_.noop(), mapDispatch)=>{
  const list = _.map(requestGraphqlList, (item)=>{
    let item_gql = null, item_config = {}
    if(_.isArray(item)){
      item_gql = item[0]
      item_config = item[1]
    }
    else{
      item_gql = item.graphql
      item_config = item
    }

    const config = _.merge({
      name : null,
      skip : false,
      options : _.noop(),
    }, item_config)
    if(!config.name){
      throw new Error('config name is required')
    }
    return graphql(item_gql, {
      name : config.name,
      skip : config.skip,
      options : config.options,
      props : (arg)=>{
        const data = arg[config.name||'data']
        const ownProps = arg.ownProps
        const obj = new GQLHelperClass(config.name||'data', data)
        let rs = {}
        if(config.props){
          rs = _.merge(rs, config.props({data, ownProps}, obj))
        }

        ownProps.gql[config.name] = obj
        return rs
      }
    })
  })

  list.unshift(connect((state, ownProps)=>{
    return _.merge({
      gql : {}
    }, mapState(state, ownProps))
  }, mapDispatch))
  return (compose.apply(null, list))(PageContainer)
}
export class GQLRetry {
  constructor(key, opts) {
    // to prevent retry lock, add notifyOnNetworkStatusChange = true
    this.key = key;
    if(window[this.key])
      return window[this.key];

    const { retries = 4, timeInterval = 250, timeSpan = 1000 } = opts || {};
    window[this.key] = this;
    this.attempts = 0;
    this.retries = retries;
    this.handleError = _.debounce(this._retry.bind(this), timeInterval + (this.attempts * timeSpan));
  }
  _retry(data) {
    const { error, refetch, networkStatus } = data;
    // make sure when it retries iff there is no error retrying
    if(networkStatus === 4)
      return;
    if(this.attempts <= this.retries) {
      console.info(new Date(),
                    'Info retry', this.key,
                    ', error for retry', error);
      refetch();
      this.attempts++;
    } else {
      console.error('Max retries', this.key, ', retries', this.retries);
    }
  }
  checkResponse(data) {
    const { loading, error } = data;
    if(loading)
      return data;
    if(error) {
      this.handleError(data);
      return { ...data, loading: true };
    }
    // notify and cleanup
    if(this.attempts > 0) {
      console.info(new Date(), 
                    'Info successfully retried', this.key, 
                    ', attempts: ', this.attempts);
    }
    window[this.key] = undefined;
    return data;
  }
};
