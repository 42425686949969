const programCategoriesMap={
    CCMEnrolled:'CCM',
    RPMEnrolled:'RPM',
    MNTEnrolled:'MNT',
    valueBasedEnrolled:'VALUE_BASED'
};

export {
    programCategoriesMap
}
