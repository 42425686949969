import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';

const VisitButtons = props => {
  const { editStatus, disableSaveButton = false, handleDelete, onCancel } = props;

  return <div className='visit-bottom-buttons'>
    <div className='visit-bottom-buttons-wrapper'>
      {
        editStatus ?
        <Button onClick={handleDelete}>Delete</Button>
        : <span></span>
      }
      <div className='visit-bottom-buttons-main'>
        {
          onCancel && 
          <Button onClick={onCancel}>Cancel</Button>
        }
        <Button type='primary' htmlType='submit' disabled={disableSaveButton}>
          {editStatus ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  </div>
};

VisitButtons.propTypes = {
  editStatus: PropTypes.bool.isRequired,
  disableSaveButton: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default VisitButtons;