export default [
    {
        header:'Data Insights Access',
        actionTitle:'Allow members in this clinic to have access to data insights section.',
        description:'By allowing, the data insights of this clinic will be displayed in the portal.',
        permissionKey:'analyticsAPI'
    },
    {
        header:'Marked in EMR Required?',
        actionTitle:'When toggled ON, the ‘Marked in EMR’ checkbox in the Charting worklist section will be required for this clinic.',
        description:'',
        permissionKey:'pinnedToEMRRequired'
    },
    {
      header: 'CCM Provider',
        actionTitle: 'Does this clinic already participate with another CCM provider ?',
        description: '',
        permissionKey: 'otherCCMProvider'
    },
    // {
    //     header:'Gluco+(BG5S) Trail',
    //     actionTitle: 'When toggle ON,Gluco+(BG5S) will be shown up as an option on the worklist',
    //     description: '',
    //     permissionKey: 'BG5STrail'
    // },
    {
        header:'Automessages',
        actionTitle: 'When toggled ON, the patients in this clinic will receive engagement automessages for both new patient and existing patient engagement.',
        description: '',
        permissionKey: 'enableAutoMessage'
    },
    {
        header: 'In-clinic device hand out',
        actionTitle: 'Will this clinic keep their own inventory of devices on-hand',
        description: '',
        permissionKey: 'keepOwnDevices'
    },
    {
        header: 'Device Eligibility',
        actionTitle: 'Clear(no smart phones solution)',
        description: '',
        permissionKey: 'supportClear',
        style:{ rowStyle: { paddingBottom:'0 !important'} },
    },
    {
        header: '',
        actionTitle: 'Loaner Smartphone',
        description: '',
        permissionKey: 'supportLoanerPhone'
    }
]
