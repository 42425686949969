import types from 'ProgramNewModule/ProgramInfo/constants/ProgramInfoFormConstants'
import programIncompleteCommonTypes from 'ProgramIncompleteModule/Common/constants/CommonConstants'
import programIncompleteCommonActions from 'ProgramIncompleteModule/Common/actions/CommonActions'

export const goIncompletePageCareplan = () => {
  return (dispatch, getState) => {
    dispatch(programIncompleteCommonActions.setTabsStep('careplan'))
    dispatch(programIncompleteCommonActions.changePage(programIncompleteCommonTypes.CAREPLAN_MAIN_PAGE))
  }
}

export const setButtonState = (state) => {
  return {
    type: types.SET_BUTTON_STATE,
    payload: state
  }
}

export default {
  goIncompletePageCareplan,
  setButtonState
}
