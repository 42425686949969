import gql from "graphql-tag";

export const patientList = gql`query patientList($page: Int, $count: Int, $sort: UserListSort, $filters: InputPatientFilters, $search: InputUserSearch) {
  boostedPatientList(filters: $filters, search: $search, page: $page, count: $count, sort: $sort) {
    pageInfo {
      total
      lastPage
    }
    data {
      user{
          id
          organization{
            id
            name
          } 
          consentInfo{
            consent
          }
          profile{
              firstName
              lastName
              fullName
            ... on MemberProfile{
                isSelfEnrolled
                doctor{
                    id
                    profile{
                        firstName
                        lastName
                        fullName
                    }
                }
                callLogFlag
			    claimedTimeLogFlag
                programCategories{
			      name
			      enrolled
			      enrolledDate
                }
            }
          }
           assignees {
          assignedToRD {
            id
            profile {
              firstName
              lastName
              __typename
            }
            __typename
          }
          assignedToCA {
            id
            profile {
              firstName
              lastName
              __typename
            }
            __typename
          }
          assignedToCANotNecessary
          assignedToRDNotNecessary
          __typename
        }
           monthlyMeasurementDays
          monthlyTimeSpent {
          totalTime
          monthOfYear
          updatedAt
          __typename
        }
      }
    }
  }
}`

export const clinicCandidatesQuery = gql`query getSelectedClinic{
    getSelectedClinic{
            boostedOrganizations{
                organizationId
                name
            }           
    }
}`;

export const setClinicsMutation = gql`mutation setSelectedClinic($boostedOrganizations:[EID!]!){
    setSelectedClinic(boostedOrganizations:$boostedOrganizations){
            boostedOrganizations{
                organizationId
                name
            }
            
    }
}`
