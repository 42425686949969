const emptyWeeklySchedule = {
  mon: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  tues: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  wed: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  thurs: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  fri: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  sat: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  },
  sun: {
    before_breakfast: false,
    after_breakfast: false,
    before_lunch: false,
    after_lunch: false,
    before_dinner: false,
    after_dinner: false,
    bedtime: false,
    overnight: false
  }
};

export const emptyWeeklyScheduleData = {
  name: 'default',
  schedule: emptyWeeklySchedule
};
