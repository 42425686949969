import React from 'react';
import SingleAreaComponent from '../component/SingleAreaComponent';
import { compose, graphql } from 'react-apollo/index';
import query from "../../query";
import helper from "../../helper/index";

const SingleAreaContainer = class extends React.Component{

    render() {
            const { organization,loading,orgListLoading,orgList,refetchOrgList,refetchOrg } = this.props;
            if(loading || orgListLoading) {
                return 'loading';
            }
            return <SingleAreaComponent organization={organization} orgList={orgList} refetchOrgList={refetchOrgList} refetchOrg={refetchOrg}/>
        }
}
const singleArea = graphql(query.organization,{
    options: (ownProps) => {
        const orgId = _.get(ownProps, 'routeParams.orgId', helper.getViewerOrgId());
        const variables = {
            id: orgId
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-first'
        }
    },
    props: ({data}) => {
        const { organization, loading,refetch } = data;
        return {
            organization,
            loading,
            refetchOrg:refetch
        }
    }
});

const orgList = graphql(query.orgsWithOnlyId,{
    options: (ownProps) => {
        const orgId = _.get(ownProps, 'routeParams.orgId', helper.getViewerOrgId());
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                parentId: orgId
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-first'
        }
    },
    props: ({data}) => {
        const { organizationList, loading, refetch } = data;
        return {
            orgList: _.get(organizationList,'data',[]),
            orgListLoading: loading,
            refetchOrgList: refetch
        }
    }
})

export default compose(singleArea,orgList)(SingleAreaContainer);