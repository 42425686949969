import type from '../constants'

export default {
  changeCurrentAlertsOption: selectedAlertOption => {
    return {
      type: type.SET_CURRENT_ALERT_OPTION,
      selectedAlertOption,
    }
  },
  selectCurrentProgram: (currentProgram)=>{
    return {
      type : type.SET_CURRENT_PROGRAM,
      currentProgram,
    }
  },
  reset: () => {
    return {
      type: type.RESET,
    }
  }
}
