
import type from '../constants'

const initialState = {
    current : null,
    selectedProgram : null,
    selectCareteam : {
        index : [],
        id : [],
        name : [],
        teamInfo: []
    },
    haveCareTeam: true,
    startDate: null,
    duration : null,
}

export default (state=initialState, action)=>{
    if(action.type === type.CHANGE_SELECT){
        return {
            ...state,
            current : action.id,
            selectedProgram: action.selectedProgram,
            selectCareteam : initialState.selectCareteam,
            duration : null
        }
    }
    else if(action.type === type.CHANGE_DURATION){
        return {
            ...state,
            duration : action.duration
        }
    }
    else if(action.type === type.SET_SELECT_CARETEAM){
        return {
            ...state,
            selectCareteam : action.selectCareteam,
            haveCareTeam : true
        }
    }
    else if(action.type === type.CHANGE_START_DATE){
        return {
          ...state,
          startDate: action.startDate
        }
    }
    else if(action.type === type.SET_HAVE_CARETEAM){
        return {
          ...state,
          haveCareTeam: action.haveCareTeam
        }
    }
    else if(action.type === type.INITIAL_STATE){
      return {
        ...initialState
      }
    }
    else if(action.type === type.RESET_STATE){
        return {
            ...initialState
        }
    }

    return state
}
