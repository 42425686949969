import { combineReducers } from 'redux';
import type from '../constants';
import moment from "moment/moment";

const INITIAL_STATE = {
    initVisitList:[],
    year: Number(moment().format('YYYY'))

};

const reducer = (state = INITIAL_STATE, action = {}) => {
    let newObj = _.cloneDeep(state);
    let id = '';
    switch (action.type) {
        case type.INIT_VISIT_LIST:
           newObj.initVisitList = action.payload.data;
           return newObj;
        case type.ADD_VISIT:
            newObj.initVisitList.unshift(action.payload.data);
            return newObj;
        case type.DELETE_VISIT:
            newObj.initVisitList = _.filter(newObj.initVisitList,(doc)=>{
                  return doc.id!=action.payload.id;
               })
            return newObj;

        case type.UPDATE_VISIT:

            const index = newObj.initVisitList.findIndex((obj)=>{
                return obj.id==action.payload.id
            })

            newObj.initVisitList[index] = action.payload.data;
            return newObj;

        case type.RESET_VISIT:
            newObj.initVisitList = [];
            return newObj;

        case type.SET_YEAR:
            newObj.year+=action.payload.offset;
            return newObj;

        default:
            return state;
    }
}

export default {
    visit: combineReducers({
        main: reducer
    })
};
