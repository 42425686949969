// Reducer
import { commonReducer, commonInitialState, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from '../component/formData/organizationEditForm';
import * as editAction from '../action/organizationEditAction';
const actionTypes=editAction.actionTypes;
/*
**************************************************
  Reducer
**************************************************
*/
// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initialState = Object.assign(commonInitialState(actionTypes), {
  initData,
  fieldsValue: initFieldsValue,
  isFieldsRequired,
  isFormSaved: true,
  isSubmitting: false,
})

const ownReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_FORM:
      return initialState
    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes)
}