import { Upload, Icon, message, Popover, Button, Modal, Row, Col} from 'antd';
import React from 'react'; 
import '../style.scss';
import API from '../../API';
class UserEditPhotoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imageUrl: this.props.photo,
            visible: false,
            deleteVisible : false
        }
    }
    handleChange = async info => {
        this.setState({visible: false});
        if (info.file.status === 'uploading') {
            return;
        }

        const image = info.file.originFileObj;
        const imageName = _.get(image, 'name');
        let res = {};
        try {
            res = await this.props.getUploadUrl(this.props.id, imageName);
            this.setState({
                loading:true
            });
        }
        catch(e){
            throw(e);
        }        
        const data = res.getPresignedUpload.data;
        const url = data.url;
        let fields = data.fields;
        fields = _.isString(fields) ? JSON.parse(fields) : fields;
        const fileExtension = imageName.split('.')[1];
        const mimeType = fileExtension ? (`image/${_.toLower(fileExtension)}`) : 'image/jpg';
        fields['Content-Type'] = mimeType;
        let fileKey={};
        try {
            fileKey = await this.props.sendAJaxReq({ uploadUrl: url, filename: imageName, file: image, mimeType, fields, setProgress:()=>{}});
            this.setState({
                loading : false
            })
        }
        catch(e){
            throw(e);
        }    
        API.editEmployee({ id: this.props.id, commonProfile: { avatar: { fileKey }}}).then(()=>{
            message.success("Photo changed successful!");
            this.props.userRefetch();
        })           
    };
    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    handleDelete = () => {
        this.setState({imageUrl: null, deleteVisible: false});
        this.props.removePhoto();
    }

    uploadButton = () => (
        <div className="uploadCircle">
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                //beforeUpload={beforeUpload}
                onChange={this.handleChange}
                style={{overflow:"hidden"}}
            >
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </Upload>
        </div>
    );

    popoverContent = () => (
        <div className="photoCard">
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                //beforeUpload={beforeUpload}
                onChange={this.handleChange}
                style={{padding:'0px', textAlign:"left"}}
            >Change Profile Picture</Upload>
            <hr/>
        <Button onClick={()=>{this.setState({deleteVisible: true, visible: false})}}>Delete Profile Picture</Button>
    </div>
    )

    imageShow = () => (
        <Popover placement="bottom" trigger="click" visible={this.state.visible} content={this.popoverContent()} onVisibleChange={this.handleVisibleChange}>
            <div className="uploadCircle">
                <img src={this.state.imageUrl} alt="avatar" style={{width: "120px", height: "120px", cursor: "pointer"}} onClick={()=>{this.setState({visible:!this.state.visible})}}/>
            </div> 
        </Popover>
    );

  render() {
    return (
        <div>
            <Modal visible={this.state.deleteVisible} footer={null} className="modalWrapper">
                <div className="modalBody">Are you sure to delete the profile picture?</div>
                <Row>
                    <Col span={11} ><Button className="CancelBtn" onClick={()=>{this.setState({deleteVisible:false})}}>Cancel</Button></Col>
                    <Col span={12} offset={1}><Button className="yesBtn" type='primary' onClick={this.handleDelete}>Yes, delete</Button></Col>
                </Row>
            </Modal>
            {this.state.imageUrl?this.imageShow():this.uploadButton()}
        </div>
    )
  }
}

export default UserEditPhotoComponent;