const EXCLUDE_MESSAGE_TYPES = ['foodReview', 'ONLINE_CLASS'];

const shouldExcludeMessage = (lastMsgEntry) => {
  let isExcluded = false;
  if(
    _.isEmpty(lastMsgEntry) || 
    !lastMsgEntry.type
  )
    return isExcluded;
  
  const { type, subType = '' } = lastMsgEntry;
  
  isExcluded = !!_.intersection(EXCLUDE_MESSAGE_TYPES, [type, subType]).length;
  return isExcluded;
};

const filterMessages = (historyMessages) => {
  return _.filter(historyMessages, m => !shouldExcludeMessage(_.get(m, 'entry')));
};

export default {
  EXCLUDE_MESSAGE_TYPES,
  shouldExcludeMessage,
  filterMessages
};

