import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get'
import find from 'lodash/find'
import pick from 'lodash/pick'
import { IHButton, IHForm, IHLoading } from 'ihcomponent'
import I18N from 'modulesAll/I18N'
import { CareplanHelper } from 'modulesAll/utils/helpers/task'
import PreviewInnerContainer from 'modulesAll/patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer';
import { getInitDataFromSchema } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import { convertFieldsValue, convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import { getItemList, schema } from 'commonModule/components/careplan/formData/TaskScheduleDetail'
import { schema as alertSchema } from 'commonModule/components/careplan/formData/AssessmentAlertForm'
import AlertFormContainer from 'ProgramEditModule/SurveyAlert/containers/CommonContainer'

// Actions
import actions from 'ProgramEditModule/Survey/actions/FormActions'
import alertFormActions from 'ProgramEditModule/SurveyAlert/actions/AlertFormActions'
import alertListActions from 'ProgramEditModule/SurveyAlert/actions/AlertListActions'
import assessmentListActions from 'ProgramEditModule/Assessment/actions/ListActions'
import surveyListActions from 'ProgramEditModule/Survey/actions/ListActions'

// GraphQL
import { editAdminProgramTask } from 'graphqlModule/mutation/editAdminProgram'
import { program as updateQueries } from 'graphqlModule/updateQueries/program'

const className = 'careplan-survey-form'
const assessmentType = 'SURVEY'
const taskType = 'editProgram'

class Container extends Component {
  static displayName = 'program/ProgramEdit/Survey/containers/FormContainer'

  componentWillUnmount() {
    const { resetAlertForm, resetAlertList, resetAssessmentList, resetSurveyList, resetAssessmentForm } = this.props
    resetAlertForm()
    resetAlertList()
    resetAssessmentList()
    resetSurveyList()
    resetAssessmentForm()
  }

  render() {
    const { program, assessment } = this.props
    if (!assessment) return <div></div>
    const currentTask = find(program.tasks, (task => get(task, 'assessment.nameKey') === assessment.nameKey))
    const backLink = this.renderBackLink()
    const footer = this.renderFooter()
    const alertForm = this.renderAlertForm()
    const scheduleDetail = this.renderScheduleDetail(currentTask)
    const preview = assessment.id ? <PreviewInnerContainer isAssessment id={assessment.id} /> : <IHLoading />

    return (
      <div>
        <div className={className}>
          {backLink}
          {preview}
          {scheduleDetail}
        </div>
        {alertForm}
        {footer}
      </div>
    )
  }

  renderScheduleDetail(currentTask) {
    const { onFieldsChange } = this.props
    const initData = CareplanHelper.convertTaskToAlertFormData(currentTask) || getInitDataFromSchema(schema)
    const fieldsValue = convertToFieldsValue(initData)
    const itemList = getItemList({ onFieldsChange, fieldsValue, title: 'Survey' })
    const ref = refNode => { this.scheduleDetail = refNode }
    const formProps = {
      vertical: true,
      itemList,
      initData,
      onFieldsChange,
      ref
    }
    const style = {
      marginTop: 40
    }
    const body = (
      <div style={style}>
        <IHForm {...formProps} />
        { this.props.validateCalendar === 'HAS_ERROR' && <div className="ant-col-20 ant-col-offset-4" style={{color:'#d72013'}}>Please select at least a day</div> }
      </div>
    )
    return body
  }

  async submit() {
    const { AlertForm, program, assessment, changePage, mutate, AlertList } = this.props
    const programId = program.id
    const panesFieldsValue = AlertList.panesFieldsValue
    const alertFieldsValue = AlertForm.fieldsValue
    let alertformData
    if (alertFieldsValue) {
      alertformData = convertFieldsValue(alertFieldsValue)
    } else {
      alertformData = getInitDataFromSchema(alertSchema)
    }
    const scheduleFormData = pick(this.scheduleDetail.getValue(), ['repeat', 'calendar'])
    const formData = Object.assign({}, alertformData, scheduleFormData)
    const resultTasks = CareplanHelper.combineAssessmentTasks(program.tasks, formData, assessment, { assessmentType, taskType, panesFieldsValue })
    const variables = {
      id: programId,
      tasks: resultTasks
    }
    const auditLogDetails = {
        programName: program.name,
        surveyName: assessment.name,
        tasks: resultTasks
    }

    try {
      const res = await mutate({ variables, updateQueries })
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.task.survey.edit'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      changePage()
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.task.survey.edit'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
  }

  renderBackLink(){
    return (
      <div className="v-link v-link-inside" onClick={() => this.props.changePage()}>
        <div>
          <i className="fa fa-angle-left" />
          Back to Care Plan
        </div>
      </div>
    )
  }

  renderAlertForm() {
    const { AlertForm, isEditMode, isShowAlertForm } = this.props
    return isShowAlertForm || isEditMode ? <AlertFormContainer {...AlertForm} /> : ''
  }

  renderFooter() {
    const { changePage } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnRight: {
        alignSelf: 'flex-end'
      }
    }
    const cancelBtnProp = {
      onClick : (e)=>{
        changePage()
      }
    }
    const addBtnProp = {
      onClick : (e)=>{
        const calendarValues = this.scheduleDetail.getValue().calendar;
        if(!calendarValues || calendarValues.length === 0){
          this.props.setValidateCalendar('HAS_ERROR');
          return;
        }

        this.submit()
        changePage()
      }
    }

    const footer = (
      <div style={styles.footer}>
        <div style={styles.btnRight}>
          <IHButton {...cancelBtnProp} style={{marginRight: 10}} bsStyle="default" label="Cancel" />
          <IHButton {...addBtnProp} bsStyle="primary" label="Save" />
        </div>
      </div>
    )
    return footer
  }
}

const ContainerWithData = graphql(editAdminProgramTask)(Container)

const mapState = ({ program }, ownProps) => {
  const Careplan = program.ProgramEdit.Careplan
  return {
    ...Careplan.Survey.SurveyForm,
    isShowAlertForm: get(Careplan, 'Survey.SurveyForm.isShowAlertForm'),
    AlertForm: get(Careplan, 'Survey.Alert.AlertForm'),
    AlertList: get(Careplan, 'Survey.Alert.AlertList'),
  }
}

const mapDispatch = (dispatch) => {
  return {
    setValidateCalendar: (validateCalendar) => dispatch(actions.setValidateCalendar(validateCalendar)),
    resetAssessmentForm: () => dispatch(actions.resetForm()),
    resetAlertForm: () => dispatch(alertFormActions.resetForm()),
    resetAlertList: () => dispatch(alertListActions.resetForm()),
    resetAssessmentList: () => dispatch(assessmentListActions.reset()),
    resetSurveyList: () => dispatch(surveyListActions.reset()),
  }
}

Container.propTypes = {
  changePage: PropTypes.func,
  onFieldsChange: PropTypes.func,
  setValidateCalendar: PropTypes.func,
  initData: PropTypes.object,
  program: PropTypes.object,
  assessment: PropTypes.object,
  fieldsValue: PropTypes.object,
  AlertForm: PropTypes.object,
  AlertList: PropTypes.object,
  isEditMode: PropTypes.bool,
  isShowAlertForm: PropTypes.bool,
  validateCalendar: PropTypes.string,
  resetAlertForm: PropTypes.func,
  resetAlertList: PropTypes.func,
  resetAssessmentList: PropTypes.func,
  resetSurveyList: PropTypes.func,
  resetAssessmentForm: PropTypes.func,
  mutate: PropTypes.func
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
