import React from 'react';
import { Spin } from 'antd';

import { Chart } from 'chart.js';
import { getVitalsDistribution } from '../API';
import { tooltipStyle, fontSize } from '../util/chartStyle';

const CANVAS_ID = 'pie-chart-vitals-distribution';

class VitalsDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    const { organizationName, organizationId } = this.props;
    this.getData(organizationName, organizationId);
  }

  getChartData = (rawData) => {
    const total = rawData.reduce((r, { memberCount }) => r + (+memberCount), 0);
    const labels = rawData.map(({ taskType, memberCount }) => `${taskType} - (${Math.round((+memberCount * 100) / total)}%)`);
    const data = rawData.map(({ memberCount }) => +memberCount);
    const backgroundColor = rawData.map(({ taskType }) => {
      switch (taskType) {
        case 'BP': return '#85e1f1';
        case 'BG': return '#ffa64c';
        case 'BP & BG': return '#025f78';
        case 'Others': return '#aee53f';
      }
    });

    return { labels, data, backgroundColor, total };
  }

  createChart = (data) => {
    const chartData = this.getChartData(data);

    this.chart = new Chart(document.getElementById(CANVAS_ID), {
      type: 'doughnut',
      data: {
        labels: chartData.labels,
        options: {
          tooltips: {
            ...tooltipStyle,
          },
          legend: { 
            display: true,
            position: 'right',
            align: 'middle',
          },
          title: {
            fontSize,
            display: true,
            text: 'Vitals Distribution'
          },
        },
        datasets: [{
          data: chartData.data,
          backgroundColor: chartData.backgroundColor,
          hoverOffset: 4
        }]
      },
      plugins: [{
        beforeDraw: function(chart) {
          const width = chart.chart.width;
          const height = chart.chart.height;
          const ctx = chart.chart.ctx;
  
          ctx.restore();
          const fontSize = (height / 114).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";
  
          const text = chartData.total;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = (height / 2) + 20;
  
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]
    });
  }

  getData = async (organizationName, organizationId) => {
    this.setState({ loading: true });
    try {
      const data = await getVitalsDistribution({ organizationName, organizationId });
      this.setState({ loading: false, data });
      this.createChart(data);
    } catch (err) {
      this.setState({ loading: false });
      this.props.openErrorModal(err.message || 'Something went wrong fetching chart data!')
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        {loading && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {!loading && 
          <React.Fragment>
            <h4 style={{ color: '#666666', textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Vitals Distribution</h4>
            <canvas id={CANVAS_ID} />
          </React.Fragment>
        }
      </div>
    );
  }
}

export default VitalsDistribution;