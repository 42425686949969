const VISIT_TYPE_ENUM = {
    'CLINIC_FOLLOW_UP': 'General Visit',
    'INIT': 'Initial Visit',
    'FOLLOW_UP': 'Follow up Visit',
    'ADDITIONAL': 'Additional Visit',
    'TECH': 'Tech Visit',
    'MNT':'MNT Visit',
    'TECH_ONBOARDING':'Tech Onboarding Visit'
};

export default VISIT_TYPE_ENUM;
