import gql from 'graphql-tag'
import { UserMini } from './schema/User';
import { Phone, Email } from './schema/common';

export default gql`
  query team($id:EID!){
    team(id:$id){
      id
      createdAt
      updatedAt
      modifiedAt
      createdBy{
        ${UserMini}
      }
      modifiedBy{
        ${UserMini}
      }
      name
      description
      contactName
      phone {
        ${Phone}
      }
      forwardingPhone{
        ${Phone}
      }
      email {
        ${Email}
      }
      healthCondition
      members{
        primary
        visible
        user{
          ${UserMini}
        }
      }
    }
  }
`
