import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import { GQLHelper } from '../../../lib/utils';

const { Option } = Select;

const defaultProps = {
  queryOptions: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
}

const SelectQueryComponent = props => {
  let { queryOptions, selectProps, processOptions } = props;
  queryOptions = {...defaultProps.queryOptions, ...queryOptions};
  const isSkipped = !!queryOptions.skip;
  return <Query {...queryOptions}>
    {
      ({loading, data, error, fetchMore, variables: prevVariables = {} }) => {
        if(error) console.error(GQLHelper.formatError(error));

        const fetchMoreData = (parseVariables, options = {}) => {
          if(typeof parseVariables !== 'function')
            return;
          
          const variables = parseVariables(prevVariables) || {};
          fetchMore({ variables, ...options });
        };

        const isLoading = !isSkipped && loading;

        const {
          renderInput,
          showSearch = true,
          notFoundContent = 'No match',
        } = selectProps || {};

        // render customized input
        if (typeof renderInput === 'function') {
          selectProps.children = renderInput(isLoading);
        }
        // component will control dataSource
        selectProps.notFoundContent = !isLoading ? notFoundContent : '';
        const options = !isLoading ? processOptions(data, Option, fetchMoreData) : [];

        return <Select {...selectProps} showSearch={showSearch}>
          {_.get(options, 'length') ? options : null}
        </Select>
      }
    }
  </Query>;
};

SelectQueryComponent.propTypes = {
  queryOptions: PropTypes.shape({
    query: PropTypes.object.isRequired,
    variables: PropTypes.object.isRequired,
    notifyOnNetworkStatusChange: PropTypes.bool,
    fetchPolicy: PropTypes.string,
    errorPolicy: PropTypes.string
  }),
  selectProps: PropTypes.object.isRequired,
  processOptions: PropTypes.func.isRequired,
};

export default SelectQueryComponent;
