/**
 * Created by zizhangai on 1/26/17.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ND, IHButton, _ } from 'ihcomponent'
import { addReason, enableButton, disableButton, deleteState } from '../actions/InterventionsActions'
// mutation
import { graphql } from 'react-apollo'
import RequestCache from 'libModule/requestCache'
import markProviderNoteError from 'modulesAll/graphql/markProviderNoteError'
import { updateQueries } from 'modulesAll/graphql/markProviderNoteError'
import { openErrorModal, closeModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils'
import { message } from 'ihcomponent'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'


class InputForInvalidationContainer extends Component {
  static displayName = 'InputForNoteInvalidationContainer'

  _onSubmit() {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientName = _.get(this.props.row, 'user.profile.fullName') || _.get(currentPatient, 'profile.fullName')
    const patientNRIC = _.get(this.props.row, 'user.identification.0.value') || _.get(currentPatient, 'identification.0.value')

    const { deleteState, mutate, id, reason, closeAfterDeprecation, dispatch, note } = this.props
    const variables = { id, reason }
    const auditLogDetails = {
      ..._.pick(note, ['category', 'content', 'createdBy']),
      markAsErrorReason: reason
    }

    this.refs.btn_sub_dep.loading(true)
    mutate({ variables, updateQueries })
      .then((res) => {
        deleteState(this.props.id)
        closeAfterDeprecation && (message.success('Note Marked as Error!'), dispatch(closeModal()))
        createAuditLog({
          action:I18N.get('auditLog.provider.alerts.mark_provider_note_error'),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
      })
      .catch((err) => {
        dispatch(openErrorModal(GQLHelper.formatError(err)))
        createAuditLog({
          action:I18N.get('auditLog.provider.alerts.mark_provider_note_error'),
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false,
          patientNRIC,
          patientName
        })
      })

  }
  render() {
    const ip = this._getInputProps()
    const onCancel = () => this.props.deleteState(this.props.id)
    return (
      <div className="intervention-notes-invalidation-wrapper">
        <ND.Input {...ip} />
        <div className="button-group">
          <IHButton label="Cancel"
                    onClick={onCancel}/>
          <IHButton label="Confirm"
                    type="primary"
                    ref="btn_sub_dep"
                    style={{margin: '0 0 0 20px'}}
                    onClick={this._onSubmit.bind(this)} />
        </div>
      </div>
    )
  }
  _getInputProps() {
    const { addReason, id, reason } = this.props
    return {
      type: 'textarea',
      placeholder: 'Add Reason here',
      value: reason,
      onChange: (e) => addReason(id, e.target.value),
      size: 'large'
    }
  }
}

const mapState = ({ Interventions }, ownProps) => {
  const { notes } = Interventions
  return {
    reason: notes[ownProps.id].reason
  }
}
const containerWithData = graphql(markProviderNoteError)(InputForInvalidationContainer)
export default connect(
  mapState,
  (dispatch) => ({
    dispatch,
    addReason: (noteId, reason) => dispatch(addReason(noteId, reason)),
    enableButton: (noteId) => dispatch(enableButton(noteId)),
    disableButton: (noteId) => dispatch(disableButton(noteId)),
    deleteState: (noteId) => dispatch(deleteState(noteId))
  })
)(containerWithData)
