import React from 'react';

const healthConditionsTooltip = <div style={{width: '465px'}}>
  <div>Transcribe all the <span>chronic</span> diagnoses from EMR (No need to include the acute/temporary dx). Only select:</div>
  <ul>
    <li>1 billable dx for enrolling patients into <span>RPM only</span> - must be the one triggering the vital(s) monitoring.</li>
    <li>2 billable dx for enrolling patients in <span>CCM and RPM</span> - must include the one(s) triggering the vital(s) monitoring</li>
  </ul>
</div>;

export {
  healthConditionsTooltip
}
