import configs from '../../../lib/config';
import { decodeId } from '../../../lib/helpers/common-helpers';

const domain = configs.UC_SERVER_URL;

export const getPatientArticles = `${domain}/patient-articles/bulk-upsert`;


// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/patient-articles-controller/bulkUpsertPatientArticles
export default (params, requestOptions) => {
  const { patientId, articleIds } = params || {};
  const currentUserId = JSON.parse(sessionStorage.getItem('currentUser')).id;

  if (!patientId) {
    console.warn('No patient id found');
    return Promise.resolve(undefined);
  }

  const _params = _.map(articleIds, (articleId) => ({
    patientId: decodeId(patientId),
    articleId,
    sentById: decodeId(currentUserId),
  }));

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(_params),
    ...requestOptions
  };

  return fetch(getPatientArticles, fetchOptions).then((res) => res.json());
};