import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

//Note: Whenever we add a new interface, it needs to be defined here explicitly!
export default new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Profile',
          possibleTypes: [
            { name: 'MemberProfile' },
            { name: 'HospitalProfile' }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Measure',
          possibleTypes: [
            { name: 'BPMeasure' },
            { name: 'BGMeasure' },
            { name: 'HSMeasure' },
            { name: 'HRMeasure' },
            { name: 'POMeasure' }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Result',
          possibleTypes: [
            { name: 'BPResult' },
            { name: 'BGResult' },
            { name: 'HSResult' },
            { name: 'HRResult' },
            { name: 'POResult' },
            { name: 'ASSESSMENTResult' }
          ]
        }
      ]
    }
  }
});
