import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import I18N from 'modulesAll/I18N';
import remove from 'lodash/remove'

const messages = I18N.get('Program.deviceForm.messages')
const labels = I18N.get('Program.deviceForm.labels')

const itemLayout = {
  labelCol : {span : 24},
  wrapperCol : {span : 24}
}

export const schema = {
  BP: {
    initialValue: 'BP5',
    required: true
  },
  HR: {
    initialValue: 'BP5',
    required: true
  },
  BG:  {
    initialValue: 'BG5',
    required: true
  },
  HS:  {
    initialValue: 'HS4S',
    required: true
  },
  TM:  {
    initialValue: 'TM3',
    required: true
  },
  PO:  {
    initialValue: 'PO3',
    required: true
  },
  /* NOTE:
    Use UPPERCASE to match with adminProgram.devices.TABLET returned from BE.
    Use IPAD_MINI to match with BE
  */
  TABLET:  {
    initialValue: 'IPAD_MINI',
    required: true
  },
}

const generateItemList = (initData, isFieldsRequired, fieldsValue) => {
  const itemListObject =
  {
    BP: {
      key : 'BP',
      label : labels.bp,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.BP,
      option: [{name:'iHealth Ease (BP3L)', value:'BP3L'}, {name:'iHealth Feel (BP5)', value:'BP5'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.BP, message : messages.bpReq}
      ]
    },
    BG: {
      key : 'BG',
      label : labels.bg,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.BG,
      option: [{name:'iHealth Smart (BG5)', value:'BG5'}, {name:'iHealth Align (BG1)', value:'BG1'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.BG, message : messages.bgReq}
      ]
    },
    HS: {
      key : 'HS',
      label : labels.hs,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.HS,
      option: [{name:'iHealth Lite (HS4S)', value:'HS4S'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.HS, message : messages.hsReq}
      ]
    },
    /* NOTE: support case where only have vital HR */
    HR: {
      key : 'HR',
      label : labels.hr,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.HR,
      option: [{name:'iHealth Ease (BP3L)', value:'BP3L'}, {name:'iHealth Feel (BP5)', value:'BP5'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.HR, message : messages.hrReq}
      ]
    },
    TM: {
      key : 'TM',
      label : labels.tm,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.TM,
      option: [{name:'iHealth Thermometer TS-1', value:'TM3'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.TM, message : messages.tmReq}
      ]
    },
    PO: {
      key : 'PO',
      label : labels.po,
      type : 'select',
      ...itemLayout,
      placeholder : 'Select Model',
      initialValue: initData.PO,
      option: [{name:'iHealth Pulse Oxygen', value:'PO3'}],
      col: {span: 12, offset: 0},
      rules : [
        {required : isFieldsRequired.PO, message : messages.tmReq}
      ]
    },
    // tablet: {
    //   key : 'tablet',
    //   label : labels.tablet,
    //   type : 'select',
    //   ...itemLayout,
    //   disabled: true,
    //   placeholder : 'Select Model',
    //   initialValue: initData.TABLET,
    //   option: [{name:'iPad Mini 2', value:'IPAD_MINI'}],
    //   col: {span: 12, offset: 0},
    //   rules : [
    //     {required : isFieldsRequired.tablet, message : messages.tabletReq}
    //   ]
    // },
    // Gateway: {
    //     key : 'Gateway',
    //     label : 'Gateway',
    //     type : 'select',
    //     ...itemLayout,
    //     placeholder : 'Select Model',
    //     option: [{name:'iHealth Go Mini', value:'GoMini'}],
    //     col: {span: 12, offset: 0},
    // }
  }

  const deviceNames = Object.keys(fieldsValue).filter(field => ['BP', 'BG', 'HS', 'HR', 'TM', 'PO'].includes(field))
  // If have BP, use it for both BP and HR if any, otherwise use HR if any
  if (deviceNames.includes("BP")) remove(deviceNames, v => v === "HR")
  const itemList = deviceNames.map(field => [itemListObject[field]])
  // itemList.push([itemListObject['tablet']])
  //itemList.push([itemListObject['Gateway']])
  return itemList
}


export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange, mapPropsToFields }) => ({
  vertical: true,
  itemList: generateItemList(initData, isFieldsRequired, fieldsValue),
  fieldsValue,
  onFieldsChange,
  mapPropsToFields
})
