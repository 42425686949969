import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { graphql, compose } from 'react-apollo';
// import { vitalsSummaryList } from 'modulesAll/graphql/resultList';
import { resultList } from '../../../logBook/query/index';
import medicationList from 'modulesAll/graphql/medicationList';
import { RESULT_COUNT_MAX } from 'libModule/constants';
import convertor from '@ihealth/convertor';
import '../style/index.css';
import '../style/vitalsSummaryPrintout.scss';
import '../style/logbookPrintOut.scss';
import { VITALS_ORDER } from '../../../../lib/constants';
import BPLogBookMiniComponent from './BPLogBookMiniComponent'
import BGLogBookMiniComponent from './BGLogBookMiniComponent';
import POLogBookMiniComponent from './POLogBookMiniComponent';
import HSLogBookMiniComponent from './HSLogBookMiniComponent';
import medicationHelper from '../../../careplanNew/component/helper/medicationHelper';

const DATE_FORMAT = 'MM/DD/YYYY';

const BG_TYPE_NAME_MAP = {
  afterMeal: 'After Meal',
  bedtime: 'Bed Time',
  bedtimeOvernightHypo: 'Bed Time + Overnight Hypos',
  beforeMeal: 'Before Meal',
  criticalHigh: 'Critical High',
  criticalLow: 'Critical Low',
  fasting: 'Fasting',
  overnight: 'Overnight',
};

const BP_TYPE_NAME_MAP = {
  afternoon: 'Afternoon',
  all: 'All',
  arrhythmia: 'Arrhythmia',
  criticalHigh: 'Critical High',
  elevated: 'Elevated',
  evening: 'Evening',
  hypertension1: 'Hypertension 1',
  hypertension2: 'Hypertension 2',
  low: 'Low',
  morning: 'Morning',
  normal: 'Normal',
  overnight: 'Overnight',
  pulsePressure: 'Pulse Pressure'
};

class VitalsSummaryPrintout extends React.Component {

  renderPatientInfo = () => {
    const { user, currentProgram } = this.props;

    const identification = _.get(user, 'identification', [{}]);
    const profile = _.get(user, 'profile', {});
    const { birthday, gender, height, weight, fullName } = profile;
    const mr = _.get(identification, '0.value');
    const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : 0;
    const heightInches = heightInInches % 12;
    const heightFeet = Math.floor(heightInInches / 12);
    let vitals = _.get(currentProgram, 'tasks', []).map(m => m.type);
    vitals = _.intersection(VITALS_ORDER, vitals);

    return (
      <div>
        <h2><strong style={{ fontWeight: 700 }}>{fullName}</strong></h2>
        <div className="patient-info">
          <b>{moment(birthday).format('MM/DD/YYYY')}</b>
          <b>{moment(birthday).fromNow().split(' ')[0] + 'y'}</b>
          <b>{gender}</b>
          <b>{mr}</b>
          <b>{heightInInches ? `${heightFeet}'${heightInches}"` : ''}</b>
          <b>{weight ? (convertor.HS(weight.value, 'kg', 'lbs', 0) + 'lb') : ''}</b>
          <b>Vitals: {vitals.join(', ')}</b>
        </div>
      </div>
    );
  }

  renderICDCodes = () => {
    const { user } = this.props;
    const healthConditions = _.get(user, 'profile.healthConditions', []);
    return (
      <div>
        <h3><strong>ICD10 Code</strong></h3>
        <Row>
          {healthConditions.map((hc, i) => {
            const [description, code] = hc.split('::');
            return (
              <Col span={12} key={i} style={{ padding: '0 10px' }} >
                <Row>
                  <Col span={6}><b>{code}</b></Col>
                  <Col span={18}><b>{description}</b></Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }


  renderMedications = () => {
    const { medications } = this.props;
    return (
      <div>
        <h3><strong>Medication</strong></h3>
        <Row>
          {medications.map((m, i) => {
            const isMedicationActive = medicationHelper.checkIfMedicationActive(m);
            return isMedicationActive ? (
              <Col span={12} key={i} style={{ padding: '0 10px' }} >
                <b>{m.name} ({m.dosage})</b>
              </Col>
            ) : null;
          })}
        </Row>
      </div>
    );
  }

  getVitalSummaryTitle = title => {
    const { fromDate, toDate } = this.props;
    
    return <h3>
      <strong>
        {title} ({moment(fromDate).format(DATE_FORMAT)} - {moment(toDate).format(DATE_FORMAT)})
      </strong>
    </h3>;
  };

  renderBPLogTable = (title, unit, data) => {
    const { fromDate, toDate } = this.props;
    return (
      <div>
       {this.getVitalSummaryTitle(title)}
       <BPLogBookMiniComponent resultList={data} fromDate={fromDate} toDate={toDate}/>
      </div>
    );
  }

  renderBloodTable = (title, unit, body) => {
    return (
      <div>
       {this.getVitalSummaryTitle(title)}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Count</th>
                <th>Average({unit})</th>
                <th>Range({unit})</th>
                <th>Vitals Spent in Normal Range</th>
              </tr>
            </thead>
            <tbody>
              {body}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderBgLogTable = (title, unit, data) => {
    const { fromDate, toDate } = this.props;
    return (
      <div>
       {this.getVitalSummaryTitle(title)}
       <BGLogBookMiniComponent resultList={data} fromDate={fromDate} toDate={toDate}/>
      </div>
    );
  }

  renderBGSummary = () => {
    const { vitalsSummary } = this.props;
    return _.map(_.pickBy(vitalsSummary.bg, (v, k) => k !== '__typename'), (value, key) => {
      return (
        <tr key={'bg_' + key}>
          <td><b>{BG_TYPE_NAME_MAP[key]}</b></td>
          <td><b>{value.count}</b></td>
          <td><b>{value.average.length > 0 ? value.average[0] : ''}</b></td>
          <td><b>{value.range.length > 0 ? `${value.range[0]}-${value.range[1]}` : 'N/A'}</b></td>
          <td><b>{value.inRangePercentage ? `${value.inRangePercentage}%` : 'N/A'}</b></td>
        </tr>
      );
    });
  }

  renderBPSummary = () => {
    const { vitalsSummary } = this.props;
    return _.map(_.pickBy(vitalsSummary.bp, (v, k) => k !== '__typename'), (value, key) => {
      const averageArr = _.get(value, 'average', 'N/A');
      const rangeArr = _.get(value, 'range', undefined);
      return (
        <tr key={'bp_' + key}>
          <td><b>{BP_TYPE_NAME_MAP[key]}</b></td>
          <td><b>{value.count}</b></td>
          <td><b>{(averageArr.length === 0 || averageArr === undefined) ? 'N/A' : (averageArr.length == 1) ? averageArr[0] : averageArr[0] + '/' + averageArr[1]}</b></td>
          <td><b>{(rangeArr.length === 0 || rangeArr === undefined) ? 'N/A' : (rangeArr.length == 2) ? rangeArr[0] + '-' + rangeArr[1] : rangeArr[0] + '-' + rangeArr[1] + '/' + rangeArr[2] + '-' + rangeArr[3]}</b></td>
          <td><b>{value.inRangePercentage ? `${value.inRangePercentage}%` : 'N/A'}</b></td>
        </tr>
      );
    });
  }

  renderBloodOxygenTable = (title, unit, body) => {
    return (
      <div>
        {this.getVitalSummaryTitle(title)}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Count</th>
                <th>Average({unit})</th>
                <th>Range({unit})</th>
                <th>Perfusion Index({unit})</th>
                <th>Average Pulse Rate(bpm)</th>
              </tr>
            </thead>
            <tbody>
              {body}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderPOSummary = () => {
    const { vitalsSummary } = this.props;
    const poSummary = _.pickBy(vitalsSummary.po, (v, k) => k !== '__typename');
    return _.map(poSummary, (value, key) => {
      const average = _.get(value, 'average') === null ? 'N/A' : _.get(value, 'average');
      const rangeArr = _.get(value, 'range', undefined);
      const POrange = (rangeArr === undefined || rangeArr.length === 0) ? 'N/A' : rangeArr[0] + '-' + rangeArr[1];
      const perfusionIndex = _.get(value, 'perfusionIndexAverage') === null ? 'N/A' : _.get(value, 'perfusionIndexAverage') + '%';
      const pulseRateAverage = _.get(value, 'pulseRateAverage') === null ? 'N/A' : '' + _.get(value, 'pulseRateAverage');
      const count = _.get(value, 'count', 0);

      return (
        <tr key={'po_' + key}>
          <td><b>{_.upperFirst(key)}</b></td>
          <td><b>{count}</b></td>
          <td><b>{average}</b></td>
          <td><b>{POrange}</b></td>
          <td><b>{perfusionIndex}</b></td>
          <td><b>{pulseRateAverage}</b></td>
        </tr>
      );
    });
  };

  renderPoLogTable = (title, unit, data) => {
    const { fromDate, toDate } = this.props;
    return (
      <div>
       {this.getVitalSummaryTitle(title)}
       <POLogBookMiniComponent resultList={data} fromDate={fromDate} toDate={toDate}/>
      </div>
    );
  }

  renderHsLogTable = (title, unit, data) => {
    const { fromDate, toDate } = this.props;
    return (
      <div>
       {this.getVitalSummaryTitle(title)}
       <HSLogBookMiniComponent resultList={data} fromDate={fromDate} toDate={toDate}/>
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return null;
    }

    const { currentProgram, resultList } = this.props;
    const shouldHaveBG = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BG').length != 0;
    const shouldHaveBP = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'BP').length != 0;
    const shouldHavePO = _.filter(_.get(currentProgram, 'tasks'), (t) => t.type == 'PO').length != 0;
    const bpLogList = _.filter(resultList, (result) => result.__typename === "BPResult" && result.severity !== "MISSED");
    const bgLogList = _.filter(resultList, (result) => result.__typename === "BGResult" && result.severity !== "MISSED");
    const poLogList = _.filter(resultList, (result) => result.__typename === "POResult" && result.severity !== "MISSED");
    const hsLogList = _.filter(resultList, (result) => result.__typename === "HSResult" && result.body_weight !== null);
    let shouldHaveBPLog = bpLogList.length !== 0;
    let shouldHaveBGLog = bgLogList.length !== 0;
    let shouldHavePOLog = poLogList.length !== 0;
    let shouldHaveHSLog = hsLogList.length !== 0;

    return (
      <Row className="print vitals-summary-printout">
        <table className="report-container">
          <thead className="report-header">
            <tr>
              <th>
                <h2 style={{ marginBottom: 20 }}>Vitals Summary</h2>
                {this.renderPatientInfo()}
                <hr style={{ borderTop: '2px solid #656565' }} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {this.renderICDCodes()}
                <hr />
                {this.renderMedications()}
                {shouldHaveBGLog && this.renderBgLogTable('Blood Glucose Log', 'mg/dL', bgLogList)}
                <hr />
                {shouldHaveBG && this.renderBloodTable('Blood Glucose Summary', 'mg/dL', this.renderBGSummary())}
                <hr />
                {shouldHaveBPLog && this.renderBPLogTable('Blood Pressure Log', 'mmHg', bpLogList)}
                <hr />
                {shouldHaveBP && this.renderBloodTable('Blood Pressure Summary', 'mmHg', this.renderBPSummary())}
                <hr />
                {shouldHavePOLog && this.renderPoLogTable('Blood Oxygen Log', '%', poLogList)}
                <hr/>
                {shouldHavePO && this.renderBloodOxygenTable('Blood Oxygen Summary', '%', this.renderPOSummary())}
                <hr/>
                {shouldHaveHSLog && this.renderHsLogTable('Weight Log', 'kg', hsLogList)}
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
    );
  }
}

const withResData = graphql(resultList, {
  options: (ownProps) => {
    const { fromDate, toDate, patientId, enrolledProgramId } = ownProps;
    return {
      variables: {
        filters: {
          memberId: patientId,
          fromDate,
          toDate,
          enrolledProgramId: [enrolledProgramId],
          type:['BP', 'BG', 'PO', 'HS']
        },
        count: RESULT_COUNT_MAX,
        page: 1,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const { loading, resultList } = data;

    if (loading) {
      return {
        loading
      };
    }
    return {
      vitalsSummary: _.get(resultList, 'stat', { bg: [], bp: [], po: [] }),
      resultList:  _.get(resultList, 'data'),
      loading,
    };
  }
});

const withMedicationList = graphql(medicationList, {
  options: (ownProps) => {
    return {
      variables: {
        memberId: ownProps.patientId,
        page: 1,
        count: 100,
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, medicationList } = data;
    if (loading) {
      return {
        loading
      };
    }
    return {
      medications: _.get(medicationList, 'data', []),
    };
  }
});

export default compose(withResData, withMedicationList)(VitalsSummaryPrintout);
