import React from 'react';
import { IHSearchTable, IHButton, moment, _ } from 'ihcomponent';
import { SuperContainer, goPath, GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

export default class ToDoListComponent extends React.Component {
  static displayName = 'ToDoListComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  async componentWillUpdate(nextProps) {
    if(this.props.modal !== nextProps.modal && this.props.refetch){
      await this.props.refetch()

      if (this.props.list && this.props.list.data.length === 0 && this.props.page.current !== 1) {
        this.props.onTableChange({ pageSize: 5, current: 1 })
      }
    }
  }

  render(){
    return (
      <div className='provider-task-list-table'>
        {this.renderTable()}
        <div className="v-c-bd">
          <IHButton
            type="primary"
            label="See All Tasks"
            onClick={()=>{
              goPath('/tasks')
            }}
          />
        </div>
      </div>
    )
  }

  getRowSelection(list){
    const onSelectChange = (obj, flag) => {
      if(flag){
        this.editTodoGQL({
          ...obj,
          completedAt : moment().toDate()
        })
      }
      else{
        this.editTodoGQL({
          ...obj,
          completedAt : null
        })
      }
    }
    return {
      selectedRowKeys : _.map(_.filter(list, (l)=>!!l.completedAt), (l)=>l.id),
      onSelect: onSelectChange,
    };
  }

  async editTodoGQL(variables){
    const auditLogDetails = {
      description: variables.description || '',
      dueDate: moment(variables.dueDate).format('MMM-DD-YYYY'),
      createdAt: moment(variables.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
      updatedAt: moment(variables.updatedAt).format('MMM-DD-YYYY hh:mm a:ss'),
      completedAt: variables.completedAt && moment(variables.completedAt).format('MMM-DD-YYYY hh:mm a:ss'),
    }

    try{
      const res = await this.props.editTodoGQL({variables})
      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.completeTask'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
    } catch(e){
      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.completeTask'),
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })
      this.props.openErrorModal(GQLHelper.formatError(e))
    } finally {
      this.props.reset()
    }
  }

  renderCreateButton(){
    const b = {
      type : 'primary',
      label : 'Create New Task',
      onClick : ()=>{
        this.props.showModal(true)
      },
      size : 'small'
    }
    return <IHButton {...b} />
  }

  renderDueData(doc){
    const t = moment(doc.dueDate).format('MMM-DD-YYYY')
    const sy = {
      color : ''
    }

    // Not using momentjs (isBefore) method because of a depreciated warning
    // This warning will cause momentjs to not work in IE
    const dueDate = new Date(doc.dueDate).setHours(0,0,0,0)
    const dateNow = new Date().setHours(0,0,0,0)

    if(dueDate < dateNow) {
      sy.color = '#e85c6a'
    }

    return <font style={sy}>{t}</font>
  }

  renderTable(){
    const p = this.getTableProps()
    return <IHSearchTable {...p} style={{height: '28em'}}/>
  }

  getTableProps(){
    const { onTableChange, page, loading } = this.props;
    const list = _.get(this.props,'list.data', [])

    return {
      title : 'To-do List',
      rowKey : (doc)=>doc.id,
      onChange: onTableChange,
      loading,
      actions : this.renderCreateButton(),
      rowSelection: this.getRowSelection(list),
      columns : [
        {
          title : 'Task Description',
          key : 'taskDescription',
          width : '50%',
          render(t, doc){
            return doc.description
          },
        },
        {
          title : 'Due On',
          key : 'due',
          dataIndex: 'dueDate',
          sorter: (a, b) => a.dueDate - b.dueDate,
          render : (t, doc)=>{
            return this.renderDueData(doc)
          },
        }
      ],
      dataSource : list,
      onRow : (doc)=>{
        return {
          onClick: () => {
            this.props.showModal(true, doc)
          }
        }
      },
      // pagination: {
      //   current: _.get(page, 'current', 1),
      //   pageSize: _.get(page, 'pageSize', 5),
      //   total: _.get(this.props.list, 'pageInfo.total', 0),
      // },
    }
  }
}
