import { IHButton, React } from 'ihcomponent';
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import { modalAction } from 'modulesAll/common/actions'
import I18N from '../../../I18N';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { PATIENT_TYPE, SEARCH_TABS } from '../constants/MainConstants';
import { Tabs, Input, Icon, Tooltip, Button,notification } from 'antd';
import PatientsTable from './PatientsTable';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { decryptRole, RequestCache, goPath } from 'libModule/utils';
import { SEARCH_COUNT_MAX } from 'libModule/constants';
import AutoCompleteComponent from '../components/AutoCompleteComponent';
import PatientListOptionTemplate from '../components/PatientListOptionTemplate';
import {helpers as dataHelper} from "../../../visitNewWorkFlow/helper";
import { patientListOnlyWithPageInfo } from 'graphqlModule/patientList';
import { patientListSearch } from 'graphqlModule/searchPatient';
import { getSearchStrForDOB, getFieldsAndMatch } from '../helper/searchHelper';
import '../css/main.scss';
import { graphql, compose } from 'react-apollo';
import { getPatientsList } from '../../API/index';
import { downloadBlob } from 'libModule/utils'
import { successModal,failModal,notificationkey } from '../../helper/index';
const SEARCH_INTERVAL = 600; // allow user to search every SEARCH_INTERVAL (ms)
const RD_HC_ROLES = ['Health Coach', 'Nurse'];
const CA_ROLES = ['Medical Assistant'];
const BUTTON_LABELS = I18N.get('patientlist.buttons');

const Component = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        searchStr: '',
        canSearch: true, 
        showCreateNewPatientForm:false, // for MA/MD only
      };
      this.userRole = decryptRole().split(':')[1];
      this.startSearch = _.debounce(this.startSearch, SEARCH_INTERVAL); // delay the API call when typing
    };

    componentDidMount = () => {
      this.props.onLoadParams(this.props.globalTableState, true); // don't remove initState when it is set
      RequestCache.set('nurse/currentPatient', undefined); //reset
    };

    // componentWillUnmount() {
      // if (!new RegExp(/^(\/patients)/).test(Store.getState().routing.location.pathname)) {
      //   this.props.onLoadParams(null, false); // don't remove initState when it is set
      // }
    // }

    setCreateNewPatientForm = showCreateNewPatientForm => 
      this.setState({ showCreateNewPatientForm }); // for MA/MD
           
    onTabChange = selectedTab => this.props.onSetParams({ selectedTab });

    onSearchChange = searchStr => {
      //this.props.onSetParams({ search: searchStr, canSearch: false });
      this.setState({ searchStr, canSearch: false }, () => {
        this.startSearch();
      });
    };

    startSearch = () => this.setState({ canSearch: true });

    goToPatientProfile = (patientId, isMAorMD) => {
      const { curPage, handleChangePage } = this.props;
      Mixpanel.calculateDuration(curPage);
      Mixpanel.track('clicked','patient', `patient_list${isMAorMD ? '_ma'  : ''}`,{PATIENTS_ID : patientId });
      goPath(`/patients/${patientId}/enrolledprogram/default`);
      Mixpanel.time_event('patient_profile');
      handleChangePage('patient_profile');
    };

    getTabName = (tab) => {
      // Show patient number in the tab header
      const { generalPatientsCount = 0, enrolledPatientsCount = 0 } = this.props;
      if(_.includes(tab.name, 'Enrolled')){
        return `${tab.name} (${enrolledPatientsCount})`
      } else {
        return `${tab.name} (${generalPatientsCount})`
      }
    }

    handleClickDownloadList =async ()=>{
        let res = await getPatientsList('/csv-file/patientList',successModal,failModal,()=>notification.close(notificationkey));
        downloadBlob(res, 'enrolled_patients_list.csv', 'text/csv;charset=utf-8;')
    }

    render() {
        const { searchStr = '', canSearch, showCreateNewPatientForm } = this.state;
        const { selectedTab } = _.get(this, 'props.globalTableState', {});
        const isMAorMD = this.userRole === 'Clinic Medical Assistant' || this.userRole === 'Doctor';
        const { myPatientsCount = '-' } = this.props;
        const [fields, match] = getFieldsAndMatch(getSearchStrForDOB(searchStr));
        const autocompleteVariables = {
          page: 1,
          count: SEARCH_COUNT_MAX,
          filters: { showAll: true },
          search: { fields, match },
          sort: { 'field': 'LAST_NAME', 'direction': 'ASC' }
        };

        const registerButtonProps = {
          size: '',
          type: 'primary',
          onClick: () => {
            Mixpanel.track('clicked', 'register new Patient', '', '');
            if(isMAorMD) {
              this.setCreateNewPatientForm(true); 
            } else {
              goPath('/patients/register/manual_input');
            }
            // Mixpanel.track('click',BUTTON_LABELS.newPatientButton.split(' ').join('_'),'patient_list');
          }
        };
        const extraContent = <div>
            { selectedTab === PATIENT_TYPE.ENROLLED  && <a onClick={()=>this.handleClickDownloadList()} style={{ marginRight: 10 }}>Export ALL enrolled patients</a> }
            {!isMAorMD && selectedTab === PATIENT_TYPE.ENROLLED && <span>My Patients: {myPatientsCount}</span>}
        </div>
        return (
          <div className="vsm-main-page vsm-patientList-main">
            <div className="vsm-main-990">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                <div>
                  <AutoCompleteComponent
                    queryOptions={{
                      query: patientListSearch,
                      variables: autocompleteVariables,
                      skip: !canSearch || !searchStr, // do not search when flag canSearch is false, or search keyword is empty
                      errorPolicy: 'ignore', // ignore error and allow MA/MD to view data
                    }}
                    autocompleteProps={{
                      className: 'search-patientlist-autocomplete',
                      value: searchStr,
                      children: (
                        <Input 
                          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          placeholder="Search ALL patients by First name, Last name / DOB / Med ID / Tel" 
                        />
                      ),
                      //onChange: this.onSearch,
                      onSearch: this.onSearchChange, // used to prevent multiple API calls when typing
                      onSelect: patientId => this.goToPatientProfile(patientId, isMAorMD),
                      autoFocus: true,
                      defaultActiveFirstOption: false,	
                      dropdownMatchSelectWidth: false,
                      dropdownStyle: {
                        maxHeight: '70vh',
                        overflowY: 'auto',
                      },
                      dropdownMenuStyle: { 
                        width: 700, 
                        maxHeight: 'fit-content', 
                        paddingLeft: 15 
                      },
                      dropdownAlign: {
                        points: ['tl', 'bl'],
                        overflow: { adjustY: 0 },
                      }
                    }}
                    processDataSource={(data, OptionLayout) => {
                      const patientList = _.get(data, 'patientList.data', []);
                      
                      const options =  patientList.map(patient => {
                        const { id, phone, identification, profile } = patient.user;
                        const { fullName, birthday } = profile;

                        const mobilePhone = _.get(_.filter(phone, e => e.type ==='MOBILE'), '0.number', '--'); // get Tel
                        const status = dataHelper.getTypeAndStatus(patient.enrolledPrograms, profile.lastMeasuredAt).status; // enrollment status
                        let CCMRPM = _.filter(profile.programCategories, e => e.enrolled === true);
                        CCMRPM = _.join(!CCMRPM.length ? ['--'] : _.map(CCMRPM, e => e.name), ', '); // get CCMRPM
                        const patientProps = { 
                          fullName, 
                          DOB: dataHelper.convertDateFormat(birthday), 
                          MRN: _.get(identification, '0.value', '--'),
                          mobilePhone,
                          status,
                          CCMRPM
                        };

                        return <OptionLayout key={id}>
                            <PatientListOptionTemplate {...patientProps} />
                        </OptionLayout>;
                      })

                      // add title for options iff there is a result, else show no result
                      options.unshift(
                        <OptionLayout key='search-result-title' disabled >
                          <PatientListOptionTemplate hasNoResult={!options.length} /> 
                        </OptionLayout>
                      )
                      return options;
                    }}
                  />
                </div>
                <div className="v-rd">
                  <Button {...registerButtonProps}>{I18N.get('patientlist.buttons.newPatientButton')}</Button>
                </div>
              </div>
              <div className="v-table">
              <Tabs activeKey={selectedTab} onChange={this.onTabChange} style={{ textAlign: 'left' }}
                    tabBarExtraContent={extraContent}>
                {
                  _.map(SEARCH_TABS, tab => (
                    <Tabs.TabPane
                      tab={
                        <Tooltip 
                          overlayStyle={tab.tooltip.style}
                          title={tab.tooltip.title} 
                          placement='bottom'
                        >
                          {this.getTabName(tab)}
                        </Tooltip>
                      } 
                      key={tab.key}
                      id={tab.testId}
                      //forceRender={true} 
                    >
                      <div className="v-table">
                        <PatientsTable 
                          patientType={tab.key}
                          {...this.props} // props include reduxActions
                          {...this.props.globalTableState}
                          isMAorMD={isMAorMD}
                          setCreateNewPatientForm={this.setCreateNewPatientForm}
                          showCreateNewPatientForm={showCreateNewPatientForm}
                          goToPatientProfile={patientId => this.goToPatientProfile(patientId, isMAorMD)}
                        />
                      </div>
                    </Tabs.TabPane>
                  ))                  
                }
              </Tabs>
              </div>
            </div>
          </div>
        );
    }
};

const mapStateToProps = state => ({
  globalTableState: state.globalTables.search_patient_list,
});

const mapDispatchToProps = dispatch => ({
  // table actions, and initState if it doesn't exist
  ...createTableAction('search_patient_list',{
    sortFieldMap: { age: 'BIRTHDAY', fullName:'LAST_NAME',timeSpent:'TIME_SPENT', enrolledDate:'ENROLLMENT_DATE', registeredAt: 'REGISTERED', monthlyMeasurementDaysRange: 'MONTHLY_MEASUREMENT_DAYS', dischargedDate: 'DISCHARGED_DATE' },
    shouldUpdateHistory: true,
    initState: {
      selectedTab: _.values(PATIENT_TYPE)[0],
    }
  }),
  showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user)),
  openErrorModal:(error)=>dispatch(openErrorModal(error)),
});

const enrolledPatients = graphql(patientListOnlyWithPageInfo, {
    options: (ownProps) => {
      return {
        variables: {
          count: 9999,
          page: 1,
          filters: {
            generalPatient: false,
            showAll: true,
            status: ['ENROLLED']
          }
        },
        fetchPolicy: 'network-only'
     }
    },
    props: ({data}) => {
      const { patientList, refetch } = data;
      return {
        enrolledPatientsCount : _.get(patientList, 'pageInfo.total'),
        refetchEnrolledPatientsCount: refetch
      }
    }
})

const myPatients = graphql(patientListOnlyWithPageInfo, {
  options: (ownProps) => {
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const { selectedRole } = loginUser;
    const { name } = selectedRole;

    let filters = {
        generalPatient: false,
        showAll: true,
        status: ['ENROLLED'],
    }

    if(_.includes(RD_HC_ROLES, name)) {
      filters['assignedToRD'] = [loginUser.id];
    }

    if(_.includes(CA_ROLES, name)) {
      filters['assignedToCA'] = [loginUser.id];
    }

    return {
      variables: {
        count: 9999,
        page: 1,
        filters
      },
      fetchPolicy: 'network-only'
   }
  },
  props: ({data}) => {
    const { patientList, refetch } = data;
    return {
      myPatientsCount : _.get(patientList, 'pageInfo.total'),
      refetchMyPatientsCount: refetch
    }
  }
})

const generalPatients = graphql(patientListOnlyWithPageInfo, {
  options: (ownProps) => {
    return {
      variables: {
        count: 9999,
        page: 1,
        filters: {
          generalPatient: true,
          showAll: true,
          status: []
        }
      },
      fetchPolicy: 'network-only'
   }
  },
  props: ({data}) => {
    const { patientList, refetch } = data;
    return {
      generalPatientsCount : _.get(patientList, 'pageInfo.total'),
      refetchGeneralPatientsCount: refetch
    }
  }
})

export default compose(connect(mapStateToProps, mapDispatchToProps), generalPatients, enrolledPatients, myPatients)(Component);