import React from 'react';
import { Table } from 'antd';
import PoMap from '../constants/map';
import POBaselineContainer from '../cotainers/POBaselineContainer';

const POResultStatisticsComponent  = class extends React.Component {
    renderHeader = () => {
        const highest = _.get(this.props, 'stat.po.highestPO');
        return (
            <div>
                <span>Blood Oxygen Summary</span>
                <POBaselineContainer highest={highest} task={this.props.task} enrolledProgramId={this.props.enrolledProgramId} />
            </div>
        );
    }

    renderBPSummary(){
        const stat = _.get(this.props, 'stat.po', _.get(this.props, 'stat', {}));
        const source =[];


        Object.entries(stat).forEach(e =>
            {   
                if(e[0] === '__typename' || e[0] === 'highestPO') {
                    return
                }
                let type = PoMap[e[0]];
                let value = e[1];
                let count = _.get(value, 'count', 'N/A');
                let average = _.get(value, 'average') === null ? 'N/A' : _.get(value, 'average');
                let rangeArr = _.get(value, 'range', undefined);
                let range = (rangeArr.length === 0 || rangeArr === undefined) ? 'N/A' : rangeArr[0] + '-' + rangeArr[1];
                let perfusionIndex = _.get(value, 'perfusionIndexAverage') === null ? 'N/A' : _.get(value, 'perfusionIndexAverage') + '%';
                let pulseRateAverage = _.get(value, 'pulseRateAverage') === null ? 'N/A' : '' + _.get(value, 'pulseRateAverage');
                source.push({
                    type,
                    count,
                    average,
                    range,
                    perfusionIndex,
                    pulseRateAverage
                })
            }
        );

        const columns = [
            {
                title:'Type',
                key:'type',
                align:'center',
                dataIndex: 'type',
                width: '26%'
            },
            {
                title:'#',
                key:'count',
                align:'center',
                dataIndex: 'count',
                width: '10%'
            },
            {
                title:'Avg (%)',
                key:'average',
                align:'center',
                dataIndex:'average',
                width: '15%'
            },
            {
                title:'Range (%)',
                key:'range',
                align:'center',
                dataIndex:'range',
                width: '20%'
            },
            {
                title:'Perfusion Index (%)',
                key:'perfusionIndex',
                align:'center',
                dataIndex:'perfusionIndex',
            },
            {
                title:'Average Pulse Rate (bpm)',
                key:'pulseRateAverage',
                align:'center',
                dataIndex:'pulseRateAverage'
            },
        ];

        return <Table dataSource={source}
                      rowKey={(record,index) => index}
                      columns={columns}
                      pagination={false}
                      bordered={false}
                      title={this.renderHeader}
                      rowClassName='statisticsTableRow'
                      className='statisticsTable'/>
    }

    render(){
        const { loading } = this.props;
        if(loading){
            return <div>Loading</div>
        }
        return<div>
                { this.renderBPSummary() }
              </div>
    }
}

export default  POResultStatisticsComponent;
