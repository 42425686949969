import React from 'react';
import { Form, Input, Modal } from 'antd';
import { STATUS_ENUM } from '../../../referAndEnroll/constant';
import { 
  VITAL_TO_CONDITIONS, CCM_CONDITION_TO_ICD, RPM_CONDITION_TO_ICD, DIAGNOSIS_SOURCE 
} from '../../../referAndEnroll/constant/programEligibility';
import { getValidICDCodes } from '../../../referAndEnroll/helper';
import { getOrgProgramParticipation } from '../../../../lib/utils';
import Client from 'libModule/gqlClient';
import editUser from 'modulesAll/graphql/mutation/editUser';

const HPTS = ['R03.01', 'I95.x']; // hypotension
const UPDATED_CONDITION_TO_ICD = (() => {
  const updated = _.cloneDeep(CCM_CONDITION_TO_ICD);
  updated.HPTS = HPTS;
  return updated;
})();
const UPDATED_VITAL_TO_CONDITIONS = (() => {
  const updated = _.cloneDeep(VITAL_TO_CONDITIONS);
  updated.BP.push('HPTS');
  return updated;
})();
const VITAL_TO_ICD = (() => {
  const vitalToICDMap = {};
  const vitalToConditionMap = { ...UPDATED_VITAL_TO_CONDITIONS };
  _.map(vitalToConditionMap, (conditionsArr, vital) => {
    const codes = _.map(conditionsArr, (condition) => {
      const conditionCodes = UPDATED_CONDITION_TO_ICD[condition] || [];
      return conditionCodes;
    });
    vitalToICDMap[vital] = _.flatMap(codes);
  });
  return vitalToICDMap;
})();

const CONDITION_PRIORITY_INDEX = {
  'DM': 0,
  'HTN': 1,
  'PreDM': 2,
  'HPTS': 3,
  'COPD': 4,
  'CKD': 5,
  'CHF': 6,
  'HLD': 7,
  'Obesity': 8,
  'otherCodes': 9,
};

const getCodeName = (code) => _.split(code, '::')[1];

export default class BillableConditionHelper {
  qualifiedPrograms = ['CCM', 'RPM'];
  constructor(args) {
    const { patient, enrolledProgram, patientReferral, consentInfo } = args;
    this.patient = patient;
    this.enrolledProgram = enrolledProgram;
    this.patientReferral = patientReferral;
    this.consentInfo = consentInfo;
    this.orgProgramParticipation = _.map(getOrgProgramParticipation(), pp => _.toUpper(pp));
    this.addedCodes = [];
    this.autoMarkedCodes = [];
    this.autoMarkedCodeWithoutMatch = null; // to track additional billable code
  }
  _getPatientId = () => _.get(this, 'patient.id');
  _getPatientBirthday = () => _.get(this, 'patient.profile.birthday');
  _getPatientHealthConditions = () => _.get(this, 'patient.profile.healthConditions') || [];
  _getPatientBillableHealthConditions = () => {
    const conditions = _.get(this, 'patient.profile.billableHealthConditions') || [];
    return conditions.map((c) => _.omit(c, '__typename'));
  }
  _getPatientProgramCategories = () => _.get(this, 'patient.profile.programCategories') || [];
  _getAddedCodes = (currentList) => {
    // const patientCodeList = this._getPatientHealthConditions();
    // const addedCodes = _.difference(currentList, patientCodeList);
    const addedCodes = _.intersection(this.addedCodes, currentList);
    this.addedCodes = addedCodes;
    return addedCodes;
  }
  _getAutoMarkedCodes = (currentBillableList) => {
    // make sure the auto marked codes still exists and checked
    const autoMarkedCodes = _.filter(this.autoMarkedCodes, c => {
      return _.findIndex(currentBillableList, { code: c.code }) > -1;
    });
    // update
    this.autoMarkedCodes = autoMarkedCodes;
    return autoMarkedCodes;
  }
  _getALLAutoMarkedCodes = (currentBillableList) => {
    // including with and without matching
    const allAutoMarkedCodes = this._getAutoMarkedCodes(currentBillableList);
    if (this.autoMarkedCodeWithoutMatch) {
      allAutoMarkedCodes.push(this.autoMarkedCodeWithoutMatch);
    }
    return allAutoMarkedCodes;
  }
  _sortCodesBasedOnCondition = (addedCodes) => {
    const codeToPriorityIndexMap = _.map(addedCodes, addedCode => {
      const code = _.split(addedCode, '::')[1];
      const index = this._mapCodeToConditionPriorityIndex(code);
      if (index !== -1) {
        return ({ addedCode, index });
      }
      return null;
    }).filter((c) => !!c);
    return _.orderBy(codeToPriorityIndexMap, c => c.index);
  }
  _mapCodeToConditionPriorityIndex = (code) => {
    for (let [condition, codesArray] of Object.entries(UPDATED_CONDITION_TO_ICD)) {
      const codesForCondition = getValidICDCodes(codesArray, [code]);
      if (codesForCondition.length > 0 && typeof CONDITION_PRIORITY_INDEX[condition] !== 'undefined') {
        return CONDITION_PRIORITY_INDEX[condition];
      }
    }
    return -1;
  }

  _isPatientEnrolled = () => _.get(this, 'enrolledProgram.status') === 'STARTED';
  _getEnrolledVitals = () => {
    const tasks = _.get(this, 'enrolledProgram.tasks') || [];
    const excludedTasks = ['EXERCISE'];
    return _.filter(tasks, t => !excludedTasks.includes(t.type)).map(t => t.type);
  }
  _getEnrolledVitalCount = () => this._getEnrolledVitals().length || 0;

  _isPatientReferred = () => _.get(this, 'patientReferral.status.referStatus') === STATUS_ENUM.COMPLETED;
  _getReferredConditions = () => _.get(this, 'patientReferral.conditionsToMonnitor') || [];
  _getReferredConditionMap = () => {
    const referredConditions = this._getReferredConditions();
    const mapObj = _.pick(CCM_CONDITION_TO_ICD, referredConditions);
    return _.flatMap(mapObj);
  }
  _getReferredHealthConditions = () => _.get(this, 'patientReferral.healthConditions') || [];
  _getReferredBillableHealthConditions = () => {
    const conditions = _.get(this, 'patientReferral.billableHealthConditions') || [];
    return conditions.map((c) => _.omit(c, '__typename'));
  }
  _getEligiblePrograms = () => _.get(this, 'patientReferral.eligiblePrgorams') || [];
  _getDiagnosisSource = () => _.get(this, 'patientReferral.diagnosisSource') || DIAGNOSIS_SOURCE.CONDITION;

  _getConsentSignedDate = () => _.get(this, 'consentInfo.signatureAt');

  _isCodeForEnrolledVital = (code) => {
    if (!this._isPatientEnrolled()) return;
    const enrolledVitals = this._getEnrolledVitals();
    let codeForVital = false;
    for (let vital of enrolledVitals) {
      const conditions = UPDATED_VITAL_TO_CONDITIONS[vital] || [];
      const codesArray = _.flatMap(_.map(conditions, c => UPDATED_CONDITION_TO_ICD[c]));
      const codesForConditions = getValidICDCodes(codesArray, [code]);
      if (!!codesForConditions[0]) {
        codeForVital = true;
        break;
      }
    }
    return codeForVital;
  }
  _isCodeForReferredConditions = (code) => {
    const map = this._getReferredConditionMap();
    const codes = getValidICDCodes(map, [code]);
    return codes.length === 1;
  }

  __getCodeBasedOnPriority = (currentList, currentBillableList) => {
    let addedCodes = this._getAddedCodes(currentList);
    // filter out ones that are auto-marked
    const autoMarkedCodes = this._getAutoMarkedCodes(currentBillableList);
    addedCodes = _.filter(addedCodes, c => {
      return _.findIndex(autoMarkedCodes, { code: getCodeName(c) }) === -1;
    });
    const sorted = this._sortCodesBasedOnCondition(addedCodes);
    const codeToAdd = _.get(sorted, '0.addedCode');
    if (!codeToAdd) return null;
    const [description, code] = _.split(codeToAdd, '::');
    return ({ code, description });
  }
  _handleCaseOneVitalWithReferral = (code, handleAddBillable) => {
    if (this._isCodeForEnrolledVital(code)) {
      handleAddBillable();
      return code;
    } 
    return null;
  }
  _handleCaseOneVitalWithoutReferral = (handleAddBillable, currentList, currentBillableList) => {
    if (!this.__checkIfValidForAdditionalBillableCode(currentList, currentBillableList)) return null;
    const billableCode = this.__getCodeBasedOnPriority(currentList, currentBillableList);
    if (billableCode) {
      handleAddBillable({ billableCode, withoutMatch: true });
    }
    return code;
  }
  _handleCaseMoreThanOneVitalWithReferral = (code, handleAddBillable, currentList, currentBillableList) => {
    if (this._isCodeForEnrolledVital(code)) {
      handleAddBillable();
      return code;
    }
    // adding additional
    if(!this.__checkIfValidForAdditionalBillableCode(currentList, currentBillableList)) return null;
    const billableCode = this.__getCodeBasedOnPriority(currentList, currentBillableList);
    if (billableCode) {
      handleAddBillable({ billableCode, withoutMatch: true });
    }
    return null;
  }
  _handleCaseMoreThanOneVitalWithoutReferral = (code, handleAddBillable) => {
    if (!this._isCodeForEnrolledVital(code)) return null;
    handleAddBillable();
    return code;
  }
  _handleCaseOrgHasRPMButCCM = (code, handleAddBillable) => {
    if (this._isCodeForEnrolledVital(code) || this._isCodeForReferredConditions(code)) {
      handleAddBillable();
      return code;
    }
    return null;
  }
  _handleAddCode = (code, handleAddBillable, currentList, currentBillableList) => {
    if (this._isCodeForEnrolledVital(code) || this._isCodeForReferredConditions(code)) {
      handleAddBillable();
      return code;
    } else if (this.__checkIfValidForAdditionalBillableCode(currentList, currentBillableList)) {
      const billableCode = this.__getCodeBasedOnPriority(currentList, currentBillableList);
      if (billableCode) {
        handleAddBillable({ billableCode, withoutMatch: true });
        const { code, description } = billableCode;
        return `${description}::${code}`;
      }
    }
    return null;
  }
  _handleAutoMarkMultipleCodes = (currentList) => {
    const billableCodes = []
    for (let value of currentList) {
      const [description, code] = _.split(value, '::');
      const billableCode = { code, description };
      if (this._isCodeForEnrolledVital(code) || this._isCodeForReferredConditions(code)) {
        billableCodes.push(billableCode);
      }
    }
    return billableCodes;
  }

  __checkIfValidForAdditionalBillableCode = (currentList, currentBillableList) => {
    const addedCodes = this._getAddedCodes(currentList);
    if (addedCodes.length < 1) return false;
    const billableListExludedWithoutMatch = _.filter(currentBillableList, (c) => {
      if (!this.autoMarkedCodeWithoutMatch) return true;
      return c.code !== this.autoMarkedCodeWithoutMatch.code;
    });
    // if (billableListExludedWithoutMatch.length > 1 && this.autoMarkedCodeWithoutMatch) {
    //   // valid to update the current additional auto-marked code if applicable
    //   return true;
    // }
    return billableListExludedWithoutMatch.length === 1;
  }
  _checkIfOnlyRPMReferred = () => {
    if (!this._checkIfOrgHasBothRPMAndCCM()) return false;
    const eligiblePrgorams = this._getEligiblePrograms();
    return eligiblePrgorams.length === 1 && eligiblePrgorams[0] === 'RPM';
  }
  _checkIfCCMEnrolled = () => {
    const programCategories = this._getPatientProgramCategories();
    const CCM = _.find(programCategories, { name: 'CCM' }) || {};
    return !!(CCM.enrolled && CCM.enrolledDate);
  }
  _checkIfOrgHasQualifiedPrograms = () => {
    return this.__checkIfOrgHasPrograms(this.qualifiedPrograms);
  }
  __checkIfOrgHasPrograms = (programs) => {
    return _.intersection(programs, this.orgProgramParticipation).length === programs.length;
  }
  _checkIfOrgHasBothRPMAndCCM = () => {
    const CCMRPM = ['CCM', 'RPM'];
    return this.__checkIfOrgHasPrograms(CCMRPM);
  }
  _checkIfOrgHasRPMButCCM = () => {
    return this.orgProgramParticipation.includes('RPM') && !this.orgProgramParticipation.includes('CCM');
  }
  _checkIfOrgHasNoRPMAndCCM = () => {
    return !this.orgProgramParticipation.includes('RPM') && !this.orgProgramParticipation.includes('CCM');
  }
  _checkIfMissingCodesFromReferral = (currentBillableList) => {
    const referredConditions = this._getReferredConditions();
    const codeCountForCondition = {};
    _.map(referredConditions, (k) => { // initialization
      codeCountForCondition[k] = 0;
    });
    const currentBillableCodes = _.map(currentBillableList, 'code');
    _.forEach(referredConditions, cond => {
      const codesArray = UPDATED_CONDITION_TO_ICD[cond] || [];
      const codesForCondition = getValidICDCodes(codesArray, currentBillableCodes);
      codeCountForCondition[cond] += codesForCondition.length
    })
    const conditionsMissingCode = _.map(codeCountForCondition, (v, k) => {
      if (v < 1) {
        return k;
      }
      return null;
    }).filter(v => v !== null);
    return conditionsMissingCode;
  }
  _checkIfQualifiedForRPM = (currentBillableList) => {
    const currentBillableCodes = _.map(currentBillableList, 'code');
    const codesArray = _.flatMap(RPM_CONDITION_TO_ICD);
    const codesForRPMConditions = getValidICDCodes(codesArray, currentBillableCodes);
    return codesForRPMConditions.length > 0;
  }
  _checkIfMissingCodesForVitals = (currentBillableList) => {
    const enrolledVitals = this._getEnrolledVitals();
    const codeCountForEnrolledVitals = {};
    const currentBillableCodes = _.map(currentBillableList, 'code');
    _.map(enrolledVitals, (vital) => {
      const codesArray = VITAL_TO_ICD[vital];
      if (!_.isEmpty(codesArray)) { // ignore vitals without mapping
        const codesForVital = getValidICDCodes(codesArray, currentBillableCodes);
        codeCountForEnrolledVitals[vital] = codesForVital.length;
      }
    });
    const vitalsMissingCode = _.map(codeCountForEnrolledVitals, (v, k) => {
      if (v < 1) {
        return k;
      }
      return null;
    }).filter(v => v !== null);
    return vitalsMissingCode;
  }

  _checkAndEnrollPrograms = (currentBillableList) => {
    if (this._isPatientEnrolled()) 
      return;
      
    const { 
      hasProgramChange = false, 
      programCategories 
    } = this.getValidPrograms(currentBillableList) || {};

    if (!hasProgramChange) return;
    return Client.mutate({
      mutation: editUser,
      variables: {
        id: this._getPatientId(),
        memberProfile: {
          birthday: this._getPatientBirthday(),
          programCategories: _.map(programCategories, p => _.omit(p, '__typename')),
        }
      },
      fetchPolicy: 'no-cache',
    });
  }

  _promptMissingCodes = (callback, billableHealthConditions) => {
    const mainPromptMessage = 'Billable ICD codes are missing for: ';
    const missingCodesFor = [];
    const vitalsMissingCode = this._checkIfMissingCodesForVitals(billableHealthConditions);
    if (vitalsMissingCode.length) {
      missingCodesFor.push(...vitalsMissingCode);
    }
    if (this._isPatientReferred()) {
      const conditionsMissingCode = this._checkIfMissingCodesFromReferral(billableHealthConditions);
      missingCodesFor.push(...conditionsMissingCode);
    }
    if (missingCodesFor.length > 0) {
      Modal.confirm({
        content: `${mainPromptMessage}${missingCodesFor.join(', ')}`,
        icon: null,
        onOk: () => callback(),
        okText: 'Continue to save',
      });
    } else {
      callback();
    }
  }

  // === public

  renderCode = (code) => {
    const map = this._getReferredConditionMap();
    const codes = getValidICDCodes(map, [code]);
    const isReferredCode = codes.length === 1;
    return (
      <div>
        <div>
          {code}
        </div>
        {
          isReferredCode &&
          <span style={{ marginRight: 3, color: 'red', fontSize: 11 }}>
            MD Referred
          </span>
        }
      </div>
    );
  }

  renderProgramChangePrompt = (form) => { // using error validator as prompt
    return (
      <Form.Item span={12} style={{ visibility: 'hidden', height: 1, padding: 0, margin: 0 }}>
        {
          form.getFieldDecorator('validateProgramChange', {
            initialValue: null,
            rules: [
              {
                validator: (rule, value, callback) => {
                  try {
                    const { billableHealthConditions } = form.getFieldsValue();
                    const hasOnlyRPMReferred = this._checkIfOnlyRPMReferred();
                    const isCCMEnrolled = this._checkIfCCMEnrolled();
                    const isQualifiedForRPM = this._checkIfQualifiedForRPM(billableHealthConditions);
                    const isQualifiedForCCM = isQualifiedForRPM && billableHealthConditions.length > 1;
                    const allAutoMarkedCodes = _.map(this._getALLAutoMarkedCodes(billableHealthConditions), 'code');
                    const beforeClosePrompt = async () => {
                      await this._checkAndEnrollPrograms(billableHealthConditions); 
                      this._promptMissingCodes(callback, billableHealthConditions);
                    };

                    if (
                      this.__checkIfOrgHasPrograms(['CCM']) 
                      && isQualifiedForCCM 
                      && hasOnlyRPMReferred 
                      && !isCCMEnrolled
                    ) {
                      const eligibleCodes = allAutoMarkedCodes.join(', ') || _.map(billableHealthConditions, 'code').join(', ');
                      Modal.confirm({
                        content: `This patient was only referred for RPM, but based on the ICD codes [${eligibleCodes}] they are now eligible and may be billed for CCM. Please inform the provider of this change, and uncheck the diagnosis if incorrect.`,
                        icon: null,
                        onOk: beforeClosePrompt,
                        okText: 'Continue to save',
                      });
                    } else {
                      beforeClosePrompt();
                    }                 
                  } catch (error) {
                    console.error(error);
                    callback();
                  }
                }
              }
            ]
          })(<Input style={{ display: 'none' }} />)
        }
      </Form.Item>
    );
  }

  getValidPrograms = (
    currentBillableList,
    startNew = false
  ) => {
    const consentSignedDate = this._getConsentSignedDate();
    if (!consentSignedDate || !currentBillableList.length) return {};
    const programs = ['RPM']; // already has length > 0
    if (currentBillableList.length > 1) programs.push('CCM');
    const programCategories = startNew ? [] : _.cloneDeep(this._getPatientProgramCategories());
    let hasProgramChange = false;
    _.forEach(programs, (program) => {
      const orgHasProgram = this.__checkIfOrgHasPrograms([program]);
      if (orgHasProgram) {
        const indexOfProgram = _.findIndex(programCategories, { name: program });
        const isProgramEnrolled = !!_.get(programCategories, `${indexOfProgram}.enrolled`);
        if (!isProgramEnrolled) {
          const programObj = {
            name: program,
            enrolled: true,
            enrolledDate: consentSignedDate
          };
          if (indexOfProgram === -1) programCategories.push(programObj);
          else programCategories.splice(indexOfProgram, 1, programObj);
          hasProgramChange = true;
        }
      }
    });
    return {
      hasProgramChange,
      programCategories
    };
  }

  checkAndAutoMarkBillable = (value, addBillableHandler, currentList, currentBillableList) => {
    // helper
    this.addedCodes.push(value);
    const [description, code] = _.split(value, '::');
    const toAddBillableCode = { code, description }; // translated from healthCondition
    const handleAddBillable = ({
      billableCode = toAddBillableCode,
      withoutMatch
    } = {}) => {
      const { code } = billableCode || {};
      if (!code) return;
      const cloneCurrentBillableList = _.cloneDeep(currentBillableList);
      const isCodeBillable = _.findIndex(cloneCurrentBillableList, { code }) > -1;
      if (!isCodeBillable && addBillableHandler) {
        //----internal processes
        // correct billable code without matching
        if (!!withoutMatch) {
          if (this.autoMarkedCodeWithoutMatch) {
            const oldCodeWithoutMatch = this.autoMarkedCodeWithoutMatch.code;
            const oldCodeWithoutMatchIdx = _.findIndex(cloneCurrentBillableList, { code: oldCodeWithoutMatch });
            if (oldCodeWithoutMatchIdx > -1) {
              // remove billable
              cloneCurrentBillableList.splice(oldCodeWithoutMatchIdx, 1);
            }
          }
          this.autoMarkedCodeWithoutMatch = billableCode;
        } else { // with matching
          this.autoMarkedCodes.push(billableCode);
        }
        //----
        cloneCurrentBillableList.push(billableCode);
        addBillableHandler(cloneCurrentBillableList);
      }
    };
    // ----

    // if (!this._isPatientEnrolled()) return null; // must be enrolled patient
    // if (this._checkIfOrgHasRPMButCCM()) {
    //   return this._handleCaseOrgHasRPMButCCM(code, handleAddBillable);
    // }
    // if (!this._checkIfOrgHasQualifiedPrograms()) return null; // must participate in all qualified programs

    // const enrolledVitalCount = this._getEnrolledVitalCount();
    // const isPatientReferred = this._isPatientReferred();
    // if (enrolledVitalCount > 1) {
    //   if (isPatientReferred) {
    //     return this._handleCaseMoreThanOneVitalWithReferral(code, handleAddBillable, currentList, currentBillableList);
    //   } else {
    //     return this._handleCaseMoreThanOneVitalWithoutReferral(code, handleAddBillable);
    //   }
    // }
    // if (enrolledVitalCount === 1) {
    //   if (isPatientReferred) {
    //     return this._handleCaseOneVitalWithReferral(code, handleAddBillable);
    //   } else {
    //     return this._handleCaseOneVitalWithoutReferral(handleAddBillable, currentList, currentBillableList);
    //   }
    // }

    // ---
    if(this._checkIfOrgHasNoRPMAndCCM()) return null;
    this._handleAddCode(code, handleAddBillable, currentList, currentBillableList);
  }

  handleAutoMarkBillableDuringEnrollment = () => {
    let currentList = this._getPatientHealthConditions();
    let currentBillableList = this._getPatientBillableHealthConditions();
    if(this._checkIfOrgHasNoRPMAndCCM()) return null;
    if (!currentList.length) return null;
    const patientReferredDiagnosisSource = this._getDiagnosisSource();
    if (patientReferredDiagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE) {
      // referred codes will override user's existing codes
      currentList = this._getReferredHealthConditions();
      currentBillableList = this._getReferredBillableHealthConditions();
    }
    const listForAutoMarkCheck = _.filter(currentList, (v) => {
      const code = _.split(v, '::')[1];
      return _.findIndex(currentBillableList, { code }) === -1;
    });
    const autoMarkedCodes = this._handleAutoMarkMultipleCodes(listForAutoMarkCheck);
    const billableCodes = _.uniqBy([...currentBillableList, ...autoMarkedCodes], 'code');
    return billableCodes;
  }
}
