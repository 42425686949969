import { IHLoading } from 'ihcomponent'
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import organizationListGQL from '../../organization/query/organizationList';
import PropTypes from 'prop-types';
import ProviderListContainer from './providerListContainer';
import get from 'lodash/get'
import { connect } from 'react-redux';
import { ORG_COUNT_MAX } from 'libModule/constants';

class Container extends Component {
    static displayName = 'superadmin/providers/container/listindex'

    render() {
        const { data, organizationLoading } = this.props;
        if (!organizationLoading) {
            //get all roles id from organization
            const organizationList = data && data.organizationList && data.organizationList.data ? data.organizationList.data : [];
            let rolesData = [];
            organizationList.forEach((org) => {
                if (org.roles) {
                    org.roles.forEach((role) => {
                        if (['Admin', 'Doctor', 'Nurse', 'Medical Assistant', 'Clinic Medical Assistant'].includes(role.name)) {
                            let r = rolesData.find(t => t == role.refId);
                            if (!r) {
                                rolesData.push(role.refId);
                            }
                        }
                    });
                }
            });

            return <ProviderListContainer rolesData={rolesData} {...this.props} />
        }
        else {
            return '';
        }
    }
}

const mapState = (state, ownProps) => {

    return {
        ...state.provider.ProviderListContainer,
        ...ownProps,
    }
}


const withGql = graphql(organizationListGQL, {
    options: (ownProps) => {
        const variables = {
            page: 1,
            count: ORG_COUNT_MAX
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ data }) => {
        return {
            data,
            organizationLoading: data.loading
        }
    }
})

const ContainerWithData = withGql(Container)

Container.propTypes = {
    data: PropTypes.object,
}

export default connect(
    mapState
)(ContainerWithData)
