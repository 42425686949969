const taskAssignment = {
  tab: {
    myTasks: {
      label: 'My Tasks',
      tip: 'Tasks assigned to you'
    },
    tracking: {
      label: 'Tracking Tasks',
      tip: 'Tasks assigned to others'
    },
    all: {
      label: 'All Tasks',
      tip: 'All tasks'
    }
  },
  createdAt: 'Date Assigned',
  createdBy: 'Creator',
  priorityLevel: {
    label: 'Priority',
    required: {
      message: 'Priority is required'
    }
  },
  dueDate: 'Due Date',
  reason: 'Reason',
  dueDateMsg: 'Due date is required',
  assignedToMsg: 'Assignee is required',
  assignedTo: 'Assignee',
  status: 'Status',
  patient: {
    name: 'Patient Name',
    addTask: 'Add task',
    viewTask: 'Click to view task(s) for patient'
  }
};

export default taskAssignment;