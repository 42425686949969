import { Task } from './Program.js';
import UserDeviceRecords from './UserDeviceRecords';
import { Phone, EducationSchema, PhoneReportInfos, ClinicGoalSchema, Identification } from './common';
import { SpecialistInfoSchema } from './SpecialistInfo';
import { SignOffStatusSchema } from "./SignOffStatus";
import { VisitRemoteEnrollmentSchema } from "./RemoteEnrollment";
import { NutritionSchema } from "./Nutrition";
import { HandoutSchema } from "./Handout";
import { UserPreferenceSchema } from './UserPreference.js';
import { PatientInsuranceProvider } from './InsuranceProvider.js';
import { CTAssigneeSchema } from './CTAssignee';

export const AssigneesSchema = `
  assignees {
    assignedToCA{
      providerId
      name
    }
    assignedToRD{
      providerId
      name
    }
    assignedToHC{
      providerId,name
    }
  }
`;

export default `
  id
  selfEvaluation {
      id
      memberId
      visitId
      evaluations{
        name
        yearsWithCondition
        comments
        seenDoctorInLastSixMonth
        otherTreatment
        specialistInfo{
            seenSpecialist
            contact
        }
        testRegularly
        treatments
        freq
        unit
      }
      areasOfImprovement
      diabetesAns
      bloodPressureAns
      CPODAns
      otherAreaAns
  }
  rescheduledToVisit{
    appointmentAt
    createdAt
  }
  createdAt
  confirm
  deviceRecordCount{
    ${UserDeviceRecords}
  }
  inventoryCount {
    ${UserDeviceRecords}
  }
  enrolledProgramStatus
  ${AssigneesSchema}
  ${SignOffStatusSchema}
  member{
    id
    enrollmentDate
    assignees{
      assignedToRD{
        id
        profile{
          fullName
        }
      }
      assignedToCA{
        id
        profile{
          fullName
        }
      }
      assignedToCANotNecessary
      assignedToRDNotNecessary
    }
    remoteEnrollment{
      ${VisitRemoteEnrollmentSchema}
    }
    phone {
      ${Phone}
    }
    identification{
      ${Identification}
    }  
    allRoles{
      refId
      category
    }  
    ${PhoneReportInfos}
    userPreference {
      ${UserPreferenceSchema}
    }
    patientMntProgram {
      id
      description
      referredBy {
        id
        profile {
          fullName
        }
      }
      referredAt
      ineligibledBy {
        id
        profile {
          fullName
        }
      }
      ineligibledAt
      status
      mntConditions
      visit {
        id
      }
    }
    patientMntCodes {
      code
      title
    }
    profile{
      fullName
      avatar {
        link
      }
      ...on MemberProfile{
        lastMeasuredByType {
          BP
          BG
          PO
          TM
          HR
          ECG
          HS
          EXERCISE
        }
        conditionsList {
          HLD
          HTN
          DM
          CKD
          Obesity
          PreDM
          COPD
          ESRD_Dialysis
        }
        lastMeasuredAt
        birthday
        isSelfEnrolled
        doctor {
          id
          profile {
            fullName
            firstName
            lastName
          }
        }
        race {
          code
          description
        }
        language {
          code
          description
        }
        nationality {
          code
          description
        }
        deviceRecords{
          ${UserDeviceRecords}
        }
        height
        weight
        gender
        appLanguage{
          code
          description
        }
        language{
          code
          description
        }
        latestWeight
        healthConditions
        mntHealthConditions{
          description
          code
        }
        billableHealthConditions{
          description
          code
        }
        insuranceProvider
        insuranceProvider2
        insuranceProviders {
          ${PatientInsuranceProvider}
        }
        motivation
        techLevel
        programCategories{
          name
          enrolled
          enrolledDate
        }
        emrList{
          EMR_Charting
          ChartA1C
          CCM
          pinnedToEMR
        }
        chartingNote
        enrollmentNote
        medicationManagement {
          reconcilationCompliance
          otherInfo
          extraNote
        }
        routine
        sleepingRoutine
        eatingRoutine
        sleepFreq
        lifestylePattern
        lifestyleNote
        activityLevel
        educationLevel
        foodAccessibility
        workDuties
        socialSupport{
          communitySupport
          contactInfo
          otherProviders
          extraNote
        }
        drinkingHistory{
          value
          startSinceYear
          drinkType
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        smokingHistory{
            value
          startSinceYear
          freq{
            value
            unit
          }
          amount
          quitYear
        }
        exerciseHabits{
          value
          freq{
            value
            unit
          }
          duration
        }
        occupation
        BGSpecialistInfo{
          ${SpecialistInfoSchema}
        }
        BPSpecialistInfo{
          ${SpecialistInfoSchema}
        }
        chronicDiseaseHistory{
          name
          yearsWithCondition
          comments
          takingMedication
          otherTreatment
          seenDoctor
          testRegularly
          freq
          unit
          treatments
          specialistInfo{
            ${SpecialistInfoSchema}
          }
          seenDoctor
          testRegularly
          freq
          unit
          treatments
          validationFlag
        }
        chronicDiseaseNote
        BGTreatments
        BPTreatments
        BGTest{
          testRegularly
          freq
          unit
        }
        BPTest{
          testRegularly
          freq
          unit
        }
        BGVisits
        BPVisits
        BGValidationFlag
        BPValidationFlag
        yearOfDiagnose {
          condition
          diagnosedDate
        }
        healthConditions
        billableHealthConditions{
          description
          code
        }
        ${NutritionSchema}
        ${HandoutSchema}
        ${EducationSchema}
      }
    }
    ${CTAssigneeSchema}
  }
  enrolledProgram{
    id
    adminProgram {
      id
      tasks {
        id
        type
        threshold {
          measure
          range
          baseline
          baselineUpdatedAt
          unit
          exerciseGoal{
            target
            unit
            timeUnit
          }
        }
      }
    }
    assessments
    goals
    tasks{
      ${Task}    
    }
    team {
      id
      consentProviderName
    }
    clinicGoals{
      ${ClinicGoalSchema}
    }
    behavioralGoals{
      type
      category
      value
    }
    extraNote
    assessmentsFlag
    goalsFlag
    status
  }
  vitalTypes
  category
  type
  reason
  appointmentAt
  appointmentTo
  checkinAt
  checkoutAt
  declineToCheckVital
  unableToCheckVital
  providers{
    id
    profile{
      firstName
      lastName
      fullName
    }
  }
  rdWorkingList{
    Medication
    GoalStatement
    Charting
    NutritionIntake
    NutritionInterpretation
    ChronicDisease
    LifestyleRoutine
    SocialSupport
    EnrollmentStatus
    Education
    MntReferral
  }
  maWorkingList{
    onBoard
    LabResults
    VisitBilling
    FollowUpVisit
  }
  conditionsList{
    HLD
    HTN
    DM
    CKD
    Obesity
    PreDM
    COPD
    ESRD_Dialysis
  }
  associatedVisit{
    id
    type
    ${AssigneesSchema}
    visitRoles
    providers{
      id
      profile{
        fullName
      }
    }
    appointmentAt
    reason
    category
    labResult{
      id
      results{
        value
        id
      }
      dateCollected
    }
  }
  tasks{
    key
    value
  }  
  labResult{
    createdAt
    id
    results{
      value
      id
    }
    dateCollected
  }
  shouldSeeDoctor
  visitRoles
  details{
    doctorSession
    cdeSession
    autoUpdateStatus
    addToContactsStatus
    technicalSetup
    eyeExam
    height
    weight
    waistHipRatio
    bgMeasurement{
      blood_glucose
      beforeMeal
      mealType
    }
    EMRAttached
    bpMeasurement{
      systolic_blood_pressure
      diastolic_blood_pressure
      heart_rate
    }
    temperature
    spo2
    appIsUptoDate
  }
  labTests{
    id
    createdAt
    updatedAt
    dateCollected
    templateId
    templateName
    results{
      name
      value
      unit
      isInHouse
    }
  }
  workingListStatus
  patientRefer {
    referredBy {
      id
      allRoles {
        refId
        name
        organization {
          id
        }
      }
    }
    clinicGoals {
      conditionEnum
      goalEnum
    }
    status {
      referStatus
      enrollStatus
      screeningStatus
      patientConsentEnroll
      confirmDevicesEnroll
      scheduleInitVisitEnroll
    }
    deviceShip
    devicePickup
  }
`;
