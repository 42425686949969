import React from 'react';
import {Checkbox, Row, Col, Button,message} from 'antd';
import {setCoveredOrgs, setData, reports, pinnedPatients, setSelectedOrgs} from '../API/index';

const TARGET_ENUM = {
  WORKSTATION: 'WORKSTATION',
  SIGNOFF: 'SIGNOFF',
  REMOTE_ENROLLMENT: 'REMOTE_ENROLLMENT',
  CROSS_CLINIC_PATIENTS:'CROSS_CLINIC_PATIENTS'
};

const SelectClinicComponent  = class extends React.Component{
        constructor(props) {
            super(props);
            const { selectedClinic=[],isDefault } = props;
            const organizationIds = _.map(selectedClinic,org=>org.organizationId);
            this.state = {
                organizations: organizationIds,
                isDefault: isDefault,
                selectedOrgsLen: selectedClinic.length
            }
        }

        setOrganizations = (organizations)=>{
            this.setState({
                organizations
            })
        }

        clinicSel = ()=>{
            const { organizations } = this.state;
            const { clinicCandidates } = this.props;
            const options = _.map(clinicCandidates, org => {
                const { organizationId, name,internalName } = org
                return { value: organizationId, label: internalName.trim().length ? internalName : name }
            });
            return <Checkbox.Group options={options} onChange={ this.setOrganizations } value={organizations} className={'selectOrg'}/>

        }

        renderSubheader = ()=>{
          const { target = TARGET_ENUM.WORKSTATION } = this.props;
          const SUBHEADER_TEXT_MAP = {
            [TARGET_ENUM.WORKSTATION]: 'Please select the clinic(s) that you cover for online work today',
            [TARGET_ENUM.SIGNOFF]: 'Please select the clinic(s) for which you will sign off Health Coaches’ (HC) work.',
            [TARGET_ENUM.REMOTE_ENROLLMENT]:'Please select clinic(s)',
            [TARGET_ENUM.CROSS_CLINIC_PATIENTS]:'Select clinics(s)'
          };
          return <span>{SUBHEADER_TEXT_MAP[target]}</span>;
        }

        // handleBtnForWorkstation = () => {
        //   const { organizations,isDefault } = this.state;
        //   const { setStarted,refetchCoveredOrg,setShowSelectClinic,setLoading,setReports,setPinnedPatients } = this.props;
        //   const memberId = sessionStorage.getItem('currentUserId')
        //   setCoveredOrgs({ organizations,memberId,isDefault}).then(res=>{
        //       message.success('Organizations selected!');
        //       if(refetchCoveredOrg){
        //           refetchCoveredOrg().then(res=>setStarted(true));
        //       }
        //       if(setShowSelectClinic) {
        //           setShowSelectClinic()
        //       }
        //       if(setLoading&&setPinnedPatients) {
        //           setData(pinnedPatients(),(res)=>setPinnedPatients(res.data,()=>setLoading('loadingPinnedPatients',false)),setLoading,'loadingPinnedPatients')
        //       }
        //       if(setLoading&&setReports) {
        //           setData(reports(),(res)=>setReports(res.data,()=>setLoading('loadingReports',false)),setLoading,'loadingReports')
        //       }
        //
        //   });
        // }

        // handleBtnForSignoff = () => {
        //   const { setShowSelectClinic } = this.props;
        //   // TODO: add mutation
        //   message.success('Organizations selected!');
        //   if(setShowSelectClinic)
        //     setShowSelectClinic();
        // }

        handleBtn = ()=>{
            const { organizations } = this.state;
            const { setShowCoveredClinicsModal,refetchSelectedOrgs,refetchRemoteEnrollmentSummary, setSelectedOrgsMutation=setSelectedOrgs} = this.props;
            try {
                setSelectedOrgsMutation({organizations}).then(res=>{
                    if (typeof setShowCoveredClinicsModal==='function') {
                        setShowCoveredClinicsModal(false);
                    }
                    if (typeof refetchSelectedOrgs==='function') {
                        refetchSelectedOrgs();
                    }
                    if (typeof refetchRemoteEnrollmentSummary==='function') {
                        refetchRemoteEnrollmentSummary();
                    }
                    message.success('Organizations selected!');
                });
                // setShowCoveredClinicsModal(false);
                // refetchSelectedOrgs();
               // message.success('Organizations selected!');
            }catch (e) {
                console.log(e);
            }

            // const { target = TARGET_ENUM.WORKSTATION } = this.props;
          // if(target === TARGET_ENUM.WORKSTATION)
          //   return this.handleBtnForWorkstation();
          // else if(target === TARGET_ENUM.SIGNOFF)
          //   return this.handleBtnForSignoff();
          // else
          //   return console.warn('warn SelectClinicComponent: Unknown target');
        }

        renderButton = ()=>{
            const { organizations } = this.state;
            const disabled = organizations.length==0;
            return <Button onClick={()=>this.handleBtn()} disabled={disabled} type={'primary'}>Confirm</Button>

        }

        renderCheckbox =()=>{
            const { isDefault } = this.state;
            const { target } = this.props;
            if(target === TARGET_ENUM.SIGNOFF)
              return null;

            return <Checkbox checked={isDefault} onChange={(e)=>this.setState({isDefault:e.target.checked})}>My covered clinic(s) for online work doesn’t change</Checkbox>
        }

        renderSelAll = ()=> {
            const { organizations } = this.state;
            const { clinicCandidates } = this.props;
            const allOrgIds =  _.map(clinicCandidates, ({ organizationId }) => organizationId);
            const checkedAll = allOrgIds.length == organizations.length;
            const onChange = ()=>{
                if( checkedAll ) {
                    this.setOrganizations([]);
                }else{
                    this.setOrganizations(allOrgIds);
                }
            }
            return <Checkbox checked={checkedAll} onChange={onChange}>Select All</Checkbox>
        }
        renderSelectedCount = ()=>{
            const { organizations } = this.state;
            const count = organizations.length;
            return <span style={{ float:'right',color: '#757980' }}>{count} selected</span>
        }

        getSubheaderClassName = () => {
          const { target } = this.props;
          let className = 'subheaderWrapper';
          if(target === TARGET_ENUM.SIGNOFF)
            className += ' signoff';
          return className;
        }

        render() {
            return <Row className={'selClinicWrapper'}>
                     <Col className={this.getSubheaderClassName()}>{ this.renderSubheader() }</Col>
                     <Col span={24} style={{ width: 550 }}>{this.renderSelectedCount()}</Col>
                     <Col span={24} style={{ padding: 25,width: 550,background: 'white',border: 'solid 1px #E7EAED', borderBottom: 'unset',marginTop: 20 }}>
                         { this.renderSelAll() }
                     </Col>
                     <Col span={24} >{this.clinicSel() }</Col>
                     <Col span={24} style={{textAlign:'center',width:550 }}>{ this.renderButton() }</Col>
                   </Row>
        }

}
export default SelectClinicComponent;
