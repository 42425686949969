import React from 'react';
import { Table, Checkbox, Modal, message } from 'antd';
import { publishReport, downloadReport } from '../helpers';
import moment from 'moment';
import adminQueries from '../../newAdmin/query';
import helper from '../../newAdmin/helper';
import Client from 'libModule/gqlClient';

const orderMap = {
  ascend:'ASC',
  desc:'DESC'
}
class ReportsTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      publishInfo: {},
      allChecked: false,
      clinicFilters: [],
    }
  }

  get isBiller() {
    return this.props.userRole === 'BILLER';
  }

  handleLoadClinics = async () => {
    const res = await Client.query({
      query: adminQueries.getChildrenOrgsQuery,
      variables: {
        rootId: helper.getViewerOrgId(),
        leaf: true,
      },
      fetchPolicy: 'network-only',
    });
    const clinics = _.get(res, 'data.getChildrenClinics') || [];
    this.setState({ 
      clinicFilters: _.map(clinics, (c) => ({
        text: c.name,
        value: atob(c.id).split(':')[1],
      })),
    });
  };

  componentDidMount() {
    const { page,pageSize, sortDirection, filters } = this.props;
    this.getData({
      page, pageSize, sortDirection, filters
    });
    this.handleLoadClinics();
  };

  componentDidUpdate(prevProps) {
    const prePage = _.get(prevProps,'page',1);
    const prePageSize = _.get(prevProps,'pageSize',10);
    const preSortDirection = _.get(prevProps,'sortDirection','DESC');
    const preFilters = _.get(prevProps,'filters');
    const { page,pageSize, sortDirection, filters } = this.props;
    if (
      prePage!== page 
      || prePageSize!== pageSize 
      || preSortDirection !== sortDirection
      || preFilters !== filters
    ) {
      this.getData({
        page, pageSize, sortDirection, filters
      })
    }
  }

  getInitialPublishData = (data = []) => {
    return data.reduce((result, curr) => {
      result[curr.id] = { published: curr.published, wantToPublish: false };
      return result;
    }, {});
  }

  isAllPublished = (data) => {
    return _.find(data, v => !v.published) === undefined;
  }

  getData = async (args) => {
    this.setState({ loading: true });
    try {
      const { data,total } = await this.props.getReports(args);
      this.setState({ data, loading: false, publishInfo: this.getInitialPublishData(data), isAllPublished: this.isAllPublished(data),total });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      this.props.openErrorModal(err.message || 'Something went wrong fetching data!');
    }
  }

  wantToPublishCount = () => {
    const { publishInfo } = this.state;
    return _.reduce(publishInfo, (res, v) => (!v.published && v.wantToPublish ? (res + 1) : res), 0);
  }

  handlePublishCheckbox = (id) => (evt) => {
    const checked = evt.target.checked;
    const { publishInfo } = this.state;
    
    publishInfo[id].wantToPublish = checked;
    this.setState({ publishInfo });
  }

  handleAllCheckboxes = (evt) => {
    const checked = evt.target.checked;
    const { publishInfo } = this.state;
    
    _.forEach(publishInfo, (v, k) => { publishInfo[k].wantToPublish = checked });
    this.setState({ publishInfo, allChecked: checked });
  }

  publishSelected = async () => {
    const { publishInfo } = this.state;
    const ids = _.map(publishInfo, (v, k) => (!v.published && v.wantToPublish ? k : null)).filter(v => v !== null && v !== undefined);
    this.publish(ids);
  }

  handleSinglePublish = (id) => {
    Modal.confirm({
      content: 'You are about to approve this report and publish it to the provider\'s team. Please confirm this action.',
      okText: 'Yes, Approve',
      cancelText: 'Cancel',
      className: 'deleteMedModule',
      onOk: () => {
        this.publish([id]);
        this.setState({ allChecked: false });
      },
      icon: null,
    });
  }

  handlePublishSelected = () => {
    Modal.confirm({
      content: 'You are about to approve all selected reports and publish these'
        + ' selected reports to the provider\'s team. Please confirm this action.',
      okText: 'Yes, Approve',
      cancelText: 'Cancel',
      className: 'deleteMedModule',
      onOk: () => {
        this.publishSelected();
      },
      icon: null,
    });
  }

  publish = async (ids) => {
    const { getCounts } = this.props;
    try {
      await publishReport(ids);
      await getCounts();
      message.success('This report has been approved and published to the provider’s team');
      const { page,pageSize, sortDirection } = this.props;
      this.getData({
        page,pageSize,sortDirection
      });    }
    catch (err) {
      this.props.openErrorModal(err.message || 'Something went wrong updating data!');
    }
  }

  handleDownload = (id, fileKey) => async () => {
    const filenameSplit = fileKey.split('/');
    const filename = _.last(filenameSplit);
    try {
      const res = await downloadReport(id);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
      this.props.openErrorModal(e.message || 'Something went wrong updating data!');
    }
  }

  getColumn = () => {
    const { publishInfo = {}, allChecked, isAllPublished, clinicFilters } = this.state;
    const columns = [];
    if (this.isBiller) {
      columns.push(
        {
          title: isAllPublished ? '' : <Checkbox onChange={this.handleAllCheckboxes} checked={allChecked} />,
          dataIndex: 'published',
          key: 'published',
          render: (data, record) => ( 
            _.get(publishInfo[record.id], 'published', false)
              ? null
              : <Checkbox checked={_.get(publishInfo[record.id], 'wantToPublish', false)} onChange={this.handlePublishCheckbox(record.id)} />
          ),
        }
      );
    }
    columns.push(
      {
        title: 'Report',
        dataIndex: 'monthOfYear',
        key: 'monthOfYear',
        width: '50%',
        render: (data, record) => 
          moment(data, 'YYYYMM').format('MMMM YYYY')
          + ` ${this.props.type} Report - ${_.get(record, 'orgName', '')} - ${_.get(record, 'teamName', '')}`,
        filters: clinicFilters,
      }
    );
    if (this.isBiller) {
      columns.push(
        {
          title: 'Date Uploaded',
          dataIndex: 'uploadedAt',
          key: 'uploadedAt',
          render: data => moment(data).format('MM/DD/YYYY'),
          sorter:true
        }
      );
    } else {
      columns.push(
        {
          title: 'Date Uploaded',
          dataIndex: 'publishedAt',
          key: 'publishedAt',
          render: data => moment(data).format('MM/DD/YYYY'),
          // sorter: true
        }
      );
    }
    if (this.isBiller) {
      columns.push(
        {
          title: 'Status',
          dataIndex: 'published',
          key: 'status',
          render: (data, record) => data 
            ? <div style={{ color: '#bad6a0' }}>Approved ({moment(record.publishedAt).format('MM/DD/YYYY')})</div> 
            : <a style={{ color: '#f6bc6b', textDecoration: 'underline' }} onClick={() => this.handleSinglePublish(record.id)}>Pending Approval</a>,
          filters: [
            { text: 'Approved', value: 'PUBLISHED' },
            { text: 'Under review', value: 'PENDING' },
          ],
        }
      );
    }
    columns.push(
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => <a style={{ textDecoration: 'underline' }} onClick={this.handleDownload(id, record.fileKey)}>Download</a>,
      }
    );
    return columns;
  }

  render() {
    const { loading, data,total } = this.state;
    const publishCount = this.wantToPublishCount();
    const { setFetchArgs,pageSize } = this.props;
    return (
      <div>
        <Table 
          loading={loading}
          title={() => (
            publishCount > 0
              ? <div style={{ marginLeft: 10 }}>
                {publishCount} reports selected
                <a style={{ color: '#bad6a0', marginLeft: 15, textDecoration: 'underline' }} onClick={this.handlePublishSelected}>
                  Approve selected reports
                </a>
              </div>
              : undefined
          )}
          columns={this.getColumn()}
          pagination={{ pageSize,total,hideOnSinglePage:true }}
          onChange={(p,f,s)=>{
            const { pageSize,current } = p;
            setFetchArgs({
              page:current,
              pageSize,
              sortDirection:orderMap[s.order] || 'DESC',
              filters: f,
            })
          }}
          dataSource={data && data.length > 0 ? data : null}
          rowKey={(record) => record.id}
        />
      </div>
    );
  }
}

export default ReportsTableContainer;