
import React, { Component } from 'react';

const FoodlogImage = class extends Component{
    render(){
        const { props } = this;
        const { foodLog,setFoodLog } = props;
        const firstImg = _.get(foodLog,'images.0.link','/image/default_image.png');

        return  <img src={firstImg} alt="Upload file" style={{height:'160px'}} onClick={()=>setFoodLog(foodLog)}/>
    }
}
export default FoodlogImage;
