import gql from 'graphql-tag';

export default gql`
  query getPESProblems($category: PESCategory) {
    getPesTemplatesByCategory(category: $category) {
      id
      category
      value
    }
  }
`;

export const getESOptions = gql`
  # loop depth of 3
  fragment ESOptions on PESStatementTemplate {
    ...BasicESInfo
    options {
      ...BasicESInfo
      options {
        ...BasicESInfo
        options {
          ...BasicESInfo
        }
      }
    }
  }
  fragment BasicESInfo on PESStatementTemplate {
    id
    category
    value
  }
  query getESOptions($id: EID) {
    getPesTemplate(id: $id) {
      ...ESOptions
    }
  }
`;