import gql from 'graphql-tag'
import { EnrolledProgram, EnrolledProgramForPatient } from './schema/EnrolledProgram'
import { PaginationInfo, ClinicGoalSchema } from './schema/common'
import { UserMiniForVendor } from './schema/User'
import {Task} from './schema/Program'
import { EP_COUNT_MAX } from '../../lib/constants'

export default gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data{
        ${EnrolledProgram}
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`

export const enrolledProgramListForPatient = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data{
        ${EnrolledProgramForPatient}
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`

export const getUserEnrolledProgramList = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        startDate
        name
        endDate
        status
        tasks {
          id
          type
          threshold {
            measure
            range
          }
        }
      }
    }
  }
`

export const enrolledProgramListMinimal = gql`
  query enrolledProgramList(
    $filters: EnrolledListOpts
    $count: Int = ${EP_COUNT_MAX}
  ){
    enrolledProgramList(
      count: $count,
      page: 1,
      filters: $filters
    ){
      data{
        id
        adminProgram {
          id
          name
        }
        status
      }
    }
  }
`;

export const miniEnrolledProgramListForPatient = gql`
  query enrolledProgramList(
    $filters: EnrolledListOpts
    $count: Int = ${EP_COUNT_MAX}
  ){
    enrolledProgramList(
      count: $count,
      page: 1,
      filters: $filters
    ){
      data{
        id
        status
      }
    }
  }
`;


export const enrolledProgramListForPatientByVendor = gql`
  query enrolledProgramListForPatientByVendor(
    $NRIC : String!,
  ){
    enrolledProgramByPatientNRIC(
      NRIC : $NRIC,

    ){
      data{
        id
        name
        status
        startDate
        endDate
        user{
          ${UserMiniForVendor}
        }
        devices{
          address
          model
          type
          status
        }
        forms{
          type
          uri
          date
        }
        organization {
          name
          id
        }
        qrcode
        status
      }
    }
  }
`

export const lightEnrolledProgramList = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        name
        team{
          id
          consentProviderName
        }
        status
        startDate
        adminProgram {
          name
          id
          tasks {
            id
            type
            threshold {
              measure
              range
              baseline
              baselineUpdatedAt
              unit
              exerciseGoal{
                target
                unit
                timeUnit
              }
            }
          }
        }
        tasks {
          ${Task}
        }
        dischargedDetail {
          key
          description
          dischargedBy{
            profile{
              fullName
            }
          }
          dischargedAt
          note
        }
      }
      pageInfo {
        ${PaginationInfo}
      }
    }
  }
`;

export const lightEnrolledProgramListForPatient = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        name
        team{
          id
          consentProviderName
        }
        status
        startDate
        adminProgram {
          name
          id
          tasks {
            id
            type
            threshold {
              measure
              range
              baseline
              baselineUpdatedAt
              unit
              exerciseGoal{
                target
                unit
                timeUnit
              }
            }
          }
        }
        tasks {
          ${Task}
        }
        dischargedDetail {
          key
          description
          dischargedBy{
            profile{
              fullName
            }
          }
          dischargedAt
          note
        }
      }
    }
  }
`

export const enrolledProgramListForWorkstation = gql`
  query enrolledProgramList(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        team{
            id
        }
        status
        startDate
        clinicGoals {
          ${ClinicGoalSchema}
        }
        tasks {
          id
          type
        }
      }
    }
  }
`;

export const enrolledProgramAndConsent = gql`
  query enrolledProgramListAndConsent(
    $page : Int,
    $count : Int,
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts,
    $memberId: EID!,
  ){
    enrolledProgramList(
      page : $page,
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        status
      }
    }
    getPatientConsent(memberId: $memberId) {
      id
      memberId
      consent
      consentType
    }
  }
`;
