// member
const pathStartsWith = [
  '/create_assessment_filled',
  '/update_assessment_filled',
  '/create_survey_filled',
  '/update_survey_filled',
]
const pathOnly = [
  '/assessments_surveys',
  '/mytasks',
  '/myvitals',
  '/emergency_contact',
  '/myprogrammes',
]

export default {
  pathStartsWith,
  pathOnly
}
