import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AlertFormContainer from 'ProgramIncompleteModule/AssessmentAlert/containers/AlertFormContainer'
import AlertListContainer from 'ProgramIncompleteModule/AssessmentAlert/containers/AlertListContainer'

// container
class Container extends Component {
  static displayName = 'program/ProgramIncomplete/AssessmentAlert/containers/CommonContainer'

  render() {
    return <AlertListContainer />
  }
}

// mapState
const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramIncomplete.Careplan.Assessment.Alert.Common
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {

  }
}


// PropTypes validation
Container.propTypes = {
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Container)
