import { React, _, message, IHLoading } from 'ihcomponent';
import { withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { getRouterParam, GQLHelper } from 'libModule/utils';
import { browserHistory } from 'react-router';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import EditComponent from '../components/EditComponent';
import action from 'modulesAll/assessment/edit/actions';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { validateAndReturnFields, getGraphAssessment } from 'modulesAll/assessment/util';
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'

// graphql
import getAssessment from 'modulesAll/graphql/assessment';
import editAssessment from 'modulesAll/graphql/editAssessment';
import publishAssessment from 'modulesAll/graphql/publishAssessment';

class component extends React.Component {
  static displayName = 'survey/edit/containers/EditContainer'

  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
  }

  async componentDidMount() {
    const { client, surveyId, setBreadCrumb } = this.props;

    const res = await client.query({
      query: getAssessment,
      variables: { id: surveyId },
      fetchPolicy: 'network-only',
    });

    setBreadCrumb(`Survey / ${_.get(res, 'data.assessment.name')} / Edit`)
    this.setState({ assessment: _.get(res, 'data.assessment') });
  }

  async save({ btn }) {
    const { client, fields, saveAssessment, setSubmitting, surveyId } = this.props;

    if (validateAndReturnFields(fields).__error) {
      return saveAssessment();
    }

    const variables = {
      ...getGraphAssessment(fields, 'SURVEY'),
      id: surveyId,
      nameKey: this.state.assessment.nameKey,

      // Publish at the same time
      publish: true
    }

    try {
      setSubmitting(true);
      if (btn) btn.loading(true)

      const res = await client.mutate({
        mutation: editAssessment,
        variables
      });

      createAuditLog({
        action:I18N.get('auditLog.admin.survey.edit'),
        details: { ...getGraphAssessment(fields, 'SURVEY') },
        request: variables,
        response: res
      })

      // await client.mutate({
      //   mutation: publishAssessment,
      //   variables: {
      //     // id: newAssessment.data.editAssessment._id,
      //     nameKey: newAssessment.data.editAssessment.nameKey,
      //   },
      // });

      message.success('Survey Saved !');
      browserHistory.push('/surveys');
    } catch (e) {
      this.props.openErrorModal(GQLHelper.formatError(e));

      createAuditLog({
        action:I18N.get('auditLog.admin.survey.edit'),
        details: { ...getGraphAssessment(fields, 'SURVEY') },
        request: variables,
        response: e,
        success: false })
    } finally {
      setSubmitting(false);
      if (btn) btn.loading(false)
    }
  }

  render() {
    if (!_.get(this, 'state.assessment')) {
      return <IHLoading />;
    }

    return <EditComponent assessment={this.state.assessment} {...this.props} onSave={this.save} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  fields: state.Assessment.edit.fields,
  surveyId: getRouterParam(state).surveyId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSubmitting: isSubmitting => dispatch(action.setSubmitting(isSubmitting)),
  saveAssessment: () => dispatch(action.saveAssessment()),
  loadAssessment: assessment => dispatch(action.loadAssessment(assessment)),
  onCancel: () => browserHistory.push('/surveys'),
  openErrorModal: errorMessage => dispatch(openErrorModal(errorMessage)),
  setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
});

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
)(component);
