/**
 * Created by zizhangai on 2/14/17.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addNewNote } from '../actions/InterventionsActions'
import InputForInvalidation from './InputForNoteInvalidationContainer'
import Note from '../components/NoteComponent'

class SingleNoteModal extends Component {
  static displayName = 'SingleNoteContainer'

  render() {
    const { note, stateNotes, addNewNote, row, user,closeModal,closeAfterDelete, onInteraction } = this.props
    const { id } = this.props.note
    return (
      <div className="intervention-notes-form">
        <div className="intervention-notes-form-header">{/* (* will be sent to NEHR) */}</div>
        <Note note={note} row={row? row:user} onClick={() => addNewNote(id)} closeModal={closeModal} closeAfterDelete={closeAfterDelete} onInteraction={onInteraction}>
          {(stateNotes[id]) && <InputForInvalidation id={id} row={row? row:user} note={note} closeAfterDeprecation={true} />}
        </Note>
      </div>
    )
  }

}

const mapState = ({ Interventions }) => {
  const { notes } = Interventions
  return { stateNotes: notes || {} }
}

export default connect(mapState, { addNewNote })(SingleNoteModal)
