import {
    React, BaseComponent, ND, _, wrappedMapPropsToFields
} from './util'
import PropTypes from 'prop-types';

import IHFormItem from './IHFormItem'

const Component  = class extends BaseComponent{

  static displayName = 'IHForm';

  init(){
  }

  getEachFormItem(item, key){
    const { getFieldDecorator, getFieldError, isFieldValidating } = this.props.form

    let itemProp = {}
    const arr = [
      'label', 'labelCol', 'wrapperCol', 'help', 'validateStatus', 'hasFeedback', 'option',
      'extra'
    ]
    _.each(arr, (key)=>{
      if(!_.isUndefined(item[key])){
        itemProp[key] = item[key]
      }
    })
    const FieldFn = getFieldDecorator(key, {
      initialValue : item.initialValue,
      rules : item.rules || []
    })

    const jsxTag = this.getJsxTag(item)
    const className = item.className ? 'IH-FormItem ' + item.className : 'IH-FormItem'
    const span = _.get(item.col, 'span')
    const offset = _.get(item.col, 'offset')

    return (
      <ND.Col span={span} offset={offset} key={item.key+'_col'}>
        <ND.Form.Item key={item.key} className={className} {...itemProp}>
          {FieldFn(jsxTag)}
          {item.children || null}
        </ND.Form.Item>
      </ND.Col>
    )
  }
  getJsxTag(item){
    //TODO
    return <IHFormItem {...item} />
  }

  render(){
    let formProp = {
      style : this.props.style || {}
    }
    if(this.props.horizontal){
      formProp.horizontal = "true"
    }
    else if(this.props.vertical){
      formProp.vertical = "true"
    }
    else if(this.props.inline){
      formProp.inline = "true"
    }
    const className = this.props.className ? `IH-Form ${this.props.className}` : 'IH-Form'
    return (
      <ND.Form className={className} {...formProp}>
        {
          _.map(this.props.itemList, (row, i)=>{
            return (
              <ND.Row gutter={10} key={i}>
                {_.map(row, (item)=>{
                  return this.getEachFormItem(item, item.key)
                })}
              </ND.Row>
            )
          })
        }
        {this.props.children || null}
      </ND.Form>
    )
  }
}

Component.propTypes = {
  itemList : PropTypes.array.isRequired
}

const Form = class extends BaseComponent{
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps);
  }

  render(){
    if (!this.Demo || this.props.isNewForm) {
      const { onFieldsChange, mapPropsToFields } = this.props
      const reduxOptions = {}
      if (onFieldsChange) {
        reduxOptions.onFieldsChange = onFieldsChange
      }
      if (mapPropsToFields) {
        reduxOptions.mapPropsToFields = wrappedMapPropsToFields(mapPropsToFields)
      }
      this.Demo = ND.Form.create(reduxOptions)(Component);
    }
    return <this.Demo wrappedComponentRef={refNode => { this._form = refNode }} {...this.props} />
  }

  getValue(){
    return this._form.props.form.getFieldsValue()
  }

  // will validate
  getFormData(callback){
    this._form.props.form.validateFieldsAndScroll((error, values)=>{
      if(error){
        callback(false)
        return
      }
      callback(true, values)
    })
  }

  setFormData(data){
    if(data && this._form){
      this._form.props.form.setFieldsValue(data)
    }
  }
//
//   componentDidMount(){
//     if(this.props.initData){
//       _.delay(()=>{
//         this.setFormData(this.props.initData)
//       }, 10)
//     }
//   }
 }

export default Form
