import Client from '../../../lib/gqlClient';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import { removeTaskFromEnrolledProgram } from 'graphqlModule/mutation/removeTaskFromEnrolledProgram';
import { generateScheduleForDB,addTemplateDetails,convertDBToSchedule, getDefaultThreshold }from 'modulesAll/utils/helpers/task.js';
import weeklyBGScheduleTemplateManyGQL from '../../../../../package/IHComponent/ShareCare/gql/weeklyBGScheduleTemplateMany';
import {removeTypename} from "../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI";
import { VITAL_TYPE_MAP_FROM_SHORT,VITAL_TYPE_MAP, DEFAULT_VITAL_SCHEDULE } from '../../utils/constants/task';

const defaultBGTemplate = 'New to Monitoring - 2 Bedtime paired and 2 mealtime paired';

const bgFrequency = 'weeklyMeal';

export default class VitalsClass {
  constructor(props) {
    const { program } = props;
    if(program && !program.status) {
      console.warn('Need program.status');
    }
    this.validProgram = program && program.status === 'STARTED';
    if(!this.validProgram) {
      return console.warn(`Need a STARTED program`);
    }

    this.programId = program.id;
    this.currentTasks = program.tasks || [];
    this.currentVitals = _.map(program.tasks,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]);
    this.adminProgram = _.get(program, 'adminProgram') || {};

    this.vitalTemplates = {} ;
    _.forEach(this.currentTasks, task =>{
      const { repeat,schedule,type,templateDetails } = task;
      const target = _.get(schedule,'0.target');
      this.vitalTemplates[VITAL_TYPE_MAP_FROM_SHORT[type]] = {
        templateDetails,
        schedule,
        type,
        target,
        repeat,
        frequencyValue:{
          times:target,
          frequency:repeat
        }
      };
    });
    const BG = _.first(_.filter(this.currentTasks,(t)=>t.type=='BG'))||{};
    this.BGSchedule = !_.isEmpty(BG) ? convertDBToSchedule(BG) : {};
  }

  getToAddVitals = async (vitalList = []) => {
    let toAddVitals = _.difference(vitalList, this.currentVitals);

    const newVitalTemplates = {};
    _.forEach(toAddVitals, v=>{
      const defaults = DEFAULT_VITAL_SCHEDULE[v] || {};
      const { times = 4, unit = 'DAILY' } = defaults;
      newVitalTemplates[v] = {
        type:v,
        templateDetails:{ },
        repeat: times,
        target: times,
        frequencyValue:{
          times: times,
          frequency: unit
        }
      }
    });
    if(_.includes(toAddVitals,'Blood Glucose')) {
      try {
        const res = await Client.query({
          query: weeklyBGScheduleTemplateManyGQL,
          variables: {}
        });
        let { weeklyBGScheduleTemplateMany } = res.data;
        weeklyBGScheduleTemplateMany = _.map(weeklyBGScheduleTemplateMany, removeTypename);
        let defaultTemplate = {};
        _.forEach(weeklyBGScheduleTemplateMany, template => {
          if(template.name === defaultBGTemplate) defaultTemplate = template;
        });
        const { _id,description,name,schedule } = defaultTemplate;
        this.BGSchedule = {
          frequency: bgFrequency,
          weeklyMealSchedule: {
            _id,description,name,schedule
          }
        };
      } catch (err) {
        console.error('err', err);
      }
    }
    this.vitalTemplates = {
      ...this.vitalTemplates,
      ...newVitalTemplates
    };

    return toAddVitals;
  }

  parseExerciseTask = (vitalData, vitalType, selectedTaskId) => {
    const type = VITAL_TYPE_MAP[vitalType];
    const prevTask = _.find(this.currentTasks, { type });
    if(prevTask) {
      const { schedule = [], threshold = [], repeat } = prevTask;
      return {
        id: selectedTaskId,
        type,
        schedule: _.map(schedule, s => _.omit(s, '__typename')),
        repeat,
        threshold: _.map(threshold, t => ({
          measure: t.measure,
          range: t.range,
          unit: t.unit,
          exerciseGoal: _.omit(t.exerciseGoal, '__typename')
        }))
      };
    }
    const { times = 7, unit = 'WEEKLY' } = DEFAULT_VITAL_SCHEDULE[vitalType];
    return {
      type,
      schedule: [{ target: times }],
      repeat: unit,
      threshold: []
    };
  }

  parseBGSchedule = (vitalData,vitalType, selectedTaskId)=>{
    let schedule  = vitalData;
    schedule.vitalType = vitalType;
    schedule.frequency = bgFrequency;
    const { templateDetails } = addTemplateDetails(schedule);
    return {
      id:selectedTaskId,
      schedule:generateScheduleForDB({schedule,vitalType}),
      type:VITAL_TYPE_MAP[vitalType],
      repeat:'SCHEDULED',
      templateDetails
    };
  }

  parseVitalSchedule = (vitalData,vitalType,selectedTaskId)=>{
    let schedule  = _.get(vitalData,'value',{});
    let times  = _.get(vitalData,'frequencyValue.times');
    const frequency  =  _.get(vitalData,'frequencyValue.frequency');
    schedule.vitalType = vitalType;
    schedule.frequency = 'frequency';
    schedule.frequencyValue = { times };
    return {
      id:selectedTaskId,
      schedule:generateScheduleForDB({schedule,vitalType}),
      repeat:frequency,
      type:VITAL_TYPE_MAP[vitalType],
    };
  }

  parseTask = (v,threshold=[])=>{
    const { parseVitalSchedule,parseBGSchedule, parseExerciseTask } = this;
    const isBG = v === 'Blood Glucose';
    const isExercise = v === 'Exercise Goal';
    const selectedTask = _.find(this.currentTasks, {type: VITAL_TYPE_MAP[v]});
    const selectedTaskId = _.get(selectedTask,'id');

    const vitalData = isBG ? this.BGSchedule : _.get(this.vitalTemplates, v);
    let f = parseVitalSchedule;
    if(isBG) f = parseBGSchedule;
    if(isExercise) f = parseExerciseTask;

    const parseVitalScheduleVal = f(vitalData,v,selectedTaskId);
    return {
      threshold,
      ...parseVitalScheduleVal
    };
  }

  // vitalList: ['Blood Glucose', 'Blood Pressure',...]
  handleAddVitals = async ({ vitalList, onSuccess, onError, refetchQueries = [] }) =>{

    const handleError = (e) => {
      if (onError) onError(e);
    }

    if(!this.validProgram) 
      return handleError('invalidProgram');
    
    if(!_.isArray(vitalList)) 
      return;

    let toAddVitals = await this.getToAddVitals(vitalList);
    if(!toAddVitals.length) return; // nothing to add

    // toAddVitals = this.currentVitals.concat(toAddVitals);

    const tasks = [];
    for(let vIndex in toAddVitals){
      const v = toAddVitals[vIndex];

      let defaultThreshold = getDefaultThreshold(v, this.adminProgram);

      const task = this.parseTask(v,defaultThreshold);

      // prevent creating BG task with blank schedule
      if(_.get(task, 'type') === 'BG' && !_.get(task, 'schedule.length')){
        return handleError('BG Schedule can\'t be blank');
      }
      tasks.push(task);
    }

    try{
      const res = await Client.mutate({
        mutation: editEnrolledProgram,
        variables: {
          id: this.programId,
          tasks
        },
        refetchQueries: [
          {
            query: enrolledProgramGQL,
            variables:{ id: this.programId }
          },
          ...refetchQueries
        ]
      });
      if(onSuccess) onSuccess(res);
    }
    catch(e){
      console.error(e);
      if(e && e.message.includes('not authorized')) {
        return handleError('notAuthorized');
      }
      handleError(e);
    }
  }

  needRemoveConfirm = vitalType =>
    this.validProgram && _.includes(this.currentVitals, vitalType);

  handleRemoveVitals = ({ vitalType, onSuccess, onError, refetchQueries = [] })=>{
    if(!this.validProgram) return;

    let err;
    if(this.currentVitals.length === 1){
      err = 'Patient must have at least one vital in the care plan.';
      if(onError)
        onError(err);
      console.error(err);
      return;
    }
    const taskId = this.getVitalId(vitalType);

    if(!taskId) {
      err = 'Task id not found';
      if(onError)
        onError(err);
      console.error(err)
      return;
    }

    Client.mutate({
      mutation: removeTaskFromEnrolledProgram,
      variables: {
        id: this.programId,
        taskId: [taskId]
      },
      refetchQueries:[
        {
          query: enrolledProgramGQL,
          variables: { id: this.programId },
        },
        ...refetchQueries
      ]
    })
    .then(res =>{
      if(onSuccess) onSuccess(res);
    })
    .catch(err => {
      if(onError)
        onError(err);
      console.error(err);
    });
  }

  getVitalId = (vitalType)=>{
    const tasks = this.currentTasks;
    const vital = _.first(_.filter(tasks,(t)=>t.type==VITAL_TYPE_MAP[vitalType]));
    return _.get(vital, 'id');
  }
}
