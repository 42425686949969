import { constants } from 'libModule/utils';

export default constants('MyPrograms', 'MyPrograms', [
  'SORT',
  'CHANGE_PAGE',
  'ON_SEARCH',
  'RESET'
])

export const variables = {
  page: 1,
  count: 500,
  options: {},
  sort: {
    field: 'START_DATE',
    direction: 'ASC'
  },
  filters: {}
}
