import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Row} from 'antd';
import educationForm from '../constant/educationForm';
import '../style/index.scss';
import getVisit from 'graphqlModule/singleVisit';
import editUserGQL from 'graphqlModule/mutation/editUser';
import Client from 'libModule/gqlClient';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
import confirmUnsavedArticles from '../../careplanNew/helpers/confirmUnsavedArticles';

const parseValue = props => {
  const { user, form } = props;
  const { handouts, ...education } = form.getFieldsValue();
  let userVariables = {
    id: _.get(user, 'id'),
    memberProfile: {
      birthday: _.get(user, 'profile.birthday'), // required by editUser
      handouts,
      education
    }
  };
  return userVariables;
};

const onFieldsChange = props => {
  const { isEditMode, onTouch, setChildProps, visit } = props;
  if(!isEditMode && onTouch && setChildProps) {
    const visitRefetchQuery = [{
      query: getVisit, 
      variables: { id: _.get(visit, 'id') },  
      fetchPolicy: 'network-only'
    }];
    onTouch();
    setChildProps(
      props, 
      parseValue, 
      editUserGQL, 
      visitRefetchQuery, 
      EducationComponent.title
    );
  }
};

let EducationComponent = class extends React.Component{
    constructor(props) {
      super(props);
    }

    renderForm = () => {
      const { form:{ getFieldDecorator }} = this.props;
      return <Form className={'educationForm scrollable-content'}>
          {_.map(educationForm, item => {
            const { key, label = '', initialValue, valuePropName, render } = item;
            const fieldOpts = {};
            if(initialValue) {
              fieldOpts.initialValue = typeof initialValue === 'function' ? 
                                      initialValue(this.props) : 
                                      initialValue;
            }
            if(valuePropName) {
              fieldOpts.valuePropName = valuePropName;
            }
            return <Form.Item key={key} label={label} colon={false}>
              {
                getFieldDecorator(key, fieldOpts)(render(this))
              }
            </Form.Item>;
          })}
        </Form>;
    }

    setHasChanges = ({ handouts = [] }) => {
      this.props.form.setFieldsValue({ handouts });
      onFieldsChange(this.props);
    }


    handleSave = _.debounce(async (actionType) => {
      const res = await confirmUnsavedArticles();
      if (!res) return;

      const { afterSaveDraftSucc, afterSubmitSucc, visit, title, submitButtonText } = this.props;
      const visitRefetchQuery = [{
        query: getVisit, 
        variables: { id: _.get(visit, 'id') },  
        fetchPolicy: 'network-only'
      }];
      Client.mutate({
        mutation: editUserGQL,
        variables: parseValue(this.props),
        refetchQueries: actionType === 'draft' ? visitRefetchQuery : []
        // auto-update values in patient profile, via cache
      }).then(res => {
        switch(actionType){
          case 'draft':
            afterSaveDraftSucc();
            break;
          case 'complete':
            afterSubmitSucc();
            let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
            Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
            break;
          default:
            console.warn(`Missing action when handing save in ${EducationComponent.displayName}`);
            break;
        }
      }).catch(err => {
          console.error(err);
          // if(openErrorModal) openErrorModal(GQLHelper.formatError(err));
      });
    }, 500);

    renderFooterButtons = ()=>{
        const { isEditMode, submitButtonText, disabled } = this.props;
        return <Row className='fix-footer' style={{ float: 'right', paddingTop: 0 }}>
          {
            <div>
              {
                isEditMode &&
                <Button
                  style={{ width: 130, marginRight: 10 }}
                  disabled={disabled}
                  onClick={() => this.handleSave('draft')}
                  className='saveDraftBtn'
                >
                    Save Draft
                </Button>
              }
              <Button
                disabled={disabled}
                type='primary'
                onClick={() => this.handleSave('complete')}
              >
                { submitButtonText || 'Complete Section' }
              </Button>
            </div>
          }
      </Row>;
    }

    render() {
      const { isWorkList, enrolledProgramId } = this.props;
      return <div className='education-main-container'>
        {/* {!enrolledProgramId && isWorkList && renderOverlayDiv(this.props)}
        <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.1 : 1 }}> */}
          <div className='fix-header'>
            <div className='fixHeaderTitle'>
              <div className='section-label-h3'>
                Education
              </div>
              {renderDraftSavedText()}
            </div>
          </div>
          { this.renderForm() }
          { this.renderFooterButtons() }
        {/* </div> */}
      </div>;
    }
}

EducationComponent.title = 'Education';
EducationComponent.displayName = 'Education';

EducationComponent.propTypes = {
  user: PropTypes.object.isRequired,
  visit: PropTypes.object,
  isWorkList: PropTypes.bool,
  isEditMode: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Form.create({ onFieldsChange })(EducationComponent);
