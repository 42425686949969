import React, { Fragment } from 'react';
import { CellularDeviceUnassignButtonComponent } from '../CellularDeviceUnassignButtonComponent/CellularDeviceUnassignButtonComponent';
import { CellularDeviceDisplayBrandComponent } from '../CellularDeviceDisplayComponent/CellularDeviceDisplayBrandComponent';

export const CellularDeviceOnboardUnassignComponent = ({
  brand,
  deviceId,
  language,
  onUnassign,
}) => (
  <Fragment>
    <div className="cellular-devices-onboard-unassign-modal__title">
      To uncheck, you need to unassign
      {' '}
      <CellularDeviceDisplayBrandComponent brand={brand} />
      {' '}
      device first.
    </div>
    <div className="cellular-devices-onboard-unassign-modal__device-info">
      <div>
        Device ID:
        {' '}
        {deviceId}
      </div>
    </div>
    <CellularDeviceUnassignButtonComponent
      onUnassign={onUnassign}
    />
  </Fragment>
);
