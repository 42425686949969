import gql from 'graphql-tag'
import {User} from './schema/User'
import { Phone, Identification } from './schema/common';

export default gql `
  query searchPatient(
    $id:String!,
    $firstName : String,
    $lastName : String,
    $dob : String!
  ){
    searchPatient(
      identification:$id,
      firstName:$firstName,
      lastName:$lastName,
      dateOfBirth:$dob
    ){
      ${User}
    }
  }
`

// Patients page
export const patientListSearch = gql`
  query patientList(
    $page:Int,
    $count:Int,
    $sort:UserListSort,
    $filters: InputPatientFilters,
    $search: InputUserSearch
  ){
    patientList(
      filters:$filters,
      search:$search,
      page:$page,
      count:$count,
      sort:$sort
    ){
      data {
        user {
          id
          phone {
            ${Phone}
          }
          identification {
            ${Identification}
          }
          profile {
            fullName
            ...on MemberProfile {
              birthday
              lastMeasuredAt
              programCategories {
                name
                enrolled
                enrolledDate
              }
            }
          }
        }
        enrolledPrograms {
          id
          name
          description
          startDate
          endDate
          status
          tasks{
            type
          }
        }
      }
    }
  }
`;
