import query from "../graphql";
import Client from 'libModule/gqlClient';

const getOrgNotifications = (cb)=>{
    Client.query({ query: query.orgNotificationsQuery,fetchPolicy:'network-only' })
          .then(({data})=> {
            if (data) {
                if(cb) cb(data.orgNotifications)
            }
        });
}

export default getOrgNotifications;
