import React from 'react';

const Overlay = ({ children, styles }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: 'rgba(255, 255, 255, 0.92)',
        ...styles,
      }}
    >
      {children}
    </div>
  );
}

export default Overlay;