import { combineReducers } from 'redux'
import type from '../constants'

const initialState = {
  currentProgram: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgram: action.currentProgram
      }
    case type.RESET:
      return {
        ...state
      }
    default:
      return state
  }
}

export default {
  inactivePatientsList: combineReducers({
    main: reducer
  })
};
