import PropTypes from 'prop-types';

export default {
  TaskWorkStationContainer: {
    member: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  },
  TaskWorkStationComponent: {
    member: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    task: PropTypes.object.isRequired, 
    containerStyles: PropTypes.object, 
    refetchTaskAssignments: PropTypes.func.isRequired
  },
  TaskWorkStationReassignment: {
    currentUser: PropTypes.object.isRequired, 
    task: PropTypes.object.isRequired, 
    enrolledPrograms: PropTypes.array, 
    team: PropTypes.array, 
    setShowReassignForm: PropTypes.func, 
    refetchTaskAssignments: PropTypes.func.isRequired
  },
  TaskWorkStationInput: {
    currentUser: PropTypes.object.isRequired, 
    task: PropTypes.object.isRequired, 
    memberId: PropTypes.string.isRequired,
    refetchTaskAssignments: PropTypes.func.isRequired
  },
};