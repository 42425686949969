import {
  React, IHSearchTable, _, IHButton, moment
} from 'ihcomponent'
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'

const Component = class extends React.Component {
  static displayName = 'organization/list'

  componentWillUnmount() {
    if (!new RegExp(/^(\/organization)/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null)
    }
  }

  render() {
    const p = this.getTableProps()
    return <IHSearchTable {...p} />
  }

  getTableProps() {
    const { list, loading, search, onTableSearch, onSearchEnter, sort = {},onTableChange, page, filter } = this.props;
    const dataSource = _.get(list, 'data');
    const filters = _.get(list, 'filters', []);
    return {
      title: `Organizations (${_.get(list, 'pageInfo.total', 0)})`,
      dataSource,
      rowKey: (doc) => {
        return doc.id;
      },
      onChange: onTableChange,
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(list, 'pageInfo.total', 0),
      },
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      inputPlaceholder: 'Search by Name',
      loading,
      columns: [
        {
          key: 'name',
          title: 'Name',
          dataIndex: 'name',
        },
        {
          key: 'identifier',
          title: 'identifier',
          dataIndex: 'identifier',
        },
        {
          key: 'leaf',
          title: 'leaf',
          dataIndex: 'leaf',
          render: (t, doc) => {
            let leaf = doc.leaf;
            return <span>{leaf ? 'true' : 'false'}</span>;
          }
        },
        {
          key: 'active',
          title: 'active',
          dataIndex: 'active',
          render: (t, doc) => {
            let active = doc.active;
            return <span>{active ? 'true' : 'false'}</span>;
          }
        },
        {
          key: 'timezone',
          title: 'timezone',
          dataIndex: 'timezone',
        },
        {
          title: 'Actions',
          key: 'Actions',
          render: (t, doc) => {
            const bp = {
              size: 'exsmall',
              bsStyle: 'primary',
              onClick: () => {
                browserHistory.push(`/superadmin/organizations/${doc.id}/edit`)
              },
              className: 'table-action-button'
            }
            const label = 'Edit'
            return (
              <IHButton {...bp}>
                {label}
                <i className="fa fa-angle-right ih-right"></i>
              </IHButton>
            )
          }
        }
      ]
    }
  }
}

Component.propTypes = {
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  list: PropTypes.object,
  onSearchEnter: PropTypes.func,
  onTableSearch: PropTypes.func,
  search: PropTypes.string,
}

export default Component
