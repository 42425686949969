import errors from './en/errors.js'
import chat from './en/chat.js'
import ThresholdInput from './en/ThresholdInput'
import columnTitle from './en/ColumnTitle'
import Program from './en/Program.js'
import validator from './en/validator.js'
import auditLog from './en/auditLog.js'
import keywords from './en/keywords'
import dates from './en/dates';
import measure from './en/measure';
import profile from './en/profile';
import address from './en/address';
import { convertUnits } from 'libModule/utils/convertUnits'
import vital from './en/vital';
import dischargeReason from './en/dischargeReason';
import memberDetail from './en/memberDetail';
import teamRoles from './en/teamRoles';
import smartAlert from './en/smartAlert';
import agoraVideo from './en/AgoraVideo';
import visit from './en/visit';
import taskAssignment from './en/taskAssignment';
import healthCondition from './en/healthCondition';
import chronicDisease from './en/chronicDisease';
import selfEvaluation from "./en/selfEvaluation";
import goalstatement from './en/goalstatement';
import monthlyReviewPlan from "./en/monthlyReviewPlan";
import deviceInput from './en/deviceInput';
import workStation from './en/workStation';
import referAndEnroll from "./en/referAndEnroll";
import admin from './en/admin';
import { complexTeamRoles, nonComplexTeamRoles } from './en/teamRoles';
import hiatusStatus from './en/hiatusStatus';
import programCategories from './en/programCategories';
import signOff from './en/signOff';
import medicationCompliance from './en/medicationCompliance';

const VSM_US_units = convertUnits('VSM_US');

const PASSWORD_PROMPT = 'Password shall contain a combination of upper and lower case alphabets, numbers and special characters (combination of any 3 of the 4 listed). Password should also be at least 8 characters long.'
export default {
  admin,
  chat,
  referAndEnroll,
  selfEvaluation,
  monthlyReviewPlan,
  goalstatement,
  agoraVideo,
  vital,
  dates,
  measure,
  memberDetail,
  profile,
  address,
  keywords,
  ThresholdInput,
  Program,
  teamRoles,
  dischargeReason,
  visit,
  taskAssignment,
  healthCondition,
  chronicDisease,
  deviceInput,
  IPAD_MINI: 'iPad Mini',
  BP: 'BP',
  DIABETES: 'Diabetes',
  HEART_FAILURE: 'Heart Failure',
  AMI: 'AMI',
  smartAlert,
  gender: {
    M: 'Male',
    F: 'Female',
  },
  techLevel:{
    HIGH:'High',
    NORMAL:'Normal',
    LOW:'Low'
  },
  motivation:{
    NONE: 'None Selected',
    PRECONTEMPLATION:'Pre-contemplation',
    CONTEMPLATION:'Contemplation',
    PREPARATION:'Preparation',
    ACTION:'Action',
    MAINTENANCE:'Maintenance'
  },
  manual: 'Manual',
  device: 'Device',
  vitals: {
    BP: 'Blood Pressure',
    BG: 'Blood Glucose',
    HS: 'Weight',
    PO: 'Blood Oxygen',
    TM: 'Temperature',
  },
  vitalsAbbreviation: {
    BP: 'BP',
    BG: 'BG',
    HS: 'Weight',
    PO: 'SpO2',
    TM: 'Temperature',
  },
  Devices: {
    BP: {
      BP5: 'iHealth Feel Wireless Blood Pressure Monitor (BP5)',
      BP3L: 'iHealth Ease Blood Pressure Monitor (BP3L)',
    },
    BG: {
      BG5: 'iHealth Smart Wireless Gluco-Monitoring System (BG5)',
    },
    TABLET: {
      IPAD_MINI: 'iPad Mini'
    },
    HS: {
      HS4S: 'iHealth Lite Wireless Body Analysis Scale (HS4S)',
    },
    HR: {
      BP5: 'iHealth Feel Wireless Blood Pressure Monitor (BP5)',
      BP3L: 'iHealth Ease Blood Pressure Monitor (BP3L)'
    },
    TM: {
      TM3: 'iHealth Thermometer',
    },
    PO: {
      PO3: 'iHealth Pulse Oxygen Device',
    }
  },
  Threshold: {
    BG: {
      name: {
        BEFORE_MEAL: 'Before Meal',
        AFTER_MEAL: 'After Meal',
      },
      title: 'Blood Glucose',
      unit: VSM_US_units.BG.unit,
    },
    BP: {
      name: {
        SYSTOLIC: 'Systolic',
        DIASTOLIC: 'Diastolic',
      },
      title: 'Blood Pressure',
      unit: 'mmHg',
    },
    HS: {
      name: {
        WEIGHT: 'Weight',
      },
      title: 'Weight',
      unit: VSM_US_units.HS.unit,
    },
    HR: {
      name: {
        HEART_RATE: '',
      },
      title: 'Heart Rate',
      unit: 'beats/min',
    },
    TM: {
      name: {
        BODY_TEMPERATURE: '',
      },
      title: 'Temperature',
      unit: 'C',
    },
    PO: {
      name: {
        BLOOD_OXYGEN: '',
      },
      title: 'Blood Oxygen',
      unit: '%',
    },
  },

  onboard: {
    step1: {
      title: 'SET PASSWORD',
      description: PASSWORD_PROMPT,
      passwordTitle: 'New Password',
      passwordConfirmTitle: 'Re-type New Password',
      mobile: 'Mobile Number',
      submit: 'Submit',
      agreementProvider: 'By checking this option, you agree to the declaration on consent to the <a target="_blank" href="/terms_provider.pdf"><u>Terms of Use</u></a>',
      agreementPatient: 'By checking this option, you agree to the declaration on consent to the <a target="_blank" href="/terms_patient.pdf"><u>Terms of Use</u></a>',
      agreement: 'By checking this option, you agree to the declaration on consent to the <a target="_blank" href="/terms.html"><u>Terms of Use</u></a>',
      loginId: 'Login Id',
    },
    step2: {
      title: '2-Step Verification',
      description: 'Please enter the verification code sent to your phone ending in',
      descriptionPart2: 'This code is only valid for 2 minutes.',
      verificationTitle: 'Verification Code',
      // verificationPlaceholder: '234789',
      verificationPlaceholder: '',
      sendNew: 'Send a New Code',
      buttonBack: 'Back',
      buttonVerify: 'Verify',
      //footerTitle: 'Not getting the code? Different Number?',
      //footerDetail: 'Try send the code again.',
      // footerDetailGoback: 'Go back',
      // footerDetail: ' to change your number or contact <br />[Tech Support Email] if still not getting the code.',
    },
  },

  auth: {
    resetPassword: {
      title: 'SET NEW PASSWORD',
      // description: 'Password must be a minimum of 8 characters and have at least 1 alphabet character and 1 number',
      description: PASSWORD_PROMPT,
      passwordTitle: 'New Password',
      passwordConfirmTitle: 'Re-type New Password',
      submit: 'Submit',
      cancel: 'Cancel'
    },
    password: {
      createPw: 'Create Password',
      createAppPw: 'Create App password',
      confirmPw: 'Confirm Password',
      changePw: 'Change Password',
      newPw: 'New Password',
      confirmNewPw: 'Confirm New Password',
      rule1: 'Must be at least 8 characters in length',
      rule2: 'Must contain a number',
      rule3: 'Must contain at least 1 lowercase letter',
      rule4: 'Must contain at least 1 UPPERCASE letter',
      rule5: 'Must contain a special character',
      errorClass: 'pw-error',
      validClass: 'pw-valid'
    }
  },
  providerNoteCat: {
    'GENERAL': 'Comprehensive Care Plan',
    'NURSING': 'Care Team Notes',
    'MED_RECONCILIATION': 'Medication Reconciliation',
    'CALL_LOG': 'Call Log',
    'RE_ENGAGEMENT': 'Re-engagement',
    'ALERTS': 'Alerts',
    'UNFLAG': 'Unflag Notes',
    'PAUSE_FOLLOW_UP': 'Hiatus status',
    'FOLLOW_UP_COUNSELING': 'Follow Up Counseling',
    'SMART_ALERT':'Smart Alert',
    'TASK_NOTES':'Task Notes',
    'OFFLINE_TASK_LOG':'Offline Task Log',
    'DOCTOR_REVIEWED': 'Doctor Reviewed',
    'MTPR': 'Monthly Review - MTPR',
    'MTPR_ADDITIONAL': 'Monthly Review - MTPR',
    'MRE': 'Monthly Review - MRE',
    'MRE_ADDITIONAL': 'Monthly Review',
  },
  validator,
  errors,
  columnTitle,
  auditLog,
  userrole: {
    nurse: 'Care Manager',
    doctor: 'Doctor',
    medicalAssistant: 'Medical Assistant',
    clinicMedicalAssistant: 'Clinic Medical Assistant'
  },

  tableTitle: {
    'Programme Tasks': `${keywords.Program} Tasks`,
    'My Programmes': `My ${keywords.Programs}`,
    'Upcoming Programme Tasks': `Pending or Upcoming ${keywords.Program} Tasks Today`,
    'Past Programme Tasks': `Completed or Missed ${keywords.Program} Tasks Today`,
    'Provider Notes': 'Provider Notes',
  },
  OTPLogInInfo: 'OTP stands for one-time password. When enabled, patient can request a OTP through SMS, and login with mobile number + the OTP. One mobile number can only be used to login one patient.',
  canUseForLogin: 'Enable OTP Login?',
  SelfEnrollment: 'If this is a self-enrolled patient, this provider will be his/her affiliated provider in the Unified Care program.',
  IsSelfEnrolled: 'This patient’s PCP is not in our program, but this patient will be affiliated to a provider already in our program.',
  SelfTag: 'This is a self-enrolled patient. This patient’s PCP is not in the Unified Care program, but this patient will be affiliated to a provider within the system.',
  NutritionUnderstanding: 'Please choose based on the patient’s overall nutritional understanding. These selections are not tied to one particular condition, but the patient’s overall health. For example, choose ‘Unaware of most foods that raise measurement levels’ if the patient knows little about how their food choices affect their conditions and vitals.',
  patientlist: {
    buttons: {
      newPatientButton: 'Create Patient',
      addCaregiverButton: 'Add Caregiver',
      cancelButton: 'Cancel',
      enrollButton: `Continue to Enroll into ${keywords.Program}`,
      createButton: 'Create',
      importButton: 'Import'
    },
    manualInputForm: {
      basicInfo: 'Basic Info',
      loginInfo: 'Login Info',
      nric: `${keywords['NRIC/FIN']}`,
      username: 'Username',
      dob: 'Date of Birth',
      firstName: 'First Name',
      lastName: 'Last Name (Family Name)',
      nickname: 'Nickname',
      gender: 'Gender',
      height: `Height (${VSM_US_units.HEIGHT.unit_ft}, ${VSM_US_units.HEIGHT.unit_inches})`,
      height_inches: ' ',
      weight: `Weight (${VSM_US_units.HS.unit})`,
      nationality: 'Nationality',
      nationType: 'Type',
      contactDetails: 'Contact Info',
      countryCode: 'Country Code',
      homeCountryCode: 'Home Phone',
      homePhone: 'Home Phone',
      mobileCountryCode: 'Mobile',
      mobilePhone: 'Mobile Phone',
      canUseForLogin: 'Enable OTP Login?',
      emailAddress: 'E-Mail',
      address: 'Address',
      streetNumber: 'Street #',
      streetName: 'Street Address',
      floor: 'Floor #',
      unitNumber: 'Apt, Suit, Floor (Optional)',
      city: 'City',
      state: 'State',
      country: 'Country',
      postCode: 'Zip Code',
      patientDetails: 'Patient Info',
      race: 'Race',
      language: 'Preferred Spoken Language',
      insuranceProvider: 'Primary Insurance Provider',
      insuranceProvider2:'Secondary Insurance Provider',
      CCMEnrolled: {
        enrolled: 'CCM Enrolled?',
        enrolledDate: 'Enrolled Date'
      },
      RPMEnrolled: {
        enrolled: 'RPM Enrolled?',
        enrolledDate: 'Enrolled Date'
      },
      MNTEnrolled: {
        enrolled: 'MNT Enrolled?',
        enrolledDate: 'Enrolled Date'
      },
      valueBasedEnrolled:{
        enrolled:'Value Based Enrollment',
        enrolledDate: 'Enrolled Date'
      },
      BPYearOfDiagnose: 'Year of Diagnosis',
      BGYearOfDiagnose: 'Year of Diagnosis',
      maritalStatus: 'Marital Status',
      // ethnicity:'Ethnicity',
      civicStatus: 'Civic Status',
      economicStatus: 'Economic Status',
      occupation: 'Occupation',
      insulinDependent: 'Insulin Dependent',
      BGTreatments: 'What treatment methods have you tried?',
      stroke: 'Stroke',
      cardiovascularDisease: 'Cardiovascular Disease',
      retinopathy: 'Retinopathy',
      other: 'Other',
      BGVisits: 'Have you seen regular visits in the past?',
      BGMedicine: 'Have you used medication regularly in the past?',
      BGTest: {
        testRegularly: 'Have you tested your blood sugar regularly in the past?',
        freq: 'How many times per week?'
      },
      BPTest: {
        testRegularly: 'Have you tested your blood pressure regularly in the past?',
        freq: 'How many times per week?'
      },
      kidneyDisease: 'Kidney Disease',
      liverDisease: 'Liver Disease',
      heartAttack: {
        hasHeartAttack: "Heart Attack",
        year: 'Year'
      },
      exerciseHabits: {
        value: 'Do you exercise?',
        freq: 'How often per week?',
        duration: 'Duration? (mins)'
      },
      dietHabits: {
        value: 'Diet',
        other: 'Other',
        dietProperties: 'Diet Intake Impression'
      },
      drinkingHistory: {
        value: 'Do you drink?',
        startSinceYear: 'Start since',
        drinkType: 'What do you like to drink?',
        freq: 'How often? (times/week)',
        amount: 'How much?',
        quitYear: 'Quit at year?'
      },
      smokingHistory: {
        value: 'Do you smoke?',
        startSinceYear: 'Start since',
        freq: 'How often? (times/day)',
        amount: 'How much?',
        quitYear: 'Quit at year?'
      },
      spouseMedicalHistory: 'Spouse',
      fatherMedicalHistory: 'Father',
      motherMedicalHistory: 'Mother',
      siblingMedicalHistory: 'Siblings',
      paternalFatherMedicalHistory: `Grandfather - Father's side`,
      paternalMotherMedicalHistory: `Grandmother - Father's side`,
      paternalUnclesOrAuntsMedicalHistory: `Uncles/Aunts - Father's side`,
      maternalFatherMedicalHistory: `Grandfather - Mother's side`,
      maternalMotherMedicalHistory: `Grandmother - Mother's side`,
      maternalUnclesOrAuntsMedicalHistory: `Uncles/Aunts - Mother's side`
    },

    importPatientForm: {
      firstName: 'First Name',
      lastName: 'Last Name (Family Name)',
      nickname: 'Nickname',
      nric: `${keywords['NRIC/FIN']} (Login ID)`,
      dob: 'Date of Birth',
      gender: 'Gender',
    }
  },
  vitalsPanelAndButtons: {
    'Vitals': 'Vitals',
    'Blood Pressure': 'Blood Pressure',
    'Blood Glucose': 'Blood Glucose',
    'Weight': 'Weight',
    'Heart Rate': 'Heart Rate',
    'List': 'List',
    'Chart': 'Chart',
    'BP': 'BP',
    'HR': 'HR',
    'Temperature': 'Temperature',
    'Blood Oxygen': 'Blood Oxygen',
    'Detailed': 'Detailed',
    'Aggregate': 'Aggregate'
  },
  dashboard: {
    noProgramCreated: `No ${keywords.program} has been enrolled!`,
    noDataPanelSelected: 'No Data Panels have been selected to show up here, choose some now!'
  },
  demographics: {
    backToDemographics: 'Back to Demographics',
    viewDemographics: 'View Demographics',
  },
  providerNoteTags: {
    CALLED_NO_ANSWER: 'Called - No answer',
    CALLED_VOICE_MAIL_FULL: 'Called - Voicemail full',
    SENT_TEXT_MESSAGE: 'Sent text message',
    CALLED_LEFT_VOICE_MAIL: 'Called - Left voicemail',
    CALLED_OUT_OF_SERVICE: 'Called - Out of service',
    SENT_CHAT: 'Sent chat message',
    PENDING_LAB_RESULTS: "﻿Pending Lab Results",
    PAUSE_PROGRAM_PARTICIPATION: "﻿Pause program participation",
    HOSPITALIZATION: "﻿Hospitalization",
    EXTENDED_TRAVEL: "﻿Extended travel",
    OTHER: "Other"
  },
  provider: {
    label: 'Provider',
    required: {
      label: 'Provider *',
      message: 'Provider is required'
    }
  },
  race: {
    label: 'Race'
  },
  error: {
    networkError: 'Network Failure,please check your network.',
    noPhoneError: 'This patient doesn’t have any phone number in the system yet. Please change another visit category or add phone number first.'
  },
  maWorkingCategory: {
    'VISIT DUE': 'VISIT DUE',
    'VISIT OVERDUE': 'VISIT OVERDUE',
    'A1C DUE': 'A1C DUE',
    'A1C OVERDUE': 'A1C OVERDUE'
  },
  remoteEnrollment: {
    addText: 'Add to remote enrollment',
    tooltip: {
      doctorReferral: {
        yes: 'This patient was referred by',
        no: 'Click to mark this patient as referred by the provider'
      }
    }
  },
  stickyNote: {
    singleLabel: 'Sticky Note',
    pluralLabel: 'Sticky Notes',
    addLabel: 'Add Sticky Note',
    editLabel: 'Edit Sticky Note',
    deleteLabel: 'Delete Sticky Note',
    required: {
      label: 'Sticky note is required',
      message: 'Sticky note is required'
    }
  },
  taskProvider: 'Tasking Provider Protocol',
  protocolTip: {
    protocolOne: '- If this is an emergency, please communicate with the provider directly via the provider’s preferred communication channel instead of tasking in the Unified Care portal.',
    protocolTwo: '- If you are tasking providers for abnormal readings, please refer to clinic-specific clinical parameter reporting guidelines.',
    protocolThree: {
      title: '- Before you task providers, be sure to call or chat with the patient to determine if the patient:',
      introOne: '• Used proper measuring techniques',
      introTwo: '• Took medication as prescribed (type, timing, dosage, etc.)',
      introThree: '• Had any dietary or lifestyle changes'
    },
    inClinicManual: 'In-Clinic Manual'
  },
  // EnrollDateTip:'It is the EARLIEST enrollment date among CCM,RPM or MNT.'
  enrollment: {
    enrolledDate: {
      tip: 'This is the date when the consent was originally obtained.',
      NAtip: 'Patient has not signed the consent form. Once signed, the enrollment date will show here.'
    },
  },
  consentForm: {
    label: {
      optionHeader: 'Which way does the patient prefer to sign the consent form?',
      changeCTHeader: "We noticed you've changed this patient's primary provider, Please resend the consent form to complete the this process",
      provider: 'Provider',
      effectiveDate: 'Effective Date',
      verbally: {
        consentDate: 'Date verbal consent is obtained'
      },
      paper: {
        consentDate: 'Date paper consent form was signed',
        signatureAt: 'Paper consent signature date*'
      },
      device: {
        legalStatement: 'I understand this is legal representation of my signature.',
        text: 'Please type initials (For patient)'
      }
    },
    error: {
      consentType: 'Signing method is required',
      provider: 'Please select at least one provider',
      effectiveDate: '',
      verbally: {
        consentDate: 'Please select the date when verbal consent form is signed'
      },
      paper: {
        consentDate: 'Please select the date when paper consent form was signed'
      },
      device: 'Please have patient type initials'
    }
  },
  missingAssignTip: "Please finish assigning care team members to this patient.",
  workStation,
  complexTeamRoles,
  nonComplexTeamRoles,
  hiatusStatus,
  programCategories,
  ctAssignees: {
    missingMsg: {
      VISIT_POPUP: {
        text: 'Missing care team assignees',
        withNoDx: "This patient's condition complexity level is also not defined. Please tick the condition checkbox or add ICD code in patient profile first."
      },
      FOLLOW_UP:  {
        text: 'Missing care team assignees. Please assign',
        withNoDx: "This patient's condition complexity level is also not defined. Please tick the condition checkbox or add ICD code first."
      },
      WORKLIST: {
        text: 'Please finish assigning care team members to this patient.',
      }
    }
  },
  signOff,
  medicationCompliance
};
