import React from 'react';
import { Modal } from 'antd';
import ModalPdfComponent from '../../chat/components/FileUpload/ModalPdfComponent';

class VersionHistoryPreviewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleClick = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { link, style = {}, children } = this.props;

    return (
      <React.Fragment>
        <Modal visible={this.state.visible} footer={null} width={1200} height={1200} onCancel={this.handleCancel}>
          <ModalPdfComponent name="VersionHistory" link={link} />
        </Modal>
        <span onClick={this.handleClick} style={{ cursor: 'pointer', ...style }}>
          {children}
        </span>
      </React.Fragment>
    );
  }
}

export default VersionHistoryPreviewButton;