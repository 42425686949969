import VitalFormTypes from '../constants/VitalFormConstants'
import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import CareplanCommonTypes from '../constants/CommonConstants'
import CareplanCommonActions from './CommonActions'
import AlertListTypes from 'patientModule/enrolledProgramInfo/VitalAlert/constants/AlertListConstants'
import AlertFormTypes from 'patientModule/enrolledProgramInfo/VitalAlert/constants/AlertFormConstants'
import { getInitAlertFormData } from 'modulesAll/utils/helpers/task'
import AlertFormContainer from 'patientModule/enrolledProgramInfo/VitalAlert/containers/AlertFormContainer'

const updateForm = (rowData, isEditMode)=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormTypes.UPDATE_FORM,
      fieldsValue: convertToFieldsValue(rowData),
      vitalType:rowData.vitalType,
      isEditMode
    })
    dispatch({
      type: AlertListTypes.INIT_DATA,
      initFormData: getInitAlertFormData(rowData, AlertFormContainer, AlertListTypes.REMOVE_TAB, AlertFormTypes.RESET_FORM, dispatch)
    })
  }
}

const resetForm = ()=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormTypes.RESET_FORM
    })
  }
}

const goToCareplanVitalForm = () => {
  return (dispatch, getState) => {
    dispatch(CareplanCommonActions.changePage(CareplanCommonTypes.CAREPLAN_VITAL_FORM_PAGE));
  }
}

const setVitalListForm = (rows) => {
  return {
    type: VitalFormTypes.SET_VITAL_LIST_FORM,
    vitalList: rows
  }
}

export default {
  updateForm,
  resetForm,
  goToCareplanVitalForm,
  setVitalListForm
}
