import { IHLoading } from 'ihcomponent'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import userGQL from 'graphqlModule/user'
import ProfileEditContainer from 'patientModule/profileEdit/containers/profileEditContainer'
import get from 'lodash/get'
import { connect } from 'react-redux'

class Container extends Component {
  static displayName = 'patient/profileEdit/containers/index'

  constructor(){
    super();
    this.state = {
      nricEnable:false,
      usernameEnable:false,
    }
    this.changeNRICState = this.changeNRICState.bind(this);
    this.changeUsernameState = this.changeUsernameState.bind(this);
  }

  changeNRICState(){
    this.setState({
        nricEnable: !this.state.nricEnable,
    })
  }

  changeUsernameState(){
    this.setState({
        usernameEnable: !this.state.usernameEnable,
    })
  }
  render() {
    const { data } = this.props
    const userData = data.user
    if(!userData){
      return <IHLoading />
    }

    return <ProfileEditContainer userData={userData} {...this.props}
                                 state = { this.state }
                                 changeNRICState={this.changeNRICState}
                                 changeUsernameState={this.changeUsernameState}/>
  }
}
Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.patientId')
  return {
    ...state.patient.profileEdit,
    ...ownProps,
    id
  }
}

const withGql = graphql(userGQL, {
  options: (ownProps)=>{
    const id = ownProps.id
    return {
      variables: {
        id
      },
      notifyOnNetworkStatusChange: true,
    }
  }
})
const ContainerWithData = withGql(Container)

Container.propTypes = {
  data: PropTypes.object,
}

export default connect(
  mapState
)(ContainerWithData)
