import React from 'react';
import ClaimBillableTimeForm  from './ClaimBillableTimeForm';
import { Modal,Popover,Button } from 'antd';
import '../css/index.scss';

let ClaimBillableTimeComponent = class extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    setShowModal = (showModal)=>{
        this.setState({
            showModal
        })
    }

    renderShowButton = ()=>{
        const { showModal } = this.state;
        const { patientId,refetchMontlySpent,user,children } = this.props;
        const { setShowModal } = this;
        return <Popover content={showModal&&<ClaimBillableTimeForm  user={ user} setShowModal={setShowModal} patientId={patientId} refetchMontlySpent={refetchMontlySpent}/>} placement={'bottom'}
                        overlayStyle={{ width:700 }} visible={showModal}
                        trigger={'click'}
                        onVisibleChange={(visible)=> !visible&&setShowModal(false) }
                        overlayClassName={'claimBillableTimePopover'}
               >
            {children ? <span onClick={()=>this.setShowModal(true)}>{children}</span> : <Button type={'primary'} onClick={()=>this.setShowModal(true)}>Add More Billable Time</Button>}
        </Popover>
    }

    render() {
        return <div style={this.props.style}>
            { this.renderShowButton()}
        </div>;
    }
}
export default ClaimBillableTimeComponent;
