import React from 'react';
import { Form,Row,Modal,Col,Collapse,Button,notification,Icon,message,Divider} from 'antd';
import { formConfig } from '../constant/formConfig';
import { renderForm } from '../../patient/AdditionalInfo/helpers/index.js';
import {compose, graphql, withApollo} from 'react-apollo/index';
import {userWithoutTeam as userGQL} from "../../graphql/user";
import getVirtualVisitTeam from '../../graphql/getVirtualVisitTeam';
import action from '../action'
import { decryptRole } from 'libModule/utils';
import API from '../API';
import helper from '../helper';
import InviteNextStepContainer from '../container/InviteNextStepContainer';
import '../style/index.scss';
import convertor from '@ihealth/convertor';
import roleMap from '../constant/roleMap';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import CreateFollowUpComponent from "../../VideoChat/component/CreateFollowUpComponent";
import {GQLHelper} from "../../../lib/utils";
const Panel = Collapse.Panel;
import Client from 'libModule/gqlClient';
import mappingMeasurements from '../constant/measurements';
import { convertCelsiusToFahrenheit } from 'libModule/utils/convertUnits';
import { IHLoading } from 'ihcomponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const { confirm } = Modal;

let VideoChatLeftComponent = class extends React.Component{
    constructor(props){
        super();
        this.state = {
            showLeaveMeetingModal: false,
            showCreateFollowUp: false,
            minimizedVideoChat: false,
            associatedVisit: props.currentVisit.associatedVisit,
            teams:null,
            latestMeasurements: {} // for Doctor's form
        }
    }

    componentDidMount(){
        const { minimizeButtonHandler } = this;
        const { currentVisit,meetingNumber, currentRole } = this.props;
        const leaveButton = _.first(document.getElementsByClassName('footer__leave-btn ax-outline'));
        const minimizeButton =document.getElementById('wc-container-left');
        const visitId = currentVisit.id;
        if(leaveButton) {
            leaveButton.addEventListener('click', () => { this.getVisitTeam()});
        }

        if (minimizeButton) {
            let btn = document.createElement("IMG");
            // Create a <button> element
            // btn.innerHTML = "CLICK ME";
            btn.src='image/minimize_button.png';
            btn.className ='full-screen-widget icon';
            btn.addEventListener('click',()=>{
                minimizeButtonHandler('none',true);
            })
            minimizeButton.appendChild(btn);
            let x = document.createElement("SPAN");
            x.className="full-screen-widget text"
            let t = document.createTextNode("Mini Mode");
            x.appendChild(t);
            minimizeButton.append(x);
        }

        if(roleMap[currentRole] == 'Doctor' || roleMap[currentRole] == 'MA'){
            // init fetch to prevent stale data from currentVisit
            // ignore if currentRole is not MA or MD because other roles don't use measurement form
            this.getLatestUpdateForVisit(currentRole, true);
        }
    }

    setAssociatedVisit = (associatedVisit)=>{
        this.setState({
            associatedVisit
        })
    }

    handleLeaveEvent = (eventType,visitId)=>{
        API.activePatientMutate({
            eventType,
            visitId
        }).then(res => {
            this.setState({
                showLeaveMeetingModal:false
            })
            Mixpanel.track('end', 'meeting', {PROVIDER_ROLE: this.props.currentRole})
            action.leaveMeeting();
        })
            .catch(error=>{
                message.error(GQLHelper.formatError(error))
        })
    }

    componentWillUnmount() {
        // action.leaveMeeting();
        const leaveButton = _.first(document.getElementsByClassName('footer__leave-btn ax-outline'));
        const minimizeButton = _.first(document.getElementsByClassName('full-screen-widget__icon'));
        // console.log(leaveButton,minimizeButton);
        // console.log(leaveButton,minimizeButton);
        // if (leaveButton) {
        //     leaveButton.removeEventListener('click');
        // }
        // if (minimizeButton) {
        //     minimizeButton.removeEventListener('click');
        // }

    }

    minimizedVideoChat = ()=>{
        const { user }= this.props;
        const { profile:{ fullName} } = user;
        return <div style={{ fontSize:16 }}>
                 <img src='image/green_phone.png' height={20}  style={{ marginRight:10 }}/>
                 <span style={{ fontWeight:'bold',marginLeft:10 }}>{ fullName }</span>
                 <a onClick={()=>this.minimizeButtonHandler('block',false)}>
                     <img src="image/Users-Exit-icon.png" style={{float:'right'}} height={20}/>
                 </a>
                </div>
    }

    minimizeButtonHandler = (zmmtgRootStatus,shouldOpen)=>{
        const zmmtgRoot = document.getElementById('zmmtg-root');
        const videoChatLeftDiv = $('#videoChatLeftDiv');
        zmmtgRoot.style.display = zmmtgRootStatus;
        if(shouldOpen){
            const variables = {
                key:'minimizedVideoChat',
                message:'',
                description:this.minimizedVideoChat(),
                duration: 0,
                className:'minimizedVideoChat',
                style:{
                    boxShadow:'0 4px 12px rgba(50,194,134,0.43)'
                }
            };
            // $('.suspension-no-drag.suspension-window.react-draggable').addClass('hidden');
            notification.open(variables);
        }else {
            // $('hidden.suspension-no-drag.suspension-window.react-draggable').removeClass('hidden');
            notification.close('minimizedVideoChat');
        }
        videoChatLeftDiv.css('display',zmmtgRootStatus);

    }

    setCreateFollowUp = (showCreateFollowUp)=>{
        this.setState({
            showCreateFollowUp
        })
    }

    getUpdateForCurrentVisit = async () => {
        const latestMeasurements = await action.getUpdatedCurrentVisit(_.get(this, 'props.currentVisit.id'));
        const {details: {height, weight, bgMeasurement, bpMeasurement, temperature, spo2}, reason} = latestMeasurements;
        let newValues = {
            weight,
            systolic: { value: _.get(bpMeasurement, 'systolic_blood_pressure.value'), unit: _.get(bpMeasurement, 'systolic_blood_pressure.unit') },
            diastolic: { value: _.get(bpMeasurement, 'diastolic_blood_pressure.value'), unit: _.get(bpMeasurement, 'diastolic_blood_pressure.unit') },
            heartBeat: { value: _.get(bpMeasurement, 'heart_rate.value'), unit: _.get(bpMeasurement, 'heart_rate.unit') },
            BG: { value: _.get(bgMeasurement, 'blood_glucose.value'), unit: _.get(bgMeasurement, 'blood_glucose.unit') },
            temperature,
            spo2,
        }
        // make sure every value has correct unit
        _.forEach(_.keys(newValues), valueName => { // valueName, ie systolic: String
            // measure, ie systolic_blood_pressure: Object
            const measure = _.filter(mappingMeasurements, measurement => measurement.name == valueName)[0];
            if(_.get(newValues, `${valueName}.value`)){ // prevent null value
                // latest value and its unit
                const { value: measurementValue, unit: measurementUnit } = _.get(newValues, valueName);
                // get value after conversion
                const valueAfterConversion = measure.getValue(measurementValue, measurementUnit)[0];
                _.set(newValues, valueName, valueAfterConversion);
            } else {
                newValues = _.omit(newValues, valueName);
            }
        });
        // special conversion for height, not in mappingMeasurements
        // height is only valuable if role is MA
        const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : '';
        const heightInFeet = height ? convertInchesToFeet(heightInInches) : '';
        const heightLeftInInch = height ? getRemainderAfterConvertingToFeet(heightInInches) : '';

        return {
            ...newValues,
            height: heightInFeet,
            height_inches: heightLeftInInch,
            reason
        };
    }

    getLatestUpdateForVisit = async (role, isInitFetch) => {
        let latestMeasurements, newValues;
        if(roleMap[role] == 'Doctor') {
            newValues = await this.getUpdateForCurrentVisit();
            // round all values
            _.forEach(_.keys(newValues), key => {
                const value = _.get(newValues, key);
                if(typeof value === 'number'){
                    _.set(newValues, key, _.round(value, 1));
                }
            });
            latestMeasurements = { ...this.state.latestMeasurements, ...newValues };
            this.setState({ latestMeasurements });
        } else {
            if(isInitFetch) {
                newValues = await this.getUpdateForCurrentVisit();
            }
            const memberId = _.get(this, 'props.currentVisit.member.id');
            latestMeasurements = await action.getLatestMeasurements(memberId);
            _.forEach(_.get(latestMeasurements, 'measureLatestByType', []), measure => {
                // clear noise in measure to get actual measurement, ie. blood_glucose: Object
                const measurements = _.pick(measure, _.keys(mappingMeasurements));
                _.forEach(_.keys(measurements), measurementType => { // measurementType, ie blood_glucose: String
                    // formKeyName, ie BG: String
                    const formKeyName = _.get(mappingMeasurements, `${measurementType}.name`);
                    // latest value and its unit
                    const { value: measurementValue, unit: measurementUnit } = _.get(measurements, measurementType);
                    // final value
                    const [value, unit] = _.get(mappingMeasurements, measurementType).getValue(measurementValue, measurementUnit);
                    newValues = {
                        ...newValues,
                        [formKeyName]: _.round(value, 1),
                    }
                })
            });
            console.log('new values ', newValues);
            this.props.form.setFieldsValue(newValues);
        }
    }

    renderPatientFormAndSaveButton = (role)=>{
        const { setCreateFollowUp, renderRefreshButton, renderSaveButton } = this;
        if(roleMap[role]=='CA'||roleMap[role]=='RD'||roleMap[role]=='Doctor'){
            return '';
        }

        return <Collapse bordered={false} className='headerInfoCollapse' expandIconPosition={'right'}
                         style={{ marginLeft:'-30px',marginRight:'-30px',padding:20,paddingTop:0,marginTop:'-45px' }}>
                  <Panel>
                   <div style={{ padding:10,padding:25 }}>
                     { renderForm(this,formConfig(roleMap[role],this.props,setCreateFollowUp,this)) }
                     { renderRefreshButton(role) }
                     { renderSaveButton() }
                   </div>
                  </Panel>
               </Collapse>
    }

    handleRefresh = role => {
        this.getLatestUpdateForVisit(role);
        message.success('Refreshed for latest measurements');
    }

    handleSaveEdit = ()=>{
        const { props } = this;
        const { form,currentVisit,currentRole,refetchVisitList } = props;
        const { validateFields,getFieldsValue } = form;
        const visitId = _.get(currentVisit,'id');
        validateFields((error,res)=>{
            if(!error){
                const formValues = getFieldsValue();
                const variables = helper.getVariables(formValues,visitId,currentRole);
                API.editVisitMutate(variables).then(res=> {
                        Mixpanel.track('save', 'measurements successfully');
                        refetchVisitList();
                        message.success('Measurements Saved');
                    }
                );
            }
        })
    }

    renderRefreshButton = (role)=>{
        return <div style={{ display:'flex',justifyContent:'center', paddingBottom: 10, width: '100%' }}>
                <Button style={{ width: '100%' }} type='primary' onClick={() => this.handleRefresh(role)}>Refresh for latest measurements</Button>
               </div>
    }

    renderSaveButton = ()=>{
        return <div style={{ display:'flex',justifyContent:'center', width: '100%' }}>
                <Button style={{ width: '100%' }} type='primary' onClick={()=>this.handleSaveEdit()} ghost>Save</Button>
               </div>
    }

    renderHeaderForNurse = (member)=>{
        const { user,currentVisit } = this.props;
        const { profile:{ fullName,language,birthday,gender } } = member;

        return <div style={{padding: '10px 30px', background: 'white', margin: '-30px', marginBottom: 0}}>
                <Row style={{marginBottom: 10, marginTop: 30}}><h4 style={{color: '#3178C9', fontSize: 20}}>{fullName}</h4></Row>
                <Row>{`${gender} ${language.description} ${helper.getAge(birthday)}`}</Row>
               </div>
    }

    renderHeaderForDoctor = (member,role)=>{
        const { user,currentVisit } = this.props;
        const { profile:{ fullName,gender,birthday,language } } = member;
        const { setCreateFollowUp, renderRefreshButton, renderSaveButton } = this;

        return <div style={{padding: '10px 30px', background: 'white', margin: '-30px', marginBottom: 0}}>
                <Row style={{marginBottom: 10, marginTop: 20}}><h4
                    style={{color: '#3178C9', fontSize: 20}}>{fullName}</h4></Row>
                    <Collapse bordered={false} className='headerInfoCollapse' expandIconPosition={'right'}>
                        <Panel key={1} header={`${gender} ${language.description} ${helper.getAge(birthday)}`}>
                            {this.renderBasicInfo(user, currentVisit)}
                            { renderForm(this,formConfig(roleMap[role],this.props,setCreateFollowUp,this)) }
                            { renderRefreshButton(role) }
                            { renderSaveButton() }
                            <Divider type='horizontal'/>
                        </Panel>
                    </Collapse>

            </div>
    }

    renderBasicInfo = (user,visit)=>{
        const { profile:{ height,weight } } = user;
        const { details:{ bpMeasurement, bgMeasurement, temperature,spo2 }, reason } = visit;
        const { latestMeasurements } = this.state;
        const latestHeightFt = _.get(latestMeasurements, 'height');
        const latestHeightIn = _.get(latestMeasurements, 'height_inches');
        const latestWeight = _.get(latestMeasurements, 'weight');
        const latestReason = _.get(latestMeasurements, 'reason');
        const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : '';
        const heightInFeet = height ? convertInchesToFeet(heightInInches) : '';
        const heightLeftInInch = height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
        const weightInPounds = weight ? convertor.HS(weight, 'kg', 'lbs', 0) : '';
        const systolic_value = _.get(latestMeasurements, 'systolic', _.get(bpMeasurement,'systolic_blood_pressure.value'));
        const diastolic_value = _.get(latestMeasurements, 'diastolic', _.get(bpMeasurement,'diastolic_blood_pressure.value'));
        const heart_rate = _.get(latestMeasurements, 'heartBeat', _.get(bpMeasurement,'heart_rate.value'));
        const blood_glucose = _.get(latestMeasurements, 'BG', _.get(bgMeasurement, 'blood_glucose.value'));
        const temperatureVal = _.get(latestMeasurements, 'temperature', _.get(temperature,'value'));
        const spo2Value = _.get(latestMeasurements, 'spo2', _.get(spo2,'value'));
        const map = [ { title:'Height',value:`${latestHeightFt || (heightInFeet||'--')}' ${latestHeightIn || (heightLeftInInch||'--')}"` },
                      { title:'Weight (lbs)', value: latestWeight || (weightInPounds||'--') },
                      { title:'BP (mmHg)',value:`${systolic_value||'--'} / ${diastolic_value||'--'}` },
                      { title:'Heart Rate (beats/min)',value:heart_rate||'--' },
                      { title:'BG (mg/dL)', value: blood_glucose || '--'},
                      { title:'Temperature (F)', value:convertCelsiusToFahrenheit(temperatureVal)||'--' },
                      { title:'SpO2 (%)',value: spo2Value||'--' },
                    ];
        return <div>
                    {
                        _.map(map,({title,value})=>{
                            return <Row key={title} style={{ fontSize:14,margin:'10px 0px',color:'black' }}>
                                     <Col span={13}>{`${title}:`}</Col>
                                     <Col span={8}>{`${value}`}</Col>
                                   </Row>
                        })
                    }
                    <Row>
                        <Col span={24}>Notes:</Col>
                        <Col span={24}>{latestReason || reason}</Col>
                    </Row>
              </div>
    }

    getVisitTeam =  () => {
        const { currentVisit } = this.props;
        const visitId = currentVisit.id;
        Client.query({
            query:getVirtualVisitTeam,
            variables:{
                visitId
            },
            fetchPolicy: 'network-only',
        }).then(res => {
            this.setState({
                teams: res.data.getVirtualVisitTeam,
                showLeaveMeetingModal: true
            });
        }).catch(e => {console.log(e)});
    }

    renderLeaveMeetingModal = ()=>{
        const { handleLeaveEvent,props } = this;
        const { showLeaveMeetingModal } = this.state;
        const { currentVisit } = props;
        const visitId = currentVisit.id;

        if(showLeaveMeetingModal){
            // const res  = Client.readQuery({
            //     query:getVirtualVisitTeam,
            //     variables:{
            //         visitId
            //     }
            // });
            const teams = this.state.teams;
            const currentUserId = sessionStorage.getItem('currentUserId')
            const currentUserStatus = _.first(_.filter(teams,(t)=>t.user.id==currentUserId),{});
            const { isHost } = currentUserStatus;
            return <Modal title="Are you sure to Leave meeting?" closable={true} footer={false} maskClosable={false} onCancel={()=>this.setState({ showLeaveMeetingModal:false })} visible={showLeaveMeetingModal}>
                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Col >
                                <Button onClick={()=>handleLeaveEvent('LEAVE',visitId)} type='primary'>Leave</Button>
                            </Col>
                            {
                                isHost ?
                                    <Col>
                                        <Button onClick={() => handleLeaveEvent('CHECK_OUT', visitId)} type='danger'
                                            style={{marginLeft: 10}}>End Meeting</Button>
                                    </Col>:''
                            }
                        </Row>
                   </Modal>
        }
        return '';
    }



    renderCreateFollowUp = ()=>{
        const { setCreateFollowUp,setAssociatedVisit } = this;
        const { currentVisit,setCurrentVisit,user } = this.props;
        const { showCreateFollowUp,associatedVisit } = this.state;
        if(showCreateFollowUp) {
            return <Modal visible={true} footer={false} onCancel={()=>setCreateFollowUp(false)}>
                <CreateFollowUpComponent user={user}
                                         currentVisit={currentVisit}
                                         associatedVisit={associatedVisit}
                                         setAssociatedVisit={setAssociatedVisit}
                                         setCreateFollowUp={setCreateFollowUp} setCurrentVisit={setCurrentVisit}/>
            </Modal>
        }
        return '';
    }

    render() {
        const { renderHeaderForDoctor, renderPatientFormAndSaveButton,renderHeaderForNurse,props,renderSaveButton,renderLeaveMeetingModal,renderCreateFollowUp } = this;
        const { loading,user,currentRole,getVirtualVisitTeam,currentVisit} = props;
        if(loading){
            return <div>loading</div>
        }

        return <div id='videoChatLeftDiv' style={{ position:'fixed',left:0,width:'25vw',zIndex:1000,display:'flex',flexDirection:'column',
                                                   height:'100%',background:'#F6F6F6',border:'solid 1px #DCDCDC',padding:30,paddingBottom:0,top:0 }}>
                        {
                            !user ?
                            <div style={{ width: '100%', height: 100, marginBottom: 30 }}>
                                <IHLoading />
                            </div>
                             :
                            <React.Fragment>
                                { roleMap[currentRole] == 'Doctor' ? renderHeaderForDoctor(user,currentRole) :renderHeaderForNurse(user,currentRole) }
                                { renderLeaveMeetingModal() }
                                { renderPatientFormAndSaveButton(currentRole) }
                                {/*{ renderSaveButton() }*/}
                                { renderCreateFollowUp() }
                            </React.Fragment>
                        }
                        <div style={{ backgroundColor:'white',margin:'0px -30px',padding:20,overflow:'scroll' }}>
                            <InviteNextStepContainer currentVisit={currentVisit}/>
                        </div>

               </div>

    }
}
// const teamData = graphql(getVirtualVisitTeam,{
//     options: (ownProps) => {
//         const visitId = ownProps.currentVisit.id
//         return {
//             variables: {
//                 visitId
//             },
//             notifyOnNetworkStatusChange: true,
//         }
//     },
//     props:({ownProps,data})=>{
//         const { loading,getVirtualVisitTeam } = data;
//         if(loading){
//             return {
//                 loading
//             }
//         }
//         return {
//             getVirtualVisitTeam
//         }
//     }
// })

VideoChatLeftComponent = Form.create({})(VideoChatLeftComponent);
export default (VideoChatLeftComponent);
