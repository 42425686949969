import adminMenu, { HOMEPAGE as adminHomePage } from 'modulesAll/utils/constants/menuPath/admin';
import superAdminMenu, { HOMEPAGE as superAdminHomePage } from 'modulesAll/utils/constants/menuPath/superAdmin';
import providerMenu, { HOMEPAGE as providerHomePage } from 'modulesAll/utils/constants/menuPath/provider';
import maMenu, { HOMEPAGE as maHomePage, specialMAMenu } from '../menuPath/ma';
import doctorMenu, { HOMEPAGE as doctorHomePage, specialDoctorMenu } from '../menuPath/newDoctorMenu';
import billerMenu, { HOMEPAGE as billerHomePage } from 'modulesAll/utils/constants/menuPath/biller';
import vendorMenu, { HOMEPAGE as vendorHomePage } from 'modulesAll/utils/constants/menuPath/vendor';
import patientMenu, { HOMEPAGE as patientHomePage } from 'modulesAll/utils/constants/menuPath/patient';
import { ROLES } from 'modulesAll/utils/constants/role';
export const MENUS = {
  [ROLES.admin]: adminMenu,
  [ROLES.patient]: patientMenu,
  [ROLES.doctor]: doctorMenu,
  // providerMenu.doctor,
  [ROLES.nurse]: providerMenu.nurse,
  [ROLES.medicalAssistant]: providerMenu.medicalAssistant,
  // [ROLES.clinicMedicalAssistant]: providerMenu.clinicMedicalAssistant,
  [ROLES.clinicMedicalAssistant]: maMenu,
  [ROLES.superAdmin]: superAdminMenu,
  [ROLES.vendor]: vendorMenu,
  [ROLES.biller]: billerMenu,
  [ROLES.partnerBiller]: billerMenu ,
  [ROLES.healthCoach]: providerMenu.healthCoach
  // [ROLES.MA] :maMenu,
  // 'PROVIDER:Clinic Medical Assistant':maMenu
}

export const SPECIAL_MENUS = {
  [ROLES.doctor]: specialDoctorMenu,
  [ROLES.clinicMedicalAssistant]: specialMAMenu,
}

export const HOMEPAGES = {
  [ROLES.admin]: adminHomePage,
  [ROLES.patient]: patientHomePage,
  [ROLES.nurse]: providerHomePage,
  [ROLES.medicalAssistant]: providerHomePage,
  [ROLES.doctor]: doctorHomePage,
  [ROLES.clinicMedicalAssistant]: maHomePage,
  [ROLES.superAdmin]: superAdminHomePage,
  [ROLES.vendor]: vendorHomePage,
  [ROLES.biller]: billerHomePage,
  [ROLES.partnerBiller]: billerHomePage ,
  [ROLES.healthCoach]: providerHomePage
}
