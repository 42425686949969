const alertLevel = {

    CDE:['LEVEL_ONE',
    'LEVEL_TWO',
    'LEVEL_THREE',
    'LEVEL_FOUR',
    'LEVEL_FIVE'],
    MA:['LEVEL_ONE',
        'LEVEL_TWO',
        'LEVEL_THREE',
        'LEVEL_FOUR']
}

const alertLevelExceptFive = [
    'LEVEL_ONE',
    'LEVEL_TWO',
    'LEVEL_THREE',
    'LEVEL_FOUR'
]

const taskType = [ 'LEVEL_ONE_BG_CRITICAL_LOW',
    'LEVEL_ONE_BG_CONTINUE_HIGH',
    'LEVEL_ONE_BP_CRITICAL_LOW',
    'LEVEL_ONE_BP_CRITICAL_HIGH',
    'LEVEL_ONE_VISIT_DUE',
    'LEVEL_TWO_A1C_DUE',
    'LEVEL_TWO_BG_PAIR_READING',
    'LEVEL_THREE_VISIT_OVERDUE',
    'LEVEL_THREE_BG_PAIR_READING',
    'LEVEL_THREE_PATIENT_APP_ENGAGEMENT',
    'LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT',
    'LEVEL_FOUR_A1C_OVERDUE',
    'LEVEL_FOUR_HIGH_FASTING_BG',
    'LEVEL_FOUR_HIGH_BG',
    'LEVEL_FOUR_HIGH_AVERAGE_BG',
    'LEVEL_FOUR_BG_TRENDING_HIGH',
    'LEVEL_FOUR_BG_TRENDING_DOWN',
    'LEVEL_FOUR_7_DAY_HYPERTENSION_STAGE_II',
    'LEVEL_FOUR_BP_TRENDING_HIGH',
    'LEVEL_FOUR_BP_TRENDING_DOWN',
    'LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS',
    'LEVEL_FIVE_BG_MISSING_PAIR',
    'LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG'
];

const MATaskTypes = ['LEVEL_ONE_VISIT_DUE','LEVEL_TWO_A1C_DUE','LEVEL_THREE_VISIT_OVERDUE','LEVEL_FOUR_A1C_OVERDUE'];
const MATaskTypesMap = {
  LEVEL_ONE_VISIT_DUE: 'Visit Due',
  LEVEL_TWO_A1C_DUE: 'A1C Due',
  LEVEL_THREE_VISIT_OVERDUE: 'Visit Overdue',
  LEVEL_THREE_INACTIVE_PATIENT: '2-week-inactive',
};
const CDETypes = _.difference(taskType,MATaskTypes);

const filterType = {
    MA:MATaskTypes,
    CDE: CDETypes
}

const alertLevelColorMap = {
    'LEVEL_ONE':{
        color:'#DA6453',
        backgroundColor:'#FEE9E6'
    },
    'LEVEL_TWO':{
        color:'#EF973B',
        backgroundColor: '#FFF4E8'
    },
    'LEVEL_THREE':{
        color:'#F2C81A',
        backgroundColor:'#FFF9DF'
    },
    'LEVEL_FOUR':{
        color:'#3BA767',
        backgroundColor:'#E9FAF0'
    },
    'LEVEL_FIVE':{
        color:'#26D3D2',
        backgroundColor:'rgba(38, 211, 210,0.05)'
    }
}
const alertLevelIndicatorColorMap = {
    'LEVEL_ONE':{
        color:'#DA6453',
        backgroundColor:'#FEE9E6'
    },
    'LEVEL_TWO':{
        color:'#EF973B',
        backgroundColor: '#FFF4E8'
    },
    'LEVEL_THREE':{
        color:'#F2C81A',
        backgroundColor:'#FFF9DF'
    },
    'LEVEL_FOUR':{
        color:'#3BA767',
        backgroundColor:'#E9FAF0'
    }
}
const alertStatus = {
    'TODO': 'TODO',
    'DONE': 'DONE'
}
export default {
    alertLevel,
    alertLevelExceptFive,
    alertLevelColorMap,
    alertLevelIndicatorColorMap,
    taskType,
    filterType,
    MATaskTypesMap,
    alertStatus
}