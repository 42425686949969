import React from 'react';
import querystring from 'querystring';

export default class StorageComponent extends React.Component {
  constructor(props){
    super(props)
    this.state = { isDone: false };
    window.addEventListener('storage', (event) => {
      const storage = this.getStorage();
      const isCredSource = JSON.parse(window.sessionStorage.getItem('credSource'));
      const isCredTarget = JSON.parse(window.sessionStorage.getItem('credTarget'));
      if(event.key === 'SESSION_SOTRAGE_REQEUST' && storage && isCredSource) {
        window.localStorage.setItem('SESSION_STORAGE_RESPONSE', JSON.stringify(storage));
        window.localStorage.removeItem('SESSION_STORAGE_RESPONSE');
        window.sessionStorage.removeItem('credSource');
      }
      if(event.key === 'SESSION_STORAGE_RESPONSE' && !storage && isCredTarget){
        this.setStorage(event.newValue);
        this.setState({ isDone: true });
        window.sessionStorage.removeItem('credTarget');
      }
    })
  }

  componentDidMount() {
    let searchString = window.location.search;
    searchString = searchString.substr(1, searchString.length);
    if (this.getStorage() || !_.get(querystring.parse(searchString), 'autoCred', false)) {
      this.setState({ isDone: true });
    } else {
      window.sessionStorage.setItem('credTarget', true);
      window.localStorage.setItem('SESSION_SOTRAGE_REQEUST', Date.now().toString());
      window.localStorage.removeItem('SESSION_SOTRAGE_REQEUST');
      setTimeout(() => { 
        if (!this.state.isDone) {
          this.setState({ isDone: true });
        }
      }, 200)
    }
  }

  getStorage = () => {
    const keys = [
      'authToken', 'doctorName', 'authAllRoles', 'currentUserConfigPermissions', 'coveredClinics', 'currentUserRoles',
      'enrolledProgramActiveIdList', 'appRoles', 'enrolledProgramIdList', 'currentUserId', 'presetNotes', 
      'adminProgramMap', 'userSettings', 'userAccess', 'currentUser', 'lastLogIn', 'adminProgramList',
    ];
    const storage = {};

    _.forEach(keys, (key) => {
      storage[key] = window.sessionStorage.getItem(key);
    });

    return storage.authToken ? storage : null;
  }

  setStorage = (value) => {
    const storage = JSON.parse(value);
    _.forEach(storage, (value, key) => {
      window.sessionStorage.setItem(key, value);
    });
  }

  render() {
    if (!this.state.isDone) {
      return null;
    }
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    )
  }
}
