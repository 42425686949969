import type from '../constants/ReplaceReturnDevicesConstants'
import { commonReplaceReturnDevicesReducer, commonReplaceReturnDevicesInitState, commonReplaceReturnDevicesInitForm, commonReplaceReturnDevicesFormReducer } from 'modulesAll/vendor/patient/reducers/CommonReplaceReturnDevicesReducer';


// Default State
const initState = {

  ...commonReplaceReturnDevicesInitForm,
  ...commonReplaceReturnDevicesInitState
};

const ownReducer = (state, action) => {

  switch(action.type) {


    //for return replace devices form
    // case type.INIT_DATA:
    //   return {
    //     ...state,
    //     initData: action.initData
    //   }


    default:
      return state
  }


}



  export default (state = initState, action) => {
    return commonReplaceReturnDevicesFormReducer( commonReplaceReturnDevicesReducer( ownReducer(state, action) , action, type) , action, type )
  }
