import React from 'react';
import { Checkbox,Form,Radio,Button,Tooltip,Icon,DatePicker } from 'antd';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import '../style/index.scss';
import API from '../API/index';
const { Group, } = Radio
import { message } from 'ihcomponent';
import { COMPLEXITY_LEVELS, PATIENT_COMPLEXITY_SOURCE_ENUM } from '../../../lib/constants';
import { getPatientComplexityByConditions , updatePatientComplexity, withComplexityWithMemberFunc} from '../../patient/main/API';
import { compose } from 'recompose';
import PrevComplexityComponent from '../../patient/main/components/PrevComplexityComponent';
import { getUpdatedComplexitySourceAndLevel } from '../helper/complexityLevel';
import ComplexitySummaryContainer from '../../patient/main/containers/ComplexitySummaryContainer';
const hoverMsg = 'The pre-checked diagnosis are carried over from the past visit.'
let CreateRemoteEnrollmentComponent = class extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            complexityLevel: null
        }
    }

    setComplexityLevel = (complexityLevel) => {
        this.setState({complexityLevel});
    }

    renderHeader = ()=>{
        return<div className='ant-form-item-label'>
                <label>Please select diagnosis</label>
                    <Tooltip title={hoverMsg}>
                        <Icon type={'info-circle'} className={'createRMInfo'}/>
                    </Tooltip>
              </div>
    }

    renderComplexity = () => {
        const { complexityLevel } = this.state;
        const { patientComplexity } = this.props;
        return (
          <ComplexitySummaryContainer 
            oldComplexity={patientComplexity}
            newComplexity={{
              source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
              level: complexityLevel
            }}
          />
        )
    }


    renderForm = ()=>{
        const { getFieldDecorator,getFieldValue } = _.get(this,'props.form');
        const conditionsList = _.get(this,'props.latestVisit.member.profile.conditionsList');
        const checkboxValue = dataHelper.conditionsListToCheckbox(conditionsList);
        
        const fullName = _.get(this,'props.fullName');
        const { patientComplexity } = this.props;
        const doctorReferral = getFieldValue('doctorReferral');
        return <Form className='createRMForm'>
                    <span className={'patientName'}>{fullName}</span>
                    <Form.Item label={this.renderHeader()}>
                        { getFieldDecorator('conditionsList',{ initialValue:checkboxValue })(this.renderCheckBoxGroup())}
                    </Form.Item>
                    {this.renderComplexity()}
                    <Form.Item label='Is this patient referred by doctor?'>
                        { getFieldDecorator('doctorReferral')(this.renderRadioBox())}
                    </Form.Item>
                    {   doctorReferral &&
                        <Form.Item label='Date Referred'>
                            { getFieldDecorator('doctorReferralDate')(<DatePicker/>)}
                        </Form.Item>
                    }
                    <div>
                        <Button type='primary' onClick={ this.onClick }>Add to remote enrollment</Button>
                    </div>
               </Form>
    }

    onClick = ()=>{
        const { form,memberId,refetch,refetchRemoteEnrollment, patientComplexity } = this.props;
        const { validateFields } = form;
        const {complexityLevel} = this.state;
        const currComplexity = {
            source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
            level: complexityLevel
        }
        const { source, level } = getUpdatedComplexitySourceAndLevel(patientComplexity, currComplexity);
        validateFields((error,res)=>{
            let { conditionsList, doctorReferral,doctorReferralDate } = res;
            let variables = { conditionsList:dataHelper.handleConditionsList(conditionsList),memberId,doctorReferralDate };
            if(doctorReferral){
                _.set(variables,'doctorReferral',doctorReferral);
            }
            API.createRE(variables).then(()=>{
                if(complexityLevel) updatePatientComplexity({ memberId: memberId, level, source });
                message.success('Patient added to remote enrollment');
                if(refetchRemoteEnrollment) {
                    refetchRemoteEnrollment();
                }
                if(refetch) {
                    refetch();
                }
            });
        })
    }

    onCheckboxChange = (checkedValue) => {
        getPatientComplexityByConditions({conditions: checkedValue}).then((res) => {
            const complexityLevel = _.get(res, 'data.getPatientComplexityByConditions')
            this.setComplexityLevel(complexityLevel);
        })
    }

    renderRadioBox = ()=>{
       return <Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
        </Group>
    }

    renderCheckBoxGroup = ()=>{
        const options = dataHelper.conditionsList.map(k=>({ label:k, value: k }));
        return <Checkbox.Group options={ options } onChange={this.onCheckboxChange}/>;
    }

    render() {
        return this.renderForm();
    }
}

CreateRemoteEnrollmentComponent = Form.create()(CreateRemoteEnrollmentComponent);

export default compose(
    withComplexityWithMemberFunc(ownProps => _.get(ownProps, 'memberId'))
)(CreateRemoteEnrollmentComponent);
// export default CreateRemoteEnrollmentComponent;