import gql from 'graphql-tag';

export default gql `
  mutation chooseLoginOrganization($ref:Ref!){
    chooseLoginOrganization(ref:$ref){
      id
      username
      sessionToken
      userSettings{
        sendHeartbeatLog
        snapshotInterval
        heartbeatInterval
        notificationFreq
      }
      role {
        refId
        name
        category
        organization {
          id
          name
          internalName
          leaf
          premiumClinic
          timezone
          templateGroups {
            id
            templates {
              id
              language {
                code
                description
              }
            }
          }
        }
        access {
          action
          rule
        }
      }
      globalConfigs {
        configKey
        value
      }
      orgPermissions {
        analyticsAPI
        enableABTesting
        pinnedToEMRRequired
        supportLoanerPhone
        supportClear
        keepOwnDevices
        otherCCMProvider
      }
      appSettings {
        parent {
          id
          leaf
          name
        }
        group{
          id
          leaf
          name
        }
        role {
          refId
          name
          category
        }
      }
    }
  }
`
