import {
    React,
    BaseComponent,
    ND,
    _
} from './util'
import PropTypes from 'prop-types';

let LOCALE = {
    filterConfirm : 'OK',
    filterReset : 'Reset',
    emptyText : ''
}

const Component = class extends BaseComponent{
    init(){
        this.LOCALE = _.clone(LOCALE)
    }

    defineBaseClassName(){
        return this.props.onRow ? 'IH-Table ih-cursor' : 'IH-Table'
    }

    render(){
        const props = _.omit(this.props, ['noDataElem'])
        if(this.props.noDataElem && !this.props.loading){
            this.LOCALE.emptyText = this.props.noDataElem
        }

        const pagination = props.pagination || {}
        const dataLength = pagination.total || _.get(props.dataSource, 'length')
        if(pagination && dataLength <= (pagination.pageSize||10)){
            props.pagination = false
        }

        const p = this.getClassAndStyleProp()
        return (
            <div {...p}>
                <ND.Table size="normal" bordered={false} locale={this.LOCALE} {...props} />
            </div>
        )
    }

}

Component.propsType = {
    noDataElem : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.array
    ])
}

export default Component
