import React from 'react';
// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers';
import { openModal } from 'modulesAll/layout/actions/MainModal';
import EditProviderEmail from 'providerModule/ProviderEdit/containers/ProviderEditEmailContainer';
import EditProviderPhone from 'providerModule/ProviderEdit/containers/ProviderEditPhoneContainer';
import EditProviderPhoto from 'providerModule/ProviderEdit/containers/ProviderEditPhotoContainer';
// Reducer
import { commonReducer, commonInitialState, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from 'providerModule/ProviderEdit/components/formData/ProviderEditForm'

/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('Provider', 'ProviderEdit', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'UPDATE_FORM',
  'SET_SUBMITTING'
]);

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
]);

export const { resetForm, updateForm, onFieldsChange, handleFormSaved, setSubmitting } = actionCreators;

// Thunks
export const openEditEmailModal = (user) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Email Address',
      showHeaderCloseButton: true,
    }
    dispatch(openModal(<EditProviderEmail userData={user} />, modalProps))
  }
}

export const openEditPhoneModal = (user) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Mobile Number',
      showHeaderCloseButton: true,
    }
    dispatch(openModal(<EditProviderPhone userData={user} />, modalProps))
  }
}

export const openEditPhotoModal=(user)=>{
  return (dispatch,getState)=>{
    const modalProps={
      title:'Edit Photo',
      showHeaderCloseButton:true,
    }
    dispatch(openModal(<EditProviderPhoto user={user}
      closeModal={() => dispatch(closeModal())}
    />,modalProps));
  }
}

/*
**************************************************
  Reducer
**************************************************
*/
// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initialState = Object.assign(commonInitialState(actionTypes), {
  initData,
  fieldsValue: initFieldsValue,
  isFieldsRequired,
  isFormSaved: true,
  isSubmitting: false,
})

const ownReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_FORM:
      return initialState
    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, actionTypes)
}
