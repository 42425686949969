import React from 'react'
import _ from 'lodash'
import { createSelector } from 'reselect'

export const addDispatch = ({...actions}, dispatch) => {
  let dispatchActions = {}
  _.map(actions, (action, key) => {
    if(typeof action === 'function') {
      dispatchActions[key] = (...args) => dispatch(action(...args))
    }
    else if(!!_.get(action, 'type')) {
      dispatchActions[key] = () => dispatch(action)
    }
    else {
      dispatchActions[key] = action
    }
  })
  return dispatchActions
}

export const addTransform = (dataSource, props) => {
  const { sorter, searchText, searchFields } = props
  const pattern = new RegExp(searchText, 'i');
  let transformed = dataSource
  if((sorter && sorter.order)) {
    const sortOrder = sorter.order === 'descend' ? 'desc' : 'asc';
    transformed = _.orderBy(transformed, [sorter.field], [sortOrder])
  }
  if(searchText && searchFields) {
    transformed = transformed.filter( Program => {
      return searchFields.some(field => pattern.test(_.get(Program, field)));
    })
  }
  return transformed
}

//use reselect to avoid unnecessary computation in mapStateToProps
export const getDataSource = ({stateName, dataSource, searchFields}) => {
  const sorterSelector = state => _.get(state, `${stateName}.sorter`)
  const textSelector = state => _.get(state, `${stateName}.searchText`)
  return createSelector(
    sorterSelector,
    textSelector,
    (sorter, searchText) => addTransform(dataSource, {sorter, searchText, searchFields})
  )
}

export const CommonContainer = class extends React.Component {
  componentWillUnmount() {
    this.props.reset()
  }
}
