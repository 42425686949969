import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
// import editMemberProfile from 'graphqlModule/mutation/editMemberProfile'
import editUser from 'graphqlModule/mutation/editUser';
import { goPath, FormHelper, convertToFieldsValue, getRouterParam, setAsyncRouteLeaveHook, GQLHelper } from 'libModule/utils'
import { IHBaseFormWithRow, IHButton, message } from 'ihcomponent'
import { getFormProps } from 'patientModule/profileEdit/components/formData'
import actions from 'patientModule/profileEdit/actions'
import moment from 'moment'
import { modalAction } from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import UserClass from 'modulesAll/graphql/class/User'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import i18nMap from '../../../I18N/index';
import convertor from '@ihealth/convertor';
import Client from 'libModule/gqlClient';
import userGQL from 'graphqlModule/user'
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import yearOfDiagnoseMap from "../../../patientList/registerNew/constants/yearOfDiagnose";
import { DATE_HELPERS } from "../../../../lib/helpers/date-helpers";
import '../css/firstLastInput.scss';
import { schema as PatientSchema } from '../../../patientList/registerNew/components/formData/AddNewPatientForm';
class Container extends Component {
  static displayName = 'patient/profileEdit/containers/profileEditContainer'

  componentWillMount() {
    setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))
    const { userData, updateForm } = this.props
    const initData = this.convertUserDataToFormData(userData)
    const fieldsValue = convertToFieldsValue(initData)
    updateForm(fieldsValue)
  }
  componentWillUnmount() {

    this.props.resetForm()
  }

  componentDidMount() {
    const user = this.props.userData;
    if (user) {
      this.props.setBreadCrumb(`Patients / ${user.profile.fullName} / Edit`)
    }
  }

  componentDidUpdate() {
    const user = this.props.userData
    if (user) {
      this.props.setBreadCrumb(`Patients / ${user.profile.fullName} / Edit`)
    }
  }
  routerWillLeave() {
    const { openConfirmModal } = this.props
    const submit = this.submit.bind(this)
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      openConfirmModal(resolve, submit)
    })
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()
    return (
      <div className='vsm-user-edit-page'>
        <div className='user-edit-form v-form-required'>
          {body}
          {footer}
        </div>
      </div>
    )
  }
  convertUserDataToFormData() {
    const { userData } = this.props
    const userClassData = new UserClass(userData)
    // setPagePath(`Patients / ${userData.profile.fullName} / Edit Profile`)
    const { role, username } = userData

    const address = userData.address && userData.address[0]
    const addressForForm = _.clone(address)
    if (addressForForm) {
      addressForForm.country = _.get(addressForForm, 'country.code')
    }
    const { firstName, lastName, nickname, gender, birthday, weight, height, insuranceProvider } = _.get(userData, 'profile')
    const nationality = _.get(userData, 'profile.nationality.code')
    // const nationtype = _.get(userData.identification[0], 'type.code')
    const nric = _.get(userData.identification[0], 'value')
    const language = _.get(userData, 'profile.language.code')
    const race = _.get(userData, 'profile.race.code')
    const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : '';
    const yearOfDiagnose = _.get(userData, 'profile.yearOfDiagnose');
    const doctorName = _.get(userData, 'profile.doctor.profile.fullName');
    const doctorId = _.get(userData, 'profile.doctor.id');
    const techLevel = _.get(userData, 'profile.techLevel',null);
    const motivation = _.get(userData, 'profile.motivation',null);
    const initData = {
      nric,
      doctorName,
      DOB: moment(birthday),
      firstName,
      lastName,
      nickname,
      gender: gender === 'M' ? 'Male' : 'Female',
      weight: weight ? convertor.HS(weight.value, 'kg', 'lbs', 0) : '',
      height: height ? convertInchesToFeet(heightInInches) : '',
      height_inches: height ? getRemainderAfterConvertingToFeet(heightInInches) : '',
      role,
      email: userClassData.variables.email.length > 0 ? userClassData.variables.email[0].address : '',
      ...addressForForm,
      race,
      language,
      nationality,
      username,
      insuranceProvider,
      yearOfDiagnose,
      doctorId,
      techLevel,
      motivation
    }

    if (userClassData.variables.phone.length > 0) {
      const mobilePhone = userClassData.variables.phone.find(val => { return val.type === 'MOBILE' }) || {};
      const homePhone = userClassData.variables.phone.find(val => { return val.type === 'HOME' }) || {};

      initData.mobilePhone = mobilePhone.number;
      initData.mobileCountryCode = mobilePhone.countryCode;

      initData.homePhone = homePhone.number;
      initData.homeCountryCode = homePhone.countryCode;
    }
    if (userClassData.variables.profile.healthConditions && userClassData.variables.profile.healthConditions.length > 0) {
      initData.healthConditions = userClassData.variables.profile.healthConditions;
    }
    else {
      initData.healthConditions = [];
    }
    return initData;
  }
  renderBody() {
    const { onFieldsChange, fieldsValue, userData, state } = this.props;
    const userClassData = new UserClass(userData);
    const props = this.props;
    if (!fieldsValue) return;
    const user = userClassData.variables;
    let formProps = getFormProps({ onFieldsChange, fieldsValue, user, props });

    return <div id='profile-edit-container'>
      <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
    </div>
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick: (e) => {
          goPath(`/patients/profile/${this.props.id}`)
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick: (e) => {
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save',
        style: {
          marginRight: 10
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} />
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  parseYearOfDiagnose(data) {
    let yearOfDiagnoseUpdated = [];
    const keys = Object.keys(yearOfDiagnoseMap);
    _.forEach(keys, (k) => {
      const key = data[`${k}`];
      if (key && key !== null) {
        yearOfDiagnoseUpdated.push({
          condition: yearOfDiagnoseMap[k],
          diagnosedDate: key
        })
      }
      if (typeof key == 'undefined') {
        const unchangedItem = data.yearOfDiagnose.filter(item => item.condition == yearOfDiagnoseMap[k]);
        if (unchangedItem.length > 0) {
          yearOfDiagnoseUpdated.push({
            condition: unchangedItem[0].condition,
            diagnosedDate: unchangedItem[0].diagnosedDate
          });
        }
      }
    });
    return yearOfDiagnoseUpdated;
  }
  getMutateVariable(data) {
    let memberProfile = {}
    let commonProfile = {};
    let address = null;
    const user = this.props.userData;
    const identificationSrc = user.identification;
    const { nric, username } = data;
    if (data.height || data.height_inches) {
      const feetToInches = Number(convertFeetToInches(data.height));
      const convertedHeight = convertor.height(feetToInches + Number(data.height_inches), 'inch', 'cm');
      memberProfile.height = { value: convertedHeight, unit: i18nMap.get('measure.units_height_cm') }
    }
    else {
      memberProfile.height = null;
    }
    if (data.weight) {
      const convertedWeight = convertor.HS(data.weight, 'lbs', 'kg');
      memberProfile.weight = { value: convertedWeight, unit: i18nMap.get('measure.units_weight_kg') }
    }
    else {
      memberProfile.weight = null;
    }
    if (data.race) {
      memberProfile.race = data.race
    }
    if (data.language) {
      memberProfile.language = data.language
    }
    if (data.nationality) {
      memberProfile.nationality = data.nationality
    }
    if (data.insuranceProvider || data.insuranceProvider.trim() == '') {
      memberProfile.insuranceProvider = data.insuranceProvider;
    }
    if (data.firstName) {
      commonProfile.firstName = data.firstName.charAt(0).toUpperCase() + data.firstName.substring(1)
    }
    if (data.lastName) {
      commonProfile.lastName = data.lastName.charAt(0).toUpperCase() + data.lastName.substring(1)
    }
    if (data.nickname) {
      commonProfile.nickname = data.nickname
    }
    if (data.DOB) {
      memberProfile.birthday = moment(data.DOB).format('YYYY-MM-DD')
    }
    if (data.healthConditions) {
      memberProfile.healthConditions = data.healthConditions;
    }
    if (data.gender) {
      commonProfile.gender = data.gender
    }
    if (data.doctorId) {
      memberProfile.doctorId = data.doctorId;
    }
    if (data.techLevel) {
      memberProfile.techLevel = data.techLevel;
    }
    if (data.motivation) {
      memberProfile.motivation = data.motivation;
    }
    const yearOfDiagnoseUpdated = this.parseYearOfDiagnose(data);

    memberProfile.yearOfDiagnose = yearOfDiagnoseUpdated;


    if (data.streetName || data.streetNumber || data.postCode || data.city || data.state) {
      address = {
        streetName: data.streetName,
        streetNumber: data.streetNumber,
        country: data.country,
        postCode: data.postCode,
        city: data.city,
        state: data.state
      }
    }
    if (data.floor) {
      address.floor = data.floor
    }
    if (data.unit) {
      address.unit = data.unit
    }

    const email = data.email ? data.email.trim() : [];
    let phone = data.phone;
    // if (data.homePhone) {
    //   phone.push({
    //     number: data.homePhone && data.homePhone.trim(),
    //     type: 'HOME',
    //     countryCode: data.homeCountryCode && data.homeCountryCode.trim()
    //   });
    // }
    // if (data.mobilePhone) {
    //   phone.push({
    //     type: 'MOBILE',
    //     number: data.mobilePhone && data.mobilePhone.trim(),
    //     countryCode: data.mobileCountryCode && data.mobileCountryCode.trim()
    //   });
    // }

    let identification = {};

    if (nric) {
      let src = identificationSrc[0];
      identification = {
        type: _.get(src, 'type.code') || _.get(PatientSchema, 'nationtype.initialValue') || 'SP',
        value: nric
      }
    }

    const result = {
      id: this.props.id,
      email,
      address,
      phone,
      memberProfile,
      commonProfile,
      identification,
      username: username
    }

    return result
  }

  async submit() {
    const { mutate, openErrorModal, handleFormSaved, fieldsValue } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    const user = this.props.userData
    const patientNRIC = _.get(user, 'identification[0].value')
    const patientName = _.get(user, 'profile.fullName')
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) return
    const mutatedValue = this.getMutateVariable(formValue);
    const variables = DATE_HELPERS.trimAllValues(mutatedValue, '', mutatedValue);

    try {
      await handleFormSaved()
      const res = await mutate({ variables })

      if (res.data.editUser) {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.editProfile'),
          patientNRIC,
          patientName,
          details: _.omit(variables, 'id'),
          request: variables,
          response: res
        })
        message.success('Edit patient profile success')

        // 2/26/18 - JV - Update user data after health conditions are added (IH-286)
        const variables = {
          id: this.props.id
        };
        Client.query({
          query: userGQL,
          variables: { ...variables },
          fetchPolicy: 'network-only'
        })
          .then(res => {
            goPath(`/patients/profile/${this.props.id}`);
          })
          .catch(err => console.log('Error user query: ', err))
          .finally(() => goPath(`/patients/profile/${this.props.id}`));
      }
    } catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.editProfile'),
        patientNRIC,
        patientName,
        details: _.omit(variables, 'id'),
        request: variables,
        response: err,
        success: false
      })
      openErrorModal(GQLHelper.formatError(err))
    }
  }
}
Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(editUser)(Container)

const mapState = (state, ownProps) => {
  const id = getRouterParam(state, 'patientId')
  return {
    ...state.patient.profileEdit,
    id
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    updateForm: (initData) => dispatch(actions.updateForm(initData)),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openConfirmModal: (resolve, submit) => dispatch(modalAction.openConfirmModal(resolve, submit)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    resetForm: () => dispatch(actions.resetForm())
  }
}

Container.propTypes = {
  id: PropTypes.string,
  userData: PropTypes.object,
  onFieldsChange: PropTypes.func,
  updateForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  route: PropTypes.object,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  isFieldsRequired: PropTypes.object,
  isFormValid: PropTypes.bool,
  isFormSaved: PropTypes.bool,
  setBreadCrumb: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openErrorModal: PropTypes.func,
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
