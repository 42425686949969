import type from '../constants/reduxTypes';

export default{
  setSelectedTab: tab => ({
    type: type.SET_SELECTED_TAB_KEY,
    tab
  }),

  setSelectedRange: ({ from, to }) => ({
    type: type.SET_SELECTED_RANGE,
    from,
    to
  }),

  setSelectedPageInfo: pageInfo=>({
    type: type.SET_SELECTED_PAGEINFO,
    pageInfo
  }),

  setSelectedAdvancedFilters: advancedFilters => ({
    type: type.SET_SELECTED_ADVANCED_FILTERS,
    advancedFilters
  }),

  setNameFilterValue: nameFilterValue => ({
    type: type.SET_NAME_FILTER_VALUE,
    nameFilterValue
  })
};
