import Client from '../gqlClient';
import gql from 'graphql-tag';

// enrolledProgramList query should accept count and page
const DEFAULT_MINI_EPLIST_QUERY = gql`
  query getMostRecentEP($count: Int, $page: Int, $filters: EnrolledListOpts) {
    enrolledProgramList(count: $count, page: $page, filters: $filters) {
      data {
        id
        status
      }
    }
  }
`;
export const getMostRecentEP = (
  memberId, enrolledProgramListQuery = DEFAULT_MINI_EPLIST_QUERY, queryOpts = {}
) => {
  return Client.query({
    query: enrolledProgramListQuery,
    variables: {
      count: 1,
      page: 1,
      filters: { memberId },
      // sort DESC by default
    },
    fetchPolicy: 'network-only',
    ...queryOpts
  });
}