import React from 'react';
import { Icon,Tooltip } from 'antd';
const MentorRDIconComponent = (props)=>{
	let title = `Mentor RD will be responsible for
reviewing the work done by 
Health Coach (HC) without credentials.`;
	return <Tooltip title={title} >
		<Icon type="info-circle" style={{ alignSelf:'center',marginLeft: 5 }} theme='filled' />
	</Tooltip>
}
export default MentorRDIconComponent;