import { React, BaseComponent, _ } from './util';
import PropTypes from 'prop-types';

const Component = class extends BaseComponent {

  render() {
    const sy = this.props.style || {};
    return (
      <div style={sy} className="IH-Panel">
        {this.renderPanelHeader()}
        {this.renderPanelBody()}
        {this.renderPanelFooter()}
      </div>
    );
  }

  renderPanelHeader() {
    const p = this.props.header;

    if (_.isObject(p) && p.title) {
      return (
        <dt className="ih-panel-header">
          <span className="ih-title">{p.title}</span>
          <div className="ih-right">{p.right || null}</div>
        </dt>
      );
    }

    return (
      <dt className="ih-panel-header ih-panel-header-elem">
        {p}
      </dt>
    );
  }

  renderPanelBody() {
    const style = this.props.bodyStyle || {};
    const hasFooter = !!this.props.footer;

    return (
      <div
        className={`ih-panel-body${hasFooter ? '' : ' footer-bottom'}`}
        style={style}
      >
        {this.props.body}
      </div>
    );
  }

  renderPanelFooter() {
    if (!this.props.footer) {
      return null;
    }

    return (
      <dd className="ih-panel-footer footer-bottom">{this.props.footer}</dd>
    );
  }
};

Component.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
  ]),
  body: PropTypes.element.isRequired,
  bodyStyle: PropTypes.object,
};

export default Component;
