import {
    React, IHButton
} from 'ihcomponent'
import OrganizationList from '../component/organizationListComponent'
import createTableAction from 'libModule/table/TableActions'
import organizationListGQL from '../query/organizationList'
import { graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import _ from 'lodash'


const tableActions = createTableAction('organization_list', {
  shouldUpdateHistory: false,
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    modifiedAt: 'MODIFIED_AT',
    careTeam: 'NAME'
  },
})

const Component = (props)=>{
    const buttonProps = {
        label : 'Create New Organization',
        size : '',
        type : 'primary',
        onClick : ()=>{
          browserHistory.push(`/superadmin/organizations/Create`)
        }
    }

    return (
        <div className="vsm-main-page vsm-patientList-main">
            <div className="vsm-main-990">
                <div className="v-rd">
                    <IHButton {...buttonProps} />
                </div>
                <div className="v-table">
                    <OrganizationList {...props} />
                </div>
            </div>
        </div>
    )
}

Component.displayName = 'superadmin/organizations'

const withData = graphql(organizationListGQL, {
  options : (ownProps)=>{
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      filters: {
        search: {
          fields: ['NAME'],
          match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
        }
      },
      search: {
        fields: ['NAME'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: null,
    }

    const sortField = _.get(ownProps, 'sort.fieldGraph', null)
    if (sortField) {
      variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'ASC'),
      }
    }

    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props : ({data})=>{
    return {
      list : data.organizationList,
      loading : data.loading,
      refetch : data.refetch
    }
  }
})

export default compose(
  connect(
    (state)=>{
        return {
          ...state.globalTables.organization_list
        }
    },
    (dispatch)=>{
        return {
            ...tableActions
        }
      }
    ),
 withData
)(Component)
