import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IHLoading } from 'ihcomponent';
import moment from 'moment';
import I18N from '../../I18N';

import Client from 'libModule/gqlClient';
import getPatientConsentGQL from '../../patient/profileDetail/query/GetpatientConsent';

import { CONSENT_TYPE } from '../../patient/main/constants';
import parse from 'html-react-parser';
import '../style/reConsentFormPreview.scss';

const checkIfPaperConsent = consentType => consentType === CONSENT_TYPE.PAPER;

const ConsentFormPreview = forwardRef((props, ref) => {
  const { 
    memberId,
    consentInfo,
    setHeader
  } = props;
  const { consent, consentType, fileUrl, content, effectiveDate, providers } = consentInfo;
  const isPaperConsent = checkIfPaperConsent(consentType);
  const [pdfViewUrl, setPDFViewURL] = useState(null);

  const getPDFViewURL = async () => {
    let newFileUrl = fileUrl;
    // consent flag changed in real-time, fetching for link
    if(!newFileUrl) { 
      try {
        const res = await Client.query({ 
          query: getPatientConsentGQL,
          variables: { memberId },
          fetchPolicy: 'network-only'
        });

        newFileUrl = _.get(res, 'data.getPatientConsent.fileUrl');
      } catch (e) {
        console.error(e);
        // don't need to notify user about error
      }
    }
      

    return new Promise((resolve, reject) => {
      if(!newFileUrl)
        return reject('No fileUrl found');
      if(pdfViewUrl) 
        return resolve(pdfViewUrl);

      const xhttp = new XMLHttpRequest();
      function onDataReceived() {
        if(xhttp.readyState === XMLHttpRequest.DONE) {
          if (xhttp.status === 0 || (xhttp.status >= 200 && xhttp.status < 400)) {
            const blob = new Blob([xhttp.response], { type: 'application/pdf' });
            return resolve(window.URL.createObjectURL(blob));
          } else {
            return reject('Failed to fetch file');
          }
        }
      }
      xhttp.onreadystatechange = onDataReceived;
      xhttp.open('GET', newFileUrl, true);
      xhttp.responseType = 'arraybuffer';
      xhttp.send();
    });
  }

  useEffect(() => {
    if(!isPaperConsent) {
      getPDFViewURL().then(url => {
        setPDFViewURL(url);
      }).catch(err => {
        console.error(err);
        setPDFViewURL(false);
      }).finally(() => {
        if(!consent && consentType === CONSENT_TYPE.APP)
          return setHeader('Patient hasn\'t signed APP consent form.');
        setHeader(null);
      });
    } else {
      return setHeader('Paper consent form signed.');
    }
  }, [consent]);

  const renderPaperPreview = () => {
    return (
      <React.Fragment>
        {
          providers && providers.length ?
          <div className='providers-info'>
            <span>Providers</span>
            <ul>
              { _.map(providers, p => <li key={p.id}>{p.name || '--'}</li>) }
            </ul>
          </div>
          : null
        }
        {
          effectiveDate && 
          <div className='date-info'>
            <span>{I18N.get('consentForm.label.paper.consentDate')}</span>
            {moment(effectiveDate).format('MM/DD/YYYY')}
          </div>
        }
      </React.Fragment>
    );
  };

  return (
    <div id='refer-enroll-consent-form-preview'>
      {
        isPaperConsent ?
          renderPaperPreview()
        :
          <div>
            {
              pdfViewUrl === null ?
                <IHLoading />
              : pdfViewUrl === false ?
                  <div className='consent-form-content'>
                    {content ? parse(content) : ''}
                  </div>
                :
                  <iframe 
                    src={pdfViewUrl} 
                    style={{ 
                      width: '100%', 
                      height: '260vh', 
                      border: 'none'
                    }}
                  >
                    {/* Fallback when unsupported */}
                    <div>Unsupported browser</div>
                  </iframe>
            }
          </div>
      }
    </div>
  );
});

ConsentFormPreview.propTypes = {
  memberId: PropTypes.string.isRequired,
  shouldShowIcon: PropTypes.bool,
  iconProps: PropTypes.object,
  consentType: PropTypes.string,
  fileUrl: PropTypes.string,
  viewText: PropTypes.string,
  viewTextStyles: PropTypes.object,
};

export default ConsentFormPreview;