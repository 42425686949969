import { React,IHLoading } from 'ihcomponent';
import PropTypes from 'prop-types';
import CareTeamContainer from './CareTeamContainer';
import '../../style/careTeam.styles.scss';
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { defaultTeamVariables } from '../../Clinic/containers/ClinicMemberContainer';
// import Draggable from 'react-draggable';
// import $ from 'jquery';

// const Context = React.createContext();

// const DraggableRow = (props) => {
//   console.log('props', props);
//   return <Context.Provider value={props.form}>
//     <Draggable 
//       axis='y' 
//       onStop={(e, data) => {
//         console.log('data', data);
//       }}
//       handle='.handle'
//     >
//       <tr {...props} />
//     </Draggable>
//   </Context.Provider>
// }

// const DraggableRowForm = Form.create()(DraggableRow);

// const CustomCell = props => {
//   console.log('props cell', props);
//   return <td {...props}>{props.children}</td>
  
// }

const CareTeamContainerWrapper = props => {
  const {
    careTeamList = [], openAddModal, organization = {}, loadingTeamList,teamListPageInfo,
    needRefetchTeamList, updateNeedRefetchTeamList, refetchList,teamPage,fetchTeamList,setTeamPage,
  } = props;
  const { total,lastPage } = teamListPageInfo;
  let hasMoreTeam = careTeamList.length < total;
  const fetchMoreTeam = async ()=>{
    if(loadingTeamList) return;
    let variables = {
      ...defaultTeamVariables,
      page: teamPage+1,
      filters: {
        organizationId: _.get(props, 'organization.id')
      }
    }
    await fetchTeamList(variables);
    setTeamPage(teamPage+1);
  }
  if(needRefetchTeamList) {
    // stop trigger refetch after edit role or remove member
    updateNeedRefetchTeamList(false);
  };

  return <InfiniteScroll loadMore={fetchMoreTeam} hasMore={hasMoreTeam} loader={<IHLoading/>} initialLoad={false}>
  <div id='careteam-container-wrapper'>
        <CareTeamContainer
          organization={organization}
          careTeamList={careTeamList}
          openAddModal={openAddModal}
        />
      </div>
  </InfiniteScroll>
};

CareTeamContainerWrapper.displayName = 'newAdmin/container/CareTeam/index';

CareTeamContainerWrapper.propTypes = {
  loadingCareTeamList: PropTypes.bool,
  careTeamList: PropTypes.array,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  openAddModal: PropTypes.func.isRequired
};

export default CareTeamContainerWrapper;