import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, _ } from 'ihcomponent';
import { formatSchedule, hasMeasurement, renderVitalType } from 'modulesAll/utils/helpers/task';
import I18N from 'modulesAll/I18N'

export default class PatientUpcomingTaskListTableComponent extends React.Component {
  static displayName = 'PatientUpcomingTaskListTableComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }
  getTableProps() {
    const { addReading, programFilters, onTableChange, data, dataSource, vitalFilters } = this.props;
    const { loading = true } = data;

    return {
      title: I18N.get('tableTitle.Upcoming Programme Tasks'),
      rowKey: 'none',
      onChange: onTableChange,
      dataSource,
      pagination: {
        pageSize:5
      },
      loading,
      columns: [
        {
          title: 'Status',
          key: 'taskStatus',
          dataIndex: 'taskStatus',
          render: (item, row) => item === 'CURRENT' ? 'Upcoming' : item.toTitleCase(),
        },
        {
          title: 'Schedule',
          key: 'schedule',
          dataIndex: 'schedule',
          render: (item, row) => formatSchedule(row),
        },
        {
          title: 'Type',
          key: 'type',
          dataIndex: 'type',
          render: renderVitalType,
          filters: vitalFilters || [],
          onFilter : (a, doc)=>{
              return a === doc.type
          }
        },
        {
          title: 'Actions',
          key: 'measure',
          dataIndex: 'measure',
          render: (item, row) => {
            // Types of task status: CURRENT, UPCOMING, MISSED, COMPLETED, INCOMPLETE
            if (row.taskStatus === 'CURRENT' || row.taskStatus === 'INCOMPLETE') {
              return  (<div className="add-reading" onClick={addReading.bind(this, row)}>
                        <span>Add</span>
                       </div>)
            }
            else {
              return  (<div className="add-upcoming-reading">
                        <span>Add</span>
                       </div>)
            }
          }
        },
        {
          title: I18N.get('keywords.Program'),
          key: 'enrolledProgram.name',
          dataIndex: 'enrolledProgram.name',
          filters: programFilters || [],
          onFilter : (a, doc)=>{
              return a === doc.enrolledProgram.id
          }
        },
      ]
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>Congrats, no more tasks to do today!</p>
      </div>
    )

    return (
      <div className="patient-task-table">
        <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
      </div>
    );
  }
}

PatientUpcomingTaskListTableComponent.propTypes = {
  onLoadParams: PropTypes.func,
  addReading: PropTypes.func,
  onTableChange: PropTypes.func,
  programFilters: PropTypes.array,
  dataSource: PropTypes.array,
  data: PropTypes.object,
  vitalFilters: PropTypes.array,
}
