import React from 'react';

const removeContent = "Are you sure you want to remove this ICD code? This will not remove any 'expected outcome' goal entered for this condition. Please review the goals section to ensure that the goals and conditions still apply.";
const unselectContent = "Are you sure you want to make this code not billable?This will not remove the ICD code or the 'expected outcome' goal. Please review the goals section to ensure that the goals and conditions still apply.";

export default {
    removeICDcode:{
        content: removeContent,
        title: ''
    },
    unselectBillable: {
        content: unselectContent,
        title: 'Are you sure to unmark this ICD-10 code as billable?',
    },
    note:'If a condition is selected as billable, then the RD will be expected to apply a clinical goal for this condition in Goals section.',
    noDiagnosisNote: {
      patientHeader: `Patient's condition is not added yet, please add in care plan.`,
      healthConditionSection: `No ICD-10 code has been selected as billable thus patient's Condition Complexity Level can't be defined.`,
    }
}
