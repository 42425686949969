import Base from './Base'
import { _, moment } from 'ihcomponent'
import { FormatHelper, RequestCache } from 'libModule/utils'
import I18N from 'modulesAll/I18N'
import convertor from '@ihealth/convertor';
import Client from 'libModule/gqlClient';
import { GQLHelper, encryptText, decryptRole } from 'libModule/utils';
import Store from 'libModule/reduxStore';
import { userForSessionStorage } from 'modulesAll/graphql/user'
import { enrolledProgramListMinimal as enrolledProgramList } from 'modulesAll/graphql/enrolledProgramList'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { DEFAULT_NOTE_PRESET } from 'modulesAll/utils/constants/task'
import { convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import BMIStatusMap from 'modulesAll/patient/main/constants/BMIStatusMap.js';
import React from 'react';
import { ROLE_MAP, NotiDefaultValMap } from 'libModule/constants';
import { getBMR,getABW,getWeightsInRange,getAverageWeight } from '../../utils/helpers/index';
import { getProviderName } from '../../../lib/utils';
import { INITIAL_EP_FETCH_ROLES } from '../../utils/constants/role';

const filterDoctors = () => {
  const teams = JSON.parse(sessionStorage.getItem('currentUser')) && JSON.parse(sessionStorage.getItem('currentUser')).team;
  let doctors = [];
  for (let index in teams) {
    const members = teams[index].members;
    for (let memberId in members) {
      const member = members[memberId];
      const allRoles = _.get(member, 'user.allRoles', []);
      const hasDoctorRole = _.filter(allRoles, a => a.name == 'Doctor').length != 0;
      const { profile, id } = member.user;
      if (hasDoctorRole) {
        const doctor = {
          id: id,
          fullName: profile.fullName,
          primary: true,
          profile
        }
        if (!_.find(doctors, { id: id }))
          doctors.push(doctor);
      }
    }
  }
  return doctors;
}

export default class extends Base {
  format(type, param = false) {
    switch (type) {
      case 'nric':
      case 'NRIC':
        return this._getNRIC()
      case 'PRN':
        return this._getPRN()
      case 'DL':
      case 'dl':
        return this._getDL()
      case 'avator':
        return this._getAvator()
      case 'age':
        return this._getAge();
      case 'tag':
        return this._getTag()
      case 'bodyInfo':
        return this._getbodyInfo()
      case 'healthConditions':
        return this._getHealthConditions()
      case 'address':
        return this._getAddress()
      case 'role':
        return this._getRole()
      case 'department':
        return this._getDepartment()
      case 'institution':
        return this._getInstitution()
      case 'specialty':
        return this._getSpecialty()
      case 'employeeId':
        return this._getEmployeeId()
      case 'registeredAt':
        return this._getRegisteredAt()
      case 'mobilePhone':
        const mobile = _.find(this._get('phone'), (item) => item.type === 'MOBILE')
        return this._getPhone(mobile)
      case 'homePhone':
        const home = _.find(this._get('phone'), (item) => item.type === 'HOME')
        return this._getPhone(home)

      case 'email':
        if (!param) {
          return this._get('email.0.address')
        }
        const emails = _.map(this._get('email'), (email) => email.address)
        return emails.join(' | ')

      case 'gender':
        return this._getGender(param)
      case 'dob':
        return this._getDOB();
      case 'techLevel':
        return this._getTechLevel(param)
      case 'motivation':
        return this._getMotivation(param)
      case 'star':
        return this._getStar(param)

      case 'emailNotification':
        return this._getNotificationEmail(param)

      case 'smsNotification':
        return this._getNotificationSMS(param)
      case 'loginID':
        return this._getLoginID()
      case 'CCM/RPM':
        return this._getCCMRPM(param);
      case 'enrolledProgramCategories':
        return this._getProgramCategories(param);
      case 'vitalType':
        return this._getVitalTypes(param);
      case 'enrolledDate':
        return this._getEnrolledDate();
      case 'language':
        return this._getLanguage();
      case 'appLanguage':
        return this._getAppLanguage();
      case 'MRN':
        return this._getMRN();
      case 'PCP':
        return this._getPCP();
      case 'subscription':
        return this._getSubscription();
      default:
        return super.format(type, param)
    }

  }

  static async saveUserInfo({ sessionToken, id, role, appSettings, allRoles, lastLogIn, orgPermissions, globalConfigs,userSettings }) {
    const userRole = `${role.category}:${role.name}`;
    const encryptedUserRole = encryptText(sessionToken, userRole)

    sessionStorage.setItem('authToken', sessionToken)
    sessionStorage.setItem('currentUserId', id)
    sessionStorage.setItem('lastLogIn',_.get(lastLogIn,'createdAt',null));
    sessionStorage.setItem('currentUserRoles', encryptedUserRole)
    sessionStorage.setItem('currentUserConfigPermissions', JSON.stringify({ orgPermissions, globalConfigs }));

    const appRolesKV = {};
    _.get(appSettings, 'role', []).map(o => {
      appRolesKV[`${o.category}:${o.name}`] = o.refId;
    });
    sessionStorage.setItem('appRoles', JSON.stringify(appRolesKV));

    const accessRole = _.get(role, 'access').map(o => { return `${o.action}:${o.rule}` })
    const encryptedAccessRole = encryptText(sessionToken, JSON.stringify([userRole].concat(accessRole)))
    sessionStorage.setItem('userAccess', accessRole ? encryptedAccessRole : encryptedUserRole)

    // IH-1051, only set when user logs in
    if (allRoles) {
      sessionStorage.setItem('authAllRoles', JSON.stringify(allRoles));
    }

    try {
      const resUser = await Client.query({
        query: userForSessionStorage,
        variables: { id },
        fetchPolicy: 'network-only'
      })
      const org = resUser.data.user.allRoles.find(val => val.refId === role.refId).organization;
      const { notePreset=DEFAULT_NOTE_PRESET,logo,brandedName, programParticipation } = org;

      let templateLanguages = [];
      const templateGroups = _.get(role, 'organization.templateGroups') || [];
      for(let group of templateGroups) {
        const templates = group.templates || [];
        templateLanguages = templateLanguages.concat(_.map(templates, t => ({ groupId: t.id, ...t.language })));
        templateLanguages = _.uniqBy(templateLanguages, 'code');
      }

      const userWithSelectedRole = { // IH-1052 Use selectedRole instead of just getting first from allRoles
        ...resUser.data.user,
        selectedRole: !role ? null :
          { 
            ...role, 
            organization: { 
              ...role.organization, 
              notePreset, logo, brandedName, templateLanguages, programParticipation 
            } 
          }
      };
      const currentRole = ROLE_MAP[_.get(userWithSelectedRole, 'selectedRole.name')];
      const notiDefaultVaule = NotiDefaultValMap[currentRole] || 'NOTIFY_LESS';
      const notificationFreq = _.get(userSettings, 'notificationFreq') || notiDefaultVaule;
      sessionStorage.setItem('userSettings',JSON.stringify(userSettings));
      sessionStorage.setItem('notificationFreq',JSON.stringify(notificationFreq));

      sessionStorage.setItem('currentUser', JSON.stringify(userWithSelectedRole));
      const team = JSON.parse(sessionStorage.getItem('currentUser')).team;
      if (team) {
        sessionStorage.setItem('doctorName', JSON.stringify(filterDoctors(team)));
      }
      const presetNotes = _.get(userWithSelectedRole, 'selectedRole.organization.notePreset') || DEFAULT_NOTE_PRESET
      sessionStorage.setItem('presetNotes', JSON.stringify(presetNotes))
      await this.saveEPsForProvider()

      //TODO: need to store userRoles in session storage so that survive browser refresh


    }
    catch (err) {
      console.log(err)
      Store.dispatch(openErrorModal(GQLHelper.formatError(err)))
    }
  }

  static async checkRole({ role }) {
    return true
  }

  static async saveEPsForProvider() {
    const userId = sessionStorage.getItem('currentUserId') //plain text
    const currentUserRoles = decryptRole() //plain text

    //only get EPs if the logged in user is a provider
    if (_.includes(INITIAL_EP_FETCH_ROLES, currentUserRoles)) {
      try {
        const resEP = await Client.query({
          query: enrolledProgramList,
          variables: { filters: { managedBy: userId } },
          fetchPolicy: 'network-only' //for VS-1225
        });
        console.log('got data for enrolledProgramList ', resEP);
        const enrolledProgramListData = resEP.data.enrolledProgramList.data
        const enrolledProgramIdList = enrolledProgramListData.map((ep) => ep.id)
        const enrolledProgramActiveIdList = enrolledProgramListData.filter((ep) => {
          return ep.status !== 'DISCHARGED' && ep.status !== 'RETURNED'
        }).map((ep) => ep.id)
        sessionStorage.setItem('enrolledProgramIdList', JSON.stringify(enrolledProgramIdList))
        sessionStorage.setItem('enrolledProgramActiveIdList', JSON.stringify(enrolledProgramActiveIdList))
        const adminProgramList = _.uniqBy(enrolledProgramListData.map((ep) => ep.adminProgram), 'id')
        sessionStorage.setItem('adminProgramList', JSON.stringify(adminProgramList))
        const adminProgramMap = {}
        adminProgramList.map((ap) => {
          if (ap && ap.id) {
            adminProgramMap[ap.id] = enrolledProgramListData.filter((ep) => (ep && ep.adminProgram) ? ep.adminProgram.id === ap.id : false).map((ep) => ep.id)
          }
        })
        sessionStorage.setItem('adminProgramMap', JSON.stringify(adminProgramMap))
      }
      catch (err) {
        console.log(err)
        Store.dispatch(openErrorModal(GQLHelper.formatError(err)))
      }
    }
  }

  static getAdminProgramList() {
    return JSON.parse(sessionStorage.getItem('adminProgramList')) || []
  }

  static getAdminProgramIds() {
    return this.getAdminProgramList().map((ap) => ap ? ap.id : '')
  }

  static getAdminProgramFilter() {
    return this.getAdminProgramList().map((ap) => {
      return ap ? { value: ap.id, text: ap.name } : {}
    })
  }

  static getAdminProgramMap() {
    return JSON.parse(sessionStorage.getItem('adminProgramMap'))
  }

  static getEnrolledProgramIdList() {
    return JSON.parse(sessionStorage.getItem('enrolledProgramIdList'))
  }

  static getEnrolledProgramActiveIdList() {
    return JSON.parse(sessionStorage.getItem('enrolledProgramActiveIdList'))
  }

  static getTeamList() {
    return JSON.parse(sessionStorage.getItem('currentUser')).team || []
  }

  static getHealthConditionList() {
    return _.uniq(this.getTeamList().map((t) => t.healthCondition))
  }

  static getConfigPermissions() {
    const { orgPermissions, globalConfigs } = JSON.parse(sessionStorage.getItem('currentUserConfigPermissions') || '') || { orgPermissions: {}, globalConfigs: [] };
    return { orgPermissions: orgPermissions || {}, globalConfigs: globalConfigs || [] };
  }

  _getNRIC() {
    const { identification } = this.variables
    // const rs = _.find(identification, (item)=>(item.type==='NRIC'||item.type.description==='NRIC'))
    // if(rs){
    //   return rs.value
    // }
    const rs = _.get(identification, '0.value') || ''

    return rs

  }
  _getMRN() {
    const { identification } = this.variables
    const rs = _.find(identification, (item) => _.get(item, 'type.code') === 'SP' );
    if (rs) {
      return rs.value
    }
    return '';
  }
  _getPRN() {
    const { identification } = this.variables
    const rs = _.find(identification, (item) => (_.get(item, 'type.code') === 'PRN' || _.get(item, 'type.description') === 'PRN'))
    if (rs) {
      return rs.value
    }

    return ''

  }

  _getHeight(){

  }

  _getBMI(){

  }

  _getWeight(){


  }

  _getPCP() { // primary care provider
    const { profile: { doctor } = {} } = this.variables;
    return getProviderName(doctor);
  }

  _getVitalsWithInactiveStatus() {
    const { profile: { lastMeasuredByType } = {} } = this.variables;
    const vitalsWithStatus = {};
    _.map(_.keys(_.omit(lastMeasuredByType, '__typename')), type => {
      const latestRes = lastMeasuredByType[type];
      const withIn14Days = latestRes && moment().diff(latestRes,'days')<14;
      vitalsWithStatus[type] = { latestRes, inactive: !withIn14Days };
    });
    return vitalsWithStatus;
  }

  _getRegisteredAt() {
    const { registeredAt } = this.variables;
    return moment(registeredAt).format('MM/DD/YYYY')
  }
  _getDL() {
    const { identification } = this.variables
    const rs = _.find(identification, (item) => (_.get(item, 'type.code') === 'DL' || _.get(item, 'type.description') === 'Driving License'))
    if (rs) {
      return rs.value
    }

    return ''

  }
  _getAvator() {
    const { profile } = this.variables
    return profile && profile.avatar && profile.avatar.link ? profile.avatar.link : '/image/default_avator.png';
  }
  _getAge() {
    const dob = this._get('profile.birthday')
    if (!dob) return ''

    return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years')
  }
  _getDOB() {
    const dob = this._get('profile.birthday');
    return moment(dob).format('MM/DD/YYYY');
  }
  _getLanguage(){
    const { profile } = this.variables
    return _.get(profile, 'language.description','');
  }

  _getAppLanguage(){
    const { profile } = this.variables
    return _.get(profile, 'appLanguage.description','');
  }
  _getTag() {
    const { profile } = this.variables
    const rs = []
    if (!profile) return rs
    if (profile.gender) {
      rs.push(this._getGender())
    }
    const dob = this._get('profile.birthday');
    if (dob) {
      rs.push(moment(dob).format('MM/DD/YYYY'));
    }
    const age = this._getAge()
    if (age) {
      rs.push(age + ' yrs')
    }
    if (profile.language) {
      rs.push(_.get(profile, 'language.description'))
    }
    if (profile.appLanguage) {
      rs.push(_.get(profile, 'appLanguage.description'))
    }

    /*remove height && weight && ethnicity SC-1446
    if(profile.height){
      const heightInInches = convertor.height(profile.height.value, 'cm', 'inch', 0);
      const heightInFeet = convertInchesToFeet(heightInInches);
      const heightRemainderInInches = getRemainderAfterConvertingToFeet(heightInInches);
      rs.push(`${heightInFeet} ${i18nMap.get('measure.units_height_ft')} ${heightRemainderInInches} ${i18nMap.get('measure.units_height_inches')}`);
      // rs.push(`${profile.height.value}${profile.height.unit}`)
    }
    if(profile.weight){
      const weightInLbs = convertor.HS(profile.weight.value, 'kg', 'lbs');
      rs.push(`${Math.round(weightInLbs)} ${i18nMap.get('measure.units_weight_lbs')}`)
    } else if(profile.latestWeight){
      rs.push(`${profile.latestWeight.value}${profile.latestWeight.unit}`)
    }/*
    //calculate BMI based on kg and meter
    const heightInM = _.get(profile,'height.value',0)/100;
    const weightInKg = _.get(profile,'weight.value',0);

    if(heightInM&&weightInKg){
      const BMI = weightInKg/heightInM/heightInM;
      rs.push(`BMI ${BMI.toFixed(1)}`);

    }
    if(profile.race){
      rs.push(_.get(profile, 'race.description'))
    }
    if(profile.nationality){
       rs.push(_.get(profile, 'nationality.description'))
     }
    */
    //calculate BMI based on kg and meter
    // const heightInM = _.get(profile, 'height.value', 0) / 100;
    // const weightInKg = _.get(profile, 'weight.value', 0);

    // let BMIValue,BMIStatus;
    // if (heightInM && weightInKg) {
    //   BMIValue = (weightInKg / heightInM / heightInM).toFixed(1);
    //   BMIStatus = this._getBMIStatus(BMIValue);
    //   const abw = getABW(heightInM, weightInKg, this._getGender());
    //   const bmr = getBMR(heightInM, weightInKg, BMIValue, this._getGender(), age, abw);
    //   rs.push(<span>BMI {BMIValue} (<span style={{ color: BMIStatus.color }}>{BMIStatus.displayName}</span>)</span>);
    // }
    return rs
  }

  _getbodyInfo() {
    const { profile, recentWeights = [] } = this.variables;
    const bodyInfo = [];
    if (!profile) return bodyInfo;
    const heightInCm = _.get(profile, 'height.value');
    const heightInM = heightInCm/100;
    const heightInInches = heightInCm ? convertor.height(heightInCm, 'cm', 'inch', 0) : '';
    const heightInFeet = heightInInches ? convertInchesToFeet(heightInInches) : '';
    const heightLeftInInch = heightInInches ? getRemainderAfterConvertingToFeet(heightInInches) : '';
    const weightInKg = _.get(profile, 'weight.value');
    const weightInPounds = weightInKg ? convertor.HS(weightInKg, 'kg', 'lbs', 0) : '';
    const recentWeightsInRange = recentWeights.length > 0 && weightInKg? getWeightsInRange(recentWeights, weightInKg) :[weightInKg];
    const avgWeight = recentWeightsInRange.length > 0 ? getAverageWeight(recentWeightsInRange) : weightInKg;
    const BMIValue = (weightInKg / heightInM / heightInM).toFixed(1);
    const BMIStatus = this._getBMIStatus(BMIValue);
    const age = this._getAge();
    const gender = this._getGender();
    const abw = getABW(heightInInches, avgWeight, gender);
    const abwInPounds = convertor.HS(abw, 'kg', 'lbs', 0);
    const activity = _.get(profile, 'activityLevel');
    const bmr = getBMR(heightInCm, avgWeight, BMIValue, gender, age, abw, activity);
    const BMTTitle =  activity ? 'Total Calories' : 'BMR';
    if (heightInInches) bodyInfo.push(<span><span style={{fontWeight: 'bold'}}>Height: </span>{heightInFeet}'{heightLeftInInch}"</span>);
    if (weightInPounds) bodyInfo.push(<span><span style={{fontWeight: 'bold'}}>Weight: </span>{weightInPounds} lbs</span>);
    if (!isNaN(BMIValue)) bodyInfo.push(<span><span style={{fontWeight: 'bold'}}>BMI: </span>{BMIValue} (<span style={{ color: BMIStatus.color }}>{BMIStatus.displayName}</span>)</span>);
    if (!isNaN(BMIValue) && bmr) bodyInfo.push(<span><span style={{fontWeight: 'bold'}}>{BMTTitle}: </span>{bmr} kcal/day</span>);
    if (BMIValue > 40 && abw > 0) bodyInfo.push(<span><span style={{fontWeight: 'bold'}}>ABW: </span>{abwInPounds} lb</span>);
    return bodyInfo;
  }

  _getBodyInfoValues() {
    const { profile, recentWeights = [] } = this.variables;
    const bodyInfo = {};

    if (!profile) return bodyInfo;

    const heightInCm = _.get(profile, 'height.value');
    const heightInM = heightInCm/100;
    const heightInInches = heightInCm ? convertor.height(heightInCm, 'cm', 'inch', 0) : '';
    const heightInFeet = heightInInches ? convertInchesToFeet(heightInInches) : '';
    const heightLeftInInch = heightInInches ? getRemainderAfterConvertingToFeet(heightInInches) : '';
    const weightInKg = _.get(profile, 'weight.value');
    const weightInPounds = weightInKg ? convertor.HS(weightInKg, 'kg', 'lbs', 0) : '';
    const recentWeightsInRange = recentWeights.length > 0 && weightInKg? getWeightsInRange(recentWeights, weightInKg) :[weightInKg];
    const avgWeight = recentWeightsInRange.length > 0 ? getAverageWeight(recentWeightsInRange) : weightInKg;
    const BMIValue = (weightInKg / heightInM / heightInM).toFixed(1);
    const BMIStatus = this._getBMIStatus(BMIValue);
    const age = this._getAge();
    const gender = this._getGender();
    const activity = this._getActivityLevel();
    const abw = getABW(heightInInches, avgWeight, gender);
    const abwInPounds = convertor.HS(abw, 'kg', 'lbs', 0);
    const bmr = getBMR(heightInCm, avgWeight, BMIValue, gender, age, abw, activity);
    if (heightInInches) {
      bodyInfo.Height = `${heightInFeet}'${heightLeftInInch}"`
    };
    if (weightInPounds) {
      bodyInfo.Weight = `${weightInPounds} lbs`;
    };
    if (!isNaN(BMIValue)) {
      bodyInfo.BMI = { value: BMIValue, statusColor: BMIStatus.color, status: BMIStatus.displayName };
    }
    if (!isNaN(BMIValue) && bmr) {
      bodyInfo.BMR = `${bmr} kcal/day`;
    }
    if (BMIValue > 40 && abw > 0) {
      bodyInfo.AdjBW = `${abwInPounds} lb`; // change wording 6188
    }
    return bodyInfo;
  }

  _getBMIStatus(value) {
    let status = '';
    switch (true) {
      case (value < 18.5):
        status = 'UNDERWEIGHT';
        break;
      case (value >= 18.5 && value <= 24.9):
        status = 'NORMAL';
        break;
      case (value >= 25 && value < 29.9):
        status = 'OVERWEIGHT';
        break;
      default:
        status = 'OBESITY';
        break;
    }
    return BMIStatusMap[status];
  }

  _getHealthConditions() {
    const { profile } = this.variables;
    let result = [];
    if (!profile) return result;
    if (profile.healthConditions) {
      result = profile.healthConditions;
    }
    return result.map(val => FormatHelper.healthConditions(val));
  }
  _getAddress() {
    const { addresses } = this.variables
    if (!addresses || !addresses[0]) return ''
    return FormatHelper.address(addresses[0])
  }
  _getPhone(o) {
    if (!o) return ''
    return FormatHelper.phone(o)
  }
  _getGender(param) {
    const gender = this._get('profile.gender')
    if (!gender) return ''
    if (param) return gender

    return I18N.get(`gender.${gender}`)
  }
  _getTechLevel(param) {
    const techLevel = this._get('profile.techLevel')
    if (!techLevel) return ''
    if (param) return techLevel

    return I18N.get(`techLevel.${techLevel}`)
  }
  _getMotivation(param) {
    const motivation = this._get('profile.nutrition.behaviorChange')
    if (!motivation) return ''
    if (param) return motivation

    return I18N.get(`motivation.${motivation}`)
  }
  _getRole() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(currentUser, 'selectedRole');
    if (!selectedRole) return '';

    if (selectedRole) {
      switch (`${selectedRole.category}:${selectedRole.name}`) {
        case 'PROVIDER:Nurse':
          return I18N.get('userrole.nurse')
        case 'PROVIDER:Doctor':
          return I18N.get('userrole.doctor')
        case 'PROVIDER:Medical Assistant':
          return I18N.get('userrole.medicalAssistant')
        case 'PROVIDER:Clinic Medical Assistant':
          return I18N.get('userrole.clinicMedicalAssistant')
        default:
          return selectedRole.name
      }
    }
    else {
      return '';
    }
  }
  _getEmployeeId() {
    const { profile } = this.variables
    return profile && profile.employeeId
  }

  _getDepartment() {
    const { profile } = this.variables
    return profile && profile.department
  }
  _getInstitution() {
    // const {allRoles} = this.variables
    // return allRoles && allRoles.length > 0 ? _.get(allRoles, '0.organization.name') : '';

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(currentUser, 'selectedRole');
    return selectedRole ? selectedRole.organization.name : '';
  }

  _getSpecialty() {
    const { profile } = this.variables
    return profile && profile.specialty
  }
  //
  // _getStar(){
  //   const {starred, starredReason} = this.variables
  //   if(!starred){
  //     return {
  //       status : false,
  //       reason : ''
  //     }
  //   }
  //
  //   return {status : true, reason : starredReason}
  // }

  _getCCMRPM() {
    const { profile } = this.variables;
    const programCategories = _.get(profile, 'programCategories', []) || [];
    const categories = programCategories.map(o => {
      return {
        name: o.name,
        enrolled: o.enrolled,
        enrolledDate: o.enrolledDate
      }
    })

    return categories;
  }

  _getProgramCategories() {
    const programCategories = this._getCCMRPM();
    const enrolledList = _.filter(programCategories, pc => !!pc.enrolled);
    return enrolledList;
  }

  _getStar() {
    const { hasFlag, flagDetails } = this.variables
    if (!hasFlag) {
      return {
        status: false,
        reason: ''
      }
    }
    const starredReason = _.get(flagDetails, '0.reason');
    return { status: true, reason: starredReason }
  }
  _getNotificationEmail() {
    const { userSettings } = this.variables
    if (userSettings) {
      return _.includes(userSettings.notification, 'EMAIL') ? 'viaEmailYes' : 'viaEmailNo'
    }
    return 'viaEmailNo'
  }

  _getNotificationSMS() {
    const { userSettings } = this.variables
    if (userSettings) {
      return _.includes(userSettings.notification, 'SMS') ? 'viaSMSYes' : 'viaSMSNo'
    }
    return 'viaSMSNo'
  }
  _getLoginID() {
    return _.get(this.variables, 'username')
  }

  _getEnrolledDate() {
    // 0703, enrollmentDate based on consentForm.signatureAt for programs starting after March11,2021
    const { enrollmentDate = '' } = this.variables;
    return enrollmentDate ? moment(enrollmentDate).format('MM/DD/YYYY') : 'N/A';
    // const categories = this._getCCMRPM().filter(o => o.enrolled);
    // const enrolledDate = categories.reduce((prev, curr) => prev < curr.enrolledDate ? prev : curr.enrolledDate, Infinity);
    // if (!enrolledDate || enrolledDate === Infinity) {
    //   return 'N/A';
    // } else {
    //   return moment(enrolledDate).format('MM/DD/YYYY')
    // }
  }

  _getActivityLevel() {
    const { profile } = this.variables;
    return profile && _.get(profile, 'activityLevel');
  }

  _getSubscription() {
    const { profile } = this.variables;
    const SUBSCRIBE_MAP = {
      'SUBSCRIBED':{ value: 'SUBSCRIBED', text : 'Subscribed'},
      'RENEW': {value: 'RENEW', text:'Subscribed' },
      'CANCEL':{ value: 'CANCEL', text: 'Subscribed' },
      'EXPIRED':{ value: 'EXPIRED',text:'Expired'},
      'UNSUBSCRIBED':{ value: 'UNSUBSCRIBED', text: '--'},
    }

    return profile&& SUBSCRIBE_MAP[_.get(profile,'subscription.status','UNSUBSCRIBED')].text;

  }

  _getLastLogin() {
    return _.get(this, 'variables.lastLogIn.id');
  }
}
