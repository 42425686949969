import React from "react";
import { IHSearchTable } from 'ihcomponent';
import map from '../constants/tableMap';
import helper from "../helper";
import setTaskToRead from "../query/setTaskToRead";
import API from "../API";
import Client from 'libModule/gqlClient';
import TrackingTaskModalComponent from "./TrackingTaskModalComponent";
const  { tableColumnsByPatient } = map;
class TaskAssignmentsByPatientComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expandIndex:[],
            taskAssignments: _.get(props,'taskList',[])
        }
    }
    onRow = (record, rowIndex)=>{
        const { expandIndex, taskAssignments } = this.state;
        const refetch = _.get(this.props,'refetchByPatient');
        const { id } = record;
        let res = [];
        // Mixpanel.track('clicked', 'task', 'patient task list popup');
        if(!expandIndex.includes(rowIndex)){
            res.push(rowIndex);
        }
        this.setState({
            expandIndex:res
        })
        if(helper.checkNeedFold(record)) {
            Client.mutate({
                mutation:setTaskToRead,
                variables:{id}
            }).then(res=>{
                // update real-time status without refetch/close popup
                const newTaskAssignments = [...taskAssignments];
                const taskIndex = _.findIndex(newTaskAssignments, { id });
                const task = newTaskAssignments[taskIndex];
                _.set(task, 'isRead.value', true);
                newTaskAssignments.splice(taskIndex, 1, task);
                this.setState({ taskAssignments: newTaskAssignments }, () => {
                    API.fetchTaskUnreadTasks();
                });
            })
        }
    };
    getTableProps = ()=> {
        const {taskList,filters,sort} = this.props;
        const { expandIndex, taskAssignments } = this.state;

        const noDataElem = (
            <div style={{height: '150px', paddingTop: '2em'}}>
                <p style={{fontSize: '18px'}}>There is no data!</p>
            </div>
        );

        const tableProps = {
            columns:tableColumnsByPatient(filters,sort),
            title:'Task Assignments',
            expandedRowKeys:expandIndex || null ,
            noDataElem,
            onRow:(record,index)=>({ onClick:()=>this.onRow(record,index) }),
            expandedRowRender:(doc, rowIndex) =>{
            const expandingIndex = _.first(expandIndex);
            const taskComponentProps = {
                key: doc.id,
                modalData: doc,
                renderBody:true,
                refetchTaskList:this.props.refetchTaskList,
                // refetchUser,
                // refetchUserList,
                // currentProgramId,
                // team,
                setModalData:()=>{},
            };
            // comparing expandingIndex and rowIndex
            return expandingIndex === rowIndex ?
                <TrackingTaskModalComponent {...taskComponentProps}/>
                : null;
            },
            rowKey:(__,id) => id,
            dataSource:taskList
        }
        return tableProps;
    }

    render(){
        console.log(this.props.taskList);
        const tableProps = this.getTableProps();
        return <IHSearchTable  { ...tableProps} className="taskAssignmentListByPatientTable inPatientTaskTable" />
    }

}


export default TaskAssignmentsByPatientComponent;

