import { goPath,getUserAccess } from 'libModule/utils';
import _ from 'lodash'
import getAccess from 'modulesAll/utils/constants/rolePath/'

export const checkPermission = (pathname) => {
  const userAccess = getUserAccess();

  const requiredAccess = getAccess(pathname);
  //bypass permission checking
  if(!userAccess || requiredAccess.length === 0) {
    return;
  }
  
  if (_.intersection(requiredAccess, userAccess).length === 0){
    goPath('/not-authorized');
  }
}
