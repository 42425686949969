import type from '../constant/reduxType';

export default {
  setSelectedSummaryIntervalKey: ({ memberId, visitId, vitalType, selectedIntervalKey }) => {
    return {
      type: type.SET_SELECTED_SUMMARY_INTERVAL_KEY,
      payload: {
        memberId,
        visitId,
        vitalType,
        selectedIntervalKey
      }
    }
  },
  setSelectedSummaryTimeRange: ({ memberId, visitId, vitalType, timeRange }) => {
    return {
      type: type.SET_SELECTED_SUMMARY_TIME_RANGE,
      payload: {
        memberId,
        visitId,
        vitalType,
        selectedTimeRange: timeRange
      }
    }
  },
};
