import moment from 'moment-timezone';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { decryptRole } from '../../../lib/utils';
import { ROLE_MAP } from '../../../lib/constants';
import { getTaskOrgIdByPatientId, getTaskOrgIdByTaskId } from '../API';
import { ITEM_TYPE_ENUM } from '../constant/signOffConstants';

const STORAGE_KEY = 'sign-off-cursor';
export class SignOffCursorClass {
  constructor() {}

  getCursor = () => { 
    const cursor = (JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {}).cursor;
    return _.isNil(cursor) ? -1 : cursor;
  }

  getCases = () => (JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {}).cases || [];

  getTotalCase = () => this.getCases().length;

  getTaskIdByCursor = () => {
    const cursor = this.getCursor();
    const _case = _.get(this.getCases(), `${cursor}`) || {};
    return _.get(_case, 'task.id');
  };

  updateStorage(updatedValues) {
    const prevValues = JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {};
    const newValues = { ...prevValues, ...updatedValues };
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newValues));
  }

  // case must include either patient.id or task.id
  updateCases(key, ids) {    
    let cases = this.getCases();    
    if(!Array.isArray(ids))
      return cases;
    // array of cases should be synced with new array of ids
    const arrayToCheck = ids.length > cases.length ? ids : cases;
    for(let idx in arrayToCheck) {
      const id = ids[idx];
      const caseId = _.get(cases, `${idx}.${key}.id`);
      if(id === caseId) {
        continue; // skip
      }
      let caseFromId = { [key]: { id } };
      if(!id) {
        cases.splice(idx, 1); // remove
      } else if(!caseId) {
        cases.push(caseFromId); // add
      } else if(id !== caseId) {
        // check if new case has already been fetched
        // if yes, use the existing one with data
        const _case = _.find(cases, c => _.get(c, `${key}.id`) === id) || {};
        caseFromId = !_.isEmpty(_case) ? _case : caseFromId;
        cases.splice(idx, 1, caseFromId); // replace
      }
    }
    cases = _.uniqBy(cases, c => _.get(c, `${key}.id`));
    this.updateStorage({ cases });
    return cases;
  }

  getAndUpdateCasesFromData(data) {
    let cases = this.getCases();  
    // either one will exist
    // should NOT exist both at the same time
    const newPatientIds = _.get(data, 'getNeedSignOffPatients.patientIds');
    const newTaskIds = _.get(data, 'getUnreadSignOffComments.signOffTaskIds');

    if(data.loading) {
      return cases;
    }
    if(Array.isArray(newPatientIds)) {
      cases = this.updateCases('patient', newPatientIds);
    }
    if(Array.isArray(newTaskIds)) {
      cases = this.updateCases('task', newTaskIds);
    }
    return cases;
  }

  parseCasesFromId(key, listOfIds) {
    let parsed = [];
    for(let id of listOfIds) {
      let caseFromId = { [key]: { id } };
      parsed.push(caseFromId);
    }
    return parsed;
  }

  pushNewCases(data) {
    let cases = this.getCases(),
        parsedCases = [],
        key, ids = [];  
    // either one will exist
    // should NOT exist both at the same time
    const newPatientIds = _.get(data, 'getNeedSignOffPatients.patientIds');
    const newTaskIds = _.get(data, 'getUnreadSignOffComments.signOffTaskIds');

    if(data.loading) {
      return cases;
    }
    if(Array.isArray(newPatientIds)) {
      key = 'patient';
      ids = newPatientIds;
      parsedCases = this.parseCasesFromId(key, ids);
    }
    if(Array.isArray(newTaskIds)) {
      key = 'task';
      ids = newTaskIds;
      parsedCases = this.parseCasesFromId(key, ids);
    }
    cases = [...cases, ...parsedCases];
    if(_.isEmpty(parsedCases) && key) {
      // no new case, sync the new list of ids with current cases
      cases = this.updateCases(key, ids);
    }
    this.updateStorage({ cases });
    return cases;
  }

  resetCursor() {
    this.updateStorage({ cursor: -1 });
  }

  hasPreviousCase() {
    const prevCursor = this.getCursor() - 1;
    const prevCase = this.getCases()[prevCursor];
    return !!prevCase
  }

  hasNextCase() {
    const nextCursor = this.getCursor() + 1;
    const nextCase = this.getCases()[nextCursor];
    return !!nextCase;
  }

  // @return patient: { id, switchToOrg }
  // switchToOrg: determine whether to switch org before open patient profile
  // - if not empty, need to switch org
  // - if null, don't have access to org / can't find clinic
  // - undefined, don't need to switch org
  // @return null: can't get patient for the case
  parsePatientData = (patient) => {
    const viewer = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerOrgId = _.get(viewer, 'selectedRole.organization.id');
    const viewerAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
    const allViewerOrgIds = _.map(viewerAllRoles, 'organization.id');
    const patientOrgId = _.get(patient, 'organizationId');
    let switchToOrg = undefined;
    if(!_.includes(allViewerOrgIds, patientOrgId) || !patientOrgId) {
      switchToOrg = null;
    } else if(viewerOrgId !== patientOrgId) {
      const nextViewerRole = _.find(viewerAllRoles, r => _.get(r, 'organization.id') === patientOrgId);
      switchToOrg = _.get(nextViewerRole, 'organization');
    }
    return { id: _.get(patient, 'id'), switchToOrg };
  }

  // get patient then update cursor
  async getPatientByCursor(cursor, getNextCursor) {
    let curCases = this.getCases(),
        newCursor, patient;

    if(cursor >= curCases.length || cursor < 0) {
      patient = null;
    } else {
      const _case = curCases[cursor];
      if(_.isEmpty(_case)) { // wrong format, check next
        return this.getPatientByCursor(getNextCursor(cursor), getNextCursor);
      } 
      newCursor = cursor;
      const { id, organizationId } = _.get(_case, 'patient') || {};
      const taskId = _.get(_case, 'task.id');
      let fn, getDataFn;
      if(!taskId && !id) {
        patient = null;
      } else if (id && organizationId) {
        patient = _.get(_case, 'patient');
      } else {
        if (taskId && !id) {
          fn = () => getTaskOrgIdByTaskId({ id: taskId });
          getDataFn = (res) => _.get(res, 'data.getSignOffTask');
        } else if (id && !organizationId) {
          fn = () => getTaskOrgIdByPatientId({ patientId: id });
          getDataFn = (res) => _.get(res, 'data.getPatientSignOffTasks');
        }
        try {
          const res = await fn();
          const data = getDataFn(res);
          // save for next time
          _.set(curCases, `${cursor}.patient`, {
            id: _.get(data, 'patient.id'),
            organizationId: _.get(data, 'organization.id')
          });
          patient = _.get(curCases, `${cursor}.patient`);

        } catch (error) {
          console.error(error);
          patient = null;
        }
      }
    }
      
    patient = this.parsePatientData(patient);

    if(!patient || !patient.id || _.isNull(patient.switchToOrg)) {
      // will fail to navigate to patient, don't update cursor
      newCursor = this.getCursor();
    }
    
    this.updateStorage({ cursor: newCursor, cases: curCases });
    return patient;
  }

  getPreviousPatient() {
    const cursor = this.getCursor();
    const getNextCursor = (cursor) => cursor - 1;
    return this.getPatientByCursor(getNextCursor(cursor), getNextCursor);
  }

  getNextPatient() {
    const cursor = this.getCursor();
    const getNextCursor = (cursor) => cursor + 1;
    return this.getPatientByCursor(getNextCursor(cursor), getNextCursor);
  }

  syncCursor(patientId, taskId) {
    const cases = this.getCases();
    if(!patientId) {
      return this.updateStorage({ cursor: -1 });
    }

    let caseIdx;
    // determine to sync by patientId or taskId
    // multiple cases with patientId => sync by taskId
    const countForPatientId = _.filter(cases, c => _.get(c, 'patient.id') === patientId);    
    if(countForPatientId.length > 1) {
      // by taskId
      caseIdx = _.findIndex(cases, c => _.get(c, 'task.id') === taskId);
    } else {
      // by patientId
      caseIdx = _.findIndex(cases, c => _.get(c, 'patient.id') === patientId);
    }
    this.updateStorage({ cursor: caseIdx }); // caseIdx = -1 => go back to first patient
  }

  popCase(removeIndex) {
    if(!removeIndex)
      return;
    const curCases = this.getCases();
    // const key = taskId ? 'task' : 'patient';
    // const filterValue = taskId || patientId;
    // const caseIndex = _.findIndex(curCases, c => _.get(c, `${key}.id`) === filterValue);
    // if(caseIndex < 0)
    //   return;
    const curCursor = this.getCursor();
    curCases.splice(curCursor + removeIndex, 1);
    this.updateStorage({ 
      cases: curCases, 
      // only sync cursor when cursor moved forward
      cursor: removeIndex < 0 ? curCursor + removeIndex : curCursor 
    });
  }
}

export const SIGN_OFF_TIME_FORMAT = 'MM/DD/YYYY, hh:mm a';

export const getRoleAndRoleName = () => {
  const role = decryptRole();
  const roleName = ROLE_MAP[role];
  return { role, roleName };
}

export const getTimeWithFormat = (time, format = SIGN_OFF_TIME_FORMAT) => {
  if(!time)
    return '--';
  return moment(time).format(format);
};

// displayOneDate if from and to is on the same date, show only 1 value
export const getTimeRangeWithFormat = (from, to, timezone, fromFormat, toFormat, displayOneDate) => {
  if(!from && !to)
    return null;

  if(!timezone || timezone === 'undefined')
    timezone = DATE_HELPERS.getClinicTimezone() || tz.guess();

  const fromTime =  moment(from).clone().tz(timezone);
  const toTime =  moment(to).clone().tz(timezone);

  const fromTimeFormat = fromTime.format(fromFormat || 'HH:mm:ss');
  const toTimeFormat = toTime.format(toFormat || fromFormat || 'HH:mm:ss, MM/DD/YYYY');

  const isSameDate = fromTime.isSame(toTime);
  if(displayOneDate && isSameDate) {
    return fromTimeFormat;
  }
  return `${fromTimeFormat} - ${toTimeFormat}`;
};

export const getAssociatedUser = (roleName, mentor, mentee) => {
  switch(roleName) {
    case 'RD':
      return mentee;
    case 'HC':
      return mentor;
    default:
      return false;
  }
};

const SIGNOFF_ITEM_RESOLVERS = {
  SmartAlert: (data) => { // clinical and provider alerts
    const categoryMap = {
      CDE: ITEM_TYPE_ENUM.CLINICAL_ALERT,
      PROVIDER: ITEM_TYPE_ENUM.PROVIDER_ALERT
    };
    let value = {
      ...data,
      category: _.get(data, 'alertCategory'),
      reason: _.get(data, 'reason')
    };
    return { itemType: categoryMap[value.category], value };
  },
  ProviderNote: (data) => {
    let value = {
      ...data,
      category: data.providerCategory
    };

    let itemType;
    switch(value.category) {
      case 'CALL_LOG':
        itemType = ITEM_TYPE_ENUM.CALL_LOG;
        break;
      default:
        itemType = ITEM_TYPE_ENUM.PROVIDER_NOTE;
    }
    
    return { itemType, value };
  },
  TaskAssignment: (data) => {
    let value = {
      ...data,
      reason: data.taskReason
    };
    return { itemType: ITEM_TYPE_ENUM.TASK_ASSIGNMENT, value };
  },
  Visit: (data) => {
    let value = { 
      ...data,
      type: data.visitType
    };
    return { itemType: ITEM_TYPE_ENUM.VISIT, value };
  },
  FoodLog: (data) => {
    let value = { ...data };
    return { itemType: ITEM_TYPE_ENUM.FOOD_LOG, value };
  },
  MonthlyReview: (data) => {
    let value = { ...data };
    return { itemType: ITEM_TYPE_ENUM.MONTHLY_REVIEW, value };
  },
  SignOffVersionHistory: (data) => {
    const { history } = data;
    let value = { sourceData: data };
    const histories = _.map(history, h => {
      const { id, visit, modifiedHistory } = h;
      if(visit && visit.id) {
        return null;
      }
      let updatedAt;
      const sections = [];
      _.map(modifiedHistory, mh => {
        const { itemsUpdated, date } = mh;
        if(!itemsUpdated)
          return null;
        updatedAt = date;
        sections.push(itemsUpdated);
      });
      return { 
        id,
        sections: _.join(sections, ', '),
        updatedAt
      };
    });

    value = Object.assign(value, { histories });
    return { itemType: ITEM_TYPE_ENUM.CARE_PLAN, value };
  },
  SignOffChatHistory: (data) => {
    let value = { ...data };
    return { itemType: ITEM_TYPE_ENUM.CHAT_HISTORY, value };
  }
};

export const parseTaskItems = (data) => {
  const parsedList = [];
  _.map(data, d => {
    const { __typename, ...dataValue } = d || {};
    const fn = SIGNOFF_ITEM_RESOLVERS[__typename];
    const { itemType, value } = fn ? fn(dataValue) : {};
    if(!itemType)
      return null;

    const itemIndex = _.findIndex(parsedList, { itemType });
    if(itemIndex > -1) {
      const data = _.get(parsedList, `${itemIndex}.data`);
      data.push(value);
      _.set(parsedList, `${itemIndex}.data`, data);
    } else {
      parsedList.push({
        itemType,
        data: [value]
      });
    }
  });
  return parsedList;
};