import gql from 'graphql-tag'

export default gql`
  mutation createBroadcast(
    $startTime : Date!,
    $endTime : Date!,
    $message : String!,
    $title : String!
  ){
    createBroadcast(
      startTime : $startTime,
      endTime : $endTime,
      message : $message,
      title : $title
    ){
      id
      message
      startTime
      endTime
      title
    }
  }
`
