import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { IHLoading } from 'ihcomponent';
import { connect } from 'react-redux';

import ReviewGoalItemComponent from '../components/ReviewGoalItemComponent';
import ReviewSummaryItemWrapperComponent from '../components/ReviewSummaryItemWrapperComponent';
import ReviewSubmitFormContainer from './ReviewSubmitFormContainer';
import { mapDispatchToTimerActions, getTimerClaimedId } from '../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../idleTime/constant';
import { getConditionName } from '../helpers';
import { handleSubmitDuplicateBillableTime } from '../helpers/billableTimeHandlers';
import { getMonthlyReviewContent, getLastFinishedMonthlyReview } from '../apis/getMonthlyReview';
import { RPM_PATIENT_REVIEW_TIMER } from '../constants';

import '../styles/ReviewActionCenterContainer.scss';

const ReviewActionCenterContainer = (props) => {
  const {
    patientId,
    isLoadingNext,
    loadingLastFinishedMonthlyReview,
    lastFinishedMonthlyReview = {},
    loadingMonthlyReviewContent,
    monthlyReviewContent = [],
    timerInfo,
    interacted,
    stopTimer,
    onSubmit,
  } = props;

  const [billableTimeCallbackValues, setBillableTimeCallbackValues] = useState(null);

  const {
    note
  } = lastFinishedMonthlyReview;

  const renderGoalSummaries = () => {
    const renderGoal = (goal, vitals) => {
      const { conditionEnum, condition } = goal;
      const title = getConditionName(conditionEnum, condition);
      return (
        <ReviewSummaryItemWrapperComponent
          key={conditionEnum || condition}
          title={title}
        >
          <ReviewGoalItemComponent
            goal={goal}
            vitals={vitals}
          />
        </ReviewSummaryItemWrapperComponent>
      );
    };

    return (
      monthlyReviewContent.map((content) => {
        const {
          vitals = [],
          goals = [],
        } = content;
        return goals.map((goal) => renderGoal(goal, vitals));
      })
    );
  };

  const renderCareTeamSummary = () => {
    if (!note) return null;
    return (
      <ReviewSummaryItemWrapperComponent
        title="Care Team Summary"
        iconSrc="image/task-report.svg"
      >
        {note}
      </ReviewSummaryItemWrapperComponent>
    );
  };

  useEffect(() => {
    // care team is helping provider to review
    // submit duplicate time for provider
    if (billableTimeCallbackValues && timerInfo && timerInfo.stopTime) {
      const { values, next } = billableTimeCallbackValues;
      const { reviewedBy } = values || {};
      handleSubmitDuplicateBillableTime(reviewedBy, timerInfo, (error) => {
        if (error) {
          console.error('Error: submit duplicate billable time, ', error)
        }
        setBillableTimeCallbackValues(null);
        // move to next patient
        if (onSubmit) onSubmit();
        next();
      });
    }
  }, [billableTimeCallbackValues, timerInfo]);

  const handleOnSubmitReview = async (values, next) => {
    await interacted(EVENT_TYPES.PATIENT_PROFILE);
    await interacted(EVENT_TYPES.MONTHLY_REVIEW);
    // submit time for Care team/ Provider manually
    await stopTimer();

    if (values && values.reviewedBy) {
      setBillableTimeCallbackValues({ values, next });
      // NOTE:
      // submit replicate billable time when CT helps provider to review
      // happens in useEffect(__, [billableTimeCallbackValues, timerInfo])
    } else {
      if (onSubmit) onSubmit();
      next();
    }
  }

  const isLoading = isLoadingNext || loadingLastFinishedMonthlyReview || loadingMonthlyReviewContent;

  return (
    <div className='review-action-center-container'>
      {
        !loadingLastFinishedMonthlyReview
          ? renderCareTeamSummary()
          : <IHLoading />
      }

      {
        !loadingMonthlyReviewContent
          ? renderGoalSummaries()
          : <IHLoading />
      }

      <ReviewSubmitFormContainer
        // key is to make sure the form is re-rendered when change patient
        // form is memoized on purpose
        key={patientId}
        patientId={patientId}
        disabled={isLoading}
        onSubmit={handleOnSubmitReview}
      />
    </div>
  );
};

ReviewActionCenterContainer.propTypes = {
  patientId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const timerId = getTimerClaimedId(RPM_PATIENT_REVIEW_TIMER, patientId);
  return {
    timerId,
    timerInfo: state.timerClaimed.main[timerId],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { patientId, timerId } = ownProps;
  const timerActions = mapDispatchToTimerActions(dispatch, RPM_PATIENT_REVIEW_TIMER, patientId);
  return {
    ...timerActions,
    stopTimer: (cb) => timerActions.stopTimer(timerId, cb),
    interacted: (name) => timerActions.interacted(name, ''),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  getLastFinishedMonthlyReview,
  getMonthlyReviewContent,
)(ReviewActionCenterContainer);