import React from 'react';
import {message, Switch} from 'antd'
import API from "../API";
const changeOrgPermissions = ({id,orgPermissions,permissionKey,value,header})=>{
    orgPermissions = _.omit(orgPermissions,'__typename');
    _.set(orgPermissions,permissionKey,value);
    const info = value ? 'turned on' :'turned off';
    API.editOrgPermission({
        orgPermissions,id
    }).then(res=>{
        message.info(`${header} ${info}`);
    })
}
const ABTestingOrgTableConfig = [
    {
        key:'brandedName',
        title:'Branded Name',
        dataIndex:'brandedName'
    },
    {
        key:'internalName',
        title:'Internal Name',
        dataIndex: 'internalName'
    },
    {
        key:'id',
        title:'Enable A/B Testing',
        dataIndex: 'id',
        render:(id,b)=>{
            const { orgPermissions,internalName } = b;
            const permissionKey = 'enableABTesting';
            const checked = !!_.get(orgPermissions,permissionKey);
            const header = `${internalName} AB Testing`
            return <Switch onChange={(value)=>changeOrgPermissions({id,orgPermissions,permissionKey,value,header})} checked={checked}/>
        }
    }
]

export default ABTestingOrgTableConfig;
