import {React, IHModal, IHBaseForm, IHButton, moment, _} from 'ihcomponent'
import {createContainer, GQLHelper} from 'libModule/utils'
import { toDoDueDateValidator } from 'libModule/validator';
import createTodoGQL from 'modulesAll/graphql/createTodo'
import editTodoGQL from 'modulesAll/graphql/editTodo'
import deleteTodoGQL from 'modulesAll/graphql/deleteTodo'
import { graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import {modalAction} from 'modulesAll/common/actions'
import PropTypes from 'prop-types';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { closeModal } from 'layoutModule/actions/MainModal'

const style = {
  div : {
    textAlign : 'center',
    marginTop : '35px',
    padding : '0 15px'
  }
}

const Component = class Component extends React.Component{
  static displayName = 'providerTask/TaskList/containers/DetailModal'

  closeModal(){
    this.props.closeModal()
  }

  render(){
    const {select} = this.props.modal
    if(select && select.completedAt){
      return (
        <div className="v-content">
          <div className="c-b1">
            <h6>Task Description</h6>
            <p>{select.description}</p>
            <h6>Due Date</h6>
            <p>{moment(select.dueDate).format('MMM-DD-YYYY')}</p>
            <h6>Completed</h6>
            <p>{moment(select.completedAt).format('MMM-DD-YYYY')}</p>
          </div>

        </div>
      )
    }

    return (
      <div className="v-content">
        {this.renderForm()}
      </div>
    )
  }

  renderForm(){
    const {select} = this.props.modal
    const p = {
      style : {
        margin : '0 auto'
      },
      horizontal : true,
      itemList : [
        {
          key : 'description',
          label : 'Task Description',
          type : 'textarea',
          labelCol : {span : 24},
          wrapperCol : {span : 24},
          placeholder : 'Describe task here',
          initialValue : select ? select.description : '',
          style : {
            height : '96px'
          },
          rules : [
            {required : true, message: 'Task description is required'}
          ]
        },
        {
          key : 'date',
          label : 'Due',
          type : 'date',
          format : 'MMM-DD-YYYY',
          labelCol : {span : 24},
          wrapperCol : {span : 15},
          placeholder : 'MMM-DD-YYYY',
          initialValue : select ? moment(select.dueDate) : null,
          rules : [
            {required : true, message: 'Due date is required'},
            { validator: (rule, value, callback) => callback(toDoDueDateValidator(value)) }
          ]
        }
      ]
    }
    const sy = style.div

    return (
      <div style={sy}>
        <IHBaseForm ref="form" {...p} />
        {this.renderBottomButton()}
      </div>
    )
  }
  renderBottomButton(){
    const cancelButton = {
      label : 'Cancel',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : ()=>{
        this.closeModal()
      }
    }
    const doneButton = {
      type : 'primary',
      label : 'Done',
      ref : 's_btn',
      size : 'large',
      onClick : async ()=>{
        try{
          const v = await this.refs.form.getFormDataSync()
          const variables = this.getVariables(v)
          const {select} = this.props.modal
          if(select) {
            this.editTodo(variables)
          }
          else {
            this.createTodo(variables)
          }
          this.refs.s_btn.loading(true)
        }catch(e){
          this.refs.s_btn.loading(false)
        }
      }
    }

    const {select} = this.props.modal
    const deleteButton = {
      type : 'danger',
      label : 'Delete',
      size : 'large',
      ref : 'd_btn',
      style : {
        position : 'absolute',
        left : 0
      },
      onClick : async () => {
        const variables = { id : select.id }
        const auditLogDetails = {
          description: select.description || '',
          dueDate: moment(select.dueDate).format('MMM-DD-YYYY'),
          createdAt: moment(select.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
          updatedAt: moment(select.updatedAt).format('MMM-DD-YYYY hh:mm a:ss'),
          completedAt: select.completedAt && moment(select.completedAt).format('MMM-DD-YYYY hh:mm a:ss'),
        }

        try {
          this.refs.d_btn.loading(true)
          const res = await this.props.deleteTodoGQL({ variables })

          createAuditLog({
            action: I18N.get('auditLog.provider.tasks.deleteTask'),
            details: auditLogDetails,
            request: variables,
            response: res
          })

          this.refs.d_btn.loading(false)
          this.closeModal()
        }
        catch(e) {
          createAuditLog({
            action: I18N.get('auditLog.provider.tasks.deleteTask'),
            details: auditLogDetails,
            request: variables,
            response: e,
            success: false
          })

          this.refs.d_btn.loading(false)
        }
      }
    }

    return (
      <div style={{textAlign:'right', marginTop:'36px', 'position':'relative'}} className="v-bottom">
        <IHButton {...doneButton} />
        <IHButton {...cancelButton} />
        {select && <IHButton {...deleteButton} />}
      </div>
    )
  }

  async createTodo(variables){
    const auditLogDetails = {
      description: variables.description || '',
      dueDate: moment(variables.dueDate).format('MMM-DD-YYYY'),
      createdAt: moment(variables.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
      updatedAt: moment(variables.updatedAt).format('MMM-DD-YYYY hh:mm a:ss'),
      completedAt: variables.completedAt && moment(variables.completedAt).format('MMM-DD-YYYY hh:mm a:ss'),
    }

    try {
      const amute = this.props.createTodoGQL
      const res = await amute({variables})

      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.createTask'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      this.closeModal()
    }
    catch(e) {
      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.createTask'),
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })

      this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(e)))
    }
  }

  async editTodo(variables){
    const auditLogDetails = {
      description: variables.description || '',
      dueDate: moment(variables.dueDate).format('MMM-DD-YYYY'),
      createdAt: moment(variables.createdAt).format('MMM-DD-YYYY hh:mm a:ss'),
      updatedAt: moment(variables.updatedAt).format('MMM-DD-YYYY hh:mm a:ss')
    }

    try {
      const amute = this.props.editTodoGQL
      const res = await amute({variables})

      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.editTask'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      this.closeModal()
    }
    catch(e) {
      createAuditLog({
        action: I18N.get('auditLog.provider.tasks.editTask'),
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })

      this.props.dispatchErrorModal(e);
    }
  }

  getVariables(v){
    const {select} = this.props.modal
    if(select){
      return _.merge({}, select, {
        description : v.description,
        dueDate : v.date.toDate()
      })
    }

    return {
      description : v.description,
      priority : 'HIGH',
      completedAt : null,
      dueDate : v.date.toDate()
    }
  }
}

Component.propTypes = {
  closeModal: PropTypes.func,
  modal: PropTypes.object,
  dispatch: PropTypes.func,
}

const mapState = null;

const mapDispatch = dispatch => {
  return {
    closeModal,
    dispatchErrorModal: (err) => dispatch(modalAction.openErrorModal(GQLHelper.formatError(err)))
  }
};

export default compose(
  graphql(createTodoGQL, {name : 'createTodoGQL'}),
  graphql(editTodoGQL, {name : 'editTodoGQL'}),
  graphql(deleteTodoGQL, {name : 'deleteTodoGQL'}),
  connect(mapState, mapDispatch)
)(Component);
