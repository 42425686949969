import React from 'react'
import { IHLoading } from 'ihcomponent'
import I18N from 'modulesAll/I18N'
import { connect } from 'react-redux'
import {compose} from 'react-apollo'
import { browserHistory } from 'react-router'
import RequestCache from 'libModule/requestCache'
import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema, convertFieldsValue } from 'libModule/helpers/reducer-helpers'
import config from 'libModule/config'
import {createPageContainer, combine} from 'libModule/helpers/gql-helpers'
import crypto from 'sjcl'
import { ROLE_MAP, VISIT_TYPE_ROLES_MAP, VISIT_TYPE_NAME_MAPPING, COMPLEXITY_ENUM, PATLANG  } from '../constants';

// 11/1/17 - JV - Remove __typename from mutate variables, an ApolloClient error
export const clearTypenameFields = function(obj) {
  let clone = Object.assign({}, obj);
  const traverse = (o) => {
    for (var i in o) {
      if (Array.isArray(o[i])) {
        o[i].forEach(x => traverse(x));
      }
      else if (o[i] !== null && typeof(o[i])=='object') {
        traverse(o[i]);
      }
      else if (i === '__typename') {
        delete o[i];
      }
    }
  };
  traverse(clone);
  return clone;
};

// Cryptography using sjcl library
export const encryptText = (key, toEncrypt) => {
  return crypto.encrypt(key, toEncrypt)
}

export const decryptRole = () => {
  if(sessionStorage.getItem('authToken') && sessionStorage.getItem('currentUserRoles')) {
    try {
      return crypto.decrypt(sessionStorage.getItem('authToken'), sessionStorage.getItem('currentUserRoles'))
    }
    catch(err) {
      console.log('decryptRole',err);
      sessionStorage.clear();
      browserHistory.push('/');
    }
  }
}

export const decryptUserAccess = () => {
  if(sessionStorage.getItem('authToken') && sessionStorage.getItem('userAccess')) {
    return crypto.decrypt(sessionStorage.getItem('authToken'), sessionStorage.getItem('userAccess'))
  }
}

export { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema, convertFieldsValue }

export const isCurrentUserAdmin = () => { return decryptRole() === 'ADMIN:Admin' }

export const isNumeric = (n) => { return !isNaN(parseFloat(n)) && isFinite(n); }

export const isPendingNumeric = s => /^-?\d*\.?\d*$/.test(s);

// return mill seconds
export const getTimeFromProgram = (number, unit) => {
  const unitToDayMap = {
    'DAY': 1,
    'WEEK': 7,
    'MONTH': 30
  }
  return unitToDayMap[unit] * number * 24 * 3600 * 1000
}

export const constants = (moduleName, namespace, constants) => {
  return Object.freeze(
    constants.reduce((obj, constant) => {
      return {
        ...obj,
        [constant]: `${moduleName}/${namespace}/${constant}`
      }
    }, {})
  )
}

/* Note: Do not use Arrow Function shorthand here, otherwise 'this' will be bound to undefined */
String.prototype.toTitleCase = function () { return this.replace(/\w+/g, chunkText => chunkText.charAt(0).toUpperCase() + chunkText.substr(1).toLowerCase()) }

export const createContainer = (component, mapState, mapDispatch)=>{
    return connect(mapState, mapDispatch)(component)
}
export const createGQLContainer = (gqlList=[], component, mapState, mapDispatch) => {
  gqlList.unshift(connect(mapState, mapDispatch));
  return (compose.apply(null, gqlList))(component)
}

export const insertToArrayGap = (array, elem) => {
    let rs = [];
    _.each(array, (item, i) => {
        if (_.size(array)-1 > i) {
            rs = rs.concat([item, elem])
        }
        else {
            rs = rs.concat([item])
        }
    });

    return rs
};

export const Loading = (props)=>{
    return (
        <div className="vsm-loading">
            <h4>{`Loading...`}</h4>
        </div>
    )
}

export const goPath = (path)=>{
    browserHistory.push(path)
}

export const replacePath = (path) => {
  browserHistory.replace(path);
}
// redux props map to antd form
// need to return a new object
export const mapPropsToFields = (props) => (props?{...props.fieldsValue}:props)

export const renderLabel = (key, label, type, col, others={}) => {
  return {
    key,
    label,
    type,
    col,
    ...others
  }
}

export const renderFormItem = (key, label, type, itemLayout, placeholder, initialValue, col, others={}) => {
  return {
    key,
    label,
    type,
    ...itemLayout,
    placeholder,
    initialValue,
    col,
    ...others
  }
}

export {
  RequestCache
}

//follow format mentioned in VET-141
export const FormatHelper = {
  address : (obj)=>{
    let rs = ''
    if(obj.streetNumber && obj.streetName){
      rs += obj.streetNumber+' '+obj.streetName+', '
    }
    if(obj.floor && obj.unit){
      rs += ( obj.floor.indexOf('#') !== 0 ? '#' : '' ) + obj.floor+'-'
    }
    if(obj.unit){
      rs += obj.unit+', '
    }
    if(obj.city){
      rs += obj.city+', '
    }
    if(obj.state){
      rs += obj.state+', '
    }
    if(obj.country && obj.country.description){
      rs += obj.country.description+' '
    }else if(obj.country.code){
      rs += obj.country.code+' '
    }

    if(obj.postCode){
      rs += obj.postCode
    }

    return rs.replace(/ $/, '')
  },
  phone : (obj)=>{
    let rs = ''
    if(obj.countryCode){
      rs += '+'+obj.countryCode.toString()+' '
    }
    const tmp = obj.number.toString().split('')
    tmp.splice(3, 0, ' ')
    tmp.splice(7, 0, ' ')
    rs += tmp.join('')

    return rs
  },
  threshold : (tasks)=>{
    const rs = []
    _.each(tasks, (t)=>{
      if(t.type === 'BP'){
        const tmp = []
        _.each(t.threshold, (hd, key)=>{
          tmp.push({
            name : key,
            range : [hd.min, hd.max]
          })
        })
        rs.push({
          name: 'Blood Pressure',
          unit: 'mmHg',
          data : tmp
        })
      }
    })

    return rs
  },
  healthConditions: str => {
    //08/20/18 - Lizzy Refactored. To support ICD-10 Code. Health_condition_description::icd_code
    // 07/18/18 - Lizzy Refactored. Directly save the full description into db, no need to transfer when display
    // 12/20/17 - JV - TODO: Use only to display health conditions on profile...may need to refactor bc 'AMI' does not need to be formatted
    return str.split('::')[0] //.replace(/_/gi, ' ').split(' ').map( val => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()).join(' ');
  }
}

export const FormHelper = {
  getValue : (fields)=>{
    const result = {}
    let f = false
    _.each(fields, (v, k)=>{
      if(v.errors){
        f = true
        return false
      }
      result[k] = v.value
    })

    if(f) return false
    return result
  },
  initValue : (initData)=>{
    const result = {}
    _.each(initData, (value, key)=>{
      result[key] = {
        name : key,
        ...value
      }
    })

    return result
  }
}

export const getRouterQuery = (state, key)=>{
  const loc = state.routing.location
  if(!loc) {
    return null;
  }
  if(!key){
    return loc.query
  }
  return loc.query[key] || null
}
export const getRouterParam = (state, key)=>{
  const loc = state.routing

  if(!key){
    return _.get(loc,'params',null);
  }
  return _.get(loc,`params.${key}`,null);
}

export const  getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const GQLHelper = {
  formatError : (err)=>{
    const MSG = 'An error occurred'
    const code = _.get(err, 'graphQLErrors.0.code');
    const error = _.get(err, 'graphQLErrors.0')
    let errMsg;
    if (code && code < 2000000) { // only show user errors
      errMsg = I18N.get(`errors.${code}`);
    }

    if(errMsg !== `errors.${code}`){
      return errMsg || MSG
    }

    if(_.get(error, 'message')){
      return _.get(error, 'message')
    }
    console.log(err);

    return 'An error occurred'
    // return error.message.replace('GraphQL error: ', '')
  },
  wrapper : (gqlList, container)=>{
    let RS = container
    _.each(gqlList, (gql)=>{

      RS = gql(RS)
    })

    return RS
  },
  createPageContainer,
  combine
}

export const PubNubHelper = {
  formatError: err => {
    console.log('err: ', err);
    const MSG = 'An error occurred';
    const code = _.get(err, 'errorData.status'); // 403
    const error = _.get(err, 'errorData.message'); // Forbidden
    const error_category = _.get(err, 'operation'); // "PNHistoryOperation"
    let errMsg;
    if (code && code < 2000000) { // only show user errors
      errMsg = I18N.get(`errors.${code}`) + ' ' + error_category;
      console.log('errMsg: ', errMsg);
    }

    if(errMsg !== `errors.${code}`){
      return errMsg || MSG;
    }

    return 'An error occurred';
  }
}

export const setPagePath = (path)=>{
  const pathBox = document.getElementById('vsm-path-box')
  if (!pathBox) return
  _.delay(()=>{
    pathBox.innerHTML = path
  }, 10)
}

// Asynchronous version of `setRouteLeaveHook`.
// Instead of synchronously returning a result, the hook is expected to
// return a promise.
export const setAsyncRouteLeaveHook = (router, route, hook) => {
  let withinHook = false
  let finalResult = undefined
  let finalResultSet = false
  router.setRouteLeaveHook(route, nextLocation => {
    if(!sessionStorage.getItem('authToken')) return  //if logged out, do nothing

    withinHook = true
    if (!finalResultSet) {
      hook(nextLocation).then(result => {
        finalResult = result
        finalResultSet = true
        if (!withinHook && nextLocation) {
          // Re-schedule the navigation
          router.push(nextLocation)
        }
      })
    }
    const result = finalResultSet ? finalResult : false
    withinHook = false
    finalResult = undefined
    finalResultSet = false
    return result
  })
}

export const sortStringAlphabetical = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0
}

export const genderMap = {
  F: 'Female',
  M: 'Male'
}

export const programConditionMap = {
  'DIABETES': 'Diabetes',
  'HEART_FAILURE': 'Heart Failure',
  'AMI': 'AMI',
  'HYPERTENSION': 'Hypertension'
//  'OTHER': 'Other',  //comment out because adminProgramList graphql does not have this option
}

export const getErrorMessage = (err) => {
  return _.get(err, 'message') || _.get(err, 'graphQLErrors.0.message.message') || _.get(err, 'graphQLErrors.0.message') || err || ''
}

export const formatErrorMessage = (err) => {
  let errM = getErrorMessage(err)
  if(!!errM.match(/GraphQL error: A user with that /) && !errM.match(/GraphQL error: A user with that email/)) {
    return `An account with this ${I18N.get('keywords.NRIC/FIN')} already exists`
  }
  switch (errM) {
    case 'GraphQL error: A user with that PRN already exists':
      return `An account with this ${I18N.get('keywords.NRIC/FIN')} already exists`
    case 'GraphQL error: A user with that email already exists':
    case "GraphQL error: Cannot assign to read only property 'name' of object 'GraphQLError: A user with that $0 already exists'":
      return 'An account with this email already exists'
    case 'GraphQL error: on board token or user not in db':
      return 'Onboard token or user invalid'
    case 'GraphQL error: User was not found':
      return 'Invalid Login ID or Password'
    case 'GraphQL error: Incorrect password':
      return 'Incorrect password'
    case 'GraphQL error: Document saving failed: This user doesn\'t have this token':
    case 'GraphQL error: user token is not valid':
      return 'Incorrect user token'
    case 'GraphQL error: verify authy code error, {}':
      return 'Incorrect code'
    case 'GraphQL error: jwt malformed':
      return 'This user is a special user created for testing only, please try this feature on the user accounts that you have created'
    default:
      return errM
  }
}

export const validateAntdForm = (formRef) => {
  return new Promise((resolve, reject) => {
    formRef.getFormData((isValid, value) => {
      if(isValid){
        return resolve(value);
      }
      return resolve(isValid);
    })
  })
}

const isScheduleValid = (fieldsValue, formRef) => {
  const vitalType = _.get(fieldsValue, 'vitalType.value')
  const errors = []
  const keyMap = {
    frequency: 'frequencyValue',
    slot: 'timeSlotValue',
    meal: 'timeSlotValueForMeal'
  }
  const schedule = _.get(fieldsValue, 'schedule.value')
  let currentFrequency = schedule.frequency
  if (vitalType === 'Blood Glucose' && currentFrequency === 'slot') {
    currentFrequency = 'meal'
  }
  const valueObject = schedule[keyMap[currentFrequency]]
  switch (currentFrequency) {
    case 'weeklyMeal':
      if(!schedule.weeklyMealSchedule) {
        errors.push('Please select a schedule')
      }
      break
    case 'frequency':
      if (!valueObject.times) {
        errors.push('Please enter frequency value')
      }
      break
    case 'slot':
      //if antd validation has error, valueObject is undefined
      if(!valueObject){
        //below is important to reset validation
        formRef._form.props.form.setFields({
          schedule: {
            value: schedule,
            errors: null
          },
        })
        return false;
      }

      if (valueObject.length === 0) {
        errors.push('Please add at least one time slot ')
      }
      let errStr = ''
      valueObject.forEach((v, i) => {
        if (!_.get(v, 'value[0]') || !_.get(v, 'value[1]')) {
          errStr += `Time Slot ${i + 1}, `
        }
      })
      errStr = errStr.slice(0, -2)
      if (errStr) {
        errors.push(`Please select time for ${errStr}`)
      }
      else {
        valueObject.forEach((v, i) => {
          const startTime = _.get(v, 'value[0]');
          const endTime = _.get(v, 'value[1]');
          if (!(endTime.format('hh:mm a') === '00:00')) {
            if (endTime.isSameOrBefore(startTime,'HHmm')) {
              errStr += `Time Slot ${i + 1}, `
            }
          }
        })
        errStr = errStr.slice(0, -2)
        if (errStr) {
          errors.push(`Invalid start and end time for ${errStr}`)
        }
      }
      break
    case 'meal':
      if (valueObject.length === 0) {
        errors.push('Please select meal slot value')
      }
      break
    case undefined:
      errors.push('Schedule is required')
      break
    default:
      break
  }
  formRef._form.props.form.setFields({
    schedule: {
      value: schedule,
      errors: errors.length > 0 ? errors : null
    },
  })
  if (errors.length > 0) {
    return false
  } else {
    return true
  }
}

const validateWeightThreshold = (fieldsValue) => {
  const isWeightVital = _.get(fieldsValue, 'vitalType.value') === 'Weight'
  const isBaseline = _.get(fieldsValue, 'weightBasedOn.value') === 'Baseline'
  const baselineValue = _.get(fieldsValue, 'HS.value.4')
  return isWeightVital && isBaseline && !baselineValue ? false : true
}
// use custom validation method due to antd's validateAntdForm method has bug,
// it will invoke `onFieldsChange` to validate then display error message,
// in our case, will dispatch FORM_FIELDS_CHANGE, and change redux/state,
// however sometimes it will get old redux value, and set back for this wrong value,
// if this happens, use below validation
export const validateAntdFormWithFieldsValue = async (fieldsValue, formRef, userRole='ADMIN:Admin') => {
  let isFormValid = true;
  let error = '';
  const antdValid = await validateAntdForm(formRef);
  if(!antdValid) {
    isFormValid = false
    error = 'Please check required fields';
  }
  if (!fieldsValue) {
    isFormValid = false
    return [isFormValid, error]
  }
  if (!fieldsValue.hasOwnProperty('schedule') || !_.get(fieldsValue, 'schedule.value')) {
    formRef._form.props.form.setFields({
      schedule: {
        value: undefined,
        errors: ['Schedule is required'],
      },
    })
    isFormValid = false
    error = error || 'Schedule is required';
  } else {
    Object.keys(fieldsValue).forEach(key => {
      // FIXME: validateAntdForm can not get right schedule value from redux
      if (key === 'schedule' && !isScheduleValid(fieldsValue, formRef)) {
        isFormValid = false
        error = error || 'Schedule is invalid';
      // } else if (fieldsValue[key].errors) {
      //   isFormValid = false
      } else if (userRole === 'PROVIDER' && !validateWeightThreshold(fieldsValue)) {
        formRef._form.props.form.setFields({
          HS: {
            value: fieldsValue.HS.value,
            errors: ['Please Set Baseline value for Threshold'],
          }
        })
        isFormValid = false
        error = error || 'Please Set Baseline value for Threshold';
      }
    })
  }
  return [isFormValid, error]
}

export const updateSelectedRowKeys = (assessmentListData, onCheckTableRow, program, assessmentType='ASSESSMENT') => {
  if (!assessmentListData || assessmentListData.length === 0 || !program.tasks) return
  const assessmentsId = assessmentListData.map(assessment => assessment._id)
  const assessmentsIdInTasks = program.tasks.filter(task => task.type === assessmentType).map(task => task.assessment._id)
  const selectedRowKeys = []

  assessmentsIdInTasks.forEach(taskAssessmentId => {
    selectedRowKeys.push(_.find(assessmentsId, id => id === taskAssessmentId))
  })
  return selectedRowKeys;
  // onCheckTableRow(selectedRowKeys)
}

export const getQRCodeFromString = (string, size=200)=>{
  return `${config.SERVER_URL}/qrcode/get?string=${encodeURIComponent(string)}&size=6`
  //return `http://api.qrserver.com/v1/create-qr-code/?size=${size}*${size}&data=${encodeURIComponent(string)}`
}

export const SuperContainer = class extends React.Component{

  render(){
    if(this.props.loading){
      return this.renderLoading()
    }
    return this.renderMain()
  }
  renderLoading(){
    return <IHLoading />
  }
  renderMain(){
    return null
  }
  componentDidMount(){
    if(!this.props.loading){
      this.runOnceAfterDataLoading(this.props)
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.loading && !this.props.loading) {
      this.runOnceAfterDataLoading(this.props)
    }
  }

  runOnceAfterDataLoading(){}
  runAfterUpdate(){}
}

export const DataHelper = {
  getCurrentUser(){
    const user =  sessionStorage.getItem('currentUser')
    return user ? JSON.parse(user) : null
  }
}

let ResetStateArray = {}
export const RouterHelper = {
  updatePage : {
    init : ()=>{
      _.each(ResetStateArray, (func, key)=>{
        func()
      })
      // ResetStateArray = _.compact(ResetStateArray)
    },
    register : (key, callback)=>{
      ResetStateArray[key] = callback

    }
  }
}

export const submittingStyle = (isSubmitting) => {
  return isSubmitting ? {backgroundColor: 'white'} : {}
}

//For VET-80, if exactly 30 days , show as 1 month; if 31 days , show as 31 days; if 40 days , show as 40 days; if 60 days, show as 2 months  ..
export const showMonthsOrDays = (days, type) => {
    if (days % 30 === 0) {
        const monthDiff = days / 30
        if (type === 'array')
            return [monthDiff, 'MONTH']
        else
            return monthDiff + ((monthDiff === 1) ? ' month' : ' months')
    } else {
        if (type === 'array')
            return [days, 'DAY']
        else
            return days + ((days === 1) ? ' day' : ' days')
    }
}


// type : GET, POST
export const asyncXMLHttpRequest = (type, url) => {
    return new Promise(function(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open(type, url);
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);

            } else {
                reject('Error ',xhr.status);
            }
        }
        xhr.onerror = (e) => {
            reject('XMLHttpRequest Error:', e);
        };
        xhr.send();
    });
}

export const getUserAccess = () => {
  if(! RequestCache.get('userAccess')){
    //get from session storage and put into request cache, if no value for 'userAccess', mean user can view everything
    const userAccess = decryptUserAccess()
    if(!userAccess)
      return [];

    RequestCache.set('userAccess', JSON.parse(userAccess));
  }

  return RequestCache.get('userAccess');
}

export const getRefIDWithRoleCategory = (categoryAndName) => {

  if(! RequestCache.get('appRoles')){

    if(!sessionStorage.getItem('appRoles'))
      return [];

      RequestCache.set('appRoles', JSON.parse(sessionStorage.getItem('appRoles')) );
  }

  return RequestCache.get('appRoles')[categoryAndName];
}

export const sortSelectedAssessmentsOnTop = (assessmentListData, selectedIds) => {
  assessmentListData = assessmentListData || []
  selectedIds = selectedIds || []
  const selectedAsssessments = assessmentListData.filter(v => selectedIds.includes(v.id))
  const notSelectedAsssessments = assessmentListData.filter(v => !selectedIds.includes(v.id))
  const sortedAssessments = selectedAsssessments.concat(notSelectedAsssessments)
  return sortedAssessments
}


export const TabWrapper = class extends React.Component {
  render() {
  const ownStyle = {
    paddingBottom: '40px'
  }
  const style = Object.assign({}, ownStyle, this.props.style)
    return (
      <div style={style}>
        {this.props.component}
      </div>
    )
  }

}

export function getMonthOfYear(date) {
    return date.getFullYear() * 100 + date.getMonth() + 1;
}

export const TableColumnsWidth = {
  CarePlan: {
    VitalList: {
      VitalType: '15%',
      Schedule: '20%',
      Thresholds: '50%',
      Actions: '15%'
    },
    Assessment: {
      Name: '15%',
      Schedule: '20%',
      Description: '50%',
      Actions: '15%'
    },
    Survey: {
      Name: '15%',
      Schedule: '20%',
      Description: '50%',
      Actions: '15%'
    }
  }
}

Number.prototype.countDecimals = function () {
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0;
}

export const setBPReadingColor = (SBP, DBP) => {
  if (!SBP || !DBP) return '';

  if (SBP >= 180 || DBP >= 120) {
    return 'aha_HypCris';
  } else if ((SBP >= 140 && SBP <= 179) || (DBP >= 90 && DBP <= 119)) {
    return 'aha_HypStage_2';
  } else if ((SBP >= 130 && SBP <= 139) || (DBP >= 80 && DBP <= 89)) {
    return 'aha_HypStage_1';
  } else if ((SBP >= 120 && SBP <= 129) && DBP < 80) {
    return 'aha_ElevatedReading';
  } else if ((SBP >= 90 && SBP <= 119) && (DBP >= 60 && DBP <= 79)) {
    return 'aha_NormalReading';
  } else if (SBP < 90 && DBP < 60) {
    return 'BPLowReading';
  }
  return 'aha_NormalReading'; // fallback
}

export const extractUserName = p => {
  if(typeof p !== 'object' || !_.get(p, 'profile')) {
    console.warn('warn extractUserName: invalid object');
    return '--'; // p arg must be an object of provider, with profile
  }

  let { firstName, lastName, fullName } = p.profile;
  let credentials = [];
  
  if(!fullName && !lastName)
    return '--';

  const splitCredentials = factor => {
    const tokens = _.split(factor, ',');
    credentials = _.slice(tokens, 1);
    return _.first(tokens);
  };

  // fullName, lastName includes credentials. ie: MD, NP, or PA-C...
  // ie. Doe, MD, NP
  if(!lastName) {
    fullName = splitCredentials(fullName);
    const fullNameTokens = _.split(fullName, /\s+/g);
    firstName = _.slice(fullNameTokens, 0, fullNameTokens.length -1).join(' ');
    lastName = _.last(fullNameTokens);
  } else {
    lastName = splitCredentials(lastName);
  }

  if(credentials.length) {
    credentials = _.map(credentials, d => d.trim());
    credentials = `, ${_.join(credentials, ', ')}`;
  }
  
  return {
    fullName,
    firstName,
    lastName,
    credentials
  };
}

// SC-5836, don't need to show credentials
export const getProviderName = (p, showCredentials = false) => {
  const extractedName = extractUserName(p);

  if(typeof extractedName === 'string') 
    return extractedName;  

  let { fullName, firstName, lastName, credentials } = extractedName;
  let providerName = fullName || '--';

  // custom render based on role
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  // MA/MD/RD/CA
  const selectedRole = _.get(ROLE_MAP, currentUser.selectedRole.name);
  const showRegularFullNameWithRoles = ['MA', 'MD'];
  if(selectedRole && _.includes(showRegularFullNameWithRoles, selectedRole))
    return providerName;

  try {
    // format first name and last name
    firstName = _.split(firstName, /\s+/g).map(w => _.toUpper(w).charAt(0) + '.').join('');
    lastName = _.split(lastName, /\s+/g).join('-') || '--';
    credentials = showCredentials ? credentials : '';

    lastName = lastName + ((firstName || credentials) ? ', ' : '');
    providerName = `${lastName}${firstName}${credentials}`;
  } catch (e) {
    console.error('error getProviderName: ', e, firstName, lastName, credentials);
    return providerName;
  }

  // Possible outcomes:
  // Last-name, first initial., credentials
  // Last-name, first initial.
  // Last-name, credentials
  // --, credentials
  // --
  return providerName;
};

// @params
// visitType: current visit type
// visitCategory: current visit category
// preVisitInfo: current visit has previous info => editStatus = true
// @return
// visitRoles: array of roles according to visitType and visitCategory
export const getVisitRoles = (visitType, visitCategory, patientComplexity,preVisitInfo) => {
  if(!visitType || !visitCategory)
    console.error('getVisitRoles: Missing required params');

  const VISIT_ROLES = _.keys(I18N.get('teamRoles')); // default
  let complexity = _.get(patientComplexity, 'level');
  if(_.isNil(complexity) || complexity === COMPLEXITY_ENUM.NULL_CODES)
    complexity = COMPLEXITY_ENUM.COMPLEX;

  let visitRoles = _.get(
    VISIT_TYPE_ROLES_MAP,
    `${visitType}.${complexity}.${visitCategory}`,
    VISIT_ROLES // fallback
  );
  const preVisitRoles = _.get(preVisitInfo, 'visitRoles');
  const preVisitType = _.get(preVisitInfo, 'type');
  const preVisitCategory = _.get(preVisitInfo, 'category')

  if(preVisitType === 'CLINIC_FOLLOW_UP' && visitType === 'INIT'){
    // see Scenario 2 in SC-6275, MA/MD stays if previously set in GENERAL visit
    visitRoles = _.uniq([...preVisitRoles, ...visitRoles]);
  } else if(preVisitType === visitType && preVisitCategory === visitCategory) {
    // based on previous logic
    visitRoles = [...preVisitRoles];
  }

  return visitRoles;
}

export const getUserRole = () => {
  const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
  const selectedRole = _.get(viewerInfo, 'selectedRole.name');
  return ROLE_MAP[selectedRole];
}

export const getOrgProgramParticipation = () => {
  const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
  const programParticipation = _.get(viewerInfo, 'selectedRole.organization.programParticipation');
  return programParticipation;
}

export const orgHasProgramParticipation = (arr)=>{
  const programParticipation = getOrgProgramParticipation();
  if(!Array.isArray(programParticipation))
    return false;
  return _.intersection(programParticipation, arr).length >0 ;
}
export const doesOrgHaveMNT = () => {
  return orgHasProgramParticipation(['MNT']);
};

export const doesOrgHaveCCM = () => {
  return orgHasProgramParticipation(['CCM']);
};

export const doesOrgHaveRPM = () => {
  return orgHasProgramParticipation(['RPM']);
};

export const doesOrgHaveValueBased = ()=>{
  return orgHasProgramParticipation(['VALUE_BASED']);

};

export const getVisitTypeOptions = () => {
  let map = {...VISIT_TYPE_NAME_MAPPING};
  const orgHasMNT = doesOrgHaveMNT && doesOrgHaveMNT();
  if(!orgHasMNT)
    map = _.omit(map, 'MNT');

  return map;
};

export const omitDeep = (value,keys)=>{
  if (typeof value === 'undefined') {
    return {};
  }

  if (_.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      value[i] = omitDeep(value[i], keys);
    }
    return value;
  }

  if (!_.isObject(value)) {
    return value;
  }

  if (typeof keys === 'string') {
    keys = [keys];
  }

  if (!_.isArray(keys)) {
    return value;
  }

  for (var j = 0; j < keys.length; j++) {
    value = _.omit(value, keys[j]);
  }

  for (var key in value) {
    if (_.has(value,key)) {
      value[key] = omitDeep(value[key], keys);
    }
  }

  return value;
};

export const getProviderLanguage = (user) => {
  let providerLanguage = _.get(user, 'profile.providerLanguage');
  if(!providerLanguage) {
    console.warn('invalid user');
    providerLanguage = [];
  }
  if(_.isEmpty(providerLanguage)) {
    providerLanguage.push({
      code: 'EL',
      description: PATLANG['EL']
    });
  }
  return providerLanguage;
}

export const  downloadBlob = (content, filename, contentType)=>{
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}
