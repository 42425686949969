import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Modal, Input, Button, Icon} from 'antd';

const CarePlanCarryOver = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          showModal: null,
          checked: null
        }
    }

    openConfirmModal = (e) => {
      e.preventDefault();
      this.setState({showModal: true});
    }

    handleOk = (e) => {
      e.preventDefault();
      // mutate
      this.setState({showModal: false});
      this.props.carryOverSubmit();
    }

    handleCancel = () => {
      this.setState({showModal: false, checked: false});
    }

    renderModal = () =>{
      const path = _.get(this.props, 'path', '');
      return( <Modal
          title={null}
          className={'previous-care-plan-confirm-modal' + path}
          id={'previous-care-plan-confirm-modal' + path}
          visible={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText='Yes I am done'
          cancelText='Not yet'
          style={{marginTop:30}}
        >
          <p style={{fontWeight: 'bold', fontSize: '16px', padding: '20px'}}> <Icon type='exclamation-circle' theme='filled' style={{color: '#FFB310'}}/>  Are you done with transferring ALL {path} <br/>data from historical care plan to new care plan?</p>
          <p style={{fontSize: '14px', padding: '0px 20px'}}>The {path} data on historical care plan<span style={{fontWeight: 'bold'}}><br/> will be lost, please double check.</span></p>
        </Modal>)
    }

    render(){
      const path = _.get(this.props, 'path');
      const name = path ? _.capitalize(path) : '';
      const content = _.get(this.props, `program.${path}`) || `No related content in historical care plan. It is possible that no ${path} was done. Please check provider notes to be sure.`;
      const disabled = _.get(this.props, 'disabled');
      const checkBoxInfo = _.get(this.props, `program.${path}`) ?
      (<span> If this checkbox is checked upon finishing the worklist, this historical care plan data will be lost.<br/>
      <span style={{paddingLeft: '5px', fontWeight: 'bold', paddingLeft:'23px'}}>Please ensure that any important data has been transferred to the new care plan.</span></span>)
      : 'Do not show this again';
      const {showModal, checked} = this.state;
      const styles = {fontWeight: 'bold', fontSize: '16px', marginBottom: '10px', marginTop: '43px', lineHeight: '19px', color: '#111111'}
        return (
          <div style={{paddingTop: '20px'}}>
            {/* <Divider /> */}
            <div className='carry-over-header' style={styles}>
            Historical Care Plan - {name}
            </div>
              <Input.TextArea disabled={true} value={content} id={`previous-care-plan-${path}`} className={`previous-care-plan ${path}`} autoSize readOnly />
            <div className='previous-care-plan-buttons'>
              <Checkbox id={`previous-${path}-checker`} style={{fontWeight: 'normal'}} disabled={disabled} checked={checked} onChange={(e)=>this.setState({checked: e.target.checked})}>
               {checkBoxInfo}
              </Checkbox>
             <div style={{marginTop: '8px'}}><Button type='primary' disabled={disabled || !checked} size='default' className='previous-care-plan-button' id={`previous-${path}-button`} onClick={this.openConfirmModal}>Save</Button></div>
            </div>
            {showModal && this.renderModal()}
          </div>
        )
    }
}

CarePlanCarryOver.propTypes = {
  program: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  carryOverSubmit: PropTypes.func.isRequired
};

export default CarePlanCarryOver;
