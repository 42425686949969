import React from 'react';
import { Button } from 'antd';
const EnrollButtonComponent = class extends React.Component{

    render() {
        const { enrollButtonOnClick, enrollButtonProps, enrollButtonText, setShowModal } = this.props;
        return <div className='enroll-button'>
                <Button 
                  type='primary' 
                  onClick={e => {
                    if(enrollButtonOnClick) {
                      enrollButtonOnClick(e);
                    }
                    setShowModal(true);
                  }} 
                  {...enrollButtonProps}
                >
                  {enrollButtonText || 'Enroll'}
                </Button>
               </div>
    }
}

export default EnrollButtonComponent;
