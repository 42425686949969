import { constants } from '../../../lib/utils';

export default constants('AWSConnect', 'main', [
  'CONTACT_STATUS',
  'CALL_STATUS',
  'AGENT_STATUS',
  'CCP_STATUS',
  'LAST_CALL_PATIENT_ID',
  'LAST_CALL_PATIENT_NAME',
  'LAST_CALL_START_TIME',
  'LAST_CALL_END_TIME',
  'LAST_CALL_ENROLLEDPROGRAM_ID',
  'INBOUND_ATTRIBUTES',
  'SET_MINIMIZED_NOTE',
]);

export const CONTACT_STATUS_MAP = {
  'onConnecting': 0,
  'onConnected': 1,
  'onEnded': 2,
  'onClosed': 7
}
