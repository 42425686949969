import { Input, Select } from "antd";
import React from "react";
import helper from "../helper";

const createClinicFormConfig = (localThis) => {
    const parentObj = helper.getParentIds(localThis, localThis.props.id, localThis.props.name);
    return [
        [{
            key:'internalName',
            label:'Internal Name *',
            rules: [{ required: true, message: 'Internal Name is required' }],
            inputContent:() => <Input/>,
            span:11
        },
        {
            key:'businessName',
            label:'Business Name',
            offset:1,
            inputContent: () => <Input/>,
            span:12
        }],
        [{
            key:'timeZone',
            label:'Time Zone *',
            rules: [{ required: true, message: 'Time Zone is required' }],
            inputContent:() => helper.generateDropDown(helper.timeList),
            span:11
        },
        {
            key:'areaBelong',
            label:'Parent Area',
            offset:1,
            formatValue:() => _.get(parentObj,'0.parentId'),
            inputContent:() => {
                return (
                     <Select disabled='true'>
                        {
                          _.map(parentObj,({parentId, parentName})=>
                            <Option value={parentId} key={parentId}>
                              {parentName}
                            </Option>
                          )
                        }
                    </Select>
                )
            },
            span:12
        }],
        [{
            key:'description',
            label: 'Notes',
            span: 24,
            inputContent:()=><Input.TextArea rows={5}/>
        }]
    ]
} 

export default createClinicFormConfig;