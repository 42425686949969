import types from 'patientModule/enrolledProgramInfo/Careplan/constants/CommonConstants'
import { commonActions } from '../../../../../lib/helpers/action-helpers';
import VitalListTypes from 'patientModule/enrolledProgramInfo/Careplan/constants/VitalListConstants'

const commonActionsObject = commonActions(types, [
  'changePage'
])

const initVitalList = (selectedProgram)=>{
  return {
    type : VitalListTypes.INIT_DATA,
    selectedProgram
  }
}

const setAssessmentHasEmptySchedule = (hasEmptySchedule) => {
  return {
    type : types.SET_ASSESSMENT_HAS_EMPTY_SCHEDULE,
    hasEmptySchedule,
  }
}

const setSurveyHasEmptySchedule = (hasEmptySchedule) => {
  return {
    type : types.SET_SURVEY_HAS_EMPTY_SCHEDULE,
    hasEmptySchedule,
  }
}

export default {
  ...commonActionsObject,
  initVitalList,
  setAssessmentHasEmptySchedule,
  setSurveyHasEmptySchedule
}
