/**
 * Created by zizhangai on 1/2/17.
 */
// Provider nodes is using this cmponent

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { IHSelect, IHButton, IHLoading, message, moment } from 'ihcomponent'
import { Input, DatePicker, Radio, Col, Modal, Checkbox } from 'antd';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js'
const { RangePicker } = DatePicker;
//mutation
import createProviderNote from 'modulesAll/graphql/createProviderNote'
import { presetNotes } from 'modulesAll/graphql/user'
import providerNoteTagsList from 'modulesAll/graphql/providerNoteTagsList';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import I18N from 'modulesAll/I18N';
import TimerContainer from '../../../timer/containers/TimerContainer';
import actions from '../../../timer/actions/';
import { API } from '../../../timer/query/index.js';
import { durationMap } from '../constants/map';
import '../style/index.scss';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import providerNoteList from 'graphqlModule/providerNoteList'
import _ from 'lodash';
import timezoneList from '../constants/timezoneConstants';
import { HIDE_CATEGORY_IN_PROVIDER_NOTE_CREATION } from '../constants/InterventionsConstants';
import { interacted } from '../../../timerClaimed/actions';
import { EVENT_TYPES } from '../../../idleTime/constant';

const COOLDOWN_INMS = 60000;
const providerNoteTagsMap = I18N.get('providerNoteTags');
const RadioGroup = Radio.Group;
const { stopTimer, updateInterventionDetails } = actions;
const initState = {
  category: null,
  nextCategory: null,
  subCats: [],
  note: '',
  isInputTouched: false,
  isDropDownTouched: false,
  startTime: null,
  stopTime: null,
  startTimeError: false,
  stopTimeError: false,
  validCallTime: true,
  callTimeString: '',
  tag: '',
  pauseDuration: null,
  showConfirmButton: false,
  followUp_S: null,
  isInputTouched_S: false,
  followUp_A: null,
  isInputTouched_A: false,
  followUp_O: null,
  isInputTouched_O: false,
  followUp_P: null,
  isInputTouched_P: false
};

const categoryToFields = {
  'GENERAL': { note: 'note' },
  'NURSING': { note: 'note' },
  'MED_RECONCILIATION': { note: 'note' },
  'CALL_LOG': { note: 'note', startTime: 'startTime', stopTime: 'stopTime' },
  'RE_ENGAGEMENT': { note: 'note', tag: 'tag' },
  'ALERTS': { note: 'note' },
  'UNFLAG': { note: 'note' },
  'PAUSE_FOLLOW_UP': { note: 'note', tag: 'tag', pauseDuration: 'pauseDuration' },
  'FOLLOW_UP_COUNSELING': { note: 'note', tag: 'tag', followUp_S: 'followUp_S', followUp_A: 'followUp_A', followUp_O: 'followUp_O', followUp_P: 'followUp_P' }, 'TASK_NOTES': { note: 'note' }
};

class InterventionNoteForm extends Component {
  static displayName = 'AddInterventionNoteFormComponentNoReading'

  constructor(props) {
    super(props);
    const category = this.props.defaultCategory;
    const initialNote = (() => {
      if (category === 'CALL_LOG' && props.setCallLogNotes) {
        return props.callLogNotes;
      }
      return '';
    })();
    this.state = {
      category,
      subCats: [],
      note: initialNote,
      isInputTouched: false,
      isDropDownTouched: false,
      patientNameInCall: this.props.patientNameInCall,
      startTime: this.props.callLogStartTime,
      stopTime: this.props.callLogEndTime,
      startTimeError: false,
      stopTimeError: false,
      validCallTime: true,
      callTimeString: '',
      tag: null,
      pauseDuration: null,
      showConfirmButton: false,
      followUp_S: null,
      isInputTouched_S: false,
      followUp_A: null,
      isInputTouched_A: false,
      followUp_O: null,
      isInputTouched_O: false,
      followUp_P: null,
      isInputTouched_P: false,
      isPopupFromAWSConnect: false,
      patientIdFromAWSConnect: null,
      techCall: false
    }
    this._getButtonProps = this._getButtonProps.bind(this)
    this._submit = this._submit.bind(this)
    this._getDropDownProps = this._getDropDownProps.bind(this)
  }

  onChange(value) {
    const startTime = value[0];
    const stopTime = value[1];
    const startString = moment(startTime).format('MM-DD-YYYY HH:mm');
    const startTimeError = !!startTime;
    const stopTimeErro = !!stopTime;
    const isInTheFuture = moment(stopTime).isAfter(new Date());

    let validCallTime = true;
    if (startTime && stopTime) {
      if (stopTime.format('HH:mm:ss') === startTime.format('HH:mm:ss') || isInTheFuture) {
        validCallTime = false;
      }
    }
    const stopString = moment(stopTime).format('MM-DD-YYYY HH:mm');
    const callTimeString = startString + ' - ' + stopString;
    this.setState({
      startTime,
      stopTime,
      startTimeError: !startTimeError,
      stopTimeError: !stopTimeErro,
      callTimeString,
      validCallTime
    })
  }

  onOk(value) {
    const startTime = value[0];
    const stopTime = value[1];
    const startString = moment(startTime).format('MM-DD-YYYY HH:mm');
    const startTimeError = !!startTime;
    const stopTimeErro = !!stopTime;
    const isInTheFuture = moment(stopTime).isAfter(new Date());
    let validCallTime = true;
    if (startTime && stopTime) {
      if (stopTime.format('HH:mm:ss') === startTime.format('HH:mm:ss') || isInTheFuture) {
        validCallTime = false;
      }
    }
    const stopString = moment(stopTime).format('MM-DD-YYYY HH:mm');
    const callTimeString = startString + ' - ' + stopString;
    this.setState({
      startTime,
      stopTime,
      startTimeError: !startTimeError,
      stopTimeError: !stopTimeErro,
      callTimeString,
      validCallTime
    })
  }




  renderCallLog() {
    const { startTimeError, startTime, stopTime, validCallTime} = this.state;
    let errorMsg = startTimeError ? "Please Select Call Date" : (validCallTime ? '' : 'Invalid time selection');
    // check selected stop time is later than now
    const isInTheFuture = moment(stopTime).isAfter(new Date());
    errorMsg = isInTheFuture ? 'Cannot select a future time period' : errorMsg;
    const hasError = (startTimeError || !validCallTime || isInTheFuture);
    const errorDiv = hasError ? <div className="ant-form-explain" style={{ display: hasError ? null : 'none' }}>{errorMsg}</div> : '';

    const dateFormat = 'MM-DD-YYYY HH:mm:ss';
    let start = this.props.callLogStartTime ? moment(this.props.callLogStartTime) :
                startTime ? moment(startTime) :
                moment(new Date(), dateFormat);
    let end = this.props.callLogEndTime ? moment(this.props.callLogEndTime) :
                stopTime ? moment(stopTime) :
                moment(new Date(), dateFormat);

    return <div className='row callLog'>

            <div  className={`col-lg-12`}  style={{'display': 'flex', alignItems: 'flex-end'}}>
              <span style={{ fontWeight: '600', color: '#474747' }}> Call Time </span>
            </div>

            <div className={`col-lg-12 ${hasError ? 'has-error' :''}`}  style={{'display': 'flex', alignItems: 'flex-end'}}>
                <RangePicker
                    showTime={{
                        format:"HH:mm",
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:00', 'HH:mm:ss')],
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                    }}
                    value={[ start, end ]}
                    format= {dateFormat}
                    placeholder={['Start Time', 'End Time']}
                    onOk={(value)=>this.onOk(value)}
                    onChange={(value)=>this.onChange(value)}
                />
            </div>
            <div className={`col-lg-12 ${hasError ? 'has-error' :''}`}>
                {errorDiv}
            </div>
        </div>
    }

  renderProviderNoteTags(category) {
    const providerNoteTagList = _.get(this.props, 'providerNoteTagList', []);
    const reengageArr = this.sortArr(providerNoteTagList);
    const filteredTags = _.filter(reengageArr, (t) => category ? t.category == category : !t.category);
    return filteredTags;
  }

  sortArr = (providerNoteTagList) => {
    const arr = ["CALLED_NO_ANSWER", "CALLED_LEFT_VOICE_MAIL", "CALLED_OUT_OF_SERVICE", "SENT_CHAT", "CALLED_VOICE_MAIL_FULL"]
    const reengageArr = [];
    _.forEach(arr, (key) => {
      _.forEach(providerNoteTagList, (o) => {
        if(o.key === key) {
          reengageArr.push(o);
        }
      })
    })
    _.forEach(providerNoteTagList, (o) => {
      if(o.category !== null) {
        reengageArr.push(o);
      }
    })
    return reengageArr;
  }

  renderReEngagementTags() {
    const filteredTags = this.renderProviderNoteTags();
    const { category, tag } = this.state;

    const radioGroup = filteredTags.map((r, i) => (
      <Col span={12} key={i} style={{ textAlign: 'start' }}>
        <Radio value={r.key} key={i}>
          {providerNoteTagsMap[r.key]}
        </Radio>
      </Col>
    ));
    const errorMsg = 'Please Select tag';
    const onChange = (tag) => this.setState((state) => {
      return {
        tag
      }
    });
    const hasError = category && tag === false;
    const errorDiv = hasError ? <div className='ant-form-explain'>{errorMsg}</div> : '';
    return <div className={`col-lg-12 ${hasError ? 'has-error' : ''}`}>
      <RadioGroup onChange={(e) => onChange(e.target.value)} value={this.state.tag} >
        {radioGroup}
      </RadioGroup>
      {errorDiv}
    </div>
  }

  renderDurationSelect() {
    const { pauseDuration } = this.state;
    const option = _.map(durationMap, ({ name, value }) => ({
      name, value
    }));
    const pSelect = {
      option,
      placeholder: 'Select...',
      value: pauseDuration,
      onChange: (pauseDuration) => {
        this.setState({
          pauseDuration
        })
      },
      size: "large",
    }

    return <IHSelect {...pSelect} style={{ width: '200px' }} />
  }

  renderPauseFollowUpTags() {
    const filteredTags = this.renderProviderNoteTags('PAUSE_FOLLOW_UP');
    const option = _.map(filteredTags, ({ key, description }) => ({
      name: description,
      value: key
    }));

    const pSelect = {
      option,
      placeholder: 'Select...',
      value: this.state.tag,
      onChange: (v) => {
        this.setState({ tag: v })
      },
      size: "large",
    }

    return <IHSelect {...pSelect} style={{ width: '200px' }} />
  }

  renderPauseFollowUp() {

    const { pauseDuration, tag } = this.state;
    const resumeDate = moment().add(pauseDuration, 'days').format('MM/DD/YYYY');
    const tagHasError = tag === false;
    const pauseDurationHasError = pauseDuration === false;
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div className="intervention-notes-form-header" ><span>Reason</span></div>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={`${tagHasError ? 'has-error' : ''}`} >
          {this.renderPauseFollowUpTags()}
          {tagHasError ? <div className='ant-form-explain'>Please Select Reason</div> : ''}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div className="intervention-notes-form-header" ><span>Duration</span></div>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={`${pauseDurationHasError ? 'has-error' : ''}`}>
          {this.renderDurationSelect()}
          {pauseDurationHasError ? <div className='ant-form-explain'>Please Select Duration</div> : ''}
        </div>
      </div>
      {pauseDuration ? <div className="intervention-notes-form-header" ><span>Est. Return Date: </span>{resumeDate}</div> : ''}
    </div>
  }

  renderFollowUpCounseling() {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(this.state.isInputTouched_S && !this.state.followUp_S ? ' has-error' : '')}>
          <div className="intervention-notes-form-header" >
            <span>S(Subjective)</span>
          </div>
          <Input.TextArea autosize={{ minRows: 2, maxRows: 2 }}
            value={this.state.followUp_S}
            size={"large"}
            className={"followNote"}
            onChange={(e) => {
              this.setState({ followUp_S: e.target.value, isInputTouched_S: true })
            }}
          />
          <div className="ant-form-explain"
            style={{ display: (this.state.isInputTouched_S && !this.state.followUp_S) ? null : 'none' }}>Please Input Subjective.</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(this.state.isInputTouched_O && !this.state.followUp_O ? ' has-error' : '')}>
          <div className="intervention-notes-form-header" >
            <span>O(Objective)</span>
          </div>
          <Input.TextArea autosize={{ minRows: 2, maxRows: 2 }}
            value={this.state.followUp_O}
            size={"large"}
            className={"followNote"}
            onChange={(e) => {
              this.setState({ followUp_O: e.target.value, isInputTouched_O: true })
            }}
          />
          <div className="ant-form-explain"
            style={{ display: (this.state.isInputTouched_O && !this.state.followUp_O) ? null : 'none' }}>Please Input Objective.</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(this.state.isInputTouched_A && !this.state.followUp_A ? ' has-error' : '')}>
          <div className="intervention-notes-form-header" >
            <span>A(Assessment)</span>
          </div>
          <Input.TextArea autosize={{ minRows: 2, maxRows: 2 }}
            value={this.state.followUp_A}
            size={"large"}
            className={"followNote"}
            onChange={(e) => {
              this.setState({ followUp_A: e.target.value, isInputTouched_A: true })
            }}
          /><div className="ant-form-explain"
            style={{ display: (this.state.isInputTouched_A && !this.state.followUp_A) ? null : 'none' }}>Please Input Assessment.</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', marginBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(this.state.isInputTouched_P && !this.state.followUp_P ? ' has-error' : '')}>
          <div className="intervention-notes-form-header" >
            <span>P(Plan)</span>
          </div>
          <Input.TextArea autosize={{ minRows: 2, maxRows: 2 }}
            value={this.state.followUp_P}
            size={"large"}
            className={"followNote"}
            onChange={(e) => {
              this.setState({ followUp_P: e.target.value, isInputTouched_P: true })
            }}
          />
          <div className="ant-form-explain"
            style={{ display: (this.state.isInputTouched_P && !this.state.followUp_P) ? null : 'none' }}>Please Input Plan.</div>
        </div>
      </div>
    </div>
  }

  renderInputContentBasedOnCategory(category) {
    const content = {
      'CALL_LOG': this.renderCallLog(),
      'RE_ENGAGEMENT': this.renderReEngagementTags(),
      'PAUSE_FOLLOW_UP': this.renderPauseFollowUp(),
      'FOLLOW_UP_COUNSELING': this.renderFollowUpCounseling()
    }
    return _.get(content, category, '');
  }

  validInputBasedOnCategory(category) {
    const valid = {
      'RE_ENGAGEMENT': () => this.isValidReengageForm(),
      'PAUSE_FOLLOW_UP': () => this.isValidFollowUpForm(),
      'FOLLOW_UP_COUNSELING': () => this.isValidFollowUpCounselingForm()
    }

    return (_.get(valid, category, () => true))();
  }

  render() {
    const displayName = this.constructor.displayName;
    const { patientId, canRestUUid, patientNameInCall } = this.props;
    const { category, showConfirmButton } = this.state;
    if (this.props.loading) {
      return <div style={{ height: '5%' }}><IHLoading /></div>
    }
    const categorySelected = !!this.state.category;
    const sp = this._getDropDownProps()
    const bp = this._getButtonProps()
    const viewingDetails = {
      component: displayName,
      path: window.location.pathname
    };

    return (
      <div className="intervention-notes-form">
        <TimerContainer displayName={displayName} category='PROVIDER_NOTES' action='ENTER'
          viewingDetails={viewingDetails} patientId={patientId}
          canRestUUid={canRestUUid} />

        { (category == 'CALL_LOG' && patientNameInCall != undefined) ?
            <div className="intervention-notes-form-header" style={{ height: '40px'}}>
              <span style={{ fontWeight: '600', color: '#474747'}}> Patient Name:  </span>
              <span style={{ fontWeight: '400', color: '#474747'}}>{ patientNameInCall } </span>
            </div>
            : ''}

        <div className="intervention-notes-form-header" ><span>Select Note Type </span>{/* (* will be sent to NEHR) */}</div>
        <div className={"intervention-notes-dropDown-wrapper " + ((this.state.isDropDownTouched && !this.state.category) ? 'has-error' : '')}
          style={{ display: 'flex', width: '100%', flexDirection: 'column', margin: 0 }}>
          <IHSelect {...sp} style={{ width: '200px' }} />
          {showConfirmButton ? this.renderConfirmButton() : ''}
          <div style={{ paddingTop: 10 }}> {this.renderInputContentBasedOnCategory(category)}</div>
          <div className="ant-form-explain" style={{ display: (this.state.isDropDownTouched && !this.state.category) ? null : 'none' }}>Please Select Note Type</div>
        </div>
        {category != 'FOLLOW_UP_COUNSELING' ? this.renderNotes() : ''}
        <div className="intervention-notes-button-wrapper">
          <IHButton {...bp} />
        </div>
      </div>
    )
  }

  renderNotes() {
    const categorySelected = !!this.state.category;
    const category = this.state.category;
    const CALL_LOG = 'CALL_LOG';
    return (<div className={"intervention-notes-textArea-wrapper" +
      ((this.state.isInputTouched && (!this.state.subCats.some(d => d.checked) && !this.state.note)) ? ' has-error' : '')}>
      <div className="intervention-notes-form-header" style={{margin: '10px 0px'}}>
        <span>Note</span>
        <div style={{ display: category === CALL_LOG ? null : 'none', fontWeight: '400', color: '#4E5054' }}>Please try not to limit your call to just tech issues. Try to review other billable topics as well, like scheduling, reminder on labs, medications, inactivity, vitals monitoring encouragement, etc.</div>
      </div>
      <Input.TextArea autosize={{ minRows: 4, maxRows: 25 }} disabled={!categorySelected}
        value={this.state.note}
        size={"large"}
        onChange={(e) => {
          const note = e.target.value;
          this.setState({ note, isInputTouched: true });
          if (this.props.setCallLogNotes) this.props.setCallLogNotes(note);
        }}
      />
      <div className="ant-form-explain"
        style={{ display: (this.state.isInputTouched && (!this.state.subCats.some(d => d.checked) && !this.state.note)) ? null : 'none' }}>Please Input Note</div>
        <Checkbox onChange={() => this.setState({techCall: !this.state.techCall})} style={{ display: category === CALL_LOG ? null : 'none', margin: '10px 0px' }}>
          <span style={{fontWeight: 400}}>This call is about tech assistance or troubleshooting with the patient <strong style={{fontWeight: 800}}>ONLY</strong> (non-billable).</span>
        </Checkbox>
    </div>
    )
  }

  _checkBoxOnChange(e) {
    this.setState({
      subCats: this.state.subCats.map(n => (n.value !== e.target.value) ? n : { ...n, checked: e.target.checked }),
      note: e.target.checked ? (this.state.note ? `${this.state.note}\r\n${e.target.value}`
        : e.target.value)
        : this.state.note
    })
    // delay this action so user can see checkbox change
    setTimeout(() => {
      this.setState({
        isInputTouched: true
      })
    }, 300)
  }

  fieldsChanged(v) {
    let flag = false;
    const fields = Object.keys(categoryToFields[v]);
    _.each(fields, (f) => { flag = (flag || !!_.get(this.state, f)); });

    return flag;
  }

  _getDropDownProps() {
    const { category } = this.state;
    let notes = JSON.parse(sessionStorage.getItem('presetNotes'));
    //remove SMART_ALERT category from creating PN drop down
        notes = _.filter(notes,(n)=>n.category!=='SMART_ALERT'&&n.category!=='CALL_LOG'&&n.category!=='PAUSE_FOLLOW_UP');
    const option = notes.map(n => ({
      value: n.category,
      name: I18N.get(`providerNoteCat.${n.category}`)
    }));

    let filteredOption = option.slice(1);
    filteredOption = filteredOption.filter(x => !HIDE_CATEGORY_IN_PROVIDER_NOTE_CREATION.includes(x.value))
    const pSelect = {
      option: filteredOption,
      placeholder: 'Select...',
      value: this.state.category,
      onSelect: (v) => {
        if (v == category) return;
        if (category && this.fieldsChanged(category)) {
          this.setState({
            showConfirmButton: true,
            nextCategory: v
          })
        } else {
          this.setState({
            category: v,
            subCats: _.find(notes, ['category', v]).subCategories.map(n => ({ value: n, checked: false })),
            note: _.find(notes, ['category', v]).subCategories.join('\r\n'),
            tag: '',
            startTime: null,
            stopTime: null,
            startTimeError: false,
            stopTimeError: false,
            validCallTime: true,
            isInputTouched: false,
            isDropDownTouched: false,
            pauseDuration: null,
            followUp_S: null,
            isInputTouched_S: false,
            followUp_A: null,
            isInputTouched_A: false,
            followUp_O: null,
            isInputTouched_O: false,
            followUp_P: null,
            isInputTouched_P: false
          })
        }
      },
      size: "large",
    }
    return pSelect
  }
  _getButtonProps() {
    const { category } = this.state;
    const bp = {
      label: 'Submit',
      bsStyle: 'primary',
      ref: 'btn_sub_note',
      disabled: category === 'FOLLOW_UP_COUNSELING' ?
        (this.state.followUp_S && this.state.isInputTouched_O && this.state.followUp_P && this.state.followUp_S ? false : true) : (this.state.note.trim() === ''),

      onClick: this._submit
    }
    return bp
  }

  renderConfirmButton() {
    const onOk = (state) => {
      this.setState({
        ...initState,
        category: state.nextCategory,
        nextCategory: null
      });
    };

    return <Modal visible={true} zIndex={2000} onOk={() => onOk(this.state)}
      onCancel={() => this.setState({ showConfirmButton: false })}
    >
      You have unsaved changes. Are you sure you want to change the category? You will lose your changes.
             </Modal>
  }

  createCallLogTimer(docId) {
    let variables;
    const clientEvents = [];
    const { startTime, stopTime, techCall } = this.state;
    const { sessionId } = this.props;
    const userId = this.props.patientId || window.location.pathname.split('/')[2];
    const viewerId = sessionStorage.getItem('currentUserId');
    const category = 'CALL_LOG';
    const action = 'ENTER';
    let start = this.props.callLogStartTime ? moment(this.props.callLogStartTime) :
                startTime ? moment(startTime) :
                moment(new Date(), dateFormat);
    let end = this.props.callLogEndTime ? moment(this.props.callLogEndTime) :
                stopTime ? moment(stopTime) :
                moment(new Date(), dateFormat);

    var totalTime = end - start;

    const startTimeType = typeof startTime === 'string' || startTime instanceof String;
    if (startTimeType) {
      let s1 = moment(start, "YYYY-MM-DD HH:mm:ss");
      let s2 = moment(end, "YYYY-MM-DD HH:mm:ss");
      totalTime = s2.diff(s1);
    }
    let interventionDetails = [{
      apiName: 'createCallLog',
      resource: 'provider_notes',
      docId: docId,
      summary: 'call log'

    }]

    let viewingDetails = {
      component: this.constructor.displayName,
      path: window.location.pathname
    }

    clientEvents.push({
      event: 'startCall',
      action: 'START_TIMER',
      ts: start
    })

    clientEvents.push({
      event: 'endCall',
      action: 'STOP_TIMER',
      ts: end
    })

    variables = {
      clientEvents: clientEvents,
      startTime : start,
      stopTime: end,
      userId,
      viewerId,
      category,
      action,
      totalTime,
      interventionDetails,
      viewingDetails,
      sessionId,
      timeout: COOLDOWN_INMS,
      techCall
    }

    API.createTimeTrackingEvent(variables);

  }

  isValidFollowUpForm() {
    const { pauseDuration, tag } = this.state;
    let isValid = true;
    if (pauseDuration === null) {
      this.setState({
        pauseDuration: false
      })
      isValid = false;
    }
    if (tag === '') {
      this.setState({
        tag: false
      })
      isValid = false;
    }

    return tag && pauseDuration && isValid;

  }

  isValidFollowUpCounselingForm() {
    const { followUp_S, followUp_A, followUp_O, followUp_P } = this.state;
    let isValid = true;
    if (!followUp_S) {
      isValid = false;
      this.setState({
        isInputTouched_S: true
      });
    }
    if (!followUp_A) {
      isValid = false;
      this.setState({
        isInputTouched_A: true
      });
    }
    if (!followUp_O) {
      isValid = false;
      this.setState({
        isInputTouched_O: true
      });
    }
    if (!followUp_P) {
      isValid = false;
      this.setState({
        isInputTouched_P: true
      });
    }
    return isValid;

  }

  isValidReengageForm() {
    const { tag } = this.state;
    let isValid = true;
    if (tag === '') {
      this.setState({
        tag: false
      })
    };
    return tag && isValid;
  }
  getRefetchPNListVariables = ()=>{
      const ownProps = this.props;
      const variables = {
          'filters': {
              'enrolledProgramId': ownProps.programId,
              ...ownProps.filter,
          },
          page: _.get(ownProps, 'page.current', 1),
          count: 10,
          sort: { 'field': 'CREATED_AT', 'direction': 'DESC' }
      }
      const sortField = _.get(ownProps, 'sort.fieldGraph', null);
      if (sortField) {
          variables.sort = {
              field: sortField,
              direction: _.get(ownProps, 'sort.direction', 'DESC'),
          };
      }
      return variables;
  }

  handleInteraction = () => {
    const { onInteraction } = this.props; 
    if (onInteraction) {
      onInteraction();
    } 
  }

  _submit = () => {
    const { category, subCats, startTime, stopTime, validCallTime, tag, pauseDuration, followUp_S, followUp_O, followUp_A, followUp_P, callTimeString, note, techCall} = this.state
    const { patientId, mutate, programId, programName, organizationId, status} = this.props;
    const _currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(_currentPatient, 'identification[0].value')
    const patientName = _.get(_currentPatient, 'profile.fullName')
    //check if its during a call
    // if (this.props.turnOnline && !this.props.turnOnline(status)){
    //   return;
    // }

    Mixpanel.track('clicked','submit','alert', {NOTE_TYPE: this.state.category});

    if (category == 'CALL_LOG') {
      if (!startTime) {
        this.setState({
          startTimeError: true
        })
        return;
      }
      if (!stopTime) {
        this.setState({
          stopTimeError: true
        })
        return;
      }
      if (!validCallTime) {
        return;
      }
    }

    if (!this.validInputBasedOnCategory(category)) {
      return;
    }


    if (!category || (!subCats.some(d => d.checked) && (category != 'FOLLOW_UP_COUNSELING' && category && !note))) {
      this.setState({
        isDropDownTouched: true,
        isInputTouched: true
      });
      return
    }

    //if right after call, auto call log pop up
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timezoneAbrr = timezoneList[timezone] || timezone;
    let afterCallString = moment(this.props.callLogStartTime).format('MM-DD-YYYY HH:mm:ss')+' - '+ moment(this.props.callLogEndTime).format('MM-DD-YYYY HH:mm:ss') + ` (${timezoneAbrr})`
    let callString = this.props.callLogStartTime ? afterCallString : callTimeString;
    const providerNoteTag = tag ? (providerNoteTagsMap[tag] + '\n') : '';
    const callTimeStringContent = callString ? (callString + '\n') : '';
    const resumeDate = pauseDuration ? moment().add(pauseDuration, 'day') : null;
    const followUp_s = followUp_S ? ('S:' + followUp_S + '\n') : '';
    const followUp_a = followUp_A ? ('A:' + followUp_A + '\n') : '';
    const followUp_o = followUp_O ? ('O:' + followUp_O + '\n') : '';
    const followUp_p = followUp_P ? ('P:' + followUp_P + '\n') : '';
    const variables = {
      category,
      content: (providerNoteTag + callTimeStringContent + followUp_s + followUp_o + followUp_a + followUp_p + note).trim(),
      enrolledProgramId: programId,
      tags: [tag],
      resumeDate,
      pauseDuration,
      organizationId,
      techCall
    }
    const auditLogDetails = {
      programName,
      category,
      content: subCats.filter(d => d.checked).map(d => d.value).concat([note]).join('\r\n'),
    }
    this.refs.btn_sub_note.loading(true)
    mutate({ variables,
        refetchQueries:[
            { query:getPatientSmartAlert,
              fetchPolicy: 'network-only',
              variables:{ memberId: patientId }
            },
            {
              query:providerNoteList,
              variables:this.getRefetchPNListVariables(),
              fetchPolicy:'network-only'
            }
            ]})
      .then(res => {
        this.handleInteraction();
        const apiName = Object.keys(res.data)[0];
        const docId = res.data[apiName].id;
        const summary = res.data[apiName].content;
        const resource = atob(docId).split(':')[0];
        let displayName = this.constructor.displayName;
        displayName = `${displayName}:${patientId}`;
        const dropDown = this.state.category;

        if (dropDown == 'CALL_LOG') {
          this.createCallLogTimer(docId, techCall);
        }

        this.props.updateInterventionDetails(displayName, apiName, resource, docId, summary);
        this.props.stopTimer(displayName, 'saveModal');

        if (this.props.timerKey) {
          this.props.interacted(this.props.timerKey);
        }

        this.props.closeModal();
        this.setState({...initState});
        message.success('Note Created!')
        createAuditLog({
          action: I18N.get(`auditLog.provider.alerts.create_${this.props.noteType}`),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
        if (this.props.turnOnline) this.props.turnOnline(); // auto-end ACW
        localStorage.removeItem('call-center-last-call');
      })
      .catch((err) => {
        this.refs.btn_sub_note.loading(false)
        createAuditLog({
          action: I18N.get(`auditLog.provider.alerts.create_${this.props.noteType}`),
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false,
          patientNRIC,
          patientName
        })
        this.props.openErrorModal(_.get(err, 'message') || 'Failed to create provider note');
      })
  }


}

const tagsOptions = graphql(providerNoteTagsList, {
  props: ({ data: { loading, providerNoteTagList } }) => {
    if (!loading) {
      return {
        providerNoteTagList: providerNoteTagList.data
      };
    }
  }
})


const variables = {
  options: () => {
    return {
      variables: {
        "id": sessionStorage.getItem('currentUserId')
      }
    }
  },
  props: ({ data: { loading, user, refetch } }) => {
    return {
      user: user || {},
      loading,
      refetch
    }
  }
}
const mapToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const timers = Object.keys(_.pickBy(state.timerClaimed.main, (v, k) => v.patientId === patientId && !v.isDone) || {});
  const timer = timers.length > 0 ? timers[0] : undefined;
  return {
    timerKey: timer ? timer.split(':')[0] : undefined,
    sessionId: state.timer.main.uuid,
    ...state.globalTables.allNotes
  }
}
export default compose(
  graphql(presetNotes, variables),
  graphql(createProviderNote),
  tagsOptions,
  connect(mapToProps, (dispatch, ownProps) => {
    const { patientId } = ownProps;
    return {
      // closeModal: () => dispatch(closeModal()),
      openErrorModal: (err) => dispatch(openErrorModal(err)),
      stopTimer: (displayName, event) => dispatch(stopTimer(displayName, event)),
      updateInterventionDetails: (displayName, apiName, resource, docId, summary) => dispatch(updateInterventionDetails(displayName, apiName, resource, docId, summary)),
      interacted: (id) => dispatch(interacted(id, patientId, EVENT_TYPES.PROVIDER_NOTES)),
    }
  })
)(InterventionNoteForm)
