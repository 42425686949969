import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Popover, Row, Col, Button } from 'antd';
import { IHLoading } from 'ihcomponent';

import { getBoostedOrganizations, getClinicCandidatesAsync } from '../apis/getOrganization';
import withRoleInfo from '../helpers/withRoleInfo';
import ReviewPatientClinicSelectContainer from './ReviewPatientClinicSelectContainer';
import { setClinics } from '../../crossClinic/API/index';

import '../styles/ReviewPatientClinicContainer.scss';

const renderClinicNames = (selectedClinics) => {
  return (
    <div className="review-patient-clinic-container__clinic-names">
      {
        _.map(selectedClinics, ({ organizationId, name }) => (
          <Row
            key={organizationId}
            className="review-patient-clinic-container__clinic-names__row"
          >
            <Col
              className="review-patient-clinic-container__clinic-names__text"
            >
              {name}
            </Col>
          </Row>
        ))
      }
    </div>
  );
};

const ReviewPatientClinicContainer = ({
  boostedOrganizations,
  roleInfo,
  loadingBoostedOrganizations,
  refetchBoostedOrganizations,
  onSubmit,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const { isMD } = roleInfo;

  useEffect(() => {
    if (loadingBoostedOrganizations) return;
    if (!Array.isArray(boostedOrganizations) || !boostedOrganizations.length){
      // first time or something wrong
      if (isMD) {
        // auto select all organization, for new user
        autoSelectClinicCandidates();
        return;
      }
      setShowSelect(true);
    }
  }, [loadingBoostedOrganizations, roleInfo]);

  const autoSelectClinicCandidates = async () => {
    try {
      const res = await getClinicCandidatesAsync();
      const candidates = _.get(res, 'data.getClinicCandidates');
      const candidateIds = _.map(candidates, 'organizationId');
      if (!candidateIds.length); // no-op
      else handleOnSubmitSelectedCandidates(candidateIds);
      return;
    } catch (error) {
      console.error('Failed to auto select clinic, ', error);
    }
    // open to select manually instead if failed
    setShowSelect(true);
  };

  const handleOnSubmitSelectedCandidates = async (candidateIds) => {
    await setClinics({
      boostedOrganizations: candidateIds,
    });
    refetchBoostedOrganizations();
    setShowSelect(false);
    onSubmit();
  };

  const renderSelect = () => {
    if (!showSelect) return null;
    if (showSelect) {
      return (
        <ReviewPatientClinicSelectContainer
          selectedClinicCandidates={boostedOrganizations}
          onSubmit={handleOnSubmitSelectedCandidates}
          setShowClinicModal={setShowSelect}
        />
      );
    }
    return null;
  };

  if (loadingBoostedOrganizations) {
    return (
      <IHLoading />
    );
  }

  return (
    <div className='review-patient-clinic-container'>
      {renderSelect()}
      <Popover
        content={renderClinicNames(boostedOrganizations)}
        trigger="hover"
        placement="rightBottom"
      >
        <Button
          type="link"
          onClick={() => setShowSelect(true)}
        >
          from
          {' '}
          {_.get(boostedOrganizations, 'length') || 0}
          {' '}
          clinics
        </Button>
      </Popover>
    </div>
  );
};

ReviewPatientClinicContainer.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRoleInfo(
  compose(
    getBoostedOrganizations,
  )(ReviewPatientClinicContainer)
);
