import React, { useState } from 'react';
import { Modal } from 'antd';
import { COMPLEXITY_LEVELS,COMPLEXITY_ENUM } from '../../../../lib/constants';

const ComplexityInfoComponent = ({ children, isUnenrolled, type, showNote }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const header = type ? `What does ${COMPLEXITY_LEVELS[type] || type} mean?` : 'What is Condition Complexity Level?';
  let content = type==COMPLEXITY_ENUM.NULL_CODES ? <div style={{ padding: 20 }}>ICD-10 code has been added, but no ICD-10 code has been selected as billable thus patient's Condition Complexity Level can't be defined. Please mark the applicable ICD-10 code as billable in the patient profile > ICD code section.</div>:
      <div style={{ padding: 20 }}>
        <h4 style={{ marginBottom: 20 }}>{header}</h4>
        <p style={{ marginBottom: 20 }}>
          Patient’s Condition Complexity Level is defined based on patient’s conditions and can be changed manually as well.
          For enrolled patients, patients with different Condition Complexity Levels will be managed by different roles in the Unified Care care team.
        </p>
        {isUnenrolled && <p>Once enrolled:</p>}
        <p>
          <span style={{ fontWeight: 'bold' }}>The Non-complex patient</span> will be managed by Health Coach (HC) Or Registered Dietitian (RD) + Clinical Assistant.
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>The Complex patients</span> will be managed by Registered Dietitian (RD) + Clinical Assistant
        </p>
        {showNote && <p style={{ marginTop: 20, color: '#6B7178' }}>
          Note: If you want to change patient’s Conditional Complexity Level,
          you can change in ICD Codes {'-->'} Health Conditions section.
        </p>}
      </div>

  return (
    <React.Fragment>
      <div style={{ display: 'inline-block' }} onClick={(e) => { e.stopPropagation(); setShowInfoModal(true); }}>{children}</div>
      <Modal visible={showInfoModal} onCancel={(e) => { e.stopPropagation(); setShowInfoModal(false); }} footer={null} width={650}>
        {content}
      </Modal>
    </React.Fragment>
  );
};

export default ComplexityInfoComponent;
