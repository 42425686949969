import { combineReducers } from 'redux'
import Common from 'ProgramNewModule/Common/reducers/CommonReducer'
import ProgramInfoForm from 'ProgramNewModule/ProgramInfo/reducers/ProgramInfoFormReducer'

const ProgramNew = combineReducers({
  Common,
  ProgramInfoForm
})

export default ProgramNew
