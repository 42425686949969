import { constants } from 'libModule/utils'

export default constants('enrolledProgramInfo', 'EnrolledProgramInfo', [
  'SET_RESUMING',
  'SET_CHAT',
  'SET_EXTEND_STEP',
  'SET_EXTEND_VALUE',
  'SET_EXTEND_TYPE',
  'RESET_EXTEND_VALUE_TYPE',

])
