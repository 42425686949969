import React from 'react'
import BaseComponent from '../BaseComponent'
import { InputNumber } from 'antd'
import _ from 'lodash'

const Component = class extends BaseComponent{
  defineBaseClassName(){
    return 'IH-InputNumber'
  }
  render(){
    let p = this.getBaseProps()
    return (
      <InputNumber {...p} />
    )
  }
}

Component.propTypes = {
}

export default Component
