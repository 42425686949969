import { combineReducers } from 'redux';
import myAssessmentAndSurvey from '../../edit/reducers';
import ListReducer from './ListReducer';

export default {
  myAssessmentAndSurvey: combineReducers({
    ...myAssessmentAndSurvey,
    ListReducer,
  }),
};
