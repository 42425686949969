import React from 'react';
import MainContainer from '../Components/main';
import Client from 'libModule/gqlClient';
import editUser from 'modulesAll/graphql/mutation/editUser';
import { message } from 'ihcomponent';
import { patientGQL } from "../../../graphql/user";
import phoneListInfo from 'graphqlModule/phoneListInfo.js';
import {compose, graphql} from "react-apollo/index";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'lib/utils';
import { connect } from 'react-redux';
import {DATE_HELPERS} from "../../../../lib/helpers/date-helpers";
import TimerContainer from '../../../timer/containers/TimerContainer';
import * as timerActions from '../../../timer/actions/index';
import { API } from '../../../timer/query/index';
import { mapDispatchToTimerActions } from '../../../timerClaimed/helpers';
import TimerClaimedContainer from '../../../timerClaimed/containers/TimerClaimedContainer';
import { EVENT_TYPES } from '../../../idleTime/constant';

const { stopTimer,updateInterventionDetails }= timerActions.default;
const PATIENT_DETAILS_TIMER = 'PATIENT_DETAILS_TIMER';
class AdditionalInfoContainer extends React.Component{
    static  displayName = 'AdditionalInfoContainer';
    constructor(props){
        super(props);
        this.state = {
            isEditMode:false
        }
    }

    handleEnableEditMode=(enableEdit)=>{
        this.setState({
            isEditMode:enableEdit
        })
    }

    render(){
        const { userId, user, loading, route, phoneInfoList, patientId, currentProgram, consentInfo, timerClaimed } = this.props;
        const { isEditMode } = this.state;
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <div>
                {
                  isEditMode ? <TimerContainer displayName={displayName} category='ADDITIONALINFO' action='UPDATE'
                                              viewingDetails={viewingDetails} patientId={ patientId }
                                              keepEventListener={keepEventListener}/>
                  :''
                }
                <TimerClaimedContainer patientId={patientId} id={PATIENT_DETAILS_TIMER} />
                <MainContainer userId={userId} user={user} phoneInfoList={phoneInfoList}
                               currentProgram={currentProgram}
                               save={this.saveEdit} 
                               isEditMode={isEditMode}
                               handleEnableEditMode={this.handleEnableEditMode}
                               renderHealthConditionPicker = { this.renderHealthConditionPicker }
                               loading = {loading}
                               route={route}
                               consentInfo={consentInfo}
                               onInteraction={(description) => timerClaimed.interacted(EVENT_TYPES.UPDATE_PATIENT_INFO, description)}
                />
             </div>
    }

     saveEdit = async (mutatedValue,stopResetEditMode)=>{
        const { userId, timerClaimed } = this.props;
        const patientId = _.get(this.props,'user.id');
        const variables = DATE_HELPERS.trimAllValues(mutatedValue,'',mutatedValue);

        try{
            const res = await Client.mutate({ mutation:editUser,
                                              variables:variables,
                                              refetchQueries:[{ query:patientGQL, variables: { id:btoa(userId) },
                                              fetchPolicy:'network-only'}]
                                            });

            if(!res.error) {
                message.success('All Changes Saved');
                const apiName = Object.keys(res.data)[0];
                const docId = res.data[apiName].id;
                API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'saveUpdate');
                timerClaimed.interacted(EVENT_TYPES.UPDATE_PATIENT_INFO, 'EDIT');
                if (!stopResetEditMode) {
                    // const apiName = Object.keys(res.data)[0];
                    // const docId = res.data[apiName].id;
                    // API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'saveUpdate');
                    this.handleEnableEditMode(false);
                }
            }
        }
        catch(error){
            this.props.openErrorModal(GQLHelper.formatError(error));
        }
    }
}

const withData = graphql(patientGQL, {
    options: (ownProps)=>{
        const { userId } = ownProps;
            return {
                variables: {
                    id: btoa(userId)
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network'
            }
    },
    props:({data})=>{
        const { loading, user } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        return {
            user
        }
    }
})

const phoneInfo = graphql(phoneListInfo, {
        options: (ownProps) => {
            const memberId = _.get(ownProps, 'patientId');
            return {
                variables: {
                    memberId
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true
            }
        },
        props: ({ data }) => {
            const { loading } = data;
            if(loading){
                return  {
                    loading:loading
                }
            }
            return {
                phoneInfoList: _.get(data, 'phoneReportInfoList.data', []),
            }
        }
    })

const mapDispatch = (dispatch, ownProps) => {
    return {
        openErrorModal: msg => dispatch(openErrorModal(msg)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
        timerClaimed: {
            ...mapDispatchToTimerActions(dispatch, PATIENT_DETAILS_TIMER, ownProps.patientId),
        },
    }
}

export default compose(connect(null,mapDispatch),withData, phoneInfo)(AdditionalInfoContainer);