import gql from 'graphql-tag';
// import Assessment from './schema/Assessment';

export default gql`
query(
  $sort: BroadcastListSort,
  $page: Int,
  $count:Int
){
  broadcastList(
    page: $page,
    count:$count,
    sort: $sort,
  ){
    data{
      createdAt
      id
      startTime
      endTime
      message
      title
      status
    }
    pageInfo {
      total
      lastPage
    }
  }
}
`;
