import React from 'react';
import { Modal } from 'antd';

export const CellularDeviceUnassignModalComponent = ({
  children,
  ...props
}) => {
  return (
    <Modal
      {...props}
      footer={null}
      visible
    >
      {children}
    </Modal>
  )
};
