import { IHButton, message, _ } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import VitalList from 'ProgramIncompleteModule/Careplan/containers/VitalListContainer'
import AssessmentList from 'ProgramIncompleteModule/Assessment/containers/CommonContainer'
import SurveyList from 'ProgramIncompleteModule/Survey/containers/CommonContainer'
// import EducationalTable from '../../../../patient/Careplan/containers/EducationalTable'
import careplanActions from 'ProgramIncompleteModule/Careplan/actions/CommonActions'
import { graphql, withApollo, compose } from 'react-apollo'
import { editAdminProgramTask } from 'graphqlModule/mutation/editAdminProgram'
import { removeTaskFromAdminProgram } from 'graphqlModule/mutation/removeTaskFromAdminProgram'
import { program as updateQueries } from 'graphqlModule/updateQueries/program'
import { removeTaskFromAdminProgram as updateQueriesForRemove } from 'graphqlModule/updateQueries/program'
import { connect } from 'react-redux'
import ProgramIncompleteActions from 'ProgramIncompleteModule/Common/actions/CommonActions'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const sy = {
    marginTop : '30px'
}

class Container extends React.Component {
  static displayName = 'program/ProgramIncomplete/Careplan/containers/MainPageContainer'

  componentDidMount() {
    const { initVitalList, program, setAssessmentHasEmptySchedule, setSurveyHasEmptySchedule } = this.props
    initVitalList(program)
    setAssessmentHasEmptySchedule(false)
    setSurveyHasEmptySchedule(false)
  }

  render(){
    return (
      <div className="v-box v-careplan patient-careplan-main-page">
        {this.renderVitalList()}
        {this.renderAssessmentsTable()}
        {/*{this.renderSurveysTable()}*/}
        {/*
          {this.renderEducationalTabl()}
        */}
        {this.renderBottomButton()}
      </div>
    )
  }

  renderVitalList(){
    const { program } = this.props
    return (
      <div id="vital-list">
        <p className="v-tlt">{`Vital Signs & Thresholds`}</p>
        <VitalList program={program} />
      </div>
    )
  }

  renderAssessmentsTable(){
    return (
      <div id="assessments">
        <AssessmentList ref="assessmentList" program={this.props.program} assessmentHasEmptySchedule={this.props.assessmentHasEmptySchedule} />
      </div>
    )
  }

  renderSurveysTable(){
    return (
      <div id="surveys">
        <SurveyList ref='surveyList' program={this.props.program} surveyHasEmptySchedule={this.props.surveyHasEmptySchedule} />
      </div>
    )
  }

  renderEducationalTabl(){
    // const { program } = this.props
    return (
      <div style={sy}>
        <p className="v-tlt">{`Select Educational Materials`}</p>
        {/*<EducationalTable program={program} />*/}
      </div>
    )
  }

  renderBottomButton() {
    const { openErrorModal, setTabsStep } = this.props
    const p1 = {
      type : 'primary',
      label : 'Save and Next',
      onClick : ()=>{
        const vitalListData = this.props.VitalList.data
        if (vitalListData.length === 0) {
          const errMessage = 'Please add Vital Signs'
          openErrorModal(errMessage)
          return
        }
        this.submit()
      },
      className : 'v-nb'
    }
    const p2 = {
      label : 'Back',
      className : 'v-bb',
      onClick : ()=>{
        setTabsStep('programInfo')
      }
    }
    return [
      <IHButton {...p1} key='device' />,
      <IHButton {...p2} key='programInfo' />
    ]
  }

  async submit() {
    const { Assessment, Survey, program, editAdminProgramTask, removeTaskFromAdminProgram, setTabsStep, openErrorModal, setAssessmentHasEmptySchedule, setSurveyHasEmptySchedule } = this.props
    const { tasks, removedTasksId } = Assessment.AssessmentList
    const { tasks: surveyTasks, removedTasksId: removedTasksIdFromSurvey } = Survey.SurveyList
    let combinedRemovedTasksId = removedTasksId || []
    if (removedTasksIdFromSurvey) {
      combinedRemovedTasksId = combinedRemovedTasksId.concat(removedTasksIdFromSurvey)
    }
    const combinedTasks = tasks.concat(surveyTasks)

    // Check if any assessment or survey has empty schedule, do not save if there is any
    let hasEmptySchedule = false
    setAssessmentHasEmptySchedule(false)
    setSurveyHasEmptySchedule(false)

    _.each(combinedTasks, (task) => {
      if(task.schedule && task.schedule[0].calendar.length === 0) {
        if(task.type === 'ASSESSMENT') {
          setAssessmentHasEmptySchedule(true)
          hasEmptySchedule = true
        }
        if(task.type === 'SURVEY') {
          setSurveyHasEmptySchedule(true)
          hasEmptySchedule = true
        }
      }
    })

    if (hasEmptySchedule) {
      message.error('Care Plan changes failed to save!')
      return
    }

    const variables = {
      id: program.id,
      tasks: combinedTasks
    }
    const variablesForRemove = {
      id: program.id,
      taskId: combinedRemovedTasksId
    }

    if (combinedRemovedTasksId && combinedRemovedTasksId.length > 0) {
      try{
        const res = await removeTaskFromAdminProgram({ variables: variablesForRemove, updateQueries: updateQueriesForRemove })
        if (res.data.removeTaskFromAdminProgram) {
          const auditLogDetails = {
            programName: program.name,
            assessments: res.data.removeTaskFromAdminProgram.tasks.filter((task) => task.type === 'ASSESSMENT').map((assessment) => {
              return assessment.assessment.name
            }),
            surveys: res.data.removeTaskFromAdminProgram.tasks.filter((task) => task.type === 'SURVEY').map((survey) => {
              return survey.assessment.name
            })
          }
          createAuditLog({
            action: I18N.get('auditLog.admin.programme.task.remove'),
            details: auditLogDetails,
            request: variablesForRemove,
            response: res
          })
        }
      } catch (e) {
        createAuditLog({
          action: I18N.get('auditLog.admin.programme.task.remove'),
          details: { programName : program.name },
          request: variablesForRemove,
          response: e,
          success: false
        })
        openErrorModal(GQLHelper.formatError(e))
        return
      }
    }

    if (combinedTasks.length > 0) {
      try{
        const res = await editAdminProgramTask({ variables, updateQueries })
        if (res.data.editAdminProgramTask) {
          const auditLogDetails = {
            programName: program.name,
            assessments: res.data.editAdminProgramTask.tasks.filter((task) => task.type === 'ASSESSMENT').map((assessment) => {
              return assessment.assessment.name
            }),
            surveys: res.data.editAdminProgramTask.tasks.filter((task) => task.type === 'SURVEY').map((survey) => {
              return survey.assessment.name
            })
          }
          createAuditLog({
            action: I18N.get('auditLog.admin.programme.task.save'),
            details: auditLogDetails,
            request: variables,
            response: res
          })
        }
      } catch (e) {
        createAuditLog({
          action:I18N.get('auditLog.admin.programme.task.save'),
          details: { programName : program.name },
          request: variables,
          response: e,
          success: false
        })
        openErrorModal(GQLHelper.formatError(e))
        return
      }
    }

    message.success('Care Plan changes have been saved!');
    setTabsStep('device')
    _.delay(()=>{
      createAuditLog({ action: I18N.get('auditLog.admin.programme.edit.save_care_plan') })
    }, 1000)
  }
}

Container.propTypes = {
  initVitalList: PropTypes.func,
  removedTasksId: PropTypes.array,
  program: PropTypes.object,
  openErrorModal: PropTypes.func,
  setTabsStep: PropTypes.func,
  Assessment: PropTypes.object,
  Survey: PropTypes.object,
  setAssessmentHasEmptySchedule: PropTypes.func,
  setSurveyHasEmptySchedule: PropTypes.func,
  editAdminProgramTask: PropTypes.func,
  removeTaskFromAdminProgram: PropTypes.func,
  assessmentHasEmptySchedule: PropTypes.bool,
  surveyHasEmptySchedule: PropTypes.bool
}

const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramIncomplete.Careplan
  }
}

const mapDispatch = (dispatch) => {
  return {
    initVitalList: (program) => dispatch(careplanActions.initVitalList(program)),
    setTabsStep: (step) => dispatch(ProgramIncompleteActions.setTabsStep(step)),
    openErrorModal: (err) => dispatch(openErrorModal(err)),
    setAssessmentHasEmptySchedule: (assessmentHasEmptySchedule) => dispatch(careplanActions.setAssessmentHasEmptySchedule(assessmentHasEmptySchedule)),
    setSurveyHasEmptySchedule: (surveyHasEmptySchedule) => dispatch(careplanActions.setSurveyHasEmptySchedule(surveyHasEmptySchedule))
  }
}

export default compose(
  withApollo,
  graphql(editAdminProgramTask, {name: 'editAdminProgramTask'}),
  graphql(removeTaskFromAdminProgram, {name: 'removeTaskFromAdminProgram'}),
  connect(mapState, mapDispatch),
)(Container);
