const map = {
    all:'All',
    overnight: 'Overnight',
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    low: 'Low',
    normal: 'Normal',
    elevated: 'Elevated',
    hypertension1: 'Stage 1 Hypertension',
    hypertension2: 'Stage 2 Hypertension',
    criticalLow: 'Critical Low',
    criticalHigh: 'Critical High',
    arrhythmia: 'Arrhythmia',
    pulsePressure : 'Pulse Pressure'
}

export default map;
