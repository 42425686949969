import React from 'react';
import { Row, Col, Checkbox, Tooltip, Icon} from "antd";
import '../style/TesterUserStyle.scss';
import I18N from '../../I18N';

const CheckSelfEnrollComponent = class extends React.Component{

    constructor(props) {
        super(props);
        const { isSelfEnrolled } = props
        this.state = {
            isSelfEnrolled: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!prevProps.disabled && this.props.disabled ){
            this.setState({isTestUser: this.props.isTestUser})
        }
    }

    renderSelfEnrollment = () => {
        const { isSelfEnrolled } = this.state;
        return <div>
                <Checkbox defaultChecked={isSelfEnrolled} onChange={this.handleCheckbox} id='isSelfEnrolled'>This is self-enrolled patient</Checkbox> 
                <Tooltip title={I18N.get('IsSelfEnrolled')}>
                    <Icon type='info-circle' style={{fontSize: 14, marginTop:-20}}/>
                </Tooltip>
               </div>    
    }

    handleCheckbox=(e)=>{
        const { localThis } = this.props;
        const { enableEditMode } = this.props;
        if(typeof enableEditMode=='function'){
            enableEditMode(true);
        }
        localThis.setState({
            isSelfEnrolled: e.target.checked
        })
        this.setState({
            isSelfEnrolled: e.target.checked
        })
    }

    render() {
        return this.renderSelfEnrollment()
    }
}

export default CheckSelfEnrollComponent;
