import I18N from "../../I18N";

const validator = (rule,value,callback)=>{
    const noteSize = _.get(value.trim(),'length');
    if(noteSize>250){
        callback(`${I18N.get('stickyNote.singleLabel')} has ${noteSize} characters, max allow 250 characters`);
    }
    callback();
};


export {
    validator
}
