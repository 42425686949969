import I18N from "../../../I18N";
import moment from "moment/moment";
import React from 'react';
import { nationOptions, langOptions, raceOptions, countryOptions } from 'libModule/constants'
import { formatFieldLabels,renderDropDown,renderRadioButton, renderRadio }from '../helpers';
import { Input, Radio, DatePicker,Tooltip,Icon, Checkbox, InputNumber, Button } from 'antd'
import { dobValidator, firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientProfileHeightInputValidator, patientHeightInchesInputValidator, usernameValidator } from 'libModule/validator';
import { programCategoriesMap } from '../constants/map';
import $ from 'jquery';
import convertor from "@ihealth/convertor/index";
import { convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits';
import SendConsentFormButton from "../../main/components/SendConsentFormButton";
import DownloadConsentFormButton from "../../main/components/DownloadConsentButton";
import CopyTextToClipboard from "../../../../lib/utils/CopyTextToClipboard";
import DeletePatientButton from "../../main/components/DeletePatientButton";
import PasswordPopoverComponent from "../Containers/PasswordPopoverContainer";
import { stageOfBehavorialChanges, DEFAULT_BEHAVIOR_CHANGE } from '../../../careplanNew/constant/nutritionInterpretationConstants'
import TestUserComponent from "../../component/TestUserComponent";
import PatientPreferencesComponent from '../../main/components/patientPreferences/PatientPreferencesComponent';
import helpers from '../../../careplanNew/helpers';
import upHelpers from '../../main/components/patientPreferences/helpers';
import ConsentFormPreview from '../../main/components/consentForm/ConsentFormPreview';
import { CONSENT_TYPE } from '../../main/constants';
import InsuranceComponent, { shouldHidePreviousInsurance } from '../Components/InsuranceComponent';
import { getProviderName, doesOrgHaveMNT, doesOrgHaveValueBased, doesOrgHaveCCM, doesOrgHaveRPM } from '../../../../lib/utils';
import InsuranceCardContainer from "../../InsuranceCard/container/InsuranceCardContainer";
import { InsuranceCopayComponent } from '../../InsuranceCopayComponent/InsuranceCopayComponent';
import { get } from 'lodash';

const rules = {
    identification:true,
    DOB:true,
    firstName:true,
    lastName:true,
    nickname:false,
    gender:true,
    tech:false,
    motivation:false,
    doctor:true,
    username:false,
    height:false,
    weight:false,
}

const radioMap = {
    race:raceOptions,
    nationality:nationOptions,
    country:countryOptions,
    maritalStatus:[
        {
            value:'SINGLE',
            label:'Single'
        },
        {
            value:'MARRIED',
            label:'Married'
        },
        {
            value:'DIVORCED',
            label:'Divorced'
        },
        {
            value:'WIDOWED',
            label:'Widowed'
        },
        {
            value:'SEPARATED',
            label:'Separated',
        }
    ],
    economicStatus:[
        {
            value:'Independent',
            label:'Independent'
        },
        {
            value:'Dependent',
            label:'Dependent'
        }
    ],
    occupation:[
        {
            value:'Student',
            label:'Student'
        },
        {
            value:'Employed',
            label:'Employed'
        },
        {
            value:'Unemployed',
            label:'Unemployed'
        },
        {
            value:'Self-Employed',
            label:'Self-Employed'
        },
        {
            value:'Retired',
            label:'Retired'
        }
    ],
    CCMEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    RPMEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    MNTEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    valueBasedEnrolled:[
        { value:true,name:'YES'},
        { value:false,name:'NO'}
    ],
    language:langOptions,
    hasSmartPhone:[
        { value: true, label: 'Yes' },
        { value: false, label: 'No'}
    ]
}

const MOTIVATION = I18N.get('motivation');
const motivationOptions = Object.keys(MOTIVATION).map(key => ({name: MOTIVATION[key], value: key}));

const TECH = I18N.get('techLevel');
const techLevelOptions = Object.keys(TECH).map(key => ({name: TECH[key], value: key}));

const phoneValidator = (value)=>{
    if (!value) return;
    if (!/^\d{10}$/.test(value)) {
        return I18N.get('validator.mobile.invalid');
    }
};

const getProgramCategories = (val,type)=>{
    const filterName = programCategoriesMap[type];
    const filteredRes  = _.filter(val,(k)=>k.name==filterName||k.name==_.toLower(filterName));
    const enrolledDate = _.get(filteredRes,'[0].enrolledDate',null);
    const programCategory  = {
        enrolled: _.get(filteredRes,'[0].enrolled'),
        enrolledDate: enrolledDate ? moment(enrolledDate) : enrolledDate
    }
    return  programCategory;
}

const getDefaultEnrolledDate = (localThis) => {
  const { consent, signatureAt } = localThis.props.consentInfo || {};
  const enrolledDate = (!!consent && signatureAt) ? moment(signatureAt) : undefined;
  return enrolledDate ? moment(enrolledDate) : undefined;
}

const onChangeEnrolledDate = (enrolledDateKey, form, localThis, date) => {
  const fieldKey = `showAutoPrefillDateNote.${enrolledDateKey}`;
  const enrolledDate = getDefaultEnrolledDate(localThis);
  const isShow = !!form.getFieldValue(fieldKey);
  if(isShow && (!date || !date.isSame(moment(enrolledDate), 'day'))) {
    form.setFieldsValue({
      [fieldKey]: false
    });
  }
}

const renderAutoPrefillDateNote = (enrolledDateKey, form, localThis) => {
  const isShow = !!form.getFieldValue(`showAutoPrefillDateNote.${enrolledDateKey}`);
  if(!isShow) 
    return null;
  const enrolledDate = getDefaultEnrolledDate(localThis);
  const note = I18N.get('programCategories.getAutoPrefillNote')(
    moment(enrolledDate).format('MM/DD/YYYY'),
    { width: 300, marginTop: 10 }
  );
  return note;
}

const genRules = (form,localThis) =>{
    const fieldArray = ['streetName', 'city','state','postCode'];
    const fieldValues = form.getFieldsValue();
    const address = _.get(localThis,'props.user.address[0]') || {};
    let hasValue = [];

    _.each(fieldArray,(v)=>{
        if((!(v in fieldValues)&&address[v])||fieldValues[v]){
            hasValue.push(v);
        }
    });

    if(hasValue.length!=0){
        return [{
            required:true,
            // message:`${field} is required`
        }]
    }
}

const contactInfo = () => [
    [
        {
            key: 'contactInformation',
            span: 24,
            style: { margin: 0 },
            path:'',
            inputContent:() => <div><h4>Contact Information</h4></div>
        }
    ],
    [
        {
            key: 'mobilePhone',
            label: formatFieldLabels('mobilePhone','mobilePhone'),
            path:(localThis)=>{
              const phone = _.get(localThis, 'props.user.phone') || [];
              const mobileIndex = _.findIndex(phone, { type: 'MOBILE' });
              return `phone[${mobileIndex}].number`;
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            inputContent:({disabled})=><Input/>,
            rules:[{ required:false, validator:(rule,v,callback)=>callback(phoneValidator(v)) }]
        },
            {
                key: 'isPrivateNumber',
                label: ' ',
                labelCol: { span: 24 },
                wrapperCol: { span: 22 },
                colName: 'isPrivateNumber',
                path: (localThis) => {
                  const phone = _.get(localThis, 'props.user.phone') || [];
                  const mobileIndex = _.findIndex(phone, { type: 'MOBILE' });
                  return `phone[${mobileIndex}].private`;
                },
                span: 4,
                inputContent:({value, disabled}) => <Checkbox style={{marginTop: '10px'}} defaultChecked={value ? value : false}>Private number</Checkbox>
            },
            {
                key: 'hasSmartPhone',
                label: ' ',
                labelCol: { span: 24 },
                wrapperCol: { span: 22 },
                colName: 'hasSmartPhone',
                path:'profile.hasSmartPhone',
                span: 4,
                inputContent:({value, disabled}) => <Checkbox style={{marginTop: '10px'}} defaultChecked={value ? value : false}>Smartphone</Checkbox>
            },
        {
            key: 'canUseForLogin',
            label: <div>
              <span>{formatFieldLabels('canUseForLogin', 'canUseForLogin')}</span>
              <Tooltip title={I18N.get('OTPLogInInfo')}>
                <Icon type='info-circle' style={{fontSize: 14,position:'absolute', marginLeft: 5}}/>
              </Tooltip>
            </div>,
            type: 'radioButton',
            labelCol: { span: 24  },
            wrapperCol: { span: 22 },
            span: 4,
            path:(localThis)=>{
              const phone = _.get(localThis, 'props.user.phone') || [];
              const mobileIndex = _.findIndex(phone, { type: 'MOBILE' });
              return `phone[${mobileIndex}].canUseForLogin`;
            },
            className: 'radio-button-2-option',
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=> (
              <Radio.Group>
                <Radio.Button value='true' style={{width: 60, textAlign: 'center'}}>On</Radio.Button>
                <Radio.Button value='false' style={{width: 60, textAlign: 'center'}}>Off</Radio.Button>
              </Radio.Group>
            ),
            formatValue:(data)=> typeof data=='boolean' ? data.toString() : (typeof data=='string'&&data.length>0) ? data :'true'
        },
        {
            key: 'homePhone',
            label: formatFieldLabels('homePhone','homePhone'),
            path:(localThis)=>{
              const phone = _.get(localThis, 'props.user.phone') || [];
              const homeIndex = _.findIndex(phone, { type: 'HOME' });
              return `phone[${homeIndex}].number`;
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            inputContent:({disabled})=><Input />,
            rules:[{ required:false, validator:(rule,v,callback)=>callback(phoneValidator(v)) }]

        },
        {
            key: 'email',
            label: formatFieldLabels('email','emailAddress'),
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            path:'email[0].address',
            rules : [
                { required : rules.email, message : 'Email is required'},
                { type: 'email', message: 'Email not valid'}
            ],
            inputContent:({disabled})=><Input />
        },
    ]
];

const insuranceInfo = () => [
    [
      {
        key: 'insuranceInformation',
        label: '',
        span: 24,
        style: { margin: 0 },
        inputContent:() => <h4>Insurance</h4>
      }
    ],
    [
      {
          key:'insuranceProvider',
          label:formatFieldLabels('insuranceProvider','insuranceProvider'),
          labelCol:{ span: 24 },
          wrapperCol: { span: 20 },
          span: 6,
          style: { marginBottom: 3 },
          path:'profile.insuranceProvider',
          hide:false,
                  // localThis => shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), true),
          inputContent:()=><Input/>,
          rules: [
            { required: true, message: 'Insurance provider is required, type "N/A" if not available'}
          ]
      },
      {
          key:'insuranceProvider2',
          label:formatFieldLabels('insuranceProvider2','insuranceProvider2'),
          labelCol:{ span: 24 },
          wrapperCol: { span: 20 },
          span: 6,
          style: { marginBottom: 3 },
          path:'profile.insuranceProvider2',
          hide: false,
                  // localThis => shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), false),
          inputContent:()=><Input/>,
          rules: [
            { required: true, message: 'Insurance provider is required, type "N/A" if not available'}
          ]
      },
      {
        key: 'insuranceProviderCopay',
        label: '',
        labelCol:{ span: 24 },
        wrapperCol: { span: 20 },
        span: 8,
        inputContent: (props) => (
          <InsuranceCopayComponent
            patientId={get(props, 'localThis.props.user.id')}
            {...props}
          />
        )
      }
    ],
    // [
    //   {
    //     key: 'previousInsuranceNote',
    //     label: '',
    //     span: 24,
    //     style: { marginBottom: 6 },
    //     hide: localThis => shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), true) && 
    //                       shouldHidePreviousInsurance(_.get(localThis, 'props.user.profile'), false),
    //     inputContent:() => <span>You cannot edit the above field(s). Please transfer the insurance information to the table below.</span>
    //   }
    // ],
    [
      {
        key: 'insuranceProviderInputs',
        label: '',
        labelCol: { span: 24 },
        span: 24,
        inputContent: (props) => <div>
          <InsuranceComponent 
            {...props} 
            insuranceProviders={_.get(props, 'localThis.props.user.profile.insuranceProviders') || []}
            disabled={false}
          />
        </div>
      }
    ]
];

export const enrollmentInfo = (orgHasMNT, orgHasCCM, orgHasRPM, orgHasValueBased) => [
    [
    {
        key: 'CCMEnrolled.enrolled',
        label: formatFieldLabels('CCMEnrolled.enrolled', 'CCMEnrolled.enrolled'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 5,
        path:'profile.programCategories',
        className: 'radio-button-2-option',
        hide: () => !orgHasCCM,
        inputContent:(props)=>renderRadioButton(radioMap['CCMEnrolled'],{...props,disabled:false}),
        onChange:(value,localThis)=> {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('CCMEnrolled.enrolledDate');
            if(value&&!fieldValue) {
              const enrolledDate = getDefaultEnrolledDate(localThis);
              form.setFieldsValue({
                  'CCMEnrolled.enrolledDate': enrolledDate,
                  'showAutoPrefillDateNote.CCMEnrolledDate': !!enrolledDate,
              });
            }
            if(fieldValue&&!value){

                form.setFieldsValue({
                    'CCMEnrolled.enrolledDate':null
                });
            }
            if(!fieldValue&&!value){
                if(_.get(form.getFieldError('CCMEnrolled.enrolledDate'),'length',0)>0){
                    form.setFields({
                        'CCMEnrolled.enrolledDate':{
                            value:null,
                            errors:[]
                        }
                    })
                    $('body').find('.ccmEnrolledDate').parent().parent().removeClass('has-error');
                }
            }
        },
        formatValue:(data,format)=> (getProgramCategories(data,'CCMEnrolled'))['enrolled']
    },
    {
        key:'CCMEnrolled.enrolledDate',
        label:formatFieldLabels('CCMEnrolled.enrolledDate','CCMEnrolled.enrolledDate'),
        labelCol:{ span: 24 },
        wrapperCol:{ span: 22 },
        span: 19,
        hide: (localThis) => !orgHasCCM || !localThis.props.form.getFieldValue('CCMEnrolled.enrolled'),
        placeholder: 'MM/DD/YYYY',
        format: 'MM/DD/YYYY',
        colStyle: (form) => {
          const isNoteShow = !!form.getFieldValue('showAutoPrefillDateNote.CCMEnrolledDate')
          return { height: !isNoteShow ? 87 : 109 };
        },
        className:'ccmEnrolledDate',
        rules:(form)=>[ { required :form.getFieldValue('CCMEnrolled.enrolled') , message : 'CCM Enrolled Date is required'} ],
        path:'profile.programCategories',
        inputContent:({ format,form,className,localThis })=> {
            const ccmDateRequired = form.getFieldValue('CCMEnrolled.enrolled');
            return <DatePicker
              disabled={!ccmDateRequired} 
              format={format} 
              className={className}
              onChange={_.partial(onChangeEnrolledDate, 'CCMEnrolledDate', form, localThis)}
            />
        },
        formatValue:(data)=> (getProgramCategories(data,'CCMEnrolled'))['enrolledDate'],
        renderFieldNote: _.partial(renderAutoPrefillDateNote, 'CCMEnrolledDate')
    }],
    [{
        key: 'RPMEnrolled.enrolled',
        label: formatFieldLabels('RPMEnrolled.enrolled', 'RPMEnrolled.enrolled'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 5,
        path:'profile.programCategories',
        className: 'radio-button-2-option',
        inputContent:(props)=>renderRadioButton(radioMap['RPMEnrolled'],{...props,disabled:false}),
        hide: () => !orgHasRPM,
        formatValue:(data,format)=>(getProgramCategories(data,'RPMEnrolled'))['enrolled'],
        onChange:(value,localThis,e)=> {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('RPMEnrolled.enrolledDate');
            if(value&&!fieldValue) {
              const enrolledDate = getDefaultEnrolledDate(localThis);
              form.setFieldsValue({
                  'RPMEnrolled.enrolledDate': enrolledDate,
                  'showAutoPrefillDateNote.RPMEnrolledDate': !!enrolledDate,
              });
            }
            if(fieldValue&&!value){
                form.setFieldsValue({
                    'RPMEnrolled.enrolledDate':null
                });
            }

            if(!fieldValue&&!value){
                if(_.get(form.getFieldError('RPMEnrolled.enrolledDate'),'length',0)>0){
                    form.setFields({
                        'RPMEnrolled.enrolledDate':{
                            value:null,
                            errors:[]
                        }
                    })
                    $('body').find('.rpmEnrolledDate').parent().parent().removeClass('has-error');
                }
            }
        },
    },
    {
        key:'RPMEnrolled.enrolledDate',
        label:formatFieldLabels('RPMEnrolled.enrolledDate','RPMEnrolled.enrolledDate'),
        labelCol:{ span: 24 },
        wrapperCol:{ span: 22 },
        span: 19,
        colStyle: (form) => {
          const isNoteShow = !!form.getFieldValue('showAutoPrefillDateNote.RPMEnrolledDate')
          return { height: !isNoteShow ? 87 : 109 };
        },
        hide: (localThis) => !orgHasRPM || !localThis.props.form.getFieldValue('RPMEnrolled.enrolled'),
        placeholder: 'MM/DD/YYYY',
        format: 'MM/DD/YYYY',
        className:'rpmEnrolledDate',
        rules:(form)=>[ { required :form.getFieldValue('RPMEnrolled.enrolled') , message : 'RPM Enrolled Date is required'} ],
        path:'profile.programCategories',
        inputContent:({ format,form,className,localThis })=> {
           const rpmDateRequired = form.getFieldValue('RPMEnrolled.enrolled');
           return <DatePicker 
            disabled={!rpmDateRequired} 
            format={format} 
            className={className} 
            onChange={_.partial(onChangeEnrolledDate, 'RPMEnrolledDate', form, localThis)}
          />
        },
        formatValue:(data)=> (getProgramCategories(data,'RPMEnrolled'))['enrolledDate'],
        renderFieldNote: _.partial(renderAutoPrefillDateNote, 'RPMEnrolledDate')
    }],
    [{
        key: 'MNTEnrolled.enrolled',
        label: formatFieldLabels('MNTEnrolled.enrolled', 'MNTEnrolled.enrolled'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 5,
        path:'profile.programCategories',
        className: 'radio-button-2-option',
        inputContent:(props)=>renderRadioButton(radioMap['MNTEnrolled'],{...props,disabled:false}),
        formatValue:(data,format)=>(getProgramCategories(data,'MNTEnrolled'))['enrolled'],
        onChange:(value,localThis,e)=> {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('MNTEnrolled.enrolledDate');
            if(value&&!fieldValue) {
              const enrolledDate = getDefaultEnrolledDate(localThis);
              form.setFieldsValue({
                  'MNTEnrolled.enrolledDate': enrolledDate,
                  'showAutoPrefillDateNote.MNTEnrolledDate': !!enrolledDate,
              });
            }
            if(fieldValue&&!value){
                form.setFieldsValue({
                    'MNTEnrolled.enrolledDate':null
                });
            }

            if(!fieldValue&&!value){
                if(_.get(form.getFieldError('MNTEnrolled.enrolledDate'),'length',0)>0){
                    form.setFields({
                        'MNTEnrolled.enrolledDate':{
                            value:null,
                            errors:[]
                        }
                    })
                    $('body').find('.mntEnrolledDate').parent().parent().removeClass('has-error');
                }
            }
        },
        hide: () => !orgHasMNT
    },
    {
        key:'MNTEnrolled.enrolledDate',
        label:formatFieldLabels('MNTEnrolled.enrolledDate','MNTEnrolled.enrolledDate'),
        labelCol:{ span: 24 },
        wrapperCol:{ span: 22 },
        span: 19,
        colStyle: (form) => {
          const isNoteShow = !!form.getFieldValue('showAutoPrefillDateNote.MNTEnrolledDate')
          return { height: !isNoteShow ? 87 : 109 };
        },
        hide: (localThis) => !orgHasMNT || !localThis.props.form.getFieldValue('MNTEnrolled.enrolled'),
        placeholder: 'MM/DD/YYYY',
        format: 'MM/DD/YYYY',
        className:'rpmEnrolledDate',
        rules:(form)=>[ { required :form.getFieldValue('MNTEnrolled.enrolled') , message : 'MNT Enrolled Date is required'} ],
        path:'profile.programCategories',
        inputContent:({ format,form,className,localThis })=> {
           const mntDateRequired = form.getFieldValue('MNTEnrolled.enrolled');
           return <DatePicker 
            disabled={!mntDateRequired} 
            format={format} 
            className={className} 
            onChange={_.partial(onChangeEnrolledDate, 'MNTEnrolledDate', form, localThis)}
           />
        },
        formatValue:(data,format)=>(getProgramCategories(data,'MNTEnrolled'))['enrolledDate'],
        renderFieldNote: _.partial(renderAutoPrefillDateNote, 'MNTEnrolledDate'),
    }],
    [{
        key: 'valueBasedEnrolled.enrolled',
        label: formatFieldLabels('valueBasedEnrolled.enrolled', 'valueBasedEnrolled.enrolled'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 5,
        path:'profile.programCategories',
        className: 'radio-button-2-option',
        inputContent:(props)=>renderRadioButton(radioMap['valueBasedEnrolled'],{...props,disabled:false}),
        formatValue:(data,format)=>(getProgramCategories(data,'valueBasedEnrolled'))['enrolled'],
        onChange:(value,localThis,e)=> {
            const { form } = localThis.props;
            const fieldValue = form.getFieldValue('valueBasedEnrolled.enrolledDate');
            if(value&&!fieldValue) {
                const enrolledDate = getDefaultEnrolledDate(localThis);
                form.setFieldsValue({
                    'valueBasedEnrolled.enrolledDate': enrolledDate,
                    'showAutoPrefillDateNote.valueBasedEnrolledDate': !!enrolledDate,
                });
            }
            if(fieldValue&&!value){
                form.setFieldsValue({
                    'valueBasedEnrolled.enrolledDate':null
                });
            }

            if(!fieldValue&&!value){
                if(_.get(form.getFieldError('valueBasedEnrolled.enrolledDate'),'length',0)>0){
                    form.setFields({
                        'valueBasedEnrolled.enrolledDate':{
                            value:null,
                            errors:[]
                        }
                    })
                    $('body').find('.valueBasedEnrolledDate').parent().parent().removeClass('has-error');
                }
            }
        },
        hide: () => !orgHasValueBased
        },
        {
            key:'valueBasedEnrolled.enrolledDate',
            label:formatFieldLabels('valueBasedEnrolled.enrolledDate','valueBasedEnrolled.enrolledDate'),
            labelCol:{ span: 24 },
            wrapperCol:{ span: 22 },
            span: 19,
            colStyle: (form) => {
                const isNoteShow = !!form.getFieldValue('showAutoPrefillDateNote.valueBasedEnrolledDate');
                return { height: !isNoteShow ? 87 : 109 };
            },
            hide: (localThis) => !orgHasValueBased || !localThis.props.form.getFieldValue('valueBasedEnrolled.enrolled'),
            placeholder: 'MM/DD/YYYY',
            format: 'MM/DD/YYYY',
            className:'rpmEnrolledDate',
            rules:(form)=>[ { required :form.getFieldValue('valueBasedEnrolled.enrolled') , message : 'Value Based Enrolled Date is required'} ],
            path:'profile.programCategories',
            inputContent:({ format,form,className,localThis })=> {
                const valueBasedDateRequired = form.getFieldValue('valueBasedEnrolled.enrolled');
                return <DatePicker
                    disabled={!valueBasedDateRequired}
                    format={format}
                    className={className}
                    onChange={_.partial(onChangeEnrolledDate, 'valueBasedEnrolledDate', form, localThis)}
                />
            },
            renderFieldNote: _.partial(renderAutoPrefillDateNote, 'valueBasedEnrolledDate'),
            formatValue:(data,format)=>(getProgramCategories(data,'valueBasedEnrolled'))['enrolledDate'],
        }]
];


const addressInfo = () => [
    [
      {
        key:'patientPreferences', // ignore field value for this key
        label: '',
        span: 14,
        inputContent:(props)=> {
          const { form, localThis = {} } = props;
          const { user } = localThis.props || {};
          const upValues = upHelpers.getUserPreferences(user);

          const isMNTSelected = !!form.getFieldValue('MNTEnrolled.enrolled');
          const isMNTEnrolled = isMNTSelected || helpers.isMNTEnrolled(user);
          return <PatientPreferencesComponent 
            form={form}
            isMNTEnrolled={isMNTEnrolled}
            userPreference={upValues}
          />;
        }
      }
    ],
    [
        {
            key: 'homeAddress',
            span: 24,
            style: { margin: 0 },
            path:'',
            inputContent:() => <div><h4>Home Address</h4></div>
        }
    ],
    [
        {
            key: 'streetName',
            label: formatFieldLabels('streetName','streetName'),
            path:'address[0].streetName',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span:8,
            rules:(form,localThis)=>{
                return genRules(form,localThis);
            },
            inputContent:({disabled})=><Input />,
            formatValue:(value, format, localThis)=> {
                const streetNumber = _.get(localThis, 'props.user.address.0.streetNumber');
                if (streetNumber) {
                    if(value && value.startsWith(streetNumber)) { // value.match(/^\d/) starts with digits
                        return value;
                    } else {
                        return streetNumber + ' ' + (value || '');
                    }
                }
                return value;
            }
        },
        {
            key: 'unit',
            label: formatFieldLabels('unit','unitNumber'),
            path:'address[0].unit',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span: 4,
            inputContent:({disabled})=><Input/>,
        },
        {
            key: 'city',
            label: formatFieldLabels('city', 'city'),
            path:'address[0].city',
            colStyle:{height:87},
            rules:(form,localThis)=>{
                return genRules(form,localThis);
            },
            inputContent:({disabled})=><Input />,
            labelCol: {span: 24},
            wrapperCol: {span: 22 },
            span:4
        },
        {
            key: 'state',
            label: formatFieldLabels('state','state'),
            path:'address[0].state',
            rules:(form,localThis)=>{
                return genRules(form,localThis);
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            colStyle:{height:87},
            span:4,
            inputContent:({disabled})=><Input />,
        },
        {
            key: 'postCode',
            label: formatFieldLabels('postCode','postCode'),
            path:'address[0].postCode',
            rules:(form,localThis)=>{
                return genRules(form,localThis);

            },
            maxLength: 6,
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            colStyle:{whiteSpace:'nowrap'},
            inputContent:({disabled})=><Input style={{whiteSpace:'nowrap'}}/>,
            // rules : [ {validator: (rule, value, callback) => callback(postCodeValidator(value))} ]
        },
        {
            key: 'copyAddressButton',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            path: '',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            inputContent:({ localThis }) => {
                const address = _.get(localThis, 'props.user.address[0]') || {};
                const addressArray = [];
                if (address.streetNumber) {
                    addressArray.push(address.streetNumber);
                }
                if (address.streetName) {
                    addressArray.push(address.streetName + (address.unit ? ',' : ''));
                }
                if (address.unit) {
                    addressArray.push(address.unit + ',');
                }
                if (address.city) {
                    addressArray.push((address.city + ','));
                }
                if (address.state) {
                    addressArray.push(address.state);
                }
                if (address.postCode) {
                    addressArray.push(address.postCode);
                }
                if (address.country) {
                    addressArray.push(address.country.code);
                }
                const addressString = addressArray.join(' ');
                return <div><CopyTextToClipboard value={addressString} buttonText="Copy Address" /></div>;
            }
        }
    ]
];

const selfEnrolledPatient = () => [
    [{
        key:'isSelfEnrolled',
        inputContent: ({localThis}) => <div>
                                            <Checkbox defaultChecked = {_.get(localThis, 'props.user.profile.isSelfEnrolled')}>This is self-enrolled patient</Checkbox> 
                                            <Tooltip title={I18N.get('IsSelfEnrolled')}>
                                                <Icon type='info-circle' style={{fontSize: 14, marginTop:-20}}/>
                                            </Tooltip>
                                       </div>                      
    }]
];

const testUser = () => [
    [{
        key:'testUser',
        path:({props})=>_.get(props,'user.isTestUser'),
        inputContent:({ localThis })=><TestUserComponent isTestUser={_.get(localThis,'props.user.isTestUser')}
                                                        headerText={'Is this a test patient?'}
                                                        localThis={localThis} enableEditMode={localThis.props.handleEnableEditMode}/>
    }]
];

const buttons = () => [
    [{
        key: 'saveButton',
        label: <React.Fragment>&nbsp;</React.Fragment>,
        path: '',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 3,
        inputContent:({ localThis }) => <div><Button size="large" onClick={() => localThis.props.onSave()} style={{ width: '100%' }} disabled={localThis.props.disabled}>Save</Button></div>,
    },
    {
        key: 'deletePatientButton',
        label: <React.Fragment>&nbsp;</React.Fragment>,
        path: '',
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        span: 3,
        inputContent:({ localThis }) => <div><DeletePatientButton user={localThis.props.user} onInteraction={localThis.props.onInteraction} /></div>,
    }]
]

const generalInfo = () =>
    [
        [
            {
                key: 'basicInfoHeader',
                span: 24,
                style: { margin: 0 },
                path:'',
                inputContent:() => <div><h4>Basic Information</h4></div>
            }
        ],
        [
        {
            key: 'firstName',
            label: formatFieldLabels('firstName', 'firstName'),
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            path:'profile.firstName',
            rules : [
                {required : rules.firstName, message : 'First Name is required'},
                {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
            ],
            // style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input />
        },
        {
            key: 'lastName',
            label: formatFieldLabels('lastName', 'lastName'),
            labelCol: { span: 24 },
            wrapperCol: { span: 23 },
            span: 4,
            path:'profile.lastName',
            rules : [
                {required : rules.lastName, message : 'Last Name is required'},
                {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input />
        },
        {
            key: 'nickname',
            label: formatFieldLabels('nickname', 'nickname'),
            labelCol: { span: 24 },
            wrapperCol: { span: 23 },
            span:4,
            path:'profile.nickname',
            rules : [
                //{validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'nickname'))}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({disabled})=><Input />
        },
        {
            key: 'gender',
            label: formatFieldLabels('gender', 'gender'),
            type: 'radioButton',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            path:'profile.gender',
            className: 'radio-button-2-option',
            rules : [
                {required : rules.gender, message : 'Gender is required'}
            ],
            style:{float:'left',width:'100%'},
            inputContent:({value,disabled})=><Radio.Group>
                <Radio.Button value={'Male'}>M</Radio.Button>
                <Radio.Button value={'Female'}>F</Radio.Button>
            </Radio.Group>,
            formatValue:(data)=>data ?( data=='M'?'Male':'Female' ) :null
        },
        {
            key: 'DOB',
            label: formatFieldLabels('DOB', 'dob'),
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 6,
            path:'profile.birthday',
            placeholder: 'MM/DD/YYYY',
            format: 'MM/DD/YYYY',
            initialValue: '',
            rules : [{required : rules.DOB, message : 'DOB is required'},
            { validator: (rule, value, callback) => {
                callback(dobValidator(value))
            }}],
            formatValue: (data) => data ? moment(data) : null,
            inputContent: ({ format, className }) => <DatePicker format={format} className={className}/>
        }
    ],
    [
        {
            key:'height',
            label: formatFieldLabels('height', 'height'),
            path:'profile.height.value',
            labelCol: { span: 24 },
            wrapperCol: { span: 23 },
            span: 2,
            rules: [ { required: rules.height }, {validator: (rule, value, callback) => callback(patientProfileHeightInputValidator(value,'height'))}],
            inputContent:({disabled})=><InputNumber />,
            formatValue:(height,format)=> {
                const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                return heightInInches ? convertInchesToFeet(heightInInches,'cm','inch',0) : '';
            }
        },
        {
            key: 'height_inches',
            label: formatFieldLabels('height_inches','height_inches'),
            path:'profile.height.value',
            labelCol: { span: 24 },
            wrapperCol: { span: 20 },
            span: 2,
            rules : [ { required: rules.height }, {validator: (rule, value, callback) => callback(patientHeightInchesInputValidator(value, 'height'))}],
            inputContent:({disabled})=><InputNumber style={{marginTop:5}} />,
            formatValue:(height)=> {
                const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                return height ? getRemainderAfterConvertingToFeet(heightInInches) :''
        }
        },
        {
            key: 'weight',
            label: formatFieldLabels('weight','weight'),
            path: 'profile.weight.value',
            labelCol: { span: 24 },
            wrapperCol: { span: 23 },
            span: 4,
            rules : [ {validator: (rule, value, callback) => callback(patientWeightRegiterValidator(value, 'weight'))}],
            inputContent:({disabled})=><InputNumber />,
            formatValue:(weight)=> weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
        },
        {
            key:'race1',
            label: formatFieldLabels('race', 'race'),
            path:'profile.race1.code',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            inputContent:(props)=>renderDropDown(raceOptions,{ ...props,disabled:false })
        },
        {
            key:'language',
            label: formatFieldLabels('language', 'language'),
            path:'profile.language.code',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            rules: [{ required: true }],
            inputContent:(props)=>renderDropDown(radioMap['language'],{...props,disabled:false})
        },
        {
            key: 'behaviorChange',
            label: 'Stage of Behavioral Change',
            path: 'profile.nutrition.behaviorChange',
            formatValue: (value, format, localThis) =>  _.get(localThis.props, 'user.profile.nutrition.behaviorChange') || _.get(localThis.props, 'user.profile.motivation') || DEFAULT_BEHAVIOR_CHANGE,
            labelCol: { span:24 },
            wrapperCol: { span: 22 },
            span: 4,
            rules:[{required : rules.motivation, message : 'Stage of Behavioral Change is required'}],
            inputContent: (props) => renderDropDown(motivationOptions, {...props,disabled:false})
        },
        {
            key:'techLevel',
            label:'Tech Level',
            path:'profile.techLevel',
            labelCol:{ span:24 },
            wrapperCol:{ span: 22 },
            span: 4,
            rules: [{ required: rules.tech, message: 'Tech Level is required' }],
            inputContent:(props)=> renderDropDown(techLevelOptions, {...props,disabled:false})

        },
    ],
    [
        {
            key:'doctorId',
            label:<div>Doctor Name{<Tooltip title={I18N.get('SelfEnrollment')}><Icon type='info-circle' style={{fontSize: 14, marginTop:-20, marginLeft:5}}/></Tooltip>}</div>,
            path: 'profile.doctor.id',
            labelCol:{ span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            rules:[ { required: rules.doctor, message: 'Doctor Name is required' } ],
            inputContent:(props) => {
                const doctorOptions  = _.map(JSON.parse(sessionStorage.getItem('doctorName')),({id, fullName})=>({name:fullName.trim(),value:id,key:id}));
                const doctor = _.get(props, 'localThis.props.user.profile.doctor') || {};
                if(doctor.id && _.filter(doctorOptions, doc => doc.key === doctor.id).length === 0){
                  doctorOptions.unshift({
                    name: _.get(doctor, 'profile.fullName'), 
                    value: doctor.id, 
                    key: doctor.id
                  });
                }
                return renderDropDown(doctorOptions, {...props,disabled:false});
            },
        },
        {
            key:'username',
            label: formatFieldLabels('username', 'username'),
            path:'username',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 4,
            rules: [{ required: rules.identification, message : `${I18N.get('username')} is required`} /*, { validator: (rule, value, callback) => callback(usernameValidator(value))}*/],
            inputContent:(props)=><Input />,
        },
        {
            key:'identification',
            label: formatFieldLabels('identificationValue', 'nric'),
            path:'identification[0].value',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span:4,
            // rules: [{ required:rules.identification, message : `${I18N.get('keywords.NRIC/FIN')} is required`}, {validator: (rule, value, callback) => callback(NRICValidator(value))}],
            inputContent:(props) => <Input />,
        },
        {
            key: 'changePasswordBtn',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            path: 'hasPassword',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            hide: (localThis) =>  !_.get(localThis, 'props.user.hasPassword'),
            className: 'change-password-btn',
            inputContent: ({ localThis }) => <PasswordPopoverComponent patientId={_.get(localThis, 'props.user.id')} buttonText="Change Password" />
        },
        {
            key: 'createPasswordBtn',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            path: 'hasPassword',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            hide: (localThis) => _.get(localThis, 'props.user.hasPassword'),
            className: 'change-password-btn',
            inputContent:({ localThis }) => <PasswordPopoverComponent patientId={_.get(localThis, 'props.user.id')} buttonText="Create Password" />
        },
        {
            key: 'sendConsentButton',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            path: '',
            hide: (localThis) => {
              return _.get(localThis, 'props.currentProgram.status') !== 'STARTED';
            },
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            style: { fontSize: '1.1em', height: 32, lineHeight: '32px', padding: '0 10px' },
            inputContent:({ localThis }) => {
              return <SendConsentFormButton patientId={_.get(localThis, 'props.user.id')} currentProgram={localThis.props.currentProgram} user={localThis.props.user} isEnrolled={_.get(localThis, 'props.currentProgram.status') === 'STARTED'} />
            },
        },
        {
            key: 'viewConsentButton',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            path: '',
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            style: { fontSize: '1.1em', height: 32, lineHeight: '32px', padding: '0 10px' },
            hide: (localThis) => {
              const isConsent = !!_.get(localThis, 'props.consentInfo.consent');
              return !isConsent;
            },
            inputContent:({ localThis }) => {
              const isPaperConsent = _.get(localThis, 'props.consentInfo.consentType') === CONSENT_TYPE.PAPER;
              return <ConsentFormPreview
                statusText={isPaperConsent ? 'Paper consent form signed.' : ' '}
                viewText='View consent form'
                memberId={_.get(localThis, 'props.user.id')}
                shouldShowIcon={false}
                {...localThis.props.consentInfo}
              />;
            },
        },
        {
            key:'viewInsuranceCard',
            label: <React.Fragment>&nbsp;</React.Fragment>,
            labelCol: { span: 24 },
            wrapperCol: { span: 22 },
            span: 3,
            style: { fontSize: '1.1em', height: 32, lineHeight: '32px', padding: '0 10px' },
            inputContent:({ localThis }) => {
                return <InsuranceCardContainer
                    memberId={_.get(localThis, 'props.user.id')}
                />;
            }
        }
    ],

]

// to hold previous values
export const shadowFields = () => [
  [
    { 
      key: 'programCategories',
      path: 'profile.programCategories',
      inputContent: () => <div style={{ display: 'none' }}></div>
    },
    { 
      key: 'showAutoPrefillDateNote.CCMEnrolledDate',
      formatValue: () => false,
      inputContent: () => <div style={{ display: 'none' }}></div>
    },
    { 
      key: 'showAutoPrefillDateNote.RPMEnrolledDate',
      formatValue: () => false,
      inputContent: () => <div style={{ display: 'none' }}></div>
    },
    { 
      key: 'showAutoPrefillDateNote.MNTEnrolledDate',
      formatValue: () => false,
      inputContent: () => <div style={{ display: 'none' }}></div>
    },
    {
      key: 'showAutoPrefillDateNote.valueBasedEnrolledDate',
      formatValue: () => false,
      inputContent: () => <div style={{ display: 'none' }}></div>
    }
  ]
];

const basicInfoConfig = (enrollStatus = 'ENROLLED')=>({
    'ENROLLED':[
        ...generalInfo(),
        ...contactInfo(),
        ...insuranceInfo(),
        ...enrollmentInfo(doesOrgHaveMNT(),doesOrgHaveCCM(),doesOrgHaveRPM(),doesOrgHaveValueBased()),
        ...addressInfo(),
        ...selfEnrolledPatient(),
        ...testUser(),
        ...buttons(),
        ...shadowFields()
    ],
    'UNENROLLED': [
        ...generalInfo(),
        ...contactInfo(),
        ...insuranceInfo(),
        ...addressInfo(),
        ...selfEnrolledPatient(),
        ...testUser(),
        ...buttons(),
        ...shadowFields()
    ]
}[enrollStatus])

export{
    basicInfoConfig,
}
