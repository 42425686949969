import types from '../constants/AssessmentFilledConstants';

const initState = {
  assessmentFilled: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_ASSESSMENT: {
      return {
        assessmentFilled: action.assessmentFilled,
      };
    }

    case types.CHANGE_ANSWER: {
      const { index, answer } = action;
      state.assessmentFilled.questions[index].answer = answer;

      return {
        assessmentFilled: {
          ...state.assessmentFilled,
        },
      };
    }

    default:
      return state;
  }
};
