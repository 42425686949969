import gql from 'graphql-tag'
import Assessment from './schema/Assessment'

export default gql `
  mutation editAssessment (
    $name: String!
    $nameKey: String!
    $healthCondition: HealthCondition
    $description: String
    $questions: [ InputAssessmentQuestion ]
    $publish: Boolean
  ) {
    editAssessment (
      nameKey: $nameKey
      name: $name
      healthCondition: $healthCondition
      description: $description
      questions: $questions
      publish: $publish
    ) {
      ${Assessment}
    }
  }
`
