import React from 'react';
import { Icon } from 'antd';
import {goPath, RequestCache} from 'libModule/utils'
import { compose } from 'recompose';
import {graphql} from "react-apollo/index";
import { getMonthlyTimeSpent } from '../query/';
import moment from 'moment';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

class TimeSpentComponent extends React.Component{

    routeToTimeSpent(patientId){
        Mixpanel.track('clicked','billable_time','patient_profile',{ PATIENT_ID:patientId })
        goPath(`/patients/${patientId}/timespent/`)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { setRefetchMontlySpent } = this.props;
        const curRefetchMontlySpent = _.get(this,'props.refetchMontlySpent');
        const { refetchMontlySpent } = prevProps;
        if( !refetchMontlySpent&&curRefetchMontlySpent ){
            setRefetchMontlySpent(curRefetchMontlySpent);
        }
    }

    render(){
        const { monthlyTimeSpent,userId } = this.props;
        const totalTime = _.get(monthlyTimeSpent,'totalTime', 0);
        const timeInMinutes = !totalTime ? 0 : Math.round(totalTime/60);
        let min = timeInMinutes > 1 ? 'mins' : 'min';
        return(
            <div className='timeSpent' onClick={()=>this.routeToTimeSpent(userId)}>
                <div style={{padding:10}}>
                    <div className='patientBillableHeader'>
                        <header style={{fontWeight:900}}>Billable Time</header>
                    </div>
                    <div className='patientBillableHeader'>
                        <span className='v-patient-link no-decoration' style={{fontSize:'26px',letterSpacing:-0.3}}>{timeInMinutes}</span>
                        <span>{min}</span>
                        <Icon type="right" className='v-patient-link no-decoration' style={{fontSize:'16px'}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const withData = graphql(getMonthlyTimeSpent,{
    options:(ownProps)=>{
        const monthOfYear = Number(moment().format('YYYYMM'));
        return {
            variables:{
                memberId:ownProps.userId,
                monthOfYear
            },
            fetchPolicy:'network-only'
        }
    },
    props:({data:{getMonthlyTimeSpent: monthlyTimeSpent,loading:loading,refetch }})=>{
        if(loading){
            return {
                loading
            }
        }
        return {
            monthlyTimeSpent,
            refetchMontlySpent: refetch
        }
    }
});
export default compose(withData)(TimeSpentComponent);
