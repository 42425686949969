// vendor

const menus = {
  PatientVendor: {
    id: 'patient_vendor',
    title: 'Patients',
    icon: 'patients',
    href: '/patient_vendor',
    class:'patient_vendor'
  },
  Broadcasts: {
    id: 'broadcasts',
    title: 'Broadcasts',
    icon: 'messages',
    href: '/broadcasts',
    class:'broadcasts'
  },
}
const menusArray = [
  menus.PatientVendor,
  //menus.Broadcasts
];

export default menusArray

export const HOMEPAGE = '/patient_vendor'
