import gql from 'graphql-tag'

export default gql `query getPatientChatInfo($userId:EID!,$newAuthKey:Boolean) {
  getUserChatInfo(userId:$userId, newAuthKey:$newAuthKey) {
        messageSource
        channels{
            name
        }
  }
}`
