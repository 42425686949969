import { graphql, withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { message } from 'ihcomponent'
import RequestResetPasswordComponent from '../components/RequestResetPasswordComponent'
import requestResetPassword from 'modulesAll/graphql/requestResetPassword'
import actions from '../actions/Login'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'RequestResetPasswordContainer'

  submit = async (params) => {
    const { loginID, email } = params
    const { mutate, back, setSubmitting, openErrorModal } = this.props
    const variables = {
      username: loginID,
      email
    }
    try {
      setSubmitting(true);

      const res = await mutate({ variables })
      createAuditLog({
        action: I18N.get('auditLog.login.requestpassword'),
        details: variables,
        request: variables,
        response: res
      })

      message.success('Successfully requested.')
      back()
    }
    catch (err) {
      createAuditLog({
        action: I18N.get('auditLog.login.requestpassword'),
        details: variables,
        request: variables,
        response: err,
        success: false
      })
      const code = _.get(err, 'graphQLErrors.0.code', '');
      if(code === '1000003'){
        message.success('Successfully requested.')
      }else{
        openErrorModal(GQLHelper.formatError(err));
      }
    }
    finally {
      setSubmitting(false);
    }
  }
  render() {
    const props = {
      ...this.props,
      submit: this.submit
    }

    return (
      <RequestResetPasswordComponent {...props} />
    )
  }
}

const mapState = ({ Login }, ownProps) => {
  return {
    ...Login
  }
}

const mapDispatch = (dispatch, ownProps) => {
  return {
    handleFieldsChange: (fieldsProps, fields) => dispatch(actions.handleFieldsChange(fieldsProps, fields)),
    back: () => dispatch(actions.back()),
    reset: () => dispatch(actions.reset()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  submit: PropTypes.func,
  handleFieldsChange: PropTypes.func,
  mutate: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string
}

export default compose(
  withApollo,
  graphql(requestResetPassword),
  connect(mapState, mapDispatch),
)(Container)
