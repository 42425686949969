import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHTab } from 'ihcomponent'
import {Link} from 'react-router'
import I18N from 'modulesAll/I18N';
import ProgramInfoFormContainer from 'ProgramNewModule/ProgramInfo/containers/ProgramInfoFormContainer'
import actions from 'ProgramNewModule/Common/actions/CommonActions'
// import { setPagePath } from 'libModule/utils'

// container
class Container extends Component {
  static displayName = 'program/ProgramNew/containers/CommonContainer'

  componentDidMount() {
    const { setTabsStep } = this.props
    setTabsStep('programInfo')
  }
  render() {
    // setPagePath(`Programmes / New Programme (Incomplete)`)
    return (
      <div className='vsm-main-page vsm-form-container'>
        {this.renderBackLink()}
        <div className="vsm-tab-container">
          {this.renderTabs()}
        </div>
      </div>
    )
  }

  renderBackLink(){
    return (
      <div className="v-link">
        <Link to="/programmes_admin">
          <i className="fa fa-angle-left" />
          <span>Back to {I18N.get('keywords.Programs')}</span>
        </Link>
      </div>
    )
  }

  renderTabs() {
    const { Common, ProgramInfoForm, DeviceForm, Careplan, setTabsStep, changePage } = this.props
    const p = {
      onSelect: (id) => {
        setTabsStep(id)
      },
      itemList: [
        {
          id: 'programInfo',
          key: 'programInfo',
          icon: '1',
          title: `${I18N.get('keywords.PROGRAM')} INFO`,
          content: <ProgramInfoFormContainer {...ProgramInfoForm} changePage={changePage} />
        },
        {
          id: 'careplan',
          key: 'careplan',
          icon: '2',
          title: 'CARE PLAN',
          content: ''
        },
        {
          id: 'device',
          key: 'device',
          icon: '3',
          title: 'DEVICE',
          content: ''
        }
      ],
      active: Common.step
    }

    return (
      <IHTab {...p} />
    )
  }
}

// mapState
const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramNew
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
    setTabsStep: (step) => dispatch(actions.setTabsStep(step)),
    changePage: (page) => dispatch(actions.changePage(page))
  }
}

// PropTypes validation
Container.propTypes = {
  setTabsStep: PropTypes.func,
  changePage: PropTypes.func,
  ProgramInfoForm: PropTypes.object,
  Careplan: PropTypes.object,
  DeviceForm: PropTypes.object,
  Common: PropTypes.object,
}

// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(Container)
