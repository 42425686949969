import React from 'react';
import CrossClinicPatientsContainer from "./CrossClinicPatientsContainer";
import { RouteContextProvider } from '../../rpmPatientReview/helpers/useRouteContext';

const CrossClinicContainer = class extends React.Component {
  render() {
    return (
      <div>
        <RouteContextProvider value={this.props.route}>
          <CrossClinicPatientsContainer />
        </RouteContextProvider>
      </div>
    );
  }

}

export default CrossClinicContainer;
