import React, { forwardRef } from 'react';
import { Select } from 'antd';

export const CellularDevicesDropdownComponent = forwardRef(({
  options,
  ...antdProps
}, ref) => (
  <Select
    ref={ref}
    {...antdProps}
  >
    {
      options.map((opt) => (
        <Select.Option
          key={opt.value}
          value={opt.value}
          disabled={opt.disabled}
        >
          {opt.label}
        </Select.Option>
      ))
    }
  </Select>
));
