/**
 * Created by zizhangai on 1/2/17.
 */
import gql from 'graphql-tag';
import update from 'immutability-helper';
import { ResultForAlerts } from './schema/Result';

export default gql`
  mutation closeAlert($id: EID!) {
    closeAlert(id: $id) {
      ${ResultForAlerts}
    }
  }
`
export const updateQueries = {
  // result: (prev, { mutationResult: { data } }) => {
  //   if (prev.result.id !== data.intervene.id) {
  //     return prev
  //   }
  //   const newResult = data.intervene.notes[0]
  //   return update(prev, {
  //     result: {
  //       notes: {
  //         $unshift: [newResult]
  //       }
  //     }
  //   })
  // },
  resultList: (prev, { mutationResult: { data } }) => {
    if (Object.keys(prev).length === 0 && prev.constructor === Object) {
      return prev
    }
    const { id, careStatus, caredBy } = data.closeAlert
    const newList = (_.get(prev, 'resultList.data') || []).map(item => {
      if (item.id !== id) {
        return item
      } else {
        return update(item, {
          careStatus: {
            $set: careStatus
          },
          caredBy: {
            $set: caredBy
          }
        })
      }
    })

    // return update(prev, {
    //   resultList: {
    //     data: {
    //       $set: newList
    //     }
    //   }
    // })
  }
}
