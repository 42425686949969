import React from 'react';
import Client from 'libModule/gqlClient';
import query from '../query/index'
import {graphql} from "react-apollo";
import {compose} from "recompose";
import ScreenedRemoteEnrollmentComponent from "../component/ScreenedRemoteEnrollmentComponent";
import StickyNoteActionComponent from "../component/StickyNoteActionComponent";
import TBDRemoteEnrollmentComponent from'../component/TBDRemoteEnrollmentComponent';
import ConfirmedRemoteEnrollmentComponent from '../component/ConfirmedRemoteEnrollmentComponent';
import CompletedRemoteEnrollmentComponent from '../component/CompletedRemoteEnrollmentComponent';
import ExcludedRemoteEnrollmentComponent from '../component/ExcludedRemoteEnrollmentComponent';
const { getRemoteEnrollment } = query;
import '../style/index.scss';
import {Modal,Row,Col,Menu,Dropdown} from "antd";
import AppointmentCalenderWrapperContainer from "../../visit/containers/AppointmentCalenderWrapperContainer";
import NotRemoteEnrolledComponent from "../component/NotRemoteEnrolledComponent";
import {userWithTaskCount as userGQL} from "../../graphql/user";
import { lightEnrolledProgramList } from '../../graphql/enrolledProgramList';
import PatientTaskStarComponent from '../../taskAssignment/components/PatientTaskStarComponent';
import { goPath } from 'libModule/utils'
import { getInsuranceProviderName } from '../../patient/AdditionalInfo/Components/InsuranceComponent';
import { renderSelfTag } from '../../visitsPage/helpers';
import UserClass from 'modulesAll/graphql/class/User'
import NumberFormat from 'react-number-format';
import { clicktoDial, ccpUrl } from '../../call/helper/helpers';
import Mixpanel from '../../mixPanel/mixPanel';

const statusToComponent =(status='UNENROLLED',remoteEnrollment,refetchRemoteEnrollment,callPhone,setContact)=>({
    'INIT':<ScreenedRemoteEnrollmentComponent remoteEnrollment={remoteEnrollment} refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone} setContact={setContact}/>,
    'TBD':<TBDRemoteEnrollmentComponent remoteEnrollment={remoteEnrollment} refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone} setContact={setContact}/>,
    'CONFIRMED':<ConfirmedRemoteEnrollmentComponent remoteEnrollment={remoteEnrollment} refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone} setContact={setContact}/>,
    'COMPLETED':<CompletedRemoteEnrollmentComponent remoteEnrollment={remoteEnrollment} refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone} setContact={setContact}/>,
    'EXCLUDED':<ExcludedRemoteEnrollmentComponent remoteEnrollment={remoteEnrollment} refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone} setContact={setContact}/>,
    'UNENROLLED':<NotRemoteEnrolledComponent refetchRemoteEnrollment={refetchRemoteEnrollment} callPhone={callPhone}/>
}[status])

const SingleRemoteEnrollmentContainer = class extends React.Component{
    constructor() {
        super();
        let rmMember = sessionStorage.getItem('rmMember');
        rmMember  = rmMember ? JSON.parse(rmMember) : null;
        this.state={
            showStickyNoteModal:null,
            currentDoc:rmMember
        }
    }

    componentWillUnmount = () => {
      sessionStorage.removeItem('rmMember');
    }

    setShowCalender = (showCalender,singleVisit, visitAction, refetchSingleVisit)=>{
        this.setState({
            showCalender,
            singleVisit,
            refetchSingleVisit
        })
    }

    renderShowCalender = (remoteEnrollment)=>{
        let { showCalender,singleVisit, refetchSingleVisit } = this.state;
        const { refetchRemoteEnrollment, user } = this.props;
        const onCancel = (needRefetchRE = true) => {
          this.setShowCalender(false,null, null, null);
          if(needRefetchRE) refetchRemoteEnrollment();
          if(refetchSingleVisit) refetchSingleVisit();
        }
        return showCalender&&
          <Modal 
            visible
            width={'80%'} 
            className='visitCalenderModal'
            footer={false} 
            onCancel={onCancel}
          >
            <AppointmentCalenderWrapperContainer key={_.get(remoteEnrollment, 'id')}
                                                 selectedVisit={ singleVisit }
                                                 isRemoteEnrollment={!remoteEnrollment}
                                                 remoteEnrollment={remoteEnrollment}
                                                 onCancel={() => onCancel(false)}
                                                 skipSearch={true} patient={{
                                                   ...singleVisit,
                                                   // TODO: user field is temp hack for CT Assign, need to break down value
                                                   user
                                                 }}/>
        </Modal>
    }

    onClickName = () => {
        const { memberId } = this.props
        window.open(`/patients/${memberId}/enrolledprogram/default`).focus();
    }

    renderPatientName = (user)=>{
        const { currentActiveProgramId, refetchUser, memberId } = this.props;
        const isSelfEnrolled = _.get(user,'profile.isSelfEnrolled', false);
        if(user){
            const patientName = _.get(user,'profile.fullName');
            return <div style={{ display: 'flex', lineHeight: '19px' }}>
              <p className='patientFullName' style={{cursor:'pointer'}} onClick={() => this.onClickName()}>{patientName}{isSelfEnrolled ? renderSelfTag() : ''}</p>
              <PatientTaskStarComponent 
                user={user}
                refetchUser={refetchUser}
                currentProgramId={currentActiveProgramId}
              />
            </div>;
        }
    }
    renderInsurance =(user)=>{
        if(user){
            const primaryInsurance = getInsuranceProviderName(_.get(user,'profile'), true);
            const secondInsurance = getInsuranceProviderName(_.get(user,'profile'), false);
            return <Row style={{lineHeight: 2}}>
                    <Col span={12}>
                        {`Primary Insurance: ${primaryInsurance}`}
                    </Col>
                    <Col span={12}>
                        {`Secondary Insurance: ${secondInsurance}`}
                    </Col>
                   </Row>
        }
    }

    renderMobilePhones = () => {
        let mobilePhone = this.uc.format('mobilePhone');
        const phones = _.get(this, 'props.user.phone',[]);
        const patientPhones = phones.filter(p => p.type === 'MOBILE');
        let privateNumberText = '';
        if (patientPhones.length) {
            privateNumberText = (patientPhones[0].private ? ' (Private)' : '');
        }
        let time;
        if (this.props.callDate) {
            let date = new Date(this.props.callDate);
            let duration = this.props.callDuration;
            time = <div style={{ width: '150px' }}><b>{`Last Call: `}</b> <br /> {moment(date).format('MM/DD/YY,HH:mm')} <br /> {duration}</div>;
        } else {
            time = 'No call'
        }
         // retrieve from local storage
         const phoneNumber = mobilePhone.replace(/\s/g, '')
         let historyDictString = localStorage.getItem('call-center-history') || '{}';
         let historyDict = JSON.parse(historyDictString)
         const aPhoneArray = _.get(historyDict, phoneNumber) || []
         const menu = (
             <Menu>
                { aPhoneArray.reverse().map((t, index) => (
                     <Menu.Item key={index}>  {t.start}, duration {t.duration}
                     </Menu.Item>
                 )) }
             </Menu>
         );
        if (mobilePhone) {
            return <div style={{ marginRight: 20 }} className={"profile-keyvalue"}>
                {/* <span><img src={'/image/call-phone.png'} /></span> */}
                Mobile:
                {
                    <Dropdown overlay={menu}>
                        <span onClick={() => this.callPhone(mobilePhone)} >
                            <NumberFormat
                              className='v-patient-link'
                              // format="+# (###) ###-####"
                              format='###-###-####'
                              value={_.replace(mobilePhone, '+1 ', '')}
                              displayType={'text'}
                            />
                            <span style={{ fontStyle: 'italic' }}>{privateNumberText}</span>
                        </span>
                    </Dropdown>
                }
                </div>
        }
        else {
            return '';
        }
    }

    renderHomePhones = () => {
        const homePhone = this.uc.format('homePhone');
        // retrieve from local storage
        const phoneNumber = homePhone.replace(/\s/g, '')
        let historyDictString = localStorage.getItem('call-center-history') || '{}';
        let historyDict = JSON.parse(historyDictString)
        const aPhoneArray = _.get(historyDict, phoneNumber) || []
        const menu = (
            <Menu>
               { aPhoneArray.reverse().map((t, index) => (
                    <Menu.Item key={index}>  {t.start}, duration {t.duration}
                    </Menu.Item>
                )) }
            </Menu>
        );
        if (homePhone) {
            return  <div className={"profile-keyvalue"}>
                {/* <span><img src={'/image/call-phone.png'} /></span> */}
                H:
                {
                    <Dropdown overlay={menu}>
                      <span onClick={() => this.callPhone(homePhone)} >
                        <NumberFormat
                          className='v-patient-link'
                          // format="+# (###) ###-####"
                          format='###-###-####'
                          value={_.replace(homePhone, '+1 ', '')}
                          displayType={'text'}
                        />
                      </span>
                    </Dropdown>
                }
           </div>
        }
        else {
            return '';
        }
    }

    // status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
    async callPhone(phoneNumber) {
        if (this.props.contact == 'onConnecting'
        || this.props.contact == 'onConnected') {
            return window.CCPClass && window.CCPClass.openCCP();
        }

        // validate phone number
        if (phoneNumber == null) {
            Modal.error({
                title: "Error",
                content: "Phone Number is invalid"
            })
            return
        }

        // validate agent
        // when user logout, terminate CCP, then portal user relogin, agent is undefined.
        // the process is load steam -> addCCP -> subscribe

        let agent = window.awsCCP != undefined ? window.awsCCP.agent : undefined;
        if (agent == undefined) {
            Modal.confirm({
                title: 'Click Yes to log in call center, choose Cancel if you are already logged in',
                content: <div><br/>  Please keep the pop-up CCP(Contact Control Panel) open on the side. <br/>  <br/>
                         If you are prompted to allow access to your microphone and speaker, choose Allow. <br/>  <br/>
                         Stay on the patient page during calling, so that Call Log can be popped up when the call ends.</div>,
                okText: `Yes`,
                onOk: () => {
                  window.CCPClass.checkLogin();
                },
                cancelText: `Cancel`,
                onCancel:()=>{
                }
            })
            return
        } else {
            // one call at a time
            let state = agent.getState()
            if (state && (state.name == 'CallingCustomer' || state.name == 'Busy')) {
                // Modal.error({
                //     title: "Error",
                //     content: "Cannot dial now, the line is busy."
                // })
                return
            }

            // Offline happens(1) logout (2) Agent set status to Offline
            // set Agent online, which is no need when portal users make calls
            // could be userful for inbound call
            if (state && state.name == 'Offline') {

                let routableState = agent.getAgentStates().filter(function(state) {
                    return state.type === global.connect.AgentStateType.ROUTABLE;
                 })[0];
                //  console.log('routableState', routableState)
                 agent.setState(routableState, {
                    success: function() {
                        // console.log('success')
                     },
                    failure: function() {
                        // console.log('failure')
                    }
                 });
            }
        }
        let username = ''
        if (this.uc) {
            username = this.uc._get('profile.firstName') + ' ' + this.uc._get('profile.lastName')
        }
        this.props.callPhone(phoneNumber, username);
        if (sessionStorage.getItem('inboundAttributes')){
            sessionStorage.removeItem('inboundAttributes');
        }

        //sync panel status
        this.props.setContact('onConnecting')

        //mark patient id
        const { remoteEnrollment, mostRecentEP } = this.props;
        const patient = _.get(remoteEnrollment,'member') || {};
        const patientId = patient.id;

        // save outbound attribute, click to jump
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        let currentOrgId = currentUser.selectedRole.organization.id;
        let outboundAttributes = {patientId:{value:patientId},
                                organizationId: {value: currentOrgId},
                                enrolledProgramId: {value: _.get(mostRecentEP, 'id')},
                                name: {value: username},
                                operationStatus: {value: _.get(mostRecentEP, 'status')},
                              }

        if (sessionStorage.getItem('outboundAttributes')){
            sessionStorage.removeItem('outboundAttributes');
        }
        sessionStorage.setItem('outboundAttributes', JSON.stringify(outboundAttributes));

        clicktoDial(phoneNumber, this.onConnected, this.onEnded, this.makeACallError);

        Mixpanel.track('clicked', 'phone', 'clickToDial', { "PATIENT_ID": patientId });
    }

    render() {
        const { renderPatientName, renderShowCalender, renderInsurance, renderMobilePhones, renderHomePhones} = this;
        const { remoteEnrollment,loading,refetchRemoteEnrollmentList,refetchRemoteEnrollment,memberId,contact,refetchRemoteEnrollmentSummary,callPhone,setContact,setCallPhoneInfo,callPhoneNumber,user,enrolled} = this.props;
        this.uc = new UserClass(user);
        const patientId = _.get(remoteEnrollment,'member.id');
        const status = _.get(remoteEnrollment,'status');
        if(loading) {
            return <div>Loading</div>
        };
        // if(remoteEnrollment) {
            return <div style={{ minHeight:550 }}>
                        { renderShowCalender(remoteEnrollment) }
                        { renderPatientName(user) }
                        { status === 'CONFIRMED' ? 
                            <div style={{display:'flex'}}>
                                {renderMobilePhones()}                            
                                {renderHomePhones()}
                            </div> : '' }
                        { renderInsurance(user)}
                        { statusToComponent(status, remoteEnrollment,refetchRemoteEnrollment,callPhone,setContact) }
                        <StickyNoteActionComponent currentDoc={ remoteEnrollment }
                                                   patientId={patientId||memberId}
                                                   refetchRemoteEnrollment={refetchRemoteEnrollment}
                                                   refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                                                   refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}
                                                   setShowCalender={this.setShowCalender}
                                                   tabSelected={status}
                                                   contact={contact}
                                                   user={user}
                                                   setCallPhoneInfo={setCallPhoneInfo}
                                                   callPhoneNumber={callPhoneNumber}
                                                   enrolled={enrolled}
                        />
                   </div>;

    }
}

const data = graphql(getRemoteEnrollment, {
    options:(props)=>{
        const { memberId } = props;
        return{
            variables:{
                memberId
            },
            fetchPolicy:'network-only'
        }
    },
    props: ({ data }) => {
        const { loading,refetch,getRemoteEnrollment } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            remoteEnrollment:getRemoteEnrollment,
            refetchRemoteEnrollment: refetch
        };
    }

})
const userData = graphql(userGQL, {
    options: (ownProps) => {
        const id = _.get(ownProps,'currentDoc.member.id',_.get(ownProps,'memberId'));
        return {
            variables: {
                id
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy:'network-only'
        }
    },
    props:({ownProps,data})=>{
        const { loading,user, refetch } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            user,
            refetchUser: refetch
        }
    }
});

const lightEPList = graphql(lightEnrolledProgramList, {
  options: ownProps => {
    const memberId = _.get(ownProps,'currentDoc.member.id',_.get(ownProps,'memberId'));
    return {
      variables: {
        count: 1,
        page: 1,
        filters: { memberId }
      },
      fetchPolicy: 'network-only'
    };
  },
  props:({data})=>{
    const { enrolledProgramList,loading } = data;
    if(loading) return { loading };
    // currentActiveProgramId to fetch team for patient's tasks
    let currentActiveProgramId, mostRecentEP; 
    if(enrolledProgramList) {
      mostRecentEP = _.get(enrolledProgramList, 'data.0', {}); 
    }
    if(mostRecentEP && mostRecentEP.status === 'STARTED'){
      currentActiveProgramId = mostRecentEP.id;
    }
    return {
      mostRecentEP,
      currentActiveProgramId
    };
  }
});

export default compose(data,userData, lightEPList)(SingleRemoteEnrollmentContainer);
