import gql from 'graphql-tag';

export default gql`
  query getMonthlyReviewRecommendation ($memberId: EID) {
    getMonthlyReviewRecommendation (memberId: $memberId) {
      count
      recommend
      vital
    }
  }
`;
