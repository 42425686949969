import React from 'react';
import { nationOptions, langOptions, countryOptions,
    docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import helper from '../helper/index';
import API from '../../API/index'
import { Input,Form,Button } from 'antd';
let CreateConsentComponent = class extends React.Component{
    renderLanguageDropDown = ()=>{
        const { selectedLanguages } = this.props;
        return helper.renderDropDown(langOptions,selectedLanguages);
    }

    renderContentInput = ()=>{
        return <Input.TextArea rows={20}/>
    }

    renderForm = ()=>{
        const { template } = this.props;
        const { getFieldDecorator } = this.props.form;
        return <Form>
                <Form.Item label='Language'>
                    {
                        getFieldDecorator(`language`, {initialValue:_.get(template,'language.code'),
                        rules: [{ required: true }]})(this.renderLanguageDropDown())
                    }
                </Form.Item>
                <Form.Item label='HTML'>
                    {
                        getFieldDecorator(`content`, {initialValue:_.get(template,'content'),
                        rules: [{ required: true }]})(this.renderContentInput())
                    }
                </Form.Item>
               </Form>
    }

    onSave = ()=>{
        const { form,refetchConsentTemplateList,id ,handleVisibleChange,template,handleEditChange} = this.props;
        const templateId = _.get(template,'id');
        form.validateFieldsAndScroll((err,res)=>{
            if(!err) {
                if (!templateId) {
                    API.createConsentTemplate({...res, groupId: id}).then(res => {
                            if (refetchConsentTemplateList) {
                                refetchConsentTemplateList();
                            }
                        }
                    );
                }
                if( templateId ){
                    API.editConsentTemplate({...res,groupId:id,id:templateId}).then(res=>{
                            if (refetchConsentTemplateList) {
                                refetchConsentTemplateList();
                            }
                    })
                }
                handleVisibleChange('visibility',{ });
            }
        })
    }
    renderButtons=()=>{
        const { type } = this.props
        const handleVisibleChange = _.get(this,'props.handleVisibleChange')
        const handleEditChange =  _.get(this,'props.handleEditChange');

        return <div className={'buttons'}>
                <Button onClick={()=>this.onSave()} className={'save'}>save</Button>
                <Button onClick={()=>{
                    if(handleVisibleChange) {
                        handleVisibleChange('visibility',{ })
                    }
                    if(handleEditChange){
                        handleEditChange(false,null,type);
                    }
                    this.props.form.resetFields();
                }} className={'cancel'}>Cancel</Button>
              </div>
    }
    render() {
        return <div className={'createConsentContainer'}>
                    { this.renderForm() }
                    { this.renderButtons()}
               </div>
    }
}
CreateConsentComponent = Form.create()(CreateConsentComponent);
export default CreateConsentComponent;