import gql from 'graphql-tag';
import { UserPreferenceSchema } from '../schema/UserPreference';

export default gql`
  mutation upsertUserPreference (
    $memberId: EID!, 
    $followupVisits: UserPreferenceInput,
    $nutritionCoaching: UserPreferenceInput
    $sendVitalReminder: UserPreferenceInput
  ) {
    upsertUserPreference(
      memberId: $memberId,
      followupVisits: $followupVisits,
      nutritionCoaching: $nutritionCoaching
      sendVitalReminder: $sendVitalReminder
    ) {
      id
      member {
        id
      }
      ${UserPreferenceSchema}
    }
  }
`;
