import gql from 'graphql-tag';

export  default gql`mutation editMedication($id:EID!,$active:Boolean,$memberId:EID!,$name:String,$type:MedicationTypeEnum,$dosage:String,$startDate:Date,$endDate:Date,
$instruction:String,$otherMD:Boolean,$status:MedicationStatus) {
  editMedication(id:$id,active:$active,memberId:$memberId,name:$name,type:$type,dosage:$dosage,instruction:$instruction,
  startDate:$startDate,endDate:$endDate,status:$status,otherMD:$otherMD) {
    id
    member {
      id
      profile {
        fullName
      }
    }
    name
    type
    startDate
    endDate
    otherMD
    healthCondition
    dosage
    instruction
    active
    createdAt
    updatedAt
    status
    histories {
        field
        originalValue
        newValue
    }
  }
}`
