import * as d3 from 'd3';
import {React, BaseComponent, _} from 'ihcomponent';

const BloodPressureChart = class extends BaseComponent {
  static displayName = 'BloodPressureChart'

  render() {
    return (
      <div className="bp-wrapper">HELLO!
        { this.renderBody() }
      </div>
    )
  }

  renderBody() {

  }
}

export default BloodPressureChart;