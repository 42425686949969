import { useEffect } from 'react';
import { replacePath } from './lib/utils';

const RouteTransitionComponent = (props) => {
  const { getTransitionPath, params = {}, location } = props;
  const curPathName = _.get(location, 'pathname') || '/';

  useEffect(() => {
    let redirectPathWithQuery = curPathName;
    if (typeof getTransitionPath === 'function') {
      redirectPathWithQuery = getTransitionPath(params);
    }
    replacePath(redirectPathWithQuery);
  }, []);
  return null;
}

export default RouteTransitionComponent;
