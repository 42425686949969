import React from 'react';
import I18N from '../../I18N';

const AssignToCTMessage = (props) => {
  const { from, loadingDx, isEnrolled, conditionsList, healthConditions } = props;
  if(!isEnrolled)
    return null;

  const I18NMissingMsg = from ? I18N.get(`ctAssignees.missingMsg.${from}`) : I18N.get('ctAssignees.missingMsg.VISIT_POPUP');
  const msg = I18NMissingMsg.text;
  const msgWithNoDx = I18NMissingMsg.withNoDx;
  const _conditionsList = _.omit(conditionsList, '__typename');
  const emptyConditionsList = _.isEmpty(_conditionsList) || !_.filter(_conditionsList, v => !!v).length;
  
  return (
    <div style={{color:'#DA6453'}}>
      <div>
        <img src='/image/red_info_icon.png' style={{ marginRight: 8 }}/>
        {msg}
      </div>
      <div>
        {
          (!loadingDx && _.isEmpty(healthConditions) && emptyConditionsList) 
            && msgWithNoDx
        }
      </div>
    </div>
  );
};

export default AssignToCTMessage;