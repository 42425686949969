import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import moment from "moment";

const OrganizationListTemplate = ({id, name, internalName, leaf,startDate,createdAt,managers}) => {
    const renderManagers = managers && managers.length>0 ? managers.map(m=>m.fullName).join(', '):'';
    const src = leaf ? '/image/icon_clinic.svg' : '/image/icon_area.svg';
    const displayedName = leaf ? internalName : name;
    return <Row>
            <Col span={2}>
                <img className='icon' src={src} style={{ width: 18, marginTop: -6 }}/>
            </Col>
            <Col span={16} style={{textOverflow:'ellipsis', overflow:'hidden'}} className='organizationName'>
                <div>{ displayedName }</div>
                <div className={'managers'}>{ renderManagers  }</div>
            </Col>
            <Col span={6} style={{textOverflow:'ellipsis', overflow:'hidden'}} className='startDate'>
                {moment(startDate||createdAt).format('MM/DD/YYYY')}
            </Col>
           </Row>;
};

export default OrganizationListTemplate;