import React, { Component } from 'react'
import { connect } from 'react-redux';
import { modalAction } from 'modulesAll/common/actions';
import { openConfirmModal, removeICDcode, unselectBillable } from '../../helpers/healthConditionHelper';
import { Form, Icon, Button, Checkbox, Input, Table, Popconfirm, Row, Modal } from 'antd';
import HealthConditionPicker from 'modulesAll/patient/AdditionalInfo/Components/HealthConditionPickerComponent/HealthConditionPicker';
import I18N from 'modulesAll/I18N';
import ComplexityAndHealthCoachContainer from '../../../patient/main/containers/ComplexityAndHealthCoach';
import { updatePatientComplexity } from '../../../patient/main/API';
import { getBillableAndNonBillableCodes } from '../helper/healthConditionHelper';
import { eventHelperCreator, EVENT_MAPS } from '../../../../lib/helpers/event-helpers';
import helper from "../../../careplanNew/helpers/index"
import BillableConditionHelper from './BillableConditionHelper';
import withPatientConsent from '../../../../lib/hooks/withPatientConsent';

const eventHelper = eventHelperCreator(EVENT_MAPS.complexity, 'icdCode');
export class HealthCdComponent extends Component {
    static displayName = 'HealthCdComponent'
    constructor(props) {
        super(props);
        this.state = {
            complexityCb: null,
        };
        this.queryVariables = { 
            cb: this.updateComplexity
        };
        this.billableConditionHelper = new BillableConditionHelper({
          patient: props.user,
          enrolledProgram: props.program,
          patientReferral: _.get(props, 'user.remoteEnrollment.patientRefer') || {},
          consentInfo: props.consentInfo
        });
    }

    componentDidUpdate(prevProps) {
        const { complexityCb } = this.state;
        if (prevProps.disabled && !this.props.disabled && typeof complexityCb === 'function') {
            complexityCb();
        }
    }

    // Api used EditUser
    generateVar = () => {
        const { healthConditions } = this.props.form.getFieldsValue();
        const { billableHealthConditions,mntHealthConditions } = this.props.form.getFieldsValue();
        const updatedBillableConditions = [];
        const updatedMNTHealthConditions = [];
        _.forEach(billableHealthConditions, con => { updatedBillableConditions.push(_.omit(con, '__typename')) });
        _.forEach(mntHealthConditions,con=>{ updatedMNTHealthConditions.push(_.omit(con, '__typename')) });
        const patientId = this.props.patientId;
        const memberProfile = {
            birthday: this.props.user.profile.birthday,
            healthConditions,
            billableHealthConditions: updatedBillableConditions,
            mntHealthConditions: updatedMNTHealthConditions
        }
        let variables = {
            id: patientId,
            memberProfile
        }
        return variables;
    }

    addHealthCondition = (value, clearFieldFn) => {
        const { form } = this.props;
        const { healthConditions, billableHealthConditions } = form.getFieldsValue();
        let newHealthConditions = [...healthConditions];
        if( healthConditions.indexOf(value) == -1) {
            newHealthConditions = [value].concat(newHealthConditions);
            this.props.form.setFieldsValue({
                healthConditions : newHealthConditions
            })
        }
        if(clearFieldFn) clearFieldFn();
        this.passQueryVars();

        this.billableConditionHelper.checkAndAutoMarkBillable(
          value,
          (newBillableHealthConditions) => {
            form.setFieldsValue({ 'billableHealthConditions': newBillableHealthConditions });
            this.passQueryVars();
          },
          newHealthConditions,
          billableHealthConditions
        );
    }

    copyBillableHealthConditions(array){
        const copiedArray = array.slice();

        return copiedArray.map(i=>({
            description:i.description,
            code:i.code
        }));
    }

    passQueryVars(){
        // let queryVariables = {};
        this.queryVariables.editUserVar = this.generateVar();
        this.queryVariables.shouldEditUser = true;
        this.queryVariables.props = this.props;
        this.props.onChange(this.queryVariables);
    }

    updateComplexity = async () => {
        const { complexityLevel } = this.props.form.getFieldsValue();
        if (complexityLevel) {
            const { level, source } = complexityLevel;
            try {
                await updatePatientComplexity({ memberId: this.props.patientId, level, source });
                eventHelper.create();
            } catch (err) {
                console.error(err);
            }
        }
    }

    removeHealthCondition=(index)=> {
        let { healthConditions } = this.props.form.getFieldsValue();
        const keys = ['billableHealthConditions','mntHealthConditions'];
        const removeItemFromArray = (arr,value)=>{
            return _.remove(arr,  (o)=>{
                return o.code!=value;
            })
        };
        const obj = {};
        const value = _.get(healthConditions[index].split('::'),'1',null);
        healthConditions =  _.remove([...healthConditions], function (_, i) {
            return i != index;
        });
        for (const key of keys) {
            const codes = _.get(this.props.form.getFieldsValue(),key) || []; 
            const arr = [...codes];
            _.set(obj,key,removeItemFromArray(arr,value));
        }
        _.set(obj,'healthConditions',healthConditions);
        // const { healthConditions,billableHealthConditions } = this.props.form.getFieldsValue();
        // const copyHealthConditions = healthConditions.slice();
        // const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        this.props.form.setFieldsValue(obj);
        //     {
        //     healthConditions: _.remove(copyHealthConditions, function (o) {
        //         return o.code!=value;
        //     }),
        //     billableHealthConditions: value ? _.remove(copyBillableHealthConditions, function (o) {
        //         return o.code!=value;
        //     }) : copyBillableHealthConditions
        // })
        this.passQueryVars();
    }

    uncheckCondition = (pars)  => {
        const {copiedArray, arrayKey,code } = pars;

        this.props.form.setFieldsValue({
            [arrayKey]: _.remove(copiedArray, function (o) {
                return o.code!=code;
            })
        })
    }

    checkCondition = (pars)=>{
        const {copiedArray, arrayKey,code,v } = pars;
        copiedArray.push({ description:v.name, code:code });
        this.props.form.setFieldsValue({ [arrayKey]: copiedArray })
    }

    onCheckChange = (arrayKey,v) => {
        const array = this.props.form.getFieldValue(arrayKey)||[];
        const copiedArray = array.slice();
        const code = _.get(v,'code','');
        const { conditionCheckedByItem } = this;
        if (conditionCheckedByItem(copiedArray,v)) {
            this.uncheckCondition({arrayKey, copiedArray, code });
        }else{
            this.checkCondition({ copiedArray,arrayKey,code,v });
        }
        this.passQueryVars();

    }

    // unCheckBillable = (pars)  => {
    //
    //     this.passQueryVars();
    // }




    onComplexityChange = (complexityLevel) => {
        const complexityCb = () => {
            this.props.form.setFieldsValue({ complexityLevel });
            this.props.onChange(this.queryVariables);
        };

        if (this.props.disabled) {
            this.setState({ complexityCb });
        } else {
            complexityCb();
        }
    }

    renderHealthConditionPicker=()=>{
        const { disabled } = this.props
        return <div className='ant-col-24' style={{ height: 'fit-content' }}>
            <div style={{width:'33%'}}>
                <HealthConditionPicker addHealthCondition={ this.addHealthCondition } disabled={disabled}/>
                {/* shadow fields */}
                <Form.Item span={12} style={{visibility:'hidden',height:1,padding:0,margin:0}}>
                {
                    this.props.form.getFieldDecorator('healthConditions', {
                        // rules: [ {required: true, message: 'ICD Code is required' }],
                        initialValue: _.get(this, 'props.user.profile.healthConditions'),
                    })(<Input style={{display: 'none'}}/>)
                }
                </Form.Item>
                <Form.Item span={12} style={{visibility:'hidden',height:1,padding:0,margin:0}}>
                {
                    this.props.form.getFieldDecorator('billableHealthConditions', {
                        initialValue: _.get(this, 'props.user.profile.billableHealthConditions') || [],
                    })(<Input style={{display: 'none'}}/>)
                }
                </Form.Item>
                <Form.Item span={12} style={{visibility:'hidden',height:1,padding:0,margin:0}}>
                    {
                        this.props.form.getFieldDecorator('mntHealthConditions', {
                            initialValue: _.get(this, 'props.user.profile.mntHealthConditions') || [],
                        })(<Input style={{display: 'none'}}/>)
                    }
                </Form.Item>
                { this.billableConditionHelper.renderProgramChangePrompt(this.props.form) }
            </div>
        </div>
    }

    conditionCheckedByItem = (array,item)=>{
        return array.findIndex((i) => item.code == i.code) != -1
    }

    renderHealthConditionTable() {
        const { healthConditions = _.get(this, 'props.user.profile.healthConditions') } = this.props.form.getFieldsValue();
        const isMNT = helper.isMNTEnrolled(this.props);
        const healthConditionsSource = _.map(healthConditions, (value) => ({
                name: value.split('::')[0],
                code: value.split('::')[1],
                value: value
        }));
        const tableColumn = [
            {
                key: 'code',
                title: 'Code',
                width:'10%',
                dataIndex: 'code',
                render: (code) => this.billableConditionHelper.renderCode(code),
            },
            {
                key: 'name',
                title: 'Condition',
                ellipsis: true,
                dataIndex: 'name'
            },
            {
                key:'billableHealthConditions',
                title: isMNT ? 'Billable' :'CCM/RPM',
                width:'7%',
                render:(v,doc)=>{
                    const { billableHealthConditions = _.get(this, 'props.user.profile.billableHealthConditions',[]) } = this.props.form.getFieldsValue();
                    const checked = this.conditionCheckedByItem(billableHealthConditions,v);
                    return v.code ? 
                      (!this.props.disabled && checked)  ? (
                        <Popconfirm
                          title={
                            <div>
                              <div className='title'>
                                {I18N.get('healthCondition.unselectBillable.title')}
                              </div>
                              <div className='description'>
                                A corresponding clinical goal is added (See in Goal section). Upon <span className='highlight'>unmarking</span> the ICD-10 code as billable, the related clinical goal will be removed as well
                              </div>
                            </div>
                          }
                          okText='Yes'
                          overlayClassName='billable-unmark-popconfirm'
                          onConfirm={() => this.onCheckChange('billableHealthConditions',v, doc)}
                        >
                          <Checkbox checked={checked} disabled={this.props.disabled}/>
                        </Popconfirm>

                      )
                      : (
                        <Checkbox onChange={() => this.onCheckChange('billableHealthConditions',v,doc)} checked={checked} disabled={this.props.disabled}/>
                      )
                    : '';
                }
            }]
        if(isMNT) {
            const { mntHealthConditions = _.get(this, 'props.user.profile.mntHealthConditions') } = this.props.form.getFieldsValue();
            tableColumn.push({
                key:'mntHealthConditions',
                title:'MNT',
                width:'10%',
                render:(v,doc)=>{
                    const checked = !Array.isArray(mntHealthConditions) ? false : mntHealthConditions.findIndex((i) => v.code == i.code) != -1;
                    return <Checkbox checked={checked} disabled={this.props.disabled} onChange={()=>this.onCheckChange('mntHealthConditions',v)} />;
                }
            });
        }
        tableColumn.push({
                key: 'remove',
                width:'10%',
                render: (text, record, index) => <Button onClick={() => openConfirmModal(removeICDcode, index, this)} disabled={this.props.disabled}><Icon
                type="close"/></Button>
        })


        return <div id='health-condition-table' className='ant-col-24'>
            <Table dataSource={ healthConditionsSource }
                   columns={ tableColumn }
                   rowKey={(key) => key.code}
                   pagination={false}
            />
        </div>
    }

    render() {
      const { form, user } = this.props;
      const { billableHealthConditions, nonBillableHealthConditions } =  getBillableAndNonBillableCodes(form.getFieldsValue(), user);
        return (
            <Form layout='vertical' className='additionInfoContentForm'>
                <Row>
                    {this.renderHealthConditionPicker()}
                    <div style={{   color: '#717171',padding:'10px 0px'}}>
                    Note: {I18N.get('healthCondition.note')}
                    </div>
                    {this.renderHealthConditionTable()}
                </Row>
                <Row>
                  {
                    this.props.form.getFieldDecorator('complexityLevel')(
                      <div>
                          <ComplexityAndHealthCoachContainer 
                              patientId={this.props.patientId}
                              isEnrolled={_.get(this.props, 'program.status') === 'STARTED'}
                              nonBillableCodes={nonBillableHealthConditions}
                              billableCodes={billableHealthConditions}
                              onChange={this.onComplexityChange}
                              disabled={this.props.disabled}
                              hasAssignees={_.get(user, 'assignees', '')}
                          />
                      </div>
                    )
                  }
                </Row>
            </Form>
        )
    }
}

const mapToDispatch = (dispatch)=>{
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
    }
}
HealthCdComponent = Form.create({})(HealthCdComponent);

export default connect(
  null,
  mapToDispatch
)(withPatientConsent(HealthCdComponent, { 
  getMemberId: (props) => _.get(props, 'user.id'),
  waitForConsentInfo: true,
}));