import React from 'react'
import moment from 'moment'
import { _ } from 'ihcomponent'
import RequestCache from 'libModule/requestCache'
import { alertColor, HSColor, alert_BP_Color } from '../constants/VitalListConstants'
import { formatSchedule} from 'modulesAll/utils/helpers/task';
import GoThumbImageComponent from 'modulesAll/gothumb/components/GoThumbImageComponent'
import { TASK_TYPE_MAP } from 'modulesAll/utils/constants/task'
import ResultInfo from 'modulesAll/alerts/InterventionNotes/containers/ResultInfoContainer.js'
import { convertBG_mmolTomgdl } from 'libModule/utils/convertUnits';
import I18N from 'modulesAll/I18N'
import convertor from '@ihealth/convertor';
import { BMIRes }  from 'modulesAll/utils/constants/BMIMap';
import { convertCelsiusToFahrenheit } from 'libModule/utils/convertUnits';
import { setBPReadingColor } from 'libModule/utils/common';
const styles = {
  img: {
    width: '100%',
    // height: 'auto'
    maxHeight: '800px',
    borderRadius: '4px'
  },
  notes: {
    width: '100%',
    padding: '14px 12px',
    backgroundColor: '#eee',
    textAlign: 'left',
    borderRadius: '4px',
    fontSize: '12px'
  },
  measurement: {
    margin: '0 0 10px 0',
    padding: '12px 12px 12px',
    borderRadius: '4px',
    color: 'white'
  },
  heartBeat:{
    textAlign:'right',
    fontSize:18,
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-start',
    justifyContent:'flex-end',
    padding:'12px 0px 12px 12px'
  },
  alertMsg: {
    name: {
      fontSize: '22px',
      fontWeight: 600,
      color: '#19313a',
      wordWrap: 'break-word',
      textAlign: 'left'
    },
    wrapper: {
      textAlign: 'left',
      margin: '10px 0 20px 0',
      lineHeight: 1.7,
      color: '#19313a'
    },
    span: {
      fontSize: '15px',
      margin: '0 10px 0 0',
      fontWeight: 600
    },
    spanValue: {
      borderRadius: '4px',
      padding: '0 4px',
    }
  },
  patientNoteTitle: {
    width: '100%',
    textAlign: 'start',
    fontWeight: 600,
    fontSize: '15px',
    marginTop:20
  },
  tagStyle:{
      backgroundColor: '#f0f0f0',
      padding:5,
      fontWeight:'normal',
      borderRadius:4,
      whiteSpace:'nowrap',
      marginRight:5
  },
  moodStyle:{
      fontWeight:'normal',
  }
};
const tagsMap = {
                  'hadMedicine':'Took oral meds',
                  'hadInsulin' : 'Took Insulin',
                  'hadActivity': "Exercised"
                }


const showMoodTypes = { 'BG':true,'BP':false };

const renderTags = (doc)=>{
    let isEmpty = true;
    const keys =  Object.keys(tagsMap);
    _.forEach(keys,(k)=>{
        if(Array.isArray(doc[k])){
            isEmpty&=!(!!doc[k].length)
        }else{
            isEmpty&=!(doc[k]);
        }
    });
    if(isEmpty){
        return <div>--</div>;
    }
    return <div style={{display:'flex'}}>
          {

            keys.map((k,i)=>{
              if(doc[k]) {
                  let content = <span style={styles.tagStyle} key={i}>{tagsMap[k]}</span>
                  return content;
              }
            })
          }
        </div>
}

const renderMoods = (doc)=>{
    const { moodTags } = doc;
    const hasTags = moodTags.length>0;
    return <div>
             <span style={styles.moodStyle}>{
                     hasTags ? moodTags.join(',') : '--'
                   }
             </span>
           </div>
}

const PatientVitalModal = ({ row, type, provider }) => {
  const renderMeasurement = (row) => {
    if (row.taskStatus === 'MISSED')
      return renderMissed(row)
    switch (type) {
      case 'HS':
        return renderMeasurementHS(row)
      case 'BG':
        return renderMeasurementBG(row)
      case 'BP':
        return renderMeasurementBP(row)
      case 'HR':
        return renderMeasurementHR(row)
      case 'PO':
        return renderMeasurementPO(row)
      case 'TM':
        return renderMeasurementTM(row)   
      default:
        return null
    }
  }
  return (
    <div>
      { provider && renderPatientName(row) }
      { provider && <ResultInfo row={row} /> }
      { renderMeasurement(row) }
      { provider && (_.get(row, 'measure.imageUrl') || _.get(row, 'measure.user_notes'))
      && <div style={styles.patientNoteTitle} >Patient Notes</div> }
      { row.measure && row.measure.imageUrl && renderPic(_.split(row.measure.imageUrl, '/files/')[1]) }
      { row.measure && row.measure.user_notes && renderNotes(row.measure.user_notes) }
      { <div style={styles.patientNoteTitle }>Tags{renderTags(row)}</div> }
      { showMoodTypes[row.type] ? <div style={ styles.patientNoteTitle }>Mood and Symptoms{renderMoods(row)}</div>:'' }
    </div>
  )
};

export default PatientVitalModal

function renderPic (url) {
  //return (<GoThumbImageComponent filename={url} style={styles.img} />);
  return <img src={url} style={styles.img} />
}

function renderNotes (notes) {
  // get current role
  // let header = ''
  // let role = decryptRole()
  // if (role.startsWith('MEMBER')) {
  //   header = 'My Note: '
  // } else if (role.startsWith('PROVIDER')) {
  //   header = 'Patient Note: '
  // }
  return (
    <div style={styles.notes}>
      {/*<span style={{fontWidth: 600}}>{header}</span>*/}
      {notes}
    </div>
  )
}

function renderMeasurementBP (row) {
  const { diastolic_blood_pressure: diastolic, systolic_blood_pressure: systolic,arrhythmia,heart_rate } = row;
  const bpUnit = diastolic? diastolic.unit : systolic? systolic.unit : 'mmHg';
  const heart_rate_value = heart_rate ? heart_rate.value : 0;
  const heart_rate_unit = 'bpm';
  const heart_rate_string = `${heart_rate_value} ${heart_rate_unit}`;

  let backgroundColorType = '';
  if (row.severity === 'MISSED') {
    backgroundColorType = 'MISSED';
  } else {
    backgroundColorType = setBPReadingColor((systolic ? systolic.value : ''), (diastolic ? diastolic.value : ''));
  }

  const backgroundColor = backgroundColorType ? alert_BP_Color[backgroundColorType || 'UNDEFINED'] : alert_BP_Color['UNDEFINED'];

  const arrhythmiaIndicator = arrhythmia ? <img src="/image/arrhythmia-hr-icon-white.svg" width={35} style={{marginRight:12}}/>:'';
  return (
    <div style={{...styles.measurement, backgroundColor,lineHeight:1}}>
      <div style={{textAlign: 'right'}}>
        <span style={{fontSize: '50px'}}>{`${systolic? systolic.value : '- - -'}/${diastolic? diastolic.value : '- - -'}`}</span>
        <span style={{fontSize: '18px'}}>{bpUnit}</span>
      </div>
      <div style={styles.heartBeat}>{arrhythmiaIndicator} {heart_rate_string}</div>
      {/*<div>{arrhythmiaIndicator}</div>*/}
      <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderMeasurementBG (row) {
  const { measure: { mealType, beforeMeal }, blood_glucose: { value, unit } } = row;
  const backgroundColor = row.severity? alertColor[row.severity || 'UNDEFINED'] : alertColor['UNDEFINED'];
  const mealSchedule = `${beforeMeal? 'before' : 'after'} ${mealType.toLowerCase()}`
  return (
    <div style={{...styles.measurement, backgroundColor}}>
      <div style={{textAlign: 'right'}}>
        <div style={{float: 'left', margin: '10px 0 0 0'}}>{mealSchedule}</div>
        {
          unit === I18N.get('measure.units_BG_UK_standard') ?
            <div>
              <span style={{fontSize: '50px'}}>{convertBG_mmolTomgdl(value)}</span>
              <span style={{fontSize: '18px'}}>{I18N.get('measure.units_BG_US_standard')}</span>
            </div>
            :
            <div>
              <span style={{fontSize: '50px'}}>{value}</span>
              <span style={{fontSize: '18px'}}>{unit}</span>
            </div>
        }
      </div>
      <div style={{clear: 'left', textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderMeasurementHS (row) {

  const { body_weight: { value },user,body_weight_change,date } = row;
  const weightInKg = value;
  const heightInM = _.get(user,'profile.height.value',0)/100;
  const BMI = heightInM&&weightInKg ? (weightInKg/heightInM/heightInM).toFixed(1):0;


  const bmiIndicator = BMI!=0 ? BMIRes(BMI): { text : 'UNDEFINED' };
  const bodyWeightValue = convertor.HS(value,'kg','lbs').toFixed(1);
  const bodyWeightUnit = I18N.get('measure.units_weight_lbs');
  const backgroundColor = bmiIndicator ? HSColor[bmiIndicator.text] : '';
  const weightChangeValue = convertor.HS(_.get(body_weight_change,'value',0),'kg','lbs').toFixed(1);

  return  <div style={{...styles.measurement, backgroundColor}}>

        <div style={{textAlign:'right'}}>
            <span style={{fontSize:44,marginRight:5,lineHeight:1}}>{bodyWeightValue}</span>
            <span style={{fontSize:18}}>{bodyWeightUnit}</span>
        </div>

        <div style={{textAlign:'right'}}>
            <span>{bmiIndicator.text=='UNDEFINED' ? '--' : bmiIndicator.text }</span>
        </div>

       <div style={{textAlign:'right',display:'flex'}}>
        <div style={{width:'50%'}}>
            <span style={{fontSize:30,marginRight:5}}>{Math.abs(weightChangeValue)}</span>
            <span style={{fontSize:18}}>{bodyWeightUnit}</span>
        </div>
        <div style={{width:'50%'}}>
            <span style={{marginRight:5}}>BMI</span>
            <span style={{fontSize:30}}>{BMI==0 ? '--' :BMI}</span>
        </div>
       </div>

       <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(date).format('MMM-DD-YYYY H:mm')}</div>

      {/*<div style={{textAlign: 'right'}}>*/}
        {/*<span style={{fontSize: '50px'}}>{*/}
          {/*(_.get(row, 'threshold.0.measure') === 'WEIGHT_CHANGE' ||*/}
          {/*_.get(row, 'threshold.0.measure') === 'WEIGHT_CHANGE_BASELINE')?*/}
          {/*value.toFixed(1) : value}*/}
        {/*</span>*/}
        {/*<span style={{fontSize: '18px'}}>{unit}</span>*/}
        {/*{_.get(row, 'threshold.0.measure') === 'WEIGHT_CHANGE'?*/}
          {/*<span style={{fontSize: '50px'}}>*/}
            {/*{` (${row.body_weight_change.value.toFixed(1)}`}*/}
            {/*<span style={{fontSize: '18px'}}>{unit}</span>*/}
            {/*{`)`}*/}
          {/*</span> : null}*/}
        {/*{_.get(row, 'threshold.0.measure') === 'WEIGHT_CHANGE_BASELINE'?*/}
          {/*<span style={{fontSize: '50px'}}>*/}
            {/*{` (${row.body_weight_change_baseline.value.toFixed(1)}`}*/}
            {/*<span style={{fontSize: '18px'}}>{unit}</span>*/}
            {/*{`)`}*/}
          {/*</span> : null}*/}
      {/*</div>*/}
      {/*<div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>*/}
    </div>
}

function renderMeasurementHR (row) {
  const { heart_rate: { value, unit } } = row;
  const backgroundColor = row.severity? alertColor[row.severity || 'UNDEFINED'] : alertColor['UNDEFINED'];
  return (
    <div style={{...styles.measurement, backgroundColor}}>
      <div style={{textAlign: 'right'}}>
        <span style={{fontSize: '50px'}}>{value}</span>
        <span style={{fontSize: '18px'}}>{unit}</span>
      </div>
      <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderMeasurementPO(row) {
  const { blood_oxygen: {value, unit} } = row;
  const backgroundColor = row.severity? alertColor[row.severity || 'UNDEFINED'] : alertColor['UNDEFINED'];
  return (
    <div style={{...styles.measurement, backgroundColor,lineHeight:1}}>
      <div style={{textAlign: 'right'}}>
        <span style={{fontSize: '50px'}}>{value ? value : '- - -'}</span>
        <span style={{fontSize: '18px'}}>{unit}</span>
      </div>
      <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderMeasurementTM(row) {
  const {body_temperature: {value, unit}} = row;
  const backgroundColor = row.severity? alertColor[row.severity || 'UNDEFINED'] : alertColor['UNDEFINED'];
  let valCopy = value.toFixed(1);
  if(unit === 'C') {
    valCopy = convertCelsiusToFahrenheit(valCopy).toFixed(1);
  }
  return (
    <div style={{...styles.measurement, backgroundColor, lineHeight:1}}>
      <div style={{textAlign: 'right'}}>
        <span style={{fontSize: '50px'}}>{valCopy ? valCopy : '- - -'}</span>
        <span style={{fontSize: '18px'}}>{'F'}</span>
      </div>
      <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderMissed(row) {
  const backgroundColor = alertColor['MISSED'];
  return (
    <div style={{...styles.measurement, backgroundColor}}>
      <div style={{textAlign: 'right'}}>
        <span style={{fontSize: '50px'}}>Missed</span>
      </div>
      <div style={{textAlign: 'right', fontSize: '14px'}}>{moment(row.date).format('MMM-DD-YYYY H:mm')}</div>
    </div>)
}

function renderPatientName(row) {
  const currentPatient = RequestCache.get('nurse/currentPatient')
  const patientName =row.patientName || _.get(row, 'user.profile.fullName') || _.get(currentPatient, 'profile.fullName')
  const patientId = _.get(row, 'user.identification.0.value') || _.get(currentPatient, 'identification.0.value')
  return <div style={styles.alertMsg.name}>{patientName} ({patientId})</div>
}