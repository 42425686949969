import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Switch } from 'antd';
import { compose } from 'react-apollo';

import OnCallComponent from '../component/OnCallComponent';

import I18N from '../../I18N';
import { ROLE_MAP } from '../../../lib/constants';
import { decryptRole } from '../../../lib/utils';
import { withCoveredMemberOptions } from '../API/coveredMembers';

const OnCallContainer = (props) => {
  const { loadingCoveredMemberOptions, coveredMemberOptions } = props;
  const [coveredMembers, setCoveredMembers] = useState(props.coveredMembers);
  const [coverAll, setCoverAll] = useState(props.coverAll);
  const [currentRole] = useState(ROLE_MAP[decryptRole()]);
  const I18NMap = I18N.get(`workStation.onCall.${currentRole}`) || {};

  const renderHeader = () => {
    const name = I18NMap.name || '--';
    return (
      <div style={{ fontSize: 20, lineHeight: '27.24px', marginBottom: 14, fontWeight: 600 }}>
        Please select the {name} for whom you will cover online work today
      </div>
    );
  }

  const renderSubHeader = () => {
    const todoCategory = I18NMap.todoCategory || [];
    return (
      <div style={{ marginBottom: 20, lineHeight: '24px' }}>
        Patients with the following items of selected {currentRole}s will be pushed to you in your workstation, and these workloads will be added up to your total patient cases and each to-do category{todoCategory.length ? ':' : '.'}
        <ul style={{ paddingTop: 8, paddingLeft: 20 }}>
          {_.map(todoCategory, (tdc, idx) => <li key={idx}>{tdc};</li>)}
        </ul>
      </div>
    );
  }

  return (
    <div>
      {renderHeader()}
      {renderSubHeader()}
      <div style={{ marginBottom: 50 }}>
        <OnCallComponent
          coveredMembers={coveredMembers}
          handleSetCoveredMembers={setCoveredMembers}
          loadingCoveredMemberOptions={loadingCoveredMemberOptions}
          coveredMemberOptions={coveredMemberOptions}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Switch
            checked={!!coverAll}
            onChange={setCoverAll}
            className="onCalleeSwitch"
          />
          {` ${I18NMap.coverAll}`}
        </div>
        <Button
          type='primary'
          className='onCallConfirmButton'
          onClick={() => props.onConfirm(coveredMembers, coverAll)}
          disabled={!coveredMembers.length || props.loadingUpdateCoveredMembers}
          loading={props.loadingUpdateCoveredMembers}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

OnCallContainer.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  coveredMembers: PropTypes.array
}
export default compose(
  withCoveredMemberOptions
)(OnCallContainer);