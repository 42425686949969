import gql from 'graphql-tag';

export default gql `
                    mutation rateFoodLog($id:EID!,$rating:FoodLogRatingInput!) {
                      rateFoodLog(
                        id:$id
                        rating: $rating
                      ) {
                        id
                        rating {
                          star
                          category {
                            carb
                            fat
                            protein
                            vegetable
                            fruit
                          }
                        }
                      }
                    }
                    `
