import React from 'react';
import { Col, Form } from 'antd';

const FormRenderer = ({ form, fields, disabled }) => {
  const { getFieldDecorator } = form;

  return (
    <React.Fragment>
      {_.map(fields, (field) => {
        let values = field;
        if (typeof field === 'function') {
          values = field(form);
        }
        const { label, key, render, col, className, formItemStyle, subText, ...config } = values;
        return (
          <Col key={key} {...col} className={className}>
            <Form.Item label={label || ' '} style={formItemStyle}>
              {subText && <div className="form-subtext">{subText}</div>}
              {getFieldDecorator(key, config)(render({ disabled }))}
            </Form.Item>
          </Col>
        );
      })}
    </React.Fragment>
  );
}

export default FormRenderer;
