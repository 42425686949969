import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { IHForm, IHButton, message, IHLoading, _ } from 'ihcomponent'
import deleteCaregiverForMember from 'graphqlModule/deleteCaregiver'
import editCaregiver from 'graphqlModule/mutation/editCaregiver'
import { getCareGiverFormProps } from 'patientModule/profileEdit/components/formData/PatientEditCareGiverForm'
import { GQLHelper, validateAntdForm, submittingStyle } from 'libModule/utils';
import { closeModal, openModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import actions from 'patientModule/profileEdit/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { goPath } from 'libModule/utils'

class Container extends Component {
  static displayName = 'patient/profileEdit/containers/PatientEditCareGiverContainer'

  init() {
    const { userData } = this.props;

    const caregivers = [];
    _.each(userData.caregiver, (careGiver, index) => {
      const n = index + 1;
      const { firstName, lastName } = _.get(careGiver, 'profile');
      const phones = _.find(_.get(careGiver, 'phones'), (item) => item.type === 'MOBILE');
      const mobile = phones ? phones.number : '';
      caregivers[index] = {};
      caregivers[index]['c_firstName_' + n] = firstName;
      caregivers[index]['c_lastName_' + n] = lastName;
      caregivers[index]['c_nric_' + n] = _.get(careGiver.identification[0], 'value');
      caregivers[index]['c_mobilePhone_' + n] = mobile;
      caregivers[index]['c_email_' + n] = _.get(careGiver.emails[0], 'address') || '';
      caregivers[index]['c_nationality_' + n] = _.get(careGiver.profile.nationality, 'code') || 'US';
      caregivers[index]['c_relationship_' + n] = _.get(careGiver.profile.relationship, 'code') || 'Child';
    });

    return caregivers;
  }

  render() {
    const body = this.renderBody();
    const footer = this.renderFooter();
    const deleteButton = this.renderDelete();

    return (
      <div className='vsm-form-container'>
        {body}
        {deleteButton}
        {footer}
      </div>
    )
  }

  renderBody() {
    const index = this.props.careGiverIndex;
    const caregivers = this.init();
    const caregiver = caregivers[index];
    if (typeof(caregiver) === 'undefined' || caregivers.length === 0) return;
    const caregiverProps = getCareGiverFormProps({ caregiver, index });
    const body = <IHForm {...caregiverProps} ref={refNode => { this.baseForm = refNode }} />;
    return body;
  }

  renderDelete() {
    const {careGiverIndex} = this.props;
    const deleteButton = (
      <div className="dlt-btn-wrapper">
        <IHButton
          label="Delete Caregiver"
          className="btn-delete"
          onClick={() => this.confirmDelete(careGiverIndex)}
        />
      </div>
    );
    return deleteButton;
  }

  confirmDelete(careGiverIndex) {
    const { closeModal, openModal } = this.props;
    const p1 = {
      type : 'primary',
      label : 'Confirm',
      size : 'large',
      onClick : () => {
        this.deleteCaregiver(careGiverIndex)
      }
    };
    const p2 = {
      label : 'Close',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : () => {
        closeModal();
      }
    };
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    );
    const param = {
      size : 'sm',
      title : 'Confirm Delete Caregiver',
      content : 'Are you sure you want to delete this Caregiver?',
      footer
    };
    openModal(param.content, param);
  }

  deleteCaregiver = async(careGiverIndex) => {
    const { openErrorModal, closeModal, userData, refetch } = this.props;
    const caregivers = userData.caregiver[careGiverIndex].id;
    const id = userData.id;
    const variables = { id, caregivers };
    const patientNRIC = _.get(userData, 'identification[0].value');
    const patientName = _.get(userData, 'profile.fullName');
    closeModal();
    try {
      const amute = this.props.deleteCaregiverForMember;
      const res = await amute({ variables });
      if (res.data) {
        createAuditLog({ action: I18N.get('auditLog.provider.patient.deleteCaregiver'), patientNRIC, patientName, details: variables, res });
        message.success('You have successfully deleted the Caregiver');
        refetch();
      }
    } catch (err) {
      createAuditLog({ action: I18N.get('auditLog.provider.patient.deleteCaregiver'), patientNRIC, patientName, details: variables, res: err, success: false });
      openErrorModal(GQLHelper.formatError(err));
    } finally {
      goPath(`/patients/profile/${id}`);
    }
  }

  renderFooter() {
    const { closeModal, isSubmitting } = this.props;
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 10
      }
    };

    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          closeModal()
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        type: 'submit',
        disabled: isSubmitting,
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    };

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    );
    return footer
  }

  getMutateVariable(data, careGiverIndex) {
    const index = careGiverIndex + 1;
    const options = {
      profile: {
        firstName: data[`c_firstName_${index}`],
        lastName: data[`c_lastName_${index}`],
        nationality: !!data[`c_nationality_${index}`] ? data[`c_nationality_${index}`] : 'US',
        relationship: !!data[`c_relationship_${index}`] ? data[`c_relationship_${index}`] : 'CHILD'
      },
      identification: {
        type: 'SP',
        value: data[`c_nric_${index}`]
      }
    };

    if (!!data[`c_mobilePhone_${index}`]) {
      options.phone = {
        countryCode : '1',
        number : data[`c_mobilePhone_${index}`],
        type : 'MOBILE'
      }
    }

    if (!!data[`c_email_${index}`]) {
      options.email = data[`c_email_${index}`]
    }

    return options
  }

  async submit() {
    const formRef = this.baseForm;
    const isFormValid = await validateAntdForm(formRef);
    if (!isFormValid) return;
    const { closeModal, openErrorModal, setSubmitting, careGiverIndex, userData, refetch } = this.props;
    const data = formRef.getValue();
    const id = userData.caregiver[careGiverIndex].id;
    const options = this.getMutateVariable(data, careGiverIndex);
    const variables = { id, options };

    const patientNRIC = _.get(userData, 'identification[0].value');
    const patientName = _.get(userData, 'profile.fullName');

    try {
      setSubmitting(true);
      const res = await this.props.editCaregiver({variables});
      if (res.data.editCaregiver) {
        createAuditLog({ action: I18N.get('auditLog.provider.patient.editCareGiver'), patientNRIC, patientName, details: variables, res });
        closeModal();
        message.success('You have successfully edited the Caregiver');
        refetch();
      }
    } catch(e) {
      createAuditLog({ action: I18N.get('auditLog.provider.patient.editCareGiver'), patientNRIC, patientName, details: variables, res: e, success: false });
      openErrorModal(GQLHelper.formatError(e));
    } finally {
      setSubmitting(false);
    }
  }
}

Container.propTypes = {
  userData: PropTypes.object,
  mutate: PropTypes.func,
  closeModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  isSubmitting: PropTypes.bool,
  careGiverIndex: PropTypes.number
};

const mapState = (state, ownProps) => {
  return {
    ...state.patient.profileEdit,
  }
};

const mapDispatch = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    openModal: (content, modalProps) => dispatch(openModal(content, modalProps)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
};

const ContainerWithData = GQLHelper.wrapper([
  graphql(deleteCaregiverForMember, { name : 'deleteCaregiverForMember'}),
  graphql(editCaregiver, { name : 'editCaregiver' })
], Container);

export default connect(mapState, mapDispatch)(ContainerWithData);
