import React, { useState, useCallback, forwardRef } from 'react';
import SelectQueryComponent from '../../../common/components/SelectQueryComponent';
import { iotDeviceConfigListWithSearch, SEARCH_FIELDS } from '../query/iotDeviceConfigList';

const SearchClearComponent = forwardRef((props, ref) => {
  const { 
    disabled, 
    handleOnSelect, 
    searchOptions = {},
    ...antdProps
  } = props;
  const [match, setMatch] = useState('');
  const [canSearch, setCanSearch] = useState(false);

  // used to prevent multiple API calls when typing
  const handleSearch = useCallback(_.debounce((matchKey) => {
    setMatch(matchKey);
    setCanSearch(true);
  }, 600), []);

  return (
    <SelectQueryComponent 
      queryOptions={{
        query: iotDeviceConfigListWithSearch,
        variables: {
          deviceStatusFilter: 'AVAILABLE',
          page: 1,
          count: 15,
          search: {
            fields: [SEARCH_FIELDS.DEVICE_ID],
            match,
          },
        },
        skip: !match || !canSearch,
        fetchPolicy: 'network-only',
      }}
      selectProps={{
        ref,
        ...(antdProps || {}),
        style: { width: 200 },
        notFoundContent: 'Device Number not Found',
        onSearch: (inputValue) => {
          if (canSearch) setCanSearch(false);
          handleSearch(inputValue);
        },
        onSelect: (deviceId) => {
          if (handleOnSelect) handleOnSelect(deviceId);
        },
        disabled,
        dropdownMatchSelectWidth: true,
        allowClear: true,
        autoFocus: true,
        ...searchOptions,
      }}
      processOptions={(data, OptionLayout) => {
        const iotDeviceConfigList = _.get(data, 'iotDeviceConfigList.data');
        const options =  _.map(iotDeviceConfigList, (device) => {
          const { id, deviceId } = device;
          return <OptionLayout key={id} value={deviceId} className="">
            {deviceId}
          </OptionLayout>;
        });

        return options;
    }}
    />
  )
});

export default SearchClearComponent;
