import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import createUser from 'graphqlModule/createUser'
// import { providerList as updateQueries } from 'graphqlModule/updateQueries/providerList'
import { IHBaseFormWithRow, IHButton, message, IHLoading, _ } from 'ihcomponent'
import PropTypes from 'prop-types';
import { getFormProps } from 'providerModule/ProviderNew/components/formData/ProviderNewForm'
import * as actions from 'providerModule/ProviderNew/ProviderNewDuck'
import { goPath, FormHelper, GQLHelper, setAsyncRouteLeaveHook, submittingStyle } from 'libModule/utils'
import { modalAction } from 'modulesAll/common/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { determineEmployeeOrMemberProfile } from '../others/_utils';
import i18nMap from '../../../I18N/index';

class Container extends Component {
  static displayName = 'provider/ProviderNew/containers/ProviderNewContainer'

  componentWillMount() {
    setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))
  }

  routerWillLeave() {
    const { openConfirmModal } = this.props
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      const Yes = {
        label: 'Confirm',
        handler: ()=>{
          resolve(true)
        },
        closeModal: true
      }
      const No = {
        label: 'Cancel',
        handler: () => {
          resolve(false)
        }
      }
      const modalProps = {
        Yes,
        No,
        title: 'Leave This Page?',
        content: 'Please confirm if you would like to leave this page'
      }
      openConfirmModal(modalProps)
    })
  }
  componentWillUnmount() {
    this.props.resetForm()
  }
  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-provider-new-page'>
        <div className='provider-new-form v-form-required'>
          {body}
          {footer}
        </div>
      </div>
    )
  }
  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    const formProps = getFormProps({ onFieldsChange, fieldsValue })
    return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }
  renderFooter() {
    const { isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick: (e)=>{
          goPath('/providers')
        },
        bsStyle: 'default',
        disabled: false,
        label: 'Cancel'
      },
      done: {
        onClick: (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        disabled: isSubmitting,
        type: 'submit',
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} >
          {isSubmitting ? <IHLoading /> : 'Create'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )

    return footer
  }
  getMutateVariable(data) {
    // const result = determineEmployeeOrMemberProfile(data);

    const phone = [];
    if (data.mobile) {
     phone.push({
       countryCode: data.mobileCountryCode ? parseInt(data.mobileCountryCode, 10) : '',
       number: data.mobile ? data.mobile.trim() : '',
       type: 'MOBILE'
     });
    }

    const appRoles = JSON.parse(sessionStorage.getItem('appRoles'));
    const roleOrg = appRoles[Object.keys(appRoles).find(key => key.toUpperCase().indexOf(":" + data.role.toUpperCase()) >= 0)];

    // const roleId = atob(roleOrg).split(':')[1];
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    // const organizationId = _.get(currentUser, 'role.organization.id') || ''

    const result = {
      role: roleOrg,
      email: data.email,
      phone,
      // identification: {
      //   type: 'SP',
      //   // type: data.identificationType,
      //   value: data.identificationValue
      // },
      employeeProfile: {
        department: data.department,
        employeeId: data.identificationValue,
        specialty: data.specialty
      },
      commonProfile: {
        firstName: data.firstName,
        lastName: data.lastName
      },
      username: data.username ? data.username : data.email
    };

    // const result = {
    //   organizationId,
    //   roleId: btoa(roleId),
    //   email: data.email,
    //   phone,
    //   identification: {
    //     type: 'SP',
    //     // type: data.identificationType,
    //     value: data.identificationValue
    //   },
    //   profile: {
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     department: data.department,
    //     employeeId: data.employeeId,
    //     specialty: data.specialty,
    //   },
    //   username: data.username
    // }

    return result;
  }

  async submit() {

    const { mutate, fieldsValue, handleFormSaved, resetForm, openSuccessModal, openErrorModal, setSubmitting } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }
    const variables = this.getMutateVariable(formValue)

    try{
      setSubmitting(true)
      await handleFormSaved()
      // const res = await mutate({ variables, updateQueries })
      const res = await mutate({ variables });

      createAuditLog({
        action: I18N.get('auditLog.admin.provider.create'),
        details: formValue,
        request: variables,
        response: res
      })

      if(res.data.createUser){
        resetForm()
        const modalTitle = 'Provider Registration Successful'
        const modalContent = `Provider has been created!`
        openSuccessModal(modalTitle, modalContent)

        goPath('/providers')
      }
    }catch(err){
        openErrorModal(GQLHelper.formatError(err))

        createAuditLog({
          action: I18N.get('auditLog.admin.provider.create'),
          details: formValue,
          request: variables,
          response: err,
          success: false
        })
    }finally {
        setSubmitting(false)
    }
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(createUser)(Container)

const mapState = ({ provider }, ownProps) => {
  return {
    ...provider.ProviderNew
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    resetForm: () => dispatch(actions.resetForm()),
    openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting))
  }
}

Container.propTypes = {
  onFieldsChange: PropTypes.func,
  resetForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  initData: PropTypes.object,
  route: PropTypes.object,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  isFieldsRequired: PropTypes.object,
  isFormValid: PropTypes.bool,
  isFormSaved: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  openSuccessModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  openConfirmModal: PropTypes.func
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
