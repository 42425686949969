import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import userGQL from 'graphqlModule/user'
import editUserSettingsGQL from 'graphqlModule/mutation/editUserSettings'
import UserClass from 'graphqlModule/class/User'
import { IHButton, IHForm, IHPanel, IHLoading, message, _ } from 'ihcomponent'
import { GQLHelper, createContainer, validateAntdForm, submittingStyle, goPath } from 'libModule/utils';
import { openErrorModal } from 'modulesAll/layout/actions/MainModal'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { getFormProps } from '../components/settingsComponent'
import actions from '../actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'patient/Settings/containers/settingsContainer'

  componentDidMount() {
    const { setBreadCrumb } = this.props
    setBreadCrumb(`Settings`)
  }

  init() {
    const { data } = this.props
    const userData = data.user

    if(userData && !data.loading) {
      const userClassData = new UserClass(userData)

      const initData = {
        viaEmail: userClassData.format('emailNotification'),
        viaSMS: userClassData.format('smsNotification')
      }

      return initData
    }

    return {}
  }

  render() {
    const body = this.renderBody()
    const p = {
      header: {
        title: 'Alerts / Reminders'
      },
      body,
      bodyStyle : {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: '40px',
      }
    }

    return (
      <div>
        <div style={{fontSize: 16, fontWeight: 600, margin:'40px 150px 10px 150px'}}>Settings</div>
        <div style={{padding: '40px 60px', margin: '0 150px 130px 150px', backgroundColor: 'white', border: '1px solid #c8c2bd', borderRadius: '4px'}}>
          <div style={{margin:'0 0 40px 0'}}>
            <IHPanel {...p} />
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    const initData = this.init()
    const formProps = getFormProps({ initData })

    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    const footer = this.renderFooter()

    return (
      <div className='vsm-form-container'>
        {body}
        {footer}
      </div>
    )
  }

  renderFooter() {
    const { isSubmitting } = this.props

    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 50,
        marginBottom: 20
      }
    }
    const buttonProps = {
      cancel: {
        onClick: (e)=>{
          goPath('/mytasks')
        },
        bsStyle: 'default',
        disabled: false,
        label: 'Cancel'
      },
      done: {
        onClick: (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        type: 'submit',
        disabled: isSubmitting,
        style: {
          marginRight: 10,
          width: '70px',
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  async submit() {
    const formRef = this.baseForm

    // validate all fields before submission
    const isFormValid = await validateAntdForm(formRef)
    if (!isFormValid) return

    const { openErrorModal, setSubmitting } = this.props
    const fieldsValue = formRef.getValue()
    const { viaEmail, viaSMS } = fieldsValue

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const patientNRIC = _.get(currentUser, 'identification[0].value')
    const patientName = _.get(currentUser, 'profile.fullName')

    const notifications = []
    if(viaEmail === 'viaEmailYes') notifications.push('EMAIL')
    if(viaSMS === 'viaSMSYes') notifications.push('SMS')

    const variables = {
      userSettings: {
        preferredUnit: 'IMPERIAL',
        notifications
      },
    }

    try {
        setSubmitting(true)
        const res = await this.props.editUserSettingsGQL({ variables })
        createAuditLog({
          action: I18N.get('auditLog.patient.setting.editSetting'),
          patientNRIC,
          patientName,
          details: { notificationSettings: notifications },
          request: variables,
          response: res
        })
        if(res.data.editUserSettings){
            message.success('Notification settings saved')
        }
    } catch(e){
        createAuditLog({
          action: I18N.get('auditLog.patient.setting.editSetting'),
          patientNRIC,
          patientName,
          details: { notificationSettings: notifications },
          request: variables,
          response: e,
          success: false
        })
        openErrorModal(GQLHelper.formatError(e))
    } finally {
        setSubmitting(false)
    }
  }
}

const mapState = (state, ownProps) => {
  return {
    ...state.patient.profileEdit,
    ...ownProps,
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting)),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
  }
}

Container.propTypes = {
  editUserSettingsGQL: PropTypes.func,
  setSubmitting: PropTypes.func,
  setBreadCrumb: PropTypes.func,
  isSubmitting: PropTypes.bool,
  data: PropTypes.object
}

export default createContainer(GQLHelper.wrapper([
  graphql(userGQL, {
    options: ()=>{
      const id = sessionStorage.getItem('currentUserId')
      return {
        variables: {
          id
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      }
    },
    props : ({ownProps, data})=>{
      return {
        data
      }
    }
  }),
  graphql(editUserSettingsGQL, {name : 'editUserSettingsGQL'})
], Container), mapState, mapDispatch)
