import gql from 'graphql-tag';
import { UserMini } from './schema/User';
import { Phone, Email, Identification } from './schema/common';

export default gql`
  query teamList($page:Int, $count:Int, $filters:TeamListOpts, $sort:TeamListSort, $search:TeamSearchInput){
    teamList(page:$page, count:$count, filters:$filters, sort:$sort, search:$search){
      data{
        id
        createdAt
        updatedAt
        modifiedAt
        consentProviderName
        name
        description
        phone {
          ${Phone}
        }
        email {
          ${Email}
        }
        healthCondition
        contactName
        members{
          role {
            refId
            name
            startFrom
            category
            organization {
              id
              name
            }
          }
          primary
          visible
          user{
            ${UserMini}
            phone {
              ${Phone}
            }
            email {
              ${Email}
            }
          }
        }
      }
      pageInfo{
        total
        lastPage
      }
    }
  }
`;


export const updatedTeamListQuery = gql`
query teamList($page:Int, $count:Int, $filters:TeamListOpts, $sort:TeamListSort, $search:TeamSearchInput){
  teamList(page:$page, count:$count, filters:$filters, sort:$sort, search:$search){
    data{
      id
      createdAt
      updatedAt
      modifiedAt
      consentProviderName
      name
      description
      phone {
        ${Phone}
      }
      email {
        ${Email}
      }
      healthCondition
      contactName
      members{
        currentRole {
          name
          startFrom
        }
        primary
        visible
        user{
          id
          profile {
            fullName
            fullNameWithNickname
            avatar {
              fileKey
              link
              thumbnailLink
            }
            firstName
            lastName
          }
          identification {
            ${Identification}
          }
          phone {
            ${Phone}
          }
          email {
            ${Email}
          }
        }
      }
    }
    pageInfo{
      total
      lastPage
    }
  }
}
`;