import gql from 'graphql-tag'

export default gql`query userPhoneList($userId:String!){
  userPhoneList(userId:$userId,sort:LASTUSED_DESC,limit:99999
){
    phoneId
    phoneName
    phoneModel
    phoneModel
    serverUrl
    appVersion
    appScheme
    phoneOSVersion
    firstUsed
    lastUsed
  }
}`
