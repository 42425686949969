import {
    React
} from 'ihcomponent'
import TopBar from '../components/TopBar'
import {createContainer} from 'libModule/utils'
import ImportPatientInfo from './ImportPatientInfo'
import AddNewPatient from './AddNewPatient'
import SearchPatientResultModal from './SearchPatientResultModal'

export default createContainer((props)=>{

  // setPagePath('Patients / Import Patient Info')

  const flag = props.flag
  let body
  if(flag === 'no'){
    body = <AddNewPatient />
  }
  else{
    body = <ImportPatientInfo />
  }
    return (
      <div>
        <TopBar active={1} />
          <div className="vsm-main-page">
            <div className="vsm-main">
              {body}
            </div>
          </div>
          <SearchPatientResultModal />
      </div>

    )
}, (state, ownProps)=>{
  return {
    flag : ownProps.location.query.import
  }
})
