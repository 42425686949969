import gql from 'graphql-tag'

export default gql`
 query resultList($filters:ResultListOpts!){
  resultList(filters:$filters){
    data{
      date
      ...on BGResult {
        blood_glucose
        mealType
        beforeMeal
        severity
      }
    }
  }
}
`
