import ImportPatientInfo from './ImportPatientInfo'
import SearchPatientResultModal from './SearchPatientResultModal'
import AddNewPatient from './AddNewPatient'
import { combineReducers } from 'redux'

export default combineReducers({
    ImportPatientInfo,
    SearchPatientResultModal,
    AddNewPatient
})
