import gql from 'graphql-tag';

export default gql`
  query auditTrailList(
    $filters: AuditListFilters,
    $sort: AuditListSort,
    $page: Int,
    $count: Int
  ) {
    auditTrailList(
      filters: $filters,
      sort: $sort,
      page: $page,
      count: $count
    ) {
      data {
        id
        resource
        action
        createdAt
        status
        username
        ip
        docId
        viewer {
          id
          profile {
            fullName
          }
        }
      }
      pageInfo {
        total
        lastPage
      }
    }
  }
 `