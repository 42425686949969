import React from "react";
import AddNewDevice from './addNewDevice';
import RenderAndUpdateDevice from '../component/renderAndUpdateExisting';
import { Card } from 'antd';
class HostAndBPMComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          reverseToAsc: false,
        };
    }

    setReverseToAsc = reverseToAsc => this.setState({ reverseToAsc });

    renderIfNoDevice(){
        return <AddNewDevice {...this.props} reverseToAsc={this.state.reverseToAsc} setReverseToAsc={this.setReverseToAsc}/>
    }

    renderWithDevice(){
        return <RenderAndUpdateDevice {...this.props}/>
    }

    render(){
        const { forModal, iotDeviceList, setAssignment, isAssigned } = this.props;
        if(iotDeviceList.length == 0) {
            return forModal ? this.renderIfNoDevice() : <Card>{ this.renderIfNoDevice() }</Card>
        }

        // if(forModal && setAssignment && !isAssigned) {
        //     setAssignment(true);
        // }

        return forModal ?
            this.renderWithDevice()
        :
            <Card style={{ borderTop:'unset !important' }} className='deviceCard'>
                {this.renderWithDevice()}
            </Card>
    }
}

export  default HostAndBPMComponent;
