import React from 'react';
import _ from "lodash";
import {getPatientInitial} from "../helper";
import {Popover, Tooltip} from "antd";

const PinnedByListComponent = class extends React.Component{
    render() {
        const { pinnedBy,index } = this.props;
        const users = _.map(pinnedBy,n=>({ fullName:n }))
        const slicedUsers = users.slice(0,3);
        let extraIcon = null;
        let img =(u)=> <span className={'pinnedPatientAvatar circled'}>{getPatientInitial(u.fullName)}</span>;
        const usersList  = _.map(slicedUsers,(u,i)=><Tooltip key={i} title={u.fullName}>{img(u)}</Tooltip>);
        if(users.length>3) {
            let extraUsers = users.slice(3,users.length);
            const content = (
                <div className={'patientsPinnedBy'}>
                    { _.map(extraUsers,(u,i)=><div key={i} className={'patientRow'}><span>{u.fullName}</span></div>)}
                </div>
            );
            extraIcon = <Popover overlayClassName='extraIconPopover' content={content} key={index} overlayStyle={{width:'fit-content !important'}}>
                <div className={'indicator circled'}>{`+${users.length-3}`}</div>
            </Popover>
        };
        return  <div className={'patientsPinnedBy'} style={{flexDirection:'row'}}>
            { usersList }
            { extraIcon }
        </div>
    }
}

export default PinnedByListComponent;
