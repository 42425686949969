import types from 'ProgramEditModule/SurveyAlert/constants/AlertListConstants'
import formTypes from 'ProgramEditModule/SurveyAlert/constants/AlertFormConstants'
import cloneDeep from 'lodash/cloneDeep'

// Default State
const initState = {
  panes: [],
  activeKey: '',
  isShowAlertForm: false,
  panesFieldsValue: {}
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.RESET:
    case types.RESET_FORM:
      return initState
    case types.INIT_DATA:
      return {
        ...state,
        ...action.initFormData
      }
    case types.TOGGLE_ALERT_FORM:
      return {
        ...state,
        isShowAlertForm: action.isShowAlertForm
      }
    case formTypes.FORM_FIELD_CHANGE: {
      const activeKey = state.activeKey
      const currentPaneFieldsValue = cloneDeep(state.panesFieldsValue[activeKey])
      const newFieldsValue = {
        [activeKey]: {
          ...currentPaneFieldsValue,
          ...cloneDeep(action.fieldsValue)
        }
      }
      return {
        ...state,
        panesFieldsValue: {
          ...state.panesFieldsValue,
          ...newFieldsValue
        },
      }
    }
    case types.ON_TAB_CHANGE:
      return {
        ...state,
        activeKey: action.activeKey
      }
    case types.ADD_TAB:
      return {
        ...state,
        panes: action.panes,
        activeKey: action.activeKey
      }
    case types.REMOVE_TAB:
      let activeKey = state.activeKey
      let isShowAlertForm = state.isShowAlertForm
      let lastIndex
      const currentPanes = state.panes
      const panes = currentPanes.filter(pane => pane.key !== activeKey)
      // no tab, reset state
      if (currentPanes.length <= 1) {
        activeKey = undefined
        isShowAlertForm = false
      } else {
        currentPanes.forEach((pane, i) => {
          if (pane.key === activeKey) {
            // tab maybe the first one
            lastIndex = i < 1 ? 0 : i - 1
            activeKey = panes[lastIndex].key
          }
        })
      }
      const panesFieldsValue = cloneDeep(state.panesFieldsValue)
      delete panesFieldsValue[state.activeKey]
      return {
        ...state,
        activeKey,
        panes,
        isShowAlertForm,
        panesFieldsValue
      }
    default:
      return state
  }
}
