import types from 'ProgramIncompleteModule/Careplan/constants/VitalFormConstants'
import { commonReducer } from 'libModule/helpers/reducer-helpers'

const initState = {
  isShowAlertForm: false,
  isEditMode: false
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.RESET_FORM:
      return initState
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
