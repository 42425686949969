import React, { Component } from 'react';
import {Col } from 'antd';
import PatientSmartAlertContainer from 'modulesAll/smartAlert/container/PatientSmartAlertTasksContainer';

class SmartAlertComponent extends Component {
    static displayName = 'main/containers/SmartAlert';

    constructor(props){
        super(props);
        this.state = {
            showMore: false,
        }
    }

    renderSmartAlertTags(colOffset) {
        const {
          smartAlertsList, loadingSmartAlertsList, patientId, data, currentProgramId, patientAlertConfig, onInteraction
        }  = this.props;


        this.isUnenrolledPatient = !_.startsWith(this.props.enrollmentStatus, 'Enrolled');

        const identification = _.get(data,'user.identification[0].value');
        const fullName = _.get(data,'user.profile.fullName');

        let filteredSmartAlertList = [...smartAlertsList];
        const patientConfigs = _.get(patientAlertConfig, 'configs', []);
        const validSnoozeConfigs = _.filter(patientConfigs, c => new Date() < c.endDate && c.isValid);
        const hasSnooze = !!validSnoozeConfigs.length;
        if(hasSnooze) {
          filteredSmartAlertList = _.filter(filteredSmartAlertList, a => {
            // hide smart alerts when there is snooze config
            return !_.find(validSnoozeConfigs, { taskType: a.taskType });
          });
        }

        return !this.isUnenrolledPatient && <Col span={24} className='v-smartalert-wrapper'>
          <Col offset={colOffset} style={{ display: 'flex', alignItems: 'baseline' }}>
            <span className='v-smartalert-title'>Smart Alerts</span>
            {
              loadingSmartAlertsList ?
              ''
              :
              (
                _.get(filteredSmartAlertList, 'length') || hasSnooze
                ?
                <PatientSmartAlertContainer
                  smartAlertsList={filteredSmartAlertList}
                  patientAlertConfig={patientAlertConfig}
                  currentProgramId={currentProgramId}
                  memberId={patientId}
                  identification={identification}
                  fullName={fullName}
                  onInteraction={onInteraction}
                />
                :
                <span style={{ display: 'flex', alignItems: 'end', marginTop: 15 }}>
                  This patient does not have any alerts at this time.
                  <img src='/image/Thumbup.svg' style={{ width: 20, marginLeft: 8 }} />
                </span>
              )
            }
          </Col>
        </Col>;
    }

    render(){
        return <div>
            {this.renderSmartAlertTags(1)}
        </div>
    }
}



export default SmartAlertComponent;