import React from 'react';
import { Steps, Row, Col, Modal, Button, Tooltip, Checkbox, Icon, Spin, message, Avatar } from 'antd';
import { VISIT_WORKLIST_MAP, STEPS } from '../constant/stepsMap';
import '../../visitWorkList/style/index.scss';
import LabResInWorkListContainer from '../container/LabResInWorkListContainer';
const { Step } = Steps;
// import MedicationAddAndListContainer from '../container/MedicationAddAndListContainer';
import VitalsAndBillingComponent from '../component/VitalsAndBillingComponent';
import FollowUpVisitComponent from '../component/FollowUpVisitComponent';
import OnBoardContainer from '../container/OnBoardContainer';
// import NutritionComponent from '../component/NutritionComponent';
import GoalStatement from 'modulesAll/careplanNew/container/GoalStatement';
import ChartingComponent from '../../careplanNew/component/ChartingComponent';
// import EnrollmentStatusComponent from '../../careplanNew/component/EnrollmentStatusComponent';
import NutritionIntakeComponent from '../../careplanNew/container/NutritionIntake';
import NutritionInterpretationComponent from '../../careplanNew/container/NutritionInterpretation';
import EducationComponent from "./EducationComponent";
import { withRouter } from 'react-router';
import Client from 'libModule/gqlClient';
import { getUserRole } from 'libModule/utils/common';
import updateVisitWorkingList from '../../graphql/mutation/updateVisitWorkingList';
import singleVisit from '../../graphql/singleVisit';
import visitMap from '../constant/visitTypeMap';
import AllTaskFinishedComponent from '../component/AllTaskFinishedComponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import ChronicHistoryComponent from 'modulesAll/careplanNew/component/ChronicHistoryComponent';
import LifestyleRoutineComponent from '../../careplanNew/component/lifestyleRoutineComponent';
import SocialSupportComponent from '../../careplanNew/component/SocialSupportComponent';
import MedicationContainer from '../../careplanNew/container/MedicationContainer';
import moment from 'moment';
import VISIT_TYPE_ENUM from '../../visit/constants/map';
import ConsentFormMiniContainer from '../../patient/main/containers/ConsentFormMiniContainer';
import { VITALS_ORDER } from '../../../lib/constants';
import { updateGoalUponICDCode } from '../../careplanNew/component/helper/goalHelper';
import { openConfirmModal } from '../helpers/billableCodeHelper';
import SelfEvaluationComponent from "./SelfEvaluationComponent";
import VitalsSummaryContainer from '../container/VitalsSummaryContainer';
import PatientPreferencesContainer from '../container/PatientPreferencesContainer';
import NoConsentOverlay from './NoConsentOverlay';
import visitAPI from '../../VideoChat/helper';
import { BillableReviewFormPopup } from './BillableReviewForm';
import { getTimeDiff } from '../../visitsPage/helpers/visitDateHelpers';
import { VISIT_STATUS_MAPPING } from '../../visitsPage/constants';
import MNTReferralComponent from './MNTReferralComponent';
import AssignCTInVisitWorkList from '../../assignCT/component/AssignCTInVisitWorkList';
import I18N from '../../I18N';
import confirmUnsavedArticles from '../../careplanNew/helpers/confirmUnsavedArticles';

const stepsToConfirmUnsavedArticles = _.map([STEPS.nutritionInterpretation, STEPS.education], 'key');

const TOAST_DURATION = 2;
const LoadingIndicator = () => {
  return <div style={{ width: 1280, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin size="large" /></div>;
};

const renderMap = (localThis, consentInfo) => {
  const {
    props,
    state,
    setChildProps,
    onSubmit,
    onTouch,
    onSaveDraft,
    getSubmitButtonText,
    isKeyDisabled,
    setBillableVitals
  } = localThis;
  const { openErrorModal } = props;
  const { isEditMode, childProps } = state;
  const user = _.get(props, 'visit.member', _.get(props, 'member'));
  const userId = _.get(user, 'id');
  let parsedId = atob(userId).split(':')[1];
  const enrolledProgram = _.get(props, 'visit.enrolledProgram');
  const enrolledProgramId = _.get(enrolledProgram, 'id');

  return {
    mntReferral: (
      <MNTReferralComponent
        memberId={userId}
        visitId={_.get(props, 'visit.id')}
        visitType={_.get(props, 'visit.type')}
        patientMntProgram={_.get(user, 'patientMntProgram')}
        patientMntCodes={_.get(user, 'patientMntCodes', [])}
        afterSubmitSucc={async () => {
          await props.refetch();
          await onSubmit();
        }}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('mntReferral')}
        submitButtonText={getSubmitButtonText('mntReferral')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('mntReferral')}
      />
    ),
    onboarding: (
      <OnBoardContainer
        user={user}
        userId={userId}
        childProps={childProps}
        setChildProps={setChildProps}
        {...props}
        title={state.selectedStepKey === 'onboarding' && localThis.selectedStepName}
        currentProgram={enrolledProgram}
        isEditMode={isEditMode}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('onboarding')}
        submitButtonText={getSubmitButtonText('onboarding')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('onboarding')}
        isWorkList
      />
    ),
    vitalsAndBilling: (
      <VitalsAndBillingComponent
        user={user}
        userId={userId}
        visitType={_.get(props, 'visit.type')}
        {...props}
        title={localThis.selectedStepName}
        isEditMode={isEditMode}
        setChildProps={setChildProps}
        program={enrolledProgram}
        enrolledProgramId={enrolledProgramId}
        childProps={childProps}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('vitalsAndBilling')}
        submitButtonText={getSubmitButtonText('vitalsAndBilling')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('vitalsAndBilling')}
        setBillableVitals={setBillableVitals}
        consentInfo={consentInfo}
      />
    ),
    medicationMgmt: (
      <MedicationContainer
        user={user}
        patientId={userId}
        {...props}
        title={localThis.selectedStepName}
        isEditMode={isEditMode}
        setChildProps={setChildProps}
        workList={true}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('medicationMgmt')}
        enrolledProgramId={enrolledProgramId}
        submitButtonText={getSubmitButtonText('medicationMgmt')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('medicationMgmt')}
      />
    ),
    labResults: (
      <LabResInWorkListContainer
        routeParams={{ userId: parsedId }}
        {...props}
        title={localThis.selectedStepName}
        router={{ goBack: () => { }, setRouteLeaveHook: () => { } }}
        hideAddButton={true}
        userId={parsedId}
        afterSubmitSucc={onSubmit}
        submitButtonText={getSubmitButtonText('labResults')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('labResults')}
      />
    ),
    followUp: (
      <FollowUpVisitComponent
        user={user}
        userId={userId}
        {...props}
        title={localThis.selectedStepName}
        isEditMode={isEditMode}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('followUp')}
        submitButtonText={getSubmitButtonText('followUp')}
        enrolledProgramId={enrolledProgramId}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('followUp')}
      />
    ),
    diseaseHistory: (
      <ChronicHistoryComponent
        user={user}
        setShowSelfEvaluation={localThis.setShowSelfEvaluation}
        showSelfEvaluation={state.showSelfEvaluation}
        program={enrolledProgram}
        isEditMode={isEditMode}
        isWorkList={true}
        {...props}
        title={localThis.selectedStepName}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('diseaseHistory')}
        setChildProps={setChildProps}
        enrolledProgramId={enrolledProgramId}
        submitButtonText={getSubmitButtonText('diseaseHistory')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('diseaseHistory')}
      />
    ),
    socialSupport: (
      <SocialSupportComponent
        user={user}
        isEditMode={isEditMode}
        setChildProps={setChildProps}
        childProps={childProps}
        isWorkList={true}
        {...props}
        title={localThis.selectedStepName}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('socialSupport')}
        enrolledProgramId={enrolledProgramId}
        submitButtonText={getSubmitButtonText('socialSupport')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('socialSupport')}
      />
    ),
    lifestyle: (
      <LifestyleRoutineComponent
        user={user}
        isEditMode={isEditMode}
        setChildProps={setChildProps}
        childProps={childProps}
        isWorkList={true}
        {...props}
        title={localThis.selectedStepName}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('lifestyle')}
        enrolledProgramId={enrolledProgramId}
        submitButtonText={getSubmitButtonText('lifestyle')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('lifestyle')}
      />
    ),
    nutritionIntake: (
      <NutritionIntakeComponent
        {...props}
        user={user}
        visit={_.get(props, 'visit')}
        isEditMode={isEditMode}
        isWorkList
        title={localThis.selectedStepName}
        enrolledProgramId={enrolledProgramId}
        onTouch={onTouch('nutritionIntake')}
        setChildProps={setChildProps}
        submitButtonText={getSubmitButtonText('nutritionIntake')}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('nutritionIntake')}
      />
    ),
    nutritionInterpretation: (
      <NutritionInterpretationComponent
        {...props}
        user={user}
        visit={_.get(props, 'visit')}
        isEditMode={isEditMode}
        isWorkList
        title={localThis.selectedStepName}
        isFollowUpVisit={_.get(props, 'visit.type') === 'FOLLOW_UP'}
        onTouch={onTouch('nutritionInterpretation')}
        enrolledProgramId={enrolledProgramId}
        setChildProps={setChildProps}
        submitButtonText={getSubmitButtonText('nutritionInterpretation')}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('nutritionInterpretation')}
        onInteraction={props.timerClaim.interacted}
      />
    ),
    goals: (
      <GoalStatement
        {...props}
        isWorkList={true}
        showDiabetesNote={_.get(props, 'visit.type') !== VISIT_TYPE_ENUM.MNT}
        user={user}
        title={localThis.selectedStepName}
        program={enrolledProgram}
        enrolledProgramId={enrolledProgramId}
        isEditMode={isEditMode}
        setChildProps={setChildProps}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('goals')}
        submitButtonText={getSubmitButtonText('goals')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('goals')}
        setBillableVitals={setBillableVitals}
        childProps={childProps}
      />
    ),
    // 12: (
    //   <EnrollmentStatusComponent
    //     user={user}
    //     userId={userId}
    //     {...props}
    //     isEditMode={isEditMode}
    //     // setIsEditMode={setIsEditMode}
    //     childProps={childProps}
    //     setChildProps={setChildProps}
    //     enrolledProgramId={enrolledProgramId}
    //     afterSubmitSucc={onSubmit}
    //     afterSaveDraftSucc={onSaveDraft}
    //     onTouch={onTouch(12)}
    //     submitButtonText={getSubmitButtonText('Enrollment Status')}
    //     openErrorModal={openErrorModal}
    //   />
    // ),
    charting: (
      <ChartingComponent
        user={user}
        userId={userId}
        {...props}
        title={localThis.selectedStepName}
        isEditMode={isEditMode}
        enrolledProgramId={enrolledProgramId}
        childProps={childProps}
        setChildProps={setChildProps}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        onTouch={onTouch('charting')}
        submitButtonText={getSubmitButtonText('charting')}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('charting')}
        consentInfo={consentInfo}
      />
    ),
    education: (
      <EducationComponent
        {...props}
        user={user}
        visit={_.get(props, 'visit')}
        isEditMode={isEditMode}
        isWorkList
        title={localThis.selectedStepName}
        onTouch={onTouch('education')}
        enrolledProgramId={enrolledProgramId}
        setChildProps={setChildProps}
        submitButtonText={getSubmitButtonText('education')}
        afterSubmitSucc={onSubmit}
        afterSaveDraftSucc={onSaveDraft}
        openErrorModal={openErrorModal}
        disabled={isKeyDisabled('education')}
        onInteraction={props.timerClaim.interacted}
      />
    ),
  };
};
let WorkListComponent = class extends React.Component {
  constructor(props) {
    super(props);
    const selectedStepKey = this.getFirstIncompleteStep();
    const selectedStepInfo = selectedStepKey !== Infinity ? this.getStepInfoWithKey(selectedStepKey) : undefined;

    this.state = {
      selectedStepKey,
      selectedStepInfo,
      isCompleteOnOpen: selectedStepKey === Infinity,
      isDone: false,
      showLeaveModal: false,
      goToStep: null,
      isEditMode: false,
      childProps: false,
      parseValue: null,
      query: null,
      refetchQueries: null,
      draftType: null,
      showSelfEvaluation: false,
      updatedComponents: {},
      signOffStatus: _.get(props, 'visit.signOffStatus'),
      enrolledVitals: _.map(_.get(props, 'visit.enrolledProgram.tasks'), task => task.type),
      billableVitals: _.get(props, 'billableVitals'),
      showVitalsSummary: { isVisible: false, mounted: false },
      userRole: getUserRole(),
      saveDraftErrors: { network: 0, authorization: 0, unknown: 0 }
    };
  }

  get visitSteps() {
    return VISIT_WORKLIST_MAP[this.props.type].steps;
  }

  get visitInfo() {
    return this.props.visit;
  }

  get selectedStepName() {
    return _.get(this.state, 'selectedStepInfo.step.title');
  }

  setShowSelfEvaluation = (showSelfEvaluation) => {
    this.setState({
      showSelfEvaluation
    })
  }

  setShowVitalsSummary = (isVisible = true) => {
    this.setState({ showVitalsSummary: { isVisible, mounted: true } });
  };

  static getDerivedStateFromProps = (nextProps, state) => {
    const { visit, billableLoading, billableVitals } = nextProps;
    const newState = { ...state };
    const prevSignOff = _.get(state, 'signOffStatus');
    const newSignOff = _.get(visit, 'signOffStatus');
    const hasSignOffChange = !_.isEqual(prevSignOff, newSignOff);
    if (hasSignOffChange) {
      newState.hasNewSignOff = true;
      newState.signOffStatus = newSignOff;
    }
    const prevBillableVitals = _.get(state, 'billableVitals');
    const newBillableVitals = billableVitals;
    if (state.billableLoading && !billableLoading && !_.isEqual(prevBillableVitals, newBillableVitals)) {
      newState.billableVitals = newBillableVitals;
    }
    newState.billableLoading = billableLoading;
    return newState;
  }

  getStepWithKey = (key) => {
    const index = _.findIndex(this.visitSteps, s => s.step.key === key);
    return index > -1 ? { stepInfo: this.visitSteps[index], index } : null;
  }

  getStepInfoWithKey = (key) => {
    const step = this.getStepWithKey(key);
    return step ? step.stepInfo : null;
  }

  isKeyDisabled = (key) => {
    const step = this.getStepInfoWithKey(key);
    return step ? step.disabled : false;
  }

  getFirstIncompleteStep = (stepKey) => {
    const workListStatuses = this.getVisitListsStatuses();
    let fixedSteps = [...this.visitSteps];

    if (stepKey) {
      const currIndex = this.getStepWithKey(stepKey).index;
      const steps = [...this.visitSteps];
      fixedSteps = steps.splice(currIndex).concat(steps);
    }

    for (let stepObj of fixedSteps) {
      const status = _.get(workListStatuses, stepObj.step.statusPath);
      if (status !== 'COMPLETED' && !stepObj.disabled && (stepObj.step.show === undefined || stepObj.step.show(this.visitInfo))) {
        return stepObj.step.key;
      }
    }
    return Infinity;
  }

  onTouch = (stepKey) => () => {
    if (!this.state.isEditMode || this.state.isDone) {
      this.setState({ isEditMode: true, isDone: false });
    }
    this.updateCurrentStepStatus(stepKey, 'TODO');
  }

  setIsEditMode = flag => {
    if (!flag) {
      Mixpanel.track('clicked', 'done', this.state.currentStep + ' step', '');
    }
    this.setState({
      isEditMode: flag
    });
  };

  checkAllTaskFinishedNew = () => {
    const selectedStepKey = this.getFirstIncompleteStep(this.state.selectedStepKey);
    const selectedStepInfo = selectedStepKey !== Infinity ? this.getStepInfoWithKey(selectedStepKey) : null;
    const isDone = selectedStepKey === Infinity;
    this.setState({
      selectedStepKey,
      selectedStepInfo,
      isDone,
    });
  };

  getVisitListsStatuses = () => {
    const statusMap = {};
    const workingList = _.get(this, 'props.visit', {});
    const steps = this.visitSteps.filter(s => !s.disabled);

    for (let stepObj of steps) {
      let status = _.get(workingList, stepObj.step.statusPath, 'TODO') || 'TODO';
      if (stepObj.step.show !== undefined && !stepObj.step.show(this.visitInfo)) {
        status = 'COMPLETED';
      }
      _.set(statusMap, stepObj.step.statusPath, status);
    }

    return statusMap;
  }

  checkBillableVitals = (selectedStepKey) => {
    const isLastComponent = this.checkIsLastComponent(selectedStepKey);
    if (isLastComponent) {
      const { billableVitals, enrolledVitals } = this.state;
      const unsetBillable = _.filter(enrolledVitals, v => !_.includes(billableVitals, v) && v !== 'EXERCISE');

      if (_.isEmpty(unsetBillable)) {
        return this.onConfirm();
      } else {
        const msg = _.join(unsetBillable, ', ');
        return openConfirmModal(this, msg);
      }
    } else {
      return this.onConfirm();
    }
  }

  onConfirm = () => {
    this.checkAllTaskFinishedNew();
    this.resetAfterSaveDraft();
  }

  checkIsLastComponent = (stepKey) => {
    const workListStatuses = this.getVisitListsStatuses();
    let fixedSteps = [...this.visitSteps];

    for (let stepObj of fixedSteps) {
      const status = _.get(workListStatuses, stepObj.step.statusPath);
      const key = stepObj.step.key;
      if (key !== stepKey && status === 'TODO') {
        return false;
      }
    }
    return true;
  }

  updateCurrentStepStatus = (stepKey, status) => {
    const id = _.get(this, 'props.visit.id');
    const statuses = this.getVisitListsStatuses();
    const variables = { id, ...statuses };
    const statusPath = _.get(this.getStepWithKey(stepKey), 'stepInfo.step.statusPath');

    if (_.get(variables, statusPath) === status) {
      return Promise.resolve();
    }

    _.set(variables, statusPath, status);

    return Client.mutate({
      mutation: updateVisitWorkingList,
      variables,
      // don't put no-cache here. It will not show AllTaskFinishedComponent
      refetchQueries: [
        {
          query: singleVisit,
          variables: {
            id
          },
          awaitRefetchQueries: true,
          fetchPolicy: 'network-only'
        }
      ]
    });
  }

  componentUpdated = (key) => {
    const name = _.get(this.getStepInfoWithKey(key), 'step.title');
    this.setState({ updatedComponents: { ...this.state.updatedComponents, [name]: true } })
  }

  onSubmit = () => {
    const { selectedStepKey } = this.state;
    return this.updateCurrentStepStatus(selectedStepKey, 'COMPLETED')
      .then(res => {
        message.success(`${this.selectedStepName} is Saved`, TOAST_DURATION);
        this.props.timerClaim.interacted('Charting', 'Completed ' + this.selectedStepName);
        this.componentUpdated(selectedStepKey);
        this.checkBillableVitals(selectedStepKey);
      })
      .catch(e => { Modal.error({ content: e.message }); });
  }

  onSaveDraft = () => {
    const { selectedStepKey } = this.state;
    this.setState({ showDraftSaved: true });
    // message.success(`${this.selectedStepName} draft is Saved`, TOAST_DURATION);
    this.props.timerClaim.interacted('Charting', 'Saved ' + this.selectedStepName);
    this.resetAfterSaveDraft();
    this.updateCurrentStepStatus(selectedStepKey, 'TODO')
      .catch(e => { Modal.error({ content: e.message }); })
  }

  resetAfterSaveDraft = () => {
    setTimeout(() => this.setState({
      isEditMode: false,
      childProps: false,
      parseValue: null,
      query: null,
      refetchQueries: null,
      draftType: null,
      showDraftSaved: false
    }), 500);
  };

  saveDraft = async ({ selectedStepKey, onErrorOk }) => {
    const {
      childProps,
      query,
      refetchQueries = [],
      parseValue,
    } = this.state;

    if (selectedStepKey === 'vitalsAndBilling' && _.get(this.props, 'visit.enrolledProgram.id')) {
      await updateGoalUponICDCode(childProps)
        .catch(e => {
          console.error(e);
          this.props.openErrorModal(`Goals are not updated: ${e.message}`);
        });
    }

    try {
      const variables = await parseValue(childProps);
      return await Client.mutate({
        mutation: query,
        variables,
        fetchPolicy: 'no-cache',
        refetchQueries
      })
        .then(() => {
          // message.success(`${draftType} draft is saved.`, TOAST_DURATION);
          this.props.timerClaim.interacted('Charting', 'Completed ' + this.selectedStepName);
          this.updateCurrentStepStatus(selectedStepKey, 'TODO');
          this.setState({ showDraftSaved: true });
        })
        .catch((e) => this.handleSaveDraftError(e, onErrorOk));
    } catch (e) {
      return this.handleSaveDraftError(e, onErrorOk);
    }
  };

  handleSaveDraftError = (error, onOk) => {
    const saveDraftErrors = { ...this.state.saveDraftErrors };

    let errorMsg = _.get(error, 'message') || '';

    if (new RegExp(/.*(not authorized|not a valid manager).*/).test(_.toLower(errorMsg))) {
      errorMsg = I18N.get('visit.worklist.errors.saveDraft.authorization');
      saveDraftErrors.authorization += 1;
    } else if (_.includes(_.toLower(errorMsg), 'failed to fetch')) {
      errorMsg = I18N.get('visit.worklist.errors.saveDraft.network');
      saveDraftErrors.network += 1;
    } else {
      errorMsg = I18N.get('visit.worklist.errors.saveDraft.unknown');
      saveDraftErrors.unknown += 1;
    }

    Modal.info({
      title: 'Save Draft Error',
      content: errorMsg,
      icon: <Icon type='info-circle' theme='filled' style={{ color: 'orangered', fontSize: 20 }} />,
      onOk: () => {
        if (onOk) onOk(saveDraftErrors);
        this.setState({ saveDraftErrors });
        this.goToSelectedKeyStep(0);
      },
      okText: 'Got it',
      onCancel: () => this.setState({ saveDraftErrors }),
      width: '30%',
      okButtonProps: {
        style: {
          marginTop: 32,
          background: 'var(--dark-blue)',
          borderColor: 'var(--dark-blue)',
        }
      },
      className: 'saveDraftErrorPopup'
    });

    console.error(error);
    return error;
  }

  goToSelectedKeyStep = (
    timeout = 500, 
    goToStep = this.state.goToStep
  ) => {
    if (!goToStep) return;
    setTimeout(() => {
      this.setState({
        showLeaveModal: false,
        goToStep: null,
        selectedStepKey: goToStep.key,
        selectedStepInfo: goToStep.info,
        isEditMode: false,
        childProps: false,
        parseValue: null,
        query: null,
        refetchQueries: null,
        draftType: null,
        showDraftSaved: false,
        changingStep: false,
        isSavingDraft: false
      });
    }, timeout);
  }

  autoSaveDraftWhenLeaveStep = () => {
    const { isEditMode, selectedStepKey, goToStep, isSavingDraft } = this.state;
    if (!isEditMode || isSavingDraft)
      return;

    const destroyLoading = message.loading();
    this.setState({ isSavingDraft: true }, () => {
      this.saveDraft({ selectedStepKey })
      .then((error) => {
        destroyLoading();
        if (error) {
          this.setState({ isSavingDraft: false });
          return;
        }

        this.goToSelectedKeyStep(0, goToStep);
      })
    })
  }

  onChangeStep = (key) => {
    const { isEditMode, showVitalsSummary, changingStep, selectedStepKey } = this.state;
    Mixpanel.track('clicked', key, 'visit pop up menu', '');
    if (selectedStepKey === key) {
      return;
    }
    const info = this.getStepInfoWithKey(key);
    const hasNewSignOff = false; // reset when click on step
    const extra = {};
    if (this.state.isDone) {
      extra.updatedComponents = {};
      extra.isCompleteOnOpen = true;
    }
    extra.showVitalsSummary = { isVisible: false, mounted: showVitalsSummary.mounted };

    this.setState({ goToStep: { key, info } }, async () => {
      if (changingStep) return;

      if (stepsToConfirmUnsavedArticles.includes(selectedStepKey)) {
        const res = await confirmUnsavedArticles();
        if (!res) return;
      }

      this.setState({ changingStep: true });
      if (isEditMode) {
        this.setState({ isDone: false, hasNewSignOff, ...extra }, () => {
          this.autoSaveDraftWhenLeaveStep();
        });
      } else {
        this.setState({ 
          selectedStepKey: key, 
          selectedStepInfo: info, 
          isDone: false, 
          hasNewSignOff,
          changingStep: false,
          ...extra 
        });
      }
    });
  }

  setChildProps = (
    childProps,
    parseValue,
    query,
    refetchQueries,
    draftType
  ) => {
    this.setState({
      childProps,
      parseValue,
      query,
      refetchQueries,
      draftType
    });
  };

  setBillableVitals = (
    billableVitals,
    enrolledVitals
  ) => {
    if (enrolledVitals) {
      this.setState({
        enrolledVitals
      })
    }

    if (billableVitals) {
      this.setState({
        billableVitals
      })
    }

  }
  renderForm = () => {
    const { selectedStepKey, userRole } = this.state;
    const {
      member, lastMeasurementLoading, billableLoading, nonBillableLoading,
      lastMeasurementNetworkStatus, billableNetworkStatus, nonBillableNetworkStatus, checkIfFirstMNTVisitLoading
    } = this.props;

    const isDataLoading = (lastMeasurementLoading && lastMeasurementNetworkStatus === 1)
      || (billableLoading && _.includes([1, 2], billableNetworkStatus))
      || (nonBillableLoading && _.includes([1, 2], nonBillableNetworkStatus))
      || checkIfFirstMNTVisitLoading;

    if (isDataLoading)
      return <LoadingIndicator />;

    if (selectedStepKey === Infinity) {
      return null;
    }

    const step = this.getStepWithKey(selectedStepKey);

    const renderStep = (consentInfo) => (
      <div className="padded-sub-div" style={{ padding: 30, paddingTop: 60 }}>
        {renderMap(this, consentInfo)[selectedStepKey]}
      </div>
    );

    if (step.stepInfo.step.noConsentOverlay && _.includes(['CA', 'RD', 'HC'], userRole)) {
      return (
        <NoConsentOverlay memberId={member.id} renderChild={renderStep} />
      );
    }
    return renderStep();
  };

  renderSteps = () => {
    const { selectedStepKey } = this.state;
    const worklistStatus = _.get(this, 'props.visit', {});
    const currIndex = _.get(this.getStepWithKey(selectedStepKey), 'index', undefined);

    return (
      <div>
        <Steps direction="vertical" current={currIndex}>
          {_.map(this.visitSteps, ({ step: { title, className, icon, key, statusPath, show }, disabled }) => {
            if (show !== undefined && !show(this.props.visit)) return null;
            const status = disabled ? 'COMPLETED' : _.get(worklistStatus, statusPath);
            return (
              <Step
                title={title}
                key={key}
                className={className + ` ${selectedStepKey == key ? 'selected' : ''}`}
                icon={icon(status)}
                onClick={_.debounce(() => this.onChangeStep(key), 300)}
              />
            );
          }).filter(v => v !== null)}
        </Steps>
      </div>
    );
  };

  getSubmitButtonText = (stepKey) => {
    const allStatuses = this.getVisitListsStatuses();
    const statusPath = _.get(this.getStepInfoWithKey(stepKey), 'step.statusPath', null);

    if (!statusPath) {
      return 'Complete';
    }

    const totalSteps = this.visitSteps.length;
    const currStatus = _.get(allStatuses, statusPath);
    let completedStepCount = 0;

    for (let stepObj of this.visitSteps) {
      if (_.get(allStatuses, stepObj.step.statusPath) === 'COMPLETED' || stepObj.disabled) {
        completedStepCount++;
      }
    }

    if (totalSteps === completedStepCount || (totalSteps - completedStepCount === 1 && currStatus === 'TODO')) {
      return 'Finish Charting';
    }
    return 'Complete Section';
  }

  renderTechTip = () => {
    const { showTechTip = false, stopShowTechTip = false } = this.state;
    return (
      <Modal
        width={451}
        height={251}
        visible={!!showTechTip}
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', padding: '31px 33px 36px 37px' }}>
          <div style={{ fontSize: 14, lineHeight: '19px' }}>
            Please try <span style={{ fontWeight: 700 }}>NOT</span> to limit your conversation to just tech issues. Try to review other billable topics as well, like scheduling, reminder on labs, medications, inactivity, vitals monitoring encouragement, etc.
          </div>
          <Checkbox
            style={{ marginTop: 15, marginBottom: 27, fontWeight: 400 }}
            onChange={e => this.setState({ stopShowTechTip: e.target.checked })}
          >
            Do not show me again
          </Checkbox>
          <Button
            style={{ alignSelf: 'center', background: 'var(--dark-blue)', padding: '7px 17px', lineHeight: '19px' }}
            type='primary'
            onClick={() => {
              if (stopShowTechTip) {
                localStorage.setItem('stop-techtip', stopShowTechTip + '');
              }
              this.setState({ showTechTip: false, stopShowTechTip });
            }}
          >
            Got it
          </Button>
        </div>
      </Modal>
    );
  }

  renderCheckInOutButton = () => {
    const { id, appointmentAt, category, type, checkinAt, checkoutAt } = this.props.visit || {};
    const isValidTechVisit = type === 'TECH' && category !== 'INCLINIC';
    const isPopupDisabled = !isValidTechVisit || !checkinAt;

    const handleOnClick = _.debounce(() => {
      if (!checkinAt) {
        if (isValidTechVisit && !localStorage.getItem('stop-techtip')) {
          this.setState({ showTechTip: true });
        }
        visitAPI.onClickCheckIn({
          visitId: id, needWarning: true, cb: () => {
            message.success('Visit is checked-in successfully', TOAST_DURATION);
          }
        });
      } else if (isPopupDisabled && !checkoutAt) {
        handleCheckout();
      }
    }, 200);

    const handleCheckout = () => {
      visitAPI.onClickCheckOut({
        visitId: id, needWarning: true, cb: () => {
          message.success('Visit is checked-out successfully', TOAST_DURATION);
        }
      });
    };

    const checkIfShouldShowButton = () => {
      let shouldShow = false; 
      const appointmentTimeDiffMin = getTimeDiff(new Date().getTime(), appointmentAt, 'minutes');
      if (checkinAt) { // on-going visit
        shouldShow = true;
      } else if (VISIT_STATUS_MAPPING.WAITING.checkTime(appointmentTimeDiffMin)) {
        shouldShow = true;
      } else if (VISIT_STATUS_MAPPING.ABOUT_START.checkTime(appointmentTimeDiffMin)) {
        shouldShow = true;
      }
      return shouldShow;
    };

    return checkIfShouldShowButton() && (
      <div style={{ marginLeft: 20, marginTop: -5 }}>
        <BillableReviewFormPopup
          placement='bottomLeft'
          isPopupDisabled={isPopupDisabled}
          visitId={id}
          onSubmit={handleCheckout}
        >
          <Button
            className='visitCheckInOutButton'
            type={!checkinAt ? 'primary' : 'default'}
            onClick={handleOnClick}
          >
            {!checkinAt ? 'Check in' : 'Check out'}
          </Button>
        </BillableReviewFormPopup>
      </div>
    );
  }

  openPatientProfile = () => {
    const memberId = _.get(this.props, 'visit.member.id');
    window.open(`/patients/${memberId}/enrolledprogram/default`).focus();
  }

  renderHeader = props => {
    const { visit, refetch: refetchVisit } = props;
    const { type, reason, member, category, enrolledProgram, enrolledProgramStatus, vitalTypes = [], checkinAt, checkoutAt } = visit || {};
    const fullName = _.get(member, 'profile.fullName');
    const avatarLink = _.get(member,'profile.avatar.link');
    const memberId = _.get(member, 'id');
    const _dob = _.get(member, 'profile.birthday');
    const dob = _dob ? moment(_dob).format('MM/DD/YYYY') : '';
    // const vitals = _.get(props, 'vitalTypes', []).join(', ');
    const vitals = _.intersection(VITALS_ORDER, vitalTypes);

    return (
      <Row>
        <Col span={6}>
          <Avatar src={avatarLink} size={50} icon='user'/>
        </Col>
        <Col span={18}>
          <span style={{ display: 'flex' }}>
              <h4>{`${visitMap[type]}`}</h4>
              <span style={{ marginLeft: 6 }}>
                {
                  enrolledProgram && !!this.getAvailableSummary().length &&
                  <Tooltip overlayStyle={{ width: 170 }} title='Vitals Summary'>
                    <img
                      style={{ height: 17, width: 17, opacity: 0.45, cursor: 'pointer' }}
                      src='/image/vitals-summary.svg'
                      onClick={() => {
                        const { isVisible } = this.state.showVitalsSummary;
                        Mixpanel.track('clicked', 'vitals_summary', 'worklist');
                        this.setShowVitalsSummary(!isVisible);
                      }}
                    />
                  </Tooltip>
                }
              </span>
            {
              type !== 'CLINIC_FOLLOW_UP' ?
                (!checkinAt || !checkoutAt) 
                  ? this.renderCheckInOutButton() 
                  : <div style={{ marginLeft: 20 }}>Visit Done!</div>
                : ''
          }
          </span>
          <h4 
            className='patientFullName'
            onClick={this.openPatientProfile}
          >
            {fullName}
          </h4>
        </Col>
        <Col span={24}>
          <h5>
            DOB&nbsp;<span>{`${dob}`}</span>
            {vitals.length ? `, ${vitals.join(', ')}` : ''}
          </h5>
        </Col>
        <Col span={24}>
          <AssignCTInVisitWorkList
            {...props}
            onSubmitSuccess={res => refetchVisit()}
          />
        </Col>
        <Col span={24}>
          <ConsentFormMiniContainer
            visitCategory={category}
            member={member}
            enrolledProgramStatus={enrolledProgramStatus}
            consentProviderName={_.get(enrolledProgram, 'team.0.consentProviderName')}
            usePopoverForForm
          />
        </Col>
        <Col span={24}>
          <SelfEvaluationComponent {...this.props} type={'MENU'} showSelfEvaluation={this.state.showSelfEvaluation}
            setShowSelfEvaluation={this.setShowSelfEvaluation}
          />
        </Col>
        <Col span={24} style={{ marginBottom: 8 }}>
          {
            type !== 'CLINIC_FOLLOW_UP' &&
            <PatientPreferencesContainer user={member} refetch={refetchVisit} />
          }
        </Col>
        <Col span={24} style={{ paddingRight: 10 }}>
          <strong>Notes:</strong>&nbsp;<span>{`${reason || ''}`}</span>
        </Col>
      </Row>
    );
  };

  renderConditionList = () => {
    const res = [];
    _.forEach(_.get(this, 'props.visit.conditionsList', []), (v, index) =>
      v === true ? res.push(index) : ''
    );
    const conditionStr = res.join(', ');
    return res.length != 0 ? (
      <div style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}>
        <span
          style={{ marginTop: 10 }}
        >{`Condition List: ${conditionStr}`}</span>
      </div>
    ) : (
      ''
    );
  };

  getAvailableSummary = () => _.intersection(['BP', 'BG', 'PO'], _.get(this, 'props.visit.vitalTypes'));

  renderVitalsSummary = () => {
    const { isVisible, mounted } = this.state.showVitalsSummary;
    const { id, member, enrolledProgram, vitalTypes = [] } = _.get(this, 'props.visit') || {};
    return mounted && <VitalsSummaryContainer
      memberId={_.get(member, 'id')}
      visitId={id}
      enrolledProgramId={_.get(enrolledProgram, 'id')}
      tasks={_.get(enrolledProgram, 'tasks')}
      availableSummary={this.getAvailableSummary()}
      visible={isVisible}
      handleClose={() => this.setShowVitalsSummary(false)}
    />;
  }

  checkIfMNTVisit = () => _.get(this, 'props.visit.type') === VISIT_TYPE_ENUM.MNT;

  checkIfShouldShowSignOff = isMNTVisit => {
    if (!isMNTVisit) return false;
    const { selectedStepKey } = this.state;

    const isStepSelected = selectedStepKey !== Infinity;

    return !isStepSelected;
  };

  handleCloseModal = async () => {
    const { isEditMode, selectedStepKey, isSavingDraft } = this.state;

    if (stepsToConfirmUnsavedArticles.includes(selectedStepKey)) {
      const res = await confirmUnsavedArticles();
      if (!res) return;
    }
    
    if (isEditMode && !!selectedStepKey && !isSavingDraft) {
      const destroyLoading = message.loading();
      await this.saveDraft({ 
        selectedStepKey, 
        onErrorOk: () => this.props.onModalCancel()
      }).then((error) => {
        destroyLoading();
        if (error) {
          this.setState({ isSavingDraft: false });
          return;
        }
        this.props.onModalCancel();
      });
    } else {
      this.props.onModalCancel();
    }
  }

  render() {
    const { props, state } = this;
    const { modalVisible = true, modalProps } = props;
    const { isDone, isCompleteOnOpen, updatedComponents, showDraftSaved } = state;
    const isMNTVisit = this.checkIfMNTVisit();
    const shouldShowSignOff = this.checkIfShouldShowSignOff(isMNTVisit);

    return (
      <Modal
        visible={modalVisible}
        width="90vw"
        style={{ top: 40 }}
        className='workListModal'
        onCancel={_.debounce(this.handleCloseModal, 500)}
        // maskClosable={false}
        footer={null}
        destroyOnClose
        {...modalProps}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* {this.renderLeaveModal()} */}
          {this.renderVitalsSummary()}
          {this.renderTechTip()}
          <Row type="flex">
            <Col className='modal-side' span={6} style={{ background: '#f5f5f5' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: '40px 0', height: '100%' }}>
                <div>
                  <div style={{ padding: '0 40px' }}>
                    {this.renderHeader(props)}
                  </div>
                  <hr style={{ borderColor: '#dddddd' }} />
                  <div style={{ padding: '0 40px' }}>
                    {this.renderConditionList()}
                    {this.renderSteps()}
                  </div>
                </div>
              </div>
            </Col>
            <Col className={`modal-side no-padding ${showDraftSaved ? 'notifyDraftSaved' : ''}`} span={18}>
              <div style={{ width: '100%', height: '100%' }}>
                {shouldShowSignOff || (!isMNTVisit && isDone) ? (
                  <div className="padded-sub-div" style={{ padding: 30, paddingTop: 60 }}>
                    <AllTaskFinishedComponent
                      firstTime={!isCompleteOnOpen}
                      memberId={props.member.id}
                      itemsUpdated={isCompleteOnOpen ? Object.keys(updatedComponents).join(', ') : '-'}
                      enrolledProgramId={_.get(props, 'visit.enrolledProgram.id')}
                      visitId={props.visitId || _.get(props, 'visit.id')}
                      openErrorModal={props.openErrorModal}
                      shouldShowSignOff={shouldShowSignOff}
                      signOffStatus={_.get(props, 'visit.signOffStatus')}
                    />
                  </div>
                ) : (
                  this.renderForm()
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
};

export default withRouter(WorkListComponent);
