import configs from '../../../lib/config';

const domain = configs.UC_SERVER_URL;

const getSessionToken = () => {
  const sessionToken = sessionStorage.getItem('authToken');
  return sessionToken;
};

export const setRPMReview = async (params) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': getSessionToken(),
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(params),
  };

  const res = await fetch(`${domain}/rpm-patient-review`, fetchOptions);
  return res.json();
};
