import React from 'react';

import './CellularAssignedDeviceComponent.scss';
import { CellularDeviceDisplayBrandComponent } from '../CellularDeviceDisplayComponent/CellularDeviceDisplayBrandComponent';

export const CellularAssignedDeviceComponent = ({
  brand,
  deviceId,
  language,
}) => {
  return (
    <div
      className="cellular-assigned-device"
    >
      <div
        className="cellular-assigned-device__type"
      >
        <CellularDeviceDisplayBrandComponent brand={brand} />
      </div>
      <div>
        Device ID:
        {' '}
        {deviceId}
      </div>
    </div>
  )
};
