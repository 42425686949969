import {Form, Input, Button, Select, Row, Col, Radio} from 'antd';
import React, { Component } from 'react';
const {Item} = Form;
const {Option} = Select;
import Client from 'libModule/gqlClient';
import {helpers} from "../../visit/helpers";
import { langOptions,countryOptions } from '../../../lib/constants';
import { dobValidator, mobileValidator, homePhoneValidator, firstAndLastNameValidator, NRICValidator } from 'libModule/validator';
import monent from 'moment';
import { getMutateVariableInfo } from '../../patient/AdditionalInfo/helpers/getMutateValueHelper';
import userGQL from "../../graphql/user"
import { IHLoading, message } from 'ihcomponent';
import { GQLHelper } from 'lib/utils';

import editUser from 'modulesAll/graphql/mutation/editUser';
import { formatFieldLabels, renderDropDown } from "../../patient/AdditionalInfo/helpers";
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import I18N from '../../I18N';
const radioMap = {
    country:countryOptions,
}
let  EditPatientFormComponent = class extends React.Component{

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        const userId = _.get(this,'props.user.id');
        const { setShowEditProfile,openErrorModal } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const variables = getMutateVariableInfo(Object.assign({ userId:atob(userId) },this.props));
                if(values.doctorId){
                    _.set(variables, 'memberProfile.doctorId', values.doctorId.split('|')[0]);
                    _.set(variables, 'memberProfile.doctorName', values.doctorId.split('|')[1]);
                }
                

                Client.mutate({ mutation:editUser,
                    variables,
                    refetchQueries:[{ query:userGQL, variables: { id:userId },
                        fetchPolicy:'network-only'}]
                }).then(res=>{
                    message.success('Patient Profile Saved!');
                    setShowEditProfile(false)
                }).catch(error=>{
                    openErrorModal(GQLHelper.formatError(error))
                });
            } else {
                console.log(err);
            }
        });

    };

    renderName(){

        const { getFieldDecorator } = this.props.form;
        const { profile:{ firstName,lastName } } = this.props.user;
        const identification = _.get(this,'props.user.identification[0].value');
        const username = _.get(this,'props.user.username');
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Item label="First Name" >
                        {getFieldDecorator('firstName',
                            {
                                initialValue:firstName,
                                rules: [{required: true, message: 'First Name is required'},
                                    {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}]

                            })
                        (
                            <Input />
                        )}
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="Last Name" >
                        {getFieldDecorator('lastName',
                            {
                                initialValue:lastName,
                                rules: [{required: true, message: 'Last Name is required'},
                                    {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}]
                            })
                        (
                            <Input />
                        )}
                    </Item>
                </Col>
                <Col span={8}>
                    <Item label="Username">
                        {getFieldDecorator('username', { initialValue:username  })
                        (
                            <Input disabled={true}/>
                        )}
                    </Item>
                </Col>

                {/*<Col span={8}>*/}
                    {/*<Item label="Username" >*/}
                        {/*{getFieldDecorator('username',*/}
                            {/*{rules: [{required: true, message: 'User name is required'},*/}
                                    {/*{ min: 3 , message: 'Need to more than 3 characters' }]*/}
                            {/*})*/}
                        {/*(*/}
                            {/*<Input />*/}
                        {/*)}*/}
                    {/*</Item>*/}
                {/*</Col>*/}
            </Row>
        )
    }

    renderLanguageGenderDOB(){
        const { getFieldDecorator } = this.props.form;
        const { profile:{ birthday,language={},gender } } = this.props.user;
        const parsedDOB = monent(birthday).format('MM/DD/YYYY');
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Item label="DOB" >
                        {getFieldDecorator('DOB', {
                            initialValue:parsedDOB,
                            rules: [
                                {required: true, message: 'DOB is required'},
                                {validator: (rule, value, callback) => callback(dobValidator(value))}
                            ]})
                        (
                           <Input />
                        )}
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="Spoken Language" >
                        {getFieldDecorator('language', {initialValue:language.code||'EL', rules: [{required: true, message: 'language is required'}]})
                        (
                            <Select>
                                { _.map(langOptions,({value,name})=><Option value={value} key={value}>{name}</Option>) }
                            </Select>
                        )}
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="Gender" >
                        {getFieldDecorator('gender', { initialValue:gender,rules: [{required: true, message: 'gender is required'}]})
                        (
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="M">M</Radio.Button>
                                <Radio.Button value="F">F</Radio.Button>
                            </Radio.Group>
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }

    renderDoctorAndMedID(){
        const { profile:{ doctor },identification } = this.props.user;
        const { getFieldDecorator } = this.props.form;
        const doctorOptions = helpers.filterDoctors();
        const medicalId = _.get(identification,'0.value');
        const doctorId = _.get(doctor,'id');
        const doctorName = doctor.profile.fullName;
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Item label={I18N.get('provider.label')}>
                        {getFieldDecorator('doctorId',{
                            initialValue: doctorName,
                            rules:[{
                              required:true, message: I18N.get('provider.required.message')
                            }]
                          })
                          (
                            <Select filterOption={false}>
                                {_.map(doctorOptions,(program)=>{
                                    return (
                                        <Option value={`${program.id}|${program.fullName}`} key={program.id}>{program.fullName}</Option>
                                    )
                                })}
                            </Select>
                          )}
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="Med ID" >
                        {getFieldDecorator('identification',
                            {
                                initialValue:medicalId,
                                rules: [{required: true, message: 'Med ID is required'},
                                    {validator: (rule, value, callback) => callback(NRICValidator(value))}]
                            })
                        (
                            <Input />
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }
    genRules = (form,localThis) =>{
        const fieldArray = ['streetNumber','streetName','city','state','postCode','country'];
        const fieldValues = form.getFieldsValue();
        const address = _.get(localThis,'props.user.address[0]',{});
        let hasValue = [];

        _.each(fieldArray,(v)=>{
            if((!(v in fieldValues)&&address[v])||fieldValues[v]){
                hasValue.push(v);
            }
        });

        if(hasValue.length!=0){
            return [{
                required:true,
                // message:`${field} is required`
            }]
        }
    }
    renderPhone(){

        const { getFieldDecorator } = this.props.form;
        const phone = _.get(this,'props.user.phone');
        const homePhone = _.first(_.filter(phone,({type})=>type =='HOME'))||{};
        const mobilePhone = _.first(_.filter(phone,({type})=>type =='MOBILE'))||{};
        const value = mobilePhone.canUseForLogin;
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Item label="Home Phone" >
                        {getFieldDecorator('homePhone', {
                            initialValue:homePhone.number,
                            rules: [
                                {required: false},
                                {validator: (rule, value, callback) => callback(homePhoneValidator(value))}
                            ]})
                        (
                            <Input />
                        )}
                    </Item>
                </Col>

                <Col span={8}>
                    <Item label="Mobile Phone" >
                        {getFieldDecorator('mobilePhone', {
                                initialValue:mobilePhone.number,
                                rules: [{required: false},
                                {validator: (rule, value, callback) => callback(mobileValidator(value))}
                            ]})
                        (
                            <Input />
                        )}
                    </Item>
                </Col>
                <Col span={6}>
                    <Item label="OTP">
                        {getFieldDecorator('canUseForLogin', { rules: [{required: false}],initialValue:typeof value=='boolean' ? value.toString() : (typeof value=='string'&&value.length>0) ? value :'true'})
                        (
                            <Radio.Group style={{marginTop:'2px',display:'flex'}}>
                                <Radio.Button value='true' style={{ textAlign: 'center'}}>On</Radio.Button>
                                <Radio.Button value='false' style={{ textAlign: 'center'}}>Off</Radio.Button>
                            </Radio.Group>
                        )}
                    </Item>
                </Col>
            </Row>
        )

    }
    renderAddress = ()=>{
        const { genRules,props } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const map =[[
                {
                    key: 'streetNumber',
                    label: formatFieldLabels('streetNumber','streetNumber'),
                    path:'address[0].streetNumber',
                    labelCol: { span: 24 },
                    wrapperCol: { span: 22 },
                    colStyle:{height:87},
                    span:4,
                    inputContent:()=><Input />,
                    rules:(form,localThis)=>{
                        return genRules(form,localThis);
                    },
                },
                {
                    key: 'streetName',
                    label: formatFieldLabels('streetName','streetName'),
                    path:'address[0].streetName',
                    labelCol: { span: 24 },
                    wrapperCol: { span: 22 },
                    colStyle:{height:87},
                    span:8,
                    rules:(form,localThis)=>{
                        return genRules(form,localThis);
                    },
                    inputContent:({disabled})=><Input disabled={disabled}/>,
                    // rules : [
                    //     { min: 2 , message: 'Need to more than 2 characters' },
                    // ]
                },
                {
                    key: 'city',
                    label: formatFieldLabels('city', 'city'),
                    path:'address[0].city',
                    colStyle:{height:87},
                    rules:(form,localThis)=>{
                        return genRules(form,localThis);

                    },
                    inputContent:({disabled})=><Input disabled={disabled}/>,
                    labelCol: {span: 24},
                    wrapperCol: {span: 8 },
                    span:12
                },
            {
                key: 'state',
                label: formatFieldLabels('state','state'),
                path:'address[0].state',
                rules:(form,localThis)=>{
                    return genRules(form,localThis);

                },
                labelCol: { span: 24 },
                wrapperCol: { span: 22 },
                colStyle:{height:87},
                span:4,
                inputContent:({disabled})=><Input disabled={disabled}/>,
            },
            {
                key: 'postCode',
                label: formatFieldLabels('postCode','postCode'),
                path:'address[0].postCode',
                rules:(form,localThis)=>{
                    return genRules(form,localThis);

                },
                colStyle:{height:87},
                maxLength: 6,
                labelCol: { span: 24 },
                wrapperCol: { span: 22 },
                span:4,
                colStyle:{whiteSpace:'nowrap'},
                inputContent:({disabled})=><Input disabled={disabled} style={{whiteSpace:'nowrap'}}/>,
                // rules : [ {validator: (rule, value, callback) => callback(postCodeValidator(value))} ]
            },
            {
                key: 'country',
                label: formatFieldLabels('country','country'),
                path:'address[0].country.code',
                rules:(form,localThis)=>{
                    return genRules(form,localThis);

                },
                colStyle:{height:87},
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                span:16,
                inputContent:(props)=>renderDropDown(radioMap['country'],props),
            }
            ]]
        const localThis = this;
        const user = _.get(props,'user');
        return _.map(map,(arrayItem,index)=> {
            return(
                <Row key={index} style={{display:'none'}}>
                    {
                        arrayItem.map(({key, className, rules, label, path, inputContent, wrapperCol, colStyle, offset, rowStyle, col, labelCol, required, formatValue, format, span, style, placeHolder, onChange,valuePropName}, index) => {
                            const value = path ? (typeof path =='string') ? _.get(user, path, '') : _.get(user,path(localThis),''):'';
                            const {form} = localThis.props;
                            className = className ? className : '';
                            return <Col span={span} key={index} style={colStyle} offset={offset}>
                                <Row justify={'start'} style={rowStyle}>
                                    {
                                        placeHolder ? <Col span={span}>{placeHolder}</Col> :
                                            <Item layout={'vertical'} key={key} label={label}
                                                  wrapperCol={wrapperCol}
                                                  col={col}
                                                  labelCol={labelCol}
                                                  style={{display:'none'}}>
                                                {
                                                    getFieldDecorator(key, {
                                                        rules: typeof rules == 'function' ? rules(form,localThis) : rules,
                                                        initialValue: typeof formatValue == 'function' ? formatValue(value, format) : value,
                                                        valuePropName:valuePropName || 'value'
                                                    })(inputContent({
                                                        className,
                                                        format,
                                                        value,
                                                        onChange,
                                                        localThis,
                                                        key,
                                                        form
                                                    }, user, localThis))
                                                }
                                            </Item>
                                    }
                                </Row>
                            </Col>
                        })
                    }
                </Row>
            )
        })
    }

    render() {
        return (
            <div className="visit_popup_patient">
                <Form onSubmit={this.handleSubmit}>
                    {this.renderName()}
                    {this.renderDoctorAndMedID()}
                    {this.renderLanguageGenderDOB()}
                    {this.renderPhone()}
                    {this.renderAddress()}
                    <Row>
                        <Col span={3} offset={21}>
                            <Item>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

}

EditPatientFormComponent = Form.create()(EditPatientFormComponent);

const mapDispatch = (dispatch) => {
    return {
        openErrorModal: msg => dispatch(openErrorModal(msg)),
    }
}

export default connect(null,mapDispatch)(EditPatientFormComponent);
