import { graphql } from 'react-apollo'
import setDevicesForAdminProgram from 'graphqlModule/setDevicesForAdminProgram'
import changeAdminProgramStatus from 'graphqlModule/changeAdminProgramStatus'
import { changeAdminProgramStatus as updateQueries } from 'graphqlModule/updateQueries/program'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { goPath } from 'libModule/utils'
import { IHBaseFormWithRow, IHButton, _ } from 'ihcomponent'
import actions from 'ProgramIncompleteModule/Device/actions/DeviceFormActions'
import types from 'ProgramIncompleteModule/Device/constants/DeviceFormConstants'
import ProgramIncompleteActions from 'ProgramIncompleteModule/Common/actions/CommonActions'
import { getFormProps, initData } from 'programModule/Common/components/formData/DeviceForm'
import { convertToFieldsValue } from 'libModule/utils'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { openSuccessModal } from 'layoutModule/actions/MainModal'

class Container extends Component {
  static displayName = 'program/ProgramIncomplete/Device/containers/DeviceFormContainer'

  componentWillMount() {
    const { updateForm, program: { devices } } = this.props
    const newInitData = {}
    if (Array.isArray(devices)) {
      devices.forEach(v => {newInitData[v.type] = v.model || initData[v.type]})
      updateForm(convertToFieldsValue(newInitData))
    }
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  render() {
    const title = this.renderTitle()
    const body = this.renderBody()
    const footer = this.renderFooter()
    return (
      <div className='vsm-device-form-container'>
        {title}
        {body}
        {footer}
      </div>
    )
  }

  renderTitle() {
    return <h3 className='vsm-form-title vsm-form-main-title'>Select Device models</h3>
  }

  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    if (!fieldsValue) return;
    const mapPropsToFields = props => props.fieldsValue
    const formProps = getFormProps({ fieldsValue, onFieldsChange, mapPropsToFields })
    const body = <IHBaseFormWithRow {...formProps} />
    return body
  }

  renderBottomButton() {
    const { setTabsStep } = this.props
    const done = {
      type : 'primary',
      label : 'Save and Done',
      onClick : ()=>{
        this.submit()
      },
      className : 'v-nb'
    }
    const back = {
      label : 'Back',
      className : 'v-bb',
      onClick : ()=>{
        setTabsStep('careplan')
      },
      style: {marginRight: 10}
    }

    return [
      <IHButton {...back} key='back' />,
      <IHButton {...done} key='done' />
    ]
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '50%',
        paddingRight: 5,
        marginTop: 10
      }
    }

    const footer = (
      <div style={styles.footer}>
        {this.renderBottomButton()}
      </div>
    )
    return footer
  }

  async submit() {
    const { program, fieldsValue, setDevicesForAdminProgram, changeAdminProgramStatus } = this.props
    let devices = {}
    for (let field in fieldsValue) {
      devices[fieldsValue[field].name.toUpperCase()] = fieldsValue[field].value
    }
    // BP and HR use the same device
    if (devices.BP && devices.HR) devices.HR = devices.BP

    const variables = {
      id: program.id,
      devices
    }
    const auditLogDetails = {
      programName: program.name,
      devices: _.mapKeys(devices, (value, key) => {
        switch(key) {
          case 'BG':
            return 'blood glucose'
          case 'BP':
            return 'blood pressure'
          case 'HR':
            return 'heart rate'
          case 'HS':
            return 'weight'
          default:
            return key
        }
      })
    }

    try {
      const res = await setDevicesForAdminProgram({ variables, updateQueries })
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.device.setDevices'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      // console.log('got data: ', res)
    } catch( err ) {
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.device.setDevices'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }

    const variableStatus = { variables: {id: program.id, status: 'ACTIVE'} };
    const auditLogStatusDetails = {
      programName: program.name,
      status: 'ACTIVE'
    }
    try {
      const statusRes = await changeAdminProgramStatus(variableStatus)
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.completeProgramme'),
        details: auditLogStatusDetails,
        request: variableStatus,
        response: statusRes
      })

      // console.log('got data', statusRes.data)
      const modalTitle = `${I18N.get('keywords.Program')} Created Successfully`
      const modalContent = `A new ${I18N.get('keywords.program')} has been created successfully`
      this.props.openSuccessModal(modalTitle, modalContent);
      goPath('/programmes_admin')
    }
    catch(err) {
      console.log('update device error: ', err)
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.completeProgramme'),
        details: auditLogStatusDetails,
        request: variableStatus,
        response: err,
        success: false
      })
    }
  }
}

// connect apollo data with container
const ContainerWithData = graphql(setDevicesForAdminProgram, { name: 'setDevicesForAdminProgram'})(
  graphql(changeAdminProgramStatus, { name: 'changeAdminProgramStatus'})(
    Container
  )
)

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields, types)),
    updateForm: (fieldsValue) => dispatch(actions.updateForm(fieldsValue)),
    resetForm: () => dispatch(actions.resetForm()),
    setTabsStep: (step) => dispatch(ProgramIncompleteActions.setTabsStep(step)),
    openSuccessModal: (title, content) => dispatch(openSuccessModal(title, content))
  }
}

Container.propTypes = {
  onFieldsChange: PropTypes.func,
  updateForm: PropTypes.func,
  resetForm: PropTypes.func,
  initData: PropTypes.object,
  editProgramDevice: PropTypes.func,
  changeAdminProgramStatus: PropTypes.func,
  program: PropTypes.object,
  fieldsValue: PropTypes.object,
  isFieldsRequired: PropTypes.object,
  isFormValid: PropTypes.bool,
  setTabsStep: PropTypes.func,
  openSuccessModal: PropTypes.func
}

export default connect(
  null, mapDispatch
)(ContainerWithData)
