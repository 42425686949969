import React from 'react';
import { Row,Button,Col,Input,Checkbox,message } from 'antd';
import I18N from "../../I18N";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';

const SendSMSComponent = class extends React.Component{
    constructor(props) {
        super(props);
        let phoneList  = _.get(this,'props.currentDoc.member.phone',[]);
        let mobile = _.get(_.filter(phoneList,(phone)=>phone.type=="MOBILE"),'0.number');
        this.state = {
            verbalConsent: false,
            phoneIsInvalid: !!this.phoneValidator(mobile),
            phoneNumber: mobile
        }
    }

    phoneValidator = (value)=>{
        if (!value) return;
        if (!/^\d{10}$/.test(value)) {
            return I18N.get('validator.mobile.invalid');
        }
    };

    renderContent = ()=>{
        const { phoneIsInvalid,phoneNumber } = this.state;
        const className = phoneIsInvalid&&'has-error';
        return<Row>
                <Col>
                    <span>
                        This patient’s mobile phone number is not in the system.Please enter a mobile phone number below:
                    </span>
                </Col>
                <Col className={className}>
                    <Input value={phoneNumber} onChange={(e)=>{
                        const phoneNumber = e.target.value;
                        this.setState({
                            phoneNumber,
                            phoneIsInvalid: !!this.phoneValidator(phoneNumber)
                        })
                    }}/>
                    <span className={'error-message'}>Invalid phone number</span>
                </Col>
                <Col>
                    <Checkbox onChange={(e)=>this.setState({verbalConsent:e.target.checked })}>Patient verbally consents to receiving SMS</Checkbox>
                </Col>
              </Row>
    }

    handleClickSend = ()=>{
        Mixpanel.track('clicked','send','program sms popup');
        let memberId  = _.get(this,'props.currentDoc.member.id');
        const { phoneNumber } = this.state;
        try{
            API.sendPromoteSMS({
                memberId,number:phoneNumber,type:'PROMOTE_UNIFIED_CARE'
            }).then(res=> {
                const smsMessage = _.get(res,'data.sendMemberSms.message','');
                message.info(smsMessage);
                this.props.setShowSendSMS(false);
            })
        }
        catch (e) {

        }
    }

    renderButtons = ()=>{
        const { setShowSendSMS } = this.props;
        const { phoneIsInvalid,verbalConsent,phoneNumber } = this.state;
        const disableSend = !phoneNumber||phoneIsInvalid||!verbalConsent;
        return <Row className={'buttons'}>
                <Col>
                   <Button disabled={disableSend} onClick={()=>this.handleClickSend()}>Send</Button>
                </Col>
                <Col>
                   <Button onClick={()=>setShowSendSMS(false)}>Never Mind</Button>
                </Col>
               </Row>
    }
    render() {
        const { renderButtons,renderContent } = this;
        return (
            <div className={'sendSMSPromoteContainer'}>
                {renderContent()}
                {renderButtons()}
            </div>
        );
    }
}

export default SendSMSComponent;
