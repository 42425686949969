import ProgramInfo from '../components/ProgramInfo'
import {createContainer, getRouterParam} from 'libModule/utils'

import { graphql } from 'react-apollo';
import programGQL from 'modulesAll/graphql/adminProgram'

const ComponentWithData = graphql(programGQL, {
  name : 'adminProgram',
  options : (ownProps)=>{
    const programId = ownProps.programId
    return {
      variables : {
        id : programId
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props : ({ownProps, adminProgram})=>{
    return {
      adminProgram
    }
  }
})(ProgramInfo)

export default createContainer(ComponentWithData, (state)=>{

  return {
    programId : getRouterParam(state, 'programId')
  }
}, (dispatch) => ({dispatch}))
