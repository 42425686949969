import React from "react";
import {Form, Select, Row, Button, Icon, Input } from 'antd';
import { stageOfBehavorialChanges, DEFAULT_BEHAVIOR_CHANGE } from '../../constant/nutritionInterpretationConstants';


let BehavioralChangeComponent = class extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      hasNote: _.get(props, 'program.extraNote'),
    }
  }

  setNotes = (e) => {
    this.setState({hasNote: e.target.value});
    this.props.form.setFieldsValue({'goalNotes': e.target.value});
  }

  onAddNoteClick = () =>{
    this.setState({hasNote: true});
  }

  renderNotes = () =>{
    const {disabled, form, program} = this.props;
    return(
      <Row className='goal-note'>
        <Form.Item label='Notes'>
        {form.getFieldDecorator('goalNotes', {
          initialValue: _.get(program, 'extraNote'),
          valuePropName: 'value'
        })(
          <Input.TextArea autoSize={{ minRows: 1 }} disabled={disabled} placeholder='Add note...' onChange={this.setNotes} allowClear/>
        )}
        </Form.Item>
      </Row>
    )
  }

  render(){
    const { isWorkList, showBehavioralGoals } = this.props;
    const { hasNote } = this.state;
    const className = isWorkList ? 'behaviroal-change-worklist' : 'border-change';
    return(<div>
          <div className ='behavioral-change'>
            <div className='goals-list-items'>
              Stage of Behavioral Change
            </div>
            <div className={className}>
            <Form.Item span={5}>
                {
                    this.props.form.getFieldDecorator('behaviorChange', {
                        initialValue: _.get(this.props, 'user.profile.nutrition.behaviorChange') || _.get(this.props, 'user.profile.motivation') || DEFAULT_BEHAVIOR_CHANGE,
                    })(<Select style={{ width: '40%' }}
                          placeholder='Select'
                          disabled={this.props.disabled}
                        >
                          {
                            _.map(stageOfBehavorialChanges, option =>
                             <Select.Option key={option.value} value={option.value} >{option.label}</Select.Option>)
                          }
                       </Select>
                    )
                }
                </Form.Item>
            </div>
        </div>

          <div>
                { !showBehavioralGoals && ( hasNote? this.renderNotes() :
                    <Row className='add-goal-note' style={{float: 'left'}}>
                      <Button className='add-click'
                              type='link'
                              onClick={this.onAddNoteClick}
                              id='goal-statement-add-note'
                              disabled={this.props.disabled}>
                              <Icon type='plus'/>
                              Add Note
                      </Button>
                  </Row>)}
           </div>

    </div>)
  }
}

export default BehavioralChangeComponent;