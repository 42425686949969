import { React, IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment } from 'ihcomponent';
import { renderForm} from "../helpers";
import { browserHistory } from 'react-router';
import { showMonthsOrDays, RequestCache} from 'libModule/utils';
import { Icon,Table,Modal } from 'antd';
// import { AssignDeviceComponent } from './assignDeviceComponent';
import UpdateMobileInfoComponent from './UpdateMobileInfoComponent';

let authMap = new Map();
authMap.set('cameraStatus', 'Camera');
authMap.set('microphoneStatus', 'Microphone');
authMap.set('photoLibraryStatus', 'Photo Library');
authMap.set('notificationStatus', 'Notifications');
authMap.set('contactsStatus', 'Contacts');
authMap.set('location', 'Location');

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:false

            // isStatusFilterClicked: false
        }
        this.changeTableActionFilter = this.changeTableActionFilter.bind(this);
    }

    setShowModal = (showModal) =>{
        this.setState({
            showModal
        })
    }
    renderModal = ()=>{
        const { showModal } = this.state;
        const { user } = this.props;
        return showModal&&<Modal visible={true} footer={false} onCancel={()=>this.setShowModal(false)} style={{ padding:25 }} className='updateMobileInfo'>
            <UpdateMobileInfoComponent user={user} setShowModal={this.setShowModal}/>
        </Modal>
    }
//   componentWillMount() {
//     this.props.onLoadParams(null, true)
//     RequestCache.set('nurse/currentPatient', undefined); //reset
//   }
//   componentWillUnmount() {
//     if (!new RegExp(/^(\/patients)/).test(Store.getState().routing.location.pathname)) {
//         this.props.onLoadParams(null, false)
//     }
//   }

  changeTableActionFilter(newFilter) {
    const { onTableChange, filter = {}, sort = {}, page } = this.props;
    const sortParam = _.pick(sort, ['field', 'order']);
    const filterParam = {
      ...filter,
      ...newFilter,
    };
    onTableChange({current: 1, size: 10}, filterParam, sortParam);
  }

  getTableProps(){
    const {
      onTableChange, data, sort, phoneInfoList
    } = this.props;
    
    // const deviceSet = new Set();
    // const newPhoneList = [];
    // _.forEach(phoneInfoList, (phone) => {
    //   if(!deviceSet.has(phone.mobileInfo.phoneId)) {
    //     deviceSet.add(phone.mobileInfo.phoneId);
    //     newPhoneList.push(phone);
    //   }
    // })

    const formatTime = (data)=>{
        return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY, HH:mm') :'time format error');
    };
    const formatTimePart = (data)=>{
      return (moment(data).isValid() ? moment(data).format('MM/DD/YYYY') :'time format error');
    };
      const b = {
          type: 'primary',
          label: 'Resend App Link',
          style: {
              position: 'absolute',
              top: '8px',
              right: '20px',
              border:'unset',
              textDecoration:'underline',
              color:'#4278C3',
              textShadow:'unset',
              fontSize:14,
              background:'inherit',
              cursor: 'pointer',
              fontWeight:'bold'
          },
          onClick:()=>{
              this.setState({
                  showModal:true
              })
              // this.props.openModal(<MedicineModalComponent {...props} modal = {addProps}/> )
          },
          size: 'small'
      }
    return {
      title: `Device`,
      dataSource: phoneInfoList,
      actions:<IHButton {...b}/>,
    //   onChange: (p, f, s) => {
    //     let result = null;
    //     if(this.props.sort.order && !s.order) {
    //     Mixpanel.track('clicked', 'sort', 'age is canceled', '');
    //     return onTableChange(p, newFilter, s);
    //     } else {
    //     Mixpanel.track('clicked', 'sort', 'age ' + s.order == 'descend' ? 'old to young' : 'young to old', '');
    //     return onTableChange(p, newFilter, s);
    //     }
    //   },
    //   inputPlaceholder: `Search by Name ${I18N.get('keywords.NRIC/FIN')} or ${I18N.get('keywords.PHONE')}`,
    //   pagination: {
    //     current: _.get(page, 'current', 1),
    //     pageSize: _.get(page, 'pageSize', 10),
    //     total: _.get(patientList, 'pageInfo.total', 0),
    //     onChange: (e) => {
    //       Mixpanel.track('clicked','pager','patients', {PAGE_NUM : "Page" + e});
    //     }
    //   },

      columns: [
        {
            title:'Device Name',
            key: 'deviceName',
            width:'9%',
            dataIndex:'mobileInfo.phoneName',
            render: (t, doc) => t,
        },
        {
            title: 'Model',
            key: 'model',
            width:'10%',
            dataIndex: 'mobileInfo.phoneModel',
            render: (t, doc) => t,
        },
        {
            title: 'Initial Used',
            key: 'initialUsed',
            width:'12%',
            dataIndex:'FirstUsedAt',
            render: (t, doc) => formatTimePart(t),
            sorter: (o1,o2)=> o1.FirstUsedAt-o2.FirstUsedAt
        },
        {
            title: 'OS Version',
            key: 'osVer',
            width:'11%',
            dataIndex:'mobileInfo.phoneOSVersion',
            render: (t, doc) => t,
            sorter: (o1,o2) => {
              let typeOne = o1.mobileInfo.phoneOSVersion.split(' ')[0];
              let typeTwo = o2.mobileInfo.phoneOSVersion.split(' ')[0];
              let verOne = o1.mobileInfo.phoneOSVersion.split(' ')[1];
              let verTwo = o2.mobileInfo.phoneOSVersion.split(' ')[1];
              if(_.startsWith(typeOne, 'A') && _.startsWith(typeTwo, 'i')) {
                return -1;
              } else if (_.startsWith(typeTwo, 'A') && _.startsWith(typeOne, 'i')) {
                return 1;
              } else {
                return verOne - verTwo;
              }
            }
        },
        {
            title: 'SC App Ver',
            key: 'scAppVer',
            width:'11%',
            dataIndex:'mobileInfo.appVersion',
            render: (t, doc) => t,
            sorter: (o1,o2) => {
              let appOne = o1.mobileInfo.appVersion.split('.');
              let appTwo = o2.mobileInfo.appVersion.split('.');
              return appOne[0] == appTwo[0] ? (appOne[1] == appTwo[1] ? appOne[2] - appTwo[2] : appOne[1] - appTwo[1]) : appOne[0] - appTwo[0];
            }
        },
        {
            title: 'Last Used',
            key: 'lastUsed',
            width:'12%',
            dataIndex:'LastUsedAt',
            render: (t, doc) => formatTime(t),
            sorter: (o1, o2) => o1.LastUsedAt - o2.LastUsedAt

        },
        {
            title:'App Authority',
            key: 'appAuth',
            dataIndex:'authorityInfo',
            render: (t, doc) => {
                //check IOS Or Android
                let isAndroid = _.startsWith(doc.mobileInfo.phoneOSVersion, 'Android');
                let grantList = new Array();
                let deniedList = new Array();
                let grantResult = '';
                let deniedResult = '';
                if(isAndroid) {
                  if(t.readContactsStatus && t.writeContactsStatus && t.readContactsStatus === 'granted' && t.writeContactsStatus === 'granted') {
                    grantList.push(authMap.get('contactsStatus'));
                  } else {
                    deniedList.push(authMap.get('contactsStatus'));
                  }
                  if(t.fineLocationStatus && t.fineLocationStatus === 'granted' || t.coarseLocationStatus && t.coarseLocationStatus === 'granted') {
                    grantList.push(authMap.get('location') );
                  } else {
                    deniedList.push(authMap.get('location'));
                  }
                } else {
                  if(t.contactsStatus === 'granted') {
                    grantList.push(authMap.get('contactsStatus'));
                  } else {
                    deniedList.push(authMap.get('contactsStatus'));
                  }
                  if(t.locationAlwaysStatus && t.locationAlwaysStatus === 'granted' || t.locationWhenInUseStatus && t.locationWhenInUseStatus === 'granted') {
                    grantList.push(authMap.get('location'));
                  } else {
                    deniedList.push(authMap.get('location'));
                  }
                }
                let newObj = _.omit(t, ['readContactsStatus', 'writeContactsStatus', 'contactsStatus', 'locationAlwaysStatus', 'locationWhenInUseStatus', 'coarseLocationStatus', 'fineLocationStatus']);
                let keyArr = Object.keys(newObj);
                let valArr = Object.values(newObj);
                for(let i = 0; i < keyArr.length - 1; i++) {
                  if(valArr[i] === 'granted') {
                    grantList.push(authMap.get(keyArr[i]));
                  } else {
                    deniedList.push(authMap.get(keyArr[i]));
                  }
                }
                for(let i = 0; i < grantList.length; i++) {
                  if(i == grantList.length - 1) {
                    grantResult += (grantList[i]);
                  } else {
                    grantResult += (grantList[i] + ', ');
                  }
                }
                for(let i = 0; i < deniedList.length; i++) {
                  if(i == deniedList.length - 1) {
                    deniedResult += (deniedList[i]);
                  } else {
                    deniedResult += (deniedList[i] + ', ');
                  }
                }
                let allowFlag = grantList.length === 6 ? true : false;
                let deniedFlag = deniedList.length === 6 ? true : false;
                let result = allowFlag === true ? 
                              <div>
                                <Icon type="check-circle" style={{ color:'green' }}/>
                                {grantResult}
                              </div> : 
                            (deniedFlag === true ? 
                              <div>
                                <Icon type="close-circle" style={{ color:'red' }}/>
                                {deniedResult}
                              </div> :
                            <div>
                              <Icon type="check-circle" style={{ color:'green' }}/>
                                {grantResult + " "} 
                              <Icon type="close-circle" style={{ color:'red' }}/>
                                {deniedResult}
                            </div>
                            )
                return (
                  <div>
                    {result}
                    {/* <Icon type="check-circle" style={{ color:'green' }}/>
                    {grantResult + " "}
                    <Icon type="close-circle" style={{ color:'red' }}/>
                    {deniedResult} */}
                  </div>
                )
            }
        },
      ]
    }
  }

  render() {
    return (
      <div>
          { this.renderModal() }
          <IHSearchTable {...this.getTableProps()}/>
      </div>
    );
  }
}
