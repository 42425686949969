import React from 'react';
import { Row,Col,Spin,message,Popconfirm } from 'antd';
import roleMap from '../constant/roleMap';
import inviteTable from '../constant/inviteTableConfig';
import API from '../API'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { ROLE_NAME } from '../../../lib/constants';

const eventType = {
    'INVITE':'INVITE'
}
const style = {
    avatarStyle:{
        width: 30,
        height: 30,
        border: 'solid 1px',
        borderRadius: '100%',
        padding:5
    }
}
const newRoleMap = {
    'Nurse':'RD',
    'Doctor':'MD',
    'Clinic Medical Assistant':'MA',
    'Medical Assistant':'CA',
    'PROVIDER:Doctor':'MD',
    "PROVIDER:Clinic Medical Assistant":'MA',
    'PROVIDER:Medical Assistant':'CA',
    'PROVIDER:Nurse':'RD',
    [ROLE_NAME.HC]: 'HC',
    [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
}
const InviteNextStepComponent = class extends React.Component{
    constructor(){
        super();
    }
    handleClickInvite = async (currentVisit,userId)=>{
        const { refreshVirtualVisitTeam } = this.props;
        const res = await API.activePatientMutate({
            eventType: eventType["INVITE"],
            visitId: currentVisit.id,
            params: {
                invitee: userId
            }
        }).then(res=> {
                Mixpanel.track('invite', 'provider into the zoom meeting successfully');
                refreshVirtualVisitTeam();
                message.success('Invited');
            }
        )

    }

    renderSyncIcon = ()=>{
        const { refreshVirtualVisitTeam,loading } = this.props;
        if(loading){
            return <Spin style={{ marginLeft:10 }}/>
        }
        return  <img src="/image/refresh_blue.png" width={20} height={20}
                     onClick={()=>refreshVirtualVisitTeam()}
                     style={{ fontSize:20,display:'block',marginLeft:10,cursor:'pointer' }}
                />
    }

    confirmInvitingMDisDiffFormVisit = (currentVisit,userId)=>{
        this.handleClickInvite(currentVisit, userId);
    }

    renderProcess = (currentUserId,currentVisit,userId,process,fullName)=>{
        const { confirmInvitingMDisDiffFormVisit } = this;
        const { isOnline,isBusy,invited,isHost,roleName } = process;
        const { providers } = currentVisit;
        const firstProvider = _.first(providers);
        const providerId = _.get(firstProvider,'id');
        const isDoctor = roleName=='Doctor';
        //if team member is off line,return offline indicator
        let status = '';
        if(!isOnline){
            return <span style={{fontStyle:'italic',color:'#F0666B'}}>Offline</span>
        }
        //if team member is invited;
        if(invited){
            return <span style={{ fontStyle:'italic',display:'flex',alignItems:'center' }}>
                        Inviting<img src={'image/red_phone.png'} style={{height:15,marginLeft:10,}}/>
                   </span>;
        }
        //if team member is hosting
        if(isHost){
            return <span className='hostButton'>Hosting</span>;
        }
        //if team member is online
        //=> isBusy
        //=> isNotBusy
        if(isOnline) {
          const isCurrentUser = currentUserId===userId;
          const isDiffProvide = providerId!=userId;
          if(isDoctor&&isDiffProvide){
              return <Popconfirm onConfirm={()=>confirmInvitingMDisDiffFormVisit(currentVisit,userId)}
                                 title={`Are you sure to invite ${fullName} who is not related to this meeting?`}>
                        <a href="#" disabled={isCurrentUser} className='inviteButton'>Invite</a>
                     </Popconfirm>
          }else{
              return <a onClick={() => this.handleClickInvite(currentVisit, userId)} disabled={isCurrentUser} className='inviteButton'>Invite</a>
          }
        }

    }

    renderVisitInTwoColumns = (getVirtualVisitTeam,currentVisit)=>{
        const currentUserId = sessionStorage.getItem('currentUserId')
        return getVirtualVisitTeam.map((team,index)=>{
            const { roleName,user:{ profile } } = team;
            const userId = _.get(team,'user.id');
            const thumbnail = _.get(profile,'avatar.thumbnail','/image/default_avator.png');
            const fullName = _.get(profile,'fullName');
            const { isOnline,isBusy } = team;
            const className=`avatarWithStatus ${isOnline ? (isBusy ? 'isBusy':'notBusy'):''}`;
            return <Col key={index} span={12} style={{ padding:'10px 10px 0px 10px' }}>
                        <Row style={{display:'flex',marginBottom: 10,marginLeft:10,marginRight:10,justifyContent:'center',alignItems:'center' }}>
                            <Col span={16}>
                                <div className={className}>
                                    <img src={thumbnail} style={style.avatarStyle}/>
                                    <span style={{ marginLeft:10,fontWeight:'bold' }}>{fullName}</span>
                                    <span>{` - ${newRoleMap[roleName]}`}</span>
                                </div>
                            </Col>
                            <Col span={8} style={{ display:'flex',justifyContent:'center',flexDirection:'column',fontSize: 14,alignItems:'flex-start' }}>
                                { this.renderProcess(currentUserId,currentVisit,userId,team,fullName)}
                            </Col>
                        </Row>
                   </Col>
        });
    }

    renderVisitRoleTable = (getVirtualVisitTeam,currentVisit)=>{
        const currentUserId = sessionStorage.getItem('currentUserId')
        return getVirtualVisitTeam.map((team,index)=>{
            const { roleName,user:{ profile } } = team;
            const userId = _.get(team,'user.id');
            const thumbnail = _.get(profile,'avatar.thumbnail','/image/default_avator.png');
            const fullName = _.get(profile,'fullName');
            const { isOnline,isBusy } = team;
            const className=`avatarWithStatus ${isOnline ? (isBusy ? 'isBusy':'notBusy'):''}`;
            return <Row key={index} style={{display:'flex',marginBottom: 10 }}>
                <Col span={18}>
                    <div className={className}>
                        <img src={thumbnail} style={style.avatarStyle}/>
                        <span style={{ marginLeft:10 ,fontWeight:'bold'}}>{fullName}</span>
                        <span>{` - ${newRoleMap[roleName]}`}</span>
                    </div>
                </Col>
                <Col span={6} style={{ display:'flex',justifyContent:'center',flexDirection:'column',fontSize: 14,alignItems:'center' }}>
                    { this.renderProcess(currentUserId,currentVisit,userId,team)}
                </Col>
            </Row>
        });
    }

    groupMemsByRoles=(getVirtualVisitTeam)=>{
        const roleOrderMap = {
            'MA':0,
            'MD':1,
            'CA':2,
            'RD':3,
            'HC':4
        }

        const MDMAList = _.sortBy(_.filter(getVirtualVisitTeam,m=>(newRoleMap[m.roleName]=='MD'||newRoleMap[m.roleName]=='MA')),o=>roleOrderMap[newRoleMap[o.roleName]]);
        const CARDHCList = _.sortBy(_.filter(getVirtualVisitTeam,m=> ['CA', 'RD', 'HC'].includes(newRoleMap[m.roleName])),o=>roleOrderMap[newRoleMap[o.roleName]]);

        return {
            'MA/MD':MDMAList,
            'CA/RD/HC':CARDHCList
        }
    }

    render(){
        const { renderVisitRoleTable,props,renderSyncIcon,renderVisitInTwoColumns,groupMemsByRoles } = this;
        const { getVirtualVisitTeam,currentVisit,twoColumns,loading } = props;
        const memberList = groupMemsByRoles(getVirtualVisitTeam);
        const listGroups = Object.keys(memberList);
        return <div>
                <div style={{backgroundColor:'white',display:'flex',padding:'10px 10px 0px 10px'}}>
                    { renderSyncIcon() }
                    <span style={{ marginLeft:10}}>Refresh to see current status</span>
                </div>
                {/*{*/}
                   {/*!loading &&*/}
                   {/*<Row style={{ backgroundColor: twoColumns&&'white',minHeight:'130',paddingTop:10 }}>*/}
                       {/*<span>MA/MD</span>*/}
                       {/*<div>*/}
                       {/*{*/}
                            {/*twoColumns ? renderVisitInTwoColumns(MDMAList, currentVisit) :*/}
                            {/*renderVisitRoleTable(MDMAList, currentVisit)*/}
                        {/*}*/}
                       {/*</div>*/}
                    {/*</Row>*/}
                {/*}*/}
                {
                    _.map(listGroups, (r,i) => {
                        return !loading &&
                        <Row style={{backgroundColor: twoColumns && 'white', minHeight: '130', paddingTop: 10}} key={i}>
                            <span style={{ marginLeft:20 }}>{r}</span>
                            <div>
                                {
                                    twoColumns ? renderVisitInTwoColumns(memberList[r], currentVisit) :
                                        renderVisitRoleTable(memberList[r], currentVisit)
                                }
                            </div>
                        </Row>
                    })
                }
               </div>
    }
}

export default InviteNextStepComponent
