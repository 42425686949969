// Admin
import I18N from 'modulesAll/I18N';

const menus = {
  Organizations: {
    id: 'organizations',
    title: I18N.get('keywords.menu.organizations'),
    src: '/image/team_mag.png',
    href: '/organizations',
    class:'organizations'
  },
  Staff: {
    id: 'users',
    title: I18N.get('keywords.menu.users'),
    icon: 'peopled',
    href: '/users',
    class:'staff'
  },
  Reports: {
    id: 'reports',
    title: 'Reports',
    href: '/reports',
    src: '/image/reports.svg',
    class: 'reports',
  },
  // ConsentForm: {
  //   id: 'consentform',
  //   title: I18N.get('keywords.menu.consentform'),
  //   icon: 'assessment',
  //   href: '/consentform',
  //   class:'consent-form'
  // }
  // ProgrammesAdmin: {
  //   id: 'programmes_admin',
  //   title: `${I18N.get('keywords.Programs')}`,
  //   //icon: 'programmes',
  //   // icon: 'programs',
  //   src: '/image/programs-nav-icon.svg',
  //   href: '/programmes_admin',
  //   class:'programmes_admin'
  // },
//   Providers: {
//     id: 'providers',
//     title: 'Providers',
//     //icon: 'providers',
//     icon: 'person',
//     href: '/providers',
//     class:'providers'
//   },

//   Careteams: {
//     id: 'careteams',
//     title: 'Care Teams',
//     //icon: 'careteams',
//     icon: 'peopled',
//     href: '/careteams',
//     class:'careteams'
//   },
//   Assessments: {
//     id: 'assessments',
//     title: I18N.get('keywords.Assessments'),
//     // icon: 'assessments',
//     icon: 'assessment',
//     href: '/assessments',
//     class:'assessments'
//   },
//   Surveys: {
//     id: 'surveys',
//     title: 'Surveys',
//     // icon: 'surveys',
//     icon: 'survey',
//     href: '/surveys',
//     class:'surveys'
//   },
//   Dashboards: {
//     id: 'dashboards',
//     title: 'Dashboards',
//     // icon: 'surveys',
//     icon: 'bar-chart',
//     href: '/dashboards',
//     class:'surveys'
//   },
}


const menusArray = [
  menus.Organizations,
  menus.Staff,
  menus.Reports
  // menus.ConsentForm
  // menus.Providers,
  // menus.Careteams,
  // menus.ProgrammesAdmin,
  // menus.Assessments,
  // menus.Surveys
  // menus.Dashboards
]



export default menusArray;
export const HOMEPAGE = '/organizations';
