import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Select, Input, Popover, Button, Form } from 'antd';
import { GROUP_DELIMITER, VALUE_DELIMITER } from '../../constant/nutritionInterpretationConstants';
import { capitalizeFirstChar, parseValue, parseOption, getRawRefId } from '../../helpers/nutritionHelpers';

const EditableForm = props => {
  const { 
    form, inputFieldId, defaultInputValue, 
    inputField, saveBtn, cancelBtn 
  } = props;
  const field = _.startsWith(inputFieldId, 'etiology') ? 'Etiology' : 'Symptom';
  return <React.Fragment>
    <div style={{ flexDirection: 'row' }}> 
      <Form.Item>
        { form.getFieldDecorator(inputFieldId, {
          rules: [{ required: true, message: `${field} is required` }],
          initialValue: defaultInputValue
        })(inputField())}
      </Form.Item>       
    </div>
    <div style={{ marginTop: 10, textAlign: 'center' }}>
      {saveBtn(() => form.getFieldValue(inputFieldId))}
      {cancelBtn()}            
    </div>
  </React.Fragment>
}

const CustomForm = Form.create()(EditableForm);

const closePopover = (optionName, statementIndex, popoverIndex) => {
  // stimulate edit button click to close popover
  $(`button#${optionName}-edit-${statementIndex}-${popoverIndex}`)[0].click();
};

// if option is not sub option, always false; otherwise,
// check if one sub option in the group is selected
// disable the rest of sub options in the same group
const isDisabled = (formValueArr, optRefId) => {
  const [groupRefId, subOptRefId] = optRefId.split(GROUP_DELIMITER);

  // BASE CASE: option is not a sub option
  //if(_.isEmpty(subOptRefId)) return false;

  const getIdTokens = obj => {
    // idToken contains groupId[VALUE_DELIMITER]subOptRefId
    let idToken = obj.split(VALUE_DELIMITER)[0];
    idToken = getRawRefId(idToken);
    // return [groupRefId, subOptRefId]
    return idToken.split(GROUP_DELIMITER);
  }

  // BASE CASE: check if there is group key existing in selected options in formValueArr
  const hasGroupKey = _.findIndex(formValueArr, o => {
    const checkingGroupRefId = getIdTokens(o)[0];
    return groupRefId === checkingGroupRefId;
  });

  // don't disable any sub option if group key is not found
  if(hasGroupKey < 0) return false;

  const subOptIndex = _.findIndex(formValueArr, o => {
    const checkingSubOptRefId = getIdTokens(o)[1];
    // should not disable currently checking sub option in the group
    return subOptRefId === checkingSubOptRefId;
  });

  return subOptIndex < 0;
};

const EditableOption = ({ defaultIndex, ...props }) => {
  const { 
    statementIndex = defaultIndex,
    optGroup = {}, 
    optionName, 
    optObject: { optionId, value: optionValue }, 
    selectedValues,
    onSave,
    disabled
  } = props;
  const { groupId, groupName = '' } = optGroup;

  let value = optionValue;
  if(groupId && !_.isEmpty(groupName)) {
    value = `${groupName.trim()} ${optionValue.trim()}`
  }

  const { optRefId, optDefaultValue } = parseOption({ 
    optionName, statementIndex, groupId, optionId, value 
  });
  // rawOptRefId doesn't include statement index part
  const rawOptRefId = getRawRefId(optRefId);
  const optValue = parseValue({selectedValues, optRefId, optDefaultValue});
  // visibleOptValue is what user would see
  const visibleOptValue = capitalizeFirstChar(optValue.split(VALUE_DELIMITER)[1]);

  const formatValue = valueToFormat => {
    const formatted = [...selectedValues];
    const actualValueIndex = _.findIndex(
      selectedValues, 
      o => getRawRefId(o.split(VALUE_DELIMITER)[0]) === rawOptRefId
    );

    formatted[actualValueIndex] = `${optRefId}${VALUE_DELIMITER}${valueToFormat}`;
    
    return formatted;
  }

  const inputFieldId = `${optionName}-editable-field-${statementIndex}-${optRefId}`;
  return <Select.Option 
    className={`${optionName}-item`} 
    key={optRefId} 
    // value from formProps; otherwise, default value from suboption/template
    value={optValue}
    // only able to select 1 sub option from each group
    disabled={isDisabled(selectedValues, rawOptRefId)}
    render={<div 
      // prevent unexpected focus to select item; onClick won't work properly
      onMouseDown={e => e.stopPropagation()}
    >
      <span className='option-template'>
        {visibleOptValue}
      </span>
      {
        !disabled &&
        <Popover
          id={`pes-editable-popover-${optionName}-${statementIndex}-${rawOptRefId}`}
          destroyPopupOnHide={true}
          trigger='click'
          placement='topRight'
          // TODO: add key down listener for ESC, prevent esc from closing modal
          title={<div 
              id={`${optionName}-hide-field-${statementIndex}-${rawOptRefId}`}
              style={{ whiteSpace: 'pre', width: '100%' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
            {/* 
              SHADOW value, 
              and this is used to maximize popover width when popover is visible
            */}
              {visibleOptValue} 
            </div>
          }
          content={<div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column',
              padding: '12px 16px' 
            }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if(!$(e.target).is('input')) {
                const inputRef = $(`#${optionName}-editable-field-${statementIndex}-${rawOptRefId}`)
                inputRef.trigger('blur');
              }
            }}
          >
            <CustomForm 
                inputFieldId={inputFieldId}
                defaultInputValue={visibleOptValue}
                inputField={() => <Input
                  size='small'
                  autoComplete='off'
                  onClick={e => e.stopPropagation()}
                  onFocus={e => e.stopPropagation()}
                />}
                saveBtn={getValue => <Button
                    size='small'
                    type='primary'
                    style={{ marginRight: 5 }}
                    onClick={e => {
                      try{ 
                        const newValue = getValue();

                        onSave(formatValue(newValue));

                        closePopover(optionName, statementIndex, rawOptRefId);
                      } catch (err) {
                        console.error('Failed to save edited option ', err);
                      }
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                >
                  Save
                </Button>}
                cancelBtn={() => <Button
                  size='small'
                  onClick={e => {
                    closePopover(optionName, statementIndex, rawOptRefId);  
                    //setFieldsValue({ [inputFieldId]: visibleOptValue })
                    e.stopPropagation();
                    e.preventDefault();             
                  }}
                >
                  Cancel
                </Button>}
              />
            </div>
          }
        >
          <Button
            id={`${optionName}-edit-${statementIndex}-${rawOptRefId}`}
            type='link'
            onClick={e => {
              e.stopPropagation();
              // focus to editable input field
              let waitTillClickable = setInterval(() => {
                const popoverInputRef = $(`input#${inputFieldId}`);

                if (popoverInputRef.length) {
                  popoverInputRef.trigger('focus');
                  clearInterval(waitTillClickable);
                }
              }, 100);
            }} 
            icon='edit'
            disabled={disabled}
          />
        </Popover>
      }
    </div>}
  >
    {/* template value */}
    { _.capitalize(optionValue) }
  </Select.Option>
};

EditableOption.propTypes = {
  defaultIndex: PropTypes.number.isRequired, // index from mapping function
  optionName: PropTypes.string.isRequired, // to complete id attr for html elements
  statementIndex: PropTypes.number, // if there are multiple Select, this is index of each Select
  optGroup: PropTypes.object, // consists of groupId and groupName from Select.OptGroup
  optObject: PropTypes.object.isRequired, // object for Select.Option
  selectedValues: PropTypes.array.isRequired, // array of selected values from Select
  onSave: PropTypes.func.isRequired, // to set new value to array of selected values from editable popover
  disabled: PropTypes.bool,
}

export default EditableOption;
