import { constants } from 'libModule/utils';

export default constants('Careteam', 'CareTeamEdit', [
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'UPDATE_FORM',
  'SET_SUBMITTING',
  'SET_SELECT_DOCTORS',
  'SET_SELECT_MANAGERS',
  'SET_PRIMARY_MANAGER',
  'SET_PRIMARY_DOCTOR',
  'CHANGE',
])
