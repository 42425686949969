import gql from 'graphql-tag';
import { ClinicGoalSchema } from './schema/common';

export const GOAL_ASSESSMENT_TYPE = `
  params {
    name
    value
  }
  assessment
`;

export const MONTHLY_REVIEW_CONTENT_TYPE = `
  condition
  vitals {
    vital
    count
  }
  goals {
    ${ClinicGoalSchema}
    goalAssessment {
      ${GOAL_ASSESSMENT_TYPE}
    }
  }
`;

export const getMonthlyReviewContent= gql`
  query getMonthlyReviewContent ($memberId: EID!) {
    getMonthlyReviewContent(memberId: $memberId) {
      ${MONTHLY_REVIEW_CONTENT_TYPE}
    }
  }
`;