import gql from 'graphql-tag';
export default gql`
mutation removeDeviceFromHub($id:EID,$serialNumber:String,$devices:[IoTDeviceInput]){
    removeDeviceFromHub(id:$id,serialNumber:$serialNumber,devices:$devices){
        id
        member{
            profile{
                fullNameWithNickname
            }
        }
        devices{
            id
            type
        }
        serialNumber
    }
}`
