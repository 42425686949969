import React from 'react';
import Client from 'libModule/gqlClient';
import { connect } from 'react-redux';
import formMap from '../constant/formMap';
import helpers, { renderDraftSavedText } from '../helpers';
import I18N from 'modulesAll/I18N';
import {
  giveOutMap, BASE_COUNTER,
  defaultDeviceCount as defaultCount,
  assignedDeviceTitles as titles,
  APP_SYNC, MANUAL,APP_SYNC_FIELDS,
  DEVICE_VITAL_MAP, DEVICE_VITAL_TYPE_MAP, BGDevices,
  userDeviceRecordsTypeName, defaultDeviceRecord,ALL_CHECKBOX_DEVICES
} from '../constant/onboardMap';
import { Form, Row, Col, Button, Modal, message } from 'antd';
import { IHLoading } from 'ihcomponent';
import editVisit from 'graphqlModule/mutation/editVisit';
import HostAndBPMComponent from '../../patient/DevicesAssign/component/hotSpotAndBPMComponent';
import LoanDeviceComponent from '../../patient/loanDevices/components/LoadDeviceComponent';
import API from '../../patient/DevicesAssign/query/API';
import UnassignLoanDevice from './UnassignLoanDevice';
import { updateVisitInventory } from '../helpers/inventoryHelpers';
import vitalHelpers from '../helpers/vitalsHelpers';
import { modalAction } from 'modulesAll/common/actions';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import singleVisit from '../../graphql/singleVisit';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import updateDeviceInputOptions from '../helpers/deviceInputHelper';
import confirmDeviceHelpers from '../../referAndEnroll/helper/confirmDeviceHelpers';
import { compose, defaultProps, withState, withHandlers } from 'recompose';
import { CGMHelperServices } from '../../CGM/helpers';
import { makeCGMContextConsumer } from '../../CGM/context/makeCGMContextConsumer';
import { makeCellularDeviceContextConsumer } from '../../CellularDevices/context/makeCellularDeviceContextConsumer';
import { CellularDevicesHelpers } from '../../CellularDevices/helpers';

const {
  onBoardFormMap,
  editDeviceFormMap,
  giveOutDevicesAndUserProfile
} = formMap;
const { createForm } = helpers;

const fieldName = ['techLevel', 'EMRAttached', 'a1c', CGMHelperServices.brandFieldName];

const checkIfFollowUp = visitType => _.includes(['ADDITIONAL', 'FOLLOW_UP'], visitType);

// deviceList is list of device field names
const getDeviceModeInfo = (oldUserDeviceRecords, newUserDeviceRecords, deviceList) => {
  let hasModeChange = false,
      newDeviceMode = {},
      appSyncToggle = {};

  _.forEach(deviceList, fieldName => {
    const oldMode = oldUserDeviceRecords[fieldName];
    const newMode = newUserDeviceRecords[fieldName];
    if((oldMode === APP_SYNC && newMode !== APP_SYNC) ||
       (oldMode !== APP_SYNC && newMode === APP_SYNC)) {
      appSyncToggle[fieldName] = true;
    }
    if(!_.isEqual(oldMode, newMode)){
      hasModeChange = true;
    }
    newDeviceMode[fieldName] = newMode;
  });

  return {
    newDeviceMode,
    hasModeChange,
    appSyncToggle
  };
}

const parseValue = (props, isComplete = false) => {
  const { form, visit, openErrorModal, cgmContextValue } = props;
  let { id: visitId, type, member, enrolledProgram } = visit;
  const isFollowUp = checkIfFollowUp(type);
  const {
    followUpEditDevices,
    easeAppSync, alignAppSync, linaAppSync, tmAppSync, poAppSync,alignCheckbox,
    Strips, Lancets, iPhone, trackSet: TrackSet,BG5SAppSync,BGAppSync,
    EMRAttached, autoUpdateStatus, addToContactsStatus, appIsUptoDate,
    spokenLanguage, appLanguage, techLevel,BG5S,nexusCheckbox,linaCheckbox,
    phoneData: newPhoneData,
    tsData: newTSData,
    onBoardFinished,
    ...formValues
  } =  form.getFieldsValue();

  const oldUserDeviceRecords = _.omit(_.get(member, 'profile.deviceRecords') || {}, '__typename');
  let newUserDeviceRecords = {...oldUserDeviceRecords};
  const getBGAppSyncStatus = (field)=>{
    if(BGAppSync == MANUAL) {
      return MANUAL;
    }
    if( field =='BG5S' ) return BG5S&&APP_SYNC ||'INVALID';
    if( field =='alignCheckbox')  return alignCheckbox&&APP_SYNC || 'INVALID';
  }
  const getHSAppSyncStatus = (field)=>{
    if(linaAppSync == MANUAL) {
        return MANUAL
    }
    if(field =='linaCheckbox') return linaCheckbox&&APP_SYNC ||'INVALID';
    if(field =='nexusCheckbox') return nexusCheckbox&&APP_SYNC ||'INVALID';
  }

  const updatedUserDeviceRecords = Object.assign({}, {
    Ease: easeAppSync || 'INVALID',
    Align: getBGAppSyncStatus('alignCheckbox'),
    Lina: getHSAppSyncStatus('linaCheckbox'),
    Nexus: getHSAppSyncStatus('nexusCheckbox'),
    BG5S:  getBGAppSyncStatus('BG5S'),
    Thermometer: tmAppSync || 'INVALID',
    Oximeter: poAppSync || 'INVALID',
    Lancets: alignCheckbox || BG5S ? (Lancets || 0) : 0,
    Strips:  alignCheckbox || BG5S ? (Strips || 0) : 0,
    iPhone, TrackSet,
  });
  newUserDeviceRecords = followUpEditDevices ?
    {...updatedUserDeviceRecords} : {...newUserDeviceRecords, ...updatedUserDeviceRecords};


  let variables = {
    id: visitId,
    EMRAttached,
    A1C: null,
    remoteTask: typeof onBoardFinished=='boolean' ? { onBoardFinished } : undefined,
    userLanguage: spokenLanguage,
    userAppLanguage: appLanguage,
    userTechLevel: techLevel,
    autoUpdateStatus: autoUpdateStatus ? 'COMPLETED' : 'PENDING',
    addToContactsStatus: addToContactsStatus ? 'COMPLETED' : 'PENDING',
    appIsUptoDate: appIsUptoDate ? 'COMPLETED' : 'PENDING',
    TechOnBoard:onBoardFinished,
    userDeviceRecords: _.omitBy(newUserDeviceRecords, _.isNull) // prevent null values
  };

  // SC-4683 Thong - remove userDeviceRecords if it is empty
  if(_.isEmpty(variables.userDeviceRecords)) {
    variables = _.omit(variables, 'userDeviceRecords');
  }

  let newDeviceRecordCount = _.omit(_.get(visit, 'deviceRecordCount'), '__typename');
  newDeviceRecordCount = {...defaultCount, ...newDeviceRecordCount};
  const ignoreList = ['Lancets', 'Strips', 'iPhone', 'TrackSet', '__typename'];

  // update phone and Track set/ Clear count
  const oldPhoneCount = _.get(newDeviceRecordCount, 'iPhone') || 0;
  const oldTSCount = _.get(newDeviceRecordCount, 'TrackSet') || 0;
  const { phoneCount: newPhoneCount, phoneChange } = newPhoneData;
  const { tsCount: newTSCount, tsChange } = newTSData;
  const { iPhone: hadPhone = false, TrackSet: hadTS = false  } = oldUserDeviceRecords;

  // check count to prevent multiple updates
  const shouldUpdatePhoneCount = !_.isEqual(newPhoneCount, ~~hadPhone) && phoneChange;
  newDeviceRecordCount.iPhone =
    shouldUpdatePhoneCount ? oldPhoneCount + (newPhoneCount - ~~hadPhone) : oldPhoneCount;

  const shouldUpdateTS = ~~(!_.isEqual(newTSCount, ~~hadTS) && tsChange);
  newDeviceRecordCount.TrackSet =
    shouldUpdateTS ? oldTSCount + (newTSCount - ~~hadTS) : oldTSCount;

  const verifyAndAddRecordCount = newDeviceRecordCount => {
    // ignore these devices/ supplies
    _.forEach(_.keys(newUserDeviceRecords), key => {
      if(
        !_.includes(ignoreList, key) &&
          ((oldUserDeviceRecords[key] === 'APP_SYNC' && newUserDeviceRecords[key] !== 'APP_SYNC') ||
            (oldUserDeviceRecords[key] !== 'APP_SYNC' && newUserDeviceRecords[key] === 'APP_SYNC'))
      ){
        const baseCounter = followUpEditDevices ? BASE_COUNTER : 1;
        newDeviceRecordCount[key] +=
          newUserDeviceRecords[key] === 'APP_SYNC' ? baseCounter : -baseCounter;
      }
    });
  }
  if(isFollowUp && !followUpEditDevices) {
  //--- When Save/Complete in FollowUpVisit
    // sync userDeviceRecords with device assignment in Patient Profile
    variables.userDeviceRecords= {
      ..._.omitBy(oldUserDeviceRecords, _.isNull),
      iPhone: !!newPhoneCount,
      TrackSet: !!newTSCount
    };

    // add count for additional devices/ supplies
    _.forEach(_.keys(giveOutMap), key => {
      let count = newDeviceRecordCount[key] || 0;
      // previousGiveOutCount
      // is to prevent from adding extra count for additional devices when re-select a selection
      const previousGiveOutCount = count % BASE_COUNTER;
      const previousCheckboxValue = !!previousGiveOutCount;
      // possible values for Checkbox: true, false, undefined, count
      const newCheckboxValue = !!_.get(formValues, giveOutMap[key]);
      if(!_.isEqual(previousCheckboxValue, newCheckboxValue)){
        count += newCheckboxValue ? 1 : -1;
      }
      newDeviceRecordCount = {
        ...newDeviceRecordCount,
        [key]: count
      };
    });

    newDeviceRecordCount = {
      ...newDeviceRecordCount,
      Lancets: +_.get(formValues, 'giveOutLancets') || 0,
      Strips: +_.get(formValues, 'giveOutStrips') || 0,
    };
  } else if (followUpEditDevices || !isFollowUp) {
  //--- When Save/Complete with saveDevices() or visit is not follow up
    const retainLancetsAndStripsCount = () => {
      const userDeviceRecordsVar = variables.userDeviceRecords;
      if(!_.isEmpty(userDeviceRecordsVar)){
        variables.userDeviceRecords = {
          ...userDeviceRecordsVar,
          // no selection in edit CurrentDevices, use previous count or 0
          Lancets: _.get(oldUserDeviceRecords, 'Lancets') || 0,
          Strips: _.get(oldUserDeviceRecords, 'Strips') || 0,
        }
      }
    }

    // --Step 1: When Save/Complete with saveDevices()
    if(followUpEditDevices){
      // clean up unnecessary variables
      variables = _.omit(variables, ['autoUpdateStatus', 'addToContactsStatus', 'appIsUptoDate']);
      // there is no selection for Lancets or Strips
      retainLancetsAndStripsCount();
    }

    // --Step 2: When visit is not follow up
    // update/ correct Lancets and Strips for
    if(!isFollowUp) {
      // Note for Thong: verifyAndAddRecordCount should be Step 2. check Branch SC-5367
      // but moved here to keep inventory logic for FollowUp visit
      verifyAndAddRecordCount(newDeviceRecordCount);

      const { newDeviceMode, hasModeChange, appSyncToggle } = getDeviceModeInfo(oldUserDeviceRecords, newUserDeviceRecords, BGDevices);

      const hasAppSyncToggle = !!_.filter(appSyncToggle, v => !!v).length;
      const isCurModeAppSync = !!_.filter(newDeviceMode, v => v === APP_SYNC).length;

      const { Lancets: oldLancets = 0, Strips: oldStrips = 0 } = oldUserDeviceRecords;
      const { Lancets: newLancets = 0, Strips: newStrips = 0 } = newUserDeviceRecords; // of user
      const { Lancets: lancetCount = 0, Strips: stripCount = 0 } = newDeviceRecordCount; // of visit

      const updateLancetsStrips = () => {
        newDeviceRecordCount.Lancets = lancetCount + (newLancets - oldLancets);
        newDeviceRecordCount.Strips = stripCount + (newStrips - oldStrips);
      }

      // check MODE change to prevent wrong update
      if(!hasModeChange) {
        // MODE is the same
        if(!_.isEqual(oldLancets, newLancets) || !_.isEqual(oldStrips, newStrips)){
          // update ONLY lancets and strips IFF BG device mode is in APP_SYNC
          if(isCurModeAppSync) {
            updateLancetsStrips();
          }
        } else {
          newDeviceRecordCount.Lancets = _.get(visit, 'deviceRecordCount.Lancets') || 0;
          newDeviceRecordCount.Strips = _.get(visit, 'deviceRecordCount.Strips') || 0;
          retainLancetsAndStripsCount();
        }
      } else if(hasAppSyncToggle){
        // MODE changes from AppSync to non-AppSync => add/subtract counts
        updateLancetsStrips();
      }
    }
  }

  _.set(variables, 'deviceRecordCount', _.omitBy(_.omit(newDeviceRecordCount, '__typename'), _.isNull));

  // START processing and updating inventory
  // IFF hitting Complete button and visit is offline/In-clinic
  if(isComplete && _.isEqual(visit.category, 'INCLINIC')){
    const updatedInventoryCount = updateVisitInventory(visit, variables);
    variables.inventoryCount = updatedInventoryCount;
  }

  if(!isFollowUp || followUpEditDevices) {
    let vitalList = _.uniq(_.map(_.keys(variables.userDeviceRecords), k => {
      const f = DEVICE_VITAL_MAP[k];
      const s = variables.userDeviceRecords[k];
      return f ? f(s) : null;
    }));
    vitalList = _.filter(vitalList, v => !_.isNull(v));
    // add task to enrolled program
    vitalHelpers.handleAddTasks(enrolledProgram, vitalList, openErrorModal);
  }

  // sync measurement input options
  updateDeviceInputOptions(variables.userDeviceRecords, enrolledProgram, props);

  CGMHelperServices.saveBrandValue(form, _.get(member, 'id'))
    .then((res) => {
      if (res && res.code === 200) {
        cgmContextValue.refetch();
      }
    });

  return variables;
};
let OnBoardComponent = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleType: '',
      followUpEditDevices: false,
      phoneChange: false, // during Onboard session
      tsChange: false, // during Onboard session,
    };

    this.assignedTrackSet = undefined,
    this.assignedPhone = undefined,
    this.trackSetInfo = { deviceId: 0 },
    this.phoneInfo = {
      deviceId: 0,
      serialNumber: 'N/A',
      version: 'N/A'
    };
    this.easeCheckbox = false;
  }

  saveEdit = (flag) => {
    const { followUpEditDevices } = this.state;
    const refetchQueries = flag ? null : [{
      query: singleVisit,
      variables: { id: _.get(this, 'props.id') },
      fetchPolicy: 'network-only'
    }];
    Client.mutate({
      mutation: editVisit,
      variables: parseValue(
        Object.assign({ followUpEditDevices }, this.props),
        flag
      ),
      refetchQueries,
      fetchPolicy: 'no-cache'
    }).then(res => {
      flag ? this.props.afterSubmitSucc() : this.props.afterSaveDraftSucc();
      this.setState({
        followUpEditDevices: false
      });
    });
  };

  // devicesFormWithValue = ()=>{
  //   const  { form,setHaveDeviceValues } = this.props;
  //   const checkboxValues  = form.getFieldsValue(Object.keys(DEVICE_VITAL_TYPE_MAP));
  //   const radioValues = form.getFieldsValue(APP_SYNC_FIELDS);
  //   let hasVal = false;
  //   for(const [key,value] of Object.entries(checkboxValues)) {
  //     if(value) {
  //       hasVal = true;
  //     }
  //   }
  //   for(const [key,value] of Object.entries(radioValues)) {
  //     if (value == "MANUAL_INPUT") {
  //       hasVal = true;
  //     }
  //   }
  //   setHaveDeviceValues(hasVal);
  //   return hasVal;
  // }

  handleOnClick = () => {
    const { form, title, submitButtonText,devicesFormWithValue} = this.props;
    let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
    Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
    form.validateFieldsAndScroll(fieldName, (error, fields) => {
      let hasDeviceValues = devicesFormWithValue(this.props,form.getFieldsValue());
      if (hasDeviceValues && !error) {
        this.saveEdit(true);
      }
    });
  };

  saveDevices = () => {
    const { form,devicesFormWithValue } = this.props;
    let hasDeviceValues = devicesFormWithValue(this.props,form.getFieldsValue());
    form.validateFieldsAndScroll((error, fields) => {
      if (hasDeviceValues && !error) {
        this.saveEdit(false);
      }
    });
  };

  handleOnClickSaveDraft = () => this.saveEdit(false);

  syncDeviceInputOption = (key, deviceRecord) => {
    const previousSelection = _.get(deviceRecord, key);
    if(previousSelection && previousSelection !== 'INVALID')
      return previousSelection;
    
    // from MA/MD referral
    const patientReferral = _.get(this, 'props.visit.patientRefer');
    const referredDeviceList = confirmDeviceHelpers.getOnlyDeviceList(patientReferral);
    if(_.includes(referredDeviceList, userDeviceRecordsTypeName[key]))
      return APP_SYNC;

    return previousSelection;
  }

  render() {
    const { handleOnClick, handleOnClickSaveDraft, saveDevices } = this;
    const {
      loadingIoTDeviceData, loadingLoanDeviceData,
      refetchIoTDeviceData, refetchLoanDeviceData,
      currentProgram, visit,
      form, isEditMode,
      iotDeviceData, loanDeviceData,
      phoneCount, tsCount, disabled
    } = this.props;

    if (loadingIoTDeviceData || loadingLoanDeviceData) {
      return <IHLoading />;
    }

    const { getFieldValue, getFieldsValue, setFieldsValue } = form;
    const { followUpEditDevices, phoneChange, tsChange } = this.state;
    const deviceRecord = _.get(visit, 'member.profile.deviceRecords') || {};
    const { haveDeviceValues } = this.props;
    // sync input option
    const mirrorDeviceRecord = !_.isEmpty(deviceRecord) ? _.clone(deviceRecord) : defaultDeviceRecord;
    for(let k in mirrorDeviceRecord) {
      const deviceInputOption = this.syncDeviceInputOption(k, mirrorDeviceRecord);
      _.set(mirrorDeviceRecord, k, deviceInputOption);
    }
    const { Ease, Align, Lina, Thermometer, Oximeter, BG5S, Nexus } = mirrorDeviceRecord;
    
    const { easeCheckbox, alignCheckbox, linaCheckbox, tmCheckbox, poCheckbox } = getFieldsValue();
    const visitType = _.get(visit,'type');
    const isFollowUp = checkIfFollowUp(visitType);
    const tasks = _.get(currentProgram, 'tasks', []);

    let hasBP = Ease === APP_SYNC || !!_.find(tasks, {type: 'BP'});
    let hasBG = (Align === APP_SYNC || BG5S === APP_SYNC) || !!_.find(tasks, {type: 'BG'});
    let hasHS = (Lina === APP_SYNC || Nexus ===APP_SYNC ) || !!_.find(tasks, {type: 'HS'});
    let hasTM = Thermometer === APP_SYNC || !!_.find(tasks, {type: 'TM'});
    let hasPO = Oximeter === APP_SYNC || !!_.find(tasks, {type: 'PO'});
    let hasAlign = Align === APP_SYNC;
    let hasLina = Lina === APP_SYNC;
    let bpChecked = !Ease
      ? hasBP
      : easeCheckbox !== undefined
        ? easeCheckbox
        : Ease === 'APP_SYNC';
    let bgChecked = !Align
      ? hasBG
      : alignCheckbox !== undefined
        ? alignCheckbox
        : (Align === 'APP_SYNC');
    let hsChecked = !Lina
      ? hasHS
      : linaCheckbox !== undefined
        ? linaCheckbox
        : Lina === 'APP_SYNC';
    let tmChecked = !Thermometer
      ? hasTM
      : tmCheckbox !== undefined
        ? tmCheckbox
        : Thermometer === 'APP_SYNC';
    let poChecked = !Ease
      ? hasPO
      : poCheckbox !== undefined
        ? poCheckbox
        : Oximeter === 'APP_SYNC';

    // set default value
    _.set(this, 'assignedTrackSet', false);
    _.set(this, 'assignedPhone', false);
    if (!!tsCount && !_.get(this, 'assignedTrackSet')) {
      _.set(this, 'assignedTrackSet', true);
      _.set(this, 'trackSetInfo', { deviceId: iotDeviceData[0].deviceId });
    }
    if (!!phoneCount && !_.get(this, 'assignedPhone')) {
      _.set(this, 'assignedPhone', true);
      _.set(this, 'phoneInfo', {
        deviceId: loanDeviceData.deviceId,
        serialNumber: loanDeviceData.serialNumber,
        version: loanDeviceData.appVersion
      });
    }

    const formMapFn =
      isFollowUp && followUpEditDevices
        ? editDeviceFormMap
        : isFollowUp
        ? giveOutDevicesAndUserProfile
        : onBoardFormMap;

    const handleCheck = (type, e) => {
      // if device with given type is assigned, prompt user to unassign before unchecking
      const assignedType =
        type === 'trackSet' ? 'assignedTrackSet' : 'assignedPhone';
      if (_.get(this, `${assignedType}`)) {
        return this.setState({ visible: true, visibleType: type }, () => {
          // keep checkbox checked
          setFieldsValue({
            [type]: true
          });
        });
      }
      this.setState({ visible: true, visibleType: type });
    };

    const openModal = type =>
      this.setState({ visible: true, visibleType: type });

    const onClose = () => {
      const type =
        this.state.visibleType === 'trackSet' ? 'trackSet' : 'iPhone';
      const assignedType =
        this.state.visibleType === 'trackSet'
          ? 'assignedTrackSet'
          : 'assignedPhone';
      setFieldsValue({
        [type]: _.get(this, assignedType)
      });
      this.setState({ visible: false, visibleType: '' });
    };

    const onUnassignIoT = (variables,userId, cb) => {
      vitalHelpers.onboardRemoveTaskConfirm({
        ...this.props,
        ...visit,
        vitalType: DEVICE_VITAL_TYPE_MAP['trackSet']
      }).then(isConfirmed => {
        if(isConfirmed) {
          API.removeIotDeviceMemberAPI(variables, userId)
          .then(() => {
            message.success('Device Unassigned');
            if(cb) cb();
          });
        }
      });
    };

    const setHostDeviceAssignment = isAssigned => {
      if (!isAssigned) {
        setFieldsValue({
          // easeCheckbox: _.get(this, 'easeCheckbox'),
          // easeAppSync: _.get(this, 'easeCheckbox')
          //   ? 'APP_SYNC'
          //   : 'MANUAL_INPUT',
          trackSet: false
        });
        refetchIoTDeviceData();
      } else {
        _.set(this, 'easeCheckbox', getFieldValue('easeCheckbox'));
        setFieldsValue({
          easeCheckbox: false,
          easeAppSync: null,
          trackSet: true
        });
      }
      const stayOn = isAssigned === true;
      this.setState({
        visible: stayOn, visibleType: stayOn ? 'trackSet' : '', tsChange: true
      });
    };

    const setLoanDeviceAssignment = isAssigned => {
      // use manually setFieldsValue here to make sure iPhone checkbox works correctly
      refetchLoanDeviceData();
      this.setState({ visible: false, visibleType: '', phoneChange: true }, () => {
        setFieldsValue({ iPhone: isAssigned });
      });
    };

    const updateTrackSetConfig = () => {
      refetchIoTDeviceData();
      onClose();
    };

    let newThis = Object.assign({}, this);
    const title = this.props.title || 'Onboarding';
    return (
      <div>
        <Row>
          {isFollowUp && followUpEditDevices ? (
            <div className='fix-header'>
              <Col span={24}>
                <a
                  onClick={() => this.setState({ followUpEditDevices: false })}
                  style={{ fontSize: 18, color: '#555555' }}
                >{`< Back`}</a>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 10 }}>
                <h4>Edit Devices</h4>
              </Col>
            </div>
          ) : (
            <div className='fix-header charting-header'>
              <div className='fixHeaderTitle'>
                {title}
                {renderDraftSavedText()}
              </div>
            </div>
          )}
          <Col span={24} className={`scrollable-content ${followUpEditDevices ? 'edit-devices' : ''} ${ haveDeviceValues ? '' :'hasError' }`}>

            {createForm(
              newThis,
              formMapFn({
                localThis: this, hasBP, hasBG, hasHS, bpChecked, bgChecked, hsChecked,hasAlign,hasLina,
                checkedTS: _.get(this, 'assignedTrackSet'),
                alignValue: Align,
                linaValue: Lina,
                nexusValue:Nexus,
                checkedIP: _.get(this, 'assignedPhone'),
                BG5SValue: BG5S,
                hasTM, hasPO, tmChecked, poChecked,haveDeviceValues
              }).concat([[
                // shadow fields
                {
                  key: 'tsData',
                  label: '',
                  initialData: { tsCount, tsChange },
                  render: () => <div style={{ display: 'none'}}></div>
                },
                {
                  key: 'phoneData',
                  label: '',
                  initialData: { phoneCount, phoneChange },
                  render: () => <div style={{ display: 'none'}}></div>
                },
                {
                  key: 'followUpEditDevices',
                  label: '',
                  initialData: followUpEditDevices,
                  render: () => <div style={{ display: 'none'}}></div>
                }
              ]]),
              'onBoardForm',
              handleCheck,
              openModal,
              _.get(this, 'phoneInfo')
            )}

          <div style={{width:'400px', marginTop: '10px'}}>
            {I18N.get('deviceInput.message')}
            {I18N.get('deviceInput.inventoryMessage')}
          </div>
            {/*{isFollowUp ?  <a onClick={()=>this.setState({ followUpEditDevices : true })}>Edit Configuration</a> : ''}*/}
          </Col>
        </Row>
        <Modal
          visible={this.state.visible}
          onCancel={onClose}
          destroyOnClose={true}
          footer={null}
          width={360}
        >
          {titles[this.state.visibleType] && (
            <div style={{ marginTop: 25, paddingLeft: 9 }}>
              <h4>{titles[this.state.visibleType].title}</h4>
              {_.get(this, [
                `assigned${
                  this.state.visibleType === 'trackSet' ? 'TrackSet' : 'Phone'
                }`
              ]) && <small>{titles[this.state.visibleType].note}</small>}
            </div>
          )}
          <div
            style={{
              marginTop: 10,
              padding: this.state.visibleType === 'iPhone' ? 10 : 0
            }}
          >
            {this.state.visibleType === 'trackSet' ? (
              <HostAndBPMComponent
                {...this.props}
                forModal={true}
                state={{ clearInfo: { disabled: false } }} // mimic behavior of patient vitals dashboard
                updateIoTDeviceMemberAPI={API.updateIoTDeviceMemberAPI}
                editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
                removeIotDeviceMemberAPI={API.removeIotDeviceMemberAPI}
                onUnassignIoT={onUnassignIoT}
                setAssignment={setHostDeviceAssignment}
                isAssigned={_.get(this, 'assignedTrackSet')}
                deviceId={_.get(this, 'trackSetInfo.deviceId')}
                iotDeviceList={iotDeviceData}
                updateTrackSetConfig={updateTrackSetConfig}
              />
            ) : _.get(this, 'assignedPhone') ? (
              <UnassignLoanDevice
                {...this.props}
                phoneInfo={_.get(this, 'phoneInfo')}
                setAssignment={setLoanDeviceAssignment}
              />
            ) : (
              <LoanDeviceComponent
                {...this.props}
                state={{ loanDeviceInfo: { disabled: false } }} // mimic behavior of patient vitals dashboard
                forModal={true}
                setAssignment={setLoanDeviceAssignment}
              />
            )}
          </div>
        </Modal>
        <Row>
          {!followUpEditDevices ? (
            <Col className='fix-footer' style={{ float: 'right' }}>
              {isEditMode ? (
                <Button
                  onClick={() => handleOnClickSaveDraft()}
                  disabled={disabled}
                  className='saveDraftBtn'
                >
                  Save Draft
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={() => handleOnClick()}
                type={'primary'}
                disabled={disabled}
              >
                {this.props.submitButtonText || 'Complete'}
              </Button>
            </Col>
          ) : (
            ''
          )}
          {followUpEditDevices ? (
            <Col style={{paddingTop: 10}}>
              <Button
                onClick={() => saveDevices()}
                type={'primary'}
                style={{ width: 130, marginRight: 10 }}
                disabled={disabled}
              >
                Save
              </Button>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }
};

const mapToDispatch = (dispatch)=>({
  openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
  openErrorModal:(e)=>dispatch(openErrorModal(e))
});

let EnhancedComponent = connect(null, mapToDispatch)(OnBoardComponent);
// EnhancedComponent = compose(
//     withState('haveDeviceValues','setHaveDeviceValues',true),
//     withHandlers({
//       devicesFormWithValue:(props)=>()=>{
//         const  { form,setHaveDeviceValues } = props;
//           const checkboxValues  = form.getFieldsValue(Object.keys(DEVICE_VITAL_TYPE_MAP));
//           const radioValues = form.getFieldsValue(APP_SYNC_FIELDS);
//           let hasVal = false;
//           for(const [key,value] of Object.entries(checkboxValues)) {
//             if(value) {
//               hasVal = true;
//             }
//           }
//           for(const [key,value] of Object.entries(radioValues)) {
//             if (value == "MANUAL_INPUT") {
//               hasVal = true;
//             }
//           }
//           setHaveDeviceValues(hasVal);
//           return hasVal;
//       }
//     })
// )(EnhancedComponent);
EnhancedComponent =  Form.create({
  onValuesChange: (props,newValues,allValues) => {
    const { childProps, setChildProps, onTouch, id: visitId,devicesFormWithValue } = props;
    if (!childProps) {
      let refetchQueries = [{
          query: singleVisit,
          variables: { id: visitId },
          fetchPolicy: 'network-only'
      }];
      setChildProps(_.omit(props, 'childProps'), parseValue, editVisit, refetchQueries, 'On Board');
    }
    if(onTouch) {
      devicesFormWithValue(props,allValues);
      onTouch();
    }
  }
})(EnhancedComponent);
export default EnhancedComponent = compose(
    withState('haveDeviceValues','setHaveDeviceValues',true),
    withHandlers({
      devicesFormWithValue:(props)=>(fromProps,allValues)=>{
        const  { form,setHaveDeviceValues } = fromProps;
        const checkboxValues  = _.pick(allValues,Object.keys(ALL_CHECKBOX_DEVICES));
        const radioValues = _.pick(allValues,APP_SYNC_FIELDS);
        let hasVal = false;
        if(_.isEmpty(checkboxValues) && _.isEmpty(radioValues)) {
          hasVal = true;
        }
        for(const [key,value] of Object.entries(checkboxValues)) {
          if(value) {
            hasVal = true;
          }
        }
        for(const [key,value] of Object.entries(radioValues)) {
          if (value == "MANUAL_INPUT") {
            hasVal = true;
          }
        }
        // check for CGM and cellular Devices
        if (
          !!allValues[CGMHelperServices.brandFieldName]
          || allValues[CellularDevicesHelpers.BPCellularCheckboxFieldName] === true
          || allValues[CellularDevicesHelpers.BGCellularCheckboxFieldName] === true
        ) {
          hasVal = true;
        }
        if(setHaveDeviceValues) {
          setHaveDeviceValues(hasVal);
        }
        return hasVal;
      }
    })
)(
  makeCGMContextConsumer(makeCellularDeviceContextConsumer(EnhancedComponent))
);