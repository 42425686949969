import React from 'react';
import AutoCompleteComponent from "../../patientList/main/components/AutoCompleteComponent";
import autoCompleteHelper from "../helper/autocomplete";
import { Modal } from 'antd';
import SingleRemoteEnrollmentContainer from '../container/SingleRemoteEnrollmentContainer';
const searchPatientComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            searchStr: '',
            canSearch: false,
            showModal: false,
            memberId: null,
        }
        this.startSearch = _.debounce(this.startSearch, 500);
    }
    onSearchChange = searchStr => {
      const prevSearchStr = _.trim(this.state.searchStr);
      const newSearchStr = _.trim(searchStr);
      const canSearch = prevSearchStr !== newSearchStr;
      this.setState({ searchStr, canSearch: false }, () => {
          this.startSearch(canSearch);
      });
    };


    startSearch = (canSearch) => {
      this.setState({ canSearch });
    }

    render(){
        const localThis = this;
        return  <div>
                    <AutoCompleteComponent  {...autoCompleteHelper.getAutoCompleteProps(localThis)} />
                </div>

    }
}

export default searchPatientComponent;