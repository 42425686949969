import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getParameterByName } from 'libModule/utils';

class OnboardVerificationComponent extends Component {
  static displayName = 'onboard/components/OnboardVerificationComponent';

  constructor(props) {
    super(props);
    this.verifyToken = this.verifyToken.bind(this);
    this.state = { emailVerificationResp: false, invalidToken: false };
  }

  componentWillMount() {
    const token = getParameterByName('token');
    sessionStorage.setItem('onboardToken', token);
    this.verifyToken()
      .then( res => {
        if (res && res.data.verifyOnBoardToken) {
          this.setState({ emailVerificationResp: true, invalidToken: false });
        }
      })
      .catch(err => {
        console.log('err verifying token: ', err);
        this.setState({ emailVerificationResp: false, invalidToken: true });
      })
  }

  componentWillUnmount() {
    this.setState({ emailVerificationResp: false });
  }

  async verifyToken() {
    const { verifyOnBoardToken } = this.props;
    const token = sessionStorage.getItem('onboardToken');
    try {
      return await verifyOnBoardToken({ variables: { token }});
    } catch (e) {
      console.log('Error! [verifyOnBoardToken]: ', e);
      this.setState({ emailVerificationResp: false, invalidToken: true });
    }
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        <h1 style={{ textAlign: 'center' }}>
        {
          this.state.emailVerificationResp ? 'Your e-mail has been verified!'
          :
            this.state.invalidToken ? 'This link has expired. Please contact iHealth to receive a new link.' : null
        }
        </h1>
      </div>
    );
  }
}

OnboardVerificationComponent.propTypes = {
  verifyOnBoardToken: PropTypes.func
}

export default OnboardVerificationComponent;