import React from 'react';
import LogBookContainer from './LogBookContainer';
import moment from "moment/moment";
import Mixpanel from '../../mixPanel/mixPanel';

const LogBookContainerWrapper = class extends React.Component {
    static  displayName = 'LogBookContainerWrapper';
    constructor(){
        super();
        this.state = {
            toDate:moment().endOf('day'),
            fromDate:moment().subtract(6,'days').startOf('day'),
            timeInterval:'w',
            value:1
        }
    }

    changePre = ()=>{
        const { fromDate, toDate,timeInterval,value } = this.state;
        const updatedFromDate = moment(fromDate).subtract(value,timeInterval).startOf('day');
        const updatedToDate = toDate.subtract(value,timeInterval).endOf('day');
        const { patientId,type } = this.props;
        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate
        },()=>{
            Mixpanel.track('clicked',`BACK_${type}_LOG`,null,{ PATIENT_ID:patientId });
        })
    }

    changeNext = ()=>{
        const { fromDate, toDate,timeInterval,value } = this.state;
        const updatedFromDate = moment(fromDate).add(value,timeInterval).startOf('day');
        const updatedToDate = toDate.add(value,timeInterval).endOf('day');
        const { patientId,type } = this.props;
        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate
        },()=>{
            Mixpanel.track('clicked',`NEXT_${type}_LOG`,null,{ PATIENT_ID:patientId });
        })
    }

    setTimeInterval = (updatedTimeInterval,updatedValue)=>{
        const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
        const updatedToDate = moment().endOf('day');
        const { patientId,type } = this.props;

        this.setState({
            fromDate:updatedFromDate,
            toDate: updatedToDate,
            timeInterval: updatedTimeInterval,
            value: updatedValue
        },()=>{
            const str = (updatedValue==1 ? 'ONE' : 'TWO') +'_'+(updatedTimeInterval=='M' ? 'MONTH' : 'WEEK');
            Mixpanel.track('clicked',str+`_${type}_LOG`,null,{ PATIENT_ID: patientId });
        })
    }

    render(){
        const { state } = this;
        const { props } = this;
        const { fromDate,toDate,value,timeInterval } = state;

        return <LogBookContainer changePre={()=>this.changePre()}
                                 changeNext={()=>this.changeNext()}
                                 setTimeInterval={(i,v)=>this.setTimeInterval(i,v)}
                                 fromDate={ fromDate.toDate() }
                                 toDate = { toDate.toDate() }
                                 value = { value }
                                 timeInterval = { timeInterval }
                                 { ...props }

        />
    }
}



export default LogBookContainerWrapper;
