import React from 'react';
import RenderFormComponent from '../../AdditionalInfo/Components/RenderFormComponent';
import {Button} from 'antd';

const HealthConditionsContainer = class extends React.Component{

    constructor(props){
        super(props)
    }

    renderSaveButton(){
        const { isEditMode, onSave } = this.props;
        return <Button  type='primary'
                        onClick={onSave}
                        disabled={!isEditMode}
                        className='health-condition-btn'>
                    Save
                 </Button>

    }

    render(){
        const { user, form, setEditMode, isEditMode } = this.props;
        return(<div className='health-condition-container'>
                    <RenderFormComponent user={user}
                                         renderPicker={true}
                                         form={form}
                                         isEditMode={isEditMode}
                                         handleEnableEditMode={setEditMode}/>
                        {this.renderSaveButton()}
                </div>)
    }
}

export default HealthConditionsContainer;