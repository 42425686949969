import React from 'react';
import getUserOrg from '../helper/getUserOrg';
import moment from 'moment-timezone';
const TimeZoneNavComponent = class extends React.Component{
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            time: '',
        };
    }

    componentDidMount() {
        this.updateEveryMinute();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    getTime = () => {
        const { timezone } = getUserOrg();
        const remote = moment().tz(timezone);
        return { time: remote.format('hh:mm A'), seconds: +remote.format('s')};
    }

    updateEveryMinute = () => {
        const { time, seconds } = this.getTime();
        this.setState({ time }, () => {
            this.timer = setTimeout(() => {
                this.updateEveryMinute();
            }, (60 - seconds) * 1000);
        });
    }

    render(){
        const { timezone } = getUserOrg();
        // let timezone = 'America/New_York';
        let localTZ = moment.tz.guess();
        let local = moment.tz(localTZ);
        let remote = local.clone();
        let local_offset = local.zone();
        let remote_offset = remote.tz(timezone).zone();
        let isAhead = remote_offset - local_offset < 0;
        let noDifference = (remote_offset - local_offset) === 0;
        let remoteZoneAbbr = moment.tz.zone(timezone).abbr(new Date());
        let sign = noDifference ? '' : (isAhead ? '+' :'-');
        let diffInHours = Math.abs(local_offset-remote_offset)/60;
        return <div className='timezoneContainer' style={{ display:'inline-block',marginRight: 10 }}>
                <div style={{ display:'flex'}}>
                    <span>{`${ remoteZoneAbbr } (${sign ? `${sign} ` : ''}${ diffInHours }HRS): `}</span>
                    <span style={{ fontWeight:'bold',marginLeft:5 }}>{this.state.time}</span>
                </div>
               </div>
    }
}

export default TimeZoneNavComponent;