import React from "react";
import iotDeviceList from '../query/iotDeviceList';
import {compose, graphql} from "react-apollo/index";
import DeviceConfigUpdateComponent from './deviceConfigUpdateComponent';
import { Form,Row,Col,Button,Progress,Modal,Tooltip } from 'antd';
import { message } from 'ihcomponent';
import { GQLHelper } from 'libModule/utils';
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import moment from 'moment';

let  RenderAndUpdateDevice = class extends React.Component {
    constructor(){
        super();
    }

    renderDeviceInfo(iotDeviceList){
        const groupedDevice = _.groupBy(iotDeviceList,'deviceId');
        const devices = Object.keys(groupedDevice);
        const { props } = this;

        return _.map(devices,(d,i)=> {
        const deviceObj = _.first(groupedDevice[d]);
        const config = _.get(deviceObj,'config')||{ };
        const { battery,lastSeenAt } = config;

        return  <Row key={i} className='renderDeviceInfo'>
                    <Col span={18}><strong>Device ID: </strong>{d}</Col>
                    {
                        !props.forModal &&
                        <Col span={6}>{this.renderUnassignButton(d)}</Col>
                    }
                    <Col span={props.forModal ? 24 : 6} >
                        <span style={{display:'flex'}}>Battery: {(battery !=null) ?<Progress percent={battery} size="small" style={{ marginLeft:10 }}/>:'N/A'}</span>
                    </Col>
                    <Col span={24}>
                        <span>Last time seen online: { (lastSeenAt !=null) ?  moment(lastSeenAt).format('HH:mm:ss MM/DD/YYYY'):'Never' }</span>
                    </Col>
                    <Col span={24}>
                        <DeviceConfigUpdateComponent {...props} deviceId={d} deviceObj={deviceObj} UnassignButton={props.forModal ? this.renderUnassignButton : null}/>
                    </Col>
                </Row>
            })


    }

    async unassignDevice(deviceId){
        const { removeIotDeviceMemberAPI,openErrorModal,setInputFieldDisabled, setAssignment, onUnassignIoT} = this.props;
        const userId = _.get(this.props,'data.user.id', _.get(this.props, 'patientId'));

        let variables = {
            deviceId
        }

        if(onUnassignIoT) {
          return onUnassignIoT(variables, userId, () => {
            if(setAssignment) {
              setAssignment(false, { deviceId: 0 });
            }
          });
        }

        return new Promise((resolve,reject)=> {
            Modal.confirm({
                title:  'Unassign Device for this patient?',
                onOk: ()=> removeIotDeviceMemberAPI(variables, userId).then(res => {
                    message.success('Device Unassigned');
                    if(setAssignment) {
                        setAssignment(false, { deviceId: 0 });
                    }
                    resolve(true);
                }).catch(e => openErrorModal(GQLHelper.formatError(e))),
                okText: "Unassign Device",
                cancelText: 'Cancel',
                onCancel: ()=>{
                    resolve(true);
                }
            })
        })

    }

    renderUnassignButton = (d) => {
        // const { disabled }  = _.get(this,'props.state.clearInfo');
        const { disabled }  = this.props;
        return this.props.forModal ?
            <Button
                type="primary"
                style={{ marginLeft: 5 }}
                onClick={() => this.unassignDevice(this.props.deviceId)}
                ghost
            >
                Unassign Devices
            </Button>
        :
            <Button disabled={disabled}
                onClick={()=>this.unassignDevice(d)}
                className='care-plan-edit-btn ih-exsmall IH-Button'
                style={{marginTop:-5}}
            >
                Unassign Devices
            </Button>
    }

    render(){
        const { iotDeviceList } = this.props;

        return this.renderDeviceInfo(iotDeviceList||[]);
    }
}

RenderAndUpdateDevice = Form.create({onFieldsChange(props){ 
    if (props.handleOnFocus) {
        props.handleOnFocus('clearInfo');
    }
}})(RenderAndUpdateDevice);
const mapToDispatch = (dispatch)=>{
    return{
        openErrorModal: err => dispatch(openErrorModal(err)),
    }
}
export  default connect(null,mapToDispatch)(RenderAndUpdateDevice);
