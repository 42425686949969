
var Log  = {
    /**no debug at all*/
    LOG_LEVEL_NONE  : 0,

    /**show even the debug messages*/
    LOG_LEVEL_DEBUG : 1,

    /**show all up to (and including) info
     *Setting the log level at info level will slow your browser a lot....so use it carefully
     **/
    LOG_LEVEL_INFO : 2,

    /**show only errors*/
    LOG_LEVEL_ERROR : 3,

    /**It will keep the log level (anything above this level will be printed)*/
    level : 2,

    /**
    * The less important of all messages
    * @param {String} message - the message to be logged
    **/
    debug: function (...message){
        if(typeof console !== 'undefined'){
            if(this.level <= this.LOG_LEVEL_DEBUG){

                //in FF is debug
                if(typeof console.debug == 'function'){
                    console.debug(...message);
                }
                else{//TODO: in IE is log
//                    console.info(...message);
                }
            }
        }
    },


    /**
    * The commonly used log message
    * @param {String} message - the message to be logged
    **/
    info : function (...message){
        if(typeof console !== 'undefined'){
            if(this.level <= this.LOG_LEVEL_INFO){
                console.info(...message);
            }
        }
    },

    /**
    * The worse kind of message. Usually a crash
    * @param {String} message - the message to be logged
    **/
    error : function (...message){
        if(typeof console !== 'undefined'){
            if(this.level <= this.LOG_LEVEL_ERROR){
                console.error(...message);
            }
        }
    },

    /**
     *Start grouping the log messages
     *@param {String} title - the title of the group
     *@see <a href="http://getfirebug.com/logging">http://getfirebug.com/logging</a>
     **/
    group : function(title){
        if(this.level <= this.LOG_LEVEL_INFO){ //ignore group if level not debug or info
            if(typeof console !== 'undefined'){
                /**If we do not test for group() function you will get an error in Opera
                 *as Opera has it's own console...which does not have a group() function*/
                if(typeof console.group === 'function'){
                    console.group(title);
                }
            }
        }
    },

    /**Ends current message grouping*/
    groupEnd : function(){
        if(this.level <= this.LOG_LEVEL_INFO){ //ignore group if level not debug or info
            if(typeof console !== 'undefined'){
                /**If we do not test for groupEnd() function you will get an error in Opera
                 *as Opera has it's own console...which does not have a group() function*/
                if(typeof console.groupEnd === 'function'){
                    console.groupEnd();
                }
            }
        }
    }

}

/*Set the log level*/
//Log.level = Log.LOG_LEVEL_DEBUG;
// Log.level = Log.LOG_LEVEL_ERROR;

window.Log = Log;
