import React, { forwardRef } from 'react';
import { CellularDeviceUnassignButtonComponent } from '../CellularDeviceUnassignButtonComponent/CellularDeviceUnassignButtonComponent';

export const CellularDeviceOnboardUnassignButtonComponent = forwardRef(({
  disabled,
  onUnassign,
}) => {
  const handleUnassign = () => {
    if (onUnassign) onUnassign();
  };

  return (
    <div className="cellular-devices-onboard-unassign-btn">
      <CellularDeviceUnassignButtonComponent
        disabled={disabled}
        modalWidth="30vw"
        onUnassign={handleUnassign}
      />
    </div>
  );
});
