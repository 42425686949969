import React from 'react';
import { browserHistory } from 'react-router';
import { goPath } from '../../../lib/utils';
import AreaContainer from '../Area/container/AreaContainer';
import helper from '../helper';

// props are from react-router
const AdminHomePageWrapper = props => {
  const { selectedRole } = helper.getCurrentUser();
  const organization = _.get(selectedRole, 'organization') || {};
  if(organization && !!organization.leaf) // Site Manager
    browserHistory.push(`/organizations/${organization.id}`);

  return <AreaContainer {...props}/>; // Area Manager
};

export default AdminHomePageWrapper;
