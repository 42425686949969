import React from 'react';
import TaskAssignmentBannerComponent from '../components/TaskAssignmentBannerComponent';
import {graphql} from "react-apollo/index";
import { compose } from 'react-apollo'

const POLL_INTERVAL = 60000*15;
import taskAssignmentList from '../../taskAssignment/query/taskAssignmentList';
const  TaskAssignmentBannerContainer = class extends React.Component {
    constructor(){
        super();
    }

    render() {
        const { props } = this;

        const { loading } = props;
        if(loading){
            return <div></div>
        }
        return <TaskAssignmentBannerComponent { ...props} />
    }
}

const withData = graphql(taskAssignmentList, {
    options: (props) => {
        const variables = _.get(props,'variables');

        const shouldSetInterval = _.get(props,'shouldSetInterval');
        return {
            variables:variables(),
            pollInterval: shouldSetInterval ? POLL_INTERVAL:0,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        };
    },
    props:({ data })=>{
        const { loading,taskAssignmentList } = data;
        if(loading){
            return {
                loading
            }
        };
        const list = _.get(taskAssignmentList,'data',[]);
        return {
            total:_.reduce(list,(sum,n)=>{
                return sum + _.get(n,'taskAssignments.length',0);
            },0)
        }
    }
});

export default  compose(withData)(TaskAssignmentBannerContainer);
