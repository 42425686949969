import React from 'react';
import helper from '../helper';
import {
  renderSelectClinicMembers,
  renderSelectIHealthMembers,
  renderOrderAndAuthorities,
  renderTextLink, renderStartFrom, renderDefaultText,
  renderAvatar, renderRole, renderAuthoritySelect, renderCareTeamList,
  renderOrderArrows, getAuthorityTableTitle, mapAuthorityValue, renderViewMemberOptions, renderMemberChannelCount
} from '../helper/careteamHelpers';
import { Tooltip,Icon } from "antd";
import { ROLE_NAME } from '../../../lib/constants';

const CREATE_CARETEAM = 'create';
const CREATE_CARETEAM_FIELDS = ['name', 'phone', 'contactName', 'consentProviderName'];
const EDIT_CARETEAM = 'edit';
const PROVIDER_COUNT_MAX = 20;
const VIEW_LIMIT_COUNT = 5;
const ROLES = ['MA', 'Provider', 'RD', 'CA', 'HC'];
const inversedRoleMap = _.invert({
  MA: 'Clinic Medical Assistant',
  Provider: 'Doctor',
  RD: 'Nurse',
  CA: 'Medical Assistant',
  HC: ROLE_NAME.HC
});
const AUTHORITIES = [
  {
    default: true,
    value: 'standard',
    visible: true,
    primary: false,
  },
  {
    value: 'primary',
    visible: true,
    primary: true
  },
  {
    value: 'visitor',
    visible: false,
    primary: false
  }
];
const AUTHORITIES_INFO =
`Standard: This member’s name will be displayed in care team, and patient is able to see name in their App. This member is not a primary member.

Primary: This member is a primary member, and name will be displayed in care team. Patient is able to see this name in their App.

Visitor: This member is not a primary member. This name will not be displayed in care team and patient is not able to see in their App.`;

const processStatusMap = {
  succeeded: {
    type: 'smile',
    color: '#52c41a'
  },
  failed: {
    type: 'frown',
    color: '#eb2f96'
  },
  inProgress: {
    type: 'sync',
    spin: true
  }
};

const stepMaps = modalAction => {
  const action = modalAction === CREATE_CARETEAM ? 'Select' : 'Edit';
  return [
    `${action} clinic members`,
    `${action} iHealth members`,
    `Set order and visibility`
  ]
};

const contentMaps = [
  props =>
    renderSelectClinicMembers({ header: 'Please select clinic members', ...props }),
  props =>
    renderSelectIHealthMembers({ header: 'Please select iHealth members',...props }),
  props =>
    renderOrderAndAuthorities({ header: 'Please review the order and visibility for each member', ...props }),
];

const commonAvatarProps = {
  title: '',
  key: 'avatar',
  dataIndex: 'avatar.link',
  width: '8%',
  render: text => renderAvatar(text)
};

const tooltipTitle =`This is the number of chat channels that this user currently has associated with his/her count. If this number exceeds 2000 or will exceed 2000 after you add him/her into this team, then some channels will not be available.`
const commonNameProps = {
  title: 'Name',
  key: 'name',
  dataIndex: 'fullName',
  width: '30%',
  sorter: (a, b) => {
    if(a.disabled || b.disabled) return 0; // don't sort when provider is disabled
    if (a.lastName < b.lastName) return -1;
    if (a.lastName > b.lastName) return 1;
    return 0;
  },
  render: text => renderDefaultText(text)
};

const commonRoleProps =  {
  title: 'Role',
  key: 'role',
  dataIndex: 'role',
  width: '15%',
  render: text => {
    console.log(text);
    return renderRole(text)
  }
};

const commonRolePropsWithFilters = {
  ...commonRoleProps,
  filters: _.map(ROLES, role => ({ text: role, value: role })),
  onFilter: (value, record) =>
    !record.disabled && value === inversedRoleMap[record.role]
};

const selectMemberColumns = teamChannelCount => [
  commonAvatarProps,
  commonNameProps,
  commonRoleProps,
  {
    title: 'Care team',
    key: 'team',
    render: (__, record) => renderCareTeamList(record.team)
  },
  {
    title: <div>Channels<Tooltip
          placement='topRight'
          overlayClassName='care-team-authority-tooltip'
          title={tooltipTitle}
          destroyTooltipOnHide
        >
        <Icon type='info-circle' style={{ marginLeft: 5, color: '#000', opacity: '45%' }}/>
      </Tooltip></div>,
    key: 'channelCount',
    width: '12%',
    render: (__, record) => renderMemberChannelCount(record.providerTotalChannels, teamChannelCount)
  }
];

// TODO: dragging sort a whole row
const selectOrderAndAuthorityColumns =
({ setTeamOrder, changeAuthority, dataSource }) => [
  commonAvatarProps,
  commonNameProps,
  commonRolePropsWithFilters,
  {
    title: getAuthorityTableTitle(),
    key: 'authority',
    dataIndex: 'authority',
    filters: _.map(AUTHORITIES, ({ value }) => ({ text: _.capitalize(value), value })),
    onFilter: (value, record) => {
      const recordAuth = _.find(AUTHORITIES, _.pick(record, ['visible', 'primary']));
      return _.get(recordAuth, 'value', '--') === _.toLower(value);
    },
    width: '20%',
    render: (__, record) => renderAuthoritySelect(record, changeAuthority)
  },
  {
    title: 'Order',
    key: 'orders',
    render: (__, record, index) => {
      return renderOrderArrows(record.disabled, index, dataSource, setTeamOrder);
    }
  }
];

const viewMemberColumns = props => [
    { ...commonAvatarProps, width: '5%', },
    {
      ...commonNameProps,
      width: '25%',
      render: (text, record) =>
        renderTextLink(text, () => helper.addPrefixAndGoTo(`users/${record.id}`))
    },
    { ...commonRolePropsWithFilters, width: '10%' },
    {
      title: 'Mobile phone',
      key: 'phone',
      dataIndex: 'phone',
      width: '10%',
      render: text => renderDefaultText(text)
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: '18%',
      render: text => renderDefaultText(text)
    },
    {
      title: 'Start Date',
      key: 'startFrom',
      dataIndex: 'startFrom',
      width: '10%',
      sorter: (a, b) => a.startFrom - b.startFrom,
      render: text => renderStartFrom(text)
    },
    {
      title: getAuthorityTableTitle(),
      key: 'authority',
      dataIndex: 'authority',
      width: '15%',
      render: (__, record) => {
        let text = mapAuthorityValue(record);
        text = _.capitalize(text);
        return renderDefaultText(text);
      }
    },
    {
      title: '',
      key: 'close-button',
      render: (__, record) => renderViewMemberOptions(props, record)
    }
    // {
    //   title: '',
    //   key: 'view-member-options',
    //   render: () => renderViewMemberOptions()
    // }
];

export {
  CREATE_CARETEAM, CREATE_CARETEAM_FIELDS, EDIT_CARETEAM,
  PROVIDER_COUNT_MAX, VIEW_LIMIT_COUNT, AUTHORITIES, AUTHORITIES_INFO,
  stepMaps, contentMaps,
  selectMemberColumns, selectOrderAndAuthorityColumns, viewMemberColumns,
  inversedRoleMap, processStatusMap
};
