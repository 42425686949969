import CareplanCommonTypes from '../constants/CommonConstants'

const updateField = (field,str,isUpdated) => {
  return{
      type: CareplanCommonTypes.UPDATE_FIELD,
      payload: {
        str,field,isUpdated
      }
  }
}
const restCarePlanUpdated = ()=>{
  return {
    type:CareplanCommonTypes.RESET_CAREPLANE_UPDATED
  }
}

const carePlanUpdated = ()=>{
  return {
    type:CareplanCommonTypes.CAREPLANE_UPDATED
  }
}
// const updateFollowUp = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_FOLLOWUP_SCHEDULE,
//       payload: str
//     })
//   }
// }
// const updateProbList = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_PROBLEM_LIST,
//       payload: str
//     })
//   }
// }
// const updateAssessments = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_ASSESSMENTS,
//       payload: str
//     })
//   }
// }
// const updateInterventions = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_INTERVENTIONS,
//       payload: str
//     })
//   }
// }
// const updateGoals = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_GOALS,
//       payload: str
//     })
//   }
// }
// const updateResource = str => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: CareplanCommonTypes.UPDATE_RESOURCE,
//       payload: str
//     })
//   }
// }

export default {
  // updateCareCoord,
  // updateFollowUp,
  // updateProbList,
  // updateAssessments,
  // updateInterventions,
  // updateGoals,
  // updateResource
    updateField,
    restCarePlanUpdated,
    carePlanUpdated
}
