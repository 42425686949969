import gql from 'graphql-tag';

export default gql `
  mutation removeUserRoles($id: EID!, $roles: [Ref]) {
    removeUserRoles(
      id: $id,
      roles: $roles
    ) {
      id
      profile {
        firstName
        lastName
        fullName
        nickname
        fullNameWithNickname
      }
    }
  }
`;
