import gql from 'graphql-tag'
export default gql`query getPresignedLogoUpload($organizationId:EID!,$filename:String!) {
  getPresignedLogoUpload(organizationId:$organizationId, filename: $filename) {
    data {
      url
      fields
    }
    info {
      expires
    }
  }
}`;