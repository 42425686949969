import { combineReducers } from 'redux';
import type from '../constants';
import { getTimerClaimedId } from '../helpers';
import moment from 'moment';

const INITIAL_STATE = {
};

const OBJ_INITIAL_STATE = { 
  startTime: null, isPaused: false, isFocused: true, isCoolDown: false, patientId: null,
  allEvents: [], interactions: [], stopTime: null, sendOnUnmount: true, isDone: false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  let newTimerObj = {};
  let isNew = false;
  const { id, patientId } = action;
  const timerId = getTimerClaimedId(id, patientId);

  if (timerId && !state[timerId]) {
    isNew = true;
    newTimerObj = _.cloneDeep(OBJ_INITIAL_STATE);
  } else {
    newTimerObj = _.cloneDeep(state[timerId]);
  }

  switch (action.type) {
    case type.START_TIMER: return combineWithState(state, timerId, startTimerCase(_.cloneDeep(OBJ_INITIAL_STATE), action));
    case type.INTERACTION: return combineWithState(state, timerId, interactionCase(newTimerObj, action));
    case type.PAUSE_TIMER: return combineWithState(state, timerId, pauseTimerCase(newTimerObj, action));
    case type.RESUME_TIMER: return combineWithState(state, timerId, resumeTimerCase(newTimerObj, action));
    case type.STOP_TIMER: return combineWithState(state, timerId, stopTimerCase(newTimerObj, action));
    case type.RESET_TIMER: return combineWithState(state, timerId, startTimerCase(_.cloneDeep(OBJ_INITIAL_STATE), action));
    case type.TIMEOUT_PAUSE: return combineWithState(state, timerId, timeoutCase(newTimerObj, action, true));
    case type.TIMEOUT_RESUME: return combineWithState(state, timerId, timeoutCase(newTimerObj, action, false));
    case type.FOCUS_PAGE: return combineWithState(state, timerId, focusCase(newTimerObj, true));
    case type.BLUR_PAGE: return combineWithState(state, timerId, focusCase(newTimerObj, false));
    case type.DONE: return isNew ? state : combineWithState(state, timerId, doneCase(newTimerObj, action));

  default:
    return state;
  }
}

const getActionName = (action) => {
  return action.type.split('/')[2];
}

const combineWithState = (state, timerId, obj) => {
  return { ...state, [timerId]: obj };
}

const startTimerCase = (obj, action) => {
  const { event, patientId, id } = action;
  const startTime = moment();
  const allEvents = new Array();
  const interactions = new Array();

  allEvents.push({
    event,
    timestamp: startTime.clone(),
    action: getActionName(action),
  });

  const newObj = Object.assign(obj, {
    id,
    patientId,
    startTime,
    allEvents,
    interactions,
  });

  return newObj;
};

const interactionCase = (obj, action) => {
  const { interactionName, description } = action;
  const { interactions } = obj;
  const timestamp = moment();

  const newInteractions = [...interactions, { name: interactionName, description, timestamp }];

  const newObj = Object.assign(obj, {
    interactions: newInteractions,
  });

  return newObj;
};

const pauseTimerCase = (obj, action) => {
  const { event } = action;
  const { allEvents, isFocused } = obj;
  const timestamp = moment();

  if (!isFocused || allEvents.length === 0 || event == 'timeoutExpire') {
    return obj;
  }

  const newEvents = [...allEvents, { event, timestamp: timestamp.clone(), action: getActionName(action) }];

  const newObj = Object.assign(obj, {
    allEvents: newEvents,
    isFocused: false,
  });

  return newObj;
};

const resumeTimerCase = (obj, action) => {
  const { event } = action;
  const { allEvents, isFocused } = obj;
  const timestamp = moment();

  if (isFocused) {
    return obj;
  }

  const newEvents = [...allEvents, { event, timestamp: timestamp.clone(), action: getActionName(action) }];

  const newObj = Object.assign(obj, {
    allEvents: newEvents,
    isFocused: true,
  });

  return newObj;
};

const stopTimerCase = (obj, action) => {
  const { event, cb } = action;
  const { allEvents } = obj;
  const timestamp = moment();
  
  // if (allEvents.length === 0) {
  //   return obj;
  // }

  const newEvents = [...allEvents, { event, timestamp: timestamp.clone(), action: getActionName(action) }];

  const newObj = Object.assign(obj, {
    sendOnUnmount: false,
    cb,
    isPaused: true,
    stopTime: timestamp.clone(),
    allEvents: newEvents,
    isDone: true,
  });

  return newObj;
};

const timeoutCase = (obj, action, isPaused) => {
  const { event } = action;
  const { allEvents } = obj;
  const timestamp = moment();

  if (obj.isPaused === isPaused) { return obj; }

  const newEvents = [...allEvents, { event, timestamp: timestamp.clone(), action: getActionName(action) }];

  const newObj = Object.assign(obj, {
    allEvents: newEvents,
    isPaused,
  });

  return newObj;
}

const focusCase = (obj, isFocused) => {
  const newObj = Object.assign(obj, {
    isFocused,
  });

  return newObj;
}

const doneCase = (obj, action) => {
  const newObj = Object.assign(obj, {
    isDone: action.isDone,
  });

  return newObj;
}

export default {
  timerClaimed: combineReducers({ main: reducer })
};
