/**
 * Created by zizhangai on 2/13/17.
 */
import gql from 'graphql-tag'
import update from 'immutability-helper'
import Measure from './schema/Measure';


export default gql`
  mutation createProviderNote(
    $category: ProviderNoteCategory!,
    $content: String!,
    $enrolledProgramId: EID
    $tags: [String]
    $pauseDuration: Int,
    $resumeDate: Date,
    $memberId:EID
    $smartAlertId:EID
    $organizationId:EID
    $techCall: Boolean
    $patientHiatusStatusId: EID
    $hiatusStartDate: Date
    $hiatusAction: ProviderHiatusAction
    $hiatusCategories: [PatientHiatusCategory]
  ) {
    createProviderNote(
      category: $category,
      content: $content,
      enrolledProgramId: $enrolledProgramId,
      tags: $tags
      pauseDuration: $pauseDuration
      resumeDate: $resumeDate,
      memberId:$memberId
      smartAlertId:$smartAlertId
      organizationId:$organizationId
      techCall:$techCall
      patientHiatusStatusId: $patientHiatusStatusId
      hiatusStartDate: $hiatusStartDate
      hiatusAction: $hiatusAction
      hiatusCategories: $hiatusCategories
    ) {
      id
      category
      content
      error {
        markedBy {
          id
          createdAt
          profile {
            fullName
          }
        }
        reason
      }
      createdBy {
        id
        profile {
          fullName
        }
      }
      tags
      result {
        id
      }
      pauseDuration
      resumeDate
      createdAt
      updatedAt
      techCall
    }
  }
`

export const createProviderNoteToResult = gql`
  mutation createProviderNote(
    $category: ProviderNoteCategory!,
    $content: String!,
    $resultId: EID
  ) {
    createProviderNote(
      category: $category,
      content: $content,
      resultId: $resultId
    ) {
      id
      category
      content
      error {
        markedBy {
          id
          createdAt
          profile {
            fullName
          }
        }
        reason
      }
      createdBy {
        id
        profile {
          fullName
        }
      }
      result {
        id
        type
        severity
        taskStatus
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
        }
        ... on BGResult {
          blood_glucose
        }
        ... on HSResult {
          body_weight,
          body_weight_change,
          body_weight_change_baseline
        }
        ... on HRResult {
          heart_rate
        }
        measure {
          ${Measure}
        }
        threshold {
          measure
        }
        schedule {
          calendar
          target
          start
          end
          beforeMeal
          mealType
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const updateQueries = {
  result: (prev, { mutationResult: { data } }) => {
    if (prev.result.id !== data.createProviderNote.result.id || !prev.result.notes) {
      return prev
    }
    const newNote = data.createProviderNote;
    return update(prev, {
      result: {
        notes: {
          data: {
            $unshift: [newNote]
          }
        }
      }
    })
  },
  providerNoteList: (prev, { mutationResult: { data } }) => {
    const newNote = data.createProviderNote;
    return update(prev, {
      providerNoteList: {
        data: {
          $unshift: [newNote]
        }
      }
    })
  },
//   resultList: (prev, { mutationResult: { data } }) => {
//     console.log(data)
//     const newResult = data.intervene.notes[0]
//     const newStatus = data.intervene.careStatus
//     const id = data.intervene.id
//     const newList = prev.resultList.data.map(item => {
//       if (item.id !== id) {
//         return item
//       } else {
//         return update(item, {
//           careStatus: {
//             $set: newStatus
//           },
//           notes: {
//             $unshift: [newResult]
//           }
//         })
//       }
//     })
//     return update(prev, {
//       resultList: {
//         data: {
//           $set: newList
//         }
//       }
//     })
//   }
}
