export const tooltipStyle = {
  titleFontSize: 15,
  bodyFontSize: 15,
};

export const scaleLabelStyle = {
  fontSize: 15,
};

export const fontSize = 15;
export const chartBarColor = '#9fdef1';