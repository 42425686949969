import { Table, Input, Button, message, Form, Checkbox, DatePicker } from 'antd';
import React from 'react';
import Client from 'libModule/gqlClient';
import editLabRes from 'graphqlModule/mutation/editLabRes.js';
import labResultList from 'graphqlModule/labResultList.js';
import moment from 'moment-timezone';
const EditableContext = React.createContext();
import '../css/labRes.scss';
import { Modal } from 'antd/lib/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    const { editMode } = this.props;
    if (!editMode) return;
    this.setState({ editing }, () => {
      if (editing && editMode) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, editMode } = this.props;
    const { editing } = this.state;
    return editMode && editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div className={`${editMode ? 'editable-cell-value-wrap' : ''}`} onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };

  render() {
    // 20200304 Thong - do not include handleSave, editMode and dataIndex in restProps to avoid warning sc-2726
    const { editable, children, handleSave, editMode, dataIndex, ...restProps } = this.props;
    return <td {...restProps}>{editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    const {
      labResult: { templateId, results: dataSource }
    } = props;

    this.state = {
      dataSource, //_.get(props,'labResult.results').map(({ name,unit,value,interval,isInHouse })=>({name,unit,value,interval,isInHouse})),
      editMode: false,
      _id: _.get(props, 'labResult._id'),
      dateCollected: moment(_.get(props, 'reportSelected')).tz(tz)
    };

    this.columns = [
      {
        title: 'Test',
        dataIndex: 'name',
        width: '20%'
      },
      {
        title: 'Result',
        dataIndex: 'value',
        editable: true,
        width: '15%'
      },
      {
        title: 'Units',
        dataIndex: 'unit',
        width: '12%'
      },
      {
        title: 'Flag',
        dataIndex: 'flag',
        editable: true,
        width: '12%'
      },
      {
        title: 'Reference Interval',
        dataIndex: 'interval',
        width: '30%'
      }
    ];
    if (templateId === 'hemoa1c') {
      this.columns.push({
        title: 'In-house Test',
        dataIndex: 'isInHouse',
        render: (v, d, index) => <Checkbox disabled={!this.state.editMode} defaultChecked={!!v}  onChange={() => this.onChange(index)} />
      });
    }
    const tz = moment.tz.guess();
  }

  onChange = (index) =>{
    const newData = [...this.state.dataSource];
    const item = newData[index];
    item.isInHouse = !item.isInHouse;
    this.setState({dataSource: newData});
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.labResult && Object.keys(nextProps.labResult).length > 0) {
      this.setState({
        dataSource: _.get(nextProps, 'labResult.results').map(({ name, unit, value, interval, isInHouse, flag }) => ({
          name,
          unit,
          value,
          interval,
          flag,
          isInHouse
        })),
        _id: _.get(nextProps, 'labResult._id'),
        dateCollected: moment(_.get(nextProps, 'reportSelected')).tz(moment.tz.guess())
      });
    }
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.name === item.name);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData });
  };

  saveChange = () => {
    const { _id, dataSource, dateCollected } = this.state;
    if (!dateCollected.isValid()) return message.error('Date is required');

    const { userId, setEditEnabled, setLabResStatus } = this.props;
    //const results = _.map(dataSource, ({ name,unit,value,interval,isInHouse })=>({name,unit,value,interval,isInHouse}));
    Mixpanel.track('clicked', 'save', 'patient profile', {
      PATIENT_ID: userId
    });
    Client.mutate({
      mutation: editLabRes,
      variables: {
        _id,
        results: dataSource,
        dateCollected
      },
      refetchQueries: [
        {
          query: labResultList,
          variables: { filter: { userId, filterDeleted: true } },
          fetchPolicy: 'network-only'
        }
      ]
    }).then(() => {
      this.setState(
        {
          editMode: false
        },
        () => {
          setEditEnabled(false, -1);
          setLabResStatus(false);
          message.success('Lab Result Updated');
        }
      );
    });
  };

  deleteLabResult = () => {
    const { _id } = this.state;
    const { userId, setEditEnabled } = this.props;
    Client.mutate({
      mutation: editLabRes,
      variables: {
        _id,
        deleted: true
      },
      refetchQueries: [
        {
          query: labResultList,
          variables: { filter: { userId, filterDeleted: true } },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
        }
      ]
    }).then(res => {
      setEditEnabled(false, -1);
      message.success('Lab Result deleted');
    });
  };

  handleDelete = () => {
    return new Promise(resolve => {
      Modal.confirm({
        title: 'Are you sure you want to delete this Lab Result ?',
        onOk: () => {
          if (this.props.onInteraction) {
            this.props.onInteraction('DELETE');
          }
          this.deleteLabResult();
        },
        zIndex: 2000,
        okText: 'Confirm',
        cancelText: `Cancel`,
        onCancel: () => {
          resolve(true);
        }
      });
    });
  };

  clickEdit = index => {
    const { setEditEnabled, editEnabled, setLabResStatus } = this.props;
    this.setState({ editMode: true });
    Mixpanel.track('clicked', 'edit', 'patient profile', {
      PATIENT_ID: this.props.userId
    });
    if (!editEnabled) {
      setEditEnabled(true, index);
      setLabResStatus(true);
    } else {
      setEditEnabled(false, -1);
    }
  };

  render() {
    const { labResult, index, activeEditKey, editEnabled } = this.props;
    const { dataSource, editMode, dateCollected } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const tz = moment.tz.guess();
    const disabled = editEnabled && activeEditKey != index;
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          editMode
        })
      };
    });

    const setSaveEditButtonRef = el => {
      if(this.props.setSaveEditButtonRef) {
        this.props.setSaveEditButtonRef(el);
      }
    };    

    return (
      <div style={{ marginBottom: 15 }}>
        <div style={{ display: 'flex', alignItems: 'baseline', paddingBottom: 5 }}>
          <div>{editMode ? <DatePicker format='MM/DD/YYYY' defaultValue={moment(dateCollected)} disabledDate={current => current && current > moment().endOf('day')} onChange={d => this.setState({ dateCollected: moment(d).tz(tz) })} /> : ''}</div>
          {editMode ? (
            <Button ref={el => setSaveEditButtonRef(el)} 
            onClick={() => {
              this.saveChange();
              setSaveEditButtonRef(null); // reset to null when finished edit
              if (this.props.onInteraction) {
                this.props.onInteraction('UPDATE');
              }
            }} 
            style={{ marginLeft: 'auto', marginRight: 10 }} 
            size='small' type='primary' className='resButton'>
              Save
            </Button>
          ) : (
            <Button size='small' onClick={() => this.clickEdit(index)} disabled={disabled} type='primary' style={{ marginLeft: 'auto', marginRight: 10 }} className='resButton'>
              Edit
            </Button>
          )}
          {
            <Button size='small' ghost type='danger' onClick={() => this.handleDelete()} disabled={editEnabled}>
              Delete
            </Button>
          }
        </div>
        <Table className='editLabResultTable' components={components} rowClassName={() => 'editable-row'} bordered dataSource={dataSource} columns={columns} pagination={false} rowKey={(_, i) => i} />
      </div>
    );
  }
}
export default EditableTable;
