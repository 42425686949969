const defaultBGDefaultValue = [
    {
        CRITICAL_1:[0,70],
        RISK_1:[71,99],
        NORMAL:[100,180],
        RISK_2:[181,249],
        CRITICAL_2:[250,Infinity]
    },
    {
        CRITICAL_1:[0,60],
        RISK_1:[61,79],
        NORMAL:[80,130],
        RISK_2:[131,199],
        CRITICAL_2:[200,Infinity]
    }
]

export default defaultBGDefaultValue;