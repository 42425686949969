import React from 'react';
import { compose, graphql } from 'react-apollo/index';
import { Form, Select, AutoComplete, Button } from 'antd';
import loanDeviceList from '../../../patient/loanDevices/query/loanDeviceList';
import assignLoanDevice from '../../../patient/loanDevices/mutate/assignLoanDevice';
import Client from 'libModule/gqlClient';

const Item = Form.Item;
const Option = AutoComplete.Option;


let AddNewLoanDevice = class extends React.Component {
  getOptions(loanDeviceList) {
    const options = loanDeviceList.map(({ deviceId }) => {
      return <Option key={deviceId} value={deviceId}>{deviceId}</Option>
    });

    return options;
  }

  renderAutoComplete(loanDeviceList) {
    const children = [...this.getOptions(loanDeviceList)];
    // const { disabled }  = _.get(this,'props.state.loanDeviceInfo');
    const { disabled } = this.props;

    return (
      <Select
        dropdownMatchSelectWidth={true}
        dataSource={children}
        style={{ width: 200 }}
        allowClear={true}
        showSearch
        disabled={disabled}
        notFoundContent='Device Number not Found'
      >
        {children}
      </Select>
    )
  }


  onSubmit = (e) => {
    e.preventDefault();
    const { form, openErrorModal, onAssign } = this.props;
    const memberId = _.get(this, 'props.user.id');

    form.validateFields(['deviceId'], (errors, { deviceId }) => {
      if (errors || !memberId)
        return; 
      let variables = {
        deviceId,
        memberId,
      };
      Client.mutate({
        mutation: assignLoanDevice,
        variables,
      }).then(res => {
          if(onAssign) onAssign(res);
        })
        .catch(e => {
          openErrorModal(e);
        });
    })
  }

  renderForm = () => {
    const { form, loanDeviceList, disabled } = this.props;
    const { getFieldDecorator } = form;

    return <Form className='' layout={'vertical'} disabled={disabled}>
      <Item label='Device ID' required={true}>
        {getFieldDecorator('deviceId', {
          rules: [{
            required: true
          }]
        })(this.renderAutoComplete(loanDeviceList || []))}
      </Item>
      <Item wrapperCol={{ span: 12 }}>
        <Button type='primary' onClick={this.onSubmit} disabled={disabled}>
          Assign Device
        </Button>
      </Item>
    </Form>
  }

  render() {
    return this.renderForm();
  }
}
const withData = graphql(loanDeviceList, {
  options: () => {
    return {
      variables: { status: 'AVAILABLE' },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const loanDeviceList = _.get(data, 'loanDeviceList.data', []);
    const loading = _.get(data, 'loading');
    if (loading) {
      return {
        loading
      }
    }
    return {
      loanDeviceList
    }
  }
});

AddNewLoanDevice = Form.create({})(AddNewLoanDevice);

export default compose(withData)(AddNewLoanDevice);
