import React from 'react';
import {Button, Icon, Tooltip} from 'antd';
import I18N from 'modulesAll/I18N';
import DischargeConfirmContainer from 'modulesAll/patient/enrolledProgramInfo/containers/DischargeConfirmContainer';
import { RequestCache } from 'libModule/utils';
import moment from 'moment';
import { createAuditLog } from 'libModule/utils/auditLog'
import PrintCarePlanButton from '../container/PrintCarePlanButton';
import UnderlinedTooltip from '../../patientList/main/components/UnderlinedTooltip';

const styles = {
  programInfoWrapper: {
    marginBottom: 8, display: 'flex', flexDirection: 'row'
  },
  programDateLabel: {
    fontFamily: 'var(--font-family)', fontWeight: 600, color: '#222222', fontSize: 14, lineHeight: '19px', minWidth: 68
  },
  programDateText: {
    display: 'flex', fontFamily: 'var(--font-family)', fontWeight: 400, color: '#222222', fontSize: 14, lineHeight: '19px', marginLeft: 5
  }
};

let ProgramInfo = class extends React.Component{
    static displayName = 'careplan/programInformation';

    dischargeBtnOnClick = () => {
      const { patientReferral, refetchPatientReferral } = this.props;
      const currentPatient = RequestCache.get('nurse/currentPatient')
      const patientNRIC = _.get(currentPatient, 'identification[0].value')
      const patientName = _.get(currentPatient, 'profile.fullName')
      createAuditLog({
          action: I18N.get('auditLog.provider.patient.dischargeProgramme'),
          patientNRIC,
          patientName
      })
  
      const dischargeConfirm = (
          <DischargeConfirmContainer
              openMadal={this.props.openModal}
              openErrorModal={this.props.openErrorModal}
              closeModal={this.props.closeModal}
              dischargeProgram={this.props.dischargeEnrolledProgram}
              currentPatient={currentPatient}
              refetchEnrolledProgramList={this.props.refetchEnrolledProgramList}
              currentProgram={this.props.currentProgram}
              location={this.props.location}
              refetchPatientReferral={() => {
                if(!_.isEmpty(patientReferral) && refetchPatientReferral) 
                  refetchPatientReferral()
              }}
          />
      );
  
      this.props.openModal(dischargeConfirm, {
          title: 'Discharge Patient ?',
          className: 'discharge-modal',
      });
    }

    renderDischargedDetail = () => {
      const dischargedDetail = _.get(this.props, 'currentProgram.dischargedDetail') || {};
      const { dischargedAt, dischargedBy, description, note } = dischargedDetail || {};
      return dischargedAt && <div className='program-discharge-detail-wrapper'>
        <div style={styles.programInfoWrapper}>
          <div style={styles.programDateLabel}>
            End Date
          </div> 
          <div style={styles.programDateText}>
            {moment(dischargedAt).format('MM/DD/YYYY HH:mm')}&nbsp;
            by {_.get(dischargedBy,'profile.fullName') || '--'}
          </div>
        </div>
        <div className='program-discharge-detail'>
          <div style={styles.programDateLabel}>&nbsp;</div>
          <p>
            {description}
            { note && ` (${note})` }
          </p>
        </div>
      </div>;
    }
    
    render(){
      const { currentProgram, isMNT, user = {} } = this.props;
      const startDate = _.get(user, 'enrollmentDate');
      const showDate = startDate ? moment(startDate).format('MM/DD/YYYY') : null;
      const isDischarged = _.get(currentProgram, 'dischargedDetail');
      return(
      <div style={{display: 'flex', flexDirection: 'column', marginBottom: 24 }}> 
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <PrintCarePlanButton enrolledProgram={currentProgram} isMNT={isMNT} />
          {!isDischarged &&<Button style={{fontSize: 13, marginLeft: 10}} className='discharge-btn' id='discharge-btn' onClick={this.dischargeBtnOnClick}>Discharge from Program</Button>}
        </div>
  
        <div style={styles.programInfoWrapper}>
          <div style={styles.programDateLabel}>
            {/* Start Date */}
            <UnderlinedTooltip 
              tooltipMsg={I18N.get('enrollment.enrolledDate.tip')}
            >
              Enrollment Date:
            </UnderlinedTooltip>
          </div> 
          <div style={styles.programDateText}>
            {/* {showDate || 'N/A'} */}
            {
              showDate ||
              <div style={styles.programDateText}>
                <span>N/A</span>
                <div style={{ marginTop: -10 }}>
                  <Tooltip overlayClassName='enrollment-enrolled-date-tip' title={I18N.get('enrollment.enrolledDate.NAtip')}>
                    <Icon type='info-circle' size={8} theme='filled' style={{ color: '#FA2F14' }}/>
                  </Tooltip>
                </div>
              </div>
            }
          </div>
        </div>
        { this.renderDischargedDetail() }
      </div>)
    }
}

export default ProgramInfo;
