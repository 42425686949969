import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import { IHLoading } from 'ihcomponent'
// import AssessmentList from 'graphqlModule/assessmentList'
import { LightAssessmentList } from 'graphqlModule/assessmentList'
import ListContainer from 'ProgramEditModule/Assessment/containers/ListContainer'
import get from 'lodash/get';
import { ASSESSMENT_LIST_COUNT_MAX } from 'libModule/constants';

const ref = 'assessmentList'
const type = 'ASSESSMENT'
class Container extends Component {
  static displayName = 'program/ProgramEdit/Assessment/containers/CommonContainer'

  render() {
    const { data, program, assessmentHasEmptySchedule } = this.props
    const assessmentListData = get(data, 'assessmentList.data') || []
    if (data.loading) {
      return <IHLoading />
    }
    const props = {
      assessmentListData,
      program,
      assessmentHasEmptySchedule
    }
    return <ListContainer ref={ref} {...props} />
  }
}

// mapState
const mapState = ({ program }, ownProps) => {
   return {
    ...program.ProgramEdit.Careplan.Assessment.Common
   }
 }

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {
  }
}

const WithData = graphql(LightAssessmentList, {
   options: ownProps => ({
     variables: {
      page: 1,
      count: ASSESSMENT_LIST_COUNT_MAX,
      filters: {
         status: ['ACTIVE'],
         healthCondition: ownProps.program.healthCondition,
         type,
      },
      sort: {
        field: 'CREATED_AT',
        direction: 'ASC'
      }
     },
     notifyOnNetworkStatusChange: true,
     fetchPolicy: 'network-only'
   }),
   props: ({ ownProps, data }) => ({
     data,
   }),
   })(Container);

// PropTypes validation
Container.propTypes = {
  program: PropTypes.object,
  data: PropTypes.object,
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(WithData)
