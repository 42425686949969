import gql from 'graphql-tag'
import Measure from './schema/Measure'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import { getStatus } from 'modulesAll/utils/helpers/task'

export default gql `
  mutation insertBP(
    $date: Date!,
    $timezone: String!,
    $systolic: BPValue!,
    $diastolic: BPValue!,
    $heartrate: HRValue,
    $imageUrl: String,
    $notes: String,
    $memberId: EID,
  ){
    insertBP(
      date: $date,
      timezone: $timezone,
      systolic: $systolic,
      diastolic: $diastolic,
      heartrate: $heartrate,
      imageUrl: $imageUrl,
      notes: $notes,
      manualInput: true,
      memberId: $memberId
    ) {
      ${Measure}
    }
  }
`
export const updateQueries = {
  resultsFromToday: (prev, { mutationResult }) => {
    // const result = mutationResult.data.insertBP
    // const { diastolic_blood_pressure, systolic_blood_pressure, heart_rate } = result
    // let resultsFromToday = cloneDeep(prev.resultsFromToday)
    // for (let i = 0; i < resultsFromToday.length; i++) {
    //   let item = resultsFromToday[i]
    //   //console.log('ownProps ', ownProps, item.refId === ownProps.refId)
    //   console.log('item ', item, i)
    //   if(item.refId === "UmVmOjU4NGViY2M5OWI4NjIyNDViMjg4YjMyNjo1ODRlYjkwZTZiNTQ2MTQzYjQ0Yzg4NDU=" && item.status === 'UPCOMING') {
    //     //item.measure = result
    //     item = {
    //       ...item,
    //       diastolic_blood_pressure,
    //       systolic_blood_pressure,
    //       heart_rate,
    //       date: new Date().getTime()
    //     }
    //     resultsFromToday[i] = item
    //     console.log('~~~~~~~~~~item ', item, resultsFromToday[i], i)
    //     break
    //   }
    // }
    // console.log('resultsFromToday ', resultsFromToday)
    // return update(prev, {
    //   resultsFromToday: {
    //     $set: resultsFromToday
    //   }
    // })
  },
}
