import gql from 'graphql-tag'
export default gql`
  mutation starMember($id:EID!, $reason:String){
    flagMember(id:$id, reason:$reason){
      id
      hasFlag
      flagDetails{
          createdBy{
              profile{
                  fullName
              }
          }
          reason
          createdAt
      }
    }
  }
`
