import { constants } from 'libModule/utils';
export default constants('Vendor', 'Patient', [
  'SET_PATIENT_INFO',
  'SET_LOADING',
  'SET_REPLACE_DEVICES_STEP',
  'CHANGE_DEVICES_INFO',
  'SET_ENROLLEDPROGRAM_ID',
  'SET_ENROLLEDPROGRAMLIST',
  'RESET_DEVICES_INFO',
  'SET_RETURN_DEVICES_STEP',
  'SET_SEARCH_TEXT',
  'SET_SEARCH_STATUS',
  'INIT_DEVICES_INFO',

  //for return replace devices form
  'FORM_FIELD_CHANGE',
  'RESET_FORM',
  'FORM_SAVED',
  'SET_SUBMITTING',
  'UPDATE_ENROLLEDPROGRAMLIST'
])
