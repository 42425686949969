import React from 'react';
import StreamPlayer from 'agora-stream-player'
import  AgoraRTC  from 'agora-rtc-sdk'
import enhanceAgoraRTC from "agoran-awe";
import helper from '../helper';
import { withRouter } from "react-router";

let AgoraRTClient = enhanceAgoraRTC(AgoraRTC);

const AgoraVideoComponent = class extends React.Component{
    constructor(){
        super();
        this.state = {
            stream: null,
            client: null,
            localStream:[],
            remoteStream:[],
            speaker: null,
            soundLevel: null,
            localAudio: true,
            cameras:null,
            currentCamera: 0,
            mainStreamIndex: 0,
            audienceMap:{ }

        }
    }

    componentWillUnmount(){
        const { client } = this.state;
        if(client){
            client.leave();
        }
    }

    async componentDidMount() {
        let { props: { params } } = this;
        const client = await AgoraRTClient.createClient({ mode: 'live', codec: 'vp8'});
        const stream = await helper.joinOneClickVideo(client,params,this);
        this.setState({
            stream,
            client
        })
    }

    async componentDidUpdate(){
        const { state } = this;
        let { props: { params } } = this;
        let { visitId } = params;
        const { audienceMap,stream,remoteStream } = state;
        if(stream&&!audienceMap[stream.getId()]) {
            await helper.setAudienceMap(stream.getId(), visitId,this);
        }
        if(remoteStream){
            _.forEach(remoteStream,async stream=>  {
                if(stream&&!audienceMap[stream.getId()]){
                    await helper.setAudienceMap(stream.getId(), visitId, this);
                }
            })
        }


    }
    renderMuteDiv = ()=>{
        const { setLocalAudio } = helper;
        const { stream,remoteStream,speaker,localAudio,client } = this.state;
        const audioStatus = localAudio ? 'mute':'unmute';
        const muteDivConfig = {
            mute:{
                width: 16,
                msg: 'Mute',
                src:'unmutedAudio.png'
            },
            unmute:{
                width: 25,
                msg:'Unmute',
                src:'mutedAudio.png'
            }
        }[audioStatus];

        const muteDiv = <div style={{
            float: 'left',
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width:'fit-content',
            cursor:'pointer'
        }} onClick={()=>setLocalAudio(this)}>
            <img src={`/image/${muteDivConfig.src}`} height={30} width={muteDivConfig.width} />
            <span style={{color:'#D8D8D8',lineHeight:1}}>{muteDivConfig.msg}</span>
        </div>;

        return muteDiv;
    }

    renderLocalStream = ()=>{
        const { stream,remoteStream,speaker,mainStreamIndex,localAudio,audienceMap } = this.state;
        // return stream && <StreamPlayer stream={stream} className='localStream' key={stream.getId()} audio={ localAudio }/>
        //                                    // audio={ localAudio } speaking={speaker == stream.getId()}
        //                                    // />
        if(remoteStream.length<=1) {
            return stream && <StreamPlayer stream={stream} className='localStream'
                                           key={stream.getId()}
                                           audio={ localAudio }
                                           // speaking={speaker == stream.getId()}
            />
        }
        let top = 0;
        let remoteCount = 0;
        if(remoteStream.length>1) {
            let newLocalStreamArray = remoteStream.slice();
            if(stream) {
                const index = _.findIndex(remoteStream,s=>s.getId() == stream.getId());
                if(index==-1){
                    newLocalStreamArray.unshift(stream);
                }
            }
            return newLocalStreamArray.map((stm, i) => {
                const isLocalStream = stm.getId() ===  stream.getId();
                if(speaker&&speaker==stm.getId()){
                    return;
                }
                if(!speaker&&_.last(remoteStream).getId()==stm.getId()){
                    return;
                }
                remoteCount++;
                return   <StreamPlayer
                    className='localStream'
                    key={stm.getId()+i}
                    stream={stm}
                    // fit="contain"
                    // video={true}
                    audio={ isLocalStream ? localAudio : true}
                    label={audienceMap[stm.getId()]||'Unknown User'}
                    style={{top: top + 120 * (remoteCount-1)}}
                    speaking={speaker == stm.getId()}
                />
            })
        }
    }

    waitingForPatient = ()=>{
        const fontStyle = {
            color: '#FFFFFF',
            fontSize: 16,
            fontWeight: 'bold',
            letterSpacing: 0,
            lineHeight: '19px'
        }
        const divStyle = {
            height:'inherit',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:'#686868'
        }
        return <div style={ divStyle} >
                <span style={fontStyle}>Patient is not in the meeting room, please wait for patient to join…</span>
               </div>
    }

    renderMainStream = ()=>{
        const { waitingForPatient } = this;
        const { remoteStream,speaker,audienceMap,stream }  = this.state;
        console.log(remoteStream);
        if(remoteStream.length==0){
            return this.waitingForPatient();
        }
        if(remoteStream.length){
            if(remoteStream.length==1) {
                return <StreamPlayer
                    className='remoteStream'
                    key={_.first(remoteStream).getId()}
                    stream={_.first(remoteStream)}
                    fit="contain"
                    label={audienceMap[_.first(remoteStream).getId()] || 'Unknown user'}
                    // speaking={speaker == speakerStream.getId()}
                />
            }
            // if remote stream has no speaker, get first remote Stream;
            // if remote stream has speaker, get speaker stream;
            let speakerStream = speaker ? _.find(remoteStream,s=>s.getId()==speaker) : _.last(remoteStream);
            // if(stream) {
            //     const index = _.findIndex(remoteStream,s=>s.getId() == stream.getId());
            //     if(index==-1){
            //         remoteStream.push(stream);
            //     }
            // }
            if(speakerStream) {
                return <StreamPlayer
                    className='remoteStream'
                    key={speakerStream.getId()}
                    stream={speakerStream}
                    fit="contain"
                    label={audienceMap[speakerStream.getId()] || 'Unknown user'}
                    // speaking={speaker == speakerStream.getId()}
                />
            }
        }
    }

    renderLeaveButton = ()=>{
        const leaveEvent = ()=>{
            window.close();

        }
        return <div style={{
                                float: 'right',
                                height: 'inherit',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                          }}>
                <button className='agoraLeaveButton' onClick={()=>helper.handleLeaveOneClickVideo(this,this.state.client,leaveEvent)}>Leave Meeting</button>
               </div>
    }

    renderStreamBottomBar = ()=>{
        const {renderMuteDiv, renderLeaveButton,state } = this;
        const { stream } = state;
        return stream&&<div className='localStreamBottomBar'>
                        <div style={{ height:'inherit',paddingLeft:40,paddingRight:40 }}>
                            { renderMuteDiv() }
                            { renderLeaveButton() }
                        </div>
                       </div>
    }

    render(){
        const { renderLocalStream,renderMainStream,renderStreamBottomBar } = this;
        const { stream } = this.state;
        if(!stream){
            return <div></div>
        }
        return <div style={{ display:'flex',flexDirection:'column',height:'100vh'}} className='agoraProvider'>
                    { renderMainStream() }
                    { renderLocalStream() }
                    { renderStreamBottomBar() }
               </div>
    }
}

export default withRouter(AgoraVideoComponent);
