import React from 'react';
import { Button } from 'antd';

import { ENROLL_FROM } from '../../../../lib/constants';
import { CONSENT_TYPE } from '../../main/constants';

const getCompleteText = (fromPlace, consentType, curProgramName) => {
  let programName = curProgramName || 'Unified Care for Chronic Disease Management';
  let followUpActionText = '';
  if(fromPlace === ENROLL_FROM.VISIT_TABLE) {
    followUpActionText = 'Please go to visit worklist and finish up charting.';
  }
  if(fromPlace === ENROLL_FROM.WORK_LIST) {
    followUpActionText = 'Now you can continue in visit worklist and finish up charting.';
  }
  if(!consentType) { // consent form has been signed
    return <React.Fragment>
      Patient has been successfully enrolled in <span className='highlight'>{programName}</span> program. {followUpActionText}
    </React.Fragment>;
  }
  switch(consentType) {
    case CONSENT_TYPE.APP:
      return <React.Fragment>
        Consent form has been sent and Patient has been successfully enrolled in <span className='highlight'>{programName}</span> program. {followUpActionText}
      </React.Fragment>;
    default:
      return <React.Fragment>
        Consent form has been signed and Patient has been successfully enrolled in <span className='highlight'>{programName}</span> program. {followUpActionText}
      </React.Fragment>;
  }
}

const EnrollCompleteComponent = props => {
  const { fromPlace, consentType, curProgramName, handleGoToWorkList, handleCloseModal } = props;
  
  const isFromVisitTable = fromPlace === ENROLL_FROM.VISIT_TABLE;

  return <div className='enroll-patient-complete-container'>
    <img src={'/image/icon_check.png'} width={56} style={{ marginBottom: 30 }}/>
    <div className='enroll-patient-complete-success-message'>
      {getCompleteText(fromPlace, consentType, curProgramName)}
    </div>
    <div className='enroll-patient-complete-buttons'>
      {
        isFromVisitTable &&
        <Button 
          type='primary'
          onClick={handleGoToWorkList}
        >
          Take me to visit worklist now
        </Button>
      }
      <Button 
        type='primary' 
        ghost={isFromVisitTable}
        onClick={handleCloseModal}
      >
        Got it
      </Button>
    </div>
  </div>;
};

export default EnrollCompleteComponent;