import React from 'react';
import { Checkbox,Collapse,Row,Col } from "antd";
import '../style/TesterUserStyle.scss';
const { Panel } = Collapse;

const TestUserComponent = class extends React.Component{

    constructor(props) {
        super(props);
        const { isTestUser } = props
        this.state = {
            isTestUser
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!prevProps.disabled && this.props.disabled ){
            this.setState({isTestUser: this.props.isTestUser})
        }
    }

    renderCollapseArea = ()=>{
       const { isTestUser } =this.state;
       const { headerText, contentText, disabled, onChangeKey } = this.props;
       const activeKey = _.get(this.props, 'activeKey') || this.state.activeKey;
       const onChange = onChangeKey ? onChangeKey : (activeKey) => this.setState({activeKey});
       const user = contentText || 'patient';
       return <Collapse className={'testUserCollapse'} activeKey={activeKey} onChange={onChange}>
                <Panel header={headerText} key="1" disabled={disabled}>
                    <Checkbox onChange={this.handleCheckbox} id='isTestUserCheckbox'
                              style={{ fontWeight:500 }}
                              checked={isTestUser}>This {user} is a test {user} </Checkbox>
                    <div style={{ marginTop: 10,lineHeight:'19px' }}>
                        <p>Note: A test {user} will not behave as a real {user} in this clinic.
                        Any data gathered for this {user} will not be counted for analysis or billing purposes.</p>
                        <p style={{fontWeight:'bold'}}>Please make sure to use “Test” as the last name for all test {user}s:</p>
                    </div>
                </Panel>
              </Collapse>
    }
    handleCheckbox=(e)=>{
        const { localThis } = this.props;
        const { enableEditMode } = this.props;
        if(typeof enableEditMode=='function'){
            enableEditMode(true);
        }
        localThis.setState({
            isTestUser:e.target.checked
        })
        this.setState({
            isTestUser:e.target.checked
        })
    }

    render() {
        return this.renderCollapseArea()

    }
}

export default TestUserComponent;
