import React from 'react';
import {Button, Select,InputNumber,Icon,Popover, Row, Form, Input} from 'antd';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import { graphql, compose } from 'react-apollo'
import { convertDBToSchedule}from 'modulesAll/utils/helpers/task.js';
import { VITAL_TYPE_MAP_FROM_SHORT,VITAL_TYPE_MAP, DEFAULT_VITAL_SCHEDULE  } from 'modulesAll/utils/constants/task';
import WeeklyMealSchedule from './BGScheduleComponent';
import { connect } from 'react-redux'
import vitalsHelper from '../helper/vitalsHelper';
import { modalAction } from 'modulesAll/common/actions'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import AddNewVitalComponent from './AddNewVitalComponent';
import '../../style/vitalMonitoring.styles.scss';
import { bgScheduleValidator } from '../../../../lib/validator';
import { CGMHelperServices } from '../../../CGM/helpers';
import { CGMDropdownContainer } from '../../../CGM/containers/CGMDropdownContainer';

const { Option } = Select;
const { Item } = Form;
const defaultTimes = 4;
const defaultFreq = 'DAILY';

const VitalsMonitoring = class extends React.Component{

    constructor(props) {
        super(props);
        const { hasCGM } = props.cgmContextValue || {};
        const tasks = _.get(props, 'program.tasks')||[];
        
        const enrolledVitals = _.map(tasks,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]) || [];
        if (hasCGM) enrolledVitals.push(CGMHelperServices.brandFieldName);

        const BG = _.first(_.filter(tasks,(t)=>t.type=='BG'))||{};
        const BGSchedule = BG ? convertDBToSchedule(BG) : {};
        let otherVitals = {} ;
        _.forEach(tasks,({ repeat,schedule,type,templateDetails}) =>{
            const target = _.get(schedule,'0.target');
            otherVitals[VITAL_TYPE_MAP_FROM_SHORT[type]] = {
              templateDetails,schedule,type,target,repeat,
              frequencyValue:{
                  times:target,
                  frequency:repeat
              }
           }
        });
        this.state = {
            enrolledVitals,
            BGSchedule,
            // initialBGSchedule:BG,
            ...otherVitals,
            showAddModal: false,
            selectedVitals:enrolledVitals,
        }

    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      const tasks = _.get(this.props, 'program.tasks')||[];
      const enrolledVitals = _.map(tasks,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]) || [];
      if (_.get(this, 'props.cgmContextValue.hasCGM')) {
        enrolledVitals.push(CGMHelperServices.brandFieldName);
      }
      if(prevProps.isEditMode && !this.props.isEditMode){ // when the edit is finished
        const BG = _.first(_.filter(tasks,(t)=>t.type=='BG'))||{};
        const BGSchedule = BG ? convertDBToSchedule(BG) : {};
        let otherVitals = {} ;
        _.forEach(tasks,({ repeat,schedule,type,templateDetails}) =>{
            const target = _.get(schedule,'0.target');
            otherVitals[VITAL_TYPE_MAP_FROM_SHORT[type]] = {
              templateDetails,schedule,type,target,repeat,
              frequencyValue:{
                  times:target,
                  frequency:repeat
              }
           }
        });
        this.setState({enrolledVitals, BGSchedule, ...otherVitals, selectedVitals: enrolledVitals})
      } else if (!_.isEqual(enrolledVitals, prevState.enrolledVitals)) {
        this.setState({ enrolledVitals, selectedVitals: enrolledVitals });
      }
    }

    setShowAddModal=(showAddModal)=>{
      if(!this.props.disabled){
        this.setState({showAddModal})
      }
    }

    setShowVitalsModal=(showVitalsModal)=>{
      this.setState({showVitalsModal})
    }

    setSelectedVitals = (selectedVitals)=>{
      this.setState({selectedVitals});
      this.props.form.setFieldsValue({'selectedVitals': selectedVitals});
    }

    handleRemoveEnrolledVital = async (vitalType)=>{
        const { cgmContextValue } = this.props;
        const { selectedVitals } = this.state;
        const taskId = this.getVitalId(vitalType);
        const res = await vitalsHelper.openConfirmModal(this,taskId,vitalType);
        if (res && res.code === 200 && vitalType === CGMHelperServices.brandFieldName) {
          cgmContextValue.refetch();
          const newSelectedVitals = _.filter(selectedVitals, (type) => type !== vitalType);
          this.setSelectedVitals(newSelectedVitals);
        }
    }
    handleRemoveAddedVital = (vitalType) =>{
        const updatedVitals = _.filter(this.state.selectedVitals,v=>v!==vitalType)
        this.setSelectedVitals(updatedVitals);
        this.setState({
            [vitalType]: null
        })
    }
    getVitalId = (vitalType)=>{
        const { program: { tasks } } = this.props;
        const vital = _.first(_.filter(tasks,(t)=>t.type==VITAL_TYPE_MAP[vitalType]));
        return _.get(vital,'id');
    }

    addLocalVitals = (vitals)=>{
      this.setState({
          ...vitals
      })

      const {getFieldDecorator, getFieldValue, setFieldsValue} = this.props.form;
      _.mapKeys(vitals, (vital, vitalType) => {
        const fieldName = `vitals.${vitalType}`;
        if(!getFieldValue(fieldName)){
          getFieldDecorator(fieldName);
        }
        setFieldsValue({[`vitals.${vitalType}`]: vital})
      })
    }

    renderBGSchedule = ()=>{
      const {getFieldDecorator} = this.props.form;
      const weeklyMealSchedule = _.get(this.state, 'BGSchedule.weeklyMealSchedule')
        return  <div>
                    <div className='vitalHeaderWrapper'>
                     <strong className={'vitalHeader'}>Glucose Management</strong>
                     {this.renderRemoveButton('Blood Glucose')}
                    </div>
                    <Form.Item>
                      {
                        getFieldDecorator('BGSchedule.weeklyMealSchedule', {
                          initialValue: weeklyMealSchedule, 
                          rules:[{
                            validator: (rule, value, callback) => {
                              callback(bgScheduleValidator(value));
                            }
                          }]})(
                          <WeeklyMealSchedule
                          directEdit
                          updateForm={this.setWeeklyMealSchedule}
                          weeklyMealSchedule={weeklyMealSchedule}
                          disabled={this.props.disabled}
                        />
                        )
                      }
                    </Form.Item>
                </div>
    }


    renderAddVitalButton = ()=>{
        const { state,setShowAddModal, setSelectedVitals, addLocalVitals } = this;
        const enrolledProgramId = _.get(this.props, 'program.id');
        const disabled = _.get(this.props, 'disabled');
        const {showAddModal, selectedVitals} = state;
          return <Popover content={
                                 <AddNewVitalComponent setSelectedVitals={setSelectedVitals}
                                                       selectedVitals={selectedVitals}
                                                       setShowAddModal={setShowAddModal}
                                                       localThis={this}
                                                       addLocalVitals={addLocalVitals}
                                                       localForm={this.props.form}
                                 />}
                        visible={ enrolledProgramId ?showAddModal: false }
                        destroyTooltipOnHide={true}
                        trigger='click'
                        onVisibleChange={ setShowAddModal }
                        overlayClassName={'vitalPopover'}
                        title={'Choose a vital you want to add'}
                >
                  <Button type='link' 
                          className='addVitalsBtn' 
                          disabled={enrolledProgramId ? disabled : true}>
                          <Icon type='plus'/> Add Vitals
                  </Button>
               </Popover>
    }



    renderRemoveButton = (vitalType)=>{
      const { handleRemoveEnrolledVital,handleRemoveAddedVital } = this;
      const { enrolledVitals } = this.state;
      if(_.includes(enrolledVitals,vitalType)) {
          return this.props.disabled ? 
          <div style={{ float: 'right', color: '#777777',textDecoration: 'underline', cursor:'pointer', fontWeight: 'normal', fontSize: '13px', lineHeight:'14px'}}
          className={vitalType+'-remove-btn'}
          id={vitalType+'-remove-btn'}
          >Remove</div> :
          <div style={{ float: 'right', color: '#777777',textDecoration: 'underline', cursor:'pointer', fontWeight: 'normal', fontSize: '13px', lineHeight: '14px', zIndex: 2, position: 'relative' }}
          className={vitalType+'-remove-btn'}
          id={vitalType+'-remove-btn'}
          onClick={() => handleRemoveEnrolledVital(vitalType)}>Remove</div>
      }
      else{
          return <div style={{ float: 'right', zIndex: 2, position: 'relative' }} className={vitalType+'-cancel-btn'} id={vitalType+'-cancel-btn'}>
            <Icon type='close' onClick={()=>handleRemoveAddedVital(vitalType)}/>
            </div>
      }
    }

    setVitalInfo =(vitalType,unit,value)=>{
      if(unit=='times'&&(!value||!_.isNumber(value))) {
          value = 4;
      };

      let vital = this.state[vitalType]||{};
      _.set(vital,`frequencyValue.${unit}`, value);
      this.setState({
          [vitalType]:vital
      })

      this.props.form.setFieldsValue({[`vitals.${vitalType}`]: vital})
  }

  renderExercise = ()=>{
    const tasks = _.get(this,'props.program.tasks',[]);
    const exercise = _.first(_.filter(tasks,t=>t.type==='EXERCISE'));
    const exerciseGoal = _.get(exercise,'threshold.0.exerciseGoal',{});
    const { timeUnit,target } = exerciseGoal;
    const stepsPerUnit = `${Math.round(target/DEFAULT_VITAL_SCHEDULE['Exercise Goal'][timeUnit])} Steps / Day `;
    const noExerciseStr = 'User hasn’t activated this function.'
    return <div style={{ display:'flex',flexDirection:'column',marginBottom:20, width:260}}>
                <div className='vitalHeaderWrapper'>
                    <strong className={'vitalHeader'}>Exercise</strong>
                    { this.renderRemoveButton('Exercise Goal') }
                </div>
                <div>
                    { timeUnit&&target ? stepsPerUnit:noExerciseStr}
                </div>
            </div>

  }

    renderOtherVitalSchedule =(vitalType)=>{
        const {form, disabled} = this.props;
        const vitalData = _.get(this.state,`${vitalType}.frequencyValue`) || {};
        const frequency = vitalData.frequency || defaultFreq;
        const times = vitalData.times || defaultTimes;
        const fieldName = `vitals.${vitalType}`;
        form.getFieldDecorator(fieldName, {initialValue: _.get(this.state,`${vitalType}`)});

        return <div style={{ display:'flex',flexDirection:'column',marginBottom:20,fontWeight:700 }} key={`${vitalType}`}>
                    <div>
                        <strong className={'vitalHeader'}>{vitalType}</strong>
                        { this.renderRemoveButton(vitalType) }
                    </div>
                    <Row style={{display: 'flex'}}>
                              <InputNumber min={1} max={7}
                                      defaultValue={times}
                                      value={times}
                                      disabled={disabled}
                                      onChange={(value)=>this.setVitalInfo(vitalType,'times',value)}
                                      style={{width: 120, marginRight: 20}}
                                      className='vitalValInput'
                              />
         
                              <Select defaultActiveFirstOption={false}
                                  defaultValue={frequency}
                                  value={frequency}
                                  allowClear={ false }
                                  disabled={disabled}
                                  className='vitalFreqInput'
                                  style={{width:120}}
                                  onChange={(value)=>this.setVitalInfo(vitalType,'frequency',value)}
                              >
                                <Option value={'DAILY'}>Day</Option>
                                <Option value={'WEEKLY'}>Week</Option>
                                <Option value={'MONTHLY'}>Month</Option>
                              </Select>

                    </Row>
               </div>
    }

    renderVitals = ()=>{
        const { renderBGSchedule, renderOtherVitalSchedule, renderExercise } = this;
        const { selectedVitals } = this.state;
        let content =[];
        const customVitalSchedule = ['Blood Glucose', 'Exercise Goal', CGMHelperServices.brandFieldName];

        if(_.includes(selectedVitals,'Blood Glucose')) {
            content.push(<div className={'col-lg-18'} style={{marginLeft: '23px'}} key={'bg'}>{renderBGSchedule()}</div>);
        }
        let rightDiv = [];
        _.filter(selectedVitals,t=>!customVitalSchedule.includes(t)).forEach(t=>rightDiv.push(<div className='vital-row' key={t}><div style={{ display:'flex' }}>{renderOtherVitalSchedule(t)}</div></div>))

        if (_.includes(selectedVitals, CGMHelperServices.brandFieldName)) {
          rightDiv.push((
            <div 
              className={'vital-row vitals-monitoring-cgm'} 
              key={_.get(rightDiv,'length',0)+1}
            >
              <div className="vitals-monitoring-cgm__wrapper">
                { this.renderRemoveButton(CGMHelperServices.brandFieldName) }
                <CGMDropdownContainer form={this.props.form} disabled={this.props.disabled} />
              </div>
            </div>
          ));
        }

        if (_.includes(selectedVitals, 'Exercise Goal')) {
          rightDiv.push((
            <div 
              className={'vital-row'} 
              key={_.get(rightDiv,'length',0)+1}
              style={{ marginLeft: -1 }}
            >
              <div style={{ display:'flex' }}>
                {renderExercise('exercise')}
              </div>
            </div>
          ))
        }

        if(!_.isEmpty(rightDiv))content.push(<div className={'col-lg-6'} style={{margin:'0 21px' }} key='other-vital'>{rightDiv}</div>)
        return _.isEmpty(content) ? '':<Row style={{ display:'flex' }}>
            {content}
        </Row>
    }

    setWeeklyMealSchedule = (weeklyMealSchedule) => {
      const {setFieldsValue, getFieldValue} = this.props.form;
      let BGSchedule = {...getFieldValue('BGSchedule')};
      BGSchedule.weeklyMealSchedule = weeklyMealSchedule;
      this.setState({BGSchedule});
      setFieldsValue({'BGSchedule': BGSchedule});
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const { disabled, showBehavioralGoals } = this.props;
        // only show the note field when NOT showing behavioral goals
        const { hasNote } = this.state;
        getFieldDecorator('selectedVitals',{initialValue: this.state.selectedVitals});
        const contentClassName = this.props.isWorkList ? 'vital-morningtoring-content-worklist' : 'vital-monitoring-content';
        return  <div>
                <div className='vitalMonitoring'>
                  <div className='goals-list-items'>
                   Vitals Monitoring
                  </div>
                  <div className ={contentClassName}>
                  {this.renderVitals()}
                  <Row style={{padding: '10px 0px'}}>{this.renderAddVitalButton()}</Row>
                  </div>
               </div>
               </div>
    }
}


const mapToDispatch = (dispatch)=>{
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal:(e)=>dispatch(openErrorModal(e))
    }
}
export default compose( graphql(editEnrolledProgram, {name: 'editEnrolledProgram'}),connect(null,mapToDispatch))(VitalsMonitoring);
