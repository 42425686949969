import { React, IHSearchTable, _, IHButton } from 'ihcomponent';
import UserClass from 'modulesAll/graphql/class/User';
import { browserHistory } from 'react-router';
import Store from 'libModule/reduxStore'
import I18N from 'modulesAll/I18N';

export default class extends React.Component {
  static displayName = 'provider/ProviderList/components/ProviderListComponent'

  componentWillUnmount() {
    if (! new RegExp(/^\/provider/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null)
    }
  }

  getTableProps() {
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, userList } = data;

    return {
      title : `Providers (${_.get(userList, 'pageInfo.total', 0)})`,
      inputPlaceholder : `Search by Name or ${I18N.get('keywords.ProviderNRIC/FIN')}`,
      initSearchValue : search,
      onSearchEnter,
      onSearch: onTableSearch,
      onChange: onTableChange,
      onRow(doc) {
        return {
          onClick: () => browserHistory.push(`/providers/profile/${doc._get('id')}`)
        }
      },
      loading,
      dataSource: _.get(userList, 'data', []).map(v => new UserClass(v)),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(userList, 'pageInfo.total', 0),
      },
      columns : [
        {
          title : 'Name',
          key : 'fullName',
          width : '20%',
          render : (t, doc) => doc._get('profile.fullName'),
        },
        {
          title : 'Login ID',
          key : 'username',
          width : '15%',
          render : (id, doc) => doc._get('username')
        },
        {
          title : 'Role',
          key : 'role',
          width : '10%',
          render : (t, doc) => {
            // return doc._get('allRoles[0].name');
              // refactored from allRole[0] to selectedRole(IH-1052)
              return doc._get('selectedRole.name');

          }
        },
        {
          title : 'Specialty',
          key : 'specialty',
          width : '10%',
          render : (t, doc) => doc._get('profile.specialty'),
        },
        {
          title : 'Department',
          key : 'department',
          width : '20%',
          render : (t, doc) => doc._get('profile.department'),
        },
        {
          title : I18N.get('keywords.ProviderNRIC/FIN'),
          key : 'nric',
          width : '15%',
          render : (t, doc) => doc._get('profile.employeeId'),
          // render : (t, doc) => doc.format('NRIC'),
        }
        // {
        //   title : 'Prof Reg Num',
        //   key : 'employeeId',
        //   width : '10%',
        //   render : (t, doc) => doc._get('profile.employeeId'),
        // },
      ],
    }
  }

  render(){
    return (
      <div className="vsm-main-page vsm-provider-list-main">
        <div className="vsm-main-table">
          <div className="v-rd">
            <IHButton label="Register New Provider" size="large" bsStyle="primary"
              onClick={() => {
                browserHistory.push(`/providers/new`);
              }}
            />
          </div>
          <div className="v-table">
            <IHSearchTable {...this.getTableProps()} />
          </div>
        </div>
      </div>
    );
  }
}
