import {
    React,
    BaseComponent,
    ND,
    _
} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
    render(){


        const props = _.omit(this.props, ['steps'])

        return (
            <ND.Steps className="IH-Step" {...props}>
                {
                    _.map(this.props.steps, (item, i)=>{
                        return this.renderEachStep(item, i)
                    })
                }
            </ND.Steps>
        )
    }

    renderEachStep(item, index){
        return (
            <ND.Steps.Step key={index} {...item} />
        )
    }
}

Component.propTypes = {
    //title, description, icon, status
    steps : PropTypes.array.isRequired
}


export default Component
