export default {

  Navigation: {
    prefix: 'Navigate To',
    termsOfUse: 'View Terms Of Use'
  },

  login: {
    step1: 'Login',
    step2: {
      verify2fa: 'Verify 2FA Code',
      resend2fa: 'Resend 2FA Code'
    },
    requestpassword: 'Request Reset Password',
    resetpassword: 'Reset Password',
    passwordExpired: 'Password Expired'
  },

  logout: 'Logout',

  vendor: {
    searchPatient: 'Search Patient',
    replaceDevices: 'Replace Devices',
    returnDevices: 'Return Devices',
    viewDeviceHistory: 'View Device History',
    loanForm: 'View Loan Form',
    noloanForm: 'No Available Loan Form',
    broadcast: {
      create: 'Create Broadcast',
      edit: 'Edit Broadcast',
      delete: 'Delete Broadcast'
    }
  },

  superadmin: {
    audittrail: 'Navigate To Audit Trail'
  },

  admin: {
    tables: {
      admin_provider_list: {
        search: 'Search Provider'
      },
      care_team_list: {
        search: 'Search Care Team'
      },
      admin_program_list: {
        search: 'Search Programme'
      },
      admin_assessment_list: {
        search: 'Search Assessment'
      },
      admin_survey_list: {
        search: 'Search Survey'
      },
      admin_careteam_doctor_list: {
        search: 'Search Care Team Doctor'
      },
      admin_careteam_manager_list: {
        search: 'Search Care Team Manager'
      }
    },
    provider: {
      create : 'Create Provider',
      edit: 'Edit Provider',
      editPhone: 'Edit Provider Phone',
      editEmail: 'Edit Provider Email',
      resendActivationEmail: 'Resend Provider Activation Code'
    },
    careTeam: {
      create: 'Create Care Team',
      edit: 'Edit Care Team',
      delete: 'Delete Care Team'
    },
    assessment: {
      create: 'Create Assessment',
      edit: 'Edit Assessment',
    },
    survey: {
      create: 'Create Survey',
      edit: 'Edit Survey'
    },
    programme: {
      edit: {
        save_program_info: 'Save Programme Info Tab',
        save_care_plan: 'Save Care Plan Tab',
        save_device: 'Save Device Tab'
      },
      create: 'Create Admin Programme',
      completeProgramme: 'Complete Admin Programme Creation',
      delete: 'Delete Admin Programme',
      view: {
        info: 'Select Programme Info Tab',
        careplan: 'Select Care Plan Tab',
        device:'Select Device Tab'
      },
      task: {
        assessment: {
          edit: 'Edit Assessment Task'
        },
        survey: {
          edit: 'Edit Survey Task'
        },
        vital: {
          add: 'Add Vital Task',
          edit: 'Edit Vital Task'
        },
        remove: 'Remove Assessment / Survey',
        save: 'Save Assessment / Survey'
      },
      device: {
        setDevices: 'Set Devices'
      }
    }
  },

  provider: {
    tables: {
      patientList: {
        search: 'Search Patient'
      },
      nurse_assessment_list: {
        search: 'Search Assessment'
      },
      nurse_survey_list: {
        search: 'Search Survey'
      },
      provider_mytasks_list: {
        search: 'Search Task'
      },
      provider_programme_list: {
        search: 'Search Programme'
      }
    },
    dashboard: {
      selectProgramme: 'Select Programme'
    },
    tasks:{
      completeTask: 'Complete Task',
      uncompleteTask: 'Uncomplete Task',
      createTask: 'Create Task',
      editTask: 'Edit Task',
      deleteTask: 'Delete Task',
      currentTasks: 'View Current Tasks',
      completedTasks: 'View Completed Tasks'
    },
    alerts:{
      create_provider_note: 'Create Provider Note',
      create_alert_note: 'Create Alerts Note',
      create_bg_note: 'Create Blood Glucose Note',
      create_bp_note: 'Create Blood Pressure Note',
      create_hr_note: 'Create Heart Rate Note',
      create_hs_note: 'Create Weight Note',
      create_assessment_note: 'Create Assessment / Survey Note',
      mark_provider_note_error: 'Mark Provider Note Error',
      alert_review: 'Review Alert',
      alert_close: 'Close Alert'
    },
    patient: {
      enrollInNewProgram: {
        edit_enrolled_program_assessment: 'Edit Enrolled Programme Assessment',
        save_care_plan_tab: 'Save Care Plan Tab',
        remove: 'Remove Assessment / Survey',
        save: 'Save Assessment / Survey',
        back_to_program_tab: 'Back To Programme Tab',
        edit_enrolled_program_vital: 'Edit Enrolled Programme Vital',
        edit_enrolled_program_startDate: 'Edit Enrolled Programme Start Date',
        save_device_tab: 'Save Device Tab',
        back_to_care_plan_tab: 'Back To Care Plan Tab',
        select_careplan_tab: 'Select Care Plan Tab',
        select_programme_tab: 'Select Programme Tab',
        select_device_tab: 'Select Device Tab',
        save_program_tab: 'Save Programme Tab',
        edit_enrolled_program_survey: 'Edit Enrolled Programme Survey',
        enroll_in_new_program: 'Enroll In New Programme',
        delete_enrolled_program: 'Delete Enrolled Programme'
      },
      dischargeProgramme: 'Discharge Patient From Programme',
      dischargeSuccessful: 'Discharge Successful',
      dischargeUnsuccessful: 'Discharge Unsuccessful',
      cancelDischarge: 'Cancel Discharge',
      resumeProgramme: 'Resume Patient From Programmes',
      suspendProgramme: 'Suspend Patient From Programmes',
      resendActivationEmail: 'Resend Account Activation Email',
      editEmail: 'Edit Patient Email',
      editHomePhone: 'Edit Patient Home Phone',
      editMobilePhone: 'Edit Patient Mobile Phone',
      editProfile: 'Edit Patient Profile',
      reimportProfile: 'Reimport Patient Profile',
      searchImportPatient: 'Search Import Patient',
      selectImportPatient: 'Select Import Patient',
      registerNew: 'Register New Patient',
      continueToEnrollment: 'Continue To Enrollment',
      generateFullReport: 'Generate Full Report',
      generateNEHRReport: 'Generate NEHR Report',
      cancelGenerateNEHRReport: 'Cancel Generate NEHR Report',
      cancelSendNEHRReport: 'Cancel Send NEHR Report',
      cancelGenerateFullReport: 'Cancel Generate Full Report',
      downloadFullReport: 'Download Full Report',
      downloadNEHRReport: 'Download NEHR Report',
      sendReportToNEHR: 'Send Report To NEHR',
      printConsentForm: 'Print Consent Form',
      extendProgrammeDuration: 'Extend Programme Duration',
      replaceDevices: 'Replace Devices',
      returnDevices: 'Return Devices',
      addCareGiver: 'Add Caregiver',
      editCareGiver: 'Edit Caregiver',
      deleteCaregiver: 'Delete Caregiver',
      loanForm: 'View Loan Form',
      noloanForm: 'No Available Loan Form',
      viewDeviceHistory: 'View Device History'
    },
    taggedpatients: {
      tagPatient: 'Tag Patient',
      untagPatient: 'Untag Patient',
      viewTaggedPatient: 'View Tagged Patient'
    },
    onetimetask: {
      createOneTimeTask: 'Create One Time Task'
    }
  },

  patient: {
    myTask: {
      insertMeasurement: 'Insert Measurement',
      completeAssessment: 'Complete Assessment / Survey',
      draftAssessment: 'Save Assessment / Survey',
      updateDraftAssessment: 'Update Assessment / Survey'
    },
    setting: {
      editSetting: 'Edit User Settings'
    }
  }

};
