import gql from "graphql-tag";

export  default gql`query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort) {
        pageInfo{
     	    total
            lastPage
        }
        data{    
          id
          checkinAt
          appointmentAt
          details{
            
            temperature
            
        }
        
     }
     }

}`
