import gql from 'graphql-tag';

export const  updatePatientAssignee = gql`mutation updatePatientAssignee($memberId:EID!,$assignedToRD: EID,$assignedToCA: EID, $assignedToCANotNecessary: Boolean, $assignedToRDNotNecessary: Boolean) {
  updatePatientAssignee(memberId:$memberId,assignedToCA:$assignedToCA,
    assignedToRD:$assignedToRD,assignedToCANotNecessary:$assignedToCANotNecessary ,assignedToRDNotNecessary: $assignedToRDNotNecessary){
    assignedToRD{
      id
      profile{
        fullName
      }
    }
    assignedToCA{
      id
      profile{
        fullName
      }
    }
    assignedToCANotNecessary
    assignedToRDNotNecessary
  }
}`;
