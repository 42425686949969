import { React, IHButton, IHLoading, moment } from 'ihcomponent'
import { Row, Col, Avatar, Button, Tag, Tooltip, Menu, Dropdown, Icon, Modal } from 'antd';
import _, { get,has } from 'lodash';
import { connect } from 'react-redux'
import UserClass from 'modulesAll/graphql/class/User'
import { goPath, RequestCache } from 'libModule/utils'
import I18N from 'modulesAll/I18N';
import Client from 'libModule/gqlClient';
import enrolledProgram from "graphqlModule/enrolledProgram"
import actions from '../../../chat/actions';
import * as R from 'ramda';
import './bootstrap_white.css';
import { API } from 'modulesAll/chat/actions/api';
import { DATE_HELPERS } from '../../../../lib/helpers/date-helpers';
import patientAction from '../actions';
import { compose } from 'recompose';
import { trace as traceF } from '../containers/utils';
import { graphql } from "react-apollo/index";
import gql from "graphql-tag";
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { TimeSpentComponent } from '../../TimeSpent/components/';
import yearOfDiagnoseMap from '../../../patientList/registerNew/constants/yearOfDiagnose';
// import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';
// import getPatientAlertConfig from 'modulesAll/smartAlert/query/getPatientAlertConfig';
import phoneListInfo from 'graphqlModule/phoneListInfo.js';
import getTempEnrolledPatient from 'modulesAll/graphql/getTempEnrolledPatient';
import PatientSmartAlertContainer from 'modulesAll/smartAlert/container/PatientSmartAlertTasksContainer';
import NumberFormat from 'react-number-format';
import { clicktoDial, ccpUrl } from '../../../call/helper/helpers';
import callActions from '../../../call/actions';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { genHC } from './HealthConditionRenderer';
import ConsentFormComponent from './consentForm/ConsentFormComponent';
import { deviceHelpers } from '../helper';
import { formatDateMM } from "../../../utils/helpers/task";
import { VITALS_ORDER } from 'libModule/constants';
import ViewMoreEffect from './ViewMoreEffect';
import DoctorReferralIcon from '../../../remoteEnrollment/component/DoctorReferralIcon';
import remoteStatus  from '../../../remoteEnrollment/constant/remoteStatus';
import { helpers } from '../../../visitNewWorkFlow/helper';
import programHelpers from '../../../careplanNew/helpers';
import upHelpers from './patientPreferences/helpers';
import PatientTaskStarComponent from '../../../taskAssignment/components/PatientTaskStarComponent';
import MNTReferralFormComponent from '../../../mntReferral/component/MNTReferralFormComponent';
import PatientPreferencesText from './patientPreferences/PatientPreferencesText';
import ClaimBillableTimeComponent from './ClaimBillableTimeComponent';
import { EVENT_TYPES } from '../../../idleTime/constant';
import { checkIsInRole } from '../../../../lib/helpers/role-helpers';
import BillableTimeComponent from './BillableTimeComponent';
import ReferStatusInPatientHeaderComponent from '../../../referAndEnroll/component/ReferStatusInPatientHeaderComponent';
import { checkEnrollStatus } from '../../../referAndEnroll/helper';
import { renderSelfTag, renderComplexityTag } from '../../../visitsPage/helpers';
import { withComplexity } from '../API';
import { eventHelperCreator, EVENT_MAPS } from '../../../../lib/helpers/event-helpers';
import HiatusIndicator from '../../hiatusStatus/components/Indicator';
import hsConstants from '../../hiatusStatus/constants';
import { getSelectedCategoryValues, checkIfHiatusActive } from '../../hiatusStatus/helpers';
import AssignToCTFormModal from "../../../assignCT/component/AssignCTFormModal";
import Column from 'antd/lib/table/Column';
import { MVPSubscriptionStatusContainer } from '../../MVPSubsctiption/container/MVPSubscriptionStatusContainer';
import { CGMVitalIconComponent } from '../../../CGM/components/CGMVitalIconComponent/CGMVitalIconComponent';
import { makeCGMContextConsumer } from '../../../CGM/context/makeCGMContextConsumer';
import { Fragment } from 'react';
const debug = false;
const trace = debug ? traceF : () => R.identity;
const dateformatString = I18N.get('dates.L');
const eventHelper = eventHelperCreator(EVENT_MAPS.complexity, 'memberDetail');
const defaultEmptyValue = "Not Assigned";
const DISPLAY_NOT_NECESSARY_VALUE = "Not Necessary";

const wrapper = class extends React.Component {
    static displayName = 'patient/main/components/MemberDetail'

    constructor(props) {
        super(props);
        this.onAvatarError = this.onAvatarError.bind(this);
        this.ref = React.createRef();
        this.state = {
            phoneNumber: '',
            showModal:false,
            modalData: null,
            showConsentForm:false,
            refetchMontlySpent: null,
            showReferralModal:false,
            showForm:false
        }

        this.makeACallError = this.makeACallError.bind(this);
        //this.setEnrollmentStatus();
    }

    setShowForm = (showForm) => {
      this.setState({showForm});
    }

    get isCcmOrRpmEnrolled() {
      return !!this.checkPatientProgramEnrolledByType('CCM') || !!this.checkPatientProgramEnrolledByType('RPM');
    }

    get hasConsent() {
      const { consentInfo } = this.props;
      return consentInfo && consentInfo.consent;
    }

    get showPatientDetailsButton() {
      return ((_.get(this.props, 'currentProgram.status') || 'DISCHARGED') !== 'DISCHARGED');
    }

    componentDidUpdate = prevProps => {
      if(
        _.get(this, 'props.data.loading') !== _.get(prevProps, 'data.loading') ||
        !_.isEqual(_.get(prevProps, 'data.user'), _.get(this, 'props.data.user')) ||
        !_.isEqual(_.get(prevProps,'patientReferral.status'),_.get(this,'props.patientReferral.status') )
      ) {
        this.setEnrollmentStatus();
      }
      if (!prevProps.showStickyHeader && this.props.showStickyHeader) {
        this.updateStickySize();
      }
    }

    componentDidMount() {
      eventHelper.listen(this.eventHandlerCb);
      window.addEventListener('resize', () => {
        this.updateStickySize();
      });
    }

    componentDidUnmount() {
      eventHelper.remove(this.eventHandlerCb);
    }

    eventHandlerCb = (e) => {
      this.props.patientComplexityRefetch();
  }

    updateStickySize = () => {
      const container = document.getElementById('sticky-container');
      if (container) {
        const left = container.getBoundingClientRect().left;
        this.setState({ providerAlertStyle: { width: container.offsetWidth, left } });
        // console.log({ width: container.offsetWidth, left });
      }
    }

    setEnrollmentStatus = () => {
      const programStatus = _.get(this, 'props.currentProgram.status');
      const statusMap = {
        STARTED: 'Enrolled',
        DISCHARGED: 'Discharged'
      };
      const curStatus = statusMap[programStatus] || 'Unenrolled';
      this.props.setEnrollmentStatus(curStatus);
      this.isUnenrolledPatient = !_.startsWith(curStatus, 'Enrolled');
      this.isCurrentDischarged = _.startsWith(curStatus, 'Discharged');

      // for display only
      this.enrollmentStatus = this.getEnrollmentStatus();
    }

    setRefetchMontlySpent = (refetchMontlySpent)=>{
        this.setState({
            refetchMontlySpent
        })
    }
    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }
    setShowConsentForm = (showConsentForm,fn)=>{
        this.setState({ showConsentForm },()=>{
            if(fn) fn();
        })
    }

    renderShowSendConsentForm=()=>{
        const { props,setShowConsentForm } = this;
        const { consentInfo,patientId,refetchConsentInfo,data,currentProgram } = props;
        const memberFullname = _.get(data, 'user.profile.fullName');
        const appLanguage = _.get(data, 'user.profile.appLanguage.code');
        const spokenLanguage = _.get(data, 'user.profile.language.code');
        const consentProviderName = _.get(currentProgram,'team.0.consentProviderName');
        const phoneNumbers = _.get(data, 'user.phone', []).map(p => '+' + p.countryCode + p.number);
        return <Modal visible={true} footer={false} width={'90%'} className='consentFormModal' onCancel={()=>this.setShowConsentForm(false)}>
                {/* <SendConsentFormComponent consentInfo={consentInfo}
                                          memberId={patientId}
                                          setShowConsentForm={setShowConsentForm}
                                          refetchConsentInfo={refetchConsentInfo} /> */}
                <ConsentFormComponent
                    language={appLanguage || spokenLanguage || 'EL'}
                    consentInfo={consentInfo}
                    consentProviderName={consentProviderName}
                    memberId={patientId}
                    memberFullname={memberFullname}
                    setShowConsentForm={setShowConsentForm}
                    refetchConsentInfo={refetchConsentInfo}
                    phoneNumbers={phoneNumbers}
                    isEnrolled={!this.isUnenrolledPatient}
                />
               </Modal>
    }

    renderSendConsentButton = ()=>{
        const { props, setShowConsentForm } = this;
        const { consentInfo } = props;
        const consent  = _.get(consentInfo,'consent');

        let msg = '';
        if(consent){
          return msg;
        }
        if(typeof consent!='boolean'){
          msg ='Send consent form'
        }
        else if(!consent) {
          // msg = 'Need to sign consent form'
          msg = 'Resend consent form';
        }
        return (!this.isCurrentDischarged && !this.isUnenrolledPatient )&& <span
          className='v-patient-link'
          style={{ marginLeft: 10 }}
          onClick={()=>setShowConsentForm(true)}
        >
          <Icon type="info-circle" style={{color:'#F5222E'}}/>
          { msg }
        </span>
    }

    setShowReferralModal=(showReferralModal)=>{
        this.setState({showReferralModal})
    }

    renderMNTEnroll = ()=>{
        const { setShowReferralModal } = this;
        const mntEnrolled = !!this.checkPatientProgramEnrolledByType('MNT');
        const { showReferralModal } = this.state;
        const { data }  = this.props;
        const user = _.get(data,'user');
        const hasMNTReferred = !!_.get(user,'patientMntProgram');
        const mntStatus = _.get(user,'patientMntProgram.status')||'Potential';
        const isCAOrRDOrMA = checkIsInRole(['CA','RD','MA']);
        const isMD = checkIsInRole(['Doctor']);
        const show = (isMD || (isCAOrRDOrMA&&hasMNTReferred)) ? 'block' :'none';
        return <div>
                 <span onClick={()=>setShowReferralModal(true)} style={{ display:show,textDecoration:'underline',color:'#3177c9',marginLeft:5,cursor:'pointer',textTransform: 'capitalize' }}>{`MNT ${mntStatus.toLowerCase()}`}</span>
                 { showReferralModal &&
                     <Modal width={'fit-content'} onCancel={()=>setShowReferralModal(false)} footer={false} visible={showReferralModal}>
                                <MNTReferralFormComponent selectedUser={user} />
                     </Modal>
                 }
               </div>
    }

    renderMR = ()=>{
        const { data }  = this.props;
        const identification = _.get(data,'user.identification[0].value');
        return <div className='v-tag'>
          MR #:
          <span style={{ marginRight: 5 }}>
            {identification}
          </span>
        </div>;
    }

    renderPCP = () => {
      return <div style={{ marginRight: 23 }} className={"profile-keyvalue"}>
          <span style={{ fontWeight: 'bold' }}>Provider: </span>
          <span className='v-tag'>{ this.uc.format('PCP') }</span>
      </div>;
    }

    checkPatientProgramEnrolledByType = (type)=>{
        const user = _.get(this, 'props.data.user');
        let programCategories = _.get(user, 'profile.programCategories')||[];
        let res = _.filter(programCategories,p=>p.enrolled && p.name==type);
        return res.length;
    }

    renderNoHealthCondition = () => {
      return <div style={{ display: 'flex', lineHeight: '19.07px' }}>
                <Icon type='info-circle' theme='filled' style={{ color: '#DA6453', marginRight: 5, marginTop: 2 }} />
                <span style={{ color: '#DA6453' }}>
                  {I18N.get('healthCondition.noDiagnosisNote.patientHeader')}
                </span>
              </div>;
    }

    renderHealthConditions = renderProps => {
      return !this.isUnenrolledPatient && <ViewMoreEffect
        elements={genHC(this.uc)}
        renderEl={(el, key) => <span className='v-tag v-hc' key={key}>{el}</span>}
        elementContainer={elNodes =>
          <div>
            <span style={{ fontWeight: 'bold', marginRight: 5 }}>
              Health Conditions:
            </span>
            { _.get(elNodes, 'length') ? elNodes : this.renderNoHealthCondition() }
          </div>
        }
        childNodesPath='childNodes.0.childNodes'
        customViewMoreEl={({ key, wrapperId, cutOffNodes, renderEl, onClick }) => {
          // TODO: tooltip showing not pretty
          return <Tooltip
            overlayClassName='v-patient-more-hc'
            trigger='hover'
            title={_.map(cutOffNodes, renderEl)}
            getPopupContainer={() => document.getElementById(wrapperId)}
          >
            <a key={key} onClick={onClick}>view more</a>
          </Tooltip>
        }}
        useDefaultViewMore={true}
        {...renderProps}
      />
    }

    renderConnectedDevices = () => !this.isUnenrolledPatient && deviceHelpers.renderDevices(this.props);

    renderInPatientProfile = (menuOpened = false, showChat = false) => {
      const patientId = _.get(this, 'props.data.user.id');
      const leftStyles = {
        minWidth:
          // (menuOpened && showChat) ? '38%' :
          // showChat ? '32%' :
          menuOpened ? '30%' :
          '30%'
      };
      const showBillableTimeBtn = this.showPatientDetailsButton && this.hasConsent && this.isCcmOrRpmEnrolled;
      const showBillableTime = !this.isUnenrolledPatient && this.isCcmOrRpmEnrolled;

      return <div style={{ display: 'flex', marginBottom: 12 }}>
          {showBillableTime && <div style={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', marginBottom: 5, padding: '10px 20px' }}>
                <div style={{ marginRight: 10 }}>{this.renderAvator(patientId)}</div>
                <div className='v-detail-patient-name'>
                  { this.renderName(false) }
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', padding: '15px 30px', flex: 1 }}>
              {this.renderTimeSpend(showBillableTimeBtn)}
            </div>
          </div>}
          <Row className='vsm-patient-member-detail patient-wrapper-6188'>
          {/* <div style={{ flex: 1 }}> */}
            {!showBillableTime && <Col span={1} style={{ textAlign: 'center' }}>
              {this.renderAvator(patientId)}
            </Col>}
            <div className='v-detail-wrapper patient-profile-page'>
              {!showBillableTime
                ? <div className='v-detail-patient-name'>
                  { this.renderName() }
                </div>
                : <div style={{ marginBottom: 7 }}>
                  { this.renderPatientTags() }
                </div>
              }
              <div className='v-detail-patient-information'>
                <div
                  className='v-detail-patient-information-left'
                  style={leftStyles}
                >
                  <div style={{ display: 'inline-table' }} >
                    { this.renderMR() }
                    { this.renderGenderBirthdayAge() }
                    { this.renderSpokenAndAppLanguage() }
                  </div>
                  <div className='v-patient-split-tag'>
                    { this.renderBodyInfo(['Height', 'Weight']) }
                  </div>
                  <div className='v-patient-split-tag'>
                    { this.renderBodyInfo(['BMI', 'BMR', 'AdjBW']) }
                  </div>
                  <div className='v-patient-split-tag'>
                    { this.renderMotivation() }
                    { this.renderTechLevel() }
                  </div>
                  <div className='v-patient-split-tag'>
                    { this.renderPhoneInfo() }
                    { this.renderMobilePhones() }
                    { this.renderHomePhones() }
                  </div>
                  {
                    !_.get(this.props, 'data.user.hasPassword') &&
                    <a
                      style={{ textDecoration: 'underline', color: '#717171', width: 'fit-content' }}
                      onClick={() => goPath(`patients/${patientId}/new-password`)}
                    >
                      {I18N.get('auth.password.createAppPw')}
                    </a>
                  }
                </div>
                <div
                  className='v-detail-patient-information-right'
                  style={{
                    minWidth: `calc(100% - ${leftStyles.minWidth})`
                  }}
                >
                  <div className='v-detail-patient-information-right-program'>
                    <div style={{ display: 'flex' }}>
                      {this.renderEnrollment()}
                      {this.renderDoctorReferral()}
                      {this.renderSendConsentButton()}
                      {/*{ this.renderMNTEnroll() }*/}
                    </div>
                    <div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline'
                      }}>
                        {this.renderPatientPreferences()}
                        {this.renderPCP()}
                        {this.renderAssignedCt()}
                        {this.renderForm()}
                        {this.renderVitalType()}
                      </div>
                      { this.renderConnectedDevices() }
                      { this.renderHealthConditions() }
                    </div>
                  </div>
                  <div className='v-detail-patient-information-right-buttons'>
                    {this.renderRightButton()}
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
          {/* { this.renderSmartAlertTags(1) } */}
        </Row>
        </div>
    }

    renderInMessages = (menuOpened = false, showChat = false) => {
      const patientId = _.get(this, 'props.data.user.id');

      return <Row className='vsm-patient-member-detail patient-wrapper-6188'>
          <Col span={24}>
            <div className='v-detail-wrapper messages-page'>
              <div className='v-detail-patient-name'>
                {this.renderAvator(patientId)}
                { this.renderName() }
              </div>
              <div className='v-detail-patient-information'>
                <div className='v-detail-patient-information-left'>
                  <div>
                    <div style={{ display: 'flex', flexFlow: 'column wrap' }} >
                      { this.renderMR() }
                      { this.renderGenderBirthdayAge() }
                      { this.renderSpokenAndAppLanguage() }
                    </div>
                    <div className='v-patient-split-tag'>
                      { this.renderBodyInfo() }
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div className='v-patient-split-tag'>
                        { this.renderMotivation() }
                        { this.renderTechLevel() }
                      </div>
                      { this.renderPhoneInfo() }
                    </div>
                  </div>
                  <div className='v-detail-patient-information-right-buttons'>
                    {this.renderRightButton()}
                  </div>
                </div>
                <div className='v-detail-patient-information-right'>
                  <div className='v-detail-patient-information-right-program'>
                    {this.renderEnrollment()}
                    {this.renderPatientPreferences()}
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      {this.renderPCP()}
                      {this.renderAssignedCt()}
                      {this.renderForm()}
                    </div>
                    { this.renderVitalType() }
                    { this.renderConnectedDevices() }
                    { this.renderHealthConditions() }
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <div className='messages-page-sma-wrapper'>
            { this.renderSmartAlertTags(0) }
          </div> */}
        </Row>;
    };
    renderSubscriptionStatus = ()=>{
        const memberId = _.get(this, 'props.data.user.id');
        const subscriptionStatus = _.get(this,'props.data.user.profile.subscription');
        return <MVPSubscriptionStatusContainer memberId={memberId} subscriptionStatus={subscriptionStatus}/>
    }
    renderStickyHeader = (menuOpened, showChat) => {
      const { viewOnly, isWorkStation, isProviderAlert, isSignOff } = this.props;
      const patientId = _.get(this, 'props.data.user.id');

      let stickyStyles = { width: '100%', left: 60, top: 54, borderRadius: 0 };
      if (!menuOpened && !showChat) {
        stickyStyles = { ...stickyStyles, width: 'calc(100% - 60px)' };
      }
      else if (!menuOpened && showChat) {
        stickyStyles = { ...stickyStyles, width: 'calc(100% - 60px)'};
      }
      else if (menuOpened && showChat) {
        stickyStyles = { ...stickyStyles, width: 'calc(100% - 60px)', left: 240}
      }
      else if (menuOpened && !showChat) {
        stickyStyles = { ...stickyStyles, left: 240 };
      }

      if(viewOnly) {
        stickyStyles = { top: 54, borderRadius: 0 };
        if(menuOpened) {
          stickyStyles = {...stickyStyles, width: 'calc((100% - 240px)/2)'};
        }
      }

      if(isWorkStation) {
        stickyStyles.top = 158;
      }

      if (isProviderAlert && this.state.providerAlertStyle) {
        stickyStyles = this.state.providerAlertStyle;
      }

      if(isSignOff) {
        stickyStyles.top = 99;
      }

      return <Row
        id='sticky_profile_detail'
        className={
          ('vsm-patient-member-detail patient-wrapper-6188 stickyHeader ' +
            (isWorkStation ? ' stickyWorkStation ' : '') +
            (menuOpened ? ' menuOpened ' : '') +
            (viewOnly ? ' viewOnly' : '') +
            (!isProviderAlert ? ' notProviderAlert' : '')
          )
        }
        style={stickyStyles}
      >
        <Col span={1} style={{ textAlign: 'center' }}>
          {this.renderAvator(patientId)}
        </Col>
        <Col span={23}>
          <div className='v-detail-wrapper' style={{ marginLeft: !viewOnly ? 0 : 5 }}>
            <div className='v-detail-patient-name'>
              { this.renderName() }
            </div>
            <div className='v-detail-patient-information'>
              <div className='v-detail-patient-information-left'>
                <div className='v-patient-split-tag'>
                  { this.renderMR() }
                  { this.renderGenderBirthdayAge() }
                  { this.renderSpokenAndAppLanguage() }
                </div>
                {
                  !viewOnly ?
                  <div className='v-patient-split-tag'>
                    { this.renderBodyInfo() }
                    { this.renderMotivation() }
                    { this.renderTechLevel() }
                  </div>
                  :
                  <div style={{ display: 'flex', flexFlow: 'column wrap' }} >
                    <div className='v-patient-split-tag'>
                      { this.renderBodyInfo() }
                    </div>
                    <div className='v-patient-split-tag'>
                      { this.renderMotivation() }
                      { this.renderTechLevel() }
                    </div>
                  </div>
                }

              </div>
              <div className='v-detail-patient-information-right'>
                <div className='v-detail-patient-information-right-program'>
                  <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    {this.renderEnrollment()}
                    {!viewOnly && this.renderSendConsentButton()}
                    <span style={{ marginLeft: 10 }}>
                      {this.renderPatientPreferences()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>;
    }

    render() {
      if (this.props.data.loading || this.props.loadingEnrolledProgramList || this.props.patientComplexityLoading) {
          return <div className="vsm-patient-member-detail patient-wrapper-6188">
            <IHLoading />
          </div>
      }
      const { showStickyHeader, menuOpened, showChat, viewOnly} = this.props;
      const { showConsentForm } = this.state;
      const user = _.get(this,'props.data.user',{});
      const userCache = Object.assign({}, user, { patientComplexity: _.get(this, 'props.patientComplexity')});
      RequestCache.set('nurse/currentPatient', userCache);
      user.recentWeights = _.get(this,'props.recentWeights',{});
      this.uc = new UserClass(user);
      this.bodyInfoValues = this.uc._getBodyInfoValues();

      const renderMemberDetail = !viewOnly ? this.renderInPatientProfile : this.renderInMessages;

      return (
        <React.Fragment>
          <div id="sticky-container">
            { renderMemberDetail(menuOpened, showChat) }
            { showConsentForm && this.renderShowSendConsentForm() }
          </div>
          {showStickyHeader && this.renderStickyHeader(menuOpened, showChat)}
        </React.Fragment>
      );
    }
    renderPwChange() {
        //updated for IH-1067
        // const { patientId, data: { user: { hasPassword } } } = this.props;
        const { patientId, data } = this.props;
        const hasPassword = data && data.user && data.user.hasPassword;

        if (hasPassword) {
            return null;
        }
        else {
            return (
                <div className="change-password-patient">
                    <h4 style={{ display: 'flex', justifyContent: 'center' }}>Initial Patient Password Creation</h4>
                    <p style={{ display: 'flex', justifyContent: 'center' }}>The patient will need this password to log into their mobile app.</p>
                    <br />
                    <IHButton bsStyle="primary" label={I18N.get('auth.password.createPw')} style={{ display: 'flex', margin: '0 auto' }} onClick={() => goPath(`patients/${patientId}/new-password`)} />
                </div>
            )
        }
    }
    onAvatarError(userId) {
        API.getUserAvatar(userId)
            .then(res => {
                const userProfile = _.get(res.data.user, 'profile') || {};
                const currentUserId = atob(_.get(res.data.user, 'id')).split(':')[1];

                const newUser = {
                    uuid: currentUserId,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    nickname: userProfile.nickname,
                    avatar: userProfile.avatar && (userProfile.avatar.link || '')
                };

                this.props.addToUserMap(newUser);
            })
            .catch(err => {
              console.log('onAvatarError, err: ', err);
            });
      return false;
    }
    renderAvator(userId) {
        // return (
        //     <div className="v-avator">
        //       <img src={this.uc.format('avator')} onError={() => this.onAvatarError(userId)} />
        //     </div>
        // )
      return <Avatar
        icon='user'
        src={_.get(this.props, 'data.user.profile.avatar.link')}
        onError={() => this.onAvatarError(userId)}
      />
    }

    renderPhoneInfo(){
        const { phoneReportInfoList } = this.props;
        const added = phoneReportInfoList ==='added';
        const text =  added ? `Care team number has been added on patient’s phonebook.` :`Please remind patient to add care team number in the phone contact list.`;

        return <Tooltip overlayClassName='v-patient-pn-contact-tip' placement="top" title={text} arrowPointAtCenter={true}>
                    <img
                      src={`/image/${added ? 'phonebook2.svg' :'phonebook2_notification.svg'}`}
                      style={{marginRight: 7, marginBottom: 4, width: 16 }}
                    />
                </Tooltip>
    }

    getEnrollmentStatus = (enrolledProgram = _.get(this, 'props.currentProgram')) => {
      const user = _.get(this, 'props.data.user');
      const { refetchUser, patientReferral } = this.props;
      const remoteEnrollment = _.get(user, 'remoteEnrollment');
      let programCategories = _.get(user, 'profile.programCategories')||[];
      const isReferred = _.get(patientReferral, 'status.referStatus')=='COMPLETED';
      const isEnrolled = _.get(patientReferral, 'status.enrollStatus') == 'COMPLETED';
      let categories = [];
      _.forEach(programCategories, (p) => {
          if (p.enrolled) {
              categories.push(_.toUpper(p.name));
          }
      });
      let isMAOrMD = checkIsInRole(['MA','Doctor']);
      const renderReferButton = () => <ReferStatusInPatientHeaderComponent 
                                        remoteEnrollment={remoteEnrollment}
                                        refetchQuery={refetchUser} 
                                        patientReferral={patientReferral||{}} 
                                        memberId={_.get(user,'id')} 
                                        type={'REFERRAL'}
                                      />
      if(enrolledProgram) {
        switch(enrolledProgram.status) {
          case 'STARTED':
            if (isEnrolled) {
              let textFn = (patientReferral) =>{
                  const programs = categories.length > 0 ? `${_.join(categories, ', ')}` : _.get(patientReferral,'eligiblePrgorams',[]).join(',');
                  return `Enrolled - ${programs}`
              }
              return <ReferStatusInPatientHeaderComponent remoteEnrollment={remoteEnrollment} refetchQuery={refetchUser} patientReferral={patientReferral||{}} memberId={_.get(user,'id')} type={'ENROLL'} textFn={textFn}/>
            }

            return `Enrolled ${categories.length > 0 ? ` - ${_.join(categories, ', ')}` : ''}`;
          case 'DISCHARGED':
            const { dischargedDetail } = enrolledProgram;
            let dischargedContent = 'Discharged';
            if(dischargedDetail) {
              const { description, dischargedAt, note } = dischargedDetail || {};
          
              dischargedContent += ` (On ${formatDateMM(dischargedAt)})`;
              dischargedContent += `: ${description} ${note ? ` (${note})` : ''}`;
            }
            return dischargedContent;
          default: // No-OP
        }
      }
      // check if patient is currently Discharged
      const dischargedEPList = _.get(this, 'props.dischargedEPList') || [];
      // if(dischargedEPList.length) {
      //   const lastDP = _.last(dischargedEPList);
      //   return this.getEnrollmentStatus(lastDP);
      // }
      
      if(isReferred){
        return renderReferButton();
      }
      return 'Unenrolled';
    }

    getEnrollmentProgram = (enrolledProgram = _.get(this, 'props.currentProgram')) => {
      const user = _.get(this, 'props.data.user');
      let programCategories = _.get(user, 'profile.programCategories', []);
      let categories = "";
      _.forEach(programCategories, (p) => {
          if (p.enrolled) {
              categories += _.toUpper(p.name);
              categories += ",";
          }
      });
      return categories.length > 0 ? categories.substring(0,categories.length-1):categories;
    }

    renderEnrollment = () => {
        // this.setEnrollmentStatus();
        return <div className={"profile-keyvalue"}>
            <span style={{ fontWeight: 'bold' }}>Status: </span>
            <span className='v-tag'>{ this.enrollmentStatus }</span>
        </div>;
    }

    renderPatientPreferences = () => {
      const { user = {} } = this.props.data || {};
      const isMNTEnrolled = !!programHelpers.isMNTEnrolled({ user });
      const upValues = upHelpers.getUserPreferences(user) || {};

      return !this.isUnenrolledPatient && !isMNTEnrolled &&
            <div className='profile-keyvalue'>
              <PatientPreferencesText
                userPreference={upValues}
                customStyles={{
                  wrapper: { color: 'inherit', lineHeight: 'inherit', fontSize: 'inherit', margin: 'unset' },
                  title: { fontWeight: 'bold', textDecoration: 'unset' }
                }}
                classNames={{ selections: 'v-tag' }}
              />
            </div>;
    }

    renderTechLevel() {
      const techLevel = this.uc.format('techLevel');
      return !this.isUnenrolledPatient && techLevel && <div className='v-tag'>
        Tech Level: <span style={{ marginRight: 5 }}>{I18N.get(techLevel)}</span>
      </div>;
    }

    renderMotivation() {
      const motivation = this.uc.format('motivation');
      return !this.isUnenrolledPatient && motivation && <div className='v-tag'>
          Stage of Behavioral Change: <span style={{ marginRight: 5 }}>{motivation}</span>
      </div>;
    }

    renderMobilePhones() {
        let mobilePhone = this.uc.format('mobilePhone');
        const phones = _.get(this, 'props.data.user.phone',[]);
        const patientPhones = phones.filter(p => p.type === 'MOBILE');
        let privateNumberText = '';
        if (patientPhones.length) {
            privateNumberText = (patientPhones[0].private ? ' (Private)' : '');
        }
        let time;
        if (this.props.callDate) {
            let date = new Date(this.props.callDate);
            let duration = this.props.callDuration;
            time = <div style={{ width: '150px' }}><b>{`Last Call: `}</b> <br /> {moment(date).format('MM/DD/YY,HH:mm')} <br /> {duration}</div>;
        } else {
            time = 'No call'
        }
         // retrieve from local storage
         const phoneNumber = mobilePhone.replace(/\s/g, '')
         let historyDictString = localStorage.getItem('call-center-history') || '{}';
         let historyDict = JSON.parse(historyDictString)
         const aPhoneArray = _.get(historyDict, phoneNumber) || []
         const menu = (
             <Menu>
                { aPhoneArray.reverse().map((t, index) => (
                     <Menu.Item key={index}>  {t.start}, duration {t.duration}
                     </Menu.Item>
                 )) }
             </Menu>
         );
        if (mobilePhone) {
            return <div style={{ marginRight: 20 }} className={"profile-keyvalue"}>
                {/* <span><img src={'/image/call-phone.png'} /></span> */}
                M:
                {
                    <Dropdown overlay={menu}>
                        <span onClick={() => this.callPhone(mobilePhone)} >
                            <NumberFormat
                              className='v-patient-link'
                              // format="+# (###) ###-####"
                              format='###-###-####'
                              value={_.replace(mobilePhone, '+1 ', '')}
                              displayType={'text'}
                            />
                            <span style={{ fontStyle: 'italic' }}>{privateNumberText}</span>
                        </span>
                    </Dropdown>
                }
                </div>
        }
        else {
            return '';
        }
    }

    renderHomePhones() {
        const homePhone = this.uc.format('homePhone');
        // retrieve from local storage
        const phoneNumber = homePhone.replace(/\s/g, '')
        let historyDictString = localStorage.getItem('call-center-history') || '{}';
        let historyDict = JSON.parse(historyDictString)
        const aPhoneArray = _.get(historyDict, phoneNumber) || []
        const menu = (
            <Menu>
               { aPhoneArray.reverse().map((t, index) => (
                    <Menu.Item key={index}>  {t.start}, duration {t.duration}
                    </Menu.Item>
                )) }
            </Menu>
        );
        if (homePhone) {
            return  <div className={"profile-keyvalue"}>
                {/* <span><img src={'/image/call-phone.png'} /></span> */}
                H:
                {
                    <Dropdown overlay={menu}>
                      <span onClick={() => this.callPhone(homePhone)} >
                        <NumberFormat
                          className='v-patient-link'
                          // format="+# (###) ###-####"
                          format='###-###-####'
                          value={_.replace(homePhone, '+1 ', '')}
                          displayType={'text'}
                        />
                      </span>
                    </Dropdown>
                }
           </div>
        }
        else {
            return '';
        }
    }

    renderAssignedCt = () => {
      const { user = {} } = this.props.data || {};
      const assignees = _.get(user, 'assignees', null);
      let isMAOrMD = checkIsInRole(['MA','Doctor']);
      let assignedCA =  assignees ? _.get(user, 'assignees.assignedToCA', null) : null;;
      let assignedRdHC = assignees ? _.get(user, 'assignees.assignedToRD', null) : null;
      let noAssign = !assignedCA && !assignedRdHC;
      let assignText = noAssign ? 'Assign' : 'Edit';
      let text = I18N.get('missingAssignTip');
      const assignedToRDNotNecessary = _.get(user, 'assignees.assignedToRDNotNecessary');
      const assignedToCANotNecessary = _.get(user, 'assignees.assignedToCANotNecessary');
      const RDfullName = assignedToRDNotNecessary ? DISPLAY_NOT_NECESSARY_VALUE : _.get(assignedRdHC, 'profile.fullName', defaultEmptyValue);
      const CAfullName = assignedToCANotNecessary ? DISPLAY_NOT_NECESSARY_VALUE : _.get(assignedCA, 'profile.fullName', defaultEmptyValue);
      const hideWarn = (assignedToCANotNecessary && assignedToRDNotNecessary) || (assignedCA && assignedToRDNotNecessary)  || (assignedRdHC && assignedToCANotNecessary);
      return <div style={{display:'flex', flexDirection:'row'}}>
              <span style={{ fontWeight: 'bold', marginRight: 8 }}>Assigned CT Members: </span>
              {
                assignedCA && assignedRdHC ? 
                <span className='v-tag' style={{marginTop: 3}}>RD/HC - {_.get(assignedRdHC, 'profile.fullName')}; CA - {_.get(assignedCA, 'profile.fullName')}</span>
                :
                <div style={{display:'flex', flexDirection:'row'}}>
                  <span className='v-tag' style={{marginTop: 3}}>RD/HC - {RDfullName}; CA - {CAfullName}</span>
                  {isMAOrMD || hideWarn ? "" : <Tooltip placement="rightTop" title={text}><Icon type="info-circle" style={{color:'#F5222E', marginTop:'5px'}}/></Tooltip>}
                </div>
              }
              {isMAOrMD ? "" : <div style={{textDecoration:'underline',cursor:'pointer', marginLeft:2}} onClick={()=>{this.setShowForm(true)}}>{assignText}</div>}
            </div>
    }

    renderForm = () => {
      const user = _.get(this.props, 'data.user');
      const memberId = _.get(user, 'id');
      const complexity = _.get(this.props, 'patientComplexity.level');
      const isEnrolled = _.get(this,'props.patientReferral.status.enrollStatus') == 'COMPLETED';
      if(this.state.showForm) {
        return <div><AssignToCTFormModal memberId={memberId} patient={user}
                                    setShowForm={this.setShowForm}
                                    complexity={complexity} isEnrolled={isEnrolled} saveCallback={this.saveCallback}/></div>
      }
      return '';
    }
    
    //Refetch Profile header after assign
    saveCallback = () => {
      this.props.refetchUser();
    }


    renderVitalType(vitalOrder = VITALS_ORDER) {
        const { hasCGM } = this.props.cgmContextValue || {};
        const vitals = _.get(this.props, 'currentProgram.tasks');
        const vitalTypes = _.map(vitals, 'type') || [];
        const vitalsWithStatus = this.uc._getVitalsWithInactiveStatus();
        return !this.isUnenrolledPatient && <div className={"profile-keyvalue"}>
            <span style={{ fontWeight: 'bold', paddingTop: 1, marginRight: 8 }}>Vitals: </span>
            {
              (vitalTypes.length || hasCGM) ? (
                <Fragment>
                  {     
                    _.map(_.intersection(vitalOrder, vitalTypes), type => {
                      const { inactive = true } = vitalsWithStatus[type] || {};
                      const getTooltipMsg = () => {
                        const vitalName = _.get(I18N.get('vitalsAbbreviation'),type);
                        return `Patient has ${inactive ? 'not' : ''} checked ${vitalName} vitals in the past 2 weeks`;
                      };
                      return <span key={type}>
                        <Tooltip title={getTooltipMsg()} overlayClassName='vitals-status-tooltip'>
                          <img
                            style={{ width: 20, margin: '-1px 8px 0px 0px' }}
                            src={`/image/${type}.svg`}
                            className={inactive ? 'vitals-inactive-status' : ''}
                          />
                        </Tooltip>
                      </span>
                    })
                  }
                  <CGMVitalIconComponent 
                    activeTooltipMsg="Patient has checked CGM vitals in the past 2 weeks"
                    inActiveTooltipMsg="Patient has not checked CGM vitals in the past 2 weeks"
                    tooltipClassName="vitals-status-tooltip"
                    inActiveClassName="vitals-inactive-status"
                  />
                </Fragment>
              )
              :
              'N/A'
            }
        </div>
    }

    renderHiatusTag = () => {
      const { hiatusStatus } = this.props;

      return <HiatusIndicator.Clickable 
        component={props => {          
          let validCategoryValues = getSelectedCategoryValues(hiatusStatus);
          const isHiatusActive = checkIfHiatusActive(hiatusStatus);

          if(!isHiatusActive || _.isEmpty(validCategoryValues))
            return null;

          validCategoryValues = _.map(validCategoryValues, v => hsConstants.indicatorTextMap[v] || '--');
          const tagText = 'Hiatus - '.concat(validCategoryValues.join(' & '));

          return <Tag 
            style={{ 
              marginLeft: 5, 
              height: 20, 
              border: 'none', 
              backgroundColor: '#FFD0D0', 
              border: 'solid 1px #F91D1D',
              paddingTop: 1,
              paddingBottom: 2,
              color: '#F91D1D',
              fontWeight: 'bold', 
              cursor: 'pointer',
              fontSize: 14,
            }}
            {...props}
          >
            {tagText}
          </Tag>;
        }}
        hiatusStatus={hiatusStatus} 
      />;
    };

    renderPatientTags = () => {
      const hasAssignees = _.get(this.props, 'data.user.assignees', '');
      const isSelfEnrolled = _.get(this.props, 'data.user.profile.isSelfEnrolled', false);
      const complexity = _.get(this.props, 'patientComplexity.level');
      // const hasHealthConditions = !!_.get(this, 'props.data.user.profile.healthConditions.length');
      return (
        <div style={{ display:'flex', alignItems: 'center' }}>
          {complexity && renderComplexityTag(complexity, this.isUnenrolledPatient)}
          {!this.isUnenrolledPatient && this.renderHiatusTag()}
          {isSelfEnrolled && renderSelfTag()}
          { this.renderSubscriptionStatus() }
        </div>
      );
    }

    renderName(showTags = true) {
      const { viewOnly, data } = this.props;
      const patientId = _.get(this, 'props.patientId');
      const patientName = this.uc._get('profile.fullNameWithNickname');
      const isTest = _.get(data, 'user.isTestUser', false);
      const testName = isTest ? '(Test Patient)' : '';
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={'v-name ' + (viewOnly  ? 'v-patient-link no-decoration' : '')}>
            {
              viewOnly ?
              <a onClick={() => {
                Mixpanel.track('clicked', 'patient_name', 'message', { PATIENT_ID: patientId });
                goPath(`/patients/${patientId}/enrolledProgram/default`)
              }
              }>
                <span style={{fontSize:18, marginLeft:0}}>{patientName}<span style={{fontSize:18, fontWeight:'normal'}}>{testName}</span></span>
              </a>
              :
              <span style={{fontSize:18, marginLeft:0}}>{patientName}<span style={{fontSize:18, fontWeight:'normal'}}>{testName}</span></span>
            }
              {/* <span>({this.uc.format('nric')})</span> */}
          </span>
          {/* <span className={`v-status v-${user.status}`}>{user.status}</span> */}
          {/* {this.renderA1CTag()} */}
          {showTags && this.renderPatientTags()}
          {this.renderStar()}
        </div>
      )
    }

    handleInteraction = (type) => (description) => {
      const { onInteraction } = this.props;
      onInteraction(type, description);
    }

    renderSelf() {
      return <Tooltip title={I18N.get('SelfTag')}>
                <Tag>Self</Tag>
             </Tooltip>
    }

    renderStar() {
      const { data, refetchUser, currentProgramId } = this.props;
      return <PatientTaskStarComponent
        overlayClassName='vsm-tooltip'
        user={_.get(data, 'user')}
        refetchUser={refetchUser}
        onInteraction={this.handleInteraction(EVENT_TYPES.TASK_ASSIGNMENT)}
        currentProgramId={currentProgramId}
      />;
        // const { data } = this.props;
        // const patientName = _.get(data,'user.profile.fullName');
        // const memberId = _.get(data,'user.id');
        // const star = (_.get(data,'user.taskAssignmentCount')!=0);
        // if (star) {
        //     const click = () => {
        //         Mixpanel.track('clicked', 'task tag', 'patient profile page');
        //         this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
        //     }
        //     return (
        //         <IHTooltip overlayClassName="vsm-tooltip" title={star.reason || I18N.get('taskAssignment.patient.viewTask')} placement="right">
        //             <a onClick={click} className="v-star v-y"><i className="fa fa-star" /> Tasks</a>
        //         </IHTooltip>
        //     )
        // }
        // else {
        //     const click = () => {
        //         Mixpanel.track('clicked', 'task tag', 'patient profile page');
        //         this.setModalData({ showModal:true,modalData:{ patientName,memberId,inPatientDetail: true }});
        //     }
        //     return (
        //         <IHTooltip overlayClassName="vsm-tooltip" title={I18N.get('taskAssignment.patient.addTask')} placement="right">
        //             <span onClick={click} className="v-star v-n"><i className="fa fa-star-o" /></span>
        //         </IHTooltip>
        //     )
        // }
    };

    renderDoctorReferral = () => {
      const { patientId, refetchUser } = this.props;
      const reStatus = remoteStatus[this.uc._get('remoteEnrollment.status')];
      const doctorReferral = this.uc._get('remoteEnrollment.doctorReferral') || false;
      const patientRefer = this.uc._get('remoteEnrollment.patientRefer');
      const shouldShow = helpers.checkIsCAOrRD() &&
                        reStatus &&
                        this.isUnenrolledPatient &&
                        !this.isCurrentDischarged;

      return shouldShow && <div className='v-detail-patient-remote-enrollment'>
        <span className='v-tag'>
          {`- ${reStatus}`}
        </span>
        <span className='v-detail-patient-doctor-referral'>
          <DoctorReferralIcon
            memberId={patientId}
            remoteEnrollment={this.uc._get('remoteEnrollment')}
            patientRefer={patientRefer}
            doctorReferral={doctorReferral}
            onClickCallback={() => refetchUser()}
          />
        </span>
      </div>;
    };

    renderSpokenAndAppLanguage = ()=>{
        const infoArray = [{key:'language',title:'Spoken'},{key:'appLanguage', title:'App'}];
        const tags = infoArray.map(({title,key})=>({ title,value:this.uc.format(key)}));
        const sa = _.map(tags, ({title,value}) => value ? `${title}-${value}` : null);
        return (
          <div className='v-tag' style={{ /*whiteSpace: 'pre'*/ display: 'inline-table' }}>
            <span style={{ marginRight: 8 }}>Language: </span>
            {
              _.map(tags, ({title,value}, index) =>
              {
                return value &&
                <span key={index}>
                  {index === tags.length -1 ? '; ' : ''}
                  {`${title}-${value}`}
                </span>
              })
            }
          </div>
        )

    }

    // renderBodyInfo = ()=>{
    //     const bodyInfo = this.uc.format('bodyInfo');
    //     return bodyInfo;
    // }

    renderGenderBirthdayAge = ()=>{
        const infoArray = ['gender','dob','age'];
        const tags = infoArray.map(i=>({ key: i,value:this.uc.format(i)}));
        return (
          <div className='v-tag'>
            {
              _.map(tags, ({key,value}) =>
                <span key={key} style={{ marginRight: 5 }}>
                  {value} {key==='age' && 'yrs'}
                </span>
              )
            }
          </div>
        )
    }

    renderTags() {
        const tags = this.uc.format('tag');
        return (
            <div style={{ display: 'flex' }}>
                {
                    _.map(tags, (tag, index) => {
                        if(index == 3){
                            return <span className="v-tag" key={index}>Spoken Language: {tag}</span>
                        }else if(index == 4 && typeof(tag) == 'string' ){
                            return <span className="v-tag" key={index}>App Language: {tag}</span>
                        }else{
                            return <span className="v-tag" key={index}>{tag}</span>
                        }
                    })
                }
            </div>
        )
    }

    renderBodyInfo = (keys = ['Height', 'Weight', 'BMI', 'BMR', 'AdjBW']) => {
        // const bodyInfo = this.uc.format('bodyInfo');
        // console.log('hereee', bodyInfo);
        // const rows = Math.round(bodyInfo.length/2);
        // let start = 0;
        // let eachRow = (i)=>{
        //     const cols = [];
        //     let j=0;
        //     for(;j<2;j++){
        //             const index = start+j;
        //             cols.push(<Col className={'v-tag'}>{bodyInfo[index]}</Col>)

        //     }
        //     start = j;
        //     return cols;
        // }

        // if (bodyInfo.length > 0) {
        //     const content = [];
        //     let array = Array.from(Array(rows).keys());
        //     _.each(array,i=> {
        //         content.push(<Row>
        //                  { eachRow(i) }
        //                </Row>)
        //     });
        //     return <div id='bodyInfoMemberContainer'>{content}</div>;
        // }
        const infoKeys = _.keys(_.pick(this.bodyInfoValues, keys));
        return _.map(infoKeys, infoKey => {
          let infoValue =  this.bodyInfoValues[infoKey];
          if(infoKey === 'BMI') {
            const BMIvalue = _.get(infoValue, 'value');
            const statusColor = _.get(infoValue, 'statusColor');
            infoValue = <span style={{ color: statusColor, marginRight: 5 }}>
                          {BMIvalue}
                        </span>;
          } else {
            infoValue = <span style={{ marginRight: 5 }}>
                          {infoValue}
                        </span>;
          }
          return _.includes(keys, infoKey) && infoValue &&
                  <div className='v-tag' key={infoKey}>
                    {infoKey}: {infoValue}
                  </div>;
        })
    }

    // renderA1CTag() {
    //     const tempPatient = this.props.tempPatient;

    //     if (tempPatient) {
    //         return (
    //             <span className="v-a1c">{tempPatient.category}</span>
    //         );
    //     }
    // }

    canClicktoDial() {
        //all clinic
        return true;
    }

    // status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
    async callPhone(phoneNumber) {
        if (this.props.contact == 'onConnecting'
        || this.props.contact == 'onConnected') {
            return window.CCPClass && window.CCPClass.openCCP();
        }

        // validate phone number
        if (phoneNumber == null) {
            Modal.error({
                title: "Error",
                content: "Phone Number is invalid"
            })
            return
        }

        // validate agent
        // when user logout, terminate CCP, then portal user relogin, agent is undefined.
        // the process is load steam -> addCCP -> subscribe

        let agent = window.awsCCP != undefined ? window.awsCCP.agent : undefined;
        if (agent == undefined) {
            Modal.confirm({
                title: 'Click Yes to log in call center, choose Cancel if you are already logged in',
                content: <div><br/>  Please keep the pop-up CCP(Contact Control Panel) open on the side. <br/>  <br/>
                         If you are prompted to allow access to your microphone and speaker, choose Allow. <br/>  <br/>
                         Stay on the patient page during calling, so that Call Log can be popped up when the call ends.</div>,
                okText: `Yes`,
                onOk: () => {
                  window.CCPClass.checkLogin();
                },
                cancelText: `Cancel`,
                onCancel:()=>{
                }
            })
            return
        } else {
            // one call at a time
            let state = agent.getState()
            if (state && (state.name == 'CallingCustomer' || state.name == 'Busy')) {
                // Modal.error({
                //     title: "Error",
                //     content: "Cannot dial now, the line is busy."
                // })
                return
            }

            // Offline happens(1) logout (2) Agent set status to Offline
            // set Agent online, which is no need when portal users make calls
            // could be userful for inbound call
            if (state && state.name == 'Offline') {

                let routableState = agent.getAgentStates().filter(function(state) {
                    return state.type === global.connect.AgentStateType.ROUTABLE;
                 })[0];
                //  console.log('routableState', routableState)
                 agent.setState(routableState, {
                    success: function() {
                        // console.log('success')
                     },
                    failure: function() {
                        // console.log('failure')
                    }
                 });
            }
        }
        let username = ''
        if (this.uc) {
            username = this.uc._get('profile.firstName') + ' ' + this.uc._get('profile.lastName')
        }
        this.props.callPhone(phoneNumber, username);
        if (sessionStorage.getItem('inboundAttributes')){
            sessionStorage.removeItem('inboundAttributes');
        }

        //sync panel status
        this.props.setContact('onConnecting')

        //mark patient id
        const { patientId } = this.props;
        // this.props.lastCallPatientName(username);
        // this.props.lastCallEnrolledProgramId(this.props.currentProgramId)
        // let contact = {'patientId': patientId, 'name': username, 'enrolledProgramId': this.props.currentProgramId,
        //               operationStatus:  _.get(this.props, 'currentProgram.status')}
        // localStorage.setItem('call-center-last-contact', JSON.stringify(contact))

        // save outbound attribute, click to jump
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        let institution = currentUser.selectedRole.organization.id;
        const curOrg = atob(institution).split(':')[1];
        let outboundAttributes = {patientId:{value: patientId},
                                organizationId: {value: institution},
                                enrolledProgramId: {value: this.props.currentProgramId},
                                name: {value: username},
                                operationStatus: {value: _.get(this.props, 'currentProgram.status')}}

        if (sessionStorage.getItem('outboundAttributes')){
            sessionStorage.removeItem('outboundAttributes');
        }
        sessionStorage.setItem('outboundAttributes', JSON.stringify(outboundAttributes));

        clicktoDial(phoneNumber, this.onConnected, this.onEnded, this.makeACallError);

        Mixpanel.track('clicked', 'phone', 'clickToDial', { "PATIENT_ID": patientId });
    }

    makeACallError(error){
        console.log('make calls error', error)
        // Modal.error({
        //     title: "Error",
        //     content: error.message
        // })
        this.props.setContact('onEnded')
    }

    renderTimeSpend(showAddButton) {
        const monthlyTimeSpent = _.get(this, 'props.data.user.monthlyTimeSpent');
        const user = _.get(this, 'props.data.user');
        const { setRefetchMontlySpent } = this;
        const totalTime = _.get(monthlyTimeSpent, 'totalTime', 0);
        const timeInMinutes = !totalTime ? 0 : Math.round(totalTime / 60);

        // return <TimeSpentComponent timeInMinutes={timeInMinutes} userId={id} setRefetchMontlySpent={setRefetchMontlySpent}/>;
        return (
          <BillableTimeComponent
            user={user}
            setTimeSpentInMin={this.props.setTimeSpentInMin}
            showAddButton={showAddButton}
            refetchMonthlySpent={this.state.refetchMontlySpent}
          />
        );
    }

    renderYearOfDiagnose() {
        const { data } = this.props;
        const yearOfDiagnose = _.get(data, 'user.profile.yearOfDiagnose', []) || [];
        const keys = Object.values(yearOfDiagnoseMap);
        const yearOfDiagnoseList = yearOfDiagnose.filter((key) => keys.indexOf(key.condition) != -1);

        return (yearOfDiagnoseList.length > 0 ?
            <div className={"profile-keyvalue"}>
                <span style={{ fontWeight: 'bold' }}>Year Of Diagnosis: </span>
                {
                    _.map(yearOfDiagnoseList, (tag, index) => {
                        const now = new Date().getFullYear();
                        const { diagnosedDate, condition } = tag;
                        const diagnosedDateTillNow = now - diagnosedDate;
                        const yearString = diagnosedDateTillNow == 0 ? '<1 year'
                            : diagnosedDateTillNow == 1 ? '1 year' : `${diagnosedDateTillNow} years`;
                        return (
                            <span className="v-tag" key={index}>
                                {_.upperFirst(condition.toLowerCase())} ({yearString})
                                </span>
                        )
                    })
                }
            </div> : ''
        )
    }

    /*remove based on SC-1446
    renderCurrentProgramNames(){
      const currentProgramNames = _.get(this.props,"currentProgramNames")||[];

      return (
        <div style={{lineHeight: 2}}>
          <span style={{fontWeight:'bold'}}>Active Enrolled Programs(s):   </span>
          {
            currentProgramNames.length > 0 ?
              currentProgramNames.map( (tag, index)=>{
                return (
                  <span className="v-tag" key={index}>{tag}</span>
                )
              }) : I18N.get('keywords.N/A')
          }
        </div>
      )
    }
    */

    renderCareContinuum() {
        const yearRange = [
            // '2017 - 2018',
            '2018 - 2019'
        ];
        const selYear = this.props.year;
        let visitList = this.props.visitList;
        let labRes = this.props.labResultList;
        const dates = Object.keys(labRes);
        if (visitList.length == 0) {
            return (
                <div>
                </div>
            )
        }

        //return if no init visit is scheduled.
        let init = _.filter(visitList, (e) => e.type == 'INIT');


        const initVisitDate = init[0] && init[0].appointmentAt;
        let mainStages = [];
        // let addition = [];
        let timeLength;
        let startDate;
        let endDate;

        // if(initVisitDate) {
        const today = new Date();
        const yesterday = moment(today).subtract(1, 'days');
        const selYearToDate = new Date(initVisitDate);

        let followUp1 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP1');
        let followUp2 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP2');
        let followUp3 = _.filter(visitList, (e) => e.type == 'FOLLOW_UP3');
        let annual = _.filter(visitList, (e) => e.type == 'ANNUAL');
        annual = annual[0] || { appointmentAt: moment(selYearToDate).add(12, 'months'), mocked: true };
        //get each visit or mock up tentative
        init = init[0] || { appointmentAt: moment(new Date()), mocked: true };
        followUp1 = followUp1[0] || { appointmentAt: moment(selYearToDate).add(3, 'months'), mocked: true };
        followUp2 = followUp2[0] || { appointmentAt: moment(selYearToDate).add(6, 'months'), mocked: true };
        followUp3 = followUp3[0] || { appointmentAt: moment(selYearToDate).add(9, 'months'), mocked: true };

        // let annual = _.filter(visitList, (e) => e.type == 'ANNUAL');
        //annual = annual[0] ||  { appointmentAt: moment(selYearToDate).add(12, 'months'),mocked:true };
        let addition = _.filter(visitList, (e) => e.type == 'ADDITIONAL');

        let initHba1c = [];
        let followUp1Hba1c = [];
        let followUp2Hba1c = [];
        let followUp3Hba1c = [];
        let annualHba1c = [];
        let isInRange = (resDate, date, value, unit) => {
            const startDate = (moment(date).subtract(value, unit));
            const endDate = (moment(date).add(value, unit));
            return moment(resDate).isAfter(startDate) && moment(resDate).isBefore(endDate);
        }

        _.each(labRes, (e) => {
            const value = 1;
            const unit = 'month';
            const dateCollected = _.get(e, 'hemoa1c.dateCollected');
            const results = _.get(e, 'hemoa1c.results');
            if (isInRange(dateCollected, init.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        initHba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp1.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp1Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp2.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp2Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, followUp3.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseFloat(res.value))) {
                        followUp3Hba1c.push(parseFloat(res.value));
                    }
                })
            }

            if (isInRange(dateCollected, annual.appointmentAt, value, unit)) {
                _.each(results, (res) => {
                    if (res.name == 'Hemoglobin A1c' && !isNaN(parseInt(res.value))) {
                        annualHba1c.push(parseInt(res.value));
                    }
                })
            }

        })

        const visitArray = [init, followUp1, followUp2, followUp3, annual];
        const labResArray = [initHba1c, followUp1Hba1c, followUp2Hba1c, followUp3Hba1c, annualHba1c];
        const typeArray = ['Initial Visit', '1s Follow Up', '2nd Follow Up', '3rd Follow Up', 'Annual Visit'];
        mainStages = _.map(visitArray, (visit, index) => {
            return {
                name: 'V' + (index + 1),
                t1: typeArray[index],
                v1: visit.appointmentAt ? DATE_HELPERS.formattedDate(visit.appointmentAt, dateformatString) : 'not scheduled',
                s1: visit.mocked ? "Tentative" : (!visit.mocked && visit.checkoutAt ? 'Completed' : (visit.appointmentAt < yesterday ? 'Missed' : 'Upcoming')),
                t2: 'HbA1c',
                v2: labResArray[index][labResArray[index].length - 1] || '--',
                l1: labResArray[index].length
            }

        });

        startDate = initVisitDate;
        endDate = annual.appointmentAt;
        timeLength = endDate - startDate;

        const styles = {
            outline: {
                display: 'flex',
                position: 'relative',
                width: 850,
                // background: 'red',
                justifyContent: 'space-between',
                fontSize: 21,
                color: 'white',
                margin: '43px 5px',
            },
            stagesBar: {
                marginTop: 24,
                marginLeft: 65,
                width: 730, height: 14, backgroundColor: 'rgb(66, 120, 195)', position: 'absolute'
            },
            mainStage: {
                borderTop: 20,
                alignItems: "center",
                borderRadius: "61px",
                display: "flex",
                fontWeight: "300",
                height: "61px",
                justifyContent: "center",
                width: "61px",
            },
            interimStage: {
                background: 'pink',
            },
        };

        const additionVisits = _.map(addition, (visit, index) => {
            return {
                name: 'V' + (index + 1),
                t1: 'Additional Visit',
                v1: visit.appointmentAt ? DATE_HELPERS.formattedDate(visit.appointmentAt, dateformatString) : 'not scheduled',
                s1: visit.mocked ? "Tentative" : (!visit.mocked && visit.checkoutAt ? 'Completed' : (visit.appointmentAt < yesterday ? 'Missed' : 'Upcoming')),
            }
        });

        const mkToolTip = ({ t1, v1, s1, t2, v2, l1 }) => {
            let hba1cIndcator;
            if (l1 > 1) {
                hba1cIndcator = "hba1cIndcator";
            }
            return (
                <div style={{ display: 'flex' }}>
                    <div>
                        <div style={{ color: '#888' }} >{t1}</div>
                        <div style={{ fontSize: 16, marginTop: 3, fontWeight: '600' }} >{v1}</div>
                        <div style={{ lineHeight: 1.3 }} >{s1}</div>
                    </div>
                    <div style={{ borderRight: 'solid 1px #c5c5c5', marginTop: 2, marginLeft: 10, marginRight: 10, height: 61 }} />
                    <div>
                        <div style={{ color: '#888' }} className={hba1cIndcator} data={l1}>{t2}</div>
                        <div></div>
                        <div style={{ fontSize: 32 }} >
                            {v2}
                            <sup style={{ fontSize: 14, color: '#888', top: -12, marginLeft: 2 }} >{(t2 === 'HbA1c' ? '%' : '')}</sup>
                        </div>
                    </div>
                </div>)
        }

        const additionToolTip = (index) => {
            const { t1, v1, s1, t2, v2, l1 } = additionVisits[index];
            return (
                <div style={{ display: 'flex' }}>
                    <div>
                        <div style={{ color: '#888' }} >{t1}</div>
                        <div style={{ fontSize: 16, marginTop: 3, fontWeight: '600' }} >{v1}</div>
                        <div style={{ lineHeight: 1.3 }} >{s1}</div>
                    </div>
                    <div>
                        <div style={{ fontSize: 32 }} >
                            {v2}
                        </div>
                    </div>
                </div>)
        }

        const additionVisitIndicators = (visits, timelength) => {
            let visitDiv;
            const width = (new Date() - startDate) / timelength * 730 + 5 || 0;
            if (visits.length > 0 && timelength) {
                visitDiv = _.map(visits, (visit, index) => {
                    const marginLeft = (visit.appointmentAt - startDate) / (timelength) * 730;
                    return (
                        <div key={index}>
                            <Tooltip
                                key={name}
                                placement="top"
                                overlay={additionToolTip(index)}
                                overlayClassName='additionVisitIndicator'
                                // mouseEnterDelay='0.2'
                                // mouseLeaveDelay='0.2'
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <img src='/image/visit_indicator.svg'
                                    style={{ 'height': '60px', 'marginTop': '-22px', 'position': 'absolute', 'marginLeft': marginLeft + 62 }}
                                />
                            </Tooltip>
                        </div>
                    )
                })
            }
            return (
                <div >
                    <div style={R.merge(styles.stagesBar, { backgroundColor: '#dbebff' })} />
                    <div style={R.merge(styles.stagesBar, { width: width })} />
                    {visitDiv}
                </div>
            )
        }

        const circle = ({ name, ...tooltipDetails }) => {
            const complete = tooltipDetails.s1 === 'Completed';
            const missed = tooltipDetails.s1 === 'Missed';
            return (
                <Tooltip
                    key={name}
                    placement="top"
                    overlay={mkToolTip(tooltipDetails)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <div style={R.merge(styles.mainStage, {
                        backgroundColor: complete ? '#4278c3' : '#dbebff',
                        color: complete ? 'white' : (missed ? 'red' : '#4278c3'),
                    })}>{name}</div>
                </Tooltip>
            )
        };

        const mkMenuItem = (str) => (
            <Menu.Item key={str}>
                <a target="_blank" rel="noopener noreferrer">{str}</a>
            </Menu.Item>
        );

        const onClick = (val) => {
            let setYear = (val.key.split('-')[0]).split(' ')[0];
            this.props.careYearChange(setYear);
        }

        const menu = (
            <Menu onClick={onClick}>
                {R.map(mkMenuItem, yearRange)}
            </Menu>
        );
        return (
            <div>
                <div style={{
                    display: 'flex',
                    fontSize: 18,
                    marginTop: 18,
                    fontWeight: 600,
                }}>
                    <div style={{ fontWeight: 800, marginRight: 20 }} >Continuum of Care</div>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link">
                            {yearRange[0]} <Icon type="down" />
                        </a>
                    </Dropdown>
                </div>
                <div style={{ marginRight: 50 }}>
                    <div>
                        {additionVisitIndicators(addition, timeLength || 0)}
                        {/*<div style={R.merge(styles.stagesBar, { backgroundColor: '#dbebff' })} />*/}
                        {/*<div style={R.merge(styles.stagesBar, { width: 100 })} />*/}
                        {/*<div style={{ width: 12, height: 12, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 0, marginLeft: 86, transform: 'rotate(45deg)' }}/>*/}
                        {/*<div style={{ width: 3, height: 35, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 3, marginLeft: 90 }} />*/}
                        {/*<div style={{ width: 12, height: 12, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 0, marginLeft: 126, transform: 'rotate(45deg)' }}/>*/}
                        {/*<div style={{ width: 3, height: 35, backgroundColor: '#ee7c81', position: 'absolute', marginTop: 3, marginLeft: 130 }} />*/}
                    </div>
                    <div style={styles.outline}>
                        {
                            R.map(circle, mainStages)
                        }
                    </div>
                </div>
            </div>
        )
    }

    fetchTeamId(currentProgramId) {
        return Client.query({
            query: enrolledProgram,
            variables: { id: currentProgramId }
        })
    }
    renderRightButton() {
        const { dischargedEPList, patientId, handleChangePage, viewOnly, currentProgram,data, isWorkStation } = this.props;
        const user = _.get(data,'user');
        const onClick = () => {
            // Click patient details on patient profile, property: patient id
            Mixpanel.track('clicked','patient_details','patient_profile',{ PATIENT_ID: patientId });
            Mixpanel.calculateDuration('patient_profile');
            goPath(`/patients/profile/${patientId}?${isWorkStation ? 'workstation' : ''}`);
            Mixpanel.time_event('patient_detail')
            handleChangePage('patient_detail')
        }
        let showDischargeButton = ( dischargedEPList && dischargedEPList.length> 0);
        const consentInfo = _.get(this,'props.consentInfo',{});
        const hasConsent = consentInfo&&consentInfo.consent;
        const categories = _.get(user,'profile.programCategories')||[];
        let programEnrolled = [];
        categories.forEach((o,i)=>{
            if(o.enrolled){
                programEnrolled.push(_.toUpper(o.name));
            }
        });
        const showPatientDetailsButton = ((_.get(currentProgram, 'status') || 'DISCHARGED') !== 'DISCHARGED');
        const ccmOrRpmEnrolled = !!this.checkPatientProgramEnrolledByType('CCM') || !!this.checkPatientProgramEnrolledByType('RPM');
        const showBillableTimeBtn = showPatientDetailsButton && hasConsent && ccmOrRpmEnrolled;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {showPatientDetailsButton && <Button
                style={{ marginBottom: 12 }}
                onClick={onClick.bind(this)}
                size='small'
                // className="v-btn"
                // style={{ right: showDischargeButton ? 235: 65 }}
              >
                {I18N.get('memberDetail.patientDetails')}
              </Button>
              }
              {
                !viewOnly && showDischargeButton &&
                  <a
                    style={{ textDecoration: 'underline', color: '#717171', marginBottom: 12 }}
                    onClick={()=> {
                      Mixpanel.track('clicked', 'discharge button', 'patient profile', {PATIENT_ID: patientId}),
                      goPath(`/patients/${patientId}/enrolledprogram/past`)
                    }}
                  >
                    Discharge history
                  </a>
              }
              {/* {
                !this.isUnenrolledPatient && ccmOrRpmEnrolled &&
                this.renderTimeSpend(showBillableTimeBtn)
              } */}
                {/* { showBillableTimeBtn && <ClaimBillableTimeComponent patientId={patientId} user={user}
                                                                          refetchMontlySpent={ this.state.refetchMontlySpent }/> } */}
            </div>
        )
    }
}

const addlabResultList = graphql(gql`
  query labResultList($filter: FilterFindManyLabResultsInput) {
    labResultMany(filter: $filter) {
      userId
      _id
      viewerId
      templateId
      templateName
      dateCollected
      deleted
      createdAt
      results {
        name
        unit
        value
        flag
        interval
      }
    }
  }
`, {
    options: ({ patientId }) => {
        const parsedPatientId = patientId ? atob(patientId).split(':')[1] : '';

        return {
            variables:
            {
                filter:
                {
                    userId: parsedPatientId,
                    results: [{ name: "Hemoglobin A1c" }],
                    deleted: true
                    //temporarily solution to exclusive deleted labRes
                }
            },
        }
    },
    props: ({ ownProps, data: { loading: labResultListLoading, labResultMany, refetch: labResultListRefetch } }) => {
        let labResultList = {}; // default value
        if (!labResultListLoading) {
            const r0a = R.reject(({ dateCollected, templateName }) => R.isNil(templateName) || R.isNil(dateCollected));
            const r0b = R.compose(R.reverse, R.sortBy(R.prop('dateCollected')));
            const r0 = R.map(R.over(R.lensProp('dateCollected'), dt => dt));
            const r1 = R.groupBy(R.prop('dateCollected'));
            const r2 = R.map(R.compose(R.map(R.mergeAll), R.groupBy(R.prop('templateId'))));
            const r3 = R.compose(trace('r2'), r2, r1, trace('r0'), r0, trace('r0b'), r0b, trace('r0a'), r0a, trace('r0a start'))(labResultMany);
            labResultList = r3;
        };
        return ({
            labResultList,
            labResultListLoading,
            labResultListRefetch,
        })
    },
});

const recentWeights = graphql(gql`
    query userMeasurements ($userId: String!, $limit: Int, $type: String) {
            userMeasurements (userId: $userId, limit: $limit, type: $type) {
            type
            ... on HSMeasureAudit {
                body_weight
            }
        }
    }
`, {
    options: (ownProps) => {
        const parsedPatientId = ownProps.patientId ? atob(ownProps.patientId).split(':')[1] : '';

        return {
            variables: {
                userId: parsedPatientId,
                limit: 10,
                type: "HS"
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { userMeasurements } = data;

        return {
            recentWeights: userMeasurements
        }
    }
});

const tempPatient = graphql(getTempEnrolledPatient, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        return {
            tempPatient: data.getTempEnrolledPatient
        }
    }
});
const phoneInfo = graphql(phoneListInfo,{
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if(loading) {
            return  {
                loading:loading
            }
        }
        return {
            phoneReportInfoList: _.get(data, 'phoneReportInfoList.data.0.addToContactsStatus', false),
            phoneInfoList: _.get(data, 'phoneReportInfoList.data', []),
        }
    }
})
const mapPropsToState = (state, ownProps) => {
    const consentInfoStatus = state.VideoChat.main.consentInfo[ownProps.patientId] || {};
  
    return {
        selectedChannel: state.chat.main.selectedChannel,
        userMap: state.chat.main.userMap,
        visitList: state.visit.main.initVisitList,
        year: state.patient.main.Year,
        PNStatus: state.chat.main.PNStatus,
        contact: state.AWSConnect.contact,
        consentInfo: {...ownProps.consentInfo, ...consentInfoStatus} // update status for consent via APP
    }
}
const mapDispatch = (dispatch) => {
    return {
        setSelectedChannel: channel => dispatch(actions.setSelectedChannel(channel)),
        verifyAndAddChannel: (channel, patientId) => dispatch(actions.verifyAndAddChannel(channel, patientId)),
        addToUserMap: userObj => dispatch(actions.addToUserMap(userObj)),
        careYearChange: sel => dispatch(patientAction.careYearChange(sel)),
        openErrorModal: msg => dispatch(openErrorModal(msg)),
        addChatHistory: (...args) => dispatch(actions.addChatHistory(...args)),
        updateChannelInfo: (channel, msgObj) => dispatch(actions.updateChannelInfo(channel, msgObj)),
        setContact: flag => dispatch(callActions.contact(flag)),
        providerNotesModalIsVisible: flag => dispatch(callActions.callStatus(flag)),
        lastCallPatientName: flag => dispatch(callActions.lastCallPatientName(flag)),
        lastCallEndTime: flag => dispatch(callActions.lastCallEndTime(flag)),
        lastCallStartTime: flag => dispatch(callActions.lastCallStartTime(flag)),
        lastCallEnrolledProgramId: flag => dispatch(callActions.lastCallEnrolledProgramId(flag)),
    }
}

const { loanDevice, hubDevice, ioTDevice } = deviceHelpers;
export default compose(connect(mapPropsToState, mapDispatch),addlabResultList, /*tempPatient, */ phoneInfo, loanDevice, hubDevice,ioTDevice,recentWeights,withComplexity)(makeCGMContextConsumer(wrapper));
