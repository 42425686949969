/**
 * Created by zizhangai on 2/8/17.
 */
import React, { Component } from 'react'
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux'
import { modalAction } from 'modulesAll/common/actions'
import { changeDate, changeReason, resetState, changeInput } from '../actions/ReportModalActions'
import { mapReason } from '../constants/ReportModalConstants'
import ModalFooter from '../components/ReportModalFooterComponent'
import ModalBody from '../components/ReportModalBodyComponent'
import moment from 'moment'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'
import { _ } from 'ihcomponent'
import I18N from 'modulesAll/I18N'

// queries
import generateNEHRReport from 'graphqlModule/generateNEHRReport'
import generateDataDetailReport from 'graphqlModule/generateFullReport'

import { openModal, openErrorModal } from 'layoutModule/actions/MainModal'
import ReportPreview from './ReportPreviewContainer'


class ReportModalContent extends Component {
  static displayName = 'report/containers/ReportModalContentContainer'

  componentWillUnmount() {
    this.props.resetState();
  }
  runMutation(variables) {
    const { isFullReport } = this.props
    if (isFullReport) {
      return this.props.client.mutate({
        mutation: generateDataDetailReport,
        variables
      })
    }
    return this.props.client.mutate({
      mutation: generateNEHRReport,
      variables
    });
  }
  render() {
    const { enrolledProgramId, enrolledProgramName, start, end, changeDate, reason, changeReason, err, dispatch,
      summary, changeInput, handleNext, isFullReport, suspendReason, handleDischargeAsync } = this.props
    const pBody = {
      range: [start, end],
      changeDate,
      reason,
      changeReason,
      err,
      changeInput,
      summary,
      isFullReport
    }
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')
    let variables = null
    let auditLogDetails = null

    if(isFullReport) {
      variables = {
        enrolledProgramId,
        startDate: +start.startOf('day'),
        endDate: +end.endOf('day')
      }
      auditLogDetails = {
        programName: enrolledProgramName,
        startDate: moment(+start.startOf('day')).format('MMM-DD-YYYY'),
        endDate: moment(+end.endOf('day')).format('MMM-DD-YYYY')
      }
    }
    else {
      variables = {
        enrolledProgramId,
        reason: mapReason[reason] + (reason === 'Suspend'? `(${suspendReason})` : ''),
        summary: summary || '',
        startDate: +start.startOf('day'),
        endDate: +end.endOf('day')
      }
      auditLogDetails = {
        programName: enrolledProgramName,
        reason: mapReason[reason] + (reason === 'Suspend'? `(${suspendReason})` : ''),
        summary: summary || '',
        startDate: moment(+start.startOf('day')).format('MMM-DD-YYYY'),
        endDate: moment(+end.endOf('day')).format('MMM-DD-YYYY')
      }
    }
    const runMutation = this.runMutation.bind(this)
    const pFooter = {
      onSubmit: function () {
        this.refs.btn_report_submit.loading(true)
        const action = isFullReport ? I18N.get('auditLog.provider.patient.generateFullReport') : I18N.get('auditLog.provider.patient.generateNEHRReport')
        runMutation(variables).then(res => {
          createAuditLog({
            action,
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: res
          })
          const p = {
            title: isFullReport? 'Generate Full Report': 'Generate NEHR Report',
            showModal: true,
            showHeaderCloseButton: false,
            size: 'lg',
            body: <ReportPreview pdfUrl={ isFullReport?  res.data.generateDataDetailReport.fileUrl : res.data.generateInterventionNotes.fileUrl}
                                 reportId={ isFullReport? null : res.data.generateInterventionNotes.interventionNotes_id }
                                 isFullReport={ isFullReport }
                                 handleNext={ handleNext }
                                 reason={ reason }
                                 handleDischargeAsync={ handleDischargeAsync } />
          }
          dispatch(openModal(p.body, p))
        }).catch(err => {
          // const p = {
          //   title: 'Generate Report',
          //   showModal: true,
          //   showHeaderCloseButton: false,
          //   size: 'lg',
          //   body: <ReportPreview pdfUrl="http://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf"
          //                        reportId="1234"
          //                        isFullReport={ isFullReport }
          //                        handleNext={ handleNext }
          //                        reason={ reason }
          //                        handleDischargeAsync={ handleDischargeAsync } />
          // }
          // dispatch(openModal(p.body, p))

          createAuditLog({
            action,
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: err,
            success: false
          })
          console.log(err);
          dispatch(openErrorModal('An Error Occurred'))
        });
      },
      onCancel: () => {
        const currentPatient = RequestCache.get('nurse/currentPatient')
        const patientNRIC = _.get(currentPatient, 'identification[0].value')
        const patientName = _.get(currentPatient, 'profile.fullName')

        if (isFullReport) {
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.cancelGenerateFullReport'),
            patientNRIC,
            patientName
          })
        }
        else {
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.cancelGenerateNEHRReport'),
            patientNRIC,
            patientName
          })
        }

        this.props.dispatch(modalAction.closeModal())
      },
      err
    }
    return (
      <div className="report-modal">
        <ModalBody {...pBody} />
        <ModalFooter {...pFooter} />
      </div>
    )
  }
}
const mapState = ({ Report, patient: { main: { Suspend: { reason } }} }, ownProps) => {
  const startDate = moment(ownProps.startDate).startOf('day')
  return {
    start: Report.start || startDate,
    end: Report.end || moment(),
    reason: Report.reason || ownProps.reason,
    err: getError(Report.start, startDate, Report.end || moment()),
    summary: Report.summary,
    suspendReason: reason
  }
}
export default connect(mapState, (dispatch) => {
  return {
    dispatch,
    changeDate: (date)  => dispatch(changeDate(date)),
    changeReason: (reason) => dispatch(changeReason(reason)),
    changeInput: (input) => dispatch(changeInput(input)),
    resetState: () => dispatch(resetState())
  }
})(withApollo(ReportModalContent))

function getError(start, startDate, end) {
  if (!start) {
    return 0;
  }
  if (start.toDate() < startDate.toDate()) {
    return 1;
  } else if (end.toDate() > moment().endOf('day').toDate()) {
    return 2;
  } else {
    return 0;
  }
}
