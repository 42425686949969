import React from 'react';
import types from 'patientModule/profileEdit/constants'
import { commonActions } from 'libModule/helpers/action-helpers'
import { openModal } from 'modulesAll/layout/actions/MainModal'
import EditPatientEmail from 'patientModule/profileEdit/containers/PatientEditEmailContainer'
import EditPatientHomePhone from 'patientModule/profileEdit/containers/PatientEditHomePhoneContainer'
import EditPatientMobilePhone from 'patientModule/profileEdit/containers/PatientEditMobilePhoneContainer'
import EditPatientCaregiver from 'patientModule/profileEdit/containers/PatientEditCareGiverContainer'
import AddPatientCaregiver from 'patientModule/profileEdit/containers/PatientAddCareGiverContainer'
import { handleFormFieldsChange } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'setSubmitting'
])

export const handleFormSaved = () => {
  return {
    type : types.FORM_SAVED
  }
}

export const updateCgForm = (fieldsValue, isEditMode=false) => {
  return {
    type: types.UPDATE_CG_FORM,
    fieldsValue,
    isEditMode
  }
}

export const openEditEmailModal = (user) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Email Address',
      showHeaderCloseButton: true,
    }
    dispatch(openModal(<EditPatientEmail userData={user} />, modalProps))
  }
}

export const openEditHomePhoneModal = (user) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Home Number',
      showHeaderCloseButton: true,
    }
    dispatch(openModal(<EditPatientHomePhone userData={user} />, modalProps))
  }
}

export const openEditMobilePhoneModal = (user) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Mobile Number',
      showHeaderCloseButton: true,
    }
    dispatch(openModal(<EditPatientMobilePhone userData={user} />, modalProps))
  }
}

export const openEditCareGiverModal = (user, index, refetch) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Edit Caregiver',
      showHeaderCloseButton: true,
      className : 'vsm-patient-edit-caregiver'
    }
    dispatch(openModal(<EditPatientCaregiver userData={user} careGiverIndex={index} refetch={refetch} />, modalProps))
  }
}

export const openAddCareGiverModal = (user, refetch) => {
  return (dispatch, getState) => {
    const modalProps = {
      title: 'Add Caregiver',
      showHeaderCloseButton: true,
      className : 'vsm-patient-add-caregiver'
    }
    dispatch(openModal(<AddPatientCaregiver userData={user} refetch={refetch} />, modalProps))
  }
}

export default {
  ...commonActionsObject,
  handleFormSaved,
  updateCgForm
}
