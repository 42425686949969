import React from 'react';
import { Row,Col,Skeleton } from 'antd';

const AccumulatedBillableTimeComponent = class extends React.Component{
    renderHeader = (header)=>{
        return <span className={'header'}>{header}</span>
    }

    renderTime = (billableTime)=>{
        return <div className={'totalTime'}>
                <span className={'number'}>{Math.round(billableTime/60)}</span>
                <span className={'unit'}> min</span>
                <span style={{ float: 'right',
                    marginLeft: 'auto',
                    lineHeight: '44px'}}>from me</span>
               </div>
    }
    render() {
        const { loading,billableTime,header } = this.props;
        return <Row className={'billableTimeWrapper'}>
                <Col span={24}>{ this.renderHeader(header) }</Col>
                {  loading ? <Skeleton/> : <Col span={24} style={{ height:100 }}>{ this.renderTime(billableTime) }</Col> }
               </Row>
    }
}

export default AccumulatedBillableTimeComponent;
