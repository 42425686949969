import types from '../constants/AssessmentFilledConstants';

export const changeAnswer = (index, answer) => ({
  type: types.CHANGE_ANSWER,
  index,
  answer,
});

export const loadAssessment = assessmentFilled => ({
  type: types.LOAD_ASSESSMENT,
  assessmentFilled,
});

// https://medium.com/@timoxley/named-exports-as-the-default-export-api-670b1b554f65
import * as myself from './AssessmentFilledActions'; // import this file into itself
export default myself; // this module's own namespace is its default export
