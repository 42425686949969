import { combineReducers } from 'redux'
import CreateBroadcast from '../../create/reducers/CreateBroadcastReducer'
import EditBroadcast from '../../edit/reducers/EditBroadcastReducer'
import Broadcast from '../../banner/reducers/broadcast'

const reducer = combineReducers({
  Main: Broadcast,
  CreateBroadcast,
  EditBroadcast,  
})

export default {
  Broadcast: reducer
}
