import React from 'react';
import EnrolledProgramList from './EnrolledProgramList';

class EnrolledProgram extends React.Component {

  render() {
    return (
      <EnrolledProgramList patientId={this.props.patientId}>
        {this.props.children}
      </EnrolledProgramList>
    );
  }
}


export default EnrolledProgram;
