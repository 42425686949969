import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import _ from 'lodash'
import uniqBy from 'lodash/uniqBy'

//files
import ProviderSmartAlertListTable from '../components/SmartAlertTableComponent';
import createTableAction from 'libModule/table/TableActions';
import { Modal } from 'antd';

//queries
import patientSmartAlertList from 'modulesAll/smartAlert/query/patientSmartAlertList.js'
import UserClass from 'graphqlModule/class/User'


const tableActions = createTableAction('patient_smart_alert_table', {
  sortFieldMap: { date: 'DATE' }
});

const Container = class extends React.Component {
  static displayName = 'PatientSmartAlertTableContainer'

  constructor(p) {
    super(p)
    this.adminProgramFilter = UserClass.getAdminProgramFilter()
    this.state = {
        showAlertModal: false,
        alert:{ }
    }
  }

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  setShowAlertModal= (flag)=>{
    this.setState({
        showAlertModal: flag
    })
  }

  setAlertData = (alert)=>{
      this.setState({
          alert
      })
  }

  render() {
    const props = {
      ...this.props,
      adminProgramFilter: this.adminProgramFilter
    }
    // console.log('SmartAlertTableContainer----Props ', props);
    const { showAlertModal } = this.state;
    const variables = getVariables(this.props);
    return <div>
                <ProviderSmartAlertListTable {...props} 
                                   variables = { variables }
                                   showAlertModal={ showAlertModal }
                                   setAlertData = { this.setAlertData }
                                   setShowAlertModal = { this.setShowAlertModal }
                />
           </div>
  }
}

const getVariables = (ownProps)=>{
  let variables =  {
      filters: {
          'enrolledProgramId': ownProps.enrolledProgramId,
          'type': _.get(ownProps, 'filter.type'),
          'adminProgramId': _.get(ownProps, 'filter.programId'),
          alertsOnly: true
      },
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      sort: { field: 'DATE', direction: 'DESC'}
  }
  const sortField = _.get(ownProps, 'sort.fieldGraph', null);
  if (sortField) {
    variables.sort = {
        field: sortField,
        direction: _.get(ownProps, 'sort.direction', 'DESC'),
    };
  }
  return variables;
}

const smartAlertsInfo = graphql(patientSmartAlertList, {
    options: (ownProps) => {
        let variables = {}
        let filter = _.cloneDeep({ ...ownProps.filter });
        let page = _.get(ownProps,'page.current',1);
        let count = _.get(ownProps,'page.size',10);
        variables.page = page;
        variables.count = count;
        variables.filters = filter;
        variables.filters.memberId = ownProps.patientId;
        // const memberId = ownProps.patientId;
        // console.log('Props--', ownProps, '\n' + 'Variables--', variables);
        
        return {
            variables: variables,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading, refetch } = data
        return {
            smartAlertTab: _.get(data, 'patientSmartAlertList', {}),
            loading,
            refetch
        } 
    }
})

const mapStateToProps = ({ globalTables }, ownProps) => ({
    ...ownProps,
    ...globalTables.patient_smart_alert_table
})

const mapDispatchToProps = (dispatch) => {
  return {
    ...tableActions
  }
}

Container.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  refetch: PropTypes.func,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  smartAlertsInfo
)(Container)