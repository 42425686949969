import configs from '../../../lib/config';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/article-controller/search_19
export const searchArticlesPath = `${domain}/articles/search`;

export const limit = 20;

// params: { searchString: string, categories: [id] | undefined, page, count }
export default (params, requestOptions) => {
  const sessionToken = sessionStorage.getItem('authToken') || '';
  const { 
    searchString = '', 
    category, 
    languages,
    page = 0, 
    count = limit,
  } = params || {};

  const finalParams = {
    filter: {
      nameRegex: searchString,
      languageIn: {
        in: languages,
      },
      // summaryRegex: searchString, [0316] not support OR search yet
      category,
    },
    pageInfo: {
      page,
      size: count,
    },
  };

  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(finalParams),
    ...requestOptions
  };

  return fetch(searchArticlesPath, fetchOptions).then((res) => res.json());
};