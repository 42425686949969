import {
  React, BaseComponent, IHLoading, _
} from 'ihcomponent'
// import {setPagePath} from 'libModule/utils'
import ProgramClass from 'modulesAll/graphql/class/Program'
import Threshold from 'modulesAll/common/components/Threshold1'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import I18N from 'modulesAll/I18N'

const Component = class extends BaseComponent{
  static displayName = 'program/Summary/components/ProgramInfo'

  componentDidUpdate() {
    const { adminProgram } = this.props
    if (adminProgram && adminProgram.adminProgram) {
      this.props.dispatch(setBreadCrumb(`Programs / ${ adminProgram.adminProgram.name }`))
    }
  }
  render(){
    const ready = this.props.adminProgram.loading
    if(ready){
      return (
        <div className="vsm-component-patient-programInfo">
          <IHLoading />

        </div>
      )
    }

    this.adminProgram = new ProgramClass(this.props.adminProgram.adminProgram)
    //console.log(this.program)
    // setPagePath(`Programmes / ${this.program._get('name')}`)

    return (
      <div className="vsm-component-patient-programInfo">
        {this.renderBaseInfo()}
        {this.renderCareplan()}
        {this.renderProgramDevice()}
      </div>
    )
  }
  renderBaseInfo(){
    return (
      <div className="c-box v-baseinfo">
        <div className="v-title">{I18N.get('keywords.Program')} Info</div>

        <div className="c-cont">

          {this.render_b_span(I18N.get('keywords.Program'), this.adminProgram._get('name'))}
          {this.render_b_span('Description', this.adminProgram._get('description'))}
          {this.render_b_span('Duration', this.adminProgram.format('duration'))}
        </div>

      </div>
    )
  }


  renderProgramDevice(){
    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Devices</div>
        <div className="c-cont">
          {this.adminProgram.variables.devices.length > 0 ? <div className="c-l">
            <b className="b1">Devices to loan</b>
            {
                _.map(_.sortBy(this.adminProgram.variables.devices.filter(v => v.type != 'HR'), ['type']), (l, i)=>{
                    return <p  key={i} className="s1">{I18N.get(`Devices.${l.type}.${l.model}`)}</p>
                })
            }
            </div> : null}
        </div>
      </div>
    )
  }

  renderCareplan(){
    const thProps = {
      list : this.adminProgram.format('thresholdComponentProps'),
      assessmentList : this.adminProgram.format('task_assessment'),
      surveyList : this.adminProgram.format('task_survey')
    }

    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Care Plan</div>
        <Threshold {...thProps} />
      </div>
    )
  }

  render_b_span(b, span){
    return (
      <p className="c-l">
        <b className="b1">{b}</b>
        <span className="s1">{span}</span>
      </p>
    )
  }
}

export default Component
