import React from 'react';
import ClinicInfoComponent from "../../Clinic/components/ClinicInfoComponent";
import {Button, Col, Row, Input,Modal} from "antd";
import OrgCardComponent from "./OrgCardComponent";
import SorterComponent from "./SorterComponent";
import helper from '../../helper'
import AreaDetailComponent from "./AreaCreateComponent";
import AreaEditComponent from "./AreaEditComponent";
import ClinicCreateComponent from "../../Clinic/components/ClinicCreateComponent"
import SearchOrgComponent from "../../Clinic/components/SearchOrgComponent";
import OrgCardContainer from "../container/OrgCardContainer";

const { Search } = Input;

const SingleAreaComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showCreateAreaModal: false,
            showCreateClinicModal: false,
            searchKey: null,
            sort:{
                clinic:{
                    direction: 'desc'
                },
                area:{
                    direction: 'desc'
                }
            }
        }
    }

    setShowEditModal = (showEditModal)=>{
        this.setState({
            showEditModal
        })
    }

    setShowCreateAreaModal = (showCreateAreaModal)=>{
        this.setState({
            showCreateAreaModal
        })
    }

    setShowCreateClinicModal = (showCreateClinicModal)=>{
        this.setState({
            showCreateClinicModal
        })
    }

    onClickEditButton = () => {
        this.setShowEditModal(true);
    }

    renderHeader = (organization)=>{
        const { name, leaf } = organization;
        const orgType = leaf ? 'Clinic' : 'Area'
        const { onClickEditButton } = this;
        return <ClinicInfoComponent onClickDetail={ onClickEditButton }
                                    organization={organization}
                                    organizationName={name}
                                    displayName={`Edit ${orgType}`}
               />
    }

    renderShowEditModal = (organization) => {
        const { showEditModal } = this.state;
        const { setShowEditModal,props } = this;
        const { refetchOrg } = props;
        const { leaf } = organization;
        const orgType = leaf ? 'Clinic' : 'Area'

        return showEditModal && <Modal title={`Edit ${orgType}`} maskClosable={false} visible={true} footer={false} onCancel={ () => this.setShowEditModal(false) }>
                    <AreaEditComponent refetchOrg={refetchOrg} organization={organization} setShowEditModal={setShowEditModal} type={'EDIT'}/>
               </Modal>
    }

    renderCreateAreaModal = (organization) => {
        const { showCreateAreaModal } = this.state;
        const { setShowCreateAreaModal, props } = this;
        const { refetchOrgList, refetchOrg } = props;
        const onCancel = () => this.setShowCreateAreaModal(false);
        const currentUser = helper.getCurrentUser();

        const parentId = _.get(organization, 'id', _.get(currentUser, 'selectedRole.organization.id'));
        return showCreateAreaModal && <Modal title={'Create an area'} maskClosable={false} visible={true} footer={false} onCancel={onCancel}>
            <AreaDetailComponent organization={organization}parentId={parentId} setShowCreateAreaModal={setShowCreateAreaModal} refetchOrgList={refetchOrgList} refetchOrg={refetchOrg} type='CREATE'/>
        </Modal>
    }

    renderCreateClinicModal = (organization) => {
        const { showCreateClinicModal } = this.state;
        const { setShowCreateClinicModal, props } = this;
        const { refetchOrgList, refetchOrg } = props;
        const onCancel = () => this.setShowCreateClinicModal(false);
        return <Modal 
                  visible={showCreateClinicModal} 
                  footer={false}
                  className={'createAreaModal'}
                  title={'Create a clinic '}
                  onCancel={onCancel}
                  destroyOnClose
                >
                  <ClinicCreateComponent 
                    refetchOrgList={refetchOrgList} 
                    refetchOrg={refetchOrg}
                    setShowCreateClinicModal={setShowCreateClinicModal} 
                    organization={organization} 
                    id={organization.id} 
                    name={organization.name}
                  />
               </Modal>
    }

    renderSearchAndButton = ()=>{
        const { renderSearchInput, renderButtonArea } = this;
        return <div className={'searchBoxAndButtons'}>
            {/*{ renderSearchInput() }*/}
            { renderButtonArea() }
        </div>
    }

    renderNoOrg = ()=>{
        return <div className={'noOrgSWrapper'}>
                <span className='placeholder'>You currently don’t have any sub-areas or clinics in this area. Click to create one</span>
                <Button type='primary' onClick={() => this.setShowCreateClinicModal(true)}>Create a clinic</Button>
                <Button onClick={() => this.setShowCreateAreaModal(true)}>Create an area</Button>
               </div>
    }

    renderSearchInput=()=>{
        return <div className='searchBoxWrapper'>
            <Search placeholder='Search by area or clinic name'
                    onChange={(e)=> this.setState({ searchKey:e.target.value })}
            />
        </div>
    }

    renderSorter = (sortKey,title)=>{
        return <SorterComponent sortKey={sortKey}
                                selected = { !!this.state.sort[sortKey].direction }
                                sortDirection={ this.state.sort[sortKey].direction }
                                onChangeSort={this.onChangeSort}
                                title={title}
        />
    }

    onChangeSort = (key,direction)=>{
        let { state } = this;
        _.set(state,`sort.${key}.direction`,direction);
        this.setState({state});
    }

    renderOrgs = (key, data, header, sorter)=>{
        const { direction } = _.get(this,`state.sort.${key}`,{})
        data = direction ? _.orderBy(data,'createdAt',[direction]) : data;
        if(data.length){
            return <div className='cardListWrapper'>
                    <Row>
                        <Col span={24} style={{ marginBottom:10 }}><span>{header}</span></Col>
                        <Col span={24} className={'sorterOuter'} style={{ marginBottom:10 }}>{ this.renderSorter(key,'Time Created')}</Col>
                        <Row>
                            {_.map(data,(data)=>
                            <Col span={4} key={data.id}>
                                <OrgCardContainer key={data.id} type={key} orgId={data.id} name={data.internalName}/>
                                {/*<OrgCardComponent key={data.id} data={data} type={key} orgId={data.id}/>*/}
                            </Col>
                            )}
                        </Row>
                    </Row>
                  </div>
        }
    }

    renderButtonArea = ()=>{
        return <div>
                <Button type='primary' onClick={()=>this.setShowCreateClinicModal(true)}>Create Clinic</Button>
                <Button onClick={()=>this.setShowCreateAreaModal(true)}>Create Area</Button>
               </div>
    }

    render() {
        const { renderHeader, renderOrgs, renderShowEditModal, renderCreateAreaModal, renderSearchAndButton, renderCreateClinicModal, renderNoOrg } = this;
        let { organization, orgList } = this.props;
        const { searchKey } = this.state;
        orgList = !searchKey ? orgList :  _.filter(orgList, (o) => new RegExp(searchKey,'i').test(o.name));
        const clinics = orgList.filter(o => o.leaf);
        const areas = orgList.filter(o => !o.leaf);
        if(!organization){
            return <span>loading</span>;
        }
        return <div>
                <SearchOrgComponent/>
                { renderHeader(organization) }
                { renderSearchAndButton() }
                { renderShowEditModal(organization) }
                { renderCreateAreaModal(organization) }
                { renderCreateClinicModal(organization) }
                { renderOrgs('area', areas,`Areas(${areas.length})`) }
                { renderOrgs('clinic',clinics,`Clinics(${clinics.length})`) }
               </div>
    }
}

export default SingleAreaComponent;