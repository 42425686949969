import { React, _, IHButton, IHSearchTable, moment } from 'ihcomponent';
import { browserHistory } from 'react-router';
import { programConditionMap } from 'libModule/utils'
import Store from 'libModule/reduxStore'
import I18N from 'modulesAll/I18N';

export default class extends React.Component {
  static displayName = 'assessment/main/components/NurseListComponent'

  componentWillUnmount() {
    if (!new RegExp(/^\/nurse_preview_assessment/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null, true);
    }
  }

  getTableProps() {
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, assessmentList } = data;
    const filters = _.get(assessmentList, 'filters', []);

    return {
      title: `${I18N.get('keywords.Assessments')} (${_.get(assessmentList, 'pageInfo.total', 0)})`,
      rowKey: 'id',
      showSearchInput: true,
      searchValue: search,
      onSearch: onTableSearch,
      onSearchEnter,
      onChange: onTableChange,
      inputPlaceholder: 'Search by Name',
      onRow: (record) => {
        return {
          onClick: () => {
            browserHistory.push(`/nurse_preview_assessment/${record._id}`)
          }
        }
      },
      loading,
      dataSource: _.get(assessmentList, 'data', []),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: 10,
        total: _.get(assessmentList, 'pageInfo.total', 0),
      },
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Health Condition',
          key: 'healthCondition',
          dataIndex: 'healthCondition',
          render: (value, row, index) => programConditionMap[value],
          filters: filters.map(key => ({text: programConditionMap[key], value: key})),
          filteredValue: _.get(filter, 'healthCondition', []),
          onFilter: (value, record) => record['healthCondition'].includes(value),
        },
        // {
        //   title: 'Created By',
        //   dataIndex: 'createdBy',
        //   key: 'createdBy',
        //   render : (t, o)=>{
        //     return _.get(t, 'profile.fullName')
        //   },
        // },
        // {
        //   title: 'Created On',
        //   dataIndex: 'createdAt',
        //   key: 'createdAt',
        //   sorter: true,
        //   sortOrder: _.get(sort, 'order', false),
        //   render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : '',
        // },
        // {
        //   title: 'Last Modified By',
        //   dataIndex: 'modifiedBy',
        //   key: 'modifiedBy',
        //   render : (t, o)=>{
        //     return _.get(t, 'profile.fullName')
        //   },
        // },
        {
          title: 'Last Modified On',
          dataIndex: 'modifiedAt',
          key: 'modifiedAt',
          sorter: true,
          sortOrder: _.get(sort, 'order', false),
          render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : '',
        },
        {
          title : 'Actions',
          key : 'Actions',
          render : (t, doc)=>{
            const bp = {
              size: 'exsmall',
              bsStyle: 'primary',
              // onClick: ()=>{
              //   browserHistory.push(`/assessments/edit/${_.get(doc, 'id')}`)
              // },
              className: 'table-action-button'
            }
            const label = 'Details'
            return (
              <IHButton {...bp}>
                {label}
                <i className="fa fa-angle-right ih-right"></i>
              </IHButton>
            )
          }
        }
      ],
    };
  }

  render() {
    return (
      <div className="vsm-main-page vsm-provider-list-main">
        <div className="vsm-main-table">
          <div style={{ textAlign: 'right' }}>
            <IHSearchTable {...this.getTableProps()} />
          </div>
        </div>
      </div>
    );
  }
}
