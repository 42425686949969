import { React, IHLoading } from 'ihcomponent';
import $ from 'jquery';
import { 
  Steps, Button, Avatar, Select, Popconfirm, Modal, message, Icon, Tooltip, Popover 
} from 'antd';
import { 
  CREATE_CARETEAM, CREATE_CARETEAM_FIELDS, EDIT_CARETEAM, AUTHORITIES, AUTHORITIES_INFO,
  inversedRoleMap, stepMaps, 
  selectMemberColumns, selectOrderAndAuthorityColumns
} from '../constant/careTeamConstants';
import MemberTable from '../CareTeam/components/MemberTable';
import moment from 'moment';
import { GQLHelper } from 'libModule/utils';
import API from '../API';
import { FormatHelper } from '../../../lib/utils/common';
import helper from '../helper';
import { CHANNEL_LIMIT } from '../../../lib/constants';
import retry  from 'async-retry';
import { ROLE_NAME } from '../../../lib/constants';
import {roleMap} from "../User/Component/UserListComponent";

const { 
  createTeam, editTeam, deleteTeam, 
  addTeamMembers: addTeamMembersMutation, 
  removeTeamMembers: removeTeamMembersMutation
} = API;
const comToKeyMap = {
  'ihealth-member-wrapper':'Ihealth Members',
  'clinic-member-wrapper': 'Clinic Members'
}
// ref link: https://icons.getbootstrap.com/icons/three-dots-vertical/
const moreIcon = (
<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
</svg>);

const WarningIcon = <Icon type='warning' style={{ fontSize: 14, cursor: 'unset' }} theme='two-tone' />;

// CONTENT renderers
class MemberTableWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      providerList: [],
      loadingProvider: false,
      total:0,
      shouldParseData: false,
      pageInfo: { total:0,lastPage:0 }
    }
  }

  componentDidMount() {
    const { page } = this.state;
    const { wrapperKey } = this.props;
    if(['create','edit'].includes(this.props.modalAction)&&comToKeyMap[wrapperKey]) {
      const roles = roleMap[comToKeyMap[wrapperKey]]
      const organizationId = _.get(this, 'props.organization.id');
      if (organizationId) {
        this.fetchAndSetProviders({roleName: roles, rootId: organizationId, count: 10, page,sort:{ field:'ENROLLMENT_DATE',direction:'DESC' } });
      }
    }
  }

  fetchAndSetProviders = async (variables)=>{
    this.setState({
      loadingProvider: true
    })
    const { pageInfo } = this.state;
    const isLastPage = variables.page == pageInfo.lastPage;
    let data  = await helper.getMembersByOrgIdWithChannelCount(variables,isLastPage);
    let userList =  _.get(data,'data.userList',{ });
    this.setProviderList(userList);
  }

  setProviderList = (userList)=>{
    const {  data,pageInfo } = userList;
    const { page } = this.state;
    this.setState({
      providerList:[
        ...this.state.providerList,
        ...data
      ],
      loadingProvider:false,
      pageInfo,
      shouldParseData:true,
      page: page + 1
    })
  }


  render() {
    const { wrapperKey, modalAction, teamChannelCount } = this.props;
    const { fetchAndSetProviders } = this;
    const { providerList,loadingProvider,page,pageInfo,shouldParseData } = this.state;
    const isEditCareTeam = modalAction === EDIT_CARETEAM;

    return <div key={wrapperKey}>
        <MemberTable
          columns={selectMemberColumns(teamChannelCount)}
          getRowSelection={getRowSelection}
          scroll={{y: 300}}
          infiniteScroll={true}
          roleName={roleMap[comToKeyMap[wrapperKey]]}
          page={page}
          shouldParseData={shouldParseData}
          pageInfo={pageInfo}
          providerList={providerList}
          fetchAndSetProviders={fetchAndSetProviders}
          loadingProvider={loadingProvider}
          {...this.props} // put here to let override default props if available
      />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, fontSize: 14, textAlign: 'right' }}>
        {
          isEditCareTeam && `Total Team Channels: ${teamChannelCount || 0}`
        }
        <span>
          { WarningIcon }
          <span style={{ marginLeft: 5, fontSize: 12 }}>
            Total channels for user exceeds 2000 limit, some channels will not be available
          </span>
        </span>
      </div>
    </div>;
  }
};

const renderSelectClinicMembers = props => {
  const roles = helper.getRefIDWithRoleCategory(
    ['Doctor','Clinic Medical Assistant'],
    props.organizationRoleRefIds
  );
  return <MemberTableWrapper
    {...props}
    wrapperKey='clinic-member-wrapper'
    roles={roles}
    locale={{ emptyText: 'No clinic member' }}
  />
};

const renderSelectIHealthMembers = props => {
  const roles = helper.getRefIDWithRoleCategory(
    ['Nurse','Medical Assistant', ROLE_NAME.HC],
    props.organizationRoleRefIds
  );
  return <MemberTableWrapper
    {...props}
    wrapperKey='ihealth-member-wrapper'
    roles={roles}
    locale={{ emptyText: 'No iHealth member' }}
  />
};

const renderOrderAndAuthorities = props => {
  const dataSource = [...props.teamSelections];
  const teamSelections = props.teamSelections;
  return <div>
    <MemberTableWrapper
      {...props}
      wrapperKey='order-authority-wrapper'
      // get props from component
      columns={componentProps => selectOrderAndAuthorityColumns(componentProps)}
      // dataSource={dataSource}
      teamSelections={teamSelections}
      isForOrderAndVisibility
      getRowSelection={props => getRowSelection(props, true)}
    />
    <div style={{ marginTop: 15, fontSize: 14, fontWeight: 'bold' }}>
      { getTotalDetailTextDiv(dataSource) }
    </div>
  </div>
};

// STEP BUTTON renderers
const renderStepButtons = allProps => {
  const { 
    props: { form }, modalAction, teamId, 
    dataSource, onModalCancel, teamSelections, 
    setLoadingModal, updateLoadingProcess, 
    stepCurrentIndex, goNextStep, isEditMode,
    organization,refetchCareTeamList
  } = allProps;
  const organizationId = _.get(organization, 'id');
  const isLastStep = stepCurrentIndex === (stepMaps(CREATE_CARETEAM).length -1);
  const isEditModal = modalAction === EDIT_CARETEAM;
  return <div style={{ textAlign: 'right', marginTop: 15 }}>
    {
      isEditModal &&
      <Popconfirm
        overlayClassName='confirm-remove-care-team'
        title='Are you sure you want to remove this care team?'
        destroyTooltipOnHide
        trigger='click'
        onConfirm={() => {
          deleteTeam({ id: teamId })
          .then(res => {
            onModalCancel({ deleteTeamId: teamId });
            message.success('Care team removed successfully!');
          }).catch(e => {
            Modal.error({ content: GQLHelper.formatError(e) });
          });
        }}
      >
        <Button type='danger' ghost style={{ float: 'left' }}>
          Delete care team
        </Button>
      </Popconfirm>
    }
    {
      stepCurrentIndex !== 0 &&
      <Button onClick={() => goNextStep(stepCurrentIndex - 1)}>
        Back
      </Button>
    }
    <Button 
      type='primary'
      style={{ marginLeft: 10 }}
      ghost={!isLastStep}
      onClick={() => {
        if(!isLastStep){
          return goNextStep();
        }

        const actions = {
          ADD: 'ADD', REMOVE: 'REMOVE', CREATE: 'CREATE', EDIT: 'EDIT'
        };

        // ignore all the disabled providers
        const filteredTeamSelections = _.filter(teamSelections, p => !p.disabled);

        const closeLoadingModal = (successMsg,newTeam) => setTimeout(() => {
          setLoadingModal({
            loadingModal: '',
            loadingProgress: [],
            cb: () => {
              if (!!newTeam) { // create
                onModalCancel({ checkEditMode: false, appendTeam:true,team:newTeam});
              } else { // edit
                onModalCancel({ checkEditMode: false, refetchCareTeamList });
              }
              message.success(successMsg);
            }
          });
        }, 1000); // to prevent confusing flash of loading modal

        const finalStepExecution = 
        async (values, loadingMsg, successMsg, needEditTeam) => {          
          const teamVariables = getTeamVariables(teamId, filteredTeamSelections, values);
          const progress = [];

          // dataSource is old list, filteredTeamSelections is new list
          const getDiffIds = (checkingArr = [], toCheckArr = []) => 
          _.map(
            _.filter(checkingArr, p => _.findIndex(toCheckArr, { id: p.id }) === -1), 
            'id'
          ); 
          
          // add members is common action between create and edit
          // checking new list against old list, no exist => add
          const toAddIds = getDiffIds(filteredTeamSelections, dataSource);
          const addTeamMembersCommonFn = async teamId => {            
            if(toAddIds.length){
              for(let i in toAddIds){
                const memberId = toAddIds[i];
                const processId = `${actions.ADD}-${memberId}`;
                try {
                  updateLoadingProcess(processId, null, 'inProgress');
                  const addVariables = { teamId, memberIds: [memberId] };
                  const res = await retry(async ()=>await addTeamMembersMutation(addVariables),{retries:3});
                  if(!_.get(res, 'data.addTeamMembers')) {
                    throw new Error('Failed to add member, return of 0');
                  }
                  updateLoadingProcess(processId, null, 'succeeded');
                } catch (error) {
                  updateLoadingProcess(processId, null, 'failed', error);
                }
              }
            }
          };

          const trackProcess = async (action, mutation, variables) => {
            let res;
            try {
              updateLoadingProcess(action, null, 'inProgress');
              res = await mutation(variables);
              if(action === actions.REMOVE && !_.get(res, 'data.removeTeamMembers')){
                throw new Error('Failed to remove member, return of 0')
              }
              updateLoadingProcess(action, null, 'succeeded');
            } catch (error) {
              updateLoadingProcess(action, null, 'failed', error);
            }
            return res;
          }

          // checking old list against new list, no exist => remove
          const toRemoveIds = getDiffIds(dataSource, filteredTeamSelections);

          if(toAddIds.length) {
            progress.push({
              id: actions.ADD,
              process: `Add member(s): `,
              subProcess: _.map(toAddIds, (mI, index) => {
                const m = _.find(teamSelections, {id: mI});
                return {
                  id: `${actions.ADD}-${mI}`,
                  process: `${index + 1}. ${m.fullName}`,
                  status: 'pending'
                }
              })
            });
          }

          if(toRemoveIds.length) {
            progress.push({
              id: actions.REMOVE,
              process: `Remove member(s)`,
              status: 'pending'
            });
          }

          if(!isEditModal) {
            progress.unshift({
              id: actions.CREATE,
              process: 'Create care team',
              status: 'pending'
            });
          }

          progress.push({
            id: actions.EDIT,
            process: 'Edit care team settings, ie. Order and Visibility',
            status: 'pending'
          });

          setLoadingModal({ 
            loadingModal: loadingMsg,
            loadingProgress: progress,
            cb: async () => {
              let newTeam;
              if(isEditModal) { // EDIT
                await addTeamMembersCommonFn(teamId);
                if(toRemoveIds.length)
                  await trackProcess(
                    actions.REMOVE, 
                    removeTeamMembersMutation,
                    { teamId, memberIds: toRemoveIds }
                  );
              } else { // CREATE
                const createTeamVariables = {
                  ..._.pick(teamVariables, CREATE_CARETEAM_FIELDS),
                  organizationId,
                };
                const res = await trackProcess(actions.CREATE, createTeam, createTeamVariables);
                const newTeamId = _.get(res, 'data.createTeam.id');
                newTeam = _.get(res,'data.createTeam');
                await addTeamMembersCommonFn(newTeamId);
                teamVariables.id = newTeamId;
              }
              if(needEditTeam) await trackProcess(actions.EDIT, editTeam, teamVariables);
              closeLoadingModal(successMsg,newTeam);
            } 
          });   
        }

        form.validateFieldsAndScroll(async (error, values) => {
          if(error) return;

          if(!isEditMode) { 
            // do need to process or call any API if there is no change
            message.success('Found no change. Closing popup');
            return onModalCancel();
          }

          let confirmationResp = true, needEditTeam = true;
          let loadingMsg = 'Please wait while we create your care team';
          let successMsg = 'Care team created successfully!';
          if(isEditModal) {
            loadingMsg = 'Please wait while we save your changes';
            successMsg = 'Care team settings saved successfully!'
          } else if(!filteredTeamSelections.length) { 
            // confirmation when creating without team members
            confirmationResp = await new Promise(resolve => Modal.confirm({
              centered: true,
              content: 'You sure to continue without a team member?',
              onOk: () => resolve(true),
              onCancel: () => resolve(false) // prevent execution
            }));
            needEditTeam = false;
          }
          if(!confirmationResp) return;
          setLoadingModal({
            loadingModal: loadingMsg,
            cb: () => finalStepExecution(values, loadingMsg, successMsg, needEditTeam)
          });
        });
      }}
    >
      {!isLastStep ? 'Next' : isEditModal ? 'Save' : 'Create'}
    </Button>
  </div>
};

// MISC functions and renderers
const getTeamVariables = (teamId, teamSelections, values) => {
  const { name, phone,consentProviderName,contactName } = values;
  const memberId = [], primary = [], visibleMembers = [];

  for(let index in teamSelections) {
    const p = teamSelections[index];
    const pId = p.id;
    const auth = _.pick(p, ['visible', 'primary']);
    memberId.push(pId);
    if(auth.primary) primary.push(pId);
    if(auth.visible) visibleMembers.push(pId);
  }

  const teamVariables = {
    id: teamId,
    name,
    memberId,
    primary,
    contactName,
    consentProviderName,
    visibleMembers
  };

  teamVariables.phone = phone ? {
    countryCode: 1,
    number: phone,
    type: 'MOBILE'
  } : null;

  return teamVariables;
}

const getTeamSettings = obj => _.pick(obj, ['visible', 'primary']);

const processProviderData = (orgId, provider) => {
  const pDetails = _.get(provider, 'user');
  let pTeamSettings = getTeamSettings(provider);
  if(_.isEmpty(pTeamSettings)){ // default team settings
    pTeamSettings = _.find(AUTHORITIES, 'default');
    pTeamSettings = getTeamSettings(pTeamSettings);
  }

  // because userList still using allRoles
  let roleForOrg = {};
  if (provider.currentRole || provider.selectedRole) {
    roleForOrg = _.get(provider, 'currentRole',_.get(provider,'selectedRole')) || {};
  } else {
    roleForOrg = _.find(_.get(pDetails, 'allRoles'), pRole => {
      return _.get(pRole, 'organization.id') === orgId;
    }); 
  }

  let team = [];
  _.forEach(pDetails.team, t => {
    const teamName = t.name;
    if(_.get(t, 'organization.id') === orgId){
      // only show the team of selected org
      team.push(teamName);
    }
  });
  team = {
    text: _.join(team, ', '),
    length: team.length
  };

  let phone;
  if(_.get(pDetails, 'phone.length')){
    let mobilePhone = _.find(pDetails.phone, { type: 'MOBILE' }) ||
                      _.find(pDetails.phone, { type: 'WORK' });
    phone = !_.isEmpty(mobilePhone) ? FormatHelper.phone(mobilePhone) : '--';
  }

  return {
    ...pTeamSettings, // primary & visible
    id: _.get(pDetails, 'id'),
    ..._.get(pDetails, 'profile', {}),
    // TODO: 
    // get correct email/ phone, this is just a test
    role: _.get(roleForOrg, 'name', '--'),
    startFrom: _.get(roleForOrg, 'startFrom'),
    email: _.get(pDetails, 'email.0.address'),
    phone,
    team,
    providerTotalChannels: _.get(pDetails, 'providerTotalChannels') || 0
  };
}

const createCustomIcon = index => <div className='step-custom-icon'>{index}</div>;

const generateSteps = ({ modalAction }) => 
_.map(stepMaps(modalAction), (title, index) => 
<Steps.Step
  key={index} 
  title={title}
  icon={createCustomIcon(index + 1)} // step index start at 1
  // onClick={() => onStepClick(index)}
  // className={`
  //   ${isEdit ? 'navigation-steps' : ''}
  //   ${isEdit && index === stepCurrentIndex ? 'navigation-active-step' : ''}
  // `}
/>
);

// disableOnly is to toggle disabled for selected provider
// instead of adding new or removing from the current list
const getRowSelection = (props, disableOnly = false) => { // props from component
  const { selectedRowKeys, addTeamMembers, removeTeamMembers } = props;
  return {
    selectedRowKeys,
    onSelect: (selectedRow, isSelected) => {
      if(isSelected) addTeamMembers([selectedRow], disableOnly);
      else removeTeamMembers([selectedRow.id], disableOnly);
    },
    onSelectAll: (isSelected, selectedRows, changeRows) => {
      if(isSelected) addTeamMembers(selectedRows, disableOnly);
      else removeTeamMembers(_.map(changeRows, 'id'), disableOnly);
    }
  }
};

const countDetails = source => {
  const counts = { Provider: 0, MA: 0, RD: 0, HC: 0, CA: 0};
  for(const i in source){
    const p = source[i];
    if(!p.disabled) {
      const role = inversedRoleMap[p.role];
      if(role)
        counts[role] += 1;
    }
  }
  return counts;
};

const getTotalDetailTextDiv = source => {
  const roleCounts = countDetails(source);
  const total = _.sum(_.values(roleCounts)); 
  return `Total: ${total} (${_.join(_.map(_.entries(roleCounts), a => _.join(a, ': ')), ', ')})`;
};

const getAuthorityTableTitle = () => (
<React.Fragment>
  Visibility
  <Tooltip 
    placement='topRight'
    overlayClassName='care-team-authority-tooltip'
    title={AUTHORITIES_INFO}
    destroyTooltipOnHide
  >
    <Icon type='info-circle' style={{ marginLeft: 5, color: '#000', opacity: '45%' }}/>
  </Tooltip>
</React.Fragment>);

const mapAuthorityValue = ({ visible, primary }) => {
  const auth = _.find(AUTHORITIES, { 
    'visible': visible, 
    'primary': primary 
  });
  return _.get(auth, 'value', '--');
};

const renderTextLink = (text, onClick) => 
  <a onClick={e => {
    e.preventDefault();
    if(onClick) onClick(e);
  }}>{text}</a>;

const renderDefaultText = text =>
<div className='member-table-default-text'>
  {text || '--'}
</div>;

const renderAvatar =
  (src = '/image/default_avator.png') => <Avatar size='small' src={src}/>;

const renderRole = role => <div>{inversedRoleMap[role] || role}</div>;

const renderAuthoritySelect = (record, changeAuthority) => (
  <Select 
    style={{ width: '100%' }} 
    defaultValue={mapAuthorityValue(record)}
    onChange={value => {
      const newAuth = _.pick(_.find(AUTHORITIES, { value }), ['visible', 'primary']);
      changeAuthority(record.id, newAuth, variables => {
        // this callback used for list view
        editTeam(variables)
        .then(() => message.success('Thisisfortesting. Updated authority successfully!'))
        .catch(error => message.error(error))
      });
    }}
    disabled={record.disabled}
  >
    { 
      _.map(AUTHORITIES, ({ value }) => {
        const authText = _.capitalize(value);
        return <Select.Option key={value} value={value}>
          {authText}
        </Select.Option>}) 
    }
  </Select>
);

const renderStartFrom = text =>
  <div>{text ? moment(parseInt(text)).format('MM/DD/YYYY') : '--'}</div>

const renderOrderArrows = (isDisabled, currentIndex, sortedDataSource, setTeamOrder) => {
  // last index when dataSource doesn't have disabled providers
  const lastIndex = _.filter(sortedDataSource, p => !p.disabled).length -1;
  const canMoveUp = !isDisabled && currentIndex !== 0;
  const canMoveDown = !isDisabled && currentIndex !== lastIndex;
  const getCommonStyle = disabled => ({
    color: '#000',
    opacity: disabled ? '15%' : '45%',
    backgroundColor: disabled ? 'white !important' : ''
  });
  return <div style={{ paddingTop: 6 }}>
    <Button 
      key={`up-${currentIndex}`} icon='up-square' 
      className='no-border-button' 
      style={getCommonStyle(!canMoveUp)}
      disabled={!canMoveUp}
      onClick={()=> setTeamOrder(sortedDataSource, currentIndex, currentIndex - 1)} 
    />
    <Button 
      key={`down-${currentIndex}`} icon='down-square'
      className='no-border-button' 
      style={getCommonStyle(!canMoveDown)}
      disabled={!canMoveDown} 
      onClick={()=> setTeamOrder(sortedDataSource, currentIndex, currentIndex + 1)} 
    />
    </div>
};

const renderViewMemberOptions = (props, record) => {
  return <Popover 
    overlayClassName='view-member-option-popover'
    placement='left'
    content={
      <Popconfirm
        overlayClassName='view-member-option-remove-confirm'
        title='Are you sure you want to remove this member?'
        placement='topRight'
        trigger='click'
        destroyPopoverOnHide
        onConfirm={() => {
          $('.view-member-option-popover').remove();
          const { teamId, refetchCareTeamList } = props;
          const pId = record.id;
          const loadingModal = Modal.info({
            className: 'view-member-option-loading-popup',
            destroyOnClose: true,
            maskClosable: false,
            content: <div 
              style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                textAlign: 'center' 
              }}
            >
              <IHLoading />
              <span>Please wait while remove member</span>
            </div>
          });
          removeTeamMembersMutation({ teamId, memberIds: [pId] })
          .then(() => {
            setTimeout(() => {
              loadingModal.destroy();
              message.success('Member removed successfully');
              refetchCareTeamList();
            }, 1000); // to prevent confusing flash of loading modal
          })
          .catch(e => {
            loadingModal.destroy();
            console.error(e);
            Modal.error({ content: GQLHelper.formatError(e) });
          });
        }}
      >
        <a onClick={e => e.preventDefault()}>Remove</a>
      </Popconfirm>
    }
    trigger='click'
    destroyPopoverOnHide
  >
    <Button className='no-border-button' style={{ float: 'right', color: 'unset' }}>
      {moreIcon}
    </Button>
  </Popover>
};

const renderCareTeamList = team => {
  return <Tooltip
    overlayClassName='care-team-total-tooltip'
    title={team.length > 1 ? `Total: ${team.length} teams` : ''}
    destroyTooltipOnHide
  >
    <div className='member-table-careteam-text'>
      {team.text || '--'}
    </div>
  </Tooltip>
};

// const MemberChannelCount = compose(
//   graphql(Query.getTeamMemberTotalChannels, {
//     options: props => ({
//       variables: { teamMemberId: props.teamMemberId }
//     })
//   })
// )(props => { // shorthand component
//   const { data, teamChannelCount = 0 } = props;
//   if(data.loading) return 'Loading...';

//   const memberChannelCount = _.get(data, 'getTeamMemberTotalChannels') || 0;

//   const willOverLimit = (memberChannelCount + teamChannelCount) >= CHANNEL_LIMIT;
//   return <div style={{ color: willOverLimit ? 'red' : 'inherit', textAlign: 'right' }}>
//     { memberChannelCount }
//   </div>;
// });

const renderMemberChannelCount = (providerTotalChannels = 0, teamChannelCount = 0) =>  {
  let willOverLimit = (providerTotalChannels + teamChannelCount) >= CHANNEL_LIMIT;  

  return <div style={{ textAlign: 'right', marginRight: 4 }}>
    { providerTotalChannels }
    { 
      willOverLimit && WarningIcon
    }
  </div>;
};

export {
  processProviderData, generateSteps,
  renderSelectClinicMembers,
  renderSelectIHealthMembers,
  renderOrderAndAuthorities,
  getTotalDetailTextDiv, getAuthorityTableTitle, mapAuthorityValue,
  renderStepButtons, renderTextLink, renderStartFrom, renderDefaultText, 
  renderAvatar, renderRole, renderAuthoritySelect,
  renderOrderArrows, renderViewMemberOptions, 
  renderCareTeamList, renderMemberChannelCount
};