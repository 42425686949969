import React from 'react';
import { openErrorModal,openModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import { VISIT_COUNT_MAX } from 'libModule/constants';
import { Tabs,Row,Col,Icon,Button } from 'antd';
import moment from 'moment';
import map from '../constants/map';
import { IHLoading } from 'ihcomponent'
import PatientVisitCardComponent from '../components/PatientVisitCardComponent';
import appointmentTypeColorCode from '../constants/appointmentTypeColorCode';
import appointmentImgFilterCode from '../constants/appointmentImgFilterCode';
import $ from 'jquery';
import {graphql} from "react-apollo/index"; 
import {compose, withState, withPropsOnChange, branch} from 'recompose';
import {helpers as visitHelpers, helpers} from "../helpers";
import {message} from "../../../../../package/IHComponent";
import deleteVisitQuery from 'graphqlModule/mutation/deleteVisit';
import visitList from 'modulesAll/graphql/patientVisitList';
import Client from 'libModule/gqlClient';
import {connect} from "react-redux";
import actions from "../actions";
import CreateVisitInPatientDetailContainer from "../containers/CreateVisitInPatientDetailContainer";
import CreateAppointmentWithCalendarContainer from '../../remoteEnrollment/component/CreateAppointmentWithCalendarContainer';
import getVirtualVisitStatus from "../../graphql/getVirtualVisitStatus";
import {VISIT_CATEGORY_ENUM} from "../../visitsPage/constants";

const TabPane = Tabs.TabPane;


const additionalFollow_Up_Types = {
    'FOLLOW_UP1':'1st Follow Up',
    'FOLLOW_UP2':'2nd Follow Up',
    'FOLLOW_UP3':'3rd Follow Up',
    'ANNUAL': 'Annual Visit',
}

const PatientAppointmentComponent = class extends React.Component {
    constructor(){
        super();
        this.state = {
            activeKey :-1,
            showCreateAppointmentModal:false
        }
        this.ref = React.createRef();
    }

    setActiveKey = (activeKey)=>{
        this.setState({
            activeKey
        })
    }

    // componentWillReceiveProps(nextProps){
    //     if(!this.props.visitList&&nextProps.visitList){
    //         const activeKey = _.findIndex(nextProps.visitList,(v)=> moment(v.appointmentAt).isAfter(moment().startOf('day')));
    //         this.setState({
    //             activeKey
    //         })
    //     }
    //
    // }

    renderNavButton=(year)=>{
        const setYear = _.get(this,'props.setYear');
        return <Row style={{fontSize:18}}>
            <Col span={12}>
                <Icon type="left-square" onClick={()=>{ this.setState({ activeKey:-1 }); setYear(-1)}}/>
            </Col>
            <Col span={12}>
                <Icon type="right-square" onClick={()=>{ this.setState({ activeKey:-1 }); setYear(1)}}/>
            </Col>
        </Row>
    }

    renderCreateAppointmentModal=()=>{
        const { showCreateAppointmentModal } = this.state;
        const { props } = this;
        if(showCreateAppointmentModal){
            return (<CreateVisitInPatientDetailContainer {...props} onCancel={this.closeCreateAppointmentModal}/>)


            // return <Modal visible={true} maskClosable={false} footer={null}
            //               // width={'fit-content'}
            //               width={600}
            //               onCancel={()=>this.closeCreateAppointmentModal()}
            //               closable={true}
            //               title={'Create an appointment'}
            //        >
            //           <CreateVisitInPatientDetailContainer {...props} fromDetail={true} closeModal={this.closeCreateAppointmentModal}/>
            //           {/*<CreateAppointmentModal {...props} closeModal={this.closeCreateAppointmentModal}/>*/}
            //        </Modal>
        }
    }

    handleOnClickCreateAppointment=()=>{
        this.setState({
            showCreateAppointmentModal:true
        })
        // openModal(<CreateAppointmentModal {...props}/>,{className:'createVisitModal'});
    }

    closeCreateAppointmentModal = () =>{
        this.setState({
            showCreateAppointmentModal: false
        })
    }

    onCancel = () => {
        if(this.props.refetchUser) {
            this.props.refetchUser();
        }
        if(this.props.refetchNextVisit){
          this.props.refetchNextVisit();
        }
        this.props.refetch();
    }

    renderCreateAppointmentButton = (patientId,fullName,patientBirthday,timerName)=>{
        // const parsedFullName = btoa(fullName);

        return (
            <CreateAppointmentWithCalendarContainer patientId={patientId} onCancel={this.onCancel} timerName={timerName}>
                <Button
                    // onClick={()=>goPath(`/appointments/create/${patientId}/${parsedFullName}/${patientBirthday}`)}
                    type='primary'
                    size='small'
                    // onClick={()=>this.handleOnClickCreateAppointment(patientId)}
                    style={{ float:'right',marginRight: 20,backgroundColor:'#4278c3',border:'unset' }}
                >+</Button>
            </CreateAppointmentWithCalendarContainer>
        )
    }

    renderHeader=(year,patientId,fullName,patientBirthday,timerName)=>{
        return <Row style={{ padding: '10px 0 10px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ float: 'left' }}>
                    <Col span={20}>
                        <span style={{ fontSize:18,fontWeight:'bold',marginRight:5 }}>Appointments</span>
                        <span style={{ fontSize:18,fontWeight:'bold' }}>{year}</span>
                    </Col>
                    <Col span={4}>
                        { this.renderNavButton(year) }
                    </Col>
                </div>
                <div style={{ float: 'right' }}>
                    { this.renderCreateAppointmentModal() }
                    {this.renderCreateAppointmentButton(patientId,fullName,patientBirthday,timerName)}
                    {/*{this.props.isInChat ? '' : this.renderCreateAppointmentButton(patientId,fullName,patientBirthday)}*/}
                </div>
            </div>
        </Row>
    }

    deleteVisit = (id)=>{
        const { patientId,filter,year,refetch, refetchNextVisit } = this.props;
        const { provider } = filter&&filter.provider ? filter : { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                    res.push(d.id)
                    return res;
                },[]
            )};
        let variables = { };

        variables.memberId = patientId;
        variables.providerIds = provider;

        Client.mutate({
            mutation: deleteVisitQuery,
            notifyOnNetworkStatusChange: true,
            variables: {id:id}
        }).then(() => {
          if(this.props.refetchNextVisit){
            this.props.refetchNextVisit();
          }
           return refetch();
        }).then(()=>{
            message.success('Patient Appointment cancelled');
        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })

        // Client.mutate({
        //     mutation: deleteVisitQuery,
        //     notifyOnNetworkStatusChange: true,
        //     variables: {id:id},
        //     refetchQueries:[{
        //         query: visitList,
        //         variables: helpers.setFromAndToDate(variables,startDate,endDate),
        //         fetchPolicy:'network-only'
        //     }]
        // }).then(()=>{
        //     message.success('Patient Appointment canceled');
        // }).catch((error)=>{
        //     this.props.openErrorModal(GQLHelper.formatError(error));
        // })
    }

    // getBorderColor = (visitList,activeKey)=>{
    //     const selectedVisit = _.get(visitList,activeKey,{ });
    //     const selectedVisitType = _.get(selectedVisit,'type');
    //     const borderColor = appointmentTypeColorCode[selectedVisitType].borderColor;
    //     return borderColor;
    // }

    // onTabChange=(visitList,activeKey)=>{
    //     const borderColor = this.getBorderColor(visitList,activeKey);
    //     const selectedItem = $('body').find('.patientAppointmentsTab').find('.ant-tabs-ink-bar.ant-tabs-ink-bar-animated');
    //     selectedItem.css({ 'background-color': borderColor });
    //     this.setActiveKey(activeKey)
    // }

    render(){
        const { loading,patientId,patientName,year,nextVisit={},refetch, visitList,timerName } = this.props;
        let { activeKey } = this.state;
        if(loading){
            return <Row style={{ height: 200,display:'flex',justifyContent:'center',alignItems:'center' }}>
                    <IHLoading/>
                   </Row>
        }

        let nextAppKey = -1
        _.map(visitList,(v,i)=>{
            if(v.id==nextVisit.id){
                nextAppKey = i;
            }
        });
        const defaultKey = nextAppKey >=0 ? (nextAppKey+'' ) : '';
        const selectedVisit = _.get(visitList,defaultKey,{ });
        const selectedVisitType = _.get(selectedVisit,'type',' ');
        const activeStyle = (v,i)=> {
            const isActive = (activeKey == i) || (activeKey == -1 && nextAppKey == i);
            const color = isActive ? appointmentTypeColorCode[`${v.type}`].borderColor : '';
            const selectedItem = $('body').find('.patientAppointmentsTab').find('.ant-tabs-ink-bar.ant-tabs-ink-bar-animated');
            selectedItem.css({ 'background-color': color });
            return {
                color: isActive ? color : '',
                fontWeight: isActive ? 'bold': 'normal',
                whiteSpace: 'pre-wrap'
            };
        }

        const getImgFilter = (v, i) => {
          const isActive = (activeKey == i) || (activeKey == -1 && nextAppKey == i);
          const filter = isActive ? appointmentImgFilterCode[`${v.type}`] : '';
          return filter || 'contrast(0.3)';
        };

        return<div className='patientAppointmentsContainer'>
                {this.renderHeader(year,patientId,patientName,undefined,timerName)}
            {
                loading ?
                        <Row style={{ height: 200,display:'flex',justifyContent:'center',alignItems:'center' }}>
                            <IHLoading/>
                        </Row>
                        :
                <Tabs
                      tabPosition={'left'}
                      className={'patientAppointmentsTab ' + selectedVisitType}
                      type={'line'}
                      onChange={(activeKey) => this.setActiveKey(activeKey)}
                      activeKey={(defaultKey>=0&&activeKey==-1 ? defaultKey : activeKey)+''}
                >

                    {
                        _.map(visitList, (v, i) => {
                                const displayStyle = activeStyle(v, i);
                                const imgStyle = {
                                  width: 14,
                                  marginLeft: 10,
                                  marginTop: -1,
                                  filter: getImgFilter(v, i)
                                };
                                return <TabPane
                                    tab={
                                        <div style={displayStyle} className={ v.id == nextVisit.id ? 'isNextVisit' : ''}>
                                            <span>
                                              {map[`${v.type}`]||additionalFollow_Up_Types[`${v.type}`]}
                                            </span>
                                            {
                                              ({
                                                PHONE: <img src='/image/telephone.svg' style={{ ...imgStyle }} />,
                                                VIRTUAL: <img src='/image/camera.svg' style={{ ...imgStyle }} />
                                              })[v.category] || ''
                                            }
                                        </div>
                                    }
                                    key={i + ''} 
                                    forceRender={true}
                                >
                                    <PatientVisitCardComponent 
                                      patient={this.props.patient}
                                      visit={v} 
                                      deleteVisit={this.deleteVisit} 
                                      refetch={refetch} 
                                      refetchUser={this.props.refetchUser}
                                      router={this.props.router}
                                      route={this.props.route}
                                      isInChat = {this.props.isInChat}
                                      refetchNextVisit={this.props.refetchNextVisit}
                                      timerName={this.props.timerName}
                                    />
                                </TabPane>
                            }
                        )

                    }
                </Tabs>
            }
            </div>
    }
}

const withData = graphql(visitList, {
    options: (ownProps) => {
        const { filter,sort,year,patientId } = ownProps;
        const  variables = {
            count: VISIT_COUNT_MAX,
        }
        let startDate = moment({year}).startOf('year');
        let endDate = moment({year}).endOf('year');
        variables.appointmentFrom = Number(startDate);
        variables.appointmentTo = Number(endDate);
        variables.sort = _.isEmpty(sort) ? { } : { field: fieldMap[sort.field],direction: sort.direction };
        variables.memberId = patientId;
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            //errorPolicy: 'all'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList, refetch } = data;
        const visitListData = _.get(visitList, 'data', _.get(ownProps, 'cacheVisitList'));
        return {
            loading,
            // TODO: remove cache props...
            visitList: visitListData,
            refetch,
            virtualVisits: _.filter(visitListData, v => v.category === VISIT_CATEGORY_ENUM.VIRTUAL),
        }
    }
})
const getVirtualVisitsStatus = graphql(getVirtualVisitStatus, {
    options:(ownProps)=>{
        const { isTabSelected, visitTabConfig,visitList } = ownProps;
        const virtualVisits = _.filter(visitList||[], v => v.category === VISIT_CATEGORY_ENUM.VIRTUAL);
        return {
            variables: { visitIds: _.map(virtualVisits, 'id') },
            fetchPolicy: 'network-only',
        }
    },
    props:({ ownProps, data })=>{
        let { visitList = [] } = ownProps; // from getVisitList props
        const { error, getVirtualVisitStatus,loading } = data;
        if(error) console.error(error);
        if(loading) {
            return {
                loading
            }
        }
        visitHelpers.parseVisitList(visitList, getVirtualVisitStatus);
        return { visitList }; // visit list with virtual status
    }
});
const mapToDispatch = (dispatch)=>{
    return {
        setYear:(offset)=>dispatch(actions.setYear(offset)),
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(modalActions.confirm(props)),
        openModal:(content,props)=>dispatch(openModal(content,props)),
    }
};

const mapToProps = (props)=>{
    return{
        year:props.visit.main.year,
    }
};

export default compose(
    connect(mapToProps,mapToDispatch),
    // visitList is refetched after create visit is triggered, this is used to prevent close modal because no visitList returned from refetch
    withState('cacheVisitList', 'setCacheVisitList', []),
    withData,
    getVirtualVisitsStatus,
    withPropsOnChange(['loading'], props => {
        if(_.get(props, 'visitList', []).length > 0) {
            props.setCacheVisitList(props.visitList);
        }
    }),
)(PatientAppointmentComponent);
