import { constants } from 'libModule/utils'

export default constants('timerClaimed', 'main', [
  'START_TIMER', 'PAUSE_TIMER', 'STOP_TIMER',
  'RESET_TIMER', 'RESUME_TIMER', 'ABORT',
  // 'UPDATE_INTERVENTION_DETAILS', 'SET_UUID',
  'CLEAR_UUID', 'RESET_COOL_DOWN', 'TIMEOUT_PAUSE', 'TIMEOUT_RESUME', 'FOCUS_PAGE', 'BLUR_PAGE',
  // 'START_CHAT'
  'INTERACTION', 'DONE',
]);


export const IDLE_TIME_THRESHOLD_TIME_IN_MS = 2 * 60000;