import React from 'react';
import { Rate, Popover } from 'antd';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

import { getTimeWithFormat } from '../../../helper/signOffHelper';

const SignOffFoodLog = (props) => {
  const { foodLogs } = props;

  return <div className='signOffItem'>
    <div className='header'>
      Food Diary
    </div>
    {
      _.map(foodLogs, log => {
        const { id, uploadedAt, images, comments, rating } = log; 
        const { star } = rating;

        const HCComments = _.filter(comments); // TODO: get HC comments

        return <div key={id} className='signOffCard signOffFoodLog'>
          <div className='description image'>
            {
              _.map(images, (img, idx) => {
                const thumbnailLink = _.get(img, 'thumbnailLink');
                const fullResLink = _.get(img, 'link');
                return (
                  <Popover
                    key={idx}
                    overlayClassName='foodLogImagePopover'
                    trigger='hover'
                    content={<img src={fullResLink}/>}
                    placement='rightBottom'
                    destroyTooltipOnHide
                  >
                    <img src={thumbnailLink}/>
                  </Popover>
                );
              })
            }
          </div>
          <div className='description'>
            Posted on: {getTimeWithFormat(uploadedAt)}
          </div>
          <div className='description note'>
            <div className='rate hideOnList'>
              <span>Rating:</span>
              <Rate value={star} className='foodLogRate' disabled/>
            </div>
            {
              _.map(HCComments, (comment, cId) => {
                const { text, commentedAt, commentedBy } = comment;
                const commentedByName = _.get(commentedBy, 'profile.fullName');
                return <div key={`${id}-${cId}`} className='comment'>
                  {
                    text && 
                    <p className='hideOnList'>
                      {
                        `Comment: 
                        ${text}`
                      }
                    </p>
                  }
                  <SignOffSubmitInfo 
                    submittedBy={commentedByName}
                    submittedAt={commentedAt}
                  />
                </div>
              })
            }  
          </div>
        </div>;
      })
    }
  </div>;
}

export default SignOffFoodLog;