import { connect } from 'react-redux';
import AssessmentFormComponent from '../components/AssessmentFormComponent';
import action from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  routing: state.routing,
  fields: state.Assessment.edit.fields,
  isSubmitting: state.Assessment.status.isSubmitting,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeField: (fieldPath, value) => dispatch(action.changeField(fieldPath, value)),
  addQuestion: () => dispatch(action.addQuestion()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AssessmentFormComponent);
