import React from 'react';
import GenerateMTPRComponent from "../component/GenerateMTPRComponent";

const GenerateMTPRContainer = class extends React.Component{
    static displayName = "workStation/container/GenerateMTPRContainer";

    render() {
        return <div className="generate-mtpr-container">
            <GenerateMTPRComponent {...this.props}/>
        </div>
    }
}


export default GenerateMTPRContainer;