import { graphql, withApollo, compose } from 'react-apollo'
import { editAdminProgramTask } from 'graphqlModule/mutation/editAdminProgram'
import setDevicesForAdminProgram from 'graphqlModule/setDevicesForAdminProgram'
import { program as updateQueries } from 'graphqlModule/updateQueries/program'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react';
import { validateAntdFormWithFieldsValue } from 'libModule/utils'
import { getFormProps } from 'commonModule/components/careplan/formData/VitalForm'
import actions from 'ProgramEditModule/Careplan/actions/VitalFormActions'
import AlertFormContainer from 'ProgramEditModule/VitalAlert/containers/CommonContainer'
import get from 'lodash/get'
import { IHButton, _, IHBaseFormWithRow  } from 'ihcomponent'
import { CareplanHelper } from '../../../../utils/helpers/task'
import alertListActions from 'ProgramEditModule/VitalAlert/actions/AlertListActions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { DEVICE_VITAL_TYPE_MAP, DEFAULT_DEVICE_FOR_VITAL } from 'modulesAll/utils/constants/task'
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withProps } from 'recompose';
import { message } from 'antd';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
const trace = debug ? traceF : () => R.identity;

class Container extends Component {
  static displayName = 'program/ProgramEdit/Careplan/containers/VitalFormContainer'

  componentWillUnmount () {
    this.props.reset()
  }
  render() {
    const title = this.renderTitle()
    const backLink = this.renderBackLink()
    const body = this.renderBody()
    const submitBtn = this.renderSubmitBtn()
    const alertForm = this.renderAlertForm()
    return (
      <div>
        {backLink}
        {title}
        {body}
        {alertForm}
        {submitBtn}
      </div>
    )
  }
  renderAlertForm() {
    const { AlertForm } = this.props
    const alertForm = <AlertFormContainer {...AlertForm} />
    return alertForm
  }
  renderBody() {
    const { onFieldsChange, fieldsValue, VitalList, isEditMode } = this.props
    const formProps = getFormProps({
      onFieldsChange,
      fieldsValue,
      VitalList,
      className: 'vsm-form-body v-form-required',
      isEditMode,
    })
    const body = <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }
  async submit() {
    const { fieldsValue, program, editAdminProgramTask, setDevicesForAdminProgram, changePage, AlertList, isEditMode } = this.props
    // validate all fields before submission
    const isFormValid = await validateAntdFormWithFieldsValue(fieldsValue, this.baseForm)
    if (!isFormValid) return
    const panesFieldsValue = AlertList.panesFieldsValue

    let variables = {
      id: program.id,
      tasks: [CareplanHelper.convertVitalToDBTask(fieldsValue, panesFieldsValue)]
    }
    const auditLogDetails = {
      programName: program.name,
      tasks: [CareplanHelper.convertVitalToDBTask(fieldsValue, panesFieldsValue)].map((task) => {
        return _.mapValues(task, (value) => {
          delete task['id']
          switch(value) {
            case 'BG':
              return 'blood glucose'
            case 'BP':
              return 'blood pressure'
            case 'HR':
              return 'heart rate'
            case 'HS':
              return 'weight'
            default:
              return value
            }
          })
        }
      )
    }

    try {
      const res = await editAdminProgramTask({ variables, updateQueries })
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.task.vital.edit'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      console.log('got data', res.data);
      changePage()
    }
    catch (err) {
      message.error(err.message);
      console.error(err);
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.task.vital.edit'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
    if (!isEditMode) {
      const type = DEVICE_VITAL_TYPE_MAP[get(fieldsValue, 'vitalType.value')]
      const model = DEFAULT_DEVICE_FOR_VITAL[type]
      const addedDevice = {
        [type]: model
      }
      variables = {
        id: program.id,
        devices: {
          ...addedDevice
        }
      }
      try {
        await setDevicesForAdminProgram({ variables })
      }
      catch (err) {
        console.log('update device error: ', err)
      }
    }
  }
  renderBackLink(){
    return (
      <div className="v-link v-link-inside" onClick={() => this.props.changePage()}>
        <div>
          <i className="fa fa-angle-left" />
          Back to Care Plan
        </div>
      </div>
    )
  }
  renderTitle() {
    return <h3 className='vsm-form-title'>Add Vitals to Care Plan</h3>
  }
  renderSubmitBtn() {
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnRight: {
        alignSelf: 'flex-end'
      }
    }
    const addBtnProp = {
      onClick : (e)=>{
        this.submit()
      }
    }
    const cancelBtnProp = {
      onClick : (e)=>{
        this.props.changePage()
      }
    }
    const btn = (
      <div style={styles.footer}>
        <div style={styles.btnRight}>
          <IHButton {...cancelBtnProp} style={{marginRight: 10}} bsStyle="default" label="Cancel" />
          <IHButton {...addBtnProp} bsStyle="primary" label="Save" />
        </div>
      </div>
    )
    return btn
  }
}

const mapState = ({ program }, ownProps) => R.merge({
  AlertList: get(program.ProgramEdit, 'Careplan.VitalAlert.AlertList'),
  AlertForm: get(program.ProgramEdit, 'Careplan.AlertForm'),
  VitalList: get(program.ProgramEdit, 'Careplan.VitalList'),
}, get(program.ProgramEdit, 'Careplan.VitalForm'));

const mapDispatch = (dispatch) => {
  const reset = alertListActions.reset
  return {
    ...bindActionCreators(actions, dispatch),
    reset: () => dispatch(reset())
  }
}

Container.propTypes = {
  program: PropTypes.object,
  changePage: PropTypes.func,
  onFieldsChange: PropTypes.func,
  editAdminProgramTask: PropTypes.func,
  setDevicesForAdminProgram: PropTypes.func,
  resetAlertList: PropTypes.func,
  fieldsValue: PropTypes.object,
  AlertForm: PropTypes.object,
  VitalList: PropTypes.object,
  isEditMode: PropTypes.bool,
}

const forceSlotToWeeklyMeal = (props) => (
  R.pathEq(['fieldsValue', 'schedule', 'value', 'frequency'], 'slot', props) && R.pathEq(['fieldsValue', 'vitalType', 'value'], 'Blood Glucose', props)
    ? R.assocPath(['fieldsValue', 'schedule', 'value', 'frequency'], 'weeklyMeal', props) 
    : props
  );

export default compose(
  withApollo,
  graphql(setDevicesForAdminProgram, { name: 'setDevicesForAdminProgram' }),
  graphql(editAdminProgramTask, {name: 'editAdminProgramTask'}),
  connect(mapState, mapDispatch),
  withProps(forceSlotToWeeklyMeal),
)(Container);
