import { ccpLoginUrl, addCCP, logoutCCP, cleanUpCCP } from '../helper/helpers';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import reduxStore from '../../../lib/reduxStore';
import callActions from '../actions';
import noteActions from '../../postIt/actions';


const STORAGE_KEYS = {
  LOCAL: 'cpp-start-times',
  SESSION: 'ccp-start-time'
}

const USER_LOGGED_OUT_STATE = 'LOGGED_OUT';

const CCP_STATUS_ENUM = {
  OPEN: 'open', CLOSE: 'close'
};

class CCPClass {
  loginPopupId = 'ccp-login-popup';
  STATUS_CHECK_INTERVAL = 10000;
  STATUS_CHECK_ATTEMPTS = 6;

  constructor() {
    this.subscriptionsHandler = () => { };
  }

  setLoginPopup(popup) {
    window.ccpLoginPopup = popup;
  }

  closeLoginPopup() {
    if (window.ccpLoginPopup && window.ccpLoginPopup.close) {
      window.ccpLoginPopup.close();
    }
  }

  setSubscriptionsHandler = (subscriptionsHandler) => {
    this.subscriptionsHandler = subscriptionsHandler;
  }

  getStartTimes() {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.LOCAL)) || [];
  }

  _terminate() {
    window.awsCCP = {};
    if (window.connect && window.connect.core.initialized) {
      window.connect.core.terminate();
    }
  }

  // set ccp start time: when agent gets first refresh data from upstream
  setCCPStartTime() {
    const savedStartTime = this.getCCPStartTime();
    if (savedStartTime) {
      // start time exists, void
      return;
    }
    const startTime = new Date().getTime();
    // check to avoid duplicate
    const startTimes = this.getStartTimes();
    if (startTimes.includes(startTime)) {
      // try again every 5 seconds
      setTimeout(this.setCCPStartTime(), 5 * 1000);
    } else {
      // save the start time
      startTimes.push(startTime);
      sessionStorage.setItem(STORAGE_KEYS.SESSION, startTime);
      localStorage.setItem(STORAGE_KEYS.LOCAL, JSON.stringify(startTimes));
    }
  }

  getCCPStartTime() {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEYS.SESSION));
  }

  terminateCCP() {
    // const startTimes = this.getStartTimes();
    // const ccpStartTime = this.getCCPStartTime();
    // const newStartTimes = startTimes.filter(st => st !== ccpStartTime);
    // cleanup
    sessionStorage.removeItem(STORAGE_KEYS.SESSION);
    localStorage.setItem(STORAGE_KEYS.LOCAL, JSON.stringify([]));
    localStorage.setItem('agent-choose-status', USER_LOGGED_OUT_STATE);
    // check if ccp is the last active
    // if(newStartTimes.length === 0)  {
    // yes, 
    // set agent status to Offline
    // const agent = window.awsCCP.agent;
    // if(agent) {
    //   const offlineState = agent.getAgentStates().filter(function (state) {
    //     return state.type === window.connect.AgentStateType.OFFLINE;
    //   })[0];
    //   agent.setState(offlineState, {
    //     success: function () {
    //       console.log('heree success');
    //       // logoutCCP();
    //     },
    //     failure: function () {
    //       console.log('here failure');
    //       logoutCCP();
    //     }
    //   });
    // }
    // } else {
    this._terminate();
    // }
  }

  logout = (options = {}) => {
    const {
      onSettled,
      onError,
      onDone, // regardless of error
    } = options;
    // move to offline
    const agent = _.get(window, 'awsCCP.agent');
    if (!agent) {
      cleanUpCCP();
      if (onDone) onDone();
      return;
    }
    var offlineState = agent.getAgentStates().filter(function (state) {
      return state.type === window.connect.AgentStateType.OFFLINE;
    })[0];
    // set aws to offline then logout
    agent.setState(offlineState, {
      success: async () => {
        if (window.CCPClass && window.CCPClass.closeLoginPopup) {
          window.CCPClass.closeLoginPopup();
        }
        window.localStorage.setItem('agent-choose-status', USER_LOGGED_OUT_STATE);
        await logoutCCP();
        if (onSettled) onSettled();
        if (onDone) onDone();
      },
      failure: function () {
        if (onError) onError();
        if (onDone) onDone();
      }
    });
  }

  setCallContactData(data) {
    localStorage.setItem('call-contact-data', JSON.stringify(data));
  }

  // origin: tab where user starts an outbound call
  // only origin has outboundAttributes
  isUnknownOutbound() {
    const outboundAttributes = JSON.parse(sessionStorage.getItem('outboundAttributes'));
    return _.isEmpty(outboundAttributes) || !!_.get(outboundAttributes, 'unknown.value');
  }

  saveError(errorMessage) {
    // const errors = JSON.parse(sessionStorage.getItem('callCenterErrors')) || [];
    // errors.push({ time: new Date(), error });
    // sessionStorage.setItem('callCenterErrors', JSON.stringify(errors));
    Mixpanel.track('receive', 'phone', 'error', { errorMessage });
  }

  runStatusCheck = () => {
    let attempts = 0;
    clearInterval(window.statusChecker);
    window.statusChecker = setInterval(() => {
      try {
        const isInitialized = window.connect.core.initialized;
        const loginPopupOpen = !window.ccpLoginPopup.closed;
        const iframe = document.querySelector('#callBody iframe');
        if (
          (!isInitialized && attempts === this.STATUS_CHECK_ATTEMPTS)
          || !loginPopupOpen
          || isInitialized
        ) {
          clearInterval(window.statusChecker);
          return;
        }
        console.info('[CCP] checking status', attempts);
        iframe.src += '';
        attempts += 1;
      } catch (error) {
        clearInterval(window.statusChecker);
        console.error('something went wrong with status checker', error);
      }
    }, this.STATUS_CHECK_INTERVAL);
  }

  checkLogin = async (options = {}) => {
    const {
      redirectToLogin = true,
      onHasLogin,
    } = options;
    let hasLogin = false;
    if (!window.awsCCP) {
      addCCP();
      this.subscriptionsHandler();
      return this.checkLogin(options);
    } else {
      const agent = window.awsCCP.agent;
      if (agent === undefined && redirectToLogin) {
        const win = window.open(
          ccpLoginUrl,
          this.loginPopupId,
          'width=420,height=520,position=absolute,top=0,left=0'
        );
        this.setLoginPopup(win);
        if (win.focus) win.focus();
        this.runStatusCheck();
      } else if (agent !== undefined) {
        hasLogin = true;
        if (onHasLogin) onHasLogin(agent);
      }
    }
    return hasLogin;
  }

  openCCP = () => {
    const { AWSConnect } = reduxStore.getState();
    const ccpStatus = _.get(AWSConnect, 'ccpStatus');
    if (ccpStatus !== CCP_STATUS_ENUM.OPEN) {
      reduxStore.dispatch(callActions.ccpStatus(CCP_STATUS_ENUM.CLOSE));
    }
  }

  toggleNotePopup = (patientId, enrolledProgramId, isNewPortal) => {
    if (enrolledProgramId && enrolledProgramId.length != 0) {
      //pop up call log
      reduxStore.dispatch(noteActions.noteStatus(false));
      reduxStore.dispatch(callActions.callStatus(true));
    } else if (patientId != undefined) {
      // popup stickie note
      reduxStore.dispatch(callActions.callStatus(false));
      reduxStore.dispatch(noteActions.noteStatus(true));
    } else if (typeof patientId == 'undefined') {
      reduxStore.dispatch(noteActions.setCallStickie(false));
      reduxStore.dispatch(noteActions.noteStatus(false));
      if (!isNewPortal) {
        reduxStore.dispatch(noteActions.setNonExistNotification(true));
      }
    }
  }

  shouldSetInboundAttributes = (newContactId) => {
    let flag = true;
    const curAttributes = JSON.parse(sessionStorage.getItem('inboundAttributes'));
    if (curAttributes) {
      const curContactId = _.get(curAttributes, 'contactId');
      flag = curContactId !== newContactId;
    }
    return flag;
  }
}

if (!window.CCPClass) {
  window.CCPClass = new CCPClass();
}

export default window.CCPClass;