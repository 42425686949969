import React from 'react';
import { Modal, Row, Col } from 'antd';
import CreateMemberFormComponent from '../components/CreateMemberFormComponent';

export default class CreateMemberContainer extends React.Component {
    static displayName = 'newAdmin/Clinic/container/CreateMemberContainer';
    constructor(props) {
        super(props)
        this.state = {
          showCreateModal : false
        }
    }

    setShowCreateModal = (showModal) => {
      this.setState({showCreateModal: showModal})
    }

    render() {
        const { setShowCreateModal, props, state } = this;
        return (
          <div>
            <div className='create-member-header'>
              Can't find the member? <a onClick={() => setShowCreateModal(true)}>Create a new user</a>
            </div>
              <Modal
                  wrapClassName='create-member-modal'
                  visible={state.showCreateModal}
                  style={{minWidth:'755px'}}
                  footer={null}
                  onCancel={() => setShowCreateModal(false)}
                  destroyOnClose
                  >
                  <CreateMemberFormComponent {...props} setShowCreateModal={setShowCreateModal}/>
              </Modal>
            </div>
        )
    }
}
