import React from 'react';
import {renderForm} from "../../patient/AdditionalInfo/helpers";
import {formConfig} from "../constant/formConfig";
import roleMap from "../constant/roleMap";
import { Modal,Button,Row} from 'antd';
import {Form} from "antd/lib/index";
import API from "../API";
import i18nMap from "../../I18N";
import convertor from "@ihealth/convertor/index";
import helpers from '../helper/index';
import {message,IHLoading} from "../../../../../package/IHComponent";
import {userWithoutTeam as userGQL} from "../../graphql/user";
import {graphql,compose} from "react-apollo/index";
import CreateFollowUpComponent from "../../VideoChat/component/CreateFollowUpComponent";
import getLoanDevice from "../../patient/loanDevices/query/getLoanDevice";
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { connect } from 'react-redux';
import { mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../idleTime/constant';

const CLIPBOARD_TIMER = 'CLIPBOARD_TIMER';

let EditPatientFormComponent = class extends React.Component{
    constructor(props){
        super();
        this.state = {
            showCreateFollowUp: false,
            associatedVisit: props.currentVisit.associatedVisit

        }
    }
    handleSaveEdit = ()=>{
        const { props } = this;
        const { form,currentVisit,currentRole,setShowRenderPatientForm,interacted,stopTimer } = props;
        const { validateFields,getFieldsValue } = form;
        const visitId = _.get(currentVisit,'id');
        validateFields((error,res)=>{
            if(!error){
                const formValues = getFieldsValue();
                const variables = helpers.getVariables(formValues,visitId,currentRole);
                interacted(EVENT_TYPES.CLIPBOARD);
                stopTimer('save', () => {
                    API.editVisitMutate(variables)
                    .then(res=>{
                        message.success('Visit Info Updated');
                        setShowRenderPatientForm(null,false,false);
                    })
                    .catch(error=>{
                        message.error(error.message)
                    });
                })
            }
        })
    }

    setAssociatedVisit = (associatedVisit)=> {
        this.setState({
            associatedVisit
        })
    }

    onCancel = (setShowRenderPatientForm)=>{
        setShowRenderPatientForm(null,false);
    }

    renderPatientForm = (currentRole,setShowRenderPatientForm)=>{
        const { props,setCreateFollowUp,renderCreateFollowUp,onCancel } = this;
        const { loading, timerName } = props;
        const patientId = _.get(props, 'currentVisit.member.id');
        return <Modal visible={true} footer={false} onCancel={()=>onCancel(setShowRenderPatientForm)} className='videoPatientForm' maskClosable={false}>
                    {timerName ? null : <TimerClaimedContainer id={CLIPBOARD_TIMER} patientId={patientId} />}
                    { loading ? <IHLoading/>
                        :
                        <div style={{padding: '20px 20px'}}>
                            {this.renderHeader()}
                            {renderForm(this, formConfig(roleMap[currentRole]+'TwoRows', this.props,setCreateFollowUp,this))}
                            {this.renderSaveButton()}
                            { renderCreateFollowUp() }

                        </div>
                    }
               </Modal>;

    }

    renderHeader = ()=>{
        const member = _.get(this,'props.user');
        const { profile:{ fullName } } = member;
        return <div style={{padding: '0px 30px', background: 'white', margin: '-30px', marginBottom: 0}}>
                 <Row style={{marginBottom: 10, marginTop: 30}}><h4 style={{ fontSize: 20 }}>{fullName}</h4></Row>
               </div>
    }

    setCreateFollowUp = (showCreateFollowUp)=>{
        this.setState({
            showCreateFollowUp
        })
    }

    renderCreateFollowUp = ()=>{
        const { setCreateFollowUp,setAssociatedVisit } = this;
        const { currentVisit,setCurrentVisit,user,loanDeviceInfo,interacted } = this.props;
        const { showCreateFollowUp,associatedVisit } = this.state;
        const hasLoanDevice = !!loanDeviceInfo;

        if(showCreateFollowUp) {
            return <div style={{ backgroundColor:'#F7F7F7',padding:20 ,margin:'10px 0'}}>
                    <CreateFollowUpComponent user={user}
                                             associatedVisit={associatedVisit}
                                             currentVisit={currentVisit}
                                             setAssociatedVisit={setAssociatedVisit}
                                             setCreateFollowUp={setCreateFollowUp}
                                             setCurrentVisit={setCurrentVisit}
                                             hasLoanDevice={hasLoanDevice}
                                             onInteraction={interacted}
                    />
                   </div>
        }
        return '';
    }

    renderSaveButton = ()=>{
        return <div style={{ display:'flex',justifyContent:'flex-end',flexDirection:'row' }}>
            <Button type='primary' onClick={()=>this.handleSaveEdit()}>Save</Button>
        </div>
    }

    render(){
        const { props,renderPatientForm } = this;
        const { currentRole,setShowRenderPatientForm } = props;

        return <div >
            { renderPatientForm(currentRole,setShowRenderPatientForm) }

        </div>
    }
}
EditPatientFormComponent = Form.create({})(EditPatientFormComponent);
const loanDeviceInfo = graphql(getLoanDevice,{
    options:(ownProps) =>{
        const userId = _.get(ownProps,'currentVisit.member.id');
        return {
            variables: {
                memberId: userId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props:({data})=>{
        const { loading } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        return {
            loanDeviceInfo: _.get(data, 'getLoanDevice', [])
        }
    }
})
const userData = graphql(userGQL, {
    options: (ownProps) => {
        const id = _.get(ownProps,'currentVisit.member.id');
        return {
            variables: {
                id
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy:'network-only'
        }
    },
    props:({ownProps,data})=>{
        const { loading,user } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            user
        }
    }
});
const mapDispatch = (dispatch, ownProps) => {
    const userId = _.get(ownProps, 'currentVisit.member.id');
    const { timerName = CLIPBOARD_TIMER } = ownProps;
    const { interacted, stopTimer } = mapDispatchToTimerActions(dispatch, timerName, userId);
    return {
      interacted,
      stopTimer,
    }
  };
export default compose(connect(null, mapDispatch), userData,loanDeviceInfo)(EditPatientFormComponent);

