import gql from 'graphql-tag';

export default gql`
query getClinicGoal($codes: [String!]){
  getClinicGoalTemplates(codes: $codes){
    id
    codes
    chronicDisease
    vitals
    condition
    values
    goals
  }
}`