import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Modal,Icon } from 'antd';
import I18N from 'modulesAll/I18N';
import API from '../API/index';
import { compose, withState } from 'recompose';
import moment from 'moment';

const DoctorReferralIcon = props => {
  const { 
    memberId, doctorReferral = false, 
    canShow = true, onClickCallback, iconProps, 
    loading, setLoading,patientRefer, remoteEnrollment
  } = props;

  const toggleDoctorReferral = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const res = await API.editReferByDoctor({
        memberId,
        doctorReferral: !doctorReferral
      })
      if (onClickCallback) onClickCallback(res);
    } catch (err) {
      Modal.error({ content: _.get(err, 'message') });
    } finally {
      setLoading(false);
    }
  }

  const getTooltipTitle = (isReferred) => {
    if(loading) return '';
    if(!doctorReferral) return I18N.get('remoteEnrollment.tooltip.doctorReferral.no');
    const diagnoses = (_.get(patientRefer, 'conditionsToMonnitor') || []).join(',');
    if(isReferred){
      const referredAt = _.get(patientRefer, 'referredAt');
      const createdAt = referredAt ? moment(referredAt).format('MM/DD/YY') : '';
      const referredDoctorFullName = _.get(patientRefer, 'referredBy.profile.fullName');
      const withConditions = diagnoses.length ? `for ${diagnoses} conditions`:''
      return `This patient was referred by ${referredDoctorFullName} on ${createdAt} ${withConditions}`;
    } else {
      const referralDate = _.get(remoteEnrollment, 'doctorReferralDate');
      const diagnosesText = diagnoses ? `has ${diagnoses} conditions` : '';
      const title = `This patient ${diagnosesText} and was referred by the provider or clinic`
      return referralDate ? title + ` on ${moment(referralDate).format('MM/DD/YY')}` : title;
    }
  }
  const referStatus = _.get(patientRefer,'status.referStatus');
  const isReferred = referStatus === 'COMPLETED';
  const tooltipTitle = getTooltipTitle(isReferred);

  return canShow && <Tooltip 
    overlayClassName='patient-doctor-referral-tip'
    title={tooltipTitle}
    arrowPointAtCenter
  >
    <img
      src={ isReferred ? '/image/referred_by_MDOrMA.svg' : doctorReferral ? '/image/referred_by_dc.png' : '/image/not_referred.png'}
      onClick={toggleDoctorReferral}
      style={{
        cursor: loading ? 'wait' : 'pointer'
      }}
      {...iconProps}
    />
  </Tooltip>;
};

DoctorReferralIcon.propTypes = {
  doctorReferral: PropTypes.bool.isRequired,
  memberId: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
  iconProps: PropTypes.object
};

export default compose(
  withState('loading', 'setLoading', false)
)(DoctorReferralIcon);