import React from 'react';


const NotFound = (props) => {
  return (
    <h4 style={{margin: '200px 5%'}}>{`O Hai, this page doesn't exist.`}</h4>
  )
}

NotFound.displayName = 'layout/components/NotFoundComponent';

export default NotFound
