import React, { useMemo } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { createCGMLineChartSeries } from '../helpers/createCGMLineChartSeries';
import { createCGMChartOptions } from '../helpers/createCGMChartOptions';

export const CGMLineChartComponent = ({
  data,
  fromDate,
  toDate,
  max,
}) => {
  const options = useMemo(() => {
    const series = createCGMLineChartSeries(data);
    return createCGMChartOptions({
      series,
      fromDate,
      toDate,
      max,
    });
  }, [
    data,
    fromDate,
    toDate,
    max,
  ]);

  return (
    <HighchartsReact 
      Highcharts={Highcharts}
      options={options}
    />
  );
};
