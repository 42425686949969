import { ConditionsListSchema } from './ConditionsList';

export const RemoteEnrollmentSchema = `
  status
  doctorReferral
  nutritionAssessment {
    value
    updatedBy{
     name 
    } 
  }
  pinnedToEMR{
    value
    updatedBy{
     name 
    } 
  }
  shippingInfo{
    shippingNumber
  }
  techOnBoard {
    value
    updatedBy{
     name 
    } 
  }
  deviceDelivered {
    value
    updatedBy{
     name 
    } 
  }
  carePlanFiled {
    value
    updatedBy{
     name 
    } 
  }
  consentFiled {
    value
    updatedBy{
     name 
    } 
  }
  conditionsList{
        HLD
        HTN
        DM
        CKD
        Obesity
        PreDM
        COPD
        ESRD_Dialysis
  }
  billingCodeAttached {
    value
    updatedBy{
     name 
    } 
  }
  callHistories{
    providerId
    name
    callDate
    isPickedUp

  }
  confirmedBy{
    providerId
    name
  }
  callers{
    name
    providerId
  }
  nextVisit{
    visitId
    appointmentAt
  }
  latestStickyNote{
    note
  }
  stickyNotesCount
`;

export const UserRemoteEnrollmentSchema = `
  status
  doctorReferral 
  doctorReferralDate
  organization {
    id
  }
  patientRefer {
    recommendedVitals
    eligiblePrgorams
    status{
      patientConsentEnroll
      confirmDevicesEnroll 
      scheduleInitVisitEnroll
      referStatus
      enrollStatus
      
    }
    wantMNTReferral
    enrolledBy {
      id
      profile {
        fullName
      }
      allRoles {
        roleType
        organization {
          id
        }
      }
    }
    referredBy{
      id
      profile{
        fullName
      }
    }
    conditionsToMonnitor
  }
  ${ConditionsListSchema}
`;

export const VisitRemoteEnrollmentSchema = `
  ${UserRemoteEnrollmentSchema}
  techOnBoard{
    value
  }
  pinnedToEMR{
    value
  }
`;