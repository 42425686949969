import React from 'react';
import { Table,Button,Icon,Modal,notification,Tooltip } from 'antd';
import API from '../API';
import { decryptRole,goPath } from 'libModule/utils';
import action from '../action/';
import moment from 'moment';
import VideoChatLeftComponent from '../component/VideoChatLeftComponent';
import helper from '../helper';
import visitMap from '../../visit/constants/map';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import EditPatientFormComponent from '../component/EditPatientFormComponent';
import { IHLoading } from 'ihcomponent';
import roleMap from '../constant/roleMap';
import VideoChatCenterComponent from '../component/VideoChatCenterComponent';
import { helpers as visitHelpers } from '../../visit/helpers/index';
import Client from 'libModule/gqlClient';
import reducerActions from '../action/reducerActions';
import {connect} from "react-redux";
import {userWithoutTeam as userGQL} from "../../graphql/user";
import dashboardActions from 'modulesAll/dashboard/main/actions';
import appointmentCategories from '../../visit/constants/appointmentCategory';
import statusTimeFormat from '../../visit/constants/statusTimeFormat';
import nameFilterHelper from '../../visit/helpers/nameFilterHelper';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import I18N from '../../I18N';
import { getProviderName } from '../../../lib/utils';
import { VIDEO_STR, VITALS_ORDER, MA_VISIT_TYPE_ENUM, MD_VISIT_TYPE_ENUM } from '../../../lib/constants';
import tableColumns from "../../visitsPage/constants/tableColumns";

const potentialLableList = ['HTN', 'DM', 'PreDM', 'Obesity'];

const indicatorStyle = {
    fontSize: '12px',
    textAlign: 'left'
};

const VISIT_ORDER = {
    'MA':0,
    'MD':1,
    'RD':2,
    'CA':3,
}

const filterVisits = {
  'MA': MA_VISIT_TYPE_ENUM,
  'Doctor': MD_VISIT_TYPE_ENUM
}

const VisitsWithVideoComponent = class extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            memberId: null,
            showRenderPatientForm:false,
            showReschedule:false,
            currentVisit: null,
            showVideoLoadingSpin: false,
            showModal:false,
            nameFilterState: {},
        }
        this.nameFilterHelper = nameFilterHelper(this);
    }


    setVisit = (memberId)=>{
        this.setState({
            memberId
        })
    }

    setModal = (showModal,fn,params)=>{
        this.setState({
            showModal
        },()=>{
            if(fn&&params){
                fn(params)
            }
        })
    }

    setCurrentVisit = (currentVisit)=>{
        this.setState({
            currentVisit
        })
    }

    setShowRenderPatientForm = (currentVisit,showRenderPatientForm,resetMemberId)=>{
        this.setState({
            showRenderPatientForm,
            currentVisit,
            memberId:resetMemberId
        },()=>{
            if(!showRenderPatientForm) {
                this.props.refetchVisitList()
            }
        })
    }

    openNotification = async (params)=>{

        const { state,getUserData,setModal,props } = this;
        const { setVirtualVisit } = props;
        const { visit,currentRole,refetchVisitList,memberId } = params;
        const user = await getUserData(memberId);
        if(visit.id){
            notification.close(visit.id);
        }
        notification.open({
            message: 'Video chat Left Div View',
            description:<VideoChatCenterComponent user={user} setModal={setModal} currentVisit={visit} currentRole={currentRole} refetchVisitList={refetchVisitList} setVirtualVisit={setVirtualVisit}/>,
            duration: 0,
            placement:'topLeft',
            key:'videoChatCenterNotification',
            className:'videoChatCenterNotification',
            style:{ zIndex:500 }
        })
    }
    renderButtonBaseOnStatus = (visitId,isVirtual,checkinAt,checkoutAt,isFinished,memberId,visit,status)=>{
        const { joinable } = visit;
        const checkedInAt = checkinAt ? moment(checkinAt).format(statusTimeFormat):'';
        const checkedOutAt = checkoutAt ? moment(checkoutAt).format(statusTimeFormat):''
        if(!isVirtual){
            if(!checkinAt) return <Button onClick={()=>helper.onClickCheckIn({ visitId })} className='checkinButton'>Check In</Button>;
            else if(!checkoutAt) return <Button onClick={() => helper.onClickCheckOut({ visitId })}  className='checkoutButton'>Check Out</Button>
        }

        //virtual visit
        if(isVirtual){
            if(!isFinished) {
                return <Button onClick={() => this.handleClickButton(memberId, visit, status)} disabled={!joinable}
                               type='primary' className={`${joinable&&'joinVideoButton'}`}>{VIDEO_STR['OPEN']}
                       </Button>;
            }
            else if(!checkoutAt) return <Button onClick={() =>helper.onClickCheckOut({ visitId })}  className='checkoutButton'>Check Out</Button>
        }

        if(checkinAt&&checkoutAt){
            return <span>{`${checkedInAt} - ${checkedOutAt}`}</span>
        }
    }

    parseStatus = (status,step,checkedOut,needAction,waitingTime)=>{
        if(checkedOut){
            return <span style={{ color: 'black' }}>{`Checked Out`}</span>;
        }
        const statusMap = {
            'WITH':'With',
            'WAITING':'Waiting',
            'CALLING_IN':'Patient calling in',
            'END':'Meeting Finished',
            'NO_SHOW':'No Show',
            'NOT_SHOWING':'Not Showing'
        }
        if(status==='CALLING_IN'){
            return <span style={{ color: 'red' }}>{`Patient waiting for ${(waitingTime/60000).toFixed(0)} mins`}</span>
        }
        if(status=='INIT'){
            return '--';
        }
        if(status=='END'){
            return <span style={{ color: 'black' }}>{statusMap[status]}</span>;
            // return statusMap[status];
        }
        if(step){
            const currentUserId = sessionStorage.getItem('currentUserId')
            const stepUserId = step.id;
            const role  =roleMap[_.get(step,'role')];
            const isRedFont = currentUserId==stepUserId||status=='CALLING_IN'||needAction;
            return <span style={{ color: isRedFont? 'red' :' black' }}>{`${statusMap[status]} ${ step.name|| role}  ${waitingTime ? ((waitingTime/60000).toFixed(0) +'mins') :''}`}</span>
        }
        return statusMap[status];
    }

    getColumns = (currentRole)=>{
        const { hideStatus, curTab, selectedFilters, selectedSorters } = this.props;
        const types = _.map(filterVisits[roleMap[currentRole]], (index, data) => {
            return {
                text: index,
                value: data
            };
        });
        const doctors = _.map(JSON.parse(sessionStorage.getItem('doctorName')),
            data => {
                return {
                    text: getProviderName(data),
                    value: data.id
                };
            }
        );

        const getFilteredValueFor = key => _.get(selectedFilters, `${curTab}.${key}`, null);

        let columns = [
            {
                key:'index',
                title:'No.',
                width: '5%',
                dataIndex:'index',
                render:(d,doc)=><div>
                                    <span style={{ marginRight:5 }}>{d}</span>
                                    {/*{doc.category =='VIRTUAL' ? <img src='/image/camera.png' width={20}/>:''}*/}
                                </div>
            },
            {
                key:'appointmentAt',
                title:'Time',
                width: curTab === 'Today' || curTab === 'Tomorrow' ? '8%' : '12%',
                dataIndex:'appointmentAt',
                sorter:(a,b)=>a.appointmentAt-b.appointmentAt,
                sortOrder: _.get(selectedSorters, `${curTab}.isSelected`) ? _.get(selectedSorters, `${curTab}.appointmentAt`, false): 'ascend', // if !isSelected, use default sort order,
                filters: appointmentCategories,
                filteredValue: getFilteredValueFor('appointmentAt'),
                onFilter: (value, record) => record.category === value,
                render:(d,doc)=> {
                  const formatStr = curTab != 'All' ? statusTimeFormat : `MM/DD/YYYY ${statusTimeFormat}`;
                  return  <div>
                          { moment(d).format(formatStr)} 
                          {
                            visitHelpers.patientIndicator(doc.member, doc.category)
                                        .render({ display: 'block' }, { marginTop: -5 })
                          }
                         </div>
                }
            },
            {
                key:'type',
                title:'Type',
                width:'7%',
                dataIndex:'type',
                filteredValue: getFilteredValueFor('type'),
                filters:types,
                onFilter: (value, record) => record.type.indexOf(value) === 0,
                render:(d)=>visitMap[d]
            },
            {
                key:'fullName',
                title: _.result(this, 'nameFilterHelper.getNameTitle', 'Name'),
                width:curTab === 'All' ? '10%':'8%',
                dataIndex:'fullName',
                // render:(d,visit)=>_.get(visit,'member.profile.fullName'),
                ..._.result(this, 'nameFilterHelper.getNameFilterProps', {}),
                render:(a,b)=>{
                    let indicator = '';
                    _.forIn(b.conditionsList, function(value, key) {
                        if(b.conditionsList.hasOwnProperty(key))
                        {
                            if (potentialLableList.includes(key) && value === true) {
                            indicator += 'Potential CCM/RPM';
                            return false;
                            }
                        }
                    });
                    const fullName = _.get(b,'member.profile.fullName');
                    const id = (b.member && b.member.id) || null;
                    return id ? (
                        <div>
                            <p
                            onClick={() => {
                                goPath(`/patients/${id}/enrolledprogram/default`);
                            }}
                        >
                            <a
                                href='JavaScript:void(0);'
                                style={{ fontWeight: 'bold', color: '#000000' }}
                            >
                                { typeof _.get(this, 'nameFilterHelper.getHighlightedForFilter') === 'function' ?
                                  this.nameFilterHelper.getHighlightedForFilter(fullName)
                                  : fullName
                                }
                            </a>
                            </p>
                            <p style={indicatorStyle}>{indicator}</p>
                        </div>

                    ) : (
                        <a href={'/'}>invalid data</a>
                    );
                }
            },
            {
               key:'provider',
               title: I18N.get('provider.label'),
               width:curTab === 'All' ? '10%':'8%',
               dataIndex:'doctorName',
               filteredValue: getFilteredValueFor('provider'),
               filters: doctors,
               onFilter: (value, record) => {
                    const indexArr = _.map(record.providers, provider => provider.id);
                    return indexArr.includes(value);
                },
                render: (value, record) => {
                    return _.map(record.providers, provider => {
                        return getProviderName(provider);
                    });
                }

            },
            {
                ...tableColumns.enrollStatusVitals(this.props)
            },
            // {
            //     key:'birthday',
            //     title:'Age',
            //     width:curTab === 'All' ? '8%':'7%',
            //     dataIndex:'birthday',
            //     sorter: (a, b) => helper.getAge(a.member.profile.birthday) - helper.getAge(b.member.profile.birthday),
            //     sortOrder: _.get(selectedSorters, `${curTab}.isSelected`) ? _.get(selectedSorters, `${curTab}.birthday`, false): false, // if !isSelected, use default sort
            //     render:(a,visit)=>{
            //        const age =  _.get(visit,'member.profile.birthday');
            //        return helper.getAge(age);
            //     }
            // },
            // {
            //     key:'gender',
            //     title:'G',
            //     width:curTab === 'All' ? '8%':'6%',
            //     dataIndex:'gender',
            //     filteredValue: getFilteredValueFor('gender'),
            //     filters: [{ value: 'M', text: 'Male' },
            //              { value: 'F', text: 'Female' }],
            //     onFilter: (value, record) => {
            //         return record.member.profile.gender.includes(value);
            //     },
            //     render:(g,visit)=>  _.get(visit,'member.profile.gender')
            // },
            {
                title: 'Participant',
                key: 'visit',
                width:curTab === 'Today' ? '10%' : '12%',
                dataIndex: 'visitRoles',
                filters: visitHelpers.PARTICIPANT_ENUM,
                filteredValue: getFilteredValueFor('visit'),
                onFilter: (value, record) => {
                  let roleList = record.visitRoles.sort();
                  let roleStr = '';
                  for (let i = 0; i < roleList.length; i++) {
                      roleStr =
                          i == roleList.length - 1
                              ? roleStr + roleList[i]
                              : roleStr + roleList[i] + ',';
                  }
                  // return _.isEqual(roleStr, value)
                  return _.includes(roleList, value) || _.isEqual(roleStr, value);
                },
                render: (value, record) => {
                    value.sort((v1, v2) => (VISIT_ORDER[v1] - VISIT_ORDER[v2]));
                    return _.get(value,'0') ? value.join(',') : '-' ;
                }
            },
            {
                title: 'Vitals',
                width: '7%',
                key: 'vitals',
                dataIndex: 'vitalTypes',
                filteredValue: getFilteredValueFor('vitals'),
                filters:
                [
                  { value: 'BP', text: 'BP' },
                  { value: 'BG', text: 'BG' },
                  { value: 'HS', text: 'HS' },
                  { value: 'PO', text: 'PO'},
                  { value: 'TM', text: 'TM'}
                ],
                filterMultiple: true,
                onFilter: (value, record) => record.vitalTypes.includes(value),
                render: value => {
                  if (Array.isArray(value) && value.length > 0) {
                    const vitalsInOrder = _.intersection(VITALS_ORDER, value);
                    return vitalsInOrder.join('/');
                  } else {
                    return value == null || value.length == 0 ? '-' : value;
                  }
                }
              },
            {
                key:'notes',
                title:'Notes',
                width: '10%',
                dataIndex:'reason'
            },
            {
                // key:'status',
                key:'progress',
                //sc-3021 Word "Status" is confusing
                title:'Start in',
                dataIndex:'status',
                render:(s,doc)=>this.parseStatus(s,doc.step,doc.checkoutAt,doc.needAction,doc.waitingTime,curTab)
            },
            {
                key: 'action',
                title:'Status',
                dataIndex: '',
                render: (dummy,doc) => {
                    const { status,checkinAt,checkoutAt,category } = doc;
                    const isVirtual = (category =="VIRTUAL");
                    const isFinished = status=='END';
                    const memberId = _.get(doc,'member.id');
                    return this.renderButtonBaseOnStatus(doc.id,isVirtual,checkinAt,checkoutAt,isFinished,memberId,doc,status,curTab);
                }
            },
            {
                key:'editPatientInfo',
                render:(e,doc)=><a onClick={()=>this.setShowRenderPatientForm(doc,true)}><img
                    src='/image/init_file.png'
                    style={{ width: '22px', height: '25px' }}
                /></a>
            },
            {
                key:'editVisit',
                render:(e,doc)=> {
                   const { checkinAt,status } = doc;
                   // const shouldBeDisabled =
                   return <a onClick={() => this.setState({showReschedule: doc})}><img
                        src='/image/edit.png'
                        style={{width: '22px', height: '25px'}}
                    /></a>
                }
            }

        ]

        if(hideStatus){
            columns = _.filter(columns,col=>col.key!='status'&&col.key!='action')
        }

        let resultColumns = curTab === 'Today' ? columns : curTab === 'Tomorrow' ? columns.slice(0, 10).concat(columns.slice(12)) : columns.slice(1, 10).concat(columns.slice(12));
        return resultColumns;
    }

    getUserData = async (id)=>{
        const res = await Client.query({
            query:userGQL,
            variables:{
                id
            },
            fetchPolicy:'network-only'
        });

        return _.get(res,'data.user');
    }

    handleClickButton = async (memberId,visit,status)=>{
        const { props,setModal,state,openNotification } = this;
        const { refetchVisitList,setVirtualVisit } = props;
        const visitId  = _.get(visit,'id');
        const currentUserRole = decryptRole();
        const currentRole = currentUserRole.split(':')[1];
        //set for testing only
        //for testing
        if(status==='CALLING_IN') {
            await  API.activePatientMutate({
                eventType: "ACCEPT",
                visitId,
            })
        }
        try {
            const token = await API.getVideoToken({visitId});
            const { data } = token;
            const { getVirtualVisitToken } = data;
            const { password,meetingNumber,apikey,signature,joinUrl, agoraToken, agoraChannelName,agoraAppId,agoraUid } = getVirtualVisitToken;
            this.meetingNumber = meetingNumber;
            //remove handleJoinMeeting with Zoom sdk
            // this.handleJoinMeeting(meetingNumber,password,setVisit,memberId,apikey,signature,visitId,VideoChatLeftComponent);
            //remove handleJoinMeeting with Zoom Sdk
            API.activePatientMutate({
                eventType: 'JOIN',
                visitId
            }).then(res=>{
                if(agoraToken&&agoraChannelName){
                    let openUrl = `/AgoraVideo/provider/${encodeURIComponent(agoraAppId)}/${encodeURIComponent(agoraChannelName)}/${encodeURIComponent(agoraToken)}/${encodeURIComponent(agoraUid)}/${encodeURIComponent(visitId)}`;
                    Mixpanel.track('opened', 'agora', '', {PROVIDER_ROLE: currentRole});
                    window.pop = window.open(openUrl);
                }else{
                    Mixpanel.track('opened', 'zoom', '', {PROVIDER_ROLE: currentRole});
                    window.open(joinUrl);
                }
            })

            if(!visit.checkinAt) {
                helper.onClickCheckIn({ visitId });
            }
            setModal(true,openNotification,{memberId,visit,currentRole,refetchVisitList});
            setVirtualVisit(visitId)

        }catch (e){
            console.log(e)
        }
        //set visit after all process step is done
        // setCurrentVisit(visit);
        setModal(true,openNotification,{memberId,visit,currentRole,refetchVisitList});
    }

    setReschedule=(visit)=>{
        const { showReschedule } = this.state;
        if (showReschedule) {
            this.setState({
                showReschedule: false
            });
        } else {
            this.setState({
                showReschedule:visit
            });
        }
    }

    setShowVideoLoadingSpin = (showVideoLoadingSpin)=>{
        this.setState({
            showVideoLoadingSpin
        })
    }

    renderShowVideoLoadingSpin = (showVideoLoadingSpin)=>{
        const antIcon = <Icon type="loading" style={{ fontSize: 24,marginBottom:20 }} spin />;
        return showVideoLoadingSpin&&<Modal visible={true} footer={false} closable={false}>
                <div style={{ height:'20vh',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    { antIcon }
                    <span>Initializing the meeting...</span>
                </div>
               </Modal>
    }

    handleJoinMeeting=(meetingNumber,password,setVisit,visit,apikey,signature,visitId)=>{
        const { currentVisit } = this.state;
        const { refetchVisitList } = this.props;
        const zoomMeeting = document.getElementById('zmmtg-root');
        action.joinMeeting(meetingNumber,password,zoomMeeting,setVisit,visit,apikey,signature,visitId,VideoChatLeftComponent,currentVisit,refetchVisitList,this);
    }

    parseVisitList = (visitList,clientStatus)=>{
        const groupedStatus = _.groupBy(clientStatus,c=>c.visitId);
        let obj = {};
        for (let [i, k] of Object.entries(groupedStatus)) {
            obj[i] = groupedStatus[i][0];
        }
        for(let [i,k] of Object.entries(obj)){
            _.forEach(visitList,v=>{
                if(v.id==i){
                    Object.assign(v,{
                        ...k
                    })
                }
            })
        }
    }


    render(){
        const { setShowRenderPatientForm,setReschedule,setCurrentVisit,} = this;
        const { currentVisit,showRenderPatientForm,showReschedule } = this.state;
        let { visitList=[],clientStatus=[],refetchVisitList,loading, selectedFilters, setSelectedFilters, selectedSorters, setSelectedSorters, curTab } = this.props;
        const currentUserRole = decryptRole();
        const currentRole = currentUserRole.split(':')[1];
        const columns = this.getColumns(currentRole);
        this.parseVisitList(visitList,clientStatus);
        if(loading){
            return <IHLoading/>
        }
        const tableOnChange = (pagination, filters, sorter) => {
          setSelectedFilters({
            ...selectedFilters,
            [curTab]: { ..._.get(selectedFilters, curTab, {}), ..._.omit(filters, 'name') }
          });
            // get selected sorter key and order
            const sorterKey = _.get(sorter, 'columnKey');
            const sorterOrder = _.get(sorter, 'order');
            // antd sorting only works for one key at a time, if there is no key => no sorting selection at the moment
            const updatedSorter = sorterKey ? {
              [sorterKey]: sorterOrder
            } : {};
            // once a sorter is selected, isSelected is always true. That means sorters won't use default value
            _.set(updatedSorter, 'isSelected', true);
            setSelectedSorters({ ...selectedSorters, [curTab]: updatedSorter});
        }
        return <div>
                { showReschedule ? (<EditVisitInDashboardContainer refetch={ refetchVisitList } visitInfo={ showReschedule } onCancel={ setReschedule }/>) : ('') }
                { showRenderPatientForm ? <EditPatientFormComponent refetchVisitList={refetchVisitList} setCurrentVisit={setCurrentVisit} currentVisit={currentVisit} user={currentVisit.member} currentRole={currentRole} setShowRenderPatientForm={setShowRenderPatientForm}/> :'' }
                {/*{ renderShowVideoLoadingSpin(showVideoLoadingSpin) }*/}
                {/*{ currentVisit &&<VideoChatCenterComponent currentVisit={currentVisit}*/}
                                                           {/*currentRole={currentRole}*/}
                                                           {/*setCurrentVisit={setCurrentVisit}*/}
                                                           {/*refetchVisitList={refetchVisitList}*/}
                                                           {/*setModal={setModal}*/}
                                                           {/*showModal={showModal}*/}

                                 {/*/>*/}
                {/*}*/}
                <Table columns={columns} dataSource={visitList} pagination={false} className='MAVisitList' onChange={tableOnChange} rowKey={(_, i) => i}/>
               </div>
    }
}


const mapToState = state => ({
    selectedFilters: state.dashboard.main.selectedFilters,
    selectedSorters: state.dashboard.main.selectedSorters
})


const mapToDispatch = (dispatch)=>{
    return {
        setVirtualVisit: (visitId) => dispatch(reducerActions.setVirtualVisit(visitId)),
        setSelectedFilters: filters => dispatch(dashboardActions.setSelectedFilters(filters)),
        setSelectedSorters: sorters => dispatch(dashboardActions.setSelectedSorters(sorters)),
      }
}

export default connect(mapToState, mapToDispatch)(VisitsWithVideoComponent);
