export const taskMap = {
  TASK_HIGH_URGENCY: {
    title: 'Urgent Task',
    src: '/image/workstation/urgent-task.png',
  },
  TASK_MEDIUM_URGENCY: {
    title: 'Medium Priority Task',
    src: '/image/workstation/medium-priority-task.png',
  },
  TASK_LOW_URGENCY: {
    title: 'Low Priority Task',
    src: '/image/workstation/low-priority-task.png',
  },
  TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_TODAY: {
    title: 'Urgent Task',
    src: '/image/workstation/urgent-task.png',
  },
  TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_TODAY: {
    title: 'Medium Priority Task',
    src: '/image/workstation/medium-priority-task.png',
  },
  TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_TODAY: {
    title: 'Low Priority Task',
    src: '/image/workstation/low-priority-task.png',
  },
  TASK_HIGH_URGENCY_ASSIGNED_TO_ME_OVERDUE: {
    title: 'Urgent Task',
    src: '/image/workstation/urgent-task.png',
  },
  TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_OVERDUE: {
    title: 'Medium Priority Task',
    src: '/image/workstation/medium-priority-task.png',
  },
  TASK_LOW_URGENCY_ASSIGNED_TO_ME_OVERDUE: {
    title: 'Low Priority Task',
    src: '/image/workstation/low-priority-task.png',
  },
  TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET: {
    title: 'Urgent Task',
    src: '/image/workstation/urgent-task.png',
  },
  TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET: {
    title: 'Medium Priority Task',
    src: '/image/workstation/medium-priority-task.png',
  },
  TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET: {
    title: 'Low Priority Task',
    src: '/image/workstation/low-priority-task.png',
  },
  APPOINTMENT_MA: {
    title: 'New Appointments from MA',
    src: '/image/workstation/new-appt-from-ma.png',
  },
  ALERT_COMPLIANCE_LEVEL_1: {
    title: 'Level 1 Compliance Alerts',
    src: '/image/workstation/level-1-2-compliance-alert.png',
  },
  ALERT_COMPLIANCE_LEVEL_2: {
    title: 'Level 2 Compliance Alerts',
    src: '/image/workstation/level-1-2-compliance-alert.png',
  },
  ALERT_COMPLIANCE_LEVEL_3: {
    title: 'Level 3 Compliance Alerts',
    src: '/image/workstation/level-3-compliance-alert.png',
  },
  ALERT_COMPLIANCE_LEVEL_4: {
    title: 'Level 4 Compliance Alerts',
    src: '/image/workstation/level-3-compliance-alert.png',
  },
  ALERT_CLINICAL_LEVEL_1: {
    title: 'Level 1 Clinical Alerts',
    src: '/image/workstation/level-1-clinical-alert.png',
  },
  ALERT_CLINICAL_LEVEL_2: {
    title: 'Level 2 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  ALERT_CLINICAL_LEVEL_3: {
    title: 'Level 3 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  ALERT_CLINICAL_LEVEL_4: {
    title: 'Level 4 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  ALERT_PROVIDER_LEVEL_1: {
    title: 'Level 1 Provider Alerts',
    src: '/image/workstation/level-1-provider-alert.png',
  },
  ALERT_PROVIDER_LEVEL_2: {
    title: 'Level 2 Provider Alerts',
    src: '/image/workstation/level-2-provider-alert.png',
  },
  ALERT_PROVIDER_LEVEL_3: {
    title: 'Level 3 Provider Alerts',
    src: '/image/workstation/level-3-provider-alert.png',
  },
  ALERT_PROVIDER_LEVEL_4: {
    title: 'Level 4 Provider Alerts',
    src: '/image/workstation/level-4-provider-alert.png',
  },
  FOOD_LOG: {
    title: 'Food Diaries',
    src: '/image/workstation/food-log.png',
  },
  MESSAGE_UNREAD: {
    title: 'Patients Sent Messages',
    src: '/image/workstation/patient-sent-msg.png',
  },
  MESSAGE_MENTION: {
    title: 'Message Mentions',
    src: '/image/workstation/message@me.png',
  },
};

const imageTitleMap = {
  urgentTask: {
    title: 'Urgent Tasks',
    src: '/image/workstation/urgent-task.png',
  },
  mediumPriorityTask: {
    title: 'Medium Priority Tasks',
    src: '/image/workstation/medium-priority-task.png',
  },
  lowPriorityTask: {
    title: 'Low Priority Tasks',
    src: '/image/workstation/low-priority-task.png',
  },
  maAppointment: {
    title: 'New Appointments from MA',
    src: '/image/workstation/new-appt-from-ma.png',
  },
  caAnyLevelComplianceAlert :{
    title: 'Compliance Alerts',
    src: '/image/workstation/level-1-2-compliance-alert.png',
  },
  anyLevelComplianceAlert: {
    title: 'Compliance Alerts',
    src: '/image/workstation/complianceAlert.png',
  },
  levelThreeComplianceAlert: {
    title: 'Level 3 Compliance Alerts',
    src: '/image/workstation/level-3-compliance-alert.png',
  },
  levelOneClinicalAlert: {
    title: 'Level 1 Clinical Alerts',
    src: '/image/workstation/level-1-clinical-alert.png',
  },
  levelTwoThreeClinicalAlert: {
    title: 'Level 2-3 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  levelTwoThreeFourClinicalAlert: {
    title: 'Level 2-4 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  levelTwoClinicalAlert: {
    title: 'Level 2 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  levelThreeClinicalAlert: {
    title: 'Level 3 Clinical Alerts',
    src: '/image/workstation/level-2-3-clinical-alert.png',
  },
  anyLevelProviderAlert: {
    title: 'Provider Alerts',
    src: '/image/workstation/level-1-provider-alert.png',
  },
  levelOneProviderAlert: {
    title: 'Level 1 Provider Alerts',
    src: '/image/workstation/level-1-provider-alert.png',
  },
  levelTwoProviderAlert: {
    title: 'Level 2 Provider Alerts',
    src: '/image/workstation/level-2-provider-alert.png',
  },
  levelThreeProviderAlert: {
    title: 'Level 3 Provider Alerts',
    src: '/image/workstation/level-3-provider-alert.png',
  },
  levelFourProviderAlert: {
    title: 'Level 4 Provider Alerts',
    src: '/image/workstation/level-4-provider-alert.png',
  },
  foodLog: {
    title: 'Food Diaries',
    src: '/image/workstation/food-log.png',
  },
  messageUnread: {
    title: 'Patients Sent Messages',
    src: '/image/workstation/patient-sent-msg.png',
  },
  messageMention: {
    title: 'Message Mentions',
    src: '/image/workstation/message@me.png',
  },
  mre: {
    title: 'Monthly Review & Engagement',
    src: '/image/workstation/monthly-review-icon.svg',
  },
  mtpr: {
    title: 'Monthly Review Treatment Plan',
    src: '/image/workstation/monthly-review-icon.svg',
  },
  missingInfo:{
    title:'Missing Information',
    src:'/image/workstation/missingInfo.png'
  }
};

export const roleTasksMap = {
  RD: [
    {
      ...imageTitleMap.levelOneClinicalAlert,
      tasks: ['ALERT_CLINICAL_LEVEL_1'],
    },
    {
      ...imageTitleMap.anyLevelProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_1','ALERT_PROVIDER_LEVEL_2','ALERT_PROVIDER_LEVEL_3', 'ALERT_PROVIDER_LEVEL_4']
    },
    {
      ...imageTitleMap.urgentTask,
      tasks: ['TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_HIGH_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.messageUnread,
      tasks: ['MESSAGE_UNREAD'],
    },
    {
      ...imageTitleMap.foodLog,
      tasks: ['FOOD_LOG'],
    },
    {
      ...imageTitleMap.levelTwoThreeFourClinicalAlert,
      tasks: ['ALERT_CLINICAL_LEVEL_2', 'ALERT_CLINICAL_LEVEL_3','ALERT_CLINICAL_LEVEL_4'],
    },
    {
      ...imageTitleMap.mediumPriorityTask,
      tasks: ['TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.lowPriorityTask,
      tasks: ['TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_LOW_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.anyLevelComplianceAlert,
      tasks: ['ALERT_COMPLIANCE_LEVEL_1', 'ALERT_COMPLIANCE_LEVEL_2', 'ALERT_COMPLIANCE_LEVEL_3','ALERT_COMPLIANCE_LEVEL_4'],
    },
    {
      ...imageTitleMap.mre,
      tasks: ['MONTHLY_REVIEW_MRE']
    },
    {
      ...imageTitleMap.mre,
      title: imageTitleMap.mre.title + ' Additional',
      tasks: ['MONTHLY_REVIEW_MRE_ADDITIONAL']
    },
    {
      ...imageTitleMap.mtpr,
      tasks: ['MONTHLY_REVIEW_MTPR', 'MONTHLY_REVIEW_MTPR_ADDITIONAL']
    },
  ],
  HC: [
    {
      ...imageTitleMap.levelOneClinicalAlert,
      tasks: ['ALERT_CLINICAL_LEVEL_1'],
    },
    {
      ...imageTitleMap.anyLevelProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_1','ALERT_PROVIDER_LEVEL_2','ALERT_PROVIDER_LEVEL_3', 'ALERT_PROVIDER_LEVEL_4']
    },
    {
      ...imageTitleMap.urgentTask,
      tasks: ['TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_TODAY','TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_HIGH_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.messageUnread,
      tasks: ['MESSAGE_UNREAD'],
    },
    {
      ...imageTitleMap.foodLog,
      tasks: ['FOOD_LOG'],
    },
    {
      ...imageTitleMap.levelTwoThreeFourClinicalAlert,
      tasks: ['ALERT_CLINICAL_LEVEL_2', 'ALERT_CLINICAL_LEVEL_3','ALERT_CLINICAL_LEVEL_4'],
    },
    {
      ...imageTitleMap.mediumPriorityTask,
      tasks: ['TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_TODAY','TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.lowPriorityTask,
      tasks: ['TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_TODAY','TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_NOT_YET', 'TASK_LOW_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.anyLevelComplianceAlert,
      tasks: ['ALERT_COMPLIANCE_LEVEL_1', 'ALERT_COMPLIANCE_LEVEL_2', 'ALERT_COMPLIANCE_LEVEL_3','ALERT_COMPLIANCE_LEVEL_4'],
    },
    {
      ...imageTitleMap.mre,
      tasks: ['MONTHLY_REVIEW_MRE']
    },
    {
      ...imageTitleMap.mre,
      title: imageTitleMap.mre.title + ' Additional',
      tasks: ['MONTHLY_REVIEW_MRE_ADDITIONAL']
    },
    {
      ...imageTitleMap.mtpr,
      tasks: ['MONTHLY_REVIEW_MTPR', 'MONTHLY_REVIEW_MTPR_ADDITIONAL']
    },
  ],
  MD: [
    {
      ...imageTitleMap.levelOneProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_1'],
    },
    {
      ...imageTitleMap.urgentTask,
      tasks: ['TASK_HIGH_URGENCY_ASSIGNED_TO_ME_RECENT_PAST', 'TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_TODAY'],
    },
    {
      ...imageTitleMap.levelTwoProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_2'],
    },
    {
      ...imageTitleMap.mediumPriorityTask,
      tasks: ['TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_RECENT_PAST', 'TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_TODAY'],
    },
    {
      ...imageTitleMap.messageUnread,
      tasks: ['MESSAGE_UNREAD'],
    },
    {
      ...imageTitleMap.levelThreeProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_3'],
    },
    {
      ...imageTitleMap.levelFourProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_4'],
    },
    {
      ...imageTitleMap.lowPriorityTask,
      tasks: ['TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_LOW_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
  ],
  CA: [
    {
      ...imageTitleMap.urgentTask,
      tasks: ['TASK_HIGH_URGENCY_ASSIGNED_TO_ME_OVERDUE', 'TASK_HIGH_URGENCY_ASSIGNED_TO_ME_DUE_TODAY'],
    },
    {
      ...imageTitleMap.maAppointment,
      tasks: ['APPOINTMENT_MA'],
    },
    {
      ...imageTitleMap.caAnyLevelComplianceAlert,
      tasks: ['ALERT_COMPLIANCE_LEVEL_1', 'ALERT_COMPLIANCE_LEVEL_2', 'ALERT_COMPLIANCE_LEVEL_3', 'ALERT_COMPLIANCE_LEVEL_4'],
    },
    {
      ...imageTitleMap.mediumPriorityTask,
      tasks: ['TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_MEDIUM_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.messageUnread,
      tasks: ['MESSAGE_UNREAD'],
    },
    {
      ...imageTitleMap.lowPriorityTask,
      tasks: ['TASK_LOW_URGENCY_ASSIGNED_TO_ME_DUE_TODAY', 'TASK_LOW_URGENCY_ASSIGNED_TO_ME_OVERDUE'],
    },
    {
      ...imageTitleMap.missingInfo,
      tasks: ['MISSING_INFO']
    },
    {
      ...imageTitleMap.mre,
      tasks: ['MONTHLY_REVIEW_MRE']
    },
    {
      ...imageTitleMap.mre,
      title: imageTitleMap.mre.title + ' Additional',
      tasks: ['MONTHLY_REVIEW_MRE_ADDITIONAL']
    },
  ],
  MA: [
    {
      ...imageTitleMap.urgentTask,
      tasks: ['TASK_HIGH_URGENCY_ASSIGNED_TO_ME'],
    },
    {
      ...imageTitleMap.messageUnread,
      tasks: ['MESSAGE_UNREAD'],
    },
    {
      ...imageTitleMap.anyLevelProviderAlert,
      tasks: ['ALERT_PROVIDER_LEVEL_2', 'ALERT_PROVIDER_LEVEL_3', 'ALERT_PROVIDER_LEVEL_4'], // level 1 is missing
    },
    {
      ...imageTitleMap.mediumPriorityTask,
      tasks: ['TASK_MEDIUM_URGENCY'],
    },
    {
      ...imageTitleMap.lowPriorityTask,
      tasks: ['TASK_LOW_URGENCY'],
    },
  ]
};

const tableConfig = {

}

export const tabMap = {
  Alert: {
    tabKey: 'Alert',
    profileTabKey: 'vitals',
  },
  Task: {
    tabKey: 'Task',
    profileTabKey: 'providerNotes',
  },
  FoodLog: {
    tabKey: 'FoodLog',
    profileTabKey: 'foodlog'
  },
  MonthlyReview: {
    tabKey: 'MonthlyReview',
    profileTabKey: 'vitals'
  },
  MissingInfo: {
    tabKey:'MissingInfo',
    profileTabKey: 'programDetails'
  }
};

export const monthlyReviewStatusMap = {
  "START": "INIT",
  "IN_PROGRESS": "IN_PROGRESS", // when start MTPR manually
  "COMPLETED": "DONE",
  "DRAFT": "DRAFT"
}

export const MONTHLY_REVIEW_TYPES = {
  "MRE": "MRE",
  "MRE_ADDITIONAL": "MRE_ADDITIONAL",
  "MTPR" : "MTPR",
  "MTPR_ADDITIONAL": "MTPR_ADDITIONAL"
}

export const MONTHLY_REVIEW_TITLE = {
  "MRE": "Monthly Review & Engagement",
  "MRE_ADDITIONAL": "Monthly Review",
  "MTPR" : "Monthly Treatment Plan Review",
  "MTPR_ADDITIONAL": "Monthly Treatment Plan Review"
}

export const RECOMMENDED_ACTIONS_MAP = {
  REGULAR_1: 'The patient is meeting all goals set for existing vitals. However, this patient has at least 1 vital that ' +
    'doesn’t have a goal added. Please review the goals and add as needed. Under all circumstances, you should ' +
    'also review the EHR for any medication, lab, visit, or appointment updates, and discuss recommended clinical ' +
    'goals for these vitals with the patient, as well as review vitals monitoring trends and goals and behavioral goals if applicable.',
  REGULAR_2: 'This patient appears to be meeting their goals! Double check that they are monitoring often and that they have the ' +
    'appropriate goals set. Review the vitals and goal status icons, if more specific guidance is needed.',
  REGULAR_3: 'The patient is meeting some goals set for existing vitals. However, this patient has at least 1 vital that ' +
    'doesn’t have a goal added. Please review the goals and add as needed. Under all circumstances, you should ' +
    'also review the EHR for any medication, lab, visit, or appointment updates, and discuss recommended ' +
    'clinical goals for these vitals with the patient, as well as review vitals monitoring ' +
    'trends and goals and behavioral goals if applicable.',
  REGULAR_4: 'This patient appears to be meeting most of their goals! Double check that they are monitoring frequently and ' +
    'that they have the appropriate goals set. Review the goal status icon if more help is needed.',
  REGULAR_5: 'The patient is not meeting the goals that were set for existing vitals. However, this patient has at ' +
    'least 1 vital that doesn’t even have a goal added. Please review the goals and add as needed. Under all ' +
    'circumstances, you should also review the EHR for any medication, lab, visit, or appointment updates, ' +
    'and discuss recommended clinical goals for these vitals with the patient, as well as review vitals ' +
    'monitoring trends and goals and behavioral goals if applicable.',
  REGULAR_6: 'This patient appears to not yet meet the goals set for them! Double check that they are monitoring ' +
    'frequently and that they have the appropriate goals set. Review the goal status icon if more help is needed.',
  MEASURE_VITAL_1: 'This patient has multiple vitals, but all together, they monitored [#] days in the last 30 days, ' +
    'and could monitor more often. Encourage the patient to monitor at least 1 vital more often, based ' +
    'on the patient’s willingness and clinical need. If the patient has any open or unresolved alerts, ' +
    'take these into consideration and resolve accordingly.',
  MEASURE_VITAL_2: 'This patient is monitoring [vital] [#] days in the last 30 days, making it tough to get an average. ' +
    'Please see vitals details or clinical goal indicator for further monitoring suggestions. If the ' +
    'patient has any open or unresolved alerts, take these into consideration and resolve accordingly.',
  MEASURE_VITAL_3: 'This patient has monitored [vital] [#] days in the last 30 days, which makes the patient’s data much ' +
    'more accurate. Give the patient kudos! Please see vitals details and provide feedback as needed. If ' +
    'the patient has any open or unresolved alerts, take these into consideration and resolve accordingly.',
  MEASURE_VITAL_4: 'This patient has multiple vitals, and all together, they monitored [#] days in the last 30 days, which ' +
    'is quite frequent. Give them kudos for monitoring often, and refer to vitals details or clinical goal ' +
    'indicators for further monitoring suggestions. If the patient has any open or unresolved alerts, take ' +
    'these into consideration and resolve accordingly.',
  TIME_DAY_1: 'Encourage monitoring [vital] at a variety of times throughout the day, ' +
    'potentially adjusting the schedule based on patient need. Example: If a patient typically checks ' +
    'pre-meals, you may encourage more post meal readings.',
  TIME_DAY_2: 'Encourage monitoring glucose at a variety of times throughout the day: If fasting is often monitored, ' +
    'you may encourage more bedtime readings and/or more breakfast paired readings.',
  TIME_DAY_3: 'Encourage monitoring glucose at a variety of times throughout the day, and frequently throughout ' +
    'the week per monitoring schedule.',
  TIME_DAY_4: 'This patient may benefit from varying the time of day that they are monitoring, and/or monitoring more often.',
  TIME_DAY_5: 'This patient may benefit from varying the time of day that they are monitoring, and/or monitoring more often.',
  BG_PAIR_1: 'Encourage the patient to take glucose paired readings more often, to better identify the patient’s ' +
    'sensitivity to foods that raise glucose, and better tailor their eating practices. This is especially ' +
    'important for patients with mealtime insulin, or for patients needing to monitor their intake more ' +
    'closely. When the pre meal glucose is in normal range, the goal paired reading difference should be ' +
    '100mg/dl or less. You will likely want to pin this patient to watch more closely.',
  BG_PAIR_2: 'Encourage the patient to check glucose post meal a few times a week if they’re curious how glucose ' +
    'may respond, especially if on a mealtime or split insulin. If the patient is on a long-acting insulin, ' +
    'bedtime +next morning fasting readings are telling. You will likely want to pin this patient to watch more closely.',
  BG_PAIR_3: 'This patient takes frequent bedtime and fasting glucose readings. Review the patient’s overnight glucoses ' +
    'for any patterns. If the patient is on a long-acting insulin, bedtime +next morning fasting readings ' +
    'are telling. You may want to pin this patient to watch more closely.',
};
