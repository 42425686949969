import {Input, Select, Checkbox, Tooltip, Icon, Button, InputNumber,Modal} from "antd";
import React from "react";
import helper from "../helper";
import UploadLogoComponent from "../../superAdmin/organization/component/UploadLogoComponent";
import MonthlyEnrollGoalCountHistoryComponent from '../Clinic/components/MonthlyEnrollGoalCountHistoryComponent';
import moment from "moment";
import {CLINIC_MANGER_STR} from '../constant/roleStrMap';
import {EHR_OPTIONS} from '../../../lib/constants';
import {forEach} from "ramda";
import { fullMonthArray } from '../constant/constVariables';
const { Option } = Select;

const addressFields = ['location', 'city', 'state', 'zip'];
// const fullMonthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const clinicTypeMap = [{ value:'REMOTE', text:'Remote' },{text:'Online+Offline',value:'ONLINE_OFFLINE'},{ text:'Hybrid',value:'HYBRID'},{text:'SAAS', value: 'SAAS'}]
const programParticipationGroupMap = {
  CCM:'CCM/RPM',
  RPM:'CCM/RPM',
  VALUE_BASED:'VALUE_BASED'
};
const programParticipationMap = [{value: 'CCM', text: 'CCM'}, {value: 'RPM', text: 'RPM'}, {value: 'MNT', text: 'MNT'},{ value:'VALUE_BASED',text:'Value-based'}];
const editClinicFormConfig = (localThis) => {
    const organization = localThis.props.organization;
    const isDemo = localThis.props.organization.excludeBilling;
    const selectedMemberIds = localThis.state.selectedMember;
    const selectedTemplateIds = localThis.state.selectedTemplate
    const parent = organization.leaf ? localThis.props.organization.parent : localThis.props.organization
    const currentUser = helper.getCurrentUser();
    const currentOrg = _.get(currentUser,'selectedRole.organization');
    const parentObj = helper.getParentIds(localThis, currentOrg.id, currentOrg.name);
    const logo = _.get(organization, 'logo', '');

    const addressValidator = (v, cb, errMsg) => {
      const { form } = localThis.props || {};
      if(!form) return cb();
      const vFields = _.pick(form.getFieldsValue(), addressFields);
      if(_.some(vFields, v => !_.isEmpty(v)) && _.isEmpty(v)) {
        return cb(errMsg);
      }
      if(_.isEmpty(v)){
        const fieldValues = addressFields.reduce((o, i) => {
          return { ...o, [i]: { value: '', error: [] }};
        }, {});
        // reset error
        form.setFields(fieldValues);
      }
      cb();
    };

    const programParticipationValidator = (v, cb, errMsg) => {
        const { form } = localThis.props || {};
        if(!form) return cb();
        return (_.find(v, (v) => v === "MNT") && v.length <= 1) ? cb(errMsg) : cb();
    }

    return [
        [{
            key:'internalName',
            label:'Internal Name *',
            formatValue: () => organization ? organization.internalName : '',
            rules: [{ required: true, message: 'Internal Name is required' }],
            inputContent:() => <Input/>,
            span:16
        }],
        [{
            key:'businessName',
            label:'Business Name',
            formatValue: () => organization ? organization.name : '',
            inputContent: () => <Input/>,
            span:16
        }],
        [{
            key:'brandName',
            label:'Brand Name',
            formatValue: () => organization ? organization.brandedName : '',
            inputContent:() => <Input />,
            span:16
        }],
        [{
          key:'EHR',
          label:'EHR',
          formatValue: () => organization ? organization.EHR : null,
          inputContent:() => <Select>
            {_.map(EHR_OPTIONS, (option, idx) =>(
              <Option key={idx} value={option}>{option}</Option>
            ))}
          </Select>,
          span:16
        }],
        [{
            key:'operationModel',
            label:'Clinic Type',
            span:16,
            formatValue: () => organization ? organization.operationModel : '',
            inputContent:()=><Select>
                                {_.map(clinicTypeMap,({value,text})=><Option key={value} value={value}>{text}</Option>)}
                             </Select>

        }],
        [{
            key: 'programParticipation',
            label: 'Program Participation *',
            rules: [{ required: true,message:'Program Participation is required.' },{
                validator : (rule, value, callback) => programParticipationValidator(value, callback, "Cannot select MNT only. Please also choose CCM and/or RPM.")
            }],
            span: 16,
            formatValue: () => organization ? _.get(organization, 'programParticipation', []) : [],
            inputContent: () => {
             const programParticipationValue = localThis.props.form.getFieldValue('programParticipation')||[];
             let programParticipationGroup = null;
             programParticipationValue.forEach(k=>{
             if(programParticipationGroupMap[k]){
                 programParticipationGroup = programParticipationGroupMap[k];
                }
             })
             return <Select mode='multiple'>
                    {_.map(programParticipationMap, ({value, text}) => {
                        let groupValue = programParticipationGroupMap[value];
                        let disabled = programParticipationGroup && (groupValue && groupValue!=programParticipationGroup);
                        return <Option key={value} value={value} disabled={disabled}>{text}</Option>
                    })}
                </Select>
            }
        }],
        // [{
        //     key:'areaBelong',
        //     label:'Parent Area',
        //     formatValue: () => _.get(organization,'parent.id', _.get(parentObj,'0.parentId')),
        //     inputContent:() => <Select onChange={(a, b) => localThis.props.setParentName(b.props.children)}>
        //         {
        //             _.map(parentObj, ({parentId, parentName}) =>
        //                 <Option value={parentId} key={parentId}>
        //                     {parentName}
        //                 </Option>
        //             )
        //         }
        //     </Select>,
        //     span:16
        // }],
        [{
            key:'timeZone',
            label:'Time Zone *',
            rules: [{ required: true, message: 'Time Zone is required' }],
            formatValue: () => organization ? organization.timezone : '',
            inputContent:() => helper.generateDropDown(helper.timeList),
            span:16
        }],
        [
            {
                key: 'dummy',
                style: { margin: 0, padding: 0 },
                label: (
                    <div>
                        iHealth Appointment serving Hours&nbsp;
                        <Tooltip placement="top" title={<span>MA/MD can only schedule visits that involve RD or CA within iHealth Appointment serving hours.</span>}>
                            <Icon type="info-circle" />
                        </Tooltip>
                    </div>
                ),
                inputContent:() => <div></div>
            }
        ],
        [
            {
                key:'visitServeTimeStart',
                span:4,
                // rules: [{ required: true, message: 'Start time is required' }],
                formatValue: () => {
                    const orgSchedules = _.get(organization, 'orgSchedules', []) || [];
                    const time = _.get(_.last(orgSchedules), 'endTime');
                    if (time) {
                        return `${+moment(time).format('HH')}`;
                    }
                },
                inputContent:()=><Select>
                    {
                        _.map(helper.hourMapIn1224Format,(h)=><Option key={h['24hour']} value={h['24hour']}>{h['12hour']}</Option>)
                    }
                </Select>
            },
            {
                key:'visitServeTimeEnd',
                span:4,
                offset:1,
                // rules: [{ required: true, message: 'End time is required' }],
                formatValue: () => {
                    const orgSchedules = _.get(organization, 'orgSchedules', []) || [];
                    const time = _.get(_.last(orgSchedules), 'startTime');
                    if (time) {
                        return `${+moment(time).format('HH')}`;
                    }
                },
                inputContent:()=><Select>
                    {
                        _.map(helper.hourMapIn1224Format,(h)=><Option key={h['24hour']} value={h['24hour']}>{h['12hour']}</Option>)

                    }
                </Select>
            },
            {
                key:'visitServeTimezone',
                span:10,
                offset:1,
                inputContent:()=><div style={{ display: 'flex', alignItems: 'center', height: 32, fontSize: 15 }}>PST (Pacific Standard Time)</div>
            }
        ],
       [{
            key:'clinicNumber',
            label: 'Clinic Phone Number',
            formatValue: () => organization ? ((organization.phone && organization.phone.number.trim()) ? organization.phone.number : '') : '',
            span: 12,
            rules: [{
                validator: (__, value, cb) => (
                  // phone number not required (which is value === '')
                  // or can be xxxyyyzzzz or (xxx)yyyzzzz or xxx-yyy-zzzz
                  (!value || value.trim() === '') ||
                  (value && new RegExp(/^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/, 'g').test(value))
                    ? cb()
                    : cb(new Error('Please enter a valid 10-digit mobile number'))
                )
            }],
            inputContent:() => <Input addonBefore='+1'/>,
        },
        {
                key:'orgPermissions.useDefaultMobile',
                span: 24,
                style:{ marginTop:'-20px' },
                valuePropName:'checked',
                formatValue: () => _.get(organization, 'orgPermissions.useDefaultMobile', false),
                inputContent:({className}) => {
                    // const value = _.get(organization, 'orgPermissions.useDefaultMobile', false);
                    return (
                        <Checkbox style={{ marginBottom: 0,fontWeight:'normal',fontSize:13 }}>
                            Use 866-number for patient visit scheduling in the app
                        </Checkbox>
                    );
                },
            },
        ],

        [{
            key:'location',
            label:'Address',
            // Address using streetName for all
            // rules:[{ required: true, message: 'Address is required' }],
            rules: [{
              validator: (r, v, cb) => addressValidator(v, cb, 'Address is required')
            }],
            formatValue: () => (_.get(organization, 'address.streetName') || '').trim(),
            inputContent:() => <Input/>,
            span:11
        },
        {
            key:'city',
            label:'City',
            offset:1,
            // rules:[{ required: true, message: 'City is required' }],
            rules: [{
              validator: (r, v, cb) => addressValidator(v, cb, 'City is required')
            }],
            formatValue: () => (_.get(organization, 'address.city') || '').trim(),
            inputContent:() => <Input/>,
            span:4
        },
        {
            key:'state',
            label:'State',
            rules: [{
              validator: (r, v, cb) => addressValidator(v, cb, 'State is required')
            }],
            formatValue: () => (_.get(organization, 'address.state') || '').trim(),
            offset:1,
            inputContent:() => <Input/>,
            span:3
        },
        {
            key:'zip',
            label:'Zip',
            // rules:[{ required: true, message: 'Zip Code is required' }],
            rules: [{
              validator: (r, v, cb) => addressValidator(v, cb, 'Zip code is required')
            }],
            formatValue: () => (_.get(organization, 'address.postCode') || '').trim(),
            offset:1,
            inputContent:() => <Input/>,
            span:3
        }],
        [{
            key:'areaBelong',
            label:'Parent Area',
            formatValue: () => _.get(organization,'parent.id', _.get(parentObj,'0.parentId')),
            inputContent:() => <Select onChange={(a, b) => localThis.props.setParentName(b.props.children)}>
                {
                    _.map(parentObj, ({parentId, parentName}) =>
                        <Option value={parentId} key={parentId}>
                            {parentName}
                        </Option>
                    )
                }
            </Select>,
            span:16
        }],
        [{
            key:'siteManager',
            label:CLINIC_MANGER_STR,
            span: 16,
            inputContent:() => helper.renderMemberSel(localThis),
            formatValue:() => selectedMemberIds,
        }],
        [{
            key:'consentForm',
            label:'Consent Form',
            span:16,
            formatValue:() => selectedTemplateIds,
            inputContent:() => helper.renderTemplateSel(localThis),
        }],
        [{
            key:'potentialPatientCount',
            label:'Total Number of Potential Patients',
            formatValue: () => organization ? organization.potentialPatientCount : '',
            inputContent:() => <InputNumber step={1} placeholder={'Enter a number here'}/>,
            span:16
        }],
        [
            {
                key:'monthlyEnrollGoalCountMonth',
                label:'Month',
                formatValue: () => parseInt(moment().format('MM')),
                inputContent:() => <Select>
                    { fullMonthArray.map((k,i)=><Option value={i+1}>{k}</Option>)}
                </Select>,
                span:8
            },
            {
                key:'monthlyEnrollGoalCountYear',
                label:'Year',
                formatValue: () => parseInt(moment().format('YYYY')),
                inputContent:() => <Select>
                    { [moment().format('YYYY'),moment().add(1,'year').format('YYYY')].map((k,i)=><Option value={k}>{k}</Option>)}
                </Select>,
                span:7,
                offset:1
            },
            {
                key:'monthlyEnrollGoalCount',
                label:'Monthly Enrollment Goal',
                formatValue: () => organization ? organization.monthlyEnrollGoalCount : '',
                inputContent:() => <InputNumber step={1} placeholder={'Enter a number here'}/>,
                span:7,
                offset:1
            }
        ],
        [{
            key:'dummy2',
            inputContent:(val)=>{
                let monthlyEnrollGoalCountHistory = _.get(val,'localThis.props.organization.monthlyEnrollGoalCountHistory',[]);
                let showHistory = _.get(val,'localThis.state.showHistory');
                let setShowHistory = _.get(val,'localThis.setShowHistory');
                return <div>
                       <p onClick={()=>setShowHistory(true)} style={{textDecoration:'underline',fontSize:14,cursor:'pointer' }}>View goal history</p>
                       <Modal visible={showHistory} footer={null} onCancel={ ()=>setShowHistory(false)} title={'Monthly Enrollment Goal History'}>
                        <MonthlyEnrollGoalCountHistoryComponent monthlyEnrollGoalCountHistory={monthlyEnrollGoalCountHistory}/>
                       </Modal>
                </div>
            }
        }],
        [{
            key:'upload',
            label: 'Logo',
            inputContent: () => <UploadLogoComponent
             {
                 ...{
                     ...localThis,
                     props: {
                         ...localThis.props,
                         organizationData: { logo }
                     }
                 }
             }
            />
        }],
        // [{
        //     key:'orgPermissions.analyticsAPI',
        //     label:'',
        //     span: 11,
        //     formatValue: () => _.get(organization, 'orgPermissions.analyticsAPI', false),
        //     inputContent:() => {
        //         const value = _.get(organization, 'orgPermissions.analyticsAPI', false);
        //         return (
        //             <Checkbox style={{ marginBottom: 10 }} defaultChecked={value || false}>
        //                 Data Insights Access&nbsp;
        //                 <Tooltip
        //                     placement="top"
        //                     title={<span>
        //                         When checked, this clinic’s iHealth members will have access to a “Data Insights”
        //                         section that shows various data pertaining to that clinic.
        //                     </span>}
        //                 >
        //                     <Icon type="info-circle" />
        //                 </Tooltip>
        //             </Checkbox>
        //         );
        //     },
        // }],
        [{
            key:'description',
            label: 'Notes',
            formatValue: () => organization ? organization.description : '',
            span: 24,
            inputContent:()=><Input.TextArea rows={5}/>
        }],
        [{
            key:'isDemo',
            valuePropName:'checked',
            formatValue:()=> isDemo,
            span:12,
            inputContent:()=><Checkbox onChange={localThis.onChange}>This is a demo clinic</Checkbox>
          },
          {
            key:'terminateBtn',
            span:12,
            style:{
              float:'right'
            } ,
            inputContent:()=> <Button type={'danger'} onClick={()=>localThis.setShowTerminateClinicModal(true)}>Terminate Clinic</Button>
          }
        ]
    ]
}

export default editClinicFormConfig;
