import React, {useState} from "react";
import {Button, Input, message} from "antd";
import { muteMissingInfo } from '../API/index';
const MuteMissingInfoComponent = (props)=>{
	const { setShowMuteModal,user,refetchMissingInfo } = props;
	const [ note,setNote ] = useState('');
	const renderHeader = ()=><div className={'header'}>Once postponed, the task will be retrieved on the first day of next month for billing purposes.</div>
	const renderInput =()=>{
		return <div className={'inputDiv'}>
			Your notes
			<Input onChange={(e)=>setNote(e.target.value)}/>
		</div>
	}
	const handleCancel = ()=>{
		setShowMuteModal(false);
	}
	const handleSubmit = ()=>{
		muteMissingInfo({note,memberId:user.id,mute:true})
			.then(async res=>{
				if(refetchMissingInfo) {
					await refetchMissingInfo();
					message.info('Missing Info muted');
				}
			})
			.catch(e=>console.log(e));

	}
	const renderBnt = ()=>{
		const disabled = !note.length;
		return <div className={'btnWrapper'}>
				<Button onClick={handleCancel} style={{marginRight:10}}>Cancel</Button>
				<Button disabled={disabled} type={'primary'} onClick={()=>handleSubmit()}>Submit</Button>
		       </div>
	}
	return <div className={'muteMissingInfoWrapper'}>
			{ renderHeader() }
			{ renderInput() }
			{ renderBnt() }
	</div>
}
export default MuteMissingInfoComponent;