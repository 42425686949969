import React from 'react';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Icon, Popover } from 'antd';
import getPatientConsentGQL from '../../profileDetail/query/GetpatientConsent';
import '../css/consentForm.scss';
import reducerActions from '../../../VideoChat/action/reducerActions';
import ConsentFormMini from '../components/consentForm/ConsentFormMini';
import { CONSENT_TYPE, VISIT_CONSENT_TYPE_MAP, DEFAULT_CONSENT_TYPE_OPTIONS } from '../constants';
import ConsentFormPreview from '../components/consentForm/ConsentFormPreview';

class ConsentFormMiniContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      consentFileUrl: null,
      // viewModal: false,
      // pdfViewUrl: null,
      // to determine fetch when it's about real-time status
      needRealtimeFetch: false,
    };
  }

  getMemberLanguage = member => {
    const appLanguage = _.get(member, 'profile.appLanguage.code');
    const spokenLanguage = _.get(member, 'profile.language.code');
    return appLanguage || spokenLanguage || 'EL';
  };

  checkIfPaperConsent = info => _.get(info, 'consentType') === CONSENT_TYPE.PAPER;

  renderConsentStatus = () => {
    const {
      consentInfo = {}, loadingConsentInfo,
      member = {}, visitCategory,
      setConsentInfo, consentInfoStatus,
      usePopoverForForm = false, consentProviderName
    } = this.props;
    const { consentFileUrl = consentInfo.fileUrl  } = this.state;
    let isPaperConsent = this.checkIfPaperConsent(consentInfo);
    let isConsent = _.get(consentInfo, 'consent');
    let fileUrl = consentFileUrl;

    if(!isConsent && !_.isEmpty(consentInfoStatus)) {
      // !isConsent is to prevent wrong real-time status
      // when real-time status failed to fetch updates
      // When isConsent is unexpectedly FALSE during session
      // Simply close and re-open parent window to fetch the new updates from API
      isConsent = _.get(consentInfoStatus, 'consent');
      isPaperConsent = this.checkIfPaperConsent(consentInfoStatus);
      fileUrl = _.get(consentInfoStatus, 'fileUrl');
    }

    const text = isPaperConsent ?
      `Paper consent form signed.` :
      `Consent form ${isConsent ? 'signed!': 'not signed.'}`;

    const ConsentFormMiniWrapper = (
    <div className='consent-form-mini-wrapper consent-form-container'>
      <ConsentFormMini
        hasBeenSent={isConsent === false && consentInfo.consentType === CONSENT_TYPE.APP}
        setConsentInfo={setConsentInfo}
        language={this.getMemberLanguage(member)}
        patientId={_.get(member, 'id')}
        patientName={_.get(member, 'profile.fullName')}
        range={VISIT_CONSENT_TYPE_MAP[visitCategory] || DEFAULT_CONSENT_TYPE_OPTIONS}
        consentProviderName={consentProviderName || ''}
      />
    </div>);

    return (!loadingConsentInfo && !_.isEmpty(member)) ?
        <div className='consent-form-status'>
          {
            isConsent ?
              <ConsentFormPreview
                successText={text}
                viewText='View consent form'
                memberId={member.id}
                consentType={consentInfoStatus.consentType || consentInfo.consentType}
                fileUrl={fileUrl}
              />
              // <div className='consent-form-status-signed'>
              //   <Icon type='check-circle' />
              //   <div className='consent-form-status-text'>
              //     {text}
              //     {
              //       !isPaperConsent &&
              //       <a onClick={e => {
              //         e.preventDefault();
              //         this.setState({ viewModal: true }, () => {
              //           const setPDFViewURL = async () => {
              //             // consent flag changed in real-time, fetching for link
              //             if(!fileUrl) {
              //               try {
              //                 const res = await Client.query({
              //                   query: getPatientConsentGQL,
              //                   variables: { memberId:_.get(member, 'id') },
              //                   fetchPolicy: 'network-only'
              //                 });

              //                 fileUrl = _.get(res, 'data.getPatientConsent.fileUrl');
              //               } catch (e) {
              //                 console.error(e);
              //                 // don't need to notify user about error
              //                 // Can simply close Task List and re-open if it fails
              //               }
              //             }

              //             return new Promise((resolve, reject) => {
              //               if(pdfViewUrl) return resolve(pdfViewUrl);
              //               const xhttp = new XMLHttpRequest();
              //               function onDataReceived() {
              //                 if(xhttp.readyState === XMLHttpRequest.DONE) {
              //                   if (xhttp.status === 0 || (xhttp.status >= 200 && xhttp.status < 400)) {
              //                     const blob = new Blob([xhttp.response], { type: 'application/pdf' });
              //                     return resolve(window.URL.createObjectURL(blob));
              //                   } else {
              //                     return reject('Failed to fetch file');
              //                   }
              //                 }
              //               }
              //               xhttp.onreadystatechange = onDataReceived;
              //               xhttp.open('GET', fileUrl, true);
              //               xhttp.responseType = 'arraybuffer';
              //               xhttp.send();
              //             });
              //           }

              //           setPDFViewURL().then(url => {
              //             this.setState({ pdfViewUrl: url, consentFileUrl: fileUrl });
              //           }).catch(err => {
              //             console.error(err);
              //           });
              //         });
              //       }}>
              //         View consent form
              //       </a>
              //     }
              //   </div>
              // </div>
            :
              (<div className='consent-form-status-not-signed'>
                <Icon type='info-circle' />
                {
                  usePopoverForForm ?
                  <Popover
                    overlayClassName='consent-form-container'
                    placement='bottom'
                    trigger='click'
                    content={ConsentFormMiniWrapper}
                  >
                    <a id='consent-form-popover-trigger'>{text}</a>
                  </Popover>
                  :
                  <span className='consent-form-status-text'>{text}</span>
                }
                {
                  !usePopoverForForm &&
                  ConsentFormMiniWrapper
                }
              </div>)
          }
        </div>
      : <span className='loading-consent-info'>Loading consent info...</span>;
  };

  render() {
    // const { viewModal, pdfViewUrl } = this.state;
    const enrolledProgramStatus = _.get(this, 'props.enrolledProgramStatus');
    const isAvailableToSendConsent = enrolledProgramStatus && _.toUpper(enrolledProgramStatus) !== 'DISCHARGED';
    return isAvailableToSendConsent &&
      <div className='consent-form-container-wrapper'>
        { this.renderConsentStatus() }
      </div>;
  }
};

// {
//   viewModal &&
//   <ConsentFormModal
//     modalBody={
//       pdfViewUrl ?
//       <iframe
//         src={pdfViewUrl}
//         style={{ width: '100%', height: '100%', border: 'none'}}
//       >
//         {/* Fallback when unsupported */}
//         <div>Unsupported browser</div>
//       </iframe>
//       : <IHLoading />
//     }
//     onCancel={() => this.setState({ viewModal: false })}
//   />
// }

ConsentFormMiniContainer.propTypes = {
  consentInfo: PropTypes.object,
  consentInfoStatus: PropTypes.any.isRequired,
  loadingConsentInfo: PropTypes.bool,
  visitCategory: PropTypes.string.isRequired,
  member: PropTypes.object.isRequired,
  enrolledProgramStatus: PropTypes.string,
  consentProviderName: PropTypes.string
};

const getPatientConsent = graphql(getPatientConsentGQL,{
  options: props => {
    const memberId = _.get(props, 'member.id');
    return { variables: { memberId } };
  },
  props:({ data })=>{
    const { getPatientConsent, loading, error } = data;

    if(error) console.error(error);

    return {
      consentInfo: getPatientConsent || {} ,
      loadingConsentInfo: loading
    }
  }
});

const mapToDispatch = dispatch => ({
  setConsentInfo: (memberId,consentInfo) =>
    dispatch(reducerActions.setConsentInfo(memberId,consentInfo))
});

const mapToProps = (state,props) => ({
  consentInfoStatus: state.VideoChat.main.consentInfo[_.get(props, 'member.id')] || {}
});

export default compose(
  connect(mapToProps, mapToDispatch),
  getPatientConsent,
)(ConsentFormMiniContainer);
