import React from 'react';
import { Input,Button,Modal } from 'antd';
const AgoraVerifyPtComponent = class extends React.Component{



    renderInput = ()=>{
        const { verification,setVerification } = this.props;
        return <Input value={verification} onChange={ (e)=>setVerification(e.target.value) } />
    }

    renderVerifyButton = ()=>{
        const { handleVerifyFirstName,verification,authorizing } = this.props;
        const msg = authorizing ? 'Authorizing' : 'Verify';
        return <Button onClick={ handleVerifyFirstName }
                       type="primary"
                       disabled={!verification}>
                { msg }
               </Button>
    }

    renderModal = ()=>{
        const { renderVerifyButton,renderInput } = this;

        return <div className='visitVerificationModal'>
                <Modal title={'Enter your first name to verify your identity.'} visible={true} closable={false} footer={false} className='visitVerificationModal'>
                    { renderInput() }
                    { renderVerifyButton() }
                </Modal>
               </div>
    }


    render(){
        const { renderModal } = this;
        return <div>
                    { renderModal() }
               </div>
    }
}


export default AgoraVerifyPtComponent;
