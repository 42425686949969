import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const ConsentFormModal = props => {
  const { 
    modalBody, destroyOnClose = true, visible = true, 
    ...restProps 
  } = props;
  return <Modal
    width={1000}
    zIndex={99999}
    style={{ top: 0 }}
    bodyStyle={{ height: '100vh', padding: '50px 0px 0px 0px' }}
    footer={null}
    visible={visible}
    destroyOnClose={destroyOnClose}
    {...restProps}
  >
    {props.modalBody}
  </Modal>;  
};

ConsentFormModal.propTypes = {
  modalBody: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ConsentFormModal;
