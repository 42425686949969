import { constants } from 'libModule/utils'
import type from "../../medicine/constants";

export default {
    isUpdated:(isUpdated)=>{
        return {
            type:type.IS_UPDATED,
            payload:{
                isUpdated
            }
        }
    }
}
