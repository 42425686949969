import React, { Component } from 'react';
import {Col, Row, Form, DatePicker, Select, Tooltip, Icon} from "antd";
const {Option} = Select;

import moment from "moment";

const {Item} = Form;
const dateFormat = 'MM/DD/YYYY';

import {helpers as dataHelper} from '../helper/index';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import I18N from '../../I18N';
import { buildVisitListMini } from '../../graphql/visitListMini';
import Client from '../../../lib/gqlClient';


const lastVisitQuery = buildVisitListMini(['appointmentAt', 'type']);
class VisitInputTime extends Component{
    static displayName = 'visitNewWorkFlow/components/VisitInputTime';
    constructor(props) {
        super(props);
        this.getVisitStartAt = (visitInfo) => {
            return _.get(visitInfo,'visitStartTime',_.get(visitInfo,'appointmentAt'));
        };

        let visitStartAt = this.getVisitStartAt(props.visitInfo);
        let localAMOrPM = null;
        let remoteAMOrPM = null;
        let selectedHour = null;
        let selectedMin = '00';

        if( visitStartAt ) {
            let rescheduledFromId = props.rescheduledFromId;
            let visitStartAtDate = new Date(visitStartAt);
            selectedHour = rescheduledFromId ? null : dataHelper.localToRemoteHour(visitStartAtDate.getHours());
            selectedMin = visitStartAtDate.getMinutes();
            if (selectedMin < 10){
              selectedMin = '0' + selectedMin;
            }
            localAMOrPM =  visitStartAtDate.getHours() >= 12 ? 'PM':'AM';
            remoteAMOrPM = selectedHour >= 12 ? 'PM':'AM';
        }

        this.state = {
            selectedHour: selectedHour > 12 ? selectedHour-12 : selectedHour,
            selectedMin,
            localAMOrPM,
            remoteAMOrPM,
            followUpVisitWindow: []
        }
    }

    setFollowUpVisitWindow = (lastVisit, healthConditions = []) => {
      if(_.isEmpty(lastVisit)) // should NOT have window time when there is no init/follow-up visit in the past
        return;
      const followUpRange = dataHelper.getFollowUpWindowTime(lastVisit, healthConditions);
      const { from, to } = followUpRange;
      const { fromFormat, toFormat } = DATE_HELPERS.getFromToDateFormat(from, to);
      this.setState({
        followUpVisitWindow: [from.format(fromFormat), to.format(toFormat)]
      });
    }

    getFollowUpVisitWindow = () => {
      // logic is mostly synced from FollowUpVisitComponent
      const { visitInfo, patient, lastVisit } = this.props;
      if(lastVisit) {
        return this.setFollowUpVisitWindow(lastVisit, this.props.healthConditions);
      }
      const member = _.get(visitInfo, 'member', _.get(patient, 'user')) || {};
      if(_.isEmpty(member)) 
        return;
      
      const { id: memberId, profile: { healthConditions = [] } = {} } = member;
      const visitStartAt = this.getVisitStartAt(visitInfo);
      const appointmentTo = moment(visitStartAt).endOf('hour').valueOf();

      const queryOpts = {
        query: lastVisitQuery,
        variables: {
          count: 1,
          appointmentTo,
          memberId,
          type: ['INIT', 'FOLLOW_UP'],
          sort: { field: 'APPOINTMENT_AT', direction: 'DESC' },
        },
        fetchPolicy: 'network-only'
      };
      Client.query(queryOpts)
      .then(res => {
        const lastVisit = _.get(res, 'data.visitList.data.0') || {};
        this.setFollowUpVisitWindow(lastVisit, healthConditions);
      })
      .catch(console.error);
    }

    componentDidMount = () => {
      this.getFollowUpVisitWindow();
    }

    componentDidUpdate(prevProps){
        const preVisitId = _.get(prevProps, 'visitInfo.id');
        const curVisitId = _.get(this.props, 'visitInfo.id');
        if(preVisitId !== curVisitId){
            // if the chosen visit has been changed
            // local time should update as well
            let visitStartAt = this.getVisitStartAt(this.props.visitInfo);


            if( visitStartAt ) {
                let visitStartAtDate = new Date(visitStartAt);
                let selectedHour = dataHelper.localToRemoteHour(visitStartAtDate.getHours());
                this.setState({
                    selectedHour: selectedHour > 12 ? selectedHour-12 : selectedHour,
                    selectedMin: visitStartAtDate.getMinutes(),
                    localAMOrPM: visitStartAtDate.getHours() >= 12 ? 'PM':'AM',
                    remoteAMOrPM: selectedHour >= 12 ? 'PM':'AM',
                })
            }

            this.getFollowUpVisitWindow();
        }
    }
    disabledDate(current){
        const week = moment(current).day();
        //sc-3671 Create/edit visit disable Sunday, and support Schedule on the past time
        if(week === 0){
            // || week === 6){
            return current;
        }

            // && current < moment().startOf('day');
    }

    isHourDisabled = () => {
        const { role, orgSchedules } = this.props;
        const visitRoles = this.props.form.getFieldValue('visitRoles');
        const hours = {};

        for (let hour24 = 0; hour24 < 24; hour24++) {
            hours[hour24] = {
                '24hour': hour24,
                '12hour': hour24 > 12 ? hour24 - 12 : hour24,
                disabled: false
            };
        }

        if (orgSchedules) {
            orgSchedules.forEach(o => {
                const startTime = +moment(o.startTime).format('HH');
                const endTime = +moment(o.endTime).format('HH');

                // SC-7051: apply the serving hours settings to ALL roles and all visits
                // if (o.blockingRoles.includes(role)) {
                    for (let hour24 = 0; hour24 < 24; hour24++) {
                        let disabled = false;

                        // if endTime is the next day
                        if (startTime > endTime) {
                            disabled = hour24 >= startTime || hour24 < endTime;
                        } else {
                            disabled = hour24 >= startTime && hour24 < endTime;
                        }

                        hours[hour24].disabled = hours.length > 0 ? hours[i].disabled && disabled : disabled;
                    };
                // }
            });
            return hours;
        }
        return hours;
    }

    isRemoteDisabled = (localHours, remote24Hour) => {
        const localHour = dataHelper.remoteToLocalHour(remote24Hour);
        const h = (localHour + 24)%24;
        return localHours[h].disabled || false;
    }

    onChangeTime = (dateFromPicker)=>{
        const { setAvailability,form, visitInfo } = this.props;
        const visitId = _.get(visitInfo, 'id');
        const {date,hour, min,type } = form.getFieldsValue();
        const combinedStartTime = dataHelper.combineDateHourAndMin(dateFromPicker||date,parseInt(hour),parseInt(min))+0;
        if (this.props.onDateChange) this.props.onDateChange(moment(combinedStartTime));
        dataHelper.onChangeAssigneeOrVisitTime(type,combinedStartTime,setAvailability,visitId);
    }

    render() {
        const disabledHours = this.isHourDisabled();
        const {visitInfo, editStatus, curTab, selectedVisitType} = this.props;
        const isMNT = selectedVisitType === "MNT"
        const { selectedMin,selectedHour,localAMOrPM,remoteAMOrPM, followUpVisitWindow} = this.state;
        let initialDate = curTab === 'Tomorrow' ? moment().add(1, 'days') : moment().add(0, 'days');
        // if(this.props.isMaOrMd) {
        //     while (moment(initialDate).day() === 0){
        //         initialDate = moment(initialDate).add(1, 'days');
        //     }
        // } else {
        //     while (moment(initialDate).day() === 0 || moment(initialDate).day() === 6){
        //         initialDate = moment(initialDate).add(1, 'days');
        //     }
        // }
        let initialHour = null;
        let initialRemoteHour = null;
        let initialMin = dataHelper.minMap[0];

        if(editStatus){
            let visitStartAt = this.getVisitStartAt(visitInfo);
            initialDate = moment(new Date(visitStartAt));
            initialHour = new Date(visitStartAt).getHours();
            initialRemoteHour = dataHelper.localToRemoteHour(initialHour);
            initialMin = new Date(visitStartAt).getMinutes();
            if(initialMin < 10) {
                initialMin = '0' + initialMin;
            }
        }
        const { getFieldDecorator } = this.props.form;
        const onChangeMin = (val)=>{
            this.props.form.setFieldsValue({
                min:val,
                remoteMin: val
            })
            // this.setState({
            //     selectedMin:val
            // })
            this.onChangeTime();
        }
        const onChangHour = (val,localToRemote)=>{
            const {localToRemoteHour,remoteToLocalHour } = dataHelper;
            let localHour =  !localToRemote ? remoteToLocalHour(val) : val;
            let remoteHour = localToRemote ? localToRemoteHour(val) : val;
            this.props.form.setFieldsValue({
                hour: localHour+'',
                amOrpm:localHour>=12 ? 'PM' : 'AM',
                remoteHour: remoteHour> 12 ? remoteHour-12 : remoteHour,
            },()=>{
                this.setState({
                    localAMOrPM: localHour>=12 ? 'PM' : 'AM'
                })
            })

            this.setState({
                // selectedHour: remoteHour> 12 ? remoteHour-12 : remoteHour,
                remoteAMOrPM: remoteHour>=12 ? 'PM' : 'AM'
            })
            this.onChangeTime();

        }
        const rowStyle = {
            display:'flex',
            flexDirection:'row'
        }
        const ampmTitle = {
            position:'absolute',
            bottom:'10px',
            right:'-10px'
        }
        const disabledText = (
            <span>
                This appointment involves RD or CA.
                It's outside iHealth Unified Care appointment
                serving hours.
            </span>
        );
        return (
            <Row gutter={4}>
                <Col span={8} >
                    <Item label={I18N.get('visit.details.visitDate.required.label')} >
                        {getFieldDecorator('date', {
                            initialValue: initialDate,
                            rules: [{
                              required: true,
                              message: I18N.get('visit.details.visitDate.required.message')
                            }]})
                        (
                            <DatePicker format={dateFormat} allowClear={false} onChange={ (date)=>this.onChangeTime(date) }/>
                        )}
                    </Item>
                </Col>
                <Col span={14}>
                    <Row style={rowStyle}>
                        <Col span={10}>
                            <Item label={I18N.get('visit.details.visitTime.required.label')} >
                                {getFieldDecorator('hour', {
                                    initialValue: _.toString(initialHour),
                                    rules: [{ required: true, message: I18N.get('visit.details.visitTime.required.message')}],
                                })(
                                    <Select onChange={(hour)=>onChangHour(hour,dataHelper.localToRemoteHour)}>
                                        {
                                            _.map(dataHelper.hourMapIn1224Format,(h)=> {
                                                const { disabled } = disabledHours[+h['24hour']];
                                                return (
                                                    <Option disabled={disabled} key={h['24hour']} value={h['24hour']}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {h['12hour']}
                                                            {disabled && <Tooltip
                                                                placement="top"
                                                                title={disabledText}
                                                            >
                                                                <Icon type="info-circle" />
                                                            </Tooltip>}
                                                        </div>
                                                    </Option>
                                                );
                                            })
                                        }
                                    </Select>
                                )}
                            </Item>
                        </Col>
                        <Col span={24} style={{ display:'none' }}>
                            {getFieldDecorator('amOrpm', {
                                initialValue: initialHour >=12 ? 'PM' : 'AM',
                            })(
                                <input />
                            )}
                        </Col>
                        <Col span={10} style={{ marginLeft: 5 }}>
                            <Item label="Min" >
                                {getFieldDecorator('min', {
                                    initialValue: _.toString(initialMin),
                                    rules: [{ required: true, message: 'Please Select Min'}],
                                })(
                                    <Select onChange={ onChangeMin }>{_.map(dataHelper.minMap,(m)=>
                                        <Option key={m} value={m}>{m}</Option>)}
                                    </Select>
                                )}
                            </Item>
                        </Col>
                        { localAMOrPM&&
                          <Col span={4} style={ampmTitle} className={'ampmDiv ampmDiv-local'}>
                            {localAMOrPM}
                          </Col>
                        }
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className='ant-form-item-label'>
                                <label className='ant-form-item-required' style={{fontSize:12}}>{I18N.get('visit.details.visitPatientTime.required.label')}</label>
                            </div>
                        </Col>
                        <Col span={10} >
                            {getFieldDecorator('remoteHour', {
                                initialValue: _.toString(initialRemoteHour),
                            })(
                            <Select onChange={(hour)=>onChangHour(hour,null,dataHelper.remoteToLocalHour)} /*value={selectedHour}*/>
                                {
                                    _.map(dataHelper.patientHoursIn1224Format,(h) => {
                                        const disabled = this.isRemoteDisabled(disabledHours, h['24hour']);
                                        return (
                                            <Option disabled={disabled} key={h['24hour']} value={h['24hour']}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {h['12hour']}
                                                    {disabled && <Tooltip
                                                        placement="top"
                                                        title={disabledText}
                                                    >
                                                        <Icon type="info-circle" />
                                                    </Tooltip>}
                                                </div>
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                            )}
                        </Col>
                        <Col span={10} style={{ marginLeft: 5 }}>
                            {getFieldDecorator('remoteMin', {
                                initialValue: _.toString(initialMin),
                            })(
                            <Select /*value={_.toString(selectedMin)}*/ onChange={ onChangeMin }>{_.map(dataHelper.minMap,(m)=>
                                <Option key={m} value={m}>{m}</Option>)}
                            </Select>
                            )}
                        </Col>
                        {
                            remoteAMOrPM&&
                            <Col span={4} style={ampmTitle} className={'ampmDiv ampmDiv-remote'}>
                                {remoteAMOrPM}
                            </Col>
                        }
                    </Row>
                </Col>
                {isMNT ? <Col span={24} style={{ marginTop: 12 }}>
                    <span>
                        {I18N.get('visit.tips.SelectMNTFollwUps')}
                    </span>
                  </Col> :
                  !_.isEmpty(followUpVisitWindow) &&
                  <Col span={24} style={{ marginTop: 12 }}>
                    <span>
                      Follow-up visit window: {_.join(followUpVisitWindow, '-')}
                    </span>
                  </Col>
                }
            </Row>
        )
    }

}

export default VisitInputTime;
