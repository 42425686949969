import keywords from './keywords';

export default {
  "1000001": "Invalid Login ID or Password",
  "1000002": "Your password is too short",
  "1000003": "Wrong username or password. Try again or click forgot password to reset it.",
  "1000004": "Phone number is required for provider registration",
  "1000005": "You are not authorized to view this data or perform the action",
  "1000006": "You are not logged in",
  "1000007": "You do not belong to an organization",
  "1000008": "You are not authorized to access data of another organization",
  "1000009": "Failed to login because the token was invalid",
  "1000010": "The verification link you followed is invalid",
  "1000011": "You need an identification field to register an account",
  "1000012": "You need an email to register an account",
  "1000013": `A user with the same ${keywords['NRIC/FIN']} already exists`,
  "1000014": `There are no tasks for this ${keywords.program}`,
  "1000015": `The ${keywords.program} duration must be at least 1 day`,
  "1000016": "You must set device models for all required device types",
  "1000017": "Tasks must not have an overlapping schedule",
  "1000018": `This ${keywords.program} does not exist`,
  "1000019": "Activation failed because some required information is missing",
  "1000020": `${keywords.Assessment} must be published to be added to tasks`,
  "1000021": `This member is already enrolled in this ${keywords.program}`,
  "1000022": `The ${keywords.program} you requested does not exist`,
  "1000023": `The ${keywords.program} you requested is currently not active`,
  "1000024": `You cannot change the start date because this enrolled ${keywords.program} has already started`,
  "1000025": `You cannot set the end date of an enrolled ${keywords.program} that has not started`,
  "1000026": `There are no device requirements for this enrolled ${keywords.program}`,
  "1000027": `You cannot change devices for a user after a ${keywords.program} has started`,
  "1000028": `This ${keywords.program} cannot be edited because it is finished`,
  "1000029": `This ${keywords.program} cannot be edited because it has not yet started`,
  "1000030": `This ${keywords.program} has already started`,
  "1000031": `You cannot start a ${keywords.program} before the current date`,
  "1000032": `You cannot suspend or resume a ${keywords.program} when it hasn't started.`,
  "1000033": `This task is already disabled or the enrolled ${keywords.program} does not exist`,
  "1000034": `This task is already enabled or the enrolled ${keywords.program} does not exist`,
  "1000035": `You can only enroll members into a ${keywords.program} that belong to your organization`,
  "1000036": `${keywords.Assessment} document(s) does not exist`,
  "1000037": `${keywords.Assessment} must be completed before published`,
  "1000038": `${keywords.Assessment} is already published; you can still edit`,
  "1000039": `${keywords.Assessment} is not included in any actively enrolled ${keywords.program}`,
  "1000040": `Cannot submit an incomplete ${keywords.assessment} result`,
  "1000041": `${keywords.Assessment} result(s) is not found`,
  "1000042": `${keywords.Assessment} result(s) cannot not be modified after submitted`,
  "1000043": "One or more members did not have the correct roles to be in a (care) team",
  "1000044": "One or more members were not part of the team you specified",
  "1000045": `Could not remove members because one or more members are currently managing a patient-enrolled ${keywords.program}`,
  "1000046": `One or more teams are not eligible to manage this ${keywords.program}`,
  "1000047": "Cannot add results for a late document",
  "1000048": "Cannot add result because all tasks were completed",
  "1000049": "Cannot add Result because it already exists",
  //"1000050": "This program does not require this device",
  "1000051": "Phone number is invalid",
  "1000052": "No user is found with this token",
  "1000053": `${keywords.Programs} that have been used to enroll a member cannot be deleted`,
  "1000054": "InterventionNotes document(s) does not exist",
  "1000055": "The token is invalid",
  "1000056": "Too many failed attempts",
  "1000057": "Cannot perform this action with provided token",
  "1000058": "authy account cannot be created",
  "1000059": "Token is expired",
  "1000060": "Error sending two factor authentication code",
  "1000061": "Id's format is wrong",
  "1000062": "This operation is only for users not having on boarded",
  "1000063": "This email is already verified, reject reonboarding verified email",
  "1000064": "Phone number is required for this operation",
  "1000065": "Two factor authentication failed",
  "1000066": `Date should be in the range of Enrolled ${keywords.Program}'s date`,
  "1000067": "End date should be less than current time and start should be less than end",
  "1000070": "This team does not exist",
  "1000071": "Teams that have been used to enroll a member cannot be deleted",
  "1000084": "Due date has already passed",
  "1000090": "Username is already taken",
  "1000101": `${keywords['NRIC/FIN']} already exists`,
  "1000102": "Data validation error",
  "403": "Forbidden - Failed "
}
