import React from 'react';
import { Checkbox,Row } from 'antd';
import ArticlesContainer from '../../careplanNew/container/ArticlesContainer';
import { shortenId } from '../../careplanNew/helpers/nutritionHelpers';

const renderCheckboxWithHeader = ({header,description})=>{
    return <Row className={'checkboxWithHeader'}>
             <div className={'header'}>{header}</div>
             <Checkbox>{description}</Checkbox>
           </Row>
}
const checkboxList = {
        selfReflection: {
            header: 'Demo & Self-Reflection',
            description: 'Ask patient to give a demonstration of how he/she takes measurements'
        },
        discussion: {
            header: 'Discussion',
            description: 'Gave patient feedback on their method; Reviewed AHA/ADA best practices of taking a measurement with patient'
        },
        appReview: {
            header: 'App Review',
            description: 'Reviewed Education and FAQ section in the app with patient'
        }
    };

const getSelectedHandouts = props => _.get(props, 'user.profile.handouts', []);

export default  [
  ..._.map(_.keys(checkboxList), key => {
    const { header, description } = checkboxList[key];
    return {
      key,
      label: header,
      initialValue: props => _.get(props, `user.profile.education.${key}`) || false,
      valuePropName: 'checked',
      render:()=> <Checkbox>{description}</Checkbox>
    };
  }),
  {
    key:'handouts',
    initialValue: props => _.map(getSelectedHandouts(props), h => shortenId(h.id)),
    render:(localThis)=>{
      const { props, setHasChanges } = localThis;
      return (
        <ArticlesContainer 
          patientId={_.get(props, 'user.id')}
          patientLanguageSettings={(
            _.pick(_.get(props, 'user.profile'), ['language', 'appLanguage'])
          )}
          onInteraction={props.onInteraction}
          disabled={props.disabled}
          isWorkList
          setHasChanges={setHasChanges}
        />
      );
    }
  }
];
