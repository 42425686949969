import gql from 'graphql-tag'

export default gql`
  mutation requestResetPassword(
    $email:EmailAddress!,
    $username: String
  ){
    requestResetPassword(
      email: $email,
      username: $username
    )
  }
`
