import { combineReducers } from 'redux'
import Common from 'ProgramEditModule/Assessment/reducers/CommonReducer'
import AssessmentForm from 'ProgramEditModule/Assessment/reducers/FormReducer'
import AssessmentList from 'ProgramEditModule/Assessment/reducers/ListReducer'
import Alert from 'ProgramEditModule/AssessmentAlert/reducers'

const assessment = combineReducers({
  Common,
  AssessmentForm,
  AssessmentList,
  Alert,
})

export default assessment
