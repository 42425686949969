import { React,IHSearchTable,IHLoading } from 'ihcomponent';
import action from '../action/index';
import {connect} from "react-redux";
import tableConfig from "../constant/tableConfig";
import {Select, Icon, Button} from "antd";
import moment from "moment";
import monthMenuMap from '../../../utils/constants/monthMenuMap';

const Option = Select.Option;

class TimeSpentListComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    renderMonthDropDown(){
        const value = _.get(this,'props.month');
        const month = monthMenuMap[value-1].value;
        const menu = _.map(monthMenuMap,(data,index)=> {
            return <Option key={index} value={index}>
                {data.value}
            </Option>
        });

        return <Select style={{ width: 120 }}
                       defaultValue={month}
                       value={month}
                       dropdownClassName='monthDropDown'
                       className='monthDropDown'
                       onChange={(month)=>this.props.setMonth(month+1)}
        >
            {menu}
        </Select>
    }

    renderYearDropDown(){
        let curYear = new Date().getFullYear();
        let lastYear = curYear - 1;
        let yearBeforeLast = lastYear - 1;
        const yearList = [{
            key: yearBeforeLast,
            value: yearBeforeLast
            },
            {
                key: lastYear,
                value: lastYear
            },
            {
                key: curYear,
                value: curYear
            }
        ]
        //need more detailed requirements on this yearlist
        const menu = _.map(yearList,(key,index)=>{
            return <Option value={key.key} key={index}>
                {key.value}
            </Option>
        });
        const year = _.get(this,'props.year');
        return <Select  style={{ width: 100,marginLeft:20 }}
                        onChange={(year)=>this.props.setYear(year)}
                        value = { year }
                        dropdownClassName='yearDropDown'
                        className='yearDropDown'
        >
            {menu}
        </Select>
    }
    renderAllMonthTable =()=>{
        const { curAndPreMonth=[] ,loading } = this.props;
        const { expanded } = this.state;
        const footer =()=> <div style={{textAlign: 'center'}}>
                <Button className='no-border-button' onClick={()=>this.setState({expanded:!expanded})}>
                    { !expanded ? 'View All Months' : 'Collapse' }
                    <Icon type={!expanded ? 'down' : 'up'}/>
                </Button>
            </div>
        console.log(curAndPreMonth);
        const tableProps = {
            columns: tableConfig.monthYearTotalTable,
            rowKey: (key, index) => index,
            loading: {spinning: loading, indicator: <IHLoading/>},
            dataSource: expanded ? curAndPreMonth : curAndPreMonth.slice(0, 4),
            pagination: true,
            className: 'timeSpentTable',
        }
        const noDataElem = (
            <div style={{  paddingTop: '2em',paddingBottom: '2em',display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'HelveticaNeue',color:'#c5c5c5' }}>
                <div style={{ fontSize: '26px',marginTop:20,marginBottom:10}}>
                    No Time Spent Records
                </div>
                <div style={{fontSize:'16px',maxWidth:'405px'}}>
                    There is no Time Spent Recorded for this patient.
                </div>
            </div>
        );

        return (
            <div className="patient-task-table">
                <IHSearchTable {...tableProps} noDataElem={noDataElem} footer={footer}/>
                {/*<div className='expandIcon' onClick={()=>this.setState({expanded:!expanded})}><Icon type={`caret-${!expanded ?'down':'up'}`} /></div>*/}
            </div>
        );
    }
    renderTable(){
        const { data=[] ,loading } = this.props;
        const events =[];
        const providers = new Set();
        data.sort((a, b) => b.monthOfYear - a.monthOfYear).forEach(d=>
            _.get(d,'events',[]).forEach(e => {
                events.push(e);
                providers.add(_.get(e,'provider.profile.fullName'));
            })
        )
        events.sort((a, b) => b.startTime - a.startTime);
        const tableProps = {
          columns: tableConfig.columns(Array.from(providers)),
          rowKey: (key,index)=>index,
          loading: { spinning:loading, indicator: <IHLoading/> },
          dataSource: events,
          pagination: true,
          className:'timeSpentTable'
        };
        const noDataElem = (
          <div style={{  paddingTop: '2em',paddingBottom: '2em',display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'HelveticaNeue',color:'#c5c5c5' }}>
              <div style={{ fontSize: '26px',marginTop:20,marginBottom:10}}>
                No Time Spent Records
              </div>
              <div style={{fontSize:'16px',maxWidth:'405px'}}>
                  There is no Time Spent Recorded for this patient.
              </div>
          </div>
        );

        return (
          <div className="patient-task-table">
            <IHSearchTable {...tableProps} noDataElem={noDataElem} />
          </div>
        );
    }

    render(){
      return <div style={{padding:20,backgroundColor:'white'}}>
        {this.renderAllMonthTable()}
        <div style={{ paddingLeft:20 }}>
            {/*{this.renderMonthDropDown()}*/}
            {/*{this.renderYearDropDown()}*/}
        </div>
        {this.renderTable()}
      </div>
    }
}

const mapToDispatch = (dispatch)=>{
    return {
        setYear: year => dispatch(action.setYear(year)),
        setMonth: month=>dispatch(action.setMonth(month))
    }
};
export default connect(null,mapToDispatch)(TimeSpentListComponent);
