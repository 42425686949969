import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, message } from 'antd';

import ReviewSubmitAdditionalOptionsFormItemComponent from '../components/ReviewSubmitAdditionalOptionsFormItemComponent';
import { decodeId, getTextPlaceholdersByRole } from '../helpers';
import useReviewPatientContext from '../helpers/useReviewPatientContext';
import { setRPMReview } from '../apis/RPMReviewAPIs';
import { DataHelper } from '../../../lib/utils';

import '../styles/ReviewSubmitFormContainer.scss';

const ReviewSubmitFormContainer = ({
  patientId,
  primaryDoctor,
  form,
  onSubmit,
}) => {
  const { validateFieldsAndScroll } = form;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const textPlaceholder = getTextPlaceholdersByRole();

  const handleSubmitReview = (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    validateFieldsAndScroll(async (error, values) => {
      if (error) return;

      const params = {
        ...values,
        memberId: decodeId(patientId),
      };

      if (!params.reviewedBy) {
        const { id } = DataHelper.getCurrentUser();
        params.reviewedBy = decodeId(id);
      } else {
        params.reviewedBy = decodeId(values.reviewedBy);
      }

      try {
        const res = await setRPMReview(params);
        if (res && res.code !== 200) {
          throw new Error('code not 200', res.code);
        }
        message.success('This patient has been reviewed successfully');

        if (onSubmit) {
          onSubmit(values, () => setIsSubmitting(false), res);
          return;
        }
      } catch (error) {
        console.error(error);
        message.error('Something went wrong. Please try again later');
      }
      setIsSubmitting(false);
    });
  }

  return (
    <div className="review-submit-form-container">
      <Form
        colon={false}
        onSubmit={handleSubmitReview}
      >
        <div className="review-submit-form-container__additional-options">
          <ReviewSubmitAdditionalOptionsFormItemComponent
            form={form}
            patientId={patientId}
            primaryDoctor={primaryDoctor}
          />
        </div>
        <div className="review-submit-form-container__submit-btn">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {textPlaceholder.submitReviewBtnText}
          </Button>
        </div>
      </Form>
    </div>
  );
};

ReviewSubmitFormContainer.propTypes = {
  patientId: PropTypes.string.isRequired,
  primaryDoctor: PropTypes.object,
};

const FormComponent = Form.create()(ReviewSubmitFormContainer);

export default (props) => {
  const patientContext = useReviewPatientContext() || {};
  return (
    <FormComponent
      patientId={patientContext.id}
      primaryDoctor={_.get(patientContext, 'profile.doctor')}
      {...props}
    />
  );
}
