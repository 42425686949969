import { combineReducers } from 'redux';
import type from '../constants';
import { createAuditLog } from 'libModule/utils/auditLog'
import roleMap from '../../layout/constants/roleMap';
import { decryptRole } from 'libModule/utils';
import markAsUnreadRoles from '../constants/markAsUnreadRoles';
import chatHelpers from '../helper';
import chatFilters from '../helper/chatFilters';

const INITIAL_STATE = {
  PNStatus: {},
  currentUserId: '',
  channels: {},
  userMap: {},
  selectedChannel: '',
  orgTeamMap: {},
  profileHeight:0,
  OrgHasUnReadMessageCount:{},
  OrgHasUnread:{},
  toBottom:false,
  isFullyLoaded: false,
  hasMoreChannels: true,
  patientIdToChannel: {},
  testInputMap: {},
  useChs: false,
  lastMsgTSInOrg:{ },
  translations: {},
  toTag: true
};

const getTeamIdFromCh = (ch)=>{
  const teamId = ch.split('-')[0];
  return teamId;
}

// const updateChatInfoInSubscribe = (channel)=>{
//     //if new message does not belong to selected channel;
//     let counterDiff = 0;
//     teamId = getTeamIdFromCh(channel);
//
//     const msgObj = newMsgObj[channel];
//     let curUpdatedCount = state.channels[channel].counter;
//
//     if (state.selectedChannel && (state.selectedChannel !== channel) && msgObj.isListening) {
//         curUpdatedCount += 1;
//         counterDiff = 1;
//     }
//     //if new message belongs to selected channel;
//     else if (state.selectedChannel === channel) {
//         counterDiff = -curUpdatedCount;
//         counterDiff = counterDiff< 0?  0 : counterDiff;
//         curUpdatedCount = 0;
//     }
//     // if there is no selected Channel and not in init process
//     else if (!state.selectedChannel && msgObj.isListening) {
//         curUpdatedCount += 1;
//         counterDiff = 1;
//     }
//     //if is during inti process
//     else if (!state.selectedChannel && !msgObj.isListening) {
//         curUpdatedCount = msgObj.unreadCountFromOffLine;
//         counterDiff = curUpdatedCount;
//     }
//     //retrieve map to find the org contains team
//     for (let org in orgTeamMap) {
//         if (orgTeamMap[org].indexOf(teamId) != -1) {
//             OrgHasUnReadMessageCount[org] = (counterDiff += OrgHasUnReadMessageCount[org] || 0);
//             newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
//         }
//     }
//     newChannels[channel].counter = curUpdatedCount;
//     newChannels[channel].lastMsgText = msgObj.lastMsgText;
//     newChannels[channel].lastMsgTime = msgObj.lastMsgTime;
//     newChannels[channel].lastMsgSender = msgObj.lastMsgSender;1
// }

const reducer = (state = INITIAL_STATE, action = {}) => {


    const updateChatInfoInit = (isInit= false)=>{
        let newMsgObj = action.payload.msgObj;
        let isFetchingMoreChannels = _.get(action, 'payload.isFetchingMoreChannels', false);
        let allChannels = { ...newMsgObj };
        if (state.useChs) {
          allChannels = { ...state.channels, ...allChannels };

          if(!isFetchingMoreChannels) { // initial load
            for (let org in orgTeamMap) {
              OrgHasUnReadMessageCount[org] = 0;   
            }
          } else {
            // reset count for current org when fetching with more channels
            const sampleChannelName = Object.keys(allChannels)[0];
            teamId = getTeamIdFromCh(sampleChannelName);
            for (let org in orgTeamMap) {
              if (orgTeamMap[org].indexOf(teamId) != -1) {
                OrgHasUnReadMessageCount[org] = 0;
                break;
              }
            }
          }
        }

        const teamCounters = {};

        _.forEach(Object.keys(allChannels),channel=> {

          let counterDiff = 0;
          teamId = getTeamIdFromCh(channel);

          const msgObj = allChannels[channel];
          const shouldIgnoreCounter = msgObj.shouldIgnoreCounter;
          let curUpdatedCount = state.channels[channel].counter;

          if(isInit){
              curUpdatedCount = msgObj.unreadCountFromOffLine || msgObj.counter || 0;
              counterDiff = curUpdatedCount;
          }

          if(!shouldIgnoreCounter) {
            teamCounters[teamId] = (teamCounters[teamId] || 0) + counterDiff;
          }

          newChannels[channel] = {
            ...newChannels[channel],
            ...msgObj,
            counter: curUpdatedCount,
            shouldIgnoreCounter: !!shouldIgnoreCounter,
          };
        });

        //retrieve map to find the org contains team
        for(let teamId of Object.keys(teamCounters)) {
          const teamCounter = teamCounters[teamId] || 0;

          for (let org in orgTeamMap) {
            if (orgTeamMap[org].indexOf(teamId) != -1) {
              OrgHasUnReadMessageCount[org] = (OrgHasUnReadMessageCount[org] || 0) + teamCounter;
              newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
              break;
            }
          }
        }
  }

  const updateChatInfoInSubscribe=(curChanelUnread)=>{

    let updatedCount = curChanelUnread;
    let counterDiff = 0;
    //if is foodlog review sikp
    const currentUserRole = decryptRole();
    
    // if there is ack from other team member;
    if(action.payload.msgObj.entry.type ==='ACK'&&_.get(action,'payload.msgObj.entry.publisher').split('-')[0]=='team'){
      const hasTagMessage = chatHelpers.getTagMessageIndex(newChannels[action.payload.channel]) > -1;
      counterDiff = hasTagMessage ? 0 : (counterDiff - (updatedCount));
      updatedCount = hasTagMessage ? 1 : 0;
    }
    else if(action.payload.msgObj.entry.type ==='ACK'&&_.get(action,'payload.msgObj.entry.publisher').split('-')[0]=='unread'){
      const hasTagMessage = chatHelpers.getTagMessageIndex(newChannels[action.payload.channel]) > -1;
      counterDiff = hasTagMessage ? 0 : 1;
      updatedCount = 1;
    }
    else if(action.payload.msgObj.entry.type ==='ACK' || action.payload.msgObj.entry.subType === 'systemMsg'){

    }
    //if new message does not belong to selected channel;
    else if (state.selectedChannel && (state.selectedChannel !== action.payload.channel) && action.payload.msgObj.isListening) {
        updatedCount += 1;
        counterDiff = 1;
    }
    //if new message belongs to selected channel <=> when chat is open and there is a new message 
    else if (state.selectedChannel === action.payload.channel) {
        chatHelpers.sendACKMessage(state.selectedChannel, action.payload.msgObj);
        counterDiff -= updatedCount;
        updatedCount = 0;

    }
    // if there is no selected Channel and not in init process
    else if (!state.selectedChannel && action.payload.msgObj.isListening) {
        updatedCount += 1;
        counterDiff = 1;
    }
    //if is during inti process
    else if (!state.selectedChannel && !action.payload.msgObj.isListening) {
        updatedCount = action.payload.msgObj.unreadCountFromOffLine;
        counterDiff = updatedCount;

    }
    if (markAsUnreadRoles.includes(roleMap[currentUserRole])){
      newChannels[action.payload.channel].counter = updatedCount;
    }
    else{
      newChannels[action.payload.channel].counter = 0;
      counterDiff = 0;
    }

      //retrieve map to find the org contains team
    for (let org in orgTeamMap) {
        if (orgTeamMap[org].indexOf(teamId) != -1) {
            const orgCount = (counterDiff += (OrgHasUnReadMessageCount[org] || 0));
            OrgHasUnReadMessageCount[org] = orgCount < 0 ? 0 : orgCount;
            newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
        }
    }
    // newChannels[action.payload.channel].counter = updatedCount;
    if(action.payload.msgObj.type!='ACK') {
        newChannels[action.payload.channel].lastMsgText = action.payload.msgObj.lastMsgText;
        newChannels[action.payload.channel].lastMsgTime = action.payload.msgObj.lastMsgTime;
        newChannels[action.payload.channel].lastMsgSender = action.payload.msgObj.lastMsgSender;
    }
  }

  let newChannels = {...state.channels};
  let newPatientIdToChannel = {...state.patientIdToChannel};
  let newUserMap = {...state.userMap};
  let OrgHasUnReadMessageCount = {...state.OrgHasUnReadMessageCount};
  let newOrgHasUnread = {...state.OrgHasUnread};
  const { orgTeamMap } = state;
  const channel  = _.get(action,'payload.channel','-');
  let teamId = _.isString(channel) ? channel.split('-')[0]:'';
  let counterDiff = 0;
  let isbatch = _.get(action,'payload.isbatch',false);
  let isOngoing = _.get(action,'payload.isOngoing',false);
  let tagMessageOffset = _.get(action, 'payload.tagMessageOffset', -1);
  let tagMessageOffsetFrom = _.get(action, 'payload.tagMessageOffsetFrom', null);
  let newTestInputMap = {...state.testInputMap};
  let toBottom = _.get(action,'payload.toBottom',false);

    // console.log('ACTION TYPE:',action.type);
  switch (action.type) {

    //Add Message Method Start
    case type.ADD_MESSAGE:
      // sc-4182 START
      const receivingChannel = newChannels[action.payload.channel];
      const newMsg = action.payload.msgObj;
      if(receivingChannel && newMsg.entry.type !== 'ACK'){
        // check whether the message is rendered: prevent double rendering, and ignore ACK message.type
        let isRendered = receivingChannel.lastMsgTime === newMsg.lastMsgTime;
        isRendered = isRendered && (receivingChannel.lastMsgSender === newMsg.lastMsgSender);
        isRendered = isRendered && (receivingChannel.lastMsgText === newMsg.lastMsgText);
        if(isRendered) return { ...state }; // NO-OP
      }
      // sc-4182 END
      try {
          let curChanelCounter = _.get(receivingChannel, 'counter', 0);
          const pId = action.payload.channel.split('-')[1];

          if (receivingChannel) {
              receivingChannel.isOngoing = true;
              receivingChannel.history.push(newMsg);
              if (newMsg.entry.type === 'ACK' && _.get(newMsg, 'entry.publisher').split('-')[0] == 'team') {
                  if (action.payload.channel.split('-')[1]) {
                    receivingChannel.counter = 0;
                  }
              }
          }
          else {
              const msgObj = {
                  timetoken: newMsg.timetoken,
                  entry: newMsg.entry,
              }
              newChannels[action.payload.channel] = {
                  counter: 0,
                  lastMessageTimestamp: newMsg.timetoken,
                  lastMsgText: newMsg.text,
                  history: _.get(newMsg, 'entry.type') != 'foodReview' ? [msgObj] : receivingChannel.history,
                  patientId: pId,
                  isOngoing: true
              }
          }

          if(pId && !newPatientIdToChannel[pId]) {
            newPatientIdToChannel[pId] = action.payload.channel;
          }

          if (_.get(newMsg, 'entry.type') != 'foodReview') {
            updateChatInfoInSubscribe(curChanelCounter);
          }
      }catch(e){
          console.log('current error',e);
          console.log('channels list',newChannels);
          console.log('current channel',action.payload.channel);
      }
      return ({
          ...state,
          channels:newChannels,
          // prevent scrolling to bottom when there is new message from other channels
          toBottom: _.isEqual(state.selectedChannel, action.payload.channel),
          OrgHasUnReadMessageCount,
          OrgHasUnread: newOrgHasUnread,
          patientIdToChannel: newPatientIdToChannel,
      });
    //End method

    case type.SET_CURRENT_USERID:
      return {
        ...state,
        currentUserId: action.payload
      }
    case type.CHAT_TEXT_INPUT_CHANGE:
      const channel = action.payload.channel;
      newTestInputMap[channel] = action.payload.text;
      return {
        ...state,
        testInputMap: newTestInputMap
      }

    //Add History Method
    case type.ADD_HISTORY:
      const newCh = action.payload.channel;
      const hasMoreMessage = action.payload.hasMoreMessage;
      
      if(isbatch){
          _.forEach(Object.keys(newCh),channel=>{
              const c = newCh[channel];
               // c : { lastMsgTS: String ; messageCount: Int; messages: Array; patientId: String, hasMoreMessage: Boolean,... }
               newChannels[channel] = {
                ..._.get(newChannels, channel, {}),
                counter: 0,
                lastMessageTimestamp: (c.lastMsgTS),
                history: chatFilters.filterMessages(c.messages),
                patientId: c.patientId,
                hasMoreMessage: c.hasMoreMessage,
                isOngoing: newChannels[channel] && newChannels[channel].isOngoing ? newChannels[channel].isOngoing : isOngoing,
                fromTimestamp: c.fromTimestamp || "0",
                teamUnread: c.teamUnread
              };
            
            if(c.patientId)
              newPatientIdToChannel[ c.patientId ] = channel;

            if(!newChannels[channel].tagMessageOffsetFrom || c.tagMessageOffsetFrom <= newChannels[channel].tagMessageOffsetFrom) {
              newChannels[channel].tagMessageOffset = c.tagMessageOffset;
              newChannels[channel].tagMessageOffsetFrom = (_.first(c.messages) || {}).timetoken; // messages are sorted older->newer
            }
          })
      }else {
          // const prevChannelCounter = _.get(newChannels, `${newCh}.counter`) || 0;
          newChannels[newCh] = {
            ..._.get(newChannels, newCh, {}),
            history: chatFilters.filterMessages(action.payload.messages),
            patientId: action.payload.patientId,
            hasMoreMessage,
            fromTimestamp: action.payload.fromTimestamp || "0",
          };
          // if there is new timestamp, no more message
          if(!action.payload.timestamp) newChannels[newCh].lastMessageTimestamp = action.payload.timestamp;
          if(action.payload.patientId)
            newPatientIdToChannel[ action.payload.patientId ] = newCh;
            
          if(!newChannels[newCh].tagMessageOffsetFrom || tagMessageOffsetFrom <= newChannels[newCh].tagMessageOffsetFrom) {
            newChannels[newCh].tagMessageOffset = tagMessageOffset;
            newChannels[newCh].tagMessageOffsetFrom = (_.first(action.payload.messages) || {}).timetoken; // messages are sorted older->newer
          }
      }
      return ({
        ...state,
        toBottom: _.isEqual(state.selectedChannel, newCh) && toBottom,
        // prevent moving to tag when there are both new message from notification AND tag
        toTag: _.isEqual(state.selectedChannel, newCh) && !action.payload.fromNotification,
        channels:newChannels,
        patientIdToChannel:newPatientIdToChannel,
        OrgHasUnReadMessageCount,
        OrgHasUnread: newOrgHasUnread
      });
    //End Add History Method

    case type.CLEAR_HISTORY:
      return {
        ...state,
        chatHistory:action.payload.messages
      };
    case type.SELECT_CHANNEL:
      // if(newChannels[action.payload]) {
      //    newChannels[action.payload].counter = 0;
      // }
      return ({
        ...state,
        selectedChannel:action.payload,
        channels: newChannels
      });
    case type.ADD_CHANNELS:
      return ({
        ...state,
        channels:action.payload});
    case type.ADD_USER_MAP:

        const uuid = _.get(action,'payload.newUser.uuid');
        const updatedUser = _.get(action,'payload.newUser');
        const hasAvatarError = _.get(action,'payload.avatarError');
        if(hasAvatarError || !newUserMap[uuid]) {
            newUserMap[uuid] = updatedUser;
        }

      return ({
        ...state,
        userMap : newUserMap
      })

    case type.SET_USER_MAP:
      newUserMap = Object.keys(state.userMap).length > 0 ? {...state.userMap, ...action.payload} : {...action.payload};
      return {
        ...state,
        userMap: newUserMap
      };
    case type.VERIFY_AND_ADD_CHANNEL:
        const channelToAdd = action.payload.channelName;
        const patientId = action.payload.patientId;
        const patientObj = action.payload.patientObj;
        const newChannelInfo = {
            lastMessageTimestamp:new Date()*1e4,
            history:[],
            patientId,
            counter:0
        }

        if(!newChannels[channelToAdd]) {
            newChannels[channelToAdd] = newChannelInfo;
            // add new user map, prevent not display patient name in Chat page
            if(!newUserMap[patientId]) newUserMap[patientId] = patientObj;
        }
        return ({
          ...state,
          channels: newChannels,
          userMap: newUserMap,
        });

    //Update Channel Info Method
    case type.UPDATE_CHANNEL_INFO:
        // let updatedCount = state.channels[action.payload.channel].counter;
        if(isbatch){
          updateChatInfoInit(isbatch);
        }
        else if(action.payload.msgObj.type != 'ACK'){
          newChannels[action.payload.channel] = {
            ...newChannels[action.payload.channel],
            ...action.payload.msgObj,
          }
        }

        return ({
          ...state,
          channels:newChannels,
          OrgHasUnReadMessageCount,
          OrgHasUnread: newOrgHasUnread
        });

    case type.UPDATE_UNREAD_COUNTER:
      const channelInfoFromLastMessage = action.payload.channelInfoFromLastMessage;
      const { unreadCountFromOffLine, lastMsg, shouldIgnoreCounter, ...lastMessageInfo } = channelInfoFromLastMessage;
      if (state.selectedChannel === action.payload.channel) { // channel is open
        // send ACK immediately if applicable
        chatHelpers.sendACKMessage(state.selectedChannel, channelInfoFromLastMessage);
        const prevChannelCounter = newChannels[state.selectedChannel].counter || 0;
        counterDiff = channelInfoFromLastMessage.hasTagMessage ?
                        prevChannelCounter > 1 ? (1 - prevChannelCounter) : 0
                      : (0 - prevChannelCounter);
      } else {
        const currentCounter = _.get(newChannels, `${action.payload.channel}.counter`) || 0;
        // should be counted to org already when previous counter > 0
        counterDiff =  unreadCountFromOffLine - currentCounter;
        
        const prevShouldIgnoreCounter = _.get(newChannels, `${action.payload.channel}.shouldIgnoreCounter`);
        if(!prevShouldIgnoreCounter && shouldIgnoreCounter) {
          // prev=false && new=true => remove count from org with current counterupdateChatInfoInit)
          counterDiff = 0 - currentCounter;
        } else if (prevShouldIgnoreCounter && !shouldIgnoreCounter) {
          // prev=true && new=false => didn't count current counter to org (refer to 
          counterDiff = currentCounter;
        }
      }
      // update channel info
      newChannels[action.payload.channel] = {
        ...newChannels[action.payload.channel],
        isOngoing: true,
        counter: unreadCountFromOffLine,
        shouldIgnoreCounter,
        ..._.omit(lastMessageInfo, 'hasTagMessage')
      };

      // update org counter
      teamId = getTeamIdFromCh(action.payload.channel);
      for(let org in orgTeamMap){

        if(orgTeamMap[org].indexOf(teamId)!=-1){
          const orgCount = counterDiff + (OrgHasUnReadMessageCount[org] || 0);
          OrgHasUnReadMessageCount[org] = orgCount < 0 ? 0 : orgCount;
          newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] !== 0;
          break;
        }
      }

      return {...state,
        channels:newChannels,
        OrgHasUnReadMessageCount,
        OrgHasUnread:newOrgHasUnread
      };

    case type.UPDATE_PN_STATUS:
      return {...state,
      PNStatus:action.payload};
    case type.UPDATE_API:
      return {...state,
        useChs:action.payload
      };

    case type.SET_ORG_TEAM_MAP:
      return {...state,
      orgTeamMap:action.payload};

    case type.SET_PROFILE_DIV_HEIGHT:
      return {...state,
        profileHeight:action.payload
      };

    case type.SET_TO_BOTTOM:
      return{
            ...state,
            toBottom:action.payload
        }
    case type.SET_LAST_MSG_ORG:
      return {
        ...state,
          lastMsgTSInOrg:{
            [action.payload.org]:{
                timestamp:action.payload.timestamp,
                pageNumber:action.payload.pageNumber
            }
          }
     }
    case type.SET_IS_FULLY_LOADED:
      return {
          ...state,
          isFullyLoaded:action.payload
      }
    case type.SET_HAS_MORE_CHANNELS:
      return {
        ...state,
        hasMoreChannels: action.payload
    }

    case type.SET_CHAT_TRANSLATION:
      const { translation, translationTimeToken } = action.payload;
      if(!state.selectedChannel || !translationTimeToken)
        return {...state};

      const newTranslations = {...state.translations};
      if(!newTranslations[state.selectedChannel]) {
        newTranslations[state.selectedChannel] = {
          [translationTimeToken]: translation
        };
      } else {
        newTranslations[state.selectedChannel][translationTimeToken] = translation;
      }
      return {...state, translations: newTranslations};

    case type.UPDATE_CHAT_TAG: // should only be called when chat opens
      if(!state.selectedChannel)
        return {...state};
      
      const { tagObj } = action.payload;
      let newHistory = [...(newChannels[state.selectedChannel] || {}).history];
      const messageIdx = _.findIndex(newHistory, { messageId: tagObj.messageid });

      if(messageIdx === -1)
        return {...state};

      const message = newHistory[messageIdx];
      newHistory[messageIdx] = {
        ...message,
        tag: { ...message.tag, ...tagObj }
      };

      newChannels[state.selectedChannel] = {
        ...newChannels[state.selectedChannel],
        history: newHistory
      };

      const hasTagMessage = chatHelpers.getTagMessageIndex(newChannels[state.selectedChannel]) > -1;
      const prevChannelCounter = newChannels[state.selectedChannel].counter || 0;
      newChannels[state.selectedChannel].counter = hasTagMessage ? 1 : 0;

      if(tagObj.taggedby && tagObj.taggedto) { // new tag
        // tagObj.taggedby === tagObj.taggedto => self-tag => equivalent to unread
        counterDiff = prevChannelCounter === 0 && tagObj.taggedby === tagObj.taggedto ? 1 : 0;
      } else { // mark a tag
        counterDiff = hasTagMessage ? 
                        (prevChannelCounter === 0 && !tagObj.read) ? 1 : 0
                      : tagObj.read ? -1 : 1;
      }

      teamId = getTeamIdFromCh(state.selectedChannel);

      for (let org in orgTeamMap) {
        if (orgTeamMap[org].indexOf(teamId) != -1) {
          const newOrgHasUnReadMessageCount = counterDiff + (OrgHasUnReadMessageCount[org] || 0);
          OrgHasUnReadMessageCount[org] = newOrgHasUnReadMessageCount < 0 ? 0 : newOrgHasUnReadMessageCount;
          newOrgHasUnread[org] = OrgHasUnReadMessageCount[org] != 0;
          break;
        }
      }

      return {
        ...state,
        channels: newChannels,
        OrgHasUnReadMessageCount,
        OrgHasUnread: newOrgHasUnread
      };

    default:
      return state
  }   
};

export default {
  chat: combineReducers({
    main: reducer
  })
};
