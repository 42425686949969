
import React, { Component } from 'react'
import { Row,Col,Divider} from 'antd';
import { getTasks } from '../helper/index';

export default class TotalSummarySimpleComponent extends Component {

    getNotificationStatus = () => {
        return sessionStorage.getItem('notificationFreq');
    }

    renderTasks = (reports)=>{
        const remove = !this.getNotificationStatus().includes('NOTIFY_ALWAYS') ? ['MESSAGE_UNREAD'] : [];
        const taskArray = getTasks(reports, remove).filter(x => x.count !== 0);
        // this is to avoid flex making big size boxes
        // for (let i = 0; i < taskArray.length % 4; i++) {
        //     taskArray.push({});
        // }

        return (
            <Row className={'totalTasks'}>
                <div className={'taskContainer'}>
                    {_.map(taskArray, ({ src, title, count }, i) => (
                        // <Col span={12} key={i}>
                            <div className={'task-wrapper'}>
                                <img className={'task-image'} src={src}/>
                                <strong className={'total'}>{count}</strong>
                                <span className={'type'}>{title}</span>
                            </div>
                        // </Col>
                    ))}
                </div>
            </Row>
        );
    }

    renderTotalAnnBtn = ()=>{
        const { total } = this.props.reports;
        return <Row className={'totalAndBtn'}>
                <Col span={24} className={'total'}>{total}</Col>
                <Col span={24} className={'subtitle'}>Patient Cases</Col>
               </Row>
    }

    renderBody = ()=>{
        const { reports } = this.props;
        return <Row className={'body'}>
                <Col span={7}>{ this.renderTotalAnnBtn() }</Col>
                <Col span={1}><Divider type="vertical" style={{height:158, marginLeft:-40}}/></Col>
                <Col span={16}>{this.renderTasks(reports.task)}</Col>
               </Row>
    }

    render() {
        const {renderBody, props} = this;
        const { reports } = props;
        return <Row className={'totalCasesWrapper'}>
                    <Col span={24} style={{ height:'90%' }}>
                        <React.Fragment>
                            {_.get(reports, 'total', 0) === 0
                                ?   <Col span={24} className={'no-unpinned-case'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                    <div style={{ padding: 20, marginRight: 20 }}>
                                        <img src={'/image/workstation/done-patient-case.png'} />
                                    </div>
                                    <div>
                                        <p>There is 0 patient case currently.</p>
                                        <p>Good job!</p>
                                    </div>
                                </Col>
                                : renderBody()

                            }
                        </React.Fragment>
                    </Col>
                </Row>
    }
}

