// Device for admin program template
export const APDevice = `
  model
  type
`

// Device for enrolled program , added status needed to render list to return devices
export const Device = `
  address
  model
  type
  other
  status
`

export const Address = `
  type
  streetName
  streetNumber
  floor
  unit
  city
  state
  country {
    code
    description
  }
  postCode
`
export const Identification = `
  type {
    code
    description
  }
  value
  MRN
`
export const Phone = `
  type
  countryCode
  number
  verified
  canUseForLogin
  private
`
export const Email = `
  address,
  primary,
  verified
`

export const Organization = `
  id
  name
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
`

export const PaginationInfo = `
  total
  lastPage
`

export const Team = `
  id
  name
  description
  healthCondition
  consentProviderName
  members{
    role {
      category
      name
    }
    primary
    user{
      id
      allRoles{
        organization {
          id
        }
        name
      }
      profile{
        firstName
        lastName
        fullName
      }
    }
  }
`;

export const PhoneReportInfos = `
  phoneReportInfos {
    LastUsedAt
    mobileInfo {
      appVersion
      latestAppVersion
    }
    authorityInfo {
      cameraStatus
      microphoneStatus
    }
  }
`;

export const EducationSchema = `
  education {
    selfReflection
    discussion
    appReview
  }
`;

export const ClinicGoalSchema = `
  condition
  value
  billable
  conditionEnum
  goalEnum
  meetingStatus
  statusUpdatedAt
`;

export const PatientHiatusSchema = `
  id
  memberId
  status
  vitalsMonitoringHiatus
  visitHiatus
  hiatusUnableToEngage
  reason
  note
  createdAt
  updatedAt
  startDate
  endDate
  timezone
`;