import { DIABETES_ICD_CODES } from '../../../lib/constants';

export const checkIfPatientIsDiabetic = (icdCodes = []) => {
  const codes = icdCodes.map(c => c.split('::')[1]);
  for (let i = 0; i < codes.length; i++) {
    const upperCaseCode = (codes[i] || '').toUpperCase();
    for (let j = 0; j < DIABETES_ICD_CODES.length; j++) {
      const { code, startWith } = DIABETES_ICD_CODES[j];
      if (startWith) {
        if (upperCaseCode.startsWith(code)) {
          return true;
        }
      } else {
        if (upperCaseCode === code) {
          return true;
        }
      }
    }
  }
  return false;
};