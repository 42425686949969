import React from 'react';
import { graphql } from 'react-apollo'
import UserPhoneList from '../query/UserPhoneList';
import { compose  } from 'redux';
import MobilePhoneComponent from '../components/MobilePhoneInfoComponent';
const sortOrder = 'LASTUSED_DESC';


class MobilePhoneContainer extends React.Component{
    render(){
        const { userPhoneList } = this.props;

        return <div>
            <MobilePhoneComponent userPhoneList={userPhoneList}/>
        </div>
    }
}

const withData = graphql(UserPhoneList,{
    options:(props)=>{
        const { userId } = props;
        return {
            variables:{
                userId:userId
            }
        }
    },
    props:({data,props})=>{
        const { loading,userPhoneList } = data;
        if(!loading) {
            return {
                userPhoneList
            };
        }
    }
})


export default compose(withData)(MobilePhoneContainer);
