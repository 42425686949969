import React from 'react';
import PropTypes from 'prop-types';
import WorkListComponent from '../component/workListComponent';
import { graphql, compose } from 'react-apollo'
import singleVisit from '../../graphql/singleVisit';
import {latestMeasurementBeforeVisit} from 'graphqlModule/resultList';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import getClinicGoalTemplate from '../../graphql/getClinicGoalTemplates';
import { matchContionsWithOptions } from '../../careplanNew/component/helper/goalHelper';
import { modalAction } from 'modulesAll/common/actions'
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import orgScheduleQuery from 'modulesAll/graphql/orgSchedule';
import { branch } from 'recompose';
import { buildVisitListMini } from '../../graphql/visitListMini';
import { withComplexityWithMemberFunc } from '../../patient/main/API';
import { loadingMessage } from '../../visitsPage/constants';

const WORK_LIST_TIMER = 'WORK_LIST_TIMER';

const errorMessage =  <div className='ant-message' style={{ position: 'fixed', top: 80 }}><span><div className='ant-message-notice'><div className='ant-message-notice-content'><div class="ant-message-custom-content ant-message-error"><i aria-label="icon: close-circle" class="anticon anticon-close-circle" style={{ marginLeft: 4 }}><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></i><span>No visit data found. Please try again.</span></div></div></div></span></div>;

const  WorkListContainer  = class extends React.Component {
    render() {
      const { 
        onCancel, timerName, visitLoading, visitNetworkStatus, ...restProps 
      } = this.props;

      const loading = (visitLoading && visitNetworkStatus === 1);

      return loading 
          ? loadingMessage
          : (
            !this.props.visit ?
            errorMessage
            :
            <React.Fragment>
              {timerName ? null : <TimerClaimedContainer patientId={this.props.memberId || _.get(this.props, 'visit.member.id')} id={WORK_LIST_TIMER} />}
              <WorkListComponent
                {...this.props.visit} 
                {...restProps} 
                onModalCancel={onCancel}
                timerName={timerName || WORK_LIST_TIMER}
              />
            </React.Fragment>
          );
        
    }
};

WorkListContainer.propTypes = {
  modalVisible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  modalProps: PropTypes.object
};

const withData =  graphql(singleVisit,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables: {
                id
            },
            fetchPolicy:'network-only',
        }
    },
    props:({data})=>{
        const { loading, getVisit, refetch, networkStatus }  = data;
        if(loading){
            return { visitLoading: loading, visitNetworkStatus: networkStatus }
        }
        return {
            visit: getVisit,
            refetch
        }
    }
})

const withLastMeasurementsData = graphql(latestMeasurementBeforeVisit, {
    options: (ownProps) => {
        const {appointmentAt, memberId} = ownProps;
        const fromDate = appointmentAt - 1000 * 3600 * 8;
        const  variables = {
            filters: {
              type: ['BP'],
              fromDate: fromDate,
              toDate: appointmentAt,
              memberId: memberId || _.get(ownProps, 'visit.member.id')
            },
            sort: { field: 'DATE', direction: 'DESC'},
            page: 1,
            count: 1
          }
          return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
          };
    },
    props:( {data} )=>{
        const { loading, resultList, refetch, networkStatus }  = data;
        if(loading){
            return { lastMeasurementLoading: loading, lastMeasurementNetworkStatus: networkStatus }
        }
        return {
            lastMeasurement: _.get(resultList, 'data') || [],
            refetchLastMeasurement: refetch
        }
    }
})

const nonBillableData = graphql(getClinicGoalTemplate, {
    options: (ownProps) => {
      const billableConditions = _.get(ownProps, 'visit.member.profile.billableHealthConditions', []);
      let billables = _.map(billableConditions, o => {return o.code});
      let codes=[];
      let healthConditions = _.get(ownProps, 'visit.member.profile.healthConditions');
      _.map(healthConditions, con => {
        const array = _.split(con, '::');
        if( array && array[1] && !_.includes(billables, array[1])){
          codes.push(array[1]);
        }
      })
      return {
        variables: {
          codes
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: ({ data }) => {
      const { loading, getClinicGoalTemplates, networkStatus, refetch } = data
      if(loading) {
        return { nonBillableLoading: loading, nonBillableNetworkStatus: networkStatus }
      }

      let result = matchContionsWithOptions(getClinicGoalTemplates);
      return {
        nonBillableGoalOptions: result.options,
        nonBillable: result.conditions,
        nonBillableDiseases: result.chronicDiseases,
        refetchNonBillableDiseases: refetch,
      }
    }
  })

const billableData = graphql(getClinicGoalTemplate, {
    options:(ownProps) =>{
        const billableConditions = _.get(ownProps, 'visit.member.profile.billableHealthConditions', []);
        let billables = [];
        _.map(billableConditions, o => {
          billables.push(o.code);
        });
        return {
        variables: {
            codes: billables
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
        }
    },
    props: ({  data }) => {
        const { loading, getClinicGoalTemplates, networkStatus, refetch } = data
        if(loading){
            return { billableLoading: loading, billableNetworkStatus: networkStatus }
        }

        let result = matchContionsWithOptions(getClinicGoalTemplates);

        return {
            billableGoalOptions: result.options,
            billable: result.conditions,
            billableDiseases: result.chronicDiseases,
            billableVitals: result.billableVitals,
            refetchBillable: refetch,
        }
    }
})

const orgScheduleInfo = graphql(orgScheduleQuery, {
  options: () => {
      const { id } = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole.organization;
      return {
          variables: {
              id
          },
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true
      }
  },
  props: ({ data }) => {
      const { loading, refetch } = data;
      if (loading) {
          return  {
              loading,
          };
      }
      return {
          orgSchedules: _.get(data, 'organization.orgSchedules', []) || [],
          refetchOrgSchedules: refetch
      };
  }
});

const checkIfFirstMNTVisit = graphql(buildVisitListMini([], false), {
  options: (ownProps) => {
    const memberId = _.get(ownProps, 'memberId') || _.get(ownProps, 'visit.member.id');
    return {
      variables: {
        count: 1,
        memberId,
        appointmentTo: _.get(ownProps, 'appointmentAt') - 1000,
        type: ['MNT']
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, error, visitList } = data;
    if(error)
      console.error(error);

    return { 
      checkIfFirstMNTVisitLoading: loading,
      isFirstMNTVisit: !_.get(visitList, 'data.0.id')
    };
  }
});

const mapDispatch = (dispatch, ownProps) => {
    const { timerName } = ownProps;
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
        timerClaim: mapDispatchToTimerActions(dispatch, timerName || WORK_LIST_TIMER, ownProps.memberId || _.get(ownProps, 'visit.member.id')),
    }
}
export default compose(connect(null,mapDispatch),
    withData,
    withLastMeasurementsData,
    billableData,
    nonBillableData,
    orgScheduleInfo,
    branch(
      (ownProps) => {
        const visitType = _.get(ownProps, 'visit.type');
        return visitType && visitType === 'MNT';
      },
      compose(checkIfFirstMNTVisit)
    ),
    withComplexityWithMemberFunc(ownProps => _.get(ownProps, 'memberId') || _.get(ownProps, 'visit.member.id')),
)(WorkListContainer);
