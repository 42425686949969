import gql from 'graphql-tag'

export default gql `
  mutation deleteBroadcast (
    $id: EID!
  ) {
    deleteBroadcast (
      id: $id
    )
  }
`
