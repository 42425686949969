import {
    React, BaseComponent, ND, _
} from './util'

const IHUpload = class extends BaseComponent{

    render(){

        return <ND.Upload className="IH-Upload" {...this.props} />
    }
}

IHUpload.Dragger = class extends BaseComponent{
    render(){
        return <ND.Upload.Dragger className="IH-Upload-Dragger" {...this.props} />
    }
}

export default IHUpload
