import React from 'react';
import { Col, Icon, Row, Input, Checkbox } from 'antd';
import HealthConditionPicker from '../../patient/AdditionalInfo/Components/HealthConditionPickerComponent/HealthConditionPicker';

const KEY = {
  HEALTH_CONDITIONS: 'healthConditions',
  BILLABLE_HEALTH_CONDITIONS: 'billableHealthConditions',
};

const IcdCodeSelect = ({ onChange, form, icdCodesInitialValue, billableIcdCodesInitialValue, disabled }) => {
  const selectedIcdCodes = form.getFieldValue(KEY.HEALTH_CONDITIONS) || icdCodesInitialValue;
  const selectedBillableIcdCodes = form.getFieldValue(KEY.BILLABLE_HEALTH_CONDITIONS) || billableIcdCodesInitialValue;

  const getCurrentValue = () => [...(form.getFieldValue(KEY.HEALTH_CONDITIONS) || [])];
  const getCurrentBillableValue = () => [...(form.getFieldValue(KEY.BILLABLE_HEALTH_CONDITIONS) || [])];

  const handleSelect = (icdCode, clearFieldFn) => {
    const newValues = getCurrentValue();
    if (_.findIndex(newValues, v => v === icdCode) > -1) return;
    newValues.push(icdCode);
    form.setFieldsValue({ 
      [KEY.HEALTH_CONDITIONS]: newValues 
    });
    if (clearFieldFn) clearFieldFn();
  }

  const handleRemove = (index) => () => {
    const newValues = getCurrentValue();
    const value = newValues[index];
    const code = _.split(value, '::')[1];
    const newBillableValues = getCurrentBillableValue();
    const billableIndex = _.findIndex(newBillableValues, { code });
    newValues.splice(index, 1);
    newBillableValues.splice(billableIndex, 1);
    form.setFieldsValue({ 
      [KEY.HEALTH_CONDITIONS]: newValues,
      [KEY.BILLABLE_HEALTH_CONDITIONS]: newBillableValues,
    });
    if (onChange) onChange(newBillableValues);
  }

  // index of healthConditions
  const toggleBillableCheckbox = (index, isBillable) => () => {
    const newValues = getCurrentValue();
    const [description, code] = _.split(newValues[index], '::');
    const newBillableValues = getCurrentBillableValue();
    if (!isBillable) { // check
      newBillableValues.push({ code, description });
    } else { // uncheck
      const billableIndex = _.findIndex(newBillableValues, { code });
      newBillableValues.splice(billableIndex, 1);
    }
    form.setFieldsValue({ 
      [KEY.BILLABLE_HEALTH_CONDITIONS]: newBillableValues,
    });
    if (onChange) onChange(newBillableValues);
  }

  return (
    <div>
      <Row style={{ marginBottom: 20 }}>
        <Col span={14}>
          <div style={{ width: '100%', border: '1px solid #dbdbdb', borderRadius: 4 }}>
            <HealthConditionPicker 
              disabled={disabled} 
              addHealthCondition={handleSelect} 
              clearOnSelect 
              noInitialValue
            />
            {
              form.getFieldDecorator(KEY.HEALTH_CONDITIONS, {
                initialValue: icdCodesInitialValue,
              })(<div style={{ display: 'none' }}></div>)
            }
            {
              form.getFieldDecorator(KEY.BILLABLE_HEALTH_CONDITIONS, {
                initialValue: billableIcdCodesInitialValue,
              })(<div style={{ display: 'none' }}></div>)
            }
          </div>
        </Col>
        {/* <Col span={9} offset={1}>
          <a>{I18N.get('referA
            ndEnroll.addBillableConditionRelatedToVitals')}</a>
        </Col> */}
      </Row>
      <div className="billable-conditions-list">
        <HealthConditionsRows 
          healthConditions={selectedIcdCodes}
          billableHealthConditions={selectedBillableIcdCodes} 
          toggleBillableCheckbox={toggleBillableCheckbox}
          handleRemove={handleRemove} 
          disabled={disabled} 
        />
      </div>
    </div>
  );
}

class HealthConditionsRows extends React.Component {
  render() {
    const { 
      healthConditions, 
      billableHealthConditions, 
      toggleBillableCheckbox, 
      handleRemove, 
      disabled 
    } = this.props;
    return (
      <div>
        {
          (healthConditions && healthConditions.length > 0)
          && (
            <Row className="billable-conditions-item row-header">
              <Col span={21} className="billable-conditions-code-and-description">
                <Col span={2} className="billable-conditions-code">Code</Col>
                <Col className="billable-conditions-description">Condition</Col>
              </Col>
              <Col className="billableSelect" />
              <Col span={1} offset={1} className="billable-conditions-remove" />
            </Row>
          )
        }
        {_.map(healthConditions, (v, i) => {
          const [description, code] = _.split(v, '::');
          const isBillable = _.findIndex(billableHealthConditions, { code }) > -1;
          return (
            <Row className="billable-conditions-item" key={code}>
              <Col span={21} className="billable-conditions-code-and-description">
                <Col span={2} className="billable-conditions-code">{code}</Col>
                <Col className="billable-conditions-description">{description}</Col>
              </Col>
              <Col>
                <Checkbox
                  checked={isBillable}
                  onClick={toggleBillableCheckbox(i, isBillable)}
                  disabled={disabled}
                />
              </Col>
              {!disabled && <Col span={1} offset={1} className="billable-conditions-remove"><Icon type="close-circle" theme="filled" className="grey-svg" onClick={handleRemove(i)} /></Col>}
            </Row>
          );
        })}
      </div>
    );
  }
}

export default IcdCodeSelect;