import {Modal} from 'antd';
import React, {Component} from 'react';
import SearchInputComponent from "../components/SearchInputComponent";
import InputVisitFormComponent from "../components/InputVisitFormComponent";
import CreatePatientFormComponent from "../components/CreatePatientFormComponent";
import '../CSS/style.scss';
import {message} from "../../../../../package/IHComponent";
import {connect} from "react-redux";
import { GQLHelper } from 'lib/utils';
import CURRENT_COMPONENT from "../constants/CurrentComponent";
import { helpers as dataHelper } from "../helper";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { API } from '../../visit/query/index';
import PostItContainerWrapper from "./PostItContainerWrapper";
import CreateAppointmentWithCalendarContainer from '../../remoteEnrollment/component/CreateAppointmentWithCalendarContainer';
import CreatedPatientSuccessComponent from '../../referAndEnroll/component/CreatedPatientSuccessComponent';
import I18N from '../../I18N';
import {checkIsInRole} from "../../../lib/helpers/role-helpers";
class CreateVisitInDashboardContainer extends Component {
    constructor(props){
        super(props);
        const { defaultPatient,defaultValue } = props
        this.state = {
            value: defaultValue || CURRENT_COMPONENT.SEARCH_COMPONENT,
            // value:CURRENT_COMPONENT.CREATE_SUCCEED,
            patient: defaultPatient|| null,
            visitInfo: null,
        };
        this.changeToInputVisit = this.changeToInputVisit.bind(this);
        this.changeToSearch = this.changeToSearch.bind(this);
        this.changeToCreatePatient = this.changeToCreatePatient.bind(this);
        this.createPatient = this.createPatient.bind(this);
        this.createVisit = this.createVisit.bind(this);
    }

    changeToInputVisit(patient){
            this.setState({
                value: CURRENT_COMPONENT.INPUT_VISIT_COMPONENT,
                patient
            });
    }

    changeToSearch(){
        this.setState({
            value: CURRENT_COMPONENT.SEARCH_COMPONENT,
            patient: null
        });
    }

    changeToCreatePatient(){
        this.setState({
            value: CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT,
            patient: null
        });
        Mixpanel.track('clicked', 'Create a new patient', 'on create a visit popup', {});
    }

    createPatient(values){
        const mutatedValue = dataHelper.getMutateVariable(values);
        const variables = dataHelper.trimAllValues(mutatedValue,'',mutatedValue);
            API.createPatient(variables)
            .then(res => {
                let patient = {};
                const user = res.data.createUser;
                user.profile.birthday = values.DOB;
                user.profile.gender = values.gender;
                user.isPrivate = typeof(values.isPrivateNumber) === 'undefined' ? false : values.isPrivateNumber
                user.canUseForLogin = (values.canUseLogin === '' || values.canUseForLogin === 'true') ? true : false;
                let phone = [];
                if(values.mobilePhone != null && values.mobilePhone.length != 0) {
                    let mobile = {
                        type : 'MOBILE',
                        countryCode : '1',
                        canUseForLogin : user.canUseForLogin,
                        number: values.mobilePhone,
                        private: user.isPrivate
                    }
                    phone.push(mobile);
                }
                if(values.homePhone != null && values.homePhone.length != 0) {
                    let home = {
                        type : 'HOME',
                        countryCode : '1',
                        canUseForLogin : user.canUseForLogin,
                        number: values.homePhone,
                        private: user.isPrivate
                    }
                    phone.push(home);
                }
                user.phone = phone;
                patient.enrolledPrograms = [];
                patient.user = user;
                this.changeStateAfterSucceed(patient);
            })
            .catch(e => {
                this.props.openErrorModal(GQLHelper.formatError(e));
            })
    }

    changeStateAfterSucceed = (patient)=>{
        const isMAorMD = checkIsInRole(['MA','Doctor']);
        const nextValue = CURRENT_COMPONENT.INPUT_VISIT_COMPONENT;
            // isMAorMD ? CURRENT_COMPONENT.CREATE_SUCCEED :  CURRENT_COMPONENT.INPUT_VISIT_COMPONENT
        this.setState({
            value:nextValue,
            patient
        });
    }

    createVisit(variables){
        API.createVisit(variables)
            .then(res => {
                message.success(`Appointment Created`);
                this.props.onCancel(true);
            })
            .catch(error => {
                this.props.openErrorModal(GQLHelper.formatError(error));
            })
    }

    renderCreateVisit = ()=>{
        const patientId = _.get(this,'state.patient.user.id');
        const { patient } = this.state;
        return <div className='createVisitWithPostIt'>
                <InputVisitFormComponent key={patientId}
                                         patient={this.state.patient}
                                         backToSearch={this.changeToSearch}
                                         createVisit={this.createVisit}
                                         editStatus={false}
                                         patient={patient}
                                         isRemoteEnrollment={this.props.isRemoteEnrollment}
                                         curTab={this.props.curTab}
                />
                <PostItContainerWrapper patientId={patientId}/>
               </div>
        // return (<InputVisitFormComponent patient={this.state.patient}
        //                                  backToSearch={this.changeToSearch}
        //                                  createVisit={this.createVisit}
        //                                  editStatus={false}
        //                                  curTab={this.props.curTab}
        // />)
    }

    renderSelectComponent(){
        const  patient= this.state.patient;
        const  onCancel = this.props.closeModal;
        const crossClinic = this.props.crossClinic;
        if(this.state.value === CURRENT_COMPONENT.SEARCH_COMPONENT){
            return (<SearchInputComponent selectPatient={this.changeToInputVisit}
                                          crossClinic={crossClinic}
                                          onCreate={this.changeToCreatePatient}
                   />)
        }
        // if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
        //     return this.renderCreateVisit();
        //     // return (<InputVisitFormComponent patient={this.state.patient}
        //     //                                  backToSearch={this.changeToSearch}
        //     //                                  createVisit={this.createVisit}
        //     //                                  editStatus={false}
        //     //                                  curTab={this.props.curTab}
        //     //         />)
        // }
        if(this.state.value === CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT){
            return (<CreatePatientFormComponent onCreatePatient={this.createPatient}/>)
        }

        if(this.state.value === CURRENT_COMPONENT.CREATE_SUCCEED) {
            return <CreatedPatientSuccessComponent patient={patient} closeModal={onCancel}/>
        }
    }

    getTitle(){
        if(this.state.value === CURRENT_COMPONENT.CREATE_PATIENT_COMPONENT){
            return "Create a Patient"
        } else {
            return I18N.get('visit.createTitle');
        }
    }

    render() {
        Mixpanel.track('showed', 'create a visit popup', '', {});
        if(this.state.value === CURRENT_COMPONENT.INPUT_VISIT_COMPONENT){
            return (
                <CreateAppointmentWithCalendarContainer 
                    patientId={this.state.patient.user.id}
                    showModalOnMount
                    onCancel={() => this.props.onCancel(true)}
                />
            );
        }
        return (
            <Modal width={'fit-content'}
                   style={{ minWidth:500 }}
                   title={this.getTitle()}
                   visible={true}
                   footer={null}
                   onCancel={() => this.props.onCancel()}
                   maskClosable={false}
            >
                {this.renderSelectComponent()}
            </Modal>
        );
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}
export default connect(null,mapToDispatch)(CreateVisitInDashboardContainer);
