import {
  React
} from 'ihcomponent'
import {createContainer} from 'libModule/utils'
import UploadForm from 'modulesAll/common/components/UploadForm'
import action from '../actions'

const ComponentWithData = UploadForm

const Container = createContainer(ComponentWithData, ({patient})=>{
  const uploadFile = patient.device.uploadFile
  return {
    ...uploadFile
  }
}, (dispatch)=>{
  return {
    dispatch,
    uploadEndCallback : (file)=>{
      dispatch(action.setUploadFile(file))
    }
  }
});

Container.displayName = 'patient/deviceTab/containers/UploadForm';

export default Container;
