import React from 'react'
import BaseComponent from '../BaseComponent'
import _ from 'lodash'
import { Radio } from 'antd';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
  render(){
    let p = _.omit(this.props, [])
    const option = p.option
    delete p.option
    const RadioType = p.type === 'radioButton' ? RadioButton : Radio
    const className = (p.className || '') + ' ' + 'IH-Radio'
    return (
      <RadioGroup {...p} className={className} >
      {
        _.map(option, (item, key)=>{
          const itemProps = {
            key,
            value: item.value
          }
          if (item.disabled) {
            itemProps.disabled = true
          }
          return <RadioType {...itemProps}>{item.name}</RadioType>
        })
      }
      </RadioGroup>
    )
  }
}

Component.propTypes = {
  //{name:'A', value:'a'}
  option : PropTypes.array.isRequired
}

export default Component
