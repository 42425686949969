import {
    React, IHModal, IHSearchTable, IHButton, _
} from 'ihcomponent'
import {createContainer, goPath} from 'libModule/utils'
import actions from '../actions/SearchPatientResultModal'
import {modalAction} from 'modulesAll/common/actions'
import UserClass from 'modulesAll/graphql/class/User'
import addNewPatientType from '../constants/AddNewPatient'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import moment from 'moment'

const Component = class extends React.Component{
  static displayName = 'patientList/registerNew/containers/SearchPatientResultModal'

  componentWillMount() {
    this.props.dispatch(actions.show(false))
  }

  render(){
    if(!this.props.show || !this.props.list || this.props.list.length < 1){
      _.delay(this.renderNoDataModal.bind(this), 200)
      return null
    }
    const modalProps = this.getModalProps()
    return <IHModal {...modalProps} />
  }

  getModalProps(){
    const {show} = this.props
    return {
      title : '',
      showModal : show,
      size : 'lg',
      showHeaderCloseButton : false,
      className : 'vsm-patient-register-search-patient-modal',
      onHide : ()=>{
        this.props.dispatch(actions.show(false))
      },
      body : this.renderBody()
    }
  }

  renderBody(){
    const { list } = this.props
    const tableProps = {
      title : 'Patients ('+list.length+')',
      columns : [
        {
          title : 'Name',
          key : 'name',
          render(t, doc){
            return doc._get('profile.fullName')
          }
        },
        {
          title : I18N.get('keywords.NRIC/FIN'),
          key : 'pid',
          render(t, doc){
            return doc.format('nric')
          }
        },
        {
          title : 'DOB',
          key : 'DOB',
          render(t, doc){
            return moment(doc._get('profile.birthday')).format('MMM-DD-YYYY')
          }
        },
        {
          title : 'Gender',
          key : 'gender',
          render(t, doc){
            return doc.format('gender')
          }
        }
      ],
      dataSource: _.map(list, (d, index) => new UserClass(Object.assign({}, d, {id: index}))),
      pagination : {
        pageSize : 10
      },
      onRow : (props)=>{
        return {
          onClick: () => {
            const patientNRIC = _.get(props, 'variables.identification[0].value')
            const patientName = _.get(props, 'variables.profile.fullName')
            createAuditLog({action: I18N.get('auditLog.provider.patient.selectImportPatient'), details: props, patientNRIC, patientName })

            // set tab to manual input
            goPath('/patients/register/manual_input')

            // init form data
            const initDataAndFieldsValue = actions.initPatientManualInputForm(props)
            this.props.dispatch({
              type: addNewPatientType.INIT_DATA,
              initData: initDataAndFieldsValue.initData
            })
            this.props.dispatch({
              type: addNewPatientType.UPDATE_FORM,
              fieldsValue: initDataAndFieldsValue.fieldsValue
            })
          }
        }
      }
    }
    return (
      <div className="v-content">
          <p className="v-tlt">Select Patient to Register</p>
          <IHSearchTable {...tableProps} />
      </div>
    )
  }

  renderNoDataModal(){
    if(!this.props.show || this.props.list.length > 0) return null

    const p1 = {
      label : 'Close',
      size : 'large',
      style : {
        marginRight : '10px'
      },
      onClick : ()=>{
        this.props.dispatch(modalAction.closeModal())
      }
    }
    const footer = (
      <div>
        <IHButton {...p1} />
      </div>
    )
    const param = {
      size : 'sm',
      title : 'No Patient Found',
      content : 'We were not able to find a patient match. Please check to see if the patient information is correct',
      footer
    }

    this.props.dispatch(modalAction.openModal(param.content, param))
  }
}

const ComponentWithData = Component

export default createContainer(ComponentWithData, ({patient})=>{
  return {
    ...patient.register.SearchPatientResultModal
  }
})
