import React from 'react';
import { Modal } from 'antd';
import AddNewPhoneComponent from './AddNewPhoneComponent';
import UnassignComponent from './UnassignComponent';
import { compose } from 'react-apollo/index';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import Client from 'libModule/gqlClient';
import unassignLoanDevice from '../../../patient/loanDevices/mutate/unassignLoanDevice';

import './style.scss';

class PhoneAssignmentComponent extends React.Component {
  renderIfNoDevice = () => {
    return <AddNewPhoneComponent {...this.props} refetchLoanDevice={this.refetchLoanDevice} setHasDevice={this.setHasDevice} />
  }

  renderUnassign = () => {
    const { user, loanDeviceData, onUnassign, onCancel, openErrorModal } = this.props;

    const onClick = () => {
      Client.mutate({
        mutation: unassignLoanDevice,
        variables: {
          memberId: _.get(user, 'id'),
          deviceId: _.get(loanDeviceData, 'deviceId')
        }
      }).then(res => {
        if (onUnassign) onUnassign(res);
      }).catch(err => {
        openErrorModal(err);
      });
    };

    return <UnassignComponent onClick={onClick} onCancel={onCancel} />;
  }

  render() {
    const { loanDeviceData, onCancel } = this.props;

    return <Modal
      className='device-assignment-popup phone-assignment'
      width={469}
      visible={true}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
    >
      {
        !loanDeviceData ?
          <React.Fragment>
            <div className='header'>Assign Patient a Loaner Phone</div>
            <div className='sub-header'>To assign device, please confirm the device ID below.</div>
            {this.renderIfNoDevice()}
          </React.Fragment>
          : this.renderUnassign()
      }
    </Modal>
  }
}

const mapToDispatch = (dispatch) => {
  return {
    openErrorModal: err => dispatch(openErrorModal(GQLHelper.formatError(err))),
  }
};

export default compose(connect(null, mapToDispatch))(PhoneAssignmentComponent);

