import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { ND, IHSearchTable, IHButton, _ } from 'ihcomponent';
import I18N from 'modulesAll/I18N';
import constant from '../../../smartAlert/constant/index.js';
import $ from 'jquery';
import { renderVitalType, formatDateMM24Date } from 'modulesAll/utils/helpers/task';
import PatientSmartAlertNoteContainer from '../containers/PatientSmartAlertNoteContainer.js';
import { Tag,Tooltip,Icon } from 'antd';
import { doTypesOverlap } from 'graphql';
import helper from '../../../smartAlert/helper';
const alertTaskMap = I18N.get('smartAlert.alertType');
const { alertLevelColorMap, filterType, alertLevel } = constant;

const Button = ND.Button

const ProviderSmartAlertListTable = (props) => {
    const { smartAlertTab, loading, onTableChange, page, patientId,
            careName } = props;
    const patientProfileTabWidth = $('.patient-profile-tabs').width();
    const variables = props.variables;
    let curPage = variables.page;
    const style = {margin: '20px 0 20px 0'}
    const alertLevelOpts = [
      { value: 'LEVEL_ONE', text: 'Level 1'},
      { value: 'LEVEL_TWO', text: 'Level 2'},
      { value: 'LEVEL_THREE', text: 'Level 3'},
      { value: 'LEVEL_FOUR', text: 'Level 4'},
      { value: 'LEVEL_FIVE', text: 'Level 5'}
    ]

    const smartData = smartAlertTab.data;
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no data available</p>
      </div>
    );
    const renderVisitWidow = (content,visitWindow)=>{
        if(visitWindow.length) return <div>{content}<Tooltip title={helper.parseVisitWindow(visitWindow)}><Icon type='info-circle'/></Tooltip></div>
        return content
    }
    const columns = [
      {
        title: (patientProfileTabWidth <= 800) ? 'Date':'Date / Time',
        key: 'createdAt',
        dataIndex: 'createdAt',
        width: '18%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: (item, row) => formatDateMM24Date(item)
      },
      {
        title: 'Alert Level',
        key: 'alertLevel',
        dataIndex: 'alertLevel',
        width: '18%',
        filters: alertLevelOpts,
        filterMultiple: true,
        onFilter: (item, record) => record.alertLevel.includes(item),
        render: (item, row) => {
          const{ color, backgroundColor} = alertLevelColorMap[item] || {};
          const style ={
            tagStyle:{
                display: 'flex',
                height: 28,
                alignItems: 'center',
                border: '1px',
                borderRadius: 15,
                marginBottom: 15,
                marginTop: 15,
                width: '80px',
                paddingLeft: '10px',
                paddingRight: '10px',
                cursor:'pointer'
            }
          };
          let alertLevelText = alertTaskMap[item];

          return {
            props: {
              className: `alertLevelCell ${alertLevel}`
            },
            children: <Tag style={{color: '#2D3238', backgroundColor: backgroundColor, ...style.tagStyle}}>
                          <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: color, display: 'inline-block', marginRight: 10 }} />
                          <p style={{fontSize:'12px'}}>{alertLevelText}</p>
                      </Tag>
          }
        }
      },
      {
        title: 'Content',
        key: 'content',
        width: '18%',
        dataIndex: 'status',
        // render: (item, doc) =>renderVisitWidow(doc.display,doc.visitWindow)
        render: (item, doc) => (
          <React.Fragment>
            <span>{doc.display}</span>
            {helper.renderSmartAlertInfo(doc)}
          </React.Fragment>
        )
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'providerNotes',
        render: (notes, doc) => {
          if(notes == null || notes.length == 0) {
            return '--';
          } else {
            return (_.last(notes) || {}).content;
          }
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '15%',
        render: (item, doc) => {
          if(doc.status === 'DONE') {
            if (doc.closeBySystem) {
              return 'Closed by system';
            }
            if (doc.closeBy) {
              return 'Closed by ' + doc.closeBy.profile.fullName
            } else {
              return 'Closed by' + 'Error'
            }
          } else {
            return (
              <PatientSmartAlertNoteContainer
                smartData={smartData} memberId={patientId} fullName={props.patientName} row={item}
                curPage={curPage} tableProps={tableProps} identification={props.identification} />
            )
          }
        }
      }
    ];

    const onChange = (p, f, s) => {
      const newFilter = { ...f };
      const newAlertLevel = f.alertLevel;
      const newPage = { ...p };
      return onTableChange(newPage, newFilter, s)
  };

    const tableProps = {
      columns,
      loading,
      rowKey: 'id',
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize', 10),
        total: _.get(smartAlertTab, 'pageInfo.total', 0)
      },
      alertLevel: props.filter.alertLevel,
      onChange: onChange,
      dataSource: smartData,
      title: `Smart Alerts`,
    };

    return (
      <div style={style}>
        <div className="patient-task-table">
          <IHSearchTable {...tableProps} noDataElem={noDataElem} onChange={onChange}/>
        </div>
      </div>
    );
};

ProviderSmartAlertListTable.propTypes = {
  smartAlertTab: PropTypes.object,
};

export default ProviderSmartAlertListTable;
