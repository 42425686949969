import { combineReducers } from 'redux'
import Common from 'patientModule/enrolledProgramInfo/AssessmentAlert/reducers/CommonReducer'
import AlertForm from 'patientModule/enrolledProgramInfo/AssessmentAlert/reducers/AlertFormReducer'
import AlertList from 'patientModule/enrolledProgramInfo/AssessmentAlert/reducers/AlertListReducer'

const assessmentAlert = combineReducers({
  Common,
  AlertForm,
  AlertList
})

export default assessmentAlert
