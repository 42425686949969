import gql from 'graphql-tag';

export default gql`query patientAssignee($id:EID){
  user(id:$id) {
    assignees{
      assignedToRD{
        id
        profile{
          fullName
        }
      }
      assignedToCA {
        id
        profile{
          fullName
        }
      }
    }
  }
}`;