import React from 'react';
import PropTypes from 'prop-types';

// @ params
// elementHeight: number - (auto-calculate) to get cut off view
// numRows: number - to give num of desired visible rows
// elements: [] - to generate element nodes
// elementContainer: func - to wrap given element nodes
// renderEl: func - to render elements
// childNodesPath: string - path to desired childNodes for shadowDivRef
// customViewMoreEl: func - to render 'view more/less' button
// useDefaultViewMore: boolean - to overwrite customViewMoreEl
// idSuffix: string - to determine each view more component in the same view

class ViewMoreEffect extends React.Component {
  constructor(props){
    super(props);
    const {
      childNodesPath = 'childNodes', idSuffix
    } = props;

    this.wrapperId = 'vm-wrapper-id';
    this.shadowId = 'vm-shadow-id';
    this.VIEW_MORE_BTN_KEY = 'vm-button-key';

    if(idSuffix) {
      this.wrapperId = `${this.wrapperId}-${idSuffix}`;
      this.shadowId = `${this.shadowId}-${idSuffix}`;
      this.VIEW_MORE_BTN_KEY = `${this.VIEW_MORE_BTN_KEY}-${idSuffix}`;
    }

    this.state = {
      canExpand: false,
      outOfRectIndex: -1,
      isExpanded: false
    };  
    this._isMounted = false;
    this.resizeObserver = undefined;

    this.checkForOutboundElements = _.debounce(() => {
      const { outOfRectIndex, isExpanded, canExpand } = this.state;
      let newOutOfRectIndex = -1, wrapperRect;

      const wrapperRef = document.getElementById(this.wrapperId);
      const shadowRef = document.getElementById(this.shadowId);

      if(!isExpanded && wrapperRef && shadowRef) {  
        wrapperRect = wrapperRef.getBoundingClientRect();
        const childNodes = _.get(shadowRef, `${childNodesPath}`, []);
        for(let i = 0; i < childNodes.length; i++){
          if(childNodes[i].getBoundingClientRect){
            const rect = childNodes[i].getBoundingClientRect();
            if(rect.bottom > wrapperRect.bottom) {
              newOutOfRectIndex = i -1; 
              break;
            }
          }
        }
      }
  
      const canExpandNext = newOutOfRectIndex > -1;
      if(this._isMounted && (canExpand !== canExpandNext || outOfRectIndex !== newOutOfRectIndex)) {
        this.setState({ 
          canExpand: canExpandNext, 
          outOfRectIndex: newOutOfRectIndex 
        });
      }
    }, 200); 
  }

  componentDidMount = () => {
    this._isMounted = true;
    const wrapperRef = document.getElementById(this.wrapperId);
    try {
      this.resizeObserver = new ResizeObserver(() => this.checkForOutboundElements());
      this.resizeObserver.observe(wrapperRef);
    } catch(err) {
      console.error(err);
      this.resizeObserver = false; // browser not supported
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.resizeObserver = undefined;  
  };

  componentDidUpdate = (prevProps) => {
    if(!_.isEqual(prevProps.elements, this.props.elements)){
      this.checkForOutboundElements();
    }
  }

  setIsViewMore = (isExpanded, cb) => this.setState({ isExpanded }, () => { if(cb) cb(); });

  toggleViewMore = (cb) => this.setIsViewMore(!this.state.isExpanded, cb);

  render(){
    const { isExpanded, canExpand, outOfRectIndex } = this.state;
    const { 
      numRows = 3, elements = [], elementHeight = 24,
      elementContainer, renderEl, customViewMoreEl, useDefaultViewMore 
    } = this.props;

    const genChildren = nodes => elementContainer ? elementContainer(nodes) : nodes;

    const genDefaultViewMore = (text, style = {}) => <a 
      id={this.VIEW_MORE_BTN_KEY} 
      key={this.VIEW_MORE_BTN_KEY} 
      style={style}
      onClick={() => this.toggleViewMore()}
    >
      {text}
    </a>;

    const genCustomViewMore = (expandedProps = {}) => customViewMoreEl({ 
      key: this.VIEW_MORE_BTN_KEY,
      wrapperId: this.wrapperId,
      shadowId: this.shadowId,
      elements, 
      renderEl, 
      canExpand,
      isExpanded, 
      onClick: this.toggleViewMore,
      ...expandedProps
    });

    let // for shadow
        elNodes = _.map(elements, renderEl), 
        // for visible
        vElNodes, vChildren, cutOffNodes, viewMoreNode, viewLessNode;

    if(canExpand) {
      // nodes within bounding rect
      vElNodes = _.map(_.slice(elements, 0, outOfRectIndex), renderEl);
      // nodes out of bounding rect
      cutOffNodes = _.map(_.slice(elements, outOfRectIndex), renderEl);
      if(customViewMoreEl && !useDefaultViewMore) {
        // add custom 'viewmore' element
        viewMoreNode = genCustomViewMore({ vElNodes, cutOffNodes });
      } else {
        viewMoreNode = genDefaultViewMore('view more');
      }
      vElNodes.push(viewMoreNode);
      vChildren = genChildren(vElNodes);
    } 

    if(isExpanded) {
      if(customViewMoreEl && !useDefaultViewMore) {
        viewLessNode = genCustomViewMore();
      } else {
        viewLessNode = genDefaultViewMore('view less', {display: 'block'});
      }
      elNodes.push(viewLessNode);
    }

    const children = genChildren(elNodes);

    const mainClass = `vm-visible-section ${canExpand ? 'vm-can-expand' : ''} ${isExpanded ? 'vm-expanded' : ''}`;

    return <div style={{ display: 'flex' }}>
      <div
        id={this.wrapperId}
        style={{
          maxHeight: !isExpanded ? elementHeight * numRows : 'fit-content',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {
          !isExpanded &&
          <div 
            id={this.shadowId}
            style={{ display: 'block', visibility: 'hidden' }}
          >
            {/* shadow DOM, to calc for the effect */}
            { children }
          </div>
        }
        <div 
          className={mainClass} 
          style={!isExpanded 
            ? { position: 'absolute', top: 0, left: 0, maxWidth: '100%' } 
            : {}
          }
        >
          {/* visible DOM */}
          { 
            _.isNil(this.resizeObserver) ?
              ''
            :
              (this.resizeObserver && canExpand && !isExpanded) ? 
                (vChildren || '')
                :
                (children || '')
          }
        </div>
      </div>
    </div>
  }
};

ViewMoreEffect.propTypes = {
  elementHeight: PropTypes.number,
  numRows: PropTypes.number,
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  elementContainer: PropTypes.func,
  renderEl: PropTypes.func.isRequired,
  childNodesPath: PropTypes.string,
  customViewMoreEl: PropTypes.any,
  useDefaultViewMore: PropTypes.bool,
  idSuffix: PropTypes.string
};

export default ViewMoreEffect;