import React from 'react';
import {Form} from 'antd'; 
import VitalMonitoring from '../component/GoalStatement/VitalMonitoring';
import goalHelper from '../component/helper/goalHelper';
import '../style/goalStatement.styles.scss';
import { CGMHelperServices } from '../../CGM/helpers';
import { makeCGMContextConsumer } from '../../CGM/context/makeCGMContextConsumer';
// ONLY used in patient profile
let VitalMonitoringContainer = class extends React.Component{

    constructor(props) {
        super(props);
    }


    render(){
      return (
      <div className='VitalMonitoringContainer' id='vital-monitoring-container'>
          <VitalMonitoring {...this.props}/>
      </div>)
    }
}

VitalMonitoringContainer = Form.create({
  onFieldsChange: (props) => {
    const { onChange } = props;
    if(typeof onChange ==='function'){
      let editEnrolledProgramVar = goalHelper.getMutateVariableGoals(props);
      let shouldEditEnrolledProgram = true;
      onChange({
        shouldEditEnrolledProgram,
        editEnrolledProgramVar,
        props,
        cb: () => {
          const {
            form,
            patientId,
            cgmContextValue,
          } = props;
          const selectedVitals = form.getFieldValue('selectedVitals');
          if (selectedVitals.includes(CGMHelperServices.brandFieldName)) {
            CGMHelperServices.saveBrandValue(form, patientId)
            .then((res) => {
              if (res && res.code === 200) {
                cgmContextValue.refetch();
              }
            });
          }
        }
      });
    }
  }
})(VitalMonitoringContainer);

VitalMonitoringContainer.title= 'Vitals Monitoring';
VitalMonitoringContainer.displayName = 'VitalMonitoringContainer';
export default makeCGMContextConsumer(VitalMonitoringContainer);
