import { constants } from 'libModule/utils'

export default constants('chat', 'main', [
  'ADD_MESSAGE',
  'INIT_UNREAD_COUNTER',
  'UPDATE_CHANNEL_INFO',
  'UPDATE_UNREAD_COUNTER',
  'SET_CURRENT_USERID',
  'ADD_HISTORY',
  'CLEAR_HISTORY',
  'SELECT_CHANNEL',
  'ADD_CHANNELS',
  'ADD_USER_MAP',
  'SET_USER_MAP',
  'ADD_CHANNELS_TO_DISPLAY',
  'VERIFY_AND_ADD_CHANNEL',
  'UPDATE_PN_STATUS',
  'SET_ORG_TEAM_MAP',
  'SET_PROFILE_DIV_HEIGHT',
  'SET_TO_BOTTOM',
  'SET_IS_FULLY_LOADED',
  'CHAT_TEXT_INPUT_CHANGE',
  'UPDATE_API',
  'SET_LOADED',
  'SET_LAST_MSG_ORG',
  'SET_HAS_MORE_CHANNELS',
  'SET_CHAT_TRANSLATION',
  'UPDATE_CHAT_TAG'
]);

export const DEFAULT_PAGE_SIZE = '15';