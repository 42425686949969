/**
 * Created by zizhangai on 1/23/17.
 */
export default {
  // patient task table
  'Status': 'Status',
  'Symbol': 'Symbol',
  'Vital': 'Vital',
  'Schedule': 'Schedule',
  'Taken': 'Taken',
  'Programme': 'Programme',
  ' (M = Manual)': ' (M = Manual)',
  'Reading ': 'Reading ',
  'Name': 'Name',
  'Time': 'Time',
  // patient view program table
  'Health Condition': 'Health Condition',
  'Programme Started At': 'Programme Started At',
  'Programme Duration': 'Programme Duration',
  'Days left in Programme': 'Days left in Programme',
  'Actions': 'Actions',
  // vitals table
  'Patient Notes': 'Patient Notes',
  //all provider notes table
  'Category': 'Category',
  'Content': 'Content',
  'Created By': 'Created By',
  'Result': 'Result',
  'Created On': 'Created On',
  'Note Status': 'Note Status'
}
