import React, { useState, useMemo } from 'react';
import { Button, Modal, Pagination } from 'antd';
import SearchDropdownComponent from './SearchDropdownComponent';
import { ArticleOptionComponent } from './ArticleOptionComponent';
import { LanguageAbbreviation } from '../../../../lib/constants';
import useSearchArticles, { PAGE_SIZE } from '../../helpers/useSearchArticles';

import './ArticlesSearchComponent.scss';

const ArticleSearchComponent = ({
  preferredLanguages,
  disabled,
  articleCategories,
  patientArticles,
  isLoading: _isLoading,
  submitButtonText = 'Send',
  onSubmit,
}) => {
  const [selectedArticles, setSelectedArticles] = useState([]);
  const {
    searchValue,
    category,
    page,
    onChange,
    articlesDataSource,
    isLoadingArticlesDataSource,
  } = useSearchArticles({
    sendOnMount: !disabled,
    initialLanguages: preferredLanguages,
  });

  const isLoading = !!_isLoading || isLoadingArticlesDataSource;
  const {
    data,
    totalSize,
  } = articlesDataSource || {};
  const selectedArticleIds = useMemo(() => _.map(selectedArticles, 'id'), [selectedArticles]);

  const handleSearchInputChange = (searchValue) => {
    onChange({ changedSearchValue: searchValue, changedPage: 0 });
  };

  const handleCategoryChange = (categoryId) => {
    onChange({ changedCategory: categoryId, changedPage: 0 });
  };

  const handleChangePage = (page) => {
    onChange({ changedPage: page });
  };

  const handleSetSelectedArticles = (articleId) => {
    const article = _.find(data, { id: articleId });
    const newSelectedArticles = [...selectedArticles, article];
    setSelectedArticles(newSelectedArticles);
  };

  const handleUnsetSelectedArticles = (articleId) => {
    const articleIndex = _.findIndex(selectedArticles, { id: articleId });
    if (articleIndex === -1) return;
    const newSelectedArticles = [...selectedArticles];
    newSelectedArticles.splice(articleIndex, 1);
    setSelectedArticles(newSelectedArticles);
  };

  const handleSubmit = () => {
    setSelectedArticles([]);
    onSubmit && onSubmit(selectedArticles);
  };

  return (
    <div className="articles-search-component">
      <div className="articles-search-component__label">
        Search articles
      </div>
      <SearchDropdownComponent
        categories={(
          _.map(articleCategories, ({ id, name }) => ({
            label: name,
            value: id,
          }))
        )}
        selectedCategory={category}
        onCategoryChange={handleCategoryChange}
        selectedKeys={selectedArticleIds}
        onSelectedKeysChange={handleSetSelectedArticles}
        onDeselectedKeysChange={handleUnsetSelectedArticles}
        value={searchValue}
        onChange={handleSearchInputChange}
        data={data}
        optRenderer={(v) => {
          const sent = patientArticles[v.id] || {};
          return (
            <ArticleOptionComponent
              key={v.id}
              label={v.name}
              link={v.url}
              hasVideo={v.hasVideo}
              isNew={!!v.isNew}
              sentOn={sent.updatedAt}
              language={v.language}
              isSelected={_.includes(selectedArticleIds, v.id)}
              excludeLanguageTags={[LanguageAbbreviation.en]}
            />
          );
        }}
        pagination={(
          <Pagination 
            onChange={handleChangePage}
            current={page}
            total={totalSize}
            pageSize={PAGE_SIZE}
            size="small"
          />
        )}
        submitButton={(
          <Button
            id="submit-article-button"
            type="primary"
            className="submit-button"
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit();
            }}
            disabled={isLoading}
          >
            {submitButtonText}
          </Button>
        )}
        disabled={disabled}
        isLoading={isLoading}
        containerClassName="intervention-education-body-wrapper"
      />
    </div>
  );
};

export default ArticleSearchComponent;
