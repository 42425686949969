import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Card,message } from 'antd';
import ReferAndEnrollForm from './ReferAndEnrollForm';
import I18N from '../../I18N';
import { 
  wantToJoinProgram, deviceAccessability, exclusionReason, exclusionReasonOther, 
  smartPhoneSkills as smartPhoneSkillsField, anotherCCMProvider, PROGRAM_ELIGIBILITY_KEYS,
} from '../fields/ProgramEligibility';
import FormRenderer from './FormRenderer';
import Overlay from './Overlay';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { upsertPatientReferralMutation } from '../API/mutation';
import { 
  getBinaryRadioValue, getSmartphoneOrgPermissions, getValue, getBPEligibility, getAnotherCCMProviderOrgPermissions, 
  getRadioValue, onlyHasHLD, checkBpAndRpmOnly, getDisabledAndRecommendations, getPatientConditions,
} from '../helper';
import { 
  getAnotherCCMFieldValue, getConditionsValue, getDeviceAccessibilityValue, 
  getExclusionReason, getExclusionOtherReason, 
  getProgramsValue, getReferredByLastName, getVitalsValue, getWouldJoinValue, getTechLevel , checkReferred,
  getBillableIcdCodesValue
} from '../helper/initialValueHelper';
import { ELIGIBLE_PROGRAMS_ENUM, EXCLUSION_REASONS_MAP, VITAL_TYPE_ENUM } from '../constant';
import { SMARTPHONE_SKILL_ENUM, DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import VitalsAndProgramsComponent from './VitalsAndProgramsComponent';
import PostAPI from '../../postIt/API/index';
import { getCurrentOrg } from '../../workStation/helper/index';
import { GQLHelper } from '../../../lib/utils';
import renderIneligibleOverlay from '../helper/renderIneligibleOverlay';

const EnrollmentProgramEligibility = ({ patientReferral, user, form, onSubmitDone, userRole, closeModal, disabled, hideDeviceAccessibility, openErrorModal, goNextStep }) => {
  const isReferred = checkReferred(patientReferral);
  const { validateFields, getFieldsValue } = form;
  const fieldsValues = getFieldsValue();
  const initialValues = useMemo(() => {
    const diagnosisSource = getValue(patientReferral.diagnosisSource) || DIAGNOSIS_SOURCE.CONDITION;
    const conditions = getConditionsValue(patientReferral,user) || [];
    const billableIcdCodes = getBillableIcdCodesValue(patientReferral);
    const patientConditions = getPatientConditions(diagnosisSource, conditions, billableIcdCodes);
    const hasHldOnly = onlyHasHLD(patientConditions);
    const hasNoConditionFromIcdCode = diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE && _.isEmpty(patientConditions);
    const isConfirmed = !hasHldOnly && !_.isEmpty(patientConditions);
    const isDeviceAccessible = getDeviceAccessibilityValue(patientReferral);
    const oldEligiblePrograms = getProgramsValue(patientReferral) || [];
    const oldVitals = getVitalsValue(patientReferral) || [];
    const notSelectedPrograms = _.keys(ELIGIBLE_PROGRAMS_ENUM).filter(v => !oldEligiblePrograms.includes(v));
    const notSelectedVitals = _.keys(VITAL_TYPE_ENUM).filter(v => !oldVitals.includes(v));
    const wouldJoin = getWouldJoinValue(patientReferral);
    const smartPhoneSkills = getTechLevel(patientReferral);
    const isBPEligible = getBPEligibility(patientConditions);
    const isAnotherCcmProviderSelected = getAnotherCCMFieldValue(patientReferral);
    const { vitals, eligiblePrograms, disabledPrograms, disabledVitals } = getDisabledAndRecommendations({
      userRole,
      isReferred: checkReferred(patientReferral),
      isDeviceAccessible,
      isBPEligible,
      smartPhoneSkills,
      eligiblePrograms: oldEligiblePrograms,
      vitals: oldVitals,
      conditions: patientConditions,
      hasAnotherCCMProvider: isAnotherCcmProviderSelected,
      notSelectedPrograms,
      notSelectedVitals,
    });
    return {
      diagnosisSource,
      conditions,
      vitals: oldVitals === null ? vitals : oldVitals,
      notSelectedVitals,
      notSelectedPrograms,
      eligiblePrograms: oldEligiblePrograms === null ? eligiblePrograms : oldEligiblePrograms,
      disabledPrograms,
      disabledVitals,
      wouldJoin: _.isNil(wouldJoin) ? true : wouldJoin,
      isDeviceAccessible,
      isAnotherCcmProviderSelected,
      smartPhoneSkills,
      exclusionReason: getExclusionReason(user),
      exclusionOtherReason: getExclusionOtherReason(patientReferral),
      hasHldOnly,
      hasNoConditionFromIcdCode,
      patientConditions,
      isConfirmed,
      isBPEligible,
    };
  }, []);
  const [disabledPrograms, setDisabledPrograms] = useState(initialValues.disabledPrograms);
  const [disabledVitals, setDisabledVitals] = useState(initialValues.disabledVitals);
  const { hasHldOnly, hasNoConditionFromIcdCode, isBPEligible, isConfirmed, patientConditions } = initialValues;
  const selectedVitals = getValue(fieldsValues.recommendVitalsList, initialValues.vitals);
  const selectedPrograms = getValue(fieldsValues.eligiblePrograms, initialValues.eligiblePrograms);
  const isJoiningProgram = getValue(getBinaryRadioValue(fieldsValues.joinProgram), initialValues.wouldJoin);
  const isDeviceAccessible = getValue(getBinaryRadioValue(fieldsValues.deviceAccessability), initialValues.isDeviceAccessible);
  const selectedExclusionReason = getValue(fieldsValues.exclusionReason, initialValues.exclusionReason);
  const selectedOtherExclusionReason = getValue(fieldsValues.exclusionReasonOther, initialValues.exclusionOtherReason);
  const orgHasAnotherCCMProvider = getAnotherCCMProviderOrgPermissions();
  const hasAnotherCCMProvider = getValue(getBinaryRadioValue(fieldsValues.anotherCCMProvider), initialValues.isAnotherCcmProviderSelected);
  const smartPhoneSkills = getValue(fieldsValues.smartPhoneSkills, initialValues.smartPhoneSkills); 
  const loanerPhoneConfig = getSmartphoneOrgPermissions();
  const bpAndRpmOnly = isJoiningProgram && isDeviceAccessible === false && checkBpAndRpmOnly(smartPhoneSkills, isBPEligible);
  const showAnotherCCMAlert = isDeviceAccessible && orgHasAnotherCCMProvider && hasAnotherCCMProvider;

  const showNotEligibleSmartPhoneOverlay = () => {
    if (isJoiningProgram) {
      if (isDeviceAccessible !== false) return false;
      if (bpAndRpmOnly) return false;
      if (loanerPhoneConfig.supportLoanerPhone && !_.isNil(smartPhoneSkills) && smartPhoneSkills !== SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE) return false;
      return true;
    }
    return false;
  }

  const showButton = () => {
    if (!isConfirmed || _.isEmpty(selectedVitals) || _.isEmpty(selectedPrograms)) return false;
    if (isJoiningProgram) {
      if (isDeviceAccessible) return true;
      if (bpAndRpmOnly) return true;
      if (loanerPhoneConfig.supportLoanerPhone && !_.isNil(smartPhoneSkills) && smartPhoneSkills !== SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE) return true;
    } else {
      if (selectedExclusionReason && (selectedExclusionReason !== 'OTHER' || selectedOtherExclusionReason)) return true;
    }
    return false;
  }

  const onDeviceAccessibilityChange = (evt) => {
    const value = !!evt.target.value;
    const smartPhoneSkills = form.getFieldValue('smartPhoneSkills');
    const oldEligiblePrograms = form.getFieldValue('eligiblePrograms');
    const oldVitals = form.getFieldValue('recommendVitalsList');

    const { disabledVitals, disabledPrograms, eligiblePrograms, vitals } = getDisabledAndRecommendations({
      userRole,
      isReferred,
      isDeviceAccessible: value,
      isBPEligible,
      smartPhoneSkills,
      eligiblePrograms: oldEligiblePrograms,
      vitals: oldVitals,
      conditions: patientConditions,
      hasAnotherCCMProvider,
      notSelectedPrograms: initialValues.notSelectedPrograms,
      notSelectedVitals: initialValues.notSelectedVitals,
    });

    setDisabledPrograms(disabledPrograms);
    setDisabledVitals(disabledVitals);
    form.setFieldsValue({ 
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: vitals,
    });
  }

  const onTechLevelChange = (evt) => {
    const smartPhoneSkills = evt.target.value;
    const isDeviceAccessible = form.getFieldValue('deviceAccessability');
    const oldEligiblePrograms = form.getFieldValue('eligiblePrograms');
    const oldVitals = form.getFieldValue('recommendVitalsList');

    const { disabledVitals, disabledPrograms, eligiblePrograms, vitals } = getDisabledAndRecommendations({
      userRole,
      isReferred,
      isDeviceAccessible,
      isBPEligible,
      smartPhoneSkills,
      eligiblePrograms: oldEligiblePrograms,
      vitals: oldVitals,
      conditions: patientConditions,
      hasAnotherCCMProvider,
      notSelectedPrograms: initialValues.notSelectedPrograms,
      notSelectedVitals: initialValues.notSelectedVitals,
    });

    setDisabledPrograms(disabledPrograms);
    setDisabledVitals(disabledVitals);
    form.setFieldsValue({ 
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: vitals,
    });
  }

  const onVitalsChange = (newVitals) => {
    const selectedSmartPhoneSkill = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS);
    if(selectedSmartPhoneSkill === SMARTPHONE_SKILL_ENUM.SMART_PHONE_NOT_PREFERRED && !_.includes(newVitals, 'BP')) {
      form.setFieldsValue({
        [PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS]: null
      });
    }
  }

  const getFields = () => {
    const fields = [
      wantToJoinProgram({ initialValue: getRadioValue(initialValues.wouldJoin) }),
    ];
    if (isJoiningProgram) {
      if (!hideDeviceAccessibility) {
        fields.push(deviceAccessability({ onChange: onDeviceAccessibilityChange, initialValue: getRadioValue(initialValues.isDeviceAccessible) }));
      }
      if (isDeviceAccessible && orgHasAnotherCCMProvider) {
        fields.push(anotherCCMProvider({ initialValue: getRadioValue(initialValues.isAnotherCcmProviderSelected) }));
      } else if (isDeviceAccessible === false && loanerPhoneConfig.supportLoanerPhone) {
        const selectedValues = {
          recommendedVitals: form.getFieldValue('recommendVitalsList') || getVitalsValue(patientReferral) || []
        };
        fields.push(smartPhoneSkillsField({ onChange: onTechLevelChange, initialValue: initialValues.smartPhoneSkills, selectedValues }));
      }
    } else if (isJoiningProgram === false) {
      fields.push(exclusionReason({ initialValue: initialValues.exclusionReason }));
      if (selectedExclusionReason === 'OTHER') {
        fields.push(exclusionReasonOther({ initialValue: initialValues.exclusionOtherReason }));
      }
    }
    return fields;
  }

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return goNextStep();
    }
    validateFields(async (err, values) => {
      if (!err) {
        const { id: memberId, remoteEnrollment } = user;
        const variables = {
          patientId: memberId,
          // referredBy: JSON.parse(sessionStorage.getItem('currentUser')).id,
          recommendedVitals: values.recommendVitalsList,
          eligiblePrgorams: values.eligiblePrograms,
          wouldJoin: !!values.joinProgram,
          // deviceAccessbility: typeof values.deviceAccessability !== 'boolean' ? patientReferral.deviceAccessability : values.deviceAccessability,
          techLevel: values.smartPhoneSkills,
          enrolledAnother: !!values.anotherCCMProvider,
        };
        if (!hideDeviceAccessibility) {
          variables.deviceAccessbility = !!values.deviceAccessability;
        }
        try {
          if (values.joinProgram === 0) {
            if (_.isNil(remoteEnrollment)) {
              await remoteEnrollmentApis.createRE({ memberId });
            }
            await remoteEnrollmentApis.editExcludedReason({
              memberId,
              excludedReason: values.exclusionReason,
            });
            await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'EXCLUDED' });
            if (values.exclusionReasonOther) {
              variables.exclusionOtherReason = values.exclusionReasonOther;
              await PostAPI.createPostItMutation({ organizationId:getCurrentOrg().id, memberId, note: `${EXCLUSION_REASONS_MAP[values.exclusionReason]}. ${values.exclusionReasonOther}`});
            }
            closeModal();
          }
          await upsertPatientReferralMutation(variables);
          onSubmitDone(values.joinProgram ? {}:{ tooltipMessage:'Patient is Excluded',hideTooltip: !values.joinProgram,fn: message.warn });
        } catch (error) {
          console.error(error);
          openErrorModal(GQLHelper.formatError(error));
        }
      }
    });
  }, [validateFields, onSubmitDone]);

  const handleNotEligibleClick = async () => {
    // mark patient as excluded
    try {
      const { id: memberId, remoteEnrollment } = user;
      const values = getFieldsValue();
      if (_.isNil(remoteEnrollment)) {
        await remoteEnrollmentApis.createRE({ memberId });
      }
      await remoteEnrollmentApis.editExcludedReason({
        memberId,
        excludedReason: 'NO_SMART_PHONE',
      });
      await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'EXCLUDED' });
      const referralVariables = {
        patientId: memberId,
        deviceAccessbility: !!values.deviceAccessability,
      };
      const techLevel = values.smartPhoneSkills;
      if (!_.isNil(techLevel)) {
        referralVariables.techLevel = techLevel;
      }
      await upsertPatientReferralMutation(referralVariables);
      closeModal();
    } catch (error) {
      console.error(error);
      openErrorModal(GQLHelper.formatError(error));
    }
  }

  const notDeviceAccessibleOverlay = () => {
    return (
      <Overlay>
        <div className="flex-center fit-parent">
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.noSmartphoneEligibility')}</div>
            <div style={{ marginBottom: 20 }}>{I18N.get('referAndEnroll.noSmartphoneEligibilitySubText')}</div>
            <div className="flex-center">
              <Button type="primary" className="new-style" onClick={handleNotEligibleClick}>{I18N.get('referAndEnroll.noSmartPhoneEligibilityButton')}</Button>
            </div>
          </div>
        </div>
      </Overlay>
    );
  }

  const renderHeader = () => {
    return isReferred
      ? <div>
        <div style={{ fontSize: 15 }}>{I18N.get('referAndEnroll.referredByDoctor').replace('[name]', getReferredByLastName(patientReferral))}</div>
        {/* <div style={{ fontWeight: 'normal', fontSize: 14 }}>{I18N.get('referAndEnroll.referredByDoctorSubText')}</div> */}
      </div>
      : 'Program Eligibility'
      ;
  }
  const btnText = isJoiningProgram ? 'Next' : 'Close';
  return (
    <React.Fragment>
      <ReferAndEnrollForm 
        header={renderHeader()} 
        className="program-eligibility"
        onSubmit={handleSubmit} 
        buttons={showButton() && <Button type="primary" htmlType="submit" className="new-style">{btnText}</Button>}
      >
        {/* isReferred is always true */ }
        {/* {!isReferred && <IcdCodeCondition 
          form={form}
          onChange={handleConditionSelect}
          disabled={disabled}
          icdCodesInitialValue={initialVal
          conditionsInitialValue={initialValues.conditions}
        />} */}
        <Card style={{ position: 'relative', boxShadow: '0px 0px 10px -4px rgba(0,0,0,0.7)', margin: '0 10px 30px 10px' }}>
          {(hasNoConditionFromIcdCode || hasHldOnly) && renderIneligibleOverlay(hasNoConditionFromIcdCode)}
          {showNotEligibleSmartPhoneOverlay() && notDeviceAccessibleOverlay()}
          <VitalsAndProgramsComponent
            form={form}
            disabled={disabled}
            disabledPrograms={disabledPrograms}
            disabledVitals={disabledVitals}
            vitalsInitialValue={initialValues.vitals}
            eligibleProgramsInitialValue={initialValues.eligiblePrograms}
            onVitalsChange={onVitalsChange}
          />
        </Card>
        <FormRenderer form={form} fields={getFields()} disabled={disabled} />
        {isJoiningProgram === false && selectedExclusionReason && 
          <div style={{ fontSize: 11, width: 400, marginTop: 20 }}>
            {I18N.get('referAndEnroll.notWillingToJoinNote')}
          </div>
        }
        {bpAndRpmOnly && <div style={{ color: '#DA6453', fontStyle: 'italic' }}>{I18N.get('referAndEnroll.noSmartPhoneBPAndRpm')}</div>}
        {showAnotherCCMAlert && <div style={{ color: '#DA6453', fontStyle: 'italic' }}>{I18N.get('referAndEnroll.anotherCCMAlert')}</div>}
      </ReferAndEnrollForm>
    </React.Fragment>
  );
}

export default Form.create()(EnrollmentProgramEligibility);
