import {
    React, IHButton, IHInputNumber, _, IHSelect, moment, IHBaseForm
} from 'ihcomponent'
import I18N from '../../../I18N'
import { formatDateMM} from "../../../utils/helpers/task";
import { RenderTopOne } from '../../../patient/main/containers/RenderTopOne';

const MU = {
    'DAY' : 'day(s)',
    'WEEK' : 'week(s)',
    'MONTH' : 'month(s)'
}
const DescArea = class extends React.Component{
    static displayName = 'patient/programTab/components/DescArea'
    renderDischarge() {
        return <RenderTopOne programInfo={this.props.programInfo}
                             openModal={this.props.openModal}
                             closeModal={this.props.closeModal}
                             lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                             dischargeEnrolledProgram={this.props.dischargeEnrolledProgram}
                             location={this.props.location}

        />
    }
    render(){
        if(!this.props.initialValue){
          return <div></div>
        }

        const startDate = formatDateMM(this.props.startDate);
            // moment(this.props.startDate).format('MMM-DD-YYYY');
        const endDate = formatDateMM(this.props.endDate);
            // moment(this.props.endDate).format('MMM-DD-YYYY');
        return (
            <div className="vsm-DescArea">
                {this.renderDischarge()}
                <div className='v-d1'>
                    <b style={{marginBottom:12}}>{I18N.get('Program.programDetails.header')}</b>
                </div>
                <div className="v-d1">
                    {/*<b>Start and End Date</b>*/}
                    <b>{I18N.get('Program.programDetails.startDate')}</b>
                    <div style={{width: 'auto'}}>{startDate}</div>
                    {/*<b>Program Description:</b>*/}
                    {/*<div>{this.props.description}</div>*/}
                </div>
                <div className="v-d2">
                    <b>{I18N.get('Program.programDetails.programDescription')}</b>
                    <div>{this.props.description}</div>
                    {/*<b>Start and End Date</b>*/}
                    {/*<b>Start Date:</b>*/}
                    {/*<div style={{width: 'auto'}}>{startDate}</div>*/}
                </div>
                {/*<div className="v-d2">*/}
                    {/*<b>Duration</b>*/}
                    {/*<div>*/}
                        {/*{this.props.extendStep===1 && this.renderStep1()}*/}
                        {/*{this.props.extendStep===2 && this.renderStep2()}*/}
                        {/*{this.props.extendStep===3 && this.renderStep3()}*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }

    renderStep1(){
        const p = {
            size : 'exsmall',
            type : 'primary',
            label : 'Extend',
            onClick : ()=>{
                this.props.setExtendStep(2)
            }
        }
        //const readonly = this.props.readonly
        return (
            [
                <span key={1} className="v-t">{this.props.initialValue[0]} {MU[this.props.initialValue[1]]}</span>
                //,readonly?null:(<IHButton key={2} {...p} />)
            ]
        )
    }

    renderStep2(){
        return (
            [
                <span key={1} className="v-t">{this.props.initialValue[0]} {MU[this.props.initialValue[1]]}</span>,
                 ( this.props.status === 'STARTED' || this.props.status === 'SUSPENDED' ) && (this.renderExtendArea())
            ]
        )
    }


    confirmButtonProp = {
           label : 'Done',
           type : 'primary',
           size : 'large',
           onClick : () => {
             this.extendDurationForm.getFormDataSync().then(result => result && this.handleOnChange())
           },
           className : 'v-b2'
       }


    cancelButtonProp = {
       label : 'Cancel',
       size : 'large',
       onClick : ()=>{
           this.props.setExtendStep(1)
           this.props.resetExtendValueType()
       },
       className : 'v-b1'
   }





    COL = {
     labelCol: { span: 1 },
     wrapperCol: { span: 1 },
   };


    extendDurationValidator = (value) => {
      if(!value)
       return ' ';//if needed put i18n
     if(value < 1 || value > 300 ) {
       return ' ';//if needed put i18n
     }
   }

     formProp = {
      inline: true,
      itemList: [

          {
            key: 'inputNumber',
            type: 'inputNumber',
            ...this.COL,
            initialValue : this.props.extendValue,
            onChange : (value)=>{

                this.props.setExtendValue(value)

            },
            rules: [
              {validator: (rule, value, callback) => callback(this.extendDurationValidator(value))}

            ],

          },
          {
            key: 'inputType',
            type: 'select',
            ...this.COL,
            option : [
                {
                    name : 'Week(s)',
                    value : 'WEEK'
                },
                {
                    name : 'Month(s)',
                    value : 'MONTH'
                },
            ],

            initialValue : this.props.extendType,
            size : 'large',
            onChange : (value)=>{
                this.props.setExtendType(value)
            },

          },




      ],
    };




    renderExtendArea(){





        return (
            <div key={2} className="v-ar">


                  <b className="v-al">Extend By</b> <IHBaseForm ref={refNode => { this.extendDurationForm = refNode }}  {...this.formProp} style={{display:'inline-block', verticalAlign:'top'}}/>

                <span>
                <IHButton {...this.confirmButtonProp}/>
                <IHButton {...this.cancelButtonProp}/>
                </span>


            </div>
        )
    }


    renderStep3(){
        const p = {
            size : 'exsmall',
            type : 'primary',
            label : 'Extend Again',
            onClick : ()=>{

                this.props.setExtendStep(2)
            }
        }
        return (
            [
                <span key={1} className="v-t">{this.props.initialValue[0]} {MU[this.props.initialValue[1]]}</span>,
                <IHButton {...p} key={2} />
            ]
        )
    }

    handleOnChange = async () => {


        if(this.props.onChange){

          let value = [ this.props.extendValue, this.props.extendType ] ;
          if(value[1] === 'WEEK'){
              value = [value[0]*7, 'DAY']
          }
          else if(value[1] === 'MONTH'){
              value = [value[0]*30, 'DAY']
          }
            console.log('extend days ',value);
            this.props.onChange(value)
        }
    }
}

export default DescArea
