import PropTypes from 'prop-types';

const VisitPropTypes = {
  main: {

  },
  tabContainer: {
    visitTabConfig: PropTypes.object.isRequired, // TODO: define specifically propTypes
    setVisitListProps: PropTypes.func.isRequired,
    renderVisitWorkList: PropTypes.func.isRequired,
    renderVisitEdit: PropTypes.func.isRequired,
    setShowVirtualVisit: PropTypes.func.isRequired,
    refetchAllVisitList: PropTypes.func.isRequired
  },
  tab: {
    visitList: PropTypes.array.isRequired,
    visitListCount: PropTypes.number.isRequired,
    visitWindowTime: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.objectOf({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired
      })
    ]).isRequired,
    tableSelectedPageInfo: PropTypes.object.isRequired,
    tableSelectedFilters: PropTypes.object.isRequired,
    tableSelectedAdvancedFilters: PropTypes.object.isRequired,
    renderVisitWorkList: PropTypes.func.isRequired,
    renderVisitEdit: PropTypes.func.isRequired,
    setShowVirtualVisit: PropTypes.func.isRequired,
    refetchAllVisitList: PropTypes.func.isRequired
  },
  tabTitle: {
    visitWindowTime: PropTypes.shape({
      from: PropTypes.number.isRequired
    }),
    showResultCount: PropTypes.bool,
    resultCount: PropTypes.number,
    totalCount: PropTypes.number,
    tableSelectedAdvancedFilters: PropTypes.object.isRequired,
    setSelectedAdvancedFilters: PropTypes.func.isRequired,
    tableAdvancedFilters: PropTypes.node.isRequired
  },
  advancedFilters: {
    // visitList: PropTypes.array.isRequired,
    selectedAdvancedFilters: PropTypes.object.isRequired,
    setSelectedAdvancedFilters: PropTypes.func.isRequired
  }
};

export default VisitPropTypes;