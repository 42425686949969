import configs from '../../../../lib/config';
import { decodeId } from '../../../../lib/helpers/common-helpers';

const domain = configs.UC_SERVER_URL;

export const getPatientInsurancePath = `${domain}/patient/`;
export default (
  params, 
  requestOptions
) => {
  const { patientId } = params || {};

  if (!patientId) {
    console.warn('No patient id found');
    return Promise.resolve(undefined);
  }

  const decodedPatientId = decodeId(patientId);

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'GET',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    ...requestOptions
  };

  const getPatientInsuranceUrl = `${getPatientInsurancePath}${decodedPatientId}/insurance`;

  return fetch(
    getPatientInsuranceUrl, 
    fetchOptions).then((res) => res.json()
  );
};