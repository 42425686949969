import React, { useState } from 'react';
import PatientPreferencesPropTypes from '../../patient/main/components/patientPreferences/proptypes';
import { Form, Button, Popover, message } from 'antd';

import helpers from '../../careplanNew/helpers';
import upHelpers from '../../patient/main/components/patientPreferences/helpers';

import PatientPreferencesComponent from '../../patient/main/components/patientPreferences/PatientPreferencesComponent';
import PatientPreferencesText from '../../patient/main/components/patientPreferences/PatientPreferencesText';

const PatientPreferencesContainer = props => {
  const [showPopover, setShowPopover] = useState(false);
  const isMNTEnrolled = helpers.isMNTEnrolled(props);
  const upValues = upHelpers.getUserPreferences(props.user);

  const onSubmit = async () => {
    const memberId = _.get(props, 'user.id');
    const fieldValues = props.form.getFieldsValue();
    const upValues = upHelpers.parseUserPreferences(fieldValues)

    const messageLoading = message.loading();
    await upHelpers.setUserPreferences(memberId, upValues);
    messageLoading(); // destroy

    if(props.refetch) 
      props.refetch();

    onCancel();
  };

  const onCancel = () => setShowPopover(false);
  
  return (
    !isMNTEnrolled &&
    <Popover
      trigger='click'
      visible={showPopover}
      onVisibleChange={setShowPopover}
      placement='bottomRight'
      overlayClassName='patient-preferences-popover'
      content={
        <div className='patient-preferences-work-list-container'>
          <PatientPreferencesComponent 
            form={props.form} 
            isMNTEnrolled={isMNTEnrolled}
            userPreference={upValues}
            showDescription
          />
          <div className='patient-preferences-buttons'>
            <Button type='primary' onClick={onSubmit}>Save</Button>
            <Button onClick={onCancel} ghost>Cancel</Button>
          </div>
        </div>
      }
      arrowPointAtCenter
      destroyTooltipOnHide
    >
      <PatientPreferencesText 
        userPreference={upValues} 
        onClick={() => setShowPopover(true)} 
      />
    </Popover>
  );
};

PatientPreferencesContainer.displayName = 'PatientPreferencesContainer';
PatientPreferencesContainer.propTypes = PatientPreferencesPropTypes.container;

export default Form.create()(PatientPreferencesContainer);