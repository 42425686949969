// import * as types from '../constants/ProviderAlertListConstants'
// import { commonActions } from 'libModule/helpers/action-helpers'
import React from 'react';
import { openModal } from 'layoutModule/actions/MainModal'
import moment from 'moment'

// files
import InterventionNotes from '../../InterventionNotes/containers/InterventionNotes'

export const onAddClick = (row,setCategoryToAlerts) => {
  const props = {
    showModal: true,
    showHeaderCloseButton: true,
    // title: `Reading on ${moment(row.date).format("MMM-DD-YYYY")}`,
    className: 'vsm-provider-modal',
    size: 'lg'
  }
  return openModal(<InterventionNotes row={row} noteType='alert_note' setCategoryToAlerts={setCategoryToAlerts}/>, props)
}
