import gql from 'graphql-tag';

import EnrolledResult from './schema/EnrolledResult';
import EnrolledResultMin from './schema/EnrolledResultMin';
import lightEnrolledResult from './schema/lightEnrolledResult';

export default gql`
  query resultsFromToday($filters: ResultsFromTodayOpts){
    resultsFromToday(filters: $filters)  {
      ${EnrolledResult}
    }
  }
`

export const resultsFromTodayMinimal = gql`
  query resultsFromToday($filters: ResultsFromTodayOpts){
    resultsFromToday(filters: $filters)  {
      ${EnrolledResultMin}
  }
}`

export const lightResultsFromToday = gql`
  query resultsFromToday($filters: ResultsFromTodayOpts){
    resultsFromToday(filters: $filters)  {
      ${lightEnrolledResult}
    }
  }
`
