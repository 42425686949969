import gql from 'graphql-tag';
import { EP_COUNT_MAX } from '../../../../lib/constants';

export const enrolledProgramListWithTeam = gql`
  query enrolledProgramList(
    $count : Int = ${EP_COUNT_MAX},
    $sort : EnrolledListSort,
    $filters: EnrolledListOpts
  ){
    enrolledProgramList(
      count : $count,
      filters : $filters,
      sort : $sort
    ){
      data {
        id
        team{
          id
          members {
            currentRole {
              roleType
            }
            user {
              id
              profile {
                fullName
              }
            }
          }
        }
      }
    }
  }
`;