import { nationOptions, langOptions, raceOptions, docTypeOptions, countryOptions } from 'libModule/constants'
import { dobValidator, emailValidator2, postCodeValidator, blockValidator, floorValidator, unitValidator,
  firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientHeightInputValidator,
  patientHeightInchesInputValidator, mobileValidator, homePhoneValidator,yearOfDiagnoseValidator } from 'libModule/validator';
import I18N from '../../../../I18N';
const FORMLABELS = I18N.get('patientlist.manualInputForm');
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields } from 'libModule/utils'
import { convertUnits } from 'libModule/utils/convertUnits'
import { Tooltip,Icon,Checkbox,Form } from 'antd';
import React from 'react';
import { getProviderName } from '../../../../../lib/utils';

const VSM_US_units = convertUnits('VSM_US');
const OTPToolTipMessage = 'When enabled, user can request a onetime passcode (OTP) through SMS, and login with mobile number + the code. One mobile number can only be used to login one patient'
export const schema = {
  identificationType: {
    initialValue: '',
    required: false
  },
  identificationValue: {
    initialValue: '',
    required: true
  },
  DOB: {
    // initialValue: '',  browser console will throw error on field change date is object not string
    required: true
  },
  firstName: {
    initialValue: '',
    required: true
  },
  lastName: {
    initialValue: '',
    required: true
  },
  nickname: {
    initialValue: '',
    required: false
  },
  gender: {
    initialValue: '',
    required: true
  },
  height: {
    initialValue: '',
    required: false
  },
  height_inches: {
    initialValue: '',
    required: false
  },
  weight: {
    initialValue: '',
    required: false
  },
  username: {
    initialValue: '',
    required: true
  },
  nationality: {
    initialValue: 'US',
    required: false
  },
  nationtype: {
    initialValue: 'SP',
    required: false
  },
  homeCountryCode: {
    initialValue: '+1',
    required: false
  },
  homePhone: {
    initialValue: '',
    required: false
  },
  mobileCountryCode: {
    initialValue: '+1',
    required: false
  },
  mobilePhone: {
    initialValue: '',
    required: false
  },
  email: {
    initialValue: '',
    required: false
  },
  streetNumber: {
    initialValue: '',
    required: false
  },
  streetName: {
    initialValue: '',
    required: false
  },
  unit: {
    initialValue: '',
    required: false
  },
  floor: {
    initialValue: '',
    required: false
  },
  city: {
    required: false
  },
  state: {
    required: false
  },
  country: {
    initialValue: 'US',
    required: false
  },
  postCode: {
    initialValue: '',
    required: false
  },
  race: {
    initialValue: '',
    required: false
  },
  insuranceProvider: {
    initialValue:'',
    required: false
  },
  language: {
    initialValue: 'EL',
    required: false
  },
  BPYearOfDiagnose: {
    required:false
  },
  BGYearOfDiagnose: {
    required:false
  },
  doctorName: {
    required:true
  },
  canUseForLogin:{
    required:false,
    initialValue:'true'
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

const formatFieldLabels = (schemaKey, i18key) => {
  if (schema[schemaKey].required) return `${FORMLABELS[i18key]} *`
  else return FORMLABELS[i18key];
}
const validInputYear = ()=>{

}
export const getFormProps = ({ fieldsValue, onFieldsChange }) => {
  const itemList = [

    // section 1: Basic Info
    [
      {
        key: 'basicInfo',
        label: FORMLABELS.basicInfo,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key:'doctorId',
        label: <div><span className="provider-label">{I18N.get('provider.label')}</span><Tooltip title={I18N.get('SelfEnrollment')}><Icon type="info-circle" style={{marginLeft: 5}}/></Tooltip></div>,
        type:'select',
        labelCol:{ span:24 },
        wrapperCol:{ span:24 },
        col:{ span:20,offset:0 },
        rules:[ { required: isFieldsRequired.doctorName,message:I18N.get('provider.required.message') } ],
        option: _.map(JSON.parse(sessionStorage.getItem('doctorName')), p =>({name: getProviderName(p),value: p.id, key: p.id})),
        showSearch:true
      },
      {
        key: 'identificationValue',
        label: I18N.get('patientlist.manualInputForm.nric'),
            // formatFieldLabels('identificationValue', 'nric'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'S1234567E',
        placeholder: '',
        initialValue: initData['identificationValue'],
        col: { span: 10, offset: 4 },
        // rules : [
        //   {required : isFieldsRequired.identificationValue, message : `${I18N.get('keywords.NRIC/FIN')} is required`},
        //   {validator: (rule, value, callback) => callback(NRICValidator(value))}
        // ],
      },
     {
        key: 'DOB',
        label: formatFieldLabels('DOB', 'dob'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'MM/DD/YYYY',
        initialValue: initData['DOB'],
        col: { span: 10, offset: 0 },
        format: 'MM/DD/YYYY',
        rules : [
          {required : isFieldsRequired.DOB, message : 'DOB is required'},
          { validator: (rule, value, callback) => callback(dobValidator(value)) }
        ]
      }
    ],

    // section 1: Basic Info
    // First Name * + Last Name (Family Name) *
    [
      {
        key: 'firstName',
        label: formatFieldLabels('firstName', 'firstName'),
        type: 'text',
        autoComplete:'new-password',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'First Name',
        placeholder: '',
        initialValue: initData['firstName'],
        col: { span: 10, offset: 4 },
        rules : [
          {required : isFieldsRequired.firstName, message : 'First Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
        ],
      },
      {
        key: 'lastName',
        label: formatFieldLabels('lastName','lastName'),
        type: 'text',
        autoComplete:'new-password',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Last Name',
        placeholder: '',
        initialValue: initData['lastName'],
        col: { span: 10, offset: 0 },
        rules : [
          {required : isFieldsRequired.lastName, message : 'Last Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
        ],
      }
    ],
    [
      {
        key: 'nickname',
        label: formatFieldLabels('nickname', 'nickname'),
        type: 'text',
        autoComplete:'new-password',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'First Name',
        placeholder: '',
        initialValue: initData['nickname'],
        col: { span: 10, offset: 4 },
        rules : [
          //{required : isFieldsRequired.nickname, message : 'Nickname is required'},
          //{validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'nickname'))}
        ],
      },
      {
        key: 'gender',
        label: formatFieldLabels('gender', 'gender'),
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['gender'],
        col: { span: 10, offset: 0 },
        option: [
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' }
        ],
        className: 'radio-button-2-option',
        rules : [
          {required : isFieldsRequired.gender, message : 'Gender is required'}
        ],
      },
    ],
    [
        {
            key: 'language',
            label: formatFieldLabels('language','language'),
            type: 'select',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            placeholder: '',
            disabled: false,
            initialValue: initData['language'],
            col: { span: 10, offset: 4 },
            option: langOptions,
            showSearch: true,
            filterOption: (inputValue, option) => {
                return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
        },
        {
          key: 'race1',
          label: formatFieldLabels('race','race'),
          type: 'select',
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          placeholder: '',
          disabled: false,
          initialValue: initData['race'],
          col: { span: 10, offset: 0 },
          option: raceOptions,
          showSearch: true,
          allowClear: true,
          filterOption: (inputValue, option) => {
              return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
        }
    ],

    // section 2; loginInfo
    [
      {
          key: 'loginInfo',
          label: FORMLABELS.loginInfo,
          type: 'label',
          col: { span: 4, offset: 0 }
      },
      {
        key: 'username',
        label: formatFieldLabels('username','username'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['username'],
        col: { span: 10, offset: 0 },
        rules : [
          { required : isFieldsRequired.username, message : `${I18N.get('validator.username.require')}` },
          { min: 3 , message: 'Need to more than 3 characters' },

        ],
      }
    ],

    // section 3; Contact Info
    [
      {
        key: 'contactDetails',
        label: FORMLABELS.contactDetails,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'homeCountryCode',
        label: formatFieldLabels('homeCountryCode', 'homeCountryCode'),
        // label: FORMLABELS.homeCountryCode,
        type: 'text',
        disabled: true,
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData['homeCountryCode'],
        col: { span: 4, offset: 0 },
      },
      {
        key: 'homePhone',
        // label: FORMLABELS.homePhone,
        label: formatFieldLabels('homePhone', 'homePhone'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData['homePhone'],
        col: { span: 6, offset: 0 },
        rules : [
          { required : isFieldsRequired.homePhone, message : 'Home Number is required' },
          {validator: (rule, value, callback) => callback(homePhoneValidator(value))}
        ],
        className: 'hide-required-mark'
      },
    ],
    [
        {
            key: 'mobileCountryCode',
            label: formatFieldLabels('mobileCountryCode', 'mobileCountryCode'),
            type: 'text',
            disabled: true,
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            initialValue: initData['mobileCountryCode'],
            col: { span: 4, offset: 4 }
        },
        {
            key: 'mobilePhone',
            label: formatFieldLabels('mobilePhone','mobilePhone'),
            type: 'text',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            initialValue: initData['mobilePhone'],
            col: { span: 6, offset: 0 },
            rules : [
                {required : isFieldsRequired.mobilePhone, message : 'Mobile Number is required'},
                {validator: (rule, value, callback) => callback(mobileValidator(value, 'mobilePhone'))}
            ],
            className: 'hide-required-mark'
        },
        {
            key: 'canUseForLogin',
            label:<div>{I18N.get('canUseForLogin')}<Tooltip title={I18N.get('OTPLogInInfo')} style={{marginLeft: 5}}><Icon type="info-circle" /></Tooltip></div>,
            type: 'radioButton',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            placeholder: '',
            initialValue: initData['canUseForLogin'],
            col: { span: 10, offset: 0 },
            option: [
                { name: 'On', value: 'true' },
                { name: 'Off', value: 'false' }
            ],
            className: 'radio-button-2-option',
        }
    ],
    // section 3: Contact Info 
    // E-Mail
    [
      {
        key: 'contactInfo2',
        label: '',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'email',
        label: formatFieldLabels('email','emailAddress'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Email Address',
        placeholder: '',
        initialValue: initData['email'],
        col: { span: 20, offset: 0 },
        rules : [
          {required : isFieldsRequired.email, message : 'Email is required'},
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ]
      }
    ]
  ]
  
  
    // onFieldsChange((val)=>console.log(val));
  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    itemList
  }
}
