import { getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'

export const schema = {
  viaEmail: {
    required: true
  },
  viaSMS: {
    required: true
  }
}

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const getFormProps = ({ initData }) => {
  const itemList = [
    [
      {
        key: 'receiveViaEmail',
        label: 'Receive via Email',
        type: 'label',
        col: { span: 10, offset: 1 },
        className: 'vsm-form-label'
      },
      {
        key: 'viaEmail',
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData.viaEmail,
        col: { span: 10, offset: 1 },
        option: [
          { name: 'Yes', value: 'viaEmailYes' },
          { name: 'No', value: 'viaEmailNo' }
        ],
        className: 'radio-button-width-150',
        rules : [
          {required : isFieldsRequired.viaEmail, message : 'Setting is required'}
        ],
      },
    ],
    [
      {
        key: 'receiveViaSMS',
        label: 'Receive via SMS',
        type: 'label',
        col: { span: 10, offset: 1 },
        className: 'vsm-form-label',
        style: { fontSize: '18px' }
      },
      {
        key: 'viaSMS',
        type: 'radioButton',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData.viaSMS,
        col: { span: 10, offset: 1 },
        option: [
          { name: 'Yes', value: 'viaSMSYes' },
          { name: 'No', value: 'viaSMSNo' }
        ],
        className: 'radio-button-width-150',
        rules : [
          {required : isFieldsRequired.viaSMS, message : 'Setting is required'}
        ],
      },
    ]
  ]
  return {
    vertical: true,
    itemList
  }
}
