import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {Form, Row, Button } from 'antd';
import ClinicGoals from '../component/GoalStatement/ClinicGoals';
import BehavioralGoals from '../component/GoalStatement/BehavioralGoals';
import VitalMonitoring from '../component/GoalStatement/VitalMonitoring';
import CarePlanCarryOver from '../component/GoalStatement/CarePlanCarryOver';
import goalHelper from '../component/helper/goalHelper';
import carryoverHelper from '../component/helper/carryOverHelper';
import getVisit from 'graphqlModule/singleVisit';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import renderOverlayDiv from '../helpers/overLayButton';
import BehavioralChangeComponent from '../component/GoalStatement/BehavioralChangeComponent';

import goalAssessmentHelper from '../../workStation/helper/goalAssessmentHelper';

import '../style/goalStatement.styles.scss';
import MonthlyReviewPlan from './MonthlyReviewPlan';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
import { monthlyReviewStatusMap } from '../../workStation/constant';
import GenerateMTPRComponent from "../../workStation/component/GenerateMTPRComponent";
import { makeCGMContextConsumer } from '../../CGM/context/makeCGMContextConsumer';

// Only used in worklist
let GoalStatement = class extends React.Component{
    static displayName = 'Goals';
    static title = 'Goals';
    static propTypes = {
      nonbillable: PropTypes.array,
      nonBillableGoalOptions: PropTypes.object,
      billable: PropTypes.array,
      billableGoalOptions: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
          showOldCareplanGoal:!_.get(this.props, 'program.goalsFlag'),
          showOldCareplanAssessment: !_.get(this.props, 'program.assessmentsFlag'),
          // show behavioral goals when it's MNT/FOLLOW_UP/INIT visit
          showBehavioralGoals: _.includes(['MNT', 'FOLLOW_UP', 'INIT'] ,_.get(this.props, 'visit.type'))
        }
        this.mtprContainerRef = React.createRef();
    }

    handleSubmit = () => {
      const hasUnfinishedMTPR = _.get(this, 'mtprContainerRef.current');
      if(hasUnfinishedMTPR) {
        hasUnfinishedMTPR.classList.add('unfinishedMTPRPrompt');
        hasUnfinishedMTPR.scrollIntoView();
        return;
      }
      goalHelper.handleSubmit(this.props);
    }

    render(){
      const { isWorkList, isEditMode, enrolledProgramId } = this.props;
      const {showOldCareplanGoal, showOldCareplanAssessment, showBehavioralGoals} = this.state;
      // if old care plan goal exists, then don't fix the complete button
      // otherwise, make it fixed
      const fixCompleteButtonCN = showOldCareplanGoal? '': ' scrollable-content';
      return (
      <div className='goalStatementContainer' id='goalStatementContainer'>
        {!enrolledProgramId && renderOverlayDiv(this.props)}
        <div style={{ opacity: !enrolledProgramId ? 0.2 : 1 }}>
        <div className='header fix-header'>
          <div className='fixHeaderTitle'>
            Goals
            {renderDraftSavedText()}
          </div>
        </div>
        <div className={showOldCareplanGoal?'scrollable-content': ' '}>
        <Form id='goalStatement' className={'goalStatement' + fixCompleteButtonCN} colon={false}>
            <MonthlyReviewPlan
              containerRef={this.mtprContainerRef}
              {...this.props}
              clinicGoals={<ClinicGoals {...this.props} />}
            />
            {/* <ClinicGoals {...this.props} /> */}
            <VitalMonitoring {...this.props}/>
            <BehavioralChangeComponent {...this.props} showBehavioralGoals={showBehavioralGoals}/>
            <BehavioralGoals {...this.props} showBehavioralGoals={showBehavioralGoals}/>
          </Form>
        {
          enrolledProgramId ? (
          <div>
          <Row className='fix-footer'>
            {isEditMode ? (
              <Button
                onClick={() => goalHelper.handleSave(this.props)}
                className='saveDraftBtn'
              >
                Save Draft
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={this.handleSubmit}
              type={'primary'}
            >
              {this.props.submitButtonText || 'Complete'}
            </Button>
          </Row>
          {showOldCareplanAssessment && !isWorkList && <CarePlanCarryOver {...this.props} carryOverSubmit={() => carryoverHelper.submitAssessments(this)} path='assessments'/>}
          {showOldCareplanGoal && <CarePlanCarryOver {...this.props} carryOverSubmit={() => carryoverHelper.submitGoals(this)} path='goals'/> }
          </div>) : ''
        }
        </div>
        </div>
      </div>)
    }
}

const withMTPRSaveDraft = (WrappedComponent) => {
  const MTPRSaveDraftWrapper = props => {
    const self = useRef();
    const [hasMTPRDraft, setHasMTPRDraft] = useState(false);
    const [_mtprHandleSubmit, _copyMTPRHandleSubmit] = useState(null);
    const [draftSaved, setDraftSaved] = useState(false);
    if (self.current && self.current.exists) {
      self.current.hasMTPRDraft = hasMTPRDraft;
      self.current.mtprHandleSubmit = _mtprHandleSubmit;
    }
    useEffect(() => {
      self.current = { exists: true };
      return () => {
        self.current = null;
      };
    }, [])

    const copyMTPRHandleSubmit = (newMtprHandleSubmit = {}) => {
      if(!_mtprHandleSubmit) {
        _copyMTPRHandleSubmit(newMtprHandleSubmit);
      }
    }

    const mtprHandleSubmit = async () => {
      const { current } = self || {};
      const { exists, hasMTPRDraft, mtprHandleSubmit } = current || {};
      if (!hasMTPRDraft) return;
      if(mtprHandleSubmit && mtprHandleSubmit.saveDraft) {
        try {
          await mtprHandleSubmit.saveDraft();
          setDraftSaved(true);
          setTimeout(() => {
            if (!!exists)
              setDraftSaved(false);
          }, 500);
        } catch (error) {
          console.error(error);
        }
      }
    }

    return <WrappedComponent
      {...props}
      draftSaved={draftSaved}
      hasMTPRDraft={hasMTPRDraft}
      setHasMTPRDraft={setHasMTPRDraft}
      mtprHandleSubmit={mtprHandleSubmit}
      copyMTPRHandleSubmit={copyMTPRHandleSubmit}
    />
  }

  return MTPRSaveDraftWrapper;
};

const parseValues = async (childProps) => {
  if (childProps.mtprHandleSubmit) await childProps.mtprHandleSubmit();
  return goalHelper.getMutateVariableGoals(childProps);
}

const getRefetchQueries = (props) => {
  const goalAssessmentRefetchQueries = goalAssessmentHelper.getGoalAssessmentRefetchQueries(props);
  return [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}, ...goalAssessmentRefetchQueries];
}

const GoalStatementForm = Form.create({
  onValuesChange: (props, changedValues) => {
    const { onChange,isEditMode, onTouch, setChildProps, hasMTPRDraft, mtprHandleSubmit, setHasMTPRDraft } = props;
    if(!isEditMode){
      if(typeof onTouch === 'function'){
        onTouch();
        const refetchQueries = getRefetchQueries(props);
        setChildProps(props, parseValues, editEnrolledProgram, refetchQueries, GoalStatement.title);
      }
      if(typeof onChange ==='function'){
        onChange({
          cb: goalHelper.handleSubmit,
          cbVar: props
        });
      }
    }
    const mtprHasChange = !!Object.keys(changedValues).filter(k => (k.includes('-status') || k.includes('-value') || k === 'note')).length;
    if (!hasMTPRDraft && mtprHasChange) {
      setHasMTPRDraft(true);
    }

  }
})(GoalStatement);

export default withMTPRSaveDraft(makeCGMContextConsumer(GoalStatementForm));
