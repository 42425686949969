import { CellularDevicesHelpers } from '../helpers';

export const cellularDeviceBrandOptions = _.map(
  CellularDevicesHelpers.BRAND_TO_DEVICE_TYPE_MAP, 
  (deviceType, brand) => ({
    label: CellularDevicesHelpers.makeCellularDeviceName(deviceType, brand),
    value: brand,
  }),
);

export const cellularDeviceBrandNameMap = Object.assign(
  {},
  ..._.map(cellularDeviceBrandOptions, ({ label, value }) => ({ [value]: label })),
);

export const cellularDeviceLanguageOptions = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'zh', label: 'Chinese (Simplified)' },
  { value: 'nl', label: 'Dutch' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'es', label: 'Spanish' },
];

export const cellularDeviceLanguageNameMap = Object.assign(
  {},
  ..._.map(cellularDeviceLanguageOptions, ({ label, value }) => ({ [value]: label })),
);
