import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import I18N from '../../I18N';
import { ELIGIBLE_PROGRAMS_ENUM, VITAL_TYPE_ENUM } from '../constant';
import { eligiblePrograms, PROGRAM_ELIGIBILITY_KEYS, recommendVitalsList } from '../fields/ProgramEligibility';
import FormRenderer from './FormRenderer';

const VitalsAndProgramsComponent = ({ form, disabledPrograms, disabledVitals, overlay, vitalsInitialValue, eligibleProgramsInitialValue, disabled, onVitalsChange }) => {
  // const disabledVitalKeys = useMemo(() => disabledVitals && disabledVitals.map(x => x.key), [disabledVitals]);
  // const disabledProgramKeys = useMemo(() => disabledPrograms && disabledPrograms.map(x => x.key), [disabledPrograms]);
  const fields = [
    recommendVitalsList({ className: 'vitals-programs', disabledKeys: disabledVitals, initialValue: vitalsInitialValue, onVitalsChange }),
    eligiblePrograms({ className: 'vitals-programs', disabledKeys: disabledPrograms, initialValue: eligibleProgramsInitialValue }),
  ];
    
  // useEffect(() => {
  //   if (!disabledVitalKeys || disabledVitals.length === 0) return;
  //   const selectedValues = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST) || [];
  //   // const values = _.keys(VITAL_TYPE_ENUM).filter(v => !disabledVitalKeys.includes(v));
  //   const values = selectedValues.filter(v => !disabledVitalKeys.includes(v));
  //   form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: values });
  // }, [disabledVitalKeys]);

  // useEffect(() => {
  //   if (!disabledProgramKeys || disabledProgramKeys.length === 0) return;
  //   const selectedValues = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS) || [];
  //   // const values = _.keys(ELIGIBLE_PROGRAMS_ENUM).filter(v => !disabledProgramKeys.includes(v));
  //   const values = selectedValues.filter(v => !disabledProgramKeys.includes(v));
  //   form.setFieldsValue({ [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: values });
  // }, [disabledProgramKeys]);

  return (
    <div style={{ position: 'relative' }}>
      {overlay}
      <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.vitalsHeader')}</div>
      <div>{I18N.get('referAndEnroll.vitalsSubheader')}</div>
      <hr />
      <FormRenderer form={form} fields={fields} disabled={disabled} />
    </div>
  );
}

export default VitalsAndProgramsComponent;
