/**
 * Created by zizhangai on 2/21/17.
 */
import gql from 'graphql-tag';

export default gql`
  mutation sendInterventionNotes($interventionNotesId: EID!) {
    sendInterventionNotes(interventionNotesId: $interventionNotesId) {
      status
    }
  }
`;
