import _ from 'lodash';
import { helpers } from '../../visitNewWorkFlow/helper';

export const getAnotherCCMFieldValue = (patientReferral) => {
  return _.get(patientReferral, 'enrolledAnother');
}

export const getIcdCodesValue = (patientReferral, user) => {
  return _.get(patientReferral, 'healthConditions') || _.get(user, `profile.healthConditions`) || [];
}

export const getBillableIcdCodesValue = (patientReferral) => {
  // default empty when first start referral else using whatever saved in referral
  const billableIcdCodes =  _.get(patientReferral, 'billableHealthConditions') || [];
  return _.map(billableIcdCodes, c => _.omit(c, '__typename'));
}

export const getConditionsValue = (patientReferral, user={}) => {
  const conditionsList = _.get(user, 'profile.conditionsList');
  return  _.get(patientReferral, 'conditionsToMonnitor')|| helpers.conditionsListToCheckbox(conditionsList) || [];
}

export const getMNTConditionsValue = (patientReferral) => {
  return _.get(patientReferral, 'mntConditions') || [];
}

export const getVitalsValue = (patientReferral) => {
  return _.get(patientReferral, 'recommendedVitals')|| [];
}

export const getProgramsValue = (patientReferral) => {
  return _.get(patientReferral, 'eligiblePrgorams', []);
}

export const getPatientMntDescriptionValue = (patientReferral) => {
  return _.get(patientReferral, 'patientDescribe');
}

export const getReferredByLastName = (patientReferral) => {
  return _.get(patientReferral, 'referredBy.profile.lastName');
}

export const getWouldJoinValue = (patientReferral) => {
  return _.get(patientReferral, 'wouldJoin');
}

export const getDeviceAccessibilityValue = (patientReferral) => {
  return _.get(patientReferral, 'deviceAccessbility');
}

export const getHasSmartPhoneValue = (patientReferral) => {
  return _.get(patientReferral, 'haveSmartphone');
}

export const getPhone = (user) => {
  const phones = _.get(user, 'phone', []);
  return (_.find(phones, v => v.type === 'MOBILE') || {}).number;
}

export const getCanUseForLogin = (user) => {
  const phones = _.get(user, 'phone', []);
  return (_.find(phones, v => v.type === 'MOBILE') || {}).canUseForLogin;
}

export const getProvider = (user) => {
  return _.get(user, 'profile.doctor.id');
}

export const getExclusionReason = (user) => {
  return _.get(user, 'remoteEnrollment.excludedReason');
}

export const getWantMntReferral = (patientReferral) => {
  return _.get(patientReferral, 'wantMNTReferral');
}

export const getStatuses = (patientReferral) => {
  return _.get(patientReferral, 'status', {});
}

export const getExclusionOtherReason = (patientReferral) => {
  return _.get(patientReferral, 'exclusionOtherReason');
}

export const getTechLevel = (patientReferral) => {
  return _.get(patientReferral, 'techLevel');
}

export const checkReferred = (patientReferral) => {
  return _.get(patientReferral, 'status.referStatus') === 'COMPLETED';
}

export const getInitialVisit = (patientReferral) => {
  const visit = _.get(patientReferral, 'initialVisit');
  if (visit) {
    return {
      id: visit.id,
      dateTime: visit.appointmentAt,
      note: visit.reason,
    }
  }
}
