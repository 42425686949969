import React from 'react';
import { UNIFIED_CARE_DEFAULT_LOGO } from '../../../lib/constants';
import I18 from '../../I18N';
import agoraVideo from "../../I18N/en/AgoraVideo";
import helper from "../helper";
const containerStyle = {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'100%',
};
const innerContainer = {
     width:'60%',
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    height:'100%'
}
const spanStyle = {
    textAlign:'center',
    fontSize: 22,
    margin:'10px 0',
    fontWeight: 'bold'
}
const downLoadButtonStyle = {
    height: 51,
    width: 128,
    borderRadius: 28.5,
    backgroundColor: '#FFFFFF',
    color:'#0B70B6',
    fontWeight: 'bold',
    border:'unset'
}

const appDownloadLink = 'https://bit.ly/2JL8f0e';
const LeftAgoraVideoForPatientComponent = class extends React.Component{
    constructor(){
        super();
        this.state = {
            innerHeight: null
        }
    }
    componentDidMount(){
        let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    topDiv = ()=>{
        const msg =  I18.get('agoraVideo.meeting_ended');
        return <div style={containerStyle}>
                <div style={innerContainer}>
                    <span style={ Object.assign({ color:'#5f5f5f' },spanStyle)}>{ msg }</span>
                </div>
               </div>
    }
     componentDidUpdate(){

        const newInnerHeight = window.innerHeight;
        if(!innerHeight||(innerHeight!=newInnerHeight)){
            this.setState({
                innerHeight:newInnerHeight
            },()=>{
                document.documentElement.style.setProperty('--vh', `${newInnerHeight/100}px`);
            })
        }
    }
    bottomDiv = ()=>{
        const msg =  I18.get('agoraVideo.better_service');
        return <div style={containerStyle}>
                <div style={innerContainer}>
                 <img src={UNIFIED_CARE_DEFAULT_LOGO} width={100}/>
                 <span style={ Object.assign({color: '#FFFFFF' },spanStyle)}>{ msg }</span>
                </div>
               </div>
    }

    downloadButton = ()=>{
        return <div style={containerStyle}>
                <div style={innerContainer}>
                    <button style={downLoadButtonStyle} onClick={()=>open(appDownloadLink)}>Download</button>
                </div>
               </div>
    }

    render(){
        const { topDiv,bottomDiv,downloadButton } = this;
        return<div className='leftAgoraPatientDiv'>
                <div style={{ height: '40%',backgroundColor:'white' }}>
                { topDiv() }
                </div>
                <div style={{ height: '45%',  backgroundColor: '#0B70B6'}}>
                    { bottomDiv() }
                </div>
                <div style={{ height: '15%',  backgroundColor: '#0B70B6',marginTop:'-1px'}}>
                    { downloadButton() }
                </div>
              </div>
    }
}


export default LeftAgoraVideoForPatientComponent;
