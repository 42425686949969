import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';


const RemoteEnrollmentPTOptTemplate = props => {
    return props.hasNoResult ?
        <Row>
            <Col>No patient found</Col>
        </Row>
        :
        <Row>
            <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                {props.fullName}
            </Col>
            <Col span={5}>
                {props.DOB}
            </Col>
            <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                {props.MRN}
            </Col>
            <Col span={5}>
                {props.mobilePhone}
            </Col>
            <Col span={4}>
                {props.status}
            </Col>
        </Row>;
};

// title
RemoteEnrollmentPTOptTemplate.defaultProps = {
    fullName: 'Name',
    DOB: 'DOB',
    MRN: 'MedID',
    mobilePhone: 'Tel',
    status: 'Status',
};

RemoteEnrollmentPTOptTemplate.propTypes = {
    hasNoResult: PropTypes.bool
};

export default RemoteEnrollmentPTOptTemplate;