import updateCGMInfo from './api/updateCGMInfo';

export class CGMHelperServices {
  static brandFieldName = 'CGMBrand';

  static checkboxFieldName = 'CGM';

  static requiredMessage = '*Please choose a CGM brand'

  static dropdownOption = {
    label: 'CGM',
    value: CGMHelperServices.brandFieldName,
  };

  static validateField = (form) => {
    return form.validateFieldsAndScroll([CGMHelperServices.brandFieldName]);
  };

  static getCheckboxFieldValue = (form) => {
    const value = form.getFieldValue(CGMHelperServices.checkboxFieldName);
    return value;
  };

  static getBrandFieldValue = (form) => {
    const value = form.getFieldValue(CGMHelperServices.brandFieldName);
    return value;
  };

  static excludeCGMFromValidation = (form) => (
    _.filter(
      Object.keys(form.getFieldsValue()),
      (k) => k !== CGMHelperServices.brandFieldName
    )
  );

  static saveBrandValue = (
    form, 
    patientId,
  ) => {
    const cgmBrand = CGMHelperServices.getBrandFieldValue(form);
    if (!patientId) return Promise.resolve(false);
    return updateCGMInfo({
      patientId,
      isCGMEnabled: !!cgmBrand,
      cgmBrand,
    });
  };

  static removeCGM = (
    patientId,
  ) => {
    return updateCGMInfo({
      patientId,
      isCGMEnabled: false,
    });
  }
}
