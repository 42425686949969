import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertPO from 'modulesAll/graphql/insertPO';
import ManualInputContainer from './ManualInputContainer';
import { patientHeartrateInputValidator } from 'libModule/validator';

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/POInputContainer'

  getVitalForm() {
    return [{
      type: 'rowGroup',
      rowGroupSpan: 24,
      gutter: 10,
      className: 'number-row',
      items: [
        {
          key: 'bloodOxygen',
          label: 'Blood Oxygen (%)',
          type: 'inputNumber',
          rowGroupSpan: 8,
          rules : [
            {validator: (rule, value, callback) => callback(patientHeartrateInputValidator(value))}
          ],
        }
      ],
    }];
  }

  insertVital(values, commonFields) {
    const variables = {
      ...commonFields,
      bloodOxygen: {
        value: values.bloodOxygen,
        unit: '%'
      },
    };

    return this.props.insertPO({ variables })
      .then(res => _.get(res, 'data.insertPO.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Blood Oxygen"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertPO, { name: 'insertPO' })
)(component);
