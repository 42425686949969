import React from 'react';
import {Button, Modal} from 'antd';
import ConsentFormModalContainer from '../container/ConsentFormModalContainer';
const ConsentFormComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showModal:false,

        }
    }

    setShowModal = (showModal,id)=>{
        this.setState({
            showModal,id
        })
    }
    onCancel=(showModal=false,id=null)=>{
        const { refetchTemplateGroupList } = this.props;
        this.setShowModal(showModal,id);
        refetchTemplateGroupList&&refetchTemplateGroupList();
    }
    renderModal = ()=>{
        const { showModal,id } = this.state;
        const { refetchTemplateGroupList } = this.props;
        return showModal&&<Modal visible={true} footer={false} width={750} maskClosable={false} onCancel={()=>this.onCancel() }>
            <ConsentFormModalContainer id={id} refetchTemplateGroupList={refetchTemplateGroupList} setShowModal={this.setShowModal} onCancel={this.onCancel}/>
        </Modal>
    }
    renderCreateButton = ()=>{
        return <div className={'addConsentBtnWrapper'}><Button type={'primary'} onClick={()=>this.setShowModal(true)}>Add consent form template</Button></div>
    }

    render() {
        const { renderModal,renderCreateButton } = this;
        return <div>
                { renderCreateButton() }
                { renderModal()}
               </div>
    }
}

export default ConsentFormComponent;