import { combineReducers } from 'redux'
import Common from 'patientModule/enrolledProgramInfo/Careplan/reducers/CommonReducer'
import VitalForm from 'patientModule/enrolledProgramInfo/Careplan/reducers/VitalFormReducer'
import VitalList from 'patientModule/enrolledProgramInfo/Careplan/reducers/VitalListReducer'
import VitalAlert from 'patientModule/enrolledProgramInfo/VitalAlert/reducers'
import Assessment from 'patientModule/enrolledProgramInfo/Assessment/reducers'
import Survey from 'patientModule/enrolledProgramInfo/Survey/reducers'
import NewCarePlan from 'patientModule/enrolledProgramInfo/Careplan/reducers/NewCarePlanReducer';
// import Education from 'patientModule/enrolledProgramInfo/Education/reducers'

const Careplan = combineReducers({
  Common,
  VitalForm,
  VitalList,
  VitalAlert,
  Assessment,
  Survey,
  NewCarePlan
  // Education
})

export default Careplan
