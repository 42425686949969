import { constants } from 'libModule/utils'
export default constants('layout', 'Nav', [
  'SCROLL',
  'SET_TOP_PATH',
  'SET_BREAD_CRUMB',
  'SET_INSTITUTION',
  'RESET_STATE',
  'CHANGE_MENU_STATS',
  'REMOUNT_MAIN_LAYOUT'
])
