import { message } from 'antd';
import reduxStore from 'libModule/reduxStore';
import actions from '../actions';
import { API as chatAPI } from '../actions/api';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const CLICK_DEBOUNCE_TIME = 100;

const decodeHTML = (text) => {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

const getUserProfile = (lookupArray, lookupId) => {
  return _.get((_.filter(lookupArray, m => _.get(m, 'user.id') === lookupId)[0] || {}), 'user.profile', {});
};

const encodeUserId = userId => btoa(`accounts:${userId}`);

const decodeUserId = id => atob(id).split(':')[1];

const showMessage = (type, content) => {
  if(!message[type])
    throw new Error('showMessage error: message type is invalid');

  // reset to default, check package/IHComponent/IHComponents/util.js
  const defaultMsgConfigs = {
    getContainer: () => document.body,
    top: 80,
    duration: 2
  };

  // to make it appear on top of chat window
  message.config({
    ...defaultMsgConfigs,
    top: 0,
    getContainer: () => document.querySelector('#chat-history-notice-message')
  });

  return message[type]({
    content,
    onClose: () => message.config(defaultMsgConfigs)
  })
};

const updateChatTag = (options) => {
  const dispatch = reduxStore.dispatch;
  dispatch(actions.updateChatTag(options));
};

const handleClickTag = _.debounce(async (messageId, currentUserId, newTaggedTo) => {
  if(!messageId)
    return;

  if(!newTaggedTo)
    return showMessage('warn', 'Please select a member to tag');

  const tagParams = {
    messageId,
    taggedBy: decodeUserId(currentUserId),
    taggedTo: decodeUserId(newTaggedTo),
  };

  const res = await chatAPI.tagMessage(tagParams)
                    .catch(error => showMessage('error', error));

  if(res && res.success) {
    updateChatTag({ 
      messageid: messageId,
      taggedby: tagParams.taggedBy,
      taggedto: tagParams.taggedTo,
      tagged: true, 
      read: false,
      taggedtime: new Date().getTime() * (10**4) // temporary time
    });
    Mixpanel.track('clicked', 'tag', 'message');
    showMessage('success', 'Tagged successfully!');
  }
}, CLICK_DEBOUNCE_TIME);

const toggleMarkTagMessage = _.debounce(async (tagObj, onSuccess) => {
  const { read: isRead, messageid } = tagObj || {};

  const res = await chatAPI.markTagMessage(!isRead, { messageId: messageid })
                    .catch(error => showMessage('error', error));;

  if(res && res.success) {
    updateChatTag({ 
      messageid,
      read: !isRead,
    });
    onSuccess && onSuccess(res);
  }
}, CLICK_DEBOUNCE_TIME);

export default {
  decodeHTML,
  getUserProfile,
  encodeUserId,
  decodeUserId,
  showMessage,
  handleClickTag,
  toggleMarkTagMessage
};