import React, { useMemo } from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Row, Select, Spin, Tooltip } from 'antd';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';
import I18N from '../../I18N';
import { API } from '../../visit/query/index';
import { PhoneField, phoneValidator } from '../../../lib/utils/phone';
import { getPhone } from '../helper/initialValueHelper';
import orgScheduleQuery from 'modulesAll/graphql/orgSchedule';
import '../style/schedulePhoneCall.scss';
import _ from 'lodash';
import { GQLHelper } from '../../../lib/utils';
import { getDisabledHours } from './ScheduleInitialVisitComponent';
import { editUserMutation } from '../API/mutation';
const { Item } = Form;
const { Option } = Select;
const { createVisit, rescheduleVisit } = API;

const dateFormat = 'MM/DD/YYYY';
const MESSAGE = 'referred, call to enroll patient. ';

const SchedulePhoneCallComponent = ({ form, closeModal, user, openErrorModal, userRole, loadingOrgSchedules, orgSchedules, onVisitSchedule, visitId, dateTime, note }) => {
  const { getFieldDecorator, validateFields } = form;
  const disabledHours = useMemo(() => getDisabledHours(orgSchedules), [orgSchedules]);

  const getNote = () => {
    if (note && note.includes(MESSAGE)) {
      const [firstHalf, actualNote] =  note.split(MESSAGE);
      return actualNote;
    }
    return undefined;
  }

  const setPhoneNumber = (phoneNumber) => {
    const { id: memberId, phone } = user;
    const variables = {
      id: memberId,
    };
    const newPhones = _.map(phone, (p) => (_.omit(p, ['__typename', 'verified'])));
    const mobile = _.find(newPhones, p => p.type === 'MOBILE');
    if (mobile) {
      mobile.number = phoneNumber;
    } else {
      newPhones.push({
        type:'MOBILE',
        number: phoneNumber,
        countryCode: '1',
      });
    }
    variables.phone = newPhones;
    return editUserMutation(variables);
  }

  const createPhoneVisit = async (fieldValues) => {
    const { date, time, note = '', phone } = fieldValues;
    const userId = _.get(user, 'id');
    const doctorId = _.get(user, 'profile.doctor.id');
    const appointmentTime = moment(date).startOf('day').add(time, 'hour').valueOf();

    const variables = {
      memberId: userId,
      type: 'INIT',
      appointmentAt: appointmentTime,
      appointmentTo: moment(appointmentTime).add(30, 'minutes').valueOf(),
      reason: `${userRole} ${MESSAGE}${note}`,
      assignees: {},
      providers: [doctorId],
      visitRoles: ['RD', 'CA'],
      category: 'PHONE',
    };

    return await createVisit(variables);
  }

  const editPhoneVisit = (fieldValues) => {
    const { date, time, note = '' } = fieldValues;
    const appointmentTime = moment(date).startOf('day').add(time, 'hour').valueOf();

    const variables = {
      id: visitId,
      appointmentAt: appointmentTime,
      appointmentTo: moment(appointmentTime).add(30, 'minutes').valueOf(),
      reason: `${userRole} ${MESSAGE}${note}`,
    };

    return rescheduleVisit(variables);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (error, values) => {
      if (!error) {
        try {
          await setPhoneNumber(values.phone);
          let res;
          let path;
          if (visitId) {
            res = await editPhoneVisit(values);
            path = 'data.editVisit';
          } else {
            res = await createPhoneVisit(values);
            path = 'data.createVisit';
          }
          const { id, appointmentAt, reason } = _.get(res, path, {});
          onVisitSchedule(id, appointmentAt, reason);
          closeModal();
        } catch (e) {
          console.log(e);
          openErrorModal(GQLHelper.formatError(e));
        }
      }
    });
  }

  return (
    <div className="schedule-phone-call-container">
      <h4>{I18N.get('referAndEnroll.phoneCallScheduleTitle')}</h4>
      <div>{I18N.get('referAndEnroll.phoneCallScheduleDescription')}</div>
      <Form style={{ marginTop: 20 }} onSubmit={handleSubmit}>
        <Row>
          <Col span={8}>
            <Item label="Date" >
              {getFieldDecorator('date', {
                initialValue: dateTime ? moment(dateTime).startOf('day') : undefined,
                rules: [{
                  required: true,
                  message: I18N.get('visit.details.visitDate.required.message')
                }]})
              (
                <DatePicker format={dateFormat} allowClear={false} />
              )}
            </Item>
          </Col>
          <Col span={8} offset={1}>
            {loadingOrgSchedules 
            ? <Spin />
            : <Item label="Time" >
              {getFieldDecorator('time', {
                initialValue: dateTime ? +moment(dateTime).format('HH') : undefined,
                rules: [{
                  required: true,
                  message: I18N.get('visit.details.visitDate.required.message')
                }]})
              (
                <Select>
                  {_.range(6, 19).map((v) => {
                    const disabled = disabledHours[v].disabled;
                    return (
                      <Option value={v} key={v} disabled={disabled}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>{v.toString().padStart(2, 0)}:00</div>
                          {disabled &&
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  This appointment involves RD or CA. It's outside iHealth Unified Care appointment serving hours.
                                </span>
                              }
                            >
                              <Icon type='info-circle' />
                            </Tooltip>
                          }
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Item>}
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <Item label="Patient's phone number" >
              {getFieldDecorator('phone', { 
                initialValue: getPhone(user),
                rules: [{ required: true, validator: phoneValidator }] 
              })
              (
                <PhoneField />
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <Item label="Notes" >
              {getFieldDecorator('note', { 
                initialValue: getNote(),
              })
              (
                <Input.TextArea />
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Button className="new-style" type="primary" htmlType="submit">Submit</Button>
        </Row>
      </Form>
    </div>
  );
}

const orgScheduleInfo = graphql(orgScheduleQuery, {
  options: () => {
    const { id } = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole.organization;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  },
  props: ({ data }) => {
    const { loading } = data;
    if (loading) {
      return {
        loadingOrgSchedules: loading,
      };
    }
    return {
      orgSchedules: _.get(data, 'organization.orgSchedules', []) || [],
    };
  }
});
export default compose(orgScheduleInfo, Form.create({ name: 'schedulePhoneCall' }))(SchedulePhoneCallComponent);
