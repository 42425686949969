import gql from 'graphql-tag'
import { taskHistoryData } from './taskDataFragment';
import { Identification } from '../../graphql/schema/common';

export default gql`query getTaskAssignmentsByPatientId($memberId:EID!,$status:[TaskAssignmentStatus]){
  getTaskAssignmentsByPatientId(memberId:$memberId,status:$status){
    taskAssignments{
      id
      status
      createdAt
      member{
        id
        profile{
            fullName
        }
        identification{
          ${Identification}
        }
      }
      resolvedNote{
        note
        resolvedBy{
            id
            profile{
              fullName
          }
        }
        resolvedAt
      }
      dueDate
      assignedTo{
        id
        profile{
          fullName
        }
      }
      reason
      createdBy{
        id
        profile{
          fullName
        }
      }
      priorityLevel
      isRead {
        value
        readAt
        readBy
      }
      ${taskHistoryData}
    }
  }
}
`;
