import { nationOptions, langOptions, countryOptions, docTypeOptions, HEALTH_CONDITION_DISPLAY } from 'libModule/constants'
import { emailValidator2, firstAndLastNameValidator, dobValidator, postCodeValidator, patientWeightRegiterValidator, patientHeightInputValidator, patientHeightInchesInputValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { convertInchesToFeet, getRemainderAfterConvertingToFeet, convertUnits } from 'libModule/utils/convertUnits'
import { mapPropsToFields } from 'libModule/utils'
import I18N from 'modulesAll/I18N';
import {goPath} from 'libModule/utils'


export const initData = {}

const getNewOptions = user => {
  // 07/18/18 - Lizzy Refactored. Directly save the full description into db, no need to transfer when display
  let staticOptions = Object.keys(HEALTH_CONDITION_DISPLAY).map(key => ({label:HEALTH_CONDITION_DISPLAY[key], value:HEALTH_CONDITION_DISPLAY[key]}));

  user.profile.healthConditions.forEach( value => {
    // 07/18/18 - Lizzy Refactored. Directly save the full description (value) into db, hence filtered by values here.
    if (Object.values(HEALTH_CONDITION_DISPLAY).indexOf(value) === -1) {
      staticOptions.push({ label: value, value });
    }
  });

  return staticOptions;
};

export const getFormProps = ({ fieldsValue, onFieldsChange, user }) => {
  const itemList = [
    [
      {
        key: 'Health_Conditions_Label',
        label: 'Health Conditions',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'healthConditions',
        type: 'checkboxGroup',
        onFieldsChange,
        //placeholder: 'Type new health condition...',
        option: getNewOptions(user)
      }
    ]
  ]
  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    itemList
  }
}
