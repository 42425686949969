import { constants } from 'libModule/utils'

export default constants('enrolledProgramInfo/Careplan', 'Common', [
  'CHANGE_PAGE',
  'CAREPLAN_MAIN_PAGE',
  'CAREPLAN_VITAL_FORM_PAGE',
  'ASSESSMENT_FORM_PAGE',
  'SURVEY_FORM_PAGE',
  'EDUCATION_FORM_PAGE',
  'SET_ASSESSMENT_HAS_EMPTY_SCHEDULE',
  'SET_SURVEY_HAS_EMPTY_SCHEDULE',
  // 'UPDATE_CARE_COORDINATION',
  // 'UPDATE_FOLLOWUP_SCHEDULE',
  // 'UPDATE_PROBLEM_LIST',
  // 'UPDATE_ASSESSMENTS',
  // 'UPDATE_INTERVENTIONS',
  // 'UPDATE_GOALS',
  // 'UPDATE_RESOURCE',
  'UPDATE_FIELD',
  'CAREPLANE_UPDATED',
  'RESET_CAREPLANE_UPDATED'
])
