import React from 'react';
import { Row, Col, Form } from 'antd';
import '../style/formBuilder.styles.scss';

const formRenderer = (formProps, getFormConfigs) => {
  const { getFieldDecorator } = formProps;
  const formConfigs = getFormConfigs();
  return <div id='form-builder-layout'>
    {
      _.map(formConfigs, (row, rowIndex) => {
        return <Row key={rowIndex}
                    style={{ 
                      display: 'flex', 
                      // alignItems for last row is flex-start, for better display if resize text area included
                      alignItems: rowIndex < formConfigs.length -1 ? 'flex-end' : 'flex-start',
                    }}>
          {
            _.map(row, (col, colIndex) => {
              const colProps = _.get(col, 'colProps', {});
              return <React.Fragment key={rowIndex + '-' + colIndex}>
                <Col className='common-col-styles' {...colProps}>
                  <Form.Item label={col.label} colon={false}>
                    {
                      getFieldDecorator(col.key, {
                        initialValue: _.get(col, 'value'),
                        valuePropName: _.get(col, 'valuePropName', 'value')
                      })(col.render())
                    }
                  </Form.Item>
                </Col>
                {
                  colProps.divider &&
                  <Col style={{ alignSelf: 'center', margin: '1.5em 8px 0px -5px' }}>
                    <span>{colProps.divider}</span>
                  </Col>
                }
              </React.Fragment>
            })
          }
        </Row>
      })
    }
    </div>
};

export default formRenderer