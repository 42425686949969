export default  {
    'assignedToCA':{
        title:'CA',
    },
    'assignedToRD':{
        title:'RD'
    },
    'assignedToHC':{
        title:'HC'
    }
}

