import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable } from 'ihcomponent';
import { formatSchedule, formatTime, formatReadingFromRow, hasMeasurement, hasMissedAlert, renderViewAssessmentButton, renderVitalType } from 'modulesAll/utils/helpers/task';
import I18N from 'modulesAll/I18N'

export default class PatientPastTaskListTableComponent extends React.Component {
  static displayName = 'PatientPastTaskListTableComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  getTableProps() {
    const { addReading, onTableChange, data, dataSource, programFilters, vitalFilters, taskStatusFilters } = this.props;
    const { loading = true } = data;

    return {
      title: I18N.get('tableTitle.Past Programme Tasks'),
      rowKey: 'none',
      onChange: onTableChange,
      dataSource,
      pagination: {
        pageSize:5
      },
      loading,
      columns: [
        {
          title: 'Status',
          key: 'taskStatus',
          dataIndex: 'taskStatus',
          render: (item, row) => item.toTitleCase(),
          filters: taskStatusFilters || [],
          onFilter : (a, doc)=>{
              return a === doc.taskStatus
          }
        },
        {
          title: 'Schedule',
          key: 'schedule',
          dataIndex: 'schedule',
          render: (item, row) => formatSchedule(row),
        },
        {
          title: 'Type',
          key: 'type',
          dataIndex: 'type',
          render: renderVitalType,
          filters: vitalFilters || [],
          onFilter : (a, doc)=>{
              return a === doc.type
          }
        },
        {
          title: <p>Reading <em style={{fontSize: '11px'}}> (M = Manual)</em></p>,
          key: 'measure',
          dataIndex: 'measure',
          render: (item, row) => {
            if (row.type === 'SURVEY' || row.type === 'ASSESSMENT') {
              if (row.taskStatus === 'MISSED') {
                return <span className="miss-black">Missed</span>;
              }
              else {
                return renderViewAssessmentButton(row)
              }
            }
            else {
              return hasMissedAlert(row) ? <span className="miss-black">Missed</span> : hasMeasurement(row) ? formatReadingFromRow(row) : '';
            }
          },
        },
        {
          title: 'Taken',
          key: 'taken',
          dataIndex: 'date',
          sorter: (a, b) => (a.taskStatus !== 'MISSED' && a.date) - (b.taskStatus !== 'MISSED' && b.date),
          render: (item, row) => _.get(row, 'taskStatus') == 'COMPLETED' ? formatTime(item) : ''
        },
        {
          title: I18N.get('keywords.Program'),
          key: 'enrolledProgram.name',
          dataIndex: 'enrolledProgram.name',
          filters: programFilters || [],
          onFilter : (a, doc)=>{
              return a === doc.enrolledProgram.id
          }
        },
      ]
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There are no past {I18N.get('keywords.program')} tasks today.</p>
      </div>
    )

    return (
      <div className="patient-task-table">
        <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
      </div>
    );
  };
}

PatientPastTaskListTableComponent.propTypes = {
  onLoadParams: PropTypes.func,
  addReading: PropTypes.func,
  onTableChange: PropTypes.func,
  programFilters: PropTypes.array,
  dataSource: PropTypes.array,
  data: PropTypes.object,
  vitalFilters: PropTypes.array,
  taskStatusFilters: PropTypes.array,
}
