import React, { forwardRef } from 'react';
import { Checkbox } from 'antd';
import { useCGMContext } from '../../context/CGMContext';

const hasCGM = (CGMInfo) => CGMInfo && CGMInfo.brand;

export const CGMCheckboxComponent = forwardRef(({
  disabled,
  ...antdProps
}, ref) => (
  <Checkbox
    {...antdProps}
    ref={ref}
    disabled={disabled}
  >
    CGM
  </Checkbox>
));
