import React from 'react';
import {compose} from "react-apollo/index";
import InviteNextStepComponent from '../component/InviteNextStepComponent';
import { withProps } from 'recompose';
import Client from 'libModule/gqlClient';
import getVirtualVisitTeam from "../../graphql/getVirtualVisitTeam";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const InviteNextStepContainer = class extends React.Component{
    constructor(){
        super()
        this.state = {
            getVirtualVisitTeam:[],
            loading:false
        }
    }
    componentDidMount(){
        this.getData()
    }
    getData = () =>{
       const { fetch }  = this.props;
       this.setState({
           loading:true
       },()=>fetch(this.props).then(res=>{
           Mixpanel.track('refresh', 'provider list successfully')
           this.setState({
                   getVirtualVisitTeam: _.get(res, 'data.getVirtualVisitTeam'),
                   loading:false
               })
        })
       )

    }

    render(){
        const { getData,props } = this;
        const { getVirtualVisitTeam,loading } = this.state;
        const { currentVisit,twoColumns } = props;
        return <InviteNextStepComponent twoColumns={ twoColumns }
                                        getVirtualVisitTeam={ getVirtualVisitTeam }
                                        loading={loading}
                                        refreshVirtualVisitTeam={ getData }
                                        currentVisit={currentVisit}/>
    }
}

 const fetch = async (ownProps)=> {
     const visitId = _.get(ownProps, 'currentVisit.id')
     return await Client.query({
        query:getVirtualVisitTeam,
        variables:{
            visitId
        },
        fetchPolicy:'network-only'
    });
}


export default compose(
    withProps(props=>({ fetch:fetch })),
)(InviteNextStepContainer);
