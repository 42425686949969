import gql from 'graphql-tag';
import { PESStatementSchema } from '../schema/PESStatement';

const editSinglePES = gql`
  mutation editSinglePES (
    $id: EID!
    $problem: InputStatement,
    $etiology: [InputStatement],
    $signSymptom: [InputStatement],
    $resolution: InputResolution,
    $deleted: Boolean
  ) {
    editPESStatement(
      id: $id,
      problem: $problem, 
      etiology: $etiology,
      signSymptom: $signSymptom
      resolution: $resolution,
      deleted: $deleted
    ) {
      ${PESStatementSchema}
    }
  }
`;

const editMultiPES = gql`
  mutation editMultiPES(
    $pesStatements: [InputPESStatement]
  ) {
    editMultiPESStatements(
      pesStatements: $pesStatements
    ) {
      ${PESStatementSchema}
    }
  }
`;

export {
  editSinglePES,
  editMultiPES
};