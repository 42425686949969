import { React, _, ND } from 'ihcomponent';

export default (props) => {
  const { options, answer, onChange, isPreview } = props;
  const value = JSON.parse(_.get(answer, 'content') || '[]');

  const SELECT_OPTIONS = _.map(options, (o, i) => ({
    label: o.label,
    value: i,
  }));

  const onCheck = (checkedValues) => {
    onChange({ content: JSON.stringify(checkedValues) });
  };

  return (
    <div className="multi-select">
      <ND.Checkbox.Group
        options={SELECT_OPTIONS}
        disabled={isPreview}
        size="large"
        value={value}
        onChange={onCheck}
      />
    </div>
  );
};
