// Refer to: https://3x.ant.design/components/config-provider/#api
const ComponentClassnameMap = {
  Select: ['ant-select', 'ant-select-dropdown'],
};

const hasClassName = (classList, key) => {
  return _.intersection(classList, ComponentClassnameMap[key]).length > 0;
}

const getPopupContainer = (triggerNode) => {
  let parentNode,
      customClass;
  if (!_.get(triggerNode, 'classList')) {
    // ignore
  } else if (hasClassName(triggerNode.classList, 'Select')) {
    parentNode = triggerNode.parentNode;
    customClass = 'my-ant-select-styles';
  }

  if (parentNode && customClass) {
    parentNode.classList.add(customClass);
  }
  return parentNode || document.body;
}

export default {
  getPopupContainer
};