import React from 'react';
import API from "../../../MAPatient/helper";
import Client from 'libModule/gqlClient';
import editUser from 'modulesAll/graphql/mutation/editUser';
import { Form,Modal,Button,Input,Tooltip,Radio,Icon } from 'antd';
import {formatFieldLabels} from "../helpers";
import I18N from "../../../I18N";
import { renderForm} from "../helpers/";

let UpdateMobileInfoComponent = class extends React.Component{

    renderUpdateMobile = (memberId, patient)=>{
        const { props } = this;
        const { user } = props;
        const { id } = user;
        const phoneValidator = (value)=>{
            if (!value) return;
            if (!/^\d{10}$/.test(value)) {
                return I18N.get('validator.mobile.invalid');
            }
        };
        const config = [
            [
                {
                    key: 'mobilePhone',
                    label: formatFieldLabels('mobilePhone','mobilePhone'),
                    path:(localThis)=>{
                        const phone  = _.get(localThis,'props.user.phone', _.get(localThis,'props.user.phone'), []);
                        const mobileIndex = phone && phone.length > 0 ? phone.map((e)=>{ return e.type; }).indexOf('MOBILE'): -1;
                        return `phone[${mobileIndex}].number`;
                    },
                    labelCol: { span: 24 },
                    wrapperCol: { span: 22 },
                    span: 12,
                    inputContent:({disabled})=><Input disabled={disabled}/>,
                    rules:[{ required:true, validator:(rule,v,callback)=>callback(phoneValidator(v)) }]
                },
                {
                    key: 'canUseForLogin',
                    label: formatFieldLabels('canUseForLogin', 'canUseForLogin'),
                    type: 'radioButton',
                    labelCol: { span: 24  },
                    wrapperCol: { span: 22 },
                    span: 12,
                    path:(localThis)=>{
                        const phone  = _.get(localThis,'props.user.phone', _.get(localThis,'props.user.phone'), _.get(localThis,'props.user.phone'), []);
                        const mobileIndex = phone && phone.length > 0 ? phone.map((e)=>{ return e.type; }).indexOf('MOBILE'): -1;
                        return `phone[${mobileIndex}].canUseForLogin`;
                    },
                    rules:[{required:true}],
                    className: 'radio-button-2-option',
                    style:{float:'left',width:'100%'},
                    inputContent:({value,disabled})=> <div>
                        <Radio.Group disabled={disabled} defaultValue={typeof value=='boolean' ? value.toString() : (typeof value=='string'&&value.length>0) ? value :'true'}>
                            <Radio.Button value='true' style={{width: 60, textAlign: 'center'}}>On</Radio.Button>
                            <Radio.Button value='false' style={{width: 60, textAlign: 'center'}}>Off</Radio.Button>
                        </Radio.Group>
                        <Tooltip title={I18N.get('OTPLogInInfo')}>
                            <Icon type='info-circle' style={{fontSize: 14, marginLeft: 5,position:'absolute',marginTop:-20}}/>
                        </Tooltip>
                    </div>,
                },
                {
                    key: 'button',
                    label: `    `,
                    type: 'button',
                    labelCol: { span: 24  },
                    wrapperCol: { span: 22 },
                    span: 24,
                    className: 'radio-button-2-option',
                    style:{float:'left',width:'100%'},
                    inputContent:()=><Button type='primary' onClick={()=>this.handleSendLink(id,user)} size='large'>Confirm and Send</Button>
                }
            ]
        ]

        return renderForm(this,config);
    }

    handleSendLink = (memberId, user)=>{

        this.props.form.validateFields(['mobilePhone'], (err, values) => {
            if (err) {
                return;
            }

            const { getFieldsValue } = this.props.form;
            let { phone, isPrivate } = user;

            let{ canUseForLogin, mobilePhone } = getFieldsValue(['canUseForLogin','mobilePhone']);
            canUseForLogin = (canUseForLogin === '' || canUseForLogin === 'true'||canUseForLogin===true) ? true : false;
            let hasMobilePhone = false;
            _.forEach(phone, p => {
                if(p.type === 'MOBILE') {
                    hasMobilePhone = true;
                }
            })
            let newPhone = [];
            if(phone.length === 0 || !hasMobilePhone) {
                let newObj = {
                    countryCode : '1',
                    type : 'MOBILE',
                    number : mobilePhone,
                    canUseForLogin : canUseForLogin,
                    private: isPrivate
                }
                phone.push(newObj);
                _.forEach(phone, p => {
                    newPhone.push(_.omit(p,['__typename','verified']));
                })
            } else {
                _.forEach(phone, p => {
                    if(p.type=='MOBILE'){
                        p.number = mobilePhone;
                        p.canUseForLogin = (canUseForLogin=='true')||(canUseForLogin==='false' ? false: canUseForLogin);
                    }
                    newPhone.push(_.omit(p,['__typename','verified']));
                })
            }
            Client.mutate({ mutation:editUser, variables:{phone:newPhone,id:memberId}})
                .then(res => {
                    API.sendInvitationLinkMutation({memberId});
                    this.props.setShowModal(false);
                })
                .catch(error=>{
                    Modal.error({
                        content: error.message
                    });
                })
        });

    }

    // renderButton = ()=>{
    //     const { props } = this;
    //     const { user } = props;
    //     const { id } = user;
    //     return <Button onClick={()=>this.handleSendLink(id,user)}></Button>
    // }

    render(){
        const { renderUpdateMobile } = this;
        return <div>
                    <h4>Please confirm with patient the phone number and login option</h4>
                    { renderUpdateMobile() }
               </div>
    }
}
UpdateMobileInfoComponent = Form.create({})(UpdateMobileInfoComponent);
export default UpdateMobileInfoComponent;
