import { constants } from 'libModule/utils';

export default constants('ProgramIncomplete', 'Common', [
  'SET_TABS_STEP',
  'CHANGE_PAGE',
  'PROGRAM_INFO_FORM_PAGE',
  'CAREPLAN_MAIN_PAGE',
  'CAREPLAN_VITAL_FORM_PAGE',
  'ASSESSMENT_FORM_PAGE',
  'SURVEY_FORM_PAGE',
  'EDUCATION_FORM_PAGE',
])
