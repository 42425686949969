import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18N from 'modulesAll/I18N'

class ChatEmptyThirdPanel extends Component {
  static displayName = 'chat/components/ChatEmptyThirdPanel';

  render() {
    return (
      <div className='chat-empty-thirdPanel-container'>
        <div style={{padding:'130px 30px 30px', textAlign:'center', float:'left'}}>
          <img className='chat-empty-thirdPanel-icon' src='/image/chat-icon-empty-third-panel.svg' />
          <h1 style={{ fontFamily:'HelveticaNeue', color:'#c5c5c5', padding:10 }}>{ I18N.get('chat.messages') }</h1>
          <p style={{ fontFamily: 'HelveticaNeue', color:'#c5c5c5' }}>
            { I18N.get('chat.channel_instructions') }
          </p>
        </div>
      </div>
    )
  }
}

export default ChatEmptyThirdPanel;