import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertTM from 'modulesAll/graphql/insertTM';
import ManualInputContainer from './ManualInputContainer';
import { patientHeartrateInputValidator } from 'libModule/validator';

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/TMInputContainer'

  getVitalForm() {
    return [{
      type: 'rowGroup',
      rowGroupSpan: 24,
      gutter: 10,
      className: 'number-row',
      items: [
        {
          key: 'bodyTemperature',
          label: 'Temperature (C)',
          type: 'inputNumber',
          rowGroupSpan: 8,
          rules : [
            {validator: (rule, value, callback) => callback(patientHeartrateInputValidator(value))}
          ],
        }
      ],
    }];
  }

  insertVital(values, commonFields) {
    const variables = {
      ...commonFields,
      bodyTemperature: {
        value: values.bodyTemperature,
        unit: 'C'
      },
    };

    return this.props.insertTM({ variables })
      .then(res => _.get(res, 'data.insertTM.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Temperature"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertTM, { name: 'insertTM' })
)(component);
