import { connect } from 'react-redux';
import { graphql, compose, withApollo } from 'react-apollo';
import verifyOnBoardToken from 'modulesAll/graphql/verifyOnBoardToken';
import OnboardPasswordComponent from '../components/OnboardPasswordComponent';

// connect apollo data with container
export default compose(
  withApollo,
  graphql(verifyOnBoardToken, { name: 'verifyOnBoardToken' }),
  connect(null, null)
)(OnboardPasswordComponent);