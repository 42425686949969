import React from 'react';
import {SuperContainer, setPagePath, decryptRole} from 'libModule/utils'
import SelectAlerts from './SelectAlerts'
import TodoListTable from './ToDoListContainer'
import TaggedPatientListTable from './TaggedPatientListContainer'
import EnrolledPatientStats from './EnrolledPatientStatsContainer';
import ProgramMeasureTrendsContainer from './ProgramMeasureTrendsContainer';
import actions from '../actions';
import moment from 'moment';
import { browserHistory } from 'react-router';
import VideoChat from 'modulesAll/VideoChat/container/VideoChatWrapperContainer';
import Sound from "react-sound";
import {connect} from "react-redux";
import { compose } from 'react-apollo';
import VisitsMainContainer from '../../../visitsPage/containers/VisitsMainContainer';
import { ROLE_NAME } from '../../../../lib/constants';

const Component = class extends SuperContainer {
  static displayName = 'dashboard/containers/main'

  constructor(props) {
    super(props);
    this.state = {
      currentPrograms: [],
      defaultValue: [],
      optionRaw: [],
      createContainer: false
    };
    this.handleProgramChange = this.handleProgramChange.bind(this);
  }

  componentWillMount() {
    // get program options
    this.adminPrograms = JSON.parse(sessionStorage.getItem('adminProgramList')) || [];
    //Lizzy: Program that has a name starts with _ is testing program, don't show graph for them.
    _.remove(this.adminPrograms,(p)=> {
      return p ? _.startsWith(p.name,'_') : false;
    })
    //console.log("AP______",this.adminPrograms)
    const optionRaw = _.map(JSON.parse(sessionStorage.getItem('adminProgramList')) || [], (prog) => {
      return prog ? 
      {
        name : prog.name,
        value : prog.id
      } : {}
    });

    // set default value
    const defaultValue = optionRaw && optionRaw.length > 0 ? [ optionRaw[0].value ] : [];
    this.setState({ defaultValue, optionRaw, currentPrograms: defaultValue }, () => this.props.selectCurrentProgram(this.state.defaultValue));
    const curUrl = browserHistory.getCurrentLocation();
    if(_.get(curUrl, 'search') === '' && sessionStorage.getItem('backToCurVisit')) {
      sessionStorage.removeItem('backToCurVisit');
    }
  }

  handleProgramChange(val) {
    this.setState({ currentPrograms: val }, () => this.props.selectCurrentProgram(this.state.currentPrograms));
  }

  getNextDateOfWeek = () => {
    let nextWeekDayStartOfDay = moment().add(1, 'day').startOf('day');
    let nextWeekDayEndOfDay = moment().add(1, 'day').endOf('day');
    // while(nextWeekDayStartOfDay.day()==0||nextWeekDayStartOfDay.day()==6){
    //     nextWeekDayStartOfDay.add(1,'day').startOf('day');
    //     nextWeekDayEndOfDay.add(1,'day').endOf('day');
    // }
    return [nextWeekDayStartOfDay, nextWeekDayEndOfDay];
  }

  // cancelCreate(refetch){
  //   this.setState({
  //     createContainer: false
  //   });

  //   if(refetch){
  //     // TODO: Need to refactor this
  //     const curTab = this.getCurTab();
  //     const refetchVisitListButton = document.getElementById(`hidden-refetch-${curTab}-button`);
  //     if(refetchVisitListButton) refetchVisitListButton.click();
  //   }
  // }

  renderMain(){
    const currentRole = decryptRole();
    const routeMap = {
      'PROVIDER:Clinic Medical Assistant':'MA',
      'PROVIDER:Doctor':'MD',
      'PROVIDER:Medical Assistant':'CA',
      'PROVIDER:Nurse':'RD',
      [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
    }
    if(routeMap[currentRole]=='CA'||routeMap[currentRole]=='RD' || routeMap[currentRole] === 'HC') {
      return (
        <div className="vsm-dashboard-page">
          <div className="dashboard-row" style={{flexDirection:'column'}}>
            <VisitsMainContainer />
            <Sound url='/sound/insight.mp3'
                   playStatus={Sound.status.PLAYING}
                   volume={0}
            />
          </div>
        </div>
      )
    }
    if(routeMap[currentRole]=='MA'||routeMap[currentRole]=='MD'){
        return <div>
                <Sound url='/sound/insight.mp3'
                       playStatus={Sound.status.PLAYING}
                       volume={0}
                />
                <VideoChat/>
               </div>
    }
    
    // If changing a role from providers to admin, currentRole will be 'Admin' or 'Super_Admin'
    // so here we need to add a default return or the page will crash
    // later the page will be redirected to admin's portal
    return '';
  }

  renderEnrolledStats(programIds, title, className) {
    //program = this.state.currentPrograms;
    return <EnrolledPatientStats currentPrograms={programIds} title={title} className={className} />
  }
  renderProgramMeasureTrend(){
    return <ProgramMeasureTrendsContainer currentPrograms={this.state.currentPrograms} />
  }
  // 12/28/17 - Deprecated for Barbardos
  renderSelectAlerts(){
    return <SelectAlerts />
  }

  renderTodoTable(){
    return (
      <div className="v-left">
        <TodoListTable />
      </div>
    )
  }

  renderTaggedPatientTable() {
    return (
      <div className="v-left">
        <TaggedPatientListTable />
      </div>
    )
  }

  // 12/28/17 - JV - Removed 'Dashboard' breadcrumb for Barbados
  runOnceAfterDataLoading(){
    setPagePath('')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectCurrentProgram: (val) => dispatch(actions.selectCurrentProgram(val))
  }
}

const mapStateToProps = (state) => ({
    loading : false,
    ...state.dashboard.main.Todo,
    ...state.dashboard.main.currentProgram,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);