import vitalsHelper from './vitalsHelper';
import Client from 'libModule/gqlClient';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import { editUserWithSemiCompleteData as editUser } from 'modulesAll/graphql/mutation/editUser';
import { GQLHelper } from 'libModule/utils';
import getClinicGoalTemplates from '../../../graphql/getClinicGoalTemplates';
import { parseNutritionVariables } from '../../helpers/nutritionHelpers';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import goalAssessmentHelper from '../../../workStation/helper/goalAssessmentHelper';

// functions for mutation
const getMutateVariableGoals = (props) => {
  const {programId, form, enrolledProgramId, isWorkList, setBillableVitals} = props;
  const data = form.getFieldsValue();
  let result = {
    id: programId || enrolledProgramId,
    extraNote: data.goalNotes || ''
  };

  const tasks = vitalsHelper.getMutateVariableVitals(props);
  if(setBillableVitals){
    const tasksType = _.map(tasks, t => t.type);
    setBillableVitals(null, tasksType);
  }
  if(tasks){
    result.tasks = tasks;
  }

  if(data.behavioralGoal){
    let behavioralGoals = [];
    _.map(data.behavioralGoal, goal => {
      behavioralGoals.push({
        category: goal.category,
        value: goal.value,
        type: goal.type
      })
    })
    result.behavioralGoals = behavioralGoals;
  }

  if(_.includes(Object.keys(data), 'nonBillable') || _.includes(Object.keys(data), 'billable') ){
    let clinicGoals = [];

    if(data.billable){
      _.map(data.billable, o => {
        if(o && o.condition && o.goalEnum){ //store billable condition when condition and goal exist
          const conditionEnum = conditionToType[o.condition]; 
          let clinicGoal = {};
          if(conditionEnum) {
            clinicGoal = {
              conditionEnum,
              goalEnum: o.goalEnum,
            };
          } else  { // UNMAPPED
            clinicGoal = {
              condition: o.condition,
              conditionEnum: 'UNMAPPED',
              value: o.goalEnum // free-type goal
            };
          }
          
          clinicGoal = setGoalStatus(clinicGoal, o);

          clinicGoals.push(clinicGoal);
        }
      });
    }

    if(data.nonBillable && data.nonBillable.length > 0){
      _.map(data.nonBillable, o => {
        if(o && o.condition){ // store nonbillable condition when ONLY condition exists
          const { condition, goalEnum, goal } = o;
          // if the condition enum exists, store all in enum, otherwise, store plain text
          const conditionEnum = conditionToType[condition];
          const isValidGoalEnum = !!CLINIC_GOAL_ENUM[goalEnum];

          let clinicGoal = {};
          if(conditionEnum && goalEnum && isValidGoalEnum){
            clinicGoal = {
              conditionEnum,
              goalEnum,
            };
          } else {
            clinicGoal = {
              condition,
              value: goal || ''
            };
          }
            
          clinicGoal = setGoalStatus(clinicGoal, o);

          clinicGoals.push(clinicGoal);
        }
      });
    }

    result.clinicGoals = clinicGoals;
  }

  if(isWorkList){
    Client.mutate({
      mutation: editUser,
      variables: getUserPars(props)
    }).catch(e => {
      if(typeof props.openErrorModal == 'function'){
        props.openErrorModal(GQLHelper.formatError(e));
      }
    })
  }

  return result;
}

const getUserPars = (props) => {
  const { form } = props;
  const { getFieldsValue } = form;
  const formValues = getFieldsValue();
  const { behaviorChange } = formValues;
  if(behaviorChange){
    let nutrition = parseNutritionVariables(
      _.get(props, 'user.profile.nutrition'),
      { behaviorChange }
    );
    const userVariables = {
      id: _.get(props, 'user.id'),
      memberProfile: {
        birthday: _.get(props, 'user.profile.birthday'), // required by editUser
        nutrition
      }
    };
    return userVariables;
  }

  return _.get(props, 'user');
}

const handleSubmit = (props) => {
  const parsedValue = getMutateVariableGoals(props);
  const {form, openErrorModal, afterSubmitSucc, title, submitButtonText} = props;
  let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
  Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
  form.validateFieldsAndScroll((errors) => {
    if(!errors){
      const goalAssessmentRefetchQueries = goalAssessmentHelper.getGoalAssessmentRefetchQueries(props);
      Client.mutate({
        mutation: editEnrolledProgram,
        variables: parsedValue,
        refetchQueries: [...goalAssessmentRefetchQueries]
      }).then(res =>{
        if(typeof afterSubmitSucc === 'function'){
          afterSubmitSucc();
        }
      }).catch((e)=>{
        if(typeof openErrorModal == 'function'){
          openErrorModal(GQLHelper.formatError(e));
        }
      })
    }
  })
}

export const getGoalStatusValue = (goal = {}) => {
  return {
    meetingStatus: goal.meetingStatus || undefined,
    statusUpdatedAt: goal.statusUpdatedAt || undefined,
    // statusUpdatedBy: _.get(goal, 'statusUpdatedBy.id') || undefined
  };
};

const setGoalStatus = (goal, value) => {
  const { prevGoalStatus, meetingStatus } = value || {};

  const goalWithStatus = {
    ...goal,
    ...prevGoalStatus,
  };

  const prevMeetingStatus = _.get(prevGoalStatus, 'meetingStatus');

  if(!_.isEqual(meetingStatus, prevMeetingStatus)) {
    const now = new Date().getTime();
    const currentUserId = sessionStorage.getItem('currentUserId');
    goalWithStatus.meetingStatus = meetingStatus;
    goalWithStatus.statusUpdatedAt = now;
    goalWithStatus.statusUpdatedBy = currentUserId;
  }

  return goalWithStatus;
};

const handleSave = (props) => {
  const parsedValue = getMutateVariableGoals(props);
  const {form, openErrorModal, afterSaveDraftSucc, refetch} = props;
  form.validateFieldsAndScroll(['BGSchedule.weeklyMealSchedule','nonBillable'], (errors) => {
    if(!errors){
      const goalAssessmentRefetchQueries = goalAssessmentHelper.getGoalAssessmentRefetchQueries(props);
      Client.mutate({
        mutation: editEnrolledProgram,
        variables: parsedValue,
        refetchQueries: [...goalAssessmentRefetchQueries]
      }).then(() => refetch())
      .then(res =>{
        if(typeof afterSaveDraftSucc === 'function'){
          afterSaveDraftSucc();
        }
      }).catch((e)=>{
        if(typeof openErrorModal == 'function'){
          openErrorModal(GQLHelper.formatError(e));
        }
      })
    }
  })
}

export default{
  handleSave,
  handleSubmit,
  getMutateVariableGoals,
  getUserPars
}

// export const nameMapping = {
//   CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: 'COPD',
//   PRE_DIABETES: 'PRE_DIABETES',
//   CHRONIC_KIDNEY_DISEASE: 'CKD',
//   HYPERLIPIDEMIA: 'HYPERLIPIDEMIA',
//   CONGESTIVE_HEART_FAILURE: 'CHF',
//   HYPERTENSION: 'HYPERTENSION',
//   DIABETES: 'DIABETES'
// }

// export const revertNameMapping = Object.keys(nameMapping).reduce((ret, key) => {
//     ret[nameMapping[key]] = key;
//     return ret;
// }, {});
export const matchContionsWithOptions = (templates) => {
  let options = {};
  let conditions = [];
  let chronicDiseases = [];
  let billableVitals = [];
  _.forEach(templates, o => {
    
    const condition = mappingCondition[o.condition];
    if(condition && !options[condition]){
      options[condition] = o.goals;
      conditions.push(condition);
    }
    if(o.chronicDisease){
      chronicDiseases.push(o.chronicDisease);
    }
    if(o.vitals && !_.isEmpty(o.vitals)){
      billableVitals = _.concat(billableVitals, o.vitals);
    }
  })
  chronicDiseases = _.uniq(chronicDiseases);
  billableVitals = _.uniq(billableVitals);
  return{
    options,
    conditions,
    chronicDiseases,
    billableVitals
  }
}


export const updateGoalUponICDCode = async (props, user) => {
  // (SC-6319, SC-6426) This function is to set default goal for COPD & HTN condition
  // if they are set as billable, set the default clinic and behavioral goals
  // if they are not billable anymore, remove the goals as well
  // SC-6793: don't set the behavioral goals
  const { healthConditions = [], billableHealthConditions = [] } = _.get(user, 'profile') || props.form.getFieldsValue() ;
  const billableCodes = [];
  billableHealthConditions.map(r => {
      billableCodes.push(r.code);
      billableCodes.push(_.split(r.code,'.')[0].concat('.*'));
  });
  const nonBillableCodes = [];
  healthConditions.forEach(con => {
      let code = _.split(con,'::')[1];
      if(code && !billableCodes.includes(code)) {
          nonBillableCodes.push(code);
          nonBillableCodes.push(_.split(code, '.')[0].concat('.*'))
      }
  })
  const program = _.get(props, 'program') || _.get(props, 'currentProgram');
  let bClinic = [],
      unmappedGoalNames = [];
  if(billableCodes && billableCodes.length > 0){
      // get default clinic goals
      await Client.query({
          query: getClinicGoalTemplates,
          variables: {
            codes: billableCodes
          },
          fetchPolicy: 'network-only'
      }).then( async ({data}) =>{
          const { loading, getClinicGoalTemplates} = data
          if(loading){
            return loading
          }

          let result = matchContionsWithOptions(getClinicGoalTemplates);
          const { setBillableVitals } = props;
          if(setBillableVitals){
            setBillableVitals(result.billableVitals, null);
          }

          _.forEach(billableHealthConditions, bhc => {
            const { code, description } = bhc;
            let goalTemplate = null,
                bClinicGoal = null;
            for(let template of (getClinicGoalTemplates || [])) {
              const { codes } = template;
              if(_.includes(codes, code)) {
                goalTemplate = template;
                break;
              }
            }

            if(goalTemplate) {
              bClinicGoal = {
                conditionEnum : goalTemplate.condition,
                goalEnum: goalTemplate.goals[0]
              };
            } else {
              const unmappedGoalName = `${code} ${description}`;
              bClinicGoal = {
                condition: unmappedGoalName,
                conditionEnum: mappingCondition.UNMAPPED
              };
              unmappedGoalNames.push(unmappedGoalName);
            }
            bClinic.push(bClinicGoal);
          });
          // SC-6858: each condtion has one default expected outcome goal
          // SC-8006: update the condition and goal with enum
          // _.forEach(getClinicGoalTemplates, template => {
          //   const { condition, goals } = template;
          //   bClinic.push({
          //     conditionEnum : condition,
          //     goalEnum: goals[0]
          //   })
          // });
      })
  }

  // SC-6894: don't remove goal when it becomes nonbillable

  // post-possing
  // Add billable goals, remove __typename
  const { programId, enrolledProgramId } = props;
  let clinicGoals = [],
      billableConditionNames = [];

  // make an array of clinicGoals from prev clinicGoals
  _.forEach(_.get(program, 'clinicGoals', []), g => {
    if(g) {
      const { 
        conditionEnum, 
        goalEnum, 
        condition, 
        value
      } = g;

      if(g.conditionEnum)
        billableConditionNames.push(getBillableConditionName(g));

      const goalStatus = getGoalStatusValue(g); // previous goal status

      if(conditionEnum && goalEnum){
        clinicGoals.push({ conditionEnum, goalEnum, ...goalStatus });
      }
      if(conditionEnum === mappingCondition.UNMAPPED) {
        clinicGoals.push({ condition, conditionEnum, value, ...goalStatus });
      } else if (condition){
        clinicGoals.push({ condition, value, ...goalStatus });
      }
    }
  });

  // remove unmapped goal if unmapped condition is unmarked as billable
  clinicGoals = _.filter(clinicGoals, g => {
    return g.conditionEnum !== mappingCondition.UNMAPPED || _.includes(unmappedGoalNames, g.condition);
  });

  // step to add billable clinical goals
  _.forEach(bClinic, g => {
    if(!_.includes(billableConditionNames, getBillableConditionName(g))){
      clinicGoals.push(g);
    }
  });

  let variables = {
      id: programId || enrolledProgramId || _.get(program, 'id'),
      clinicGoals,
  }
  const goalAssessmentRefetchQueries = goalAssessmentHelper.getGoalAssessmentRefetchQueries({...props, user});
  return await Client.mutate({
    mutation: editEnrolledProgram,
    variables,
    refetchQueries: [...goalAssessmentRefetchQueries],
    fetchPolicy: 'no-cache'
  });
}

export const getBillableConditionName = (goal) => {
  const { condition, conditionEnum } = goal;

  let billableConditionName = conditionEnum;
  if(conditionEnum === mappingCondition.UNMAPPED)
    billableConditionName = condition; 
  return billableConditionName;
}

// constants:
export const HAS_DEFAULT_GOAL = ['Chronic Obstructive Pulmonary Disease', 'Blood Pressure Management'];

export const mappingCondition = {
  'GLUCOSE_MANAGEMENT': 'Glucose Management',
  'BLOOD_PRESSURE_MANAGEMENT' : 'Blood Pressure Management',
   HYPERTENSION: 'Blood Pressure Management',
   DIABETES:'Glucose Management',
  'LIPID_MANAGEMENT': 'Lipid Management',
  'WEIGHT_REDUCTION': 'Weight Reduction',
  'CANCER': 'Cancer',
  'UNDERWEIGHT': 'Underweight',
  'CHRONIC_KIDNEY_DISEASE': 'Chronic Kidney Disease',
  'CONGESTIVE_HEART_FAILURE': 'Congestive Heart Failure',
  'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE': 'Chronic Obstructive Pulmonary Disease',
  'COVID': 'COVID',
  'UNMAPPED': 'UNMAPPED'
}

export const conditionToType = {
  'Glucose Management': 'GLUCOSE_MANAGEMENT',
  'Blood Pressure Management': 'BLOOD_PRESSURE_MANAGEMENT',
  'Lipid Management': 'LIPID_MANAGEMENT',
  'Weight Reduction': 'WEIGHT_REDUCTION',
  'Cancer': 'CANCER',
  'Underweight': 'UNDERWEIGHT',
  'Chronic Kidney Disease': 'CHRONIC_KIDNEY_DISEASE',
  'Congestive Heart Failure': 'CONGESTIVE_HEART_FAILURE',
  'Chronic Obstructive Pulmonary Disease': 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  'COVID': 'COVID',
  'UNMAPPED': 'UNMAPPED'
}

export const CONDITION_ENUM_MAP = _.invert(conditionToType);

export const conditionToEvaluationMap ={
    'Glucose Management': 'DIABETES',
    'Blood Pressure Management': 'HYPERTENSION',
    'Lipid Management': 'LIPID_MANAGEMENT',
    'Weight Reduction': 'OBESITY',
    'Cancer': 'CANCER',
    'Underweight': 'UNDERWEIGHT',
    'Chronic Kidney Disease': 'CHRONIC_KIDNEY_DISEASE',
    'Congestive Heart Failure': 'CONGESTIVE_HEART_FAILURE',
    'Chronic Obstructive Pulmonary Disease': 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    'Covid': 'COVID',
    'UNMAPPED': 'UNMAPPED' // TODO: check the use
}

export const GOAL_TYPE_ENUM = {
  'GLUCOSE_MANAGEMENT': 'GLUCOSE_MANAGEMENT',
  'BLOOD_PRESSURE_MANAGEMENT' : 'BLOOD_PRESSURE_MANAGEMENT',
  'HYPERTENSION': 'HYPERTENSION',
  'DIABETES': 'DIABETES',
  'LIPID_MANAGEMENT': 'LIPID_MANAGEMENT',
  'WEIGHT_REDUCTION': 'WEIGHT_REDUCTION',
  'CANCER': 'CANCER',
  'UNDERWEIGHT': 'UNDERWEIGHT',
  'CHRONIC_KIDNEY_DISEASE': 'CHRONIC_KIDNEY_DISEASE',
  'CONGESTIVE_HEART_FAILURE': 'CONGESTIVE_HEART_FAILURE',
  'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE': 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  'COVID': 'COVID',
  'UNMAPPED': 'UNMAPPED' // TODO: check the use
};

export const CLINIC_GOAL_ENUM = {
  GLUCOSE_MANAGEMENT_1: 'Maintain or improve Hgb A1C by 0.5% over the next 3 months without symptoms of hypoglycemia',
  GLUCOSE_MANAGEMENT_2: 'Maintain or improve Hgb A1C to less than 7% over the next 3 months without symptoms of hypoglycemia',
  GLUCOSE_MANAGEMENT_3: 'Maintain a stable A1C between 6.5-7% over the next 3 months without symptoms of hypoglycemia',
  GLUCOSE_MANAGEMENT_4: 'Maintain or improve A1C between 7-7.5% over the next 3 months, due to the patient’s significant advanced risks due to other complicating conditions, without symptoms of hypoglycemia',
  GLUCOSE_MANAGEMENT_5: 'Pre-diabetes A1C will not worsen over the next 6 months without symptoms of hypoglycemia',
  GLUCOSE_MANAGEMENT_6: 'Pre-diabetes A1C will be maintained or improved to below 5.7% in the next 6 months without symptoms of hypoglycemia',

  BLOOD_PRESSURE_MANAGEMENT_1: 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension',
  BLOOD_PRESSURE_MANAGEMENT_2: 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension',
  BLOOD_PRESSURE_MANAGEMENT_3: 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks',
  BLOOD_PRESSURE_MANAGEMENT_4: 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months',
  BLOOD_PRESSURE_MANAGEMENT_5: 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months',

  WEIGHT_REDUCTION_1: 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months',
  WEIGHT_REDUCTION_2: 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  WEIGHT_REDUCTION_3: 'Aim to lose up to avg 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  WEIGHT_REDUCTION_4: 'Aim to lose up to avg 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  WEIGHT_REDUCTION_5: 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week',

  CANCER_1: 'Maintain current weight in the next 3 months',
  CANCER_2: 'Gain at least 0.5 lbs weekly in the next 3 months',
  CANCER_3: 'No further muscle wasting in the next 3 months',

  UNDERWEIGHT_1: 'Maintain current weight in the next 3 months',
  UNDERWEIGHT_2: 'Gain at least 0.5 lbs weekly in the next 3 months',
  UNDERWEIGHT_3: 'No further muscle wasting in the next 3 months',

  CONGESTIVE_HEART_FAILURE_1: 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week, and without symptoms of exacerbated CHF',
  CONGESTIVE_HEART_FAILURE_2: 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months',
  CONGESTIVE_HEART_FAILURE_3: 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months',
  CONGESTIVE_HEART_FAILURE_4: 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)',
  CONGESTIVE_HEART_FAILURE_5: 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)',
  CONGESTIVE_HEART_FAILURE_6: 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months',
  CONGESTIVE_HEART_FAILURE_7: 'Lipid profile will not worsen in the next 12 months',
  CONGESTIVE_HEART_FAILURE_8: 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed',
  CONGESTIVE_HEART_FAILURE_9: 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed',
  CONGESTIVE_HEART_FAILURE_10: 'Maintain or improve lipid profile to normal levels within the next 12 months',
  CONGESTIVE_HEART_FAILURE_11: 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension',
  CONGESTIVE_HEART_FAILURE_12: 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension',
  CONGESTIVE_HEART_FAILURE_13: 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks',
  CONGESTIVE_HEART_FAILURE_14: 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months',
  CONGESTIVE_HEART_FAILURE_15: 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months',
  CONGESTIVE_HEART_FAILURE_16: 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months',
  CONGESTIVE_HEART_FAILURE_17: 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  CONGESTIVE_HEART_FAILURE_18: 'Aim to lose up to 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  CONGESTIVE_HEART_FAILURE_19: 'Aim to lose up to 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases',
  CONGESTIVE_HEART_FAILURE_20: 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week',

  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1: 'Maintain oxygen saturation level within or above 88-92% without any symptoms of COPD exacerbation in the next 6 months',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2: 'Maintain current weight over the next 3-6 months with no significant weight change',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3: 'Aim for weight gain of 1-3 lbs over the next 3-6 months',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4: 'Aim for weight loss of 1-3 lbs over the next 3-6 months',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5: 'No further muscle wasting over the next 3-6 months',

  CHRONIC_KIDNEY_DISEASE_1: 'Maintain eGFR at current level for the next 3-6 months',
  CHRONIC_KIDNEY_DISEASE_2: 'Maintain or improve renal panel with in normal range in the next 3-6 months',
  CHRONIC_KIDNEY_DISEASE_3: 'Maintain or improve serum albumin level to 3.4-5.4 g/dL in the next 3-6 months',
  CHRONIC_KIDNEY_DISEASE_4: 'Maintain or improve serum potassium level to 3.5-5.0 mmol/L in the next 3-6 months',
  CHRONIC_KIDNEY_DISEASE_5: 'Maintain or improve serum phosphorus level to 2.5-4.5 mg/dL in the next 3-6 months',
  CHRONIC_KIDNEY_DISEASE_6: 'Maintain current dry weight in the next 3-6 months',

  LIPID_MANAGEMENT_1: 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months',
  LIPID_MANAGEMENT_2: 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months',
  LIPID_MANAGEMENT_3: 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)',
  LIPID_MANAGEMENT_4: 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)',
  LIPID_MANAGEMENT_5: 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months',
  LIPID_MANAGEMENT_6: 'Lipid profile will not worsen in the next 12 months',
  LIPID_MANAGEMENT_7: 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed',
  LIPID_MANAGEMENT_8: 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed',
  LIPID_MANAGEMENT_9: 'Maintain or improve lipid profile to normal levels within the next 12 months',

  COVID_1: 'The temperature and/or blood oxygen for the duration of the acute COVID illness will be maintained at or near normal levels, and the patient will notify the provider if symptoms worsen.'
};

/*
  {
    [CONDITION_ENUM]: {
      [goalText]: GOAL_ENUM
    }
  }
*/
export const REVERSED_CLINIC_GOAL_ENUM =  (() => {
  let reversed = {};
  _.map(CLINIC_GOAL_ENUM, (text, goalEnum) => {
    const conditionEnumTokens = goalEnum.split('_').slice(0, -1);
    const conditionEnum = conditionEnumTokens.join('_');
    const textEnum = { [text]: goalEnum };
    if (!reversed[conditionEnum]) reversed[conditionEnum] = [textEnum];
    else reversed[conditionEnum].push(textEnum);
  });
  _.keys(reversed).map((conditionEnum) => {
    reversed[conditionEnum] = _.assign({}, ...reversed[conditionEnum]);
  });
  return reversed;
})();