import React from 'react';
import { Form,Row,Modal,Col,Button,notification,message} from 'antd';
import { formConfig } from '../constant/formConfig';
import { renderForm } from '../../patient/AdditionalInfo/helpers/index.js';
import getVirtualVisitTeam from '../../graphql/getVirtualVisitTeam';
import action from '../action'
import API from '../API';
import helper from '../helper';
import InviteNextStepContainer from '../container/InviteNextStepContainer';
import '../style/index.scss';
import convertor from '@ihealth/convertor';
import roleMap from '../constant/roleMap';
import { convertInchesToFeet, getRemainderAfterConvertingToFeet, convertBG_mmolTomgdl } from 'libModule/utils/convertUnits'
import CreateFollowUpComponent from "../../VideoChat/component/CreateFollowUpComponent";
import {goPath, GQLHelper} from "../../../lib/utils";
import Client from 'libModule/gqlClient';
import mappingMeasurements from '../constant/measurements';
import { convertCelsiusToFahrenheit } from 'libModule/utils/convertUnits';
import moment from 'moment';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import getLoanDevice from "../../patient/loanDevices/query/getLoanDevice";
import store from '../../../lib/reduxStore';
import { ApolloProvider } from 'react-apollo';
import ConsentFormMiniContainer from '../../patient/main/containers/ConsentFormMiniContainer';
import { Provider } from 'react-redux';
import { ENROLL_FROM } from '../../../lib/constants';
import EnrollPatientContainer from '../../patient/EnrollPatient/container/EnrollPatientContainer';
import getVisit from 'graphqlModule/singleVisit';
import { BillableReviewFormPopup } from '../../visitWorkList/component/BillableReviewForm';
import { helpers } from '../../visitNewWorkFlow/helper';
import Draggable from 'react-draggable';

const IHEALTH_MEMBERS = ["CA", "RD", "HC"];

let VideoChatCenterComponent = class extends React.Component{
    constructor(props){
        super();
        this.state = {
            showLeaveMeetingModal: false,
            showCreateFollowUp: false,
            minimizedVideoChat: false,
            associatedVisit: props.currentVisit.associatedVisit,
            teams:[ ],
            latestMeasurements: {} // for Doctor's form
        }
    }

    componentDidMount(){
        const { currentRole } = this.props;
        this.getVisitTeam();
        this.getLoanDeviceInfo();
        // init fetch to prevent stale data from currentVisit
        this.getLatestUpdateForVisit(roleMap[currentRole], true);
        $('.ant-notification-topLeft').css('z-index',1000);
    }

    setAssociatedVisit = (associatedVisit)=>{
        this.setState({
            associatedVisit
        })
    }

    handleLeaveEvent = (eventType,visitId)=>{
        const { refetchVisitList,setVirtualVisit } = this.props;
        const key= 'videoChatCenterNotification'
        Modal.confirm({
            title: `Do you want to ${_.lowerCase(eventType)} this visit?`,
            className:'leaveConfirm',
            zIndex: 1020,
            onOk() {
                API.activePatientMutate({
                    eventType,
                    visitId
                }).then(res => {
                    setVirtualVisit(null);
                    Mixpanel.track('leave and hold', 'zoom successfully');
                    notification.close(key);
                    if(refetchVisitList) {
                        refetchVisitList();
                    }
                })
                .catch(error=>{
                    console.log(error);
                        message.error(GQLHelper.formatError(error))
                })
            }
        });
    }


    minimizedVideoChat = ()=>{
        const { user }= this.props;
        const { profile:{ fullName} } = user;
        return (
          <Draggable
            bounds={{ top: -25, left: -1250, right: 0, bottom: 815 }}
            handle='.draggable-popup-content'
            // {...draggableOptions}
          >
            <div 
              className='minimized-video-notification draggable-popup-container'
              style={{ 
                // width: 600, minWidth: 330, maxWidth: 600,
                // height: 330, minHeight: 320, maxHeight: 600,
                // top: 100, left: (window.innerWidth / 2) - 300, // 300 is half of container width
                overflow: 'auto',
                // ...containerStyles 
                width: 384, zIndex: 999,
              }}
            >
              <div className='draggable-popup-content'>
                <div style={{ fontSize:16 }}>
                  <img src='image/camera_blue.png' height={16}  style={{ marginRight:10 }}/>
                  <span style={{ fontWeight:'bold',marginLeft:10 }}>{ fullName }</span>
                  <a onClick={()=>this.minimizeButtonHandler(false)}>
                      <img src="image/max_button.png" style={{float:'right'}} height={20}/>
                  </a>
                </div>
              </div>
            </div>
          </Draggable>
        );
    }

    minimizeButtonHandler = (shouldOpen)=>{
        const videoChatCenterDiv = $('#videoChatCenter');

        if(shouldOpen){
            videoChatCenterDiv.css('display','none');
            const variables = {
                key:'minimizedVideoChat',
                message:'',
                placement:'topRight',
                description:this.minimizedVideoChat(),
                duration: 0,
                className:'minimizedVideoChat draggable-notification',
                style:{
                    boxShadow:'0 0 12px 1px rgba(162,162,162,0.6)'
                }
            };
            notification.open(variables);
            Mixpanel.track('maximize', 'zoom meeting window');
        }else {
            notification.close('minimizedVideoChat');
            Mixpanel.track('minimize', 'zoom meeting window');
            videoChatCenterDiv.css('display','flex');
        }
    }

    setCreateFollowUp = (showCreateFollowUp)=>{
        this.setState({
            showCreateFollowUp
        })
    }

    getUpdateForCurrentVisit = async () => {
        const latestMeasurements = await action.getUpdatedCurrentVisit(_.get(this, 'props.currentVisit.id'));
        const {details: {height, weight, bgMeasurement, bpMeasurement, temperature, spo2}, reason} = latestMeasurements;
        let newValues = {
            weight,
            systolic: { value: _.get(bpMeasurement, 'systolic_blood_pressure.value'), unit: _.get(bpMeasurement, 'systolic_blood_pressure.unit') },
            diastolic: { value: _.get(bpMeasurement, 'diastolic_blood_pressure.value'), unit: _.get(bpMeasurement, 'diastolic_blood_pressure.unit') },
            heartBeat: { value: _.get(bpMeasurement, 'heart_rate.value'), unit: _.get(bpMeasurement, 'heart_rate.unit') },
            BG: { value: _.get(bgMeasurement, 'blood_glucose.value'), unit: _.get(bgMeasurement, 'blood_glucose.unit') },
            temperature,
            spo2,
        }
        // make sure every value has correct unit
        _.forEach(_.keys(newValues), valueName => { // valueName, ie systolic: String
            // measure, ie systolic_blood_pressure: Object
            const measure = _.filter(mappingMeasurements, measurement => measurement.name == valueName)[0];
            if(_.get(newValues, `${valueName}.value`)){ // prevent null value
                // latest value and its unit
                const { value: measurementValue, unit: measurementUnit } = _.get(newValues, valueName);
                // get value after conversion
                const valueAfterConversion = measure.getValue(measurementValue, measurementUnit)[0];
                _.set(newValues, valueName, valueAfterConversion);
            } else {
                newValues = _.omit(newValues, valueName);
            }
        });
        // special conversion for height, not in mappingMeasurements
        if(height){
            const heightInInches = convertor.height(height.value, 'cm', 'inch', 0);
            const heightInFeet = convertInchesToFeet(heightInInches);
            const heightLeftInInch = getRemainderAfterConvertingToFeet(heightInInches);
            newValues = {
                ...newValues,
                height: heightInFeet,
                height_inches: heightLeftInInch,
            }
        }

        return {
            ...newValues,
            reason
        };
    }

    getLatestUpdateForVisit = async (role, isInitFetch) => {
        let latestMeasurements, newValues;
        if(roleMap[role] == 'MA') {
            if(isInitFetch) {
                newValues = await this.getUpdateForCurrentVisit();
            }
            const memberId = _.get(this, 'props.currentVisit.member.id');
            latestMeasurements = await action.getLatestMeasurements(memberId);
            _.forEach(_.get(latestMeasurements, 'measureLatestByType', []), measure => {
                if(moment(measure.date).format('MM/DD/YYYY') == moment().format('MM/DD/YYYY')) { // get only data for TODAY
                    // clear noise in measure to get actual measurement, ie. blood_glucose: Object
                    const measurements = _.pick(measure, _.keys(mappingMeasurements));
                    _.forEach(_.keys(measurements), measurementType => { // measurementType, ie blood_glucose: String
                        // formKeyName, ie BG: String
                        const formKeyName = _.get(mappingMeasurements, `${measurementType}.name`);
                        // latest value and its unit
                        const { value: measurementValue, unit: measurementUnit } = _.get(measurements, measurementType);
                        // final value
                        const [value, unit] = _.get(mappingMeasurements, measurementType).getValue(measurementValue, measurementUnit);
                        newValues = {
                            ...newValues,
                            [formKeyName]: _.round(value, 1),
                        }
                    });
                }
            });
            this.props.form.setFieldsValue(newValues);
            latestMeasurements = newValues;
        } else {
            newValues = await this.getUpdateForCurrentVisit();
            // round all values
            _.forEach(_.keys(newValues), key => {
                const value = _.get(newValues, key);
                if(typeof value === 'number'){
                    _.set(newValues, key, _.round(value, 1));
                }
            });
            latestMeasurements = { ...this.state.latestMeasurements, ...newValues };
            this.setState({ latestMeasurements });
        }
        // set Notes
        const { reason } = this.props.form.getFieldsValue(); // current Notes or currentVisit.reason pre-filled to reason field
        let notes = _.get(latestMeasurements, 'reason', reason); // no updated notes, using current note
        this.props.form.setFieldsValue({ reason: notes })
    }

    renderPatientFormAndSaveButton = (role,currentVisit)=>{
        const { setCreateFollowUp, renderRefreshButton, renderSaveButton } = this;
        if(roleMap[role]=='MA'){
            return <div style={{ padding:10,padding:25 }}>
            { renderForm(this,formConfig(roleMap[role],this.props,setCreateFollowUp,this)) }
            { renderSaveButton() }
            { renderRefreshButton(role,currentVisit) }
           </div>
        }
    }

    handleRefresh = role => {
        this.getLatestUpdateForVisit(role);
        Mixpanel.track('refresh', 'latest measurement');
        message.success('Refreshed for latest measurements');
    }

    handleSaveEdit = ()=>{
        const { props } = this;
        const { form,currentVisit,currentRole } = props;
        const { validateFields,getFieldsValue } = form;
        const visitId = _.get(currentVisit,'id');
        validateFields((error,res)=>{
            if(!error){
                const formValues = getFieldsValue();
                const variables = helper.getVariables(formValues,visitId,currentRole);
                API.editVisitMutate(variables).then(res=> {
                        Mixpanel.track('save', 'measurement successfully', '', {PROVIDER_ROLE: currentRole});
                        message.success('Measurements Saved');
                    }
                );
            }
        })
    }

    renderRefreshButton = (role,currentVisit)=>{
        const hasEP = _.get(currentVisit,'enrolledProgramStatus')==='STARTED';
        return hasEP&&<div style={{ display:'flex',justifyContent:'center', paddingBottom: 10, width: '100%',marginTop:10 }}>
            <Button style={{ width: '60%' }} type='primary' onClick={() => this.handleRefresh(role)}>Refresh to get patient’s data</Button>
        </div>
    }

    renderSaveButton = ()=>{
        return <div style={{ display:'flex',justifyContent:'center', width: '100%',marginTop:10 }}>
            <Button style={{ width: '60%' }} type='primary' onClick={()=>this.handleSaveEdit()} ghost>Save Data</Button>
        </div>
    }

    renderHeaderForNurse = (member)=>{
        const { profile:{ fullName,language,birthday,gender } } = member;

        return <div style={{ background: 'white',marginBottom: 0,marginLeft:25 }}>
                <Row style={{marginBottom: 30, marginTop: 50}}><h4 style={{color: '#3178C9', fontSize: 20}}>{fullName}</h4></Row>
                <Row>{`${gender} ${language.description} ${helper.getAge(birthday)}`}</Row>
               </div>
    }

    renderHeaderForDoctor = (member,role)=>{
        const { user,currentVisit } = this.props;
        const { profile:{ fullName,gender,birthday,language } } = member;
        const { setCreateFollowUp, renderRefreshButton, renderSaveButton } = this;
        return <div 
          style={{padding: '30px 50px', background: 'white', marginBottom: 0}}
        >
                <Row style={{marginBottom: 10, marginTop: 20}}>
                    <h4  style={{color: '#3178C9', fontSize: 20}}>{fullName}</h4>
                </Row>
                    {`${gender} ${helper.getAge(birthday)}y ${language.description} `}
                    {this.renderBasicInfo(user, currentVisit)}
                    {/*{ renderForm(this,formConfig(roleMap[role],this.props,setCreateFollowUp,this)) }*/}
                    { renderRefreshButton(role) }
                    {/* { renderSaveButton() } */}
                </div>
    }

    handleEnrollButton = (memberId, visitId)=>{
      if(visitId && memberId) {
        const visitObj = JSON.parse(sessionStorage.getItem('storeCurVisit'));
        sessionStorage.removeItem('storeCurVisit');
        sessionStorage.setItem('backToCurVisit', JSON.stringify(visitObj));
        goPath(`/patients/${memberId}/enrolledprogram/${visitId}_`);
        this.minimizeButtonHandler(true);
      }else{
        message.error('memberId or visitId not exists')
      }

  }
    renderConsentForm = () =>{
        const { currentVisit, user, refetchSingleVisit, refetchVisitList } = this.props;
        const { 
          id: visitId, vitalTypes=[], enrolled, category, enrolledProgramStatus 
        } = currentVisit;
        const memberId = _.get(user, 'id');
        const hasEnrolled = vitalTypes.length!==0 ? true : enrolled;

        // This videoChat component is not wrapped by redux.Provider
        // => explicitly pass "store" as a prop => need to pass deep to EnrollPatientContainer
        // OR wrap with Provider

        return !hasEnrolled &&
            <ApolloProvider client={Client}>
              <Provider store={store}>
                <div style={{ width: '100%', margin: '30px -15px'}}>
                  <div style={{ textAlign: 'center', marginBottom: 30 }}>
                    <EnrollPatientContainer 
                      user={user}
                      fromPlace={ENROLL_FROM.VIDEO_CALL}
                      onCompleteEnrollment={async () => {  
                        if(refetchSingleVisit) {
                          const res = await Client.query({
                            query: getVisit,
                            variables: { id: visitId },
                            fetchPolicy: 'network-only'
                          });

                          const newVisitData = _.get(res, 'data.getVisit') || {};
                          if(_.isEmpty(newVisitData))
                            return;
                          
                          refetchSingleVisit(newVisitData);
                        } else {
                          console.warn('refetchSingleVisit not found, it will fail to refresh enroll status');
                        }
                      }}
                      enrollButtonProps={{
                        style: { width: '50%' }
                      }}
                      enrollButtonOnClick={() => {}}
                      enrollButtonText='Enroll Patient'
                      templateContainerId='consent-template-in-video-call'
                    />
                  </div>
                  <ConsentFormMiniContainer 
                    visitCategory={category}
                    member={user}
                    enrolledProgramStatus={enrolledProgramStatus}
                  />
                </div>
              </Provider>
            </ApolloProvider>;
          
        // return  <ConsentFormContainer memberId={id} store={store} currentVisit={currentVisit} minimizeButtonHandler={minimizeButtonHandler}/>

    }

    renderHeaderForCA = member=>{
        const { user,currentVisit } = this.props;
        const { profile:{ fullName,gender,birthday,language } } = member;
        return <div style={{padding: '30px 50px 10px', background: 'white', marginBottom: 0}}>
                <Row style={{marginBottom: 10, marginTop: 20}}>
                    <h4  style={{color: '#3178C9', fontSize: 20}}>{fullName}</h4>
                </Row>
                {`${gender} ${helper.getAge(birthday)}y ${language.description} `}
                { this.renderBasicInfo(user, currentVisit)}
                { this.renderConsentForm() }
              </div>
    }


    renderButtons = (visitId)=>{
        const { handleLeaveEvent,props,minimizeButtonHandler } = this;
        const { setModal, currentVisit, setVirtualVisit, refetchVisitList } = props;
        const teams = this.state.teams;
        const currentUserId = sessionStorage.getItem('currentUserId')
        const currentUserStatus = _.first(_.filter(teams,(t)=>t.user.id==currentUserId))||{};
        const { isHost } = currentUserStatus;
        const { category, type, checkinAt } = currentVisit || {};
        const isValidTechVisit = type === 'TECH' && category !== 'INCLINIC';
        const isPopupDisabled = !isValidTechVisit || helpers.checkIsMAOrMD()/*|| !checkinAt*/;

        return<Row style={{ display:'flex',background:'#F6F6F6',alignItems:'flex-end',justifyContent:'flex-end'}}>
                    <Col span={8} className='rightCornerButton firstButton' onClick={()=>{
                        setModal(false);
                        minimizeButtonHandler(true)}
                    }>
                        <div style={{height:30,display:'flex',alignItems:'center',justifyContent:'center'}} >
                            <img src='/image/minimize_button_white.png' width={20}/>
                        </div>
                        Minimize
                    </Col>
                    <Col span={8} className='rightCornerButton' onClick={()=>handleLeaveEvent('LEAVE',visitId)}>
                        <div style={{fontSize: 20, lineHeight: '25px', color: '#32C286',height:30,verticalAlign:'center'}}>...</div>
                        Leave & hold <br/>patient online
                    </Col>
                    {
                        isHost &&
                        <BillableReviewFormPopup
                          placement='bottomRight'
                          isPopupDisabled={isPopupDisabled}
                          visitId={visitId}
                          onSubmit={() => {
                            API.activePatientMutate({
                              eventType: 'CHECK_OUT',
                              visitId
                            }).then(res => {
                              setVirtualVisit(null);
                              Mixpanel.track('leave and hold', 'zoom successfully');
                              notification.close('videoChatCenterNotification');
                              if(refetchVisitList) {
                                refetchVisitList();
                              }
                            })
                            .catch(error=>{
                              console.log(error);
                              message.error(GQLHelper.formatError(error))
                            })
                          }}
                        >
                          <Col span={8} className='rightCornerButton lastButton'
                            onClick={() => {
                              if(isPopupDisabled) {
                                handleLeaveEvent('CHECK_OUT', visitId);
                              }
                            }}>
                              <div style={{height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  <img src={'/image/red_phone.png'} width={20}/>
                              </div>
                              End Meeting
                          </Col>
                        </BillableReviewFormPopup>
                    }
             </Row>
    }

    renderBasicInfo = (user,visit)=>{
        const { profile:{ height,weight,gender,birthday } } = user;
        const { details:{ bpMeasurement, bgMeasurement, temperature,spo2 }, reason } = visit;
        const { latestMeasurements } = this.state; // all data for latestMeasurements are converted
        const latestHeightFt = _.get(latestMeasurements, 'height');
        const latestHeightIn = _.get(latestMeasurements, 'height_inches');
        const latestWeight = _.get(latestMeasurements, 'weight');
        const latestBG = _.get(latestMeasurements, 'BG');
        const latestTemperature = _.get(latestMeasurements, 'temperature');
        const heightInCm = height ? height.value : '';
        const heightInM = heightInCm ? heightInCm/100 : '';
        const heightInInches = height ? convertor.height(height.value, 'cm', 'inch', 0) : '';
        const heightInFeet = height ? convertInchesToFeet(heightInInches) : '';
        const heightLeftInInch = height ? getRemainderAfterConvertingToFeet(heightInInches) : '';
        const weightInKg = weight ? weight.value : '';
        const weightInPounds = weight ? convertor.HS(weight.value, 'kg', 'lbs', 0) : '';
        const age = helper.getAge(birthday);
        const gend = helper.getGender(gender);
        const BMIValue = helper.getBMIValue(weightInKg, heightInM);
        const BMIStatus = helper.getBMIStatus(BMIValue);
        const abw = helper.getABW(heightInInches, weightInKg, gend);
        const abwInPounds = convertor.HS(abw, 'kg', 'lbs', 0);
        const bmr = helper.getBMR(heightInCm, weightInKg, BMIValue, gend, age, abw);
        const systolic_value = _.get(latestMeasurements, 'systolic', _.get(bpMeasurement,'systolic_blood_pressure.value'));
        const diastolic_value = _.get(latestMeasurements, 'diastolic', _.get(bpMeasurement,'diastolic_blood_pressure.value'));
        const heart_rate = _.get(latestMeasurements, 'heartBeat', _.get(bpMeasurement,'heart_rate.value'));
        const blood_glucose = _.get(bgMeasurement, 'blood_glucose.unit') == 'mmol/L' ? convertBG_mmolTomgdl(_.get(bgMeasurement, 'blood_glucose.value')) : _.get(bgMeasurement, 'blood_glucose.value');
        const temperatureVal = _.get(temperature,'value');
        const spo2Value = _.get(latestMeasurements, 'spo2', _.get(spo2,'value'));
        const map = [ { title:'Height',value:`${latestHeightFt || (heightInFeet||'--')}' ${latestHeightIn || (_.isNaN(heightLeftInInch) ? '--' : heightLeftInInch)}"` },
            { title:'Weight (lbs)', value: latestWeight || (weightInPounds||'--') },
            { title:'BMI', value: BMIValue},
            { title:'BMR', value: bmr},
            { title:'ABW', value: abwInPounds},
            { title:'BP (mmHg)',value:`${systolic_value||'--'} / ${diastolic_value||'--'}` },
            { title:'Heart Rate (beats/min)',value:heart_rate||'--' },
            { title:'BG (mg/dL)', value: latestBG || (blood_glucose || '--')},
            { title:'Temperature (F)', value: latestTemperature || (convertCelsiusToFahrenheit(temperatureVal)||'--') },
            { title:'SpO2 (%)',value: spo2Value||'--' },
        ];
        return <div>
            {
                _.map(map,({title,value})=>{
                    if (title === 'BMI') {
                        if (!isNaN(BMIValue)) {
                            return <Row key={title} style={{ fontSize:14,margin:'10px 0px',color:'black' }} id={`bodyInfoVideo-${title}`}>
                                <Col span={13}>{`${title}:`}</Col>
                                <Col span={8} style={{fontWeight:'bold'}}>{`${value} `}(<span style={{color: BMIStatus.color}}>{`${BMIStatus.displayName}`}</span>)</Col>
                            </Row>
                        }
                    }
                    else if (title === 'BMR') {
                        if (bmr) {
                            return <Row key={title} style={{ fontSize:14,margin:'10px 0px',color:'black' }} id={`bodyInfoVideo-${title}`}>
                                <Col span={13}>{`${title}:`}</Col>
                                <Col span={8} style={{fontWeight:'bold'}}>{`${value} kcal/day`}</Col>
                            </Row>
                        }
                    }
                    else if (title === 'ABW') {
                        if (BMIValue > 40) {
                            return <Row key={title} style={{ fontSize:14,margin:'10px 0px',color:'black' }} id={`bodyInfoVideo-${title}`}>
                                <Col span={13}>{`${title}:`}</Col>
                                <Col span={8} style={{fontWeight:'bold'}}>{`${value} lb`}</Col>
                            </Row>
                        }
                    } 
                    else {
                        return <Row key={title} style={{ fontSize:14,margin:'10px 0px',color:'black' }} id={`bodyInfoVideo-${title}`}>
                            <Col span={13}>{`${title}:`}</Col>
                            <Col span={8} style={{fontWeight:'bold'}}>{`${value}`}</Col>
                        </Row>
                    }
                })
            }
            {/*<Row>*/}
                {/*<Col span={24}>Notes:</Col>*/}
                {/*<Col span={24}>{latestReason || reason}</Col>*/}
            {/*</Row>*/}
        </div>
    }


    saveNoteButton = ()=>{
            return <div style={{ display:'flex',justifyContent:'flex-end' }}>
                <Button type='primary' onClick={()=>this.handleSaveEdit()}>Save Data</Button>
            </div>
    }

    renderNoteForm = () =>{
        const { setCreateFollowUp,saveNoteButton } = this;
        return <div style={{padding: '10px 20px',marginBottom: 0}}>
            { renderForm(this,formConfig("noteFormConfig",this.props,setCreateFollowUp,this)) }
            { saveNoteButton() }
        </div>
    }

    getVisitTeam =  () => {
        const { currentVisit } = this.props;
        const visitId = currentVisit.id;
        Client.query({
            query:getVirtualVisitTeam,
            variables:{
                visitId
            },
            fetchPolicy: 'network-only',
        }).then(res => {
            this.setState({
                teams: res.data.getVirtualVisitTeam,
                showLeaveMeetingModal: true
            });
        }).catch(e => {console.log(e)});
    }

    getLoanDeviceInfo = ()=>{
        const { user } = this.props;
        const userId = user.id;
        Client.query({
            query:getLoanDevice,
            variables: {
                memberId:userId
            }
        }).then(({data})=>{
            console.log(data);
            this.setState({
                loanDeviceInfo: _.get(data, 'getLoanDevice', [])
            })
        })
    }
    renderCreateFollowUp = ()=>{
        const { setCreateFollowUp,setAssociatedVisit } = this;
        const { currentVisit,setCurrentVisit,user } = this.props;
        const { associatedVisit,loanDeviceInfo } = this.state;
        const hasLoanDevice = !!loanDeviceInfo;
        return    <Modal visible={true} footer={false} maskClosable={false} closable={true} onCancel={()=>setCreateFollowUp(false)}>
                    <CreateFollowUpComponent user={user}
                                         currentVisit={currentVisit}
                                         associatedVisit={associatedVisit}
                                         setAssociatedVisit={setAssociatedVisit}
                                         hasLoanDevice={hasLoanDevice}
                                         setCreateFollowUp={setCreateFollowUp} setCurrentVisit={setCurrentVisit}/>
                 </Modal>

    }

    render() {
        const { renderHeaderForDoctor, renderPatientFormAndSaveButton,renderHeaderForNurse,props,renderHeaderForCA,renderCreateFollowUp,state,renderButtons } = this;
        const { loading,user,currentRole,currentVisit,showModal} = props;
        const { showCreateFollowUp,loanDeviceInfo } = state;
        const { visitRoles } = currentVisit;
        const hasLoanDevice = !!loanDeviceInfo;
        if(loading){
            return <div>loading</div>
        }

        // {/*<Modal visible={showModal} footer={false} id='videoChatCenter' className='videoChatCenter' maskClosable={false} closable={false}>*/}

        return <div 
          style={{ position:'fixed',display:'flex',flexDirection:'column' }} id='videoChatCenter' 
          className='videoChatCenter'
        >
          <div 
            className="ant-modal-mask" 
            style={{zIndex:500, padding: '50px 150px 225px', overflow: 'auto'}}
          >
            <Row 
              style={{zIndex:500,display:'flex',height: 'fit-content',overflow: 'auto'}}
            >
              <Col span={9} style={{background:'white'}}>
                  { _.includes(IHEALTH_MEMBERS,roleMap[currentRole]) ? renderHeaderForCA(user,currentRole): roleMap[currentRole] == 'Doctor' ? renderHeaderForDoctor(user,currentRole) :renderHeaderForNurse(user,currentRole) }
                  { renderPatientFormAndSaveButton(currentRole,currentVisit) }
              </Col>
                  {/*{ renderLeaveMeetingModal() }*/}
              <Col span={15} style={{background:'#F6F6F6'}}>
                  { showCreateFollowUp&&renderCreateFollowUp() }
                  { renderButtons(currentVisit.id)}
                  <div style={{ margin:10,padding:10,overflow:'scroll' }}>
                      <div style={{ display:'flex',flexDirection:'column',marginBottom:10 }}>
                          <span style={{fontWeight:'bold'}}>Invite people to join</span>
                          <span>{`(Patient scheduled to see ${visitRoles.join(',')})`}</span>
                      </div>
                      <InviteNextStepContainer currentVisit={currentVisit} twoColumns={true}/>
                  </div>
                  <div style={{background:'#F6F6F6' }}>
                      {this.renderNoteForm(currentRole)}
                  </div>
              </Col>
            </Row>
          </div>
        </div>
    }
}

// const userData = graphql(userGQL, {
//     options: (ownProps) => {
//         const id = _.get(ownProps,'currentVisit.member.id');
//         return {
//             variables: {
//                 id
//             },
//             notifyOnNetworkStatusChange: true,
//             fetchPolicy:'network-only'
//         }
//     },
//     props:({ownProps,data})=>{
//         const { loading,user } = data;
//         if(loading){
//             return {
//                 loading
//             }
//         }
//         return {
//             user
//         }
//     }
// });

VideoChatCenterComponent = Form.create({})(VideoChatCenterComponent);
export default (VideoChatCenterComponent);
