import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { goPath, isCurrentUserAdmin } from 'libModule/utils'
import { IHButton } from 'ihcomponent'
import ListTable from './ListTable'
import I18N from 'modulesAll/I18N';
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'

class Component extends React.Component {
  static displayName = 'program/ProgramListAdmin/containers/ProgramListAdminContainer'

  static propTypes = {
    setBreadCrumb:PropTypes.func
  }

  componentDidMount() {
    this.props.setBreadCrumb(`${I18N.get('keywords.Programs')}`)
  }

  buttonElem () {
    const buttonProps = {
        label : `Create New ${I18N.get('keywords.Program')}`,
        size : '',
        type : 'primary',
        onClick : ()=>{
            goPath('/programmes/new')
        }
    }

    return !isCurrentUserAdmin() ? '' : (
      <div className="v-rd">
        <IHButton {...buttonProps} />
      </div>
    )
  }

  render() {
    return (
      <div className="vsm-main-page vsm-program-list-main">
        <div className="vsm-main-table">
          {this.buttonElem()}
          <div className="v-table">
            <ListTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    setBreadCrumb: (path) => dispatch(setBreadCrumb(path)),
  }
}

export default connect(null, mapDispatch)(Component)
