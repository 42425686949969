import { getFieldsRequiredFromSchema, getInitDataFromSchema } from 'libModule/helpers/reducer-helpers'
import { get } from 'lodash'
import { _ } from 'ihcomponent'

export const schema = {
  task: {
    initialValue: '',
    required: true
  },
  assessment: {
    initialValue: '',
    required: true
  },
  survey: {
    initialValue: '',
    required: true
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange, assessmentAndSurveyList, programmeTasks, healthCondition }) => {
  const data = get(assessmentAndSurveyList, 'data', [])

  const assessmentList = _.orderBy(data.filter((item) => {
    return item.type === 'ASSESSMENT' && item.healthCondition === healthCondition
  }).map((assessment) => {
    return {
      name: assessment.name,
      value: assessment.nameKey
    }
  }), [assessment => assessment.name.toLowerCase()], ['asc'])

  const surveyList = _.orderBy(data.filter((item) => {
    return item.type === 'SURVEY'
  }).map((survey) => {
    return {
      name: survey.name,
      value: survey.nameKey
    }
  }), [survey => survey.name.toLowerCase()], ['asc'])

  const vitalList = _.sortBy(programmeTasks, ['type']).filter((item) => {
    return item.type !== 'ASSESSMENT' && item.type !== 'SURVEY' && item.type !== 'BP'
  }).map((vitals) => {
    let optionName
    switch(vitals.type) {
      case 'BG':
        optionName = 'Send Blood Glucose Reading'
        break
      case 'HR':
        optionName = 'Send Blood Pressure / Heart Rate Reading'
        break
      case 'HS':
        optionName = 'Send Weight Reading'
        break
      case 'TM':
        optionName = 'Send Temperature'
        break
      case 'PO':
        optionName = 'Send Blood Oxygen'
        break
      default:
        optionName = ''
    }
    return {
      name: optionName,
      value: vitals.type
    }
  })
  vitalList.push(
    { name: "Send Assessment", value: "ASSESSMENT" },
    // { name: "Send Survey", value: "SURVEY" } // 9/28/17 - JV - Hide per IH-95
  )

  const itemList = [
    [
      {
        key: 'task',
        label: 'Task',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'Select Task',
        initialValue: initData['task'],
        option: vitalList,
        rules : [
          { required : isFieldsRequired.task, message : 'Task is required' }
        ],
        onChange: () => {
          fieldsValue.assessment = {
            name: 'assessment',
            value: ''
          }
          fieldsValue.survey = {
            name: 'survey',
            value: ''
          }
        }
      }
    ],
    [
      {
        key: 'assessment',
        label: 'Assessment',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData['assessment'],
        option: assessmentList,
        rules : [
          { required : isFieldsRequired.assessment, message : 'Assessment is required' }
        ],
      }
    ],
    [
      {
        key: 'survey',
        label: 'Survey',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData['survey'],
        option: surveyList,
        rules : [
          { required : isFieldsRequired.survey, message : 'Survey is required' }
        ],
      }
    ]
  ]

  if (fieldsValue.task.value !== 'ASSESSMENT' && fieldsValue.task.value !== 'SURVEY') {
    delete itemList[1]
    delete itemList[2]
  }
  else if (fieldsValue.task.value === 'ASSESSMENT') {
    delete itemList[2]
  }
  else if (fieldsValue.task.value === 'SURVEY') {
    delete itemList[1]
  }

  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    itemList
  }
}
