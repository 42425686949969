import { emailValidator2, mobileValidator, usernameValidator2, firstAndLastNameValidator, NRICValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields } from 'libModule/utils'
import get from 'lodash/get'
import I18N from 'modulesAll/I18N';
import {goPath} from 'libModule/utils'
const FORMLABELS = I18N.get('patientlist.manualInputForm');

export const schema = {
  identificationValue: {
    initialValue: '',
    required: true,
  },
  employeeId: {
    initialValue: '',
    required: true,
  },
  firstName: {
    initialValue: '',
    required: true
  },
  lastName: {
    initialValue: '',
    required: true
  },
  email: {
    initialValue: ' ',
    required: true
  },
  mobileCountryCode: {
    initialValue: '+1',
    required: false
  },
  mobilePhone: {
    initialValue: ' ',
    required: false
  },
  username: {
    initialValue: '',
    required: true,
    disabled: true
  },
  role: {
    initialValue: '',
    required: true,
    disabled: false
  },
  institution: {
    initialValue: '',
    required: true,
    disabled: false
  },
  department: {
    initialValue: '',
    required: false
  },
  specialty: {
    initialValue: '',
    required: true
  },
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({fieldsValue, onFieldsChange}) => {

  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
  const organization = get(currentUser,'selectedRole.organization') || {};
  const organizationName = organization.name;
  const organizationId = organization.id;
  const departments = organization.departments || [];
  const specialties = organization.specialties || [];
  // const organizationName = get(currentUser, 'allRoles.0.organization.name') || ''
  // const organizationId = get(currentUser, 'allRoles.0.organization.id') || ''
  // const departments = get(currentUser, 'allRoles.0.organization.departments') || []
  // const specialties = get(currentUser, 'allRoles.0.organization.specialties') || []

  fieldsValue.institution.value = organizationId

  const itemList = [
    [
      {
        key: 'basicInfo',
        label: 'Basic Info',
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'identificationValue',
        label: I18N.get('keywords.ProviderNRIC/FIN'),
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['identificationValue'],
        col: { span: 10, offset: 0 },
        disabled: isFieldsDisabled.identificationValue,
        rules : [
          {required : isFieldsRequired.identificationValue, message : `${I18N.get('keywords.ProviderNRIC/FIN')} is required`},
          {validator: (rule, value, callback) => callback(NRICValidator(value))}
        ],
      },
      // {
      //   key: 'employeeId',
      //   label: 'Professional Registration Number',
      //   type: 'text',
      //   labelCol: { span: 24 },
      //   wrapperCol: { span: 24 },
      //   placeholder: '',
      //   initialValue: initData['employeeId'],
      //   col: { span: 10, offset: 0 },
      //   disabled: isFieldsDisabled.employeeId,
      //   rules : [
      //     {required : isFieldsRequired.employeeId, message : 'Professional Registration Number is required'},
      //   ],
      // }
    ],
    [
      {
        key: 'firstName',
        label: 'First Name',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'First Name',
        placeholder: '',
        initialValue: initData['firstName'],
        col: { span: 10, offset: 4 },
        disabled: isFieldsDisabled.firstName,
        rules : [
          {required : isFieldsRequired.firstName, message : 'First Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
        ],
      },
      {
        key: 'lastName',
        label: 'Last Name (Family Name)',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Last Name',
        placeholder: '',
        initialValue: initData['lastName'],
        col: { span: 10, offset: 0 },
        disabled: isFieldsDisabled.lastName,
        rules : [
          {required : isFieldsRequired.lastName, message : 'Last Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
        ],
      }
    ],
    [
      {
        key: 'username',
        label: 'Login ID',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['username'],
        col: { span: 10, offset: 4 },
        disabled: isFieldsDisabled.username,
        rules : [
          {required : isFieldsRequired.username, message : 'Login ID is required'},
          {validator: (rule, value, callback) => callback(usernameValidator2(value))}
        ],
      },
      {
        key: 'role',
        label: 'Role',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'Select Role',
        initialValue: initData['role'],
        col: { span: 10, offset: 0 },
        option: [
          { name: 'Physician(MD)', value: 'Doctor' },
          { name: 'Certified Diabetes Educator (RD)', value: 'Nurse' },
          { name: 'Medical Assistant (CA)', value: 'Medical Assistant'},
          { name: 'Clinic Medical Assistant (MA)', value: 'Clinic Medical Assistant'}
        ],
        disabled: isFieldsDisabled.role,
        rules : [
          {required : isFieldsRequired.role, message : 'Role is required'}
        ],
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      }
    ],
    [
      {
        key: 'contactDetails',
        label: FORMLABELS.contactDetails,
        type: 'label',
        col: { span: 4, offset: 0 }
      },
      {
        key: 'mobileCountryCode',
        label: 'Country Code',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 4, offset: 0 },
        placeholder: '+1',
        initialValue: initData['mobileCountryCode']
        // rules : [
          // {required : isFieldsRequired.mobile, message : 'Country Code is required'}
        // ]
      },
      {
        key: 'mobile',
        label: 'Mobile Phone',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: '',
        initialValue: initData['mobile'],
        col: { span: 6, offset: 0 },
        rules : [
          // {required : isFieldsRequired.mobile, message : 'Mobile Number is required'},
          {validator: (rule, value, callback) => callback(mobileValidator(value))}
        ]
        // className: 'hide-required-mark'
      },
      {
        key: 'email',
        label: 'Email Address',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Email Address',
        placeholder: '',
        initialValue: initData['email'],
        col: { span: 10, offset: 0 },
        rules : [
          {required : isFieldsRequired.email, message : 'Email is required'},
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ]
      }
    ],
    [
      {
        key: 'placement',
        label: 'Placement',
        type: 'label',
        col: { span: 4, offset: 0 },
        disabled: isFieldsDisabled.placement
      },
      {
        key: 'institution',
        label: 'Institution',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'Select Institution',
        initialValue: initData['institution'],
        col: { span: 10, offset: 0 },
        disabled: isFieldsDisabled.institution,
        option: [
          { name: organizationName, value: organizationId }
        ],
        rules : [
          {required : isFieldsRequired.institution, message : 'Institution is required'}
        ],
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
      {
        key: 'department',
        label: 'Department',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'Select Department',
        initialValue: initData['department'],
        col: { span: 10, offset: 0 },
        option: departments.map( (department) => {
          return { name: department.name, value: department.code}
        }),
        rules : [
          {required : isFieldsRequired.department, message : 'Department is required'}
        ],
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
    ],
    [
      {
        key: 'specialty',
        label: 'Specialty',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        placeholder: 'Select Specialty',
        initialValue: initData['specialty'],
        col: { span: 10, offset: 4 },
        option: specialties.map( (specialty) => {
          return { name: specialty.name, value: specialty.code}
        }),
        // rules : [
        //   {required : isFieldsRequired.specialty, message : 'Specialty is required'}
        // ],
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
      {
        key: 'changePasswordBtn',
        label: ' ', // Leave empty space so proper top padding applies
        btnLabel: I18N.get('auth.password.changePw'),
        type: 'button',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 10, offset: 0 },
        showButton: true,
        onClick: () => goPath(`patients/${fieldsValue.id.value}/change-password`),
        className: 'change-password-btn-full'
      }
    ]
  ]

  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    itemList
  }
}
