import React from 'react';
import { Form, Button, Row, Popover, Spin, message, Tooltip, Icon,Modal } from 'antd';

import MonthlyReviewNoticeComponent from '../component/MonthlyReviewNoticeComponent';
import '../style/monthlyReviewPlan.scss';
import MonthlyReviewHeaderComponent from './MonthlyReviewHeaderComponent';
import MonthlyReviewNoteComponent from './MonthlyReviewNoteComponent';
import GoalAssessmentContainer from '../container/GoalAssessmentContainer';
import moment from 'moment';
import { monthlyReviewStatusMap } from "../constant/index";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {createMonthlySummaryReport} from "../API";
import MonthlyReportContainer from "../../monthlyReport/container/MonthlyReportContainer";
import { CGMHelperServices } from '../../CGM/helpers';


const MonthlyReviewBaseComponent = class extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			isSubmitting: false,
			popoverVisible: false,
			monthlyReportId: null
		};
	}

	setIsSubmitting = (isSubmitting) => {
		this.setState({ isSubmitting });
	}

	hidePopover = () => {
    this.setState({ popoverVisible: false });
  };

  handlePopoverVisibleChange = (popoverVisible) => {
    this.setState({ popoverVisible });
  };

	handleSubmit = (e) => {
    if (e) e.preventDefault();
    const { form, onInteraction, onSubmit } = this.props;
    Mixpanel.track('clicked', 'submit', 'monthly_review');
    const excludedCGM = CGMHelperServices.excludeCGMFromValidation(form);
    form.validateFieldsAndScroll(excludedCGM, async (error, values) => {
      if (error) {
        return;
      }
      this.setIsSubmitting(true);
      await onSubmit(values);
      form.resetFields();
      this.setIsSubmitting(false);
      if (onInteraction) onInteraction();
			this.hidePopover();
    });
  }

  handleCancel = () => {
    // this.props.form.resetFields();
		this.hidePopover();
  }

	renderButtonPopOver = () => {
		const { type, form } = this.props;
		const { isSubmitting } = this.state;
		const noteHasValue = !!form.getFieldValue('note');
		const isMTPR = _.includes(type, 'MTPR');

		return (
			<div>
				<p>Are you sure to submit the monthly review? Once submitted, you can only edit the MTPR note in Patient profile {'>'} provider notes .</p>
				<Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					{isMTPR && <Button onClick={this.handleCancel} disabled={!noteHasValue || isSubmitting}>Cancel</Button>}&nbsp;
					<Button type="primary" onClick={() => this.handleSubmit()} disabled={(isMTPR && !noteHasValue) || isSubmitting}>{isMTPR ? 'Submit' : 'Done'}</Button>
				</Row>
			</div>
		);
	}

	handleSendMR = async ()=>{
		const memberId = _.get(this,'props.user.id');
		const refetchMonthlySummaryReport = _.get(this,'props.refetchMonthlySummaryReport');
		try{
			await createMonthlySummaryReport({memberId});
			message.success('Monthly Report Sent');
			await refetchMonthlySummaryReport();
		}catch (e){
			console.log(e);
		}
	}
	setMonthlyReportId =(monthlyReportId)=>{
		this.setState({
			monthlyReportId
		})
	}
	sendMRContent = (lastMonthlySummaryReport)=>{
		const hasMR = !!lastMonthlySummaryReport;
		const sentDate = _.get(lastMonthlySummaryReport,'createdAt');
		const formattedDate = sentDate ? <span style={{ width:'fit-content',textDecoration:'underline',cursor:'pointer' }} onClick={()=>this.setMonthlyReportId(lastMonthlySummaryReport.id)}>{moment(sentDate).format('MM/DD/YYYY')}</span> :'';
		const MRContent =  <div>
			{  hasMR ? <div className={'message'}> {`Monthly summary report sent on `}{formattedDate} Review and send a new one if needed.</div>
				     : <div className={'message'}>Click the button below to send patient the Monthly Summary Report and start MTPR.
						<p style={{ width:'fit-content',textDecoration:'underline',cursor:'pointer',color:'#3177C9' }} onClick={()=>this.setMonthlyReportId(true)}>Preview</p>
					 </div>
			}
		</div>
		return <div className={'sendMR'}>
				{ this.renderPreviewMonthlyReport() }
	    		{  MRContent }
				<Button type="default" className="new-style" style={{ width:200 }} onClick={this.handleSendMR}>
					Send Monthly Report
				</Button>
		</div>
	}
	renderPreviewMonthlyReport = ()=>{
		const { monthlyReportId } = this.state;
		const { currentProgram } = this.props;
		const memberId = _.get(this,'props.user.id');
		return monthlyReportId && <Modal visible={ !!monthlyReportId} onCancel={()=>this.setMonthlyReportId(null)} footer={null}>
			<MonthlyReportContainer id={monthlyReportId} memberId={memberId} currentProgram={currentProgram}/>
		</Modal>

	}

	render() {
		const { setGetGoalsFunc, type, form, measurementCounts, monthlyReviewData, monthlyReviewContents, onInteraction, currentProgram, monthlyReviewSummary, isWorkList,lastMonthlySummaryReport } = this.props;
		const { loadingSummary, loadingMonthlySummaryReport,loadingMonthlyReviewContents } = this.props;
		const { isSubmitting, popoverVisible } = this.state;
		const {id, status } = monthlyReviewData;
		const noteHasValue = !!form.getFieldValue('note');
        const isMTPR = _.includes(type, 'MTPR');
		const sendMR = !lastMonthlySummaryReport;
		const showSendMRContent = isMTPR;
		if(loadingMonthlyReviewContents || loadingMonthlySummaryReport || loadingSummary) {
			return <Spin/>
		}
    const draftInfo = status === monthlyReviewStatusMap.DRAFT ? {
      draftSavedBy: _.get(monthlyReviewData, 'reviewedBy'),
      draftSavedAt: _.get(monthlyReviewData, 'updatedAt'),
    } : {};
		const MSRContent = <div>
				{!isWorkList && <MonthlyReviewNoticeComponent type={type} isMRE={_.includes(type, 'MRE')} />}
				{!isWorkList && <MonthlyReviewHeaderComponent
					type={type}
					generatedDate={moment(_.get(monthlyReviewData, 'createdAt'))}
					dueDate={moment(_.get(monthlyReviewData, 'dueDate'))}
					showDueDate={_.get(monthlyReviewData, 'id', false)}
          draftInfo={draftInfo}
				/>}
				{ showSendMRContent && this.sendMRContent(lastMonthlySummaryReport)}
				</div>

		return (
			<div className={'monthly-review-container' + (isWorkList ? ' workstation' : '')}>
				<div className="monthly-review-body">
					{MSRContent}
					<GoalAssessmentContainer {...this.props} setGetGoalsFunc={setGetGoalsFunc} />
					<hr />
				</div>
				<div className="monthly-review-form">
					<Form onSubmit={this.handleSubmit}>
						<MonthlyReviewNoteComponent
							type={type}
							// onSubmit={onSubmit}
							form={form}
							monthlyReviewContents={monthlyReviewContents}
							measurementCounts={measurementCounts}
							monthlyReviewData={monthlyReviewData}
							// onInteraction={onInteraction}
							currentProgram={currentProgram}
							monthlyReviewSummary={monthlyReviewSummary}
							isWorkList={isWorkList}
						/>
						<Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
							{!isWorkList && isMTPR && <Button onClick={this.handleCancel} disabled={!noteHasValue || isSubmitting}>Cancel</Button>}&nbsp;
							{isWorkList
								? <Popover visible={popoverVisible} trigger="click" onVisibleChange={this.handlePopoverVisibleChange} content={this.renderButtonPopOver()} overlayStyle={{ width: 250 }}>
									<Button type="primary" disabled={(isMTPR && !noteHasValue) || isSubmitting}>{isMTPR ? 'Submit' : 'Done'}</Button>
								</Popover>
								: <Button type="primary" htmlType="submit" disabled={(isMTPR && !noteHasValue) || isSubmitting}>{isMTPR ? 'Submit' : 'Done'}</Button>}
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}
export default Form.create({})(MonthlyReviewBaseComponent);
