import React from 'react';
import PropTypes from 'prop-types';

import Draggable from 'react-draggable';
import { Icon } from 'antd';

import VitalsSummaryComponent from '../component/VitalsSummaryComponent';

import '../style/draggable.scss';

const DEFAULT_POS = { x: 457, y: 30 };

const VitalsSummaryContainer = props => {
  const { availableSummary, tasks, visible, handleClose, ...restProps } = props;

  return (
    <Draggable
      defaultPosition={DEFAULT_POS} // relative to workListModal
      bounds={{ top: -40, left: -600, right: 800, bottom: 650 }} // relative to workListModal
      handle='.vitals-summary-popup-handle'
    >
      <div 
        className='draggable-container'
        style={{ width: 680, height: 400, display: visible ? 'block' : 'none' }}
      >
        <div className='vitals-summary-popup-handle'>
          <span>&nbsp;</span>
          <span className='title'>Vitals Summary</span>
          <Icon type='close' onClick={handleClose}/>
        </div>
        <div className='vitals-summary-popup-content'>
          {
            _.map(availableSummary, type => (
              <VitalsSummaryComponent 
                key={`${props.memberId}-${type}`}
                defaultIntervalKey='month3'
                vitalType={type}
                task={_.find(tasks, { type }) || {}}
                {...restProps}
              />
            ))
          }
        </div>
      </div>
    </Draggable>
  );
};

VitalsSummaryContainer.propTypes = {
  memberId: PropTypes.string.isRequired,
  enrolledProgramId: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  availableSummary: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default VitalsSummaryContainer;