import gql from 'graphql-tag'
import update from 'immutability-helper'

export default gql`
  mutation editBroadcast(
    $id : EID!,
    $startTime : Date,
    $endTime : Date,
    $message : String,
    $title : String
  ){
    editBroadcast(
      id : $id,
      startTime : $startTime,
      endTime : $endTime,
      message : $message,
      title : $title
    ){
      id
      startTime
      endTime
      message
      title,
      status
    }
  }
`

export const updateQueries = {
  broadcastById: (prev, { mutationResult }) => {
    const newBroadcast = mutationResult.data.editBroadcast
    return update(prev, {
      broadcastById: {
        $set: newBroadcast
      }
    })
  },
}
