import {React, BaseComponent, _} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
  defineBaseClassName(){
    return 'IH-CheckboxButtonGroup'
  }

  init(){
    this.type = this.props.type || 'radio'

    this.init = false

    this.state = {
      value : []
    }
    if(!this.init && this.props.initValue){
      this.state.value = this.props.initValue
      this.init = true
    }
  }

  render(){

    const p = this.getClassAndStyleProp()
    return (
      <div {...p}>
        {
          _.map(this.props.option, (item, index)=>{
            return this.renderEachOption(item, index)
          })
        }

      </div>
    )
  }

  renderEachOption(item, index){
    const className = 'ih-each'
      + (_.includes(this.state.value, item.value) ? ' active':'')
      + (item.disabled ? ' disabled' : '')
      + (item.selected ? ' active' : '')
    const p = {
      className,
      onClick : (e)=>{
        e.preventDefault()
        const v = e.target.getAttribute('value')
        if(this.type === 'radio'){
          if(_.includes(this.state.value, v)){
            this.setState({
              value : []
            })
          }
          else{
            this.setState({
              value : [v]
            })
          }

        }
        else{
          if(_.includes(this.state.value, v)){
            _.remove(this.state.value, (l)=>l===v)
            this.setState({
              value : this.state.value
            })
          }
          else{
            this.state.value.push(v)
            this.setState({
              value : this.state.value
            })
          }


        }

        if(this.props.onChange){
          _.delay(()=>{
            this.props.onChange(this.type==='radio'?this.state.value[0]:this.state.value)
          }, 200)

        }
      },
    }

    return (
      <span key={index} value={item.value} {...p}>{item.name}</span>
    )
  }
}

Component.propTypes = {
  type : PropTypes.string, //radio checkbox
  initValue : PropTypes.array,
  onChange : PropTypes.func,
  option : PropTypes.array.isRequired  //{name, value}
}

export default Component
