import type from '../constants'
import {patientDetail} from "../../fake/patients";

export default {
  chatTextInputChange: (channel, text) => {
    return {
      type: type.CHAT_TEXT_INPUT_CHANGE,
      payload: {
        channel,
        text
      }
    }
  },
  addMsg: (channel, msgObj) => {
    return {
      type: type.ADD_MESSAGE,
      payload: {
        channel,
        msgObj
      }
    }
  },
  updateApi: (useChs) => {
    return {
      type: type.UPDATE_API,
      payload: useChs
    }
  },
  initUnreadCounter:(channel,unreadMsgCount)=>{
      return {
        type: type.UPDATE_UNREAD_COUNTER,
        payload:{
            channel,
            unreadMsgCount
        }
    }
  },
  updateUnreadCounter:(channel, channelInfoFromLastMessage)=>{
    return {
      type: type.UPDATE_UNREAD_COUNTER,
      payload:{
        channel,
        channelInfoFromLastMessage
      }
    }
  },
  setCurrentUserId: userId => {
    return {
      type: type.SET_CURRENT_USERID,
      payload: userId
    }
  },
  updateChannelInfo: (...args) =>{
    const [channel,msgObj,isbatch, isFetchingMoreChannels] = args;
    return {
      type:type.UPDATE_CHANNEL_INFO,
      payload:{
        channel,
        msgObj,
        isbatch,
        isFetchingMoreChannels
      }
    }
  },
  addChatHistory: (channel, patientId, messages, timestamp,messageCount,isbatch,toBottom, hasMoreMessage, isOngoing, tagMessageOffset, tagMessageOffsetFrom, fromNotification, fromTimestamp) => {
    return {
      type: type.ADD_HISTORY,
      payload: {
        channel,
        patientId,
        messages,
        timestamp,
        messageCount,
        isbatch,
        toBottom,
        hasMoreMessage,
        isOngoing,
        tagMessageOffset,
        tagMessageOffsetFrom,
        fromNotification,
        fromTimestamp
      }
    }
  },
  clearHistory: () => {
    return {
      type: type.CLEAR_HISTORY,
      payload: {
        messages: []
      }
    }
  },
  setSelectedChannel:(channel) => {
    return {
      type:type.SELECT_CHANNEL,
      payload:channel.trim()
    }
  },
  verifyAndAddChannel:(channelName,patientId, patientObj)=>{
     return {
       type:type.VERIFY_AND_ADD_CHANNEL,
       payload:{
         channelName,
         patientId,
         patientObj
       }
     }
    } ,
  addChannels: channelsObj => {
    return {
      type: type.ADD_CHANNELS,
      payload: channelsObj
    }
  },
  addToUserMap: (newUser,avatarError) => {
    return {
      type: type.ADD_USER_MAP,
      payload: {
        newUser,
        avatarError
      }
    }
  },
  setUserMap: userMap => {
    return {
      type: type.SET_USER_MAP,
      payload: userMap
    }
  },
  updatePNStatus: status => {
    return {
      type: type.UPDATE_PN_STATUS,
      payload: status
    }
  },
  setOrgTeamMap: map => {
    return {
      type: type.SET_ORG_TEAM_MAP,
      payload: map
    }
  },
  setPatientDivHeight: height =>{
    return {
      type: type.SET_PROFILE_DIV_HEIGHT,
      payload:height
    }
  },
  moveToBottom: (val)=>{
    return {
      type:type.SET_TO_BOTTOM,
      payload:val
    }
  },
  setIsFullyLoaded:(flag)=> {
    return {
        type: type.SET_IS_FULLY_LOADED,
        payload: flag
    }
  },
  setHasMoreChannels:(flag)=> {
    return {
        type: type.SET_HAS_MORE_CHANNELS,
        payload: flag
    }
  },
  setLastMSGTOfOrg:(org,timestamp,pageNumber)=>{
    return {
      type:type.SET_LAST_MSG_ORG,
      payload:{
        org,timestamp,pageNumber
      }
    }
  },
  setChannelsLoaded:(flag) =>{
    return {
      type:type.SET_LOADED,
      payload: flag
    }
  },
  setChatTranslation: (translation, translationTimeToken) => {
    return {
      type: type.SET_CHAT_TRANSLATION,
      payload: {
        translation,
        translationTimeToken,
      }
    }
  },
  updateChatTag: (tagObj) => {
    return {
      type: type.UPDATE_CHAT_TAG,
      payload: {
        tagObj
      }
    }
  }
};
