import {Component} from "react";
import React from 'react';
import TaskAssignmentListContainer from '../containers/TaskAssignmentListContainer';
import { Tabs, Button } from 'antd';
import { decryptRole } from 'libModule/utils';
import roleMap from "../../VideoChat/constant/roleMap";
import helper from "../helper/index";
const { TabPane } = Tabs;
let shouldShowTaskAssignment = {
    'Nurse':'RD',
    'Clinic Medical Assistant':'MA',
    'Medical Assistant':'CA',
    "PROVIDER:Clinic Medical Assistant":'MA',
    'PROVIDER:Medical Assistant':'CA',
    'PROVIDER:Nurse':'RD'

}

class TaskAssignmentListContainerWrapper extends Component {
    constructor(){
        super();
        this.state = {
            taskType: '0',
            showModal: false,
            modalData: null
        }

    }
    setTaskType = (taskType)=>{
        this.setState({
            taskType
        })
    }

    setModalData = ({showModal,modalData})=>{
        this.setState({
            showModal,
            modalData
        })
    }

    render(){
        const { taskType,showModal,modalData } = this.state;
        const currentRole = decryptRole();
        if(helper.shouldShowTaskAssignment[roleMap[currentRole]]) {
            return <TaskAssignmentListContainer setTaskType={this.setTaskType}
                                                taskType={taskType}
                                                showModal={showModal}
                                                setModalData={this.setModalData}
                                                modalData={modalData}
            />
        }
        return <div></div>
    }

}

export default TaskAssignmentListContainerWrapper;
