import React from 'react';
import { Row,Switch,Col,message,Radio } from 'antd';
import {from} from "apollo-link";
import API from '../../API/index';
const ClinicFeatureConfigComponent = class extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            orgPermissions:{ }
        }
    }

    renderHeader=(header)=>{
        return header && <h4>{header}</h4>
    }

    setPermissions = (permissionKey,value,cb)=>{
        this.setState({
            orgPermissions:{
                [permissionKey]:value
            }
        },()=>{
            if(cb) cb();
        })
    }

    changeOrgPermissions = ({id,orgPermissions,permissionKey,value,header})=>{
        orgPermissions = _.omit(orgPermissions,'__typename');
        _.set(orgPermissions,permissionKey,value);
        const info = value ? 'turned on' :'turned off';
        this.setPermissions(permissionKey,true,
        ()=>API.editOrgPermission({ orgPermissions,id })
            .then(res=>{
                this.setPermissions(permissionKey,false,()=>message.info(`${header} ${info}`))
        }))
    }

    renderDescription=(description)=>{
        return <span>{description}</span>
    }

    renderCheckBox=({organization,permissionKey,actionTitle,header})=>{
        const { id,orgPermissions={} } = organization;
        const checked  = orgPermissions&&orgPermissions[permissionKey];
        const disabled = _.get(this,`state.orgPermissions.${permissionKey}`);
        return <Row>
                <Col span={18} style={{ fontWeight:'bold' ,color:'#222222'}}>
                    {this.renderActionTitle(actionTitle)}
                </Col>
                <Col span={6}>
                    <Radio.Group disabled={ disabled } value={ checked } onChange={(value)=>this.handleChangeSwitch({value:value.target.value,id,orgPermissions,permissionKey,header})}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Col>
               </Row>
    }

    renderActionTitle=(actionTitle)=>{
        return <span>{actionTitle}</span>
    }
    handleChangeSwitch= ({value,id,orgPermissions,permissionKey,header})=>{
       this.changeOrgPermissions({id,orgPermissions,permissionKey,value,header})
    }

    render() {
        const { renderHeader,renderDescription,renderCheckBox,renderActionTitle,props } = this;
        const { header,description,organization,permissionKey,actionTitle,style={} } = props;
        const { rowStyle={} } = style;
        return <Row style={{ paddingBottom:20,...rowStyle }}>
                    <Col span={24}>
                        { renderHeader(header) }
                    </Col>
                    <Col span={24}>
                        { renderCheckBox({organization,permissionKey,actionTitle,header }) }
                    </Col>
                    <Col span={16}>
                        { renderDescription(description) }
                    </Col>
              </Row>
    }

}

export default ClinicFeatureConfigComponent;
