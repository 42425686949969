import gql from 'graphql-tag'
import Assessment from './schema/Assessment'
import update from 'immutability-helper'
import _ from 'lodash'

export default gql `
  mutation createAssessment (
    $name: String!
    $description: String
    $healthCondition: HealthCondition
    $type: AssessmentType!
    $questions: [ InputAssessmentQuestion ]
    $publish: Boolean
  ) {
    createAssessment (
      name: $name
      description: $description
      healthCondition: $healthCondition
      type: $type
      questions: $questions
      publish: $publish
    ) {
      ${Assessment}
    }
  }
`
export const updateQueries = {
  assessmentList: (prev, { mutationResult }) => {
    const assessment = mutationResult.data.createAssessment
      || mutationResult.data.editAssessment;
    const list = prev.assessmentList.data;

    const index = _.findIndex(list, a => a.id === assessment.id);

    if (index > -1) {
      list[index] = assessment;
    } else {
      list.push(assessment)
    }

    return update(prev, {
      assessmentList: {
        $set: [...list]
      }
    })

  },
  assessment: (prev, { mutationResult }) => {
    return {
      assessment: mutationResult.data.editAssessment || mutationResult.data.createAssessment
    }
  }
}
