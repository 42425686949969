import query from "../query";
import Client from 'libModule/gqlClient';
import {variables} from "../../patientView/myPrograms/constants/MyProgramsConstants";

const { editOrganization,createOrganization,deactivateUser,createUser,editUser,createTeamGQL,editTeamGQL,
    addTeamMembers: addTeamMembersGQL,
    removeTeamMembers: removeTeamMembersGQL,
    editConsentTemplateQuery,
    editTemplateGroupQuery,
    createConsentTemplateQuery,
    createTemplateGroupQuery,
    templateGroupsListQuery,
    editAccountRoleCapacityMutation,
    removeConsentTemplateQuery,removeConsentGroupQuery,addOrgManagersQuery,removeOrgManagersQuery,
    deleteTeamGQL, removeUserRoles, taskAssignmentList, upsertUserRoles, resendOnBoardEmail,
    createOrgSchedule: createOrgScheduleGQL,
    editOrgSchedule: editOrgScheduleGQL,
    editOrgPermissionMutate,
    terminateClinicMutate,userCountInOrg
} = query;
const createOrgMap = (type)=>({
    clinic:{
        accessTmpl:'LEAF',
        leaf: true
    },
    area:{
        accessTmpl: 'GROUP',
        leaf: false
    }
})[type];

const setVarBasedOnType = (type,variables)=>{
    return Object.assign(variables,createOrgMap(type));
}
const editOrg = (variables)=>{
    return Client.mutate({mutation:editOrganization,variables});
}

const createOrg = (type,variables)=>{
    variables = setVarBasedOnType(type,variables);
    return Client.mutate({mutation:createOrganization,variables});
}

const createOrgSchedule = (variables) => {
    return Client.mutate({mutation:createOrgScheduleGQL,variables});
}

const editOrgSchedule = (variables) => {
    return Client.mutate({mutation:editOrgScheduleGQL,variables});
}
const editOrgPermission = (variables)=>{
    return Client.mutate({mutation:editOrgPermissionMutate,variables});
}
const editArea = (variables)=> editOrg(variables);
const createArea = (variables)=> createOrg('area',variables);
const createClinic = (variables)=> createOrg('clinic',variables);
const editClinic = (variables)=> editOrg(variables);
const createClinicSchedule = (variables) => createOrgSchedule(variables);
const editClinicSchedule = (variables) => editOrgSchedule(variables);
const terminateClinic = (variables)=> Client.mutate({mutation:terminateClinicMutate,variables});
//user APIs
const deleteEmployee = (variables) => Client.mutate({ mutation: deactivateUser, variables })//refer to Provider/src/modules/auth/containers/DeleteUserContainer.js
const createEmployee = (variables) =>Client.mutate({ mutation:createUser,variables });//refer to Provider/src/modules/provider/ProviderNew/containers/ProviderNewContainer.js
const editEmployee = (variables)=>Client.mutate({mutation:editUser,variables });//refer to Provider/src/modules/provider/ProviderEdit/containers/ProviderEditContainer.js
const editAccountRoleCapacity = (variables)=>Client.mutate({ mutation:editAccountRoleCapacityMutation,variables });
// set fetchPolicy to prevent empty dropdown after calling removeEmployee
const removeEmployee = (variables) => Client.mutate({ mutation: removeUserRoles, variables, fetchPolicy: 'no-cache'}); //To remove an employee from a clinic
const checkTaskList = (variables) => Client.query({ query: taskAssignmentList, variables}); // To check whether this employee has unresolved tasks
const addEmployee = (variables) => Client.mutate({ mutation: upsertUserRoles, variables}); //To add an employee to a new clinic
const resendOnBoardEmailLink = (variables) => Client.mutate({ mutation: resendOnBoardEmail, variables}); // resend onBoardLink
const addOrgManagers = (variables)=> Client.mutate({ mutation:addOrgManagersQuery,variables});
const removeOrgManagers = (variables)=>Client.mutate( { mutation:removeOrgManagersQuery,variables});
//team APIs
const createTeam = (variables)=>Client.mutate({ mutation:createTeamGQL,variables })
//refer to Provider/src/modules/careteam/createCareTeam/containers/NewCareTeamContainer.js
const editTeam = (variables)=>Client.mutate({ mutation:editTeamGQL,variables});
//refer to Provider/src/modules/careteam/editCareTeam/containers/EditCareTeamContainer.js
const removeTeamMembers = variables =>
  Client.mutate({ mutation:removeTeamMembersGQL,variables });
const addTeamMembers = variables =>
  Client.mutate({mutation:addTeamMembersGQL,variables });
//refer to Provider/src/modules/careteam/editCareTeam/containers/DoctorTable.js
const deleteTeam = (variables)=>Client.mutate({mutation:deleteTeamGQL,variables});
// const assignAreaManager = (variables)=>client.mu
const getUserCountInOrg = (variables)=>Client.query( { query: userCountInOrg,variables,fetchPolicy:'network-only' });

//consent apis
const createConsentTemplate = variables=> Client.mutate({mutation: createConsentTemplateQuery,variables});
const editConsentTemplate = variables=> Client.mutate({mutation: editConsentTemplateQuery,variables});
const createTemplateGroup = variables=>Client.mutate({mutation:createTemplateGroupQuery,variables});
const editTemplateGroup = variables=>Client.mutate({mutation:editTemplateGroupQuery,variables});
const templateGroupsList =()=>Client.query({query:templateGroupsListQuery});
const removeConsentTemplate = variables=>Client.mutate({ mutation:removeConsentTemplateQuery,variables});
const removeTemplateGroup = variables=>Client.mutate({mutation:removeConsentGroupQuery,variables});
const getTeamList = (variables, options = {})=>Client.query({ query: query.updatedTeamListQuery,variables,fetchPolicy:'network-only', ...options })
const getTeam = (variables)=>Client.query({ query:query.getTeam,variables });
export default {
    editArea,
    editClinic,
    createArea,
    createClinic,
    deleteEmployee,
    createEmployee,
    editEmployee,
    addEmployee,
    editAccountRoleCapacity,
    removeEmployee,
    resendOnBoardEmailLink,
    checkTaskList,
    createTeam,
    editTeam,
    removeTeamMembers,
    addTeamMembers,
    deleteTeam,
    editOrg,
    createConsentTemplate,
    editConsentTemplate,
    createTemplateGroup,
    editTemplateGroup,
    templateGroupsList,
    removeConsentTemplate,
    removeTemplateGroup,
    addOrgManagers,
    removeOrgManagers,
    createClinicSchedule,
    editClinicSchedule,
    editOrgPermission,
    terminateClinic,
    getUserCountInOrg,
    getTeamList,
    getTeam

}
