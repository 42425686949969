import React from 'react';
import { Tooltip, Icon } from 'antd';
import I18N from '../../I18N';

import { getMoment, getNextDayFromTo, getTodayFromTo, getNextDay, DATE_FORMAT_WITH_DAY_OF_WEEK  } from '../helpers/visitDateHelpers';

import TodayVisitsComponent from '../components/TodayVisitsComponent';
import NextDayVisitsComponent from '../components/NextDayVisitsComponent';
import AllVisitsComponent from '../components/AllVisitsComponent';

const VISIT_LIST_POLLING = 0 * 60 * 1000; // NO NEED FOR NOW

const VISIT_STATUS_POLLING = 0.4 * 60 * 1000; // 15 seconds

const ALL_VISITS_RANGE_INTERVAL = 3;

const VISITS_DEFAULT_PAGE_SIZE = 10;

const visitTabConfig = [
  {
    tabKey: 'TODAY',
    tabForceRender: true,
    getTabTitle: count => <div>{I18N.get('visit.tab.today')} ({count})</div>,
    getVisitsFromTo: getTodayFromTo,
    getRefetchVisitList: (refetch) => refetch(),
    VisitTabComponent: TodayVisitsComponent,
    shouldPollVisitList: isTabSelected => (isTabSelected && VISIT_LIST_POLLING) || 0,
    shouldPollVirtualStatus: isTabSelected => (isTabSelected && VISIT_STATUS_POLLING) || 0,
  },
  {
    tabKey: 'NEXT_DAY',
    tabForceRender: true,
    getTabTitle: count => <div>
      {I18N.get('visit.tab.nextDay')} ({count})&nbsp;
      <Tooltip title={getNextDay(DATE_FORMAT_WITH_DAY_OF_WEEK)} overlayStyle={{ width: 230 }}>
        <Icon type='info-circle' />
      </Tooltip>
    </div>,
    getVisitsFromTo: getNextDayFromTo, // appointmentFrom/To should not get Sunday?
    getRefetchVisitList: (refetch) => refetch(),
    VisitTabComponent: NextDayVisitsComponent,
    shouldPollVisitList: () => 0,
    shouldPollVirtualStatus: () => 0,
  },
  {
    tabKey: 'ALL',
    tabForceRender: false,
    getTabTitle: () => I18N.get('visit.tab.all'),
    showVisitCount: false,
    getVisitsFromTo: selectedRange => {
      let { from, to } = selectedRange;
      if(!from || !to) {
        const { to: endOfToday } = getTodayFromTo();
        from = getMoment(endOfToday).add(-1 * ALL_VISITS_RANGE_INTERVAL, 'day');
        from = from.startOf('day').valueOf();
        to = getMoment(endOfToday).valueOf();
      }
      return { from , to };
    },
    getRefetchVisitList: (refetch, selectedTabKey) => selectedTabKey === 'ALL' && refetch(),
    VisitTabComponent: AllVisitsComponent,
    shouldPollVirtualStatus: () => 0,
    shouldPollVisitList: () => 0,
    fetchOnNetwork: true,
    defaultPageSize: VISITS_DEFAULT_PAGE_SIZE
  }
];

export default visitTabConfig;