import React from "react";
import moment from "moment";
import helper from '../helper/index';
import {getProviderName, goPath} from "../../../lib/utils";
import {Badge, Checkbox, Icon, Input, notification, Popover} from 'antd';
import API from '../API';
import callphone from '../helper/callphone';
import { FormatHelper, RequestCache } from 'libModule/utils'
import ReferredByMDComponent from '../component/ReferredByMDComponent';
import NumberFormat from "react-number-format";
import I18N from "../../I18N";
import ReferStatusInPatientHeaderComponent from "../../referAndEnroll/component/ReferStatusInPatientHeaderComponent";
import { renderSelfTag } from '../../visitsPage/helpers';
import {checkIfNeedChangeOrg, handleSwitchOrgCB} from "../../workStation/helper";
import {switchOrg} from "../../workStation/API";

const mapToField = {
    'DOCTOR_REFERRAL': 'doctorReferral',
    'NEXT_VISIT_TIME':'nextVisitTime',
    'LAST_CALL_DATE':'callHistories',
    'PINNED_TO_EMR':'pinnedToEMR',
    'LAST_NAME':'member.profile.fullName'
}
const directionMap = {
    'desc':'descend',
    'asc':'ascend',
    'DESC':'descend',
    'ASC':'ascend'
}
const languageOpts = [
                      { code:'EL',description:'English'},{code:"CT",description: 'Cantonese'},
                      { code:'MN',description: 'Mandarin'},{ code:'SP',description: 'Spanish'},
                      { code:'VN',description: 'Vietnamese'},{code:'OTHERS',description: 'Others'}
                     ]
const getSortOrder = (key,localThis)=> {
    const { tabSelected } = localThis.props;
    const { sort = {} } = localThis.props[tabSelected]||{};
    const { field, fieldGraph, order, direction } = sort;
    return (key == field|| key == mapToField[fieldGraph]) ? (order||directionMap[direction]):'';
};
const getSorter = (a,b,path)=>{
    return _.get(a,path)-_.get(b,path);
}
const handlePatientChange = (params)=>{
    const { organizationId,id,name } = params || {};
    const cb =(orgSwitched)=>{
      goPath(`/patients/${id}`);
      if (orgSwitched) helper.simulatePageReload();
    }
    helper.onOrganizationChange({organizationId,name},cb);
}
const renderNameWithLanguage = (fullName,doc)=>{
    const isSelfEnrolled = _.get(doc, 'member.profile.isSelfEnrolled', false);
    const { id,name } = _.get(doc,'organization');
    const patientName = <a herf = "javascript:void(0);" onClick={()=>handlePatientChange({ organizationId:id,name,id:doc.member.id })}>{fullName}{isSelfEnrolled ? renderSelfTag() : ''}</a>;
    const language = _.get(doc,'member.profile.language.description');
    return <div>{patientName}<p style={{ color:'#717171',lineHeight:'20px' }}>{`Spoken: ${language||'--'}`}</p></div>
}
const name = (localThis)=> [{
                                key: 'language', 
                                title: 'Name', 
                                width: '15%',
                                dataIndex: 'member.profile.fullName',
                                render: (r, doc) => renderNameWithLanguage(r,doc),
                                filterMultiple:true,
                                filters: _.map(languageOpts,({code,description}) =>({ text: description,value: code })),
                                filteredValue:(()=>{
                                    const tabSelected = _.get(localThis,'props.tabSelected');
                                    const filteredValue = _.get(localThis,`props.${tabSelected}.filter`);
                                    return _.get(filteredValue,'language',[]);
                                })(),
                            }]
                                 // <a herf = "javascript:void(0);" onClick={()=>goPath(`/patients/${doc.member.id}`)}>{r}</a>}
const enrollmentDate = ()=>[{
    key:'enrollmentDate',
    title:'Enrollment Date',
    dataIndex:'member.profile.programCategories',
    render:(r)=>{
        const categories = _.filter(r,o => o.enrolled);
        const enrolledDate = categories.reduce((prev, curr) => prev < curr.enrolledDate ? prev : curr.enrolledDate, Infinity);
        if (!enrolledDate || enrolledDate === Infinity) {
            return 'N/A';
        } else {
            return moment(enrolledDate).format('MM/DD/YYYY')
        }
    }
    }];
const phone = (localThis)=>[{ key:'phone',title:'Phone#' ,
                     dataIndex:'member.phone',
                     render:(doc,row)=> _.map(doc,(phone,i)=> {
                           const prefix = _.first(_.get(phone,'type'));
                           return<div>
                                    <span>{`${prefix}: `}
                                        <NumberFormat className={'keyclick'} format={`+# (###) ###-####`} value={FormatHelper.phone(phone)}
                                                      displayType={'text'} onClick={()=>callphone(localThis,phone.number,row)}/>
                                    </span>
                                 </div>
                         }
                     )}];
const provider = (localThis)=>[{
    key:'providerId',
    title: I18N.get('provider.label'),
    dataIndex:'member.profile.doctor',
    filterMultiple:true,
    filters: _.map(JSON.parse(sessionStorage.getItem('doctorName')),p =>({ text: getProviderName(p),value: p.id })),
    filteredValue:(()=>{
        const tabSelected = _.get(localThis,'props.tabSelected');
        const filteredValue = _.get(localThis,`props.${tabSelected}.filter`);
        return _.get(filteredValue,'providerId');
    })(),
    render: p =>{
        // if(typeof r=='string') {
        //     if(_.includes(r,',')){
        //         r = r.split(',')[0];
        //     }
        //     const firstAndLastName = r.split(' ');
        //     const lastName = firstAndLastName.length > 1 ? _.last(firstAndLastName) : firstAndLastName[0];
        //     return `Dr. ${lastName}`;
        // }
        // return typeof r=='string' ? getProviderName(r) : '--';
        return getProviderName(p);
    }
}]
const renderNote = (value,maxLen)=>{
    if(value.length>maxLen) {
        return <Popover
            style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
            content={
                <div>
                    {value}
                </div>
            }
        >
            <div>{value.substring(0, maxLen) + '...'}</div>
        </Popover>
    }
    return value;
}
const commonFields1 = (localThis)=>[
    ...provider(localThis),
    {
        key:'callerId',
        title: 'Caller(s)',
        dataIndex:'callers',
        filters:_.map(helper.getAllMembers(),({fullName,id})=>({ text:fullName,value:id })),
        filterMultiple:true,
        filteredValue:(()=>{
            const tabSelected = _.get(localThis,'props.tabSelected');
            const filteredValue = _.get(localThis,`props.${tabSelected}.filter`);
            return _.get(filteredValue,'callerId');
        })(),
        render:(r)=>r.length!=0 ? _.map(r,({ name })=><p>{name}</p>) :'--'
    },
    {
        key:'lastCallDate',
        title: 'Last Called',
        dataIndex: 'callHistories',
        sorter:(a,b)=>getSorter(_.last(a.callHistories),_.last(b.callHistories),'callDate'),
        sortOrder: getSortOrder('callHistories',localThis),
        render:(r)=>{
            const sortedArray = _.sortBy(r,(o)=>-o.callDate);
            const latestCall = _.first(sortedArray);
            return latestCall ? moment(latestCall.callDate).format('MM/DD/YYYY') : '--';
        }
    }
];

const commonFields2 = (localThis)=>! _.get(localThis,'state.showCollapsedColumn') ? [
    {
        key:'recentNote',
        title: 'Last Sticky Note',
        dataIndex:'latestStickyNote',
        render:(r,doc)=>{
            let value = _.get(r,'note')||'--';
            let patientReferral = _.get(doc,'patientRefer');
            let isEnrolled = _.get(patientReferral,'status.enrollStatus') === 'COMPLETED';
            let memberId = _.get(doc,'member.id');
            let organization = _.get(doc,'organization');
            if(isEnrolled) {
                return <ReferStatusInPatientHeaderComponent memberId={memberId} type={'ENROLL'} remoteEnrollment={doc} patientReferral={patientReferral} organization={organization} />
            }
            return renderNote(value,15);
        }
    },
    {
        key:'notes',
        title: 'Sticky Notes',
        dataIndex: 'stickyNotesCount',
        render:(r,doc)=><div onClick={()=>localThis.setShowStickyNoteModal(true,doc)}>
                            <Badge count={r} style={{ backgroundColor:'#AAAAAA',cursor:'pointer'}} showZero={true}/>
                        </div>
    },
    {
        key:'action',
        title: 'Move to',
        render:(r)=>helper.getMoveOptions(localThis,r.status,{ currentDoc: r })
    }
]:[];

const commonFieldsWithPinnedToEMR = (localThis, tabSelected)=> [
        {
            key:'recentNote',
            title: 'Most Recent Sticky Note',
            dataIndex:'latestStickyNote',
            render:(r)=>{
                let note = _.get(r,'note')||'--';
                if(note.length>15) {
                    return <div>{note.substring(0, 16) + '...'}</div>
                }
                return note;
            }
        },
        {
            key:'notes',
            title: 'Sticky Notes',
            dataIndex: 'stickyNotesCount',
            render:(r,doc)=><div onClick={()=>localThis.setShowStickyNoteModal(true,doc)}>
                <Badge count={r} style={{ backgroundColor:'#AAAAAA',cursor:'pointer'}} showZero={true}/>
            </div>
        },
        {
            key:'pinnedToEMR',
            title:'Marked in EMR',
            dataIndex: 'pinnedToEMR',
            filters:[{text:'Pinned',value:true},{ text:'Not Pinned',value: false}],
                // _.map(helper.getAllMembers(),({fullName,id})=>({ text:fullName,value:id })),
            filterMultiple:false,
            filteredValue: _.get(localThis, `props.${tabSelected}.filter.pinnedToEMR`) || null,
            // sorter:(a,b)=>getSorter(a,b,'pinnedToEMR'),
            // sortOrder:getSortOrder('pinnedToEMR',localThis),
            render:(r,doc)=> {
                const value = _.get(r,'value');
                const name = _.get(r,'updatedBy.name');
                return <div>
                    {!value?<Checkbox checked={value}
                        disabled={false}
                        onChange={(e) =>
                            API.checkRemoteEnrollmentTaskHandler({ memberId: _.get(doc, 'member.id'), 'pinnedToEMR': e.target.checked})
                                .then(res=>localThis.props.refetchRemoteEnrollmentList())
                        }
                    />:
                    <Popover style={{ whiteSpace: 'pre-wrap', width:'unset !important' }}
                    content={<p>If this is inaccurate, please manually mark this patient in the doctor's EMR OR go to this patient's worklist to uncheck the box.</p>}
                    >
                        <Checkbox checked={value}
                                disabled={true}
                                onChange={(e) =>
                                    API.checkRemoteEnrollmentTaskHandler({ memberId: _.get(doc, 'member.id'), 'pinnedToEMR': e.target.checked})
                                        .then(res=>localThis.props.refetchRemoteEnrollmentList())
                                }
                        />
                    </Popover>}
                    { value&&<p>{ name }</p> }  
                </div>
            }
        },
        {
            key:'action',
            title: 'Move to',
            render:(r)=>helper.getMoveOptions(localThis,r.status,{ currentDoc: r })
        }
    ];

const latestVisit = localThis=>[
    {
        key:'nextVisitTime',
        title:'Latest Visit',
        dataIndex:'nextVisitTime',
        sorter:(a,b)=>getSorter(a,b,'nextVisit.appointmentAt'),
        sortOrder: getSortOrder('nextVisitTime',localThis),
        render:(r,doc)=> {
            const nextVisit = _.get(doc,'nextVisit.appointmentAt');
            return nextVisit ? moment(nextVisit).format('MM/DD HH:mm') :'--';
        }
    }
]

const nextVNamePhone = (localThis)=>[
        {
            key:'nextVisitTime',
            title:'Latest Visit',
            dataIndex:'nextVisitTime',
            sorter:(a,b)=>getSorter(a,b,'nextVisit.appointmentAt'),
            sortOrder: getSortOrder('nextVisitTime',localThis),
            render:(r,doc)=> {
                const nextVisit = _.get(doc,'nextVisit.appointmentAt');
                return nextVisit ? moment(nextVisit).format('MM/DD HH:mm') :'--';
            }
        },
        ...name(localThis),
        ...phone(localThis)
    ];


const renderTaskColumns = (tasks,localThis)=>{
    return _.map(tasks,({ key,title,extraContent,hideCheckBox })=>({
        key,title,dataIndex:key,
        render:(r,doc)=> {
            const value = _.get(r,'value');
            const name = _.get(r,'updatedBy.name');
            return <div key={key}>{
                   <Checkbox checked={value}
                           disabled={hideCheckBox}
                          onChange={(e) =>
                                    API.checkRemoteEnrollmentTaskHandler({ memberId: _.get(doc, 'member.id'), [key]: e.target.checked})
                                        .then(res=>localThis.props.refetchRemoteEnrollmentList())
                          }
                    />
                }
                { value&&<p>{ name }</p> }
                { extraContent ? extraContent(localThis,doc) : ''}
            </div>
        }
    }))
};
const generalTasks = (localThis)=> {
 const  showCollapsedColumn = _.get(localThis,'state.showCollapsedColumn');
 const  generalTasksList = [
        {
            key: 'nutritionAssessment',
            title: 'Nutrition',
            hideCheckBox:true,
        },
        {
            key: 'techOnBoard',
            hideCheckBox:true,
            title: 'Tech',
        },
        {
            key: 'deviceDelivered',
            extraContent:(localThis,doc)=>helper.shippingInfoContent(localThis,doc),
            title: <div className='titleWithArrow'>
                        Device Delivered
                       { !showCollapsedColumn&&<Icon type="right-circle-o" onClick={()=>localThis.setState({ showCollapsedColumn: true })}/> }
                   </div>
        }
    ]
    return renderTaskColumns(generalTasksList,localThis);
    // return _.map(generalTasksList,({ key,title })=>({
    //     key,title,dataIndex:key,
    //     render:(r,doc)=> {
    //        return <div>
    //             <Checkbox onChange={(e) => API.checkRemoteEnrollmentTaskHandler({
    //                 memberId: _.get(doc, 'member.id'),
    //                 [key]: e
    //             })}/>
    //         </div>
    //     }
    // }))
}
const collapsedTasks = (localThis)=>{
    const showCollapsedColumn = _.get(localThis,'state.showCollapsedColumn');
    return renderTaskColumns(showCollapsedColumn ? [
        {
            key:'carePlanFiled',
            title:'Care Plan Filed',
        },
        {
            key:'consentFiled',
            title: 'Consent Form Filed'
        },
        {
            key:'billingCodeAttached',
            title:<div className='titleWithArrow'>
                    Billing Code Attached
                    { showCollapsedColumn&&<Icon type="left-circle-o" onClick={()=>localThis.setState({ showCollapsedColumn: false })}/> }
                  </div>

        }
    ]:[],localThis);
}
const referredByMD = (localThis, tabSelected)=> [{
  key:'doctorReferral',
  title:'Doctor Referral',
  dataIndex: 'doctorReferral',
  width:120,
  sorter: true,
  sortOrder: getSortOrder('doctorReferral',localThis),
  // sortDirections:['ascend', 'descend','ASC','DESC'],
  filterMultiple: false,
  filters: [{ value:true,text:'Referred'},{ value: false,text:'Not Referred' }],
  filteredValue: _.get(localThis, `props.${tabSelected}.filter.doctorReferral`) || null,
  render:(d,doc)=><ReferredByMDComponent localThis={localThis} remoteEnrollment={doc}/>
}];

const tableMap =(tabSelected,localThis)=>({
    'INIT':[
        ...referredByMD(localThis, tabSelected),
        ...nextVNamePhone(localThis),
        ...commonFields1(localThis),
        ...commonFields2(localThis)
    ],
    'REFERRED':[
        ...referredByMD(localThis, tabSelected),
        ...nextVNamePhone(localThis),
        ...commonFields1(localThis),
        ...commonFields2(localThis)
    ],
    'TBD':[
        ...referredByMD(localThis, tabSelected),
        ...nextVNamePhone(localThis),
        ...commonFields1(localThis),
        ...commonFields2(localThis)
    ],
    'CONFIRMED':[
        ...referredByMD(localThis, tabSelected),
        ...latestVisit(localThis),
        ...name(localThis),
        ...commonFields1(localThis),
        ...generalTasks(localThis),
        ...collapsedTasks(localThis),
        ...commonFields2(localThis)
    ],
    'COMPLETED':[
        ...referredByMD(localThis, tabSelected),
        ...enrollmentDate(localThis),
        ...name(localThis),
        ...commonFields1(localThis),
        ...commonFieldsWithPinnedToEMR(localThis, tabSelected)
    ],
    'EXCLUDED':[
        ...referredByMD(localThis, tabSelected),
        ...name(localThis),
        ...phone(localThis),
        ...commonFields1(localThis),
        ...commonFields2(localThis)
    ]
}[tabSelected]);

export default tableMap;
