import React from "react";
import Main from './main';
import { CellularDevicesContextProvider } from '../../../CellularDevices/context/CellularDevicesContext';


class mainWrapper extends React.Component {
    render(){
        const { props } = this;
        return (
          <CellularDevicesContextProvider
            patientId={_.get(props, 'data.user.id') || _.get(props, 'patientId')}
          >
            <Main {...props}/>
          </CellularDevicesContextProvider>
        );
    }
}

export default mainWrapper;
