import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Radio, Button, Form, Pagination, Checkbox } from 'antd';
import insuranceProviderList from '../../../graphql/insuranceProviderList';
import AutoCompleteComponent from '../../../patientList/main/components/AutoCompleteComponent';
import moment from "moment";
const FIELD_KEY = 'insuranceProviders';

const parseTitle = (props,title)=>{
  if(props.isWorkStation&&title.length){
    return title[0];
  }
  return title;
}
const getColumns = props => [
  {
    key: 'payerId',
    dataIndex: 'payerId',
    title: 'ID',
    width:'10%',
    render: (text) =>  <div>{text}</div>
  },
  {
    key: 'payerName',
    dataIndex: 'payerName',
    title: 'Insurance',
    render: (text, record) => <div>{text}{formatServiceStates(record.description)}</div>
  },
  {
    key: 'isPrimary',
    title: parseTitle(props,'Primary'),
    width:'10%',
    render: (__, record) => (
      <Radio 
        onChange={(e) => props.onRadioChange(true, record, e.target.checked)} 
        checked={record.primary}
        disabled={props.isTableDisabled}
      />
    )
  },
  {
    key: 'isSecondary',
    title: parseTitle(props,'Secondary'),
    width:'10%',
    render: (__, record) => (
      <Radio 
        disabled={props.isSecondaryDisabled || props.isTableDisabled}
        onChange={(e) => props.onRadioChange(false, record, e.target.checked)} 
        checked={!record.primary}
      />
    )
  },
  {
    key: 'removeButton',
    title: '',
    width:'10%',
    render: (__, ___, index) => (
      <Button 
        style={{ padding: '0px 15px 3px' }} 
        onClick={() => props.onRemoveTableData(index)} 
        size='small'
        disabled={props.isTableDisabled}
      >
        x
      </Button>
    )
  },
];

const formatName = (payerName, payerId) => `${payerName || '--'} ${payerId ? `(${payerId})` : ''}`;
const formatServiceStates = (description) => {
  let stateList = _.split(description, ':');
  stateList = _.get(stateList, '1', '').trim() || '';
  return stateList === 'ALL' ? '' : ` - ${stateList}`;
};
const getOldKey = isPrimary => isPrimary ? 'insuranceProvider' : 'insuranceProvider2';

export const getInsuranceProviderName = (userProfile, isPrimary) => {
  if(!userProfile)
    return '';
  
  const { insuranceProvider } = _.filter(userProfile.insuranceProviders, ip => ip.primary === isPrimary)[0] || {};
  return insuranceProvider ? 
          formatName(insuranceProvider.payerName, insuranceProvider.payerId) : 
            (_.get(userProfile, getOldKey(isPrimary)) || '');
};

export const shouldHidePreviousInsurance = (userProfile, isPrimary) => {
  if(!userProfile)
    return true; // unknown/ error case
  
  const { insuranceProvider } = _.filter(userProfile.insuranceProviders, ip => ip.primary === isPrimary)[0] || {};
  return !_.isEmpty(insuranceProvider) || !_.get(userProfile, getOldKey(isPrimary));
};

const initialSearchProps = {
  value: '',
  shouldFilterAllStates: false,
  canSearch: false,
  page: 1
};

const InsuranceComponent = props => {
  const [searchProps, setSearchProps] = useState({...initialSearchProps});
  const [showWarning, setShowWarning] = useState(false);
  const { isWorkStation } = props;
  const [tableData, setTableData] = useState(_.map(props.insuranceProviders, e => ({
    ..._.omit(e.insuranceProvider, '__typename'),
    primary: e.primary
  })));
  const handleSetSearchProps = newSearchProps => setSearchProps({ ...searchProps, ...newSearchProps });

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      handleSetSearchProps({ canSearch: !!searchProps.value });
    }, 500);
    return () => clearTimeout(handleSearch);
  }, [searchProps.value])

  const _setTableData = (newTableData = []) => {
    setTableData(newTableData);
    props.form.setFieldsValue({ [FIELD_KEY]: newTableData });
  };

  const onRemoveTableData = (index) => {
    const newTableData = [...tableData];
    newTableData.splice(index, 1);
    // if there is only 1 insurance left, it must be Primary
    if(newTableData.length === 1) {
      newTableData[0].primary = true;
    }

    _setTableData(newTableData);
    setShowWarning(false);
  };

  const onRadioChange = (isForPrimary, record, isChecked) => {
    let newTableData = [];
    let isPrimarySelected = isForPrimary && isChecked;
    tableData.forEach(data => {
      const temp = {...data};
      if(temp.id === record.id) {
        temp.primary = isPrimarySelected;
      } else {
        temp.primary = !isPrimarySelected
      }
      newTableData.push(temp);
    });    

    _setTableData(newTableData);
  };

  const onSearch = (value) => {
    if(showWarning)
      setShowWarning(false);

    handleSetSearchProps({
      value,
      canSearch: false,
      page: 1
    });
  };

  const onSelect = (value) => {
    const data = JSON.parse(value || null);
    if(tableData.length === 2) 
      return setShowWarning(true);
    
    if(!data)
      return;

    const newTableData = [...tableData];
    data.primary = !tableData.length || !!_.filter(tableData, d => !d.primary).length;
    newTableData.push(data);

    _setTableData(newTableData);
    handleSetSearchProps({
      ...initialSearchProps, 
      shouldFilterAllStates: searchProps.shouldFilterAllStates // don't reset filter
    }); // reset
  };
  const isWorkStationClass = isWorkStation ? 'insurance isWorkStation' :'insurance';
  const insuranceUpdatedAt = _.get(props,'localThis.props.user.profile.insuranceUpdatedAt');

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} className={isWorkStationClass}>
        <AutoCompleteComponent
          queryOptions={{
            query: insuranceProviderList,
            variables: { 
              match: searchProps.value,
              filters: {
                serviceStates: searchProps.shouldFilterAllStates ? ['ALL'] : []
              },
              page: searchProps.page
            },
            skip: !searchProps.canSearch || !searchProps.value
          }}
          autocompleteProps={{
            style:{ width: '33%', marginRight: 8 },
            value: searchProps.value,
            onSearch: onSearch,
            onSelect: onSelect,
            defaultActiveFirstOption: false,
            placeholder: 'Search insurance by name or ID',
            disabled: props.disabled,
            ...(!isWorkStation 
            ? {}
            : {
              dropdownStyle: { width: 'fit-content' },
              dropdownMenuStyle: { width:'fit-content' }
            }),
            optionLabelProp: 'searchStr'
          }}
          processDataSource={(data, OptionLayout) => {
            const insuranceProviderList = _.get(data, 'insuranceProviderList.data') || [];
            const matchTotal = _.get(data, 'insuranceProviderList.pageInfo.total') || 0;
            let newSearchData = _.map(insuranceProviderList, d => {
              const payerInfo = _.omit(d, '__typename');
              return (
                <OptionLayout 
                  key={d.id}
                  value={JSON.stringify(payerInfo)}
                  searchStr="" // reset after selection
                >
                  {formatName(d.payerName, d.payerId)}
                  {formatServiceStates(d.description)}
                </OptionLayout>
              );
            });
            if(newSearchData.length) {
              newSearchData.push(<OptionLayout key='ipl-pagination' value="pagination" disabled>
                <Pagination 
                  current={searchProps.page}
                  total={matchTotal}
                  onChange={(page) => handleSetSearchProps({ page })}
                />
              </OptionLayout>);
            }
            return newSearchData;
          }}
        />
        {_.get(props, 'showFilterCheckbox', true) && <Checkbox
          disabled={props.disabled}
          checked={searchProps.shouldFilterAllStates}
          onChange={e => handleSetSearchProps({ shouldFilterAllStates: e.target.checked })}
        >
          Filter for payers that service all states
        </Checkbox>}
      </div>
      {
        showWarning &&
        <div style={{ fontSize: 15, lineHeight: '18px', color: '#A5A5A5', marginBottom: 8 }}>
          Only 2 insurances can be selected. Please remove one.
        </div>
      }
      { insuranceUpdatedAt && <div style={{fontSize:14, padding:'10px 0px'}}>Insurance Updated At:{ moment(insuranceUpdatedAt).format(' hh:mm A MM/DD/YYYY')}</div> }
      <Table 
        dataSource={tableData}
        rowKey={(record) => record.id}
        columns={getColumns({ 
          isSecondaryDisabled: tableData.length === 1, 
          isTableDisabled: props.disabled,
          onRemoveTableData, 
          onRadioChange,
          isWorkStation
        })}
        locale={{ emptyText: 'No Insurance' }}
        size='small'
        pagination={false}
      />
      <Form.Item label='' style={{ marginBottom: 0 }}>
        {
          // shadow field to store data and display error
          props.form.getFieldDecorator(FIELD_KEY, {
            rules: props.rules || [],
            initialValue: tableData
          })(<div></div>)
        }
      </Form.Item>
    </div>
  );
};

InsuranceComponent.propTypes = {
  insuranceProviders: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  rules: PropTypes.array,
};

export default InsuranceComponent;