import React from 'react';
import {Form, Row, Col, Input, Button, DatePicker, Select, Modal, Popover, message, Tooltip, Icon} from 'antd';
import API from '../../API';
import moment from 'moment';
import CreateUserPopup from '../../User/Component/CreateUserPopup';
import TestUserComponent from '../../../patient/component/TestUserComponent';
import { emailValidator, mobileValidator, firstAndLastNameValidator, usernameValidator2,emailValidator2} from 'libModule/validator';
import { ROLE_NAME } from '../../../../lib/constants';
import I18N from 'modulesAll/I18N';

const dateFormat = 'MM/DD/YYYY';
const DISPLAYED_ROLES = ['Doctor', 'Clinic Medical Assistant', 'Medical Assistant', 'Nurse', ROLE_NAME.HC];

import roleMap from '../../constant/roleMap';
import '../../style/addModal.scss';
import consentHelper from "../../ConsentForm/helper";
import { langOptions } from 'libModule/constants'

import MentorRDIconComponent from '../../User/Component/MentorRDIconComponent';
import Client from 'libModule/gqlClient';
import userList from 'graphqlModule/miniUserList';
import helper from "../../helper";

const { Item } = Form;
const {Option} = Select;


const getAutoUserName = (firstNameValue, lastNameValue) => {
  let autoUsername = '';
  if (firstNameValue && firstNameValue.trim().length > 0) {
    autoUsername += firstNameValue.trim().replace(/\s/g, '').toLowerCase();
  }
  if (lastNameValue && lastNameValue.trim().length > 0) {
    autoUsername += lastNameValue.trim().replace(/\s/g, '').toLowerCase();
  }
  // autoUsername has no leading/trailing spaces or space in-between
  return autoUsername;
}

const CreateMemberFormComponent = class extends React.Component {
    static displayName = 'newAdmin/Clinic/components/CreateMemberFormComponent';
    constructor(props) {
        super(props)
        this.state = {
          selectedRole: null,
          email: null,
          popUpVisible: false,
          isTestUser: false,
          RDList: null
        }
    }

    componentDidUpdate = () => {
      const { form, organization } = this.props;
      const { RDList, loadingRDList } = this.state;
      const hcCredential = form.getFieldValue('hasCredential');
      if(!RDList && !loadingRDList && hcCredential === I18N.get('admin.hcCredential.options').NO) {
        this.setState({ loadingRDList: true }, () => {
          Client.query({
            query: userList,
            variables:(()=>{
                const orgRoles = _.get(organization, 'roles') || [];
                const selectedRoles = ['Nurse'];
                const roles = helper.getRefIDWithRoleCategory(selectedRoles, orgRoles);
                return{
                        count: 500,
                        filters: { roles }
                    }
            })(),
          }).then(({data})=>{
              let RDList = _.get(data,'userList.data') || [];
              this.setState({
                  RDList,
                  loadingRDList: false
              })
          })
        });
      }
    }

    setPopUpVisible = (visible) => {
      this.setState({popUpVisible: visible});
    }

    renderNameInput = () => {
      const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
      return <Row>
          <Col span={12}>
            <Item label='First Name'>
              {getFieldDecorator('firstName', {
                rules: [
                  { required: true, message: 'First Name is required' },
                  { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName')) }
                ]
              })(
                <Input style={{ textTransform: 'capitalize' }}
                       id='firstName'
                       onChange={e => {
                        let v = e.target.value.trim();
                        const firstNameValue = v;
                        const lastNameValue = getFieldValue('lastName');
                        setFieldsValue({ loginID: getAutoUserName(firstNameValue, lastNameValue) });
                      }}/>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label='Last Name'>
              {getFieldDecorator('lastName', {
                rules: [
                  { required: true, message: 'Last Name is required' },
                  { validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName')) }
                ]
              })(
                <Input style={{ textTransform: 'capitalize'}}
                       id='lastName'
                       onChange={e => {
                        let v = e.target.value.trim();
                        const firstNameValue = getFieldValue('firstName');
                        const lastNameValue = v;
                        setFieldsValue({ loginID: getAutoUserName(firstNameValue, lastNameValue) });
                      }}/>
              )}
            </Item>
          </Col>
      </Row>
    }

    renderIDAndDate = () => {
      const { getFieldDecorator } = this.props.form;

      return <Row>
          <Col span={12}>
            <Item label='Login ID' colon={false}>
              {getFieldDecorator('loginID', {
                  rules: [
                    { validator: (rule, value, callback) => callback(usernameValidator2(value)) }
                  ]
              })(
                <Input id='loginID'/>
              )}
            </Item>
          </Col>

          <Col span={12}>
              <Item label='Start Date' colon={false}>
                  {getFieldDecorator('startDate')(
                      <DatePicker format={dateFormat}/>
                  )}
              </Item>
          </Col>
      </Row>
    }

    renderContact = () => {
      const { getFieldDecorator } = this.props.form;
      return <Row>
          <Col span={12}>
            <Item label='Email Address' colon={false}>
              {getFieldDecorator('Email', {
                 rules: [
                  { required: true },
                  { validator: (rule, value, callback) => callback(emailValidator(value)) }
                ]
              })(
                <Input id='Email'
                      onChange={e => {
                        let v = e.target.value.trim();
                        this.setState({email: v})
                      }}/>
              )}
            </Item>
          </Col>

          <Col span={12}>
              <Item label='Phone Number' colon={false} >
                  {getFieldDecorator('phoneNumber', {
                    rules: [
                      { validator: (rule, value, callback) => callback(mobileValidator(value)) }
                    ]
                  })(
                      <Input id='phoneNumber'/>
                  )}
              </Item>
          </Col>
      </Row>
    }
    renderLanguage = ()=>{
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return <Col span={24}>
            <Form.Item label={'Language'}>
                {getFieldDecorator('providerLanguage',{ initialValue: ([{code:'EL'}]).map(l=>l.code) })(consentHelper.multiLanguageSel(langOptions,['EL']))}
            </Form.Item>
        </Col>
    }
    renderDirectAddress = ()=>{
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return<Row>
                <div style={{ display:'flex',alignItems:'center' }}>
                    <Form.Item key="directAddress" label="Direct Address" style={{ width:'inherit' }}>
                        {getFieldDecorator('directAddress',
                            { rules: [{validator: (rule, value, callback) => callback(emailValidator2(value))}]})
                        (<Input />)}
                    </Form.Item>
                    <Tooltip title={I18N.get('admin.directAddress.tooltip')} overlayClassName={'directAddressTooltip'}>
                        <Icon type="info-circle" style={{ marginLeft: 10 }}/>
                    </Tooltip>
               </div>
        </Row>
    }
    renderRole = () => {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const roles  = _.get(this.props, 'organization.roles');

      let options = [];
      _.map(roles, r => {
        if(_.includes(DISPLAYED_ROLES, r.name)){
           options.push({name: r.name, refId: r.refId})
        }
      })

      return <Row>
        <Col span={12}>
          <Item label='Role'>
            {getFieldDecorator('role', {
                rules: [
                  { required: true, message: 'Role is required' }
                ]
            })(
              <Select onSelect={(value, opt) => this.setState({selectedRole: opt.key})}>
                {_.map(options, ( {name, refId} )=> {
                    return <Option value={refId} key={name}>{roleMap[name]}</Option>
                })}
              </Select>
            )}
          </Item>
        </Col>
      </Row>
    }

    renderHCCredential = () => {
      const { getFieldDecorator, getFieldValue } = this.props.form;

      const doesHCHaveCredential = getFieldValue('hasCredential') !== I18N.get('admin.hcCredential.options').NO;

      return this.state.selectedRole === ROLE_NAME.HC &&
      <Row>
        <Col span={12}>
          <Form.Item label={I18N.get('admin.hcCredential.label')}>
            {
              getFieldDecorator('hasCredential', {
                initialValue: null,
                rules: [{ required: true, message: I18N.get('admin.hcCredential.errorMessage') }]
              })(
                <Select>
                  {
                    _.map(I18N.get('admin.hcCredential.options'), (v, k)=><Option key={k} value={v}>
                      {_.capitalize(k)}
                      </Option>)
                  }
                </Select>
              )
            }
          </Form.Item>
        </Col>
        {
          !doesHCHaveCredential &&
          <Col span={13} style={{ fontSize: 12, lineHeight: '16.24px', margin: '-15px 0px 18px' }}>
            {I18N.get('admin.hcCredential.note')}
          </Col>
        }
        {
          !doesHCHaveCredential &&
          <Col span={15} style={{display: 'flex'}}>
              <Form.Item label={I18N.get('admin.mentorRD.label')} style={{width: '100%'}}>
                  {
                      getFieldDecorator('mentorRD', {
                        initialValue: null,
                        rules: [{
                          required: getFieldValue('hasCredential') == I18N.get('admin.hcCredential.options').NO,
                          message: I18N.get('admin.hcCredential.errorMessage')
                        }]
                      })(
                          <Select loading={this.state.loadingRDList}>
                            {
                              _.map(this.state.RDList, (v, k) => {
                                let value = v.id;
                                let hasHealthCoach = _.get(v, 'profile.healthCoach.id');
                                let hasHCMsg = hasHealthCoach ? ' (Matched with another HC) ' : ''
                                let fullName = _.get(v, 'profile.fullName', 'Unknown');
                                return <Option key={value} value={value} disabled={hasHealthCoach}>
                                    {_.capitalize(fullName) + hasHCMsg}
                                </Option>
                              })
                            }
                          </Select>
                      )
                  }
              </Form.Item>
              <MentorRDIconComponent/>
          </Col>
        }
      </Row>;
    };

    renderTestUserCheckBox = () => {
      return <Row>
              <Col span={20}>
                  <TestUserComponent localThis={this}
                                      headerText={'Creating a test provider?'}
                                      contentText={'account'}/>
              </Col>
            </Row>
    }
    onSubmit = () => {
      const { onSelectProvider, setShowCreateModal, form, refetch, refetchList, updateNeedRefetchTeamList } = this.props;
      const { getFieldsValue, resetFields } = form;
      const formValues = getFieldsValue();
      const variables = {
        role: formValues.role,
        username: formValues.loginID ? formValues.loginID : formValues.Email,
        email: formValues.Email,
        isTestUser: this.state.isTestUser,
        commonProfile: {
            firstName: formValues.firstName,
            lastName: formValues.lastName
        },
      }
      if(formValues.phoneNumber){
        variables.phone = [{
          countryCode: '+1',
          number: formValues.phoneNumber,
          type: 'MOBILE'
        }]
      }

      variables.employeeProfile = {
          startDate: formValues.startDate ? formValues.startDate : null,
          'providerLanguage': formValues['providerLanguage'],
          'directAddress':formValues['directAddress']
      }
      

      if(!_.isNil(formValues.hasCredential)) {
        const hasCredential = formValues.hasCredential === I18N.get('admin.hcCredential.options').YES;
        _.set(variables, 'employeeProfile.hasCredential', hasCredential);
        _.set(variables, 'employeeProfile.mentorId', !hasCredential ? formValues.mentorRD : undefined);
      }

      API.createEmployee(variables)
      .then((res) => {
        // show this member to the add-member modal
        const { fullName, startDate } = res.data.createUser.profile;
        const {selectedRole} = this.state;
        let doc = {
          fullName: fullName,
          previousRole: selectedRole,
          newRegistered: true,
          startDate: startDate ? moment(startDate).format(dateFormat) : '-',
          roles: [<div key='role'>{roleMap[selectedRole]}</div>],
          sites: [<div key='site'>{_.get(this.props, 'organization.name')}</div>]
        }
        // add to the state, selectedProviders
        onSelectProvider(res.data.createUser.id, doc);
      }).then(() => {
        this.setPopUpVisible(false);
        setShowCreateModal(false);
        // message sent
        message.success('Invitation Link Sent!');
        // reset the form to enable next form
        resetFields();
        // refetch the user List
        refetchList();
        // adding this function to update the team list after adding employee
        updateNeedRefetchTeamList(true);
        // adding this refetch to update the header
        refetch();
      })
      .catch(err => {
        console.warn(err);
        Modal.error({content: err.message});
      });

    }


    checkValidHandler = () => {
      const {validateFieldsAndScroll} = this.props.form;
      validateFieldsAndScroll((err) => {
          if (!err) {
              this.setPopUpVisible(true)
          }
      });
    }


    render() {
        const { renderNameInput, renderContact, renderIDAndDate, renderRole, renderHCCredential, setPopUpVisible, onSubmit, checkValidHandler, renderTestUserCheckBox,renderLanguage,renderDirectAddress} = this;
        const { email, popUpVisible} = this.state;
        return (
          <div style={{margin: '34px'}}>
              <Row className='header'>
                Create a new user
              </Row>
                  <Row>
                    <Col span={8} className='sub-header'>
                        Basic Information
                    </Col>
                    <Col span={16}>
                      {renderNameInput()}
                      {renderIDAndDate()}
                      {renderContact()}
                      { renderDirectAddress() }
                      {renderLanguage()}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={8} className='sub-header'>
                        Role in this clinic
                    </Col>
                    <Col span={16}>
                      {renderRole()}
                      {renderHCCredential()}
                      {renderTestUserCheckBox()}
                    </Col>
                  </Row>
                  <Row >
                  <Popover overlayClassName='create-user-confirmation-popover'
                    content={<CreateUserPopup onCancel={()=>setPopUpVisible(false)} onCreate={onSubmit} email={email}/>}
                    trigger="click"
                    visible={popUpVisible}
                    onVisibleChange={checkValidHandler}>
                        <Button type='primary' id='create-btn'>Create</Button>
                    </Popover>
                  </Row>
            </div>
        )
    }
}

export default Form.create({
  name: 'create-member-form'
})(CreateMemberFormComponent);