import { React } from 'ihcomponent';
import AssessmentFormContainer from '../containers/AssessmentFormContainer';

export default class extends React.Component {
  static displayName = 'survey/edit/components/CreateComponent'

  componentWillMount() {
    this.props.resetForm();
  }

  render() {
    const { onSave, onCancel } = this.props;

    return (
      <div className="admin-assessment">
        <button className="link-like back" onClick={onCancel}>&lt; Back to Surveys</button>
        <h5>
          <span>New Survey</span>
          <small>* Required</small>
        </h5>

        <AssessmentFormContainer onSave={onSave} onCancel={onCancel} />
      </div>
    );
  }
}
