import API from "../../API/index";
import {Select} from "antd";
import React from "react";
const { Option } = Select;
const createTemplateGroup = (name,cb,arg)=>{

    if(!arg.id){
        API.createTemplateGroup({name})
            .then(res=>{
                if(cb){
                    cb(Object.assign(arg,res))
                }
            })
    }else{
        API.editTemplateGroup({id:arg.id,name})
            .then(res=>{
                if(cb){
                    cb(Object.assign(arg,res))
                }
            })
    }
}
const renderDropDown = (options,selectedLanguages)=> <Select showSearch
                                                 width={200}
                                                 style={{ width:200 }}
                                                 // disabled={props.disabled}
                                                 filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
    {
        _.map(options, ({value, name},index) => <Option value={value} key={index} disabled={_.includes(selectedLanguages,value)}>{name}</Option>)
    }
</Select>

const multiLanguageSel = (options,selectedLanguages,disabled)=> <Select showSearch
                                                                        disabled={disabled}
                                                                        mode="multiple"
                                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
    {
        _.map(options, ({value, name}, index) => <Option value={value} key={index} disabled={_.includes(selectedLanguages, value)}>{name}</Option>)
    }
</Select>


export default {
    createTemplateGroup,
    renderDropDown,
    multiLanguageSel
}