import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getParameterByName, submittingStyle } from 'libModule/utils';
import { IHPanel, IHButton, IHLoading, IHBaseForm } from 'ihcomponent';
import ResetPassword from '../../auth/containers/ResetPasswordContainer';

// Onboard Email with Set Password for Providers
class OnboardPasswordComponent extends Component {
  static displayName = 'onboard/components/OnboardPasswordComponent';

  constructor(props) {
    super(props);
    this.verifyToken = this.verifyToken.bind(this);
    this.state = { emailVerificationResp: false, invalidToken: false };
  }
  componentWillMount() {
    const token = getParameterByName('token');
    sessionStorage.setItem('onboardToken', token);
    this.verifyToken()
      .then( res => {
        if (res && res.data.verifyOnBoardToken) {
          this.setState({ emailVerificationResp: true });
        }
      })
      .catch(err => {
        console.log('err verifying token: ', err);
        this.setState({ emailVerificationResp: false, invalidToken: true });
      })
  }
  componentWillUnmount() {
    this.setState({ emailVerificationResp: false });
  }
  async verifyToken() {
    const { verifyOnBoardToken } = this.props;
    const token = sessionStorage.getItem('onboardToken');
    try {
      return await verifyOnBoardToken({ variables: { token }});
    } catch (e) {
      console.log('Error! [verifyOnBoardToken]: ', e);
      this.setState({ emailVerificationResp: false, invalidToken: true });
    }
  }
  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        {
          this.state.emailVerificationResp ? <ResetPassword />
          : this.state.invalidToken ? <h1 style={{ textAlign: 'center' }}>This link has expired. Please contact iHealth to receive a new link.</h1> : null
        }
      </div>
    );
  }
}

OnboardPasswordComponent.propTypes = {
  verifyOnBoardToken: PropTypes.func
}

export default OnboardPasswordComponent;