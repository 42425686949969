import { ROLE_NAME } from '../../../lib/constants';

export default {
  'ADMIN:Admin': 'Admin',
  'SUPER_ADMIN:Super Admin': 'Super Admin',
  'Nurse':'RD',
  'Doctor':'Provider',
  'Clinic Medical Assistant':'MA',
  'Medical Assistant':'CA',
  'PROVIDER:Doctor':'Provider',
  "PROVIDER:Clinic Medical Assistant":'MA',
  'PROVIDER:Medical Assistant':'CA',
  'PROVIDER:Nurse':'RD',
  'OTHER:Vendor': 'Vendor',
  [ROLE_NAME.HC]: 'HC',
  [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
}