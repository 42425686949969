import { React, IHRadio, _ } from 'ihcomponent';
import { renderValidatableInput } from '../../../util';

export default (props) => {
  const { onChange, options } = props;

  const RADIO_OPTIONS = _.map(Array(10), (v, i) => ({ name: i + 1, value: i + 1 }));

  const onOptionChange = (value, index) => {
    options[index].meaning = value;
    onChange(options);
  };

  return (
    <div className="scaled">
      <IHRadio option={RADIO_OPTIONS} disabled type="radioButton" />

      <div className="vertical-line left" />
      {renderValidatableInput(options[0].meaning, {
        onChange: ({ target }) => onOptionChange(target.value, 0),
      }, 'left-input')}

      <div className="vertical-line right" />
      {renderValidatableInput(options[9].meaning, {
        onChange: ({ target }) => onOptionChange(target.value, 9),
      }, 'right-input')}
    </div>
  );
};
