import editOrganization from "../../superAdmin/organization/query/editOrganization";
import createOrganization from "../../superAdmin/organization/query/createOrganization";
import {deactivateUser} from 'modulesAll/graphql/deactivateUser'
import createUser from 'graphqlModule/createUser'
import editUser from 'graphqlModule/mutation/editUser';
import createTeamGQL from 'modulesAll/graphql/createTeam'
import editTeamGQL from 'modulesAll/graphql/editTeam'
import addTeamMembers from '../../careteam/query/addTeamMember';
import removeTeamMembers from '../../careteam/query/removeTeamMember';
import deleteTeamGQL from 'modulesAll/graphql/deleteTeam';
import organizationList from 'modulesAll/superAdmin/organization/query/organizationList'
import organization from "../../superAdmin/organization/query/organization";
import removeUserRoles from 'graphqlModule/mutation/removeUserRoles';
import taskAssignmentList from 'modulesAll/taskAssignment/query/taskAssignmentList';
import upsertUserRoles from 'graphqlModule/mutation/upsertUserRoles';
import resendOnBoardEmail from 'modulesAll/graphql/resendOnboardEmail';
import organizationListWithOnlyRoles from 'modulesAll/superAdmin/organization/query/organizationListWithOnlyRoles';
import teamList, { updatedTeamListQuery } from 'graphqlModule/teamList';
import getTeam from 'graphqlModule/team'
import gql from 'graphql-tag';
import consentFormQuery from '../query/consentFormQuery';
const {   editConsentTemplateQuery,
    editTemplateGroupQuery,
    createConsentTemplateQuery,removeConsentGroupQuery,
    createTemplateGroupQuery,templateGroupsListQuery,removeConsentTemplateQuery }  = consentFormQuery;
const userListWithOnlyRoles = gql` query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                id
                registeredAt
                allRoles{
                    refId
                    name
                    organization{
                        id
                        name
                        leaf
                        internalName
                        parent {
                          id
                          name
                        }
                    }
                }
                lastLogIn{
                    id
                }
                phone{
                    number
                }
                email{
                    address
                }
                profile{
                    
                    fullName
                    ...on EmployeeProfile {
                        startDate
                        mentor{
                            id
                            profile{
                                fullName
                        }
                      }
                      healthCoach {
                        id
                        profile {
                            fullName
                        }
                      }
                    }
                }
            }
            pageInfo{
                total
                lastPage
          
            }  
        }
    }`;
const userListWithOnlyRolesWithTeams = gql` query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!
    ) {
        userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data {
                id
                team{
                    id
                    name
                }
                allRoles{
                    refId
                    name
                    organization{
                        id
                        name
                        leaf
                        internalName
                        parent {
                          id
                          name
                        }
                    }
                }
                lastLogIn{
                    id
                }
                phone{
                    number
                }
                email{
                    address
                }
                profile{
                    
                    fullName
                    
                    ...on EmployeeProfile {
                        startDate
                        mentor{
                            id
                            profile{
                                fullName
                        }
                      }
                      healthCoach {
                        id
                        profile {
                            fullName
                        }
                      }
                    }
                }
            }
            pageInfo{
                total
                lastPage
          
            }  
        }
    }`;

const userListWithOnlyRolesSummary = gql` query userList(
        $page: Int,
        $count: Int,
        $sort: UserListSort
        $filters: UserListFilters!)
        {
             userList(
            page: $page,
            count: $count,
            filters: $filters,
            sort: $sort
        ) {
            data{
              
              allRoles{
                name
                refId
                 organization{
                        id
                        name
                        leaf
                        internalName
                        parent {
                          id
                          name
                        }
                    }
              }
              phone{
                    number
                }
                email{
                    address
                }
                profile{
                    
                    fullName
                    ...on EmployeeProfile {
                        startDate
                        mentor{
                            id
                            profile{
                                fullName
                        }
                      }
                      healthCoach {
                        id
                        profile {
                            fullName
                        }
                      }
                    }
                
            }
            }
            pageInfo{
                total
                lastPage
          
            }  
        }
        }
`
const simpleOrgList = gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
				createdAt
				name,
                brandedName,
                internalName,
                identifier,
                leaf,
                active,
                description,
				timezone,
                programParticipation,
				orgSummary{
        			    clinicCount
        			    areaCount
        			    roleCounts{
        			        roleType
        			        count
        			    }
        			}
				address{
					type
        			streetName
        			streetNumber
        			floor
        			unit
        			city
        			state
        			postCode
        			country{
          				description
          				code
        			}
                }
                managers{
                    fullName
                }
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;
const orgListWithOnlyNameAndId =gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
				name,
				brandedName,
				internalName
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;
const orgListWithNameAndPermissions = gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
				name,
				brandedName,
				internalName
				orgPermissions{
				    enableABTesting
				}
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;
const orgListForSearch = gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
                name,
                internalName
				leaf
				createdAt,
				startDate,
				managers{
				    id
				    fullName
				}
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;
const orgListForCreateUser = gql`
	query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
		organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
			data {
				id,
				createdAt
				name,
				brandedName,
                identifier,
                internalName,
                leaf,
                active,
                description,
				timezone,
				roles{
					refId,
					name
                }
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;

const addOrgManagersQuery = gql`mutation addOrgManagers($organizationId:EID!,$managerIds:[EID!]!){
  addOrgManagers(organizationId:$organizationId,managerIds:$managerIds){
   id
  }
}`;

const removeOrgManagersQuery = gql`mutation removeOrgManagers($organizationId:EID!,$managerIds:[EID!]!){
  removeOrgManagers(organizationId:$organizationId,managerIds:$managerIds){
   id
  }
}`;

const createOrgSchedule = gql`
    mutation createOrgSchedule($organizationId:EID!, $affectiveDays: [DayOfWeek], $blockingRoles: [RoleType], $startTime: Date, $endTime: Date) {
        createOrgSchedule(
            organizationId: $organizationId,
            affectiveDays: $affectiveDays,
            blockingRoles: $blockingRoles,
            startTime: $startTime,
            endTime: $endTime,
        ) {
            id
            affectiveDays
            blockingRoles
            startTime
            endTime
        }
    }
`;

const editOrgSchedule = gql`
    mutation editOrgSchedule($id: EID!, $affectiveDays: [DayOfWeek], $blockingRoles: [RoleType], $startTime: Date, $endTime: Date) {
        editOrgSchedule(
            id: $id,
            affectiveDays: $affectiveDays,
            blockingRoles: $blockingRoles,
            startTime: $startTime,
            endTime: $endTime,
        ) {
            id
            affectiveDays
            blockingRoles
            startTime
            endTime
        }
    }
`;

const getTeamTotalChannels = gql`
  query getTeamTotalChannels($teamId: EID!) {
    getTeamTotalChannels(teamId: $teamId)
  }
`;

const getTeamMemberTotalChannels = gql`
  query getTeamMemberTotalChannels($teamMemberId: EID!) {
    getTeamMemberTotalChannels(teamMemberId: $teamMemberId)
  }
`;
const editOrgPermissionMutate = gql`mutation editOrganization($id:EID!,$orgPermissions:OrgPermissionsInput){
  editOrganization(id:$id,orgPermissions:$orgPermissions){
      id
      name
      brandedName
      internalName
      logo
      description
      operationModel
      programParticipation
      orgPermissions {
        analyticsAPI
        useDefaultMobile
        enableABTesting
        pinnedToEMRRequired
        enableAutoMessage
        otherCCMProvider
        keepOwnDevices
        supportClear
        supportLoanerPhone
      }
      templateGroups {
        id
        name
      }
    }
}
`
const terminateClinicMutate = gql`mutation terminateClinic($id:EID!){
  terminateOrganization(id:$id)
}`
const editAccountRoleCapacityMutation = gql`
mutation editAccountRoleCapacity($id:EID!,$capacitys:[RoleCapacityInput]) {
  editAccountRoleCapacity(id:$id,capacitys:$capacitys){
    capacitys{
      role
      capacity
    }

  }
}`;

const userCountInOrg = gql`query count($rootId:EID){
    getStaffSummary(rootId: $rootId){
      staffCount{
            role
            count
        }
        areaAdmin{
            id
            firstName
            lastName
        }
        siteAdmin{
            id
            firstName,
            lastName
        }
    }
}`
const orgsWithOnlyId = gql`query organizationList($page:Int, $count:Int,$filters: OrganizationFilter,$search:OrganizationSearch,$sort:OrganizationSort) {
    organizationList(page: $page, count: $count,filters: $filters,search:$search, sort: $sort) {
        data {
            id
            leaf
            name
            internalName
            createdAt
  }
}
}`
const getChildrenOrgsQuery = gql`query count($rootId:EID!, $leaf: Boolean = false){
    getChildrenClinics(rootId:$rootId,leaf: $leaf){
        id
        name
        leaf
    }
}`;

export default {
    editOrganization,
    createOrganization,
    deactivateUser,
    createUser,
    editUser,
    createTeamGQL,
    editTeamGQL,
    addTeamMembers,
    removeTeamMembers,
    deleteTeamGQL,
    organizationList,
    organization,
    removeUserRoles,
    taskAssignmentList,
    upsertUserRoles,
    resendOnBoardEmail,
    userListWithOnlyRoles,
    userListWithOnlyRolesWithTeams,
    organizationListWithOnlyRoles,
    editConsentTemplateQuery,
    editTemplateGroupQuery,
    createConsentTemplateQuery,
    createTemplateGroupQuery,
    templateGroupsListQuery,
    removeConsentTemplateQuery,
    simpleOrgList,
    removeConsentGroupQuery,
    orgListForCreateUser,
    removeOrgManagersQuery,
    addOrgManagersQuery,
    orgListWithOnlyNameAndId,
    orgListWithNameAndPermissions,
    createOrgSchedule,
    orgListForSearch,
    editOrgSchedule,
    getTeamTotalChannels,
    getTeamMemberTotalChannels,
    editOrgPermissionMutate,
    terminateClinicMutate,
    editAccountRoleCapacityMutation,
    userListWithOnlyRolesSummary,
    userCountInOrg,
    orgsWithOnlyId,
    teamList,
    updatedTeamListQuery,
    getTeam,
    getChildrenOrgsQuery
}
