import React from 'react'
import { Card } from 'antd';
import {VITAL_TYPE_ENUM} from "../../referAndEnroll/constant";
import { MONTHLY_REPORT_VITAL_MAP,MONTHLY_REPORT_VITAL_TYPE_ENUM } from '../constant/index';
import {VitalCardComponent} from "./VitalCardComponent";
import moment from "moment-timezone";
const MonthlyReportComponent = class extends React.Component{
	renderHeader = (monthlyReport)=>{
		const { startDate,endDate } = monthlyReport;
		return <div>{`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}</div>
	}
	render() {
		const { monthlyReport,currentProgram } = this.props;
		const enrolled_vitals = _.get(currentProgram,'tasks',[]).map(t=>t.type);
		const vitals = _.intersection(Object.keys(MONTHLY_REPORT_VITAL_TYPE_ENUM),enrolled_vitals);
		return <div>
			{ this.renderHeader(monthlyReport||{}) }
			{ monthlyReport ? vitals.map(vital=><VitalCardComponent key={vital} vitalType={vital} monthlyReport={monthlyReport[vital]}/>) :'' }
		</div>
	}
}
export default MonthlyReportComponent;
