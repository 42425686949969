import { React, IHInput } from 'ihcomponent';

export default props => (
  <div className="input-long">
    <IHInput
      type="textarea"
      rows={4}
      disabled
    />
  </div>
);
