import { React, message} from 'ihcomponent';
import { connect } from 'react-redux';
import Client from 'libModule/gqlClient';
import medicationList  from  'modulesAll/graphql/medicationList';
import getVariables  from '../query/variables';
import { Form, Select, Button, Radio, Modal, Row, Col, Checkbox, DatePicker, Timeline, Input, AutoComplete } from 'antd';
import "antd/lib/button/style/index.css";
import '../style/style.scss';
import actions from "../../medicine/actions";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { GQLHelper } from 'lib/utils'
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import { API } from '../../timer/query/index';
import medTypes from '../constants/medTypes.js';
import moment from 'moment';
import {graphql,compose} from "react-apollo/index";
import medNameList from "../../graphql/medNameList";
import I18N from '../../I18N';

const DATE_FORMAT = 'MM/DD/YYYY';

const Item = Timeline.Item;
const { stopTimer,updateInterventionDetails } = timerActions.default;
const FormItem = Form.Item;
const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment();
}
let MedicineModalComponent = class extends React.Component{
    static displayName ='AddMedicineComponent';
    constructor(props){
        super(props);
        this.renderInputFields.bind();
        this.renderRadioField.bind();
        this.renderFooter.bind();
        this.createMed.bind();
        this.onSubmit.bind();
        this.removeMed.bind();
        this.showDeleteConfirm.bind();
        this.state = {
            showMedAddedMsg: false,
            showAddedMedHistory: false,
            showMedInTakingMsg : false,
            endDate: moment(),
            dataSource: [],
            searchMatches: [],
            isDisabled:true
        }
    }

    static getDerivedStateFromProps = (nextProps, state) => {
      const newState = {...state};
      const medNameList = _.uniqBy(nextProps.medNameList, _.toLower);
      if(!nextProps.loadingMedNameList &&
         !_.isEqual(newState.dataSource, medNameList)
      ){
        newState.dataSource = medNameList;
        newState.searchMatches = medNameList;
      }
      return newState;
    }

    componentWillUnmount(){
        if(this.props.modal.type=='update') {
            this.props.isUpdated(false);
        }
    }

    setDisabledMode = (isDisabled)=>{
        this.setState({
            isDisabled
        })
    }

    renderRadioField(){
        const { getFieldDecorator } = this.props.form;
        const data = this.props.curMed||{};
        const other =  {
                key: 'otherMD',
                label:"This medication is not prescribed by this patient's primary provider",
                type:'checkbox',
                // cols:24,
                valuePropName:'checked',
                initialValue: data.otherMD,
                required: false,
                message: null
            }
            return <Row>
                <Col span={other.cols}>
                    <FormItem
                        // label={other.label}
                        key={other.key}
                        style={{display: 'flex', flexDirection: 'column'}}
                    >
                        {getFieldDecorator(other.key, {
                            rules: [{
                                required: other.required,
                                message: other.message || null,
                            }],
                            initialValue: other.initialValue,
                            valuePropName: other.valuePropName || 'value'

                        })
                        (
                            <Checkbox onChange={() => this.setDisabledMode(false)} style={{fontWeight: '400'}}>{other.label}</Checkbox>
                        )}
                    </FormItem>
                </Col>
            </Row>
    }

    renderRadioGroup(){
        const { getFieldDecorator,setFieldsValue } = this.props.form;
        const data = this.props.curMed||{};
        const typeList = [
            {
                key: 'TAKING',
                label:'Taking'
            },
            {
                key: 'TAKING_IRREGULARLY',
                label: 'Taking Irregularly'
            },
            {
                key: 'NOT_SURE',
                label:'Not Sure'
            },
            {
                key: 'NOT_TAKING',
                label:'Not Taking'
            }
        ];
        const itemList = [
            {
                label: 'Status',
                key: 'status',
                message: 'Please select a Status',
                initialValue: data.status || null,
                cols: 8,
                render: () => <Select placeholder='Select' onChange={() => this.setDisabledMode(false)}>
                    { _.map(typeList,(value,index)=> <Select.Option value={value.key} key={index}>{value.label}</Select.Option>) }
                </Select>
            },
            {
                key: 'dosage',
                label: "Dosage",
                type: 'textarea',
                initialValue: data.dosage||'',
                className: 'textareaInput',
                size:'default',
                required:true,
                message:'Please enter dosage',
                cols:4,
                render: () => <Input cols={4}
                    onBlur={(e)=>this.handleOnBlur(e,dosage.key)}
                    onChange={()=>this.handleOnChange(false)}/>
            }
        ]

        return <Row style={{ display:'flex' }}>
        {_.map(itemList, (item, i) =>
                        <Col span={item.cols}>
                            <FormItem
                                label={item.label}
                                key={item.key}
                                required={item.required}
                                style={{display:'flex',flexDirection:'column', marginLeft: i === 0 ? 0 : 13}}
                            >
                                {
                                    getFieldDecorator(item.key,{
                                        rules: [
                                            { required: item.required, message: item.message || ""},
                                        ],
                                        initialValue: item.initialValue || null
                                    })
                                    (
                                        item.render()
                                    )
                                }
                            </FormItem>
                        </Col>
        )}
        </Row>
    }

    handleOnBlur = (e,key)=>{
        const { uniqueMedNames,medicationList } = this.props;
        const name = e.target.value;
        const addedMed = _.find(medicationList.data,m=> m.name == name);
        if(_.indexOf(uniqueMedNames,name)!=-1) {
            if(!addedMed.endDate || addedMed.endDate>= new Date().getTime()){
                this.setState({
                    showMedInTakingMsg: addedMed
                })
            }else {
                this.setState({
                    showMedAddedMsg: name
                })
            }
        }else{
            this.setState({
                showMedAddedMsg: false
            })
        }

    }

    handleOnChange = (isDisabled)=>{
        const { showMedInTakingMsg,showMedAddedMsg } = this.state;
        if( showMedAddedMsg ||showMedInTakingMsg ) {
            this.setState({
                showMedAddedMsg: false,
                showMedInTakingMsg: false,
            })
        }
        this.setState({
            isDisabled
        })
    }

    medAddedMsg = ()=>{
        return <p>*Patient has taken this medication before.
                    <a href="javascript:void(0)" onClick={()=>this.setState({ showAddedMedHistory:true })}>Click here </a>
                to check the medication history.
               </p>
    }

    renderMedTaking = ()=>{
        return <p>*Patient is current taking this medication.
                   To change the dosage, please click the button below
               </p>
    }

    renderInputFields (showMedInTakingMsg,showMedAddedMsg){
        const { getFieldDecorator } = this.props.form;
        const data  = this.props.curMed||{};
        const { modal } = this.props;

        return <div>
                    { this.renderNameAndDosage(data,modal,getFieldDecorator,showMedInTakingMsg)}
                    { showMedAddedMsg ? this.medAddedMsg() : null}
                    { !showMedInTakingMsg ? this.renderStartDate(data,modal,getFieldDecorator,showMedInTakingMsg) : '' }
                </div>
    }

    renderAddedMedHistory = ()=>{
        const { medicationList } = this.props;
        const { showMedAddedMsg } = this.state;
        const addedMed = _.find(medicationList.data,m=> m.name == showMedAddedMsg);
        const { histories } = addedMed;
        const filteredHis = _.filter(histories,h=>h.field);
        return <div>
                {
                    filteredHis.length > 0 ?
                        <div style={{ padding:20 }}>
                            <h4 style={{ marginLeft: 18,marginBottom: 30 }}>{ showMedAddedMsg }</h4>
                            <Timeline mode={'alternate'}>
                                {_.map(filteredHis, ({originalValue, newValue, field, modifiedAt}, index) =>
                                    <div style={{ display:'flex' }} key={index}>
                                        <li className='ant-timeline-item' style={{ marginRight:10 }}>
                                            <div className='ant-timeline-item-content'>{ moment(modifiedAt).format('MM/DD/YYYY') }</div>
                                        </li>
                                        <Item key={index}>{`${_.upperFirst(field)} changed ${originalValue} to ${newValue}`}</Item>
                                    </div>)
                                }
                            </Timeline>
                        </div>
                        :
                        <div>
                            <h4 style={{ marginLeft: 20 }}>{ name }</h4>
                            <span style={{ marginLeft: 20 }}>No History</span>
                        </div>
                }
                <Button onClick={()=>this.setState({ showAddedMedHistory: false })}>Back</Button>
            </div>
    }

    handleMedSearch = _.debounce(value => {
      const { dataSource } = this.state;
      let results = [...dataSource];
      if(!_.isEmpty(value)) {
        const input = _.toLower(value);
        results = _.filter(dataSource, m => _.includes(_.toLower(m), input));
        results = _.sortBy(results, r => _.toLower(r).indexOf(input));
      }
      this.setState({
        searchMatches: results
      });
    }, 500);

    renderNameAndDosage = (showMedInTakingMsg)=>{
        const { getFieldDecorator } = this.props.form;
        const { searchMatches } = this.state;
        const data  = this.props.curMed||{};
        const { modal } = this.props;
        let itemList =
            [
                // [
                //     {
                //         key: 'dosage',
                //         label: "Dosage",
                //         type: 'textarea',
                //         initialValue: data.dosage||'',
                //         className: 'textareaInput',
                //         size:'default',
                //         required:true,
                //         message:'Please enter dosage',
                //         cols:8
                //     }
                // ]
                [{
                    key: 'type',
                    label: "Medication Type",
                    required:true,
                    initialValue:data.type,
                    cols: 8,
                    render: () => <Select placeholder='Select' onChange={() => this.setDisabledMode(false)}>
                        {
                            _.map(medTypes,({name,value},index)=><Select.Option value={value} key={index}>{name}</Select.Option> )
                        }
                    </Select>
                }]
            ]
        // if(modal.type === 'add') {
            itemList[0].unshift(
                {
                    key: 'name',
                    label: "Medication Name",
                    className: 'textareaInput',
                    type: 'text',
                    required: true,
                    initialValue:data.name||'',
                    message:'Please enter Medicine Name',
                    cols:12,
                    // render:()=><Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>{
                    //     _.map(_.uniq(medNameList), (v,index) => <Select.Option value={v} key={v}>{v}</Select.Option>)
                    // }
                    // </Select>
                    render:() => <AutoComplete
                      dataSource={searchMatches}
                      onSearch={this.handleMedSearch}
                      onChange={() => this.setDisabledMode(false)}
                      // filterOption={(inputValue, option) =>
                      //   option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      // }
                    />
                })
        // }
        // if(showMedInTakingMsg) {
        //     itemList = [[
        //         {
        //             key: 'name',
        //             label: "Medication Name",
        //             className: 'textareaInput',
        //             type: 'text',
        //             initialValue: showMedInTakingMsg.name || '',
        //             render:() => <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>{
        //                 _.map(_.uniq(medNameList), (v,index) => <Select.Option value={_.replace(v,`"`,'')} key={v}>{v}</Select.Option>)
        //             }
        //             </Select>
        //         }
        //     ]]
        // }
        return _.map(itemList,(arr,i) =>
            <Row key={i}>
                {
                    _.map(arr, (value, index) => {
                        let len = index === 0 ? 0 : 13
                        return (
                            <Col span={value.cols} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                    style={{display: 'flex', flexDirection: 'column', marginLeft: len}}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <Input rows={value.rows || 1} cols={value.cols || 55}
                                                   onBlur={(e)=>this.handleOnBlur(e,value.key)}
                                                   onChange={()=>this.handleOnChange(true)}/>
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }

    getStartAndEndDate = () => {
      const data  = this.props.curMed||{};
      const initialStartDate = data.startDate ? moment(data.startDate) : null;
      const initialEndDate = data.endDate ? moment(data.endDate) : null;

      const { startDate = initialStartDate, endDate = initialEndDate } = this.props.form.getFieldsValue();
      return { startDate, endDate };
    }

    renderStartDate = ()=>{
        const { getFieldDecorator } = this.props.form;
        const { startDate, endDate } = this.getStartAndEndDate();
       
        let itemList = [[
                    {
                        key:'startDate',
                        label:'Start Date',
                        cols:8,
                        initialValue: startDate,
                        render:()=> <DatePicker 
                          format={DATE_FORMAT}
                          onChange={() => this.setDisabledMode(false)} 
                          disabledDate={current => {
                            if(!endDate)
                              return false;
                            return current && (current >= moment(endDate) || current.isSame(endDate, 'day'));
                          }}
                        />
                    },
                    {
                        key:'endDate',
                        label:'Discontinue date',
                        cols:8,
                        initialValue: endDate,
                        render:()=> <DatePicker 
                          format={DATE_FORMAT}
                          onChange={() => this.setDisabledMode(false)} 
                          disabledDate={current => {
                            if(!startDate)
                              return false;
                            return current && (current <= moment(startDate) || current.isSame(startDate, 'day'));
                          }}
                        />
                    },
                ]]
        return _.map(itemList,(arr,i) =>
            <Row key={i}>
                {
                    _.map(arr, (value, index) => {
                        return (
                            <Col span={value.cols} key={index}>
                                <FormItem
                                    label={value.label}
                                    key={value.key}
                                    style={{display: 'flex', flexDirection: 'column', marginLeft: index === 0 ? 0 : 13}}
                                >
                                    {getFieldDecorator(value.key, {
                                        rules: [{
                                            required: value.required,
                                            message: value.message || null,
                                        }],
                                        initialValue: value.initialValue,
                                        valuePropName: value.valuePropName || 'value'

                                    })
                                    (
                                        value.render ? value.render(value) :
                                            <input rows={value.rows || 1} cols={value.col || 55}
                                                   onBlur={(e)=>this.handleOnBlur(e,value.key)}
                                                   onChange={()=>this.handleOnChange()}/>
                                    )}
                                </FormItem>
                            </Col>
                        )
                    })
                }
            </Row>)
    }

    renderInstruction(){
        const { getFieldDecorator } = this.props.form;
        const data = this.props.curMed || {};
        const value = {
            key: 'instruction',
            label: "Instruction",
            type: 'textarea',
            initialValue: data.instruction || '',
            className: 'textareaInput',
            rows:'2',
            size: 'default',
            render:(value) => <textarea onChange={() => this.setDisabledMode(false)} rows={ value.rows || 1 } style={{ width: '100%' }} />
        }
        return <Row>
                <Col span={value.cols}>
                    <FormItem
                        label={value.label}
                        key={value.key}
                        style={{display: 'flex', flexDirection: 'column'}}
                    >
                        {getFieldDecorator(value.key, {
                            rules: [{
                                required: value.required,
                                message: value.message || null,
                            }],
                            initialValue: value.initialValue,
                        })
                        (
                            value.render ? value.render(value) :
                                <input rows={value.rows || 0} cols={value.col || 55}/>
                        )}
                    </FormItem>
                </Col>
               </Row>
    }

    showDeleteConfirm() {
        Modal.confirm({
            title: 'Are you sure you want to delete this medication?',
            content:"Once it’s deleted, this medication will not show in the medication history.",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex:2000,
            mask:true,
            className:'deleteMedModule',
            maskClosable:true,
            onOk:()=>{
              this.removeMed();
              this.modalOnValueChange();
            }
        });
    }

    discontinueMed = () =>{
        const id = this.props.curMed.id;
        const query = this.props.modal.query;
        const patientId = this.props.patientId;
        const { setShowMedModal } = this.props;
        const { endDate } = this.state;

        let variables = {
            id:id,
            memberId: patientId,
            endDate
        }

        Client.mutate({
            mutation: query,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'
            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'discontinueMedicine');
            setShowMedModal(false);
            this.handleInteraction('DISCONTINUED');
            message.success('Medication Discontinued');
        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    handleClickEditMed = (showMedInTakingMsg)=>{
        const { setModal,setMedicine } = this.props;
        this.setState({
            showMedInTakingMsg: false,
        })
        setModal({type:'update'});
        setMedicine(showMedInTakingMsg);

    }

    renderHeader = ()=>{
        const data  = this.props.curMed||{};
        const { modal } = this.props;
        if(modal.type == 'update') {
            return <h4>Edit Medication: {data.name}</h4>;
        }
        return <h4>Add Medication</h4>
    }

    getDiscontinueNote = () => {
      const { endDate } = this.getStartAndEndDate();
      if(!endDate)
        return null;
      const isFuture = moment(endDate).isAfter(moment());
      return I18N.get(`medicationCompliance.discontinueMedication.note.${isFuture ? 'future' : 'past'}`);
    }

    renderFooter(showMedInTakingMsg) {
        const { modal } = this.props;
        let { isDisabled } = this.state;
        let isUpdated = false;

        if(showMedInTakingMsg){
            return <Button onClick={() => this.handleClickEditMed(showMedInTakingMsg) }>Edit Medicine</Button>
        }

        if(modal.type == 'update') {
             isUpdated = this.props.status;
        }else{
            isUpdated = true;
        }
        const baseStyle = {
            margin:5,
            minWidth:100
        }

        let buttonList =  modal.type == 'update' ? [
            // {
            //     key: 'Remove',
            //     label: "Delete Medication",
            //     type: 'danger',
            //     size:'default',
            //     className:'remove',
            //     style:baseStyle,
            //     onClick:()=>{
            //         this.showDeleteConfirm();
            //     }
            // },
            {
                key: 'submit',
                label: "Save",
                type: 'primary',
                htmlType:"submit",
                className:"addMed",
                style:baseStyle,
                disabled:isDisabled
            },
            // {
            //     key: 'Discontinue',
            //     label: "Discontinue",
            //     type: 'danger',
            //     size:'default',
            //     className:'remove',
            //     style:baseStyle,

            //     onClick:()=>{
            //         let startDate = _.get(this, 'state.medicine.startDate');
            //         Modal.confirm({
            //             width: 476,
            //             title: I18N.get('medicationCompliance.discontinueMedication.title'),
            //             // content: 'Are you sure you would like to Discontinue this Medication?',
            //             content: <div style={{ marginBottom: 43 }}>
            //               <div style={{ marginBottom: 17 }}>
            //                 {I18N.get('medicationCompliance.discontinueMedication.subTitle')}
            //               </div>
            //               <div className='discontinueField'>
            //                 <span className='fieldLabel'>
            //                   {I18N.get('medicationCompliance.discontinueMedication.datePickerLabel')}
            //                 </span>
            //                 <DatePicker
            //                   format={DATE_FORMAT}
            //                   style={{ width: '70%' }} 
            //                   popupStyle={{ zIndex:3000 }} 
            //                   onChange={(endDate)=>this.setState({endDate})} 
            //                   // defaultValue={ date } 
            //                   disabledDate={current => startDate && current && current < moment(startDate)}
            //                 />
            //               </div>
            //               <div style={{ opacity: 0.8 }}>
            //                 { this.getDiscontinueNote() }
            //               </div>
            //             </div>,
            //             okText: 'Yes',
            //             okType: 'danger',
            //             zIndex:2000,
            //             mask:true,
            //             className:'deleteMedModule',
            //             overlayClassName: 'discontinueMedicationModal',
            //             onOk:()=>{
            //               this.discontinueMed();
            //               this.modalOnValueChange();
            //             },
            //             okButtonProps: { disabled: !!endDate, style: { marginLeft: 20 } }
            //         });
            //     }
            // }
        ] :
        [{
            key: 'submit',
            label: "Save",
            type: 'primary',
            htmlType:"submit",
            className:"addMed",
            style:baseStyle,
            disabled:!isUpdated
        }]

        return  buttonList.map((value, index)=> {
                    return (
                        <FormItem
                            key={value.label}
                        >
                            <Button className={value.className || ''} disabled={value.disabled} onClick={value.onClick}
                                    type={value.type} style={value.style} htmlType={value.htmlType}>{value.label}</Button>
                        </FormItem>
                    )
        });
    }



    createMed(){
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();
        const createMedication = this.props.modal.query;

        let variables = {
            memberId: patientId,
            ...formVariables,
        };
        Client.mutate({
            mutation: createMedication,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'
            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'createMedicine');
            this.props.setShowMedModal(false);
            message.success('Medication added');
        }).catch((error)=>{
           this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    updateMed(){
        const patientId = this.props.patientId;
        const formVariables  = this.props.form.getFieldsValue();
        const query = this.props.modal.query;
        const id = this.props.curMed.id;
        // const { name } = this.props.curMed;
        let variables = {
            id:id,
            memberId: patientId,
            ...formVariables,
            // name
        };
        Client.mutate({
            mutation: query,
            variables: variables,
            refetchQueries:[{
                query:medicationList,
                variables:getVariables(this.props),
                fetchPolicy:'network-only'

            }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'updateMedicine');
            message.success('Medication Updated');
            this.props.setShowMedModal(false);

        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    removeMed=()=>{
        const id = this.props.curMed.id;
        const query = this.props.modal.query;
        const patientId = this.props.patientId;
        const { setShowMedModal } = this.props;

        let variables = {
            id:id,
            memberId: patientId,
            active:false
        }

        Client.mutate({
        mutation: query,
        variables: variables,
        refetchQueries:[{
            query:medicationList,
            variables:getVariables(this.props),
            fetchPolicy:'network-only'
        }]
        }).then((res)=>{
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,'removeMedicine');
            setShowMedModal(false);
            message.success('Medication Removed');

        }).catch((error)=>{
            this.props.openErrorModal(GQLHelper.formatError(error));
        })
    }

    modalOnValueChange = () => { 
      // TODO: need more discussion to consider add/edit/discontinue/remove as unsaved change
      // Because in Patient Profile, "Medication Recon..." is still required to Save
      // currently, NO-OP
      return;
      const { isEditMode, workList, onChange, onTouchCb } = this.props;
      if(isEditMode) return;
      if(!workList && onChange) {
        return onChange();
      }
      if(onTouchCb) onTouchCb();
    }

    handleInteraction = (description) => {
        const { onInteraction } = this.props;
        if (onInteraction) {
            onInteraction(description);
        }
    }

    onSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((error)=>{
            if(!error){
               if(this.props.modal.type=='add'){
                this.createMed();
                this.modalOnValueChange();
                this.handleInteraction('ADD');
               }
               if(this.props.modal.type=='update'){
                this.updateMed();
                this.modalOnValueChange();
                this.handleInteraction('UPDATE');
               }
            }
        })
    }

    renderForm = (showMedAddedMsg, showAddedMedHistory, showMedInTakingMsg) => {
        let pos = this.props.modal.type === 'update' ? 'flex-end' : 'center'
        return  <Form onSubmit={this.onSubmit} style={{display:showAddedMedHistory ? 'none' : 'flex',flexDirection:'column'}} className='medicine_form'>
                    { this.renderHeader() }
                    { this.renderNameAndDosage(showMedInTakingMsg)}
                    {  showMedAddedMsg ? this.medAddedMsg() :'' }
                    { !showMedInTakingMsg ? this.renderRadioField() :'' }
                    { !showMedInTakingMsg ? this.renderRadioGroup() : null }
                    { !showMedInTakingMsg ? this.renderStartDate() :'' }
                    { !showMedInTakingMsg ? this.renderInstruction() : null }
                    {/*{ this.renderInputFields(showMedInTakingMsg,showMedAddedMsg) }*/}
                    {  showMedInTakingMsg ? this.renderMedTaking() : '' }
                    <div style={{ marginBottom: 47 }}>
                      { this.getDiscontinueNote() }
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:pos}}>
                        { this.renderFooter(showMedInTakingMsg) }
                    </div>
                </Form>
    }

    render(){
        const { showMedAddedMsg,showAddedMedHistory,showMedInTakingMsg } = this.state;
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const patientId = _.get(this.props,'patientId');
        const keepEventListener = true;
        return(
            <div style={{ width: '100%' }}>
                <TimerContainer displayName={displayName} category='MEDICINE' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={patientId}
                                keepEventListener={keepEventListener}
                />
                { showAddedMedHistory ? this.renderAddedMedHistory() :'' }
                { this.renderForm(showMedAddedMsg,showAddedMedHistory,showMedInTakingMsg) }
            </div>
        )

    }
}

const withData = graphql(medNameList,{
    options:()=>{
        return{
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const { loading,medNameList } = data;
        if(loading){
            return {
                loading,
                loadingMedNameList: loading
            }
        }
        return {
            medNameList
        }
    }
})
function handleValueChange(props){
    if(props.modal.type=='update'){
        props.isUpdated(true);
    }
}

const mapToProps = (state)=>{
    return {
        ...state.medicineList.main,

    }
}
const mapToDispatch = (dispatch)=> {

    return {
       isUpdated:(isUpdated)=>dispatch(actions.isUpdated(isUpdated)),
       openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
       stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
       updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}

MedicineModalComponent = Form.create({onValuesChange:(props,changed,allValues)=>handleValueChange(props,changed,allValues)})(MedicineModalComponent);

export default compose(connect(mapToProps,mapToDispatch),withData)(MedicineModalComponent);
