import { combineReducers } from 'redux'
import type from '../constants'
import {_} from 'ihcomponent'

const initialState = {
  currentAlertsOption: 'ALERTS'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_CURRENT_ALERT_OPTION:
      return {
        ...state,
        currentAlertsOption: action.selectedAlertOption
      }
    case type.SET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgram: action.currentProgram
      }
    case type.RESET:
      return {
        ...state
      }
    default:
      return state
  }
}

export default {
  alerts: combineReducers({
    main: reducer
  })
};
