import gql from 'graphql-tag';
import Assessment from './schema/Assessment';

export default gql`
  query assessment($id: EID!) {
    assessment(id: $id) {
      ${Assessment}
    }
  }
`;
