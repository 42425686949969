import React, { useEffect } from 'react';
import VisitPropTypes from '../constants/propTypes';
import { branch } from 'recompose';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import getVirtualVisitStatus from '../../graphql/getVirtualVisitStatus';

import VisitAdvancedFilters from '../components/VisitAdvancedFilters';
import { helpers as visitHelpers } from '../../visit/helpers';
import { buildVisitListsQuery, buildVisitListQueryOpts } from '../helpers';
import { VISIT_CATEGORY_ENUM, loadingMessage } from '../constants';

const visitListQuery = buildVisitListsQuery();

const VisitTabContainer = props => {
  const { 
    loading, needVisitListProps, setVisitListProps, visitTabConfig,
    isTabSelected, selectedAdvancedFilters, selectedPageInfo, selectedFilters,
    ...restProps 
  } = props;
  const { tabKey, VisitTabComponent } = visitTabConfig;

  useEffect(() => {
    if(!loading) {
      const { visitListCount, refetchVisitList } = props;
      // set count, refetch for tab 
      setVisitListProps(tabKey, { visitListCount, refetchVisitList });
    }
  }, [loading]);

  return (
    <div id={`visits-tab-container-${tabKey}`}>
      { isTabSelected && loading && loadingMessage }
      <VisitTabComponent 
        {...restProps}
        tableSelectedPageInfo={selectedPageInfo[tabKey] || {}}
        tableSelectedFilters={selectedFilters[tabKey] || {}}
        tableSelectedAdvancedFilters={selectedAdvancedFilters[tabKey] || {}}
        tableAdvancedFilters={<VisitAdvancedFilters />}
      /> 
    </div>
  );
};

VisitTabContainer.propTypes = VisitPropTypes.tabContainer;

const getVisitList = graphql(visitListQuery, {
  options: buildVisitListQueryOpts,
  props: ({ ownProps, data }) => {
    const { visitTabConfig } = ownProps;
    const { error, loading, refetch, variables } = data;
    if(error) console.error(error);

    let visitWindowTime = {};
    const { appointmentFrom, appointmentTo } = _.get(variables, 'filters');
    if(appointmentFrom && appointmentTo) {
      visitWindowTime = { from: appointmentFrom, to: appointmentTo };
    }

    let { data: visitList = [], pageInfo } = data.visitList || {};
    const visitListCount = _.get(pageInfo, 'total') || visitList.length;

    return {
      loading,
      refetchVisitList: _.partial(visitTabConfig.getRefetchVisitList, refetch),
      visitWindowTime,
      visitList,
      visitListCount,
      virtualVisits: _.filter(visitList, v => v.category === VISIT_CATEGORY_ENUM.VIRTUAL),
    };
  }
});

const getVirtualVisitsStatus = graphql(getVirtualVisitStatus, {
  options:(ownProps)=>{
    const { virtualVisits, isTabSelected, visitTabConfig } = ownProps;
    return {
      variables: { visitIds: _.map(virtualVisits, 'id') },
      fetchPolicy: 'network-only',
      pollInterval: visitTabConfig.shouldPollVirtualStatus(isTabSelected),
    }
  },
  props:({ ownProps, data })=>{
    let { visitList = [] } = ownProps; // from getVisitList props
    const { error, getVirtualVisitStatus } = data;
    if(error) console.error(error);

    visitHelpers.parseVisitList(visitList, getVirtualVisitStatus);

    return { visitList }; // visit list with virtual status
  }
});

const mapStateToProps = (state, ownProps) => ({
  isTabSelected: state.visitsPage.selectedTabKey === ownProps.visitTabConfig.tabKey,
  selectedRange: state.visitsPage.selectedRange,
  selectedPageInfo: state.visitsPage.selectedPageInfo,
  selectedFilters: state.visitsPage.selectedFilters,
  selectedAdvancedFilters: state.visitsPage.selectedAdvancedFilters
});

export default compose(
  connect(mapStateToProps, null),
  getVisitList,
  branch(
    ({ virtualVisits = [] }) => !!virtualVisits.length,
    compose(getVirtualVisitsStatus)
  )
)(VisitTabContainer);