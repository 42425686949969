import React from 'react';
import { Button,Modal,Input,Form,Select,DatePicker,Radio,message, Tooltip, Icon, Checkbox } from 'antd';
import moment from "moment/moment";
import map from '../constants/tableMap';
const { priorityMap,patientTaskHistoryColumn } = map;
const { TextArea } = Input;
const { Item } = Form;
import helper from '../helper';
import '../style/index.scss'
import TaskAssignmentTableComponent from '../components/TaskAssignmentTableComponent';
import createTaskAssign from '../query/createTaskAssign';
import Client from 'libModule/gqlClient';
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';
import I18N from '../../I18N';
const { stopTimer,updateInterventionDetails }= timerActions.default;
const style = {
    rowStyle:{
        display:'flex',
        flexDirection:'column'
    },
    buttonDivStyle:{
        margin:'10px 0px'
    },
    buttonStyle:{

    },
    labelStyle:{
        fontWeight:'bold'
    }
}

let CreateNewTaskComponent = class extends React.Component {
    static displayName ='CreateNewTaskComponent';

    constructor(){
        super();
        this.state = {
            note:'',
            expandIndex:[],
            showCreateFormOnly:false,
            showTaskProviderModal:false,
            addToPN: false,
        }

    }

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }
    // async componentDidMount() {
    //     const id = _.get(this.props,'modalData.memberId');
    //     const data = await API.getPatientAssignee(id);
    //     const assignees = _.get(data,'data.user.assignees');
    //     this.setState({
    //         assignees
    //     })
    // }

    setAddToPN = (e) => {
        this.setState({addToPN: e.target.checked});
    }

    onSubmit = ()=>{
        const { closeModal,props } = this;
        const { form,modalData,refetchUser,onInteraction } = props;
        const refetch = _.get(props,'data.refetch');
        const { memberId } = modalData||this.props;
        const { reason,assignedTo,dueDate,priorityLevel } = form.getFieldsValue();
        const { addToPN } = this.state;
        form.validateFields((error)=>{
            if(!error){
                Client.mutate({
                    mutation:createTaskAssign,
                    variables:{
                        reason,
                        assignedTo,
                        dueDate:dueDate ? moment(dueDate).endOf('day'):null,
                        priorityLevel,
                        memberId,
                    }
                }).then(res=>{
                    if (onInteraction) onInteraction('CREATE');
                    if(refetch) {
                        refetch();
                    }
                    if(refetchUser){
                        refetchUser();
                    }
                    API.fetchTaskUnreadList();
                    API.fetchTaskUnreadTasks();
                    const apiName = Object.keys(res.data)[0];
                    const docId = res.data[apiName].id;
                    const resource = atob(docId).split(':')[0];
                    let displayName = this.constructor.displayName;
                    const summary = res.data[apiName].id;
                    Mixpanel.track('clicked', 'submit', 'new task popup', {PRIORITY: priorityLevel});
                    displayName = `${displayName}:${memberId}`;
                    this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
                    this.props.stopTimer(displayName, 'saveModal');
                    message.success('Task Assigned');
                    closeModal();

                    if(addToPN){
                        helper.createPN({
                          memberId,
                          content: reason
                        })
                    }
                }).catch(e=>{
                    Modal.error({ content : e.message });
                })
            }
        })

    }

    closeModal = ()=>{
        const { setModalData } = this.props;
        setModalData({showModal:false,modalData:null})
    }

    renderRadioGroup = ()=>{
        return  <Radio.Group>
            { _.map(priorityMap,(value,key)=><Radio value={key} key={value}>{value}</Radio>) }
        </Radio.Group>
    }

    renderFooter = ()=>{
        const { closeModal } = this;
        return <div>
            <Button type='primary' onClick={ ()=>this.onSubmit() }>Submit</Button>
            <Button onClick={ ()=>closeModal() }>Cancel</Button>
        </div>
    }

    renderTable = (enrolledPrograms)=>{
        const columns = patientTaskHistoryColumn;
        const { props } = this;
        return <TaskAssignmentTableComponent { ...props} columns={columns} enrolledPrograms={enrolledPrograms}/>
    }

    renderTaskProviderModal = () => {
        return <Modal
                    wrapClassName={'modal-custom'}
                    title="Tasking Provider Protocol"
                    visible={this.state.showTaskProviderModal}
                    footer={null}
                    onCancel={() => this.setTaskProviderVisible(false)}
                >
                    <div>
                        <p className={'taskProviderModalContent'}>{I18N.get('protocolTip.protocolOne')}</p>
                        <p className={'taskProviderModalContent'}>{I18N.get('protocolTip.protocolTwo')}</p>
                        <p className={'taskProviderModalContent'}>{I18N.get('protocolTip.protocolThree.title')}</p>
                        <p style={{marginLeft:15}}>{I18N.get('protocolTip.protocolThree.introOne')}</p>
                        <p style={{marginLeft:15}}>{I18N.get('protocolTip.protocolThree.introTwo')}</p>
                        <p style={{marginLeft:15}}>{I18N.get('protocolTip.protocolThree.introThree')}</p>
                        <p className={'taskProviderModalContent'}>- You can refer to the <span onClick={() => this.onClickManual()} style={{textDecoration: 'underline', fontSize: '14px', cursor: 'pointer', userSelect: 'none' }}>{I18N.get('protocolTip.inClinicManual')}</span> for task templates and more details.</p>
                    </div>
                </Modal>
    }

    setTaskProviderVisible = (isVisible) => {
        this.setState({
            showTaskProviderModal: isVisible
        })
    }

    checkTaskProviderPopUp = () => {
        const { getFieldValue } = this.props.form;
        const { modalData, team, organization } = this.props;
        const { enrolledPrograms } = modalData || {};
        const curUser = JSON.parse(sessionStorage.getItem("currentUser"));
        const curRole = _.get(curUser, 'selectedRole.name', '');
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;
        const isRdOrCa = curRole === "Medical Assistant" || curRole === 'Nurse'
        const curOrgId = _.get(organization, 'id');
        const roleInCurClinic = helper.getRoleInCurClinic(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team, curOrgId)
        const isProvider = helper.checkIsProvider(roleInCurClinic, getFieldValue('assignedTo'));
        
        return isRdOrCa && isProvider ? <div style={{marginTop:-15, marginBottom:10, display:'flex'}}>
                    <Icon type="info-circle" style={{marginRight: 5, paddingTop:4}}/>
                    <span>It looks like you are tasking a provider. Please make sure you follow the <span onClick={() => this.setTaskProviderVisible(true)} style={{textDecoration: 'underline', fontSize: '14px', cursor: 'pointer', userSelect: 'none' }}>{I18N.get('taskProvider')}</span> before submitting</span>
                </div> : ''
    }

    onClickManual = () => {
        window.open( "https://docs.google.com/document/d/1lLKU1n1KsSv2L8hWZe621LhMJ_u92ZCVzL8SUoGrcRM/edit" )
    }
    
    getFormProps = (enrolledPrograms,team)=>{
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { modalData,assignees } = this.props;
        const { patientName } = modalData||{};
        const disabledDate = (current)=>{
            return current && current < moment().startOf('day');
        }
        const { reason } = getFieldsValue();
        return <Form style={{ padding:10, maxWidth:390 }} className='trackingTaskModal'>
                <strong style={style.labelStyle}>{`New Task: ${patientName}`}</strong>
                <Item key='reason'>
                    {getFieldDecorator('reason',{ rules: [{ required: true, message:'Reason is Required' }] })(<TextArea rows={6}/>)}
                </Item>
                <div>
                <Checkbox
                    onChange={ this.setAddToPN }
                    disabled={!reason}
                    >
                    Add to Provider Notes
                </Checkbox>
                </div>
                <div style={{display:'flex'}}>
                    <Item key='assignedTo' label={I18N.get('taskAssignment.assignedTo')}>
                        {getFieldDecorator('assignedTo', {rules:[{required: true, message: I18N.get('taskAssignment.assignedToMsg')}]})(helper.assignedToDropDown(enrolledPrograms,team,undefined,undefined,assignees))}
                    </Item>
                    <Item key='dueDate' label={I18N.get('taskAssignment.dueDate')}>
                        {getFieldDecorator('dueDate', {rules:[{required: true, message: I18N.get('taskAssignment.dueDateMsg')}]})(<DatePicker format='MM/DD/YYYY' disabledDate={disabledDate} />)}
                    </Item>
                </div>
                {this.checkTaskProviderPopUp()}
                <Item label={I18N.get('taskAssignment.priorityLevel.label')}>
                    {getFieldDecorator('priorityLevel',{initialValue:'MEDIUM', rules: [{ required: true, message: I18N.get('taskAssignment.priorityLevel.required.message') }] })(this.renderRadioGroup())}
                </Item>
                { this.renderFooter() }
               </Form> 
    }

    onClickCreateNewTask = ()=>{
        Mixpanel.track('clicked', 'create a new task', 'patient task list popup');
        this.setState({
            showCreateFormOnly:true
        })
    }

    renderModalFooter = ()=>{
        return <div>
                <Button type='primary' onClick={ this.onClickCreateNewTask } style={{ marginRight: 10 }}>Create New Task</Button>
               </div>
    }
    renderTimer(){
        const { modalData:{ memberId } } = this.props;
        let displayName = this.constructor.displayName;
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='ENTER'
                               viewingDetails={viewingDetails}
                               patientId={memberId}
                               keepEventListener={keepEventListener}
        />
    }
    render(){
        const { props, state, closeModal } = this;
        const { modalData,team,taskList,loading } = props;
        const { showCreateFormOnly, showTaskProviderModal } = state;
        const { enrolledPrograms } = modalData||{};
        const showCreateFormOnlyFromProps = _.get(this,'props.modalData.showCreateFormOnly');
        const showCreateFormOnlyCombined = showCreateFormOnlyFromProps || showCreateFormOnly||(_.get(taskList,'length',0)==0);
        if(loading){
            return '';
        }
        const teamsFromLoginUser = JSON.parse(sessionStorage.getItem('currentUser')).team;

        return <Modal visible={true} footer={null} maskClosable={false} onCancel={()=>closeModal() } destroyOnClose={true} width={'fit-content'}>
                { this.renderTimer() }
                { showCreateFormOnlyCombined ? this.getFormProps(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team)  :''}
                { showTaskProviderModal ? this.renderTaskProviderModal() : ''}
                { !showCreateFormOnlyCombined ? this.renderTable(enrolledPrograms) : ''}
                { !showCreateFormOnlyCombined ? this.renderModalFooter():'' }
               </Modal>

    }
};

const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};
CreateNewTaskComponent = Form.create()(CreateNewTaskComponent);
export default connect(mapToProps,mapToDispatch)(CreateNewTaskComponent);

