import types from '../constants/MainLayout'

// Default State
const initState = {
  dom: {
    width: 0,
    height: 0
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.RESIZE_WINDOW:
      return {
        ...state,
        dom: {
          width: action.width,
          height: action.height
        }
      }
    default:
      return state
  }
}
