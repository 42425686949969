import React, { useState } from 'react';
import { Table, Input, Form } from 'antd';
import PropTypes from 'prop-types';
import { columnMap } from '../../constant/nutritionIntakeConstants';
import { parseIntakeValues } from '../../helpers/nutritionHelpers';
import './recallOrTypical.styles.scss';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  return <EditableContext.Provider 
    value={{ 
      form, 
      rowIndex: props['data-row-key'], 
    }} 
  >
    <tr {...props} />
  </EditableContext.Provider>
};

const EditableFormRow = Form.create()(EditableRow);;

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        const el = this.input;
        el.focus();
        if(el.value) {
          // TODO: set caret to the end if there is value
          // el.selectionStart = el.selectionEnd = el.value.length;
        }
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = ({ form, rowIndex }) => {
    this.form = form;
    const { record, dataIndex, placeholder, disabled } = this.props;
    const currentValue = _.get(record, dataIndex, '');
    // only show placeholder for first row, per Design
    const shouldPutPlaceholder = rowIndex === 0;
    return this.state.editing ?
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            initialValue: currentValue,
            valuePropName: 'value'
          })(<Input.TextArea 
                ref={node => (this.input = node)} 
                autoSize={{ minRows: 1, maxRows: 2 }}
                //onPressEnter={this.save} 
                onBlur={this.save} 
                placeholder={shouldPutPlaceholder ? placeholder : ''}
              />
          )}
        </Form.Item>
       :
        <Form.Item className='editable-cell-wrapper'>
          <Input.TextArea 
            disabled={disabled}
            autoSize={{ minRows: 1, maxRows: 2 }}
            value={currentValue} 
            onFocus={this.toggleEdit}
            placeholder={shouldPutPlaceholder ? placeholder : ''}
          />
        </Form.Item>
  };

  render() {
    const { 
      editable, dataIndex, title, record,
      index, handleSave, children, ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
};

const EditableTable = props => {
  const dataSource = parseIntakeValues(props.dataSource);

  const handleSave = row => {
    const newData = [...dataSource];
    // find row index based on meal type (unique value)
    const rowIndex = _.findIndex(newData, o => o.meal === row.meal);
    // save only there is change
    if(!_.isEqual(row, newData[rowIndex])){
      newData.splice(rowIndex, 1, row);
      props.setHasChanges({ intake: newData });
    }
  };

  const columns = columnMap.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
          record,
          ...col,
          handleSave,
          disabled: props.disabled // flag to disable all cell
        }),
    };
  });

  return (
    <div id='nutrition-recall-typical-intake-wrapper'>
      <Table
        components={{
          body: {
            row: EditableFormRow,
            cell: EditableCell,
          }
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={(__, index)=> index}
      />
    </div>
  );
};

EditableTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default EditableTable;