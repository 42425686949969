import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import moment from 'moment'
import get from 'lodash/get'

import editBroadcastGQL from 'modulesAll/graphql/editBroadcast'
import { updateQueries } from 'modulesAll/graphql/editBroadcast'
import deleteBroadcastGQL from 'modulesAll/graphql/deleteBroadcast'

import { IHButton, message, IHLoading, IHForm } from 'ihcomponent'
import { goPath, FormHelper, GQLHelper, submittingStyle, createContainer } from 'lib/utils'
import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import actions from '../actions/EditBroadcastActions'
import { modalAction } from 'modulesAll/common/actions'
import { getFormProps } from '../components/BroadcastFormComponent'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  
  componentWillMount() {
    const { broadcastData, updateForm } = this.props
    this.initData = this.convertBroadcastDataToFormData(broadcastData)
    const fieldsValue = convertToFieldsValue(this.initData)

    updateForm(fieldsValue)
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()
    return (
      <div className='vsm-broadcast-new'>
        <div className='broadcast-new'>
            {body}
            {footer}
        </div>
      </div>
    )
  }

  renderBody() {
    const { onFieldsChange, fieldsValue } = this.props
    const formProps = getFormProps({ onFieldsChange, initData: this.initData, fieldsValue })
    return <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }

  renderFooter() {
    const { isSubmitting } = this.props
    const styles = {
      footer: {
        display: 'inline-block',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 10,
      }
    }
    const buttonProps = {
      delete: {
        onClick : ()=>{
          this.confirmDelete()
        },
        bsStyle: 'primary',
        label: 'Delete Broadcast',
        className: "btn-delete",
        style: {
          float: 'left'
        }
      },
      cancel: {
        onClick: (e)=>{
          goPath('/broadcasts')
        },
        bsStyle: 'default',
        disabled: false,
        label: 'Cancel',
        style: {
          float: 'right'
        }
      },
      done: {
        onClick : ()=>{
          this.editBroadcast()
        },
        bsStyle: 'primary',
        disabled: isSubmitting,
        type: 'submit',
        style: {
          marginRight: 10,
          float: 'right',
          ...submittingStyle(isSubmitting)
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.delete} />
        <IHButton {...buttonProps.cancel} />
        <IHButton {...buttonProps.done} >
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
      </div>
    )

    return footer
  }

  convertBroadcastDataToFormData(broadcastData) {
    const initData = {
      title : (broadcastData.title === 'Scheduled Maintenance' || broadcastData.title === 'Unscheduled Maintenance') ? broadcastData.title : 'others',
      title_text : broadcastData.title,
      message : broadcastData.message,
      startTime : moment(broadcastData.startTime),
      endTime : moment(broadcastData.endTime)
    }

    if ((initData.title === 'Scheduled Maintenance' || initData.title === 'Unscheduled Maintenance')) {
      delete initData['title_text']
    }

    return initData
  }

  confirmDelete(){
    const { closeModal, openModal } = this.props
    const p1 = {
      type : 'primary',
      label : 'Confirm',
      size : 'large',
      onClick : ()=>{
        this.deleteBroadcast()
      }
    }
    const p2 = {
      label : 'Cancel',
      size : 'large',
      style : {
        marginLeft : '10px'
      },
      onClick : ()=>{
        closeModal()
      }
    }
    const footer = (
      <div>
        <IHButton {...p1} />
        <IHButton {...p2} />
      </div>
    )
    const param = {
      size : 'sm',
      title : 'Delete Broadcast?',
      content : 'Please confirm if you want to delete this broadcast',
      footer
    }
    openModal(param.content, param)
  }

  async deleteBroadcast() {
    const { deleteBroadcast, closeModal, openErrorModal, id, fieldsValue } = this.props
    const variables = { id }
    const formValue = FormHelper.getValue(fieldsValue)
    const auditLogDetails = {
      title: formValue.title,
      message: formValue.message,
      startTime: moment(formValue.startTime).format('MMM-DD-YYYY hh:mm a:ss'),
      endTime: moment(formValue.endTime).format('MMM-DD-YYYY hh:mm a:ss'),
    }

    closeModal()

    try {
      const res = await deleteBroadcast({ variables })
      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.delete'),
        details: auditLogDetails,
        request: variables,
        response: res
      })
      if (res.data.deleteBroadcast) {
        message.success('Delete Broadcast Success')
        goPath('/broadcasts')
      }
    }
    catch (err) {
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.delete'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
  }

  async editBroadcast(){
    const { editBroadcast, fieldsValue, handleFormSaved, openSuccessModal, openErrorModal, setSubmitting, id } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }
    const variables = this.getMutateVariable(formValue)
    variables.id = id
    const auditLogDetails = {
      title: variables.title,
      message: variables.message,
      startDate: moment(variables.startTime).format('MMM-DD-YYYY hh:mm a:ss'),
      endDate: moment(variables.endTime).format('MMM-DD-YYYY hh:mm a:ss')
    }

    try {
      setSubmitting(true)
      await handleFormSaved()

      const res = await editBroadcast({ variables, updateQueries })
      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.edit'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      if (res.data.editBroadcast) {
        const modalTitle = 'Broadcast Edited Successfully'
        const modalContent = `Broadcast has been succesfully edited!`
        openSuccessModal(modalTitle, modalContent)
        goPath('/broadcasts')
      }
    }
    catch(err) {
      openErrorModal(GQLHelper.formatError(err))
      createAuditLog({
        action: I18N.get('auditLog.vendor.broadcast.edit'),
        details: auditLogDetails,
        request: variables,
        response: err,
        success: false
      })
    }
    finally {
      setSubmitting(false)
    }
  }

  getMutateVariable(data) {
    const variables = {
      title : (data.title === 'others')? data.title_text : data.title,
      startTime : data.startTime,
      endTime : data.endTime,
      message : data.message
    }
    return variables
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const wrapperData = graphql(editBroadcastGQL, {name : 'editBroadcast'})(graphql(deleteBroadcastGQL, {name : 'deleteBroadcast'})(Container))

const mapState = (state, ownProps) => {
  const id = get(state.routing, 'params.broadcastId')
  return {
    id,
    ...state.Broadcast.EditBroadcast,
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    changeField: (fieldPath, value) => dispatch(actions.changeField(fieldPath, value)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    resetForm: () => dispatch(actions.resetForm()),
    openSuccessModal: (title, content) => dispatch(modalAction.openSuccessModal(title, content)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
    openModal: (content, modalProps) => dispatch(modalAction.openModal(content, modalProps)),
    closeModal: () => dispatch(modalAction.closeModal()),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting)),
    updateForm: (row) => dispatch(actions.updateForm(row))
  }
}

Container.propTypes = {
  openErrorModal: PropTypes.func,
  onFieldsChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  fieldsValue: PropTypes.object,
  resetForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  openSuccessModal: PropTypes.func,
  setSubmitting: PropTypes.func,
  editBroadcast: PropTypes.func,
  deleteBroadcast: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  id: PropTypes.string,
  broadcastData: PropTypes.object,
  updateForm: PropTypes.func,
  data: PropTypes.object
}

export default createContainer(wrapperData,
  mapState, mapDispatch
)
