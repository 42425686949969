import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

const getPatientConsentQuery = (includeFileUrl) => gql`
  query getpatientConsent($memberId:EID!){
    getPatientConsent(memberId:$memberId){
      id
      consent
      consentType
      ${includeFileUrl ? 'fileUrl' : ''}
      effectiveDate
      signatureAt
    }
  }
`;

const withPatientConsent = (Component, options) => {
  const { includeFileUrl = false, getMemberId, waitForConsentInfo = false } = options || {};
  const handleGetMemberIdFromProps = (props) => {
    const memberId = typeof getMemberId === 'function' 
                        ? getMemberId(props) 
                        : props.memberId;
    return memberId;
  };

  const getPatientConsent = graphql(getPatientConsentQuery(includeFileUrl), {
    options: (ownProps) => {
      return {
        variables: { memberId: handleGetMemberIdFromProps(ownProps) },
      };
    },
    props: ({ data }) => {
      const { getPatientConsent, loading, error } = data;
      if (error) console.error(error);
      return {
        loadingConsentInfo: loading,
        consentInfo: getPatientConsent || {},
      };
    }
  });

  const mapToProps = (state, ownProps) => {
    try {
      // from app signed, signatureAt should always be on the current date
      // otherwise, something wrong with Pubnub
      const consentInfoStatus = state.VideoChat.main.consentInfo[handleGetMemberIdFromProps(ownProps)] || {};
      if ( !!consentInfoStatus.consent ) {
        consentInfoStatus.signatureAt = new Date().getTime();
      }
      return {
        consentInfo: {...ownProps.consentInfo, ...consentInfoStatus} // update status for consent via APP
      };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const WrappedComponent = (props) => {
    const { loadingConsentInfo } = props;
    if (waitForConsentInfo && loadingConsentInfo) return <Spin />;
    return <Component {...props} />;
  }

  return compose(getPatientConsent, connect(mapToProps, null))(WrappedComponent);
}
export default withPatientConsent;
