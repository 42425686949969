const typeToDuration = {
    'CLINIC_FOLLOW_UP':30,
    INIT:60,
    FOLLOW_UP:30,
    ADDITIONAL:30,
    TECH:30,
    MNT:60,
    TECH_ONBOARDING:30
}
export {
    typeToDuration
}
