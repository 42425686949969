import React from 'react';
import { Spin } from 'antd';
import { graphql } from "react-apollo/index";
import { compose } from 'recompose';
import api from '../../VideoChat/API';
import { connect } from 'react-redux';

class NoConsentOverlay extends React.Component {
  render() {
    const { loading, children, consentInfo, renderChild } = this.props;

    const consent = _.get(consentInfo, 'consent');
    // if (!loading ) {
    //  const consent = _.get(consentInfo, 'consent');
    //   if(typeof consent === 'boolean' && consent) {
    //     return <div>{children}</div>;
    //   }
    // }

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {
          (typeof consent !== 'boolean' || !consent) &&
          <React.Fragment>
            <div className="no-consent-overlay-top"></div>
            <div className="no-consent-overlay">
              <div style={{ width: 600, color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                {loading
                  ? <Spin />
                  : <div style={{ backgroundColor: 'white' }}>This section cannot be filled out yet because patient has not signed the consent form.
                  Please ensure that the patient signs the consent form prior to receiving our services.</div>
                }
              </div>
            </div>
          </React.Fragment>
        }
        {
          typeof renderChild === 'function' 
            ? renderChild(consentInfo)
            : children
        }
      </div>
    );
  }
}

const consentInfo = graphql(api.getPatientConsent, {
  options: (ownProps) => {
    const memberId = ownProps.memberId;

    return {
      variables: {
        memberId
      },
      fetchPolicy:'network-only'
    }
  },
  props: ({ data }) => {
    const { getPatientConsent, loading } = data;

    return {
      loading,
      consentInfo: getPatientConsent || {},
    };
  }
});

const mapToProps = (state, ownProps) => {
  try {
    // from app signed, signatureAt should always be on the current date
    // otherwise, something wrong with Pubnub
    const consentInfoStatus = state.VideoChat.main.consentInfo[ownProps.memberId] || {};
    if ( !!consentInfoStatus.consent ) {
      consentInfoStatus.signatureAt = new Date().getTime();
    }
    return {
      consentInfo: {...ownProps.consentInfo, ...consentInfoStatus} // update status for consent via APP
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default compose(consentInfo, connect(mapToProps, null))(NoConsentOverlay);