import { constants } from 'libModule/utils'

export default constants('patientList', 'AddNewPatient', [
  'FORM_FIELD_CHANGE',
  'INIT_DATA',
  'RESET_FORM',

  'ADD_CAREGIVER',
  'REMOVE_CAREGIVER',
  'UPDATE_FORM'
])
