import {
    React, IHButton
} from 'ihcomponent'
import providerListGQL from '../../../graphql/userList';
import ProviderList from '../component/providerListComponent';
import createTableAction from 'libModule/table/TableActions'
import { graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import _ from 'lodash'


const tableActions = createTableAction('provider_list', {
    shouldUpdateHistory: false,
    sortFieldMap: {
        createdAt: 'CREATED_AT',
        modifiedAt: 'MODIFIED_AT',
        careTeam: 'NAME'
    },
})

const Component = (props) => {

    return (
        <div className="vsm-main-page vsm-patientList-main">
            <div className="vsm-main-990">
                <div className="v-table">
                    <ProviderList {...props} />
                </div>
            </div>
        </div>
    )
}

Component.displayName = 'superadmin/providers'

const withData = graphql(providerListGQL, {
    options: (ownProps) => {
        const rolesData=ownProps.rolesData?ownProps.rolesData:[];
        const variables = {
            page: _.get(ownProps, 'page.current', 1),
            count: 10,
            filters: {
                roles: rolesData,
                search: {
                    fields: ['NAME'],
                    match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', '')
                }
            },
            // search: {
            //     fields: ['NAME'],
            //     match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', '')
            // },
            sort: null
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ data }) => {
        return {
            userData: data,
            list: data.userList,
            loading: data.loading,
            refetch: data.refetch
        }
    }
})


export default compose(
    connect(
        (state) => {
            return {
                ...state.globalTables.provider_list
            }
        },
        (dispatch) => {
            return {
                ...tableActions
            }
        }
    ),
    withData
)(Component)
