
import { graphql } from 'react-apollo';
import {
  boostedOrganizations as boostedOrganizationsQuery,
  clinicCandidates as clinicCandidatesQuery
} from './query/boostedOrganizations';
import Client from '../../../lib/gqlClient';

export const getBoostedOrganizations = graphql(boostedOrganizationsQuery, {
  options: ({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }) => {
    const { loading, getSelectedClinic, error, refetch } = data;

    if (error) {
      console.error(error);
    }

    return {
      boostedOrganizations: _.get(getSelectedClinic, 'boostedOrganizations'),
      loadingBoostedOrganizations: loading,
      refetchBoostedOrganizations: refetch,
    };
  }
});

export const getClinicCandidates = graphql(clinicCandidatesQuery, {
  props: ({ data }) => {
    const { loading, getClinicCandidates, error } = data;

    if (error) {
      console.error(error);
    }

    return {
      loadingClinicCandidates: loading,
      clinicCandidates: getClinicCandidates,
    };
  }
});

export const getClinicCandidatesAsync = () => Client.query({ query: clinicCandidatesQuery });
