import React from 'react';
import { Table,Icon } from 'antd';
import moment from "moment/moment";
import $ from 'jquery';
import _ from 'lodash';

const { Column } = Table;
const HSLogBookMiniComponent = class extends React.Component {
    constructor(){
        super();
    }

    generateDaySpots(){
        const fromDate = moment(_.get(this,'props.fromDate'));
        const toDate = moment(_.get(this,'props.toDate'));
        let timeSpots = [];

        while(fromDate.isSameOrBefore(toDate,'day')){
            timeSpots.push(fromDate.format('MM/DD/YYYY'));
            fromDate.add(1,'day');
        }
        return timeSpots.reverse();
    }

    renderUnit(){
        return <Icon type='info-circle-o' className='unitIcon'>
            <span style={{paddingLeft:5}}>Measurements are in Kg</span>
        </Icon>
    }

    groupData(){
        const data = this.props.resultList;
        const timeSpots = this.generateDaySpots();
        const parsedData =  _.groupBy(data, (d)=>{
            return moment(d.date).format('MM/DD/YYYY')
        })
        let groupedData = _.map(timeSpots,(key)=>{
            if(parsedData[key] == undefined) {
                return null;
            }
            return {
                [key]:parsedData[key]
            }
        }).filter(v => !!v)
        return groupedData;
    }

    renderReadingInCell(data){
        let result = "";
        _.forEach(data, (d) => {
            let hour = new Date(d.date).getHours();
            let mins = new Date(d.date).getMinutes();
            let timeTail = hour > 12 ? 'pm' : 'am';
            let time = hour + ':' + mins + timeTail;
            result += result !== "" ? " " + _.get(d, 'body_weight.value', 0) + " (" + time + ") " : _.get(d, 'body_weight.value', 0) + " (" + time + ") ";
            result = result.substring(0, result.length - 1);
        })
       
        return (
            <div>
                <div>
                    <span>{result}</span>
                </div>
            </div>
        );
    }

    renderTable(){
        const dataToRender = this.groupData();
        const result = [];
        const parsedData = _.map((dataToRender),(d) => {
            const valueArray = Object.values(d)[0];
            const valueKey = Object.keys(d)[0];
            result.push({
                data:valueArray,
                date:valueKey
            })
            return {
                date:valueArray[0].date,
                data:_.groupBy(valueArray, (d) => d.date),
            }
        })
        return  <div className='IH-SearchTable'>
            <Table dataSource={result} rowKey={(data,index)=>data.date+index}
                   className='logMiniBookTable' bordered
                   style={{ marginBottom:0, fontSize:10}}
                   pagination={false}
            >
                <Column
                    title="Date"
                    dataIndex="date"
                    render={(data)=>moment(data).format('MM/DD/YYYY')}
                    width={'20%'}
                />
                <Column
                    title="Measurements"
                    dataIndex="data"
                    key='Measurements'
                    className='logBookIcon'
                    render={(data, index) => {
                        return {
                            children: <div>
                                {this.renderReadingInCell(data)}
                            </div>
                        }
                    }
                    }
                />
            </Table>
        </div>
    }

    render(){
        return <div>
            {this.renderTable()}
            {this.renderUnit()}
        </div>
    }
}

export default HSLogBookMiniComponent;
