import React from 'react';
import { Icon, Input, Button } from 'antd';
import './nameFilterHelper.scss';

// helpers for Name column in List component / Antd Table
// In List component, must have state nameFilterState = {} by default
const getFilterActions = componentInstance => {
  const { curTab, setSelectedFilters} = _.get(componentInstance, 'props');
  const curFilterState = () => _.get(componentInstance, `state.nameFilterState.${curTab}`);
  const curSelectedFilters = () => _.get(componentInstance, `props.selectedFilters`); // Thong- please don't refactor this
  const getPrevNameFilterState = () => {
    const otherNameFilterStates = _.get(componentInstance, `state.nameFilterState`);
    return {
      ...otherNameFilterStates,
      [curTab]: curFilterState()
    };
  };

  const getValue = (field, defaultValue = null) => _.get(curFilterState(), field, defaultValue);

  const setNewValues = (...fieldsAndValues) => { // accept each args as array of [field, value]
    const prevNameFilterState = getPrevNameFilterState();
    const updatedNameFilterState = prevNameFilterState;
    let newValueCount = 0;
    _.forEach(fieldsAndValues, fieldAndValue => {
      const [field, newValue] = fieldAndValue;
      if(_.get(prevNameFilterState, `${curTab}.${field}`) !== newValue){
        _.set(updatedNameFilterState, `${curTab}.${field}`, newValue);
        newValueCount += 1;
      }
    });
    if(newValueCount !== 0) componentInstance.setState({ nameFilterState: updatedNameFilterState });
  };

  const updateReduxStore = value => {
    // update Redux if applicable
    if(setSelectedFilters){
      const prevSelectedFilters = curSelectedFilters();
      const curTabOldFilters = _.get(prevSelectedFilters, curTab, {});
      setSelectedFilters({ ...prevSelectedFilters, [curTab]: { ...curTabOldFilters, name: value }});
    }
  }

  const setIsFilterVisible = isVisible => {
    // if isVisible is not defined, toggle visibility
    const newValue = isVisible !== undefined ? isVisible : !getValue('isVisible', false); // false is default if state.{curTab}.isVisible is null
    setNewValues(['isVisible', newValue]);
  };

  const tokenizeIfMatch = (original, keyword) => _.split(original, new RegExp(`(${keyword})`, 'gi'));

  const getNameTitle = () => (
    <div>
      Patient
      <Icon
        className='name-filter-icon'
        type='search'
        style={{ color: (_.get(curSelectedFilters(), `${curTab}.name`)||[]).length ? '#1890ff' : 'rgba(0, 0, 0, 0.45)', marginLeft: 5 }}
        onClick={() => setIsFilterVisible()}
      />
    </div>
  );

  const getNameFilterProps = () => {
    const onSearch = () => {
      setNewValues(['isVisible', false]);
      updateReduxStore(getValue('filterInput'));
    }
    return {
      filterDropdown: (
        <div id='name-filter-dropdown'>
          <Input
            placeholder=''
            ref={ref => { if(ref) ref.focus() }} // focus to input when it is clickable
            value={getValue('filterInput.0', _.get(curSelectedFilters(), `${curTab}.name.0`, ''))}
            onChange={e => setNewValues(['filterInput', [e.target.value]])} // filterInput must be an array
            onPressEnter={onSearch}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type='primary' icon='search' size='small' style={{ width: 90, marginRight: 8 }}
            onClick={onSearch}
          >
            Search
          </Button>
          <Button size='small' style={{ width: 90 }}
            onClick={() => {
              setNewValues(['filterInput', ['']]);
              updateReduxStore([]);
            }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <div></div>, // empty filter icon
      filterDropdownVisible: getValue('isVisible', false),
      filteredValue: _.get(curSelectedFilters(), `${curTab}.name`, null),
      // Thong - lodash or native includes fail for special characters within string. ie: keyword=de' => result:Lucy Dev
      onFilter: (value, record) => tokenizeIfMatch(_.get(record, 'member.profile.fullName', ''), value).length > 1,
      // hide dropdown when another filter is clicked
      // this prop only execute callback when it is not visible / visible=false
      onFilterDropdownVisibleChange: visible => setIsFilterVisible(visible),
    }
  };

  const getHighlightedForFilter = name => {
    const wordsToHighLight = _.get(curSelectedFilters(), `${curTab}.name.0`, '');
    const tokens = name && wordsToHighLight ? tokenizeIfMatch(name, wordsToHighLight) : name;

    return <span>
      {
        _.map(tokens, (token, id) =>
        // token and wordsToHighLight must not be empty
        // .match() will return an array if match; null, otherwise
          token && wordsToHighLight.length && token.match(new RegExp(`(${wordsToHighLight})`, 'i')) ?
            <span key={id} className='name-filter-highlighted'>{token}</span>
            : token
        )
      }
    </span>;
  };

  return {
    getNameTitle,
    getNameFilterProps,
    getHighlightedForFilter,
  };
};

const nameFilterHelper = componentInstance => {
  if(!_.get(componentInstance, 'state.nameFilterState')) {
    console.error('** Failed to load name filter helper, Make sure to have state nameFilterState in List Component');
    return null;
  }
  const filterActions = getFilterActions(componentInstance);

  return {
    getNameTitle: () => filterActions.getNameTitle(),
    getNameFilterProps: () => filterActions.getNameFilterProps(),
    getHighlightedForFilter: name => filterActions.getHighlightedForFilter(name),
  }
};

export { nameFilterHelper as default };
