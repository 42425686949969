import gql from 'graphql-tag';

export const getSystemDynamicConfigsList = gql`
	query getSystemDynamicConfigs($page:Int, $count:Int) {
		getSystemDynamicConfigs(page: $page, count: $count) {
			data {
				id
				configKey
				value
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;

export const setSystemDynamicConfig = gql`
	mutation setSystemDynamicConfig($id: EID!, $value: String!) {
		setSystemDynamicConfig(id: $id, value: $value) {
			id
			configKey
			value
		}
	}
`;

export const deleteSystemDynamicConfig = gql`
	mutation deleteSystemDynamicConfig($id: EID!) {
		deleteSystemDynamicConfig(id: $id)
	}
`;

export const createSystemDynamicConfig = gql`
	mutation createSystemDynamicConfig($key: String!, $value: String!) {
		createSystemDynamicConfig(key: $key, value: $value) {
			id
			configKey
			value
		}
	}
`;
