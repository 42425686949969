import mixpanel from 'mixpanel-browser';

let env = process.env.CONFIG_ENV;//window.VSM_CONFIG.NODE_ENV;
let token = '';
let env_check = VSM_CONFIG.MIXPANEL_ENV_CHECK || true;
switch (env) {
    case 'development':
        token = 'fa1b48faf4cec00ed51d77952862dd75';
        break;
    case 'test':
        token = 'be89583122b59c255590f22efd61abf5';
        break;
    case 'production':
        token = '02c86ed97952c4ebca533933b63886f0';
        break;
    default:
        env_check = false;
}

//mixpanel flag
if(env_check){
    mixpanel.init(token);
}

const getProperty = ()=>{
    const userInSession = sessionStorage.getItem('currentUser');
    const userObj = userInSession&&JSON.parse(userInSession);
    if(userObj){
        const { profile, selectedRole } = userObj;
        const USER_ID = _.get(userObj,'id','');
        const FULL_NAME  = _.get(profile,'fullName','');
        const ROLE_CATEGORY = _.get(selectedRole,'category','');
        const ROLE_NAME = _.get(selectedRole,'name');
        const organization  = _.get(selectedRole,'organization','');
        const ORG_ID = _.get(organization,'id','');
        const ORG_NAME = _.get(organization,'name','');
        
        return {
            USER_ID,FULL_NAME,ROLE_CATEGORY,ROLE_NAME,ORG_ID,ORG_NAME
        }
    }
}

const parseStr = (action,item,page)=>{
    return (action + '_' + item + (page ? `_ON_${page}` : '')).toUpperCase();
}

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (action,item,page,props = {}) => {
        const basicProps = getProperty();
        const parsedStr = parseStr(action,item,page);
        if (env_check) mixpanel.track(parsedStr, Object.assign(basicProps,props));
    },
    time_event: (event) => {
        if (env_check) mixpanel.time_event(event.toUpperCase() + ' TOTAL DURATION');
    },
    calculateDuration: (event) => {
        if (env_check) mixpanel.track(event.toUpperCase() + ' TOTAL DURATION');
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
};

export default actions;
