import moment from "moment";
import interventionWording from "./interventionWording";
import {getProviderName} from "../../../../lib/utils";
import helper from "../../../taskAssignment/helper/index"

const monthYearTotalTable = [
    {
        title: 'Month/Year',
        dataIndex: 'monthOfYear',
        render:(value)=>("0" + value%100).slice(-2)+'/'+Math.round(value/100)
    },
    {
        title:'Total Time',
        dataIndex:'totalTime',
        render:(value)=>(
            Math.round(value/60) + (Math.round(value/60) > 1 ? ` mins` : ` min`)
        )
    },
]
const parseTime =(date)=>{
    return moment(date).format('hh:mm:ss a');
}
const parseDate = (date)=>{
    return moment(date).format('MM/DD/YYYY');
}
const columns = (providers) => [
    {
        title:'Date',
        dataIndex: "startTime",
        key:'date',
        width:'150px',
        sorter:(a,b) => b.startTime - a.startTime,
        render:(d)=>parseDate(d)
    },
    {
        title:'Provider',
        dataIndex:'provider',
        width:'200px',
        filters:(()=> {
            return _.map(providers, (fullName) =>({ text: fullName, value: fullName }));
        })(),
        render:(d)=>_.get(d,'profile.fullName'),
        onFilter: (value, record) => _.get(record,'provider.profile.fullName') === value
    },
    {
        title:'Total Time',
        width:'150px',
        sorter:(a,b)=>a.accumulatedTime-b.accumulatedTime,
        dataIndex: 'accumulatedTime',
        render:(d)=> {
            const minStr = d / 60;
            const secStr = d % 60;
            return `${_.round(minStr)} m ${_.round(secStr)} s`
        }
    },
    // {
    //     title:'Start Time',
    //     width:'200px',
    //     dataIndex: 'startTime',
    //     render:(d)=>parseTime(d)
    // },
    // {
    //     title:'End Time',
    //     dataIndex: 'endTime',
    //     width:'200px',
    //     render:(d)=>parseTime(d)
    // },
    {
        title: 'Interventions',
        dataIndex: 'interventions',
        render: (d) => d.map(d => interventionWording[d.intervention] ? interventionWording[d.intervention]: d.intervention).join()
    }
]

export default {
    columns, monthYearTotalTable
}
