import React from 'react';
import Client from 'libModule/gqlClient';
import editUserGQL from 'graphqlModule/mutation/editUser';
import getVisit from 'graphqlModule/singleVisit';
import '../style/chronicHisotry.styles.scss';
import CarePlanCarryOver from '../component/GoalStatement/CarePlanCarryOver';
import carryoverHelper from '../component/helper/carryOverHelper';
import {Form, Row, Col, Button, Popconfirm, Icon, Divider, Checkbox, Input} from 'antd';
import { diabeteList, hypertensionList,BGfreqList, BPfreqList,
  BGTreatments, extraNote,BPTreatments, BGSpecialist, BPSpecialist,
  generateConditionItems, getMutateVariableChronicDisease, defaultDiseaseHistoryItemMap, DISEASES, otherBillableList} from '../constant/chronicHistoryConstant';
import renderOverlayDiv from '../helpers/overLayButton';
import helpers from '../helpers/index';
import I18N from 'modulesAll/I18N';

import Mixpanel from 'modulesAll/mixPanel/mixPanel';

import { mappingCondition,nameMapping,conditionToType,conditionToEvaluationMap } from '../component/helper/goalHelper';
import SelfEvaluationComponent from "../../visitWorkList/component/SelfEvaluationComponent";
import { parseBGOrBPInitial } from '../helpers/healthConditionHelper';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
const { Item } = Form;

const BG = 'DIABETES';
const BP = 'HYPERTENSION';

const checkEmptyConditions = (obj) => {
  let isEmpty = true;
  _.mapKeys(obj, (value, key) => {
    if( key === 'specialistInfo'){
      if(typeof value.seenSpecialist !== 'undefined'){
        isEmpty = false;
      }
    } else if (typeof value !== 'undefined'){
      isEmpty = false;
    }
  })
  return isEmpty;
}

const BGKey = 'DIABETES';
const BPKey = 'HYPERTENSION';
// Component starts here
let ChronicHistoryComponent = class extends React.Component{
    static displayName = 'ChronicHistoryComponent';
    static title = 'Disease History';
    constructor(props) {
      super(props);
      // get condition keys
      let index = 0;
      let conditionKeys = [];
      const chronicDisease = _.get(props, 'user.profile.chronicDiseaseHistory', []);
      const otherBillableConditions = {};
      const otherConditions = [];
      _.map(chronicDisease, (ch) => {
        if( _.includes(DISEASES, ch.name) && !otherBillableConditions[ch.name]){
          // only store the first entry point
          otherBillableConditions[ch.name] = ch;
        } else {
          otherConditions.push(ch);
          conditionKeys.push(index++);
        }
      });
      const nonBillableName = _.get(props,'nonBillableDiseases',[])
      const billableByName = _.get(props,'billableDiseases',[])
      const healthConditionByName = [...nonBillableName,...billableByName];
      const BGTestValue = _.omit(_.get(props,'user.profile.BGTest',{}),'__typename');
      const BPTestValue = _.omit(_.get(props,'user.profile.BPTest',{}),'__typename');
      const hasBG = _.includes(billableByName, BGKey)
          // || helpers.hasSomeValue(BGTestValue);
      const hasBP = _.includes(billableByName,BPKey)
          // || helpers.hasSomeValue(BPTestValue);
      this.state = {
          conditionKeys,
          hasNote: _.get(this.props, 'user.profile.chronicDiseaseNote', null),
          showOldCareplanAssessment: !_.get(this.props, 'program.assessmentsFlag'),
          otherBillableConditions,
          otherConditions,
          nonBillableName,
          billableByName,
          healthConditionByName,
          index,
          BGRemoved: false,
          BPRemoved: false,
          hasBP,
          hasBG,
          BPValidationFlag: _.get(this.props, 'user.profile.BPValidationFlag', null),
          BGValidationFlag: _.get(this.props, 'user.profile.BGValidationFlag', null)
      }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      if(!_.isEqual(prevState.conditionKeys, this.state.conditionKeys) && !this.props.disabled ){
        // update the form field when in edit mode
        const {setFieldsValue, getFieldValue} = this.props.form;
        let history = getFieldValue('chronicDiseaseHistory');
        setFieldsValue({'chronicDiseaseHistory': _.filter(history, (_, i) => !this.state.conditionKeys.includes(i))});
      }

      if(prevProps.disabled === false && this.props.disabled === true){
        // when finish editing, update the state to filter out empty other condition
        let index = 0;
        let conditionKeys = [];
        const { getFieldValue } = this.props.form;
        let history = getFieldValue('chronicDiseaseHistory');
        _.map(history, (h) => { if(!checkEmptyConditions(h)) conditionKeys.push(index++) });
        this.setState({
            conditionKeys,
            hasNote: _.get(this.props, 'user.profile.chronicDiseaseNote', null),
            showOldCareplanAssessment: !_.get(this.props, 'program.assessmentsFlag'),
            index
        })
      }
    }

    addCondition = () =>{
      let index = this.state.index;
      this.setState({ conditionKeys: [...this.state.conditionKeys, index++], index});
      const {getFieldDecorator, setFieldsValue, getFieldValue} = this.props.form;
      if(!getFieldValue('conditionKeys')){
        getFieldDecorator('conditionKeys');
      }
      setFieldsValue({'conditionKeys': this.state.conditionKeys});
    }

    // removeOtherCondition = (index) =>{
    //   this.setState({conditionKeys: this.state.conditionKeys.filter((key, i) => key !== index)})
    // }

    removeOtherCondition =(index)=>{
      const { otherBillableConditions } = this.state;
      const { form } = this.props;
      const otherBillableConditionsName = form.getFieldValue('otherBillableConditionsName');
      if(_.includes(['BG','BP'],index)) {

            this.setState({
                [`${index}Removed`]: true
            },()=>{
                form.setFieldsValue({[`${index}Removed`]: true})
            })
      }
      if(otherBillableConditions[index]) {
          otherBillableConditionsName.splice(index, 1);
          form.setFieldsValue({ otherBillableConditionsName })
          const updatedOtherBillableConditions = _.omit(otherBillableConditions,index);
          this.setState({
              otherBillableConditions: updatedOtherBillableConditions
          }, ()=>{
            form.setFieldsValue({'otherBillable': updatedOtherBillableConditions})
          })
      }else{
          this.setState({conditionKeys: this.state.conditionKeys.filter((key, i) => key !== index)})
      }
    }

    handleSave = () => {
      const {validateFieldsAndScroll} = this.props.form;
     // validateFieldsAndScroll(['chronicDiseaseHistory', 'yearOfDiagnose'], (errors)=>{
      //  if(!errors){
          const parsedValue = getMutateVariableChronicDisease({props:this.props,state: this.state});
          Client.mutate({
            mutation: editUserGQL,
            variables: parsedValue
          }).then(() => this.props.refetch())
          .then(()=>this.props.afterSaveDraftSucc())
          .catch((error)=>{
            const {openErrorModal} = this.props;
            if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
          })
      //  }
     // })
    }

    handleSubmit = () => {
      const {afterSubmitSucc, openErrorModal, form, title, submitButtonText} = this.props;
      let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
      Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
      form.validateFieldsAndScroll((errors)=>{
        if(!errors){
          const parsedValue = getMutateVariableChronicDisease({props:this.props,state: this.state});
          Client.mutate({
            mutation: editUserGQL,
            variables: parsedValue
          }).then((res)=>{
            afterSubmitSucc();
          }).catch((error)=>{
            if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
          })
        }
      })
    }

    setNotes = (e) => {
      this.setState({hasNote: e.target.value});
      this.props.form.setFieldsValue({'chronicDiseaseNote': e.target.value});
    }

    renderItem = (source, form, items) =>{
      const {disabled} = this.props;
      const content = [];
        _.map(items, ({span, key, label, wrapperCol, labelCol, rules, initialValue, path, inputContent, formatValue, valuePropName, display,renderExtra}, i) => {
          let value = _.get(source, path, initialValue);
          if(value === null) value = initialValue;
          const renderExtraContent = typeof renderExtra=='function' ? renderExtra(value):'';
          const hidden = typeof display === 'function' ? !display(form) : null;
          if(!hidden){
          content.push(
            <Col key={i} id={`chronic-col-${i}`} span={span}>
              <Item key={key} label={label} wrapperCol={wrapperCol} labelCol={labelCol}>
                {form.getFieldDecorator(key,
                  { rules: typeof rules == 'function' ? rules(form, this) : rules,
                    initialValue: typeof formatValue === 'function' ? formatValue(value): value,
                    valuePropName: valuePropName || 'value'
                  }

                  )(inputContent(disabled, this, value))}
              </Item>
              {renderExtraContent}
            </Col>)
            }
          })
      return <div>
        {content}
      </div>
    }

    renderRemoveClick = (key, disabled,fn) => {
      return <div className='remove-click'>
      <Popconfirm
        placement='topRight'
        title={'Are you sure to remove this condition?'}
        onConfirm={() => fn(key)}
            // this.removeCondition(key)}
        okText="Yes"
        cancelText="No"
      >
       <a disabled={disabled}>Remove</a>
      </Popconfirm>
      </div>
    }

    getConditionNameClass = (itemsArr)=>{
        return itemsArr[0].className;
    }
    renderConditionName= (condition,form,itemsArr)=>{
        return <div>
                    {this.renderItem(condition, form, itemsArr)}
               </div>
    }

    renderOtherCondition = () =>{
      const { getConditionNameClass } = this;
      const {conditionKeys, otherConditions,otherBillableConditions,billableByName } = this.state;
      const {form, disabled, user} = this.props;
      return _.map(conditionKeys, (key, i)=>{
        const items = generateConditionItems(key,otherBillableConditions);
        return (
          <Row className='other-condition-border' key={key}>
            <div className='other-condition'>
            <div className='other-condition-content'>
              <Row className={getConditionNameClass(items[0])}>
                { this.renderConditionName(otherConditions[key], form, items[0])}
                { this.renderRemoveClick(key, disabled,this.removeOtherCondition) }
              </Row>
              <Row>{this.renderItem(otherConditions[key], form, items[1])}</Row>
              <Row>{this.renderItem(otherConditions[key], form, items[2])}</Row>
              <Row>{this.renderItem(otherConditions[key], form, items[3])}</Row>
            </div>
            </div>
            {
              i < conditionKeys.length -1 &&
              <Divider />
            }
          </Row>
        )
      })
    }
    // parseBGOrBPInitial = (type)=>{
    //     const DUMMYKEYS = ['SpecialistInfo','Treatments','Test','Visits'];
    //     const yearOfDiagnose = _.get(this.props,`user.profile.yearOfDiagnose`,[]);
    //     const BPOrBGKeys = (type)=>{
    //         return _.map(DUMMYKEYS,(k)=>`${type}${k}`);
    //     }
    //     const vitalName = {
    //         'BG': 'DIABETES',
    //         'BP': 'HYPERTENSION'
    //     }[type]
    //     const yearOfDiagnoseByType = ()=>{
    //         return _.omit(_.find(yearOfDiagnose,(v)=>v.condition == vitalName),'__typename');
    //     }
    //     console.log(type, BPOrBGKeys(type), yearOfDiagnoseByType());
    //     console.log(_.reduce(BPOrBGKeys(type),(res,key)=>{ _.set(res,key, _.get(this.props,`user.profile.${key}`,{})); return res;},{}));
    //     return Object.assign(_.reduce(BPOrBGKeys(type),(res,key)=>{ _.set(res,key, _.get(this.props,`user.profile.${key}`,{})); return res;},{}),yearOfDiagnoseByType());
    // }
    renderBG = (isBillable,BGRemoved) =>{
      const { user, form,disabled } = this.props;
      // const BGTestValue = _.omit(_.get(this.props,'user.profile.BGTest',{}),'__typename');
      // const BGTreatments = _.omit(_.get(this.props,'user.profile.BGTreatments',[]),'___type');
      const hasBGValue = helpers.hasSomeValue(parseBGOrBPInitial('BG', _.get(this.props, 'user.profile')));
      const showBG = !BGRemoved&&(isBillable|| hasBGValue);
      const showRemove = showBG && !isBillable;
      const BGTestStatus = typeof form.getFieldValue('BGTest.testRegularly') === 'boolean' ? form.getFieldValue('BGTest.testRegularly') : _.get(user, 'profile.BGTest.testRegularly');
      if(showBG){
        return(
          <div className='history-section'>
            <Row className='chronic-row-BG' >
            <div className={'headerAndRemoveButton'}>
              <div className = 'section-header'>Diabetes</div>
              { showRemove&&this.renderRemoveClick('BG',disabled,this.removeOtherCondition)}
            </div>
              { this.renderItem(user, form, diabeteList(this.state.BGValidationFlag)) }
              { BGTestStatus && this.renderItem(user, form, BGfreqList)}
            </Row>
            <Row>
              {this.renderItem(user, form, BGSpecialist)}
            </Row>
            <Row>
              { this.renderItem(user, form, BGTreatments)}
            </Row>
            <Divider />

          </div>
        )
      }
    }

    renderBP = (isBillable,BPRemoved) =>{
      const { user, form,disabled } = this.props;
      const hasBPValue = helpers.hasSomeValue(parseBGOrBPInitial('BP', _.get(this.props, 'user.profile')));
      const showBP = !BPRemoved&&(isBillable|| hasBPValue);
      const showRemove = showBP && !isBillable;
      const BPTestStatus = typeof form.getFieldValue('BPTest.testRegularly') === 'boolean' ? form.getFieldValue('BPTest.testRegularly') : _.get(user, 'profile.BPTest.testRegularly');
      if(showBP){
        return(
            <div className='history-section'>
              <Row id='chronic-row-BP' >
                <div className={'headerAndRemoveButton'}>
                    <div className = 'section-header'>Hypertension</div>
                    { showRemove&&this.renderRemoveClick('BP',disabled,this.removeOtherCondition)}
                </div>
                { this.renderItem(user, form, hypertensionList(this.state.BPValidationFlag)) }
                { BPTestStatus && this.renderItem(user, form, BPfreqList)}
              </Row>
              <Row>
                {this.renderItem(user, form, BPSpecialist)}
              </Row>
              <Row>
                  { this.renderItem(user, form, BPTreatments)}
              </Row>
              <Divider />
            </div>
        )
      }
    }

    renderOtherBillables = () => {
      const { chronicDiseaseConditions, form, user,disabled } = this.props;
      const { otherBillableConditions,healthConditionByName,billableByName } = this.state;
      const formData = form.getFieldsValue();
      const result = [];
      _.forEach(DISEASES, d => {
        const data = otherBillableConditions[d] || {};
        const dataWithoutNoise = _.omit(data,['__typename','name']);
        const hasValue = helpers.hasSomeValue(dataWithoutNoise);
        const hasCondition = _.includes(billableByName, d);
        const showCondition = hasCondition || hasValue;
        const showRemove = !hasCondition && hasValue;
        if(showCondition){
          const items = otherBillableList(d, data, formData, this);
          result.push(<div className='history-section'>
             <Row className={`chronic-row-${d}`} >
                <div className={'headerAndRemoveButton'}>
                    <div className = 'section-header'>{I18N.get(`chronicDisease.${d}.title`)}</div>
                    { showRemove&&this.renderRemoveClick(d, disabled,this.removeOtherCondition) }
                </div>
                <Row>{this.renderItem(user, form, items[0])}</Row>
                <Row>{this.renderItem(user, form, items[1])}</Row>
                <Row>{this.renderItem(user, form, items[2])}</Row>
            </Row>
            <Divider />
          </div>);
        }

      })
      return result;
    }

    renderSelfEvaluation = ()=>{
        const { props,state } = this;
        const { setShowSelfEvaluation,showSelfEvaluation } = props;
        return <SelfEvaluationComponent {...this.props} type={'CHARTING'} setShowSelfEvaluation={setShowSelfEvaluation} showSelfEvaluation={showSelfEvaluation}/>
    }

    // setShowSelfEvaluation =(showSelfEvaluation)=>{
    //     this.setState({
    //         showSelfEvaluation
    //     })
    // }
    renderHiddenFormItems = ()=>{
        const { user, form, isEditMode, isWorkList, disabled, program, enrolledProgramId, chronicDiseaseConditions } = this.props;
        const { hasNote, otherBillableConditions,BPRemoved,BGRemoved,hasBG,hasBP } = this.state;
        const otherBillableConditionsName = _.map(otherBillableConditions,n=>n.name);
        return <div>
                <Form.Item className={'notVisible'}>
                    {form.getFieldDecorator('BGRemoved',{initialValue:BGRemoved,valuePropName:'checked'})(<Checkbox style={{ visibility:'hidden',height:0 }}/>)}
                </Form.Item>
                <Form.Item className={'notVisible'}>
                    {form.getFieldDecorator('BPRemoved',{initialValue:BPRemoved,valuePropName:'checked'})(<Checkbox style={{ visibility:'hidden',height:0 }}/>)}
                </Form.Item>
                <Form.Item className={'notVisible'}>
                    {form.getFieldDecorator('otherBillableConditionsName',{ initialValue:otherBillableConditionsName })(<Input/>)}
                </Form.Item>
        </div>
    }
    render(){
      const { user, form, isEditMode, isWorkList, disabled, program, enrolledProgramId, chronicDiseaseConditions } = this.props;
      const { hasNote, showOldCareplanAssessment,BPRemoved,BGRemoved,hasBG,hasBP } = this.state;
      const className = !isWorkList ? 'chronicDiseaseHistory careplan' : 'chronicDiseaseHistory worklist';
      const onlyFormScrollableCN = showOldCareplanAssessment ? '' : ' scrollable-content';
      // const BGTestValue = _.omit(_.get(user,'profile.BGTest',{}),'__typename');
      // const BPTestValue = _.omit(_.get(user,'profile.BPTest',{}),'__typename');
      // const hasBG = helpers.hasSomeValue(BGTestValue);
      // const hasBP = helpers.hasSomeValue(BPTestValue);
      return (
        <div>
          {!enrolledProgramId && isWorkList && renderOverlayDiv(this.props)}
          <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.2 : 1 }}>
            {
              isWorkList &&
              <div className='chronic-history-worklist-header fix-header' style={{fontSize: '16px', fontWeight: 'bold', color: '#000000', lineHeight: '19px', paddingLeft: '8px',display:'flex'}}>
                <div className='fixHeaderTitle'>
                  Disease History
                  { this.renderSelfEvaluation() }
                  { renderDraftSavedText() }
                </div>
              </div>
            }
            <div className={showOldCareplanAssessment ? 'scrollable-content' : ' '}>
              {/*{ this.renderSelfEvaluation() }*/}
            <Form id='chronicDiseaseHistory' className={className + onlyFormScrollableCN} colon={false}>

              { this.renderBG(hasBG,BGRemoved) }
              { this.renderBP(hasBP,BPRemoved) }
              { this.renderOtherBillables() }
              { this.renderHiddenFormItems() }
              <Row className='chronic-row-other-condition' id='chronic-row-other-condition'>
                <div className='section-header'>
                  Other Condition
                </div>
                { this.renderOtherCondition()}
                {<Button type='link' onClick={this.addCondition} disabled={disabled} className='add-click'><Icon type='plus'/>Add Condition</Button>}
              </Row>
              <Row className='chronic-row-note' id='chronic-row-note'>
                {hasNote? this.renderItem(user, form, extraNote) :
                <Button type='link' onClick={()=> this.setState({hasNote: true})} disabled={disabled} className='add-click'><Icon type='plus'/>Add Note</Button>}
              </Row>
            </Form>
            {
                isWorkList ?
                (<Row className='fix-footer'>
                {isEditMode ? (
                  <Button
                    onClick={this.handleSave}
                    disabled={disabled}
                    className='saveDraftBtn'
                  >
                    Save Draft
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  onClick={this.handleSubmit}
                  type={'primary'}
                  disabled={disabled}
                >
                  {this.props.submitButtonText || 'Complete'}
                </Button>
              </Row>) : ''
              }
            {program&&showOldCareplanAssessment && isWorkList &&<CarePlanCarryOver {...this.props} carryOverSubmit={()=>carryoverHelper.submitAssessments(this)} path='assessments'/> }
            </div>
          </div>
        </div>
      )
    }
}

ChronicHistoryComponent = Form.create({
  onValuesChange: props => {
    const dummyState = props.form.getFieldsValue(['BGRemoved','BPRemoved']);
    const { onTouch, setChildProps } = props;
    if(typeof onTouch === 'function'){
      onTouch();
      const refetchQueries = [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}];
      setChildProps({props,state:dummyState }, getMutateVariableChronicDisease, editUserGQL, refetchQueries, ChronicHistoryComponent.title);
    }
  },
  onFieldsChange: (props) => {
    const { onChange } = props;
    const dummyState = props.form.getFieldsValue(['BGRemoved','BPRemoved']);
    // if(typeof onTouch === 'function'){
    //   onTouch();
    //   const refetchQueries = [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}];
    //   setChildProps(props, getMutateVariableChronicDisease, editUserGQL, refetchQueries, ChronicHistoryComponent.title);
    // }
    if(typeof onChange ==='function'){
      let editUserVar = getMutateVariableChronicDisease({props, state: dummyState});
      let shouldEditUser = true;
      onChange({
        shouldEditUser,
        editUserVar,
        props
      });
    }
  }
})(ChronicHistoryComponent);
export default ChronicHistoryComponent;
