import React from 'react'
import _ from 'lodash'


import './css/index.scss';


export default class extends React.Component{


  constructor(p){
    super(p)


    this.init()
  }

  defineBaseClassName(){
    return 'IH-Component'
  }

  getClassAndStyleProp(){
    const p = _.pick(this.props, ['className', 'style'])

    const cn = this.defineBaseClassName()+(p.className?(' '+p.className):'')
    return {
      className : cn,
      style : p.style||{}
    }
  }

  getBaseProps(){
    const cs = this.getClassAndStyleProp()

    return _.merge({}, this.props, cs)
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.ComponentNotUpdate){
      return false
    }

    return true
  }



  /* can be override start */
  init(){}


  render(){
    return <div></div>
  }

  /* can be override end */
}
