import {Icon, Input} from "antd";
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";
import RemoteEnrollmentPTOptTemplate from "../component/RemoteEnrollmentPTOptTemplate";
import patientList from 'graphqlModule/patientList';
import query from '../query/index';
import React from "react";
import {getFieldsAndMatch, getSearchStrForDOB} from "../../patientList/main/helper/searchHelper";
import remoteStatusMap from "../constant/remoteStatus";
const MAX_PAGE_COUNT = 15; // allow user to see MAX_PAGE_COUNT results at a time

const getAutoCompleteProps = (localThis)=> {
    const { state } = localThis;
    const { searchStr, canSearch } = state;
    const [fields, match] = getFieldsAndMatch(getSearchStrForDOB(searchStr));
    const crossClinic = _.get(localThis,'props.crossClinic');
    const autocompleteVariables = {
        page: 1,
        count: MAX_PAGE_COUNT,
        filters: { showAll: true,crossClinic },
        search: { fields, match },
        sort: { 'field': 'LAST_NAME', 'direction': 'ASC' }
    };
    return {
        queryOptions: {
            query: query.patientListWithRemoteEnrollment,
            variables: autocompleteVariables,
            skip: !canSearch || !searchStr, // do not search when flag canSearch is false, or search keyword is empty
            errorPolicy: 'ignore', // ignore error and allow MA/MD to view data
        },
        autocompleteProps: {
            className: 'search-patientlist-autocomplete',
            value: searchStr,
            renderInput: (isLoading) => (
              <Input.Search
                placeholder="Search ALL patients by First name, Last name / DOB / Med ID / Tel"
                loading={isLoading}
              />
            ),
            onSearch: (localThis.onSearchChange),// used to prevent multiple API calls when typing
            onSelect: (a,b) =>localThis.props.setShowModal(true,a,b),
            autoFocus: true,
            defaultActiveFirstOption: false,
            dropdownMenuStyle: {width: 700, maxHeight: 'fit-content', paddingLeft: 15},
            optionLabelProp: 'searchStr',
        },
        processDataSource: (data, OptionLayout) => {
            const patientList = _.get(data, 'patientList.data', []);
            const options = patientList.map(patient => {
                const { id, phone, identification, profile,remoteEnrollment,organization={ } } = patient.user;
                const remoteEnrollStatus =_.get(remoteEnrollment,'status');
                const {fullName,birthday} = profile;
                const mobilePhone = _.get(_.filter(phone, e => e.type === 'MOBILE'), '0.number', '--'); // get Tel
                const status = remoteEnrollStatus&&remoteStatusMap[ remoteEnrollStatus ]||''
                const organizationId = _.get(organization,'id');
                const orgName = _.get(organization,'name');

                const patientProps = {
                    fullName,
                    DOB: dataHelper.convertDateFormat(birthday),
                    MRN: _.get(identification, '0.value', '--'),
                    mobilePhone,
                    status,
                    organizationId,
                    name:orgName
                };

                return <OptionLayout key={id} value={id} searchStr={searchStr}>
                    <RemoteEnrollmentPTOptTemplate {...patientProps} />
                </OptionLayout>;
            })

            // add title for options iff there is a result, else show no result
            options.unshift(
                <OptionLayout key='search-result-title' disabled>
                    <RemoteEnrollmentPTOptTemplate hasNoResult={!options.length}/>
                </OptionLayout>
            )
            return options
        }
    }
}

export default {
    getAutoCompleteProps
}