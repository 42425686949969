import React, { useState, useEffect, useMemo, useRef } from 'react';
import { compose, graphql } from 'react-apollo';
import foodLogHelper from '../../foodLog/helper'
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";
import taskAssignmentUnreadTasks from '../../taskAssignment/query/taskAssignmentUnreadTasks';
import taskAssignmentOpenTasks from '../../taskAssignment/query/taskAssignmentUnreadList';
import { smartAlertTasksPageInfo } from '../../smartAlert/query/smartAlertTasksList';
import { patientCount } from '../../workStation/API';
import { getUserRole } from '../../../lib/utils';

const POLL_INTERVAL = 30000; //30 s
const DAYS_RANGE = 30;
const ROLES_GET_PATIENT_COUNT = ['RD', 'HC', 'CA'];

const getFoodLogUnreadList = graphql(foodLogUnreadList, {
  options: {
    variables: foodLogHelper.getFoodLogVariables({
      count: 1,
      filter: {
        fromDate: new Date((new Date()).getTime() - DAYS_RANGE * 24 * 3600000),
        reviewed: false
      }
    }),
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  },
  props: ({ data }) => ({
    foodlogUnreadCount: _.get(data, 'foodLogList.pageInfo.total') || 0,
  })
});

const getTaskAssignmentOpen = graphql(taskAssignmentOpenTasks, {
  options: () => ({
    variables: {
      count: 1,
      filters: {
        assignedTo: [sessionStorage.getItem('currentUserId')],
      }
    },
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  }),
  props: ({ data }) => ({
    openTaskCount: _.get(data, 'taskAssignmentList.pageInfo.total') || 0, // count of patients with open task 
  })
});

const getTaskAssignmentUnread = graphql(taskAssignmentUnreadTasks, {
  options: () => ({
    variables: {
      filters: {
        assignedTo: [sessionStorage.getItem('currentUserId')],
        isRead: false
      }
    },
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  }),
  props: ({ data }) => ({
    unreadTaskCount: _.get(data, 'taskAssignmentList.pageInfo.total') || 0, // count patients with unread task
  })
});

const getSmartAlertTasksPageInfo = graphql(smartAlertTasksPageInfo, {
  options: {
    variables: {
      filters: {
        status: 'TODO',
        categories: ['PROVIDER']
      }
    },
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL
  },
  props: ({ data }) => ({
    smartAlertsCount: _.get(data, 'smartAlertTasksList.pageInfo.total') || 0
  })
});

const withPatientCount = (WrappedComponent) => {
  const PatientCountWrapper = (props) => {
    const mounted = useRef(false);
    const [role] = useState(getUserRole());
    const [unresolvedWorkStationTaskCount, setUnresolvedWorkStationTaskCount] = useState(-1);


    const checkUnresolvedWorkStation = async () => {
      const res = await patientCount();
      if (res.code !== 200) {
        console.error('code ', res.code, ', message ', res.message);
        return;
      }
      if (mounted.current) {
        setUnresolvedWorkStationTaskCount(res.data);
      }
    }

    useEffect(() => {
      mounted.current = true;
      let interval;
      if (ROLES_GET_PATIENT_COUNT.includes(role)) {
        checkUnresolvedWorkStation(); // initial call
        interval = setInterval(checkUnresolvedWorkStation, POLL_INTERVAL);
      }
      return () => {
        clearInterval(interval);
        mounted.current = false;
      };
    }, []);

    return (
      <WrappedComponent
        {...props}
        unresolvedWorkStationTaskCount={unresolvedWorkStationTaskCount}
      />
    );
  };

  return PatientCountWrapper;
}

const getTaskAssignmentUnreadList = compose(getTaskAssignmentOpen, getTaskAssignmentUnread);
const composePolls = (role) => (({
  RD: compose(getFoodLogUnreadList, getTaskAssignmentUnreadList, getSmartAlertTasksPageInfo),
  HC: compose(getFoodLogUnreadList, getTaskAssignmentUnreadList, getSmartAlertTasksPageInfo),
  CA: compose(getFoodLogUnreadList, getTaskAssignmentUnreadList, getSmartAlertTasksPageInfo),
  MD: compose(getTaskAssignmentUnreadList, getSmartAlertTasksPageInfo),
  MA: compose(getTaskAssignmentUnreadList)
})[role] || compose());

const withPollNotificationsByRole = (WrappedComponent) => {
  const PollNotificationsWrapper = (props) => {
    const [role, setRole] = useState(getUserRole());

    useEffect(() => {
      const newRole = getUserRole();
      if (newRole !== role) {
        setRole(newRole);
      }
    }, [getUserRole()]);

    const EnhancedC = useMemo(() => composePolls(role)(withPatientCount(WrappedComponent)), [role]);
    return <EnhancedC {...props} />;
  }

  return PollNotificationsWrapper;
};

export default withPollNotificationsByRole;
