import gql from 'graphql-tag';
import { SignOffStatusSchema } from '../schema/SignOffStatus';

const signOffVisit = gql`
  mutation signOffVisit($id: EID!, $signOffStatus: InputSignOffStatus) {
    signOffVisit(id: $id, signOffStatus: $signOffStatus) {
      id
      checkoutAt
      ${SignOffStatusSchema}
    }
  }
`;


export {
  signOffVisit
};