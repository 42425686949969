import Client from 'libModule/gqlClient';
import getPresignedLogoUploadQuery from '../../../modules/superAdmin/organization/query/getPresignedLogoUpload';
import getPresignedFileUploadQuery from '../../../modules/graphql/getPresignedFileUpload';
import getSignedFileDownload from "modulesAll/graphql/getSignedFileDownload";


const getPresignedFileUpload = async ({userId,filename})=>{
       try {
           const res = await Client.query({
               query: getPresignedFileUploadQuery,
               variables: {
                   userId,
                   filename
               },
               fetchPolicy: 'network-only'
           })
           return _.get(res,'data.getPresignedFileUpload.data');
       }
       catch(e){
           throw e;
       }
}
const getPresignedLogoUpload = async ({organizationId,filename})=>{
    try {
        const res = await Client.query({
            query: getPresignedLogoUploadQuery,
            variables: {
                organizationId,
                filename
            },
            fetchPolicy: 'network-only'
        })
        return _.get(res,'data.getPresignedLogoUpload.data');
    }
    catch(e){
        throw e;
    }
}

const getFileUrl = async (variables)=>{
    return await Client.query({
        query:getSignedFileDownload,
        variables,
        fetchPolicy: 'network-only'
    })
}

const sendAJaxReq = ({uploadUrl,fields,mimeType,filename,file,setProgress}) =>{
    let formData = new FormData();
    Object.keys(fields).forEach(key => formData.append(key, fields[key]));
    formData.append('file', file,filename);
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener("progress",(e)=>{
            const { loaded,total } = e;
            setProgress(loaded/total);
        });
        xhr.addEventListener('error', (e) => {
            reject(e);
        });

        xhr.onreadystatechange = function (e) {
            if (xhr.status >= 200 && xhr.status <= 299) {
                 resolve(fields.key);
            }
            if( xhr.status==400){
                const parse = new DOMParser();
                let xmlDoc = parse.parseFromString(xhr.response,"text/xml");
                const str = xmlDoc.getElementsByTagName('Message');
                const error = str[0].childNodes[0].nodeValue;
                reject(error);
            }
        };
        xhr.open('POST', uploadUrl,true);
        xhr.send(formData);
    })
}

export default {
    sendAJaxReq,
    getPresignedFileUpload,
    getFileUrl,
    getPresignedLogoUpload
}
