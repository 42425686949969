import { React, _, IHRadio } from 'ihcomponent';

export default (props) => {
  const { options, answer, onChange, isPreview } = props;
  const value = JSON.parse(_.get(answer, 'content') || '""');

  const OPTIONS = _.map(Array(10), (v, i) => ({ name: i + 1, value: i }));

  const onRadioChange = ({ target }) => {
    onChange({ content: JSON.stringify(target.value) });
  };

  return (
    <div className="scaled">
      <IHRadio
        type="radioButton"
        option={OPTIONS}
        disabled={isPreview}
        value={value}
        onChange={onRadioChange}
      />
      <div className="vertical-line left" />
      <span className="display left">
        {options[0].meaning}
      </span>

      <div className="vertical-line right" />
      <span className="display right">
        {options[9].meaning}
      </span>
    </div>
  );
};
