import types from './TableConstants';
import { updateHistory, loadFromUrl } from './_utils';
import update from 'immutability-helper';

export const initState = {
  // for each tableName there will be a reducer
  //
  // tableName: { sort: {}, filter: {}, page: {}, search: '', selectedRowKeys: [] },
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.RESET_TABLE_PROPS:{
        const { tableName,setting } = action;
        const { reducerNext } = setting;
        const newState = {
            ...state[tableName],
            filter: { },
            sort: { },
            page: { },
        };
        return {
            ...state,
            [tableName]: reducerNext(newState),
        };
    };

    case types.ON_TABLE_CHANGE: {
      const { tableName, setting, page, filter, sort } = action;
      const { reducerNext, shouldUpdateHistory, sortFieldMap } = setting;
      // 20200626 Thong - enhancement for multi-tab tables
      // multi-tab table should have selectedTab in state/initState
      const selectedTab = state[tableName] && state[tableName].selectedTab;
      const newState = {
        ...state[tableName],
        filter,
        sort: !selectedTab ? _.pick(sort, ['field', 'order']) : sort,
        page: !selectedTab ? {
          pageSize: _.get(page, 'pageSize', 10),
          current: _.get(page, 'current', 1),
        } : page,
        tableName,
      };

      let sorter = _.get(newState, 'sort', {});
      if(selectedTab) sorter = _.get(sorter, selectedTab, {});
      const sortField = sorter.field;
      const sortOrder = sorter.order;

      if (sortField) { 
        const fieldGraph = _.get(sortFieldMap, sortField, sortField);
        const direction = sortOrder ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : sortOrder;
        if(selectedTab) {
          newState.sort[selectedTab].fieldGraph = fieldGraph;
          newState.sort[selectedTab].direction = direction;
        } else {
          newState.sort.fieldGraph = fieldGraph;
          newState.sort.direction = direction;
        }
      }

      if (shouldUpdateHistory) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SELECT_ROW: {
      const { tableName, setting, selectedRowKeys } = action;
      const { reducerNext } = setting;

      const newState = {
        ...state[tableName],
        selectedRowKeys: selectedRowKeys || [],
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SEARCH: {
      const { tableName, setting, search } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        search: search || '',
        canSearch: false,
        tableName,
      };

      if (shouldUpdateHistory) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_TABLE_SEARCH_ENTER: {
      const { tableName, setting } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        canSearch: true,
        prevSearch: state[tableName].search,
        /* HACK: Need to set page to 1, otherwise will try to fetch page 'page.current' which may yield no data */
        page: update(_.get(state[tableName], 'page', {}), { current: { $set : 1 } })
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.FILTER_TABLE_BY_DATE_RANGE: {
      const { tableName, setting, dateRangeToFilter } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        dateRangeToFilter,
        /* HACK: Need to set page to 1, otherwise will try to fetch page 'page.current' which may yield no data */
        page: update(_.get(state[tableName], 'page', {}), { current: { $set : 1 } })
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.SHOW_TASKS_BY_STATUS: {
      const { tableName, setting, table_select } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      const newState = {
        ...state[tableName],
        table_select
      };

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_LOAD_PARAMS: {
      const { tableName, setting, params, isLoadFromUrl } = action;
      const { reducerNext, shouldUpdateHistory } = setting;

      let newParams = params || setting.initState;
      const routerTableName = _.get(action, 'routing.location.query.tableName');
      if (shouldUpdateHistory && isLoadFromUrl && !params && routerTableName && JSON.parse(routerTableName) === tableName) {
        newParams = loadFromUrl(action.routing);
      }

      const newState = {
        ...state[tableName],
        ...newParams,
        tableName,
      };
      // for alert table, when unload, updateHistory is unnecessary
      if (shouldUpdateHistory && isLoadFromUrl) {
        updateHistory(action.routing, newState);
      }

      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    case types.ON_SET_PARAMS: {
      const { tableName, setting, paramObj, routing } = action;
      const { reducerNext, shouldUpdateHistory } = setting;
      const newState = {
        ...state[tableName],
        ...paramObj,
        tableName,
      };
      if (shouldUpdateHistory) {
        updateHistory(routing, newState);
      }
      return {
        ...state,
        [tableName]: reducerNext(newState),
      };
    }

    default: {
      return state;
    }
  }
};
