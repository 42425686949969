import { providerMenuItems } from './provider';

const menus = {
  ...providerMenuItems,
  videochat: {
    id: 'videochat',
    title: 'Visits',
    // icon: 'dashboard',
    src: '/image/visits.png',
    icon: 'bar-chart',
    href: '/videochat',
    class: 'videochat'
  },
  Chat: {
    id: 'messages',
    title: 'Messages',
    href: '/messages',
    src: '/image/chat-icon.svg',
    class: 'messages'
  },
  visits: {
    id: 'schedule',
    title: 'Calendar',
    // icon: 'dashboard',
    icon: 'bar-chart',
    href: '/schedule',
    class:'visits'
  }
};

const maArray = [
  menus['videochat'],
  // menus['visits'],
  menus['Patients'],
  menus['Chat'],
  // menus['WorkStation'],
  menus['TaskAssignments'],
  menus['DataInsights'],
  menus['Reports'],
];

export default maArray;
export const HOMEPAGE = '/videochat';
export const specialMAMenu = [
  menus['videochat'],
  menus['Patients'],
  menus['Chat'],
  menus['TaskAssignments'],
];
