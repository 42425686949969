

export default (meetingNumber,passWord,apikey,fullName)=> ({
    apiKey: apikey,
    meetingNumber,
    // apiSecret: API_SECRET,
    userName: fullName,
    passWord,
    leaveUrl: '/',
    role: 0
});

