import moment from "moment/moment";
import { FOODLOG_COUNT_MAX } from "../../../lib/constants";

export default {
    getFoodLogVariables: (ownProps) => {
        const { count, filter = {}, value, year } = ownProps;
        const variables = {
          // TODO: filter should not have count, check APIs for foodLog
            count: filter.count ? 
              (filter.count > FOODLOG_COUNT_MAX ? FOODLOG_COUNT_MAX : filter.count) 
              : 
              (count ? 
              (count > FOODLOG_COUNT_MAX ? FOODLOG_COUNT_MAX : count)
              : FOODLOG_COUNT_MAX)
        }

        const defaultFilter = ownProps.userId ? undefined : 'false';
        const rating = _.get(filter, 'rating[0]', defaultFilter);
        const memberId = _.get(ownProps, 'userId');
        const reviewed = filter ? _.get(filter, 'reviewed') : undefined;
        if (reviewed != undefined) {
            variables.filters = {
                reviewed
            }
        }
        else {
            if (rating != undefined) {
                if (rating == '0') {
                    variables.filters = {};
                }
                else {
                    variables.filters = {
                        reviewed: (rating === 'true')
                    };
                }
            }
            else {
                variables.filters = {};
            }
        }
        // if (rating != undefined) {
        //     if (rating == '0') {
        //         if (reviewed != undefined) {
        //             variables.filters = { reviewed: reviewed };
        //         }
        //         else {

        //         }
        //     }
        //     else {
        //         variables.filters = {
        //             reviewed: (rating === 'true')
        //         }
        //     }
        // }
        // else {
        //     variables.filters = {
        //         reviewed:false
        //     }
        // }

        const sortField = 'CREATED_AT';
        variables.filters.memberId = memberId;

        if (ownProps.userId) {
            if (year && !_.isNil(value)) {
                const fromDate = moment([year, value]).startOf('month').valueOf();
                const toDate = moment(fromDate).endOf('month').valueOf();

                Object.assign(variables.filters, { fromDate, toDate });
            }
            else {
                Object.assign(variables.filters, { fromDate: undefined, toDate: undefined });
            }
        } else {
            // 30 days for the foodlog list when no patient is given.
            const days = 30;
            const toDate = undefined;

            // Set start to 30 days ago
            const fromDate = moment().subtract(days, 'days').startOf('day').valueOf();
            Object.assign(variables.filters, { fromDate: fromDate, toDate });
        }
        variables.sort = {
            field: sortField,
            direction: ownProps.sort ? (ownProps.sort.direction || 'DESC') : 'DESC'
        }
        return variables;
    }
}

const ENGLISH = 'English';
export const getUserTranslateLanguage = ({ language, appLanguage }) => {
    let selected;
    if (language === appLanguage) {
      if (language === ENGLISH) {
        selected = ENGLISH;
      } else {
        selected = appLanguage;
      }
    } else {
      if (appLanguage === ENGLISH) {
        selected = language;
      } else {
        selected = appLanguage;
      }
    }
    return selected || ENGLISH;
}

