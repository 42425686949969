import { graphql } from 'react-apollo';

import { getOnCalleeOptionsQuery } from '../../graphql/assignee.js';

export const withCoveredMemberOptions = graphql(getOnCalleeOptionsQuery, {
  options: () => {
    return {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    };
  },
  props: ({ data }) => {
    const { loading, getOnCalleeOptions, error } = data;
    
    if(error)
      console.error(error);

    // convert memberId to id to make it compatible with previous implementation
    const coveredMemberOptions = _.map(getOnCalleeOptions, o => ({ id: o.memberId, ...o }));

    return { 
      loadingCoveredMemberOptions: loading,
      coveredMemberOptions
    };
  }
});