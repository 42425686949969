import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArticlesContainer from './ArticlesContainer';

import '../style/interventionEducation.styles.scss';

const  InterventionEducation = props => {
  const { 
    disabled, onChange: globalOnChange, user, onInteraction
  } = props;
  const setHasChanges = () => {
    if(globalOnChange) {
      globalOnChange({

      });
    }
  }

  return (
    <div id='intervention-education-main-container'>
      <div className="__title">
        {InterventionEducation.title}
      </div>
      <div className="__body">
        <ArticlesContainer
          patientId={_.get(user, 'id')}
          patientLanguageSettings={(
            _.pick(_.get(user, 'profile'), ['language', 'appLanguage'])
          )}
          disabled={disabled}
          onInteraction={onInteraction}
          setHasChanges={setHasChanges}
        />
      </div>
    </div>
  );
}

InterventionEducation.propTypes = {
  disabled: PropTypes.bool, // to toggle editmode in global
  onChange: PropTypes.func, // set queryVariables and editMode=>true
  user: PropTypes.object,
}

InterventionEducation.title = 'Education';
InterventionEducation.displayName = 'InterventionEducationContainer';

export default InterventionEducation;
