import { React, _ } from 'ihcomponent';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import createTableAction from 'libModule/table/TableActions';
import { getRefIDWithRoleCategory } from 'libModule/utils'
import ProviderListComponent from '../components/ProviderListComponent';
// graphql
import providerList from 'graphqlModule/providerList'

const tableActions = createTableAction('admin_provider_list');

const withData = graphql(providerList, {
  options: (ownProps) => {
    const allRoles = JSON.parse(sessionStorage.getItem("appRoles"));
    const providerRoles = [];
    for (let key in allRoles) {
      if (key === 'PROVIDER:Doctor' || key === 'PROVIDER:Nurse' || key === 'PROVIDER:Medical Assistant' || key === 'PROVIDER:Clinic Medical Assistant' ) {
        providerRoles.push(allRoles[key]);
      }
    }
    const variables = {
      page: _.get(ownProps, 'page.current', 1),
      count: 10,
      search: {
        fields: ['NAME', 'IDENTIFICATION', 'EMPLOYEEID'],
        match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
      },
      sort: {},
      filters: {
        roles: providerRoles
      }
    };
    return {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    };
  },
  props: ({data}) => {
    const {error, userList = {}, loading} = data;
    if (error) {
      console.warn(error);
    }
    return {
      data,
      userList,
      loading,
      pageInfo: userList.pageInfo
    };
  }
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.admin_provider_list,
});

export default compose(
  connect(mapStateToProps, () => tableActions),
  withData,
)(ProviderListComponent);
