import * as d3 from 'd3'
import {React, BaseComponent, _} from 'ihcomponent'
import I18N from 'modulesAll/I18N';
//import MultiSelect from './MultiSelect'
import ProgramSelect from '../containers/ProgramSelect'
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { createAuditLog } from 'libModule/utils/auditLog'

const COLOR_ARRAY = [
  '#e85c6a', '#e89b5c', '#6e6e6e', '#258ce6', '#c1d036'
];

// TODO: Why is this invoked, on Dashboard reload? Weird.
// console.log('--------- inside dashboard/main/components/SelectAlerts.js!');

// 12/28/17 - Deprecated bc we r using different dashboard for Barbados
const Component = class extends BaseComponent{
  static displayName = 'SelectAlerts'

  render(){
    return (
      <div className="vsm-SelectAlerts-comp">

        <div className="v-header">
          <div className="v-right">
            <ProgramSelect />
          </div>
          <div className="v-right">{
            this.renderMultiSelect()
          }</div>
        </div>
        {this.renderBody()}
      </div>
    )
  }

  renderBody(){
    const {list, currentProgram} = this.props

    // If no programmes have been created
    if (_.isNull(currentProgram) || _.isUndefined(currentProgram)){
      return (
        <div className="v-body">
          <div className="v-empty flex_center">
            <p>
              <span>{I18N.get('dashboard.noProgramCreated')}</span>
            </p>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="v-body">
          {
            _.map(list, (item, index)=>{
              return this.renderEachItem(item, index)
            })
          }
        </div>
      )
    }
  }

  renderEmpty(){
    return (
      <div className="v-empty flex_center">
        <p>
          <span>{I18N.get('dashboard.noDataPanelSelected')}</span>
          <br/>
          {
            //this.renderMultiSelect()
          }
        </p>

      </div>
    )
  }
  onItemClick(item) {
    return; // To Fix: remove this line if need this functionality
    if (item.type === 'Alert' && item.num > 0) {
      createAuditLog({
        action: `View ${item.title} ${item.info}`,
        details: { 'programName' : this.props.enrolledProgramName.adminProgram.name }
      })

      const params = {
        filter: {
          "programId": [this.props.currentProgram],
          "severity": this.convertToSeverity(item.title),
          "careStatus": ['NEW']
        }
      }
      browserHistory.push({
        pathname: '/alerts',
        query: _.mapValues(params, v => JSON.stringify(v)),
      });
    }
    else if (item.type === 'Patient' && item.num > 0) {
      createAuditLog({
        action: `View ${item.title} ${item.info}`,
        details: { 'programName' : this.props.enrolledProgramName.adminProgram.name }
      })

      const params = {
        filter: {
          "adminProgramId": [this.props.currentProgram]
        }
      }
      if (item.info === "<7 days left") {
        params.filter.daysRemaining = "7"
      }
      if (item.info === "Suspended") {
        params.filter.status = ["SUSPENDED"]
      }
      browserHistory.push({
        pathname: '/patients',
        query: _.mapValues(params, v => JSON.stringify(v)),
      });
    }
  }
  convertToSeverity(title) {
    switch (title) {
      case "CL/CH Threshold": return ['CRITICAL'];
      case "L/H Threshold": return ['RISK'];
      case "Missed Reading": return ['MISSED'];
    }
  }
  renderEachItem(item, index){
    return (
      <div key={index} className="v-ea" onClick={this.onItemClick.bind(this,item)}>
        <b style={{color:COLOR_ARRAY[index%5]}}>{item.num}</b>
        <p className="c1">{item.title}</p>
        <p className="c2">{item.info}</p>
      </div>
    )
  }

  renderMultiSelect(){
    // const p = {
    //   ...this.props
    // }
    //return <MultiSelect {...p} />
    return
  }
}

Component.propTypes = {
  title : PropTypes.string,
  list : PropTypes.array
}

export default Component
