import React from 'react';
import { Form, Icon, Row, Col, Button} from 'antd';
import Client from 'libModule/gqlClient';
import editUserGQL from 'graphqlModule/mutation/editUser';
import getVisit from 'graphqlModule/singleVisit';
import '../style/socialSupport.styles.scss';
import UserClass from 'modulesAll/graphql/class/User';
import { socialSupportItems, socialSupportNote }  from '../constant/socialSupportConstant';
import { getMutateVariableLifeStyle } from '../constant/lifestyleConstant';
import { GQLHelper } from 'libModule/utils';
import renderOverlayDiv from '../helpers/overLayButton';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { renderDraftSavedText } from '../../visitWorkList/helpers';

const { Item } = Form;

// Component starts here
let SocialSupportComponent = class extends React.Component{
    static displayName = 'SocialSupportComponent';
    static title = 'Social Support';
    constructor(props) {
      super(props);
      const { isWorkList } = props;
      this.state = {
          hasNote: _.get(this.props, 'user.profile.socialSupport.extraNote', null),
      }
    }

    handleOnSave = () => {
      const parsedValue = getMutateVariableLifeStyle(this.props);
      Client.mutate({
        mutation: editUserGQL,
        variables: parsedValue
      }).then(() => this.props.refetch())
      .then(()=>this.props.afterSaveDraftSucc())
      .catch((error)=>{
        const {openErrorModal} = this.props;
        if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
      })
    }

    handleSubmit = () =>{
      const { afterSubmitSucc, openErrorModal, form, title, submitButtonText} = this.props
      let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
      Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
      form.validateFieldsAndScroll((errors)=>{
        if(!errors){
          const parsedValue = getMutateVariableLifeStyle(this.props);
          Client.mutate({
            mutation: editUserGQL,
            variables: parsedValue
          }).then((res)=>{
            afterSubmitSucc();
          }).catch((error)=>{
            if(typeof openErrorModal === 'function') openErrorModal(GQLHelper.formatError(error));
          })
        }
      })
    }


    renderBodyInfo() {
      const { user,isWorkList } = this.props;
      this.uc = new UserClass(user);
      const bodyInfo = this.uc.format('bodyInfo');
      let showInfo;
      if (bodyInfo.length > 0) {
          showInfo = (
            <div className= 'socialSupport-bodyInfo' id='socialSupport-bodyInfo'>
                {
                    _.map(bodyInfo, (info, index) => {
                    return <span className="v-tag" style={{marginRight: 10}} key={index} id={`socialSupport-bodyInfo-${index}`}>{info}</span>
                    })
                }
            </div>
          )
      } else {
        showInfo = (<div className='socialSupport-bodyInfo'>Body info not on file</div>)
      }

      return(
        <div className='fix-header' style={{ marginLeft: 8 }}>
          <div className='socialSupport-worklist-header' style={{fontSize: '16px', fontWeight: 'bold', color: '#000000', lineHeight: '19px'}}>
            <div className='fixHeaderTitle'>
              Social Support
              { renderDraftSavedText() }
            </div>
          </div>
          {showInfo}
        </div>
      )
    }

    setNotes = (e) => {
      const { form } = this.props;
      const {setFieldsValue} = form;
      this.setState({hasNote: e.target.value}, () => {
        setFieldsValue({ socialSupportNote: this.state.hasNote })
      });
    }

    renderForm = (itemList) =>{
      const { user, form, disabled} = this.props;
      const { isMNT } = this.state;
      return _.map(itemList, (items, index) => {
        return (
          <Row key={index} className={`socialSupport-row-${index}`} id={`socialSupport-row-${index}`}>
            {
              _.map(items, ({span, key, label, wrapperCol, labelCol, rules, initialValue, path, inputContent, valuePropName, placeHolder, MNTOnly}, i) => {
                let value = _.get(user, path);
                if(!value && value !== 0) value = initialValue;

                return (
                  <Col key={i} className={`socialSupport-col-${i}`} id ={`socialSupport-col-${i}`} span={span} >
                    { (!isMNT && MNTOnly) ? null : placeHolder ? inputContent(this) : <Item key={key} label={label} wrapperCol={wrapperCol} labelCol={labelCol}>
                      {form.getFieldDecorator(key,
                        {rules: typeof rules === 'function' ? rules(form, this) : rules,
                         initialValue: value,
                         valuePropName:valuePropName || 'value'}
                        )(inputContent(disabled, this))}
                    </Item>
                    }
                  </Col>
                  )
              })
            }
          </Row>
        )
      })
    }

    render(){
      const { hasNote } = this.state;
      const { isWorkList, disabled, form, isEditMode, user, enrolledProgramId } = this.props;
      return (
        <div>
          {!enrolledProgramId && isWorkList && renderOverlayDiv(this.props)}
          <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.1 : 1 }}>
              {isWorkList ? this.renderBodyInfo() :''}
            <Form id='socialSupport' className='socialSupport scrollable-content' colon={false}>
              { this.renderForm(socialSupportItems) }
              {hasNote? this.renderForm(socialSupportNote) :
                <Button type='link' onClick={()=> this.setState({hasNote: true})} disabled={disabled} className='add-click'><Icon type='plus'/>Add Note</Button>}
            </Form>
            {
                isWorkList ?
                (<Row className='fix-footer'>
                {isEditMode ? (
                  <Button
                    onClick={this.handleOnSave}
                    className='saveDraftBtn'
                  >
                    Save Draft
                  </Button>
                ) : ''}
                <Button
                  onClick={this.handleSubmit}
                  type={'primary'}
                >
                  {this.props.submitButtonText || 'Complete'}
                </Button>
              </Row>) : ''
              }
            </div>
          </div>
      )
    }
}

SocialSupportComponent = Form.create({
  onValuesChange: (props) => {
    const { onTouch, setChildProps} = props;
    if(typeof onTouch === 'function'){
      onTouch();
      const refetchQueries = [{query: getVisit, variables: { id: _.get(props, 'visit.id') },  fetchPolicy: 'network-only'}];
      setChildProps(props, getMutateVariableLifeStyle, editUserGQL, refetchQueries, SocialSupportComponent.title);
    }
  },
  onFieldsChange: (props) => {
    const { onChange } = props;
    if(typeof onChange ==='function'){
      let editUserVar = getMutateVariableLifeStyle(props);
      let shouldEditUser = true;
      onChange({
        shouldEditUser,
        editUserVar,
        props
      });
    }

  }
})(SocialSupportComponent);
export default SocialSupportComponent;
