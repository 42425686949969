import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Form, DatePicker, Select, Tooltip, Checkbox, Row, Col, Button, Icon, Radio } from 'antd';
import { graphql, compose } from 'react-apollo';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import I18N from '../../I18N';
import { visitConstants, STATUS_ENUM } from '../constant';
import { helpers as dataHelper } from '../../visitNewWorkFlow/helper/index';
import { API as visitAPI } from '../../visit/query';
import orgScheduleQuery from 'modulesAll/graphql/orgSchedule';
import { getVisitRoles } from '../../../lib/utils/common';
import { upsertPatientReferralMutation } from '../API/mutation';
import { VISIT_CATEGORY_ENUM } from '../../visitsPage/constants';
import { getPhone } from '../helper/initialValueHelper';

import ReferAndEnrollForm from './ReferAndEnrollForm';

import '../style/scheduleVisit.scss';
import visitList from "../../graphql/visitList";
import visitListMini from "../../graphql/visitListMini";
import { IHLoading } from 'ihcomponent'

const KEYS = _.mapKeys(['date', 'hour', 'min', 'category', 'scheduleLater','remoteHour']);

export const getDisabledHours = (orgSchedules) => {
  const hours = {};

  for (let hour24 = 0; hour24 < 24; hour24++) {
    hours[hour24] = {
      '24hour': hour24,
      '12hour': hour24 > 12 ? hour24 - 12 : hour24,
      disabled: false
    };
  }

  if (Array.isArray(orgSchedules)) {
    orgSchedules.forEach(o => {
      const startTime = +moment(o.startTime).format('HH');
      const endTime = +moment(o.endTime).format('HH');

      // SC-7051: apply the serving hours settings to ALL roles and all visits
      // if (o.blockingRoles.includes(role)) {
      for (let hour24 = 0; hour24 < 24; hour24++) {
        let disabled = false;

        // if endTime is the next day
        if (startTime > endTime) {
          disabled = hour24 >= startTime || hour24 < endTime;
        } else {
          disabled = hour24 >= startTime && hour24 < endTime;
        }

        hours[hour24].disabled = hours.length > 0 ? hours[i].disabled && disabled : disabled;
      };
    });
    return hours;
  }
  return hours;
};

const visitDate = visitInfo => ({
  key: KEYS.date,
  label: I18N.get('visit.details.visitDate.required.label'),
  colProps: { 
    span: 6,
    style: { marginRight: 24 }
  },
  initialValue: moment(_.get(visitInfo, 'appointmentAt')),
  rules: (ignoreRequired) => [{
    required: !ignoreRequired,
    message: I18N.get('visit.details.visitDate.required.message')
  }],
  render: (props) => {
    return <DatePicker 
      format={visitConstants.VISIT_DATE_FORMAT} 
      allowClear={false} 
      style={{ width: '100%' }}
      disabledDate={current => current && current < moment().startOf('day')}
      disabled={props.disabled}
    />
  }
});

const visitHour = (visitInfo, disabledHours = {}) => ({
  key: KEYS.hour,
  label: I18N.get('visit.details.visitTime.required.label'),
  colProps: { 
    span: 4,
    style: { marginRight: 8 }
  },
  initialValue: (visitInfo && moment(_.get(visitInfo, 'appointmentAt')).format('HH')) || null,
  rules: (ignoreRequired) => [{
    required: !ignoreRequired, 
    message: I18N.get('visit.details.visitTime.required.message')
  }],
  render: (props) => {
    return <Select /*onChange={(v)=>onChangeHour(v,true,props)}*/ disabled={props.disabled}>
      {
        _.map(dataHelper.hourMapIn1224Format, (h) => {
          const { disabled } = disabledHours[+h['24hour']];
          return (
            <Select.Option disabled={disabled} key={h['24hour']} value={h['24hour']}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {h['12hour']}
                {
                  disabled &&
                  <Tooltip
                    placement='top'
                    title={<span>
                      This appointment involves RD or CA. It's outside iHealth Unified Care appointment serving hours.
                    </span>}
                  >
                    <Icon type='info-circle' />
                  </Tooltip>
                }
              </div>
            </Select.Option>
          );
        })
      }
    </Select>
  }
});

const onChangeHour = (val,localToRemote,props)=>{
  const {localToRemoteHour,remoteToLocalHour } = dataHelper;
  let localHour =  !localToRemote ? remoteToLocalHour(val) : val;
  let remoteHour = localToRemote ? localToRemoteHour(val) : val;
  props.form.setFieldsValue({
    hour: localHour+'',
    amOrpm:localHour>=12 ? 'PM' : 'AM',
    remoteHour: remoteHour> 12 ? remoteHour-12 : remoteHour,
  });
}

const isRemoteDisabled = (localHours, remote24Hour) => {
  const localHour = dataHelper.remoteToLocalHour(remote24Hour);
  const h = (localHour + 24)%24;
  return localHours[h].disabled || false;
};

const visitRemoteHour = (visitInfo, disabledHours = {}) => ({
  key: KEYS.remoteHour,
  label: 'Patient Local Time',
      // I18N.get('visit.details.visitTime.required.label'),
  colProps: {
    span: 4,
    style: { marginRight: 8,marginLeft:'calc( 25% + 24px )' }
  },
  initialValue: (visitInfo && dataHelper.localToRemoteHour( moment(_.get(visitInfo, 'appointmentAt')).format('HH')) ) || null,
  rules: (ignoreRequired) => [{
    required: !ignoreRequired,
    message: I18N.get('visit.details.visitTime.required.message')
  }],
  render: (props) => {
    return <Select onChange={(v)=>onChangeHour(v,false,props)} disabled={props.disabled}>
      {
        _.map(dataHelper.hourMapIn1224Format, (h) => {
          const disabled = isRemoteDisabled(disabledHours, h['24hour']);
          return (
              <Select.Option disabled={disabled} key={h['24hour']} value={h['24hour']}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {h['12hour']}
                  {
                    disabled &&
                    <Tooltip
                        placement='top'
                        title={<span>
                      This appointment involves RD or CA. It's outside iHealth Unified Care appointment serving hours.
                    </span>}
                    >
                      <Icon type='info-circle' />
                    </Tooltip>
                  }
                </div>
              </Select.Option>
          );
        })
      }
    </Select>
  }
});
const visitMinute = visitInfo => ({
  key: KEYS.min,
  label: I18N.get('visit.details.visitTimeMin.required.label'),
  colProps: { 
    span: 4,
    style: { marginRight: 8 }
  },
  initialValue: (visitInfo && moment(_.get(visitInfo, 'appointmentAt')).format('mm')) || dataHelper.minMap[0],
  rules: (ignoreRequired) => [{ 
    required: !ignoreRequired, 
    message: I18N.get('visit.details.visitTimeMin.required.message')
  }],
  render: (props) => {
    return <Select disabled={props.disabled}>
      {_.map(dataHelper.minMap, (m) => <Select.Option key={m} value={m}>{m}</Select.Option>)}
    </Select>
  }
});

const scheduleLaterCheckbox = isScheduledLater => ({
  key: KEYS.scheduleLater,
  label: '',
  // colProps: { span: 6 },
  initialValue: !!isScheduledLater,
  valuePropName: 'checked',
  render: (props) => {
    return <Checkbox disabled={props.disabled}>Schedule the visit later</Checkbox>
  }
});

const visitCategory = (visitInfo, disableVirtualVisit) => ({
  key: KEYS.category,
  label: I18N.get('visit.details.visitCategory'),
  colProps: { 
    span: 10,
    style: { marginRight: 8 }
  },
  initialValue: _.get(visitInfo, 'category') || VISIT_CATEGORY_ENUM.VIRTUAL,
  render: (props) => {
    const selectedCategoryValue = props.form.getFieldValue(KEYS.category);
    const mobilePhone = getPhone(_.get(props, 'user'));
    return <Radio.Group className='visit-category-radio' disabled={props.disabled}>
        <Radio value={VISIT_CATEGORY_ENUM.VIRTUAL} disabled={disableVirtualVisit}>
          Virtual
        </Radio>
        <Radio value={VISIT_CATEGORY_ENUM.INCLINIC}>
          In-clinic
        </Radio>
        <Radio value={VISIT_CATEGORY_ENUM.PHONE} className='phone-radio'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Phone</span>
            {
              selectedCategoryValue === VISIT_CATEGORY_ENUM.PHONE &&
              <span style={{ marginTop: 6 }}>
                (Please confirm phone number with patient: 
                  <NumberFormat
                    style={{ marginLeft: 4 }}
                    format='###-###-####'
                    value={mobilePhone}
                    defaultValue='--'
                    displayType='text'
                  />
                )
              </span>
            }
          </div>
        </Radio>
    </Radio.Group>
  }
})
const getAMOrPMByType = (key) =>({
  key: 'localAMOrPM',
  label:'',
  initialValue:'',
  colProps: {
    span: 2,
    style: { marginLeft: 5,marginRight:5 }
  },
  render:(props)=>{
    const localHour = props.form.getFieldValue(key);
    let localAMOrPM =  localHour >= 12 ? 'PM':'AM';
    return <div className={'ampmDiv ampmDiv-local'}>{localAMOrPM}<input style={{ display:'none'}}/></div>
  }
})

const warningText = (isValueBasedOrg) =>({
  key: 'warningText',
  label: '',
  colProps: {
    style: { height: 16 }
  },
  render: () => (
    <div style={{ fontSize: 14, marginTop: -30, color: 'red' }}>
      { !isValueBasedOrg ? '* Please schedule this care team appointment at least 2 days (48+ hours) away to give the team enough time to staff accordingly.' : '' }
    </div>
  )
})

const getFormConfigs = (visitInfo, isScheduledLater, disabledHours, disableVirtualVisit,isValueBasedOrg) => [
  [
    visitDate(visitInfo),
    visitHour(visitInfo, disabledHours),
    visitMinute(visitInfo),
    getAMOrPMByType('hour'),
    scheduleLaterCheckbox(isScheduledLater),
  ],
  [
    warningText(isValueBasedOrg)
  ],
  [
    visitCategory(visitInfo, disableVirtualVisit)
  ]
];

const ScheduleInitialVisitComponent = props => {
  const { form, user, patientReferral, orgSchedules, onSubmitDone, goBack, disabled,nearestInitialVisit,loading,loadingNearestInitialVisit, goNextStep, patientComplexity,isValueBasedOrg } = props;
  const { getFieldValue, getFieldDecorator, setFieldsValue, validateFields, validateFieldsAndScroll } = form;
  const { initialVisit, deviceAccessbility } = patientReferral || {};
  const visitInfo = _.get(initialVisit, 'id') ? {...initialVisit} : _.get(nearestInitialVisit,'id') ? { ...nearestInitialVisit } : null;
  const initialRender = useRef(true);
  const [disableVirtualVisit, setDisableVirtualVisit] = useState(false);
  const isScheduledLater = getFieldValue(KEYS.scheduleLater) || false;
  const showNextButton = isScheduledLater || visitInfo || getFieldValue(KEYS.hour) || disabled;

  const disabledHours = useMemo(() => getDisabledHours(orgSchedules), [orgSchedules]);
  const formConfigs = getFormConfigs(visitInfo, isScheduledLater, disabledHours, disableVirtualVisit,isValueBasedOrg);

  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else if(isScheduledLater) {
      validateFields(_.keys(KEYS), { force: true });
    }
  }, [isScheduledLater]);

  useEffect(() => {
    if(!deviceAccessbility && !visitInfo) {
      // deviceAccessibility = false => loaner phone / clear user
      setDisableVirtualVisit(true);
      setFieldsValue({ [KEYS.category]: VISIT_CATEGORY_ENUM.PHONE });
    }
  }, [deviceAccessbility]);

  if(!visitInfo&&(loading||loadingNearestInitialVisit)) {
    return <IHLoading/>;
  }
  
  const handleSubmit = () => {
    if (disabled) {
      return goNextStep();
    }

    if(isScheduledLater)
      return onSubmitDone();
      
    validateFieldsAndScroll(async (errors, values) => {
      if(errors)
        return;
      
      const { date, hour, min, category } = values;

      const doctorId = _.get(visitInfo, 'member.profile.doctor.id') ||  _.get(user, 'profile.doctor.id');
      const type = visitConstants.DEFAULT_VISIT_TYPE;

      const visitRoles = getVisitRoles(type, category,patientComplexity, visitInfo);
      const combinedStartTime = dataHelper.combineDateHourAndMin(date, parseInt(hour), parseInt(min)) + 0;;
      const combinedEndTime = dataHelper.getAppointmentToTime(combinedStartTime, type);

      let visitId =  _.get(visitInfo, 'id');
      const variables = {
        id: _.get(visitInfo, 'id'),
        memberId: _.get(user, 'id'),
        type,
        category,
        appointmentAt:combinedStartTime,
        appointmentTo:combinedEndTime,
        visitRoles,
        providers: [doctorId]
      };

      let res;
      try {
        if(visitId) {
          res = await visitAPI.rescheduleVisit(variables);
        } else {
          res = await visitAPI.createVisit(variables);
          visitId = _.get(res, 'data.createVisit.id');
        }

        const prVariables = {
          patientId: _.get(user, 'id'),
          initialVisitId: visitId,
          status: {
            scheduleInitVisitEnroll: STATUS_ENUM.COMPLETED
          }
        };
        await upsertPatientReferralMutation(prVariables);
                
        onSubmitDone();
      } catch (error) {
        console.error('error scheduleInitialVisit submit', error);
      }
    });
  };

  return (
    <ReferAndEnrollForm 
      header='Schedule Unified Care initial visit' 
      onSubmit={console.log} 
      buttons={
        showNextButton ?
          <Button 
            type='primary'
            className='new-style'
            onClick={handleSubmit}
          >
            Next
          </Button>
        :
          <Button onClick={goBack}>Go back</Button>
      }
    >
      <div id='refer-enroll-schedule-initial-visit'>
        {
          _.map(formConfigs, (row, rowIdx) => {
            return <Row key={rowIdx} style={{ display: 'flex', alignItems: 'flex-end' }}>
              {
                _.map(row, col => {
                  const { key, label, colProps = {}, initialValue, valuePropName, render } = col;
                  const rules = typeof col.rules === 'function' ? col.rules(isScheduledLater) : [];
                  return <Col key={key} {...colProps}>
                    <Form.Item label={label} colon={false}>
                      {
                        getFieldDecorator(key, {
                          initialValue,
                          rules,
                          valuePropName: valuePropName || 'value'
                        })(render(props))
                      }
                    </Form.Item>
                  </Col>
                })
              }
            </Row>
          })
        }
      </div>
    </ReferAndEnrollForm>
  );
};

const orgScheduleInfo = graphql(orgScheduleQuery, {
  options: () => {
    const { id } = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole.organization;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  },
  props: ({ data }) => {
    const { loading } = data;
    if (loading) {
      return {
        loading,
      };
    }
    return {
      orgSchedules: _.get(data, 'organization.orgSchedules', []) || [],
    };
  }
});
const nearestInitialVisit = graphql(visitListMini,{
  options:(props)=>{
    const memberId = _.get(props,'user.id');
    const appointmentFrom = moment().startOf('day').valueOf();
    const type = ['INIT'];
    return {
      variables:{
        memberId,appointmentFrom,type
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  },
  props:({ data })=>{
    const { loading } = data;
    if (loading) {
      return {
        loadingNearestInitialVisit:loading,
      };
    }
    return {
      nearestInitialVisit: _.first(_.get(data,'visitList.data',[]))
    }
  }
})
export default Form.create({})(
  compose(
    orgScheduleInfo,nearestInitialVisit
  )(ScheduleInitialVisitComponent)
);