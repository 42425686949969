import React from 'react'
import VideoNotificationComponent from '../component/VideoNotificationComponent';


const VideoNotificationContainer = class extends React.Component{
    render(){
        const { props } = this;
        return <VideoNotificationComponent {...props}/>
    }
}


export default (VideoNotificationContainer);
