import gql from 'graphql-tag';
import AssessmentFilled from './schema/AssessmentFilled';
import { Question } from './schema/Assessment';

export default gql`
  query assessmentFilled($id: EID!) {
    assessmentFilled(id: $id) {
      ${AssessmentFilled}
    }
  }
`;

export const patientAssPreview = gql`
  query assessmentFilled($id: EID!) {
    assessmentFilled(id: $id) {
      name
      description
      questions {
        ${Question}      
      }
    }
  }
`