const medicationCompliance = {
  discontinueMedication: {
    title: 'Are you sure to discontinue this medication?',
    subTitle: 'Please select the date when this medication has been or will be discontinued.',
    datePickerLabel: 'Discontinue date *',
    note: {
      past: `Based on the date of your selection, the medication has been discontinued. Upon saving, this medication will be moved to “Previous Medications”.`,
      future: `The medication will be discontinued and moved to “Previous Medications” automatically on the date of your choice.`,
    }
  }
};

export default medicationCompliance;