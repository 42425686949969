import { connect } from 'react-redux';
import QuestionComponent from '../components/QuestionComponent';
import action from '../actions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeField: (fieldPath, value) => dispatch(action.changeField(fieldPath, value)),
  removeQuestion: () => dispatch(action.removeQuestion(ownProps.index)),
  saveQuestion: () => dispatch(action.saveQuestion(ownProps.index)),
  changeType: questionType => dispatch(action.changeType(ownProps.index, questionType)),
});

export default connect(
  ownProps => ownProps, mapDispatchToProps,
)(QuestionComponent);

