import React from 'react';
import { Switch, Select } from 'antd';
import moment from 'moment';
import { map, pick } from 'lodash';
import '../css/index.scss';
import Mixpanel from '../../../mixPanel/mixPanel';
const { Option } = Select;

const DAY_TYPE = 'day';
// const WEEK_TYPE = 'week';
// const MONTH_TYPE = 'month';
// const YEAR_TYPE = 'year';
const INTERVALS = {
  week1: {
    type: DAY_TYPE,
    interval: 1 * 7,
    intervalWord: 'SEVEN',
    name: '7 Days',
    xAxisLabelPerDay: 1
  },
  week2: {
    type: DAY_TYPE,
    interval: 2 * 7,
    intervalWord: 'FOURTEEN',
    name: '14 Days',
    xAxisLabelPerDay: 1,
  },
  month1: {
    type: DAY_TYPE,
    interval: 1 * 30,
    intervalWord: 'THIRTY',
    name: '30 Days',
    xAxisLabelPerDay: 1,
  },
  month2: {
    type: DAY_TYPE,
    interval: 2 * 30,
    intervalWord: 'SIXTY',
    name: '60 Days',
    xAxisLabelPerDay: 2,
  },
  month3: {
    type: DAY_TYPE,
    interval: 3 * 30,
    intervalWord: 'NINETY',
    name: '90 Days',
    xAxisLabelPerDay: 3,
  },
  month6: {
    type: DAY_TYPE,
    interval: 6 * 30,
    intervalWord: 'ONE_HUNDRED_EIGHTY',
    name: '180 Days',
    xAxisLabelPerDay: 5,
  },
  year1: {
    type: DAY_TYPE,
    interval: 12 * 30,
    intervalWord: 'THREE_HUNDRED_SIXTY',
    name: '360 Days',
    xAxisLabelPerDay: 11,
  }
};

class LogBookOrChartView extends React.Component {
  constructor(props) {
    super(props);
    const { logbookView, defaultInterval } = props;
    const intervals = this.getIntervals();
    const intervalKeys = Object.keys(intervals);
    const selectedInterval = defaultInterval ? intervals[defaultInterval] : intervals[intervalKeys[0]];
    const intervalType = selectedInterval.type;
    const interval = selectedInterval.interval;
    this.state = {
      isLogBookView: logbookView ? true : false,
      fromDate: moment().add(-interval, intervalType).startOf('day').add(1, 'day'),
      toDate: moment().endOf('day'),
      intervalType,
      interval,
      chartType: 'TREND_VIEW',
      intervalKey: defaultInterval || intervalKeys[0],
    };
  }
  
  getIntervals = () => {
    const { intervals: intervalKeys } = this.props;
    return intervalKeys ? pick(INTERVALS, intervalKeys) : INTERVALS;
  }

  handleSwitchChange = (checked) => {
    this.setState({ isLogBookView: !checked });
  }

  handleSetTime = (key) => {
    const intervalObj = INTERVALS[key];
    const toDate = moment().endOf('day');
    this.setState({ 
      intervalKey: key,
      fromDate: toDate.clone().add(-intervalObj.interval, intervalObj.type).add(1, 'day').startOf('day'),
      toDate
    }, () => {
      const { type, patientId } = this.props;
      const { isLogBookView, chartType } = this.state;
      const str = intervalObj.intervalWord + '_' + intervalObj.type.toUpperCase();
      const mode = isLogBookView ? 'LOG' : `CHART_${chartType}` ;

      Mixpanel.track('clicked', `${str}_${type}_${mode}`, null, { PATIENT_ID: patientId });
    });
  }

  renderTimeRange = () => {
    const { fromDate, toDate } = this.state;
    const fromDateString = fromDate.format('MM/DD/YYYY');
    const toDateString = toDate.format('MM/DD/YYYY');

    return (
      <div style={{ marginLeft: 5, marginRight: 5 }}>
        <p className='timeRange'>{fromDateString} - {toDateString}</p>
      </div>
    );
  }

  changePrevNext = (type) => {
    const { fromDate, toDate, intervalKey } = this.state;
    const intervalObj = INTERVALS[intervalKey];
    const sign = type === 'prev' ? -1 : 1;

    this.setState({ 
      fromDate: fromDate.clone().add(sign * intervalObj.interval, intervalObj.type).startOf('day'),
      toDate: toDate.clone().add(sign * intervalObj.interval, intervalObj.type).endOf('day'),
    }, () => {
      const { patientId, type: vitalType } = this.props;
      const { chartType, isLogBookView } = this.state;
      const mode = isLogBookView ? 'LOG' : `CHART_${chartType}` ;
      const backOrNext = type === 'prev' ? 'BACK' : 'NEXT';

      Mixpanel.track('clicked',`${backOrNext}_${vitalType}_${mode}`, null, { PATIENT_ID: patientId });
    });
  }

  renderNextPreFooter = () => {
    return (
      <div>
          <ul className='ant-pagination logBookPreNext' >
              <li title="Previous Page" className=" ant-pagination-prev" aria-disabled="false" onClick={() => this.changePrevNext('prev')}>
                  <a className="ant-pagination-item-link logbook-prev-link"></a>
              </li>
              {this.renderTimeRange()}
              <li title="Next Page" className=" ant-pagination-next" aria-disabled="false" onClick={() => this.changePrevNext('next')}>
                  <a className="ant-pagination-item-link logbook-next-link"></a>
              </li>
          </ul>
      </div>
    );
  }

  renderInterval(){
    const { intervalKey } = this.state;
    const intervals = this.getIntervals();

    return (
      <Select defaultValue={'month1'} value={intervalKey} onChange={this.handleSetTime} style={{ width: 110 }}>
        {map(intervals, (value, key) => {
          return (
            <Option value={key} key={key}>{value.name}</Option>
          );
        })}
      </Select>
    );
  }

  onChartTypeChange = (chartType) => {
    this.setState({ chartType })
  }

  render() {
    const { title = '', logbookView, chartView } = this.props;
    const { isLogBookView, fromDate, toDate, intervalKey } = this.state;
    const { xAxisLabelPerDay } = INTERVALS[intervalKey];

    const LogBookView = logbookView ? React.cloneElement(logbookView, { fromDate, toDate }) : null;
    const ChartView = chartView ? React.cloneElement(chartView, { fromDate, toDate, onChartTypeChange: this.onChartTypeChange, xAxisLabelPerDay }) : null;

    return (
      <div style={{ marginTop: 15 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 600 }} className="ih-dd logBookHeaderContainer" >
            {title}
            {this.renderInterval()}
            {this.renderNextPreFooter()}
          </div>
          {LogBookView && ChartView && <div>
            <Switch checked={!isLogBookView} onChange={this.handleSwitchChange} /> Chart view
          </div>}
        </div>
        {isLogBookView ? LogBookView : ChartView}
      </div>
    );
  }
}

export default LogBookOrChartView;