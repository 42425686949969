import React from 'react';

import PrevComplexityComponent from '../components/PrevComplexityComponent';
import ComplexityMessageTemplateBySources from '../components/ComplexityMessageTemplateBySources';

import { PATIENT_COMPLEXITY_SOURCE_ENUM } from '../../../../lib/constants';
import { COMPLEXITY_ROLES } from '../../../utils/constants/role';
import { checkIsInRole } from '../../../../lib/helpers/role-helpers';


const { MANUAL_INPUT, CONDITION } = PATIENT_COMPLEXITY_SOURCE_ENUM;

const ComplexitySummaryContainer = (props) => {
  const isRoleEligible = checkIsInRole(COMPLEXITY_ROLES);
  if(!isRoleEligible)
    return null;

  const { oldComplexity, newComplexity } = props;
  const { level: oldLevel, source: oldSource, updatedBy } = oldComplexity || {};
  const { level: newLevel, source: newSource } = newComplexity || {};
  
  const updatedByName = _.get(updatedBy, 'profile.fullName');

  const renderPreviousSummary = () => {
    const shouldShowPreviousLevel = (
      oldSource &&
      oldSource !== MANUAL_INPUT &&
      (oldSource === CONDITION ? true : newSource !== CONDITION)
    );

    let messageForPreviousSummary = '';
    if(newSource === CONDITION) {
      messageForPreviousSummary = `Based on the condition checkboxes selected previously, the patient's Condition Complexity Level is`;
    } else {
      messageForPreviousSummary = `Based on the condition checkboxes or ICD-10 code selected previously, the patient's Condition Complexity Level is`;
    }

    return shouldShowPreviousLevel && (
      <PrevComplexityComponent patientComplexity={oldComplexity} customMessage={messageForPreviousSummary} />
    );
  }

  const renderUpdatedSummary = () => {
    return (
      <div id='complexityUpdatedSummary'>
        <ComplexityMessageTemplateBySources
          {...props}
          sources={{ oldSource, newSource }}
          oldLevel={oldLevel}
          newLevel={newLevel}
          updatedByName={updatedByName} 
        />
      </div>
    )
  };

  return (
    <div>
      {renderPreviousSummary()}
      {renderUpdatedSummary()}
    </div>
  );
};

export default ComplexitySummaryContainer;