// iotDeviceList has Clear devices mixing with other device types
// Clear devices will have position
export const getClearDeviceList = (
  iotDeviceList,
) => {
  if (iotDeviceList && iotDeviceList.length) {
    return iotDeviceList.filter((iot) => !!iot.position);
  }
  return iotDeviceList;
};
