import gql from "graphql-tag";

export const insuranceListQuery = gql`query insuranceList($memberId:EID!) {
    insuranceList(memberId:$memberId) {
      fileInfo{
        link
        fileKey
      }
      createdAt
    }
  } `;