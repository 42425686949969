import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { IHLoading } from 'ihcomponent';
import { Modal } from 'antd';

import { getClinicCandidates } from '../apis/getOrganization';
import SelectClinicComponent from "../../workStation/component/SelectClinicComponent";

const ReviewPatientClinicSelectContainer = ({
  loadingClinicCandidates,
  clinicCandidates,
  selectedClinicCandidates = [],
  onSubmit,
  setShowClinicModal,
}) => {
  const handleOnSubmit = useCallback(_.debounce((selection) => {
    const orgIds = _.get(selection, 'organizations') || [];
    if (onSubmit) onSubmit(orgIds);
  }, 500), []);

  const handleCancel = () => {
    setShowClinicModal(false);
  };

  return (
    <div className='review-patient-clinic-select-container'>
      <Modal
        visible
        width='fit-content'
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ padding: '48px 63px 54px 57px' }}
        destroyOnClose
      >
        {
          !loadingClinicCandidates
            ? (
              <SelectClinicComponent
                target="CROSS_CLINIC_PATIENTS"
                setShowCoveredClinicsModal={setShowClinicModal}
                clinicCandidates={clinicCandidates}
                setSelectedOrgsMutation={handleOnSubmit}
                selectedClinic={selectedClinicCandidates}
              />
            ) : <IHLoading />
        }
      </Modal>
    </div>
  );
};

ReviewPatientClinicSelectContainer.propTypes = {
  loadingClinicCandidates: PropTypes.bool,
  clinicCandidates: PropTypes.array,
  setShowClinicModal: PropTypes.func,
};
export default compose(
  getClinicCandidates,
)(ReviewPatientClinicSelectContainer);