import { useState, useEffect } from 'react';

const useIntersectionObserver = ({
  rootSelector,
  elementSelector,
  rootMargin = '0px',
  threshold = 1,
}) => {
  const [intersected, setIntersected] = useState(undefined); // undefined | boolean
  const [observer, setObserver] = useState(null);

  const root = document.querySelector(rootSelector);
  const element = document.querySelector(elementSelector);

  useEffect(() => {
    if (root && element && !observer) {
      const newObserver = new IntersectionObserver(_.debounce(([entry]) => { 
        const flag = entry.isIntersecting;
        setIntersected((prevIntersected) => {
          if (prevIntersected !== flag)
            return flag;
        });
      }, 200), {
        root,
        rootMargin,
        threshold,  // intersected or visible by ([threshold] * 100)%
      });

      if (newObserver) {
        newObserver.observe(element);
        setObserver(newObserver);
      }
    }
    return () => {
      setObserver(null);
    };
  }, [root, element]);

  return { intersected };
};

export default useIntersectionObserver;
