import React, { useContext } from 'react';

const RouteContext = React.createContext();

export const RouteContextProvider = RouteContext.Provider;

export default () => {
  const context = useContext(RouteContext);
  return context;
};
