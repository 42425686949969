import React from 'react';
import { Divider } from 'antd';
import { mappingDevicesForNotes } from '../constant/constants';

export default function InventoryNotes(props) {
  const notes = _.get(props, 'notes.notes');

  const restockGiveOutRenderer = (type, typeArray) => {
    const isRestock = type === 'restocks';
    return typeArray.length > 0 &&
    <div>
      <span style={{ color: isRestock ? 'green' : 'red', fontSize: 14 }}>{type.toUpperCase()}:</span>
      {
        _.map(typeArray, ({product: {productType, quantity}}, id) => 

          <span key={id}>{id > 0 ? ', ' : ''} {_.get(mappingDevicesForNotes, productType).toUpperCase()} ({isRestock ? quantity : -1*quantity})</span>)
      }
    </div>
  }

  const noteRenderer = () => _.map(notes, (note, id) => {
    const history = _.get(note, 'history');
    const restocks = _.filter(history, el => _.get(el, 'product.quantity') > 0);
    const giveOuts = _.filter(history, el => _.get(el, 'product.quantity') < 0);
    return note.history && note.history.length > 0 &&
    <div key={id} style={{ marginBottom: 10 }}>
      { restockGiveOutRenderer('restocks', restocks) }
      { restockGiveOutRenderer('give-Outs', giveOuts) }
      <small>By: {note.createdBy}</small>
      <p style={{ marginTop: 8 }}>Note: {note.note || 'N/A'}</p>
      <Divider />
    </div>
  })

  return (
    <div>
      { noteRenderer() }
    </div>
  )
}
