import { formatFieldLabels, renderCheckBoxGroup, renderRadioButton,renderCheckBoxGroupWithNoneSel } from "../helpers";
import React from 'react';
let checked = false;
const  options = [
        { value: 'Diabetes', label: 'Diabetes',checked:checked },
        { value: 'Hypertension', label: 'Hypertension',checked:checked },
        { value: 'Obesity', label: 'Obesity',checked:checked },
        { value: 'Cardiovascular disease', label: 'Cardiovascular disease',checked:checked },
        { value: 'Kidney disease', label: 'Kidney disease',checked:checked },
        { value: 'Liver disease', label: 'Liver disease',checked:checked },
        { value: 'Stroke', label: 'Stroke',checked:checked },
        { value: 'Cancer', label: 'Cancer',checked:checked },
        { value: 'None of the above', label: 'None of the above' }
    ]


const handleCheckBoxChange = (value,key,localThis)=>{
    localThis.setState({
        [key]:!localThis.state[key]
    })
}
let familyHistoryKeys =["spouseMedicalHistory", "fatherMedicalHistory", "motherMedicalHistory", "siblingMedicalHistory", "paternalFatherMedicalHistory", "paternalMotherMedicalHistory", "paternalUnclesOrAuntsMedicalHistory", "maternalFatherMedicalHistory", "maternalMotherMedicalHistory", "maternalUnclesOrAuntsMedicalHistory"]

const familyHistoryConfig=(()=>{
   const headerDiv = [{
                       span: 24,
                       placeHolder: <div><h4>Family History:</h4></div>
                     }];

    let familyHistory = [headerDiv];
    familyHistory = familyHistory.concat(_.map(familyHistoryKeys,(m)=>([{
        key:m,
        label:formatFieldLabels(m,m),
        path:`profile.${m}`,
        span:24,
        inputContent:(props)=>renderCheckBoxGroup(options,props),
        formatValue:(data)=>Array.isArray(data) ? data:[],
        onChange:(value,key,localThis)=>handleCheckBoxChange(value,key,localThis)
        }])
    ));

    return familyHistory;
})()



export {
    familyHistoryKeys,
    familyHistoryConfig
}
