import hsConstants from '../constants';

// @return list of category options
export const getSelectedCategories = (hiatusStatus) => {
  if(_.isEmpty(hiatusStatus))
    return [];

  return _.filter(hsConstants.categoryOptions, opt => {
    return !!_.get(hiatusStatus, `${opt.value}`);
  });
};

export const getSelectedCategoryLabels = (hiatusStatus) => {
  return _.map(getSelectedCategories(hiatusStatus), 'label');
};

export const getSelectedCategoryValues = (hiatusStatus) => {
  return _.map(getSelectedCategories(hiatusStatus), 'value');
};

export const checkIfHiatusActive = (hiatusStatus) => {
  const { startDate } = hiatusStatus || {};
  const now = new Date().setHours(0, 0, 0, 0);
  return startDate && (new Date(startDate).getTime() <= now);
};