import React from 'react';
import { Popover, Icon } from 'antd';
import tableColumns from '../constants/tableColumns';
import VisitNameFilter from '../components/VisitNameFilter';
import I18N from '../../I18N';

// combinedFilters: standalone filters 
// @return flag for additional filter handlers with logical OR operator
const _handleCombinedFilters = (record, combinedFilterFns) => {
  return !!_.filter(combinedFilterFns, handleFilter => !!handleFilter(record)).length;
};

// standaloneFilter: iff when other filters are not set
const _checkIfStandaloneFilter = (selfKey, allSelectedFilters) => {
  for(let key in allSelectedFilters) {
    const filter = allSelectedFilters[key] || [];
    if(key !== selfKey && filter.length) {
      return false;
    }
  }
  return true;
}

const _handleVisitCommonFilter = (record, filteredValue = [], valuePath) => {
  if(!filteredValue.length) return true;
  return _.includes(filteredValue, _.get(record, valuePath));
};

const _handleVisitVitalsFilter = (record, filteredValue = []) => {
  if(!filteredValue.length) return true;
  const vitalTypes = record.vitalTypes || [];
  return !!_.filter(filteredValue, value => {
    return !value ||
            value === 'NONE' && !vitalTypes.length || // selected No Vital
            _.includes(vitalTypes, value);
  }).length;
};

const _handleVisitParticipantFilter = (record, filteredValue = []) => {
  const { visitRoles, providers, assignees } = record;
  try {
    const v = JSON.parse(_.first(filteredValue));
    const { MD = [], RD = [], CA = [], HC = [] } = v || {};

    const hasFilters = !!MD.length || !!RD.length || !!CA.length || !!HC.length;

    return !hasFilters ||
           (_.includes(visitRoles, 'MD') &&
            !!_.filter(providers, p => _.includes(MD, p.id)).length) ||
           _.includes(RD, _.get(assignees, 'assignedToRD.providerId')) ||
           _.includes(CA, _.get(assignees, 'assignedToCA.providerId')) ||
           _.includes(HC, _.get(assignees, 'assignedToRD.providerId'));
  } catch (err) {
    console.error(err);
    return true;
  }
};

const tokenizeIfMatch = (original, keyword) => _.split(original, new RegExp(`(${keyword})`, 'gi'));

// export const visitNameFilter = (filteredValue = [], onFilter, hasOtherFilters) => {
export const visitNameFilter = (allSelectedFilters) => {
  const FILTER_KEY = 'filterByName';
  const valuePath = 'member.profile.fullName';
  const filteredValue = _.get(allSelectedFilters, FILTER_KEY) || [];
  const nameFilteredValue = _.first(filteredValue) || '';
  const isStandalone = _checkIfStandaloneFilter(FILTER_KEY, allSelectedFilters);
  return {
    title: <div>
      <span style={{ marginRight: 5 }}>
        { I18N.get('visit.columns.patient') }
      </span>
      <Popover
        trigger='click'
        placement='bottomRight'
        overlayClassName='visit-name-filter-popup'
        content={<VisitNameFilter nameFilterValue={nameFilteredValue} />}
        arrowPointAtCenter
        destroyTooltipOnHide
      >
        <Icon
          className={`name-filter-icon ${nameFilteredValue ? 'has-filtered-value' : ''}`}
          type='search'
        />
      </Popover>
    </div>,
    // overwrite render to highlight search text
    render: (__, record) => tableColumns.renderPatient(record, ({
      highlightName: (fullName) => {
        const wordsToHighLight = nameFilteredValue;
        if(!wordsToHighLight.length) 
          return fullName;

        const tokens = (fullName && wordsToHighLight) ? 
                      tokenizeIfMatch(fullName, wordsToHighLight) : 
                      fullName;
        return <span>
          {
            _.map(tokens, (token, id) =>
            // token and wordsToHighLight must not be empty
            // .match() will return an array if match; null, otherwise
              token && 
              token.match(new RegExp(`(${wordsToHighLight})`, 'i')) 
              ? <span key={id} className='name-filter-highlighted'>{token}</span>
              : token
            )
          }
        </span>;
      }
    })),
    ...isStandalone ? {
      filteredValue,
      onFilter: (value, record) => tokenizeIfMatch(_.get(record, valuePath), value).length > 1
    } : {}
    
  };
};

// @return
// filteredData: data after all filterings
// advancedFilteredResultCount: data length after advanced filterings
const handleFilterData = (allSelectedFilters, rawDatasource) => {
  const { filterByName, ...advancedFilteredValues } = allSelectedFilters || {};
  const { 
    // advanced filters
    filterByCategory,
    filterByType,
    filterByPCP,
    filterByVitals,
    filterByMD,
    filterByRD,
    filterByCA,
    filterByHC
  } = advancedFilteredValues || {};

  const filterByParticipant = [
    JSON.stringify({ // filter value should be string
      MD: filterByMD,
      RD: filterByRD,
      CA: filterByCA,
      HC: filterByHC
    })
  ];

  // definitions for standalone filter fn
  const nameFilteredValue = _.first(filterByName) || '';
  const _handleFilterName = record => {
    const valuePath = 'member.profile.fullName';
    return tokenizeIfMatch(_.get(record, valuePath), nameFilteredValue).length > 1;
  };

  // array of standalone filter fns
  const combinedFilterFns = [];
  if(nameFilteredValue) 
    combinedFilterFns.push(_handleFilterName);

  // --
  let advancedFilterResultCount = 0;
  const filteredData = _.filter(rawDatasource, data => {
    // filtered by advanced
    let flag = (
      _handleVisitCommonFilter(data, filterByCategory, 'category') &&
      _handleVisitCommonFilter(data, filterByType, 'type') &&
      _handleVisitCommonFilter(data, filterByPCP, 'member.profile.doctor.id') &&
      _handleVisitVitalsFilter(data, filterByVitals) &&
      _handleVisitParticipantFilter(data, filterByParticipant)
    );

    if(flag) 
      advancedFilterResultCount += 1;

    const flagHandler = () => flag; // simple fn to return flag value
    
    flag = _handleCombinedFilters(data, [flagHandler, ...combinedFilterFns]);
    return flag;
  });

  return {
    filteredData,
    advancedFilterResultCount
  };  
};

export default handleFilterData;