import FoodLogContainer from './FoodLogContainer';
import React from "react";
import moment from "moment/moment";

const FoodLogContainerWrapper = class extends React.Component {
    constructor(props){
        super(props);
        this.state={
            value:moment(new Date()).month(),
            year:moment(new Date()).year(),
        }
    }

    changeNext = ()=>{
        let { value } = this.state;
        if(value+1<=11){
            value ++;
        }
        this.setState({
            value
        })
    }

    changePre = ()=>{
        let { value } = this.state;
        if(value-1>=0){
            value = value-1;
        }
        this.setState({
            value
        })
    }

    changeMonth = (value)=>{
        this.setState({
            value
        })
    }

    changeYear = (year)=>{
        this.setState({
            year
        })
    }

    render(){
        const { state } = this;
        const { props } = this;
        return <FoodLogContainer changeNext={this.changeNext}
                                 changePre={this.changePre}
                                 changeMonth={this.changeMonth}
                                 changeYear={this.changeYear}
                                 {...state}
                                 {...props}

                />
    }
}

export default FoodLogContainerWrapper;
