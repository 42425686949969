import {
    React,
    BaseComponent,
    RB,
    _
} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{
    render(){

        const items = []

        _.each(this.props.link, (item)=>{
            const tmp = this.renderEachItem(item)
            items.push(tmp[1])

            return tmp[0]
        })

        return (
            <RB.Breadcrumb className="IH-Breadcrumb">
                {items}
            </RB.Breadcrumb>
        )
    }
    renderEachItem(item){
        const p = {}
        if(item.active){
            p.active = true
        }

        const rs = (
            <RB.Breadcrumb.Item {...p} href={item.url}>{item.name}</RB.Breadcrumb.Item>
        )

        return [!item.active, rs]
    }
}

Component.propTypes = {
    //name, url
    link : PropTypes.array.isRequired
}

export default Component
