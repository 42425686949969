import React from 'react';
import { goPath, RequestCache } from 'libModule/utils'
import { notification } from 'antd';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const goToAssignList = ()=>{
    Mixpanel.track('clicked','task_notify_to_task_list');
    goPath('/taskAssignments')
    notification.close('newTask');
    notification.close('dueToday');
    notification.close('overdue');
};

const link =()=>{
    const isAtAssignmentPage = window.location.pathname=='/taskAssignments'
    return isAtAssignmentPage ? 'Task List' : <a href="javascript:void(0)" onClick={ goToAssignList }>Task List </a>;
}
export default {
    newTask:{
        render:(total)=> <div>You’ve been assigned {total} new task(s), please go to { link() } to review. </div>,
        alertType:'info'
    },
    dueToday:{
        render:(total)=>  <div>You have {total } task(s) due today, please go to { link() } to review.</div>,
        alertType:'warning'
    },
    overdue:{
        render:(total)=> <div>You have {total } task(s) overdue, please go to { link() } to review.</div>,
        alertType:'error'
    }
}
