import {
    React, IHTab, IHPanel, IHButton, BaseComponent, moment, _, message
} from 'ihcomponent';
import { Table,Checkbox,Button,Radio } from 'antd';
import Client from 'libModule/gqlClient';
import editEnrolledProgramGQL from 'modulesAll/graphql/mutation/editEnrolledProgram';
import { connect } from 'react-redux'
import { GQLHelper } from 'libModule/utils';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import I18N from 'modulesAll/I18N';
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';

const RadioGroup = Radio.Group;
const excludedVitals = ['EXERCISE'];
const ManualInputComponent = class extends BaseComponent {
    constructor(){
        super();
        this.state = {
            manualInputEnabledTaskTypes:[],
            deviceInputEnabledTaskTypes:[],
            disabledManualTableButton:true
        }
    }

    componentDidMount(){
        const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = _.get(this.props,'currentProgram',{});

        this.setState({
            manualInputEnabledTaskTypes: manualInputEnabledTaskTypes||[],
            deviceInputEnabledTaskTypes: deviceInputEnabledTaskTypes||[]
        })
    }

    updateDeviceInputEnabledTaskTypes(type,checked){
        let { deviceInputEnabledTaskTypes } = this.state;
        deviceInputEnabledTaskTypes = deviceInputEnabledTaskTypes.slice();
        if(deviceInputEnabledTaskTypes.indexOf(type)!=-1&&!checked){
            deviceInputEnabledTaskTypes = _.reject(deviceInputEnabledTaskTypes,(i)=>i==type);
        }
        else if(deviceInputEnabledTaskTypes.indexOf(type)==-1&&checked){
            deviceInputEnabledTaskTypes.push(type);
        }
        this.setState({
            deviceInputEnabledTaskTypes
        });
    }

    updateManualInputEnabledTaskTypes(type,checked){
        let { manualInputEnabledTaskTypes } = this.state;
        manualInputEnabledTaskTypes = manualInputEnabledTaskTypes.slice();
        if(manualInputEnabledTaskTypes.indexOf(type)!=-1&&!checked){
            manualInputEnabledTaskTypes = _.reject(manualInputEnabledTaskTypes,(i)=>i==type);
        }
        else if(manualInputEnabledTaskTypes.indexOf(type)==-1&&checked){
            manualInputEnabledTaskTypes.push(type);
        }
        this.setState({
            manualInputEnabledTaskTypes
        });
    }

    resetManualInputTable(){
        const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = _.get(this.props,'currentProgram',{});
        this.setState({
            manualInputEnabledTaskTypes,
            deviceInputEnabledTaskTypes
        })
    }

    enableManualInputButton(){
        this.setState({
            disabledManualTableButton:false
        })
    }

    getMutateVariables(){
        const { currentProgram }  = this.props;
        const { id } = currentProgram;
        const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = this.state;
        return {
            id,
            manualInputEnabledTaskTypes,
            deviceInputEnabledTaskTypes
        }

    }

    componentWillReceiveProps(nextProps){
        const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = nextProps.currentProgram;
        if(!_.isEqual(manualInputEnabledTaskTypes,this.state.manualInputEnabledTaskTypes)||
           !_.isEqual(deviceInputEnabledTaskTypes,this.state.deviceInputEnabledTaskTypes)
          ){
            this.setState({
                manualInputEnabledTaskTypes,
                deviceInputEnabledTaskTypes
            })
        }
    }

    async editCurrentEnrolldProgram(){
        Client.mutate({
            mutation: editEnrolledProgramGQL,
            variables: this.getMutateVariables(),
            fetchPolicy: 'no-cache',
            refetchQueries:[{
                query:enrolledProgram,
                variables:{
                    id: this.props.currentProgram.id
                }
            }]
        })
        .then( res => {
                message.success(I18N.get('Program.newCarePlan.save_success'));
                this.setState({
                    disabledManualTableButton:true
                })
                if (this.props.setInputFieldDisabled) {
                    this.props.setInputFieldDisabled('manualInput')
                }
        })
        .catch( e => this.props.openErrorModal(GQLHelper.formatError(e)))
    }

    showChecked(src,type){
        return Array.isArray(src)&&src.indexOf(type)!=-1;
    }

    onRadioGroupChange(value){
        let { manualInputEnabledTaskTypes, deviceInputEnabledTaskTypes } = this.state;
        const op = value.split(':')[1];
        const type = value.split(':')[0];
        if(op=='device'){
            let tmpSet = new Set(deviceInputEnabledTaskTypes);
            tmpSet.add(type);
            manualInputEnabledTaskTypes = _.remove(manualInputEnabledTaskTypes,(t)=>t!=type);
            this.setState({
                manualInputEnabledTaskTypes:manualInputEnabledTaskTypes,
                deviceInputEnabledTaskTypes:Array.from(tmpSet)
            })
        }

        if(op=='manual'){
            let tmpSet = new Set(manualInputEnabledTaskTypes);
            tmpSet.add(type);
            deviceInputEnabledTaskTypes = _.remove(deviceInputEnabledTaskTypes,(t)=>t!=type);
            this.setState({
                manualInputEnabledTaskTypes:Array.from(tmpSet),
                deviceInputEnabledTaskTypes:deviceInputEnabledTaskTypes
            })
        }
    }

    render(){
        const { currentProgram, setInputFieldDisabled, disabled, isBtnDisabled } = this.props;
        // const { disabled,isBtnDisabled }  = _.get(this,'props.state.manualInput');
        const { manualInputEnabledTaskTypes,deviceInputEnabledTaskTypes } = this.state;
        const manualSet = new Set(manualInputEnabledTaskTypes);
        const deviceSet = new Set(deviceInputEnabledTaskTypes);
        const taskTypes = _.get(currentProgram,'tasks').filter(({type})=>  !excludedVitals.includes(type) )

        const columns = [
            {
                title:'',
                dataIndex:'type',
                key:'type',
                width:'35%',
                render:(type)=>I18N.get(`vitals.${type}`)
            },
            {
                title:'App Sync/Manual Input',
                key:'',
                render:(_,row)=> {
                    return <RadioGroup name="radiogroup" disabled = { disabled }
                                onChange={(e)=>this.onRadioGroupChange(e.target.value)}>
                        <Radio value={`${row.type}:device`} checked={ deviceSet.has(row.type) }>App Sync</Radio>
                        <Radio value={`${row.type}:manual`} checked={ manualSet.has(row.type) }>Manual Input</Radio>
                    </RadioGroup>
                }
            },
            // {
            //     title:I18N.get('manual'),
            //     key:'manual',
            //     render:(_,row)=><Checkbox disabled = { disabledManualTableButton }
            //                            name={row.type}
            //                            id={row.type}
            //                            checked={this.showChecked(manualInputEnabledTaskTypes,row.type)}
            //                            onChange={(value)=>this.updateManualInputEnabledTaskTypes(row.type,value.target.checked)}/>
            // }
        ]

        return (
            <div className='IH-table'>
                <div style={{width:'400px',display:'flex'}}>
                    <p className="care-plan-fields"><b>Measurement Input Options</b></p>
                    <div style={{ marginLeft:'auto'}}>
                         <IHButton onClick={()=>disabled ? setInputFieldDisabled('manualInput') : this.editCurrentEnrolldProgram() }
                                    className={ disabled ? 'care-plan-edit-btn' : 'care-plan-done-btn' }
                                    disabled={isBtnDisabled}
                                    size='exsmall'
                         >  { disabled ? 'Edit' : 'Done' } </IHButton>
                    </div>
                </div>
                <Table columns={columns} dataSource={taskTypes}
                       style={{width:400}} pagination={false}
                       className='IH-Table' rowKey={row=>row.type}/>
                <div style={{width:'400px', marginTop: '10px'}}>
                    {I18N.get('deviceInput.message')}
                </div>
            </div>
        )
    }
}

const mapDispatch = (dispatch) => {
    return {
        openErrorModal: err => dispatch(openErrorModal(err))
    }
}

export default  connect(null,mapDispatch)(ManualInputComponent);
