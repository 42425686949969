import {
  React, _, IHLoading, IHSelect
} from 'ihcomponent'
import PropTypes from 'prop-types';

import UserClass from 'modulesAll/graphql/class/User'
import DevicesListContainer from '../containers/DevicesListContainer'

const Component = class extends React.PureComponent {
  static displayName = 'vendor/patient/components/PatientInfoComponent'

  static propTypes = {
    loading: PropTypes.bool,
    searchStatus: PropTypes.string,
    patientInfo: PropTypes.object,
    devicesStatus: PropTypes.array,
    setReplaceDevicesStep: PropTypes.func,
    replaceOrReturnDevicesStep: PropTypes.number,
    changeDeviceRemarkTableInfo: PropTypes.func,
    processReplaceOrReturnDevicesAsync: PropTypes.func,
    changeDeviceStatusTableInfo: PropTypes.func,
    enrolledProgramId: PropTypes.string,
    enrolledProgramList: PropTypes.array,
    returnDevicesStep: PropTypes.number,
    setReturnDevicesStep: PropTypes.func,
    onChangeEnrolledProgram: PropTypes.func,
    resetDevicesStatusTableInfo: PropTypes.func,
    resetForm: PropTypes.func,
    initDevicesStatusTableInfo: PropTypes.func,
    getPreviousKeyInDevicesInfo: PropTypes.func,
    updateDevicesInEnrolledProgramInList: PropTypes.func //use by DevicesReplacementHistory
  }

  render() {
    if (this.props.loading) {
      return <IHLoading />
    } else if ( (this.props.searchStatus === 'NO_RESULT')  && (!this.props.patientInfo || !this.props.patientInfo.id) ) {
      return <div style={{padding:'0 12px'}}>No user found</div>
    } else if (this.props.searchStatus === 'INIT' ) {
      return <div></div>
    }

    this.userObject = new UserClass(this.props.patientInfo)

    return (
      <div className="v-patient-profileDetail">

        {this.renderBox1()}
        {this.renderBox2()}

        <div className="gapBetweenSec">
          {this.renderDropDownEnrolledProgram() /** because graphQL result of enrolledProgramByPatientNRIC API retrieve with enrolledprogram and user is a child of it. Therefore no need to check to display/hide this dropdown component, because enrolledProgram array always has value **/ }
        </div>

        <div className="gapBetweenSec">
          <DevicesListContainer
            devicesStatus={this.props.devicesStatus}
            setReplaceDevicesStep={this.props.setReplaceDevicesStep}
            replaceOrReturnDevicesStep={this.props.replaceOrReturnDevicesStep}
            setReturnDevicesStep={this.props.setReturnDevicesStep}
            changeDeviceStatusTableInfo={this.props.changeDeviceStatusTableInfo}
            changeDeviceRemarkTableInfo={this.props.changeDeviceRemarkTableInfo}
            processReplaceOrReturnDevicesAsync={this.props.processReplaceOrReturnDevicesAsync}
            returnDevicesStep={this.props.returnDevicesStep}
            enrolledProgramId={this.props.enrolledProgramId}
            enrolledProgramList={this.props.enrolledProgramList}
            patientInfo={ this.props.patientInfo }
            updateDevicesInEnrolledProgramInList={this.props.updateDevicesInEnrolledProgramInList}
          />
        </div>

      </div>
    )
  }

  renderBox1(){
    return (
      <div className="v-box-1">
        <img alt="patient" src={this.userObject.format('avator')} />
        <div>
          <p className="c-n1">
            {this.userObject._get('profile.fullName')}
            <span className="s1">({this.userObject.format('nric')})</span>
            <span className="s2 active">Active</span>
          </p>

          <p className="c-n2">
            {
              _.map(this.userObject.format('tag'), (tag, i) => {
                return <span key={i} className="c-tag">{tag}</span>
              })
            }
          </p>
        </div>
      </div>
    )
  }

  renderBox2(){
    return (
      <div className="v-box-2">
        <p className="c-tlt">Contact Info</p>
        <div className="c-flex c-m">
          <div className="ea">
            <b>Address</b>
            <span>{this.userObject.format('address')}</span>
          </div>
          <div className="ea">
            <b>Email</b>
            <span>{this.userObject.format('email')}
            </span>
          </div>
          <div className="ea">
            <b>Contact</b>
            <span>Home {this.userObject.format('homePhone')}
            </span>
            <span>Mobile {this.userObject.format('mobilePhone')}
            </span>
          </div>
        </div>
      </div>
    )
  }

  dropDownEnrolledProgramProp() {
    const preparedDropDownEnrolledProgramProp = {
      option: (this.props.enrolledProgramList && this.props.enrolledProgramList.length) ? this.props.enrolledProgramList.map(ep => ({ name: ep.rawName, value: ep.value}) ) : [],
      rules : [
          {required : true}
      ],
      onChange : (selectedValue)=>{

        const ep = _.find(this.props.enrolledProgramList, {value: selectedValue});
        const previousKeyInDevicesInfo = this.props.getPreviousKeyInDevicesInfo(ep);
        if (previousKeyInDevicesInfo && previousKeyInDevicesInfo.length) {
          this.props.initDevicesStatusTableInfo(previousKeyInDevicesInfo);
          this.props.setReplaceDevicesStep(2);
        } else {
          this.props.resetDevicesStatusTableInfo(); //reset devicesStatus in redux
          this.props.setReplaceDevicesStep(1); //go back step 1
        }

        this.props.onChangeEnrolledProgram(selectedValue);
        this.props.setReturnDevicesStep(1); //back to step 1

        //reset form
        this.props.resetForm();
      }
    }

    if (this.props.enrolledProgramList && this.props.enrolledProgramList.length) {
      preparedDropDownEnrolledProgramProp.value = this.props.enrolledProgramId? this.props.enrolledProgramId : this.props.enrolledProgramList[0].value;
    }

    return preparedDropDownEnrolledProgramProp;
  }

  parseEnrolledProgrammeInfo() {
    const enrolledProgramSelected= this.props.enrolledProgramId && this.props.enrolledProgramId.length ?
      this.props.enrolledProgramList.find((f) => { return f.value === this.props.enrolledProgramId  } )
      : this.props.enrolledProgramList[0]

    return (enrolledProgramSelected.startDate ? `Start Date: ${enrolledProgramSelected.startDate} ` : '')
    .concat(enrolledProgramSelected.duration ? `Duration: ${enrolledProgramSelected.duration} ` : '')
  }

  renderDropDownEnrolledProgram() {
    return (
     <div style={{width:'400px'}}>
        <IHSelect {...this.dropDownEnrolledProgramProp()} />
        {this.parseEnrolledProgrammeInfo()}
      </div>
    )
  }
}

export default Component
