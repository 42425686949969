// import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import { React, IHLoading, IHDatePicker } from 'ihcomponent'
import { createContainer, RequestCache, getRouterParam, goPath, GQLHelper, SuperContainer, RouterHelper} from '../../../../lib/utils'
import actions from '../actions/main'
import {setExtendStep} from '../../enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import ProgramTable from './ProgramTable'
import {modalAction} from 'modulesAll/common/actions'
import moment from 'moment';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import VitalList from 'patientModule/enrolledProgramInfo/Careplan/containers/VitalListContainer'
import { Avatar, Button } from 'antd';

import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { generateScheduleForDB } from 'modulesAll/utils/helpers/task'
import adminProgramList from 'graphqlModule/adminProgramList'
import enrollMemberGQL from 'modulesAll/graphql/enrollMember'
import setDateForEnrolledProgram from 'modulesAll/graphql/mutation/setDateForEnrolledProgram'
import manageTeamForEnrolledProgramGQL from 'modulesAll/graphql/manageTeamForEnrolledProgram'
import UserClass from 'graphqlModule/class/User'
import { API } from '../../../visit/query/index';
import { genHC } from '../../main/components/HealthConditionRenderer'
import EnrollConsentFormComponent from '../../main/components/consentForm/EnrollConsentFormComponent';
import ConsentFormPreview from '../../main/components/consentForm/ConsentFormPreview'
import EnrollCompleteComponent from '../../EnrollPatient/component/EnrollCompleteComponent'
import { CONSENT_TYPE } from '../../main/constants'
import ConsentFormLanguageSelector from '../../main/components/consentForm/ConsentFormLanguageSelector'
import { getPatientReferralForRDCAEnroll } from '../../../referAndEnroll/query';
import consentFormHelpers from '../../../referAndEnroll/helper/consentFormHelpers';
// import ReferStatusInPatientHeaderComponent from '../../../referAndEnroll/component/ReferStatusInPatientHeaderComponent';

const Component = class extends SuperContainer{
    static displayName = 'patient/programTab/containers/main'
    constructor(p) {
      super(p);

      const appLanguage = _.get(p, 'user.profile.appLanguage.code');
      const spokenLanguage = _.get(p, 'user.profile.language.code');
      const language = appLanguage || spokenLanguage || 'EL';

      this.state = {
        initLoading: false,
        visible: false,
        confirmLoading: false,
        selectedTeam: null,
        language,
        languageGroupId: null,
        consentProviderName: '',
        selectedTeamProviderIds: [],
        submitErrors: {},
      }
      this.stepRefs = { };
      this.changeStartDate = this.changeStartDate.bind(this);
      this.onClickNext = _.debounce(this._onClickNext, 1000);
      // this.onClickBack = this.onClickBack.bind(this);
      this.showModal = this.showModal.bind(this);
      // this.handleGoBack = this.handleGoBack.bind(this);
      // this.handleCancel = this.handleCancel.bind(this);
      this.onSetDefault = this.onSetDefault.bind(this);
      this.getSelectedConsentType = () => null; // default to null if consent signed but status is false
    }

    showModal = () => {
      const curProgram = this.props.program.selectedProgram ? this.props.program.selectedProgram.name : '';
      Mixpanel.track('Showed', 'enroll success popup', '', {PROGRAM_NAME: curProgram});
      this.setState({
        visible: true,
      });
    };

    // backToEnrolledProgram = () => {
    //   const curUrl = browserHistory.getCurrentLocation().pathname;
    //   const urlArr = _.split(curUrl, '/');
    //   const patientId = urlArr[urlArr.length - 3];
    //   goPath(`/patients/` + patientId + `/enrolledprogram/default`);
    //   RequestCache.get('refetch-enrolledProgramList').call();
    // }

    // backToRemoteEnrollment = () => {
    //   const curUrl = browserHistory.getCurrentLocation().pathname;
    //   const urlArr = _.split(curUrl, '/');
    //   const patientId = urlArr[urlArr.length - 3];
    //   sessionStorage.setItem('backToRemoteEnrollment', JSON.stringify(patientId));
    //   goPath(`/remoteEnrollment`);
    // }

    // handleGoBack = () => {
    //   Mixpanel.track('clicked', 'go back and finish the task button', '', {});
    //   const curUrl = browserHistory.getCurrentLocation().pathname;
    //   const urlArr = _.split(curUrl, '/');
    //   const url = urlArr[urlArr.length - 1];
    //   const visitId = url.substring(0, url.length - 1);
    //   this.setState({
    //     confirmLoading: true
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       visible: false,
    //       confirmLoading: false,
    //     });
    //   goPath(`/dashboard` + '?' + visitId);
    //   }, 2000);
    // };

    handleGoToWorkList = () => {
      Mixpanel.track('clicked', 'go back and finish the task button', '', {});
      this.props.handleCloseModal(null, { reopenWorkList: true });
      // const backToCurVisit = JSON.parse(sessionStorage.getItem('backToCurVisit'));
      // goPath(`/dashboard` + '?' + _.get(backToCurVisit, 'id'));
    }

    // handleCancel = () => {
    //   this.setState({
    //     visible: false,
    //     confirmLoading: false
    //   });
    //   const curUrl = browserHistory.getCurrentLocation().pathname;
    //   const urlArr = _.split(curUrl, '/');
    //   const patientId = urlArr[urlArr.length - 3];
    //   goPath(`/patients/` + patientId + `/enrolledprogram/default`);
    //   RequestCache.get('refetch-enrolledProgramList').call();
    // };

    onSetDefault(program) {
      const selectedProgram = program
      this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      this.props.dispatch(actions.setHaveCareTeam(true))
      //this.props.dispatch(actions.initVitalList(selectedProgram[0]))
      this.changeStartDate( (this.props.currentProgram && this.props.currentProgram.startDate) ? moment(this.props.currentProgram.startDate): moment());
      this.props.setExtendStep(2)
    }

    componentWillMount() {
      const startDate = _.get(this.props, 'program.startDate') || _.get(this.props, 'currentProgram.startDate')
      if (startDate) {
        this.changeStartDate(moment(startDate))
      }
    }

    componentWillUnmount(){
        this.props.dispatch(actions.resetState());
    }

    componentWillReceiveProps(np) {
      if(np.templateProgramId && this.props.templateProgramId !== np.templateProgramId){
        const programme = this.props.programList || []
        const id = np.templateProgramId
        const selectedProgram = programme.filter(prog => { return prog.id === id})
        this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      }
      if(!this.state.initLoading && np.programList && np.programList.length > 0) {
        this.setState({
          initLoading: true
        }, () => {
          this.onSetDefault(np.programList);
        })
      }
    }

    resetSubmitError = key => {
      if(!!this.state.submitErrors[key]) {
        this.setState(prev => ({ submitErrors: {...prev.submitErrors, [key]: false}}));
      }
    }

    selectConsentFormLanguage = (value, languageGroupId = null) => this.setState({ language: value, languageGroupId });

    renderConsentForm = ()=>{
      const { user = {}, loadingPatientReferral, loadingConsentInfo, patientReferral, consentInfo = {}, refetchConsentInfo, templateContainerId } = this.props;
      const { selectedTeam, language, languageGroupId, consentProviderName, selectedTeamProviderIds, submitErrors }  = this.state;

      const hasError = submitErrors.missingConsentType;

      if(loadingPatientReferral || loadingConsentInfo) {
        return <div style={{ position: 'relative' }}>
          <IHLoading />
        </div>;
      }
      
      return <div 
        ref={el => this.stepRefs.consentForm = el} 
        className='insideEnrollForm' 
        style={{ position: 'relative' }}
      >
        {
          (!selectedTeam && !consentInfo.consent) &&
          <div className='enroll-consent-form-disabled-overlay'></div>
        }
        <p className='v-tlt enroll-step-label'>
          Step 3: Sign Consent Form
          {
            hasError &&
            <span className='enroll-step-label-error'>Please choose an option</span>
          }
        </p>
        {
          (_.isEmpty(consentInfo) ||
          (!consentInfo.consent && consentInfo.consentType === CONSENT_TYPE.APP)) ?
            <EnrollConsentFormComponent 
              onDidMount={consentFormInstance => {
                this.getSelectedConsentType = () => consentFormInstance.state.consentType;
                this.isConsentFormButtonDisabled = consentFormInstance.isButtonDisabled;
                this.checkIfHasConsentType = consentFormInstance.checkIfHasConsentType;
                this.submitConsentForm = consentFormInstance.onSubmit;
              }}
              renderHeader={isPaperConsent => (
                <div className='enroll-consent-form-header'>
                  <div className='enroll-consent-form-header-title'>{I18N.get('consentForm.label.optionHeader')}</div>
                  <ConsentFormLanguageSelector 
                    prefixCls='enroll'
                    language={language} 
                    setLanguage={this.selectConsentFormLanguage} 
                    isDisabled={!!isPaperConsent}
                  />
                </div>
              )}
              memberId={user.id}
              memberFullname={_.get(user, 'profile.fullName')}
              consentProviderName={consentProviderName}
              // selectedTeamProviderIds={selectedTeamProviderIds}
              language={language}
              languageGroupId={languageGroupId}
              refetchConsentInfo={refetchConsentInfo}
              resetSubmitError={key => this.resetSubmitError(key)}
              templateContainerId={templateContainerId}
              referredAt={patientReferral.referredAt}
            />
          :
          consentInfo.consent ?
            <ConsentFormPreview 
              statusText={`${consentInfo.consentType === CONSENT_TYPE.PAPER ? 'Paper consent form' : 'Consent form'} has already been signed!`}
              viewText='View'
              iconProps={{
                theme: 'filled',
                style: { color: 'rgb(57, 218, 148)', cursor: 'auto' }
              }}
              memberId={user.id}
              {...consentInfo}
            />
            :
            <span style={{ fontSize: 14, fontStyle: 'italic'}}>
              Consent form should have been signed, but the status is not correct. Please contact tech team for support
            </span>
        }
      </div>;
    }

    renderMemberDetail = () => {
      const { user, patientReferral }  = this.props;
      const referrerName = _.get(patientReferral, 'referredBy.profile.fullName');
      const healthConditions = genHC(user);

      return <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 15 }}>
          <Avatar
            icon='user'
            src={_.get(user, 'profile.avatar.link')}
            onError={() => {}}
          />
        </div>
        <div id='enroll-patient-info-wrapper'>
          <div className='enroll-patient-info-name'>{_.get(user, 'profile.fullNameWithNickname')}</div>
          <div style={{ display: 'flex' }}>
            <span className='enroll-patient-info-label'>Provider:</span>
            <span >{_.get(user, 'profile.doctor.profile.fullName') || ''}</span><span style={{ marginLeft: 5 }}></span>
          </div>
            {
                referrerName &&
                <div>
                    <span className='enroll-patient-info-label'>Referred by:</span>
                        <span>{referrerName}</span>
                </div>
                // <ReferStatusInPatientHeaderComponent
                //   type='REFERRAL'
                //   btnText={`Referred by ${referrerName}`}
                // />
            }
          <div>
            <span className='enroll-patient-info-label'>Health condition:</span>
            <span>{
              healthConditions.length ?
                healthConditions.join('; ')
              : 'N/A'
            }</span>
          </div>
        </div>
      </div>;
    }

    renderMain(){
      const { loadingPatientReferral, consentInfo, fromPlace, isEnrollCompleted, handleCloseModal } = this.props;
      const _currentPatient = RequestCache.get('nurse/currentPatient')
      // const name = _currentPatient.profile.fullName;
      const curProgram = this.props.program.selectedProgram ? this.props.program.selectedProgram.name : '';
      // const curUrl = browserHistory.getCurrentLocation().pathname;

      if(isEnrollCompleted) {
        const hasConsent = !!consentInfo.consent; // consent signed
        return <EnrollCompleteComponent 
          fromPlace={fromPlace}
          consentType={hasConsent ? null : this.getSelectedConsentType()}
          curProgramName={curProgram}
          handleGoToWorkList={this.handleGoToWorkList}
          handleCloseModal={handleCloseModal}
        />;
      }

      return (
        <div className='v-box vsm-patient-programme-tab enroll-form-wrapper-7303'>
          <div className='enroll-form-wrapper-7303-title'>
            Enroll Patient into Unified Care
          </div>
          {
            !loadingPatientReferral ?
            <div>
              {
                this.props.templateProgramId &&
                <div>
                  {this.renderMemberDetail()}
                  {this.renderTeamList()}
                  {this.renderVitalList()}
                  {this.renderConsentForm()}
                  {this.renderBottomButton()}
                </div>
              }
            </div>
            : <IHLoading />
          }
          {/* <Modal
            title='Congratulations!'
            visible={this.state.visible}
            onOk={this.handleGoBack}
            onCancel={this.handleCancel}
            confirmLoading={this.state.confirmLoading}
            okText='Go back and finish the task'
            centered
          >
            <p>{name} has been successfully enrolled in <a style={{fontWeight:'bold', color:'#000000'}}>{curProgram}</a> Program.<br/>Please go back to the dashboard to finish up the task</p>
          </Modal> */}
        </div>
      );
    }

    changeStartDate(date) {
      this.props.dispatch(actions.changeStartDate(date ? date.toDate(): moment().toDate() )); //when press x on the select date, default today date
    }

    disabledPreviousDate(currentDate) {
      // can not select days before today and today
      return currentDate && currentDate.valueOf() < moment().add(-1,'day').toDate();
    }

    setVisitCheckInTime = async () => {
      // const visitId = this.props.eid.substring(0, this.props.eid.length - 1);
      // const backToCurVisit = JSON.parse(sessionStorage.getItem('backToCurVisit'));
      // if(backToCurVisit){
      //   // backToCurVisit should alwaysexist when it is displaying enrollment page
      //   // checking it to make sure prevent corner case
      //   const { checkinAt, checkoutAt } = backToCurVisit;
      //   if(!checkinAt && !checkoutAt){
      //     // 20200501 Thong - only set new checkin time if it is not already checked-in or checked-out
      //     await API.updateVisit(backToCurVisit.id, 'checkIn');
      //   }
      // }
    }

    renderDateSelector(){
      const { program }=this.props;
      const startDate = _.get(program, 'startDate')
      return (

          <div style={{ marginTop:20 }}>
              <p className='v-tlt' style={{ font:'#474747',fontSize:15, display:'flex',alignItems:'center', marginBottom:20, fontWeight:'bold' }}>Start Date</p>
              <IHDatePicker format='MMM-DD-YYYY' value={moment(startDate)} onChange={this.changeStartDate}  disabledDate={this.disabledPreviousDate}/>
          </div>
      )
    }

    renderVitalList(){
        const program  = _.get(this,'props.program.selectedProgram');
        const patientId = _.get(this,'props.patientId');
        const hasError= !!this.state.submitErrors.vitals;
        const recommendedVitals = this.props.patientReferral.recommendedVitals || [];
        return (
            <div className={`enroll-step ${hasError ? 'enroll-step-has-error' : ''}`} style={{ marginBottom: 64 }}>
                <p className='v-tlt enroll-step-label'>
                  Step 2: Choose Vitals
                  {
                    hasError &&
                    <span className='enroll-step-label-error'>Please choose at least one vital</span>
                  }
                </p>
                <div ref={el => this.stepRefs.vitals = el} >
                  {
                    recommendedVitals.length ?
                    <div style={{ fontSize: 12, color: 'red' }}>
                      MD Referred for {recommendedVitals.join(', ')} vitals
                    </div> : null
                  }
                  <VitalList 
                    program={program} 
                    isFromEnrollProgram={ true } 
                    patientId={patientId} 
                    hideBtn = {true} 
                    resetSubmitError={() => this.resetSubmitError('vitals')}
                    recommendedVitals={recommendedVitals}
                  />
                </div>
            </div>
        )
    }

    runOnceAfterDataLoading(){
      if(this.props.templateProgramId){
        const programme = this.props.programList || []
        const id = this.props.templateProgramId
        const selectedProgram = programme.filter(prog => { return prog.id === id})
        this.props.dispatch(actions.changeSelect(selectedProgram[0]))
      }

      RouterHelper.updatePage.register('Patients_Current_Programmes_New', ()=>{
        if(this.props.eid === 'new'){
            this.props.dispatch(actions.initialState())
        }
      })
    }

    setSelectedTeam = (values) => this.setState({ ...values });

    renderTeamList(){
      const hasError = !!this.state.submitErrors.team;
      return (
        <div className={`v-table enroll-step ${hasError ? 'enroll-step-has-error' : ''}`} style={{ marginBottom: 38,marginTop: 30 }}>
            <p className='v-tlt enroll-step-label'>
              Step 1: Choose Care Team
              {
                this.state.submitErrors.team &&
                <span className='enroll-step-label-error'>Please choose care team</span>
              }
            </p>
            <div ref={el => this.stepRefs.team = el} >
              <ProgramTable 
                currentProgram={this.props.currentProgram} 
                setSelectedTeam={(teamId, consentProviderName, selectedTeamProviderIds) => { 
                  this.setSelectedTeam({
                    selectedTeam: teamId, 
                    consentProviderName, 
                    selectedTeamProviderIds
                  });
                  this.resetSubmitError('team');
                }}
              />
            </div>
        </div>
      )
    }

    async enrollMember(variables){
      const _currentPatient = RequestCache.get('nurse/currentPatient') || this.props.user;
      const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
      // const patientNRIC = _currentPatient.identification[0].value
      const patientName = _currentPatient.profile.fullName
      const auditLogDetails = {
        programName: this.props.program.selectedProgram.name,
        careTeams: this.props.program.selectCareteam.teamInfo,
        startDate: moment(variables.startDate).format('MMM-DD-YYYY'),
        endDate: moment(variables.endDate).format('MMM-DD-YYYY')
      }
      // const curUrl = browserHistory.getCurrentLocation().pathname;
      try {
        const res = await this.props.enrollMember({variables});
        // message.success('Success! This patient has been enrolled.');
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
          details: auditLogDetails,
          request: variables,
          response: res,
          patientNRIC,
          patientName
        })
        await UserClass.saveEPsForProvider();
        //Won't go this condition currently since all enroll is from dashboard
        // if(!_.endsWith(curUrl, '_')) {
        //   const promise = RequestCache.get('refetch-enrolledProgramList').call();
        //   promise.then(()=>{
        //     // this.setDevicesForEP(res.data.enrollMember);
        //     window.location.reload();
        //   })
        // } else {
          // try {
            //Need do check for whether it already has Check-In or Check-Out time,
            //otherwise will lead to incorrectly display for start time in dashboard
            // await this.setVisitCheckInTime();
          // } catch(error) {
          //   console.error(error);
          //   this.openErrorModal(error);
          //   return;
          // }
        // }
        // if (_.includes(curUrl, 'default_')) {
        //   this.backToEnrolledProgram()
        // }
        // else if (_.endsWith(curUrl, '/_')) {
        //   this.backToRemoteEnrollment()
        // }
        // else {
        //   this.handleGoBack()
        // }
        return res;
      } catch(error) {
        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
          details: auditLogDetails,
          request: variables,
          response: error,
          patientNRIC,
          patientName,
          success: false
        })
        this.openErrorModal(error);
        return;
      }
    }

    // async editCurrentEnrolledProgram(callback, modifiedStartDate, modifiedEndDate){
    //     const currentUserId = sessionStorage.getItem('currentUserId')
    //     const _currentPatient = RequestCache.get('nurse/currentPatient')
    //     const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
    //     const patientName = _currentPatient.profile.fullName
    //     const variables = {
    //       id : this.props.currentProgram.id,
    //       teamId : this.props.program.selectCareteam.id,
    //       managerId: [currentUserId]
    //     }
    //     const auditLogDetails = {
    //       programName: this.props.program.selectedProgram.name,
    //       careTeams: this.props.program.selectCareteam.teamInfo
    //     }

    //     if (this.props.currentProgram.startDate !== (modifiedStartDate && modifiedStartDate.getTime())) {
    //         const setDateEPVariables= {id: this.props.currentProgram.id, startDate: modifiedStartDate, endDate: modifiedEndDate};
    //         const auditLogSetDateDetails = {
    //           programName: this.props.program.selectedProgram.name,
    //           startDate: moment(modifiedStartDate).format('MMM-DD-YYYY'),
    //           endDate: moment(modifiedEndDate).format('MMM-DD-YYYY')
    //         }
    //         try {
    //             const res = await this.props.setDateForEnrolledProgram({ variables: setDateEPVariables})
    //             createAuditLog({
    //               action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_startDate'),
    //               details: auditLogSetDateDetails,
    //               request: setDateEPVariables,
    //               response: res,
    //               patientNRIC,
    //               patientName
    //             })
    //         } catch( error ){
    //             createAuditLog({
    //               action:I18N.get('auditLog.provider.patient.enrollInNewProgram.edit_enrolled_program_startDate'),
    //               details: auditLogSetDateDetails,
    //               request: setDateEPVariables,
    //               response: error,
    //               patientNRIC,
    //               patientName,
    //               success: false
    //             })
    //             return;
    //         }
    //     }

    //     try{
    //         const res = await this.props.manageTeamForEnrolledProgram({variables})
    //         createAuditLog({
    //           action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
    //           details: auditLogDetails,
    //           request: variables,
    //           response: res,
    //           patientNRIC,
    //           patientName
    //         })

    //         const promise = RequestCache.get('refetch-enrolledProgramList').call()
    //         promise.then(()=>{
    //             this.props.dispatch(tabAction.setTabsStep('careplan'))
    //             _.delay(callback, 1000)
    //         })

    //     } catch(error) {
    //         this.openErrorModal(error);
    //         createAuditLog({
    //           action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
    //           details: auditLogDetails,
    //           request: variables,
    //           response: error,
    //           success: false,
    //           patientNRIC,
    //           patientName
    //         })
    //         callback()
    //     } finally {

    //     }
    // }

    renderBottomButton(){
        const p1 = {
          type : 'primary',
          loading : this.props.isEnrollingPatient,
          onClick : this.onClickNext,
          className : 'v-nb',
          style: {
            padding: '9px 12px', 
            backgroundColor: '#3177C9', 
            height: 40, 
            fontFamily: 'var(--font-family)',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '22px'
          }
        }

        return (
            <div className='v-bottom-btn' style={{ margin: '42px 0px 77px', textAlign:'center' }}>
                <Button {...p1}>
                  Enroll Patient
                </Button>
            </div>
        )
    }

    // setButtonLoadingStatus(status=true) {
    //   const button = _.get(this, 'refs.btn_next')
    //   button && button.loading(status)
    // }

    getNewAry(field) {
        const list = [];
        field = _.forEach(field, item => {
          list.push(_.omit(item, '__typename'));
        })
        return list;
    }

  openErrorModal = (error) => this.props.dispatch(modalAction.openErrorModal(GQLHelper.formatError(error)));

  _onClickNext = () => {
    const { setIsEnrollingPatient, setIsEnrollCompleted, patientReferral } = this.props;
    const dispatch = this.props.dispatch
    const vitalListForm = this.props.vitalListForm
    // convert vital list
    const tasks = [];
    const adminProgramTaskList = _.get(this.props.programList[0], 'tasks', []);
    const selectedAdminProgram = this.props.programList[0];
    // const selectedAdminProgram = (this.props.programList || []).filter( (p) => p.id === this.props.program.current )
    const startDate = this.props.program.startDate;
    const duration = _.get(selectedAdminProgram, 'duration', '')
    const calendar = _.get(duration, 'calendar', '')
    const multiplier = calendar === 'DAY'
                        ? 1 : calendar === 'WEEK'
                        ? 7 : calendar === 'MONTH'
                        ? 30 : 0
    const endDate = moment(startDate).add(_.get(duration, 'length') * multiplier - 1, 'd').toDate();
    //remove _type from program response
    const map = new Map();

    const { consent: isConsent = false } = this.props.consentInfo;
    let shouldSubmitConsentForm = true;

    _.forEach(adminProgramTaskList, task => {
      map.set(task.id, task);
    })

    _.forEach(vitalListForm, vital => {
      if(map.get(vital.id)) {
        let val = map.get(vital.id);
        const scheduleList = this.getNewAry(val.schedule);
        let thresholdList = this.getNewAry(val.threshold);
        thresholdList = _.map(thresholdList, item => {
          return _.omit(item, 'baselineUpdatedAt');
        })
        const notificationList = this.getNewAry(val.notification);
        val.schedule = scheduleList;
        val.threshold = thresholdList;
        val.notification = notificationList;
        let vitalObj = {};
        vitalObj.schedule = vital.schedule;
        vitalObj.vitalType = vital.vitalType;
        val.schedule = generateScheduleForDB(vitalObj);
        val.templateDetails = _.omit(val.templateDetails, '__typename');
        let schedule = val.templateDetails.schedule ? _.omit(val.templateDetails.schedule, '__typename') : null;
        schedule = _.mapValues(schedule, item => {
          return _.omit(item, '__typename');
        })
        if(val.templateDetails) {
          val.templateDetails.schedule = schedule;
        }
        tasks.push(_.omit(val, '__typename'));
      }
    })

    const variables = {
        id : this.props.templateProgramId,
        memberId : this.props.patientId,
        teamId : this.props.program.selectCareteam.id,
        startDate,
        endDate,
        customizedTasks: tasks,
    }
    
    const submitErrors = {}; // clean up errors
    // Do NOT proceed if no care team is selected, show error message instead
    if (variables.teamId.length === 0) {
      submitErrors.team = true;
    }
    if (vitalListForm.length === 0) {
      submitErrors.vitals = true;
    }
    if(this.isConsentFormButtonDisabled && this.isConsentFormButtonDisabled()) {
      submitErrors.consentForm = true;
    } else if (!this.isConsentFormButtonDisabled) {
      shouldSubmitConsentForm = false;
      console.warn(`Can't validate consent form. Consent form will not be sent`);
    }
    
    // Do NOT proceed if there is any error
    if(!_.isEmpty(submitErrors)) {
      if(submitErrors.consentForm && this.checkIfHasConsentType && !this.checkIfHasConsentType()) {
        submitErrors.missingConsentType = true;
      }
      this.setState({ submitErrors }, () => {
        let element, scrollIntoViewOpts = { block: 'center', inline: 'nearest', behavior: 'smooth' };
        try {
          if(this.stepRefs.team && submitErrors.team) {
            element = this.stepRefs.team;
          } else if(this.stepRefs.vitals && submitErrors.vitals)  {
            element = this.stepRefs.vitals;
          } else if(this.stepRefs.consentForm && (submitErrors.consentForm || submitErrors.missingConsentType)) {
            element = this.stepRefs.consentForm;
            scrollIntoViewOpts.block = 'start';
          }

          if(element)
            element.scrollIntoView(scrollIntoViewOpts);
        } catch (err) {
          console.error(err);
        }
      });
      return;
    }

    setIsEnrollingPatient(true, async () => {
      // const curUrl = browserHistory.getCurrentLocation().pathname;
      if(!this.props.currentProgram) {
        try {
          let enrollRes, consentRes;
          enrollRes = await this.enrollMember(variables);
          const enrolledProgram = _.get(enrollRes, 'data.enrollMember') || {};
          if(shouldSubmitConsentForm && typeof this.submitConsentForm === 'function') {
            consentRes = await this.submitConsentForm();
          }
          setIsEnrollCompleted();

          try {
            consentFormHelpers.syncAccounts(this.props.user, patientReferral, enrolledProgram, consentRes);
            consentFormHelpers.syncClinicGoals(enrolledProgram.id, patientReferral.clinicGoals);
            consentFormHelpers.syncConditionsList(this.props.user, patientReferral);
          } catch (e) {
            console.error(e);
            this.props.dispatch(modalAction.openErrorModal('Failed to sync enrolled program'));
            return;
          }
        } catch(error) {
          console.error(error);
          this.openErrorModal(error);
          // if(_.endsWith(curUrl, '_')) {
          //   return;
          // }
        }
      } else {
        console.warn('currentProgram exists, please check status. No-OP!');
        //this.editCurrentEnrolledProgram(() => {}, startDate, endDate)
      }
      setIsEnrollingPatient(false);
    });
  }
}

const withData = graphql(adminProgramList, {
  options: {
    variables: {
      'page': 1,
      'count': 999, //need to get all of the admin programs, there's no pagination here
      'options': {},
      'sort': {
        'field': 'CREATED_AT',
        'direction': 'ASC'
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy:'ignore'
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, adminProgramList } = data
    return {
      programList: _.filter(get(adminProgramList, 'data'), (ap) => {
        return ap.isDefault === true
      }),
      // programList: get(adminProgramList, 'data'),
      loading,
      refetch
    }
  }
})

const getPatientReferral = graphql(getPatientReferralForRDCAEnroll,{
	options:(ownProps)=>{
		return {
      variables: {
        patientId:  _.get(ownProps,'user.id'),
      },
			fetchPolicy:'network-only'
		}
	},
	props:({data})=>{
		const {loading, error, getPatientReferral } = data;
    if(error)
      console.error(error);
      
		return {
			patientReferral: getPatientReferral || {},
      loadingPatientReferral: loading
		};
	}
});

const enrollMemberWrapper = graphql(enrollMemberGQL
  , {
    name : 'enrollMember'
})

const manageTeamForEnrolledProgramGQLWrapper = graphql(manageTeamForEnrolledProgramGQL, {
    name : 'manageTeamForEnrolledProgram'
})

const setDateForEnrolledProgramWrapper = graphql(setDateForEnrolledProgram, {
  name: 'setDateForEnrolledProgram'
})

const WrapperContainer = GQLHelper.wrapper([withData, getPatientReferral, enrollMemberWrapper, manageTeamForEnrolledProgramGQLWrapper, setDateForEnrolledProgramWrapper], Component)
export default createContainer(WrapperContainer, (state, ownProps)=>{
    const cache = RequestCache.get('enrolledProgramList')
    const eid = getRouterParam(state, 'enrolledProgramId')
    const templateId = (ownProps, 'currentProgram.id')
    const templateProgramId = get(ownProps, 'currentProgram.adminProgram.id')
                           || get(state, 'patient.program.current')
    const enrolledProgramList = cache
    const patientId = getRouterParam(state, 'patientId') || _.get(ownProps, 'user.id')
    const haveCareTeam = get(state, 'patient.program.haveCareTeam')
    const vitalListForm = state.patient.enrolledProgramInfo.Careplan.VitalForm.vitalList
    return {
        templateId,
        templateProgramId,
        enrolledProgramList,
        patientId,
        eid,
        haveCareTeam,
        vitalListForm,
        ...state.patient
    }
},
{
  setExtendStep
});