import gql from 'graphql-tag'

export default gql `query getUserChatInfo($userId:EID!,$newAuthKey:Boolean) {
  getUserChatInfo(userId:$userId, newAuthKey:$newAuthKey) {
    userChatId
    publishKey
    subscribeKey
    authKey
    cipherKey
    messageSource
    channels{
        name
        description
        teamId
        adminProgramId
        channelGroup
        newChannelGroup
    }
    channelGroup {
      name
      channels {
        name
        member {
          id
          profile {
            firstName
            lastName
            gender
            avatar {
              link
            }
          }
          allRoles {
            name
            category
          }
        }
      }
    }
  }
}`
