import React from 'react';
import { Button,Modal,Input,Form,message,DatePicker,Radio, Tooltip, Checkbox } from 'antd';
import moment from "moment/moment";
import map from '../constants/tableMap';
import helper from '../helper';
import enrolledProgramList from "graphqlModule/enrolledProgramList";
import editTask from '../query/editTask';
import deleteTask from '../query/deleteTaskAssignment';
import { graphql, compose } from 'react-apollo';
import Client from 'libModule/gqlClient';
import { IHLoading } from 'ihcomponent';
const { priorityMap } = map;
const { TextArea } = Input;
const { Item } = Form;
import '../style/index.scss'
import {connect} from "react-redux";
import TimerContainer from '../../timer/containers/TimerContainer';
import * as timerActions from '../../timer/actions/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import API from '../API/index';
import I18N from '../../I18N';
import ResolvedNote from './ResolvedNote';
import MyTasksModalComponent from './MyTasksModalComponent';
import TaskAssignmentHistory from './TaskAssignmentHistory';
import { EVENT_TYPES } from '../../idleTime/constant';

const { stopTimer,updateInterventionDetails }= timerActions.default;
const style = {
    rowStyle:{
        display:'flex',
        flexDirection:'column'
    },
    buttonDivStyle:{
        margin:'10px 0px'
    },
    buttonStyle:{

    },
    labelStyle:{
        fontWeight:'bold'
    },
    reasonStyle:{
        paddingBottom: '10px',
        color: '#474747',
        opacity:0.7
    },
    assignerStyle:{
        color:'#555555',
        marginRight: 10,
        fontWeight:'bold'
    },
    resolveStyle:{
        color:'#555555',
        display:'flex',
        justifyContent:'flex-end'
    }

}

let TrackingTaskModalComponent = class extends React.Component {
    static displayName ='TrackingTaskModalComponent';

    constructor(){
        super();
        this.state = {
          note:'',
          resolveFormVisibity: false,
          isReasonRequired: false,
          addToPN: false,
          assignees: { }
        }
    }

    setResolveFormVisibility = (isVisible = true) =>
      this.setState({ resolveFormVisibity: isVisible });

    onChangeInput = (e)=>{
        const note = e.target.value;
        this.setState({
            note
        })
    }
    async componentDidMount() {
        const id = _.get(this.props,'modalData.memberId',_.get(this.props,'modalData.member.id'));
        const data = await API.getPatientAssignee(id);
        const assignees = _.get(data,'data.user.assignees');
        this.setState({
            assignees
        })
    }

    setAddToPN = (e) => {
      this.setState({addToPN: e.target.checked});
    }

    renderRadioGroup = (disabled)=>{
      return  <Radio.Group disabled={disabled}>
                { _.map(priorityMap,(value,key)=><Radio value={key} key={value}>{value}</Radio>) }
              </Radio.Group>
    }

    submit = ()=>{
      const { form,modalData, refetchTasksCount, timerClaimed,refetchTaskList } = this.props;
      form.validateFields(['reason', 'assignedTo', 'dueDate'], errors => {
        if(errors) return;
        const { id,refetch } = modalData;
        const memberId = _.get(modalData,'member.id');
        const { reason,assignedTo,dueDate,priorityLevel } = form.getFieldsValue();
        const { addToPN } = this.state;
        Mixpanel.track('clicked', 'submit', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
        Client.mutate({
            mutation:editTask,
            variables:{
                id,reason,assignedTo,priorityLevel,
                dueDate:dueDate? moment(dueDate).endOf('day'): null,
            }
        }).then(res=>{
            if(refetchTaskList) {
                refetchTaskList();
            }
            if(refetch){
                refetch();
            };
            if(refetchTasksCount){
              refetchTasksCount();
            }
            API.fetchTaskUnreadList();
            API.fetchTaskUnreadTasks();
            const apiName = Object.keys(res.data)[0];
            const docId = res.data[apiName].id;
            const resource = atob(docId).split(':')[0];
            let displayName = this.constructor.displayName;
            const summary = res.data[apiName].id;

            displayName = `${displayName}:${memberId}`;
            this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
            this.props.stopTimer(displayName, 'saveModal');
            message.success('Task Updated');
            if(addToPN){
              helper.createPN({
                memberId,
                content: reason
              });
            }
            if (timerClaimed) {
              timerClaimed.interacted(EVENT_TYPES.TASK_ASSIGNMENT, 'UPDATED');
              timerClaimed.stopTimer();
            }
            this.close();
        })
      });
    }

    deleteTask = ()=>{
        const { modalData,refetch,refetchUser,refetchUserList, refetchTasksCount, timerClaimed } = this.props;
        const { id } = modalData;
        // const { priorityLevel } = form.getFieldsValue();
        // Mixpanel.track('clicked', 'delete task', 'patient task list popup', {PRIORITY_LEVEL: priorityLevel});
        return new Promise((resolve,reject)=> {
            Modal.confirm({
                title:  'Delete Task Assignment?',
                onOk: () => {
                    Client.mutate({
                        mutation: deleteTask,
                        variables: {
                            id
                        }
                    }).then(res => {
                        if (refetch) {
                            refetch();
                        }
                        if(refetchUser){
                            refetchUser();
                        }
                        if(refetchUserList){
                            refetchUserList()
                        }
                        // update the tasks number when delete one
                        if(refetchTasksCount){
                            refetchTasksCount();
                        }
                        API.fetchTaskUnreadList();
                        message.success('Task Deleted');
                        if (timerClaimed) {
                          timerClaimed.interacted(EVENT_TYPES.TASK_ASSIGNMENT, 'DELETED');
                          timerClaimed.stopTimer();
                        }
                        this.close();
                    }).catch(e=>{
                        Modal.error({ content : e.message });
                    })
                },
                okText: 'Delete',
                cancelText: `Cancel`,
                onCancel:()=>{
                    resolve(true);
                }
            })

        })
    }

    renderFooter = (resolvedNote)=>{
        return <div>
                    { !resolvedNote  ? <Button type='primary' style={{ marginRight:10 }} onClick={()=>this.submit()}>Submit</Button> :'' }
                    <Button onClick={ ()=>this.deleteTask() }>Delete Task</Button>
               </div>
    }

    close = ()=>{
      const { setModalData } = this.props;
      setModalData({showModal:false,modalData:null});
    }

    renderBodyInProfile = () =>  {
      const { createdAt, reason, history } = _.get(this, 'props.modalData') || {};
      return <div>
        <div className='tracking-task-reason-container'>
          <span>Reason:</span>
          <div>{reason}</div>
        </div>
        <TaskAssignmentHistory taskCreatedAt={createdAt} history={history} />
      </div>
    }

    onChangeAssignee = (value) => {
      const currentAssigneeId = _.get(this, 'props.modalData.assignedTo.id');
      const isReasonRequired = !_.isEqual(currentAssigneeId, value);
      this.setState({
        isReasonRequired
      }, () => {
        if(!isReasonRequired) {
          this.props.form.validateFields(['reason'], { force: true });
        }
      });
    }

    getFormProps = ()=>{
        const { setResolveFormVisibility, props, state } = this;
        const { 
          form, modalData,renderBody,team,loading,
          enrolledPrograms, showFormOnly, customHeader, 
          setReassignmentFormVisibility 
        } = props;
        const { resolveFormVisibity, isReasonRequired,assignees } = state;
        const { getFieldDecorator, getFieldsValue } = form;
        const { 
          createdBy, priorityLevel,assignedTo,
          dueDate,member,resolvedNote
        } = modalData||{};
        const  patientName  = _.get(member,'profile.fullName');
        const mrn = _.get(member,'identification.0.value');
        const assigneeId = _.get(assignedTo,'id');
        if(loading) return <IHLoading/>
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

        const teamsFromLoginUser = currentUser.team;
        // ONLY creator can delete the task
        const canDeleteTask = currentUser.id === _.get(createdBy, 'id');
        // creator should not be able to resolve their own-created task
        // they can resolve when they are assignee
        const canResolveTask = currentUser.id !== _.get(createdBy, 'id') || currentUser.id === assigneeId;
        const { reason } = getFieldsValue();
        return resolveFormVisibity ?
          <MyTasksModalComponent
            {...props}
            setResolveFormVisibility={setResolveFormVisibility} 
            showFormOnly
          />
        :
          <div style={{ padding:10 }} >
            <Form className={`trackingTaskModal ${renderBody ? 'inPatientProfile':''}`} >
                    <div className='firstDiv'>
                        <Item key='reason'>
                            {
                              customHeader ||
                              <strong style={style.labelStyle}>
                                { renderBody? this.renderBodyInProfile() :`${patientName} (${mrn})` }
                              </strong>
                            }
                            {getFieldDecorator('reason', { 
                              rules: [
                                { 
                                  required: isReasonRequired,
                                  message: 'Reason is required when reassigning',
                                  whitespace: true,
                                }
                              ] 
                            })(<TextArea rows={6} disabled={resolvedNote}/>)}
                        </Item>
                        <div>
                          <Checkbox
                              onChange={ this.setAddToPN }
                              disabled={!reason}
                          >
                              Add to Provider Notes
                          </Checkbox>
                        </div>
                    </div>
                    <div style={{display:'flex'}} className='secondDiv'>
                        <div className='assignAndDueDayDiv'>
                            <Item key='assignedTo' label={I18N.get('taskAssignment.assignedTo')}>
                                {getFieldDecorator('assignedTo',{ rules: [{required: true, message: I18N.get('taskAssignment.assignedToMsg')}], initialValue: assigneeId || null})(helper.assignedToDropDown(enrolledPrograms,(!team||team.length==0) ? teamsFromLoginUser : team,resolvedNote,this.onChangeAssignee,assignees))}
                            </Item>
                            <Item key='dueDate' label={I18N.get('taskAssignment.dueDate')}>
                                {getFieldDecorator('dueDate',{ rules: [{required: true, message: I18N.get('taskAssignment.dueDateMsg')}], initialValue: dueDate ? moment(dueDate) : null})(<DatePicker format='MM/DD/YYYY' disabled={resolvedNote}/>)}
                            </Item>
                        </div>
                        <Item label={I18N.get('taskAssignment.priorityLevel.label')}>
                            {getFieldDecorator('priorityLevel',{ initialValue: priorityLevel || null})(this.renderRadioGroup(resolvedNote))}
                        </Item>
                    </div>
                </Form>
                { 
                  resolvedNote ? 
                  <ResolvedNote {...resolvedNote} />
                  :
                  <div 
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                    className={showFormOnly ? 'task-reassign-form-btn-panel' : ''}
                  >
                    <Button 
                      type='primary' style={{ marginRight:10 }} 
                      onClick={this.submit}
                    >
                      Submit
                    </Button>
                    {
                      showFormOnly &&
                      <Button onClick={() => setReassignmentFormVisibility(false)}>
                        Cancel
                      </Button>
                    }
                    <div style={
                      showFormOnly ? { width: '100%', textAlign: 'right'} : {}
                    }>
                      <Tooltip
                        overlayClassName='task-assignment-tooltip'
                        title={canDeleteTask ? '' : 'Only creator can delete this task'}
                      >
                        <Button 
                          type='danger'
                          onClick={this.deleteTask}
                          ghost
                          disabled={!canDeleteTask}
                        >
                          Delete Task
                        </Button>
                      </Tooltip>
                    </div>
                    {
                      renderBody && 
                      <div style={{ 
                        marginLeft: 10, textDecoration: 'underline', fontSize: 14 
                      }}>
                        <Tooltip
                          overlayClassName='task-assignment-tooltip'
                          title={canResolveTask ? '' : 'Creator cannot resolve this task. Please reassign to yourself first.'}
                        >
                          <span>
                            <a 
                              disabled={!canResolveTask}
                              onClick={setResolveFormVisibility}
                            >
                              Resolve Task
                            </a>
                          </span>
                        </Tooltip>
                      </div>
                    }                         
                  </div>
                }
              </div>;

    }
    renderTimer(){
        const { modalData } = this.props;
        let displayName = this.constructor.displayName;
        const memberId = _.get(modalData,'member.id');
        const viewingDetails ={
            component : displayName,
            path:window.location.pathname
        };
        const keepEventListener = true;
        return <TimerContainer displayName={displayName} category='TASK_ASSIGNMENT' action='UPDATE'
                               viewingDetails={viewingDetails}
                               patientId={memberId}
                               keepEventListener={keepEventListener}
        />
    }

    render(){
        const { props } = this;
        const { renderBody , showFormOnly } = props;
        return <div>
                    { this.renderTimer()}
                    {
                        !renderBody && !showFormOnly ? 
                        <Modal 
                          visible={true} footer={null} maskClosable={false} 
                          onCancel={() => this.close()}
                          destroyOnClose={true}
                        >
                          {this.getFormProps()}
                        </Modal> 
                        : 
                        <div>{this.getFormProps()}</div>
                    }
                </div>
    }


}
const team = graphql(enrolledProgramList,{
    options:(props)=>{
        const { member,memberId } = props.modalData
        return{
            variables:{
                filters: {
                    memberId:memberId||member.id
                }
            }
        }
    },
    props:({data})=>{
        const { enrolledProgramList,loading } = data;
        if(loading) {
            return {
                loading
            };
        }
        const startedEP = _.filter(_.get(enrolledProgramList,'data',[]),(ep)=>ep.status==='STARTED');
        const team  = _.get(startedEP,'0.team',[]);
        return {
            team
        }
    }

})
const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        uuid
    }
};
const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};

TrackingTaskModalComponent = Form.create()(TrackingTaskModalComponent);
export default compose(team,connect(mapToProps,mapToDispatch))(TrackingTaskModalComponent);
