import React, { Component } from 'react';
import { Col, Row, Select, Form, Radio, Tooltip, Icon } from "antd";
import {helpers} from "../../visit/helpers";
import {helpers as dataHelper} from "../helper";
import I18N from "../../I18N";
import { getVisitTypeOptions } from '../../../lib/utils';

const {Item} = Form;
const {Option} = Select;
class VisitInputTypeAndProvider extends Component{
    renderType(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { visitType, setVisitType, followUpVisitTypeOptions, type, showFollowUpTypeWarning } = this.props;
        const selectCategory = getFieldValue('category');
        const visitTypeTips = Object.entries(I18N.get('visit.tips.visitType')).map((info, i) => {
            return (
                <li key={i}><span style={{fontWeight: 'bold'}}>{info[0]}: </span><span>{info[1]}</span></li>
            )
        });

        return (
            <div>
                <Item label={I18N.get('visit.details.visitType')}>
                    {getFieldDecorator('type', {
                        initialValue: visitType,
                        rules:[{required:true}]
                    })(
                        <Select onChange={setVisitType}>
                            {_.map(followUpVisitTypeOptions || getVisitTypeOptions(),(type,val)=>{
                                const isPhoneMNTOption = this.PhoneMNTOption(selectCategory, val);
                                return (                                    
                                    <Option disabled={isPhoneMNTOption} value={val} key={val}>
                                        {isPhoneMNTOption?<Tooltip title={I18N.get('visit.tips.MNTPhoneContent')} overlayStyle={{maxWidth: 350}}>{type}</Tooltip>:type}
                                    </Option>
                                )
                            })}
                        </Select>
                    )}
                </Item>
                { 
                  showFollowUpTypeWarning &&
                  <div style={{ color: 'red', margin: '-14px 0px 10px', fontSize: 12 }}>
                    {I18N.get('visit.tips.visitTypeWarning')}
                  </div>
                }
                <Tooltip overlayClassName='visit-type-tip' trigger='click' placement='right' overlayStyle={{maxWidth: 450}} title={() => <ul>{visitTypeTips}</ul>}><span style={{textDecoration: 'underline', fontSize: '12px', cursor: 'pointer', userSelect: 'none' }}>How to choose visit type?</span></Tooltip>
            </div>
        )
    }

    PhoneMNTOption = (selectCategory, type) => selectCategory === 'PHONE' && type === 'MNT'

    getInitialCategory = () => {
      const { visitInfo, editStatus, hasLoadDevice } = this.props;
      const category = _.get(visitInfo, 'category');
      const defaultVisitType = hasLoadDevice ? 'INCLINIC' :'VIRTUAL';
      return category || (editStatus ? 'INCLINIC' : defaultVisitType);
    }

    renderCategory(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { hasLoadDevice, visitType } = this.props;
        const isSelectTypeMNT = getFieldValue('type') == 'MNT';
        const hasLoanPhoneIndicator = <Tooltip title={I18N.get('visit.tips.loanerPhoneUser')}><Icon type="info-circle" /></Tooltip>;
        return (
            <Item label={I18N.get('visit.details.visitCategory')} >
                {getFieldDecorator('category', {
                  initialValue: this.getInitialCategory(), 
                  rules:[{required:true}]
                })
                (
                    <Radio.Group>
                        <Radio value={'VIRTUAL'} disabled={hasLoadDevice}>Virtual{ hasLoadDevice&&hasLoanPhoneIndicator }</Radio>
                        <Radio value={'INCLINIC'}>In-clinic</Radio>
                        <Radio value={'PHONE'}>Phone</Radio>
                        {/* <Radio value={'PHONE'} disabled={isSelectTypeMNT}>
                            {isSelectTypeMNT? <Tooltip title={I18N.get('visit.tips.MNTPhoneContent')} overlayStyle={{maxWidth: 350}}>Phone</Tooltip>:"Phone"}
                        </Radio> */}
                    </Radio.Group>
                )}
            </Item>
        )
    }

    renderProvider(){
        const {form, visitInfo, editStatus, patient} = this.props;
        const { getFieldDecorator } = form;
        const doctorOptions = helpers.filterDoctors();

        let initDoctorOption = {};
        if(editStatus && visitInfo.providers){
          initDoctorOption = { id: _.get(visitInfo, 'providers.0.id') };
        }
        if(!initDoctorOption.id) {
          initDoctorOption = _.get(patient,'user.profile.doctor') ||
                             _.get(_.filter(doctorOptions,d=>d.primary),'0') || {};
        }

        return (
            <Item>
                {getFieldDecorator('doctor',{
                  initialValue:initDoctorOption.id ||null,
                  rules:[{required:true , message: I18N.get('provider.required.message') }]
                })
                (
                    <Select filterOption={false}>
                        {_.map(doctorOptions,(program)=>{
                            return (
                                <Option value={program.id} key={program.id}>{program.fullName}</Option>
                            )
                        })}
                    </Select>
                )}
            </Item>
        )
    }

    renderNoPhoneError = () => {
        const { getFieldValue } = this.props.form;
        const isPhoneVisit = getFieldValue('category') === 'PHONE';
        const hasPhone = !(_.get(this.props, 'user.phone').length === 0);
        const hasError = isPhoneVisit && !hasPhone;
        return hasError ? <div style={{marginTop:5}}>
                    <Icon type="info-circle" style={{marginRight: 5, paddingTop:4, color:'red'}}/>
                    <span style={{color:'red'}}>{I18N.get('error.noPhoneError')}</span> 
                </div> : ''
    }

    // checkVisitRoles = (rule, value, callback) => {
    //     const {visitType} = this.props;
    //     if(visitType !== 'TECH' && value.length === 1 && value[0] ==='MD'){
    //         callback("You can't schedule a visit only for MD.");
    //     }
    //     callback();
    // }

    // renderNotes = ()=>{
    //     const {visitInfo, editStatus, visitType} = this.props;
    //     const { getFieldDecorator } = this.props.form;
    //     let reason = null;
    //     if(editStatus){
    //         reason = visitInfo.reason;
    //     }
    //     return visitType === 'CLINIC_FOLLOW_UP' || visitType === 'INIT' ? '' :
    //             <Item label={I18N.get('visit.details.visitNote')} >
    //                 {getFieldDecorator('reason', {
    //                     initialValue: reason,
    //                     rules: [{required: false}]
    //                 })
    //                 (
    //                     <Input />
    //                 )}
    //             </Item>

    // }

    // renderRoles = ()=>{
    //         const { form: { getFieldDecorator }, visitType, visitInfo } = this.props;
    //         return (
    //           <Item label={I18N.get('visit.details.visitRoles.required.label')} >
    //               {getFieldDecorator('visitRoles', {
    //                   initialValue: _.get(visitInfo, 'visitRoles') || 
    //                                 getVisitRoles(visitType, this.getInitialCategory()),
    //                   rules: [
    //                       { required: true, message: I18N.get('visit.details.visitRoles.required.message'), type: 'array' },
    //                       // { validator: (rule, value, callback) => this.checkVisitRoles(rule, value, callback)}
    //                   ],
    //               })(
    //                   <Checkbox.Group>
    //                         {
    //                             _.map(_.keys(I18N.get('teamRoles')), (role, key) => {
    //                                 return <div>
    //                                     <Checkbox key={key} value={role}>{role === 'MD' ? 'Provider' : role}</Checkbox>
    //                                 </div>
    //                             })
    //                         }
    //                   </Checkbox.Group>
    //               )}
    //           </Item>
    //         )
    // }

    // renderAssignees = (type,role)=>{
    //     const {visitInfo, editStatus, visitType,onAssigneeSearch,assigneesAvailability} = this.props;
    //     const assigneesAvailabilityMap = {};
    //     _.forEach(assigneesAvailability,(s=> _.set(assigneesAvailabilityMap,s.id, s.overlapVisits.length!=0)));
    //     const { getFieldDecorator } = this.props.form;
    //     const searchKey = _.get(this.props.searchKey,`${type}`,'');
    //     const assignees  = _.get(visitInfo,'assignees');
    //     const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //     const teamsFromLoginUser = loginUser.team||[];
    //     const titleMap = {
    //         assignedToCA:'CA Assignee',
    //         assignedToRD:'RD Assignee'
    //     };
    //     const placeholderMap = {
    //         assignedToCA: 'Assign a CA (Optional)',
    //         assignedToRD: 'Assign a RD (Optional)'
    //     }
    //     return  <Item>
    //         {getFieldDecorator(type,{
    //             initialValue: _.get(assignees,`${type}.providerId`),
    //             rules:[{
    //                 validator:(rule,value,cb)=>{
    //                    if(dataHelper.validateAssigneesAvailable(assigneesAvailabilityMap,value)) {
    //                        cb('Assignee is unavailable at this time. Please re-select the time or reassign.');
    //                    }
    //                    else cb();
    //                 }
    //             }]
    //         })
    //         (
    //             <Select filterOption={false} allowClear={true} showSearch placeholder={placeholderMap[type]}
    //                     onChange={(t,option)=>this.props.changeMemberSel(type,t,option||{})}
    //                     // onSelect={(id,target)=>this.props.changeMemberSel(type,id,target)}
    //                     onSearch={ (str)=>onAssigneeSearch(type,str) }
    //                     onBlur={()=>onAssigneeSearch(type,'')}
    //             >
    //                 {_.map(helper.getMembersByRole(teamsFromLoginUser,role).filter(({ fullName })=>{
    //                     const regex = new RegExp(searchKey, 'gi');
    //                     return fullName.match(regex);
    //                 }),({id,fullName})=>{
    //                     return (
    //                         <Option key={id} data-fullname={fullName} disabled={assigneesAvailabilityMap[id]}>
    //                                 {`${fullName} ${loginUser.id===id ?`(Assign to me)`:'' }`}
    //                                 <span style={{ float:'right',color:'#aaaaaa' }}>
    //                                     {assigneesAvailabilityMap[id] ? 'Unavailable':''}
    //                                 </span>
    //                         </Option>
    //                     )
    //                 })}
    //             </Select>
    //         )}
    //     </Item>
    // }

    // renderParticipants = () => {
    //     const { form: { getFieldDecorator, getFieldValue }, visitType, visitInfo } = this.props;
    //     const { renderAssignees, changeMemberSel } = this;
    //     const formVisitRoles = getFieldValue('visitRoles') ||
    //                            _.get(visitInfo, 'visitRoles') ||
    //                            getVisitRoles(visitType, this.getInitialCategory());

    //     return (
    //       <Item label={I18N.get('visit.details.visitRoles.required.label')} >
    //           {getFieldDecorator('visitRoles', {
    //               initialValue: _.get(visitInfo, 'visitRoles') ||
    //                             getVisitRoles(visitType, this.getInitialCategory()),
    //               rules: [
    //                   { required: true, message: I18N.get('visit.details.visitRoles.required.message'), type: 'array' },
    //                   // { validator: (rule, value, callback) => this.checkVisitRoles(rule, value, callback)}
    //               ],
    //           })(
    //               <Checkbox.Group style={{width: '100%'}}>
    //                     {
    //                         _.map(_.keys(I18N.get('teamRoles')), (role, key) => {
    //                             return (
    //                                 <div className='visit-roles-checkbox-item' style={{ display: 'flex', alignItems: 'center' }} key={key}>
    //                                     <Col span={8} className='visit-role-checkbox'>
    //                                         <Checkbox key={key} value={role}>{role === 'MD' ? 'Provider' : role}</Checkbox>
    //                                     </Col>
    //                                     <Col span={14}>
    //                                         {
    //                                             role === 'MD' && formVisitRoles.includes('MD') ? this.renderProvider() :
    //                                             role === 'CA' && formVisitRoles.includes('CA') ? renderAssignees('assignedToCA',['Medical Assistant'],changeMemberSel) :
    //                                             role === 'RD' && formVisitRoles.includes('RD') ? renderAssignees('assignedToRD',['PROVIDER:Nurse','Nurse'],changeMemberSel) :
    //                                             null
    //                                         }
    //                                     </Col>
    //                                 </div>
    //                             )
    //                         })
    //                     }
    //               </Checkbox.Group>
    //           )}
    //       </Item>
    //     )
    // }

    // changeMemberSel = (type,id,{ props })=>{
    //     const { assignee } = this.state;
    //     _.set(assignee,type,{
    //         id,fullName:props['data-fullname']
    //     })
    //         this.setState(assignee)
    // }

    render() {
        return (
            <div>
                <Row gutter={16}>
                    <Col span={10}>
                        { this.renderType()}
                    </Col>
                    <Col span={14}>
                        { this.renderCategory()}
                    </Col>
                </Row>
                {this.renderNoPhoneError()}
            </div>
            )
    }
}

export default VisitInputTypeAndProvider;
