/**
 * Created by zizhangai on 2/8/17.
 */
import types from '../constants/ReportModalConstants'

const initState = {
  reason: null,
  start: null,
  end: null,
  summary: null
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.CHANGE_DATE:
      return {
        ...state,
        start: action.payload[0],
        end: action.payload[1]
      }
    case types.CHANGE_REASON:
      return {
        ...state,
        reason: action.payload
      }
    case types.RESET:
      return initState
    case types.CHANGE_INPUT:
      return {
        ...state,
        summary: action.payload
      }
    default:
      return state
  }
}

