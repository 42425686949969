
import types from '../constants'

const INITIAL_STATE = {
  contact: null,
  providerNotesModalIsVisible: false,
  lastCallPatientId: null,
  lastCallPatientName: null,
  lastCallStartTime: null,
  lastCallEndTime: null,
  lastCallEnrolledProgramId: null,
  inboundAttributes: null,
  agentStatus: null,
  ccpStatus: null,
  minimizedNote: null,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.CONTACT_STATUS:
      return {
        ...state,
        contact: action.payload
      }
    case types.CALL_STATUS:
      return {
        ...state,
        providerNotesModalIsVisible: action.payload
      }
    case types.LAST_CALL_PATIENT_ID:
      return {
        ...state,
        lastCallPatientId: action.payload
      }
    case types.LAST_CALL_PATIENT_NAME:
      return {
        ...state,
        lastCallPatientName: action.payload
      }
    case types.LAST_CALL_START_TIME:
      return {
        ...state,
        lastCallStartTime: action.payload
      }
    case types.LAST_CALL_END_TIME:
      return {
        ...state,
        lastCallEndTime: action.payload
      }
    case types.LAST_CALL_ENROLLEDPROGRAM_ID:
      return {
        ...state,
        lastCallEnrolledProgramId: action.payload
      }
    case types.INBOUND_ATTRIBUTES:
      return {
        ...state,
        inboundAttributes: action.payload
      }
    case types.AGENT_STATUS:
      return {
        ...state,
        agentStatus: action.payload
      }
      case types.CCP_STATUS:
        return {
          ...state,
          ccpStatus: action.payload
        }
      case types.SET_MINIMIZED_NOTE:
        return {
          ...state,
          minimizedNote: action.payload,
        };
    default:
      return state;
  }
}

export default {
  AWSConnect: reducer,
};
