import React, { useMemo } from 'react';
import { Popover, Tag } from 'antd';
import _ from 'lodash';
import constant from '../constant';

export default () => {
  const levels = useMemo(() => _.map(constant.alertLevelIndicatorColorMap, (v, k) => ({
    name: _.upperFirst(k.replace('_', ' ').replace('ONE', '1').replace('TWO', '2').replace('THREE', '3').replace('FOUR', '4').toLocaleLowerCase()),
    color: v.color,
    backgroundColor: v.backgroundColor
  })), []);

  return (
    <Popover
        overlayStyle={{ width: 470 }}
        content={
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around' }}>
            {levels.map(v => (
              <Tag style={{color: '#2D3238', display: 'flex', alignItems: 'center', margin: '0 10px', paddingLeft:'10px', paddingRight: '10px', border:1, height: '28', borderRadius: 15, width: 80, backgroundColor: v.backgroundColor}}>
                <div style={{ width: 8, height: 8, borderRadius: 10, backgroundColor: v.color, display: 'inline-block', marginRight: 10 }} />
                {v.name}
              </Tag>
            ))}
          </div>
        }
        trigger="hover"
      >
        <a style={{ color: '#757980', textDecoration: 'underline dotted', fontSize: 14 }}>Alert Level Indicator</a>
      </Popover>
  );
}
