import gql from 'graphql-tag'
import {Program} from './schema/Program'

export default gql`
  query adminProgramSummary($id: EID!){
    adminProgramSummary(id: $id){
      adminProgram {
        name
      }
      memberSummary {
        nearDischarge
        discharged
        enrolled
        suspended
      }
      alertSummary {
        total
        critical
        risk
        missed
      }
    }
  }
`
