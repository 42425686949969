import React from 'react';
import { Popover } from 'antd';
import SnoozeComponent from './SnoozeComponent';

export default class SnoozePopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  handleConfirm = (...values) => {
    this.closePopover();
    this.props.onConfirm(...values);
  }

  closePopover = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  handleUnsnooze = () => {
    this.closePopover();
    this.props.onUnsnooze();
  }

  render() {
    const { value, showUnsnooze } = this.props;
    const { visible } = this.state;

    return (
      <Popover
        overlayStyle={{ width: 340 }}
        onVisibleChange={this.handleVisibleChange}
        visible={visible}
        content={<SnoozeComponent showUnsnooze={showUnsnooze} value={value} onConfirm={this.handleConfirm} onCancel={this.closePopover} onUnsnooze={this.handleUnsnooze} />}
        trigger="click"
      >
        {this.props.children}
      </Popover>
    );
  }
}
