import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, _, IHTooltip,IHButton, IHInput ,IHLoading } from 'ihcomponent';

import I18N from 'modulesAll/I18N';
import UserClass from 'modulesAll/graphql/class/User'
import { goPath } from 'libModule/utils'
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal'
import AddFoodLogNotes from "../../foodLog/components/AddFoodLogNotes";
import moment from "moment";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

export default class TaggedPatientListComponent extends React.Component {
  static displayName = 'providerTaggedPatients/components/TaggedPatientsComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  getTableProps(){

    const { onTableChange, page, showPatientStarModal, loading, curPage, handleChangePage} = this.props;
    const list = _.get(this.props.list, 'data')
    const loadObj = { spinning:loading,indicator: <IHLoading/> }

    return {
      title: `Tagged Patients (${_.get(this.props.list, 'pageInfo.total', 0)})`,
      rowKey: doc => doc.user._get('id'),
      onChange: onTableChange,
      loading:loadObj,
      dataSource : _.map(list, (l)=>{
          return {
              user : new UserClass(l.user),
              o : l
          }
      }),
      onRow(doc){
        return {
          onClick: () => {
            let id = doc.user._get('id')
            Mixpanel.calculateDuration(curPage)
            Mixpanel.track('clicked', 'patient item', 'tagged patient', {PATIENT_ID: id});
            goPath(`/patients/${doc.user._get('id')}/enrolledprogram/default`)
            Mixpanel.time_event('patient_profile')
            handleChangePage('patient_profile')
          }
        }
      },
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(this.props.list, 'pageInfo.total', 0),
        onChange:(e) => {
          Mixpanel.track('clicked', 'pager', 'tagged patient', {PAGE_NUM: e})
        }
      },
      columns : [
        {
          key : 'Star',
          width : '5%',
          render : (t, doc) => {
            const star = doc.user.format('star')
            if(star.status){
              const click = (e)=>{
                e.stopPropagation()
                showPatientStarModal(true, 'delete', doc.user.variables)
              }
              return (
                <IHTooltip overlayClassName="vsm-tooltip" title='Click to untag patient' placement="right">
                  <a onClick={click} className="v-star v-y"><i className="fa fa-star" /></a>
                </IHTooltip>
              )
            }
          }
        },
        {
          title : 'Name',
          key : 'Name',
          width : '10%',
          render : (t, doc)=>{
              return doc.user._get('profile.fullName')
          }
        },
        {
          title: 'Date Tagged',
          key: 'Date',
          width : '10%',
          render: (t, doc)=>{
            return  moment(_.get(doc, 'user.variables.flagDetails.0.createdAt')).format('MMM-DD-YYYY');
          }
        },

        {
          title : 'Reason',
          key : 'Reason',
          render : (t, doc)=>{
            return _.get(doc, 'user.variables.flagDetails.0.reason');
          }
        },
        {
          title : 'Action',
          key : 'Action',
          width:'10%',
          render: (t, doc) => {
            const buttonProps = {
              label: 'Edit',
              size: 'small',
              bsStyle: 'primary',
              onClick: (e) => {
                Mixpanel.track('clicked', 'edit', 'tagged patient', {PATIENT_ID: doc.user._get('id')});
                e.stopPropagation()
                showPatientStarModal(true, 'edit', doc.user.variables)
              },
              className: 'table-action-button'
            }
            return <IHButton {...buttonProps} />
          }
        },
        // {
        //   title: 'Notes',
        //   key: 'notes',
        //   dataIndex: 'notes',
        //   render: (notes, row) => {
        //     const buttonProps = {
        //       label: 'View',
        //       size: 'small',
        //       bsStyle: 'primary',
        //       onClick: (e) => {e.stopPropagation();console.log('clicked')},
        //       className: 'table-action-button'
        //     }
        //     return <IHButton {...buttonProps} />
        //   }
        // }
      ],
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no patient tagged!</p>
      </div>
    )

    return (
      <div className="vsm-main-page vsm-task-list-main">
        <div className="vsm-main-table">
          <div className="v-table">
            <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
          </div>
        </div>
        {<PatientStarModal refetch={async () => {
          await this.props.refetch()
          this.props.onTableChange({ pageSize: 10, current: 1 })
          // After unstar a patient, the current page might not be valid
        }} />}
      </div>
    )
  }
}

TaggedPatientListComponent.propTypes = {
  onLoadParams: PropTypes.func,
  list: PropTypes.object,
  onTableChange: PropTypes.func,
  page: PropTypes.object,
  showPatientStarModal: PropTypes.func
}
