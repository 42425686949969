import type from '../constants'

const initialState = {
  PatientStarModal : {
    type : 'add',
    show : false,
    user : {}
  }
}

const reducer = (state=initialState, action)=>{
  switch(action.type){
    case type.PATIENT_STAR_MODAL:
      return {
        ...state,
        PatientStarModal : action.PatientStarModal
      }
    default:
      return state
  }
}

export default{
  common : reducer
}
