import React from 'react';
import helper from '../../helper'
import { Form, Button, message, Checkbox } from 'antd';
import API from '../../API';
import query from "../../query";
import {compose, graphql} from "react-apollo";

const { renderFormByKey } = helper;

let ClinicCreateComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            creatingOrg: false,
            isDemo: false
        }
    }

    onChange = (e) => {
        let isChecked = e.target.checked
        this.setState({
            isDemo: isChecked
        })
    }

    createOrg = () => {
        const { setShowCreateClinicModal, refetchOrgList, refetchOrg} = this.props;
        const { validateFields } = this.props.form;
        validateFields((err,res) => {
            if(!err) {
                let { internalName, businessName, description, timeZone, areaBelong} = res;
                this.setState({
                    creatingOrg: true
                })
                const variables = {
                    name: businessName ? businessName : ' ',
                    internalName: internalName, 
                    timezone: timeZone,
                    parentId: areaBelong, 
                    description,
                    excludeBilling: this.state.isDemo,
                    identifier: internalName
                }
                API.createClinic(variables)
                .then(res=>{
                  message.info('Clinic Created!')
                  refetchOrgList && refetchOrgList();
                  refetchOrg && refetchOrg();
                  setShowCreateClinicModal(false);
                })
                .catch(e=>{
                  console.log(e);
                  message.error(e.message);
                })
            }
        })
    }

    render() {
        const localThis = this;
        const { orgList } = this.props
        if(orgList === []) {
            this.props.orgsLoading
        }
        return<div>
                { renderFormByKey(localThis, 'createClinicForm') }
                <div className={'createAreaButtonWrapper'}>
                    <Checkbox onChange={this.onChange}>This is a demo clinic</Checkbox>
                    <Button style={{ float: 'right' }} onClick={this.createOrg} type={'primary'}>Create</Button>
                </div>
              </div>
    }
};

ClinicCreateComponent = Form.create({})(ClinicCreateComponent);
const areaList = graphql(query.organizationList,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const parentId = _.get(currentUser,'selectedRole.organization.id');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                leafOnly: false,
                parentId
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organizationList,loading,refetch } = data;
        return {
            orgList: _.get(organizationList,'data',[]),
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})

export default compose(areaList)(ClinicCreateComponent);
