import { React, _ } from 'ihcomponent';
import AssessmentFormComponent from 'modulesAll/assessment/edit/components/AssessmentFormComponent';
import QuestionContainer from 'modulesAll/assessment/edit/containers/QuestionContainer';

// extends assessment/AssessmentFormComponent
export default class extends AssessmentFormComponent {
  renderSavedQuestions() {
    const { questions } = this.props.fields;
    const savedQuestions = [];
    let questionNo = 0
    /* Need to preserve the index since we use them as key for React list */
    questions.forEach((v, i) => { if (v._status === 'SAVED') savedQuestions[i] = v; });

    if (savedQuestions.length) {
      return (
        <div>
          <h5>Survey</h5>
          <div className="saved-wrap">
            {savedQuestions.map((question, index) => (
              <QuestionContainer question={question} key={index} index={index} questionNo={questionNo++} />
            ))}
          </div>
        </div>
      );
    }
  }

  render() {
    return super.render(false);
  }

}
