import React from 'react';
import StreamPlayer from 'agora-stream-player'
import  AgoraRTC  from 'agora-rtc-sdk'
import enhanceAgoraRTC from "agoran-awe";
import helper from '../helper';
import { Modal } from 'antd';
import  VConsole  from  'vconsole'
import {goPath, GQLHelper} from "../../../lib/utils";
import API from "../API";
import {message} from "antd/lib/index";
let AgoraRTClient = enhanceAgoraRTC(AgoraRTC);

// let vConsole = new VConsole();

const AgoraPatientVideoComponent = class extends React.Component{
    constructor(){
        super();
        this.state = {
            stream: null,
            client: null,
            localStream:[],
            remoteStream:[],
            speaker: null,
            soundLevel: null,
            localAudio: true,
            cameras:null,
            mainStreamIndex: 0,
            audienceMap:{ },
            innerHeight: null,
            remoteAudio: false,
            shouldShowOpenAudio: false
        }
    }


    componentWillUnmount(){
        const { client } = this.state;
        if(client){
            client.leave();
        }
    }

    async componentDidMount() {
        let { props: { params } } = this;
        const { state : { remoteAudio,remoteStream,shouldShowOpenAudio} } = this;
        const client = await AgoraRTClient.createClient({ mode: 'live', codec: 'vp8'});
        const stream = await helper.joinOneClickVideo(client,params,this);
        const cameras = await client.getCameras();

        this.setState({
            stream,
            client,
            cameras,
            currentCamera: cameras[0]
        });
        // const firstRemoteStream = _.first(remoteStream);
        // if(shouldShowOpenAudio&&firstRemoteStream) {
        //     this.setState({
        //         shouldShowOpenAudio:false,
        //     },()=>{
        //         const firstRemoteStreamId = firstRemoteStream.getId();
        //         firstRemoteStream.play(`agora--player__${firstRemoteStreamId}`, {muted: true});
        //         Modal.info({
        //             title: 'Tap on the button below to open your speaker.',
        //             onOk: () => {
        //                 if (firstRemoteStream.isPlaying()) {
        //                     firstRemoteStream.stop();
        //                 }
        //                 firstRemoteStream.play(`agora--player__${firstRemoteStreamId}`, {muted: false}, () => {
        //                     this.setState({
        //                         remoteAudio: true,
        //                         shouldShowOpenAudio:false
        //                     })
        //                 });
        //                 resolve();
        //             },
        //             okText: 'Open Speaker'
        //         });
        //     })
            // const firstRemoteStreamId = firstRemoteStream.getId();
            // firstRemoteStream.play(`agora--player__${firstRemoteStreamId}`, {muted: true});
            // Modal.info({
            //     title: 'Tap on the button below to open your speaker.',
            //     onOk: () => {
            //         if (firstRemoteStream.isPlaying()) {
            //             firstRemoteStream.stop();
            //         }
            //         firstRemoteStream.play(`agora--player__${firstRemoteStreamId}`, {muted: false}, () => {
            //             this.setState({
            //                 remoteAudio: true
            //             })
            //         });
            //         resolve();
            //     },
            //     okText: 'Open Speaker'
            // });
        // }
        // let vh = window.innerHeight * 0.01;
        // document.documentElement.style.setProperty('--vh', `${vh}px`);

    }

    chromeActions = (remoteStream,shouldShowOpenAudio)=>{
        const latestRemoteStream = _.last(remoteStream);
        let latestRemoteStreamId = null;
        let domCreated = false;
        console.log(latestRemoteStream);
        if(latestRemoteStream){
            latestRemoteStreamId = latestRemoteStream.getId();
            console.log('#agora--player__'+`${latestRemoteStreamId}`);
            domCreated = $('#agora--player__'+`${latestRemoteStreamId}`);
        }
        console.log('domCreated',domCreated);
        if(shouldShowOpenAudio&&latestRemoteStream&&domCreated) {
            this.setState({
                shouldShowOpenAudio:false,
            },()=>{
                const latestRemoteStreamId = latestRemoteStream.getId();
                latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: true});
                Modal.info({
                    title: 'Tap on the button below to open your speaker.',
                    onOk: () => {
                        if (latestRemoteStream.isPlaying()) {
                            latestRemoteStream.stop();
                        }
                        latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: false}, () => {
                            this.setState({
                                remoteAudio: true,
                                shouldShowOpenAudio:false
                            })
                        });
                    },
                    okText: 'Open Speaker'
                });
            })
            // latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: true});
            // if (latestRemoteStream.isPlaying()) {
            //     latestRemoteStream.stop();
            // }
            // latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: false}, () => {
            //     this.setState({
            //         remoteAudio: true,
            //         shouldShowOpenAudio:false
            //     })
            // });
        }

    }

    safariActions = (remoteStream,shouldShowOpenAudio)=>{
        const latestRemoteStream = _.last(remoteStream);
        if(shouldShowOpenAudio&&latestRemoteStream) {
            this.setState({
                shouldShowOpenAudio:false,
            },()=>{
                const latestRemoteStreamId = latestRemoteStream.getId();
                latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: true});
                Modal.info({
                    title: 'Tap on the button below to open your speaker.',
                    onOk: () => {
                        if (latestRemoteStream.isPlaying()) {
                            latestRemoteStream.stop();
                        }
                        latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: false}, () => {
                            this.setState({
                                remoteAudio: true,
                                shouldShowOpenAudio:false
                            })
                        });
                    },
                    okText: 'Open Speaker'
                });
            })
        }
    }
    async componentDidUpdate(prevProps){
        const { state,props } = this;
        const { visitId } = props;
        const { audienceMap,stream,innerHeight,remoteStream,shouldShowOpenAudio } = state;
        // const preRemoteStream = _.get(prevProps,'remoteStream');
        // const firstPreRemoteStream = _.first(preRemoteStream);
        if(stream&&!audienceMap[stream.getId()]) {
            await helper.setAudienceMap(stream.getId(),visitId, this);
        }

        if(remoteStream){
            _.forEach(remoteStream,stream=>{
                if(stream&&!audienceMap[stream.getId()]){
                    (async()=> {
                        await helper.setAudienceMap(stream.getId(), visitId, this);
                    })()
                }
            })
        }

        const newInnerHeight = window.innerHeight;
        if(!innerHeight||(innerHeight!=newInnerHeight)){
            this.setState({
                innerHeight:newInnerHeight
            },()=>{
                document.documentElement.style.setProperty('--vh', `${newInnerHeight/100}px`);
            })
        }

        if(shouldShowOpenAudio) {
            let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            isSafari ? this.safariActions(remoteStream, shouldShowOpenAudio) : this.chromeActions(remoteStream,shouldShowOpenAudio);
        }
        //if the remote Audio is still not enabled
        // const latestRemoteStream = _.last(remoteStream);
        // if(shouldShowOpenAudio&&latestRemoteStream) {
        //     this.setState({
        //         shouldShowOpenAudio:false,
        //     },()=>{
        //         const latestRemoteStreamId = latestRemoteStream.getId();
        //         latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: true});
        //         Modal.info({
        //             title: 'Tap on the button below to open your speaker.',
        //             onOk: () => {
        //                 if (latestRemoteStream.isPlaying()) {
        //                     latestRemoteStream.stop();
        //                 }
        //                 latestRemoteStream.play(`agora--player__${latestRemoteStreamId}`, {muted: false}, () => {
        //                     this.setState({
        //                         remoteAudio: true,
        //                         shouldShowOpenAudio:false
        //                     })
        //                 });
        //             },
        //             okText: 'Open Speaker'
        //         });
        //     })
        // }
    }

    renderLocalStream = ()=>{
        const { stream,remoteStream,speaker,mainStreamIndex,audienceMap,localAudio } = this.state;
        if(!stream){
            return;
        }
        if(remoteStream.length<=1) {
            return stream && <StreamPlayer stream={stream}
                                           className='localStream'
                                           key={stream.getId()}
                                           audio={ localAudio }
                             />
        }
        let top = 0;
        let remoteCount = 0;
        if(remoteStream.length>1) {
            let newLocalStreamArray = remoteStream.slice();
            if(stream) {
                const index = _.findIndex(remoteStream,s=>s.getId() == stream.getId());
                if(index==-1){
                    newLocalStreamArray.push(stream);
                }
            }

            return newLocalStreamArray.map((stm, i) => {
                    const isLocalStream = stm.getId() ===  stream.getId();
                    if(speaker&&speaker==stm.getId()){
                        return;
                    }
                    if(!speaker&&_.last(remoteStream).getId()==stm.getId()){
                        return;
                    }
                    remoteCount++;


                    return   <StreamPlayer
                        className='localStream'
                        key={stm.getId()+i}
                        stream={stm}
                        audio={ isLocalStream ? localAudio : true}
                        label={audienceMap[stm.getId()]||'Unknown User'}
                        style={{top: top + 100 * (remoteCount-1)}}
                        speaking={speaker == stm.getId()}
                    />
            })
        }
    }
    waitingForProvider = ()=>{
        const fontStyle = {
            color: '#FFFFFF',
            fontSize: 16,
            fontWeight: 'bold',
            letterSpacing: 0,
            lineHeight: '19px',
            width:'80%',
            // marginTop:'60%'
        }

        const divStyle = {
            height:'inherit',
            display:'flex',
            justifyContent:'center',
            backgroundColor:'#686868',
            alignItems:'center',
            textAlign:'center'
        }
        return <div style={ divStyle} >
            <span style={fontStyle}>Clinic member will join you soon, please wait…</span>
        </div>
    }

    renderMainStream = ()=>{
        const { waitingForProvider } = this;
        const { remoteStream,mainStream,speaker,audienceMap,stream }  = this.state;
        if(remoteStream.length==0){
            return waitingForProvider();
        }
        if(remoteStream.length==1){
            let speakerStream = _.first(remoteStream);
            if(speakerStream) {
                return <StreamPlayer
                    className='remoteStream'
                    key={speakerStream.getId()}
                    stream={speakerStream}
                    fit="contain"
                    label={audienceMap[speakerStream.getId()] || 'Unknown user'}
                    // speaking={speaker == speakerStream.getId()}
                />
            }
        }
        if(remoteStream.length){
            // if remote stream has no speaker, get first remote Stream;
            // if remote stream has speaker, get speaker stream;
            let speakerStream = speaker ? _.find(remoteStream,s=>s.getId()==speaker) : _.last(remoteStream);
            if(speakerStream) {
                return <StreamPlayer
                    className='remoteStream'
                    key={speakerStream.getId()}
                    stream={speakerStream}
                    fit="contain"
                    label={audienceMap[speakerStream.getId()] || 'Unknown User'}
                    speaking={speaker == speakerStream.getId()}
                />
            }
        }
    }

    renderMuteDiv = ()=>{
        const { setLocalAudio } = helper;
        const { stream,remoteStream,speaker,localAudio,client } = this.state;
        const audioStatus = localAudio ? 'mute':'unmute';
        const muteDivConfig = {
            mute:{
                width: 60,
                src:'mute_button_pt.png'
            },
            unmute:{
                width: 60,
                src:'unmute_button_pt.png'
            }
        }[audioStatus];

        const muteDiv = <div style={{
            float: 'right',
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width:'fit-content',
            cursor:'pointer'
        }} onClick={()=>setLocalAudio(this)}>
                <img src={`/image/${muteDivConfig.src}`}
                     width={muteDivConfig.width}
                     style={{ pointerEvents:'none' }}
                />
        </div>;

        return muteDiv;
    }

    renderRevertCamera = ()=>{
        return <button style={{
                        float: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        marginRight: 10,
                        cursor:'pointer',
                        width:60,
                        height:60,
                        backgroundColor:'#9B9EB9',
                        borderRadius:'50%',
                        border:'unset'
                    }}
                    onClick={()=>helper.switchDevice(this)}
                    className='revertCameraButton'
                    onTouchStart={()=>{}}
                >
                <img className='agoraLeaveButton'
                     width={30}
                     src="/image/revert_camera.png"
                     style={{ pointerEvents:'none' }}

                />
               </button>
    }

    renderLeaveButton = ()=>{
        const { state } = this;
        const { client } = state;
        const leaveEvent = ()=>{
            const eventType = 'CHECK_OUT';
            const { props:{ visitId } } = this;
            API.activePatientMutate({
                eventType,
                visitId
            }).then(res => {
                goPath('/agoravideo/bye');
            })
            .catch(error=>{
                    console.log(error);
                    message.error(GQLHelper.formatError(error))
            })
        };
        return <div style={{
                                float: 'left',
                                height: 'inherit',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor:'pointer'

                         }}
                    onClick={()=>helper.handleLeaveOneClickVideo(this,client,leaveEvent)}

                >
                    <img className='agoraLeaveButton'
                         width={60}
                         src="/image/leave_phone.png"
                         style={{ pointerEvents:'none' }}

                    />
               </div>
    }

    renderStreamBottomBar = ()=>{
        const {renderMuteDiv, renderLeaveButton,state,renderRevertCamera } = this;
        const { stream } = state;

        return stream&&<div className='localStreamBottomBar'>
            <div style={{ height:'inherit',paddingLeft:20,paddingRight:20,display: 'flex', justifyContent: 'space-evenly' }}>
                { renderRevertCamera() }
                { renderLeaveButton() }
                { renderMuteDiv() }

            </div>
        </div>
    }


    render(){
        const { renderLocalStream,renderMainStream,renderStreamBottomBar } = this;
        return <div style={{ display:'flex',flexDirection:'column' }} className='agoraPatient'>
            { renderMainStream() }
            { renderLocalStream() }
           { renderStreamBottomBar() }
        </div>
    }
}

export default (AgoraPatientVideoComponent);
