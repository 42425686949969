import gql from 'graphql-tag';

export const visitListInCalendarQuery = gql`
query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
  visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
      data{    
        id
        assignees{
          assignedToRD{
              providerId,name
          }
          assignedToCA{
              providerId,name
          }
          assignedToHC{
            providerId,name
          }
        }
        member{
          id
          profile{
            fullName
            gender
            
            ...on MemberProfile{
              birthday
              lastMeasuredAt
            }
          }
        }
        type
        confirm
        reason
        category
        appointmentAt
        appointmentTo
        checkinAt
        checkoutAt
        visitRoles
        shouldSeeDoctor  
   }
  }
}
`;
