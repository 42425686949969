import React from 'react';
import { Spin } from 'antd';

import moment from 'moment';
import { Chart } from 'chart.js';
import { getNewEnrolledPatientsWeekly } from '../API';
import { tooltipStyle, fontSize, chartBarColor } from '../util/chartStyle';

const DATE_FORMAT = 'MMM Do, YYYY';
const CANVAS_ID = 'bar-chart-weekly-enrollment';

class WeeklyEnrollments extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: undefined,
      loading: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    this.getData(organizationName, organizationId, startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    if (prevState.data !== this.state.data) {
      const data = this.state.data.values || [];
      if (this.chart === null) {
        this.createChart(data);
      } else {
        this.updateData(data);
      }
    }
    if (
      prevProps.organizationName !== organizationName 
      || prevProps.startDate !== startDate 
      || prevProps.endDate !== endDate 
      || prevProps.organizationId !== organizationId
    ) {
      this.getData(organizationName, organizationId, startDate, endDate, true);
    }
  }

  calculateYMax = (data, tick) => {
    const yMax = data.reduce((max, { yvalue }) => Math.max(max, yvalue), 0) + tick; 
    return yMax - (yMax % tick);
  }

  getChartData = (rawData) => {
    const labels = rawData.map(({ xvalue }) => moment(xvalue).format(DATE_FORMAT));
    const data = rawData.map(({ yvalue }) => yvalue);
    const backgroundColor = rawData.map(v => chartBarColor);

    return { labels, data, backgroundColor };
  }

  updateData(data) {
    const chartData = this.getChartData(data);
    this.chart.data.labels = chartData.labels;
    this.chart.data.datasets[0].data = chartData.data;
    this.chart.data.datasets[0].backgroundColor = chartData.backgroundColor;
    this.chart.update();
  }

  createChart = (data) => {
    const chartData = this.getChartData(data);
    const yMax = this.calculateYMax(data, 10);

    this.chart = new Chart(document.getElementById(CANVAS_ID), {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: [
          {
            label: "Enrolled Patients",
            backgroundColor: chartData.backgroundColor,
            data: chartData.data
          }
        ]
      },
      options: {
        legend: { display: false },
        title: {
          display: true,
          text: 'Weekly New Enrolled Patients Snapshot',
          fontSize,
        },
        tooltips: {
          ...tooltipStyle,
          callbacks: {
            title: function([{ xLabel }]) {
              const start = moment(xLabel, DATE_FORMAT);
              const end = start.clone().add(6, 'day');
              let tooltip = '';
              if (start.year() !== end.year()) {
                tooltip = start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT);
              } else if (start.month() !== end.month()) {
                tooltip = start.format('MMM Do') + ' - ' + end.format('MMM Do') + ', ' + start.format('YYYY');
              } else {
                tooltip = start.format('MMM DD') + ' - ' + end.format('Do') + ', ' + start.format('YYYY');
              }
              return tooltip;
            }
          },
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              fontSize,
              display: true,
              labelString: 'Enrolled Patients'
            },
            ticks: {
              display: true,
              beginAtZero: true,
              suggestedMax: yMax,
              fontSize,
              callback: function(label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              fontSize,
              display: true,
              labelString: 'Start Date (WEEK)',
            },
            ticks: {
              fontSize,
            }
          }]
        }
      }
    });
  }

  getData = async (organizationName, organizationId, startDate, endDate, isUpdating = false) => {
    this.setState({ loading: isUpdating ? false : true, isUpdating });
    try {
      const data = await getNewEnrolledPatientsWeekly({ organizationName, organizationId, startDate, endDate });
      this.setState({ loading: false, data, isUpdating: false });
    } catch (err) {
      this.setState({ loading: false, isUpdating: false });
      this.props.openErrorModal(err.message || 'Something went wrong fetching chart data!')
    }
  }

  render() {
    const { loading, isUpdating } = this.state;

    return (
      <div>
        {loading && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {isUpdating && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {!loading && <canvas id={CANVAS_ID} />}
      </div>
    );
  }
}

export default WeeklyEnrollments;