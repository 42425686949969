import {graphql} from "react-apollo";
import foodLogUnreadList from "graphqlModule/foodLogUnreadList";
import {FOODLOG_COUNT_MAX, ROLE_MAP} from "../../../lib/constants";
import {
    unresolvedTaskAssignmentsByPatientId,
    unresolvedSmartAlertsQuery,
    miniUserAPIQuery,
    setCoveredOrgsMutation,
    setSelectedOrgsMutation,
    missingInfoQuery,
    editMissingInfoMutation,
    providerBillableTimeQuery,
    missingInfoListQuery,
    muteMissingInfoMutation,
    getNeedSignOffPatientIdsQuery,
    getUnreadSignOffTaskIdsQuery,
    getNeedSignOffPatientsQuery,
    getUnreadSignOffCommentsQuery,
    getPatientSignOffTasksQuery,
    getSignOffTaskQuery,
    signOffByMentorMutation,
    readSignOffCommentMutation,
    getTaskOrgIdByPatientIdQuery,
    getTaskOrgIdByTaskIdQuery,
    createMonthlySummaryReportMutation,
    futureFollowUpVisitCountQuery,
} from '../query';
import chooseLoginOrganization from 'modulesAll/graphql/mutation/chooseLoginOrganization';
import Client from 'libModule/gqlClient';
import config from 'libModule/config';
import moment from 'moment';
import { enrolledProgramListForWorkstation } from "../../graphql/enrolledProgramList";
import goalAssessmentHelper from '../helper/goalAssessmentHelper';
import { decryptRole } from '../../../lib/utils';
import { branch, compose } from 'recompose';

const endpoint = config.WORK_STATION_URL;


const sessionToken = (()=>{
    const sessionToken = sessionStorage.getItem('authToken');
    return sessionToken
})
const getFetch = async ({api}) =>{
    const response = await fetch(`${endpoint}${api}`,{
        method: 'GET',
        headers: { 'x-session-token': sessionToken() },
    })
   return response.json();
}
const putMethod = async ({api,value,query})=>{
    const body = {
        "patientId":value
    }
    const response = await fetch(`${endpoint}${api}${ query ? query :''}`,{
        method: 'PUT',
        headers: { 'x-session-token':sessionToken(),
                   'cache-control': 'no-cache',
                    Connection: 'keep-alive',
                   'Accept-Encoding': 'gzip, deflate',
                    Host: 'dev-workstation.ihealth-eng.com',
                    Accept: '*/*',
                    'content-type': 'application/json; charset=utf-8'
        },
        body:JSON.stringify(body)
    })
    return response.json();
}
 export const top = async ()=> {
  return await getFetch({api:'patients/top'});
 }
export const reports = async ()=>{
   return await getFetch({api:'reports'})
}
export const pinnedBy = async (memberId)=>{
    return await getFetch({ api:`pinnedBy?patientId=${memberId}`});
        // ,value:memberId, query:`?patientId=${memberId}`})
}
export const pinnedPatients = async ()=>{
    return await getFetch({ api:'reports/pinned'})
}
export const patientCount = async () => {
  return await getFetch({ api: 'patientCount' });
}
export const pin = async(memberId)=>{
    return await putMethod({api:`pin`,value:memberId})
}
export const work = async(memberId)=>{
    return await putMethod({api:`work`,value:memberId})
}
export const unpin = async(memberId)=>{
    return await putMethod({api:`unpin`,value:memberId})
}

export const postpone = async (memberId)=>{
    return await putMethod({api:'postpone',value:memberId })
}
export const workers = async (memberId)=>{
    return await getFetch({ api:`workers?patientId=${memberId}`});
        // putMethod({api:'workers',value:memberId, query:`?patientId=${memberId}`})
}
export const setData = (api,cb,setLoading,key)=>{
    if(api && Object.prototype.toString.call(api) === "[object Promise]"){
        setLoading(key,true)
        api.then(res=> {
            cb(res);
        });
    }
}
const DAYS_RANGE = 7;
export const unratedFoodLogs = graphql(foodLogUnreadList, {
    options: ownProps => ({
        variables: {
            count: FOODLOG_COUNT_MAX,
            filters: {
                reviewed: false,
                fromDate: moment().startOf('day').add(-7, 'day').valueOf(),
                memberId: ownProps.params.id
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        return {
            unratedFoodLogs: _.get(data, 'foodLogList.pageInfo.total', 0),
            loadingUnresolvedFoodLogs: data.loading,
            refetchUnratedFoodLogsCount: data.refetch
        }
    }
});

export const unresolvedTasks = graphql(unresolvedTaskAssignmentsByPatientId,{
    options:ownProps=>({
        variables:{
                memberId: ownProps.params.id,
                status: ['OPEN']
        },
        fetchPolicy:'network-only',
    }),
    props:({ ownProps, data })=>{
        const { getTaskAssignmentsByPatientId, loading, error, refetch } = data;

        return {
            unresolvedTasks: _.get(getTaskAssignmentsByPatientId, 'taskAssignments',[]).length,
            loadingUnresolvedTasks: loading,
            refetchUnresolvedTasksCount: refetch
        }
    }
});
export const unresolvedSmartAlerts = graphql(unresolvedSmartAlertsQuery,{
    options:ownProps=>({
        variables:{
            filters: {
                memberId: ownProps.params.id,
                status: 'TODO'
            }
        },
        fetchPolicy:'network-only',
    }),
    props:({ ownProps, data })=>{
        const { patientSmartAlertList, loading, error, refetch } = data;
        return {
            unresolvedAlerts: _.get(patientSmartAlertList, 'pageInfo.total',0),
            loadingUnresolvedAlerts: loading,
            refetchUnresolvedAlertCount: refetch
        }
    }
});
export const missingInfoAPI  = graphql(missingInfoQuery,{
    options:ownProps=>({
        variables:{
            id: ownProps.params.id,
        },
        fetchPolicy:'network-only',
    }),
    props:({ownProps,data})=>{
        const {getMissingInfo ,loading } = data;
        return {
            missingInfo:getMissingInfo,
            loadingUser: loading
        }
    }
});
export const missingInfoListAPI  = graphql(missingInfoListQuery,{
    options:ownProps=>({
        variables:{
            memberId: ownProps.params.id,
        },
        fetchPolicy:'network-only',
    }),
    props:({ownProps,data})=>{
        const {getMissingInfoList ,loading,refetch } = data;
        return {
            missingInfo:getMissingInfoList,
            refetchMissingInfo:refetch,
            loadingUser: loading
        }
    }
})
export const miniUserAPI = graphql(miniUserAPIQuery,{
    options:ownProps=>({
        variables:{
                id: ownProps.params.id,
        },
        fetchPolicy:'network-only',
    }),
    props:({ownProps,data})=>{
        const { user,loading,refetch } = data;
        return {
            user,
            refetchMiniUser:refetch,
            loadingUser: loading
        }
    }
})

export const resolveMissingInfoAPI = (variables)=>Client.mutate({
    mutation:editMissingInfoMutation,
    variables:{
        ...variables,
        status:'DONE'
    }

})
export const setCoveredOrgs =  (variables)=>Client.mutate({
    mutation: setCoveredOrgsMutation,
    variables
});
export const setSelectedOrgs = (variables)=>Client.mutate({
    mutation: setSelectedOrgsMutation,
    variables
})
export const switchOrg = (organizationId)=> {
    const authAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
    const selectedOrg = _.first(_.filter(authAllRoles,role=>role.organization.id == organizationId));
    const { refId } = selectedOrg || {};
    return Client.mutate({
        mutation: chooseLoginOrganization,
        variables: {ref: refId},
        fetchPolicy:'no-cache'
    })
}
export const billableTimeQuery = graphql(providerBillableTimeQuery,{
    options:()=>{
        return {
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const {loading,getProviderBillableTime,refetch } = data;
        if(loading) {
            return {
                loading
            }
        }
        return {
            billableTime : getProviderBillableTime || {},
            refetchBillabelTimeToday:refetch
        }
    }
});

export const withEnrolledCurrentProgram = graphql(enrolledProgramListForWorkstation, {
    options: ownProps => {
      const { query, ...options } = goalAssessmentHelper.getEnrolledProgramListQuery(ownProps);
      return {...options};
    },
    props: ({ ownProps, data }) => {
        const mostRecentEP = _.first(_.get(data, 'enrolledProgramList.data', []));
        return { currentProgram: mostRecentEP, currentProgramLoading: data.loading, currentProgramRefetch: data.refetch };
    },
  });

export const getEnrolledCurrentProgram = (memberId) => Client.query({
    query: enrolledProgramListForWorkstation,
    variables: {
        page: 1,
        count: 1,
        filters: { memberId },
    }
}).then(({ data }) => {
    return _.first(_.get(data, 'enrolledProgramList.data', []));
});

export const muteMissingInfo = (variables)=>Client.mutate({ mutation: muteMissingInfoMutation,variables });

export const withFutureFollowUpVisitCount = graphql(futureFollowUpVisitCountQuery, {
  options: (ownProps) => {
    const memberId = (ownProps.params || {}).id;
    return {
      variables: {
        filters: {
          memberId,
          appointmentFrom: moment().startOf('minute').valueOf(),
          appointmentTo: moment().endOf('month').valueOf(),
          types: ['FOLLOW_UP'],
        },
      },
    }
  },
  props: ({data}) => {
    const { loading, visitList }  = data;

    const visitCount = _.get(visitList, 'pageInfo.total') || 0;
    const hasFutureFollowUpVisit = visitCount > 0;

    return {
      loadingFutureFollowUpVisit: loading,
      hasFutureFollowUpVisit,
    };
  }
})


const withPatientSignOffTasks = graphql(getPatientSignOffTasksQuery, {
  options: (ownProps) => ({
    variables: { patientId: ownProps.params.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => {
    const { loading, getPatientSignOffTasks, refetch } = data;

    return {
      loadingTask: loading,
      patientTask: getPatientSignOffTasks || {},
      refetchPatientTasks: refetch,
    }
  }
});

const withSignOffTask = graphql(getSignOffTaskQuery, {
  options: (ownProps) => {
    const taskId = ownProps.updatedTaskId || window.signOffCursor.getTaskIdByCursor();
    return {
      variables: { id: taskId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getSignOffTask, refetch } = data;

    return {
      loadingTask: loading,
      patientTask: getSignOffTask || {},
      refetchPatientTasks: refetch,
    }
  }
});

const composeQueryBasedOnRole = (rdGQL, hcGQL) => branch(
  () => ROLE_MAP[decryptRole()] === 'RD',
  compose(rdGQL),
  branch(
    () => ROLE_MAP[decryptRole()] === 'HC',
    compose(hcGQL)
  )
);

const getQueryBasedOnRole = (rdQuery, hcQuery) => {
  const currentRoleName = ROLE_MAP[decryptRole()];
  return {
    RD: rdQuery,
    HC: hcQuery,
  }[currentRoleName];
};

const getSignOffSummaryVariablesBasedOnRole = () => {
  const currentRoleName = ROLE_MAP[decryptRole()];
  const currentUserId = sessionStorage.getItem('currentUserId');
  return {
    RD: { mentorId: currentUserId },
    HC: { healthCoachId: currentUserId },
  }[currentRoleName] || {};
};

const signOffSummaryGQLOptions = ({
  options: () => ({
    variables: getSignOffSummaryVariablesBasedOnRole(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy:'network-only',
  }),
  props: ({ data }) => {
    const mentor =  _.get(data, 'getUnreadSignOffComments.mentor') || {};
    const mentee =  _.get(data, 'getNeedSignOffPatients.healthCoach') || {};
    const cases = window.signOffCursor.getAndUpdateCasesFromData(data);
    const patientCount = _.get(data, 'getUnreadSignOffComments.count');

    return {
      loadingSignOffSummary: data.loading,
      mentor,
      mentee,
      totalCaseCount: cases.length,
      patientCount,
      refetchSummary: data.refetch
    };
  }
});

const signOffCountSummaryGQLOptions = ({
  options: () =>({
    variables: getSignOffSummaryVariablesBasedOnRole(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy:'network-only',
  }),
  props:({data})=>{
    const { loading, refetch } = data;
    const cases = window.signOffCursor.getAndUpdateCasesFromData(data);

    return {
      loadingPatientIds: loading,
      cases,
      totalCaseCount: cases.length,
      refetchPatientIds: refetch
    };
  }
});

export const withSignOffTasks = composeQueryBasedOnRole(withPatientSignOffTasks, withSignOffTask);

const withSignOffSummaryForRD = graphql(getNeedSignOffPatientsQuery, signOffSummaryGQLOptions);
const withSignOffSummaryForHC = graphql(getUnreadSignOffCommentsQuery, signOffSummaryGQLOptions);
export const withSignOffSummary = composeQueryBasedOnRole(withSignOffSummaryForRD, withSignOffSummaryForHC);


const withSignOffCaseCountForRD = graphql(getNeedSignOffPatientIdsQuery, signOffCountSummaryGQLOptions);
const withSignOffCaseCountForHC = graphql(getUnreadSignOffTaskIdsQuery, signOffCountSummaryGQLOptions);
export const withSignOffCaseCount = composeQueryBasedOnRole(withSignOffCaseCountForRD, withSignOffCaseCountForHC);

export const getSignOffCaseCount = (options) => Client.query({
  query: getQueryBasedOnRole(getNeedSignOffPatientIdsQuery, getUnreadSignOffTaskIdsQuery),
  variables: getSignOffSummaryVariablesBasedOnRole(),
  fetchPolicy: 'network-only',
  ...options
});
export const getTaskOrgIdByPatientId = (variables, options = {}) => Client.query({ query: getTaskOrgIdByPatientIdQuery, variables, fetchPolicy: 'network-only', ...options });
export const getTaskOrgIdByTaskId = (variables, options = {}) => Client.query({ query: getTaskOrgIdByTaskIdQuery, variables, fetchPolicy: 'network-only', ...options });
export const signOffByMentor = (variables, options = {}) => Client.mutate({ mutation: signOffByMentorMutation, variables, fetchPolicy: 'no-cache', ...options });
export const readSignOffComment = (variables, options = {}) => Client.mutate({ mutation: readSignOffCommentMutation, variables, fetchPolicy: 'no-cache', options });
export const createMonthlySummaryReport = (variables,options={})=>Client.mutate({mutation: createMonthlySummaryReportMutation,variables,...options});
