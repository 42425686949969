import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import ArticleSearchComponent from '../component/ArticlesSearch/ArticlesSearchComponent';
import useArticleCategories from '../helpers/useArticleCategories';
import usePatientArticles from '../helpers/usePatientArticles';
import useSendAndSaveArticles from '../helpers/useSendAndSaveArticles';
import { EVENT_TYPES } from '../../idleTime/constant';
import { WEBFLOW_LANGUAGE_MAP } from '../../../lib/constants';
import ArticleSendingHistoryContainer from './ArticleSendingHistoryContainer';

import './ArticlesContainer.scss';

const ArticlesContainer = ({
  patientId,
  patientLanguageSettings,
  disabled,
  onInteraction,
}) => {
  const { articleCategories, isLoadingArticleCategories } = useArticleCategories();
  const { patientArticles, refetchPatientArticles, isLoadingPatientArticles } = usePatientArticles(
    patientId,
  );
  const { sendAndSaveArticles } = useSendAndSaveArticles({ patientId });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoading = !!isLoadingArticleCategories || !!isLoadingPatientArticles || isSubmitting;
  const patientArticlesObject = useMemo(() => _.keyBy(patientArticles, 'articleId'), [patientArticles]);
  const preferredLanguages = useMemo(() => {
    const { language, appLanguage } = patientLanguageSettings || {};
    if (!language && !appLanguage) return null;
    const preferred = [];
    const spokenLanguageCode = _.get(language, 'code');
    const appLanguageCode = _.get(appLanguage, 'code');
    if (spokenLanguageCode) {
      preferred.push(spokenLanguageCode);
    }
    if (appLanguageCode && spokenLanguageCode !== appLanguageCode) {
      preferred.push(appLanguageCode);
    }
    return _.map(preferred, (p) => WEBFLOW_LANGUAGE_MAP[p] || p);
  }, [patientLanguageSettings]);

  const handleOnSubmit = useCallback(_.debounce(async (selectedArticles) => {
    setIsSubmitting(true);
    await sendAndSaveArticles(selectedArticles, {
      onSuccess: (__, hasSendError) => {
        const errorMessage =  hasSendError ? `, but failed for some. Please double check` : '';
        message.success(`Article(s) sent ${errorMessage}`);
        refetchPatientArticles();
        onInteraction && onInteraction(EVENT_TYPES.SEND_ARTICLE);
      },
      onError: (errorMessage) => {
        message.error(errorMessage);
      },
    });
    setIsSubmitting(false);
  }, 500), [patientId, onInteraction, sendAndSaveArticles]);

  return (
    <div className="articles-container">
      <div className="articles-container__title">
        Send Articles to Patient's App
      </div>
      <ArticleSearchComponent
        preferredLanguages={preferredLanguages}
        disabled={disabled}
        articleCategories={articleCategories}
        patientArticles={patientArticlesObject}
        isLoading={isLoading}
        onSubmit={handleOnSubmit}
      />
      <ArticleSendingHistoryContainer 
        patientArticles={patientArticles}
        disabled={disabled}
      />
    </div>
  );
};

ArticlesContainer.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientLanguageSettings: PropTypes.shape({
    language: PropTypes.object.isRequired,
    appLanguage: PropTypes.object.isRequired,
  }),
  onInteraction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
export default ArticlesContainer;