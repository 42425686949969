import Patient from '../../patient/reducers'
import { combineReducers } from 'redux'

const reducer = combineReducers({
  Patient
  //so that cater for more in the future
})

export default {
   Vendor: reducer
}
