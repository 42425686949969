import { combineReducers } from 'redux'
import Common from './CommonDuck'
import AlertForm from './AlertFormDuck'
import AlertList from './AlertListDuck'

const vitalAlert = combineReducers({
  Common,
  AlertForm,
  AlertList
})

export default vitalAlert
