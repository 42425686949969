import Todo from './schema/Todo'
import gql from 'graphql-tag'

export default gql`
  mutation createTodo(
    $description:String!,
    $priority:TodoPriority,
    $completedAt:Date,
    $dueDate:Date
  ){
    createTodo(
      description:$description,
      priority:$priority,
      completedAt:$completedAt,
      dueDate:$dueDate
    ){
      ${Todo}
    }
  }
`
