import { combineReducers } from 'redux'
import type from '../constants'
import actions from '../actions';
import {_} from 'ihcomponent';
import moment from 'moment';

const initialState = {
  SelectAlerts : {
    data : [],
    selected : ['a','a1','a2','a3','a4']
  },
  Todo : {
    modal : {
      show : false,
      select : null
    }
  },
  currentProgram : null,
  currentPeriod: 'MONTH',
  selectedTabKey: null, // select first available tab as default
  selectedRange: {},
  selectedFilters: {},
  selectedSorters: {},
  selectedPageInfo: {
    current:1
  }
}

const reducer = (state=initialState, action)=>{
  switch(action.type){
    case type.SET_SELECTALERT_LIST:
      const sa = _.merge({}, state.SelectAlerts, action.SelectAlerts)
      return {
        ...state,
        SelectAlerts : sa
      }
    case type.SET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgram : action.select
      }
    case type.SET_CURRENT_PEROID:
      return{
          ...state,
          currentPeriod:action.select,
      }
    case type.GET_MEASURE_DATA:
      return {
          ...state,
          programData: getMeasureData(action.peroid)
      }
    case type.RESET:
      return {
      ...state,
      Todo : {
        modal : {
          show : false,
          seelct : null
        }
      },
    }
    case type.SET_SELECTED_TAB_KEY:
      return { ...state, selectedTabKey: action.tab };
    case type.SET_SELECTED_RANGE:
      const selectedRange = { from: action.from, to: action.to };
      return { ...state, selectedRange };
    case type.SET_SELECTED_FILTERS:
      return { ...state, selectedFilters: action.filters };
    case type.SET_SELECTED_SORTERS:
      return { ...state, selectedSorters: action.sorters };
    case type.SET_SELECTED_PAGEINFO:
      return { ...state,selectedPageInfo: action.pageInfo }

    default:
      return state
  }
}

export default{
  dashboard : combineReducers({
    main : reducer
  })
}
