import gql from 'graphql-tag';

export default gql`
	query tempEnrolledPatientsList($page:Int, $count:Int) {
		tempEnrolledPatientsList(page: $page, count: $count) {
			data {
				id
				category
				organizationName
				firstName
				lastName
				types
				memberId
				bmi
				visitingDetail
				contact
				doctorName
				visitWindow
			}
			pageInfo{
				total
				lastPage
			}
		}
	}
`;