 const medTypes =  [
     { name: 'Oral', value: 'ORAL' },
     { name: 'External',value:'EXTERNAL'},
     { name: 'Injectable', value: 'INJECTABLE' },
     { name: 'Inhalable', value: 'INHALED' }
];

export default medTypes;
//     {
//     name: 'External',value:'EXTERNAL',
//     name: 'Oral Medication', value: 'ORAL' ,
//     name: 'Injectable', value: 'INJECTABLE',
//     name: 'Inhaleinhaled', value: 'INHALEINHALED'
// }
