import React, { useMemo } from 'react';
import { Modal, Steps } from 'antd';
import './step-modal.scss';
const { Step } = Steps;

const StepModal = (props) => {
  const { className: modalClassName, ...modalProps } = props.modalProps;
  const { setShowModal } = modalProps;
  const childrenProps = useMemo(() => {
    return React.Children.map(props.children, element => {
      if (!React.isValidElement(element)) return;
      if (element.type !== StepModal.Step) return;
      return {
        ...element.props,
        key: element.key,
      };    
    }).filter(x => x);
  }, [props.children])

  const renderSteps = () => {
    return (
      <Steps direction="vertical">
        {childrenProps.map(v =>
          <Step
            key={v.key}
            title={v.title}
            icon={v.isCompleted ? <img src={'/image/icon_check.png'} width={20} /> : <img src={'/image/Oval.png'} width={20} />}
            className={'substep' + (props.activeKey === v.key ? ' selected' : '')}
            onClick={() => props.onStepChange(v.key)}
          />
        )}
      </Steps>
    );
  };

  const renderContent = () => {
    const step = _.find(childrenProps, v => props.activeKey === v.key);
    return step ? step.children : null;
  };

  return (
    <Modal footer={null} {...modalProps} className={'step-modal ' + (modalClassName || '')} style={{ padding: 0 }}>
      <div className="step-modal-container">
        <div className="left-step-modal-container">
          {props.header && <div className="step-modal-header">{props.header}</div>}
          <div className="step-modal-steps">{renderSteps()}</div>
        </div>
        <div className="right-step-modal-container">
          {renderContent()}
        </div>
      </div>
    </Modal>
  )
};

StepModal.Step = ({ children, title, key, isCompleted }) => {
  return (
    <div className="step-modal-step-container">
      {children}
    </div>
  );
};

export default StepModal;
