// vendor

const pathStartsWith = []
const pathOnly = [
  '/patient_vendor',
  '/mytasks',
  '/myvitals',
  '/emergency_contact',
  '/myprogrammes',
]

      
export default {
  pathStartsWith,
  pathOnly
}
