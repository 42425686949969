import React, { useCallback } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { CellularDeviceBrandDropdownFormItemComponent } from '../../components/CellularDeviceDropdownFormItemComponent/CellularDeviceBrandDropdownFormItemComponent';
import { CellularDeviceIdSearchDropdownFormItemComponent } from '../../components/CellularDeviceDropdownFormItemComponent/CellularDeviceIdSearchDropdownFormItemComponent';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularDevicesAssignFormContainer = Form.create()(({
  form,
  onSubmit,
}) => {
  const handleSubmit = useCallback(_.debounce(() => {
    form.validateFieldsAndScroll((error, values) => {
      if (error) return;
      if (onSubmit) onSubmit(values);
      form.resetFields();
    });
  }, 500), []);

  const selectedBrand = form.getFieldValue(CellularDevicesHelpers.deviceBrandFieldName);

  return (
    <Form>
      <Row>
        <Col
          span={9}
        >
          <CellularDeviceBrandDropdownFormItemComponent
            form={form}
          />
        </Col>
      </Row>
      {
        selectedBrand
          ? (

            <Row>
              <Col
                span={9}
              >
                <CellularDeviceIdSearchDropdownFormItemComponent
                  form={form}
                  deviceType={CellularDevicesHelpers.BRAND_TO_DEVICE_TYPE_MAP[selectedBrand]}
                />
              </Col>
              <Col
                span={9}
                offset={1}
              >
                <Button
                  type="primary"
                  className="cellular-devices-assign-btn"
                  onClick={handleSubmit}
                >
                  Assign Device
                </Button>
              </Col>
            </Row>
          ) : null
      }
    </Form>
  );
});