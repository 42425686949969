import types from '../constants/VitalListHSConstants'
// Default State
const initState = {
  sorter: { columnKey: 'date', order: null },
  pagination: {pageSize: 5},
  view: 'List',
  range: null,
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.ON_TABLE_CHANGE:
      const { sorter, pagination } = action
      pagination.pageSize = 5 // this may change!!
      return {
        ...state,
        sorter,
        pagination,
      }
    case types.CHANGE_VIEW: {
      return {
        ...state,
        view: action.view
      }
    }
    case types.CHANGE_RANGE:
      return {
        ...state,
        range: action.range
      }
    default:
      return state
  }
}

