import React from 'react';
import { parseDigit, ReactInput, templateFormatter, templateParser } from 'input-format';
import I18N from '../../modules/I18N';

export const PHONE_TEMPLATE = '(xxx) xxx-xxxx';
export const parse = templateParser(PHONE_TEMPLATE, parseDigit);
export const format = templateFormatter(PHONE_TEMPLATE)

export const _phoneValidator = (value) => {
  if (!value) I18N.get('validator.mobile.required');
  if (!/^\d{10}$/.test(value)) {
		return I18N.get('validator.mobile.invalid');
  }
};

export const phoneValidator = (rule, v, callback) => callback(_phoneValidator(v));

export const PhoneField = ({ disabled, ...props }) => <ReactInput className='ant-input' parse={parse} format={format} disabled={disabled} onChange={() => {}} {...props} />;
