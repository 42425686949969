import React, {useState} from "react";
import { Modal,Tooltip,Icon } from "antd";

const TooltipWithModal = (props)=>{
	const [ showModal,setShowModal ] = useState(false);
	const { modalHeader ='', modalBody='',tooltipTitle=''} = props;
	const renderModal = ()=>{
		return <Modal visible={showModal} title={modalHeader} onCancel={()=>setShowModal(false)} footer={false}>
			{ modalBody }
		</Modal>
	}
	return <Tooltip title={tooltipTitle} style={{ width:'fit-content' }}>
		<Icon type="info-circle" onClick={ ()=>setShowModal(true)} style={{ marginLeft:10 }} theme={'filled'}/>
		{renderModal()}
	</Tooltip>
}
export default TooltipWithModal;