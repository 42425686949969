import { combineReducers } from 'redux'
import Common from 'ProgramEditModule/Survey/reducers/CommonReducer'
import SurveyForm from 'ProgramEditModule/Survey/reducers/FormReducer'
import SurveyList from 'ProgramEditModule/Survey/reducers/ListReducer'
import Alert from 'ProgramEditModule/SurveyAlert/reducers'

const survey = combineReducers({
  Common,
  SurveyForm,
  SurveyList,
  Alert,
})

export default survey
