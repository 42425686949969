import { graphql, withApollo, compose } from 'react-apollo';

import { editAdminProgramTask } from 'graphqlModule/mutation/editAdminProgram'
import setDevicesForAdminProgram from 'graphqlModule/setDevicesForAdminProgram'
import { removeTaskFromAdminProgram } from 'graphqlModule/mutation/removeTaskFromAdminProgram'
import { program as updateQueries } from 'graphqlModule/updateQueries/program'
import { connect } from 'react-redux'
import React, { Component } from 'react';
import { validateAntdFormWithFieldsValue } from '../../../../../lib/utils'
import { getFormProps } from 'commonModule/components/careplan/formData/VitalForm'
import actions from 'ProgramIncompleteModule/Careplan/actions/VitalFormActions'
import types from 'ProgramIncompleteModule/Careplan/constants/VitalFormConstants'
import AlertFormContainer from 'ProgramIncompleteModule/VitalAlert/containers/CommonContainer'
import get from 'lodash/get'
import { IHBaseFormWithRow, IHButton } from 'ihcomponent'
import { CareplanHelper } from '../../../../utils/helpers/task'
import careplanActions from 'ProgramIncompleteModule/Careplan/actions/CommonActions'
import alertListActions from 'ProgramIncompleteModule/VitalAlert/actions/AlertListActions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { DEVICE_VITAL_TYPE_MAP, DEFAULT_DEVICE_FOR_VITAL } from 'modulesAll/utils/constants/task'
import PropTypes from 'prop-types';
import * as R from 'ramda'
import { withProps } from 'recompose';
import { message } from 'antd';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
const trace = debug ? traceF : () => R.identity;

class Container extends Component {
  static displayName = 'program/ProgramIncomplete/Careplan/containers/VitalFormContainer'

  componentWillUnmount () {
    this.props.reset()
  }

  render() {
    const title = this.renderTitle()
    const backLink = this.renderBackLink()
    const body = this.renderBody()
    const submitBtn = this.renderSubmitBtn()
    const alertForm = this.renderAlertForm()
    return (
      <div>
        {backLink}
        {title}
        {body}
        {alertForm}
        {submitBtn}
      </div>
    )
  }

  renderAlertForm() {
    const { AlertForm } = this.props
    const alertForm = <AlertFormContainer {...AlertForm} />
    return alertForm
  }

  renderBody() {
    const { onFieldsChange, fieldsValue, VitalList, isEditMode } = this.props

    const formInfo =  { onFieldsChange, fieldsValue, VitalList, className: 'vsm-form-body v-form-required', isEditMode, self: this };
    const formProps = getFormProps(formInfo);
    const body = <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
    // for (const item in body.props.fieldsValue) {
    //     if (body.props.fieldsValue.hasOwnProperty(item)) {
    //       body.props.fieldsValue[item].errors = null;
    //     }
    // }
    return body
  }

  async submit() {
    const { fieldsValue, program, addVitalFormToList, editAdminProgramTask, setDevicesForAdminProgram, initVitalList, AlertList, isEditMode } = this.props
    // validate all fields before submission
    // console.log('formRef', this.baseForm);
    const isFormValid = await validateAntdFormWithFieldsValue(fieldsValue, this.baseForm)
    if (!isFormValid) return
    const panesFieldsValue = AlertList.panesFieldsValue
    let variables = {
      id: program.id,
      tasks: [CareplanHelper.convertVitalToDBTask(fieldsValue, panesFieldsValue)]
    }

    try {
      const res = await editAdminProgramTask({ variables, updateQueries })
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.task.vital.add'),
        details: fieldsValue,
        request: variables,
        response: res
      })
      // console.log('got data', res.data);
      initVitalList(res.data.editAdminProgram)
      addVitalFormToList(fieldsValue)
    }
    catch (err) {
      message.error(err.message);
      console.error(err);
      createAuditLog({
        action:I18N.get('auditLog.admin.programme.task.vital.add'),
        details: fieldsValue,
        request: variables,
        response: err,
        success: false
      })
    }

    if (!isEditMode) {
      const type = DEVICE_VITAL_TYPE_MAP[get(fieldsValue, 'vitalType.value')]
      const model = DEFAULT_DEVICE_FOR_VITAL[type]
      const addedDevice = {
        [type]: model
      }
      variables = {
        id: program.id,
        devices: {
          ...addedDevice
        }
      }
      try {
        await setDevicesForAdminProgram({ variables })
      }
      catch (err) {
        console.log('update device error: ', err)
      }
    }
  }

  renderBackLink(){
    return (
      <div className="v-link v-link-inside" onClick={() => this.props.changePage()}>
        <div>
          <i className="fa fa-angle-left" />
          Back to Care Plan
        </div>
      </div>
    )
  }

  renderTitle() {
    return <h3 className='vsm-form-title'>Add Vitals to Care Plan</h3>
  }

  renderSubmitBtn() {
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnRight: {
        alignSelf: 'flex-end'
      }
    }
    const addBtnProp = {
      onClick : (e)=>{
        this.submit()
      }
    }
    const btn = (
      <div style={styles.footer}>
        <div style={styles.btnRight}>
          <IHButton {...addBtnProp} bsStyle="primary" label="Save" />
        </div>
      </div>
    )
    return btn
  }
}

const mapState = ({ program }, ownProps) => R.merge({
  AlertList: get(program.ProgramIncomplete, 'Careplan.VitalAlert.AlertList'),
  AlertForm: get(program.ProgramIncomplete, 'Careplan.AlertForm'),
  VitalList: get(program.ProgramIncomplete, 'Careplan.VitalList'),
}, get(program.ProgramIncomplete, 'Careplan.VitalForm'));

const mapDispatch = (dispatch) => {
  const reset = alertListActions.reset
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields, types)),
    addVitalFormToList: (fieldsValue) => dispatch(actions.addVitalFormToList(fieldsValue)),
    initVitalList: (program) => dispatch(careplanActions.initVitalList(program)),
    reset: () => dispatch(reset()),
  }
}

Container.propTypes = {
  program: PropTypes.object,
  changePage: PropTypes.func,
  onFieldsChange: PropTypes.func,
  initVitalList: PropTypes.func,
  addVitalFormToList: PropTypes.func,
  editAdminProgramTask: PropTypes.func,
  setDevicesForAdminProgram: PropTypes.func,
  addTaskToAdminProgram: PropTypes.func,
  removeTaskFromAdminProgram: PropTypes.func,
  fieldsValue: PropTypes.object,
  AlertForm: PropTypes.object,
  VitalList: PropTypes.object,
  isEditMode: PropTypes.bool,
  reset: PropTypes.func
}

const forceSlotToWeeklyMeal = (props) => (
  R.pathEq(['fieldsValue', 'schedule', 'value', 'frequency'], 'slot', props) && R.pathEq(['fieldsValue', 'vitalType', 'value'], 'Blood Glucose', props)
    ? R.assocPath(['fieldsValue', 'schedule', 'value', 'frequency'], 'weeklyMeal', props)
    : props
  );

export default compose(
  withApollo,
  graphql(setDevicesForAdminProgram, { name: 'setDevicesForAdminProgram' }),
  graphql(editAdminProgramTask, {name: 'editAdminProgramTask'}),
  graphql(removeTaskFromAdminProgram, {name: 'removeTaskFromAdminProgram'}),
  connect(mapState, mapDispatch),
  withProps(forceSlotToWeeklyMeal),
)(Container);
