import React from 'react';
import { 
  LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip ,
  AreaChart, Area, Brush,
} from 'recharts';

import { Row, Col } from 'antd';
// eslint-disable-next-line
import { track, Chart, Geom, Axis, Tooltip as TooltipB, Coord, Label, Legend, View, Guide, Shape } from 'bizcharts';  
// eslint-disable-next-line
import DataSet from '@antv/data-set';
// Turn off G2's Experience Improvement Program RBI request (eg server rendering) BizCharts.track(false);
track( false );

const { DataView } = DataSet;

const data1 = [
  {
    "name": "Page A",
    "uv": 400,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 300,
    "pv": 4567,
    "amt": 2400
  },
  {
    "name": "Page C",
    "uv": 300,
    "pv": 1398,
    "amt": 2400
  },
  {
    "name": "Page D",
    "uv": 200,
    "pv": 9800,
    "amt": 2400
  },
  {
    "name": "Page E",
    "uv": 278,
    "pv": 3908,
    "amt": 2400
  },
  {
    "name": "Page F",
    "uv": 189,
    "pv": 4800,
    "amt": 2400
  }
]; 

const Chart2 = () => (
  <LineChart width={500} height={300} data={data1} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
  </LineChart>
);


const data2 = [
      {name: 'Page A', uv: 4000, pv: 9000},
      {name: 'Page B', uv: 3000, pv: 7222},
      {name: 'Page C', uv: 2000, pv: 6222},
      {name: 'Page D', uv: 1223, pv: 5400},
      {name: 'Page E', uv: 1890, pv: 3200},
      {name: 'Page F', uv: 2390, pv: 2500},
      {name: 'Page G', uv: 3490, pv: 1209},
];

const SimpleAreaChart = () => (
  <div>
    <h5>User Stats by Device</h5>
    <LineChart width={500} height={200} data={data2} syncId="anyId"
          margin={{top: 10, right: 30, left: 0, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Line type='monotone' dataKey='uv' stroke='#8884d8' fill='#8884d8' />
    </LineChart>
    <p>Logins by region</p>
    <LineChart width={500} height={200} data={data2} syncId="anyId"
          margin={{top: 10, right: 30, left: 0, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Line type='monotone' dataKey='pv' stroke='#82ca9d' fill='#82ca9d' />
      <Brush />
    </LineChart>
    <AreaChart width={500} height={200} data={data2} syncId="anyId"
          margin={{top: 10, right: 30, left: 0, bottom: 0}}>
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid strokeDasharray="3 3"/>
      <Tooltip/>
      <Area type='monotone' dataKey='pv' stroke='#82ca9d' fill='#82ca9d' />
    </AreaChart>
  </div>
);

// bizcharts
const data3 = [
  { value: 251, type: 'iOS', name: 'BP' },
  { value: 128, type: 'iOS', name: 'BG' },
  { value: 25, type: 'iOS', name: 'HS' },
  { value: 55, type: 'iOS', name: 'AM' },
  { value: 100, type: 'Android', name: 'BP' },
  { value: 200, type: 'Android', name: 'BG' },
  { value: 10, type: 'Android', name: 'HS' },
  { value: 20, type: 'Android', name: 'AM' },
];
const dv = new DataView({});
dv.source(data3).transform({
  type: 'percent',
  field: 'value',
  dimension: 'type',
  as: 'percent'
});
const cols = {
percent: {
  formatter: val => {
    val = (val * 100).toFixed(2) + '%';
    return val;
  }
}
}  
const dv1 = new DataView({});
dv1.source(data3).transform({
  type: 'percent',
  field: 'value',
  dimension: 'name',
  as: 'percent'
});

const Chart3 = () => (
  <Chart height={300} data={dv} scale={cols} padding={[ 10, 20, 10, 20 ]} forceFit>
    <Coord type='theta' radius={0.5} />
    <TooltipB
      showTitle={false} 
      itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      />
    <Geom
      type="intervalStack"
      position="percent"
      color='type'
      tooltip={['name*percent',(item, percent) => {
        percent = (percent * 100).toFixed(2) + '%';
        return {
          name: item,
          value: percent
        };
      }]}
      style={{lineWidth: 1,stroke: '#fff'}}
      select={false}
      >
      <Label content='type' offset={-10} />
    </Geom>
    <View data={dv1} scale={cols} >
      <Coord type='theta' radius={0.75} innerRadius={0.5 / 0.75}/>
      <Geom
      type="intervalStack"
      position="percent"
      color={['name', [ '#BAE7FF', '#7FC9FE', '#71E3E3', '#ABF5F5', '#8EE0A1', '#BAF5C4' ]]}
      tooltip={['name*percent',(item, percent) => {
        percent = (percent * 100).toFixed(2) + '%';
        return {
          name: item,
          value: percent
        };
      }]}
      style={{lineWidth: 1,stroke: '#fff'}}
      select={false}
      >
        <Label content='name'/>
      </Geom>
    </View>
  </Chart>
);

const Result = () => (
  <div className="code-box-demo" >
    <div className="chart-background" >
     <Row>
        <Col md={24} lg={12} style={{ textAlign: 'center' }}>
          <div className='chart-container' >
            <SimpleAreaChart />
           </div>
        </Col>
        <Col md={24} lg={12} style={{ textAlign: 'center' }}>
          <div className='chart-container' >
            <h5>User complaints</h5>
            <Chart2 />
          </div>
          <Chart3 />
       </Col>
      </Row>

    </div>
  </div>
);

export default Result;