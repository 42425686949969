import React from 'react';
import { constants } from '../../../../lib/utils';

export default constants('patient', 'main', [
  'SET_TABS_STEP',
  'CHANGE_SUSPEND_REASON',
  'SET_SUBMITTING',
  'RESET',
    'CARE_YEAR_CHANGE'
]);

export const mappingDeviceModels = {
  Ease: 'Ease (BP3L)',
  Align: 'Align (BG1)',
  Lina: 'Lina (HS2)',
  Thermometer: 'Thermometer',
  Oximeter: 'Oximeter',
  iPhone: 'iPhone',
  TrackSet: 'iHealth Clear',
  BG5S: 'Gluco+ (BG5S)',
  Nexus: 'Nexus (HS2S)'
}

export const CONSENT_TYPE = {
  APP: 'APP',
  TEAM_MEMBER_DEVICE: 'TEAM_MEMBER_DEVICE',
  VERBALLY: 'VERBALLY',
  PAPER: 'PAPER'
};

export const getConsentTypeOptions = indexes => _.pullAt(_.cloneDeep([
  {
    label: 'Sign consent form through the App',
    value: CONSENT_TYPE.APP,
    tip: '(For smart phone patient)',
    getNote: (styles, isEnrolled) => {
      if(isEnrolled) {
        return <div style={styles}>
          Upon enrolling this patient, the patient will automatically receive the consent form (Consent form language version will be aligned with App language) in their App once they log in. <span style={{ ...styles, fontWeight: 600 }}>The patient may NOT able to take a vitals measurement until the consent form has been signed.</span>
        </div>;
      } else {
        return <div style={styles}>
          The patient will automatically receive the consent form (Consent form language version will be aligned with App language) in their App once they log in.
        </div>;
      }
    }
  },
  {
    label: `Sign consent form via care team member's device`,
    value: CONSENT_TYPE.TEAM_MEMBER_DEVICE,
    tip: '(For in-clinic enrollment only)',
  },
  {
    label: 'Patient verbally consents',
    value: CONSENT_TYPE.VERBALLY
  },
  {
    label: 'Paper consent form has been signed',
    value: CONSENT_TYPE.PAPER
  }
]), indexes);

export const VISIT_CONSENT_TYPE_MAP = {
  VIRTUAL: [0, 2, 3],
  INCLINIC: [0, 1, 2, 3],
  PHONE: [0, 1, 2, 3]
};

export const DEFAULT_CONSENT_TYPE_OPTIONS = VISIT_CONSENT_TYPE_MAP['INCLINIC'];

export const CONSENT_DATE_FORMAT = 'MM/DD/YYYY';

export const getActivityOptions = (role) => ({
  MD:[
       { label: 'Called patient without a visit', value: 'Spoke with patient' },
       { label: 'Chart patient in EMR', value: 'Chart patient in EMR' },
       { label: 'Update medications', value: 'Update medications' },
       { label: 'Review treatment plan', value:'Review treatment plan'},
       { label: 'Refer to specialist', value: 'Refer to specialist' },
       { label: 'Other', value: 'Other'}
   ],
  RD:[
      { label: 'Called patient without a visit', value: 'Spoke with patient' },
      { label: 'Made note in the EMR about this patient', value: 'Chart patient in EMR' },
      { label: 'Translated education/communication', value:'Translating content'},
      { label: 'Reviewed vitals and/or treatment goals', value:'Review treatment plan'},
      { label: 'Researched patient condition', value: 'Patient research' },
      { label: 'Coordinated care with team or caregiver', value: 'Offline care coordination' },
      { label: 'Other', value: 'Other'}
  ]
})[role];
