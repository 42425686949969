import gql from 'graphql-tag'

export default gql`
  mutation resendOnBoardEmail(
    $id: EID!
  ){
    resendOnBoardEmail(
      id: $id,
    )
  }
`
