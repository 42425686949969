import _ from "lodash";
import moment from 'moment';

const checkIsOtherMed = (val) => {
    return _.includes(medicationList, val);
}

const medicationList = [
    'Medications taken as prescribed',
    'Medications taken as prescribed, but sometimes forgets',
    'Some medications patient often self titrates or stops independent of/ in conflict with physician advice',
    "Not taking medications because of non-compliance",
    "Medication reconciliation not done...Patient will do at follow up with MD or with RD online",
    "Patient does not have any medications prescribed"
]

const getDefaultVal = (state) => {
    const otherMed = state.otherMed;
    const reconcilationCompliance = state.reconcilationCompliance
    return otherMed === "" ? (reconcilationCompliance === 'Other' ? "" : reconcilationCompliance) : otherMed
}

const checkIfMedicationDiscontinued = (medicine) => {
  const { endDate } = medicine || {};
  return endDate && moment(endDate).isBefore(moment().startOf('day'));
}

const checkIfMedicationActive = (medicine) => {
  return !checkIfMedicationDiscontinued(medicine);
}

export default {
    checkIsOtherMed,
    getDefaultVal,
    checkIfMedicationDiscontinued,
    checkIfMedicationActive
}