import { _ } from 'ihcomponent';

const lang = window.sessionStorage.getItem('lang') || 'en';

export default {
  get: (key) => {
    const MAP = require(`./${lang}`).default;
    return _.get(MAP, key) || key;
  }

  // getFrom : (stringMap)=>(key)=>{
  //   const MAP = stringMap || {}
  //   return _.get(MAP, key) || key
  // }
};
