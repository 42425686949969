import { combineReducers } from 'redux';
const INITIAL_STATE = {
    patientHistory: []
};
const reducer = (state = INITIAL_STATE, action = {}) => {
        const { type,payload } = action;
        const patient  = payload;
        const { patientHistory, monthlyReviewStatus, monthlyReviewType, showMonthlyReviewTab } = state;
        switch (type) {
            case 'ADD_PATIENT_TO_HISTORY':
                let newPatientHistory = [...patientHistory];
                //remove previous if already in list
                if (patient) {
                  _.remove(patientHistory, { id: _.get(patient, 'id' )});
                  if(patientHistory.length==5) patientHistory.shift();
                  newPatientHistory = [patient,...patientHistory ];
                }
                return {...state,patientHistory:newPatientHistory };
            case 'SET_MONTLY_REVIEW_STATUS':
                if(!_.isEqual(payload, monthlyReviewStatus)){
                    return {
                        ...state,
                        monthlyReviewStatus: payload
                    }
                } else {
                    return state;
                }
            case 'SET_MONTLY_REVIEW_OPEN':
                if(!_.isEqual(payload, showMonthlyReviewTab)){
                    return {
                        ...state,
                        showMonthlyReviewTab: payload
                    }
                } else {
                    return state;
                }
            case 'SET_MONTLY_REVIEW_TYPE':
                if(!_.isEqual(payload, monthlyReviewType)){
                    return {
                        ...state,
                        monthlyReviewType: payload
                    }
                } else {
                    return state;
                }
            default:
                return state;
        }
}
export default {
    workstation: combineReducers({
        main: reducer
    })
}
