/**
 * Created by zizhangai on 1/10/17.
 */
import ProgramInfo from '../components/ProgramInfo'
import {createContainer, getRouterParam} from 'libModule/utils'

import { graphql } from 'react-apollo';
import { enrolledProgramForPatient as enrolledProgram } from 'modulesAll/graphql/enrolledProgram'

import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'

const ComponentWithData = graphql(enrolledProgram, {
  options : (ownProps)=>{
    const programId = ownProps.programId
    return {
      variables : {
        id : programId
      },
      notifyOnNetworkStatusChange: true,
    }
  },
  props : ({ownProps, data })=>{
    const { enrolledProgram, loading } = data || {}
    return {
      program : enrolledProgram || {},
      loading
    }
  }
})(ProgramInfo)

export default createContainer(ComponentWithData, (state)=>{
  return {
    programId : getRouterParam(state, 'programId')
  }
}, { setBreadCrumb })
