import { removeTaskFromEnrolledProgram } from 'graphqlModule/mutation/removeTaskFromEnrolledProgram';
import { VITAL_TYPE_MAP_FROM_SHORT,VITAL_TYPE_MAP, DEFAULT_THRESHOLD_MAP, DEFAULT_VITAL_SCHEDULE} from 'modulesAll/utils/constants/task';
import { generateScheduleForDB,addTemplateDetails,convertDBToSchedule, getDefaultThreshold}from 'modulesAll/utils/helpers/task.js';

import Client from 'libModule/gqlClient';
import { message } from 'ihcomponent'
import I18N from 'modulesAll/I18N';
import enrolledProgramGQL from 'modulesAll/graphql/enrolledProgram';
import { GQLHelper } from 'libModule/utils';
import { CGMHelperServices } from '../../../CGM/helpers';

 const openConfirmModal=(localThis,taskId,vitalType)=>{
    const { fullName=''} = localThis.props;
    return new Promise((resolve, reject) => {
        const Yes = {
            label: 'Confirm',
            handler: ()=>{
              if (vitalType === CGMHelperServices.brandFieldName) {
                const { patientId, memberId } = localThis.props;
                resolve(CGMHelperServices.removeCGM(patientId || memberId));
                return;
              }
                resolve(handleRemoveTaskFromEnrolledProgram(localThis,taskId,vitalType))
                resolve(localThis.setState({'enrolledVitals' : _.filter(localThis.state.enrolledVitals,v=>v!==vitalType) }))
            },
            closeModal: true
        }
        const No = {
            label: 'Cancel',
            handler: () => {
                resolve(false)
            }
        }

        const vitalTypeName = (() => {
          switch (vitalType) {
            case CGMHelperServices.brandFieldName:
              return 'CGM';
            default:
              return vitalType;
          }
        })();

        const modalProps = {
            Yes,
            No,
            title: I18N.get('vital.deleteVital.confirm.title'),
            content: I18N.get('vital.deleteVital.confirm.content')(vitalTypeName)
        };
        localThis.props.openConfirmModal(modalProps)
    })
}

 const handleRemoveTaskFromEnrolledProgram = (localThis,taskId,vitalType)=>{
    const { openErrorModal,program } = localThis.props;
    const { selectedVitals } = localThis.state;
    const enrolledProgramId = program.id;
    try{
        Client.mutate({
            mutation: removeTaskFromEnrolledProgram,
            variables: {
                id:enrolledProgramId,
                taskId:[taskId]
            },
            refetchQueries:[
                {
                    query:enrolledProgramGQL,
                    variables: {
                        id: enrolledProgramId
                    },
                }
            ]
        })
            .then(()=>{
                localThis.setSelectedVitals(_.filter(selectedVitals,t=>t!==vitalType));
                message.success('Vital deleted successfully.');
            })
            .catch(e=>openErrorModal(GQLHelper.formatError(e)));
    }catch(e){
        openErrorModal(GQLHelper.formatError(e))
    }
}

const parseBGSchedule = (vitalData,vitalType, selectedTaskId, props)=>{
  const data = props.form.getFieldsValue();
  const BGSchedule = _.get(data, 'BGSchedule') || _.get(data, 'vitals.BGSchedule') ||{};
  let frequency = 'weeklyMeal';
  BGSchedule.vitalType = vitalType;
  BGSchedule.frequency = frequency;

  if(!BGSchedule.weeklyMealSchedule && vitalData){
    const BG = convertDBToSchedule(vitalData);
    BGSchedule.weeklyMealSchedule = _.get(BG,'weeklyMealSchedule');//if there is no change now;
  }
  
  if(BGSchedule.weeklyMealSchedule) {
    const { templateDetails } = addTemplateDetails(BGSchedule); // must have a schedule
    let BGres = {
      schedule:generateScheduleForDB({schedule: BGSchedule,vitalType}),
      type:VITAL_TYPE_MAP[vitalType],
      repeat:'SCHEDULED',
      templateDetails
    }
    if(selectedTaskId){
      BGres.id = selectedTaskId
    }
    BGres.threshold = DEFAULT_THRESHOLD_MAP[vitalType] ? DEFAULT_THRESHOLD_MAP[vitalType] : [];
    return BGres;
  }
}
const parseVitalSchedule = (vitalData,vitalType,selectedTaskId, props)=>{
  let schedule  = _.get(vitalData,'value',{});
  let times  = _.get(vitalData,'frequencyValue.times');
  const frequency  =  _.get(vitalData,'frequencyValue.frequency');
  schedule.vitalType = vitalType;
  schedule.frequency = 'frequency';
  schedule.frequencyValue = {
      times
  }

  const defaultThreshold = getDefaultThreshold(vitalType, _.get(props, 'program.adminProgram'));
  
  return {
      id:selectedTaskId,
      schedule:generateScheduleForDB({schedule,vitalType}),
      repeat:frequency,
      type:VITAL_TYPE_MAP[vitalType],
      threshold: defaultThreshold
  }
}

const parseExerciseSchedule = (vitalData,vitalType,selectedTaskId, props) => {

  if(vitalData){
    const { schedule = [], threshold = [], repeat } = vitalData;
    return {
      id: selectedTaskId,
      type: VITAL_TYPE_MAP[vitalType],
      schedule: _.map(schedule, s => _.omit(s, '__typename')),
      repeat,
      threshold: _.map(threshold, t => ({
        measure: t.measure,
        range: t.range,
        unit: t.unit,
        exerciseGoal: _.omit(t.exerciseGoal, '__typename')
      }))
    };
  }
  const { times = 7, unit = 'WEEKLY' } = DEFAULT_VITAL_SCHEDULE[vitalType];
  return {
    type: VITAL_TYPE_MAP[vitalType],
    schedule: [{ target: times }],
    repeat: unit,
    threshold: []
  };
}

const parseTask = (v, props) => {
  const { program, form } = props;
  const data = form.getFieldsValue();

  const tasksFromProgram = _.get(program,'tasks');
  let curVital = _.get(data.vitals,v);
  const selectedTask = _.find(tasksFromProgram,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]==v);
  const selectedTaskId = _.get(selectedTask,'id');
  const vitalData = (v === 'Blood Glucose' || v === 'Exercise Goal')? selectedTask : curVital;
  let f = parseVitalSchedule;
  if(v === 'Blood Glucose') f = parseBGSchedule;
  if(v === 'Exercise Goal') f = parseExerciseSchedule;

  const parseVitalScheduleVal = f(vitalData, v, selectedTaskId, props);

  if(parseVitalScheduleVal){
    const task = {
        ...parseVitalScheduleVal
    }
    return task;
  }
}



const getMutateVariableVitals = (props) =>{
  const {getFieldValue} = props.form;
  const selectedVitals = getFieldValue('selectedVitals');
  if(selectedVitals){
    const tasks = [];
    _.forEach(selectedVitals,(v)=>{
        const task = parseTask(v, props);
        if(task.type) tasks.push(task);
    })
     return tasks;
  }
}


export default {
     handleRemoveTaskFromEnrolledProgram,
     openConfirmModal,
     getMutateVariableVitals
}