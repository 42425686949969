import React from 'react';
import PatientPage from 'modulesAll/patient/main/containers/main';
import { withRouter } from "react-router";
import { Row,Col,Icon } from 'antd';
import { IHLoading } from 'ihcomponent';
import '../style/index.scss';
import {graphql} from "react-apollo";
import { compose } from 'recompose';
import ProviderAlertResolveComponent from '../component/ProviderAlertResolveComponent';
import patientSmartAlertList from '../query/patientSmartAlertListMini';
import { connect } from 'react-redux';
import {changeMenuStats} from "../../layout/actions/Nav";
import {getUserRole, goPath} from "../../../lib/utils";
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../idleTime/constant';
import { withEnrolledCurrentProgram } from '../../workStation/API';
import { OMRONContextProvider } from '../../OMRONContext/OMRONContextProvider';

const PROVIDER_ALERT_TIMER = 'PROVIDER_ALERT_TIMER';
const WORKSTATION_ROLES = ['RD', 'CA', 'HC'];

const ProviderAlertResolveContainer = class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {
                currentIndex:-1,
                initialed: false,
                hasAlerts: undefined,
                isEnrolled: undefined,
            }
            this.redirectRdCaToWorkstation();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            this.redirectRdCaToWorkstation();
            const { alertsWithIdOnly,params } = this.props;
            const { currentIndex,initialed } = this.state;
            const memberId = params.id;
            const index = _.findIndex(alertsWithIdOnly,a=> a.memberId==memberId);
            if(currentIndex!=index&&!initialed) {
                this.setState({
                    currentIndex:index,
                    initialed:true
                })
            }
        }

        redirectRdCaToWorkstation = () => {
            if (WORKSTATION_ROLES.includes(getUserRole())) {
              const userId = this.props.params.id;
              goPath(`/workstation/${userId}/single`);
              return;
            }
        }

        setCurrentIndex = (currentIndex)=>{
            const { alertsWithIdOnly } = this.props;
            const memberId = _.get(alertsWithIdOnly,`${currentIndex}.memberId`);
            this.setState({
                currentIndex
            },()=>{
                window.history.pushState(null, "New Page Title", `/provideralert/${memberId}`);
            })
        }

        setHasAlerts = (hasAlerts) => { 
            if (this.state.hasAlerts !== hasAlerts) {
                this.setState({ hasAlerts }); 
            }
        }

        setIsEnrolled = (isEnrolled) => { 
            if (this.state.isEnrolled !== isEnrolled) {
                this.setState({ isEnrolled }); 
            }
        }

        renderLoading = ()=>{
            return <IHLoading/>
        }

        render() {
            const { loadingAlertsWithIdOnly,alertsWithIdOnly,params } = this.props;
            const { currentIndex, hasAlerts, isEnrolled } = this.state;
            const patientId = _.get(alertsWithIdOnly,`${currentIndex}.memberId`) || params.id;
            const showOnlyPatientProfile = hasAlerts === false;
            if(loadingAlertsWithIdOnly) return this.renderLoading();
            return <div className='providerSmartAlertResolveOutter'>
                    {/*<Row>*/}
                    {/*    <Col span={24} className={'backButton'}>*/}
                    {/*        <Icon type="left-square" onClick={goPath('/provideralert')}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <TimerClaimedContainer patientId={patientId} id={PROVIDER_ALERT_TIMER} />
                    <Row style={{ maxHeight: 'calc(100vh - 54px - 15px', overflowY: 'auto' }}>
                        {!showOnlyPatientProfile && <Col span={6} style={{ position:'sticky',top:0 }}>
                            <div className='providerSmartAlertResolveWrapper'>
                                   <ProviderAlertResolveComponent {...this.props}
                                                                   isSingle={this.props.route.isSingle}
                                                                   key={patientId}
                                                                   setCurrentIndex={this.setCurrentIndex}
                                                                   patientId={ patientId }
                                                                   alertsWithIdOnly={alertsWithIdOnly}
                                                                   currentIndex={currentIndex}
                                                                   onInteraction={(description) => { this.props.interacted(EVENT_TYPES.ALERT, description); }}
                                                                   setHasAlerts={this.setHasAlerts}
                                                                   />

                            </div>
                        </Col>}
                        <Col span={showOnlyPatientProfile ? 24 : 18}>
                          <OMRONContextProvider
                            patientId={patientId}
                          >
                            <PatientPage enrolledProgramId={params.enrolledProgramId || 'default'}
                                        {...this.props} isInChat={true}
                                         patientId={patientId}
                                         currentIndex={currentIndex}
                                         chatClassName={''}
                                         key={patientId}
                                         timerName={PROVIDER_ALERT_TIMER}
                                         isProviderAlert={true}
                                         setIsEnrolled={this.setIsEnrolled}
                            />
                          </OMRONContextProvider>
                        </Col>
                    </Row>
                   </div>

        }
}

const miniSmartList = graphql(patientSmartAlertList,{
    options:()=>{
        let variables = {};
        let filter = { categories: ['PROVIDER']};
        variables.filters = filter;
        variables.count = 100;
        return{
            variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=>{
        const { smartAlertTasksList,refetch,loading } = data;
        return {
            alertsWithIdOnly: _.get(smartAlertTasksList,'data',[]),
            loadingAlertsWithIdOnly:loading,
            refetchAlertsWithIdOnly:refetch
        }
    }
})
const mapDispatch = (dispatch, ownProps) => {
    const patientId = ownProps.params.id;
    return {
        changeMenuStats: (menuOpened) => dispatch(changeMenuStats(menuOpened)),
        ...mapDispatchToTimerActions(dispatch, PROVIDER_ALERT_TIMER, patientId)
    }
}

export default withRouter(compose(miniSmartList,connect(null,mapDispatch))(ProviderAlertResolveContainer));
