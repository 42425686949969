import React, { useState } from 'react';
import { Select, Input, Icon, Tabs, Spin } from 'antd';
import { map } from 'lodash';
import './SearchDropdown.scss';

const SearchDropdownComponent = ({
  containerClassName,
  categories,
  onCategoryChange,
  selectedCategory,
  selectedKeys,
  onSelectedKeysChange,
  onDeselectedKeysChange,
  value,
  onChange,
  onBlur,
  data,
  optRenderer,
  disabled,
  isLoading,
  submitButton,
  pagination,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openDropdown = () => setIsDropdownOpen(true);

  const hasSelection = selectedKeys && selectedKeys.length > 0;

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    if (typeof onChange === 'function') {
      onChange(searchValue);
    }
  };

  const handleOnBlur = () => {
    onBlur && onBlur();
    setIsDropdownOpen(false);
  };

  const renderCategories = () => {
    return (
      <Select.Option key='categories' disabled className="dropdown-search-categories">
        <Tabs size="small" activeKey={selectedCategory} onChange={onCategoryChange}>
          {map(categories, (c) => (<Tabs.TabPane tab={c.label} key={c.value} />))}
        </Tabs>
      </Select.Option>
    );
  };

  const renderPagination = () => {
    return pagination
      ? (
        < Select.Option 
          key='pagination' 
          disabled 
          className="dropdown-search-pagination" 
        >
          {pagination}
        </Select.Option >
      )
      : null
  };

  const renderSubmitButton = () => {
    return typeof submitButton === 'function'
      ? submitButton(() => setIsDropdownOpen(false))
      : submitButton;
  }

  return (
    <div className={`search-dropdown ${containerClassName}`}>
      <Input
        style={{ marginBottom: 5 }}
        className="search-dropdown-input"
        disabled={disabled}
        prefix={<Icon type='search' style={{ fontSize: '1.5em' }} />}
        placeholder='Search'
        autoFocus={false}
        value={value}
        onChange={handleInputChange}
        onPressEnter={openDropdown}
        onFocus={openDropdown}
        onBlur={handleOnBlur}
        suffix={isLoading ? <Spin /> : null}
      />
      <div className={`search-dropdown__select search-dropdown${hasSelection ? '' : '__hide'}`}>
        <Select
          mode="multiple"
          allowClear
          clearIcon={renderSubmitButton()}
          disabled={disabled}
          autoFocus={true}
          defaultActiveFirstOption={false}
          showSearch={false}
          getInputElement={() => <Input style={{ display: 'none' }} disabled />}
          value={selectedKeys}
          optionLabelProp='label'
          onSelect={onSelectedKeysChange}
          onDeselect={onDeselectedKeysChange}
          style={{ width: '100%' }}
          notFoundContent='No article found'
          dropdownClassName="search-dropdown__popup"
          open={isDropdownOpen}
        >
          {renderCategories()}
          {
            map(data, (v) => (
              <Select.Option
                key={v.id}
                value={v.id}
                label={v.name}
              >
                {optRenderer(v)}
              </Select.Option>
            ))
          }
          {renderPagination()}
        </Select>
      </div>
    </div>
  );
};

export default SearchDropdownComponent;
