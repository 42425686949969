const deleteConfirmContent = (vitalType)=>
`Are you sure you want to unenroll this patient from monitoring ${vitalType}?  This will also remove the patient's ability to monitor this vital at home. `;

export default {
    deleteVital:{
        confirm:{
            content:deleteConfirmContent,
            title:'Delete Vital?'
        },
        success:'Vital deleted successfully.',
    },
    deleteVitalInOnboard: {
      confirm:{
        content: `Are you sure you want to remove this device for this patient? This will also remove this patient’s vitals monitoring goal for this vital.`,
        title:''
      },
      success:'Vital deleted successfully.'
    },
    error: {
      bg: {
        unknownTemplate: 'Please select a template',
        emptySchedule: 'Please select at least one time for glucose measurement'
      }
    }
}
