/**
 * Created by zizhangai on 2/16/17.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { IHLoading } from 'ihcomponent'
import Waypoint from 'react-waypoint'
import Note from '../components/NoteComponent'
import { addNewNote, clearState } from '../actions/InterventionsActions'
import InputForInvalidation from '../containers/InputForNoteInvalidationContainer'
//queries
import { providerNoteCursorList, updateQueryFetchMore } from 'modulesAll/graphql/providerNoteList.js'
import Client from 'libModule/gqlClient';
import editProviderNote from 'graphqlModule/mutation/editProviderNote.js';

class AllNoteList extends Component {
  static displayName = 'AllNoteListModal'

  componentDidMount() {
    this.props.refetch()
  }
  componentWillUnmount() {
    this.props.clearState()
  }
  render() {
    if (this.props.loading) {
      return <div style={{width: '400px', height: '400px'}}><IHLoading/></div>
    }
    const { deleteProviderNote } = this.props;
    const docId = _.get(this.props,'docId');
    const { hasMore } = this.props.cursor || {}
    {/*<div style={{width: '400px', height: '600px', overflowY: 'auto'}}>*/}
    return (
        <div className="intervention-notes-notes all-notes">
        {
          this.props.notes.map((d, i) => (
            <Note key={i} note={d} row={this.props.row} onClick={() => this.props.addNewNote(d.id) } deleteProviderNote = {(id,cat)=>deleteProviderNote(id,cat,docId) }>
              {(this.props.stateNotes[d.id])?<InputForInvalidation id={d.id} note={d} row={this.props.row}/>: null}
            </Note>
          ))
        }
        { hasMore?
          <div>
            <Waypoint onEnter={()=>{ this.props.loadMore() }} bottomOffset="-200px"/>
            <div style={{height: '100px'}}><IHLoading /></div>
          </div>
          : null
        }
        </div>
    )
  }
}
const mapToDispatch = (dispatch,ownProps)=> {
    // console.log(ownProps);
    return {
        deleteProviderNote: (id, category) => {
            return Client.mutate({
                mutation: editProviderNote,
                variables: {
                    id,
                    category,
                    deleted: true
                },
                refetchQueries: [{
                    query: providerNoteCursorList,
                    variables: {
                        "after": null,
                        "before": null,
                        "filters": {
                            "enrolledProgramId": ownProps.enrolledProgramId
                        },
                        "count": 20,
                        "sort": {"field": "CREATED_AT", "direction": "DESC"}
                    },
                    fetchPolicy: 'network-only'
                }]
            })
        }
    }
}

const variables = {
  options: (ownProps) => {
    return {
      variables: {
        "after": null,
        "before": null,
        "filters": {
          "enrolledProgramId": ownProps.enrolledProgramId
        },
        "count": 20,
        "sort": {"field": "CREATED_AT", "direction": "DESC"}
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ ownProps, data }) => {
    const { loading, providerNoteCursorList, refetch, fetchMore } = data
    return {
      notes: (providerNoteCursorList || {}).data || [],
      cursor: (providerNoteCursorList || {}).cursorInfo,
      loading,
      refetch,
      loadMore() {
        return fetchMore({
          variables: {
            "after": providerNoteCursorList.cursorInfo.cursor,
          },
          notifyOnNetworkStatusChange: true,
          updateQuery: updateQueryFetchMore
        })
      }
    }
  }
}

const mapState = ({ Interventions }) => {
  const { notes } = Interventions
  return { stateNotes: notes || {} }
}
export default compose(
  graphql(providerNoteCursorList, variables),
  connect(mapState, { addNewNote, clearState }),
  connect(null,mapToDispatch)
)(AllNoteList)
