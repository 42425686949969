import { IHButton } from 'ihcomponent'
import I18N from 'modulesAll/I18N'

export const getReplaceDeviceModalProps = (qrcode, closeModal) => {
    const projectName = window.VSM_CONFIG.DEMO || 'Readmission'
    const body = (
      <div>
        <p style={{textAlign:'left', fontSize:'16px'}}>Please pick up a tablet and perform the following steps to complete the replacement:</p>
        <ul style={{textAlign:'left',paddingLeft:'40px',marginTop:'15px'}}>
          <li>1. Assign the Devices to the Patient</li>
          <li>2. Sign the Device Loan Form</li>
        </ul>
        <p style={{marginTop:'8px',textAlign:'left', fontSize:'16px'}}>
          Scan the below QR code to begin.
        </p>
        <img alt="" src={qrcode} style={{width:'80%',marginTop:'15px',marginBottom:'15px'}} />

        {/* <p style={{marginTop:'8px',textAlign:'left', fontSize:'16px'}}>
            If you prefer to start the {I18N.get('keywords.program')} at a later time, you can close this box now.
            <br/>You can always start the {I18N.get('keywords.program')} any time by scanning this QR code any time.
        </p> */}
      </div>
    )
    const b1 = {
      key : 'v1',
      size : 'md',
      label : 'Close',
      type : 'primary',
      onClick : ()=>{
          closeModal();
      }
    }
    return {
      title : 'Replacement Partially Complete',
      body,
      size : 'sm',
      footer : [
            <IHButton {...b1} />
        ]
    }
}
