import React from "react";
import {Button, Collapse, message,Icon, Tooltip } from "antd";
import moment from 'moment';
import { collapseComponents, componentList } from '../constant/index';
import { editUserWithSemiCompleteData as editUser } from 'modulesAll/graphql/mutation/editUser';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';
import Client from 'libModule/gqlClient';
import '../style/style.scss';
import '../style/versionHistory.styles.scss';
import HealthCdComponent from '../component/HealthCondition/HealthCdComponent';
import VersionHistory from "./VersionHistory";
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import {routerWillLeaveHelper} from "../../patient/enrolledProgramInfo/helper/helpers";
import { setAsyncRouteLeaveHook } from 'libModule/utils';
import { GQLHelper } from 'libModule/utils';
import ProgramInfo from '../component/ProgramInfo';
import CareTeamInfo from '../component/CareTeamInfo';
import carryoverHelper from '../component/helper/carryOverHelper';
import CarePlanCarryOver from '../component/GoalStatement/CarePlanCarryOver';
import createVersionHistory from "../../graphql/mutation/createVersionHistory";
import { getPESStatements } from "../../graphql/getPESStatements";
import helper from '../helpers/index';
import { updateGoalUponICDCode } from "../component/helper/goalHelper";
import { healthConditionsTooltip } from "../constant/healthConditionConstant";
import { disabledEditBtnTooltip } from "../constant/disabledEditBtnConstant";
import confirmUnsavedArticles from '../helpers/confirmUnsavedArticles';
const MTPR_AFFECTED_COMPONENTS = ["ClinicGoalsContainer"];

// import PrintCarePlan from './PrintCarePlanContainer';

const { Panel } = Collapse;

const CarePlanContainer = class extends React.Component{
    static initState = {
        openAllSections: false,
        isEditMode: false,
        enabledComponentMap: null,
        activeKey: [],
        queryVariables: { },
    }
    constructor(props) {
        super(props);
        this.state = {
            openAllSections: false,
            isEditMode: false,
            enabledComponentMap: null,
            activeKey: [],
            queryVariables: { },
            showOldCareplanGoal:!_.get(this.props, 'currentProgram.goalsFlag'),
            showOldCareplanAssessment: !_.get(this.props, 'currentProgram.assessmentsFlag')
        }

      this.handleScrollListener = _.debounce(() => {
        try {
          const header = document.querySelector('#sticky_profile_detail');
          // 54 is the height of top nav
          let HEADER_BOTTOM = 54, shouldShowEditTip = true;
          if(header && header.getBoundingClientRect) {
            HEADER_BOTTOM = header.getBoundingClientRect().bottom;
          }
          const editTipDiv = document.querySelector('.goToEditingBtn');
          const target = document.querySelector('.sessionInEdit');
          if(target && target.getBoundingClientRect) {
            const targetRect = target.getBoundingClientRect();
            const { top, bottom } = targetRect || {};
            shouldShowEditTip = bottom <= HEADER_BOTTOM || top >= window.innerHeight;
          }
          if(editTipDiv && shouldShowEditTip) {
            editTipDiv.classList.add('show-careplan-container-edit-tip');
          }
          if(editTipDiv && !shouldShowEditTip) {
            editTipDiv.classList.remove('show-careplan-container-edit-tip');
          }
        } catch (err) {
          console.error(err);
        }
      }, 200);
    }

    setScrollEventListener = shouldSet => {
      // const container = document.querySelector('.outtercontainer.patient-wrapper-6188');
      if(shouldSet) {
        window.addEventListener('scroll', this.handleScrollListener);
      } else {
        window.removeEventListener('scroll', this.handleScrollListener);
      }
    };

    componentDidMount() {
      if(this.props.addRouteLeaveHook) {
        this.removeRouteLeaveHook = this.props.addRouteLeaveHook(this.routerWillLeave.bind(this), 0);
      } else {
        setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this));
      }
      this.setScrollEventListener(true);
    }

    componentWillUnmount() {
      this.setScrollEventListener(false);
      if(this.removeRouteLeaveHook)
        this.removeRouteLeaveHook();
    }

    static getDerivedStateFromProps = (nextProps, state) => {
      const editTipDiv = document.querySelector('.goToEditingBtn');
      if(editTipDiv) {
        const action = nextProps.isInChat ? 'add' : 'remove';
        editTipDiv.classList[action]('chat-is-open');
      }
      return {...state};
    }

    routerWillLeaveClickOK=()=>{
      this.handleClickCancelButton();
    }

    routerWillLeave = () => {
      return new Promise((resolve,reject)=> {
            if(!this.state.isEditMode){
                resolve(true);
            } else {
              routerWillLeaveHelper(this.routerWillLeaveClickOK,resolve)
            }
        })
    }

    setEditMode = (isEditMode,enabledComProps)=>{
        this.setState({
            isEditMode,
            enabledComProps
        })
    }

    onSubmit = async (title)=>{
        const { queryVariables, enabledComponentMap } = this.state;
        const { shouldEditUser,shouldEditEnrolledProgram,editUserVar,editEnrolledProgramVar,editEnrolledProgramRefetchQueries,cb,cbVar,props } = queryVariables;
        let userResponse, currentProgramResponse;
        if(props){
          try{
            await props.form.validateFieldsAndScroll();
          }
          catch (e) {
              return;
          }
        }
        try {
            if(shouldEditUser) {
                userResponse = await Client.mutate({
                    mutation: editUser,
                    variables: editUserVar,
                    // refetchQueries:[{ query:userGQL, variables: { id:editUserVar.id },
                    //     fetchPolicy:'network-only'}]
                })
            }
            if(shouldEditEnrolledProgram) {
                currentProgramResponse = await Client.mutate({
                    mutation: editEnrolledProgram,
                    variables: editEnrolledProgramVar,
                    refetchQueries: editEnrolledProgramRefetchQueries || []
                    // refetchQueries:[{ query:enrolledProgramGQL, variables: { id:editEnrolledProgramVar.id },
                    //     fetchPolicy:'network-only'}]
                })
            }
            const user = _.get(userResponse, 'data.editUser') || this.props.user;
            const currentProgram = _.get(currentProgramResponse, 'data.editEnrolledProgram') || this.props.currentProgram;
            await this.generateVersionHistory(user, currentProgram, title);
            if(cb){
                await cb(cbVar)
            }

            // reverted from SC-6793, 0405-only revert the behavioral goal, so bring back the function back
            // update the goals only when patient is enrolled
            if(title === 'Health Conditions'){
                await updateGoalUponICDCode(this.props, user)
                .then(() => this.props.refreshProgram())
                .catch(e => {
                    console.error(e);
                    this.props.openErrorModal(`Goals are not updated: ${e.message}`);
                });
            }

            if (this.isEducationContainer(enabledComponentMap)) {
              const res = await confirmUnsavedArticles();
              if (!res) return;
            } else {
              message.success('Care plan Updated!');
            }

            this.props.setCarePlanStatus(false);
            this.setState({
                isEditMode: false,
                enabledComponentMap: null,
                queryVariables: { },
            });

        }catch(e){
            console.log(e);
            this.props.openErrorModal(GQLHelper.formatError(e));
        }
    }

    setEnableComponentMap = (name) => {
        this.setState({
            enabledComponentMap:name
        })
    }

    onChange = (queryVariables) => {
        this.setState({
            isEditMode: true,
            queryVariables
        }, () => {
            this.props.setCarePlanStatus(true);
        })
    }

    /*
    renderGeneralComponents = ()=>{
        const { isEditMode,enabledComponentMap } = this.state;
        const { setEditMode,props,setEnableComponentMap,onSubmit } = this;
        const isMNTEnrolled = helper.isMNTEnrolled(props);
        const program = _.get(props,'currentProgram');
        const programId = _.get(props,'currentProgram.id');
        const patientId = _.get(props,'user.id');
        const { user } = props;
        console.log(isMNTEnrolled);
        return _.map(componentList,com=>{
            //upper case for component convention purpose;
            const ComName = com;
            const { displayName } = ComName;
            return <ComName key={displayName}
                            user={user}
                            className='carePlanComponentWrapper'
                            isEditMode={isEditMode}
                            programId={programId}
                            program={program}
                            setEditMode={setEditMode}
                            patientId={patientId}
                            onSubmit={onSubmit}
                            enabledComponentMap={enabledComponentMap}
                            setEnableComponentMap={setEnableComponentMap}
                   />
        });
    }
    */

    handleClickEditButton = (name,e)=>{
      e.stopPropagation();
      this.setEnableComponentMap(name);
      this.handleScrollListener(); // reset
    }

    handleClickSaveButton = (title) => (e) => {
        e.stopPropagation();
        this.onSubmit(title || '');
        if (this.props.onInteraction) {
            this.props.onInteraction(title);
        }
    }

    handleClickCancelButton = (e) => {
        if(e) e.stopPropagation();
        this.setEnableComponentMap(null);
        this.setEditMode(false);
        this.props.setCarePlanStatus(false);
    }

    renderSaveEditButton = ({ name, title },index)=>{
        const { handleClickEditButton, handleClickSaveButton, handleClickCancelButton } = this;
        const { isEditMode,activeKey,enabledComponentMap } = this.state;
        const { showMonthlyReviewTab, monthlyReviewType } = this.props;
        const isEducationContainer = this.isEducationContainer(name);

        const button = ()=>{
            let isCurrentCom = enabledComponentMap === name;
            //if is in edit mode, and is current component, show enabled save button
            //if not current component show enabled edit button
            //if is in edit mode, and is current component, show enabled save button
            //if is in edit mode and is not current component, show disabled edit button;
            if(isCurrentCom) {
              if (isEducationContainer) {
                return (
                  <div className={'ant-collapse-extra'}>
                    <div className="panel-button">
                    <Button 
                      onClick={(e) => handleClickSaveButton(title)(e)} 
                      className={`careplanSaveButton ${name}`}
                    >
                      Done
                    </Button>
                    </div>
                  </div>
                );
              }
                
              return (
              <div className={'ant-collapse-extra'}>
                <div className="panel-button">
                  <Button 
                    onClick={(e) => handleClickCancelButton(e)} 
                    className='panel-cancel-btn'
                  >
                    Cancel
                  </Button>
                  <Button 
                    onClick={(e) => handleClickSaveButton(title)(e)} 
                    disabled={!isEditMode}  
                    className={`careplanSaveButton ${name}`}
                  >
                    Save
                  </Button>
                </div>
              </div>
              );
            }
            else{
                // disable the affected components' edit buttons when
                // 1) MTPR/MTPR_ADDITIONAL exits AND 2)the tab is open;
                const isAffectedByMTPR = _.includes(MTPR_AFFECTED_COMPONENTS, name);
                const isMTPR = _.includes(monthlyReviewType, "MTPR");

                const shouldDisableBtn = isAffectedByMTPR && isMTPR && showMonthlyReviewTab;
                const disabled = shouldDisableBtn || isEditMode;
                const text = shouldDisableBtn ? disabledEditBtnTooltip : "";

                return <div className={'ant-collapse-extra'}>
                        <div className="panel-button">
                            <Tooltip title={text} overlayClassName="edit-button-tooltip">
                                <Button onClick={(e) => handleClickEditButton(name, e)} disabled={disabled} className={`careplanEditButton ${name}`}>
                                    Edit
                                </Button>
                            </Tooltip>
                        </div>
                       </div>
            }
        }
        if(index) {
            //render for collapsed components
            if (_.includes(activeKey, index)) {
                return button();
            }
            return <div></div>;
        }
        else {
            //render for general components
            return button();
        }
    }

    onChangePanel = (activeKey)=>{
        this.setState({
            activeKey
        })
    }

    handleClickCollapseAll = ()=>{
        const { openAllSections } = this.state;
        const collapseComCount = Object.keys(collapseComponents(this.props)).length;
        const collapseKeys = Array.from(Array(collapseComCount), (_, i) => i+'');
        this.setState({
          activeKey: (!openAllSections ? collapseKeys : []),
          openAllSections:!openAllSections
        })
    }

    renderHealthCondition = () => {
        const { setEditMode,state,props,setEnableComponentMap,renderSaveEditButton,handleClickSaveButton,onChange } = this;
        const { isEditMode,enabledComponentMap,queryVariables, activeKey } = state;
        const { user, openErrorModal } = props;
        const program = _.get(props,'currentProgram');
        const programId = _.get(props,'currentProgram.id');
        const patientId = _.get(props,'user.id');
        const displayName = 'HealthCdComponent'
        const title = 'Health Conditions';

        return (
            <div className={`healthConditionWrapper ${enabledComponentMap===displayName ? ' sessionInEdit' :''}`}>
                <div id={displayName+'ExtraArea'} className='extraArea'></div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <Tooltip overlayClassName='health-conditions-tooltip' title={healthConditionsTooltip}><span style={{fontSize:14, fontWeight:'700', marginTop:8, color: '#000000'}}>Health Conditions</span></Tooltip>
                    <div style={{marginLeft:'auto'}}>
                        { renderSaveEditButton({name: displayName, title}) }
                    </div>

                </div>
                <HealthCdComponent
                    key={displayName+':'+enabledComponentMap}
                    user={user}
                    patientId={patientId}
                    isEditMode={isEditMode}
                    setEditMode={setEditMode}
                    onSubmit={handleClickSaveButton(title)}
                    onChange={onChange}
                    disabled={enabledComponentMap !== displayName}
                    enabledComponentMap={enabledComponentMap}
                    setEnableComponentMap={setEnableComponentMap}
                    openErrorModal={openErrorModal}
                    program={program}
                    programId={programId}
                />
            </div>
        )
    }

    renderCollapseComponents = ()=>{
        const { setEditMode,state,props,setEnableComponentMap,renderSaveEditButton,handleClickSaveButton,onChange,onChangePanel,handleClickCollapseAll } = this;
        const { openAllSections,isEditMode,enabledComponentMap,queryVariables,activeKey } = state;
        // passing goal-related props for Goals section
        const { user, openErrorModal, billableGoalOptions, billable, nonBillableGoalOptions, nonBillable, defaultBehavioralGoals, nonBillableDiseases, billableDiseases, onInteraction  } = props;

        const program = _.get(props,'currentProgram');
        const programId = _.get(props,'currentProgram.id');
        const patientId = _.get(props,'user.id');

        let index = 0;
        // <Collapse className='careplanCollapse' activeKey={activeKey} onChange={(index)=>onChangePanel(index)}>{ this.renderCollapseComponents() }</Collapse>

        return <div className={'carePlanCollapse'}>
                <div className='carePlanCollapseHeader'>
                   <h6>Comprehensive Assessment</h6>
                   <div id='collapseAllButton' onClick={ handleClickCollapseAll }>
                     <Icon type={openAllSections ? 'down':'right'} />
                     {openAllSections ? 'Collapse All' : 'Expand All'}
                    </div>
                </div>
                <Collapse  activeKey={activeKey} onChange={(index)=>onChangePanel(index)}>
                {
                    _.map(collapseComponents(props),(com)=>{
                        //upper case for component convention purpose;
                        const ComName = com;
                        const { displayName, title='' } = ComName;
                        index++;
                        return <Panel key={index + ''} id={displayName} extra={renderSaveEditButton({name: displayName, title}, index+'')}
                                      className={(enabledComponentMap===displayName ? ' sessionInEdit' :'' ) }
                                      header={<div><p>{`${index}. ${title}`}</p><div id={displayName+'ExtraArea'} className='extraArea'></div></div>}
                               >
                                    <ComName key={displayName+':'+enabledComponentMap}
                                             user={user}
                                             patientId={patientId}
                                             isEditMode={isEditMode}
                                             className='carePlanComponentWrapper'
                                             setEditMode={setEditMode}
                                             openAllSections={openAllSections}
                                             onSubmit={handleClickSaveButton(title)}
                                             onChange={ onChange }
                                             program={program}
                                             programId={programId}
                                             disabled={enabledComponentMap!==displayName}
                                             enabledComponentMap={enabledComponentMap}
                                             setEnableComponentMap={setEnableComponentMap}
                                             openErrorModal={openErrorModal}
                                             billable={billable}
                                             nonBillable={nonBillable}
                                             billableGoalOptions={billableGoalOptions}
                                             nonBillableGoalOptions={nonBillableGoalOptions}
                                             defaultBehavioralGoals={defaultBehavioralGoals}
                                             nonBillableDiseases={nonBillableDiseases}
                                             billableDiseases={billableDiseases}
                                             onInteraction={onInteraction}
                                    />
                        </Panel>
                    })
                }
                </Collapse>
               </div>;
    }

    isEducationContainer = (displayName) => displayName === 'InterventionEducationContainer';

    renderGeneralComponents = ()=>{
        const { isEditMode,enabledComponentMap, queryVariables} = this.state;
        const { setEditMode,props,setEnableComponentMap,handleClickSaveButton,onChange,renderSaveEditButton } = this;
        const { name } = queryVariables;
        const program = _.get(props,'currentProgram');
        const programId = _.get(props,'currentProgram.id');
        const patientId = _.get(props,'user.id');

        const { user, openErrorModal, billable, nonBillable, billableGoalOptions, nonBillableGoalOptions, defaultBehavioralGoals,  nonBillableDiseases, billableDiseases, goalLoading, onInteraction } = props;
        return _.map(componentList(props),com=>{

            //upper case for component convention purpose;
            const ComName = com;
            const { displayName, title='' } = ComName;
            const isEducationContainer = this.isEducationContainer(displayName);

            return <div 
              className={
                'carePlanGeneral' + (enabledComponentMap===displayName ? ' sessionInEdit' :'' )
                + (isEducationContainer ? ' education-container' : '')
              }
            >
                    <div id={displayName+'ExtraArea'} className='extraArea'></div>
                    { renderSaveEditButton({name: displayName, title}) }
                    <ComName key={displayName+':'+enabledComponentMap}
                            user={user}
                            className='carePlanComponentWrapper'
                            isEditMode={isEditMode}
                            programId={programId}
                            program={program}
                            setEditMode={setEditMode}
                            disabled={enabledComponentMap!==displayName}
                            patientId={patientId}
                            onSubmit={handleClickSaveButton(title)}
                            onChange={ onChange }
                            enabledComponentMap={enabledComponentMap}
                            setEnableComponentMap={setEnableComponentMap}
                            openErrorModal={openErrorModal}
                            billable={billable}
                            nonBillable={nonBillable}
                            billableGoalOptions={billableGoalOptions}
                            nonBillableGoalOptions={nonBillableGoalOptions}
                            defaultBehavioralGoals={defaultBehavioralGoals}
                            nonBillableDiseases={nonBillableDiseases}
                            billableDiseases={billableDiseases}
                            goalLoading={goalLoading}
                            onInteraction={onInteraction}
                    />
                   </div>
// =======
//             const { displayName } = ComName;
//             index++;
//             return <Panel key={index+''} extra={renderSaveEditButton(displayName,index+'')} header={<p>{displayName}</p>}>
//                         <ComName key={displayName}
//                                  user={user}
//                                  patientId={patientId}
//                                  isEditMode={isEditMode}
//                                  className='carePlanComponentWrapper'
//                                  setEditMode={setEditMode}
//                                  openAllSections={openAllSections}
//                                  onSubmit={onSubmit}
//                                  enabledComponentMap={enabledComponentMap}
//                                  setEnableComponentMap={setEnableComponentMap}
//                         />
//                    </Panel>;
// >>>>>>> SC-4879
        });
    }

    renderGoToEditingBtn = ()=>{
        const { enabledComponentMap, isEditMode} = this.state;
        const { pathname } = window.location;
        return !!enabledComponentMap && isEditMode &&
        <div className='goToEditingBtn'>
          You have unsaved changes.
          &nbsp;
          <a href={pathname+`#${enabledComponentMap}ExtraArea`}>
            Take me there
          </a>
        </div>;
    }

    generateVersionHistory = async (user, enrolledProgram, itemsUpdated = 'Unknown') => {
        try {
            const userPESStatementsData = await Client.query({
                query: getPESStatements,
                variables: {
                    memberId: _.get(this.props, 'user.id'),
                },
            })
            const variables = {
                enrolledProgramId: _.get(enrolledProgram, 'id'),
                date: moment().format('YYYY-MM-DD'),
                itemsUpdated,
                careplanData: JSON.stringify({
                    enrolledProgram,
                    user,
                    pes: userPESStatementsData.getPESStatements,
                }),
            };

            await Client.mutate({
                mutation: createVersionHistory,
                variables
            });

        } catch (e) {
            this.props.openErrorModal('Something went wrong creating the PDF!');
        }
    }

    renderHistoricalAssessments = () => {
        return<div className='historicalAssessments'>
            {this.state.showOldCareplanAssessment && <CarePlanCarryOver
                                                    {...this.props}
                                                    program={this.props.currentProgram}
                                                    carryOverSubmit={() => carryoverHelper.submitAssessments(this)}
                                                    path='assessments'/>}
        </div>
    }

    renderHistoricalGoals = () => {
        return <div className='historicalGoals'>
            {this.state.showOldCareplanGoal && <CarePlanCarryOver
                                                {...this.props}
                                                program={this.props.currentProgram}
                                                carryOverSubmit={() => carryoverHelper.submitGoals(this)}
                                                path='goals'/>}
        </div>
    }

    render(){
        const { props,renderCollapseComponents,renderGeneralComponents, renderHealthCondition,renderGoToEditingBtn, renderHistoricalAssessments, renderHistoricalGoals} = this;
        const isMNT = helper.isMNTEnrolled(props);
        return <div className='careplanContainer'>
                    <div className="version-history-info"><VersionHistory enrolledProgramId={_.get(this.props, 'currentProgram.id')} /></div>
                    <ProgramInfo {...this.props} isMNT={isMNT} />
                    { renderHealthCondition() }
                    <CareTeamInfo {...this.props} />
                    { renderCollapseComponents() }
                    { renderGeneralComponents() }
                    { renderHistoricalAssessments() }
                    { renderHistoricalGoals() }
                    { renderGoToEditingBtn() }
                </div>
    }
}

const mapStateToProps = (state) => {
    return {
        showMonthlyReviewTab: _.get(state,'workstation.main.showMonthlyReviewTab'),
        monthlyReviewType: _.get(state,'workstation.main.monthlyReviewType')
    };
}

const mapDispatch = (dispatch) => {
    return {
        openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
    };
}

export default compose(connect(mapStateToProps, mapDispatch))(CarePlanContainer);
