import {Button, Form, Col, Row, Select, Card,message, Modal,Input} from 'antd';
import React from 'react';
import upsertUserRolesGQL from 'modulesAll/graphql/mutation/upsertUserRoles';
import removeUserRolesGQL from 'modulesAll/graphql/mutation/removeUserRoles';
// import teamList from 'graphqlModule/teamList';
import Client from 'libModule/gqlClient';
import '../style.scss';
import {CLINIC_MANGER_STR, AREA_MANAGER_STR, HC_STR} from '../../constant/roleStrMap';
import { ROLE_NAME,CAPACITY_ROLE,ROLE_DEFAULT_CAPACITY } from '../../../../lib/constants';
import API  from '../../API/index';
import CapacityIconComponent from "./CapacityIconComponent";
const rolesKey = ['MA', 'Provider', 'CA', 'RD','Biller', 'Partner Biller', HC_STR];
const rolesExcluded = ['Site Manager','Area Manager'];
const { Option } = Select;
const { confirm, error } = Modal;
const getTeamListAPI = (orgId)=>{
    let variables={
        page:1,
        count:9999,
        filters: {
            organizationId: orgId
        },
        sort: null,
        search: null
    }
    return Client.query({
                   query:teamList,
                   variables:variables,
                   fetchPolicy:'network-only'
    })
}
class RoleManagement extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            roleEdit: false,
            orgToTeams:{},
            currNewRole:false,
            creatingRole:false,
        }
    }
    // componentDidUpdate = () => {
    //     if (Object.entries(this.state.orgToTeams).length===0&&this.props.roles) {
    //         this.SetOrgToTeams(this.props.roles);
    //     }
    // }
    countRole = () => {
        let res  = 0;
        _.map(Object.values(this.props.roles), r => res = res + r.length)
        return res;
    }
    getTeamList = (orgId) => {
        getTeamListAPI(orgId).then((data)=> {
            this.setState({
                orgToTeams:{
                    ...this.state.orgToTeams,
                    [orgId]: data.data.teamList.data
                }
            })

        })
    }
    SetOrgToTeams = (roles) => {
        _.map(Object.values(roles),(key)=>{
            if (key.length>0) {
                _.map(key,k=>this.getTeamList(k.organization.id))
            }
        });
    }
    addRole = (role, org) => {
        const {validateFieldsAndScroll} = this.props.form;
        validateFieldsAndScroll((err) => {
            if (!err) {
                this.setState({
                    creatingRole:true
                })
                const roleRefId = [];
                // _.map(org, (o)=>{
                    const site = _.filter(this.props.organizationList, (key)=>key.id===org)[0];
                    role === 'MA'?_.map(site.roles,(role)=> role.name==='Clinic Medical Assistant'?roleRefId.push(role.refId):null):null;
                    role === 'Provider'?_.map(site.roles,(role)=> role.name==='Doctor'?roleRefId.push(role.refId):null):null;
                    role === 'CA'?_.map(site.roles,(role)=> role.name==='Medical Assistant'?roleRefId.push(role.refId):null):null;
                    role === 'RD'?_.map(site.roles,(role)=> role.name==='Nurse'?roleRefId.push(role.refId):null):null;
                    role === 'Biller' ?_.map(site.roles,(role)=>role.name==='Biller' ? roleRefId.push(role.refId):null):null;
                    role === 'Partner Biller' ? _.map(site.roles,(role)=>role.name==='Partner Biller' ? roleRefId.push(role.refId):null):null;
                    role === HC_STR ? _.map(site.roles,(role)=>role.name===ROLE_NAME.HC ? roleRefId.push(role.refId):null):null;
                // })
                const userVariables = {
                  id: this.props.userData.id,
                  roles: roleRefId
                };
                changeRole(userVariables, upsertUserRolesGQL).then(res=>{
                    this.setState({currNewRole:false, creatingRole:false});
                    this.props.userRefetch();
                }).catch(e=>{
                    this.setState({creatingRole: false});
                    message.error(e.message);
                });
            }
        });
    }

    removeRole= (roleRefId) => {
        const userVariables = {
          id: this.props.userData.id,
          roles: [roleRefId]
        };
        changeRole(userVariables, removeUserRolesGQL).then(res=>{
            this.props.userRefetch();
        }).catch(e=>{
            message.error(e.message);
        });
    }
    showConfirm = (doc) => {
        const localThis = this;
        const { organization, refid } = doc;
        const { internalName } = organization;
        this.countRole() > 1?confirm({
            title: <div>
                    <div>Are you sure you want to remove this user from {internalName}? </div>
                    <div style={{fontSize: '14px', marginTop: '10px'}}>This will remove this user from all care teams in this clinic.</div>
                </div>,
            onOk(){
                localThis.removeRole(refid)
            },
            onCancel(){
                console.log('Cancel');
            }
        }):error({
            title: 'The last role cannot be deleted'
        })
    }
    renderRoles = (key, value) => {
        const { getFieldDecorator } = this.props.form;
        const capacities = _.get(this,'props.userData.capacitys',[]);
        const capacitysValueMap = Object.keys(capacities).reduce((res,cur)=>{
            const { role,capacity } = capacities[cur];
            res[role] = capacity;
            return res;
        },{});
        return (
            <Form className={'renderListByRoleForm'}>

                {_.map(value, (it, index) => {
                    return <div>
                        <Row>
                        {index===0?
                            <React.Fragment>
                            <Col span={7}>
                                <p className={'renderListTitle'}>Role</p>
                                <p className={'renderListBody'}>{key}</p>
                            </Col>
                            {
                                CAPACITY_ROLE.includes(key) &&
                                <Col span={16} offset={1} style={{ marginBottom:5 }}>
                                    <div>Capacity</div>
                                    <Form.Item>
                                      <div style={{ display: 'flex' }}>
                                        {getFieldDecorator(key,{ rules:[{ required:true,message:'Capacity is required.' }], initialValue:capacitysValueMap[key] || ROLE_DEFAULT_CAPACITY[key]} )
                                        (<Input style={{ width: 200 }} disabled={ !this.state.roleEdit }/>)}
                                        <CapacityIconComponent/>
                                      </div>
                                    </Form.Item>
                                </Col>
                            }
                            </React.Fragment>
                            :<Col span={7}></Col>}

                            <Col span={16} offset={8}>
                                <p className={'renderListTitle'}>Organization
                                {this.state.roleEdit?<Button onClick={()=>this.showConfirm(it)} className='deleteRoleBtn'>Delete</Button>:null}</p>
                                <p className={'renderListBody'}>{it.organization.internalName}</p>
                            </Col>
                            <Col span={16} offset={8}>
                                <p className={'renderListTitle'}>Care Team(s)</p>
                                <p className={'renderListBody'}>{_.map(it.team,(k, i)=>i===0?k.name:", "+k.name)}</p>
                            </Col>
                        </Row>
                    </div>
                })}
            </Form>
        )
    }
    renderOtherRoles = (key, value) => {
        return (
            <Form className={'renderListByRoleForm'}>
                <div>
                    <Row>
                        <Col span={7}>
                            <p className={'renderListTitle'}>Role</p>
                            <p className={'renderListBody'}>{key==='Site Manager'?CLINIC_MANGER_STR:AREA_MANAGER_STR}</p>
                        </Col>
                        <Col span={16} offset={1}>
                            <p className={'renderListTitle'}>Organization</p>
                            <p className={'renderListBody'}>{key==='Site Manager'?
                            _.map(value, (k, i) =>i===0?k.organization.internalName:", "+k.organization.internalName):
                            _.map(value, (k, i) =>i===0?k.organization.name:", "+k.organization.name)}</p>
                        </Col>
                    </Row>
                </div>
            </Form>
        )
    }

    saveCapacity = ()=>{

    }

    handleSave = ()=>{
        const { getFieldValue} = this.props.form;
        this.addRole(getFieldValue('role'), getFieldValue('site'));

    }

    renderNewRole = () => {
        const { getFieldDecorator, getFieldValue} = this.props.form;
        const { creatingRole } = this.state;
        const clinic = _.filter(this.props.organizationList, (key)=>key.leaf);
        // const area = _.filter(this.props.organizationList, (key)=>!key.leaf);
        return (
            <div>
                {this.state.roleEdit?<Form>
                    <Row>
                        <Col span={7}>
                            <Form.Item label="Role">
                            {getFieldDecorator('role', {rules: [{required: true,message: 'Please select the role'}]})
                            (<Select>{_.map(rolesKey, (h, i) => <Option value={h}>{h}</Option>)}</Select>)}
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={1}>
                            <Form.Item label="Organization">
                            {getFieldDecorator('site', {rules: [{required: true,message: 'Please select the site/clinic'}]})(
                                        <Select>
                                            {_.map(clinic, (key, i) => key.internalName?<Option value={key.id}>{key.internalName}</Option>:null)}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>:null}
                {this.state.roleEdit?<Button onClick={()=>this.handleSave()} loading={creatingRole}>{creatingRole ? 'Creating Role':'Save'}</Button>:null}
            </div>
        )
    }
// !_.includes(rolesExcluded,r[0])
    saveRoles = async ()=>{
      const { getFieldsValue } = this.props.form;
      const id = _.get(this,'props.userData.id');
      const values = getFieldsValue();
      let variables = {};
      variables.capacitys = Object.keys(values).map((k,v)=>({ role:k,capacity:values[k] }));
      variables.id = id;
      try {
         await API.editAccountRoleCapacity(variables);
         this.props.userRefetch();

      }catch (e) {
          console.log(e);
      }
    }

    renderButtons = ()=>{
      const { roleEdit } = this.state;
      const editFlag = roleEdit ? 'EDIT' : 'INIT';
      const handler = {
          INIT:()=>this.setState({roleEdit:!roleEdit}),
          EDIT:()=>this.saveRoles()
      }
      // const button =   <Button onClick={()=>{this.setState({roleEdit:!roleEdit})}} className={'editButton'}>{!roleEdit ? 'Edit':'Done'}</Button>
      return  <Button onClick={ handler[editFlag] } className={'editButton'}>{!roleEdit ? 'Edit':'Done'}</Button>
    };

    render(){
        const { roleEdit,creatingRole } = this.state;
        return(
            <Card bordered={false} style={{ background: '#FFFFFF', marginTop:'50px'}}>
                <Row className={'headerWrapper'}>
                    <Col span={20} className={'formHeader'}>Role Management</Col>
                    <Col span={4}>{this.renderButtons()}</Col>
                </Row>
                <Row>*To change RD/CA roles, please 1) delete the old ones and 2) add the new roles.</Row>
                <Row>&nbsp;&nbsp;To change area/site admin roles, please go to area/site details</Row>
                <br/>
                    {_.map(_.filter(Object.entries(this.props.roles),r=>!_.includes(rolesExcluded,r[0])), (k) => {
                        const [key, value] = k;
                        return value.length>0 ? this.renderRoles(key, value):null
                    })}
                    {_.map(_.filter(Object.entries(this.props.roles),r=>_.includes(rolesExcluded,r[0])), (k) => {
                        const [key, value] = k;
                        return value.length>0 ? this.renderOtherRoles(key, value):null
                    })}
                {
                    this.state.roleEdit&&!this.state.currNewRole ?
                    <Button onClick={()=>{this.setState({ currNewRole:true })}}
                            className={'addNewRoleButton'}
                    >Add new Role
                    </Button> : null
                }
                {this.state.currNewRole?this.renderNewRole():null}
            </Card>
        )
    }
}
RoleManagement = Form.create()(RoleManagement);
const changeRole = (userVariables, updateRoles) => Client.mutate({
      mutation: updateRoles,
      variables: userVariables
});


export default RoleManagement;
