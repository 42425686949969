// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { actionTypes as VitalFormActionTypes } from './VitalFormDuck'
import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import { actionTypes as CareplanCommonTypes, actionCreators as CareplanCommonActions } from './CommonDuck'
import { actionTypes as AlertListTypes } from 'patientModule/VitalAlert/ducks/AlertListDuck'
import { actionTypes as AlertFormTypes } from 'patientModule/VitalAlert/ducks/AlertFormDuck'
import { getInitAlertFormData } from 'modulesAll/utils/helpers/task'
import AlertFormContainer from 'patientModule/VitalAlert/containers/AlertFormContainer'
// Reducer
import { convertDBToVitalList, addVitalDataToList } from 'modulesAll/utils/helpers/task'
/*
**************************************************
  Constants - Action Types
**************************************************
*/
const actionTypes = constants('patient/Careplan', 'VitalList', [
  'ADD_VITAL_DATA',
  'INIT_DATA'
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {};

actionCreators.updateForm = (rowData, isEditMode)=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormActionTypes.UPDATE_FORM,
      fieldsValue: convertToFieldsValue(rowData),
      isEditMode
    })
    dispatch({
      type: AlertListTypes.INIT_DATA,
      initFormData: getInitAlertFormData(rowData, AlertFormContainer, AlertListTypes.REMOVE_TAB, AlertFormTypes.RESET_FORM, dispatch)
    })
  }
}

actionCreators.resetForm = ()=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormActionTypes.RESET_FORM
    })
  }
}

actionCreators.goToCareplanVitalForm = () => {
  return (dispatch, getState) => {
    dispatch(CareplanCommonActions.changePage(CareplanCommonTypes.CAREPLAN_VITAL_FORM_PAGE))
  }
}

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  data: []
};

export default (state = initState, action) => {
  switch(action.type) {
    case actionTypes.INIT_DATA:
      return {
        ...state,
        data: convertDBToVitalList(action.selectedProgram)
      };
    case actionTypes.ADD_VITAL_DATA:
      return addVitalDataToList(state, action.fieldsValue);
    default:
      return state;
  };
};
