import React  from 'react';
import VisitsWithVideoComponent from '../component/VisitsWithVideoComponent';
import { compose, graphql } from 'react-apollo/index';
import getVirtualVisitStatus from "../../graphql/getVirtualVisitStatus";
import { IHLoading } from 'ihcomponent';

const VideoChat = class extends React.Component{

    constructor(){
        super();

    }

    render(){
        const { curTab, visitList,loading,clientStatus,refetchVisitList } = this.props;
        if(loading){
            return <IHLoading/>
        }
        return<div>
                <VisitsWithVideoComponent curTab={curTab} visitList={visitList} clientStatus={clientStatus} refetchVisitList={refetchVisitList}/>
             </div>

    }
}
const clientStatus = graphql(getVirtualVisitStatus,{
    options:(ownProps)=>{
        const { uniqueVisitIds } = ownProps;
        return {
            variables:{
                visitIds:uniqueVisitIds
            },
            fetchPolicy:'network-only',
            pollInterval:15000
        }
    },
    props:({owmProps,data})=>{
        const { loading } = data;
        if(loading){
            return {
                loading
            }
        }
        return{
            clientStatus:data.getVirtualVisitStatus
        }
    }
})

export default compose(clientStatus)(VideoChat);
