import React from 'react';
import { Icon } from 'antd';
import '../../style/index.scss';
const renderContent = (type,className,onChangeSort,sortKey,direction)=> <Icon type={type} className={'areaSorter ' + className} onClick={()=>onChangeSort(sortKey,direction)}/>;
const directionMap = [{ direction:'asc',type:'caret-up'},
                      { direction: 'desc',type:'caret-down'}];


const SorterComponent = class extends React.Component{
    renderTitle = ()=>{
        const { title } = this.props;
        return <span className={'title'}>{title}</span>
    }
    renderIcon = ()=>{
        const { onChangeSort,selected,sortDirection,sortKey } = this.props;
        return <div className={'sorterWrapper'}>
                {_.map(directionMap, ({direction, type}) => {
                        const className = selected && (direction === sortDirection) ? 'selected' : ''
                        return renderContent(type, className, onChangeSort, sortKey, direction);
                    })
                }
               </div>
    }

    render(){
        return <div className={'sorterComponent'}>
                    {this.renderTitle()}
                    {this.renderIcon()}
               </div>
    }
}
export  default SorterComponent;