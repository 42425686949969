import { browserHistory } from 'react-router';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import logoutQL from 'modulesAll/graphql/logout';
import Client from 'libModule/gqlClient';
import { RequestCache } from 'libModule/utils';
import createAccountEventHistory from "../../graphql/mutation/createAccountEventHistory";
import { notification, Modal } from 'antd';
import { resetState } from '../../layout/actions/Nav';

const apiLogout = async (token) => {
    await Client.mutate({
      mutation: logoutQL,
      variables: { token }
    })
  return await Client.cache.reset();
};

export const logout = (event) => {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('authToken');
    if(token) {
        Client.mutate({
            mutation:createAccountEventHistory,
            variables:{
                event:event
            }
        });
        apiLogout(token)
        .catch(e => {
          console.log('apiLogout, e: ', e);
        })
        .finally( () => {
          //either logout success or fail , force user to login page
          sessionStorage.clear();
          browserHistory.push('/login');
          dispatch(resetState());
          RequestCache.set('userAccess', undefined); //reset
          RequestCache.set('appRoles', undefined);
          notification.destroy();
          Modal.destroyAll();
        });
    }
  }
};

export const autoLogout = (event) => {
  return (dispatch, getState) => {
    dispatch(logout(event));
    const errorMessage = 'You have been logged out due to inactivity.';
    dispatch(openErrorModal(errorMessage));
  }
};
