import React from 'react';
import moment from 'moment';
import appointmentTypeColorCode from '../constants/appointmentTypeColorCode';
import debounce from 'lodash/debounce';
import $ from 'jquery';
import assigneesMap from '../constants/assigneesMap';

const IsValidJSONString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


const SingleVisitTabComponent = class extends React.Component {
    constructor() {
        super();
        this.state = {
            content: ""
        };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseEnter = debounce(this.onMouseEnter, 250);
    }

    renderName = (fullName) => {
        return <div className={'fullName'} style={{
            paddingLeft: 10,
            //paddingTop: 2,
            //paddingBottom: 3,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height:  20,
            lineHeight:'20px',
           // lineHeight: 1.3,
            //display: 'flex',
            //flexDirection: 'column',
           // justifyContent: 'flex-end'
        }}
        >
            <span title={fullName} style={{
                overflowY: 'overlay',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}> {fullName}</span>
        </div>
    }


    onMouseEnter = () => {
        const { visit } = this.props;
        this.setState({
            content: _.isString(visit) ? visit.split(' ')[1] : ''
        })
    }

    onMouseLeave = () => {
        this.onMouseEnter.cancel();
        this.setState({
            content: ''
        })
    }

    clickOnSingleVisit = (visit, originalVisit, isJSONString) => {
        const { setSelectedVisit, setOriginalVisit, selectedVisit } = this.props;
        if (visit.isCrossClinic) return;
        setSelectedVisit(visit);
        if (typeof originalVisit != 'undefined') {
            setOriginalVisit(originalVisit);
        }
    }

    emptySlotHandleOnClick = (visit) => {
        const { selectedVisit, isEdit } = this.props;
        if (isEdit && IsValidJSONString(selectedVisit)) {
            const toObj = JSON.parse(selectedVisit);
            const omittedObj = _.omit(toObj, ['appointmentAt', 'visitStartTime', 'appointmentTo']);
            const json = JSON.stringify({
                ...omittedObj,
                appointmentAt: moment(visit).toDate(),
                visitStartTime: moment(visit).toDate(),
                appointmentTo: null,
            });

            this.clickOnSingleVisit(json)
            return;
        }
        isEdit ? this.clickOnSingleVisit({
            ...selectedVisit,
            appointmentAt: moment(visit).toDate(),
            visitStartTime: moment(visit).toDate(),
            appointmentTo: null
        }, selectedVisit)
            : this.clickOnSingleVisit(visit)
    }

    scheduledSlotHandleOnClick = (visit) => {
        const { selectedVisit, isEdit } = this.props;
        if (visit.isCrossClinic) return;
        if (isEdit && IsValidJSONString(selectedVisit)) {
            const toObj = JSON.parse(selectedVisit);
            const omittedObj = _.omit(toObj, ['appointmentAt', 'visitStartTime', 'appointmentTo']);
            const json = JSON.stringify({
                ...omittedObj,
                appointmentAt: moment(visit.appointmentAt).toDate(),
                // visitStartTime: moment(visit.appointmentAt).toDate(),
                appointmentTo: null,
            });

            this.clickOnSingleVisit(json)
            return;
        }
        isEdit ? this.clickOnSingleVisit({
            ...selectedVisit,

            appointmentAt: moment(visit.appointmentAt).toDate(),
            // visitStartTime: moment(visit.appointmentAt).toDate(),
            appointmentTo: null
        }, selectedVisit)
            : this.clickOnSingleVisit(visit);
    }
    parseAssignee = (assignees)=>{
        let assigneesStr = '';
        // for(let key in assigneesMap){
        //     if(_.get(assignees,key)) {
        //         assigneesStr = assigneesStr.concat(`${assigneesMap[key].title}: ${assignees[key].name} `);
        //     }
        // }
        return assigneesStr;
    }
    render() {
        const { visit, style, selectedVisit, isEdit, originalVisit } = this.props;
        const { type, assignees, isCrossClinic } = visit;
        const { content } = this.state;
        const renderStr = visit.index ?  this.parseAssignee(assignees) : (isCrossClinic ? ('Visit in ' + _.get(visit, 'organization.internalName', '')) : _.get(visit, 'member.profile.fullName', ''));
        // const type = isCrossClinic ? 'CROSS_CLINIC' : _type;
        if (_.isString(visit)) {
            const isSameVisit = selectedVisit == visit;
            const style = isSameVisit ? {
                border: 'solid 2px #67afe2',
                width: '100%'
            } : { width: '100%' }
            return <div style={style} className='visitTab emptySlot'
                // onClick={() => this.emptySlotHandleOnClick(visit)}
                onMouseEnter={() => this.onMouseEnter()}
                onMouseLeave={() => this.onMouseLeave()}
            >
                <div style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'center', height: '100%',
                    fontWeight: isEdit ? 'bold' : 'normal'
                }}>
                    {isSameVisit ? visit.split(' ')[1] : content}
                </div>
            </div>
        }
        _.extend(style, {
            height: 20,
            overflow: 'hidden',
            borderLeft: 'solid 4px',
            borderColor: _.get(appointmentTypeColorCode, `${type}.borderColor`, '#36c5cc'),
        })

        const isSameVisit = _.get(selectedVisit, 'id') == _.get(visit, 'id');
        const className = (isSameVisit ? 'visitTab isSelectedVisit ' : 'visitTab') + (' cell-' + _.get(visit, 'index')) + (_.get(visit, 'last') ? ' cell-last' : '');
        // const className = isSameVisit ? ('visitTab isSelectedVisit '+' cell-'+ (_.get(visit,'last') ? 'last':_.get(visit,'index'))) : 'visitTab';
        // const isOneCell=_.get(visit, 'index')==0&&_.get(visit, 'last');
        return <div style={style} className={className}
            onClick={() => this.scheduledSlotHandleOnClick(visit)}
        >
            <div style={{ backgroundColor: _.get(appointmentTypeColorCode, `${type}.backgroundColor`, 'rgba(196, 252, 255, 0.24)') }} >
                {this.renderName(renderStr)}
               {/*<div className={'assignees'}> { assigneesStr }</div>*/}
            </div>
        </div>
    }


}


export default SingleVisitTabComponent;
