export const ConditionsListSchema = `
  conditionsList{
    HLD
    HTN
    DM
    CKD
    Obesity
    PreDM
    COPD
    ESRD_Dialysis
  }
`;