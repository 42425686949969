import React from 'react';
import { missingInfoMap,missingInfoTasksResolver } from '../constant/MissingInfoConstant';
import {Popover } from 'antd';
import {renderForm} from "../../patient/AdditionalInfo/helpers";
import MuteMissingInfoComponent from "./MuteMissingInfoComponent";
let MissingInfoComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			// missingInfoMapInfoTasks:[],
			showMuteModal: false
		}
	}
	renderSingleItem = (item)=>{
		const { user ,missingInfo } = this.props;
		const { key } = item;
		const content = item.content(user,this,missingInfo);
		const show = !missingInfoTasksResolver[key].resolver(user,missingInfo);
		return { show, content }
	}
	componentDidMount() {
		// const { user,missingInfo } = this.props;
		// let missingInfoMapInfoTasks = new Set();
		// for (const [key, value] of Object.entries(missingInfoMap)) {
		// 	const show = !missingInfoTasksResolver[key].resolver(user,this,missingInfo);
		// 	if(show) {
		// 		missingInfoMapInfoTasks.add(TASK_COMBINED_MAP[key] ? TASK_COMBINED_MAP[key] : key)
		// 	}
		// }
		// this.setState({missingInfoMapInfoTasks: Array.from(missingInfoMapInfoTasks)})
	}

	renderInput = ()=>{
		const columns = [];
		for (const [key, item] of Object.entries(missingInfoMap)) {
			const {show,content} = this.renderSingleItem(item);
			if(show) {
				columns.push(...content);
			}
		}

		return columns;
	}
	setShowMuteModal = (showMuteModal)=>{
		this.setState({showMuteModal})
	}
	renderMuteBtn = ()=>{
		const { showMuteModal } = this.state;
		const { user,refetchMissingInfo } = this.props;
		const { setShowMuteModal } = this;
		const style = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 20,
			textDecoration:'underline'
		}
		return  <Popover title={'Please enter a note below to postpone the current Missing information Tasks.'} placement={'bottomRight'} visible={showMuteModal} content={<MuteMissingInfoComponent refetchMissingInfo={refetchMissingInfo} setShowMuteModal={setShowMuteModal} user={user}/>} overlayClassName={'muteBtnTooltip'}>
					<div style={style}><a onClick={()=>setShowMuteModal(true)}>I'll do it later</a></div>
		</Popover>
	}
	render() {
		const { renderButton,user } = this.props;
		return <div style={{ height: '100%' }}>
				{ renderButton(this,user) }
				{renderForm(this,this.renderInput())}
			   </div>
	}
}
export default MissingInfoComponent;