import React, { Component } from 'react';
import MessageThumbnailComponent from './MessageThumbnailComponent';
import MessageTextComponent from './MessageTextComponent';
import getSignedFileDownload from "../../../graphql/getSignedFileDownload";
import {compose, graphql} from "react-apollo/index";
import { renderTranslatedTextView } from '../ChatMessagePopover';
import chatHelpers from '../../helper';

const  FileUploadMessageComponent = class extends Component {
  constructor(){
    super();
    this.state = {
      hasError: false
    }
  }

  onError = e =>{
      const { refetch } = this.props;
       refetch();
  }

  render() {
    const { text, fileKey, userId,loading, originalText } = this.props;
    const { onError } = this;
    const thumbNailLink = _.get( this.props, 'getSignedFileDownload.link', '/image/default_image.png');
    const container = {
          padding: '10px 10px',
          backgroundColor: 'white',
          border: 'solid 1px rgb(80,176,231)',
          borderRadius: '8px',
          display: 'flex',
          flexDirection:'column',
          minHeight:50,
          overflowWrap: 'anywhere'
    };

    return (
          <div style={container}>
            { originalText  && renderTranslatedTextView(text, originalText, true, true) }
            <MessageTextComponent text={originalText || text || ''} />
            <MessageThumbnailComponent fileKey={fileKey} userId={userId} thumbNailLink={thumbNailLink} onError={ onError } loading={loading}/>
          </div>
        );
  }
}

const withData = graphql(getSignedFileDownload, {
    options: ownProps => {
        const { fileKey, userId } = ownProps;
        return {
            variables: { 
              fileKey, 
              userId: chatHelpers.getEncodedUserId(userId), 
              type: 'THUMBNAIL' 
            },
            fetchPolicy: 'cache-first'
        };
    },
    props: ({ _, data }) => {
        const { loading, getSignedFileDownload,refetch } = data;
        if(loading){
            return {
              loading
            }
        }

        if (getSignedFileDownload) {
            return {
                getSignedFileDownload,
                refetch
            };
        }
    }
});
export default compose(withData)(FileUploadMessageComponent);
