import { React, IHSelect } from 'ihcomponent';
import { graphql } from 'react-apollo';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import MemberDetail from './MemberDetail';
import TimerContainer from '../../../timer/containers/TimerContainer';
import {modalAction} from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import actions from 'modulesAll/patient/deviceTab/actions'
import { compose, withProps } from 'recompose';
import * as R from 'ramda';
import { patientGQL } from '../../../graphql/user';
import { createContainer, RequestCache } from 'libModule/utils';
import { openModal, closeModal } from 'layoutModule/actions/MainModal';
import EnrolledProgramInfo from '../../enrolledProgramInfo/containers/main'
import PatientAppointmentContainerWrapper from 'modulesAll/visit/containers/PatientAppointmentContainerWrapper';
import { EP_COUNT_MAX } from '../../../../lib/constants';
import { mapDispatchToTimerActions } from '../../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../../idleTime/constant';
import SmartAlertAndMTPR from './SmartAlertAndMTPR';

const NoActiveProgram = (props)=>{
  return <div style={{ padding:25 }}>No Active Program</div>
}

const debug = false;

const wrapGQLData2 = graphql(lightEnrolledProgramList, {
  options: ownProps => ({
    variables: {
      count: EP_COUNT_MAX,
      page: 1,
      filters: {
        memberId: ownProps.patientId,
      }
      // sort by CreatedAt
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
});

const displayName = 'patient/main/containers/ViewOnlyMain'
class ProviderViewPatientContainer extends React.Component {
    static displayName = displayName;
  constructor(props) {
    super(props);
    this.state = {
      selectedProgramIndex:0,
      tabSelected:'vitals'
  };
  };

  componentDidMount() {
    this.props.interacted(EVENT_TYPES.PATIENT_PROFILE);
  }

  renderSelect(options){
    const p = {
      option: [
        ...options
      ],
      size: 'large',
      value: options[this.state.selectedProgramIndex].name,
      onChange: (index) => {
        this.setState({selectedProgramIndex:parseInt(index)});
      },
    };

    return (
      <div style={{backgroundColor:"white", width:"100%", display:"flex", justifyContent:"center"}} className="programDropDown">
        <IHSelect {...p} />
      </div>
    );
  }

  updateTabSelected=(tabSelected)=>{
      this.setState({
          tabSelected
      })
  }

  setEnrollmentStatus = enrollmentStatus => {
    this.setState({enrollmentStatus})
  }

  render() {
    const {  
      data, patientId, patientName,
      showStickyHeader, menuOpened,
      patientBirthday, doctor, interacted
    } = this.props;
    let { enrolledProgramList, loading, refetch } = data;
    const { tabSelected, enrollmentStatus } = this.state;
    
    if(debug) console.log('ProviderViewPatientContainer props', this.props);

    if (loading) return 'Loading...';

    // const nurseEPList = UserClass.getEnrolledProgramIdList()
    const canViewEPList = _.get(enrolledProgramList, 'data') || [];
    // const canViewEPList = sortedEnrolledProgramList.filter( (ep) => (nurseEPList || []).indexOf(ep.id) !== -1 )
    const startedProgramsCanView = canViewEPList.filter(e => (e.status !== 'DISCHARGED' && e.status !== 'RETURNED'));
    const dischargedEPList = canViewEPList.filter(e => e.status == 'DISCHARGED');

    //TODO please check logic here. make showStarted=false will have some errors.

    this.currentProgram = startedProgramsCanView[this.state.selectedProgramIndex] || null;
    RequestCache.set('enrolledProgramList', enrolledProgramList);


    const items = startedProgramsCanView.map((item,index)=>({value:index.toString(), name:item.name}));
    const currentProgramNames = startedProgramsCanView.map(item=>item.name);
    let displayName = this.constructor.displayName;
    displayName = `${displayName}:${patientId}`;
    const viewingDetails ={
        component : displayName,
        path:window.location.pathname
    };

    const patient = {
      user: _.get(this.props, 'user'),
      enrolledPrograms: _.get(this.props, 'data.enrolledProgramList.data')
    };

    return (
      <div className="view-only-main">
        <TimerContainer displayName={displayName} category='PATIENT_PROFILE'
                        action='REVIEW' patientId={patientId} viewingDetails={viewingDetails} canRestUUid={true}/>
        <MemberDetail patientId={patientId}
                      currentProgramNames={currentProgramNames}
                      showStickyHeader={showStickyHeader}
                      currentProgramId={ _.get(this,'currentProgram.id')}
                      currentProgram = {this.currentProgram}
                      enrolledProgramList={enrolledProgramList}
                      menuOpened={menuOpened}
                      dischargedEPList={dischargedEPList}
                      refetchUser={this.props.refetchUser}
                      viewOnly
                      onInteraction={interacted}
                      enrollmentStatus={enrollmentStatus}
                      setEnrollmentStatus={this.setEnrollmentStatus}
        />
        <SmartAlertAndMTPR patientId={patientId}
                    data={patient}
                    patientLoading={this.props.patientLoading}
                    enrollmentStatus={enrollmentStatus}
                    currentProgram={this.currentProgram}
                    inProfile={false}
                    viewOnly/>

        <div style={{minWidth:'100%'}}>
            <PatientAppointmentContainerWrapper patientId={ patientId }
                                                patientName = { patientName }
                                                patientBirthday = { patientBirthday }
                                                className='inChat'
                                                doctor={doctor}
                                                isInChat = { true }
                                                patient = {patient}
                                                router={this.props.router} route={this.props.route}
                                                refetchUser={this.props.refetchUser}

            />
        </div>
        {
          items.length > 1 &&
            <div style={{
              display: 'flex',
              padding: '18px 44px',
              alignItems: 'center',
              background: 'white',
              justifyContent: 'space-between'
            }}>{this.renderSelect(items)}</div>
        }
        {
          this.currentProgram ?
            <EnrolledProgramInfo patientId={patientId} refetchEnrolledProgramList={refetch}
                                 enrolledProgramId={_.get(this,'currentProgram.id')} currentProgram={this.currentProgram}
                                 location={this.props.location} {...this.props} canRestUUid={false}
                                 isInChat = { true }
                                 patientName={patientName} tabSelected = {tabSelected} updateTabSelected={this.updateTabSelected}
                                 lightEnrolledProgramListRefetch={refetch}
                                 viewOnly
                                 onInteraction={interacted}
            />
            :
            <NoActiveProgram/>
        }

      </div>
    );
  }
};

const mapToProps = (state)=>{
  return {
    menuOpened: state.Nav.menuOpened
  }
}
const mapDispatch = (dispatch, ownProps) => {
  const { timerName, patientId } = ownProps;
  return {
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openAlertModal: (opts) => dispatch(modalAction.alert(opts)),
    openModal: (content, row) => dispatch(openModal(content, row)),
    closeModal:()=>dispatch(closeModal()),
    setInitDeviceData: (table, value) => dispatch(actions.setInitDeviceData(table, value)),
    interacted: mapDispatchToTimerActions(dispatch, timerName, patientId).interacted
  }
};
const enhance = compose(
  wrapGQLData2,
  withProps(()=>{
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
    return { viewerInfo, viewerId }
  }),
  graphql(patientGQL, {
    options : ({ patientId })=>({
      variables : { id: patientId },
    }),
    props: ({ ownProps, data: { loading, user, refetch}}) => ({
      patientLoading: loading,
      patientName: loading ? '' : R.path(['profile', 'fullName'], user ),
      patientBirthday: loading ? '' :R.path(['profile','birthday'],user),
      doctor:loading ? '':R.path(['profile','doctor'],user),
      user: loading ? '' : user,
      refetchUser:refetch
    }),
  }),
);



export default createContainer(enhance(ProviderViewPatientContainer), mapToProps, mapDispatch);
