import React from 'react';
import { Input, InputNumber, Select, Radio, Checkbox } from 'antd';
import { getMemberProfile } from 'patientModule/AdditionalInfo/helpers/index';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
const { Option } = Select;
const { TextArea } = Input;
import I18N from 'modulesAll/I18N';
import SelfEvaluationComponent from '../../visitWorkList/component/SelfEvaluationComponent';

const DISEASES = Object.keys(I18N.get('chronicDisease'));

const unitOptions = [
  { value: 'times/day', label: 'Day'},
  { value: 'times/week', label: 'Week'},
  { value: 'times/month', label: 'Month'}
];

const BGTreatmentsOptions = [
  { value: 'No Treatment', label: 'No Treatment' },
  { value: 'Diet', label: 'Diet' },
  { value: 'Exercise', label: 'Exercise' },
  { value: 'Oral Medication', label: 'Oral Medication' },
  { value: 'Insulin Medication', label: 'Injectable medication' },
  { value: 'Herbal Medication', label: 'Herbal Medication' }
];
const BPTreatmentsOptions = [
  { value: 'No Treatment', label: 'No Treatment' },
  { value: 'Diet', label: 'Diet' },
  { value: 'Exercise', label: 'Exercise' },
  { value: 'Oral Medication', label: 'Oral Medication' },
  { value: 'Herbal Medication', label: 'Herbal Medication' }
];
const COPDTreatmentsOptions = [
  { value: 'No Treatment', label: 'No Treatment' },
  { value: 'Diet', label: 'Diet' },
  { value: 'Exercise', label: 'Exercise' },
  { value: 'Oral Medication', label: 'Oral Medication' },
  { value: 'Herbal Medication', label: 'Herbal Medication' },
  { value: 'Oxygen Therapy', label: 'Oxygen Therapy' }
];
const radioOptions =[
  { value: true, label: 'Yes'},
  { value: false, label: 'No'}
];
const map = {
  COPD:[ 'name','yearsWithCondition','seenDoctor',"testRegularly","freq","unit",'specialistInfo.seenSpecialist',"specialistInfo.contact","treatments" ],
  PRE_DIABETES:[ 'name','yearsWithCondition','seenDoctor',"testRegularly","freq","unit",'specialistInfo.seenSpecialist',"specialistInfo.contact","treatments" ],
  CKD: [ 'name','yearsWithCondition','seenDoctor',"testRegularly","freq","unit",'specialistInfo.seenSpecialist',"specialistInfo.contact","treatments" ],
  HYPERLIPIDEMIA:[ 'name','yearsWithCondition','seenDoctor','specialistInfo.seenSpecialist',"specialistInfo.contact","treatments" ],
  CHF:[ 'name','yearsWithCondition','seenDoctor',"testRegularly","freq","unit",'specialistInfo.seenSpecialist',"specialistInfo.contact","treatments" ],
}
const otherConditionItem = [ 'name','yearsWithCondition','takingMedication',"otherTreatment",'specialistInfo.seenSpecialist',"specialistInfo.contact","comments" ];

const inputNumberRule = { validator: (rule, value, callback) => {
      if(value && value < 0){
        callback('Can\'t be less than 0');
      } else if(value && isNaN(value)){
        callback('Only numeric input allowed');
      } else {
        callback()
      }
  }}

const requiredMessage = 'Field is required';

const getDiagnoseDate = (val,type) => {
  const filteredRes  = _.filter(val,(k)=>k.condition==type);
  const diagnosedDate  = filteredRes.length > 0 ? filteredRes[0].diagnosedDate : null;
  return  diagnosedDate;
};

const generateConditionItemsBasedOnName = (items,index,name)=>{
  const itemsArray = map[name] || otherConditionItem;
    let newOuter = [];
    for(let outer of items){
      let newInner = [];
      for(let inner of outer) {
        if(itemsArray.includes(inner.path)){
          if(map[name]&&(inner.path ==='name')) {
            inner.className = inner.className+'hide';
          }
          newInner.push(inner);
        }
      }
      newOuter.push(newInner)
  }
  return newOuter;
}
const freqUnitMap = {
  WEEKLY:'times/week',
  MONTHLY: 'times/month',
  DAILY: 'times/day'
}
const conditionNameMap = {
  OBESITY: 'Weight reduction'
}
const generateConditionItems = (index,name) => {
  let items = [[
    {
      key: `chronicDiseaseHistory.${index}.name`,
      label: 'Condition',
      path: 'name',
      span: 4,
      // className:'chronicDiseaseHistoryName',
      // rules: [{ required: true, message: 'Field is required'}],
      inputContent: (disabled) => <Input disabled={disabled} placeholder='Enter condition here…'/>,
      // renderExtra:(value)=><span className={'title section-header'}>{value}</span>
      formatValue: (v) => conditionNameMap[v] || v
    }
    ],
    [{
      key: `chronicDiseaseHistory.${index}.yearsWithCondition`,
      label: 'Years with condition',
      rules: [inputNumberRule],
      path: 'yearsWithCondition',
      span: 4,
      inputContent: (disabled) => <Input disabled={disabled} />
    },
    {
      key: `chronicDiseaseHistory.${index}.takingMedication`,
      label: 'Taking Medicine',
      path: 'takingMedication',
      span: 4,
      inputContent: (disabled) => {
        return(
          <Radio.Group key={`medicineGroup-${index}`} disabled ={disabled}>
            {
              radioOptions.map(radio => {
                return(<Radio.Button key={`medicineStatus-${index}-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
              })
            }
          </Radio.Group>
        )
      }
    },
    {
      key: `chronicDiseaseHistory.${index}.otherTreatment`,
      label: 'Other Treatment',
      path: 'otherTreatment',
      span: 12,
      inputContent: (disabled) => <TextArea autoSize={{ minRows: 1 }} disabled={disabled} />
    }
    ],
    [
      {
        key: `chronicDiseaseHistory.${index}.specialistInfo.seenSpecialist`,
        path: 'specialistInfo.seenSpecialist',
        label: 'Does the patient see a specialist for this condition?',
        span: 8,
        inputContent: (disabled) => {
          return(
            <Radio.Group key={`specialist-${index}`} disabled ={disabled}>
              {
                radioOptions.map(radio => {
                  return(<Radio.Button key={`specialist-${index}-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
                })
              }
            </Radio.Group>
          )
        }
      },
      {
        key: `chronicDiseaseHistory.${index}.specialistInfo.contact`,
        path: 'specialistInfo.contact',
        span: 12,
        label: ' ',
        display: (form) => form.getFieldValue(`chronicDiseaseHistory.${index}.specialistInfo.seenSpecialist`),
        inputContent: (disabled) => <Input disabled={disabled} placeholder='Type specialist name and contact information'/>
      },
    ],
    [{
      key: `chronicDiseaseHistory.${index}.comments`,
      label: 'Additional Comments',
      path: 'comments',
      span: 24,
      inputContent: (disabled) => <TextArea autoSize={{ minRows: 1 }} disabled={disabled} />
    }]
  ];

  const parsedItems = generateConditionItemsBasedOnName(items,index,name);
  return parsedItems;
}

const diabeteList = (proReq) => {
  return [
    {
      key: 'yearOfDiagnose.DIABETES',
      label: 'Years with condition',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BGValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      path: 'profile.yearOfDiagnose',
      span: 4,
      formatValue: (data) => getDiagnoseDate(data,'DIABETES'),
      inputContent: (disabled) => {
        return(
          <Input disabled={disabled} />
      )}
    },
    {
      key: 'BGVisits',
      path: 'profile.BGVisits',
      label: 'Seen doctor in past 6 mos?',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BGValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      span: 4,
      inputContent: (disabled) => {
        return(
          <Radio.Group key='bgvisitGroup' disabled ={disabled}>
            {
              radioOptions.map(radio => {
                return(<Radio.Button key={`bgvisitStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
              })
            }
          </Radio.Group>
        )
      }
    },
    {
      key: 'BGTest.testRegularly',
      path: 'profile.BGTest.testRegularly',
      label: 'Tested BG before?',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BGValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      span: 4,
      inputContent: (disabled) => {
        return(
          <Radio.Group key='bgtestGroup' disabled ={disabled}>
            {
              radioOptions.map(radio => {
                return(<Radio.Button key={`bgtestStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
              })
            }
          </Radio.Group>
        )
      }
    },
    {
      key: 'BGValidationFlag',
      path: 'profile.BGValidationFlag',
      label: "",
      span: 22,
      inputContent: (disabled, p, value) => {
        return (
          <Checkbox defaultChecked = {value} disabled={disabled}>Information not available</Checkbox>
        )
      }
    }
  ];
}
const BGSpecialist = [
  {
    key: 'BGSpecialist.seenSpecialist',
    path: 'profile.BGSpecialistInfo.seenSpecialist',
    label: 'Does the patient see a specialist for this condition?',
    span: 8,
    inputContent: (disabled) => {
      return(
        <Radio.Group key='bgspecialist' disabled ={disabled}>
          {
            radioOptions.map(radio => {
              return(<Radio.Button key={`bgspecialist-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
            })
          }
        </Radio.Group>
      )
    }
  },
  {
    key: 'BGSpecialist.contact',
    path: 'profile.BGSpecialistInfo.contact',
    span: 12,
    label: ' ',
    display: (form) => form.getFieldValue('BGSpecialist.seenSpecialist'),
    inputContent: (disabled) => <Input disabled={disabled} placeholder='Type specialist name and contact information'/>
  },
];

const BGfreqList = [
  {
    key: 'BGTest.freq',
    label: 'How Often?',
    path:'profile.BGTest.freq',
    span: 2,
    rules: (form, localThis) => {
      let rule = [inputNumberRule];
      const propUnit = _.get(localThis.props, 'user.profile.BGTest.unit');
      const formUnit = form.getFieldValue('BGTest.unit');
      const required = propUnit ? (_.isEqual(formUnit, propUnit)) : (formUnit && !_.isEqual(formUnit, 'times/week'));
      if( required && rule.length === 1) {
        rule.push({required: true, message: 'Field is requied'});
      }
      return rule;
    },
    inputContent: (disabled) => <InputNumber disabled ={disabled}/>
  },
  {
    key: 'none',
    label: ' ',
    span: 1,
    inputContent: () => <div>/</div>
  },
  {
    key:'BGTest.unit',
    label: ' ',
    path:'profile.BGTest.unit',
    initialValue: 'times/week',
    span: 3,
    inputContent: (disabled) => {
      return (
      <Select disabled ={disabled}>
        {
          unitOptions.map(o => {
          return <Option key={o.value} value={o.value}>{o.label}</Option>
          })
        }
      </Select>
    )}
  }
];

const BGTreatments = [
  {
    key:'BGTreatments',
    label: 'Current Treatment',
    span: 24,
    path:'profile.BGTreatments',
    rules: [{ required: true, message: 'Field is required'}],
    inputContent: (disabled, localThis) => {
      return(
        <Checkbox.Group
          options={BGTreatmentsOptions}
          disabled ={disabled}
          onChange={val =>{
            const preValue = localThis.props.form.getFieldValue('BGTreatments') || [];
            if(preValue.includes('No Treatment') && val.length > 1) {
              _.pull(val, 'No Treatment');
            } else if (val.includes('No Treatment')){
              _.remove(val, v => v !== 'No Treatment');
            }
            localThis.props.form.setFieldsValue({'BGTreatments': val});
          }}
         />
      )
    },
    formatValue:(data)=> Array.isArray(data)?data:[]
  }
];
const BPTreatments = [
  {
    key:'BPTreatments',
    label: 'Current Treatment',
    span: 24,
    path:'profile.BPTreatments',
    rules: [{ required: true, message: 'Field is required'}],
    inputContent: (disabled, localThis) => {
      return(
          <Checkbox.Group
              options={BPTreatmentsOptions}
              disabled ={disabled}
              onChange={val =>{
                const preValue = localThis.props.form.getFieldValue('BPTreatments') || [];
                if(preValue.includes('No Treatment') && val.length > 1) {
                  _.pull(val, 'No Treatment');
                } else if (val.includes('No Treatment')){
                  _.remove(val, v => v !== 'No Treatment');
                }
                localThis.props.form.setFieldsValue({'BPTreatments': val});
              }}
          />
      )
    },
    formatValue:(data)=> Array.isArray(data)?data:[]
  }
];

const hypertensionList = (proReq) => {
  return [
    {
      key: 'yearOfDiagnose.HYPERTENSION',
      label: 'Years with condition',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BPValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      path: 'profile.yearOfDiagnose', //TODO
      span: 4,
      formatValue: (data) => getDiagnoseDate(data,'HYPERTENSION'),
      inputContent: (disabled) => {
        return(
          <Input disabled={disabled} />
      )}
    },
    {
      key: 'BPVisits',
      path: 'profile.BPVisits',
      label: 'Seen doctor in past 6 mos?',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BPValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      span: 4,
      inputContent: (disabled) => {
        return(
          <Radio.Group key='bpvisitGroup' disabled ={disabled}>
            {
              radioOptions.map(radio => {
                return(<Radio.Button key={`bpvisitStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
              })
            }
          </Radio.Group>
        )
      }
    },
    {
      key: 'BPTest.testRegularly',
      path: 'profile.BPTest.testRegularly',
      label: 'Tested BP before?',
      rules: (form, localThis) => {
        let rule = [inputNumberRule];
        const formReq = form.getFieldValue('BPValidationFlag');
        const required = formReq === undefined ? !proReq : !formReq;
        if( required ) {
          rule.push({required: true, message: 'Field is requied'});
        }
        return rule;
      },
      span: 4,
      inputContent: (disabled) => {
        return(
          <Radio.Group key='bptestGroup' disabled ={disabled}>
            {
              radioOptions.map(radio => {
                return(<Radio.Button key={`bptestStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
              })
            }
          </Radio.Group>
        )
      }
    },
    {
      key: 'BPValidationFlag',
      path: 'profile.BPValidationFlag',
      label: "",
      span: 22,
      inputContent: (disabled, p, value) => {
        return (
          <Checkbox defaultChecked = {value} disabled={disabled}>Information not available</Checkbox>
        )
      }
    }
  ];
} 

const BPSpecialist = [
  {
    key: 'BPSpecialist.seenSpecialist',
    path: 'profile.BPSpecialistInfo.seenSpecialist',
    label: 'Does the patient see a specialist for this condition?',
    span: 8,
    inputContent: (disabled) => {
      return(
        <Radio.Group key='bpspecialist' disabled ={disabled}>
          {
            radioOptions.map(radio => {
              return(<Radio.Button key={`bpspecialist-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
            })
          }
        </Radio.Group>
      )
    }
  },
  {
    key: 'BPSpecialist.contact',
    path: 'profile.BPSpecialistInfo.contact',
    span: 12,
    label: ' ',
    display: (form) => form.getFieldValue('BPSpecialist.seenSpecialist'),
    inputContent: (disabled) => <Input disabled={disabled} placeholder='Type specialist name and contact information'/>
  },
]

const BPfreqList = [
  {
    key: 'BPTest.freq',
    label: 'How Often?',
    path:'profile.BPTest.freq',
    span: 2,
    rules: (form, localThis) => {
      let rule = [inputNumberRule];
      const propUnit = _.get(localThis.props, 'user.profile.BPTest.unit');
      const formUnit = form.getFieldValue('BPTest.unit');
      const required = propUnit ? (_.isEqual(formUnit, propUnit)) : (formUnit && !_.isEqual(formUnit, 'times/week'));
      if( required && rule.length === 1) {
        rule.push({required: true, message: 'Field is requied'});
      }
      return rule;
    },
    inputContent: (disabled) => <InputNumber disabled ={disabled}/>
  },
  {
    key: 'none',
    label: ' ',
    span: 1,
    inputContent: () => <div>/</div>
  },
  {
    key:'BPTest.unit',
    label: ' ',
    path:'profile.BPTest.unit',
    initialValue: 'times/week',
    span: 3,
    inputContent: (disabled) => {
      return (
      <Select disabled ={disabled}>
        {
          unitOptions.map(o => {
          return <Option key={o.value} value={o.value}>{o.label}</Option>
          })
        }
      </Select>
    )}
  }
];

const otherBillableList = (disease, data, formData, localThis) => {
  const contactValue = _.get(formData, `otherBillable.${disease}.specialistInfo.seenSpecialist`);
  const testRegularlyValue = _.get(formData, `otherBillable.${disease}.testRegularly`);
  const displayContact = typeof contactValue == 'boolean' ? contactValue : _.get(data, 'specialistInfo.seenSpecialist');
  const displayFreq =  typeof testRegularlyValue == 'boolean' ? testRegularlyValue:  _.get(data, 'testRegularly');
  const validationFlag = _.get(data, 'validationFlag');
  return (
    [
      [
      {
        key: `otherBillable.${disease}.yearsWithCondition`,
        label: 'Years with condition',
        rules: (form) => {
          let rule = [inputNumberRule];
          const formReq = form.getFieldValue(`otherBillable.${disease}.validationFlag`);
          const isRequired = formReq === undefined ? !validationFlag : !formReq;
          if( isRequired ) {
            rule.push({required: true, message: 'Field is requied'});
          }
          return rule;
        },
        initialValue: _.get(data, 'yearsWithCondition'),
        span: 4,
        inputContent: (disabled) => <Input disabled={disabled}/>
      },
      {
        key: `otherBillable.${disease}.seenDoctor`,
        label: 'Seen doctor in past 6 mos?',
        rules: (form) => {
          let rule = [];
          const formReq = form.getFieldValue(`otherBillable.${disease}.validationFlag`);
          const isRequired = formReq === undefined ? !validationFlag : !formReq;
          if( isRequired ) {
            rule.push({required: true, message: 'Field is requied'});
          }
          return rule;
        },
        initialValue: _.get(data, 'seenDoctor'),
        span: 4,
        inputContent: (disabled) => {
          return(
            <Radio.Group key={`${disease}-visitGroup`} disabled={disabled}>
              {
                radioOptions.map(radio => {
                  return(<Radio.Button key={`${disease}-visitStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
                })
              }
            </Radio.Group>
          )
        }
      },
      {
        key: `otherBillable.${disease}.testRegularly`,
        label: I18N.get(`chronicDisease.${disease}.testLabel`),
        rules: (form) => {
          let rule = [];
          const formReq = form.getFieldValue(`otherBillable.${disease}.validationFlag`);
          const isRequired = formReq === undefined ? !validationFlag : !formReq;
          if( isRequired ) {
            rule.push({required: true, message: 'Field is requied'});
          }
          return rule;
        },
        span: 6,
        display: () => !_.isEqual(I18N.get(`chronicDisease.${disease}.testLabel`), 'NONE'),
        initialValue: _.get(data, 'testRegularly'),
        inputContent: (disabled) => {
          return(
            <Radio.Group key='bgtestGroup' disabled ={disabled}>
              {
                radioOptions.map(radio => {
                  return(<Radio.Button key={`bgtestStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
                })
              }
            </Radio.Group>
          )
        }
      },
      {
        key: `otherBillable.${disease}.validationFlag`,
        label: '',
        span: 22,
        inputContent: (disabled) => <Checkbox defaultChecked={validationFlag} disabled={disabled}>Information not available</Checkbox>
      },
      {
        key: `otherBillable.${disease}.freq`,
        label: 'How Often?',
        span: 2,
        rules: (form, localThis) => {
          let rule = [inputNumberRule];
          const propUnit = _.get(data, 'unit');
          const formUnit = _.get(formData, `otherBillable.${disease}.unit`)
          const required = propUnit ? (_.isEqual(formUnit, propUnit)) : (formUnit && !_.isEqual(formUnit, 'times/week'));
          if( required && rule.length === 1) {
            rule.push({required: true, message: requiredMessage });
          }
          return rule;
        },
        initialValue:_.get(data, 'freq'),
        display: () => displayFreq,
        inputContent: (disabled) => <InputNumber disabled ={disabled}/>
      },
      {
        key: 'none',
        label: ' ',
        span: 1,
        display: () => displayFreq,
        inputContent: () => <div>/</div>
      },
      {
        key: `otherBillable.${disease}.unit`,
        label: ' ',
        span: 3,
        initialValue: freqUnitMap[_.get(data, 'unit')]||_.get(data,'unit') ||'times/week',
        display: () => displayFreq,
        inputContent: (disabled) => {
          return (
          <Select disabled ={disabled}>
            {
              unitOptions.map(o => {
              return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )}
      }
    ],
    [
      {
        key: `otherBillable.${disease}.specialistInfo.seenSpecialist`,
        label: 'Does the patient see a specialist for this condition',
        span: 8,
        initialValue: _.get(data, 'specialistInfo.seenSpecialist'),
        inputContent: (disabled) => {
          return(
            <Radio.Group key={`specialist-${disease}`} disabled ={disabled}>
              {
                radioOptions.map(radio => {
                  return(<Radio.Button key={`specialist-${disease}-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
                })
              }
            </Radio.Group>
          )
        }
      },
      {
        key: `otherBillable.${disease}.specialistInfo.contact`,
        span: 12,
        label: ' ',
        initialValue: _.get(data, 'specialistInfo.contact'),
        display: () => displayContact,
        inputContent: (disabled) => <Input disabled={disabled} placeholder='Type specialist name and contact information'/>
      },
    ],
    [
      {
        key:`otherBillable.${disease}.treatments`,
        label: 'Which of the followings does the patient use to treat this condition',
        span: 24,
        rules: [{ required: true, message: requiredMessage }],
        initialValue: _.get(data, 'treatments'),
        inputContent: (disabled, localThis) => {
          return(
            <Checkbox.Group
              options={COPDTreatmentsOptions}
              disabled ={disabled}
              onChange={val =>{
                const preValue = _.get(formData, `otherBillable.${disease}.treatments`) || [];
                if(preValue.includes('No Treatment') && val.length > 1) {
                  _.pull(val, 'No Treatment');
                } else if (val.includes('No Treatment')){
                  _.remove(val, v => v !== 'No Treatment');
                }
                localThis.props.form.setFieldsValue({ [`otherBillable.${disease}.treatments`] : val});
              }}
             />
          )
        },
        formatValue:(data)=> Array.isArray(data)?data:[]
      }
    ]
  ])
}

const extraNote = [
  {
    key: 'chronicDiseaseNote',
    label: 'Notes',
    path: 'profile.chronicDiseaseNote',
    span: 12,
    inputContent: (disabled, localThis) => <TextArea  autoSize={{ minRows: 1}}
                                                      disabled ={disabled}
                                                      placeholder='Enter any additional information here… '
                                                      onChange={localThis.setNotes}
                                                      allowClear/>
  }
];

const getMutateVariableChronicDisease = ({props,state})=>{
  const { user } = props;
  const { BPRemoved,BGRemoved } = state;
  const getFieldsValue  = _.get(props,'form.getFieldsValue');
  const data =  _.omit(getFieldsValue(), 'none');
  let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday']);
  const { BGTreatments, BPTreatments,BGVisits, BGTest={},BPTest={}, BPVisits, chronicDiseaseHistory, yearOfDiagnose={}, chronicDiseaseNote,
  BGSpecialist={}, BPSpecialist={}, otherBillable,BPValidationFlag, BGValidationFlag } = data;
  const DUMMYKEYS = ['SpecialistInfo','Treatments','Test','Visits'];
  const BPOrBGKeys = (type)=>{
    return _.map(DUMMYKEYS,(k)=>`${type}${k}`);
  }
  const emptyValueMap = {
    BGSpecialistInfo: {},
    BPSpecialistInfo :{},
    BGTreatments: [],
    BPTreatments: [],
    BGTest: {},
    BPTest: {},
    BGVisits: null,
    BPVisits: null,
    BGValidationFlag: null,
    BPValidationFlag: null
  }

  //handle BG value update;
  if(!BGRemoved) {
    if (typeof BGVisits === 'boolean') {
      memberProfile.BGVisits = BGVisits;
    }
    if ((typeof BGTest.testRegularly) == 'boolean') {
      memberProfile.BGTest = {
        testRegularly: BGTest.testRegularly
      }
      if ((typeof BGTest.freq === 'number')) {
        memberProfile.BGTest.freq = BGTest.freq;
        memberProfile.BGTest.unit = BGTest.unit;
      }
    }

    if (typeof BGSpecialist.seenSpecialist === 'boolean') {
      memberProfile.BGSpecialistInfo = BGSpecialist
    }
    if (typeof BGValidationFlag === 'boolean') {
      memberProfile.BGValidationFlag = BGValidationFlag
    }
    if (BGTreatments) {
      memberProfile.BGTreatments = BGTreatments;
    }
  }else{
    _.forEach(BPOrBGKeys('BG'),(k)=>{
      _.set(memberProfile,k,emptyValueMap[k]);
    })
  }

  if(!BPRemoved) {
    if (typeof BPVisits === 'boolean') {
      memberProfile.BPVisits = BPVisits;
    }

    if ((typeof BPTest.testRegularly) == 'boolean') {
      memberProfile.BPTest = {
        testRegularly: BPTest.testRegularly
      }
      if ((typeof BPTest.freq === 'number')) {
        memberProfile.BPTest.freq = BPTest.freq;
        memberProfile.BPTest.unit = BPTest.unit;
      }
    }


    if (typeof BPSpecialist.seenSpecialist === 'boolean') {
      memberProfile.BPSpecialistInfo = BPSpecialist
    }
    if (typeof BPValidationFlag === 'boolean') {
      memberProfile.BPValidationFlag = BPValidationFlag
    }

    if (BPTreatments) {
      memberProfile.BPTreatments = BPTreatments;
    }
  }else{
    _.forEach(BPOrBGKeys('BP'),(k)=>{
      _.set(memberProfile,k,emptyValueMap[k]);
    })
  }

  memberProfile.chronicDiseaseNote = chronicDiseaseNote || null;
  let updateChronicDiseaseHistory = [];

  _.mapKeys(otherBillable, (fields, diseaseName) => {
    let updateItem = {};
    _.mapKeys(fields, (value, key) =>{

      switch (key) {
        case 'specialistInfo':
          if( typeof value.seenSpecialist !== 'undefined'){
            updateItem[key] = value;
          }
          break;
        case 'unit':
          if((typeof fields.freq ==='number')){
              updateItem[key] = value;
          }
          break;
        case 'treatments': // this is a required field
          if(typeof value !== 'undefined' && !_.isEmpty(value)){
            updateItem[key] = value;
          }
          break;
        default:
          if(typeof value !== 'undefined'){
            updateItem[key] = value;
        }
      }
    })

      if(!_.isEmpty(updateItem)){
        updateItem['name'] = diseaseName;
        updateChronicDiseaseHistory.push(updateItem);
      }
  })


  if(Array.isArray(chronicDiseaseHistory)){
    _.map(chronicDiseaseHistory, o => {
      let updateItem = {};
      _.mapKeys(o, (value, key) =>{
        if(key === 'specialistInfo'){
          if( typeof value.seenSpecialist !== 'undefined'){
            updateItem[key] = value;
          }
        } else if(typeof value !== 'undefined'){
          updateItem[key] = value;
        }
        if(key === 'name' && value ==='Weight reduction'){
          updateItem[key] = 'OBESITY';
        }
      })
      if(!_.isEmpty(updateItem)){ updateChronicDiseaseHistory.push(updateItem);}
    })
  }
  memberProfile.chronicDiseaseHistory = updateChronicDiseaseHistory;

  let yearOfDiagnoseUpdated = [];
  // if(yearOfDiagnose){
    const keys = Object.keys(yearOfDiagnose||{});
    _.map(keys, k=> {
      if(k && !_.isNull(yearOfDiagnose[k]) && yearOfDiagnose[k] !== ""){
        yearOfDiagnoseUpdated.push({
          condition: k,
          diagnosedDate: yearOfDiagnose[k]
        })
      }
    })
    memberProfile.yearOfDiagnose = yearOfDiagnoseUpdated;
  // }

  const result = {
      id: user.id,
      memberProfile,
  }
  let variables = DATE_HELPERS.trimAllValues(result, '', result);
  return variables;
}

const defaultDiseaseHistoryItemMap = [
  "COPD",
  "PRE_DIABETES",
  "CKD",
  "HYPERLIPIDEMIA",
  "CHF"
];


export {
  diabeteList,
  hypertensionList,
  BGTreatments,
  BPTreatments,
  BGfreqList,
  BPfreqList,
  BGSpecialist,
  BPSpecialist,
  extraNote,
  generateConditionItems,
  getMutateVariableChronicDisease,
  defaultDiseaseHistoryItemMap,
  otherBillableList,
  DISEASES
}
