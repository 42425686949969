import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import PatientVitalModal from '../../../patientView/vitalList/components/PatientVitalModal'
import NotesContainer from './InterventionNotesContainer'
import AddInterventionNoteForm from './AddInterventionNoteFormComponent'
import AllNotes from './AllNoteListModal'
import { IHButton } from 'ihcomponent'
import { switchView, clearState } from '../actions/InterventionsActions'
import {graphql,compose} from "react-apollo";
import { getResultNotes } from 'modulesAll/graphql/result'
import { Spin } from 'antd';
// const InterventionNotes = ({ row, switchView, view }) => {
//     return (
//       <div className="alerts-intervention-notes">
//         {renderVitalReading(row)}
//         {
//           view === 'partial'?
//             renderNotes(row.id, switchView) :
//             renderAllNotes(row.enrolledProgram.id)
//
//         }
//       </div>
//     );
// }

class InterventionNotes extends Component {
  static displayName = 'InterventionNotes'

  componentWillUnmount() {
    this.props.clearState()
  }

  render() {
    const { row, switchView, view, noteType,setCategoryToAlerts,closeModal,refetch,loading,result } = this.props;

    return (
      <div className="alerts-intervention-notes">
        {renderVitalReading(row)}
        {
          view === 'partial' ?
          renderNotes(row.id, switchView, row, noteType,setCategoryToAlerts,closeModal,refetch,result,loading) :
          renderAllNotes(row.enrolledProgram.id, switchView, row,closeModal)
        }
      </div>
    )
  }
}
const withData = graphql(getResultNotes, {
    options: (ownProps) => {
        return {
            variables: {
                "id": _.get(ownProps,'row.id')
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only' // this is necessary as we are updating exisiting data
        }
    },
    props: ({ ownProps, data }) => {
        const { loading, result, refetch } = data
        return {
            result,
            loading,
            refetch
        }
    }
})
const mapState = ({ Interventions }) => {
  const { view } = Interventions
  return { view }
}

export default compose(withData,connect(mapState, { switchView, clearState }))(InterventionNotes);

function renderVitalReading(row) {
  return (
    <div className="intervention-notes-vital-reading">
      <PatientVitalModal row={row} type={row.type} provider={true} />
    </div>
  )
}

function renderNotes(id, switchView, row, noteType, setCategoryToAlerts, closeModal,refetch,result,loading) {
  return (
    <div className="intervention-notes-wrapper">
      {/*<IHButton label="See All Notes" className="all-notes-switch" type="primary" value="all" onClick={ (e) => switchView(e.target.value) } />*/}
      {/*remvoe base on */}
      <AddInterventionNoteForm refetch={refetch} id={id} row={row} noteType={noteType} setCategoryToAlerts={setCategoryToAlerts} closeModal={closeModal}/>
      <div className="intervention-notes-title"><span>Notes </span>{/* (* will be sent to NEHR) */}</div>
      <div className="intervention-notes-notes">
        { loading ? <Spin/> : <NotesContainer id={id} row={row} closeModal={closeModal} refetch={refetch} result={result}/> }
      </div>
    </div>
  )
}

function renderAllNotes(id, switchView, row, closeModal) {
  return (
    <div className="intervention-notes-wrapper">
      <IHButton label="Back" className="all-notes-switch" type="primary" value="partial" onClick={ (e) => switchView(e.target.value) } />
      <AllNotes enrolledProgramId={id} row={row} closeModal={closeModal}/>
    </div>
  )
}