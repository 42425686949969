import React, { Component } from 'react';
import {Col, Row, Form, Input} from "antd";
import I18N from '../../I18N';

const {Item} = Form;

class VisitInputNote extends Component{

    render() {
        const { getFieldDecorator } = this.props.form;

        const {visitInfo, editStatus, lastVisitInfo, visitType} = this.props;
        // let lastNote = lastVisitInfo ? lastVisitInfo.reason : null
        // const reason = visitInfo ? (editStatus ? visitInfo.reason : null) : ((visitType === 'CLINIC_FOLLOW_UP' || visitType === 'INIT') ? lastNote : null);
        const reason = editStatus ? visitInfo.reason : null

        return (
            <Row gutter={16}>
                <Col span={24}>
                    <Item label={I18N.get('visit.details.visitNote')} >
                        {getFieldDecorator('reason', {
                            initialValue: reason,
                            rules: [{required: false}]
                        })
                        (
                            <Input.TextArea autoSize={{minRows: 2}} />
                        )}
                    </Item>
                </Col>
            </Row>
        )
    }
}

export default VisitInputNote;
