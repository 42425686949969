import { getFieldsRequiredFromSchema, getInitDataFromSchema } from 'libModule/helpers/reducer-helpers'
import { phoneOptions } from 'libModule/constants'
import { homePhoneValidator } from 'libModule/validator'

export const schema = {
  homeCountryCode: {
    initialValue: '+1',
    required: false
  },
  homePhone: {
    initialValue: '',
    required: false
  },
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const getFormProps = ({ initData }) => {
  const itemList = [
    [
      {
        key: 'homeCountryCode',
        label: 'Home',
        type: 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData.homeCountryCode,
        col: { span: 6, offset: 0 },
        option: phoneOptions,
        disabled: true,
      },
      {
        key: 'homePhone',
        label: ' ',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData.homePhone,
        col: { span: 18, offset: 0 },
        rules : [
          {validator: (rule, value, callback) => callback(homePhoneValidator(value, 'homePhone'))}
        ],
        className: 'hide-required-mark'
      },
    ]

  ]
  return {
    vertical: true,
    itemList
  }
}
