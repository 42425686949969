import React from "react";
import { GQLHelper } from "../../../lib/utils";
import VersionHistoryPreviewButton from "../../careplanNew/component/versionHistoryPreviewButton";
import Client from 'libModule/gqlClient';
import createVersionHistory  from  'modulesAll/graphql/mutation/createVersionHistory';
import CreateVersionHistoryButton from "../../careplanNew/container/createVersionHistory";
import SignOffComponent from './SignOffComponent';
import { signOffVisit } from "../../graphql/mutation/signOffVisit";
import PrintCarePlanButton from "../../careplanNew/container/PrintCarePlanButton";
import VISIT_TYPE_ENUM from "../../visit/constants/map";
import { Modal, Spin } from "antd";
import versionHistoryQuery  from  'modulesAll/graphql/versionHistory';

const PRINT_TRIGGER_ID = 'print-care-plan-shadow-trigger';

let AllTaskFinishedComponent = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          versionHistoryData: null,
        };
        this.loadingModal = null;
    }

    handleVHSuccess = (response) => {
        this.setState({ versionHistoryData: response });
    }

    handleVHForSignOffSuccess = response => {
      this.setState({ 
        versionHistoryData: response,
        creatingVersionHistory: false,
        isSigningOff: false
      });
    }

    handleOpenPrint = async () => {       
      const { enrolledProgramId, visitId } = this.props
      if(!this.state.versionHistoryData) {
        this.loadingModal = Modal.info({
          centered: true,
          icon: <Spin />,
          content: 'Opening PDF print preview...',
          keyboard: false
        });
        try {
          // TODO: need an API to get single Version history
          const res = await Client.query({
            query: versionHistoryQuery,
            variables: { enrolledProgramId },
            fetchPolicy: 'network-only' 
          });

          const data = _.get(res, 'data.getVersionHistoryList');
          const versionHistoryData = _.find(data, ['visit.id', visitId]);

          return this.setState({ versionHistoryData }, this.handleOpenPrint);
        } catch (err) {
          console.error(err);
          return Modal.error({ content: _.get(err, 'message') });
        }
      }

      if(this.loadingModal) this.loadingModal.destroy();
      const printDataBtnRef = document.getElementById(PRINT_TRIGGER_ID);
      printDataBtnRef.click();
    }

    getPrintData = () => {
        const { versionHistoryData } = this.state;
        if (versionHistoryData) {
            const data = JSON.parse(versionHistoryData.careplanData);
            const isMNT = _.get(versionHistoryData, 'visit.type') === VISIT_TYPE_ENUM.MNT;
            const visitType = _.get(versionHistoryData, 'visit.type');
            const visitDate = _.get(versionHistoryData, 'visit.appointmentAt');
            return {
                enrolledProgram: data.enrolledProgram,
                user: data.user,
                userPESStatements: data.pes,
                visitType,
                visitDate,
                signOffStatus: isMNT ? {
                    ..._.get(versionHistoryData, 'visit.signOffStatus'),
                    checkinTime: _.get(versionHistoryData, 'visit.checkinAt'),
                } : undefined,
            }
        }
        return {};
    }

    renderVersionHistory = () => {
        const { firstTime, memberId, enrolledProgramId, visitId, itemsUpdated } = this.props;
        const { versionHistoryData } = this.state;
        const printData = this.getPrintData();

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                {!versionHistoryData
                    ? <CreateVersionHistoryButton sendWithoutClick={firstTime} memberId={memberId} visitId={visitId} enrolledProgramId={enrolledProgramId} itemsUpdated={itemsUpdated} onSuccess={this.handleVHSuccess}>
                        <a href="javascript:void(0);" style={{ color: '#555555', textDecoration: 'underline' }}>Generate a Care Plan version history PDF</a>
                    </CreateVersionHistoryButton>
                    : <div>
                        <img src={'/image/icon_check.png'} width={20} />&nbsp;
                        A Care Plan version history PDF has been created. Click&nbsp;
                        <PrintCarePlanButton
                            style={{ display: 'inline-block' }}
                            skipFetch
                            {...printData}
                        >
                            <a>here</a>
                        </PrintCarePlanButton>
                        &nbsp;to view.
                    </div>
                }
            </div>
        );
    }

    handleSignOff = timeSpent => {
      const { openErrorModal, visitId } = this.props;

      this.setState({ isSigningOff: true }, async () => {
        try {
          const variables = {
            id: visitId,
            signOffStatus: {
              signOff: true,
              timeSpent
            }
          };

          await Client.mutate({
            mutation: signOffVisit,
            variables
          });

          this.setState({ creatingVersionHistory: true });
        } catch (err) {
          console.error(err);
          if(openErrorModal) openErrorModal(GQLHelper.formatError(err));
        }
      });              
    };

    render() {
        const { firstTime, shouldShowSignOff, memberId, enrolledProgramId, visitId, itemsUpdated, signOffStatus } = this.props;
        const { isSigningOff, versionHistoryData, creatingVersionHistory } = this.state;

        const signOffCreateVersionHistoryProps = {
          memberId,
          visitId,
          enrolledProgramId,
          itemsUpdated: itemsUpdated || '-',
          loadingIndicator: ' ',
          sendingIndicator: ' ',
          onSuccess: this.handleVHForSignOffSuccess
        };

        return (
            <div style={{ height:600,width:'inherit',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center' }}>
                {shouldShowSignOff 
                    ? 
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <SignOffComponent 
                          signOffStatus={signOffStatus}
                          isSigningOff={!!isSigningOff}
                          onClickSignOff={this.handleSignOff}
                          renderViewVersionHistoryLink={children => (
                            <span onClick={this.handleOpenPrint}>
                              {children}
                            </span>
                          )}
                        />
                        {
                          creatingVersionHistory &&
                          <CreateVersionHistoryButton
                            {...signOffCreateVersionHistoryProps}
                            sendWithoutClick={true}
                          />
                        }
                        {
                          versionHistoryData &&
                          <PrintCarePlanButton
                            style={{ visibility: 'none' }}
                            skipFetch
                            {...this.getPrintData()}
                          >
                            <span id={PRINT_TRIGGER_ID} />
                          </PrintCarePlanButton>
                        }
                      </div>
                    : (
                        <React.Fragment>
                            <img src={'/image/smile_face.png'} width={130} style={{ marginBottom: 20 }}/>
                            <h4>{firstTime ? 'Great! You have finished the patient\'s chart!' : 'You have made updates in this patient\'s chart!'}</h4>
                            {this.renderVersionHistory()}
                        </React.Fragment>
                    )
                }
                
            </div>
        );
    }
}
export default AllTaskFinishedComponent;
