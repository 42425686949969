const INITIAL_STATE = {
    orgPath:[]
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    const { orgPath } = state;
    switch (action.type) {
        case 'ADD_ORG_PATH':
            return {
                ...state,
                orgPath: [...state.orgPath,action.org]
            }
        case 'REMOVE_ORG_PATH': {
            const orgId  = action.orgId;
            if( orgPath.length==1&&orgPath[0].orgId==orgId ){
                return {
                    ...state,
                    orgPath: []
                }
            }
        };

        default: return state;
    }
}

export default { orgPath:reducer };