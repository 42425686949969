const visit = {
  createBtn: 'Create Visit',
  createTitle: 'Create Visit',
  tab: {
    today: `Today`,
    nextDay: `Next Business Day`,
    all: 'All'
  },
  columns: {
    category: ' ',
    visit: 'Visit',
    patient: 'Patient',
    primaryProvider: 'Provider',
    enrollVitals: 'Enroll Status/Vitals',
    note: 'Visit Note',
    participant: 'Participant',
    status: 'Status',
    action: 'Action',
    chart: 'Chart'
  },
  tips: {
    noApp: 'This patient has not logged into the app yet!',
    loanerPhoneUser: 'This patient uses a loaner phone and cannot have virtual visits',
    MNTPhoneContent : 'You cannot schedule a phone visit for MNT visit types, as MNT phone visits are not billable.',
    SelectMNTFollwUps: 'MNT follow-ups typically occur every 6-8 weeks. Talk with the patient and assess the best time to follow-up based on condition and needs.',
    visitType: {
      'General': 'For patients who use the virtual visit feature to see the provider only.',
      'Initial': 'For potential patients, OR patients who have their first visit with RD/CA to be enrolled in CCM/RPM only (not for MNT), OR enrolled patients who only see RD for initial assessment for CCM/RPM.',
      'Follow up': 'For regular follow up visits with RD/CA for CCM/RPM only (not for MNT).',
      'Additional': 'For enrolled patients that need to meet with RD/CA for other reasons.',
      'Tech': 'For enrolled patients who need tech support with a CA.',
      'MNT': 'For MNT patients only.',
      'Tech-onboarding': 'For patients who need initial device/app onboarding support only; initial assessment will be done at a separate time'
    },
    visitTypeWarning: 'WARNING: This patient has had no initial visit done in the past. Schedule the visit type that is most appropriate.',
  },
  details: {
    webLink: 'Send virtual visit web link',
    appLink: 'Send App download link via SMS',
    visitCategory: 'Visit Category',
    visitType: 'Visit Type',
    visitTypes: 'Visit Types',
    visitNote: 'Note',
    visitRoles: {
      label: 'Participant',
      required: {
        label: 'Participant',
        message: 'Participant is required'
      }
    },
    visitDate: {
      label: 'Date',
      required: {
        label: 'Date',
        message: 'Date is required'
      }
    },
    visitTime: {
      label: 'Hour',
      required: {
        label: 'Hour',
        message: 'Hour is required'
      }
    },
    visitTimeMin: {
      label: 'Min',
      required: {
        label: 'Min',
        message: 'Minute is required'
      }
    },
    visitPatientTime: {
      label: 'Patient Local Time',
      required: {
        label: 'Patient Local Time',
        message: 'Patient Local Time is required'
      }
    }
  },
  worklist: {
    errors: {
      saveDraft: {
        network: `Draft is not saved because your Internet is disconnected. Please check your network connection`,
        authorization: `Draft is not saved because you are not a part of this patient's care team. Please double check with your manager.`,
        unknown: `Draft is not saved because of some issue. Please contact support.`
      }
    }
  }
};

export default visit;