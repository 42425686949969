
// Imports
import MainLayout from './MainLayout';
import Nav from './Nav';
import MainModal from './MainModal';

const reducer = {
  MainLayout,
  Nav,
  MainModal,
}
export default reducer
