
export const roleToBtn =(role)=>{
	switch (role) {
		case 'PROVIDER:Clinic Medical Assistant':
			return {
				showEnrollButton: true,
				showReferButton: true
			}
		case 'PROVIDER:Doctor':
			return {
				showEnrollButton: true,
				showReferButton: true
			}
		default:
			return {
				showEnrollButton: false,
				showReferButton: false
			}
	}

}

export const btnConfigMap =(patientReferral)=> {
	let patientReferStatus = _.get(patientReferral,'status')||{};
	let { referStatus }  = patientReferStatus;
  
	const isReferred = referStatus === 'COMPLETED';

	return {
		VISIT_TABLE: {
			style:{
				display:'flex',
				marginTop:5
			},
			enrollBtn:{
				text:'Enroll',
				type:'primary',
				style:{
					height: 28,
					width: 65,
					marginRight: 20
				}
			},
			referBtn: {
				text: 'Refer',
				type: 'primary',
				style:{
					height: 28,
					width: 65,
					display:isReferred ? 'none' :'flex'
				}
			}
		},
		PATIENT_PROFILE: {
			style: {
				display:'flex',
				justifyContent:'center',
				marginBottom: '20px',
				marginTop: 20
			},
			enrollBtn:{
				text:'Enroll Patient',
				type:'primary',
				flex: 1
			},
			referBtn: {
				text: isReferred ? 'Review Referral' : 'Refer patient to care team',
				style: {
					marginLeft: 20
				}
			}
		},
		CREATE_PATIENT: {
			style: {
				display:'flex',
			},
			enrollBtnWrapper:{
				flex:1
			},
			enrollBtn:{
				text:'Enroll Patient',
				type:'primary',
				style:{
					flex: 1
				}
			},
			referBtn: {
				text: 'Refer patient to care team',
			}
		}
	}
}