import types from '../constants/MainModal';
import {
    React, IHButton
} from 'ihcomponent'

export const openModal = (content, modalProps) => {
  return {
    type: types.OPEN_MODAL,
    content,
    modalProps
  }
}

export const closeModal = () => {
  return {
    type: types.CLOSE_MODAL
  }
}


export const ErrorMessageDiv = ({errorMessage}) => {
  return <div>
    {
      errorMessage
    }
  </div>
}

export const openErrorModal = (errorMessage) => {
  return (dispatch, getState) => {
    const closeModalHandler = () => dispatch(closeModal())
    const modalProps = {
      size : 'sm',
      title: 'Warning',
      showHeaderCloseButton: false,
      footer : <IHButton key="ok" size="large" style={{width:'82px'}} label="Ok" bsStyle="primary" onClick={closeModalHandler} />
      // [
      //
      //     <IHButton key="close" label="close" onClick={closeModalHandler} />
      // ],
    }
    dispatch(openModal(<ErrorMessageDiv errorMessage={errorMessage} />, modalProps))
  }
}

export const openSuccessModal = (title, content, buttonLabel='Done') => {
  return (dispatch, getState) => {
    const closeModalHandler = () => dispatch(closeModal())
    const modalProps = {
      size : 'sm',
      title,
      showHeaderCloseButton: false,
      footer : <IHButton key="ok" size="large" style={{width:'82px'}} label={buttonLabel} bsStyle="primary" onClick={closeModalHandler} />
      // [
      //
      //     <IHButton key="close" label="close" onClick={closeModalHandler} />
      // ],
    }
    dispatch(openModal(content, modalProps))
  }
}


export const openConfirmModal = (resolve, saveForm) => {
  return (dispatch, getState) => {
    const contentElem = (
      <div>
        Would you like to save changes made before leaving this page?
      </div>
    )
    const okModalHandler = () => {
      saveForm()
      resolve(false)
      dispatch(closeModal())
    }
    const cancelModalHandler = () => {
      resolve(true)
      dispatch(closeModal())
    }

    const footer = (
      <div>
        <IHButton key="ok" size="large" label="Save" bsStyle="primary" onClick={okModalHandler} />
        <IHButton key="cancel" size="large" style={{marginLeft: 10}} label="Leave without Saving" bsStyle="default" onClick={cancelModalHandler} />
      </div>
    )
    const modalProps = {
      size : 'sm',
      title: 'Save Changes',
      showHeaderCloseButton: false,
      footer
    }
    dispatch(openModal(contentElem, modalProps))
  }
}

// doing this so that this file can be imported in either way
// import * as actions from 'modulesAll/layout/actions/MainModal' or
// import actions from 'modulesAll/layout/actions/MainModal'
// https://medium.com/@timoxley/named-exports-as-the-default-export-api-670b1b554f65
import * as self from './MainModal';
export default self;
