import React, { useState } from 'react';
import { Slider, InputNumber, Icon, /* Input, Checkbox, Col */ } from 'antd';
import { helpers } from '../../visitNewWorkFlow/helper';
// import { map } from 'lodash';
// import { EVENTS } from '../constant';
// import { getActivityOptions } from '../../patient/main/constants';
// const { TextArea } = Input;

// const TITLE_STYLE = {
//   fontWeight: 'bold',
//   marginBottom: 10,
// };

// const SECTION_STYLE = {
//   margin: '20px 0',
// };

// const CHECKBOX_STYLE = {
//   margin: '10px 0',
// };

const IdleTimeComponent = ({ selectedTime, onTimeChange, nonIdleTime, totalTime, /* onEventsChange, onActivitiesChange, events, activities, other, onOtherChange */ }) => {
  const [showInputSelector, setShowInputSelector] = useState(false);
  const role = helpers.checkIsMAOrMD() ? 'MD' : 'RD';

  return (
    <div>
      <h4 style={{ marginBottom: 30, fontWeight: 'bold', lineHeight: '28px' }}>
        We noticed you've worked on this patient for {nonIdleTime}mins, but had <span style={{ color: 'var(--darker-blue)'}}>{totalTime - nonIdleTime}mins</span> inactive session.
      </h4>

      <p>
        Is there any work that you did outside of our portal? Adjust the time bellow so that we can
        add it to the patient's billable time.
      </p>

      {/* <div style={SECTION_STYLE}>
        <h5 style={TITLE_STYLE}>The system tracked events:</h5>
        <Checkbox.Group onChange={onEventsChange} value={events}>
          {map(EVENTS, (v, k) => (
            <Col key={k} span={8} style={CHECKBOX_STYLE}>
              <Checkbox value={k}>{v.text}</Checkbox>
            </Col>
          ))}
        </Checkbox.Group>
      </div>

      <div style={SECTION_STYLE}>
        <h5 style={TITLE_STYLE}>Select bellow if you have any offline work:</h5>
        <Checkbox.Group onChange={onActivitiesChange} value={activities}>
          {map(getActivityOptions(role), (v, i) => (
            <Col key={i} span={12} style={CHECKBOX_STYLE}>
              <Checkbox value={v.value}>{v.label}</Checkbox>
            </Col>
          ))}
        </Checkbox.Group>
        {activities.includes('Other') && <TextArea rows={3} value={other} onChange={(e) => onOtherChange(e.target.value)} />}
      </div> */}

      <div style={{ padding: '0 40px', marginTop: 40 }}>
        <div>Time spent:</div>
        <Slider
          min={nonIdleTime}
          max={totalTime}
          onChange={onTimeChange}
          value={selectedTime}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 style={{ display: 'inline-block' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {showInputSelector
                ? <InputNumber
                  min={nonIdleTime}
                  max={totalTime}
                  value={selectedTime}
                  onChange={onTimeChange}
                />
                : <React.Fragment>{selectedTime}</React.Fragment>
              }
              mins&nbsp;
              <Icon type="edit" theme="filled" onClick={() => setShowInputSelector(!showInputSelector)} />
            </div>
          </h4>
          <div>
            <h4 style={{ display: 'inline-block' }}>{totalTime}mins</h4>
            {/*&nbsp;(Total online session) */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdleTimeComponent;
