import { React, IHButton } from 'ihcomponent';
import I18N from 'modulesAll/I18N';
import { goPath } from '../../../../lib/utils';

export default class extends React.Component {
  static displayName = 'patient/main/components/TopBar'

  render() {
    const {
      currentProgram,
      patientId,
      enrollButtonClick,
      goCurrentProgramTab,
      goPastProgramTab,
      tabSelected,
      updateTabSelected,
    } = this.props;

    return (
      <div>
        <div className="vsm-patient-topbar">
          <button className={`v-1 ${tabSelected === 'current' ? 'active' : ''}`} onClick={goCurrentProgramTab}>CURRENT {I18N.get('keywords.PROGRAM')}</button>
          <button className={`v-2 ${tabSelected === 'past' ? 'active' : ''}`} onClick={goPastProgramTab}>PAST {I18N.get('keywords.PROGRAM')}</button>
          <button className={`v-3 ${tabSelected === 'summary' ? 'active' : ''}`} onClick={()=>updateTabSelected('summary')}>SUMMARY </button>
          <button className={`v-4 ${tabSelected === 'addresult' ? 'active' : ''}`} onClick={()=>updateTabSelected('addresult')}>ADD RESULT </button>

          <IHButton bsStyle="primary" label={`Enroll in New ${I18N.get('keywords.Program')}`} className="v-btn v1" onClick={enrollButtonClick} />
         </div>
      </div>
    );
  }
}
