import React from 'react';
import ConsentFormComponent from "../component/ConsentFormComponent";
import ConsentTemplateListContainer from '../container/ConsentTemplateListContainer';
const ConsentFormContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            refetchTemplateGroupList: null
        }
    }

    setRefetchTemplateGroupList = (refetchTemplateGroupList)=>{
        this.setState({
            refetchTemplateGroupList
        })
    }

    renderTemplateGroupList = ()=>{
        return <ConsentTemplateListContainer setRefetchTemplateGroupList={this.setRefetchTemplateGroupList}/>
    }

    render(){
     const { renderTemplateGroupList,state  } = this;
     const { refetchTemplateGroupList } = state;
     return <div>
                <ConsentFormComponent refetchTemplateGroupList={refetchTemplateGroupList} />
                { renderTemplateGroupList() }
            </div>
    }


}

export default ConsentFormContainer;