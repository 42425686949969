import gql from 'graphql-tag'

export default gql`mutation createTaskAssignment($memberId:EID!
    $teamId: EID
    $dueDate: Date
    $assignedTo:EID
    $reason: String!
    $priorityLevel:TaskPriorityLevel! 
){
createTaskAssignment(
    memberId: $memberId
    teamId: $teamId
    dueDate: $dueDate
    assignedTo:$assignedTo
    reason: $reason
    priorityLevel: $priorityLevel
 ){
     id
        member {
            id
            identification {
               value
            }
            profile {
               fullName
            }
        }
  			createdAt
  			organization {
          id 
          name
        }
        assignedTo {
          	id
            profile {
              fullName
            }
        }
        team {
          id
          name
        }
  			priorityLevel 
  			dueDate
  }
}
`
