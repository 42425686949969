import React from "react";
import moment from 'moment';
import { Table,Button,Icon,Spin,Switch,Select,Popover } from 'antd';
import SingleVisitComponent from '../components/SingleVisitComponent';
import types from "../constants/appointmentTypes";
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { IHLoading } from 'ihcomponent'
import { helpers } from "../helpers/index";
import VISIT_TYPE_ENUM from '../constants/map';
import I18N from "../../I18N";
import helper from "../../taskAssignment/helper";
import { GQLHelper, getProviderName, getVisitTypeOptions } from 'libModule/utils';
import Client from 'libModule/gqlClient';
import careTeamMemberVisits from 'modulesAll/graphql/careTeamMemberVisits';

const weekDayMap = [
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI'
];

const circleStyle = (color)=>({
    width:15,
    height:15,
    backgroundColor:color,
    borderRadius:20,
    marginRight: 15,
})

const style = {
    weekCalenderStyle:{
        paddingLeft:15,
        paddingRight:15,
        display:'flex',
        flexDirection:'column',
        width:'65%'
    },

    mainContainer:{ marginLeft:'auto',marginRight:'auto',marginTop:20 },
    calenderContainer:{ border: '1px solid #d9d9d9', borderRadius: 4, },
    dateStyle:{
        fontSize: 20,
        fontWeight: 600,
        margin: '0 10px'
    },
    headerStyle:{
        marginTop:10,
        marginBottom:10,
        // marginLeft:25,
        display:'flex',
        flexDirection:'column',
    },
    todayButtonStyle:{
        float: 'right',
        // flex:1
    },
    preNextButtonStyle:{
        // flex: 1,
        fontSize: 12,
        flex:5
    },
    preNextButtonIndividualStyle: {
        color: '#B5B9BE',
        border: '1px solid #B5B9BE',
        padding: 3,
        marginRight: 5
    }
};

const timeSpotCount = 49;
const startTime = 7;
const timeSpotRange = 15;
const timeFormatHalfHourAndAMPM = 'HH:mm';
const timeHeaderRange = (props)=>{
    const start = Number(moment(startTime,'HH').toDate());
    const timeSpots = {};
    for(let i=0;i<timeSpotCount;i++){
           timeSpots[`${moment(start+timeSpotRange*i*60*1000).format(timeFormatHalfHourAndAMPM)}`] = new Array(5);
    }

    return timeSpots;
};

const groupByHelper = (d)=>{
    const appointmentAtVisit = moment(d.appointmentAt);
    const appointmentMin = appointmentAtVisit.minute();
    // const timeInHours = moment(d.appointmentAt).minute() < 15 ?
    //                     moment(d.appointmentAt).millisecond(0).seconds(0).minutes(0).format(timeFormatHalfHourAndAMPM)
    //                     : moment(d.appointmentAt).millisecond(0).seconds(0).minutes(15).format(timeFormatHalfHourAndAMPM);
    return moment(appointmentAtVisit).millisecond(0).seconds(0).minutes(appointmentMin).format(timeFormatHalfHourAndAMPM);
};

const defaultShowAllVisits  = 0;
const AppointmentWeekCalenderComponent = class extends React.Component {
    constructor() {
        super();
        const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
        const { id: organizationId } = viewerInfo.selectedRole.organization;
        this.currentOrgId = organizationId;
        this.state={
            showAllVisits:defaultShowAllVisits,
            // filters:{
            //     providerId:[],
            //     type:[],
            //     assignedToRD:[],
            //     assignedToCA:[]
            // },
            tempFilters: {
                providerId: [],
                type: [],
                assignedToRD:[],
                assignedToCA:[],
                assignedToHC:[],
            },
            visibleFilterKey: null,
            team: this.getIdNameMap(),
            isCrossClinicOn: false,
            crossClinicVisits: null,
            crossClinicVisitsLoading: false,
        }
    }

    componentDidUpdate(prevProps) {
        const isEqualFilters = _.isEqual(prevProps.filters, this.props.filters);
        if (!isEqualFilters || prevProps.startOfWeek !== this.props.startOfWeek || prevProps.endOfWeek !== this.props.endOfWeek) {
            if (this.state.isCrossClinicOn) {
                this.getCrossClinicData();
            }
            if (!isEqualFilters) {
                this.setState({ tempFilters: _.cloneDeep(this.props.filters) });
            }
        }
    }

    getIdNameMap = () => {
        const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const teamsFromLoginUser = loginUser.team||[];
        return helper.getMembersByRole(teamsFromLoginUser,['Medical Assistant','PROVIDER:Nurse','Nurse','Health Coach']).reduce((result, curr) => { 
            result[curr.id] = curr.fullName; 
            return result; 
        }, {});
    }

    renderPreNextButton = ()=>{
        return<div  style={style.preNextButtonStyle}>
                <Icon type="left" style={style.preNextButtonIndividualStyle} onClick={()=>{
                    Mixpanel.track('clicked','backward','appointment');
                    this.props.preMonth()}}/>
                <Icon type="right" style={style.preNextButtonIndividualStyle} onClick={()=>{
                    Mixpanel.track('clicked','forward','appointment');
                    this.props.nextMoth()
                }}/>
              </div>
    }

    renderTodayButton = ()=> {
        const { setAppointMonthAndYear } = this.props;
        const clickAndSetToToday =()=> {
            Mixpanel.track('clicked','today','appointment')
            setAppointMonthAndYear(moment());
        };

        return <div style={style.todayButtonStyle}>
                <Button onClick = {clickAndSetToToday}>
                Today
                </Button>
               </div>
    }

    renderCreateAppointment = ()=>{
        const { setIsCreate,isEdit,isCreate } = this.props;
        const disabled = isEdit||isCreate;

        return <Button type='primary' onClick={()=>{ Mixpanel.track('clicked','create','appointment'); setIsCreate(true);}}
                       className='scheduleVisitButton'
                       disabled={disabled} style={{backgroundColor:'#4278c3',border:'unset'}}>
                 Create an Appointment
               </Button>
    }

    renderDate(){
        const { props } = this;
        const month = _.get(props,'month','')-1;
        const year = _.get(props,'year','');
        const date = moment([year,month]).format('MMMM/YYYY');
        const parsedMonth = date.split('/')[0];
        const parseYear = date.split('/')[1];

        return <div style={style.dateStyle}>
                { `${parsedMonth} ${parseYear}` }
               </div>
    }

    renderCaRdHcs = () => {
        const { team } = this.state;
        const { filters, onChangeFilter } = this.props;
        const assignedToCA = (_.get(filters, 'assignedToCA') || []).filter(x => x !== undefined);
        const assignedToRD = (_.get(filters, 'assignedToRD') || []).filter(x => x !== undefined);
        const assignedToHC = (_.get(filters, 'assignedToHC') || []).filter(x => x !== undefined);

        const renderTeamMemberTag = (filterKey) => (id) => {
            return (
                <div style={{ margin: '0px 5px', backgroundColor: '#F2F2F2', padding: '3px 5px', fontSize: 14 }}>
                    <span style={{ marginRight: 5 }}>{team[id]}</span>
                    <Icon type="close" onClick={() => { 
                        const fs = [...filters[filterKey]];
                        _.remove(fs, x => x === id);
                        onChangeFilter(filterKey, fs || []);
                     }} />
                </div>
            );
        }

        return (
            <div style={{ display: 'flex' }}>
                {_.map(assignedToCA, renderTeamMemberTag('assignedToCA'))}
                {_.map(assignedToRD, renderTeamMemberTag('assignedToRD'))}
                {_.map(assignedToHC, renderTeamMemberTag('assignedToHC'))}
            </div>
        );
    }

    handleCrossClinic = (checked) => {
        if (checked) {
            this.setState({ isCrossClinicOn: checked });
            this.getCrossClinicData();
        } else {
            this.setState({ isCrossClinicOn: checked, crossClinicVisits: null });
        }
    }

    getOtherOrgIds = () => {
        const authAllRoles = JSON.parse(sessionStorage.getItem('authAllRoles'));
        const selectedRole = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole;
        return authAllRoles.map(v => v.organization.id).filter(v => v !== selectedRole.organization.id);
    }

    getCrossClinicData = async () => {
        const { filters, startOfWeek, endOfWeek, openErrorModal } = this.props;
        
        try {
            this.setState({ crossClinicVisitsLoading: true });
            const memberIds = (_.get(filters, 'assignedToCA') || []).concat(_.get(filters, 'assignedToRD') || []).concat(_.get(filters, 'assignedToHC') || []).filter(x => x != null);
            let organizationIds = undefined;
            if (memberIds.length === 0) {
                organizationIds = this.getOtherOrgIds();
            }

            const types = _.get(filters, 'type') || [];
            const res = await Client.query({
                query: careTeamMemberVisits,
                variables: {
                    memberIds,
                    excludeOrgIds: [this.currentOrgId],
                    organizationIds,
                    from: startOfWeek.valueOf(),
                    to: endOfWeek.valueOf(),
                    types,
                },
                fetchPolicy: 'network-only'
            });
            const crossClinicVisits = _.flatten(_.get(res, 'data.careTeamMemberVisits', []).map(x => x.visits));
            this.setState({ crossClinicVisits, crossClinicVisitsLoading: false });
        } catch (e) {
            openErrorModal(GQLHelper.formatError(e));
            this.setState({ crossClinicVisits: null, crossClinicVisitsLoading: false });
        }
    }

    renderCrossClinic = () => {
        const { isCrossClinicOn } = this.state;
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <Switch onChange={this.handleCrossClinic} checked={isCrossClinicOn} />
                <div style={{ marginLeft: 10, fontSize: 14 }}>
                View cross-clinic schedule
                </div>
            </div>
        );
    }

    renderHeader(){
        const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const teamsFromLoginUser = loginUser.team||[];

        return <div style={style.headerStyle} className='calenderHeader'>
                <div style={{ display:'flex', alignItems: 'center' }}>
                    { this.renderTodayButton() }
                    { this.renderDate() }
                    { this.renderPreNextButton() }
                </div>
                <div style={{ display:'flex', justifyContent:'space-around', padding: '10px 0', border: '1px solid #D2D2D2', borderLeft: 'none', borderRight: 'none', margin: '10px 0' }}>
                    { this.renderShowConfirmedVisitsSwitch() }
                    { this.renderTypeFilter() }
                    { this.renderProviderFilter() }
                    <div style={{ borderRight: '1px #D2D2D2 solid' }} />
                    <div>
                        <div style={{ display:'flex' }}>
                            { this.renderCAFilter(teamsFromLoginUser) }
                            { this.renderRDFilter(teamsFromLoginUser) }
                            { this.renderHCFilter(teamsFromLoginUser) }
                        </div>
                        <div>
                            { this.renderCrossClinic() }
                        </div>
                    </div>
                </div>
                <div>
                    { this.renderCaRdHcs() }
                </div>
                {/*{ this.renderCreateAppointment() }*/}
               </div>

    }

    onChangeFilter = (filterKey, value)=>{
        const { tempFilters } = this.state;
        this.setState({
            tempFilters: _.set(tempFilters, filterKey, value)
        });
    }

    onResetFilter = (filterKey) => {
        const { tempFilters } = this.state;
        this.setState({
            // filters: _.set(filters, filterKey, []),
            tempFilters: _.set(tempFilters, filterKey, []),
            visibleFilterKey: null,
        });
        this.props.onChangeFilter(filterKey, []);
    }

    onOkayFilter = (filterKey) => {
        const { tempFilters } = this.state;
        this.setState({
            // filters: _.set(filters, filterKey, tempFilters[filterKey] || []),
            visibleFilterKey: null,
        });
        this.props.onChangeFilter(filterKey, tempFilters[filterKey] || []);
    }

    handleFilterVisibleChange = (filterKey) => (visible) => {
        const { filters } = this.props; // this.state;
        if (!visible) {
            this.setState({
                tempFilters: _.cloneDeep(filters),
                visibleFilterKey: null,
            });
        } else {
            this.setState({
                visibleFilterKey: filterKey
            });
        }
    }

    filterVisitByPath = (list,filter,path)=>{
        if(filter.length==0){
            return [];
        }
        return _.filter(list,v=>_.includes(filter,_.get(v,path)));
    }

    //parse visit split into each cell with appointmentAt.
    //Base on real visit and create 'fake' visit in cell with appointmentAt time adding 30 mins
    parseVisitList = (list,showAllVisits,filters) => {
        const { crossClinicVisits } = this.state;
        const { filterVisitByPath } = this;
        const { providerId,type,assignedToCA,assignedToRD,assignedToHC } = filters;
        list = showAllVisits ? list : _.filter(list,v=>v.confirm);
        const typeList = filterVisitByPath(list,type,'type');
        const providerList = filterVisitByPath(list,providerId,'providers.0.id');
        const caList = filterVisitByPath(list,assignedToCA,'assignees.assignedToCA.providerId');
        const rdList = filterVisitByPath(list,assignedToRD,'assignees.assignedToRD.providerId');
        //hc and rd are both saved in assignedToRD, so using assignees.assignedToRD to filter assignedToCA and assignedToRD visits;
        const hcList = filterVisitByPath(list,assignedToHC,'assignees.assignedToRD.providerId');
        let newList = list;
        if (filters.providerId.length > 0 || filters.assignedToCA.length > 0 || filters.assignedToRD.length > 0 || filters.assignedToHC.length > 0) {
            newList = _.union(providerList, caList, rdList, hcList);
        }
        if (filters.type.length > 0) {
            newList = _.intersection(typeList, newList);
        }

        const parsedList = this.getParsedVisitList(newList, false);
        const parsedCrossClinicList = crossClinicVisits ? this.getParsedVisitList(crossClinicVisits, true) : [];
        return parsedList.concat(parsedCrossClinicList);
    }

    getParsedVisitList = (list, isCrossClinic) => {
        const parsedVisitArray = [];
        for(let v in list){
            const { appointmentAt,appointmentTo } = list[v];
            const visitLength = appointmentTo  ? (appointmentTo-appointmentAt)/1000/60 : 60;
            parsedVisitArray.push({...list[v],index:0,visitStartTime:appointmentAt,last: 0==(Number(visitLength/timeSpotRange)-1),isCrossClinic});
            for(let i=1;i<Number(visitLength/timeSpotRange);i++){
                const parsedVisit = {
                    ...list[v],
                    appointmentAt:Number(moment(appointmentAt).add(i*timeSpotRange,'minutes').toDate()),
                    visitStartTime:appointmentAt,
                    index: i,
                    last: i==(Number(visitLength/timeSpotRange)-1),
                    isCrossClinic,
                }
                parsedVisitArray.push(parsedVisit);
            }
        }
        return parsedVisitArray;
    }

    createDataSourceByVisitList = (dataSource,showAllVisits,filters)=>{
        const { visitList,startOfWeek }  = this.props;
        const timeSpots = timeHeaderRange();
        const parsedVisitList = this.parseVisitList([..._.get(visitList,'data',[])],showAllVisits,filters);
        const groupedVisits = _.groupBy(parsedVisitList,groupByHelper);

        for(let v in groupedVisits){
            for(let i in groupedVisits[v]) {
                const vt = groupedVisits[v][i];
                const index = (new Date(vt.appointmentAt)).getDay()-1;
                if(timeSpots[v]) {
                    if(!Array.isArray(timeSpots[v][index])) {
                        timeSpots[v][index] = [];
                    }
                    timeSpots[v][index].push(groupedVisits[v][i]);
                }
            }
        }
        const keys = _.keys(timeSpots);

        for(let i=0;i<keys.length;i++){
            dataSource[i] = {};
            dataSource[i].appointmentAt = keys[i];
            dataSource[i].visitStartTime = keys[i];
            for(let j=0;j<=60/timeSpotRange;j++){
                dataSource[i][`${weekDayMap[j]}`] = timeSpots[keys[i]][j];
            }
        }

        for(let i=0;i<dataSource.length;i++){
            const { appointmentAt } = dataSource[i];
            for(let key in dataSource[i]){
                if(!dataSource[i][key]){
                    const hourAndMin = appointmentAt.split(':');
                    const aHour = Number(hourAndMin[0]);
                    const aMin = Number(hourAndMin[1]);
                    const dayOffSet = weekDayMap.indexOf(key.toString());
                    dataSource[i][key] = moment(startOfWeek).add(dayOffSet,'day')
                                                            .hours(aHour)
                                                            .minutes(aMin)
                                                            .milliseconds(0).format('MM/DD/YYYY HH:mm');
                }
            }
        }
        //add seq to make UI look better
        let mapIdIndex = {};
        for(let i=0;i<dataSource.length;i++){
            _.forEach(weekDayMap,(w)=> {
                if (Array.isArray(dataSource[i][w])) {
                    const len = dataSource[i][w].length;
                    let preIndex = 0;
                    for (let j = 0; j < len; j++) {
                        const visit = dataSource[i][w][j];
                        if (!mapIdIndex[visit.id]) {
                            dataSource[i][w][j] = _.extend(dataSource[i][w][j], {seq: preIndex});
                            mapIdIndex[visit.id] = preIndex;
                            preIndex++;
                        } else {
                            const visitSeq = mapIdIndex[visit.id];
                            dataSource[i][w][j] = _.extend(dataSource[i][w][j], {seq: visitSeq});
                        }
                    }
                }
            })
        }
        //
        //sort seq
        for(let i=0;i<dataSource.length;i++){
            _.forEach(weekDayMap,w=>{
                if (Array.isArray(dataSource[i][w])) {
                    dataSource[i][w].sort((o1,o2)=>o1.seq-o2.seq);
                }
            })
        }
    }

    createColumn = ()=>{
        const { props } = this;
        const { startOfWeek,setSelectedVisit,selectedVisit } = props;
        const columns = [
            {
                dataIndex:'visitStartTime',
                key:'visitStartTime',
                width:'75px',
                className:'timeSpotClass',
                render:(row)=>{
                    const isFullTime = row.split(':')[1]=='00';
                    if(isFullTime){
                        return row;
                    }
                }
            }
        ]

        _.each(weekDayMap,(w,i)=>{
            const dayOfWeek = moment(startOfWeek).add(i,'day');
            const weekDayInMonth = dayOfWeek.date();
            const className = 'weekdayTitle'+(moment(dayOfWeek).isSame(new Date(),'day') ?
                ' isToday' : moment(dayOfWeek).isBefore(new Date(),'d') ? ' beforeToday' :'');
            columns.push({
                dataIndex:w,
                key:w,
                title:<div className={className}><p>{w}</p><div className='dayOfMonth'>{weekDayInMonth}</div></div>,
                width:'20%',
                className:'colClass',
                rowClassName:'rowClass',
                render:(row,data)=>{
                    if(Array.isArray(row)){
                        return <div style={{ display:'flex',height:19 }} className={'visitTabWrapper'}>
                                {_.map(row,(v,i)=><SingleVisitComponent style={{ width:`${(100/row.length)}%` }}
                                                                        visit={v}
                                                                        key={i}
                                                                        setSelectedVisit={setSelectedVisit}
                                                                        selectedVisit={selectedVisit}
                                                                        {...props}
                                                  />)
                                }
                               </div>
                    }else{
                        return <div style={{ display:'flex',height:19 }} className={'visitTabWrapper'}>
                                <SingleVisitComponent visit={row} setSelectedVisit={setSelectedVisit}
                                                      selectedVisit={selectedVisit}
                                                      {...props}
                                />
                               </div>
                    }
                }
            })
        })
        return columns;
    }
    renderAppointmentTypes = ()=>{
        return <div style={{display:'flex',flexDirection:'column',padding:'20px 40px'}}>
            <div style={{marginBottom:10,fontSize:16,color:'#717b7d'}}>
                <span>{I18N.get('visit.details.visitTypes')}</span>
            </div>
            {
                _.map(types,(k,i)=>
                    <div style={{ display:'flex',alignItems:'center' }} key={i}>
                        <div style={circleStyle(k.color)}></div>
                        <span style={{fontSize:16}}>{ k.text }</span>
                    </div>
                )
            }
        </div>
    }

    rennderProviderOkButton = (filterKey) => {
        return (
            <div className='filterButton'>
                <span onClick={()=>this.onOkayFilter(filterKey)}>
                    Ok
                </span>
              </div>
        );
    }

    renderResetButton = (filterKey)=>{
       return <div className='filterButton'>
                <span onClick={()=>this.onResetFilter(filterKey)}>
                    Reset
                </span>
              </div>
    }

    renderFilter = (filterKey,title,list,keyPath,valuePath,render)=>{
        const localThis = this;
        const { filters } = this.props;
        const { visibleFilterKey } = this.state;
        const filterVal = _.get(filters,filterKey);
        const hasValue = !_.isEmpty(filterVal);
        const content = <div style={{ display:'flex',flexDirection:'column'}} className='popoverFilter'>
                            {helpers.renderFilterContainer(filterKey,list,localThis,keyPath,valuePath,render)}
                            <div className="filterButtonContainer">
                                {this.rennderProviderOkButton(filterKey)}
                                {this.renderResetButton(filterKey)}
                            </div>
                        </div>
        return  (
            <Popover
                content={content}
                placement="bottom"
                trigger="click"
                onVisibleChange={this.handleFilterVisibleChange(filterKey)}
                visible={visibleFilterKey === filterKey}
            >
                <div className='calenderFilter'>{title} <Icon type="filter" className={hasValue? 'hasValue' :''}/></div>
            </Popover>
        );

    }
    renderProviderFilter = ()=>{
        const { props } = this;
        const  visitList  = _.get(props,'visitList.data',[]);
        const visitsUniqByProviderId = _.uniqBy(visitList,(v)=>_.get(v,'providers.0.id'));
        const providersList = _.map(visitsUniqByProviderId,(v)=>{
          const p = _.get(v,'providers.0');
          return {
            id: _.get(p, 'id'),
            profile: {
              ..._.get(p, 'profile'),
              fullName: getProviderName(p)
            }
          };
        });
        return this.renderFilter('providerId',I18N.get('provider.label'),providersList,'id','profile.fullName');
    }

    renderCAFilter = (teamsFromLoginUser)=>{
        const CAList = [...helper.getMembersByRole(teamsFromLoginUser,['Medical Assistant']),{fullName:'Not Assigned'}];
        return this.renderFilter('assignedToCA','CA',CAList,'id','fullName');
    }

     renderRDFilter = (teamsFromLoginUser)=>{
        const CAList = [...helper.getMembersByRole(teamsFromLoginUser,['PROVIDER:Nurse','Nurse']),{fullName:'Not Assigned'}];
        return this.renderFilter('assignedToRD','RD',CAList,'id','fullName');
    }

    renderHCFilter = (teamsFromLoginUser)=>{
        const HCList = [...helper.getMembersByRole(teamsFromLoginUser,['Health Coach']),{fullName:'Not Assigned'}];
        return this.renderFilter('assignedToHC','HC',HCList,'id','fullName');
    }

    renderTypeFilter = ()=>{
        const keys = Object.keys(getVisitTypeOptions());
        const typeList = keys.map((v,k)=>({
            key:v,
            value:VISIT_TYPE_ENUM[v]
        }));
        const render = (type)=>{
              const colorCodeMap = {
                  'CLINIC_FOLLOW_UP' : {
                      text: 'General Visit',
                      color: '#6236FF'
                  },
                  'INIT':{
                      text:'Initial Visit',
                      color:'#36c5cc'
                  },
                  'FOLLOW_UP':{
                      text:'Follow Up Visit',
                      color:'#4278c3'
                  },
                  'ADDITIONAL':{
                      text:'Additional Visit',
                      color:'#ee7c81'
                  },
                  'TECH': {
                      text: 'Tech Visit',
                      color: '#FFB310'
                  },
                  'MNT':{
                      text: 'MNT',
                      color: '#83C858'
                  },
                  'TECH_ONBOARDING': {
                      text: 'Tech Onboarding',
                      color: '#CF51AB'
                  }
              }
              return <div style={{ display:'flex',alignItems:'center' }} >
                        <div style={circleStyle(colorCodeMap[type].color)} className='circleOpt'></div>
                        <span style={{fontSize:14,fontWeight:'normal'}}>{ colorCodeMap[type].text }</span>
                     </div>


        }

        return this.renderFilter('type','Visit Type',typeList,'key','value',render);

    }

    renderShowConfirmedVisitsSwitch = ()=>{
        const { showAllVisits} = this.state;
        const menu = [{value:1,label:'Show All Visits'},{value:0,label:'Show Confirmed Visits'}];
        const options = _.map(menu,({value,label},i)=><Select.Option value={value} key={i}>{label}</Select.Option>);
        return <div>
                <Select className='showVisitsSwitch' value={showAllVisits} style={{ width: 220 }} onChange={(showAllVisits)=>this.setState({showAllVisits})}>
                    {options}
                </Select>
               </div>
    }

    renderCalendarFooter = ()=>{
        return<div style={{ display:'flex',alignItems:'center' }}>
                { this.renderAppointmentTypes() }
                {/*{ this.renderShowConfirmedVisitsSwitch() }*/}
              </div>
    }

    renderWeekCalender(){
        const { state } = this;
        const { showAllVisits } = state;
        const { loading, filters } = this.props;
        const dataSource = new Array(timeSpotCount);
        this.createDataSourceByVisitList(dataSource,!!showAllVisits,filters);
        const columns = this.createColumn(dataSource);
        return <div>
                { this.renderHeader() }
                {
                    <Table dataSource={dataSource} rowKey='appointmentAt'
                        columns={columns} pagination={false}
                        bordered={true}
                        loading={{spinning: loading, indicator: <IHLoading/>}}
                        className='weekVisitTable'
                    />
                }
                { this.renderCalendarFooter() }
                </div>

    }
    render(){
        return <div>
                 { this.renderWeekCalender() }
               </div>
    }
}

export default  AppointmentWeekCalenderComponent;
