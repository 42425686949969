import React from 'react';
import { IHButton, _ } from 'ihcomponent'
import { compose } from 'react-apollo'
import { openModal,closeModal } from 'layoutModule/actions/MainModal'
import createTableAction from 'libModule/table/TableActions';
import { graphql } from "react-apollo/index";
import { resultList }from '../query/index';
import LogBookListComponent from '../components/LogBookListComponent';
import BPLogBookListComponent from '../components/BPLogBookListComponent';
import ResultStatisticsComponent from '../components/ResultStatisticsComponent';
import $ from 'jquery';
import BPResultStatisticsComponent from "../components/BPResultStatisticsComponent";
import { RESULT_COUNT_MAX } from '../../../lib/constants';


const logBookActions = createTableAction('logBookTable');

const LogBookContainer = class extends React.Component {
    static  displayName = 'LogBookContainer';
    constructor(){
        super();
        this.handleResize.bind(this);
        this.state={
            width:0,
            showChart: true
        }
    }
    handleResize(){
        const width = $('.logBookContainer').width();
        this.setState({
            width: width
        });
    }

    // componentWillMount(){
    //     this.props.initialState();
    // }

    componentDidMount(){
        this.setState({ width:$('.logBookContainer').width() });
        window.addEventListener('resize',this.handleResize());
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize());
    }

    render(){
        const { props } = this;
        const { resultList,patientId,stat={},type,loading,isInChat } = this.props;
        const { width,showChart }= this.state;
        const logBookContainerClassName = 'row logBookContainer'+((width<800||!width) ? ' smallDiv':'');

        return  <div className={logBookContainerClassName} style={{ marginTop:20, marginBottom: 50 }}>
                  <div className={`${isInChat ? 'col-lg-12' :'col-lg-7'}`}>
                      {
                          type == 'BG' ? <LogBookListComponent resultList={resultList} patientId={patientId} {...props}/>
                                       : <BPLogBookListComponent resultList={resultList} patientId={patientId} {...props}/>
                      }
                  </div>
                  <div className={`${isInChat ? 'col-lg-12' :'col-lg-5'}`}>
                      {
                          type == 'BG' ?  <ResultStatisticsComponent stat={stat.bg} loading={loading}/>
                                       : <BPResultStatisticsComponent stat={stat.bp} loading={loading}/>
                      }
                  </div>
                </div>
    }
}

const withData = graphql(resultList, {
    options: (ownProps) => {
        const patientId = ownProps.patientId||null;
        const {fromDate, toDate, type}  = ownProps;
        const programId = ownProps.currentProgram.id;

        const  variables = { };
        variables.count = RESULT_COUNT_MAX;
        variables.filters = {
            memberId:patientId,
            fromDate,
            toDate,
            type,
            enrolledProgramId:[programId]
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading || !resultList ){
            return {
                loading
            }
        }

        return {
                resultList:resultList.data,
                stat:resultList.stat,
                loading,
            }
    }

})


LogBookContainer.displayName = 'LogBookContainer';
export default compose(withData)(LogBookContainer);
// export default LogBookContainer;
