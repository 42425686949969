import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { moment,IHLoading} from "../../../../../package/IHComponent";
import { connect } from 'react-redux';
import I18N from 'modulesAll/I18N';
import { Divider,Button, Rate, Popover } from 'antd';
import { API } from '../../chat/actions/api';
import actions from "../../foodLog/actions";
import { onAddClick } from "../../patient/alertTable/actions/AlertTableActions";
import { openModal,closeModal } from 'layoutModule/actions/MainModal';
import BGMeasurementComponent from './Measurement/components/BGMeasurement';
import BGMeasurementsComponent from './Measurement/components/BGMeasurements';
import BPMeasurementComponent from './Measurement/components/BPMeasurement';
import BPMeasurementsComponent from './Measurement/components/BPMeasurements';
import FileUploadMessageComponent from './FileUpload/FileUploadMessageComponent';
import chatActions from '../actions/index';
import FoodlogView from './FoodLog/FoodlogView';
import EmptyChannelComponent from './EmptyChannelComponent';
import hideMessageType from '../constants/hideMessageType';
const timeFormatString = I18N.get('dates.L');
const timestampFormat = I18N.get('dates.LTS');
import Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import HSMeasurementComponent from './Measurement/components/HSMeasurement';
import HSMeasurementsComponent from './Measurement/components/HSMeasurements';
import POMeasurementComponent from './Measurement/components/POMeasurement';
import POMeasurementsComponent from './Measurement/components/POMeasurements';
import TEMPMeasurementComponent from './Measurement/components/TEMPMeasurement';
import TEMPMeasurementsComponent from './Measurement/components/TEMPMeasurements';
import parse from 'html-react-parser';
import ChatMessagePopover, { renderChatTaggedTextView } from './ChatMessagePopover';
import chatHelpers from '../helper';
import { ROLE_MAP } from 'libModule/constants';
import MonthlyReportInChatContainer from "../../monthlyReport/container/MonthlyReportInChatContainer";
import ChatPatientUnreadIndicator from './ChatPatientUnreadIndicator';
import ArticleLinkPreviewComponent from '../../careplanNew/component/ArticlesSearch/ArticleLinkPreviewComponent';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers';
import { checkAndDecodeId } from '../../../lib/helpers/common-helpers';

class ChatHistoryComponent extends Component {

static displayName = 'chat/components/ChatHistoryComponent';

constructor(props) {
  super(props);
  this._isMounted = false;
  this.messagesStart = React.createRef();
  this.messagesEnd = React.createRef();
  this.messageBodyRefs = React.createRef();
  this.chatContainerRef = React.createRef();

  this.scrollToBottom = this.scrollToBottom.bind(this);
  this.fetchMoreMessages = this.fetchMoreMessages.bind(this);

  this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  this.currentUserRole = ROLE_MAP[_.get(this.currentUser, 'selectedRole.name')];
  const currentUserOrgId = _.get(this.currentUser, 'selectedRole.organization.id');
  const currentUserTeams = _.get(this.currentUser, 'team') || [];
  const patientCareTeam = _.find(currentUserTeams, { id: props.patientCareTeamId }) || {};
  const patientCareTeamMembers = _.filter(patientCareTeam.members, member => {
    const selectedRole = _.find(_.get(member, 'user.allRoles'), r => r.organization.id === currentUserOrgId);
    if(!selectedRole)
      return false;
    return true;
  });

  this.tagMessageIdx = -1;

  const callback = entries => {
    if(!this._isMounted)
      return;

    const [entry] = entries;
    const { shouldShowHasTagMessage } = this.state;
    if(!shouldShowHasTagMessage && !entry.isIntersecting) {
      this.setState({ shouldShowHasTagMessage: true });
    } else if(shouldShowHasTagMessage && entry.isIntersecting) {
      this.setState({ shouldShowHasTagMessage: false });
    }
  };

  this.tagMessageObserver = new IntersectionObserver(callback, {
    root: this.chatContainerRef.current,
    rootMargin: '0px',
    threshold: 0.5,
  });

  // this.getAvatar = this.getAvatar.bind(this);
  // this.onAvatarError = this.onAvatarError.bind(this);
  this.state = {
      renderSignleMeasure:false,
      patientCareTeamMembers
  };
}

getPatientId = () => {
  const selectedChannelId = _.get(this, 'props.selectedChannel') || '';
  if(!selectedChannelId) {
    console.warn('warn getPatientId for chat: no selected channel');
  }
  
  const patientId = _.split(selectedChannelId, '-')[1];
  return patientId;
}

renderDateSpe(flag,dateHeader){
  if(flag)
  return(
      <div className='messageTimestamp'>
          <Divider className='chatDateDivider'>
            <header>{ dateHeader }</header>
          </Divider>
      </div>
  )
}
scrollToBottom() {
    if(this.messagesEnd&&this.messagesEnd.current) {
        this.messagesEnd.current.scrollIntoView({behavior: 'auto'});
        this.props.moveToBottom(false);
    }
}

fetchMoreMessages() {
  
  const { fetchHistory, selectedChannel, channels } = this.props;
  if (selectedChannel && channels[selectedChannel].fromTimestamp) {
    Mixpanel.track('clicked','load_more_message','message', {CHANNEL_ID: selectedChannel});

    this.scrollToHeight = this.refs.messageList.scrollHeight;
    
    this.shouldScrollToView = true;
    this.scrollToViewHeight = this.refs.messageList.scrollHeight;
    this.scrollToViewScroll = this.refs.messageList.scrollTop;
    // firstMsgTimestamp: the time of the oldest viewable msg in channel.history (newest -> oldest)
    const firstMsgTimestamp = channels[selectedChannel].fromTimestamp;

    this.messageBodyRefs.current = null;
    
    fetchHistory(selectedChannel, this.getPatientId(), firstMsgTimestamp, false, false);
  }
}

//removed so 'load more' will stay on top
componentDidUpdate(prevProps) {
  if (this.props.toBottom){
    //move to bottom if init channel and move to bottom if channel fetch again;
    this.scrollToBottom();
  } else if (prevProps.toBottom && !this.props.toBottom && this.props.toTag && this.observingTagMessageEl) {
    // scroll to the oldest tag if tag message is loaded in initial fetch / from notification
    this.observingTagMessageEl.scrollIntoView(true);
  } else if (this.shouldScrollToView && this.refs.messageList) {
    // prevent message view from scrolling to bottom. Use case: fetch more history
    const newScrollHeight = this.refs.messageList.scrollHeight;
    if (newScrollHeight !== this.scrollToViewHeight) {
      this.refs.messageList.scrollTop = this.scrollToViewScroll + newScrollHeight - this.scrollToViewHeight;
    }
    // reset
    this.shouldScrollToView = false;
  } 
}

componentDidMount = () => {
  const { isChatLoading, selectedChannel: selectedChannelId, channels, fetchHistory } = this.props;
  this._isMounted = true;
  // don't fetch history when chat user info is not available
  if(!isChatLoading && channels[selectedChannelId]){ 
    fetchHistory(selectedChannelId, this.getPatientId(), null, false, true);
  }
}

componentWillUnmount = () => {
  this._isMounted = false;
  this.tagMessageObserver = null;
}

getEncodedId = (id) => {
  if (!id) return id;
  const isEncoded = COMMON_HELPERS.checkIfEncoded(id);
  const encoded = isEncoded ? id : btoa(`accounts:${id}`);
  return encoded;
}

getAvatar(userId, userRole) {
  const decodedId = userId ? checkAndDecodeId(userId) : userId;
  if (userRole.toLowerCase() === 'admin') {
    return <img src='/image/systemmsg-chat-avtr.svg' className='avatar' />
  }
  else if (this.props.userMap[decodedId] && this.props.userMap[decodedId].avatar) {
    return <img src={ this.props.userMap[decodedId].avatar } className='avatar' onError={ (e) => this.onAvatarError(decodedId,e) } />
  }
  else if (decodedId) {
    return <img src='' className='avatar' onError={ (e) => this.onAvatarError(decodedId,e) } />
  } else {
    return <img src='/image/default_avator.png' className='avatar' />
  }
}

onAvatarError(id,e) {
  const encoded = this.getEncodedId(id);
  API.getUserAvatar(encoded)
    .then( res => {

      const userProfile = _.get(res.data.user, 'profile') || {};
      const userId = _.get(res.data.user, 'id') || encoded;
      const currentUserId = atob(userId).split(':')[1];

      const newUser = {
        uuid: currentUserId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        avatar: _.get(userProfile,'avatar.link') || '/image/default_avator.png',
      };

      this.props.addToUserMap(newUser,true);
    })
    .catch(err => console.error('onAvatarError, err: ', err));
}

renderStar(startCount){
    return <Rate className='tableStar' value = {startCount} disabled/>;
}

renderMsg(classString, msgObj, ind, timetoken, messageId, tagObj){
    const { type,duration,dateFrom,dateTo,text,measuredAt,value,unit,oxygen_saturation, weight, weight_change, temperature,severity,vitalName,diastolic,systolic,arrhythmia, fileKey,foodLogId, articleMetaData } = msgObj.entry;
    const { currentProgram } = this.props;
    const MessagePopoverWrapper = (props) => {
      const { children, ...restProps } = props;
      return (
        <ChatMessagePopover
          key={ind}
          msgObjEntry={msgObj.entry} 
          timetoken={timetoken}
          currentUser={this.currentUser}
          patientCareTeamMembers={this.state.patientCareTeamMembers}
          messageId={messageId}
          tagObj={tagObj || {}}
          {...restProps}
        >
          {children}
        </ChatMessagePopover>
      );
    };

    const VitalMessagePopoverWrapper = props => {
      return (
        <MessagePopoverWrapper hideTranslation>
          <div>
            {props.children}
          </div>
        </MessagePopoverWrapper>
      );
    };

    switch(vitalName) {
        case 'BG': {
          if (type == 'measurements') {
              return <VitalMessagePopoverWrapper>
                <BGMeasurementsComponent 
                  dateFrom={dateFrom} 
                  dateTo={dateTo}
                  text={text} 
                  duration={duration}/>
              </VitalMessagePopoverWrapper>
          }
          if (type == 'measurement') {

              return <VitalMessagePopoverWrapper>
                <BGMeasurementComponent 
                  measuredAt={measuredAt} 
                  type={type}
                  value={value} 
                  unit={unit} 
                  text={text}
                  severity={severity}/>
              </VitalMessagePopoverWrapper>
          }
        }
        case 'BP':{
          if (type == 'measurements') {
              return <VitalMessagePopoverWrapper>
                <BPMeasurementsComponent dateFrom={dateFrom} dateTo={dateTo}
                  text={text} duration={duration} />
              </VitalMessagePopoverWrapper>;
          }
          if (type == 'measurement') {
              return <VitalMessagePopoverWrapper>
                <BPMeasurementComponent measuredAt={measuredAt} type={type}
                  unit={unit} text={text}
                  severity={severity}
                  diastolic={diastolic} systolic={systolic}
                  arrhythmia={arrhythmia}/>
              </VitalMessagePopoverWrapper>
          }
        }
        case 'PO': {
          if (type == 'measurements') {
            return <VitalMessagePopoverWrapper>
              <POMeasurementsComponent 
                dateFrom={dateFrom} 
                dateTo={dateTo}
                text={text} 
                duration={duration}/>
            </VitalMessagePopoverWrapper>
          }
          if (type == 'measurement') {
              return <VitalMessagePopoverWrapper>
                <POMeasurementComponent 
                  measuredAt={measuredAt} 
                  type={type}
                  value={oxygen_saturation}
                  unit={unit}
                  text={text}
                  severity={severity}/>
              </VitalMessagePopoverWrapper>
          }
        }
        case 'TM': {
          if (type == 'measurements') {
            return <VitalMessagePopoverWrapper>
              <TEMPMeasurementsComponent 
                dateFrom={dateFrom} 
                dateTo={dateTo}
                text={text} 
                duration={duration}/>
            </VitalMessagePopoverWrapper>
          }
          if (type == 'measurement') {
              return <VitalMessagePopoverWrapper>
                <TEMPMeasurementComponent 
                  measuredAt={measuredAt} 
                  type={type}
                  value={temperature}
                  unit={unit} 
                  text={text}
                  severity={severity}/>
              </VitalMessagePopoverWrapper>
          }
        }
        case 'HS': {
          if (type == 'measurements') {
            return <VitalMessagePopoverWrapper>
              <HSMeasurementsComponent 
                dateFrom={dateFrom} 
                dateTo={dateTo}
                text={text} 
                duration={duration}/>
            </VitalMessagePopoverWrapper>
          }
          if (type == 'measurement') {
              return <VitalMessagePopoverWrapper>
                <HSMeasurementComponent 
                  measuredAt={measuredAt} 
                  type={type}
                  value={weight}
                  weight_change = {weight_change}
                  unit={unit} 
                  text={text}
                  severity={severity}/>
              </VitalMessagePopoverWrapper>
          }
        }
    }

    if (type === 'fileUpload') {
      return (
        <MessagePopoverWrapper hideTranslation>
          <div>
            <FileUploadMessageComponent 
              text={text} 
              fileKey={fileKey} 
              userId={btoa(`accounts:${_.get(msgObj, 'entry.publisher')}`)} 
              originalText={_.get(msgObj, 'entry.originalText')} 
            />
          </div>
        </MessagePopoverWrapper>
      )
    }

    if(type === 'commentFoodLog'){
      return (
        <MessagePopoverWrapper hideTranslation>
          <div>
            <FoodlogView foodLogId={foodLogId} text={text}/>
          </div>
        </MessagePopoverWrapper>
      )
    }

    if(type==='MSR') {
        const { msrId } = msgObj.entry;
        return <MonthlyReportInChatContainer id={msrId} currentProgram={currentProgram}/>
    }

    const isSystemMessage = _.get(msgObj, 'entry.subType') === 'systemMsg';

    // clickable message
    const addClickableURL = (messageText) => {
      const urlRegEx = /http[s]?:\/\/\S+/gi;
      return parse(messageText.replace(urlRegEx, url => {
        return `<a href='${url}' target='_blank'>${url}</a>`;
      }));
    };

    const MessageTextBubble = ({ 
      children, 
      hasOriginalText 
    }) => (
      <li 
        className={classString} 
        style={{ 
          wordBreak: 'break-word', 
          ...(hasOriginalText ? { display: 'flex', flexDirection: 'column-reverse' } : {}) 
        }}
      >
        {children}
      </li>
    );

    if (articleMetaData) {
      return (
        <MessageTextBubble>
          <ArticleLinkPreviewComponent {...articleMetaData} />  
        </MessageTextBubble>
      );
    }

    return (
      <MessagePopoverWrapper disabled={isSystemMessage}>
        {
          (renderChatTranslatedText, originalText) => {
            const messageText = originalText || text || '';
            const hasOriginalText = !!originalText;
            return (
              <div style={{ width: 'fit-content' }}>
                <MessageTextBubble hasOriginalText={hasOriginalText}>
                  <React.Fragment>
                    {isSystemMessage ? addClickableURL(messageText) : messageText}
                    {renderChatTranslatedText(!!hasOriginalText)}
                  </React.Fragment>
                </MessageTextBubble>
              </div>
            );
          }
        }
      </MessagePopoverWrapper>
    );
}

renderMessageBody(msgObj, ind, history, self,isNewDay,dateHeader,timeStamp){
    const { channels, selectedChannel: selectedChannelId } = this.props;
    let classString = "collection-item-avatar".concat(self ? ' self':'');
    const userRole = msgObj.entry.userRole ? msgObj.entry.userRole : 'UnknownRole';
    const userId = msgObj.entry.publisher;

    const { messageId, tag, timetoken } = msgObj || {};
    const channel = _.get(channels, selectedChannelId) || {};

   return (
        <div 
          key={ind} 
          id={ind} 
          className='collection-item-container before' 
          data-timetoken={timetoken}
          ref={el => {
            if(el) {
              this.messageBodyRefs.current = {
                ...this.messageBodyRefs.current,
                [ind]: el
              };
            }
          }}
        >
            <div style={{width:'100%'}}>
                {this.renderDateSpe(isNewDay,dateHeader)}
                <div className='messageItemContainer'>
                  { this.getAvatar(userId, userRole) }
                    <div style={{width:'100%'}}>
                        <span style={{'fontWeight': 'bold'}}>
                              { msgObj.entry.displayName ? msgObj.entry.displayName : 'Unknown' }
                            <span className='userRole'> { userRole === 'admin' ? '' : ` - ${_.upperFirst(_.toLower(userRole))}` }</span>
                        </span>
                        <span className='timeStamp'>{timeStamp}</span>
                        {this.renderMsg(classString,msgObj,ind, timetoken, messageId, tag)}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 5 }}>
                  {renderChatTaggedTextView(tag, this.currentUser, this.state.patientCareTeamMembers)}
                </div>
                <ChatPatientUnreadIndicator 
                  curMsgIndex={ind}
                  curMsgTimetoken={timetoken}
                  lastClientACK={channel.lastClientACK}
                  history={history}
                />
            </div>
        </div>
    )
}

  ChatHistoryWrapper = ({ isLoading, children }) => (
    <div 
      id='chat-history-collection-wrapper' 
      className='chat-history-collection'
      ref={this.chatContainerRef}
    >
      <div id='chat-history-notice-message'>
        { isLoading && loadingMessage }
      </div>
      {children}
    </div>
  );

  render() {
    const { selectedChannel: selectedChannelId, channels, userMap, loadingChatInfo, isChatLoading, chatLoadingError, retryLoading } = this.props;

    if(loadingChatInfo)
      return (
        <this.ChatHistoryWrapper isLoading={true}>
          <div style={{ textAlign: 'center' }}>
            Loading patient's channel info
          </div>
        </this.ChatHistoryWrapper>
      );

    if(chatLoadingError) {
      return <EmptyChannelComponent 
        error={chatLoadingError} 
        retryLoading={() => retryLoading(selectedChannelId, this.getPatientId(), null, false, true)} />
    }

    const selectedChannel = _.get(channels, selectedChannelId) || {};
    const hasMoreMessage = _.get(selectedChannel, 'hasMoreMessage', false);
    let history = (_.get(selectedChannel, 'history') || []).filter((msgObj) => {
      const type = _.get(msgObj,'entry.type','');
      return msgObj && msgObj.entry.text && !hideMessageType[type];
    });

    const tagMessageIdx = chatHelpers.getTagMessageIndex(selectedChannel, history); // get older tag
    if(this.tagMessageIdx !== tagMessageIdx) {
      this.tagMessageIdx = tagMessageIdx;
      if(this.observingTagMessageEl) {
        this.tagMessageObserver.unobserve(this.observingTagMessageEl);
        this.observingTagMessageEl = null;
      }
      setTimeout(() => {
        const oldestTagMessage = _.get(this, `messageBodyRefs.current.${this.tagMessageIdx}`);
        if(oldestTagMessage) {
          this.tagMessageObserver.observe(oldestTagMessage);
          this.observingTagMessageEl = oldestTagMessage;
          
          if(this.shouldJumpToTagMessage) {
            // auto jump to the available oldest tag
            this.observingTagMessageEl.scrollIntoView(true);
          }
        }
      }, 100);
    }

    let preDate = new Date(0,0,0,0);
    const renderChatMessages = history && history.length > 0 ?
      history.map((msgObj, ind) => {
        const userRole = msgObj.entry.userRole;
        const isToday = moment(msgObj.timetoken / 1e4).format(timeFormatString) == moment(new Date()).format(timeFormatString);
        const isNewDay = moment(preDate).format(timeFormatString) != moment(new Date(msgObj.timetoken / 1e4)).format(timeFormatString);
        preDate = new Date(msgObj.timetoken / 1e4);
        const dateHeader = isToday ? 'Today' : moment(preDate).format(timeFormatString);
        const timeStamp = moment(preDate).format(timestampFormat);
        const isPatient = userRole === 'patient';
        return this.renderMessageBody(msgObj, ind, history, isPatient, isNewDay, dateHeader, timeStamp);
      })
    : isChatLoading ? 
        null
        : hasMoreMessage ?
          ''
          : <EmptyChannelComponent />;

    const renderHasMoreMessageButton = hasMoreMessage ?
      <div>
          <Divider className='chatDivider'>
              <Button 
                type="solid"  
                onClick={()=> {
                  this.fetchMoreMessages(history);
                  this.shouldJumpToTagMessage = false;
                }}
              >
                More messages
              </Button>
          </Divider>
      </div> :'';

    const shouldRenderTagMessageBubble = !isChatLoading && (this.state.shouldShowHasTagMessage || this.tagMessageIdx > history.length);

    const renderHasTagMessageBubble =  shouldRenderTagMessageBubble && (
      <div 
        className='chat-history-has-tag-indicator'
        onClick={() => {
          try {
            if (_.get(this, `messageBodyRefs.current.${this.tagMessageIdx}`)) {
              this.messageBodyRefs.current[this.tagMessageIdx].scrollIntoView(true);
            } else if(this.tagMessageIdx > history.length) {
              this.messagesStart.current.scrollIntoView({behavior: 'auto'});
              this.shouldJumpToTagMessage = true;
              this.fetchMoreMessages(history);
            } else {
              console.error('Failed to execute jump to tag message: No offset OR No index');
            }
          } catch (err) {
            console.error('Failed to execute jump to tag message: ', err);
          }
        }}
      >
        {/* <Icon type={this.state.isTagMessageAbove ? 'arrow-up' : 'arrow-down'} size={12} /> */}
        <span>You’ve been tagged to view a msg</span>
      </div>
    );

    return (
      <this.ChatHistoryWrapper isLoading={isChatLoading}>
        { renderHasTagMessageBubble }
        <ul ref='messageList' style={{ height: '100%' }}>
            { renderHasMoreMessageButton }
            <div style={{ float:"left", clear: "both" }} ref={this.messagesStart}></div>
            { renderChatMessages }
            <div style={{ float:"left", clear: "both" }} ref={this.messagesEnd}></div>
        </ul>
      </this.ChatHistoryWrapper>
    );
  }
}

const loadingMessage = (
  <div className='ant-message'><span><div className='ant-message-notice'><div className='ant-message-notice-content'><div className='ant-message-custom-content ant-message-loading'><i aria-label='icon: loading' className='anticon anticon-loading'><svg viewBox='0 0 1024 1024' focusable='false' className='anticon-spin' data-icon='loading' width='1em' height='1em' fill='currentColor' aria-hidden='true'><path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z'></path></svg></i><span> </span></div></div></div></span></div>
);

const mapToDispatch = (dispatch)=>{

    return {
        setRating: (id,rating)=>dispatch(actions.setRating(id,rating)),
        updateComment:(id,comments)=>dispatch(actions.updateComment(id,comments)),
        reset: (id)=>dispatch(actions.reset(id)),
        resetInitList:()=>dispatch(actions.resetInitList()),
        openModal: (content, row) => dispatch(openModal(content, row)),
        closeModal:()=>dispatch(closeModal()),
        onAddClick:(row)=>dispatch(onAddClick(row)),
        moveToBottom:(val)=>dispatch(chatActions.moveToBottom(val)),
    }
}

const mapState = ({ chat }, ownProps) => {
  const selectedChannel = chat.main.selectedChannel;
  const patientCareTeamId = btoa(`teams:${_.split(selectedChannel, '-')[0]}`);
  return {
    useChs: chat.main.useChs,
    selectedChannel,
    patientCareTeamId,
    channels: chat.main.channels,
    userMap: chat.main.userMap,
    toBottom: chat.main.toBottom,
    toTag: chat.main.toTag,
  }
};

ChatHistoryComponent.propTypes = {
  userId: PropTypes.string,
  history: PropTypes.array,
  selectedChannel:PropTypes.string
};

export default connect(mapState, mapToDispatch)(ChatHistoryComponent);