import React from 'react';
import helper from '../../helper'
import {Form, Button, message} from 'antd';
import API from '../../API';
import {compose, graphql} from "react-apollo";
import query from "../../query";

const { renderFormByKey } = helper;

let AreaDetailComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            creatingOrg: false
        }
    }

    createOrg = () => {
        const { setShowCreateAreaModal, parentId, refetchOrgList, refetchOrg } = this.props;
        const { validateFields } = this.props.form;
        validateFields((err,res) => {
            if(!err) {
                let {name, parentId, description} = res;
                this.setState({
                    creatingOrg: true
                })
                const variables = {
                    name, parentId, description, identifier:name
                }
                API.createArea(variables)
                    .catch(e=>{
                        console.log(e);
                    })
                    .then(res=>{
                        message.info('Area Created!')
                        refetchOrgList && refetchOrgList();
                        refetchOrg && refetchOrg()
                        setShowCreateAreaModal(false);
                    })
            }
        })
    }

    render() {
        const localThis = this;
        const { creatingOrg } = this.state;
        return<div className={'createAreaModal'}>
                { renderFormByKey(localThis,'createAreaForm') }
                <div className={'createAreaButtonWrapper'}>
                    <Button onClick={ this.createOrg } type={'primary'}>Create</Button>
                </div>
              </div>
    }
};

AreaDetailComponent = Form.create({})(AreaDetailComponent);
export default (AreaDetailComponent);
