import React  from 'react';
import { Table } from 'antd';

import '../style/onCall.styles.scss';

// const getOnCalleeOptions = (currentRole) => {
//   const loginUser = JSON.parse(sessionStorage.getItem('currentUser')) || {};
//   const teamsFromLoginUser = loginUser.team || [];
//   const options =  {
//     CA: roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.CA]),
//     RD: roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.RD]),
//     HC: roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.HC]),
//   }[currentRole] || [];
//   return _.filter(options, opt => opt.id !== loginUser.id);
// }

const OnCallComponent = (props) => {
  const { coveredMembers, handleSetCoveredMembers, loadingCoveredMemberOptions, coveredMemberOptions } = props; 

  const handleSelection = (__, selectedRows) => {
    handleSetCoveredMembers(selectedRows);
  }

  const tableProps = {
    className: 'onCalleeTable',
    columns: [
      {
        title: <b>Select all</b>,
        dataIndex: 'fullName'
      }
    ],
    loading: loadingCoveredMemberOptions,
    dataSource: coveredMemberOptions,
    rowSelection: {
      selectedRowKeys: _.map(coveredMembers, 'id'),
      onChange: handleSelection,
      onSelectAll: handleSelection,
    },
    rowKey: (r, idx) => r ? r.memberId : idx,
    scroll: { y: 285 },
    pagination: false
  }


  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        {coveredMembers.length} selected
      </div>
      <Table {...tableProps}/>
    </div>
  );
};

export default OnCallComponent;