import gql from 'graphql-tag'
import {
  User, UserWithoutTeam, UserForSessionStorage, UserMiniWithSetting, ProviderProfile, UserForAdmin
} from './schema/User'
import {Address, Phone} from './schema/common';
import { PatientPageSchema } from './schema/Patient';

export default gql`
  query user($id:EID, $identification: String){
    user(id:$id, identification: $identification){
      ${User}
      patientMntProgram {
            id
            status
      }
    }
  }
`

export const patientGQL = gql`
  query user($id:EID, $identification: String){
    user(id:$id, identification: $identification){
      ${PatientPageSchema}
      patientMntProgram {
            id
            status
      }
    }
  }
`;

export const userForAvatar = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      profile {
        firstName
        lastName
        nickname
        fullName
        fullNameWithNickname
        avatar {
          link
        }
      }
    }
  }
`

export const userReferAndEnroll = gql`
  query user($id: EID) {
    user(id: $id) {
      id
      identification {
        type {
          code
          description
        }
        value
        MRN
      }
      address {
        ${Address}
      }
      phone {
        ${Phone}
      }
      profile {
        ...on MemberProfile {
          firstName
          lastName
          nickname
          fullName
          fullNameWithNickname
          gender
          birthday
          conditionsList {
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
            ESRD_Dialysis
          }

          programCategories {
            name
            enrolled
            enrolledDate
          }

          healthConditions
          
          billableHealthConditions {
            code
            description
          }

          language {
            code
            description
          }

          doctor {
            id
          }

          insuranceProviders {
            insuranceProvider {
              id
              payerId
              payerName
              description
              clearingHouse
            }
            primary
          }
        }
      }
    }
  }
`

export const userLanguages = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      profile {
        ...on MemberProfile {
          language {
            code
            description
          }
          appLanguage {
            code
            description
          }
        }
      }
    }
  }
`

export const userMntCodes = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      patientMntCodes {
        code
        title
      }
    }
  }
`

export const patientMntProgram = gql`
  query user($id: EID) {
    user(id: $id) {
      id
      patientMntProgram {
        id
        description
        # patient {
          
        # }
        referredBy {
          id
          profile {
            fullName
          }
        }
        referredAt
        ineligibledBy {
          id
          profile {
            fullName
          }
        }
        ineligibledAt
        status
        mntConditions
      }
      patientMntCodes {
        code
        title
      }
    }
  }
`

export const userForChat = gql`
  query user($id:EID) {
    user(id:$id) {
      id
      createdAt
      registeredAt
      allRoles {
        refId
        name
        category
        active
      }
      username
      email {
        address
      }
      address {
        ${Address}
      }
      phone {
        ${Phone}
      }
      profile {
        title
        firstName
        lastName
        nickname
        fullName
        fullNameWithNickname
        avatar {
          link
        }
        ...on MemberProfile {
          birthday
          language {
            code
            description
          }
          height
          weight
        }
      }
      userSettings {
        preferredUnit
        listeningOrgs
        sendHeartbeatLog
        snapshotInterval
      }
      hasPassword
    }
  }
`

export const userForSessionStorage = gql`
  query user($id:EID){
    user(id:$id){
      ${UserForSessionStorage}
    }
  }
`

export const userWithoutTeam = gql`
  query user($id:EID!){
    user(id:$id){
      ${UserWithoutTeam}
    }
  }
`

export const userWithTaskCount = gql`
  query user($id:EID!){
    user(id:$id){
      ${UserWithoutTeam}
      taskAssignmentCount
    }
  }
`

export const userMiniWithSetting = gql`
  query user($id:EID!){
    user(id:$id){
      ${UserMiniWithSetting}
    }
  }
`

export const presetNotes = gql`
  query User($id: EID!) {
    user(id: $id) {
      id
      allRoles {
        organization {
          id
          notePreset {
            category
            display
            subCategories
          }
        }
      }
    }
  }
`

export const providerProfile = gql`
  query user($id:EID!){
    user(id:$id){
      ${ProviderProfile}
    }
  }
`;

export const userForAdmin = gql`
  query user($id:EID){
    user(id:$id){
      ${UserForAdmin}
    }
  }
`

export const teamsForUser = gql`
query userData($id:EID){
  user(id: $id)  {
    id
    team {
      id
      members {
        user {
          id
          profile {
            fullName
            firstName
            lastName
  
            ...on EmployeeProfile {
              providerLanguage {
                code
                description
              }
              assignedPatientCount
              orgAssignedPatientCount
            }
          }
          allRoles {
            refId
            name
            category
            organization {
              id
            }
          }
          capacitys {
            role
            capacity
          }
        }
      }
    }
  }
}
`
