import React from 'react';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { browserHistory } from 'react-router';
import { goPath } from '../../../lib/utils';
import { Button } from 'antd';
import { ENROLL_FROM } from '../../../lib/constants';
import EnrollPatientContainer from '../../patient/EnrollPatient/container/EnrollPatientContainer';

export default (props) => {
  const { visit, refetch } = props;
  const { id: visitId, member } = visit || {};
  const memberId = _.get(member, 'id');
  const path = `/patients/${memberId}/enrolledprogram/${visitId}_`;
  const curUrl = browserHistory.getCurrentLocation().pathname;
  const canEnrollHere = _.endsWith(curUrl, 'dashboard');
  return (
    <div
      style={{
        width: '90%',
        position: 'absolute',
        height: '90%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex:9999
      }}
    >
      {
          !canEnrollHere ?
          <span style={{
            width: '62%',
            font: 'normal normal 600 14px "Open Sans", sans-serif',
            lineHeight: '19px'
          }}>
            Before starting your assessment, please first click “Enroll” from the visits tab. You may need to notify your CA for tech assistance and onboarding.
          </span>
          :
          <EnrollPatientContainer 
            user={member}
            fromPlace={ENROLL_FROM.WORK_LIST}
            onCompleteEnrollment={() => {
              if(refetch)
                refetch(); // refetch single visit
            }}
            enrollButtonOnClick={() => {
              try {
                Mixpanel.track('clicked', 'enroll patients to the program', "in Work List", {});
                sessionStorage.setItem('backToCurVisit', JSON.stringify(visit));
              } catch (err) {
                console.error(err);
              }
            }}
            enrollButtonText='Enroll Patient to Program'
          />
          // <Button 
          //   onClick={() => {
          //     try {
          //       Mixpanel.track('clicked', 'enroll patients to the program', "in Work List", {});
          //       sessionStorage.setItem('backToCurVisit', JSON.stringify(visit));
          //     } catch (err) {
          //       console.error(err);
          //     } finally {
          //       goPath(path);
          //     }
          //   }} 
          //   type={'primary'}
          // >
          //     Enroll Patient to Program
          // </Button>
        }
    </div>
  );
};