import React from 'react';
import { renderForm } from "../helpers";
import { connect } from 'react-redux';
import { modalAction } from 'modulesAll/common/actions';
import { openConfirmModal, removeICDcode, unselectBillable} from '../../../careplanNew/helpers/healthConditionHelper';
import HealthConditionPicker from '../Components/HealthConditionPickerComponent/HealthConditionPicker';
import { Table,Button,Icon,Checkbox, Form, Input, Tooltip, Popconfirm, Row, Modal } from 'antd';
import '../styles/index.scss';
import I18N from 'modulesAll/I18N';
import ComplexityAndHealthCoachContainer from '../../../patient/main/containers/ComplexityAndHealthCoach';
import { healthConditionsTooltip } from '../../../careplanNew/constant/healthConditionConstant';
import { getBillableAndNonBillableCodes } from '../../../careplanNew/component/helper/healthConditionHelper';
import helper from "../../../careplanNew/helpers";
import BillableConditionHelper from '../../../careplanNew/component/HealthCondition/BillableConditionHelper';
import withPatientConsent from '../../../../lib/hooks/withPatientConsent';

class RenderFormComponent extends React.Component{
    constructor(props){
        super(props);
        this.state= {};
        this.billableConditionHelper = new BillableConditionHelper({
          patient: props.user,
          enrolledProgram: props.program,
          patientReferral: _.get(props, 'user.remoteEnrollment.patientRefer') || {},
          consentInfo: props.consentInfo,
        });
    }

    addHealthCondition=(value, clearFieldFn)=>{
        const { form } = this.props;
        const { healthConditions, billableHealthConditions } = form.getFieldsValue();
        let newHealthConditions = [...healthConditions];
        if(healthConditions.indexOf(value)==-1) {
            newHealthConditions = [value].concat(healthConditions);
            this.props.form.setFieldsValue({
                healthConditions : newHealthConditions
            });
        }
        if(clearFieldFn) clearFieldFn();
        this.billableConditionHelper.checkAndAutoMarkBillable(
          value,
          (newBillableHealthConditions) => {
            form.setFieldsValue({ 'billableHealthConditions': newBillableHealthConditions });
          },
          newHealthConditions,
          billableHealthConditions
        );
    }
    /*
    copyBillableHealthConditions(array){
        const copiedArray = array.slice();

        return copiedArray.map(i=>({
            description:i.description,
            code:i.code
        }));
    }

    removeHealthCondition=(index)=> {
        const { healthConditions,billableHealthConditions } = this.props.form.getFieldsValue();
        const copyHealthConditions = healthConditions.slice();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const value = _.get(healthConditions[index].split('::'),'1',null);
        this.props.form.setFieldsValue({
            healthConditions: _.remove(copyHealthConditions, function (_, i) {
                return i != index;
            }),
            billableHealthConditions: value ? _.remove(copyBillableHealthConditions, function (o) {
                return o.code!=value;
            }) : copyBillableHealthConditions
        })
        this.props.handleEnableEditMode(true);
    }


    onCheckChange(v){
        const { billableHealthConditions } = this.props.form.getFieldsValue();
        const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        const code = _.get(v,'code','');
        if(copyBillableHealthConditions.findIndex((i)=>code==i.code)!=-1){
            // uncheck the checkbox
            // openConfirmModal(unselectBillable, { copyBillableHealthConditions, code }, this);
            this.unCheckBillable({ copyBillableHealthConditions, code });
        }
        else{
            copyBillableHealthConditions.push({
                description:v.name,
                code:code
            });
            this.props.form.setFieldsValue({
                billableHealthConditions: copyBillableHealthConditions
            })
        }
    }

    unCheckBillable = (pars) => {
        const {copyBillableHealthConditions, code } = pars;
        this.props.form.setFieldsValue({
            billableHealthConditions: _.remove(copyBillableHealthConditions, function (o) {
               return o.code!=code;
            })
        })
    }
    */
    removeHealthCondition=(index)=> {
        let { healthConditions } = this.props.form.getFieldsValue();
        const keys = ['billableHealthConditions','mntHealthConditions'];
        const removeItemFromArray = (arr,value)=>{
            return _.remove(arr,  (o)=>{
                return o.code!=value;
            })
        };
        const obj = {};
        const value = _.get(healthConditions[index].split('::'),'1',null);
        healthConditions =  _.remove([...healthConditions], function (_, i) {
            return i != index;
        });
        for (const key of keys) {
            const codes = _.get(this.props.form.getFieldsValue(),key) || []; 
            const arr = [...codes];
            _.set(obj,key,removeItemFromArray(arr,value));
        }
        _.set(obj,'healthConditions',healthConditions);
        // const { healthConditions,billableHealthConditions } = this.props.form.getFieldsValue();
        // const copyHealthConditions = healthConditions.slice();
        // const copyBillableHealthConditions = Array.isArray(billableHealthConditions) ? this.copyBillableHealthConditions(billableHealthConditions) :[];
        this.props.form.setFieldsValue(obj);
        //     {
        //     healthConditions: _.remove(copyHealthConditions, function (o) {
        //         return o.code!=value;
        //     }),
        //     billableHealthConditions: value ? _.remove(copyBillableHealthConditions, function (o) {
        //         return o.code!=value;
        //     }) : copyBillableHealthConditions
        // })
    }
    conditionCheckedByItem = (array,item)=>{
        return array.findIndex((i) => item.code == i.code) != -1
    }

    uncheckCondition = (pars)  => {
        const {copiedArray, arrayKey,code } = pars;

        this.props.form.setFieldsValue({
            [arrayKey]: _.remove(copiedArray, function (o) {
                return o.code!=code;
            })
        })
    }

    checkCondition = (pars)=>{
        const {copiedArray, arrayKey,code,v } = pars;
        copiedArray.push({ description:v.name, code:code });
        this.props.form.setFieldsValue({ [arrayKey]: copiedArray })
    }

    onCheckChange = (arrayKey,v) => {
        const array = this.props.form.getFieldValue(arrayKey)||[];
        const copiedArray = array.slice();
        const code = _.get(v,'code','');
        const { conditionCheckedByItem } = this;
        if (conditionCheckedByItem(copiedArray,v)) {
            this.uncheckCondition({arrayKey, copiedArray, code });
        }else{
            this.checkCondition({ copiedArray,arrayKey,code,v });
        }
    }

    renderComplexity = () => {
        const { user, form, disabled } = this.props;
        const { billableHealthConditions, nonBillableHealthConditions } = getBillableAndNonBillableCodes(form.getFieldsValue(), user);
        return (
            <Row style={{ marginTop: 20 }}>
                {form.getFieldDecorator('complexityLevel')(
                    <div>
                        <ComplexityAndHealthCoachContainer
                            patientId={_.get(user, 'id')}
                            isEnrolled={false}
                            nonBillableCodes={nonBillableHealthConditions}
                            billableCodes={billableHealthConditions}
                            onChange={(complexityLevel) => { 
                                form.setFieldsValue({ complexityLevel });
                            }}
                            disabled={disabled}
                        />
                    </div>
                )}
            </Row>
        );
    }

    renderHealthConditionPicker=()=>{
        const isWorkStation = _.get(this.props,'isWorkStation');

        return <div id='HealthConditionPicker ant-col-24' style={{ height: 100 }}>
            <Tooltip placement='topLeft' overlayClassName='health-conditions-tooltip' title={healthConditionsTooltip}><h4>Health Conditions</h4></Tooltip>
            <div className={'searchWrapper'+ isWorkStation ? ' isWorkStation':''}>
                <label className='ant-form-item-required' style={{fontSize: 12}}>ICD Code</label>
                <HealthConditionPicker disabled={this.props.disabled} addHealthCondition={ this.addHealthCondition }/>
                {/* shadow fields */}
                <Form.Item span={12}>
                {
                    this.props.form.getFieldDecorator('healthConditions', {
                        rules: [ {required: true, message: 'ICD Code is required' }],
                        initialValue: _.get(this, 'props.user.profile.healthConditions'),
                    })(<Input style={{display: 'none'}}/>)
                }
                </Form.Item>
                <Form.Item span={12}>
                {
                    this.props.form.getFieldDecorator('billableHealthConditions', {
                        initialValue: _.get(this, 'props.user.profile.billableHealthConditions') || [],
                    })(<Input style={{display: 'none'}}/>)
                }
                </Form.Item>
                <Form.Item span={12} style={{visibility:'hidden',height:1,padding:0,margin:0}}>
                    {
                        this.props.form.getFieldDecorator('mntHealthConditions', {
                            initialValue: _.get(this, 'props.user.profile.mntHealthConditions') || [],
                        })(<Input style={{display: 'none'}}/>)
                    }
                </Form.Item>
                { this.billableConditionHelper.renderProgramChangePrompt(this.props.form) }
            </div>
        </div>
    }

    renderHealthConditionTable() {
        const { healthConditions = _.get(this, 'props.user.profile.healthConditions') } = this.props.form.getFieldsValue();
        const disabled = _.get(this.props, 'disabled');
        const isMNT = helper.isMNTEnrolled(this.props);

        // const isMNT = _.get(this.props, 'isMNT');
        const isWorkStation = _.get(this.props,'isWorkStation');
        const healthConditionsSource = _.map(healthConditions, (value) => (
            {
                name: value.split('::')[0],
                code: value.split('::')[1],
                value: value
            }))
        const tableColumn = [
            {
                key: 'code',
                title: 'Code',
                width:'15%',
                dataIndex: 'code',
                render: (code) => this.billableConditionHelper.renderCode(code)
            },
            {
                key: 'name',
                title: 'Condition',
                ellipsis: true,
                dataIndex: 'name'
            },
            {
                key:'billableHealthConditions',
                title: isMNT ? 'CCM/RPM' : 'Billable',
                width:'10%',
                render:(v,doc)=>{
                    const { billableHealthConditions = _.get(this, 'props.user.profile.billableHealthConditions') } = this.props.form.getFieldsValue();
                    const checked = !Array.isArray(billableHealthConditions) ? false : billableHealthConditions.findIndex((i)=>v.code==i.code)!=-1;

                    return v.code ? 
                      (!disabled && checked)  ? (
                        <Popconfirm
                          title={
                            <div>
                              <div className='title'>
                                {I18N.get('healthCondition.unselectBillable.title')}
                              </div>
                              <div className='description'>
                                A corresponding clinical goal is added (See in Goal section). Upon <span className='highlight'>unmarking</span> the ICD-10 code as billable, the related clinical goal will be removed as well
                              </div>
                            </div>
                          }
                          okText='Yes'
                          overlayClassName='billable-unmark-popconfirm'
                          onConfirm={() => this.onCheckChange('billableHealthConditions',v, doc)}
                        >
                          <Checkbox checked={checked} disabled={disabled}/>
                        </Popconfirm>

                      )
                      :  <Checkbox onChange={() => this.onCheckChange('billableHealthConditions',v,doc)} checked={checked} disabled={this.props.disabled}/>
                    : '';
                }

            },
        ];

        if (isMNT) {
            const { mntHealthConditions = _.get(this, 'props.user.profile.mntHealthConditions') } = this.props.form.getFieldsValue();
            tableColumn.push({
                key:'MNT',
                title:'MNT',
                width:'10%',
                render:(v,doc)=>{
                    const checked = !Array.isArray(mntHealthConditions) ? false : mntHealthConditions.findIndex((i) => v.code == i.code) != -1;
                    return <Checkbox checked={checked} disabled={this.props.disabled} onChange={()=>this.onCheckChange('mntHealthConditions',v)} />;
                }
            });
        }

        tableColumn.push({
            key: 'remove',
            width:'10%',
            title:'Action',
            render: (text, record, index) => <Button disabled={disabled} onClick={() => openConfirmModal(removeICDcode, index, this)}><Icon
            type="close"/></Button>
        });

        return <div id='health-condition-table' /*style={{padding: '20px 10px 0 10px'}}*/ className={ isWorkStation ?`ant-col-24 isWorkStation` :`ant-col-24`}>
                <div style={{color: '#717171'}}>
                    Note: {I18N.get('healthCondition.note')}
                </div>
                <Table dataSource={ healthConditionsSource }
                       columns={ tableColumn }
                       rowKey={(key) => key.code}
                       pagination={false}
                />
                {/* in worklist + unenrolled patient profile*/ }
                { this.renderComplexity() }
              </div>
    }

    render(){
        const { config } = this.props;
        return (
            <div>
                <Row>
                    {renderForm(this,config)}
                </Row>
            </div>
        );
    }

}
const mapToDispatch = (dispatch)=>{
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
    }
}

export default connect(
  null,
  mapToDispatch
)(withPatientConsent(RenderFormComponent, { 
  getMemberId: (props) => _.get(props, 'user.id'),
  waitForConsentInfo: true
}));