import React from 'react';
import  { DATE_HELPERS  } from '../../../../../lib/helpers/date-helpers';
import obj from '../../Measurement/constants/index';
import { Divider } from 'antd';
import moment from 'moment';
import I18N from 'modulesAll/I18N'
import {Icon} from 'antd';

const timeFormatString = I18N.get('dates.L');

const symbols = {
  Down: <Icon type='arrow-down' style = {{ color :'green'}}/>,
  Up: <Icon type='arrow-up' style={{ color: 'red'}}/>,
  Zero: <Icon type='minus' style = {{color:'#08c'}}/>,
};



class HSMeasurementComponent extends React.Component{

    render(){
        const { HSMeasureTypeMap , style} = obj;
        const { value, unit, measuredAt, text, type, weight_change } = this.props;
        const { container, header, divider1, message } = style;
        const { title } = HSMeasureTypeMap[type];
        let displayValue = !isNaN(value) ? Number(value).toFixed(1): '--';
        const measuretAtDate = DATE_HELPERS.formattedDate(moment(measuredAt),timeFormatString);
        const icon = weight_change > 0? symbols.Up : weight_change < 0? symbols.Down : symbols.Zero;
        let change = !isNaN(weight_change)? (weight_change < 0? -1*Number(weight_change).toFixed(1): Number(weight_change).toFixed(1)) : 0;


        const changeStyle = {
            width:'100%',
            height:'25px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius:'12px',
            fontSize:'13px',
            marginTop:'10px'
        }

        const divider3 ={
            width:'3px',
            height:'inherit'
        }

        return (
            <div style={container}>
                <div style={header}>
                    <span style={{marginBottom:10,whiteSpace:'nowrap'}}>{title}({measuretAtDate})</span>
                    <Divider style={divider1}/>
                </div>
                <div style={message}>
                    <div style={header}>
                        <span style={{fontSize:16,fontWeight:'bold',lineHeight:'10pt'}}>{displayValue}</span>
                        <span>{unit}</span>
                          <div style = {changeStyle}>{icon}{change}{' ' + unit}</div>
                    </div>
                    <Divider type='vertical' style={divider3}/>
                    <div>
                        {text}
                    </div>
                </div>
            </div>
        )
    }
}

export default HSMeasurementComponent;
