import gql from 'graphql-tag'

export const deactivateUser =  gql `
mutation deactivateUser (
  $id: EID!,
  $roles: [Ref]!
) {
  deactivateUser (
    id: $id,
    roles: $roles
  ){
    role{
      active
    }
  }
}
`