import gql from 'graphql-tag'

export default gql`
  query enrolledProgram($id: EID!) {
    enrolledProgram(id: $id) {
      user {
        profile {
          ...on MemberProfile {
            healthConditions
          }
        }
      }
    }
  }
`
