import React, { useCallback } from 'react';
import { Form } from 'antd';
import MABasicInformation from './MABasicInfo';

const MAReferralBasicInformation = (props) => {
  return (
    <MABasicInformation {...props} />
  );
}

export default Form.create({ name: 'basicInformation' })(MAReferralBasicInformation);
