import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import { IHLoading } from 'ihcomponent'
// import AssessmentList from 'graphqlModule/assessmentList'
import { LightAssessmentList } from 'graphqlModule/assessmentList'
import ListContainer from 'ProgramEditModule/Survey/containers/ListContainer'
import get from 'lodash/get';
import { ASSESSMENT_LIST_COUNT_MAX } from 'libModule/constants';

const ref = 'surveyList'
const type = 'SURVEY'
class Container extends Component {
  static displayName = 'program/ProgramEdit/Survey/containers/CommonContainer'

  render() {
    const { data, program, surveyHasEmptySchedule } = this.props
    const assessmentListData = get(data, 'assessmentList.data') || []
    if (data.loading) {
      return <IHLoading />
    }
    const props = {
      assessmentListData,
      program,
      surveyHasEmptySchedule
    }
    return <ListContainer ref={ref} {...props} />
  }
}

// mapState
const mapState = ({ program }, ownProps) => {
  return {
    ...program.ProgramEdit.Careplan.Survey.Common
  }
}

// mapDispatch
const mapDispatch = (dispatch, ownProps) => {
  return {

  }
}

const WithData = graphql(LightAssessmentList, {
  options: ownProps => ({
    variables: {
      page: 1,
      count: ASSESSMENT_LIST_COUNT_MAX,
      filters: {
        status: ['ACTIVE'],
        type,
     },
      sort: {
        field: 'CREATED_AT',
        direction: 'ASC'
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ ownProps, data }) => ({
    data,
  }),
})(Container);

// PropTypes validation
Container.propTypes = {
  program: PropTypes.object,
  data: PropTypes.object,
}
// connect container with mapState and mapDispatch then export
export default connect(
  mapState, mapDispatch
)(WithData)
