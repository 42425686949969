import React from 'react';
import { Steps,Button,Input,Popover } from 'antd';
const { Step } = Steps;
import { compose, graphql } from 'react-apollo/index';
import API from '../../API/index'
import LanguageSetupComponent from '../component/LanguageSetupComponent';
import query from '../../query/consentFormQuery'
import helper from '../helper/index';
import ConsentFormModalComponent from "../component/ConsentFormModalComponent";
const ConsentFormModalContainer = class extends React.Component{
    render() {
        const { id,refetchTemplateGroupList,setShowModal,templateGroup,templateGroupLoading,consentTemplateList,refetchConsentTemplateList,onCancel } = this.props;
        if(typeof templateGroupLoading=='boolean') {
            if(templateGroupLoading){
                return <span>loading</span>
            }
            return <ConsentFormModalComponent id={id} refetchTemplateGroupList={refetchTemplateGroupList} setShowModal={setShowModal}
                                              consentTemplateList={consentTemplateList} templateGroup={templateGroup}
                                              refetchConsentTemplateList={refetchConsentTemplateList}
                                              onCancel={onCancel}
            />
        }else {
            return <ConsentFormModalComponent refetchTemplateGroupList={refetchTemplateGroupList}
                                              consentTemplateList={consentTemplateList}
                                              onCancel={onCancel}
                                              refetchConsentTemplateList={refetchConsentTemplateList}
                                              setShowModal={setShowModal}/>
        }
    }
}
const getTemplateGroup = graphql(query.getTemplateGroupQuery,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables:{
                id
            },
            skip: !id,
            fetchPolicy:'network-only'
        }
    },
    props:({data} )=>{
        const templateGroup = _.get(data,'getTemplateGroup');
        const templateGroupLoading = _.get(data,'loading');
        return {
            templateGroup,templateGroupLoading
        }
    }
})
const getConsentTemplateList = graphql(query.getConsentTemplateListQuery,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables:{
                groupId:id
            },
            skip: !id,
            fetchPolicy:'network-only'
        }
    },
    props:({data} )=>{
        const consentTemplateList = _.get(data,'getConsentTemplateList');
        const refetchConsentTemplateList = _.get(data,'refetch');
        return {
            consentTemplateList,
            refetchConsentTemplateList
        }
    }
})
export  default compose(getTemplateGroup,getConsentTemplateList)(ConsentFormModalContainer);