import React from "react";
import BPLineChartComponent from '../components/BPLineChartComponent';
// import moment from "moment/moment";
// import { Switch,Radio } from 'antd';
// import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

// const isLastDay = () =>{
//   const today = moment();
//   return today.format('D') === today.endOf('month').format('D');
// }

const BPLineChartContainer = class extends React.Component {
    constructor(){
        super();
        this.state = {
            // allVisit: false,
            // toDate:moment().endOf('day'),
            // fromDate:moment().subtract(6,'d').startOf('day'),
            // timeInterval:'d',
            // value:6,
        }
    }
    // changePre = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const updatedFromDate = fromDate.subtract(value,timeInterval).startOf('day');
    //     const updatedToDate = (timeInterval === 'M' && isLastDay()) ? toDate.subtract(value,timeInterval).endOf('month').endOf('day') : toDate.subtract(value,timeInterval).endOf('day');
    //     const { patientId } = this.props;
    //     const strVal =  (value==14 ? 'TWO' : 'ONE');
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','BACK_BP',null,{ PATIENT_ID:patientId,DURATION: str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // changeNext = ()=>{
    //     const { fromDate, toDate,timeInterval,value } = this.state;
    //     const updatedFromDate = fromDate.add(value,timeInterval).startOf('day');
    //     const updatedToDate = (timeInterval === 'M' && isLastDay()) ? toDate.add(value,timeInterval).endOf('month').endOf('day') : toDate.add(value,timeInterval).endOf('day');
    //     const { patientId } = this.props;
    //     const strVal =  (value==14 ? 'TWO' : 'ONE');
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked','NEXT_BP',null,{ PATIENT_ID:patientId,DURATION:str });
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }
    // renderNextButton = () =>{
    //     const { value,timeInterval } = this.state;
    //     const val = value == 1 ? value : ((value == 6 ||value == 7) ? 1 : 2);
    //     const unit = (timeInterval === 'd') ? (val == 1 ? 'Week' : 'Weeks') :'Month';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //         <span>{`Next ${val} ${unit}`}</span>
    //         <ul className='ant-pagination logBookPreNext' >
    //             <li className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.changeNext()}>
    //                 <a className="ant-pagination-item-link"></a>
    //             </li>
    //         </ul>
    //     </div>
    // }

    // renderPrevButton = ()=>{
    //     const { value,timeInterval } = this.state;
    //     const val = value == 1 ? value : ((value == 6 ||value == 7) ? 1 : 2);
    //     const unit = (timeInterval === 'd') ? (val == 1 ? 'Week' : 'Weeks') :'Month';
    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //         <ul className='ant-pagination logBookPreNext' >
    //             <li className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.changePre()}>
    //                 <a className="ant-pagination-item-link"></a>
    //             </li>
    //         </ul>
    //         <span>{`Past ${val} ${unit}`}</span>
    //     </div>
    // }


    // setTimeInterval = (updatedTimeInterval,updatedValue)=>{
    //     const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
    //     const updatedToDate = moment().endOf('day');
    //     const { patientId } = this.props;
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate,
    //         timeInterval: updatedTimeInterval,
    //         value: updatedValue
    //     },()=>{
    //         const strVal =  (updatedValue==14 ? 'TWO' : 'ONE');
    //         const strUnit = (updatedTimeInterval=='M' ? 'MONTH' : 'WEEK')
    //         const str = strVal+'_'+strUnit;

    //         Mixpanel.track('clicked',str+'_BP',null,{ PATIENT_ID: patientId,DURATION:str })
    //     })
    // }

    // renderRadioButton = ()=>{
    //     const { fromDate,toDate } = this.state;
    //     const diffInWeek = toDate.diff(fromDate,'week');
    //     const oneWeekChecked = (diffInWeek===0) ;
    //     const twoWeekChecked = (diffInWeek===1) ;
    //     const oneMonthChecked = (diffInWeek===4) ;

    //     return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginLeft:10, marginRight: 10}}>
    //         <Radio.Button  value='7'  onClick={()=>this.setTimeInterval('d',7)} checked = {oneWeekChecked}>1 Week</Radio.Button>
    //         <Radio.Button  value='14'  onClick={()=>this.setTimeInterval('d',14)} checked = {twoWeekChecked} >2 Weeks</Radio.Button>
    //         <Radio.Button  value='1' onClick={()=>this.setTimeInterval('M',1)} checked = {oneMonthChecked}>1 Month</Radio.Button>
    //     </Radio.Group>
    // }

    render(){
        // const { props,renderRadioButton,renderPrevButton,renderNextButton } = this;
        const { fromDate, toDate, xAxisLabelPerDay = 1, ...props } = this.props;

        return <div style={{ padding: 20 }}>
                    {/* <div className='row'>
                    <div style={{ fontSize:16,fontWeight:'bold' }} className={`${ isInChat ? 'col-lg-3':'col-lg-7'}`}>Blood Pressure</div>
                    <div className={`${ isInChat ? 'col-lg-9':'col-lg-5'}`}style={{display:'flex'}}>
                        {renderPrevButton()}
                        {renderRadioButton()}
                        {renderNextButton()}
                    </div>
                    </div> */}
                <BPLineChartComponent {...props} fromDate={fromDate.toDate()} toDate={toDate.toDate()} xAxisLabelPerDay={xAxisLabelPerDay} />
            {/*<HSAreaChartComponent*/}
            {/*allVisit={allVisit}*/}
            {/*patientId={patientId}*/}
            {/*changePre={() => this.changePre()}*/}
            {/*changeNext={() => this.changeNext()}*/}
            {/*setTimeInterval={(i, v) => this.setTimeInterval(i, v)}*/}
            {/*fromDate={fromDate.toDate()}*/}
            {/*toDate={toDate.toDate()}*/}
            {/*value={value}*/}
            {/*timeInterval={timeInterval}*/}
            {/*{...props}*/}
            {/*currentProgram={currentProgram}/>*/}
                </div>
        // return <BGLineChartComponent {...props}/>

    }
}

export default BPLineChartContainer;