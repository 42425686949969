/**
 * Created by zizhangai on 2/8/17.
 */
import types from '../constants/ReportModalConstants'
import moment from 'moment'

export const changeDate = (range) => ({
  type: types.CHANGE_DATE,
  payload: [moment(range[0]), moment(range[1])]
})

export const changeReason = (reason) => ({
  type: types.CHANGE_REASON,
  payload: reason
})

export const resetState = () => ({
  type: types.RESET
})

export const changeInput = (value) => ({
  type: types.CHANGE_INPUT,
  payload: value
})

