import type from '../constants/reduxTypes';

import { VISIT_TABS } from '../constants';

const DEFAULT_VISIT_TABKEY = _.first(VISIT_TABS).tabKey;

const initialState = {
  selectedTabKey: DEFAULT_VISIT_TABKEY,
  selectedRange: {},
  selectedFilters: {},
  selectedAdvancedFilters: {},
  selectedPageInfo: {}
}

const reducer = (state=initialState, action)=>{
  const newState = {...state};
  let newSelectedAdvancedFilters = {...state.selectedAdvancedFilters};
  let newSelectedFilters = {...state.selectedFilters};

  const resetPageInfo = (newState) => {
    // reset page info if applicable
    let resetSelectedPageInfo = state.selectedPageInfo[state.selectedTabKey];
    if(resetSelectedPageInfo) {
      resetSelectedPageInfo = {
        ...state.selectedPageInfo,
        [state.selectedTabKey]: {}
      }
      newState.selectedPageInfo = resetSelectedPageInfo;
    }
  }

  switch(action.type){
    case type.SET_SELECTED_TAB_KEY:
      newState.selectedTabKey = action.tab;
      return newState;

    case type.SET_SELECTED_RANGE:
      const selectedRange = { from: action.from, to: action.to };
      newState.selectedRange = selectedRange;
      resetPageInfo(newState);
      return newState;

    case type.SET_SELECTED_PAGEINFO:
      const newSelectedPageInfo = {
        ...state.selectedPageInfo,
        [state.selectedTabKey]: action.pageInfo
      }
      newState.selectedPageInfo = newSelectedPageInfo;
      return newState;

    case type.SET_SELECTED_ADVANCED_FILTERS:
      newSelectedAdvancedFilters[state.selectedTabKey] = action.advancedFilters;
      newState.selectedAdvancedFilters = newSelectedAdvancedFilters;
      resetPageInfo(newState);
      return newState;

    case type.SET_NAME_FILTER_VALUE:
      newSelectedFilters[state.selectedTabKey] = {
        ...newSelectedFilters[state.selectedTabKey],
        filterByName: action.nameFilterValue
      }
      newState.selectedFilters = newSelectedFilters;
      return newState;

    default:
      return newState;
  }
}

export default { visitsPage: reducer };
