import React, { useState } from 'react';
import { Row } from 'antd';
import I18N from '../../I18N';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import { conditionList } from '../fields/ProgramEligibility';
import FormRenderer from './FormRenderer';
import IcdCodeSelect from './IcdCodeSelect';

import './icdCodeCondition.styles.scss';

const IcdCodeCondition = ({ form, onChange, subHeader, conditionListField = conditionList, icdCodesInitialValue, billableIcdCodesInitialValue, conditionsInitialValue, disabled, diagnosisSource, switchDiagnosisSource }) => {
  const isDiagnosisSourceICDCode = diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE;
  const [showEnterIcdCode, _setShowEnterIcdCode] = useState(isDiagnosisSourceICDCode);

  const handleSetShowEnterIcdCode = (flag) => {
    if (disabled) return;
    _setShowEnterIcdCode(flag);
    if (switchDiagnosisSource) {
      const newDiagnosisSource = flag ? DIAGNOSIS_SOURCE.ICD_CODE : DIAGNOSIS_SOURCE.CONDITION;
      switchDiagnosisSource(newDiagnosisSource);
    }
  }

  const renderPatientConditionSubHeader = () => {
    const clickableText = I18N.get('referAndEnroll.patientConditionIcdCodes');
    let subHeaderText = subHeader;
    if (typeof subHeaderText === 'undefined') {
      subHeaderText = I18N.get('referAndEnroll.patientConditionSubheader')
      const splitSubHeaderText = subHeaderText.split(clickableText);
      subHeaderText = (
        <div>
          {splitSubHeaderText[0] + ' '}
          <a onClick={() => handleSetShowEnterIcdCode(true)} style={{ textDecoration: 'underline' }}>{clickableText}</a>
          {' ' + splitSubHeaderText[1]}
        </div>
      );
    }
    return subHeaderText; 
  }

  const renderBillableConditionSubHeader = () => {
    const clickableText = I18N.get('referAndEnroll.addBillableConditionQuickBox');
    const strSplit = I18N.get('referAndEnroll.addBillableConditionsSubHeader2').split(clickableText);
    return (
      <div style={{ marginBottom: 20 }}>
        {strSplit[0] + ' '}
        <a onClick={() => handleSetShowEnterIcdCode(false)} style={{ textDecoration: 'underline' }}>{clickableText}</a>
        {strSplit[1]}
      </div>
    );
  }

  const handleConditionSelect = (conditions) => {
    if (onChange) onChange({ conditions });
  }

  const handleIcdCodeSelect = (billableIcdCodes) => {
    if (onChange) onChange({ billableIcdCodes });
  }

  return (
    <div className={`icdCodeCondition ${disabled ? 'disabled' : ''}`}>
      {showEnterIcdCode ? renderBillableConditionSubHeader() : renderPatientConditionSubHeader()}
      <Row hidden={!showEnterIcdCode}>
        <IcdCodeSelect 
          form={form} 
          onChange={handleIcdCodeSelect} 
          icdCodesInitialValue={icdCodesInitialValue} 
          billableIcdCodesInitialValue={billableIcdCodesInitialValue} 
          disabled={disabled} 
        />
      </Row>
      <Row hidden={showEnterIcdCode}>
        <FormRenderer 
          form={form} 
          fields={[conditionListField({ onChange: handleConditionSelect, initialValue: conditionsInitialValue })]} 
          disabled={disabled}
        />
      </Row>
    </div>
  );
}

export default IcdCodeCondition;
