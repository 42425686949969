import _ from 'lodash'

export const getSortedAdminProgramFormSessionStorage = (programs) => {
  if (!programs) {
    programs = JSON.parse(sessionStorage.getItem('adminProgramList'))
  }
  const programsSorted = _.orderBy(
    programs,
    [programme => programme.name ? programme.name.toLowerCase() : ''],
    ['asc']
  )
  return programsSorted
}