import type from '../constants'

const initialState = {
  reason: 'Readmitted to Hospital',
};

export default (state = initialState, action) => {
  switch(action.type) {
    case type.CHANGE_SUSPEND_REASON:
    return {
      reason: action.reason
    }
    default:
      return state
  }
};
