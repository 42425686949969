import types from 'patientModule/profileEdit/constants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'setSubmitting'
])

export default {
  ...commonActionsObject,
}
