import { connect } from 'react-redux';

import MainModal from '../components/MainModalComponent';
import actions from  '../actions/MainModal';

const mapState = ({ MainModal }) => {
  return {
    ...MainModal
  }
}

export default connect(
  mapState, actions
)(MainModal);
