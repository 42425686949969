import React from 'react';
import _ from 'lodash';
import { Modal, Icon } from 'antd';
import { graphql } from "react-apollo/index";
import { compose } from 'recompose';
import ConsentFormComponent from './consentForm/ConsentFormComponent';
import api from '../../../VideoChat/API';
import '../../main/css/consentForm.scss';

const consentInfo = graphql(api.getPatientConsent, {
  options: (ownProps) => {
    const memberId = ownProps.patientId;

    return {
      variables: {
        memberId
      },
      fetchPolicy:'network-only'
    }
  },
  props: ({ data, ownProps }) => {
    const { getPatientConsent, refetch, loading } = data;

    // if (!loading) {
    //   const consent  = _.get(getPatientConsent, 'consent');
    //   if(typeof consent !== 'boolean'){
    //     ownProps.hide();
    //   } 
    // }

    return {
      consentInfo: getPatientConsent || {},
      refetchConsentInfo: refetch,
    };
  }
})


const SendConsentFormButton = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConsentForm: false
    };
  }

  setShowConsentForm = (showConsentForm, cb) => {
    this.setState({ showConsentForm }, () => {
      if (cb) cb();
    });
  }

  getButtonName = () => {
    const consent  = _.get(this.props, 'consentInfo.consent');

    if(typeof consent !== 'boolean'){
      return 'Send consent form';
    } else {
      return 'Resend consent form';
    }
  }

  renderShowSendConsentForm= () => {
    const { consentInfo, patientId, refetchConsentInfo, currentProgram, user, isEnrolled } = this.props;
        
    const memberFullname = _.get(user, 'profile.fullName');
    const appLanguage = _.get(user, 'profile.appLanguage.code');
    const spokenLanguage = _.get(user, 'profile.language.code');
    const consentProviderName = _.get(currentProgram, 'team.0.consentProviderName');
    const phoneNumbers = _.get(user, 'phone', []).map(p => '+' + p.countryCode + p.number);

    return (
      <Modal visible={true} footer={false} title={this.getButtonName()} width={320} className='consentFormModal' width={'90%'} onCancel={() => this.setShowConsentForm(false)}>
        <ConsentFormComponent
          language={appLanguage || spokenLanguage || 'EL'}
          consentInfo={consentInfo}
          consentProviderName={consentProviderName}
          memberId={patientId}
          memberFullname={memberFullname}
          setShowConsentForm={this.setShowConsentForm}
          refetchConsentInfo={refetchConsentInfo}
          phoneNumbers={phoneNumbers}
          isEnrolled={isEnrolled}
        />
      </Modal>
    );
  }

  renderSendConsentButton = () => {
    const { props, setShowConsentForm } = this;
    const { showIcon } = props;

    return (
        <div className='consent-form-status'>
          <div className='consent-form-status-signed'>
            <div className='consent-form-status-text' style={{ marginTop:5 }}>
            <a style={{ textDecoration:'underline' }} onClick={() => setShowConsentForm(true)}>
              {showIcon && <Icon type="info-circle" style={{ color:'#F5222E' }}/>}
              {this.getButtonName()}
            </a>
            </div>
          </div>
        </div>
    );
  }

  render() {
    const { showConsentForm } = this.state;

    return (
      <div>
        {this.renderSendConsentButton()}
        {showConsentForm && this.renderShowSendConsentForm()}
      </div>
    );
  }
}

export default compose(consentInfo)(SendConsentFormButton);
