import gql from 'graphql-tag'


const timeSpentGQL =  gql`query getMonthlyTimeSpent($userId:EID!,$monthOfYear:Int!) {
                                      getMonthlyTimeSpent(
                                        memberId:$userId,
                                        monthOfYear:$monthOfYear
                                      ) {
                                        totalTime
                                        monthOfYear
                                        updatedAt
                                      }
                                    }`


const timeSpentListGQL = gql`query monthlyTimeSpentList($memberId:EID,$count:Int,$page:Int,$monthOfYear:[Int]) {
  monthlyTimeSpentList(filters: {memberId:$memberId,months:$monthOfYear},count:$count,page:$page){
   data{
    totalTime
    monthOfYear
    updatedAt
    events{
      accumulatedTime
      startTime
      endTime
      interventions{
        intervention
      }
      provider{
        profile{
          fullName
        }
      }
    }
  }
  pageInfo{
    total
    lastPage
  }
  }
}`
const timeSpentListMiniGQL = gql`query monthlyTimeSpentMiniList($memberId:EID,$count:Int,$page:Int,$monthOfYear:[Int]) {
  monthlyTimeSpentList(filters: {memberId:$memberId,months:$monthOfYear},count:$count,page:$page){
   data{
    totalTime
    monthOfYear
  }
  pageInfo{
    total
  }
  }
}`
const getMonthlyTimeSpent = gql`query getMonthlyTimeSpent($memberId:EID!,$monthOfYear:Int!){
  getMonthlyTimeSpent(memberId:$memberId,monthOfYear:$monthOfYear){
    totalTime
    monthOfYear
  }
}`;

const getUserTotalTimeSpent = gql`
query getMemberTotalTimeSpent($memberId: EID!) {
  getMemberTotalTimeSpent(memberId: $memberId)
}
`;

export {
  timeSpentGQL,
  timeSpentListGQL,
  getMonthlyTimeSpent,
  getUserTotalTimeSpent,
  timeSpentListMiniGQL
}
