import React from 'react';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

import I18N from 'modulesAll/I18N';

const SignOffProviderNote = (props) => {
  const { providerNotes } = props;

  return <div className='signOffItem'>
    <div className='header'>
      Provider Note
    </div>
    {
      _.map(providerNotes, ({ id, category, content, createdAt, createdBy  }) => {
        const createdByName = _.get(createdBy, 'profile.fullName');

        return <div key={id} className='signOffCard signOffProviderNote'>
          <div className='description'>
            Category: {I18N.get('providerNoteCat')[category] || '--'}
          </div>
          <div className='description note'>
            {
              content && 
              <p className='hideOnList'>
                {
                  `Content: 
                  ${content}`
                }
              </p>
            }
            <SignOffSubmitInfo 
              submittedBy={createdByName}
              submittedAt={createdAt}
            />
          </div>
        </div>;
      })
    }
  </div>;
}

export default SignOffProviderNote;