import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const GoalComponentWrapper = props => {
  const { children } = props;
  return (
    <div id='goal-component-wrapper'>
      { children }
    </div>
  )
};

GoalComponentWrapper.displayName = 'GoalComponentWrapper';
GoalComponentWrapper.propTypes = {
  children: PropTypes.node
};

export default GoalComponentWrapper;