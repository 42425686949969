import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';

import ChatContainerWrapper from '../../../../chat/containers/ChatContainerWrapper';

import { getTimeRangeWithFormat } from '../../../helper/signOffHelper';

const SignOffChat = (props) => {
  const { chatHistory, selectedChannel } = props;

  const { channel, from, to } = chatHistory;
  const patientId = btoa(`accounts:${_.split(channel, '-')[1]}`);

  return <div className='signOffItem'>
    <div className='header'>
      Chat History
    </div>
    <div className='signOffCard signOffChat'>
      <div className='description'>
        {getTimeRangeWithFormat(from, to, null, 'MM/DD/YYYY', 'MM/DD/YYYY', true)}
      </div>
      <ChatContainerWrapper 
        programStatus={'STARTED'} // TODO: get actual program; what if patient is discharged when signoff?
        patientId={patientId}
        renderChatIcon={({ handleToggleChat }) => (
          <Button type='link' onClick={handleToggleChat}>
            {(selectedChannel !== channel ? 'Open' : 'Close') + ' chat'}
          </Button>
        )}
        chatWithIndividual
        // shouldRenderChatWindow: false => must exist another ChatContainer in DOMtree, true => this is the only one
        shouldRenderChatWindow={false} 
        // prevent from hiding chat icon in renderChatIcon
        keepIconOnShow
      />
      {/* <div className='description' style={{ textAlign: 'right' }}>
        Chat by: {senderNames.join(', ')}
      </div> */}
    </div>
  </div>;
}

const mapStateToProps = (state) => ({
  selectedChannel: state.chat.main.selectedChannel
});

export default connect(mapStateToProps, null)(SignOffChat);