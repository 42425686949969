import React from 'react';
import { IHSelect, _ } from 'ihcomponent'
import { connect } from 'react-redux'
import { SuperContainer, goPath, getRouterQuery } from 'libModule/utils'
import actions from '../actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { mayBeStubbed } from '@storybook/react-stubber';
import { getSortedAdminProgramFormSessionStorage } from 'modulesAll/utils/helpers/program'

const Component = class extends SuperContainer{
  static displayName = 'ProgramSelect'

  renderMain(){
    const {selectCurrentProgram, currentProgram} = this.props
    const optionRaw = _.map(JSON.parse(sessionStorage.getItem('adminProgramList')) || [], (prog) => ({
        name : prog.name,
        value : prog.id
    }))
    const option = getSortedAdminProgramFormSessionStorage(optionRaw)

    const p = {
      option,
      size: 'large',
      notFoundContent: `No ${I18N.get('keywords.Program')}`,
      value: currentProgram? currentProgram : _.get(option, '0.value'),
      onChange: (id) => {
        // selectCurrentProgram(id)
        const selectedProgram = _.find(JSON.parse(sessionStorage.getItem('adminProgramList')), { id })
        createAuditLog({
          action: I18N.get('auditLog.provider.dashboard.selectProgramme'),
          details: { programName: selectedProgram.name }
        })

        goPath('/dashboard?programId='+id)
      }
    }

    const sy = {
      width : '480px',
      marginBottom : '10px',
      marginTop : '10px',
      marginLeft : '10px'
    }

    return (
      <div style={sy}>
        <IHSelect {...p} />
      </div>
    )
  }
}

const mapStateToProps = (state)=>{
  const query = getRouterQuery(state)
  return {
    ...query,
    currentProgram : _.get(state, 'routing.location.query.programId')
  }
}

export default mayBeStubbed(connect(mapStateToProps, { selectCurrentProgram: actions.selectCurrentProgram })(Component));
