/**
 * Created by zizhangai on 2/8/17.
 */
import { constants } from 'libModule/utils';

export default constants('patientView', 'VitalList', [
  'CHANGE_DATE',
  'CHANGE_REASON',
  'CHANGE_INPUT',
  'RESET'
])

export const mapReason = {
  'Suspend': 'Suspension from programme',
  'Discharge': 'Discharge of patient from programme',
  'Condition change': 'Significant changes in patient’s condition'
}
