import { constants } from 'libModule/utils'

export default constants('ProgramIncomplete/Careplan', 'Common', [
  'CHANGE_PAGE',
  'CAREPLAN_MAIN_PAGE',
  'CAREPLAN_VITAL_FORM_PAGE',
  'ASSESSMENT_FORM_PAGE',
  'SURVEY_FORM_PAGE',
  'EDUCATION_FORM_PAGE',
  'SET_ASSESSMENT_HAS_EMPTY_SCHEDULE',
  'SET_SURVEY_HAS_EMPTY_SCHEDULE'
])
