import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'antd';
import { message } from 'ihcomponent';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils';
import unassignLoanDevice from "../../patient/loanDevices/mutate/unassignLoanDevice";
import getLoanDevice from "../../patient/loanDevices/query/getLoanDevice";

export class UnassignLoanDevice extends Component {
  constructor(props){
    super(props);
  }

  unassignDevice = async () => {
    const { phoneInfo: { deviceId }, setAssignment } = this.props;
    const memberId = _.get(this.props,'userId');
    let variables = {
        deviceId,
        memberId
    }

    return new Promise((resolve,reject)=> {
        Modal.confirm({
            title:  'Unassign Device for this patient?',
            onOk: ()=>
                Client.mutate({
                    mutation: unassignLoanDevice,
                    variables,
                  })
                  .then(res => {
                  message.success('Device Unassigned');
                  setAssignment(false, {});
                  resolve(true);
              }).catch(e => {
                  console.log(e);
                  openErrorModal(GQLHelper.formatError(e))
              }),
            okText: "Unassign Device",
            cancelText: 'Cancel',
            onCancel: ()=>{
                resolve(true);
            }
          })
      })

  }

  render() {
    return (
      <div>
        <Row>
          <Col span={12}>
            Serial number
          </Col>
          <Col>
            {this.props.phoneInfo.serialNumber}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            Version
          </Col>
          <Col>
            {this.props.phoneInfo.version}
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          <Col span={24} style={{float: 'right'}}>
            <Button onClick={() => {
              this.unassignDevice();
            }} >
              Unassign device
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default UnassignLoanDevice
