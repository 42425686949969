import { convertToFieldsValue, getInitDataFromSchema } from 'libModule/helpers/reducer-helpers'
import types from '../constants/OneTimeTaskConstants'
import { schema } from '../components/OneTimeTaskComponent'


// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initState = {
  fieldsValue: initFieldsValue,
  isFormSaved: true,
  isSubmitting: false,
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.FORM_SAVED:
      return {
        ...state,
        isFormSaved: true,
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormSaved: false
      }
    case types.RESET_FORM:
      return initState
    case types.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }
    default:
      return state
  }
}
