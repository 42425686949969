import React from 'react';
import { Icon,Tooltip } from 'antd';
const CapacityIconComponent = (props)=>{
	let title = 'Capacity is the maximum number \n' +
		'of patients this care team member \n' +
		'is able to handle under the selected \n' +
		'role in all clinics.'
		return <Tooltip title={title} >
			<Icon type="info-circle" style={{ alignSelf:'center',marginLeft: 5 }} theme='filled' />
		</Tooltip>
}
export default CapacityIconComponent;