import gql from 'graphql-tag';
export default gql`
query loanDeviceList($status:LoanStatusFilterEnum) {
    loanDeviceList(filters: {status: $status}) {
        data {
            deviceId
            serialNumber
            osVersion
            appVersion
            status
            member {
                profile {
                    firstName
                    lastName
                }
            }
        }
    }
}`
