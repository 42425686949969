import {
    React, IHButton
} from 'ihcomponent'
import ListTable from './ListTable'
import createTableAction from 'libModule/table/TableActions'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import _ from 'lodash'


const tableActions = createTableAction('care_team_list', {
  shouldUpdateHistory: false,
  sortFieldMap: {
    createdAt: 'CREATED_AT',
    modifiedAt: 'MODIFIED_AT',
    careTeam: 'NAME'
  },
})

const Component = (props)=>{
    const buttonProps = {
        label : 'Create New Care Team',
        size : '',
        type : 'primary',
        onClick : ()=>{
          browserHistory.push(`/careteams/new`)
        }
    }

    return (
        <div className="vsm-main-page vsm-patientList-main">
            <div className="vsm-main-990">
                <div className="v-rd">
                    <IHButton {...buttonProps} />
                </div>
                {/* <div className="v-table">
                    <ListTable {...props} />
                </div> */}
            </div>
        </div>
    )
}

Component.displayName = 'careTeam/list/containers/main'

export default compose(
  connect(
    (state)=>{
        return {
          ...state.globalTables.care_team_list
        }
    },
    (dispatch)=>{
        return {
            ...tableActions
        }
      }
    ),
)(Component)
