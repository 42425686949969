
// Imports
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import PreLogin from '../components/PreLoginLayoutComponent'


class Container extends Component {
  static displayName = 'PreLoginLayoutContainer'

  componentDidMount() {
    document.title = window.VSM_CONFIG.DEMO ? window.VSM_CONFIG.DEMO: 'VSM';
  }

  render() {
    const {main} = this.props;

    return <PreLogin {...{main}} />;
  }
}

Container.propTypes = {
  main: PropTypes.object.isRequired,
};


export default Container
