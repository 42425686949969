import React from 'react';
import { InputNumber } from 'antd';
import moment from 'moment';
import WeeklyEnrollments from '../components/WeeklyEnrollments';
import { debounce } from 'lodash';
import { CHART_DEBOUNCE_TIME } from '../constants';

class WeeklyEnrollmentsContainer extends React.Component {
  constructor(props) {
    super(props);
    const defaultColumnCount = props.defaultColumnCount || 4;
    this.state = {
      weeks: defaultColumnCount,
      startDate: this.getStartingDate(defaultColumnCount),
    };
    this.deboundUpdateStartDate = debounce(this.updateStartDate, CHART_DEBOUNCE_TIME);
  }

  updateStartDate = () => {
    this.setState({ startDate: this.getStartingDate(this.state.weeks) });
  }
  
  handleWeekChange = (weeks) => {
    this.setState({ weeks }, this.deboundUpdateStartDate);
  }
  
  getStartingDate = (weeks) => {
    return moment().endOf('week').add(2, 'day').add(-weeks, 'week');
  }

  render() {
    const { organizationName, organizationId, openErrorModal } = this.props;
    const { weeks, startDate } = this.state;

    return (
      <div>
        Weeks <InputNumber onChange={this.handleWeekChange} value={weeks} />
        <WeeklyEnrollments organizationName={organizationName} organizationId={organizationId} openErrorModal={openErrorModal} startDate={startDate} />
      </div>
    );
  }
}

export default WeeklyEnrollmentsContainer;