import gql from 'graphql-tag';
import Measure from './schema/Measure';
import { BGStat, BPStat, POStat } from './schema/Result';

export default gql`
  query resultList(
    $count: Int,
    $page: Int,
    $sort: ResultListSort,
    $filters: ResultListOpts!
  ) {
    resultList(
      filters: $filters,
      count: $count,
      page: $page,
      sort: $sort
    ) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        caredBy {
          id
          profile {
            fullName
          }
        }
        user {
          id
          profile {
            fullName
            ...on MemberProfile{
                height
            }
          }
          identification {
            value
          }
        }
        enrolledProgram {
          id
          name
          tasks {
            id
            repeat
            type
          }
        }
        measure {
          user_notes
          imageUrl
          ${Measure}
         
        }
        manualInput
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          arrhythmia
        }
        ... on BGResult {
          type
          beforeMeal
          mealType
          blood_glucose
        }
        ... on HSResult {
          body_weight,
          body_weight_change,
          body_weight_change_baseline
        }
        ... on HRResult {
          heart_rate
        }
        ... on TMResult {
          body_temperature
        }
        ... on POResult {
          blood_oxygen
          perfusion_index
        }
        ... on ASSESSMENTResult {
          assessment {
            _id
            name
          }
        }
        threshold {
          measure
        }
        repeat
        schedule {
          calendar
          target
          start
          end
          beforeMeal
          mealType
        }
      } pageInfo {
          total
          lastPage
      }
    }
  }
`;

export const resultListWithoutProviderNotes = gql`
  query resultList(
    $count: Int,
    $page: Int,
    $sort: ResultListSort,
    $filters: ResultListOpts!
  ) {
    resultList(
      filters: $filters,
      count: $count,
      page: $page,
      sort: $sort
    ) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        hadMedicine
        hadActivity
        hadInsulin
        moodTags
        enrolledProgram {
          id
          name
          tasks {
            id
            repeat
            type
          }
        }
        measure {
          user_notes
          imageUrl
          ${Measure}
        }
        user {
          id
          profile {
            fullName
            ...on MemberProfile{
                height
            }
          }
          identification {
            value
          }
        }
        assessmentFilled {
          id
          _id
          name
        }
        refId
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          arrhythmia
        }
        ... on BGResult {
          blood_glucose
          beforeMeal
          type
          mealType
        }
        ... on HSResult {
          body_weight,
          body_weight_change,
          body_weight_change_baseline
        }
        ... on HRResult {
          heart_rate
        }
        ... on TMResult {
          body_temperature
        }
        ... on POResult {
          blood_oxygen,
          perfusion_index,
        }
        threshold {
          measure
          exerciseGoal{
          unit
          target
          timeUnit
          }
          
        }
        ... on ASSESSMENTResult {
          assessment {
            id
            _id
            name
          }
          completed
        }
        manualInput
        repeat
        schedule {
          calendar
          target
          start
          end
          beforeMeal
          mealType
        }
      } pageInfo {
        total
        lastPage
      }
    }
  }
`;

export const resultListMinimal = gql`
  query resultList(
    $count: Int,
    $filters: ResultListOpts!
  ) {
    resultList(
      count: $count,
      filters: $filters,
    ) {
      data {
        id
      }
      pageInfo {
        total
      }
    }
  }
`;

export const assessmentSurveyResultList = gql`
  query resultList(
    $count: Int,
    $page: Int,
    $sort: ResultListSort,
    $filters: ResultListOpts!
  ) {
    resultList(
      filters: $filters,
      count: $count,
      page: $page,
      sort: $sort
    ) {
      data {
        id
        date
        type
        severity
        taskStatus
        careStatus
        enrolledProgram {
          id
          name
          tasks {
            id
            repeat
            type
          }
        }
        assessmentFilled {
          id
          _id
        }

        ... on ASSESSMENTResult {
          assessment {
            id
            name
            _id
          }
          completed
        }
        repeat
        schedule {
          calendar
          target
          start
          end
          beforeMeal
          mealType
        }
      } pageInfo {
          total
          lastPage
      }
    }
  }
`;

export const vitalsSummaryList = gql`
  query resultList(
    $count: Int,
    $page: Int,
    $sort: ResultListSort,
    $filters: ResultListOpts!
  ) {
  resultList(filters: $filters, sort: $sort, page: $page, count: $count) {
    stat {
      bg {
        ${BGStat}
      }
      bp {
        ${BPStat}
      }
      po {
        ${POStat}
      }
    }
  }
}
`;

export const vitalsSummaryStats = gql`
  query resultList(
    $count: Int,
    $filters: ResultListOpts!
    $BG: Boolean = false
    $BP: Boolean = false
    $PO: Boolean = false
  ) {
  resultList(count: $count, filters: $filters) {
    stat {
      bg @include(if: $BG) {
        ${BGStat}
      }
      bp @include(if: $BP){
        ${BPStat}
      }
      po @include(if: $PO){
        ${POStat}
      }
    }
  }
}
`;

export const latestMeasurementBeforeVisit = gql`
  query resultList(
    $count: Int,
    $filters: ResultListOpts!
  ) {
    resultList(
      count: $count,
      filters: $filters,
    ) {
      data {
        ... on BPResult {
          systolic_blood_pressure
          diastolic_blood_pressure
          heart_rate
          arrhythmia
          manualInput
        }
      }
      pageInfo {
        total
      }
    }
  }
`;

export const getMeasurementCounts = gql`
  query getMeasurementCount($filters: ResultListOpts!) {
    resultList(filters: $filters) {
      data {
        id
        type
      }
    }
  }
`;