import React from "react";
import { Calendar,Button } from 'antd';
import types from '../constants/appointmentTypes';
import _ from 'lodash';
import moment from 'moment';

const styles = {
    mainContainer:{ },
}


const AppointmentMonthCalenderComponent = class extends React.Component {
    constructor() {
        super();
    }


    render(){
        const { setAppointMonthAndYear,selectedDay } = this.props;

        return <div style={ styles.mainContainer }>
                <div style={{display:'flex',marginTop:20,marginBottom:20,justifyContent:'center'}}>
                    <Calendar fullscreen={false}
                              onPanelChange={(value,mode)=>setAppointMonthAndYear(value)}
                              onSelect={(value)=>setAppointMonthAndYear(value)}
                              mode={'month'}
                              className='visitCalender'
                              value={moment(selectedDay)}

                    />
                </div>
              </div>
    }

}
export default  AppointmentMonthCalenderComponent;
