import { LANGUAGE_MAP } from './languageMap';
import config from '../../../lib/config';

export const getTranslate = async (query,target)=>{
    const _target = LANGUAGE_MAP[target] || target;
    const ep = `https://translation.googleapis.com/language/translate/v2?key=${config.GOOGLE_TRANSLATE_KEY}&q=${query}&target=${_target}`;
    const response =  await fetch(ep,{
        method: 'get'
    })
    return response.json();
}

export const getLanguages = async () => {
    const ep = `https://translation.googleapis.com/language/translate/v2/languages?key=${config.GOOGLE_TRANSLATE_KEY}&target=EN`;
    const response =  await fetch(ep, {
        method: 'get'
    })
    const value = await response.json();
    return _.reduce(value.data.languages, (result, curr) => { 
        result[curr.name] = curr.language;
        return result;
    }, {});
}
