import React from 'react';
import { Form, InputNumber } from 'antd';

const replaceDollarSign = (value) => {
  const valueStr = String(typeof value === 'number' ? value : (value || ''));
  return valueStr.replace(/\$\s*/g, '');
};

// export interface InsuranceCopayInputFormItemComponentProps extends FormItemProps {}
export const InsuranceCopayInputFormItemComponent = (
  props,
) => {
  const {
    form,
    initialValue,
    ...restProps
  } = props;
  if (!form) return null;
  return (
    <Form.Item
      label=""
      hidden={!form.getFieldValue('hasCopay')}
      {...restProps}
    >
      {
        form.getFieldDecorator('copay', {
          initialValue,
        })(
          <InputNumber
            formatter={(value) => `$ ${replaceDollarSign(value)}`}
            parser={replaceDollarSign}
            placeholder="Enter amount"
          />
        )
      }
    </Form.Item>
  );
};
