import React from 'react';
import types from '../constants/VitalListBGConstants';
import { commonActions } from 'libModule/helpers/action-helpers';
import { openModal } from 'layoutModule/actions/MainModal';
import moment from 'moment'
// files
import PatientVitalModal from '../components/PatientVitalModal'

export const changeRange = (range) => ({
  type: types.CHANGE_RANGE,
  range
})

const changeView = (view) => ({
  type: types.CHANGE_VIEW,
  view
})

const changeMode = (mode) => ({
  type: types.CHANGE_MODE,
  mode
})

const openPatientVitalModal = (row, type) => {
  // const taskId = get(row, 'task.id') 
  // const programId = get(row, 'program.id') 
  // const modalProps = { 
  //   taskId, 
  //   programId 
  // } 
  return (dispatch) => {
    const props = {
      showModal: true,
      showHeaderCloseButton: true,
      title: `My Reading on `+ moment(row.date).format("MMM-DD-YYYY"),
      className: 'vsm-manual-input-modal',
      size: 'md'
    };
    dispatch(openModal(<PatientVitalModal row={row} type={type} />, props))

  }
}

export default Object.assign({
  changeRange,
  changeView,
  changeMode,
  openPatientVitalModal
}, commonActions(types, ['onTableChange']))
