import React from 'react';
import ABTestingConfigComponent from "../component/ABTestingConfigComponent";
import {graphql} from "react-apollo";
import query from "../../query";
import helper from "../../helper";
import {compose} from "recompose";
import { Spin } from 'antd'
const ABTestingConfigContainer = class extends React.Component{

    render() {
        const { orgList,orgsLoading } = this.props;
        if(orgsLoading) {
            return <div style={{ height:'70vh',display:'flex',alignItems:'center',justifyContent:'center' }}><Spin/></div>
        }
        return <ABTestingConfigComponent orgList={orgList}/>
    }
}

const areaList = graphql(query.orgListWithNameAndPermissions,{
    options: (ownProps) => {
        const currentUser = helper.getCurrentUser();
        const rootId = _.get(currentUser,'selectedRole.organization.id');
        const variables = {
            page: 1,
            count: 99999,
            filters: {
                rootId,
                leafOnly:true
            }
        }
        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { organizationList,loading,refetch } = data;
        return {
            orgList: _.get(organizationList,'data',[]),
            orgsLoading: loading,
            refetchAreaList: refetch
        }
    }
})
export default compose(areaList)(ABTestingConfigContainer);
