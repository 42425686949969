import React from 'react';
import {graphql} from "react-apollo";
import Table from 'antd/lib/table';
import 'antd/lib/table/style/index.css';
import { connect } from 'react-redux';
import { compose, defaultProps, withState, withHandlers } from 'recompose';
import moment from 'moment';
import { default as get } from 'lodash/get';
import atob from 'atob';

import auditTrailListTag from 'graphqlModule/auditTrailList';

const formatDate = (date) => date ? moment(date).format('MMMM DD, YYYY HH:mm:ss') : '';

const columns = [{
  title: 'Date Created',
  dataIndex: 'createdAt',
  render: formatDate
}, {
  title: 'Viewer',
  dataIndex: 'viewer.profile.fullName',
}, {
  title: 'IP',
  dataIndex: 'ip',
}, {
  title: 'Action',
  dataIndex: 'action'
}, {
  title: 'Resource',
  dataIndex: 'resource',
}, {
  title: 'Resource ID',
  dataIndex: 'docId',
  render: id => id && atob(id)
}, {
  title: 'Resource Owner',
  dataIndex: 'username',
}, {
  title: 'Effect',
  dataIndex: 'status',
}];

const AuditTrailListView = ({ 
  auditTrailList,
  pagination, loading,
  onChange
}) => {
  return (
    <Table
    paginate={true}
    columns={columns}
    dataSource={auditTrailList}
    bordered
    loading={loading}
    onChange={onChange}
    size="default"
    pagination={pagination}
    />
  )
}

const fetchData = graphql(auditTrailListTag, {
  options: ownProps => ({
    variables: {
      filters: {
        action: ownProps.filters_action
      },
      page: ownProps.pagination.current,
      count: ownProps.pagination.pageSize,
      sort: {
        field: ownProps.sort_field,
        direction: ownProps.sort_direction,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({data, ownProps}) => {
    return {
      loading: data.loading,
      auditTrailList: get(data, 'auditTrailList.data', []).map(row => ({...row, key: `audittrail_${row.id}`})),
      refetch: data.refetch,
      pagination: {
        ...ownProps.pagination,
        total: get(data, 'auditTrailList.pageInfo.total', 1)
      }
    }
  }
});

const enhance = compose(
  defaultProps({
    filters_action: null,
    sort_field: 'CREATED_AT',
    sort_direction: 'DESC',
    pagination: {
      current: 1,
      pageSize: 10
    },
    auditTrailList: []
  }),
  withState('pagination', 'updatePagination', {}),
  withHandlers({
    onChange: props => event => {
      const { current, pageSize, total } = event;
      props.updatePagination({
        ...props.pagination,
        current, pageSize, total
      })
    }
  }),
  fetchData
);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(enhance(AuditTrailListView))
