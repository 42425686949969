import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHButton, _ } from 'ihcomponent';
import { Icon } from 'antd'
import StatusDropDown from '../../../alerts/InterventionNotes/containers/StatusDropDownContainer'
import { formatReadingFromRow, hasMeasurement, hasMissedAlert, renderVitalType,formatDateMM24Date } from 'modulesAll/utils/helpers/task';
import moment from 'moment'
import $ from 'jquery';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';

const ProviderAlertListTable = (props) => {
    const { alertList, onAddClick, loading, onTableChange, adminProgramFilter, vitalFilters, page, sort,isInChat,enrolledProgramId,setAlertData,setShowAlertModal,patientId } = props;
    const patientProfileTabWidth = $('.patient-profile-tabs').width();
    const variables = props.variables;
    const columns = [
    {
      title: (patientProfileTabWidth <= 800) ? 'Date':'Date / Time',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b) => a.date - b.date,
      render: (item, row) => formatDateMM24Date(item)
          // moment(item).format('MMM-DD-YYYY hh:mm a')
    },
    {
      title: <p>Reading </p>,
          // <em style={{fontSize: '11px'}}> (M = Manual)</em></p>,
      key: 'measure',
      width: isInChat ? '20%':'',
      dataIndex: 'measure',
      render: (item, row) => hasMissedAlert(row) ? <span className="miss-black">Missed</span> : hasMeasurement(row) ? formatReadingFromRow(row,isInChat) : '',
      
    },
    {
      title: 'Type',
      // width: '15%',
      key: 'type',
      dataIndex: 'type',
      render: renderVitalType,
      filters: [    
                  { value: 'BG', text: 'Blood Glucose' },
                  { value: 'BP', text: 'Blood Pressure' },
                  { value: 'TM', text: 'Temperature' },
                  { value: 'PO', text: 'Blood Oxygen' },
                  { value: 'HS', text: 'Weight' }
               ],
      onFilter : (a, doc)=>{
          return a === doc.type
      }
    },
    {
      title: 'Status',
      key: 'careStatus',
      // width: '15%',
      dataIndex: 'careStatus',
      render: (item, row) => {
        switch (item) {
          case 'NEW': return 'Open'
          case 'HANDLED': return 'Reviewed by ' + _.get(row.caredBy, 'profile.fullName')
          case 'CLOSED': return 'Closed by ' + _.get(row.caredBy, 'profile.fullName')
        }
      },
      filters: [
        { value: 'NEW', text: 'Open' },
        { value: 'HANDLED', text: 'Reviewed' },
        { value: 'CLOSED', text: 'Closed' }
      ]
    },
    {
      title: 'Actions',
      key: 'actions',
      // width: '14%',
      render: (item) => <StatusDropDown row={item} variables={variables} enrolledProgramId={enrolledProgramId} isInChat={isInChat}/>
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: '15%',
      render: (notes, row) => {
        const setCategoryToAlerts = true;

        if (notes && notes.length > 0) {
          return <div onClick={()=>onAddClick(row,setCategoryToAlerts)} style={{cursor: 'pointer'}}><Icon type="file-text"/></div>
        }
        const buttonProps = {
          label: 'Add >',
          size: 'small',
          bsStyle: 'primary',
          onClick: () => {
            Mixpanel.track('clicked','review_note','vitals',{ PATIENT_ID:patientId });
            setShowAlertModal(true);
            setAlertData(row);
          },
              // onAddClick(row,setCategoryToAlerts),
          className: 'table-action-button'
        }
        return <IHButton {...buttonProps} />
      }
    }
  ];

  const tableProps = {
    columns,
    rowKey: 'id',
    pagination: {
      current: _.get(page, 'current', 1),
      pageSize: 10,
      total: _.get(alertList, 'pageInfo.total', 0),
    },
    onChange: onTableChange,
    loading,
    dataSource: alertList ? alertList.data : null,
    title: `Alerts (${_.get(alertList, 'pageInfo.total', 0)})`,
  };

  const noDataElem = (
    <div style={{ height: '150px', paddingTop: '2em' }}>
      <p style={{ fontSize: '18px' }}>There is no data available</p>
    </div>
  );
  const style = {margin: '20px 0 20px 0'}
  return (
    <div style={style}>
      <div className="patient-task-table">
        <IHSearchTable {...tableProps} noDataElem={noDataElem} />
      </div>
    </div>
  );
};

ProviderAlertListTable.propTypes = {
  alertList: PropTypes.object,
  // onRowClick: PropTypes.func,
};

const styles = {
  takeReading: {
    backgroundColor: '#258ce6',
    width: '7.3em',
    padding: '0.2em 1em',
    borderRadius: '10px',
    color: 'white',
    cursor: 'pointer',
  },
};

export default ProviderAlertListTable;