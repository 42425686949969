import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { compose } from 'react-apollo';
import { getPatientConsent } from '../API/query';
import { VITALS_ORDER } from '../../../lib/constants';
import { VITAL_TYPE_MAP_FROM_SHORT } from 'modulesAll/utils/constants/task'

import ReferAndEnrollForm from './ReferAndEnrollForm';
import { conditionToNameMap, STATUS_ENUM } from '../constant';
import { upsertPatientReferralMutation } from '../API/mutation';

import '../style/enrolledSuccess.scss';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper";

const EnrolledSuccessComponent = props => {
  const { user, patientReferral, consentInfo, closeModal } = props;
  const { 
    eligiblePrgorams, 
    recommendedVitals, 
    conditionsToMonnitor,
    initialVisit, 
    devicePickup, 
    deviceShip,
    status
  } = patientReferral || {};
  let patientId = _.get(user, 'id');
  useEffect(() => {
    if(_.get(status, 'enrollStatus') !== STATUS_ENUM.COMPLETED) {
      upsertPatientReferralMutation({
        patientId,
        status: {
          enrollStatus: STATUS_ENUM.COMPLETED
        }
      }).then(async res=>{
        if(!_.get(user, 'remoteEnrollment')) {
          await remoteEnrollmentApis.createRE({ 
            memberId: patientId,
            doctorReferral: true,
            conditionsList: dataHelper.handleConditionsList(conditionsToMonnitor)
          }).catch(console.error);
        }

        await remoteEnrollmentApis.moveRemoteEnrollmentHandler({
          memberId: patientId,
          status: 'CONFIRMED'
        }).catch(console.error);
      });
    }
  }, []);

  const userFullname = _.get(user, 'profile.fullName') || '--';

  const getMonitoredVitals = () => {
    let vitals = _.intersection(VITALS_ORDER, recommendedVitals);
    return _.map(vitals, v => VITAL_TYPE_MAP_FROM_SHORT[v]);
  };

  const getConsentStatusText = () => {
    if(_.isEmpty(consentInfo))
      return '--';
    if(consentInfo.consent)
      return 'Signed';
    return 'Not signed';
  };

  const getInitialVisitText = () => {
    return (initialVisit && initialVisit.id) ? 'Scheduled' : 'Not scheduled';
  };

  const getConditions = () => {
    const names = _.map(conditionsToMonnitor, c => conditionToNameMap[c] || c);
    return _.uniq(names);
  };

  const getDevicesArray = () => {
    let textArr = [];
    if(Array.isArray(deviceShip) && deviceShip.length)
      textArr.push('Shipping requested')
    if(Array.isArray(devicePickup) && devicePickup.length)
      textArr.push('Given');
    return textArr;
  };

  return (
    <ReferAndEnrollForm>
      <div id='refer-enroll-enrolled-success'>
        <img src='/image/referandenroll/enrolled_success.svg' width='145px' height='165px'/>
        <div className='congrats-text'>
          Congratulations! You’ve successfully enrolled {userFullname} to the Unifed Care Program!
        </div>
        <div className='enrolled-information'>
          <div className='summary-text'>
            Enrollment summary:
          </div>
          <div>Enrolled program: {_.join(eligiblePrgorams, ', ') || '--'}</div>
          <div>Monitored vitals: {_.join(getMonitoredVitals(), ', ') || '--'}</div>
          <div>Conditions: {_.join(getConditions(), ', ') || '--'}</div>
          <div>Consent form status: {getConsentStatusText()}</div>
          <div>Initial visit: {getInitialVisitText()}</div>
          <div>Devices: {_.join(getDevicesArray(), '/') || '--'}</div>
        </div>
        <Button type='primary' onClick={closeModal}>
          Close
        </Button>
      </div>
    </ReferAndEnrollForm>
  );
};

EnrolledSuccessComponent.propTypes = {
  user: PropTypes.object.isRequired,
  patientReferral: PropTypes.object,
  closeModal: PropTypes.func.isRequired
};

export default compose(getPatientConsent)(EnrolledSuccessComponent);