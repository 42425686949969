const timezoneMap = {
  'America/New_York': 'EST',
  'America/Chicago': 'CST',
  'America/Phoenix': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Anchorage': 'AST',
  'Pacific/Honolulu': 'HST'
}
export default timezoneMap;

export const weekDayMap = {
  'Tue': 'Tues',
  'Thu': 'Thur'
};

// export const getTimeZoneStr = (timezone) => {
//   if (timezoneMap[timezone]) {
//     return timezoneMap[timezone];
//   }
//   const city = _.split(timezone, '/')[1];
//   if (city) return `${city} time`;
//   return '--';
// }
