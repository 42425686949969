import React from 'react';
import { Input, InputNumber, Select, Radio, Icon } from 'antd';
import { getMemberProfile } from 'patientModule/AdditionalInfo/helpers/index';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';

const { Option } = Select;
const { TextArea } = Input;

const infoWarning = 'Be careful how you ask about education level. Preferably, just remain observant and enter this information if the patient mentions it during discussion.';
const inputNumberRules = [
  { validator: (rule, value, callback) => {
      if(value && value < 0){
        callback('Can\'t be less than 0');
      } else if(value && isNaN(value)){
        callback('Only numeric input allowed');
      } else {
        callback()
      }
  }}
];
const inputNumberRulesHowOften = [
  { validator: (rule, value, callback) => {
      if(value !== 0 && !value) 
        return callback();

      if(!_.isFinite(value)){
        callback('Only numeric input allowed');
      } else if(value < 1){
        callback('Must be a positive whole number');
      } else {
        callback()
      }
  }}
];

const occupationOptions = [
  { value:'Student', label:'Student'},
  { value:'Employed', label:'Employed' },
  { value:'Unemployed', label:'Unemployed'},
  { value:'Self-Employed', label:'Self-Employed' },
  { value:'Retired', label:'Retired' }
];

const activityOptions = [
  { value: 'EXTREMELY_INACTIVE', label: 'Extremely inactive (sitting for at least 10 hours per day)' },
  { value: 'SEDENTARY', label: 'Sedentary (sitting for at least 6 hours per day)' },
  { value: 'IRREGULARLY_EXERCISING', label: 'Irregularly exercising (exercising, but some weeks are completely sedentary)'},
  { value: 'MODERATELY_ACTIVE', label: 'Moderately active (exercising at least 30 minutes per day, 4 days per week)'},
  { value: 'ACTIVE', label: 'Active (at least 60 minutes per day, 4 days per week)' },
  { value: 'VERY_ACTIVE', label: 'Very active (Agricultural or other manual labor; exercising 2+ hours daily)' },
  { value: 'EXTREMELY_ACTIVE', label: 'Extremely active (Competitive sports; heavy weight lifting; exercise 3+ hours daily)' }
];

const unitOptions = [
  { value: 'times/day', label: 'Day' },
  { value: 'times/week', label: 'Week' },
  { value: 'times/month', label: 'Month'}
];

const foodAccessibilityOptions = [
  { value: 'NONE', label: 'None Selected'},
  { value: 'LACK_RESOURCES', label: 'Lack of available resources to shop for food (rural living)'},
  { value: 'SHOP_NO_CARE', label: 'Limited ability to shop independently, and no caregiver to help'},
  { value: 'NO_AID', label: 'Limited income to buy food, not receiving aid (WIC, CalFresh, Food Bank, etc)'},
  { value: 'AID', label: 'Limited income to buy food, but getting aid (WIC, CalFresh, Food Bank, etc)'},
  { value: 'KITCHEN', label: 'Limited access to kitchen features' },
  { value: 'COOK_NO_CARE', label: 'Limited ability to cook for oneself, and no caregiver' },
  { value: 'COOK_CARE', label: 'Limited ability to cook for oneself, but has a caregiver' },
  { value: 'SHOP_CARE', label: 'Limited ability to shop independently, but has a caregiver' }
];


const educationOptions = [
  { value: 'NONE', label: 'None Selected'},
  { value: 'THIRD_GRADE', label: '3rd grade' },
  { value: 'SIXTH_GRADE', label: '6th grade' },
  { value: 'HIGH_SCHOOL', label: 'High school graduate' },
  { value: 'COLLEGE', label: 'College graduate'}
];

const routineOptions = [
  { value: 'NONE', label: 'None selected' },
  { value: 'NO_ROUTINE', label: 'No routine for sleeping; regular routine for meals at least once per day' },
  { value: 'AT_LEAST_ONE_MEAL', label: 'Regular routine for sleeping; at least one meal per day' },
  { value: 'IRREGULAR_ROUTINE', label: 'Regular routine for sleeping; irregular routine for meals' },
  { value: 'REGULAR_ROUTINE', label: 'Have a regular routine for both meals and sleeping' }
];

const eatingRoutineOptions =[
  {
    "value": "REGULAR_ROUTINE",
    label:'Regular eating schedule for each meal of the day.'
  },
  {
    "value": "SOMEWHERE_IRREGULAR_ROUTINE",
    label: 'Somewhat irregular schedule for eating (time to eat SOME meals varies by at least 2 hours).'
  },
  {
    "value": "IRREGULAR_ROUTINE",
    label: 'Irregular schedule for eating (time to eat ALL meals varies by at least 2 hours).'
  },
  {
    "value": "NONE",
    label:'None selected'
  },
]

const sleepingRoutineOptions = [
  {
    "value": "REGULAR_ROUTINE",
    label:'Regular sleeping routine everyday'
  },
  {
    "value": "MODERATELY_REGULAR_ROUTINE",
    label: 'Moderately regular sleeping routine (3-6 days of the week)'
  },
  {
    "value": "IRREGULAR_ROUTINE",
    label: 'Irregular sleeping routine (sleeps and wakes at different times daily)'
  },
  {
    "value": "NONE",
    label:'None selected'
  }
]


const sleepOptions = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'FOUR_NIGHTS', label: '4-6 nights per week' },
  { value: 'TWO_NIGHTS', label: '2-3 nights per week' },
  { value: 'NEVER', label: 'Fewer than 2 nights per week' },
  { value: 'IRREGULAR_ROUTINE', label: 'Irregular sleeping routine (sleeps and wakes at different times daily)'}
];

const radioOptions =[
  { value: true, label: 'Yes'},
  { value: false, label: 'No'}
];

const itemList = [
  [
    // {
    //   key: 'routine',
    //   label: 'Eating & Sleeping Routine',
    //   path: 'profile.routine',
    //   rules: (form, localThis) => _.get(localThis, 'state.isMNT') && _.get(localThis, 'props.isWorkList') && [{ required: true, message: 'Field is required'}],
    //   span: 14,
    //   inputContent: (disabled) => {
    //     return (
    //       <Select disabled={disabled} placeholder="Select the routine that best fits this patient's profile">
    //         {
    //           routineOptions.map(o => {
    //             return <Option key={o.value} value = {o.value}>{o.label}</Option>
    //           })
    //         }
    //       </Select>
    //     )
    //   }
    // }
    {
      key: 'eatingRoutine',
      label: 'Eating Routine',
      path: 'profile.eatingRoutine',
      rules: (form, localThis) => _.get(localThis, 'state.isMNT') && _.get(localThis, 'props.isWorkList') && [{ required: true, message: 'Field is required'}],
      span: 14,
      inputContent: (disabled) => {
        return (
            <Select disabled={disabled} placeholder="Select the eating routine that best fits this patient's profile">
              {
                eatingRoutineOptions.map(o => {
                  return <Option key={o.value} value = {o.value}>{o.label}</Option>
                })
              }
            </Select>
        )
      }
    },
  //   {
  //     key: 'sleepingRoutine',
  //     label: 'Sleeping Routine',
  //     path: 'profile.sleepingRoutine',
  //     rules: (form, localThis) => _.get(localThis, 'state.isMNT') && _.get(localThis, 'props.isWorkList') && [{ required: true, message: 'Field is required'}],
  //     span: 14,
  //     inputContent: (disabled) => {
  //       return (
  //           <Select disabled={disabled} placeholder="Select the sleeping routine that best fits this patient's profile">
  //             {
  //               sleepingRoutineOptions.map(o => {
  //                 return <Option key={o.value} value = {o.value}>{o.label}</Option>
  //               })
  //             }
  //           </Select>
  //       )
  //     }
  //   }
  ],
  [
    {
      key: 'sleepFreq',
      label: 'How often do you get 7-8 hours of sleep?',
      path: 'profile.sleepFreq',
      rules: (form, localThis) => _.get(localThis, 'state.isMNT') && _.get(localThis, 'props.isWorkList') && [{ required: true, message: 'Field is required'}],
      span: 14,
      inputContent: (disabled) => {
        return (
          <Radio.Group disabled={disabled}>
            {
              sleepOptions.map(o => {
                return <Radio key={o.value} value = {o.value}>{o.label}</Radio>
              })
            }
          </Radio.Group>
        )
      }
    }
  ],
  [
    {
      key: 'occupation',
      label: 'Employment Status',
      rules: (form, localThis) => _.get(localThis, 'state.isMNT') && _.get(localThis, 'props.isWorkList') && [{ required: true, message: 'Field is required'}],
      path: 'profile.occupation',
      span: 10,
      //placeholder: 'Select',
      MNTOnly: true,
      inputContent: (disabled) => {
        return (
          <Select disabled ={disabled} placeholder="Select">
            {
              occupationOptions.map(o => {
              return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )
      }
    }
  ],
  [
    {
      key: 'workDuties',
      label: 'Work Duties and Schedule',
      path: 'profile.workDuties',
      span: 14,
      MNTOnly: true,
      inputContent: (disabled) => <TextArea autoSize={{ minRows: 1 }} disabled={disabled}
                                            placeholder='Enter work tasks, hours, if irregular, and typical movements at work.'/>
    },
  ],
  [
    {
      key: 'activityLevel',
      label: 'Physical Activity Level',
      path: 'profile.activityLevel',
      //rules: [{ required: true, message: 'Field is required'}],
      span: 14,
      inputContent: (disabled) => {
        return (
          <Select disabled={disabled} placeholder='Select'>
            {
              activityOptions.map(o => {
              return <Option key={o.value} value = {o.value}>{o.label}</Option>
              })
            }
          </Select>
        )
      }
    },
    {
      key: 'exerciseHabits.freq.value',
      label: 'How often?',
      path: 'profile.exerciseHabits.freq.value',
      span: 2,
      rules: inputNumberRules,
      inputContent: (disabled) => <InputNumber disabled ={disabled}/>
    },
    {
      key: 'none',
      label: ' ',
      span: 1,
      inputContent: () => <div>/</div>
    },
    {
      key: 'exerciseHabits.freq.unit',
      label: ' ',
      path: 'profile.exerciseHabits.freq.unit',
      span: 3,
      initialValue: 'times/week',
      inputContent: (disabled) => {
        return (
        <Select disabled ={disabled}>
          {
            unitOptions.map(o => {
            return <Option key={o.value} value={o.value}>{o.label}</Option>
            })
          }
        </Select>
      )},
    },
    {
      key:'exerciseHabits.duration',
      path:'profile.exerciseHabits.duration',
      label: 'Duration (min)',
      span: 4,
      rules: inputNumberRules,
      inputContent: (disabled) => <InputNumber disabled ={disabled}/>
    },
  ],
  [
    {
      key: 'foodAccessibility',
      path:'profile.foodAccessibility',
      label: 'Food Accessibility',
      span: 14,
      inputContent: (disabled) => {
        return (
          <Select disabled ={disabled} placeholder='Select' showSearch={true} optionFilterProp='children'>
            {
              foodAccessibilityOptions.map(o => {
                return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )
      }
    },
    {
      key:'educationLevel',
      path:'profile.educationLevel',
      label: 'Education Level',
      //rules: [{ required: true, message: 'Field is required'}],
      span: 7,
      MNTOnly: true,
      inputContent: (disabled) => {
        return (
          <Select disabled ={disabled} placeholder='Select'>
            {
              educationOptions.map(o => {
                return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )
      }
    }
  ],
  [
    {
      key: 'lifestylePattern',
      label: 'Typical Lifestyle Pattern',
      // rules: [{ required: true, message: 'Field is required'}],
      path: 'profile.lifestylePattern',
      span: 14,
      inputContent: (disabled) => {
        return(
          <TextArea autoSize={{ minRows: 2 }} disabled={disabled} style={{marginTop: '5px'}}
                    placeholder='Enter other lifestyle patterns for the patient here...'/>
      )}
    },
    {
      key: 'educationInfo',
      placeHolder: 'educationInfo',
      span: 7,
      MNTOnly: true,
      inputContent: (localThis) => <div className='education-level-info'>
        <div><Icon type='exclamation-circle' theme='filled'/></div>{infoWarning}</div>
    }
  ]
];

const radioGroups = {
  'drinking': {
    key: 'drinkingHistory.value',
    path: 'profile.drinkingHistory.value',
    label: 'Does Patient Drink?',
    //rules: [{ required: true, message: 'Field is required'}],
    span: 4,
    inputContent: (disabled) => {
      return(
        <Radio.Group key='drinkingValue' disabled ={disabled} >
          {
            radioOptions.map(radio => {
              return(<Radio.Button key={`drinkingStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
            })
          }
        </Radio.Group>
      )
    }
  },
  'smoking': {
    key: 'smokingHistory.value',
    path: 'profile.smokingHistory.value',
    label: 'Does Patient Smoke?',
    //rules: [{ required: true, message: 'Field is required'}],
    span: 4,
    inputContent: (disabled) => {
      return(
        <Radio.Group key='smokingValue' disabled ={disabled}>
          {
            radioOptions.map(radio => {
              return(<Radio.Button key={`smokingStatus-${radio.value}`} value={radio.value}>{radio.label}</Radio.Button>)
            })
          }
        </Radio.Group>
      )
    }
  }
};

const statusItems = {
  'false': {
    'drinking': [{
      key:'drinkingHistory.quitYear',
      label: 'Quit Year',
      path:'profile.drinkingHistory.quitYear',
      span: 4,
      inputContent: (disabled)=><Input disabled ={disabled} placeholder='YYYY'/>,
    }],
    'smoking': [{
      key:'smokingHistory.quitYear',
      label: 'Quit Year',
      path:'profile.smokingHistory.quitYear',
      span: 4,
      inputContent: (disabled)=><Input disabled ={disabled} placeholder='YYYY'/>,
    }]
  },
  'true': {
    'drinking': [
      {
        key:'drinkingHistory.drinkType',
        label: 'Drink Choice',
        path:'profile.drinkingHistory.drinkType',
        span: 6,
        inputContent: (disabled) => <Input disabled ={disabled} placeholder='Type...'/>,
      },
      {
        key:'drinkingHistory.freq.value',
        label: 'How often?',
        path:'profile.drinkingHistory.freq.value',
        span: 2,
        rules: (form, localThis) => {
          let rule = [...inputNumberRulesHowOften];
          // const propUnit = _.get(localThis.props, 'user.profile.drinkingHistory.freq.unit');
          // const formUnit = form.getFieldValue('drinkingHistory.freq.unit');
          // const required = propUnit ? (_.isEqual(formUnit, propUnit)) : (formUnit && !_.isEqual(formUnit, 'times/week'));
          // if( required && rule.length === 1) {
          //   rule.push({required: true, message: 'Field is requied'});
          // }
          return rule;
        },
        inputContent: (disabled) => <InputNumber disabled ={disabled}/>,
      },
      {
        key: 'none',
        label: ' ',
        span: 1,
        inputContent: () => <div>/</div>
      },
      {
        key:'drinkingHistory.freq.unit',
        label: ' ',
        path:'profile.drinkingHistory.freq.unit',
        initialValue: 'times/week',
        span: 3,
        inputContent: (disabled) => {
          return (
          <Select disabled ={disabled}>
            {
              unitOptions.map(o => {
              return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )}
      },
      {
        key:'drinkingHistory.amount',
        label: 'Quantity',
        path:'profile.drinkingHistory.amount',
        span: 4,
        inputContent: (disabled) => <Input disabled ={disabled} placeholder='e.g., 2 drinks'/>,
      },
    ],
    'smoking': [
      {
        key:'smokingHistory.freq.value',
        label: 'How often?',
        path:'profile.smokingHistory.freq.value',
        span: 2,
        rules: (form, localThis) => {
          let rule = [...inputNumberRulesHowOften];
          // const propUnit = _.get(localThis.props, 'user.profile.smokingHistory.freq.unit');
          // const formUnit = form.getFieldValue('smokingHistory.freq.unit');
          // const required = propUnit ? (_.isEqual(formUnit, propUnit)) : (formUnit && !_.isEqual(formUnit, 'times/week'));
          // if( required && rule.length === 1) {
          //   rule.push({required: true, message: 'Field is requied'});
          // }
          return rule;
        },
        inputContent: (disabled) => <InputNumber disabled ={disabled}/>,
      },
      {
        key: 'none',
        label: ' ',
        span: 1,
        inputContent: () => <div>/</div>
      },
      {
        key:'smokingHistory.freq.unit',
        label: ' ',
        path:'profile.smokingHistory.freq.unit',
        initialValue: 'times/week',
        span: 3,
        inputContent: (disabled) => {
          return (
          <Select disabled ={disabled}>
            {
              unitOptions.map(o => {
              return <Option key={o.value} value={o.value}>{o.label}</Option>
              })
            }
          </Select>
        )}
      }
    ]
  }
}

const extraNote = [
  [{
    key: 'lifestyleNote',
    label: 'Notes',
    path: 'profile.lifestyleNote',
    span: 12,
    inputContent: (disabled, localThis) => <TextArea autoSize={{ minRows: 1}}
                                                     disabled ={disabled}
                                                     placeholder='Add note...'
                                                     onChange={localThis.setNotes}
                                                     allowClear/>
  }]
]

const getMutateVariableLifeStyle = (props)=>{
  const { user } = props;
  const getFieldsValue  = _.get(props,'form.getFieldsValue');
  const data =  _.omit(getFieldsValue(), 'none');
  let memberProfile = getMemberProfile(user.profile,['language','race','maritalStatus','birthday']);
  // const keys = Object.keys(data);
  const { eatingRoutine,sleepingRoutine, sleepFreq, activityLevel, drinkingHistory, exerciseHabits, foodAccessibility, lifestylePattern, occupation, smokingHistory, socialSupport = {}, workDuties, educationLevel, lifestyleNote, socialSupportNote} = data
  let drinkingSince;
  let smokingSince
  if(memberProfile.drinkingHistory && memberProfile.drinkingHistory.startSinceYear){drinkingSince = memberProfile.drinkingHistory.startSinceYear}
  if(memberProfile.smokingHistory && memberProfile.smokingHistory.startSinceYear){smokingSince = memberProfile.smokingHistory.startSinceYear}

  // routine
  // if(routine){
  //   memberProfile.routine = routine;
  // }
  if(eatingRoutine) {
    memberProfile.eatingRoutine = eatingRoutine;
  }
  // if(sleepingRoutine) {
  //   memberProfile.sleepingRoutine = sleepingRoutine;
  // }

  // sleep 7-8 hours
  if(sleepFreq){
    memberProfile.sleepFreq = sleepFreq;
  }

  if(activityLevel){
    memberProfile.activityLevel = activityLevel;
  }

  if(educationLevel) {
    memberProfile.educationLevel = educationLevel;
  }

  if(drinkingHistory && (typeof drinkingHistory.value)==='boolean') {
    memberProfile.drinkingHistory = {
        value: drinkingHistory.value,
        drinkType: drinkingHistory.drinkType,
        amount: drinkingHistory.amount,
        quitYear: drinkingHistory.quitYear
    };

    if(drinkingSince){
      memberProfile.drinkingHistory.startSinceYear = drinkingSince;
    }
    if ((drinkingHistory.freq && typeof drinkingHistory.freq.value) ==='number') {
      memberProfile.drinkingHistory.freq = {
        value: Number( drinkingHistory.freq.value),
        unit: drinkingHistory.freq.unit
      }

    }
  }

  const {communitySupport, contactInfo, otherProviders} = socialSupport;
  const support = {};

  if(typeof communitySupport === 'string'){
    support.communitySupport = communitySupport;
  }

  if(typeof contactInfo === 'string'){
    support.contactInfo = contactInfo;
  }

  if(typeof otherProviders === 'string'){
    support.otherProviders = otherProviders;
  }

  if(typeof socialSupportNote === 'string'){
    support.extraNote = socialSupportNote;
  }

  if(!_.isEmpty(support)){
    memberProfile.socialSupport = support;
  }

  if(smokingHistory && (typeof smokingHistory.value)==='boolean') {
    memberProfile.smokingHistory = {
        value: smokingHistory.value,
        quitYear: smokingHistory.quitYear,
        amount: smokingHistory.amount
    };

    if(smokingSince){
      memberProfile.smokingHistory.startSinceYear = smokingSince;
    }

    if ((smokingHistory.freq && typeof smokingHistory.freq.value)==='number') {
      memberProfile.smokingHistory.freq = {
        value: Number(smokingHistory.freq.value),
        unit: smokingHistory.freq.unit
      }
    }
  }

  if(exerciseHabits){
    let tempExerciseHabits = {};
    if(typeof exerciseHabits.freq.value === 'number' ){
      _.set(tempExerciseHabits, 'freq', exerciseHabits.freq)
    }

    if((typeof exerciseHabits.duration) === 'number'){
      _.set(tempExerciseHabits, 'duration', exerciseHabits.duration);
    }
    memberProfile.exerciseHabits = tempExerciseHabits;
  }




  if(lifestylePattern){
    memberProfile.lifestylePattern = lifestylePattern;
  }

  if(foodAccessibility){
    memberProfile.foodAccessibility = foodAccessibility;
  }


  if(occupation) {
    memberProfile.occupation = occupation;
  }

  if(workDuties) {
    memberProfile.workDuties = workDuties;
  }

  memberProfile.lifestyleNote = lifestyleNote || null;

  const result = {
      id: user.id,
      memberProfile,
  }
  let variables = DATE_HELPERS.trimAllValues(result, '', result);
  return variables;
}

export {
  occupationOptions,
  activityOptions,
  unitOptions,
  foodAccessibilityOptions,
  educationOptions,
  radioOptions,
  itemList,
  radioGroups,
  statusItems,
  extraNote,
  infoWarning,
  routineOptions,
  sleepOptions,
  getMutateVariableLifeStyle
}
