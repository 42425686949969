import gql from 'graphql-tag';
import Assessment from './schema/Assessment';

export default gql `
  mutation publishAssessment (
    $nameKey: String!
  ) {
    publishAssessment (
      nameKey: $nameKey
    ) {
      ${Assessment}
    }
  }
`;
