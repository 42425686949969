import {
  React, _
} from 'ihcomponent'
import I18N from 'modulesAll/I18N';
import withCommonDevicesList from 'modulesAll/vendor/patient/components/CommonDevicesList';
import { REPLACE_OR_RETURN, filterOnlyUniqueDeviceAddress, populateFormData, getLoanForm } from 'modulesAll/vendor/patient/common/utils';
import PropTypes from 'prop-types'
class DevicesList extends React.PureComponent {
  static displayName = 'vendor/patient/components/DevicesList'

  static propTypes = {
    replaceOrReturnDevicesStep: PropTypes.number,
    enrolledProgramId: PropTypes.string,
    enrolledProgramList: PropTypes.array,
    updateDevicesInEnrolledProgramInList: PropTypes.func,

    //simiilar
    renderMainLayout: PropTypes.func,
    data: PropTypes.object,

    //for replace return devices form
    fieldsValue: PropTypes.object,
    onFieldsChange: PropTypes.func,



  }

  componentWillMount(){
    if( (this.getEnrolledProgramStatus(this.props) === 'STARTED' /**|| this.getEnrolledProgramStatus(this.props) === 'SUSPENDED' **/ ) && this.props.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.FORM_DETAILS)
      populateFormData({onFieldsChange: this.props.onFieldsChange, isDischarged: this.checkEnrolledProgramIsDischarged(this.props)});
  }

  componentWillUpdate(nextProps, nextState) {
    if( (this.props.enrolledProgramId !== nextProps.enrolledProgramId) && (this.getEnrolledProgramStatus(nextProps) === 'STARTED' /**|| this.getEnrolledProgramStatus(nextProps) === 'SUSPENDED'**/ ) && nextProps.replaceOrReturnDevicesStep === REPLACE_OR_RETURN.FORM_DETAILS){
      populateFormData({onFieldsChange: nextProps.onFieldsChange, isDischarged: this.checkEnrolledProgramIsDischarged(nextProps)});
    }
  }

  // onChangeSelectStatus = (doc,selectedValue) => {
  //   this.props.changeDeviceStatusTableInfo(doc.address,selectedValue);
  // }

  checkEnrolledProgramIsDischarged(props) {
    return this.getEnrolledProgramStatus(props) === 'DISCHARGED';
  }

  getEnrolledProgramStatus(props) {
    //if the EP is not discharged, it’s replace devices. if it’s discharged, it’s returned devices button.
    return this.getEnrolledProgramme(props).status;
  }

  getEnrolledProgramme(props) {
    if (props.enrolledProgramId) {
      const enrolledProgramme = _.find(props.enrolledProgramList, { value: props.enrolledProgramId })
        return enrolledProgramme
    } else {
      return props.enrolledProgramList[0]
    }
  }

  //will get mutated
  deviceTableProps = {
    rowKey: 'address',
  }

  render(){
    // let qrcode = false;
    const ep = this.getEnrolledProgramme(this.props);
    const loanForm = getLoanForm(ep);
    const epStatus = ep.status;
    const epStartDate = ep.rawStartDate;
    const epId = ep.value;
    const updateDevicesInEnrolledProgramInList = this.props.updateDevicesInEnrolledProgramInList; //optional
    const i18nLoanForm = I18N.get('auditLog.vendor.loanForm');
    const i18nViewDevicesHistory = I18N.get('auditLog.vendor.viewDeviceHistory');
    const i18nNoLoanForm = I18N.get('auditLog.vendor.noloanForm');

    this.deviceTableProps.dataSource = filterOnlyUniqueDeviceAddress(_.get(ep, 'devices', []) || [])/** .filter( device => device.status === 'VENDOR_LOAN' ) VS-2084 shows all devices including patient owned **/;

    const hasVendorLoanDevices = this.deviceTableProps.dataSource.some(d => d.status === 'VENDOR_LOAN');
    const isEnrolledProgramDischarged = ep.status === 'DISCHARGED';
    const { onFieldsChange, fieldsValue } = this.props


    return this.props.renderMainLayout({ep, loanForm, epStatus, epStartDate, epId, updateDevicesInEnrolledProgramInList,
    i18nLoanForm, i18nViewDevicesHistory, i18nNoLoanForm, hasVendorLoanDevices, isEnrolledProgramDischarged, onFieldsChange, fieldsValue, deviceTableProps: this.deviceTableProps });

  }
}

export default withCommonDevicesList(DevicesList)
