import  React  from 'react';
import {compose} from "react-apollo/index";
import { connect } from 'react-redux';
import {Modal} from "antd";
import createTableAction from 'libModule/table/TableActions';
import { IHSearchTable } from 'ihcomponent';
import tableMap from "../constant/tableMap";
import '../style/index.scss';
import AppointmentCalenderWrapperContainer from '../../visit/containers/AppointmentCalenderWrapperContainer';
import helpers from "../helper/index";
import SingleRemoteEnrollmentContainer from "../container/SingleRemoteEnrollmentContainer";
import ConfirmExcludedReasonComponent from '../component/ConfirmExcludedReasonComponent';
import defaultSortAndFilterMap from "../constant/defaultSortAndFilterMap";

const createTableActions = tableName => createTableAction(tableName, {
    shouldUpdateHistory: true ,
    sortFieldMap: { 'member.profile.fullName':'LAST_NAME',nextVisitTime: 'NEXT_VISIT_TIME',callHistories:'LAST_CALL_DATE',pinnedToEMR:'PINNED_TO_EMR', doctorReferral: 'DOCTOR_REFERRAL' },
    initState:defaultSortAndFilterMap(tableName)
});

const RemoteEnrollmentListComponent = class extends React.Component {
    constructor(props) {
        super(props);
        let rmMember = sessionStorage.getItem('rmMember');
        rmMember  = rmMember ? JSON.parse(rmMember) : null;

        this.state={
            showStickyNoteModal: rmMember,
            currentDoc: rmMember,
            showCalender: false,
            singleVisit:null,
            visitAction:null,
            showCollapsedColumn:false,
            showExcludedReasonModal: false,
            memberId:null,
            enrolled:false
        }
    }
    componentDidMount(){
        const { onLoadParams, tabSelected } = this.props;
        onLoadParams(this.props[tabSelected], true);
        const memberId = JSON.parse(sessionStorage.getItem('backToRemoteEnrollment'));
        if (memberId) {
            this.setState({enrolled:true});
            this.setShowStickyNoteModal(true, {'member':{'id' : memberId}});
        }
        sessionStorage.setItem('backToRemoteEnrollment', null);
    };

    setShowExcludedReasonModal =(showExcludedReasonModal,memberId)=>{
        this.setState({
            showExcludedReasonModal,
            memberId
        })
    }

    renderExcludedReasonModal = ()=>{
        const { setShowExcludedReasonModal } = this;
        const { showExcludedReasonModal,memberId } = this.state;
        const { refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary } = this.props;
        return showExcludedReasonModal&&
               <Modal visible={true} maskClosable={false} footer={false}
                      onCancel={()=>this.setShowExcludedReasonModal(false)}>
                      <ConfirmExcludedReasonComponent memberId={memberId}
                                                      setShowExcludedReasonModal={setShowExcludedReasonModal}
                                                      refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}
                                                      refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                      />
               </Modal>
    }


    setShowStickyNoteModal = (showStickyNoteModal,currentDoc, next)=>{
        const { confirm } = Modal;
        const { showCreateComponent } = this.props;
        const { id,name }  = _.get(currentDoc,'organization',{ });
        const cb = ()=>{
            let fn = async (orgSwitched)=>{
                if(orgSwitched){
                  await helpers.simulatePageReload();
                }else {
                    this.setState({
                        showStickyNoteModal,
                        currentDoc
                    })
                }
                if (typeof next === 'function') next();
            }
            helpers.saveModalMember((JSON.stringify(currentDoc)));
            helpers.onOrganizationChange({ organizationId:id ,name },fn);
        }
        if(showCreateComponent) {
            confirm({
                title: 'You have an unsaved sticky note. Are you sure you want to leave?',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: () =>cb()
            })
        }else{
           cb();
        }
    }

    setShowCalender = (showCalender,singleVisit,visitAction)=>{
        this.setState({
            showCalender,
            singleVisit,
            visitAction
        })
    }

    renderShowCalender = ()=>{
        let { showCalender,singleVisit } = this.state;
        return showCalender&&<Modal visible={true} width={'80%'} className={'visitCalenderModal'} footer={false}
                      onCancel={()=>this.setShowCalender(false,null)}
               >
                    <AppointmentCalenderWrapperContainer
                        selectedVisit={ singleVisit }
                        onCancel={()=>this.props.refetchRemoteEnrollmentList()}
                        skipSearch={true} patient={ singleVisit }/>
               </Modal>
    }

    renderStickyNoteModal = ()=>{
        const { setShowCalender,props } = this;
        const { showStickyNoteModal,currentDoc, enrolled } = this.state;
        const { tabSelected,refetchRemoteEnrollmentList,contact,refetchRemoteEnrollmentSummary,setContact,callPhone,setCallPhoneInfo,callPhoneNumber } = props;
        const patientId = _.get(currentDoc,'member.id') || _.get(props,'memberId');
        return showStickyNoteModal&&<Modal footer={false} width={1000} maskClosable={false}
                                           visible={true} onCancel={()=>this.setShowStickyNoteModal(false,null)}>
                                        {/*<StickyNoteActionComponent patientId={patientId} currentDoc={currentDoc}*/}
                                        {/*                           refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}*/}
                                        {/*                           setShowCalender={setShowCalender} tabSelected={tabSelected}/>*/}
                                        <SingleRemoteEnrollmentContainer memberId={patientId}
                                                                         setContact={setContact}
                                                                         contact={contact}
                                                                         callPhone={callPhone}
                                                                         setCallPhoneInfo={setCallPhoneInfo}
                                                                         callPhoneNumber={callPhoneNumber}
                                                                         refetchRemoteEnrollmentSummary={refetchRemoteEnrollmentSummary}
                                                                         refetchRemoteEnrollmentList={refetchRemoteEnrollmentList}
                                                                         enrolled={enrolled}/>
                                    </Modal>
    }

    handleOnSelectChange = (o,variables)=>{
        const { refetchRemoteEnrollmentList,refetchRemoteEnrollmentSummary } = this.props;
        if (o !== 'EXCLUDED') {
            helpers.confirmMove(o, variables, refetchRemoteEnrollmentList, refetchRemoteEnrollmentSummary);
        }else{
            this.setShowExcludedReasonModal(true,variables.memberId);
        }
    }

    renderTable=()=>{
        const { props } = this;
        const {  tabSelected,remoteEnrollmentList,loading,refetchRemoteEnrollmentList,pageInfo } = props;
        const page = _.get(props,`${tabSelected}.page`);
        const sort = _.get(props,`${tabSelected}.sort`);
        // const postItList =
        const pagination = {
            current: _.get(page, 'current', 1),
            pageSize: _.get(page, 'pageSize', 10),
            total: _.get(pageInfo, 'total', 0),
        };
        const onChange = (a, b, c) => this.props.onTableChange(a, b, c);
        const columns = tableMap(tabSelected,this);
        return <IHSearchTable columns={columns} className='remoteEnrollmentTable'
                              scroll={{ x: 1000 }}
                              loading={loading}
                              pagination={pagination}
                              rowKey={(d)=>_.get(d,'member.id')}
                              onChange={onChange}
                              dataSource={helpers.parseREList(_.get(sort,'field',''),remoteEnrollmentList)}/>
    }

    // renderCreateVisitModal = ()=>{
    //     return <CreateVisitInDashboardContainer onCancel={()=>this.showCreateVisitModal(false)} isRemoteEnrollment={true} />;
    // }

    render(){
        const { renderTable,renderStickyNoteModal,renderShowCalender,renderExcludedReasonModal } = this;
        return <div>
                    { renderTable() }
                    { renderStickyNoteModal() }
                    { renderShowCalender() }
                    { renderExcludedReasonModal() }
               </div>
    }
}



const mapStateToProps = (state, ownProps) => {
    const { tabSelected } = ownProps;
    return {
        tabSelected,
        [tabSelected]: state.globalTables[tabSelected],
        showCreateComponent:state.Stickie.showCreateComponent
        // contact: state.AWSConnect.contact
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
            ...createTableActions((ownProps.tabSelected)),
            // setContact: flag => dispatch(callActions.contact(flag)),
    }
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RemoteEnrollmentListComponent);
