import types from 'ProgramEditModule/Careplan/constants/VitalListConstants'
import { convertDBToVitalList, addVitalDataToList } from 'modulesAll/utils/helpers/task'
import get from 'lodash/get'

// Default State
const initState = {
  data: []
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        data: convertDBToVitalList(action.selectedProgram),
        programId: get(action, 'selectedProgram.id')
      }
    case types.ADD_VITAL_DATA:
      return addVitalDataToList(state, action.fieldsValue)
    default:
      return state
  }
}
