import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import { IHButton, message, _ } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import tabAction from 'patientModule/main/actions/Tab'
import VitalList from './VitalListContainer'
import AssessmentList from 'patientModule/Assessment/containers/CommonContainer'
import SurveyList from 'patientModule/Survey/containers/CommonContainer'
// import EducationalTable from 'patientModule/Careplan/containers/EducationalTable'
import { graphql, withApollo, compose } from 'react-apollo'
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import { removeTaskFromEnrolledProgram } from 'graphqlModule/mutation/removeTaskFromEnrolledProgram'
import { enrolledProgram as updateQueries } from 'graphqlModule/updateQueries/enrolledProgram'
import { removeTaskFromEnrolledProgram as updateQueriesForRemove } from 'graphqlModule/updateQueries/enrolledProgram'
import { openErrorModal } from 'modulesAll/layout/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import RequestCache from 'libModule/requestCache'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
import { actionCreators as careplanActions } from '../ducks/CommonDuck'
import TemporaryButton from 'modulesAll/patient/main/containers/TemporaryButton'

const sy = {
    marginTop : '30px'
}
class Container extends React.Component{
  static displayName = 'patient/Careplan/Vital/containers/MainPageContainer'

  componentDidMount() {
    const { setAssessmentHasEmptySchedule, setSurveyHasEmptySchedule } = this.props
    setAssessmentHasEmptySchedule(false)
    setSurveyHasEmptySchedule(false)
  }

  render(){
    return (
      <div className="v-box v-careplan patient-careplan-main-page">
        {this.renderVitalList()}
        {/*{this.renderAssessmentsTable()}*/}
        {/*{this.renderSurveysTable()}*/}
        {/*
          {this.renderEducationalTabl()}
        */}
        {this.renderBottomButton()}
      </div>
    )
  }

  renderVitalList(){
    const { program } = this.props
    return (
      <div>
        <p className="v-tlt">{`Vital Signs & Thresholds`}</p>
        <VitalList program={program} />
      </div>
    )
  }

  renderAssessmentsTable(){
    return (
      <AssessmentList ref="assessmentList" program={this.props.program} assessmentHasEmptySchedule={this.props.assessmentHasEmptySchedule} />
    )
  }

  renderSurveysTable(){
    return (
        <SurveyList ref='surveyList' program={this.props.program} surveyHasEmptySchedule={this.props.surveyHasEmptySchedule} />
    )
  }

  renderEducationalTabl(){
    // const { program } = this.props
    return (
      <div style={sy}>
        <p className="v-tlt">{`Select Educational Materials`}</p>
        {/*<EducationalTable program={program} />*/}
      </div>
    )
  }

  renderBottomButton(){
    const dispatch = this.props.dispatch
    const nextBtnProp = {
      type : 'primary',
      // label : 'Save And Next',
      label : 'Save',
      onClick : ()=>{
        //dispatch(tabAction.setTabsStep('device'))
        this.submit()
      },
      className : 'v-nb'
    }
    const backBtnProp = {
      label : 'Back',
      className : 'v-bb',
      onClick : ()=>{
        const _currentPatient = RequestCache.get('nurse/currentPatient')
        const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
        // const patientNRIC = _currentPatient.identification[0].value
        const patientName = _currentPatient.profile.fullName

        createAuditLog({
          action:I18N.get('auditLog.provider.patient.enrollInNewProgram.back_to_program_tab'),
          patientNRIC,
          patientName
        })

        dispatch(tabAction.setTabsStep('programme'))
      }
    }
    return (
      <div className="careplan-page-footer">
        <IHButton style={{marginLeft:15}} {...backBtnProp} key='programInfo' />
        {/*<IHButton {...nextBtnProp} key='device' />*/}
        <TemporaryButton {...this.props} currentProgram={this.props.program} />
      </div>
    )
  }

  validateWeightThreshold(tasks) {
    const weightTask = find(tasks, task => task.type === 'HS')
    const isBaseline = get(weightTask, 'threshold.0.measure') === 'WEIGHT_CHANGE_BASELINE'
    const baselineValue = get(weightTask, 'threshold.0.baseline')
    return isBaseline && !baselineValue ? false : true
  }

  async submit() {
    const { dispatch, Assessment, Survey, program, editEnrolledProgram, removeTaskFromEnrolledProgram, openErrorModal, setAssessmentHasEmptySchedule, setSurveyHasEmptySchedule } = this.props
    // validate baseline value when weight.threshold.0.measure is WEIGHT_CHANGE_BASELINE
    //const isWeightValid = this.validateWeightThreshold(program.tasks)
    const _currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
    // const patientNRIC = _currentPatient.identification[0].value
    const patientName = _currentPatient.profile.fullName
    // if (!isWeightValid) {
    //   message.error('Please review the baseline weight to proceed')
    //   return
    // }
    const { tasks, removedTasksId } = Assessment.AssessmentList
    const { tasks: surveyTasks, removedTasksId: removedTasksIdFromSurvey } = Survey.SurveyList
    let combinedRemovedTasksId = removedTasksId || []
    if (removedTasksIdFromSurvey) {
      combinedRemovedTasksId = combinedRemovedTasksId.concat(removedTasksIdFromSurvey)
    }
    const combinedTasks = tasks.concat(surveyTasks)

    // Check if any assessment or survey has empty schedule, do not save if there is any
    let hasEmptySchedule = false
    setAssessmentHasEmptySchedule(false)
    setSurveyHasEmptySchedule(false)

    _.each(combinedTasks, (task) => {
      if(task.schedule && task.schedule[0].calendar.length === 0) {
        if(task.type === 'ASSESSMENT') {
          setAssessmentHasEmptySchedule(true)
          hasEmptySchedule = true
        }
        if(task.type === 'SURVEY') {
          setSurveyHasEmptySchedule(true)
          hasEmptySchedule = true
        }
      }
    })

    if (hasEmptySchedule) {
      message.error('Care Plan changes failed to save!')
      return
    }

    const variables = {
      id: program.id,
      tasks: combinedTasks
    }
    const variablesForRemove = {
      id: program.id,
      taskId: combinedRemovedTasksId
    }

    if (combinedRemovedTasksId && combinedRemovedTasksId.length > 0) {
      try {
        const res = await removeTaskFromEnrolledProgram({ variables: variablesForRemove, updateQueries: updateQueriesForRemove })
        if (res.data.removeTaskFromEnrolledProgram) {
          const auditLogDetails = {
            programName: program.name,
            assessments: res.data.removeTaskFromEnrolledProgram.tasks.filter((task) => task.type === 'ASSESSMENT').map((assessment) => {
              return assessment.assessment.name
            }),
            surveys: res.data.removeTaskFromEnrolledProgram.tasks.filter((task) => task.type === 'SURVEY').map((survey) => {
              return survey.assessment.name
            })
          }

          createAuditLog({
            action: I18N.get('auditLog.provider.patient.enrollInNewProgram.remove'),
            details: auditLogDetails,
            request: variablesForRemove,
            response: res,
            patientNRIC,
            patientName
          })
        }
      }
      catch(e) {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.enrollInNewProgram.remove'),
          details: { programName : program.name },
          request: variables,
          response: e,
          success: false,
          patientNRIC,
          patientName
        })
        openErrorModal(GQLHelper.formatError(e))
        return
      }
    }

    if (combinedTasks.length > 0) {
      try {
        const res = await editEnrolledProgram({ variables, updateQueries })
        if (res.data.editEnrolledProgram) {
          const auditLogDetails = {
            programName: program.name,
            assessments: res.data.editEnrolledProgram.tasks.filter((task) => task.type === 'ASSESSMENT').map((assessment) => {
              return assessment.assessment.name
            }),
            surveys: res.data.editEnrolledProgram.tasks.filter((task) => task.type === 'SURVEY').map((survey) => {
              return survey.assessment.name
            })
          }
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save'),
            details: auditLogDetails,
            request: variables,
            response: res,
            patientNRIC,
            patientName
          })
        }
      }
      catch(e) {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save'),
          details: { programName : program.name },
          request: variables,
          response: e,
          success: false,
          patientNRIC,
          patientName
        })
        openErrorModal(GQLHelper.formatError(e))
        return
      }
    }

    message.success('Care Plan changes have been saved!');
    dispatch(tabAction.setTabsStep('device'))
    // Have to add at minimum 1 sec delay so that below Audit Log will appear after the logs above
    _.delay(()=>{
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_care_plan_tab'),
        patientNRIC,
        patientName
      })
    }, 1000)
  }
}

Container.propTypes = {
  dispatch: PropTypes.func,
  removedTasksId: PropTypes.array,
  program: PropTypes.object,
  Assessment: PropTypes.object,
  Survey: PropTypes.object,
  setAssessmentHasEmptySchedule: PropTypes.func,
  setSurveyHasEmptySchedule: PropTypes.func,
  openErrorModal: PropTypes.func,
  editEnrolledProgram: PropTypes.func,
  removeTaskFromEnrolledProgram: PropTypes.func,
  assessmentHasEmptySchedule: PropTypes.bool,
  surveyHasEmptySchedule: PropTypes.bool
}

const mapState = ({ patient }) => {
  return {
    ...patient.Careplan
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setAssessmentHasEmptySchedule: (assessmentHasEmptySchedule) => dispatch(careplanActions.setAssessmentHasEmptySchedule(assessmentHasEmptySchedule)),
    setSurveyHasEmptySchedule: (surveyHasEmptySchedule) => dispatch(careplanActions.setSurveyHasEmptySchedule(surveyHasEmptySchedule)),
  }
}

export default compose(
  withApollo,
  graphql(editEnrolledProgram, {name: 'editEnrolledProgram'}),
  graphql(removeTaskFromEnrolledProgram, {name: 'removeTaskFromEnrolledProgram'}),
  connect(mapState, mapDispatch),
)(Container);
