import type from '../constants'
import moment from "moment/moment";

export default {
    initialState:()=>{
      return{
          type:type.SET_INITIAL_STATE
      }
    },
    reset:(id)=>{
      return {
          type:type.RESET,
          payload:{
              id
          }
      }
    },
    setRating: (id,rating) => {
        return {
            type: type.SET_RATING,
            payload: {
                id,
                rating
            }
        }
    },
    updateComment:(id,comments)=>{
        return {
            type:type.UPDATE_COMMENTS,
            payload:{
                id,
                comments
            }
        }
    },
    changePre:()=>{
        return {
            type:type.CHANGEPRE
        }
    },
    changeNext:()=>{
        return {
            type:type.CHANGENEXT
        }
    },
    changeYear:(year)=>{
        return{
            type:type.CHANGEYEAR,
            payload:year
        }
    },
    changeMonth:(month)=>{
        return{
            type:type.CHANGEMONTH,
            payload:month
        }
    },
    resetState:()=>{
        return{
            type:type.RESETSTATE
        }
    },
    setTimeInterval:(timeinterval,value)=>{
        return {
            type:type.SETTIMEINTERVAL,
            payload:{
                interval:timeinterval,
                value:value
            }
        }
    }
}
