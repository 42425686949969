import { IHButton, React } from 'ihcomponent'
import ListTable from './ListTableContainer'
import { createContainer } from 'libModule/utils'
import action from '../actions'

const Component = (props) => {
  const {dispatch} = props

  const buttonProps = {
    label: 'Add New Task',
    size: '',
    bsStyle: 'primary',
    onClick: () => {
      dispatch(action.showModal(true))
    }
  }

  return (
    <div className="vsm-main-page vsm-task-list-main">
      <div className="vsm-main-table">
        <div className="v-rd">
          <IHButton {...buttonProps} />
        </div>
        <div className="v-table">
          <ListTable />
        </div>
      </div>
    </div>
  )
}

Component.displayName = 'providerTask/TaskList/containers/TaskListContainer';

export default createContainer(Component, (state) => {
  return {
    ...state.task.TaskList
  }
})
