import { IHTable, IHButton,WeeklyMealScheduleInputGrid,emptyWeeklyScheduleData } from 'ihcomponent'
import PropTypes from 'prop-types';
import React from 'react';
import { createContainer } from 'libModule/utils'
import { convertDBToVitalList } from 'modulesAll/utils/helpers/task'
import { VITAL_TYPE_MAP } from 'modulesAll/utils/constants/task'
import actions from '../actions/VitalListActions'
import { renderThresholdsTitle, renderThresholds, renderSchedule } from 'libModule/helpers/component-helpers'
import { browserHistory } from 'react-router';
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';

class Component extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/Careplan/containers/VitalListContainer';
  state = {
    selectedRowKeys: []
  }

  dataSource = convertDBToVitalList(this.props.program);

  componentDidMount = () => {
    // select recommended vitals referred by MA/MD
    const { recommendedVitals } = this.props;
    if(recommendedVitals) {
      const selectedRowKeys = [],
            vitalList = [];      
      _.forEach(this.dataSource, (t) => {
        const type = VITAL_TYPE_MAP[t.vitalType];
        if(_.includes(recommendedVitals, type)) {
          selectedRowKeys.push(t.id);
          vitalList.push(t);
        }
      });
      this.setState({ selectedRowKeys });
      this.props.setVitalListForm(vitalList);
    }
  }

  render() {
    const { isFromEnrollProgram, hideBtn, resetSubmitError } = this.props;
    const curUrl = browserHistory.getCurrentLocation().pathname;
    let p = {
      rowKey: 'id',
      columns: [
        // {
        //   title: '',
        //   dataIndex: 'hidden',
        //   key: 'hidden',
        //   width: '49',
        // },
        {
          title: 'Vitals',
          dataIndex: 'vitalType',
          key: 'vitalType',
          // width: _.endsWith(curUrl, '_') ? '' : '15%',
          render: (vital, row, index) => {
            if (vital === 'Weight' && row.weightBasedOn === 'Last Measurement') return 'Weight Change'
            return vital
          }
        },
        {
          title: renderThresholdsTitle(),
          dataIndex: 'thresholds',
          key: 'thresholds',
          // width: _.endsWith(curUrl, '_') ? '' : '50%',
          render: (value, row, index) => renderThresholds(value, row, index)
        },
        {
          title: 'Schedule',
          dataIndex: 'schedule',
          key: 'schedule',
          // width: _.endsWith(curUrl, '_') ? '' : '20%',
          render: (value, row, index) => {
           return renderSchedule(value, row, index,
            {render:this.props.openModal, closer:this.props.closeModal})
          }
          //render: (value, row, index) => renderSchedule(value, row, index)

        },
        {
          key: 'Actions',
          title: 'Actions',
          width: '15%',
          render: (value, row, index) => {
            const { changePage, updateForm,isFromEnrollProgram ,patientId} = this.props;
            const onClick = () => {
              const patientId =  isFromEnrollProgram ? patientId : this.props.program.user.id;
              updateForm(row, true);
              // browserHistory.push('/patients/' + patientId + '/patientschedule_edit');
              changePage();
            };
            return (
              <div>
                <IHButton bsStyle='primary' size='exsmall' onClick={onClick} disabled={this.props.isVitalEditBtnDisabled ? this.props.isVitalEditBtnDisabled : false} >
                  Edit
                  <i className="fa fa-angle-right ih-right"></i>
                </IHButton>
              </div>
            )
          }
        }
      ],
      dataSource: this.dataSource,
      noDataElem: this.renderAddVitalElem('Add Vital Sign', 'flex_center', {height:'200px'}),
      rowSelection : {
        selectedRowKeys: this.state.selectedRowKeys,
        onChange : (selectedRowKeys, row, ...args)=>{ 
          this.setState({ selectedRowKeys });
          this.props.setVitalListForm(row); 
          if(resetSubmitError)
            resetSubmitError();
        }
      }
      //       //     this.props.setVitalListForm(row);
      //       //   }
      // _.endsWith(curUrl, '_') ? {
      //   onChange : (key, row)=>{
      //     this.props.setVitalListForm(row);
      //   }
      // } : null
    }
    const vitalListLength = this.dataSource.length
    const addAnotherVitalBtn = vitalListLength >= 0 && vitalListLength < 5 ? this.renderAddVitalElem('Add Another Vital', '', {margin: '20px 0 30px'}) : ''
    const url = window.location.href;
    const inMessagePage  = (url.indexOf('messages')!=-1);
    if(inMessagePage||isFromEnrollProgram)
      {
        p.columns = p.columns.slice(0,3);
      }

    if(hideBtn){
      return (
        <div>
          <IHTable {...p} />
        </div>
      )
    }else{
      return (
        <div>
          <IHTable {...p} />
          {addAnotherVitalBtn}
        </div>
      )
    }

  }
  renderAddVitalElem(label, className, style) {
    const { changePage, resetForm,isVitalEditBtnDisabled } = this.props
    const pb = {
      bsStyle: 'primary',
      label,
      // disabled:isVitalEditBtnDisabled,
      size: 'small',
      onClick: () => {
        changePage()
        resetForm()
      }
    }

    return (
      <div className={className} style={style}>
        <IHButton {...pb} />
      </div>
    )
  }
}

const mapState = ({ patient }) => {
  return {
    data: patient.enrolledProgramInfo.Careplan.VitalList.data,
    vitalListForm: patient.enrolledProgramInfo.Careplan.VitalForm.vitalList
  }
}

const mapDispatch = (dispatch) => {
  return {
    updateForm: (rowData, isEditMode) => dispatch(actions.updateForm(rowData, isEditMode)),
    resetForm: () => dispatch(actions.resetForm()),
    changePage: () => dispatch(actions.goToCareplanVitalForm()),
    closeModal: () => {dispatch(closeModal())},
    openModal: (content, modalProps) => dispatch(openModal(content, modalProps)),
    setVitalListForm: (rows) => dispatch(actions.setVitalListForm(rows))}
}

Component.propTypes = {
  program: PropTypes.object,
  data: PropTypes.array,
  updateForm: PropTypes.func,
  resetForm: PropTypes.func,
  changePage: PropTypes.func,
  setVitalListForm: PropTypes.func
}

export default createContainer(Component, mapState, mapDispatch)

export { Component };
