import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import I18N from 'modulesAll/I18N';

const messages = I18N.get('Program.programInfoForm.messages')
const labels = I18N.get('Program.programInfoForm.labels')

const itemLayout = {
  labelCol : {span : 24},
  wrapperCol : {span : 24}
}

export const schema = {
  name: {
    initialValue: '',
    required: true
  },
  description:  {
    initialValue: '',
    required: true
  },
  healthCondition:  {
    initialValue: '',
    required: true,
  },
  durationLength:  {
    initialValue: 1200,
    required: true
  },
  durationCalendar:  {
    initialValue: 'MONTH',
    required: true
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = (initData) => {
  return {
    vertical: true,
    itemList : [
      [
        {
          key : 'name',
          label : labels.name,
          type : 'text',
          ...itemLayout,
          // placeholder : 'HF VSM Programme',
          placeholder : '',
          initialValue: initData.name,
          col: {span: 16, offset: 0},
          rules: [
            {required : isFieldsRequired.name, message : messages.nameReq}
          ],
          disabled: isFieldsDisabled.name
        },
      ],
      [
        {
          key : 'description',
          label : labels.description,
          type : 'textarea',
          ...itemLayout,
          // placeholder : 'Add description here',
          placeholder : '',
          initialValue: initData.description,
          col: {span: 16, offset: 0},
          rules : [
            {required : isFieldsRequired.description, message : messages.descriptionReq}
          ],
          rows: 4,
          disabled: isFieldsDisabled.description,
          autosize : {minRows: 2, maxRows: 2}
        }
      ],
      [
        {
          key : 'healthCondition',
          label : labels.condition,
          type : 'select',
          ...itemLayout,
          placeholder : 'Select Health Condition',
          initialValue: initData.healthCondition,
          option: [
            // {name:'AMI', value:'AMI'},
            {name:'Diabetes', value:'DIABETES'},
            {name:'Heart Failure', value:'HEART_FAILURE'},
            {name:'AMI', value:'AMI'},
          ],
          col: {span: 12, offset: 0},
          rules : [
            {required : isFieldsRequired.healthCondition, message : messages.conditionReq}
          ],
          disabled: initData.status === 'ACTIVE'
        },
      ],
      // [
      //   {
      //     key : 'durationLength',
      //     type : 'inputNumber',
      //     label : labels.durationLength,
      //     ...itemLayout,
      //     // placeholder : 1,
      //     placeholder : '',
      //     initialValue: initData.durationLength,
      //     col: {span: 6, offset: 0},
      //     rules : [
      //       {required : isFieldsRequired.durationLength, message : messages.durationLengthReq},
      //       {min : 0, type: 'number', message: messages.durationLengthMin},
      //       {type: 'integer', message: messages.durationLengthType}
      //     ],
      //     disabled: isFieldsDisabled.durationLength
      //   },
      //   {
      //     key : 'durationCalendar',
      //     type : 'select',
      //     label: ' ',
      //     ...itemLayout,
      //     placeholder : 'Day(s)',
      //     initialValue: initData.durationCalendar,
      //     option: [{name:'Day(s)', value:'DAY'}, {name:'Week(s) (7 days) ', value:'WEEK'}, {name:'Month(s) (30 days) ', value:'MONTH'}],
      //     col: {span: 6, offset: 0},
      //     rules : [
      //       {required : isFieldsRequired.durationCalendar, message : messages.durationCalendarReq}
      //     ],
      //     className: 'form-field duration-calendar hide-required-mark',
      //     disabled: isFieldsDisabled.durationCalendar
      //   }
      // ]
    ]
  }
}
