import React from "react";
import BGLineChartComponent from '../components/BGLineChartComponent';
import BGDotChartComponent from '../components/BGDotChartComponent';
// import moment from "moment/moment";
import { Switch,Radio } from 'antd';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';

// const chartTypeMap ={
//     0:'TREND_VIEW',
//     1:'POINT_VIEW'
// }
// const isLastDay = () =>{
//   const today = moment();
//   return today.format('D') === today.endOf('month').format('D');
// }
const BGLineChartContainer = class extends React.Component {
    constructor(){
        super();
        this.state = {
            // allVisit: false,
            // toDate:moment().endOf('day'),
            // fromDate:moment().subtract(6,'d').startOf('day'),
            // timeInterval:'d',
            // value:6,
            chartType: 0
        }
    }
    // changePre = ()=>{
    //     const { fromDate, toDate,timeInterval,value,chartType } = this.state;
    //     const updatedFromDate = fromDate.subtract(value,timeInterval).startOf('day');
    //     const updatedToDate = (timeInterval === 'M'&&isLastDay()) ? toDate.subtract(value,timeInterval).endOf('month').endOf('day') : toDate.subtract(value,timeInterval).endOf('day');
    //     const { patientId } = this.props;
    //     const strVal =  (value==14 ? 'TWO' : 'ONE');
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked',`BACK_BG_${chartTypeMap[chartType]}`,null,{ PATIENT_ID:patientId,DURATION:str });

    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }

    // changeNext = ()=>{
    //     const { fromDate, toDate,timeInterval,value,chartType } = this.state;
    //     const updatedFromDate = fromDate.add(value,timeInterval).startOf('day');
    //     const updatedToDate = (timeInterval === 'M'&&isLastDay())? toDate.add(value,timeInterval).endOf('month').endOf('day') : toDate.add(value,timeInterval).endOf('day');
    //     const { patientId } = this.props;
    //     const strVal =  (value==14 ? 'TWO' : 'ONE');
    //     const strUnit = (timeInterval=='M' ? 'MONTH' : 'WEEK')
    //     const str = strVal+'_'+strUnit;
    //     Mixpanel.track('clicked',`NEXT_BG_${chartTypeMap[chartType]}`,null,{ PATIENT_ID:patientId,DURATION:str });

    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate
    //     })
    // }
    // renderNextButton = () =>{
    //     const { value,timeInterval } = this.state;
    //     const val = value == 1 ? value : ((value == 6 ||value == 7) ? 1 : 2);
    //     const unit = (timeInterval === 'd') ? (val == 1 ? 'Week' : 'Weeks') :'Month';

    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <span>{`Next ${val} ${unit}`}</span>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-next" aria-disabled="false" onClick={()=>this.changeNext()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //             </div>
    // }

    // renderPrevButton = ()=>{
    //     const { value,timeInterval } = this.state;
    //     const val = value == 1 ? value : ((value == 6 ||value == 7) ? 1 : 2);
    //     const unit = (timeInterval === 'd') ? (val == 1 ? 'Week' : 'Weeks') :'Month';
    //     return <div style={{ display:'flex',flexDirection:'row',alignItems:'center'}}>
    //                 <ul className='ant-pagination logBookPreNext' >
    //                     <li className=" ant-pagination-prev" aria-disabled="false" onClick={()=>this.changePre()}>
    //                         <a className="ant-pagination-item-link"></a>
    //                     </li>
    //                 </ul>
    //                 <span>{`Past ${val} ${unit}`}</span>
    //             </div>
    // }


    // setTimeInterval = (updatedTimeInterval,updatedValue)=>{
    //     const { value,timeInterval,chartType } = this.state;
    //     const { patientId } = this.props;

    //     if((updatedTimeInterval===timeInterval)&&(updatedValue==value)){
    //         return;
    //     }
    //     const updatedFromDate = moment().subtract(updatedValue,updatedTimeInterval).add(1,'days').startOf('day');
    //     const updatedToDate = moment().endOf('day');
    //     this.setState({
    //         fromDate:updatedFromDate,
    //         toDate: updatedToDate,
    //         timeInterval: updatedTimeInterval,
    //         value: updatedValue
    //     },()=>{
    //         const str = (updatedValue==14 ? 'TWO' : 'ONE') +'_'+(updatedTimeInterval=='M' ? 'MONTH' : 'WEEK');
    //         Mixpanel.track('clicked',`${str}_BG_${chartTypeMap[chartType]}`,null,{ PATIENT_ID: patientId })
    //     })
    // }

    onClickChartView = (chartType,typeString)=>{
        const { patientId, onChartTypeChange } = this.props;
        this.setState({chartType});
        onChartTypeChange(typeString);
        Mixpanel.track('clicked',typeString+'_BG',null,{ PATIENT_ID:patientId });
    }

    renderChartTypeSwitch = ()=>{
        const { chartType } = this.state;
        return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginTop:10 }}>
            <Radio.Button  value={0}  onClick={()=>this.onClickChartView(0,'TREND_VIEW')} checked = {!chartType}>Trend View</Radio.Button>
            <Radio.Button  value={1}  onClick={()=>this.onClickChartView(1,'POINT_VIEW')} checked = {chartType} >Point View</Radio.Button>
        </Radio.Group>
    }

    // renderRadioButton = ()=>{
    //     const { fromDate,toDate } = this.state;
    //     const diffInWeek = toDate.diff(fromDate,'week');

    //     const oneWeekChecked = (diffInWeek===0) ;
    //     const twoWeekChecked = (diffInWeek===1) ;
    //     const oneMonthChecked = (diffInWeek===4) ;

    //     return <Radio.Group className='it-dd logBookButtonGroup' style={{ marginLeft:10, marginRight: 10}}>
    //         <Radio.Button  value='7'  onClick={()=>this.setTimeInterval('d',7)} checked = {oneWeekChecked}>1 Week</Radio.Button>
    //         <Radio.Button  value='14'  onClick={()=>this.setTimeInterval('d',14)} checked = {twoWeekChecked} >2 Weeks</Radio.Button>
    //         <Radio.Button  value='1' onClick={()=>this.setTimeInterval('M',1)} checked = {oneMonthChecked}>1 Month</Radio.Button>
    //     </Radio.Group>
    // }

    render(){
        // const { props,renderRadioButton,renderPrevButton,renderNextButton,renderChartTypeSwitch } = this;
        const { fromDate, toDate, isInChat, xAxisLabelPerDay = 1, ...props } = this.props;
        const { chartType } = this.state;

        // const { isInChat } = props;
        return   <div style={{ padding: 20 }}>
                    <div className='row'>
                        {/* <div className={`${ isInChat ? 'col-lg-3':'col-lg-7'}`} style={{ fontWeight:'bold',fontSize:16 }}>Blood Glucose</div>
                        <div className={`${ isInChat ? 'col-lg-9':'col-lg-5'}`} style={{display:'flex',marginTop:(isInChat? 10 : 0)}}>
                            { renderPrevButton() }
                            {renderRadioButton()}
                            {renderNextButton()}
                        </div> */}
                        <div className={`${ isInChat ? 'col-lg-12':'col-lg-12'}`}>
                            { this.renderChartTypeSwitch() }
                        </div>
                    </div>
                    {
                        chartType ? <BGDotChartComponent {...props} fromDate={fromDate.toDate()} toDate={toDate.toDate()} xAxisLabelPerDay={xAxisLabelPerDay} /> :
                                  <BGLineChartComponent {...props} fromDate={fromDate.toDate()} toDate={toDate.toDate()} xAxisLabelPerDay={xAxisLabelPerDay} />
                    }
                </div>

    }
}

export default BGLineChartContainer;