import { React } from 'ihcomponent';
import PropTypes from 'prop-types';
import Client from 'libModule/gqlClient';
import $ from 'jquery';
import { Button, Icon } from 'antd';
import MemberTable from '../components/MemberTable';
import { 
  viewMemberColumns, VIEW_LIMIT_COUNT, EDIT_CARETEAM 
} from '../../constant/careTeamConstants';
import { getTotalDetailTextDiv, processProviderData } from '../../helper/careteamHelpers';
import Query from '../../query';

class CareTeamContainer extends React.Component {
  state = { 
    tablesAreShowingAll: []
  };

  getShowingAllTableIndex = (checkList = this.state.tablesAreShowingAll, tableIndex) => 
    _.indexOf(checkList, tableIndex);
  

  toggleViewAll = tableIndex => {
    const newTablesAreShowingAll = [...this.state.tablesAreShowingAll];
    const arrIndex = this.getShowingAllTableIndex(newTablesAreShowingAll, tableIndex);
    if(arrIndex < 0){
      newTablesAreShowingAll.push(tableIndex);
    } else {
      newTablesAreShowingAll.splice(arrIndex, 1);
    }
    this.setState({ tablesAreShowingAll: newTablesAreShowingAll });
  };

  render() {
    const { 
      state: { tablesAreShowingAll }, 
      toggleViewAll 
    } = this;
    const { organization, openAddModal, careTeamList } = this.props;

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      {
        _.map(careTeamList, (careTeam, tableIndex) => {
          const careteamId = _.get(careTeam, 'id');
          const dataSource = _.map(_.get(careTeam, 'members', []), 
                          p => processProviderData(organization.id, p));
          const totalProviders = dataSource.length;
          
          const needLimitView = totalProviders > VIEW_LIMIT_COUNT;
          let isTableShowingAll = false;
          if(needLimitView)
            isTableShowingAll = 
              this.getShowingAllTableIndex(tablesAreShowingAll, tableIndex) > -1;

          const careTeamProps = {
            teamId: careteamId,
            ..._.omit(careTeam, ['id', 'members']), // dataSource contains members
            dataSource,
          };

          const tableId = `care-team-table-${tableIndex}`;  
          
          return <MemberTable
            tableId={tableId}
            key={careteamId}
            careteamId={careteamId}
            dataSource={dataSource}
            columns={ viewMemberColumns({ ...this.props, ...careTeamProps }) }
            header={
              <div 
                style={{ 
                  display: 'flex', justifyContent: 'space-between', marginTop: 32 
                }}
              >
                <div>
                  <span 
                    style={{ fontWeight: 'bold', fontSize: 14, lineHeight: '20px' }}
                  >
                    {`${_.get(careTeam, 'name') || 'N/A name'}`}
                  </span>
                  <span style={{ fontSize: 13, lineHeight: '15px', marginLeft: 15  }}>
                    { getTotalDetailTextDiv(dataSource) }
                  </span>
                </div>
                <Button
                  onClick={async e => {
                    const res = await Client.query({
                      query: Query.getTeamTotalChannels,
                      variables: { teamId: careteamId },
                      fetchPolicy: 'network-only'
                    }).catch(err => console.error(err));

                    openAddModal(e, {
                      modalAction: EDIT_CARETEAM,
                      careTeamProps,
                      teamSelections: dataSource,
                      teamChannelCount: _.get(res, 'data.getTeamTotalChannels') || 0
                    });
                  }}
                  type='primary'
                  style={{ borderColor: '#3277C9', color: '#3277C9' }}
                  ghost
                >
                  Edit
                </Button>
              </div>
            }
            {...needLimitView && {
              footer: () => {
                return <div style={{ textAlign: 'center' }}>
                  <Button
                    className='no-border-button'
                    onClick={() => toggleViewAll(tableIndex)}
                  >
                    {
                      !isTableShowingAll ?
                        'View All Members'
                      :
                        'Collapse'
                    }
                    <Icon type={!isTableShowingAll ? 'down' : 'up'}/>
                  </Button>
                </div>
              },
              pagination: {
                pageSize: !isTableShowingAll ? VIEW_LIMIT_COUNT : totalProviders,
                showTotal: (total, range) => {
                  const footerDiv = $(`#${tableId} .ant-table-footer`);
                  const hideClassname = `hide-footer-${tableId}`;
                  if(footerDiv.length) {
                    if(total > VIEW_LIMIT_COUNT) {
                      footerDiv.removeClass(hideClassname);
                    } else {
                      footerDiv.addClass(hideClassname);
                    }
                  }
                }
              }
            }}
          />
        })
      }
    </div> 
  }
};

CareTeamContainer.displayName = 'CareTeamContainer';

CareTeamContainer.propTypes = {
  careTeamList: PropTypes.array,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  openAddModal: PropTypes.func.isRequired
};

export default CareTeamContainer;
