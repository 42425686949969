import React from 'react';
import singleREMap from "../constant/SingleREColumnsMap";
import helper from '../helper/index';
const ConfirmedEnrollmentComponent = class extends React.Component{

    render() {
        const { remoteEnrollment } = this.props;
        return <div>{helper.renderSingleREColumns(singleREMap('CONFIRMED',this),remoteEnrollment)}</div>
    }
}

export default ConfirmedEnrollmentComponent;

