import gql from "graphql-tag";

export  default gql`query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
        pageInfo{
     	    total
            lastPage
        }
        data{    
          id
          category
          type
          visitRoles
          appointmentAt
          appointmentTo
          checkinAt
          checkoutAt
          declineToCheckVital
          unableToCheckVital
        }
     }

}`

// @params
// visitFields: array - data to get from single visit
export const buildVisitListMini = (visitFields, needPageInfo) => gql`
  query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
      data {
        id
        ${_.join(visitFields, '\n')}
      }
      ${
        !needPageInfo ? '' 
        : `
          pageInfo {
            total
            lastPage
          }
        `
      }
    }
  }
`;
