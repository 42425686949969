import React from 'react';
import { Spin } from 'antd';

import moment from 'moment';
import { Chart } from 'chart.js';
import { getActiveRateMonthly } from '../API';
import { tooltipStyle, fontSize, chartBarColor } from '../util/chartStyle';

const DATE_FORMAT = 'MMM YYYY';
const CANVAS_ID = 'bar-chart-monthly-active-rate';

class MonthlyActiveRate extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: undefined,
      loading: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    this.getData(organizationName, organizationId, startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    if (prevState.data !== this.state.data) {
      const data = this.state.data.values || [];
      if (this.chart === null) {
        this.createChart(data);
      } else {
        this.updateData(data);
      }
    }
    if (
      prevProps.organizationName !== organizationName 
      || prevProps.startDate !== startDate 
      || prevProps.endDate !== endDate 
      || prevProps.organizationId !== organizationId
    ) {
      this.getData(organizationName, organizationId, startDate, endDate, true);
    }
  }

  getChartData = (rawData) => {
    const labels = rawData.map(({ xvalue }) => moment(xvalue).format(DATE_FORMAT));
    const data = rawData.map(({ yvalue }) => Math.round(yvalue * 100));
    const backgroundColor = rawData.map(v => chartBarColor);

    return { labels, data, backgroundColor };
  }

  updateData(data) {
    const chartData = this.getChartData(data);
    this.chart.data.labels = chartData.labels;
    this.chart.data.datasets[0].data = chartData.data;
    this.chart.data.datasets[0].backgroundColor = chartData.backgroundColor;
    this.chart.update();
  }

  createChart = (data) => {
    const chartData = this.getChartData(data);

    this.chart = new Chart(document.getElementById(CANVAS_ID), {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: [
          {
            label: "Active Rate",
            backgroundColor: chartData.backgroundColor,
            data: chartData.data
          }
        ]
      },
      options: {
        legend: { display: false },
        title: {
          fontSize,
          display: true,
          text: 'Monthly Active Rate'
        },
        tooltips: {
          ...tooltipStyle
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              fontSize,
              display: true,
              labelString: 'Percentage Rate'
            },
            ticks: {
              fontSize,
              max: 100,
              display: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              fontSize,
              display: true,
              labelString: 'Start Date (MONTH)'
            },
            ticks: {
              fontSize,
            }
          }]
        }
      }
  });
  }

  getData = async (organizationName, organizationId, startDate, endDate, isUpdating = false) => {
    this.setState({ loading: isUpdating ? false : true, isUpdating });
    try {
      const data = await getActiveRateMonthly({ organizationName, organizationId, startDate, endDate });
      this.setState({ loading: false, data, isUpdating: false });
    } catch (err) {
      this.setState({ loading: false, isUpdating: false });
      this.props.openErrorModal(err.message || 'Something went wrong fetching chart data!')
    }
  }

  render() {
    const { loading, isUpdating } = this.state;

    return (
      <div>
        {loading && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {isUpdating && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {!loading && <canvas id={CANVAS_ID} />}
      </div>
    );
  }
}

export default MonthlyActiveRate;