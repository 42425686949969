import React from 'react';
import  { DATE_HELPERS  } from '../../../../../lib/helpers/date-helpers';
import obj from '../../Measurement/constants/index';
import { Divider } from 'antd';
import moment from 'moment';
import I18N from 'modulesAll/I18N'

const timeFormatString = I18N.get('dates.L');

class BGMeasurementComponent extends React.Component{

    render(){
        const { BGMeasureTypeMap , BGSeverityMap,style } = obj;
        const { value, unit,measuredAt,text,type,severity } = this.props;
        const { container,header,divider1,message } = style;
        const { title } = BGMeasureTypeMap[type];
        const measuretAtDate = DATE_HELPERS.formattedDate(moment(measuredAt),timeFormatString);

        const keys = Object.keys(BGSeverityMap);
        let severityValue ='unknown';
        let severityColor='white';

        keys.map((k)=> {
                if (k == severity) {
                    severityValue = BGSeverityMap[k].text;
                    severityColor = BGSeverityMap[k].color;
                }
            }
        );

        const severityStyle = {
            width:'100%',
            height:'25px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:severityColor,
            borderRadius:'12px',
            lineHeight:'4.7px',
            color:'white',
            fontSize:'10px'
        }
        const divider3 ={
            width:'3px',
            height:'inherit'
        }

        return (
            <div style={container}>
                <div style={header}>
                    <span style={{marginBottom:10}}>{title}({measuretAtDate})</span>
                    <Divider style={divider1}/>
                </div>
                <div style={message}>
                    <div style={header}>
                        <span style={{fontSize:18,fontWeight:'bold',lineHeight:'10pt'}}>{ typeof value=='number' ? value.toFixed(0) :'N/A'}</span>
                        <span >{unit}</span>
                        <div style={severityStyle}>{severityValue}</div>
                    </div>
                    <Divider type='vertical' style={divider3}/>
                    <div>
                        {text}
                    </div>
                </div>
            </div>
        )
    }
}

export default BGMeasurementComponent;
