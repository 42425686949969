// Admin
const pathStartsWith = [
  '/providers/profile',
  '/careteams/edit/',
  '/programmes_admin',
  '/edit_assessment',
  '/edit_survey',
  '/admin_preview_survey'
]
const pathOnly = [
  '/providers',
  '/providers/new',
  '/careteams',
  '/careteams/new',
  '/programmes/new',
  '/assessments',
  '/create_assessment',
  '/surveys',
  '/create_survey',
]

export default {
  pathStartsWith,
  pathOnly
}
