import React, { useState, useEffect, useCallback } from 'react';
import ComplexityComponent from '../components/ComplexityComponent';
import { compose } from 'react-apollo';
import { getPatientComplexityByCodes, withComplexity } from '../API';
import { Spin } from 'antd';

const ComplexityAndHealthCoachContainer = ({ 
  patientId, isEnrolled, billableCodes, 
  nonBillableCodes, patientComplexity, 
  patientComplexityLoading, patientComplexityRefetch,
  onChange, disabled, hasAssignees
}) => {
  const [codeBasedLoading, setCodeBasedLoading] = useState(false);
  const [codeBasedComplexity, setCodeBasedComplexity] = useState();

  const getComplexityByCondition = useCallback(_.debounce(async (_billableCodes, _nonBillableCodes) => {
    try {
      setCodeBasedLoading(true);
      const response = await getPatientComplexityByCodes({ billableCodes: _billableCodes, nonBillableCodes: _nonBillableCodes });
      setCodeBasedComplexity(_.get(response, 'data.getPatientComplexityByCodes'));
    } catch (err) {
      console.error(err);
    } finally {
      setCodeBasedLoading(false);
    }
  }, 600), [setCodeBasedLoading]);

  useEffect(() => {
    if (billableCodes !== undefined && nonBillableCodes !== undefined) {
      getComplexityByCondition(billableCodes, nonBillableCodes);
    }
  }, [billableCodes, nonBillableCodes]);

  if (patientComplexityLoading) {
    return <Spin />;
  }

  return (
    <ComplexityComponent 
      isEnrolled={isEnrolled}
      complexity={patientComplexity}
      codeBasedComplexity={codeBasedComplexity}
      onChange={onChange}
      disabled={disabled}
      hasAssignees={hasAssignees}
    />
  );
};

export default compose(withComplexity)(ComplexityAndHealthCoachContainer);
