import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  billableTimeQuery,
  withSignOffCaseCount,
  withSignOffTasks,
  signOffByMentor,
  readSignOffComment,
  getSignOffCaseCount
} from '../API/index';
import { compose } from "react-apollo/index";
import PatientPage from 'modulesAll/patient/main/containers/main';
import TaskCompletedMessage from '../component/TaskCompletedMessage';
import SignOffBody from '../component/SignOff/SignOffBody';
import { Col, Row, Button, Spin, message } from "antd";
import { goPath } from "../../../lib/utils";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { getTimerClaimedId, mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import I18N from '../../I18N';
import { handlePatientChangeForSignOff } from '../helper';
import { getRoleAndRoleName } from '../helper/signOffHelper';
import showMessageOnContainer from '../../layout/helper/showMessageOnContainer';
import { withState } from 'recompose';

const TASK_RESOLVER_TIMER = 'TASK_RESOLVER_TIMER';
import '../style/signOffResolveContainer.scss';
import { OMRONContextProvider } from '../../OMRONContext/OMRONContextProvider';

const initialState = {
  isLoading: false,
  showUnsavedModal: false,
  timeSpentInMin: 0,
  cursorSynced: false,
  systemGenerateMonthlyReviewData: null,
};

const stickyStyle = {
  position: 'sticky',
  zIndex: 1,
};

const SignOffResolveContainer = class extends React.Component {
  constructor(props) {
    super(props);
    this.currentUserRoleName = _.get(getRoleAndRoleName(), 'roleName');
    this.state = { 
      ...initialState,
    };
  }

  getTotalCaseCount = () => window.signOffCursor.getTotalCase();

  componentDidUpdate = () => {
    // const { loadingPatientIds, params, patientTask } = this.props
    // const taskId = _.get(patientTask, 'id');
    // if(!this.state.cursorSynced && !loadingPatientIds && !_.isEmpty(patientTask)){
    //   window.signOffCursor.syncCursor(params.id, taskId);
    //   this.setState({ isLoading: false, cursorSynced: true });
    // }
  }

  setTimeSpentInMin = (timeSpent) => {
    const { timeSpentInMin } = this.state;
    if (timeSpent !== timeSpentInMin) {
      this.setState({ timeSpentInMin: timeSpent });
    }
  }

  renderCases = () => {
    const totalCaseCount = this.getTotalCaseCount();
    return (
      <Col span={24} style={{ padding: '10px 20px', backgroundColor: 'white' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 17 }}>
          <div style={{ display: 'flex' }}>
            <a 
              style={{ color: '#757980', marginRight: 5 }} 
              href='/workstation'
            >
              Workstation /
            </a>
            { _.replace(I18N.get(`signOff.totalCaseText.${this.currentUserRoleName}`), 'XX', !_.isNil(totalCaseCount) ? totalCaseCount : '--') }
          </div>
          <div>
            {this.renderBillableTime()}
          </div>
        </div>
      </Col>
    );
  }

  renderBillableTime = () => {
    const { billableTime = {} } = this.props;
    const { totalEnrTime } = billableTime;
    return <div className={'totalTime'} style={{ marginLeft: 'auto' }}>
      <span style={{ fontSize: 14 }}>{`Total time spent on enrolled patients:  `}</span>
      <span className={'number'} style={{ fontWeight: 800 }}>{totalEnrTime ? Math.round(totalEnrTime / 60) : '--'}</span>
      <span className={'unit'}> min</span>
    </div>
  }

  goToPatient = (patient, handleError) => {
    const isNewTask = this.checkIfNewTask(patient);
    if(!isNewTask) {
      handlePatientChangeForSignOff(patient, handleError);
    } else {
      const updatedTaskId = window.signOffCursor.getTaskIdByCursor();
      this.props.setUpdatedTaskId(updatedTaskId);
    }
  };

  // one patient can have multiple tasks (ie Health coach with multiple review comment),
  // so next or previous patient get from cursor might have same patient id with current viewing one
  // Then, refresh the page states to try to get new task
  checkIfNewTask = (newPatient) => {
    const viewingPatientId = this.props.params.id;
    const newPatientId = _.get(newPatient, 'id');
    return viewingPatientId === newPatientId;
  };

  handlePreviousPatient = _.debounce(() => {
    this.setState({ isLoading: true }, async () => {
      const prevPatient = await window.signOffCursor.getPreviousPatient();
      this.goToPatient(prevPatient);
      this.setState({ isLoading: false });
    })
  }, 200);

  handleNextPatient = _.debounce(() => {
    this.setState({ isLoading: true }, async () => {
      const nextPatient = await window.signOffCursor.getNextPatient();
      this.goToPatient(nextPatient);
      this.setState({ isLoading: false });
    })
    // if (this.state.showUnsavedModal) {
    //   const resp = await new Promise((resolve, reject) => Modal.confirm({
    //     title: 'Are you sure you want to leave this page?',
    //     content: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //       <span style={{ fontWeight: 600, marginTop: 8, marginBottom: 4 }}>
    //         You have unsaved changes. Your changes will be lost if you navigate away from this page.
    //       </span>
    //     </div>,
    //     onOk: () => {
    //       resolve(true)
    //       this.setState({ showUnsavedModal: false });
    //     },
    //     onCancel: () => resolve(false)
    //   }));
    //   if (!resp)
    //     return;
    // }
    // Mixpanel.calculateDuration('workstation next patient');
    // Mixpanel.time_event('workstation next patient');
    // this.props.stopTimer('save', () => handlePatientChange(data));
    // Mixpanel.track('clicked', 'next_patient', 'workstation');
  }, 200);

  renderToolBar = () => {
    const { isLoading } = this.state;
    const hasPreviousCase = window.signOffCursor.hasPreviousCase();
    const hasNextCase = window.signOffCursor.hasNextCase();
    
    return <div className={'toolbar'}>
      {
        hasPreviousCase ?
        <Button
          type='link'
          className='navigateBtn'
          onClick={this.handlePreviousPatient}
          disabled={isLoading}
        >
          &lt;  Previous
        </Button> : <div></div>
      }
      {
        hasNextCase ?
        <Button
          type='link'
          className='navigateBtn'
          onClick={this.handleNextPatient}
          disabled={isLoading}
        >
          Next  &gt;
        </Button> : <div></div>
      }
    </div>
  }

  popFeedback = (type, content, containerSelector = '#signOffResolveBody') => showMessageOnContainer({
    type, content, containerSelector, configs: { top: 55, duration: 1.5 }
  });

  refreshPageStates = () => {
    this.setState({ isLoading: true, cursorSynced: false }, async () => {
      const res = await getSignOffCaseCount();
      await window.signOffCursor.pushNewCases(_.get(res, 'data'));
      await this.props.refetchPatientTasks();
      this.setState({ isLoading: false });
    });
  }

  resolveTaskAndNavigateNext = (cb) => async () => {
    try {
      const nextPatient = await window.signOffCursor.getNextPatient();
      if(nextPatient && nextPatient.id) {
        window.signOffCursor.popCase(-1);
        this.goToPatient(nextPatient, true);
      } else {
        // check if there is prev patient
        const prevPatient = await window.signOffCursor.getPreviousPatient();
        if(prevPatient && prevPatient.id) {
          window.signOffCursor.popCase(1);
          this.goToPatient(prevPatient, true);
        } else {
          // check if there is new patient ids generated
          // it will trigger to render FinishComponent if no more new patient ids / task
          this.refreshPageStates();
        }
      }
    } catch (error) {
      this.refreshPageStates();
    } finally {
      return cb && cb();
    }
  }

  onSubmit = _.debounce((comment) => {
    this.setState({ isLoading: true }, async () => {
      try {
        const taskId = _.get(this, 'props.patientTask.id');
        if(!taskId) {
          throw new Error('Something went wrong with the task!');
        }
        const actionMap = {
          RD: () => signOffByMentor({ id: taskId, comment }),
          HC: () => readSignOffComment({ id: taskId })
        };
        await actionMap[this.currentUserRoleName]();
        this.popFeedback('success', I18N.get(`signOff.feedback.success.${this.currentUserRoleName}`));
        setTimeout(this.resolveTaskAndNavigateNext(() => {
          this.setState({ isLoading: false });
        }), 1200);
      } catch (error) {
        this.refreshPageStates();
        return this.popFeedback('error', _.get(error, 'message', 'Failed to submit'));
      }
    })
  }, 200);

  renderBody = () => {
    const { isLoading } = this.state;
    const { loadingTask, patientTask } = this.props;
    const totalCaseCount = this.getTotalCaseCount();

    const FinishComponent = <TaskCompletedMessage
      show={true}
      showChat={false}
      imageSrc={ "/image/workstation/good-job.png" }
      message={I18N.get(`signOff.finishedMessage.${this.currentUserRoleName}.greeting`)}
      subMessage={this.getFinishedMessage()}
    />;

    const didHCRead = !!_.get(patientTask, 'readDate');
    
    return <div className='signOffResolveContainerBody'>
      { 
        (isLoading || loadingTask) ?
        <Spin />
        :
        (
          (totalCaseCount && !_.isEmpty(patientTask) && !didHCRead) ?
          <SignOffBody patientTask={patientTask} onSubmit={this.onSubmit} />
          :
          FinishComponent
        )
      }
    </div>
  }


  getFinishedMessage = () => {
    const { patientTask } = this.props;
    const totalCaseCount = this.getTotalCaseCount();

    const didHCRead = !!_.get(patientTask, 'readDate');

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          {I18N.get(`signOff.finishedMessage.${this.currentUserRoleName}.subMessage`)}
        </div>
        {
          (totalCaseCount && (_.isEmpty(patientTask) || didHCRead))
            ? <Button block type="primary" className="new-style" onClick={() => {
              this.setState({ isLoading: true }, this.resolveTaskAndNavigateNext(() => this.setState({ isLoading: false })));
            }}>
              Next Patient
            </Button>
            : <Button block type="primary" className="new-style" onClick={() => goPath('/workstation')}>
              Go back to dashboard
            </Button>
        }
      </div>
    );
  }

  setSystemGenerateMonthlyReviewData = (systemGenerateMonthlyReviewData) => {
    this.setState({ systemGenerateMonthlyReviewData });
  }

  render() {
    const { id, enrolledProgramId } = this.props.params;
    const { systemGenerateMonthlyReviewData } = this.state;

    return <div id='signOffResolveContainer'>
      <TimerClaimedContainer patientId={id} id={TASK_RESOLVER_TIMER} />
      <Col span={24} className="patientsHeader">
        {this.renderCases()}
        <Col span={24}><hr style={{ margin: '0 60px', backgroundColor: 'gray' }} /></Col>
      </Col>
      <Row className='resolveWrapper'>
        <Col span={6} style={{ ...stickyStyle, top: 50, zIndex: 501 }}>
          <div id='signOffResolveBody' className='resolveContainer'>
            {this.renderToolBar()}
            {this.renderBody()}    
          </div>
        </Col>
        <Col span={18} style={{...stickyStyle, top: 108 }}>
          <OMRONContextProvider
            patientId={id}
          >
            <PatientPage 
              enrolledProgramId={enrolledProgramId || 'default'}
              timerName={TASK_RESOLVER_TIMER}
              {...this.props} 
              isInChat={true}
              patientId={id}
              chatClassName={''}
              setTimeSpentInMin={this.setTimeSpentInMin}
              isWorkStation={true}
              isSignOff={true}
              systemGenerateMonthlyReviewData={systemGenerateMonthlyReviewData}
              setSystemGenerateMonthlyReviewData={this.setSystemGenerateMonthlyReviewData}
            />
          </OMRONContextProvider>
        </Col>
      </Row>
    </div>
  }
}

const mapToDispatch = (dispatch, ownProps) => {
  const patientId = ownProps.params.id;
  return {
    ...mapDispatchToTimerActions(dispatch, TASK_RESOLVER_TIMER, patientId)
  }
}

const SignOffResolveWrapper = compose(
  connect(null, mapToDispatch), 
  billableTimeQuery,
  // withSignOffCaseCount,
  withState('updatedTaskId', 'setUpdatedTaskId', null),
  withSignOffTasks,
)
(withRouter(SignOffResolveContainer));

export default SignOffResolveWrapper;