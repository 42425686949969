/**
 * Created by zizhangai on 1/25/17.
 */
import { constants } from 'libModule/utils';

export default constants('Alters', 'Interventions', [
  'ADD_NOTE',
  'OPEN_INPUT',
  'CLOSE_INPUT',
  'ADD_REASON',
  'DISABLE_BUTTON',
  'ENABLE_BUTTON',
  'CLEAR_STATE',
  'DELETE_STATE',
  'SWITCH_VIEW'
])

export const EDIT_DELETE_RULE = "Note is ONLY editable for the creator within 24 hours after first creation. Delete is ONLY enabled for the creator."; 

export const HIDE_CATEGORY_IN_PROVIDER_NOTE_CREATION = ['OFFLINE_TASK_LOG','MTPR','MRE','MTPR_ADDITIONAL','MRE_ADDITIONAL', 'DOCTOR_REVIEWED'];