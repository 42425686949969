import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

import ChatContainerWrapper from '../../chat/containers/ChatContainerWrapper';
import ChatIcon from '../../chat/components/ChatIcon';

import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { EP_COUNT_MAX } from '../../../lib/constants';

const CustomChatIcon = props => {
  const shouldShowRedDot = props.shouldShowRedDot;

  useEffect(() => {
    const chatTab = document.querySelector('.unresolvedTaskWrapper .ant-tabs-tab-disabled');
    const width = shouldShowRedDot ? 'initial' : '0px';
    const padding = shouldShowRedDot ? '10px' : '0px';
    if(chatTab) {
      chatTab.style.width = width;
      chatTab.style.padding = padding;
    }
  }, [shouldShowRedDot]);

  return !!shouldShowRedDot && (
    <div className='chatTaskItem'>
      <ChatIcon 
        {...props} 
        imageClassName='color-inverted'
      />
    </div>
  );
};

CustomChatIcon.propTypes = {
  shouldShowRedDot: PropTypes.bool.isRequired
};

const ChatWorkStationComponent = props => {
  const { patientId, loadingEnrolledProgramList, enrolledProgramList } = props;

  if(loadingEnrolledProgramList)
    return '';

  const mostRecentEP = useMemo(() => _.first(enrolledProgramList) || {}, [enrolledProgramList]);
    
  return (
    <ChatContainerWrapper 
      programStatus={mostRecentEP.status}
      patientId={patientId}
      renderChatIcon={props => <CustomChatIcon {...props} />}
      chatWithIndividual
      // shouldRenderChatWindow: false => must exist another ChatContainer in DOMtree, true => this is the only one
      shouldRenderChatWindow={false} 
    />
  )
};

ChatWorkStationComponent.propTypes = {
  patientId: PropTypes.string.isRequired
};

const getEnrolledProgramList = graphql(lightEnrolledProgramList, {
  options: ownProps => ({
    variables: {
      page: 1,
      count: EP_COUNT_MAX, //note: there's no pagination for EPList
      filters: {
        memberId: ownProps.patientId,
      },
      // sort by CreatedAt
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  }),
  props: ({ data }) => { 
    const { enrolledProgramList, loading, refetch, error } = data;

    if(error)
      console.error(error);

    return { 
      loadingEnrolledProgramList: loading,
      enrolledProgramList: _.get(enrolledProgramList, 'data') || [],
      refetchEnrolledProgramList: refetch
    }; 
  },
});

export default compose(getEnrolledProgramList)(ChatWorkStationComponent);