import {Input, Select} from "antd";
import React from "react";
import helper from "../helper";
import {AREA_MANAGER_STR} from '../constant/roleStrMap';

const generateDropDown = (list, disabled)=>{
    return <Select disabled={disabled}>
            { _.map(list,l=><Select.Option key={l.id} value={l.id}>
                {l.name}
              </Select.Option>)}
           </Select>
}
const createAreaFormConfig =(localThis)=>{
    const {
      type, organization, areaList, orgsLoading
    } = localThis.props || {};
    const selectedMemberIds = localThis.state.selectedMember;
    const currentViewingOrg  = organization || {};
    const { id: parentId, name: parentName } = currentViewingOrg.parent || {};
    let parentAreaList = areaList || [];
    parentAreaList = _.uniqBy(parentAreaList,a=>a.id);
    const isCreate = type==='CREATE';
    if(isCreate) {
      parentAreaList.unshift(currentViewingOrg);
    } else { // EDIT
      parentAreaList = _.filter(parentAreaList, a => a.id !== currentViewingOrg.id);

      // prevent showing raw orgId in dropdown
      if(parentId && !_.find(parentAreaList, { id: parentId })){
        parentAreaList.unshift({ id: parentId, name: parentName });
      }
    }
    const nameAndParentID = [
                                {
                                    key:'name',
                                    label:'Area Name *',
                                    rules: [{ required: true, message: 'Name is required' }],
                                    formatValue:()=>!isCreate ? currentViewingOrg.name:'',
                                    inputContent:()=><Input />,
                                    span:11
                                },
                                {
                                    key:'parentId',
                                    formatValue:()=>isCreate ? _.get(parentAreaList,'0.id') : parentId,
                                    inputContent:()=>orgsLoading ? <span>Fetching areas...</span> : generateDropDown(parentAreaList, isCreate),
                                    label:'Parent Area',
                                    offset: 1,
                                    span:11
                                }
                            ];
    const manager = [ {
                        key:'areaManger',
                        label:`${AREA_MANAGER_STR}(s)`,
                        inputContent:()=>helper.renderMemberSel(localThis),
                        formatValue:()=>selectedMemberIds,
                        span:11
                       }];
    const description =  [
                            {
                                key:'description',
                                label: 'Notes',
                                span: 23,
                                formatValue:()=>!isCreate ? currentViewingOrg.description:'',
                                inputContent:()=><Input.TextArea rows={5}/>
                            }
                        ]
    return {
        CREATE:[nameAndParentID,description],
        EDIT:[nameAndParentID,manager,description]
    }[type]
}

export default createAreaFormConfig;