import React, { useContext } from 'react';

const ReviewPatientContext = React.createContext();

export const ReviewPatientContextProvider = ReviewPatientContext.Provider;

export default () => {
  const context = useContext(ReviewPatientContext);
  return context;
};
