import {Modal} from 'antd';
import React, {Component} from 'react';
import InputVisitFormComponent from "../../visitNewWorkFlow/components/InputVisitFormComponent";
import '../../visitNewWorkFlow/CSS/style.scss';
import {message} from "../../../../../package/IHComponent";
import {connect} from "react-redux";
import { GQLHelper } from 'lib/utils';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { API } from '../../visit/query/index';
import I18N from '../../I18N';


class CreateVisitInPatientDetailContainer extends Component {

    constructor(props){
        super(props);
        this.createVisit = this.createVisit.bind(this);
    }

    createVisit(variables){
        API.createVisit(variables)
            .then(res => {
                if(this.props.refetchUser) {
                    this.props.refetchUser();
                }
                return this.props.refetch();
            })
            .then(res => {
                message.success('Appointment Scheduled');
                this.props.onCancel();
            })
            .catch(error => {
                console.log(error);
                this.props.openErrorModal(GQLHelper.formatError(error));
            })

        // const { year,patientId } = this.props;
        // const startDate = moment({year}).startOf('year');
        // const endDate = moment({year}).endOf('year');
        //
        // console.log('submit visit');
        //     Client.mutate({
        //         mutation: createVisitQuery,
        //         variables: variables,
        //         fetchPolicy: 'no-cache',
        //         refetchQueries:[{
        //             query: visitList,
        //             variables: helpers.setFromAndToDate({ memberId:patientId,sort:{} }, startDate,endDate),
        //             fetchPolicy: 'network-only'
        //         }]
        //     }).then((res)=>{
        //         this.props.onCancel();
        //         message.success('Appointment Scheduled');
        //     }).catch((error)=>{
        //         console.log(error);
        //         this.props.openErrorModal(GQLHelper.formatError(error));
        //     })
    }

    renderSelectComponent(){
        return (<InputVisitFormComponent patient={this.props.patient}
                                             backToSearch={this.props.onCancel}
                                             createVisit={this.createVisit}
                                             editStatus={false}
                                             fromDetail={true}
            />)
    }

    getTitle(){
            return I18N.get('visit.createTitle');
    }

    render() {
        Mixpanel.track('showed', 'create a visit popup', '', {});
        return (
            <Modal width={550}
                   title={this.getTitle()}
                   visible={true}
                   footer={null}
                   onCancel={this.props.onCancel}
                   maskClosable={false}
            >
                {this.renderSelectComponent()}
            </Modal>
        );
    }
}

const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
    }
}

export default connect(null,mapToDispatch)(CreateVisitInPatientDetailContainer);


