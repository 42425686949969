import gql from "graphql-tag";

const createHeartbeatLogMutation = gql`
mutation createHeartbeatLog($snapshots:[SnapshotInput]!,$heartbeatInterval:Int){
  createHeartbeatLog(
    snapshots:$snapshots,heartbeatInterval:$heartbeatInterval
  ){
    viewerId
    snapshots{
      patientId
      focused
      snapshotInterval
      componentPath
    }
    createdAt
    heartbeatInterval
    sendHeartbeatLog
    snapshotInterval
  }
}
`;

const getUserSettingsQuery =  gql`
  query user($id:EID) {
    user(id:$id) {
      id
      userSettings{
        sendHeartbeatLog
        snapshotInterval
        heartbeatInterval
        notificationFreq
      }
    }
  }
`

const orgNotificationsQuery = gql`query orgNotifications{
  orgNotifications(alert:{categories:["CDE","PROVIDER"]}){
    alerts{
      level1Count
      nonLevel1Count
      id
    } 
    foodLog{
      count
      id
    }
  }
}`

export const getSignedFeatureDownloadQuery= gql`query getSignedFeatureDownload{
    getSignedFeatureDownload{
      fileKey
      link
    }
  }`;
export const readLatestReleaseQuery = gql`query readLatestReleaseFromUser($id:EID!){
        user(id:$id){
            id
            lastReleaseRead
        }
}`
export const setLastReadReleaseMutate = gql` mutation setLastReadRelease($id:EID!,$lastReleaseRead:Int) {
  editUser(id:$id,
    lastReleaseRead: $lastReleaseRead
  ) {
    id
    lastReleaseRead
    readLatestRelease
  }
}`;
export const getUserOrgsQuery = gql`query getUserOrgs($id:EID!) {
  user(id:$id) {
    id
    allRolesCrossClinic {
        refId
        name
        organization {
            name
            internalName
            id
        }
    }
  }
}`;
export default {
    createHeartbeatLogMutation,
    getUserSettingsQuery,
    orgNotificationsQuery,
    setLastReadReleaseMutate,
    getUserOrgsQuery
}
