import React from 'react';
import { Form, Select, Button, Switch, Progress, Col } from 'antd';
import { language, languageConfig } from '../../../patient/DevicesAssign/constant';
import moment from 'moment';

const Item = Form.Item;

let UpdateClearConfigComponent = class extends React.Component {
  getLanguageOptions() {
    const { disabled } = this.props;

    const options = language.map(({ key, value }) => {
      return <Select.Option key={key} value={key}>{value}</Select.Option>
    });
    return <Select
      dataSource={options}
      style={{ width: 200 }}
      onChange={() => this.setVolume()}
      disabled={disabled}
    >
      {options}
    </Select>

  }

  onSubmit = (e) => {
    e.preventDefault();
    const { form, config, editIoTDeviceConfigAPI, openErrorModal, onUpdate } = this.props;
    const memberId = _.get(this, 'props.user.id');
    const deviceId = _.get(config, 'deviceId');

    form.validateFields(['language', 'volume'], (errors, { language, volume }) => {
      if (errors || !memberId || !deviceId)
        return;

      editIoTDeviceConfigAPI({ deviceId, language, volume: volume ? 50 : 0 }, memberId)
        .then(res => {
          if (onUpdate) onUpdate(res);
        })
        .catch(e => {
          openErrorModal(e)
        });
    });
  }

  setVolume() {
    const { form } = this.props;
    const { resetFields } = form;
    resetFields(['volume'])
  }

  renderForm = () => {
    const { form, config, disabled } = this.props;
    const { deviceId, language, volume, battery, lastSeenAt } = config || {};

    return <React.Fragment>
      <Col span={24} style={{ margin: '12px 0px', padding: '0px !important' }}>
        Device ID: {deviceId}
      </Col>
      <Col span={24} style={{ margin: '12px 0px', padding: '0px !important' }}>
        <span style={{ display: 'flex' }}>
          Battery:
          {
            (battery != null) ?
              <Progress percent={battery} size='small' style={{ marginLeft: 10 }} />
              : 'N/A'
          }
        </span>
      </Col>
      <Col span={24} style={{ margin: '12px 0px', padding: '0px !important' }}>
        <span>
          Last time seen online:
          {(lastSeenAt != null) ? moment(lastSeenAt).format('HH:mm:ss MM/DD/YYYY') : 'Never'}
        </span>
      </Col>
      <Col span={24} style={{ padding: '0px !important' }}>
        {
          <Form className='addDeviceForm' layout={'vertical'}>
            <Item label='Device Language' >
              {form.getFieldDecorator('language', { initialValue: language || 'en' })(this.getLanguageOptions())}
            </Item>
            <div className='language-switch' style={{ display: 'flex' }}>
              <Item label='' wrapperCol={{ span: 3 }}>
                {form.getFieldDecorator('volume', { initialValue: !!volume, valuePropName: 'checked' })(
                  <Switch disabled={disabled || (!_.get(languageConfig, `${language}.volume`, false))} />
                )}
              </Item>
              <div style={{ marginTop: 13, marginLeft: 8 }}>
                <div>Read out BP reading</div>
                <div style={{ fontSize: 12 }}>(only available in English &#38; French)</div>
              </div>
            </div>
            <Item wrapperCol={{ span: 12 }}>
              <Button
                type='primary'
                onClick={this.onSubmit}
                style={{ background: 'var(--dark-blue)', color: '#FFF' }}
                disabled={disabled}
              >
                Update Device
              </Button>
            </Item>
          </Form>
        }
      </Col>
    </React.Fragment>
  }

  render() {
    return this.renderForm();
  }
}

UpdateClearConfigComponent = Form.create({})(UpdateClearConfigComponent);

export default UpdateClearConfigComponent;