import {
    React, IHButton, IHBaseFormWithRow, _
} from 'ihcomponent'
import PropTypes from 'prop-types'
import I18N from '../../../I18N';
import { firstAndLastNameValidator } from 'libModule/validator';
const FORMLABELS = I18N.get('patientlist.importPatientForm');
const BUTTONLABELS = I18N.get('patientlist.buttons');

const styles = {
  form: {},
  footer: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'space-between',
    margin: '20px 0'
  }
}

export default class NewPatient extends React.Component{
  static displayName = 'patientList/registerNew/components/ImportPatientInfo'

  handleImport = async () => {
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }
    await this.refs.importNewPatientBaseForm.getFormData(callback);
    if(!isFormValid)
    return;

    this.props.handleImportClick()
  }

  handleCancel(){
    this.props.handleCancelClick()
  }

  getBaseFormProps(){
    const itemLayout = {
      labelCol : {span : 24},
      wrapperCol : {span : 24}
    }
    const formData = this.props.ImportPatientInfo
    return {
      vertical: true,
      itemList : [
        [
          {
            key : 'firstName',
            label : FORMLABELS.firstName,
            type : 'text',
            ...itemLayout,
            // placeholder : 'First Name',
            placeholder : '',
            initialValue: formData.initData['firstName'].value,
            col: {span: 12, offset: 0},
            rules : [
              {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
            ]
          },
          {
            key : 'lastName',
            label : FORMLABELS.lastName,
            type : 'text',
            ...itemLayout,
            // placeholder : 'Last Name',
            placeholder : '',
            initialValue: formData.initData['lastName'].value,
            col: {span: 12, offset: 0},
            rules : [
              {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
            ]
          }
        ],
        [
          {
            key : 'nric',
            label : FORMLABELS.nric,
            type : 'text',
            ...itemLayout,
            // placeholder : 'S12345678E',
            placeholder : '',
            initialValue: formData.initData['nric'].value,
            col: {span: 12, offset: 0},
            rules : [
              {required : true, message: 'NRIC is required'}
            ]
          },
          {
            key : 'DOB',
            label : FORMLABELS.dob,
            type : 'date',
            ...itemLayout,
            placeholder : 'MMM-DD-YYYY',
            format: 'MMM-DD-YYYY',
            initialValue: formData.initData['DOB'].value,
            col: {span: 12, offset: 0},
            rules : [
              {required : true}
            ]
          }
        ],
        // [
        //   {
        //     key : 'gender',
        //     label : FORMLABELS.gender,
        //     type : 'radioButton',
        //     ...itemLayout,
        //     placeholder : '',
        //     initialValue: formData.initData['gender'].value,
        //     option: [{name:'Male', value:'Male'}, {name:'Female', value:'Female'}],
        //     col: {span: 12, offset: 0}
        //   }
        // ]
      ]
    }
  }

  renderBottomButton(){
    const {formError} = this.props
    return (
      <div style={styles.footer}>
        <IHButton onClick={this.handleCancel.bind(this)} bsStyle="default" label={BUTTONLABELS.cancelButton} />
        <IHButton onClick={this.handleImport.bind(this)} bsStyle="primary" disabled={formError} label={BUTTONLABELS.importButton} />
      </div>
    )
  }
  render(){

    const formProp = this.getBaseFormProps()
    const formProps = _.pick(this.props, ['onFieldsChange', 'mapPropsToFields'])

    const bodyElem = (
      <IHBaseFormWithRow style={styles.form} {...formProp} {...formProps} ref="importNewPatientBaseForm">
      </IHBaseFormWithRow>
    )

    return (
      <div className="vsm-patient-import_patient_info v-form-required">
        {bodyElem}
        {this.renderBottomButton()}
      </div>
    )
  }
}

NewPatient.propTypes = {
  dispatch: PropTypes.func,
  footer: PropTypes.node,
  handleImportClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
  ImportPatientInfo: PropTypes.object,
  formError: PropTypes.bool
}
