import React from 'react';
import UnderlinedTooltip from '../../../patientList/main/components/UnderlinedTooltip';

import '../styles/index.scss';

const categoryOptions = [
  { 
    label: <UnderlinedTooltip
      tooltipMsg={<div>
        <span>Vitals Monitoring Hiatus means when there is a time period during which patient hasn’t taken / won’t take vitals. While in Vitals Monitoring Hiatus:</span>
        <ul>
          <li>No alerts or auto-messages related to vitals monitoring inacitivity will be generated.</li>
          <li>Patient will be excluded from Activity Rate Data Analysis</li>
        </ul>
      </div>}
      tooltipProps={{
        overlayClassName:'hiatus-status underlined-tip' 
      }}
    >
      Vitals Monitoring Hiatus
    </UnderlinedTooltip>,
    value: 'vitalsMonitoringHiatus', 
    disabled: false 
  },
  { 
    label: <UnderlinedTooltip
      tooltipMsg={<div>
        <span>Visit Hiatus means when there is a time period in which patient hasn't seen /won't see us. While in Visit Hiatus:</span>
        <ul>
          <li>No visit due or visit overdue will be generated.</li>
        </ul>
      </div>}
      tooltipProps={{
        overlayClassName:'hiatus-status underlined-tip' 
      }}
    >
      Visit Hiatus
    </UnderlinedTooltip>,
    value: 'visitHiatus',
    disabled: false 
  },
  {
    label: <UnderlinedTooltip
      tooltipMsg={<div>
        <div>Designed for patients with 2-week Inactive alerts. For each 2-w inactive alert, you'll call the patient and snooze for no more than 1 week at a time. After <strong>4</strong> unsuccessful attempts to re-engage with a call and/or a visit, place the patient on Hiatus Unable to Engage.</div>
        <div>
          <strong>While on Hiatus Unable to Engage</strong>
        </div>
        <ul>
          <li>No CA alerts will be generated.</li>
          <li>The status will be removed automatically when the patient resumes checking vitals.</li>
        </ul>
      </div>}
      tooltipProps={{
        overlayClassName:'hiatus-status underlined-tip' 
      }}
    >
      Hiatus unable to engage
    </UnderlinedTooltip>,
    value: 'hiatusUnableToEngage', 
    disabled: false 
  }
];

const categoryMap = {
  vitalsMonitoringHiatus : 'VITALS_MONITORING_HIATUS',
  visitHiatus: 'VISIT_HIATUS',
  hiatusUnableToEngage: 'HIATUS_UNABLE_TO_ENGAGE',
};

const statusEnum = {
  VALID: 'PAUSED',
  INVALID: 'RESUMED'
};

const REASON_ENUM = {
  EXTENDED_TRAVEL: 'Extended Travel',
  HOSPITALIZATION: 'Hospitalization',
  PAUSE_PROGRAM_PARTICIPATION: 'Pause Program Participation',
  LOST_INSURANCE: 'Lost Insurance',
  TAKE_BREAK: 'Condition is well-controlled and the patient wants to take a break',
  PENDING_LAB_RESULTS: 'Pending Lab Result',
  OTHERS: 'Others'
};

const REASON_ENUM_INV = _.invert(REASON_ENUM); 

const indicatorTextMap = {
  vitalsMonitoringHiatus: 'Vitals Monitoring',
  visitHiatus: 'Visit',
  hiatusUnableToEngage: 'Unable to engage',
};

const ACTION_ENUM = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  RESUMED: 'RESUMED',
};

export default {
  categoryOptions,
  categoryMap,
  statusEnum,
  REASON_ENUM,
  REASON_ENUM_INV,
  indicatorTextMap,
  ACTION_ENUM
};