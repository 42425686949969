import React from 'react';
import { Card } from 'antd';
import '../../style/index.scss';
import moment from "moment";
import {goPath} from "../../../../lib/utils";
import OrgSummaryHeaderComponent from "./OrgSummaryHeaderComponent";
import {MANAGER_STR} from '../../constant/roleStrMap';
import {IHLoading} from 'ihcomponent';

const OrgCardComponent = class extends React.Component {

    renderHeader = (header)=>{
        return <span className='header'>{header}</span>
    }

    renderManager = (managers) => {
        let nameArr = '';
        if(managers && managers.length > 0) {
            _.forEach(managers, ({fullName}) => {
                nameArr += fullName + '/'
            })
        }
        nameArr = _.trimEnd(nameArr, '/');
        return <div className='managers'>{MANAGER_STR}: {nameArr}</div>
    }

    renderLocation = (location) => {
        return <div className='orgText'>{location}</div>
    }

    renderCreateTime = (createdAt)=>{
        return <div>
                    <span>Created on:</span>
                    <span className='createdAt'>{moment(createdAt).format('MM/DD/YY')}</span>
                </div>;
    }

    getLocation = (address) => {
        const city = address ? _.get(address, 'city', '') : '';
        const state = address ? _.get(address, 'state', '') : '';
        const location = city === '' && state === '' ? '' : city + ' ' + state;
        return location;
    }
    renderMainContent = (data)=>{
        const { address, createdAt, id, leaf, orgSummary, managers } = data;
        const name = leaf ? _.get(data, 'internalName', '') : _.get(data, 'name', '');
        const location = this.getLocation(address);
        const { renderHeader,renderManager,renderCreateTime,renderLocation} = this;
        return <div>
            {renderHeader(name)}
            {leaf ? renderLocation(location) : ""}
            {renderManager(managers)}
            {renderCreateTime(createdAt)}
            <OrgSummaryHeaderComponent orgSummary={orgSummary} className={'orgSummaryInCard'} leaf={leaf}/>
        </div>
    }

    renderLoadingContent = (name)=>{
        return <div>
            {this.renderHeader(name)}
            <IHLoading/>
        </div>
    }

    renderCard = (data, type) => {
        const { loading } = this.props;
        const { id,name } = data;
        return <Card className='AreaCard' onClick={() => !loading ? goPath(`/${type}/${id}`) :'' }>
                { loading ? this.renderLoadingContent(name): this.renderMainContent(data) }
            </Card>
    }

    render() {
        const { renderCard, props } = this;
        const { data={}, type,name,loading } = props;
        return <div>{renderCard(data, type)}</div>
    }
}

export default OrgCardComponent;
