import  React  from 'react';
import { compose } from "react-apollo/index";
import closeSmartAlert from 'modulesAll/smartAlert/query/closeSmartAlert';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';
import { ND, IHSearchTable, IHButton, _ } from 'ihcomponent';
import { connect } from 'react-redux'
import { GQLHelper } from 'libModule/utils'
import { modalAction } from 'modulesAll/common/actions'
import Client from 'libModule/gqlClient';
import { message,Modal,Row,Col,Tag,Form,Input,Checkbox } from 'antd';
import {API} from "modulesAll/timer/query";
import createProviderNote from 'modulesAll/graphql/createProviderNote'
import constant from "modulesAll/smartAlert/constant";
import providerNoteList from 'graphqlModule/providerNoteList'
import TimerContainer from 'modulesAll/timer/containers/TimerContainer';
import patientSmartAlertList from 'modulesAll/smartAlert/query/patientSmartAlertList.js'
import * as timerActions from "modulesAll/timer/actions";

const { stopTimer,updateInterventionDetails }= timerActions.default;
const { alertLevelColorMap } = constant;

const Button = ND.Button
const ButtonGroup = Button.Group

let PatientSmartAlertNoteContainer = class extends React.Component {
    static displayName = 'PatientSmartAlertNoteContainer';
    
    constructor(props){
        super(props);
        this.state = {
            showSmartPNModal: false,
            alert: props.row,
        }
        this.handleShow = this.handleShow.bind(this);
    }

    changeSmartPNModalStatus = (alert) =>{
        const { showSmartPNModal } = this.state;
        const { memberId } = this.props;
        const { taskType } = alert;
        this.setState({
            showSmartPNModal: !showSmartPNModal,
            alert
        })
    }

    showConfirmForCloseAlert=(id)=>{
        return new Promise((resolve)=>{
            const Yes = {
                label: 'Yes, Close Alert',
                handler: ()=>{
                    resolve(this.handleCloseAlert(id));
                },
                style:{
                    color:'white',
                    background:'#ed5c5f',
                    textShadow:'none'
                },
                closeModal: true
            }
            const No = {
                label: 'No',
                style:{
                    border: 'solid 1px #afafaf',
                    color:'#333',
                    background:'white',
                    textShadow:'none'
                },
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Close Alert',
                content: 'Are you sure to close this alert?'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    handleShow() {
        this.setState({
            showSmartPNModal: true
        })
    }

    handleSubmit = async (e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
        const { form, memberId, openErrorModal } = this.props;
        const { alert } = this.state;
        const { getFieldsValue } = form;
        const { notes, dismissAlert } = getFieldsValue();
        if(notes && notes.trim().length != 0){
            let variables = {
                memberId,
                smartAlertId:alert.id,
                category:'SMART_ALERT',
                content:notes
            }
            try {
                const res = await this.createProviderNote(variables);
                const apiName = Object.keys(res.data)[0];
                console.log(apiName);
                const docId = res.data[apiName].id;
                const summary = res.data[apiName].content;
                const resource = atob(docId).split(':')[0];
                let displayName = this.constructor.displayName;
                displayName = `${displayName}:${memberId}`;
                this.props.updateInterventionDetails(displayName,apiName,resource,docId,summary);
                this.props.stopTimer(displayName, 'saveModal');

                // if(dismissAlert){
                //     this.handleCloseAlert(alert.id);
                // } else {
                //     this.setState({
                //         isReviewed: true
                //     })
                // }

                this.handleCloseAlert(alert.id);
                this.setState({
                    showSmartPNModal:false,
                    alert:{}
                })
            }
            catch(e){
                console.log(e);
                openErrorModal(GQLHelper.formatError(e))
            }
        }
    }

    handleCloseAlert = (taskId)=>{
        const { openErrorModal, memberId, uuid, curPage, row, tableProps} = this.props;
        const { alertLevel } = tableProps;
        const event = 'HANDLE_SMART_ALERT';
        const category = 'ALERT';
        const action = 'UPDATE';
        const timeTrackerEvent = API.startTimeTracker(event,category,action,memberId);
        Client.mutate({
            mutation: closeSmartAlert,
            variables:{taskId},
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query:patientSmartAlertList,
                    variables:{ filters: {memberId, alertLevel}, page: curPage},
                    fetchPolicy:'network-only'
                },
                {
                    query:getPatientSmartAlert,
                    variables:{ memberId},
                    fetchPolicy:'network-only'
                }
            ],
        }).catch(e=>{
            console.log(e);
            openErrorModal(GQLHelper.formatError(e))
        }).then(_=> {
            const canRestUUid = false;
            API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);
            message.success('Alert Closed');
        });
    }

    createProviderNote = async (variables)=>{
        const { props } = this;
        const refetchVariable = (() => {
            const variables = {
                'filters': {
                    'enrolledProgramId': props.currentProgramId,
                    ...props.filter,
                },
                page: _.get(props, 'page.current', 1),
                count: 10,
                sort: {'field': 'CREATED_AT', 'direction': 'DESC'}
            }
            const sortField = _.get(props, 'sort.fieldGraph', null);
            if (sortField) {
                variables.sort = {
                    field: sortField,
                    direction: _.get(props, 'sort.direction', 'DESC'),
                };
            }
            return variables;
        })();
        return Client.mutate({
            mutation:createProviderNote,
            variables,
            fetchPolicy: 'no-cache',
            refetchQueries: [{
                query: providerNoteList,
                variables: refetchVariable,
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
            }]
        });
    }

    renderPatientInfo= () =>{
        const { identification, fullName } = this.props;
        return <Row>
                <Col span={24}>
                    <span style={{ fontSize:18,fontWeight:'bold',color:'#000000' }}>{`${fullName} (${identification})`}</span>
                </Col>
               </Row>
    }

    renderSmartAlert = () => {
        const { alert } = this.state;
        const style = {
            tagStyle:{
                display: 'flex',
                height: 28,
                alignItems: 'center',
                border: '1px',
                borderRadius: 15,
                marginBottom: 15,
                marginTop: 15,
                width:'fit-content',
                paddingLeft: '10px',
                paddingRight: '10px'
            }
        };
        const {color, backgroundColor} = alertLevelColorMap[alert.alertLevel] || {};

        return <Tag style={{color: '#2D3238', backgroundColor: backgroundColor, ...style.tagStyle}}>
                    <div style={{ width: 8, height: 8, borderRadius: 6, backgroundColor: color, display: 'inline-block', marginRight: 5 }}/>
                    <p style={{fontSize:12, cursor:'pointer'}}>{alert.display}</p>
               </Tag>
    }

    renderForm = ()=>{
        const { TextArea } = Input;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form layout="inline" style={{ width:'100%',paddingRight:20 }}>
                 <Form.Item style={{ width:'inherit' }} className='smartAlertPNForm'>
                     {getFieldDecorator('notes', {
                         rules: [{ required: true, message: 'Please Enter Provider Notes' },{ whitespace:true,message: 'Please Enter Provider Notes' }],
                     })(
                         <TextArea rows={6}/>
                     )}
                 </Form.Item>
                 {/* <Form.Item>
                    {getFieldDecorator('dismissAlert', {
                        rules: [{ required: false }],
                        initialValue: true,
                        valuePropName: 'checked'
                    })(
                        <Checkbox>Dismiss the alert</Checkbox>
                    )}
                 </Form.Item> */}
                     <Button type="primary" htmlType='submit' onClick={this.handleSubmit} style={{ float:'right',marginTop:20 }}>
                         Submit
                     </Button>
               </Form>
    }

    renderModal = ()=>{
        const { showSmartPNModal } = this.state;
        let displayName = this.constructor.displayName;
        const { memberId } = this.props

        const viewingDetails = {
            component : displayName,
            path: window.location.pathname
        }
        const keepEventListener = true;

        return showSmartPNModal ?
                <Modal visible={showSmartPNModal} onCancel={ ()=>this.setState({ showSmartPNModal: false })} width={940}
                      maskClosable={false} footer={null}>
                <TimerContainer displayName={'PatientSmartAlertTasksContainer'} category='ALERT' action='UPDATE'
                                viewingDetails={viewingDetails} patientId={memberId}
                                keepEventListener={keepEventListener}
                />
                 <Row>
                     <Col span={8}>
                         { this.renderPatientInfo() }
                         { this.renderSmartAlert() }
                     </Col>
                     <Col span={16}>
                        { this.renderForm() }
                     </Col>
                 </Row>
                </Modal> :''
    }

    render(){
        const { isInChat, row, curPage, tableProps } = this.props;
        const taskId = row.id;
        return (
            this.state.isReviewed ? 
            <div>
                { this.renderModal() }
                <div className={`alert-btn-gp ${isInChat ? 'isInChat' : ''}`}>
                    <ButtonGroup>
                        <Button disabled={true}>Reviewed</Button>
                        { isInChat ? <span>/</span>: '' }
                        <Button onClick={() => this.showConfirmForCloseAlert(taskId)}>Close</Button>
                    </ButtonGroup>
                </div>
            </div> :
            <div>
                { this.renderModal() }
                <div className={`alert-btn-gp ${isInChat ? 'isInChat' : ''}`}>
                    <ButtonGroup>
                        <Button onClick={this.handleShow}>Review</Button>
                        { isInChat ? <span>/</span>: '' }
                        <Button onClick={() => this.showConfirmForCloseAlert(taskId)}>Close</Button>
                    </ButtonGroup>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    const uuid = _.get(state,'timer.main.uuid',null);
    return {
        ...state.globalTables.allNotes,
        smartAlertTableProps:state.globalTables.patient_smart_alert_table,
        uuid,
    }
};
const mapDispatch = (dispatch) => {
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal: msg => dispatch(modalAction.openErrorModal(msg)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
PatientSmartAlertNoteContainer = Form.create({})(PatientSmartAlertNoteContainer);

export default compose(
    connect(mapState,mapDispatch))
    (PatientSmartAlertNoteContainer);
