import React from 'react';
import { notification,Button,Icon } from "antd";
import helper from "../helpers/index";

const SelfEvaluationComponent = class extends React.Component{
    constructor(props) {
        super(props);
        // this.state = {
        //     showSelfEvaluation: false
        // }
    }

    renderSelfEvaluation = (type,params)=>{
        switch (type) {
            case 'MENU':
                return helper.renderSelfEvaluationAns(helper.getFullSelfEvalAns({...this.props}));
            case 'CHARTING':
                return helper.renderSelfEvaluationAns(params.evaluations);
            default: return helper.renderSelfEvaluationAns(helper.getFullSelfEvalAns({...this.props}));
        }
    }

    openNotification = ({className,type,params,message}) => {
        const { setShowSelfEvaluation,showSelfEvaluation } = this.props;
        const args = {
            message,
            key:type,
            description: this.renderSelfEvaluation(type,params),
            duration: 0,
            className,
            placement:'topRight',
            top:100,
            onClose:()=>{
                setShowSelfEvaluation(false);
            }
        };
        notification.close(showSelfEvaluation);
        setShowSelfEvaluation(type);
        notification.open(args);
    }


    inMenu = (selfEvaluation)=>{
        const { openNotification,props } = this;
        const { showSelfEvaluation } = props;
        const hasSelf = !_.isEmpty(selfEvaluation);
        const icon = hasSelf ? <Icon type="check-circle" /> : <Icon type="info-circle" />;
        const message = hasSelf ? 'Health screening submitted!':'Health screening missing';
        const className = 'middleSelfEva';
        const viewLink = <a className={`message ${hasSelf ? 'hasSelf':''}`} onClick={()=>openNotification({className,type:'MENU',message:'Health screening form'})} disabled={!hasSelf||showSelfEvaluation=='MENU'}>View Health screening form</a>
        return <div className='selfEvaluationStatus'>
                <div className={`icon ${hasSelf ? 'hasSelf':''}`}>{ icon }<p>{ message }</p></div>
                { hasSelf&&viewLink }
               </div>;
    }

    inSession = (selfEvaluation)=>{
        const { openNotification,props } = this;
        const { showSelfEvaluation } = props;
        const hasSelf = !_.isEmpty(selfEvaluation);
        if(!hasSelf) {
            return '';
        }
        const evaluations = helper.getConfigBasedOnSelfEvaAndHealthCode({...this.props});
        const hasExtraInfo = !! evaluations.length;
        const allSynced = hasSelf&&!hasExtraInfo;
        const message = allSynced ? 'Information has been synced!':'*Patient input additional information'
        const className = 'sideSelfEva';
        const args = {
            evaluations
        }
        const notificationHeader = 'Additional information that patient filled in in Health screening form'
        return <div className='selfEvaluationStatus'>
            <a className={`message ${allSynced ?'allSynced':'missInfo'}`} onClick={()=> openNotification({className,type:'CHARTING',params:args,message:notificationHeader})} disabled={allSynced||showSelfEvaluation=='CHARTING'}>{message}</a>
        </div>;
    }

    buttonAndIcon = (selfEvaluation,type)=>{
        const typeMap = {
            CHARTING: this.inSession,
            MENU: this.inMenu
        }
        return typeMap[type](selfEvaluation);
    }

    render() {
        const { props } = this;
        const selfEvaluation  = _.get(this,'props.visit.selfEvaluation',{});
        const type = _.get(props,'type');
        const visitType = _.get(this,'props.visit.type');
        const { buttonAndIcon } = this;
        return <div>
                { visitType==='INIT'&&buttonAndIcon(selfEvaluation,type) }
               </div>
    }
}

export default SelfEvaluationComponent;
