import { React, IHButton, IHLoading } from 'ihcomponent';
import { connect } from 'react-redux';
import { branch, withState } from 'recompose';
import { graphql, compose } from 'react-apollo';
import userList from 'graphqlModule/userList';
import teamList from 'graphqlModule/teamList';
import providerList from 'graphqlModule/providerList';
import { Modal, Tabs } from 'antd';
import { modalAction as ModalActions } from 'modulesAll/common/actions';
import helper from '../../helper';
import CareTeamContainerWrapper from '../../CareTeam/containers';
import EditTeamContainer from './EditTeamContainer';
import modalHelper from '../../constant/modalMap';
import { clinicTabKeyMap } from '../../constant/formConfig';
import EmptyClinicListPlaceholder from './EmptyClinicListPlaceholder';
import UserClass from 'graphqlModule/class/User';
import query from "../../query";
import API from '../../API/index';

const { TabPane } = Tabs;
const MAX_TEAM_COUNT = 50;


export const defaultTeamVariables = {
    count: 5,
    page:1,
    sort: {
        field: 'CREATED_AT',
        direction: 'DESC'
    }
}
class ClinicMemberContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          modalBodyProps: {},
          providerList: [],
          loadingProviderList: true,
          teamList:[],
          teamListPageInfo: { },
          loadingTeamList:false
        }
    }

    // fetchAndSetTeam = async (variables)=>{
    //     this.setState({
    //         loadingTeamList: true
    //     })
    //     let data = await API.getTeamList(variables);;
    //     let teamList = _.get(data,'data.teamList');
    //     this.setTeam( teamList );
    // }
    //
    // setTeam = (teamList)=>{
    //     const {  data,pageInfo } = teamList;
    //     this.setState({
    //         teamList:[
    //             ...this.state.teamList,
    //             ...data
    //         ],
    //         loadingTeamList:false,
    //         teamListPageInfo:pageInfo
    //     })
    //
    // }
    // componentDidMount = async () => {
    //   const { props } = this;
    //   const variables = {
    //       ...defaultTeamVariables,
    //       filters: {
    //           organizationId: _.get(props, 'organization.id')
    //       }
    //   }
    //   console.log('did mount');
    //   this.fetchAndSetTeam(variables)
    // }

    setEditMode = (isEditMode = true, needRefetch = true) => {
      // auto-set true for isEditMode, otherwise, set given value (ie. false)
      // needRefetch should share same boolean with isEditMode
      if(!this.props.isEditMode || !isEditMode) {
        this.props.setEditMode(isEditMode);
        this.props.setNeedRefetch(needRefetch);
      }
    };

    updateNeedRefetchTeamList = needRefetchTeamList => {
      if(!this.props.needRefetchTeamList || !needRefetchTeamList) 
        this.props.setNeedRefetchTeamList(needRefetchTeamList);
    };

    createBarExtraContent = (isLeaf) => {
      // should not render Add Members button when org is leaf and not CT tab
      let hasValue = false;
      const { contentListLength, careTeamList } = this.props;
      let currentTab = this.props.selectedTab;
      switch(currentTab){
        case 'CARE_TEAM':
            hasValue = careTeamList.length;
            break;
        default:
            hasValue = contentListLength;
            break;
      }
      const shouldRenderButton = !isLeaf || this.props.selectedTab ==='CARE_TEAM';
      return shouldRenderButton && hasValue &&
          <div className='pane-extra-content'>
              <IHButton 
                type='primary' 
                className='add-button' 
                onClick={this.openAddModal}
                size='small'
              >
                {clinicTabKeyMap[this.props.selectedTab].buttonLabel}
              </IHButton>
          </div>;
    }

    openAddModal = (e, modalBodyProps ={} ) => {
      this.setState({
        addModalVisible: this.props.selectedTab,
        modalBodyProps
      });
    }

    // checkEditMode: when finished action and ignore editMode, set this to false
    // closeAndRefetch: manually set if an action (ie Delete) need refetch without toggling needRefetch
    closeAddModal = (closeAddModalProps) => {
      const { 
        checkEditMode = true, 
        closeAndRefetch,
        appendTeam = false,
        refetchCareTeamList,
        deleteTeamId,
        team
      } = closeAddModalProps || {};
      const { selectedTab, refetchList,appendTeamToFront, deleteTeam } = this.props;

      const needRefetchList = closeAndRefetch || this.props.needRefetch;

      const resetStates = (needRefetch = needRefetchList) => this.setState({
        addModalVisible: false,
        modalBodyProps: {}
      }, () => {
        if(needRefetch){
          this.setEditMode(false, false);
            refetchList &&  refetchList();
        }
      });

      if(appendTeam&&team) {
          appendTeamToFront&&appendTeamToFront(team);
      }

      if (refetchCareTeamList) refetchCareTeamList();

      if (deleteTeamId && deleteTeam) {
        deleteTeam(deleteTeamId);
      }

      if(checkEditMode && this.props.isEditMode) {
        // default modal is for create
        const { modalBodyProps : { modalAction = 'create' } = {} } = this.state;

        let confirmModalProps = {
          // ..._.get(props, 'confirmModalProps', 
            ..._.get(clinicTabKeyMap, `${selectedTab}.confirmModalProps.${modalAction}`, 
              {})
        };

        const {
          content = 'You have unsaved changes. Are you sure to leave?',
          okText = 'Keep editing',
          cancelText = 'Leave anyway'
        } = confirmModalProps;

        return Modal.confirm({
          className: 'clinic-confirm-modal',
          content,
          okText,
          cancelText,
          icon: '',
          onCancel: () => resetStates(false), // leave without refetch
        })
      }

      resetStates();
    }

    renderAddModal = currentTab => {
      const ModalContainer = modalHelper.addModalContainer(currentTab, this);
      return  ModalContainer;
    }

    renderTable = currentTab => {
      const { addModalVisible, isEditMode } = this.state;
      const { 
        contentListLength, loadingFetchUserList,
          careTeamList,loadingTeamList,teamListPageInfo,fetchTeamList
      } = this.props;

      let ViewComponent;
      let isLoadingList = false;
      let hasValue = false;
      switch(currentTab){
        case 'CARE_TEAM':
          ViewComponent = CareTeamContainerWrapper;
          isLoadingList = loadingTeamList;
          hasValue = careTeamList.length;
          break;
        default: 
          ViewComponent = EditTeamContainer;
          isLoadingList = loadingFetchUserList;
          hasValue = contentListLength;
          break;
      }
      const orgRoles = _.get(this.props, 'organization.roles', []);
      const selectedRoles = _.get(clinicTabKeyMap, `${currentTab}.roles`, []);
      const roles = helper.getRefIDWithRoleCategory(selectedRoles, orgRoles);
      return isLoadingList || hasValue ?
        <ViewComponent 
          {...this.props} 
          roles={roles}
          key={currentTab}
          curTab={currentTab}
          addModalVisible={addModalVisible}
          isEditMode={isEditMode}
          setEditMode={this.setEditMode}
          openAddModal={this.openAddModal}
          updateNeedRefetchTeamList={this.updateNeedRefetchTeamList}
          setPage={this.props.setPage}
          setOrder={this.props.setOrder}
          setFilter={this.props.setFilter}
          filter={this.props.filter}
          loading={isLoadingList}
          careTeamList={careTeamList}
          loadingTeamList={loadingTeamList}
          fetchTeamList={fetchTeamList}
          teamListPageInfo={teamListPageInfo}
        />
        :
          <EmptyClinicListPlaceholder
            {...clinicTabKeyMap[currentTab].emptyPlaceholder}
            openAddModal={this.openAddModal}
          />;
    }

    render() {
       const isLeaf =  helper.checkIsCurrentOrganizationLeaf();
        return (
            <div style={{ margin: '37px 41px 37px 30px' }}>
                <Tabs
                  defaultActiveKey={this.props.selectedTab || 'CLINIC_MEMBER'}
                  tabBarExtraContent={this.createBarExtraContent(isLeaf)}
                  onChange={selectedTab => {
                      this.props.setPage(1);
                      this.props.setSelectedTab(selectedTab);
                      this.props.setFilter({ });
                  }}
                >
                  {
                    _.map(_.keys(clinicTabKeyMap), k =>
                      <TabPane key={k} tab={clinicTabKeyMap[k].tabName}>
                        {
                          <div>
                            {this.renderTable(k)}
                            {this.renderAddModal(k)}
                          </div>
                        }
                      </TabPane>)
                  }
                </Tabs>
            </div>
        )
    }
}

const fetchUserList = graphql(query.userListWithOnlyRoles, {
  options: props => {
    const { page,sort,filter } = props;
    const orgId = _.get(props, 'organization.id', []);
    const selectedTab = _.get(props,'selectedTab');
    const roleName = _.get(filter,'role.0') ? _.get(filter,'role') : clinicTabKeyMap[selectedTab].roleName;

    return {
      variables: {
        count: 10,
        page,
        filters: { roleName,rootId:orgId },
        sort
      }
    };
  },
  props: ({ ownProps, data }) => {
    const userListData = _.get(data, 'userList.data', []);
    var userList = [];
    if(data.loading) {
        return {
            loadingFetchUserList: data.loading,
        }
    }
    userListData.map(u => {
      // should filter the correct role
      const user = {...u};
      const allRoles = _.get(u, 'allRoles', []);
      const role = _.get(_.filter(allRoles, r => _.get(r, 'organization.id') === _.get(ownProps, 'organization.id')), '0');
      _.set(user, 'role', role);
      // if(_.includes( _.get(clinicTabKeyMap, `${ownProps.selectedTab}.roles`, []) ,_.get(role, 'name'))){
        userList.push(new UserClass(user));
      // }
    })

    return {
      loadingFetchUserList: data.loading,
      userList:userList,
      userListPageInfo: _.get(data, 'userList.pageInfo', { total: 0, lastPage: 0 }),
      contentListLength: _.get(data, 'userList.data.length', 0),
      refetchList: data.refetch
    }
  }
});

const fetchCareTeamList = graphql(teamList, {
  options: ownProps => {
    return {
      variables: {
        filters: {
          organizationId: _.get(ownProps, 'organization.id')
        },
        count: MAX_TEAM_COUNT,
        sort: {
          field: 'CREATED_AT',
          direction: 'DESC'
        }
      },
      ...ownProps.needRefetchTeamList && {
        // trigger refetch when edit role or remove member
        fetchPolicy: 'network-only'
      }
    };
  },
  props: ({ ownProps, data }) => {
    if(data.error) {
      return console.error('Failed to fetch care team list ', data.error);
    }
    if(data.loading) {
        return {
            loadingCareTeamList: data.loading
        }
    }
    return {
      loadingCareTeamList: data.loading,
      careTeamList: _.get(data, 'teamList.data', []),
      careTeamPageInfo: _.get(data, 'teamList.pageInfo', {}),
      contentListLength: _.get(data, 'teamList.data.length', 0),
      refetchList: data.refetch
    };
  }
});

export default compose(
  withState('isEditMode', 'setEditMode', false),
  withState('needRefetch', 'setNeedRefetch', false),
  withState('needRefetchTeamList', 'setNeedRefetchTeamList', false),
  withState('page','setPage',1),
  withState('filter','setFilter',{}),
  withState('sort','setOrder',{ field:'LAST_NAME',direction:'ASC' }),
  withState('teamPage','setTeamPage',1),
  connect(null, dispatch => ({
    openConfirmModal: props => dispatch(ModalActions.confirm(props))
  })),
  branch(
    props => _.get(clinicTabKeyMap, `${props.selectedTab}.roles.length`),
    compose(fetchUserList),
    // compose(fetchCareTeamList)
  )
)(ClinicMemberContainer);