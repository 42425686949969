import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';


const ProviderListOptionTemplate = props => {
    return props.hasNoResult ?
        <Row>
            <Col>No provider found</Col>
        </Row>
        :
        <Row>
            <Col span={2}>
              {props.avatar}
            </Col>
            <Col span={5} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                {props.fullName}
            </Col>
            <Col span={2} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                {props.roles}
            </Col>
            <Col span={8} style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                {props.sites}
            </Col>
            <Col span={3}>
                {props.startDate}
            </Col>
            <Col span={2}>
                {props.alreadyInClinic && <span>(Already in this clinic)</span>}
            </Col>
        </Row>;
};

// title
ProviderListOptionTemplate.defaultProps = {
    avatar: '',
    fullName: 'Name',
    roles: 'Role',
    sites: 'Site',
    startDate: 'Start Date',
};

ProviderListOptionTemplate.propTypes = {
    hasNoResult: PropTypes.bool
};

export default ProviderListOptionTemplate;