import {Program, Task} from './Program'
import {Device, Team, ClinicGoalSchema} from './common'
import {UserMini} from './User'

const EnrolledProgramHistory = `
  note
  date
`

const EnrolledProgram = `
  id
  createdAt
  updatedAt
  startDate
  status
  qrcode
  endDate
  adminProgram {
    ${Program}
  }
  clinicGoals{
    ${ClinicGoalSchema}
  }
  behavioralGoals{
    type
    category
    value
  }
  extraNote
  assessmentsFlag
  goalsFlag
  healthCondition
  name
  description
  tasks {
    ${Task}
  }
  duration {
    length
    calendar
  }
  user {
    ${UserMini}
  }
  devices {
    ${Device}
  }
  forms {
    type
    uri
    date
  }
  team{
    ${Team}
  }
  organization {
    id
  }
  careCoordination
  followupSchedule
  problemList
  assessments
  interventions
  goals
  resource
  manualInputEnable
  deviceInputEnabledTaskTypes
  manualInputEnabledTaskTypes
  dischargedDetail{
    key
      description
      dischargedBy{
        profile{
          fullName
        }
      }
      dischargedAt
      note
  }
`

const EnrolledProgramForPatient = `
  id
  createdAt
  updatedAt
  startDate
  status
  qrcode
  endDate
  healthCondition
  name
  description
  tasks {
    ${Task}
  }
  duration {
    length
    calendar
  }
  user {
    ${UserMini}
  }
  devices {
    ${Device}
  }
  team{
    ${Team}
  }
`

const EnrolledProgramForVendor = `
  id
  createdAt
  updatedAt
  startDate
  status
  qrcode
  endDate
  healthCondition
  name
  description
  duration {
    length
    calendar
  }
  user {
    ${UserMini}
  }
  devices {
    ${Device}
  }
`

export {
  EnrolledProgram,
  EnrolledProgramForPatient,
  EnrolledProgramForVendor
}
