import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHButton, _ , IHTooltip} from 'ihcomponent';
import UserClass from 'modulesAll/graphql/class/User'
import { goPath } from 'libModule/utils'
import PatientStarModal from 'modulesAll/common/containers/PatientStarModal'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

export default class TaggedPatientListComponent extends React.Component {
  static displayName = 'TaggedPatientListComponent'

  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  getTableProps(){
    const { onTableChange, page, showPatientStarModal, loading } = this.props;
    const list = _.get(this.props.list, 'data')

    return {
      title : 'Tagged Patient List',
      rowKey : (doc)=> doc.user.key,
      onChange: onTableChange,
      loading,
      dataSource : _.map(list, (l)=>{
        return {
          user : new UserClass(l.user),
          o : l
        }
      }),
      onRow: doc => {
        return {
          onClick: () => {
            const patientNRIC = doc.user._get('identification[0].value')
            const patientName =  doc.user._get('profile.fullName')
            createAuditLog({
              action: I18N.get('auditLog.provider.taggedpatients.viewTaggedPatient'),
              patientNRIC,
              patientName
            })

            goPath(`/patients/${doc.user._get('id')}/enrolledprogram/default`)
          }
        }
      },
      columns : [
        {
          key : 'Star',
          width : '10%',
          render : (t, doc) => {
            const star = doc.user.format('star')
            if(star.status){
              const click = (e)=>{
                e.stopPropagation()
                showPatientStarModal(true, 'delete', doc.user.variables)
              }
              return (
                <IHTooltip overlayClassName="vsm-tooltip" title='Click to untag patient' placement="right">
                  <a onClick={click} className="v-star v-y"><i className="fa fa-star" /></a>
                </IHTooltip>
              )
            }
          }
        },
        {
          title : 'Name',
          key : 'Name',
          render : (t, doc)=>{
            return doc.user._get('profile.fullName')
          }
        },
        {
          title : I18N.get('keywords.NRIC/FIN'),
          key : 'NRIC',
          render : (t, doc)=>{
            return doc.user.format('NRIC')
          }
        },
        // {
        //   title : 'Unhanded Alerts',
        //   key : 'Alerts',
        //   render : (t, doc) => doc.o.alerts
        // }
      ],
      // pagination: {
      //   current: _.get(page, 'current', 1),
      //   pageSize: _.get(page, 'pageSize', 5),
      //   total: _.get(this.props.list, 'pageInfo.total', 0),
      // },
    }
  }

  render() {
    const noDataElem = (
      <div style={{ height: '150px', paddingTop: '2em' }}>
        <p style={{ fontSize: '18px' }}>There is no patient tagged!</p>
      </div>
    )

    return (
      <div>
        <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} style={{height: '28em'}} />
        <div className="v-c-bd">
          <IHButton type="primary" label="See All Tagged Patients" onClick={()=>{
            goPath('/tagged_patients')
          }} />
        </div>
        <PatientStarModal user={this.props.user} refetch={async () => {
          await this.props.refetch()
          this.props.onTableChange({ pageSize: 5, current: 1 })
          // After unstar a patient, the current page might not be valid
        }} />
      </div>
    )
  }
}

TaggedPatientListComponent.propTypes = {
  onLoadParams: PropTypes.func,
  list: PropTypes.object,
  onTableChange: PropTypes.func,
  page: PropTypes.object,
  showPatientStarModal: PropTypes.func,
  user: PropTypes.object
}
