import React from 'react';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import ReportsTableContainer from './ReportsTableContainer';
import { getSummaryReports, getInitialReports, getReportsCount } from '../helpers';
import _ from 'lodash';
import { ROLE_MAP } from '../../../lib/constants';
const { TabPane } = Tabs;

class ReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: this.getUserRole(),
      loadingCounts: false,
      page:1,
      pageSize:10,
      sortDirection:'DESC',
      filters: null,
    };
  }

  componentDidMount() {
    this.getCounts();
  }

  setFetchArgs = (args)=>{
    this.setState({
      ...args
    })
  }

  getUserRole() {
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(viewerInfo, 'selectedRole.name');
    return ROLE_MAP[selectedRole];
  }

  getCounts = async () => {
    this.setState({ loadingCounts: true });
    try {
      const [monthlyBillingReportsCount, initialReportsCount] = await Promise.all([getReportsCount("summary"), getReportsCount("initial")]);
      this.setState({ monthlyBillingReportsCount, initialReportsCount, loadingCounts: false });
    } catch (e) {
      console.log(err);
      this.setState({ loadingCounts: false });
      this.props.openErrorModal(err.message || 'Something went wrong getting count!');
    }
  }

  getCount = (tabKey) => {
    return this.state.loadingCounts ? 'Loading...' : _.get(this.state, `${tabKey}Count`);
  }

  render() {
    const { role,page,pageSize,sortDirection, filters } = this.state;
    const { openErrorModal } = this.props;
    const { getCounts } = this;
    return (
      <div style={{ width: '100%', height: '100%', padding: 20 }}>
        <Tabs defaultActiveKey="monthlyBillingReports">
            <TabPane tab={<div>Monthly Billing Reports ({this.getCount("monthlyBillingReports")})</div>} key="monthlyBillingReports">
              <ReportsTableContainer 
                openErrorModal={openErrorModal}
                getReports={getSummaryReports}
                type="Monthly"
                userRole={role}
                page={page}
                getCounts={getCounts}
                pageSize={pageSize}
                sortDirection={sortDirection}
                filters={filters}
                setFetchArgs={this.setFetchArgs}
              />
            </TabPane>
            <TabPane tab={<div>Initial Reports ({this.getCount("initialReports")})</div>} key="initialReports">
              <ReportsTableContainer
                openErrorModal={openErrorModal}
                getReports={getInitialReports}
                type="Initial"
                userRole={role}
                getCounts={getCounts}
                page={page}
                pageSize={pageSize}
                sortDirection={sortDirection}
                filters={filters}
                setFetchArgs={this.setFetchArgs}
              />
            </TabPane>
          </Tabs>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
  };
}
export default connect(null, mapDispatch)(ReportsContainer);
