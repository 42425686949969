import { get } from 'lodash';
import { ROLE_MAP } from '../../../lib/constants';

export function getIframeSrc(options) {
  const {url, parameters, locale, footerPaddingEnabled} = options;
  let src = url + '&punyCodeEmbedOrigin=' + window.location.origin + '/';

  if (locale) {
      src = src + '&locale=' + locale;
  }

  if (footerPaddingEnabled) {
      src = src + '&footerPaddingEnabled=' + String(footerPaddingEnabled);
  }

  if (parameters) {
      return useParameterValuesInUrl(src, parameters);
  }

  return src;
}

function useParameterValuesInUrl(url, parameters) {
  const parameterNames = Object.keys(parameters);
  const parameterStrings = parameterNames.map(name => {
      const value = parameters[name];
      const values = [].concat(value);
      const encodedName = encodeURIComponent(name);
      return values.map(paramValue => encodeURIComponent(paramValue))
          .map(encodedValue => `p.${encodedName}=${encodedValue}`)
          .join('&');
  });

  return `${url}#${parameterStrings.join('&')}`;
}

export function getUserRole() {
  const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
  const selectedRole = get(viewerInfo, 'selectedRole.name');
  return ROLE_MAP[selectedRole];
}

export function getOrganizationInfo() {
  const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
  let { id: organizationId, name: organizationName } = viewerInfo.selectedRole.organization;
  const params = {}

  organizationId = atob(organizationId).split(':')[1];
  if (organizationId.trim() !== '') {
    params.organizationId = organizationId;
  }

  if (organizationName.trim() !== '') {
    params.organizationName = organizationName;
  }

  return params;
}

export function getRole() {
  const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
  const roleName = viewerInfo.selectedRole.name;
  return roleMap[roleName];
}