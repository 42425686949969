import React from 'react';
import { Modal, Row } from 'antd';
import AddNewClearComponent from './AddNewClearComponent';
import UpdateClearConfigComponent from './UpdateClearConfigComponent';
import UnassignComponent from './UnassignComponent';
import API from '../../../patient/DevicesAssign/query/API';
import { compose } from 'react-apollo/index';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import './style.scss';

class ClearAssignmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reverseToAsc: false,
    };
  }

  setReverseToAsc = reverseToAsc => this.setState({ reverseToAsc });

  renderIfNoDevice = () => {
    return <AddNewClearComponent
      {...this.props}
      updateIoTDeviceMemberAPI={API.updateIoTDeviceMemberAPI}
      editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
      reverseToAsc={this.state.reverseToAsc}
      setReverseToAsc={this.setReverseToAsc}
    />;
  }

  renderUpdateOrUnassign = () => this.props.isUpdate ? this.renderUpdate() : this.renderUnassign();

  renderUpdate = () => {
    const groupedDevice = _.groupBy(this.props.iotDeviceData, 'deviceId');
    const devices = Object.keys(groupedDevice);
    return <div className='update-clear-device'>
      <div className='header'>Update Patient iHealth Clear Device</div>
      {
        _.map(devices,(deviceId,i)=> {
          const deviceObj = _.first(groupedDevice[deviceId]);
          const config = _.get(deviceObj,'config') || {};
          return <Row key={i}>
            <UpdateClearConfigComponent 
              {...this.props}
              config={config}
              editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
            />
          </Row>
        })
      }
    </div>
  };

  renderUnassign = () => {
    const { user, iotDeviceData, onUnassign, onCancel, openErrorModal } = this.props;

    const onClick = () => {
      const ids = _.uniq(_.map(iotDeviceData, 'deviceId'));
      const deviceId = _.first(ids);
      if(!deviceId)
        return;

      API.removeIotDeviceMemberAPI({
        deviceId
      }, _.get(user, 'id')).then(res => {
        if (onUnassign) onUnassign(res);
      }).catch(err => {
        openErrorModal(err);
      });
    };

    return <UnassignComponent onClick={onClick} onCancel={onCancel} />;
  };

  render() {
    const { iotDeviceData, onCancel } = this.props;

    return <Modal
      className='device-assignment-popup'
      width={469}
      visible={true}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
    >
      {
        iotDeviceData.length === 0 ?
          <React.Fragment>
            <div className='header'>Assign Patient iHealth Clear Device</div>
            <div className='sub-header'>To assign device, please confirm the device ID and patient’s language below.</div>
            {this.renderIfNoDevice()}
          </React.Fragment>
          : this.renderUpdateOrUnassign()
      }
    </Modal>;
  }
}

const mapToDispatch = (dispatch) => {
  return {
    openErrorModal: err => dispatch(openErrorModal(GQLHelper.formatError(err))),
  }
};

export default compose(connect(null, mapToDispatch))(ClearAssignmentComponent);