import React from 'react';
import VitalsDistribution from '../components/VitalsDistribution';

class VitalsDistributionContainer extends React.Component {
  render() {
    const { organizationName, organizationId, openErrorModal } = this.props;

    return (
      <VitalsDistribution organizationName={organizationName} organizationId={organizationId} openErrorModal={openErrorModal} />
    );
  }
}

export default VitalsDistributionContainer;