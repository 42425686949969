// Constants
import { constants } from 'libModule/utils';
// Action Creators
import { commonActions } from 'libModule/helpers/action-helpers'
// Reducer
import { actionTypes as formTypes } from './AlertFormDuck';
import cloneDeep from 'lodash/cloneDeep';
/*
**************************************************
  Constants - Action Types
**************************************************
*/
// TODO: change the path after moving into Careplan or Careplan/Vital
const actionTypes = constants('patient/VitalAlert', 'AlertList', [
  'FORM_FIELD_CHANGE',
  'INIT_DATA',
  'RESET_FORM',
  'RESET',
  'TOGGLE_ALERT_FORM',
  'ADD_TAB',
  'REMOVE_TAB',
  'ON_TAB_CHANGE',
]);

export { actionTypes };
/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = commonActions(actionTypes, [
  'reset',
  'resetForm',
  'updateForm',
  'onFieldsChange'
]);

actionCreators.toggleAlertForm = (isShowAlertForm=true) => {
  return {
    type: actionTypes.TOGGLE_ALERT_FORM,
    isShowAlertForm
  };
};

actionCreators.addTab = (panes, activeKey)=> {
  return {
    type : actionTypes.ADD_TAB,
    panes,
    activeKey
  };
};

actionCreators.removeTab = (activeKey)=> {
  return {
    type : actionTypes.REMOVE_TAB,
    activeKey
  };
};

actionCreators.onTabChange = (activeKey)=> {
  return {
    type : actionTypes.ON_TAB_CHANGE,
    activeKey
  };
};

export { actionCreators };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  panes: [],
  activeKey: '',
  isShowAlertForm: false,
  panesFieldsValue: {}
};

export default (state = initState, action) => {
  switch(action.type) {
    case actionTypes.RESET:
      return initState;
    case actionTypes.INIT_DATA:
      return {
        ...state,
        ...action.initFormData
      };
    case actionTypes.TOGGLE_ALERT_FORM:
      return {
        ...state,
        isShowAlertForm: action.isShowAlertForm
      };
    case formTypes.FORM_FIELD_CHANGE: {
      const activeKey = state.activeKey;
      const currentPaneFieldsValue = cloneDeep(state.panesFieldsValue[activeKey]);
      const newFieldsValue = {
        [activeKey]: {
          ...currentPaneFieldsValue,
          ...cloneDeep(action.fieldsValue)
        }
      };
      return {
        ...state,
        panesFieldsValue: {
          ...state.panesFieldsValue,
          ...newFieldsValue
        },
      };
    }
    case actionTypes.ON_TAB_CHANGE:
      return {
        ...state,
        activeKey: action.activeKey
      };
    case actionTypes.ADD_TAB:
      return {
        ...state,
        panes: action.panes,
        activeKey: action.activeKey
      };
    case actionTypes.REMOVE_TAB:
      let activeKey = state.activeKey;
      let isShowAlertForm = state.isShowAlertForm;
      let lastIndex;
      const currentPanes = state.panes;
      const panes = currentPanes.filter(pane => pane.key !== activeKey);
      // no tab, reset state
      if (currentPanes.length <= 1) {
        activeKey = undefined
        isShowAlertForm = false
      } else {
        currentPanes.forEach((pane, i) => {
          if (pane.key === activeKey) {
            // tab maybe the first one
            lastIndex = i < 1 ? 0 : i - 1
            activeKey = panes[lastIndex].key
          }
        })
      }
      const panesFieldsValue = cloneDeep(state.panesFieldsValue);
      delete panesFieldsValue[state.activeKey];
      return {
        ...state,
        activeKey,
        panes,
        isShowAlertForm,
        panesFieldsValue
      };
    default:
      return state;
  }
};
