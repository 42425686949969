import React from 'react';
import {Popover,Row,Col,Icon} from "antd";
import CreateConsentComponent from "./CreateConsentComponent";
import PreviewConsentComponent from "./PreviewConsentComponent";
import API from "../../API/index";
const LanguageSetupComponent = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visibility: { },
            selectedTemplate: null
        }
    }


    renderPreviewBtn = (c)=>{
        const { visibility,selectedTemplate } = this.state;
        const { preview } =  visibility;
        const visible = preview && (preview && c.id == _.get(selectedTemplate, 'id'));
        const previewBtn = <span onClick={()=>this.setState({'visibility': { preview:c.id },selectedTemplate:c})}><img src='/image/icon_preview.svg'/></span>

        return visible ? <Popover overlayStyle={{ width:700 }}
                        overlayClassName={'previewConsent'}
                        content={<PreviewConsentComponent template={selectedTemplate}/>}
                        trigger={'click'}
                        visible={ visible }
                        onVisibleChange={()=> {
                                if (preview && selectedTemplate) {
                                    this.setState({'visibility':{  },selectedTemplate: null})
                                }
                            }
                        }
               >{previewBtn}
               </Popover> : previewBtn
    }

    handleVisibleChange = (key,value,selectedTemplate) => {
        this.setState({ [key]:value,selectedTemplate });
    }

    renderCreateConsent = (btnTest)=>{
        const { handleVisibleChange } = this;
        const { refetchConsentTemplateList,id,selectedLanguages } = this.props;
        const { visibility,selectedTemplate,edit } = this.state;
        const { create } = visibility ;
        const createBtb = <span onClick={()=>this.handleVisibleChange('visibility',{create:true})}>{btnTest}</span>

        return create ? <Popover visible={!!create}
                        overlayStyle={{ width:'fit-content' }}
                        content={<CreateConsentComponent selectedLanguages={ selectedLanguages }
                                                         type={'visible'}
                                                         template={selectedTemplate}
                                                         id={id}
                                                         handleVisibleChange={handleVisibleChange}
                                                         refetchConsentTemplateList={refetchConsentTemplateList}
                        />}
                        trigger={'click'}>
                        {createBtb}
               </Popover> : createBtb;
    }

    renderEditConsent = (btnTest,c)=> {
        const {handleVisibleChange} = this;
        const {refetchConsentTemplateList, id, selectedLanguages} = this.props;
        const {selectedTemplate, visibility} = this.state;
        const {edit} = visibility;
        const visible = edit && (edit && c.id == _.get(selectedTemplate, 'id'));
        const editBtn = <span onClick={() => this.setState({'visibility': {edit: c.id}, selectedTemplate: c})}><Icon
            type="edit"/></span>

        return visible ? <Popover visible={visible}
                                  overlayStyle={{width: 'fit-content'}}
                                  content={<CreateConsentComponent selectedLanguages={selectedLanguages}
                                                                   type={'edit'}
                                                                   template={selectedTemplate} id={id}
                                                                   handleVisibleChange={handleVisibleChange}
                                                                   refetchConsentTemplateList={refetchConsentTemplateList}/>}
                                  trigger={'click'}
        >
            {editBtn}
        </Popover> : editBtn
        }


    renderRemoveBtn = (c)=>{
        const { refetchConsentTemplateList,id } = this.props;
        const onClick = ()=>API.removeConsentTemplate({id:c.id}).then(res=>refetchConsentTemplateList&&refetchConsentTemplateList());
        return<Icon type="close" onClick={onClick}/>
    }

    templateButtons = (c)=>{
        return <Row className={'item'} key={c.language.description}>
                <Col span={5}>{c.language.description}</Col>
                <Col span={3}>{ this.renderPreviewBtn(c) }</Col>
                <Col span={14}>{ this.renderEditConsent('Edit',c)}</Col>
                <Col span={2}>{this.renderRemoveBtn(c)}</Col>
               </Row>
    }

    renderTemplateList = ()=>{
        const { consentTemplateList } = this.props;
        return <div className={'templateButtons'}>{_.map(consentTemplateList,c=>{
            return this.templateButtons(c);
        })}</div>

    }

    render() {
        const { renderCreateConsent,renderTemplateList,renderPreview } = this;
        return (
            <div>
                { renderCreateConsent('Add Language') }
                { renderTemplateList() }
            </div>
        );
    }

}

export default LanguageSetupComponent