import {_} from 'ihcomponent'
import type from '../constants'

export default {
    changeSelectDevice(v){
        return {
            type : type.CHANGE_SELECT_DEVICE,
            value : v
        }
    },

    changeSelectDeviceTableStatusReason : (v, docId)=>(dispatch, getState)=>{
        const value = getState().patient.device.deviceTableValue
        value[docId].reason = v

        const ll = getState().patient.device.deviceTableStatusReasonSelectList
        const f = _.find(ll, (n)=>n.value===v)

        if(f.modal){
            value[docId].modal = ''
        }
        else{
            value[docId].modal = false
        }

        dispatch({
            type : type.CHANGE_SELECT_DEVICE_TABLE_STATUS_REASON,
            value : value
        })

    },

    changeSelectTableSelectedRows : (list)=>(dispatch, getState)=>{
        const value = getState().patient.device.deviceTableValue
        _.each(value, (v)=>{
            v.select = false
        })
        _.each(list, (v)=>{
            value[v].select = true
        })

        dispatch({
            type : type.CHANGE_DEVICE_TABLE_SELECTED_ROWS,
            value
        })
    },

    changeDeviceTableStatusReasonModal : (v, docId)=>(dispatch, getState)=>{
        const value = getState().patient.device.deviceTableValue
        value[docId].reason = v

        dispatch({
            type : type.CHANGE_DEVICE_TABLE_STATUS_REASON_MODAL,
            value : value
        })
    },

    changeHomeSetup : (flag)=>{
        return {
            type : type.IS_HOME_SETUP,
            value : flag
        }
    },

    changeCurrentStep : (step)=>{
        return {
            type : type.CHANGE_DEVICE_CURRENT_STEP,
            value : step
        }
    },

    setUploadFile : (file)=>{
        return {
            type : type.SET_UPLOAD_FILE,
            file
        }
    },

    setInitDeviceData : (table, value)=>{
      return {
        type : type.SET_INIT_DEVICE,
        table,
        value
      }
    }
}
