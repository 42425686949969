import React from 'react';
import TaskAssignmentBannerContainer from '../containers/TaskAssignmentBannerContainer';
import moment from "moment/moment";
import {  notification } from 'antd';

const taskMap = {
    'NEW':{
        name:'newTask',
        variables:()=>{
            const currentUserId = sessionStorage.getItem('currentUserId');

            const variables = {
                filters: {
                    fromDate:moment().subtract(15,'minute').valueOf(),
                    assignedTo:[currentUserId],
                    notAssignedByUser: true
                },
            }
            return variables;
        },
        shouldRender:()=>true,
        shouldSetInterval: true,
        className:'newTaskNotification',
        render: notification.info

    },
    'DUE':{
        name:'dueToday',
        variables:()=>{
            const currentUserId = sessionStorage.getItem('currentUserId');
            const variables = {
                filters: {
                    dueFromDate:moment().startOf('day').valueOf(),
                    dueToDate:moment().endOf('day').valueOf(),
                    assignedTo:[currentUserId],
                },
            }
            return variables;
        },
        shouldRender:()=>{
            const now = moment();
            const lastLogIn = moment(Number(sessionStorage.getItem('lastLogIn')));

            const isOver12Hours  = now.diff(lastLogIn,'hours') >= 12;
            return isOver12Hours;
        },
        shouldSetInterval:false,
        className:'dueToday',
        render: notification.warning
    },
    'OVERDUE': {
        name:'overdue',
        variables: () => {
            const currentUserId = sessionStorage.getItem('currentUserId');

            const variables = {
                filters: {
                    dueToDate: moment().valueOf(),
                    assignedTo: [currentUserId],
                },
            }
            return variables;
        },
        shouldRender: () => {
            const now = moment();
            const lastLogIn = moment(Number(sessionStorage.getItem('lastLogIn')));
            const isOver12Hours = now.diff(lastLogIn, 'hours') >= 12;
            return isOver12Hours;
        },
        shouldSetInterval: false,
        className:'overdue',
        render: notification.warning
    }
}

const  TaskAssignmentBannerWrapper = class extends React.Component {
       constructor(){
           super();
       }

       shouldComponentUpdate(){
        return false;
       }

        render(){
           const res = [];

            _.forEach(taskMap,({variables,shouldRender,name,shouldSetInterval,className,render},k)=>shouldRender() ? res.push(<TaskAssignmentBannerContainer className={className} render={render}
                                                                                                                           shouldSetInterval={shouldSetInterval} name={name} key={k} variables={variables} />)
                                                                                                    :'')
           return <div>{res}</div>;
        }
}




 export default (TaskAssignmentBannerWrapper);
