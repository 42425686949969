import { useState, useEffect, useRef } from 'react';
import getPatientArticles from '../API/getPatientArticles.js';

export default (
  patientId, 
  {
    sendOnMount = true
  } = {},
) => {
  const initialRender = useRef(true);
  const [isLoadingPatientArticles, setIsLoadingPatientArticles] = useState(false);
  const [patientArticles, setPatientArticles] = useState([]);

  const loadAndSetPatientArticles = async () => {
    if (!patientId || isLoadingPatientArticles) return;

    setIsLoadingPatientArticles(true);
    try {
      const res = await getPatientArticles({ patientId });
      if (res && res.code !== 200) {
        throw new Error('Server error: ', _.get(res, 'msg'));
      }
      const _patientArticles = _.get(res, 'data.content') || [];
      setPatientArticles(_patientArticles);
    } catch (error) {
      console.error('Failed to load patient articles, ', error);
    }
    setIsLoadingPatientArticles(false);
  };

  useEffect(() => { 
    if (!sendOnMount && initialRender.current) 
      initialRender.current = false;
    else
      loadAndSetPatientArticles() 
  }, [patientId, sendOnMount]);

  return {
    patientArticles,
    refetchPatientArticles: loadAndSetPatientArticles,
    isLoadingPatientArticles,
  };
};