import {React, _, moment} from 'ihcomponent'
import DevicesList from '../components/DevicesList'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import {openModal, closeModal, openErrorModal} from 'layoutModule/actions/MainModal';
import { commonReplaceReturnDevicesActionsObject, commonReplaceReturnDevicesFormActionsObject } from '../actions/ReplaceReturnDevicesActions';
import RequestCache from 'libModule/requestCache'
import { getReplaceDeviceModalProps } from 'modulesAll/vendor/patient/components/DevicesReplacementScanQRCode';
import { GQLHelper, getQRCodeFromString, FormHelper } from 'libModule/utils';
import returnDevices from 'modulesAll/graphql/returnDevices';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N';
import { getPreviousKeyInDevicesInfo, REPLACE_OR_RETURN } from 'modulesAll/vendor/patient/common/utils';


class DevicesListContainer extends React.Component {
  static displayName = 'patient/enrolledProgramInfo/containers/DevicesListContainer'

  static propTypes = {
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    handleFormSaved: PropTypes.func,
    resetForm: PropTypes.func,
    setSubmitting: PropTypes.func,
    fieldsValue: PropTypes.object,
    patientInfo: PropTypes.object,
    data: PropTypes.object,
    client: PropTypes.object,
    resetDevicesTab: PropTypes.func,
    setReturnDevicesStep: PropTypes.func,
    openErrorModal: PropTypes.func,
    refetchEnrolledProgramList: PropTypes.func,
    setReplaceDevicesStep: PropTypes.func,
  }
  openReplaceDevicesModal = (qrcode) => {
      const p = getReplaceDeviceModalProps(qrcode, this.props.closeModal)
      this.props.openModal(p.body, p)
  }

  processReplaceOrReturnDevicesAsync = async (epId, results, isDischarged) => {
    const { handleFormSaved, resetForm, setSubmitting, fieldsValue } = this.props;
    const currentPatient = this.props.patientInfo
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

     setSubmitting(true);
     await handleFormSaved();

     const formValue = FormHelper.getValue(fieldsValue);
     const returnee = isDischarged ? { /** return devices **/

                returnedBy: formValue.returnedBy,
                returnedDate: formValue.returnedDateAndTime.toDate(),
                receivedBy: formValue.processedBy,
                receivedDate: formValue.processedDateAndTime.toDate()
      } :
      { /** replace devices **/
                replacedBy: formValue.replacedBy,
                replacedDate: formValue.replacedDateAndTime.toDate()
      }
     const variables = {
       id: epId,
       returnee
     };
     results.map( e => {
       variables[e.type] = {
         //condition : e.select,
         address : e.address,
         //remark: e.remark
       }
     });
     let auditLogDetails = _.cloneDeep(variables)
     delete auditLogDetails['id']
     auditLogDetails.programName = this.props.data.name
     auditLogDetails.returnee = isDischarged ? { /** return devices **/
                returnedBy: formValue.returnedBy,
                returnedDate: formValue.returnedDateAndTime.format('MMM-DD-YYYY hh:mm a:ss'),
                receivedBy: formValue.processedBy,
                receivedDate: formValue.processedDateAndTime.format('MMM-DD-YYYY hh:mm a:ss')
      } :
      { /** replace devices **/
                replacedBy: formValue.replacedBy,
                replacedDate: moment(formValue.replacedDateAndTime).format('MMM-DD-YYYY hh:mm a:ss')
      }
      auditLogDetails = _.mapKeys(auditLogDetails, (value, key) => {
        switch(key) {
          case 'BG':
            return 'blood glucose'
          case 'BP':
            return 'blood pressure'
          case 'HR':
            return 'heart rate'
          case 'HS':
            return 'weight'
          case 'TABLET':
            return 'tablet'
          default:
            return key
        }
      })

     try{
      const res = await this.props.client.mutate({
        mutation: returnDevices,
        variables
      });

      if (isDischarged) {
        createAuditLog({
          action: I18N.get('auditLog.vendor.returnDevices'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: res
        })
      }
      else {
        createAuditLog({
          action: I18N.get('auditLog.vendor.replaceDevices'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: res
        })
      }

      //if the EP is not discharged, it’s replace devices. if it’s discharged, it’s returned devices button.
      if (!isDischarged) {
        const qrcode = _.get(res,'data.returnDevices.qrcode');

        /*** for case after replace devices, then scan qr code, new set of devices given to user, then on the same page, user re-submit replace devices with old device address.
             result, new devices address are shown but read only mode, replaceReturn step becomes 1
        **/
        const initDevices = getPreviousKeyInDevicesInfo( _.get(res,'data.returnDevices'));
        if(!initDevices || initDevices.length === 0 ){
            this.props.resetDevicesTab(this.props.data.status, initDevices); //force go to step 1
        }
        else if(qrcode)
          this.openReplaceDevicesModal(getQRCodeFromString(qrcode));
        else {
          console.log('qrcode',qrcode);
        }
      }
      else {
        this.props.setReturnDevicesStep(3); //returnDevicesStep  step 1, show 'Return Devices', step 2, show 'Done', step 3, hide the button
      }
    }
    catch(err){
      if (isDischarged) {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.returnDevices'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false
        })
      }
      else {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.replaceDevices'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: err,
          success: false
        })
      }
      this.props.openErrorModal(GQLHelper.formatError(err))
    }
    finally {
      setSubmitting(false)
      if (isDischarged) {
        //only reset form if return process; for replace process stick on the edit page can press submit multiple times
        resetForm()
        this.props.refetchEnrolledProgramList();
        this.props.setReplaceDevicesStep(REPLACE_OR_RETURN.INITIAL_STATE);

      }
    }
  }


  render() {
    return <DevicesList {...this.props} openReplaceDevicesModal={this.openReplaceDevicesModal} processReplaceOrReturnDevicesAsync={this.processReplaceOrReturnDevicesAsync}/>
  }
}

export default

connect((state, ownProps) => ({
  devicesStatus: state.patient.enrolledProgramInfo.ReplaceReturnDevices.devicesStatus,
  replaceOrReturnDevicesStep: state.patient.enrolledProgramInfo.ReplaceReturnDevices.replaceOrReturnDevicesStep,
  fieldsValue: state.patient.enrolledProgramInfo.ReplaceReturnDevices.fieldsValue,
  returnDevicesStep: state.patient.enrolledProgramInfo.ReplaceReturnDevices.returnDevicesStep,
  patientInfo: RequestCache.get('nurse/currentPatient')
  }),
  {
  closeModal,
  openModal,
  openErrorModal,
  changeDeviceStatusTableInfo:  commonReplaceReturnDevicesActionsObject.changeDeviceStatusTableInfo,
  changeDeviceRemarkTableInfo: commonReplaceReturnDevicesActionsObject.changeDeviceRemarkTableInfo,
  setSubmitting: commonReplaceReturnDevicesFormActionsObject.setSubmitting,
  handleFormSaved: commonReplaceReturnDevicesFormActionsObject.handleFormSaved
})(withApollo(DevicesListContainer));
