import React from 'react';
import { Table, Tooltip,Popover, Popconfirm, message } from 'antd';
import { goPath } from 'libModule/utils'
import { getTasks, handlePatientChange,getPatientInitial } from '../helper/index'
import { taskMap } from '../constant';
import PinnedByListComponent from '../component/PinnedByListComponent';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { unpin } from "../API/index";


const PinnedPatientsComponents = class extends React.Component{
    renderTable = ()=>{
        const { loading,pinnedPatients } = this.props;
        const columns = [{
            key:'fullName',
            title:'Name',
            dataIndex:'patient.fullName',
        },{
            key:'task',
            title:'Action Items',
            dataIndex: 'task',
            render:(text,record,index)=>{
                const taskArray = getTasks(record.task).filter(x => x.count !== 0);
                return (
                    <div>
                        {_.map(taskArray, (v) =>
                            <Tooltip title={v.title}>
                                <img key={v.src} src={v.src} style={{ margin: 2, width: 20 }} />
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            key:'organization',
            title:'Clinic',
            dataIndex: 'patient.organizationName'
        },
        {
            key: "pinnedBy",
            title: 'Also Pinned By',
            dataIndex: 'pinnedBy',
            render:( pinnedBy, record, index)=>{
                return <PinnedByListComponent pinnedBy = {pinnedBy} index={index}/>
                // const names = Object.keys(pinnedBy);
                // const users = _.map(names,n=>({ fullName:n }))
                // // const defaultSrc="/image/default_avator.png";
                // const slicedUsers = users.slice(0,3);
                // let extraIcon = null;
                // let img =(u)=> <span className={'pinnedPatientAvatar circled'}>{getPatientInitial(u.fullName)}</span>;
                // const usersList  = _.map(slicedUsers,(u,i)=><Tooltip key={i} title={u.fullName}>{img(u)}</Tooltip>);
                // if(users.length>3) {
                //     let extraUsers = users.slice(3,users.length);
                //     const content = (
                //         <div className={'patientsPinnedBy'}>
                //             { _.map(extraUsers,(u,i)=><div key={i} className={'patientRow'}>{img(u)}<span>{u.fullName}</span></div>)}
                //         </div>
                //     );
                //     extraIcon = <Popover overlayClassName='extraIconPopover' content={content} key={index} overlayStyle={{width:'fit-content !important'}}>
                //                         <div className={'indicator circled'}>{`+${users.length-3}`}</div>
                //                 </Popover>
                // };
                // return  <div className={'patientsPinnedBy'} style={{flexDirection:'row'}}>
                //     { usersList }
                //     { extraIcon }
                // </div>

            },
        },
        {
          key: 'action',
          title: '',
          width: '10%',
          render: (text, record) => {
            const patientId = _.get(record, 'patient.id');
            return <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
              <div>
                <Popconfirm 
                  title='Are you sure to unpin?'
                  okText='Yes'
                  onConfirm={() => this.handleUnpin(patientId)}
                >
                  <Tooltip overlayClassName={'tool-tip-customized'} title='Unpin'>
                    <img className='pinIcon' src='/image/unpin.svg' width={13}/>
                  </Tooltip>
                </Popconfirm>
              </div>

            </div>;

          }
        }]

        const onRow=  (record, rowIndex)=> {
            return {
                onClick: event => {
                    const { patient }  = record;
                    handlePatientChange(patient,()=> goPath(`/workstation/${patient.id}`))
                }
            }
        };
        return <Table dataSource={pinnedPatients} columns={columns} pagination={true} loading={loading} onRow={onRow} scroll={{ y:330 }} style={{cursor:"pointer"}}/>
    }

    handleUnpin = (patientId) => {
      if(!patientId) return;
      Mixpanel.track('clicked', 'on_unpin_patient_icon');
      unpin(patientId).then(res=>{
        const { code,data,msg } = res;
        if(code==200) {
          this.props.refetchPinnedPatients()
          message.success(`Patient unpinned.`);
        }
        if(code==500){
          message.warning(`${msg} ${data}`)
        }
      });
    }

    render() {
        return this.renderTable()
    }
}

export default PinnedPatientsComponents;
