import ProgramMeasureTrendsComponent from './ProgramMeasureTrendsComponent';
import React, { Component } from 'react'
import * as _ from 'lodash';
 const monthMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

class MonthlyTrendsComponent extends Component{
    constructor(){
        super()
        this.monthMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        this.fullMonthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    }

    parseMonthlyData(){

        const monthMap = this.monthMap;
        let inputData = this.props.programMeasureTrends;
        return inputData.map(function(v){
            let tmp =_.pick(v.conditionCounts,['hyperCrisis','hyperStage2','hyperStage1','elevated','normal']);
            let timeStamp = new Date(parseInt(v.group));
            let month = monthMap[timeStamp.getMonth()];
            tmp.Month = month;
            tmp.total = v.total;
            tmp.group = v.group;

            return tmp
        })
    }

    getxAxisKeys(){
        let monthMap = this.monthMap;
        let inputData = this.props.programMeasureTrends;
        let dataMonths = [];

        inputData.forEach(function(v){
            let timeStamp = new Date(parseInt(v.group));
            let month = monthMap[timeStamp.getMonth()];
            dataMonths.push(month);
        });
        let newXMonth = monthMap.slice(monthMap.indexOf(dataMonths[0]),dataMonths.length).concat(monthMap);
        return newXMonth;
    }

    getChartTitle(){
        const monthMap = this.fullMonthArray;
        let parsedData = this.props.programMeasureTrends;
        let startDate = new Date(parseInt(parsedData[0].group));
        let startYear = startDate.getFullYear();
        let startMonth = monthMap[startDate.getMonth()];
        let endDate = new Date(parseInt(parsedData[parsedData.length - 1].group));
        let endYear = endDate.getFullYear();
        let endMonth = monthMap[endDate.getMonth()];

        return `${startMonth} ${startYear} - ${endMonth} ${ endYear }`;
    }

    render(){

       return(
                <ProgramMeasureTrendsComponent
                    chartTitle = { this.getChartTitle() }
                    parsedData = { this.parseMonthlyData() }
                    xAxisKeys = { this.getxAxisKeys() }
                    width={ this.props.width }
                    height={ this.props.height }
                    marginTop={ this.props.marginTop }
                    marginRight={ this.props.marginRight }
                    marginBottom = { this.props.marginBottom }
                    marginLeft = { this.props.marginLeft }
                />
       )
    }
}

export default MonthlyTrendsComponent;
