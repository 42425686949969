import React from 'react';
import {Upload,Icon} from "antd";
import helper from "../../../upload/API/helpers";
import uploadHelper from '../../../upload/API/index';
import API from "../../../upload/API";
const getBase64  = helper.getBase64;
const { sendAJaxReq } = uploadHelper;
const UploadLogoComponent = class extends React.Component{
    constructor(props) {
        super(props);
        const logo = _.get(props,'props.organizationData.logo');
        this.state ={
            loading: false,
            progress: 0,
            sendingAttachment:false,
            imageUrl:logo
        }
    }

    setProgress=(progress)=>{
        this.setState({
            progress
        })
    }

    componentDidUpdate(preProps,preState) {
        const { state: { logo } } = this.props;
        if(logo != this.state.imageUrl) {
            this.setState({
                imageUrl:logo
            })
        }
    }

    handleChange = async info => {
        const { filename, attachmentType } = this.state;
        const pathname = window.location.pathname;
        const organizationId = pathname.split('/')[2];
        const { file } = info;
        const { name,originFileObj } = file;
        let res = {};
        try{
            res = await API.getPresignedLogoUpload({ filename:name, organizationId });
            this.setState({
                loading:true
            });
        }
        catch(e){
            throw(e);
        }
        const ctx = {};
        const fields = _.isString(res.fields) ? JSON.parse(res.fields) : res.fields;
        const  mimeType = attachmentType ? ( `image/${_.toLower(attachmentType)}`) : 'image/jpg';
        fields['Content-Type'] = mimeType;
        ctx.fields = fields;
        const uploadUrl = res.url;
        let fileKey = {};
        const setLogo = _.get(this,'props.setLogo');
        try {
            fileKey = await API.sendAJaxReq({ uploadUrl, filename, mimeType, fields, file:originFileObj,setProgress:this.setProgress });
            this.setState({
                imageUrl: `${uploadUrl}/${fileKey}`,
                loading:false
            });
            setLogo(`${uploadUrl}/${fileKey}`,true);

        }catch(e){
            console.log('line 63',e);
            throw(e);
        }
        return fileKey
    };

    renderUpload(){
        const { loading, imageUrl, progress } = this.state;
        const uploadButton = (
            <div>
                <Icon type={ loading ? 'loading' : 'plus'} />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                //action="https://unifiedcare-dev-organization-logo.s3-us-west-2.amazonaws.com/5920e0791b8a0e0022feb732"
                //beforeUpload = {this.beforeUpload}
                onChange={this.handleChange}
            >
                { !loading&&imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        )
    }

    render(){
        const pathname = window.location.pathname;
        return this.renderUpload();
    }
}

export default UploadLogoComponent;