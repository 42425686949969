import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { compose } from 'react-apollo';
import actions from '../../chat/actions';
import { API } from '../../chat/actions/api';
import createPostIt from '../../graphql/mutation/createPostIt';
import PostItList from '../../graphql/postItList';
import { API as TIMEAPI } from '../../timer/query';
import { measureDom } from '../actions/MainLayout';
import { autoLogout } from 'authModule/actions/CurrentUser';
import MainLayout from '../components/MainLayoutComponent';
import { checkPermission } from './checkPermission';
import I18N from 'modulesAll/I18N';
import { createAuditLog } from 'libModule/utils/auditLog';
import RequestCache from 'libModule/requestCache';
import { message, moment, IHButton } from 'ihcomponent';
import { decryptRole, goPath } from 'libModule/utils';
import { CHAT_ROLES } from 'modulesAll/utils/constants/role';
import { COMMON_HELPERS } from 'libModule/helpers/common-helpers';
import { closeModal, openModal, ErrorMessageDiv } from 'layoutModule/actions/MainModal';
import UserClass from 'modulesAll/graphql/class/User';
import { setInstitution, setRemountMainLayout, resetState } from '../../layout/actions/Nav';
import getOrgTeams from 'modulesAll/graphql/getOrgTeams';
import Client from 'libModule/gqlClient';
import { MAX_MSG_PER_CHANNEL } from 'libModule/constants';
import CallWindow from 'modulesAll/call/containers/callContainer';
import {
  loadConnectStreams,
  getInBoundAttributes,
  setLocalStorageLastCall
} from 'modulesAll/call/helper/helpers';
import callActions from '../../call/actions';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { Row, Modal, Col, notification, Icon } from 'antd';
import CreateNote from '../../alerts/InterventionNotes/containers/AddInterventionNoteFormComponentNoReading';
import PostItCreateComponent from '../../postIt/components/PostItCreateComponent';
import '../css/index.scss';
import noteActions from '../../postIt/actions';
import * as timerActions from '../../timer/actions/index';
import VideoChatAPI from '../../VideoChat/API/index';
import helper from '../../chat/helper/index';
import chatFilters from '../../chat/helper/chatFilters';
import { browserHistory } from 'react-router';
import HeartBeatTracking from "../helper/HeartbeatLog";
const { stopTimer,updateInterventionDetails }= timerActions.default;

let idleTimeout = 43200000; //auto-logout time in milliseconds (12hrs)
let idlePopUpTime = 900000; //auto-popup time in milliseconds (15minutes)
const idleIntervalLength = 30000; //check every 30 second
const MAX_CHANNELS_PER_BATCH = 299;
const CUSTOM_AWS_STATUS = ['LOGGED_OUT', 'null'];
// 199;
import VideoChatHelper from '../../VideoChat/helper';
import changeLogoAndTitle from '../helper/index';
// function difference(object, base) {
//     function changes(object, base) {
//         return _.transform(object, function (result, value, key) {
//             if (!_.isEqual(value, base[key])) {
//                 result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
//             }
//         });
//     }
//     return changes(object, base);
// }

import CCPClass from '../../call/class/ccpClass';
import roleMap from '../constants/roleMap';

let list = [];
const transferId = (userId)=>{
    const isEncoded = COMMON_HELPERS.checkIfEncoded(userId);
    const userProfileId = isEncoded ? userId : btoa(`accounts:${userId}`);
    const userMapId = isEncoded ? atob(userId).split(':')[1] : userId;
    return { userProfileId,userMapId } ;
}
class Container extends Component {
    static displayName = 'MainLayoutContainer'
    static contextTypes = {
        dom: PropTypes.object
    };
    static childContextTypes = {
        dom: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.subscribeToChannels = this.subscribeToChannels.bind(this);
        this.setChannels = this.setChannels.bind(this);
        this.getTimestampOfLastTextMsg = this.getTimestampOfLastTextMsg.bind(this);
        this.apiFetchBatchHistory = this.apiFetchBatchHistory.bind(this);
        this.setUserMap = this.setUserMap.bind(this); // not used
        this.handleChangeOrg = this.handleChangeOrg.bind(this);
        this.setOrgTeamMap = this.setOrgTeamMap.bind(this);
        this.warning = this.warning.bind(this);
        this.confirm = this.confirm.bind(this);
        this.submitWarning = this.submitWarning.bind(this);
        this.channels = [];
        this.useChs = true;
        let initArr = _.split(props.location.pathname, '/');
        let initPage = initArr[initArr.length - 1];
        if(initPage === 'default') {
            initPage = 'patient_profile'
        }
        if(_.startsWith(initPage, 'YW')) {
            initPage = 'patient_detail'
        }
        this._screenshots = [];
        this.state = {
            //CCP
            showChat: null,
            channelName: '',
            callPhoneNumber: '',
            callUserName: '',
            callStatus: 7,
            callDate: null,
            callDuration: 0,
            curPage: initPage,
            showChromeSuggestion: false,
            checkPopup: false,
            checkConfirmPop: false,
            checkEndWarning: false,
            heartbeatInterval: null,
            snapshotInterval: null,
            mainLayoutKey: null,
            callLogNotes: ''
        }

        this.subscriptions = this.subscriptions.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        CCPClass.setSubscriptionsHandler(this.subscriptions);
    }

    isHC = () => {
      const currentUserRole = decryptRole();
      return roleMap[currentUserRole] === 'HC';
    }

    registerHearBeatHelper=()=>{
        new HeartBeatTracking();
    }

    getChildContext() {
        return {
            dom: this.props.dom
        };
    }

    handleChangePage(page) {
        this.setState({
            curPage: page
        })
    }


    createTrackingEvent(res, summary, patientId){
        const apiName = Object.keys(res.data)[0];
        const docId = res.data[apiName].id;

        TIMEAPI.saveModalAndUpdateTimer(this.props,'PostItContainer',apiName,patientId,docId,summary);
    }

    createPostIt  = (variables) => {
        let lastContact = JSON.parse(localStorage.getItem('call-center-last-contact') || '{}')
        const patientId = _.get(lastContact, 'patientId.value') || window.location.href.split('/')[4];
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const organizationId = _.get(currentUser,'selectedRole.organization.id');

        let parsedVariables = Object.assign(variables,{
            memberId:patientId,
            organizationId
        });

        return new Promise((resolve)=>{
            Client.mutate({
                mutation: createPostIt,
                variables: parsedVariables,
                refetchQueries: [{
                    query: PostItList,
                    variables: {memberId: patientId},
                    fetchPolicy: 'network-only'
                }]
            }).then((res) => {

                this.createTrackingEvent(res,'createPostIt', patientId);
                message.success('Note Created!');
                this.props.setShowCreateComponent(false);
                resolve(true);
                localStorage.removeItem('call-center-last-call');

            }).catch((e) => {
                this.props.openErrorModal(e);
            })
        })
    }

    resetAllCaches = async () => {
      try {
        const persistedStates = ['AWSConnect', 'Stickie', 'routing','globalTables'];
        this.props.resetReduxStore(persistedStates);
        await Client.clearStore();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    setCurPageOnMount = () => {
      const path = browserHistory.getCurrentLocation().pathname;
      const pageIndex = _.split(path, '/')[1];
      if (this.state.curPage === pageIndex) return;
      this.setState({ curPage: pageIndex });
    }

    remountComponent = async () => {
      await this.resetAllCaches();
      this.unmountComponent();
      this.mountComponent();
      this.setState({ mainLayoutKey: new Date().getTime() });
    }

    componentWillUnmount = () => this.unmountComponent();

    unmountComponent = () => {
        if (API.getPubNub()) {
          API.getPubNub().unsubscribeAll();
        }
        if(this.idleInterval) clearInterval(this.idleInterval);
        try {
          const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
          if(!userSettings) return;
          const { heartbeatTimer,snapshotTimer } = userSettings || {};
          clearInterval(heartbeatTimer);
          clearInterval(snapshotTimer);
        } catch (error) {} //ignore
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          checkPermission(nextProps.location.pathname);
        }

        if (nextProps.breadCrumb && (nextProps.breadCrumb !== this.props.breadCrumb || nextProps.institution !== this.props.institution)) {

            // To have patientNRIC and patientName when navigation inside a specific patient
            // There should not be patientNRIC and patientName during import and manual registration
            // This is not a good way to handle all scenario but due to time constraint
            if (_.includes(nextProps.breadCrumb, 'Patients /')) {
                const currentPatient = RequestCache.get('nurse/currentPatient')
                const patientNRIC = _.get(currentPatient, 'identification[0].value')
                const patientName = _.get(currentPatient, 'profile.fullName')

                createAuditLog({
                    patientNRIC,
                    patientName,
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
            // To have patientNRIC and patientName for navigation using Patient Role Login
            else if (decryptRole() === 'MEMBER:Member') {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
                const patientNRIC = _.get(currentUser, 'identification[0].value')
                const patientName = _.get(currentUser, 'profile.fullName')

                createAuditLog({
                    patientNRIC,
                    patientName,
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
            else {
                createAuditLog({
                    source: nextProps.breadCrumb,
                    action: `${I18N.get('auditLog.Navigation.prefix')} ${nextProps.breadCrumb}`
                })
            }
        }
        return true;
    }

    beforeUnload = (e) => {
      const isStickyNoteOpen = !!this.state.showCreateComponent;
      const isCallLogOpen = !!this.props.providerNotesModalIsVisible;
      if(isStickyNoteOpen || isCallLogOpen || !!this.props.minimizedNote) {
        e.preventDefault();
        e.returnValue = 'call log popup is not finished'; // this message text won't show due to browser design
      }
      if (API.getPubNub()) {
        API.getPubNub().unsubscribeAll();
      }
      CCPClass.closeLoginPopup()
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.PNStatus.category === 'PNTimeoutCategory' || nextProps.PNStatus.category === 'PNNetworkDownCategory' || nextProps.PNStatus.category === 'PNNetworkIssuesCategory' || nextProps.PNStatus.category === 'PNUnexpectedDisconnectCategory') {
            API.getPubNub().reconnect();
        }
        // console.log(nextProps.PNStatus);
        if (nextProps.PNStatus.category === 'PNConnectedCategory' && this.props.PNStatus.category !== 'PNConnectedCategory') {
            let parseCh = (ch)=>{
                return this.useChs ? ch : ch.name;
            }
            let channelsArr = this.channels.map(ch => parseCh(ch));
            // let channelsArr = this.channels.map(ch => ch);
            channelsArr = _.filter(channelsArr,(ch)=>!_.includes(ch,'org-'));
            let maxPerBatch = this.useChs ? 2000 : MAX_CHANNELS_PER_BATCH;
            if (channelsArr) {
                if (channelsArr.length === 0){
                    this.props.setIsFullyLoaded(true);
                }
                for (let i = 0; i < channelsArr.length; i += maxPerBatch) {
                  try {
                      const slicedChannelsArray = channelsArr.slice(i, i + maxPerBatch);
                      let slicedPatientsArray = slicedChannelsArray.map(ch => ch.split('-')[1]);

                      let param = {
                            patientIds: slicedPatientsArray,
                            count: MAX_MSG_PER_CHANNEL
                            // timestamp:moment(new Date()).valueOf()
                        };
                    //   console.log(useChs,param);
                        if (this.useChs){
                            API.getChatHistoryFromChs(param)
                                .catch(error=>console.log(e))
                                .then(res => {
                                          const map = helper.parseChatChannelsResponse(res, this.useChs);
                                          helper.handleBatchHistory(map,slicedChannelsArray, true)
                                        }
                                    );
                        } else {
                            this.apiFetchBatchHistory(slicedChannelsArray).then(res => {
                                res = parseChatChannelsResponse(res.channels, this.useChs);
                                helper.handleBatchHistory(res, slicedChannelsArray);
                            });
                        }
                      // this.findUniqCount(Object.keys(resp.channels));
                  } catch (e) {
                      console.log(e);
                  }
                }
                if (!this.useChs) {
                    this.props.setIsFullyLoaded(true);
                }
            }
        }
    }

    componentDidUpdate = (prevProps) => {
      if(!prevProps.remountMainLayout && this.props.remountMainLayout) {
        this.remountComponent();
        // this.props.setRemountMainLayout(false);
      }

      if(
        this.state.triggerAutoLogoutAWSLater 
          && prevProps.contactStatus !== 'onEnded' 
          && this.props.contactStatus === 'onEnded'
      ) {
        this.handleAutoLogoutAWS(this.state.triggerAutoLogoutAWSLaterReason);
      }
    }

   componentDidMount = () => this.mountComponent();

   mountComponent = async () => {
        this.registerHearBeatHelper();

        checkPermission(this.props.location.pathname);
        changeLogoAndTitle(document);
        this.setCurPageOnMount();
        this.idleInterval = setInterval(() => {
            if (!this.refs.idleTimer || !this.refs.idleNotification) {
                clearInterval(this.idleInterval);
            } else {
                if(this.refs.idleNotification.getRemainingTime() === 0 && !this.state.checkPopup) {
                    this.warning(); // will show popup window if 15mins no response
                    this.setState({
                        checkPopup: true
                    })
                }
                if (this.refs.idleTimer.getRemainingTime() === 0) {

                    // terminate call center
                    CCPClass.logout({
                      onDone: () => {
                        this.props.autoLogout('AUTO_LOG_OUT');
                        clearInterval(this.idleInterval);
                      }
                    });
                }
            }
        }, idleIntervalLength);

        // document.title = window.VSM_CONFIG.DEMO ? window.VSM_CONFIG.DEMO : 'VSM';

        window.addEventListener('beforeunload', this.beforeUnload);

        const currentUserRole = decryptRole();
        const userId = sessionStorage.getItem('currentUserId');

        // Only show Chat for providers (not admins)
        if (CHAT_ROLES.includes(currentUserRole)) {
            const isChs = _.get(await API.getIfIsCHS(userId),'data.getUserChatInfo.messageSource')==='CHS';
            this.useChs = isChs;
            API.apiGetUserChatInfo(userId,isChs)
            .then(async res => {
                    const { props } = this; // use this props for function only. ie: props.addMessage

                    // for initializing pubnub
                    const userId = atob(sessionStorage.getItem('currentUserId')).split(':')[1];
                    const publishKey = res.data.getUserChatInfo.publishKey;
                    const subscribeKey = res.data.getUserChatInfo.subscribeKey;
                    const authKey = res.data.getUserChatInfo.authKey;
                    const channelGroupsArr = res.channelGroupsArr;
                    // const messageSource = res.data.getUserChatInfo.messageSource;
                    // const isChs = messageSource === 'CHS' ? true : false;
                    // this.useChs = isChs;
                    this.props.updateApi(isChs);

                    await this.setOrgTeamMap();
                    API.apiInitializePubNub(publishKey, subscribeKey, authKey, userId);
                    if (res.data.getUserChatInfo) {
                        // this.setUserMap(res.data.getUserChatInfo);
                        // const transferId = (userId)=>{
                        //     const isEncoded = COMMON_HELPERS.checkIfEncoded(userId);
                        //     const userProfileId = isEncoded ? userId : btoa(`accounts:${userId}`);
                        //     const userMapId = isEncoded ? atob(userId).split(':')[1] : userId;
                        //     return userProfileId;
                        // }
                        const channelsArr = isChs ? (await API.getPaginatedChannels({ pageNumber:(new Date().getTime()*1e4)+'',fromTimestamp:(new Date().getTime()*1e4)+'',unread:true,teamIds:[] })).Channels :res.data.getUserChatInfo.channelGroup.channels;

                        if (this.isHC()) {
                          const promiseList = isChs ? _.map(channelsArr,async (c)=>VideoChatAPI.getPatientChatInfoWithComplexity({ id: transferId(c.split('-')[1]).userProfileId })) : [];
                          let userChatInfo = isChs ? await Promise.all(promiseList): res.data.getUserChatInfo;
                          helper.setUserMap(userChatInfo,isChs);
                        }

                        this.setChannels(channelsArr,isChs);
                    }
                    // add listener before subscribing to channels, per pubnub documentation
                    API.getPubNub().addListener({
                        status: status => {
                            this.props.updatePNStatus({ category: status.category, operation: status.operation });

                            if (status.error) {
                                if (status.category === 'PNNetworkUpCategory' || status.category === 'PNNetworkIssuesCategory') {
                                  API.getPubNub().reconnect(); // TODO: refetch history batch to get new unread messages
                                }

                                // get new authKey & re-subscribe to channels if expired
                                if (status.category === 'PNAccessDeniedCategory' && status.operation === 'PNSubscribeOperation') {
                                    // TODO: need to check why at some moment, no new key is provided
                                    // API.apiGetUserChatInfo(userId,this.useChs)
                                        // .then(res => {

                                        //     API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);
                                        //     this.subscribeToChannels(channelGroupsArr); // need to re-subscribe after auth key reset
                                        // })
                                        // .catch(err => console.log('Error getUserChatInfo, ', err));
                                }

                                // (IH-649, IH-618) Throw error when user tries to subscribe to over 10 channel groups
                                if (status.category === 'PNBadRequestCategory' && status.operation === 'PNSubscribeOperation') {
                                    const errMsg = JSON.parse(status.errorData.response.text);

                                    if (errMsg.message === I18N.get('chat.max_channels')) {
                                        props.openErrorModal(I18N.get('chat.max_channels') + '. ' + I18N.get('chat.contact_tech_support'));
                                    }
                                }
                            }
                        },
                        message: async msg => {
                            let { channel, message } = msg;
                            const { type, publisher, patient: patientId = '' } = message;

                            //handle notification message
                            if(type === 'notification'){
                              const store  = (this.__reactInternalMemoizedMergedChildContext.store);
                              VideoChatHelper.handleNotification(message,store);
                              return;
                            }

                            if(type === 'consent'){
                                const store  = (this.__reactInternalMemoizedMergedChildContext.store);
                                VideoChatHelper.handleConsent(message,store);
                                return;
                            }

                            const addUserToUserMap = userId => {
                              try {
                                const isEncoded = COMMON_HELPERS.checkIfEncoded(userId);
                                const userProfileId = isEncoded ? userId : btoa(`accounts:${userId}`);
                                const userMapId = isEncoded ? atob(userId).split(':')[1] : userId;
                                if(!this.props.userMap[userMapId]){
                                  VideoChatAPI.getPatientChatInfoWithComplexity({ id: userProfileId })
                                  .then(res => {
                                    const userObj = {
                                      uuid: userId,
                                      ...helper.getUserMapObj(res.data.user)
                                    }
                                    props.addToUserMap(userObj);
                                  })
                                  .catch(err => console.log('Error fetching user profiles: ', err));
                                }
                              } catch (err) {
                                  console.log('Err: ', err, ' Publisher on message not valid: ', userId);
                              }
                            }

                            // only fetch in background for HC due to specific logic for red dot with complexity
                            if (this.isHC()) {
                              addUserToUserMap(patientId);
                            }

                            if(this.useChs) {
                              // channel from CHS notification msg is just teamId
                              channel = `${channel}-${patientId}`;
                              setTimeout(async () => {
                                const res = await API.getChatHistoryFromChs({
                                  patientIds: [patientId],
                                  count: MAX_MSG_PER_CHANNEL
                                });


                                const data = res[0] || {};
                                let messages = _.filter(data.messages, val => typeof val === 'object')
                                                .map(val => helper.genMessageObject(val, true));

                                if (messages.length) {
                                  const messageCount = messages.length;
                                  const hasMoreMessage = messageCount == MAX_MSG_PER_CHANNEL;
                                  const tagMessageOffset = data.tagMessageOffset || -1;
                                  const tagMessageOffsetFrom = (_.last(messages) || {}).timetoken;

                                  const channelHistory = _.get(this, `props.channels.${channel}.history`) || [];
                                  messages = _.uniqBy([...messages, ...channelHistory], m => Number(m.timetoken));
                                  messages = _.sortBy(messages, 'timetoken');

                                  const lastMessageInHistory = _.last(messages) || {};
                                  if(type !== 'ACK' && chatFilters.shouldExcludeMessage(_.get(lastMessageInHistory, 'entry')))
                                    return;

                                  const fromTimestamp = helper.getFromTimestamp(messages);

                                  const channelInfoFromLastMessage = helper.handleUnreadAndLastMsg(messages, {
                                    ...data,
                                    id: channel, 
                                    tagMessageOffset, 
                                    tagMessageOffsetFrom, 
                                    history: messages, 
                                  });
                                  channelInfoFromLastMessage.isListening = false;

                                  this.props.addChatHistory(channel, patientId, messages, null, messageCount, false, true, hasMoreMessage, false, tagMessageOffset, tagMessageOffsetFrom, true, fromTimestamp);
                                  this.props.updateUnreadCounter(channel, channelInfoFromLastMessage);
                                }
                              }, 600);
                            } else { // fallback
                              const msgObj = {
                                timetoken: Number(msg.timetoken),
                                entry: msg.message,
                                isListening: true, // flag to increment unread counter, only if msg comes from listener
                                lastMsgText: msg.message.text,
                                lastMsgTime: msg.timetoken,
                                lastMsgSender: msg.message.displayName,
                                type,
                                publisher
                              };
                              props.addMessage(channel, msgObj);
                            }
                        }
                    });

                    //subscribe to channels after add lister
                    this.subscribeToChannels(channelGroupsArr);
                })
                .catch(err => {
                  console.error('Error getUserChatInfo, ', err);
                  if (err.message === "Cannot read property 'Channels' of undefined" || err.message === "Cannot read property 'map' of null") {
                      Modal.error({ content: 'Failed to start chat. Please contact your manager to be added to a care team.'})
                  } else {
                      Modal.error({ content: 'Failed to start chat. Please try refresh'});
                  }
                });
        }

        // add call center script
        // only when there is no established agent
        if (!window.awsCCP || !window.awsCCP.agent) {
          loadConnectStreams(this.subscriptions)
        }
    }

    async setOrgTeamMap() {
        try {
            const res = await Client.query({
                query: getOrgTeams,
                fetchPolicy: 'network-only'
            })
            if (_.get(res, 'data.getOrgTeams')) {
                const orgTeamMap = {};
                res.data.getOrgTeams.forEach(val => orgTeamMap[val.organizationId] = val.teamIds);
                this.props.setOrgTeamMap(orgTeamMap);
            }
        }
        catch (e) {
            console.log('getOrgTeams err: ', e);
        }
        // .then(res => {
        //   const orgTeamMap = {};
        //   res.data.getOrgTeams.forEach(val => orgTeamMap[val.organizationId] = val.teamIds);
        //   this.props.setOrgTeamMap(orgTeamMap);
        // })
        // .catch(err => console.log('getOrgTeams err: ', err));
        // (res => {

    }

    async handleChangeOrg(data) {
        const { sessionToken, id, role, appSettings, orgPermissions, globalConfigs } = data.chooseLoginOrganization || {};
        if (sessionToken) {
            await UserClass.saveUserInfo({ sessionToken, id, role, appSettings, orgPermissions, globalConfigs });
            await this.props.setInstitution(role.refId);
            await this.props.setRemountMainLayout(true);
            RequestCache.clear(); // before, reload will reset this
            goPath('/');
            message.success('Organization changed');
        }
    }

    // Not used
    getUserMapObj = userObj => ({
      firstName: _.get(userObj, 'profile.firstName', ''),
      lastName: _.get(userObj, 'profile.lastName', ''),
      avatar: _.get(userObj, 'profile.avatar.link', '/image/default_avator.png'),
      patientComplexity: _.get(userObj, 'patientComplexity', {})
    })

    // Not used
    setUserMap(chatInfo,isChs) {
        const userMap = {};
        const { props } = this;
        isChs ? _.map(chatInfo,c=>c.data.user&&_.set(userMap,transferId(c.data.user.id).userMapId,this.getUserMapObj(c.data.user))):
        chatInfo.channelGroup.channels.forEach(ch => {
            if (ch.member && ch.member.id) {
                const decodedId = atob(ch.member.id).split(':')[1];

                userMap[decodedId] = this.getUserMapObj(ch.member);
            }
        });

        props.setUserMap(userMap);
    }

    setChannels(combinedChannels,isChs) {
        let channelsObj = {};
        this.channels = combinedChannels;
        let parseCh = (ch)=>{
           return isChs ? ch : ch.name
        }
        combinedChannels.forEach(ch=>{
            const patientId = parseCh(ch).split('-')[1]; // TODO: Make helper function
            const newChannel = {
                        patientId,
                        lastMessageTimestamp: new Date().getTime() * 1e4, // get messages starting today
                        history: [],
                        counter: 0
                    };
            channelsObj[ch] = newChannel;
        })
        // combinedChannels.forEach(ch => {
        //     const patientId = ch.name.split('-')[1]; // TODO: Make helper function
        //     const newChannel = {
        //         patientId,
        //         lastMessageTimestamp: new Date().getTime() * 1e4, // get messages starting today
        //         history: [],
        //         counter: 0
        //     };
        //     channelsObj[ch.name] = newChannel;
        // });

        this.props.addChannels(channelsObj);
    }

    subscribeToChannels(channelGroupsArr) {
      API.subscribePubNub(channelGroupsArr);
    }
//     async apiFetchBatchHistory(patientsArr) {
//         return new Promise((resolve, reject) => {
//             API.getChatHistoryFromChs({
//                 patientIds: patientsArr,
//                 count: MAX_MSG_PER_CHANNEL,
//             })
//     },(resp) =>{
//         this.handleBatchHistory(resp, channelsArr);
//     }
// }

    async apiFetchBatchHistory(channelsArr) {
        const userId = sessionStorage.getItem('currentUserId');
        // batch history API only returns channels with messages; if a channel does not have messages, it is not incl. in response
        return new Promise((resolve, reject) => {
            API.getPubNub().fetchMessages({
                channels: channelsArr,
                count: MAX_MSG_PER_CHANNEL,
            }, (status, resp) => {
                // if expired authKey, fetch & set new authKey
                if (status.error) {
                    console.log('Error fetch batch history: ', status);
                    if (status.category === 'PNAccessDeniedCategory') {
                        API.apiGetUserChatInfo(userId,this.useChs)
                            .then(res => {

                                API.getPubNub().setAuthKey(res.data.getUserChatInfo.authKey);

                                const channelGroupsArr = res.channelGroupsArr;
                                this.subscribeToChannels(channelGroupsArr); // need to re-subscribe after auth key reset

                                API.getPubNub().fetchMessages({
                                    channels: channelsArr,
                                    count: MAX_MSG_PER_CHANNEL // max
                                }, (innerStatus, innerResp) => {

                                    if (innerStatus.error) {
                                        reject(innerStatus.error);
                                        console.log('Error fetch batch history, after authKey reset, ', innerStatus);
                                    }
                                    if (innerResp) {
                                        const channels = parseChatChannelsResponse(innerResp, this.useChs);
                                        // console.log('innerResp, fetch batch history: ', innerResp);
                                        this.handleBatchHistory(channels, channelsArr);
                                    }
                                })

                            })
                            .catch(err => console.log('error getUserChatInfo: ', err));
                    } else {
                        console.log('Error fetch batch history: ', status);
                        reject(status);
                    }
                }

                if (resp) {
                    // this.handleBatchHistory(resp, channelsArr);
                    resolve(resp);
                }
            });
        })
    }

    getTimestampOfLastTextMsg(messages) {
        let lastMessageTimestamp = 1524175294358 * 10000; // 1970 default
        return messages.length > 0 ? messages[0].timetoken : lastMessageTimestamp;
    }

    warning() {
        let onOk = () => {
            this.setState({
                checkPopup: false
            })
            // clearInterval(this.idleInterval);
        }
        Modal.warning({
            title: 'Session Timed Out',
            content: 'You have been inactive for at least 15 minutes. Click OK to resume your session.',
            onOk: onOk
        });
    }

    render() {
        const { nav, children, nonExistNotification, openNonExistNotification} = this.props;
        const curPath = this.props.location.pathname;
        const nonExistNotificationContent = "Note: this number is NOT in the system, please add Provider Note/Sticky Note manually after the call."
        return (
            <IdleTimer ref="idleTimer" timeout={idleTimeout}>
                <div key={this.state.mainLayoutKey}>
                  <IdleTimer ref='idleNotification' timeout={idlePopUpTime}/>
                  <MainLayout id='MainLayout' {...{ nav, curPath, children}}
                              curPage = {this.state.curPage}
                              handleChangeOrg={this.handleChangeOrg}
                              handleChangePage={this.handleChangePage}/>
                  {this.renderCallLog()}
                  {this.props.callStickie && this.renderStickie()}
                </div>
                {this.renderCallWindow()}
                {nonExistNotification && openNonExistNotification(nonExistNotificationContent)}
            </IdleTimer>
        )
    }
    renderStickie() {
        const { showCreateComponent, contactStatus} = this.props;
        const { renderButtons } = this;
        let lastContact = JSON.parse(localStorage.getItem('call-center-last-contact') || '{}')
        return  showCreateComponent?
        <div style={{zIndex: 3000, position:'fixed',display:'flex',flexDirection:'column', bottom:100,top:100,left:300,right:300 }} id='StickieNote' className='videoChatCenter'>
                    <div className="ant-modal-mask" style={{  zIndex:500}}></div>
                    <Row style={{zIndex:500,display:'flex', overflow: 'auto', justify:'center'}}>
                        <Col span={16} style={{background:'#F6F6F6' }}>
                            {/* { showCreateFollowUp&&renderCreateFollowUp() } */}
                            { renderButtons()}

                            <div style={{background:'#F6F6F6'}}>
                                {/* {this.renderNoteForm(currentRole)} */}
                                <div style={{ margin:10,padding:10  }}>

                                <PostItCreateComponent createPostIt={ this.createPostIt }
                                            patientId = { _.get(lastContact, 'patientId.value') || window.location.href.split('/')[4] }
                                            status= {contactStatus}
                                            turnOnline= {this.turnOnline}
                                            setCallStickie={this.props.setCallStickie}

                                />
                                </div>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    :<div></div>;

    }
    renderButtons = ()=>{
        const { handleCloseEvent, minimizeButtonHandler } = this;
        const {showCreateComponent} = this.props;
        return  <Row style={{ height:45, display:'flex', alignItems:'flex-end',justifyContent:'flex-end'}}>
                   <Col span={18}> </Col>
                    <Col span={3}
                    onClick={()=>{
                        // setModal(false);
                        minimizeButtonHandler(true)} } >
                        <div style={{height:15,display:'flex',alignItems:'center',justifyContent:'center'}} >
                        <Icon type="minus" height={16}  style={{height:15, marginRight:10, color: '#000'}}   />
                        </div>
                    </Col>
                    {!showCreateComponent &&
                        <Col span={3} onClick={() => handleCloseEvent()}  >
                            <Icon type="close" height={16}  style={{ marginRight:10, color: '#000' }}   />
                        </Col>
                    }
             </Row>
    }
    confirm() {
        let onOk = () => {
            this.setState({
                checkConfirmPop: false,
            })
            this.executeClose();
        }
        let onCancel = () =>{
            this.setState({
                checkConfirmPop: false,
            })
        }
        Modal.confirm({
            title: 'Did you submit a call log?',
            content: 'Please remember to submit a call log for this patient. Thank you',
            zIndex:3500,
            onOk: onOk,
            onCancel:onCancel
        });
    }
    // close provider note div
    handleCloseEvent = ()=>{
            if (!this.state.checkConfirmPop){
                this.confirm();
                this.setState({
                    checkConfirmPop: true
                })
            }
    }

    executeClose(){
        this.props.providerNotesModalIsVisibleAction(false);
        this.props.setCallStickie(false);
        // const videoChatCenterDiv = $('#CallLog');
        // videoChatCenterDiv.css('display','none');
        this.props.lastCallPatientIdAction(null);
    }

    minimizedNote = ()=>{
        return <div style={{ fontSize:16 }}>
            <Icon type="edit" height={16}  style={{ marginRight:10 }}   />
            <span style={{ fontWeight:'bold',marginLeft:10 }}>Continue Editing</span>
            <a onClick={()=>this.minimizeButtonHandler(false)}>
            <Icon type="arrows-alt" style={{ float:'right', height:20} }/>
            </a>
        </div>
    }

    minimizeButtonHandler = (isMinimized)=>{
        const {showCreateComponent, setShowCreateComponent, minimizedNote, setMinimizedNote } = this.props;
        let lastContact = JSON.parse(localStorage.getItem('call-center-last-contact') || '{}') 
        // const callLogDiv = $('#CallLog');

        if(isMinimized){
            if(showCreateComponent) {
              setShowCreateComponent(false)
            }
            // showCreateComponent ? this.props.setShowCreateComponent(false) : callLogDiv.css('display','none');

            const variables = {
                key:'minimizedNote',
                message:'',
                placement:'topRight',
                description:this.minimizedNote(),
                duration: 0,
                className:'minimizedVideoChat',
                style:{
                    boxShadow:'0 0 12px 1px rgba(162,162,162,0.6)'
                }
            };
            notification.open(variables);
            Mixpanel.track('minimize', 'call log window');
            setMinimizedNote(!showCreateComponent ? 'CALL_LOG' : 'STICKY_NOTE');
        }else {
            notification.close('minimizedNote');
            Mixpanel.track('maximize', 'call log window');
            if(_.get(lastContact, 'enrolledProgramId.value')){
                if (_.get(lastContact, 'operationStatus.value') === 'DISCHARGED') {
                  // providerNotesModalIsVisibleAction(true);
                // } else {
                  setShowCreateComponent(true)
                }
            } else {
                setShowCreateComponent(true);
            }
            setMinimizedNote(null);
        }
    }

    handleSetCallLogNotes = (callLogNotes) => this.setState({ callLogNotes });

    renderCallLog() {
        const { providerNotesModalIsVisible, contactStatus, minimizedNote } = this.props;   
        const { callLogNotes } = this.state;    
        let lastContact = JSON.parse(localStorage.getItem('call-center-last-contact') || '{}') 
        let endTime = contactStatus === 'onConnected' ? _.get(lastContact, 'startTime') : _.get(lastContact, 'endTime');
        const { renderButtons } = this;
        return providerNotesModalIsVisible ? (
          <div
            style={{
              zIndex: 1049,
              position: 'fixed',
              left: 0,
              display: minimizedNote === 'CALL_LOG' ? 'none' : 'flex',
              flexDirection:'column', 
              bottom: 100,
              top: 100,
              left: 300,
              right: 300,
            }}
            id='CallLog'
            className='videoChatCenter'
          >
            <div className="ant-modal-mask" style={{  zIndex:500}}></div>
            <Row style={{zIndex:500,display:'flex', overflow: 'auto', justify:'center', width: '66.7%'}}>
                <Col span={24} style={{background:'#F6F6F6' }}>
                    {/* { showCreateFollowUp&&renderCreateFollowUp() } */}
                    { renderButtons()}

                    <div style={{background:'#F6F6F6'}}>
                        {/* {this.renderNoteForm(currentRole)} */}
                        <div style={{ margin:10,padding:10  }}>

                        <CreateNote
                          noteType='provider_note'
                          note={this.props.note}
                          setNote={this.props.setNote}
                          patientId={ _.get(lastContact, 'patientId.value') }
                          programId={  _.get(lastContact, 'enrolledProgramId.value') }
                          organizationId={_.get(lastContact, 'organizationId.value')}
                          defaultCategory={ "CALL_LOG"}
                          closeModal={() => {
                              this.props.providerNotesModalIsVisibleAction(false);
                              this.props.setCallStickie(false);
                              this.handleSetCallLogNotes('');
                              // const callLogDiv = $('#CallLog');
                              // callLogDiv.css('display','none');
                              // this.setState({ showAddPNModal: false })
                          }}
                          patientNameInCall={  _.get(lastContact, 'name.value') }
                          callLogStartTime={ _.get(lastContact, 'startTime') }
                          callLogEndTime={ endTime }
                          status= {contactStatus}
                          turnOnline= {this.turnOnline}
                          callLogNotes={callLogNotes}
                          setCallLogNotes={this.handleSetCallLogNotes}
                        />
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
          ): null;
        }


    //submit warning for call log submission during a call
    submitWarning() {
        let onOk = () => {
            this.setState({
                checkEndWarning: false
            })
        }

        Modal.warning({
            title: 'Alert Message',
            content: 'The call is ongoing, please submit after the call is ended',
            zIndex:3500,
            onOk: onOk
        });

    }

    //Turn agent to online
    //Return indicator true/false help check if during the call
    turnOnline = (contactStatus) =>{
        let agent = window.awsCCP.agent
        if (!agent) return;
        let routableState = agent.getAgentStates().filter(function(state) {
            return state.type === global.connect.AgentStateType.ROUTABLE;
        })[0];
        agent.setState(routableState, {
            success: function() { },
            failure: function() { }
        });
        return true;
        // if (contactStatus && contactStatus === 'onEnded') {

        // }
        // if (contactStatus && contactStatus === 'onConnected' && !this.state.checkEndWarning) {

        //     this.submitWarning();
        //     this.setState({
        //         checkEndWarning: true
        //     })
        //     return false;
        // }
        // return false;
    }

    renderCallWindow() {
        const {  callPhoneNumber } = this.state;
        const { contactStatus, lastCallPatientName,lastCallEndTime,lastCallStartTime } = this.props;
        let status = 7
        if (contactStatus == 'onConnected') {
            status = 1
        } else if (contactStatus == 'onConnecting') {
            status = 0
        } else if (contactStatus == 'onEnded') {
            status = 2
        } else if (contactStatus == 'onClosed') {
            status = 7
        }
        let timeDiff = Date.daysBetween(lastCallStartTime, lastCallEndTime)
        const date = lastCallEndTime ? lastCallEndTime.format("YYYY-MM-DD HH:mm:ss") : ''
        const callArgs = {
            showChat: true,
            phoneNumber: callPhoneNumber,
            username: lastCallPatientName,
            status: status,
            duration: timeDiff,
            date: date
        }
        return <CallWindow callArgs={callArgs} close={() => { this.props.setContact('onClosed') }} />;
    }

    handleAutoLogoutAWS = (error) => {
      const { triggerAutoLogoutAWSLater } = this.state;
      const { contactStatus } = this.props;
      const errorMsg = _.get(error, 'errorMessage', '');
      if (['onConnected', 'onConnecting'].includes(contactStatus)) {
        if (!triggerAutoLogoutAWSLater) {
          this.setState({ 
            triggerAutoLogoutAWSLater: true, 
            triggerAutoLogoutAWSLaterReason: errorMsg,
          });
        }
        return;
      }

      this.setState({ 
        logoutAWSwithoutReload: true,
        triggerAutoLogoutAWSLater: false,
        triggerAutoLogoutAWSLaterReason: null
      }, () => {
        CCPClass.logout({
          onSettled: () => {
            // close CCP
            this.props.setCcpStatus('close');
            this.props.setContact('onClosed');
            this.setState({ logoutAWSwithoutReload: false }, () => {
              Modal.confirm({
                title: 'Amazon Connect',
                content: (
                  <div>
                    <div>
                      You are currently been logged out, please click the button below to re-login your account
                    </div>
                    {
                      errorMsg
                      && <div>{`Reason: ${errorMsg}`}</div>
                    }
                  </div>
                ),
                okText: 'Login now',
                cancelText: 'I\'ll log in later',
                onOk: CCPClass.checkLogin,
                icon: <Icon type="warning" theme="filled" />
              });
            });
          }
        });
      });
      
    }

    //first time load script; then add ccp and dial a number
    subscriptions() {
        let that = this
        window.awsCCP = window.awsCCP || {};
        window.connect.agent((agent) => {
            window.awsCCP.agent = agent;
            CCPClass.closeLoginPopup();
            //*
            //Event subscriptions link your app into the heartbeat of Amazon Connect
            //by allowing your code to be called when new agent information is available.
            // console.log("Subscribing to events for agent " + agent.getName());
            // console.log("Agent is currently in status of " + agent.getStatus().name);
            agent.onRefresh(function handleAgentRefresh(agent) {
                // console.log("[agent.onRefresh] Agent data refreshed. Agent status is ", agent.getStatus().name);
                //  if (agent.getStatus().name == 'FailedConnectAgent') {
                //     that.props.setAgentStatus("Missed call")
                //  } else {

            // Start ------------- 08/09 change ------------------
                // If user chose a status, store it locally, and apply the status
                // Otherwise, use the latest current status
                const currentAgentStatus = agent.getStatus().name;

                // If an agent choose not to answer the call, set the chosen status to null (Not Available in portal)
                // And the next status will eventually be Offline (in AWS)
                // If patient choose not to anwer the call, the chosen status won't change after ACW
                if(currentAgentStatus == 'FailedConnectAgent'){
                    localStorage.setItem('agent-choose-status', null);
                }

                const agentChooseStatus = localStorage.getItem('agent-choose-status');
                const allStates = agent.getAgentStates().map(function (state) {
                    return state.name;
                });

                // IF agent chose a status
                // And current Status is either 'Available' or 'Offline'
                // And current status does not equal to agent's choice
                // overwrite the Amazon Connect agent status
                if( agentChooseStatus &&
                    !CUSTOM_AWS_STATUS.includes(agentChooseStatus) &&
                    _.includes(allStates, currentAgentStatus) &&
                    agentChooseStatus !== currentAgentStatus){
                    that.props.setAgentStatus(agentChooseStatus);

                    const statusNeedToUpdate = agent.getAgentStates().filter(function (state) {
                        return state.name === agentChooseStatus;
                    })[0];

                    agent.setState(statusNeedToUpdate, {
                        success: function () {
                        },
                        failure: function () {
                        }
                    });
                } else if(CUSTOM_AWS_STATUS.includes(agentChooseStatus) &&
                  _.includes(allStates, currentAgentStatus)){
                    // when user has multiple tabs open and logout
                    // keep the status from aws and update local storages
                    that.props.setAgentStatus(currentAgentStatus);
                    localStorage.setItem('agent-choose-status', currentAgentStatus);
                } else {
                    that.props.setAgentStatus(currentAgentStatus);
                    // not user-defined states from agent, try to notify user
                    if(!_.includes(allStates, currentAgentStatus) && that.props.ccpStatus === null) { 
                      that.props.setCcpStatus('open');
                    }
                }

            // End ------------- 08/09 change ------------------
            });

            // agent.onError((error) => {
            //   const message = _.get(error, 'message') || 'Agent Error';
            //   CCPClass.saveError(message);
            // });

            agent.onSoftphoneError((error) => {
              // network/ permission error will not be caught here
              // this.handleAutoLogoutAWS(error);
              CCPClass.saveError(error.getErrorMessage());
            });

            agent.onWebSocketConnectionLost(() => {
              // could happen because of no network in 2 minutes
              CCPClass.saveError('Websocket lost');
            });

            let bus = window.connect.core.getEventBus();
            const busSubscriptions = [];
            // bus.subscribe(window.connect.ContactEvents.ACW, this.handleClearContact);
            // Event published indicating that the most recent API call returned a status header indicating that
            // the current user authentication is no longer valid.
            // This usually requires the user to log in again for the CCP to continue to function.
            // See connect.initCCP() under Initialization for more information about automatic login popups
            // which can be used to give the user the chance to log in again when this happens.
            busSubscriptions.push(bus.subscribe(window.connect.EventType.AUTH_FAIL, this.handleAgentAuthFail));

            // get the agent init to set logged in
            busSubscriptions.push(bus.subscribe(window.connect.AgentEvents.INIT, this.handleAgentLogin));

            // handle shared worker terminated to set agent logged out
            busSubscriptions.push(bus.subscribe(window.connect.EventType.TERMINATED, this.handleAgentLogout));

            window.awsCCP.eventBus = {
              subscriptions: busSubscriptions,
            };
        });

        window.connect.contact((contact) => {
            window.awsCCP.contact = contact;
            contact.onConnecting(function handleContactConnected(contact) {
                if (contact) {
                    const contactId = contact.getContactId();
                    CCPClass.setCallContactData({
                      connectingTime: new Date(),
                      contactId,
                      isInbound: contact.isInbound()
                    });
                    // mark down date
                    that.props.setContact('onConnecting')
                    let attributes = contact.getAttributes()

                    if (contact.isInbound()) {           
                      sessionStorage.removeItem('outboundAttributes');
                      attributes = getInBoundAttributes(contact, attributes);
                       // prevent overwriting selected profile
                      if (CCPClass.shouldSetInboundAttributes(contactId)) {
                        sessionStorage.setItem('inboundAttributes', JSON.stringify(attributes));
                        
                        that.props.setInboundAttributes(attributes);
                      }
                    } else {
                      sessionStorage.removeItem('inboundAttributes');
                      const phoneNumber = contact.getInitialConnection().getEndpoint().phoneNumber;
                      // dial directly or in other tab
                      let outboundAttributes = { contactId, name: { value: phoneNumber }, unknown: { value: true }};
                      setTimeout(() => {
                        if (!sessionStorage.getItem('outboundAttributes')) {
                          sessionStorage.setItem('outboundAttributes', JSON.stringify(outboundAttributes));
                        }
                      }, 1500); // delay to prevent racing
                    }
                    that.props.setCcpStatus('open');
                }
            });

            contact.onConnected(function handleContactConnected(contact) {
                if (contact) {
                    if(that.props.ccpStatus === null) {
                      // when refresh the page
                      that.props.setCcpStatus('open');
                    }
                    that.props.setContact('onConnected')
                    that.props.setCallStickie(true);
                    const dateFormat = 'MM/DD/YYYY HH:mm:ss';
                    let startMoment = moment(new Date(), dateFormat)
                    // that.props.setLastCallStartTime(startMoment);
                    const contactId = contact.getContactId();
                    let attributes = contact.getAttributes()
                    let lastCallPatientId;

                    // IF it's INBOUND call
                    if (contact.isInbound()) {
                      let inboundAttributes;
                        if (CCPClass.shouldSetInboundAttributes(contactId)) {
                          attributes = getInBoundAttributes(contact, attributes);
                          sessionStorage.setItem('inboundAttributes', JSON.stringify(attributes));
                          that.props.setInboundAttributes(attributes);
                          inboundAttributes = { ...attributes };
                        } else {
                          inboundAttributes = JSON.parse(sessionStorage.getItem('inboundAttributes'));
                        }
                        inboundAttributes['startTime'] = startMoment;
                        setLocalStorageLastCall(inboundAttributes);
                        // Set the in-bound attributes to local storage
                        
                        const isNewPortal = !!_.get(inboundAttributes, 'newPortal.value');
                        let enrolledProgramId = _.get(inboundAttributes,'enrolledProgramId.value', undefined)
                        let inboundPatientId = _.get(inboundAttributes,'patientId.value', undefined)

                        // If the patient has enrolled program from inbound call
                        // it's an enrolled patient
                        CCPClass.toggleNotePopup(inboundPatientId, enrolledProgramId, isNewPortal);
                    } else if (!CCPClass.isUnknownOutbound()) {
                        // outbound call when click on phone number
                        // pop up call log
                        let outboundAttributes = JSON.parse(sessionStorage.getItem('outboundAttributes'));
                        outboundAttributes['startTime'] =startMoment;
                        setLocalStorageLastCall(outboundAttributes);
                        let patientId =  _.get(outboundAttributes,'patientId.value', undefined);
                        let operationStatus = _.get(outboundAttributes,'operationStatus.value');
                        let enrolledProgramId =  _.get(outboundAttributes,'enrolledProgramId.value', undefined);
                        enrolledProgramId = (operationStatus && operationStatus !== 'DISCHARGED') ? enrolledProgramId : undefined;

                        lastCallPatientId = atob(patientId).split(':')[1];
                        that.props.lastCallPatientIdAction(lastCallPatientId);

                        CCPClass.toggleNotePopup(patientId, enrolledProgramId);
                    }
                    Mixpanel.track('receive', 'phone', 'onConnected', { "PATIENT_ID": lastCallPatientId, "CONTACT_ID": contact.getContactId()  });
                    // save to storage
                    const phoneNumber = contact.getInitialConnection().getEndpoint().phoneNumber;
                    sessionStorage.setItem('call-center-last-call', JSON.stringify({ phoneNumber, startTime: startMoment.toISOString() }));
                    let historyDictString = localStorage.getItem('call-center-history') || '{}';
                    let historyDict = JSON.parse(historyDictString)
                    const aPhoneArray = _.get(historyDict, phoneNumber) || []
                    let alreadyHasThisContact = false;
                    if (aPhoneArray.length > 0) {
                        const contactId = contact.getContactId()
                        const filtedContactArray = aPhoneArray.filter(val => {
                            return _.get(val, 'contactId') == contactId
                        })
                        // this call has start time, refresh browser, cause that
                        if (filtedContactArray.length > 0) {
                            alreadyHasThisContact = true;
                        }
                    }

                    if (!alreadyHasThisContact) {
                        const aPhoneCallDict = { 'start': startMoment.format(dateFormat), 'contactId': contact.getContactId() }
                        if (aPhoneArray.length >= 3) {
                            aPhoneArray.shift()
                        }
                        aPhoneArray.push(aPhoneCallDict)
                        historyDict[phoneNumber] = aPhoneArray
                        localStorage.setItem('call-center-history', JSON.stringify(historyDict))
                    }

                } else {
                    // console.log("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
                }
            });
            contact.onEnded(function handleContactEnded(contact) {
                // if last state was onConnected then pop up
                //console.log("~~~~~~~~[contact.onEnded]" + contact.getContactId(), "status:" + contact.getStatus().type, 'agent status', window.awsCCP.agent.getState());
                if ( that.props.contactStatus !== 'onConnected'){
                    that.handleMissedCall()
                }
                that.props.setContact('onEnded');
                CCPClass.setCallContactData(null);
            });
            contact.onACW(function handleContactEnded(contact) {
              try {
                const dateFormat = 'MM/DD/YYYY HH:mm:ss';
                let endMoment = moment(new Date(), dateFormat)
                that.props.setLastCallEndTime(endMoment);

                let lastContact = JSON.parse(localStorage.getItem('call-center-last-contact') || '{}')
                lastContact['endTime'] = endMoment
                setLocalStorageLastCall(lastContact);

                    // patient id is not saved in lascall, saved in attribute
                    // DO NOT pop up call log if enrolledProgramId is undefine
                    // both GP or EP have patientId (lambda function has changed, GP has patientId)
                const { lastCallPatientId } = that.props;
                Mixpanel.track('receive', 'phone', 'onEnded', { "PATIENT_ID": lastCallPatientId, "CONTACT_ID": contact.getContactId() });
                // save duration to storage
                const phoneNumber = contact.getInitialConnection().getEndpoint().phoneNumber
                let historyDictString = localStorage.getItem('call-center-history') || '{}';
                let historyDict = JSON.parse(historyDictString)
                const aPhoneArray = _.get(historyDict, phoneNumber) || []
                if (aPhoneArray.length > 0) {
                    const contactId = contact.getContactId()
                    const filtedContactArray = aPhoneArray.filter( val => {
                        return _.get(val, 'contactId') == contactId
                    })
                    const thePhoneCallDict = filtedContactArray[0]
                    let timeDiff = Date.minutesBetween(moment(thePhoneCallDict.start,dateFormat), endMoment)
                    thePhoneCallDict['duration'] = timeDiff
                    localStorage.setItem('call-center-history', JSON.stringify(historyDict))
                }
              } catch(error) {
                CCPClass.saveError(error.message || 'onACW error');
              } finally {
                sessionStorage.removeItem('outboundAttributes');
                sessionStorage.removeItem('inboundAttributes');
              }
            });

            contact.onError((error) => {
              const message = _.get(error, 'message') || 'Contact Error';
              CCPClass.saveError(message);
            });
        });
    }

    handleAgentLogin = (agent) => {
         const agentChooseStatus = localStorage.getItem('agent-choose-status');
         if(agentChooseStatus !== 'LOGGED_OUT') {
           return;
         }

         // one call at a time
         let state = agent.getState()
         if (state && (state.name == 'CallingCustomer' || state.name == 'Busy')) {
             return
         }
         // no need to show missed call

         // Offline happens(1) logout (2) Agent set status to Offline
         // set Agent online, which is no need when portal users make calls
         // could be userful for inbound call
        //  if (state && state.name == 'Offline') {

            // [20220503] keep the status the same as in aws connect (status will be synced when agent is refreshed)
            //  let routableState = agent.getAgentStates().filter(function(state) {
            //      return state.type === global.connect.AgentStateType.ROUTABLE;
            //   })[0];
            //   agent.setState(routableState, {
            //      success: function() {
            //         localStorage.setItem('agent-choose-status', routableState.name);
            //       },
            //      failure: function() { }
            //   });
        //  }
    }

    handleAgentAuthFail = () => {

    }

    handleAgentLogout = () => {
      // reload the page when logout
      // tried relogin -> no subscription
      // tried login in the iframe, sometime no sound
      // reload is the best option so far.
      if (!this.state.logoutAWSwithoutReload) {
        window.location.reload(false);
      }
    }

    handleMissedCall() {
        let agent = window.awsCCP.agent
        let state = agent.getState()
        // for FailedConnectCustomer, CCP set agent routable automatically
        // MissedCallAgent
        // FailedConnectAgent agent click reject
        if (['MissedCallAgent', 'FailedConnectAgent'].includes(_.get(state, 'name'))) {
            let routableState = agent.getAgentStates().filter(function(state) {
                return state.type === global.connect.AgentStateType.OFFLINE;
             })[0];
             agent.setState(routableState, {
                success: function() { setTimeout(()=>{
                    let routableState = agent.getAgentStates().filter(function(state) {
                        return state.type === global.connect.AgentStateType.ROUTABLE;
                     })[0];
                     agent.setState(routableState, {
                        success: function() { },
                        failure: function() { }
                     });
                }, 3*60*1000) },
                failure: function() { }
             });
        }
    }
}


const mapState = ({ MainLayout, Nav, chat, AWSConnect, Stickie}, ownProps) => {
    return {
        ...MainLayout,
        ...Nav,
        userMap: chat.main.userMap,
        channels: chat.main.channels,
        selectedChannel: chat.main.selectedChannel,
        PNStatus: chat.main.PNStatus,
        institution: Nav.institution,
        contactStatus: AWSConnect.contact,
        lastCallPatientId: AWSConnect.lastCallPatientId,
        lastCallPatientName: AWSConnect.lastCallPatientName,
        lastCallEndTime: AWSConnect.lastCallEndTime,
        lastCallStartTime: AWSConnect.lastCallStartTime,
        minimizedNote: AWSConnect.minimizedNote,
        ccpStatus: AWSConnect.ccpStatus,
        providerNotesModalIsVisible: AWSConnect.providerNotesModalIsVisible,
        lastCallEnrolledProgramId: AWSConnect.lastCallEnrolledProgramId,
        showCreateComponent:Stickie.showCreateComponent,
        note:Stickie.note,
        callStickie:Stickie.callStickie,
        nonExistNotification: Stickie.nonExistNotification,
        useChs: chat.main.useChs
    }
};

const mapDispatch = (dispatch) => {
    return {
        openErrorModal: (errorMessage) => {
            const closeModalHandler = () => dispatch(closeModal())
            const modalProps = {
                size: 'sm',
                title: 'Warning',
                showHeaderCloseButton: false,
                footer: <IHButton key="ok" size="large" style={{ width: '82px' }} label="Ok" bsStyle="primary" onClick={closeModalHandler} />
            }
            dispatch(<ErrorMessageDiv errorMessage={errorMessage} />, modalProps)
        },
        openNonExistNotification: (content) => {
            const closeModalHandler = () => { dispatch(closeModal()); dispatch(noteActions.setNonExistNotification(false))}
            const modalProps = {
                size :'sm',
                title: 'Attention',
                showHeaderCloseButton: false,
                footer : <IHButton key="ok" size="large" style={{width:'82px'}} label="OK" bsStyle="primary" onClick={closeModalHandler} />
            }
            dispatch(openModal(content, modalProps))
        },
        addMessage: (channel, msg) => dispatch(actions.addMsg(channel, msg)),
        updateChannelInfo: (channel, msgObj, isbatch) => dispatch(actions.updateChannelInfo(channel, msgObj, isbatch)),
        addToUserMap: newUser => dispatch(actions.addToUserMap(newUser)),
        setUserMap: userMap => dispatch(actions.setUserMap(userMap)),
        addChatHistory: (...args) => dispatch(actions.addChatHistory(...args)),
        addChannels: (channelsObj) => dispatch(actions.addChannels(channelsObj)),
        updateUnreadCounter : (channel, channelInfoFromLastMessage)=>dispatch(actions.updateUnreadCounter(channel, channelInfoFromLastMessage)),
        measureDom: () => dispatch(measureDom(window.innerWidth, window.innerHeight)),
        updateApi: (useChs) => dispatch(actions.updateApi(useChs)),
        autoLogout: (event) => dispatch(autoLogout(event)),
        updatePNStatus: status => dispatch(actions.updatePNStatus(status)),
        setInstitution: inst => dispatch(setInstitution(inst)),
        setRemountMainLayout: flag => dispatch(setRemountMainLayout(flag)),
        setOrgTeamMap: map => dispatch(actions.setOrgTeamMap(map)),
        setIsFullyLoaded: flag => dispatch(actions.setIsFullyLoaded(flag)),
        setContact: flag => dispatch(callActions.contact(flag)),
        providerNotesModalIsVisibleAction: flag => dispatch(callActions.callStatus(flag)),
        lastCallPatientIdAction: flag => dispatch(callActions.lastCallPatientId(flag)),
        // setLastCallPatientId: flag => dispatch(callActions.lastCallPatientId(flag)),
        // setLastCallEnrolledProgramId: flag => dispatch(callActions.lastCallEnrolledProgramId(flag)),
        // setLastCallPatientName: flag => dispatch(callActions.lastCallPatientName(flag)),
        setLastCallEndTime: flag => dispatch(callActions.lastCallEndTime(flag)),
        setLastCallStartTime: flag => dispatch(callActions.lastCallStartTime(flag)),
        setMinimizedNote: name => dispatch(callActions.setMinimizedNote(name)),
        setInboundAttributes: flag => dispatch(callActions.inboundAttributes(flag)),
        setAgentStatus: flag => dispatch(callActions.agentStatus(flag)),
        setCcpStatus: flag => dispatch(callActions.ccpStatus(flag)),
        setShowCreateComponent: flag => dispatch(noteActions.noteStatus(flag)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        setNote: flag => dispatch(noteActions.setNote(flag)),
        setCallStickie: flag => dispatch(noteActions.setCallStickie(flag)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
        setNonExistNotification: flag => dispatch(noteActions.setNonExistNotification(flag)),
        resetReduxStore: (persistedStates) => dispatch(resetState(persistedStates)),
    }
};

export default compose(
    connect(mapState, mapDispatch)
)(Container);
