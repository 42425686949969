import Todo from './schema/Todo'
import gql from 'graphql-tag'

export default gql`
  mutation editTodo(
    $id:EID!,
    $description:String,
    $priority:TodoPriority,
    $completedAt:Date,
    $dueDate:Date
  ){
    editTodo(
      id:$id,
      description:$description,
      priority:$priority,
      completedAt:$completedAt,
      dueDate:$dueDate
    ){
      ${Todo}
    }
  }
`
