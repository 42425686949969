import types from 'ProgramEditModule/ProgramInfo/constants/ProgramInfoFormConstants'
import { commonReducer } from 'libModule/helpers/reducer-helpers'

const initState = {
  isFormValid: true,
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.RESET_FORM:
      return initState
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
