import React, { Fragment } from 'react';
import { CellularAssignedDevicesComponent } from '../../components/CellularAssignedDevicesComponent/CellularAssignedDevicesComponent';
import { CellularDevicesAssignFormContainer } from '../CellularDevicesAssignFormContainer/CellularDevicesAssignFormContainer';
import { Divider } from 'antd';
import { useCellularDevicesContext } from '../../context/CellularDevicesContext';

import './CellularConnectedDevicesContainer.scss';

export const CellularConnectedDevicesContainer = () => {
  const {
    cellularDevices,
    handleAssignDevice,
  } = useCellularDevicesContext();

  return (
    <div className="cellular-devices-connected-devices">
      <CellularDevicesAssignFormContainer 
        onSubmit={handleAssignDevice}
      />
      {
        cellularDevices.length
          ? (
            <Fragment>
              <Divider />
              <CellularAssignedDevicesComponent devices={cellularDevices} />
            </Fragment>
          ) : null
      }
    </div>
  );
};
