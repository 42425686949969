import gql from 'graphql-tag';
export default gql`mutation assignLoanDevice($memberId:EID!,$serialNumber:String,$deviceId:String) {
  assignLoanDevice(
    serialNumber:$serialNumber, 
    deviceId:$deviceId,
    memberId:$memberId
  ) {
    deviceId
    serialNumber
    osVersion
    appVersion
    status
    member {
      profile {
        firstName
        lastName
      }
    }
  }
}`;
