import React from 'react';
import { Steps,Button,Input,Form } from 'antd';
const { Step } = Steps;
import { compose, graphql } from 'react-apollo/index';
import API from '../../API/index'
import LanguageSetupComponent from '../component/LanguageSetupComponent';
import query from '../../query/consentFormQuery'
import helper from '../helper/index';
let ConsentFormModalComponent = class extends React.Component{
    constructor(props) {
        super(props);
        const name = _.get(props,'templateGroup.name',null);
        const consentTemplateList = _.get(props,'consentTemplateList',[]);
        this.state = {
            current: 0,
            name: name,
            templateGroupsList: null,
            selectedLanguages:_.map(consentTemplateList,c=>c.language.code)
        }
    }
    setSelectedLanguages=(selectedLanguages)=>{
        this.setState({
            selectedLanguages
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const preConsentTemplateList = prevProps.consentTemplateList;
        const consentTemplateList = this.props.consentTemplateList;
        if(!_.isEqual(preConsentTemplateList,consentTemplateList)){
            this.setSelectedLanguages(_.map(consentTemplateList,c=>c.language.code));
        }
    }

    handleCreateTemplateGroupSuc = (args)=>{
        const { refetchTemplateGroupList,setShowModal,id } = this.props;
        const current = _.get(args,'current');
        const templateGroupId = _.get(args,'data.createTemplateGroup.id',id);
        this.setState({ current,templateGroupId },()=>{
            setShowModal&&setShowModal(true,templateGroupId)
        })
    }

    next() {
        const { name } = this.state;
        const { id,form:{ validateFieldsAndScroll} } = this.props;
        const current = this.state.current + 1;
        validateFieldsAndScroll((err,res)=>{
            if(!err) {
                helper.createTemplateGroup(name, this.handleCreateTemplateGroupSuc, {current, id});
            }
        })
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    renderTemplateGroup=()=>{
        const { form:{ getFieldDecorator }} = this.props;
        const name  = this.state.name ||_.get(this,'props.templateGroup.name');
        return <div className={'templateGroupInput'}>
                <Form>
                    <Form.Item label='name'>
                        {
                            getFieldDecorator(`name`, {initialValue:name, rules: [{ required: true }]})(<Input onChange={(e)=>this.setState({
                                name:e.target.value
                            })}/>)
                        }
                    </Form.Item>
                </Form>
               </div>
    }


    renderSteps = ()=>{
        const { renderTemplateGroup,props,setSelectedLanguages } = this;
        const { consentTemplateList,refetchConsentTemplateList,id,setShowModal,onCancel } = props;
        const { current,selectedLanguages } = this.state;
        const steps = [ { title:'Template name',content:renderTemplateGroup },
                        { title:'Language set up',content:()=><LanguageSetupComponent
                                                                id={id}
                                                                setSelectedLanguages={setSelectedLanguages}
                                                                selectedLanguages={selectedLanguages}
                                                                refetchConsentTemplateList={refetchConsentTemplateList}
                                                                consentTemplateList={consentTemplateList}/> }
                      ];
        return (
            <div className='templateStepsContainer'>
                <div className='templateStepsUpper'>
                    <Steps current={current} direction={'vertical'}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content()}</div>
                </div>
                <div className="steps-action">
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()} className={'next'}>
                            Next
                        </Button>
                    )}
                    {current > 0 && (
                        <Button  className={'previous'} onClick={() => this.prev()}>
                            Previous
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" className={'done'} onClick={()=>onCancel(false,null)}>
                            Done
                        </Button>
                    )}
                </div>
            </div>
        );
    }
    render() {
        const { renderSteps } = this;
        return renderSteps();
    }
}/*
const getTemplateGroup = graphql(query.getTemplateGroupQuery,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables:{
                id
            },
            skip: !id,
            fetchPolicy:'network-only'
        }
    },
    props:({data} )=>{
        const templateGroup = _.get(data,'getTemplateGroup');
        return {
            templateGroup
        }
    }
})
const getConsentTemplateList = graphql(query.getConsentTemplateListQuery,{
    options:(ownProps)=>{
        const { id } = ownProps;
        return {
            variables:{
                groupId:id
            },
            skip: !id,
            fetchPolicy:'network-only'
        }
    },
    props:({data} )=>{
        const consentTemplateList = _.get(data,'getConsentTemplateList');
        const refetchConsentTemplateList = _.get(data,'refetch');
        return {
            consentTemplateList,
            refetchConsentTemplateList
        }
        // const templateGroup = _.get(data,'getTemplateGroup');
        // return {
        //     templateGroup
        // }
    }
})*/
ConsentFormModalComponent = Form.create()(ConsentFormModalComponent);
export  default ConsentFormModalComponent
// compose(getTemplateGroup,getConsentTemplateList)(ConsentFormModalComponent);