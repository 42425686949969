import React from 'react';
import { graphql } from "react-apollo/index";
import { compose } from 'recompose';
import { IHLoading } from 'ihcomponent';
import api from '../../../../VideoChat/API';
import { CONSENT_TYPE } from '../../constants';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// font names must be matching with names in S3 bucket
const FONT_NAME_REGULAR = 'ArialUnicodeMS.ttf';
const FONT_NAME_BOLD = 'ArialUnicodeMS-Bold.ttf';

const getFilename = name => `${window.location.origin}/${name}`;

const replacementString = {
  patientName: 'PatientBIU',
  doctorName: 'DoctorBIU',
  effectiveDate: 'MM/DD/YYYY'
};

const DEFAULT_CONTAINER_ID = 'consent-template';

class ConsentFormTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: 'UNKNOWN',
      currentTime: moment(),
      pdfMakeOptions: {
        defaultStyle: { font: 'Roboto' } // defined in pdfMake.fonts
      }
    };
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
      },
    };
  }

  updatePDFFont = _.debounce((language) => {
    let defaultStyle;
    // only get special font for Chinese languages
    if (_.includes(['MN', 'CT'], language)) {
      const fontNameExt = _.split(FONT_NAME_REGULAR, '.')[1];
      const fontKey = _.replace(FONT_NAME_REGULAR, `.${fontNameExt}`, '');
      pdfMake.fonts = {
        [fontKey]: {
          normal: getFilename(FONT_NAME_REGULAR),
          italics: getFilename(FONT_NAME_REGULAR),
          bold: getFilename(FONT_NAME_BOLD),
          bolditalics: getFilename(FONT_NAME_BOLD),
        }
      };
      defaultStyle = { font: fontKey }; // refer to pdfmake docs
    } else {
      pdfMake.fonts = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-Italic.ttf'
        }
      };
      defaultStyle = { font: 'Roboto' };
    }
    this.setState({ pdfMakeOptions: { defaultStyle } }, () => {
      this.handleChange();
    });
  }, 1000);

  componentDidMount() {
    this.handleChange();
    this.getIPAddress().then(ip => {
      this.setState({ ip })
    }).catch(e => console.log(e));
  }

  componentWillUnmount = () => {
    pdfMake.fonts = {}; // prevent fonts called next time
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loadingTemplate, consentTemplate, language, patientId,
      patientName, doctorsNames, effectiveDate, onChange, errorTemplate,
      openGraphQLErrorModal, careTeamFullname, consentType, consentProviderName
    } = this.props;
    const { ip } = this.state;

    if (prevProps.loadingTemplate && !loadingTemplate && errorTemplate) {
      if (openGraphQLErrorModal) openGraphQLErrorModal(errorTemplate);
      return;
    }

    if (
      !loadingTemplate 
      && consentTemplate
      && prevProps.loadingTemplate !== loadingTemplate) {
      this.updatePDFFont(_.get(consentTemplate, 'language.code'));
      return;
    }

    if (
      !loadingTemplate && consentTemplate && (
        prevProps.consentTemplate !== consentTemplate
        || prevProps.language !== language
        || prevProps.patientId !== patientId
        || prevProps.patientName !== patientName
        || !_.isEqual(prevProps.doctorsNames, doctorsNames)
        || !prevProps.effectiveDate.isSame(effectiveDate)
        || prevProps.onChange !== onChange
        || prevProps.consentType !== consentType
        || prevProps.careTeamFullname !== careTeamFullname
        || prevState.ip !== ip
        || prevProps.consentProviderName !== consentProviderName
      )) {
      this.handleChange();
    }
  }

  handleChange = () => {
    const { onChange, patientName, doctorsNames, effectiveDate, consentProviderName } = this.props;
    this.addOrUpdateTemplate();
    if (onChange) {
      const template = this.populateTemplate();
      if (template) {
        this.convertToPDF(template + this.getVerificationOfConsent()).then(b64PDF => {
          onChange({
            content: b64PDF,
            isValid: patientName && (doctorsNames.length > 0 || consentProviderName) && effectiveDate,
          });
        })
      }
    }
  }

  convertToPDF = (htmlContent) => {
    return new Promise((resolve) => {
      const pdf = htmlToPdfmake(htmlContent);
      pdfMake.createPdf({
        content: pdf,
        ...this.state.pdfMakeOptions
      }).getBase64(b64 => {
        resolve(b64);
      });
    });
  }

  getIPAddress = () => {
    return new Promise((resolve, reject) => {
      const API_URL = `https://www.cloudflare.com/cdn-cgi/trace`;
      const xhttp = new XMLHttpRequest();
      function onDataRecieve() {
        if (xhttp.readyState === XMLHttpRequest.DONE) {
          if (xhttp.status === 0 || (xhttp.status >= 200 && xhttp.status < 400)) {
            const ipRegex = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/
            const ip = xhttp.responseText.match(ipRegex)[0];
            return resolve(ip);
          }
          return reject('Couldn\'t get the ip address!');
        }
      }
      xhttp.onreadystatechange = onDataRecieve;
      xhttp.open("GET", API_URL, true);
      xhttp.send();
    });
  }

  getVerificationOfConsent = () => {
    const { patientId, patientName, consentType, careTeamFullname, callInfo } = this.props;
    const { ip, currentTime } = this.state;

    if (consentType === CONSENT_TYPE.APP) {
      return '';
    }

    let verification = '<div style="text-align: center; margin: 20px 0;"><strong>Verification of Consent</strong></div>';

    if (consentType === CONSENT_TYPE.VERBALLY) {
      verification += `<p style="margin-bottom: 20px;">Care team member ${careTeamFullname} obtained verbal consent from patient ${patientName}.</p>`
    }
    verification += '<div><strong>Signing Information:</strong></div>';
    verification += `<div>Name: ${patientName}</div>`;
    verification += `<div>Date: ${currentTime.toISOString()} (UTC time)</div>`;
    verification += `<div>User ID: ${patientId}</div>`;
    verification += '<div style="margin-top: 20px;"><strong>Care Team Member Device Information:</strong></div>';
    verification += `<div>IP Address: ${ip}</div>`;

    if (consentType === CONSENT_TYPE.VERBALLY && callInfo) {
      verification += '<div style="margin-top: 20px;"><strong>Call Log Evidence:</strong></div>';
      if (callInfo.callDate) {
        verification += `<div>Call Date and Time: ${callInfo.callDate}</div>`;
      }
      verification += `<div>Caller: ${callInfo.caller}</div>`;
      verification += `<div>Logged-in User ID: ${callInfo.callerId}</div>`;
      if (callInfo.callNumber) {
        verification += `<div>Call Number: ${callInfo.callNumber}</div>`;
      }
    }

    return verification;
  }

  populateTemplate = () => {
    const {
      consentTemplate, patientName, doctorsNames, effectiveDate, errorTemplate, consentProviderName
    } = this.props;
    const doctorName = consentProviderName ? consentProviderName : ((doctorsNames || []).join(', '));
    if (consentTemplate) {
      let innerHTML = _.get(consentTemplate, 'content');
      if (!innerHTML) innerHTML = _.get(errorTemplate, 'message', '');
      innerHTML = innerHTML.replace(replacementString.patientName, patientName);
      innerHTML = innerHTML.replace(replacementString.doctorName, doctorName);
      innerHTML = innerHTML.replace(replacementString.effectiveDate, effectiveDate.format('MM/DD/YYYY'));

      return innerHTML;
    }

    return '';
  }

  addOrUpdateTemplate = () => {
    const innerHTML = this.populateTemplate();
    if (innerHTML) {
      const containerId = this.props.containerId || DEFAULT_CONTAINER_ID;
      document.getElementById(containerId).innerHTML = innerHTML;
    }
  }

  render() {
    const { loadingTemplate, containerId = DEFAULT_CONTAINER_ID } = this.props;

    if (loadingTemplate) {
      return <IHLoading />;
    }
    return (
      <div id={containerId}></div>
    );
  }
}

const consentTemplate = graphql(api.getConsentTemplate, {
  options: (ownProps) => {
    const { language, languageGroupId } = ownProps;

    const variables = { language };
    if (languageGroupId) {
      variables.groupId = languageGroupId;
    }

    return {
      variables,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const { getConsentTemplate, loading, error } = data;

    if (loading) {
      return {
        loadingTemplate: loading
      };
    }
    return {
      errorTemplate: error,
      consentTemplate: getConsentTemplate || {},
    };
  }
})

export default compose(consentTemplate)(ConsentFormTemplate);