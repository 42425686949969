import {
  React, IHSearchTable, _, IHCheckbox
} from 'ihcomponent'
import { getRefIDWithRoleCategory} from 'libModule/utils'
import { graphql, compose } from 'react-apollo'
import userListGQL from 'modulesAll/graphql/userList'
import UserClass from 'modulesAll/graphql/class/User'
import CareTeamClass from 'modulesAll/graphql/class/CareTeam'
import  actions from '../actions/EditCareTeamActions'
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import addTeamMember from '../../query/addTeamMember';
import Client from 'libModule/gqlClient';

import removeTeamMember from '../../query/removeTeamMember';

const Component = class extends React.Component {
  static displayName = 'careteam/editCareTeam/containers/DoctorTable'
  componentWillUnmount() {
    this.props.onLoadParams(null);
  }

  render(){
    const p = this.getTableProps();
    return (
      <div className="v-table">
        <p>Select Doctors</p>
        <IHSearchTable {...p} />
      </div>
    )
  }

  componentWillReceiveProps(nextProps){
    const { updateUserList } = this.props;
    const nextUserList = _.get(nextProps,'data.userList.data',[]);
    const addedDoctors = _.get(nextProps,'addedDoctors');
    if(nextUserList.length>0&&!addedDoctors){
        updateUserList(nextUserList,'addedDoctors');
    }
  }

  changeSelect(id, type, checked){
    const  {doctors, p_doctors,removeFromSelectedMem,addToSelectedMem} = this.props
    const modified_doctors =  Object.assign([], doctors)
    const modified_p_doctors = Object.assign([], p_doctors)
    if(checked){
      if(type === 'member'){
        _.remove(modified_doctors, (v)=>v===id)
        removeFromSelectedMem(id);
      }
      _.remove(modified_p_doctors, (v)=>v===id)
    }
    else{
      if(type === 'primary'){
        modified_p_doctors.push(id)
      }
      modified_doctors.push(id)
      addToSelectedMem(id);
    }
    this.props.changeDoctor(modified_doctors, modified_p_doctors)
  }

  getTableProps(){
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, userList } = data;
    const teamId = _.get(this,'props.teamData.id','');

    return {
      title: `Doctors (${_.get(userList, 'pageInfo.total', 0)})`,
      columns: [
        {
          title: ' ',
          key: 'row_s',
          width: '40px',
          render: (t, doc) => {
            const memberId =  doc._get('id');
            const checked = _.includes(this.props.doctors, memberId);
            return <IHCheckbox checked={checked}
                               onChange={()=>{
                                 Client.mutate({
                                     mutation: !checked ? addTeamMember : removeTeamMember,
                                     variables:{
                                         teamId,
                                         memberIds:[doc._get('id')]
                                     }
                                 }).then(()=>{
                                     this.changeSelect.bind(this, doc._get('id'), 'member', checked)();
                                 }).catch(e=>{
                                   console.log(e);
                                 })
                               }}/>
          },
          // comment out reason because we are using paging, each time we only retrieve portion of userList from backend and thus below is not possible to do sort in memory
          sorter: (a, b) => {
            return (_.includes(this.props.doctors, a._get('id')) ? 1 : 0) - (_.includes(this.props.doctors, b._get('id')) ? 1 : 0)
          },
          sortOrder: 'descend'
        },
        {
          title: 'Name',
          key: 'Name',
          render: (t, doc) => {
            return doc._get('profile.fullName')
          }
        },
        {
          title: 'Specialty',
          key: 'Specialty',
          render(t, doc){
            return doc.format('specialty')
          }
        },
        {
          title: 'Contact Number',
          key: 'Contact Number',
          render(t, doc){
            return doc.format('mobilePhone')
          }
        },
        // {
        //   title: 'Mark as Primary',
        //   key: 'Mark as Primary',
        //   render: (t, doc) => {
        //     const checked = _.includes(this.props.p_doctors, doc._get('id'))
        //     return <IHCheckbox checked={checked}
        //                        onChange={this.changeSelect.bind(this, doc._get('id'), 'primary', checked)}/>
        //   }
        // }
      ],
      pagination: {
        pageSize: 5
      },
      dataSource: _.map(_.get(userList, 'data', []) , (u) => new UserClass(u)),
      inputPlaceholder: 'Search by Name',
      autoFocus: false,
      onSearch: onTableSearch,
      onChange: onTableChange,
      onSearchEnter,
      initSearchValue : search,
      loading

    }

  }
  runOnceAfterDataLoading(np){
    const { teamData } = this.props
    if(teamData){
      const team = new CareTeamClass(teamData)
      const members = team._get('members')
      const doctors = []
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
      const organizationId = _.get(currentUser,'selectedRole.organization.id','');
      _.each(members, (u)=>{
          const memberAllRoles = u.user.allRoles;
          const roleObject = _.find(memberAllRoles,(d)=>d.organization.id===organizationId);
          if(roleObject.category === 'PROVIDER' && roleObject.name === 'Doctor') {
            doctors.push(u.user.id)
          }
      })

      const pm = team.format('primaryDoctors')
      const p_doctors = _.map(pm, (p)=>p._get('id'))
      np.changeDoctor(doctors, p_doctors)
    }
  }



  componentDidMount(){
    this.runOnceAfterDataLoading(this.props);
  }

}

const tableActions = createTableAction('admin_careteam_doctor_list');

const withData = graphql(userListGQL, {
  options : (ownProps)=>{
    return {
      variables : {
        page : 1,
        count : 9999, //Not using server pagination because client wants selected doctors/managers to be display on top
        filters : {
          roles : [ getRefIDWithRoleCategory('PROVIDER:Doctor') ],
          search: {
            fields: ['NAME'],
            match: _.get(ownProps, 'canSearch') ? _.get(ownProps, 'search', '') : _.get(ownProps, 'prevSearch', ''),
          }
        },
        sort : {}
      },
      fetchPolicy:'network-only'
    }
  }

})

const mapDispatchToProp = (dispatch) => {
  return {
    ...tableActions,
    changeDoctor: (doctors, p_doctors) => dispatch(actions.changeDoctor(doctors, p_doctors)),
  }
}


export default compose(
  connect(
    (state)=>{
      const param = state.Careteam.CareTeamEdit
      return {
        ...param,
        ...state.globalTables.admin_careteam_doctor_list,
      }
    }
    , mapDispatchToProp),
  withData,
)(Component);
