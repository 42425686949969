import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ArticlesContainer from '../../careplanNew/container/ArticlesContainer';


import './ChatSendArticleButtonComponent.scss';

const ChatSendArticleButtonComponent = ({
  patientId,
  patientLanguageSettings,
  onInteraction,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="chat-send-article-attachment-button">
      <img 
        src="image/article-attachment.svg" 
        onClick={() => setShowPopup(true)}
      />
      <Modal
        visible={showPopup}
        onCancel={() => setShowPopup(false)}
        width="70vw"
        footer={null}
        destroyOnClose
        overlayClassName="chat-send-article-attachment-button-modal"
      >
        <div className="chat-send-article-attachment-button-modal__body">
          <ArticlesContainer
            patientId={patientId}
            patientLanguageSettings={patientLanguageSettings}
            onInteraction={onInteraction}
          />
        </div>
      </Modal>
    </div>
  );
};

ChatSendArticleButtonComponent.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default ChatSendArticleButtonComponent;