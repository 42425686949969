const WeakUserAuthentication = `
  id
  mobile {
    type
    countryCode
    number
    verified
  }
  token
  role {
    category
  }
  isExpired
`
export default WeakUserAuthentication
