import React from "react";
import {Button, Select,InputNumber,Icon,Popover, Modal,message,Form } from 'antd';
import { IHBaseFormWithRow } from 'ihcomponent'
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram'
import { enrolledProgram as updateQueries } from 'graphqlModule/updateQueries/enrolledProgram'
import { graphql, compose } from 'react-apollo'
import { generateScheduleForDB,addTemplateDetails,convertDBToSchedule, getDefaultThreshold }from 'modulesAll/utils/helpers/task.js';
import { VITAL_TYPE_MAP_FROM_SHORT,VITAL_TYPE_MAP } from 'modulesAll/utils/constants/task'
import { connect } from 'react-redux'
import enrolledProgram from 'modulesAll/graphql/enrolledProgram';
import vitalsHelper from '../helpers/vitalsHelpers';
const { Option } = Select;
import { modalAction } from 'modulesAll/common/actions'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import AddNewVitalComponent from './AddNewVitalComponent';
import '../style/index.scss';
import I18N from "../../I18N";
import { CGMDropdownContainer } from '../../CGM/containers/CGMDropdownContainer';
import { CGMHelperServices } from '../../CGM/helpers';
import { makeCGMContextConsumer } from '../../CGM/context/makeCGMContextConsumer';
const freqMap = {
    'WEEKLY':7
}

const vitalsShouldNotAddedAlone = 'Exercise Goal';
const VitalsMonitoringComponent = class extends React.Component{

    constructor(props) {
        super(props);
        const { program, cgmContextValue } = props;
        const { hasCGM } = cgmContextValue || {};
        const { tasks } = program;
        const enrolledVitals = _.map(tasks,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]);
        if (hasCGM) enrolledVitals.push(CGMHelperServices.brandFieldName);
        const BG = _.first(_.filter(tasks,(t)=>t.type=='BG'))||{};
        let otherVitals = {} ;
        _.forEach(tasks,({ repeat,schedule,type,templateDetails}) =>{
            const target = _.get(schedule,'0.target');
            otherVitals[VITAL_TYPE_MAP_FROM_SHORT[type]] = {
            templateDetails,schedule,type,target,repeat,
            frequencyValue:{
                times:target,
                frequency:repeat
            }
        }
        });
        this.state = {
            enrolledVitals,
            selectedVitals:enrolledVitals,
            BGSchedule:BG ? convertDBToSchedule(BG) : {},
            // initialBGSchedule:BG,
            ...otherVitals,
            showAddModal: false,
            bgError: false,
        }
        this.baseForm = null;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const tasks = _.get(this,'props.program.tasks');
        const enrolledVitals = _.get(this,'state.enrolledVitals');
        const enrolledVitalsFromEP = _.map(tasks,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]);
        if (_.get(this, 'props.cgmContextValue.hasCGM')) {
          enrolledVitalsFromEP.push(CGMHelperServices.brandFieldName);
        }
        if( !_.isEqual(enrolledVitalsFromEP,enrolledVitals)) {
            this.setState({
                enrolledVitals:enrolledVitalsFromEP
            })
        }
    }

    setSelectedVitals = (selectedVitals)=>{
        this.setState({
            selectedVitals
        })
    }
    parseBGSchedule = (vitalData,vitalType, selectedTaskId)=>{
        let schedule  = vitalData;
        const { props,state,parseVitalSchedule,parseBGSchedule } = this;
        const { program,editEnrolledProgram } = props;
        const { selectedVitals,BGSchedule={} } = state;
            // _.get(vitalData,'value',{});
        let frequency = 'weeklyMeal';
        schedule.vitalType = vitalType;
        schedule.frequency = frequency;
        const { templateDetails } = addTemplateDetails(BGSchedule);

        // schedule.weeklyMealSchedule = _.get(vitalData,'weeklyMealSchedule');//if there is no change now;
        return {
            id:selectedTaskId,
            schedule:generateScheduleForDB({schedule,vitalType},),
            type:VITAL_TYPE_MAP[vitalType],
            repeat:'SCHEDULED',
            templateDetails
        }

    }
    parseVitalSchedule = (vitalData,vitalType,selectedTaskId)=>{
        let schedule  = _.get(vitalData,'value',{});
        let times  = _.get(vitalData,'frequencyValue.times');
        const frequency  =  _.get(vitalData,'frequencyValue.frequency');
        schedule.vitalType = vitalType;
        schedule.frequency = 'frequency';
        schedule.frequencyValue = {
            times
        }

        return {
            id:selectedTaskId,
            schedule:generateScheduleForDB({schedule,vitalType}),
            repeat:frequency,
            type:VITAL_TYPE_MAP[vitalType],
        }
    }


    parseTask = (v,threshold=[])=>{
        const { props,state,parseVitalSchedule,parseBGSchedule } = this;
        const { program,editEnrolledProgram } = props;
        const { selectedVitals,BGSchedule={} } = state;
        const tasksFromProgram = _.get(program,'tasks');
        let curVital = _.get(state,v);
        const selectedTask = _.find(tasksFromProgram,t=>VITAL_TYPE_MAP_FROM_SHORT[t.type]==v);
        const selectedTaskId = _.get(selectedTask,'id');
        const parseVitalScheduleVal = v==='Blood Glucose' ? parseBGSchedule(BGSchedule,v,selectedTaskId) : parseVitalSchedule( curVital,v,selectedTaskId);
        // const { templateDetails } = v==='Blood Glucose' ? addTemplateDetails(BGSchedule) :{ };
        // const frequency  = v==='Blood Glucose'? 'SCHEDULED' : _.get(curVital,'frequencyValue.frequency',_.get(curVital,'value.frequencyValue.frequency'))
        let task = {
            threshold,
            ...parseVitalScheduleVal
            // id:selectedTaskId,
            // type:VITAL_TYPE_MAP[v],
            // repeat: frequency,
            // schedule:generateScheduleForDB(parseVitalScheduleVal),
            // templateDetails
        }
        return task;
    }

    handleSubmit = async ()=>{      
        const { props,state,parseTask } = this;
        const { program,editEnrolledProgram,callback, form, patientId, cgmContextValue } = props;
        const { selectedVitals}  = state;
        const tasks = [];

        const shouldUpdateCGM = selectedVitals.includes(CGMHelperServices.brandFieldName);

        if (shouldUpdateCGM) {
          await CGMHelperServices.validateField(form);
          CGMHelperServices.saveBrandValue(form, patientId)
          .then((res) => {
            if (res && res.code === 200) {
              cgmContextValue.refetch();
            }
          });
        }

        // const defaultBGThreshold =
        for(let vIndex in selectedVitals){
          const v = selectedVitals[vIndex];

          let defaultThreshold = getDefaultThreshold(v, _.get(program, 'adminProgram'));

          const task = parseTask(v,defaultThreshold);
          // prevent creating BG task with blank schedule
          if(_.get(task, 'type') === 'BG' && !_.get(task, 'schedule.length')){
            return this.setState({ bgError: true });
          }
          if (task.type) {
            tasks.push(task);
          }
        }
        let variables = {
            id: program.id,
            tasks
        };
        try{
            const res = await editEnrolledProgram({
                    variables,
                    updateQueries, refetchQueries: ['lightResultsFromToday',{
                    query:enrolledProgram,
                    variables:{
                        id:program.id
                    }}]});
            if(res.data){
                callback(false);
                message.success('Vitals Updated');
            }
        }
        catch(e){
          console.error(e);
          Modal.error({ content: e.message });
        }
    }

    setShowAddModal=(showAddModal)=>{
        this.setState({
            showAddModal
        })
    }

    handleAdd = ()=>{


    }


    handleRemoveEnrolledVital = async (vitalType,enrolledVitals)=>{
      const { cgmContextValue } = this.props;
      const { selectedVitals } = this.state;
        if(_.filter(enrolledVitals,v=>v!=vitalsShouldNotAddedAlone).length==1){
            message.warning('Patient must have at least one vital in the care plan.');
            return;
        }
        const taskId = this.getVitalId(vitalType);
        const res = await vitalsHelper.openConfirmModal(this,taskId,vitalType);
        if (res && res.code === 200 && vitalType === CGMHelperServices.brandFieldName) {
          cgmContextValue.refetch();
          const newSelectedVitals = _.filter(selectedVitals, (type) => type !== vitalType);
          this.setSelectedVitals(newSelectedVitals);
        }
    }
    handleRemoveAddedVital = (vitalType) =>{
        const { selectedVitals } = this.state;
        this.setSelectedVitals(_.filter(selectedVitals,v=>v!==vitalType));
        this.setState({
            [vitalType]: null
        })
    }
    getVitalId = (vitalType)=>{
        const { program: { tasks } } = this.props;
        const vital = _.first(_.filter(tasks,(t)=>t.type==VITAL_TYPE_MAP[vitalType]));
        return _.get(vital,'id');
    }

    renderBGSchedule = ()=>{
        const  BG = _.get(this,'state.BGSchedule',{});
        let updatedInitialValue = {...BG};
        updatedInitialValue.frequency = 'weeklyMeal';
        const formProps = {
            itemList:[
                [
                    {
                        key: 'schedule',
                        label: 'Select Patient Self-Monitoring Schedule *',
                        type: 'timePickerBoxGroup',
                        vitalType:'Blood Glucose',
                        slotType: 'meal',
                        frequency:true,
                        className:'BGSchedule',
                        initialValue:updatedInitialValue,
                        showInitialValue: true,
                        onFieldsChange:(a,schedule)=>{
                            this.setState({
                              'BGSchedule': _.get(schedule,'schedule.value'),
                              bgError: false
                            })
                        }
                    }
                ]
            ]
        }
        return  <div>
                    <div className='vitalHeaderWrapper'>
                     <strong className={'vitalHeader'}>Blood Glucose management</strong>
                     {this.renderRemoveButton('Blood Glucose')}
                    </div>
                    <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }}/>
                    {
                      this.state.bgError &&
                      <div className='ant-form-explain' style={{ color: 'red' }}>
                        {I18N.get('vital.error.bg.emptySchedule')}
                      </div>
                    }
                </div>
    }

    addLocalVitals = (vitals)=>{
        this.setState({
            ...vitals
        })
    }

    renderAddVitalButton = ()=>{
        const { handleAdd,setSelectedVitals,state,setShowAddModal,addLocalVitals } = this;
        const { selectedVitals,showAddModal } = state;
        return <Popover content={
                                 <AddNewVitalComponent setSelectedVitals={setSelectedVitals}
                                                       selectedVitals={selectedVitals}
                                                       setShowAddModal={setShowAddModal}
                                                       addLocalVitals={addLocalVitals}
                                                       localThis={this}
                                 />}
                        visible={ showAddModal }
                        destroyTooltipOnHide={true}
                        trigger="click"
                        onVisibleChange={ setShowAddModal }
                        overlayClassName={'vitalPopover'}
                        title={'Choose a vital you want to add'}
                >
                 <Button style={{ marginRight: 10 }} className={'addVitalsBtn'}>Add Vitals</Button>
               </Popover>

    }


    renderSaveButton = ()=>{
        const { handleSubmit } = this;
        return <Button type={'primary'} onClick={handleSubmit} style={{ float:'right' }}>Save</Button>
    }

    renderRemoveButton = (vitalType)=>{
      const { handleRemoveEnrolledVital,handleRemoveAddedVital } = this;
      const { enrolledVitals } = this.state;

      if(_.includes(enrolledVitals,vitalType)) {
          return <strong className="vital-remove-btn remove-enrolled-vital" style={{ float: 'right', color: '#4278C3',fontWeight:'bold', cursor:'pointer' }}
                         onClick={() => handleRemoveEnrolledVital(vitalType,enrolledVitals)}>Remove</strong>
      }
      else{
          return <div className="vital-remove-btn" style={{ float: 'right' }}><Icon type="close" onClick={()=>handleRemoveAddedVital(vitalType)}/></div>
      }
    }

    setVitalInfo =(vitalType,unit,value)=>{

        if(unit=='times'&&(!value||!_.isNumber(value))) {
            value = 1;
        };

        let vital = this.state[vitalType]||{};
        _.set(vital,`frequencyValue.${unit}`, value);
        this.setState({
            [vitalType]:vital,
            bgError: false
        })
    }

    renderOtherVitalSchedule =(vitalType)=>{
        const { frequency,times } = _.get(this,`state.${vitalType}.frequencyValue`)||{};
        return <div style={{ display:'flex',flexDirection:'column',marginBottom:20,fontWeight:700 }}>
                    <div>
                        <strong className={'vitalHeader'}>{vitalType}</strong>
                        { this.renderRemoveButton(vitalType) }
                    </div>
                    <div>
                        <InputNumber min={1} max={7} style={{ width:220,marginRight:20 }}
                                     defaultValue={times}
                                     value={times}
                                     className='vitalValInput'
                                     onChange={(value)=>this.setVitalInfo(vitalType,'times',value)}
                        />
                        <Select defaultActiveFirstOption={true}
                                allowClear={ false }
                                defaultValue={frequency}
                                style={{ width:220 }}
                                className='vitalFreqInput'
                                onChange={(value)=>this.setVitalInfo(vitalType,'frequency',value)}
                        >
                            <Option value={'MONTHLY'}>Month</Option>
                            <Option value={'DAILY'}>Daily</Option>
                            <Option value={'WEEKLY'}>Week</Option>
                        </Select>
                    </div>
               </div>
    }

    renderVitals = ()=>{
        const { state,renderBGSchedule,renderVitalsCheckBox,renderSaveButton,renderOtherVitalSchedule,renderExercise } = this;
        const { selectedVitals } = state;
        const customVitalSchedule = ['Blood Glucose', 'Exercise Goal', CGMHelperServices.brandFieldName];

        let content =[];
        if(_.includes(selectedVitals,'Blood Glucose')) {
            content.push(<div className={'col-lg-12'} key={_.get(content,'length',0)+1}
                              style={{ display:'flex',justifyContent:'center'}}>
                                {renderBGSchedule()}
                         </div>);
        }
        let rightDiv = [];
        _.filter(selectedVitals,t=>!customVitalSchedule.includes(t)).forEach(t=>rightDiv.push(<div className={'row'} key={_.get(rightDiv,'length',0)+1}><div style={{ display:'flex' }}>{renderOtherVitalSchedule(t)}</div></div>))

        if (_.includes(selectedVitals, CGMHelperServices.brandFieldName)) {
          rightDiv.push((
            <div 
              className={'row vitals-monitoring-cgm'} 
              key={_.get(rightDiv,'length',0)+1}
            >
              <div className="vitals-monitoring-cgm__wrapper">
                { this.renderRemoveButton(CGMHelperServices.brandFieldName) }
                <CGMDropdownContainer form={this.props.form} />
              </div>
            </div>
          ));
        }

        if (_.includes(selectedVitals, 'Exercise Goal')) {
          rightDiv.push((
            <div 
              className={'row'} 
              key={_.get(rightDiv,'length',0)+1}
              style={{ marginLeft: -1 }}
            >
              <div style={{ display:'flex' }}>
                {renderExercise('exercise')}
              </div>
            </div>
          ))
        }

        content.push(
          <div 
            className={'col-lg-12 vital-monitoring-right'} 
            key={_.get(content,'length',0)+1} 
            style={{ display:'flex',flexDirection:'column',minWidth:500 }}
          >
            {rightDiv}
          </div>
        );
        return <div className={'row'} style={{ display:'flex' }}>
            {content}
        </div>
    }

    renderCloseButton = ()=>{
        const { callback } = this.props;
        return <Button onClick={()=>callback(false)} style={{ marginRight:10 }}>Cancel</Button>
    }
    renderButtons = ()=>{
        const { renderSaveButton,renderAddVitalButton,renderCloseButton } = this;
        return <div style={{ display:'flex',justifyContent:'flex-end',height:560,alignItems:'flex-end' }}>
                { renderAddVitalButton() }
                { renderCloseButton() }
                { renderSaveButton() }
               </div>
    }

    renderExercise = (index)=>{
        const tasks = _.get(this,'props.program.tasks',[]);
        const exercise = _.first(_.filter(tasks,t=>t.type==="EXERCISE"));
        const exerciseGoal = _.get(exercise,'threshold.0.exerciseGoal',{});
        const { timeUnit,target } = exerciseGoal;
        const stepsPerUnit = `${Math.round(target/freqMap[timeUnit])} Steps / Day `;
        const noExerciseStr = 'User hasn’t activated this function.'
        return <div className={'row'} key={index} style={{minWidth:455}}>
                <div style={{ display:'flex',flexDirection:'column' }}>
                    <div className='vitalHeaderWrapper'>
                        <strong className={'vitalHeader'}>Exercise</strong>
                    </div>
                    <div>
                        { timeUnit&&target ? stepsPerUnit:noExerciseStr}
                    </div>
                </div>
               </div>

    }

    render() {
        const { renderVitals,renderButtons,renderExercise } = this;
        return <div className='vitalMonitorComponent'>
                { renderVitals() }
                { renderButtons() }
               </div>;
    }
}


const mapToDispatch = (dispatch)=>{
    return {
        openConfirmModal: (props) => dispatch(modalAction.confirm(props)),
        openErrorModal:(e)=>dispatch(openErrorModal(e))
    }
}

const FormComponent = Form.create({})(VitalsMonitoringComponent);

export default compose( graphql(editEnrolledProgram, {name: 'editEnrolledProgram'}),connect(null,mapToDispatch))
(makeCGMContextConsumer(FormComponent));
