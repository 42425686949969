import gql from 'graphql-tag'
import Measure from './schema/Measure'

export default gql `
  mutation insertPO(
    $date: Date!,
    $timezone: String!,
    $bloodOxygen: POValue!,
    $imageUrl: String,
    $notes: String,
    $memberId: EID,
  ){
    insertPO(
      date: $date,
      timezone: $timezone,
      bloodOxygen: $bloodOxygen,
      imageUrl: $imageUrl,
      notes: $notes,
      manualInput: true,
      memberId: $memberId
    ) {
      ${Measure}
    }
  }
`
