import gql from 'graphql-tag'
import { ProgramWithoutCreator } from './schema/Program'

export default gql`
  query adminProgram($id: EID!) {
    adminProgram(id: $id) {
      ${ProgramWithoutCreator}
    }
  }
`
