import types from '../constants/Onboard'
import {commonReducer} from 'libModule/helpers/reducer-helpers.js'
// use to set form init data with antd's setFieldsValue method
const initData = {
  // password : '',
  // verificationCode: ''
}

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = {}
Object.keys(initData).forEach(key => {
  initFieldsValue[key] = {name: key, value: initData[key] }
})

const initState = {
  initData,
  fieldsValue: initFieldsValue,
  step: 1,
  loading: true
}




const ownReducer = (state = initState, action) => {
  switch(action.type) {

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case types.RESET:
      return initState
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
