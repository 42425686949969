import React from 'react';
import {graphql,compose} from "react-apollo/index";
import userGQL from "../../graphql/user";
import visitList from 'modulesAll/graphql/visitList';
import { IHLoading, IHButton, BaseComponent, message } from 'ihcomponent';
import MAPatientProfileComponent from '../component/MAPatientProfileComponent';
import {helpers} from "../../visit/helpers";
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { EP_COUNT_MAX, VISIT_COUNT_MAX } from '../../../lib/constants';

const MAPatientProfileContainer = class extends React.Component{

    render(){
        const { props } = this;
        const { user,loading,visitList,refetchVisitList,enrolledProgramList } = props;
        if(loading){
            return <IHLoading/>
        }
        const { profile:{ fullName } } = user;
        const { id } = user;
        const enrolledPrograms = _.filter(enrolledProgramList,({ status })=> status=='STARTED');
        return <MAPatientProfileComponent user={user} userId={id}
                                          visitList={visitList}
                                          refetchVisitList={refetchVisitList}
                                          enrolledPrograms={enrolledPrograms}
               />

    }
}


const visitListGgl = graphql(visitList,{
    options: (ownProps) => {

        const { filter,sort,year } = ownProps;
        const { pathname } = window.location;
        const id = pathname.split('/')[2];
        const  variables = {
            count: VISIT_COUNT_MAX,
        }
        const { provider } = filter&&filter.provider ? filter : { provider:_.reduce(helpers.filterDoctors(),(res,d)=>{
                    res.push(d.id)
                    return res;
                },[]
            )};
        variables.providerIds = provider;
        variables.memberId = id;

        return {
            variables,
            notifyOnNetworkStatusChange: false,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList,refetch } = data;
        if(loading){
            return {
                loading:loading
            };
        }
        let todayIndex = 1;
        let indexVisitList = _.map(visitList.data, v =>
            Object.assign(v, { index: todayIndex++ })
        );
        if(visitList&&visitList.data) {
            return {
                visitList: indexVisitList,
                refetchVisitList:refetch
            }
        }
    }
})

const withGql = graphql(userGQL, {
    options: (ownProps)=>{
        const { pathname } = window.location;
        const id = pathname.split('/')[2];
        return {
            variables : {
                id
            },
            notifyOnNetworkStatusChange: true,
        }
    },
    props:({ownProps,data})=>{
        const { loading,user } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            user
        }
    }
})
const wrapGQLData2 = graphql(lightEnrolledProgramList, {
    options: ownProps => ({
        variables: {
            page: 1,
            count: EP_COUNT_MAX, //need to get all of the enrolled programs, there's no pagination here
            filters: {
                memberId: (()=>{
                    const { pathname } = window.location;
                    return pathname.split('/')[2];
                })()
            },
            sort: {
                field: 'NAME',
                direction: 'ASC',
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data }) => {
        const { enrolledProgramList,loading } = data;
        if(loading){
            return {
                loading
            }
        }
        return { enrolledProgramList:enrolledProgramList.data, lightEnrolledProgramListRefetch: data.refetch }
    },
});
export default compose(withGql,visitListGgl,wrapGQLData2)(MAPatientProfileContainer);
