import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import Query from '../../../VideoChat/API/index';

import vitalListActions from '../../enrolledProgramInfo/Careplan/actions/VitalListActions';

import EnrollForm from '../../programTab/containers/main';
import EnrollButtonComponent from '../component/EnrollButtonComponent';

const initState = {
  showModal: false,
  isEnrollingPatient: false,
  isEnrollCompleted: false
};

const EnrollPatientContainer = class extends React.Component{
  constructor(props) {
    super(props);
    this.state = { ...initState }
  }

  setShowModal = (showModal)=> this.setState({ showModal });

  showEnrollButton = ()=>{
    const { enrollButtonOnClick, enrollButtonProps = {}, enrollButtonText = '' } = this.props;
    return <EnrollButtonComponent 
      setShowModal={this.setShowModal} 
      enrollButtonOnClick={enrollButtonOnClick}
      enrollButtonProps={enrollButtonProps} 
      enrollButtonText={enrollButtonText}
    />;
  }

  setIsEnrollingPatient = (isEnrollingPatient, cb) => this.setState({ isEnrollingPatient }, () => cb && cb());

  setIsEnrollCompleted = () => this.setState({ isEnrollCompleted: true, isEnrollingPatient: false });

  handleCloseModal = (e, closeProps = {}) => {
    if(this.state.isEnrollCompleted && this.props.onCompleteEnrollment)
      this.props.onCompleteEnrollment(closeProps);

    this.setState({ ...initState });
    this.props.resetVitalList();
  }

  renderModal = ()=>{
    const { showModal, isEnrollingPatient, isEnrollCompleted } = this.state;

    return <Modal 
      wrapClassName={`enroll-patient-container-modal ${isEnrollCompleted ? 'enroll-patient-completed' : ''}`}
      width='90%' 
      style={{ top: 64 }} 
      footer={false} 
      visible={showModal} 
      onCancel={this.handleCloseModal} 
      maskClosable={!isEnrollCompleted && !isEnrollingPatient}
      destroyOnClose
    >
      <EnrollForm 
        {...this.props}
        handleCloseModal={this.handleCloseModal}
        isEnrollingPatient={isEnrollingPatient}
        setIsEnrollingPatient={this.setIsEnrollingPatient}
        isEnrollCompleted={isEnrollCompleted}
        setIsEnrollCompleted={this.setIsEnrollCompleted}
      />
    </Modal>;
  }

  render() {
    return <div>
              { this.renderModal() }
              { this.showEnrollButton() }
            </div>;
  }

}

EnrollPatientContainer.propTypes = {
  user: PropTypes.object.isRequired,
  fromPlace: PropTypes.string.isRequired,
  enrollButtonOnClick: PropTypes.func,
  enrollButtonProps: PropTypes.object,
  enrollButtonText: PropTypes.string,
  templateContainerId: PropTypes.string, // use when 2 or more ConsentFormTemplate present in DOM tree
};

const getPatientConsent = graphql(Query.getPatientConsent, {
  options: (ownProps) => {
    const memberId = _.get(ownProps,'user.id');
    return {
      variables:{ memberId },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getPatientConsent, refetch, error } = data;

    if(error)
      console.error('EnrollPatientContainer getPatientConsent', error);

    return {
      loadingConsentInfo: loading,
      consentInfo: getPatientConsent || {},
      refetchConsentInfo: refetch
    };
  }
});

const mapDispatch = (dispatch, ownProps) => {
  return { 
    dispatch,
    resetVitalList: () => dispatch(vitalListActions.setVitalListForm([]))
  }
};

export default compose(
  getPatientConsent,
  connect(null, mapDispatch)
)(EnrollPatientContainer);