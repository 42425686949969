import React, { Component } from 'react';
import { Modal } from 'antd';
import { IHLoading } from 'ihcomponent';

import ModalImageComponent from './ModalImageComponent';
import ModalPdfComponent from './ModalPdfComponent';

export default class MessageThumbnailComponent extends Component {

  constructor(){
    super();
    this.state = {
        visible: false,
    }
  }

  showImageDialog = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };



  render() {
    const { fileKey, userId, memberFullName,thumbNailLink,onError,loading } = this.props;
    const modalParams = {
      onCancel: this.handleCancel,
      // title: memberFullName || 'File Upload',
      visible: this.state.visible,
      footer: null,
      width: 1200,
      height: 1200,


    };
    const isPdf = fileKey && fileKey.endsWith('.pdf');
    const name = fileKey ? fileKey.substring(fileKey.lastIndexOf('/') + 1 || 0, fileKey.length): '';

    if(loading){
      return <IHLoading/>
    }

    return (
      <div style={{marginTop: 10}} >
        {isPdf
          ? <React.Fragment>
              <a onClick ={this.showImageDialog}>{name}</a>
              <Modal {...modalParams} className='imageUploadModal'>
                <ModalPdfComponent name={name} link={thumbNailLink} />
              </Modal>
            </React.Fragment>
          : <React.Fragment>
              <a onClick ={this.showImageDialog}>
                <img src={thumbNailLink} alt={'Handling Image Uploading...'} onError={ onError } />
              </a>
              <Modal {...modalParams} className='imageUploadModal'>
                <ModalImageComponent fileKey={fileKey} userId={userId} />
              </Modal>
            </React.Fragment>
        }
      </div>
    );
  }
}
