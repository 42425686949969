import {
  React
} from 'ihcomponent'
import MemberDetail from './MemberDetail'
import TopBar from './TopBar'
import ProgramSummary from '../../programSummary/containers/main'

const Container = (props)=>{
    return (
        <div>
            <MemberDetail patientId="VXNlcjo1ODQ1NjJlZGIzMDIyZDEyMTRkYTQxMmU=" />
            <TopBar />

            <div className="vsm-main-page vsm-patient-main">
                <div className="vsm-main vsm-sm-main">
                    <ProgramSummary />

                </div>
            </div>
        </div>
    )
};

Container.displayName = 'patient/main/containers/ProgramSummaryMain';

export default Container;
