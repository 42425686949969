import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IHButton } from 'ihcomponent'
import { Tabs } from 'antd';
import { MAX_ALERT_TAB_COUNT } from 'modulesAll/utils/constants/task'
import AlertFormContainer from './AlertFormContainer'
import { actionCreators as FormActions } from 'patientModule/Careplan/Vital/ducks/VitalFormDuck'
import { actionCreators as actions } from '../ducks/AlertListDuck'

const TabPane = Tabs.TabPane;

class Container extends Component {
  static displayName = 'patient/VitalAlert/containers/AlertListContainer'

  render() {
    const addAlertBtn = this.renderAddAlertBtn()
    const tabs = this.renderTabs()
    return (
      <div className="card-container" style={{marginTop: 30}}>
        {addAlertBtn}
        {tabs}
      </div>
    )
  }
  renderAddAlertBtn() {
    const { toggleAlertForm, isShowAlertForm, resetForm } = this.props
    const styles = {
      footer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
      },
      btnLeft: {
        alignSelf: 'flex-start',
        marginBottom: 30
      },
    }
    const alertBtnProp = {
      onClick : (e)=>{
        this.addAlertTab()
        resetForm()
        toggleAlertForm()
      },
      size: 'exsmall',
    }
    const btn = isShowAlertForm ? '' : (
      <div style={styles.footer}>
        <div style={styles.btnLeft}>
          <IHButton {...alertBtnProp} bsStyle="primary" label="Add An Alert" />
        </div>
      </div>
    )
    return btn
  }
  renderTabs() {
    const { panes, onTabChange, activeKey, isShowAlertForm } = this.props

    const operations = panes.length < MAX_ALERT_TAB_COUNT ? this.renderAlertBtn() : ''
    const alertList = panes.map((pane, index) => {
      return (
        <TabPane tab={`Alert ${index+1}`} key={pane.key}>
          {pane.content}
        </TabPane>
      )
    })

    return !isShowAlertForm ? '' : (
      <Tabs
        tabBarExtraContent={operations}
        hideAdd
        onChange={onTabChange}
        activeKey={activeKey}
        type='card'
      >
        {alertList}
      </Tabs>
    )
  }
  addAlertTab() {
    const { panes, addTab } = this.props
    const removeBtn = this.renderRemoveAlertBtn()
    const lastPaneNum = panes.length > 0 ? Number(panes[panes.length-1].key.split('_')[1]) : 0
    const activeKey = 'key_' + (lastPaneNum+1)
    const formRef = `baseForm_${lastPaneNum+1}`
    const alertElem = (
      <div>
        <AlertFormContainer
          key={activeKey}
          ref={refNode => { this[formRef] = refNode }}
          formRef={formRef}
        />
        {removeBtn}
      </div>
    )
    const newPane = {
      content: alertElem,
      key: activeKey
    }
    const newPanes = panes.concat([newPane])
    addTab(newPanes, activeKey)
  }
  renderAlertBtn(label="Add Another Alert") {
    const style = {
      alignSelf: 'flex-end',
      position: 'relative',
      top: -5
    }
    const alertBtnProp = {
      size: 'exsmall',
      onClick : (e)=>{
        this.addAlertTab()
      }
    }
    const alertElem = (
      <div style={style}>
        <IHButton {...alertBtnProp} bsStyle="primary" label={label} />
      </div>
    )
    return alertElem
  }
  renderRemoveAlertBtn() {
    const { removeTab, resetForm } = this.props
    const style = {
      alignSelf: 'flex-end',
      position: 'relative',
      top: -5,
      textAlign: 'right'
    }
    const alertBtnProp = {
      size: 'small',
      onClick : ()=>{
        removeTab()
        resetForm()
      },
      className: "btn-ghost ghost-danger",
    }
    const alertElem = (
      <div style={style}>
        <IHButton {...alertBtnProp} label={'Delete'} />
      </div>
    )
    return alertElem
  }
}

const mapState = ({ patient }, ownProps) => {
  return {
    ...patient.Careplan.VitalAlert.AlertList,

  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators(Object.assign({}, FormActions.toggleAlertForm, FormActions.resetForm, actions), dispatch)
}

Container.propTypes = {
  panes: PropTypes.array,
  addTab: PropTypes.func,
  removeTab: PropTypes.func,
  onTabChange: PropTypes.func,
  resetForm: PropTypes.func,
  activeKey: PropTypes.string,
  isShowAlertForm: PropTypes.bool,
  toggleAlertForm: PropTypes.func,
}

export default connect(
  mapState, mapDispatch
)(Container)
