import React, { useCallback } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { CellularDeviceIdSearchDropdownFormItemComponent } from '../../components/CellularDeviceDropdownFormItemComponent/CellularDeviceIdSearchDropdownFormItemComponent';
import { CellularDeviceDisplayBrandComponent } from '../../components/CellularDeviceDisplayComponent/CellularDeviceDisplayBrandComponent';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularDevicesAssignModalFormContainer = Form.create()(({
  form,
  brand,
  onSubmit,
}) => {
  const handleSubmit = useCallback(_.debounce(() => {
    form.validateFieldsAndScroll((error, values) => {
      if (error) return;
      const valuesWithBrand = {
        brand,
        ...values,
      };
      if (onSubmit) onSubmit(valuesWithBrand);
    });
  }, 500), []);

  return (
    <Form>
      <Row>
        <Col className="cellular-devices-assign-form__title">
          Assign
          {' '}
          <CellularDeviceDisplayBrandComponent brand={brand} />
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
        >
          <CellularDeviceIdSearchDropdownFormItemComponent
            form={form}
            deviceType={CellularDevicesHelpers.BRAND_TO_DEVICE_TYPE_MAP[brand]}
          />
        </Col>
      </Row>
      <Row>
        <Col className="cellular-devices-assign-btn-wrapper">
          <Button
            type="primary"
            className="cellular-devices-assign-btn"
            onClick={handleSubmit}
          >
            Assign Device
          </Button>
        </Col>
      </Row>
    </Form>
  );
});