// code can string or function
// Numbers after the “. “ (Labeled here as *.x)= diagnosis reasons of etiology 
const codePrefixAbbrMap = {
  'I10': 'HTN',
  'E11': 'T2DM',
  'E78': 'Hyperlipidemia',
  'E66': 'Obesity',
  'N18': (name, x) => Number(x) < 5 ? `CKD ${x}` : name, // name: condition's full name, x: number for diagnosis reasons
};

// function to get code abbreviation
// @params:
//  code: ICD10 code, name: condition's full name
//  abbrList: to prevent duplicate common code abbr
export default (rawConditionName, abbrList) => {
  const [name, code] = _.split(rawConditionName, '::');
  // [0]: code prefix, [1]: diagnosis reasons
  const codeTokens = _.split(code, '.');
  const mappedName = _.get(codePrefixAbbrMap, codeTokens[0], name);
  let displayName = typeof mappedName === 'function' ? 
                      mappedName(name, codeTokens[1]) : mappedName;
  if(!_.includes(abbrList, displayName)) {
    abbrList.push(displayName);
  }
}