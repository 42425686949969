import type from '../constants/SearchPatientResultModal'
// import addNewPatientType from '../constants/AddNewPatient'
import _ from 'lodash'
import { convertToFieldsValue, getInitDataFromSchema } from 'libModule/helpers/reducer-helpers'
import { schema } from 'modulesAll/patientList/registerNew/components/formData/AddNewPatientForm'
import moment from 'moment'

export default {
  setList : (list)=>{
    return {
      type : type.SET_LIST,
      list
    }
  },

  show : (show)=>{
    return {
      type : type.CHANGE_MODAL,
      show
    }
  },

  setGQLVariables : (value)=>{
    return {
      type : type.SET_GQL_VARIABLES,
      value
    }
  },

  initPatientManualInputForm: (props) => {
    const pickedKeys = ['addresses', 'emails', 'id', 'identification', 'phones', 'profile']
    const userData = _.pick(props.variables, pickedKeys)
    const initData = getInitDataFromSchema(schema)

    if (Array.isArray(userData.identification)) {
      initData.identificationType = _.get(userData, 'identification.0.type.code')
      initData.identificationValue = _.get(userData, 'identification.0.value')
    }
    initData.DOB = moment(_.get(userData, 'profile.birthday'))
    initData.firstName = _.get(userData, 'profile.firstName')
    initData.lastName = _.get(userData, 'profile.lastName')
    initData.nickname = _.get(userData, 'profile.nickname')
    initData.gender = _.get(userData, 'profile.gender') === 'M' ? 'Male' : 'Female'
    initData.height = _.get(userData, 'profile.height') || ''
    initData.weight = _.get(userData, 'profile.weight') || ''

    // initData.nationality = _.get(userData.profile, 'nationality')
    // initData.nationtype = _.get(userData.profile, 'nationtype')
    Array.isArray(userData.phones) && userData.phones.forEach(phone => {
      if (phone.type !== 'HOME') {
        initData.mobileCountryCode = phone.countryCode || '+1'
        initData.mobilePhone = phone.number
      } else {
        initData.homeCountryCode = phone.countryCode || '+1'
        initData.homePhone = phone.number
      }
    })
    initData.email = _.get(userData, 'emails.0.address')
    if (Array.isArray(userData.addresses)) {
      const userAddress = _.get(userData, 'addresses.0')
      initData.streetNumber = _.get(userAddress, 'streetNumber')
      initData.streetName = _.get(userAddress, 'streetName')
      initData.unit = _.get(userAddress, 'unit')
      initData.floor = _.get(userAddress, 'floor')
      initData.city = _.get(userAddress, 'city')
      initData.country = _.get(userAddress, 'country.code')
      initData.postCode = _.get(userAddress, 'postCode')
    }
    initData.race = _.get(userData, 'profile.race.code')
    initData.language = _.get(userData, 'profile.language.code')
    const fieldsValue = convertToFieldsValue(initData)

    return {

      initData,
      fieldsValue
    }
  }
}
