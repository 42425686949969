import React from 'react';
import LeftNav from '../components/LeftNavComponent';
import { connect } from 'react-redux';
import { decryptRole } from 'libModule/utils';
import { checkIsInRole } from 'libModule/helpers/role-helpers';
import { MENUS, SPECIAL_MENUS } from '../../utils/constants/menuPath';


const specialOrganizationIds = [
  '5ebe0cd14f782f00136912d7', // WellClinic
  '65caca53593cfa001371ddce' // Inspire Medical
];

const logoUrl = window.VSM_CONFIG.DEMO ? '/logos/sharecare-logo-small.svg' : '/image/sharecare-logo-small.svg';
// '/logos/logo-simple-white.png' : '/image/vsm/logo-simple-white.png'
const TestData = {
  logo: {
    href: '/',
    src: logoUrl,
  },
  menu: [],
};

const isUserInSpecialOrganization = () => {
  const isMAMD = checkIsInRole(['MA', 'Doctor']);
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentOrgId = _.split(
    window.atob(_.get(currentUser, 'selectedRole.organization.id')),
    ':'
  )[1];
  return isMAMD && _.includes(specialOrganizationIds, currentOrgId);
};

// filter menu based on role and organization id
const getMenu = (role) => {
  let menu = MENUS[role];
  if (isUserInSpecialOrganization() && SPECIAL_MENUS[role]) {
    menu = SPECIAL_MENUS[role];
  }
  return menu;
};

const Container = (props) => {
  const { dispatch, curPath, orgTeamMap, institution, curPage, handleChangePage } = props;
  const currentUserRole = decryptRole();
  let current = curPath.split('/')[1];
  if (_.includes(currentUserRole, 'SUPER_ADMIN')) {
    // for superadmin, key location is different in url
    current = _.get(curPath.split('/'), 2);
  }
  TestData.menu = getMenu(currentUserRole);;
  return <LeftNav {...TestData} current={current} dispatch={dispatch} orgTeamMap={orgTeamMap} institution={institution}
    curPage={curPage} curPath={curPath} handleChangePage={handleChangePage} />;
};

Container.displayName = 'LeftNavContainer';

const mapState = (state, ownProps) => ({
  institution: state.Nav.institution,
  orgTeamMap: state.chat.main.orgTeamMap
});

const mapDispatch = dispatch => ({
  dispatch,
});
export default connect(
  mapState, mapDispatch,
)(Container);
