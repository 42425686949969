import { commonActions } from 'libModule/helpers/action-helpers';
import types from '../constants/ListConstants';

const commonActionsObject = commonActions(types, [
  'onTableChange',
]);

const setVariables = variables => ({
  type: types.SET_VARIABLES,
  variables,
});
const initVariables = () => ({
  type: 'init_data',
});
const resetTable = () => ({
  type: types.ON_TABLE_RESET,
});

export default {
  ...commonActionsObject,
  setVariables,
  initVariables,
  resetTable,
};
