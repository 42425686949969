import React from 'react';
import { Row,Col,Icon,Button } from 'antd';
import moment from 'moment';

const style = {
    fullNameStyle:{
        fontSize:16,
        fontWeight: 'bold'
    },
    timeStampStyle:{
        color: '#aaaaaa'
    }

}
const PostItCardComponent = class extends React.Component{
    constructor(){
        super();
    }

    handleOnEditButton = ()=>{
        const { setShowEditComponent,setNote,post,setPost } = this.props;
        setShowEditComponent(true);
        setNote(post.note);
        setPost(post);
    }

    renderButton = (id)=>{
        const { showConfirmForDeletePostIt,setShowEditComponent } = this.props;
        return <div style={{float:'right'}}>
                <Button size='small' onClick={()=>this.handleOnEditButton()} style={{ marginRight: 10 }}><Icon type="edit" /></Button>
                <Button size='small' onClick={()=>showConfirmForDeletePostIt(id)}><Icon type="delete" /></Button>
               </div>
    };

    renderHeader = (fullName,id,createdAt)=>{

        const createdAtString = moment(createdAt).format('MM/DD/YYYY, HH:mm')
        return<Row style={{ margin:10 }}>
                <Col span={12}><span style={style.fullNameStyle}>{fullName}</span></Col>
                <Col span={12}>{ this.renderButton(id) }</Col>
                <Col span={24}><span style={style.timeStampStyle}>{createdAtString}</span></Col>
              </Row>
    }

    renderNote = (note)=>{
        return <div style={{ margin: '0px 10px 10px',whiteSpace:'pre-line' }}>{note}</div>
    }

    render(){
        // const fullName = _.get(this,'props.post.createdBy.profile.fullName');
        const id = _.get(this,'props.post.id');
        const modifiedAt = _.get(this,'props.post.modifiedAt');
        const modifiedBy = _.get(this,'props.post.modifiedBy.profile.fullName');
        const note = _.get(this,'props.post.note');

        return<div className='PostItCard'>
                 <Row>
                     <Col span={24}> { this.renderHeader(modifiedBy,id,modifiedAt) } </Col>
                     <Col span={24}> { this.renderNote(note) }</Col>
                 </Row>
              </div>
    }
}


export default  PostItCardComponent;
