import React from 'react';
import type from '../constants'
import { openModal } from 'layoutModule/actions/MainModal'
import DetailModal from '../containers/DetailModal'

export default {
  setVariables : (variables)=>{
    return {
      type : type.SET_VARIABLES,
      variables
    }
  },
  initVariables : ()=>{
    return {
      type : 'init_data'
    }
  },

  showModal : (show=false, select=null)=>{
    let title = 'Edit Task';
    if (!select) {
      title = 'Add New Task';
    }
    else if (select.completedAt) {
      title = 'Completed Task';
    }
    const modal = {
      show,
      select
    };
    const modalProps = {
      title,
      showModal: show,
      size: 'sm',
      showHeaderCloseButton: true,
      className : 'vsm-patient-taskList-modal',
      enforceFocus : false
    };
    return openModal(<DetailModal modal={modal} />, modalProps);
  },

  reset : ()=>{
    return {
      type : type.RESET,
    }
  }
}
