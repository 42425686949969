import React from "react";
import VitalsMonitoringComponent from '../component/VitalsMonitoringComponent';

const VitalsMonitoringContainer = class extends React.Component{
    render(){
        const { props } = this;
        return <VitalsMonitoringComponent {...props}/>
    }
}

export default VitalsMonitoringContainer;