import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import moment from 'moment';

import '../style/taskHistory.scss';

const HISTORY_DATE_FORMAT = 'MM/DD/YYYY';

class TaskAssignmentHistory extends React.Component {
  constructor(props) {
    super(props);
    const taskHistoryList = _.clone(props.history) || [];

    this.state = {
      shouldShowButton: false,
      taskHistoryList,
      showHistory: false
    }
  };

  componentDidMount = () => {
    this.setState({
      shouldShowButton: this.shouldShowButton()
    });
  }

  componentDidUpdate = (prevProps) => {
    if(!_.isEqual(prevProps.history, this.props.history)){
      this.setState({ taskHistoryList: _.clone(this.props.history) || [] });
    }
  }

  shouldShowButton = () => {
    const { taskHistoryList } = this.state;
    if(taskHistoryList.length === 0) 
      return false;

    if(taskHistoryList.length > 1)
      return true;
    
    // if there is only 1 history AND not create history, show history
    return !this.checkIfCreateHistory();
  }

  toggleShowHistory = () => this.setState(prev => ({ showHistory: !prev.showHistory }));

  checkIfCreateHistory = (assignedAt) => {
    const { taskCreatedAt  = 0 } = this.props;
    const { taskHistoryList } = this.state;

    if(!taskHistoryList.length) return false;

    const firstHistoryData = _.first(taskHistoryList);
    const reasonCreatedAt = assignedAt || _.get(firstHistoryData, 'assignedAt');

    return reasonCreatedAt <= taskCreatedAt;
  }

  getAction = (id, assigneeName, note, assignedAt) => {
    let assignedToText = '';
    if(this.checkIfCreateHistory(assignedAt)) {
      assignedToText = assigneeName ? ` for ${assigneeName}` : '';
      return `created task${assignedToText}`;
    }

    const prevHistory = _.get(this, `state.taskHistoryList.${id-1}`);
    const prevAssigneeName = _.get(prevHistory, 'assignedTo.profile.fullName');
    if(prevAssigneeName === assigneeName && note) {
      return `added note`;
    }
    assignedToText = assigneeName ? `assigned to ${assigneeName}` : '';
    return `${assignedToText}`;
  }

  renderHistory = (historyDetails, id) => {
    const { note, assignedTo, assignedBy, assignedAt } = historyDetails;
    const assigneeName = _.get(assignedTo, 'profile.fullName') || '';
    const assignerName = _.get(assignedBy, 'profile.fullName') || '';
    const historyDate = assignedAt ? moment(assignedAt).format(HISTORY_DATE_FORMAT) : '';

    const action = this.getAction(id, assigneeName, note, assignedAt);
    const title = <div>
      <span className='task-history-title-date'>{historyDate}</span>
      {assignerName} {action}
    </div>;

    const description = note || '';

    return <Steps.Step
      key={id}
      title={title}
      description={description}
    />;
  };

  render() {
    const { shouldShowButton, taskHistoryList, showHistory } = this.state;
    const { showButton } = this.props;

    const showButtonProps = {
      className: 'task-history-show-button',
      onClick: e => {
        e.preventDefault();
        this.toggleShowHistory();
      }
    };
    const buttonText = `${!showHistory ? 'Show' : 'Hide'} Reason History`;

    return shouldShowButton &&
    <div id='task-history-container-wrapper'>
      {
        (typeof showButton === 'function' && 
          showButton(showHistory, showButtonProps, taskHistoryList.length)) ||
        <a {...showButtonProps}>
          {`${buttonText} (${taskHistoryList.length})`}
        </a>
      }
      {
        showHistory &&
        <div id='task-history-details-container'>
          <Steps 
            progressDot 
            current={-1} 
            direction='vertical'
            size='small'
          >
            {
              _.reverse(taskHistoryList.map(this.renderHistory))
            }
          </Steps>
        </div>
      }
    </div>
  };
};

TaskAssignmentHistory.displayName = 'TaskAssignmentHistory';
TaskAssignmentHistory.propTypes = {
  taskCreatedAt: PropTypes.number.isRequired,
  history: PropTypes.array,
  showButton: PropTypes.func
};

export default TaskAssignmentHistory;