import gql from 'graphql-tag';

const language = `language{
    code
    description
}`

const createTemplateGroupQuery = gql`mutation createTemplateGroup($name:String!){
    createTemplateGroup(name:$name){
        name
        id
    }
}`;

const createConsentTemplateQuery = gql`mutation createConsentTemplate($title:String,$language:Language,$groupId:EID,$content:String!){
  createConsentTemplate(title:$title,language:$language,groupId:$groupId,content:$content){
   id 
  }
}`;

const editTemplateGroupQuery = gql`mutation editTemplateGroup($name:String!,$id:EID!){
  editTemplateGroup(name:$name,id:$id){
    name
    id
  }
}`

const editConsentTemplateQuery = gql`mutation editConsentTemplate($title:String,$language:Language,$groupId:EID,$content:String!,$id:EID!){
   editConsentTemplate(title:$title,language:$language,groupId:$groupId,content:$content,id:$id){
    id 
  }
}`;

const templateGroupsListQuery = gql`query templateGroupOrganizationsList($count:Int){
  templateGroupOrganizationsList(count:$count){
    data{
      id
      name
      language{
        description
      }
      organizations{
        id
        name
      }
    }
    pageInfo{
        total
        lastPage
    }
  }
}`;

const getTemplateGroupQuery = gql`query getTemplateGroup($id:EID!){
  getTemplateGroup(id:$id){
    id
    name
    templates{
      ${language}
      content
    }
  }
}`;
const getConsentTemplateListQuery = gql`query getConsentTemplateList($groupId:EID){
getConsentTemplateList(groupId: $groupId){
    ${language}
    content
    id
}
}`
const getConsentTemplateQuery = gql`query getConsentTemplate($id:EID!){
  getConsentTemplate(id:$id){
    ${language}
    content
    createdAt
    id
  }
}`;

const removeConsentTemplateQuery = gql`mutation deleteConsentTemplate($id:EID!){
    deleteConsentTemplate(id:$id)
}`
const removeConsentGroupQuery = gql`mutation deleteTemplateGroup($id:EID!){
  deleteTemplateGroup(id:$id)
}`;
export  default {
    getConsentTemplateQuery,
    getTemplateGroupQuery,
    editConsentTemplateQuery,
    editTemplateGroupQuery,
    createConsentTemplateQuery,
    createTemplateGroupQuery,
    templateGroupsListQuery,
    getConsentTemplateListQuery,
    removeConsentTemplateQuery,
    removeConsentGroupQuery
}

