import types from 'ProgramEditModule/SurveyAlert/constants/AlertFormConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
])


export default {
  ...commonActionsObject
}
