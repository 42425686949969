import React from 'react';
import { Checkbox, Radio, InputNumber, Select, Input, DatePicker, Row, Col, Modal } from 'antd';
const Option = Select.Option;
import moment from 'moment';
import { langOptions } from '../../../lib/constants';
import { BASE_COUNTER, DEVICE_VITAL_TYPE_MAP, editDeviceNameMap as deviceNameMap, techLevel,APP_SYNC,MANUAL, userDeviceRecordsTypeName } from '../constant/onboardMap';
import vitalHelpers from '../helpers/vitalsHelpers';
import { CGMRadioGroupComponent } from '../../CGM/components/CGMRadioGroupComponent/CGMRadioGroupComponent';
import { CGMCheckboxComponent } from '../../CGM/components/CGMCheckboxComponent/CGMCheckboxComponent';
import { CGMHelperServices } from '../../CGM/helpers';
import { CellularDeviceCheckboxComponent } from '../../CellularDevices/components/CellularDeviceCheckboxComponent/CellularDeviceCheckboxComponent';
import { CellularDeviceOnboardUnassignButtonComponent } from '../../CellularDevices/components/CellularDeviceOnboardUnassignComponent/CellularDeviceOnboardUnassignButtonComponent';
import { renderCellularDeviceAssignModal, renderCellularDeviceUnassignModal } from '../helpers/renderCellularDeviceModal';
import { CellularDevicesHelpers } from '../../CellularDevices/helpers';
import { cellularDeviceBrandNameMap } from '../../CellularDevices/constants';

const onBoardFinishedList = ['INIT','TECH','CONFIRMED','TBD','INIT','EXCLUDED','TECH_ONBOARDING'];
const vitalDeviceConfirm = (e, cb, props) => {
  const eId = e.target.id;
  const isChecked = e.target.checked;
  if(isChecked) return cb && cb();

  const vitalType = DEVICE_VITAL_TYPE_MAP[eId];

  vitalHelpers.onboardRemoveTaskConfirm({
    ...props,
    ...props.visit,
    vitalType,
  }).then(isConfirmed => {
    if(isConfirmed) return cb && cb();

    props.form.setFieldsValue({ [eId]: true });
  });
};
const BGAppSyncValue = (hasBG, BG5SValue,alignValue)=> {
  if( BG5SValue === APP_SYNC || alignValue === APP_SYNC ){
    return APP_SYNC;
  }
  if(hasBG && (BG5SValue === MANUAL || alignValue === MANUAL)){
    return MANUAL;
  }
  return 'INVALID';
}
const HSAppSyncValue = (hasHS,LinaValue,NexusValue)=>{
  if( LinaValue === APP_SYNC || NexusValue === APP_SYNC ){
    return APP_SYNC;
  }
  if(hasHS && (LinaValue === MANUAL || NexusValue === MANUAL)){
    return MANUAL;
  }
  return 'INVALID';
}
const fieldValue = (localThis,field)=>{
  return localThis.props.form.getFieldValue(field);
}
const getOrgPermissions = ()=>{
  return JSON.parse(sessionStorage.getItem('currentUserConfigPermissions'));
}

const getCellularDeviceInfo = (key, props) => {
  const deviceInfo = _.get(props, `cellularDeviceContextValue.${key}`);
  return deviceInfo;
};

const onBoardFormMap = (args) => {
  const {
    localThis, checkedTS, checkedIP,
    hasBP, hasBG, hasHS, hasTM, hasPO,alignValue,BG5SValue,
    bpChecked, bgChecked, hsChecked, tmChecked, poChecked,hasAlign,hasLina,linaValue,nexusValue,haveDeviceValues
  } = args;
  const { Lancets, Strips } = _.get(localThis, 'props.visit.member.profile.deviceRecords') || {};
  const type = _.get(localThis,'props.visit.type');
  const showOnBoardFinishedCheckBox = _.includes(onBoardFinishedList,type);
  const remoteEnrollment = _.get(localThis,'props.visit.member.remoteEnrollment',{ });
  const isConfirmed = true;
      // remove just from remote patients logic from SC-8127
      // _.includes(onBoardFinishedList,_.get(remoteEnrollment,'status'));
  const BGSyncedValueFromForm =  fieldValue(localThis,'BGAppSync');
  const manualBG = BGSyncedValueFromForm === MANUAL;
  const hasBGAppSync = BG5SValue===  APP_SYNC || alignValue === APP_SYNC;
  const onVitalDeviceChange = (e, cb) => vitalDeviceConfirm(e, cb, localThis.props);
  // const BG5STrail = _.get(getOrgPermissions(),'orgPermissions.BG5STrail');

    // localThis.props.form.getFieldValue('alignAppSync') === 'APP_SYNC' ||
    // (hasBG && bgChecked);

  const isFormDisabled = _.get(localThis, 'props.disabled', false);

  const hasBPCellularDevice = !!getCellularDeviceInfo('bpCellularDeviceInfo', localThis.props);
  const hasBGCellularDevice = !!getCellularDeviceInfo('bgCellularDeviceInfo', localThis.props);

  const shouldShowLancetsAndStrips = (hasBGCellularDevice || manualBG) 
                                      ? false 
                                      : (_.isUndefined(BGSyncedValueFromForm) ?
                                        hasBGAppSync : BGSyncedValueFromForm === APP_SYNC);

  return [
    [
      {
        key: 'easeCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled || hasBPCellularDevice || checkedTS}
            // onClick={e => vitalDeviceOnClick(e, localThis.props)}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                easeAppSync: e.target.checked ?
                             'APP_SYNC' : null
                             //hasBP ? 'MANUAL INPUT' : null
              });
            })}
          >
            Ease (BP3L)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: (hasBPCellularDevice || checkedTS) ? false : bpChecked,
        valuePropName: 'checked'
      },
      {
        key: 'easeAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='easeAppSync'
              disabled={isFormDisabled || hasBPCellularDevice}
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  easeCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio disabled={checkedTS} value='APP_SYNC'>
                App Sync
              </Radio>
              <Radio disabled={checkedTS} value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: (hasBPCellularDevice || checkedTS)
          ? null
          : hasBP
          ? bpChecked
            ? 'APP_SYNC'
            : 'MANUAL_INPUT'
          : null,
        itemStyle: { display: 'flex' },
        span: 6
      },
      {
        key: CellularDevicesHelpers.BPCellularCheckboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CellularDeviceCheckboxComponent 
            label={cellularDeviceBrandNameMap[CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.PYLO]}
            disabled={isFormDisabled || checkedTS || checkedIP}
            onChange={(value) => {
              if (value) {
                renderCellularDeviceAssignModal({
                  brand: CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.PYLO,
                  onSubmit: (values) => {
                    const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleAssignDevice');
                    fn(values);
                    localThis.props.form.setFieldsValue({
                      easeCheckbox: false,
                      easeAppSync: null,
                    });
                  },
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BPCellularCheckboxFieldName]: false,
                    });
                  },
                });
              } else if (hasBPCellularDevice) {
                renderCellularDeviceUnassignModal({
                  device: getCellularDeviceInfo('bpCellularDeviceInfo', localThis.props),
                  onUnassign: _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBP'),
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BPCellularCheckboxFieldName]: true,
                    });
                  },
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 5,
        initialData: hasBPCellularDevice,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editBpCellularDevice',
        label: '',
        render: () => (
          <CellularDeviceOnboardUnassignButtonComponent 
            disabled={isFormDisabled}
            onUnassign={() => {
              const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBP');
              fn();
              localThis.props.form.setFieldsValue({
                [CellularDevicesHelpers.BPCellularCheckboxFieldName]: false,
              });
            }}
          />
        ),
        span: 2,
        hidden: !hasBPCellularDevice,
      },
      {
        key: 'trackSet',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox
            disabled={isFormDisabled || checkedIP || hasBPCellularDevice}
            onChange={e => onChange(e)}
          >
            iHealth Clear
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        initialData: checkedTS,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editTrackSet',
        label: '',
        render: openModal => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                if (isFormDisabled) {
                  return;
                }
                openModal();
              }}
            >
              Edit Device
            </a>
          </div>
        ),
        span: 6,
        itemStyle: { display: checkedTS ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: 'BG5S',
        label: '',
        className:'BG5SRow',
        type: 'checkbox',
        render: onChange => (
            <Checkbox
                disabled={isFormDisabled || hasBGCellularDevice}
                onChange={e => onVitalDeviceChange(e, () => {
                  localThis.props.form.setFieldsValue({
                    BGAppSync: e.target.checked ?
                        'APP_SYNC' : null
                  });
                  if (e.target.checked) {
                    const lancetsCT = fieldValue(localThis,'Lancets')||2;
                    const stripsCT = fieldValue(localThis,'Strips')||2;
                    localThis.props.form.setFieldsValue({
                      Lancets: lancetsCT,
                      Strips: stripsCT,
                      alignCheckbox: false,

                    });
                  }
                })}
            >
              Gluco+ (BG5S)
            </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        colStyle:{ },
            // BG5STrail ? {} : { visibility:'hidden',height:0 },
        initialData: !hasBGCellularDevice && BG5SValue == APP_SYNC,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'BGAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='BGAppSync'
              disabled={isFormDisabled || hasBGCellularDevice}
              onChange={e => {
                localThis.props.form.setFieldsValue({
                  BG5S: e.target.value == APP_SYNC,
                });
                if(e.target.value !== APP_SYNC) {
                  localThis.props.form.setFieldsValue({
                    BG5S: false,
                    alignCheckbox: false
                  })
                }
              }
              }
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: !hasBGCellularDevice ? BGAppSyncValue(hasBG, alignValue,BG5SValue) : null,
            // hasBG ? (bgChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
        // path:'visit.member.profile.deviceRecords.Align',
      },
      {
        key: 'Lancets',
        label: 'Lancets',
        className: 'numberInput',
        // undefined => give default value
        // null or lt 2 => show no number
        initialData: Lancets === undefined 
        ? 2 
        : (hasBGCellularDevice || _.isNull(Lancets) || Lancets < 2) ? null : Lancets,
        render: () => {
          return (
            <InputNumber
              disabled={isFormDisabled}
              min={2}
              max={6}
              onChange={e => localThis.props.form.setFieldsValue({ Strips: e })}
            />
          );
        },
        itemStyle: {
          display: shouldShowLancetsAndStrips ? 'flex' : 'none',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        colStyle: {
          display: shouldShowLancetsAndStrips ? 'flex' : 'none',
        },
      },
      {
        key: 'Strips',
        label: 'Strips',
        className: 'numberInput',
        // undefined => give default value
        // null or lt 2 => show no number
        initialData: Strips === undefined 
        ? 2 
        : (hasBGCellularDevice || _.isNull(Strips) || Strips < 2) ? null : Strips,
        render: () => {
          return (
            <InputNumber
              disabled={isFormDisabled}
              min={2}
              max={6}
              onChange={e =>
                localThis.props.form.setFieldsValue({ Lancets: e })
              }
            />
          );
        },
        itemStyle: {
          display: shouldShowLancetsAndStrips ? 'flex' : 'none',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        colStyle: {
          display: shouldShowLancetsAndStrips ? 'flex' : 'none',
        },
      },
      {
        key: CellularDevicesHelpers.BGCellularCheckboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CellularDeviceCheckboxComponent 
            label={cellularDeviceBrandNameMap[CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.BIOLAND]}
            disabled={isFormDisabled}
            onChange={(value) => {
              if (value) {
                renderCellularDeviceAssignModal({
                  brand: CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.BIOLAND,
                  onSubmit: (values) => {
                    const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleAssignDevice');
                    fn(values);
                    localThis.props.form.setFieldsValue({
                      BG5S: false,
                      BGAppSync: null,
                    });
                  },
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BGCellularCheckboxFieldName]: false,
                    });
                  },
                });
              } else if (hasBGCellularDevice) {
                renderCellularDeviceUnassignModal({
                  device: getCellularDeviceInfo('bgCellularDeviceInfo', localThis.props),
                  onUnassign: _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBG'),
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BGCellularCheckboxFieldName]: true,
                    });
                  },
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 5,
        initialData: hasBGCellularDevice,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editBgCellularDevice',
        label: '',
        render: () => (
          <CellularDeviceOnboardUnassignButtonComponent 
            disabled={isFormDisabled}
            onUnassign={() => {
              const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBG');
              fn();
              localThis.props.form.setFieldsValue({
                [CellularDevicesHelpers.BGCellularCheckboxFieldName]: false,
              });
            }}
          />
        ),
        span: 2,
        hidden: !hasBGCellularDevice
      },
    ],
    [
      {
        key: 'alignCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
            <Checkbox
                //disabled={!hasBG}
                disabled={isFormDisabled || hasBGCellularDevice}
                onChange={e => onVitalDeviceChange(e, () => {
                  localThis.props.form.setFieldsValue({
                    BGAppSync: e.target.checked ?
                        'APP_SYNC' : null
                    //hasBG ? 'MANUAL_INPUT' : null
                  });
                  if (e.target.checked) {
                    const lancetsCT = fieldValue(localThis,'Lancets')||2;
                    const stripsCT = fieldValue(localThis,'Strips')||2;
                    localThis.props.form.setFieldsValue({
                      Lancets: lancetsCT,
                      Strips: stripsCT,
                      BG5S: false
                    });
                  }
                })}
            >
              Align (BG1)
            </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: !hasBGCellularDevice && alignValue===APP_SYNC,
        colStyle: hasAlign ? {} : { visibility:'hidden',height:0 },
        // path:'visit.member.profile.deviceRecords.Align',
        valuePropName: 'checked'
      },
      // {
      //   key: 'BG5S',
      //   label: '',
      //   className:'BG5SRow',
      //   type: 'checkbox',
      //   render: onChange => (
      //       <Checkbox
      //           disabled={isFormDisabled}
      //           onChange={e => onVitalDeviceChange(e, () => {
      //             localThis.props.form.setFieldsValue({
      //               BGAppSync: e.target.checked ?
      //                   'APP_SYNC' : null
      //             });
      //             if (e.target.checked) {
      //               const lancetsCT = fieldValue(localThis,'Lancets')||2;
      //               const stripsCT = fieldValue(localThis,'Strips')||2;
      //               localThis.props.form.setFieldsValue({
      //                 Lancets: lancetsCT,
      //                 Strips: stripsCT,
      //                 alignCheckbox: false,
      //
      //               });
      //             }
      //           })}
      //       >
      //         Gluco+ (BG5S)
      //       </Checkbox>
      //   ),
      //   itemStyle: { display: 'flex', flexDirection: 'row' },
      //   span: 4,
      //   colStyle: BG5STrail ? {} : { visibility:'hidden',height:0 },
      //   initialData: BG5SValue == APP_SYNC,
      //   // path:'visit.member.profile.deviceRecords.TrackSet',
      //   valuePropName: 'checked'
      // },
      // {
      //   key: 'BG5SAppSync',
      //   label: '',
      //   type: 'radio',
      //   render: () => {
      //     return (
      //         <Radio.Group
      //             key='BG5SAppSync'
      //             disabled={isFormDisabled}
      //             onChange={e =>
      //                 localThis.props.form.setFieldsValue({
      //                   alignCheckbox: e.target.value === 'APP_SYNC',
      //                   BG5S: e.target.value === 'APP_SYNC'
      //                 })
      //             }
      //         >
      //           <Radio value='APP_SYNC'>App Sync</Radio>
      //           <Radio value='MANUAL_INPUT'>Manual Input</Radio>
      //         </Radio.Group>
      //     );
      //   },
      //   initialData:alignValue,
      //   // initialData: hasBG ? (bgChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
      //   itemStyle: { display: 'flex' },
      //   span: 6
      //   // path:'visit.member.profile.deviceRecords.Align',
      // },
    ],
    [
      {
        key: 'nexusCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                linaAppSync: e.target.checked ?
                             'APP_SYNC' : null
                            //  hasHS ? 'MANUAL_INPUT' : null
              })
              if (e.target.checked) {
                localThis.props.form.setFieldsValue({
                  linaCheckbox: false
                })
              }
            })}
          >
            Nexus (HS2S)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: nexusValue === APP_SYNC,
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked'
      },
      {
        key: 'linaAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              disabled={isFormDisabled}
              key='linaAppSync'
              onChange={e => {
                localThis.props.form.setFieldsValue({
                  nexusCheckbox: e.target.value === APP_SYNC
                })
                if(e.target.value !== APP_SYNC) {
                  localThis.props.form.setFieldsValue({
                    nexusCheckbox: false,
                    linaCheckbox: false
                  })
                }
              }}
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'> Manual Input </Radio>
            </Radio.Group>
          );
        },
        initialData: HSAppSyncValue(hasHS,linaValue,nexusValue),
            // hasHS ? (hsChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' }
        // path:'visit.member.profile.deviceRecords.Lina',
      }
    ],
    [
      {
        key: 'linaCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
            <Checkbox
                disabled={isFormDisabled}
                onChange={e => onVitalDeviceChange(e, () => {
                  localThis.props.form.setFieldsValue({
                    linaAppSync: e.target.checked ?
                        'APP_SYNC' : null
                    //  hasHS ? 'MANUAL_INPUT' : null
                  })
                  if (e.target.checked) {
                    localThis.props.form.setFieldsValue({
                      nexusCheckbox: false
                    })
                  }
                })}
            >
              Lina (HS2)
            </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: linaValue === APP_SYNC,
        // colStyle: hasLina ? {} : { visibility:'hidden',height:0 },
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked'
      },
    ],
    [
      {
        key: 'tmCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                tmAppSync: e.target.checked ?
                           'APP_SYNC' : null
                          //  hasTM ? 'MANUAL_INPUT' : null
              })
            })}
          >
            Thermometer
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: tmChecked,
        valuePropName: 'checked'
      },
      {
        key: 'tmAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              disabled={isFormDisabled}
              key='tmAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  tmCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: hasTM ? (tmChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'poCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                poAppSync: e.target.checked ?
                           'APP_SYNC' : null
                          //  hasPO ? 'MANUAL_INPUT' : null
              })
            })}
          >
            Pulse Oximeter
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: poChecked,
        valuePropName: 'checked'
      },
      {
        key: 'poAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='poAppSync'
              disabled={isFormDisabled}
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  poCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: hasPO ? (poChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'iPhone',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={isFormDisabled || checkedTS} onChange={onChange}>
            iPhone
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: checkedIP
        // path:'visit.member.profile.deviceRecords.iPhone',
      },
      {
        key: 'appVersion',
        label: '',
        render: appVersion => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            Version: {appVersion || 'N/A'}
          </div>
        ),
        span: 4,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      },
      {
        key: 'unassignIPhone',
        label: '',
        render: action => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                if (isFormDisabled) {
                  return;
                }
                action();
              }}
            >
              Unassign device
            </a>
          </div>
        ),
        span: 3,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: CGMHelperServices.checkboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CGMCheckboxComponent 
            disabled={isFormDisabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (!isChecked) {
                localThis.props.form.setFieldsValue({
                  [CGMHelperServices.brandFieldName]: undefined,
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: !!_.get(localThis, 'props.cgmContextValue.hasCGM'),
      },
      {
        key: CGMHelperServices.brandFieldName,
        label: '',
        type: 'radio',
        rules: [{
          validator: (rule, value, cb) => {
            if(
              CGMHelperServices.getCheckboxFieldValue(localThis.props.form)
              && !value
            ) {
              cb(CGMHelperServices.requiredMessage);
              return;
            }
            cb();
          },
        }],
        render: () => (
          <CGMRadioGroupComponent 
            disabled={isFormDisabled}
            onChange={(value) => {
              if (value) {
                localThis.props.form.setFieldsValue({
                  [CGMHelperServices.checkboxFieldName]: true,
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex' },
        span: 6,
        initialData: _.get(localThis, 'props.cgmContextValue.CGMInfo.cgmBrand'),
      }
    ],
    [
      {
        key:'haveDeviceValues',
        label:'',
        renderOnly: true,
        render:()=> !haveDeviceValues ? <div style={{ fontSize:14,color:'red' }}>Please select device to process</div> :<div></div>
      }
    ],
    // [
    //     {
    //         key:'title',
    //         label:'',
    //         renderOnly:true
    //     }
    // ],
    [
      {
        key: 'spokenLanguage',
        label: 'Spoken Language',
        initialData: 'EL',
        path: 'visit.member.profile.language.code',
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 4
      },
      {
        key: 'appLanguage',
        label: 'App Language',
        initialData: 'EL',
        path: 'visit.member.profile.appLanguage.code',
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 4
      },
      {
        key: 'techLevel',
        label: 'Tech Level',
        path: 'visit.member.profile.techLevel',
        rules: [{ required: true, message: 'Tech Level Required' }],
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(techLevel, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'none',
        label: '',
        render: () => <div></div>,
        span: 1,
      },
      {
        key: 'EMRAttached',
        label: '',
        itemStyle: { paddingLeft: 10, paddingTop: 25 },
        render: () => (
          <Checkbox disabled={isFormDisabled}>
            EMR (attach consent form)
          </Checkbox>
        ),
        span: 12,
        firstPath: 'visit.member.profile.',
        path: 'visit.details.EMRAttached',
        valuePropName: 'checked'
      },
    ],
    [
      {
        key: 'a1c',
        label: 'A1C%',
        className: 'a1cInput',
        render: () => <Input disabled={true} />,
        // rules: [{ required: true, message: 'A1C Required' }],
        path: 'visit.labResult.results.0.value',
        firstPath: 'visit.labResult.results.0.value',
        span: 3
      },
      {
        key: 'a1cDate',
        label: 'Collected Date',
        // rules: [{ required: true, message: 'A1C Date is Required' }],
        path: 'visit.labResult.dateCollected',
        firstPath: 'visit.labResult.dateCollected',
        changeToBoolean: val => moment(val),
        render: () => <DatePicker disabled={true} />,
        span: 4,
      },
      // {
      //   key: 'a1cId',
      //   label: '',
      //   firstPath: 'visit.labResult.id',
      //   path: 'getLatestA1C.id',
      //   render: () => <div></div>
      // },
      {
        key: 'needInHouseA1c',
        label: '',
        path: 'needInHouseA1C',
        // itemStyle: {
        //   marginTop: 30,
        //   display: localThis.props.needInHouseA1C ? 'flex' : 'none'
        // },
        // type: 'checkbox',
        // initialData: localThis.props.needInHouseA1C ? true : false,
        // valuePropName: 'checked',
        render: () => {
          const firstPath = 'visit.labResult.dateCollected';
          const path = 'visit.labResult.dateCollected';
          const dateInMS = _.get(
            localThis,
            `props.${firstPath}`,
            _.get(localThis, `props.${path}`)
          );
          const range = Date.now() - dateInMS;
          const needInHouseA1C = range > 30 * (1000 * 60 * 60 * 24);
          return needInHouseA1C ? (
            <div
              style={{
                marginTop: 30,
                display: needInHouseA1C ? 'flex' : 'none',
                alignItems: 'center',
                height: '100%',
                color: 'red'
              }}
            >
              Need in-house A1C
            </div>
          ) : (
            <div></div>
          );
        },
        span: 5,
      },
      {
        key: 'addToContactsStatus',
        label: '',
        itemStyle: { paddingLeft: 10, marginTop: -20 },
        render: () => <Checkbox disabled={isFormDisabled}>Add Care Team Number into Phone Book</Checkbox>,
        span: 12,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.addToContactsStatus'
      }
    ],
    [
      {
        key: 'none',
        label: '',
        render: () => <div></div>,
        span: 12,
      },
      {
        key: 'autoUpdateStatus',
        label: '',
        itemStyle: { paddingLeft: 10, marginTop: -65 },
        render: () => <Checkbox disabled={isFormDisabled}>Set up app auto update</Checkbox>,
        span: 12,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.autoUpdateStatus'
      },
    ],
    [
      {
        key: 'none',
        label: '',
        render: () => <div></div>,
        span: 12,
      },
      {
        key: 'onBoardFinished',
        label: '',
        itemStyle: { paddingLeft: 10, marginTop: -50 },
        initialData:_.get(remoteEnrollment,'techOnBoard.value'),
        valuePropName: 'checked',
        render: (__,doc) => {
          //show checkbox if this is init/tech visit;
          // if remote exists and status is confirmed;
          return  showOnBoardFinishedCheckBox&&isConfirmed ?
                  <Checkbox disabled={isFormDisabled}>Tech On-boarding Finished<p/></Checkbox> :<div></div>;
        },
        span: 12,
      },
    ]
  ];
};
const giveOutDevicesAndUserProfile = ({ localThis, checkedTS, checkedIP,haveDeviceValues }) => {
  const visit = _.get(localThis, 'props.visit');
  // convert to giveout count/ selection
  const getInitialData = deviceName => (_.get(visit, `deviceRecordCount.${deviceName}`) || 0) % BASE_COUNTER;
  const isFormDisabled = _.get(localThis, 'props.disabled', false);
  // const BG5STrail = _.get(getOrgPermissions(),'orgPermissions.BG5STrail');
  const DEVICE_FIELD_MAP = {
    Ease: {
      key: 'giveOutEase',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Ease (BP3L)</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      //path: 'visit.deviceRecordCount.Ease',
      initialData: getInitialData('Ease'),
      valuePropName: 'checked'
    },
    Thermometer: {
      key: 'giveOutTM',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Thermometer</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('Thermometer'),
      valuePropName: 'checked'
    },
    Lina: {
      key: 'giveOutLina',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Lina (HS2)</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('Lina'),
      valuePropName: 'checked'
    },
    Nexus: {
      key: 'giveOutNexus',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Nexus (HS2S)</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('Nexus'),
      valuePropName: 'checked'
    },
    Align: {
      key: 'giveOutAlign',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Align (BG1)</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('Align'),
      valuePropName: 'checked'
    },
    BG5S: {
      key: 'giveOutBG5S',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Gluco+ (BG5S)</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      colStyle: { },
          // BG5STrail ? {} : { visibility:'hidden',width:0,height:0,padding:0 },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('BG5S'),
      valuePropName: 'checked'
    },
    Oximeter: {
      key: 'giveOutPO',
      label: '',
      render: () => <Checkbox disabled={isFormDisabled}>Pulse Oximeter</Checkbox>,
      itemStyle: { display: 'flex', flexDirection: 'row' },
      span: 4,
      type: 'checkbox',
      initialData: getInitialData('Oximeter'),
      valuePropName: 'checked'
    },
    LancetStrip: [
      {
        key: 'lancetStripCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={() => {
              localThis.props.form.setFieldsValue({
                giveOutLancets: 0,
                giveOutStrips: 0,
              });
            }}
          />
        ),
        type: 'checkbox',
        initialData: _.get(visit, 'deviceRecordCount.Lancets', 0) > 0 || _.get(visit, 'deviceRecordCount.Strips', 0) > 0,
        valuePropName: 'checked'
      },
      {
        key: 'giveOutLancets',
        label: 'Lancets',
        className: 'numberInput',
        initialData: 0,
        path: 'visit.deviceRecordCount.Lancets',
        span: 4,
        colStyle: { marginLeft: -18 },
        render: () => {
          return (
            // <div style={{ marginLeft: -25, marginTop: -3, fontSize: 14.5 }}>
            //   Lancets
              <InputNumber
                disabled={isFormDisabled || !localThis.props.form.getFieldValue('lancetStripCheckbox')}
                min={0} max={6}
              />
            // </div>
          )
        },
        itemStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
      },
      {
        key: 'giveOutStrips',
        label: 'Strips',
        className: 'numberInput',
        initialData: 0,
        path: 'visit.deviceRecordCount.Strips',
        span: 4,
        colStyle: { marginLeft: -28},
        render: () => {
          return (
            // <div style={{ marginLeft: -40, marginTop: -3, fontSize: 14.5 }}>
            //   Strips
              <InputNumber
                disabled={isFormDisabled || !localThis.props.form.getFieldValue('lancetStripCheckbox')}
                min={0} max={6}
              />
            // </div>
          )
        },
        itemStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
      }
    ]
  };

  let syncedDeviceRecords = [],
      giveOutOptions = [],
      currentIndex = 0,
      hasBGDevice = false;
  const userDeviceRecords = _.omit(_.get(localThis, 'props.member.profile.deviceRecords'), '__typename');
  _.set(userDeviceRecords, userDeviceRecordsTypeName.TrackSet, !!checkedTS);
  _.set(userDeviceRecords, userDeviceRecordsTypeName.iPhone, !!checkedIP);
  for (let [key, value] of Object.entries(userDeviceRecords || {})) {
    if ((typeof value == 'boolean' && value) || value == 'APP_SYNC') {
      syncedDeviceRecords.push(deviceNameMap[key]);

      const giveOutOption = DEVICE_FIELD_MAP[key];
      if(giveOutOption) {
        if(!giveOutOptions[currentIndex]) { // first item
          giveOutOptions.push([giveOutOption]);
        } else if (giveOutOptions[currentIndex].length < 3) { // 3 items per row
          giveOutOptions[currentIndex].push(giveOutOption);
        } else { // go to new row
          giveOutOptions.push([giveOutOption]);
          currentIndex += 1;
        }
        if(key === 'Align' || key === 'BG5S'){
          hasBGDevice = true;
        }
      }
    }
  }

  if(hasBGDevice) {
    giveOutOptions.push(DEVICE_FIELD_MAP['LancetStrip']);
  }

  if(!Object.keys(giveOutOptions).length) {
    giveOutOptions.push([
      {
        key: 'noAvailableGiveOutOption',
        label: '',
        render: () => <div style={{ fontFamily: 'var(--font-family)', fontSize: 14, }}>No available give out option</div>
      }
    ]);
  }

  return [
    [
      {
        key: 'none',
        label: '',
        itemStyle: { marginBottom: 0 },
        render: () => <h4>Give out additional devices</h4>
      }
    ],
    ...giveOutOptions,
    [
      {
        key: 'link',
        renderOnly: () => {
          // if(checkedTS && !_.includes(syncedDeviceRecords, 'iHealth Clear')) syncedDeviceRecords.push('iHealth Clear');
          // if(checkedIP && !_.includes(syncedDeviceRecords, 'iPhone')) syncedDeviceRecords.push('iPhone');
          return (
            <Row style={{ marginTop: 10, marginBottom: 50 }}>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                Current Devices:
              </Col>
              <Col span={24}>
                <Row>
                  {
                    syncedDeviceRecords.map((device, id) =>
                        <Col key={id} span={8} style={{marginBottom: 4, minWidth:'fit-content'}}>{device}</Col>)
                  }
                </Row>
                {/*syncedDeviceRecords.join(', ')*/}
              </Col>
              <Col style={{ marginTop: 8 }} span={24}>
                <a
                  onClick={e => {
                    e.preventDefault();
                    if (isFormDisabled) {
                      return;
                    }
                    localThis.setState({ followUpEditDevices: true });
                  }}
                  style={{ textDecoration: 'underline', marginLeft: 0 }}
                >
                  Edit Devices
                </a>
              </Col>
            </Row>
          );
        }
      }
    ],
    [
      {
        key: 'none',
        label: '',
        itemStyle: { marginBottom: 0 },
        render: () => <h4>Confirm following information with patient</h4>
      }
    ],
    [
      {
        key: 'spokenLanguage',
        label: 'Spoken Language',
        initialData: 'EL',
        path: 'visit.member.profile.language.code',
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 4
      },
      {
        key: 'appLanguage',
        label: 'App Language',
        initialData: 'EL',
        path: 'visit.member.profile.appLanguage.code',
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(langOptions, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 4
      },
      {
        key: 'techLevel',
        label: 'Tech Level',
        path: 'visit.member.profile.techLevel',
        rules: [{ required: true, message: 'Tech Level Required' }],
        render: () => (
          <Select disabled={isFormDisabled}>
            {_.map(techLevel, ({ value, name }, id) => (
              <Option key={id} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        ),
        span: 3
      },
      {
        key: 'none',
        label: '',
        render: () => <div></div>,
        span: 1,
      },
      {
        key: 'EMRAttached',
        label: '',
        itemStyle: { paddingLeft: 10, paddingTop: 25 },
        render: () => (
          <Checkbox disabled={isFormDisabled}>
            EMR (attach consent form)
          </Checkbox>
        ),
        span: 12,
        path: 'visit.details.EMRAttached',
        valuePropName: 'checked'
      },
    ],
    [
      {
        key: 'a1c',
        label: 'A1C%',
        className: 'a1cInput',
        render: () => <Input disabled={true} />,
        // rules: [{ required: true, message: 'A1C Required' }],
        path: 'visit.labResult.results.0.value',
        firstPath: 'visit.labResult.results.0.value',
        span: 3
      },
      {
        key: 'a1cDate',
        label: 'Collected Date',
        //rules: [{ required: true, message: 'A1C Date is Required' }],
        path: 'visit.labResult.dateCollected',
        firstPath: 'visit.labResult.dateCollected',
        changeToBoolean: val => moment(val),
        render: () => <DatePicker disabled={true} />,
        span: 4,
      },
      // {
      //   key: 'a1cId',
      //   label: '',
      //   firstPath: 'visit.labResult.id',
      //   path: 'getLatestA1C.id',
      //   render: () => <div></div>
      // },
      {
        key: 'needInHouseA1c',
        label: '',
        path: 'needInHouseA1C',
        // itemStyle: {
        //   marginTop: 30,
        //   display: localThis.props.needInHouseA1C ? 'flex' : 'none',
        //   alignItems: 'center',
        //   height: '100%'
        // },
        // type: 'checkbox',
        // initialData: localThis.props.needInHouseA1C ? true : false,
        // valuePropName: 'checked',
        render: () => {
          const firstPath = 'visit.labResult.dateCollected';
          const path = 'visit.labResult.dateCollected';
          const dateInMS = _.get(
            localThis,
            `props.${firstPath}`,
            _.get(localThis, `props.${path}`)
          );
          const range = Date.now() - dateInMS;
          const needInHouseA1C = range > 30 * (1000 * 60 * 60 * 24);
          return needInHouseA1C ? (
            <div
              style={{
                marginTop: 30,
                display: needInHouseA1C ? 'flex' : 'none',
                alignItems: 'center',
                height: '100%',
                color: 'red'
              }}
            >
              Need in-house A1C
            </div>
          ) : (
            <div></div>
          );
        },
        span: 5,
      },
      {
        key: 'addToContactsStatus',
        label: '',
        itemStyle: { paddingLeft: 10, marginTop: -20 },
        render: () => <Checkbox disabled={isFormDisabled}>Add Care Team Number into Phone Book</Checkbox>,
        span: 12,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.addToContactsStatus'
      }
    ],
    [
      {
        key: 'none',
        label: '',
        render: () => <div></div>,
        span: 12,
      },
      {
        key: 'autoUpdateStatus',
        label: '',
        itemStyle: { paddingLeft: 10, marginTop: -65 },
        render: () => <Checkbox disabled={isFormDisabled}>Set up app auto update</Checkbox>,
        span: 12,
        changeToBoolean: val => val == 'COMPLETED',
        valuePropName: 'checked',
        path: 'visit.details.autoUpdateStatus'
      },
    ],
    // [
    //   {
    //     key: 'none',
    //     label: '',
    //     render: () => <div></div>,
    //     span: 12,
    //   },
    //   {
    //     key: 'appIsUptoDate',
    //     label: '',
    //     itemStyle: { paddingLeft: 10, marginTop: -55 },
    //     render: () => <Checkbox>App is up to date</Checkbox>,
    //     span: 12,
    //     changeToBoolean: val => val == 'COMPLETED',
    //     valuePropName: 'checked',
    //     path: 'visit.details.appIsUptoDate'
    //   }
    // ]
  ];
};
const editDeviceFormMap = (args) => {
  const {
    localThis, checkedTS, checkedIP,
    hasBP, hasBG, hasHS, hasTM, hasPO,BG5SValue,alignValue,
    bpChecked, bgChecked, hsChecked, tmChecked, poChecked,hasAlign,hasLina,linaValue,nexusValue,haveDeviceValues
  } = args;

  const isFormDisabled = _.get(localThis, 'props.disabled', false);
  const onVitalDeviceChange = (e, cb) =>
                              vitalDeviceConfirm(e, cb, localThis.props);
  //remove BG5STrail regulation on worklist devices
  // const BG5STrail = _.get(getOrgPermissions(),'orgPermissions.BG5STrail',true);

  const hasBPCellularDevice = !!getCellularDeviceInfo('bpCellularDeviceInfo', localThis.props);
  const hasBGCellularDevice = !!getCellularDeviceInfo('bgCellularDeviceInfo', localThis.props);

  return [
    [
      {
        key: 'easeCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled || hasBPCellularDevice || checkedTS}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                easeAppSync: e.target.checked ?
                             'APP_SYNC' : null
                            //  hasBP ? 'MANUAL_INPUT' : null
              })
            })}
          >
            Ease (BP3L)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: (hasBPCellularDevice || checkedTS) ? false : bpChecked,
        valuePropName: 'checked'
      },
      {
        key: 'easeAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='easeAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  easeCheckbox: e.target.value === 'APP_SYNC'
                })
              }
              disabled={isFormDisabled || hasBPCellularDevice}
            >
              <Radio disabled={checkedTS} value='APP_SYNC'>
                App Sync
              </Radio>
              <Radio disabled={checkedTS} value='MANUAL_INPUT'>
                Manual Input
              </Radio>
            </Radio.Group>
          );
        },
        initialData: (hasBPCellularDevice || checkedTS)
          ? null
          : hasBP
          ? bpChecked
            ? 'APP_SYNC'
            : 'MANUAL_INPUT'
          : null,
        itemStyle: { display: 'flex' },
        span: 6
      },
      {
        key: CellularDevicesHelpers.BPCellularCheckboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CellularDeviceCheckboxComponent 
            label={cellularDeviceBrandNameMap[CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.PYLO]}
            disabled={isFormDisabled || checkedTS || checkedIP}
            onChange={(value) => {
              if (value) {
                renderCellularDeviceAssignModal({
                  brand: CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.PYLO,
                  onSubmit: (values) => {
                    const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleAssignDevice');
                    fn(values);
                    localThis.props.form.setFieldsValue({
                      easeCheckbox: false,
                      easeAppSync: null,
                    });
                  },
                  onSubmit: _.get(localThis, 'props.cellularDeviceContextValue.handleAssignDevice'),
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BPCellularCheckboxFieldName]: false,
                    });
                  },
                });
              } else if (hasBPCellularDevice) {
                renderCellularDeviceUnassignModal({
                  device: getCellularDeviceInfo('bpCellularDeviceInfo', localThis.props),
                  onUnassign: _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBP'),
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BPCellularCheckboxFieldName]: true,
                    });
                  },
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 5,
        initialData: hasBPCellularDevice,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editBpCellularDevice',
        label: '',
        render: () => (
          <CellularDeviceOnboardUnassignButtonComponent 
            disabled={isFormDisabled}
            onUnassign={() => {
              const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBP');
              fn();
              localThis.props.form.setFieldsValue({
                [CellularDevicesHelpers.BPCellularCheckboxFieldName]: false,
              });
            }}
          />
        ),
        span: 2,
        hidden: !hasBPCellularDevice
      },
      {
        key: 'trackSet',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={isFormDisabled || checkedIP || hasBPCellularDevice} onChange={e => onChange(e)}>
            iHealth Clear
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        initialData: checkedTS,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editTrackSet',
        label: '',
        render: openModal => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                if (isFormDisabled) {
                  return;
                }
                openModal();
              }}
            >
              Edit Device
            </a>
          </div>
        ),
        span: 4,
        itemStyle: { display: checkedTS ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: 'BG5S',
        label: '',
        type: 'checkbox',
        render: onChange => (
            <Checkbox
                disabled={isFormDisabled || hasBGCellularDevice}
                onChange={e => onVitalDeviceChange(e, () => {
                  localThis.props.form.setFieldsValue({
                    BGAppSync: e.target.checked ?
                        'APP_SYNC' : null
                  });
                  if (e.target.checked) {
                    localThis.props.form.setFieldsValue({
                      alignCheckbox: false,
                    });
                  }
                })}
            >
              Gluco+ (BG5S)
            </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        initialData: !hasBGCellularDevice && BG5SValue == APP_SYNC,
        colStyle: {},
            // ? {} : { visibility:'hidden',height:0 },
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'BGAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='BGAppSync'
              disabled={isFormDisabled || hasBGCellularDevice}
              onChange={e => {
                localThis.props.form.setFieldsValue({
                  BG5S: e.target.value == APP_SYNC,
                });
                if(e.target.value !== APP_SYNC) {
                  localThis.props.form.setFieldsValue({
                    BG5S: false,
                    alignCheckbox: false
                  })
                }
              }}
            >
              <Radio /*disabled={!hasBG}*/ value='APP_SYNC'>App Sync</Radio>
              <Radio /*disabled={!hasBG}*/ value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: !hasBGCellularDevice ? BGAppSyncValue(hasBG, alignValue,BG5SValue) : null,
        itemStyle: { display: 'flex' },
        span: 6
        // path:'visit.member.profile.deviceRecords.Align',
      },
      {
        key: CellularDevicesHelpers.BGCellularCheckboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CellularDeviceCheckboxComponent 
            label={cellularDeviceBrandNameMap[CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.BIOLAND]}
            disabled={isFormDisabled}
            onChange={(value) => {
              if (value) {
                renderCellularDeviceAssignModal({
                  brand: CellularDevicesHelpers.AVAILABLE_BRAND_ENUM.BIOLAND,
                  onSubmit: (values) => {
                    const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleAssignDevice');
                    fn(values);
                    localThis.props.form.setFieldsValue({
                      BG5S: false,
                      BGAppSync: null,
                    });
                  },
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BGCellularCheckboxFieldName]: false,
                    });
                  },
                });
              } else if (hasBGCellularDevice) {
                renderCellularDeviceUnassignModal({
                  device: getCellularDeviceInfo('bgCellularDeviceInfo', localThis.props),
                  onUnassign: _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBG'),
                  onCancel: () => {
                    localThis.props.form.setFieldsValue({
                      [CellularDevicesHelpers.BGCellularCheckboxFieldName]: true,
                    });
                  },
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 5,
        initialData: hasBGCellularDevice,
        // path:'visit.member.profile.deviceRecords.TrackSet',
        valuePropName: 'checked'
      },
      {
        key: 'editBgCellularDevice',
        label: '',
        render: () => (
          <CellularDeviceOnboardUnassignButtonComponent 
            disabled={isFormDisabled}
            onUnassign={() => {
              const fn = _.get(localThis, 'props.cellularDeviceContextValue.handleUnassignBG');
              fn();
              localThis.props.form.setFieldsValue({
                [CellularDevicesHelpers.BGCellularCheckboxFieldName]: false,
              });
            }}
          />
        ),
        span: 2,
        hidden: !hasBGCellularDevice
      },
    ],
    [
      {
        key: 'alignCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
            <Checkbox
                //disabled={!hasBG}
                disabled={isFormDisabled || hasBGCellularDevice}
                onChange={e => onVitalDeviceChange(e, () => {
                  localThis.props.form.setFieldsValue({
                    BGAppSync: e.target.checked ?
                        'APP_SYNC' : null,
                    BG5S: false
                    // hasBG ? 'MANUAL_INPUT' : null
                  })
                })}
            >
              Align (BG1)
            </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: !hasBGCellularDevice && alignValue===APP_SYNC,
        colStyle: hasAlign ? {} : { visibility:'hidden',height:0 },
        // path:'visit.member.profile.deviceRecords.Align',
        valuePropName: 'checked'
      },
      // {
      //   key: 'BG5S',
      //   label: '',
      //   type: 'checkbox',
      //   render: onChange => (
      //       <Checkbox
      //           disabled={isFormDisabled}
      //           onChange={e => onVitalDeviceChange(e, () => {
      //             localThis.props.form.setFieldsValue({
      //               BGAppSync: e.target.checked ?
      //                   'APP_SYNC' : null
      //             });
      //             if (e.target.checked) {
      //               localThis.props.form.setFieldsValue({
      //                 alignCheckbox: false,
      //               });
      //             }
      //           })}
      //       >
      //         Gluco+ (BG5S)
      //       </Checkbox>
      //   ),
      //   itemStyle: { display: 'flex', flexDirection: 'row' },
      //   span: 4,
      //   initialData: BG5SValue == APP_SYNC,
      //   colStyle: BG5STrail ? {} : { visibility:'hidden',height:0 },
      //   // path:'visit.member.profile.deviceRecords.TrackSet',
      //   valuePropName: 'checked'
      // },
    ],
    [
      {
        key: 'nexusCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                linaAppSync: e.target.checked ?
                             'APP_SYNC' : null
                            //  hasHS ? 'MANUAL_INPUT' : null
              })
              if (e.target.checked) {
                localThis.props.form.setFieldsValue({
                  linaCheckbox: false
                })
              }
            })}
          >
            Nexus (HS2S)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: nexusValue === APP_SYNC,
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked'
      },
      {
        key: 'linaAppSync',
        label: '',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='linaAppSync'
              disabled={isFormDisabled}
              onChange={e => {
                localThis.props.form.setFieldsValue({
                  nexusCheckbox: e.target.value === APP_SYNC
                })
                if(e.target.value !== APP_SYNC) {
                  localThis.props.form.setFieldsValue({
                    nexusCheckbox: false,
                    linaCheckbox: false
                  })
                }
              }}
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: HSAppSyncValue(hasHS,linaValue,nexusValue),
        itemStyle: { display: 'flex' }
        // path:'visit.member.profile.deviceRecords.Lina',
      }
    ],
    [
      {
        key: 'linaCheckbox',
        label: '',
        type: 'checkbox',
        render: () => (
          <Checkbox
            // disabled={!hasHS}
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                linaAppSync: e.target.checked ?
                             'APP_SYNC' : null
                            //  hasHS ? 'MANUAL_INPUT' : null
              })
              if (e.target.checked) {
                localThis.props.form.setFieldsValue({
                  nexusCheckbox: false
                })
              }
            })}
          >
            Lina (HS2)
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        //changeToBoolean:(val) => val=='APP_SYNC',
        initialData: linaValue === APP_SYNC,
        // path:'visit.member.profile.deviceRecords.Lina',
        valuePropName: 'checked',
        // colStyle: hasLina ? {} : { visibility:'hidden',height:0 },
      },
    ],
    [
      {
        key: 'tmCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                tmAppSync: e.target.checked ?
                           'APP_SYNC' : null
                          //  hasTM ? 'MANUAL_INPUT' : null
              })
            })}
          >
            Thermometer
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: tmChecked,
        valuePropName: 'checked'
      },
      {
        key: 'tmAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              key='tmAppSync'
              disabled={isFormDisabled}
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  tmCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: hasTM ? (tmChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'poCheckbox',
        label: '',
        render: () => (
          <Checkbox
            disabled={isFormDisabled}
            onChange={e => onVitalDeviceChange(e, () => {
              localThis.props.form.setFieldsValue({
                poAppSync: e.target.checked ?
                           'APP_SYNC' : null
                          //  hasPO ? 'MANUAL_INPUT' : null
              })
            })}
          >
            Pulse Oximeter
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        type: 'checkbox',
        //path:'visit.member.profile.deviceRecords.Ease',
        //changeToBoolean:(val)=>val=='APP_SYNC'||val=='MANUAL_INPUT',
        initialData: poChecked,
        valuePropName: 'checked'
      },
      {
        key: 'poAppSync',
        label: '',
        // path:'visit.member.profile.deviceRecords.Ease',
        type: 'radio',
        render: () => {
          return (
            <Radio.Group
              disabled={isFormDisabled}
              key='poAppSync'
              onChange={e =>
                localThis.props.form.setFieldsValue({
                  poCheckbox: e.target.value === 'APP_SYNC'
                })
              }
            >
              <Radio value='APP_SYNC'>App Sync</Radio>
              <Radio value='MANUAL_INPUT'>Manual Input</Radio>
            </Radio.Group>
          );
        },
        initialData: hasPO ? (poChecked ? 'APP_SYNC' : 'MANUAL_INPUT') : null,
        itemStyle: { display: 'flex' },
        span: 6
      }
    ],
    [
      {
        key: 'iPhone',
        label: '',
        type: 'checkbox',
        render: onChange => (
          <Checkbox disabled={isFormDisabled || checkedTS} onChange={onChange}>
            iPhone
          </Checkbox>
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: checkedIP
        // path:'visit.member.profile.deviceRecords.iPhone',
      },
      {
        key: 'appVersion',
        label: '',
        render: appVersion => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            Version: {appVersion || 'N/A'}
          </div>
        ),
        span: 4,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      },
      {
        key: 'unassignIPhone',
        label: '',
        render: action => (
          <div style={{ fontSize: 14, marginTop: 1 }}>
            <a
              onClick={e => {
                e.preventDefault();
                if (isFormDisabled) {
                  return;
                }
                action();
              }}
            >
              Unassign device
            </a>
          </div>
        ),
        span: 3,
        itemStyle: { display: checkedIP ? 'flex' : 'none' }
      }
    ],
    [
      {
        key: CGMHelperServices.checkboxFieldName,
        label: '',
        type: 'checkbox',
        render: () => (
          <CGMCheckboxComponent 
            disabled={isFormDisabled}
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (!isChecked) {
                localThis.props.form.setFieldsValue({
                  [CGMHelperServices.brandFieldName]: undefined,
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex', flexDirection: 'row' },
        span: 4,
        valuePropName: 'checked',
        initialData: !!_.get(localThis, 'props.cgmContextValue.hasCGM'),
      },
      {
        key: CGMHelperServices.brandFieldName,
        label: '',
        type: 'radio',
        rules: [{
          validator: (rule, value, cb) => {
            if(
              CGMHelperServices.getCheckboxFieldValue(localThis.props.form)
              && !value
            ) {
              cb(CGMHelperServices.requiredMessage);
              return;
            }
            cb();
          },
        }],
        render: () => (
          <CGMRadioGroupComponent 
            disabled={isFormDisabled}
            onChange={(value) => {
              if (value) {
                localThis.props.form.setFieldsValue({
                  [CGMHelperServices.checkboxFieldName]: true,
                });
              }
            }}
          />
        ),
        itemStyle: { display: 'flex' },
        span: 6,
        initialData: _.get(localThis, 'props.cgmContextValue.CGMInfo.cgmBrand'),
      }
    ],
    [
      {
        key:'haveDeviceValues',
        label:'',
        renderOnly: true,
        render:()=> !haveDeviceValues ? <div style={{ fontSize:14,color:'red' }}>Please select device to process</div> :<div></div>
      }
    ]
  ];
};

export default {
  onBoardFormMap,
  editDeviceFormMap,
  giveOutDevicesAndUserProfile
};
