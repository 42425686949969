import { connect } from 'react-redux';
import AssessmentFilledComponent from '../components/AssessmentFilledComponent';
import * as actions from '../actions/AssessmentFilledActions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeAnswer: (index, answer) => dispatch(actions.changeAnswer(index, answer)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AssessmentFilledComponent);
