import React from 'react';
import { Row, Col, Form, AutoComplete, Select, Input, Icon, Popconfirm } from 'antd';

import { CLINIC_GOAL_ENUM, mappingCondition, REVERSED_CLINIC_GOAL_ENUM, conditionToType } from '../../helper/goalHelper';
import GoalStatus from './GoalStatus';

const getConditionInitialValue = (goalCondition) => mappingCondition[goalCondition] || goalCondition; // Enum string or plain string
const getGoalInitialValue = (goalCondition, goalValue) => {
  const conditionInitialValue = conditionToType[getConditionInitialValue(goalCondition)]; // should be converted to Enum
  const goalInitialValue = _.get(REVERSED_CLINIC_GOAL_ENUM, `${conditionInitialValue}.${goalValue}`) || goalValue;
  return goalInitialValue;
}

const NonBillableGoalComponent = props => {
  const { 
    form, 
    idx, 
    goalCondition, 
    goalValue, 
    rowClassName, 
    isReferredGoal, 
    disabled, 
    conditionOptions, 
    goalOptions, 
    goalHandlers,
    hideGoalStatus,
    newAddedGoal
  } = props;

  const { getFieldDecorator } = form;

  return (
    <Row id='nonbillable-goal-component' className={rowClassName}>
      <Col span={24} className={isReferredGoal ? 'referred-goal' : ''}>
        <Form.Item>
          {getFieldDecorator(`nonBillable.${idx}.condition`, {
            rules: [{ required: true, message: 'Field is required' }],
            initialValue: getConditionInitialValue(goalCondition)
          })(
            <AutoComplete
              disabled={disabled}
              style={{ width: '100%' }}
              onChange={(value) => goalHandlers.setGoalOptions(value, idx)}
              dataSource={conditionOptions}
              placeholder='Select or type a condition'
              defaultActiveFirstOption={false}
              dropdownClassName={`non-billable-goal-dropdown-${idx}`}
              notFoundContent='No condition is available'
            />
          )}
        </Form.Item>
      </Col>
      <Col span={hideGoalStatus ? 21 : 14}>
        <Form.Item>
          {
            (goalOptions) ?
              getFieldDecorator(`nonBillable.${idx}.goalEnum`, {
                // using reversed to migrate dirty data
                initialValue: getGoalInitialValue(goalCondition, goalValue)
              })(<Select 
                className='goal-value-select'
                placeholder='Select Goal'
                disabled={disabled}
                defaultActiveFirstOption={false}
                onChange={(value) => goalHandlers.setGoal('goalEnum', value, idx)}
                dropdownClassName={`nonBillable-goal-dropdown-${idx}`}
              >
                {_.map(goalOptions, (o, i) => {
                  return <Select.Option key={i} value={o}>{CLINIC_GOAL_ENUM[o] || o}</Select.Option>
                })}
              </Select>)
            : 
              getFieldDecorator(`nonBillable.${idx}.goal`, {
                initialValue: CLINIC_GOAL_ENUM[goalValue] || goalValue
              })(<Input 
                style={{ width: '100%', minHeight: 64, whiteSpace: 'pre-line' }}
                disabled={disabled}
                placeholder='Enter goal'
                onChange={(value) => goalHandlers.setGoal('goal', value, idx)}
              />)
          }
        </Form.Item>
      </Col>
      {
        !newAddedGoal &&
        <Col span={hideGoalStatus ? 0 : 6} offset={1}>
          <GoalStatus
            {...props}
            fieldKeyPrefix={`nonBillable.${idx}`}
            selectPlaceholder={undefined}
          />
        </Col>
      }
      <Col span={2} offset={1}>
        {
          goalCondition && !disabled ?
            <Popconfirm
              placement='topRight'
              title='Are you sure to remove this condition?'
              onConfirm={() => goalHandlers.removeCondition(idx)}
              okText='Yes'
              cancelText='No'
            >
              <Icon 
                type='delete' 
                className={!disabled ? '' : 'disabled-icon'}
                style={{ fontSize: 16, marginTop: 12 }} 
              />
            </Popconfirm> 
          :
            <Icon 
              type='delete' 
              className={!disabled ? '' : 'disabled-icon'}
              style={{ fontSize: 16, marginTop: 12 }}
              onClick={() => {
                if(!disabled) 
                  goalHandlers.removeCondition(idx)
              }}
            />
        }
      </Col>
    </Row>
  )
};

export default NonBillableGoalComponent;