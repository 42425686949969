import React from "react";
import { Form, Row, Popconfirm, Icon, AutoComplete, List, Input, Checkbox, Button } from 'antd';
import Client from 'libModule/gqlClient';
import getBehavioralGoalTemplates from '../../../graphql/getBehavioralGoalTemplate';
import _ from "lodash";
const {Option, OptGroup} = AutoComplete;
const {Item} = Form;
const linkStyle = {
  color: '#777777',
  textDecoration: 'underline'
}

let BehavioralGoals = class extends React.Component{
  constructor(props) {
      super(props);
      let data = _.get(props, 'program.behavioralGoals')||[];
      let checkedValues = [];
      let index = 0;
      _.map(data, d => {
        let r = {...d};
        r.key = index++;
        r.editMode = false;
        checkedValues.push(r);
      })
      this.state = {
        hasNote: _.get(props, 'program.extraNote'),
        dataSource: {},
        openDropdown: false,
        editingValues: {},
        value: null,
        checkedValues,
        index
      }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // catch the change of behavioral goals
    const prevBGoal = _.get(prevProps, 'program.behavioralGoals');
    const curBGoal = _.get(this.props, 'program.behavioralGoals');
    if(!_.isEqual(prevBGoal, curBGoal)){
      let checkedValues = [];
      let index = 0;
      _.map(curBGoal, d => {
        let r = {...d};
        r.key = index++;
        r.editMode = false;
        checkedValues.push(r);
      })
      this.setState({
        checkedValues,
        index
      })
    }
  }

  setNotes = (e) => {
    this.setState({hasNote: e.target.value});
    this.props.form.setFieldsValue({'goalNotes': e.target.value});
  }

  setCheckedValues = (checkedValues) =>{
    const {setFieldsValue, getFieldsValue, getFieldDecorator} = this.props.form;
    this.setState({checkedValues});
    if(!getFieldsValue().behavioralGoal){
      getFieldDecorator('behavioralGoal', {initialValue: _.get(this.props, 'program.behavioralGoals'),valuePropName: 'value'})
    }
    setFieldsValue({'behavioralGoal': checkedValues});
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getName = (string) => {
    return _.join(_.split(string, '_').map(x => this.capitalizeFirstLetter(x.toLowerCase())), ' ');
  }

  tokenizeIfMatch = (original, keyword) => _.split(original, new RegExp(`(${keyword})`, 'gi'));

  getHighlightedOptValue = optValue => {
    // const { value } = this.state;
    const value = _.trim(this.state.value);
    const tokens = optValue && value ? this.tokenizeIfMatch(optValue, value) : optValue;

    return <span>
      {
        _.map(tokens, (token, id) =>
        // token and search value must not be empty
        // .match() will return an array if match; null, otherwise
          token && value && token.match(new RegExp(`(${value})`, 'i')) ?
            <span key={id} className='behavioral-goal-highlighted'>{token}</span>
            : token
        )
      }
    </span>;
  };

  mapDataSource = (dataSource) => {
    let {checkedValues} = this.state;
    let options = [];

    _.forEach(dataSource, (v, _type) => {
      _.forEach(v, (c) => {
        const type = _.join(_.split(_type, '_'), ' ');
        const category = _.join(_.split(c.category, '_'), ' ');
        options.push(
          <OptGroup key={`group::${_type}::${c.category}`} label={
            <div>
              <div style={{ fontSize: 14, fontWeight: 'bold', color: '#222222', padding: '5px 0px', lineHeight: '16px', margin: 0 }}>{type}</div>
              <div style={{ fontSize: 12, color: '#717171', padding: 0, lineHeight: '14px', marginBottom: 10 }}>{category}</div>
            </div>
          }>
            {_.map(c.values, (optValue) => (
              <Option key={`${_type}::${c.category}::${optValue}`}>
                <Checkbox
                  checked={
                    _.find(checkedValues, (item) => {
                      return item.type === _type && item.category === c.category && item.value === optValue
                    })
                    // _.findIndex(checkedValues,
                    //   (item) => {
                    //     return item.marker === `${_type}:${c.category}:${optValue}` > -1
                    //   }
                    // )
                  }
                />
                <span style={{ marginLeft: 8 }}>{this.getHighlightedOptValue(optValue)}</span>
              </Option>
            ))}
          </OptGroup>
        )
      })
    })

    return options;
  }

  handleRemove = (item) => {
    let {checkedValues} = this.state;
    let updateCheckedValues = checkedValues.filter(o => o.key!== item.key);
    this.setCheckedValues(updateCheckedValues);
  };

  handleEdit = (item) =>{
    let {checkedValues} = this.state;
    let i =  _.findIndex(checkedValues, o => o.key === item.key);
    let newItem = {...checkedValues[i]}
    newItem.editMode = true;
    this.setCheckedValues([...checkedValues.slice(0, i), newItem, ...checkedValues.slice(i+1)])
  }

  handleSave = (item, isSaving) =>{
    let {checkedValues} = this.state;
    let i =  _.findIndex(checkedValues, o => o.key === item.key);
    let newItem = {...checkedValues[i]}
    newItem.editMode = false;
    if(isSaving){
      let value = this.state.editingValues[item.key];
      newItem.value = value;
    }
    this.setCheckedValues([...checkedValues.slice(0, i), newItem, ...checkedValues.slice(i+1)])
  }

  handleInputChange = (e, item) =>{
    e.preventDefault();
    const {editingValues} = this.state;
    let updateEditingValues = {...editingValues};
    updateEditingValues[item.key] = e.target.value;
    this.setState({editingValues: updateEditingValues})
  }

  getData = (value)=>{
    this.setState({value});
          Client.query({
            query: getBehavioralGoalTemplates,
            variables: {search: value === ' ' ? value : value.trim()},
            fetchPolicy: 'network-only'
          }).then(res => {
            const data = _.get(res, 'data.getBehavioralGoalTemplates') || [];
            const updateDataSource = _.groupBy(data, d => d.type);
            this.setState({dataSource: updateDataSource, openDropdown: true})
        }).catch(e => console.log(e))
  }

  handleSearch = (value) => {
    this.getData(value);
  }

  checkIsSame = (o1, o2) => {
    return o1.type === o2.type && o1.category === o2.category && o1.value == o2.value;
  }

  handleSelect = (val, option) =>{
    // cannot remove from selection
    if(option){
      const { checkedValues } = this.state;
      const optionKeyArr = _.split(option.key, '::');
      const type = optionKeyArr[0];
      const category = optionKeyArr[1];
      const value = optionKeyArr[2];
      let obj = {
        key: this.state.index,
        marker: val,
        value,
        category,
        type,
        editMode: false
      }
      let isChecked = false;
      _.forEach(checkedValues, (item) => {
        if(this.checkIsSame(item, obj)) {
          isChecked = true;
          _.remove(checkedValues, (item) => {
            return this.checkIsSame(item, obj);
          })
        }
      })
      if(isChecked) {
        const updateCheckedValues = [
          ...checkedValues,
        ];
        this.setState({index: this.state.index - 1});
        this.setCheckedValues(updateCheckedValues);
      } else {
        const updateCheckedValues = [
          ...checkedValues,
          { key: this.state.index,
            marker: val,
            value,
            category,
            type,
            editMode: false }
          ];
        this.setState({index: this.state.index + 1});
        this.setCheckedValues(updateCheckedValues);
      }
    }
  }

  renderAction = (item) =>{
    const {editingValues} = this.state;
    const {disabled, isEditMode} = this.props;

    let actions = disabled? []:[
      <a style={linkStyle} onClick={() => this.handleEdit(item)}>Edit</a>,
      <Popconfirm
        placement='topRight'
        title={'Are you sure to remove this condition?'}
        onConfirm={() => this.handleRemove(item)}
        okText='Yes'
        cancelText='No'
      >
        <a style={linkStyle}>Remove</a>
      </Popconfirm>];

    if(item.editMode && (isEditMode&& !disabled)){
      actions[0] =  <a style={{ textDecoration: 'underline' }} onClick={() => this.handleSave(item, !!editingValues[item.key])}>Save</a>
      actions[1] = <a style={linkStyle} onClick={()=>this.handleSave(item, false)}>Cancel</a>
    }

    return actions;
  }

  renderTags = () => {
    const {disabled, form, isEditMode,type} = this.props;
    const {checkedValues} = this.state;
    const className = disabled ? 'behavioral-goal-list-disabled' :'behavioral-goal-list';
    const isMNT = type == 'MNT';
    return (
      <div>
      <Item>
        {form.getFieldDecorator('behavioralGoal',{
          initialValue: _.get(this.props, 'program.behavioralGoals',[]),
          valuePropName: 'value',
          rules: [
            { validator: (rule, value, callback) => {
              if(!isMNT) callback();
              if(Array.isArray(value) && _.isEmpty(value)){
                callback('Please apply at least one goal')
              }else{
                callback()
              }
            },
            },
          ],
        })(<div></div>)}
      </Item>

      {
       checkedValues && !_.isEmpty(checkedValues) && <List
          className= {className}
          itemLayout='horizontal'
          bordered={true}
          size='small'
          id='behavioral-goal-list'
          dataSource={checkedValues}
          locale={{emptyText: ' '}}
          renderItem={(item, index) => {
            return (
            <List.Item key={index} actions={this.renderAction(item)}>
                {item.editMode && (isEditMode&& !disabled)? <Input.TextArea autoSize defaultValue={item.value} onChange={(e) => this.handleInputChange(e, item)}/> : item.value}
          </List.Item>
          )}}
        />
      }

      </div>

    )
  }


  renderSelect = () =>{
    const {dataSource, openDropdown} = this.state;
    const {disabled, isWorkList} = this.props
    return (
        <AutoComplete
          dataSource={this.mapDataSource(dataSource)}
          dropdownClassName='behavioral-goal-select-menu'
          id={isWorkList ? 'behavioral-goal-select-menu-work-list' : 'behavioral-goal-select-menu'}
          size='large'
          style={{width: '100%'}}
          disabled={disabled}
          defaultActiveFirstOption={false}
          onSearch={this.handleSearch}
          onSelect={this.handleSelect}
          onFocus={()=>this.getData(' ')}
          onBlur={()=> this.setState({openDropdown: false, value: null})}
          open={openDropdown}
          value={this.state.value}
          getPopupContainer={() => document.getElementById(isWorkList ? 'behavioral-goals-select-work-list' : 'behavioral-goals-select' )}
        >
          <Input id={isWorkList ? 'behavioral-goals-select-work-list' : 'behavioral-goals-select' } prefix={<Icon type='search' style={{ fontSize: '1.5em' }}/>}
                 // getPopupContainer={() => document.getElementById('behavioral-goal-select-menu')}
                 placeholder='Search behavioral goals'/>
        </AutoComplete>
      )
  }

  onAddNoteClick = () =>{
    this.setState({hasNote: true});
  }

  renderNotes = () =>{
    const {disabled, form, program} = this.props;
    return(
      <Row className='goal-note'>
        <Item label='Notes'>
        {form.getFieldDecorator('goalNotes', {
          initialValue: _.get(program, 'extraNote'),
          valuePropName: 'value'
        })(
          <Input.TextArea autoSize={{ minRows: 1 }} disabled={disabled} placeholder='Add note...' onChange={this.setNotes} allowClear/>
        )}
        </Item>
      </Row>
    )
  }

  render(){
    const {disabled, isWorkList, showBehavioralGoals } = this.props;
    const {hasNote} = this.state;
    const className = isWorkList ? 'behavioral-goals-worklist' : 'border-goals';
    // only display when enrolled MNT
    // or MNT/FOLLOW_UP/INITIAL
    return (
      showBehavioralGoals &&
      <div className='behavioralGoals'>
        <div>
            <div className='goals-list-items'>
              Behavioral Goals
            </div>
            <div className={className} id='behaviroal-goal'>
              <div className='behavioral-goal-value' style={{marginBottom: '30px'}}>
                <Row key='search-input' className='behavioral-goals-select' id={isWorkList ? 'behavioral-goals-select-work-list' : 'behavioral-goals-select'}>
                  {this.renderSelect()}
                </Row>
                <Row key='tags' className='behavioral-goals-tags'>
                {this.renderTags()}
                </Row>
              </div>
              </div>
        </div>
       { hasNote? this.renderNotes() :
           <Row className='add-goal-note' style={{float: 'left'}}>
            <Button className='add-click'
                    type='link'
                    onClick={this.onAddNoteClick}
                    id='goal-statement-add-note'
                    disabled={disabled}>
                    <Icon type='plus'/>
                    Add Note
            </Button>
          </Row>}
      </div>
    )
  }
}
BehavioralGoals = Form.create({})(BehavioralGoals);
export default BehavioralGoals;
