import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Button, Input, Modal } from 'antd';
import $ from 'jquery';
import NutritionInterpretationSelect from '../component/NutritionInterpretation/NutritionInterpretationSelect';
import { 
  renderBodyInfo, parseNutritionVariables, checkIfResolved, shortenId, getRawRefId 
} from '../helpers/nutritionHelpers';
import { 
  categoryMap, GROUP_DELIMITER, VALUE_DELIMITER, PESStatementActions, visitTypesCanResolvePES 
} from '../constant/nutritionInterpretationConstants';
import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils';
import editUser from 'graphqlModule/mutation/editUserNutrition';
import getVisit from '../../graphql/singleVisit';
import getProblemsGQL from 'graphqlModule/getPESTemplates';
import { getPESStatements } from '../../graphql/getPESStatements';
import { createMultiPES } from '../../graphql/mutation/createPESStatement';
import { editMultiPES } from '../../graphql/mutation/editPESStatement';
import '../style/nutritionInterpretation.styles.scss';
import renderOverlayDiv from '../helpers/overLayButton';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
import ArticlesContainer from './ArticlesContainer';
import confirmUnsavedArticles from '../helpers/confirmUnsavedArticles';

const  NutritionInterpretation = props => {
  const { 
    disabled = false, isEditMode = false, onChange: globalOnChange, 
    user, visit, enrolledProgramId, isWorkList, 
    onTouch, setChildProps, openErrorModal,
    submitButtonText, afterSubmitSucc, afterSaveDraftSucc, title,
    problemOptions, loadingProblemOptions,
    loadingUserPESStatements, userPESStatements, refetchUserPESStatements,
    onInteraction,
  } = props;
  const userNutritionValues = _.get(user, 'profile.nutrition');
  const [componentValues, setComponentValues] = useState({});
  const [PESStatementData, setPESStatementData] = useState(undefined);
  const [interpretationNote, setInterpretationNote] = useState(_.get(userNutritionValues,'interpretationNote') || '');
  const [hasInterpretationNote, setHasInterpretationNote] = useState(!_.isEmpty(interpretationNote));

  useEffect(() => {
    const newNote = _.get(userNutritionValues, 'interpretationNote');
    if(!_.isEqual(interpretationNote, newNote)) {
      setInterpretationNote(newNote);
      setHasInterpretationNote(!_.isEmpty(newNote));
    }
  }, [userNutritionValues]);

  const visitRefetchQuery = [{
    query: getVisit,
    variables: { id: _.get(visit, 'id') },
    fetchPolicy: 'network-only'
  }];

  const getRefetchQueryForMember = () => {
    // container for this component in Patient Profile collapse
    const containerRef = $('#nutrition-interpretation-select-wrapper-not-in-work-list');

    return !_.isEmpty(containerRef) ? [{
      query: getPESStatements,
      variables: { memberId: _.get(user, 'id') }
    }] : [];
  };

  // using debounce to reduce multiple updates when typing input fields
  const setHasChanges = changeValues => {
    const allValues = {
      ...componentValues,
      PESStatementData,
      interpretationNote,
      ...changeValues
    };
    if(globalOnChange) {
      globalOnChange({
        shouldEditUser: true,
        editUserVar: parseValue(allValues),
        cb: allValues => {
          const newInterpretationNote = _.get(allValues, 'interpretationNote', '');
          setHasInterpretationNote(!_.isEmpty(newInterpretationNote));
        },
        cbVar: allValues
      });
    } else if(isWorkList && onTouch) {
      if(!isEditMode) onTouch();
      setChildProps(
        allValues,
        parseValue,
        editUser,
        visitRefetchQuery,
        NutritionInterpretation.title
      );
    }
    setComponentValues(allValues);
  };

  useEffect(() => {
    if(!loadingProblemOptions && !loadingUserPESStatements) {
      const visitId = _.get(visit, 'id');
      let statements = [...userPESStatements];
      // Logic 2 - see BackEnd Design
      // filter all resolved PES from the past
      // aka not belong to the current visit, if applicable
      if(visitId){
        statements = _.filter(statements, s => {
          const pesVisitId = _.get(s, 'visit.id');
          return _.isEqual(pesVisitId, visitId) ||
                 _.isEqual(_.get(s, 'resolution.visit.id'), visitId) || 
                (!_.isEqual(pesVisitId, visitId) && !checkIfResolved(_.get(s, 'resolution.status')));
        });
      }

      // pre-process data for initial fetch and after saving draft with button
      // if PES is created during the visit, then PES should be removable
      statements = _.map(statements, (data, statementIndex) => {
        const removable = _.isEqual(_.get(data, 'visit.id'), visitId);
        return {
          statementIndex,
          data,
          removable,
        }
      });

      setPESStatementData(statements);
    }
  }, [loadingProblemOptions, loadingUserPESStatements, userPESStatements]);

  const getKeyByValue = (map, value) => _.keys(map).find(k => map[k] === value);

  const processData = (category, value) => {
    if(category === 'problem'){
      const pValue = _.get(_.find(problemOptions, pO => pO.refId === value), 'value');
      return { optionId: value, value: pValue };
    }
    const categoryValues = _.map(value, optionString => {
      let [optRefId, value] = optionString.split(VALUE_DELIMITER);
      optRefId = getRawRefId(optRefId);
      let obj = {
        optionId: optRefId,
        value
      };
      if(_.includes(optRefId, GROUP_DELIMITER)){
        const [groupId, optionId] = optRefId.split(GROUP_DELIMITER);
        obj = { ...obj, groupId, optionId }
      }
      return obj;
    });
    return categoryValues;
  }

  const parseValue = (allValues = componentValues) => {
    const patientId = _.get(user, 'id');
    const {
      PESStatementData = [], interpretationNote, handouts, 
      behaviorChange, ...PESStatements 
    } = allValues;

    let nutrition = parseNutritionVariables(
      _.get(user, 'profile.nutrition'),
      { behaviorChange, interpretationNote }
    );

    const userVariables = {
      id: patientId,
      memberProfile: {
        birthday: _.get(user, 'profile.birthday'), // required by editUser
        nutrition
      }
    };    

    if(isWorkList && !_.isEmpty(PESStatements)){
      const visitId = _.get(visit, 'id');
      // Don't process PES because PES is only editable and creatable in Work List
      const commonVars = {
        memberId: patientId,
        visitId
      };
      let createdPESStatements = {...commonVars, pesStatements: []};
      let editedPESStatements = { pesStatements: [] };
  
      _.forEach(PESStatementData, data => {
        const { statementIndex, created = false, data: { id: pesId } } = data;
        const problem = _.get(PESStatements, `problem-${statementIndex}`);
        const etiology = _.get(PESStatements, `etiology-${statementIndex}`);
        const signSymptom = _.get(PESStatements, `signSymptom-${statementIndex}`);
        if(!_.isEmpty(problem)) { // Skip/ auto-remove any statement doesn't have problem
          let pesStatementObj = { problem, etiology, signSymptom };
          if(created) {
            createdPESStatements.pesStatements.push(pesStatementObj);
          } else {
            pesStatementObj = {...pesStatementObj, id: pesId};
            const status = _.get(PESStatements, `resolution-${statementIndex}`);
            if(status) {
              pesStatementObj = {
                ...pesStatementObj, resolution: { status }
              };
            }
            editedPESStatements.pesStatements.push(pesStatementObj);
          }
        }
      });
  
      const pushPESStatements = action => {
        let editAction = false, mutation, variables;
        switch(action){
          case PESStatementActions.CREATE:
            mutation = createMultiPES;
            variables = {...createdPESStatements};
            break;
          case PESStatementActions.EDIT:
            editAction = true;
            mutation = editMultiPES;
            variables = {...editedPESStatements};
            break;
          default:
            throw new Error('Missing action for pushPESStatements function');
        }

        const getOldPES = sIndex => {
          let {
            id, problem, 
            etiology, signSymptom, resolution = {}
          } = _.get(PESStatementData[sIndex], 'data', {});
          let oldPES = {};
          problem = _.omitBy(_.omit(problem, '__typename'), _.isNull);

          // only compare version of existing PES
          if(id){
            resolution = _.omit(resolution, '__typename');
            etiology = _.map(etiology, e => _.omitBy(_.omit(e, '__typename'), _.isNull));
            signSymptom = _.map(signSymptom, s => _.omitBy(_.omit(s, '__typename'), _.isNull));

            oldPES = { id, problem, etiology, signSymptom };
            if(!_.isEmpty(resolution) && !_.isNil(_.get(resolution, 'status'))){
              oldPES.resolution = {
                status: _.get(resolution, 'status')
              };
            }
          }
          return oldPES;
        }
  
        if(_.get(variables, 'pesStatements.length')){
          variables.pesStatements = _.map(variables.pesStatements, (statement, sIndex) => {
            let data = {...statement};
            data = _.omit(data, ['statementIndex', 'removable']);
            _.forEach(_.keys(data), k => {
              if(getKeyByValue(categoryMap, k)) {
                data[k] = processData(k, data[k]);
              }
            });

            const oldPES = getOldPES(sIndex);

            if(editAction && !_.isEmpty(oldPES)){
              const newPESnoRes = _.omit(data, 'resolution');
              const oldResolution = _.get(oldPES, 'resolution.status');
              const newResolution = _.get(data, 'resolution.status');
              
              const hasNewPES = !_.isEqual(newPESnoRes, _.omit(oldPES, 'resolution'));
              const hasNewResolution = !_.isEqual(oldResolution, newResolution);
              // prevent update if there is no change
              if(hasNewPES || hasNewResolution) {
                if(hasNewResolution){
                  data.resolution = {
                    ...data.resolution,
                    visitId: shortenId(visitId)
                  };
                } else {
                  data = newPESnoRes;
                }
                return data;
              } else {
                return null;
              }
            }

            return data;
          });

          variables.pesStatements = _.filter(variables.pesStatements, p => !_.isEmpty(p));

          if(_.get(variables, 'pesStatements.length')){
            Client.mutate({
              mutation,
              variables,
              refetchQueries: getRefetchQueryForMember()
            }).catch(err => {
              console.error(err);
              if(openErrorModal) openErrorModal(`Failed to ${action} PES Statement(s)`);
            });
          }
        }
      };
  
      // Create PES if there is any
      pushPESStatements(PESStatementActions.CREATE);
  
      // Edit PES if there is any
      pushPESStatements(PESStatementActions.EDIT);

    }

    // handouts is only available in WorkList
    if(isWorkList && handouts) userVariables.memberProfile.handouts = handouts;

    return userVariables;
  }

  // check whether there is PES Statement unfinished
  const hasUnfinishedPESStatement = () => {
    const unfinished = _.filter(PESStatementData, ({ statementIndex }, formIndex) => {
      const problem = _.get(componentValues, `problem-${statementIndex}`);
      const hasEtiology = _.get(componentValues, `etiology-${statementIndex}`, []).length > 0;
      const hasSignSymptom = _.get(componentValues, `signSymptom-${statementIndex}`, []).length > 0;
      const isUnfinished = !_.isEmpty(problem) && !(hasEtiology && hasSignSymptom);
      if(isUnfinished){
        // set error
        const formWrapperRef = $('#nutrition-interpretation-select-wrapper-in-work-list #form-builder-layout');
        // pes statement div starting from index of 2 -> (n-1)
        // MIGRATION ATTENTION
        const pesRef = _.get(formWrapperRef, `0.children.${formIndex + 2}`);
        if(pesRef) pesRef.classList.add('error');
      }
      return isUnfinished;
    });
    return !!unfinished.length;
  };

  const meetRequirement = () => {
    if(_.get(PESStatementData, 'length', 0) < 1) return true;
    // don't count PES statement that has RESOLVED status
    const temp = _.filter(PESStatementData, ({ data, removable, statementIndex }) => {
      // ignore PES template (no data or problem available)
      if(_.isEmpty(data) &&_.isEmpty(_.get(componentValues, `problem-${statementIndex}`, ''))) 
        return false;

      let resolutionStatus = 
        _.get(componentValues, `resolution-${statementIndex}`); // _.get won't set default value if null
      resolutionStatus = resolutionStatus || _.get(data, 'resolution.status');
      const isResolved =  checkIfResolved(resolutionStatus);

      // if PES is removable, PES is added in the visit
      // if PES is removable and has status of unresolved. TODO: clarify for this requirement
      return (removable && !isResolved) || !isResolved;
    });
    return temp.length < 1;
  }

  const handleSave = _.debounce(async (actionType) => {
    const isCompleteAction = _.isEqual(actionType, 'complete');
    if(hasUnfinishedPESStatement()) {
      return Modal.warning({ 
        centered: true,
        content: 'Please complete or remove\nthe unfinished PES statement(s) before saving.'
      });
    }
    if(isCompleteAction && meetRequirement()) {
      return Modal.warning({ 
        centered: true,
        content: `Please add at least one PES statement before completing the ${NutritionInterpretation.title}`
      });
    }
    
    const res = await confirmUnsavedArticles();
    if (!res) return;

    Client.mutate({
      mutation: editUser,
      variables: parseValue(),
      refetchQueries: actionType === 'draft' ? visitRefetchQuery : []
      // auto-update values in patient profile, via cache
    }).then((res) => {
      switch(actionType){
        case 'draft':
          setHasInterpretationNote(!_.isEmpty(interpretationNote));
          afterSaveDraftSucc();
          break;
        case 'complete':
          let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
          Mixpanel.track('Clicked', submitText, 'Charting_' + title, {});
          afterSubmitSucc();
          break;
        default:
          console.warn(`Missing action when handing save in ${NutritionInterpretation.displayName}`);
          break;
      }
      // refetch the list of PES Statements
      // action type is complete => no refetch to prevent memory leak
      if(!isCompleteAction) refetchUserPESStatements();
    }).catch(err => {
      console.error(err);
      if(openErrorModal) openErrorModal(GQLHelper.formatError(err));
    });
  }, 500);

  return (
  <div id='nutrition-interpretation-main-container'>
    {!enrolledProgramId && isWorkList && renderOverlayDiv(props)}
    <div style={{ opacity: !enrolledProgramId && isWorkList ? 0.2 : 1 }}>
      { 
        isWorkList && 
        <div className='fix-header'>
          <div className='fixHeaderTitle'>
            <div className='section-label-h3'>
              Nutrition Interpretation/Diagnosis
            </div>
            {renderDraftSavedText()}
          </div>
          { renderBodyInfo(user) }
        </div>
      }
      <div className='scrollable-content'>
        <NutritionInterpretationSelect 
          disabled={disabled}
          behaviorChange={_.get(user, 'profile.nutrition.behaviorChange', null)}
          currentVisitId={_.get(visit, 'id')}
          isCheckedIn={!_.isNil(_.get(visit, 'checkinAt'))}
          isWorkList={!!isWorkList}
          canResolvePES={_.includes(visitTypesCanResolvePES, _.toUpper(_.get(visit, 'type')))}
          PESStatementDatasource={PESStatementData}
          setPESStatementDatasource={setPESStatementData}
          setHasChanges={setHasChanges}
          problemOptions={problemOptions || []}
          getRefetchQueryForMember={getRefetchQueryForMember}
        />
        {
          isWorkList &&
          <div className='nutrition-interpretation-intervention-education-wrapper'>
            <ArticlesContainer 
              patientId={_.get(user, 'id')}
              patientLanguageSettings={(
                _.pick(_.get(user, 'profile'), ['language', 'appLanguage'])
              )}
              isWorkList
              onInteraction={onInteraction}
            />
          </div>
        }
        <div className='extra-notes-area-wrapper'>
          {
            !hasInterpretationNote ?
              <Button
                className='add-click'
                type='link' icon='plus'
                disabled={disabled}
                onClick={() => setHasInterpretationNote(true)}
              >
                Add Note
              </Button>
            :
              <div className='extra-notes-textarea-wrapper'>
                <div className='section-label'>Notes</div>
                <Input.TextArea
                  disabled={disabled}
                  autoSize={{ minRows: 3 }}
                  value={interpretationNote}
                  onChange={e => {
                    const newInterpretationNote = e.target.value;
                    setInterpretationNote(newInterpretationNote);
                    setHasChanges({ interpretationNote: newInterpretationNote });
                  }}
                  placeholder='Add additional information here...'
                />
              </div>
          }
        </div>
      </div>
      {
        isWorkList &&
        <div className='fix-footer'>
          {
            isEditMode &&
            <Button 
              disabled={disabled} 
              onClick={() => handleSave('draft')}
              className='saveDraftBtn'
            >
              Save Draft
            </Button>
          }
          <Button 
            disabled={disabled} 
            type='primary' 
            onClick={() => handleSave('complete')}
          >
            { submitButtonText || 'Complete Section' }
          </Button>
        </div>
      }
    </div>
  </div>
  );
}

NutritionInterpretation.propTypes = {
  disabled: PropTypes.bool, // to toggle editmode in global
  isEditMode: PropTypes.bool.isRequired, // to notify component has change
  onChange: PropTypes.func, // set queryVariables and editMode=>true
  problemOptions: PropTypes.array.isRequired,
  loadingProblemOptions: PropTypes.bool.isRequired,
  /* for WorkList only */
  visit: PropTypes.object,
  isWorkList: PropTypes.bool,
  onTouch: PropTypes.func,
  setChildProps: PropTypes.func,
  submitButtonText: PropTypes.string,
  afterSubmitSucc: PropTypes.func,
  afterSaveDraftSucc: PropTypes.func,
  openErrorModal: PropTypes.func,
  /*-------------------*/
  user: PropTypes.object.isRequired,
};

NutritionInterpretation.title = 'Nutrition Interpretation';
NutritionInterpretation.displayName = 'NutritionInterpretationContainer';

const fetchProblems = graphql(getProblemsGQL, {
  options: ownProps => ({
    skip: _.get(ownProps, 'problemOptions.length') > 0,
    variables: { category: 'PROBLEM' },
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => {
    if(data.error) console.error('Failed to fetch problem options ', data.error);

    let problemOptions = [];

    const addPOption = (category, refId, value) =>
      problemOptions.push({ category, refId, value });
    
    _.forEach(_.get(data, 'getPesTemplatesByCategory', []), d => {
      const { id, category, value } = d;
      if(category === 'PROBLEM') addPOption(category, shortenId(id), value);
    });

    return {
      problemOptions,
      loadingProblemOptions: data.loading,
    };
  }
});

const fetchUserPESStatements = graphql(getPESStatements, {
 options: ownProps => ({
  // ONLY for initial fetch and after saving draft
  skip: ownProps.isEditMode, 
  variables: {
    memberId: _.get(ownProps, 'user.id'),
    visitId: _.get(ownProps, 'visit.id')
  },
  fetchPolicy: 'network-only'
}),
 props: ({ ownProps, data }) => {
    if(data.error) {
     console.error('Failed to fetch user PES Statements ', data.error);
     if(ownProps.openErrorModal) 
      ownProps.openErrorModal(`Failed to retrieve PES Statement(s)`);
    }
    return {
      loadingUserPESStatements: data.loading,
      userPESStatements: _.get(data, 'getPESStatements', []),
      refetchUserPESStatements: data.refetch
    }
 }
});

export default compose(fetchProblems, fetchUserPESStatements)(NutritionInterpretation);