import gql from 'graphql-tag';

export  default gql`mutation createMedication($memberId:EID!,$name:String!,$startDate:Date,$endDate:Date,
$type:MedicationTypeEnum,$dosage:String,$instruction:String,$otherMD:Boolean,$status:MedicationStatus)  {
  createMedication(memberId:$memberId,name:$name,type:$type,dosage:$dosage,instruction:$instruction,startDate:$startDate,endDate:$endDate, status:$status,
  otherMD:$otherMD)  
   {
    id
    member {
      id
      profile {
        fullName
      }
    }
    name
    type
    startDate
    endDate
    otherMD
    healthCondition
    dosage
    instruction
    active
    createdAt
    updatedAt
    status
  }
}`
