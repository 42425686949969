import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import types from '../constants/NewCareTeamConstants'
import { schema } from '../components/formData/NewCareTeamForm'


// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)

const initState = {
  initData,
  fieldsValue: initFieldsValue,
  isFormValid: calcFormStatus(initFieldsValue, isFieldsRequired),
  isFieldsRequired,
  isFormSaved: true,
  isSubmitting: false,
  doctors : [],
  managers : [],

  p_doctors : [],
  p_managers : [],
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.FORM_SAVED:
      return {
        ...state,
        isFormSaved: true,
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormValid: calcFormStatus(newFieldsValue, isFieldsRequired),
        isFormSaved: false
      }
    case types.RESET_FORM:
      return initState
    case types.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }
    case types.CHANGE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
