const MonthlyReviewSummarySchema = `
  vital
  unit
  recent2Week {
    average
    diastolic
    systolic
  }
  previous2To4Week {
    average
    diastolic
    systolic
  }
  previous4To8Week {
    average
    diastolic
    systolic
  }
  schedule {
    target
    unit
  }
  percentage
  mealType
  beforeMeal
  timeSlots
  popUp
  type
  drop
  rose
  similar
`;

export default MonthlyReviewSummarySchema;