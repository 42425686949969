/*
  This Component will return an image, using the signedUrl.
  GoThumb's 15min signedUrl validity has been handled.
  signedUrl will only be fetch if empty or expired.

  Trigger to fetch: on render
*/

import PropTypes from 'prop-types';

import React from 'react';
import config from 'libModule/config'

class GoThumbImageComponent extends React.Component {
  static displayName = 'GoThumbImageComponent'

  static cache = {}

  request(url) {
    GoThumbImageComponent.cache[url] = { timestamp: 0, loading: true, errorMessage: '' }

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    const myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(`${config.signFileURL}/${url}`, myInit)
      .then(res => res.text())
      .then(signedUrl => {
        GoThumbImageComponent.cache[url] = { timestamp: Date.now(), signedUrl, loading: false }

        // force rerender
        this.setState({})
      })
      .catch((error) => {
        GoThumbImageComponent.cache[url] = { timestamp: Date.now(), signedUrl: '', loading: false, errorMessage: error}
      });;
  }

  render() {
    const originUrl = this.props.filename
    const cacheItem = GoThumbImageComponent.cache[originUrl]

    const defaultStyles = {
      width:'30px',
      height:'30px',
      solid: '#000',
      display: 'inline-block',
      margin: '0 10px 0 0',
      borderRadius: '4px'
    }
    const style = this.props.style ? this.props.style : defaultStyles

    if (!cacheItem || Date.now() - cacheItem.timestamp > 15 * 60 * 1000) {
      this.request(originUrl);
      return <img src="" alt=""/>
    }

    return <img src={cacheItem.signedUrl} alt="" style={style} />
  }
}

GoThumbImageComponent.propTypes = {
  filename: PropTypes.string,
  style: PropTypes.object
}

export default GoThumbImageComponent
