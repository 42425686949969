import {
  React, _
} from 'ihcomponent'
import I18N from 'modulesAll/I18N';
import { getPreviousKeyInDevicesInfo, filterOnlyUniqueDeviceAddress, getLoanForm } from 'modulesAll/vendor/patient/common/utils';
import withCommonDevicesList from 'modulesAll/vendor/patient/components/CommonDevicesList';
import PropTypes from 'prop-types'
class DevicesList extends React.PureComponent {
  static displayName = 'patient/enrolledProgramInfo/components/DevicesList'

  static propTypes = {
    resetDevicesTab: PropTypes.func,

    //similar
    renderMainLayout: PropTypes.func,
    data: PropTypes.object,

    //for replace return devices form
    fieldsValue: PropTypes.object,
    onFieldsChange: PropTypes.func,



  };


  componentWillUpdate(nextProps, nextState) {

    //handle case on the same ep, replace devives follow by discharge ep without refreshing page
    //reset to replace return screen to step 1
    if(this.props.data.id  === nextProps.data.id &&
      (this.props.data.status === 'STARTED' /** || this.props.data.status === 'SUSPENDED'**/ ) &&
      nextProps.data.status === 'DISCHARGED'){
      this.props.resetDevicesTab(nextProps.data.status, getPreviousKeyInDevicesInfo(nextProps.data.devices));
    }

  }

 //  shouldComponentUpdate(np) {
 //    //if status remain the same, don't update devices tab;
 //    //after replace devices and there is update in enrolledprogram.devices as apollo update all cache dont update to retain old data until page is refresh
 //    //so if press replace devices many times, retain the first time devices address info entered until page is refresh and provider is consistence with vendor page
 //    if (this.props.data.status === 'STARTED'  &&
 //      this.props.data.status === np.data.status  &&
 //      np.data.id === this.props.data.id &&
 //      this.props.data.devices && np.data.devices !== this.props.data.devices
 //    ) {
 //      return false
 //    }
 //    return true
 // }

  getEnrolledProgramme(props) {
    return props.data;
  }


  checkEnrolledProgramIsDischarged(props) {
    return _.get(props.data, 'status') === 'DISCHARGED';
  }


  //will get mutated
  deviceTableProps = {
    rowKey: 'address',
  }

  render() {
    const ep = this.getEnrolledProgramme(this.props);
    const loanForm = getLoanForm(ep);
    const epStatus = ep.status;
    const epStartDate = ep.startDate;
    const epId = ep.id;
    const updateDevicesInEnrolledProgramInList = false; //optional
    const i18nLoanForm = I18N.get('auditLog.provider.patient.loanForm');
    const i18nViewDevicesHistory = I18N.get('auditLog.provider.patient.viewDeviceHistory');
    const i18nNoLoanForm = I18N.get('auditLog.provider.patient.noloanForm');

    this.deviceTableProps.dataSource = filterOnlyUniqueDeviceAddress(_.get(ep, 'devices', []) || [])/** .filter( device => device.status === 'VENDOR_LOAN' ) VS-2084 shows all devices including patient owned **/;

    const hasVendorLoanDevices = this.deviceTableProps.dataSource.some(d => d.status === 'VENDOR_LOAN');
    const isEnrolledProgramDischarged = ep.status === 'DISCHARGED';
    const { onFieldsChange, fieldsValue } = this.props;

    return this.props.renderMainLayout({ep, loanForm, epStatus, epStartDate, epId, updateDevicesInEnrolledProgramInList,
    i18nLoanForm, i18nViewDevicesHistory, i18nNoLoanForm, hasVendorLoanDevices, isEnrolledProgramDischarged, onFieldsChange, fieldsValue, deviceTableProps: this.deviceTableProps });


  }


}





export default withCommonDevicesList(DevicesList);
