import { getQRCodeFromString, FormHelper, GQLHelper } from 'libModule/utils';
import PatientComponent from '../components/PatientComponent'
import { getReplaceDeviceModalProps } from '../components/DevicesReplacementScanQRCode'
import {enrolledProgramListForPatientByVendor} from 'modulesAll/graphql/enrolledProgramList'
import returnDevices from 'modulesAll/graphql/returnDevices';
import PropTypes from 'prop-types';
import { withApollo,compose } from 'react-apollo'
import { React, _ } from 'ihcomponent'
import { connect } from 'react-redux'
import {setPatientInfo, setLoading, onChangeEnrolledProgram, setEnrolledProgromList,setSearchText, setSearchStatus, commonReplaceReturnDevicesFormActionsObject, commonReplaceReturnDevicesActionsObject /**updateEnrolledProgromList**/} from '../actions'
import { openModal, closeModal } from 'layoutModule/actions/MainModal';
import moment from 'moment'
import I18N from 'modulesAll/I18N'
import { createAuditLog } from 'libModule/utils/auditLog'
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { getPreviousKeyInDevicesInfo } from 'modulesAll/vendor/patient/common/utils';
import { showMonthsOrDays } from 'libModule/utils';

class PatientContainer extends React.Component {
  static displayName = 'vendor/patient/containers/PatientContainer'

  static propTypes = {
    setPatientInfo: PropTypes.func,
    setReplaceDevicesStep: PropTypes.func,
    initDevicesStatusTableInfo: PropTypes.func,
    processReplaceOrReturnDevicesAsync: PropTypes.func,
    onChangeEnrolledProgram: PropTypes.func,
    client: PropTypes.object,
    setEnrolledProgromList: PropTypes.func,
    enrolledProgramList: PropTypes.array,
    setLoading: PropTypes.func,
    setSearchStatus: PropTypes.func,
    setReturnDevicesStep: PropTypes.func,
    handleFormSaved: PropTypes.func,
    resetForm: PropTypes.func,
    setSubmitting: PropTypes.func,
    updateEnrolledProgromList: PropTypes.func,
    fieldsValue: PropTypes.object,
    setSearchText: PropTypes.func,
    openErrorModal: PropTypes.func,
    patientInfo: PropTypes.object,
    resetDevicesStatusTableInfo: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func
  }

    render() {
      return <PatientComponent {...this.props} runUserQuery={this.runUserQuery} setReplaceDevicesStep={this.props.setReplaceDevicesStep}
         processReplaceOrReturnDevicesAsync={this.processReplaceOrReturnDevicesAsync} getPreviousKeyInDevicesInfo={getPreviousKeyInDevicesInfo}
         updateDevicesInEnrolledProgramInList={this.updateDevicesInEnrolledProgramInList}
       />
    }

    componentWillMount(){
      this.props.setSearchStatus('INIT');//reset
      this.props.setSearchText(''); //reset
      this.props.setReplaceDevicesStep(1); //go back step 1
      this.props.resetDevicesStatusTableInfo(); //reset devicesStatus in redux
      this.props.setReturnDevicesStep(1); //back to step 1
      this.props.resetForm();
    }



    runUserQuery = async (NRIC) => {
      this.props.setLoading(true);
      this.props.setSearchStatus('INIT');//reset
      this.props.onChangeEnrolledProgram(''); //reset state.Vendor.Patient.enrolledProgramId, need  be '' ,empty to reset
      try{
        const res = await this.props.client.query({
          query: enrolledProgramListForPatientByVendor,
          variables: {
            NRIC
          }
        });
        const userInfo = _.get(res,'data.enrolledProgramByPatientNRIC.data[0].user');

      createAuditLog({
        action: I18N.get('auditLog.vendor.searchPatient'),
        details: { 'patientNRIC': NRIC },
        request: { nric: NRIC },
        response: res  //temporily remove due to res too huge
      })

      const parseEnrollProgramName = (name, organization) => {
          return `${organization.name}, ${name}`
      }

      this.props.setPatientInfo(userInfo || {});
      const enrolledProgramList = _.get(res,'data.enrolledProgramByPatientNRIC.data', [])
                      .filter(ep => (ep.status === 'STARTED' /**|| ep.status === 'SUSPENDED'**/ || ep.status === 'DISCHARGED' || ep.status === 'RETURNED') )
                      .map(e => { const diffDays = moment(e.endDate).diff(e.startDate, 'days') + 1;
                                  return { name: e.name,
                                           rawName : parseEnrollProgramName(e.name, e.organization) ,  //use for selectbox
                                           value: e.id, //use for select box
                                           devices: e.devices,
                                           forms: e.forms,
                                           status: e.status,
                                           qrcode: e.qrcode,
                                           startDate: e.startDate && moment(e.startDate).format('MMM-DD-YYYY'),
                                           rawStartDate: e.startDate,
                                           duration: diffDays && `${ showMonthsOrDays(diffDays, 'text') }`  }; })
      this.props.setEnrolledProgromList(enrolledProgramList);

      const ep = enrolledProgramList[0];
      const previousKeyInDevicesInfo = getPreviousKeyInDevicesInfo(ep);
      if (previousKeyInDevicesInfo && previousKeyInDevicesInfo.length) {
         this.props.initDevicesStatusTableInfo(previousKeyInDevicesInfo);
         this.props.setReplaceDevicesStep(2);



      } else {
         this.props.resetDevicesStatusTableInfo(); //reset devicesStatus in redux
         this.props.setReplaceDevicesStep(1); //go back step 1
      }

      this.props.setReturnDevicesStep(1); //back to step 1
      this.props.setSearchStatus( this.props.enrolledProgramList && this.props.enrolledProgramList.length ? 'HAS_RESULT' : 'NO_RESULT');
    } catch(e) {
      this.props.setPatientInfo({});
      this.props.setEnrolledProgromList([]);
      this.props.setSearchStatus('NO_RESULT');

      createAuditLog({
        action: I18N.get('auditLog.vendor.searchPatient'),
        details: { 'patientNRIC': NRIC },
        request: { nric: NRIC },
        response: e,
        success: false
      })
    } finally {
      //always reset dropdown list when search different user
      this.props.setLoading(false);
    }
  }

  processReplaceOrReturnDevicesAsync = async (epId, results, isDischarged) => {
    const { handleFormSaved, resetForm, setSubmitting, fieldsValue, patientInfo } = this.props;
    setSubmitting(true);
    await handleFormSaved();

     const formValue = FormHelper.getValue(fieldsValue)
     const returnee = isDischarged ?
     {  /** return devices **/
        returnedBy: formValue.returnedBy,
        returnedDate: formValue.returnedDateAndTime.toDate(),
        receivedBy: formValue.processedBy,
        receivedDate: formValue.processedDateAndTime.toDate()
      } :
      { /** replace devices **/
        replacedBy: formValue.replacedBy,
        replacedDate: formValue.replacedDateAndTime.toDate()
      }
     const patientNRIC = _.get(patientInfo,'identification[0].value')
     const patientName = _.get(patientInfo,'profile.fullName')
     const variables = {
          id: epId,
          returnee
     }
     results.map( e => {
            variables[e.type] = {
              condition : e.select,
              address : e.address,
              remark: e.remark
            }
      })

      let auditLogDetails = _.cloneDeep(variables)
      delete auditLogDetails['id']
      auditLogDetails.returnee = isDischarged ?
      { /** return devices **/
        returnedBy: formValue.returnedBy,
        returnedDate: formValue.returnedDateAndTime.format('MMM-DD-YYYY hh:mm a:ss'),
        receivedBy: formValue.processedBy,
        receivedDate: formValue.processedDateAndTime.format('MMM-DD-YYYY hh:mm a:ss')
       } :
       { /** replace devices **/
        replacedBy: formValue.replacedBy,
        replacedDate: moment(formValue.replacedDateAndTime).format('MMM-DD-YYYY hh:mm a:ss')
       }
       auditLogDetails = _.mapKeys(auditLogDetails, (value, key) => {
         switch(key) {
           case 'BG':
             return 'blood glucose'
           case 'BP':
             return 'blood pressure'
           case 'HR':
             return 'heart rate'
           case 'HS':
             return 'weight'
           case 'TABLET':
             return 'tablet'
           default:
             return key
         }
       })

     try {
        const res = await this.props.client.mutate({
          mutation: returnDevices,
          variables
        });

        if (isDischarged) {
          createAuditLog({
            action: I18N.get('auditLog.vendor.returnDevices'),
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: res
          })
        }
        else {
          createAuditLog({
            action: I18N.get('auditLog.vendor.replaceDevices'),
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: res
          })
        }

        //get latest devices status
        //this.props.updateEnrolledProgromList(epId, _.get(res,'data.returnDevices.devices'));

        //if the EP is not discharged, it’s replace devices. if it’s discharged, it’s returned devices button.
        if (!isDischarged) {
          const qrcode = _.get(res,'data.returnDevices.qrcode');

          /*** for case after replace devices, then scan qr code, new set of devices given to user, then on the same page, user re-submit replace devices with old device address.
               result, new devices address are shown but read only mode, replaceReturn step becomes 1
          **/
          const initDevices = getPreviousKeyInDevicesInfo( _.get(res,'data.returnDevices'));
          if(!initDevices || initDevices.length === 0 ){
            this.props.resetDevicesStatusTableInfo(); //reset devicesStatus in redux
            this.props.setReplaceDevicesStep(1); //go back step 1
            this.props.setReturnDevicesStep(1); //back to step 1
            resetForm()
          }
          else if(qrcode)
            this.openReplaceDevicesModal(getQRCodeFromString(qrcode));
          else {
            // console.log('qrcode',qrcode);
          }

        }
        else {
          this.props.setReturnDevicesStep(1); //returnDevicesStep  step 1, show 'Return Devices', step 2, show 'Done', step 3, hide the button
          this.props.setReplaceDevicesStep(1); //go back step 1
        }

        //when deviceList detect option.hide=true for enrolledProgram, IHSelect in PatientInfoComponent will not allow user to select this enrolledProgram again
        //because user already submitted enrolledd Program
        this.props.setEnrolledProgromList(this.props.enrolledProgramList.map(ep => {
          if (ep.value === epId) {
            ep.disabled = true;

            const updatedEnrolledProgram = _.get(res, 'data.returnDevices');
            if (updatedEnrolledProgram){
              if(updatedEnrolledProgram.qrcode)
                ep.qrcode = updatedEnrolledProgram.qrcode;
              if(updatedEnrolledProgram.status)
                ep.status = updatedEnrolledProgram.status;
              if(updatedEnrolledProgram.devices)
                ep.devices = updatedEnrolledProgram.devices;
              }
            }
          return ep;
        }));
     } catch(err) {
        this.props.openErrorModal(GQLHelper.formatError(err))
        if (isDischarged) {
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.returnDevices'),
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: err,
            success: false
          })
        }
        else {
          createAuditLog({
            action: I18N.get('auditLog.provider.patient.replaceDevices'),
            patientNRIC,
            patientName,
            details: auditLogDetails,
            request: variables,
            response: err,
            success: false
          })
        }
     } finally {
        setSubmitting(false)
        if (isDischarged) {
          //only reset form if return process; for replace process stick on the edit page can press submit multiple times
          resetForm()
        }
     }
   }

   openReplaceDevicesModal = (qrcode) => {
       const p = getReplaceDeviceModalProps(qrcode, this.props.closeModal)
       this.props.openModal(p.body, p)
   }

   updateDevicesInEnrolledProgramInList = (epId, newDevicesList) => {

     this.props.setEnrolledProgromList(this.props.enrolledProgramList.map(ep => {
       if (ep.value === epId) {

         if (newDevicesList){
             ep.devices = newDevicesList;
         }
        }
       return ep;
     }));
   }
}

const mapStateToProps = (state, ownProps) => ({
  patientInfo : state.Vendor.Patient.Info.patientInfo,
  loading : state.Vendor.Patient.Info.loading,
  enrolledProgramId: state.Vendor.Patient.Info.enrolledProgramId,
  enrolledProgramList: state.Vendor.Patient.Info.enrolledProgramList,
  searchText: state.Vendor.Patient.Info.searchText,
  searchStatus: state.Vendor.Patient.Info.searchStatus,
  replaceOrReturnDevicesStep: state.Vendor.Patient.ReplaceReturnDevices.replaceOrReturnDevicesStep,
  returnDevicesStep:  state.Vendor.Patient.ReplaceReturnDevices.returnDevicesStep,
  devicesStatus: state.Vendor.Patient.ReplaceReturnDevices.devicesStatus,
  fieldsValue: state.Vendor.Patient.ReplaceReturnDevices.fieldsValue
});

export default compose(
  withApollo,
  connect(mapStateToProps,
    { setPatientInfo,
      setLoading,
      setReplaceDevicesStep: commonReplaceReturnDevicesActionsObject.setReplaceDevicesStep,
      initDevicesStatusTableInfo: commonReplaceReturnDevicesActionsObject.initDevicesStatusTableInfo,
      setReturnDevicesStep: commonReplaceReturnDevicesActionsObject.setReturnDevicesStep,
      changeDeviceStatusTableInfo: commonReplaceReturnDevicesActionsObject.changeDeviceStatusTableInfo,
      changeDeviceRemarkTableInfo: commonReplaceReturnDevicesActionsObject.changeDeviceRemarkTableInfo,
      onChangeEnrolledProgram,
      setEnrolledProgromList,
      resetDevicesStatusTableInfo: commonReplaceReturnDevicesActionsObject.resetDevicesStatusTableInfo,
      setSearchText,
      setSearchStatus,
      closeModal,
      openModal,
      //replace return devices form
      handleFormSaved: commonReplaceReturnDevicesFormActionsObject.handleFormSaved,
      resetForm: commonReplaceReturnDevicesFormActionsObject.resetForm,
      setSubmitting: commonReplaceReturnDevicesFormActionsObject.setSubmitting,
      openErrorModal
      //updateEnrolledProgromList
   }
  )
)(PatientContainer);
