import react from 'react'
import {MONTHLY_REPORT_VITAL_MAP} from "../constant";
import {Card} from "antd";
import React from "react";
import '../style/index.scss';

export const VitalCardComponent = (props)=>{
	const { vitalType,monthlyReport } = props;
	const vitalCard = (vitalType,monthlyReport)=>{
		let config = MONTHLY_REPORT_VITAL_MAP[vitalType];
		const { title,resultsAndAverage } = config;
		return <Card title={title(vitalType)} className='monthlyReportCard'>
					{resultsAndAverage(monthlyReport)}
		</Card>;
	}
	return vitalCard(vitalType,monthlyReport);
}
