import {
  React, BaseComponent, ND, _
} from './util';
import PropTypes from 'prop-types';

const Select = ND.Select;
const Option = Select.Option;

/*
 * 2/1/18 - JV - For AntD v2.7.0 or earlier, use <Select multiple={true} />. For AntD v3.0 or later, use <Select mode='multiple' />
 */

const IHMultiSelect = class extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { children: [] };
  }
  componentWillMount() {
    this.addOptions();
  }
  addOptions() {
    let children = [];
    for (let i = 0; i < this.props.option.length; i++) {
      children.push(<Option key={this.props.option[i].value}>{ this.props.option[i].name }</Option>);
    }
    this.setState({ children });
  }
  render() {
    return (
      <Select multiple={true} style={{ width: '100%' }} placeholder="Please Select" defaultValue={this.props.defaultValue} onChange={this.props.onChange}>
        { this.state.children }
      </Select>
    )
  }
};

IHMultiSelect.propTypes = {
  option: PropTypes.array.isRequired,
  defaultValue: PropTypes.array,
  width: PropTypes.string
};

export default IHMultiSelect;