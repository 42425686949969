import { combineReducers } from 'redux'
import Common from 'ProgramEditModule/Careplan/reducers/CommonReducer'
import VitalForm from 'ProgramEditModule/Careplan/reducers/VitalFormReducer'
import VitalList from 'ProgramEditModule/Careplan/reducers/VitalListReducer'
import VitalAlert from 'ProgramEditModule/VitalAlert/reducers'
import Assessment from 'ProgramEditModule/Assessment/reducers'
import Survey from 'ProgramEditModule/Survey/reducers'
// import Education from 'ProgramEditModule/Education/reducers'

const careplan = combineReducers({
  Common,
  VitalForm,
  VitalList,
  VitalAlert,
  Assessment,
  Survey,
  // Education
})

export default careplan
