
export const patientDetail = {
    name : 'This is test detail'
}

export const patientCaregiverList = [
    {
        name : 'This is test patient caregiver detail'
    },
    {
        name : 'This is test patient caregiver 1111'
    },
    {
        name : 'This is test patient caregiver 2222'
    }

]

export const programme = {
    list : [
        {
            name : 'Programme 01',
            value : 'p01'
        },
        {
            name : 'Programme 02',
            value : 'p02',
            disabled : true
        },
        {
            name : 'Programme 03',
            value : 'p03'
        }
    ],
    data : {
        p01 : [
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            },
            {
                id : 'a',
                careTeam : 'Care Team 01',
                doctor : 'Doctor 01',
                manager : 'Nurse 01',
                team : 'Nurse 001',
                programmes : 'Programme 01'
            },
            {
                id : 'b',
                careTeam : 'Care Team 02',
                doctor : 'Doctor 02',
                manager : 'Nurse 02',
                team : 'Nurse 002',
                programmes : ['Programme 01', 'Programme 02']
            }
        ]
    }
}
