import React from 'react';
import { Table, Button } from 'antd';
import { IHSearchTable, IHCheckbox, IHInputNumber, IHButton, _, moment } from 'ihcomponent';
import { goPath } from 'libModule/utils';
import I18N from 'modulesAll/I18N';

const categoryColor = {
	'VISIT DUE': '#6C0000',
	'A1C DUE': '#B20202',
	'VISIT OVERDUE': '#FF5151',
	'A1C OVERDUE': '#FF9999'
};

export default class TempEnrolledPatientsList extends React.Component {
  componentWillMount() {
    this.props.onLoadParams(null, true);
  }

  getTableProps(){
    const { onTableChange, page, loading } = this.props;
    const list = _.get(this.props.list, 'data');

    return {
      title: `MA Working List (${_.get(this.props.list, 'pageInfo.total', 0)})`,
      rowKey: doc => doc.id,
      onChange: (p, f, s) => {
          const newFilter = { ...f};
          const newPage = {...p};
          return onTableChange(newPage, newFilter, s)
      },
      loading,
      dataSource : list,
      onRow(doc){
        return {
          onClick: () => goPath(`/patients/${doc.memberId}/enrolledprogram/default`)
        }
      },
      columns : [
        {
          key: 'name',
          title: 'Name',
          dataIndex: 'name',
        },
        {
          key: 'category',
          title: 'Category',
          dataIndex: 'category',
          render: (t, doc) => <p style={{color:categoryColor[t]}}>{I18N.get(`maWorkingCategory.${t}`)}</p>
        },
        {
          key: 'phone',
          title: 'Phone',
          dataIndex: 'contact',
        },
        {
          key: 'visitWindow',
          title: 'Visit Window',
          dataIndex: 'visitWindow'
        }
      ],
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(this.props.list, 'pageInfo.total', 0),
      },
    }
  }

	render() {
        const noDataElem = (
          <div style={{ height: '150px', paddingTop: '2em' }}>
            <p style={{ fontSize: '18px' }}>There is no data!</p>
          </div>
        );
        return <div style={{ padding:20}}>
                  <IHSearchTable {...this.getTableProps()} noDataElem={noDataElem} />
               </div>;
	}
}
