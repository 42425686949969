import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { capitalizeFirstChar } from '../../helpers/nutritionHelpers';

// to render select with multiple kinds of option
// eg: Select -> Select.OptGroup ->  Select.Option
//     OR     -> Select.Option
const CustomSelectOptions = props => {
  const { options = [], optionRenderer } = props;

  const customRenderer = (option, defaultIndex) => {
    const category = option.category;
    let renderer = optionRenderer({
      category,
      optObject: { 
        optionId: option.refId,
        value: option.value,
      },
      defaultIndex
    });

    if(_.get(option, 'options.length')){  
      // has sub-options
      const groupId = _.get(option, 'refId');
      const groupName = capitalizeFirstChar(option.value); // can't be NULL

      renderer = <Select.OptGroup 
        key={groupId} 
        label={groupName}
      >
        {
          _.map(_.get(option, 'options', []), (sub, defaultIndex) => 
            optionRenderer({
              category,
              groupId,
              groupName,
              optObject: { 
                optionId: sub.refId,
                value: sub.value,
              },
              defaultIndex
            })
          )
        }
      </Select.OptGroup>;
    }

    return renderer;
  }

  return _.map(options, customRenderer);
};

CustomSelectOptions.propTypes = {
  options: PropTypes.arrayOf({
    category: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  optionRenderer: PropTypes.func.isRequired,
}

export default CustomSelectOptions;
