import React, { forwardRef } from 'react';
import { Select, Spin, Tooltip, Icon } from 'antd';

import { sortUserList } from '../helper';

import '../style/assigneeDropdown.styles.scss';

const AssigneeOptionDropdown = (props, ref) => {
  const { isLoading, patient, userList, complexityLevel, missingAssigneeData, ...antdProps } = props;

  const sortedUserList = sortUserList(patient, complexityLevel, isLoading ? [] : userList);
  const optionComponents = _.get(sortedUserList, 'length') ?
    _.map(sortedUserList, user => {
      const { memberId, fullName, providerLanguage, orgAssignedPatientCount, occupancyRate } = user;
      const languages = _.map(providerLanguage, 'description');
      return <Select.Option key={memberId} value={memberId} className='selectableOption' fullname={fullName}>
        <div>
          <div style={{ fontWeight: 600 }}>{fullName}</div>
          <div style={{ opacity: 0.7 }}>
            <span className='userInfo'>Language: {_.join(languages, ', ')};</span>
            <span className='userInfo'>Patients in current clinic: {orgAssignedPatientCount};</span>
            <span className='userInfo'>Occupancy rate: {occupancyRate}%</span>
          </div>
        </div>
      </Select.Option>;
    })
    : [<Select.Option disabled value='noOption' key='noOption'>
      <div>
        {
          isLoading ? <Spin /> :
            <div>
              <span>No available members</span>
              <Tooltip title={`Please check if you are in the clinic's teams`}>
                <Icon type='question-circle' theme='filled' style={{ marginLeft: 8 }} />
              </Tooltip>
            </div>
        }
      </div>
    </Select.Option>];

  optionComponents.push(
    <Select.Option key={'not-necessary'} value={'not-necessary'} className='selectableOption' fullname='Not Necessary'>
      <div>
        <div style={{ fontWeight: 600 }}>Not Necessary</div>
      </div>
    </Select.Option>)

  if (missingAssigneeData) {
    const { id, fullName, renderLabel } = missingAssigneeData;
    const missingLabel = renderLabel(fullName);
    optionComponents.unshift(
      <Select.Option key={id} value={id} className='selectableOption' fullname={missingLabel}>
        {missingLabel}
      </Select.Option>
    );
  }

  return <Select
    ref={ref}
    {...antdProps}
    loading={isLoading}
    className={`assigneeDropdownInput ${isLoading ? 'loadingAssigneeOptions' : ''}`}
    dropdownClassName='assigneeDropdown'
    dropdownMatchSelectWidth={false}
    optionLabelProp='fullname'
  >
    {[
      <Select.Option disabled value='header' key='header' className='optionHeader'>
        <div>
          <span>From the highest match to the lowest</span>
          <Tooltip
            overlayClassName='assigneeHeaderTip'
            title={
              <div>
                <div>
                  The match rate is based on the 3 factors:
                </div>
                <ol>
                  <li>If the care team member's language matches with the patient's spoken language/App language;</li>
                  <li>The care team member with the most patients in the current clinic;</li>
                  <li>Lower occupancy rate;</li>
                </ol>
                <div>Occupancy rate = the number of patients assigned to this care team member in all clinics / total capacity.</div>
              </div>
            }
          >
            <Icon type='info-circle' theme='filled' style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      </Select.Option>,
      ...optionComponents
    ]}
  </Select>;
};

export default forwardRef(AssigneeOptionDropdown);