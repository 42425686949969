import React from 'react';
import { Redirect, Route, IndexRoute } from 'react-router';

// Lib
import I18N from 'modulesAll/I18N';
import { requireAuth, setLocation, enterIndex, verifyRoleAccess, verifyRouteAccess } from './lib/accountsClient';
import { SIGN_OFF_ROLES, IHEALTH_MEMBER_ROLES } from './modules/utils/constants/role';
import Store from 'libModule/reduxStore';

// Modules
import MainLayout from './modules/layout/containers/MainLayoutContainer';
import PreLoginLayout from './modules/layout/containers/PreLoginLayoutContainer';

import { setBreadCrumb } from 'modulesAll/layout/actions/Nav';

import NotFound from './modules/layout/components/NotFoundComponent';
import NotAuthorized from 'modulesAll/common/components/NotAuthorized';
import Dashboard from './modules/dashboard/main/containers/main';
import {
  ProgramNew,
  ProgramEdit,
  ProgramList,
  ProgramListAdmin,
  ProgramIncomplete
} from './modules/program/Common/containers';

import PatientDetail from 'modulesAll/patient/profileDetail/containers/main';
import PatientEdit from 'modulesAll/patient/profileEdit/containers';
import PatientTimeSpent from 'modulesAll/patient/TimeSpent/containers/TimeSpentContainer';
import PatientPage from './modules/patient/main/containers/main';
import PatientSettings from './modules/patient/Settings/containers/settingsContainer';
import PatientProgramSummary from './modules/patient/main/containers/ProgramSummaryMain';
import ProgramSummary from 'modulesAll/program/Summary/containers/main';
import PatientReimport from 'modulesAll/patient/profileReimport/containers/main';

import PatientListPage from './modules/patientList/main/containers/main';
// import PatientListPForMaAndMd from './modules/patientList/main/containers/mainForMdAndMd';

import PatientRegisterNewImportPatientInfo from './modules/patientList/registerNew/containers/mainImportPatientInfo';
import PatientRegisterNewManualInput from './modules/patientList/registerNew/containers/mainManualInput';
import { AddLabResults } from './modules/patient/main/containers/AddLabResults';

import LoginPage from './modules/auth/containers/LoginContainer';
// import Onboard from './modules/onboard/containers/SimpleOnboardContainer';
import Onboard from './modules/onboard/containers/OnboardVerificationContainer';
import OnboardPassword from './modules/onboard/containers/OnboardPasswordContainer';
import ResetPassword from './modules/auth/containers/ResetPasswordContainer';
import ChangePwContainer from './modules/patient/main/containers/ChangePwContainer';
import NewPwContainer from './modules/patient/main/containers/NewPwContainer';
// import DeleteUser from './modules/auth/containers/DeleteUserContainer';
import intermediaLogOutComponent from './modules/auth/components/intermediaLogOutComponent';
import PatientTaskList from './modules/patientView/patientTaskList/components/PatientTaskListComponent';
import VitalList from './modules/patientView/vitalList/containers/main';
import EmergencyContacts from './modules/patient/emergencyContacts/components/emergencyContactsComponent';
import MyPrograms from './modules/patientView/myPrograms/containers/MyProgramsContainer';
import MyProgramSummary from './modules/patientView/myPrograms/components/Summary';
// provider
import {
  ProviderNew,
  ProviderEdit,
  ProviderList,
  ProviderDetail
} from './modules/provider';

// task
import ProviderTaskList from './modules/providerTask/TaskList/containers/TaskListContainer';

// Inventory
import InventoryWrapper from './modules/inventory/container/InventoryWrapper';

// tagged patients
import TaggedPatientsList from './modules/providerTaggedPatients/containers/TaggedPatientsContainer';

// inactive patients
import InactivePatientsList from './modules/patientList/inactivePatients/containers/InactivePatientsContainer';

// alerts
import ProviderAlertList from './modules/alerts/ProviderAlerts/containers/ProviderAlertListContainer';
import ProviderAlertResolve from './modules/smartAlert/container/ProviderAlertResolveContainer';
// admin assessment & survey
import AssessmentList from './modules/assessment/main/containers/ListContainer';
import AdminAssessmentCreate from './modules/assessment/edit/containers/CreateContainer';
import AdminAssessmentEdit from './modules/assessment/edit/containers/EditContainer';
import AdminPreviewAssessment from './modules/assessment/preview/components/PreviewComponent';

import SurveyList from './modules/survey/main/containers/ListContainer';
import AdminSurveyCreate from './modules/survey/edit/containers/CreateContainer';
import AdminSurveyEdit from './modules/survey/edit/containers/EditContainer';
import AdminPreviewSurvey from './modules/survey/preview/components/PreviewComponent';

// nurse assessment & survey
import AssessmentListNurse from './modules/assessment/main/containers/NurseListContainer';
import SurveyListNurse from './modules/survey/main/containers/NurseListContainer';
import NursePreviewSurvey from './modules/survey/preview/components/NursePreviewComponent';
import NursePreviewAssessment from './modules/assessment/preview/components/NursePreviewComponent';

// vendor
import PatientVendor from './modules/vendor/patient/containers';

// my assessment and survey
import myAssessmentAndSurveyList from './modules/patientView/myAssessmentAndSurvey/main/containers/ListContainer';
import PatientNewAssessment from './modules/patientView/myAssessmentAndSurvey/edit/containers/NewContainer';
import PatientUpdateAssessment from './modules/patientView/myAssessmentAndSurvey/edit/containers/UpdateContainer';
import PatientPreviewAssessment from './modules/patientView/myAssessmentAndSurvey/preview/components/PreviewComponent';

import CareTeamListPage from 'modulesAll/careteam/list/containers/main';
import CareTeamNew from 'modulesAll/careteam/createCareTeam/containers/NewCareTeamContainer';
import CareTeamEdit from 'modulesAll/careteam/editCareTeam/containers';

//Broadcasts
import BroadcastList from './modules/broadcasts/main/containers/ListContainer';
import BroadcastNew from './modules/broadcasts/create/containers/CreateContainer';
import BroadcastEdit from './modules/broadcasts/edit/containers';

// temp enrolled patients
import TempEnrolledPatients from 'modulesAll/tempEnrolledPatients/containers/TempEnrolledPatientsListContainer';

// SuperAdmin
import {
  SuperAdmin,
  TroubleshootingList,
  TroubleshootingOverview,
  AppAnalytics,
  AuditTrail,
  Organization,
  OrganizationCreate,
  OrganizationEdit,
  Providers,
  ProviderEditRole,
  SystemDynamicConfigs
} from './modules/superAdmin';

// chat
import ChatWindow from './modules/chat/containers/ChatContainer';
import FoodLogWindow from './modules/foodLog/containers/FoodLogContainer';

//appointments
import AppointmentCalenderWrapperContainer from './modules/visit/containers/AppointmentCalenderWrapperContainer';

//smart alert
import SmartAlertListContainer from './modules/smartAlert/container/SmartAlertListContainer';
import ProviderSmartAlertContainerWrapper from "./modules/smartAlert/container/ProviderSmartAlertContainerWrapper";
//upload
import UploadImgComponent from './modules/upload/components/UploadImgComponent';

//task list
import TaskAssignmentListContainer from './modules/taskAssignment/containers/TaskAssignmentListContainerWrapper';

//visit work list
import WorkListContainer from './modules/visitWorkList/container/workListContainer';
import ScheduleContainer from './modules/MAPatient/container/ScheduledVisitsContainerWrapper.js'

//videoChat
import VideoChat from './modules/VideoChat/container/VideoChatWrapperContainer';
import MAPatientProfile from './modules/MAPatient/container/MAPatientProfileContainer';

import AdminDashboard from './modules/adminDashboard/AdminDashboardComponent';

//agora video

import AgoraVideo from './modules/VideoChat/container/AgoraVideoContainer';
import AgoraPatientVideo from './modules/VideoChat/container/AgoraPatientVideoContainer';
import AgoraPatientLeft from './modules/VideoChat/component/LeftAgoraVideoForPatientComponent';

//Data Insights
import DataInsights from './modules/quickSight/containers/main';

//Reports
import Reports from './modules/reports/containers/main';

//remote enrollment
import RemoteEnrollmentContainer from './modules/remoteEnrollment/container/RemoteEnrollmentContainerWrapper';

//newAdmin
import AdminHomePageWrapper from './modules/newAdmin/container/AdminHomePageWrapper';
import AreaContainer from "./modules/newAdmin/Area/container/AreaContainer";
import SingleAreaContainer from './modules/newAdmin/Area/container/SingleAreaContainer';

import ClinicManagementContainer from './modules/newAdmin/Clinic/containers/ClinicManagementContainer';
import UserContainer from './modules/newAdmin/User/Container/UserListcontainer';
import UserDetailPage from './modules/newAdmin/User/Container/userDetailContainer';
import ConsentFormContainer from "./modules/newAdmin/ConsentForm/container/ConsentFormContainer";
import EditClinicContainer from './modules/newAdmin/Clinic/containers/EditClinicContainer';
import ABTestingConfigContainer from './modules/newAdmin/superAdmin/container/ABTestingConfigContainer';

//work station
import WorkStationMainContainer from "./modules/workStation/container/WorkStationMainContainer";
import TaskResolveContainer from "./modules/workStation/container/TaskResolveContainer";
import SignOffResolveContainer from "./modules/workStation/container/SignOffResolveContainer";

import Translate from './modules/translation/container/index';
import MNTReferralContainer from './modules/mntReferral/container/MNTReferralContainer';
import MNTReferralFormComponent from './modules/mntReferral/component/MNTReferralFormComponent';
import ReferralAndEnrollContainer from './modules/referAndEnroll/container/index';
import Test from './modules/referAndEnroll/container/testpaeg';
import { getUserRole } from './lib/utils';
import AthenaLogin from './modules/athena/AthenaLogin';
import RouteTransitionComponent from './RouteTransitionComponent';
import  MonthlyReportContainer from './modules/monthlyReport/container/MonthlyReportContainer';
//cross clinic
import CrossClinicContainer from './modules/crossClinic/container/CrossClinicContainer';

// rpm patient list
import ReviewPatientPageContainer from './modules/rpmPatientReview/containers/ReviewPatientPageContainer';

function setNavVal(value) {
  return (nextState, replace) => {
    try {
      Store.dispatch(setBreadCrumb(value));
    } catch (error) {
      // ignore
    }
  };
}



// const dashboardRoutBasedOnRole = ()=>{
//    const roleMap = {
//      'CMA':'PROVIDER:Clinic Medical Assistant',
//      'DOCTOR':'PROVIDER:Doctor'
//    };

//    if(currentUserRole==roleMap['CMA']||currentUserRole==roleMap['DOCTOR']) {
//       return VideoChat;
//    }
//    return Dashboard;
// };

const accessRole = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN', // Super Admin can have access to Admin, not vice versa
}

const verifyAccess = (role, routeReplace, value) => {
  verifyRoleAccess(role, routeReplace, () => Store.dispatch(setBreadCrumb(value)));
};

const routes = (
  <Route>
    <Route component={PreLoginLayout}>
      <Route path='/onboard' components={{ main: Onboard }} />
      <Route path='/onboard-password' components={{ main: OnboardPassword }} />
      <Route path='/login' components={{ main: LoginPage }} />
      <Route path='/reset-password' components={{ main: ResetPassword }} />
      <Route path='/logout' components={{ main: intermediaLogOutComponent }} />
      <Route path='AgoraVideo/provider/:agoraAppId/:agoraChannelName/:agoraToken/:agoraUid/:visitId' components={{main: AgoraVideo }} />
      <Route path='AgoraVideo/pt/:code' components={{ main:AgoraPatientVideo }} />
      <Route path='agoravideo/bye' components={{main:AgoraPatientLeft}} />
      <Route path="/athena" components={{main: AthenaLogin}} />
    </Route>

    <Route path="/" component={MainLayout} onEnter={requireAuth}>
      <IndexRoute components={Dashboard} onEnter={enterIndex} />

      /**************************** dashboard ****************************/
      <Route path="dashboard" components={Dashboard} triggerRouter={true} onEnter={setNavVal('Dashboard')} />
      /**************************** admin assessment & survey ****************************/
      <Route path="assessments" components={AssessmentList} onEnter={setNavVal(I18N.get('keywords.Assessments'))} />
      <Route path="create_assessment" components={AdminAssessmentCreate} onEnter={setNavVal(`${I18N.get('keywords.Assessments')} / New`)} />
      <Route path="edit_assessment/:assessmentId" components={AdminAssessmentEdit} />
      <Route path="admin_preview_assessment/:assessmentId" components={AdminPreviewAssessment} />

      <Route path="surveys" components={SurveyList} onEnter={setNavVal('Surveys')} />
      <Route path="create_survey" components={AdminSurveyCreate} onEnter={setNavVal('Surveys / New')} />
      <Route path="edit_survey/:surveyId" components={AdminSurveyEdit} />
      <Route path="admin_preview_survey/:surveyId" components={AdminPreviewSurvey} />

      /**************************** patient assessment & survey ****************************/
      <Route path="assessments_surveys" components={myAssessmentAndSurveyList} onEnter={setNavVal(`${I18N.get('keywords.Assessments')} & Surveys`)} />
      <Route path="create_assessment_filled/:assessmentId/:refId" components={PatientNewAssessment} />
      <Route path="update_assessment_filled/:assessmentFilledId" components={PatientUpdateAssessment} />
      <Route path="preview_assessment_filled/:assessmentFilledId" components={PatientPreviewAssessment} />
      <Route path="create_survey_filled/:assessmentId/:refId" components={PatientNewAssessment} />
      <Route path="update_survey_filled/:assessmentFilledId" components={PatientUpdateAssessment} />
      <Route path="preview_survey_filled/:assessmentFilledId" components={PatientPreviewAssessment} />

      /**************************** nurse assessment & survey ****************************/
      <Route path="nurse_assessments" components={AssessmentListNurse} onEnter={setNavVal(I18N.get('keywords.Assessments'))} />
      <Route path="nurse_surveys" components={SurveyListNurse} onEnter={setNavVal('Surveys')} />
      <Route path="nurse_preview_assessment/:assessmentId" components={NursePreviewAssessment} />
      <Route path="nurse_preview_survey/:surveyId" components={NursePreviewSurvey} />

      /**************************** programmes ****************************/
      <Route path='programmes_admin/:id/edit' components={ProgramEdit} />
      <Route
        path='programmes_admin/:id/edit-incomplete'
        components={ProgramIncomplete}
      />
      <Route
        path='programmes'
        components={ProgramList}
        onEnter={setNavVal(I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes_admin'
        components={ProgramListAdmin}
        onEnter={setNavVal(I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes/new'
        components={ProgramNew}
        onEnter={setNavVal(`${I18N.get('keywords.Programs')} / New`)}
      />
      /**************************** patients ****************************/
      <Route path="/patients/:patientId/enrolledprogram/:enrolledProgramId/past" components={PatientPage} triggerRouter={true} />
      <Route path="patients/:id/enrolledprogram/:enrolledProgramId" components={(props) => (
        <RouteTransitionComponent
          {...props}
          getTransitionPath={(params) => {
            const {id, enrolledProgramId = '' } = params || {};
            const userRole = getUserRole();
            const redirectPath = IHEALTH_MEMBER_ROLES.includes(userRole)
              ? `workstation/${id}/single/${enrolledProgramId}`
              : `provideralert/${id}/single/${enrolledProgramId}`;
            return redirectPath;
          }}
        />
      )}/>
      <Route path="patients/profile/:patientId" components={PatientDetail} />
      <Route path="patientForMA/:patientId" components={MAPatientProfile} />
      <Route path="patients/profile/:patientId/edit" components={PatientEdit} />
      <Route path="providers/profile/:providerId" components={ProviderDetail} />
      <Route path="providers/profile/:providerId/edit" components={ProviderEdit} />
      <Route path="programmes/:programId" components={ProgramSummary} />
      <Route path="myprogrammes/:programId" components={MyProgramSummary} />
      <Route path='patients/profile/:patientId/reimport' components={PatientReimport} />
      <Route path="patients/:patientId/change-password" components={props => <ChangePwContainer {...props}/>} />
      <Route path='patients/:patientId/new-password' components={NewPwContainer} />
      <Route path="patients/:patientId/addlabresults" components={AddLabResults} triggerRouter={true} />
      <Route path='patients/:patientId/timespent' components={PatientTimeSpent} triggerRouter={true} />
      /**************************** vendors ****************************/
      <Route
        path='patient_vendor'
        components={PatientVendor}
        onEnter={setNavVal('Patients')}
      />
      <Route
        path='patients'
        components={PatientListPage}
        onLeave={PatientListPage.onLeave}
        onEnter={setNavVal('Patients')}
      />
      {/* <Route path='patientsForMA' components={PatientListPage} onLeave={PatientListPage.onLeave} onEnter={setNavVal('patientsForMA')} /> */}
      <Redirect
        from='patients/:patientId'
        to='patients/:patientId/enrolledprogram/default'
      />
      <Route
        path='patients/register/import_patient_info'
        components={PatientRegisterNewImportPatientInfo}
        onEnter={setNavVal('Patients / New / Import Patient')}
      />
      <Route
        path='patients/register/manual_input'
        components={PatientRegisterNewManualInput}
        onEnter={setNavVal('Patients / New / Manual Input')}
      />
      <Route
        path='patients/program/enroll'
        components={PatientProgramSummary}
        onEnter={setLocation}
      />
      <Route path='patient/settings' components={PatientSettings} />
      // provider
      <Route
        path='providers/new'
        components={ProviderNew}
        onEnter={setNavVal('Providers / New')}
      />
      <Route
        path='providers'
        components={ProviderList}
        onEnter={setNavVal('Providers')}
      />
      // task
      <Route
        path='tasks'
        components={ProviderTaskList}
        onEnter={setNavVal('Tasks')}
      />
      <Route
        path='alerts'
        components={ProviderAlertList}
        onEnter={setNavVal('Alerts')}
      />
      <Route path="providerSettings/change-password" components={props => <ChangePwContainer {...props}/>} />
      // inventory
      <Route
        path='inventory'
        components={InventoryWrapper}
        onEnter={setNavVal('Inventory')}
      />
      // tagged patients
      <Route
        path='tagged_patients'
        components={TaggedPatientsList}
        onEnter={setNavVal('Tagged Patients')}
      />
      // inactive patients
      <Route
        path='inactive_patients(/:page)'
        components={InactivePatientsList}
        onEnter={setNavVal('Patients')}
      />
      // temp enrolled patients
      <Route
        path='tempenrolledpatients'
        components={props => <SmartAlertListContainer {...props} />}
        onEnter={setNavVal('Temp Enrolled Patients')}
      />
      // chat
      <Route
        path='messages'
        components={props => <ChatWindow {...props} shouldGetUserInfo />}
        onEnter={setNavVal('Messages')}
      />
      <Route
        path='foodLog'
        components={FoodLogWindow}
        onEnter={setNavVal('Food Diary')}
      />
      <Route
        path='mytasks'
        components={PatientTaskList}
        onEnter={setNavVal('My Tasks')}
      />
      <Route
        path='myvitals'
        components={VitalList}
        onEnter={setNavVal('My Vitals')}
      />
      <Route
        path='emergency_contact'
        components={EmergencyContacts}
        onEnter={setNavVal('My Emergency Contacts')}
      />
      <Route
        path='myprogrammes'
        components={MyPrograms}
        onEnter={setNavVal(`My ${I18N.get('keywords.Programs')}`)}
      />
      <Route
        path='careteams'
        components={CareTeamListPage}
        onEnter={setNavVal('Care Teams')}
      />
      <Route
        path='careteams/new'
        components={CareTeamNew}
        onEnter={setNavVal('Care Teams / New')}
      />
      <Route
        path='dataInsights'
        components={DataInsights}
        onEnter={setNavVal('Data Insights')}
      />
      <Route
        path='reports'
        components={Reports}
        onEnter={setNavVal('Reports')}
      />
      <Route
        path='rpmPatientList'
        components={ReviewPatientPageContainer}
        onEnter={setNavVal('RPM Patient List')}
      />
      <Route path='careteams/edit/:careteamId' components={CareTeamEdit} />
      //smart alert
      <Route path='smartalert' components={props => <SmartAlertListContainer {...props} />} />
      <Route path='provideralert' components={ProviderSmartAlertContainerWrapper}/>
      <Route path='provideralert/:id' components={ProviderAlertResolve}/>
      <Route path='provideralert/:id/single' isSingle components={ProviderAlertResolve}/>
      <Route path='provideralert/:id/single/:enrolledProgramId' isSingle components={ProviderAlertResolve}/>
      // New Password
      <Route path='setuserpassword/:patientId' components={props => <ChangePwContainer {...props}/>} />
      /**************************** Broadcasts ****************************/
      <Route
        path='broadcasts'
        components={BroadcastList}
        onEnter={setNavVal('Broadcasts')}
      />
      <Route
        path='broadcasts/new'
        components={BroadcastNew}
        onEnter={setNavVal('Broadcasts / New')}
      />
      <Route
        path='broadcasts/edit/:broadcastId'
        components={BroadcastEdit}
        onEnter={setNavVal('Broadcasts / Edit')}
      />
      <Route
        path='audit_trails'
        components={SuperAdmin}
        onEnter={setNavVal('Audit Trail')}
      />
      <Route path='TaskAssignments' components={TaskAssignmentListContainer} />
      <Route
        path='appointments/reschedule/:visitId'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      <Route
        path='appointments/create/:patientId/:fullName(/:birthday)'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      <Route
        path='AgoraVideo'
        components={AgoraVideo}
      />
      <Route
        path='appointments'
        components={AppointmentCalenderWrapperContainer}
        onEnter={setNavVal('Appointments')}
      />
      /************************ Super admin *******************/
      <Route
        path='superadmin/audittrail'
        components={AuditTrail}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Audit Trail - Overview')}
      />
      <Route
        path='superadmin/troubleshooting'
        components={TroubleshootingList}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'User Troubleshooting - List')}
      />
      <Route
        path='superadmin/troubleshooting/:userId'
        components={TroubleshootingOverview}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'User Troubleshooting - Details')}
      />
      <Route
        path='superadmin/appanalytics'
        components={AppAnalytics}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'App Analytics')}
      />
      <Route
        path='superadmin/organizations'
        components={Organization}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Organizations - List')}
      />
      <Route
        path='superadmin/organizations/Create'
        components={OrganizationCreate}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Organizations - Create')}
      />
      <Route
        path='superadmin/organizations/:organizationId/Edit'
        components={OrganizationEdit}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Organizations - Edit')}
      />
      <Route
        path='superadmin/providers'
        components={Providers}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Provider - List')}
      />
      <Route
        path='superadmin/providers/:providerId/setrole'
        components={ProviderEditRole}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Provider - EditRole')}
      />
      <Route
        path='superadmin/systemdynamicconfigs'
        components={SystemDynamicConfigs}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'SystemDynamicConfigs - List')}
      />
      <Route
        path='superadmin/areas'
        components={AreaContainer}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Areas')}
      />
      <Route
        path='superadmin/users'
        components={props => <UserContainer {...props}/>}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Users - List')}
      />
      <Route
        path='superadmin/users/:userId'
        components={props => <UserDetailPage {...props}/>}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Users - Details')}
      />
      <Route path='superadmin/abtestingconfig' components={ABTestingConfigContainer}/>
      <Route
        path='superadmin/consentform'
        component={ConsentFormContainer}
        onEnter={(__, r) => verifyAccess(accessRole.SUPER_ADMIN, r, 'Consent Form')}
      />
      /************************ Admin/AreaManager/SiteManager *******************/
      <Route
        path='organizations'
        components={props => <AdminHomePageWrapper {...props}/>}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Organizations')}
      />
      <Route
        path='organizations/:orgId'
        component={ClinicManagementContainer}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Clinic Management')}
      />
      <Route
        path='clinic/:orgId'
        component={ClinicManagementContainer}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Clinic Management')}
      />
      <Route path='clinic/:orgId/edit' components={EditClinicContainer}/>
      <Route
        path='users'
        components={props => <UserContainer {...props}/>}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Users')}
      />
      <Route
        path='users/:userId'
        components={props => <UserDetailPage {...props}/>}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Users - Details')}
      />
      {/* <Route
        path='consentform'
        component={ConsentFormContainer}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Consent Form')}
      /> */}
      <Route
        path='area/:orgId'
        component={SingleAreaContainer}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Area')}
      />
      /**************************** programmes ****************************/
      <Route
        path='programmes_admin/:id/edit'
        components={ProgramEdit}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Program / Edit')}
      />
      <Route
        path='programmes_admin/:id/edit-incomplete'
        components={ProgramIncomplete}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, 'Program / Edit Incomplete')}
      />
      <Route
        path='programmes'
        components={ProgramList}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes_admin'
        components={ProgramListAdmin}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, I18N.get('keywords.Programs'))}
      />
      <Route
        path='programmes/new'
        components={ProgramNew}
        onEnter={(__, r) => verifyAccess(accessRole.ADMIN, r, `${I18N.get('keywords.Programs')} / New`)}
      />
      <Route components={Translate} path='translate'/>
      <Route components={MNTReferralContainer} path='mntReferral'/>
      <Route component={ReferralAndEnrollContainer} path='referandenroll'/>
      <Route component={Test} path='referralTest' />
      <Route path='remoteEnrollment' components={RemoteEnrollmentContainer}/>
      <Route path='workstation' components={WorkStationMainContainer}/>
      <Route path='workstation/:id/sign-off' components={props => {
        const { id } = _.get(props, 'routeParams') || {};
        return <SignOffResolveContainer key={id} {...props} />
      }} onEnter={(__, r) => verifyRouteAccess(SIGN_OFF_ROLES, r)}/>
      <Route path='workstation/:id' components={TaskResolveContainer}/>
      <Route path='crossClinic' components={CrossClinicContainer}/>
      <Route path='workstation/:id/single' isSingle components={TaskResolveContainer}/>
      <Route path='workstation/:id/single/:enrolledProgramId' isSingle components={TaskResolveContainer}/>
      <Route path='visitWorList' components={WorkListContainer} />
      <Route path="not-authorized" components={NotAuthorized} onEnter={setNavVal('Not authorized')} />
      <Route path='upload' components={UploadImgComponent}/>
      <Route path='videochat' components={VideoChat} />
      <Route path='schedule' component={ScheduleContainer}/>
      <Route path='MRE' component={MonthlyReportContainer}/>
      <Route path="dashboards" components={AdminDashboard} />
      <Route path="*" components={NotFound} />

    </Route>
  </Route>
);

export default routes;
