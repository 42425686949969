import React from "react";
import moment from "moment";
import {Row, Col, Icon, Checkbox, Input} from 'antd'
import API from "../API";
import helper from '../helper/index'
import callphone from '../helper/callphone';
import NumberFormat from "react-number-format";
import { FormatHelper, RequestCache } from 'libModule/utils'
import I18N from "../../I18N";
import { getProviderName } from "../../../lib/utils";

const renderProviderAndConfirmedBy = ()=>{
    return [
        {
            title: I18N.get('provider.label'),
            path:'member.profile.doctor',
            span:'4',
            render: r => getProviderName(r)
        },
        {
            title:'Caller Confirmed',
            path:'confirmedBy.name',
            span:'4',
            render:(r,doc)=>r
        }
    ]
}

const enrollmentAndProvider = ()=>{
    return [
        {
            title:'Enrolled Date',
            path:'member.profile.programCategories',
            span:'4',
            render:(r,doc)=>{
                const categories = _.filter(r,o => o.enrolled);
                const enrolledDate = categories.reduce((prev, curr) => prev < curr.enrolledDate ? prev : curr.enrolledDate, Infinity);
                if (!enrolledDate || enrolledDate === Infinity) {
                    return 'N/A';
                } else {
                    return moment(enrolledDate).format('MM/DD/YYYY')
                }
            }
        },
        {
            title:'Caller Confirmed',
            path:'confirmedBy.name',
            span:'4',
            render:(r,doc)=>r
        }
    ]
}
const renderPhoneProviderCallerLastCall = (localThis)=>{
    return [
        {
            title:'Phone#',
            path:'member.phone',
            render:(doc,row)=> _.map(doc,(phone,i)=> {
                    const prefix = _.first(_.get(phone,'type'));
                    return<div key={i}>
                                    <span>{`${prefix}: `}
                                        <NumberFormat className={'keyclick'}
                                                      format={`+# (###) ###-####`}
                                                      value={FormatHelper.phone(phone)}
                                                      displayType={'text'} onClick={()=>callphone(localThis,phone.number,row)}/>
                                    </span>
                    </div>
                }
            )
                // _.map(r,(d,i)=><p key={i}>{`#${d.number}`}</p>)
        },
        {
            title: I18N.get('provider.label'),
            path:'member.profile.doctor',
            render:r=> getProviderName(r)
        },
        {
            title:'Caller',
            path:'callers',
            render:(r,path,doc)=>r.length!=0 ? _.map(r,({ name })=><p key={name}>{name}</p>) :'--'
        },
        {
            title:'Last Call Made',
            path:'callHistories',
            render:(r)=>{
                const sortedArray = _.sortBy(r,(o)=>-o.callDate);
                const latestCall = _.first(sortedArray);
                return latestCall ? moment(latestCall.callDate).format('MM/DD/YYYY') : '--';
            }
        }
    ]
}

const nextVisitCol = ()=>{
    return [
        {
            title:'Latest Visit',
            path:'nextVisitTime',
            render:(r,doc)=> moment(_.get(doc,'nextVisit.appointmentAt')).format('MM/DD HH:mm')
        }
    ]
}
const renderTaskColumns = (tasks,localThis,doc)=>{
    return _.map(tasks,({ key,title,extraContent,hideCheckBox })=>{
            const value = _.get(doc,`${key}.value`);
            const name = _.get(doc,`${key}.updatedBy.name`);
            return <Col key={key} span={24}>
                    <Row style={{ width: 'inherit'}}>
                        <Col span={12}>
                        <span>{title}</span>{
                                <Checkbox defaultChecked={value}
                                          disabled={hideCheckBox}
                                          onChange={(e) =>
                                              API.checkRemoteEnrollmentTaskHandler({
                                                  memberId: _.get(doc, 'member.id'),
                                                  [key]: e.target.checked
                                              })
                                                  .then(res => {
                                                          if (typeof localThis.props.refetchRemoteEnrollment == 'function')
                                                              localThis.props.refetchRemoteEnrollment();
                                                      }
                                                  )
                                          }
                                />
                        }
                        </Col>
                        <Col span={12}>
                            { value&&<p className={'patientName'}>{ name }</p> }
                        </Col>
                        <Col span={24}>
                            { extraContent ? extraContent(localThis,doc,'trackingInfoInModal') : ''}
                        </Col>
                    </Row>
            </Col>
        })
};
const RMTasksList = (localThis)=>{
    const  generalTasksList = [
        {
            key: 'nutritionAssessment',
            title: 'Nutrition Assessment',
            hideCheckBox: true
        },
        {
            key: 'techOnBoard',
            title: 'Tech Onboard',
            hideCheckBox: true
        },
        {
            key: 'deviceDelivered',
            title: 'Device Delivered',
            extraContent:(localThis,doc,className)=> helper.shippingInfoContent(localThis,doc,className)
        }
    ]

    const collapsedTaskList = [{
            key:'carePlanFiled',
            title:'Care Plan Filed',
        },
        {
            key:'consentFiled',
            title: 'Consent Form Filed'
        },
        {
            key:'billingCodeAttached',
            title:'Billing Code Attached'
        }
    ]
    const rows = [
        {
            key: 'firstRow',
            path:'techOnBoard',
            span:'8',
            render:(r,doc)=><Row className={'EPTaskInModal'}>
                {renderTaskColumns(generalTasksList,localThis,doc)}
            </Row>
        },
        {
            key: 'secondRow',
            path:'techOnBoard',
            span:'8',
            render:(r,doc)=><Row className={'EPTaskInModal'}>
                {renderTaskColumns(collapsedTaskList,localThis,doc)}
            </Row>
        }

    ]

    return rows;
}


const singleREMap =(tabSelected='UNENROLLED',localThis)=>({
    'INIT':[
        ...nextVisitCol(),
        ...renderPhoneProviderCallerLastCall(localThis)
    ],
    'TBD':[
        ...nextVisitCol(),
        ...renderPhoneProviderCallerLastCall(localThis)
    ],
    'COMPLETED':[
        ...enrollmentAndProvider()
    ],
    'EXCLUDED':[
        ...renderPhoneProviderCallerLastCall(localThis)
    ],
    'CONFIRMED':[
        ...renderProviderAndConfirmedBy(),
        ...RMTasksList(localThis)
    ],
    'UNENROLLED':[
        ...renderPhoneProviderCallerLastCall(localThis)
    ]
}[tabSelected]);

export default singleREMap;