import React from 'react'
import BaseComponent from '../BaseComponent'
import {TimePicker} from 'antd'
import _ from 'lodash'

const Component = class extends BaseComponent{

    render(){
        let p = _.omit(this.props, [])

        return (
            <TimePicker placeholder="Select Time" {...p} className="IH-TimePicker" />
        )

    }
}

Component.propTypes = {

}

export default Component
