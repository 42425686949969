import gql from 'graphql-tag'
import {UserMiniWithPhone} from 'graphqlModule/schema/User'

export default gql `
    mutation changeUserPhone (
      $id: EID!,
      $phone: [InputPhone]!,
    ) {
      changeUserPhone (
        id: $id,
        phone: $phone,
      ) {
        ${UserMiniWithPhone}
      }
    }
`
