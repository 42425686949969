import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Card, Row, message } from 'antd';
import ReferAndEnrollForm from './ReferAndEnrollForm';
import I18N from '../../I18N';
import { 
  wantToJoinProgram, deviceAccessability, exclusionReason, exclusionReasonOther, 
  smartPhoneSkills as smartPhoneSkillsField, anotherCCMProvider, PROGRAM_ELIGIBILITY_KEYS,
} from '../fields/ProgramEligibility';
import FormRenderer from './FormRenderer';
import Overlay from './Overlay';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { editUserMutation, upsertPatientReferralMutation } from '../API/mutation';
import { 
  getBinaryRadioValue, getSmartphoneOrgPermissions, getValue, getBPEligibility, getAnotherCCMProviderOrgPermissions, 
  getRadioValue, onlyHasHLD, getDisabledProgramKeys, getVitalsAndPrograms, checkBpAndRpmOnly, getDisabledAndRecommendations,
  getPatientConditions
} from '../helper';
import { getOrgProgramParticipation } from '../../../lib/utils/common';
import {
  getAnotherCCMFieldValue, getConditionsValue, getDeviceAccessibilityValue,
  getExclusionReason, getExclusionOtherReason, getIcdCodesValue,
  getProgramsValue, getVitalsValue, getWouldJoinValue, getTechLevel , getBillableIcdCodesValue,
} from '../helper/initialValueHelper';
import { EXCLUSION_REASONS_MAP } from '../constant';
import { SMARTPHONE_SKILL_ENUM, DIAGNOSIS_SOURCE } from '../constant/programEligibility';
import VitalsAndProgramsComponent from './VitalsAndProgramsComponent';
import PostAPI from '../../postIt/API/index';
import ReferralCodeConditionContainer from '../container/ReferralCodeConditionContainer';
import { getCurrentOrg } from '../../workStation/helper/index';
import { GQLHelper } from '../../../lib/utils';
import renderIneligibleOverlay from '../helper/renderIneligibleOverlay';

const EnrollmentProgramEligibility = ({ patientReferral, user, form, onSubmitDone, userRole, closeModal, disabled, openErrorModal, goNextStep }) => {
  const { validateFields, getFieldsValue } = form;
  const fieldsValues = getFieldsValue();
  const initialValues = useMemo(() => {
    const diagnosisSource = getValue(patientReferral.diagnosisSource) || DIAGNOSIS_SOURCE.CONDITION;
    const conditions = getConditionsValue(patientReferral,user) || [];
    const icdCodesInitialValue = getIcdCodesValue(patientReferral, user);
    const billableIcdCodesInitialValue = getBillableIcdCodesValue(patientReferral);
    const patientConditions = getPatientConditions(diagnosisSource, conditions, billableIcdCodesInitialValue);
    const isDeviceAccessible = getDeviceAccessibilityValue(patientReferral);
    const oldEligiblePrograms = getProgramsValue(patientReferral) || [];
    const oldVitals = getVitalsValue(patientReferral) || [];
    const wouldJoin = getWouldJoinValue(patientReferral);
    const smartPhoneSkills = getTechLevel(patientReferral);
    const isBPEligible = getBPEligibility(patientConditions);
    const isAnotherCcmProviderSelected = getAnotherCCMFieldValue(patientReferral);
    const isConfirmed = !_.isEmpty(patientConditions);
    const { vitals, eligiblePrograms, disabledPrograms, disabledVitals } = getDisabledAndRecommendations({
      userRole,
      isDeviceAccessible,
      isBPEligible,
      smartPhoneSkills,
      eligiblePrograms: oldEligiblePrograms,
      vitals: oldVitals,
      conditions: patientConditions,
      hasAnotherCCMProvider: isAnotherCcmProviderSelected,
    });
    return {
      patientConditions,
      icdCodesInitialValue,
      billableIcdCodesInitialValue,
      vitals: oldVitals === null ? vitals : oldVitals,
      eligiblePrograms: oldEligiblePrograms === null ? eligiblePrograms : oldEligiblePrograms,
      disabledPrograms,
      disabledVitals,
      wouldJoin: _.isNil(wouldJoin) ? true : wouldJoin,
      isDeviceAccessible,
      isAnotherCcmProviderSelected,
      smartPhoneSkills,
      exclusionReason: getExclusionReason(user),
      exclusionOtherReason: getExclusionOtherReason(patientReferral),
      isConfirmed,
      diagnosisSource
    };
  }, []);

  const [disabledPrograms, setDisabledPrograms] = useState(initialValues.disabledPrograms);
  const [disabledVitals, setDisabledVitals] = useState(initialValues.disabledVitals);
  const [isConfirmed, setIsConfirmed] = useState(initialValues.isConfirmed);
  const selectedVitals = getValue(fieldsValues.recommendVitalsList, initialValues.vitals);
  const selectedDiagnosisSource = getValue(fieldsValues.diagnosisSource) || initialValues.diagnosisSource;
  const selectedConditions = getValue(fieldsValues.conditionsList, initialValues.patientConditions);
  const selectedBillableIcdCodes = getValue(fieldsValues.billableHealthConditions, initialValues.billableIcdCodesInitialValue);
  const patientConditions = getPatientConditions(selectedDiagnosisSource, selectedConditions, selectedBillableIcdCodes);
  const hasHldOnly = onlyHasHLD(patientConditions);
  const hasNoConditionFromIcdCode= selectedDiagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE && _.isEmpty(patientConditions);
  let selectedPrograms = getValue(fieldsValues.eligiblePrograms, initialValues.eligiblePrograms);
  selectedPrograms = _.intersection(selectedPrograms,getOrgProgramParticipation());
  const isJoiningProgram = getValue(getBinaryRadioValue(fieldsValues.joinProgram), initialValues.wouldJoin);
  const isDeviceAccessible = getValue(getBinaryRadioValue(fieldsValues.deviceAccessability), initialValues.isDeviceAccessible);
  const selectedExclusionReason = getValue(fieldsValues.exclusionReason, initialValues.exclusionReason);
  const selectedOtherExclusionReason = getValue(fieldsValues.exclusionReasonOther, initialValues.exclusionOtherReason);
  const orgHasAnotherCCMProvider = getAnotherCCMProviderOrgPermissions();
  const hasAnotherCCMProvider = getValue(getBinaryRadioValue(fieldsValues.anotherCCMProvider), initialValues.isAnotherCcmProviderSelected);
  const smartPhoneSkills = getValue(fieldsValues.smartPhoneSkills, initialValues.smartPhoneSkills); 
  const loanerPhoneConfig = getSmartphoneOrgPermissions();
  const isBPEligible = getBPEligibility(patientConditions);
  const bpAndRpmOnly = isJoiningProgram && isDeviceAccessible === false && checkBpAndRpmOnly(smartPhoneSkills, isBPEligible);
  const showAnotherCCMAlert = isDeviceAccessible && orgHasAnotherCCMProvider && hasAnotherCCMProvider;
  const showSecondForm = !_.isNil(isDeviceAccessible) && 
  ((!orgHasAnotherCCMProvider || !_.isNil(hasAnotherCCMProvider)) ||
  (isDeviceAccessible === false && loanerPhoneConfig.supportLoanerPhone && !_.isNil(smartPhoneSkills)));

  // useEffect(() => {
  //   if (!showSecondForm) return;
  //   handleConditionSelect(patientConditions);
  // }, [showSecondForm]);

  const showNotEligibleSmartPhoneOverlay = () => {
    if (isJoiningProgram) {
      if (isDeviceAccessible !== false) return false;
      if (bpAndRpmOnly) return false;
      if (loanerPhoneConfig.supportLoanerPhone && !_.isNil(smartPhoneSkills) && smartPhoneSkills !== SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE) return false;
      return true;
    }
    return false;
  }

  const showButton = () => {
    if (!isConfirmed || _.isEmpty(selectedVitals) || _.isEmpty(selectedPrograms)) return false;
    if (isJoiningProgram) {
      if (isDeviceAccessible) return true;
      if (bpAndRpmOnly) return true;
      if (loanerPhoneConfig.supportLoanerPhone && !_.isNil(smartPhoneSkills) && smartPhoneSkills !== SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE) return true;
    } else {
      if (selectedExclusionReason && (selectedExclusionReason !== 'OTHER' || selectedOtherExclusionReason)) return true;
    }
    return false;
  }

  const _getDisabledAndRecommendations = (updatedValues) => {
    const isBPEligible = getBPEligibility(patientConditions);
    const isDeviceAccessible = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.DEVICE_ACCESSABILITY);
    const smartPhoneSkills = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS);
    const oldEligiblePrograms = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS);
    const oldVitals = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST);
    return getDisabledAndRecommendations({
      userRole,
      isDeviceAccessible,
      isBPEligible,
      smartPhoneSkills,
      eligiblePrograms: oldEligiblePrograms,
      vitals: oldVitals,
      conditions: patientConditions,
      hasAnotherCCMProvider,
      ...updatedValues
    });
  }

  const handleConditionSelect = (patientConditions) => {
    const { disabledVitals,  disabledPrograms } = _getDisabledAndRecommendations({ isDeviceAccessible });
    const { eligiblePrograms, vitals } = getVitalsAndPrograms(patientConditions, disabledPrograms.map(x => x.key));
    setDisabledPrograms(disabledPrograms);
    form.setFieldsValue({ 
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: _.difference(vitals, _.map(disabledVitals, 'key')),
    });
  }

  const onDeviceAccessibilityChange = (evt) => {
    const isDeviceAccessible = !!evt.target.value;
    const { 
      disabledVitals, disabledPrograms, eligiblePrograms, vitals 
    } = _getDisabledAndRecommendations({ isDeviceAccessible });

    setDisabledPrograms(disabledPrograms);
    setDisabledVitals(disabledVitals);
    form.setFieldsValue({ 
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: vitals,
    });
  }

  const onTechLevelChange = (evt) => {
    const smartPhoneSkills = evt.target.value;
    const { 
      disabledVitals, disabledPrograms, eligiblePrograms, vitals 
    } = _getDisabledAndRecommendations({ smartPhoneSkills });

    setDisabledPrograms(disabledPrograms);
    setDisabledVitals(disabledVitals);
    form.setFieldsValue({ 
      [PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS]: eligiblePrograms,
      [PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST]: vitals,
    });
  }

  const onVitalsChange = (newVitals) => {
    const selectedSmartPhoneSkill = form.getFieldValue(PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS);
    if(selectedSmartPhoneSkill === SMARTPHONE_SKILL_ENUM.SMART_PHONE_NOT_PREFERRED && !_.includes(newVitals, 'BP')) {
      form.setFieldsValue({
        [PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS]: null
      });
    }
  }

  const getFieldsOne = () => {
    const fields = [];
    fields.push(deviceAccessability({ onChange: onDeviceAccessibilityChange, initialValue: getRadioValue(initialValues.isDeviceAccessible) }));
    if (isDeviceAccessible === false && loanerPhoneConfig.supportLoanerPhone) {
      const selectedValues = {
        // conditionsToMonnitor: form.getFieldValue('conditionsList') || getConditionsValue(patientReferral,user) || [],
        recommendedVitals: form.getFieldValue('recommendVitalsList') || getVitalsValue(patientReferral) || []
      };
      fields.push(smartPhoneSkillsField({ onChange: onTechLevelChange, initialValue: initialValues.smartPhoneSkills, selectedValues }));
    }
    if (isDeviceAccessible && orgHasAnotherCCMProvider) {
      fields.push(anotherCCMProvider({ initialValue: getRadioValue(initialValues.isAnotherCcmProviderSelected) }));
    }
    return fields;
  }

  const getFieldsTwo = () => {
    const fields = [];
    fields.push(wantToJoinProgram({ initialValue: getRadioValue(initialValues.wouldJoin) }));
    if (isJoiningProgram === false) {
      fields.push(exclusionReason({ initialValue: initialValues.exclusionReason }));
      if (selectedExclusionReason === 'OTHER') {
        fields.push(exclusionReasonOther({ initialValue: initialValues.exclusionOtherReason }));
      }
    }
    return fields;
  }

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (disabled) {
      return goNextStep();
    }
    validateFields(async (err, values) => {
      if (!err) {
        const { id: memberId, remoteEnrollment } = user;
        const patientConditions = getPatientConditions(values.diagnosisSource, values.conditionsList, values.billableHealthConditions);
        const variables = {
          patientId: memberId,
          // referredBy: JSON.parse(sessionStorage.getItem('currentUser')).id,
          recommendedVitals: values.recommendVitalsList,
          eligiblePrgorams: values.eligiblePrograms,
          wouldJoin: !!values.joinProgram,
          deviceAccessbility: !!values.deviceAccessability,
          techLevel: values.smartPhoneSkills,
          enrolledAnother: !!values.anotherCCMProvider,
          diagnosisSource: values.diagnosisSource,
          conditionsToMonnitor: patientConditions,
          healthConditions: values.healthConditions,
          billableHealthConditions: values.billableHealthConditions,
        };
        // if (!hideDeviceAccessibility) {
        //   variables.deviceAccessbility = !!values.deviceAccessability;
        // }
        try {
          if (values.joinProgram === 0) {
            if (_.isNil(remoteEnrollment)) {
              await remoteEnrollmentApis.createRE({ memberId });
            }
            await remoteEnrollmentApis.editExcludedReason({
              memberId,
              excludedReason: values.exclusionReason,
            });
            await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'EXCLUDED' });
            if (values.exclusionReasonOther) {
              variables.exclusionOtherReason = values.exclusionReasonOther;
              await PostAPI.createPostItMutation({ organizationId:getCurrentOrg().id, memberId, note: `${EXCLUSION_REASONS_MAP[values.exclusionReason]}. ${values.exclusionReasonOther}`});
            }
          }
          await upsertPatientReferralMutation(variables);
          // onSubmitDone(values.joinProgram ? {}:{ tooltipMessage:'Patient is Excluded',hideTooltip: !values.joinProgram,fn: message.warn });
          if (values.joinProgram === 0) {
            message.warn('Patient is Excluded');
            closeModal();
          } else {
            if (values.diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE) {
              await editUserMutation({
                id: memberId,
                memberProfile: {
                  birthday: _.get(user, 'profile.birthday'),
                  healthConditions: values.healthConditions,
                  billableHealthConditions: values.billableHealthConditions,
                }
              });
            }
            onSubmitDone();
          }
        } catch (error) {
          console.error(error);
          openErrorModal(GQLHelper.formatError(error));
        }
      }
    });
  }, [validateFields, onSubmitDone]);

  const handleNotEligibleClick = async () => {
    // mark patient as excluded
    try {
      const { id: memberId, remoteEnrollment } = user;
      const values = getFieldsValue();
      if (_.isNil(remoteEnrollment)) {
        await remoteEnrollmentApis.createRE({ memberId });
      }
      await remoteEnrollmentApis.editExcludedReason({
        memberId,
        excludedReason: 'NO_SMART_PHONE',
      });
      await remoteEnrollmentApis.moveRemoteEnrollmentHandler({ memberId, status: 'EXCLUDED' });
      const referralVariables = {
        patientId: memberId,
        deviceAccessbility: !!values.deviceAccessability,
      };
      const techLevel = values.smartPhoneSkills;
      if (!_.isNil(techLevel)) {
        referralVariables.techLevel = techLevel;
      }
      await upsertPatientReferralMutation(referralVariables);
      closeModal();
    } catch (error) {
      console.error(error);
      openErrorModal(GQLHelper.formatError(error));
    }
  }

  const notDeviceAccessibleOverlay = () => {
    return (
      <Overlay>
        <div className="flex-center fit-parent">
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.noSmartphoneEligibility')}</div>
            <div style={{ marginBottom: 20 }}>{I18N.get('referAndEnroll.noSmartphoneEligibilitySubText')}</div>
            <div className="flex-center">
              <Button type="primary" className="new-style" onClick={handleNotEligibleClick}>{I18N.get('referAndEnroll.noSmartPhoneEligibilityButton')}</Button>
            </div>
          </div>
        </div>
      </Overlay>
    );
  }

  const btnText = isJoiningProgram ? 'Next' : 'Close';
  return (
    <React.Fragment>
      <ReferAndEnrollForm 
        header="Program Eligibility" 
        className="program-eligibility"
        onSubmit={handleSubmit} 
        buttons={showButton() && <Button type="primary" htmlType="submit" className="new-style">{btnText}</Button>}
      >
        <ReferralCodeConditionContainer 
          form={form} 
          handleConditionSelect={handleConditionSelect} 
          disabled={disabled} 
          diagnosisSourceInitialValue={initialValues.diagnosisSource}
          icdCodesInitialValue={initialValues.icdCodesInitialValue}
          billableIcdCodesInitialValue={initialValues.billableIcdCodesInitialValue}
          conditionsInitialValue={initialValues.patientConditions}
          isConfirmed={isConfirmed}
          setIsConfirmed={setIsConfirmed}
          selectedConditions={selectedConditions}
          selectedBillableIcdCodes={selectedBillableIcdCodes}
        />
        {
          isConfirmed 
          && (
            <React.Fragment>
              <FormRenderer form={form} fields={getFieldsOne()} disabled={disabled} />
              {bpAndRpmOnly && <div style={{ color: '#DA6453', fontStyle: 'italic', marginBottom: 20 }}>{I18N.get('referAndEnroll.noSmartPhoneBPAndRpm')}</div>}
              {showAnotherCCMAlert && <div style={{ color: '#DA6453', fontStyle: 'italic', marginBottom: 20 }}>{I18N.get('referAndEnroll.anotherCCMAlert')}</div>}
            </React.Fragment>
          )
        }
        <Row style={{ display: (isConfirmed && showSecondForm) ? 'block' : 'none' }}>
          <Card 
            style={{ 
              position: 'relative', 
              boxShadow: '0px 0px 10px -4px rgba(0,0,0,0.7)', 
              margin: '0 10px 30px 10px',
            }}
          >
            {(hasNoConditionFromIcdCode || hasHldOnly) && renderIneligibleOverlay(hasNoConditionFromIcdCode)}
            {showNotEligibleSmartPhoneOverlay() && notDeviceAccessibleOverlay()}
            <VitalsAndProgramsComponent
              form={form}
              disabled={disabled}
              disabledPrograms={disabledPrograms}
              disabledVitals={disabledVitals}
              vitalsInitialValue={initialValues.vitals}
              eligibleProgramsInitialValue={initialValues.eligiblePrograms}
              onVitalsChange={onVitalsChange}
            />
          </Card>
          <FormRenderer form={form} fields={getFieldsTwo()} disabled={disabled} />
          {isJoiningProgram === false && selectedExclusionReason && 
            <div style={{ fontSize: 11, width: 400, marginTop: 20 }}>
              {I18N.get('referAndEnroll.notWillingToJoinNote')}
            </div>
          }
        </Row>
      </ReferAndEnrollForm>
    </React.Fragment>
  );
}

export default Form.create()(EnrollmentProgramEligibility);
