import { ROLE_NAME } from '../../../lib/constants';
import roleMap from '../../VideoChat/constant/roleMap';

export const ROLES = {
  admin: 'ADMIN:Admin',
  doctor: 'PROVIDER:Doctor',
  nurse: 'PROVIDER:Nurse',
  medicalAssistant: 'PROVIDER:Medical Assistant',
  clinicMedicalAssistant: 'PROVIDER:Clinic Medical Assistant',
  biller: 'PROVIDER:Biller',
  patient: 'MEMBER:Member',
  vendor: 'OTHER:Vendor',
  superAdmin: 'SUPER_ADMIN:Super Admin',
  MA:'MA',
  partnerBiller: 'PROVIDER:Partner Biller',
  healthCoach: `PROVIDER:${ROLE_NAME.HC}`
}

export const CALL_ROLES = {
  admin: 'ADMIN:Admin',
  doctor: 'PROVIDER:Doctor',
  nurse: 'PROVIDER:Nurse',
  medicalAssistant: 'PROVIDER:Medical Assistant',
  clinicMedicalAssistant: 'PROVIDER:Clinic Medical Assistant',
  MA:'MA',
  healthCoach: ROLES.healthCoach
}

export const CHAT_ROLES = [ROLES.doctor, ROLES.nurse, ROLES.medicalAssistant, ROLES.clinicMedicalAssistant, ROLES.healthCoach];

export const rolesMap = {
    'ADMIN:Admin': "admin",
    'PROVIDER:Doctor': "doctor",
    'PROVIDER:Nurse': "nurse",
    'PROVIDER:Medical Assistant': "MA",
    'PROVIDER:Clinic Medical Assistant': "clinicMedicalAssistant",
    'PROVIDER:Biller': 'biller',
    'PROVIDER:Partner Biller': 'partnerBiller',
    'MEMBER:Member': "patient",
    'OTHER:Vendor': "vendor",
    'SUPER_ADMIN:Super Admin': "superAdmin",
    [ROLES.healthCoach]: 'healthCoach'
}

export const rolesViewAssessmentLink = ['MA', 'MD', 'RD'];

export const roleNameMap = {
  MA: 'Clinic Medical Assistant',
  MD: 'Doctor',
  RD: 'Nurse',
  CA: 'Medical Assistant',
  HC: 'Health Coach'
};

export const IHEALTH_MEMBER_ROLES = ['RD', 'HC', 'CA'];

export const ASSIGNED_RD_HC_ROLES = [roleNameMap.RD, roleNameMap.HC];
export const ASSIGNED_CA_ROLES = [roleNameMap.CA];

export const ALERT_POLL_ROLES = ['MD', 'RD', 'CA', 'HC'];

// only for referrence
export const CT_ASSIGNEE_ROLES = ['RD/HC', 'CA'];

// roles will see reminder for missing CT assignee
export const ASSIGNEE_REMINDER_ROLE = IHEALTH_MEMBER_ROLES;

export const SIGN_OFF_ROLES = [ROLES.nurse, ROLES.healthCoach];

// roles can see complexity level and update manually
export const COMPLEXITY_ROLES = IHEALTH_MEMBER_ROLES;

// roles to view discharged history
export const INITIAL_EP_FETCH_ROLES = [ROLES.nurse, ROLES.doctor, ROLES.medicalAssistant, ROLES.clinicMedicalAssistant, ROLES.healthCoach];

export const DISCHARGED_HISTORY_VIEW_CARETEAM_ROLES = IHEALTH_MEMBER_ROLES;