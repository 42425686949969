import React from "react";
import { Table } from 'antd';
import moment from "moment";
import { groupBy,values,map,get,first } from 'lodash';
import { fullMonthArray } from '../../constant/constVariables';
const MonthlyEnrollGoalCountHistoryComponent = (props)=>{
	const { monthlyEnrollGoalCountHistory } = props;
	let groupedByHistory = _.groupBy(_.sortBy(monthlyEnrollGoalCountHistory,['year','month']).reverse(),(h)=>h.month+'/'+h.year);
	let parsedArray = Object.keys(groupedByHistory).map(k=>({ key:k,values:groupedByHistory[k].sort(o=>o.createdAt) }));
	const columns = [{
		title:'Month',
		width:'25%',
		dataIndex: 'values',
		render:(d)=>fullMonthArray[first(d).month - 1]
	},
	{   title:'Year',
		width:'25%',
		dataIndex: 'values',
		render:(d)=>first(d).year
	},
	{
		title:'Goal',
		dataIndex: 'values',
		width:'50%',
		render:(d,doc)=>first(d).count
	}]
	const expandColumns = [{
		title:'',
		width:'25%',
		dataIndex: 'month',
		render:(d)=>fullMonthArray[d-1]
	},{
		title:'',
		width:'25%',
		dataIndex: 'year',
	},
	{
		title:'',
		dataIndex: 'count',
		width:'50%',
		render:(d,doc)=>{
			return <div>
				<p>{d}</p>
				<p>Created at:{moment(doc.createdAt).format('MM/DD/YYYY')}</p>
			</div>
		}
	}]
	return  <Table columns={columns} dataSource={parsedArray}
	               expandedRowRender={(a)=><Table dataSource={a.values} pagination={{ hideOnSinglePage:true }} className={'monthlyEnrollGoalExpandTable'} columns={expandColumns}/> }
	               pagination={{ hideOnSinglePage:true }} style={{ paddingTop:30,paddingBottom:30 }}/>

}
export default MonthlyEnrollGoalCountHistoryComponent;