import { React, IHButton } from 'ihcomponent';
import { goPath } from 'libModule/utils';
// component
import PreviewInnerContainer from '../../../patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer';

const Component = (props) => {
  const surveyId = props.params.surveyId;
  const onCancel = () => goPath('/nurse_surveys');

  return (
    <div className="patient-take-assessment">
      <button className="link-like back" onClick={onCancel}>&lt; Back to Surveys</button>
      <div className="paper">
        <PreviewInnerContainer isAssessment id={surveyId} breadCrumb={'Survey'} />

        <div className="btn-wrapper">
          <div className="right">
            <IHButton label="Back" onClick={onCancel} bsStyle="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};

Component.displayName = 'survey/preview/components/NursePreviewComponent';

export default Component;
