import { nationOptions, relationOptions } from 'libModule/constants';
import { firstAndLastNameValidator, emailValidator2, NRICValidator, mobileValidator } from 'libModule/validator';
import I18N from 'modulesAll/I18N';

export const getAddCareGiverFormProps = ({ onFieldsChange, index }) => {

  const itemLayout = {
    labelCol : {span : 24},
    wrapperCol : {span : 24}
  };

  const n = index + 1;

  const itemList = [
    [
      {
        key: `c_firstName_${n}`,
        label: 'Caregiver First Name',
        type: 'text',
        disabled: false,
        initialValue: '',
        ...itemLayout,
        col: { span: 12, offset: 0 },
        rules : [
          {required : true, message : 'First Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
        ]
      },
      {
        key: `c_lastName_${n}`,
        label: 'Caregiver Last Name',
        type: 'text',
        disabled: false,
        initialValue: '',
        ...itemLayout,
        col: {span: 12, offset: 0},
        rules : [
          {required : true, message : 'Last Name is required'},
          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
        ]
      }
    ],
    // 2nd Row
    [
      {
        key: `c_nric_${n}`,
        label: `Caregiver ${I18N.get('keywords.CaregiverNRIC/FIN')}`,
        type: 'text',
        disabled: false,
        initialValue: '',
        ...itemLayout,
        col: {span: 12, offset: 0},
        rules : [
          {required : true, message : `${I18N.get('keywords.CaregiverNRIC/FIN')} is required`},
          {validator: (rule, value, callback) => callback(NRICValidator(value))}
        ]
      },
      {
        key: `c_mobileCountryCode_${n}`,
        label: 'Caregiver Mobile',
        type: 'text',
        disabled: true,
        ...itemLayout,
        col: {span: 4, offset: 0},
        initialValue: '+1'
      },
      {
        key: `c_mobilePhone_${n}`,
        label: ' ',
        type: 'text',
        disabled: false,
        initialValue: '',
        ...itemLayout,
        col: {span: 8, offset: 0},
        rules : [
          {validator: (rule, value, callback) => callback(mobileValidator(value, 'mobilePhone'))}
        ],
      }
    ],
    // 3rd Row
    [
      {
        key: `c_email_${n}`,
        label: 'Caregiver Email',
        type: 'text',
        disabled: false,
        initialValue: '',
        ...itemLayout,
        col: {span: 24, offset: 0},
        rules : [
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ]
      }
    ],
    // 4th Row
    [
      {
        key: `c_nationality_${n}`,
        label: 'Nationality',
        type: 'select',
        initialValue: 'US',
        disabled: false,
        ...itemLayout,
        col: {span: 10, offset: 0},
        option: nationOptions,
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
      {
        key: `c_relationship_${n}`,
        label: 'Relationship',
        type: 'select',
        initialValue: 'CHILD',
        disabled: false,
        ...itemLayout,
        col: {span: 6, offset: 0},
        option: relationOptions,
        showSearch: true,
        filterOption: (inputValue, option) => {
          return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      },
    ]
  ];

  return {
    horizontal: true,
    onFieldsChange,
    itemList
  }
};
