import types from 'patientModule/enrolledProgramInfo/Careplan/constants/CommonConstants'

const initState = {
  currentPage: types.CAREPLAN_MAIN_PAGE,
  assessmentHasEmptySchedule: false,
  surveyHasEmptySchedule: false
}

export default (state=initState, action) => {
  switch(action.type) {
    case types.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.currentPage ? action.currentPage : initState.currentPage
      }
    case types.SET_ASSESSMENT_HAS_EMPTY_SCHEDULE:
      return {
        ...state,
        assessmentHasEmptySchedule: action.hasEmptySchedule
      }
    case types.SET_SURVEY_HAS_EMPTY_SCHEDULE:
      return {
        ...state,
        surveyHasEmptySchedule: action.hasEmptySchedule
      }
    default:
      return state
  }
}
