import React from 'react';
import { Table, Tooltip } from 'antd';
import BpMap from '../constants/map';
import {pulsePressureTooltipContent} from '../constants/pulsePressure';

let all = 1;
const BPResultStatisticsComponent  = class extends React.Component {
    renderBPSummary(){
        const  { stat = { } } = this.props;
        const source =[];


        Object.entries(stat).forEach(e =>
            {   
                if(e[0] === '__typename') {
                    return
                }
                let type = BpMap[e[0]];
                let value = e[1];
                let count = _.get(value, 'count', 'N/A');
                let averageArr = _.get(value, 'average', 'N/A');
                let average = (averageArr.length === 0 || averageArr === undefined) ? 'N/A' :  (averageArr.length == 1) ? averageArr[0]: averageArr[0] + '/' + averageArr[1];
                let rangeArr = _.get(value, 'range', undefined);
                let range = (rangeArr.length === 0 || rangeArr === undefined) ? 'N/A' :(rangeArr.length == 2) ? rangeArr[0] + '-' + rangeArr[1]  :rangeArr[0] + '-' + rangeArr[1] + '/' + rangeArr[2] + '-' + rangeArr[3] ;
                let percentage = _.get(value, 'inRangePercentage') === null ? 'N/A' : _.get(value, 'inRangePercentage') + '%';
                source.push({
                    type,
                    count,
                    average,
                    range,
                    percentage
                })
            }
        );

        const columns = [
            {
                title:'Type',
                key:'type',
                align:'center',
                dataIndex: 'type',
                width: '20%',
                render: (d) => {
                    if(d === BpMap['pulsePressure']){
                        return <Tooltip title={pulsePressureTooltipContent}>
                            <span style={{textDecorationStyle: 'dashed', textDecorationLine: "underline"}}>{d}</span>
                        </Tooltip>
                    }
                    return d;
                }
            },
            {
                title:'#',
                key:'count',
                align:'center',
                width: '10%',
                dataIndex: 'count',
                render: (a, b) => {
                    if(b.type === 'All') {
                        all = a;
                        return a
                    }
                    let percentage = Math.round(Number(a/all)*100)
                    return a === 0 ? a : a + ', ' + (percentage === 0 ? '' : percentage + '%')
                }
            },
            {
                title:'Avg\n(mmHg)',
                key:'average',
                align:'center',
                dataIndex:'average'
            },
            {
                title:'Range\n(mmHg)',
                key:'range',
                align:'center',
                dataIndex:'range',
                width: '20%'
            },
            {
                title:'Vitals in Normal Range',
                key:'percentage',
                align:'center',
                dataIndex:'percentage'
            },
        ];

        return <Table dataSource={source}
                      rowKey={(record,index) => index}
                      columns={columns}
                      pagination={false}
                      bordered={false}
                      title={()=><span>Blood Pressure Summary</span>}
                      rowClassName='statisticsBPTableRow'
                      className='statisticsBPTable'/>
    }

    render(){
        const { loading } = this.props;
        if(loading){
            return <div>Loading</div>
        }
        return<div>
                { this.renderBPSummary() }
              </div>
    }
}


export default  BPResultStatisticsComponent;
