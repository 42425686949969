import { combineReducers } from 'redux';

const INITIAL_STATE = {
    consentInfo:{},
    roomNumber: null
};
const reducer = (state = INITIAL_STATE, action) => {
    const { consentInfo } = state;
    const { visitId,memberId,roomNumber } = action || {};
    const oldConsentInfo = Object.assign({},consentInfo[memberId]||{ });
    const newConsentInfo = Object.assign(oldConsentInfo,action.consent);
    switch (action.type) {
        case 'SET_VIRTUAL_VISIT':
            return {
                ...state,
                visitId
            };
        case 'SET_CONSENT_INFO':
            return {
                ...state,
                consentInfo:{
                    ...consentInfo,
                    [`${memberId}`]:newConsentInfo,
                }
            }
        case 'RESET_CONSENT_INFO':
            return {
              ...state,
              consentInfo:{
                ...consentInfo,
                [memberId]: {},
              }
            }
        case 'SET_ROOM_NUMBER' :
            return {
                ...state,
                roomNumber
            }
        default:
            return state
    }
}

export default {
    VideoChat: combineReducers({main:reducer})
};
