import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Radio, Checkbox, Input, Row, Col, Modal } from 'antd';
import { compose, graphql } from 'react-apollo';
import I18N from '../../I18N';
import { editUserMutation } from '../API/mutation';
import { CONFIRM_DEVICE_KEYS as KEYS, DELIVERY_TYPE_ENUM, confirmDeviceConstants } from '../constant';
import { APP_SYNC, userDeviceRecordsTypeName  } from '../../visitWorkList/constant/onboardMap';
import { VITALS_ORDER } from '../../../lib/constants';
import { SMARTPHONE_SKILL_ENUM } from '../constant/programEligibility';

import ReferAndEnrollForm from './ReferAndEnrollForm';
import ClearAssignmentComponent from './ConfirmDevice/ClearAssignmentComponent';
import PhoneAssignmentComponent from './ConfirmDevice/PhoneAssignmentComponent';
import AddressVerification from './ConfirmDevice/AddressVerification';

import confirmDeviceHelpers from '../helper/confirmDeviceHelpers';
import {getKeepOwnDevicesOrgPermission, getSmartphoneOrgPermissions} from '../helper';
import { upsertPatientReferralMutation } from '../API/mutation';
import iotDeviceList from '../../patient/DevicesAssign/query/iotDeviceList';
import getLoanDevice from '../../patient/loanDevices/query/getLoanDevice';
import remoteEnrollmentApis from '../../remoteEnrollment/API';
import { STATUS_ENUM } from '../constant';

import '../style/confirmDevice.scss';
import {withState} from "recompose";
import {omitDeep} from "../../../lib/utils";
import { getClearDeviceList } from '../../../lib/helpers/device-helpers';

const CLEAR_VALUE = confirmDeviceConstants.CLEAR.value;
const PHONE_VALUE = confirmDeviceConstants.LOANER_PHONE.value;
const EASE_VALUE = userDeviceRecordsTypeName.Ease;
const ROUTER_VALUE = confirmDeviceConstants.ROUTER.value;
const HOTSPOT_VALUE = confirmDeviceConstants.HOTSPOT.value;

const STEP_INDEX_MAP = confirmDeviceConstants.STEP_INDEX_MAP;

const deliveryType = () => ({
  key: KEYS.deliveryType,
  label: '',
  initialValue: null,
  rules: [{
    required: true,
    message: 'Please select an option'
  }],
  getInitialValue: ({ selectedDeliveryType }) => selectedDeliveryType,
  render: (props) => {
    const { iotDeviceData, loanDeviceData, disabled } = props;
    let  { keepOwnDevices } = getKeepOwnDevicesOrgPermission();
    let hideRadio = !keepOwnDevices;
    return <Radio.Group
      options={confirmDeviceConstants.DELIVERY_TYPE}
      onChange={e => {
        props.setStepIndex(KEYS.deliveryType); // reset to start
        props.setSelectedDeliveryType(e.target.value);
        props.setSelectedCuffSize(null);
      }}
      style={ hideRadio ? { visibility: 'hidden',height: 1 }:{} }
      // force user to unassign first before change delivery type
      disabled={disabled || !!(iotDeviceData && iotDeviceData.length || loanDeviceData)}
    />;
  }
});

const doesHaveRouter = () => ({
  key: KEYS.hasRouter,
  label: 'Does patient have own router at home?',
  rules: [{
    required: true,
    message: 'Please select an option'
  }],
  getInitialValue: ({ hasRouter }) => hasRouter,
  render: (props) => {
    return <Radio.Group
      options={confirmDeviceConstants.HAS_ROUTER}
      disabled={props.disabled}
      onChange={() => props.setStepIndex(KEYS.giveoutDevices)}
    />;
  }
});

const giveoutDevices = (iotDeviceData, loanDeviceData, selectedDeliveryType) => {
  return {
    key: KEYS.giveoutDevices,
    label: I18N.get(`referAndEnroll.giveoutDevicesLabel.${selectedDeliveryType}`),
    initialValue: [],
    rules: [{
      required: true,
      message: ' '
    }],
    getInitialValue: ({ selectedDeliveryType, listOfAvailableDevices, patientReferral }) => 
      confirmDeviceHelpers.getPreviousSelectedGiveoutDevices(patientReferral, listOfAvailableDevices, selectedDeliveryType, iotDeviceData, loanDeviceData),
    render: (props) => {
      const { hasClearInOptions, hasRouter, selectedCuffSize } = props;
      const isPickup = props.selectedDeliveryType === DELIVERY_TYPE_ENUM.PICK_UP;
      return <Checkbox.Group
        className={!isPickup ? 'hide-checkbox' : ''}
        disabled={props.disabled || !isPickup}
        options={_.map(props.listOfAvailableDevices, sd => {
          const { value, imgSrc, displayName, otherName, disabled } = sd;
          return ({
            label: (
              <div className={hasClearInOptions ? 'has-clear-option' : ''}>
                <div className='confirm-devices-shipping-device'>
                  <img src={imgSrc} alt={displayName} width='92px' height='75px' />
                  <div>
                    <div className='device-display-name'>{displayName}</div>
                    {otherName && <div className='device-other-name'>({otherName})</div>}
                  </div>
                  {
                    selectedCuffSize && value === EASE_VALUE &&
                    <div>
                      <span className='ease-cuff-size-selection-text'>
                        Cuff size: {_.get(confirmDeviceConstants, `CUFF_SIZE_DISPLAY_NAME.${selectedCuffSize}`, '--')}
                      </span>
                      <Button 
                        type='link'
                        className='ease-cuff-size-edit-btn' 
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setShowBP3LCuffSize(true);
                        }}
                        disabled={props.disabled}
                      >
                        Edit
                      </Button>
                    </div>
                  }
                </div>
                {
                  !_.isNil(hasRouter) && value === CLEAR_VALUE ?
                  <div className='confirm-devices-shipping-device confirm-devices-router'>
                    {
                      (() => {
                        let item = hasRouter === 'YES' ? confirmDeviceConstants.ROUTER : confirmDeviceConstants.HOTSPOT;
                        const { imgSrc: itemImgSrc, displayName: itemDisplayName } = item;
                        return (
                          <div>
                            <img src={itemImgSrc} alt={itemDisplayName} width='92px' height='75px' />
                            <div style={{ marginTop: 5 }}>
                              <div className='device-display-name'>{itemDisplayName}</div>
                            </div>
                          </div>
                        );
                      })()
                    }
                  </div>
                  : null
                }
              </div>
            ),
            value,
            disabled
          });
        })}
        onChange={checkedValues => {
          const prevCheckedValues = props.form.getFieldValue(KEYS.giveoutDevices);

          const isAdded = value => !_.includes(prevCheckedValues, value) && _.includes(checkedValues, value);
          const isRemoved = value => _.includes(prevCheckedValues, value) && !_.includes(checkedValues, value);
          const hasActionOnValue = (value) => isAdded(value) || isRemoved(value);

          props.setStepIndex(KEYS.giveoutDevices); // reset to select devices to confirm again
          if(hasActionOnValue(CLEAR_VALUE))
            return props.setShowAddClear(true);
          if(hasActionOnValue(PHONE_VALUE))
            return props.setShowLoanerPhone(true);
          if(hasActionOnValue(EASE_VALUE)) {
            let visible = true;
            if(isRemoved(EASE_VALUE)) {
              visible = false;
              props.setSelectedCuffSize(null);
            }
            return props.setShowBP3LCuffSize(visible);
          }
        }}
      />;
    }
  };
};

const cuffSize = () => ({
  key: KEYS.cuffSize,
  label: '',
  initialValue: null,
  rules: [{
    required: true,
    message: 'Please select an option'
  }],
  getInitialValue: ({ selectedCuffSize }) => selectedCuffSize,
  render: (props) => {
    return <Radio.Group 
      options={confirmDeviceConstants.CUFF_SIZE[userDeviceRecordsTypeName.TrackSet]} 
      disabled={props.disabled}
      onChange={(e) => {
        const newSelectedCuffSize = e.target.value;
        props.setStepIndex(KEYS.giveoutDevices);
        props.setSelectedCuffSize(newSelectedCuffSize);
      }}
    />
  }
});

const streetName = () => ({
  key: KEYS.streetName,
  label: I18N.get('referAndEnroll.shippingAddress.streetName'),
  colProps: {
    span: 14
  },
  initialValue: '',
  rules: [{
    required: true,
    message: `${I18N.get('referAndEnroll.shippingAddress.streetName')} is required`
  }],
  valuePropPath: 'patientReferral.shipAddresses.streetName',
  alternativeValuePropPath: 'user.address.0.streetName',
  formatValue: _.trim,
  render: (props) => {
    const { getFieldValue, setFieldsValue } = props.form;
    return <AddressVerification 
      disabled={props.disabled} 
      setAddress={(value) => {
        try {
          let addressTokens = _.split(value, ',');
          addressTokens = _.map(addressTokens, t => _.split(t, '</span>'));
          addressTokens = _.flattenDeep(addressTokens);
          const newValues = Object.assign(
            {}, // reset all fields
            ..._.map(_.values(confirmDeviceConstants.AUTOCOMPLETE_ADDRESS_MAP), v => ({
              [v]: ''
            })), 
          );
          for(let t of addressTokens) {
            if(!t)
              continue;
            const str = _.replace(t, ',', '') + '</span>';
            const { elClass, elValue } = confirmDeviceHelpers.getAddressComponent(str);
            const valueKey = confirmDeviceConstants.AUTOCOMPLETE_ADDRESS_MAP[elClass];
            if(valueKey) {
              newValues[valueKey] = elValue;
            }
          } 
          setFieldsValue(newValues);
        } catch (err) {
          console.error(err);
        } finally {
          props.setStepIndex(KEYS.streetName)
        }
      }}
      formatSuggestions={(listOfSuggestions, keyword, ACOptionComponent) => {
        const renderACOptionComponent = ({ s, selectedText, disabled }) => (
          <ACOptionComponent key={s.adr_address} value={s.adr_address} selectedtext={selectedText} disabled={disabled}>
            {s.formatted_address}
          </ACOptionComponent>
        );

        const formatted = _.map(listOfSuggestions, s => {
          let str = _.split(s.adr_address, ',')[0]; // ie: HTMLString, String
          const { elClass, elValue } = confirmDeviceHelpers.getAddressComponent(str);
          if(!confirmDeviceConstants.AUTOCOMPLETE_ADDRESS_MAP.hasOwnProperty(elClass))
            return renderACOptionComponent({ s, selectedText: _.includes(str, '<span>') ? s.formatted_address : str });
          
          return renderACOptionComponent({ s, selectedText: elValue });
        });

        if(keyword && _.isEmpty(formatted))
          formatted.push(renderACOptionComponent({
            s: { adr_address: 'no_match', formatted_address: 'No match' },
            disabled: true
          }));

        return formatted;
      }}
      autoCompleteProps={{
        defaultValue: getFieldValue(KEYS.streetName),
        optionLabelProp: 'selectedtext',
        notFoundContent: undefined,
        onChange: v => setFieldsValue({ [KEYS.streetName]: v })
      }}
    />
  }
});

const unit = () => ({
  key: KEYS.unit,
  label: I18N.get('referAndEnroll.shippingAddress.unit'),
  colProps: {
    span: 6,
    offset: 1
  },
  initialValue: '',
  valuePropPath: 'patientReferral.shipAddresses.unit',
  alternativeValuePropPath: 'user.address.0.unit',
  formatValue: _.trim,
  render: (props) => <Input disabled={props.disabled} onChange={() => props.setStepIndex(KEYS.unit)}/>
});

const city = () => ({
  key: KEYS.city,
  label: I18N.get('referAndEnroll.shippingAddress.city'),
  colProps: {
    span: 8
  },
  initialValue: '',
  rules: [{
    required: true,
    message: `${I18N.get('referAndEnroll.shippingAddress.city')} is required`
  }],
  valuePropPath: 'patientReferral.shipAddresses.city',
  alternativeValuePropPath: 'user.address.0.city',
  formatValue: _.trim,
  render: (props) => <Input disabled={props.disabled} onChange={() => props.setStepIndex(KEYS.city)}/>
});

const state = () => ({
  key: KEYS.state,
  label: I18N.get('referAndEnroll.shippingAddress.state'),
  colProps: {
    span: 5,
    offset: 1
  },
  initialValue: '',
  rules: [{
    required: true,
    message: `${I18N.get('referAndEnroll.shippingAddress.state')} is required`
  }],
  valuePropPath: 'patientReferral.shipAddresses.state',
  alternativeValuePropPath: 'user.address.0.state',
  formatValue: _.trim,
  render: (props) => <Input disabled={props.disabled} onChange={() => props.setStepIndex(KEYS.state)}/>
});

const postCode = () => ({
  key: KEYS.postCode,
  label: I18N.get('referAndEnroll.shippingAddress.postCode'),
  colProps: {
    span: 6,
    offset: 1
  },
  initialValue: '',
  rules: [{
    required: true,
    message: `${I18N.get('referAndEnroll.shippingAddress.postCode')} is required`
  }],
  valuePropPath: 'patientReferral.shipAddresses.postCode',
  alternativeValuePropPath: 'user.address.0.postCode',
  formatValue: _.trim,
  render: (props) => <Input disabled={props.disabled} onChange={() => props.setStepIndex(KEYS.postCode)}/>
});

const getAddressFormConfigs = () => [
  [
    streetName(),
    unit()
  ],
  [
    city(),
    state(),
    postCode()
  ]
];

const helpedSetupAndUseDevice = () => ({
  key: KEYS.helpedSetupAndUseDevice,
  label: '',
  rules: [{
    required: true,
    message: 'Please select an option'
  }],
  getInitialValue: props => confirmDeviceHelpers.getRadioAnswer(_.get(props, `patientReferral.${KEYS.helpedSetupAndUseDevice}`)),
  render: (props) => {
    return <Radio.Group
      options={confirmDeviceConstants.TECH_HELP}
      disabled={props.disabled}
    />;
  }
});

const renderFormItem = (config, props) => {
  const { form } = props;
  const { key, label, valuePropPath, alternativeValuePropPath, getInitialValue, valuePropName, render, formatValue } = config;
  const rules = (typeof config.rules === 'function' ? config.rules(props) : config.rules) || [];
  let initialValue = _.get(props, valuePropPath) || 
                     _.get(props, alternativeValuePropPath) || 
                     (typeof getInitialValue === 'function' && getInitialValue(props)) || 
                     config.initialValue;
  
  return <Form.Item key={key} label={label} colon={false}>
    {
      form.getFieldDecorator(key, {
        initialValue: typeof formatValue === 'function' ? formatValue(initialValue) : initialValue,
        rules,
        valuePropName: valuePropName || 'value'
      })(render(props))
    }
  </Form.Item>;
};

const parseGiveoutDevices = (values, listOfAvailableDevices, selectedCuffSize) => {
  const deliveryType = values[KEYS.deliveryType];
  const isPickup = deliveryType === DELIVERY_TYPE_ENUM.PICK_UP;
  const devicePickup = [];
  const deviceShip = [];
  const deviceRecords = _.assign({}, ..._.map(listOfAvailableDevices, d => {
    const isDeviceSelected = _.includes(values[KEYS.giveoutDevices], d.value);
    let argField = (isPickup && isDeviceSelected) ? devicePickup : deviceShip;
    const hasRouter = confirmDeviceHelpers.parseRadioAnswer(values[KEYS.hasRouter]);
    if(typeof hasRouter === 'boolean' && d.value === CLEAR_VALUE) {
      const v = hasRouter ? ROUTER_VALUE : HOTSPOT_VALUE;
      if(!argField.includes(v))
        argField.push(v); // add hasRouter
    }

    argField.push(d.value); // add device
    if(selectedCuffSize && !argField.includes(selectedCuffSize) && [CLEAR_VALUE, EASE_VALUE].includes(d.value))
      argField.push(selectedCuffSize); // add cuff size

    return {
      [d.value]: APP_SYNC
    };
  }));

  const parsed = {
    devicePickup, 
    deviceShip
  };

  return parsed;
};

const parseAddress = (values, keepOwnDevices, listOfAvailableDevices, selectedCuffSize) => {
  let parsed = undefined;
  if (values[KEYS.streetName])
    parsed = {
      shipAddresses: {
        type: 'MAIN',
        streetNumber: '',
        country: 'US',
        [KEYS.streetName]: values[KEYS.streetName],
        [KEYS.unit]: values[KEYS.unit],
        [KEYS.city]: values[KEYS.city],
        [KEYS.state]: values[KEYS.state],
        [KEYS.postCode]: values[KEYS.postCode],
      }
    };

  if(parsed && !keepOwnDevices) {
    const giveoutDevices = parseGiveoutDevices(values, listOfAvailableDevices, selectedCuffSize);
    parsed = {
      ...parsed,
      ...giveoutDevices
    };
  }
  return parsed;
}

/* map item: {
  requiredFields: fields to validate by form
  render: to render item
  parse: to get value for mutation
  validate: (extra) manual validation after form validation
} */
const buildStepMap = (args) => {
  const {
    stepIndex, 
    listOfAvailableDevices, 
    selectedDeliveryType, 
    selectedGiveoutDevices, 
    selectedCuffSize,
    hasClearInOptions,
    hasRouter
  } = args;
  const isPickup = selectedDeliveryType === DELIVERY_TYPE_ENUM.PICK_UP;
  const deviceSpecs = [ROUTER_VALUE, HOTSPOT_VALUE, ..._.keys(confirmDeviceConstants.CUFF_SIZE_DISPLAY_NAME)];
  const excludedDeviceSpecs = _.filter(selectedGiveoutDevices, d => !_.includes(deviceSpecs, d));
  const pickupAllDevices = isPickup && listOfAvailableDevices.length === _.get(excludedDeviceSpecs, 'length', 0);
  let reachLastStep = false;
  let  { keepOwnDevices } = getKeepOwnDevicesOrgPermission();

  const map = [{
    requiredFields: [KEYS.deliveryType],
    render: (props) => {
      return (
        <div>
          <div className='main-label'>
            { !keepOwnDevices ? 'The following devices will be shipped to patient in a week': 'How will patient get device?' }
          </div>
          {renderFormItem(deliveryType(), props)}
        </div>
      );
    }
  }];

  if (stepIndex >= map.length && selectedDeliveryType) 
    map.push({
      requiredFields: 
        [KEYS.giveoutDevices]
        .concat(hasClearInOptions ? [KEYS.hasRouter] : [])
        .concat(hasClearInOptions && !_.isNil(hasRouter) ? [KEYS.cuffSize] : [])
      ,
      render: (props) => {
        const { disabled, iotDeviceData, loanDeviceData } = props;
        return (
          <div>
            {hasClearInOptions && renderFormItem(doesHaveRouter(), props)}
            {
              (!hasClearInOptions ||
              // disabled is for R&E before 0120 release
              (hasClearInOptions && (!_.isNil(hasRouter) || disabled))) && 
              renderFormItem(giveoutDevices(iotDeviceData, loanDeviceData, selectedDeliveryType), props)
            }
            {
              (iotDeviceData && iotDeviceData.length) ?
              <Button 
                type='link'
                className='bpm1-edit-devive-btn'
                onClick={() => props.setShowUpdateClear(true)}
                style={{ fontSize: 12, marginTop: 8 }}
                disabled={disabled}
              >
                Edit device
              </Button>
              : null
            }
            {
              ((selectedDeliveryType === DELIVERY_TYPE_ENUM.SHIP_TO_PATIENT && !_.isNil(hasRouter)) ||
              (iotDeviceData && iotDeviceData.length)) ?
              renderFormItem(cuffSize(), props)
              : null
            }
          </div>
        )
      },
      parse: (values) => parseGiveoutDevices(values, listOfAvailableDevices, selectedCuffSize),
      validate: (values, props) => {
        // make sure clear or phone is assigned when it's selected for pickup before moving on
        const { iotDeviceData, loanDeviceData } = props;
        const deliveryType = values[KEYS.deliveryType];
        const giveoutDevices = values[KEYS.giveoutDevices];

        // whenever Ease is selected AND cuff size is not selected
        if(_.includes(giveoutDevices, EASE_VALUE) && !selectedCuffSize) { 
          props.setShowBP3LCuffSize(true);
          return false;
        }

        if(deliveryType !== DELIVERY_TYPE_ENUM.PICK_UP)
          return true;

        if(_.includes(giveoutDevices, CLEAR_VALUE) && (!iotDeviceData || !iotDeviceData.length)) {
          props.setShowAddClear(true);
          return false;
        }
        if(_.includes(giveoutDevices, PHONE_VALUE) && !loanDeviceData) {
          props.setShowLoanerPhone(true);
          return false;
        }
        return true;
      }
    });
    

  if (stepIndex >= map.length && selectedDeliveryType && (!isPickup || !pickupAllDevices)) {
    map.push({
      requiredFields: [KEYS.streetName, KEYS.city, KEYS.state, KEYS.postCode],
      render: (props) => (
        <div className='confirm-devices-address'>
          <div className='confirm-devices-address-label'>
            <div className='main-label'>
              {I18N.get(`referAndEnroll.confirmDeviceLabel.${selectedDeliveryType}.main`)}
            </div>
            <div className='sub-label'>
              {I18N.get(`referAndEnroll.confirmDeviceLabel.${selectedDeliveryType}.sub`)}
            </div>
          </div>
          {
            _.map(getAddressFormConfigs(), (row, rowIdx) => {
              return <Row
                key={rowIdx}
                gutter={[8, 8]}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                {
                  _.map(row, col => {
                    const { colProps = {}, ...restConfigProps } = col;
                    return <Col key={restConfigProps.key} {...colProps}>
                      {renderFormItem(restConfigProps, props)}
                    </Col>
                  })
                }
              </Row>
            })
          }
        </div>
      ),
      parse: (values) => parseAddress(values, keepOwnDevices, listOfAvailableDevices, selectedCuffSize),
      validate: (values, props) => {
        // when Ease is available AND cuff size is not selected
        const hasEaseInOptions = !!_.find(listOfAvailableDevices, { value: EASE_VALUE });
        if(hasEaseInOptions && !selectedCuffSize) { 
          props.setShowBP3LCuffSize(true);
          return false;
        }
        return true;
      }
    });
    // when tech help question will not be available
    if(!isPickup)
      reachLastStep = true;
  }

  if (stepIndex >= map.length && isPickup) {
    map.push({
      requiredFields: [KEYS.helpedSetupAndUseDevice],
      render: (props) => (
        <div>
          <div className='main-label'>
            Have you helped the patient set up the device and told patient how to use?
          </div>
          {renderFormItem(helpedSetupAndUseDevice(), props)}
        </div>
      ),
      parse: (values) => {
        const parsedAddress = parseAddress(values, keepOwnDevices, listOfAvailableDevices, selectedCuffSize);
        return {
          ...parsedAddress,
          helpedSetupAndUseDevice: confirmDeviceHelpers.parseRadioAnswer(values[KEYS.helpedSetupAndUseDevice]) 
        };
      }
    });
    reachLastStep = true;
  }

  return { map, reachLastStep };
};

let ConfirmDeviceComponent = props => {
  const { 
    form, 
    user, onSubmitDone, goBack, disabled, goNextStep,
    patientReferral, refetchPatientReferral,
    loadingIoTDevice, iotDeviceData, refetchIoTDeviceData, 
    loadingLoanDevice, loanDeviceData, refetchLoanDeviceData,addressChanged
  } = props;

  const { recommendedVitals, conditionsToMonnitor, deviceAccessbility, techLevel, devicePickup, deviceShip } = patientReferral || {};
  const { getFieldDecorator, getFieldValue, getFieldsValue, setFieldsValue, validateFieldsAndScroll } = form;
  const [stepIndex, _setStepIndex] = useState(confirmDeviceHelpers.getPreviousStepIndex(patientReferral));
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(confirmDeviceHelpers.getPreviousSelectedDeliveryType(patientReferral));
  const [selectedCuffSize, _setSelectedCuffSize] = useState(confirmDeviceHelpers.getCuffSize(patientReferral));
  const [showAddClear, setShowAddClear] = useState(false);
  const [showUpdateClear, setShowUpdateClear] = useState(false);
  const [showLoanerPhone, setShowLoanerPhone] = useState(false);
  const [showBP3LCuffSize, _setShowBP3LCuffSize] = useState(false);

  const selectedGiveoutDevices = getFieldValue(KEYS.giveoutDevices) ||
                                  confirmDeviceHelpers.getPreviousSelectedGiveoutDevices(patientReferral, listOfAvailableDevices, selectedDeliveryType, iotDeviceData, loanDeviceData);
  let hasRouter = !_.isNil(getFieldValue(KEYS.hasRouter)) ? getFieldValue(KEYS.hasRouter) : confirmDeviceHelpers.getHasRouter(patientReferral);


  const listOfAvailableDevices = useMemo(() => {
    const list = [];
    let { supportClear, supportLoanerPhone } = getSmartphoneOrgPermissions();
    const isBPEligible = _.includes(recommendedVitals, 'BP'); // || _.intersection(conditionsToMonnitor, BPConditions).length;

    // this is safe check, these conditions should already lead to NO eligible in Program eligibility
    if(techLevel === SMARTPHONE_SKILL_ENUM.NOT_ELIGIBLE || 
        (!deviceAccessbility && 
          (
            (!supportLoanerPhone && !supportClear) || // no supporting devices for any vitals
            (!supportLoanerPhone && !isBPEligible) || // support only Clear, but no BP eligible
            (techLevel === SMARTPHONE_SKILL_ENUM.SMART_PHONE_NOT_PREFERRED && !supportClear) // only option left is Clear, but not supported
          )
        )
      )
      return list;
    
    if(!deviceAccessbility && isBPEligible &&
        (!supportLoanerPhone || // must have supportClear
          techLevel === SMARTPHONE_SKILL_ENUM.SMART_PHONE_NOT_PREFERRED) // both supportLoanerPhone and supportClear are true
    ) {
      list.push(confirmDeviceConstants.CLEAR);
      return list;
    }

    _.forEach(_.intersection(VITALS_ORDER, recommendedVitals), vital => {
      const device = _.find(confirmDeviceConstants.DEVICES, { vital });
      if (device)
        list.push(device);
    });

    if(!deviceAccessbility && supportLoanerPhone) {
      list.push(confirmDeviceConstants.LOANER_PHONE);
    }
      
    return list;
  }, [recommendedVitals]);

  const hasClearInOptions = !!_.find(listOfAvailableDevices, { value: CLEAR_VALUE });

  const setStepIndex = (index) => {
    const isKeyString = typeof index === 'string'; // if key string, use map to get index; else, it's actual index
    const stepIndex = isKeyString ? STEP_INDEX_MAP[index] : index;
    _setStepIndex(stepIndex);
  };

  const setSelectedCuffSize = (cuffSize) => {
    setFieldsValue({ [KEYS.cuffSize]: cuffSize });
    return _setSelectedCuffSize(cuffSize);
  };

  const setShowBP3LCuffSize = (visible) => {
    if(visible) {
      const miniMap = confirmDeviceConstants.CUFF_SIZE[userDeviceRecordsTypeName.Ease];
      const cuffSize = selectedCuffSize || _.get(miniMap, '0.value');
      setSelectedCuffSize(cuffSize)
    }
    return _setShowBP3LCuffSize(visible);
  };

  const fnComponentProps = {
    ...props,
    listOfAvailableDevices,
    hasClearInOptions,
    hasRouter,
    selectedCuffSize,
    setStepIndex,
    selectedDeliveryType,
    setSelectedDeliveryType,
    setShowAddClear,
    setShowUpdateClear,
    setShowLoanerPhone,
    setShowBP3LCuffSize,
    setSelectedCuffSize
  };

  const { map: stepMap, reachLastStep } = buildStepMap({
    stepIndex, 
    listOfAvailableDevices, 
    selectedDeliveryType, 
    selectedGiveoutDevices,
    selectedCuffSize,
    hasClearInOptions,
    hasRouter
  });

  const validateStep = (customStepIndex = stepIndex) => {
    if (customStepIndex < 0 || customStepIndex > stepMap.length) {
      console.warn('warn validateStep: out of bound');
      return false;
    }

    const requiredFields = _.get(stepMap, `${stepIndex}.requiredFields`, []);
    return _.every(getFieldsValue(requiredFields), v => !_.isEmpty(v));
  };

  const shouldSetRemoteEnrollment = () => {
    const isPickup = selectedDeliveryType === DELIVERY_TYPE_ENUM.PICK_UP;
    const giveoutAll = listOfAvailableDevices.length === _.get(selectedGiveoutDevices, 'length', 0);
    return isPickup && giveoutAll;
  };
  const phoneFields = ['countryCode','areaCode','number','type','canUseForLogin','private'];
  const parseIdentification = (identification)=>{
    // [
    //   {
    //     "type": {
    //       "code": "SP",
    //       "description": "Singapore Pink Identification Card"
    //     },
    //     "value": "oct4",
    //     "MRN": true
    //   }
    // ]
    return identification.map(i=>{
      return {
        type: _.get(i,'type.code'),
        value: i.value
      }
    })
  }
  const parsePhone = (phones)=>{
    return phones.map(phone=>{
      return _.pick(phone,phoneFields);
    });
  }
  const editPatientAddress = async (user,parsed)=>{
    const parsedUser = omitDeep(user,'__typename');
    const newAddress = [{ ...parsed.shipAddresses }];
    parsedUser.address = newAddress;
    parsedUser.phone = parsePhone(user.phone||[]);
    parsedUser.identification = _.get(parseIdentification(user.identification|| [],'0',{ } ));
    editUserMutation(parsedUser);
  };
  const handleConfirm = () => {
    if (disabled) {
      return goNextStep();
    }
    
    let validatingFields = _.get(stepMap, `${stepIndex}.requiredFields`, []),
        nextStepIndex = stepIndex + 1;

    if (reachLastStep)
      validatingFields = [];

    validateFieldsAndScroll(validatingFields, async (error) => {
      if (error)
        return;

      const values = getFieldsValue();
      const stepParseFn = _.get(stepMap, `${stepIndex}.parse`);
      let parsed, variables = {
        patientId: _.get(user, 'id')
      };
      if(stepParseFn) 
        parsed = stepParseFn(values);

      const stepValidateFn = _.get(stepMap, `${stepIndex}.validate`);
      if(stepValidateFn) {
        const isValid = stepValidateFn(values, fnComponentProps);
        if(!isValid)
          return;
      }

      if(parsed) {
        variables = {
          ...variables,
          ...parsed
        };
      } else {
        console.warn('Warn handleConfirm, nothing to save. Please check if parse function is missing', stepIndex);
      }
        
      if (!reachLastStep) {
        await upsertPatientReferralMutation(variables);
        await refetchPatientReferral();
        return setStepIndex(nextStepIndex);
      }

      await upsertPatientReferralMutation({
        ...variables,
        status: {
          confirmDevicesEnroll: STATUS_ENUM.COMPLETED
        }
      });
      if (addressChanged) {
        await editPatientAddress(user,parsed);
      }

      if(shouldSetRemoteEnrollment()) {
        const { helpedSetupAndUseDevice } = variables;
        remoteEnrollmentApis.checkRemoteEnrollmentTaskHandler({
          memberId: _.get(user, 'id'),
          deviceDelivered: true,
          techOnBoard:helpedSetupAndUseDevice

        }).catch(console.error);
      }


      onSubmitDone();
    });
  };

  // only happen when it's pickup and device is assigned / unassigned
  const updateDevice = async (deviceName, isAssigned) => {
    const isPickup = selectedDeliveryType === DELIVERY_TYPE_ENUM.PICK_UP;
    if(!isPickup)
      return;

    const prevDevicePickup = devicePickup || [],
          isCLEAR = deviceName === userDeviceRecordsTypeName.TrackSet;
    let newDevicePickup = [...prevDevicePickup];
    let newDeviceShip;
    if(isAssigned) {
      newDevicePickup.push(deviceName);
      if(isCLEAR) {
        // check and remove previous hasRouter answer 
        // then add new answer
        newDevicePickup = _.filter(newDevicePickup, d => !_.includes([ROUTER_VALUE, HOTSPOT_VALUE], d));
        newDevicePickup.push(hasRouter === 'YES' ? ROUTER_VALUE : HOTSPOT_VALUE);
      }
      newDevicePickup = _.uniq(newDevicePickup);
        
      if(Array.isArray(deviceShip)) { 
        // make sure deviceShip doesn't have deviceName or hasRouter answer
        newDeviceShip = _.filter(deviceShip, d => d !== deviceName || !_.includes([ROUTER_VALUE, HOTSPOT_VALUE], d));
      }
    } else {
      // remove device
      newDevicePickup = _.filter(newDevicePickup, d => d !== deviceName);
      // remove cuff size selection if device is CLEAR AND is unassigned
      if(isCLEAR && selectedCuffSize) {
        newDevicePickup = _.filter(newDevicePickup, d => d !== selectedCuffSize);
        setSelectedCuffSize(null);
      }
      // add deviceShip when Confirm is clicked ONLY
    }

    const variables = {
      patientId: _.get(user, 'id'),
      devicePickup: newDevicePickup,
      deviceShip: newDeviceShip
    };
    await upsertPatientReferralMutation(variables);
    await refetchPatientReferral();
  };

  const closeDevicePopup = (compareValue, closeFn) => {
    let currentCheckedValue = [...selectedGiveoutDevices];
    if(_.includes(currentCheckedValue, compareValue)) {
      // cancel assigning
      currentCheckedValue = _.filter(currentCheckedValue, v => v !== compareValue);
    } else {
      // cancel unassigning
      currentCheckedValue.push(compareValue);
    }
    setFieldsValue({ [KEYS.giveoutDevices]: currentCheckedValue });
    closeFn();
  };

  return (
    <ReferAndEnrollForm
      header=''
      onSubmit={console.log}
      buttons={<div>
        <Button onClick={goBack}>Go back</Button>
      </div>}
    >
      <div id='refer-enroll-confirm-devices'>
        {(loadingIoTDevice || loadingLoanDevice) && loadingMessage}
        {stepMap.map((step, idx) => (
          <React.Fragment key={idx}>
            { step.render(fnComponentProps) }
          </React.Fragment>
        ))
        }
        {
          <Button
            type='primary'
            className='confirm-button'
            onClick={handleConfirm}
            disabled={!validateStep(stepIndex + 1)}
          >
            Confirm
          </Button>
        }
        { 
          (showAddClear || showUpdateClear) && 
          <ClearAssignmentComponent 
            isUpdate={!!showUpdateClear}
            user={user} 
            iotDeviceData={iotDeviceData} 
            onAssign={res => {
              updateDevice(CLEAR_VALUE, true);
              refetchIoTDeviceData();
              setShowAddClear(false);
            }}
            onUnassign={res => {
              updateDevice(CLEAR_VALUE, false);
              refetchIoTDeviceData();
              setShowAddClear(false);
            }}
            onUpdate={res => {
              refetchIoTDeviceData();
              setShowUpdateClear(false);
            }}
            onCancel={() => {
              return showAddClear ? 
                      closeDevicePopup(CLEAR_VALUE, () => setShowAddClear(false)) : 
                      setShowUpdateClear(false);
            }}
          /> 
        }
        { 
          showLoanerPhone && 
          <PhoneAssignmentComponent 
            user={user} 
            loanDeviceData={loanDeviceData} 
            onAssign={res => {
              updateDevice(PHONE_VALUE, true);
              refetchLoanDeviceData();
              setShowLoanerPhone(false);
            }}
            onUnassign={res => {
              updateDevice(PHONE_VALUE, false);
              refetchLoanDeviceData();
              setShowLoanerPhone(false);
            }}
            onCancel={() => closeDevicePopup(PHONE_VALUE, () => setShowLoanerPhone(false))}
          /> 
        }
        {
          !hasClearInOptions && 
          <Modal
            visible={showBP3LCuffSize}
            className='confirm-devices-ease-cuff-size-modal'
            width={375}
            maskClosable={false}
            closable={false}
            footer={null}
          >
            <div className='confirm-devices-ease-cuff-size'>
              <div className='title'>
                Choose the cuff size for patient
              </div>
              {
                getFieldDecorator(KEYS.cuffSize, {
                  initialValue: null, // should be always null initial until visible
                  rules: [{ required: true, message: 'Please select an option' }]
                })(<Radio.Group 
                  options={confirmDeviceConstants.CUFF_SIZE[userDeviceRecordsTypeName.Ease]} 
                  disabled={disabled} 
                />)
              }
              <Button
                className='save-btn'
                type='primary'
                onClick={async () => {
                  setShowBP3LCuffSize(false);
                  const newSelectedCuffSize = getFieldValue(KEYS.cuffSize);
                  setSelectedCuffSize(newSelectedCuffSize);
                  const parsed = parseGiveoutDevices(getFieldsValue(), listOfAvailableDevices, newSelectedCuffSize);
                  const variables = {
                    patientId: _.get(user, 'id'),
                    ...parsed // devicePickup, deviceShip
                  };
                  await upsertPatientReferralMutation(variables);
                  refetchPatientReferral();
                }}
              >
                Save
              </Button>
            </div>
          </Modal>
        }        
      </div>
    </ReferAndEnrollForm>
  )
};

ConfirmDeviceComponent.propTypes = {
  user: PropTypes.object.isRequired,
  patientReferral: PropTypes.shape({
    shipAddresses: PropTypes.object,
    devicePickup: PropTypes.array,
    deviceShip: PropTypes.array,
    helpedSetupAndUseDevice: PropTypes.bool
  }),
};

const getIoTDeviceListData = graphql(iotDeviceList, {
  options: ownProps => {
    const memberId = _.get(ownProps, 'user.id');
    return {
      variables: { memberId },
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    };
  },
  props: ({ data }) => {
    const iotDeviceList = getClearDeviceList(_.get(data, 'iotDeviceList.data', []));
    return {
      iotDeviceData: iotDeviceList,
      refetchIoTDeviceData: data.refetch,
      loadingIoTDevice: data.loading
    };
  }
});

const getLoanDeviceData = graphql(getLoanDevice, {
  options: ownProps => {
    const memberId = _.get(ownProps, 'user.id');
    return {
      variables: { memberId },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const getLoanDevice = _.get(data, 'getLoanDevice', null);
    return {
      loanDeviceData: getLoanDevice,
      refetchLoanDeviceData: data.refetch,
      loadingLoanDevice: data.loading
    };
  }
});

const loadingMessage = (
  <div className='ant-message'><span><div className='ant-message-notice'><div className='ant-message-notice-content'><div className='ant-message-custom-content ant-message-loading'><i aria-label='icon: loading' className='anticon anticon-loading'><svg viewBox='0 0 1024 1024' focusable='false' className='anticon-spin' data-icon='loading' width='1em' height='1em' fill='currentColor' aria-hidden='true'><path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z'></path></svg></i><span> </span></div></div></div></span></div>
);
ConfirmDeviceComponent = Form.create({
  onFieldsChange:(props,changedFields)=>{
    const changedAddress = ()=>{
      const addressFields = ['streetName', 'unit', 'city', 'state', 'postCode'];
      const changedKeys = Object.keys(changedFields);
      return _.intersection(addressFields,changedKeys).length > 0;
    }
   const { addressChanged,setAddressChanged } = props;
   if(!addressChanged && changedAddress()) {
     setAddressChanged(true)
   }
  }
})(
    compose(
        getIoTDeviceListData,
        getLoanDeviceData
    )(ConfirmDeviceComponent)
);
export default ConfirmDeviceComponent = compose( withState('addressChanged', 'setAddressChanged',false) )(ConfirmDeviceComponent);

