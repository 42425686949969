import gql from 'graphql-tag';


export default gql`
     query epchangelog($filter:EPChangeLogListOpts,$page:Int,$count:Int){
  epChangeLogList(filters:$filter,page:$page,count:$count){
    data{
      id
      updatedAt
      enrolledProgramId
      change{
        careCoordination
        followupSchedule
        assessments
        manualInputEnable
        goals
        interventions
        resource
        problemList
        histories{
            action
        }
        tasks{
            id
        }
      }
      modifiedByUser{
        profile{
          fullName
        }
      }
      
    },
    pageInfo{
      total
      lastPage
      
    }
  }
}
`
