import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import './ArticleLinkPreviewComponent.scss';

const ArticleLinkPreviewComponent = (articleMetaData) => {
  const {
    url,
    name,
    summary,
    thumbnailImageUrl,
  } = articleMetaData || {};

  return (
    <div className="article-link-preview-component">
      <Card
        hoverable
        cover={thumbnailImageUrl ? (
          <img
            alt="thumbnailImageUrl"
            src={thumbnailImageUrl}
          />
        ) : null}
        onClick={(e) => {
          if (!url) return;
          e.preventDefault();
          window.open(url);
        }}
      >
        <Card.Meta
          title={name}
          description={summary}
        />
      </Card>
    </div>
  );
};

ArticleLinkPreviewComponent.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  thumbnailImageUrl: PropTypes.string,
};
export default ArticleLinkPreviewComponent;