import types from 'patientModule/enrolledProgramInfo/Careplan/constants/VitalFormConstants'
import { commonReducer } from 'libModule/helpers/reducer-helpers'

const initState = {
  isEditMode: false,
  vitalList: []
}

const ownReducer = (state = initState, action) => {
  switch(action.type) {
    case types.RESET_FORM:
      return initState
    case types.SET_VITAL_LIST_FORM:
      console.log('Redux Vital', action.vitalList);
      return {
        ...state,
        vitalList: action.vitalList
      }  
    default:
      return state
  }
}

export default (state = initState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
