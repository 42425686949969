import gql from 'graphql-tag';

export default gql`query getLoanDevice($memberId:EID){
    getLoanDevice(memberId:$memberId){
        deviceId
        serialNumber
        osVersion
        appVersion
        status
        member {
            profile {
                firstName
                lastName
            }
        }
        history {
          updatedAt
          updatedBy {
            id
          }
        }
    }
}`
