import ProgramMeasureTrendsComponent from './ProgramMeasureTrendsComponent';
import React, { Component } from 'react'
import moment from "moment/moment";

const monthMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

class WeeklyTrendsComponent extends Component{

    parseWeeklyData(){
        let inputData = this.props.programMeasureTrends;
        return inputData.map(function(v){
            let tmp =_.pick(v.conditionCounts,['hyperCrisis','hyperStage2','hyperStage1','elevated','normal']);
            let timeStamp = new Date(parseInt(v.group));
            let firstDayOfWeekStr = moment(timeStamp).startOf('week').toDate().toString();
            const formattedFirstDayOfWeek= firstDayOfWeekStr.split(' ')[1]+ firstDayOfWeekStr.split(' ')[2];
            const xKeyStr = `${formattedFirstDayOfWeek}`;
            tmp.Week = xKeyStr;
            tmp.total = v.total;
            return tmp;

        })
    }

    getxAxisKeys(){

        let inputData = this.props.programMeasureTrends;
        let xWeeks = [];
        inputData.forEach(function(v){
            let timeStamp = new Date(parseInt(v.group));
            let firstDayOfWeekStr = moment(timeStamp).startOf('week').toDate().toString();
            const formattedFirstDayOfWeek= firstDayOfWeekStr.split(' ')[1]+ firstDayOfWeekStr.split(' ')[2];
            const xKeyStr = `${formattedFirstDayOfWeek}`;
            xWeeks.push(xKeyStr);
        });
        return xWeeks;
    }

    getChartTitle(){
        let parsedData = this.props.programMeasureTrends;

        let startDate = new Date(parseInt(parsedData[0].group));
        let firstDayOfWeek = moment(startDate).startOf('week').toDate();
        let startDay = firstDayOfWeek.getDate();
        let startMonth = monthMap[firstDayOfWeek.getMonth()];
        let startYear = firstDayOfWeek.getFullYear();

        let endDate = new Date(parseInt(parsedData[parsedData.length - 1].group));
        let lastDayOfWeek = moment(endDate).endOf('week').toDate();
        let endDay = lastDayOfWeek.getDate();
        let endMonth = monthMap[lastDayOfWeek.getMonth()];
        let endYear = lastDayOfWeek.getFullYear();

        return `${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay} ${ endYear }`;
    }

    render(){
        return(
                <ProgramMeasureTrendsComponent
                    chartTitle = { this.getChartTitle(this.props.chartTitle) }
                    parsedData = { this.parseWeeklyData(this.props.programMeasureTrends) }
                    xAxisKeys = { this.getxAxisKeys(this.props.programMeasureTrends) }
                    width={ this.props.width }
                    height={ this.props.height }
                    marginTop={ this.props.marginTop }
                    marginRight={ this.props.marginRight }
                    marginBottom = { this.props.marginBottom }
                    marginLeft = { this.props.marginLeft }
                />
        )
    }
}

export default WeeklyTrendsComponent;
