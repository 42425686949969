import React  from 'react';
import { Checkbox,Row,Col,message } from "antd";
import helper from '../helper/index'
import moment from 'moment-timezone';
import SnoozePopover from './SnoozePopover';
const SmartAlertResolveCardComponent = class extends React.Component{
        onCheck = (alert,e)=>{
           const checked = e.target.checked;
           const { setCheckedAlerts } = this.props;
            setCheckedAlerts(alert.id,checked);
        }

        handleConfirmSnooze = async (snoozeValue, startDate) => {
            const { alert } = this.props;
            await this.props.onConfirm(alert, snoozeValue, startDate);
            message.success('Alert snoozed!');
        }

        handleUnsnooze = () => {
            const { config } = this.props;
            this.props.onUnsnooze(config);
            message.success('Alert unsnoozed!');
        }

        renderCheckAndType = (alert)=>{
            const { checked, isSnoozed } = this.props;
            return <Row className={'alertCheckAndType'}>
                    {!isSnoozed && <Col span={3} className={'checkboxDiv'}>
                        <Checkbox onChange={ (e)=>this.onCheck(alert,e) } checked={checked}/>
                    </Col>}
                    <Col span={21}>
                        { helper.renderSmartAlert(alert)}
                    </Col>
                   </Row>
        }

        renderTriggeredDate=(alert)=>{
            return <Row className={'triggerDate'}>
                        <Col span={12} className={'title'}>Triggered On:</Col>
                        <Col span={12} className={'date'}>{moment(alert.createdAt).format('hh:mm a, MM/DD/YYYY')}</Col>
                   </Row>
        }

        renderDueInDate = (alert) => {
            const { createdAt, replyTime } = alert;
            const time = moment(createdAt + replyTime).fromNow().replace('in ', '');
            return (
                <Row className={'triggerDate'}>
                    <Col span={12} className={'title'}>Due in:</Col>
                    <Col span={12} className={'date'}>{time}</Col>
                </Row>
            );
        }

        renderNotes = (alert) =>{
            const { providerNotes = [] } = alert;
            return <div>
                {
                    _.map(providerNotes,pn=>{
                        const { createdBy,createdAt,content } = pn;
                        const {  fullName,avatar } = createdBy;
                        return <Row className={'alertProviderNoteWrapper'}>
                                <Col span={4}> {helper.renderAvatar(avatar)} </Col>
                                <Col span={20} className={'header'}>
                                    <Row>
                                        <span className={'name'}>{fullName}</span>
                                        <span className={'date'}>{moment(createdAt).format('hh:mm a, MM/DD/YYYY')}</span>
                                    </Row>
                                    <Row>
                                        <span className={'note'}>{content}</span>
                                    </Row>
                                </Col>
                               </Row>
                    })
                }
            </div>
        }

        renderAlertSnooze = (config) => {
            return (
                <div>
                    Snooze time: {config.snoozeInterval} (Starts from {moment(config.startDate).tz(moment.tz.guess()).format('MM/DD/YY')}) &nbsp;
                    <SnoozePopover onConfirm={this.handleConfirmSnooze} value={config.snoozeInterval} showUnsnooze onUnsnooze={this.handleUnsnooze}>
                        <a>Edit</a>
                    </SnoozePopover>
                </div>
            );
        }

        renderAlertReason = (alert)=>{
            const reason = helper.renderAlertReason(alert);
            if(!reason) return '';
            return <Row className={'alertReason'}>
                     <Col className={'title'}>Alert Reason:</Col>
                     <Col className={'reason'}>{ reason }</Col>
                   </Row>
        }

        render() {
            const { alert, shouldRenderDueIn, config } = this.props;
            if(!alert.isValid) {
                return <div className={'smartResolveCard'}>
                    {this.renderCheckAndType(alert)}
                    {this.renderTriggeredDate(alert)}
                    {shouldRenderDueIn && this.renderDueInDate(alert)}
                    {this.renderAlertReason(alert)}
                    {this.renderNotes(alert)}
                </div>
            } return <div className={'smartResolveCard'}>
                {this.renderCheckAndType(alert)}
                {config && config.isValid && this.renderAlertSnooze(config)}
            </div>
        }
}

export default SmartAlertResolveCardComponent;
