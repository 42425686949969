import React from 'react';
import I18 from '../../../modules/I18N';
import {Button, Icon,Collapse} from 'antd';
import '../style/index.scss';
import {Checkbox} from "antd/es";
const { Panel } = Collapse;

const NO_SHOW_KEY = 'stop-mtprnotice';

const MonthlyReviewNoticeComponent = class extends React.Component{
	constructor(props) {
		super(props);
    const stopShowMTPRNotice = !!localStorage.getItem(NO_SHOW_KEY);
		const type = this.getType(props);
		this.state={
      typeText: I18.get(`monthlyReviewPlan.typeText.${type}`),
      stopShowMTPRNotice,
			noShowNextTime: false,
			activeKeys: stopShowMTPRNotice ? [] : ['1']
		}
	}
	getType = (props) => {
		const { isMRE, type: _type } = props || this.props;
		let type;
		if (isMRE) {
			type = 'MRE';
			if (_type === 'MRE_ADDITIONAL') {
				type = 'MR';
			}
		} else {
			type = 'MTPR';
		}
		return type;
	}

	renderHeader = ()=>{
		const type = this.getType();
		const key = `monthlyReviewPlan.${type}.header`;
		const header = I18.get(key) !== key ? I18.get(key) : I18.get('monthlyReviewPlan.header').replace('XXX', this.state.typeText);
		return <div className={'headerWrapper'}>
				<Icon type="info-circle" style={{ color: '#3177C9'}}/>
				<span className={'header'}>{header}</span>
			  </div>
	}
	renderBody = ()=>{
		const type = this.getType();
		const contentBottomKey = `monthlyReviewPlan.${type}.contentBottom`;
		const contentBottom = I18.get(contentBottomKey) === contentBottomKey ? '' : I18.get(contentBottomKey);
		return <div className={'bodyWrapper'}>
				<p>{I18.get(`monthlyReviewPlan.${type}.contentTop`)}</p>
				<p>{ type == 'MTPR' && I18.get(`monthlyReviewPlan.${type}.contentMid`)}</p>
				{contentBottom && <p>{contentBottom}</p>}
			   </div>
	}

	renderCheckbox = ()=>{
		return <div>
				<Checkbox onChange={(e)=>this.setState({
					noShowNextTime:e.target.checked
					})}>{I18.get('monthlyReviewPlan.checkboxText')}
				</Checkbox>
			   </div>
	}

	handleBtn = ()=>{
    if(this.state.noShowNextTime)
		  localStorage.setItem(NO_SHOW_KEY, true + '');

		this.setState(prevState => ({
			activeKeys:[],
      stopShowMTPRNotice: prevState.noShowNextTime
		}));
	}

	renderBtn = ()=>{
		const btnText = this.state.stopShowMTPRNotice? "OK" : "Got it";
		return <div className={'btnWrapper'}>
				<Button className={'button'} onClick={ this.handleBtn }>{btnText}</Button>
			   </div>
	}

	onClickCollapseHeader = ()=>{
		const { activeKeys } = this.state;
		if(!activeKeys.length){
			this.setState({
				activeKeys:['1']
			});
		}
	}

	renderCollapse = (div)=>{
		const { activeKeys } = this.state;
		const header = <span onClick={ this.onClickCollapseHeader }>
      {I18.get('monthlyReviewPlan.collapseText').replace('XXX', this.state.typeText)}
    </span>
		return <Collapse defaultActiveKey={activeKeys} className={'monthlyNoticeCollapse'} expandIconPosition={'right'} activeKey={activeKeys}>
			<Panel header={ header } key="1" showArrow={false}>
					{div}
			</Panel>
		</Collapse>
	}
	render() {
		const { renderHeader,renderBody,renderCheckbox,renderBtn,renderCollapse } = this;
		const div = <div className={`monthlyNoticeWrapper expandable`}>
								{ renderHeader()}
			 					{ renderBody() }
			 					{ !this.state.stopShowMTPRNotice && renderCheckbox() }
			 					{ renderBtn() }
	                </div>
		return renderCollapse(div);

	}

}
export default MonthlyReviewNoticeComponent;