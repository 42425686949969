/*
  This Component will render an HTML hyperlink button <a href>, using the signedUrl.
  GoThumb's 15min signedUrl validity has been handled.
  signedUrl will only be fetch if empty or expired.

  Trigger to fetch: on click
*/

import PropTypes from 'prop-types';

import React from 'react';
import config from 'libModule/config'
import { _ } from 'ihcomponent'
import { createAuditLog } from 'libModule/utils/auditLog'
import RequestCache from 'libModule/requestCache'

class GoThumbButtonComponent extends React.Component {
  static displayName = 'GoThumbButtonComponent'

  static cache = {}

  request(url) {
    GoThumbButtonComponent.cache[url] = { timestamp: 0, errorMessage: '' }

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    const myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    return fetch(`${config.signFileURL}/${url}`, myInit)
      .then(response =>
        response.text()
      )
      .then(signedUrl => {
        GoThumbButtonComponent.cache[url] = { timestamp: Date.now(), signedUrl}

        const originUrl = this.props.filename
        const cacheItem = GoThumbButtonComponent.cache[originUrl]
        const buttonElement = document.createElement('a');
        buttonElement.setAttribute("href", cacheItem.signedUrl)
        buttonElement.setAttribute("target", "_blank")
        buttonElement.style.display = 'none'
        document.body.appendChild(buttonElement);
        buttonElement.click();
        buttonElement.remove();
      })
      .catch((error) => {
        GoThumbButtonComponent.cache[url] = { timestamp: Date.now(), signedUrl: '', errorMessage: error}
      });
  }

  handleOnClick = () => {
    const originUrl = this.props.filename
    this.request(originUrl).then(
      this.handleAuditLog
    )
  }

  handleAuditLog = () => {
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    createAuditLog({
      action: this.props.action,
      patientNRIC,
      patientName,
      details: { programName: this.props.programName }
    })
  }

  render() {

    const defaultStyle = {
      float: 'right',
      marginBottom: '5px'
    }
    const style = this.props.style ? this.props.style : defaultStyle

    return (
      <a className="IH-Button btn btn-primary"
      style={style} onClick={this.handleOnClick} >{this.props.buttonLabel}</a>
    )
  }
}

GoThumbButtonComponent.propTypes = {
  filename: PropTypes.string,
  buttonLabel: PropTypes.string,
  programName: PropTypes.string,
  action: PropTypes.string,
  style: PropTypes.object
}

export default GoThumbButtonComponent
