import { TreeSelect} from 'antd';
import React from 'react';
import Client from 'libModule/gqlClient';
import createProviderNote from '../../graphql/createProviderNote';
import '../style/index.scss';
const { TreeNode } = TreeSelect

const sortMembersByName = (members, order = 'ASC') => {
  let sorted = _.sortBy(_.cloneDeep(members), (m) => {
    const { lastName } = _.get(m, 'user.profile') || {};
    return _.toLower(lastName);
  });

  if (order === 'DESC') {
    sorted = _.reverse(sorted);
  }

  return sorted;
}

const getAllAssignedTo = (team)=>{
    const members = [];
    const currentUserId = sessionStorage.getItem('currentUserId');
    let currentUser = null;
    _.forEach(team,t=>_.forEach(t.members,m=>{
       if(currentUserId!=m.user.id) {
           members.push(m);
       }else{
           currentUser = m;
       }
    }));
    const sortedMembers = sortMembersByName(members);
    if(currentUser) sortedMembers.unshift(currentUser);
    return sortedMembers;
}
const getMembersByRole = (team,roles)=>{
    let members = [];
    _.forEach(team,t=>_.forEach(t.members,({user})=>{
        const currentRole = _.first(_.get(user,'allRoles')) || {};
        const currentRoleName = currentRole.name || '';
        if(_.includes(roles,currentRoleName)) {
            if(_.findIndex(members,m=>m.id==user.id)===-1) {
                members.push({
                    id: user.id,
                    fullName: user.profile.fullName
                });
            }
        }
    }));
    return members;
}

// const getTeamMembersByRole = (teams,roles)=>{
//   const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
//   const currentOrgId =  _.get(currentUser,'selectedRole.organization.id','');
//   let members = [];
//   _.forEach(teams, t => {
//     _.forEach(t.members,({ user }) => {
//       const { id, allRoles, profile, capacitys } = user || {};
//       const { fullName = '--', orgAssignedPatientCount, assignedPatientCount = 0 } = profile || {};
//       const selectedUserRole = _.find(allRoles, d => _.get(d, 'organization.id') === currentOrgId) || {};
//       const selectedRole = ROLE_MAP[selectedUserRole.name];
//       const capacity = (_.find(capacitys, { role: selectedRole }) || {}).capacity || ROLE_DEFAULT_CAPACITY[selectedRole] || 0;
//       const occupancyRate = (capacity ? (assignedPatientCount / capacity) : 0) * 100; 
//       if(_.includes(roles, selectedRole) && _.findIndex(members, { id }) === -1) {
//         members.push({
//           id,
//           fullName,
//           orgAssignedPatientCount: orgAssignedPatientCount || 0,
//           providerLanguage: getProviderLanguage(user),
//           occupancyRate: Math.round((occupancyRate + Number.EPSILON) * 100) / 100,
//           selectedRole
//         });
//       }
//     })
//   });
//   return members;
// }
const renderGroup = (options,title)=>{
    return <TreeNode title={title} selectable={false} checkable={false} disabled={true} value={title}>
        { options.map(({user})=>{
            let key = _.get(user,'id');
            let value = _.get(user,'profile.fullName');
            return <TreeNode key={key} title={value} value={key} />
        })}
    </TreeNode>
}
const parseAssignees = (assignees) =>{
    if( _.isEmpty(assignees)) {
        return [];
    }
    const { assignedToRD = { },assignedToCA={ } } = assignees || { };
    const assignedToRDId = _.get(assignedToRD,'id');
    const assignedToCAId = _.get(assignedToCA,'id');
    const assignedToRDName = _.get(assignedToRD,'profile.fullName');
    const assignedToCAName = _.get(assignedToCA,'profile.fullName');
    const arr = [];
    if (assignedToCAId) {
       arr.push(
           {
           user:{
               id:assignedToCAId,
               profile:{
                   fullName:assignedToCAName
               }
           }
       })
    }
    if (assignedToRDId) {
        arr.push(
            {
            user:{
                id:assignedToRDId,
                profile:{
                    fullName:assignedToRDName
                }
            }
        })
    }
    return arr;
}
const removeAssigneeFromMembers = (members,assignees={})=>{
    const { assignedToRD = { },assignedToCA={ } } = assignees || { };
    const assignedToRDId = _.get(assignedToRD,'id');
    const assignedToCAId = _.get(assignedToCA,'id');
    return members.filter( m=>{
        let id = _.get(m,'user.id');
        return id!=assignedToRDId && id!=assignedToCAId;
    });
}
const assignedToDropDown = (enrolledPrograms,team=[],disabled, onChange,assignees={})=>{
    const parsedAssignees = parseAssignees(assignees);
    const inputTeam = _.get(_.first(enrolledPrograms),'team',null) ? [ _.get(_.first(enrolledPrograms),'team',null)] : team;
    let members = getAllAssignedTo(inputTeam);
    members = _.uniqBy(removeAssigneeFromMembers(members,assignees),m=>m.user&&m.user.id);
    return  (
      <TreeSelect 
        style={{ width: 200 }} 
        treeDefaultExpandAll={true} 
        dropdownClassName={'assigneeDropdown'}
        showSearch
        filterTreeNode={(inputValue, node) => {
          const { title } = _.get(node, 'props');
          return title.search(new RegExp(inputValue, 'i')) >= 0;
        }}
      >
          { renderGroup(parsedAssignees,'Assigneed team members')}
          { renderGroup(members,'Others')}
      </TreeSelect>
    );
    // return <Select
    //     showSearch
    //     style={{ width: 200 }}
    //     placeholder="Select a person"
    //     optionFilterProp="children"
    //     disabled={disabled}
    //     onChange={onChange}
    // >
    //     {
    //         _.map(members,(m,i)=>{
    //             const { user } = m;
    //             const { id, profile:{fullName}} = user;
    //             return <Option value={id} key={i}>{fullName}</Option>
    //         })
    //     }
    // </Select>
}
let shouldShowTaskAssignment = {
    'CA':'CA',
    'RD':'RD',
    'Doctor':'Doctor',
    'MA':'MA',
    'HC': 'HC'
}
const createPN = (variables,refetchVariables)=>{
    let category = 'TASK_NOTES';
    variables.category = category;
    return Client.mutate({
        mutation:createProviderNote,
        variables,
    })
}

const checkIsRead = (taskAssignments) => {
    let isRead = true
    _.forEach(taskAssignments, (taskAssignment) => {
        if(!taskAssignment.isRead || taskAssignment.isRead.value === false) {
            isRead = isRead && false;
        } else {
            isRead = isRead && true
        }
    })
    return isRead
}

const checkNeedFold = (taskAssignment) => {
    const curLogin = JSON.parse(sessionStorage.getItem('currentUser')).id;
    const curAssignedTo = _.get(taskAssignment, 'assignedTo.id');
    const taskIsRead = _.get(taskAssignment, 'isRead');
    const isRead = (taskIsRead && taskIsRead.value) ? true : false
    const isCur = curLogin === curAssignedTo
    return (isCur && !isRead) ? true : false;
}

const columnTypeWidth = {
  'createdBy': {'0': 120, '1': 0, '2': 120},
  'assignedTo': {'0': 0, '1': 120, '2': 120}
}

const checkWidth = (taskType, id) => {
  const field = _.get(columnTypeWidth, id);
  return _.get(field, taskType);
}

const getRoleInCurClinic = (enrolledPrograms,team=[], orgId) => {
    const inputTeam = _.get(_.first(enrolledPrograms),'team',null) ? [ _.get(_.first(enrolledPrograms),'team',null)] : team;
    let members = getAllAssignedTo(inputTeam);
    members = _.uniqBy(members,m=>m.user&&m.user.id);
    let curRoleArr = [];
    _.forEach(members, (member) => {
        let curId = _.get(member, 'user.id');
        let roleArr = _.get(member, 'user.allRoles');
        _.forEach(roleArr, (role) => {
            let id = _.get(role, 'organization.id');
            let name = _.get(role, 'name');
            if(id === orgId) {
                curRoleArr.push({id: curId, name})
            }
        })
    })
    return curRoleArr
}

const checkIsProvider = (roleArr, curId) => {
    let isProvider = false;
    _.find(roleArr, (role) => {
        if(role.id === curId) {
            isProvider = isProvider || (role.name === 'Doctor') 
        }
    })
    return isProvider
}

export default {
    assignedToDropDown,
    createPN,
    shouldShowTaskAssignment,
    getAllAssignedTo,
    getMembersByRole,
    checkNeedFold,
    checkIsRead,
    checkWidth,
    getRoleInCurClinic,
    checkIsProvider,
    // getTeamMembersByRole
}
