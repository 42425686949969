import React, { forwardRef } from 'react';
import { Checkbox } from 'antd';

export const CellularDeviceCheckboxComponent = forwardRef(({
  label,
  onChange,
  ...antdProps
}, ref) => {

  const handleOnChange = (
    e,
  ) => {
    const value = e.target.checked
    if (onChange) onChange(value);
  }

  return (
    <Checkbox
      ref={ref}
      {...antdProps}
      onChange={handleOnChange}
    >
      {label}
    </Checkbox>
  );
});
