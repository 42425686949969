import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { convertCelsiusToFahrenheit } from 'libModule/utils/convertUnits';
import visitsWithTemp from 'modulesAll/graphql/visitsWithTemp';
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import moment from 'moment-timezone';
import { IHLoading } from 'ihcomponent';
import { CHART_SEVERITY, RESULT_COUNT_MAX, VISIT_COUNT_MAX } from "../../../lib/constants";

const TMAreaChartComponent = class extends React.Component {
    static displayName = 'charts/components/TMAreaChartComponent';
    constructor(props){
        super(props);
    }

    groupDataBySeverity = (array,{severity})=>{
        array.sort((a1,a2)=>(a1.appointmentAt - a2.appointmentAt));

        //const filteredArray = _.filter(array,(a)=>_.isArray(severity) ? _.includes(severity,a.severity) : a.severity==severity);
        const data  = [];
        array.forEach(v=>{
            const { appointmentAt, details }  = v;
            const notes = _.get(v, 'measure.user_notes', '--');
            if(details.temperature){
                const value = details.temperature.value;
                const unit = details.temperature.unit;
                const showVal = unit === 'C' ? Number(convertCelsiusToFahrenheit( value).toFixed(1)) : Number(value.toFixed(1));
                const severity = (showVal < 95 || showVal > 100.04)? 'CRITICAL': (showVal >= 97 &&  showVal<= 99) ? 'NORMAL': 'RISK';

                const marker =  {
                    enabled:true,
                    symbol:'triangle',
                    halo:null,
                    states: {
                        hover: {
                            lineWidth: 0,
                            halo: null,
                            animation:{
                                duration: 20
                            }
                        }
                    },
                    radius:4,
                    fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),
                    lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),

                };
                data.push({
                    x:moment(appointmentAt).startOf('day').valueOf(),
                    y: showVal, // remove the convertor to show original critical readings
                    severity,
                    marker,
                    notes
                })
            }

        })
        return data;
    }

    groupAllData = (visitList, resultList,{severity})=>{

        let uniqueVitalListByDate = _.filter(visitList,
            (v1)=>(v1.body_temperature!=null)||v1.details);
        let array = uniqueVitalListByDate.concat(_.filter(resultList,(v2)=>(v2.body_temperature!=null)||v2.details));
        array.sort((a1,a2)=>(a1.date|| a1.appointmentAt) -(a2.date || a2.appointmentAt));

        //const filteredArray = _.filter(array,(a)=>_.isArray(severity) ? _.includes(severity,a.severity) : a.severity==severity);
        const data  = [];
        array.forEach(v=>{
            const { appointmentAt, date, details, body_temperature, severity }  = v;
            const notes = _.get(v, 'measure.user_notes', '--');
            if(body_temperature){
                const value = body_temperature.value;
                const unit =body_temperature.unit;
                const showVal = unit === 'C' ? Number(convertCelsiusToFahrenheit( value).toFixed(1)) : Number(value.toFixed(1));
                const marker =  {
                    enabled:true,
                    symbol:'triangle',
                    halo:null,
                    states: {
                        hover: {
                            lineWidth: 0,
                            halo: null,
                            animation:{
                                duration: 20
                            }
                        }
                    },
                    radius:4,
                    fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),
                    lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),

                };
                data.push({
                    x: moment(date).startOf('day').valueOf(),
                    y: showVal, // remove the convertor to show original critical readings
                    severity,
                    marker,
                    notes
                })


            }else if(details.temperature){
                const value = details.temperature.value;
                const unit = details.temperature.unit;
                const showVal = unit === 'C' ? Number(convertCelsiusToFahrenheit( value).toFixed(1)) : Number(value.toFixed(1));
                const severity = (showVal < 95 || showVal > 100.04)? 'CRITICAL': (showVal >= 97 &&  showVal<= 99) ? 'NORMAL': 'RISK';

                const marker =  {
                    enabled:true,
                    symbol:'triangle',
                    halo:null,
                    states: {
                        hover: {
                            lineWidth: 0,
                            halo: null,
                            animation:{
                                duration: 20
                            }
                        }
                    },
                    radius:4,
                    fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),
                    lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :'#7bd002'),

                };
                data.push({
                    x: moment(appointmentAt).startOf('day').valueOf(),
                    y: showVal, // remove the convertor to show original critical readings
                    severity,
                    marker,
                    notes
                })
            }


        })
        return data;
    }

    generateSeriesInfo = (visitList, resultList, fromDate, allVisit)=>{
        const seriesMap = {

            'NORMAL':{
                name: 'Normal',
                severity:'NORMAL',
                lineWidth: 0,
                color:'#7bd002',
                lineColor: '#7bd002',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'triangle',
                },

                fn: allVisit? this.groupAllData: this.groupDataBySeverity
            },


            'CRITICAL':{
                name:'Critical',
                severity:'CRITICAL',
                lineWidth: 0,
                lineColor:'#EE6161',
                color:'#EE6161',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'triangle'
                },
                fn: allVisit? this.groupAllData: this.groupDataBySeverity
            },
            'RISK':{
                name:'Risk',
                severity:'RISK',
                lineWidth: 0,
                lineColor:'#FFBD03',
                color:'#FFBD03',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol:'triangle'
                },

                fn: allVisit? this.groupAllData: this.groupDataBySeverity
            },
        }
        const seriesConfig = [];
        for (let [k, v] of Object.entries(seriesMap)) {
            const { type,name,lineWidth,states,marker,data,fn,severity,visible,lineColor,color,tooltip,events={},symbol } = v;
            seriesConfig.push({
                type,
                name,
                lineWidth,
                states,
                marker,
                findNearestPointBy:'xy',
                data:allVisit?fn(visitList, resultList, {severity}) :fn(visitList,{severity}),
                visible,
                lineColor,
                color,
                tooltip,
                events,
                symbol
            });
        }
        return seriesConfig;
    }

    renderTMChart = ()=>{
        const { props,loading } = this;
        const { patientId, chartWidth, xAxisLabelPerDay} = props;
        if(loading){
            return <div>loading placeholder</div>
        }
        const { fromDate,toDate, visitList = [], resultList = [], allVisit } = props;
        const seriesConfig = this.generateSeriesInfo(visitList, resultList, fromDate, allVisit);
        const options =  {
            chart: {
                type: 'line',
                width: chartWidth
            },
            title:null,
            plotBackgroundColor:'#0099FF',
            xAxis: {
                type: 'datetime',
                tickWidth:2,
                tickInterval: 24 * 3600 * 1000 * xAxisLabelPerDay,
                min:fromDate.valueOf(),
                max:toDate.valueOf(),
                labels:{
                    formatter:(value)=>{
                        const xValue = value.value;
                        return moment(xValue).format('MM/DD/YYYY');
                    }
                }
            },
            yAxis:{
                title: {
                    text: 'Fahrenheit'
                },
                // remove the minimum to show original critical readings
                // min:90,
                max: 112, // update the maximum to keep consistent with mobile submission
                gridLineColor: 'transparent',
                plotBands: [{
                    from: 97,
                    to: 99,
                    color: 'rgb(119,119,119,0.05)',
                }]
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            tooltip: {
                crosshairs: true,
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                },
                pointFormatter:function(){
                    const { y, severity, notes } = this;
                    return `<br>${_.upperFirst(severity.toLowerCase())}: <b>${y} F</b></br><br>Notes: ${notes} </br>`
                }
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            credits: {
                enabled: false
            },
            series:seriesConfig
        }
        return <HighchartsReact
          highcharts={Highcharts} options={options}
          callback={this.props.chartCallback}
        />

    }
    render(){
        const { loading } = this.props;
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};
        return <div style={style}>
            { loading? <IHLoading/> : this.renderTMChart() }
        </div>
    }
}


const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate, allVisit} = ownProps;

        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.enrolledProgramId,
                    "type": "TM",
                    fromDate:!allVisit? Number(fromDate): null,
                    toDate: !allVisit? Number(toDate): null,
                    severity: CHART_SEVERITY
                },
                "count": RESULT_COUNT_MAX,
                "sort":{field: 'DATE', direction: "ASC"}
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            resultList: _.get(resultList,'data',[]),
            loading,
        }
    }
})

const withVisitData = graphql( visitsWithTemp, {
    options: (ownProps) => {
        const { fromDate,toDate, patientId,allVisit } = ownProps;
        return {
            variables: {
                    memberId: patientId,
                    appointmentFrom : !allVisit ? Number(fromDate) : null,
                    appointmentTo : !allVisit ? Number(toDate) : null,
                    "count": VISIT_COUNT_MAX,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({data}) => {
        const { loading, visitList } = data;
        if(loading){
            return {
                loading
            }
        }

        return {
            visitList:  _.get(visitList,'data',[]),
            loading,
        }
    }
});



export default compose(withVisitData, withResData)(TMAreaChartComponent);
