import {Address, Phone, Identification} from './common';
import { UserPreferenceSchema } from '../schema/UserPreference';
import { Email } from './User';
import UserDeviceRecords from './UserDeviceRecords';
import { PatientInsuranceProvider } from './InsuranceProvider';
import { NutritionSchema } from '../schema/Nutrition';
import { SpecialistInfoSchema } from '../schema/SpecialistInfo';
import { ConditionsListSchema } from './ConditionsList';

const PatientRemoteEnrollment = `
  status
  doctorReferral 
  doctorReferralDate
  patientRefer {
    recommendedVitals
    eligiblePrgorams
    status{
      patientConsentEnroll
      confirmDevicesEnroll 
      scheduleInitVisitEnroll
      referStatus
      enrollStatus
    }
    wantMNTReferral
    enrolledBy {
      id
      profile {
        fullName
      }
    }
    referredBy{
      id
      profile{
        fullName
      }
    }
    conditionsToMonnitor
  }
  ${ConditionsListSchema}
`

const PatientProfile = `
  title
  firstName
  lastName
  nickname
  fullName
  fullNameWithNickname
  gender
  avatar {
    link
  }
  ...on MemberProfile {
    isSelfEnrolled
    techLevel
    motivation
    birthday
    callLogFlag
    subscription {
        status
    }
    claimedTimeLogFlag
    lastMeasuredAt
    lastMeasuredByType{
      BG,BP,PO,TM,HS,ECG,HR,EXERCISE
    }
    race {
      code
      description
    }
    race1 {
      code
      description
    }
    deviceRecords{
      ${UserDeviceRecords}
    }
    insuranceProvider
    insuranceProvider2
    insuranceProviders {
      ${PatientInsuranceProvider}
    }
    insuranceUpdatedAt
    economicStatus
    occupation
    doctor{
      id
      profile{
        fullName
        firstName
        lastName
      }
    }
    programCategories{
      name
      enrolled
      enrolledDate
    }
    language {
      code
      description
    }
    appLanguage {
      code
      description
    }
    maritalStatus{
      code
      description
    }
    nationality {
      code
      description
    }
    height
    weight
    latestWeight
    healthConditions
    billableHealthConditions{
      description
      code
    }
    mntHealthConditions{
        description
        code
    }
    yearOfDiagnose {
      condition
      diagnosedDate
    }
    insulinDependent
    BGTreatments
    BPTreatments
    stroke
    cardiovascularDisease
    retinopathy
    BGOther
    BGVisits
    BPVisits
    BGMedicine
    BGTest{
      testRegularly
      freq
      unit
    }
    BPTest{
      testRegularly
      freq
      unit
    }
    kidneyDisease
    liverDisease
    heartAttack{
      hasHeartAttack
      year
    }
    exerciseHabits{
      value
      freq{
        value
        unit
      }
      duration
    }
    dietHabits{
      value
      other
      dietProperties
    }
    drinkingHistory{
      value
      startSinceYear
      drinkType
      freq{
        value
        unit
      }
      amount
      quitYear
    }
    smokingHistory{
      value
      startSinceYear
      freq{
        value
        unit
      }
      amount
      quitYear
    }
    spouseMedicalHistory
    fatherMedicalHistory
    motherMedicalHistory
    siblingMedicalHistory
    paternalFatherMedicalHistory
    paternalMotherMedicalHistory
    paternalUnclesOrAuntsMedicalHistory
    maternalFatherMedicalHistory
    maternalMotherMedicalHistory
    maternalUnclesOrAuntsMedicalHistory
    routine
    sleepingRoutine
    eatingRoutine
    sleepFreq
    lifestylePattern
    lifestyleNote
    activityLevel
    educationLevel
    foodAccessibility
    workDuties
    socialSupport{
      communitySupport
      contactInfo
      otherProviders
      extraNote
    }
    BGSpecialistInfo{
      ${SpecialistInfoSchema}
    }
    BPSpecialistInfo{
      ${SpecialistInfoSchema}
    }
    chronicDiseaseHistory{
      name
      yearsWithCondition
      comments
      takingMedication
      otherTreatment
      seenDoctor
      testRegularly
      freq
      unit
      treatments
      specialistInfo{
        ${SpecialistInfoSchema}
      }
      seenDoctor
      testRegularly
      freq
      unit
      treatments
    }
    chronicDiseaseNote
    medicationManagement {
      reconcilationCompliance
      otherInfo
      extraNote
    }
    ${NutritionSchema}
    hasSmartPhone
    ${ConditionsListSchema}
  }
`
export const PatientPageSchema = `
  id
  isTestUser
  createdAt
  updatedAt
  registeredAt
  assignees{
     assignedToRD{
      id
      profile{
        fullName
      }
    }
    assignedToCA{
      id
      profile{
        fullName
      }
    }
    assignedToCANotNecessary
    assignedToRDNotNecessary
  }
  username
  email {
    ${Email}
  }
  address {
    ${Address}
  }
  phone {
    ${Phone}
  }
  identification {
    ${Identification}
  }
  profile {
    ${PatientProfile}
  }

  team {
    id
    name
  }
  userPreference {
    ${UserPreferenceSchema}
  }
  starred
  starredReason
  hasFlag
  flagDetails {
    reason
    createdAt
  }
  hasPassword
  isDefaultPassword
  deleted
  remoteEnrollment {
    ${PatientRemoteEnrollment}
  }
  enrollmentDate
  latestVisit {
    id
    appointmentAt
  }
  monthlyTimeSpent {
    totalTime
    monthOfYear
    updatedAt
  }
  taskAssignmentCount
`