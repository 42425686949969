import React from 'react'
// import { IHBaseFormWithRow } from 'ihcomponent'
import { graphql, withApollo, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { defaultProps, withProps, lifecycle, withHandlers, mapProps, branch, renderComponent } from 'recompose';

import * as R from 'ramda'
import weeklyBGScheduleTemplateManyGQL from './gql/weeklyBGScheduleTemplateMany';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
export const trace = debug ? traceF : () => R.identity;

export const removeTypename = (o) => {
  if(R.isNil(o)) return
  const o1 = R.dissoc('__typename', o);
  const o2 = R.map((v)=>( typeof v === 'object' ? removeTypename(v) : v), o1);
  return o2;
};

var mongoObjectId = function () {
  var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  const newObjectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
      return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
  console.log('newObjectId generated: ', newObjectId);
  return newObjectId;
};

export const removeCurrentLabel = (weeklyMealSchedule) => R.merge(weeklyMealSchedule, { name: weeklyMealSchedule.name.replace(/([^\(\)]+)(.*)/, '$1')} );
export const addIDWhenMissing = R.unless((weeklyMealSchedule)=>R.isNil(weeklyMealSchedule) || !R.isNil(weeklyMealSchedule._id), (weeklyMealSchedule)=>R.merge(weeklyMealSchedule, { _id: mongoObjectId() }))
export const labelCurrentTemplate = (label, weeklyMealSchedule) => addIDWhenMissing(R.merge(weeklyMealSchedule, {
  name: weeklyMealSchedule.name.replace(/([^\(\)]+)(.*)/, '$1('+label+')'),
}));

const modifyWeeklyMealTemplates = R.compose(
  R.over(R.lensProp('templateDetails'), R.partial(labelCurrentTemplate, ['Current'])),
);
const modifyBGTemplateTasks = R.map(
  R.when(
    ({ type, scheduleType, templateDetails }) => type === 'BG' && !R.isNil(templateDetails),
    modifyWeeklyMealTemplates)
);

export const labelCurrentinProgramBGTasks = (path, x) => R.over(
  R.lensPath(path)
  ,(arr)=>{
    // if(debug) debugger;
    return modifyBGTemplateTasks(arr);
  }
  , x
);

const weeklyBGScheduleTemplateMany = graphql(weeklyBGScheduleTemplateManyGQL, {
  options: () => ({ variables: { }}),
  props: ({ ownProps, data: { loading, weeklyBGScheduleTemplateMany = [], refetch }}) => {


    const weeklyBGScheduleTemplateMany2 =
      // weeklyBGScheduleTemplateMany;
      R.map(removeTypename, weeklyBGScheduleTemplateMany);
    return ({
      weeklyBGScheduleTemplateMany: weeklyBGScheduleTemplateMany2,
      weeklyBGScheduleTemplateManyLoading: loading,
      weeklyBGScheduleTemplateManyRefetch: refetch,
    })
  },
});

const weeklyBGScheduleTemplateCreate = graphql(gql`
  mutation weeklyBGScheduleTemplateCreate($weeklyBGScheduleTemplate: CreateOneWeeklyBGScheduleTemplatesInput!) {
    weeklyBGScheduleTemplateCreate(record: $weeklyBGScheduleTemplate) {
      recordId
    }
  }
`, {
  name: 'weeklyBGScheduleTemplateCreate',
  options: (weeklyBGScheduleTemplate) => ({ variables: { weeklyBGScheduleTemplate: weeklyBGScheduleTemplate }}),
  props: (p2) => ({
    weeklyBGScheduleTemplateCreate: (weeklyBGScheduleTemplate) => {
      const { weeklyBGScheduleTemplateCreate, ownProps: ownProps0 } = p2
      const {
        viewerId,
        userId,
      } = ownProps0;
      return weeklyBGScheduleTemplateCreate({ variables: {
        weeklyBGScheduleTemplate: R.merge({
          viewerId,
          userId,
        }, weeklyBGScheduleTemplate)
      }}).then(({ data }) => {
        if(debug) console.log('weeklyBGScheduleTemplateCreate', data);
        return data;
      });
    },
  }),
});

const weeklyBGScheduleTemplateUpdate = graphql(gql`
  mutation weeklyBGScheduleTemplateUpdate($weeklyBGScheduleTemplate: UpdateByIdWeeklyBGScheduleTemplatesInput!) {
    weeklyBGScheduleTemplateUpdate(record: $weeklyBGScheduleTemplate) {
      recordId
    }
  }
`, {
  name: 'weeklyBGScheduleTemplateUpdate',
  options: (weeklyBGScheduleTemplate) => ({ variables: { weeklyBGScheduleTemplate: weeklyBGScheduleTemplate }}),
  props: (p2) => ({
    weeklyBGScheduleTemplateUpdate: (weeklyBGScheduleTemplate) => {
      const { weeklyBGScheduleTemplateUpdate, ownProps: ownProps0 } = p2
      const {
        viewerId,
        userId,
      } = ownProps0;
      return weeklyBGScheduleTemplateUpdate({ variables: {
        weeklyBGScheduleTemplate: R.merge({
          viewerId,
          userId,
        }, weeklyBGScheduleTemplate)
      }}).then(({ data }) => {
        if(debug) console.log('weeklyBGScheduleTemplateUpdate', data);
        return data;
      });
    },
  }),
});

const weeklyBGScheduleTemplateRemove = graphql(gql`
  mutation weeklyBGScheduleTemplateRemove($weeklyBGScheduleTemplateId: MongoID!) {
    weeklyBGScheduleTemplateRemove(_id: $weeklyBGScheduleTemplateId) {
      recordId
    }  
  }
`, {
  props: ({ mutate }) => ({
    weeklyBGScheduleTemplateRemove: (weeklyBGScheduleTemplateId) => mutate({ variables: { weeklyBGScheduleTemplateId } })
    .then(({ data }) => {
      if(debug) console.log('weeklyBGScheduleTemplateRemove', data);
      return data;
    }),
  }),
});

export const addWeeklyBGScheduleTemplateAPI = compose(
  // withApollo,
  weeklyBGScheduleTemplateMany,
  weeklyBGScheduleTemplateCreate,
  weeklyBGScheduleTemplateUpdate,
  weeklyBGScheduleTemplateRemove,
  mapProps(({
    weeklyBGScheduleTemplateCreate: create,
    weeklyBGScheduleTemplateRemove: remove,
    weeklyBGScheduleTemplateManyLoading: loading,
    weeklyBGScheduleTemplateManyRefetch: refetch,
    weeklyBGScheduleTemplateUpdate: update,
    weeklyBGScheduleTemplateMany: records,
    ...otherProps,
  }) => (R.merge(otherProps, {
    weeklyBGScheduleTemplateAPI: {
      create,
      remove,
      loading,
      refetch,
      update,
      records,
    }
  }))),
  // withProps(trace('weeklyBGScheduleTemplate trace: ')),

);
