import Client from 'libModule/gqlClient';
import { GQLHelper } from 'libModule/utils'
import editEnrolledProgramGQL from 'modulesAll/graphql/mutation/editEnrolledProgram';
import {MANUAL, APP_SYNC, DEVICE_VITAL_MAP_SHORT} from '../constant/onboardMap';


const getMutateVariables = (userDeviceRecords) => {

    let  manualInputEnabledTaskTypes = [];
    let deviceInputEnabledTaskTypes = [];
    _.mapKeys(userDeviceRecords, (val, k) => {
        if(val == MANUAL && DEVICE_VITAL_MAP_SHORT[k]){
            manualInputEnabledTaskTypes.push(DEVICE_VITAL_MAP_SHORT[k]);
        }
        if(val == APP_SYNC && DEVICE_VITAL_MAP_SHORT[k]){
            deviceInputEnabledTaskTypes.push(DEVICE_VITAL_MAP_SHORT[k]);
        }
    })
    return {
        manualInputEnabledTaskTypes,
        deviceInputEnabledTaskTypes
    };
}

export default function(userDeviceRecords, enrolledProgram, props){
    const {openErrorModal} = props;
    const enrolledProgramId = _.get(enrolledProgram, 'id');
    if( !_.isNull(userDeviceRecords) &&  enrolledProgramId){
        const {  manualInputEnabledTaskTypes, deviceInputEnabledTaskTypes } = getMutateVariables(userDeviceRecords);
        let variables = {
            id: enrolledProgramId,
            manualInputEnabledTaskTypes,
            deviceInputEnabledTaskTypes
        }
        Client.mutate({
            mutation: editEnrolledProgramGQL,
            variables,
            fetchPolicy: 'no-cache'
        })
        .catch( e => {
            // if(typeof openErrorModal == 'function'){
            //     openErrorModal(GQLHelper.formatError(e))
            // }
            console.error(e);
        })
    }
}

