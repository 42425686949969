import React from 'react';
import {Tabs} from "antd";
import {ListGroup} from "react-bootstrap";
import ChannelListComponent from "../components/ChannelListComponent";
import {connect} from "react-redux";
import { decryptRole } from 'libModule/utils';
import roleMap from '../../layout/constants/roleMap';
import markAsUnreadRoles from '../constants/markAsUnreadRoles';
import helper from "../helper/index";
import { DEFAULT_PAGE_SIZE } from '../constants';
import actions from '../actions';

const { TabPane } = Tabs;

const ChatChannelListContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            channelStatus: 'UNREAD',
            loadedAll: false,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const preChannelStatus = prevProps.channelStatus;
        const curChannelStatus = this.props.channelStatus;

    }

    renderChannels = (channelStatus,showLoadMoreBtn)=>{
        const { props } = this;
        const filteredChannels = this.filterChannels(channelStatus);
        const sortedCh = this.sortChannels(filteredChannels);
        return <ChannelListComponent 
                    key={channelStatus}    
                    setSelectedChannel={ props.setSelectedChannel }
                    addToUserMap={ props.addToUserMap }
                    sortedChannels={sortedCh}
                    showLoadMoreBtn={showLoadMoreBtn}
                                     // channelStatus={channelStatus}
                />
    }
    sortChannels(filteredChs) {
        const { props } = this;

        // put object key inside ea object
        const reformatted = filteredChs.map( val => {
            let newObj = _.clone(props.channels[val]);
            newObj.id = val;
            return newObj;
        });
        const sortedCh = _.orderBy(reformatted, ['lastMsgTime'], ['desc']);
        return sortedCh.map(s=>s.id);
    }

    filterChannels(channelStatus) {
        const { channels,selectedChannel } = this.props;
        const channelsArr = Object.keys(this.props.channels);
        // render only channels that belong to user's team (of selected organization)
        const curUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const teamIds = curUser.team.map(val => atob(val.id).split(':')[1]);

        const teamIdMap = {};
        teamIds.forEach(val => teamIdMap[val] = true);
        const onlyShowUnread = channelStatus=='UNREAD';
        let filterCondition = (val)=>{
            return teamIdMap[val.split('-')[0]] && (onlyShowUnread ? channels[val].isOngoing : true );
        }
        return channelsArr.filter(val => filterCondition(val) );
    }

    getDefaultChsFetchVar = () => ({
      fromTimestamp: (new Date()).getTime()*1e4+'',
      pageSize: DEFAULT_PAGE_SIZE,
      teamIds: helper.getCurrentUserTokenAndTeamIds().teamsId,
      unread: false,
      pageNumber:(new Date()).getTime()*1e4+''
    });

    loadUnreadChannels = () => {
      const defaultChsFetchVar = this.getDefaultChsFetchVar();
      const unreadParams = { ...defaultChsFetchVar, pageSize: undefined, unread:true };
      return helper.getChannelsAndHistoryWithCHS(unreadParams, { shouldSetHasMoreChannels: false, isOngoing: true });
    };

    loadAllChannels = () => {
      const defaultChsFetchVar = this.getDefaultChsFetchVar();
      return helper.getChannelsAndHistoryWithCHS(defaultChsFetchVar); 
    };

    loadChannels = (channelStatus) => {
      this.setState({ loadingMoreChannels: true }, async  () => {
        if (channelStatus === 'UNREAD') await this.loadUnreadChannels();
        else await this.loadAllChannels();
        this.setState({ loadingMoreChannels: false });
      });
    }

    componentDidMount(){
        const { channelStatus } = this.state;

        if (this.filterChannels(channelStatus).length == 0){
          this.setState({channelStatus:"ALL"})
        }
    }

    componentDidUpdate(prevProps, prevState) {
      const { loadedAll, channelStatus } = this.state;
      if (!loadedAll && prevState.channelStatus !== channelStatus && channelStatus !== 'UNREAD') {
        this.loadChannels('READ');
        this.setState({ loadedAll: true });
      }
    }

    render() {
        const {useChs } = this.props;
        const { channelStatus } = this.state;
        const tabPaneStyle = { 
          height: 'calc(100vh - 99px)' // 99px = 54px of top nav bar, 45px tab header
        };
        const chatContainerStyles = {
            height: 'calc(100vh - 54px)',
            overflowY: 'auto'
        };
        const currentUserRole = decryptRole();
        return markAsUnreadRoles.includes(roleMap[currentUserRole]) && useChs ? <div className={`chatChannelListContainer`}>
            <Tabs 
                activeKey={channelStatus}
                onChange={(channelStatus)=>this.setState({channelStatus})}
                // className={'chatTabSwitch'}
               >
                    <TabPane key={'UNREAD'} tab='Ongoing Messages' style={tabPaneStyle}>
                        <ListGroup className='channelListGroup'>
                            { this.props.channels ? this.renderChannels('UNREAD') : 'Loading' }
                        </ListGroup>            
                    </TabPane>
                    <TabPane key='ALL' tab={'All Messages'} style={tabPaneStyle}>{
                        <ListGroup className='channelListGroup'>
                            { this.props.channels ? this.renderChannels('ALL',true) : 'Loading' }
                        </ListGroup>}
                    </TabPane>
        </Tabs>
        </div> :
        <div style = {chatContainerStyles}>
            {useChs? this.renderChannels('ALL',true): this.renderChannels()}
        </div>
            
    }
}

const mapState = (state,ownProps) => {
    const { chat } = state;
    const selectedChannel = _.get(chat,'main.selectedChannel');
    const patientId = _.get(chat,`main.channels.${selectedChannel}.patientId`);
    const parsedPatientId = patientId ? btoa('accounts:'+patientId) : '';
    const displayName = `chat/containers/ChatContainer:${parsedPatientId}`;

    return {
        testInputMap: chat.main.testInputMap,
        currentUserId: chat.main.currentUserId,
        selectedChannel: chat.main.selectedChannel,
        channels: chat.main.channels,
        patientIdToChannel: chat.main.patientIdToChannel,
        userMap: chat.main.userMap,
        timer:state.timer.main[displayName],
        profileHeight:chat.main.profileHeight,
        isFullyLoaded:chat.main.isFullyLoaded,
        useChs: chat.main.useChs
    }
};

const mapDispatch = (dispatch) => {
  return {
      addChatHistory: (...args) => dispatch(actions.addChatHistory(...args)),
      updateChannelInfo: (channel, msgObj, isbatch, isFetchingMoreChannels) => dispatch(actions.updateChannelInfo(channel, msgObj, isbatch, isFetchingMoreChannels)),
      initUnreadCounter:(channel,unreadMsgCount)=>dispatch(actions.initUnreadCounter(channel,unreadMsgCount)),
      setUserMap: userMap => dispatch(actions.setUserMap(userMap)),
      setIsFullyLoaded: flag => dispatch(actions.setIsFullyLoaded(flag)),
      setHasMoreChannels: flag => dispatch(actions.setHasMoreChannels(flag)),
      setLastMSGTOfOrg: (org,timestamp,pageNumber)=>dispatch(actions.setLastMSGTOfOrg(org,timestamp,pageNumber))
  }
};

export default connect(mapState, mapDispatch)(ChatChannelListContainer);
