import store from './reduxStore'
import { decryptRole } from 'libModule/utils';
import { HOMEPAGES } from 'modulesAll/utils/constants/menuPath'

const requireAuth = (nextState, replace) => {
  if(!sessionStorage.getItem("authToken") || !sessionStorage.getItem("currentUser")) {
    if(nextState.location.pathname !== '/onboard/' && nextState.location.pathname !== '/resetpass/') {
      replace('/login')
    }
  }
}

const setLocation = function(nextState, replace){
    if(nextState.location){
      store.dispatch({
        type : 'root/app/SET_LOCATION',
        location : nextState.location,
        params : nextState.params
      })
    }
}

const enterIndex = (ns, replace)=>{
  const role = decryptRole()
  if (HOMEPAGES[role]) {
    replace(HOMEPAGES[role])
  }
  else {
    replace('/not-authorized')
  }
}

// custom args, not passing directly from router props
const verifyRoleAccess = (roleToCheck, replace, cb) => {
  const role = decryptRole();
  if(!_.includes(role, roleToCheck)){
    replace('/'); // implicit Not-Authorized
  } else if(cb) {
    cb();
  }
}

// roleWhiteList: array
const verifyRouteAccess = (roleWhiteList, replace, cb) => {
  const role = decryptRole();
  if(!_.includes(roleWhiteList, role)){
    replace('/'); // implicit Not-Authorized
  } else if(cb) {
    cb();
  }
}

export { requireAuth, setLocation, enterIndex, verifyRoleAccess, verifyRouteAccess };
