import React from 'react';
import { Modal, Table, Button, Tooltip, Tabs } from 'antd';
import moment from 'moment';
import VISIT_TYPE_ENUM from '../../visit/constants/map';
import PrintCarePlanButton from '../container/PrintCarePlanButton';
import ProgramUpdateHistoryContainer from '../../patient/enrolledProgramInfo/ProgramUpdateHistory/container/ProgramUpdateHistoryContainer';
const { TabPane } = Tabs;

class VersionHistoryModal extends React.Component {
  getTableColumns = () => {
    return [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date === b.date ? 0 : (a.date < b.date ? 1 : -1),
        render: data => <div>{moment(data).format('MM/DD/YY')}</div>,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: data => <div>{data}</div>,
      },
      {
        title: 'Updated by',
        dataIndex: 'updatedBy',
        key: 'updatedBy',
        render: data => (
          <Tooltip placement="top" title={data}>
            <div style={{ whiteSpace: 'nowrap', width: 140, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data}</div>
          </Tooltip>
        )
      },
      {
        title: 'Updated content',
        dataIndex: 'updatedContent',
        key: 'updatedContent',
        render: data => (
          <Tooltip placement="top" title={data}>
            <div style={{ whiteSpace: 'nowrap', width: 140, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data}</div>
          </Tooltip>
        )
      },
      {
        title: '',
        dataIndex: 'printData',
        key: 'printData',
        render: data => 
          data === null ? null : <PrintCarePlanButton {...data}><Button>Print</Button></PrintCarePlanButton>,
      },
    ]
  }

  getItemsUpdated = (modifiedHistory = []) => {
    const allWithDuplicates = modifiedHistory.map(mh => _.get(mh, 'itemsUpdated', '') || '');
    if (allWithDuplicates.includes('-')) { return '-'; }
    const allAsStringWithDuplicates = allWithDuplicates.reduce((res, curr) => res + (res && ', ') + curr, '');
    const all = allAsStringWithDuplicates.split(',').map(x => x.trim());
    return _.uniq(all).join(', ');
  }

  getUpdatedBy = (modifiedHistory = []) => {
    const allWithDuplicates = modifiedHistory.map(mh => _.get(mh, 'user.profile.fullName', ''));
    return _.uniq(allWithDuplicates).join(', ');
  }

  getPrintData = (versionHistory) => {
    const isMNT = _.get(versionHistory, 'visit.type') === VISIT_TYPE_ENUM.MNT;
    const visitType = _.get(versionHistory, 'visit.type');
    const visitDate = _.get(versionHistory, 'visit.appointmentAt');
    const data = JSON.parse(versionHistory.careplanData);
    if (_.get(versionHistory, 'visit') === null) { return null; }
    return {
      skipFetch: true,
      enrolledProgram: data.enrolledProgram,
      user: data.user,
      userPESStatements: data.pes,
      visitType,
      visitDate,
      signOffStatus: isMNT ? {
        ..._.get(versionHistory, 'visit.signOffStatus'),
        checkinTime: _.get(versionHistory, 'visit.checkinTime'),
      } : undefined,
    };
  }

  getDataSource = () => {
    return this.props.versionHistoryList.map((vh, i) => {
      const visitType = _.get(vh, 'visit.type');
      return {
        key: i,
        date: _.get(_.last(vh.modifiedHistory || []), 'date'),
        type: visitType ? VISIT_TYPE_ENUM[visitType] : 'Profile',
        updatedBy: this.getUpdatedBy(vh.modifiedHistory),
        updatedContent: this.getItemsUpdated(vh.modifiedHistory),
        printData: this.getPrintData(vh),
      };
    });
  }

  render() {
    const { visible, onClose, enrolledProgramId } = this.props;
    const dataSource = this.getDataSource();

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          title={null}
          onCancel={onClose}
          width={1000}
          footer={null}
        >
          <Tabs defaultActiveKey="1" className="version-history-tabs">
            <TabPane tab="Care Plan Version History" key="1">
              <Table dataSource={dataSource} columns={this.getTableColumns()} rowKey={x => x.date} pagination={{ pageSize: 5 }} />
            </TabPane>
            <TabPane tab="Pre-care plan change version history" key="2">
              <ProgramUpdateHistoryContainer  enrolledProgramId={enrolledProgramId} />
            </TabPane>
          </Tabs>
        </Modal>
      </React.Fragment>
    );
  }
}

export default VersionHistoryModal;