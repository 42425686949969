import PATRACE from '@ihealth/codetranslate/lib/PATRACE'
import PATNAT from '@ihealth/codetranslate/lib/PATNAT'
import _PATLANG from '@ihealth/codetranslate/lib/PATLANG'
import PHONECODE from '@ihealth/codetranslate/lib/PHONECODE'
import PATCOUNTRY from '@ihealth/codetranslate/lib/PATCOUNTRY'
import PATDOCTYPE from '@ihealth/codetranslate/lib/PATDOCTYPE'
import { helpers} from "modulesAll/visit/helpers";
import I18N from '../modules/I18N'

// import CAREGIVERRELATIONSHIP from '@ihealth/codetranslate/lib/CAREGIVERRELATIONSHIP'
const CAREGIVERRELATIONSHIP = {
  "CHILD": "Child",
  "DOMESTICHELPER": "Domestic Helper",
  "GRANDCHILD": "Grandchild",
  "GRANDPARENT": "Grandparent",
  "LEGALGUARDIAN": "Legal Guardian",
  "PARENT": "Parent",
  "SIBLINGS": "Siblings",
  "SPOUSE": "Spouse",
  "OTHERRELATIVE": "Other Relative",
  "OTHERS": "Others",
}

const preferOptions = {
  "EL": "English",
  "CT": "Cantonese",
  "MN": "Mandarin",
  "SP": "Spanish",
  "VN": "Vietnamese",
  "TA": "Tagalog",
  "JP": "Japanese",
  "KR": "Korean"
}

export const LanguageEnum = Object.assign(
  {}, 
  ..._.map([
    'en', 'zh-tw', 'zh-cn', 'es', 'vi'
  ], (v) => ({ [v]: v }))
);


export const LanguageAbbreviation = {
  [LanguageEnum.en]: 'ENG',
  [LanguageEnum['zh-tw']]: 'CAN',
  [LanguageEnum['zh-cn']]: 'MAN',
  [LanguageEnum.es]: 'SPA',
  [LanguageEnum.vi]: 'VIE',
};

export const WEBFLOW_LANGUAGE_MAP = {
  EL: LanguageEnum.en,
  CT: LanguageEnum['zh-tw'],
  MN: LanguageEnum['zh-cn'],
  SP: LanguageEnum.es,
  VN: LanguageEnum.vi,
};

export const PATLANG = Object.assign({}, _PATLANG);

const removedOptions = Object.assign(preferOptions,  _.omit(_PATLANG, Object.keys(preferOptions)));

// const DoctorNames = helpers.filteb rDoctors();
export const HEALTH_CONDITION_DISPLAY = {
  // Diabetes_Type_I: 'Diabetes Type I',
  // Diabetes_Type_II: 'Diabetes Type II',
  // Hypertension: 'Hypertension',
  // Heart_Failure: 'Heart Failure',
  // Ischemic_Heart_Disease: 'Ischemic Heart Disease',
  // COPD: 'Chronic Obstructive Pulmonary Disease (COPD)',
  // Osteoporosis: 'Osteoporosis',
  // Obesity: 'Obesity',
  // Arthritis: 'Arthritis',
  // Asthma: 'Asthma',
  // Cancer: 'Cancer',
  // Depression: 'Depression',
  // Autism_Spectrum_Disorders: 'Autism Spectrum Disorders',
  // Alzheimer: 'Alzheimer’s and Related Dementia',
}
export const HEALTH_CONDITION_ICDCODE = {
  // Diabetes_Type_I: 'A00',
  // Diabetes_Type_II: 'A01',
  // Hypertension: 'A02',
  // Heart_Failure: 'A03',
  // Ischemic_Heart_Disease: 'A04',
  // COPD: 'A05',
  // Osteoporosis: 'A06',
  // Obesity: 'A07',
  // Arthritis: 'A08',
  // Asthma: 'A09',
  // Cancer: 'A10',
  // Depression: 'A11',
  // Autism_Spectrum_Disorders: 'A12',
  // Alzheimer: 'A13',
}
 const RACE_OPTIONS = [
  { code: 'WHITE', description: 'White' },
  { code: 'BAA', description: 'Black or African American' },
  { code: 'ASIAN', description: 'Asian' },
  { code: 'NHOPI', description: 'Native Hawaiian and Other Pacific Islander' },
  { code: 'AIAN', description: 'American Indian and Alaska Native' },
  { code: 'HISPANIC', description: 'Hispanic' },
  { code: 'MULTIRACIAL', description: 'Multiracial' },
  { code: 'OTHER', description: 'Other' },
  { code: 'NOT_TO_SAY', description: 'Prefer not to say' },
];
export const nationOptions = Object.keys(PATNAT).map(key => ({name: PATNAT[key], value: key}))
export const langOptions = Object.keys(removedOptions).map(key => ({name: removedOptions[key], value: key}))
export const raceOptions = RACE_OPTIONS.map((opt) => ({name: opt.description, value: opt.code }))
export const phoneOptions = Object.keys(PHONECODE).map(key => ({name: `+${key}`, value: `+${key}`}))
export const countryOptions = Object.keys(PATCOUNTRY).map(key => ({name: PATCOUNTRY[key], value: key}))
export const docTypeOptions = Object.keys(PATDOCTYPE).map(key => ({name: PATDOCTYPE[key], value: key}))
export const relationOptions = Object.keys(CAREGIVERRELATIONSHIP).map(key => ({name: CAREGIVERRELATIONSHIP[key], value: key}))
// NO USE for DoctorNameOptions => AND DcotorNameArray
export const DoctorNameArray = helpers.filterDoctors();
export const DoctorNamesOptions = _.map(DoctorNameArray,({fullName})=>({name:fullName.trim(),value:fullName.trim(),key:fullName.trim()}));

export const ROLE_NAME = {
  HC: 'Health Coach'
};

export const ROLE_MAP = {
  'Nurse':'RD',
  'Doctor':'MD',
  'Clinic Medical Assistant':'MA',
  'Medical Assistant':'CA',
  'PROVIDER:Doctor':'MD',
  "PROVIDER:Clinic Medical Assistant":'MA',
  'PROVIDER:Medical Assistant':'CA',
  'PROVIDER:Nurse':'RD',
  'Biller': 'BILLER',
  'PROVIDER:Biller': 'BILLER',
  'PROVIDER:Partner Biller': 'Partner Biller',
  'Partner Biller': 'PROVIDER:Partner Biller',
  [ROLE_NAME.HC]: 'HC',
  [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
};

export const NotiDefaultValMap = {
  "RD" : "NOTIFY_ALWAYS",
  "HC" : "NOTIFY_ALWAYS",
  "CA" : "NOTIFY_ALWAYS",
  "MD" : "NOTIFY_LESS",
  "MA" : "NOTIFY_LESS"
}

export const INVERTED_ROLE_MAP = _.invert(ROLE_MAP);

export const VIDEO_STR = {
  OPEN: 'Open Video',
  JOIN: 'Join Video'
};

export const VISIT_TAB_KEY_TO_TEXT = {
  0: 'Today',
  1: 'Tomorrow',
  2: 'All'
};

export const VISIT_ROLES = Object.keys(I18N.get('teamRoles'));
export const COMPLEX_INCLINIC_VISIT_ROLES = VISIT_ROLES; // _.difference(VISIT_ROLES, ['HC']); // filter out
export const COMPLEX_VISIT_ROLES = I18N.get('complexTeamRoles');
export const NON_COMPLEX_INCLINIC_VISIT_ROLES = VISIT_ROLES; //_.difference(VISIT_ROLES, ['RD']);
export const NON_COMPLEX_VISIT_ROLES = I18N.get('nonComplexTeamRoles');
const CLINIC_ROLES = ['MA', 'MD'];

export const VISIT_TYPE_NAME_MAPPING = {
  'CLINIC_FOLLOW_UP': 'General Visit',
  'INIT': 'Initial Visit',
  'FOLLOW_UP': 'Follow up Visit',
  'ADDITIONAL': 'Additional Visit',
  'TECH': 'Tech Visit',
  'MNT':'MNT',
  'TECH_ONBOARDING':'Tech Onboarding'
};

export const VISIT_TYPE_ROLES_MAP = {
  INIT: {
    NON_COMPLEX: {
      VIRTUAL: NON_COMPLEX_VISIT_ROLES,
      INCLINIC: NON_COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: NON_COMPLEX_VISIT_ROLES,
    },
    COMPLEX: {
      VIRTUAL: COMPLEX_VISIT_ROLES,
      INCLINIC: COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: COMPLEX_VISIT_ROLES,
    }
  },
  FOLLOW_UP: {
    NON_COMPLEX: {
      VIRTUAL: NON_COMPLEX_VISIT_ROLES,
      INCLINIC: NON_COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: NON_COMPLEX_VISIT_ROLES,
    },
    COMPLEX: {
      VIRTUAL: COMPLEX_VISIT_ROLES,
      INCLINIC: COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: COMPLEX_VISIT_ROLES,
    }
  },
  ADDITIONAL: {
    NON_COMPLEX: {
      VIRTUAL: NON_COMPLEX_VISIT_ROLES,
      INCLINIC: NON_COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: NON_COMPLEX_VISIT_ROLES,
    },
    COMPLEX: {
      VIRTUAL: COMPLEX_VISIT_ROLES,
      INCLINIC: COMPLEX_INCLINIC_VISIT_ROLES,
      PHONE: COMPLEX_VISIT_ROLES,
    }
  },
  CLINIC_FOLLOW_UP: { // GENERAL visit
    NON_COMPLEX: {
      VIRTUAL: CLINIC_ROLES,
      INCLINIC: CLINIC_ROLES,
      PHONE: CLINIC_ROLES,
    },
    COMPLEX: {
      VIRTUAL: CLINIC_ROLES,
      INCLINIC: CLINIC_ROLES,
      PHONE: CLINIC_ROLES,
    }
  },
  TECH: {
    NON_COMPLEX: {
      VIRTUAL: ['CA'],
      INCLINIC: ['CA'],
      PHONE: ['CA']
    },
    COMPLEX: {
      VIRTUAL: ['CA'],
      INCLINIC: ['CA'],
      PHONE: ['CA']
    }
  },
  MNT:{
    NON_COMPLEX: {
      VIRTUAL: ['RD'],
      INCLINIC: ['RD'],
      PHONE: ['RD']
    },
    COMPLEX: {
      VIRTUAL: ['RD'],
      INCLINIC: ['RD'],
      PHONE: ['RD']
    }
  },
  TECH_ONBOARDING:{
    NON_COMPLEX: {
      VIRTUAL: NON_COMPLEX_VISIT_ROLES,
      INCLINIC: NON_COMPLEX_VISIT_ROLES,
      PHONE: NON_COMPLEX_VISIT_ROLES,
    },
    COMPLEX: {
      VIRTUAL: COMPLEX_VISIT_ROLES,
      INCLINIC: COMPLEX_VISIT_ROLES,
      PHONE: COMPLEX_VISIT_ROLES,
    }
  }
};

export const MA_VISIT_TYPE_ENUM = _.omit(VISIT_TYPE_NAME_MAPPING, ['MNT', 'TECH_ONBOARDING']);

export const MD_VISIT_TYPE_ENUM = _.omit(VISIT_TYPE_NAME_MAPPING, ['TECH', 'MNT', 'TECH_ONBOARDING']);

export const FOLLOW_UP_VISIT_RANGE = {
  withDiabetes: {
    min: { amount: 75, unit: 'days' },
    max: { amount: 112, unit: 'days' },
  },
  withoutDiabetes: {
    min: { amount: 75, unit: 'days' },
    max: { amount: 195, unit: 'days' },
  }
};

export const DIABETES_ICD_CODES = [
  { code: 'E08.', startWith: true },
  { code: 'E09.', startWith: true },
  { code: 'E10.', startWith: true },
  { code: 'E11.', startWith: true },
  { code: 'E13.', startWith: true },
  { code: 'Z79.4', startWith: false },
];

export const ENROLL_FROM = {
  'VISIT_TABLE': 'VISIT_TABLE',
  'WORK_LIST': 'WORK_LIST',
  'PATIENT_PROFILE': 'PATIENT_PROFILE',
  'REMOTE_ENROLLMENT': 'REMOTE_ENROLLMENT',
  'VIDEO_CALL': 'VIDEO_CALL'
};

export const DEFAULT_TEMPLATE_LANGUAGES = [
  { code: 'EL', description: 'English' },
  { code: 'MN', description: 'Mandarin' },
  { code: 'CT', description: 'Cantonese' },
  { code: 'SP', description: 'Spanish' }
];

export const SIGNOFF_TIME_FORMAT = 'h:mm A (z [time zone]), MM/DD/YYYY';

export const VITALS_ORDER = ['BP', 'BG', 'PO', 'HS', 'TM'];

export const BILLABLE_VITALS = ['BP', 'BG', 'PO', 'HS'];

export const SEARCH_COUNT_MAX = 20;

export const VISIT_COUNT_MAX = 500;

export const FOODLOG_COUNT_MAX = 200;

export const EP_COUNT_MAX = 20;

export const RESULT_COUNT_MAX = 500;

export const SYSTEM_CONFIG_COUNT_MAX=200;

export const MEDICINE_COUNT_MAX = 100;

export const TIMESPENT_COUNT_MAX = 10;

export const ORG_COUNT_MAX = 500;

export const ASSESSMENT_LIST_COUNT_MAX = 200;

export const ALERT_COUNT_MAX = 500;

export const CHART_SEVERITY = ['NORMAL', 'RISK', 'CRITICAL'];

export const CHANNEL_LIMIT = 2000;

export const USER_LIST_COUNT_MAX = 500;

export const PROVIDER_LIST_COUNT_MAX = 10;

export const UNIFIED_CARE_DEFAULT_LOGO = '/logos/Logomark_RGB.svg';
export const roleMap = {
  'Nurse':'RD',
  'Doctor':'Doctor',
  'Clinic Medical Assistant':'MA',
  'Medical Assistant':'CA',
  'PROVIDER:Doctor':'Doctor',
  "PROVIDER:Clinic Medical Assistant":'MA',
  'PROVIDER:Medical Assistant':'CA',
  'PROVIDER:Nurse':'RD',
  [ROLE_NAME.HC]: 'HC',
  [`PROVIDER:${ROLE_NAME.HC}`]: 'HC'
}

export const MAX_MSG_PER_CHANNEL = 20;

export const COMPLEXITY_ENUM = {
  COMPLEX: 'COMPLEX',
  NON_COMPLEX: 'NON_COMPLEX',
  NULL_CODES: 'NULL_CODES'
};

export const COMPLEXITY_LEVELS = {
  COMPLEX: 'Complex',
  NON_COMPLEX: 'Non-complex',
  NULL_CODES: 'Undefined condition complexity level'
};

// complexity managed by roles
export const ROLES_BY_COMPLEXITY = {
  COMPLEX: ['RD', 'CA'],
  NON_COMPLEX: ['HC', 'CA'],
  NULL_CODES: ['RD', 'HC', 'CA']
};

export const EHR_OPTIONS = [
  "Epic Systems",
  "Cerner Corporation",
  "Meditech",
  "CPSI",
  "Allscripts",
  "McKesson",
  "Medhost",
  "Athenahealth",
  "DrChrono",
  "eClinicalWorks",
  "GE Healthcare",
  "Greenway Health",
  "Kareo",
  "Praxis",
  "NextGen (Quality Systems, Inc.)",
  "Practice Fusion",
  "Praxis EMR",
  "Nextgen",
  "CureMD",
  "eMDs",
  "Modernizing Medicine, Inc",
  "Care Cloud",
  "Netsmart Technologies",
  "Harris Healthcare ",
  "OfficeAlly",
  "ChARM",
  "Revolution",
  "Acumen",
  "Medgen",
  "Advanced MD",
  "PrognoCIS",
  "Simple Practice",
  "Nextech",
  "Chartlogic",
  "Modernizing Medicine",
  "RAPID (by ACOM Health)",
  "Compulink Healthcare",
  "WRS Health",
  "Practice Perfect",
  "Heno",
  "MDRhythm",
  "EHI",
  "IMS",
  "Citrix Workspace",
  "eMedRec",
  "PatientHolistics",
  "Not Using Any EHR",
  "Currently Unknown"
];

export const NON_COMPLEX_CONDITIONS = [
  ["HTN"],
  ["HLD","HTN"]
];
export const CAPACITY_ROLE = ['RD','CA','HC']
export const VISIT_REQUIRE_ASSIGNEE = ['INIT', 'FOLLOW_UP', 'ADDITIONAL', 'TECH', 'TECH_ONBOARDING'];

export const PATIENT_COMPLEXITY_SOURCE_ENUM  = {
  ICD_CODE: 'ICD_CODE',
  CONDITION: 'CONDITION',
  MANUAL_INPUT: 'MANUAL_INPUT'
}

export const COMPLEXITY_SOURCE_PRIORITY = {
  MANUAL_INPUT: 1,
  ICD_CODE: 2,
  CONDITION: 3,
}

export const ROLE_DEFAULT_CAPACITY =  {
  RD: 500,
  CA: 900,
  HC: 500
}

export const UPLOAD_FILETYPES = [
  {
    accept: '.jpg',
    display: '*.jpg'
  },
  {
    accept: '.png',
    display: '*.png'
  },
  {
    accept: '.pdf',
    display: '*.pdf'
  }
];
