import React, { useState, useMemo } from 'react';
import { Button, Tabs, Tooltip } from 'antd';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

import { getTimeWithFormat } from '../../../helper/signOffHelper';
import { useDraggableSummaryPopup } from '../../../helper/monthlyReviewHelper';
import { CONDITION_ENUM_MAP, CLINIC_GOAL_ENUM } from '../../../../careplanNew/component/helper/goalHelper';
import I18N from 'modulesAll/I18N';
import { VITAL_DELIMITER } from '../../../constant/monthlyReview';


const IMG_PATH = '/image/workstation';
const goalI18N = I18N.get('workStation.goalAssessment');

const SignOffMTPR = (props) => {
  const { monthlyReviews } = props;

  const [showMTPRContent, setShowMTPRContent] = useState({});
  const [draggablePopups, renderVitalsSummaryPopup] = useDraggableSummaryPopup();

  const handleToggleContent = (id) => setShowMTPRContent({ ...showMTPRContent, [id]: !showMTPRContent[id] });

  return <div className='signOffItem'>
    <div className='header'>
      Monthly Treatment Plan Review (MTPR)
    </div>
    {
      _.map(monthlyReviews, (monthlyReview) => {
        const { id, createdAt, note, updatedAt, reviewedBy, type } = monthlyReview;
        if(!reviewedBy || !_.includes(type, 'MTPR'))
          return null;
        const reviewedByName = _.get(reviewedBy, 'profile.fullName');

        return (
          <div key={id} className='signOffCard signOffMTPR'>
            <Button type='link' onClick={() => handleToggleContent(id)}>
              {`${!showMTPRContent[id] ? 'View' : 'Hide'}`} MTPR Content
            </Button>
            {
              showMTPRContent[id] &&
              <SignOffMTPR.Content
                key={id}
                monthlyReview={monthlyReview} 
                renderVitalsSummaryPopup={renderVitalsSummaryPopup}
              />
            }
            <div className='description'>
              Generated on: {getTimeWithFormat(createdAt)}
            </div>
            <div className='description note'>
              {
                note && 
                <p className='hideOnList'>
                  {
                    `Note: 
                    ${note}`
                  }
                </p>
              }
              <SignOffSubmitInfo 
                submittedBy={reviewedByName}
                submittedAt={updatedAt}
              />
            </div>
          </div>
        );
      })
    }
    {draggablePopups.map(dPu => dPu.render(draggablePopups, dPu.isVisible))}
  </div>;
}

SignOffMTPR.Content = (props) => {
  const { monthlyReview, renderVitalsSummaryPopup } = props;
  const { id, goals, previousVitals, previousGoals, preSummary } = monthlyReview || {};
  const previousVitalCounts = Object.assign({}, ..._.map(previousVitals, v => ({ [v.vital]: v.count })));

  const monthlyReviewSummary = preSummary || {};

  const availableGoals = useMemo(() => _.map(goals, g => ({
    condition: CONDITION_ENUM_MAP[g.condition] || g.condition, // tab name
    goalType: g.condition,
    associatedVitals: _.split(g.vital, VITAL_DELIMITER),
  })), [goals]);

  const handleRenderPopup = (goalType) => renderVitalsSummaryPopup(goalType, {
    availableGoals,
    monthlyReviewSummary,
    isMRE: false,
    locateAssessment: false,
    popupKey: `vitalsSummary-${id}`
  });

  const renderMonthlyMeasurementDays = (goal) => {
    const { condition, vital } = goal || {};
    let vitals = _.split(vital, VITAL_DELIMITER);
    vitals  = _.filter(vitals, v => !!v);

    const handleClick = (buttonKey) => {
      if(buttonKey) {
        handleRenderPopup(condition);
      }
    };

    const ButtonTemplate = props => (
      <Button 
        id={props.buttonKey}
        className={`monthly-measurement-days`}// ${isClicked[props.buttonKey] ? 'clicked' : ''}`}
        style={{...props.style}} 
        onClick={() => handleClick(props.buttonKey)}
      >
        {props.children}
      </Button>
    );

    return vitals.length ? 
      _.map(vitals, associatedVital => {
        const vitalName = associatedVital === 'HS' ? 'WT' : associatedVital;
        const measurementCount = previousVitalCounts[associatedVital] || 0;
        const buttonKey= `${condition}-${vitalName}-SummaryBtn`;
        return (<ButtonTemplate key={buttonKey} buttonKey={buttonKey}>
          <div className='content'>
            <Tooltip overlayClassName='monthly-measurement-days-tooltip' /*title='View vital measurement summary'*/>
              <img src={`${IMG_PATH}/${associatedVital}-vital.svg`} height='14px' style={{ marginTop: -5 }}/>
                <span className='number-of-days'>{measurementCount}</span>
              <span>Days of meas.</span>
            </Tooltip>
            {
              measurementCount < 12 &&
              <div className='note'>  
                <Tooltip
                  overlayClassName='monthly-measurement-days-note-tooltip'
                  title={goalI18N.tips.dayNote.MTPR.replace('XXX', vitalName)}
                >
                  <div className='info-icon'>!</div>
                </Tooltip>
              </div>
            }
          </div>
        </ButtonTemplate>)
      }) : (<ButtonTemplate>
        <span>No associated vital</span>
      </ButtonTemplate>);
  };

  return (<div className='contentWrapper'>
    {
      _.map(goals, (goal) => {
        const { condition, value, status } = goal;
        const previousGoal = _.find(previousGoals, { conditionEnum: condition }) || {};
        const previousGoalValue = _.get(previousGoal, 'goalEnum');

        return <div key={condition} className='condition'>
          <div className='conditionText'>
            {CONDITION_ENUM_MAP[condition] || condition}
          </div>
          <div className='vital'>
            {renderMonthlyMeasurementDays(goal)}
          </div>
          <div className='goal'>
            <Tabs>
              <Tabs.TabPane key='newGoal' tab={<div>New Goal</div>}>
                <div className='goalValue'>
                  {CLINIC_GOAL_ENUM[value] || value}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane key='previousGoal' tab={<div>Previous Goal</div>}>
                <div className='goalValue'>
                  {CLINIC_GOAL_ENUM[previousGoalValue] || previousGoalValue}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>;
      })
    }
  </div>);
};

export default SignOffMTPR;