import gql from 'graphql-tag';
import MonthlyReviewSummarySchema from './schema/MonthlyReviewSummary';

export default gql`
query getMonthlyReviewSummary($memberId:EID) {
    getMonthlyReviewSummary(memberId:$memberId) {
      BG {
        ${MonthlyReviewSummarySchema}
      }
      BP {
        ${MonthlyReviewSummarySchema}
      }
      PO {
        ${MonthlyReviewSummarySchema}
      }
      TM {
        ${MonthlyReviewSummarySchema}
      }
      HS {
        ${MonthlyReviewSummarySchema}
      }
    }
  }`