import {_} from 'ihcomponent'
import { calcFormStatus, convertToFieldsValue, getInitDataFromSchema, getFieldsRequiredFromSchema } from 'libModule/helpers/reducer-helpers'
import {schema as returnSchema} from 'modulesAll/vendor/patient/components/ReturnDevicesForm'
import {schema as replaceSchema} from 'modulesAll/vendor/patient/components/ReplaceDevicesForm'

const schema = {...returnSchema, ...replaceSchema}
// use to set form init data with antd's setFieldsValue method
const initData = getInitDataFromSchema(schema)

// use to validate the form
const isFieldsRequired = getFieldsRequiredFromSchema(schema)

// antd's form data structure
// { description : {name: 'description', value: ''} }
const initFieldsValue = convertToFieldsValue(initData)


export const commonReplaceReturnDevicesInitState = {

  replaceOrReturnDevicesStep: 1,
  returnDevicesStep: 1,
  devicesStatus : [] ,

}

export const commonReplaceReturnDevicesInitForm = {
  //for return replace devices form
  // initData,
  fieldsValue: initFieldsValue,
  isFormValid: calcFormStatus(initFieldsValue, isFieldsRequired),
  isFieldsRequired,
  isFormSaved: true,
  isSubmitting: false,
}


export const commonReplaceReturnDevicesReducer = (state, action, types) => {
  switch (action.type) {
  case types.SET_REPLACE_DEVICES_STEP:
  return {
    ...state,
    replaceOrReturnDevicesStep: action.replaceOrReturnDevicesStep
  }

  case types.SET_RETURN_DEVICES_STEP:
  return {
    ...state,
    returnDevicesStep: action.returnDevicesStep
  }

  case types.CHANGE_DEVICES_INFO:


  const updateDevicesStatus = Object.assign([], state.devicesStatus);
  const index =  _.findIndex(updateDevicesStatus, {deviceId: action.devicesStatus.deviceId});

  if (-1 === index) {
    updateDevicesStatus.push(action.devicesStatus)
  }else{
    updateDevicesStatus[index] = _.merge( updateDevicesStatus[index],  action.devicesStatus)
  }


  return {
    ...state,
    devicesStatus: updateDevicesStatus

  }

  case types.INIT_DEVICES_INFO:
  return {
    ...state,
    devicesStatus : action.devicesStatus
  }

  case types.RESET_DEVICES_INFO:
  return {
    ...state,
    devicesStatus : commonReplaceReturnDevicesInitState.devicesStatus
  }

  default:
    return state
 }

}

export const commonReplaceReturnDevicesFormReducer = (state, action, types) => {
  switch (action.type) {

    case types.FORM_SAVED:
      return {
        ...state,
        isFormSaved: true,
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormValid: calcFormStatus(newFieldsValue, isFieldsRequired),
        isFormSaved: false
      }
    case types.RESET_FORM:
      return {
          ...state,
          ...commonReplaceReturnDevicesInitForm
      }
    case types.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }

    default:
    return state

  }
}
