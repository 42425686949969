import React from 'react';
import I18N from '../../I18N';
import { Select, Checkbox, Col, Radio, Popover, Input, Icon, Row } from 'antd';
import { helpers } from '../../visit/helpers';
import { ELIGIBLE_PROGRAMS, VITAL_TYPE_MAP } from '../constant';
import { CONDITION_LIST } from '../constant/programEligibility';
import excludedReasons from '../../remoteEnrollment/constant/excludedReasons';
import { doesOrgHaveCCM, doesOrgHaveRPM, doesOrgHaveValueBased,getOrgProgramParticipation} from "../../../lib/utils";

export const PROGRAM_ELIGIBILITY_KEYS = {
  CONDITION_LIST: 'conditionsList',
  VITALS_LIST: 'recommendVitalsList',
  ELIGIBLE_PROGRAMS: 'eligiblePrograms',
  ANOTHER_CCM_PROVIDER: 'anotherCCMProvider',
  JOIN_PROGRAM: 'joinProgram',
  DEVICE_ACCESSABILITY: 'deviceAccessability',
  EXCLUSION_REASON: 'exclusionReason',
  EXCLUSION_REASON_OTHER: 'exclusionReasonOther',
  SMARTPHONE_SKILLS: 'smartPhoneSkills',
};

export const conditionList = ({ onChange, ...configs } = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.CONDITION_LIST,
	label: '',
  ...configs,
	render: ({ disabled }) => {
		return (
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        {_.map(CONDITION_LIST, (v, k) => <Col span={6} key={k}><Checkbox value={k}>{v}</Checkbox></Col>)}
      </Checkbox.Group>
    );
	}
});

export const recommendVitalsList = ({ disabledKeys, onVitalsChange, ...configs} = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.VITALS_LIST,
	label: I18N.get('referAndEnroll.recommendVitalsHeader'),
  ...configs,
	render: ({ disabled }) => {
		return (
      <Checkbox.Group style={{ width: '100%' }} disabled={disabled} onChange={newValues => {
        if(typeof onVitalsChange === 'function') {
          onVitalsChange(newValues);
        }
      }}>
        {_.map(VITAL_TYPE_MAP, (v, k) => {
          const disabledObj = disabledKeys && _.find(disabledKeys, x => x.key === k);
          return (
          <Col span={6} key={k}>
            {!!disabledObj && disabledObj.reason
            ? <Popover trigger="hover" content={disabledObj.reason}>
              <Checkbox value={k} disabled={true}>{v}</Checkbox>
            </Popover>
            : <Checkbox value={k} disabled={!!disabledObj}>{v}</Checkbox>
          }
          </Col>
          )
        })}
      </Checkbox.Group>
    );
	}
});
const assignEligiblePrograms = (filteredEligiblePrograms,programs)=>{
	return Object.assign(filteredEligiblePrograms,_.pick(ELIGIBLE_PROGRAMS,programs));
}
export const eligiblePrograms = ({ disabledKeys, ...configs} = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.ELIGIBLE_PROGRAMS,
	label: I18N.get('referAndEnroll.eligibleProgramsHeader'),
  ...configs,
	render: ({ disabled }) => {
		const orgHasCCM = doesOrgHaveCCM();
    const orgHasRPM = doesOrgHaveRPM();
		const orgHasValueBased = doesOrgHaveValueBased();
		let filteredEligiblePrograms = { };
		filteredEligiblePrograms = orgHasCCM ? assignEligiblePrograms(filteredEligiblePrograms,['CCM']) : filteredEligiblePrograms;
		filteredEligiblePrograms = orgHasRPM ? assignEligiblePrograms(filteredEligiblePrograms,['RPM']) : filteredEligiblePrograms;
		filteredEligiblePrograms = orgHasValueBased ? assignEligiblePrograms(filteredEligiblePrograms,['VALUE_BASED']) :filteredEligiblePrograms;
		return (
      <Checkbox.Group style={{ width: '100%' }} disabled={disabled}>
        {_.map(filteredEligiblePrograms, (v, k) => {
          const disabledObj = disabledKeys && _.find(disabledKeys, x => x.key === k);
          return (
          <Col span={8} key={k}>
            {!!disabledObj && disabledObj.reason
            ? <Popover trigger="hover" content={disabledObj.reason}>
              <Checkbox value={k} disabled={true}>{v}</Checkbox>
            </Popover>
            : <Checkbox value={k} disabled={!!disabledObj}>{v}</Checkbox>
          }
          </Col>
          )
        })}
        { _.get(getOrgProgramParticipation(),'length',0)==0 ? 'Currently, there is no program available based on clinic setting. Please contact the site manager for support.' :''}
      </Checkbox.Group>
    );
	}
});

export const anotherCCMProvider = ({ onChange, ...configs } = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.ANOTHER_CCM_PROVIDER,
	label: (
    <div style={{ textAlign: 'left', marginBottom: 10 }}>
      <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.hasOtherCCMProviderHeader')}</div>
      <div style={{ fontWeight: 'normal' }}>{I18N.get('referAndEnroll.hasOtherCCMProviderSubheader')}</div>
    </div>
  ),
  ...configs,
	render: ({ disabled }) => {
    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio style={radioStyle} value={1}>Yes</Radio>
        <Radio style={radioStyle} value={0}>No</Radio>
      </Radio.Group>
    );
	}
});

export const wantToJoinProgram = ({ onChange, ...configs } = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.JOIN_PROGRAM,
	label: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontWeight: 'bold', fontSize: 15, marginRight: 20 }}>{I18N.get('referAndEnroll.joinProgramQuestion')}</div>
      <Popover trigger="hover" overlayStyle={{ width: 500 }} content={
        <div>
          <div style={{ fontWeight: 'bold' }}>{I18N.get('referAndEnroll.whyChooseUnifiedCareHeader')}</div>
          <div>{I18N.get('referAndEnroll.whyChooseUnifiedCareDescription')}</div>
        </div>
      }>
        <a>{I18N.get('referAndEnroll.introduceUnifiedCare')}</a>
      </Popover>
    </div>
  ),
  ...configs,
	render: ({ disabled }) => {
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio value={1}>Yes</Radio>
        <Radio value={0}>No</Radio>
      </Radio.Group>
    );
	}
});

export const deviceAccessability = ({ onChange, ...configs } = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.DEVICE_ACCESSABILITY,
	label: (
    <div style={{ textAlign: 'left' }}>
      <div style={{ fontWeight: 'bold', fontSize: 15 }}>{I18N.get('referAndEnroll.deviceAccessabilityTitle')}</div>
      <div style={{ fontWeight: 'normal', fontSize: 14 }}>{I18N.get('referAndEnroll.deviceAccessabilityDescription')}</div>
    </div>
  ),
  ...configs,
	render: ({ disabled }) => {
		return (
      <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
        <Radio value={1}>Yes</Radio>
        <Radio value={0}>No</Radio>
      </Radio.Group>
    );
	}
});

export const exclusionReason = ({ onChange, ...configs } = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.EXCLUSION_REASON,
	label: <div style={{ fontWeight: 'bold', fontSize: 15 }}>{I18N.get('referAndEnroll.reasonFormProgramExclusion')}</div>,
  formItemStyle: { marginBottom: 0 },
  ...configs,
	render: ({ disabled }) => {
		return (
      <Select style={{ width: 300 }} onChange={onChange} disabled={disabled}>
        {_.map(excludedReasons, reason => (
          <Select.Option value={reason.key} key={reason.key}>
            {reason.value}
          </Select.Option>
        ))}
      </Select>
    );
	}
});

export const exclusionReasonOther = (configs = {}) => ({
	key: PROGRAM_ELIGIBILITY_KEYS.EXCLUSION_REASON_OTHER,
	label: '',
  ...configs,
	render: ({ disabled }) => {
		return (
      <Input.TextArea style={{ width: 300, height: 100 }} disabled={disabled} />
    );
	}
});

const popOverContent = 'Loaner phone is a pre set-up device (iPhone 6) for patient to support daily vitals monitoring. ' +
  '(Please check with your organization to see if any extra fee will be applied)';
export const SMARTPHONE_SKILL = {
  SMART_PHONE_PREFERRED: (
    <span>
      Patient is OK to use smartphone, give patient Unified Care loaner phone.&nbsp;
      <Popover trigger="hover" content={popOverContent}>
        <Icon type="info-circle" theme="filled" />
      </Popover>
    </span>
  ),
  SMART_PHONE_NOT_PREFERRED: 'Patient prefers a non-smartphone solution. (Patient will only be able to enroll in blood pressure and RPM program)',
  NOT_ELIGIBLE: 'Patient doesn’t feel comfortable to use smartphone, not eligible to enroll.',
};

export const smartPhoneSkills = ({ onChange, selectedValues, ...configs } = {}) => {
  const { recommendedVitals } = selectedValues || {};
  const isDisabledOption = (k) => {
    let meetRequirements = true;
    switch(k) {
      case 'SMART_PHONE_NOT_PREFERRED':
        // const requiredConditions = [CONDITION_LIST.HTN, CONDITION_LIST.CKD];
        const requiredVitals = ['BP'];
        meetRequirements &= !!_.intersection(recommendedVitals, requiredVitals).length;
        break;
      default:
        break;
    }
    return !meetRequirements;
  };
  return {
    key: PROGRAM_ELIGIBILITY_KEYS.SMARTPHONE_SKILLS,
    label: '',
    subText: (
      <div style={{ textAlign: 'left', marginBottom: 10 }}>
        <div style={{ fontWeight: 'bold', fontSize: 15, lineHeight: '17px', color: '#2d2d2d' }}>{I18N.get('referAndEnroll.patientSkillLevel')}</div>
      </div>
    ),
    ...configs,
    render: ({ disabled }) => {
      return (
        <Radio.Group style={{ width: '100%' }} onChange={onChange} disabled={disabled}>
          {_.map(SMARTPHONE_SKILL, (v, k) => {
            const isDisabled = isDisabledOption(k);
            return <Row style={isDisabled ? {
              color: 'rgba(0,0,0, 0.3)',
              cursor: 'not-allowed'
            } : {}}>
              <Radio key={k} value={k} disabled={isDisabledOption(k)}></Radio>{v}
            </Row>
          })}
        </Radio.Group>
      );
    }
  };
};
