import React from 'react';
import { Row,Col } from 'antd';
import PinnedPatientsComponent from '../component/PinnedPatientsComponent';
import {pinnedPatients} from "../API";
const PinnedPatientsContainer = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pinnedPatients:[]
        }
    }

    renderHeader = (count)=>{
        return <span className={'header'}>Pinned Patient Cases ({count})</span>
    }
    render() {
        const { loading,pinnedPatients } = this.props;
        const count = (pinnedPatients || []).length;

        return <Row className={'pinnedPatientsWrapper'}>
                <Col span={24}>{this.renderHeader(count)}</Col>
                <Col span={24}>
                    {count == 0
                        ? <p className={'no-pinned-text'}>
                            You have 0 pinned cases. If you ever need to come back to a patient later, you
                            can pin the case to access it easily from here.
                        </p>
                        : <PinnedPatientsComponent {...this.props} loading={loading} pinnedPatients={pinnedPatients} />
                    }
                </Col>
               </Row>
    }
}

export default PinnedPatientsContainer;
