
// Check if a String is base64 encoded or not
const checkIfEncoded = (str) => {
  if (str.match(/^[0-9a-fA-F]{24}$/)) {
    return false;
  } else {
    return true;
  }
}

export const decodeId = (id) => _.last(_.split(atob(id), ':'));

export const checkAndDecodeId = (id) => {
  const isDecoded = !checkIfEncoded(id);
  return isDecoded ? id : decodeId(id);
}

export const COMMON_HELPERS = {
  checkIfEncoded,
};
