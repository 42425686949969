import { Modal } from 'antd';

const DEFAULT_CONTENT = 'You have unsent article(s), do you want to complete this section and send the articles?';
const DEFAULT_OK_TEXT = 'Complete and Send';
export default ({
  submitButtonSelector = '#submit-article-button',
  content = DEFAULT_CONTENT,
  okText = DEFAULT_OK_TEXT,
} = {}) => {
  const submitButton = document.querySelector(submitButtonSelector);
  if (submitButton) {
    return new Promise((resolve) => {
      Modal.confirm({
        content,
        okText,
        onOk: () => {
          submitButton.click();
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        }
      })
    });
  }
  return true;
};