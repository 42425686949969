const patientListPath ='/csv-file/patientList'
const API_KEY = process.env.API_KEY || window.VSM_CONFIG.API_KEY;
const sessionToken = (()=>{
	const sessionToken = sessionStorage.getItem('authToken');
	return sessionToken
})
const SERVER_URL = process.env.SERVER_URL || window.VSM_CONFIG.SERVER_URL || `http://${location.hostname}:4000`

export const postMethod = async ({query}) =>{
	const response = await fetch(`${SERVER_URL}${query ? query :''}`,{
		method: 'POST',
		headers: {
			'x-session-token':sessionToken(),
			'cache-control': 'no-cache',
			Connection: 'keep-alive',
			'Accept-Encoding': 'gzip, deflate',
			Accept: '*/*',
			'x-api-key':API_KEY,
			'content-type': 'application/octet-stream'
		}
	})
	if(response.status==200) {
		return response.text();
	}else{
		throw new Error();
	}
}
export const getPatientsList = async (query,successModal,failModal,cb) => {
	let res;
	try {
		successModal();
		res = await postMethod({query});
		if(cb){
			cb();
		}
	}
	catch (e){
		console.log(e);
		failModal()
	}

	// if(cb) {

	// 	cb()
	// }
	return res;
}