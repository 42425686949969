import React from 'react';
import {compose, withState, withPropsOnChange, branch} from 'recompose';
import createTableAction from 'libModule/table/TableActions';
import CrossClinicTabContainer from './CrossClinicTabContainer';
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import helper from "../../remoteEnrollment/helper";
import defaultSortAndFilterMap from "../../remoteEnrollment/constant/defaultSortAndFilterMap";
import query from '../../remoteEnrollment/query';
import { setClinics } from '../API/index';
import { patientList,clinicCandidatesQuery } from '../query/index';
import roleHelpers from "../../taskAssignment/helper";
import {roleNameMap} from "../../utils/constants/role";
import SelectClinicComponent from "../../workStation/component/SelectClinicComponent";
import {Col, Modal, Popover, Row, Tabs} from "antd";
import '../../remoteEnrollment/style/index.scss';
import { IHLoading } from 'ihcomponent';
import {getMeasurementDaysFilter, getProgramsEnrolledFilter} from "../../patientList/main/helper/searchHelper";
import ReviewPatientTabContainer from '../../rpmPatientReview/containers/ReviewPatientTabContainer';
import { RPM_TAB_TITLE_PLACEHOLDER } from '../../rpmPatientReview/constants';

const DEFAULT_SORT = {
	field:'TIME_SPENT',
	direction:'DESC'
}
const NOT_NECESSARY = "not-necessary";

const DEFAULT_FILTER = { 'timeSpent':['15:20'] };

const CrossClinicPatientsContainer = class extends React.Component{
	constructor() {
		super();
		this.state = {
			showCoveredClinicsModal:false,
      rpmPatientListCount: 0,
		}
	}

  loadParams = () => {
    const { onLoadParams, tableProps } = this.props;
		onLoadParams(tableProps, true);
  }

  handleOnTabChange = (tabKey) => {
    if (tabKey === 'crossClinicPatientList') {
      this.loadParams();
    }
    this.setState({ tabKey });
  }

	componentDidMount() {
    this.loadParams();
	}
	setShowCoveredClinicsModal=(showCoveredClinicsModal)=>{
		this.setState({
			showCoveredClinicsModal
		});
	}
	clinicName = (selectedClinic)=>{
		return <div style={{ maxHeight:'460px',overflow:'scroll' }}>{selectedClinic.map(({name}) => <Row style={{display:'flex',flexDirection:'row'}}>
			<Col style={{width:'fit-content',lineHeight:'2'}}>{name}</Col>
		</Row>)}</div>
	}
	setSelectOrg = (orgs )=>{
		return setClinics({boostedOrganizations:_.get(orgs,'organizations',[])});
	}
	render() {
		const { tableProps={},patientList,selectedClinic=[],clinicCandidates=[],refetchSelectedOrgs,refetchPatientList,patientListLoading,loadingSelectedClinic,pageInfo } =  this.props;
		const { filter,sort,page } = tableProps;
		const { showCoveredClinicsModal, rpmPatientListCount, tabKey } = this.state;
		const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
		const teamsFromLoginUser = loginUser.team || [];
		const CAs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.CA]);
		const RDAndHCs = roleHelpers.getMembersByRole(teamsFromLoginUser, [roleNameMap.RD, roleNameMap.HC]);
		return <div style={{ padding:20 }}>
				<Row style={{ display:'flex' }} className='remoteEnrollmentRowWrapper'>
					<Col>
						<h4>Patient List</h4>
					</Col>
					<Col style={{ marginLeft:20 }}>
					<Popover content={this.clinicName(selectedClinic)} trigger={'hover'} placement={'rightBottom'}>
						<div onClick={()=>this.setShowCoveredClinicsModal(true)} className={'fromClinicTitle'}>from {selectedClinic.length} <span>clinics</span></div>
					</Popover>
					</Col>
				</Row>
				<Modal visible={showCoveredClinicsModal}
				       width='fit-content'
				       maskClosable={false}
				       onCancel={()=>this.setShowCoveredClinicsModal(false)}
				       footer={null}
				       bodyStyle={{ padding: '48px 63px 54px 57px'}}
				       destroyOnClose
				>
					<div style={{ marginLeft: 15 }}>
						{
							loadingSelectedClinic ? <IHLoading/> :
							<SelectClinicComponent target={'CROSS_CLINIC_PATIENTS'}
							                       setShowCoveredClinicsModal={this.setShowCoveredClinicsModal}
							                       refetchSelectedOrgs={refetchSelectedOrgs}
							                       clinicCandidates={clinicCandidates}
							                       setSelectedOrgsMutation={this.setSelectOrg}
							                       selectedClinic={selectedClinic}
							                       refetchRemoteEnrollmentSummary={refetchPatientList}/>
						}
					</div>
				</Modal>
        <Tabs
          onChange={this.handleOnTabChange}
        >
          <CrossClinicTabContainer
            {...this.props} 
            key="crossClinicPatientList" 
            tab={`Patients to reach 16 days or 20 minutes (${_.get(pageInfo, 'total') || 0})`}
            patientListLoading={patientListLoading} 
            page={page} 
            pageInfo={pageInfo}
            filter={filter} 
            sort={sort} 
            patientList={patientList} 
            CAs={CAs} 
            RDAndHCs={RDAndHCs}
          />
          <ReviewPatientTabContainer 
            key="rpmPatientList" 
            tab={RPM_TAB_TITLE_PLACEHOLDER.replaceAll('[count]', rpmPatientListCount)}
            setRPMPatientListCount={(count) => this.setState({ rpmPatientListCount: count || 0 })}
            // to unmount then trigger load params
            isActive={tabKey === 'rpmPatientList'}
          />
        </Tabs>
		</div>
	}
}
const parseFilter = (filter)=>{
	const { timeSpent } = filter;
	if(timeSpent) {
		let timeSpentObj = (() => {
			return _.map(timeSpent, (t) => {
				const timeRange = t.split(':');
				return { min: timeRange[0]*60, max: timeRange[1]*60 };
			})
		})()
		filter.timeSpent = timeSpentObj;
	}

	if(filter.assignedToRD){
		let assignedToRD = [];
		_.forEach(filter.assignedToRD, assignee => {if(!_.includes(['null', 'not-necessary'], assignee)) assignedToRD.push(assignee)});
		if(_.includes(filter.assignedToRD, 'null')) assignedToRD.push(null);
		if(_.includes(filter.assignedToRD, NOT_NECESSARY)){
			filter.assignedToRDNotNecessary = true;
		} else {
			filter.assignedToRDNotNecessary = false;
		}
		filter.assignedToRD = assignedToRD;
	}

	if(filter.assignedToCA){
		let assignedToCA = [];
		_.forEach(filter.assignedToCA, assignee => {if(!_.includes(['null', 'not-necessary'], assignee)) assignedToCA.push(assignee)});
		if(_.includes(filter.assignedToCA, 'null')) assignedToCA.push(null);
		if(_.includes(filter.assignedToCA, NOT_NECESSARY)){
			filter.assignedToCANotNecessary = true;
		} else {
			filter.assignedToCANotNecessary = false;
		}
		filter.assignedToCA = assignedToCA;
	}
	if(_.isEmpty(filter.assignedToRD )){
		filter = _.omit(filter, ['assignedToRD']);
	}
	if(_.isEmpty(filter.assignedToCA )){
		filter = _.omit(filter, 'assignedToCA');
	}
	filter = Object.assign({},getMeasurementDaysFilter(filter,true));
	filter = getProgramsEnrolledFilter(filter);

	return filter;
}
const data = graphql(patientList, {
	options:(props)=>{
		const { tableProps } = props;
		const sort = DEFAULT_SORT;
		let filters = Object.assign({},_.get(tableProps,`filter`));
		let newFilter = parseFilter(filters);
		const  page = _.get(tableProps, `page.current`, 1);
		const  count = _.get(tableProps, `page.pageSize`, 10);
		return{
			variables:{
				sort:sort,
				filters:newFilter,
				page,
				count
			},
			fetchPolicy:'network-only'
		}
	},
	props: ({ data }) => {
		const { loading,boostedPatientList,refetch } = data;
		if(loading){
			return {
				patientListLoading: loading
			}
		}
		return {
			pageInfo:_.get(boostedPatientList,'pageInfo'),
			patientList: _.get(boostedPatientList,'data',[]),
			refetchPatientList: refetch
		};
	}
});

const getClinicCandidates = graphql(query.clinicCandidatesQuery,{
	props:({data})=>{
		const { loading,getClinicCandidates,refetch } = data;
		return {
			clinicCandidates: getClinicCandidates || []
		}
	}
});

const getSelectedOrgs = graphql(clinicCandidatesQuery,{
	options:()=>{
		return {
			fetchPolicy:'network-only',
			notifyOnNetworkStatusChange: true
		}
	},
	props: ({data})=>{
		const { loading,getSelectedClinic,refetch } = data;
		if(loading) {
			return {
				loadingSelectedClinic: loading
			}
		}
		return {
			selectedClinic: _.get(getSelectedClinic,'boostedOrganizations') || [],
			refetchSelectedOrgs: refetch,
			loadingSelectedClinic:loading
		}
	}
});
const mapStateToProps = state => ({
	tableProps: state.globalTables.crossClinic_patient_list,
});
const mapDispatchToProps = dispatch => ({
	// table actions, and initState if it doesn't exist
	...createTableAction('crossClinic_patient_list',{
		shouldUpdateHistory:true,
		sortFieldMap: {
			age: 'BIRTHDAY',
			fullName:'LAST_NAME',
			timeSpent:'TIME_SPENT',
			enrolledDate:'ENROLLMENT_DATE',
			registeredAt: 'REGISTERED',
			monthlyMeasurementDaysRange: 'MONTHLY_MEASUREMENT_DAYS'
		},
		initState:{
			sort:DEFAULT_SORT,
			filter: DEFAULT_FILTER
		}
	}),
	showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user)),
	openErrorModal:(error)=>dispatch(openErrorModal(error)),
});
export default compose(connect(mapStateToProps, mapDispatchToProps),getSelectedOrgs,data,getClinicCandidates)(CrossClinicPatientsContainer);
