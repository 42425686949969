import gql from 'graphql-tag';

export default gql`
mutation setUserPassword(
  $id: EID!,
  $newPass: String!
){
  setUserPassword(
    id: $id,
    newPass: $newPass,
) {
    id
    active
    sessionToken
    token
    username
  }
}`