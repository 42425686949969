import gql from 'graphql-tag';

export default gql`
  query getLatestA1C($memberId:EID!) {
        getLatestA1C(memberId:$memberId) {
            id
            dateCollected
            results{
                value
                isInHouse
            }
        }
  }
`
