import _ from 'lodash'

const isFormFieldValid = (fieldObject, isRequired) => {
  if (!fieldObject.errors) {
    if ((isRequired && fieldObject.value !== '') || !isRequired) {
      return true
    }
  }
  return false
}

// antd form
// fieldsValue: { description : {name: 'description', value: 'Ipsum'} }
export const calcFormStatus = (fieldsValue, isFieldsRequired) => {
  return Object.keys(fieldsValue).every(key => {
    return isFormFieldValid(fieldsValue[key], isFieldsRequired[key])
  })
}

// antd form, convert fieldsValue(object) to table data item(object)
export const convertFieldsValue= (fieldsValue) => {
  const result = {}
  Object.keys(fieldsValue).map(key => {
    result[key] = fieldsValue[key].value
  })
  return result
}

// antd form, convert initData(object) to fieldsValue(object)
export const convertToFieldsValue= (initData) => {
  const result = {}
  Object.keys(initData).forEach(key => {
    result[key] = {name: key, value: initData[key] }
  })
  return result
}

// const schema = {
//   iHealthBP: {
//     initialValue: 'BP1',
//     required: true
//   }
// }
// antd form, convert schema(object) to initData(object)
export const getInitDataFromSchema= (schema) => {
  const result = {}
  const clonedSchema = _.cloneDeep(schema)
  Object.keys(clonedSchema).forEach(key => {
    result[key] = clonedSchema[key].initialValue
  })
  return result
}

// antd form, convert schema(object) to fieldsRequired(object)
export const getFieldsRequiredFromSchema= (schema) => {
  const result = {}
  Object.keys(schema).forEach(key => {
    result[key] = Boolean(schema[key].required)
  })
  return result
}

// antd form, convert schema(object) to fieldsDisabled(object)
export const getFieldsDisabledFromSchema= (schema) => {
  const result = {}
  Object.keys(schema).forEach(key => {
    result[key] = Boolean(schema[key].disabled)
  })
  return result
}



export const commonInitialState = (types) => {
  let initialState = {}
  if(types.ON_CHECK_TABLE_ROW) {
    initialState = Object.assign(initialState, {
      selectedRowKeys: [],
    })
  }

  return initialState
}

export const commonReducer = (state, action, types) => {
  let pagination = _.clone(state.pagination) || {}
  switch (action.type) {
    case types.SORT:
      return {
        ...state,
        sorter: action.sorter
      }
    case types.CHANGE_PAGE:
      pagination.current = action.currentPage
      return {
        ...state,
        pagination,
        currentPage: action.currentPage,
      }
    case types.ON_CHECK_TABLE_ROW:
      return {
        ...state,
        selectedRowKeys: action.selectedRowKeys
      }
    case types.INIT_DATA:
      return {
        ...state,
        initData: action.initData
      }
    case types.UPDATE_FORM:
      const result = {
        ...state,
        fieldsValue: action.fieldsValue,
      }
      if (action.hasOwnProperty('isEditMode')) {
        result.isEditMode = action.isEditMode
      }
      return result
    case types.FORM_SAVED:
      return {
        ...state,
        isFormSaved: true
      }
    case types.FORM_FIELD_CHANGE:
      const newFieldsValue = {
        ...state.fieldsValue,
        ...action.fieldsValue
      }
      return {
        ...state,
        fieldsValue: newFieldsValue,
        isFormSaved: false
      }
    case types.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }
    case types.SET_STEP:
      return {
        ...state,
        step: action.step
      }
    case types.ON_SEARCH:
      return {
        ...state,
        searchText: action.text
      }
    default:
      return state
  }
}
