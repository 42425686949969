import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Divider } from 'antd';
import { compose } from 'react-apollo';
import { IHLoading } from 'ihcomponent';

import { RPM_PATIENT_REVIEW_TIMER } from '../constants';
import ReviewActionCenterContainer from './ReviewActionCenterContainer';
import ReviewPopupHeaderComponent from '../components/ReviewPopupHeaderComponent';
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import useIntersectionObserver from '../helpers/useIntersectionObserver';
import useRouteContext from '../helpers/useRouteContext';
import PatientProfile from '../../patient/main/containers/ViewOnlyMain';
import ReviewPatientCompletedComponent from '../components/ReviewPatientCompletedComponent';
import { getRPMPatientQueue } from '../apis/getPatientList';
import {
  handleCheckAndChangeOrganization,
  handleReloadWhenSwitchOrg,
} from '../helpers';
import { ReviewPatientContextProvider } from '../helpers/useReviewPatientContext';

import '../styles/ReviewPopupContainer.scss';

const ReviewPopupContainer = (props) => {
  const {
    onCancel,
    currentPage,
    setCurrentPage,
    loadingBoostedPatientList,
    boostedPatientList,
    boostedPatientListCount,
    boostedPatientListLastPage,
    refetchBoostedPatientList,
  } = props;

  const route = useRouteContext();
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    if (loadingBoostedPatientList) return;

    if (Array.isArray(boostedPatientList) && boostedPatientList.length) {
      const newPatient = _.get(boostedPatientList, '0.user');
      handleNextPatient(newPatient);
    }
  }, [loadingBoostedPatientList]);

  const {
    intersected
  } = useIntersectionObserver({
    rootSelector: '.review-popup-container__modal__body__right-pane',
    elementSelector: '.vsm-patient-member-detail.patient-wrapper-6188',
    threshold: 0,
  });

  const handleFetchMore = (nextPage) => {
    // fetch more and try load patient again in useEffect
    setCurrentPage(nextPage);
  };

  const handleNextPatient = (
    nextPatient,
  ) => {
    setIsLoadingNext(true);
    if (nextPatient) {
      const { organization } = nextPatient;
      // check if need to switch org
      handleCheckAndChangeOrganization(
        organization,
        async (orgSwitched) => {
          if (orgSwitched) {
            await handleReloadWhenSwitchOrg({
              page: currentPage,
            });
          }
          setPatient(nextPatient);
          setIsLoadingNext(false);
        }
      );
      return;
    }

    let nextPage = currentPage + 1;
    if (nextPage > boostedPatientListLastPage) {
      // loop back
      nextPage = 1;
    }
    handleFetchMore(nextPage);
    setIsLoadingNext(false);
  };

  const handleOnSubmitReview = () => {
    // page is cleared, refresh the list at the same page
    // and try to load patient again in useEffect
    setPatient(null);
    refetchBoostedPatientList();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const renderSuccessPage = () => {
    return (
      <ReviewPatientCompletedComponent
        autoCloseIn={2}
        onClose={handleCancel}
      />
    );
  };

  const isLoading = loadingBoostedPatientList || !patient || isLoadingNext;

  // show when the header is not visible by [1 - threshold]%
  const showStickyHeader = !isLoading && intersected !== undefined && intersected === false;

  const isAllCompleted = boostedPatientListCount === 0;


  return (
    <div className="review-popup-container">
      <Modal
        visible
        onCancel={handleCancel}
        width={`${!isAllCompleted ? 80 : 40}vw`}
        className="review-popup-container__modal"
        footer={null}
        destroyOnClose
        maskClosable={false}
        closable={!isAllCompleted}
      >
        {
          !isAllCompleted
            ? (
              <div>
                <Row className="review-popup-container__modal__header">
                  <Col>
                    <ReviewPopupHeaderComponent
                      count={boostedPatientListCount}
                      onClickNext={handleNextPatient}
                      isLoadingNext={isLoading}
                    />
                  </Col>
                </Row>
                <Divider />
                {
                  (!isLoading)
                    ? (
                      <ReviewPatientContextProvider value={patient}>

                        <Row className="review-popup-container__modal__body">
                          <Col
                            span={6}
                            className="review-popup-container__modal__body__left-pane"
                          >
                            <ReviewActionCenterContainer
                              patientId={patient.id}
                              onSubmit={handleOnSubmitReview}
                              isLoadingNext={isLoadingNext}
                            />
                          </Col>
                          <Col
                            span={18}
                            className="review-popup-container__modal__body__right-pane"
                          >
                            <TimerClaimedContainer
                              patientId={patient.id}
                              id={RPM_PATIENT_REVIEW_TIMER}
                            />
                            <PatientProfile
                              patientId={patient.id}
                              timerName={RPM_PATIENT_REVIEW_TIMER}
                              showStickyHeader={showStickyHeader}
                              isInChat
                              route={route}
                            />
                          </Col>
                        </Row>
                      </ReviewPatientContextProvider>
                    ) : <IHLoading />
                }
              </div>
            ) : renderSuccessPage()
        }
      </Modal>
    </div>
  );
};

ReviewPopupContainer.propTypes = {
  page: PropTypes.number,
  onCancel: PropTypes.func
};

const EnhancedC = compose(
  getRPMPatientQueue,
)(ReviewPopupContainer);

export default (props) => {
  const { page, removeOption, ...restProps } = props;
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    removeOption();
  }, []);

  return <EnhancedC
    {...restProps}
    page={{ current: currentPage }} // for graphql
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
  />;
};
