import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertBP from 'modulesAll/graphql/insertBP';
import ManualInputContainer from './ManualInputContainer';
import {
  patientSystolicInputValidator,
  patientDiastolicInputValidator,
  patientHeartrateInputValidator
} from 'libModule/validator';

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/BPInputContainer'

  getVitalForm() {
    return [
      {
        type: 'rowGroup',
        rowGroupSpan: 24,
        gutter: 10,
        className: 'number-row',
        items: [
          {
            key: 'systolic',
            label: 'Systolic (mmHg)',
            type: 'inputNumber',
            rowGroupSpan: 8,
            rules : [
              {validator: (rule, value, callback) => callback(patientSystolicInputValidator(value))}
            ],
          },
          {
            key: 'diastolic',
            label: 'Diastolic (mmHg)',
            type: 'inputNumber',
            rowGroupSpan: 8,
            rules : [
              {validator: (rule, value, callback) => callback(patientDiastolicInputValidator(value))}
            ],
          },
          {
            key: 'heartrate',
            label: 'Heart Rate (beats/min)',
            type: 'inputNumber',
            rowGroupSpan: 8,
            rules : [
              {validator: (rule, value, callback) => callback(patientHeartrateInputValidator(value))}
            ],
          }
        ],
      },
    ];
  }

  insertVital(values, commonFields) {
    const { systolic, diastolic, heartrate } = values;

    const variables = {
      ...commonFields,
      systolic,
      diastolic,
      heartrate,
    };

    return this.props.insertBP({ variables })
      .then(res => _.get(res, 'data.insertBP.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Blood Pressure"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertBP, { name: 'insertBP' })
)(component);
