import React, { useMemo } from 'react';
import { Form } from 'antd';

const ReferAndEnrollForm = ({ header, children, buttons, onSubmit, className }) => {
  return (
    <Form onSubmit={onSubmit} className={className}>
      <div className="refer-and-enroll-step">
        <div className="refer-and-enroll-form-header">{header}</div>
        <div className="refer-and-enroll-form">
          {children}
        </div>
        <div className="refer-and-enroll-next-button">
          {buttons}
        </div>
      </div>
    </Form>
  );
}

export default ReferAndEnrollForm;
