import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Button, Modal, Popover } from 'antd';

import '../style/billableReviewForm.scss';
import visitAPI from '../../VideoChat/helper';

export const BillableReviewFormPopup = props => {
  const { 
    children, 
    placement, 
    trigger = 'click', 
    overlayClassName = '',
    visitId, 
    onSubmit, 
    isPopupDisabled = false 
  } = props;

  return isPopupDisabled ? 
  children : (
    <Popover 
      overlayClassName={`review-billable-popup ${overlayClassName}`}
      trigger={trigger}
      placement={placement}
      content={(
        <BillableReviewForm 
          visitId={visitId}
          onSubmit={onSubmit}
        />
      )}
      destroyTooltipOnHide
    >
      { children }
    </Popover>
  );
};

BillableReviewFormPopup.propTypes = {
  visitId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isPopupDisabled: PropTypes.bool,
  placement: PropTypes.string, 
  trigger: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ]), 
  overlayClassName: PropTypes.string,
};

const BillableReviewForm = props => {
  const { visitId, onSubmit } = props; 
  const [reviewBillable, setReviewBillable] = useState(null);

  const handleSubmit = _.debounce(async () => {
    visitAPI.reviewBillable(visitId, reviewBillable)
    .then(res => {
      onSubmit(res);
    })
    .catch(err => {
      console.error(err);
      Modal.error({ content: err.message });
    });
  }, 200);

  return (
    <div id='billable-review-form-wrapper'>
      <div className='description'>
        Did you review other billable topics as well, like scheduling, reminder on labs, medications, inactivity, vitals monitoring encouragement, etc.?
      </div>
      <Radio.Group 
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
        value={reviewBillable}
        onChange={e => setReviewBillable(e.target.value)}
      />
      <Button
        type='primary'
        onClick={handleSubmit}
        disabled={reviewBillable === null} // TODO: check if need to disable
      >
        Submit and checkout patient
      </Button>
    </div>
  );
};

BillableReviewForm.propTypes = {
  visitId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BillableReviewForm;