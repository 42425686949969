import gql from 'graphql-tag'
import {EnrolledProgram} from 'graphqlModule/schema/EnrolledProgram'

export default gql`
  mutation addOneTimeTaskToEnrolledProgram (
    $id: EID!,
    $type: TaskType!,
    $assessment: InputAssessmentTaskEnrolled
  ) {
    addOneTimeTaskToEnrolledProgram (
      id: $id,
      type: $type,
      assessment: $assessment
    ) {
      ${EnrolledProgram}
    }
  }
`
