import React, { forwardRef, useMemo } from 'react';
import { Radio } from 'antd';
import { useCGMContext } from '../../context/CGMContext';

export const CGMRadioGroupComponent = forwardRef(({
  disabled,
  ...antProps
}, ref) => {
  const {
    getAvailableCGMBrands,
  } = useCGMContext();

  const brandOptions = useMemo(() => {
    const availableBrands = getAvailableCGMBrands();
    return availableBrands;
  }, [getAvailableCGMBrands]);

  return (
    <Radio.Group
      {...antProps}
      ref={ref}
      disabled={disabled}
    >
      {
        brandOptions.map(({
          cgmBrand,
          name
        }) => (
          <Radio
            key={cgmBrand}
            value={cgmBrand}
          >
            {name}
          </Radio>
        ))
      }
    </Radio.Group>
  );
});
