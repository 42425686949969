import types from '../constants/VitalListWrapperConstants'
const initState = {
  currProgIdx: 0,
  range: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.CHANGE_RANGE:
      return {
        ...state,
        range: action.range
      }
    case types.CHANGE_PROGRAM:
      return {
        ...state,
        currProgIdx: action.currProgIdx
      }
    default:
      return state
  }
}
