const isMNTEnrolled = (props)=>{
    let enrollMNT = false;
    const programCategories = _.get(props, 'user.profile.programCategories', []);
    _.forEach(programCategories, ({name, enrolled}) => {
        if(name === 'MNT' && enrolled === true){
            enrollMNT = true;
        }
    })
    return enrollMNT;
}

const hasSomeValue = (obj)=>{
    obj = _.omit(obj, '__typename');
    for (let [key, value] of Object.entries(obj)) {
        if(typeof value=='object'){
            value = _.omit(value, '__typename');
            if(!_.isNull(value)&& hasSomeValue(value)) {
                // console.log(value);
                return true;
            }
        } else if(!_.isNull(value) || !_.isEmpty(value)) {
                // console.log(value);
                return true;
        }

    }
    return false;
}

export default {
    isMNTEnrolled,
    hasSomeValue
}
