export const getAPI_KEY = (env, userRole) => {
  // // dev server also use API_KEY, so can not use window.VSM_CONFIG.NODE_ENV === development
  // if (window.location.hostname === 'localhost' || window.VSM_CONFIG.IS_LOCAL || window.VSM_CONFIG.DEMO) {
  //   return window.VSM_CONFIG.API_KEYS_LOCAL && window.VSM_CONFIG.API_KEYS_LOCAL[userRole];
  // }
  // // should set this env when deploy by run script for different server
  // return window.VSM_CONFIG.API_KEY

  //TODO: always use local api key for now
  // return window.VSM_CONFIG.API_KEYS_LOCAL && window.VSM_CONFIG.API_KEYS_LOCAL[userRole];
  return process.env.API_KEY || window.VSM_CONFIG.API_KEY;
}
