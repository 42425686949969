export default {
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: {
    title: 'Chronic Obstructive Pulmonary Disease (COPD)',
    testLabel: 'Tested home blood oxygen before?',
    printoutName: 'home blood oxygen',
  },
  PRE_DIABETES: {
    title: 'Pre-diabetes',
    testLabel: 'Tested home BG before?',
    printoutName: 'home BG'
  },
  CHRONIC_KIDNEY_DISEASE: {
    title: 'Chronic Kidney Disease (CKD)',
    testLabel: 'Tested home BP before?',
    printoutName: 'home BP',
  },
  HYPERLIPIDEMIA: {
    title: 'Hyperlipidemia',
    testLabel: 'NONE',
    printoutName: 'NONE',
  },
  CONGESTIVE_HEART_FAILURE: {
    title: 'Congestive Heart Failure (CHF)',
    testLabel: 'Tested home Weight before?',
    printoutName: 'home weight',
  }
}
