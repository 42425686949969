import gql from 'graphql-tag';

export default gql`
	query organization($id:EID!) {
		organization(id: $id) {
			id
			leaf
			active
			identifier
            name
			brandedName
            EHR
			operationModel
			internalName
			programParticipation
			logo
            description
            managers{
                id
                fullName
            }
            timezone
            brandedName
            parent{
              id
              name
              internalName
              leaf
            }
			roles{
				refId
				name
				active
			}
			address{
				type
        		streetName
        		streetNumber
        		floor
        		unit
        		city
        		state
        		postCode
        		country{
          			description
          			code
        		}
			}   
			phone{
                countryCode
                number
			}
			timezone
			roles{
                refId
                name
            }
            createdAt
            updatedAt
            orgSummary{
				clinicCount
				areaCount
				roleCounts{
					roleType
					count
				}
			}
			excludeBilling
			templateGroups{
				id
				name
				templates{
				    language{
				        description
				    }
				    content
				}
			}
			orgPermissions {
                analyticsAPI
                useDefaultMobile
                enableABTesting
				pinnedToEMRRequired
				enableAutoMessage
				otherCCMProvider
		        keepOwnDevices
		        supportClear
		        supportLoanerPhone
            }
			orgSchedules {
				id
				affectiveDays
				blockingRoles
				startTime
				endTime
			}
			monthlyEnrollGoalCount
			potentialPatientCount
			monthlyEnrollGoalCountHistory {
				count
				createdAt
				month
				year
			}
		}
	}
`;
