import types from '../constants/Login'
import { commonActions } from 'libModule/helpers/action-helpers'

const next = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_STEP,
      step: 2
    })
  }
}

const forgotPassword = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_STEP,
      step: 1.5
    })
  }
}

const back = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_STEP,
      step: 1
    })
  }
}

export default {
  ...commonActions(types, ['handleFieldsChange', 'updateForm', 'setSubmitting', 'reset']),
  next,
  forgotPassword,
  back,
}
