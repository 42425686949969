import types from '../constants/Logout';
import {commonReducer} from 'libModule/helpers/reducer-helpers.js';

const INITIAL_STATE = {
    shouldRemoveMTPRBlock: false
}

const reducer = (state = INITIAL_STATE, action = {}) => {
        const { type,payload } = action;
        const { shouldRemoveMTPRBlock } = state;

        switch (type) {
            case types.REMOVE_MTPR_BLOCK:
                if(!_.isEqual(payload, shouldRemoveMTPRBlock)){
                    return {
                        ...state,
                        shouldRemoveMTPRBlock: payload
                    }
                } else {
                    return state;
                }
            default:
                return state;
        }
}

export default (state = INITIAL_STATE, action) => {
    return commonReducer(reducer(state, action), action, types)
}
