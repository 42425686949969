import React from 'react';
import { IHSearchTable, IHLoading, IHButton, _,IHInput } from 'ihcomponent';
import { goPath } from 'libModule/utils'
import { connect }from 'react-redux';
import moment from 'moment';
import AddFoodLogNotes from './AddFoodLogNotes';
import {commonActionsHelper} from 'libModule/helpers/action-helpers';
import actions from "../actions";
import { Rate,Badge,Modal } from 'antd';
import "antd/lib/rate/style/index.css";
import $ from 'jquery';
import FoodDiaryModule from './FoodDiaryModule';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import {isEqual} from 'lodash';

const FoodLogListComponent = class extends React.Component {
    static displayName = 'FoodLogListComponent';

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            filteredValue:'false',
            showAddFoodLogModal:false
        }
    }

    componentWillUnmount(){
        this.props.resetTableProps();
    }

    // renderStar(startCount){
    //
    //     return <Rate className='tableStar' value = {startCount} disabled/>;
    // }
    renderStar(startCount,isInChat){
        if(!isNaN(startCount)&&startCount!=0) {

            return <Rate className='tableStar'
                         value={startCount}
                         disabled className='foodDiaryLogBook'
                         style={{ fontSize: isInChat ? 15 : 20}}/>;
        }
    }

    handleShowAddFoodLogModal(flag){
        this.setState({
            showAddFoodLogModal:flag
        })
    }

    renderFoodLogModal(){
        const { props } = this;
        // const props = {
        //     showModal: true,
        //     showHeaderCloseButton: false,
        //     className: 'vsm-provider-modal',
        //     size: 'lg'
        // }
        const { showAddFoodLogModal,userId,rows } = this.state;
        if(showAddFoodLogModal) {
            return <Modal visible={true}
                          className='vsm-provider-modal' footer={null}
                          destroyOnClose={true}
                          bodyStyle={{padding:0}}
                          width={1000}
                          closable={false}
            >
                <FoodDiaryModule rows={rows}
                                 userId={userId}
                                 renderStar={this.renderStar}
                                 hidePatientName={true}
                                 className='FoodDiaryModal '
                                 handleShowAddFoodLogModal={(flag) => this.handleShowAddFoodLogModal(flag)}
                                 {...props}
                />
            </Modal>
        }
    }
    render() {
        const foodlogTableWidth = $('.patientFoodLogTable').width();
        // const {onTableChange} = this.props;
        const {loading,foodLogList,page,userId,filter } = this.props;
        const defaultFilter = userId ? '0' : 'false';
        const filteredValue = _.get(filter,'rating[0]',defaultFilter);
        const data  = foodLogList&&foodLogList.data;
        const groupByPatientId = _.groupBy(data,(d)=>d.member.id);
        const inputArray = _.map(Object.keys(groupByPatientId),(k)=> groupByPatientId[k][0]);
        const patientNameField  =  {
            title: 'Patient Name',
            key: 'user.profile.fullName',
            width:'14%',
            dataIndex: 'member.profile.fullName',
            render:(item,row)=> {
                const foodLogCount = groupByPatientId[_.get(row,'member.id')].length;
                return <div>{item}
                {/*<Badge count={foodLogCount} style={{backgroundColor:'rgb(82, 196, 26)'}}/>*/}
                </div>
            }
        }
        const unratedCount = {
            title: 'Unrated Count',
            key: 'user.profile.lastName',
            width:'14%',
            dataIndex: 'member.profile.lastName',
            render:(item,row)=> {
                const foodLogCount = _.filter(groupByPatientId[_.get(row,'member.id')],(f)=>f.rating.star==0).length;
                return <Badge count={foodLogCount} style={{backgroundColor:'rgb(82, 196, 26)'}} showZero/>
            }
        }

        const columns = [
            {
                title: foodlogTableWidth&&(foodlogTableWidth <=800) ? 'Date':'Date / Time',
                key: 'createdAt',
                dataIndex: 'createdAt',
                width:'14%',
                sorter: (a,b) => a.createdAt - b.createdAt,
                render: (item, row) => moment(item).format('MM/DD/YYYY HH:mm')

            },
            {
                title: 'Meal Type',
                key: 'mealType',
                dataIndex: 'mealType',
                width:'10%',
                render: (item,row)=>{

                    return _.upperFirst(_.toLower(item));
                }
            },
            {
                title:'Patient Comments',
                key:'note',
                width:'16%',
                dataIndex:'note',
                render:(item,row)=>{
                    return item||'--';
                }
            },
            {
                title: 'Provider Comments',
                key: 'comments',
                dataIndex: 'comments',
                width: '16%',
                render:(comments,row)=>{
                    if(comments.length>0){
                        const lastComment = comments[comments.length-1].text;
                        return lastComment;
                    }
                    return '--'
                }
            },
            {
                title: 'Rating',
                key: 'rating',
                width:'10%',
                className:'ratingInList',
                dataIndex: 'rating.star',
                render: (item, row) => {
                    return this.renderStar(item);
                },
                filterMultiple:false,
                filters: [
                    { text: 'All', value: '0' },
                    { text: 'Reviewed',value: 'true' },
                    { text: 'Unreviewed',value: 'false' }
                ],
                filteredValue:[filteredValue],
            },
            {
                title: 'Add Notes',
                width:'10%',
                render: (notes, row) => {
                    const buttonProps = {
                        label: 'Review',
                        size: 'small',
                        bsStyle: 'primary',
                        onClick: (e) => {
                            // const props = {
                            //     showModal: true,
                            //     showHeaderCloseButton: true,
                            //     className: 'vsm-provider-modal',
                            //     size: 'lg'
                            // }
                            const userId = this.props.userId;
                            const foodLogList = groupByPatientId[_.get(row,'member.id')];
                            // this.props.openModal(<FoodDiaryModal userId={userId} renderStar={this.renderStar}
                            //                                      reset={this.props.reset}
                            //                                      shouldStayAndRestAfterSubmit={true}
                            //                                      rows={foodLogList}
                            //                                      hidePatientName = { false }
                            //                                      {...this.props}
                            //     />
                                /*<AddFoodLogNotes userId={userId} row = {row} reset = { this.props.reset }
                                                                  setRating={this.props.setRating} closeModal={this.props.closeModal}
                                                                  updateComment = {this.props.updateComment}
                                                                  renderStar = {this.renderStar}
                                                                  key= { row.id }
                                                 ,props);*/
                            this.setState({showAddFoodLogModal:true, userId:userId, rows:foodLogList});
                            Mixpanel.track('clicked','review','food diary', {PATIENT_ID: row.member.id});
                        },
                        className: 'table-action-button'
                    }
                    return <IHButton {...buttonProps} />
                }
            }
        ];

        if(!userId) {
            columns.splice(1,0,unratedCount);
            columns.splice(1,0,patientNameField);
        }
        const tableProps = {
            columns:  columns,
            rowKey: 'id',
            pagination: {
                current: _.get(page, 'current', 1),
                pageSize: _.get(page, 'pageSize', 10),
                total: _.get(inputArray, 'length', 0),
            },
            onChange:(p,f,s)=>{
                let result = null;
                let checkReset = false;
                if(!isEqual(f.rating, this.props.filter.rating)) {
                    result = ['set', 'rating filter'];
                }
                let type = null;
                switch(f.rating[0]) {
                    case('0'):
                        type = 'All';
                        break;
                    case('true'):
                        type = 'Reviewed';
                        break;
                    case('false'):
                        type = 'Unreviewed';
                        break;
                }
                if(result != null) {
                    if(result[1] == 'rating filter') {
                        if((!f.rating && this.props.filter.rating) || (f.rating && f.rating.length === 0 && this.props.filter.rating)) {
                            result[0] = 'reset';
                            result[1] = 'rating filter';
                            checkReset = true;
                        }
                    }
                    if(checkReset == false) {
                        Mixpanel.track(result[0] + '', result[1] + '', 'food log diary', {FILTER_TYPE: type});
                    } else {
                        Mixpanel.track(result[0] + '', result[1] + '', 'food log diary', '');
                    }
                }
                this.props.onTableChange(p, f, s);
            },
            loading:{spinning: loading, indicator: <IHLoading/>},
            dataSource:  inputArray,
            title: `Food Diary`,
            optionsBox: true,
            titleClassName: 'foodLogOptBox',
        };

        const noDataElem = (
            <div style={{ height: '150px', paddingTop: '2em' }}>
                <p style={{ fontSize: '18px' }}>No New Diary Entry</p>
            </div>
        );

        return (
            <div className="patient-task-table">
                <IHSearchTable {...tableProps} noDataElem={noDataElem} />
                {this.renderFoodLogModal()}
            </div>
        );
    }
};

const mapToDispatch = (dispatch)=>{
    return {
        setRating: (id,rating)=>dispatch(actions.setRating(id,rating)),
        updateComment:(id,comments)=>dispatch(actions.updateComment(id,comments)),
        reset: (id)=>dispatch(actions.reset(id)),
    }
}

export default connect(null,mapToDispatch)(FoodLogListComponent);
