import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertHR from 'modulesAll/graphql/insertHR';
import ManualInputContainer from './ManualInputContainer';
import { patientHeartrateInputValidator } from 'libModule/validator';

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/HRInputContainer'

  getVitalForm() {
    return [{
      type: 'rowGroup',
      rowGroupSpan: 24,
      gutter: 10,
      className: 'number-row',
      items: [
        {
          key: 'heartrate',
          label: 'Heart Rate (beats/min)',
          type: 'inputNumber',
          rowGroupSpan: 8,
          rules : [
            {validator: (rule, value, callback) => callback(patientHeartrateInputValidator(value))}
          ],
        }
      ],
    }];
  }

  insertVital(values, commonFields) {
    const variables = {
      ...commonFields,
      heartrate: values.heartrate,
    };

    return this.props.insertHR({ variables })
      .then(res => _.get(res, 'data.insertHR.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Heart Rate"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertHR, { name: 'insertHR' })
)(component);
