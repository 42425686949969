import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Select, DatePicker, Button, Row, Col, message, Modal, Input } from 'antd';
import { CONSENT_TYPE, getConsentTypeOptions, CONSENT_DATE_FORMAT } from '../../constants';
import { helpers } from '../../../../visit/helpers';
import I18N from '../../../../I18N';
import moment from 'moment';
import ConsentFormModal from './ConsentFormModal';
import ConsentFormTemplate from './ConsentFormTemplate';
import API from '../../../../VideoChat/API';

const initialInputValues = {
  consentType: undefined, 
  providerIds: [], 
  effectiveDate: moment(),
  // paperSignatureDate: null,
  careTeamSignature: null,
  isSendingConsent: false,
  consentDate: null
}
class ConsentFormMini extends React.Component {
  constructor(props){
    super(props);
    this.consentTypeOptions = getConsentTypeOptions(props.range);
    this.providerOptions = helpers.filterDoctors({ shortName: false });
    this.currentUserInfo = this.getCurrentUserInfo();
    this._isMounted = false;
    this.state = {
      ...initialInputValues,
      previewModal: false,
      template: null,
      hasErrors: false
    };
  };

  // due to the way ConsentFormTemplate updates template
  // cause memory leak with handleTemplateChange, use _isMounted pattern to prevent
  componentDidMount = () => this._isMounted = true;
  componentWillUnmount = () => this._isMounted = false;

  getCurrentUserInfo = () => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const fullName = _.get(currentUser, 'profile.fullName');
    const id = _.get(currentUser, 'id');
    return { fullName, id };
  };

  getCallInfo = () => {
    const { phoneNumbers = [] } = this.props;
    const lastCall = JSON.parse(sessionStorage.getItem('call-center-last-call') || '{}');
    const info = {
      caller: this.currentUserInfo.fullName,
      callerId: this.currentUserInfo.id,
    };

    if (Array.isArray(phoneNumbers) && phoneNumbers.includes(lastCall.phoneNumber)) {
      info.callNumber = lastCall.phoneNumber;
      info.callDate = lastCall.startTime;
    }

    return info;
  };

  handleTemplateChange = template => {
    if(this._isMounted) this.setState({ template });
  };

  showError = (e, needPopup = false) => {
    if(this._isMounted){
      this.setState({ hasErrors: true }, () => {
        if(needPopup) Modal.error({ content: e.message });
      });
    }
  };

  handleChangeConsentDate = (consentDate) => this.setState({ consentDate });

  render() {
    const { 
      consentType = _.get(this, 'consentTypeOptions.0.value'), 
      providerIds, effectiveDate, careTeamSignature,
      previewModal, template, isSendingConsent, hasErrors, consentDate
    } = this.state;
    const { 
      hasBeenSent, language, patientId, 
      patientName, consentProviderName 
    } = this.props;

    const needCTSignature = consentType === CONSENT_TYPE.TEAM_MEMBER_DEVICE && !careTeamSignature;
    const isPaperConsent = consentType === CONSENT_TYPE.PAPER;
    const shouldShowProviderOptions = isPaperConsent || !consentProviderName;
  
    const renderConsentTypeOptions = () => (
      <div className='consent-form-type-options'>
        <span>Which way does the patient prefer to sign?</span>
        <Radio.Group
          disabled={isSendingConsent}
          value={consentType}
          onChange={e => {
            const consentType = e.target.value;
            this.setState({ 
              consentType, 
              careTeamSignature: null,
              consentDate: consentType === CONSENT_TYPE.VERBALLY ? moment() : null
            })
          }}
          options={this.consentTypeOptions}
        />
      </div>
    )
  
    const renderProviderOptions = () => (
      <div>
        <span className='consent-form-provider-label'>{I18N.get('consentForm.label.provider')}</span>
        <Select
          mode='multiple'
          disabled={isSendingConsent}
          placeholder='Please select'
          style={{ width: '100%', alignSelf: 'center' }}
          onChange={providerIds => this.setState({ providerIds })}
          value={providerIds}
        >
          {
            _.map(this.providerOptions, p =>
              <Select.Option value={p.id} key={p.id}>
                {p.fullName}
              </Select.Option>)
          }
        </Select>
      </div>
    );
  
    // const renderEffectiveDateSelector = () => (
    //   <div className='consent-form-effective-date'>
    //     <span>Effective date</span>
    //     <DatePicker 
    //       disabled={isSendingConsent}
    //       allowClear={false}
    //       value={effectiveDate}
    //       style={{ width:'100%', alignSelf:'center' }}
    //       onChange={v => this.setState({ effectiveDate: v || moment() })}
    //     />
    //   </div>
    // );

    const renderCTSignatureForm = () => (
      <div className='consent-form-device-initials'>
        <strong>{I18N.get('consentForm.label.device.legalStatement')}</strong>
        <span>{I18N.get('consentForm.label.device.text')}</span>
        <Input 
          onChange={e => this.setState({ careTeamSignature: e.target.value })} 
          value={careTeamSignature} 
        />
      </div>
    );

    // const renderPaperSignatureDateSelector = () => (
    //   <div className='consent-form-paper-signature-date'>
    //     <span>Paper consent signature date*</span>
    //     <DatePicker 
    //       disabled={isSendingConsent}
    //       value={paperSignatureDate}
    //       style={{ width:'100%', alignSelf:'center' }}
    //       onChange={v => this.setState({ paperSignatureDate: v })}
    //     />
    //   </div>
    // );

    const renderConsentDateSelector = () => {  
      const label = isPaperConsent ? 
                    I18N.get('consentForm.label.paper.consentDate') :
                    I18N.get('consentForm.label.verbally.consentDate');
  
      return (
        <React.Fragment>
          <strong className='consent-form-date-label'>{label}</strong>
          <DatePicker
            style={{ width: 248 }}
            value={consentDate}
            onChange={this.handleChangeConsentDate}
            format={CONSENT_DATE_FORMAT}
          />
        </React.Fragment>
      );
    }
  
    const sendConsentForm = () => {
      this.setState({ isSendingConsent: true }, async () => {
        const { patientId, setConsentInfo } = this.props;
        const { 
          consentType = _.get(this, 'consentTypeOptions.0.value'), 
          providerIds, effectiveDate, template 
        } = this.state;
        let res, successMsg = 'Consent form signed!';
        try {
          const commonVariables = {
            memberId: patientId, 
            providers: providerIds, 
            effectiveDate: (consentDate || effectiveDate).valueOf(), 
            timezone: moment.tz.guess(), 
            consentType
          };
          const sendConsentFormForNonApp = async () => {
            const nonAppResp = await API.createAndSignConsentMutation({
              ...commonVariables, fileContent: template.content
            });
            return _.get(nonAppResp, 'data.createAndSignConsent');
          };
          switch(consentType){
            case CONSENT_TYPE.APP:
              successMsg = 'Consent form sent.';
              commonVariables.consentProviderName = consentProviderName;
              res = await API.enrollPatientConsentMutate(commonVariables);
              res = _.get(res, 'data.enrollPatientConsent');
              break;
            case CONSENT_TYPE.VERBALLY:
              res = await sendConsentFormForNonApp();
              break;
            case CONSENT_TYPE.TEAM_MEMBER_DEVICE:
              res = await sendConsentFormForNonApp();
              break;
            case CONSENT_TYPE.PAPER:
              commonVariables.signatureAt = consentDate;
              res = await API.createPaperConsentMutation(commonVariables);
              break;
            default:
              throw new Error(`This mini form doesn't handle ${consentType}`);
          };
          message.success(successMsg, 5);
        } catch(err) {
          this.showError(err, true);
        }  finally {
          this.setState(prev => ({
            ...prev,
            // programatically reset input values
            ...initialInputValues
          }), () => {
            if (res && setConsentInfo) {
              setConsentInfo(patientId, res);
            }
            // close popover
            const trigger = document.getElementById('consent-form-popover-trigger')
            if(trigger) trigger.click();
          }); 
        }   
      });
    }
  
    return (
      <div className='consent-form-body'>
        {
          hasBeenSent &&
          <span style={{ fontSize: 12, fontStyle: 'italic', marginBottom: 10 }}>
            Consent form has been sent via APP.
          </span>
        }
        {renderConsentTypeOptions()}
        <Row>
          {
            shouldShowProviderOptions &&
            <Col span={24} style={{ marginRight: 5 }}>
              {  renderProviderOptions()}
            </Col>
          }
          {/* <Col span={9}>
            {renderEffectiveDateSelector()}
          </Col> */}
        </Row>
        {
          consentType === CONSENT_TYPE.TEAM_MEMBER_DEVICE &&
          <Row style={{ marginTop: 12 }}>
            <Col>
              {renderCTSignatureForm()}
            </Col>
          </Row>
        }
        {/* {
          isPaperConsent &&
          <Row>
            <Col span={24}>
              {renderPaperSignatureDateSelector()}
            </Col>
          </Row>
        } */}
        {
          (isPaperConsent ||
            consentType === CONSENT_TYPE.VERBALLY) &&
            <Row style={{ marginTop: 12 }}>
              <Col>
                { renderConsentDateSelector() }
              </Col>
            </Row>
        }
        {
          !isPaperConsent &&
          <a 
            disabled={
              (shouldShowProviderOptions && !providerIds.length) ||
              isSendingConsent 
            }
            onClick={e => {
              e.preventDefault();
              this.setState({ previewModal: true });
            }}
          >
            Preview consent form
          </a>
        }
        <Button 
          type='primary' 
          onClick={sendConsentForm} 
          // prevent proceed consent form action if consentFormTemplate has errors
          disabled={
            hasErrors || 
            (isPaperConsent ?
              (!providerIds.length || !consentDate)
              :
              (!template || !template.isValid || needCTSignature)
            )
          }
          loading={isSendingConsent}
        >
          {`${
              consentType === CONSENT_TYPE.APP ? 
              (hasBeenSent ? 'Resend' : 'Send' )
              : 'Sign'
            } Consent Form`
          }
        </Button>
        {
          !isPaperConsent &&
          <ConsentFormModal 
            modalBody={<ConsentFormTemplate 
              language={language}
              patientId={patientId}
              patientName={patientName}
              consentType={consentType}
              consentProviderName={consentProviderName}
              doctorsNames={
                this.providerOptions
                .filter(p => _.includes(providerIds, p.id))
                .map(p => p.fullName)
              }
              effectiveDate={consentDate || effectiveDate}
              careTeamFullname={this.currentUserInfo.fullName}
              callInfo={this.getCallInfo()}
              onChange={this.handleTemplateChange}
              openGraphQLErrorModal={this.showError}
            />}
            onCancel={() => this.setState({ previewModal: false })}
            destroyOnClose={false}
            maskStyle={{ 
              backgroundColor: 'black', 
              opacity: '90%',
              ...!previewModal && {
                display: 'none'
              }
            }}
            wrapClassName={`consent-form-preview-${previewModal ? 'show' : 'hide'}`}
          />
        }
      </div>
    );
  }
};

ConsentFormMini.propTypes = {
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasBeenSent: PropTypes.bool.isRequired,
  setConsentInfo: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  consentProviderName: PropTypes.string.isRequired
};

export default ConsentFormMini;
