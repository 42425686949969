import {Form} from 'antd';
import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { Modal, Icon } from 'antd';
import { goPath, decryptRole, getVisitRoles } from 'libModule/utils'
import { ROLE_MAP, VISIT_REQUIRE_ASSIGNEE, PATIENT_COMPLEXITY_SOURCE_ENUM } from 'libModule/constants';
import getLatestA1C from "../../graphql/getLatestA1C";
import Client from 'libModule/gqlClient';
import {helpers as dataHelper} from "../helper"
import VisitPersonHeader from "./VisitPersonHeader"
import VisitInputTypeAndProvider from "./VisitInputTypeAndProvider";
import VisitInputProviders from "./VisitInputProviders";
import VisitInputA1C from "./VisitInputA1C";
import VisitInputTime from "./VisitInputTime";
import VisitInputNote from "./VisitInputNote";
import VisitInputCheckbox from "./VisitInputCheckbox";
import VisitInputBottomButton from "./VisitInputBottomButton";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import visitList from 'modulesAll/graphql/visitListWithEp';
import orgScheduleQuery from 'modulesAll/graphql/orgSchedule';
import {compose, graphql} from "react-apollo/index";
import getLoanDevice from "../../patient/loanDevices/query/getLoanDevice";
import { Divider } from 'antd';
import VisitButtons from './VisitButtons';
import { typeToDuration } from '../constants/index';
import TimerClaimedContainer from '../../timerClaimed/containers/TimerClaimedContainer';
import { connect } from 'react-redux';
import { mapDispatchToTimerActions } from '../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../idleTime/constant';
import { withComplexity } from '../../patient/main/API';
import { withEnrolledCurrentProgram } from '../../workStation/API';
import { isPatientEnrolled } from '../../patient/AdditionalInfo/helpers';
import HiatusOverlapNote from '../../patient/hiatusStatus/components/OverlapNote';
import { getPatientHiatusQuery } from '../../graphql/getHiatus';
import { getPatientComplexityByConditions, updatePatientComplexity } from '../../patient/main/API';
import { ASSIGNEE_REMINDER_ROLE } from '../../utils/constants/role';
import { getUpdatedComplexitySourceAndLevel } from '../../remoteEnrollment/helper/complexityLevel';
import ComplexitySummaryContainer from '../../patient/main/containers/ComplexitySummaryContainer';
import {getAssigneesCount} from '../../assignCT/helper/index';
import PrevComplexityComponent from '../../patient/main/components/PrevComplexityComponent';
import { checkIsInRole} from '../../../lib/helpers/role-helpers';
import { helpers } from '../helper';

const dateFormat = 'MM/DD/YYYY';
const INPUT_VISIT_TIMER = 'INPUT_VISIT_TIMER';
class InputVisitFormComponent extends Component{

    constructor(props){
        super(props);
        const patientAssignees = dataHelper.parsePatientAssignees(_.get(props, 'patient.user.assignees'));
        const assignees = _.get(props,'visitInfo.assignees') || patientAssignees;
        this.state = {
            // id: null,
            // A1C: null,
            // A1CDate: null
            visitType: this.getVisitType(),
            assignees: this.parseAssignees(assignees),
            assigneesAvailability: [],
            complexityLevel: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.clickPatient = this.clickPatient.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    clickPatient(){
        const { isFromVisitTable } = this.props;
        const memberId = _.get(this,'props.visitInfo.member.id');
        if(isFromVisitTable){
            goPath(`/patients/${memberId}/enrolledprogram/default`)
        }
        this.props.backToSearch();
    }

    parseAssignees = (obj)=>{
        const key = '__typename';
        const newObj = {};
        for( const k of Object.keys(obj||{})){
            if(k!==key){
                if(typeof obj[k]=='object'){
                    newObj[k] = this.parseAssignees(obj[k]);
                }else {
                    newObj[k] = obj[k];
                }
            }
        }
        return newObj;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const preVisit = _.get(prevProps,'visitInfo');
        const curVisit = _.get(this,'props.visitInfo');
        const preVisitId = _.get(preVisit,'id');
        const curVisitId = _.get(curVisit,'id');
        if(preVisitId!==curVisitId){
            this.setState({ assignees: this.parseAssignees(curVisit.assignees||{})});
            this.props.form.resetFields();
        }
    }

    componentDidMount() {
        const {patient, visitInfo} = this.props;
        let userId = null;
        if(patient){
            userId = _.get(patient, 'user.id', '');
        } else {
            userId = visitInfo.member.id;
        }
        // get assigneesAvailability if in edit mode
        if(visitInfo) {
            const { type,visitStartTime,appointmentAt,id } = visitInfo;
            dataHelper.onChangeAssigneeOrVisitTime(type,visitStartTime || appointmentAt,this.setAvailability,id);
        }
        if(visitInfo && visitInfo.labResult){
            return;
        }

        Client.query({
            query: getLatestA1C,
            variables: {memberId: userId},
            fetchPolicy: 'network-only'
        })
            .then(res => {
                if(!res.data.getLatestA1C){
                    return;
                }
                const data = res.data.getLatestA1C;
                const id = data.id;
                const date = data.dateCollected;
                const value = data.results[0].value;
                const isInHouse = data.results[0].isInHouse;
                this.setState({
                    id: id,
                    A1C: value,
                    A1CDate: date,
                    isInHouse
                })

            })
            .catch(e => {
                console.log(e);
            })


    }

    setAvailability = (assigneesAvailability)=>{
        this.setState({
            assigneesAvailability
        },()=>{
            dataHelper.reValidAssignees(this.props.form);
        })
    }

    handleSubmit(e){
        e.preventDefault();

        const { editStatus, timerClaim, timerName,patient, patientId, currentProgram, form, patientComplexity } = this.props;
        const { getFieldValue, validateFields } = form;
        const assignees = _.get(patient,'user.assignees');
        const hasValue = !_.isNil(assignees) ||  _.some(assignees,(v,k)=> v);
        const hasEmptyValue = _.filter(_.omit(assignees, '__typename'),  v=>v).length == 1;
        const visitType = getFieldValue('type');
        const currentRole = ROLE_MAP[decryptRole()];
        const shouldAssignCT = isPatientEnrolled(currentProgram) && 
                                ASSIGNEE_REMINDER_ROLE.includes(currentRole) &&
                                VISIT_REQUIRE_ASSIGNEE.includes(visitType) && 
                                !hasValue;
        const { complexityLevel } = this.state;
        const isCAOrRDOrHC = checkIsInRole(['RD','CA','HC']);
        const currComplexity = {
            source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
            level: complexityLevel
        }
        const { source, level } = getUpdatedComplexitySourceAndLevel(patientComplexity, currComplexity);
        if(shouldAssignCT) {
            const inputVisitPatientDiv = document.getElementById('inputVisitPatient');
            inputVisitPatientDiv.scrollIntoView();
            inputVisitPatientDiv.style.borderColor='red';
            return;
        }
        validateFields((err, values) => {
            if (!err) {
                const A1CDate = values.A1CDate;
                const A1C = values.A1C;
                let  A1CVariables = null;
                if(A1C && A1CDate){
                    //have input data
                    if(!this.state.A1C){
                        // no previous data, save data
                        A1CVariables = {
                            value: A1C,
                            dateCollected: A1CDate.toDate().getTime(),
                        }
                    } else {
                        //have previous data, create and save status are same.
                        let start_date = moment(this.state.A1CDate);
                        const end_date = moment();
                        if ((end_date.diff(start_date, 'days')) < 30) {
                            //create and save status, previous date < 30
                            const originalDate = start_date.format(dateFormat);
                            const modifiedDate = A1CDate.format(dateFormat);
                            if (this.state.A1C != A1C || originalDate != modifiedDate) {
                                // if input data is different, save
                                A1CVariables = {
                                    value: A1C,
                                    dateCollected: A1CDate.toDate().getTime(),
                                }
                            } else {

                            }
                        } else {
                            //create and edit status, previous date > 30, input < 30 save, input > 30 no save
                            start_date = moment(A1CDate);
                            if ((end_date.diff(start_date, 'days')) < 30) {
                                A1CVariables = {
                                    value: A1C,
                                    dateCollected: A1CDate.toDate().getTime(),
                                }
                            }
                        }
                    }
                    // A1C is given by patient, so it can't be inHouse
                    if(A1CVariables) A1CVariables.isInHouse = false;
                } else {
                    //no input data.
                    if(editStatus) {
                        //edit status, no save
                    } else {
                        //create status, previous date < 30, save previous A1C
                        if(this.state.A1C){
                            // const start_date = moment(this.state.A1CDate);
                            // const end_date = moment();
                            // if((end_date.diff(start_date, 'days')) < 30){
                                A1CVariables = {
                                    id: this.state.id,
                                    value: this.state.A1C,
                                    dateCollected: this.state.A1CDate,
                                    isInHouse: this.state.isInHouse
                                }
                            // }
                        }
                    }
                }
                timerClaim.interacted(editStatus ? EVENT_TYPES.EDIT_VISIT : EVENT_TYPES.CREATE_VISIT);
                if(hasEmptyValue && isCAOrRDOrHC){
                    this.showConfirmForSubmit(timerClaim, timerName, values, A1CVariables);
                    return;
                } else {
                    this.stopTimerAndSubmitForm(timerClaim, timerName, values, A1CVariables)
                }
                if(complexityLevel) updatePatientComplexity({ memberId: patientId, level, source})
            } else {
                console.log(err);
            }
        });
        Mixpanel.track('clicked', 'create', 'create a visit popup', '', {});
    };

    stopTimerAndSubmitForm = (timerClaim, timerName, values, A1CVariables) => {
        // No need to stop the timer if the InputVisitFormComponent is within a tracked page
        if (timerName) {
            console.log('timer name');
            this.handleInitialAndFollowUpDate(values, A1CVariables)
        } else {
            console.log('stop timer');
            timerClaim.stopTimer('submit', () => this.handleInitialAndFollowUpDate(values, A1CVariables));
        }
    }

    showConfirmForSubmit = (timerClaim, timerName, values, A1CVariables) => {
        const onOk = () => {
            this.stopTimerAndSubmitForm(timerClaim,timerName, values, A1CVariables);
        }
        const onCancel = () => {}
        Modal.confirm({
            content: <div>
               You've only selected <span >one</span> field for assignee, but <span>two</span> is recommended. Are you sure you want to submit?
            </div>,
            zIndex:3500,
            icon: <Icon type="info-circle" theme="filled" style={{ color: '#DA6453' }}/>,
            className:"assignee-confirmation-modal",
            onOk: onOk,
            onCancel:onCancel
        });
    }

    shouldKeepConditionsList = variables => {
      let newVars = {...variables};
      if(dataHelper.checkIsMAOrMD()) {
        // omit conditionsList, always null for MA/MD
        // better to omit than using previous values
        newVars = _.omit(newVars, 'conditionsList');
      }
      return newVars;
    }

    handleInitialAndFollowUpDate(values, A1CVariables){
        const { createVisit, visitInfo, editStatus, editVisit, patient,rescheduledFromId } = this.props;
        const doctorId = _.get(visitInfo,'member.profile.doctor.id') ||  _.get(patient, 'user.profile.doctor.id');
        const { assignees } = this.state;
        const date = values.date;
        const hour = values.hour;
        const min = values.min;
        const combinedStartTime = dataHelper.combineDateHourAndMin(date,parseInt(hour),parseInt(min)) + 0;
        const visitDurationInMin = _.get(typeToDuration,`${values.type}`,30);
        const combinedEndTime = combinedStartTime + visitDurationInMin*60000; //30 minutes

        const { visitType } = this.state;
        let variables = null;
        if(visitType === 'CLINIC_FOLLOW_UP'  || visitType === 'INIT'){
            variables = {
                id: visitInfo ? visitInfo.id : null,
                memberId: visitInfo ? visitInfo.member.id:  this.props.patient.user.id,
                type:values.type,
                assignees,
                appointmentAt:combinedStartTime,
                appointmentTo:combinedEndTime,
                reason:values.reason,
                visitRoles: values.visitRoles,
                category:values.category,
                conditionsList: dataHelper.handleConditionsList(values.conditionsList),
                A1C: A1CVariables,
                remoteStatus:values.remoteStatus
            }
        } else {
            variables = {
                id: visitInfo ? visitInfo.id : null,
                memberId: visitInfo ? visitInfo.member.id:  this.props.patient.user.id,
                type:values.type,
                appointmentAt:combinedStartTime,
                appointmentTo:combinedEndTime,
                assignees,
                reason:values.reason,
                visitRoles: values.visitRoles,
                category:values.category,
                remoteStatus:values.remoteStatus,
                A1C: A1CVariables
            }
        }
        if(rescheduledFromId) {
            variables.rescheduledFromId = rescheduledFromId;
        }
        if(_.includes(values.visitRoles, 'MD')) {
          variables.providers = [values.doctor];
        } else {
          variables.providers = [doctorId];
          if(!doctorId) console.log("Doctor id doesn't exist");
        }

        if(visitType !== 'MNT' && _.includes(values.visitRoles, 'RD')) {
          variables.visitRoles.push('HC');
          variables.visitRoles = _.uniq(variables.visitRoles);
        }

        variables = this.shouldKeepConditionsList(variables);
        if(editStatus){
            editVisit(variables);
        } else {
            createVisit(variables, A1CVariables);
        }
    }

    handleDelete(){
        // const { visitInfo } = this.props;
        this.props.handleDelete(() => this.props.timerClaim.interacted(EVENT_TYPES.DELETE_VISIT));
    }

    getVisitType(){
        const {visitInfo, patient, editStatus,isRemoteEnrollment} = this.props;

        if(isRemoteEnrollment) {
          return 'INIT';
        }
        if(editStatus && visitInfo) {
          return visitInfo.type;
        }
        const { enrolledPrograms, user } = patient || {};
        const typeAndStatus = dataHelper.getTypeAndStatus(enrolledPrograms, _.get(user, 'profile.lastMeasuredAt'));

        if(dataHelper.checkIsMAOrMD() || // see SC-6336
          (_.includes(['General', 'Discharged'], _.get(typeAndStatus, 'status')))
        ){
          return 'CLINIC_FOLLOW_UP';
        } else {
          return 'FOLLOW_UP';
        }
    }

    setVisitType = (visitType) => {
        this.setState({ visitType },()=>{
            const { form, visitInfo } = this.props;
            const {date,hour, min,type } = form.getFieldsValue();
            const combinedStartTime = dataHelper.combineDateHourAndMin(date,parseInt(hour),parseInt(min))+0;
            if(date&&hour&&min) {
                dataHelper.onChangeAssigneeOrVisitTime(type, combinedStartTime, this.setAvailability, _.get(visitInfo, 'id'));
                dataHelper.reValidAssignees(this.props.form);
            }
        });
    }

    setComplexityLevel = (complexityLevel) => {
        this.setState({complexityLevel});
    }

    onCheckboxChange = (checkedValue) => {
        getPatientComplexityByConditions({conditions: checkedValue}).then((res) => {
            const complexityLevel = _.get(res, 'data.getPatientComplexityByConditions')
            this.setComplexityLevel(complexityLevel);
        })
    }

    changeMemberSel = (type,providerId,{ props } )=>{
        const { assignees={} } = this.state;
        _.set(assignees, type, providerId ? { providerId, name: props['data-fullname'] }:{});
        this.setState({assignees});
        if (this.props.onAssigneeChange) {
            this.props.onAssigneeChange(type, providerId);
        }
    }

    autoFillAssignees = (assignees) => {
      const { form } = this.props;
      this.setState({ assignees });
      form.setFieldsValue({ ...dataHelper.getAssigneeFieldValue(assignees) });
      if (this.props.onAssigneeChange) {
        _.map(assignees, (v, k) => {
          if(v && v.providerId) {
            this.props.onAssigneeChange(k, v.providerId);
          }
        });
      }
    }

    onAssigneeSearch = (type,str)=>{
        const { searchKey={} } = this.state;
        _.set(searchKey,type,str);
        this.setState({ searchKey });
    }
    renderInitialOrFollowUp(){
        const { changeMemberSel,onAssigneeSearch } = this;
        const {visitInfo, patient={}, editStatus, disableSaveButton, curVisitInfo,loanDeviceInfo,isRemoteEnrollment,orgSchedules,patientComplexity,hiatusStatus,rescheduledFromId } = this.props;
        const { visitType,searchKey,assigneesAvailability, complexityLevel } = this.state;
        const { getFieldValue } = this.props.form;
        const isMaOrMd = dataHelper.checkIsMAOrMD();
        const role = ROLE_MAP[decryptRole()];
        const hasLoadDevice = !!loanDeviceInfo;
        let A1CData = {};
        if(visitInfo && visitInfo.labResult){
            const data = visitInfo.labResult;
            A1CData.A1CDate = data.dateCollected;
            A1CData.A1C = data.results[0].value;
        }
        let lastVisitInfo = this.getLastInitOrGeneral(curVisitInfo);
        let remoteEnrollment = _.get(patient,'user.remoteEnrollment',_.get(visitInfo,'member.remoteEnrollment'));
        return (
            <Fragment>
                <VisitInputTypeAndProvider form={this.props.form}
                                           visitType={_.get(visitInfo, 'type') || visitType}
                                           setVisitType={this.setVisitType}
                                           visitInfo={visitInfo}
                                           patient={patient}
                                           user={patient.user||_.get(visitInfo,'member')}
                                           hasLoadDevice={hasLoadDevice}
                                           editStatus={editStatus}
                                           changeMemberSel={changeMemberSel}
                                           onAssigneeSearch={onAssigneeSearch}
                                           searchKey={searchKey}
                                           setAvailability={this.setAvailability}
                                           assigneesAvailability={assigneesAvailability}
                                           patientComplexity={patientComplexity}
                />
                <Divider></Divider>
                <VisitInputTime form={this.props.form} visitInfo={visitInfo}
                                rescheduledFromId={rescheduledFromId}
                                editStatus={editStatus} curTab={this.props.curTab}
                                setAvailability={this.setAvailability}
                                onDateChange={this.props.onDateChange}
                                isMaOrMd={isMaOrMd} role={role} orgSchedules={orgSchedules} patient={patient} />
                <Divider></Divider>
                <VisitInputProviders form={this.props.form}
                                           visitType={getFieldValue('type') || _.get(visitInfo, 'type') || visitType}
                                           setVisitType={this.setVisitType}
                                           visitInfo={visitInfo}
                                           patient={patient}
                                           user={patient.user||_.get(visitInfo,'member')}
                                           hasLoadDevice={hasLoadDevice}
                                           editStatus={editStatus}
                                           changeMemberSel={changeMemberSel}
                                           onAssigneeSearch={onAssigneeSearch}
                                           searchKey={searchKey}
                                           setAvailability={this.setAvailability}
                                           assigneesAvailability={assigneesAvailability}
                                           patientComplexity={patientComplexity}
                                           autoFillAssignees={this.autoFillAssignees}
                />
                <Divider></Divider>
                <VisitInputNote form={this.props.form} visitInfo={visitInfo} editStatus={editStatus || helpers.isNoShowApt(visitInfo)} lastVisitInfo={lastVisitInfo} visitType={visitType}/>
                {!dataHelper.checkIsMAOrMD() &&
                <div>
                  {
                    (visitType === 'CLINIC_FOLLOW_UP' || visitType === 'INIT')  &&
                    <div>
                        <VisitInputCheckbox form={this.props.form} visitInfo={visitInfo} editStatus={editStatus} remoteEnrollment={ this.props.remoteEnrollment }
                        lastVisitInfo={lastVisitInfo} isRemoteEnrollment={isRemoteEnrollment} onCheckboxChange={this.onCheckboxChange}/>
                        <ComplexitySummaryContainer 
                          oldComplexity={patientComplexity}
                          newComplexity={{
                            source: PATIENT_COMPLEXITY_SOURCE_ENUM.CONDITION,
                            level: complexityLevel
                          }}
                        />
                    </div>
                  }
                  <VisitInputA1C form={this.props.form}
                                editStatus={editStatus}
                                data={editStatus ? A1CData : this.state}
                                patient={patient}
                                visitInfo={visitInfo}/>
                </div> }
                <VisitInputBottomButton form={this.props.form}
                                        editStatus={editStatus}
                                        visitInfo={visitInfo}
                                        patient={patient}
                                        handleDelete={this.handleDelete}
                                        disableSaveButton={disableSaveButton}
                                        isRemoteEnrollment={isRemoteEnrollment}
                                        remoteEnrollment={remoteEnrollment}/>
                <div className='visit-hiatus-overlap-note'>
                  <HiatusOverlapNote 
                    hiatusStatus={hiatusStatus}
                    visit={
                      !_.isNil(getFieldValue('date')) ?
                        { appointmentAt: getFieldValue('date') }
                        : {}
                    }
                  />                       
                </div>
                <VisitButtons
                  editStatus={editStatus}
                  disableSaveButton={disableSaveButton}
                  handleDelete={this.handleDelete}
                  onCancel={this.props.onCancel || this.props.backToSearch}
                />
            </Fragment>
        )
    }



    render() {
        const { clickPatient } = this;
        const {visitInfo, patient, fromAppointments, editStatus, isFromVisitTable, setShowTaskModal, fromDetail, timerName, patientComplexity, currentProgram, setPatient, curVisitInfo} = this.props;
        let remoteEnrollment = this.props.remoteEnrollment || _.get(patient,'user.remoteEnrollment',_.get(visitInfo,'member.remoteEnrollment'));
        return (
            <React.Fragment>
                {timerName ? null : <TimerClaimedContainer patientId={_.get(patient, 'user.id', '') || _.get(visitInfo, 'member.id', '')} id={INPUT_VISIT_TIMER} />}
                <div className="visit_popup_patient scrollable-content">
                    <VisitPersonHeader patient={patient}
                                    clickPatient={clickPatient}
                                    visitInfo={visitInfo}
                                    fromAppointments={fromAppointments}
                                    isFromVisitTable={isFromVisitTable}
                                    editStatus={editStatus}
                                    fromDetail={fromDetail}
                                    patientComplexity={patientComplexity}
                                    setPatient={setPatient}
                                    isEnrolled={isPatientEnrolled(currentProgram)}
                                    curVisitInfo={this.getLastInitOrGeneral(curVisitInfo)}
                                    autoFillAssignees={this.autoFillAssignees}
                                    remoteEnrollment={remoteEnrollment}
                                    />
                    { isFromVisitTable ? <a onClick={()=>setShowTaskModal(visitInfo)} style={{ textDecoration:'underline' }}>Show Tasks</a>:' ' }
                    <Form onSubmit={this.handleSubmit} colon={false}>
                        {this.renderInitialOrFollowUp()}
                    </Form>
                </div>
            </React.Fragment>
        );
    }

    getLastInitOrGeneral(curVisitInfo) {
        if(!curVisitInfo) {
            return
        }
        for(let i = 0; i < curVisitInfo.length; i++) {
            if(curVisitInfo[i].type === 'INIT' || curVisitInfo[i].type === 'CLINIC_FOLLOW_UP') {
                return curVisitInfo[i]
            }
        }
        return null
    }
}
const loanDeviceInfo = graphql(getLoanDevice,{
    options:(ownProps) =>{
        const {patient, visitInfo} = ownProps;
        let userId = null;
        if(patient){
            userId = _.get(patient, 'user.id', '');
        } else {
            userId = visitInfo.member.id;
        }
        return {
            variables: {
                memberId: userId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props:({data})=>{
        const { loading } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        return {
            loanDeviceInfo: _.get(data, 'getLoanDevice', [])
        }
    }
})
const curVisitInfo = graphql(visitList, {
    options: (ownProps) => {
        const {patient, visitInfo} = ownProps;
        let userId = null;
        if(patient){
            userId = _.get(patient, 'user.id', '');
        } else {
            userId = visitInfo.member.id;
        }
        return {
            variables: {
                memberId: userId,
                type: ['INIT', 'CLINIC_FOLLOW_UP'],
                sort: { 'field': 'CREATED_AT', 'direction': 'DESC' },
                count: 1
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if(loading){
            return  {
                loading:loading
            }
        }
        return {
            curVisitInfo: _.get(data, 'visitList.data', [])
        }
    }
})
const orgScheduleInfo = graphql(orgScheduleQuery, {
    options: () => {
        const { id } = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole.organization;
        return {
            variables: {
                id
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data }) => {
        const { loading } = data;
        if (loading) {
            return  {
                loading,
            };
        }
        return {
            orgSchedules: _.get(data, 'organization.orgSchedules', []) || [],
        };
    }
})

const getPatientHiatusStatus = graphql(getPatientHiatusQuery, {
  options: (ownProps) => {
    const {patient, visitInfo} = ownProps;
    let memberId = null;
    if(patient){
        memberId = _.get(patient, 'user.id', '');
    } else {
        memberId = visitInfo.member.id;
    }
    return {
      variables: {
          memberId
      },
      fetchPolicy: 'network-only'
    };
  },
  props: ({ data }) => {
    const { loading, getPatientHiatusStatus, refetch, error } = data;
    if(error)
      console.error(error);
      
    return {
      hiatusStatus: loading ? {} : (getPatientHiatusStatus || {}),
      refetchHiatusStatus: refetch
    };
  }
})

InputVisitFormComponent = Form.create({
    onValuesChange: (props, changedValues, allValues) => {
        const { disableSaveButton, form, visitInfo, curVisitInfo, patient, patientComplexity } = props;
        // if(disableSaveButton) {
        //     props.setDisableSaveButton(false);
        // }
        const { type, category } = changedValues;
        const visitType = _.get(allValues, 'type');
        const visitCategory = _.get(allValues, 'category');
        const selectedVisitRoles = _.get(allValues, 'visitRoles');

        if(form && (type || category)) {
            // current values of type and category
            const visitRoles = getVisitRoles(visitType, visitCategory,patientComplexity,visitInfo);
            form.setFieldsValue({ visitRoles });
        }

        // if(_.last(selectedVisitRoles) == 'HC'){

        //     const visitRoles = ["CA", "MA"]//_.remove(selectedVisitRoles, 'RD');
        //     console.log("after remove: ", visitRoles);
        //     form.setFieldsValue({ visitRoles })
        // }
        const isPhoneVisit = visitCategory === 'PHONE';
        const hasPhone = (_.get(curVisitInfo, '0.member.phone') || _.get(visitInfo, 'member.phone') || _.get(patient, 'user.phone') ||[]).length > 0;
        const hasError = isPhoneVisit && !hasPhone;
        props.setDisableSaveButton(!!hasError);
        if(dataHelper.needReValidateAvailability(_.first(Object.keys(changedValues)))) {
            dataHelper.reValidAssignees(props.form);
        }
    }
})(InputVisitFormComponent);

const mapDispatch = (dispatch, ownProps) => {
    const { timerName } = ownProps;
    const patientId = _.get(ownProps, 'patient.user.id', '') || _.get(ownProps, 'visitInfo.member.id', '');
    return {
        timerClaim: mapDispatchToTimerActions(dispatch, timerName || INPUT_VISIT_TIMER, patientId),
        patientId,
    }
}
export default compose(connect(null,mapDispatch), curVisitInfo, loanDeviceInfo, orgScheduleInfo, withComplexity, withEnrolledCurrentProgram, getPatientHiatusStatus)(InputVisitFormComponent);
