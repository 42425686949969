import gql from 'graphql-tag'
import {EnrolledProgram} from './schema/EnrolledProgram'

export default gql`
  mutation suspendEnrolledProgram(
    $id : EID!
  ){
      suspendEnrolledProgram(
        id: $id,
      ) {
        ${EnrolledProgram}
      }
  }
`
