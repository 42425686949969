import { React, IHBaseForm, IHButton, _, IHLoading } from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import insertHS from 'modulesAll/graphql/insertHS';
import ManualInputContainer from './ManualInputContainer';
import { patientWeightInputValidator } from 'libModule/validator';
import { convertUnits } from 'libModule/utils/convertUnits'

const VSM_US_units = convertUnits('VSM_US');

class component extends ManualInputContainer {
  static displayName = 'patientView/manualInput/containers/HSInputContainer'

  getVitalForm() {
    return[
      {
        type: 'rowGroup',
        className: 'number-row',
        items: [
          {
            key: 'weight',
            label: `Weight (${VSM_US_units.HS.unit})`,
            type: 'inputNumber',
            rowGroupSpan: 8,
            rules : [
              {validator: (rule, value, callback) => callback(patientWeightInputValidator(value))}
            ],
          },
        ],
      },
    ];
  }

  insertVital(values, commonFields) {
    const variables = {
      ...commonFields,
      weight: { value: values.weight, unit: VSM_US_units.HS.unit },
    };

    return this.props.insertHS({ variables })
      .then(res => _.get(res, 'data.insertHS.id'));
  }

  render() {
    return (
      <ManualInputContainer
        refId={this.props.refId}
        vitalType="Weight"
        vitalForm={this.getVitalForm()}
        insertVital={this.insertVital.bind(this)}
        enrolledProgramName={this.props.enrolledProgramName}
      />
    );
  }
}

export default compose(
  graphql(insertHS, { name: 'insertHS' })
)(component);
