import React from 'react';
import autoCompleteHelper from '../../helper/autocomplete';
import SelectedMembersComponent from '../components/SelectedMembersComponent';
import { Button, message, Form, Modal, AutoComplete, Input, Icon } from 'antd';
import { IHLoading } from 'ihcomponent';
import API from '../../API';
import roleMap from '../../constant/roleMap';
import '../../style/addModal.scss';
import CreateMemberContainer from './CreateMemberContainer';
import helper from '../../helper';
import { ROLE_NAME } from '../../../../lib/constants';
import { clinicTabKeyMap } from '../../constant/formConfig';
const currentTab = 'IHEALTH_MEMBER';
const SEARCH_INTERVAL = 1000; // allow user to search every SEARCH_INTERVAL (ms)
const DISPLAYED_ROLES = ['Doctor', 'Clinic Medical Assistant', 'Medical Assistant', 'Nurse', ROLE_NAME.HC];

const AddMemberContainer = class extends React.Component{
    static displayName = 'newAdmin/Area/container/AddiHealthMemberContainer';

    constructor(props) {
        super(props);
        const { organization } = props;
        let currentOrgRoles = {};
        let availableRoles = [];
        // get refId of current organization
        _.map(_.get(organization, 'roles'), r => {
            if(_.includes(DISPLAYED_ROLES, r.name)){
                currentOrgRoles[r.name] = r.refId;
                availableRoles.push(roleMap[r.name]);
            }
        })


        this.state ={
            searchStr: '',
            canSearch: true,
            showModal: false,
            memberId: null,
            loading: false,
            selectedProviders: {},
            currentOrgRoles,
            availableRoles,
            showFailedModal: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        const preAddModalVisible = _.get(prevProps, 'state.addModalVisible');
        const curAddModalVisible = _.get(this.props, 'state.addModalVisible');
        if(preAddModalVisible === currentTab && curAddModalVisible !== currentTab){
            this.resetState();
        }
    }

    resetState = () => {
        this.setState({
            searchStr: '',
            canSearch: true,
            showModal: false,
            memberId: null,
            selectedProviders: {}
        })
    }

    onSearchChange = _.debounce(async searchStr => {
        const { selectedTab } = this.props;
        const rootId = helper.getViewerOrgId();
        const roleName = clinicTabKeyMap[selectedTab].roleName;
        // const { canSearch } = this.state;

        // this.setState({ searchStr, canSearch: false }, () => {
        //     _.debounce(() => this.setState({ canSearch: true }), SEARCH_INTERVAL)(); // debounce the search
        // });
        if(searchStr.trim()) {
            const data = await helper.getMembersByAppRoles({
                rootId,
                roleName,
                search: {fields: ['NAME'], match: [searchStr]},
            });
            const providerList = _.get(data, 'data.userList.data', []);
            this.setState({
                providerList,
            })
        }

        // }
        // this.setState({ searchStr, canSearch: false }, () => {
        //     _.debounce(() => this.setState({ canSearch: true }), SEARCH_INTERVAL)(); // debounce the search
        // });
    }, SEARCH_INTERVAL);

    setEditMode = (isEditMode = true) => {
        if(!this.props.isEditMode || !isEditMode) {
            this.props.setEditMode(isEditMode);
        }
    };

    addProviderToState = (providerId, doc) => {
        this.setState({
            selectedProviders: {
                ...this.state.selectedProviders,
                [providerId]: doc
            }
        })
    }

    onSelectProvider = (providerId, doc) => {
        const keys = Object.keys(this.state.selectedProviders);
        // avoid duplicate choose
        if(!_.includes(keys, providerId)){
            this.setEditMode();
            this.addProviderToState(providerId, doc);
        }
    }

    onCancelSelect = (id) => {
        const selectedProviders = {...this.state.selectedProviders};
        delete selectedProviders[id];
        this.setState({selectedProviders});
        if(_.isEmpty(selectedProviders)) {
            this.setEditMode(false);
        }
    }

    onRoleSelect = (providerId, role) => {
        let selectedProviders = {...this.state.selectedProviders};
        const keys = Object.keys(selectedProviders);
        if(_.includes(keys, providerId)){
            // If the provider exists, add to state
            selectedProviders[providerId] = {
                ...selectedProviders[providerId],
                selectedRole: role
            }

            this.setState({selectedProviders});
        }
    }

    onAddClick = () => {
        const { form, closeAddModal, refetchList, updateNeedRefetchTeamList, refetch } = this.props;
        const { selectedProviders, currentOrgRoles } = this.state;
        let failedCases= [];
        form.validateFields( async (error) => {
            if(error) return;

            // at first, giving a loading status
            if(!_.isEmpty(selectedProviders)){
                this.setState({loading: true});
            }

            // start calling API
            await Promise.all(
                Object.keys(selectedProviders).map( async (id) => {
                    const doc =  await selectedProviders[id];
                    // if the member is new registered, then there's no children.props, just user the document
                    // see CreateMemberFormComponent
                    const childProps = await _.get(doc, 'props.children.props') || doc;
                    const refId = await currentOrgRoles[_.get(doc, 'selectedRole', _.get(childProps, 'previousRole'))];
                    const fullName = await _.get(childProps, 'fullName');
                    const variables = {
                        id,
                        roles: [refId]
                    }
                    await API.addEmployee(variables).then(res => {
                        // If add successfully, remove this provider from the temporary table
                        this.onCancelSelect(id);
                    }).catch(e => {
                        failedCases.push(<div key={id}>{fullName + ' : ' + e.message}</div>)
                        throw e;
                    })
                })
            ).then(() => {
                this.setEditMode(false);
                closeAddModal();
                message.success('Member(s) Added!');
                // refetch the user List
                refetchList();
                // adding this function to update the team list after adding employee
                updateNeedRefetchTeamList(true);
                // adding this refetch to update the header
                refetch();
            }).catch(e => {
                // if error exists, the modal stay open, so close the loading status
                this.setState({loading: false});
                Modal.warning({
                    title: 'Failed to add the following member(s):',
                    content: <div>
                       {failedCases}
                    </div>
                });
                console.error(e);
            })
        })
    }

    render() {
        const { selectedProviders, loading, availableRoles, searchStr} = this.state;
        const { form, organization, refetch, refetchList, updateNeedRefetchTeamList, loadingProviderList  } = this.props;
        const options = helper.renderProviderList(this);
        const {onCancelSelect, onRoleSelect, onAddClick, onSelectProvider} = this;
        return <div className='add-members-modal'>
                <div className='add-members-header'>
                    Add members
                    <div className = 'create-new-member'>
                        <CreateMemberContainer  organization = {organization}
                                                refetchList={refetchList}
                                                refetch={refetchList}
                                                updateNeedRefetchTeamList={updateNeedRefetchTeamList}
                                                onSelectProvider={onSelectProvider}
                                                onRoleSelect={onRoleSelect}/>
                    </div>
                </div>
            <div className='add-members-content'>
                {loading && <div className='add-members-loading'><IHLoading /></div>}
                <div className='search-component'>
                    <AutoComplete
                     className= 'search-providerlist-autocomplete'
                     dataSource={options}
                     onSearch={(searchStr) => {
                        this.setState({ searchStr }, () => this.onSearchChange(searchStr));
                     }}
                     onSelect={(t, doc) => this.onSelectProvider(t,doc)}
                     autoFocus={true}
                     value={searchStr}
                     defaultActiveFirst={{ maxHeight: 400, paddingLeft: 15, overflow: 'scroll'}}
                    >
                    <Input prefix={<Icon type="search" style={{color: 'rgba(0,0,0,.25)'}}/>}
                           placeholder="Search by name"/>
                    </AutoComplete>
                </div>
                <div className='selected-table'>
                    <SelectedMembersComponent selectedProviders={selectedProviders}
                                              onCancelSelect={onCancelSelect}
                                              onRoleSelect={onRoleSelect}
                                              availableRoles={availableRoles}
                                              form={form}/>
                </div>
                <div style={{marginTop: '20px'}}>
                        <Button type='primary'
                                style={{float: 'right'}}
                                onClick={onAddClick}
                                disabled={_.isEmpty(selectedProviders)}
                                >Add</Button>
                </div>
            </div>
        </div>
    }
}


export default Form.create({
    name: 'add-member-form'
})(AddMemberContainer);
