import _ from 'lodash';
import UserClass from 'modulesAll/graphql/class/User';
import React from 'react';
import { SUBSCRIBE_STATUS_FILTER } from '../constants/MainConstants';
import '../css/index.scss';
export const hasCCMOrRPM = (doc)=>{
    const categories = doc.user.format('CCM/RPM');
    let content = [];
    categories.forEach((o,i)=>{
        if(o.enrolled){
            content.push(_.toUpper(o.name));
        }
    })
    const hasCCM = _.includes(content,'CCM');
    const hasRPM = _.includes(content,'RPM');

    return {
        CCMOnly: hasCCM && !hasRPM,
        RPMOnly: !hasCCM && hasRPM,
        RPMAndCCM: hasCCM && hasRPM
    }
};

export const getTotalTime =(data)=>{
    const user = new UserClass(data);
    const totalTime = _.get(user,'variables.user.variables.monthlyTimeSpent.totalTime', 0) ;
    return totalTime
}
const getHasSpokenWithPatient = (doc,events)=>{
    const user = new UserClass(doc);
    const { callLogFlag,claimedTimeLogFlag } = _.get(user,'variables.user.variables.profile') ;
    const hasClaimedTimeLogFlag = _.includes(events,'Spoke with patient');
    // return callLogFlag && claimedTimeLogFlag;
    return callLogFlag|| claimedTimeLogFlag||hasClaimedTimeLogFlag;
}

export const getComplianceRes = (doc,{ totalTimeFromForm,events,showTotalTimeNum,isFromList },header)=>{
    let totalTime = getTotalTime(doc);
    totalTime = !isFromList && _.get(events,'length') ? totalTimeFromForm : totalTime;

    const spokenWithPatient = getHasSpokenWithPatient(doc,events);
    const moreThan20Min = totalTime >=1200;
    const { CCMOnly,RPMAndCCM,RPMOnly } = hasCCMOrRPM(doc);
    let typeClass = '';
    let res = '';
    let spokenWithPatientType = '';
    let mainHeader = '';
    let billableTimeEligible = false
    let spokenWithPatientEligible = false;
    let billableTypeClass ='';
    if(CCMOnly || RPMAndCCM) {
        let isBillable =  moreThan20Min;
        typeClass = isBillable ? 'green' : 'yellow';
        billableTypeClass = isBillable ? 'green' :'yellow';
        res = moreThan20Min ? 'Yes' :'No';
        mainHeader = `Patient is currently ${moreThan20Min ? '':'not'} eligible to bill CCM time`
    }
    if(RPMOnly) {
        billableTimeEligible = moreThan20Min;
        spokenWithPatientEligible = spokenWithPatient;
        billableTypeClass = billableTimeEligible ? 'green' :'yellow';
        typeClass = billableTimeEligible&&spokenWithPatientEligible ? 'green' :'yellow';
        res = billableTimeEligible&&spokenWithPatientEligible ? 'Yes' :'No';
        spokenWithPatientType = spokenWithPatientEligible ? 'green' :'yellow';

        mainHeader = `Patient is currently ${billableTimeEligible&&spokenWithPatientEligible ? '':'not'} eligible to bill RPM time`

    }
    return {
        content:  <div style={{display:'flex',alignItems:'center'}}>{header || res}
                    <div className={`${ showTotalTimeNum ? `${typeClass}Text` : 'circle'} ${typeClass}`}>{ showTotalTimeNum ? `${Math.round(totalTime/60) } mins` :''}</div>
                  </div>,
        billableTimeContent: <div style={{display:'flex',alignItems:'center'}}>{'Billable Time: '}
            <div className={`${ showTotalTimeNum ? `${billableTypeClass}Text` : 'circle'} ${typeClass}`}>{ showTotalTimeNum ? `${Math.round(totalTime/60) } mins` :''}</div>
        </div>,
        spokenWithPatientContent: RPMOnly && <div style={{ display:'flex',alignItems:'center' }}>{'Phone or video call completed: '}
                                                <div className={`${spokenWithPatientType}Text`}>{spokenWithPatientEligible ? 'Yes' :'No'}</div>
                                             </div>,
        header: mainHeader
    }
}

export const isPremiumClinic = ()=>{
    const currentOrg = _.get(JSON.parse(sessionStorage.getItem('currentUser')), 'selectedRole.organization',{ });
    const premiumClinic = currentOrg.premiumClinic;
    return premiumClinic;
}
// const SUBSCRIBE_STATUS_FILTER = {
//     "SUBSCRIBED":[
//         'SUBSCRIBED','RENEW'
//     ],
//     'CANCEL':[
//         'CANCEL','EXPIRED'
//     ],
//     'NOT_START':[]
// }


export const parseSubscriptionFilter = (filters, patientType)=>{
  let statusArr = [];
  const filterPath = `${patientType}.subscription`;
  const filter = _.get(filters, filterPath) || [];
  filter.forEach((s,v)=> statusArr.push(...(SUBSCRIBE_STATUS_FILTER[s] || [s])));
  _.set(filters, filterPath, _.uniq(statusArr));
}

export const getEnrollerRoleType = (remoteEnrollment) => {
    const patientOrgId = _.get(remoteEnrollment, 'organization.id');
    const roles = _.get(remoteEnrollment, 'patientRefer.enrolledBy.allRoles', []);
    if (patientOrgId && roles && roles.length > 0) {
        const role = _.find(roles, (r) => _.get(r, 'organization.id') === patientOrgId);
        return _.get(role, 'roleType');
    }
}
