import React from 'react';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { GQLHelper } from 'libModule/utils';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import moment from 'moment';
import { TaskQuery } from '../query';
import { Input, Popover, Button } from 'antd';
import Client from 'libModule/gqlClient';
import editEnrolledProgram from 'graphqlModule/mutation/editEnrolledProgram';

const BASELINE_PATH = 'task.threshold[0].baseline';
const POBaselineContainer = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseline: _.get(props, BASELINE_PATH),
      expandBaseline: false,
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    const prevBaseline = _.get(prevProps, BASELINE_PATH);
    const baseline = _.get(this.props, BASELINE_PATH);
    if (prevBaseline !== baseline) {
      this.setState({ baseline });
    }
  }

  apiEditEnrolledProgram() {
    const { enrolledProgramId, task } = this.props;
    const { baseline } = this.state;
    if (!_.isEmpty(task)) {
      const { __typename, baselineUpdatedAt, ...threshold } = task.threshold[0];
      const schedule = _.map(task.schedule, ({ __typename, ...s }) => s);
      const variables = {
        id: enrolledProgramId,
        tasks: [{
          id: task.id,
          type: 'PO',
          schedule,
          threshold: [{
            ...threshold,
            baseline: +baseline,
          }]
        }]
      };
      this.setState({ isSaving: true });
      return Client.mutate({
        mutation: editEnrolledProgram,
        variables,
        fetchPolicy: 'no-cache',
        refetchQueries:[{
          query: TaskQuery,
          variables:{
            id: enrolledProgramId
          }
        }]
      })
      .then(() => this.setState({ isSaving: false }))
      .catch(e => {
        this.props.openErrorModal(GQLHelper.formatError(e));
        this.setState({ isSaving: false });
      });
    } else {
      return Promise.reject('Task is empty!');
    }
  }

  handleBaselineChange = (evt) => {
    const value =  evt.target.value;
    if (isNaN(+value)) {
      return;
    }
    this.setState({ baseline: value.trim() });
  }

  closeBaseline = () => {
    this.setState({ expandBaseline: false, baseline: _.get(this.props, BASELINE_PATH) });
  }

  handleVisibleChange = (expandBaseline) => {
    this.setState({ expandBaseline, baseline: _.get(this.props, BASELINE_PATH) });
  }

  handleSave = () => {
    this.apiEditEnrolledProgram()
      .then(() => {
        this.closeBaseline();
      });
  }

  renderBaseline() {
    const { expandBaseline, baseline, isSaving } = this.state;

    return (
      <Popover
        trigger="click"
        overlayClassName="baseline-overlay"
        visible={expandBaseline}
        onVisibleChange={this.handleVisibleChange}
        content={<POBaselinePopoverContent 
          visible={expandBaseline}
          onSave={this.handleSave}
          onBaselineChange={this.handleBaselineChange}
          onCloseBaseline={this.closeBaseline}
          value={baseline}
          isSaving={isSaving}
        />}
      >
        <Input addonAfter="%" style={{ width: 80 }} size="small" value={_.get(this.props, BASELINE_PATH)} onChange={() => {}}/>
      </Popover>
    );
  }

  render() {
    const highest = _.get(this.props, 'highest', 'N/A') || 'N/A';
    const baselineUpdatedAt = _.get(this.props, 'task.threshold[0].baselineUpdatedAt');

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'normal', marginTop: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: 130, fontWeight: 'bold', fontSize: 14 }}>Patient Baseline:</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            {this.renderBaseline()}
            <div style={{ fontStyle: 'italic' }}>(Last Updated: {baselineUpdatedAt ? moment(baselineUpdatedAt).format('MM/DD/YYYY') : 'N/A'})</div>
          </div>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          Highest: {highest}{highest !== 'N/A' && '%'}
        </div>
      </div>
    );
  }
}

class POBaselinePopoverContent extends React.Component {
  constructor(props) {
    super(props);
    this.baselineInputRef = React.createRef();
  }

  componentDidMount() {
    this.setFocus(false);
  }

  componentDidUpdate(prevProps) {
    this.setFocus(prevProps.visible);
  }

  setFocus(prevVisible) {
    if (!prevVisible && this.props.visible && this.baselineInputRef.current !== null) {
      this.baselineInputRef.current.focus();
    }
  }

  render() {
    const { onSave, onCloseBaseline, onBaselineChange, value, isSaving } = this.props;
    const disabled = isSaving || value === '' || value === null || value === undefined;
    return (
      <div>
        <Input 
          addonAfter="%"
          onChange={onBaselineChange}
          value={value}
          style={{ width: 90, marginRight: 15 }}
          ref={this.baselineInputRef}
        />
        <Button type="primary" onClick={onSave} disabled={disabled}>Save</Button>&nbsp;
        <Button onClick={onCloseBaseline}>Cancel</Button>
      </div>
    );
  }

}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal:(errorMessage) => dispatch(openErrorModal(errorMessage))
  }
}
export default compose(connect(null,mapDispatch))(POBaselineContainer);
