import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Icon, Button } from 'antd';
import { graphql } from 'react-apollo';
import { compose, branch } from 'recompose';

import HiatusIndicator from './Indicator';

import { VISIT_TYPE_NAME_MAPPING } from '../../../../lib/constants';
import I18N from '../../../I18N';
import { buildVisitListMini } from '../../../graphql/visitListMini';

import '../styles/overlapNote.scss';

const HiatusOverlapNote = props => {
  const { hiatusStatus, rowClsName, loading } = props;

  const getOverlapHiatusNote = () => {
    // when set hiatus in patient detail
    const { overlapVisit } = props;

    if(_.isEmpty(overlapVisit))
      return null; // no overlap whatsover

    const { type, appointmentAt } = overlapVisit;

    let text = I18N.get('hiatusStatus.warningNote.inDetail');

    text = text.replace('[visitType]', VISIT_TYPE_NAME_MAPPING[type] || '--');
    text = text.replace('[visitDate]', appointmentAt ? moment(appointmentAt).format('MM/DD/YYYY') : '--');

    return text;
  };

  const getOverlapVisitNote = () => {
    // when schedule visit, props.visit must be {} by default
    const { visit } = props;
    const { startDate, endDate } = hiatusStatus;

    if(_.isNil(visit) || !visit.appointmentAt)
      return null; // not scheduling visit OR // visit is not set date yet

    const hStart = moment(startDate).startOf('day');
    const hEnd = moment(endDate).endOf('day');
    if(moment(visit.appointmentAt).isBefore(hStart) || moment(visit.appointmentAt).isAfter(hEnd))
      return null; // visit date is out of hiatus duration

    let text = I18N.get('hiatusStatus.warningNote.inVisitPopup');
    text = text.replace('[startDate]', moment(hStart).format('MM/DD/YYYY'));
    text = text.replace('[endDate]', moment(hEnd).format('MM/DD/YYYY'));
    text = text.split('[link_text]');

    return <div>
      { text[0] }
      <HiatusIndicator.Clickable 
        component={(props) => 
          <Button 
            type='link' 
            className='overlap-visit-info-button' 
            {...props}
          >
            Visit Hiatus
          </Button>
        }
        hiatusStatus={hiatusStatus}
      />
      { text[1] }
    </div>;
  };

  const renderOverlapNote = () => {
    if(_.isEmpty(hiatusStatus) || !hiatusStatus.startDate || !hiatusStatus.endDate || !hiatusStatus.visitHiatus)
      return null; // no overlap whatsover OR no visit hiatus

    if(loading)
      return null;

    // 2 fns can't return something at same time
    const overlapNote = getOverlapVisitNote() || getOverlapHiatusNote();

    return overlapNote ? (
      <Row id='hiatus-status-overlap-note' className={rowClsName || ''}>
        <Col span={1}>
          <Icon type='info-circle' theme='filled' style={{ color: 'var(--dark-blue)' }}/>
        </Col>
        <Col span={23} className='overlap-note-text'>
          {overlapNote}
        </Col>
      </Row>
    ) : null;
          
  };

  return renderOverlapNote();
};

const getOverlapVisit = graphql(buildVisitListMini(['type', 'appointmentAt'], false), {
  options: (ownProps) => {
    const { memberId, hiatusStatus } = ownProps;
    const { startDate, endDate } = hiatusStatus || {};
    // don't get visit in the past
    const today = moment().startOf('day');
    const appointmentFrom = moment(startDate).startOf('day').isBefore(today) ? today : startDate;
    return {
      variables: {
        memberId,
        count: 1,
        appointmentFrom: moment(appointmentFrom).startOf('day').valueOf(),
        appointmentTo: moment(endDate).endOf('day').valueOf()
      }
    };
  },
  props: ({ data }) => {
    const { loading, visitList, error } = data;

    if(error)
      console.error(error);

    return {
      loading,
      overlapVisit: _.get(visitList, 'data.0') || {}
    };
  }
});

HiatusOverlapNote.propTypes = {
  memberId: PropTypes.string.isRequired,
  hiatusStatus: PropTypes.shape({
    startDate: PropTypes.any.isRequired,
    endDate: PropTypes.any.isRequired,
    visitHiatus: PropTypes.bool.isRequired
  })
};

export default compose(
  branch(
    ({ visit, hiatusStatus }) => _.isNil(visit) && hiatusStatus && hiatusStatus.startDate && hiatusStatus.endDate && !!hiatusStatus.visitHiatus,
    compose(getOverlapVisit)
  )
)(HiatusOverlapNote);