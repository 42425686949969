import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'antd'; 
import ClinicGoals from '../component/GoalStatement/ClinicGoals';
import goalHelper from '../component/helper/goalHelper';
import '../style/goalStatement.styles.scss';
import goalAssessmentHelper from '../../workStation/helper/goalAssessmentHelper';

let ClinicGoalContainer = class extends React.Component{
    static propTypes = {
      nonbillable: PropTypes.array,
      nonBillableGoalOptions: PropTypes.object,
      billable: PropTypes.array,
      billableGoalOptions: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
          hasNote: _.get(props, 'program.extraNote')
        }
    }


    render(){
      return (
      <div className='goalStatementContainer' id='goalStatementContainer'>
        <div className='header'>Goals</div>
          <ClinicGoals {...this.props} />
      </div>)
    }
}

ClinicGoalContainer = Form.create({
  onFieldsChange: (props) => {
    const { disabled, onChange } = props;
    if(!disabled && typeof onChange ==='function'){
      let editEnrolledProgramVar = goalHelper.getMutateVariableGoals(props);
      let shouldEditEnrolledProgram = true;
      const goalAssessmentRefetchQueries = goalAssessmentHelper.getGoalAssessmentRefetchQueries(props);
      onChange({
        shouldEditEnrolledProgram,
        editEnrolledProgramVar,
        props,
        editEnrolledProgramRefetchQueries: [...goalAssessmentRefetchQueries]
      });
    }
  }
})(ClinicGoalContainer);

ClinicGoalContainer.title = 'Goals';
ClinicGoalContainer.displayName = 'ClinicGoalsContainer';
export default ClinicGoalContainer;
