import { UNIFIED_CARE_DEFAULT_LOGO } from '../../../lib/constants';

const changeLogoAndTitle = (document)=>{

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const selectedRole = _.get(currentUser,'selectedRole',{});
    const { logo,brandedName } = _.get(selectedRole,'organization',{});
    const defaultLogo = UNIFIED_CARE_DEFAULT_LOGO;
    const defaultTitle = brandedName ? brandedName : (window.VSM_CONFIG.DEMO ? window.VSM_CONFIG.DEMO : 'VSM');
    let link = document.querySelector("link[rel*='icon']");
    link.href = logo||defaultLogo;
    document.title = defaultTitle;

}

export default changeLogoAndTitle;