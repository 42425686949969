import gql from 'graphql-tag'
export default gql`
query providerTags{
  providerNoteTagList{
    data{
      id
      key
      description
      category
    }
  }
}`
