import React from 'react';
import PostItPatientListComponent from '../components/PostItPatientListComponent';
import { Row,Col,Icon,Button,Spin,message } from 'antd';
import Client from 'libModule/gqlClient';

import PostItList from '../../graphql/postItList';
import deletePostIt from '../../graphql/mutation/deletePostIt';
import createPostIt from '../../graphql/mutation/createPostIt';
import editPostIt from '../../graphql/mutation/editPostIt';
import {connect} from "react-redux";
import modalActions  from 'modulesAll/common/actions/modal'
import { openErrorModal,openModal } from 'layoutModule/actions/MainModal';
import * as timerActions from '../../timer/actions/index';
import { IHLoading } from 'ihcomponent';
import noteActions from '../actions';

import { API } from '../../timer/query/index';
import I18N from '../../I18N';
const { stopTimer,updateInterventionDetails }= timerActions.default;
const PostItContainer = class extends React.Component {
    static displayName ='PostItContainer';

    constructor(){
        super();
        this.state = {
            showEditComponent: false
        }
    }

    editPostIt = (variables) =>{
        const { patientId } = this.props;
        return new Promise((resolve,reject)=>{
            Client.mutate({
                mutation: editPostIt,
                variables: variables,
                refetchQueries: [{
                    query: PostItList,
                    variables: {memberId: patientId},
                    fetchPolicy: 'network-only'
                }]
            }).then((res) => {
                this.createTrackingEvent(res,'editPostIt');
                message.success('PostIt Edited!');
                this.setShowEditComponent(false);
                resolve(true);

            }).catch((e) => {
                this.props.openErrorModal(e);
            })
        })
    }

    createTrackingEvent(res,summary){
        const apiName = Object.keys(res.data)[0];
        const docId = res.data[apiName].id;
        const { patientId } = this.props;
        API.saveModalAndUpdateTimer(this.props,this.constructor.displayName,apiName,patientId,docId,summary);
    }

    createPostIt  = (variables) => {
        const { patientId } = this.props;
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const organizationId = _.get(currentUser,'selectedRole.organization.id');

        let parsedVariables = Object.assign(variables,{
            memberId:patientId,
            organizationId
        });

        return new Promise((resolve,reject)=>{
            Client.mutate({
                mutation: createPostIt,
                variables: parsedVariables,
                refetchQueries: [{
                    query: PostItList,
                    variables: {memberId: patientId},
                    fetchPolicy: 'network-only'
                }]
            }).then((res) => {

                this.createTrackingEvent(res,'createPostIt');
                message.success('Note Created!');
                this.props.setShowCreateComponent(false);
                resolve(true);

            }).catch((e) => {
                this.props.openErrorModal(e);
            })
        })
    }

    showConfirmForDeletePostIt = (id)=>{
        const { enableEdit,originalVisit,setOriginalVisit,setSelectedVisit } = this.props;
        return new Promise((resolve,reject)=>{
            const Yes = {
                label: 'Confirm',
                handler: ()=>{
                    resolve(this.deletePostIt(id));
                },
                closeModal: true
            }
            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: `${I18N.get('stickyNote.deleteLabel')}?`,
                content: `Are you sure you want to delete this ${I18N.get('stickyNote.singleLabel')}? This action can not be undone.`
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    deletePostIt = (id)=>{
        const { uuid } = this.props;
        const patientId = _.get(this,'props.patientId');
        const event = 'DELETE_POSTIT';
        const category = 'POSTIT';
        const action = 'UPDATE';
        const timeTrackerEvent = API.startTimeTracker(event,category,action,patientId);
        const canRestUUid = false;
        Client.mutate({
            mutation: deletePostIt,
            variables: {id:id},
            refetchQueries:[{
                query: PostItList,
                variables: { memberId:patientId },
                fetchPolicy:'network-only'
            }]
        }).then((res)=>{
            API.createTimeTracker(event,timeTrackerEvent,canRestUUid,uuid);
            message.success('PostIt deleted!');
        }).catch((e)=>{
            this.props.openErrorModal(e);
        })
    }

    setShowEditComponent = (flag)=>{
        this.setState({
            showEditComponent: flag
        })
    }
    componentWillUnmount() {
        const { setShowCreateComponent } = this.props;
        setShowCreateComponent(false);
    }

    render(){
        const { props } = this;
        const {  loading, postItList } = this.props;
        const { showEditComponent }  = this.state;
        if(loading){
            return <IHLoading />
        }

        return <PostItPatientListComponent postItList = { postItList } className='PostItList'
                                           showConfirmForDeletePostIt = { this.showConfirmForDeletePostIt }
                                           showCreateComponent = { this.props.showCreateComponent }
                                           showEditComponent = { showEditComponent }
                                           setShowEditComponent = { this.setShowEditComponent }
                                           setShowCreateComponent = { this.props.setShowCreateComponent }
                                           createPostIt = { this.createPostIt }
                                           editPostId = { this.editPostIt }
                                           openModal = { openModal }
                                           {...props }
                />

    }
}

const mapToProps = (state,props)=>{
    const uuid = _.get(state,'timer.main.uuid',null);

    return {
        modal:state.MainModal,
        uuid,
        showCreateComponent:state.Stickie.showCreateComponent
    }
};

const mapToDispatch = (dispatch)=>{
    return {
        setShowCreateComponent: flag => dispatch(noteActions.noteStatus(flag)),
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(modalActions.confirm(props)),
        openModal:(content, modalProps) => dispatch(openModal(content, modalProps)),
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
};

export default connect(mapToProps,mapToDispatch)(PostItContainer);

// export default  PostItContainer;
