import _ from 'lodash';
import moment from 'moment';
import { DateRange } from 'moment-range';
import React from 'react';
import { getProviderName, goPath } from "../../../lib/utils";
import I18N from '../../I18N';
import { helpers } from '../../visit/helpers/index';
import helper from '../helper/index'

import { ROLE_MAP } from '../../../lib/constants';
import { renderSelfTag } from '../../visitsPage/helpers';

const dateString = 'MM/DD/YYYY';

const taskTypes = [
  { 
    type:'ASSIGNED',
    title: I18N.get('taskAssignment.tab.myTasks.label') + ' ',
    tooltip: I18N.get('taskAssignment.tab.myTasks.tip')
  },
  { 
    type:'ASSIGNTO',
    title: I18N.get('taskAssignment.tab.tracking.label') + ' ',
    tooltip: I18N.get('taskAssignment.tab.tracking.tip')
  },
  { 
    type:'ALL',
    title: I18N.get('taskAssignment.tab.all.label') + ' ',
    tooltip: I18N.get('taskAssignment.tab.all.tip')
  }
];

const priorityMap = {
     'URGENT':'Urgent' ,
     'MEDIUM':'Medium' ,
     'LOW':'Low'
}
const statusMap = {
    'OPEN':'Open',
    'RESOLVED':'Resolved',
}
const colorMap = {
    'Overdue': '#F2948A',
    'Due Tomorrow': '#F2C81A',
    'Due Today': '#E32215',
} 
const tableColumnsByPatient =(filter,sort)=> [{
        id:'createdAt',
        dataIndex:'createdAt',
        title: I18N.get('taskAssignment.createdAt'),
        render:(d,doc)=>{
            return moment(d).format(dateString)
        },
        sorter:(a,b)=> a.createdAt - b.createdAt
    },
    {
        id:'createdBy',
        dataIndex:'createdBy',
        title: I18N.get('taskAssignment.createdBy'),
        render:(d)=>{
            return _.get(d,'profile.fullName');
        },
    },
    {
        id:'assignedTo',
        dataIndex:'assignedTo',
        title: I18N.get('taskAssignment.assignedTo'),
        render:(d)=>{
            return _.get(d,'profile.fullName');
        }
    },
    {
        id: 'reason',
        title: I18N.get('taskAssignment.reason'),
        dataIndex:'reason',
        render:(d)=>{
          return d;
        }
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title: I18N.get('taskAssignment.priorityLevel.label'),
        render:(d,doc)=>{
            const isUrgent = (d=='URGENT');
            return <p style={{ color:isUrgent ? 'red':null }}>{ priorityMap[d] }</p>
        },
        filters: _.map(priorityMap,(k,v)=> ({ text:k,value:v })),
        onFilter: (value, record) => record.priorityLevel == value

    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title: I18N.get('taskAssignment.dueDate'),
        render:(d) => {
            const now = new Date();
            return d ? Date.daysBetween(now.getTime(), d) :'---'
        },
        sorter:(a,b)=>a.dueDate-b.dueDate

    },
    {
        id:'status',
        dataIndex:'status',
        title: I18N.get('taskAssignment.status'),
        render:(d,doc)=>{
            return statusMap[d];
        },
        filters: _.map(statusMap,(k,v)=> ({ text:k,value:v })),
        onFilter: (value, record) => record.status == value
    }
]

const expandedRowColumn =({taskType}, arr)=>[
    {
        id:'dummy',
        key: 'dummy',
        width: '180px',
    },
    {
        id:'taggedDate',
        dataIndex:'createdAt',
        title: I18N.get('taskAssignment.createdAt'),
        // width: '120px',
        render:(d, data) => {
            const isRead = data.isRead ? data.isRead.value : false;
            const time = moment(d).format(dateString)
                return <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}} >{time}</p>
        }
    },
    {
        id: 'doctorId',
        title: I18N.get('provider.label'),
        dataIndex: 'doctor',
        // width: '120px',
        render: (d, data) => {
            const name = getProviderName(d)
            const isRead = data.isRead ? data.isRead.value : false;
            return <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}} >{name}</p>
        }
    },
    ...!!helper.checkWidth(taskType, 'createdBy') ?
    [{
      id: 'createdBy',
      title: I18N.get('taskAssignment.createdBy'),
      dataIndex: 'createdBy',
      width: helper.checkWidth(taskType, 'createdBy'),
      render: (d, data) => {
          const name = _.get(d, 'profile.fullName', '--');
          const isRead = data.isRead ? data.isRead.value : false;
          return <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}} >{name}</p>
      }
    }] : [],
    ...!!helper.checkWidth(taskType, 'assignedTo') ?
    [{
      id:'assignedTo',
      dataIndex:'assignedTo',
      title: I18N.get('taskAssignment.assignedTo'),
      width: helper.checkWidth(taskType, 'assignedTo'),
      render:(d,{taskAssignments})=>{
          return taskType === '0' ?  '' : _.get(d,'profile.fullName');
      }
    }] : [],
    {
        id: 'reason',
        title: I18N.get('taskAssignment.reason'),
        dataIndex:'reason',
        render:(d, data)=>{
            const isRead = data.isRead ? data.isRead.value : false;
            return <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}}>{d}</p>;
        }
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title: I18N.get('taskAssignment.priorityLevel.label'),
        // width: '140px',
        render:(d, data)=>{
            const isUrgent = (d=='URGENT');
            const isRead = data.isRead ? data.isRead.value : false;
            return <p style={{ color:isUrgent ? 'red':null, fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}}>{ priorityMap[d] }</p>
        }
    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title: I18N.get('taskAssignment.dueDate'),
        // width: '120px',
        render:(d, data)=>{
            const isRead = data.isRead ? data.isRead.value : false;
            const curTime = moment(d).format('L')
            return d ? <p style={{fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}}>{curTime}</p> : '---'
        }
    },
    {
        id:'status',
        dataIndex:'status',
        title: I18N.get('taskAssignment.status'),
        render:(d, data) => {
            const { dueDate, resolvedNote } = data
            const isRead = data.isRead ? data.isRead.value : false;
            const now = d === 'RESOLVED' ? _.get(resolvedNote, 'resolvedAt', '') : new Date().getTime();
            const date = Date.daysBetween(now, dueDate)
            const curStatus = checkStatus(d, date);
            const color = colorMap[curStatus]
            return <p style={{ color:color, fontWeight: taskType !== '0' ? '' : (isRead ? '' : 'bold')}}>{curStatus}</p> 
        }
    }
]
const getFilterDoctors = () => helpers.filterDoctors ();

const tableMap = ({sort,filter,taskType}, ar) =>[
        {
            id:'member',
            title: I18N.get('taskAssignment.patient.name'),
            dataIndex:'member',
            width: '180px',
            render:(d, {member, taskAssignments})=>{
                const { id } = member;
                const { fullName } = member.profile
                const isSelfEnrolled = _.get(d, 'profile.isSelfEnrolled', false);
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                return <a 
                        style={{ fontWeight:isRead ? '' : 'bold' }} 
                        onClick={()=>goPath(`/patients/${id}/enrolledprogram/default`)}>{fullName}{isSelfEnrolled ? renderSelfTag() : ''}</a>
                // return _.get(member,'profile.fullName');
            },

        },
        {
            id:'taggedDate',
            title: I18N.get('taskAssignment.createdAt'),
            dataIndex:'createdAt',
            // width: '120px',
            render:(d, {taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { createdAt } = firstTask
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                const time = moment(createdAt).format(dateString)
                return <p style={{ fontWeight: isRead ? '' : 'bold' }} >{time}</p>
            },
            sorter:true,
            sortOrder: _.get(sort, 'field', null) === 'createdAt' ? _.get(sort, 'order', false) : null,
        },
        {
            id:'doctorId',
            title: I18N.get('provider.label'),
            dataIndex:'doctor',
            // width: '120px',
            render: (d, {taskAssignments}) => {
                const name = getProviderName(d);
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                return <p style={{ fontWeight:isRead ? '' : 'bold' }} >{name}</p>
            },
            filters: _.map(getFilterDoctors(),({id,fullName})=>({ text:fullName,value:id })),
            filteredValue: _.get(filter,'doctorId',[])
        },
        ...ar,
        {
            id: 'reason',
            title: I18N.get('taskAssignment.reason'),
            // width:'270px',
            render:(d,{taskAssignments})=>{
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                const firstTask = _.first(taskAssignments);
                const { reason } = firstTask;
                return <p style={{ fontWeight:isRead ? '' : 'bold' }}>{reason}</p>;
            }
        },
        {
            id:'priorityLevel',
            dataIndex:'priorityLevel',
            title: I18N.get('taskAssignment.priorityLevel.label'),
            // width: '140px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { priorityLevel } = firstTask;
                const isUrgent = (priorityLevel=='URGENT');
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                return <p style={{ color:isUrgent ? 'red':null, fontWeight:isRead ? '' : 'bold' }}>{ priorityMap[priorityLevel] }</p>
            },
            filters: _.map(priorityMap,(k,v)=> ({ text:k,value:v })),
            filteredValue: _.get(filter, 'priorityLevel'),
        },
        {
            id:'dueDate',
            title: I18N.get('taskAssignment.dueDate'),
            // width: '120px',
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { dueDate } = firstTask;
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                const curTime = moment(dueDate).format('L')
                return dueDate ? <p style={{ fontWeight:isRead ? '' : 'bold' }}>{curTime}</p> : '---'
            },
            sorter:true,
            sortOrder: _.get(sort, 'field', null) === 'dueDate' ? _.get(sort, 'order', false) : null,
        },
        {
            id:'status',
            dataIndex:'status',
            title: I18N.get('taskAssignment.status'),
            render:(d,{taskAssignments})=>{
                const firstTask = _.first(taskAssignments);
                const { dueDate, status } = firstTask;
                const isRead = taskType === '0' ? helper.checkIsRead(taskAssignments) : true;
                const now = status === 'RESOLVED' ? _.get(firstTask, 'resolvedNote.resolvedAt', '') : new Date().getTime();
                const date = Date.daysBetween(now, dueDate)
                const curStatus = checkStatus(status, date);
                const color = colorMap[curStatus]
                return <p style={{ color:color, fontWeight:isRead ? '' : 'bold' }}>{curStatus}</p> 
            },
            filters: _.map(statusMap,(k,v)=> ({ text:k,value:v })),
            filteredValue: _.get(filter, 'status') || ['OPEN'],
        },
        ];

const patientTaskHistoryColumn = [
    {
        id:'taggedDate',
        dataIndex:'createdAt',
        title: I18N.get('taskAssignment.createdAt'),
        width: '140px',
        render:(d, data)=>{
            const needFold = helper.checkNeedFold(data);
            const time = moment(d).format(dateString)
            return <p style={{ fontWeight: needFold ? 'bold' : ''}} >{time}</p>
        }
    },
    {
        id:'createdBy',
        dataIndex:'createdBy',
        title: I18N.get('taskAssignment.createdBy'),
        width: '120px',
        render:(d, data)=>{
            const name = _.get(d,'profile.fullName')
            const needFold = helper.checkNeedFold(data);
            return <p style={{fontWeight: needFold ? 'bold' : ''}} >{name}</p>
        }

    },
    {
        id:'assignedTo',
        dataIndex:'assignedTo',
        title: I18N.get('taskAssignment.assignedTo'),
        width: '120px',
        render:(d, data)=>{
            const name = _.get(d,'profile.fullName');
            const needFold = helper.checkNeedFold(data);
            return <p style={{fontWeight: needFold ? 'bold' : ''}} >{name}</p>
        }
    },
    {
        id: 'reason',
        title: I18N.get('taskAssignment.reason'),
        dataIndex:'reason',
        render:(d, data)=>{
            const needFold = helper.checkNeedFold(data);
            return <p style={{fontWeight: needFold ? 'bold' : ''}} >{d}</p>
        }
    },
    {
        id:'priorityLevel',
        dataIndex:'priorityLevel',
        title: I18N.get('taskAssignment.priorityLevel.label'),
        width: '140px',
        render:(d, data)=>{
            const isUrgent = (d=='URGENT');
            const needFold = helper.checkNeedFold(data);
            return <p style={{ color:isUrgent ? 'red':null, fontWeight: needFold ? 'bold' : ''}}>{ priorityMap[d] }</p>
        }
    },
    {
        id:'dueDate',
        dataIndex:'dueDate',
        title: I18N.get('taskAssignment.dueDate'),
        width: '120px',
        render:(d, data)=>{
            const needFold = helper.checkNeedFold(data);
            const curTime = moment(d).format('L')
            return d ? <p style={{ fontWeight: needFold ? 'bold' : ''}}>{curTime}</p> : '---'
        }
    }]

function checkIfDaysBetween (date1, date2) {
  if(date1 == undefined || date2 == undefined) return;
  //Get 1 day in milliseconds
  var one_day=1000*60*60*24;

  // Convert both dates to milliseconds
  var date1_ms = date1;
  var date2_ms = date2;

  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;
  //take out milliseconds
  difference_ms = difference_ms/1000;
  var seconds = Math.floor(difference_ms % 60);
  difference_ms = difference_ms/60;
  var minutes = Math.floor(difference_ms % 60);
  difference_ms = difference_ms/60;
  var hours = moment(date2).diff(date1,'hours');
  var days = moment(date2).diff(date1,'days');
  if (days < 0) {
      return "Overdue"
  } else if(hours <= 24) {
      return "Due within 24 hrs"
  } else 
  if (days == 1) {
      return 'Due Tomorrow'
  } else {
      return 'Open'
  }
}

const checkStatus = (status, date) => {
  if(date === undefined) {
      return status === 'OPEN' ? 'Open' : 'Resolved';
  } else if(date === 'Overdue') {
      return status === 'OPEN' ? date : 'Resolved (Late)'
  } else {
      return status === 'OPEN' ? date : 'Resolved';
  }
}

export default {
    tableMap,
    taskTypes,
    priorityMap,
    statusMap,
    colorMap,
    expandedRowColumn,
    patientTaskHistoryColumn,
    filterDoctors: getFilterDoctors(),
    tableColumnsByPatient,
    checkIfDaysBetween,
    checkStatus
};

Date.daysBetween = checkIfDaysBetween
