import Client from 'libModule/gqlClient';
import editUserPreferences from '../../../../graphql/mutation/editUserPreferences';
import upConstants from './constants';

const getUserPreferences = (user = {}) => {
  const upValues = {...(user.userPreference || {})};
  Object.keys(upValues).forEach(k => {
    upValues[k] = _.omit(upValues[k], '__typename');
  });
  return upValues;
};

// @params
// fieldValues: object - should contain both basic value and its note, ie. followupVisits, followupVisitsNote
// @return
// upValues: object
const parseUserPreferences = (fieldValues, isMNTEnrolled = false) => {
  const validKeys = upConstants.userPreferenceFieldKeys;
  const noteMap = upConstants.keyNoteMapping;
  const sendVitalReminderMap = upConstants.sendVitalReminderMap;
  const sendVitalReminderKeys = Object.keys(sendVitalReminderMap);
  const upValues = _.pick(fieldValues, [...validKeys,...sendVitalReminderKeys]);
  if(_.isEmpty(upValues)) return null;
  validKeys.forEach(k => {
    const value = isMNTEnrolled ? upConstants.valueEnum.YES : upValues[k]; // current is a value
    const noteMapKey = noteMap[k] || '';
    // set as obj
    upValues[k] = {
      value,
      note: _.get(fieldValues, noteMapKey)
    };
  });
  sendVitalReminderKeys.forEach(k=>{
    const value = upValues[k] ? upConstants.valueEnum.YES : upConstants.valueEnum.NO; // current is a value
    const noteMapKey = sendVitalReminderMap[k] || '';
    // set as obj
    upValues[k] = {
      value,
      note:value ? 'YES' :'NO'
    };
  })
  return upValues;
};

const setUserPreferences = (memberId, upValues) => {
  return Client.mutate({
    mutation: editUserPreferences,
    variables: {
      memberId,
      ...upValues
    }
  });
};

export default {
  getUserPreferences,
  parseUserPreferences,
  setUserPreferences
};
