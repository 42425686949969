import ProviderNew from './ProviderNew/containers/ProviderNewContainer';
import ProviderEdit from './ProviderEdit/containers';
import ProviderList from './ProviderList/containers/ProviderListContainer';
import ProviderDetail from './ProviderShow/containers/main';

export {
  ProviderNew,
  ProviderEdit,
  ProviderList,
  ProviderDetail
}
