import { graphql } from 'react-apollo'
import createAdminProgram, { updateQueries } from 'graphqlModule/createAdminProgram'
import actions from 'ProgramNewModule/ProgramInfo/actions/ProgramInfoFormActions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { goPath } from 'libModule/utils'
import { getFormProps, initData } from 'ProgramNewModule/ProgramInfo/components/formData/ProgramInfoForm'
import { IHForm, IHButton } from 'ihcomponent'
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'
const durationLength = 1200;
const durationCalendar = 'MONTH';
class Container extends Component {
  static displayName = 'program/ProgramNew/containers/PrograminfoFormContainer'

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className="program-new-info-form">
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const formProps = getFormProps(initData)
    const body = <IHForm {...formProps} ref={refNode => { this.baseForm = refNode }} />
    return body
  }

  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '67%',
        paddingRight: 5
      }
    }
    const buttonProps = {
      submit: {
        onClick : (e)=>{
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save and Next',
        // disabled: this.props.disable
        disabled: false
      }
    }
    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.submit} />
      </div>
    )
    return footer
  }

  async submit() {
    const formRef = this.baseForm
    const { goIncompletePageCareplan, createAdminProgram } = this.props
    this.props.disableSave() // disable save button to avoid multiple submission
    // validate all fields before submission
    let isFormValid = true
    const callback = (result, value) => {
      // console.log(result, value)
      if (!result) {
        isFormValid = false
      }
    }
    await formRef.getFormData(callback)
    if (!isFormValid) {
      this.props.enableSave()
      return
    }
    const fieldsValue = formRef.getValue()
    const {
      name,
      description,
      healthCondition,
      // durationLength,
      // durationCalendar
    } = fieldsValue
    const variables = {
      name,
      description,
      healthCondition: healthCondition,
      duration: {
        length: durationLength,
        calendar: durationCalendar
      },
      tasks: null
    }

    try {
      const res = await createAdminProgram({ variables, updateQueries })
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.create'),
        details: variables,
        request: variables,
        response: res
      })

      this.props.enableSave()
      // console.log('got data', res.data)
      const program = res.data.createAdminProgram
      goPath(`/programmes_admin/${program.id}/edit-incomplete`)
      goIncompletePageCareplan()
    }
    catch (err) {
      this.props.enableSave()
      this.props.openErrorModal('Error Saving the Program.')
      console.log('there was an error sending the query', err);
      createAuditLog({
        action: I18N.get('auditLog.admin.programme.create'),
        details: variables,
        request: variables,
        response: err,
        success: false
      })
    }
  }
}

const ContainerWithData = graphql(createAdminProgram, {name: 'createAdminProgram'})(Container)

const mapState = ({ program }, ownProps) => {
  return {
    disable: program.ProgramNew.ProgramInfoForm.isButtonDisabled
  }
}

const mapDispatch = (dispatch) => {
  return {
    goIncompletePageCareplan: () => dispatch(actions.goIncompletePageCareplan()),
    disableSave: () => dispatch(actions.setButtonState(true)),
    enableSave: () => dispatch(actions.setButtonState(false)),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
  }
}

Container.propTypes = {
  goIncompletePageCareplan: PropTypes.func,
  createAdminProgram: PropTypes.func,
  openErrorModal: PropTypes.func,
  disable: PropTypes.bool
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
