import {
  React, IHSelect, IHDropdown, _
} from 'ihcomponent';
import { logout } from 'authModule/actions/CurrentUser';
import { goPath } from 'libModule/utils';
import '../css/master.scss';
import get from 'lodash/get';
import { createAuditLog } from 'libModule/utils/auditLog';
import I18N from 'modulesAll/I18N';
import { decryptRole } from 'libModule/utils';
import { API } from '../../chat/actions/api';
import * as R from 'ramda';
import Client from 'libModule/gqlClient';
import chooseLoginOrganization from 'modulesAll/graphql/mutation/chooseLoginOrganization';
import zoomAPI from '../../VideoChat/action';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { CALL_ROLES } from 'modulesAll/utils/constants/role';
import callActions from '../../call/actions';
import roleMap from '../constants/roleMap';
import TimeZoneNavComponent from "./TimeZoneNavComponent";
import SlidesIconContainer from '../containers/SlidesIconContainer';
import getOrgNotifications from '../helper/getOrgNotifications';
import { Spin, Tooltip } from "antd";
import { helpers } from "../../visitNewWorkFlow/helper";
import { removeMTPRBlockAction } from '../../auth/actions/Logout';
import layoutAPI from '../API/index';

/*
content: '';
height: 10px;
width: 10px;
border-radius: 5px;s
background: red;
color: red;
position: absolute;
top: 20px;
right: 655px;
z-index: 2;
*/

const excludedField =['__typename'];
const notificationHover = {
    foodLog:'Food Logs',
    messages:'Messages',
    // tasks:'',
    level1:'Level 1 Clinical Alerts',
    nonLevel1:'Non-Level 1 Clinical Alerts'
}
const UserArea = class extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        const currentUser = this.props.currentUser;
        const role = get(currentUser, 'role')
        let itemList = null

        if (role && role.category === 'MEMBER') {
         itemList = [
            {
                title : 'Settings',
                id : 'settings'
            },
            {
                title : 'Terms of Use',
                id : 'terms_of_use',
                href : '/terms_patient.pdf'
            },
            {
                title : 'Logout',
                id : 'logout'
            }
          ]
        }
        else {
          itemList = [
            {
              title : 'Settings',
              id : 'settings'
            },
            // {
            //     title : 'Terms of Use',
            //     id : 'terms_of_use',
            //     href : '/terms_provider.pdf'
            // },
            {
                title : 'Logout',
                id : 'logout'
            }
          ]
        }

        const p = {
            title : this.renderTitle(),
            className : 'v-user',
            itemList,
            onClick : (key)=>{
                if(key === 'logout'){
                    this.logout('LOGOUT')
                }
                else if (key === 'settings'){
                  if (role && role.category === 'MEMBER') {
                    goPath('/patient/settings')
                  } else {
                    goPath('/providerSettings/change-password')
                  }
                }
                // else if (key === 'terms_of_use'){
                //     createAuditLog({ action: I18N.get('auditLog.Navigation.termsOfUse') })
                //     const getPdfLink = _.find(itemList,['id', 'terms_of_use']).href
                //     window.open(getPdfLink)
                // }
            },
            overlayClassName: 'settings-option-dropdown'
        }

        return (
            <IHDropdown {...p}  />
        )
    }
    onAvatarError(id) {
      API.getUserAvatar(id)
        .then( res => {
          const userProfile = get(res.data.user, 'profile') || {};
          const currentUserId = atob(get(res.data.user, 'id')).split(':')[1];

          const newUser = {
            uuid: currentUserId,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
          };

          this.props.addToUserMap(newUser);
        })
        .catch(err => console.log('onAvatarError, err: ', err));
    }

    renderTitle(){
        const userProfile = get(this.props.currentUser, 'profile') || {}
        const encodedUserId = get(this.props.currentUser, 'id');
        const currentUserId = atob(encodedUserId).split(':')[1];

        const roleName = decryptRole()
        const userName = `${userProfile.firstName || ''} ${userProfile.lastName || ''}`;
        const role = roleMap[roleName];

        return (
            <div className="v-ub">
                <img src={ _.get(this,`props.userMap[${currentUserId}]`) ? this.props.userMap[currentUserId].avatar
                         : '/image/default_avator.png' } onError={ () => this.onAvatarError(encodedUserId) } />
                <span><span style={{fontWeight:'bold'}}>{userName}</span> - { role }</span>
                <i className="fa fa-caret-down"></i>
            </div>
        )
    }

    logout(event){
      const { dispatch } = this.props;
      const cleanUp = () => {
        createAuditLog({ action: I18N.get('auditLog.logout') });
        zoomAPI.leaveMeeting();
        dispatch(logout(event))
      }

      const lgo = () => {
        window.CCPClass.logout({
          onDone: cleanUp,
        });
      };

      const loggingout = () => {
        this.props.handleOnNavigatingAway('logout', lgo);
      }
      this.removeMTPRBlock(loggingout);
    }

    removeMTPRBlock(cb){
      const {removeMTPRBlockAction} = this.props;
      Promise.resolve(removeMTPRBlockAction(true))
      .then(() => cb())
    }
}

const Nav = class extends React.Component{
    static displayName = 'layout/components/NavComponent'

    constructor(props) {
      super(props);
        this.state = {
            orgNotifications: undefined,
            loading: false,
            allOrgs: []
        }
      this.renderRedDot = this.renderRedDot.bind(this);
    }
    componentWillMount() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        this.props.setInstitution(this.currentUser.selectedRole.organization.id);

        const userProfile = get(this.currentUser, 'profile') || {}
        const currentUserId = atob(get(this.currentUser, 'id')).split(':')[1];
        const userRole = _.get(this.currentUser,'selectedRole.name') || '';
        const newUser = {
          uuid: currentUserId,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          avatar: R.isNil(userProfile.avatar) ? '/image/default_avator.png' : userProfile.avatar.link ? userProfile.avatar.link : '/image/default_avator.png'
        };

        this.props.addToUserMap(newUser);
    }

    render(){

        const currentUserRole = decryptRole()
        const callDropFlag = Object.values(CALL_ROLES).includes(currentUserRole);
        return (
            <div className="vsm-top-nav">
              <div className="v-box">
                {/*<IHTooltip title={p.breadCrumb || ''} placement="bottomLeft" overlay=''>*/}
                  {/*<p className="v-path" id="vsm-path-box">{p.breadCrumb}</p>*/}
                {/*</IHTooltip>*/}
                  {/*<TaskAssignmentBannerContainer />*/}
                  { this.renderDropBox() }
                   <TimeZoneNavComponent/>
                   <div style={{width: 100, display: 'inline-flex', justifyContent: 'center' }}>
                     { helpers.checkIsMAOrMD() && <SlidesIconContainer/> }
                   </div>
                  { this.renderUserBox() }
                  { callDropFlag && this.renderTechSupportBox() }
              </div>
            </div>
        )
    }

    parseAlertNotification = (contentMap,id,level1Count,nonLevel1Count)=>{
        const parToKey ={
            level1Count:'level1',
            nonLevel1Count:'none-level1'
        }
        if(level1Count) {
            _.set(contentMap,`${id}.${parToKey.level1Count}`,<Tooltip key={parToKey.level1Count} title={notificationHover.level1} className={parToKey.level1Count+' circle'} overlayClassName={'orgNotificationTooltip'}/>)
        }
        if(nonLevel1Count) {
            _.set(contentMap,`${id}.${parToKey.nonLevel1Count}`,<Tooltip key={parToKey.nonLevel1Count} title={notificationHover.nonLevel1} className={parToKey.nonLevel1Count+' circle'} overlayClassName={'orgNotificationTooltip'}/>)
        }
    }

    getDotsBasedOnOrg = ()=>{
         const { orgNotifications={} }  = this.state;
        // const orgNotifications = {
        //     "alerts": [
        //         {
        //             "count": 550,
        //             "id": "b3JnYW5pemF0aW9uczo1ODQxMDY4YWQ3OTc4NDM2ZWVlY2EzZWU=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 3,
        //             "id": "b3JnYW5pemF0aW9uczo1ZmQ2OGM5OGI5YjM0MzM4OTRjZDc5Y2I=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 1261,
        //             "id": "b3JnYW5pemF0aW9uczo1YTQ0M2JhZTU2ZjM3MTc2MTdiZmNjNTU=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 16937,
        //             "id": "b3JnYW5pemF0aW9uczo1YTMxYmEyZjI5YTRhYTc1OTE2NDhiMDY=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         }
        //     ],
        //     "foodlogs": [
        //         {
        //             "count": 550,
        //             "id": "b3JnYW5pemF0aW9uczo1ODQxMDY4YWQ3OTc4NDM2ZWVlY2EzZWU=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 3,
        //             "id": "b3JnYW5pemF0aW9uczo1ZmQ2OGM5OGI5YjM0MzM4OTRjZDc5Y2I=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 1261,
        //             "id": "b3JnYW5pemF0aW9uczo1YTQ0M2JhZTU2ZjM3MTc2MTdiZmNjNTU=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         },
        //         {
        //             "count": 16937,
        //             "id": "b3JnYW5pemF0aW9uczo1YTMxYmEyZjI5YTRhYTc1OTE2NDhiMDY=",
        //             "__typename": "OrgSmartAlertTaskCount"
        //         }
        //     ],
        // }
        const notificationKeys = Object.keys(orgNotifications);
        const contentMap = {};
        for(let key of notificationKeys) {
            if(!excludedField.includes(key)) {
                orgNotifications[key].map(({id,count,level1Count,nonLevel1Count})=>{
                    if(key=='alerts'){
                        this.parseAlertNotification(contentMap,id,level1Count,nonLevel1Count);
                    }
                    else if(count) {
                        _.set(contentMap,`${id}.${key}`,<Tooltip key={key} title={notificationHover[key]} className={key+' circle'} overlayClassName={'orgNotificationTooltip'}/>);
                    }
                });
            }
        }
        return contentMap;
    }

    createDropDownOptions() {
      const { loading } = this.state;
      if(loading) {
          return [{ value:'loading',name:<div style={{width: 280,textAlign:"center"}}><Spin/></div> }]
      }
      const getOrgName = (obj)=>{
          const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
          return (_.first((_.get(obj,'organization.internalName') || _.get(obj,'organization.name') || ' ').match(NUMERIC_REGEXP))||'').trim()
      }
      const parseArray = (arr)=>{
          const defaultOrg = arr.splice(0, 1); // remove default role from the array
          let numberArray =[];
          let charArray = [];
          for(let obj of arr) {
              let prefix = getOrgName(obj);
              if (prefix&&!isNaN(prefix)) {
                  numberArray.push(obj)
              }else{
                  charArray.push(obj);
              }
          }
          numberArray = numberArray.sort(comparator);
          charArray = _.sortBy(charArray,['organization.internalName','organization.name']);
          return [...defaultOrg,...numberArray,...charArray];

      }
      const comparator = (o1,o2)=>{
          let name1 = getOrgName(o1);
          let name2 = getOrgName(o2);
          if (!isNaN(name1) && !isNaN(name2)) {
             let num1 = parseInt(name1);
             let num2 = parseInt(name2);
             return num1-num2<0 ? -1 : 1;
          }
      }
      const authAllRoles = parseArray(JSON.parse(sessionStorage.getItem('authAllRoles')));
      const orgToNotificationMap = this.getDotsBasedOnOrg();
      return authAllRoles.map(val => {
        const orgId = (val.organization.id);

        return {
          value: val.refId,
          name: val.organization.internalName || val.organization.name,
          renderRedDot: this.renderRedDot(orgToNotificationMap,orgId)
        }
      })
    }

    setOrgNotifications = (orgNotifications)=>{
        this.setState({ orgNotifications,loading:false })
    }

    renderRedDot(orgToNotificationMap,val) {
      const content = Object.values(orgToNotificationMap[val]||{});
      const { OrgHasUnread } = this.props;
      const unreadFlag = _.get(OrgHasUnread,`${btoa(atob(val).split(':')[1])}`,0);
      let notificationSetting = JSON.parse(sessionStorage.getItem('notificationFreq'))
      const showOrgRedDot = notificationSetting === 'NOTIFY_ALWAYS';
      if(unreadFlag && showOrgRedDot) {
           content.push(<Tooltip title={notificationHover["messages"]} className={'messages circle'} overlayClassName={'orgNotificationTooltip'} key={val}/>)
      }
      return content;
    }

    renderDropBox(){
        const roleName = decryptRole()
        const { loading	 } = this.state;
        const selectedRole = JSON.parse(sessionStorage.getItem('currentUser')).selectedRole;
        const { OrgHasUnread } = this.props;
        const currentUserId = sessionStorage.getItem('currentUserId');

        const p = {
            option: this.createDropDownOptions(),
            dropdownClassName: 'organization-option-dropdown',
            loading,
            onDropdownVisibleChange:(v)=>{
                if(v) {
                    this.setState({ loading:true });
                    getOrgNotifications(this.setOrgNotifications);
                    layoutAPI.getUserOrgs({ id: currentUserId }).then(res=>{
                        const allRoles = _.get(res,'data.user.allRolesCrossClinic',[]);
                        sessionStorage.setItem('authAllRoles',JSON.stringify(allRoles));
                    })
                }
            },
            // onVisibleChange:(visible)=>{
            //     console.log(visible);
            // },
            // option : [
            //     {
            //         name : organizationName,
            //         value : organizationId
            //     }
            // ],
            showSearch:true,
            filterOption:(input, option)=>{
                const val = _.get(option,'props.children.props.children.0.props.children','').toLowerCase();
                return val.includes(input.toLowerCase());
                // return option.props.children.props.children.filter( a=>a.props.children.toLowerCase().includes(input.toLowerCase())).length
            },
            optionFilterProp:"children",
            optionParentClass: 'parent-select-option',
            optionLeftDiv: 'select-option-left',
            optionRightDiv: 'select-org-name',
            optionLeftDivContainer: 'select-option-left-container',
            value : selectedRole ? selectedRole.organization.internalName || selectedRole.organization.name: null,
            // refactored from allRole[0] to selectedRole(IH-1052)
            onChange: async (v) => {
              const { institution, handleChangeOrg, handleOnNavigatingAway } = this.props;
              const curOrg = atob(institution).split(':')[1];
              const nextOrg = atob(v).split('organizationId:')[1];

              if(curOrg ===  nextOrg) return;

              await Client.mutate({
                mutation: chooseLoginOrganization,
                variables: { ref: v },
              }).then(async res => {
                handleOnNavigatingAway('switch organization', () => {
                  handleChangeOrg(res.data, v);
                });
              }).catch( error => {
                console.log('chooseLoginOrganization, error: ', error);
              })
            },
        }
        const curOrgId = atob(_.get(selectedRole,'organization.id',':')).split(':')[1];
        //some unselected org
        let hasUnRead = false;
        _.forEach(OrgHasUnread,(v,i)=>{
            if(i!=curOrgId&&v[i]){
                hasUnRead = true;
            }
        })

        if (roleName === 'MEMBER:Member'
        || roleName === 'OTHER:Vendor') {
          return
        }
        else {
          const className = 'v-drop';
          return (
              <div className={className}>
                  <IHSelect {...p} />
              </div>
          )
        }
    }

    renderUserBox(){
        return (
            <UserArea {...this.props} currentUser={this.currentUser}/>
        )
    }

    renderTitle(){
      var agentState = 'Offline'
      var img = '/image/techsupport-gray.png'
      var height = 22;
      var agent = window.awsCCP ? window.awsCCP.agent : undefined;
      if (agent != undefined)  {
        let stateObject = this.props.agentStatus; //agent.getState()
        if (stateObject) {
          if (stateObject == 'Available') {
            agentState = 'Online'
            img =  '/image/techsupport-green.png'
            height = 16;
          } else if (stateObject == 'Offline') {
            agentState = 'Offline'
            img =  '/image/techsupport-gray.png'
            height = 22;
          } else {
            agentState = 'Not Available'
            img =  '/image/techsupport-red.png'
            height = 16;
          }
        }
      }
      return (
          <div className="v-ub">
              <img src={img} style={{width:'21px', height:height}} />
               <span style={{fontWeight:'bold'}}>{ agentState }</span>
              <i className="fa fa-caret-down"></i>
          </div>
      )
  }

  renderTechSupportBox() {
   var itemList = [
      {
          title : 'Offline',
          id : 'Offline'
      },
      {
          title : 'Online',
          id : 'Online',
      },
      {
          title : 'Open CCP',
          id : 'Open CCP'
      }
    ]

    const p1 = {
      title : this.renderTitle(),
      className : 'v-user',
      itemList,
      overlayClassName: 'aws-option-dropdown',
      onClick : (key)=>{
          if(key === 'Offline'){
            window.CCPClass.checkLogin({ 
              redirectToLogin: false,
              onHasLogin: (agent) => {
                var offlineState = agent.getAgentStates().filter(function (state) {
                  return state.type === window.connect.AgentStateType.OFFLINE;
                })[0];
                agent.setState(offlineState, {
                  success: function () {
                    window.localStorage.setItem('agent-choose-status', offlineState.name);
                  },
                  failure: function () {
                  }
                });
              }
            })
          }
          else if (key === 'Online'){
            window.CCPClass.checkLogin({
              onHasLogin: (agent) => {
                var routableState = agent.getAgentStates().filter(function (state) {
                  return state.type === window.connect.AgentStateType.ROUTABLE;
                })[0];
                agent.setState(routableState, {
                  success: function () {
                    window.localStorage.setItem('agent-choose-status', routableState.name);
                  },
                  failure: function () {
                  }
                });
              }
            });
          }
          else if (key === 'Open CCP'){
            window.CCPClass.checkLogin({
              onHasLogin: () => {
                this.props.setCcpStatus('open');
              }
            });
          }
      }
  }

  return (
      <IHDropdown {...p1}  />
  )
  }
}


Nav.propTypes = {

}
// export default Nav

const mapPropsToState = (state) => {
  return {
      agentStatus: state.AWSConnect.agentStatus,
      ccpStatus: state.AWSConnect.ccpStatus,
      shouldRemoveMTPRBlock: _.get(state,'Logout.shouldRemoveMTPRBlock')
  }
}
const mapDispatch = (dispatch) => {
  return {
    setContact: flag => dispatch(callActions.contact(flag)),
    setCcpStatus: flag => dispatch(callActions.ccpStatus(flag)),
    removeMTPRBlockAction: (shouldRemoveMTPRBlock) => dispatch(removeMTPRBlockAction(shouldRemoveMTPRBlock)),
  }
}

export default compose(connect(mapPropsToState, mapDispatch))(Nav);
