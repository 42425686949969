import types from '../constants/MainConstants'

const initState = {
  searchDatabase: false,
  isStatusFilterClicked: false
}

export default (state = initState, action) => {
  switch(action.type) {
    case types.SET_SEARCH_DATABASE:
      return {
        ...state,
        searchDatabase: action.searchDatabase
      }

    case types.SET_STATUS_FILTERED_CLICKED:
      return {
          ...state,
          isStatusFilterClicked: true
      }

    default:
      return state
  }
}
