import React from 'react';
import VisitForMAComponent from '../component/VisitListForMAComponent';
import EditVisitInDashboardContainer from '../../visitNewWorkFlow/containers/EditVisitInDashboardContainer';
import CreateVisitInDashboardContainer from "../../visitNewWorkFlow/containers/CreateVisitInDashboardContainer";
import CURRENT_COMPONENT from "../../visitNewWorkFlow/constants/CurrentComponent";

const  VisitListForMAContainer = class extends React.Component{
    constructor(){
        super();
        this.state = {
            showReschedule: false,
            cancelCreate: false,
        }
    }

    showCreate=()=>{
        this.setState({
            createContainer: true
        });
    }
    cancelCreate=()=>{
        const { refetchVisitList } = this.props;
        this.setState({
            createContainer: false
        },()=>{
            refetchVisitList();
        });
    }
    setReschedule=(visit)=>{
        const { showReschedule } = this.state;
        if (showReschedule) {
            this.setState({
                showReschedule: false
            });
        } else {
            this.setState({
                showReschedule:visit
            });
        }
    }


    createVisitButton = ()=>{
        const { showCreate } = this;
        return <div onClick={()=>showCreate()} style={{textDecoration:'underline',padding:'10px 0px',fontSize:14}}>
                    <a href="javascript: void(0)" style={{ color:'black' }}>Create a new visit</a>
               </div>
    }

    render(){
        const { setReschedule,state,cancelCreate,showCreate,createVisitButton } = this;
        const { showReschedule,createContainer } = state;
        const { visitList,refetchVisitList,user,enrolledPrograms } = this.props;

        return <div>
                { showReschedule ? (<EditVisitInDashboardContainer refetch={ refetchVisitList } visitInfo={ showReschedule } onCancel={ setReschedule }/>) : ('') }
                { createContainer ? <CreateVisitInDashboardContainer onCancel={ cancelCreate } curTab={'Today'} defaultPatient={{user,enrolledPrograms}} defaultValue={CURRENT_COMPONENT.INPUT_VISIT_COMPONENT}/> : '' }
                <VisitForMAComponent visitList={visitList} setReschedule={setReschedule} showCreate={showCreate}/>
                { createVisitButton() }
               </div>
    }
}

export default VisitListForMAContainer;
