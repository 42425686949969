import gql from 'graphql-tag'
import { Program } from 'graphqlModule/schema/Program'

export const removeTaskFromAdminProgram = gql `
  mutation removeTaskFromAdminProgram (
    $id: EID!,
    $taskId: [EID!]
  ) {
    removeTaskFromAdminProgram (
      id: $id,
      taskId: $taskId
    ) {
      ${Program}
    }
  }
`
