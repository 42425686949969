import React from 'react';
import WorkStationSummaryContainer from "./WorkStationSummaryContainer";
import {Col, Row} from "antd";
import AccumulatedBillableTimeContainer from "./AccumulatedBillableTimeContainer";
import SignOffSummaryContainer from './SignOffSummaryContainer';
import {helpers} from '../../visitNewWorkFlow/helper/';

const WorkStationWrapper = class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderHeader = ()=>{
        return <span className={'header'}>My Workstation</span>
    }

    renderBody = ()=>{
        return  <React.Fragment>
                <Col span={16}><WorkStationSummaryContainer {...this.props} /> </Col>
                <Col span={8}>
                  <AccumulatedBillableTimeContainer/>
                  <SignOffSummaryContainer />
                </Col>
               </React.Fragment>
    }
    render() {
        const { renderHeader,renderBody } = this;
        return <div className={'workstationWrapper'}>
                <Row>
                    <Col>{renderHeader()}</Col>
                </Row>
                <Row>
                  {renderBody()}
                </Row>
              </div>
    }
}
export default WorkStationWrapper;
