import {React, BaseComponent, _} from './util'
import { Checkbox, Row, Col } from 'antd';
import IHInput from './IHInput';
import IHButton from './IHButton';
import PropTypes from 'prop-types';
import HealthConditionPicker from '../ShareCare/HealthConditionPicker'
import $ from 'jquery';
const CheckboxGroup = Checkbox.Group;

/*
 * 1/25/18 - JV - IH-284
 * This custom component renders a group of checkboxes, including an input field with submit button. The input field allows user to
 * add new user-defined health condition.
 * TODO: Add columns using the grid system to checkboxes. If you upgrade to AntD 3.0, the Column and Row API works perfectly here but
 * causes code to break in other areas. Figure out a way to render columns without upgrading for now.
 */

const handleAddHealthCondition = prevState => {
  //const healthConditionsUppercase = prevState.healthConditionsInput.toUpperCase();
  const titleCode = prevState.healthConditionsInput.split("::")
  let label = titleCode [0];
  if (titleCode.length > 1) label += `(ICD-10 ${titleCode[1]})`
  const healthInputObj = { label: label , value: prevState.healthConditionsInput };
  const { options,healthConditionsInput } = prevState;
  const inputLength = healthConditionsInput.trim().length;
  if(_.find(options,(o)=>o.value==prevState.healthConditionsInput)||inputLength==0){
    return;
  }
  return {
    options: [...prevState.options, healthInputObj],
    initValue: [...prevState.initValue, prevState.healthConditionsInput],
    healthConditionsInput: ''
  }
};

const Component = class extends BaseComponent{
  defineBaseClassName(){
    return 'IH-CheckboxGroup'
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }
  init(){
    this.type = this.props.type || 'checkboxGroup'

    this.init = false

    this.state = {
      value: [],
      options: this.props.option,
      initValue: this.props.initValue,
      healthConditionsInput: '',
      healthConditionValues: [...this.props.initValue]
    }
    if(!this.init && this.props.initValue){
      this.state.value = this.props.initValue
      this.init = true
    }
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ healthConditionsInput: e.target.value });
  }
  onSelected(value) {
    this.setState({ healthConditionsInput: value });
  }
  onCheckboxChange(val) {
    this.setState({ initValue: val }, () => this.props.onChange(val));
  }
  render(){
    const p = this.getClassAndStyleProp()
    const buttonProp = {
      style: {
        marginLeft: '550px'
      },
      label: 'Add',
      bsStyle: 'primary',
      onClick: () => {
        this.setState(handleAddHealthCondition, () => {
          this.props.onChange(this.state.initValue);
        });
      }
    };
    return ( // TODO: Continue to style to make 2 columns
      <div {...p}>
        <CheckboxGroup style={{paddingLeft: 125, paddingTop: 25}} options={this.state.options} onChange={this.onCheckboxChange} value={this.state.initValue} />
          <div style={{ position: 'relative', width: '100%', lineHeight: 0 }}>
            {/*<IHInput style={{marginLeft: 125, width: '30%', position: 'absolute', float: 'left'}} value={this.state.healthConditionsInput} onChange={this.onChange} placeholder={this.props.placeholder} />*/}
            <div style={{marginLeft: 125, width: '30%', position: 'absolute', float: 'left'}}>
            <HealthConditionPicker  onSelect={this.onSelected.bind(this)}/>
            </div>
            <IHButton {...buttonProp} />
          </div>
      </div>
    )
  }

  // 12/18/17 - JV - This was copied from <IHCheckboxButtonGroup /> may not need
  renderEachOption(item, index){
    const className = 'ih-each'
      + (_.includes(this.state.value, item.value) ? ' active':'')
      + (item.disabled ? ' disabled' : '')
      + (item.selected ? ' active' : '')
    const p = {
      className,
      onClick : (e)=>{
        e.preventDefault()
        const v = e.target.getAttribute('value')
        if(this.type === 'radio'){
          if(_.includes(this.state.value, v)){
            this.setState({
              value : []
            })
          }
          else{
            this.setState({
              value : [v]
            })
          }

        }
        else{
          if(_.includes(this.state.value, v)){
            _.remove(this.state.value, (l)=>l===v)
            this.setState({
              value : this.state.value
            })
          }
          else{
            this.state.value.push(v)
            this.setState({
              value : this.state.value
            })
          }


        }

        if(this.props.onChange){
          _.delay(()=>{
            this.props.onChange(this.type==='radio'?this.state.value[0]:this.state.value)
          }, 200)

        }
      },
    }

    return (
      <span key={index} value={item.value} {...p}>{item.name}</span>
    )
  }
}

Component.propTypes = {
  type : PropTypes.string, //radio checkbox
  initValue : PropTypes.array,
  onChange : PropTypes.func,
  option : PropTypes.array.isRequired  //{name, value}
}

export default Component
