import React from 'react';
import {connect} from "react-redux";
import actions from '../action/index';
import reducerActions from "../action/reducerActions";
import roleMap from "../constant/roleMap";
import {graphql,compose} from "react-apollo/index";
import visitListQuery from "../../graphql/visitList";
import API from '../API';
import { Modal } from 'antd';

const colorStyle = {
    backgroundColor: '#49A5E4',
    boxShadow: '0 0 54px 0 #49A5E4',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
}
const fontStyle = {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    margin: 10
};
const welcomeBoxStyle = {
    width: 250,
    marginTop: 30,
    display: 'flex',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}
const AgoraPatientWaitingComponent = class extends React.Component{
    constructor(){
        super();
    }

    renderOrgName = ()=>{
        const { organization='organization' } = this.props;
        return <div style={{ width: '250px', height: '250px', marginTop: '20%', position: 'relative' }}>
                   <div style={Object.assign({ height:'100%',width:'100%',borderRadius: '100%' },colorStyle)}>
                       <span style={Object.assign({ textAlign:'center' },fontStyle)}>{organization}</span>
                   </div>
               </div>
    }

    async componentDidMount(){
        const { visitId } = this.props;
        try {
            await API.checkInPatient({eventType: 'CHECK_IN', visitId});
        }catch (e){
            console.log(e);
        }
    }

    componentDidUpdate = ()=>{
        const { setAgoraChannel,getVirtualVisitToken } = this.props;
        if(getVirtualVisitToken){
            setAgoraChannel({...getVirtualVisitToken})
        }

    }

    renderWelcomeText = ()=>{
        return <div style={ welcomeBoxStyle }>
                <span>Clinic member will join you soon, please wait.</span>
               </div>
    }

    renderMessage = ()=>{
        const welcomMsg = <span>Verification success!</span>
        const modal = Modal.info({
            title: welcomMsg,
            visible: true,
            footer: false,
            closable: false
        })
        const timer = setInterval(() => {
            secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        });

        return <div></div>
        // return <Modal title={welcomMsg} visible={true} footer={false} closable={false}/>
    }

    render(){
        const { renderOrgName,renderMessage,renderWelcomeText  } = this;
        return <div style={ { backgroundColor:'#0B70B6',height:'100vh',width:'100vw',display: 'flex',flexDirection:'column',alignItems:'center' } }>
                { renderOrgName() }
                {/*{ renderMessage() }*/}
                { renderWelcomeText() }
               </div>
    }
}

const getRoomNumber = graphql(API.getVideoTokenQuery,{
    options:(props)=>{
        const { visitId } = props;
        return {
            variables:{
                visitId
            },
            fetchPolicy:'network-only',
            pollInterval:15000
        }
    },
    props:({ data })=>{
        const { getVirtualVisitToken,error } = data;
        return {
            getVirtualVisitToken
        }
    }

})

export default compose(getRoomNumber)(AgoraPatientWaitingComponent);




