import { COMPLEXITY_SOURCE_PRIORITY } from '../../../lib/constants';

export const getUpdatedComplexitySourceAndLevel = (prevComplexity, currComplexity) => {
    const prevSource = _.get(prevComplexity, 'source');
    const currSource = _.get(currComplexity, 'source');
    const prevLevel = _.get(prevComplexity, 'level');
    const currLevel = _.get(currComplexity, 'level');

    const prevSourcePriority = COMPLEXITY_SOURCE_PRIORITY[prevSource];
    const currSourcePriority = COMPLEXITY_SOURCE_PRIORITY[currSource];

    if(prevSourcePriority < currSourcePriority){
        return {
            source : prevSource,
            level: prevLevel
        }
    }

    return {
        source: currSource,
        level: currLevel
    }
}