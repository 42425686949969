import {
    React, BaseComponent, _
} from 'ihcomponent'
import PropTypes from 'prop-types'
import { renderRangeValueResult, renderRangeValueResultForBG } from 'libModule/helpers/component-helpers'
import { formatScheduleValue } from 'modulesAll/utils/helpers/task'
import { VITAL_TYPE_MAP, VITAL_TYPE_MAP_FROM_SHORT, HR_UNIT, BP_UNIT, WEIGHT_UNIT } from 'modulesAll/utils/constants/task'
import I18N from 'modulesAll/I18N'

const Component = class extends BaseComponent{
    static displayName = 'Threshold1'

    render(){
        return (
            <div className="vsm-common-threshold vsm-common-threshold1">

                <div className="v-header">
                    {this.renderTitle()}
                </div>
                <div className="v-body">
                    {this.renderBody()}
                    {this.renderBottomBody()}
                </div>
            </div>
        )
    }

    renderBottomBody(){
        const {assessmentList, surveyList} = this.props
        if(assessmentList.length < 1 && surveyList.length < 1) return null

        return (
            <div className="v-bottom-body">
                {assessmentList.length > 0 ? <div className="v-bt">
                    <b>{I18N.get('keywords.Assessments')}</b>
                    {
                        _.map(assessmentList, (l, i)=>{
                            return <p key={i}>{l.name} (Schedule: {l.schedule})</p>
                        })
                    }
                </div> : null}

                {surveyList.length>0 ? <div className="v-bt">
                    <b>Surveys</b>
                    {
                        _.map(surveyList, (l, i)=>{
                            return <p key={i}>{l.name} (Schedule: {l.schedule})</p>
                        })
                    }
                </div>  : null}
            </div>
        )
    }

    renderTitle(){
        const title = this.props.title
        if(!title) return null
        return (
          <div className="v-header">
              <p className="v-tlt">{title}</p>
          </div>

        )
    }

    renderBody(){
        return this.renderDataBody()
    }

    renderDataHeader(flag){

        const hh = (
            <div className="v-h-long">
                <span>Critically Low</span>
                <span>Low</span>
                <span>Normal</span>
                <span>High</span>
                <span>Critically High</span>
            </div>
        )

        return (
            <div className="v-d-header">

                <div className="v-h v-h2"></div>
                <div className="v-h v-h3">{hh}</div>

                {flag || <div className="v-h v-h4"></div>}
                {flag || <div className="v-h v-h5">{hh}</div>}
            </div>
        )
    }

    renderDataBody(){
        const data = this.props.list
        return (
            <div className="v-d-body">
                {
                    _.map(data, (item, index)=>{
                        return this.renderEachData(item, index)
                    })
                }
            </div>
        )
    }

    renderEachData(item, index){
        if(!item.data.length) { //do not display if it's assessment
          return ''
        }
        console.log('item: ', item);

        let miu = 0.1
        let isWeight = false
        let baselineWeight
        const isBaseLine = item.isBaseline
        if(item.unit === BP_UNIT || item.unit === HR_UNIT){
            miu = 1
        } else if (item.unit === WEIGHT_UNIT) {
            miu = 0
          isWeight = true
          baselineWeight = item.baselineWeight
        }
        return (
            <div key={index}>

                <div className="v-d-title">
                    {(isWeight && !isBaseLine) ?
                    `Weight Change (${formatScheduleValue(item, VITAL_TYPE_MAP[item.name])})` :
                    item.name + ' (' + formatScheduleValue(item, VITAL_TYPE_MAP[item.name]) + ')'}
                </div>

                {this.renderDataHeader(!item.data[1])}
                <div key={index} className="v-d-each">
                    {
                      item.data[0].name ?
                      <div className="v-h v-h2">
                          {item.data[0].name}
                          <br/>
                          <font>({ item.name === VITAL_TYPE_MAP_FROM_SHORT.BG ? I18N.get('measure.units_BG_US_standard') : item.unit })</font>
                      </div> : null
                    }

                    <div className="v-h v-h3">
                        {
                          item.name === 'Blood Glucose' && item.unit === 'mmol/L' ?
                            renderRangeValueResultForBG(item.data[0].range, miu)
                          :
                            renderRangeValueResult(item.data[0].range, miu, isWeight, baselineWeight, isBaseLine)
                        }
                    </div>
                    {item.data[1]?
                      <div className="v-h v-h4">
                          {item.data[1].name}
                          <br/>
                          <font>({ item.name === VITAL_TYPE_MAP_FROM_SHORT.BG ? I18N.get('measure.units_BG_US_standard') : item.unit })</font>
                      </div> : null
                    }
                    {
                      item.data[1]?
                      <div className="v-h v-h5">
                          {
                            item.name === 'Blood Glucose' && item.unit === 'mmol/L' ?
                              renderRangeValueResultForBG(item.data[0].range, miu)
                            :
                              renderRangeValueResult(item.data[1].range, miu)
                          }
                      </div> : null
                    }
                </div>
            </div>

        )
    }
}

Component.propTypes = {
    /*
    {
        name : 'Blood Glucose',
        unit : 'mmol/L',
        data : [
            {
                name : 'Before Meal',
                range : [
                    3.11, 3.89, 5, 6.89
                ]
            },
            {
                name : 'After Meal',
                range : [
                    3.11, 3.89, 5, 6.89
                ]
            }
        ]
    }
     */
    list : PropTypes.array.isRequired,
    title : PropTypes.string,
    assessmentList : PropTypes.array,
    surveyList : PropTypes.array
}

export default Component
