import { Upload, Icon, message, Popover, Button} from 'antd';
import React from 'react'; 
import API from '../../API';
import presignedUploadGQL from '../../../provider/gql/getpresignedUpload';
import Client from 'libModule/gqlClient';
import UserEditPhotoComponent from '../Component/UserEditPhotoComponent';
class UserEditPhotoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imageUrl: this.props.photo,
            visible: false,
            progress: 0
        }
    }
    setProgress = (progress) => {
        //this.setState({progress});
    }
    saveImage = async image => {
        const imageName = _.get(image, 'name');
        let res = {};
        try {
            res = await getUploadUrl(this.props.id, imageName);
        }
        catch(e){
            throw(e);
        }        
        const data = res.getPresignedUpload.data;
        const url = data.url;
        let fields = data.fields;
        fields = _.isString(fields) ? JSON.parse(fields) : fields;
        const fileExtension = imageName.split('.')[1];
        const mimeType = fileExtension ? (`image/${_.toLower(fileExtension)}`) : 'image/jpg';
        fields['Content-Type'] = mimeType;
        let fileKey={};
        try {
            fileKey = await sendAJaxReq({ uploadUrl: url, filename: imageName, file: image, mimeType, fields, setProgress:this.setProgress});
        }
        catch(e){
            throw(e);
        }    
        API.editEmployee({ id: this.props.id, commonProfile: { avatar: { fileKey }}}).then(()=>{
            message.success("Photo changed successful!");
            this.props.userRefetch();
        })           
    }
    removePhoto = () => {
        API.editEmployee({'id':this.props.id,'commonProfile':{'avatar':null}}).then(()=>{
            message.success("Photo removed successfully")
        });
    }
  render() {
    return (
        <UserEditPhotoComponent
        id={this.props.id}
        getUploadUrl={getUploadUrl}
        saveImage={this.saveImage}
        photo={this.state.imageUrl}
        sendAJaxReq={sendAJaxReq}
        removePhoto={this.removePhoto}
        userRefetch={this.props.userRefetch}/>    
    );
  }
}
const getUploadUrl = (userId, filename) => {
    const variables = {
        userId,
        filename
    };
    return Client.query({
        query: presignedUploadGQL,
        variables,
        fetchPolicy: 'network-only'
    }).then(res => {
        return res.data;
    });
}
const sendAJaxReq = ({uploadUrl,fields,mimeType,filename,file,setProgress}) =>{
    let formData = new FormData();
    Object.keys(fields).forEach(key => formData.append(key, fields[key]));
    formData.append('file', file,filename);
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener("progress",(e)=>{
            const { loaded,total } = e;
            setProgress(loaded/total);
        });
        xhr.addEventListener('error', (e) => {
            reject(e);
        });

        xhr.onreadystatechange = function (e) {
            if (xhr.status >= 200 && xhr.status <= 299) {
                 resolve(fields.key);
            }
            if( xhr.status==400){
                const parse = new DOMParser();
                let xmlDoc = parse.parseFromString(xhr.response,"text/xml");
                const str = xmlDoc.getElementsByTagName('Message');
                const error = str[0].childNodes[0].nodeValue;
                reject(error);
            }
        };
        xhr.open('POST', uploadUrl,true);
        xhr.send(formData);
    })
}

export default UserEditPhotoContainer;