import gql from "graphql-tag";
import { SignOffStatusSchema } from "../schema/SignOffStatus";

export  default gql`
  mutation upsertVersionHistory(
    $enrolledProgramId: EID, 
    $visitId: EID,
    $itemsUpdated: String,
    $careplanData: String,
    $date: CalDate,
  ) {
    upsertVersionHistory(
      enrolledProgramId: $enrolledProgramId,
      visitId: $visitId
      itemsUpdated: $itemsUpdated,
      careplanData: $careplanData,
      date: $date
  ) {
    id
    careplanData
    visit {
      id
      type
      checkinAt
      appointmentAt
      ${SignOffStatusSchema}
    }
  }
}
`;
