import React, { useState, useCallback } from 'react';
import { Form, Modal, Button } from 'antd';

import InformationComponent from '../InformationComponent';
import SignOffSubmittedNote from './SignOffSubmittedNote';
import SignOffItemList from './Items';
import SignOffSubmitInput from './SignOffSubmitInput';

import I18N from '../../../I18N';
import { getRoleAndRoleName } from '../../helper/signOffHelper';

const SignOffBody = (props) => {
  const { form, patientTask, onSubmit } = props;
  const { roleName } = getRoleAndRoleName();
  const [showCoverage, setShowCoverage] = useState(false);

  const handleSubmit = useCallback(_.debounce(() => {
    form.validateFieldsAndScroll((errors, values) => {
      if(errors)
        return;
    
      const comment = _.get(values, 'comment');
      onSubmit(comment);
    })
  }, 200), []);

  const handleAcknowledge = useCallback(_.debounce(() => {
    onSubmit();
  }, 200), []);

  const renderCoverageModal = () => {
    return(<Modal
      visible={showCoverage}
      width='35vw'
      onCancel={() => setShowCoverage(false)}
      footer={null}
    >
      <div style={{ padding: '40px 50px 30px 25px' }}>
        {I18N.get('signOff.coverageText')}
      </div>
    </Modal>);
  }

  const submitBtnText = I18N.get(`signOff.submitBtnText.${roleName}`);

  return <div>
    <div>
      <InformationComponent 
        noShowKey='signOffInformation'
        clickableText={I18N.get(`signOff.information.clickableText.${roleName}`)}
        informationTitle={I18N.get(`signOff.information.title.${roleName}`)}
        noShowText={I18N.get(`signOff.information.noShowText`)}
        okText={I18N.get(`signOff.information.okText`)}
        containerClass='signOff'
      >
        <div className='content'>
          {I18N.get(`signOff.information.getContent.${roleName}`)({
            onClick: () => setShowCoverage(true)
          })}
        </div>
      </InformationComponent>
    </div>
    <div>
      <SignOffSubmittedNote 
        comment={_.get(patientTask, 'comment')} 
        signOffDate={_.get(patientTask, 'signOffDate')} 
      />
    </div>
    <div>
      <SignOffItemList patientTask={patientTask} />
    </div>
    <div>
      {
        roleName === 'RD' ?
          <SignOffSubmitInput form={form} handleSubmit={handleSubmit} submitBtnText={submitBtnText} />
        : <div style={{ textAlign: 'center', marginTop: 30 }}>
          <Button 
            type='primary' 
            style={{ backgroundColor: 'var(--dark-blue)', border: 'var(--dark-blue)'}}
            onClick={handleAcknowledge}
          >
            {submitBtnText}
          </Button>
        </div>
      }
    </div>
    { renderCoverageModal() }
  </div>;
}

export default Form.create({})(SignOffBody);