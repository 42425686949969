
// Default State
const initState = {
  sorter: { field: 'createdAt', order: 'ascend' },
  pagination: {pageSize: 5},
  currentPage: 1
}

export default (state = initState, action) => {
  return state
}
