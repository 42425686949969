import React from 'react';
import {compose, graphql} from "react-apollo/index";
import ProviderSmartAlertComponent from "../component/ProviderSmartAlerComponent";
import smartAlertTaskList from'../query/smartAlertTasksList';
import moment from "moment";
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker;

const ProviderSmartAlertContainer = class extends React.Component{
    renderRangePicker = ()=>{
        const {  fromDate, toDate,setSelectedRange  } = this.props;
        return (
            <div style={{ display:'flex' }}>
                <RangePicker
                    style={{ marginBottom:20,marginLeft:'auto' }}
                    value={[moment(fromDate),moment(toDate)]}
                    defaultValue={[moment(fromDate), moment(toDate)]}
                    onChange={selectedRange => setSelectedRange({
                            fromDate: _.get(selectedRange, '0').startOf('day').valueOf(),
                            toDate: _.get(selectedRange, '1').endOf('day').valueOf()
                        })
                    }
                />
            </div>
        )
    }
    render() {
        const { loading,smartAlertTasksList,status,setStatus } = this.props;
        return <div className={'providerSmartAlertWrapper'}>
                { this.renderRangePicker() }
                <ProviderSmartAlertComponent loadingAlert={loading} smartAlertTasksList={smartAlertTasksList} status={status} setStatus={setStatus}/>
        </div>
    }
}
const  smartAlertTasksList = graphql(smartAlertTaskList,{
    options:(ownProps)=>{
        let variables = {};
        const {  fromDate, toDate,status  } = ownProps;
        const alertCategories = ['PROVIDER'];
        let filter = { 
          categories: alertCategories, 
          toDate, 
          fromDate, 
          status 
        };
        variables.count = 500;
        variables.filters = filter;

        return {
            variables:variables,
            fetchPolicy:'network-only'
        }
    },
    props:({data})=> {
        const { smartAlertTasksList, loading } = data;
        if (loading) {
            return {
                loading
            }
        }

        return {
            smartAlertTasksList: _.get(smartAlertTasksList, 'data') || [],
            pageInfo: _.get(smartAlertTasksList, 'pageInfo') || {},
        };
    }
})

export default compose(smartAlertTasksList)(ProviderSmartAlertContainer);
