// member and provider

const pathStartsWith = [
  '/preview_assessment_filled',
  '/preview_survey_filled',
]

const pathOnly = []
      
export default {
  pathStartsWith,
  pathOnly
}
