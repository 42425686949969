import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Select, Button, Popconfirm, Modal, Icon, Tooltip } from 'antd';
import CustomSelectOptions from './CustomSelectOptions';
import EditableOption from './EditableOption';
import { 
  resolutionOptions, categoryMap, VALUE_DELIMITER 
} from '../../constant/nutritionInterpretationConstants';
import { parseOption, checkIfResolved } from '../../helpers/nutritionHelpers';
import './pesStatement.styles.scss';

const PESStatement = (
  props,
  PESStatementDatasource, 
  ESOptions = {},
  removePESStatement, 
  fetchEtiologyAndSignSymptom
) => {
  const {
    form, isWorkList, canResolvePES, isCheckedIn, disabled, 
    statementIndex, data, removable = false,
    problemOptions, loadingProblemOptions,
    rawIndex
  } = props;
  const formValues = form.getFieldsValue();
  const pesId = _.get(data, 'id');
  
  const resolutionValue = 
    _.get(formValues, `resolution-${statementIndex}`, _.get(data, 'resolution.status'));
  const isResolved = checkIfResolved(resolutionValue);
  
  const problemValue = _.get(formValues, `problem-${statementIndex}`, _.get(data, 'problem.optionId'));

  const clearError = () => {
    // clear error class only iff PES is on WorkList
    const formWrapperRef = 
      $('#nutrition-interpretation-select-wrapper-in-work-list #form-builder-layout');
    // pes statement div starting from index of 2 -> (n-1)
    // MIGRATION ATTENTION
    const pesRef = _.get(formWrapperRef, `0.children.${rawIndex + 2}`);
    pesRef.classList.remove('error');
  };
                        
  const getSelectedValues = category => _.map(_.get(data, category, []), option => {
      const { optRefId, optDefaultValue } = parseOption({ 
        optionName: category,
        statementIndex, ...option 
      });
      return `${optRefId}${VALUE_DELIMITER}${optDefaultValue}`;
    });

  const optionRenderer = props => {
    const { category, groupId, groupName, optObject, defaultIndex } = props;
    const mappedCategory = categoryMap[category];
    const statementCategory = `${mappedCategory}-${statementIndex}`;
    return EditableOption({
      optGroup: { groupId, groupName }, 
      optObject,
      statementIndex,
      defaultIndex, 
      optionName: mappedCategory,
      selectedValues: _.get(formValues, statementCategory, getSelectedValues(mappedCategory)),
      onSave: newValues => {
        form.setFieldsValue({ [statementCategory]: newValues });
      },
      disabled: (disabled || isResolved)
    });
  };

  const getFieldValue = (fieldName, defaultValue) => 
    _.get(formValues, `${fieldName}-${statementIndex}`, _.get(data, fieldName)) || defaultValue;

  // check whether removing PES needs confirmation
  const needRemoveConfirmation = () => {
    // do not confirm when statement is unfinished
    return !_.isNil(problemValue) && hasSelectedES();
  }

  const hasSelectedES = () => {
    const hasEtiology = getFieldValue('etiology', []).length > 0;
    const hasSignSymptom = getFieldValue('signSymptom', []).length > 0;
    return hasEtiology || hasSignSymptom;
  }
  
  const diagnosisTermLabel = (() => {
    const inputLabel = 'Problem or Nutrition Diagnosis Term';
    const confirmDisabled = removable && !needRemoveConfirmation();
    const resolutionLb = <span className='resolution-value'>
      {_.get(_.find(resolutionOptions, {value: resolutionValue}), 'label')}
    </span>;

    return <div 
            id='pes-statement-resolution-wrapper'
            className={!removable && !isCheckedIn ? 'disabled-pes-resolution-select' : ''}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>{inputLabel}</span>
            <Tooltip
              overlayClassName='pes-resolution-tooltip'
              title={(canResolvePES && !isCheckedIn && !removable) ? 'Please check in the visit first' : ''}
            >
              {
                removable ?
                  _.isEmpty(resolutionValue) ?
                    <div id='pes-statement-solution'>
                      <Popconfirm
                        id='pes-popconfirm'
                        title={
                          <div>
                            Are you sure you want to remove this?<br/>
                            This PES statement will be lost
                          </div>
                        }
                        placement='topRight'
                        onConfirm={() => removePESStatement(statementIndex, pesId)}
                        disabled={confirmDisabled}
                        okText='Yes'
                      >
                        <Button
                          id='pes-remove-button'
                          disabled={disabled}
                          onClick={() => {
                            if(confirmDisabled) removePESStatement(statementIndex, pesId);
                          }}
                        >
                          Remove
                        </Button>
                      </Popconfirm>
                    </div>
                  : checkIfResolved(resolutionValue) ?
                      '' 
                      : resolutionLb
                :
                  (isWorkList &&
                    canResolvePES ?
                      form.getFieldDecorator(`resolution-${statementIndex}`, {
                        // when it's null, it doesn't show placeholder
                        initialValue: _.get(data, 'resolution.status') || undefined
                      })(<Select 
                        id='pes-statement-resolution'
                        size='small'
                        style={{ width: '40%', paddingTop: 7, fontSize: '0.8em' }}
                        getPopupContainer={() => 
                      // div#nutrition-interpretation-select-wrapper is in NutritionInterpretationSelect.js
                      // custom styles in nutritionInterpretationSelect.styles.js
                          document.getElementById('nutrition-interpretation-select-wrapper-in-work-list')
                        }
                        placeholder='Select a resolution'
                        disabled={!isCheckedIn}
                      >
                        {
                          _.map(resolutionOptions, o => 
                            <Select.Option key={o.value} value={o.value}>
                              { o.label }
                            </Select.Option>
                          )
                        }
                      </Select>)
                    : resolutionLb
                  )                        
              }
            </Tooltip>
          </div>
  })();

  return [
    {
      label: diagnosisTermLabel,
      key: `problem-${statementIndex}`,
      value: problemValue,
      colProps: { },
      render: () => <Select 
        style={{ width: '100%' }}
        disabled={disabled || isResolved}
        placeholder='Select'
        loading={loadingProblemOptions}
        onChange={(__, o) => {
          const changeP = () => {
            // clear other options
            form.setFieldsValue({
              [`etiology-${statementIndex}`]: [],
              [`signSymptom-${statementIndex}`]: []
            });
            fetchEtiologyAndSignSymptom(o.key);
            clearError();
          };

          if(hasSelectedES()) {
            return Modal.confirm({
              centered: true,
              icon: null,
              content: 
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Icon 
                  type='warning' 
                  style={{ fontSize: 30, marginBottom: 10 }} 
                  theme='twoTone'
                  twoToneColor='#ffcc00'
                />
                <div>
                  Changing 
                  <span style={{ fontWeight: 600, margin: '0px 5px' }}>
                    Problem or Nutrition Diagnosis Term
                  </span> 
                  will clear any selection for Etiology or Signs and Symptoms.
                  Are you sure you want to change?
                </div>
              </div>,
              okText: 'Yes',
              onOk: changeP,
              onCancel: () => 
                // revert to previous value of problem
                form.setFieldsValue({ [`problem-${statementIndex}`]: problemValue })
            });
          } else {
            changeP();
          }
        }}
      >
        {
          _.map(problemOptions, ({refId, value}) => 
            <Select.Option 
              key={refId} 
              value={refId}
              className='problem-item'
              disabled={_.filter(PESStatementDatasource, d => {
                  // using d.statementIndex to get correct reference
                  // form values are one-cycle behind
                  const p = 
                    _.get(formValues, `problem-${d.statementIndex}`, _.get(d, 'data.problem.optionId'));
                  const r = 
                    _.get(formValues, `resolution-${d.statementIndex}`, 
                      _.get(d, 'data.resolution.status'));
                  const isDResolved = checkIfResolved(r);
                  // ignore if statement is resolved
                  // don't disable option when it is selected by self statement
                  return (
                    !isDResolved &&
                    !_.isEqual(statementIndex, d.statementIndex) &&
                    _.isEqual(p, refId)
                  );
                }).length > 0
              }
            >
              {value}
            </Select.Option>
          )
        }
      </Select>
    },
    {
      label: 'Etiology',
      key: `etiology-${statementIndex}`,
      value: getSelectedValues('etiology'),
      colProps: { },
      render: () => <Select 
        mode='multiple'
        dropdownClassName='etiology-select-wrapper'
        style={{ width: '100%', height: '52px !important' }}
        disabled={disabled || !problemValue || isResolved}
        placeholder='Select'
        optionLabelProp='render'
        onChange={() => clearError()}
        getInputElement={() => <input maxLength={0} />}
      >
        {
          CustomSelectOptions({ 
            options: ESOptions.etiology,
            optionRenderer
          })
        }
      </Select>
    },
    {
      label: 'Signs and Symptoms',
      key: `signSymptom-${statementIndex}`,
      value: getSelectedValues('signSymptom'),
      colProps: { },
      render: () => <Select 
        mode='multiple'
        dropdownClassName='signs-and-symptoms-select-wrapper'
        style={{ width: '100%', height: '52px !important' }}
        disabled={disabled || !problemValue || isResolved}
        placeholder='Select'
        optionLabelProp='render'
        onChange={() => clearError()}
        getInputElement={() => <input maxLength={0} />}
      >
        {
          CustomSelectOptions({ 
            options: ESOptions.signSymptom,
            optionRenderer
          })
        }
      </Select>
    }
  ]
};

PESStatement.propTypes = {
  form: PropTypes.object.isRequired,
  isWorkList: PropTypes.bool.isRequired,
  canResolvePES: PropTypes.bool.isRequired,
  isCheckedIn: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  statementIndex: PropTypes.number.isRequired, 
  data: PropTypes.object,
  removable: PropTypes.bool.isRequired,
};

export default PESStatement;
