const hourMap = [
    '07','08','09','10','11','12','13','14','15','16','17','18'
];
const minMap = ['00','15','30','45'];
const hourMap2 = [
    '6','7','8','9','10','11','12','13','14','15','16','17','18'
];
const patientHoursMap = Array.from(Array(24).keys());

const hourMapIn1224Format = hourMap2.map(h=>({
    '12hour': `${parseInt(h)}` > 12 ? `${parseInt(h)-12}` : h,
    '24hour': h
}));
const patientHoursIn1224Format = patientHoursMap.map(h=>({
    '12hour': h > 12 ? (h-12).toString() : h.toString(),
    '24hour': h.toString()
}));

export default {
    hourMap,
    minMap,
    hourMapIn1224Format,
    patientHoursIn1224Format
}
