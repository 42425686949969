import {
  React, BaseComponent
} from './util';

const Component = class extends BaseComponent{
  defineBaseClassName(){
    return 'IH-Loading'
  }

  render(){
    return (
      <div 
        className="IHLoading"
        style={{width:'inherit',display:'flex',justifyContent:'center',padding:10,height:'inherit',alignItems:'center' }}
      >
          <div className="spinner rainbow"></div>
      </div>
    )
  }
}

// const style = {
//   display: 'flex',
//   justifyContent: 'center',
//   margin: '7em'
// }
//
// const Component = () => (
//   <div style={style}>
//     <Spinner spinnerName='three-bounce' noFadeIn />
//   </div>
// )

export default Component
