const BgMap = {
    all: 'All',
    fasting: 'Fasting',
    beforeMeal: 'Before Meal',
    afterMeal: 'After Meal',
    bedtimeOvernightHypo: 'Bedtime+Overnight Hypos',
    bedtime: 'Bedtime',
    overnight: 'Overnight',
    criticalHigh: 'Critical High',
    criticalLow: 'Critical Low'
}

export default BgMap