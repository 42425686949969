import { React, IHLoading, _, message } from 'ihcomponent';
import { withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { GQLHelper } from 'libModule/utils';
import { openErrorModal, closeModal, openModal } from 'layoutModule/actions/MainModal';

// graphql
import getAssessment from 'modulesAll/graphql/assessment';
import insertAssessmentFilled from 'modulesAll/graphql/insertAssessmentFilled';
import insertTaskResult from 'modulesAll/graphql/insertTaskResult';

// component
import NewComponent from '../components/NewComponent';

import * as actions from '../actions/AssessmentFilledActions';
import { goBack } from '../utils'
import { getGraphQuestions } from '../../utils';

import { createAuditLog } from 'libModule/utils/auditLog';
import I18N from 'modulesAll/I18N'

class component extends React.Component {
  static displayName = 'patientView/myAssessmentAndSurvey/edit/containers/NewContainer'

  constructor(props) {
    super(props);

    this.state = { loading: true };
    this.save = this.save.bind(this);
  }

  async componentDidMount() {
    const { client, params, loadAssessment } = this.props;

    const res = await client.query({
      query: getAssessment,
      variables: { id: params.assessmentId },
      fetchPolicy: 'network-only',
    });

    loadAssessment(_.cloneDeep(_.get(res, 'data.assessment')));
    this.setState({ loading: false });
  }

  async save(isSubmit) {
    const { client, params, assessmentFilled } = this.props;
    const { assessmentId, refId } = params;
    const variables = {
      assessmentId,
      memberId: sessionStorage.getItem('currentUserId'),
      date: new Date(),
      timezone: 'America/Los_Angeles',
      submit: !!isSubmit,
      questionResults: getGraphQuestions(this.props.assessmentFilled),
    }
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const patientNRIC = _.get(currentUser, 'identification[0].value')
    const patientName = _.get(currentUser, 'profile.fullName')
    const auditLogDetails = {
      type: assessmentFilled.type,
      name: assessmentFilled.name,
      submit: !!isSubmit,
      questionResults: getGraphQuestions(this.props.assessmentFilled).map(question => {
        delete question['_id']
        return question
      })
    }
    try {
      const res = await client.mutate({
        mutation: insertAssessmentFilled,
        variables,
      });

      createAuditLog({
        patientNRIC,
        patientName,
        action: !!isSubmit ? I18N.get('auditLog.patient.myTask.completeAssessment') : I18N.get('auditLog.patient.myTask.draftAssessment'),
        details: auditLogDetails,
        request: variables,
        response: res
      })

      await client.mutate({
        mutation: insertTaskResult,
        variables: {
          assessmentFilledId: res.data.insertAssessmentFilled._id,
          refId,
          measureId: null,
        },
      });

      message.success(`${I18N.get('keywords.Assessment')} Saved !`);
      goBack(this.props)
    } catch (e) {
      this.props.openErrorModal(GQLHelper.formatError(e));
      createAuditLog({
        patientNRIC,
        patientName,
        action: !!isSubmit ? I18N.get('auditLog.patient.myTask.completeAssessment') : I18N.get('auditLog.patient.myTask.draftAssessment'),
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })
    } finally {
      // setSubmitting(false);
    }
  }

  render() {
    if (this.state.loading) {
      return <IHLoading />;
    }

    return <NewComponent {...this.props} onSave={this.save} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  assessmentFilled: state.myAssessmentAndSurvey.edit.assessmentFilled,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadAssessment: assessment => dispatch(actions.loadAssessment(assessment)),
    openErrorModal: errorMessage => dispatch(openErrorModal(errorMessage)),
    onCancel: () => goBack(ownProps),
    closeModal: () => dispatch(closeModal()),
    openModal: (content, modalProps) => dispatch(openModal(content, modalProps)),
  }
};

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
)(component);
