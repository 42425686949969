import React from "react";
import { Tabs } from 'antd';
import HubInfo from '../../AdditionalInfo/Components/HubInfoComponent';
import HostAndBPMComponent from '../component/hotSpotAndBPMComponent';
import LoanDeviceComponent from '../../loanDevices/components/LoadDeviceComponent'
import {compose, graphql} from "react-apollo/index";
import API  from '../query/API';
import '../style/index.scss';
import { IHLoading } from 'ihcomponent';
import iotDeviceListAndGetLoanDevice from '../query/iotDeviceListAndGetLoanDevice';
import { CellularConnectedDevicesContainer } from '../../../CellularDevices/containers/CellularConnectedDevicesContainer/CellularConnectedDevicesContainer';
import { makeCellularDeviceContextConsumer } from '../../../CellularDevices/context/makeCellularDeviceContextConsumer';
import { getClearDeviceList } from '../../../../lib/helpers/device-helpers';

const { TabPane } = Tabs;

const tabs = [
  {
    key: '0',
    className: 'cellular-devices-container',
    title: 'Cellular Device',
    content: () => (
      <CellularConnectedDevicesContainer />
    ),
  },
    { title: 'EDevice Hub', content:(props)=><HubInfo {...props} />, key: '1' ,className:'hubTab'},
    { title: 'iHealth Clear', content:(props)=><HostAndBPMComponent {...props}
                                            updateIoTDeviceMemberAPI={API.updateIoTDeviceMemberAPI}
                                            editIoTDeviceConfigAPI={API.editIoTDeviceConfigAPI}
                                            removeIotDeviceMemberAPI = {API.removeIotDeviceMemberAPI}
             />, key: '2',className:'hotSpotAndBPM1' },
    { title:'iPhone Tracking',content:(props)=><LoanDeviceComponent {...props}/>,key:'3',className:'loanDevice' }
]


class Main extends React.Component {
    getDefaultTab = () => {
        const { iotDeviceList, getLoanDevice, cellularDeviceContextValue } = this.props;

        if (_.get(cellularDeviceContextValue, 'cellularDevices.length') > 0) {
          return '0';
        }

        if (iotDeviceList && iotDeviceList.length > 0) {
            return '2';
        } 
        if (getLoanDevice) {
            return '3';
        }
        return '1';
    }

    render(){
        const { props } = this;
        const {
          isLoading: isLoadingCellularDevices,
        } = props.cellularDeviceContextValue;

        const isLoading = props.loading || isLoadingCellularDevices;

        return (
            <div>
                <p className="care-plan-fields"><b>Connected Devices</b></p>
                {isLoading
                    ? <IHLoading /> 
                    : <Tabs defaultActiveKey={this.getDefaultTab()} type="card" className='deviceTabs'>
                        {
                          _.map(
                            tabs,
                            ({ title, content, key, className}) => (
                              <TabPane 
                                tab={_.toUpper(title)} 
                                key={key} 
                                className={className}
                              >
                                  {content(props)}
                              </TabPane>
                            )
                          )
                        }
                    </Tabs>
                } 
            </div>
        );  
    }
}

const withData = graphql(iotDeviceListAndGetLoanDevice, {
    options: (ownProps) => {
        const userId = _.get(ownProps, 'data.user.id') || _.get(ownProps, 'patientId');

        return {
            variables:{
                memberId: userId,
            },
            fetchPolicy:'network-only',
            errorPolicy: 'ignore',
        }
    },
    props: ({ data }) => {
      const iotDeviceList  = getClearDeviceList(_.get(data,'iotDeviceList.data', []));
      const getLoanDevice  = _.get(data,'getLoanDevice', null);
      const loading        = _.get(data, 'loading', false);

        if (loading) {
            return {
                loading
            };
        }

        return {
            loading,
            iotDeviceList,
            getLoanDevice,
        };
    }
});

export default compose(withData)(makeCellularDeviceContextConsumer(Main));
