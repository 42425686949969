import {React, IHButton, message, IHSelect, _} from 'ihcomponent';
import { openModal, closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import { GQLHelper } from 'libModule/utils';
import ReportModal from '../../../report/containers/ReportModalContentContainer'
import PropTypes from 'prop-types';
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

const Component = class extends React.Component {
  static displayName = 'patient/main/components/SuspendConfirmComponent'

  constructor(props) {
    super(props);
    this.state = { submiting: false };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit() {
    const { mutate, refetchEnrolledProgramList, dispatch, startDate, id, currentPatient, currentProgram, suspend } = this.props;
    const variables = {
      id: _.get(currentProgram, 'id'),
      reason: _.get(suspend, 'reason')
    }
    const auditLogDetails = {
      programName : _.get(currentProgram, 'name'),
      programDescription: _.get(currentProgram, 'description'),
      healthCondition: _.get(currentProgram, 'healthCondition'),
      suspendReason: _.get(suspend, 'reason')
    }
    const patientNRIC = _.get(currentPatient, 'identification[0].value')
    const patientName = _.get(currentPatient, 'profile.fullName')

    try {
      this.setState({ submiting: true });

      const res = await mutate();
      if(res.data.suspendEnrolledProgram) {
        createAuditLog({
          action: I18N.get('auditLog.provider.patient.suspendProgramme'),
          patientNRIC,
          patientName,
          details: auditLogDetails,
          request: variables,
          response: res    //temporily remove due to res too huge
        })
        message.success(`${I18N.get('keywords.Program')} suspend success!`);
        // const p = {
        //   title: 'Generate NEHR Report',
        //   showModal: true,
        //   showHeaderCloseButton: false,
        //   body: <ReportModal startDate={ startDate }
        //                      reason="Suspend"
        //                      enrolledProgramId={ id } />

        // }
        // dispatch(openModal(p.body, p))
        dispatch(closeModal());
        //refetchEnrolledProgramList();
      }
    }
    catch (e) {
      createAuditLog({
        action: I18N.get('auditLog.provider.patient.suspendProgramme'),
        patientNRIC,
        patientName,
        details: auditLogDetails,
        request: variables,
        response: e,
        success: false
      })
      dispatch(openErrorModal(GQLHelper.formatError(e)))
    }
  }

  render() {
    const { onSubmit } = this;
    const { closeModal, currentPatient } = this.props;
    const { submiting } = this.state;

    return (
      <div className="discharge-modal-body">
        <p>
          When you suspend a patient, they can no longer upload any more data until they resume the {I18N.get('keywords.program')}.
        </p>
        <p>
        Select the reason for suspension and confirm to complete suspension of
          [{currentPatient.profile.firstName} {currentPatient.profile.lastName}].
        </p>

        <span className="IH-FormItem">
          <span className="ant-form-item-control">
            {this.renderSelect()}
          </span>
        </span>

        <div className="discharge-buttons">
          {submiting
            ? <IHButton bsStyle="primary" label="Suspending..." disabled />
            : <IHButton bsStyle="primary" label="Confirm Suspension" onClick={onSubmit} />
          }
          <IHButton label="Cancel" onClick={closeModal} />
        </div>
      </div>
    );
  }

  renderSelect(doc) {
    const { suspend, changeSuspendReason } = this.props
    const sp = {
      value: suspend && suspend.reason,
      option : [ { name: 'Readmitted to Hospital', value: 'Readmitted to Hospital' },
        { name: "Patient's Request", value: "Patient's Request" } ],
      onChange : (reason)=>{ changeSuspendReason(reason) }
    }
    return <IHSelect {...sp} />
  }
};

Component.propTypes = {
  suspend: PropTypes.object,
  currentPatient: PropTypes.object,
  changeSuspendReason: PropTypes.func,
  closeModal: PropTypes.func,
}

export default Component;
