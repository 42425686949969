import React from 'react';
import { Divider } from 'antd';

const ChatPatientUnreadIndicator = (props) => {
  const { 
    curMsgIndex, 
    curMsgTimetoken, 
    lastClientACK, 
    history = [] 
  } = props;

  if (!curMsgTimetoken || !lastClientACK) return null;

  const isLastMsg = curMsgIndex === history.length - 1;
  const nextMsgObj = history[curMsgIndex + 1] || {};
  const nextMsgTimetoken = nextMsgObj.timetoken ? parseInt(nextMsgObj.timetoken, 10) : Infinity;

  
  let shouldShowIndicator = false;
  try {
    shouldShowIndicator = parseInt(lastClientACK, 10) >= parseInt(curMsgTimetoken, 10) 
                          && parseInt(lastClientACK, 10) < nextMsgTimetoken;
  } catch (e) {
    console.error(e);
  }
  const displayText = isLastMsg ? 'PATIENT READ ALL MESSAGES' : 'PATIENT UNREAD';

  return shouldShowIndicator ? (
    <div className={`patientUnreadIndicator ${!isLastMsg ? '' : 'noMoreUnread'}`}>
      <Divider className='indicatorDivider'>
        <header>
          {displayText}
        </header>
      </Divider>
    </div>
  ) : null;
};

export default ChatPatientUnreadIndicator;
