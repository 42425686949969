import gql from 'graphql-tag'

export default gql`
mutation createOrganization(
  $identifier:String, 
  $name:String!,
  $parentId:EID!, 
  $phone:InputPhone,
  $leaf:Boolean, 
  $accessTmpl:AccessTmpl!,
  $description:String, 
  $address:InputAddress,
  $timezone:String,
  $brandedName: String,
  $internalName:String,
  $excludeBilling:Boolean,
  $consentTemplateIds:[EID],
  $orgPermissions:OrgPermissionsInput
  ) {
  	createOrganization(
      identifier:$identifier,
      name:$name,
      parentId:$parentId,
      phone:$phone,
      leaf:$leaf,
      accessTmpl:$accessTmpl,
      description:$description,
      address:$address,
      timezone:$timezone
      brandedName:$brandedName,
      internalName:$internalName,
      excludeBilling:$excludeBilling,
      consentTemplateIds:$consentTemplateIds,
      orgPermissions:$orgPermissions
    )
    {
      id
      name
      orgSummary{
        clinicCount
        areaCount
        roleCounts{
					roleType
					count
				}
      }
    }
  }
`
