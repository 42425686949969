import types from '../constants/OneTimeTaskConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
])

export default {
  ...commonActionsObject,
}
