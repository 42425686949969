/**
 * Created by zizhangai on 1/25/17.
 */
import _ from 'lodash'
import types from '../constants/InterventionsConstants'

export default (state={notes: {}, view: 'partial'}, action) => {
  switch(action.type) {
    case types.ADD_NOTE:
      // if exists, ignore
      return (state.notes.hasOwnProperty(action.id))? state : _.mergeWith({}, state, action.payload)
    case types.OPEN_INPUT:
    // case types.CLOSE_INPUT:
    case types.ADD_REASON:
    case types.DISABLE_BUTTON:
    case types.ENABLE_BUTTON:
      return _.mergeWith({}, state, action.payload)
    case types.CLEAR_STATE:
      return action.payload
    case types.DELETE_STATE:
      return {
        ...state,
        notes: _.omit(state.notes, action.payload)
      }
    case types.SWITCH_VIEW:
      return {
        ...state,
        view: action.payload
      }
    default:
      return state
  }
}

