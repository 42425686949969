import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import CrossClinicPatientsComponent from '../component/CrossClinicPatientsComponent';

const CrossClinicTabContainer = (props) => {
  const {
    tab,
    key,
    ...restProps
  } = props;

  return (
    <Tabs.TabPane key={key} tab={tab} {...restProps}>
      <CrossClinicPatientsComponent {...restProps} />
    </Tabs.TabPane>
  );
};

CrossClinicTabContainer.propTypes = {
  tab: PropTypes.any.isRequired,
  key: PropTypes.string.isRequired,
};

export default CrossClinicTabContainer;
