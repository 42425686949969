const enumToType = {
    'ORAL':'Oral Medication',
    'INJECTABLE':'Injectable',
    'EXTERNAL':'External',
    "INHALED":'Inhalable'
}

const statusEnumToType = {
    'NOT_TAKING':'Not Taking',
    'NOT_SURE':'Not Sure',
    'TAKING': 'Taking',
    'TAKING_IRREGULARLY': 'Taking Irregularly'
};

const medicationEnumToType = {
    "Taken":'Medications taken as prescribed',
    "TakenWithForget":'Medications taken as prescribed, but sometimes forgets',
    "SelfTaken": 'Some medications patient often self titrates or stops independent of/ in conflict with physician advice',
    "NoTaken":"Not taking medications because of non-compliance",
    "NotDone":"Medication reconciliation not done...Patient will do at follow up with MD or with RD online",
    "NoPrescription":"Patient does not have any medications prescribed",
    "Other":"Other"
};

export {
    enumToType,
    statusEnumToType,
    medicationEnumToType
  }