import React from 'react';
import UserClass from 'modulesAll/graphql/class/User';
import { MEAL_TYPES } from '../constant/nutritionIntakeConstants';
import { STATEMENT_DELIMITER, GROUP_DELIMITER, VALUE_DELIMITER } from '../constant/nutritionInterpretationConstants';
import { clearTypenameFields } from '../../../lib/utils';

const renderBodyInfo = user => {
  const uc = new UserClass(user);
  const bodyInfo = uc.format('bodyInfo') || [];
  if (bodyInfo.length > 0) {
    return (
      <div style={{ display: 'flex', fontSize: 14, color: 'rgba(0,0,0,0.85)' }} id='bodyInfoNutritionContainer'>
        {
          _.map(bodyInfo, (info, index) => {
              return <span style={{marginRight: 10}} key={index} id={`bodyInfoNutrition-${index}`}>{info}</span>
          })
        }
      </div>
    );
  }
  return <div>Body info not on file</div>
};

const parseNutritionVariables = (curValues, newValues) => {
  const newNutritionValues = { 
    ...curValues, 
    ..._.omitBy(newValues, _.isUndefined)
  };

  return clearTypenameFields(newNutritionValues);
};

/* --- START --- NUTRITION INTAKE HELPERS */

const parseIntakeValues = (userIntakeValues = []) => {
  const hasUserIntakeValues = userIntakeValues.length > 0; 
  const newUserIntakeValues = hasUserIntakeValues ?
      _.map(userIntakeValues, v => _.omit(v, '__typename'))
    :
      Array(MEAL_TYPES.length).fill().map((__, index) => ({
        meal: _.upperCase(MEAL_TYPES[index]),
        timeRange: '',
        foodTypeAmount: '',
        mealFreq: '',
        skipFreq: '',
      }));
  return newUserIntakeValues;
};

/* --- END --- NUTRITION INTAKE HELPERS */

/* --- START --- NUTRITION INTERPRETATION HELPERS */

const capitalizeFirstChar = string => 
  _.toUpper(string.charAt(0)) + string.substring(1);

const getRawRefId = optRefId => {
  if(!_.includes(optRefId, STATEMENT_DELIMITER)) return optRefId;
  return optRefId.split(STATEMENT_DELIMITER)[1];
}

const parseOption = ({ 
  optionName, // etiology or signSymptom
  statementIndex, 
  groupId, 
  optionId, 
  value: optDefaultValue 
}) => {
  let optRefId = optionId;
  
  if(groupId) {
    optRefId = `${groupId}${GROUP_DELIMITER}${optRefId}`;
  }
  optRefId = `${optionName}${statementIndex}${STATEMENT_DELIMITER}${optRefId}`;
  return { optRefId, optDefaultValue };
}

// check if form values include current value for checkingRefId
// otherwise, return defaultValue
const parseValue = ({selectedValues = [], optRefId, optDefaultValue = ''}) => {
  const actualIndex = _.findIndex(selectedValues, o => {
    const checkingOptRefId = o.split(VALUE_DELIMITER)[0];
    return optRefId === checkingOptRefId;
  });
  return _.get(selectedValues, actualIndex, 
    // default value format: groupId|subOptRefId-defaultValueForSubOpt
    `${optRefId}${VALUE_DELIMITER}${optDefaultValue}`
  );
};

/* 
get templates return long id, but pes_statement* only return short id
shorten id to make it consistent 
*/
const shortenId = id => btoa(_.split(atob(id), ':')[1]);

/*
check if statement is resolved
2 statuses representing for resolved: RESOLVED and NO_LONGER_APPROPRIATE
*/
const checkIfResolved = status => {
  return status ? 
  (status === 'RESOLVED' || status === 'NO_LONGER_APPROPRIATE')
  : false;
}

/* --- END --- NUTRITION INTERPRETATION HELPERS */

export {
  renderBodyInfo,
  parseNutritionVariables,
  parseIntakeValues,
  capitalizeFirstChar,
  getRawRefId,
  parseOption,
  parseValue,
  shortenId,
  checkIfResolved
};