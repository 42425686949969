import React from "react";
import {Button, Row, Icon } from 'antd';
import GoalStatementTooltipComponent from "./GoalStatementTooltipComponent";
import { CLINIC_GOAL_ENUM, conditionToType, mappingCondition, getGoalStatusValue } from '../helper/goalHelper';
import { ROLE_MAP } from '../../../../lib/constants';

import GoalComponentWrapper from './ClinicGoalModules/GoalComponentWrapper';
import BillableGoalComponent from './ClinicGoalModules/BillableGoalComponent';
import NonBillableGoalComponent from './ClinicGoalModules/NonBillableGoalComponent';

const getNonBillableConditions = (billable, nonBillableGoalOptions, data, index) =>{
  let nonBillableRes = [];
  let conditionKeys = [];
  let chosenNonBillable = []; // use for disable multiple condition selection
  _.map(data, d =>{
    const { condition, conditionEnum } = d;
    let key = mappingCondition[conditionEnum] || condition;
    if(key === mappingCondition.UNMAPPED)
      key = condition;

    if(!_.includes(billable, key)) {
      // if the condition doesn't exist in the billable condition,
      // then it is considered as non-billable
      conditionKeys.push(index++);
      nonBillableRes.push({
        ...d, 
        goalOptions: nonBillableGoalOptions[key]
      });
      chosenNonBillable.push(key);
    }
  })
  return {nonBillableRes, conditionKeys, index, chosenNonBillable};
}

const getBillableConditions = (clinicGoals, presetBillable) => {
  let unmappedGoals = _.filter(clinicGoals, g => g.conditionEnum === mappingCondition.UNMAPPED),
      billableConditions = [...(presetBillable || [])];

  // ADD unmapped conditions
  for(let g of unmappedGoals) {
    const { condition } = g;
    if(condition && !_.includes(billableConditions, condition))
      billableConditions.push(condition);
  }
  return billableConditions;
};

const buildStates = (props) => {
  const {billable, nonBillableGoalOptions={}, program, billableGoalOptions = {}, nonBillable} = props;
  const clinicGoals = _.get(program,'clinicGoals')||[];
  let billableConditions = getBillableConditions(clinicGoals, billable);

  const res = getNonBillableConditions(billableConditions, nonBillableGoalOptions, clinicGoals, 0);

  return {
    conditionKeys: res.conditionKeys,
    nonBillableConditions: res.nonBillableRes,
    index: res.index,
    // updateKey: true,
    billable: billableConditions,
    nonBillableGoalOptions,
    nonBillable,
    billableGoalOptions,
    chosenNonBillable: res.chosenNonBillable
  };
};

let ClinicGoals = class extends React.Component{
  constructor(props) {
      super(props);

      const initStates = buildStates(props);
      this.state = {...initStates};
  }


  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { nonBillableGoalOptions={}, program, goalLoading, billableGoalOptions } = this.props;
    if(goalLoading)
      return;
    
    const prevClinicGoals = _.get(prevProps, 'program.clinicGoals') || [];
    const clinicGoals = _.get(program,'clinicGoals')||[];
    // const {updateKey} = this.state;
    // adding the IFs to wait until all the data is ready
    if(!_.isEqual(prevClinicGoals, clinicGoals) ||
        !_.isEqual(prevProps.nonBillableGoalOptions, nonBillableGoalOptions) ||
        !_.isEqual(prevProps.billableGoalOptions, billableGoalOptions)
      ) {
      const updatedStates = buildStates(this.props);
      this.setState({...updatedStates, updateKey: false});
    }
    // if(!_.isEqual(this.state.billable, billable)){
    //   this.setState({ billable, updateKey: true})
    // }
    // if(!_.isEqual(this.state.billableGoalOptions, billableGoalOptions)){
    //   this.setState({billableGoalOptions})
    // }
    // if(!_.isEqual(this.state.nonBillable, nonBillable)){
    //   this.setState({nonBillable})
    // }
    // if(!_.isEqual(this.state.nonBillableGoalOptions, nonBillableGoalOptions)){
    //   this.setState({nonBillableGoalOptions, updateKey: true })
    // }

    // // console.log('hereeee updateKey', this.state.updateKey, this.state);
    // if(this.state.updateKey){
    //   const res = getNonBillableConditions(this.state.billable, nonBillableGoalOptions, clinicGoals, 0);
    //   this.setState({
    //     conditionKeys: res.conditionKeys,
    //     nonBillableConditions: res.nonBillableRes,
    //     index: res.index,
    //     updateKey: false
    //   })
    // }

    // // 1. update the state when billable and nonBillableGoalOptions exists, and only update once
    // // 2. update when finish editing
    const { getFieldValue, setFields} = this.props.form;
    // 0416-skip the change from icd codes
    if( !prevState.updateKey && !_.isEqual(prevState.conditionKeys, this.state.conditionKeys)){
      // when prevState.updateKey and this.state.updateKey are both false, the conditionkeys is originally generated
      // in this case, if conditionKeys is updated, make sure the nonBillable fields are updated.
      const history = getFieldValue('nonBillable');
      const updateField =  _.filter(history, (_, i) => !this.state.conditionKeys.includes(i));
      setFields({'nonBillable': updateField});
    }
  }

  addCondition = () => {
    const {nonBillableConditions, conditionKeys, index} = this.state;
    let updateNonBillableConditions = [
      ...nonBillableConditions, 
      { condition: null, goal: null, goalOptions: null, newAddedGoal: true }
    ];
    this.setState({
      nonBillableConditions: updateNonBillableConditions,
      conditionKeys: [...conditionKeys, index],
      index: index+1,
    });
  }

  removeCondition = (index) =>{
    this.setState({conditionKeys: this.state.conditionKeys.filter((key, i) => key !== index)})
  }

  setGoalOptions = (value, index) => {
    let record = {...this.state.nonBillableConditions[index]};
    const {nonBillableGoalOptions} = this.state;
    record.goalOptions = nonBillableGoalOptions[value];
    record.condition = value;
    this.setState({
      nonBillableConditions: [...this.state.nonBillableConditions.slice(0, index), record, ...this.state.nonBillableConditions.slice(index+1)],
      chosenNonBillable: [...this.state.chosenNonBillable, value]
    })
  }

  setGoal = (field, value, index) =>{
    // SC-8006: if from dropdown, update 'goalEnum', else update 'goal'
    let record = {...this.state.nonBillableConditions[index]};
    record[field] = value;
    this.setState({nonBillableConditions: [...this.state.nonBillableConditions.slice(0, index), record, ...this.state.nonBillableConditions.slice(index+1)]})
  }

  renderBillableCondition = () => {
    const {form, disabled, program, patientRefer,isFirstMNTVisit} = this.props;
    const {billableGoalOptions, billable} = this.state;
    const clinicGoals = _.get(program,'clinicGoals')||[];
    let rowClassName = disabled ? 'clinic-goal-item  disabled' : 'clinic-goal-item';
    return _.map(billable, (d, key) => {
      const options = billableGoalOptions[d];
      const gIndex = _.findIndex(clinicGoals, r => {
        const isUnmapped = r.conditionEnum === mappingCondition.UNMAPPED;
        return isUnmapped ? r.condition === d : r.conditionEnum === conditionToType[d];
      });
      const bg = clinicGoals[gIndex] || {};
      const isUnmappedGoal = bg.conditionEnum === mappingCondition.UNMAPPED;
      const initialOption = gIndex === -1 ? undefined : (isUnmappedGoal ? bg.value : bg.goalEnum);
      const initialStatus = gIndex === -1 ? undefined : getGoalStatusValue(bg);
      // All billables are from dropdown select, using conditionEnum to fitler
      // const i = _.findIndex(clinicGoals, r => r.conditionEnum === conditionToType[d]);
      // const initialOption = i > -1 ? clinicGoals[i].goalEnum : undefined;
      const isReferredGoal = !!_.find(_.get(patientRefer, 'clinicGoals'), isUnmappedGoal ? 
        {
          condition: d,
          conditionEnum: mappingCondition.UNMAPPED,
          value: initialOption,
        } 
      :
        { 
          conditionEnum: conditionToType[d], 
          goalEnum: initialOption
        }
      );
      return(
        <GoalComponentWrapper 
          key={`billable-${key}`}
        >
          <BillableGoalComponent 
            idx={key}
            form={form}
            goalCondition={d}
            goalValue={initialOption}
            goalStatus={initialStatus}
            isReferredGoal={isReferredGoal}
            rowClassName ={rowClassName}
            disabled={disabled} 
            goalOptions={options}
            hideGoalStatus={!!isFirstMNTVisit}
            isUnmappedGoal={isUnmappedGoal}
          />
        </GoalComponentWrapper>
      )
    })
  }

  renderNonBillableCondition = () => {
    const {conditionKeys, nonBillableConditions, nonBillable, billable, chosenNonBillable} = this.state;
    const {form, disabled, patientRefer, isFirstMNTVisit } = this.props
    const conditionOptions = _.filter(nonBillable, o => !_.includes(billable, o) && !_.includes(chosenNonBillable, o));
    const { getFieldValue } = form;
    let rowClassName = disabled ? 'clinic-goal-item  disabled' : 'clinic-goal-item';
    return  _.map(conditionKeys, (indexKey, i) => {
      const record = nonBillableConditions[indexKey];
      if(record){
        const { condition, conditionEnum, goalEnum, value, newAddedGoal } = record;

        const goalStatus = getGoalStatusValue(record);
        
        const isReferredGoal = !!_.find(_.get(patientRefer, 'clinicGoals'), c => {
          const newCondition = getFieldValue(`nonBillable.${indexKey}.condition`);
          const newGoal = getFieldValue(`nonBillable.${indexKey}.goalEnum`) || getFieldValue(`nonBillable.${indexKey}.goal`);
          return c.conditionEnum === (conditionToType[newCondition] || newCondition || conditionEnum) &&
                 ((c.goalEnum === (newGoal || goalEnum)) || // newGoal is goalEnum
                  (CLINIC_GOAL_ENUM[c.goalEnum] === newGoal)); // newGoal is value
        });
        return (
          <GoalComponentWrapper 
            key={`non-billable-${indexKey}`} 
          >
            <NonBillableGoalComponent 
              idx={indexKey}
              form={form}
              goalCondition={conditionEnum || condition}
              goalValue={goalEnum || value}
              goalStatus={goalStatus}
              isReferredGoal={isReferredGoal}
              rowClassName ={rowClassName}
              disabled={disabled} 
              conditionOptions={conditionOptions}
              goalOptions={record.goalOptions}
              goalHandlers={{
                setGoalOptions: this.setGoalOptions,
                setGoal: this.setGoal,
                removeCondition: this.removeCondition
              }}
              hideGoalStatus={!!isFirstMNTVisit}
              newAddedGoal={newAddedGoal}
            />
          </GoalComponentWrapper>
        )
      }
    })
  }

  renderReferralIndicator = () => {
    const { referredBy, clinicGoals } = this.props.patientRefer || {};
    const currentOrgId = _.get(JSON.parse(sessionStorage.getItem('currentUser')), 'selectedRole.organization.id');
    if(!referredBy || !Array.isArray(clinicGoals) || !clinicGoals.length || !currentOrgId) 
      return null;
      
    const referrerAllRoles = _.get(referredBy, 'allRoles');
    const referrerRole = _.filter(referrerAllRoles, r => _.get(r, 'organization.id') === currentOrgId)[0];

    if(!referrerRole)
      return null;

    const referrerRoleName = ROLE_MAP[referrerRole.name];
    return <div style={{ margin: '5px 0px 10px' }}>
      * Based on {referrerRoleName} referral
    </div>;
  };

  render(){
    const {disabled, isWorkList, goalLoading } = this.props;
    const enrolledProgramId = _.get(this.props, 'program.id');
    const className = isWorkList ? 'clinic-goals-worklist' : 'border-goals';

    if(goalLoading)
      return 'Loading...';
    // if(updateKey){
    //   return <Skeleton />
    // }
    return (
      <div className='clinicGoals'>
        <div className='goals-list-items'>
          Clinical Goals
          <GoalStatementTooltipComponent/>
        </div>
        <div className={className} id='clinic-goals'>
          {this.renderReferralIndicator()}
          <div className='billable-condition'>
           {this.renderBillableCondition()}
          </div>
          <div className='non-billable-condition' style={{ marginTop: '8px' }}>
           {this.renderNonBillableCondition()}
          </div>
          <Row className='add-click'>
            <Button type='link' onClick={this.addCondition} disabled={enrolledProgramId? disabled : true} id='add-click'>
              <Icon type='plus-circle'/>Add Condition</Button>
         </Row>
        </div>
      </div>

    )
  }
}

export default ClinicGoals;