import React from 'react';
import SingleAreaContainer from "./SingleAreaContainer";
const AreaContainer = class extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return <SingleAreaContainer/>
    }
}

export default AreaContainer;