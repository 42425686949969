import React from 'react';
import PropTypes from 'prop-types';

import '../styles/ReviewSummaryItemWrapperComponent.scss';

const ReviewSummaryItemWrapperComponent = (props) => {
  const {
    title,
    iconSrc,
    children,
  } = props;

  const renderTitleIcon = () => {
    if (!iconSrc) return null;
    return (
      <div className="review-summary-item-wrapper__title__icon">
        <img src={iconSrc} />
      </div>
    );
  }

  return (
    <div className="review-summary-item-wrapper">
      <div className="review-summary-item-wrapper__title">
        {renderTitleIcon()}
        <div className="review-summary-item-wrapper__title__text">
          {title}
        </div>
      </div>
      <div className="review-summary-item-wrapper__children">
        {children}
      </div>
    </div>
  );
};

ReviewSummaryItemWrapperComponent.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
};

export default ReviewSummaryItemWrapperComponent;
