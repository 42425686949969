
import React, { Component } from 'react';
import {helpers as dataHelper} from "../helper";
import helper from '../../utils/helpers';
import {Col, Row,Icon} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getProviderName } from '../../../lib/utils';
import Client from '../../../lib/gqlClient';
import { miniEnrolledProgramListForPatient } from '../../graphql/enrolledProgramList';
import { renderComplexityTag, renderSelfTag } from '../../visitsPage/helpers';
import _ from 'lodash';
import AssignCTInVisitHeader from "../../assignCT/component/AssignCTInVisitHeader";

const ENROLLED_STATUS = ['STARTED', 'CREATED'];
class VisitPersonHeader extends Component{
  constructor(props){
    super(props);

    this.state = {
      isEnrolled: null,
      enrollmentStatus: null,
      showForm:false,
    }
  }

  setShowForm=(showForm)=>{
      this.setState({showForm});
  }


  setEnrollmentStatus = () => {
    const { visitInfo, patient, editStatus } = this.props;
    const user = _.get(visitInfo, 'member', _.get(patient, 'user')) || {};
    if(_.isEmpty(user)) return;

    const shouldFetchEP = !_.get(visitInfo, 'vitalTypes') &&
                          !_.get(patient, 'enrolledPrograms');
    let isEnrolled = false;
    if(shouldFetchEP) {
      return Client.query({
        query: miniEnrolledProgramListForPatient,
        variables: {
          count: 1,
          filters: { memberId: user.id }
        }
      }).then(res => {
        const ep = _.get(res, 'data.enrolledProgramList.data.0') || {};
        isEnrolled = _.includes(ENROLLED_STATUS, ep.status);
        this.setState({ isEnrolled, enrollmentStatus: ep.status });
      }).catch(console.error);
    }
    let typeAndStatus;
    const lastMeasuredAt = _.get(user, 'profile.lastMeasuredAt');
    if (editStatus) {
      typeAndStatus = dataHelper.getTypeAndStatusFromVisit(visitInfo, lastMeasuredAt);
    } else {
      typeAndStatus = dataHelper.getTypeAndStatus(patient.enrolledPrograms, lastMeasuredAt);
    }
    isEnrolled = ['Enrolled', '2-w inactive'].includes(typeAndStatus.status);
    this.setState({ isEnrolled, enrollmentStatus: typeAndStatus.status });
  }

  componentDidMount = () => {
    this.setEnrollmentStatus();
  }

  // componentDidUpdate = (prevProps) => {
  //   const preVisitId = _.get(prevProps, 'visitInfo.id');
  //   const curVisitId = _.get(this, 'props.visitInfo.id');
  //   if(preVisitId !== curVisitId) {
  //     this.setEnrollmentStatus();
  //   }
  // };

  handleData(patient, visitInfo, editStatus){
      const data = {};
      let user = editStatus ? visitInfo.member : patient.user;
      if(this.state.isEnrolled === null) {
        data.status = '';
      } else if(this.state.isEnrolled) {
        data.status = 'Enrolled';
        const programCategories = _.get(user, 'profile.programCategories');
        const enrolled = _.filter(programCategories, p => p.enrolled);
        data.status += enrolled.length ? ` - ${_.map(enrolled, 'name').join(', ')}` : '';
      } else {
        let REstatus = '';
        const isDischarged = _.toUpper(this.state.enrollmentStatus) === 'DISCHARGED';
        if(!isDischarged) {
          const reStatus = _.get(visitInfo, 'member.remoteEnrollment.status') ||
                            _.get(patient, 'user.remoteEnrollment.status');
          REstatus = reStatus ? ` - ${_.capitalize(reStatus)}` : '';
        }
        data.status = `Unenrolled${REstatus}`;
      }
      data.fullName = user.profile.fullName;
      data.birthday = dataHelper.convertDateFormat(user.profile.birthday);
      data.age = helper.getAge(user.profile.birthday);
      const provider = _.get(visitInfo, 'member.profile.doctor', _.get(patient, 'user.profile.doctor'));
      data.doctorName = getProviderName(provider);
      if(user.profile.gender){
          data.gender = user.profile.gender === 'M' || user.profile.gender === 'Male' ? 'M' : 'F';
      }else{
          data.gender = '-';
      }
      return data;
    }

    render() {
        const {patient, clickPatient, visitInfo, fromAppointments, editStatus, isFromVisitTable, fromDetail, patientComplexity, isEnrolled,setPatient, curVisitInfo, autoFillAssignees, remoteEnrollment} = this.props;
        const data = this.handleData(patient, visitInfo, editStatus);
        const isSelfEnrolled = _.get(visitInfo, 'member.profile.isSelfEnrolled', false);
        const complexity = _.get(patientComplexity, 'level');
        const memberData = _.get(patient, 'user') || _.get(visitInfo, 'member');
        const healthConditions = _.get(memberData, 'profile.healthConditions') || [];
        // const hasHealthConditions = !!healthConditions.length;
        return (
            <div className="inputVisitPatient"  id='inputVisitPatient' >
                <UserOutlined style={{width: 32, height: 32, backgroundColor: '#717171', margin: '10px', borderRadius: '50%', alignItems: 'center', fontSize: '20px', padding: '5px', color: '#FFF'}}/>
                <Row style={{ width: '100%' }}>
                    {/* <Col span={2} style={{textAlign:'center'}}><img src="/image/person-avatar.png"
                                       width={16}
                                       height={16}/>
                    </Col> */}
                    <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <span style={{fontWeight: 'bold', color:'#1890ff',cursor: isFromVisitTable ? 'pointer':'default'}} onClick={ ()=>isFromVisitTable ? clickPatient() : null}>
                          {data.fullName}
                        </span>
                        <span>
                          {isSelfEnrolled && renderSelfTag()}
                          {complexity && renderComplexityTag(complexity, !isEnrolled)}
                        </span>
                        <span style={{fontSize: '12px'}}>{data.age} ys/{data.gender} ({data.birthday})</span>
                      </div>
                      {
                        (editStatus||isFromVisitTable ||fromDetail) ? '' :
                        <Icon
                          type="close-circle"
                          style={{ marginTop: 2 }}
                          onClick={()=>(patient || fromAppointments) ? clickPatient() : null}
                        />
                      }
                    </Col>
                    <Row>
                        Status: {data.status}
                    </Row>
                    <Row>
                        { data.doctorName ? 'Primary Provider: ' + data.doctorName : null}
                    </Row>
                    <Row>
                        {<AssignCTInVisitHeader 
                            setShowForm={this.setShowForm} 
                            patient={patient || {
                              user: _.get(visitInfo, 'member')
                            }}
                            showForm={this.state.showForm} 
                            setPatient={setPatient}
                            isEnrolled={isEnrolled} 
                            patientComplexity={patientComplexity} 
                            curVisitInfo={curVisitInfo} 
                            healthConditions={healthConditions}
                            saveCallback={data => {
                              if(autoFillAssignees && data) {
                                const assignees = dataHelper.parsePatientAssignees(data);
                                autoFillAssignees(assignees);
                              }
                            }}
                            remoteEnrollment={remoteEnrollment}
                        />}
                    </Row>
                </Row>
            </div>
        )
    }

}

export default VisitPersonHeader;