// export default {
//     'OVER_COPAY':'Concern over paying for copay',
//     'NO_SMARTPHONE':'Doesn't have smartphone',
//     'TECHNOLOGY_HINDERANCE':'Technology learning hinderance',
//     'NO_NEED_PROGRAM_MONITORING':'Patient doesn't feel they need program monitoring',
//     'NO_NEED_HOME_PROGRAM':'Patient doesn't want to do home monitoring',
//     'DROPPED': 'Patient dropped from MD care',
//     'PHONE_ISSUE':'Issue with phone or phone permissions',
//     'DISCREPANCIES_WITH_METER':'Question discrepancies with our meter',
//     'ACCURACY_OF_METER':'Question accuracy of our meter',
//     'MULTIPLE NO-SHOWS':'Multiple no-shows',
//     'DECEASED':'Deceased',
//     'USING_OTHER_DEVICE':'Using other device',
//     'OTHER':'Other'
// }
export default {
  'NO_NEED_HOME_PROGRAM':'Not willing to monitor any vitals',
  'NO_NEED_PROGRAM_MONITORING':'Stable, feels monitoring is not necessary',
  'TECHNOLOGY_HINDERANCE':'Technology is overwhelming',
  'OVER_COPAY':'Insurance concern',
  'NO_SMARTPHONE':`Doesn't have smartphone`,
  'DROPPED': 'Patient dropped from MD care',
  'PHONE_ISSUE':'Issue with phone or phone permissions',
  'DISCREPANCIES_WITH_METER':'Question discrepancies with our devices',
  'ACCURACY_OF_METER':'Question accuracy of our devices',
  'MULTIPLE NO-SHOWS':'Long period of inactivity',
  'USING_OTHER_DEVICE':'Using other device',
  'SWITCHED_ORG':'Switched to another organization',
  'MONITORING_CONCERN': 'Concern of being monitored',
  'BEHAVIORAL_ISSUE': 'Behavioral issue',
  'ELDERLY_DEMENTIA': 'Elderly/Dementia',
  'VITALS_ALREADY_TRACKED': 'Vitals already being tracked',
  'NO_NUTRITION_INTERVENTION': 'Doesn’t want nutrition intervention',
  'LOST_INSURANCE_COVERAGE': 'Lost insurance coverage',
  'CHGD_TO_NON_IHEALTH_PROVIDER': 'Changed to non-iHealth provider',
  'ENROLLED_BY_MISTAKE': 'Enrolled by mistake',
  'DECEASED':'Deceased',
  'REFUSED':'Patient refuses to sign consent',
  'OTHER':'Multiple/Other - Please type details'
}
