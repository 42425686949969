import { constants } from 'libModule/utils'

export default constants('dashboard', 'main', [
  'SET_SELECTALERT_LIST',
  'SET_CURRENT_PROGRAM',
  'SET_CURRENT_PEROID',
  'RESET',
  'SET_SELECTED_TAB_KEY',
  'SET_SELECTED_RANGE',
  'SET_SELECTED_FILTERS',
  'SET_SELECTED_SORTERS',
  'SET_SELECTED_PAGEINFO'
])
