import React from 'react';
import { Row, Col, Button, Select, message, Tooltip, Icon, Divider} from 'antd';
import { upsertUserRolesReturnID } from 'modulesAll/graphql/mutation/upsertUserRoles';
import { updateCoveredClinics } from 'modulesAll/graphql/mutation/editUser';
import Client from 'libModule/gqlClient';
import { createAuditLog } from 'libModule/utils/auditLog'
import { IHPanel } from '../../../../../../package/IHComponent';
import { decryptRole } from 'libModule/utils';
import roleMap from "../constants/roleMap";
import { API } from '../../../chat/actions/api';  
import { IHEALTH_MEMBER_ROLES } from '../../../utils/constants/role'; 

export default function MyClinicComponent({ containerInstance }) {
  const { isClinicEditable, userId, userClinics, prevSelectedClinic, selectedClinic, coveredClinics,isCoveredClinicsEditable } = containerInstance.state;
  const saveClinic = async e => {
    e.preventDefault();
    if(isClinicEditable && prevSelectedClinic !== selectedClinic){ 
      // don't need to update default clinic if selected the same one
      try{ 
        await Client.mutate({
          mutation: upsertUserRolesReturnID,
          variables: {
            id: userId,
            defaultRole: selectedClinic
          }
        });
        // update previous selected clinic to new one
        containerInstance.setSelectedClinic({ prevSelectedClinic: selectedClinic });
        containerInstance.setDefaultRole(selectedClinic);
        message.success('Default Clinic saved successfully. Changes will apply next login');
      } catch (e) {
        // revert / display previous selected clinic
        containerInstance.setSelectedClinic({ selectedClinic: prevSelectedClinic });
        console.log(e);
        return message.error('Failed to save Default Clinic');
      }
    }
    containerInstance.setClinicEditable(!isClinicEditable);
  }

  const saveCoveredClinic = async e => {
    e.preventDefault();
    const variables = {
      id: userId,
      userSettings: {
        listeningOrgs : coveredClinics
      },
    }
    
    //check if there are any changes
    let update = true;
    if (JSON.stringify(containerInstance.getSessionCover()) === JSON.stringify(coveredClinics)){
      update = false;
    }
    if(isCoveredClinicsEditable && update){ 
      // console.log(coveredClinics);
      // update if it is editable
      try{ 
        await Client.mutate({
          mutation: updateCoveredClinics,
          variables
        });
        message.success('Covered clinics set successfully');
        
        API.getUserProfile(userId)
        .then( res => {
          let covered = res.data.user.userSettings.listeningOrgs;
          containerInstance.setSessionCover(covered);
        })
        .catch( err => console.log('Error fetching user listening Orgs: ', err));

      } catch (e) {
        console.log(e);
        return message.error('Failed to save Covered Clinics');
      }
    }
    containerInstance.setCoveredClinicsEditable(!isCoveredClinicsEditable);
  }

  const info = 'Covered clinic(s) means the clinic(s) for which you want to receive incoming calls';
  const defaultBtnText = isClinicEditable ? 'Save' : 'Edit';
  const defaultBtnType = isClinicEditable ? 'primary' : 'default';
  const coveredBtnText = isCoveredClinicsEditable ? 'Save' : 'Edit';
  const coveredBtnType = isCoveredClinicsEditable ? 'primary' : 'default';
  const currentUserRole = decryptRole();
  const currentRole = currentUserRole.split(':')[1];
  //check if this is iHealth members
  const isIHealthMember = IHEALTH_MEMBER_ROLES.includes(roleMap[currentRole]);

  //get the body Elem based on roles
  const bodyElem = isIHealthMember ? (
    <Row>
      <Col span={21}>My Default Clinic</Col>
      <Col style={{ marginTop: -5, float: 'right' }}>
        <Button 
          type={defaultBtnType}
          size='small'
          onClick={saveClinic}
        >
          {defaultBtnText}
        </Button>
      </Col>
      <Col span={24}>
        <Select 
          type='large'
          style={{ width: '100%', marginTop: 10 }} 
          value={selectedClinic}  // first one in role list is always the default
          onChange={e => containerInstance.setSelectedClinic({ selectedClinic: e })}
          disabled={!isClinicEditable} 
        >
          {
            _.map(userClinics, clinic => 
              <Select.Option key={clinic.key} value={clinic.refId}>{clinic.internalName || clinic.name}</Select.Option>
            )
          }
        </Select>
      </Col>
      <Divider></Divider>
      <Col span={20}>My Covered Default Clinic(s)
      <Tooltip title={info}><Icon type="info-circle" /></Tooltip>
      </Col>
      <Col style={{ marginTop: -5, float: 'right' }}>
        <Button 
          type={coveredBtnType}
          size='small'
          onClick={saveCoveredClinic}
        >
          {coveredBtnText}
        </Button>
      </Col>
      <Col span={24}>
        <Select 
          mode='multiple'
          type='large'
          style={{ width: '100%', marginTop: 10 }}
          value={coveredClinics}
        //   value={selectedClinic}  // first one in role list is always the default
          onChange={e => containerInstance.setCoveredClinics({coveredClinics: e})}
          disabled={!isCoveredClinicsEditable}
        >
          {
            _.map(userClinics, clinic => 
              <Select.Option key={clinic.key} value={clinic.key}>{clinic.internalName || clinic.name}</Select.Option>
            )
          }
        </Select>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col span={21}>My Default Clinic</Col>
      <Col style={{ marginTop: -5, float: 'right' }}>
        <Button 
          type={defaultBtnType}
          size='small'
          onClick={saveClinic}
        >
          {defaultBtnText}
        </Button>
      </Col>
      <Col span={24}>
        <Select 
          type='large'
          style={{ width: '100%', marginTop: 10 }} 
          value={selectedClinic}  // first one in role list is always the default
          onChange={e => containerInstance.setSelectedClinic({ selectedClinic: e })}
          disabled={!isClinicEditable} 
        >
          {
            _.map(userClinics, clinic => 
              <Select.Option key={clinic.key} value={clinic.refId}>{clinic.internalName || clinic.name}</Select.Option>
            )
          }
        </Select>
      </Col>
    </Row>
  )
  return (
    <div className='settings-inner'>
      <div className='change-password-title'>My Clinic</div>
      <div className='clinic-container'>
        <IHPanel body={bodyElem}/>
      </div>
    </div>
  )
}
