import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

import '../styles/ReviewPopupHeaderComponent.scss';

const ReviewPopupHeaderContainer = ({
  count = 0,
  onClickNext,
  isLoadingNext,
}) => {
  const handleClickNext = () => {
    if (isLoadingNext) return;
    onClickNext();
  };

  const disableNextBtn = useMemo(() => {
    return !!isLoadingNext || count < 2;
  }, [isLoadingNext, count]);

  const tooltipTitle = useMemo(() => {
    if (count === 1) return 'This is the last patient to review';
    // hide tooltip
    return '';
  }, [count]);

  return (
    <div className="review-popup-header-component">
      <div className="review-popup-header-component__header">
        <div className="review-popup-header-component__header__title">
          RPM patients need provider&apos;s review ({count})
        </div>
        <div className="review-popup-header-component__header__sub-title">
          Please review these patients&apos; care plan goals with a provider
        </div>
      </div>
      <div className="review-popup-header-component__navigation">
        <Tooltip 
          title={tooltipTitle}
        >
          <Button 
            type="link"
            className="review-popup-header-component__navigation__next"
            onClick={handleClickNext}
            disabled={disableNextBtn}
          >
            Next Patient &gt;
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

ReviewPopupHeaderContainer.propTypes = {
  count: PropTypes.number,
  onClickNext: PropTypes.func,
};

export default ReviewPopupHeaderContainer;