import React from 'react';
import { Button, Icon } from 'antd';

const divStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA'
}
const EmptyChannelComponent  = props => {
    return !props.error ?
        <div style={divStyle}>
            There are no messages on this channel.
            <br/><br/>
            Please double check this patient’s profile. 
            <br/>
            If you think this is an error, please report this issue.
        </div>
        :
        <div style={{ ...divStyle, flexDirection: 'column', flexWrap: 'wrap', padding: 0, textAlign: 'center'}}>
          <Icon type="warning" theme="twoTone" style={{ fontSize: '3em', marginBottom: 5 }} /><br/>
          <span>Failed to load {props.error}</span>
          <Button size='small' style={{ margin: '10px 0' }} onClick={props.retryLoading}>Retry</Button>
          <span>If it is consistent, please report this issue</span>
        </div>
}

export default EmptyChannelComponent;
