import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import registerCaregiverForMember from 'graphqlModule/registerCaregiverForMember'
import { IHForm, IHButton, message, IHLoading, _ } from 'ihcomponent'
import { getAddCareGiverFormProps } from 'patientModule/profileEdit/components/formData/PatientAddCareGiverForm'
import { GQLHelper, validateAntdForm, submittingStyle } from 'libModule/utils'
import { closeModal, openErrorModal } from 'modulesAll/layout/actions/MainModal'
import actions from 'patientModule/profileEdit/actions'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N'

class Container extends Component {
  static displayName = 'patient/profileEdit/containers/PatientAddCareGiverContainer'

  init() {
    const { userData } = this.props;
    const caregivers = [];
    _.each(userData.caregiver, (careGiver, index) => {
      const { firstName, lastName } = _.get(careGiver, 'profile');
      const n = index + 1;
      caregivers[index] = {};
      caregivers[index]['c_firstName_' + n] = firstName;
      caregivers[index]['c_lastName_' + n] = lastName;
      caregivers[index]['c_nric_' + n] = _.get(careGiver.identification[0], 'value');
      caregivers[index]['c_mobilePhone_' + n] = (_.get(careGiver.phones[0], 'number') || '');
      caregivers[index]['c_email_' + n] = _.get(careGiver.emails[0], 'address') || '';
      caregivers[index]['c_nationality_' + n] = (_.get(careGiver.identification[0], 'type.code') || '');
      caregivers[index]['c_nationality_' + n] = (_.get(careGiver.identification[0], 'type.code') || '');
    });

    return caregivers;
  }

  render() {
    const body = this.renderBody();
    const footer = this.renderFooter();

    return (
      <div className='vsm-form-container'>
        {body}
        {footer}
      </div>
    )
  }

  renderBody() {
    const caregivers = this.init();
    const index = caregivers.length;
    const { updateCgForm } = this.props;

    const onFieldsChange = (fieldsProps, fields) => {
      updateCgForm(fieldsProps, fields)
    };

    const caregiverProps = getAddCareGiverFormProps({ onFieldsChange, index });

    const body = <IHForm {...caregiverProps} ref={refNode => { this.baseForm = refNode }} />;
    return body;
  }

  renderFooter() {
    const { closeModal, isSubmitting } = this.props;
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 10
      }
    };

    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          closeModal()
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e) => {
          this.submit()
        },
        bsStyle: 'primary',
        type: 'submit',
        disabled: isSubmitting,
        style: {
          marginRight: 10,
          ...submittingStyle(isSubmitting)
        }
      }
    };

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done}>
          {isSubmitting ? <IHLoading /> : 'Save'}
        </IHButton>
        <IHButton {...buttonProps.cancel} />
      </div>
    );
    return footer
  }

  getMutateVariable(data, index) {
    index += 1;
    const careGiver = {
      profile: {
        firstName: data[`c_firstName_${index}`],
        lastName: data[`c_lastName_${index}`],
        nationality: !!data[`c_nationality_${index}`] ? data[`c_nationality_${index}`] : 'US',
        relationship: !!data[`c_relationship_${index}`] ? data[`c_relationship_${index}`] : 'CHILD'
      },
      identification: {
        type: 'SP',
        value: data[`c_nric_${index}`]
      }
    };

    if (!!data[`c_mobilePhone_${index}`]) {
      careGiver.phone = {
        countryCode : '+1',
        number : data[`c_mobilePhone_${index}`],
        type : 'MOBILE'
      }
    }

    if (!!data[`c_email_${index}`]) {
      careGiver.email = data[`c_email_${index}`]
    }

    return careGiver
  }

  async submit() {
    const caregivers = this.init();
    const index = caregivers.length;
    const formRef = this.baseForm;
    const isFormValid = await validateAntdForm(formRef);
    if (!isFormValid) return;

    const { mutate, closeModal, openErrorModal, setSubmitting, userData, refetch } = this.props;
    const fieldsValue = formRef.getValue();
    const caregiver = this.getMutateVariable(fieldsValue, index);

    const id = userData.id;
    const variables = { id, caregiver };

    const patientNRIC = _.get(userData, 'identification[0].value');
    const patientName = _.get(userData, 'profile.fullName');

    try {
      setSubmitting(true);
      const res = await this.props.registerCaregiverForMember({ variables });
      if (res.data.registerCaregiverForMember) {
        createAuditLog({ action: I18N.get('auditLog.provider.patient.addCareGiver'), patientNRIC, patientName, details: variables, res });
        closeModal();
        message.success('You have successfully added the Caregiver');
        refetch();
      }
    } catch(e){
      createAuditLog({ action: I18N.get('auditLog.provider.patient.addCareGiver'), patientNRIC, patientName, details: variables, res: e, success: false })
      openErrorModal(GQLHelper.formatError(e))
    } finally {
      setSubmitting(false)
    }
  }
}

// data from apollo
const withData = graphql(registerCaregiverForMember, {name: 'registerCaregiverForMember'});

// connect apollo data with container
const ContainerWithData = withData(Container);

const mapState = (state, ownProps) => {
  return {
    ...state.patient.profileEdit,
  }
};

const mapDispatch = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setSubmitting: (isSubmitting) => dispatch(actions.setSubmitting(isSubmitting)),
    updateCgForm: (fieldsProps, changedFields) => dispatch(actions.updateCgForm(fieldsProps, changedFields)),
  }
};

Container.propTypes = {
  userData: PropTypes.object,
  mutate: PropTypes.func,
  closeModal: PropTypes.func,
  openErrorModal: PropTypes.func,
  isSubmitting: PropTypes.bool,
  careGiverIndex: PropTypes.number,
  updateCgForm: PropTypes.func
};

export default connect(mapState, mapDispatch)(ContainerWithData)
