const timeSpentType = {
    'curMonth':{
        'title':'Current Month',
        'defaultValue':'--'
    },
    'preMonth':{
        'title':'Previous Month',
        'defaultValue':'--'
    },
    'average':{
        'title':'Monthly Average',
        'defaultValue':'--'
    }

}

export default timeSpentType;
