import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo'
import find from 'lodash/find'
import moment from 'moment'
import { i18nPanel, i18nRadioButton } from 'libModule/helpers/component-helpers'

//files
import VitalList from '../components/VitalListComponent';
import actions from '../actions/VitalListBGActions';
const { changeRange, changeView, onTableChange, changeMode, openPatientVitalModal } = actions;
import { changeRange as changeRangeWrapper } from '../actions/VitalListWrapperActions'

//components
import { IHRadio, IHPanel, VSMDateRange } from 'ihcomponent'
import BGChartComponent from '../components/BGChartComponent'

//queries
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';

const style = {
  panel: {
    margin : '20px',
    borderRadius: '4px'
  }
}
class BGWrapper extends React.Component {
  static displayName = 'patientView/vitalList/containers/BGContainer'

  // componentDidUpdate(prevProps, prevState) {
  //   if((prevProps.currentPage !== this.props.currentPage) || (prevProps.sorter !== this.props.sorter)) {
  //     const { sorter, pagination, currentPage } = this.props
  //     const count = get(pagination, 'pageSize')
  //     this.props.refetch && this.props.refetch({sort: sorter, count, currentPage})
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.enrolledProgramId !== this.props.enrolledProgramId) ||
      (nextProps.BG !== this.props.BG) &&
      this.props.BG &&
      this.props.enrolledProgramId) {
      this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
      this.props.changeMode('Detailed')
      this.props.changeView('List')
    }
  }
  componentWillUnmount() {
    this.props.onTableChange({pageSize: 5, current: 1}, {}, { columnKey: 'date', order: null })
    this.props.changeMode('Detailed')
    this.props.changeView('List')
  }
  _generateData(data, range) {
    if (data === void 0) // when loading, data is undefined
      return []
    const start = moment(range[0]).startOf('day').toDate()
    const end = moment(range[1]).endOf('day').toDate()
    return data.data.filter(d => (moment(d.date).toDate() >= start && moment(d.date).toDate() <= end))
  }
  _getThresholds(thresholds) {
    const thresholds_beforeMeal = find(thresholds, { measure: 'BEFORE_MEAL'}).range
    const thresholds_afterMeal = find(thresholds, { measure: 'AFTER_MEAL'}).range
    const result = {
      before: [thresholds_beforeMeal[1], thresholds_beforeMeal[2]],
      after: [thresholds_afterMeal[1], thresholds_afterMeal[2]]
    }
    return result
  }
  _renderChartSwitch() {
    if (this.props.view === 'Chart') {
      const radioProps = {
        type: 'radioButton',
        value: this.props.mode,
        option: [ {name: 'Detailed', value: 'Detailed'}, {name: 'Aggregate', value: 'Aggregate'}],
        className: 'vitals-radio-btn',
        onChange: (e) => {
          this.props.changeMode(e.target.value)
        }
      }
      return (
        <div style={{display: 'inline-block'}}>
          <IHRadio {...i18nRadioButton(radioProps, 'vitalsPanelAndButtons')}/>
        </div>)
    }
    return null
  }
  render() {
    // console.log(this.props)
    const radioProps = {
      type: 'radioButton',
      value: this.props.view,
      option: [ {name:'List', value:'List'}, {name:'Chart', value:'Chart'}],
      className: 'vitals-radio-btn',
      onChange: (e) => {this.props.changeView(e.target.value)}
    }
    const bgData = this._generateData(this.props.vitalList, this.props.range)
    const listProps = {
      ...this.props,
      data: bgData,
      type: 'BG',
      title: 'Blood Glucose'
    }
    const p = {
        header : {
            title: 'Blood Glucose',
            right: (
            <div>
              {this._renderChartSwitch.bind(this)()}
              <div style={{display: 'inline-block', margin: '0 20px'}}>
                <IHRadio {...i18nRadioButton(radioProps, 'vitalsPanelAndButtons')}/>
              </div>
              <div style={{display: 'inline-block'}}>
                <VSMDateRange value={this.props.range}
                  onChange={(v) => {
                    this.props.changeRange([moment(v[0]), moment(v[1])])
                    this.props.changeRangeWrapper(null)
                  }
                }/>
              </div>
            </div>)
        },
        body: (this.props.view === 'List' || bgData.length === 0 || this.props.loading)?
          (<VitalList {...listProps} />) :
          (<BGChartComponent data={bgData}
                             range={this.props.range}
                             mode={this.props.mode}
                             thresholds_beforeMeal={this._getThresholds(this.props.BG_thresholds).before}
                             thresholds_afterMeal={this._getThresholds(this.props.BG_thresholds).after}
          />)
    }
    return (
      <div style={style.panel} className="vital-list-wrapper">
        <IHPanel {...i18nPanel(p, 'vitalsPanelAndButtons')} />
      </div>
    )
  }
}


const withData = graphql( resultList, {
  options: (ownProps) => {
    // console.log(ownProps.enrolledProgramId, ownProps.BG)
    return {
      variables: {
        "filters": {
          "enrolledProgramId": ownProps.enrolledProgramId,
          "type": "BG"
        },
        "count": 999999, // retrieve all at once; intended behavior
        "sort": {field: 'DATE', direction: 'DESC'}
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ownProps, data}) => {
    const { loading, refetch, resultList } = data
    return {
      vitalList: resultList,
      loading,
      refetch
    }
  }
})

const BGWrapperWithData = withData(BGWrapper)

const mapState = (state, ownProps) => {
  const { BG: { sorter, pagination, view, range, mode } } = state.VitalList
  // const { loading, refetch, programList } = ownProps.data || {}
  return {
    sorter,
    pagination,
    view,
    range,
    mode
  }
}

// const mapDispatchObj = {
//   ...actions,
//   dispatch: (dispatch) => dispatch,
// }
BGWrapper.propTypes = {
  sorter: PropTypes.object,
  pagination: PropTypes.object,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  view: PropTypes.string,
  changeView: PropTypes.func,
  range: PropTypes.any,
  changeRange: PropTypes.func,
  mode: PropTypes.string,
  changeMode: PropTypes.func,
  onTableChange: PropTypes.func
}

export default connect(
  mapState, { changeView, changeRange, changeRangeWrapper, changeMode, onTableChange, openPatientVitalModal }
)(BGWrapperWithData)
