import {
  calculateTotalTime,
  getUniqueInteractions,
  sendClaimedTimeReq,
} from '../../timerClaimed/helpers';
import { EVENTS } from '../../idleTime/constant';
import { getMonthOfYear } from '../helpers';

export const handleSubmitDuplicateBillableTime = _.debounce(async (submittedBy, timerInfo, next) => {
  if (!submittedBy || !timerInfo) {
    console.warn('Warn: No patient id or submitted by or timerInfo');
    return;
  }

  const cb = (error) => {
    if (next) next(error);
  }

  const {
    patientId,
    startTime,
    stopTime,
    allEvents,
  } = timerInfo;

  allEvents.push({ event: 'unmount', timestamp: stopTime, action: 'STOP_TIMER' })
  const nonIdleTime = calculateTotalTime(allEvents);
  const eventTypes = getUniqueInteractions(timerInfo);
  const totalTime = stopTime - startTime;

  if (!patientId || totalTime === 0 || !eventTypes || eventTypes.length === 0) {
    cb();
    return;
  }

  const idleTime = Math.round((totalTime - nonIdleTime) / 1000);

  try {
    await sendClaimedTimeReq({
      memberId: patientId,
      providerId: submittedBy,
      events: eventTypes.map((v) => EVENTS[v] ? EVENTS[v].text : v).map(v => ({ name: v })),
      totalTime: nonIdleTime,
      startTime,
      endTime: stopTime,
      idleTime: idleTime
    });
    cb();
  } catch (error) {
    cb(error);
  }
}, 500);
