import { max } from 'lodash';

export const findMaxWithStep = (
  arr, 
  step, 
  defaultMax,
) => {
  const maxNumber = max(arr);
  if (maxNumber !== undefined) {
    return maxNumber + (step - (maxNumber % step));
  }
  return defaultMax;
};
