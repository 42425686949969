
import {
  React, ND,
} from '../IHComponents/util';

import * as R from 'ramda';

import FaMoon from 'react-icons/lib/fa/moon-o';
import FaBed from 'react-icons/lib/fa/bed';

import { WeeklyMealScheduleInputGrid } from './WeeklyMealScheduleInputGrid';

import { emptyWeeklyScheduleData } from './emptyWeeklyScheduleData';

const { Tabs, Table, Icon, Row, Col, Dropdown, Menu, Button, 
  Modal, Input, Form, Popconfirm, message,
} = ND;

const FormItem = Form.Item;

const style = {
  flexHorizontal: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
  flexVertical: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    color: 'rgba(0,0,0,.65)',
    fontWeight: 100,
  },
  menuItem: { fontSize: 15, fontWeight: 600, lineHeight: 2.5 },
};

const mkMenu = (arr, onMenuItemClick, api) => (
  <Menu>
    {
      arr.map((t) => (
        <Menu.Item key={t._id}>
          <div onClick={()=>{onMenuItemClick(t)}} style={{ display: 'flex', alignItems: 'center' }} >
            <Popconfirm 
              title={`Are you sure delete template ${t.name}?`} okText="Yes" cancelText="No"
              onConfirm={() => {
                api.remove(t._id).then((r)=>{
                  console.log('Template ' + t.name + ' deleted', r);
                  message.success('Template ' + t.name + ' deleted' + JSON.stringify(r), 3);
                }).then(api.refetch).catch((e)=>{
                  console.log('Error deleting template ' + t.name, e);
                  message.error('Error deleting template ' + t.name + JSON.stringify(e), 3)
                })
              }}>
              <Icon type="close-circle" style={{ marginRight: 10, fontSize: 15 }} />
            </Popconfirm>
            <div style={style.menuItem} >{t.name}</div>
          </div>
        </Menu.Item>
      ))
    }
  </Menu>
);

class WeeklyMealScheduleEditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      okPressed: false,
      buttonLoading: false,
      recordId: null,
    };
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }
  onOk = () => {
    if(this.props.visibleModal === 'edit' && !this.state.recordId) {
        message.error('Select a template to edit first');
        return;
    };

    this.setState({
      okPressed: true,
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      };

      this.setState({
        buttonLoading: true,
      });
 
      const values2 = this.props.visibleModal === 'edit' ? R.merge(values , { _id: this.state.recordId }) : values;

      const submitFunction = (this.props.visibleModal === 'edit') ? this.props.weeklyBGScheduleTemplateAPI.update : this.props.weeklyBGScheduleTemplateAPI.create;

      submitFunction(values2)
      .then((res)=>{
        console.log('update resolved', res);
        return this.props.weeklyBGScheduleTemplateAPI.refetch();
      }).then(
        (r)=>{
          console.log('promise resolve', r);
          this.setState({
            buttonLoading: false,
          });
  
          this.props.form.resetFields();
          this.props.closeModal();
        }
      ).then(() => this.props.updateParentForm(values2))
      .catch((e)=>{
        // console.log('promise reject', R.keys(e), R.values(e));
        message.error(e.message, 5);
        this.setState({
          buttonLoading: false,
        });
      })
    });
  }

  onCancel = () => {
    this.props.form.resetFields();
    this.props.closeModal();
  }

  render() {
    const { visibleModal, templates, onSave, form } = this.props;
    const { getFieldValue, getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const nameError = (isFieldTouched('name') || this.state.okPressed) && getFieldError('name');
    const descriptionError = (isFieldTouched('description') || this.state.okPressed) && getFieldError('description');

    const onMenuItemClick = (t) => {
      this.setState({ recordId: t._id }); 
      form.setFieldsValue(R.pick(['name', 'description', 'schedule'], t));
    };

    const settings = {
      title: "Edit Weekly Meal Schedule Template",
      templates: null,
    };
    const settings2 = {
      title: "New Weekly Meal Schedule Template",
      templates: null,
    };
 
    const title = (visibleModal === 'edit' ? 'Edit ' : 'New ') + 'Weekly Meal Schedule Template';

    const selectedName = getFieldValue('name');
    return (
      <Modal
        title={title}
        visible={visibleModal !== 'none'}
        onOk={this.onOk}
        onCancel={this.onCancel}
        width={1016}
        className='weeklymeal-schedule'
        confirmLoading={this.state.buttonLoading}
      >
        <Form onSubmit={this.handleSubmit}> <Row>
            <Col span={13} style={R.mergeAll([{ paddingRight: 30, height: 449, display: 'flex', flexDirection: 'column' }])} >
              { visibleModal !== 'edit' ? null :
                  <div style={{ marginBottom: 24 }} >
                    <Dropdown overlay={mkMenu(templates, onMenuItemClick, this.props.weeklyBGScheduleTemplateAPI)}>
                      <Button size='large' style={R.merge({ height: 40, width: '100%' }, style.flexHorizontal )} >
                        { selectedName ? selectedName : 'Select Weekly Meal Schedule Template to edit' }
                        <Icon type="up" />
                      </Button>
                    </Dropdown>
                  </div>
              }
              <Row>
                Template Name *
                <FormItem
                  validateStatus={nameError ? 'error' : ''}
                  help={nameError || ''}
                >
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please provide a name!' }],
                  })(
                    <Input size="large" placeholder="Template name" />
                  )}
                </FormItem>
              </Row>
              <Row style={{ marginTop: 14 }} >
                Template Description *
                <FormItem
                    validateStatus={descriptionError ? 'error' : ''}
                    help={descriptionError || ''}
                >
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Please add a description!' }],
                  })(
                    <Input.TextArea type="textarea" placeholder="Template description" style={{ resize: 'none', width: '100%', borderColor: '#d9d9d9', borderRadius: 4 }} rows={4}/>
                  )}
                </FormItem>
              </Row>
  
              <Row style={{ marginTop: 'auto', justifyContent: 'center' }} >
                <hr style={{ width: '100%', height: 15, color: '#c5c5c5' }} />
              </Row>
              <Row style={ R.merge({ fontSize: 13 }, style.text) } >
                <Col span={4} offset={15}>
                  <div>B = Before</div>
                  <div>A = After</div>
                </Col>
                <Col span={5} offset={0} >
                  <div><FaBed /> = Bedtime</div>
                  <div><FaMoon /> = Overnight</div>
                </Col>
            </Row>
            </Col>

            <Col span={11} style={ style.flexVertical } >
              <Row style={{ minWidth: 405, marginTop: visibleModal === 'new' ? 18 : 0 }} >
                <FormItem>
                { getFieldDecorator('schedule', { initialValue: emptyWeeklyScheduleData.schedule } )(<WeeklyMealScheduleInputGrid allowEdit />)
                }
                </FormItem>
              </Row>
              <div style={{ marginTop: 24, width: 400 }} >
                Click on the time slots above to select.
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
};

export const WeeklyMealScheduleEditor = Form.create()(WeeklyMealScheduleEditorComponent);
