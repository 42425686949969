import { startDateValidator, endDateValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import moment from 'moment'
import { mapPropsToFields } from 'libModule/utils'

export const schema = {
  title: {
    initialValue: 'Scheduled Maintenance',
    required: true
  },
  title_text: {
    initialValue: '',
    required: true
  },
  message: {
    initialValue: '',
    required: true
  },
  startTime: {
    initialValue: moment(),
    required: true
  },
  endTime: {
    initialValue: moment(),
    required: true
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange }) => {

  const itemList = [
    [{
      key: 'title',
      label: 'Broadcast Title',
      type: 'select',
      option: [
        {
          name : ' Scheduled Maintenance',
          value : 'Scheduled Maintenance',
          key : 'scheduled'
        },
        {
          name : ' Unscheduled Maintenance',
          value : 'Unscheduled Maintenance',
          key : 'unscheduled'
        },
        {
          name : 'Others',
          value : 'others',
          key : 'others'
        }
      ],
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      placeholder: 'Enter title of broadcast',
      initialValue: initData['title'],
      rules : [
        {required : isFieldsRequired.title, message : 'Title is required'},
      ],
    }],
    [{
      key: 'title_text',
      label: '',
      type: 'text',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      placeholder: 'Enter custom title for broadcast',
      initialValue: initData['title_text'],
      rules : [
        {required : isFieldsRequired.title_text, message : 'Title is required'}
      ],
    }],
    [{
      key: 'message',
      label: 'Broadcast Message',
      type: 'textarea',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      style : {height : '100px'},
      placeholder: 'Enter message to broadcast',
      initialValue: initData['message'],
      rules : [
        {required : isFieldsRequired.message, message : 'Message is required'},
      ],
    }],
    [{
      key: 'text',
      label: 'Show this broadcast message, between these dates',
      type: 'label',
      wrapperCol: { span:'no-display' }
    }],
    [{
      key : 'startTime',
      type : 'date',
      label : 'From',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      placeholder: 'MMM-DD-YYYY',
      initialValue: initData['startTime'],
      col: { span: 12, offset: 0 },
      format: 'MMM-DD-YYYY hh:mm a',
      showTime : {format: 'hh:mm a'},
      rules : [
        {required : isFieldsRequired.startDate, message : 'Start Date and Time is required'},
        { validator: (rule, value, callback) => { return callback(startDateValidator(value)) }}
      ],
    },
    {
      key: 'endTime',
      type: 'date',
      label : 'To',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      col: { span: 12, offset: 0 },
      placeholder: 'MMM-DD-YYYY',
      initialValue: initData['endTime'],
      format: 'MMM-DD-YYYY hh:mm a',
      showTime : {format: 'hh:mm a'},
      rules : [
        {required : isFieldsRequired.endDate, message : 'End Date and Time is required'},
        { validator: (rule, value, callback) => callback(endDateValidator(value)) }
      ],
    }]
  ]

  if (fieldsValue.title.value !== 'others') {
    delete itemList[1]
  }

  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    itemList,
    mapPropsToFields
  }
}
