import React, { useState, useMemo, useEffect } from 'react';
import { Spin, Modal } from 'antd';
import StepModal from '../../stepModal/StepModal';
import '../style/index.scss';
import { getReferralOrEnrollMap } from '../constant/formMap';
import { compose } from 'react-apollo';
import moment from 'moment';
import { getUserRole } from '../../../lib/utils';
import { getPatientReferral, getUserData, getDischargedPatientReferral, getRemoteEnrollmentData } from '../API/query';
import ExcludedOverlay from '../component/ExcludedOverlay';
import { upsertPatientReferralMutation } from '../API/mutation';
import { STATUS_ENUM } from '../constant';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { connect } from 'react-redux';
import usePrevious from '../../../lib/hooks/usePrevious';
import ReferAndEnrollForm from '../component/ReferAndEnrollForm';
import { makePatientReferral } from '../helper/reReferAndEnroll';
import { doesOrgHaveValueBased } from "../../../lib/utils/index";

const FormWrapper = (props) => {
  const { 
    editConfirm, 
    setEditConfirm, 
    setShouldUpdateCompletedReferStatus, 
    hasChange, 
    children, 
    onSubmit,
    ...formProps 
  } = props;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(editConfirm && hasChange) {
      return Modal.confirm({
        title: 'You\'re about to edit a completed referral. Are you sure?',
        okText: 'Yes',
        onOk: () => {
          setEditConfirm(false);
          setShouldUpdateCompletedReferStatus(true);
          if(onSubmit) 
            onSubmit(e);
        }
      });
    } else if (onSubmit) {
      onSubmit(e);
    }
  };

  return (<ReferAndEnrollForm
    {...formProps}
    onSubmit={handleFormSubmit}
  >
    {children}
  </ReferAndEnrollForm>);
};

const Step = StepModal.Step;

const filterVisibility = (patientReferral, isScreened) => (v) => {
	if (v.isVisible === undefined) {
		return true;
	}
	if (typeof v.isVisible === 'function') {
		return v.isVisible(patientReferral, isScreened)
	}
	return v.isVisible;
}

const SUCCESS_PAGE_KEY = ['referredSuccess', 'enrolledSuccess']

const ReferAndEnrollModal = (props) => {
	const { visible, setShowModal, userData, type, patientReferralLoading, refetchQuery, refetchUser, openErrorModal, patientComplexity, dischargedPatientReferralLoading, dischargedPatientReferral, loadingRE, remoteEnrollment, refetchRE } = props;
  const refetchPatientReferral = _.get(props, 'getPatientReferralRefetch');

	if (!visible) return null;
  
  const patientReferral = makePatientReferral(props.patientReferral, dischargedPatientReferral);
  _.set(userData, 'user.remoteEnrollment', remoteEnrollment);
	const userRole = getUserRole();
	const [showExcludedOverlay, setShowExcludedOverlay] = useState(true);
	const isScreened = _.get(userData, 'user.remoteEnrollment.status') === 'INIT';
  const [initialReferStatus, setInitialReferStatus] = useState(undefined);
	const isReferred = _.get(patientReferral, 'status.referStatus') === STATUS_ENUM.COMPLETED;
	const isRDorCA = ['CA', 'RD'].includes(userRole);
	const prevPatientReferral = usePrevious(patientReferral);
	const [isEnrolledOnMount, setIsEnrolledOnMount] = useState();
  const [editConfirm, setEditConfirm] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [shouldUpdateCompletedReferStatus, setShouldUpdateCompletedReferStatus] = useState(!isReferred);
  

	useEffect(() => {
		if (_.isEmpty(prevPatientReferral) && !_.isEmpty(patientReferral)) {
			const isEnrolled = _.get(patientReferral, 'status.enrollStatus') === STATUS_ENUM.COMPLETED;
			setIsEnrolledOnMount(isEnrolled);
		}
	}, [prevPatientReferral, patientReferral]);

  useEffect(() => {
    if(!patientReferralLoading && typeof initialReferStatus === 'undefined') {
      const referStatus = _.get(patientReferral, 'status.referStatus');
      setInitialReferStatus(referStatus);
      setEditConfirm(referStatus === STATUS_ENUM.COMPLETED);
      setShouldUpdateCompletedReferStatus(referStatus !== STATUS_ENUM.COMPLETED);
    }
  }, [patientReferralLoading]);

	const closeModal = () => {
		// if(refetchQuery) refetchQuery();
		setShowModal(false);
		if(refetchQuery) refetchQuery();

	}

  const isEnrolledSuccess = (stepObj) => {
    if(stepObj.key === 'enrolledSuccess') {
      // use status of step before as indicator for this step status
      // since enrollStatus is completed in patientConsentEnroll step
      const stepIndex = _.findIndex(stepsMap, { key: stepObj.key });
      const stepBefore = _.get(stepsMap, stepIndex - 1) || {};
      return _.get(patientReferral, `status.${stepBefore.statusPath}`) === STATUS_ENUM.COMPLETED;
    }
  }

	const stepsMap = useMemo(() => {
		return getReferralOrEnrollMap(userRole|| 'MD', type||'REFERRAL');
	}, [userRole, type]);

	const [activeStep, setActiveStep] = useState(_.get(stepsMap, '[0]'));
	const [stepStatus, setStepStatus] = useState({});

	const handleStepChange = (stepKey) => {
		const stepObj = _.first(stepsMap.filter(v => v.key === stepKey)) || {};
		const isRdCaReferral = isRDorCA && isReferred && type === 'REFERRAL';
		if (_.get(stepStatus, stepObj.statusPath) || _.get(patientReferral, `status.${stepObj.statusPath}`) === STATUS_ENUM.COMPLETED || isRdCaReferral || isEnrolledSuccess(stepObj)) {
			const newActiveStep = _.find(stepsMap, v => v.key === stepKey);
			setActiveStep(newActiveStep);
		}
	}

	const goBack = (obj) => () => {
    const steps = stepsMap.filter(filterVisibility(patientReferral, isScreened));
		const newActiveStepIndex = _.findIndex(steps, v => v.key === obj.key);
		if (newActiveStepIndex === 0) {
			return;
		}
		setActiveStep(steps[newActiveStepIndex - 1]);
	}

	const goNextStep = (obj) => () => {
		const steps = stepsMap.filter(filterVisibility(patientReferral, isScreened));
		const newActiveStepIndex = _.findIndex(steps, v => v.key === obj.key);
		setActiveStep(steps[newActiveStepIndex + 1]);
	}

	const onSubmitDone = (obj) => async (messageConfig={}) => {
		const { tooltipMessage,fn } = messageConfig;
		if(tooltipMessage && fn) {
			fn(tooltipMessage);
		}
		const patientId = _.get(userData, 'user.id');
		setStepStatus({ ...stepStatus, [obj.statusPath]: true });
		if (!SUCCESS_PAGE_KEY.includes(obj.key)) {
			const variables = { patientId };
			_.set(variables, `status.${obj.statusPath}`, STATUS_ENUM.COMPLETED);
			await upsertPatientReferralMutation(variables)
		}
		const res = await refetchPatientReferral();
		const newPatientReferral = _.get(res, 'data.getPatientReferral');
		const { setEnrollStatus } = props;
		if(setEnrollStatus){
			const enrollStatus = _.get(newPatientReferral, 'status.enrollStatus');
			setEnrollStatus(enrollStatus);
		}
		const steps = stepsMap.filter(filterVisibility(newPatientReferral, isScreened));
		const newActiveStepIndex = _.findIndex(steps, v => v.key === obj.key);
		setActiveStep(steps[newActiveStepIndex + 1]);
	}

	const header = useMemo(() => {
		if (_.get(props, 'userData.data.loading')) {
			return <Spin />;
		} else {
			const profile = _.get(props, 'userData.user.profile', {});
			const { fullName, gender, birthday } = profile;
			return (
				<div className="refer-and-enroll-modal-header">
					<h3 className="refer-and-enroll-modal-header-name">{fullName}</h3>
					<div className="refer-and-enroll-modal-header-info">
						<div>{gender === 'M' ? 'Male' : 'Female' }</div>
						{birthday && <div>{moment(birthday).format('MM/DD/YYYY')}</div>}
						{birthday && <div>{moment().diff(birthday, 'years')} yrs</div>}
					</div>
				</div>
			);
		}
	}, [props.userData]);

	const isStepCompleted = (stepObj) => {
		if (activeStep && SUCCESS_PAGE_KEY.includes(activeStep.key) && stepObj.key === activeStep.key) {
			return true;
		}
		if (type.includes('ENROLL')) {
      if(stepObj.key === 'enrolledSuccess') {
        return isEnrolledSuccess(stepObj);
      }
			return _.get(patientReferral, `status.${stepObj.statusPath}`) === STATUS_ENUM.COMPLETED;
		} else {
			// REFERRAL FLOW
			if (isReferred) {
				return true;
			}
			return _.get(stepStatus, stepObj.statusPath, false);
		}
	}

	const isValueBasedOrg = doesOrgHaveValueBased();
	return <StepModal
			modalProps={{
        width: 1280, 
        visible, 
        className: 'refer-and-enroll-modal', 
        setShowModal,
        onCancel: () => {
          // refetch visit list on demand, when referStatus is completed
          if(_.isNil(initialReferStatus) && activeStep.key === 'referredSuccess')
            closeModal();
          else
            setShowModal(false);
        }
      }}
			activeKey={activeStep && activeStep.key}
			onStepChange={handleStepChange}
			header={header}
		>
			{stepsMap.filter(filterVisibility(patientReferral, isScreened)).map(({ Content, ...v }, i) => (
				<Step title={v.label} key={v.key} isCompleted={isStepCompleted(v)}>
          {
            (userData.loading || patientReferralLoading || dischargedPatientReferralLoading || loadingRE) ?
            <Spin />
            : (
              <React.Fragment>
                {
                  i === 0 && 
                  showExcludedOverlay && 
                  <ExcludedOverlay 
                    user={_.get(props, 'userData.user')} 
                    closeModal={closeModal} 
                    onContinue={() => { setShowExcludedOverlay(false); }} 
                  />
                }
                {<Content
                  FormWrapper={FormWrapper}
                  formWrapperProps={{ editConfirm, hasChange, setEditConfirm, setShouldUpdateCompletedReferStatus }}
                  isValueBasedOrg={isValueBasedOrg}
                  openErrorModal={openErrorModal}
                  onSubmitDone={onSubmitDone(v)}
                  user={_.get(props, 'userData.user')}
                  patientReferral={patientReferral}
                  refetchPatientReferral={refetchPatientReferral}
                  refetchQuery={props.refetchQuery}
                  userRole={userRole}
                  closeModal={closeModal}
                  isScreened={isScreened}
                  goBack={goBack(v)}
                  goNextStep={goNextStep(v)}
                  refetchUser={() => {
                    refetchUser();
                    refetchRE();
                  }}
                  disabled={isRDorCA || (isEnrolledOnMount && _.get(patientReferral, `status.${v.statusPath}`) === STATUS_ENUM.COMPLETED)}
                  patientComplexity={patientComplexity}
                  setHasChange={() => {
                    if(!hasChange) setHasChange(true);
                  }}
                  shouldUpdateCompletedReferStatus={shouldUpdateCompletedReferStatus}
                />}
              </React.Fragment>
            )
          }
					
				</Step>
			))}
		</StepModal>
};

const mapDispatch = (dispatch) => {
	return {
		openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
	}
}
export default compose(
  connect(null, mapDispatch), 
  getDischargedPatientReferral,
  getUserData, 
  getPatientReferral,
  getRemoteEnrollmentData
)(ReferAndEnrollModal);
