import moment from 'moment';
import { createAuditLog } from 'libModule/utils/auditLog';
import I18N from 'modulesAll/I18N';
import UserClass from 'graphqlModule/class/User';
import Client from 'libModule/gqlClient';
import enrollMemberGQL from 'modulesAll/graphql/enrollMember';
import adminProgramListQuery from 'graphqlModule/adminProgramList';
import { generateScheduleForDB, convertDBToVitalList } from 'modulesAll/utils/helpers/task';


const getNewAry = (field) => {
  const list = [];
  field = _.forEach(field, item => {
    list.push(_.omit(item, '__typename'));
  })
  return list;
};

const enrollMember = async (props) => {
  const { 
    user, 
    selectedProgram, 
    selectedCareTeam, 
    selectedVitalTypes,
    onSuccess, 
    onError 
  } = props;
  
  if(!Array.isArray(selectedVitalTypes) || !selectedVitalTypes.length)
    return onError('Failed to enroll patient. No recommended vitals found');

  if(!selectedCareTeam || !selectedCareTeam.id)
    return onError('Failed to enroll patient. No care team found');

  const adminProgram = selectedProgram || await Client.query({
    query: adminProgramListQuery,
    variables: {
      'page': 1,
      'count': 999, //need to get all of the admin programs, there's no pagination here
      'options': {},
      'sort': {
        'field': 'CREATED_AT',
        'direction': 'ASC'
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy:'none'

  }).then(res => {
    const adminProgramList = _.filter(_.get(res, 'data.adminProgramList.data'), a => a.isDefault);
    return _.first(adminProgramList);
  });

  if(!adminProgram)
    return onError('Failed to enroll patient. No admin program found');

  const { 
    id, 
    startDate = moment().toDate(), 
    duration: { calendar, length } = {},
    tasks
  } = adminProgram;

  const multiplier = calendar === 'DAY'
                      ? 1 : calendar === 'WEEK'
                      ? 7 : calendar === 'MONTH'
                      ? 30 : 0;
  const endDate = moment(startDate).add(length * multiplier - 1, 'd').toDate();

  // START--process TASKS
  const customizedTasks = [];

  const adminProgramTasks = _.keyBy(tasks, 'type');
  const vitalFormList = _.keyBy(convertDBToVitalList(adminProgram), 'vitalType');

  _.forEach(selectedVitalTypes, type => {
    let taskTemplate = adminProgramTasks[type];
    const vitalTypeName = I18N.get('vitals')[type]; // to full name
    const vitalForm = vitalFormList[vitalTypeName];
    if(taskTemplate && vitalForm) {
      taskTemplate.schedule = generateScheduleForDB({
        schedule: vitalForm.schedule,
        vitalType: vitalTypeName
      });

      let thresholdList = getNewAry(taskTemplate.threshold);
      thresholdList = _.map(thresholdList, item => {
        return _.omit(item, 'baselineUpdatedAt');
      });
      taskTemplate.threshold = thresholdList;

      const notificationList = getNewAry(taskTemplate.notification);
      taskTemplate.notification = notificationList;

      taskTemplate.templateDetails = _.omit(taskTemplate.templateDetails, '__typename');
      let templateSchedule = taskTemplate.templateDetails.schedule ? _.omit(taskTemplate.templateDetails.schedule, '__typename') : null;
      templateSchedule = _.mapValues(templateSchedule, item => {
        return _.omit(item, '__typename');
      })
      if(taskTemplate.templateDetails) {
        taskTemplate.templateDetails.schedule = templateSchedule;
      }

      customizedTasks.push(_.omit(taskTemplate, '__typename'));
    } else {
      console.warn('warn enrollMember: program doesn\'t have task OR no vital form found');
    }
  });
  // END---

  const variables = {
    id,
    memberId : user.id,
    teamId : [selectedCareTeam.id],
    startDate,
    endDate,
    customizedTasks,
  };

  const patientNRIC = user.identification && user.identification.length > 0 ? user.identification[0].value : [];
  const patientName = user.profile.fullName
  const auditLogDetails = {
    programName: adminProgram.name,
    careTeams: [selectedCareTeam],
    startDate: moment(variables.startDate).format('MMM-DD-YYYY'),
    endDate: moment(variables.endDate).format('MMM-DD-YYYY')
  }
  try {
    const res = await Client.mutate({
      mutation: enrollMemberGQL,
      variables
    });

    createAuditLog({
      action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
      details: auditLogDetails,
      request: variables,
      response: res,
      patientNRIC,
      patientName
    });

    await UserClass.saveEPsForProvider();

    return onSuccess(res);
  } catch (error) {
    createAuditLog({
      action: I18N.get('auditLog.provider.patient.enrollInNewProgram.save_program_tab'),
      details: auditLogDetails,
      request: variables,
      response: error,
      patientNRIC,
      patientName,
      success: false
    });

    return onError(error);
  }
};

export default enrollMember;