import { graphql } from 'react-apollo';
import { monthlyReviewList, monthlyReviewContent } from './query/monthlyReview';
import { getMonthOfYear } from '../helpers';

export const getLastFinishedMonthlyReview = graphql(monthlyReviewList, {
  options: (ownProps) => {
    const {
      patientId,
      monthOfYear = getMonthOfYear(),
    } = ownProps;

    return {
      variables: {
        count: 1,
        filters: {
          memberId: patientId,
          monthOfYear,
          status: 'DONE'
        },
        sort: {
          field: 'CREATED_AT',
          direction: 'DESC',
        },
      }
    };
  },
  props: ({ data }) => {
    const { loading, error, monthlyReviewList } = data;

    if (error) {
      console.error(error);
    }

    return {
      loadingLastFinishedMonthlyReview: loading,
      lastFinishedMonthlyReview: _.get(monthlyReviewList, 'data.0'),
    };
  }
});

export const getMonthlyReviewContent = graphql(monthlyReviewContent, {
  options: ({ patientId }) => ({
    variables: {
      memberId: patientId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ data }) => {
    const { loading, error, getMonthlyReviewContent } = data;

    if (error) {
      console.error(error);
    }

    return {
      loadingMonthlyReviewContent: loading,
      monthlyReviewContent: getMonthlyReviewContent
    };
  }
});