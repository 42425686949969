import React from 'react';
import RemoteEnrollmentContainer from "./RemoteEnrollmentContainer";
import { withRouter } from "react-router";
import {compose, graphql} from "react-apollo";
import query from "../query";
import {connect} from "react-redux";
const defaultTabSelected = 'INIT';

const RemoteEnrollmentContainerWrapper = class extends React.Component{
    constructor() {
        super();
        this.state = {
            crossClinic: true
        }
    }

    componentWillUnmount() {
      sessionStorage.removeItem('prMember');
    }

    setCrossClinic = (crossClinic)=>{
        this.setState({ crossClinic });
    }



    render() {
        const { location,selectedClinic,refetchSelectedOrgs,loadingSelectedClinic } = this.props;
        let defaultTab = _.get(location,'query.tableName',defaultTabSelected).replace(/['"]+/g,'');
        const { crossClinic } = this.state;
        return <RemoteEnrollmentContainer defaultTab={defaultTab} refetchSelectedOrgs={refetchSelectedOrgs} crossClinic={crossClinic}
                                          selectedClinic={selectedClinic} loadingSelectedClinic={loadingSelectedClinic}/>
    }
}
const getSelectedOrgs = graphql(query.getSelectedOrgsQuery,{
    options:()=>{
        return {
            fetchPolicy:'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({data})=>{
        const { loading,getSelectedClinic,refetch } = data;
        if(loading) {
            return {
                loadingSelectedClinic: loading
            }
        }
        return {
            selectedClinic:_.get(getSelectedClinic,'organizations',[]),
            refetchSelectedOrgs: refetch,
            loadingSelectedClinic:loading
        }
    }
});

export default withRouter(compose(getSelectedOrgs)(RemoteEnrollmentContainerWrapper));