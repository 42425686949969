import Todo from './schema/Todo'
import gql from 'graphql-tag'

export default gql`
  query todoList($page:Int, $count:Int, $sort:TodoListSort){
    todoList(page:$page, count:$count,   sort:$sort){
      data{
        ${Todo}
      }
      pageInfo{
        total
        lastPage
      }
    }
  }
`
