import React from 'react';
import { CONSENT_TYPE, CONSENT_DATE_FORMAT } from '../../constants';
import { helpers } from '../../../../visit/helpers';
import { Select, DatePicker, Input, message } from 'antd';
import API from '../../../../VideoChat/API';
import moment from 'moment';
import { connect } from 'react-redux';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import I18N from '../../../../I18N';
import '../../css/consentForm.scss';
const { Option } = Select;

import ConsentFormTemplate from './ConsentFormTemplate';
import ConsentOptionsComponent from './ConsentOptionsComponent';
import consentFormHelpers from '../../../../referAndEnroll/helper/consentFormHelpers';

class EnrollConsentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    const referredAt = props.referredAt;
    this.isReferredDateValid = consentFormHelpers.validateReferredDate(referredAt);
    this.getConsentDate = () => this.isReferredDateValid ? moment(referredAt) : moment();
    const consentProviderName = _.get(props,'consentProviderName');
    this.state = {
      currentUserFullName: this.getCurrentUserInfo().fullName,
      template: null,
      callInfo: this.getCallInfo(),
      effectiveDate: this.getConsentDate(),
      careTeamSignature: '',
      consentType: null,
      consentProviderName,
      consentProviderIds: [],
      consentDate: null,
      errors: {}
    };
  }

  componentDidMount = () => {
    if(this.props.onDidMount) 
      this.props.onDidMount(this);
  }

  openGraphQLErrorModal = (e) => {
    this.props.openErrorModal(GQLHelper.formatError(e));
  }

  getCurrentUserInfo = () => {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const fullName = _.get(currentUser, 'profile.fullName');
    const id = _.get(currentUser, 'id');
    return { fullName, id };
  }

  getCallInfo = () => {
    const { phoneNumbers } = this.props;
    const lastCall = JSON.parse(sessionStorage.getItem('call-center-last-call') || '{}');
    const currentUserInfo = this.getCurrentUserInfo();
    const info = {
      caller: currentUserInfo.fullName,
      callerId: currentUserInfo.id,
    }

    if (Array.isArray(phoneNumbers) && phoneNumbers.includes(lastCall.phoneNumber)) {
      info.callNumber = lastCall.phoneNumber;
      info.callDate = lastCall.startTime;
    }

    return info;
  }
  
  getDoctorsNames = (providerIds) => {
    const doctorOptions = helpers.filterDoctors({ shortName: false });
    return doctorOptions.filter(({ id }) => _.includes(providerIds, id)).map(p => p.fullName);
  }
  
  handleTemplateChange = (template) => {
    this.setState({ template });
  }

  handleChangeConsentType = (consentType) => {
    const resetStates = {
      errors: {},
      careTeamSignature: '',
      consentDate: consentType === CONSENT_TYPE.VERBALLY ? this.getConsentDate() : null,
      consentProviderIds: []
    };
    this.setState({ consentType, ...resetStates }, () => {
      if(this.props.resetSubmitError)
        this.props.resetSubmitError('missingConsentType');
    });
  }

  // handleEffectDateChange = (effectiveDate) => {
  //   this.setState({
  //     effectiveDate: effectiveDate || moment(),
  //     effectiveDateUpdated: true
  //   });
  // }

  // renderEffectiveDate = () => {
  //   const { effectiveDate } = this.state;

  //   return (
  //     <React.Fragment>
  //       <strong>Effective date</strong>
  //       <DatePicker
  //         allowClear={false}
  //         value={effectiveDate}
  //         style={{ width:'100%', alignSelf:'center' }}
  //         onChange={this.handleEffectDateChange}
  //       />
  //     </React.Fragment>
  //   );
  // }
  
  handleChangeProviderIds = (consentProviderIds) => {
    const errors = {...this.state.errors, consentProviderIds: false};
    this.setState({ consentProviderIds, doctorsNames: this.getDoctorsNames(consentProviderIds), errors });
  }

  renderProviderSelector = () => {
    const { consentProviderIds, errors } = this.state;
    const providerOptions = helpers.filterDoctors({ shortName: false });

    return (
      <React.Fragment>
        <strong className='consent-form-provider-label'>{I18N.get('consentForm.label.provider')}</strong>
        <Select
          style={{ width: '100%', border: errors.consentProviderIds ? '1px solid #FA2F14' : 'unset', borderRadius: 4 }}
          mode='multiple'
          placeholder='Please select'
          defaultValue={consentProviderIds}
          onChange={this.handleChangeProviderIds}
        >
          {_.map(providerOptions, (provider) =>
              <Option value={provider.id} key={provider.id}>{provider.fullName}</Option>
          )}
        </Select>
        {
          errors.consentProviderIds /*|| errors.verbalConsent */ && 
          <span className='consent-form-error' style={{ maxWidth: 248 }}>
            {I18N.get('consentForm.error.provider')}
          </span>
        }
      </React.Fragment>
    );
  }

  handleChangeConsentDate = (consentDate) => {
    const errors = {...this.state.errors, consentDate: false };
    this.setState({ consentDate, errors });
  }

  renderConsentDateSelector = (isPaperConsent) => {
    const { consentDate, errors } = this.state;

    const label = isPaperConsent ? 
                  I18N.get('consentForm.label.paper.consentDate') :
                  I18N.get('consentForm.label.verbally.consentDate');
    const errorText = isPaperConsent ?
                      I18N.get('consentForm.error.paper.consentDate') :
                      I18N.get('consentForm.error.verbally.consentDate');

    return (
      <React.Fragment>
        <strong className='consent-form-date-label'>{label}</strong>
        <DatePicker
          style={{ width: 248, border: errors.consentDate ? '1px solid #FA2F14' : 'unset', borderRadius: 4 }}
          value={consentDate}
          onChange={this.handleChangeConsentDate}
          format={CONSENT_DATE_FORMAT}
        />
        {
          errors.consentDate /*|| errors.verbalConsent */ && 
          <span className='consent-form-error' style={{ maxWidth: 248 }}>
            {errorText}
          </span>
        }
      </React.Fragment>
    );
  }

  handleSignatureChange = (evt) => {
    const errors = {...this.state.errors, deviceConsent: false };
    this.setState({ careTeamSignature: evt.target.value, errors  });
  }

  renderCareTeamForm = () => {
    const { careTeamSignature, errors } = this.state;
    return (
      <div className='consent-form-device-initials'>
        <strong>{I18N.get('consentForm.label.device.legalStatement')}</strong>
        <span>{I18N.get('consentForm.label.device.text')}</span>
        <Input 
          onChange={this.handleSignatureChange} 
          value={careTeamSignature} 
          style={{ width: 248, border: `0.5px solid ${errors.deviceConsent ? '#FA2F14' : '#D9D9D9'}`, borderRadius: 4 }}
        />
        { 
          errors.deviceConsent && 
          <span className='consent-form-error'>{I18N.get('consentForm.error.device')}</span>
        }
      </div>
    );
  }

  checkIfPaperConsent = consentType => consentType === CONSENT_TYPE.PAPER;

  isButtonDisabled = () => {
    const { template, careTeamSignature, consentType, consentDate, consentProviderIds } = this.state;

    const errors = {};
    if (!consentType) {
      errors.consentType = true;
    } else if (consentType === CONSENT_TYPE.TEAM_MEMBER_DEVICE && !careTeamSignature) {
      errors.deviceConsent = true;
    } else if (consentType === CONSENT_TYPE.VERBALLY && !consentDate) {
      errors.verbalConsent = true;
    } else if (consentType === CONSENT_TYPE.PAPER && !consentProviderIds.length) {
      errors.consentProviderIds = true;
    } else if (consentType === CONSENT_TYPE.PAPER && !consentDate) {
      errors.consentDate = true;
    } else if (consentType !== CONSENT_TYPE.PAPER && (!template || !template.isValid)) {
      errors.invalidTemplate = true;
      message.error('Template might be still in-progress or corrupted. Please wait for a moment and try again');
    }

    this.setState({ errors });

    return !_.isEmpty(errors);
  }

  checkIfHasConsentType = () => !!this.state.consentType;

  afterSubmit = (res) => {
    const { setConsentInfo, refetchConsentInfo, memberId } = this.props;
    if (res && setConsentInfo) {
      setConsentInfo(memberId, res);
    }
    if (refetchConsentInfo) {
      refetchConsentInfo();
    }
    return res;
  }

  handleAppSubmit = async () => {
    const { memberId,consentProviderName, selectedTeamProviderIds: providers = [] } = this.props;
    const { effectiveDate } = this.state;
    const timezone = moment.tz.guess();

    const appConsentVariables = {
      consentProviderName,
      memberId, 
      providers, 
      effectiveDate: effectiveDate.valueOf(), 
      timezone, 
      consentType: CONSENT_TYPE.APP
    };

    const res = await API.enrollPatientConsentMutate(appConsentVariables);
    return _.get(res, 'data.enrollPatientConsent');
  }

  submitNonAPP = async (consentType) => {
    const { memberId, selectedTeamProviderIds: providers = [] } = this.props;
    const { template, effectiveDate, consentDate } = this.state;
    const timezone = moment.tz.guess();
    const fileContent = template.content;

    const nonAppConsentVariables = {
      memberId, 
      providers, 
      effectiveDate: (consentDate || effectiveDate).valueOf(), 
      timezone, 
      fileContent, 
      consentType
    };

    const res = await API.createAndSignConsentMutation(nonAppConsentVariables);
    return _.get(res, 'data.createAndSignConsent');
  }

  handleCareTeamSignatureSubmit = async () => {
    return await this.submitNonAPP(CONSENT_TYPE.TEAM_MEMBER_DEVICE);
  }

  handleVerbalSubmit = async () => {
    return await this.submitNonAPP(CONSENT_TYPE.VERBALLY);
  }

  handlePaperSubmit = async () => {
    const { memberId } = this.props;
    const { consentType, consentProviderIds, effectiveDate, consentDate } = this.state;

    const paperConsentVariables = {
      memberId,
      providers: consentProviderIds,
      effectiveDate: (consentDate || effectiveDate).valueOf(),
      signatureAt: consentDate,
      timezone: moment.tz.guess(),
      consentType
    };

    return await API.createPaperConsentMutation(paperConsentVariables);
  }

  onSubmit = async () => {
    const { consentType } = this.state;
    let res;
    try {
      switch (consentType) {
        case CONSENT_TYPE.APP:
          res = await this.handleAppSubmit();
          break;
        case CONSENT_TYPE.TEAM_MEMBER_DEVICE:
          res = await this.handleCareTeamSignatureSubmit();
          break;
        case CONSENT_TYPE.VERBALLY:
          res = await this.handleVerbalSubmit();
          break;
        case CONSENT_TYPE.PAPER:
          res = await this.handlePaperSubmit();
          break;
      }
    } catch (e) {
      console.error(e);
      this.openGraphQLErrorModal(e);
    } finally {
      return this.afterSubmit(res);
    }
  }

  render() {
    const { language, languageGroupId, memberFullname, memberId,consentProviderName, renderHeader, selectedTeamProviderIds: providers = [], templateContainerId } = this.props;
    const { consentType, effectiveDate, currentUserFullName, callInfo, consentDate } = this.state;
    const isPaperConsent = this.checkIfPaperConsent(consentType);

    return (
      <div>
        {renderHeader(isPaperConsent)}
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
          {
            <div style={{ flex: 1, height: 700, minWidth: '55%', marginLeft: 62 }}>
              {
                !isPaperConsent ?
                  <ConsentFormTemplate
                    language={language}
                    languageGroupId={languageGroupId}
                    consentProviderName={consentProviderName}
                    patientId={memberId}
                    patientName={memberFullname}
                    doctorsNames={this.getDoctorsNames(providers)}
                    effectiveDate={consentDate || effectiveDate}
                    consentType={consentType}
                    careTeamFullname={currentUserFullName}
                    callInfo={callInfo}
                    onChange={this.handleTemplateChange}
                    openErrorModal={this.openGraphQLErrorModal}
                    containerId={templateContainerId}
                  />
                :
                  <div id='consent-template'>
                    No available preview for Paper consent form
                  </div>
              }
            </div>
          }
          <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ConsentOptionsComponent 
                    value={consentType}
                    onChange={this.handleChangeConsentType}
                  />
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {  shouldShowProviderOptions ?
                      <div style={{flex: 1, marginRight: 5}}>
                      {this.renderProviderSelector()}
                      </div> :''
                  }
                  <div style={{ flex: 1, marginLeft: 5 }}>
                    {this.renderEffectiveDate()}
                  </div>
                  {  !shouldShowProviderOptions ? <div style={{flex: 1, marginRight: 5}}></div> :'' }
                </div> */}
                {
                  CONSENT_TYPE.TEAM_MEMBER_DEVICE === consentType &&
                  <div style={{ marginTop: 40 }}>{this.renderCareTeamForm(consentProviderName)}</div>
                }
                {
                  isPaperConsent &&
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                    {this.renderProviderSelector()}
                  </div>
                }
                {
                  (isPaperConsent ||
                  consentType === CONSENT_TYPE.VERBALLY) &&
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: isPaperConsent ? 20 : 40 }}>
                    {this.renderConsentDateSelector(isPaperConsent) }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
  }
};

export default connect(null, mapDispatch)(EnrollConsentFormComponent);
