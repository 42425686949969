import {
    React,
    BaseComponent,
    _
} from './util'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{

    render(){

        const type = this.props.type || 'note'
        let msg = this.props.message || null

        if(_.isString(msg)){
            msg = <p className="ih-msg">{msg}</p>
        }


        return (
            <div className="IH-Message">
                <dd className="ih-body">
                    <i className={`ih-${type}`}></i>
                    {msg}
                </dd>
            </div>
        )
    }
}

Component.propTypes = {
    // warning, success, error, note, locked, loading
    type : PropTypes.string,

    message : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ])
}

export default Component
