import React, { Component } from 'react';
import { Radio, Button, DatePicker, Modal, Input, Checkbox, Row, Col, InputNumber } from 'antd';
import { _ } from 'ihcomponent';
import moment from 'moment';
import Client from 'libModule/gqlClient';
import addToInventory from '../../graphql/updateQueries/addToInventory'
import removeFromInventory from '../../graphql/updateQueries/removeFromInventory'

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const mappingDevices = {
    'BP': 'BP3L',
    'LANCET': 'Lancets',
    'CLEAR': 'Clear',
    'BG': 'BG1',
    'STRIP': 'Strips',
    'PHONE': 'iPhone',
    'HS': 'HS2',
    'A1C': 'A1C',
    'THERMOMETER': 'Thermometer',
    'OXIMETER': 'Oximeter',
    'BG5S':'BG5S',
    'NEXUS': 'Nexus'
};
const devicesArr = [
  ['BP', 'PHONE', 'CLEAR', 'A1C'], 
  ['BG', 'BG5S', 'LANCET', 'STRIP'], 
  ['THERMOMETER', 'OXIMETER', 'HS', 'NEXUS']
];

const initialDisplayMap = () => ({
    BP : false,
    LANCET: false,
    CLEAR: false,
    BG: false,
    STRIP: false,
    PHONE: false,
    HS: false,
    A1C: false,
    THERMOMETER: false,
    OXIMETER: false,
    BG5S: false,
    NEXUS: false
})

const initialStockMap = () => ({
    BP: 0,
    LANCET: 0,
    CLEAR: 0,
    BG: 0,
    STRIP: 0,
    PHONE: 0,
    HS: 0,
    A1C: 0,
    THERMOMETER: 0,
    OXIMETER: 0,
    BG5S: 0,
    NEXUS: 0
})

export default class DateControlContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            btnType: 0,
            value: '',
            displayMap: initialDisplayMap(),
            stockMap: initialStockMap()
        }
    }

    showModal = (type) => this.setState({ btnType: type, visible: true });

    handleOk = type => {
        this.setState({ loading: true }, async () => {
            const products = [];
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            Object.keys(this.state.stockMap).forEach((key) => {
                const quantity = _.get(this, `state.stockMap.${key}`);
                if(quantity !== 0) {
                    products.push({
                        productType: key,
                        quantity: type==='restock' ? quantity : -1 * quantity
                    } )
                }
            });
            try {
                await Client.mutate({
                    mutation: type === 'restock' ? addToInventory : removeFromInventory,
                    variables: {
                        organizationId: this.props.organizationId,
                        memberId: currentUser.id, //memberId should not be required
                        products,
                        note: this.state.value
                    },
                });
                // use to re-calculate forecast after giving out
                // let giveOuts = type === 'restock' ? undefined : products;
                this.handleCancel();
                this.props.removeObserver();
                // this.props.refetchInventoryData(giveOuts, true);
                this.props.refetchInventoryData(null, true);
            } catch (err) {
                console.log('error---', err);
            }
        });
    };

    handleCancel = () => this.setState({ loading: false, visible: false, value: '', displayMap: initialDisplayMap(), stockMap: initialStockMap() });

    onChangeNote = ({ target: { value } }) => {
        this.setState({ value });
    };

    changeStockItem = (id, val) => {
        const copyObj = {...this.state.stockMap};
        if(_.get(this.state.displayMap, id)) {
            _.set(copyObj, id, val);
        } else {
            _.set(copyObj, id, 0);
        }
        this.setState({
            stockMap: copyObj
        })
    }

    toggleVisibility = (e) => {
        const copyObj = {...this.state.displayMap};
        const copyStock = {...this.state.stockMap};
        _.set(copyObj, e.target.id, e.target.checked)
        if(!e.target.checked) {
            _.set(copyStock, e.target.id, 0);
        }
        this.setState({
            displayMap: copyObj,
            stockMap: copyStock
        })
    }

    onDateChange = (value, dateString) => {
        if(value) {
            this.props.removeObserver();
            this.props.fetchWithViewOption({
                target : {
                    value: null,
                    rangePicked: { fromDate: value[0].startOf('day').valueOf(), toDate: value[1].endOf('day').valueOf() }
                }
            });
        }
    }

    onRadioChange = e => {
        this.props.removeObserver();
        this.props.fetchWithViewOption(e);
    }

    checkSubmitValid = () => {
        const checkArr = Object.values(this.state.displayMap);
        const stockArr = Object.values(this.state.stockMap);
        let count = 0;
        for(let i = 0; i < checkArr.length; i++) {
            if(checkArr[i] === false) {
                count++;
            } else {
                if(stockArr[i] === 0 || stockArr[i] === "" || typeof(stockArr[i]) === 'undefined') {
                    return false;
                }
            }
        }
        if(count === Object.keys(mappingDevices).length) {
            return false;
        }
        return true;
    }

    createCheckBox = (mappingDevices, devicesArr, toggleVisibility, changeStockItem, type) => {
        return _.map(devicesArr, (arr, i) => {
            return <Row key={i} style={{marginTop:'10px'}}>
                {
                    _.map(arr,(k, i)=>{
                        return (
                            <Col span={6} key={i}>
                                <Row>
                                    <Col span={13}>
                                        <Checkbox id={k} value={k} onChange={e => toggleVisibility(e)}>
                                            {_.get(mappingDevices, k)}
                                        </Checkbox>
                                    </Col>
                                    <Col span={11}>
                                        {
                                            _.get(this.state.displayMap, k) &&
                                            <InputNumber
                                                placeholder="Quantity"
                                                // style={{marginLeft: (k === 'THERMOMETER' || k === 'OXIMETER') ? 0 : -35 }}
                                                min={type==='restock' ? 1 : -999}
                                                max={type==='restock' ? 999 : -1}
                                                size='small'
                                                onChange={e => changeStockItem(k, e)}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })
                }
                </Row>
        })
    }

    render() {
        const { visible, loading, value } = this.state;
        const type = this.state.btnType === 0 ? 'restock' : 'give out';
        const organization = this.props.organizationName;
        const title = "Choose the item you want to " + type + " for " + organization;
        const isViewOptionDisabled = this.props.isViewOptionDisabled;
        return (
            <div style={{fontSize:"14px", marginTop:"70px", marginLeft:"55px"}}>
                <span>Choose calendar view:</span>
                <br/>
                <Radio.Group
                    value={this.props.viewOption}
                    style={{marginTop:"12px", marginBottom:"12px", width:"240px", color: isViewOptionDisabled ? 'rgba(0,0,0,.25)' : ''}}
                    onChange={this.onRadioChange}
                    disabled={isViewOptionDisabled}
                >
                    <Radio.Button value="DAY" style={{width:"80px", textAlign:"center", color: isViewOptionDisabled ? 'rgba(0,0,0,.25)' : ''}}>
                        Day
                    </Radio.Button>
                    <Radio.Button value="WEEK" style={{width:"80px", textAlign:"center", color: isViewOptionDisabled ? 'rgba(0,0,0,.25)' : ''}}>
                        Week
                    </Radio.Button>
                    <Radio.Button value="MONTH" style={{width:"80px", textAlign:"center", color: isViewOptionDisabled ? 'rgba(0,0,0,.25)' : ''}}>
                        Month
                    </Radio.Button>
                </Radio.Group>
                <br/>
                <span>Choose date range:</span>
                <br/>
                <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={['Start Time', 'End Time']}
                    value={this.props.viewOption ? [null, null] : [moment(this.props.dateRange.fromDate), moment(this.props.dateRange.toDate)] }
                    onChange={this.onDateChange}
                    style={{marginTop:"20px", marginBottom:"50px", width:"240px"}}
                    disabledDate={current => current && current > moment().endOf('day')}
                    allowClear={false}
                    disabled={isViewOptionDisabled}
                />
                <br/>
                <br/>
                <Button onClick={() => this.showModal(0)}
                        style={{width:"110px", textAlign:"center", backgroundColor:'#36CC8D', fontWeight:'bold', color:'white'}}>Restock</Button>
                <Button onClick={() => this.showModal(1)}
                        style={{width:"110px", textAlign:"center", marginLeft:'20px', backgroundColor:'#EE6161', fontWeight:'bold', color:'white'}}>Give Out</Button>
                <Modal
                    visible={visible}
                    title= {title}
                    onCancel={this.handleCancel}
                    width='1000px'
                    destroyOnClose={true}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={() => this.handleOk(type)}
                            disabled={!this.state.value || this.state.value.trim() === '' || !this.checkSubmitValid()}
                        >
                            Submit
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    ]}>
                    {this.createCheckBox(mappingDevices, devicesArr, this.toggleVisibility, this.changeStockItem, type)}
                    <TextArea
                        rows={4}
                        value={value}
                        style={{marginTop:'40px'}}
                        placeholder="Add Note Here"
                        onChange={this.onChangeNote}>
                    </TextArea>
                </Modal>
            </div>
        )
    }
}
