import React from 'react';
import {IHLoading} from 'ihcomponent';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import {providerProfile} from '../../../graphql/user';
import createTableAction from 'libModule/table/TableActions';
import get from 'lodash/get'
import query from '../../query';
import UserDetailComponent from '../Component/UserDetailComponent';
import helper  from '../../helper/index';
import {HC_STR} from '../../constant/roleStrMap';
import { ROLE_NAME } from '../../../../lib/constants';

const listRoles = (user) => {
    const roles = user.allRoles;
    const team = user.team;
    let map = {
        'MA':[],
        'Provider':[],
        'Site Manager':[],
        'Area Manager':[],
        'CA':[],
        'RD':[],
        'Biller':[],
        'Partner Biller': [],
        'HC': []
    }
    _.map(roles, (key) => {
        const currTeam =_.filter(team,(it)=>it.organization.id===key.organization.id);
        if(key.name==='Doctor'){
            map['Provider'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name==='Clinic Medical Assistant'){
            map['MA'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.organization.leaf&&key.name==='Admin'){
            map['Site Manager'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(!key.organization.leaf&&key.name==='Admin'){
            map['Area Manager'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name==='Medical Assistant'){
            map['CA'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name==='Nurse'){
            map['RD'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name==='Biller') {
            map['Biller'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name==='Partner Biller') {
          map['Partner Biller'].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
        if(key.name===ROLE_NAME.HC) {
          map[HC_STR].push({'refid':key.refId,'organization':key.organization,'team':currTeam});
        }
    })
    return map;
}
class UserDetailContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { loading,userLoading, location } = this.props;
        if (loading|| userLoading) {
            return <IHLoading />
        }
        return (
            <UserDetailComponent
            userData={this.props.userData}
            userRefetch={this.props.userRefetch}
            roles={this.props.roles}
            organizationList={this.props.organizationList.data}
            location={location}
            />
        )
    }
}

const userData = graphql(providerProfile, {
    options: (ownProps) => {
      const id = ownProps.id
      return {
        variables: {
          id
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: ({ data }) => {
      if( data.loading){
          return {
              userLoading: data.loading
          }
        }
      return {
        userData: data,
        roles:!data.loading?listRoles(_.get(data, 'user')):null,
        userRefetch: data.refetch
      }
    }
  })
  const tableActions = createTableAction('organization_list', {
    shouldUpdateHistory: false,
    sortFieldMap: {
      createdAt: 'CREATED_AT',
      modifiedAt: 'MODIFIED_AT',
      careTeam: 'NAME'
    },
  })
  const organizationListData = graphql(query.orgListForCreateUser, {
    options: (ownProps) => {
      const currentUser = helper.getCurrentUser();
      const curOrgId = _.get(currentUser,'selectedRole.organization.id');
      const variables = {page:1,count:9999,parentId:curOrgId,leafOnly:true};

      return {
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: ({ data }) => {
      return {
        organizationList: data.organizationList,
        loading: data.loading,
        refetch: data.refetch
      }
    }
  })

  const mapState = (state, ownProps) => {
    const id = get(state.routing, 'params.userId')
    return {
      ...state.globalTables.organization_list,
      ...ownProps,
      id
    }
  }

  export default compose(connect(
    mapState,
    (dispatch) => {
      return {
        ...tableActions
      }
    }
  ), userData, organizationListData)(UserDetailContainer);
