import gql from 'graphql-tag';
import Measure from './schema/Measure';
import Result from './schema/Result';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';

export default gql `
  mutation insertTaskResult(
    $measureId: EID,
    $assessmentFilledId: EID,
    $refId: [Ref]!
  ){
    insertTaskResult(
      measureId: $measureId,
      assessmentFilledId: $assessmentFilledId,
      refId: $refId
    ) {
      id
      user {
        id
      }
      taskStatus
      severity
      threshold {
        measure
      }
      careStatus
      refId
      measure {
        ${Measure}
      }
      ${Result}
      threshold {
        measure
      }
    }
  }
`;

export const insertTaskResultMeasurement = gql `
  mutation insertTaskResult(
    $measureId: EID
    $refId: [Ref]!
  ){
    insertTaskResult(
      measureId: $measureId
      refId: $refId
    ) {
      id
      user {
        id
      }
      taskStatus
      severity
      careStatus
      refId
      measure {
        ${Measure}
      }
      ${Result}
      threshold {
        measure
      }
    }
  }
`;

export const updateQueries = {
  resultsFromToday: (prev, { mutationResult }) => {
    const result = mutationResult.data.insertTaskResult[0];
    //console.log('insertTaskResult result in updateQueries for resultsFromToday ', result)
    const resultsFromToday = cloneDeep(prev.resultsFromToday);
    for (let i = 0; i < resultsFromToday.length; i++) {
      let item = resultsFromToday[i];
      if (item.refId === result.refId && ['CURRENT', 'INCOMPLETE'].includes(item.taskStatus)) {
        //need to handle BG separately, need the mealType and beforeMeal information if it's not by frequency and it's not a one-time task
        const beforeMeal = item.schedule.beforeMeal === 'BEFORE_MEAL'
        if(result.measure.type !== 'BG' || item.schedule.beforeMeal === 'ANY_TIME' || !item.schedule.beforeMeal || (result.measure.mealType === item.schedule.mealType && result.measure.beforeMeal === beforeMeal) ||
           item.schedule.absoluteStart) {
          item = {
            ...item,
            ...result,
            severity: result.severity,
            date: new Date().getTime(),
            taskStatus: 'COMPLETED'
          };
          resultsFromToday[i] = item;
          break;
        }
      }
    }
    return update(prev, {
      resultsFromToday: {
        $set: resultsFromToday,
      },
    });
  },
  resultList: (prev, { mutationResult }) => {
    const result = mutationResult.data.insertTaskResult[0];
    //console.log('insertTaskResult result in updateQueries resultList for resultList ', result)
    const newResult = {
      ...result,
      taskStatus: 'COMPLETED',
    };
    return update(prev, {
      resultList: {
        $set: [
          newResult,
          ...prev.resultList,
        ],
      },
    });
  },
};
