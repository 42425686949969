import React from 'react';
import MonthlyReportContainer from "./MonthlyReportContainer";
import {Modal} from "antd";
import {graphql} from "react-apollo";
import {enrolledProgramListForWorkstation} from "../../graphql/enrolledProgramList";
import goalAssessmentHelper from "../../workStation/helper/goalAssessmentHelper";
import { compose } from 'recompose';

const MonthlyReportInChatContainer = class extends React.Component{
	constructor() {
		super();
		this.state = {
			monthlyReportId: null
		}
	}
	renderPreviewMonthlyReport = ()=>{
		const { monthlyReportId } = this.state;
		const { currentProgram } = this.props;
		return monthlyReportId && <Modal visible={ !!monthlyReportId} onCancel={()=>this.setMonthlyReportId(null)} footer={null}>
			<MonthlyReportContainer id={monthlyReportId} currentProgram={currentProgram}/>
		</Modal>

	}
	setMonthlyReportId =(monthlyReportId)=>{
		this.setState({
			monthlyReportId
		})
	}

	render(){
		const { id } = this.props;
		const { monthlyReportId } = this.state;
		return <React.Fragment>
				<li className={'collection-item-avatar MRE'} onClick={()=>this.setMonthlyReportId(id)} style={{ textDecoration:'underline',cursor:'pointer' }}>Your Monthly Health Report is ready.</li>
				{ this.renderPreviewMonthlyReport() }
		</React.Fragment>
	}
}
export const withEnrolledCurrentProgram = graphql(enrolledProgramListForWorkstation, {
	skip:(ownProps)=>{
		return _.get(ownProps,'currentProgram.id');
	},
	options: ownProps => {
		const { query, ...options } = goalAssessmentHelper.getEnrolledProgramListQuery(ownProps);
		return {...options};
	},
	props: ({ ownProps, data }) => {
		const mostRecentEP = _.first(_.get(data, 'enrolledProgramList.data', []));
		return { currentProgram: mostRecentEP, currentProgramLoading: data.loading, currentProgramRefetch: data.refetch };
	},
});
// export default MonthlyReportInChatContainer;
export default compose(withEnrolledCurrentProgram)(MonthlyReportInChatContainer);
