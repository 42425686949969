
import gql from 'graphql-tag';

export  default gql`
query taskAssignmentUnreadTasks($filters: TaskAssignmentListOpts){
  taskAssignmentList(filters:$filters){
    pageInfo{
      total
    }
    data{
      taskAssignments{
        isRead {
          value
          readBy
        }
      }
    }
  }
}`
