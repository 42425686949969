import {React, IHLoading, IHButton, BaseComponent, ND} from 'ihcomponent';
import { graphql } from 'react-apollo';
import I18N from 'modulesAll/I18N';
import { lightEnrolledProgramListForPatient } from 'modulesAll/graphql/enrolledProgramList';
import MemberDetail from './MemberDetail';
import Tab from './Tab';
import Dropdown from './Dropdown';
import EnrolledProgramInfo from '../../enrolledProgramInfo/containers/main'
import { modalAction } from 'modulesAll/common/actions'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import actions from 'modulesAll/patient/deviceTab/actions'
import { Row } from 'antd';
import { compose, withProps } from 'recompose';
import * as R from 'ramda';
import { patientGQL } from '../../../graphql/user';
import ChatWindow from 'modulesAll/chat/containers/ChatContainer';
// import CallWindow from 'modulesAll/call/containers/callContainer';
import { createContainer, getRouterParam, RequestCache, goPath } from 'libModule/utils';
import dischargeEnrolledProgram from 'modulesAll/graphql/dischargeEnrolledProgram';
import resumeEnrolledProgram from 'modulesAll/graphql/resumeEnrolledProgram';
import { openModal, closeModal, openErrorModal } from 'layoutModule/actions/MainModal';
import { setResuming, setChat } from 'patientModule/enrolledProgramInfo/actions/EnrolledProgramInfoActions';
import TimerContainer from 'modulesAll/timer/containers/TimerContainer';
import PatientAppointmentContainerWrapper from 'modulesAll/visit/containers/PatientAppointmentContainerWrapper';
import getPatientChatInfo from "modulesAll/graphql/getPatientChatInfo";
import Client from 'libModule/gqlClient';
import { routerWillLeaveHelperWithOneButton } from '../../enrolledProgramInfo/helper/helpers';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { ENROLL_FROM, EP_COUNT_MAX } from '../../../../lib/constants';
import Query from '../../../VideoChat/API/';
import EnrollPatientContainer from '../../EnrollPatient/container/EnrollPatientContainer';
import ChatContainerWrapper from '../../../chat/containers/ChatContainerWrapper';
import { mapDispatchToTimerActions } from '../../../timerClaimed/helpers';
import { EVENT_TYPES } from '../../../idleTime/constant';
import TimerClaimedContainer from '../../../timerClaimed/containers/TimerClaimedContainer';
import SmartAlertAndMTPR from './SmartAlertAndMTPR';
import '../../../workStation/style/generateMTPR.scss';
import { getPatientHiatusQuery } from '../../../graphql/getHiatus';
import { patientReferralMini } from '../query/index';


const PATIENT_PROFILE_TIMER = 'PATIENT_PROFILE_TIMER';
const debug = false;
const tabMap = {
  programDetails: 'CARE_PLAN',
  providerNotes: 'PROVIDER_NOTE',
  vitals: 'VITALS',
  foodlog: 'FOOD_LOG',
  LogBook: 'LOG_BOOK',
  labresults: 'LAB_RESULTS',
  medicine: 'MEDICATION'
}
const wrapResumeGQLData = graphql(resumeEnrolledProgram, {
  name: 'resumeEnrolledProgram',
});

const wrapDischargeGQLData = graphql(dischargeEnrolledProgram, {
  name: 'dischargeEnrolledProgram',
});

const wrapGQLData2 = graphql(lightEnrolledProgramListForPatient, {
  options: ownProps => ({
    variables: {
      page: 1,
      count: EP_COUNT_MAX, //note: there's no pagination for EPList
      filters: {
        memberId: ownProps.patientId,
      },
      // sort by CreatedAt
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ ownProps, data }) => { return { data, lightEnrolledProgramListRefetch: data.refetch } },
});

const displayName = 'patient/main/containers/main'
class ProviderViewPatientContainer extends BaseComponent {
  static displayName = displayName;
  constructor() {
    super();
    this.state = {
      showStickyHeader: false,
      tabSelected: '',
      showChat: null,
      channelName: '',
      callPhoneNumber: '',
      callUserName: '',
      callStatus: 7,    //7 is close
      callDate: null,
      callDuration: 0
    }
    this.ref = React.createRef();
    this.handleContainerScroll = this.handleContainerScroll.bind(this);
    this._isMounted = false;
    this.profileHeaderObserver = null;
  }

  componentDidUpdate(prevProps) {
    //a workaround that do no need major changes to existing file, so that console does not throw error for breadcumb
    const currentPatient = RequestCache.get('nurse/currentPatient')
    const patientNameFromProgramme = _.get(this.currentProgram, 'user.profile.fullName')
    const patientNameFromRequestCache = _.get(currentPatient, 'profile.fullName')

    if (!this.state.hasBreadCrumb && this.currentProgram) {
      const { status, name } = this.currentProgram;
      const programStatus = (status === 'DISCHARGED' || this.props.enrolledProgramId === 'past') 
        ? 'Past' 
        : 'Current';
      this.props.setBreadCrumb(`Patients / ${patientNameFromProgramme || patientNameFromRequestCache} / ${programStatus} ${I18N.get('keywords.Programs')} / ${name || 'New'}`);
      this.setState({ hasBreadCrumb: true });
    }

    if (!this.props.timerName && prevProps.data.loading && !this.props.data.loading && this.props.interacted) {
      this.props.interacted(EVENT_TYPES.PATIENT_PROFILE);
    }

    if (this.props.setIsEnrolled && _.get(prevProps, 'data.loading') === true && _.get(this.props, 'data.loading') === false) {
      this.props.setIsEnrolled(_.get(this.props, 'data.enrolledProgramList.data[0].status') === 'STARTED');
    }

    // sticky profile header
    let stickyStartingPoint = document.querySelector('#sticky-container');
    if(!stickyStartingPoint) {
      this.profileHeaderObserver = null; // initial or reset when loading
      return;
    } else if (this.stickyStartingPoint === stickyStartingPoint) {
      return;
    }
      
    this.stickyStartingPoint = stickyStartingPoint;

    this.profileHeaderObserver = new IntersectionObserver(_.debounce(([entry]) => {
      const setStickyHeader = (flag) => this.setState(((preState) => {
        if (preState.showStickyHeader !== flag) {
          return { showStickyHeader: flag };
        }
      }));

      if(!this._isMounted)
        return;

      if(entry.isIntersecting)
        setStickyHeader(false);
      else
        setStickyHeader(true);
    }, 150), {
      threshold: 0.5
    });
    this.profileHeaderObserver.observe(this.stickyStartingPoint);
  }

  componentWillMount() {
    // setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this))
    this.profileHeaderObserver = null;
  }

  routerWillLeave() {
    return new Promise((resolve, reject) => {
      const { contactStatus } = this.props;
      if (contactStatus == 'onConnected' || contactStatus == 'onConnecting') {
        routerWillLeaveHelperWithOneButton(resolve, resolve)
      } else {
        resolve(true);
        return;
      }
    })
  }

  componentDidMount() {
    const { interacted } = this.props;
    this._isMounted = true;
    // window.addEventListener('scroll', this.handleContainerScroll);
    
    if (interacted) {
      interacted(EVENT_TYPES.PATIENT_PROFILE);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleContainerScroll);
    //terminateCCP()
    this._isMounted = false;

  }

  handleContainerScroll(e) {
    // const target = e.target;
    // const height = $(target).find("#profile_detail").outerHeight();
    // const visitListTop = _.get($(target).find('.patientAppointCollapse').offset(), 'top');

    // const { profileHeight } = this.props;
    // if(this.props.profileHeight==0){
    //     this.props.setPatientDivHeight(height);
    // }
    //54 is the height of top menu;
    //87 is the height of collapsed patient profile div;
    //if scroll to height less than top menu height plus collapsed patient profile div,
    //then show the sticky header;

    let isUnEnrolled = document.getElementsByClassName('unenrolledPatientProfile');
    let isInWorkStation = document.getElementsByClassName('patientsWrapper');
    let isDischarged = document.getElementsByClassName('program-discharge-detail-wrapper');
    const height = isUnEnrolled.length === 0 ? (isInWorkStation.length == 0 ? (isDischarged == 0 ? 335 : 250) : 400) : 200;
    let visitListCollapse = document.querySelector('.patientAppointCollapse');
    if(!visitListCollapse || !visitListCollapse.getBoundingClientRect)
      return;

    let visitListTop = visitListCollapse.getBoundingClientRect();
    visitListTop = _.get(visitListTop, 'top');

    if (visitListTop < height) {
      this.setState(((preState) => {
        if (!preState.showStickyHeader) {
          return { showStickyHeader: true }
        }
      }))
      return;
    }
    //if scroll to height to 100px less than original patient profile div
    // hide sticky header;
    if (visitListTop > 70) {
      this.setState((preState) => {
        if (preState.showStickyHeader) {
          return { showStickyHeader: false }
        }
      })
      return;
    }
  }

  updateTabSelected = (curTab) => {
    // const PATIENT_FULL_NAME =  _.get(this.props,'patientName');
    const PATIENT_ID = _.get(this.props, 'patientId');
    const TAB_NAME = tabMap[curTab];
    this.setState({
      tabSelected: curTab
    }, () => {
      Mixpanel.track('clicked', tabMap[curTab], 'patient_profile', { PATIENT_ID, TAB_NAME });
    })
  }

  setChatStatus = (showChat) => {
    this.setState({
      showChat
    })
        // () => this.props.setChat(this.state.showChat));
  }

  getChatInfo = async () => {
    const { patientId } = this.props;
    let { channelName } = this.state;
    if (channelName.length == 0) {
      const res = await Client.query({
        query: getPatientChatInfo,
        variables: { userId: patientId },
        fetchPolicy: 'network-policy',
      });
      channelName = _.get(res, 'data.getUserChatInfo.channels[0].name');
    }
    this.setState({
      channelName
    })
    return channelName;
  }

  setEnrollmentStatus = (enrollmentStatus) => {
    this.setState({enrollmentStatus})
  }

  renderTopButton(){
    const { handleChangePage, patientId } = this.props;
    const newUrl = `/patients/${patientId}/enrolledprogram/default`;
    const b1 = {
      label : '< Back to Patient Profile',
      size : 'large',
      type : 'link',
      onClick : () => {
        goPath(newUrl);
        handleChangePage('patient_profile')
      }
    }

    return (
      <div className="v-top">
        <IHButton style={{color:'black'}} {...b1} />
      </div>
    )
  }

  render() {
    const { data, enrolledProgramId, patientId, patientName, patientBirthday, doctor, menuOpened, refetchUser, viewerInfo, consentInfo, refetchConsentInfo, isWorkStation, profileTabSelected, interacted, timerName,
      monthlyReviewData, setMonthlyReviewData, setSystemGenerateMonthlyReviewData, systemGenerateMonthlyReviewData, isProviderAlert, hiatusStatus, refetchHiatusStatus, patientReferral, refetchPatientReferral, isSignOff } = this.props;
    const { showStickyHeader, showChat, channelName, enrollmentStatus } = this.state;
    let role = _.get(viewerInfo, 'selectedRole.name', '');
    let curTab = this.state.tabSelected === '' ? 
                ( // default activeTab
                  profileTabSelected ||
                  ((role === 'Doctor' || role === 'Clinic Medical Assistant') ? 'vitals' : 'programDetails')
                ) : this.state.tabSelected;
    let { enrolledProgramList, loading, refetch } = data;
    enrolledProgramList = _.get(enrolledProgramList, 'data') || [];
    if (debug) console.log('ProviderViewPatientContainer props', this.props);
    let dischargedEPList = _.filter(enrolledProgramList, (e => e.status == 'DISCHARGED'));

    let memberDetail = <MemberDetail patientId={patientId}
      isWorkStation={isWorkStation}
      dischargedEPList={dischargedEPList}
      showStickyHeader={showStickyHeader}
      callPhone={(phoneNumber, username) => this.callPhone(phoneNumber, username)}
      updateCallStatus={(status, duration, date) => this.updateCallStatus(status, duration, date)}
      callDate={this.state.callDate} callDuration={this.state.callDuration}
      refetchUser={refetchUser}
      menuOpened={menuOpened}
      curPage = {this.props.curPage} handleChangePage = {this.props.handleChangePage}
      enrolledProgramList={enrolledProgramList}
      loadingEnrolledProgramList={loading}
      consentInfo={consentInfo}
      refetchConsentInfo={refetchConsentInfo}
      onInteraction={interacted}
      setEnrollmentStatus={this.setEnrollmentStatus}
      isProviderAlert={isProviderAlert}
      hiatusStatus={hiatusStatus}
      patientReferral={patientReferral}
      />;
    let body = <IHLoading />;

    if (dischargedEPList.length == 0) {
      dischargedEPList = '';
    }

    const parentWidth = _.get(this, 'ref.current.offsetWidth');
    if (loading) {
      return (
        <div className={'outtercontainer patient-wrapper-6188'}>
          {memberDetail}
          {body}
        </div>
      );
    }
    RequestCache.set('refetch-enrolledProgramList', refetch);

    let mostRecentEP = _.first(enrolledProgramList);

    const sortedEnrolledProgramList = _.orderBy(enrolledProgramList, [programme => programme.name.toLowerCase()], ['asc'])

    let showStarted = false;

    let canViewEPList = sortedEnrolledProgramList;
    // sortedEnrolledProgramList.filter( (ep) => (nurseEPList || []).indexOf(ep.id) !== -1 )

    const startedPrograms = sortedEnrolledProgramList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))
    const pastPrograms = sortedEnrolledProgramList.filter(enrolled => (enrolled.status === 'DISCHARGED' || enrolled.status === 'RETURNED'))

    const startedProgramsCanView = canViewEPList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))
    const pastProgramsCanView = canViewEPList.filter(enrolled => (enrolled.status === 'DISCHARGED' || enrolled.status === 'RETURNED'))

    //handle discharged program
    let showDischargedPrograms = false;
    let signFromDash = false;
    //TODO please check logic here. make showStarted=false will have some errors.
    if (enrolledProgramId === 'default' || enrolledProgramId === 'current' || enrolledProgramId === 'new' || _.endsWith(enrolledProgramId, '_')) {
      this.currentProgram = startedProgramsCanView[0];
      if (enrolledProgramId === 'new') {
        this.currentProgram = null
      }
      if(_.endsWith(enrolledProgramId, '_')) {
        signFromDash = true;
      }
      showStarted = true;
    } else if (enrolledProgramId === 'past') {
      this.currentProgram = pastProgramsCanView[0];
      showStarted = false;
    } else {
      this.currentProgram = _.find(enrolledProgramList, enrolled => enrolled.id === enrolledProgramId)
      if (_.find(startedPrograms, (p) => p.id === enrolledProgramId)) {
        showStarted = true
      }
      // showStarted = !currentProgram
      //   || currentProgram.status === 'STARTED'
      //   || currentProgram.status === 'SUSPENDED';
    }

    //handle discharged program
    if (this.currentProgram && this.currentProgram.status === 'DISCHARGED') {
      showDischargedPrograms = true;
      canViewEPList = sortedEnrolledProgramList.filter(enrolled => (enrolled.status === 'DISCHARGED'));
      dischargedEPList = '';
    }
    RequestCache.set('enrolledProgramList', enrolledProgramList);

    //console.log('CurrentProgram -> ', currentProgram)
    //let qrcode = null
    const showIncompletedEP = !this.currentProgram || this.currentProgram.status === 'CREATED' || this.currentProgram.status === 'PENDING';
    // if(R.contains(tabSelected, ['summary', 'addresult'])) {
    //   body = null;
    // }

    //4/3/2018 edited by Adam to pass currentProgramId to member detail for chatting
    if (mostRecentEP && mostRecentEP.id) {
      memberDetail = <MemberDetail
        isWorkStation={isWorkStation}
        isSignOff={isSignOff}
        patientId={patientId}
        currentProgramId={mostRecentEP.id}
        currentProgram={mostRecentEP}
        currentProgramNames={startedProgramsCanView.map(item => item.name)}
        showStickyHeader={showStickyHeader}
        parentWidth={parentWidth}
        menuOpened={menuOpened}
        showChat={showChat}
        dischargedEPList={dischargedEPList}
        callPhone={(phoneNumber, username) => this.callPhone(phoneNumber, username)}
        updateCallStatus={(status, duration, date) => this.updateCallStatus(status, duration, date)}
        callDate={this.state.callDate} callDuration={this.state.callDuration} refetchUser={refetchUser}
        handleChangePage = {this.props.handleChangePage}
        enrolledProgramList={enrolledProgramList}
        consentInfo={consentInfo}
        refetchConsentInfo={refetchConsentInfo}
        onInteraction={interacted}
        setEnrollmentStatus={this.setEnrollmentStatus}
        isProviderAlert={isProviderAlert}
        setTimeSpentInMin={this.props.setTimeSpentInMin}
        hiatusStatus={hiatusStatus}
        patientReferral={patientReferral}
      />;
    }

    const ProgramDropDown = () => (
      <div style={{ marginRight: 29, width: 400 }}>
        <Dropdown
          value={this.currentProgram ? this.currentProgram.id : 'new'}
          epStatus={this.currentProgram ? this.currentProgram.status : '' /** case when enrol new program **/}
          enrolledProgramList={showStarted ? startedPrograms : canViewEPList}
          key="1"
          {...this.props}
          devices={this.currentProgram ? this.currentProgram.devices : []}
        />
        {this.currentProgram && this.currentProgram.status === 'SUSPENDED' && (
          <ND.Alert
            className="vsm-patient-warning"
            message={`Patient Suspended from ${I18N.get('keywords.Program')}`}
            description={`Patient cannot complete any tasks in this ${I18N.get('keywords.program')} during suspension period.`}
            type="warning"
            showIcon
          />
        )}
      </div>
    );

    const enrollButtonClick = () => {
      const _currentPatient = RequestCache.get('nurse/currentPatient')
      const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
      const patientName = _currentPatient.profile.fullName
      // createAuditLog({action:I18N.get('auditLog.provider.patient.enrollInNewProgram.enroll_in_new_program'), patientNRIC, patientName })
      goPath(`/patients/${patientId}/enrolledprogram/default_`);
    };

    if (!this.currentProgram) {
      // program states are passed through the ID field
      const knownAlternateStates = ['new', 'default'];
      const enrolledProgramId = this.props.enrolledProgramId;
      if (!R.contains(enrolledProgramId, knownAlternateStates)) {
        console.warn('Unknown state for this.currentProgram not defined', enrolledProgramId);
      };
    };
    const displayName = this.constructor.displayName;
    const viewingDetails = {
      component: displayName,
      path: window.location.pathname
    }
    const hasStartedProgram = this.currentProgram && this.currentProgram.status == 'STARTED';
    let width = showChat && menuOpened ? '100%' : '100%';
    // if(menuOpened&&!showChat||(menuOpened&&showChat)){
    //     width = '75%';
    // } else{
    //     width = '100%'
    // }
    const patient = {
      user: _.get(this.props, 'user'),
      enrolledPrograms: _.get(this.props, 'data.enrolledProgramList.data')
    };
    const path = window.location.pathname;
    // const inProfile = _.includes(path, '/single');
    const isHistory = this.props.enrolledProgramId === 'past';

    return (
      <div className='outtercontainer patient-wrapper-6188' /*onScroll={(e) => this.handleContainerScroll(e)}*/ ref={this.ref} style={{ width: width, display: 'flex', flexDirection:'column'}}>
        {isHistory ? this.renderTopButton() : ''}
        <TimerContainer displayName={displayName} category='PATIENT_PROFILE' action='REVIEW'
          patientId={patientId} canRestUUid={true} viewingDetails={viewingDetails} />
        {timerName ? null : <TimerClaimedContainer id={PATIENT_PROFILE_TIMER} patientId={patientId} />}
        <div className={`leftDiv ${showChat ? 'showChat' : 'closeChat'}`} >
          {memberDetail}
          <SmartAlertAndMTPR patientId={patientId}
                              data={patient}
                              patientLoading={this.props.patientLoading}
                              enrollmentStatus={enrollmentStatus}
                              currentProgram={this.currentProgram}
                              inProfile={true}
                              monthlyReviewData={monthlyReviewData}
                              setMonthlyReviewData={setMonthlyReviewData}
                              setSystemGenerateMonthlyReviewData={setSystemGenerateMonthlyReviewData}
                              systemGenerateMonthlyReviewData={systemGenerateMonthlyReviewData}
                              />

          {/*{*/}
          {/*hasStartedProgram ? <PatientAppointmentContainerWrapper patientId={ patientId } patientName = { patientName } patientBirthday={ patientBirthday }/> :''*/}
          {/*}*/}
          <PatientAppointmentContainerWrapper
            patient={patient}
            patientId={patientId}
            patientName={patientName}
            patientBirthday={patientBirthday}
            doctor={doctor}
            router={this.props.router}
            route={this.props.route}
            currentProgram={this.currentProgram}
            refetchUser={this.props.refetchUser}
            timerName={timerName || PATIENT_PROFILE_TIMER}
          />
          {
            ((!this.currentProgram || _.get(startedProgramsCanView, 'length') === 1) && !showDischargedPrograms) 
            ? null 
            : <div style={{
              display: 'flex',
              padding: '18px 44px',
              alignItems: 'center',
              background: 'white',
              justifyContent: 'space-between',
            }}>
              <ProgramDropDown />
              {_.get(startedProgramsCanView, 'length') > 0 ?
                null :
                <EnrollPatientContainer
                  enrollButtonText={`Enroll in New ${I18N.get('keywords.Program')}`}
                  enrollButtonProps={{
                    style: {}
                  }}
                  enrollButtonOnClick={() => {
                    // const _currentPatient = RequestCache.get('nurse/currentPatient')
                    // const patientNRIC = _currentPatient.identification && _currentPatient.identification.length > 0 ? _currentPatient.identification[0].value : [];
                    // const patientName = _currentPatient.profile.fullName
                    // // createAuditLog({action:I18N.get('auditLog.provider.patient.enrollInNewProgram.enroll_in_new_program'), patientNRIC, patientName })
                    // goPath(`/patients/${patientId}/enrolledprogram/default_`);
                  }}
                  user={_.get(patient, 'user') || {}} // user must have profile.doctor, profile.healthConditions
                  fromPlace={ENROLL_FROM.PATIENT_PROFILE}
                  onCompleteEnrollment={async () => {
                    await refetch(); // refetch enrolled program list
                    goPath(`/patients/${patient.user.id}/enrolledprogram/default`);
                  }}
                />
              }
               {/* <IHButton bsStyle="primary" label={`Enroll in New ${I18N.get('keywords.Program')}`} className="v-btn v1" onClick={enrollButtonClick} />} */}
            </div>
          }
          {
            showIncompletedEP
              ? <Tab
                  key="2"
                  currentProgram={this.currentProgram}
                  patientId={patientId}
                  lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                  consentInfo={consentInfo}
                  patientReferral={patientReferral}
                  refetchUser={refetchUser}
                  refetchPatientReferral={refetchPatientReferral}
                />
              : <EnrolledProgramInfo {...this.props} refetchEnrolledProgramList={refetch} enrolledProgramId={this.currentProgram.id}
                currentProgram={this.currentProgram} location={this.props.location}
                canRestUUid={false}
                route={this.props.route}
                tabSelected={curTab}
                updateTabSelected={this.updateTabSelected}
                programInfo={this.currentProgram}
                lightEnrolledProgramListRefetch={this.props.lightEnrolledProgramListRefetch}
                submitEnrolledProgram={this.apiEditEnrolledProgram}
                profileTabSelected={profileTabSelected}
                onInteraction={interacted}
                showChat={showChat}

                />
          }
        </div>
        {/*{*/}
        {/*channelName ? <ChatWindow chatWithIndividual={ true } channelName={channelName || ''}*/}
        {/*showProfileStickyHeader={showStickyHeader} showChat={showChat} setChatStatus={ this.setChatStatus }/>*/}
        {/*:''*/}
        {/*}*/}
        {/* {
          _.get(this, 'currentProgram.id') && this.currentProgram.status != 'DISCHARGED' ?
              <ChatWindow chatWithIndividual={true} channelName={channelName || ''} getChatInfo={this.getChatInfo}
            showProfileStickyHeader={showStickyHeader} showChat={showChat} setChatStatus={this.setChatStatus}
            patientId={patientId}
          /> : ''
        } */}
        <ChatContainerWrapper 
          programStatus={_.get(this, 'currentProgram.status')}
          patientId={patientId}
          userInfo={patient.user}
          currentProgram={_.get(this,'currentProgram')}
          showProfileStickyHeader={showStickyHeader}
          // showChat={showChat}
          syncShowChat={this.setChatStatus}
          chatWithIndividual
          onInteraction={() => {
            if (interacted) {
              interacted(EVENT_TYPES.CONVERSATION);
            }
          }}
        />
        {/* {this.renderCallWindow()} */}
      </div>
    );
  }
  callPhone = (phoneNumber, username) => {
    /**
     * callArgs
     * {
     *      phoneNumber
     *      username
     *      status    0:connecting    1:connected    2:ended     3:incoming    4:accepted    5:destroy    6:acw   7:close
     *      duration   min
     *      date
     *      showChat
     * }
     */
    this.setState({ callPhoneNumber: phoneNumber, callUserName: username, callStatus: 0 });
  }

  updateCallStatus = (status, duration, date) => {
    let owerState = {
      callStatus: status, callDuration: this.state.callDuration, callDate: this.state.callDate
    }
    if (date) {
      owerState.callDate = date;
    }
    if (duration) {
      owerState.callDuration = duration;
    }
    this.setState(owerState);
  }

  /**
   * close Call Box
   */
  // closeCallBox = () => {
  //   this.setState({ callStatus: 7 });
  // }

  // renderCallWindow() {
  //   const { showChat, callPhoneNumber, callUserName, callStatus, callDuration, callDate } = this.state;
  //   const callArgs = {
  //     showChat: showChat,
  //     phoneNumber: callPhoneNumber,
  //     username: callUserName,
  //     status: callStatus,
  //     duration: callDuration,
  //     date: callDate
  //   }

  //   return <CallWindow callArgs={callArgs} close={() => { this.closeCallBox() }} />;
  // }
};

const mapState = (state) => {
  const params = getRouterParam(state);
  return {
    ...params,
    menuOpened: state.Nav.menuOpened,
    providerNotesModalIsVisible: state.AWSConnect.providerNotesModalIsVisible,
    contactStatus: state.AWSConnect.contact,
    isInChat: state.patient.enrolledProgramInfo.EnrolledProgramInfo.isInChat
  };
};
const mapDispatch = (dispatch, ownProps) => {
  const { timerName = PATIENT_PROFILE_TIMER, patientId, params } = ownProps;
  const userId = patientId || _.get(params, 'patientId');
  return {
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openAlertModal: (opts) => dispatch(modalAction.alert(opts)),
    setInitDeviceData: (table, value) => dispatch(actions.setInitDeviceData(table, value)),
    openModal: (content, row) => dispatch(openModal(content, row)),
    closeModal: () => dispatch(closeModal()),
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    setChat: (isInChat) => dispatch(setChat(isInChat)),
    setResuming,
    interacted: mapDispatchToTimerActions(dispatch, timerName, userId).interacted
  }
};

const enhance = compose(
  wrapGQLData2,
  wrapDischargeGQLData,
  wrapResumeGQLData,
  withProps(() => {
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const viewerId = viewerInfo ? R.last(R.split(':', atob(viewerInfo.id))) : null;
    return { viewerInfo, viewerId }
  }),
  graphql(patientGQL, {
    options: ({ patientId }) => ({
      variables: { id: patientId },
      fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, data: { loading, user,refetch } }) => ({
      patientLoading: loading,
      patientName: loading ? '' : R.path(['profile', 'fullName'], user),
      patientGender: loading ? '' : R.path(['profile', 'gender'], user),
      patientBirthday: loading ? '' : R.path(['profile', 'birthday'], user),
      doctor: loading ? '' : R.path(['profile', 'doctor'], user),
      user: loading ? '':  user,
      refetchUser:refetch
    }),
  }),
  graphql(Query.getPatientConsent, {
    options:(ownProps)=> ({
      variables: {
        memberId: ownProps.patientId
      },
      fetchPolicy:'network-only'
    }),
    props:({data})=>{
      const { getPatientConsent,refetch } = data;
      return {
        consentInfo:getPatientConsent||{},
        refetchConsentInfo:refetch
      };
    }
  }),
  graphql(getPatientHiatusQuery, {
    options: (ownProps)=> ({
      variables: {
        memberId: ownProps.patientId
      },
      fetchPolicy:'network-only'
    }),
    props:({data})=>{
      const { loading, getPatientHiatusStatus, refetch, error } = data;
      if(error)
        console.error(error);
        
      return {
        hiatusStatus: loading ? {} : (getPatientHiatusStatus || {}),
        refetchHiatusStatus: refetch
      };
    }
  }),
  graphql(patientReferralMini,{
    options:(ownProps)=>{
        const patientId = ownProps.patientId;
        return {
            variables: {
                patientId
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props:({data})=>{
        const { getPatientReferral, refetch } = data;
        return {
            patientReferral: getPatientReferral,
            refetchPatientReferral: refetch
        }
    }
  })
);

export default createContainer(enhance(ProviderViewPatientContainer), mapState, mapDispatch);
