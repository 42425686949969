import convertor from '@ihealth/convertor';

/*
  Returns appropriate unit, based on environment variable
*/
export const convertUnits = (env) => {
  switch (env) {
    case 'VSM_US':
      return window.VSM_CONFIG[env];
    default:
      return null;
  }
};

export const convertInchesToFeet = inches => {
  return Math.floor(inches / 12);
};

export const getRemainderAfterConvertingToFeet = inches => {
  return Math.round(inches % 12);
};

export const convertFeetToInches = ft => {
  return ft ? ft * 12 : 0;
};

export const convertBG_mmolTomgdl = mmol => {
  return convertor.BG(Number(mmol), 'mmol/l', 'mg/dl', 0);
};

export const convertBG_mgdlTommol = mgdl => {
  return convertor.BG(Number(mgdl), 'mg/dl', 'mmol/l', 10);
}

export const convertKgToPound = kg =>  convertor.HS(Number(kg),'kg','lbs',0);

export const convertCelsiusToFahrenheit = c => {
  return convertor.temperature(Number(c), 'c', 'f');
}

export const convertFahrenheitToCelsius = f => {
  return convertor.temperature(Number(f), 'f', 'c');
}
