import { React, BaseComponent, IHButton } from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import {goPath} from 'libModule/utils'
import {Button, Tooltip} from 'antd'

const Component = class extends BaseComponent{
  static displayName = 'provider/ProviderShow/components/Detail'

  openEditPhoto(){
    this.props.openEditPhoto();
  }

  render(){
    this.user = this.props.data
    this.userObject = new UserClass(this.user)
    return (
      <div className="v-provider-profileDetail">
        {this.renderBox1()}
        <div style={{display: 'flex'}}>
        {/*{this.renderBox2()}*/}
        {this.renderBox3()}
        </div>
      </div>
    )
  }

  renderBox1(){
    const b1 = {
      label : 'Edit Profile',
      size : 'large',
      type : 'primary',
      onClick : ()=>{
        goPath(`/providers/profile/${this.user.id}/edit`)
      }
    };
    const b2 = {
      label: 'Resend Account Activation E-Mail',
      size: 'medium',
      type: 'link',
      style: {
        color: '#ff8932'
      },
      onClick: this.props.resendActivationEmail
    };

    return (
      <div className="v-box-1">
        <img src={this.userObject.format('avator')} onClick={()=>this.openEditPhoto()} />
        <div>
          <div className="c-n1">
            <IHButton style={{ float: 'right' }} {...b1} />
            <h2>{this.userObject._get('profile.fullName')}</h2>
          </div>
          <p className="c-n2">
            <span className="c-tag">{this.userObject.format('employeeId')}    |  </span>
            <span className="c-tag">{this.userObject.format('loginID')}    |  </span>
            <span className="c-tag">{this.userObject.format('role')}</span>
          </p>
          {
            // To Fix: bring back the resend activation email button when backend supports the resendOnboardEmail API
            // <p className="c-n3">
            //   <IHButton {...b2} />
            // </p>
          }
        </div>
      </div>
    )
  }
  renderContactInfo() {
    return (
      <div className="ea">
        <h4>Contact Information</h4>
        <p className="provider-info-block">
          <b>Mobile</b>
          <span>{ this.userObject.format('mobilePhone') }</span>
        </p>
        <p className="provider-info-block">
          <b>E-Mail</b>
          <span>{ this.userObject.format('email') }</span>
        </p>
      </div>
    )
  }
  renderPlacement() {
    return (
      <div className="ea">
        <h4>Placement</h4>
        <p className="provider-info-block">
          <b>Institution</b>
          <span>{ this.userObject.format('institution') }</span>
        </p>
        <p className="provider-info-block">
          <b>Department</b>
          <span>{ this.userObject.format('department') }</span>
        </p>
        <p className="provider-info-block">
          <b>Specialty</b>
          <span>{ this.userObject.format('specialty') }</span>
        </p>

      </div>
    )
  }

  // 8/31/17 - JV - Deprecated, merged to edit form instead, per UX direction
  // renderBox2(){
  //   const mobileIconButton = {
  //     icon : 'edit',
  //     shape : 'circle',
  //     size : 'small',
  //     style : {
  //       marginLeft : '10px'
  //     },
  //     onClick : this.props.editProviderPhone
  //   }
  //
  //   const emailIconButton = {
  //     icon : 'edit',
  //     shape : 'circle',
  //     size : 'small',
  //     style : {
  //       marginLeft : '10px'
  //     },
  //     onClick : this.props.editProviderEmail
  //   }
  //   return (
  //     <div className="v-box-2" style={{marginRight: '4em'}}>
  //       <p className="c-tlt">Contact Info</p>
  //       <div className="c-flex c-m">
  //         <div className="ea">
  //           <b>Mobile</b>
  //           <span>{this.userObject.format('mobilePhone')}
  //             <Tooltip placement="rightTop" title="Click to edit mobile number">
  //               <Button {...mobileIconButton} />
  //             </Tooltip>
  //           </span>
  //         </div>
  //         <div className="ea">
  //           <b>Email</b>
  //           <span>{this.userObject.format('email')}
  //             <Tooltip placement="rightTop" title="Click to edit email address">
  //               <Button {...emailIconButton} />
  //             </Tooltip>
  //           </span>
  //         </div>
  //       </div>
  //
  //     </div>
  //   )
  // }

  renderBox3(){
    return (
      <div className="v-box-2">
        <p className="c-tlt">Contact Information</p>
        <div className="c-flex c-m">
          { this.renderContactInfo() }
          { this.renderPlacement() }
        </div>
      </div>
    )
  }
}

export default Component
