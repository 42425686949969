import React, { useMemo, useCallback } from 'react';

import { ITEM_TYPE_RENDER_MAP, ITEM_TYPE_DATA_GETTER } from '../../../constant/signOffConstants';
import { parseTaskItems } from '../../../helper/signOffHelper';
import '../../../style/signOffItem.scss';

const TaskItem = (props) => {
  const { data, children } = props;
  if(_.isEmpty(data)) {
    return null;
  }
  return (
    <li>
      {children}
    </li>
  );
};

const SignOffItemList = (props) => {
  const { patientTask } = props;
  
  const healthCoachId = _.get(patientTask, 'healthCoach.id');
  const taskItems = _.get(patientTask, 'tasks') || [];
  const itemList = useMemo(() => parseTaskItems(taskItems), [taskItems]);
  const renderItemList = useCallback(() => _.map(itemList, item => {
    const { itemType, data } = item;
    const ItemComponent = ITEM_TYPE_RENDER_MAP[itemType];
    if(!ItemComponent)
      return null;

    ItemComponent.displayName = `ItemComponent.${itemType}`;
    const dataGetter = ITEM_TYPE_DATA_GETTER[itemType] || ITEM_TYPE_DATA_GETTER['DEFAULT'];
    const itemData = dataGetter(data);
    return <TaskItem key={itemType} data={itemData}>
      <ItemComponent key={itemType} data={itemData} healthCoachId={healthCoachId} />
    </TaskItem>;
  }), [itemList]);
  
  const totalItemCount = itemList.reduce((curCount, b) => curCount + (b ? (Array.isArray(b.data) ? b.data.length : 1) : 0), 0);

  return <div id='signOffItemList'>
    <div>
      <div className='totalText'>
        {totalItemCount} Item{totalItemCount > 0 ? 's' : ''} total
      </div>
    </div>
    <div className={`viewDETAIL itemsContainer`}>
      <ol>
        { renderItemList() }
      </ol>
    </div>
  </div>;
}

export default SignOffItemList;