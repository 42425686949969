import gql from 'graphql-tag'
import {UserMini} from 'graphqlModule/schema/User'

export default gql `
  mutation deleteCaregiverForMember (
    $id: EID!
    $caregivers: [EID]
  ) {
    deleteCaregiverForMember (
      id: $id
      caregivers: $caregivers
    ) { 
      ${UserMini}
    }
  }
`
