import gql from 'graphql-tag';

 export default gql`
                query getFoodLog($id:EID!) {
  getFoodLog(id:$id) {
    id
    member {
      id
      profile {
        fullName
        fullNameWithNickname
      }
    }
    mealType
    note
    images{
        link
    }
    createdAt
    uploadedAt
    rating {
      star
      category {
        carb
        fat
        protein
        vegetable
        fruit
      }
    }
    vitalTypes
    comments {
      text
      commentedBy {
        profile {
          fullName
        }
      }
      commentedAt
    }
  }
}
`
