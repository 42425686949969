import React from 'react';
import {Form, Select, Row, Col, DatePicker, Button, Input, Radio, Tooltip, Icon, message} from 'antd';
import { helpers } from '../../visit/helpers';
import I18N from 'modulesAll/I18N';
import visitMap from '../../visitWorkList/constant/visitConstMap';
import { API } from '../../visit/query/index';
import moment from 'moment';
import VisitPersonHeader from '../../visitNewWorkFlow/components/VisitPersonHeader';
import {helpers as dataHelper} from "../../visitNewWorkFlow/helper/index";
import { getVisitRoles, getVisitTypeOptions } from 'libModule/utils'
import { EVENT_TYPES } from '../../idleTime/constant';
import { compose } from 'react-apollo';
import { isPatientEnrolled } from '../../patient/AdditionalInfo/helpers';
import { getEnrolledCurrentProgram, withEnrolledCurrentProgram } from '../../workStation/API';
import { getPatientComplexity } from '../../patient/main/API';

const FormItem = Form.Item;
const { minMap, hourMap,hourMapIn1224Format,patientHoursIn1224Format } = visitMap;
const { Option } = Select;
const { createVisit, rescheduleVisit } = API;
const disabledDate = current => {
    //sc-3671 Create/edit visit disable Sunday, and support Schedule on the past time
    const week = moment(current).day();
    if(week === 0){
        // || week === 6){
        return current;
    }
};

let CreateFollowUpComponent = class extends React.Component {
    constructor(props) {
        super(props);
        const followUpVisit = _.get(props, 'associatedVisit', {});
        let visitStartAt = _.get(followUpVisit,'appointmentAt');
        let localAMOrPM = null;
        let remoteAMOrPM = null;
        let selectedHour = null;
        let selectedMin = '00';
        if( visitStartAt ) {
            let visitStartAtDate = new Date(visitStartAt);
            selectedHour = dataHelper.localToRemoteHour(visitStartAtDate.getHours());
            selectedMin = visitStartAtDate.getMinutes();
            if(selectedMin < 10) {
              selectedMin = '0' + selectedMin;
            }
            localAMOrPM =  visitStartAtDate.getHours() >= 12 ? 'PM':'AM';
            remoteAMOrPM = selectedHour >= 12 ? 'PM':'AM';
        }

        this.state = {
            selectedHour:selectedHour > 12 ? selectedHour - 12 : selectedHour ,
            selectedMin,
            localAMOrPM,
            remoteAMOrPM
        }
    }

    componentDidMount() {
        this.getPatientComplexityAndEnrollment();
    }

    getPatientComplexityAndEnrollment = async () => {
        const memberId = _.get(this.props, 'currentVisit.member.id');
        const patientComplexity = await getPatientComplexity({ memberId });
        const currentProgram = await getEnrolledCurrentProgram(memberId);
        this.setState({ patientComplexity, currentProgram });
    }

    onChangHour = (val,localToRemote)=>{
        const {localToRemoteHour,remoteToLocalHour } = dataHelper;
        let hourValue = (!localToRemote ? remoteToLocalHour(val) : localToRemoteHour(val));
        // let localHour =  !localToRemote ? remoteToLocalHour(val) : val;
        // let remoteHour = localToRemote ? localToRemoteHour(val) : val
        let key = !localToRemote ? 'hour' : 'remoteHour';

        this.props.form.setFieldsValue({
            [key]: hourValue > 12 ? hourValue - 12 : hourValue,
            // amOrpm: hourValue >= 12 ? 'PM' : 'AM'
        });
        let { remoteAMOrPM,localAMOrPM }  = this.state;

        if(!localToRemote) {
            remoteAMOrPM = val >= 12 ? 'PM' : 'AM';
            localAMOrPM = hourValue >= 12 ? 'PM' : 'AM';
        }else{
            remoteAMOrPM = hourValue >= 12 ? 'PM' : 'AM';
            localAMOrPM = val >= 12 ? 'PM' : 'AM';
        }
        this.setState({
            remoteAMOrPM,
            localAMOrPM
        })
    }

    onChangeMin = (val)=>{
        this.props.form.setFieldsValue({
            min:val,
            remoteMin:val
        })
    }

    renderForm = () => {
        const followUpVisit = _.get(this, 'props.associatedVisit');
        const { getFieldDecorator } = this.props.form;
        const doctorOptions = helpers.filterDoctors();
        const { hasLoanDevice, patientComplexity } = this.props;
        const { selectedHour, selectedMin,localAMOrPM,remoteAMOrPM } = this.state;
        const hasLoanPhoneIndicator = <Tooltip title={I18N.get('visit.tips.loanerPhoneUser')}><Icon type="info-circle" /></Tooltip>;
        const defaultVisitType = hasLoanDevice ? 'INCLINIC' :'VIRTUAL';
        const rowStyle = {
            marginLeft: '-10px',
            width: '40px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '26px',
            textAlign: 'center'
        }
        const itemList = [
            [

                {
                    key: 'provider',
                    label: I18N.get('provider.required.label'),
                    render: () => (
                        <Select filterOption={false}>
                            {_.map(doctorOptions, program => {
                                return (
                                    <Option value={program.id} key={program.id}>
                                        {program.fullName}
                                    </Option>
                                );
                            })}
                        </Select>
                    ),
                    rules: [{ required: true, message: I18N.get('provider.required.message') }],
                    initialValue: _.get(followUpVisit, 'providers.0.id'),
                    span: 12
                },
                {
                    key:'category',
                    initialValue: _.get(followUpVisit, 'category', defaultVisitType),
                    rules:[{required:true}],
                    render:()=><Radio.Group>
                        <Radio key='VIRTUAL' value='VIRTUAL' disabled={hasLoanDevice}>Virtual{ hasLoanDevice&&hasLoanPhoneIndicator }</Radio>
                        <Radio value={'INCLINIC'}>Clinic</Radio>
                    </Radio.Group>,
                    span:12
                }
                ],
                [
                {
                    key: 'visitRoles',
                    label: I18N.get('visit.details.visitRoles.required.label'),
                    render: () => (
                        <Select mode='multiple'>
                            {_.map(dataHelper.VISIT_ROLES, (type, val) => {
                                return (
                                    <Option value={val} key={val}>
                                        {type}
                                    </Option>
                                );
                            })}
                        </Select>
                    ),
                    rules: [
                        { required: true, message: I18N.get('visit.details.visitRoles.required.message') }
                    ],
                    initialValue: _.get(followUpVisit, 'visitRoles') ||
                    getVisitRoles(_.get(followUpVisit, 'type', 'FOLLOW_UP'),
                                  _.get(followUpVisit, 'category', defaultVisitType), patientComplexity),
                    span: 12
                },
                {
                    key: 'type',
                    label: I18N.get('visit.details.visitType'),
                    type: 'radioButton',
                    className: 'radio-button add_medicine_header',
                    required: true,
                    rules: [{ required: true }],
                    initialValue: followUpVisit ? _.get(followUpVisit, 'type') : 'FOLLOW_UP',
                    render: () => (
                        <Select>
                            {_.map(getVisitTypeOptions(), (type, val) => {
                                return (
                                    <Option value={val} key={val}>
                                        {type}
                                    </Option>
                                );
                            })}
                        </Select>
                    ),
                    span: 12
                }
            ],
            [
                {
                    key: 'date',
                    label: I18N.get('visit.details.visitDate.required.label'),
                    render: () => <DatePicker allowClear={false} disabledDate={disabledDate} />,
                    rules: [{ required: true, message: I18N.get('visit.details.visitDate.required.message') }],
                    initialValue: moment(_.get(followUpVisit, 'appointmentAt')),
                    span: 8
                },
                {
                    key: 'hour',
                    label: I18N.get('visit.details.visitTime.required.label'),
                    render: () => (
                        <Select onChange={(hour)=>this.onChangHour(hour,dataHelper.localToRemoteHour)}>
                            {_.map(hourMapIn1224Format, h => (
                                <Option value={h['24hour']} key={h['24hour']}>{h['12hour']}</Option>
                            ))}
                        </Select>
                    ),
                    rules: [{ required: true, message: I18N.get('visit.details.visitTime.required.message') }],
                    initialValue: _.get(followUpVisit, 'appointmentAt')
                        ? moment(_.get(followUpVisit, 'appointmentAt')).format('H')
                        : null,
                    span: 7
                },
                {
                    key: 'min',
                    label: 'Min',
                    render: () => (
                        <Select  onChange={this.onChangeMin}>
                            {_.map(minMap, m => (
                                <Option value={m} key={m}>{m}</Option>
                            ))}
                        </Select>
                    ),
                    initialValue: _.get(followUpVisit, 'appointmentAt')
                        ? moment(_.get(followUpVisit, 'appointmentAt')).format('mm')
                        : minMap[0],
                    rules: [{ required: true }],
                    span: 7
                },
                {
                    key: 'localAMOrPM',
                    render: () =>  localAMOrPM ? <div className={'ampmDiv'} style={rowStyle}>{localAMOrPM}</div>:<div></div>,
                    span:4
                }
            ],
            [
                {
                    key:'placeholder',
                    span:8,
                    render:()=><div></div>
                },
                {
                    key: 'remoteHour',
                    label: I18N.get('visit.details.visitPatientTime.required.label'),
                    render: () => (
                        <Select onChange={(hour)=>this.onChangHour(hour)}>
                            {_.map(patientHoursIn1224Format, (h, i) => (
                                <Option value={h['24hour']} key={i}>{h['12hour']}</Option>
                            ))}
                        </Select>
                    ),
                    rules: [{ required: true, message: I18N.get('visit.details.visitPatientTime.required.message') }],
                    initialValue: selectedHour,
                    span: 7
                },
                {
                    key: 'remoteMin',
                    label:' ',
                    // rules: [{ required: true }],
                    render: () => (
                        <Select onChange={this.onChangeMin}>
                            {_.map(minMap, (h, i) => (
                                <Option value={h} key={i}>{h}</Option>
                            ))}
                        </Select>
                    ),
                    initialValue: _.toString(selectedMin),
                    span: 7
                },
                {
                    key: 'remoteAMOrPM',
                    render: () => remoteAMOrPM ? <div className={'ampmDiv'} style={rowStyle}>{remoteAMOrPM}</div>:<div></div>,
                    span:4
                }
            ],
            [
                {
                    key: 'notes',
                    label: I18N.get('visit.details.visitNote'),
                    span: 24,
                    initialValue: _.get(followUpVisit, 'reason')
                }
            ]
        ];
        return _.map(itemList, (arr, i) => (
            <Row key={i} style={{ display: 'flex' }} gutter={20}>
                {_.map(arr, (value, index) => {
                    return (
                        <Col
                            key={i * 10 + index}
                            span={value.span || 8}
                            style={{ display: value.display }}
                        >
                            <FormItem label={value.label} key={value.key}>
                                {getFieldDecorator(value.key, {
                                    rules: value.rules,
                                    initialValue: value.initialValue,
                                    valuePropName: value.valuePropName || 'value'
                                })(value.render ? value.render(value) : <Input />)}
                            </FormItem>
                        </Col>
                    );
                })}
            </Row>
        ));
    };

    combineDateHourAndMin = (date, hour, min) => {
        return moment(date)
            .hour(hour)
            .minute(min);
    };

    saveEdit = () => {
        const { currentVisit,setCreateFollowUp,setCurrentVisit,setAssociatedVisit,onInteraction } = this.props;
        const { getFieldsValue } = this.props.form;
        const visitId = _.get(currentVisit, 'id');
        const userId = _.get(currentVisit,'member.id');
        const followUpVisit = _.get(this,'props.associatedVisit');
        let {
            date,
            min,
            hour,
            notes,
            visitRoles,
            type,
            provider,
            category
        } = getFieldsValue();
        let variables = {
            memberId: userId,
            type: type,
            appointmentAt: this.combineDateHourAndMin(
                date,
                parseInt(hour),
                parseInt(min)
            ),
            appointmentTo: this.combineDateHourAndMin(
                date,
                parseInt(hour),
                parseInt(min + 30)
            ),
            providers: [provider],
            reason: notes,
            visitRoles: visitRoles,
            category
        };
        if (!followUpVisit) {
            createVisit(variables).then(res => {
                const associatedVisitId = _.get(res, 'data.createVisit.id');
                if (onInteraction) onInteraction(EVENT_TYPES.CREATE_VISIT);
                rescheduleVisit({
                    id: visitId,
                    associatedVisitId: associatedVisitId
                }).then(res => {
                    message.success('Follow Visit is Scheduled');
                    if(setAssociatedVisit) {
                        setAssociatedVisit(res.data.editVisit.associatedVisit);
                    }
                });
            });
        } else {
            variables.id = followUpVisit.id;
            rescheduleVisit(variables).then(res => {
                message.success('Follow Visit is Rescheduled');
                if (onInteraction) onInteraction(EVENT_TYPES.EDIT_VISIT);
                if(setAssociatedVisit) {
                    res.data.editVisit.type = type;
                    setAssociatedVisit(res.data.editVisit);
                }
            });
        }
        setCreateFollowUp(false)

    };

    handleClick = () => {
        const { form } = this.props;
        form.validateFieldsAndScroll((error, res) => {
            if (!error) {
                this.saveEdit();
            }
        });
    };

    render() {
        const { props } = this;
        const { user } = props;
        const { patientComplexity, currentProgram } = this.state;
        const followUpVisit = _.get(props,'associatedVisit');

        return (
            <div style={{ display:'flex',flexDirection:'column'}}>
                <Form className='additionInfoContentForm videoPatientForm'>
                    <VisitPersonHeader 
                        patient={{user,enrolledPrograms:[]}}
                        patientComplexity={patientComplexity}
                        isEnrolled={isPatientEnrolled(currentProgram)}
                    />
                    {this.renderForm()}
                </Form>
                <Button onClick={()=>this.handleClick()} style={{ width:'fit-content',marginLeft:'auto' }}>{ followUpVisit ? 'Save' : 'Schedule' }</Button>
            </div>
        );
    }
};

CreateFollowUpComponent = Form.create({
  onValuesChange: (props, changedValues, allValues) => {
    const { form, associatedVisit,patientComplexity } = props;
    const { type, category } = changedValues;
    if(form && (type || category)) {
      // current values of type and category
      const visitType = _.get(allValues, 'type');
      const visitCategory = _.get(allValues, 'category');
      const visitRoles = getVisitRoles(visitType, visitCategory, patientComplexity, associatedVisit);

      form.setFieldsValue({ visitRoles });
    }
  }
})(CreateFollowUpComponent);

export default CreateFollowUpComponent;
