import React from 'react';
import types from '../constants/PatientTaskListConstants';
import { commonActions } from 'libModule/helpers/action-helpers';
import { openModal } from 'layoutModule/actions/MainModal'
import { browserHistory } from 'react-router';
import BPManualInputContainer from 'modulesAll/patientView/manualInput/containers/BPInputContainer'
import HSManualInputContainer from 'modulesAll/patientView/manualInput/containers/HSInputContainer'
import BGManualInputContainer from 'modulesAll/patientView/manualInput/containers/BGInputContainer'
import HRManualInputContainer from 'modulesAll/patientView/manualInput/containers/HRInputContainer'
import TMManualInputContainer from 'modulesAll/patientView/manualInput/containers/TMInputContainer'
import POManualInputContainer from 'modulesAll/patientView/manualInput/containers/POInputContainer'

const onRowClick = (props) => {
  //console.log('onRowClick, props', props)
  return (dispatch) => {
  }
}

const addReading = (row) => {
  console.log('addReading', row)
  const { refId, schedule, assessment, assessmentFilled, enrolledProgram } = row
  const { beforeMeal, mealType } = schedule || {}
  const modalProps = {
    refId,
    beforeMeal,
    mealType,
    enrolledProgramName: enrolledProgram.name
  }
  return (dispatch) => {
    dispatch({
      type: types.SET_REF_ID,
      refId
    })
    const param = {
      size : 'md',
      title : 'Add New Reading',
      showHeaderCloseButton: true,
      className: 'vsm-manual-input-modal'
    }
    let url

    if(row.type === "BP") {
      dispatch(openModal(<BPManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === "BG") {
      dispatch(openModal(<BGManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === "HS") {
      dispatch(openModal(<HSManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === "HR") {
      dispatch(openModal(<HRManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === "TM") {
      dispatch(openModal(<TMManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === "PO") {
      dispatch(openModal(<POManualInputContainer {...modalProps} />, param))
    }
    else if(row.type === 'ASSESSMENT') {
      if(assessmentFilled) {
        url = `/update_assessment_filled/${_.get(assessmentFilled, '_id')}?backPath=myTasks`
      }
      else {
        url = `/create_assessment_filled/${_.get(assessment, '_id')}/${refId}?backPath=myTasks`
      }
      browserHistory.push(url)
    }
    else if(row.type === 'SURVEY') {
      if(assessmentFilled) {
        url = `/update_survey_filled/${_.get(assessmentFilled, '_id')}?backPath=myTasks`
      }
      else {
        url = `/create_survey_filled/${_.get(assessment, '_id')}/${refId}?backPath=myTasks`
      }
      browserHistory.push(url)
    }
  }
}

export default {
  ...commonActions(types, ['sort', 'changePage']),
  onRowClick,
  addReading,
}
