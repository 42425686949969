import { graphql } from 'react-apollo'
import { createContainer, GQLHelper } from 'libModule/utils'
import { _ } from 'ihcomponent'
import createTableAction from 'libModule/table/TableActions';
import { openErrorModal } from 'modulesAll/layout/actions/MainModal'
import action from '../actions'
import TodoListAction from 'modulesAll/providerTask/TaskList/actions'
import ToDoListComponent from '../components/ToDoListComponent'

// graphql
import todoListGQL from 'modulesAll/graphql/todoList'
import editTodoGQL from 'modulesAll/graphql/editTodo'

const tableActions = createTableAction('provider_todo_list_forDashboard', {
  sortFieldMap: {
    dueDate: 'DUE_DATE'
  }
});


const mapState = (state, ownProps)=>{
  return {
    ...state.dashboard.main.Todo,
    ...state.globalTables.provider_todo_list_forDashboard,
  }
}

const mapDispatch = (dispatch)=>{
  return {
    ...tableActions,
    showModal : (show, select)=>{
      dispatch(TodoListAction.showModal(show, select))
    },
    openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
    reset : () => dispatch(action.reset())
  }
}

export default createContainer(GQLHelper.wrapper([
  graphql(todoListGQL, {
    options : (ownProps)=>{
      const field = _.get(ownProps, 'sort.fieldGraph')
      const direction = _.get(ownProps, 'sort.direction')
      const sort = field && direction && { field, direction } || null

      return {
        variables : {
          page: _.get(ownProps, 'page.current', 1),
          count : 5,
          options: { completed: false },
          sort,
        },
        notifyOnNetworkStatusChange: true,
      }
    },
    props : ({data})=>{
      return {
        loading : data.loading,
        list : data.todoList,
        refetch : data.refetch
      }
    }
  }),
  graphql(editTodoGQL, {name : 'editTodoGQL'})
], ToDoListComponent), mapState, mapDispatch)
