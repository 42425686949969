import gql from 'graphql-tag'

export default gql `
  mutation deleteTeam (
    $id: EID!
  ) {
    deleteTeam (
      id: $id
    )
  }
`
