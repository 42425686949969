import React from 'react';
import { Row,Col,Button } from 'antd';
import { roleToBtn,btnConfigMap } from '../constant/roleToBtn';
import { decryptRole } from 'libModule/utils';
import ReferralAndEnrollContainer from '../container/index';
import { STATUS_ENUM } from '../constant';

const ReferAndEnrollButtonWrapper = class extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			modalType: null,
			enrollStatus: null,
		};
	}

	setShowModal = (showModal) => {
		this.setState({ showModal });
		const { enrollStatus } = this.state;
		const { onCompleteEnrollment } = this.props;
		const isEnrolled = (enrollStatus === STATUS_ENUM.COMPLETED);
		if(showModal == false && isEnrolled && onCompleteEnrollment){
			onCompleteEnrollment();
		}
	}

	setEnrollStatus = (enrollStatus) => {
		this.setState({ enrollStatus })
	}

	onClick = (modalType) => () => {
		this.setState({ showModal: true, modalType });
	}

	showReferModal = () => {
		const { showModal, modalType } = this.state;
		const { memberId, refetchQuery } = this.props;
		return showModal &&
			     <ReferralAndEnrollContainer 
            visible={showModal} 
            setShowModal={this.setShowModal} 
            type={modalType} 
            memberId={memberId} 
            refetchQuery={refetchQuery} 
            setEnrollStatus={this.setEnrollStatus}
          /> 
	}

	showReferButton = (btnType)=>{
		const currentRole = decryptRole();
		const { patientReferral } = this.props;
		const { showReferButton } = roleToBtn(currentRole);
		const btnConfig = _.get(btnConfigMap(patientReferral),`${btnType}.referBtn`);
		return showReferButton && 
           <Button 
            onClick={this.onClick('REFERRAL')} 
            type={btnConfig.type} 
            style={btnConfig.style}
          >
            {btnConfig.text}
          </Button>
		// <ReferButtonComponent refetchQuery={refetchQuery} btnConfig={btnConfig} closeModal={closeModal} memberId={memberId}/>
	}

	showEnrollButton = (btnType)=>{
		const currentRole = decryptRole();
		const { enrollStatus,referStatus,patientReferral } = this.props;
		const { showEnrollButton } = roleToBtn(currentRole);
		const btnConfig = _.get(btnConfigMap(patientReferral),`${btnType}.enrollBtn`);
		const isReferred = referStatus === STATUS_ENUM.COMPLETED;
		return showEnrollButton && enrollStatus !== STATUS_ENUM.COMPLETED && 
            <Button 
              onClick={this.onClick(isReferred ? 'REFERRED_ENROLL' : 'ENROLL')} 
              type={btnConfig.type} 
              style={btnConfig.style}
            >
              {btnConfig.text}
            </Button>;
	}

	render() {
		const { showEnrollButton,showReferButton } = this;
		const { btnType,patientReferral } = this.props;
		const style = _.get(btnConfigMap(patientReferral),`${btnType}.style`);
		const {enrollBtnWrapper,referBtnWrapper } = _.get(btnConfigMap(patientReferral),`${btnType}`);
		return <Row style={style}>
				{this.showReferModal()}
				<Col style={enrollBtnWrapper}>
					{ showEnrollButton(btnType) }
				</Col>
				<Col style={ referBtnWrapper }>
					{ showReferButton(btnType) }
				</Col>
			  </Row>

	}
}

export default ReferAndEnrollButtonWrapper;