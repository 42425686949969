import { browserHistory } from 'react-router';
import getClinicGoalTemplates from '../../graphql/getClinicGoalTemplates';
import { getMonthlyReviewContent } from '../../graphql/getMonthlyReviewContent';
import { enrolledProgramListForWorkstation } from "../../graphql/enrolledProgramList";
import getMonthlyReviewSummary from "../../graphql/getMonthlyReviewSummary";

// for refetch across the portal
const getEnrolledProgramListQuery = (ownProps) => {
  const memberId = _.get(ownProps, 'user.id') || _.get(ownProps, 'params.id') || _.get(ownProps, 'patientId');
  return {
    query: enrolledProgramListForWorkstation,
    variables: {
      page: 1,
      count: 1,
      filters: { memberId },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };
};

const getGoalOptionsQuery = (ownProps) => {
  const healthConditions = _.get(ownProps, 'user.profile.healthConditions') || [];
  const codes = [];
  _.map(healthConditions, con => {
    const array = _.split(con, '::');
    if(array && array[1] && !_.includes(codes, array[1])){
      codes.push(array[1]);
    }
  });
  return {
    query: getClinicGoalTemplates,
    variables: { codes },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }
};

const getMonthlyReviewContentsQuery = (ownProps) => {
  const memberId = _.get(ownProps, 'user.id') || _.get(ownProps, 'params.id');
  return {
    query: getMonthlyReviewContent,
    variables: { memberId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };
};

const getMonthlyReviewSummaryQuery = (ownProps) => {
  const memberId = _.get(ownProps, 'user.id') || _.get(ownProps, 'params.id');
  return {
    query: getMonthlyReviewSummary,
    variables: { memberId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };
}

const getGoalAssessmentRefetchQueries = (ownProps, needUpdateGoalTemplates = false) => {
  const isInWorkstation = browserHistory.getCurrentLocation().pathname.includes('workstation');
  if(isInWorkstation && needUpdateGoalTemplates &&!_.get(ownProps, 'user.profile.billableHealthConditions')) {
    console.warn('Failed to refetch updates for goal assessment')
    return [];
  }

  const refetchQueries = [{ ...getEnrolledProgramListQuery(ownProps), ...getMonthlyReviewContentsQuery(ownProps) }];
  
  return isInWorkstation ?  
    needUpdateGoalTemplates ? 
      refetchQueries.concat({...getGoalOptionsQuery(ownProps)}) 
      : refetchQueries
  : [];
};
// ---- END for refetch across portal

export default {
  getEnrolledProgramListQuery,
  getGoalOptionsQuery,
  getMonthlyReviewContentsQuery,
  getGoalAssessmentRefetchQueries,
  getMonthlyReviewSummaryQuery
};