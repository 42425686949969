import { combineReducers } from 'redux';
import type from '../constant/reduxType';

const INITIAL_STATE = {
  vitalsSummary: {} // { [memberId]: { [visitId]: { type: {...selections} } } }}
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  const { memberId, visitId, vitalType } = action.payload || {};
  const newVitalsSummary = {...state.vitalsSummary};

  switch(action.type) {
    case type.SET_SELECTED_SUMMARY_INTERVAL_KEY:
      const { selectedIntervalKey } = action.payload;
      _.set(newVitalsSummary, `${memberId}.${visitId}.${vitalType}.selectedIntervalKey`, selectedIntervalKey);
      return {...state, vitalsSummary: newVitalsSummary};
    case type.SET_SELECTED_SUMMARY_TIME_RANGE:
      const { selectedTimeRange } = action.payload;
      _.set(newVitalsSummary, `${memberId}.${visitId}.${vitalType}.selectedTimeRange`, selectedTimeRange);
      return {...state};
    default:
      return state;
  }
};

export default {
  visitWorkList: combineReducers({
    main: reducer
  })
}
