import React from 'react';
import PostItCardComponent from '../components/PostItCardComponent';
import { Row,Col,Button,Modal,Icon } from 'antd';
import _ from 'lodash';
import {connect} from "react-redux";
import '../style/index.scss';
import PostItCreateComponent from '../components/PostItCreateComponent';
import PostItEditComponent from '../components/PostItEditComponent';
import noteActions from '../actions';

const PostItPatientListComponent = class extends React.Component {
    constructor(){
        super();
        this.state={
            post: { },
            visible:false
        }
    }

    renderCreateButton = ()=>{
        const { setShowCreateComponent,openModal,createPostIt,contactStatus } =  this.props;
        return <Button size='small'
                       type='primary'
                       style={{ backgroundColor:'rgb(66, 120, 195)' ,border: 'unset',float:'right' }}
                       onClick={ ()=> setShowCreateComponent(true)}
                >+</Button>
    }

    setPost = (post)=>{
        this.setState({
            post
        })
    }

    render(){
        const { postItList,className,showConfirmForDeletePostIt,showCreateComponent,showEditComponent,setShowEditComponent,editPostId, setShowCreateComponent,createPostIt,patientId, callStickie, style} = this.props;
        const { post } = this.state;

        return<div className={className} style={style}>
                {!callStickie && showCreateComponent ?
                        <PostItCreateComponent createPostIt={ createPostIt }
                                               setShowCreateComponent={ setShowCreateComponent }
                                               patientId = { patientId }
                        />:''
                }
                { showEditComponent ?
                    <Modal visible={ showEditComponent } className='editPostItModal' closable={false} footer={false}>
                        <PostItEditComponent setShowEditComponent={setShowEditComponent}
                                             setNote = { this.props.setNote } note={ this.props.note } editPostId = { editPostId }
                                             setPost = { this.setPost }
                                             patientId = { patientId }
                                             post = { post }
                        />
                    </Modal> :''
                }
                <Row >
                    <Col span={12} style={{ padding: '10px 10px 0px 10px',fontWeight:'bold' }}>
                        <span style={{ fontSize:16 }}>Sticky Notes</span>
                    </Col>
                    <Col span={12} style={{ padding: '10px 20px' }}>{ this.renderCreateButton() }</Col>
                </Row>

                {_.map(postItList,(post,i)=><PostItCardComponent key={i} post={ post }
                                                                 showConfirmForDeletePostIt = { showConfirmForDeletePostIt }
                                                                 setShowEditComponent = { setShowEditComponent }
                                                                 setNote = { this.props.setNote } note={ this.props.note }
                                                                 setPost = { this.setPost }
                                                                 editPostId = { editPostId }
                />)}
              </div>
    }
}
const mapToProps = (state,props)=>{
    
    return {
        note:state.Stickie.note,
        contactStatus: state.AWSConnect.contact,
        callStickie:state.Stickie.callStickie
    }
};

const mapToDispatch = (dispatch)=>{
    return {
        setNote: flag => dispatch(noteActions.setNote(flag))
    }
};

export default connect(mapToProps,mapToDispatch)(PostItPatientListComponent);