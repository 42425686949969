import React from 'react';
import { Form, Select } from 'antd';
import { CGMHelperServices } from '../../helpers';

export const CGMDropdownFormItemComponent = ({
  form,
  deviceList,
  initialValue,
  disabled,
}) => {
  if(!form) return null;
  return (
    <Form.Item
      label={CGMHelperServices.dropdownOption.label}
    >
      {
        form.getFieldDecorator(CGMHelperServices.brandFieldName, {
          initialValue,
          rules: [{
            required: true, message: CGMHelperServices.requiredMessage
          }]
        })(
          <Select
            placeholder="Select a CGM brand"
            disabled={disabled}
          >
            {
              deviceList.map(({
                cgmBrand,
                name,
              }) => (
                <Select.Option
                  key={cgmBrand}
                  value={cgmBrand}
                >
                  {name}
                </Select.Option>
              ))
            }
          </Select>
        )
      }
    </Form.Item>
  )
};
