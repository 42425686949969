import { constants } from 'libModule/utils';

export default constants('ProgramEdit/Survey', 'SurveyList', [
  'CHANGE_PAGE',
  'ADD_ITEM_DATA',
  'INIT_DATA',
  'ON_CHECK_TABLE_ROW',
  'ON_TABLE_CHANGE',
  'SELECT_ITEM',
  'CHANGE_TASKS',
  'RESET'
])
