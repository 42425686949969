export const openConfirmModal = (localThis, missingVitals) => {

  return new Promise((resolve, rejcet) => {
      const Yes = {
        label: 'Go to Vitals & Billing',
        handler: ()=>{
          // resolve(handleRemoveTaskFromEnrolledProgram(localThis,taskId,vitalType))
          // resolve(localThis.setState({'enrolledVitals' : _.filter(localThis.state.enrolledVitals,v=>v!==vitalType) }))
          // resolve(goToVitalsAndBilling(localThis));
          localThis.onConfirm();
          goToVitalsAndBilling(localThis);
          resolve();
        },
        closeModal: true
      };
      const No = {
        label: 'Cancel',
        handler: () => {
          localThis.onConfirm();
          resolve(false)
        }
      };

      const modalProps = {
        Yes,
        No,
        title: '',
        content: content(missingVitals)
      };

      localThis.props.openConfirmModal(modalProps)

  })
}

export const goToVitalsAndBilling = (localThis) => {
  const selectedStepKey = 'vitalsAndBilling';
  const selectedStepInfo = localThis.getStepInfoWithKey(selectedStepKey);
  localThis.updateCurrentStepStatus(selectedStepKey, 'TODO');
  localThis.setState({
    selectedStepKey,
    selectedStepInfo,
    isDone: false
  })

}

export const content = (missingVitals) => `Great job completing the worklist! However, it looks like the ${missingVitals} vital does not have a related ICD code selected as billable. Go back to the ‘Vitals & Billing’ section to add one! Note: You may need to double check clinical outcome goals to be sure that these also still apply, as a new clinical outcome goal may be added.`


