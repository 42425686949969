import gql from "graphql-tag";

export default gql`
  mutation editMonthlyReview(
    $id: EID!,
    $note: String,
    $status: MonthlyReviewStatus,
    $goals: [monthlyReviewGoalInput],
    $previousVitals: [PreviousVitalsInput],
    $previousGoals: [PreviousGoalsInput],
    $preSummary: MonthlyReviewSummaryInput
  ) {
    editMonthlyReview(id: $id, note: $note, status: $status, goals: $goals, previousVitals: $previousVitals, previousGoals: $previousGoals, preSummary: $preSummary) {
      id
      memberId
      organizationId
      teamId
      type
      status
      createdAt
      monthOfYear
      updatedAt
      dueDate
      note
      goals {
        value
        status
        vital
        condition
      }
      reviewedBy { 
        id
        profile {
          fullName
        }
      }
    }
  }
`;
