// import { getMonthOfYear } from '../helpers';

export const MAX_PATIENT_PER_PAGE = 10;
export const MAX_PATIENT_IN_QUEUE = 500;

export const commonRPMPatientListFilters = {
  timeSpent: [{ min: 20 * 60 }], // 20 minutes
  programsEnrolled: ['RPM'],
  monthlyReviewDone: true,
  hasEngagement: true
};

export const commonTODORPMPatientListFilters = {
  ...commonRPMPatientListFilters,
  rpmPatientReviewStatus: 'TODO',
};


export const DEFAULT_PATIENT_LIST_SORT = {
  field: 'PATIENT_LAST_REVIEW',
  direction: 'ASC', // status: TODO => DONE
};

export const ROLES_TO_TEXT = {
  Provider: {
    startReviewBtnText: 'Start to Review',
    submitReviewBtnText: 'I\'ve reviewed this patient',
  },
  CareTeam: {
    startReviewBtnText: 'Review with Provider',
    submitReviewBtnText: 'Provider has reviewed this patient',
  },
};

export const RPM_TAB_TITLE_PLACEHOLDER = 'RPM patients need provider\'s review ([count])';

export const RPM_PATIENT_REVIEW_TIMER = 'RPM_PATIENT_REVIEW_TIMER';

export const DISPLAY_NOT_ASSIGNED_VALUE = 'Not Assigned';
export const DISPLAY_NOT_NECESSARY_VALUE = 'Not Necessary';

export const REDUX_STATE_NAME = 'rpm_review_patient_list';

export const STORAGE_KEY = 'rpmReview';
