import {_} from 'ihcomponent'
import type from '../constants'

const initialState = {
    deviceTableData : [],
    deviceTableValue : {},
    deviceTableStatusReasonSelectList : [
        {
            name : 'Patient owned',
            modal : true,
            value : 'owned'
        },
        {
            name : 'Patient will purchase',
            modal : false,
            value : 'purchase'
        }
    ],
    isHomeSetup : false,
    selectDeviceList : [
        {
            name : 'BG5',
            value : 'bg5'
        },
        {
            name : 'BG4',
            value : 'bp4'
        },
        {
            name : 'iPad Mini',
            value : 'ipadmini'
        }
    ],
    selectDeviceValue : null,

    uploadFile : {
        title : 'Loan Form',
        printButton : {
            title : 'Print out Loan Form',
            onClick : () => {
              // Fix: use real file name
              window.open('https://sg-demo.s3.amazonaws.com/upload/file/abc')
            }
        },
        uploadButton : {
            title : 'Upload Signed Loan Form'
        },
        reUploadButton : {
            title : 'Re-Upload Signed Loan Form'
        },
        file : null
    },

    currentStep : 1 //current page is table or upload form
}

export default (state=initialState, action)=>{
    let rs = {}
    switch(action.type){
        case type.CHANGE_SELECT_DEVICE:
            rs = {
                selectDeviceValue : action.value
            }
            break
        case type.CHANGE_SELECT_DEVICE_TABLE_STATUS_REASON:
        case type.CHANGE_DEVICE_TABLE_SELECTED_ROWS:
        case type.CHANGE_DEVICE_TABLE_STATUS_REASON_MODAL:
            rs = {
                deviceTableValue : action.value
            }
            break
        // case type.CHANGE_DEVICE_TABLE_STATUS_REASON_MODAL:
        //     return state
        case type.IS_HOME_SETUP:
            rs = {
                isHomeSetup : action.value
            }
            break
        case type.CHANGE_DEVICE_CURRENT_STEP:
            rs = {
                currentStep : action.value
            }
            break
        case type.SET_UPLOAD_FILE:
            rs = {
                uploadFile : {
                    file : action.file
                }
            }
            break
        case type.SET_INIT_DEVICE:
            return {
              ...state,
              deviceTableData : action.table,
              deviceTableValue : action.value
            }
    }

    return _.merge({}, state,  rs)
}
