import React, { Component,Fragment } from 'react';
import {Col, Row, Form, Checkbox} from "antd";

import {helpers as dataHelper} from '../helper'
import VisitRemoteEnrollCheckBox from "./VisitRemoteEnrollCheckBox";
const {Item} = Form;

class VisitInputCheckbox extends Component{

    render() {
        const { getFieldDecorator } = this.props.form;
        const {visitInfo, editStatus, lastVisitInfo,remoteEnrollment,onCheckboxChange } = this.props;
        let initArray = null;
        const conditionsList = _.get(visitInfo,'member.profile.conditionsList') || _.get(remoteEnrollment,'member.profile.conditionsList') || _.get(lastVisitInfo,'member.profile.conditionsList');
        initArray = dataHelper.conditionsListToCheckbox(conditionsList)

        return (
            <Fragment>
            <Item >
                {getFieldDecorator('conditionsList', {
                    initialValue: initArray
                })(
                    <Checkbox.Group style={{ width: '100%' }} onChange={onCheckboxChange}> 
                        Select patient diagnosis below:
                        <Row>
                            {dataHelper.conditionsList.map((value, index) => {
                                let spanValue = 6;
                                let displayValue = value;
                                if (value === 'ESRD_Dialysis') {
                                    displayValue = 'ESRD on Dialysis';
                                }
                                return (
                                    <Col span={spanValue} key={value}>
                                        <Checkbox value={value}>{displayValue}</Checkbox>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Checkbox.Group>
                )}
            </Item>
            </Fragment>
        )
    }
}

export default VisitInputCheckbox;
