import moment from 'moment';
import React from 'react';
import {FormatHelper} from '../../../../lib/utils/common';
import {Icon, Tooltip, message, Button} from 'antd';
import API from '../../API';
import '../style.scss';
import {CLINIC_MANGER_STR, AREA_MANAGER_STR} from '../../constant/roleStrMap';

const mapClinicMemberName = {
  'Clinic Medical Assistant': 'MA',
  'Doctor': 'MD',
  'Partner Biller': 'PARTNER_BILLER'
}
const sendInvitation = (userId, event) => {
  event.stopPropagation();
  const variables = {
    id : userId
  }
  API.resendOnBoardEmailLink(variables).then(() => {
    message.success("Invitation Link resend")
  })
}
const name = (localThis) => [{ 
    key:'name', 
    title: 'Name', 
    dataIndex:'user.profile.fullName', 
    sorter:true,
    render: (t, r) => !r.expanded ? t : ''
}];

const getColumnFilters = (key, filters = []) => {
  return filters.length > 1 ? {
    filters: _.map(filters, f => ({ text: f, value: f })),
    onFilter: (v, d) => {
      const namePath = key === 'area' ? 'parent.name' : 'name';
      const allRoles = _.get(d, 'user.allRoles', []);
      return _.filter(allRoles, r => _.get(r.organization, namePath, '') === v).length;
    }
  } : {};
}

const area = localThis => [{ 
  key:'area',
  title: 'Area', 
  dataIndex: 'role.organization.parent.name', 
  ...getColumnFilters('area', _.get(localThis, 'state.areaAdmin')),
  render: (t, r) => t || '--'
}];
const site = (localThis)=> [{ 
  key:'site', 
  title: 'Site', 
  dataIndex: 'role.organization.internalName',
  ...getColumnFilters('site', _.get(localThis, 'props.siteFilters')),  
  render: t => t || '--'
}];
const areaForAdmin = localThis => [{ 
  key:'areaForAdmin', 
  title: 'Area', 
  dataIndex: 'role.organization.name',
  ...getColumnFilters('areaForAdmin', _.get(localThis, 'props.areaForAdminFilters')),  
  render: t => t || '--'
}];
const status = (localThis)=> [{ key:'status', title: 'Status', dataIndex: 'user.lastLogIn.id',
render:(doc, r)=> !r.expanded ? (!_.isEmpty(doc)?"Logged in":
  <div>
    <span style={{color: '#D01407'}}>Pending...</span>
      <Tooltip title='This user hasn’t logged in yet.'>
        <Icon type='info-circle' theme='filled'/>
      </Tooltip>
      <Button className={'statusBtn'} onClick={(event) =>sendInvitation(r.userId, event)}>Resend Invitation Link</Button>
  </div>) : ''
}];
const phone = (localThis)=> [{ key:'phone', title: 'Phone', dataIndex: 'user.phone', width: '10%',
render:(doc, r)=> !r.expanded ? (doc.length===0?'--':FormatHelper.phone(doc[0])) : ''
}];
const email = (localThis)=> [{ key:'email', title: 'E-mail', dataIndex: 'user.email', width: '20%',
render:(doc, r)=> !r.expanded ? (doc.length===0?'--':doc[0].address) : ''
}];
const startDatePhoneEmail = (localThis)=> [
    ...phone(),
    ...email(),
    { 
      key:'startDate',
      title: 'Start date', 
      width: '10%',
      dataIndex: 'user.profile.startDate',
      render:(doc, r) => !r.expanded ? (doc?moment(doc).format('MM/DD/YYYY'):'--') : ''
    },
];
const role = (localThis)=> [{
    key:'role', title: 'Role', dataIndex:'role.name', width:'10%',
    render:(doc)=> mapClinicMemberName[doc] || 'Provider',
    filters: _.map(mapClinicMemberName, (k, v) => ({ text:v,value:k }) ),
}];
const mentor = (localThis) =>[{
    key:'mentor',
    title:'Mentor',
    dataIndex:'user.profile.mentor.profile.fullName',
    render:(doc,r)=>!r.expanded ?( doc || '--') : ''
}]
const mentee = (localThis)=>[{
    key:'mentee',
    title:'Mentee',
    dataIndex:'user.profile.healthCoach.profile.fullName',
    render:(doc,r)=>!r.expanded ?( doc || '--') : ''
}]
const tableMap =(tabSelected,localThis)=>({
    'RD':[
        ...name(localThis),
        ...area(localThis),
        ...site(localThis),
        ...startDatePhoneEmail(localThis),
        ...mentee(),
        ...status()
    ],
    'HC':[
        ...name(localThis),
        ...area(localThis),
        ...site(localThis),
        ...startDatePhoneEmail(localThis),
        ...mentor(),
        ...status()
    ],
    'CA':[
        ...name(localThis),
        ...area(localThis),
        ...site(localThis),
        ...startDatePhoneEmail(localThis),
        ...status()
    ],
    [CLINIC_MANGER_STR]:[
        ...name(localThis),
        ...site(localThis),
        ...startDatePhoneEmail(localThis),
        ...status()
    ],
    [AREA_MANAGER_STR]:[
        ...name(localThis),
        ...areaForAdmin(localThis),
        ...startDatePhoneEmail(localThis),
        ...status()
    ],
    'Clinic Members':[
        ...name(localThis),
        ...role(localThis),
        ...area(localThis),
        ...site(localThis),
        ...startDatePhoneEmail(localThis),
        ...status()
    ]
}[tabSelected]);

export default tableMap;