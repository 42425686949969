import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { compose } from 'react-apollo';

import { getTextPlaceholdersByRole } from '../helpers';
import useOption from '../helpers/useOption';
import ReviewPopupContainer from './ReviewPopupContainer';
import { getTODORPMPatientListCount } from '../apis/getPatientList';
import withRoleInfo from '../helpers/withRoleInfo';

const ReviewStartButtonContainer = ({
  roleInfo,
  filters,
  loadingBoostedPatientListPageInfo,
  boostedPatientListPageInfo,
  onRefetch,
}) => {
  // option to open popup when landing
  // when load patient in different organization
  const { page, removeOption } = useOption();
  const [popupVisible, setPopupVisible] = useState(false);

  const { isMD } = roleInfo;
  const textPlaceholder = getTextPlaceholdersByRole();

  useEffect(() => {
    if (!popupVisible && page) {
      // re-open popup after changing organization
      setPopupVisible(true);
    }
  }, [page, popupVisible]);

  const handleStartReview = useCallback(_.debounce(() => {
    setPopupVisible(true);
  }, 500), []);

  const handleCancel = () => {
    removeOption();
    setPopupVisible(false);
    if (onRefetch) onRefetch();
  };

  const hasPatientToReview = (_.get(boostedPatientListPageInfo, 'total') || 0) > 0;

  const renderPopup = () => {
    return (
      <ReviewPopupContainer
        page={page || 1}
        filters={filters}
        count={1} // loading 1 patient at a time
        onCancel={handleCancel}
        removeOption={removeOption}
      />
    );
  };

  const disableBtn = useMemo(() => {
    const hasPatientToReview = (_.get(boostedPatientListPageInfo, 'total') || 0) > 0;
    return loadingBoostedPatientListPageInfo || !hasPatientToReview;
  }, [loadingBoostedPatientListPageInfo, boostedPatientListPageInfo]);

  if (!isMD) {
    return null;
  }

  return (
    <div className="review-start-button-container">
      <Button
        type="primary"
        loading={loadingBoostedPatientListPageInfo}
        disabled={disableBtn}
        onClick={handleStartReview}
      >
        {textPlaceholder.startReviewBtnText}
      </Button>
      {
        popupVisible
        && renderPopup()
      }
    </div>
  )
};

ReviewStartButtonContainer.propTypes = {
  filters: PropTypes.object,
  disabled: PropTypes.bool,
};
export default withRoleInfo(
  compose(
    getTODORPMPatientListCount,
  )(ReviewStartButtonContainer)
);