import React, { Fragment } from 'react';
import { Icon, Modal } from 'antd';
import { CellularDevicesAssignModalFormContainer } from '../../CellularDevices/containers/CellularDeviceAssignModalFormContainer/CellularDeviceAssignModalFormContainer';
import { CellularDeviceOnboardUnassignComponent } from '../../CellularDevices/components/CellularDeviceOnboardUnassignComponent/CellularDeviceOnboardUnassignComponent';

export const renderCellularDeviceAssignModal = ({
  brand,
  onSubmit,
  onCancel,
}) => {
  const modal = Modal.info({
    width: '30vw',
    className: "cellular-devices-assign-form-modal",
    onCancel,
    destroyOnClose: true,
    maskClosable: true,
    okButtonProps: {
      style: { display: 'none' },
    },
  });
  const closeModal = () => {
    modal.destroy();
    if (onCancel) onCancel();
  };
  const handleSubmit = (values) => {
    if (onSubmit) onSubmit(values);
    modal.destroy();
  };
  modal.update({
    content: (
      <Fragment>
        <div className="cellular-devices-assign-form-modal__close-icon">
          <Icon
            type="close"
            onClick={closeModal}
          />
        </div>
        <CellularDevicesAssignModalFormContainer
          brand={brand}
          onSubmit={handleSubmit}
        />
      </Fragment>
    )
  })
};
export const renderCellularDeviceUnassignModal = ({
  device,
  onUnassign,
  onCancel,
}) => {
  const {
    deviceId,
    brand,
    language,
  } = device;
  const modal = Modal.info({
    width: '30vw',
    className: "cellular-devices-onboard-unassign-modal",
    onCancel,
    destroyOnClose: true,
    maskClosable: true,
    okButtonProps: {
      style: { display: 'none' },
    },
  });
  const closeModal = () => {
    modal.destroy();
    if (onCancel) onCancel();
  };
  const handleUnassign = () => {
    if (onUnassign) onUnassign();
    modal.destroy();
  };
  modal.update({
    content: (
      <Fragment>
        <div className="cellular-devices-assign-form-modal__close-icon">
          <Icon
            type="close"
            onClick={closeModal}
          />
        </div>
        <CellularDeviceOnboardUnassignComponent
          deviceId={deviceId}
          brand={brand}
          language={language}
          onUnassign={handleUnassign}
        />
      </Fragment>
    ),
  });
};
