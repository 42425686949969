import React from 'react';
import MissingInfoComponent from "../component/MissingInfoComponent";
import editUserForMissingInfo from "../API/editUserForMissingInfo";
import {missingInfoTasksResolver} from '../constant/MissingInfoConstant';
import {Form, Button, Modal, message} from "antd";
import { omitDeep } from '../../../lib/utils';
import moment from "moment";
import {parseProgramCategories} from "../../patient/AdditionalInfo/helpers/getMutateValueHelper";
import { copyBillableHealthConditions} from "../../patient/AdditionalInfo/helpers/getMutateValueHelper";
import AllMissingInfoResolvedComponent from "../component/AllMissingInfoResolvedComponent";
import { resolveMissingInfoHandler,getMissingInfoCount } from '../helper/index';
import { updatePatientComplexity } from '../../patient/main/API';
import { eventHelperCreator, EVENT_MAPS } from '../../../lib/helpers/event-helpers';
import { schema as PatientSchema } from '../../patientList/registerNew/components/formData/AddNewPatientForm';
import withPatientConsent from '../../../lib/hooks/withPatientConsent';
import '../style/missingInfo.styles.scss';
import setPatientInsurance from '../../patient/InsuranceCopayComponent/hooks/setPatientInsurance';

const eventHelper = eventHelperCreator(EVENT_MAPS.complexity, 'icdCode');
const fieldsToValidate = ['CCMEnrolled.enrolledDate', 'RPMEnrolled.enrolledDate', 'MNTEnrolled.enrolledDate', 'valueBasedEnrolled.enrolledDate'];

const MissingInfoContainer = class extends React.Component{
	constructor() {
		super();
		this.state = {
			missingInfoCount:1,
			missingInfoTaskType:[]
		}
	}
	componentDidMount() {
		const { user,missingInfo } = this.props;
		const { missingInfoCount,missingInfoTaskType } = getMissingInfoCount(user,missingInfo);
		this.setState({
			missingInfoCount,
			missingInfoTaskType
		})
	}

	parseVariables = (localThis,user, missingInfoTaskType)=>{
		user = omitDeep(user,'__typename');
		const userId = user.id;
		const getFieldsValue  = _.get(localThis,'props.form.getFieldsValue');
		const data = getFieldsValue();
		let memberProfile = {}
		let commonProfile = {};
		let address = [];
		const { language,maritalStatus,gender,
			occupation,economicStatus,race,firstName,lastName, nickname,
			streetName,streetNumber='',state,unit,city,country='US',postCode,insuranceProvider,insuranceProvider2, insuranceProviders,
			canUseForLogin, ChartA1C,EMR_Charting,CCM,motivation,pinnedToEMR,healthConditions,billableHealthConditions,
			complexityLevel,mntHealthConditions, hasCopay, copay,
		} = data;
		const DOB = user.profile.birthday;
		const identificationSrc = _.get(user, 'identification.0');
		if(streetName&&state&&city&&country&&postCode){
			let addressObj = {
				type:'MAIN',
				streetName,
				streetNumber,
				city,
				unit,
				state,
				country,
				postCode
			}
			address[0]= addressObj;
		}

		if(race){
			memberProfile.race = race;
		}

		if (language) {
			memberProfile.language = language
		}


		if(DOB) {
			memberProfile.birthday = moment(DOB).format('YYYY-MM-DD');
		}

		if(maritalStatus){
			memberProfile.maritalStatus = maritalStatus;
		}
		if(firstName) {
			commonProfile.firstName = firstName
		}
		if(lastName) {
			commonProfile.lastName = lastName
		}
		if(typeof nickname === 'string') {
			commonProfile.nickname = nickname;
		}

		if(gender) {
			commonProfile.gender = gender
		}

		if(economicStatus){
			memberProfile.economicStatus = economicStatus;
		}

		if(typeof insuranceProvider === 'string'){
			memberProfile.insuranceProvider = insuranceProvider;
		}
		if(typeof insuranceProvider2 === 'string'){
			memberProfile.insuranceProvider2 = insuranceProvider2;
		}
		if(insuranceProviders) {
			memberProfile.insuranceProviders = _.map(insuranceProviders, e => ({ id: e.id, primary: e.primary }));
		}

    if (_.includes(missingInfoTaskType, 'NO_PROGRAM_SELECTION')) {
      // only update when there is missing task for program
      const programCategories = parseProgramCategories(data);
      memberProfile.programCategories = programCategories;
    }

		if(occupation){
			memberProfile.occupation = occupation;
		}
		if(motivation){
			memberProfile.motivation = motivation;
		}
		const emrList = {}
		if(EMR_Charting) {
			emrList.EMR_Charting = EMR_Charting;
		}
		if(ChartA1C) {
			emrList.ChartA1C = ChartA1C;
		}
		if(CCM) {
			emrList.CCM = CCM;
		}
		if(pinnedToEMR) {
			emrList.pinnedToEMR = pinnedToEMR;
		}
		if(_.keys(emrList).length) {
			memberProfile.emrList = emrList;
		}
		let phone = [];
		if (data.homePhone) {
			phone.push({
				number: data.homePhone && data.homePhone.trim(),
				type: 'HOME',
				countryCode: '+1'
			});
		}

		if (data.mobilePhone) {
			phone.push({
				type: 'MOBILE',
				number: data.mobilePhone && data.mobilePhone.trim(),
				countryCode: '+1',
				canUseForLogin: typeof canUseForLogin=='boolean' ? canUseForLogin : (typeof canUseForLogin==='string' &&canUseForLogin.length>0) ? canUseForLogin=='true' : true,
				private: data.isPrivateNumber ? true : false
			});
		}
		if(Array.isArray(healthConditions)) {
			memberProfile.healthConditions = healthConditions;
		}
		if(Array.isArray(mntHealthConditions)) {
			memberProfile.mntHealthConditions = mntHealthConditions;
		}
		if(Array.isArray(billableHealthConditions)){
			memberProfile.billableHealthConditions = copyBillableHealthConditions(billableHealthConditions);
		}
		let identification = {};
		identification = {
			type : _.get(identificationSrc,'type.code') || _.get(PatientSchema, 'nationtype.initialValue') || 'SP',
			value: data.MRN || _.get(identificationSrc,'value')
		}
		let result = {
			id: userId,
			memberProfile,
			commonProfile,
			identification
		}

    if (typeof hasCopay !== 'undefined') {
      setPatientInsurance({
        patientId: userId,
        hasCopay,
        copay,
      });
    }
    
		return {
			userId,
			result,
			complexityLevel
		};

	}


	onSubmit = (localThis,user, missingInfoTaskType)=>{
		const { refetchMiniUser,refetchMissingInfo,missingInfo } = this.props;
		const missingInfoId = _.get(missingInfo,'id');
		const { result, complexityLevel,userId } = this.parseVariables(localThis,user, missingInfoTaskType);

    localThis.props.form.validateFieldsAndScroll(fieldsToValidate, (error) => {
      if (error) return;
      Promise.resolve().then(() => {
        if (complexityLevel) {
          const { level, source } = complexityLevel;
          return updatePatientComplexity({ memberId: userId, level, source });
        }
      }).then(res => {
        if (res) {
          eventHelper.create();
        }
      }).then(() => {
        return editUserForMissingInfo(result);
      }).then(res=> {
        const updatedUser = _.get(res, 'data.editUser');
        const updatedMissingInfoTaskType = (() => {
          const updated = [];
          missingInfoTaskType.map((type) => {
            let isMissing = false;
            if (_.get(missingInfoTasksResolver, `${type}.resolver`)) {
              isMissing = !missingInfoTasksResolver[type].resolver(updatedUser, missingInfo);
            }
            if (isMissing) updated.push(type);
          });
          return updated;
        })();
        if(!updatedMissingInfoTaskType.length) {
          resolveMissingInfoHandler(this.props,missingInfoId);
        }else {
          message.success('Successfully saved');
          this.setState({
            missingInfoTaskType: updatedMissingInfoTaskType,
            missingInfoCount: updatedMissingInfoTaskType.length
          });
          refetchMissingInfo && refetchMissingInfo();
        }
        refetchMiniUser&&refetchMiniUser();
      }).catch(error => {
        Modal.error({ content: _.get(error, 'message', 'Failed to save') });
      });
    })
	}

	renderButton = (missingInfoTaskType) => (localThis,user)=>{
		return <div style={{ display:'flex',justifyContent:'flex-end',margin:'5px 0px',marginBottom:'20px' }}>
				{ localThis.renderMuteBtn() }
				<Button onClick={()=>this.onSubmit(localThis,user, missingInfoTaskType)} type={'primary'}>Save</Button>
			   </div>
	}

	render() {
		const { missingInfoCount, missingInfoTaskType } = this.state;
		return <div className="missingInfoContainer">
			{
				missingInfoCount ? <MissingInfoComponent renderButton={this.renderButton(missingInfoTaskType)}
			                                          {...this.props}
			                                          handleEnableEditMode={() => true}
				/> : <AllMissingInfoResolvedComponent {...this.props}/>
			}
			   </div>
	}
}

const MissingInfoFormContainer = Form.create()(MissingInfoContainer);

export default withPatientConsent(MissingInfoFormContainer, {
  getMemberId: (props) => _.get(props, 'user.id'),
});