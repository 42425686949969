import { constants } from 'libModule/utils'

export default constants('patient', 'deviceTab', [
    'CHANGE_SELECT_DEVICE',
    'CHANGE_SELECT_DEVICE_TABLE_STATUS_REASON',
    'CHANGE_DEVICE_TABLE_SELECTED_ROWS',
    'CHANGE_DEVICE_TABLE_STATUS_REASON_MODAL',
    'IS_HOME_SETUP',
    'CHANGE_DEVICE_CURRENT_STEP',

    'SET_UPLOAD_FILE',

    'SET_INIT_DEVICE'
])
