import { constants } from 'libModule/utils';

export default constants('assessment', 'edit', [
  'NEW_QUESTION',
  'REMOVE_QUESTION',
  'FIELD_CHANGE',
  'CANCEL_ASSESSMENT',
  'CHANGE_TYPE',
  'SAVE_QUESTION',
  'SAVE_ASSESSMENT',
  'LOAD_ASSESSMENT',
  'SAVE_ASSESSMENT',
]);
