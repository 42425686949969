import { React, IHButton } from 'ihcomponent';
import { goPath } from 'libModule/utils';
// component
import PreviewInnerContainer from '../containers/PreviewInnerContainer';

export default (props) => {
  const query = props.location.query
  const backPath = query.backPath
  const onCancel = () => {
    backPath ? goPath(`/${backPath}`)
    : query.patientId && query.epId ? goPath(`/patients/${query.patientId}/enrolledprogram/${query.epId}`)
    : goPath('/assessments_surveys')
  }

  return (
    <div className="patient-take-assessment">
      <button className="link-like back" onClick={onCancel}>&lt;
        Back
      </button>
      <div className="paper">
        <PreviewInnerContainer id={props.params.assessmentFilledId} />

        <div className="btn-wrapper">
          <div className="right">
            <IHButton label="Cancel" onClick={onCancel} />
          </div>
        </div>
      </div>
    </div>
  );
};
