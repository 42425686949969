import Client from 'libModule/gqlClient';
import taskAssignmentUnreadList from '../../taskAssignment/query/taskAssignmentUnreadList';
import taskAssignmentUnreadTasks from '../../taskAssignment/query/taskAssignmentUnreadTasks';
import patientAssignee from '../query/patientAssigneeQuery';
const taskAssignmentUnreadVariables = () => {
    const currentUserId = sessionStorage.getItem('currentUserId');
    return {
        count: 1,
        filters: {
            assignedTo:[currentUserId],
        }
    }
}

const taskAssignmentUnreadTaskVariables = () => {
    const currentUserId = sessionStorage.getItem('currentUserId');
    return {
        filters: {
            assignedTo:[currentUserId],
            isRead:false
        }
    }
}

const fetchTaskUnreadList = ()=>{
    Client.query({
        query:taskAssignmentUnreadList,
        variables:taskAssignmentUnreadVariables(),
        fetchPolicy:'network-only'
    })
}
const getPatientAssignee = (id)=> Client.query({
        query: patientAssignee,
        variables:{
            id
        }
    })

const fetchTaskUnreadTasks = () => {
    Client.query({
        query:taskAssignmentUnreadTasks,
        variables:taskAssignmentUnreadTaskVariables(),
        fetchPolicy:'network-only'
    })
}

export default {
    fetchTaskUnreadList,
    fetchTaskUnreadTasks,
    getPatientAssignee
}
