import React from 'react';
import {SuperContainer} from 'libModule/utils'
import PatientUpcomingTaskListTable from '../containers/PatientUpcomingTaskListContainer'
import PatientPastTaskListTable from '../containers/PatientPastTaskListContainer'

const Component = class extends SuperContainer{
  render(){
    return (
      <div className="vsm-main-page vsm-patientList-main">
        <div className="vsm-main-990">
          <div className="v-table">
            <PatientUpcomingTaskListTable />
          </div>
          <div className="v-table">
            <PatientPastTaskListTable />
          </div>
        </div>
      </div>
    )
  }
}

Component.displayName = 'PatientTaskListComponent';

export default Component
