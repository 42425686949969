const valueEnum = {
  YES: 'YES',
  NO: 'NO'
};

const keyTextMapping = {
  followupVisits: 'Visits',
  nutritionCoaching: 'Nutrition'
};

const keyNoteMapping = {
  followupVisits: 'followupVisitsNote',
  nutritionCoaching: 'nutritionCoachingNote',

};
const sendVitalReminderMap = {
  sendVitalReminder:'sendVitalReminder'
}
const userPreferenceFieldKeys = Object.keys(keyTextMapping);

export default {
  valueEnum,
  keyTextMapping,
  keyNoteMapping,
  userPreferenceFieldKeys,
  sendVitalReminderMap
};
