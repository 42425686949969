import gql from "graphql-tag";

export default gql`
  mutation createMonthlyReview(
    $memberId: EID!,
    $teamId: EID!,
    $dueDate: Date,
    $type: MonthlyReviewType,
    $status: MonthlyReviewStatus,
    $monthOfYear: Int!,
    $note: String,
    $goals: [monthlyReviewGoalInput],
    $previousVitals: [PreviousVitalsInput],
    $previousGoals: [PreviousGoalsInput],
    $preSummary: MonthlyReviewSummaryInput
  ) {
    createMonthlyReview(memberId: $memberId, teamId: $teamId, dueDate: $dueDate, type: $type, status: $status, monthOfYear: $monthOfYear, note: $note, goals: $goals, previousVitals: $previousVitals, previousGoals: $previousGoals, preSummary: $preSummary) {
      id
      memberId
      organizationId
      teamId
      type
      status
      goals {
        value
        status
        vital
        condition
      }
      createdAt
      monthOfYear
      updatedAt
      dueDate
      note
    }
  }
`;
