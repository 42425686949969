import { _, React, IHSearchTable, moment } from 'ihcomponent'
import { connect } from 'react-redux';
import createTableAction from 'libModule/table/TableActions';
import { compose, withApollo } from 'react-apollo'

import { enrolledProgramWithDevicesHistory } from 'modulesAll/graphql/enrolledProgram'
import I18N from 'modulesAll/I18N';

const condition = {
  NORMAL: 'Normal',
  LOST: 'Lost',
  DAMAGED: 'Damaged'
}

const status = {
  LOANED: 'Loaned',
  RETURNED: 'Returned',
  REPLACED: 'Replaced'
}
const DeviceReplacementHistory = class extends React.PureComponent {
  static displayName = 'vendor/patient/components/DeviceReplacementHistory'

  constructor(props) {
    super(props);
    this.resetState();
  }

  resetState() {
    this.state = { data: [], loading:true };
  }

  componentWillMount(){
    this.setState({loading: true});
    this.runQuery(this.props.epId);
  }

  componentWillUnmount() {
    this.props.onLoadParams(null);
    this.resetState();
  }

  runQuery = async (epId) => {

    try{
    const res = await this.props.client.query({
      query: enrolledProgramWithDevicesHistory,
      variables : {
        id : epId
      },
      fetchPolicy: 'network-only'
    });

    this.setState({data: this.prepareData(_.get(res,'data.enrolledProgram')) || []})

    //only use by vendor page because using withApollo will not auto update
    if(this.props.getDevicesHistoryModalProps){
        this.props.getDevicesHistoryModalProps(epId, _.get(res,'data.enrolledProgram.devices'));
    }

  } catch(e) {
    this.state = { data: []};
  } finally {
    this.setState({loading: false});
  }


  }

  filterOnlyUniqueDeviceAddress(devices) {
    //because BP.BP5 and HR.BP5 are using same device, thus same address;
    const uniqDevices = {};
    const uniqDevicesArray = [];
    devices.map( e =>  {
      if( (e.status === 'PATIENT_OWNED') && !uniqDevices[`${e.model}`]  ){
          const patientOwnedDevice = Object.assign({}, e, {address: `${e.type}_${e.model}`});
          uniqDevices[`${e.model}`] = true;
          uniqDevicesArray.push(patientOwnedDevice);
      }
      else if( (e.status === 'VENDOR_LOAN' || e.status === 'RETURNED') && e.address && e.address.length && !uniqDevices[e.address]) {
        uniqDevices[e.address] = true;
        uniqDevicesArray.push(e);
      }
    })

    return uniqDevicesArray;
  }

  prepareData = (inputDevices) => {
     const deviceHistories = inputDevices.deviceHistories ? inputDevices.deviceHistories.reverse() : [];
     const currentDevices = inputDevices.devices;
     let devices = [];
     if(!currentDevices)
      return [];


     devices = this.filterOnlyUniqueDeviceAddress(currentDevices).map( d => this.prepareEachDevice(d, 'CURRENT') );

     if (deviceHistories && deviceHistories.length) {

        deviceHistories.map( eachHistory => {
          //filtered out PATIENT_OWNED for history devices
          devices = devices.concat( (this.filterOnlyUniqueDeviceAddress(eachHistory.devices).filter(d => d.status !== 'PATIENT_OWNED') || []).map( d => this.prepareEachDevice(d, 'HISTORY')) );

        })
     }

     return devices;

  }


  prepareEachDevice = (eachDevice, type) => {
    //type is CURRENT OR HISTORY
    return {
      deviceName: I18N.get(`Devices.${eachDevice.type}.${eachDevice.model}`),
      deviceId: this.getDeviceId({address: eachDevice.address, type: eachDevice.type, model: eachDevice.model, status: eachDevice.status, other: eachDevice.other}),
      status: type === 'CURRENT' ? this.getCurrentDeviceStatus(eachDevice.status) : this.getHistoryDeviceStatus(eachDevice.status),
      condition: eachDevice.condition ? condition[eachDevice.condition] : condition['NORMAL'],
      remark: eachDevice.remark,
      processedBy: type === 'CURRENT' ? ( eachDevice.receivedBy ? eachDevice.receivedBy: '') : eachDevice.replacedBy,
      processedDateAndTime: type === 'CURRENT' ? ( eachDevice.receivedDate ? eachDevice.receivedDate: '') : eachDevice.replacedDate,
      key: `${eachDevice.type}_${eachDevice.model}_${eachDevice.address}_${type === 'CURRENT' ? '' : eachDevice.replacedDate}` //for IHTable
    }
  }

  getCurrentDeviceStatus(statusKey){
    //current devices in ep.devices will not have status REPLACED
    let statusValue;
    switch(statusKey){
      case 'RETURNED':
        statusValue = 'Returned';
        break;
      case 'PATIENT_OWNED':
        statusValue = '';
        break;
      default:
        statusValue = 'Loaned';
    }
    return statusValue;
  }

  //for history devices filtered out PATIENT_OWNED
  getHistoryDeviceStatus(statusKey){
    return 'Replaced';
  }

  getDeviceId({address, type, model, status, other}){
    if(status === 'PATIENT_OWNED'){
      return `Patient Owned${ other ? `. ${other}` : '' }`;
    }else if(status === 'VENDOR_LOAN') {
      return address;
    }
    else return '';
  }


  filterAndSearch(input, searchKeyword, canSearch, filter){

     let afterFilter = (filter && filter.condition) ? input.filter(e => filter.condition.includes(e.condition) )  : input;
     if (filter && filter.status)
      afterFilter = afterFilter.filter(e => filter.status.includes(e.status) ) ;

     if(canSearch && afterFilter){
        return afterFilter.filter(e=> (e.deviceName.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 || e.deviceId.indexOf(searchKeyword) > -1 ))
     }else{
       return afterFilter || [];
     }

  }

  getTableProps(){
    const { onTableChange, onTableSearch, onSearchEnter, filter, sort, page, search = '', canSearch } = this.props;
    const data = this.state.data;
    const  loading = this.state.loading;
    return {
      title: `Devices (${this.filterAndSearch(data, search, canSearch, filter).length})`,
      columns: [

        {
          title: 'Device Name',
          key: 'deviceName',
          dataIndex: 'deviceName',
          sorter: (a, b) => {
            const name1 = a.deviceName;
            const name2 = b.deviceName;
            if(name1 < name2) return -1;
            if(name1 > name2) return 1;
            return a.processedDateAndTime - b.processedDateAndTime;

          },
          sortOrder: _.get(sort, 'field', null) === 'deviceName' ? _.get(sort, 'order', false) : null,

        },
        {
          title: 'Device ID',
          key: 'deviceId',
          dataIndex: 'deviceId',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          filters: Object.keys(status).map(e => status[e]).map(e => ({text: e, value:e })  ),  //IE11 not support Object.values
          onFilter: (value, doc) => doc.status.indexOf(value) === 0,
        },
        {
          title: 'Condition',
          key: 'condition',
          dataIndex: 'condition',
          filters: Object.keys(condition).map(e => condition[e]).map(e => ({text: e, value:e })  ),  //IE11 not support Object.values
          onFilter: (value, doc) => doc.condition.indexOf(value) === 0,
        },
        {
          title: 'Remark',
          key: 'remark',
          dataIndex: 'remark',
        },
        {
          title: 'Processed By',
          key: 'processedBy',
          dataIndex: 'processedBy',
        },
        {
          title: 'Processed Date Time',
          key: 'processedDateAndTime',
          dataIndex: 'processedDateAndTime',
          sorter: (a, b) => a.processedDateAndTime - b.processedDateAndTime,
          sortOrder: _.get(sort, 'field', null) === 'processedDateAndTime' ? _.get(sort, 'order', false) : null,
          render: (item, row) => row.processedDateAndTime ? moment(row.processedDateAndTime).format('MMM-DD-YYYY hh:mm a') : ''
        }
      ],
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: this.filterAndSearch(data, search, canSearch, filter).length,
      },
      dataSource: this.filterAndSearch(data, search, canSearch, filter) ,
      inputPlaceholder: 'Search by Name',
      autoFocus: false,
      onSearch: onTableSearch,
      onChange: onTableChange,
      onSearchEnter,
      initSearchValue : search,
      loading

    }

  }

  render(){
    const p = this.getTableProps()
    return (
      <div className="v-table">
        <IHSearchTable {...p} />
      </div>
    )
  }

}


const tableActions = createTableAction('provider_replacereturn_devicehistory');


const DeviceReplacementHistoryWithData = compose(
  connect(
    (state)=>{
      return {
        ...state.globalTables.provider_replacereturn_devicehistory,
      }
    }
    , () => tableActions),
  withApollo,
)(DeviceReplacementHistory);



export const getDevicesHistoryModalProps = (epId, closeModal, getDevicesHistoryModalProps=false) => {

    const body = (
        <DeviceReplacementHistoryWithData epId={epId} getDevicesHistoryModalProps={getDevicesHistoryModalProps}/>
    )
    // const b1 = {
    //   key: 'closeDeviceHistory',
    //   size : 'md',
    //   label : 'Close',
    //   type : 'primary',
    //   onClick : ()=>{
    //       closeModal();
    //   }
    // }
    return {
      showHeaderCloseButton: true,
      title : 'Devices History',
      body,
      size : 'lg',
      // footer : [
      //       <IHButton {...b1} />
      //   ]
    }
}
