import React from 'react';
import moment from 'moment/moment';
import { Checkbox, Row, Button, Icon, Form, DatePicker, Radio, Col} from 'antd/lib/index';
import Client from 'libModule/gqlClient';
import editUser from 'modulesAll/graphql/mutation/editUser';
import '../style/charting.styles.scss';
import renderOverlayDiv from '../helpers/overLayButton';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { helpers } from '../../visitNewWorkFlow/helper';
import { renderDraftSavedText } from '../../visitWorkList/helpers';
import I18N from '../../I18N';
import singleVisit from '../../graphql/singleVisit';
import { doesOrgHaveCCM, doesOrgHaveRPM, doesOrgHaveValueBased} from "../../../lib/utils";

const  getOrgPermissions = ()=>{
  return JSON.parse(sessionStorage.getItem('currentUserConfigPermissions'));
}

const getSyncedEnrolledDate = (consentInfo) => {
  const { consent, signatureAt } = consentInfo || {};
  const enrolledDate = (!!consent && signatureAt) ? moment(signatureAt) : undefined;
  return enrolledDate;
}

const parseValue = (props) => {
  const { form, user } = props;
  let { 
    CCMEnrolled, CCMEnrolledDate, 
    RPMEnrolled, RPMEnrolledDate,
    MNTEnrolled, MNTEnrolledDate,
    valueBasedEnrolled,valueBasedEnrolledDate,
    ChartA1C, EMR_Charting, pinnedToEMR,
    consentInfo
  } = form.getFieldsValue();

  const getProgramEnrollment = (key, isEnrolled, selectedEnrolledDate) => {
    return {
      name: key,
      enrolled: isEnrolled,
      enrolledDate: (isEnrolled && selectedEnrolledDate) ? selectedEnrolledDate.valueOf() : null,
    };
  };


  const prevProgramCategories = _.get(user, 'profile.programCategories') || [];
  let newProgramCategories = [
    getProgramEnrollment('CCM', CCMEnrolled, CCMEnrolledDate),
    getProgramEnrollment('RPM', RPMEnrolled, RPMEnrolledDate),
    getProgramEnrollment('MNT', MNTEnrolled, MNTEnrolledDate),
    getProgramEnrollment('VALUE_BASED', valueBasedEnrolled, valueBasedEnrolledDate)
  ];

  // revert to prev selection for new program enrolled without enrolled Date
  newProgramCategories = _.map(newProgramCategories, pc => {
    if (pc.enrolled && !pc.enrolledDate) {
      const prevSelection = _.find(prevProgramCategories, { name: pc.name });
      if (prevSelection) return _.omit(prevSelection, '__typename');
    }
    return pc;
  });

  let variables = {
    id: user.id,
    memberProfile: {
      birthday: user.profile.birthday,
      emrList: { ChartA1C, EMR_Charting, pinnedToEMR },
      programCategories: newProgramCategories,
    }
  }
  return variables;
};

let ChartingComponent = class extends React.Component {
  //static displayHeader = 'Charting';
  constructor(props) {
    super(props);
    this.state = {
      ccmEnrolled:_.find(_.get(this.props, 'user.profile.programCategories'), {'name':'CCM'}),
      rpmEnrolled:_.find(_.get(this.props, 'user.profile.programCategories'), {'name':'RPM'}),
      mntEnrolled:_.find(_.get(this.props, 'user.profile.programCategories'), {'name':'MNT'}),
      valueBasedEnrolled: _.find(_.get(this.props, 'user.profile.programCategories'), {'name':'VALUE_BASED'}),
      showAutoPrefillDateNote: {}
    }
  }

  // onTouchComponent = () => {
  //   this.props.onTouch()
  //   const draftType = 'Charting';
  //   const refetchQueries = [{query: singleVisit, variables: { id: _.get(this, 'props.visit.id') },  fetchPolicy: 'network-only' }];
  //   this.props.setChildProps(null, this.parseValue, editUser, refetchQueries, draftType)
  // }

  saveEdit = (flag, setEditMode, msg) => {
    Client.mutate({
      mutation: editUser,
      variables: parseValue(this.props),
      fetchPolicy: 'no-cache',
    })
      .then(res => {
        if (flag) {
          this.props.afterSubmitSucc();
        } else {
          this.props.refetch();
          this.props.afterSaveDraftSucc();
        }
      })
      .catch(console.error);
  };

  handleOnClick = (e) => {
    e.preventDefault();
    const { form, title, submitButtonText } = this.props;
    let submitText = submitButtonText === 'Finish Charting' ? submitButtonText : 'Complete_Button'
    Mixpanel.track('Clicked', submitText,'Charting_' + title, {});
    form.validateFields((error, fields) => {
      if (!error) {
        this.saveEdit(true, false, 'Charting is Saved');
      }
    });
  }

  handleOnClickSaveDraft = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, fields) => {
      if (!error) {
        this.saveEdit(false, false, 'Charting Draft is Saved');
      }
    });
  };

  setShowAutoPrefillDateNote = (enrolledDateKey, flag) => {
    this.setState(prev => ({ showAutoPrefillDateNote: { ...prev.showAutoPrefillDateNote, [enrolledDateKey]: flag } }));
  }

  onChangeProgramCategory = (e, enrolledDateKey) => {
    const isChecked = e.target.value;
    const enrolledDate = getSyncedEnrolledDate(this.props.consentInfo);
    const shouldAutoPrefill = isChecked && !!enrolledDate;
    if (shouldAutoPrefill) this.props.form.setFieldsValue({ [enrolledDateKey]: enrolledDate });
    this.setShowAutoPrefillDateNote(enrolledDateKey, shouldAutoPrefill);
    // this.onTouchComponent();
  }

  onChangeEnrolledDate = (enrolledDateKey, date) => {
    const enrolledDate = getSyncedEnrolledDate(this.props.consentInfo);
    if(this.state.showAutoPrefillDateNote &&
      (!date || !date.isSame(moment(enrolledDate), 'day')))
    {
      this.setShowAutoPrefillDateNote(enrolledDateKey, false);
    }
    // this.onTouchComponent();
  }

  renderAutoPrefillDateNote = (enrolledDateKey) => {
    if(!this.state.showAutoPrefillDateNote[enrolledDateKey])
      return null;
    const enrolledDate = getSyncedEnrolledDate(this.props.consentInfo);
    const note = I18N.get('programCategories.getAutoPrefillNote')(
      moment(enrolledDate).format('MM/DD/YYYY'),
      { marginTop: -20, marginBottom: 10 }
    );
    return note;
  }

  render() {
    const { isEditMode, enrolledProgramId, user, consentInfo } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {ccmEnrolled, rpmEnrolled, mntEnrolled,valueBasedEnrolled} = this.state;
    const visitType = _.get(this.props, 'visit.type');
    const isMNT = visitType == 'MNT';
    const pinnedEMRReq = _.get(getOrgPermissions(), 'orgPermissions.pinnedToEMRRequired');
    const curPinnedToEMR = getFieldValue('pinnedToEMR') || _.get(user, 'profile.emrList.pinnedToEMR');
    const orgHasCCM = doesOrgHaveCCM();
    const orgHasRPM = doesOrgHaveRPM();
    const orgHasValueBased = doesOrgHaveValueBased();

    const pinnedToEMRRule = [
      { validator: (rule, value, callback) => {
        if (typeof(value) !== 'undefined') {
          if(!value && pinnedEMRReq){
            callback('Did you forget to add this patient to the EMR? If so, please mark this patient in the EMR and check this box before completing this section.');
          } else {
            callback()
          }
        } else {
          if (!curPinnedToEMR && pinnedEMRReq) {
            callback('Did you forget to add this patient to the EMR? If so, please mark this patient in the EMR and check this box before completing this section.');
          } else {
            callback()
          }
        }
      }}
    ];
    const headerStyle = {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#000000',
      lineHeight: '19px'
    };
    const radioStyle = {
      paddingLeft: '30px',
      paddingRight: '30px'
    };

    return (
      <div>
        {!enrolledProgramId && renderOverlayDiv(this.props)}
        <div style={{ opacity: !enrolledProgramId ? 0.2 : 1 }}>
          <div className='fix-header' style={headerStyle}>
            <div className='fixHeaderTitle'>
              Charting
              {renderDraftSavedText()}
            </div>
          </div>
          <div className='scrollable-content'>
          <Form layout='vertical'>
              <Row style={{ display: !orgHasCCM ? 'none' : 'flex'}}>
                <Col span={8}>
                  <Form.Item label='CCM Enrollment'>
                    {getFieldDecorator('CCMEnrolled', {
                      initialValue: _.get(ccmEnrolled, 'enrolled'),
                      rules: [
                        {
                          required: isMNT,
                          message: 'Please Select CCM Enrollment',
                        },
                      ],
                    })(<Radio.Group onChange={e => this.onChangeProgramCategory(e, 'CCMEnrolledDate')}>
                      <Radio.Button value={true} style={radioStyle}>Yes</Radio.Button>
                      <Radio.Button value={false} style={radioStyle}>No</Radio.Button>
                    </Radio.Group>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Start Date' style={!getFieldValue('CCMEnrolled') ? { display: 'none' }: {}}>
                    {getFieldDecorator('CCMEnrolledDate', {
                      initialValue: _.get(ccmEnrolled, 'enrolledDate') ? moment(_.get(ccmEnrolled, 'enrolledDate')) : undefined,
                      rules: [
                        {
                          required: !!getFieldValue('CCMEnrolled'),
                          message: 'Please Select CCM Start Date',
                        },
                      ],
                    })(<DatePicker 
                        format='MM/DD/YYYY' 
                        onChange={_.partial(this.onChangeEnrolledDate, 'CCMEnrolledDate')}
                        allowClear={false}
                      />)}
                  </Form.Item>
                  { this.renderAutoPrefillDateNote('CCMEnrolledDate') }
                </Col>
              </Row>
              <Row style={{ display: !orgHasRPM ? 'none' : 'flex'}}>
                <Col span={8}>
                  <Form.Item label='RPM Enrollment'>
                    {getFieldDecorator('RPMEnrolled', {
                      initialValue: _.get(rpmEnrolled, 'enrolled'),
                      rules: [
                        {
                          required: isMNT,
                          message: 'Please Select RPM Enrollment',
                        },
                      ],
                    })(<Radio.Group onChange={e => this.onChangeProgramCategory(e, 'RPMEnrolledDate')}>
                      <Radio.Button value={true} style={radioStyle}>Yes</Radio.Button>
                      <Radio.Button value={false} style={radioStyle}>No</Radio.Button>
                    </Radio.Group>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Start Date' style={!getFieldValue('RPMEnrolled') ? { display: 'none' }: {}}>
                    {getFieldDecorator('RPMEnrolledDate', {
                      initialValue: _.get(rpmEnrolled, 'enrolledDate') ? moment(_.get(rpmEnrolled, 'enrolledDate')) : undefined,
                      rules: [
                        {
                          required: !!getFieldValue('RPMEnrolled'),
                          message: 'Please Select RPM Start date',
                        },
                      ],
                    })(<DatePicker 
                        format='MM/DD/YYYY' 
                        onChange={_.partial(this.onChangeEnrolledDate, 'RPMEnrolledDate')}
                        allowClear={false}
                      />)}
                  </Form.Item>
                  { this.renderAutoPrefillDateNote('RPMEnrolledDate') }
                </Col>
              </Row>
              <Row style={{ display: !orgHasValueBased ? 'none' : 'flex'}}>
                <Col span={8}>
                  <Form.Item label='Value Based Enrollment'>
                    {getFieldDecorator('valueBasedEnrolled', {
                      initialValue: _.get(valueBasedEnrolled, 'enrolled'),
                    })(<Radio.Group onChange={e => this.onChangeProgramCategory(e, 'valueBasedEnrolledDate')}>
                      <Radio.Button value={true} style={radioStyle}>Yes</Radio.Button>
                      <Radio.Button value={false} style={radioStyle}>No</Radio.Button>
                    </Radio.Group>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Start Date' style={!getFieldValue('valueBasedEnrolled') ? { display: 'none' }: {}}>
                    {getFieldDecorator('valueBasedEnrolledDate', {
                      initialValue: _.get(valueBasedEnrolled, 'enrolledDate') ? moment(_.get(valueBasedEnrolled, 'enrolledDate')) : undefined,
                      rules: [
                        {
                          required: !!getFieldValue('valueBasedEnrolled'),
                          message: 'Please Select Start date',
                        },
                      ],
                    })(<DatePicker
                        format='MM/DD/YYYY'
                        onChange={_.partial(this.onChangeEnrolledDate, 'valueBasedEnrolledDate')}
                        allowClear={false}
                    />)}
                  </Form.Item>
                  { this.renderAutoPrefillDateNote('valueBasedEnrolledDate') }
                </Col>
              </Row>
              <Row style={{ visibility: isMNT ? 'visible':'hidden' }}>
                <Col span={8}>
                  <Form.Item label='MNT Enrollment'>
                    {getFieldDecorator('MNTEnrolled', {
                      initialValue: _.get(mntEnrolled, 'enrolled'),
                      display:'none',
                      rules: [
                        {
                          required: isMNT,
                          message: 'Please Select MNT Enrollment',
                        },
                      ],
                    })(<Radio.Group onChange={e => this.onChangeProgramCategory(e, 'MNTEnrolledDate')}>
                      <Radio.Button value={true} style={radioStyle}>Yes</Radio.Button>
                      <Radio.Button value={false} style={radioStyle}>No</Radio.Button>
                    </Radio.Group>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {
                    getFieldValue('MNTEnrolled') ?
                        <Form.Item label='Start Date'>
                          {getFieldDecorator('MNTEnrolledDate', {
                            initialValue: _.get(mntEnrolled, 'enrolledDate') ? moment(new Date(_.get(mntEnrolled, 'enrolledDate'))) : moment(),
                            rules: [
                              {
                                required: true,
                                message: 'Please Select MNT Start Date',
                              },
                            ],
                          })(<DatePicker 
                            format='MM/DD/YYYY' 
                            allowClear={false}
                            onChange={_.partial(this.onChangeEnrolledDate, 'MNTEnrolledDate')}
                          />)}
                        </Form.Item> : null
                  }
                  { this.renderAutoPrefillDateNote('MNTEnrolledDate') }
                </Col>
              </Row>
            {
              isMNT &&
              <Row>
                <Col span={16} style={{ margin: '-10px 0px 30px'}}>
                  <Icon type="info-circle" theme="filled" />
                  This will temporarily be used as an indication of patient's MNT enrollment status shown in different places in portal such as patient profile, patient list etc.
                </Col>
              </Row>
            }

            <Row>
              <Form.Item label=''>
                {
                  getFieldDecorator('EMR_Charting', {
                    initialValue: _.get(user, 'profile.emrList.EMR_Charting'),
                    valuePropName: 'checked'
                  })(<Checkbox>EMR Charting &amp; Billing Done</Checkbox>)
                }
              </Form.Item>
            </Row>
            <Row style = {{marginTop: -10}}>
              <Form.Item label=''>
                {
                  getFieldDecorator('ChartA1C', {
                    initialValue: _.get(user, 'profile.emrList.ChartA1C'),
                    valuePropName: 'checked'
                  })(<Checkbox>Chart A1C in EMR</Checkbox>)
                }
              </Form.Item>
            </Row>
            {
              !(helpers.checkIsCAOrRD() && isMNT) &&
              <Row style = {{marginTop: -10}}>
                <Form.Item label="">
                  {getFieldDecorator('pinnedToEMR', {
                      rules: pinnedToEMRRule,
                      initialValue: _.get(user, 'profile.emrList.pinnedToEMR'),
                      valuePropName: 'checked'
                  })(<Checkbox>
                        <label className={pinnedEMRReq? 'ant-form-item-required' : ''}>
                          Marked in EMR
                        </label>
                      </Checkbox>)}
                </Form.Item>
              </Row>  
            }
            <Row style = {{ display: 'none' }}>
                <Form.Item label="">
                  {getFieldDecorator('consentInfo', {
                    initialValue: consentInfo
                  })(<div></div>)}
                </Form.Item>
              </Row>  
            </Form>
          </div>
          <Row className='fix-footer'>
            {isEditMode ? (
              <Button onClick={this.handleOnClickSaveDraft} className='saveDraftBtn'>
                Save Draft
              </Button>
            ) : ('')}
            <Button onClick={this.handleOnClick} type={'primary'}>
              {this.props.submitButtonText || 'Complete'}
            </Button>
          </Row>
        </div>
      </div>
    );
  }
};

ChartingComponent = Form.create({
  onValuesChange: (ownProps) => {
    const { onTouch, setChildProps, visit } = ownProps;
    if(typeof onTouch === 'function'){
      onTouch()
      const draftType = 'Charting';
      const refetchQueries = [{query: singleVisit, variables: { id: _.get(visit, 'id') },  fetchPolicy: 'network-only' }];
      setChildProps(ownProps, parseValue, editUser, refetchQueries, draftType);
    }
  }
})(ChartingComponent);

export default ChartingComponent;
