import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';

const columns = [
  {
    key: 'article',
    title: 'Article',
    width: '80%',
    dataIndex: 'article',
    render: (v) => {
      const { name, url } = v || {};
      return url ?
        (<span
          onClick={() => {
            if (!url) return;
            window.open(url, '_blank');
          }}
          className="article-select-option-info__link"
        >
          {name}
        </span>)
        : (name || '--');
    },
  },
  {
    key: 'updatedAt',
    title: 'Sent date',
    dataIndex: 'updatedAt',
    render: (v) => v ? moment(v).format('MM/DD/YYYY') : '--',
    sorter: (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf(),
    defaultSortOrder: 'descend'
  },
];

const ArticleSendingHistoryComponent = ({
  disabled,
  isLoading,
  patientArticles,
}) => {


  return (
    <div className="article-sending-history-component">
      <Table
        disabled={disabled}
        columns={columns}
        dataSource={patientArticles}
        loading={isLoading}
        size="small"
        rowKey={(r) => r.id}
        pagination={{
          size: 10,
        }}
      />
    </div>
  );
};

ArticleSendingHistoryComponent.propTypes = {
  patientArticles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    updatedAt: PropTypes.number,
  }))
};
export default ArticleSendingHistoryComponent;