/**
 * Created by zizhangai on 2/8/17.
 */
import React, { Component } from 'react';
import moment from 'moment';
import { VSMDateRange, IHSelect, ND } from 'ihcomponent'
import { mapReason } from '../constants/ReportModalConstants'
import I18N from 'modulesAll/I18N';

class reportModalBody extends Component {
  static displayName = 'report/components/ReportModalBodyComponent'

  render() {
    const { range, changeDate, reason, changeReason, err, summary, changeInput, isFullReport } = this.props
    /*
    const p = {
      option: [
        { name: 'Patient is suspended', value: 'Suspend' },
        { name: 'Patient is discharged', value: 'Discharge' },
        { name: 'Patient Condition Changes', value: 'Condition change'}
      ],
      value: reason,
      onChange: changeReason
    }
    */
    if (isFullReport) {
      return (
        <div>
          <h4>Select a date range</h4>
          <div className={err? "has-error":""}>
          <VSMDateRange onChange={changeDate} value={range} initData={range} />
          {
            err === 1 && <div className="ant-form-explain">Start Date should not be earlier than Program Start Date</div>
          }
          {
            err === 2 && <div className="ant-form-explain">End Date should not be later than today</div>
          }
          </div>
        </div>
      )
    }
    return (
      <div>
        <h4>Date Range</h4>
        <h4 style={{fontWeight: '200', fontSize: '14px'}}>{`${moment(range[0]).format("MMM-DD-YYYY")} to ${moment(range[1]).format("MMM-DD-YYYY")}`}</h4>
        <h4>Reason for This Report</h4>
        <h4 style={{fontWeight: '200', fontSize: '14px'}}>{mapReason[reason]}</h4>
        <h4>{I18N.get('keywords.Program')} Summary</h4>
        <ND.Input.TextArea row={4} type="textarea" style={{fontSize:'14px', padding: '10px'}}
                  onChange={ (e) =>changeInput(e.target.value) } placeholder="Add Summary Here" />
      </div>
    )
  }
}

export default reportModalBody
