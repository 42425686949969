import React from 'react';
import UserListComponent, {roleMap} from '../Component/UserListComponent';
import { Tabs,Row,Col,Button, Modal, Input, Steps, Icon, message, AutoComplete} from 'antd';
import '../style.scss';
import FirstPage from '../Component/FirstPage';
import SecondPage from '../Component/SecondPage';
import { graphql, compose } from 'react-apollo';
import {IHLoading} from 'ihcomponent';
import API from '../../API';
import helper from '../../helper';
import query from '../../query';
import {CLINIC_MANGER_STR, AREA_MANAGER_STR, HC_STR} from '../../constant/roleStrMap';
import { ROLE_NAME,CAPACITY_ROLE, ROLE_DEFAULT_CAPACITY } from '../../../../lib/constants';
import _ from "lodash";
const SEARCH_INTERVAL = 1000; // allow user to search every SEARCH_INTERVAL (ms)

const { TabPane } = Tabs;
const { Step } = Steps;
const { Option } = AutoComplete;
const tabs = ["RD", HC_STR, "CA", CLINIC_MANGER_STR, AREA_MANAGER_STR, "Clinic Members"]

const tabRoleMap = {
  'Doctor': 'Clinic Members',
  'Clinic Medical Assistant': 'Clinic Members',
  'Medical Assistant': 'CA',
  'Nurse': 'RD',
  'Admin': 'Admin',
  'Partner Biller': 'Clinic Members',
  [ROLE_NAME.HC]: 'HC'
};

const allRoles = (roles) => {
    let set = new Set();
    _.map(roles, (key) => {
        key.name==='Doctor'?set.add('Provider') :null;
        key.name==='Clinic Medical Assistant'?set.add('MA'):null;
        key.organization.leaf&&key.name==='Admin'?set.add(CLINIC_MANGER_STR):null;
        !key.organization.leaf&&key.name==='Admin'?set.add(AREA_MANAGER_STR) :null;
        key.name==='Medical Assistant'?set.add('CA') :null;
        key.name==='Nurse'?set.add('RD') :null;
        key.name === 'Partner Biller' ? set.add('Partner Biller'):null;
        key.name === ROLE_NAME.HC ? set.add(HC_STR):null;
    })
    let res = "";
    set.forEach((item) => res? res = res +','+'\xa0'+ item : res = res + item);
    return res;
}
const getTableData = list => {
  const f = {
    'RD': [], [HC_STR]: [], 'CA': [], [CLINIC_MANGER_STR]: [], [AREA_MANAGER_STR]: [], 'Clinic Members': [] 
  };
  const users = _.cloneDeep(f), areaFilters = _.cloneDeep(f), siteFilters = _.cloneDeep(f), areaForAdminFilters = _.cloneDeep(f);
  _.map(list, user => {
    const userId = user.id;
    const allRoles = _.sortBy(user.allRoles, r => ~~!(_.get(r,`organization.leaf`)));
    _.forEach(allRoles, role => {
      const { refId, name, organization } = role;
      let roleName = tabRoleMap[name];
      if(roleName) {
        if(roleName === 'Admin'){
          roleName = organization.leaf ? CLINIC_MANGER_STR : AREA_MANAGER_STR;
        }
        const site = organization.internalName; // must not be Nil
        const area = _.get(organization, 'parent.name');
        const areaForAdmin = organization.name;
        if(site && !_.includes(siteFilters[roleName], site)) {
          siteFilters[roleName].push(site);
        }
        if(area && !_.includes(areaFilters[roleName], area)){
          areaFilters[roleName].push(area);
        }
        if(areaForAdmin && !_.includes(areaForAdminFilters[roleName], areaForAdmin)){
          areaForAdminFilters[roleName].push(areaForAdmin);
        }
        const newEntry = { rowKey: `${userId}-${refId}`, userId, user, role };
        const userIndex = _.findIndex(users[roleName], { userId });
        if(userIndex > -1) {
          // add expanded row
          const u = users[roleName][userIndex];
          u.children = [..._.get(u, 'children', []), {
            ...newEntry, expanded: true
          }];
          users[roleName].splice(userIndex, 1, u);
        } else {
          // initial
          users[roleName].push(newEntry);
        }
      }
    });
  });
  return { users, areaFilters, siteFilters, areaForAdminFilters};
}
class User extends React.Component {
    static diaplayName = 'newAdmin/User/Conatainer/UserListcontainer';

    constructor(props) {
        super(props);
        this.state={
            tabSelected:"RD",
            visible: false,
            page: 0,
            basicInfor:{},
            memberList: [],
            popUpVisible: false,
            loading: true,
            searchStr: '',
            canSearch: true,
            list:[],
            currRole: "",
            pageInfo: {  },
            summary: { },
            loadingSearch: false
        }
    }

    componentDidMount = async () => {
        // const orgId = helper.getViewerOrgId();
        // this.fetchUser({page:1,count:9999,roleName:['RD'] }).then((data)=> {
        //     this.setState({summaryList: data.data.userList.data, loading:data.loading,pageInfo: data.data.userList.pageInfo})
        // });

        this.fetchUserList({page:1,count:10,roleName:['RD']});
        this.fetchUserCount();
        // helper.getMembersByAppRoles( { rootId: orgId, roleName:['RD'],page:1,count: 999 } ).then(data=>{
        //     this.setState({ summaryList: data.data.userList.data,pageInfo: data.data.userList.pageInfo })
        // });
        // helper.getMembersByAppRoles({ rootId: orgId, roleName:['RD'],page:1,count: 10 }).then((data)=> {
        //     this.setState({memberList: data.data.userList.data, loading:data.loading})
        // })
    }

    fetchUserCount = async ()=>{
        const orgId = helper.getViewerOrgId();
        const response = await API.getUserCountInOrg({rootId:orgId});
        const staffCount = _.get(response,'data.getStaffSummary.staffCount',[]);
        const areaAdmin = _.get(response,'data.getStaffSummary.getStaffSummary',[]);
        const siteAdmin = _.get(response,'data.getStaffSummary.siteAdmin',[]);
        const summary = {};
        staffCount.forEach(({count,role})=>{
            _.set(summary,role,count)
        })
        this.setState({
            summary,
            areaAdmin,
            siteAdmin
        })
    }

    fetchUserList = (args)=>{
        this.setState({
            loading: true
        })
        this.fetchUser(args).then((data)=>{
            this.setState({memberList: data.data.userList.data, loading:data.loading,pageInfo: data.data.userList.pageInfo})
        });
    };

    fetchUser = (args)=>{
        const orgId = helper.getViewerOrgId();
        const { count=10,page=1,roleName=['RD'],sort }  = args;
        return helper.getMembersByAppRoles({ rootId: orgId, roleName,page,count,sort })
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
    };
    onCancel = () => {
        this.setState({
          visible: false,
        });
    }
    handlerNext = (value) => {
        this.setState({
            page: this.state.page + 1,
            basicInfor: value
        })
    }
    createHandler = (value) => {
        let roleRefId = [],
            mentorRD,
            hasCredential;
        _.map(value,(key) => {
            _.map(key.site, (o) => {
                const site = _.filter(this.props.organizationList.data, (item)=>item.id===o)[0];
                key.role === CLINIC_MANGER_STR || key.role === AREA_MANAGER_STR?_.map(site.roles,(role)=> role.name==='Admin'?roleRefId.push(role.refId):null):null;
                key.role === 'MA'?_.map(site.roles,(role)=> role.name==='Clinic Medical Assistant'?roleRefId.push(role.refId):null):null;
                key.role === 'Provider'?_.map(site.roles,(role)=> role.name==='Doctor'?roleRefId.push(role.refId):null):null;
                key.role === 'CA'?_.map(site.roles,(role)=> role.name==='Medical Assistant'?roleRefId.push(role.refId):null):null;
                key.role === 'RD'?_.map(site.roles,(role)=> role.name==='Nurse'?roleRefId.push(role.refId):null):null;
                key.role === 'Partner Biller' ? _.map(site.roles,(role)=> role.name==='Partner Biller'?roleRefId.push(role.refId):null):null;
                if(key.role === HC_STR) {
                  _.forEach(site.roles,(role)=> role.name===ROLE_NAME.HC ? roleRefId.push(role.refId) : null);
                  hasCredential = !!key.hasCredential;
                  mentorRD = !hasCredential ? key.mentorRD : undefined;
                }
            })
        })
        const phoneArray = [];
        if (this.state.basicInfor.phone) phoneArray.push({'countryCode':'+1','number':this.state.basicInfor.phone,'type':'MOBILE',canUseForLogin: true});
        let capacitys = value.reduce((res,cur)=>{
            const { role,capacity } = cur;
            if(CAPACITY_ROLE.includes(role)) {
                res.push({
                    role,
                    capacity: capacity || ROLE_DEFAULT_CAPACITY[role]
                })
            }
            return res;
        },[]);
        const variables = {
            role: roleRefId,
            username: this.state.basicInfor.id?this.state.basicInfor.id:this.state.basicInfor.email,
            email: this.state.basicInfor.email,
            phone: phoneArray,
            isTestUser: this.state.basicInfor.isTestUser,
            capacitys,
            commonProfile: {
                firstName: this.state.basicInfor.firstName,
                lastName: this.state.basicInfor.lastName
            },
            employeeProfile: {
              startDate: this.state.basicInfor.startDate,
              NPI: this.state.NPI,
              mentorId:mentorRD,
              hasCredential,
              providerLanguage: this.state.basicInfor.providerLanguage,
              directAddress: this.state.basicInfor.directAddress
            }
        }
        const execMutation = async () => {
          try {
            await API.createEmployee(variables);
            // const userId = _.get(res, 'data.createUser.id');

            //await API.resendOnBoardEmailLink({ id: userId });
            message.success('Invitation link sent');
          } catch(err) {
            message.error(err.message);
          } finally {
              this.fetchUser({});
              this.fetchUserCount();
            // this.setState({loading:true,page:0,basicInfor:{},list:[]}, () => {
            //   helper.getMembersByAppRoles().then((data)=> {
            //     this.setState({memberList:data.data.userList.data, loading:data.loading});
            //   });
            // });
          }
        };

        execMutation();
    }

    setNPI = (NPI)=>{
        this.setState({
            NPI
        });
    }
    getOptions = () => {
        const { providerList, searchStr, loadingSearch } = this.state;
        const options = [];
        // if canSearch is false, meaning haven't done any search yet
        // if no searchStr, meaning no search action
        if(loadingSearch) return;
        _.forEach(providerList, (key) =>{
            const s = allRoles(_.get(key, 'allRoles'));
            const nameStyle = {
              fontSize: 14, color: '#222222', fontWeight: 'medium'
            };
            const roleStyle = {
              fontSize: 12, color: '#717171', fontWeight: 'regular'
            };
            if (s) {
                const providerName = _.get(key, 'profile.fullName');
                if(_.includes(_.toLower(providerName), _.toLower(searchStr))){
                  options.push( <Option key={key.id} value={key.id} className='providerItem'>
                    <Row key={providerName}>
                      <Col style={nameStyle}>{providerName}</Col>
                      <Col style={roleStyle}>{s}</Col>
                    </Row>
                  </Option>)
                }
            }}
          )
        // if til here, options is still empty, means cannot find the matching provider
        if(options.length === 0) {
          options.push( <Option key='no-user' disabled>
            No Provider
          </Option>)
        }
        return options;
    }

    handleSelect = (userId) => helper.addPrefixAndGoTo(`users/${userId}`);
    onSearchChange = async searchStr => {
        if( !searchStr || (searchStr && searchStr.trim().length==0)) return;
        const { selectedTab } = this.props;
        const rootId = helper.getViewerOrgId();
        this.setState({
            loadingSearch: true,
            providerList: []
        })
        const data = await helper.getMembersByAppRoles({
            rootId,
            roleName:roleMap['ALL'],
            search: {fields: ['NAME'],match: [searchStr]},
        });
        const providerList = _.get(data,'data.userList.data',[]);
        this.setState({
            providerList:[...providerList],
            loadingSearch: false
        })

    };
    handlerBack = (value, role) => {
      this.setState({page : 0, list : value, currRole: role});
    }
    onChangeTab = (tab)=>{
        this.setState({tabSelected:tab});
        this.fetchUserList({ roleName: roleMap[[tab]] });
    }
    getSummaryCount = (summary,t)=>{
        const ROLE_HEADER_MAP = {
            "CA":summary['CA'],
            "RD":summary['RD'],
            "HC":summary['HC'],
            "MA":summary['MA'],
            "MD":summary['MD'],
            [AREA_MANAGER_STR]:summary['AREA_ADMIN'],
            [CLINIC_MANGER_STR]:summary['SITE_ADMIN'],
            'Clinic Members': summary['MA'] + summary['MD']
        }
        return ROLE_HEADER_MAP[t]||0;
    }
    render() {
        // if (this.state.loading) {
        //     return <IHLoading/>
        // }
        const { siteAdmin,areaAdmin } = this.state;
        return (
            <div style={{backgroundColor:'white'}}>
                <div>
                    <Row className='userRowWrapper'>
                        <Col span={16}>
                          <AutoComplete
                              dataSource={this.getOptions()}
                              className='search-provider-autocomplete'
                              dropdownClassName='search-provider-dropdown'
                              onSearch={ _.debounce(this.onSearchChange,SEARCH_INTERVAL)}
                              onSelect={this.handleSelect}
                              defaultActiveFirstOption={false}
                              autoFocus={true}
                            >
                              <Input.Search  className='search-provider-input'
                                      placeholder='Search by name'
                                      loading={this.state.loadingSearch}
                              />
                            </AutoComplete>
                        </Col>
                        <Col span={8}>
                            <Button className={'CreateNewUser'} type={"primary"} onClick={this.showModal}>Create new user</Button>
                        </Col>
                        <Modal
                        wrapClassName="user-modal-wrap"
                        visible={this.state.visible}
                        style={{minWidth:'768px'}}
                        footer={null}
                        onCancel={this.onCancel}
                        title="Add a new User"
                        destroyOnClose
                        >
                            <Col span={8}>
                                <Steps direction="vertical" current= {this.state.page} size='small' onChange={()=>{this.setState({page:0})}}>
                                <Step key='Basic information' title='Basic information'/>
                                <Step key='Setup role' title='Setup role'/>
                                </Steps>
                            </Col>
                            <Col span={16}>
                                {this.state.page === 0?<FirstPage handlerNext={this.handlerNext} basicInfor={this.state.basicInfor}/>:<SecondPage
                                handlerBack={this.handlerBack}
                                currRole={this.state.currRole}
                                list={this.state.list} 
                                closeModal={this.onCancel}
                                setNPI={this.setNPI}
                                createHandler={this.createHandler}
                                organization={this.props.organizationList.data}
                                popUpVisible={this.state.popUpVisible}
                                NPI={this.state.basicInfor.NPI}
                                email={this.state.basicInfor.email}/>}
                            </Col>
                        </Modal>
                    </Row>
                </div>
                <div>
                    <Tabs defaultActiveKey="RD" className='userTabs' onChange={(k)=> this.onChangeTab(k)}>
                    {
                      _.map(tabs,(t)=>{
                        const {  areaFilters, siteFilters, areaForAdminFilters } = getTableData(this.state.summaryList);
                        const { users } = getTableData(this.state.memberList);
                        const { summary } = this.state;
                        return <TabPane key={t} tab={`${t}(${this.getSummaryCount(summary,t)})`}>
                          <UserListComponent 
                            tabSelected={t} 
                            dataSource={users[t]}
                            pageInfo={this.state.pageInfo}
                            areaFilters={areaFilters[t]}
                            siteFilters={siteFilters[t]}
                            fetchUserList={this.fetchUserList}
                            loading={this.state.loading}
                            siteAdmin={siteAdmin}
                            areaAdmin={areaAdmin}
                          />
                        </TabPane>})
                    }
                </Tabs>
                </div>
            </div>
        )
    }
}


  const organizationListData = graphql(query.orgListForCreateUser, {
    options: (ownProps) => {
      const variables = {page:1,count:9999};
      return {
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: ({ data }) => {
      return {
        organizationList: data.organizationList,
        loading: data.loading,
        refetch: data.refetch
      }
    }
  })

export default compose(organizationListData)(User)