import React from 'react';
import { Row,Col } from 'antd';
import ReferAndEnrollButtonWrapper from '../container/ReferAndEnrollButtonWrapper';
const CreatedPatientSuccessComponent = class extends React.Component{
	renderHeader = (fullName)=>{
		return <h4>{`Great! You’ve successfully added ${fullName} to our system!`}</h4>
	}
	renderBody = ()=>{
		return <div style={{ margin:'20px 0',marginBottom:'150px' }}>
					<p>Would you like to refer the patient today? Or directly enroll?
					(If the patient had a visit today, you can bill an extra G0506 code if patient enrolled)?</p>
			   </div>
	}

	render() {
		const { renderHeader,props,renderBody } = this;
		const { fullName,closeModal,memberId } = props;
		return <Row style={{ padding:'30px' }}>
				<Col>
					{renderHeader(fullName)}
				</Col>
				<Col>
					{renderBody()}
				</Col>
					<ReferAndEnrollButtonWrapper btnType={'CREATE_PATIENT'} closeModal={closeModal} memberId={memberId}/>
			  </Row>
	}
}
export default CreatedPatientSuccessComponent