import React from 'react';
import tableMap from './StaffTableMap';
import {IHSearchTable} from 'ihcomponent';
import '../style.scss';
import helper from '../../helper';
import {AREA_MANAGER_STR, CLINIC_MANGER_STR} from "../../constant/roleStrMap";
import { defaultSort } from '../../helper/index';
import { ROLE_NAME } from '../../../../lib/constants';

export const keyMap = {
    name:'LAST_NAME',
    startDate:'STARTED_DATE',
    registeredAt:'REGISTERED',
}
export const directionMap = {
    ascend:'ASC',
    descend:'DESC'
}
export const roleMap = {
    "CA":['CA'],
    "RD":['RD'],
    "HC":['HC'],
    "MA":['MA'],
    "MD":['MD'],
    [AREA_MANAGER_STR]:['AREA_ADMIN'],
    [CLINIC_MANGER_STR]:['SITE_ADMIN'],
    // 'Clinic Members': ['MA','MD','BILLER'],
     'Clinic Members': ['MA','MD','PARTNER_BILLER'],
    'ALL':['RD','CA','HC','MA','MD','AREA_ADMIN','SITE_ADMIN','PARTNER_BILLER'],
    'ADMIN:Admin': 'Admin',
    'SUPER_ADMIN:Super Admin': 'Super Admin',
    'Nurse':'RD',
    'Doctor':'Provider',
    'Clinic Medical Assistant':'MA',
    'Medical Assistant':'CA',
    'PROVIDER:Doctor':'Provider',
    "PROVIDER:Clinic Medical Assistant":'MA',
    'PROVIDER:Medical Assistant':'CA',
    'PROVIDER:Nurse':'RD',
    'OTHER:Vendor': 'Vendor',
    [ROLE_NAME.HC]: 'HC',
    [`PROVIDER:${ROLE_NAME.HC}`]: 'HC',
    'Ihealth Members':['RD','CA','HC'],
    'TEAM_MEMBERS':['RD','CA','HC','MA','MD']
}
class UserListComponent extends React.Component {
    onTableChange = (page,filter,sorter)=>{
        const { fetchUserList,tabSelected,loading } = this.props;
        const { pageSize,current } = page
        const { columnKey, order } = sorter;
        const newSorter = columnKey && order ? {
            field: keyMap[columnKey],
            direction:directionMap[order]
        } : defaultSort;
        console.log(filter,sorter,page);
        const roleNameFromFilter = _.get(filter,'role',[]);
        const roleName = roleNameFromFilter.length > 0 ? roleNameFromFilter : roleMap[tabSelected];
        fetchUserList({ roleName ,total:pageSize,page:current,sort: newSorter  })
    }
    render() {
        const onRow=(row)=>{
          return !row.expanded ? {
            onClick: () => helper.addPrefixAndGoTo(`users/${row.userId}`)
          } : {};
        };
        const { total } = this.props.pageInfo;
        const { loading } = this.props;
        const pagination = {
            total,
            pageSize: 10,
        }

        return (
            <div>
                <IHSearchTable
                  onRow={onRow} 
                  columns={tableMap(this.props.tabSelected,this)}
                  indentSize={0}
                  className='userTable'
                  loading={loading}
                  pagination={ pagination }
                  dataSource={this.props.dataSource}
                  rowKey={r => r.rowKey}
                  onChange={((a,b,c)=>this.onTableChange(a,b,c))}
                />
            </div>
        )
    }
}

export default UserListComponent;