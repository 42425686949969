import configs from '../../../lib/config';

const domain = configs.UC_SERVER_URL;

// https://dev-uc.ihealth-eng.com/swagger-ui/index.html#/patient-device-controller/getAvailableDevices
export const searchCellularDevicesPath = `${domain}/patient/{memberId}/available-devices`;
export default (
  params, 
  requestOptions
) => {
  const { 
    // patientId,
    searchStr,
    deviceType,
  } = params || {};

  const bodyParams = {
    filter: {
      deviceIdNear: searchStr,
      deviceType,
    },
  };

  const sessionToken = sessionStorage.getItem('authToken') || '';
  const fetchOptions = {
    method: 'POST',
    headers: {
      'x-session-token': sessionToken,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(bodyParams),
    ...requestOptions
  };

  const searchCellularDevicesUrl = `${searchCellularDevicesPath.replace('{memberId}', '1')}`;

  return fetch(
    searchCellularDevicesUrl, 
    fetchOptions).then((res) => res.json()
  );
};