import React from 'react';
import { Form } from 'antd';
import { CellularDevicesDropdownComponent } from '../CellularDevicesDropdownComponent/CellularDevicesDropdownComponent';
import { cellularDeviceBrandOptions } from '../../constants';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularDeviceBrandDropdownFormItemComponent = ({
  form,
}) => (
  <Form.Item label="Device Type">
    {
      form.getFieldDecorator(CellularDevicesHelpers.deviceBrandFieldName, {

      })(
        <CellularDevicesDropdownComponent
          options={cellularDeviceBrandOptions}
        />
      )
    }
  </Form.Item>
);
