import { React, _, IHSearchTable, moment, IHButton } from 'ihcomponent';
import { browserHistory } from 'react-router';
import Store from 'libModule/reduxStore'

export default class extends React.Component {

  componentWillUnmount() {
    if (!new RegExp(/^((\/admin_preview_broadcast)|(\/edit_broadcast))/).test(Store.getState().routing.location.pathname)) {
      this.props.onLoadParams(null, true);
    }
  }
  getTableProps() {
    const { onTableChange, onTableSearch, onSearchEnter, data, filter, sort, page, search = '' } = this.props;
    const { loading = true, broadcastList } = data;
    return {
      title: `Broadcasts (${_.get(broadcastList, 'pageInfo.total', 0)})`,
      rowKey: 'id',
      onChange: onTableChange,
      loading,
      dataSource: _.get(broadcastList, 'data', []),
      pagination: {
        current: _.get(page, 'current', 1),
        pageSize: _.get(page, 'pageSize',10),
        total: _.get(broadcastList, 'pageInfo.total', 0),
      },
      columns: [
        {
          title : 'Title',
          dataIndex : 'title',
          key : 'title'
        },
        {
          title : 'Message',
          dataIndex: 'message',
          key: 'message'
        },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          sortOrder: _.get(sort, 'field', null) === 'createdAt' ? _.get(sort, 'order', false) : null,
          render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : '',
        },
        {
          title: 'Start Date',
          dataIndex: 'startTime',
          key: 'startTime',
          sorter: true,
          render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : '',
          sortOrder: _.get(sort, 'field', null) === 'startTime' ? _.get(sort, 'order', false) : null,
        },
        {
          title: 'End Date',
          dataIndex: 'endTime',
          key: 'endTime',
          render: value => value ? moment(value).format('MMM-DD-YYYY hh:mm a') : ''
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (value, row)=> (
            <IHButton
              label='Edit >'
              bsStyle="primary" className="ih-exsmall"
              onClick={() => {
                browserHistory.push(`/broadcasts/edit/${row.id}`)
              }}
            />
          )
        }
      ]
    };
  }

  render() {
    const buttonProps = {
        label : 'Create New Broadcast',
        size : '',
        type : 'primary',
        onClick : ()=>{
          browserHistory.push(`/broadcasts/new`)
        }
    }

    return (
      <div className="vsm-main-page vsm-provider-list-main">
        <div style={{textAlign:'right', marginBottom:'12px'}}>
            <IHButton {...buttonProps} />
        </div>
        <div className="vsm-main-table">
            <IHSearchTable {...this.getTableProps()} />
        </div>
      </div>
    );
  }
}
