const Measure = `
  id
  date
  type
  user_notes
  ... on BPMeasure {
    systolic_blood_pressure
    diastolic_blood_pressure
    heart_rate
  }
  ... on BGMeasure {
    blood_glucose
    beforeMeal
    mealType
  }
  ... on HSMeasure {
    body_weight
  }
  ... on HRMeasure {
    heart_rate
  }
  ...on EXERCISEMeasure {
    steps
    
  }
`
export default Measure
