import React from 'react';
import { setAsyncRouteLeaveHook } from 'libModule/utils'
import {Row, Col, Layout, Button, Icon, Popconfirm, Badge} from 'antd';
import modalActions  from 'modulesAll/common/actions/modal'
import moment from 'moment';
import {compose} from "react-apollo/index";
import {connect} from "react-redux";
import { openErrorModal } from 'layoutModule/actions/MainModal'
import taskListFormMap from '../constants/taskListFormMap';
import convertor from "@ihealth/convertor/index";
import { convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import { IHButton } from 'ihcomponent';
import {API} from "../query";
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import EditVisitInDashboardContainer from "../../visitNewWorkFlow/containers/EditVisitInDashboardContainer";
import WorkListContainer from '../../visitWorkList/container/workListContainer';
import { helpers as visitHelpers } from '../helpers';
import I18N from '../../I18N';
import { VITALS_ORDER } from '../../../lib/constants';
import {helpers} from "../../visitNewWorkFlow/helper";
import "../../careplanNew/style/charting.styles.scss";
import {getVisitStatus, visitStatusContent, renderRescheduledVisit, checkIfRescheduled} from "../../visitsPage/helpers";
import {statusMap} from "../../visitsPage/constants";
const VISIT_ORDER = {
  'MA':0,
  'MD':1,
  'RD':2,
  'CA':3,
};

const renderTaskNameAndReading = (type,value)=>{
    const map = {
        "a1cTest":{
            text:"Hba1C",
            render:()=>{
                if(value){
                    return <Row style={{display:'flex',flexDirection:'column',width:'100%'}}>
                        <Col span={24} style={{ minHeight: 21,marginLeft: 5 }}>{`  -  ${ value } %` }</Col>
                    </Row>
                }
            }
        },
        "height":{
            text:"Height",
            render:()=>_.get(value,'value') ?
                        ' - ' + convertInchesToFeet(convertor.height(value.value, 'cm', 'inch', 0)) +"'"+ (
                       _.get(value,'value') ? ( getRemainderAfterConvertingToFeet(convertor.height(value.value, 'cm', 'inch', 0)) +`"` ):'')
                       : ''
        },
        "weight":{
            text:"Weight",
            render:()=>value ? ' - '+convertor.HS(_.get(value,'value'), 'kg', 'lbs').toFixed(1) +' lbs' : '',
        },
        "waistHipRatio":{
            text:"W/H Ratio",
            render:()=>value ? (' - ' + value ):''
        },
        "bpMeasurement":{
            text:'BP',
            render:()=>{
                    if(value) {
                        const systolic_blood_pressure_value = _.get(value, 'systolic_blood_pressure.value', false);
                        const diastolic_blood_pressure_value = _.get(value, 'diastolic_blood_pressure.value', false);
                        const hasSysAndDia = systolic_blood_pressure_value && diastolic_blood_pressure_value;
                        const heart_rate_value = _.get(value, 'heart_rate.value', false);
                        return <Row style={{display:'flex',flexDirection:'column',width:'100%'}}>
                                <Col span={24} className='taskItem' style={{ minHeight: 21 }}>{ hasSysAndDia ?
                                    ` - ${systolic_blood_pressure_value} / ${diastolic_blood_pressure_value} mmHg` :''}</Col>
                                <Col span={24} className='taskItem' style={{marginLeft:'-39px',marginTop:10}}>
                                    <Icon className = { heart_rate_value ? 'completedTask' :'pendingTask' }
                                          type = { heart_rate_value!='' ? "check-circle" : 'close-circle' }
                                    />
                                    <span style={{ marginLeft: 5,marginRight: 5 }}>Heart Beat</span>{ heart_rate_value ?  ` - ${heart_rate_value} b/min` : ''}
                                </Col>
                               </Row>
                    }
            }
        },
        "eyeExam":{
            text:"Eye Exam",
            render:()=>value
        },
        "footExam":{
            text:"Foot Exam",
            render:()=>value
        },
        "urineTest":{
            text:"Urine Exam",
            render:()=>value
        },
        "mpTest":{
            text:"Blood Labs",
            render:()=>value
        },
        "addToContactsStatus":{
            text: "Add Number",
            render:()=>value
        },
        "autoUpdateStatus":{
            text: "Set up update",
            render:()=>value
        },
        "appIsUptoDate":{
            text: "App is up to date",
            render:()=>value
        }
    }
    if(!map[type]){
        return <div></div>
    }
    return <div style={{display:'flex',width:'100%'}}>{ map[type].text }{map[type].render()}</div>;
}

const PatientVisitCardComponent = class extends React.Component {
    constructor(pros) {
        super(pros);
        this.state = {
            editStatus: false,
            isSaved : true,
            showEditModal: false,
            showTaskModal: false,
            rescheduledFromId: null
        }

        this.onCancelReschedule = this.onCancelReschedule.bind(this);
    }
    renderHeader = () =>{
        const visit = _.get(this,'props.visit', {} );
        const {
          appointmentAt,checkinAt,checkoutAt, category, id, member
        } = visit;
        const appointmentAtString = moment(appointmentAt).format('MMM DD, hh:mm a');
        let isCheckedIn = checkinAt && moment().isAfter(checkinAt);
        let isCheckedOut = checkoutAt && moment().isAfter(checkoutAt);

        return <Row style={{ padding:'10px 20px 0 20px' }}>
                  <Col span={10}>
                      <span style={{ fontSize: 16.5,fontWeight:'bold' }} >
                        {appointmentAtString}
                        {
                          visitHelpers.patientIndicator(member, category)
                                      .render({ marginLeft: 8, marginRight: 0 }, { width: 16.5 })
                        }
                      </span>
                  </Col>
                  <Col span={4}>{ visitStatusContent(this.props.visit)}</Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    {
                      isCheckedIn ?
                        <p>
                          { isCheckedIn && <span style={{ marginRight: 10 }}>Check-in: {moment(checkinAt).format('hh:mm a')}</span>}
                          { isCheckedOut && <span>Check-out: {moment(checkoutAt).format('hh:mm a')}</span>}
                        </p>
                      :
                        this.renderButtons( visit )
                    }
                  </Col>
                </Row>
    }


    renderButtons =  ( visit ) =>{
        const { id,rescheduledToVisit }  = visit;
        const { isNoShow,visitRescheduled } = checkIfRescheduled(visit);
        if(isNoShow&&visitRescheduled) {
            return renderRescheduledVisit(rescheduledToVisit);
        }
        const editText = isNoShow ? 'Reschedule' : 'Edit';
        return <Row gutter={8} style={{ display:'flex',width:'100%',justifyContent:'flex-end' }}>
                  <Col style={{ marginRight: 5 }}>
                      { isNoShow  ? '': <Button onClick={()=>this.showConfirmForCancel(id)}>Delete</Button> }
                  </Col>
                  <Col>
                      <Button onClick={()=>this.reschedule(id,isNoShow)}>{editText}</Button>
                  </Col>
               </Row>
    }

    handleEditContent(){
        this.setState({
            editStatus: true
        })
    }

    setShowTaskModal = (visit)=>{
        this.setState({
            showTaskModal:visit
        })
    }

    renderTaskList = (tasks, details, checkinAt) => {
        const content = <Col span={20}>
                            {
                                _.map(tasks,(lists,index) =>{
                                const spanValue = index == 1 ? 11 : index == 2 ? 5 : 8;
                                return <Col span={spanValue} key={index}>
                                   {_.map(lists,(t,i)=>{
                                       const value = _.get(details,`${t}`,false);
                                        const isCompleted = _.isString(value) ? value=='COMPLETED' : value;
                                        return <Row key={`${index}-${i}`} style={{ margin:'0 0 10px',display:'flex'}}>
                                                <Icon className = {isCompleted ? 'completedTask' :'pendingTask' }
                                                      onClick={()=>this.setShowTaskModal(null)}
                                                      type={ isCompleted ? "check-circle" : 'close-circle' } style={{ marginRight:5,marginTop:5 }}/>
                                                      { renderTaskNameAndReading(t) ?
                                                        renderTaskNameAndReading(t, _.isObject(isCompleted) || _.isNumber(isCompleted) ? isCompleted: ''  ) : t
                                                      }
                                               </Row>
                                    })
                                   }
                                </Col>})
                            }
                        </Col>;

        const editButton = <p onClick={this.handleEditContent.bind(this)}
                              style={{float: 'right', clear: 'right', cursor: 'pointer',
                                        textDecoration:'underline', marginRight: '20px', marginBottom: '5px'}}
                            >Edit task Information</p>

        return  <div>
                    <div style={{ padding:'20px 20px 0' }}>
                        <Col span={4}><span style={{ fontSize: 16,fontWeight:'bold' }}>Task list:</span></Col>
                        { content }
                    </div>
                    <div>
                        {checkinAt && !this.props.isInChat? editButton : ''}
                    </div>
                </div>

    }

    renderNote = (note) =>{
        return <div className='scrollable-content'>
                <span style={{lineHeight:1.2}}>{note}</span>
               </div>;
    }

    reschedule = (id,isNoShow) => {
      //  goPath(`/appointments/reschedule/${id}`);

        this.setState({
            showEditModal: true,
            rescheduledFromId: isNoShow ? id : null
        })

    }

    onCancelReschedule(){
        this.setState({
            showEditModal: false,
            rescheduledFromId: null
        })
    }

    deleteVisit = (id) => {
        this.props.deleteVisit(id);
    }

    showConfirmForCancel = (id)=>{
        return new Promise((resolve)=>{
            const Yes = {
                label: 'Yes, Cancel Appointment',
                handler: ()=>{
                    resolve(this.deleteVisit(id));
                },
                style:{
                    border: 'solid 1px #aaaaaa',
                    color:'red',
                    background:'white',
                    textShadow:'none'
                },
                closeModal: true
            }
            const No = {
                label: 'No',
                style:{
                    border: 'solid 1px #4278c3',
                    color:'#4278c3',
                    background:'white',
                    textShadow:'none'
                },
                handler: () => {
                    resolve(false)
                }
            }
            const modalProps = {
                Yes,
                No,
                title: 'Cancel Appointment?',
                content: 'Are you sure you want to cancel this appointment? This action can not be undone.'
            }
            this.props.openConfirmModal(modalProps)
        });
    }

    createTaskListMapBasedOnTasks=(tasks)=>{
        let keys = {};
        _.forEach(tasks,(t)=>keys[`${t.key}`] = t.key );
        let cloneMap =  _.cloneDeep(taskListFormMap);
        for(let arrayIndex in cloneMap){
            let array = cloneMap[arrayIndex];
            _.remove(array,(i,v)=>!keys[i]||i=='bgMeasurement');
            cloneMap[arrayIndex] = array;
        }
        return cloneMap;
    }

    confirm(id,type){
        API.updateVisit(id,type)
            .then((res)=>{
                this.props.refetch();
            })
            .catch((error)=>{
                console.log(error);
            })
    }

    renderCheckOperation = (id, checkIn, checkOut, appointmentAt) => {

        const isAfterToday = moment(appointmentAt).isAfter(new Date(),'day');

        const checkInButtonProps = {
            label: 'Check In',
            bsStyle: 'primary',
            className: 'table-action-button',
        };

        const checkOutButtonProps = {
            label: 'Check Out',
            bsStyle: 'primary',
            className: 'table-action-button',
        }

        if (checkIn && checkOut) {
            const checkInTime = moment(checkIn).format('HH:mm');
            const checkOutTime = moment(checkOut).format('HH:mm');
            return (
                <div>
                    <p className='check-information-p'>Checked in at {checkInTime}</p><br/>
                    <p className='check-information-p'>Checked out at {checkOutTime}</p>
                </div>
            )
        } else if (!checkIn && !isAfterToday) {
            return (
                <div className='checkConfirm'>
                    <Popconfirm
                        className='checkPopUp'
                        title='Check in patient ?'
                        onConfirm={() => {
                            Mixpanel.track('check in', 'confirm', 'patient profile', {PATIENT_ID: id})
                            this.confirm(id, 'checkIn')
                        }}>
                        <IHButton {...checkInButtonProps}/>
                    </Popconfirm>
                </div>
            )
        } else if(!checkOut && !isAfterToday) {
            const checkInTime = moment(checkIn).format('HH:mm');
            return (
                <div className='checkConfirm'>
                    <p className='check-information-p'>Checked in at {checkInTime}</p><br/>
                    <Popconfirm
                        className='checkPopUp'
                        title='Check out patient ?'
                        onConfirm={() => {
                            Mixpanel.track('check out', 'confirm', 'patient profile', {PATIENT_ID: id})
                            this.confirm(id, 'checkOut')
                        }}>
                        <IHButton {...checkOutButtonProps}/>
                    </Popconfirm>
                </div>
            )
        }else{}
    }

    handleOnModify = () =>{
        this.setState({
            editStatus: false
        })
    }

    setIsSaved = (isSaved)=>{
        this.setState({
            isSaved
        })
    }


    routerWillLeave=()=>{
        const { openConfirmModal } = this.props;
        const { isSaved } = this.state;

        return new Promise((resolve, reject) => {
            if (isSaved){
                resolve(true)
                return;
            }

            const Yes = {
                label: 'Confirm',
                handler: () => {
                    resolve(true)
                },
                closeModal: true
            }

            const No = {
                label: 'Cancel',
                handler: () => {
                    resolve(false)
                }
            }

            const modalProps = {
                Yes,
                No,
                title: 'Leave This Page?',
                content: 'Please confirm if you would like to leave this page without saving your changes'
            }
            openConfirmModal(modalProps)
        })
    }

    componentWillMount() {
        if(this.props.route && this.props.router){
            setAsyncRouteLeaveHook(this.props.router, this.props.route, this.routerWillLeave.bind(this));
        }
    }

    renderVisit() {
        const { visit } = this.props;
        const value = _.get(visit,'workingListStatus');
        let visitRolesSpan, vitalsSpan;
        if(visit.visitRoles && visit.visitRoles.length > 0){
          visit.visitRoles.sort((v1, v2) => (VISIT_ORDER[v1] - VISIT_ORDER[v2]));
          visitRolesSpan = <span>
            {`${I18N.get('visit.details.visitRoles.label')}: `}
            {visit.visitRoles.join('/')}
          </span>;
        }
        if(visit.vitalTypes && visit.vitalTypes.length > 0){
          const vitalsInOrder = _.intersection(VITALS_ORDER, visit.vitalTypes);
          vitalsSpan = <span>
            {`Vitals: `}
            {vitalsInOrder.join('/')}
          </span>;
        }
        const reason = 'RPM: ' + visit.reason;
        let href = '';
        const isMAorMD = helpers.checkIsMAOrMD();
        if(!visit.confirm&&!isMAorMD) {
            href = '/image/init_file.png';
        } else {
            switch (value) {
                case 'TODO':
                    href = '/image/init_confirmed_file.png';
                    break;
                case 'PENDING':
                    href = '/image/pending_file.png';
                    break;

                case 'COMPLETED':
                    href = '/image/done_file.png';
                    break;

                default:
                    href = '/image/init_confirmed_file.png';
                    break;
            }
        }
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {visitRolesSpan || ''}
                  {vitalsSpan || ''}
                </div>
                <div style={{overflow:'auto', maxHeight:300}}>{reason}</div>
                 <React.Fragment>
                    { !visit.confirm&&!isMAorMD ?
                        <img src={href} style={{ width:'22px', height:'25px',cursor:'pointer'}}/> :
                        <img src={href} style={{ width:'22px', height:'25px',cursor:'pointer'}}
                             onClick={()=>this.setShowTaskModal(visit)}
                        />
                    }
                </React.Fragment>
            </div>
        )
    }

    renderTaskModal = () => {
      const { setShowTaskModal, props: { refetch: refetchVisitList, timerName } } = this;
      const visitId = _.get(this, 'state.showTaskModal.id');
      const appointmentAt = _.get(this, 'state.showTaskModal.appointmentAt');
      const memberId = _.get(this, 'state.showTaskModal.member.id');
      return visitId && <WorkListContainer
        id={visitId}
        appointmentAt={appointmentAt}
        memberId={memberId}
        onCancel={() => {
            if (refetchVisitList) refetchVisitList();
            setShowTaskModal(null);
        }}
        timerName={timerName}
        onCloseModal={() => setShowTaskModal(null)}
      />;
    };

    render(){
        const { visit } = this.props;
        const { rescheduledFromId } = this.state;
        const { tasks } = visit;
        let {details} =  visit;
        const addToContactsStatus = {
            key: 'addToContactsStatus',
            value: 'PENDING',
            __typename: 'VisitTask'
        };
        const autoUpdateStatus = {
            key: 'autoUpdateStatus',
            value: 'PENDING',
            __typename: 'VisitTask'
        };
        const appIsUptoDate = {
            key: 'appIsUptoDate',
            value: 'PENDING',
            __typename: 'VisitTask'
        };
        tasks.push(addToContactsStatus, autoUpdateStatus, appIsUptoDate);
        if(details && details.addToContactsStatus && details.addToContactsStatus === 'COMPLETED'){
            addToContactsStatus.value = 'COMPLETED';
            if(!details){
                details = {addToContactsStatus : 'COMPLETED'};
            }
        }
        if(details && details.autoUpdateStatus && details.autoUpdateStatus === 'COMPLETED'){
            autoUpdateStatus.value = 'COMPLETED';
            if(!details){
                details = {autoUpdateStatus : 'COMPLETED'};
            }
        }
        if(details && details.appIsUptoDate && details.appIsUptoDate === 'COMPLETED'){
            appIsUptoDate.value = 'COMPLETED';
            if(!details){
                details = {appIsUptoDate : 'COMPLETED'};
            }
        }

        return <Layout className='PatientVisitCard'>
                <Row style={{ minHeight: '50%',backgroundColor:'#f7f7f7'}} >
                   <Col span={24} className='PatientVisitCardHeader'>
                       { this.renderHeader() }
                   </Col>
                   <Col span={16} style={{ padding:'5px 0 20px 20px' }}>
                       {this.renderVisit()}
                   </Col>
                </Row>
                {this.state.showTaskModal && this.renderTaskModal()}
                {
                  this.state.showEditModal &&
                  <EditVisitInDashboardContainer
                    refetchVisitListInPatientProfile={this.props.refetch}
                    refetchUser={this.props.refetchUser}
                    refetchNextVisit={this.props.refetchNextVisit}
                    visitInfo={visit}
                    rescheduledFromId={rescheduledFromId}
                    patient={this.props.patient}
                    onCancel={this.onCancelReschedule}
                    timerName={this.props.timerName}
                  />
                }
               </Layout>
    }
}

const mapToDispatch = (dispatch)=>{
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error)),
        openConfirmModal: (props) => dispatch(modalActions.confirm(props)),
    }
}
export default compose(connect(null, mapToDispatch))(PatientVisitCardComponent);
// export default  PatientVisitCardComponent;
