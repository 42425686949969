import React from 'react';
import { Button, message, Popconfirm, /*Popover*/ } from 'antd';
// import NumberFormat from 'react-number-format';

import visitTabConfig from './visitTabConfig';

import { formatDate, APPOINTMENT_TIME_FORMAT } from '../helpers/visitDateHelpers';
import { checkIfShouldDisableAction } from '../helpers';
import visitAPI from '../../VideoChat/helper';
import { VIDEO_STR, ENROLL_FROM } from '../../../lib/constants';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import EnrollPatientContainer from '../../patient/EnrollPatient/container/EnrollPatientContainer';
import { BillableReviewFormPopup } from '../../visitWorkList/component/BillableReviewForm';

// import { makePhoneCall } from '../../utils/helpers/ccpHelpers';

export const VISIT_TABS = visitTabConfig;

const VISIT_TIME_RANGE = [-2*60, 20 ]; // within 2 hours before and 3 hours after
const VISIT_COUNTDOWN_TIME = -30; // 30 minutes before visit
const VISIT_STATUS_COLOR_ENUM = {
  red: '#E32215',
  blue: '#3177C9',
  yellow: '#F2C81A',
  green: '#62C046',
  grey: '#AAAAAA',
}

export const ENROLLED_STATUS = ['STARTED', 'CREATED'];

export const POTENTIAL_CCMRPM_LIST = ['HTN', 'DM', 'PreDM', 'Obesity'];

export const VISIT_CATEGORY_ENUM = {
  VIRTUAL: 'VIRTUAL',
  INCLINIC: 'INCLINIC',
  PHONE: 'PHONE'
};

export const VISIT_CATEGORY_MAPPING = {
  VIRTUAL: 'Virtual',
  INCLINIC: 'In-clinic',
  PHONE: 'Phone'
};

export const VISIT_FILTERS_MAP = {
  //-- for in-table filters
  // filterByName,

  //-- for advanced filters
  filterByCategory: 'filters.categories',
  filterByType: 'filters.types',
  // filterByPCP,
  // filterByVitals,
  filterByMD: 'filters.providerIds',
  filterByRD: 'filters.assignedToRD',
  filterByCA: 'filters.assignedToCA',
  filterByHC: 'filters.assignedToRD'
};

export const WORKING_LIST_STATUS_MAPPING = {
  TODO: {
    imgSrc: '/image/init_confirmed_file.png',
    imgProps: { width: 19, height: 22.5 }
  },
  PENDING: {
    imgSrc: '/image/pending_file.png',
    tip: 'Chart incomplete'
  },
  COMPLETED: {
    imgSrc: '/image/done_file.png',
    tip: 'Chart completed'
  }
};

const actionButtonProps = {
  block: true,
  style: { }
};

const virtualVisitAction = ({ record, setShowVirtualVisit }) => {
  const isTechVisit = record.type === 'TECH';
  return (
    <Popconfirm
      overlayClassName='visits-checkin-confirm'
      placement='topRight'
      onConfirm={() => visitAPI.handleOpenVideoVisit(record, setShowVirtualVisit)}
      title={(
        <div className='wrapper'>
          <span className='title'>Open video and check in this appointment?</span>
          <div className='description'>
            Please try <span className='highlight'>NOT</span> to limit your conversation to just tech issues. Try to review other billable topics as well, like scheduling, reminder on labs, medications, inactivity, vitals monitoring encouragement, etc.
          </div>
        </div>
      )}
      disabled={!record.joinable || !isTechVisit || record.checkinAt}
    >
      <Button 
        {...actionButtonProps}
        type='primary'
        disabled={!record.joinable} 
        onClick={() => {
          if(!isTechVisit || (isTechVisit && record.checkinAt)) {
            visitAPI.handleOpenVideoVisit(record, setShowVirtualVisit);
          }
        }}
      >
        {VIDEO_STR['OPEN']}
      </Button>
    </Popconfirm>
  )
};

const renderCheckinButton = ({ record }) => {
  const isValidTechVisit = record.type === 'TECH' && record.category !== 'INCLINIC';
  return (
    <Popconfirm
      overlayClassName={isValidTechVisit ? 'visits-checkin-confirm' : ''}
      placement='topRight'
      onConfirm={async () => {
        visitAPI.onClickCheckIn({ 
          visitId: record.id, needWarning: true, needMixPanel: true, cb: () => {
            message.success('Visit is checked-in successfully');
          } 
        });
      }}
      title={isValidTechVisit ? 
        (
          <div className='wrapper'>
            <span className='title'>Check in this appointment?</span>
            <div className='description'>
              Please try <span className='highlight'>NOT</span> to limit your conversation to just tech issues. Try to review other billable topics as well, like scheduling, reminder on labs, medications, inactivity, vitals monitoring encouragement, etc.
            </div>
          </div>
        ) : 'Check in this appoinment?'
      }
    >
      <Button 
        {...actionButtonProps}
        type='primary' 
      >
        Check in
      </Button>
    </Popconfirm>
  )
}

export const VISIT_STATUS_MAPPING = {
  DONE: {
    statusText: 'Done',
    key:'DONE',
    extraStatus: ({ record }) => {
      const { checkinAt, checkoutAt } = record;
      const checkinTime = formatDate(checkinAt, APPOINTMENT_TIME_FORMAT);
      const checkoutTime = formatDate(checkoutAt, APPOINTMENT_TIME_FORMAT);
      return `${checkinTime} - ${checkoutTime}`; 
    },
    statusColor: VISIT_STATUS_COLOR_ENUM.grey,
  },
  ABOUT_START: {
    checkTime: (timeDiffMin) => timeDiffMin >= VISIT_TIME_RANGE[0] && timeDiffMin <= 0,
    getStatusText: (duration, appointmentTimeDiffMin) => {
      if (appointmentTimeDiffMin >= VISIT_COUNTDOWN_TIME) {
        return `Start in ${duration}min${duration > 1 ? 's' : ''}`
      }
      return 'Confirmed';
    },
    statusColor: VISIT_STATUS_COLOR_ENUM.yellow,
    getStatusColor: (duration, appointmentTimeDiffMin) => {
      if (appointmentTimeDiffMin >= VISIT_COUNTDOWN_TIME) {
        return VISIT_STATUS_COLOR_ENUM.yellow;
      }
      return VISIT_STATUS_COLOR_ENUM.blue;
    },
    action: renderCheckinButton,
  },
  WAITING: {
    checkTime: (timeDiffMin, type) => {
      switch (type) {
        case 'CLINIC_FOLLOW_UP':
          return timeDiffMin >= 0 && timeDiffMin <= 2*60;
        default:
          return timeDiffMin >= 0 && timeDiffMin <= VISIT_TIME_RANGE[1];
      }
    },
    statusText: 'Waiting',
    key:'WAITING',
    statusColor: VISIT_STATUS_COLOR_ENUM.yellow,
    action: ({ record }) => {
      const { id, category, member = {}, enrolledProgram, type } = record;
      switch(category) {
        // SC-6440: this will not be in the scope
        // case VISIT_CATEGORY_ENUM.PHONE:
        //   const enrolledProgramId = _.get(enrolledProgram, 'id');
        //   return phoneVisitAction(id, member, enrolledProgramId);
        default:
          return renderCheckinButton({ record });
      }
    }
  },
  VIRTUAL_WAITING: {
    getStatusText: (patientWaitTime, appointmentTimeDiffMin, waitingRole = {}) => {
      const waitingRoleSpan = !waitingRole.text ? '' :
                              <span>
                                <span style={waitingRole.style || {}}>
                                  {waitingRole.text}
                                </span>
                                ,&nbsp;
                              </span>;
      return <div style={{ display: 'contents' }}>
        Patient is waiting for {waitingRoleSpan}{patientWaitTime} min
      </div>;
    },
    badgeStatus: 'processing',
    statusColor: VISIT_STATUS_COLOR_ENUM.red,
    action: virtualVisitAction
  },
  VIRTUAL_PENDING:{
    getStatusText:(duration, appointmentTimeDiffMin) => {
      if(appointmentTimeDiffMin<=0) {
        return 'pending';
      }
      return <div style={{ display: 'contents' }}>
        Waiting
      </div>;
    },
    badgeStatus: 'processing',
    statusColor: VISIT_STATUS_COLOR_ENUM.yellow,
  },
  VIRTUAL_ON_GOING: {
    getStatusText: (patientWaitTime, appointmentTimeDiffMin, waitingRole = {}) => {
      const waitingRoleSpan = !waitingRole.text ? '' :
                              <span>
                                with&nbsp;
                                <span style={waitingRole.style || {}}>
                                  {waitingRole.text}
                                </span>
                              </span>;
      return <div style={{ display: 'contents' }}>
        On-going {waitingRoleSpan}
      </div>;
    },
    badgeStatus: 'processing',
    statusColor: VISIT_STATUS_COLOR_ENUM.green,
    action: virtualVisitAction,
  },
  ON_GOING: {
    statusText: 'On-going',
    key:'ON_GOING',
    badgeStatus: 'processing',
    statusColor: VISIT_STATUS_COLOR_ENUM.green,
    action: ({ record }) => {
      const { id, appointmentAt, category, type, checkinAt } = record;
      const isValidTechVisit = type === 'TECH' && category !== 'INCLINIC';
      const isPopupDisabled = !isValidTechVisit || !checkinAt;
      const handleCheckout = () => {
        visitAPI.onClickCheckOut({ 
          visitId: id, needWarning: true, needMixPanel: true, cb: () => {
            message.success('Visit is checked-out successfully');
          }
        });
      };
      return (
        <BillableReviewFormPopup 
          placement='topRight'
          isPopupDisabled={isPopupDisabled}
          visitId={id}
          onSubmit={handleCheckout}
        >
          <Button 
            {...actionButtonProps}
            disabled={checkIfShouldDisableAction(appointmentAt)}
            className='visitCheckOutButton'
            onClick={() => isPopupDisabled && handleCheckout()} 
          >
            Check out
          </Button> 
        </BillableReviewFormPopup>
    )}
  },
  NO_SHOW: {
    checkTime: (timeDiffMin,type) => {
      switch (type) {
        case 'CLINIC_FOLLOW_UP':
          return timeDiffMin > 2*60;
        default:
          return timeDiffMin > VISIT_TIME_RANGE[1];
      }
    },
    statusText: 'No show',
    key: 'NO_SHOW',
    getStatusText:()=>{
      return 'No Show';
    },
    statusColor: VISIT_STATUS_COLOR_ENUM.red,
    action: ({record, renderVisitEdit}) =>{
        return <Button
            {...actionButtonProps}
            type='primary'
            onClick={() => {
              Mixpanel.track('clicked', 'reschedule', 'on Visits page', {});
              renderVisitEdit(record);
            }}
            ghost
        >
          Reschedule
        </Button>
  }
  },
  CONFIRMED: {
    statusText: 'Confirmed',
    key:'CONFIRMED',
    statusColor: VISIT_STATUS_COLOR_ENUM.blue
  },
  VIRTUAL_CONFIRMED: {
    key:'CONFIRMED',
    getStatusColor: (__, appointmentTimeDiffMin) => {
      if (appointmentTimeDiffMin >= VISIT_COUNTDOWN_TIME) {
        return VISIT_STATUS_COLOR_ENUM.yellow;
      }
      return VISIT_STATUS_COLOR_ENUM.blue;
    },
    getStatusText: (patientWaitTime, appointmentTimeDiffMin) => {
      const duration = Math.abs(appointmentTimeDiffMin);
      if (appointmentTimeDiffMin >= VISIT_COUNTDOWN_TIME && appointmentTimeDiffMin <= 0) {
        return `Start in ${duration}min${duration > 1 ? 's' : ''}`
      }
      return 'Confirmed';
    }
  },
  UNCONFIRMED: {
    statusText: 'Unconfirmed',
    key:'UNCONFIRMED',
    customStatus: { 
      count: <span></span>,
      style: {
        display: 'inline-block', 
        backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(255, 255, 255)',
        border: '1px solid rgb(49, 119, 201)', borderRadius: '50%',
        width: 8, height: 8, marginBottom: 2,
        position: 'unset', transform: 'unset', transformOrigin: 'unset',
      }
    },
    action: ({ record }) => (
      <Button
        {...actionButtonProps}
        disabled={checkIfShouldDisableAction(record.appointmentAt)}
        onClick={() => {
          Mixpanel.track('clicked', 'Confirm', 'on pending appointment', {});
          visitAPI.confirmVisit(record.id);
        }}
      >
        Confirm
      </Button>
    )
  },
  ENROLL: {
    statusText: '',
    action: ({ record, renderVisitWorkList, refetchAllVisitList }) => (
      <EnrollPatientContainer
        fromPlace={ENROLL_FROM.VISIT_TABLE}
        user={record.member}
        onCompleteEnrollment={(closeProps) => {
          if(closeProps.reopenWorkList) {
            let data = JSON.parse(sessionStorage.getItem('backToCurVisit'));
            if(data && data.id) {
              renderVisitWorkList(data);
            }
          } else {
            refetchAllVisitList();
          }
          sessionStorage.removeItem('backToCurVisit');
        }}
        enrollButtonOnClick={() => {
          sessionStorage.setItem('backToCurVisit', JSON.stringify(record));
        }}
        enrollButtonProps={actionButtonProps}
      />
      // <Button 
      //   {...actionButtonProps}
      //   type='primary'
      //   onClick={() => {
      //     const { id: visitId, member: { id } } = record;
      //     sessionStorage.setItem('backToCurVisit', JSON.stringify(record));
      //     goPath(`/patients/${id}/enrolledprogram/${visitId}_`);
      //   }}
      // >
      //   Enroll
      // </Button>
    )
  }
};

// copy from antd
export const loadingMessage = (
  <div className='ant-message' style={{ position: 'fixed', top: 80 }}><span><div className='ant-message-notice'><div className='ant-message-notice-content'><div className='ant-message-custom-content ant-message-loading'><i aria-label='icon: loading' className='anticon anticon-loading'><svg viewBox='0 0 1024 1024' focusable='false' className='anticon-spin' data-icon='loading' width='1em' height='1em' fill='currentColor' aria-hidden='true'><path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z'></path></svg></i><span> </span></div></div></div></span></div>
);

export const statusMap = {
  'INIT': 'VIRTUAL_CONFIRMED',
  'WITH': 'VIRTUAL_ON_GOING',
  'WAITING':'VIRTUAL_WAITING',
  'CALLING_IN':'VIRTUAL_WAITING',
  'END':'DONE',
  'NO_SHOW':'NO_SHOW',
  'NOT_SHOWING':'NO_SHOW'
};