import { decryptRole } from '../../../lib/utils';
import { ROLES } from '../../utils/constants/role';
import { ROLES_TO_TEXT } from '../constants';
import { mappingCondition } from '../../careplanNew/component/helper/goalHelper';
import { DISPLAY_NOT_NECESSARY_VALUE, STORAGE_KEY } from '../constants';
import { getProviderName } from '../../../lib/utils';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import { IHEALTH_MEMBER_ROLES } from '../../utils/constants/role';
import helper from '../../remoteEnrollment/helper';
import { goPath } from '../../../lib/utils';

export const decodeId = (encodedId) => encodedId ? _.split(window.atob(encodedId), ':')[1] : undefined;

export const getTextPlaceholdersByRole = (role) => {
  const currentRole = role || decryptRole();

  switch (currentRole) {
    case ROLES.nurse:
    case ROLES.medicalAssistant:
    case ROLES.healthCoach:
      return ROLES_TO_TEXT.CareTeam;
    case ROLES.doctor:
      return ROLES_TO_TEXT.Provider;
    default:
      return {};
  }
};

export const getMonthOfYear = (inputDate) => {
  const date = inputDate || new Date();
  return date.getFullYear() * 100 + date.getMonth() + 1;
};

export const getConditionName = (conditionEnum, condition) => {
  let name = mappingCondition[conditionEnum];
  if (!name || name === 'UNMAPPED') {
    name = condition;
  }
  return name;
};

export const getTeamsFromLoginUser = () => {
  const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
  return loginUser.team || [];
};

export const renderAssignee = (key, assignees) => {
  const isNotNecessary = _.get(assignees, `${key}NotNecessary`);
  let fullName = _.get(assignees, `${key}.profile.fullName`);
  if (isNotNecessary) {
    fullName = DISPLAY_NOT_NECESSARY_VALUE;
  } else if (!fullName) {
    fullName = '-';
  }
  return fullName;
};

export const renderProvider = (
  provider,
  shortenNameForCareTeam = true,
) => {
  const isCareTeam = checkIsInRole(IHEALTH_MEMBER_ROLES);
  let providerName = _.get(provider, 'profile.fullName');
  if (isCareTeam && shortenNameForCareTeam) {
    providerName = getProviderName(provider);
  }
  return providerName;
};

// status=TODO means if patient doesn't have rpm review finished in given monthOfYear
export const filterTODORPMPatientList = (
  boostedPatientList,
  monthOfYear = getMonthOfYear(),
) => {
  return _.filter(boostedPatientList, ({ user }) => {
    const {
      id,
      patientStatus,
    } = user || {};
    return (
      id
      && (!patientStatus || patientStatus.lastFinishedMonthlyReview !== monthOfYear)
    );
  });
};

export const goToPatientProfile = ({
  patientId,
  organization,
}) => {
  handleCheckAndChangeOrganization(organization, (orgSwitched) => {
    goPath(`/patients/${patientId}`);
    if (orgSwitched) helper.simulatePageReload();
  })
};

export const handleReloadWhenSwitchOrg = (saveOptions, key = STORAGE_KEY) => {
  if (saveOptions) {
    sessionStorage.setItem(key, JSON.stringify(saveOptions));
  }
  return helper.simulatePageReload();
};

export const handleCheckAndChangeOrganization = async (organization, cb) => {
  const handleCallback = (orgSwitched) => {
    if (cb) cb(orgSwitched);
  };

  if (!organization || !organization.id) {
    console.error('Error: Missing org id');
    return handleCallback();
  }

  const { id, name } = organization;

  helper.onOrganizationChange({
    organizationId: id,
    name,
  }, handleCallback);
};

export const parseAssigneeFilter = (filters = {}, key) => {
  const selectedValues = filters[key];
  let newFilters = { ...filters };
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const assignedTo = [];
    _.forEach(selectedValues, (assigneeId) => {
      switch (assigneeId) {
        case 'null':
          assignedTo.push(null);
          break;
        case 'not-necessary':
          newFilters[`${key}NotNecessary`] = true;
          break;
        default:
          assignedTo.push(assigneeId);
      };
    });
    newFilters[key] = assignedTo;
  } else {
    newFilters = _.omit(newFilters, key);
  }
  return newFilters;
}

export const isCurrentUserProvider = () => {
  return checkIsInRole(['Doctor']);
};

export const parseFilters = (filters) => {
  let parsed = { ...filters };

  parsed = parseAssigneeFilter(parsed, 'assignedToRD');
  parsed = parseAssigneeFilter(parsed, 'assignedToCA');
  
  return parsed;
};

export const getRPMReviewStatus = (lastFinishedInMonthOfYear) => {
  return lastFinishedInMonthOfYear === getMonthOfYear() ? 'DONE' : 'TODO';
};
