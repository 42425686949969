import React from 'react';
import gql from 'graphql-tag';
import {Tooltip, Tag, Col, Badge, Row} from 'antd';
import I18N from '../../I18N';
import { VISIT_COUNT_MAX } from '../../../lib/constants';
import { helpers } from '../../visit/helpers';
import {ENROLLED_STATUS, statusMap, VISIT_CATEGORY_ENUM, VISIT_FILTERS_MAP, VISIT_STATUS_MAPPING} from '../constants';
import { getTimeDiff, getClinicTime } from './visitDateHelpers';
import VisitSchema from '../../graphql/schema/Visit';
import User from '../../graphql/class/User';
import roleMap from '../../VideoChat/constant/roleMap';
import ComplexityInfoComponent from '../../patient/main/components/ComplexityInfoComponent';
import { COMPLEXITY_LEVELS, COMPLEXITY_ENUM } from '../../../lib/constants';
import { checkIsInRole } from '../../../lib/helpers/role-helpers';
import { COMPLEXITY_ROLES } from '../../utils/constants/role';
import moment from "moment";

export const buildVisitListsQuery = () => {
  return gql`
    query getVisitListForVisitsPage (
      $count: Int = ${VISIT_COUNT_MAX},
      $page: Int,
      $filters: VisitListOpts,
      $sort: VisitListSort,
      $pageInfoOnly: Boolean!
      $dataWithPageInfo: Boolean = false
    ) {
      visitList(count: $count, page: $page, filters: $filters, sort: $sort) {
        data @skip(if: $pageInfoOnly) { 
          ${VisitSchema} 
        }
        pageInfo @include(if: $pageInfoOnly) { 
          total 
        } 
        pageInfo @include(if: $dataWithPageInfo) {
          total
        }
      }
    }
  `;
};

export const buildVisitListQueryOpts = (queryProps) => {
  const { visitTabConfig, isTabSelected, selectedRange } = queryProps;
  const { tabKey, getVisitsFromTo, shouldPollVisitList, fetchOnNetwork, defaultPageSize } = visitTabConfig;

  const selectedPageInfo = queryProps.selectedPageInfo[tabKey] || {};
  let selectedFilters = {
    ...queryProps.selectedFilters[tabKey] || {},
    ...queryProps.selectedAdvancedFilters[tabKey] || {}
  };

  selectedFilters = removeEmptyArrayFilter(selectedFilters);

  const { from, to } = getVisitsFromTo(selectedRange);

  const fetchPolicy = //(fetchOnNetwork || // TODO: consider to have this for All tab visit
                      (!fetchOnNetwork && _.isEmpty(selectedFilters))
                      ? 'network-only' : 'cache-first';

  const queryOpts = {
    notifyOnNetworkStatusChange: true,
    fetchPolicy
  };

  if(fetchPolicy === 'network-only') {
    queryOpts.pollInterval = shouldPollVisitList(isTabSelected);
  }

  const variables = {};

  variables.pageInfoOnly = !isTabSelected;
  variables.filters = {
    // providerIds: _.map(JSON.parse(sessionStorage.getItem('doctorName')), 'id'),
    appointmentFrom: from,
    appointmentTo: to
  };

  //-- pageInfoOnly means NOT need data in visitList API
  //-- !fetchOnNetwork means NOT using filters, page, count,... in visitList API
  if(variables.pageInfoOnly || !fetchOnNetwork) {
    return Object.assign(queryOpts, { variables });
  }

  if(defaultPageSize) {
    let { current = 1, pageSize = defaultPageSize } = selectedPageInfo;
    variables.page = current;
    variables.count = pageSize;
    variables.dataWithPageInfo = true;
  }

  _.forEach(_.entries(VISIT_FILTERS_MAP), ([filterKey, filterPath]) => {
    const filterValue = _.get(selectedFilters, filterKey);
    _.set(variables, filterPath, filterValue);
  });

  return Object.assign(queryOpts, { variables });
};

export const removeEmptyArrayFilter = (filters) => {
  let nonEmptyFilters = {...(filters || {})};
  // remove filter that has empty array
  _.forEach(_.keys(nonEmptyFilters), key => {
    const filter = nonEmptyFilters[key] || [];
    if(!filter.length)
      nonEmptyFilters = _.omit(nonEmptyFilters, key);
  });
return nonEmptyFilters;
}

export const getVisitCategoryImg = visitCategory => {
  let src = '', tip = '', style = { };
  switch(visitCategory) {
    case VISIT_CATEGORY_ENUM.VIRTUAL:
      src = '/image/camera.png';
      tip = 'This is a virtual visit';
      break;
    case VISIT_CATEGORY_ENUM.PHONE:
      src = '/image/telephone.svg';
      tip = 'This is a phone visit';
      style = { ...style, opacity: 0.6 };
      break;
    default:
  }
  return { src, tip, style };
};

export const getPatientPhoneInfo = (member, visitCategory) => {
  const { renderIndicator } = helpers.patientIndicator(member, visitCategory);
  return renderIndicator && renderIndicator();
};

export const getPatientStatus = enrolledProgramStatus => {
  return _.includes(ENROLLED_STATUS, enrolledProgramStatus) ? 'Enrolled' : 'Unenrolled';
};

export const getPatientInfo = member => {
  const patient = new User(member);
  const patientId = patient._get('id');
  const isSelfEnrolled = _.get(patient, 'profile.isSelfEnrolled', false);
  const profilePath = `/patients/${patientId}/enrolledprogram/default`;
  const enrolledProgramCategories = patient.format('enrolledProgramCategories');
  return {
    id: patientId,
    profilePath,
    isSelfEnrolled: isSelfEnrolled,
    fullName: patient._get('profile.fullName') || '',
    age: patient.format('age') || '--',
    gender: patient.format('gender', true),
    PCP: patient.format('PCP'),
    enrolledProgramCategories: enrolledProgramCategories.length ? ` - ${_.join(_.map(enrolledProgramCategories, 'name'), ', ')}` : '',
    vitalsWithStatus: patient._getVitalsWithInactiveStatus()
  };
};

const TAG_STYLE = { marginLeft: 5, height: 20, border: 'none', backgroundColor: '#E7EAED', fontWeight: 'bold' };
export const renderSelfTag = () => <Tooltip title={I18N.get('SelfTag')}>
                                    <Tag style={TAG_STYLE}>Self</Tag>
                                   </Tooltip>

export const renderComplexityTag = (complexity, isUnenrolled) => {
  const isRoleEligible = checkIsInRole(COMPLEXITY_ROLES);
  if(!isRoleEligible)
    return null;

  const level = COMPLEXITY_LEVELS[complexity] || complexity;
  if(level === COMPLEXITY_ENUM.NULL_CODES)
    return null;

  return (
    <ComplexityInfoComponent isUnenrolled={isUnenrolled} type={complexity} showNote={true}>
      <Tooltip trigger="hover" title={`What does ${level} mean?`}>
        <Tag style={{ ...TAG_STYLE, cursor: 'pointer' }}>{level}</Tag>
      </Tooltip>
    </ComplexityInfoComponent>
  );
};


export const toArrayConditionList = conditionsList => {
  let conditionsListArray = _.keys(_.omit(conditionsList, '__typename'));
  conditionsListArray = _.filter(conditionsListArray, k => !!conditionsList[k]);
  return conditionsListArray;
};

// when visit time is past a day (based on clinic time zone),
// should disable action to prevent hidden error, illogical action
export const checkIfShouldDisableAction = appointmentAt => {
  return false;
  // No need for now
  const appointmentTime = getClinicTime(appointmentAt);
  const now = getClinicTime();
  return !appointmentTime.isSame(now, 'date');
};

const getWaitingRole = (visit) => {
  const { status, step, needAction } = visit;
  let waitingRole = {};
  if(step) {
    const currentUserId = sessionStorage.getItem('currentUserId');
    const stepUserId = step.id;``
    const role  =  roleMap[_.get(step, 'role')];
    const shouldHighlight = currentUserId === stepUserId ||
                      status === 'CALLING_IN'||
                      needAction;
    waitingRole = step.name || role;
    waitingRole = {
      text: waitingRole || '',
      style: { fontWeight: shouldHighlight ? 600 : 200, marginRight: 3 },
    };
  }
  return waitingRole;
};

const getVirtualVisitStatus  = visit => {

  let { status, waitingTime = 0,type,appointmentAt } = visit;
  let mappedStatus = _.get(statusMap,status,statusMap.INIT);
  let visitStatus = VISIT_STATUS_MAPPING[mappedStatus];
  const now = new Date().getTime();
  const appointmentTimeDiffMin = getTimeDiff(now,appointmentAt,'minutes');
  if( _.includes([statusMap['NO_SHOW'],statusMap['NOT_SHOWING']],mappedStatus)) {
    visitStatus = VISIT_STATUS_MAPPING[visitStatus.checkTime(appointmentTimeDiffMin, type) ? 'NO_SHOW' : 'VIRTUAL_PENDING'];
    visitStatus.statusText = visitStatus.getStatusText(Math.abs(appointmentTimeDiffMin), appointmentTimeDiffMin);
  }
  else if(typeof visitStatus.getStatusText === 'function') {
    waitingTime = (waitingTime/60000).toFixed(0);
    const waitingRole = getWaitingRole(visit);
    visitStatus.statusText = visitStatus.getStatusText(waitingTime, appointmentTimeDiffMin, waitingRole);
  }
  if(typeof visitStatus.getStatusColor === 'function') {
    visitStatus.statusColor = visitStatus.getStatusColor(Math.abs(appointmentTimeDiffMin), appointmentTimeDiffMin);
  }

  return visitStatus;
};

export const getVisitStatus = visit => {
  const { type, category, confirm, enrolledProgram, appointmentAt, checkinAt, checkoutAt } = visit;
  const now = new Date().getTime();
  const appointmentTimeDiffMin = getTimeDiff(now, appointmentAt, 'minutes');
  let visitStatus = VISIT_STATUS_MAPPING.CONFIRMED;
  if(category === VISIT_CATEGORY_ENUM.VIRTUAL) {
    return getVirtualVisitStatus(visit);
  }

  if(checkinAt && checkoutAt){
    visitStatus = VISIT_STATUS_MAPPING.DONE;
  }
  else if(checkinAt && !checkoutAt) {
    visitStatus = VISIT_STATUS_MAPPING.ON_GOING;
  }
  else if(!checkinAt && VISIT_STATUS_MAPPING.NO_SHOW.checkTime(appointmentTimeDiffMin,type)) {
    visitStatus = VISIT_STATUS_MAPPING.NO_SHOW;
  }
  else if(!confirm) {
    visitStatus = VISIT_STATUS_MAPPING.UNCONFIRMED;
  }
  // else if(!checkinAt && type === 'INIT' && !enrolledProgram) {
  //   visitStatus = VISIT_STATUS_MAPPING.ENROLL;
  // }
  else if(!checkinAt && VISIT_STATUS_MAPPING.WAITING.checkTime(appointmentTimeDiffMin,type)){
    visitStatus = VISIT_STATUS_MAPPING.WAITING;
  }
  else if(!checkinAt && VISIT_STATUS_MAPPING.ABOUT_START.checkTime(appointmentTimeDiffMin,type)) {
    visitStatus = VISIT_STATUS_MAPPING.ABOUT_START;
  }

  if(typeof visitStatus.getStatusText === 'function') {
    visitStatus.statusText = visitStatus.getStatusText(Math.abs(appointmentTimeDiffMin), appointmentTimeDiffMin);
  }

  if(typeof visitStatus.getStatusColor === 'function') {
    visitStatus.statusColor = visitStatus.getStatusColor(Math.abs(appointmentTimeDiffMin), appointmentTimeDiffMin);
  }
  return visitStatus;
};

export const visitStatusContent = (record)=>{
  const {
    statusText, badgeStatus, extraStatus, statusColor, customStatus
  } = getVisitStatus(record);
  return <Row>
    <Col span={24}>
      <Badge
          color={statusColor}
          status={badgeStatus || null}
          text={statusText}
          {...customStatus}
      />
    </Col>
    {
        extraStatus &&
        <Col span={24}>
          {extraStatus({ record })}
        </Col>
    }
  </Row>
}
export const checkIfRescheduled = (visit)=>{
  const { key } = getVisitStatus(visit);
  const { rescheduledToVisit } = visit;
  const isNoShow = key === statusMap.NO_SHOW;
  const visitRescheduled = !!_.get(rescheduledToVisit,'appointmentAt');
  return {
    isNoShow,
    visitRescheduled
  }
}
export const renderRescheduledVisit = (rescheduledFromVisit)=>{
  const { createdAt,appointmentAt } = rescheduledFromVisit;
  const formatDate = (date)=>moment(date).format('MM/DD/YYYY');
  const formatTime = (date)=>moment(date).format('HH:mm A');
  return `Rescheduled to ${formatDate(appointmentAt)} at ${formatTime(appointmentAt)} `;
}

