import * as types from '../constants/PatientProgramTaskListConstants';
import { commonActions } from 'libModule/helpers/action-helpers';

const onRow = (props) => {
  return {
    onClick: () => {
      return (dispatch) => {
      }
    }
  }
}

export default Object.assign({
  onRow,
}, commonActions(types, ['sort', 'changePage']))
