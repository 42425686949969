import query from '../graphql';
import Client from 'libModule/gqlClient';

const createHeartbeatLog = (variables)=>Client.mutate({mutation:query.createHeartbeatLogMutation,variables});
const getUserSettings = (variables)=>Client.query({query:query.getUserSettingsQuery,variables});
const setLastReadRelease = (variables)=>Client.mutate({ mutation: query.setLastReadReleaseMutate,variables});
const getUserOrgs = (variables)=>Client.query({ query:query.getUserOrgsQuery, variables,fetchPolicy:'network-only' });
export  default {
    createHeartbeatLog,
    getUserSettings,
    setLastReadRelease,
    getUserOrgs
}
