import React from 'react';
import {Input} from 'antd';
import moment from "moment";
import {MOTIVATION_ENUM} from "../../patient/profileEdit/constants/profileFieldMap";
import InsuranceComponent  from '../../patient/AdditionalInfo/Components/InsuranceComponent';
import RenderFormComponent from '../../patient/AdditionalInfo/Components/RenderFormComponent';
import { doesOrgHaveValueBased,doesOrgHaveMNT,doesOrgHaveCCM, doesOrgHaveRPM } from "../../../lib/utils";
import {enrollmentInfo, shadowFields} from "../../patient/AdditionalInfo/constants/basicInfo";
import { InsuranceCopayComponent } from '../../patient/InsuranceCopayComponent/InsuranceCopayComponent';

export const MISSING_TASK_TYPE = {
	MISSING_MRN: 'MISSING_MRN',
	MISSING_INSURANCE: 'MISSING_INSURANCE',
	NO_PROGRAM_SELECTION: 'NO_PROGRAM_SELECTION',
	NO_ICD_CODE: 'NO_ICD_CODE',
	DUPLICATE_MRN: 'DUPLICATE_MRN',
	ICD_NOT_MATCH: 'ICD_NOT_MATCH',
	SIP_INSURANCE: 'SIP_INSURANCE'
};

const columnList = () => [
  ...enrollmentInfo(doesOrgHaveMNT(), doesOrgHaveCCM(), doesOrgHaveRPM(), doesOrgHaveValueBased()).map(arr=> arr.map(a=>({ ...a,span:12 }))),
  ...shadowFields().map((row) => row.map((field) => ({ ...field, colStyle: { display: 'none' } }))),
];

export  const formKeysToMissingInfoKey = {
	"insuranceProviders": 'MISSING_INSURANCE',
	"RPMEnrolled": 'NO_PROGRAM_SELECTION',
	'CCMEnrolled': 'NO_PROGRAM_SELECTION',
	'MRN':'MISSING_MRN',
	'healthConditions':'NO_ICD_CODE',
	'billableHealthConditions': 'NO_ICD_CODE',
}

const checkIfResolveICDNotMatch = (user) => {
  const { programCategories, billableHealthConditions } = _.get(user, 'profile') || {};
  // following BE logic
  if (!programCategories || programCategories.length === 0) {
    return true;
  }

  let RPM = false;
  let CCM = false;
  for (const pro of programCategories) {
    if (pro.enrolled && pro.name === 'RPM') {
      RPM = true;
    }

    if (pro.enrolled && pro.name === 'CCM') {
      CCM = true;
    }
  }
  
  if (CCM && RPM && billableHealthConditions && billableHealthConditions.length === 1) {
    return false;
  }

  return true;
}

export const missingInfoTasksResolver = {
	MRN_TASK:{
		resolver:(user,missingInfo)=>{
			const path = 'identification[0].value';
			const missingTasks = _.get(missingInfo,'missingTasks',[]);
			const hasDuplicate_MRN = _.includes(missingTasks,'DUPLICATE_MRN');
			const hasValue = _.get(user,path);
			return hasValue && !hasDuplicate_MRN;
		}
	},
	INSURANCE_TASK:{
		resolver:(user,missingInfo)=>{
			const path = ['profile.insuranceProvider.length','profile.insuranceProvider2','profile.insuranceProviders.length'];
			const ICPDisMatch = _.includes(_.get(missingInfo,'missingTasks'),MISSING_TASK_TYPE['SIP_INSURANCE']);
			const hasValue = _.reduce(path,(res,cur)=>{
				res = res || _.get(user,cur,false);
				return res;
			},false)
			return hasValue && !ICPDisMatch;
		}
	},
	NO_PROGRAM_SELECTION:{
		resolver:(user,missingInfo)=>{
			const path = 'profile.programCategories';
			const value = _.get(user,path,[]);
			const hasValue = _.reduce(value,(res,cur)=>{
				res = res || _.get(cur,'enrolled');
				return res;
			},false);
			return hasValue
		}
	},
	ICD_CODE_TASK:{
		resolver:(user,missingInfo)=>{
			const path = ['profile.healthConditions.length','profile.billableHealthConditions.length'];
			// const hasNoBillableCode = _.includes(_.get(missingInfo,'missingTasks'),MISSING_TASK_TYPE['NO_ICD_CODE']);;
			const hasICDNotMatch = _.includes(_.get(missingInfo,'missingTasks'),MISSING_TASK_TYPE['ICD_NOT_MATCH']);
      let isICDNotMatchResolved = true;
      if (hasICDNotMatch) {
        isICDNotMatchResolved = checkIfResolveICDNotMatch(user);
      }
			const hasValue = _.reduce(path,(res,cur)=>{
				res = res || !!_.get(user,cur,false);
				return res;
			},false)

			return hasValue && isICDNotMatchResolved;
				// && !hasNoBillableCode;
		}
	}
}

export const TASK_COMBINED_MAP = {
	'SIP_INSURANCE' : 'INSURANCE_TASK',
	'MISSING_INSURANCE':'INSURANCE_TASK',
	'MISSING_MRN':'MRN_TASK',
	'DUPLICATE_MRN':'MRN_TASK',
	'ICD_NOT_MATCH':'ICD_CODE_TASK',
	'NO_ICD_CODE':'ICD_CODE_TASK'
}


export const missingInfoMap = {
		MRN_TASK:{
			key:'MRN_TASK',
			label:'MRN',
			path:'identification[0].value',
			content:(user,missingInfo)=>  [[{
				key:'MRN',
				label:<div>
					<div className="ant-col ant-form-item-label"><label className="" title="MRN">MRN</label></div>
					{_.includes(_.get(missingInfo,'missingTasks',[]),'DUPLICATE_MRN')? <p style={{ color:'red',fontWeight:'normal' }}>Patient has same MRN as other patient in clinic, please check and change accordingly.</p>:''}
				</div>,
				path:'identification.0.value',
				inputContent:({value})=> {
					const missingTasks = _.get(missingInfo,'missingTasks',[]);
					const path = 'identification[0].value';
					const MRN = _.get(user,path);
					const hasDuplicate_MRN = _.includes(missingTasks,'DUPLICATE_MRN');
					// return <div>
					// 	{ hasDuplicate_MRN ? <p style={{ color:'red' }}>Patient has same MRN as other patient in clinic, please check and change accordingly.</p> :'' }
					// 	 <Input />
					// </div>

					return  <Input/>
				}
			}]],
		},
		INSURANCE_TASK: {
			key:'INSURANCE_TASK',
			label:'Insurance',
			content:(user,localThis)=> [
        [{
          key:'insurance',
          label:'Insurance',
          inputContent:()=>{
            const hasICP = _.includes(_.get(localThis,'props.missingInfo.missingTasks'),MISSING_TASK_TYPE['SIP_INSURANCE']);
            return <React.Fragment>
              { hasICP ? <p style={{ color:'red' }}>Patient's insurance is SIP, for billing purposes please check in EHR and see if patient has I10 code.</p> :'' }
                <InsuranceComponent {...localThis.props} isWorkStation={true} insuranceProviders={_.get(user, 'profile.insuranceProviders') || []}/>
            </React.Fragment>
          },
        }],
        [{
          key:'insuranceProviderCopay',
          label:'',
          inputContent:()=>(
            <InsuranceCopayComponent 
              patientId={_.get(user, 'id')}
              {...localThis.props}
            />
          ),
        }],
      ],
			parseData:()=>'',
		},
		NO_PROGRAM_SELECTION: {
			key:'NO_PROGRAM_SELECTION',
			label:'Program',
			content:()=> columnList(),
			parseData:()=>'',
		},
		ICD_CODE_TASK: {
			key:'ICD_CODE_TASK',
			label:'ICD Codes',
			content:(user,localThis)=>[[{
				key:'insurance',
				label:'',
				inputContent:()=> {
					const ICDDisMatch = _.includes(_.get(localThis,'props.missingInfo.missingTasks'),MISSING_TASK_TYPE['ICD_NOT_MATCH']);
					return <React.Fragment>
							{ ICDDisMatch ? <p style={{ color:'red' }}>ICD code and patient's program doesn't match. Please revise the information.</p> :'' }
							<RenderFormComponent {...localThis.props} isEditMode={true} config={[[]]} renderPicker={true}
					                     isWorkStation={true}/>
						   </React.Fragment>
				}
			}]],

				// <RenderFormComponent {...localThis.props} isEditMode={true} config={[[]]} renderPicker={true}/>,
			parseData:()=>'',
		}
		// DUPLICATE_MRN: {
		// 	key:'DUPLICATE_MRN',
		// 	label:'MRN',
		// 	content:()=><Input key='DUPLICATE_MRN'/>,
		// 	parseData:()=>'',
		// }
}