import React from 'react';
import PatientPage from 'modulesAll/patient/main/containers/main';
import {Row, Col, Avatar,Input,Radio,Button,Icon,message,Modal, Collapse, Popover} from 'antd';
import _ from "lodash";
import { IHLoading } from 'ihcomponent';
import SmartAlertResolveCardComponent from "./SmartAlertResolveCardComponent";
import createProviderNote from 'modulesAll/graphql/createProviderNote';
import closeSmartAlert from 'modulesAll/smartAlert/query/closeSmartAlert';
import Client from 'libModule/gqlClient';
import { goPath } from 'libModule/utils'
import {graphql} from "react-apollo";
import { compose } from 'recompose';
import getPatientSmartAlert from 'modulesAll/smartAlert/query/getPatientSmartAlert.js';
import { userForAvatar } from 'modulesAll/graphql/user.js';
import helper from '../helper/index';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import setPatientAlertConfig from '../query/setPatientAlertConfig';
import getPatientAlertConfig from '../query/getPatientAlertConfig';
import SnoozePopover from './SnoozePopover';
import AlertLevelColorIndicator from './AlertLevelColorIndicator';
import { connect } from 'react-redux';
import timerActions from '../../timer/actions';
import { MapActionsConfiguration } from '../../timer/constants/map';
import TimerContainer from '../../timer/containers/TimerContainer';
import moment from 'moment-timezone';
import { getUserRole } from '../../../lib/utils';
import { eventHelperCreator, EVENT_MAPS } from '../../../lib/helpers/event-helpers';
const { Panel } = Collapse;

const { stopTimer, updateInterventionDetails } = timerActions;
const eventHelper = eventHelperCreator(EVENT_MAPS.alert, 'workstationAlerts');

const defaultTabMap = {
    RD: "clinicalAlerts",
    CA: "complianceAlerts",
    MA: "providerAlerts",
    MD: "providerAlerts",
    HC: "clinicalAlerts"
};

const defaultAction = 'RESOLVE';
const TIMER_ACTIONS = MapActionsConfiguration.WORKSTATION_ALERT;
const { confirm } = Modal;
const ProviderAlertResolveComponent= class extends React.Component{
    static displayName = 'ProviderAlertResolveComponent';
    initialState = {
        checkedAlerts:[],
        action: defaultAction,
        note: '',
        snoozeObj: undefined,
        snoozeValue: undefined,
        snoozeFromDate: undefined,
    }
    constructor(props) {
        super(props);
        this.state = {...this.initialState};
        this.role = getUserRole();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.loadingSmartAlertsList && !this.props.loadingSmartAlertsList) {
        const objPath = 'smartAlertsList.tasks';
        const preTasks = _.get(prevProps,objPath,[]);
        const curTasks = _.get(this.props,objPath,[]);
        const providerAlerts = _.filter(curTasks,s=>s.category==='PROVIDER');
        const nonProviderAlerts = _.filter(curTasks,s=>s.category !== 'PROVIDER');
        const prePatientId = _.get(prevProps,'patientId');
        const curPatientId = _.get(this,'props.patientId');
        const changePatient = prePatientId != curPatientId;
        const curSnoozedTypes = this.getSnoozedTypes();
        const isWorkStation = this.props.isWorkStation;
        if(!_.isEqual(preTasks, curTasks) || changePatient) {
            let firstTaskId;
            if (isWorkStation) {
                const getAlerts = {
                    clinicalAlerts: this.getClinicalAlerts,
                    providerAlerts: this.getProviderAlerts,
                    complianceAlerts: this.getComplianceAlerts,
                }
                firstTaskId = _.get(_.first(getAlerts[defaultTabMap[this.role]](curTasks, true, curSnoozedTypes)), 'id');
            } else {
                firstTaskId = _.get(_.first(providerAlerts.length ? providerAlerts : nonProviderAlerts),'id');
            }
           if(firstTaskId) {
               this.setState({checkedAlerts:[firstTaskId]}, this.setSnoozeObj)
           }
        }
        if (this.props.setHasAlerts) this.props.setHasAlerts(curTasks.length > 0);
      }
    }

    commentAlerts = (snoozeNote, isResolving)=>{
        const {checkedAlerts,note } = this.state;
        const { user, isWorkStation } = this.props;
        const memberId = user.id;
        let ids = checkedAlerts;

        if (isResolving) {
            // only creating a provider note for the last alert of the same type
            const tasks = _.get(this.props, 'smartAlertsList.tasks', []).filter(x => checkedAlerts.includes(x.id));
            const filtered = tasks.reduce((res, curr) => {
                res[curr.taskType] = curr;
                return res;
            }, {});
            ids = _.map(filtered, x => x.id);
        }
        const promiseList = _.map(ids,smartAlertId=>Client.mutate({mutation:createProviderNote,variables:{ memberId,
                smartAlertId,
                category:'SMART_ALERT',
                content: snoozeNote || note }}));
        return Promise.all(promiseList).then(res=>{
            if (!isWorkStation) {
                this.setState({ ...this.initialState });
            }
            message.success('Alert handled.');
            return res;
        })
            .catch(e=>console.log(e))
    }

    closeAlerts = ()=>{
        const {checkedAlerts, note } = this.state;
        const { refetchSmartAlert } = this.props;
        const promiseList = _.map(checkedAlerts,smartAlertId=>Client.mutate({
            mutation: closeSmartAlert,
            variables:{taskId:smartAlertId}}));
        return Promise.all(promiseList)
                .catch(e => {
                    console.error(e);
                    Modal.error({
                        content: e.message,
                        onOk(){
                            if(refetchSmartAlert)refetchSmartAlert();
                        }
                    });
                });
    }

    createPatientAlertConfig = (alert, snoozeValue, startDate) => {
        const { taskType, display } = alert;

        const config = {
            startDate: startDate.valueOf(),
            endDate: startDate.valueOf() + snoozeValue.value,
            taskType: taskType,
            snoozeInterval: snoozeValue.interval,
            isValid: true,
            alertDisplay: display
        };

        return config;
    }

    setPatientAlertConfig = (alert, snoozeValue, startDate) => {
        const config = this.createPatientAlertConfig(alert, snoozeValue, startDate);
        return this.sendPatientAlertConfig([config]);
    }

    unsnoozePatientAlertConfig = async (config) => {
        const _config = {
            startDate: config.startDate,
            endDate: config.endDate,
            taskType: config.taskType,
            snoozeInterval: config.snoozeInterval,
            isValid: false,
            alertDisplay: config.alertDisplay
        };

        const res = await this.sendPatientAlertConfig([_config]);
        this.props.onInteraction('UNSNOOZE');
        this.submitBillable('unsnooze', res);
    }

    sendPatientAlertConfig = (config) => {
        const { patientId: memberId } = this.props;

        return Client.mutate({
            mutation: setPatientAlertConfig,
            variables: {
                memberId,
                config,
            },
            fetchPolicy: 'no-cache',
            refetchQueries:[
                {
                    query: getPatientAlertConfig,
                    variables:{ memberId },
                    fetchPolicy:'network-only'
                }
            ]
        });
    }


    snoozeAlerts = async () => {
        const { checkedAlerts, snoozeValue, snoozeFromDate: startDate } = this.state;
        const tasks = _.get(this.props, 'smartAlertsList.tasks', []).filter(({ id }) => checkedAlerts.includes(id));
        const configs = _.map(tasks, a => this.createPatientAlertConfig(a, snoozeValue, startDate));
        const promiseList = [this.sendPatientAlertConfig(configs)].concat(_.map(checkedAlerts,smartAlertId=>Client.mutate({
            mutation: closeSmartAlert,
            variables:{taskId:smartAlertId}})));
                        
        const res = await Promise.all(promiseList);
        message.success('Alert(s) snoozed!');
        return res;
    }

    // renderAvatar = (avatar)=>{
    //     console.log(avatar);
    //     const avatarLink = !_.isNil(avatar) ? avatar.link : '/image/default_avator.png';
    //     return <Avatar
    //         icon='user'
    //         src= {avatarLink}
    //     />
    // }
    renderHeader = ()=>{
        const { user } = this.props;
        const { profile:{ fullName,avatar } } = user
        return <Row className={'header'}>
                <Col span={1}></Col>
                <Col span={4}>
                    {helper.renderAvatar(avatar)}
                </Col>
                <Col span={17} className={'patientName'}>{fullName}</Col>
               </Row>
    }

    setSnoozeObj = () => {
        const { smartAlertsList, isWorkStation } = this.props;
        const { checkedAlerts, action } = this.state;
        let snoozeObj = undefined;
        const resetValues = {};
        const hasSelectedCompliance = this.getComplianceAlerts(_.get(smartAlertsList, 'tasks', [])).filter(x => checkedAlerts.includes(x.id)).length > 0;

        if (isWorkStation && hasSelectedCompliance) {
          const disabled = this.shouldSnoozeBeDisabled();
          snoozeObj = {
            value: 'SNOOZE',
            disabled,
            label: <span>Snooze alert&nbsp;{disabled && <Popover content={this.renderSnoozeToolTipTitle()}><a style={{ textDecoration: 'underline' }}>Why can't I snooze?</a></Popover>}</span>
          };
        }
        if(!snoozeObj && action === 'SNOOZE') {
          resetValues.action = defaultAction;
          resetValues.snoozeValue = undefined;
        }
        this.setState({ snoozeObj, ...resetValues });
    }

    setCheckedAlerts = (alert,checked)=>{
        const { setShowUnsavedModal } = this.props;
        const { checkedAlerts } = this.state;
        let updatedList = [];
        if(checked) {
            updatedList = [...checkedAlerts,alert];
        }
        else{
            updatedList = _.filter(checkedAlerts,a=>a != alert);
        }
        if (setShowUnsavedModal) setShowUnsavedModal(updatedList !== this.state.checkedAlerts);
        this.setState({ checkedAlerts: updatedList }, this.setSnoozeObj);
    }

    getAlertConfig = (alert) => {
        return _.first(_.get(this.props, 'patientAlertConfig.configs', []).filter(v => v.taskType === alert.taskType));
    }

    renderList = (tasks, isSnoozed = false) => {
        const { checkedAlerts } =this.state;
        const { isWorkStation, onInteraction } = this.props;

        return _.map(tasks, (t, i )=> {
            const id = t.id;
            const checked = _.includes(checkedAlerts, id);
            return (
                <SmartAlertResolveCardComponent
                    alert={{...t}}
                    isSnoozed={isSnoozed}
                    config={this.getAlertConfig(t)}
                    key={i}
                    checked={checked}
                    shouldRenderDueIn={isWorkStation}
                    setCheckedAlerts={this.setCheckedAlerts}
                    onUnsnooze={this.unsnoozePatientAlertConfig}
                    onConfirm={async (alert, snoozeValue, startDate) => {
                        const res = await this.setPatientAlertConfig(alert, snoozeValue, startDate);
                        onInteraction('SNOOZE');
                        this.submitBillable('snoozed', res);
                    }}
                />
            )
        });
    }

    renderBody = () => {
        const { smartAlertsList, isWorkStation } = this.props;
        const tasks = _.get(smartAlertsList, 'tasks', []);

        return isWorkStation
            ? this.renderWorkStationBody(tasks)
            : this.renderNonWorkStationBody(tasks);
    }

    renderWorkStationBody = (tasks) => {
        const panelStyle = {
            background: 'white',
            border: 0,
        }
        const { patientAlertConfig } = this.props;
        const snoozedTypes = this.getSnoozedTypes();
        const clinicalAlerts = this.getClinicalAlerts(tasks, true, snoozedTypes);
        const providerAlerts = this.getProviderAlerts(tasks, true, snoozedTypes);
        const complianceAlerts = this.getComplianceAlerts(tasks, true, snoozedTypes);
        const snoozedAlerts = this.getSnoozedAlerts(patientAlertConfig);

        return (
            <div className="alertsBody">
                <Collapse bordered={false} defaultActiveKey={defaultTabMap[this.role]}>
                    {clinicalAlerts.length > 0 && <Panel header={`Clinical Alerts (${clinicalAlerts.length})`} key="clinicalAlerts" style={panelStyle}>
                        {this.renderList(clinicalAlerts)}
                    </Panel>}
                    {providerAlerts.length > 0 && <Panel header={`Provider Alerts (${providerAlerts.length})`} key="providerAlerts" style={panelStyle}>
                        {this.renderList(providerAlerts)}
                    </Panel>}
                    {complianceAlerts.length > 0 && <Panel header={`Compliance Alerts (${complianceAlerts.length})`} key="complianceAlerts" style={panelStyle}>
                        {this.renderList(complianceAlerts)}
                    </Panel>}
                    {snoozedAlerts.length > 0 && <Panel header={`Snoozed Alerts (${snoozedAlerts.length})`} key="snoozedAlerts" style={panelStyle}>
                        {this.renderList(snoozedAlerts, true)}
                    </Panel>}
                </Collapse>
            </div>
        );
    }

    renderNonWorkStationBody = (tasks) => {
        const providerAlerts = this.getProviderAlerts(tasks);
        const nonProviderAlerts = this.getNonProviderAlerts(tasks);

        return (
            <div className={'alertsBody'} style={{ padding: '0 30px' }}>
                <div>{this.renderList(providerAlerts)}</div>
                <div className={'nonProviderListWrapper'}>
                    <div className={'title'}>Below are clinical alert(s) or compliance alert(s):</div>
                    <div className={'subtitle'}>Note: Clinical alert or compliance alert are handled by CDE or Clinical Assistant)</div>
                    {this.renderList(nonProviderAlerts)}
                </div>
            </div>
        );
    }

    getSnoozedTypes = () => {
        return _.get(this.props, 'patientAlertConfig.configs', []).filter(v => v.isValid).map(v => v.taskType);
    }

    getProviderAlerts = (smartAlertsList, filterSnoozed, snoozedTypes)=>{
        return _.filter(smartAlertsList, s => s.category === 'PROVIDER');
            // .filter(v => !filterSnoozed || !snoozedTypes.includes(v.taskType));
    }

    getNonProviderAlerts = (smartAlertsList, filterSnoozed, snoozedTypes)=>{
        return _.filter(smartAlertsList, s => s.category !== 'PROVIDER');
            // .filter(v => !filterSnoozed || !snoozedTypes.includes(v.taskType));
    }

    getClinicalAlerts = (smartAlertsList, filterSnoozed, snoozedTypes) => {
        return _.filter(smartAlertsList, s => s.category === 'CDE');
            // .filter(v => !filterSnoozed || !snoozedTypes.includes(v.taskType));
    }

    getComplianceAlerts = (smartAlertsList, filterSnoozed, snoozedTypes) => {
        return _.filter(smartAlertsList, s => s.category === 'MA');
        // {
        //   return s.category === 'MA' && (!filterSnoozed || !snoozedTypes.includes(s.taskType));
        // });
    }

    getSnoozedAlerts = (getPatientAlertConfig) => {
        return _.get(getPatientAlertConfig,'configs',[]).filter(c => new Date() < c.endDate && c.isValid).map(alert=>helper.snoozeToAlert(alert));
        // _.filter(smartAlertsList, v => snoozedTypes.includes(v.taskType));
    }

    onChangeRadio = (e)=>{
        const { value } = e.target;
        const { setShowUnsavedModal } = this.props;
        if (setShowUnsavedModal) setShowUnsavedModal(this.state.action !== value);
        this.setState({
            action: value,
            snoozeValue: undefined,
            snoozeFromDate: undefined,
        })
    }

    onChangeNote = (e)=>{
        const { setShowUnsavedModal } = this.props;
        const note  = e.target.value;
        if (setShowUnsavedModal) setShowUnsavedModal(this.state.note !== note);
        this.setState({
            note
        })
    }

    renderSnoozeToolTipTitle = () => {
        return (
            <div>
                Currently, a&nbsp;
                <strong style={{ fontWeight: 'bold' }}>clinical alert</strong> or <strong style={{ fontWeight: 'bold' }}>provider alert</strong>&nbsp;
                is also selected. Please unselect before proceeding.
            </div>
        );
    }

    shouldSnoozeBeDisabled = () => {
        const { checkedAlerts } =this.state;
        const tasks = _.get(this.props, 'smartAlertsList.tasks', []).filter(x => checkedAlerts.includes(x.id) && (['PROVIDER', 'CDE'].includes(x.category)));
        return tasks.length > 0;
    }

    handleConfirmSnooze = (value, fromDate) => {
        const { setShowUnsavedModal } = this.props;
        if (setShowUnsavedModal) setShowUnsavedModal(this.state.snoozeValue != value || this.state.snoozeFromDate != fromDate);
        this.setState({ snoozeValue: value, snoozeFromDate: fromDate.clone() });
    }

    renderFooter = ()=>{
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const avatar = _.get(currentUser,'profile.avatar');
        const { note,action,checkedAlerts,snoozeValue, snoozeObj } = this.state;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const radioMap = [
            {
                value:'RESOLVE',
                label:'Resolve alert'
            },
            {
                value:'COMMENT',
                label:'Write note only'
            }]


        return <Row className={'alertsFooter'}>
                <Col span={3}>
                    { helper.renderAvatar(avatar)}
                </Col>
                <Col span={21} className={'noteAndAction'}>
                    <Input.TextArea rows={3} placeholder={'Add note here...'} onChange={this.onChangeNote} value={note}/>
                    <Radio.Group onChange={this.onChangeRadio} value={ action }>
                        { _.map(radioMap,({value,label,disabled},i)=><Radio value={value} disabled={disabled} key={i} style={radioStyle}>{label}</Radio>) }
                        {snoozeObj && (
                            snoozeObj.disabled
                            ? <Radio value={snoozeObj.value} disabled key="snooze" style={radioStyle}>{snoozeObj.label}</Radio>
                            : (
                                <React.Fragment>
                                    <SnoozePopover onConfirm={this.handleConfirmSnooze} value={snoozeValue ? snoozeValue.value : undefined}>
                                        <Radio value={snoozeObj.value} key="snooze" style={radioStyle}>{snoozeObj.label}</Radio>
                                    </SnoozePopover>
                                    {snoozeValue && <div>
                                        <span style={{ color: '#757980' }}>Snooze time: {snoozeValue.interval}</span>&nbsp;
                                        <SnoozePopover onConfirm={this.handleConfirmSnooze} value={snoozeValue ? snoozeValue.value : undefined}><a>Edit</a></SnoozePopover>
                                    </div>}
                                </React.Fragment>
                            )
                        )}
                    </Radio.Group>
                    { this.renderButton(note,action,checkedAlerts,snoozeValue) }
                </Col>
               </Row>
    }

    getTimerDisplayName = () => {
        return `${this.getDisplayName()}:${this.props.patientId}`;
    }

    submitBillable = (type, res) => {
        const { isWorkStation, patientId, updateInterventionDetails } = this.props;
        if (isWorkStation) {
            const apiName = Object.keys(res.data)[0];
            const data = res.data[apiName];
            const docId = data.id;
            let summary = '';
            if (type === 'snooze') {
                const config = _.get(data, 'configs[0]', {});
                summary = `Snooze time: ${config.snoozeInterval} (Starts from ${moment(config.startDate).tz(moment.tz.guess()).format('MM/DD/YY')})`;
            } else if (type === 'comment') {
                summary = _.get(data, 'content');
            } else if (type === 'unsnooze') {
                summary = 'unsnooze';
            } else if (type === 'resolve') {
                summary = 'resolve';
            }
            const resource = atob(docId).split(':')[0];
            const displayName = this.getTimerDisplayName();
            updateInterventionDetails(displayName, apiName, resource, docId, summary);
            this.props.stopTimer(displayName, TIMER_ACTIONS.stopTimerAction);
        }
    }

    renderButton = (note,action,checkedAlerts,snoozeValue)=>{
        const { refetchSmartAlert, onInteraction, setShowUnsavedModal, refetchUnresolvedAlertCount } = this.props
        const hasNote = note.trim().length;
        const selectedAction = action;
        const actionMap = {
            'COMMENT': async ()=> {
                const [res] = await this.commentAlerts();
                this.submitBillable('comment', res);
            },
            'RESOLVE': async ()=>{
                const [res] = await this.closeAlerts();
                await this.commentAlerts(undefined, true);
                this.submitBillable('resolve', res);
            },
            'SNOOZE': async () => {
                const [res] = await this.snoozeAlerts();

                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                const snoozedBy = currentUser.profile.fullName;
                const additionalSnoozeNote = hasNote ? ': ' + note : '';
                const snoozeNote = `Snoozed for ${snoozeValue.interval} by ${snoozedBy}${additionalSnoozeNote}`;
                await this.commentAlerts(snoozeNote);

                this.submitBillable('snooze', res);
            }
        }[action]
        const handleClick = async () => {
            await actionMap();
            // this is for not overwriting snoozeObj
            const { snoozeObj, ...restInitialState } = this.initialState;
            this.setState({...restInitialState}, async () => {
              onInteraction(action);
              await refetchSmartAlert();
              eventHelper.create();
              if (setShowUnsavedModal) 
                setShowUnsavedModal(false);
              if (refetchUnresolvedAlertCount) {
                refetchUnresolvedAlertCount();
              }
            });
        }

        const isSubmitDisabled = selectedAction === 'SNOOZE' ?
                                      (this.shouldSnoozeBeDisabled() || !snoozeValue || !checkedAlerts.length) :
                                      (!hasNote || !selectedAction|| !checkedAlerts.length)
        return <div className={'button'} style={{ marginTop: 15 }}>
            <Button type={'primary'} onClick={ handleClick } disabled={isSubmitDisabled}>Submit</Button>
        </div>
    }

    showCheckEditMode = (cb)=>{
        const { note,checkedAlerts } = this.state;
        const localThis = this;
        if(note.trim().length) {
            let modal = confirm({
                title:'Are you sure you want to leave this page?',
                content:'You have unsaved changes. Your changes will be lost if you navigate away from this page.',
                onOk(){
                    modal.destroy()
                },
                onCancel(){
                    modal.destroy();
                    if(cb) {
                        localThis.setState({ ...localThis.initialState },()=>cb())
                    }

                },
                okText:'Not Now',
                cancelText:'Yes, leave now'
            })
        }else{
            if(cb) {
                cb();
            }
        }
    }
    renderPreNextPatient = ()=>{
        const { currentIndex,setCurrentIndex,alertsWithIdOnly } = this.props;
        const totalAlerts = alertsWithIdOnly.length;
        const nextIndex = currentIndex+1;
        const hasPre = currentIndex >= 1;
        const hasNext = currentIndex < totalAlerts-1;
        const preIndex = currentIndex -1;
        return <Row className={'previousNextWrapper'}>
                <Col style={{ visibility: hasPre ? 'visible' : 'hidden' }}
                     className={'left'}
                     onClick={()=> {
                         Mixpanel.track('clicked','previous','', {});
                         if(hasPre){
                            const cb= ()=> setCurrentIndex(preIndex);
                            this.showCheckEditMode(cb);
                         }
                     }}
                >
                    <Icon type="left" />
                    <span className={'title'} style={{ 'cursor':'pointer' }}>Previous Patient</span>
                </Col>
                <Col onClick={()=>{
                         if(hasNext){
                             Mixpanel.track('clicked','next patient','', {});
                             const cb = ()=> setCurrentIndex(nextIndex);
                             this.showCheckEditMode(cb);
                         }else{
                            Mixpanel.track('clicked','next 10','', {});
                            const cb = ()=>this.fetchNextChunk();
                            this.showCheckEditMode(cb);
                         }
                     }}
                     className={'right'}
                >
                    <span className={'title'} style={{ 'cursor':'pointer' }}>{ hasNext ?'Next Patient' :'Next 10' }</span>
                    <Icon type="right" />
                </Col>
               </Row>
    }

    fetchNextChunk = ()=>{
        const { refetchAlertsWithIdOnly,setCurrentIndex } = this.props;
        refetchAlertsWithIdOnly().then(res=>setCurrentIndex(0));
    }

    renderLoading = ()=>{
        return <IHLoading/>
    }

    getDisplayName = () => {
        return this.constructor.displayName;
    }

    // onTimerSent = () => {
    //     if (this.props.refetchUnresolvedAlertCount) {
    //         this.props.refetchUnresolvedAlertCount();
    //     }
    // }

    render() {
        const { loadingSmartAlertsList, smartAlertNetworkStatus, loadingUser, userNetworkStatus,
            loadingPatientAlertConfig, patientAlertConfigNetworkStatus,
            isWorkStation, patientId, smartAlertsList, isSingle } = this.props;
        if ((loadingSmartAlertsList && smartAlertNetworkStatus === 1)
            || (loadingUser && userNetworkStatus === 1)
            || (loadingPatientAlertConfig && patientAlertConfigNetworkStatus === 1)) return this.renderLoading();

        return (
            <Row className='providerSmartAlertResolveOutter' style={{ overflow: 'visible' }}>
                {/* {isWorkStation &&
                    <TimerContainer
                        displayName={this.getDisplayName()}
                        category="WORKSTATION_ALERT"
                        action='REVIEW'
                        viewingDetails={{
                            component: this.constructor.displayName,
                            path: window.location.pathname
                        }}
                        patientId={patientId}
                        docId={_.get(smartAlertsList, 'tasks[0].id', '')}
                        keepEventListener={false}
                        sendAndResetOnStop
                        onTimerSent={this.onTimerSent}
                    />
                } */}
                <div className={'workstation-section-body'} style={{ paddingBottom: 20 }}>
                     { !isWorkStation && !isSingle && this.renderPreNextPatient() }
                    <div className={'workstation-alerts'}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 15px' }}><AlertLevelColorIndicator /></div>
                        { !isWorkStation && this.renderHeader() }
                        { this.renderBody() }
                    </div>
                    <div className={isWorkStation ? 'workstation-submit-form' : ''}>
                        { this.renderFooter() }
                    </div>
                </div>
            </Row>
        );
    }
}
const smartAlerts = graphql(getPatientSmartAlert, {
    options: (ownProps) => {
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props: ({ data,refetch }) => {
        return {
            smartAlertsList: _.get(data, 'getPatientSmartAlert'),
            refetchSmartAlert: _.get(data,'refetch'),
            loadingSmartAlertsList: data.loading,
            smartAlertNetworkStatus: data.networkStatus
        }
    }
});
const userForAvatarData = graphql(userForAvatar,{
    options:(ownProps) =>{
        const id = ownProps.patientId;
        return {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props:({data})=>{
        return {
            user:_.get(data,'user'),
            loadingUser: data.loading,
            userNetworkStatus: data.networkStatus
        }
    }
});
const withPatientAlertConfig = graphql(getPatientAlertConfig,{
    options:(ownProps) =>{
        const memberId = ownProps.patientId;
        return {
            variables: {
                memberId,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }
    },
    props:({data})=>{
        return {
            patientAlertConfig: _.get(data, 'getPatientAlertConfig', {}),
            loadingPatientAlertConfig: data.loading,
            patientAlertConfigNetworkStatus: data.networkStatus
        }
    }
});

const mapToDispatch = (dispatch)=>{
    return {
        stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
        updateInterventionDetails:(displayName,apiName,resource,docId,summary)=>dispatch(updateInterventionDetails(displayName,apiName,resource,docId,summary)),
    }
}
export default compose(userForAvatarData,smartAlerts,withPatientAlertConfig,connect(null,mapToDispatch))(ProviderAlertResolveComponent);
