import types from '../constants/VitalListWrapperConstants';

export const changeRange = (range) => ({
  type: types.CHANGE_RANGE,
  range
})

export const changeProgram = (currProgIdx) => ({
  type: types.CHANGE_PROGRAM,
  currProgIdx
})

