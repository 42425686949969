import gql from 'graphql-tag';

export default gql`
                query getTempEnrolledPatient($memberId:EID!) {
 getTempEnrolledPatient(memberId:$memberId) {
                id
				category
				organizationName
				firstName
				lastName
				types
				memberId
				bmi
				visitingDetail
				contact
				a1cInfo
				doctorName
  }
}
`
