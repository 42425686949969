import React, { Component } from 'react'
import {Form, Button, Modal, message} from 'antd';
import helper from '../../helper';
import moment from "moment-timezone";
import API from "../../API";
import TerminateClinicComponent from "./TerminateClinicComponent";
const { renderFormByKey, hasErrors } = helper;
const initState = ((props)=>({
    loading: false,
    isDemo: props.isDemo,
    selectedMember: props.managerIds,
    selectedTemplate:props.templateGroupIds,
    logo: props.organization.logo,
    isRequired: false,
    creatingOrg: false,
    showTerminateClinicModal: false,
    prevParentId : _.get(props, 'organization.parent.id'),
    showHistory: false
}));
class DetailFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = initState(props)
  }

  onChange = (e) => {
    let isChecked = e.target.checked
    this.setState({
        isDemo: isChecked
    })
  }

  setShowHistory = (showHistory)=>{
      this.setState({showHistory})
  }
  onSelectMember = (val) => {
    let { selectedMember } = this.state;
    this.setState({
        selectedMember: [...selectedMember, val]
    })
  }

  onDeselectMember = (val) =>{
      let { selectedMember } = this.state;
      this.setState({
          selectedMember: _.filter(selectedMember, (m) => m!==val)
      })
  }

  onSelectTemplates = (val) => {
    let { selectedTemplate } = this.state;
    this.setState({
      selectedTemplate: [...selectedTemplate, val]
    })
  }

  onDeselectTemplates = (val) => {
      let { selectedTemplate } = this.state;
      this.setState({
        selectedTemplate: _.filter(selectedTemplate, (m) => m !== val)
      })
  }

  setLogo = (logo,shouldSetEditMode)=>{
    const { setFormEnabled } = this.props;
    this.setState({
        logo,
    },()=>{
        if(shouldSetEditMode) {
            setFormEnabled(true);
        }
    })
  }

  setShowTerminateClinicModal= (showTerminateClinicModal)=>{
      this.setState({
          showTerminateClinicModal
      })
  }

  renderTerminateClinicModal = ()=>{
      const { setShowTerminateClinicModal } = this;
      const { organization } = this.props;
      const { id,name,internalName } = organization;
      return <Modal visible={true} footer={false} className={'IH-Modal discharge-modal'} title={'Terminate Clinic'}
                    onCancel={()=>setShowTerminateClinicModal(false)}>
                <TerminateClinicComponent id={id} name={name} internalName={internalName}setShowTerminateClinicModal={ setShowTerminateClinicModal } />
             </Modal>
  }

  editClinic = (localThis) => {

        const { refetch, form, organization,setFormEnabled } = localThis.props;
        const { validateFieldsAndScroll } = form;
        const id = organization.id;
        // const isDemo = localThis.state.isDemo;
        const { prevParentId } =  localThis.state;
        const { managers } = organization
        const prevClinicManagerList = _.map(managers, m => m.id)

        validateFieldsAndScroll((err,res) => {
            if(!err) {
                let { internalName, businessName, brandName, description, timeZone, areaBelong,
                    clinicNumber, location, city, state, zip, siteManager, consentForm, orgPermissions,
                    potentialPatientCount,monthlyEnrollGoalCount,monthlyEnrollGoalCountMonth,monthlyEnrollGoalCountYear,
                    visitServeTimeStart, visitServeTimeEnd,isDemo,operationModel, programParticipation, EHR
                } = res;

                this.setState({
                  creatingOrg: true
                })

                const address = {};
                address.streetNumber = '121';
                address.streetName = location || ' ';
                address.city = city || ' ';
                address.state = state || ' ';
                address.postCode = zip || ' ';
                address.country = 'US';


                const phone = {}
                phone.countryCode = '1';
                phone.number = clinicNumber ? clinicNumber : ' ';
                phone.type = 'WORK'

                const variables = {
                    id,
                    name: businessName ? businessName : ' ',
                    internalName: internalName,
                    brandedName: brandName,
                    timezone: timeZone,
                    address,
                    parentId: areaBelong,
                    description,
                    logo:localThis.state.logo,
                    phone,
                    templateGroupIds: consentForm,
                    managerIds: siteManager,
                    excludeBilling: isDemo,
                    identifier: internalName,
                    orgPermissions,
                    operationModel,
                    programParticipation,
                    EHR,
                    potentialPatientCount:parseInt(potentialPatientCount),
                    monthlyEnrollGoalCount: parseInt(monthlyEnrollGoalCount),
                    monthlyEnrollGoalMonth: parseInt(monthlyEnrollGoalCountMonth),
                    monthlyEnrollGoalYear: parseInt(monthlyEnrollGoalCountYear)
                }


                const dateFormat = 'YYYY-MM-DD';
                const dateTimeFormat = 'YYYY-MM-DD HH:mm';
                const visitServeTimezone = 'America/Los_Angeles';
                const startTime = moment.tz(`${moment().format(dateFormat)} ${visitServeTimeEnd}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();
                const endTime = moment.tz(`${moment().add(1, 'day').format(dateFormat)} ${visitServeTimeStart}:00`, dateTimeFormat, visitServeTimezone).toDate().getTime();

                const clinicScheduleVariables = {
                    affectiveDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
                    blockingRoles: ['MA', 'MD'],
                    startTime,
                    endTime,
                };

                if (organization.orgSchedules.length > 0) {
                    clinicScheduleVariables.id = organization.orgSchedules[organization.orgSchedules.length - 1].id;
                } else {
                    clinicScheduleVariables.organizationId = id;
                }
                const { addedList, removedList } = helper.getAddedAndRemovedIds(prevClinicManagerList, siteManager);
                const managersChanged = (addedList.length) || (removedList.length);
                const data = {
                    variables,
                    managersChanged,
                    addedList,
                    removedList,
                    id,
                    organization,
                    clinicScheduleVariables
                }
                if(prevParentId && (prevParentId !== areaBelong)) {
                    this.renderPopUp(data);
                } else {
                    this.saveEdit(data);
                }
            }
        })
    }

    saveEdit = (data) => {
        const { variables, managersChanged, addedList, removedList, id, organization, clinicScheduleVariables} = data;
        const { setFormEnabled } = this.props;
        const { parentId } = variables;
        const cb = ()=>{
            message.info('Clinic Settings updated!')
            // localThis.setState(initState(this.props),()=>{
            //     refetch();
                this.setState({
                    creatingOrg: false,
                    prevParentId: parentId
                },()=>{
                    setFormEnabled(false);
                })
            // });
            // this.onCancelDetail();
        }
        API.editClinic(variables)
            .then(() => {
                if(managersChanged){
                    return helper.addAndRemoveManager(addedList, removedList, id)
                }
            })
            .then(() => {
                if (organization.orgSchedules.length > 0) {
                    return API.editClinicSchedule(clinicScheduleVariables);
                } else {
                    return API.createClinicSchedule(clinicScheduleVariables);
                }
            })
            .then((res) => {
                cb();
            })
            .catch(e=>{
                console.error(e);
                Modal.error({
                    content: 'We could not save the information at this time due to an error. Please refresh and try again.'
                });
                this.setState({ creatingOrg: false });
            })
    }

    // confirmation pop-up for changing clinic's parent org
    renderPopUp = (data) => {
        const { organization, curParentName } = this.props;
        const { variables } = data;
        // If any change apply to this clinic's internal name, use the updated name first, then use the previous internal name
        const clinicName = variables.internalName || organization.internalName;
        const msg = 'Are you sure you want to move ' + clinicName + ' under ' + curParentName;
        Modal.confirm({
            title: msg,
            onOk: ()=> this.saveEdit(data),
            onCancel: ()=> this.setState({ creatingOrg: false })
        });
    }

  handleCancel = ()=>{
      const { setFormEnabled,form } = this.props;
      const iniState = initState(this.props);
      const { logo } = iniState;
      form.resetFields();
      setFormEnabled(false);
      this.setState(iniState);

  }

  render() {
    const { renderTerminateClinicModal,renderTerminateBtn } = this;
    const { editMode, form } = this.props;
    const { creatingOrg, showTerminateClinicModal} = this.state;
    return (
      <div className='clinic_popup_detail'>
        { renderFormByKey(this, 'editClinicForm') }
        { showTerminateClinicModal&&renderTerminateClinicModal() }  
        <div className={'createAreaButtonWrapper'}>
            { editMode && <div style={{float: 'right'}}>
                            <Button onClick={() => this.editClinic(this)} disabled={hasErrors(form.getFieldsError())} type={'primary'} loading={creatingOrg}>Save</Button>
                            <Button style={{marginLeft: 5}} onClick={() => this.handleCancel()}>Cancel</Button>
                          </div>
            }
        </div>
      </div>
    )
  }
}

DetailFormComponent = Form.create({onFieldsChange:(props)=>{
    const { setFormEnabled } = props;
    setFormEnabled(true);
    }})(DetailFormComponent)

export default DetailFormComponent
