import {
    React,
    BaseComponent,
    ND
} from './util'

//TODO
const Component = class extends BaseComponent{

    render(){
        const p = this.props;
        const titles = p.titles || ['Available List', 'Selected List']
        return (
            <ND.Transfer
                className="IH-Transfer"
                {...p}
                titles={titles}
            />
        )

    }

}

// Component.propTypes = {
//
// }

export default Component
