import gql from 'graphql-tag';

export const monthlyReviewList = gql`
  query monthlyReviewList (
    $page: Int
    $count: Int
    $filters: MonthlyReviewListOpts
    $sort: MonthlyReviewListSort
  ){
    monthlyReviewList (
        page: $page
        count: $count
        filters: $filters
        sort:$sort
    ){
      data {
        id
        note
      }
    }
  }
`; 

export const monthlyReviewContent = gql`
  query getMonthlyReviewContent ($memberId: EID!) {
    getMonthlyReviewContent(memberId: $memberId) {
      vitals {
        vital
        count
      }
      goals {
        conditionEnum
        goalEnum
        condition
        value
      }
    }
  }
`;
