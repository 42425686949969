
import { constants } from '../../../../lib/utils'

export default constants('patient', 'program', [
    'CHANGE_SELECT',
    'CHANGE_DURATION',
    'CHANGE_START_DATE',
    'SET_SELECT_CARETEAM',
    'SET_HAVE_CARETEAM',
    'INITIAL_STATE',
    'RESET_STATE'
])
