import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import { commonActions } from 'libModule/helpers/action-helpers'
import get from 'lodash/get'
import find from 'lodash/find'
import { CareplanHelper, getInitAlertFormData, getAlertFormData } from 'modulesAll/utils/helpers/task'
import CareplanCommonActions from 'patientModule/enrolledProgramInfo/Careplan/actions/CommonActions'
import CareplanCommonTypes from 'patientModule/enrolledProgramInfo/Careplan/constants/CommonConstants'
import alertFormActions from 'patientModule/enrolledProgramInfo/AssessmentAlert/actions/AlertFormActions'
import types from 'patientModule/enrolledProgramInfo/Assessment/constants/ListConstants'
import FormTypes from 'patientModule/enrolledProgramInfo/Assessment/constants/FormConstants'
import AlertListTypes from 'patientModule/enrolledProgramInfo/AssessmentAlert/constants/AlertListConstants'
import AlertFormTypes from 'patientModule/enrolledProgramInfo/AssessmentAlert/constants/AlertFormConstants'
import AlertFormContainer from 'patientModule/enrolledProgramInfo/AssessmentAlert/containers/AlertFormContainer'

const FORM_PAGE = CareplanCommonTypes.ASSESSMENT_FORM_PAGE

const commonActionsObject = commonActions(types, [
  'onCheckTableRow',
  'onTableChange',
  'changePage',
  'reset'
])

const updateForm = (rowData, isEditMode)=> {
  return (dispatch, getState) => {
    dispatch({
      type: FormTypes.UPDATE_FORM,
      fieldsValue: convertToFieldsValue(rowData),
      isEditMode,
      assessment: {
        id: get(rowData, 'id'),
        nameKey: get(rowData, 'nameKey'),
        name: get(rowData, 'name')
      }
    })
  }
}

const updateAlertForm = (rowData, tasks, isEditMode=true)=> {
  return (dispatch, getState) => {
    const currentTask = find(tasks, task => get(task, 'assessment._id') === btoa(atob(rowData.id).split(':')[1]))
    if (!currentTask) return
    const initData = CareplanHelper.convertTaskToAlertFormData(currentTask)
    dispatch(alertFormActions.updateForm(convertToFieldsValue(initData), isEditMode))
    const fakeRowData = { alertFormData: getAlertFormData(currentTask) }
    dispatch({
      type: AlertListTypes.INIT_DATA,
      initFormData: getInitAlertFormData(fakeRowData, AlertFormContainer, AlertListTypes.REMOVE_TAB, AlertFormTypes.RESET_FORM, dispatch)
    })
  }
}

const resetForm = ()=> {
  return {
    type: FormTypes.RESET_FORM
  }
}

const goToForm = () => {
  return CareplanCommonActions.changePage(FORM_PAGE)
}

const selectItem = (selectedIds)=> {
  return {
    type: types.SELECT_ITEM,
    selectedIds,
  }
}
// tasks: {
//   tasks,
//   removedTasksId
// }
const changeTasks = (tasks) => {
  return {
    type: types.CHANGE_TASKS,
    tasks,
  }
}

export default {
  ...commonActionsObject,
  updateForm,
  updateAlertForm,
  resetForm,
  goToForm,
  selectItem,
  changeTasks
}
