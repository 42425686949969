import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { lightEnrolledProgramList } from 'modulesAll/graphql/enrolledProgramList';
import { EP_COUNT_MAX } from '../../../../lib/constants';
import EnrolledProgramWithId from './EnrolledProgramWithId';

class EnrolledProgramList extends React.Component {

  getEnrolledProgram = () => {
    const enrolledProgramList = _.get(this.props, 'data.enrolledProgramList.data') || [];
    const sortedEnrolledProgramList = _.orderBy(enrolledProgramList, [programme => programme.name.toLowerCase()], ['asc'])
    const startedProgramsCanView = sortedEnrolledProgramList.filter(enrolled => (enrolled.status !== 'DISCHARGED' && enrolled.status !== 'RETURNED'))
    return _.get(startedProgramsCanView, '[0].id');
  }
  
  render() {
    const id = this.getEnrolledProgram();

    if (id) {
      return (
        <EnrolledProgramWithId enrolledProgramId={id}>
          {this.props.children}
        </EnrolledProgramWithId>
      );
    }
    
    return null;
  }
}

const epList = graphql(lightEnrolledProgramList, {
  options: ownProps => ({
    variables: {
      page: 1,
      count: EP_COUNT_MAX, //note: there's no pagination for EPList
      filters: {
        memberId: ownProps.patientId,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => { return { data } },
});

const enhance = compose(
  epList,
);
export default enhance(EnrolledProgramList);
