import {MiniEnrolledProgram} from './schema/MiniEnrolledProgram';
import UserDeviceRecords from './schema/UserDeviceRecords';

import gql from "graphql-tag";
import { Phone } from './schema/common';
import { VisitRemoteEnrollmentSchema } from './schema/RemoteEnrollment';
import { CTAssigneeSchema } from './schema/CTAssignee';

export default gql`query visitList($organizationId:EID,$page:Int,$count:Int,$type:[VisitTypeEnum],$providerIds:[EID],$memberId:EID,$appointmentFrom:Date,$appointmentTo:Date,$sort:VisitListSort,$sortByConfirm:Boolean) {
    visitList(filters: {organizationId:$organizationId,providerIds:$providerIds,types:$type,memberId:$memberId,appointmentFrom:$appointmentFrom,appointmentTo:$appointmentTo},page:$page,count:$count,sort:$sort,sortByConfirm:$sortByConfirm) {
        pageInfo{
     	    total
            lastPage
        }
        data{    
          id
          createdAt
          assignees{
            assignedToRD{
                providerId,name
            }
            assignedToCA{
                providerId,name
            }
            assignedToHC{
              providerId,name
            }
          }
          patientRefer {
              referredBy {
                id
                allRoles {
                  refId
                  name
                  organization {
                    id
                  }
                }
              }
              clinicGoals {
                conditionEnum
                goalEnum
              }
              status{
                  patientConsentEnroll
                  screeningStatus
                  confirmDevicesEnroll
                  scheduleInitVisitEnroll 
                  referStatus
                  enrollStatus
              }
          }
          member{
            ${CTAssigneeSchema}
            remoteEnrollment{
              ${VisitRemoteEnrollmentSchema}
            }
            id
            phone{
              ${Phone}
            }
            phoneReportInfos {
              LastUsedAt
              mobileInfo {
                appVersion
                latestAppVersion
              }
              authorityInfo {
                cameraStatus
                microphoneStatus
              }
            }
            profile{
              fullName
              gender
              
              ...on MemberProfile{
                birthday
                lastMeasuredByType{
                    BG,BP,PO,TM,HS,ECG,HR,EXERCISE
                }
                isSelfEnrolled
                conditionsList {
                  HLD
                  HTN
                  DM
                  CKD
                  Obesity
                  PreDM
                  COPD
                  ESRD_Dialysis
                }
                lastMeasuredAt
                deviceRecords {
                  ${UserDeviceRecords}
                }
                healthConditions
                programCategories {
                  name
                  enrolled
                }
                doctor {
                  id
                  profile {
                    fullName
                  }
                }
              }
            }
          }
          type
          reason
          programs{
            ${MiniEnrolledProgram}
          }
          associatedVisit{
            id
            type
            category
            type
            providers{
                id
                profile{
                  fullName
                  firstName
                  lastName
                }
            }
            visitRoles       
            appointmentAt    
            reason
          }
          doctorNotes
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
            ESRD_Dialysis
          }
          category
          appointmentAt
          appointmentTo
          checkinAt
          checkoutAt
          providers{
            id
            profile{
              fullName
              firstName
              lastName
            }
          }
          visitRoles
          shouldSeeDoctor  
          tasks{
            key
            value
          }
          details{
            doctorSession
            cdeSession
            technicalSetup
            eyeExam
            urineTest
            footExam
            height
            weight
            mpTest
            waistHipRatio
            a1cTest
            spo2
            temperature
            addToContactsStatus
            appIsUptoDate
            autoUpdateStatus
            EMRAttached
            bgMeasurement{
              blood_glucose
                beforeMeal
              mealType
            }
            bpMeasurement{
              systolic_blood_pressure
              diastolic_blood_pressure
              heart_rate
            }
          }
          enrolledProgramStatus
          confirm
          workingListStatus
          conditionsList{
            HLD
            HTN
            DM
            CKD
            Obesity
            PreDM
            COPD
          }
          labTests{
            id
            createdAt
            updatedAt
                  dateCollected
                  templateId
                  templateName
                  results{
                      name
                      value
                      unit
                      isInHouse
                  }
            }   
            vitalTypes 
            needInHouseA1C
            labResult{
                id
                createdAt
                updatedAt
                dateCollected
                templateId
                templateName
                results{
                      name
                      value
                      unit
                      isInHouse
                }
            }  
            deviceRecordCount{
              ${UserDeviceRecords}
            }
            inventoryCount {
              ${UserDeviceRecords}
            } 
          }
     }

}`
