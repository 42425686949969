import React from 'react';
import VisitPropTypes from '../constants/propTypes';
import { Table } from 'antd';
import tableColumns from '../constants/tableColumns';
import handleFilterData, { visitNameFilter } from '../constants/tableFilters';
import VisitTableTitle from './VisitTableTitle';

const NEXT_DAY_TABLE_KEY = 'visits-next-day-table';

const NextDayVisitsComponent = props => {
  const { visitList, visitListCount, tableSelectedFilters, tableSelectedAdvancedFilters } = props;
  const allSelectedFilters = { ...tableSelectedFilters, ...tableSelectedAdvancedFilters };
  const { filteredData, advancedFilterResultCount} = handleFilterData(allSelectedFilters, visitList);

  const tableProps = {
    scroll: { x: '130%' },
    pagination: false,
    rowKey: (record, idx) => record.id || idx,
    columns: [
      tableColumns.visitCategory(),
      tableColumns.visit(),
      tableColumns.patient(visitNameFilter(allSelectedFilters)),
      tableColumns.primaryProvider(),
      tableColumns.enrollStatusVitals(),
      tableColumns.visitNote(),
      tableColumns.participant(),
      tableColumns.visitStatus(),
      tableColumns.visitAction({}, props),
      tableColumns.chart({}, props)
    ]
  };

  return <div>
    <VisitTableTitle 
      resultCount={advancedFilterResultCount}
      totalCount={visitListCount}
      visitWindowTime={props.visitWindowTime}
      tableSelectedAdvancedFilters={props.tableSelectedAdvancedFilters}
      tableAdvancedFilters={props.tableAdvancedFilters}
    />
    <Table
      key={NEXT_DAY_TABLE_KEY}
      id={NEXT_DAY_TABLE_KEY}
      {...tableProps}
      dataSource={filteredData}
    />
  </div>;
};

NextDayVisitsComponent.displayName = 'visitsPage/components/NextDayVisitsComponent';
NextDayVisitsComponent.propTypes = VisitPropTypes.tab;

export default NextDayVisitsComponent;