import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import _ from 'lodash'

// files
import actions from '../actions';
import Client from 'libModule/gqlClient';
import { IHMultiSelect } from 'ihcomponent';
import InactivePatientsComponent from '../components/InactivePatientsComponent';

// Queries & Mutations
import enrolledUserStatus from 'modulesAll/graphql/enrolledUserStatus';
import updateLastReviewedAt from 'modulesAll/graphql/updateLastReviewedAt';
import providerNoteTagsList from 'modulesAll/graphql/providerNoteTagsList';

const Container = class extends React.Component {
  static displayName = 'ProviderAlertListContainer'

  constructor(p) {
    super(p)
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleReviewButton = this.handleReviewButton.bind(this);
    this.state = { currentPrograms: [], optionRaw: [], defaultValue: [] };
  }
  componentWillMount() {
    // get program options
    const optionRaw = _.map(JSON.parse(sessionStorage.getItem('adminProgramList')) || [], (prog) => ({
      name : prog.name,
      value : prog.id
    }));

    // set default value
    const defaultValue = optionRaw && optionRaw.length > 0 ? [ optionRaw[0].value ] : [];

    this.setState({ defaultValue, optionRaw, currentPrograms: defaultValue }, () => this.props.selectCurrentProgram(this.state.defaultValue));
  }
  handleProgramChange(val) {
    this.setState({ currentPrograms: val }, () => this.props.selectCurrentProgram(this.state.currentPrograms));
  }
  async handleReviewButton(id) {
    const variables = {
      adminProgramIds: this.state.currentPrograms,
      memberId: id
    };

    try {
      const res = await this.props.runUpdateLastReviewedAt({ variables });

      if (res.data.updateLastReviewedAt) {
        const enrolledUsersVariables = {
          adminProgramIds: this.state.currentPrograms
        };

        // 2-22-18 - JV - Rerun 'enrolledUserStatus' query to fetch latest
        Client.query({
          query: enrolledUserStatus,
          variables: { ...enrolledUsersVariables },
          fetchPolicy: 'network-only'})
          .then()
          .catch(err => console.log('----- ERROR: enrolledUserStatus Client.query! err: ', err));
      }
    } catch (e) {
      console.log('------ ERROR: updateLastReviewedAt! e: ', e);
    }
  }
  render() {
    return (
      <div className='inactive-patients-container'>
        {/*<div className='inactive-patients-dropDown'>*/}
          {/*/!*<IHMultiSelect option={this.state.optionRaw}*!/*/}
                         {/*/!*defaultValue={this.state.defaultValue}*!/*/}
                         {/*/!*onChange={ val => this.handleProgramChange(val) } />*!/*/}
        {/*</div>*/}
        <div className='inactive-patients-list'>
            <InactivePatientsComponent handleReviewButton={this.handleReviewButton} {...this.props} />
        </div>
      </div>
    )
  }
}

const loadEnrolledUsersList = graphql(enrolledUserStatus, {
  options: (ownProps) => {
    return {
      variables: {
        adminProgramIds: _.get(ownProps,'currentProgram',[])
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  },
  props: ({ ownProps, data }) => {
    return {
      enrolledUserStatusList: _.get(data, 'enrolledUserStatus'),
      isLoading_enrolledUserStats: _.get(data, 'loading')
    }
  }
});

const tagsOptions = graphql(providerNoteTagsList,{
    props: ({ data:{ loading, providerNoteTagList} })=>{
        if(!loading){
            return {
                providerNoteTagList:providerNoteTagList.data
            };
        }
    }
});

const runUpdateLastReviewedAt = graphql(updateLastReviewedAt, { name: 'runUpdateLastReviewedAt' });

const mapState = (state) => {
  return {
    currentProgram: state.inactivePatientsList.main.currentProgram
  }
};
const mapDispatch = (dispatch) => ({
  selectCurrentProgram: currentProgram => dispatch(actions.selectCurrentProgram(currentProgram))
});

Container.propTypes = {
  selectCurrentProgram: PropTypes.func.isRequired,
  currentProgram: PropTypes.array
}

export default compose(
  connect(mapState, mapDispatch),
  loadEnrolledUsersList,
  runUpdateLastReviewedAt,
  tagsOptions
)(Container);
