import React from 'react';
import { Icon } from 'antd';
import Overlay from '../component/Overlay';
import I18N from '../../I18N';

const renderIneligibleOverlay = (hasNoConditionFromIcdCode) => {
  const ineligibleBold = hasNoConditionFromIcdCode
    ? I18N.get('referAndEnroll.noIcdCodeOverlayBold')
    : I18N.get('referAndEnroll.hldOnlyOverlayBold');
  const ineligibleSubText = hasNoConditionFromIcdCode
    ? I18N.get('referAndEnroll.noIcdCodeOverlaySubText')
    : I18N.get('referAndEnroll.hldOnlyOverlaySubText');
  return (
    <Overlay>
      <div className="flex-center fit-parent">
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>
            <Icon type="info-circle" theme="filled" className="red-svg" />
            &nbsp;
            {ineligibleBold}
          </div>
          <div>{ineligibleSubText}</div>
        </div>
      </div>
    </Overlay>
  );
}
export default renderIneligibleOverlay;
