import { providerMenuItems } from './provider';

const menus = {
  ...providerMenuItems,
  videochat: {
    id: 'videochat',
    title: 'Visits',
    src: '/image/visits.png',
    icon: 'bar-chart',
    href: '/videochat',
    class: 'videochat'
  },
  visits: {
    id: 'schedule',
    title: 'Calendar',
    // icon: 'dashboard',
    icon: 'bar-chart',
    href: '/schedule',
    class:'visits'
  }
};

const doctorArray = [
  menus['videochat'],
  // menus['WorkStation'],
  // menus['visits'],
  menus['Alerts'],
  menus['TaskAssignments'],
  menus['Chat'],
  menus['Patients'],
  menus['DataInsights'],
  menus['Reports'],
  menus['RPMPatientList'],
  // menus['settings']
];

export default doctorArray;
export const HOMEPAGE = '/videochat';
export const specialDoctorMenu = [
  menus['videochat'],
  menus['TaskAssignments'],
  menus['Chat'],
  menus['Patients'],
];
