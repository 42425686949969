import React from 'react';
import { connect } from 'react-redux';
import { openModal, openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';
import { Modal } from 'antd';
import ConsentFormComponent from '../../patient/main/components/consentForm/ChangeCTConsentFormComponent';
import ChangeCareTeamContainer from '../../patient//enrolledProgramInfo//containers/ChangeCareTeamContainer';
import { Row, Col } from 'antd';

// Component starts here
let CareTeamInfo = class extends React.Component{
    static displayName = 'careTeamInfo';

    constructor(props) {
      super(props);
      this.state = {
        showConsentForm: false,
        changeCTVal:{},
        consentProviderName:"",
      }
    }

    ChangeCTSubmit = (val, consentProviderName, showConsentForm) => {
      this.setState({
        showConsentForm,
        changeCTVal: val,
        consentProviderName
      });
    }

    setShowConsentForm = (showConsentForm,fn)=>{
      this.setState({ showConsentForm },()=>{
          if(fn) fn();
      })
  }

  renderShowSendConsentForm=()=>{
      const { props,setShowConsentForm } = this;
      const { consentInfo,refetchConsentInfo,data,currentProgram } = props;
      const patientId = _.get(data, 'user.id');
      const memberFullname = _.get(data, 'user.profile.fullName');
      const appLanguage = _.get(data, 'user.profile.appLanguage.code');
      const spokenLanguage = _.get(data, 'user.profile.language.code');
      const {consentProviderName} = this.state;
      const phoneNumbers = _.get(data, 'user.phone', []).map(p => '+' + p.countryCode + p.number);
      return <Modal visible={true} footer={false} width={'90%'} className='consentFormModal' onCancel={()=>this.setShowConsentForm(false)}>
              <ConsentFormComponent
                  language={appLanguage || spokenLanguage || 'EL'}
                  consentInfo={consentInfo}
                  consentProviderName={consentProviderName}
                  memberId={patientId}
                  memberFullname={memberFullname}
                  setShowConsentForm={setShowConsentForm}
                  refetchConsentInfo={refetchConsentInfo}
                  phoneNumbers={phoneNumbers}
                  isEnrolled={true}
                  changeCTVal={this.state.changeCTVal}
              />
             </Modal>
  }

    getEachCareteam(ct, index) {

      return (
        <Row key={index}>
          {
            (ct.members || []).map(({ user }) => {
              const { id, profile = {} } = user;
              return (
                <Col key={id} span={4} style={{ marginRight: 16, minWidth: 180, maxWidth: 220, padding: 0 }}>
                  <span className='care-team-member'>{profile.fullName || '--'}</span>
                </Col>
              );
            })
          }
        </Row>
        // <div className='c-e' key={index}>
        //   <div className='c-e-info'>
        //     {
        //       _.map(members, (m, index) => {
        //         return <div className='c-e-column' key={index}>{m}</div>
        //       })
        //     }
        //   </div>
        // </div>
      )
    }

    handleOpenModal = () => {
      const {props, ChangeCTSubmit} = this;
      const { currentProgram, openModal, openErrorModal } = props;
      const modalProps = {
        showModal: true,
        showHeaderCloseButton: true,
        className: 'ChangeCareTeamContainer',
        size: 'lg'
      };

      openModal(
        <ChangeCareTeamContainer
          ChangeCTSubmit={ChangeCTSubmit}
          currentProgram={currentProgram}
          onError={err => {
            console.error(err);
            openErrorModal(`You're not authorized to make this change since you're not in this patient's care team`);
          }}
        />,
        modalProps
      );
    }

    render(){
      this.programInfo = _.get(this.props, 'currentProgram');
      this.programObject = new EnrolledProgramClass(this.programInfo);
      const team = this.programObject.format('careteams');

      return <div className='v-care-team-member-info'>
        <div className='header'>
          <span>Unified Care Team</span>
          <a className='change-care-team-btn' onClick={this.handleOpenModal}>
            Change Care Team
          </a>
        </div>
        <div className='v-care-team-members'>
          {
            _.map(team, (t, index)=>{
              return this.getEachCareteam(t.variables, index)
            })
          }
        </div>
        { this.state.showConsentForm && this.renderShowSendConsentForm() }
      </div>;
    }
}

export default connect(
  null,
  { openModal, openErrorModal }
)(CareTeamInfo);
