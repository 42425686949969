import React from 'react';
import {Form} from 'antd';
import BehavioralChangeComponent from '../component/GoalStatement/BehavioralChangeComponent';
import goalHelper from '../component/helper/goalHelper';
import '../style/goalStatement.styles.scss';

// Used in patient profile ONLY
let BehavioralChangeContainer = class extends React.Component{
    title = 'Stage of Behavioral Change';
    displayName = 'BehavioralChangeContainer';

    render(){
      return (
      <div className='BehavioralChangeContainer' id='behavioral-change-container'>
          <BehavioralChangeComponent {...this.props} showBehavioralGoals />
      </div>)
    }
}


BehavioralChangeContainer = Form.create({
  onFieldsChange: (props) => {
    const { onChange, form } = props;
    if(typeof onChange ==='function'){
      const { getFieldValue } = form;

      let editEnrolledProgramVar;
      let shouldEditEnrolledProgram;
      const goalNotes = getFieldValue('goalNotes');
      const prevGoalNotes = _.get(props, 'program.extraNote');
      // update enrolledProgram when goalNotes exists in this part and has been changed
      if(typeof goalNotes !== 'undefined' && !_.isEqual(goalNotes, prevGoalNotes)){
        editEnrolledProgramVar = goalHelper.getMutateVariableGoals(props);
        shouldEditEnrolledProgram = true;
      }

      let editUserVar = goalHelper.getUserPars(props);
      let shouldEditUser = true;

      onChange({
        shouldEditEnrolledProgram,
        editEnrolledProgramVar,
        shouldEditUser,
        editUserVar,
        props
      });
    }
  }
})(BehavioralChangeContainer);

export default BehavioralChangeContainer;
