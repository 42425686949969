import { React, _ } from 'ihcomponent';
import { COMPONENTS_MAP } from '../../utils';

export default class extends React.Component {
  static displayName = 'patientView/myAssessmentAndSurvey/edit/components/AssessmentFilledComponent'

  constructor(props) {
    super(props);

    this.onAnswerChange = this.onAnswerChange.bind(this);
  }

  onAnswerChange(index) {
    const { changeAnswer } = this.props;

    return (answer) => {
      changeAnswer(index, answer);
    };
  }

  renderQuestion(question, index) {
    const { options, answer, type } = question;
    const Comp = _.get(COMPONENTS_MAP, `${type}.component`);

    if (Comp) {
      return (
        <Comp
          options={options}
          answer={answer}
          isPreview={this.props.isPreview}
          onChange={this.onAnswerChange(index)}
        />
      );
    }
  }

  render() {
    const { assessmentFilled } = this.props;

    return (
      <div className="assessment-filled-wrap">
        <h4>{assessmentFilled.name}</h4>
        <h6>{assessmentFilled.description}</h6>

        <div className="split-line" />

        {
          _.map(assessmentFilled.questions, (q, i) => (
            <div className="question" key={`assessment-q-${i}`}>
              <h5>Question {i + 1}</h5>
              <h6>{q.text}</h6>

              <div className="question-body">
                {this.renderQuestion(q, i)}
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}
