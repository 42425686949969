import { React, IHButton } from 'ihcomponent'
import Detail from './PatientInfoComponent'
import PropTypes from 'prop-types'

export default class extends React.PureComponent {
  static displayName = 'vendor/patient/components/PatientComponent'

  static propTypes = {
    data: PropTypes.object,
    runUserQuery: PropTypes.func,
    setReplaceDevicesStep: PropTypes.func,
    changeDeviceStatusTableInfo: PropTypes.func,
    replaceOrReturnDevicesStep: PropTypes.number,
    returnDevicesStep: PropTypes.number,
    setReturnDevicesStep: PropTypes.func,
    devicesStatus: PropTypes.array,
    patientInfo: PropTypes.object,
    changeDeviceRemarkTableInfo: PropTypes.func,
    processReplaceOrReturnDevicesAsync: PropTypes.func,
    callReturnDevices: PropTypes.func,
    runReturnDevicesMutation: PropTypes.func,
    enrolledProgramId: PropTypes.string,
    onChangeEnrolledProgram: PropTypes.func,
    enrolledProgramList: PropTypes.array,
    resetDevicesStatusTableInfo: PropTypes.func,
    setSearchText: PropTypes.func,
    searchText: PropTypes.string,
    searchStatus: PropTypes.string,
    resetForm: PropTypes.func,
    initDevicesStatusTableInfo: PropTypes.func,
    getPreviousKeyInDevicesInfo: PropTypes.func,
    updateDevicesInEnrolledProgramInList: PropTypes.func //use by DevicesReplacementHistory
  }

  onClickSearch = () => {
    this.props.runUserQuery(this.props.searchText);
  }

  keyInTextSearch = (e) => {
    this.props.setSearchText(e.target.value)
  }

  render() {
    return (
      <div className="vendor-patient">
        <div className="content-wrap">
          <div className="IH-SearchTable" style={{border:'none', display: 'flex', justifyContent: 'space-around', width: '40em'}}>
          <div className="ih-dd" style={{display:'inline-block', width:'260px', verticalAlign:'middle'}}>
            <div className="ih-searchbox" style={{right:'inherit'}}>
              <i className="fa fa-search"></i>
              <input onBlur={this.keyInTextSearch} /**value={this.props.searchText}**/ autoFocus className="html-input" placeholder="Identification" maxLength="30" />
            </div>
          </div>

          <IHButton label="Search Patient" type="primary" onClick={this.onClickSearch} />

          </div>
            <Detail
              enrolledProgramId={this.props.enrolledProgramId}
              patientInfo={this.props.patientInfo}
              devicesStatus={this.props.devicesStatus}
              setReplaceDevicesStep={this.props.setReplaceDevicesStep}
              initDevicesStatusTableInfo={this.props.initDevicesStatusTableInfo}
              replaceOrReturnDevicesStep={this.props.replaceOrReturnDevicesStep}
              setReturnDevicesStep={this.props.setReturnDevicesStep}
              changeDeviceStatusTableInfo={this.props.changeDeviceStatusTableInfo}
              changeDeviceRemarkTableInfo={this.props.changeDeviceRemarkTableInfo}
              returnDevicesStep={this.props.returnDevicesStep}
              processReplaceOrReturnDevicesAsync={this.props.processReplaceOrReturnDevicesAsync}
              onChangeEnrolledProgram={this.props.onChangeEnrolledProgram}
              enrolledProgramList={this.props.enrolledProgramList}
              resetDevicesStatusTableInfo={this.props.resetDevicesStatusTableInfo}
              searchText={this.props.searchText}
              searchStatus={this.props.searchStatus}
              resetForm={this.props.resetForm}
              getPreviousKeyInDevicesInfo={this.props.getPreviousKeyInDevicesInfo}
              updateDevicesInEnrolledProgramInList={this.props.updateDevicesInEnrolledProgramInList}
              curPage={this.props.curPage}
              handleChangePage={this.props.handleChangePage}
            />
          </div>
      </div>
    );
  }
}
