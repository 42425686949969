import gql from 'graphql-tag'
import {User} from 'graphqlModule/schema/User'


export default gql `
    mutation editMemberProfile (
      $id: EID!,
      $address: InputAddress,
      $profile: InputMemberUserProfile,
      $identification: [InputIdentification]
    ) {
      editMemberProfile (
        id: $id,
        address: $address,
        profile: $profile,
        identification: $identification
      ) {
        ${User}
      }
    }
`
