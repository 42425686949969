import React from 'react';
import I18N from 'modulesAll/I18N';
import { Spin } from 'antd';
import { graphql } from 'react-apollo';
import getMonthlyReviewRecommendation from 'modulesAll/graphql/monthlyReviewRecommendation';
import { RECOMMENDED_ACTIONS_MAP } from '../constant';
import _ from 'lodash';
import { VITAL_TYPE_MAP } from '../../referAndEnroll/constant';
const goalI18N = I18N.get('workStation.goalAssessment');

const GoalAssessmentRecommendedAction = props => {
  const { isMRE, monthlyReviewRecommendation, loading } = props;
  const recActions = _.filter(monthlyReviewRecommendation, v => v && v.recommend);

  const parseRecommendation = (rec, key) => {
    let value = RECOMMENDED_ACTIONS_MAP[rec.recommend];
    if (!_.isNil(rec.vital)) {
      const vitals = _.map(rec.vital.split(','), v => VITAL_TYPE_MAP[v] || v).join(', ');
      value = value.replace('[vital]', vitals);
    }
    if (!_.isNil(rec.count)) {
      value = value.replace('[#]', rec.count);
    }
    return <li key={key}>{value}</li>;
  }

  if (loading) return <Spin />;
  return (
    <div id='goal-assessment-recommended-action'>
      <div className='title'>
        Recommended Action
      </div>
      <ol style={{ marginTop: 20 }}>
        {_.map(recActions, parseRecommendation)}
      </ol>
      {/* {
        isMRE ?
          <div>
            Please review each vital for patterns, review the care plan and EMR as needed, and either help the patient to adjust their schedule, or encourage the patient to monitor vitals more often (2-4 times/week). If the patient has any open or unresolved alerts, take these into consideration and resolve as able.
          </div>
        :
          (
            <ol className='recommended-action-container'>
              <li>
                <span>Please call or message the patient after reviewing the following points:</span>
                <div className='info'>
                  <div className='icon-wrapper'>
                    <img src='/image/workstation/assessment-recommended-action-tip.svg' width='17px' />
                  </div>
                  <div className='text'>
                    {goalI18N.note.recommendedAction}
                  </div>
                </div>
                <ol className='suggestions'>
                  <li>Review each vital summary.</li>
                  <li>
                    Monitoring schedule adjustment
                    <div className='suggestion'>
                      The monitoring schedule is meant to change based on the patients’ conditions and needs. Check if they:
                      <ul>
                        <li>Have insulin during the day or at night?</li>
                        <li>Is the patient new to monitoring this vital?</li>
                        <li>Does the patient have a special schedule? </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    Review clinical goals
                    <ul className='suggestion'>
                      <li>Are they still appropriate based on the patient’s disease progress?</li>
                      <li>Are the patient’s current vitals average in the target range for their clinical goals?</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <span className='highlight'>
                  Record patient’s feedback in provider note
                </span> after interacting with patient.
              </li>
              <li>
                <span className='highlight'>
                  Consider tasking provider
                </span> if you are seeing a particular trend or pattern that is concerning, or per the provider’s preference.
              </li>
              <li>
                <span className='highlight'>
                  If there is a recent doc visit
                </span> - Review doctor’s note and add to provider note about patient’s updated conditions; update medications and labs if there is any change.
              </li>
              <li>
                <span className='highlight'>
                  If the patient sends food logs regularly
                </span> - Review the food logs to get a better picture of the patient's diet, summarize any pattern (e.g. no vegetables for all meals, high fat intake with dinner, etc.) and provide suggestions to the patient accordingly.
              </li>
            </ol>
          )
      } */}
    </div>
  );
}

const withData = graphql(getMonthlyReviewRecommendation, {
  options: (ownProps) => {
    const { memberId } = ownProps;

    return {
      variables: {
        memberId,
      },
      fetchPolicy: 'network-only',
    }
  },
  props: ({ data }) => {
    const { loading, getMonthlyReviewRecommendation } = data;

    return {
      monthlyReviewRecommendation:  getMonthlyReviewRecommendation || [],
      monthlyReviewRecommendationLoading: loading,
    }
  }
});
export default withData(GoalAssessmentRecommendedAction);
