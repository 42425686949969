import React from 'react';
import { Form } from 'antd';
import { CellularDevicesIdSearchDropdownComponent } from '../CellularDevicesIdSearchDropdownComponent/CellularDevicesIdSearchDropdownComponent';
import { CellularDevicesHelpers } from '../../helpers';

export const CellularDeviceIdSearchDropdownFormItemComponent = ({
  form,
  deviceType,
}) => {
  return (
    <Form.Item label="Device ID">
      {
        form.getFieldDecorator(CellularDevicesHelpers.deviceIdFieldName, {
          rules: [
            { required: true, message: 'Device ID is required' },
          ]
        })(
          <CellularDevicesIdSearchDropdownComponent deviceType={deviceType} />
        )
      }
    </Form.Item>
  );
};
