import gql from 'graphql-tag'
import WeakUserAuthentication from './schema/WeakUserAuthentication'
import { loginResponse } from './schema/Login';

export default gql `
  mutation loginWithUsernameOrEmail(
    $usernameOrEmail: String!,
    $password: String!
  ) {
    loginWithUsernameOrEmail(
      usernameOrEmail: $usernameOrEmail,
      password: $password
    ) {
      ${loginResponse}
    }
  }
`;
