import gql from "graphql-tag";

export default gql`
  query resultList(
    $count: Int,
    $filters: ResultListOpts!
  ) {
    resultList(
      count: $count,
      filters: $filters,
    ) {
      data {
        id
      }
      pageInfo {
        total
      }
    }
  }
`;