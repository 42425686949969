import { React, _ } from 'ihcomponent';
import { connect } from 'react-redux'
import moment from 'moment';
import 'moment-timezone';
import { graphql, compose } from 'react-apollo';
import { closeModal, openErrorModal } from 'layoutModule/actions/MainModal'
import { insertTaskResultMeasurement, updateQueries } from 'modulesAll/graphql/insertTaskResult';
import { GQLHelper } from 'libModule/utils';
import ManualInputComponent from '../components/ManualInputComponent';
import { createAuditLog } from 'libModule/utils/auditLog';
import I18N from 'modulesAll/I18N'

class component extends React.Component {
  submit(values) {
    const { insertVital, insertTaskResult, refId, enrolledProgramName } = this.props;

    const commonFields = {
      imageUrl: values.image && values.image.url,
      date: new Date(),
      notes: values.notes,
      memberId: sessionStorage.getItem('currentUserId'),
      timezone: moment.tz.guess(),
    };

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const patientNRIC = _.get(currentUser, 'identification[0].value')
    const patientName = _.get(currentUser, 'profile.fullName')

    insertVital(values, commonFields)
      .then(measureId => insertTaskResult({ variables: { measureId, refId }, updateQueries }))
      .then(res => { this.props.closeModal(); return res }) // I don't want to use comma, why ? I don't know...
      .then(res => createAuditLog({
        action: I18N.get('auditLog.patient.myTask.insertMeasurement'),
        patientNRIC,
        patientName,
        details: { programName: enrolledProgramName, ...values },
        request: values,
        response: res
      }))
      .catch(err => {
        createAuditLog({
          action: I18N.get('auditLog.patient.myTask.insertMeasurement'),
          patientNRIC,
          patientName,
          details: { programName: enrolledProgramName, ...values },
          request: values,
          response: err,
          success: false
        })
        this.props.openErrorModal(GQLHelper.formatError(err))
      });
  }

  render() {
    return (
      <ManualInputComponent {...this.props} submit={this.submit.bind(this)} />
    );
  }
}

const mapState = (state, ownProps) => state;
const mapDispatch = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage)),
});

export default compose(
  graphql(insertTaskResultMeasurement, { name: 'insertTaskResult' }),
  connect(mapState, mapDispatch),
)(component);
