import React from 'react';

import SignOffSubmitInfo from '../SignOffSubmitInfo';

import smartAlertHelpers from '../../../../smartAlert/helper';
import { getTimeWithFormat } from '../../../helper/signOffHelper';

const SignOffAlert = (props) => {
  const { headerText, alerts, healthCoachId } = props;

  return <div className='signOffItem'>
    <div className='header'>
      {headerText || ''}
    </div>
    {
      _.map(alerts, (alert => {
        const { id, createdAt, providerNotes } = alert || {};

        return <div key={id} className='signOffCard signOffAlert'>
          <div className='description'>
            {smartAlertHelpers.renderSmartAlert(alert)}
          </div>
          <div className='description' style={{ display: 'initial', marginBottom: 12 }}>
            <div style={{ display: 'initial', marginRight: 5  }}>
              Alert reason: 
            </div>
            <div style={{ display: 'initial' }} className='alertReasonText'>
              { smartAlertHelpers.renderAlertReason(alert) }
            </div>
          </div>
          <div className='description' style={{ marginTop: 8 }}>
            Generated on: {getTimeWithFormat(createdAt)}
          </div>
          {
            _.map(providerNotes, (pn, idx) => {
              const { id, content, createdBy, createdAt } = pn || {};
              const createdById = _.get(createdBy, 'id');
              const createdByName = _.get(createdBy, 'fullName');
              if(!id || createdById !== healthCoachId) 
                return null;

              const labelText = `${idx === _.get(providerNotes, 'length') -1 ? 'Resolve ' : ''}Note:`;
              return (
                <div key={id} className='description note'>
                  {
                    <p className='hideOnList'>
                      {
                        `${labelText} 
                        ${content}`
                      }
                    </p>
                  }
                  <SignOffSubmitInfo 
                    submittedBy={createdByName}
                    submittedAt={createdAt}
                  />
                </div>
              );
            })
          }
        </div>;
      }))
    }
  </div>;
}

export default SignOffAlert;