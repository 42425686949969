import React from 'react';
import AgoraPatientWaitingComponent from '../component/AgoraPatientWaitingComponent';
import AgoraPatientVideoComponent from '../component/AgoraPatientVideoComponent';
import AgoraVerifyPtComponent from '../component/AgoraVerifyPtComponent';
import { withRouter } from "react-router";
import API from '../API';
import { Modal } from 'antd';
import { openErrorModal } from 'layoutModule/actions/MainModal'
import { connect }from 'react-redux';
import { GQLHelper } from 'lib/utils';
import {graphql} from "react-apollo/index";
import Fullscreen from "react-full-screen";

const AgoraPatientVideoContainer = class extends React.Component{
    constructor(){
        super();
        this.state ={
            agoraChannelName: 0,
            authorized: false,
            authorizing: false,
            audienceMap:{ },
            organization: null,
            verification: null,
            code: null,
            visitId: null,
            videoVisitState: null,
            authFailure: null,
            isFull: false,
        }
    }

    checkVideoToken = async (visitId)=>{
        const { data: {getVirtualVisitToken} } = await API.getVideoToken({ visitId });
        if(getVirtualVisitToken){
            return getVirtualVisitToken;
        }
    }

    setFullScreen = ()=>{
        this.setState({
            isFull: true
        })
    }

    handleVerifyFirstName = async ()=>{
        const { code } = this.props.params;
         this.setState({
             authorizing:true
         })
         try{
             const { data } = await API.loginWithOneClickMeeting({ code });
             const sessionToken  = _.get(data,'loginWithOneClickMeeting.sessionToken');
             const visitId = _.get(data,'loginWithOneClickMeeting.visitId');
             const organization = _.get(data,'loginWithOneClickMeeting.role.organization.name');
             if(sessionToken) {
                sessionStorage.setItem('authToken', sessionToken);
                this.setState({
                    authorized: true,
                    organization,
                    visitId
                })
             }
             await API.activePatientMutate({eventType:'CHECK_IN',visitId});
             const { agoraAppId, agoraChannelName, agoraToken, agoraUid } = getVirtualVisitToken;
             this.setState({ agoraChannelName,agoraAppId,agoraToken,agoraUid,visitId });

        } catch (e) {
             // Modal.error({title:e.message});
             console.log(e);
        }finally {
             this.setState({
                 authorizing: false
             })
         }
    }

    setVerification = (verification)=>{
        this.setState({
            verification
        })
    }

    setAgoraChannel = (args)=>{
        const { state } = this;
        this.setState({
            state,
            ...args,
        })
    }

    async componentDidMount(){
        // const { code } = this.props.params;
        this.handleVerifyFirstName();
        // this.setState({
        //     code
        // })
    }

    render(){
        const { state,handleVerifyFirstName,setVerification,setAgoraChannel,setFullScreen } = this;
        const { authorized,agoraChannelName,organization,verification,code,authorizing,visitId,isFull } = state;
        if(authorizing||!authorized) {
            return <span>Connecting...</span>;
        }
        // if(!authorized) {
        //     return <AgoraVerifyPtComponent code={code}
        //                                    authorizing={authorizing}
        //                                    handleVerifyFirstName={handleVerifyFirstName}
        //                                    setVerification={setVerification}
        //                                    verification={verification}/>
        // }

        if(agoraChannelName==0&&authorized) return <AgoraPatientWaitingComponent organization={ organization }
                                                                                   visitId={visitId}
                                                                                   setAgoraChannel={setAgoraChannel}
                                                                                   agoraChannel={agoraChannelName}
        />
        if(agoraChannelName){
            return <AgoraPatientVideoComponent params = {state} visitId={visitId} />
        }


    }
}
const mapToDispatch = (dispatch)=> {
    return {
        openErrorModal:(error)=>dispatch(openErrorModal(error))
    }
};

export default connect(null,mapToDispatch)(withRouter(AgoraPatientVideoContainer));
