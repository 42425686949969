import React from 'react';
import { graphql, compose } from 'react-apollo';
import editMonthlyReview from '../../graphql/mutation/editMonthlyReview';
import createMonthlyReview from '../../graphql/mutation/createMonthlyReview';
import { editClinicGoalsQuery } from 'graphqlModule/mutation/editEnrolledProgram';
import MonthlyReviewBaseComponent from "../component/MonthlyReviewBaseComponent";
import Client from 'libModule/gqlClient';
import moment from 'moment';
import { getMonthlyReviewContent } from '../../graphql/getMonthlyReviewContent';
import goalAssessmentHelper from '../helper/goalAssessmentHelper';
import { CONDITION_ENUM_MAP, getGoalStatusValue } from '../../careplanNew/component/helper/goalHelper';
import { BILLABLE_VITALS } from '../../../lib/constants';
import { monthlyReviewStatusMap } from "../constant/index";
import { connect } from 'react-redux';
import { openErrorModal } from '../../layout/actions/MainModal';
import { GQLHelper } from '../../../lib/utils';
import getMonthlyReviewSummary from '../../graphql/getMonthlyReviewSummary'
import { clearTypenameFields } from '../../../lib/utils';
import { removeTypename } from '../../../../../package/IHComponent/ShareCare/addWeeklyBGScheduleTemplateAPI';
import {listMonthlySummaryReport} from "../query";
import { checkIfMonthlyReviewInCompleted } from '../helper/monthlyReviewHelper';

const DEFAULT_MONTHLY_REVIEW_TYPE = "MTPR";

const MonthlyReviewPlanContainer = class extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			getGoalsFunc: null,
		};
	}

  componentDidMount = () => {
    if(this.props.copyMTPRHandleSubmit) {
      // from GoalStatement in Visit worklist to save draft MTPR
      this.props.copyMTPRHandleSubmit({ saveDraft: this.handleSaveDraft });
    }
  }

	setGetGoalsFunc = (getGoalsFunc, patientGoals) => {
		this.setState({ getGoalsFunc, patientGoals });
	}

  getPreviousSummary = () => {
    const { monthlyReviewSummary } = this.props;
    const preSummary = removeTypename(monthlyReviewSummary);
    for(let key in preSummary) {
      const value = preSummary[key];
      if(!value) continue;
      preSummary[key] = _.map(value);
    }

    return { preSummary };
  }

  getPreviousVitalsAndGoals = () => {
    const { previousVitals, previousGoals } = this.props;
    return { previousVitals, previousGoals };
  }

	editMonthlyReview = (note, goals, status = 'DONE') => {
		const { monthlyReviewData } = this.props;
		const id = _.get(monthlyReviewData, 'id');
		const variables = {
			id,
			note,
			status,
			goals,
      ...this.getPreviousVitalsAndGoals(),
      ...this.getPreviousSummary()
		};

		return Client.mutate({
			mutation: editMonthlyReview,
			variables,
		});
	}

	createMonthlyReview = (note, goals, status = 'DONE') => {
		const { user, currentProgram } = this.props;
		const teamId = _.get(currentProgram, 'team[0].id');
		const memberId = _.get(user, 'id');
		const type = _.get(this.props, 'defaultMonthlyReviewType');
		const variables = {
			memberId,
			teamId,
			goals,
			type,
			note,
			status,
			monthOfYear: parseInt(moment().format('YYYYMM')),
      ...this.getPreviousVitalsAndGoals(),
      ...this.getPreviousSummary()
		};

		return Client.mutate({
		 mutation: createMonthlyReview,
			variables,
		});
	}

  editClinicGoals = (clinicGoals) => {
    if(_.includes(_.get(this.props, 'monthlyReviewData.type'), 'MRE'))
      return; // ignore if MRE

    const prevClinicGoals = _.get(this, 'props.currentProgram.clinicGoals') || [];
    const enrolledProgramId = _.get(this, 'props.currentProgram.id');
    if(!enrolledProgramId || !clinicGoals)
      return console.warn('warn MonthlyReviewPlanContainer editClinicGoals: invalid inputs');

    let newClinicGoals = [...clinicGoals];
    _.forEach(prevClinicGoals, cg => {
      const { conditionEnum, condition } = cg;
      const isUnmappedGoal = conditionEnum === CONDITION_ENUM_MAP.UNMAPPED;
      if((conditionEnum && !isUnmappedGoal && !_.find(newClinicGoals, { conditionEnum })) ||
          (condition && !_.find(newClinicGoals, { condition }))
      ) {
        let goal = _.omit(cg, '__typename');
        goal = Object.assign({}, ..._.map(goal, (v, k) => !_.isNil(v) ? { [k]: v } : {}));
        goal = {...goal, ...getGoalStatusValue(goal)};
        newClinicGoals.push(goal);
      }
    });

    newClinicGoals = _.map(newClinicGoals, cg => {
      if(cg.conditionEnum && cg.conditionEnum !== CONDITION_ENUM_MAP.UNMAPPED)
        return _.omit(cg, 'condition');
      return cg;
    });

    return Client.mutate({
      mutation: editClinicGoalsQuery,
      variables: {
        id: enrolledProgramId,
        clinicGoals: newClinicGoals,
      }
    })
    .then(() => this.props.refetchMonthlyReviewContents());
  }

  handleSaveDraft = async () => {
    const { getGoalsFunc, patientGoals } = this.state;
    const { monthlyReviewData, monthlyReviewRefetch, form, setSystemGenerateMonthlyReviewData, setMonthlyReviewData } = this.props;
    const values = form.getFieldsValue();
    const { id, status, createdBy } = monthlyReviewData || {};
    let res = null;
		try {
	  	const goals = typeof getGoalsFunc === 'function' ?
                    getGoalsFunc(patientGoals)
                    : {};
      const { parsedGoals = [], clinicGoals = [] } = goals;
			if (id && checkIfMonthlyReviewInCompleted(status)) {
        res = await this.editMonthlyReview(values.note, parsedGoals, monthlyReviewStatusMap.DRAFT);
        if (!createdBy || !createdBy.id) {
          // update monthlyReviewData for workStation if it's system generated
          const newMonthlyReviewData = _.get(res, 'data.editMonthlyReview');
          if (setSystemGenerateMonthlyReviewData) setSystemGenerateMonthlyReviewData(newMonthlyReviewData);
          if (setMonthlyReviewData) setMonthlyReviewData(newMonthlyReviewData);
        }
			} else {
        res = await this.createMonthlyReview(values.note, parsedGoals, monthlyReviewStatusMap.DRAFT);
      }
      if(res) {
        await this.editClinicGoals(clinicGoals);
      }
      if(monthlyReviewRefetch) return await monthlyReviewRefetch();
    } catch (e) {
      let message = e.message;
      if(_.includes(message, 'Missing goal:')) {
        message = _.replace(e, 'Error: Missing goal:', 'Failed to save draft MTPR:');
        this.props.openErrorModal(message);
      }
      throw new Error(message);
    }
  }

	handleSubmit = async (values) => {
		const { getGoalsFunc, patientGoals } = this.state;
    const { setMonthlyReviewStatus, monthlyReviewData, openErrorModal, setMonthlyReviewData, setSystemGenerateMonthlyReviewData, monthlyReviewRefetch, setHasMTPRDraft } = this.props;

		const { note } = values;
		const id = _.get(monthlyReviewData, 'id');
    const status = _.get(monthlyReviewData, 'status');

		try {
      let newMonthlyReviewData, res;
	  	const goals = typeof getGoalsFunc === 'function' ?
                    getGoalsFunc(patientGoals)
                    : {};
      const { parsedGoals = [], clinicGoals = [] } = goals;
      // SC-8131: upon clicking MTPR generating section, if MTPR is generated from system, edit monthlyreview
      // otherwise, create monthly review
			if (id && checkIfMonthlyReviewInCompleted(status)) {
        res = await this.editMonthlyReview(note, parsedGoals);
        newMonthlyReviewData = _.get(res, 'data.editMonthlyReview');
			} else {
        res = await this.createMonthlyReview(note, parsedGoals)
        newMonthlyReviewData = _.get(res, 'data.createMonthlyReview');
      }
      // cancel save draft, should be before refetch monthlyReviewList
      if (setHasMTPRDraft) setHasMTPRDraft(false);
      if(newMonthlyReviewData && setSystemGenerateMonthlyReviewData){
        setSystemGenerateMonthlyReviewData(newMonthlyReviewData)
      }
      if (monthlyReviewRefetch) {
        await monthlyReviewRefetch();
      }
			if (setMonthlyReviewData && setMonthlyReviewStatus) {
        setMonthlyReviewStatus(monthlyReviewStatusMap.COMPLETED);
      }
			await this.editClinicGoals(clinicGoals);
		} catch (e) {
      let message = e.message;
      if(_.includes(message, 'Missing goal:')) {
        message = _.replace(e, 'Error: Missing goal:', '');
      } else if (new RegExp(/.*(not authorized|not a valid manager).*/).test(_.toLower(message))) {
        message = `Goals are not updated: This patient is currently not in your team, please check patient's team information and try again`;
      } else {
        message = GQLHelper.formatError(e);
      }
      console.error(e);
			openErrorModal(message);
		}
	}

	render() {
        const { initialValues, updates } = this.state;
		const type = _.get(this.props, 'monthlyReviewData.type') || _.get(this.props, 'defaultMonthlyReviewType');
        const { listMonthlySummaryReport,monthlyReviewSummary,refetchMonthlySummaryReport } = this.props;
		const lastMonthlySummaryReport = _.last(listMonthlySummaryReport);
		return <MonthlyReviewBaseComponent
			{...this.props}
			type={type}
			onSubmit={this.handleSubmit}
			setGetGoalsFunc={this.setGetGoalsFunc}
			initialValues={initialValues}
			setUpdatedValues={this.setUpdatedValues}
			updates={updates}
			lastMonthlySummaryReport={lastMonthlySummaryReport}
            monthlyReviewSummary={monthlyReviewSummary}
			refetchMonthlySummaryReport={refetchMonthlySummaryReport}
		/>
	}

}

const getMonthlyReviewContents = graphql(getMonthlyReviewContent, {
  options: (ownProps) => {
    const { query, ...options } = goalAssessmentHelper.getMonthlyReviewContentsQuery(ownProps);
    return {...options};
  },
  props: ({  ownProps, data }) => {
    const { loading, getMonthlyReviewContent, error, refetch } = data;
    const monitoredVitals = _.map(_.get(ownProps, 'currentProgram.tasks'), 'type');
    if(error)
      console.error(error);

    if(loading)
      return { loadingMonthlyReviewContents: loading };

    const prevGoalUpdates = _.keyBy(_.get(ownProps, 'monthlyReviewData.goals'), 'condition');

    let monthlyReviewContents = [];
    let measurementCounts = {};
    let previousVitals = [],
        previousGoals = [];
    const missingGoal = [];
    for(let mrc of getMonthlyReviewContent) {
      const associatedVitals = [];
      const { vitals, goals } = mrc;


      previousVitals.push(...vitals);
       // only 1 bilalble goal per condition
      const previousGoal = _.pick(
        _.first(goals),
        ['condition', 'value', 'conditionEnum', 'goalEnum', 'meetingStatus', 'goalAssessment']
      );
      previousGoals.push(clearTypenameFields(previousGoal));

      const contentCondition = _.get(mrc, 'condition');

      _.forEach(vitals, ({ vital, count }) => {
        if(BILLABLE_VITALS.includes(vital))
          associatedVitals.push(vital);
        measurementCounts[vital] = count;
      });

      if(!_.isEmpty(goals)) {
        monthlyReviewContents = monthlyReviewContents.concat(_.map(goals, g => {
          _.forEach(vitals, (v) => {
            if(!monitoredVitals.includes(v.vital)) {
              missingGoal.push(g.goalEnum);
            }
          })
          const { condition, value, billable, conditionEnum, goalEnum, meetingStatus, goalAssessment } = g;
          const prevGoalUpdate = prevGoalUpdates[conditionEnum || condition] || {};
          const initialGoalValue = prevGoalUpdate.value || goalEnum || value;
          const prevStatus = prevGoalUpdate.status;
          const initialStatusValue =  prevStatus || meetingStatus;
          const isSystemStatus = !prevStatus || (meetingStatus && _.isEqual(meetingStatus, prevStatus));

          return {
            condition: CONDITION_ENUM_MAP[conditionEnum] || condition,
            goalType: conditionEnum || condition,
            initialGoalValue,
            initialStatusValue,
            isSystemStatus,
            associatedVitals,
            billable: billable || true, // 0923, always true
            goalAssessment
          };
        }));
      } else {
        monthlyReviewContents.push({
          condition: CONDITION_ENUM_MAP[contentCondition] || contentCondition,
          goalType: contentCondition,
          associatedVitals,
          billable: true // 0923, always true
        });
      }
    }

    return {
      monthlyReviewContents,
      measurementCounts,
      previousVitals: _.toArray(clearTypenameFields(previousVitals)),
      previousGoals,
      missingGoal,
      refetchMonthlyReviewContents: refetch
    };
  }
});

const getMonthlyReviewSummaryContents = graphql(getMonthlyReviewSummary, {
  options: (ownProps) => {
    const { query, ...options } = goalAssessmentHelper.getMonthlyReviewSummaryQuery(ownProps);
    return {...options}
  },
  props: ({ data }) => {
    const { loading, error, refetch, getMonthlyReviewSummary } = data;
    if(error) {
      console.error(error);
    }

    return {
      monthlyReviewSummary: getMonthlyReviewSummary,
      loadingSummary: loading,
      refetchSummary: refetch
    };
  }
});
const getListMonthlySummaryReport = graphql(listMonthlySummaryReport,{
	options:(ownProps)=>{
		const userId = _.get(ownProps,'params.id');
		const monthOfYear = moment().format('YYYYMM')
		return {
			variables: {
				memberId: userId,
				monthOfYear
			}
		}
	},
	props: ({ data }) => {
		const { loading, error, refetch, listMonthlySummaryReport=[] } = data;
		if(error) {
			console.error(error);
		}

		return {
			listMonthlySummaryReport: listMonthlySummaryReport,
			loadingMonthlySummaryReport: loading,
			refetchMonthlySummaryReport: refetch
		};
	}
})
const mapDispatchToProps = (dispatch) => ({
	openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
})

export default compose(connect(null, mapDispatchToProps), getListMonthlySummaryReport,getMonthlyReviewContents, getMonthlyReviewSummaryContents)(MonthlyReviewPlanContainer);
