import moment from 'moment';
import config from '../../../lib/config';

export const DATA_ANALYTICS_API_SERVER_URL = config.SERVER_URL + '/insights';
const getSessionToken = () => sessionStorage.getItem('authToken') || '';

export const getNewEnrolledPatientsWeekly = ({ 
  organizationId,
  startDate = moment().startOf('week').add(-4, 'weeks'),
  endDate = moment().endOf('week')
}) => {
  const url = `${DATA_ANALYTICS_API_SERVER_URL}/enrolledPatients/${organizationId}/weekly?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  return baseRequest(url);
}

export const getActiveRateWeekly = ({ 
  organizationId,
  startDate = moment().startOf('week').add(-4, 'weeks'),
  endDate = moment().endOf('week')
}) => {
  const url = `${DATA_ANALYTICS_API_SERVER_URL}/activeRate/${organizationId}/weekly?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  return baseRequest(url);
}

export const getActiveRateMonthly = ({ 
  organizationId,
  startDate = moment().startOf('week').add(-4, 'weeks'),
  endDate = moment().endOf('week')
}) => {
  const url = `${DATA_ANALYTICS_API_SERVER_URL}/activeRate/${organizationId}/monthly?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  return baseRequest(url);
}

export const getBillablePatientsMonthly = ({ 
  organizationId,
  startDate = moment().startOf('month').add(-4, 'month'),
  endDate = moment().add(1, 'month').startOf('month')
}) => {
  const url = `${DATA_ANALYTICS_API_SERVER_URL}/billable/${organizationId}/monthly?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  return baseRequest(url);
}

export const getVitalsDistribution = ({ organizationId }) => {
  const url = `${DATA_ANALYTICS_API_SERVER_URL}/vitalsDistribution/${organizationId}`;
  return baseRequest(url);
}

const baseRequest = (url) => {
  return new Promise((resolve, reject) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      const res = JSON.parse(this.responseText || '{}');
      if (+this.readyState === 4) {
        if (+this.status >= 200 && +this.status < 300) {
          return resolve(res);
        } else {
          reject(res);
        }
      }
    };
    xhttp.open('GET', url, true);
    xhttp.setRequestHeader('x-session-token', getSessionToken());
    xhttp.send();
  });
}