import React from 'react';
import {compose, graphql} from "react-apollo/index";
import { getSignedFeatureDownloadQuery,readLatestReleaseQuery } from '../graphql/index';
import  SlidesIconComponent from "../components/SlidesIconComponent";
const SlidesIconContainer = class extends React.Component{
    render() {
        const images = _.get(this,'props.images',[]);
        const latestRelease = process.env.LATEST_RELEASE_NUM || 20210715;
        const lastReleaseRead = _.get(this,'props.lastReleaseRead',latestRelease);
        const loadingUser  = _.get(this,'props.loadingUser');
        const hasLastLogIn = sessionStorage.getItem('lastLogIn');
        const shouldEditLastReadRelease = hasLastLogIn && (lastReleaseRead < latestRelease);
        if(loadingUser) return '';
        return <SlidesIconComponent images={images} showModal={shouldEditLastReadRelease} shouldEditLastReadRelease={shouldEditLastReadRelease} latestRelease={latestRelease} lastReleaseRead={lastReleaseRead}/>
    }
}
const withData = graphql(getSignedFeatureDownloadQuery,{
    props:({data})=>{
        const { loading,getSignedFeatureDownload } = data;
        if( loading ){
            return {
                loadingImgs:loading
            }
        }
        return {
            images: getSignedFeatureDownload
        }
    }
});

const lastReleaseRead =  graphql(readLatestReleaseQuery,{
    options:()=>{
        const currentUserId = sessionStorage.getItem('currentUserId');
        return {
            variables:{
                id:currentUserId
            }
        }
    },
    props:({data})=>{
        const { loading,user } = data;
        if( loading ){
            return {
                loadingUser:loading
            }
        }
        return {
            lastReleaseRead: _.get(user,'lastReleaseRead')
        }
    }
});
export default compose(withData,lastReleaseRead)(SlidesIconContainer);
