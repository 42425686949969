import React from 'react';
import { Tabs } from 'antd';
import { compose, graphql } from 'react-apollo';

import '../style/taskWorkStation.scss';
import proptypes from '../constants/proptypes';
import { IHLoading } from '../../../../../package/IHComponent';
import getTaskAssignmentsByPatientId from '../query/getTaskAssignmentsByPatientId';

import TaskWorkStationComponent from '../components/workstation/TaskWorkStationComponent';

const TaskWorkStationContainer = props => {  
  const { loadingTaskAssignments, taskAssignments = [], containerStyles = {}, ...restProps } = props || {};

  return loadingTaskAssignments ?
    <IHLoading /> :
    (
      <div id='task-assignment-work-station' style={{ ...containerStyles }}>
        {
          taskAssignments.length === 1 ?
          <TaskWorkStationComponent key={taskAssignments[0].id} task={taskAssignments[0]} {...restProps}/>
          :
          <Tabs destroyInactiveTabPane onChange={() => props.setShowUnsavedModal(false)}>
            {
              taskAssignments.map((task, idx) => (
                <Tabs.TabPane key={task.id} tab={`Task ${idx + 1}`}>
                  <TaskWorkStationComponent key={task.id} task={task} {...restProps}/>
                </Tabs.TabPane>
              ))
            }
          </Tabs>
        }
      </div>
    );
};

TaskWorkStationContainer.propTypes = proptypes.TaskWorkStationContainer;
TaskWorkStationContainer.displayName = 'TaskWorkStationContainer';

const getPatientTaskAssignments = graphql(getTaskAssignmentsByPatientId, {
  options: (ownProps) => ({
    variables: {
      memberId: _.get(ownProps, 'member.id'),
      // status: ['OPEN']
    },
    fetchPolicy: 'network-only'
  }),
  props: ({ data }) => {
    const { getTaskAssignmentsByPatientId, loading, error, refetch } = data;
    if(error) 
      console.error(error);

    return {
      loadingTaskAssignments: loading,
      taskAssignments: _.get(getTaskAssignmentsByPatientId, 'taskAssignments') || [],
      refetchTaskAssignments: refetch
    };
  }
});

export default compose(getPatientTaskAssignments)(TaskWorkStationContainer);