import React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import VisitPropTypes from '../constants/propTypes';

import { formatDate, TAB_TIME_FORMAT } from '../helpers/visitDateHelpers';


const VisitTableTitle = props => {
  const { 
    visitWindowTime,
    showResultCount = true,
    resultCount = 0, 
    totalCount = 0, 
    tableSelectedAdvancedFilters,
    setSelectedAdvancedFilters,
    tableAdvancedFilters,
    children
  } = props;

  const hasFilters = !_.isEmpty(tableSelectedAdvancedFilters) && 
                     _.some(tableSelectedAdvancedFilters, filters => !_.isEmpty(filters));

  return <div className='visits-table-title'>
    {
      children ||
      <div className='visits-table-label'>
        {formatDate(visitWindowTime.from, TAB_TIME_FORMAT)}
      </div>
    }
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }}>
      {
        showResultCount && hasFilters &&
        <div className='advanced-filter-results'>
          <span>
            {resultCount} results out of {totalCount}
          </span>
          <a 
            onClick={e => {
              e.preventDefault();
              setSelectedAdvancedFilters({});
            }}
          >
            Clear all filters
          </a>
        </div>
      }
      {tableAdvancedFilters}
    </div>
  </div>
};

VisitTableTitle.propTypes = VisitPropTypes.tabTitle;

const mapDispatchToProps = (dispatch) => ({
  setSelectedAdvancedFilters: advancedFilters => 
                      dispatch(actions.setSelectedAdvancedFilters(advancedFilters))
});

export default connect(null, mapDispatchToProps)(VisitTableTitle);