import React from 'react';
import { Radio, Icon } from 'antd';
import { DEFAULT_CONSENT_TYPE_OPTIONS, getConsentTypeOptions } from '../../constants';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const noteStyles = {
  fontFamily: 'var(--font-family)',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '19px'
};

class ConsentOptionsComponent extends React.Component {
  handleChange = (evt) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(evt.target.value);
    }
  }

  render() {
    const { value, isEnrolled = true } = this.props;

    return (
      <Radio.Group defaultValue={value} onChange={this.handleChange}>
        {
          _.map(getConsentTypeOptions(DEFAULT_CONSENT_TYPE_OPTIONS), opt => (
            <div key={opt.value} style={{ display: 'flex', flexDirection: 'column', marginBottom: 12, width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Radio style={{ height: 30, lineHeight: '30px' }} value={opt.value} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='consent-type-option-label'>{opt.label}</span>
                    <span>{opt.tip}</span>
                  </div>
                </div>
              </div>
              {
                value === opt.value && opt.getNote && 
                <div className='consent-form-option-note-wrapper'>
                  <div style={{ display: 'flex', alignItems: 'baseline', paddingLeft: 16 }}>
                    <div>
                      <Icon type='info-circle' theme='filled' style={{ color: 'rgb(49, 119, 201)', cursor: 'auto' }} />
                    </div>
                    <div className='consent-type-option-note'>
                      <span style={noteStyles}>Note:</span>
                      <div>{opt.getNote(noteStyles, isEnrolled)}</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </Radio.Group>
    );
  }
}

export default (ConsentOptionsComponent);