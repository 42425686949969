import React from 'react';
import { Spin } from 'antd';

import moment from 'moment';
import { Chart } from 'chart.js';
import { getBillablePatientsMonthly } from '../API';
import { tooltipStyle, fontSize } from '../util/chartStyle';
import { omit, map } from 'lodash';

const DATE_FORMAT = 'MMM YYYY';
const CANVAS_ID = 'bar-chart-monthly-billable-patients';

const CONSTANTS = {
  memberId: {
    name: '# of Billable Patients',
    codes: [], 
    color: '#7550ec',
  },
  ccmInitial: {
    name: 'CCM Initial',
    codes: ['G0506'], 
    color: '#b7dded',
  },
  ccmMonthly: {
    name: 'CCM Monthly',
    codes: ['99490', '99487', '99489', 'G2056', 'G2058'], 
    color: '#405c74',
  },
  inHouseA1C: {
    name: 'In House A1C',
    codes: ['83037', '83036'], 
    color: '#e3af70',
  },
  rpmCare: {
    name: 'RPM Care',
    codes: ['99457', '99458'], 
    color: '#cae16a',
  },
  rpmData: {
    name: 'RPM Data',
    codes: ['99454'], 
    color: '#c355b2',
  },
  rpmInitial: {
    name: 'RPM Initial',
    codes: ['99453'], 
    color: '#7598d8',
  },
}

class MonthlyBillablePatients extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: undefined,
      loading: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    this.getData(organizationName, organizationId, startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { organizationName, organizationId, startDate, endDate } = this.props;
    if (prevState.data !== this.state.data) {
      const { values } = this.state.data;
      const data = values || [];
      if (this.chart === null) {
        this.createChart(data);
      } else {
        this.updateData(data);
      }
    }
    if (
      prevProps.organizationName !== organizationName 
      || prevProps.startDate !== startDate 
      || prevProps.endDate !== endDate 
      || prevProps.organizationId !== organizationId
    ) {
      this.getData(organizationName, organizationId, startDate, endDate, true);
    }
  }

  getCodeCount = (codesCountObj, codes = []) => {
    return codes.reduce((result, c) => {
      if (c in codesCountObj) {
        result += codesCountObj[c];
      }
      return result;
    }, 0);
  }

  getCodeData = (rawData) => (codeInfo) => {
    return {
      yAxisID: "bar-stacked",
      label: codeInfo.name,
      data: rawData.map(({ yvalue }) => this.getCodeCount(yvalue.codeCountMap, codeInfo.codes)),
      backgroundColor: codeInfo.color,
    };
  }

  getChartData = (rawData) => {
    const labels = rawData.map(({ xvalue }) => {
      const value = xvalue.slice(0, 4) + '-' + xvalue.slice(4, 6) + '-01';
      return moment(value).format(DATE_FORMAT);
    });
    const getCodeFunc = this.getCodeData(rawData);
    const data =  [
      {
        type: 'line',
        fill: false,
        lineTension: 0,
        pointRadius: 3,
        borderColor: CONSTANTS.memberId.color,
        label: CONSTANTS.memberId.name,
        data: rawData.map(({ yvalue }) => yvalue.distinctMemberCount),
        backgroundColor: CONSTANTS.memberId.color,
      },
      ...map(omit(CONSTANTS, 'memberId'), getCodeFunc),
    ]

    return { labels, data };
  }

  updateData(data) {
    const chartData = this.getChartData(data);
    this.chart.data.labels = chartData.labels;
    this.chart.data.datasets = chartData.data;
    this.chart.update();
  }

  createChart = (data) => {
    const chartData = this.getChartData(data);

    this.chart = new Chart(document.getElementById(CANVAS_ID), {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: chartData.data
      },
      options: {
        legend: { 
          display: true,
          position: 'right',
          align: 'middle',
        },
        title: {
          fontSize,
          display: true,
          text: 'Total Billable Patients'
        },
        tooltips: {
          ...tooltipStyle
        },
        scales: {
          yAxes: [
            {
              id: "bar-stacked",
              stacked: true,
              scaleLabel: {
                fontSize,
                display: true,
                labelString: 'Total Billable Counts'
              },
            },
          ],
          xAxes: [{
            stacked: true,
            scaleLabel: {
              fontSize,
              display: true,
              labelString: 'Month'
            },
            ticks: {
              fontSize,
            }
          }]
        }
      }
    });
  }

  getData = async (organizationName, organizationId, startDate, endDate, isUpdating = false) => {
    this.setState({ loading: isUpdating ? false : true, isUpdating });
    try {
      const data = await getBillablePatientsMonthly({ organizationName, organizationId, startDate, endDate });
      this.setState({ loading: false, data, isUpdating: false });
    } catch (err) {
      this.setState({ loading: false, isUpdating: false });
      this.props.openErrorModal(err.message || 'Something went wrong fetching chart data!')
    }
  }

  render() {
    const { loading, isUpdating } = this.state;

    return (
      <div>
        {loading && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {isUpdating && <Spin size="large" style={{ width: '100%', margin: 20 }}></Spin>}
        {!loading && <canvas id={CANVAS_ID} />}
      </div>
    );
  }
}

export default MonthlyBillablePatients;