import types from '../constants'

export default {
    careYearChange: (sel) => {
        return {
            type : types.CARE_YEAR_CHANGE,
            payload:sel
        }
    }
}
