import I18N from "../../../I18N";
import moment from "moment/moment";
import React from 'react';
import { nationOptions, langOptions, countryOptions, docTypeOptions, HEALTH_CONDITION_DISPLAY,HEALTH_CONDITION_ICDCODE } from 'libModule/constants'
import { formatFieldLabels,renderRadio,renderDropDown }from '../helpers';
import { Input, Radio, DatePicker,InputNumber,CheckboxGroup } from 'antd'
import { dobValidator, emailValidator2, postCodeValidator, blockValidator, floorValidator, unitValidator,
    firstAndLastNameValidator, NRICValidator, patientWeightRegiterValidator, patientHeightInputValidator,
    patientHeightInchesInputValidator, mobileValidator, homePhoneValidator,yearOfDiagnoseValidator } from 'libModule/validator';
import { convertFeetToInches, convertInchesToFeet, getRemainderAfterConvertingToFeet } from 'libModule/utils/convertUnits'
import convertor from "@ihealth/convertor/index";
import { renderCheckBoxGroup,renderRadioButton } from '../helpers'

const checkBoxGroupOptions = {
                        insulinDependent:[
                            { value:true,name: 'Insulin dependent'},
                            { value:false,name: 'Non-insulin dependent'}
                        ],
                        hasHeartAttack:[
                            { value:true,name:'Yes' },
                            { value:false,name:'No'}
                        ],
                        BGVisits:[
                            { value:true,name:'Yes' },
                            { value:false,name:'No'}
                        ],
                        BGMedicine:[
                            { value:true,name:'Yes' },
                            { value:false,name:'No'}
                        ],
                        BGTest:[
                            { value:true,name:'Yes' },
                            { value:false,name:'No'}
                        ],
                        BPTest:[
                            { value:true,name:'Yes' },
                            { value:false,name:'No'}
                        ],
                        BGTreatments:[
                            { value: "No Treatment", label: "No Treatment" },
                            { value: "Diet", label: "Diet" },
                            { value: "Exercise", label: "Exercise" },
                            { value: "Oral Medication", label: "Oral Medication" },
                            { value: "Insulin Medication", label: "Insulin medication" },
                            { value: "Herbal Medication", label: "Herbal Medication" }
                        ],
                        stroke:[
                            { value: "Hemorrhage", label: "Hemorrhage" },
                            { value: "Cerebral infarction", label: "Cerebral Infarction" },
                            { value: "Heart Failure", label: "Heart Failure" },
                            { value: "Interventional Surgery", label: "Interventional Surgery" },
                            { value: 'None of the above', label: "None of the above" }
                        ],
                        cardiovascularDisease:[
                            { value: 'Angina Pectoris', label: 'Angina Pectoris' },
                            { value: 'Myocardial Infarction', label: 'Myocardial infarction' },
                            { value: 'Heart Failure', label: 'Heart Failure' },
                            { value: 'Interventional Surgery', label: 'Interventional Surgery' },
                            { value: 'None of the above', label: 'None of the above' },
                        ],
                        retinopathy:[
                            { value: 'Small amount of bleeding', label: 'Small amount of bleeding' },
                            { value: 'Massive bleeding', label: 'Massive bleeding' },
                            { value: 'Retinal detachment', label: 'Retinal detachment' },
                            { value: 'Blindness', label: 'Blindness' },
                            { value: 'None of the above', label: 'None of the above' }
                        ],
                        kidneyDisease:[
                            { value:'Stage 1' ,label:'Stage 1'},
                            { value:'Stage 2' ,label:'Stage 2'},
                            { value:'Stage 3' ,label:'Stage 3'},
                            { value:'Stage 4' ,label:'Stage 4'},
                        ],
                        liverDisease:[
                            { value: 'Hepatitis B', label: 'Hepatitis B' },
                            { value: 'Hepatitis C', label: 'Hepatitis C' },
                            { value: 'Fatty liver', label: 'Fatty liver' },
                            { value: 'Alcoholic liver', label: 'Alcoholic liver' },
                            { value: 'Cirrhosis', label: ' Cirrhosis' }
                        ],
                        other:[
                            { value: 'Diabetic foot', label: 'Diabetic foot' },
                            { value: 'Neuropathy', label: 'Neuropathy' },
                            { value: 'Sex dysfunction', label: 'Sex dysfunction' },
                            { value: 'None of the above', label: 'None of the above' } ]

}

const getDiagnoseDate = (val,type)=>{
    const filteredRes  = _.filter(val,(k)=>k.condition==type);
    const diagnosedDate  = filteredRes.length > 0 ? filteredRes[0].diagnosedDate : null;
    return  diagnosedDate;
}
const placeHolderStyle={
    borderTop: 'solid 1px #d9d9d9 ',
    paddingTop: 20,
    paddingBottom: 10,
    marginTop: 20
}
const medicalHistoryConfig = [
                [
                    {
                        key:'height',
                        label: formatFieldLabels('height', 'height'),
                        path:'profile.height.value',
                        labelCol: { span: 24 },
                        wrapperCol: { span: 24 },
                        colStyle:{ marginRight:5 },
                        span:2,
                        rules: [ {validator: (rule, value, callback) => callback(patientHeightInputValidator(value,'height'))}],
                        inputContent:({disabled})=><InputNumber disabled={disabled} />,
                        formatValue:(height,format)=> {
                            const heightInInches = convertor.height(height, 'cm', 'inch', 0);
                            return heightInInches ? convertInchesToFeet(heightInInches,'cm','inch',0) : '';
                        }
                    },
                    {
                        key: 'height_inches',
                        label: formatFieldLabels('height_inches','height_inches'),
                        labelCol: { span: 24},
                        wrapperCol: { span: 16 },
                        path:'profile.height.value',
                        span:3,
                        rules : [ {validator: (rule, value, callback) => callback(patientHeightInchesInputValidator(value, 'height'))}],
                        inputContent:({disabled})=><InputNumber style={{marginTop:5}} />,
                        formatValue:(height)=> {
                            const heightInInches = convertor.height(height, 'cm', 'inch', 0);

                            return height ? getRemainderAfterConvertingToFeet(heightInInches) :''
                    }
                    },
                    {
                        key: 'weight',
                        label: formatFieldLabels('weight','weight'),
                        labelCol: { span: 24 },
                        wrapperCol: { span: 3 },
                        path:'profile.weight.value',
                        span:18,
                        rowStyle:{ marginLeft:10 },
                        rules : [ {validator: (rule, value, callback) => callback(patientWeightRegiterValidator(value, 'weight'))}],
                        inputContent:({disabled})=><InputNumber disabled={disabled} />,
                        formatValue:(weight)=> weight ? convertor.HS(weight, 'kg', 'lbs', 0) : ''
                    }
                ],
                [
                    {
                        key:'cardiovascularDisease',
                        label: formatFieldLabels('cardiovascularDisease', 'cardiovascularDisease'),
                        span:24,
                        path:'profile.cardiovascularDisease',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['cardiovascularDisease'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [
                    {
                        key:'kidneyDisease',
                        label: formatFieldLabels('kidneyDisease', 'kidneyDisease'),
                        span:24,
                        path:'profile.kidneyDisease',
                        inputContent:(props)=>renderRadio(checkBoxGroupOptions['kidneyDisease'],props),
                    }
                ],
                [
                    {
                        key:'liverDisease',
                        label: formatFieldLabels('liverDisease', 'liverDisease'),
                        span:24,
                        path:'profile.liverDisease',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['liverDisease'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [
                    {
                        key:'stroke',
                        label: formatFieldLabels('stroke', 'stroke'),
                        span:24,
                        path:'profile.stroke',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['stroke'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [
                    {
                        key:'retinopathy',
                        label: formatFieldLabels('retinopathy', 'retinopathy'),
                        span:24,
                        path:'profile.retinopathy',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['retinopathy'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [
                    {
                        key: 'heartAttack.hasHeartAttack',
                        label: formatFieldLabels('heartAttack.hasHeartAttack','heartAttack.hasHeartAttack'),
                        labelCol: { span: 24 },
                        wrapperCol: { span: 24 },
                        path:'profile.heartAttack.hasHeartAttack',
                        span:6,
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['hasHeartAttack'],props)
                    },
                    {
                        key: 'heartAttack.year',
                        label: formatFieldLabels('heartAttack.year','heartAttack.year'),
                        labelCol: { span: 24 },
                        wrapperCol: { span: 5 },
                        path:'profile.heartAttack.year',
                        span:18,
                        inputContent:({form})=><Input  />,
                    }
                ],
                [
                    {
                        span:24,
                        placeHolder:<div style={placeHolderStyle}><h4>Diabetes:</h4></div>
                    }
                ],
                [
                    {
                        key: 'BGYearOfDiagnose',
                        label: formatFieldLabels('BGYearOfDiagnose','BGYearOfDiagnose'),
                        labelCol: { span: 24 },
                        wrapperCol: { span: 6 },
                        path:'profile.yearOfDiagnose',
                        span:12,
                        inputContent:({disabled})=><Input disabled={disabled} />,
                        formatValue:(data)=> getDiagnoseDate(data,'DIABETES'),
                        // rules:[{validator: (rule, value, callback) => callback(yearOfDiagnoseValidator(value))}]
                    }
                ],
                [
                    {
                        key: 'insulinDependent',
                        label: formatFieldLabels('insulinDependent', 'insulinDependent'),
                        type: 'radioButton',
                        labelCol: { span: 8 },
                        wrapperCol: { span: 24 },
                        span:24,
                        path:'profile.insulinDependent',
                        className: 'radio-button-2-option',
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['insulinDependent'],props)
                    }
                ],
                [
                    {
                        key:'BGTreatments',
                        label: formatFieldLabels('BGTreatments', 'BGTreatments'),
                        span:24,
                        path:'profile.BGTreatments',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['BGTreatments'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [   {
                        key:'BGOther',
                        label: formatFieldLabels('other', 'other'),
                        span:24,
                        path:'profile.BGOther',
                        inputContent:(props)=>renderCheckBoxGroup(checkBoxGroupOptions['other'],props),
                        formatValue:(data)=> Array.isArray(data)?data:[]
                    }
                ],
                [
                    {
                        key:'BGVisits',
                        label:formatFieldLabels('BGVisits','BGVisits'),
                        span:12,
                        path:'profile.BGVisits',
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['BGVisits'],props)
                    },
                    {
                        key:'BGMedicine',
                        label:formatFieldLabels('BGMedicine','BGMedicine'),
                        span:12,
                        path:'profile.BGMedicine',
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['BGMedicine'],props)
                    }
                ],
                [
                    {
                        key:'BGTest.testRegularly',
                        label:formatFieldLabels('BGTest.testRegularly','BGTest.testRegularly'),
                        span:9,
                        path:'profile.BGTest.testRegularly',
                        onChange:(value,localThis)=> localThis.setState({disableBGFreq:value}),
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['BGTest'],props)
                    },
                    {
                        key:'BGTest.freq',
                        label:formatFieldLabels('BGTest.freq','BGTest.freq'),
                        labelCol:{ span:24 },
                        wrapperCol:{ span:10 },
                        span:12,
                        path:'profile.BGTest.freq',
                        inputContent:({localThis,disabled})=> <InputNumber disabled={disabled||!localThis.state.disableBGFreq}/>
                    },
                ],
                [
                    {
                        span:24,
                        placeHolder:<div style={placeHolderStyle}><h4>Hypertension:</h4></div>
                    },
                ],
                [
                    {
                        key: 'BPYearOfDiagnose',
                        label: formatFieldLabels('BPYearOfDiagnose','BPYearOfDiagnose'),
                        labelCol: { span: 24 },
                        wrapperCol: { span: 3 },
                        path:'profile.yearOfDiagnose',
                        span:24,
                        inputContent:({disabled})=><Input disabled={disabled} />,
                        formatValue:(data)=> getDiagnoseDate(data,'HYPERTENSION'),
                        // rules:[{validator: (rule, value, callback) => callback(yearOfDiagnoseValidator(value))}]
                    }
                ],
                [
                    {
                        key:'BPTest.testRegularly',
                        label:formatFieldLabels(`BPTest.testRegularly`,'BPTest.testRegularly'),
                        span:9,
                        path:'profile.BPTest.testRegularly',
                        onChange:(value,localThis)=>localThis.setState({disableBPFreq:value}),
                        inputContent:(props)=>renderRadioButton(checkBoxGroupOptions['BPTest'],props)
                    },
                    {
                        key:'BPTest.freq',
                        label:formatFieldLabels('BPTest.freq','BPTest.freq'),
                        labelCol:{ span:24 },
                        wrapperCol:{ span:10 },
                        span:12,
                        path:'profile.BPTest.freq',
                        inputContent:({localThis,disabled})=> <InputNumber disabled={disabled||!localThis.state.disableBPFreq}/>
                    }
                ],
                // [
                //     {
                //         key:'healthConditions',
                //         path:'profile.healthConditions',
                //         span:12,
                //         inputContent:(props)=><Input style={{display:'none'}} className='ant-col-12'/>,
                //     },
                //     {
                //         key:'billableHealthConditions',
                //         path:'profile.billableHealthConditions',
                //         span:12,
                //         inputContent:(props)=><Input style={{display:'none'}} className='ant-col-12'/>,

                //     }
                // ]
               ]
export {
    medicalHistoryConfig
}
