import gql from 'graphql-tag'

export default gql`
query getDeviceHub($serialNumber: String, $memberId: EID, $id: EID) {
  getDeviceHub(serialNumber: $serialNumber, memberId: $memberId, id: $id){
    serialNumber
    member {
      id
      profile {
        firstName
        lastName
      }
    }
    lastHeartbeat
    devices {
      id
      type
      unit
    }
  }
}`
