import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Spin } from 'antd';
import { openErrorModal } from 'layoutModule/actions/MainModal';
import { GQLHelper } from 'libModule/utils';
import Client from 'libModule/gqlClient';
import loginWithToken from 'modulesAll/graphql/loginWithToken';
import userQuery from 'modulesAll/graphql/user';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import UserClass from 'modulesAll/graphql/class/User';
import _ from 'lodash';
import './style.scss';
import { ROLE_MAP } from '../../lib/constants';

const AthenaHealthLogin = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { token, athenaPatientId, patientIdentification } = _.get(props, 'location.query') || {};

  const login = async () => {
    if (!token) return;
    setLoading(true);
    try {
      const res = await Client.mutate({
        mutation: loginWithToken,
        variables: {
          token,
        },
      });
      const { sessionToken, id, role, appSettings, allRoles, lastLogIn, orgPermissions, globalConfigs, userSettings, username } = res.data.loginWithToken || {};
      if (sessionToken) {
        Mixpanel.identify(id);
        Mixpanel.people.set({
          "$organization": role.organization.name,
          "$category": role.category,
          "$role": role.name,
          "$name" : username
        });
        await UserClass.saveUserInfo({ sessionToken, id, role, appSettings, allRoles, lastLogIn, orgPermissions, globalConfigs, userSettings });
        sessionStorage.setItem('currentUserId', id);
      }
    } catch (err) {
      setLoading(false);
      props.openErrorModal(GQLHelper.formatError(err));
    }
  }

  const wait = interval => new Promise(resolve => setTimeout(resolve, interval));
  const retryPromise = async (fn, retriesLeft = 3, interval = 200) => {
    try {
      return await fn;
    } catch (error) {
      await wait(interval);
      if (retriesLeft === 0) {
        throw new Error(error);
      }
      // console.log('retriesLeft: ', retriesLeft);
      return await retryPromise(fn, --retriesLeft, interval);
    };
  }

  const getPatient = () => {
    return new Promise((resolve, reject) => {
      Client.query({
        query: userQuery,
        variables: {
          identification: patientIdentification
        },
      }).then(res => {
        const user = _.get(res, 'data.user');
        if (user === null) {
          return reject('Could NOT find the user!!');
        } else {
          return resolve(user);
        }
      });
    })
  }
  
  useEffect(() => {
    const loginAndCreatePatient = async () => {
      try {
        await login();
        if (!athenaPatientId && !patientIdentification) {
          const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
          const currentRole = _.get(currentUser, 'selectedRole.name');
          const selectedRole = _.get(ROLE_MAP, currentRole);
          if (_.includes(['MD'], selectedRole)) {
            browserHistory.push('/provideralert');
          } else {
            browserHistory.push('/');
          }
          return;
        }
        const patient = await retryPromise(getPatient(), 5, 5000);
        browserHistory.push(`/workstation/${patient.id}/single`);
      } catch (e) {
        console.error(e);
        setError('' + e.message);
      }
    }
    loginAndCreatePatient();
  }, [token, athenaPatientId, patientIdentification, setError]);

  if (error) {
    return (
      <div className="athena-login-container">
        <div style={{ fontSize: '1.5em' }}>
          <p>Something went wrong! Please try again!</p>
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="athena-login-container">
      <div>
        <img class="login-sharecare-logo" src="/logos/Logomark_RGB.svg"></img>
        <div class="login-title-container">Unified Care</div>
        {loading && <div className="flex-center"><Spin size="large" /></div>}
      </div>
    </div>
  );
}

const mapDispatch = (dispatch) => ({
  openErrorModal: (errorMessage) => dispatch(openErrorModal(errorMessage))
})
export default connect(null, mapDispatch)(AthenaHealthLogin);
