import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, message } from 'antd';
import { graphql, compose } from 'react-apollo';
import getClinicGoalTemplates from '../../graphql/getClinicGoalTemplates';
import goalAssessmentHelper from '../helper/goalAssessmentHelper';
import DraggablePopup from '../component/DraggablePopup';
import GoalAssessmentComponent from '../component/GoalAssessmentComponent';
import GoalAssessmentPopup from '../component/GoalAssessmentPopup';
import GoalAssessmentRecommendedAction from '../component/GoalAssessmentRecommendedAction';
import Mixpanel from 'modulesAll/mixPanel/mixPanel';
import { VITAL_DELIMITER } from '../constant/monthlyReview';

import I18N from 'modulesAll/I18N';
const goalI18N = I18N.get('workStation.goalAssessment');

import '../style/goalAssessment.styles.scss';

import { conditionToType, CLINIC_GOAL_ENUM } from '../../careplanNew/component/helper/goalHelper';
import { options } from 'less';

const DEFAULT_POPUP_TOP = 150;
const DEFAULT_POPUP_LEFT = (window.innerWidth / 2) - 300;

const GoalAssessmentContainer = props => {
  const { 
    loadingGoalOptions, loadingMonthlyReviewContents, setGetGoalsFunc, 
    goalOptions, goalCodes, monthlyReviewContents, type: monthlyReviewType = '',
    monthlyReviewSummary, ...restProps 
  } = props;

  const isLoading = loadingGoalOptions || loadingMonthlyReviewContents;

  if(isLoading)
    return null;

  const [patientGoals, setPatientGoals] = useState([]);
  const [draggablePopups, setDraggablePopups] = useState([]);

  const isMRE = _.includes(monthlyReviewType, 'MRE');
  const availableGoals = useMemo(() => 
                                    _.filter(patientGoals, p => p.goalType && p.associatedVitals && p.associatedVitals.length)
                                    ,[patientGoals]);

  useEffect(() => {
    if (setGetGoalsFunc)
      setGetGoalsFunc(parseData, patientGoals);
  }, [patientGoals]);

  useEffect(() => {
    if(!isLoading && monthlyReviewContents) {
      const newPatientGoals = [...monthlyReviewContents];
      // currently there is no manual goal => no remove function
      setPatientGoals(newPatientGoals);
    }
  }, [isLoading, monthlyReviewContents]);

  const parseData = (patientGoals) => {
    const fieldValues = restProps.form.getFieldsValue();
    if(_.isEmpty(fieldValues))
      return console.warn('warn parsePatientGoals: fieldValues is invalid');

    const parsedGoals = [];
    const clinicGoals = [];
    const now = new Date().getTime();
    const currentUserId = sessionStorage.getItem('currentUserId');
    for(let pg of patientGoals){
      const { condition, goalType, billable, toRemove, initialGoalValue, initialStatusValue, associatedVitals } = pg;
      const conditionEnum = conditionToType[condition];
      const hasCondition = !!(conditionEnum || condition);
      const goalValue = _.get(fieldValues, `${condition}-value`) || initialGoalValue;
      const isGoalValueEnum = !!CLINIC_GOAL_ENUM[goalValue];
      const status = _.get(fieldValues, `${condition}-status`) || initialStatusValue;

      if(toRemove) {
        // dont add goal to saving array => to remove
      } else if (!isMRE && hasCondition && !goalValue && billable) { // MTPR only
        const billableCodes = _.map(_.get(restProps, 'user.profile.billableHealthConditions'), 'code');
        const existingICDCodes = _.intersection(goalCodes[goalType], billableCodes);
        throw new Error('Missing goal: ' + goalI18N.warning.conditionHasNoValue.replace('XXX', _.join(existingICDCodes) || '--'));
      } else if (hasCondition) {
        let goal = {};
        if(conditionEnum && isGoalValueEnum){
          goal = { conditionEnum, condition, goalEnum: goalValue };
        } else {
          goal = { conditionEnum, condition, value: goalValue };
        }

        if(status) {
          goal = {
            ...goal,
            meetingStatus: status,
            statusUpdatedAt: now,
            statusUpdatedBy: currentUserId
          };
        }

        clinicGoals.push(goal);
      }

      if(!hasCondition || toRemove)
        continue;

      parsedGoals.push({ 
        condition: conditionEnum || condition, 
        value: goalValue, 
        status, 
        vital: _.join(associatedVitals, VITAL_DELIMITER) 
      });
    }
    
    return { parsedGoals, clinicGoals };
  };

  const handleRemoveManualGoal = (goalType) => {
    const conditionIdx = _.findIndex(patientGoals, { goalType });
    if(conditionIdx < 0)
      return console.error('Failed to remove manual goal. Out of bound');
    
    const newPatientGoals = [...patientGoals];
    newPatientGoals[conditionIdx].toRemove = true;
    setPatientGoals(newPatientGoals);
  };

  const genDraggablePopup = (key, children, containerStyles) => {
    const popupCount = draggablePopups.length;
    return {
      key,
      isVisible: false,
      render: (draggablePopups, isVisible, newChildren) => (
        <DraggablePopup 
          key={key}
          isVisible={isVisible} 
          onClose={() => handleClosePopup(draggablePopups, key)}
          containerStyles={{
            top: DEFAULT_POPUP_TOP + (30 * popupCount), // translate down
            left: DEFAULT_POPUP_LEFT + (30 * popupCount), // translate right
            ...containerStyles
          }}
        >
          {newChildren || children}
        </DraggablePopup>
      )
    };
  };
  
  const handleOpenPopup = (popup) => {
    let newDraggablePopups = [...draggablePopups];
    let popupIdx = _.findIndex(draggablePopups, { key: popup.key }),
        isVisible = true;
    const curPopup = newDraggablePopups[popupIdx];
    if(curPopup && popupIdx !== newDraggablePopups.length -1) {
      // popup exists, bring it to front (by moving to end of array) and make it visible
      newDraggablePopups.splice(popupIdx, 1);
      newDraggablePopups.push(popup);
      popupIdx = newDraggablePopups.length -1;
    } else if(curPopup) {
      // popup exists, visible and in the front
      // replace curPopup to get new updates
      newDraggablePopups.splice(popupIdx, 1, popup);
    } else {
      // popup doesn't exist
      newDraggablePopups.push(popup);
      popupIdx = newDraggablePopups.length - 1;
    }
    newDraggablePopups[popupIdx].isVisible = isVisible;
    setDraggablePopups(newDraggablePopups);
  };
  
  const handleClosePopup = (draggablePopups, key) => {
    const newDraggablePopups = [...draggablePopups];
    const popupIdx = _.findIndex(draggablePopups, { key });
    newDraggablePopups[popupIdx].isVisible = false;
    setDraggablePopups(newDraggablePopups);
  };

  const renderVitalsSummaryPopup = (goalType, locateAssessment = false) => {
    if(!goalType) {
      message.warn('Goal category is not available');
      return console.warn('warn renderVitalsSummaryPopup: invalid goal type');
    }

    const popupProps = {
      availableGoals,
      preSelectedTab: goalType,
      isMRE,
      locateAssessment,
      monthlyReviewSummary
    };

    const children = <GoalAssessmentPopup
      key='vitalsSummary'
      {...popupProps}
    />;

    let popup = _.find(draggablePopups, { key: 'vitalsSummary' });
    if(popup) {
      popup.render = _.partialRight(popup.render, children);
    } else {
      popup = genDraggablePopup('vitalsSummary', 
        children
      ,{ height: 600, minWidth: 600, minHeight: 260 });
    }

    handleOpenPopup(popup, popup);
  };

  return (
    <div id='goal-assessment-container'>
      {
        patientGoals.map((goal, idx) => {
          const { 
            condition,
            goalType,
            billable,
            associatedVitals, 
            goalAssessment,
            toRemove = false,
          } = goal;
          const hasAssociatedVitals = associatedVitals && !!associatedVitals.length;
          return !toRemove && billable && (
            <React.Fragment key={`${condition}-${idx}`}>
              <Divider style={{ margin: '18.5px 0px 17.5px 0px' }} />
              <GoalAssessmentComponent
                {...restProps} 
                {...goal}
                isMRE={isMRE}
                associatedVitals={associatedVitals || []}
                hasAssociatedVitals={hasAssociatedVitals}
                goalOptions={goalOptions[goalType] || []}
                renderVitalsSummaryPopup={(locateAssessment) => renderVitalsSummaryPopup(goalType, locateAssessment)}
                handleRemoveManualGoal={() => handleRemoveManualGoal(goalType)}
              />
            </React.Fragment>
          )
        })
      }
      <Button 
        className="recommended-actions-button"
        style={{ alignSelf: 'center', marginTop: 28, marginBottom: 40, padding: '5px 16px', borderColor: 'var(--dark-blue)', color: 'var(--dark-blue)' }}
        onClick={() => {
          Mixpanel.track('clicked', 'recommended_action', 'goal_assessment');
          const popup = genDraggablePopup('recommendedAction', (
            <GoalAssessmentRecommendedAction isMRE={isMRE} memberId={_.get(props, 'user.id')} />
          ),{ height: !isMRE ? 600 : 260, minWidth: 600, minHeight: 260 });
          handleOpenPopup(popup);
        }}
      >
        See recommended action
      </Button>
      {draggablePopups.map(dPu => dPu.render(draggablePopups, dPu.isVisible))}
    </div>
  );
};

const getGoalOptions = graphql(getClinicGoalTemplates, {
  options:(ownProps) => {
    const { query, ...options } = goalAssessmentHelper.getGoalOptionsQuery(ownProps);
    return {...options};
  },
  props: ({  data }) => {
    const { loading, getClinicGoalTemplates, error, refetch } = data;
    if(error)
      console.error(error);

    const goalOptions = {};
    const goalCodes = {};
    _.forEach(getClinicGoalTemplates, template => {
      const { condition, goals, codes } = template;
      goalOptions[condition] = [...goals];
      goalCodes[condition] = [...codes];
    });

    return {
      loadingGoalOptions: loading,
      goalOptions,
      goalCodes,
      refetchBillable: refetch,
    };
  }
});

GoalAssessmentContainer.propTypes = {
  form: PropTypes.object,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      billableHealthConditions: PropTypes.array
    }).isRequired
  }).isRequired,
  monthlyReviewContents: PropTypes.array,
  type: PropTypes.string // monthlyReviewType
};

export default compose(getGoalOptions)(GoalAssessmentContainer);