import moment from "moment/moment";
import {Avatar, Tag, Tooltip, Icon, Divider} from "antd";
import React from "react";
import constant from '../constant/index';
import I18N from 'modulesAll/I18N';

const ALERT_DESCRIPTIONS = I18N.get('smartAlert.alertDescriptions');
const ALERT_ACTIONS = I18N.get('smartAlert.hoverMessage');

const map = {
    LEVEL_ONE_BP_HTN_CRITICAL_HIGH:'Pt is symptomatic per Triage, AND SBP≥180 or DBP≥120',
    LEVEL_ONE_BP_HTN_CRITICAL_LOW:'Pt is symptomatic per Triage, AND - DBP<50, OR - SBP < 90, OR - 50<DBP<60, AND given the DBP today is more than 5+ mmHg below last 7-day’s average',
    LEVEL_TWO_BP_HTN_CRITICAL_HIGH:'BP reading is SBP≥180 or DBP≥120 and patient has no symptoms per triage, AND,7-day average is SBP≥180 or DBP≥120, AND,No HTN Critical High-Asymptomatic alert was triggered in the last 7 days',
    LEVEL_TWO_BP_HTN_CRITICAL_LOW:"Patient has no symptoms per triag AND,DBP<50, OR ,SBP < 90, OR,50<DBP<60, AND given the DBP today is more than 5+ mmHg below last 7-day’s average,AND,Patient rechecked BP in 10 minutes and second BP is still critical low (meeting logics above for critical low), OR patient doesn’t check the second reading,AND,No HTN Critical Low-Asymptomatic alert was triggered in the last 14 days",
    LEVEL_THREE_BP_HTN_STAGE_2_HIGH:"SBP≥150 AND DBP≥90, AND,7-day average (including this reading) is also in SBP ≥150 AND DBP≥90 range，AND,No “HTN Stage 2 High” or “HTN Stage 2 Average” has been triggered in the past 7 days",
    LEVEL_TWO_BG_FASTING_HIGH:"FBG today is >180 mg/dL, AND,7-day average (including this reading) is in FBG>180 mg/dL range, AND,No “Fasting Glucose High” alert generated in the past 7 days",
    LEVEL_THREE_BP_HTN_STAGE_2_AVERAGE:"Patient has a SBP≥140 OR DBP≥90 reading, AND,30-day average (including this reading) is in the SBP≥140 OR DBP≥90 range, AND,No “HTN Stage 2 Average” alert was generated or closed in the past 30 days, AND,No “HTN Stage 2 High” alert was generated or closed in the past 7 days"
}
const parseVisitWindow = (visitWindow)=>{
    if(!visitWindow||!_.includes(visitWindow,' - ')){
        return '';
    }
    const windowArr = visitWindow.split(' - ');
    const windowStr = `Upcoming test time window: `+moment(windowArr[0]).format('MMM/DD') +' - '+moment(windowArr[1]).format('MMM/DD');

    return windowStr;
}
const renderAlertReason = (alert)=>{
    const reasons = (alert || {}).reason || [];
    const reason = _.join(reasons, '. ') || '';
    if(!reason) return '';
    const isOverflowing = reason.length > 80;
    const slicedStr = isOverflowing ? reason.substring(0,81) : reason;
    return <div>{slicedStr}
        { isOverflowing && <Tooltip title={reason} overlayClassName={'alertReasonTooltip'}>
            <span style={{textDecoration: 'underline',marginLeft:5}}>View All</span>
        </Tooltip> }
    </div>
}

const renderSmartAlertInfo = (alert) => {
  let alertDescription = _.get(ALERT_DESCRIPTIONS, alert.taskType);
  const alertVisitWindow = parseVisitWindow(alert.visitWindow);
  const alertAction = _.get(ALERT_ACTIONS, alert.taskType);
  let isLongTip = false;

  if(alertDescription && alertVisitWindow) {
    alertDescription = alertDescription.concat('<divider/>').concat(alertVisitWindow);
    isLongTip = true;
  }

  if(alertDescription && alertAction) {
    alertDescription = alertDescription.concat('<divider/>').concat(alertAction);
    isLongTip = true;
  }

  if(isLongTip) {
    const contents = alertDescription.split('<divider/>');
    alertDescription = contents.map((content, idx) => (
      <React.Fragment>
        <span style={{ whiteSpace: 'pre-line' }}>{content}</span>
        { idx < contents.length - 1 && <Divider /> }
      </React.Fragment>
    ));
  }

  return alertDescription ?
  (
    <Tooltip
      overlayClassName={`alert-description-tooltip ${isLongTip ? 'long-tip' : ''}`}
      title={<div style={{ whiteSpace: 'pre-line' }}>{alertDescription}</div>}
      // destroyTooltipOnHide
    >
      <Icon type='info-circle' style={{ marginLeft: 5 }}/>
    </Tooltip>
  ) : '';
};
const parseSmartAlertName = (display) =>{
    return typeof display=='string' ? display.replaceAll('>=','≥').replaceAll('<=','≤'): display;
}
const renderSmartAlert = (alert)=>{
    const style ={
        tagStyle:{
            display: 'flex',
            height: 28,
            alignItems: 'center',
            border: '1px',
            borderRadius: 15,
            marginBottom: 15,
            marginTop:15,
            width:'fit-content',
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    };
    const {color, backgroundColor} = constant.alertLevelColorMap[alert.alertLevel] || {};
    
    return <Tag style={{color: '#2D3238', backgroundColor: backgroundColor,...style.tagStyle}}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <div style={{ width: 8, height: 8, borderRadius: 6, backgroundColor: color, display: 'inline-block', marginRight: 5 }} />
            {parseSmartAlertName(alert.display)}
            {renderSmartAlertInfo(alert)}
        </div>
    </Tag>
}
const renderAvatar = (avatar)=>{
    const avatarLink = !_.isNil(avatar) ? avatar.link : '/image/default_avator.png';
    return <Avatar
        icon='user'
        src= {avatarLink}
    />
}

// [0216] deprecated  level indicator in taskType is not accurate
// const extractAlertLevel = taskType => {
//   if(typeof taskType !== 'string')
//     return undefined;
//   let alertLevel = taskType.toUpperCase().match(/LEVEL_(?:ONE|TWO|THREE|FOUR|FIVE)/g);
//   return _.get(alertLevel, '0');
// }

const snoozeToAlert = (alert)=>{
    const { alertDisplay, display, alertLevel } = alert || {};
    const level = alertLevel || 'LEVEL_FIVE'; // default as BE logic
    // || extractAlertLevel(taskType);
    let newAlert = Object.assign({...alert},{ 
      display: alertDisplay || display,
      alertLevel : level,
    });
    return newAlert;
}
export default {
    parseVisitWindow,
    renderSmartAlert,
    renderAlertReason,
    renderAvatar,
    snoozeToAlert,
    renderSmartAlertInfo
}
