import types from '../constants/QuestionConstants';

export const changeField = (fieldPath, value) => (dispatch, getState) => {
  dispatch({
    type: types.FIELD_CHANGE,
    fieldPath,
    value,
  });
};

export const cancelAssessment = (fieldPath, value) => (dispatch, getState) => {
  dispatch({
    type: types.CANCEL_ASSESSMENT,
    fieldPath,
    value,
  });
};

export const addQuestion = () => (dispatch, getState) => {
  dispatch({
    type: types.NEW_QUESTION,
  });
};

export const removeQuestion = index => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_QUESTION,
    index,
  });
};

export const changeType = (index, questionType) => (dispatch, getState) => {
  dispatch({
    type: types.CHANGE_TYPE,
    index,
    questionType,
  });
};

export const saveQuestion = index => (dispatch, getState) => {
  dispatch({
    type: types.SAVE_QUESTION,
    index,
  });
};

export const loadAssessment = assessment => (dispatch, getState) => {
  dispatch({
    type: types.LOAD_ASSESSMENT,
    assessment,
  });
};

export const saveAssessment = () => (dispatch, getState) => {
  dispatch({
    type: types.SAVE_ASSESSMENT,
  });
};
