import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { graphql, compose } from 'react-apollo'
import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import moment from 'moment-timezone';
import { IHLoading } from 'ihcomponent';
import  Mixpanel  from 'modulesAll/mixPanel/mixPanel';
import { RESULT_COUNT_MAX, CHART_SEVERITY } from 'libModule/constants'

const BPLineChartComponent = class extends React.Component {
    groupData = (array,{ valuePath,color,pairedValue })=>{
        array.sort((a1,a2)=>a1.date-a2.date);
        const data  = [];
        array.forEach(v=>{
            const { date,severity }  = v;
            const value = _.get(v,valuePath,0);
            const diastolic_blood_pressure = _.get(v,'diastolic_blood_pressure.value');
            const systolic_blood_pressure = _.get(v,'systolic_blood_pressure.value');
            const heart_rate = _.get(v,'heart_rate.value');
            const hasMarker = _.includes(['CRITICAL','RISK'],severity);
            const marker = hasMarker ? {
                enabled:true,
                symbol:'circle',
                halo:null,
                states: {
                    hover: {
                        lineWidth: 0,
                        halo: null,
                        animation:{
                            duration: 20
                        }
                    }
                },
                radius:4,
                fillColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? '#FDBE43' :''),
                lineColor: severity=='CRITICAL' ? '#EE6161' : (severity=='RISK' ? 'FDBE43' :''),

            } : null
            data.push({
                x:moment(date).startOf('day').valueOf(),
                y:Number(value),
                systolic_blood_pressure,
                diastolic_blood_pressure,
                heart_rate,
                marker,
                color
            })

        })

        return data;
    }

    generateSeriesInfo = (resultList)=>{
        const seriesMap = {
            'DIASTOLIC': {
                name: 'Diastolic Blood Pressure',
                type: 'line',
                valuePath: 'diastolic_blood_pressure.value',
                color:'#00D187',
                marker: {
                    enabled: false,
                },
            }
            ,
            'SYSTOLIC':{
                name:'Systolic Blood Pressure',
                type:'line',
                valuePath:'systolic_blood_pressure.value',
                color:'#7AD000',
                marker: {
                    enabled: false,
                },
            },
            'CRITICAL':{
                name:'Critical',
                data:[],
                color:'#EE6161',
                states: {
                    hover: {
                        lineWidthPlus: 0
                    }
                },
                lineWidth: 0,
                marker: {
                    enabled: true,
                    symbol:'circle',
                    radius: 4,
                    lineWidth: 0,
                    lineColor:null
                },
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },
            },
            'RISK':{
                name:'Risk',
                data:[],
                color:'#FFBD04',
                marker: {
                    enabled: true,
                    symbol:'circle',
                    radius: 4,
                    lineWidth: 0,
                    lineColor:null
                },
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },

            }

        }
        const seriesConfig = [];
        for (let [k, v] of Object.entries(seriesMap)) {
            const { type,name,valuePath,color,data,marker={},lineWidth,events={} } = v;
            seriesConfig.push({
                data: !data ? this.groupData(resultList,{ valuePath }) : data,
                type,
                name,
                valuePath,
                color,
                // lineWidth,
                findNearestPointBy:'xy',
                states:{
                    hover:{
                        halo: null
                    }
                },
                tooltip:{
                    pointFormatter:function(){
                        let { category, diastolic_blood_pressure,systolic_blood_pressure,heart_rate } = this;
                        heart_rate = heart_rate ? heart_rate : '--';
                        const date = moment(category).format('MM/DD/YYYY');
                        return `<br>
                              Systolic: <b>${systolic_blood_pressure}</b><br>
                              Diastolic: <b>${diastolic_blood_pressure}</b><br>
                              Heart Rate: <b>${heart_rate}</b>
                              `;
                    }
                },
                marker,
                events
            });
        }
        return seriesConfig;
    }

    renderBGChart = ()=>{
        const { props,loading } = this;
        if(loading){
            return <div>loading placeholder</div>
        }
        const { resultList,fromDate,toDate,patientId, chartWidth, xAxisLabelPerDay } = props;
        const seriesConfig = this.generateSeriesInfo(resultList,fromDate);
        const options =  {
            chart: {
                type: 'line',
                width: chartWidth
            },
            title:null,
            plotBackgroundColor:'#0099FF',
            xAxis: {
                type: 'datetime',
                tickWidth:0,
                tickInterval: 24 * 3600 * 1000 * xAxisLabelPerDay,
                min:fromDate.valueOf(),
                max:toDate.valueOf(),
                labels:{
                    formatter:(value)=>{
                        const xValue = value.value;
                        return moment(xValue).format('MM/DD/YYYY');
                    }
                }
            },
            yAxis: {
                title: 'mmHg',
                gridLineColor: 'transparent',
                min:40,
                max:300,
                plotBands: [{
                    from: 60,
                    to: 80,
                    color: 'rgb(119,119,119,0.05)',
                }, {
                    from:90,
                    to: 130,
                    color: 'rgb(119,119,119,0.05)',
                }]
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
            },
            tooltip: {
                // shared: true,
                crosshairs: true,
                dateTimeLabelFormats: {
                    day: "%m/%e/%Y",
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            const { name } = this;
                            Mixpanel.track('clicked',name+'_BP',null,{ PATIENT_ID:patientId })
                        }
                    }
                }
            },
            time:{
                useUTC:false,
                timezone:moment.tz.guess()
            },
            credits: {
                enabled: false
            },
            series:seriesConfig
        }
        return <HighchartsReact
          highcharts={Highcharts} options={options}
          callback={this.props.chartCallback}
        />

    }
    render(){
        const { props } = this;
        const { loading } = props;
        const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};

        return <div style={style}>
            { loading? <IHLoading/> : this.renderBGChart() }
        </div>
    }
}
const withResData = graphql( resultList, {
    options: (ownProps) => {
        const { fromDate,toDate } = ownProps;
        return {
            variables: {
                "filters": {
                    "enrolledProgramId": ownProps.enrolledProgramId,
                    "type": "BP",
                    fromDate,
                    toDate,
                    severity: CHART_SEVERITY
                },
                "count": RESULT_COUNT_MAX,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        }
    },
    props: ({ownProps, data}) => {
        const { loading, resultList } = data;
        if(loading){
            return {
                loading
            }
        }
        return {
            resultList: _.get(resultList,'data',[]),
            loading,
        }
    }
})

export default compose(withResData)(BPLineChartComponent);
