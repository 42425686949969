
import gql from 'graphql-tag'

export default gql`
  mutation deleteTodo(
    $id:EID!
  ){
    deleteTodo(
      id:$id
    )
  }
`
