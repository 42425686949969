import { React, IHLoading, _ } from 'ihcomponent';
import { withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
// graphql
import { patientAssPreview as getAssessmentFilled } from 'modulesAll/graphql/assessmentFilled';
import getAssessment from 'modulesAll/graphql/assessment';

// component
import AssessmentFilledContainer from '../../edit/containers/AssessmentFilledContainer';

class component extends React.Component {
  static displayName = 'patientView/myAssessmentAndSurvey/preview/containers/PreviewInnerContainer'

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { client, isAssessment = false, id, setBreadCrumb, breadCrumb } = this.props;

    const res = await client.query({
      query: isAssessment ? getAssessment : getAssessmentFilled,
      variables: { id },
      fetchPolicy: 'network-only',
    });

    this.setState({
      loading: false,
      assessmentFilled: _.get(res, `data.assessment${isAssessment ? '' : 'Filled'}`),
    });

    if (breadCrumb) {
      setBreadCrumb(`${breadCrumb} / ${ this.state.assessmentFilled && this.state.assessmentFilled.name }`)
    }
  }

  render() {
    if (this.state.loading) {
      return <IHLoading />;
    }

    return <AssessmentFilledContainer {...this.props} assessmentFilled={this.state.assessmentFilled} isPreview />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
});

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
)(component);
