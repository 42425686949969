import type from '../constants'

export default {
  changeSuspendReason: (reason) => {
    return {
        type : type.CHANGE_SUSPEND_REASON,
        reason
    }
  }
}
