import React from 'react';
import {compose, graphql} from "react-apollo";
import CreateRemoteEnrollmentComponent from "../component/CreateRemoteEnrollmentComponent";
import query from '../query/index';

const CreateRemoteEnrollmentContainer = class extends React.Component{
    render() {
        const { latestVisit,loading,memberId,refetch,fullName,refetchRemoteEnrollment } = this.props;
        if(loading){
            return loading;
        }
        return <CreateRemoteEnrollmentComponent latestVisit={latestVisit} refetchRemoteEnrollment={refetchRemoteEnrollment}
                                                memberId={memberId} fullName={fullName} refetch={refetch}/>
    }
}


const latestVisit = graphql(query.latestVisit,{options: (ownProps) => {
        const memberId = _.get(ownProps,'memberId');
        const  variables = {
            page: 1,
            count: 1,
            sort:{
                field: 'APPOINTMENT_AT',
                direction: 'DESC'
            },
            memberId,
        }

        return {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading,visitList } = data;
        if(loading){
            return {
                loading
            }
        }

        return {
            latestVisit:_.last(visitList.data)
        }
    }
});

export default compose(latestVisit)(CreateRemoteEnrollmentContainer);