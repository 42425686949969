import type from '../constants/ReplaceReturnDevicesConstants'
import { commonActions } from 'libModule/helpers/action-helpers'
import { commonReplaceReturnDevicesActions } from 'modulesAll/vendor/patient/actions/CommonReplaceReturnDevicesActions'

//for return replace devices form
export const commonReplaceReturnDevicesFormActionsObject = {
  ...commonActions(type, [
  'resetForm',
  'updateForm',
  'onFieldsChange',
  'handleFormSaved',
  'setSubmitting'
])
}



export const commonReplaceReturnDevicesActionsObject =
{
  ...commonReplaceReturnDevicesActions(type, [
    'setReplaceDevicesStep',
    'setReturnDevicesStep',
    'changeDeviceStatusTableInfo',
    'changeDeviceRemarkTableInfo',
    'resetDevicesStatusTableInfo',
    'initDevicesStatusTableInfo'
  ])
}
