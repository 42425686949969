import query  from '../query';
import Client from 'libModule/gqlClient';
import {variables} from "../../patientView/myPrograms/constants/MyProgramsConstants";

const moveRemoteEnrollmentHandler = (variables)=> Client.mutate({mutation:query.moveRemoteEnrollment,variables});
const addLastCallByHandler = (variables)=>Client.mutate({mutation:query.addLastCallBy,variables});
const checkRemoteEnrollmentTaskHandler = (variables)=> Client.mutate({mutation:query.checkRemoteEnrollmentTask,variables});
const editShippingNumber = (variables )=>Client.mutate({mutation:query.editShippingNumber,variables});
const editReferByDoctor = (variables)=> Client.mutate({mutation:query.editReferByDoctor,variables});
const editExcludedReason = (variables)=>Client.mutate({mutation:query.editExcludedReason,variables});
const fetchPostItList = (variables,fetchPolicy)=>Client.query({query:query.PostItList,variables,fetchPolicy});
const createRE = (variables)=>Client.mutate({mutation:query.createRE,variables});
const editConditionList = (variables)=>Client.mutate({mutation:query.editConditionListMutate,variables});
const sendPromoteSMS =  (variables)=>Client.mutate({mutation:query.sendPromoteSMSMutate,variables});
export default {
    moveRemoteEnrollmentHandler,
    addLastCallByHandler,
    checkRemoteEnrollmentTaskHandler,
    editShippingNumber,
    editReferByDoctor,
    editExcludedReason,
    fetchPostItList,
    createRE,
    editConditionList,
    sendPromoteSMS
}
