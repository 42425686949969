import gql from 'graphql-tag';
import AssessmentFilled from './schema/AssessmentFilled';
import update from 'immutability-helper';
import _ from 'lodash';

export default gql `
  mutation insertAssessmentFilled (
    $assessmentId: EID!
    $memberId: EID!
    $date: Date!
    $timezone: String!
    $submit: Boolean
    $questionResults: [ InputAssessmentFilledQuestion ]
  ) {
    insertAssessmentFilled (
      assessmentId: $assessmentId
      memberId: $memberId
      date: $date
      timezone: $timezone
      submit: $submit
      questionResults: $questionResults
    ) {
      ${AssessmentFilled}
    }
  }
`;
