import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import createTableAction from 'libModule/table/TableActions';
import {modalAction} from 'modulesAll/common/actions'
import TaggedPatientListComponent from '../components/TaggedPatientListComponent'
import { taggedPatients as patientList } from 'modulesAll/graphql/patientList'
import { _ } from 'ihcomponent'

const tableActions = createTableAction('provider_tagged_patient_list_forDashboard');

const withData = graphql( patientList, {
  options : (ownProps)=>{
    return {
      variables : {
        page: _.get(ownProps, 'page.current', 1),
        count : 5,
        filters : {
          starred : true,
          showAll: true,
          //severity: ['RISK', 'CRITICAL', 'MISSED']
        },
        sort : { "field": "BIRTHDAY",
                 "direction": "ASC"}
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  },
  props : ({ownProps, data})=>{
    return {
      loading : data.loading,
      list : _.get(data, 'patientList'),
      refetch : data.refetch
    }
  }
})

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.globalTables.provider_tagged_patient_list_forDashboard,
  ...state.common.PatientStarModal
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...tableActions,
    showPatientStarModal: (show, type, user) => dispatch(modalAction.showPatientStarModal(show, type, user))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withData,
)(TaggedPatientListComponent);
