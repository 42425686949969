import React from 'react';
import {Icon} from 'antd';
import { COMPLEXITY_LEVELS, COMPLEXITY_ENUM } from '../../../../lib/constants';

const style = {
  border : '1px solid #ccc',
  borderRadius: 4,
  padding : '20px',
  display: 'flex',
  marginBottom: '20px',
  opacity: 0.8
}

const DEFAULT_MESSAGE = `Based on the condition checkbox or ICD-10 code that have been selected previously, the patient's Condition Complexity Level is`;

const PrevComplexityComponent = ({ patientComplexity, customMessage }) => {
    const complexityLevel = _.get(patientComplexity, 'level');
    const message = customMessage || DEFAULT_MESSAGE;
    if(complexityLevel && complexityLevel != COMPLEXITY_ENUM.NULL_CODES){
        return <div style={style}>
          <div><Icon type='exclamation-circle' theme='filled' style={{ marginRight: 10}} /></div>
          <div>
              {message}
              <span style={{ fontWeight: 'bold', color: '#333', marginLeft: 3 }}>
                {COMPLEXITY_LEVELS[complexityLevel]}
              </span>
          </div>
        </div>
    }
    return '';
};

export default PrevComplexityComponent;
