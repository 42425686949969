import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import verifyOnBoardToken from 'modulesAll/graphql/verifyOnBoardToken';
import OnboardVerificationComponent from '../components/OnboardVerificationComponent';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

// connect apollo data with container
const OnboardVerificationContainer = graphql(verifyOnBoardToken, { name: 'verifyOnBoardToken' })(OnboardVerificationComponent);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardVerificationContainer);
