import React from "react";
import AddNewLoanDevice from './addNewLoadDevice';
import RenderAndUpdataExistingLoanDevice from '../components/renderAndUpdataExistingLoanDevice';
import { Card } from 'antd';
import { IHLoading } from 'ihcomponent';

class LoanDeviceComponent extends React.Component {
    constructor(){
        super();
    }

    renderIfNoDevice(props){
        return <AddNewLoanDevice {...props} refetchLoanDevice = { this.refetchLoanDevice } setHasDevice={this.setHasDevice}/>
    }

    renderWithDevice=(props)=>{
        return <RenderAndUpdataExistingLoanDevice {...props} refetchLoanDevice = { this.refetchLoanDevice } setHasDevice={this.setHasDevice}/>
    }

    refetchLoanDevice = ()=>{
        const { refetch } = this.props;
        return  refetch({
            props:({data})=>{
                const getLoanDevice  = _.get(data,'getLoanDevice',null);
                if(loading){
                    return{
                        loading
                    }
                }
                return{
                    getLoanDevice,
                }
            }
        });
    }

    render(){
        const { props, state } = this;
        const { getLoanDevice,loading, forModal } = props;
        if(loading) return <IHLoading/>;

        if(!getLoanDevice) return forModal ? this.renderIfNoDevice(props) : <Card>{ this.renderIfNoDevice(props) }</Card>

        return forModal ?
                this.renderWithDevice(props)
            :
                <Card style={{ borderTop:'unset !important' }} className='deviceCard'>
                    {this.renderWithDevice(props)}
                </Card>
    }
}

export  default LoanDeviceComponent;

