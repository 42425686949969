import { React, IHButton, IHBaseFormWithRow, _ } from 'ihcomponent'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'antd'
import I18N from '../../../I18N';
import { nationOptions, relationOptions } from 'libModule/constants'
const BUTTONLABELS = I18N.get('patientlist.buttons');
import { emailValidator2, firstAndLastNameValidator, NRICValidator } from 'libModule/validator';

import $ from 'jquery';

import { templateParser, templateFormatter, parseDigit } from 'input-format'
import { ReactInput } from 'input-format'
import TestUserComponent from "../../../patient/component/TestUserComponent";
import CheckSelfEnrollComponent from '../../../patient/component/CheckSelfEnrollComponent';
// DOB template
const TEMPLATE = 'xx/xx/xxxx'
const parse = templateParser(TEMPLATE, parseDigit)
const dobParseFormat = templateFormatter(TEMPLATE)

const styles = {
  form: {},
  footer: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'space-between',
    margin: '20px 0'
  }
}

export default class NewPatient extends React.Component{
  static displayName = 'patientList/registerNew/components/AddNewPatient'

  componentWillUnmount() {
    this.props.resetForm()
  }

  componentDidUpdate(prevProps){
    var updateUsername = false;
    var value = this.props.AddNewPatient.fieldsValue.firstName.value;
    if ((prevProps.AddNewPatient.fieldsValue.firstName.value != value) ){
      updateUsername = true;
      if (value.length > 0) {
       const firstNameValueUpdated = value[0].toUpperCase() + value.slice(1);
       this.refs.newPatientBaseForm.setFormData({ 'firstName': firstNameValueUpdated})
      }
    }
    var lastNameValue = this.props.AddNewPatient.fieldsValue.lastName.value;
    if ( (prevProps.AddNewPatient.fieldsValue.lastName.value != lastNameValue) ){
      updateUsername = true;
      if (lastNameValue.length > 0){
        const lastNameValueUpdated = lastNameValue[0].toUpperCase() + lastNameValue.slice(1);
        this.refs.newPatientBaseForm.setFormData({ 'lastName': lastNameValueUpdated})
      }
    }
    if (updateUsername) {
      var autoUsername = ''
      if (value.length > 0) {
        autoUsername += value.toLowerCase()
      }
      if (lastNameValue.length > 0) {
        autoUsername += lastNameValue.toLowerCase()
      }
      this.refs.newPatientBaseForm.setFormData({ 'username': autoUsername})
    }
  }

  constructor(p){
    super(p)

    this.state = {
      caregiver : 0,
      DOB: ''
    }
  }

  componentDidMount() {
    // use JQuery add new input, keep the old input
    $('#DOB').after( $( "#dob-input" ) );
    // $('#DOB').height(80)
  }

  getCaregiverInfoItemList(){

  }

  // formProp.main is itemList
  // & most for care givers
  getBaseFormProps(){
    const {importValue, AddNewPatient} = this.props
    const initData = AddNewPatient.initData
    const caregiverList = AddNewPatient.caregiverList

    const itemLayout = {
      labelCol : {span : 24},
      wrapperCol : {span : 24}
    }
    const renderFormItem = (key, label, type, itemLayout, placeholder, initialValue, disabled, col, others={}) => {
      return {
        key,
        label,
        type,
        ...itemLayout,
        placeholder,
        disabled,
        initialValue: importValue[key] || (initData[key] && initData[key].value) || initialValue,
        col,
        ...others
      }
    }
    const renderLabel = (key, label, type, col, others={}) => {
      return {
        key,
        label,
        type,
        col,
        ...others
      }
    }

    const itemList = this.props.itemList

    let caregiversArray = [];
    // const firstItemIdx = _.findIndex(caregiverList, d => d)
    _.each(caregiverList, (item, index) => {
      const n = index+1
      const arr = [
        [
          renderLabel('gap', '', 'label', {span: 24, offset: 0})
        ],
        [
          renderFormItem(`c_firstName_${n}`, 'Caregiver First Name', 'text', itemLayout, '', '', false, {span: 10, offset: 4},
                        {rules : [
                          {required : true, message : 'First Name is required'},
                          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'firstName'))}
                        ]}),
          renderFormItem(`c_lastName_${n}`, 'Caregiver Last Name', 'text', itemLayout, '', '', false, {span: 10, offset: 0},
                        {rules : [
                          {required : true, message : 'Last Name is required'},
                          {validator: (rule, value, callback) => callback(firstAndLastNameValidator(value, 'lastName'))}
                        ]}),
        ],
        [
          renderFormItem(`c_nric_${n}`, `Caregiver ${I18N.get('keywords.CaregiverNRIC/FIN')}`, 'text', itemLayout, '', '', false, {span: 10, offset: 4},
                        {rules : [
                          {required : true, message : `${I18N.get('keywords.CaregiverNRIC/FIN')} is required`},
                          {validator: (rule, value, callback) => callback(NRICValidator(value))}
                        ]}),
          renderFormItem(`c_mobileCountryCode_${n}`, 'Caregiver Mobile', 'text', itemLayout, '', '+1', true, {span: 3, offset: 0}),
          renderFormItem(`c_mobilePhone_${n}`, ' ', 'text', itemLayout, '', '', false, {span: 7, offset: 0},
                        {rules : [
                          //{ len: 8, message: 'Need to equal 8 digits'}
                        ]}),
        ],
        [
          renderFormItem(`c_email_${n}`, 'Caregiver Email', 'text', itemLayout, '', '', false, {span: 20, offset: 4},
                          {rules : [
                            {validator: (rule, value, callback) => callback(emailValidator2(value))}
                          ]}),
        ],
        [
          renderFormItem(`c_nationality_${n}`, 'Nationality', 'select', itemLayout, '', 'US', false, {span: 10, offset: 4},
          { option: nationOptions,
            showSearch: true,
            filterOption: (inputValue, option) => {
              return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }}),
          renderFormItem(`c_relationship_${n}`, 'Relationship', 'select', itemLayout, '', 'Child', false, {span: 6, offset: 0},
          { option: relationOptions,
            showSearch: true,
            filterOption: (inputValue, option) => {
              return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }}
          )
        ]
      ]
      // Add the label 'Caregiver_info' just on the first Caregiver for all the caregiver group
        arr[1][0].col.offset = 0
        arr[1].unshift(renderLabel('Caregiver_info', 'Caregiver Info', 'label', {span: 4, offset: 0}))

      caregiversArray.push(arr)

    })

    caregiversArray = _.map(caregiversArray, (item) => {
      return {
        horizontal : true,
        itemList : item
      }
    })

    return {
      main : {
        horizontal: true,
        fieldsValue: AddNewPatient.fieldsValue,
        itemList
      },
      caregiver : caregiversArray
    }
  }

  getAddCaregiverButtonProps(){
    const len = this.props.AddNewPatient.caregiverList.length
    return {
      label : BUTTONLABELS.addCaregiverButton,
      onClick : () => {
        this.props.addCaregiver()
      }
    }
  }

  async handleEnrollClick(formProps) {
    let isPatientFormValid = true
    let isCaregiverFormValid = true

    const patientFormCallBack = (result, value) => {
        if (!result) {
          isPatientFormValid = false
        }
    }
    await this.refs.newPatientBaseForm.getFormData(patientFormCallBack)
    await Promise.all(this.props.AddNewPatient.caregiverList.map((c, i) => {
      return c?
          this.refs[`newCgBaseForm_${i}`].getFormDataSync() : Promise.resolve()
      })
    ).catch(val => {
      isCaregiverFormValid = false
    })

    if (!isPatientFormValid || !isCaregiverFormValid) return
    const o = this.refs.enroll_btn
    const hide = ()=>o.loading(false)
    o.loading(true)
    this.props.handleEnrollClick(hide)
  }

  async handleCreateClick(formProps) {
    let isPatientFormValid = true
    let isCaregiverFormValid = true

    const patientFormCallBack = (result, value) => {
        if (!result) {
          isPatientFormValid = false
        }
    }
    await this.refs.newPatientBaseForm.getFormData(patientFormCallBack)
    await Promise.all(this.props.AddNewPatient.caregiverList.map((c, i) => {
      return c?
          this.refs[`newCgBaseForm_${i}`].getFormDataSync() : Promise.resolve(true)
      })
    ).catch(val => {
      isCaregiverFormValid = false
    })

    if (!isPatientFormValid || !isCaregiverFormValid) return
    const o = this.refs.create_btn
    const hide = ()=>o.loading(false)
    o.loading(true)
    this.props.handleCreateClick({close:hide,state:this.state})
  }

  render(){
    const {disabled, AddNewPatient: { caregiverList }} = this.props
    const formProp = this.getBaseFormProps()
    const footerElem = this.props.footer ? this.props.footer : (
      <div>
        <Row>
          <Col span={4}></Col>
          <Col span={20}><CheckSelfEnrollComponent localThis={this}/></Col>
        </Row>
        <Row style={{marginTop:10}}>
          <Col span={4}></Col>
          <Col span={20}><TestUserComponent localThis={this} headerText={'Creating a test patient?'}/></Col>
        </Row>
        <Row>
          <Col span={20} offset={4}>
            <div style={styles.footer}>
              <IHButton onClick={this.props.handleCancelClick} bsStyle="default" disabled={disabled} label={BUTTONLABELS.cancelButton} />
              <div>
                <IHButton ref="create_btn" onClick={this.handleCreateClick.bind(this, formProp)} bsStyle="primary" disabled={disabled} label={BUTTONLABELS.createButton} style={{marginLeft: '10px'}} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )


    // user ref newPatientBaseForm getFormData
    const bodyElem = (
      <IHBaseFormWithRow style={styles.form} {...formProp.main} {...this.props} ref="newPatientBaseForm" ></IHBaseFormWithRow>
    )

    return (
      <div className="vsm-patient-import_patient_info vsm-patient-AddNewPatient v-form-required">

        {/* patient DOB input, will be add into bodyElem by JQuery*/}
        <div id="dob-input" className="dob-input">
          <ReactInput
            className="ant-input"
            placeholder="MM/DD/YYYY"
            value={this.state.DOB}
            onChange={DOB => {
              this.setState({ DOB })
              String.prototype.splice = function(idx, rem, str) {
                return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
              };
              var result = DOB.splice(2, 0, "/");
              var result2 = result.splice(5, 0, "/");
              this.refs.newPatientBaseForm.setFormData({ 'DOB': result2  })
             }
            }
            parse={parse}
            format={dobParseFormat}/>
        </div>

        {/* patient Basic Info, Login Info, Contact Info*/}
        {bodyElem}

        {/* for caregiver */}
        {
          _.map(formProp.caregiver, (item, index)=>{
            const ref = "newCgBaseForm_" + index
            return (
              <div key={index} style={{display: caregiverList[index]? null : 'none'}}
                   className={_.findIndex(caregiverList, d => d) === index? "":"cg-form-noshow"} >
                <IHBaseFormWithRow style={styles.form} {...this.props} {...item} ref={ref} ></IHBaseFormWithRow>
                <div className="dlt-btn-wrapper">
                  <IHButton label="Delete" className="btn-delete" onClick={() => this.props.removeCaregiver(index)} />
                </div>
              </div>
            )
          })
        }

        {/* cancel and create button */}
        {footerElem}
      </div>
    )
  }
}

NewPatient.propTypes = {
  addCaregiver: PropTypes.func,
  disabled: PropTypes.bool,
  formProp: PropTypes.object,
  footer: PropTypes.node,
  handleCreateClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
  AddNewPatient: PropTypes.object,
  flag: PropTypes.bool,
  importValue: PropTypes.object,
  itemList: PropTypes.array
}
