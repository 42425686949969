import {
    React
} from 'ihcomponent';
import { Progress } from 'antd';

class ProcessBarComponent extends React.Component{
    render(){

        const { monthlyTimeSpent } = this.props;
        let timeInMinutes = Math.round(monthlyTimeSpent/60);
        const bar1 = (timeInMinutes/20 >=1 ? 1 : timeInMinutes/20)*100;
        timeInMinutes = timeInMinutes - 20;
        const bar2 = (timeInMinutes/40 >=1 ? 1 : timeInMinutes/40)*100;
        timeInMinutes = timeInMinutes - 40;
        const bar3 = (timeInMinutes/30 >=1 ? 1 : timeInMinutes/30)*100;
        const showCheck = (bar) => bar==100;

        return(
            <div style={{width:360,display:'flex',marginLeft:20}}>
                <div style={{width:'22%',marginLeft:2}}>
                    <Progress percent={bar1} showInfo={showCheck(bar1)} strokeWidth={8} type='line' className='bar1 timeBar'/>
                </div>
                <div style={{width:'44%',marginLeft:2}}>
                    <Progress percent={bar2} showInfo={showCheck(bar2)} strokeWidth={8} type='line' className='bar2 timeBar'/>
                </div>
                <div style={{width:'33%',marginLeft:2}}>
                    <Progress percent={bar3} showInfo={showCheck(bar3)} strokeWidth={8} type='line' className='bar3 timeBar'/>
                </div>
            </div>
        )
    }
}

export default ProcessBarComponent;
