import React, { Component } from 'react'
import { compose } from 'react-apollo'
import { graphql } from "react-apollo/index";
import medicationList  from  'modulesAll/graphql/medicationList';
import MedicationComponent from '../component/MedicationManagement/MedicationComponent'
import getVariables from 'modulesAll/medicine/query/variables.js'
import renderOverlayDiv from '../helpers/overLayButton';
import { renderDraftSavedText } from '../../visitWorkList/helpers';


export class MedicationContainer extends Component {
    static displayName = 'medicationComponent';
    static title= 'Medication Compliance'
    constructor(){
        super();
        this.state = {
            showMedModal:false
        }
    }

    setShowMedModal = (flag) => {
        this.setState({
            showMedModal:flag
        })
    }

    render() {
        const { props } = this;
        const { enrolledProgramId, workList } = props;
        const { showMedModal } = this.state;
        return (
            <div>
                {/*{!enrolledProgramId && workList && renderOverlayDiv(this.props)}*/}
                {workList ?
                    <div>
                        <div className={workList ? 'fix-header' : ''} style={{fontSize:16, fontWeight:'bold', color:"#000000"}}>
                          <div class="fixHeaderTitle">
                            Medication Management
                            {renderDraftSavedText()}
                          </div>
                        </div>
                        <MedicationComponent {...props} showMedModal={showMedModal} setShowMedModal={this.setShowMedModal}/>
                    </div> : <MedicationComponent {...props} showMedModal={showMedModal} setShowMedModal={this.setShowMedModal}/>
                }
            </div>
        )
    }
}

const withData = graphql(medicationList, {
    options: (ownProps) => {
        const variables = getVariables(ownProps);
        return {
            variables,
            fetchPolicy: 'network-only'
        };
    },
    props: ({ownProps, data}) => {
        const { loading, medicationList } = data;
        if(medicationList && medicationList.data) {
            return {
                medicationList,
                uniqueMedNames: _.map(_.uniqBy(medicationList.data,'name'), m => m.name),
                loading
            }
        }

        return {
            loading
        }
    }
})

export default compose(withData) (MedicationContainer)
