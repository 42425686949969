import types from 'ProgramEditModule/Assessment/constants/ListConstants'

import {commonReducer, commonInitialState} from 'libModule/helpers/reducer-helpers.js'

const initialState = Object.assign({
  data: [],
  pagination: {pageSize: 5},
  selectedIds: null, // Do not use [], [] means no selected items
  tasks: []
}, commonInitialState(types))

const ownReducer = (state, action) => {
  switch (action.type) {
    // case types.ADD_ITEM_DATA:
    //   return addDataToList(state, action.fieldsValue)
    case types.ON_TABLE_CHANGE:
      const { filter, sorter, pagination } = action
      return {
        ...state,
        filter,
        sorter,
        pagination,
      }
    case types.SELECT_ITEM:{
      const { selectedIds } = action;

      return {
        ...state,
        selectedIds
      }
    }

    case types.CHANGE_TASKS:{
      const { tasks } = action;

      return {
        ...state,
        ...tasks
      }
    }
    case types.RESET: {
      return initialState
    }

    default:
      return state
  }
}

export default (state = initialState, action) => {
  return commonReducer(ownReducer(state, action), action, types)
}
