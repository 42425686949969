import types from '../constants/Broadcast'

// Default State
const initState = {
  showBroadcastState: true,
  timeToUpdateBroadcast: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.HIDE_BROADCAST:
      return {
        ...state,
        showBroadcastState: false
    }
    case types.UPDATE_BROADCAST:
      return {
        ...state,
        timeToUpdateBroadcast: action.trueFalse
    }
    default:
      return state
  }
}
