import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Icon, Radio, Tooltip, Button, Spin } from 'antd';
import { DATE_HELPERS } from '../../../lib/helpers/date-helpers';
import { SIGNOFF_TIME_FORMAT } from '../../../lib/constants';
import { helpers } from '../../visitNewWorkFlow/helper';

class SignOffComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSpent: null
    };
  };
  generateTimeOpts = ()=>{
      let step = 15; // minutes
      let totalSteps = 8;
      const options = [];
      for (let i = 1; i <= totalSteps; i++) {
          let min = i * step;
          options.push({
              value:min,
              label:`${min} min`
          })
      }
      return options;
  }
  renderSignOffButton = () => {
    const { signOffStatus, onClickSignOff } = this.props;
    const prevTimeSpent = _.get(signOffStatus, 'timeSpent');
    const selectedTimeSpent = this.state.timeSpent || prevTimeSpent;
    const isRD = helpers.getRole() === 'RD';
    return (
      <div className='sign-off-button-wrapper'>
        <Divider />
        <div className='sign-off-estimated-time'>
          <div className='sign-off-estimated-time-label'>
            <span>
              Estimated Time Spent with Patient
            </span>
            <Tooltip
              overlayClassName='sign-off-info-tip'
              title='This estimation will be used for the billable time calculation for Medical Nutrition Therapy (MNT). Capped at 120 minutes.'
            >
              <Icon type='info-circle' theme='filled' />
            </Tooltip>
          </div>
          <Radio.Group
            value={selectedTimeSpent}
            onChange={e => this.setState({ timeSpent: e.target.value })}
            options={this.generateTimeOpts()}
          />
          {
            prevTimeSpent &&
            <div className='sign-off-small-note'>
              (You selected {prevTimeSpent} min in the previous sign off)
            </div>
          }
        </div>
        <Button
          type='primary'
          disabled={!isRD || !_.isNumber(selectedTimeSpent)}
          onClick={() => onClickSignOff(selectedTimeSpent)}
        >
          Sign off
        </Button>
        {
          !isRD &&
          <div className='sign-off-small-note'>
            (Only the RD may sign-off on an MNT chart)
          </div>
        }
      </div>
    );
  };

  renderFirstTimeSignOff = () => {
    return (
      <div className='sign-off-container'>
        <img src={'/image/smile_face.png'} width={80} style={{ marginBottom: 18 }}/>
        <span className='sign-off-congratulations'>
          Great! You have finished this patient’s chart!  Please select the best estimate for the total time you spent on this patient’s chart and sign off below
        </span>
        <span className='sign-off-info'>
          By signing off, this patient will be checked out and a Care Plan version history PDF will be created.
        </span>
        { this.renderSignOffButton() }
      </div>
    );
  };

  renderNewSignOff = () => {
    return (
      <div className='sign-off-container'>
        <span className='sign-off-congratulations sign-off-congratulations-new'>
          You have made updates in this patient’s chart!  Please select the best re-estimate for the total time you spent on this patient’s chart and sign off below.
        </span>
        <span className='sign-off-info'>
          By signing off, the previous Care Plan version history PDF will be updated with this new one.
        </span>
        { this.renderSignOffButton() }
      </div>
    );
  };

  renderCompletedSignOff = () => {
    const { signOffStatus, renderViewVersionHistoryLink } = this.props;
    let { signedOffBy, signedOffTime } = signOffStatus;
    signedOffBy = _.get(signedOffBy, 'profile.fullName') || 'N/A';
    signedOffTime = DATE_HELPERS.getTimeInClinicTimezone(signedOffTime);
    signedOffTime = signedOffTime ? signedOffTime.format(SIGNOFF_TIME_FORMAT) : 'N/A';

    return (
      <div className='sign-off-container'>
        <img src={'/image/icon_check.png'} width={80} style={{ marginBottom: 18 }}/>
        <span className='sign-off-congratulations'>
          A Care Plan version history PDF is available
        </span>
        <span className='sign-off-info sign-off-info-completed'>
          Click {
            renderViewVersionHistoryLink(
              <a onClick={e => e.preventDefault()}>here</a>
            )
          } to view or print. You can also find the care plan PDF
          in Patient profile &#62; Care plan &#62; Version history
        </span>
        <div className='sign-off-data'>
          <span>
            Sign off by: {signedOffBy}
          </span>
          <span>
            Sign off time: {signedOffTime}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { isSigningOff, signOffStatus } = this.props;

    if(isSigningOff)
      return <Spin />;

    const isFirstTime = _.isNil(signOffStatus);
    if(isFirstTime)
      return this.renderFirstTimeSignOff();

    const { signOff } = signOffStatus;
    if(signOff)
      return this.renderCompletedSignOff();

    return this.renderNewSignOff();
  }
};

SignOffComponent.propTypes = {
  isSigningOff: PropTypes.bool.isRequired,
  signOffStatus: PropTypes.shape({
    signOff: PropTypes.bool.isRequired,
    signedOffBy: PropTypes.object.isRequired,
    signedOffTime: PropTypes.number.isRequired
  }),
  onClickSignOff: PropTypes.func.isRequired,
  renderViewVersionHistoryLink: PropTypes.func.isRequired
};

export default SignOffComponent;
