import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHLoading, IHButton, _ } from 'ihcomponent';
import { formatSchedule, formatTime, hasMeasurement, hasMissedAlert, formatReadingFromRow, renderVitalType } from 'modulesAll/utils/helpers/task';
import { renderAction } from 'modulesAll/patientView/myAssessmentAndSurvey/utils'
import OneTimeTask from '../containers/OneTimeTaskContainer'

export const oneTimeTaskButton = (props) => {
  const b = {
    type : 'primary',
    label : 'Assign One-time Task',
    style : {
      position : 'absolute',
      top : '8px',
      right : '12px'
    },
    onClick : () => {
      const oneTimeTask = (
        <OneTimeTask enrolledProgramId={props.enrolledProgramId} enrolledProgramName={props.currentProgram.name}
        refetch={props.refetch} programmeTasks={props.data.enrolledProgram.tasks} healthCondition={props.data.enrolledProgram.healthCondition} />
      );

      props.openModal(oneTimeTask, {
        title: 'Assign One-time Task',
        showHeaderCloseButton: true,
        className : 'vsm-patient-onetimetask-modal',
        enforceFocus : false
      });
    },
    size : 'small'
  }
  return <IHButton {...b} />
}

const PatientProgramTaskListTable = (props) => {
  const { patientProgramTaskList, onRow, loading, onChange, vitalFilters, currentProgram } = props;
  const columns = [
    {
      title: 'Status',
      key: 'taskStatus',
      dataIndex: 'taskStatus',
      render: (item, row) => item === 'CURRENT' ? 'Upcoming' : item.toTitleCase(),
      filters: [
        // { value: 'CURRENT', text: 'Current' },
        { value: 'CURRENT, UPCOMING', text: 'Upcoming' },
        { value: 'INCOMPLETE', text: 'Incomplete' },
        { value: 'MISSED', text: 'Missed' },
        { value: 'COMPLETED', text: 'Completed' }
      ],
      onFilter : (a, doc)=>{
        return a === doc.taskStatus || _.includes(a, doc.taskStatus)
      }
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: renderVitalType,
      filters: vitalFilters || [],
      onFilter : (a, doc)=>{
          return a === doc.type
      }
    },
    {
      title: 'Schedule',
      key: 'schedule',
      dataIndex: 'schedule',
      render: (item, row) => formatSchedule(row),
    },
    {
      title: <p>Reading <em style={{fontSize: '11px'}}> (M = Manual)</em></p>,
      key: 'measure',
      dataIndex: 'taskStatus',
      render: (status, record) => {
        if (status === 'COMPLETED') {
          if (record.type === 'ASSESSMENT' || record.type === 'SURVEY') {
            return renderAction(status, {...record, ref:'nurse'})
          }
          else {
            return hasMissedAlert(record) ? <span className="miss-black">Missed</span> : hasMeasurement(record) ? formatReadingFromRow(record) : ''
          }

        }
        return null;
      }
    },
    {
      title: 'Taken On',
      key: 'taken',
      dataIndex: 'date',
      render: (item, row) => _.get(row, 'taskStatus') === 'COMPLETED' ? formatTime(item) : ''
    }
  ];

  const pagination = {
    pageSize: 5,
  };

  const tableProps = {
    columns,
    // rowKey: 'refId',
    actions: currentProgram.status === 'STARTED' && oneTimeTaskButton(props),
    pagination,
    onRow,
    onChange,
    dataSource: patientProgramTaskList.map((v, i) => ({...v, key: i})),
    title: 'Patient\'s Tasks Today',
  };

  const noDataElem = (
    <div style={{ height: '150px', paddingTop: '2em' }}>
      <p style={{ fontSize: '18px' }}>There is no data available</p>
    </div>
  );

  if (loading) {
    return <IHLoading />;
  }

  return (
    <div className="patient-task-table">
      <IHSearchTable {...tableProps} noDataElem={noDataElem} />
    </div>
  );
};

PatientProgramTaskListTable.displayName = 'patient/enrolledProgramInfo/components/PatientProgramTaskListTableComponent';

oneTimeTaskButton.propTypes = {
  openModal: PropTypes.func,
  enrolledProgramId: PropTypes.string,
  refetch: PropTypes.func,
  vitalFilters: PropTypes.array
}

PatientProgramTaskListTable.propTypes = {
  patientProgramTaskList: PropTypes.array,
  onRow: PropTypes.func,
  vitalFilters: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool
};

export default PatientProgramTaskListTable
