import createAreaFormConfig from "./AreaFormConfig";
import editClinicFormConfig from './editClinicFormConfig';
import careTeamFormConfig from './careTeamFormConfig';
import createClinicFormConfig from './createClinicFormConfig'
import { ROLE_NAME } from '../../../lib/constants';

const keyToForm =(localThis,key)=> ({
    createAreaForm: createAreaFormConfig(localThis),
    createClinicForm: createClinicFormConfig(localThis),
    editClinicForm: editClinicFormConfig(localThis),
})[key]

export const clinicTabKeyMap = {
  'CLINIC_MEMBER':{
    tabName: 'Clinic Members',
    buttonLabel: 'Add Members',
    roles: [
      'Doctor',
      'Clinic Medical Assistant'
    ],
    roleName:['MA','MD'],
    emptyPlaceholder: {
      content: 'There is no clinic member yet',
      buttonLabel: 'Add members'
    }
  },
  'IHEALTH_MEMBER' : {
    tabName: 'iHealth Members',
    buttonLabel: 'Add Members',
    roleName:['CA','RD','HC'],
    roles: [
      'Nurse',
      'Medical Assistant',
      ROLE_NAME.HC
    ],
    emptyPlaceholder: {
      content: 'There is no iHealth member yet',
      buttonLabel: 'Add members'
    }
  },
  'CARE_TEAM': {
    tabName: 'Unified Care Team',
    buttonLabel: 'Add a care team',
    confirmModalProps: {
      create: {
        content: 'Care team not created yet. Are you sure you want to leave?',
      }
    },
    emptyPlaceholder: {
      content: 'Choose clinic members and iHealth members to create a care team',
      buttonLabel: 'Create a unified care team'
    }
  }
};

export default {
    keyToForm
}