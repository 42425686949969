import { IHPanel, React, IHButton, IHLoading, IHBaseForm } from 'ihcomponent';
import I18N from '../../I18N';
import { passwordValidator, passwordConfirmValidator } from '../../../lib/validator';
import { submittingStyle } from 'libModule/utils'
import MainModal from 'layoutModule/containers/MainModalContainer';

const TITLES = I18N.get('auth.resetPassword');

export default class extends React.Component {
  render() {
    const {
      submit,
      isSubmitting,
    } = this.props;

    const validAndSubmit = (e, { validateFieldsAndScroll }) => {
      e.preventDefault();

      validateFieldsAndScroll((err, values) => {
        !err && submit(values);
      });
    };

    const formProp = {
      onSubmit: validAndSubmit,
      horizontal: true,
      ref: 'form',
      itemList: [
        {
          key: 'newPass',
          label: TITLES.passwordTitle,
          type: 'password',
          rules: [
            { validator: (rule, value, callback) => callback(passwordValidator(value)) },
          ],
        },
        {
          key: 'newPassCheck',
          label: TITLES.passwordConfirmTitle,
          type: 'password',
          rules: [
            { validator: (rule, value, callback) => {
              const pwd = this.refs.form.getValue().newPass;
              return callback(passwordConfirmValidator(value, pwd));
            },
            },
          ],
        },
      ],
    };

    const bodyElem = (
      <IHBaseForm {...formProp}>
        <div className="buttons-wrap">
          <IHButton bsStyle="primary" disabled={isSubmitting} type="submit" size="large" style={submittingStyle(isSubmitting)}>
            {isSubmitting ? <IHLoading /> : TITLES.submit}
          </IHButton>
        </div>
      </IHBaseForm>
    );

    const headerElem = (
      <div>
        <h3>{TITLES.title}</h3>
        <p>{TITLES.description}</p>
      </div>
    );

    const panelProps = {
      header: headerElem,
      body: bodyElem,
    };

    return (
      <div className="vsm-auth-box vsm-new-box auth-reset-password">
        <MainModal />
        <IHPanel {...panelProps} />
      </div>
    );
  }
}
