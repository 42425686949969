import I18N from 'modulesAll/I18N';

export const removeICDcode = 'REMOVE';
export const unselectBillable = 'UNSELECT';

export const openConfirmModal = (confirmType, doc, localThis) => {
  let confirmProps =  {};
  let confirmFn;
  switch (confirmType){
      case removeICDcode:
          confirmProps = I18N.get('healthCondition.removeICDcode');
          confirmFn = localThis.removeHealthCondition;
          break;
      case unselectBillable:
          confirmProps = I18N.get('healthCondition.unselectBillable');
          confirmFn = localThis.unCheckBillable;
          break;
  }

  return new Promise((resolve, reject) => {
      const Yes = {
          label: 'Confirm',
          handler: ()=>{
              if(confirmFn){
                  resolve(confirmFn(doc));
              }
          },
          closeModal: true
      }
      const No = {
          label: 'Cancel',
          handler: () => {
              resolve(false)
          }
      }
      const modalProps = {
          Yes,
          No,
          title: confirmProps.title,
          content: confirmProps.content
      };
      localThis.props.openConfirmModal(modalProps)
  })
}


export const parseBGOrBPInitial = (type, profile) => {
    const DUMMY_KEYS = ['SpecialistInfo', 'Treatments', 'Test', 'Visits'];
    const yearOfDiagnose = _.get(profile, `yearOfDiagnose`, []);
    const BPOrBGKeys = (type) => {
        return _.map(DUMMY_KEYS,(k)=>`${type}${k}`);
    }

    const vitalName = {
        'BG': 'DIABETES',
        'BP': 'HYPERTENSION'
    }[type]

    const yearOfDiagnoseByType = ()=>{
        return _.omit(_.find(yearOfDiagnose,(v) => v.condition === vitalName), '__typename');
    }
    return Object.assign(
        _.reduce(BPOrBGKeys(type), (res, key) => { 
            _.set(res, key, _.get(profile, `${key}`, {}));
            return res;
        }, {}), 
        yearOfDiagnoseByType()
    );
}
