import React from "react";
import { graphql, compose } from 'react-apollo'
// import { resultListWithoutProviderNotes as resultList } from 'modulesAll/graphql/resultList';
import $ from 'jquery';
import { RESULT_COUNT_MAX } from "../../../lib/constants";
import POLogBookComponent from "../components/POLogBookComponent";
import POResultStatisticsComponent from "../components/POResultStatisticsComponent";
import { POResultList } from "../query";

const POLogBookContainer = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize.bind(this);
    this.state={
      width:0,
      showChart: true
    };
  }

  handleResize() {
    const width = $('.logBookContainer').width();
    this.setState({
      width: width
    });
  }

  componentDidMount() {
    this.setState({ width: $('.logBookContainer').width() });
    window.addEventListener('resize', this.handleResize());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize());
  }

  render(){
    const { loading, resultList, stat, patientId, isInChat, ...props } = this.props;
    const { width } = this.state;
    const logBookContainerClassName = 'row logBookContainer'+((width<800||!width) ? ' smallDiv':'');
    const poTask = _.find(props.currentProgram.tasks, (o) => o.type === 'PO');


    // const style = loading ? { minHeight:400,justifyContent:'center',alignItems:'center',display:'flex' } : {};
    return (
      <div className={logBookContainerClassName} style={{ marginTop: 20, marginBottom: 50 }}>
        <div className={`${isInChat ? 'col-lg-12' : 'col-lg-7'}`}>
          <POLogBookComponent resultList={resultList} patientId={patientId} loading={loading} {...props}/>
        </div>
        <div className={`${isInChat ? 'col-lg-12' : 'col-lg-5'}`}>
          <POResultStatisticsComponent stat={stat} loading={loading} task={poTask} enrolledProgramId={props.currentProgram.id} />
        </div>
      </div>
    );
  }
}

const withResData = graphql(POResultList, {
    options: (ownProps) => {
      const { fromDate, toDate, patientId, currentProgram } = ownProps;

      return {
        variables: {
          filters: {
            memberId: patientId,
            enrolledProgramId: [currentProgram.id],
            type: 'PO',
            fromDate: Number(fromDate),
            toDate:  Number(toDate),
          },
          count: RESULT_COUNT_MAX, // retrieve all at once; intended behavior
          // sort: {field: 'DATE', direction: 'ASC'}
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      };
    },
    props: ({data}) => {
      const { loading, resultList } = data;
      if (loading) {
        return {
          loading
        };
      }
      return {
        resultList: _.get(resultList, 'data', []),
        stat: _.get(resultList, 'stat', {}),
        loading,
      };
    }
})

export default compose(withResData)(POLogBookContainer);
