import { DELIVERY_TYPE_ENUM, confirmDeviceConstants } from '../constant';
import { getKeepOwnDevicesOrgPermission } from '../helper/index';

const CLEAR_VALUE = confirmDeviceConstants.CLEAR.value;
const PHONE_VALUE = confirmDeviceConstants.LOANER_PHONE.value;
const ROUTER_VALUE = confirmDeviceConstants.ROUTER.value;
const HOTSPOT_VALUE = confirmDeviceConstants.HOTSPOT.value;

const getPreviousStepIndex = (patientReferral = {}) => {
  let  { keepOwnDevices } = getKeepOwnDevicesOrgPermission();
  let stepIndex = 0;

  if(_.isEmpty(patientReferral))
    return stepIndex;
  
  const { devicePickup, deviceShip, helpedSetupAndUseDevice, shipAddresses } = patientReferral;
  if( (_.get(devicePickup, 'length') ||  _.get(deviceShip, 'length')) && !_.isEmpty(shipAddresses) && !keepOwnDevices ) return 3;
  if(!_.get(devicePickup, 'length') &&  !_.get(deviceShip, 'length') && !keepOwnDevices ) return 2;

  if(_.get(devicePickup, 'length') || _.get(deviceShip, 'length'))
    stepIndex++;
  
  if(!_.isEmpty(shipAddresses))
    stepIndex++;

  if(!_.isNil(helpedSetupAndUseDevice))
    stepIndex++;
  
  return stepIndex;
};

const getPreviousSelectedDeliveryType = (patientReferral) => {
  if(_.isEmpty(patientReferral))
    return null;
  let  { keepOwnDevices } = getKeepOwnDevicesOrgPermission();
  let deliveryType = keepOwnDevices ? null : DELIVERY_TYPE_ENUM.SHIP_TO_PATIENT;
  const devicePickup = _.get(patientReferral, 'devicePickup') || [];
  const deviceShip = _.get(patientReferral, 'deviceShip') || [];
  if(devicePickup.length) {
    deliveryType = DELIVERY_TYPE_ENUM.PICK_UP;
  } else if(deviceShip.length) {
    deliveryType = DELIVERY_TYPE_ENUM.SHIP_TO_PATIENT;
  }
  return deliveryType;
};

const getPreviousSelectedGiveoutDevices = (patientReferral, listOfAvailableDevices = [], prevSelectedDeliveryType, iotDeviceData, loanDeviceData) => {
  const isPickup = prevSelectedDeliveryType === DELIVERY_TYPE_ENUM.PICK_UP;

  let giveoutDevices = null;
  const devicePickup = _.get(patientReferral, 'devicePickup') || [];
  const deviceShip = _.get(patientReferral, 'deviceShip') || [];
  if(!devicePickup.length && !deviceShip.length) {
    giveoutDevices = !isPickup ? _.map(listOfAvailableDevices, 'value') : [];

    // check for previous selection for clear or loaner phone
    if(iotDeviceData && iotDeviceData.length) {
      giveoutDevices.push(confirmDeviceConstants.CLEAR.value);
    }
    if(loanDeviceData) {
      giveoutDevices.push(confirmDeviceConstants.LOANER_PHONE.value);
    }
  } else if (devicePickup.length) {
    giveoutDevices = [...devicePickup];
    // don't need to show selection for deviceShip
  } else if (deviceShip.length) {
    giveoutDevices = [...deviceShip];
  }
  return giveoutDevices;
};

const getAddressComponent = (str) => {
  if(!str)
    return {};
    
  const el = new DOMParser().parseFromString(str, 'text/xml').documentElement;
  const elClass = el.className;
  let elValue = el.textContent;
  if(_.includes(elValue, 'This page contains the following errors'))
    elValue = '';
    
  return { elClass, elValue };
};

const getHasRouter = (patientReferral) => {
  if(_.isEmpty(patientReferral))
    return null;

  const devicePickup = patientReferral.devicePickup || [];
  const deviceShip = patientReferral.deviceShip || [];
  const fullList = [...devicePickup, ...deviceShip];
  const routerOrHotspot = _.filter(fullList, l => l === ROUTER_VALUE || l === HOTSPOT_VALUE);
  if(!routerOrHotspot.length)
    return null;

  return getRadioAnswer(_.includes(fullList, ROUTER_VALUE));
};

const getCuffSize = (patientReferral) => {
  if(_.isEmpty(patientReferral))
    return null;

  const devicePickup = patientReferral.devicePickup || [];
  const deviceShip = patientReferral.deviceShip || [];
  const deviceValueList = _.map(confirmDeviceConstants.DEVICES, 'value').concat([CLEAR_VALUE, PHONE_VALUE]);
  const routerHotspotValue = [ROUTER_VALUE, HOTSPOT_VALUE];
  const cuffSize = _.filter([...devicePickup, ...deviceShip], d => {
    return !_.includes([...deviceValueList, ...routerHotspotValue], d);
  })[0];
  return cuffSize;
};

const getRadioAnswer = (value) => !_.isNil(value) ? (value ? 'YES' : 'NO') : value; // true/false => YES/NO
const parseRadioAnswer = (value) => !_.isNil(value) ? (value === 'YES' ? true : false) : value; // YES/NO => true/false

const getOnlyDeviceList = (patientReferral) => {
  if(_.isEmpty(patientReferral))
    return null;

  const devicePickup = patientReferral.devicePickup || [];
  const deviceShip = patientReferral.deviceShip || [];
  const giveoutDevices = [...devicePickup, ...deviceShip];
  const cuffSize = getCuffSize(patientReferral);
  const nonDeviceList = [ROUTER_VALUE, HOTSPOT_VALUE, cuffSize];
  return _.filter(giveoutDevices, d => !_.includes(nonDeviceList, d)); 
}

export default {
  getPreviousStepIndex,
  getPreviousSelectedDeliveryType,
  getPreviousSelectedGiveoutDevices,
  getAddressComponent,
  getHasRouter,
  getCuffSize,
  getRadioAnswer,
  parseRadioAnswer,
  getOnlyDeviceList
};