import { CONSENT_TYPE } from '../../modules/patient/main/constants';
import Query from '../../modules/VideoChat/API';
import moment from 'moment-timezone';

const submitConsentForm = async ({ user, values, needRefetch, onSuccess, onError }) => {
  const { template, consentType, providers, consentProviderName, consentDate, effectiveDate } = values;
  const memberId = _.get(user, 'id');
  let res;
  const commonVariables = {
    memberId, 
    providers, 
    effectiveDate: (consentDate || effectiveDate).valueOf(), 
    timezone: moment.tz.guess(), 
    consentType
  };
  try {
    const sendConsentFormForNonApp = async () => {
      const nonAppResp = await Query.createAndSignConsentMutation({
        ...commonVariables, fileContent: template.content
      }, needRefetch);
      return _.get(nonAppResp, 'data.createAndSignConsent');
    };
    switch(consentType){
      case CONSENT_TYPE.APP:
        commonVariables.consentProviderName = consentProviderName;
        res = await Query.enrollPatientConsentMutate(commonVariables, needRefetch);
        res = _.get(res, 'data.enrollPatientConsent');
        break;
      case CONSENT_TYPE.VERBALLY:
        res = await sendConsentFormForNonApp();
        break;
      case CONSENT_TYPE.TEAM_MEMBER_DEVICE:
        res = await sendConsentFormForNonApp();
        break;
      case CONSENT_TYPE.PAPER:
        commonVariables.signatureAt = consentDate;
        res = await Query.createPaperConsentMutation(commonVariables, needRefetch);
        break;
      default:
        throw new Error(`submitConsentForm doesn't handle ${consentType}`);
    };
    return onSuccess ? onSuccess(res) : res;
  } catch(err) {
    console.error('error submitConsentForm: ', err);
    return onError ? onError(err) : err;
  }
};

export default submitConsentForm;