import {
  React, BaseComponent, IHLoading, _
} from 'ihcomponent'
import UserClass from 'modulesAll/graphql/class/User'
import ProgramClass from 'modulesAll/graphql/class/EnrolledProgram'
import Threshold from 'modulesAll/common/components/Threshold1'
import I18N from 'modulesAll/I18N'
import EnrolledProgramClass from 'modulesAll/graphql/class/EnrolledProgram';

const Component = class extends BaseComponent{
  static displayName = 'patient/programSummary/components/ProgramInfo'

  render(){
    const ready = this.props.program.loading || this.props.patientData.loading
    if(ready){
      return (
        <div className="vsm-component-patient-programInfo">
          <IHLoading />

        </div>
      )
    }

    this.program = new ProgramClass(this.props.program.program)

    return (
      <div className="vsm-component-patient-programInfo">
        {this.renderBaseInfo()}

        {this.renderPatientInfo()}

        {/*this.renderCareplan()*/}

        {this.renderProgramDevice()}
      </div>
    )
  }
  renderBaseInfo(){
    return (
      <div className="c-box v-baseinfo">
        <div className="v-title">{I18N.get('keywords.Program')} Info</div>

        <div className="c-cont">

          {this.render_b_span('Programme', this.program._get('name'))}
          {this.render_b_span('Description', this.program._get('description'))}
          {this.render_b_span('Duration', this.program.format('duration'))}
        </div>

        <div className="c-cont">
          {this.render_b_span('Care Team(s)')}

          <div className="c-b">
            <span className="s2">Care Team name 1</span>
            <span className="s2">Care Team Nurse 1</span>
          </div>

        </div>
      </div>
    )
  }

  renderPatientInfo(){

    const user = new UserClass(this.props.patientData.user)

    return (
      <div className="c-box v-patientinfo">
        <div className="v-title">Content & Patient Info</div>

        <div className="c-cont c-flex">
          <div className="c-b">
            {this.render_b_span('Name', user._get('profile.fullName'))}
            {this.render_b_span('NRIC', user.format('nric'))}
            {this.render_b_span('DOB', user._get('profile.birthday'))}
            {this.render_b_span('Gender', user._get('profile.gender'))}
          </div>

          <div className="c-b">
            {this.render_b_span('Height', '180cm')}
            {this.render_b_span('Weight', '80kg')}
            {this.render_b_span('BMI', '24.2')}
          </div>

          <div className="c-b">
            {this.render_b_span('Condition', 'Heart Failure')}
            {this.render_b_span('Ethnicity', 'Pacific IsLander')}
            {this.render_b_span('Preferred Language', 'English')}
            {this.render_b_span('WIP?', 'No')}
          </div>

          <div className="c-b">
            {this.render_b_span('Address', user.format('address'))}
            {this.render_b_span('Email', user.format('email'))}
            {this.render_b_span('Mobile', user.format('mobilePhone'))}
            {this.render_b_span('Home', user.format('homePhone'))}
          </div>
        </div>

        <div className="c-cont">
          {this.render_b_span('Consent Form Uploaded?', 'Yes')}
        </div>

      </div>
    )
  }

  renderProgramDevice(){
    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Devices</div>
        <div className="c-cont">
          {this.program.variables.devices.length > 0 ? <div className="c-l">
            <b className="b1">Devices to loan123</b>
            {
                _.map(_.sortBy(this.adminProgram.variables.devices, ['type']), (l, i)=>{
                    return <p className="s1" key={i}>{I18N.get(`Devices.${l.type}.${l.model}`)}</p>
                })
            }
            </div> : null}
          {this.render_b_span('Devices to Purchase', '')}
          {this.render_b_span('Devices Owned', '(None)')}
        </div>
        <div className="c-cont">
          {this.render_b_span('Home Delivery?', 'No')}
          {this.render_b_span('Set Number', 'AA')}
        </div>
      </div>
    )
  }

  renderCareplan(){
    const programObject = new EnrolledProgramClass(this.props.program.program);

    const thProps = {
      list: programObject.format('thresholdComponentProps')
    }

    return (
      <div className="c-box v-programdevice">
        <div className="v-title">Care Plan</div>
        <Threshold {...thProps} />
      </div>
    )
  }

  render_b_span(b, span){
    return (
      <p className="c-l">
        <b className="b1">{b}</b>
        <span className="s1">{span}</span>
      </p>
    )
  }
}

export default Component
