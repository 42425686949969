import React from 'react';
import { Checkbox } from 'antd';

const ReferAndEnrollCheckboxComponent = class extends React.Component{
	constructor(props){
		super(props);
	}

	referredByMAMDCheckbox = ()=>{
		const { setReferralFilter,referredByMAMD,remoteEnrollmentSummary } = this.props;
		const referredByMAMDCount  = _.get(remoteEnrollmentSummary,'referredByMAMD');
		return <Checkbox onChange={()=>setReferralFilter('referredByMAMD')} checked={referredByMAMD}>Referred by MA/MD ({referredByMAMDCount})</Checkbox>
	}

	enrolledByMAMDCheckbox = ()=>{
		const { setReferralFilter,enrolledByMAMD,remoteEnrollmentSummary } = this.props;
		const enrolledByMAMDCount  = _.get(remoteEnrollmentSummary,'enrolledByMAMD');
		return <Checkbox onChange={()=>setReferralFilter('enrolledByMAMD')} checked={enrolledByMAMD}>Enrolled by MA/MD ({enrolledByMAMDCount})</Checkbox>
	}

	renderCheckbox = ()=>{
		const { tabSelected } = this.props;
		const { enrolledByMAMDCheckbox, referredByMAMDCheckbox} = this;
		switch (tabSelected) {
			case 'REFERRED':
				return referredByMAMDCheckbox();
			case 'CONFIRMED':
				return enrolledByMAMDCheckbox()
			default:
				return <div></div>
		}
		return <div></div>
	}

	render() {
		return this.renderCheckbox();
	}
}

export default ReferAndEnrollCheckboxComponent;