import { React } from 'ihcomponent';
import I18N from 'modulesAll/I18N';
import AssessmentFormContainer from '../containers/AssessmentFormContainer';

export default class extends React.Component {
  static displayName = 'assessment/edit/components/EditComponent'

  componentWillMount() {
    const { loadAssessment, assessment } = this.props;

    loadAssessment(assessment);
  }
    componentWillUnmount() {
      this.props.loadAssessment();
    }

  render() {
    const { onSave, onCancel } = this.props;

    return (
      <div className="admin-assessment">
        <button className="link-like back" onClick={onCancel}>&lt; Back to {I18N.get('keywords.Assessments')}</button>
        <h5>
          <span>Edit {I18N.get('keywords.Assessment')}</span>
          <small>* Required</small>
        </h5>

        <AssessmentFormContainer onSave={onSave} onCancel={onCancel} />
      </div>
    );
  }
}
