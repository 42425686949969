import types from 'ProgramEditModule/Device/constants/DeviceFormConstants'
import { commonActions } from 'libModule/helpers/action-helpers'

const commonActionsObject = commonActions(types, [
  'resetForm',
  'updateForm',
  'onFieldsChange'
])

export default {
  ...commonActionsObject,
}
