import gql from 'graphql-tag';
import { HandoutSchema } from '../schema/Handout';
import { NutritionSchema } from '../schema/Nutrition';

// memberProfile: { birthday:required, nutrition}
export default gql`
  mutation editUserNutrition($id: EID!, $memberProfile: InputMemberProfile){
    editUser(id: $id, memberProfile: $memberProfile){
      id
      profile {
        ...on MemberProfile {
          ${NutritionSchema}
          ${HandoutSchema}
        }
      }
    }
  }
`;