import React from 'react';
import { getTranslate } from '../API/index';
const TranslateContainer = class extends React.Component{
    constructor() {
        super();
        this.state = {
            str:'',
            translatedText:''
        }
    }
    handleTranslate = (str)=>{
        getTranslate(str,'en').then(res=>{
            this.setState({
                translatedText: res.data.translations[0].translatedText
            })
        })
    }
    translate = ()=>{
        const { str,translatedText } = this.state;
        const div = <input onChange={(event => this.setState( {str:event.target.value}) )}/>
        const btn = <button onClick={()=> this.handleTranslate(str) }>translate</button>
        const translatedTextDiv = translatedText ? translatedText : '';
        return <div>{div}{btn}{translatedTextDiv}</div>
    }

    render() {
        return (
            <div>
                {this.translate()}
            </div>
        );
    }
};

export default TranslateContainer;
