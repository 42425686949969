import SingleMeasureComponent from './SingleMeasureComponent';
import React from "react";
import '../style/index.scss';
import { Button } from 'antd';
import { closeModal } from 'layoutModule/actions/MainModal'
import {compose} from "react-apollo/index";
import {connect} from "react-redux";

const SingleMeasureRenderComponent = class extends React.Component {
    render(){
        const {data,title,handleData,patientId,beforeMeal,showSingleReading,date,type} = this.props;

        return <div className='row'>
                <div className='col-lg-12' style={{fontWeight:'550',textAlign:'start'}}>
                    <span>{title}</span>
                </div>
                <SingleMeasureComponent
                    data={ data }
                    title={ title }
                    handleData={ handleData }
                    beforeMeal={ beforeMeal }
                    patientId = { patientId }
                    date = { date }
                    type={type}
                    showSingleReading={ showSingleReading }
                />
                <div className='col-lg-12' style={{textAlign:'start'}}>
                    <Button  onClick={()=>this.props.closeModal()} type="primary"
                             className='logBookCloseButton'
                             style={{ height:28,width:80,marginRight:25 }}>
                        Close
                    </Button>
                </div>
               </div>
    }

}

const mapToDispatch = (dispatch)=> {
    return {
        closeModal:()=>dispatch(closeModal()),
    }
}

export default compose(connect(null,mapToDispatch))(SingleMeasureRenderComponent);
