import PropTypes from 'prop-types';
import React from 'react';
import { IHSearchTable, IHButton } from 'ihcomponent'
import moment from 'moment'
import { goPath, showMonthsOrDays } from 'libModule/utils'
import { getTableProps, noDataElem, commonTablePropTypes } from 'libModule/helpers/component-helpers'
import { createAuditLog } from 'libModule/utils/auditLog'
import I18N from 'modulesAll/I18N';

const MyProgramTable = (props) => {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Health Condition',
        key: 'healthCondition',
        dataIndex: 'healthCondition',
      },
      {
        title: `${I18N.get('keywords.Program')} Started At`,
        key: 'startDate',
        dataIndex: 'startDate',
        sorter: true,
        render: (value, row, index) => {
          return value? moment(value).format('MMM-DD-YYYY') : ''
        }
      },
      {
        title: `${I18N.get('keywords.Program')} Duration`,
        key: 'duration',
        dataIndex: 'duration',
        render: (duration, row, index) => {
          const {startDate, endDate} = row
          if(!startDate || !endDate) {
            if (duration) {
              return `${duration.length} ${duration.calendar.toLowerCase()}${duration.length === 1 ? '' : 's'}`
            } else {
              return ''
            }
          }
          const diff = moment(endDate).diff(moment(startDate), 'days')
          return showMonthsOrDays(diff,'text')
        }
      },
      {
        title: `Days left in ${I18N.get('keywords.Program')}`,
        render: (row) => {
          if (row.endDate && row.endDate >= moment()) {
            const diff = moment(row.endDate).diff(moment(), 'days')
            return diff === 1? '1 day': diff + ' days'
          }
          return null
        }
      },
      {
        title: 'Actions',
        render: (row) => {
          const bp = {
            label: 'View >',
            size: 'small',
            bsStyle: 'primary',
            onClick: () => {
              goPath(`/myprogrammes/${row.id}`)
            },
            className: 'table-action-button'
          }
          return <IHButton {...bp} />
        }
      }
    ]

    const title = `My ${I18N.get('keywords.Programs')}`

    const tableProps = {
      ...getTableProps(props, {columns, title}),
      onSearch: null
    }

    return (
      <div className="patient-task-table">
        <IHSearchTable {...tableProps} noDataElem={noDataElem} style={{height: '28em'}} />
      </div>
    )
}

MyProgramTable.propTypes = commonTablePropTypes

export default MyProgramTable
