import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';

import {
  renderAssignee,
  renderProvider,
  goToPatientProfile,
  getRPMReviewStatus,
} from '../helpers';
import { renderSelfTag } from '../../visitsPage/helpers';
import useAssigneeFilters from '../helpers/useAssigneeFilters';
import useProviderFilters from '../helpers/useProviderFilters';

import '../styles/ReviewPatientTableComponent.scss';

const ReviewPatientTableComponent = ({
  tableData = [], // array of user data
  isLoadingTableData,
  filteredValues,
  pagination,
  onChange,
}) => {
  const {
    MDFilters
  } = useProviderFilters();
  const {
    CAFilters,
    RDHCFilters,
  } = useAssigneeFilters();

  const handleTableOnChange = (p, f) => {
    onChange(p, f);
  };

  return (
    <div className="review-patient-table-component">
      <Table
        dataSource={tableData}
        loading={!!isLoadingTableData}
        columns={[
          {
            key: 'name',
            title: 'Name',
            width: '20%',
            render: (__, record) => {
              const { id, profile, organization } = record || {};
              if (!profile) return '--';
              const { isSelfEnrolled, fullName } = profile;
              return (
                <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    goToPatientProfile({ organization, patientId: id });
                  }}
                >
                  {fullName}
                  {''}
                  {isSelfEnrolled ? renderSelfTag() : null}
                </Button>
              );
            }
          },
          {
            key: 'organization',
            title: 'Organization',
            width: '20%',
            dataIndex: 'organization.name',
          },
          {
            key: 'doctorId',
            title: 'Provider',
            width: '18%',
            dataIndex: 'profile.doctor',
            filters: MDFilters,
            filteredValue: filteredValues.doctorId,
            render: (doctor) => renderProvider(doctor),
          },
          {
            key: 'assignedToRD',
            title: 'Assigned RD/HC',
            width: '18%',
            dataIndex: 'assignees',
            filters: RDHCFilters,
            filteredValue: filteredValues.assignedToRD,
            render: (assignees) => renderAssignee('assignedToRD', assignees),
          },
          {
            key: 'assignedToCA',
            title: 'Assigned CA',
            width: '18%',
            dataIndex: 'assignees',
            filters: CAFilters,
            filteredValue: filteredValues.assignedToCA,
            render: (assignees) => renderAssignee('assignedToCA', assignees),
          },
          {
            key: 'reviewStatus',
            title: 'Status',
            dataIndex: 'patientStatus.lastReviewFinishedIn',
            render: getRPMReviewStatus
          },
        ]}
        onChange={handleTableOnChange}
        pagination={pagination}
        rowKey={(record) => record.id}
        size="small"
      />
    </div >
  );
};

ReviewPatientTableComponent.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    profile: PropTypes.object,
    organization: PropTypes.object,
  })),
  isLoadingTableData: PropTypes.bool,
};
export default ReviewPatientTableComponent;