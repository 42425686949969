import React, { Component } from 'react';
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Link } from 'react-router'
// import editProviderProfile from 'graphqlModule/mutation/editProviderProfile'
import editUser from 'graphqlModule/mutation/editUser';
import { providerProfile as userGQL } from 'graphqlModule/user'
import Client from 'libModule/gqlClient';
import { goPath, FormHelper, convertToFieldsValue, getRouterParam, setAsyncRouteLeaveHook, GQLHelper } from 'libModule/utils'
import { IHBaseFormWithRow, IHButton, message } from 'ihcomponent'
import PropTypes from 'prop-types';
import { getFormProps } from 'providerModule/ProviderEdit/components/formData/ProviderEditForm'
import * as actions from 'providerModule/ProviderEdit/ProviderEditDuck'
import { setBreadCrumb } from 'modulesAll/layout/actions/Nav'
import { modalAction } from 'modulesAll/common/actions'
import UserClass from 'modulesAll/graphql/class/User'
import I18N from 'modulesAll/I18N'
import { createAuditLog } from 'libModule/utils/auditLog'
import upsertUserRoles from 'graphqlModule/mutation/upsertUserRoles';

class Container extends Component {
  static displayName = 'provider/ProviderEdit/containers/ProviderEditContainer'

  componentWillMount() {
    setAsyncRouteLeaveHook(this.context.router, this.props.route, this.routerWillLeave.bind(this))

    const { userData, updateForm } = this.props
    const initData = this.convertUserDataToFormData(userData)
    const fieldsValue = convertToFieldsValue(initData)
    updateForm(fieldsValue)
  }
  componentDidMount() {
    const { userData, setBreadCrumb } = this.props
    if (userData) {
      setBreadCrumb(`Providers / ${userData.profile.fullName} / Edit`)
    }
  }
  componentWillUnmount() {
    this.props.resetForm()
  }

  routerWillLeave() {
    const { openConfirmModal } = this.props
    const submit = this.submit.bind(this)
    return new Promise((resolve, reject) => {
      if (this.props.isFormSaved) {
        resolve(true)
        return
      }
      openConfirmModal(resolve, submit)
    })
  }

  convertUserDataToFormData() {
    const { userData } = this.props
    const userClassData = new UserClass(userData)
    const { variables: { email, phone, username, allRoles } } = userClassData;
    const role = allRoles && allRoles.find( val => { return val.category === 'PROVIDER' });
    const mobile = phone && phone.length > 0 ? phone[0].number : '';
    const mobileCountryCode = phone.length > 0 ? phone[0].countryCode : '+1';
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const institution = _.get(currentUser,'selectedRole.organization.id') ||'';
    // const institution = _.get(currentUser, 'allRoles.0.organization.id') || '';
    // const identification = userData.identification && userData.identification[0]
    // const identificationType = _.get(identification, 'type.code')
    // const identificationType = 'SP' // SP & BP is for NRIC
    // const identificationValue = _.get(identification, 'value')
    // const email = userClassData.format('email')

    // const mobileArray = userClassData.format('mobilePhone').split(' ').splice(1);
    // const mobile = mobileArray.length > 1 ? mobileArray.join('') : ' ';
    // // const mobileCountryCode = mobileArray.length > 1 ? mobileArray[0] : '+1'
    // const mobileCountryCode = '+1';

    // const sharedMobile = userClassData.format('sharedMobile')
    // const personalMobile = userClassData.format('personalMobile')

    // const institution = userClassData.format('institution')

    // refactored from allRole[0] to selectedRole(IH-1052)

      const { firstName, lastName, employeeId, department, specialty } = _.get(userClassData, 'variables.profile')

    const initData = {
      id: userData.id,
      identificationValue: employeeId,
      firstName,
      lastName,
      role: role.name,
      mobile,
      mobileCountryCode,
      email: email && email.length > 0 ? email[0].address : '',
      institution,
      department,
      specialty,
      username
    }
    return initData
  }

  render() {
    const body = this.renderBody()
    const footer = this.renderFooter()

    return (
      <div className='vsm-provider-new-page vsm-form-container'>
        {this.renderBackLink()}
        <div className='provider-new-form v-form-required'>
          {body}
          {footer}
        </div>
      </div>
    )
  }

  renderBackLink(){
    return (
      <div className="v-link">
        <Link to={`/providers/profile/${this.props.id}`}>
          <i className="fa fa-angle-left" />
          <span>{I18N.get('demographics.backToDemographics')}</span>
        </Link>
      </div>
    )
  }
  renderBody() {
    const { onFieldsChange, userData } = this.props
    const initData = this.convertUserDataToFormData(userData)
    const fieldsValue = convertToFieldsValue(initData)
    const formProps = getFormProps({ onFieldsChange, fieldsValue })
    return <IHBaseFormWithRow {...formProps} ref={refNode => { this.baseForm = refNode }} />
  }
  renderFooter() {
    const styles = {
      footer: {
        display: 'flex',
        flexBasis: '100%',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 5,
        marginTop: 10
      }
    }
    const buttonProps = {
      cancel: {
        onClick : (e)=>{
          goPath(`/providers/profile/${this.props.id}`)
        },
        bsStyle: 'default',
        label: 'Cancel'
      },
      done: {
        onClick : (e)=>{
          e.preventDefault();
          this.submit()
        },
        bsStyle: 'primary',
        label: 'Save',
        style: {
          marginRight: 10
        }
      }
    }

    const footer = (
      <div style={styles.footer}>
        <IHButton {...buttonProps.done} />
        <IHButton {...buttonProps.cancel} />
      </div>
    )
    return footer
  }

  getMutateVariable(data) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    const appRoles = JSON.parse(sessionStorage.getItem('appRoles'));
    const roleOrg = appRoles[Object.keys(appRoles).find(key => key.toUpperCase().indexOf(":" + data.role.toUpperCase()) >= 0)];

    // TODO: check if new role is present
    Client.mutate({
      mutation: upsertUserRoles,
      variables: {
        id: this.props.id,
        roles: [roleOrg],
      }
    }).catch(err => {
      console.error(err);
    })


    let phone = [];
    const result = {
      id: this.props.id,
      commonProfile : {
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim()
      },
      employeeProfile: {
        department: data.department,
        specialty: data.specialty,
        employeeId: data.identificationValue
      }
    }
    result.email = data.email && data.email.trim();
    result.username = data.username && data.username.trim();

    if (data.mobile) {
      phone.push({
        countryCode: data.mobileCountryCode ? parseInt(data.mobileCountryCode, 10) : '',
        number: data.mobile ? data.mobile.trim() : '',
        type: 'MOBILE'
      });
    }
    result.phone = phone;
    return result
  }

  async submit() {
    const { mutate, fieldsValue, handleFormSaved, openErrorModal } = this.props
    const formValue = FormHelper.getValue(fieldsValue)
    let isFormValid = true
    const callback = (result, value) => {
      if (!result) {
        isFormValid = false
      }
    }

    await this.baseForm.getFormData(callback)
    if (!isFormValid) {
      message.error('Oops, please check the errors below')
      return
    }

    const variables = this.getMutateVariable(formValue)
    try {
      await handleFormSaved()
      const res = await mutate({ variables })

      createAuditLog({
        action:I18N.get('auditLog.admin.provider.edit'),
        details: formValue,
        request: variables,
        response: res
      })

      if (res.data.editUser){

        message.success('Edit provider profile success');

        // 2/26/18 - JV - Update user data after health conditions are added (IH-208)
        const variables = {
          id: this.props.id
        };
        Client.query({
          query: userGQL,
          variables: { ...variables },
          fetchPolicy: 'network-only'})
          .then( res => goPath(`/providers/profile/${this.props.id}`))
          .catch( err => console.log('----- JENNA! err: ', err))
          .finally( () => goPath(`/providers/profile/${this.props.id}`));
      }
    }
    catch (e) {
      openErrorModal(GQLHelper.formatError(e))
      createAuditLog({
        action:I18N.get('auditLog.admin.provider.edit'),
        details: formValue,
        request: variables,
        response: e,
        success: false
      })
    }
  }
}

Container.contextTypes = {
  router: PropTypes.object.isRequired
}

const ContainerWithData = graphql(editUser)(Container)

const mapState = (state, ownProps) => {
  const id = getRouterParam(state, 'providerId')
  return {
    ...state.provider.ProviderEdit,
    id
  }
}

const mapDispatch = (dispatch) => {
  return {
    onFieldsChange: (fieldsProps, changedFields) => dispatch(actions.onFieldsChange(fieldsProps, changedFields)),
    handleFormSaved: () => dispatch(actions.handleFormSaved()),
    updateForm: (initData) => dispatch(actions.updateForm(initData)),
    resetForm: () => dispatch(actions.resetForm()),
    setBreadCrumb: (breadCrumb) => dispatch(setBreadCrumb(breadCrumb)),
    openConfirmModal: (resolve, submit) => dispatch(modalAction.openConfirmModal(resolve, submit)),
    openErrorModal: (errorMessage) => dispatch(modalAction.openErrorModal(errorMessage)),
  }
}

Container.propTypes = {
  id: PropTypes.string,
  onFieldsChange: PropTypes.func,
  resetForm: PropTypes.func,
  handleFormSaved: PropTypes.func,
  updateForm: PropTypes.func,
  userData: PropTypes.object,
  route: PropTypes.object,
  mutate: PropTypes.func,
  fieldsValue: PropTypes.object,
  isFormSaved: PropTypes.bool,
  setBreadCrumb: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openErrorModal: PropTypes.func,
}

export default connect(
  mapState, mapDispatch
)(ContainerWithData)
