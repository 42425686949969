import React from "react";
import { Modal, Button, Icon } from "antd";
import I18N from "../../../I18N";
import "../css/leaveModal.scss";

export default (props) => {
    const { visible, handleCancel, handleOk } = props;
    const modalContents = I18N.get('monthlyReviewPlan.leaveModal');

    const renderBody = ()=>{
        return <div className={'bodyWrapper'}>
                <p className='header'>{_.get(modalContents, 'header')}</p>
                <p className='note'>{_.get(modalContents, 'note')}</p>
            </div>
    }

    const renderMTPRButton = () => {
        return <div className="startBtn">
                <Button onClick={handleOk} type="primary">{_.get(modalContents, 'startBtnText')}</Button>
            </div>
    }

    const renderCancelButton = () => {
        return <div className="cancelBtn">
                <Button onClick={handleCancel} type="link">{_.get(modalContents, 'cancelBtnText')}</Button>
            </div>
    }
    const renderFooter = () => {
        return <div className="footer">
            { renderMTPRButton() }
            { renderCancelButton() }
        </div>
    }


    return <Modal className={"leaveModal"}
                visible={visible}
                closable={false}
                footer={null}
                width={484}
                >
                { renderBody()}
                {renderFooter()}
            </Modal>
}
