import VitalFormTypes from 'ProgramIncompleteModule/Careplan/constants/VitalFormConstants'
import { convertToFieldsValue } from 'libModule/helpers/reducer-helpers'
import CareplanCommonTypes from 'ProgramIncompleteModule/Careplan/constants/CommonConstants'
import CareplanCommonActions from 'ProgramIncompleteModule/Careplan/actions/CommonActions'
import AlertListTypes from 'ProgramIncompleteModule/VitalAlert/constants/AlertListConstants'
import AlertFormTypes from 'ProgramIncompleteModule/VitalAlert/constants/AlertFormConstants'
import { getInitAlertFormData } from 'modulesAll/utils/helpers/task'
import AlertFormContainer from 'ProgramIncompleteModule/VitalAlert/containers/AlertFormContainer'
import CommonActions from 'ProgramIncompleteModule/Common/actions/CommonActions'

const updateForm = (rowData, isEditMode)=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormTypes.UPDATE_FORM,
      fieldsValue: convertToFieldsValue(rowData),
      isEditMode
    })
    dispatch({
      type: AlertListTypes.INIT_DATA,
      initFormData: getInitAlertFormData(rowData, AlertFormContainer, AlertListTypes.REMOVE_TAB, AlertFormTypes.RESET_FORM, dispatch)
    })
  }
}

const resetForm = ()=> {
  return (dispatch, getState) => {
    dispatch({
      type: VitalFormTypes.RESET_FORM
    })
  }
}

const goToCareplanVitalForm = () => {
  return (dispatch, getState) => {
    dispatch(CommonActions.setTabsStep('careplan'))
    dispatch(CareplanCommonActions.changePage(CareplanCommonTypes.CAREPLAN_VITAL_FORM_PAGE))
  }
}

export default {
  updateForm,
  resetForm,
  goToCareplanVitalForm
}
