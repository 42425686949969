import gql from "graphql-tag";
import VisitSchema from '../schema/Visit';

export default gql `
mutation editVisit ($id:EID!,$type:VisitTypeEnum,$appointmentAt:Date,$appointmentTo:Date,$providers:[EID],$doctorNotes:String,
                    $assignees:AssigneeInput,
                    $reason:String,$programIds:[EID],$checkinAt:Date,$checkoutAt:Date,$deviceRecordCount:InputUserDeviceRecordCount,
                    $inventoryCount: InputUserDeviceRecordCount,
                    $height:HTValue,$weight:HSValue,$waistHipRatio:Float,$bgMeasurement:VisitBgMeasureInput,$bpMeasurement:VisitBpMeasureInput
                    $eyeExam: VisitTaskStatus,$doctorSession: VisitTaskStatus,$cdeSession: VisitTaskStatus,$technicalSetup: VisitTaskStatus
                    $footExam: VisitTaskStatus,
                    $urineTest: VisitTaskStatus
                    $mpTest:VisitTaskStatus,
                    $a1cTest:Float,
                    $spo2:POValue,
                    $remoteTask:InputRemoteTask,
                    $temperature:TMValue,
                    $shouldSeeDoctor:Boolean,
                    $visitRoles:[String],
                    $addToContactsStatus: VisitTaskStatus,
                    $autoUpdateStatus: VisitTaskStatus,
                    $appIsUptoDate: VisitTaskStatus,
                    $EMRAttached: Boolean,
                    $userDeviceRecords: InputUserDeviceRecords
                    $conditionsList:InputConditionsListType,
                    $userLanguage:Language,
                    $category:VisitCategoryEnum,
                    $userAppLanguage: Language,
                    $userTechLevel: TechLevel,
                    $A1C:InputA1CObject,
                    $needInHouseA1C: Boolean
                    $associatedVisitId:EID,
                    $remoteStatus: Boolean
                    $declineToCheckVital: Boolean
                    $unableToCheckVital: Boolean
                    $TechOnBoard: Boolean
                    ){
    editVisit(id:$id,type:$type,assignees:$assignees,appointmentAt:$appointmentAt,appointmentTo:$appointmentTo,providerIds:$providers,reason:$reason,associatedVisitId:$associatedVisitId,
              programIds:$programIds,checkinAt:$checkinAt,checkoutAt:$checkoutAt,shouldSeeDoctor:$shouldSeeDoctor,visitRoles:$visitRoles,deviceRecordCount:$deviceRecordCount,inventoryCount:$inventoryCount
              conditionsList:$conditionsList,doctorNotes:$doctorNotes,category:$category,remoteStatus:$remoteStatus,remoteTask:$remoteTask,
              details:{addToContactsStatus:$addToContactsStatus, EMRAttached: $EMRAttached,
                autoUpdateStatus:$autoUpdateStatus,spo2:$spo2,temperature:$temperature,
                appIsUptoDate:$appIsUptoDate,
                TechOnBoard:$TechOnBoard,
                height:$height,weight:$weight,waistHipRatio:$waistHipRatio,bgMeasurement:$bgMeasurement,bpMeasurement:$bpMeasurement,
              eyeExam:$eyeExam,footExam:$footExam,mpTest:$mpTest,urineTest:$urineTest,doctorSession:$doctorSession,cdeSession:$cdeSession,technicalSetup:$technicalSetup,
              a1cTest:$a1cTest
              },userDeviceRecords:$userDeviceRecords,userLanguage:$userLanguage, userAppLanguage:$userAppLanguage, userTechLevel:$userTechLevel,A1C:$A1C, needInHouseA1C: $needInHouseA1C,
              declineToCheckVital: $declineToCheckVital,
              unableToCheckVital: $unableToCheckVital){
      ${VisitSchema}
    }
}`

