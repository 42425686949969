import { React, IHRadio, IHButton, _ } from 'ihcomponent';
import { QUESTION_TYPE_RADIO, renderValidatableInput, getQuestionType } from '../../util';

export default class extends React.Component {
  static displayName = 'assessment/edit/components/QuestionComponent'

  constructor(props) {
    super(props);

    this.onQuestionChange = this.onQuestionChange.bind(this);
  }

  onQuestionChange(newQuestion) {
    const { index, changeField } = this.props;
    changeField(`questions[${index}]`, newQuestion);
  }

  renderQuestonTitle() {
    const { question } = this.props;

    return (
      <div>
        {renderValidatableInput(question.text, {
          onChange: ({ target }) => this.onQuestionChange({ ...question, text: target.value }),
          className: 'input-question',
          type: 'textarea',
          rows: 4,
        })}
      </div>
    );
  }

  renderAnswer() {
    const { question } = this.props;
    const Comp = _.get(getQuestionType(question.type.__value), 'component');

    if (Comp) {
      return (
        <div className="answer-wrapper">
          <h5>Answers</h5>
          <Comp
            options={question.options}
            onChange={options => this.onQuestionChange({ ...question, options })}
          />
        </div>
      );
    }
  }

  renderSavedQuestion() {
    const { index, question, removeQuestion, questionNo } = this.props;

    return (
      <div className="question">
        <h5>
          <span>Question {questionNo + 1}</span>
          <small>{getQuestionType(question.type.__value).display}</small>
        </h5>

        {this.renderQuestonTitle()}
        {this.renderAnswer()}

        <div className="btn-wrapper">
          <div className="right">
            <IHButton className="btn-remove" onClick={removeQuestion} label="Delete Question" />
          </div>
        </div>
      </div>
    );
  }

  renderDraftQuestion() {
    const { question, removeQuestion, changeType, saveQuestion } = this.props;
    const { type } = question;

    return (
      <div className="question">
        <h5>Question Type</h5>
        <IHRadio
          type="radioButton" option={QUESTION_TYPE_RADIO}
          value={type.__value} size="large"
          onChange={({ target }) => changeType(target.value)}
        />

        {type.__value ? <h5>Question</h5> : null}
        {type.__value ? this.renderQuestonTitle() : null}
        {type.__value ? this.renderAnswer() : null}

        <div className="btn-wrapper">
          <div className="right">
            <IHButton label="Done" bsStyle="primary" disabled={!type.__value} onClick={saveQuestion} />
            <IHButton label="Cancel" bsStyle="default" onClick={removeQuestion} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.question._status !== 'SAVED'
      ? this.renderDraftQuestion()
      : this.renderSavedQuestion();
  }
}
