import React from 'react';
import { Row, Button } from 'antd';
import IcdCodeCondition from '../component/IcdCodeCondition';
import I18N from '../../I18N';
import { onlyHasHLD, getPatientConditions } from '../helper';
import { DIAGNOSIS_SOURCE } from '../constant/programEligibility';

import './referralCodeConditionContainer.styles.scss';

const ReferralCodeConditionContainer = ({
  form,
  handleConditionSelect,
  disabled,
  diagnosisSourceInitialValue = DIAGNOSIS_SOURCE.CONDITION,
  icdCodesInitialValue,
  billableIcdCodesInitialValue,
  conditionsInitialValue,
  isConfirmed,
  setIsConfirmed,
  selectedConditions,
  confirmButtonDisabled = false,
  onConfirm
}) => {
  const diagnosisSource = form.getFieldValue('diagnosisSource') || diagnosisSourceInitialValue;
  const selectedIcdCodes = form.getFieldValue('healthConditions') || icdCodesInitialValue;
  const selectedBillableIcdCodes =  form.getFieldValue('billableHealthConditions') || billableIcdCodesInitialValue;
  const selectedPatientConditions = getPatientConditions(diagnosisSource, selectedConditions, selectedBillableIcdCodes);
  const hasHldOnly = onlyHasHLD(selectedPatientConditions);

  const checkIfHasSelection = () => {
    if (diagnosisSource === DIAGNOSIS_SOURCE.ICD_CODE) {
      return !_.isEmpty(selectedIcdCodes);
    } else {
      return !_.isEmpty(selectedPatientConditions);
    }
  };

  const _handleConditionSelect = (value) => {
    if (!isConfirmed) return;
    const { conditions = [], billableIcdCodes = [] } = value || {};
    const newPatientConditions = getPatientConditions(diagnosisSource, conditions, billableIcdCodes);
    handleConditionSelect(newPatientConditions);
  };

  const switchDiagnosisSource = (newDiagnosisSource) => {
    form.setFieldsValue({ diagnosisSource: newDiagnosisSource });
    // reset when switching between diagnosis sources
    setIsConfirmed(false);
  };

  const handleConfirm = async ()=>{
    await handleConditionSelect(selectedPatientConditions);
    setIsConfirmed(true);
    if (onConfirm) onConfirm();
  };

  const renderConfirmButton =  () => {
    const isConfirmDisabled = !checkIfHasSelection() || confirmButtonDisabled;
    return hasHldOnly 
      ? I18N.get('referAndEnroll.hldOnlyNote')
      : (
        !isConfirmed 
        && <Button 
          type="primary" 
          className="new-style" 
          onClick={handleConfirm} 
          disabled={isConfirmDisabled}
        >
          Confirm
        </Button>
      );
  };

  return (
    <div className={`referralCodeConditionContainer ${isConfirmed ? 'isConfirmed' : ''}`}>
      <IcdCodeCondition 
        form={form}
        onChange={_handleConditionSelect}
        disabled={disabled}
        icdCodesInitialValue={icdCodesInitialValue}
        billableIcdCodesInitialValue={billableIcdCodesInitialValue}
        conditionsInitialValue={conditionsInitialValue}
        diagnosisSource={diagnosisSource}
        switchDiagnosisSource={switchDiagnosisSource}
      />
      {!isConfirmed && <Row style={{ marginTop: 30 }}>{renderConfirmButton()}</Row>}
      {
        form.getFieldDecorator('diagnosisSource', {
          initialValue: diagnosisSourceInitialValue
        })(<div style={{ display: 'none'}}></div>)
      }
    </div>
  );
};

export default ReferralCodeConditionContainer;
