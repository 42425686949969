import gql from "graphql-tag";


export default gql`
query getVirtualVisitTeam($visitId: EID!){
  getVirtualVisitTeam(visitId:$visitId){
    user{
     id 
     profile{
        fullName
        avatar{
          thumbnailLink
        }
     }
     selectedRole{
            name
    }
    }
    roleName
    isOnline
    isHost
    invited
    isBusy
  }
}
`
