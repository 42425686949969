import { React, BaseComponent, _ } from './util'
import IHTable from './IHTable'
import IHInput from './IHInput'
import IHDateRangePicker from './IHDateRangePicker'
import PropTypes from 'prop-types';

const Component = class extends BaseComponent{

    defineBaseClassName(){
      return 'IH-SearchTable'
    }

    render(){
        const p = this.getClassAndStyleProp()
        return (
            <div {...p}>
                <div className="ih-dd">
                    { this.renderTitle() }
                    <div className='task-table-top-right-tools'>
                      {/* TODO: Use map to render all injected tools */}
                      {this.props.showSearchInput === false ? '' : this.renderSearchInput()}
                      {this.renderFilterByDateRange()}
                      {this.renderActions()}
                      {this.renderCustomizedHeaderComponent()}
                    </div>
                </div>
                {this.renderTable()}

            </div>
        )
    }

    renderTitle(){
        return <p className="ih-tlt">{this.props.title}</p>
    }

    renderActions() {
      return this.props.actions ? this.props.actions : ''
    }

    renderCustomizedHeaderComponent() {
      const { CustomizedHeaderComponent } = this.props
      return CustomizedHeaderComponent && CustomizedHeaderComponent
    }

    renderSearchInput(){
        const {onSearch, onSearchEnter, searchValue} = this.props;
        if(!onSearch) {
            return null
        }

        const p = {
            placeholder : this.props.inputPlaceholder || 'Search',
            onChange : (e)=>{
              const v = e.target.value
              onSearch && onSearch(v)

              // If no search text, will trigger onSearchEnter to show all rows
              // if(v === "") {
              //   onSearchEnter && onSearchEnter()
              // }
            },
            /* Reset the search keyword to match current search result
            TODO: Need to pass prevSearch as prop to all the tables
            onBlur: e => onSearch && prevSearch !== undefined && onSearch(prevSearch)
            */
        }
        if (!_.isUndefined(searchValue)) {
            p.value = searchValue;
        }

        const onSubmit = (e) => {
          e.preventDefault()
          onSearchEnter && onSearchEnter()
        }

        return (
            <form className="ih-searchbox" onSubmit={onSubmit}>
                <i className="fa fa-search"></i>
                <input ref={refNode => { this.input = refNode }}
                       autoFocus={ typeof this.props.autoFocus === "boolean"? this.props.autoFocus : false }
                       className="html-input" {...p} />
            </form>
        )
    }

    renderFilterByDateRange() {
      const { filterByDateRange } = this.props;
      return (
        filterByDateRange &&
        <IHDateRangePicker format='MMM-DD-YYYY' onChange={ (date, dateString) => filterByDateRange(date) } />
      );
    }

    renderTable(){
        const p = _.omit(this.props, ['title', 'onSearch', 'className', 'style'])

        return <IHTable {...p} />
    }

    componentDidMount(){
        if(this.props.initSearchValue && _.isUndefined(this.props.searchValue)){
            this.input.value = this.props.initSearchValue
        }
    }
}

Component.propTypes = {
    title : PropTypes.string,
    onSearch : PropTypes.func,
    initSearchValue : PropTypes.string
}

export default Component
