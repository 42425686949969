import {
 moment, _
} from 'ihcomponent'
import { DataHelper } from 'libModule/utils';

const getPreviousKeyInDevicesInfo = (ep) => {

  if (ep && ep.devices && ep.devices.length) {
    return ep.devices.filter(d =>( d.status === 'VENDOR_LOAN' && d.address && ( (d.condition && d.condition.length) || (d.remark && d.remark.length))  ) )
    .map(d => ({deviceId: d.address, select: d.condition, remark: d.remark }) )
  }
  return [];
}


const REPLACE_OR_RETURN = {
  INITIAL_STATE : 1,
  FORM_DETAILS : 2,
  SUBMITTED: 3
}


const filterOnlyUniqueDeviceAddress = (devices) => {
  //because BP.BP5 and HR.BP5 are using same device, thus same address;
  const uniqDevices = {};
  const uniqDevicesArray = [];
  devices.map( e =>  {
    if( (e.status === 'PATIENT_OWNED') && !uniqDevices[`${e.model}`]  ){
        const patientOwnedDevice = Object.assign({}, e, {address: `${e.type}_${e.model}`});
        uniqDevices[`${e.model}`] = true;
        uniqDevicesArray.push(patientOwnedDevice);
    }
    else if( (e.status === 'VENDOR_LOAN' || e.status === 'RETURNED' ) && e.address && e.address.length && !uniqDevices[e.address]) {
      uniqDevices[e.address] = true;
      uniqDevicesArray.push(e);
    }
  })

  return uniqDevicesArray;
}


const disabledTooOldDate = (startDate) => (currentDate) => {
  return currentDate && ( currentDate.valueOf() <  moment(startDate).toDate() || currentDate.valueOf() > moment().add(3,'day').toDate());
}


const prepareFinalValuesWhenProcessReplaceOrReturnDevices = (ep, devicesStatus) => {
  const replaceDevices  = ( _.get(ep, 'devices', []) ).filter( device => device.status === 'VENDOR_LOAN' )
        .map( e => {
        const userInput = _.find(devicesStatus, f => f.deviceId === e.address);
        //default values

    const addDefaultValueObj = Object.assign({},e);
    addDefaultValueObj.select = 'NORMAL';
    addDefaultValueObj.remark ='';
    return ( Object.assign({}, _.merge( addDefaultValueObj,  userInput) ))

  } );

  return replaceDevices;
}

const populateInitFormData = (isDischarged, currentUserFullName, ) => {

    const momentDate = moment();
    return isDischarged ?
    {
    //this is for return devices
    processedBy: {
    name: 'processedBy',
    value: currentUserFullName,
    },
    processedDateAndTime: {
      name: 'processedDateAndTime',
      value: momentDate,
    },
    returnedDateAndTime: {
      name: 'returnedDateAndTime',
      value: momentDate,
    },

    } : {
      //this is for replace devices
      replacedBy: {
        name: 'replacedBy',
        value: currentUserFullName,
      },
      replacedDateAndTime: {
        name: 'replacedDateAndTime',
        value: momentDate,
      },
    }

}


const populateFormData = ({onFieldsChange, isDischarged}) => {

  onFieldsChange({},
    populateInitFormData(isDischarged, DataHelper.getCurrentUser().profile.fullName)
  )
}

const getLoanForm = (ep) => {
  const { forms } = ep;
  let loanForm= false;
  if (_.size(forms) > 0) {
    loanForm = _.find(forms, (form) => {
      return form.type === 'LOAN_FORM'
    } );
  }
  return loanForm;
}

export {
  getPreviousKeyInDevicesInfo,
  REPLACE_OR_RETURN,
  filterOnlyUniqueDeviceAddress,
  disabledTooOldDate,
  prepareFinalValuesWhenProcessReplaceOrReturnDevices,
  populateInitFormData,
  populateFormData,
  getLoanForm
};
