
import {
  React, IHLoading, IHButton, message, _, ND,
} from '../IHComponents/util';

import PropTypes from 'prop-types';

import * as R from 'ramda';

import './WeeklyMealSchedule.scss';

import { WeeklyMealScheduleInputGrid } from './WeeklyMealScheduleInputGrid';
import { WeeklyMealScheduleEditor } from './WeeklyMealScheduleEditor';

import FaMoon from 'react-icons/lib/fa/moon-o';
import FaBed from 'react-icons/lib/fa/bed';

import { compose, withProps, withState, branch, renderComponent } from 'recompose';

import weeklyBGScheduleTemplateManyGQL from './gql/weeklyBGScheduleTemplateMany';

import { addWeeklyBGScheduleTemplateAPI, labelCurrentTemplate, addIDWhenMissing } from './addWeeklyBGScheduleTemplateAPI';

const debug = true;
const traceF = (label) => R.tap((x)=>console.log(label, x));
const trace = debug ? traceF : () => R.identity;

const { Icon, Row, Col, Dropdown, Menu, Button, Spin,
} = ND;

const mkMenu = (arr, onMenuItemClick) => (
  <Menu onClick={(e)=>{
    const t = R.find(({ _id, name })=> _id+name === e.key, arr);
    onMenuItemClick(R.pick(['name', 'description', 'schedule', '_id'], t));
  }}>
    {
       arr.map(({ _id, name }) => ( <Menu.Item key={_id+name}>{name}</Menu.Item>))
    }
  </Menu>
);

const style = {
  row1: { marginBottom: 20 },
  flexHorizontal: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
  flexVertical: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    color: 'rgba(0,0,0,.65)',
    fontWeight: 100,
  },
};

class WeeklyMealScheduleComponent extends React.Component {
  state = { visibleModal: 'none' }  // new, edit, none

  setVisibleModal = (visibleModal) => {
    this.setState({
      visibleModal,
    });
  };

  clearBG = () =>{
    const {weeklyMealSchedule, updateForm} = this.props;
    let updateBG = {...weeklyMealSchedule};
    let updateSchedule = _.get(updateBG, 'schedule');
    let freq = _.get(updateBG, 'frequency');
    if(freq && typeof freq === 'object'){
      freq.times = 0;
      freq.every = 0;
    }
    const empty = {
      after_breakfast: false,
      after_dinner: false,
      after_lunch: false,
      bedtime: false,
      before_breakfast: false,
      before_dinner: false,
      before_lunch: false,
      overnight: false,
    }
    if(updateSchedule){
      const days = Object.keys(updateSchedule);
      _.map(days, day => {
        updateSchedule[day] = empty
      })
      updateBG.schedule = updateSchedule;
    }
    updateForm(updateBG);
  }

  render() {
    const { templates, directEdit, directEditUpdate, weeklyMealSchedule } = this.props;
    return (
      <div>
        <Row>
          <Col xs={24} lg={13} style={R.mergeAll([{ paddingRight: 30, display: 'flex', flexDirection: 'column' }])} >
            <Row>
              <Dropdown overlay={mkMenu(templates, (t)=>this.props.updateForm(t))} trigger={['click']}>
                <Button size='large' style={R.merge(style.flexHorizontal, { height: 40, width: 390 } )} >
                  { R.isNil(weeklyMealSchedule) ? 'Select a template' : weeklyMealSchedule.name }
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </Row>

            { R.isNil(weeklyMealSchedule) ? null :
              <div style={{"height":"100%","display":"flex","flexDirection":"column"}} >
                <Row style={{ padding: 20 }} >
                  <div style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 8 }} >Template Description</div>
                  <div style={ style.text } > { weeklyMealSchedule.description }</div>
                </Row>
                <Row>
                  {
                    R.map((i)=><Col key={i} lg={24} xs={0} style={{ height: 40}}/>, R.range(0, 6))
                  }
                </Row>
                <Row style={{ marginTop: 'auto', justifyContent: 'center' }} >
                  <Col lg={24} xs={0} >
                    <hr style={{ width: 445, height: 15, color: '#c5c5c5' }} />
                  </Col>
                </Row>
                <Row style={ R.merge({ fontSize: 13 }, style.text) } >
                  <Col span={4} offset={15}>
                    <div>B = Before</div>
                    <div>A = After</div>
                  </Col>
                  <Col span={5} offset={0} >
                    <div><FaBed /> = Bedtime</div>
                    <div><FaMoon /> = Overnight</div>
                  </Col>
                </Row>
                <Row>
                  {
                    R.map((i)=><Col key={i} lg={0} xs={24} style={{ height: 10}}/>, R.range(0, 4))
                  }
                </Row>

              </div>
            }
          </Col>

          <Col xs={24} lg={11} style={ style.flexVertical } >
            {
              directEdit ? null :
                <div style={style.row1} >

                  { R.isEmpty(templates) ? null :
                    <Button size='large' type="primary" ghost onClick={this.setVisibleModal.bind(this, 'edit')} > Edit Templates </Button>
                  }
                  <Button size='large' type="primary" style={{ marginLeft: 20 }} onClick={this.setVisibleModal.bind(this, 'new')} >
                    <Icon type="plus" />
                    New Template
                  </Button>
                </div>
            }
            <Row style={{ minWidth: 450 }}>
              { R.isNil(weeklyMealSchedule) ? null
                : <WeeklyMealScheduleInputGrid value={ weeklyMealSchedule.schedule }
                    allowEdit={directEdit}
                    onChange={(schedule) => {
                      if(directEdit) this.props.updateForm(
                        labelCurrentTemplate('Customized', R.merge(weeklyMealSchedule, { schedule }))
                      );
                  }}/>
              }
            </Row>
          </Col>
        </Row>
        {
          // R.isEmpty(templates) ? null:
             <WeeklyMealScheduleEditor
              weeklyBGScheduleTemplateAPI={this.props.weeklyBGScheduleTemplateAPI}
              visibleModal={this.state.visibleModal}
              templates={R.reject(({ name })=> (new RegExp('(Current)')).test(name) , templates)}
              closeModal={()=>this.setVisibleModal('none')}
              updateParentForm={(weeklyMealSchedule) => {
                this.props.updateFrequency('weeklyMeal');
                this.props.updateForm(weeklyMealSchedule);
              }}
            />
        }
        {
          directEdit && R.isNil(weeklyMealSchedule) ? null :
          <Row style={{marginTop: '10px'}}>
            <a onClick={this.clearBG} style={{color: '#777777', textDecoration:'underline', float: 'right'}}>
             Clear All Selected Times
          </a>
        </Row>
        }
        
      </div>
    )
  }
}

WeeklyMealScheduleComponent.propTypes = {
  templates: PropTypes.array.isRequired,
  weeklyBGScheduleTemplateAPI: PropTypes.object,
  // updateForm: React.PropTypes.function,
  weeklyMealSchedule: PropTypes.object,
};

const Spinner = () => (
  <div style={{"textAlign":"center","background":"rgba(0,0,0,0.05)","borderRadius":"4px","marginBottom":"20px","padding":"30px 50px","margin":"20px 0"}} >
    <Spin />
  </div>
);

const spinnerWhileLoading = isLoadingF =>
  branch(
    isLoadingF,
    renderComponent(Spinner)
  )

const enhance = compose(
  addWeeklyBGScheduleTemplateAPI,
  spinnerWhileLoading(R.path(['weeklyBGScheduleTemplateAPI', 'loading'])),
  withState('currentSchedule','updateCurrentSchedule_notused', ({ weeklyMealSchedule }) => weeklyMealSchedule),
  withProps(({ weeklyBGScheduleTemplateAPI: { records }, currentSchedule, weeklyMealSchedule }) => ({
      templates: R.compose(R.uniqBy(({ _id, name })=>_id+name), R.concat(records), R.map(addIDWhenMissing), R.reject(R.isNil))([weeklyMealSchedule, currentSchedule])
    })
  ),
);

const WeeklyMealSchedule = enhance(WeeklyMealScheduleComponent);

export { WeeklyMealScheduleInputGrid, WeeklyMealSchedule, WeeklyMealScheduleEditor };
