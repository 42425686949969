import React from 'react';
import { Button } from 'antd';

export default {
  totalCaseText: {
    RD: 'Review Cases (XX)',
    HC: 'View Cases (XX)'
  },
  coverageText: 'The review coverage includes Clinical alert, Provider alert, MTPR, Call log (Non-tech assisstant call), Food diary, Chat history, Provider note, Task, Visit and Care plan.',
  summary: {
    title: {
      RD: `Mentee's Patient Cases Need Review`,
      HC: 'Work comments from mentor'
    },
    tipText: {
      RD: `Each Health Coach (HC) without credentials is matched with a mentor RD. The mentor needs to review the work that has been done on the mentee's patients.`,
      HC: 'These are the comments your mentor Registered Dietitian (RD) has left on the work done on your patients.'
    },
    userTitle: {
      RD: 'My Mentees:',
      HC: 'My mentor:'
    },
    btnText: {
      RD: 'Review now',
      HC: 'View'
    }
  },
  information: {
    clickableText: {
      RD: 'How review works?',
      HC: 'How viewing comment works?'
    },
    title: {
      RD: 'How review works?',
      HC: 'How viewing comment works?'
    },
    noShowText: 'Do not show me next time',
    okText: 'Got it',
    getContent: {
      RD: ({ onClick }) => (<div>
        <div>
          The review procedure is for <span className='highlight'>mentorship and audit purposes</span>. See <Button type='link' className='showCoverageBtn' onClick={onClick}>review coverage</Button>
        </div><br/>
        <div>
          The list below contains the work done on your mentee's patients. Please review these items. You can either directly finish reviewing, or leave a comment if you have suggestions. <span className='highlight'>Once the comment is submitted, you will not be able to edit it elsewhere.</span> Your mentee HC will receive the comment on their side.
        </div>
      </div>),
      HC: () => (<div>
        <div>
          Your mentor Registered Dietitian (RD) has commented on the work done on your patients.
        </div><br/>
        <div>
          Please view the comment and acknowledge. <span className='highlight'>Once acknowledged, the comment won't be accessible anymore.</span> So please talk with your mentor RD if you have any questions regards the comment.
        </div>
      </div>)
    }
  },
  submitBtnText: {
    RD: 'Finish review',
    HC: 'Acknowledge'
  },
  finishedMessage: {
    RD: {
      greeting: 'Good job!',
      subMessage: `You've reviewed all the work that has been done on your mentee's patients.`
    },
    HC: {
      greeting: 'Nice',
      subMessage: `You've viewed all the comments left by your mentor Registered Dietitian (RD).`,
    },
  },
  feedback: {
    success: {
      RD: 'The work has been reviewed.',
      HC: 'Comment acknowledged'
    }
  }
}