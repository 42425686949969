import gql from 'graphql-tag';

export default gql`
query postItList($memberId:EID!){
  postItList(memberId:$memberId){
    id
    createdAt
    note
    modifiedAt
    modifiedBy{
        profile{
            fullName
        }
    }
    createdBy{
      profile{
        fullName
      }
    }
  }  
}`;
