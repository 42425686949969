import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Icon } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import proptypes from '../../constants/proptypes';
import TaskAssignmentHistory from '../TaskAssignmentHistory';
import TaskWorkStationReassignment from './TaskWorkStationReassignment';
import TaskWorkStationInput from './TaskWorkStationInput';
import TimerContainer from '../../../timer/containers/TimerContainer';

import TaskConstants from '../../constants/tableMap';
import { DataHelper } from '../../../../lib/utils';

import * as timerActions from '../../../timer/actions';

const { stopTimer,updateInterventionDetails }= timerActions.default;

const {
  priorityMap,
  statusMap,
  colorMap,
  checkIfDaysBetween,
  checkStatus
} = TaskConstants;

const TaskWorkStationComponent = props => {
  const { 
    member: { id: memberId } = {}, 
    task, 
    refetchTaskAssignments, refetchUnresolvedTasksCount,
    timer, updateInterventionDetails, stopTimer,
    onInteraction, currentProgram
  } = props;
  const [currentUser] = useState(DataHelper.getCurrentUser());
  const [showReassignForm, setShowReassignForm] = useState(false);
  const [shouldRenderTimer, setShouldRenderTimer] = useState(false);

  const displayNamePrefix = `${TaskWorkStationComponent.displayName}-${task.id}`;
  const startTime = _.get(timer, `${displayNamePrefix}:${memberId}.startTime`);
  const stopTime = _.get(timer, `${displayNamePrefix}:${memberId}.stopTime`);

  useEffect(() => {
    if(!_.isNil(stopTime)) {
      setShouldRenderTimer(false);
    }
    if(_.isNil(startTime)) {
      setShouldRenderTimer(true);
    }
  }, [startTime, stopTime]);

  const renderTimer = () => {
    const viewingDetails ={
      component : TaskWorkStationComponent.displayName,
      path: window.location.pathname
    };
    const keepEventListener = true;
    
    return shouldRenderTimer && (
      <TimerContainer 
        displayName={displayNamePrefix} 
        category='TASK_ASSIGNMENT' 
        action='UPDATE'
        viewingDetails={viewingDetails}
        patientId={memberId}
        keepEventListener={keepEventListener}
      />
    );
  };

  const handleCreateTrackingTime = (res, cb) => {
    const apiName = Object.keys(res.data)[0];
    const summary = res.data[apiName].id;
    const docId = res.data[apiName].id;
    const resource = atob(docId).split(':')[0];
    let displayName = `${displayNamePrefix}:${memberId}`;
    updateInterventionDetails(displayName,apiName,resource,docId,summary);
    stopTimer(displayName, 'saveModal');
    if(cb)
      cb();
  };

  const renderCurrentStatus = () => {
    const { dueDate, status, resolvedNote } = task || {};
    // const isRead = data.isRead ? data.isRead.value : false; //TODO: check if need to apply this logic
    const now = status === statusMap.RESOLVED ? _.get(resolvedNote, 'resolvedAt', '') : new Date().getTime();
    const date = checkIfDaysBetween(now, dueDate);
    const curStatus = checkStatus(status, date);
    const color = colorMap[curStatus];
    return (
      <Col span={14} className='task-assignment-description' style={{ color }}>
        {curStatus}
      </Col>
    );
  };

  return (
    <React.Fragment>
      { renderTimer() }
      {
        showReassignForm ?
          <React.Fragment>
            <TaskWorkStationReassignment
              currentUser={currentUser}
              memberId={memberId}
              task={task}
              setShowReassignForm={setShowReassignForm}
              refetchTaskAssignments={refetchTaskAssignments}
              onInteraction={onInteraction}
              handleCreateTrackingTime={handleCreateTrackingTime}
              setShowUnsavedModal={props.setShowUnsavedModal}
              currentProgram={currentProgram}
            />
          </React.Fragment>
          :
          <React.Fragment>
            <Row className='wrapper'>
              <Row style={{ display: 'flex', flexDirection: 'column', marginBottom: 24 }}>
                <Col span={24} className='task-assignment-reason-title'>
                  Task Reason
                </Col>
                <Col span={24} className='task-assignment-reason-description'>
                  {task.reason || '--'}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 12 }}>
                <Col span={10} className='task-assignment-title'>
                  Priority:
                </Col>
                <Col
                  span={14}
                  className={`task-assignment-description ${priorityMap[task.priorityLevel] || ''}`}
                >
                  {priorityMap[task.priorityLevel] || '--'}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 12 }}>
                <Col span={10} className='task-assignment-title'>
                  Status:
                </Col>
                { renderCurrentStatus() }
              </Row>
              <Row style={{ display: 'flex', marginBottom: 12 }}>
                <Col span={10} className='task-assignment-title'>
                  Date assigned:
                </Col>
                <Col span={14} className='task-assignment-description'>
                  {moment(task.createdAt).format('MM/DD/YYYY')}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 12 }}>
                <Col span={10} className='task-assignment-title'>
                  Due Date:
                </Col>
                <Col span={14} className='task-assignment-description'>
                  {task.dueDate ? moment(task.dueDate).format('MM/DD/YYYY') : '--'}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 12 }}>
                <Col span={10} className='task-assignment-title'>
                  Creator:
                </Col>
                <Col span={14} className='task-assignment-description'>
                  {task.createdBy.profile.fullName || '--'}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 4 }}>
                <Col span={10} className='task-assignment-title'>
                  Assignee:
                </Col>
                <Col span={14} className='task-assignment-description'>
                  {task.assignedTo ? task.assignedTo.profile.fullName : '--'}
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 20 }}>
                <Col offset={10} span={14}>
                  <Button
                    type='link'

                    className='reassign-link'
                    onClick={() => setShowReassignForm(true)}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
              <Row style={{ display: 'flex', marginBottom: 26 }}>
                <Col span={24}>
                  <TaskAssignmentHistory
                    taskCreatedAt={task.createdAt}
                    history={task.history}
                    showButton={(isShowing, buttonProps, taskHistoryLength) => {
                      return (
                        <Button
                          {...buttonProps}
                          block
                        >
                          Show task reason history ({taskHistoryLength})
                          <Icon type={!isShowing ? 'down' : 'up'} style={{ fontSize: 12, marginLeft: 15 }} />
                        </Button>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Row>
            <TaskWorkStationInput
              memberId={memberId}
              currentUser={currentUser}
              task={task}
              refetchUnresolvedTasksCount={refetchUnresolvedTasksCount}
              refetchTaskAssignments={refetchTaskAssignments}
              onInteraction={onInteraction}
              handleCreateTrackingTime={handleCreateTrackingTime}
              setShowUnsavedModal={props.setShowUnsavedModal}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );    
};

TaskWorkStationComponent.propTypes = proptypes.TaskWorkStationComponent;
TaskWorkStationComponent.displayName = 'TaskWorkStationComponent';

export default 
connect(
  state => ({
    timer: _.get(state, 'timer.main'),
    uuid: _.get(state,'timer.main.uuid', null)
  }),
  dispatch => ({
    stopTimer:(displayName,event)=>dispatch(stopTimer(displayName,event)),
    updateInterventionDetails:(...args) => dispatch(updateInterventionDetails(...args)), // args: (displayName,apiName,resource,docId,summary),
  })
)(TaskWorkStationComponent);
