
import { getCareTeamMemberAvailability, editConditionsListQuery } from '../query/index';
import Client from 'libModule/gqlClient';
const getCareTeamMemberAvailabilityAPI = (variables)=>{
    return Client.query({
        query:getCareTeamMemberAvailability,
        variables:variables,
        fetchPolicy:'network-only'
    })
};
const editConditionsList = (variables) => {
    return Client.mutate({
        mutation: editConditionsListQuery,
        variables: variables,
        fetchPolicy:'no-cache'
    })
}
export {
    getCareTeamMemberAvailabilityAPI,
    editConditionsList
}
