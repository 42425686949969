import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

const ScreeningSuccess = ({ closeInSeconds = 5, closeModal }) => {
  const [countDown, setCountDown] = useState(closeInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(cd => cd - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      closeModal();
    }
  }, [countDown]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%' }}>
      <div>
        <img src="/image/smile_face.png" width="100px" />
        <div style={{ fontWeight: 'bold', marginBottom: 300, marginTop: 20, fontSize: 17 }}>Great! Thanks for screening this patient!</div>
        <div style={{ marginBottom: 10 }}><Button type="primary" className="new-style" onClick={closeModal}>Close</Button></div>
        <div>(This popup will automatically close in {countDown} seconds)</div>
      </div>
    </div>
  );
}

export default ScreeningSuccess;
