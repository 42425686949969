import React from 'react';
import Client from 'libModule/gqlClient';
import quicksightDashboard from '../graphql/quicksightDashboard';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

const AdminDashboard = class extends React.Component {
  static displayName = 'layout/components/DashboardsComponent';
  render() {
    const dashboardId = '0fee8fe1-4d03-445e-aec1-033ab817be11';
    const viewerInfo = JSON.parse(sessionStorage.getItem('currentUser'));
    const organizationName = viewerInfo.selectedRole.organization.name;
    const username = viewerInfo.username;
    Client.query({
      query: quicksightDashboard,
      variables: {
        username,
        dashboardId
      },
      fetchPolicy: 'network-only',
    })
      .then((data) => {
        const containerDiv = document.getElementById('dashboardContainer');
        containerDiv.innerHTML = '';
        const options = {
          url: data.data.quicksightDashboard,
          container: containerDiv,
          scrolling: 'no',
          height: '900px',
          width: '1500px',
        };
        if (organizationName.trim() !== '') {
          options['parameters'] = {
            organizationName,
          };
        }
        console.log(options);
        QuickSightEmbedding.embedDashboard(options);
      })
      .catch((e) => {
        console.log(e);
      });
    return (
      <div>
        <div id="dashboardContainer"></div>
      </div>
    );
  }
};

export default AdminDashboard;