import React from 'react';
import { Button, Popover } from 'antd';
import SetPasswordContainer from './SetPasswordContainer';

export default class PasswordPopoverComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  render() {
    return (
      <Popover 
        overlayClassName='change-password-popover'
        content={<SetPasswordContainer patientId={this.props.patientId} onCancel={this.hide} onSuccess={this.hide} />}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Button>{this.props.buttonText}</Button>
      </Popover>
    );
  }
}