import BG from './VitalListBGReducer'
import BP from './VitalListBPReducer'
import HS from './VitalListHSReducer'
import HR from './VitalListHRReducer'
import TM from './VitalListTMReducer'
import PO from './VitalListPOReducer'
import Wrapper from './VitalListWrapperReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  BG,
  BP,
  HS,
  HR,
  TM,
  PO,
  Wrapper
})
