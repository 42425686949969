import { emailValidator2, mobileValidator } from 'libModule/validator';
import { getInitDataFromSchema, getFieldsRequiredFromSchema, getFieldsDisabledFromSchema } from 'libModule/helpers/reducer-helpers'
import { mapPropsToFields } from 'libModule/utils'

export const schema = {
  name: {
    initialValue: '',
    required: true
  },
  description: {
    initialValue: '',
    required: false
  },
  contactName: {
    initialValue: ''
  },
  // healthCondition: {
  //   initialValue: '',
  //   required: true
  // },
  countryCode: {
    initialValue: '+1',
    required: false,
    disabled: true
  },
  number: {
    initialValue: '',
    required: false
  },
  forwardingPhoneCountryCode: {
      initialValue: '+1',
      required: false,
      disabled: true
  },
  forwardingPhone:{
    initialValue:'',
      required: false
  },
  email: {
    initialValue: '',
    required: false
  }
}

export const initData = getInitDataFromSchema(schema)

export const isFieldsRequired = getFieldsRequiredFromSchema(schema)

export const isFieldsDisabled = getFieldsDisabledFromSchema(schema)

export const getFormProps = ({ fieldsValue, onFieldsChange }) => {

  const itemList = [
    [
      {
        key : 'name',
        label : 'Name',
        type : 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 14, offset: 0 },
        placeholder: 'Care Team name',
        initialValue: initData['name'],
        rules : [
          {required : isFieldsRequired.name, message : 'Name is required'},
        ],
      }
    ],
    [
      {
        key: 'description',
        label: 'Description',
        type: 'textarea',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 14, offset: 0 },
        placeholder: '',
        initialValue: initData['description'],
      }
    ],
   /* [
      {
        key : 'healthCondition',
        label : 'Health Condition',
        type : 'select',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        col: { span: 10, offset: 0 },
        placeholder : 'Select Health Condition',
        // disabled : select ? true : false,
        // initialValue : select ? select._get('healthCondition') : '',
        initialValue: initData['healthCondition'],
        option: [
          {value: 'DIABETES', name: 'Diabetes'},
          {value: 'HEART_FAILURE' , name: 'Heart Failure'},
          {value: 'AMI', name: 'AMI'},
          {value: 'HYPERTENSION', name: 'Hypertension'}
        ],
        rules : [
          {required: isFieldsRequired.healthCondition, message: 'Health Condition is required'},
        ]
      },
    ],*/
    [
        {
            key: 'contactName',
            label: 'Contact Name',
            type: 'text',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            col: { span: 14, offset: 0 },
            placeholder: '',
            initialValue: initData['contactName'],
        }
    ],
    [
      {
        key: 'countryCode',
        label: 'Mobile',
        type: 'text',
        disabled: true,
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        initialValue: initData['countryCode'],
        col: { span: 2, offset: 0 },
        rules : [
          {required : isFieldsRequired.countryCode}
        ],
      },
      {
        key: 'number',
        label: ' ',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: '#### ####',
        placeholder: '',
        initialValue: initData['number'],
        col: { span: 8, offset: 0 },
        rules : [
          {required : isFieldsRequired.number, message : 'Mobile Number is required'},
          {validator: (rule, value, callback) => callback(mobileValidator(value, 'mobilePhone'))}
        ],
        className: 'hide-required-mark'
      }
    ],
    [
        {
            key: 'forwardingPhoneCountryCode',
            label: 'Forwarding Phone',
            type: 'text',
            disabled: true,
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            initialValue: initData['forwardingPhoneCountryCode'],
            col: { span: 2, offset: 0 },
            rules : [
                {required : isFieldsRequired.countryCode}
            ],
        },
        {
            key: 'forwardingPhone',
            label: ' ',
            type: 'text',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
            placeholder: '',
            initialValue: initData['forwardingPhone'],
            col: { span: 8, offset: 0 },
            rules : [
                {required : isFieldsRequired.number, message : 'Forwarding Number is required'},
                {validator: (rule, value, callback) => callback(mobileValidator(value, 'mobilePhone'))}
            ],
            className: 'hide-required-mark'
        }
    ],
    [
      {
        key: 'email',
        label: 'Email Address',
        type: 'text',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        // placeholder: 'Email Address',
        placeholder: '',
        initialValue: initData['email'],
        col: { span: 14, offset: 0 },
        rules : [
          {required : isFieldsRequired.email, message : 'Email is required'},
          {validator: (rule, value, callback) => callback(emailValidator2(value))}
        ],
      }
    ]
  ]

  return {
    vertical: true,
    fieldsValue,
    onFieldsChange,
    mapPropsToFields,
    itemList
  }
}
