import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ResolvedNote = props => {
  const { resolvedBy, resolvedAt, note = '--' } = props;
  const resolver = _.get(resolvedBy,'profile.fullName');
  return (
    <div id='task-resolved-note-container'>
      <div className='task-resolved-note'>
        Resolved Note:
        <p className='task-shadow-text no-margin'>{note}</p>
      </div>
      <div className='task-resolved-info-container'>
        <div style={{ marginRight: 10 }}>
          Resolved By: 
          <span className='task-shadow-text'>{resolver}</span>
        </div>
        <div className='task-shadow-text'>
          {moment(resolvedAt).format('MM/DD/YYYY hh:mm')}
        </div>
      </div>
    </div>
  );
};

ResolvedNote.propTypes = {
  note: PropTypes.string.isRequired,
  resolvedBy: PropTypes.object.isRequired,
  resolvedAt: PropTypes.number.isRequired
};

export default ResolvedNote;
